import { selectorFamily } from 'recoil'
import { citiesAtom, countriesAtom, userAtom } from './atoms'

export const authSelector = selectorFamily({
	key: 'authSelector',
	get:
		(props) =>
		({ get }) => {
			const user = get(userAtom)

			const authorized = true
			return user ? { user: user, authorized } : undefined
		},
})

export const locationSelector = selectorFamily({
	key: 'locationSelector',
	get:
		(props) =>
		({ get }) => {
			const countries = get(countriesAtom)
			const cities = get(citiesAtom)
			return [countries, cities]
		},
})
