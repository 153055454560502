import { PrimaryHeader, Footer } from '@components'
import { useEffect } from 'react'
import Lounge from './images/lounge.png'
import One from './images/one.svg'
import Two from './images/two.svg'
import Three from './images/three.svg'
import './howItWorksNew.modules.css'

const HowItWorksNew = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
	}, [])
	return (
		<div className='h-full flex flex-col justify-between'>
			<PrimaryHeader />
			<div className='flex flex-col lg:px-24 md:px-12 max-md:px-6 items-center pt-32 pb-24 min-h-[700px] extraLargePadding'>
				{/* <p className='font-bold text-[#9B83CB] text-[36px] leading-[40px] pb-12'>How it works</p> */}

				<p className='text-[2rem] leading-[2.1rem] text-[#474747] font-bold pb-6 text-left'>
						How It Works
					</p>
					<p className='font-[500] text-[gray] text-[20px] leading-[26px] pb-6'>Follow these 3 simple steps to get it started! </p>
				
				<div className='mainFlex'>
					<div className='displayNoneToMobile'>
						<img className='w-[90%] object-cover pb-2' src={Lounge} alt='' />
					</div>	
				

					<div className='displayYesToMobile'>
						
						<div className='subFlex'>
							<div className='iconContainer'>
								<img className='icon' src={One} alt='' />
								<div className='line'></div>
							</div>
							<div>
								<p className='font-[400] text-[#9B83CB] text-[22px] leading-[40px] pb-3'>(1) Start Your Free Trial and List Your Property</p>
								<p className='font-[400] text-[#333333] text-[14px] leading-[26px] pb-8'>
								Sign up for a free trial on <span className='text-[#9B83CB]'><a href='https://cityswapp.com/home/about' target='blank'></a>www.cityswapp.com</span> and create your account. If you have a home, list it on the platform for swap or sublease, specifying available dates. If you're just looking for subleasing properties, skip listing a home and proceed to Step 2.
								</p>
							</div>
						</div>


						<div className='subFlex'>
							<div className='iconContainer'>
								<img className='iconTwo' src={Two} alt='' />
								<div className='line2'></div>
							</div>
							<div>
							<p className='font-[400] text-[#9B83CB] text-[22px] leading-[40px] pb-3'>(2) Browse, Message & Connect</p>
						<p className='font-[400] text-[#333333] text-[14px] leading-[26px]  pb-8'>
						Explore properties based on your destination and preferences, regardless of whether you have a home to list. Send messages to inquire about swap or sublease possibilities, using the platform's messaging feature. Discuss date flexibility and details with potential matches before finalising plans. If interested, organise a video call on platforms like Zoom or Google Meet to enhance trust and finalise arrangements.
						</p>
							</div>
						</div>


						<div className='subFlex'>
							<div className='iconContainer'>
								<img className='iconThree' src={Three} alt='' />
							</div>
							<div>
								<p className='font-[400] text-[#9B83CB] text-[22px] leading-[40px] pb-3'>(3) Finalize & Approve</p>
								<p className='font-[400] text-[#333333] text-[14px] leading-[26px] pb-8'>
									For direct swap requests: Ensure dates align, and both parties agree. Adjust listed property dates if needed to enable the direct swap request. If swap dates don’t match, the platform provides suggestions to sublease instead and recommend other subleasing properties if they are any listed in the area of your preference. For sublease requests: use the sublease filter when browsing. Send sublease requests. Once details are finalised and both parties are in agreement, get the host's approval for swap requests or confirm sublease arrangements. Continue communication through the messaging system for any further coordination or adjustments.
								</p>
							</div>
						</div>
						
					</div>
				</div>

				<div className='displayOnlyToMobile'>
						<img className='w-full object-cover pb-2' src={Lounge} alt='' />
				</div>

			</div>
			<Footer />
		</div>
	)
}

export default HowItWorksNew
