import { Steps } from 'antd'

const { Step } = Steps

const StepsHeader = ({ pages, page, showBackIcon = false, buttonfuncs }) => {
	return (
		<>
			<div className='flex h-full flex-row items-center justify-start bg-white'>
				<Steps
					responsive={false}
					direction='horizontal'
					size='small'
					current={page}
					progressDot
					items={pages?.map((page) => ({ title: <div className='max-md:text-[10px]'>{page.title}</div> }))}
				>
					{pages
						?.filter((page) => !page.resultPage)
						?.map((pageData, index) => (
							<Step key={`step-${index}`} className='items-center' status={index < page ? 'finish' : 'process'} />
						))}
				</Steps>
			</div>
		</>
	)
}

export default StepsHeader
