import React, { useContext, useState } from 'react'
import { Modal, Upload } from 'antd'
import { firebase } from '@auth'
import { Form } from '@components'
import { ListingContext } from '../pages/newListing/helpers/context'
import { mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'

// test https://firebasestorage.googleapis.com/v0/b/cityswapp-55550.appspot.com/o/public%2Fimages%2Fblogs%2F1-malaysiaProperty.PNG?alt=media&token=bdfc09f7-ac9c-489f-845e-4f0c38b06fd6

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})

const normFile = (e) => {
	if (Array.isArray(e)) return e
	return e && e.fileList
}
const PropertyPictures = (props) => {
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')

	const DocumentUpload = async ({ file, onProgress, onSuccess, onError }) => {
		
		const response = firebase.storage
			.ref()
			.child(
				`public/images/blogs/2-${file.name}`
			)
			.put(file)
		response.on(
			'state_changed',
			(snapshot) => onProgress({ percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100 }),
			(error) => onError(error),
			() => onSuccess(null, response.metadata_)
		)
	}
	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj)
		}
		setPreviewImage(file.url || file.preview)
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
	}
	const ChangeFileList = async ({ fileList }) => {
		if (fileList.length > 0) {
			fileList.forEach((file, index) => {
				if (!file.url && file.status === 'done') {
					const response = firebase.storage
						.ref()
						.child(
							`public/images/blogs/2-${file.name}`
						)
					response.getDownloadURL().then((result) => {
						fileList[index].url = result
						console.log("Image URL is " , result)
						props.setMainImage(fileList)
						
					})
				}
			})
		}
	}
	const handleCancel = () => setPreviewOpen(false)

	return (
		<>
			<p className='text-[#333333] font-[700] text-2xl pb-7 pl-2'>Upload Blog Background Picture</p>

			<Form.Item
				key={'photo-upload'}
				name={['photos']}
				rules={[{ required: true, message: `Please upload at least one photo` }]}
				valuePropName='fileList'
				getValueFromEvent={normFile}
			>
				<Upload
					className='max-md:w-full'
					customRequest={DocumentUpload}y
					listType='picture-card'
					onPreview={handlePreview}
					multiple={true}
					onChange={ChangeFileList}
				>
					<div className='bg-[#B3A7C9B2] md:h-full md:w-full sm:w-full sm:h-full xs:h-24 xs:w-24 flex flex-row items-center justify-center rounded-lg border border-solid border-[#664F94]'>
						<Icon path={mdiPlus} size={1.5} className='text-[#333333]' />
					</div>
				</Upload>
			</Form.Item>

			<Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
				<img
					alt='example'
					style={{
						width: '100%',
					}}
					src={previewImage}
				/>
			</Modal>
		</>
	)
}
export default PropertyPictures
