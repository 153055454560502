import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Form, Loader } from '@components'
import { endpoints } from '../../../helpers/enums'
import { useCallback, useState } from 'react'
import { Button, Input, notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import { userAtom } from '@atoms'
import { useSetRecoilState } from 'recoil'
const StripeForm = ({ success, setSuccess = false, userEmail, action, isCoupon = true }) => {
	const navigator = useNavigate()
	const stripe = useStripe()
	const elements = useElements()
	const [form] = Form.useForm()
	const setUserAtom = useSetRecoilState(userAtom)
	const [loading, setLoading] = useState(false)
	// console.log({ isCoupon, action })

	const handleSubmit = useCallback(async (e) => {
		if (!elements || !stripe) {
			return
		}

		setLoading(true)
		const CardNumberElement = elements.getElement('cardNumber')

		await stripe
			.createToken(CardNumberElement)
			.then((result) => {
				if (result.error) {
					console.log(result.error.message)
					notification.error({
						message: 'Error',
						description: `${action === 'update' ? 'Payment method updation has failed.' : 'Unable to subscribe and proceed further.'} Please try again later.`,
					})
					setLoading(false)
				} else {
					console.log('Token sucessfully generated', result)
					form.validateFields().then(async (values) => {
						await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}${endpoints[action === 'update' ? 'change-default-payment-method' : 'create-subscription']}`, {
							method: 'POST',
							headers: { 'Content-Type': 'application/json;charset=utf-8' },
							body: JSON.stringify({
								email: userEmail,
								token: result?.token,
								renew: action !== 'update' ? false : true,
								...(values?.couponCode && action !== 'update' ? { couponCode: values?.couponCode } : {}),
							}),
						}).then(async (response) => {
							if (response.status === 200) {
								response.json().then(async (data) => {
									notification.success({
										message: `${action === 'update' ? 'Updation' : 'Subscription'} Successful`,
										description: action === 'update' ? 'Your payment method has been updated sucessfully.' : 'Your subscription is now active!',
									})

									console.log('Successfully added data Payment', data)
									setUserAtom({ ...data?.updatedUser })
									localStorage.setItem('user', JSON.stringify({ ...data?.updatedUser, id: data?.updatedUser?._id }))
									// if (action === 'update') {
									// 	await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}${endpoints['create-subscription']}`, {
									// 		method: 'POST',
									// 		headers: { 'Content-Type': 'application/json;charset=utf-8' },
									// 		body: JSON.stringify({ email: userEmail, renew: true, ...(values?.couponCode ? { couponCode: values?.couponCode } : {}) }),
									// 	}).then(async (response) => {
									// 		if (response.status === 200) {
									// 			response.json().then((data) => {
									// 				notification.success({
									// 					message: 'Subscription Successful',
									// 					description: 'Your subscription is now active! You can cancel your subscription anytime.',
									// 				})
									// 				console.log('Successfully sent data Payment', data)
									// 				setUserAtom({ ...data?.updatedUser })
									// 				localStorage.setItem('user', JSON.stringify({ ...data?.updatedUser, id: data?.updatedUser?._id }))
									// 			})
									// 		} else {
									// 			console.log('Successfully sent data Payment', response)
									// 			const error = await response.json()
									// 			console.log('Subscription Failed', error)
									// 			notification.error({
									// 				message: 'Error',
									// 				description: `${
									// 					error?.Error === 'Coupon Not Valid'
									// 						? 'Invalid Coupon Code.'
									// 						: error?.Error === 'Sorry! This code has been used by all the eligible users.'
									// 						? 'Sorry! This code has been used by all the eligible users.'
									// 						: action === 'update'
									// 						? 'Payment method updation has failed.'
									// 						: 'Unable to subscribe and proceed further.'
									// 				} Please try again.`,
									// 			})
									// 		}
									// 	})
									// }
									setSuccess && setSuccess(true)
									setLoading(false)
									if (action != 'new') {
									navigator(-1)
									}
								})
							} else {
								const error = await response.json()
								console.log('Subscription Failed', error)
								notification.error({
									message: 'Error',
									description: `${
										error?.Error === 'Coupon Not Valid'
											? 'Invalid Coupon Code.'
											: error?.Error === 'Sorry! This code has been used by all the eligible users.'
											? 'Sorry! This code has been used by all the eligible users.'
											: action === 'update'
											? 'Payment method updation has failed.'
											: 'Unable to subscribe and proceed further.'
									} Please try again.`,
								})
								setSuccess && setSuccess(false)
								setLoading(false)
							}
						})
					})
				}
			})
			.catch((error) => {
				console.log('promise', error)
				notification.error({
					message: 'Error',
					description: `${action === 'update' ? 'Payment method updation has failed.' : 'Unable to subscribe and proceed further.'} Please try again later.`,
				})
				setLoading(false)
			})
	}, [])
	return (
		<div className='flex flex-col lg:w-[80%]'>
			{success ? (
				<>
					<Button onClick={() => navigator('/home/about')} className='btn-primary !h-[50px] !w-full mt-8'>
						Go to Home
					</Button>
				</>
			) : (
				<Form form={form} onFinish={handleSubmit}>
					{!stripe || !elements ? (
						<div className='my-auto align-middle'>
							<Loader />
						</div>
					) : (
						<div className='flex flex-col w-full py-2 mx-auto'>
							<div>
								<div className='font-bold text-sm text-[#222222] mb-2'>Card Number:</div>
								<CardNumberElement className='w-full rounded py-3 px-4 font-medium text-base bg-[#F6F7F9] mb-4' />
							</div>
							<div className='flex flex-row justify-between !w-full mb-4'>
								<div className='w-[47%]'>
									<div className='font-bold text-sm text-[#222222] mb-2'>Exp Date:</div>
									<CardExpiryElement className='rounded py-3 px-4 font-medium text-base bg-[#F6F7F9]' />
								</div>
								<div className='w-[47%]'>
									<div className='font-bold text-sm text-[#222222] mb-2'>CVC:</div>
									<CardCvcElement className='rounded py-3 px-4 font-medium text-base bg-[#F6F7F9]' />
								</div>
							</div>

							{isCoupon && (
								<div>
									<div className='font-bold text-sm text-[#222222] mb-2'>Coupon Code:</div>

									<Form.Item name='couponCode'>
										<Input className='w-full rounded !border-0 py-3 px-4 font-medium text-base bg-[#F6F7F9] mb-4' placeholder='Enter Coupon Code' />
									</Form.Item>
								</div>
							)}
						</div>
					)}
					<Button htmlType='submit' loading={loading} className='btn-primary !h-[50px] !w-full my-8'>
						{action === 'update' ? 'Update' : 'Subscribe'}
					</Button>
					<div className='text-center text-xs font-normal'>All the payments are powered by Stripe.</div>
				</Form>
			)}
		</div>
	)
}
export default StripeForm

// eqOAginV
