import React, { useState, useEffect } from 'react';
import { PrimaryHeader, Footer } from '@components'
import axios from 'axios';
import Post from './Post';
import coverH from './aboutCover.png';
import cover from './blogCover.png';
import dateImage from './date.svg';
import calendarImage from './calendar.svg';
import authorImage from './author.svg';
import './blog.modules.css';
import { useParams } from 'react-router-dom'

const BlogView = () => {
    const [posts, setPosts] = useState([]);
    const { id } = useParams()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}posts/${id}`)
            .then(res => setPosts(res.data))
            .catch(err => console.error(err));
    }, []);


    // Recent Posts Section
    const [postss, setPostss] = useState([]);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}posts`)
            .then(res => setPostss(res.data))
            .catch(err => console.error(err));
    }, []);

    let lastTwoPostsContainCurrentId = false

    return (
        <div >
            
            <PrimaryHeader />
        <div className='mainClassAboutWebsite'>
            <br></br>
            <br></br>
            <br></br>   
        <div className='mainClassAboutContainer'>
            {/* <img src={cover} className='aboutCoverImage' alt='Ali' /> */}
            


            <div style={{ position: 'relative', width: '100%' }}>
            <img src={(posts && posts.pictures && posts.pictures[0]) ? posts.pictures[0] : cover} className='aboutCoverImage' alt='Blog Cover' />
            <div 
                style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontSize: '32px', // Default font size for larger screens
                textAlign: 'center'
                }}
                className="responsiveText" // Add this class
            >
                {posts ? posts.title : 'Loading...'}

                {/* What are some trendy home decor ideas for small spaces? */}
                <div className='flex justify-center'>
                <br></br> 
                            <div className='flex justify-center items-center font-[600] text-[18px] w-[100%] authorNameMobile'>
                                <img className='authorMobile' src={authorImage} alt='Date' />&nbsp;&nbsp;{posts ? posts.author : '  '}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <img className='authorMobile' src={calendarImage} alt='Calendar' />&nbsp;&nbsp;{posts ? posts.publishDate : '  '}
                            </div>

                </div>
                
            </div>
            </div>

            <br></br>
            <div className='mainFlexBelowImage'>
                <div className='mainFlex70'>
                    <p className='text-[1.7rem] leading-[1.9rem] text-[#474747] font-bold pt-6 pb-6 text-left textHeadingOnMobile'>{posts ? posts.background : 'Loading...'}</p>
                    <p className='font-[400] text-[#333333] text-[14px] leading-[26px] pb-8'> {posts ? posts.bgPara : 'Loading...'} </p>

                    <p className='text-[1.7rem] leading-[1.9rem] text-[#474747] font-bold pt-6 pb-6 text-left textHeadingOnMobile'>{posts ? posts.solution : 'Loading...'}</p>
                    <p className='font-[400] text-[#333333] text-[14px] leading-[26px] pb-8'> {posts ? posts.solPara1 : 'Loading...'} </p>
                    <p className='font-[400] text-[#333333] text-[14px] leading-[26px] pb-8'> {posts ? posts.solPara2 : 'Loading...'} </p>

                    <p className='text-[1.7rem] leading-[1.9rem] text-[#474747] font-bold pt-6 pb-6 text-left textHeadingOnMobile'>{posts ? posts.conclusion : 'Loading...'}</p>
                    <p className='font-[400] text-[#333333] text-[14px] leading-[26px] pb-8'> {posts ? posts.conclusionPara : 'Loading...'} </p>

                </div>
                <div className='mainFlex30'>
                    <p className='text-[1.5rem] leading-[1.7rem] text-[#474747] font-bold pt-6 pb-6 text-center textHeadingOnMobile'>Recent Posts</p>
                    {/* <p className='font-[400] text-[#333333] text-[14px] leading-[26px] pb-8'> In response to shifting consumer preferences and lifestyle trends, the project of exploring trendy home decor ideas emerged as a means to cater to an increasingly design-conscious audience seeking to personalize their living spaces. Drawing inspiration from various sources such as social media influencers, design blogs, and emerging interior design trends, the project aims to curate a diverse range of innovative and aesthetically pleasing decor concepts. By staying attuned to evolving tastes and preferences, the project seeks to offer fresh perspectives and creative solutions that resonate with contemporary lifestyles, thereby empowering individuals to transform their homes into stylish and inviting environments reflective of their unique personalities and interests. </p> */}

                    <div className='.flexPostRecent'>

                        {lastTwoPostsContainCurrentId = postss.slice(-2).some(post => post._id === id)}
                        {lastTwoPostsContainCurrentId ? (
                            // Render the 4th and 3rd last posts
                            postss.slice(-4,-2).map(post => (
                                <Post key={post._id} post={post} />
                            ))
                        ) : (
                            // Render the last two posts
                            postss.slice(-2).map(post => (
                                <Post key={post._id} post={post} />
                            ))
                        )}
                    </div>

                </div>
            </div>
            <br></br><br></br>
            
        </div>
        </div>
        <Footer />
        </div>
    );
};

export default BlogView;
