import { citiesAtom, countriesAtom } from '@atoms'
import { useCallback, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

const { endpoints } = require('../helpers/enums')
export const findLocations = async (setCountries, setCities) => {
	const locationsResponse = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}${endpoints.findLocation}`, {
		method: 'GET',
		headers: { 'Content-Type': 'application/json;charset=utf-8' },
	})

	if (locationsResponse.status === 200) {
		const data = await locationsResponse.json()
		setCountries(data.countries)
		setCities(data.cities)
	}
}
