import FooterIcon from '../../assets/logos/footerLogo2.png'

const FooterLogo = () => {
	return (
		<div className=' '>
			<img className='' src={FooterIcon} alt='' />
		</div>
	)
}

export default FooterLogo
