import React from 'react';
import './blog.modules.css'; // Make sure the file name is blog.module.css if you are using CSS Modules
import { useNavigate } from 'react-router-dom';

const Post = ({ post }) => {
    const navigate = useNavigate();

    // Adjusted to be a function that navigates when the div is clicked
    const navigateToPost = () => {
        navigate(`/blog/${post._id}`);
        window.location.reload();
    };

    return (
        <div className="post-container" onClick={navigateToPost}> {/* Here we reference the function */}
          
            {post.pictures && post.pictures[0] && (
                <div className='flex-row'>
                    <img
                        alt='Post'
                        className='w-full h-auto rounded-[16px] object-scale-down'
                        style={{ maxHeight: '300px',height: '250px',objectFit: 'cover' }}
                        src={post.pictures[0]}
                    />
                </div>
            )}
			<br></br>
			<h2 className="post-title">{post.title}</h2>
            <p className="post-content">{post.content}</p>
            <p className="post-author">Author: {post.author}</p>
        </div>
    );
};

export default Post;


