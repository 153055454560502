import { Button } from 'antd'

const StepsFooter = ({ pages, page, buttonfuncs, promiseLoading, disableFunction, termsAndConditions, dispatchLoading }) => {
	return (
		<div className='flex w-full flex-row items-center justify-center' key='footer'>
			{pages?.[page]?.buttons
				?.filter((button) => !(button?.type === 'next' && !pages?.[page + 1]))
				?.map((button, index) => {
					return (
						<Button
							id={button?.type}
							disabled={
								disableFunction && button?.type === 'dispatch'
									? disableFunction?.({ termsAndConditions })
									: ['cancel', 'back']?.includes(button?.type) && (promiseLoading || dispatchLoading)
									? true
									: false
							}
							className={`${button?.className} ml-3 !h-12 max-xs:!h-11 max-xs:!w-32 xs:max-sm:!w-36 xs:!w-44`}
							style={{ height: 40 }}
							onClick={buttonfuncs[button?.type]}
							loading={!['cancel', 'back']?.includes(button?.type) && (promiseLoading || dispatchLoading)}
							key={'button-' + index}
						>
							{button?.title}
						</Button>
					)
				})}
		</div>
	)
}
export default StepsFooter
