import { Footer, Form } from '@components'
import PrimaryHeader from '../../components/headers/primaryHeader'
import { Button, Input, notification } from 'antd'
import { Outlet, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { mdiContentCopy } from '@mdi/js'
import Icon from '@mdi/react'
import { endpoints } from '../../helpers/enums'

const GenerateCoupon = () => {
	const navigate = useNavigate()
	const [form] = Form.useForm()
	const formWatch = Form.useWatch(undefined, form)
	const [coupon, setCoupon] = useState(null)
	const [buttonLoading, setButtonLoading] = useState(false)
	const copyLink = () => {
		navigator.clipboard.writeText(coupon)
		notification.success({ message: 'Copied to clipboard!' })
	}

	useEffect(() => {
		if (!form.getFieldValue(['limitedUserCoupon'])) {
			form.setFieldsValue({ couponUserCount: '' })
		}
	}, [formWatch])

	const fetchCoupon = () => {
		form
			.validateFields()
			.catch((e) => console.log(e))
			.then((values) => {
				setButtonLoading(true)
				fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}${endpoints['create-coupon']}`, {
					method: 'POST',
					headers: { 'Content-Type': 'application/json;charset=utf-8' },
					body: JSON.stringify({ ...values, discountPercentage: parseInt(values.discountPercentage), expiryInDays: parseInt(values.expiryInDays) }),
				})
					.catch((e) => ({ type: 'error' }))
					.then((response) => {
						if (response.type !== 'error') {
							response.json().then((data) => {
								notification.success({
									message: 'Coupon generation Successful',
									description: `Coupon # ${data.couponCode} generated sucessfully.`,
								})
								setCoupon(data.couponCode)
								setButtonLoading(false)
							})
						} else {
							notification.error({ message: 'Error', description: 'Unable to generate a coupon at the moment. Please try later.' })
							setButtonLoading(false)
						}
					})
			})
	}

	return (
		<>
			<div className=''>
				<PrimaryHeader />
				<Form form={form}>
					<div className='min-h-[70vh] lg:mx-64 sm:mx-16 max-md:mx-4 sm:mb-16 sm:mt-28 max-md:mb-4 max-md:mt-12 py-8 md:px-8 max-md:px-4 h-fit self-center bg-[#F9FAFB] rounded-lg flex flex-col'>
						<p className='text-[#101828] sm:text-[30px] max-md:text-[24px] font-[600] sm:pb-8 max-md:pb-4'>Coupon</p>
						<div className='w-full flex flex-col items-start mb-4 border-b border-solid border-[#EAECF0]'>
							<p className='flex  text-[#344054] pb-2 text-sm font-[600]'>Coupon Name</p>
							{Form.renderSchema([
								{
									type: 'input',
									itemClassName: 'w-3/4',
									name: ['couponName'],
									required: true,
									message: 'Please enter coupon name',
									placeholder: 'Enter Coupon Name',
								},
							])}
						</div>
						<div className='w-full flex flex-col items-start mb-4 border-b border-solid border-[#EAECF0]'>
							<p className='flex  text-[#344054] pb-2 text-sm font-[600]'>Percentage</p>
							{Form.renderSchema([
								{
									type: 'input',
									itemClassName: 'w-3/4',
									name: ['discountPercentage'],
									required: true,
									message: 'Please enter percentage',
									placeholder: 'Enter Percentage',
								},
							])}
						</div>
						<div className='w-full flex flex-col items-start mb-4 border-b border-solid border-[#EAECF0]'>
							<p className='flex  text-[#344054] pb-2 text-sm font-[600]'>Coupon Expiration Days</p>
							{Form.renderSchema([
								{
									type: 'input',
									itemClassName: 'w-3/4',
									name: ['expiryInDays'],
									required: true,
									message: 'Please enter coupon expiration days',
									placeholder: 'Enter Coupon Expiration Days',
								},
							])}
						</div>
						<div className='w-full flex flex-col items-start mb-4 border-b border-solid border-[#EAECF0]'>
							<div className=' w-full flex  mb-4 items-center'>
								<p className='flex  text-[#344054] pb-2 text-sm font-[600] w-3/4'>Add Number of Users</p>
								{Form.renderSchema([
									{
										type: 'checkbox',
										name: ['limitedUserCoupon'],
										initialValue: false,
									},
								])}
							</div>

							<div className='flex flex-row'></div>
							{Form.renderSchema([
								{
									type: 'input',
									inputType: 'number',
									itemClassName: 'w-3/4',
									name: ['couponUserCount'],
									// required: true,
									message: 'Please enter number of users',
									placeholder: 'Enter Number of Users',
									disabled: !!!form.getFieldValue(['limitedUserCoupon']),
								},
							])}
						</div>
						{coupon && (
							<div className='w-full flex mb-4 items-center'>
								<Input className='h-12 w-3/4 rounded' placeholder={coupon} disabled />
								<Button className='ml-4 flex items-center p-0 text-primary-800' type='link' onClick={copyLink}>
									<Icon size={0.8} color='#664F94' className='mr-1' path={mdiContentCopy} />
									<p className='font-bold text-[#664F94] max-md:text-xs'>COPY LINK</p>
								</Button>
							</div>
						)}
						<div className={`w-full flex flex-row justify-start items-start  max-md:px-4`}>
							<Button
								className='btn-secondary !h-10 mr-6'
								onClick={() => {
									navigate('/home/about')
								}}
							>
								Go Back
							</Button>

							<Button className='btn-primary !h-10 text-lg' loading={buttonLoading} onClick={fetchCoupon}>
								Generate Coupon
							</Button>
						</div>
					</div>
				</Form>
				<Footer />
			</div>
			<Outlet />
		</>
	)
}

export default GenerateCoupon
