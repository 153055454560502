import { atom } from 'recoil'
export const userAtom = atom({ default: null, key: 'userAtom' })
export const listingsAtom = atom({ default: [], key: 'listingsAtom' })
export const chatsAtom = atom({ default: [], key: 'chatsAtom' })
export const requestsAtom = atom({
	default: {},
	key: 'requestsAtom',
})
export const chatCountAtom = atom({
	default: 0,
	key: 'chatCountAtom',
})
export const usersAtom = atom({ default: [], key: 'usersAtom' })
export const countriesAtom = atom({ default: [], key: 'countriesAtom' })
export const citiesAtom = atom({ default: [], key: 'citiesAtom' })
