const CountryEnum = {
	Afghanistan: {
		country: 'Afghanistan',
		cities: [
			'Yaḩyá Khēl',
			'Pul-e Khumrī',
			'Maymana',
			'Khwājah Dū Kōh',
			'Khāsh',
			'Gardez',
			'Tsowkêy',
			'Chinār',
			'Chahār Burj',
			'Bāmyān',
			'Khadīr',
			'Maydanshakhr',
			'Taywarah',
			'Tarinkot',
			'Qaīşār',
			'Mīrābād',
			'Dwah Manḏay',
			'Bāzār-e Tālah',
			'Ḩukūmatī Azrah',
			'Shērwānī-ye Bālā',
			'Karukh',
			'Chakaray',
			'Sharan',
			'‘Alāqahdārī Shāh Jōy',
			'Sarōbī',
			'Rūdbār',
			'Qarāwul',
			'Qarah Bāgh',
			'Mīr Bachah Kōṯ',
			'Mardīān',
			'Mandōl',
			'Lashkar Gāh',
			'Khūgyāṉī',
			'Khōst',
			'Kabul',
			'Jabal os Saraj',
			'Fayẕābād',
			'Dasht-e Qal‘ah',
			'Dāngām',
			'Qaranghū Tōghaī',
			'Chowṉêy',
			'Bābā Şāḩib',
			'March',
			'Salām Khēl',
			'Zīārat-e Shāh Maqşūd',
			'Zaṟah Sharan',
			'Zaranj',
			'Sāyagaz',
			'Sar Kāṉī',
			'Sarfirāz Kalā',
			'Urgun',
			'Ḩukūmat-e Nād ‘Alī',
			'Kushk',
			'Kōṯowāl',
			'Khamyāb',
			'Kanḏay',
			'Jalrēz',
			'Jalālābād',
			'Deh-e Now',
			'Barg-e Matāl',
			'Amānzī',
			'‘Alāqahdārī-ye Almār',
			'Surkh Bilandī',
			'Bāzārak',
			'Pas Pul',
			'Khulm',
			'Khandūd',
			'Khānaqāh',
			'Kalān Deh',
			'Fayzabad',
			'Tsapêraī',
			'Alah Sāy',
			'Karbōṟī',
			'Zīrakī',
			'Tōrmay',
			'Qāshqāl',
			'Sang Atesh',
			'Rū-ye Sang',
			'Rāmak',
			'Quchanghī',
			'Ōbêh',
			'Māmā Khēl',
			'Larkird',
			'Khōshāmand',
			'Chahār Bāgh',
			'Ghazni',
			'Dūāb',
			'Bagrāmī',
			'Āfāqī',
			'Zurmat',
			'Yangī Qal‘ah',
			'Markaz-e Sayyidābād',
			'Sang-e Chārak',
			'Qurghān',
			'Pul-e ‘Alam',
			'Deh Khwāhān',
			'Kishk-e Nakhūd',
			'Jānī Khēl',
			'Darqad',
			'Dowr-e Rabāţ',
			'Anār Darah',
			'Āb-e Kamarī',
			'Qaram Qōl',
			'Wulêswālī Bihsūd',
			'Siyāhgird',
			'Khōshī',
			'Imām Şāḩib',
			'Būrkah',
			'‘Alāqahdārī Aṯghar',
			'Ashkāsham',
			'Pachīr wa Āgām',
			'Kuhsān',
			'Zaybāk',
			'Wāshēr',
			'Şayād',
			'Sar-e Tayghān',
			'Qal‘ah-ye Kūf',
			'Pasāband',
			'Kalafgān',
			'Jurm',
			'Gereshk',
			'Banū',
			'’Unābah',
			'Ibrāhīm Khān',
			'Khayr Kōṯ',
			'Dahan-e Jarf',
			'Bati',
			'Āq Kupruk',
			'Bāzār-e Yakāwlang',
			'Tīr Pul',
			'Bāzār-e Tashkān',
			'Taloqan',
			'Sangīn',
			'Qarqīn',
			'Panjāb',
			'Ghurayd Gharamē',
			'Tsamkanī',
			'Adraskan',
			'Bāgh-e Maīdān',
			'Kirāmān',
			'Markaz-e Ḩukūmat-e Sulţān-e Bakwāh',
			'Qarah Bāgh',
			'Qādis',
			'Kajrān',
			'Ḩāfiz̧ Moghul',
			'Qal‘ah-ye Fārsī',
			'Darzāb',
			'Ḏanḏar',
			'Āqchah',
			'‘Alī Shēr ‘Alāqahdārī',
			'Kushkak',
			'Spērah',
			'Qala i Naw',
			'Pasnay',
			'Uruzgān',
			'Nayak',
			'Mīzān ‘Alāqahdārī',
			'Dū Qal‘ah',
			'Ḩukūmatī Dahanah-ye Ghōrī',
			'Chīras',
			'Qal‘ah-ye Na‘īm',
			'Zindah Jān',
			'Lāsh-e Juwayn',
			'Herāt',
			'Ghōriyān',
			'Dōshī',
			'Dowlatyār',
			'Dowlat Shāh',
			'‘Alāqahdārī Yōsuf Khēl',
			'Pul-e Sangī',
			'Māṉōgay',
			'Shibirghān',
			'Rabāţ-e Sangī-ye Pā’īn',
			'Qalāt',
			'Bala Murghab',
			'Mīrān',
			'Khānaqāh',
			'Khākirān',
			'Gōmal Kêlay',
			'Bulōlah',
			'Wuṯahpūr',
			'Shīnḏanḏ',
			'Shaykh Amīr Kêlay',
			'Sidqābād',
			'Sar-e Pul',
			'Rustāq',
			'Narang',
			'Māymay',
			'Kaz̲h̲ah',
			'Istālif',
			'Dasht-e Archī',
			'Darāyim',
			'Charkh',
			'Wuluswālī ‘Alīngār',
			'Markaz-e Woluswalī-ye Āchīn',
			'Spīn Bōldak',
			'Maīdān Khūlah',
			'Mashhad',
			'La‘l',
			'Khudāydād Khēl',
			'Ḩukūmat-e Shīnkaī',
			'Guz̄arah',
			'Markaz-e Ḩukūmat-e Darwēshān',
			'Bahārak',
			'Zargarān',
			'Nīlī',
			'Now Dahānak',
			'Muqêr',
			'Kuran wa Munjan',
			'Charikar',
			'Ārt Khwājah',
			'‘Alī Khēl',
			'Ţāqchah Khānah',
			'Lāsh',
			'Ōmnah',
			'Khulbisāt',
			'Khinj',
			'Khān Neshīn',
			'Jawand',
			'Ḩājī Khēl',
			'Chāh Āb',
			'Baraki Barak',
			'Dū Laīnah',
			'Lab-Sar',
			'Zamtō Kêlay',
			'Tītān',
			'Sōzmah Qal‘ah',
			'Qal‘ah-ye Kuhnah',
			'Nūrgal',
			'Nīkêh',
			'Nahrīn',
			'Muḩammad Āghah Wuluswālī',
			'Kandahār',
			'‘Alāqahdārī Gēlān',
			'Dowlatābād',
			'Dê Nārkhēl Kêlay',
			'Chahār Qal‘ah',
			'Qarah Bāgh Bāzār',
			'Zīṟūk ‘Alāqahdārī',
			'Khwājah Ghār',
			'Ḩāfiz̧ān',
			'Farah',
			'Injīl',
			'Dowlatābād',
			'Chisht-e Sharīf',
			'Chākarān',
			'Baghlān',
			'Asadābād',
			'Mīray',
			'Shahrān',
			'Sangar Sarāy',
			'Qal‘ah-ye Shāhī',
			'Nūsay',
			'Nāyak',
			'Nāṟay',
			'Kunduz',
			'Ghormach',
			'Farkhār',
			'Muhmand Dara',
			'Tagāw-Bāy',
			'Shwāk',
			'Shahrak',
			'Paghmān',
			'Now Zād',
			'Mazār-e Sharīf',
			'Mutā Khān',
			'Kalakān',
			'Chimtāl',
			'Fayrōz Kōh',
			'Langar',
			'Tūlak',
			'Tagāb',
			'Aībak',
			'Khinjān',
			'Kā’ī',
			'Gōshtah',
			'Faīẕābād',
			'Dehī',
			'‘Alāqahdārī Dīshū',
			'Ghulām ‘Alī',
			'‘Alīābād',
			'Stêr Giyān',
			'Qarghah’ī',
			'Qarchī Gak',
			'Pārūn',
			'Pāshmūl',
			'Ōkak',
			'Mūsá Qal‘ah',
			'Deh-e Şalāḩ',
			'Dehdādī',
			'Zōr Kōṯ',
			'S̲h̲ēwah',
			'Wulêswālī Sayyid Karam',
			'Sar Chakān',
			'Sang-e Māshah',
			'Qal‘ah-ye Shahr',
			'Mingajik',
			'Mehtar Lām',
			'Eslam Qaleh',
			'Āsmār',
			'Andkhōy',
			'Chandal Bā’ī',
			'Pul-e Ḩişār',
			'Zarghūn Shahr',
			'Shahr-e Şafā',
			'Kushk-e Kuhnah',
			'Khanabad',
			'Dasht-e Qal‘ah',
			'Bal Chirāgh',
			'Balkh',
			'‘Alāqahdārī Saṟōbī',
			'Sulţānpūr-e ‘Ulyā',
		],
	},
	Albania: {
		country: 'Albania',
		cities: [
			'Sllovë',
			'Përmet',
			'Kukës',
			'Gjegjan',
			'Fushë-Muhurr',
			'Fierzë',
			'Gjerbës',
			'Velipojë',
			'Qerret',
			'Kavajë',
			'Grekan',
			'Gegë',
			'Fier-Shegan',
			'Allkaj',
			'Fierzë',
			'Vergo',
			'Mborje',
			'Leskovik',
			'Shezë',
			'Rrapë',
			'Poshnje',
			'Libofshë',
			'Karbunara e Vogël',
			'Hot',
			'Hekal',
			'Drenovë',
			'Dërmënas',
			'Cërrik',
			'Golem',
			'Markat',
			'Tomin',
			'Steblevë',
			'Përrenjas-Fshat',
			'Proptisht',
			'Këlcyrë',
			'Kastriot',
			'Kokaj',
			'Dardhas',
			'Vranisht',
			'Selitë',
			'Luftinjë',
			'Kuman',
			'Krujë',
			'Kolonjë',
			'Divjakë',
			'Dajç',
			'Bajram Curri',
			'Topojan',
			'Poroçan',
			'Hotolisht',
			'Bushtricë',
			'Thumanë',
			'Lushnjë',
			'Lapardha Një',
			'Kutalli',
			'Gradishtë',
			'Vau i Dejës',
			'Ballagat',
			'Dushk',
			'Sult',
			'Polis-Gostimë',
			'Funarë',
			'Zharrëz',
			'Nicaj-Shalë',
			'Rrogozhinë',
			'Kastrat',
			'Konispol',
			'Zavalinë',
			'Vithkuq',
			'Orosh',
			'Maqellarë',
			'Gjinar',
			'Lekbibaj',
			'Kashar',
			'Kalenjë',
			'Hysgjokaj',
			'Dajt',
			'Korçë',
			'Golaj',
			'Bulqizë',
			'Barmash',
			'Xhafzotaj',
			'Rrashbull',
			'Petrelë',
			'Manzë',
			'Gruemirë',
			'Gracen',
			'Golem',
			'Zerqan',
			'Shushicë',
			'Qestorat',
			'Picar',
			'Klos',
			'Uznovë',
			'Koplik',
			'Kamëz',
			'Goraj',
			'Surroj',
			'Qukës-Skënderbe',
			'Petran',
			'Gostimë',
			'Fushë-Arrëz',
			'Cerava',
			'Arrën',
			'Starovë',
			'Roskovec',
			'Progonat',
			'Bërdica e Madhe',
			'Shëngjin',
			'Poliçan',
			'Maliq',
			'Llugaj',
			'Lis',
			'Libonik',
			'Leshnjë',
			'Kthella e Epërme',
			'Gjinaj',
			'Ersekë',
			'Çarshovë',
			'Blinisht',
			'Lukovë',
			'Vaqarr',
			'Patos Fshat',
			'Lezhë',
			'Livadhja',
			'Shënmëri',
			'Odrie',
			'Novoselë',
			'Lenias',
			'Fushë-Lurë',
			'Derjan',
			'Burrel',
			'Synej',
			'Strum',
			'Shkodër',
			'Prezë',
			'Përparim',
			'Orikum',
			'Krrabë',
			'Himarë',
			'Guri i Zi',
			'Dajç',
			'Bubq',
			'Berat',
			'Baldushk',
			'Zall-Reç',
			'Voskopojë',
			'Vërtop',
			'Ujmisht',
			'Trebinjë',
			'Sukë',
			'Shirgjan',
			'Rukaj',
			'Potom',
			'Mollas',
			'Labinot-Mal',
			'Labinot-Fushë',
			'Kushovë',
			'Kolsh',
			'Ballaban',
			'Rrëshen',
			'Pukë',
			'Pajovë',
			'Memaliaj',
			'Kajan',
			'Aliko',
			'Zapod',
			'Vreshtas',
			'Stravaj',
			'Saraqinishtë',
			'Pishaj',
			'Peshkopi',
			'Mollas',
			'Melan',
			'Frashër',
			'Moglicë',
			'Zejmen',
			'Shijak',
			'Patos',
			'Luzi i Vogël',
			'Gosë e Madhe',
			'Gjoçaj',
			'Durrës',
			'Belsh',
			'Ballsh',
			'Rajcë',
			'Lekas',
			'Fushë-Bulqizë',
			'Bicaj',
			'Sukth',
			'Selenicë',
			'Novoselë',
			'Maminas',
			'Katundi i Ri',
			'Hajmel',
			'Cudhi Zall',
			'Selitë',
			'Macukull',
			'Zall-Herr',
			'Shalës',
			'Kuç',
			'Fushë-Krujë',
			'Fratar',
			'Brataj',
			'Bërzhitë',
			'Finiq',
			'Qereshnik',
			'Pirg',
			'Orenjë',
			'Lunik',
			'Librazhd',
			'Kodovjat',
			'Vlorë',
			'Shëngjin',
			'Peza e Madhe',
			'Dhërmi',
			'Allambres',
			'Lapardha Dy',
			'Progër',
			'Pojan',
			'Kurbnesh',
			'Ksamil',
			'Samaticë',
			'Portëz',
			'Mbrostar-Urë',
			'Kolç',
			'Bërxull',
			'Delvinë',
			'Drenovë',
			'Arras',
			'Rubik',
			'Paskuqan',
			'Moravë',
			'Mamurras',
			'Levan',
			'Kotë',
			'Hasan',
			'Cukalat',
			'Bushat',
			'Armen',
			'Xarrë',
			'Sarandë',
			'Xibër-Murrizë',
			'Skënderbegas',
			'Selishtë',
			'Martanesh',
			'Liqenas',
			'Klos',
			'Çepan',
			'Bytyç',
			'Bogovë',
			'Ulëz',
			'Sevaster',
			'Frakulla e Madhe',
			'Aranitas',
			'Kakavijë',
			'Tunjë',
			'Gjorica e Sipërme',
			'Elbasan',
			'Ura Vajgurore',
			'Ungrej',
			'Nikël',
			'Krahës',
			'Fier-Çifçi',
			'Fier',
			'Vendresha e Vogël',
			'Suç',
			'Qafëmal',
			'Përrenjas',
			'Mollaj',
			'Kukur',
			'Gramsh',
			'Gjirokastër',
			'Buz',
			'Tirana',
			'Valbonë',
			'Rrasa e Sipërme',
			'Kuçovë',
			'Kombësi',
			'Farka e Madhe',
			'Duhanas',
			'Mesopotam',
			'Pogradec',
			'Fajzë',
			'Blerim',
			'Remas',
			'Otllak',
			'Magjatë',
			'Lekaj',
			'Kutë',
			'Krutja e Poshtme',
			'Kallmet',
			'Vllahinë',
			'Dhivër',
			'Zall-Dardhë',
			'Vithkuq',
			'Kardhiq',
			'Bradashesh',
			'Vorë',
			'Pobrat',
			'Peqin',
			'Milot',
			'Kryevidh',
			'Kozarë',
			'Gurra e Vogël',
			'Gjepalaj',
			'Dushnik',
			'Grudë-Fushë',
			'Buçimas',
			'Tepelenë',
			'Skore',
			'Shishtavec',
			'Bujan',
			'Topojë',
			'Tapi',
			'Laç',
			'Karinë',
			'Kaçinar',
			'Udënisht',
			'Tregan',
			'Trebisht-Muçinë',
			'Gjinkar',
			'Ostreni i Math',
			'Libohovë',
			'Lazarat',
			'Çorovodë',
			'Çlirim',
			'Velabisht',
			'Tërbuf',
			'Ruzhdie',
			'Kurjan',
			'Grabjan',
			'Çlirim',
			'Drenovicë',
			'Cakran',
			'Shtiqën',
			'Miras',
			'Krumë',
			'Kalis',
			'Hoçisht',
			'Librazhd-Qendër',
			'Piskovë',
			'Zall-Bastar',
			'Shëngjergj',
			'Qelëz',
			'Ngraçan',
			'Ndroq',
			'Kallmeti i Madh',
			'Helmas',
			'Havaleas',
			'Fushëkuqe',
			'Baz',
			'Kodër-Vorë',
			'Voskop',
			'Velçan',
			'Shupenzë',
			'Vukatanë',
			'Papër',
			'Nicaj-Shosh',
			'Balldreni i Ri',
			'Iballë',
			'Gjergjan',
			'Fushë-Çidhnë',
			'Dishnicë',
			'Bilisht',
			'Sinaballaj',
			'Shënkoll',
			'Perondi',
			'Ishëm',
			'Greshicë',
			'Bubullimë',
		],
	},
	Algeria: {
		country: 'Algeria',
		cities: [
			'Reggane',
			'Oued el Alleug',
			'Messaad',
			'Charef',
			'Boumagueur',
			'Boghni',
			'Biskra',
			'Aïn Smara',
			'Tizi Ouzou',
			'Sidi Abdelli',
			'El Idrissia',
			'Drean',
			'Chorfa',
			'Boû Arfa',
			'Beni Douala',
			'Akbou',
			'Tizi-n-Tleta',
			'el hed',
			'Mostaganem',
			'Melouza',
			'Makouda',
			'Boudouaou',
			'Birkhadem',
			'Algiers',
			'Aïn Sefra',
			'Adrar',
			'BABOR - VILLE',
			'Sig',
			'Sedrata',
			'Seddouk',
			'Relizane',
			'Dar el Beïda',
			'Cheria',
			'Bougaa',
			'Bensekrane',
			'Barbacha',
			'’Aïn el Melh',
			'’Aïn el Hammam',
			'Djebilet Rosfa',
			'Ouargla',
			'Oran',
			'Ighram',
			'Boufarik',
			'Birine',
			'Aïn Bessem',
			'Aïn Arnat',
			'Boumerdas',
			'Mouzaïa',
			'Megarine',
			'Médéa',
			'Ksar el Boukhari',
			'Khemis Miliana',
			'Hadjout',
			'El Kseur',
			'Chetouane',
			'‘Aïn el Hadjel',
			'Metlili Chaamba',
			'Mers el Kebir',
			'Meftah',
			'Berrouaghia',
			'’Aïn Boucif',
			'’Aïn Abid',
			'Sidi Senoussi سيدي سنوسي',
			'Salah Bey',
			'Remchi',
			'Lakhdaria',
			'Beni Mester',
			'Batna',
			'’Aïn el Hadjar',
			'Aïn Beïda',
			'Zemoura',
			'Tébessa',
			'Khenchela',
			'Héliopolis',
			'Guelma',
			'Draa el Mizan',
			'Douera',
			'Chelghoum el Aïd',
			'Bouinan',
			'Bir el Ater',
			'Arhribs',
			'Annaba',
			'’Aïn Merane',
			'Tindouf',
			'Mehdia daira de meghila',
			'Larbaâ',
			'Hassi Messaoud',
			'El Oued',
			'El Khroub',
			'El Attaf',
			'El Aouinet',
			'Dellys',
			'Smala',
			'Tadmaït',
			'Souma',
			'Robbah',
			'Nedroma',
			'Mekla',
			'Ghardaïa',
			'Bir el Djir',
			'Arbatache',
			'Aïn Touta',
			'Lardjem',
			'Sougueur',
			'Sidi ech Chahmi',
			'Sétif',
			'Naama',
			'Laghouat',
			'El Tarf',
			'El Affroun',
			'Bordj Ghdir',
			'Abou el Hassan',
			'Ait Yahia',
			'Tipaza',
			'Oued Sly',
			'Hammamet',
			'El Meghaier',
			'El Eulma',
			'El Abiodh Sidi Cheikh',
			'Ech Chettia',
			'Bougara',
			'Aïn Temouchent',
			'Aïn Kercha',
			'Timizart',
			'Oued el Abtal',
			'Mazouna',
			'Mascara',
			'El Kala',
			'Boudjima',
			'Berrahal',
			'Barika',
			'Azzaba',
			'Mansourah',
			'El Amria',
			'Dar Chioukh',
			'Chiffa',
			'Bouïra',
			'Bou Hanifia el Hamamat',
			'Ben Mehidi',
			'Aoulef',
			'’Aïn el Berd',
			'Zeribet el Oued',
			'Tissemsilt',
			'Sidi Mérouane',
			'Rouiba',
			'Reghaïa',
			'Merouana',
			'Draa Klalouche',
			'Frenda',
			'El Achir',
			'Tazoult-Lambese',
			'Skikda',
			'Sidi Akkacha',
			'Oumache',
			'Ouled Moussa',
			'L’Arbaa Naït Irathen',
			'El Malah',
			'Chlef',
			'Chebli',
			'Boukadir',
			'’Aïn el Turk',
			'Saoula',
			'M’sila',
			'Ksar Chellala',
			'Hammam Bou Hadjar',
			'El Hadjar',
			'Béni Abbès',
			'Azazga',
			'Tamanrasset',
			'Sidi Khaled',
			'Ouled Mimoun',
			'Oued Rhiou',
			'Mansoûra',
			'Feraoun',
			'Aïn Oussera',
			'’Aïn Deheb',
			'Sour el Ghozlane',
			'Souk Ahras',
			'Sidi Okba',
			'Sidi Moussa',
			'Sebdou',
			'Naciria',
			'Hennaya',
			'Arris',
			'Aïn Defla',
			'In Salah',
			'Rouached',
			'Meskiana',
			'Constantine',
			'Blida',
			'Berriane',
			'Bejaïa',
			'Tizi Gheniff',
			'Sidi Amrane',
			'Sidi Aïssa',
			'Oued Fodda',
			'Djelfa',
			'Didouche Mourad',
			'Besbes',
			'Beni Saf',
			'Baraki',
			'Tolga',
			'Timimoun',
			'Tebesbest',
			'Saïda',
			'Jijel',
			'Bordj el Kiffan',
			'Bab Ezzouar',
			'Zeralda',
			'Touggourt',
			'Tiaret',
			'Thenia',
			'Telerghma',
			'Tamalous',
			'Sidi Bel Abbès',
			'Rouissat',
			'Kolea',
			'Kerkera',
			'Djidiouia',
			'Es Senia',
			'El Bayadh',
			'Debila',
			'Cheraga',
			'Bou Ismaïl',
			'Beni Mered',
			'Ammi Moussa',
			'Tlemcen',
			'Tirmitine',
			'Freha',
			'Draa Ben Khedda',
			'Bou Tlelis',
			'Boumahra Ahmed',
			'’Aïn el Bell',
			'’Aïn Benian',
			'Aflou',
			'District of Taher',
			'Tizi Rached',
			'Reguiba',
			'Râs el Oued',
			'Râs el Aïoun',
			'Ouled Djellal',
			'Mila',
			'El Abadia',
			'Chemini',
			'Brezina',
			'Bordj Zemoura',
			'Beni Amrane',
			'Amizour',
			'Aïn el Bya',
			'Taïbet',
			'Oum el Bouaghi',
			'In Guezzam',
			'Hamma Bouziane',
			'El Hadjira',
			'Chabet el Ameur',
			'Béchar',
			'Abadla',
			'Theniet el Had',
			'Sfizef',
			'Khemis el Khechna',
			'Illizi',
			'El Meniaa',
			'Djanet',
			'Djamaa',
			'Bordj Bou Arréridj',
			'Aïn Taya',
			'Aïn Fakroun',
		],
	},
	'American Samoa': {
		country: 'American Samoa',
		cities: [
			'Malaeimi',
			'Vaitogi',
			'Faleniu',
			'Leone',
			'Vailoatai',
			'Taulaga',
			'Ofu',
			'Fagatogo',
			'Mapusagafou',
			'Pago Pago',
			'Tāfuna',
			'Ta`ū',
			'Aūa',
			'Faleāsao',
		],
	},
	Andorra: {
		country: 'Andorra',
		cities: [
			'Sant Julià de Lòria',
			'Encamp',
			'la Massana',
			'El Tarter',
			'Pas de la Casa',
			'Andorra la Vella',
			'Ordino',
			'les Escaldes',
			'Canillo',
			'Arinsal',
		],
	},
	Angola: {
		country: 'Angola',
		cities: [
			'Sumbe',
			'Lobito',
			'Cuito',
			'Léua',
			'Mbanza Kongo',
			'Moçâmedes',
			'Lumeji',
			'Huambo',
			'Luena',
			'Catumbela',
			"N'zeto",
			'Menongue',
			'Benguela',
			'Saurimo',
			'Catabola',
			'Dundo',
			'Caconda',
			'N’dalatando',
			'Luanda',
			'Cazaji',
			'Quibala',
			'Ondjiva',
			'Uacu Cungo',
			'Lubango',
			'Chela',
			'Lucapa',
			'Luau',
			'Cabinda',
			'Chissamba',
			'Caxito',
			'Caála',
			'Chinjenje',
			'Uíge',
			'Soio',
			'Longonjo',
			'Camacupa',
			'Malanje',
			'Camabatela',
		],
	},
	Anguilla: {
		country: 'Anguilla',
		cities: [
			'Stoney Ground',
			'North Hill Village',
			'Farrington',
			'Island Harbour',
			'The Valley',
			'Sandy Ground Village',
			'The Quarter',
			'Sandy Hill',
			'East End Village',
			'Blowing Point Village',
			'George Hill',
			'West End Village',
			'North Side',
			'South Hill Village',
		],
	},
	'Antigua and Barbuda': {
		country: 'Antigua and Barbuda',
		cities: ['All Saints', 'Falmouth', 'Potters Village', 'Parham', 'Liberta', 'Codrington', 'Saint John’s', 'Piggotts', 'Bolands'],
	},
	Argentina: {
		country: 'Argentina',
		cities: [
			'Urdinarrain',
			'Santo Pipó',
			'Riacho Eh-Eh',
			'Puerto Ibicuy',
			'Monte Caseros',
			'Mariano I. Loza',
			'Las Garcitas',
			'La Escondida',
			'General Belgrano',
			'Garuhapé',
			'Vista Alegre',
			'Villa Berthet',
			'Tinogasta',
			'Tama',
			'Santa Rosa',
			'San Francisco del Chañar',
			'Salliqueló',
			'Rawson',
			'Pozo del Tigre',
			'Milagro',
			'Malargüe',
			'Los Cocos',
			'La Playosa',
			'Laguna Larga',
			'La Cesira',
			'Ingeniero Jacobacci',
			'Idiazábal',
			'Famatina',
			'Dolavón',
			'Bernasconi',
			'Alta Italia',
			'Alberti',
			'Villa Lugano',
			'Villa del Rosario',
			'San Justo',
			'San Javier',
			'La Leonesa',
			'Itatí',
			'Garruchos',
			'El Colorado',
			'Concepción',
			'Bella Vista',
			'Viale',
			'Tío Pujio',
			'Selva',
			'Seguí',
			'Santa Victoria',
			'Rosario de Lerma',
			'Rodeo',
			'Mattaldi',
			'Las Perdices',
			'Ingeniero Luis A. Huergo',
			'Ingeniero Guillermo N. Juárez',
			'Guatimozín',
			'Frías',
			'Daireaux',
			'Colonia Dora',
			'Chivilcoy',
			'Chimbas',
			'Chilecito',
			'Camilo Aldao',
			'Bella Vista',
			'Arata',
			'Achiras',
			'San Justo',
			'Reconquista',
			'Piedras Blancas',
			'Navarro',
			'Miramar',
			'Itá Ibaté',
			'General Vedia',
			'Fontana',
			'Carmen de Areco',
			'Berisso',
			'Villa de María',
			'Villa Aberastain',
			'Tres Lomas',
			'Trelew',
			'Totoras',
			'Sunchales',
			'San Lorenzo',
			'San Blas de los Sauces',
			'San Benito',
			'Saldán',
			'Rivadavia',
			'Ramallo',
			'Oro Verde',
			'Miguel Riglos',
			'Metán',
			'Lincoln',
			'La Toma',
			'Las Breñas',
			'Junín',
			'Granadero Baigorria',
			'General La Madrid',
			'Chazón',
			'Campo Gallo',
			'Arraga',
			'Santa María',
			'Puerto Eldorado',
			'Paso de los Libres',
			'Nueve de Julio',
			'Margarita Belén',
			'Ituzaingó',
			'Formosa',
			'Curuzú Cuatiá',
			'Cruz de los Milagros',
			'Cerro Corá',
			'Victorica',
			'Tafí del Valle',
			'Sebastián Elcano',
			'San Carlos',
			'Río Pico',
			'Rinconada',
			'Recreo',
			'Realicó',
			'Puerto Madryn',
			'Puelches',
			'Porteña',
			'Guaminí',
			'Gálvez',
			'El Arañado',
			'Cipolletti',
			'Cinco Saltos',
			'Casilda',
			'Buchardo',
			'Alejandro Roca',
			'Alderetes',
			'Wanda',
			'Villa Elisa',
			'Tortuguitas',
			'San Pedro',
			'San Miguel del Monte',
			'San Ignacio',
			'San Cayetano',
			'Puerto Tirol',
			'Nuestra Señora del Rosario de Caa Catí',
			'Magdalena',
			'General Conesa',
			'Villa General Mitre',
			'Valle Grande',
			'Ucacha',
			'Tilisarao',
			'San Isidro',
			'Río Primero',
			'Monte Buey',
			'Los Telares',
			'Leones',
			'Las Varas',
			'Las Higueras',
			'La Merced',
			'Hernández',
			'General Conesa',
			'General Cabrera',
			'El Trébol',
			'Cuchillo Có',
			'Cruz del Eje',
			'Coronel Pringles',
			'Contraalmirante Cordero',
			'Cafayate',
			'Cachí',
			'Ingeniero Pablo Nogués',
			'Zárate',
			'Ubajay',
			'San Gustavo',
			'Puerto Rico',
			'Pampa Almirón',
			'Maipú',
			'Larroque',
			'Laguna Naick-Neck',
			'Gobernador Virasora',
			'Caraguatay',
			'Bonpland',
			'Zapala',
			'Winifreda',
			'Vinchina',
			'Villa María',
			'Villa Carlos Paz',
			'Viamonte',
			'Unquillo',
			'Tupungato',
			'Tres Algarrobos',
			'Tecka',
			'Sierra Grande',
			'Santa Sylvina',
			'Santa Fe',
			'Santa Eufemia',
			'San Jorge',
			'Roldán',
			'Río Grande',
			'Río Colorado',
			'Plottier',
			'Mutquín',
			'Las Heras',
			'Las Coloradas',
			'Las Acequias',
			'Laborde',
			'Isla Verde',
			'Coronel Du Graty',
			'Concepción del Bermejo',
			'Chimpay',
			'Añelo',
			'Villa Mercedes',
			'Río Turbio',
			'Tolhuin',
			'Tigre',
			'Santo Tomé',
			'San Pedro',
			'Pontevedra',
			'General José de San Martín',
			'Charadai',
			'Capilla del Señor',
			'Ayacucho',
			'Villa Ascasubi',
			'San Pedro de Guasayán',
			'San Carlos de Bolívar',
			'Salsipuedes',
			'Rojas',
			'Río Gallegos',
			'Olavarría',
			'Noetinger',
			'Mainque',
			'Las Lajas',
			'Hermoso Campo',
			'Gancedo',
			'Elena',
			'Cañada de Gómez',
			'Arroyito',
			'Arias',
			'Alto Río Senguer',
			'Villa Berna',
			'San Carlos de Bariloche',
			'José C. Paz',
			'Villa Ortúzar',
			'Santos Lugares',
			'San Roque',
			'San José',
			'Palo Santo',
			'Lapachito',
			'La Criolla',
			'Juan Pujol',
			'Florentino Ameghino',
			'Capitán Solari',
			'Barracas',
			'Baradero',
			'Villa Giardino',
			'Villa Atamisqui',
			'Tintina',
			'Tartagal',
			'Simoca',
			'San Carlos Centro',
			'Oncativo',
			'Olta',
			'Nueva Esperanza',
			'Mina Clavero',
			'Lonquimay',
			'Libertador General San Martín',
			'La Francia',
			'La Banda',
			'Justo Daract',
			'Jesús María',
			'General Roca',
			'General Pico',
			'El Carmen',
			'Devoto',
			'Coronel Dorrego',
			'Comandante Luis Piedra Buena',
			'Cerrillos',
			'Capilla del Monte',
			'Bandera',
			'Anguil',
			'Agua de Oro',
			//'Santa Ana',
			'Palmar Grande',
			'Oberá',
			'Morón',
			'Loreto',
			'La Plata',
			'Chavarría',
			'Buenos Aires',
			'Brandsen',
			'Belén de Escobar',
			'Azul',
			'Yuto',
			'Yacimiento Río Turbio',
			'Villa Mugueta',
			'Tancacha',
			'Santa Isabel',
			'Sampacho',
			'Rivadavia',
			'Obispo Trejo',
			'Los Cóndores',
			'Los Antiguos',
			'La Caldera',
			'Icaño',
			'Huerta Grande',
			'General Enrique Mosconi',
			'General Baldissera',
			'Embalse',
			'Castro Barros',
			'Carhué',
			'Aviá Terai',
			'Avellaneda',
			'Villa de Mayo',
			'Tacuarendí',
			'San Clemente del Tuyú',
			'Presidencia de la Plaza',
			'Lanús',
			'Esquina',
			'Ceibas',
			'Boedo',
			'Azara',
			'Arroyo del Medio',
			'Villa Nueva',
			'Villa La Angostura',
			'Villa Bustos',
			'Victoria',
			'Trenel',
			'Santa María',
			'San Luis',
			'San Francisco',
			'Rosario de la Frontera',
			'Pergamino',
			'La Quiaca',
			'Laguna Paiva',
			'Laboulaye',
			'Joaquín V. González',
			'Jacinto Arauz',
			'Helvecia',
			'General Alvear',
			'Funes',
			'Choele Choel',
			'Catriel',
			'San Miguel',
			'Villa Ocampo',
			'Santa María',
			'San Luis del Palmar',
			'Pila',
			'Paso de la Patria',
			'Nogoyá',
			'Merlo',
			'Makallé',
			'Loreto',
			'Leandro N. Alem',
			'Las Toscas',
			'Almafuerte',
			'Guernica',
			'Villa Regina',
			'Villa Cañás',
			'Valcheta',
			'Santiago del Estero',
			'Santa Lucía',
			'San Salvador de Jujuy',
			'Salsacate',
			'Rufino',
			'Río Mayo',
			'Río Cuarto',
			'Recreo',
			'Rada Tilly',
			'Quitilipi',
			'Monte Quemado',
			'La Puerta de San José',
			'La Poma',
			'General Villegas',
			'General Levalle',
			'General Acha',
			'Famaillá',
			'El Cuy',
			'Deán Funes',
			'Cutral-Có',
			'Costa Sacate',
			'Carcarañá',
			'Berrotarán',
			'Belén',
			'Arroyo Cabral',
			'Aminga',
			'Cariló',
			'Santa Catalina - Dique Lujan',
			'Villaguay',
			'San Miguel',
			'Los Charrúas',
			'Gualeguay',
			'General Rodríguez',
			'Dos de Mayo',
			'Bella Vista',
			'Villa Basilio Nievas',
			'Villa Valeria',
			'Villa Urquiza',
			'Villa Rumipal',
			'Villa Reducción',
			'Villa General Belgrano',
			'Villa del Dique',
			'Unión',
			'Tres Isletas',
			'San Cristóbal',
			'Quemú Quemú',
			'Pilar',
			'Piedra del Águila',
			'Monte Cristo',
			'Loncopué',
			'General Ramírez',
			'General Enrique Godoy',
			'Fiambalá',
			'Fernández',
			'Esperanza',
			'Corralito',
			'Colonia San Bartolomé',
			'Villa Paranacito',
			'Suipacha',
			'Sauce de Luna',
			'Posadas',
			'Los Polvorines',
			'La Verde',
			'Empedrado',
			'Caseros',
			'Belgrano',
			'Villa Cura Brochero',
			'Veinticinco de Mayo',
			'Ushuaia',
			'Tumbaya',
			'Sarmiento',
			'San Miguel de Tucumán',
			'San Antonio Oeste',
			'San Agustín',
			'Pozo Hondo',
			'Patquía',
			'Pasco',
			'Nueve de Julio',
			'Monte Maíz',
			'Maipú',
			'Londres',
			'La Viña',
			'Las Lomitas',
			'La Paz',
			'General Lavalle',
			'General Mosconi',
			'Del Campillo',
			'Dalmacio Vélez Sársfield',
			'Coronda',
			'Bell Ville',
			'Antofagasta de la Sierra',
			'Alta Gracia',
			'Villa Gesell',
			'Verónica',
			'Tandil',
			'Lucas González',
			'Gobernador Roca',
			'General Lavalle',
			'General Guido',
			'Campana',
			'Bonpland',
			'Villa Paula de Sarmiento',
			'Chañar Ladeado',
			'Villa Allende',
			'Toledo',
			'Sumampa',
			'Serrezuela',
			'Santa Rosa de Río Primero',
			'Santa Rosa',
			'San José',
			'San Agustín de Valle Fértil',
			'Rancul',
			'Molinos',
			'Miramar',
			'Malagueño',
			'San Isidro de Lules',
			'Los Varela',
			'Los Altos',
			'La Tigra',
			'Las Ovejas',
			'El Calafate',
			'Corzuela',
			'Chumbicha',
			'Catriló',
			'Carlos Casares',
			'Buena Esperanza',
			'Gato Colorado',
			'Puerto Bermejo',
			'Pilar',
			'Panambí',
			'Marcos Paz',
			'Luján',
			'Las Flores',
			'Hasenkamp',
			'Clorinda',
			'Caseros',
			'Capitán Sarmiento',
			'Basail',
			'Barranqueras',
			'Tunuyán',
			'Telsen',
			'Tapalqué',
			'Santo Tomé',
			'San Antonio de Litín',
			'Salto',
			'Pérez',
			'Parera',
			'Nueve de Julio',
			'Morrison',
			'Macachín',
			'Luján',
			'Los Juríes',
			'Libertador General San Martín',
			'Las Plumas',
			'Lamarque',
			'La Carlota',
			'Junín',
			'James Craik',
			'Ingeniero Luiggi',
			'Huillapima',
			'Hualfín',
			'Hernando',
			'General Pinedo',
			'General Martín Miguel de Güemes',
			'Coronel Baigorria',
			'Chacabuco',
			'Carnerillo',
			'Burruyacú',
			'San Francisco de Laishí',
			'Ruiz de Montoya',
			'General San Martín',
			'Coté-Lai',
			'Castelli',
			'Villa Media Agua',
			'Villa Concepción del Tío',
			'Suncho Corral',
			'Santa Magdalena',
			'San Martín',
			'Rafaela',
			'Quimilí',
			'Puerto Deseado',
			'Puan',
			'Pocito',
			'Pellegrini',
			'Malvinas Argentinas',
			'Inriville',
			'Graneros',
			'General Viamonte',
			'Etruria',
			'El Galpón',
			'El Carril',
			'Darwin',
			'Chorotis',
			'Chicoana',
			'Carlos Tejedor',
			'Calingasta',
			'Calchaquí',
			'Armstrong',
			'Apolinario Saravia',
			'Altos de Chipión',
			'La Punta',
			'San Vicente',
			'Santa Rosa',
			'Puerto Leoni',
			'Herlitzka',
			'Guaraní',
			'Goya',
			'General Galarza',
			'Garupá',
			'Candelaria',
			'Campo Ramón',
			'Villa Unión',
			'Villa del Salvador',
			'San José de la Dormida',
			'San José de Jáchal',
			'San Antonio',
			'Río Segundo',
			'Punta Alta',
			'Puerto Santa Cruz',
			'Puerto San Julián',
			'Mendoza',
			'Malanzán',
			'Limay Mahuida',
			'La Cañada',
			'General Pinto',
			'Fraile Pintado',
			'El Bolsón',
			'Centenario',
			'Brea Pozo',
			'Algarrobo del Águila',
			'Cuesta Blanca',
			'Los Laureles',
			'Villa General Guemes',
			'Veinticinco de Mayo',
			'Tres Capones',
			'San José de Feliciano',
			'San Isidro',
			'Roque Pérez',
			'Retiro',
			'Puerto Libertad',
			'Perugorría',
			'Mercedes',
			'Mártires',
			'Los Conquistadores',
			'General Las Heras',
			'Domínguez',
			'Colegiales',
			'Campo Grande',
			'Balcarce',
			'Trenque Lauquen',
			'Santa Rosa de Tastil',
			'San Ramón de la Nueva Orán',
			'Samuhú',
			'Rosario',
			'Rivadavia',
			'Pomán',
			'Pampa de los Guanacos',
			'Napenay',
			'Monte Hermoso',
			'Melincué',
			'Justiniano Posse',
			'Gobernador Gálvez',
			'General Manuel J. Campos',
			'Fray Luis Beltrán',
			'El Aguilar',
			'Colonia La Tordilla',
			'Cervantes',
			'Carrilobo',
			'Banda del Río Salí',
			'Arauco',
			'Ancasti',
			'Almafuerte',
			'Allen',
			'Rincón de Los Sauces',
			'Saladillo',
			'Riachuelo',
			'Puerto Iguazú',
			'Montecarlo',
			'La Cruz',
			'Hurlingham',
			'Gobernador Mansilla',
			'Felipe Yofré',
			'Federal',
			'Ensenada',
			'Dos Arroyos',
			'Comandante Fontana',
			'Ciervo Petiso',
			'Berazategui',
			'Avellaneda',
			'Villa Unión',
			'Villa Tulumba',
			'Villa Huidobro',
			'Villa Dolores',
			'Trevelin',
			'San Pedro de Jujuy',
			'Río Tercero',
			'Presidencia Roque Sáenz Peña',
			'Pico Truncado',
			'Paraná',
			'Marull',
			'Mariano Moreno',
			'Maimará',
			'Lago Puelo',
			'La Falda',
			'La Clotilde',
			'La Candelaria',
			'La Esperanza',
			'Gobernador Gregores',
			'El Simbolar',
			'El Rodeo',
			'Coronel Belisle',
			'Chichinales',
			'Ceres',
			'Capayán',
			'Cañada de Luque',
			'Caimancito',
			'Brinkmann',
			'Bahía Blanca',
			'Alejo Ledesma',
			'Villa Mantero',
			'Resistencia',
			'Rauch',
			'Puerto Piray',
			'Pirané',
			'Pinamar',
			'Mburucuyá',
			'Los Helechos',
			'La Paz',
			'Laguna Limpia',
			'Herrera',
			'El Alcázar',
			'Concepción del Uruguay',
			'Villa Las Rosas',
			'Villa Constitución',
			'Ticino',
			'Sañogasta',
			'San Martín',
			'San Justo',
			'Pilcaniyeu',
			'Paso de Indios',
			'Pascanas',
			'Palpalá',
			'Los Surgentes',
			'La Rioja',
			'La Calera',
			'Hersilia',
			'General Fernández Oro',
			'Chos Malal',
			'Candelaria',
			'Calilegua',
			'Florentino Ameghino',
			'Aimogasta',
			'Adelia María',
			'Abra Pampa',
			'Yataity Calle',
			'Villa María Grande',
			'San Lorenzo',
			'Puerto Yeruá',
			'José María Ezeiza',
			'Colonia Elisa',
			'Cañuelas',
			'Aristóbulo del Valle',
			'Alba Posse',
			'Villa Nueva',
			'Termas de Río Hondo',
			'San Martín de los Andes',
			'Pigüé',
			'Naschel',
			'Leleque',
			'La Para',
			'Huanchillas',
			'Henderson',
			'General Alvear',
			'Gastre',
			'El Maitén',
			'Corral de Bustos',
			'Colonia Barón',
			'Cintra',
			'Chamical',
			'Cavanagh',
			'Caucete',
			'Castelli',
			'Barrancas',
			'Andalgalá',
			'San Javier',
			'San Antonio de Areco',
			'Puerto Vilelas',
			'Pedro R. Fernández',
			'Pampa del Indio',
			'Muñiz',
			'Mar del Tuyú',
			'Capioví',
			'Villa Krause',
			'Villa Ángela',
			'Veinticinco de Mayo',
			'Tres Arroyos',
			'Tilcara',
			'Saujil',
			'Santa Rosa del Conlara',
			'San Nicolás de los Arroyos',
			'San Carlos',
			'Quilino',
			'Oliva',
			'Ñorquinco',
			'Los Menucos',
			'La Maruja',
			'La Cocha',
			'Gobernador Costa',
			'Fray Luis A. Beltrán',
			'Esquel',
			'Crespo',
			'Concarán',
			'Charata',
			'Buta Ranquil',
			'Bragado',
			'Beltrán',
			'Aranguren',
			'La Adela',
			'Yapeyú',
			'Santa Anita',
			'Mercedes',
			'Maciá',
			'Ituzaingó',
			'Florencio Varela',
			'Federación',
			'El Soberbio',
			'Dolores',
			'Concordia',
			'Colonias Unidas',
			'Colonia Elía',
			'Berón de Astrada',
			'Villa General Roca',
			'Toay',
			'Taco Pozo',
			'Susques',
			'San Juan',
			'Catamarca',
			'San Basilio',
			'San Antonio',
			'Pampa del Infierno',
			'Morteros',
			'Mendiolaza',
			'Luján de Cuyo',
			'Las Varillas',
			'Las Parejas',
			'Las Lajitas',
			'Las Heras',
			'La Puerta',
			'Laprida',
			'La Granja',
			'General San Martín',
			'General Arenales',
			'Embarcación',
			'El Huecú',
			'El Alto',
			'Diamante',
			'Cruz Alta',
			'Córdoba',
			'Chepes',
			'Añatuya',
			'Villa Hernandarias',
			'Villa Escolar',
			'San Cosme',
			'Saladas',
			'Rosario del Tala',
			'Necochea',
			'Mojón Grande',
			'Mar del Plata',
			'Espinillo',
			'Colón',
			'Alvear',
			'Aldea San Antonio',
			'Villa Santa Rosa',
			'Villa Ojo de Agua',
			'Villa Castelli',
			'Uriburu',
			'Tamberías',
			'Santiago Temple',
			'Salta',
			'Perito Moreno',
			'Nono',
			'Monteros',
			'Maquinchao',
			'Iruya',
			'Humahuaca',
			'Herrera',
			'Guachipas',
			'Garza',
			'Embajador Martini',
			'El Tío',
			'Eduardo Castex',
			'Clodomira',
			'Charras',
			'Capitán Bermúdez',
			'Aluminé',
			'Villa Santa Rita',
			'Bella Italia',
			'Adrogué',
			'Sauce',
			'Santa Elena',
			//'Santa Ana',
			'San Carlos',
			'Quilmes',
			'Pueblo Libertador',
			'Lobos',
			'Lobería',
			'Herradura',
			'Gobernador Juan E. Martínez',
			'Eldorado',
			'Conscripto Bernardi',
			'Benito Juárez',
			'Villa Trinidad',
			'Villa de Soto',
			'Vedia',
			'Trancas',
			'Tostado',
			'Saturnino M. Laspiur',
			'Santa Rosa de Calamuchita',
			'San Rafael',
			'Sacanta',
			'Pozo del Molle',
			'Pehuajó',
			'Neuquén',
			'Machagai',
			'Intendente Alvear',
			'Hoyo de Epuyén',
			'Firmat',
			'Despeñaderos',
			'Concepción',
			'Comodoro Rivadavia',
			'Bernardo Larroudé',
			'Balnearia',
			'Alpachiri',
			'Tabossi',
			'San Andrés de Giles',
			'Puerto Esperanza',
			'Picada Gobernador López',
			'Mocoretá',
			'Malabrigo',
			'Jardín América',
			'General Juan Madariaga',
			'General Alvear',
			'Don Torcuato',
			'Concepción de la Sierra',
			'Chascomús',
			'Bernardo de Irigoyen',
			'Vicuña Mackenna',
			'Tafí Viejo',
			'Senillosa',
			'San Francisco del Monte de Oro',
			'Río Ceballos',
			'Plaza Huincul',
			'Piquillín',
			'Ordóñez',
			'Médanos',
			'Marcos Juárez',
			'La Mendieta',
			'Laguna Yema',
			'La Cumbre',
			'Italó',
			'Huinca Renancó',
			'Gaimán',
			'Estanislao del Campo',
			'El Quebrachal',
			'Comallo',
			'Campo Largo',
			'Arrufó',
			'Arroyo Seco',
			'San Vicente',
			'Ranchos',
			'Presidencia Roca',
			'Olivos',
			'Lomas de Zamora',
			'Lomas de Vallejos',
			'La Eduvigis',
			'Ibarreta',
			'Gualeguaychú',
			'General Pacheco',
			'Colonia Benítez',
			'Bovril',
			'Apóstoles',
			'Yerba Buena',
			'Villa del Rosario',
			'Venado Tuerto',
			'28 de Noviembre',
			'Telén',
			'Sierra Colorada',
			'Serrano',
			'Sastre',
			'Santa Clara',
			'San Bernardo',
			'Puerta de Corral Quemado',
			'Palma Sola',
			'Las Rosas',
			'Las Junturas',
			'Junín de los Andes',
			'Guatraché',
			'General Roca',
			'El Hoyo',
			'Coronel Moldes',
			'Colón',
			'Carmen de Patagones',
			'Campo Quijano',
			'Caleta Olivia',
			'Arrecifes',
			'Andacollo',
			'Balvanera',
			'Santa Lucía',
			'San Salvador',
			'Pronunciamiento',
			'Libertad',
			'General Campos',
			'Corrientes',
			'Coronel Vidal',
			'Colonia Aurora',
			'Chajarí',
			'Cerro Azul',
			'Campo Viera',
			'Wenceslao Escalante',
			'Villa del Totoral',
			'Viedma',
			'Vera',
			'Valle Hermoso',
			'Ulapes',
			'Tornquist',
			'Santa Catalina',
			'San Antonio de los Cobres',
			'Picún Leufú',
			'Merlo',
			'Los Frentones',
			'La Consulta',
			'José de San Martín',
			'Godoy Cruz',
			'Doblas',
			'Cosquín',
			'Coronel Suárez',
			'Canals',
			'Camarones',
			'Caleufú',
			'Bañado de Ovanta',
			'Albardón',
			'Aguilares',
		],
	},
	Armenia: {
		country: 'Armenia',
		cities: [
			'Khndzoresk',
			'Verin Artashat',
			'Tsaghkaber',
			'Alashkert',
			'Pemzashen',
			'Lerrnants’k’',
			'Shahumyan',
			'Dzoraghbyur',
			'Chochkan',
			'Aygehovit',
			'Nor Yerznka',
			'Sayat’-Nova',
			'Voskehat',
			'Lusarrat',
			'Yerevan',
			'Yeghegnut',
			'Varser',
			'Vahagni',
			'Mosesgegh',
			'Gyulagarak',
			'Doghs',
			'Berdavan',
			'Arevshat',
			'Amasia',
			'Bardzrashen',
			'Lenughi',
			'Gmbet’',
			'Vedi',
			'Shinuhayr',
			'Noyakert',
			'Goris',
			'Akner',
			'Vagharshapat',
			'Tsovak',
			'Tsaghkunk’',
			'Nalbandyan',
			'Darpas',
			'Dalarik',
			'Berd',
			'Aygestan',
			'Argavand',
			'Myasnikyan',
			'Griboyedov',
			'Areni',
			'Voskevan',
			'Odzun',
			'Mets Masrik',
			'Mets Mant’ash',
			'Lerrnavan',
			'Kosh',
			'Zarrit’ap’',
			'Avshar',
			'Vardenis',
			'Nor Armavir',
			'Kotayk’',
			'Hnaberd',
			'Kaputan',
			'Argel',
			'Arevik',
			'Arevashat',
			'Aramus',
			'Nshavan',
			'Nizami',
			'Taronik',
			'Zangakatun',
			'Vayk’',
			'Hats’avan',
			'Horrom',
			'Margara',
			'Sevan',
			'P’shatavan',
			'P’ok’r Mant’ash',
			'Hayanist',
			'Haghartsin',
			'Gyumri',
			'Chambarak',
			'Gay',
			'Musalerr',
			'Brrnakot’',
			'Saratak',
			'Dsegh',
			'Artimet',
			'Arevshat',
			'Vardablur',
			'Zovaber',
			'Voskevaz',
			'Merdzavan',
			'Lorut',
			'Getazat',
			'Geghamavan',
			'Aygeshat',
			'Argavand',
			'Zarr',
			'Tandzut',
			'Spitak',
			'Khasht’arrak',
			'Karanlukh',
			'Janfida',
			'Byurakan',
			'Bambakashat',
			'Sasunik',
			'Jermuk',
			'Berk’anush',
			'Vardenik',
			'Ushi',
			'Masis',
			'Gavar',
			'Jrashen',
			'Aparan',
			'Gandzak',
			'Tegh',
			'Malishka',
			'Aygavan',
			'Zorak',
			'Verin Dvin',
			'Vahan',
			'Sarukhan',
			'Tsiatsan',
			'Fioletovo',
			'Aygeshat',
			'Arshaluys',
			'Akunk’',
			'Aghavnatun',
			'Arevabuyr',
			'Tashir',
			'Sis',
			'Navur',
			'Ijevan',
			'Ghukasavan',
			'Arak’s',
			'Akunk’',
			'Aghavnadzor',
			'Dashtavan',
			'Hovtashen',
			'Goravan',
			'Armash',
			'Tsaghkadzor',
			'Ptghunk’',
			'Norakert',
			'Nerkin Getashen',
			'Metsavan',
			'Mayisyan',
			'Lchap',
			'Jrashen',
			'Arzni',
			'Arevik',
			'Arazap’',
			'Akhuryan',
			'Mrgavet',
			'Aralez',
			'Verin Getashen',
			'Tsovagyugh',
			'Tsaghkahovit',
			'Lanjaghbyur',
			'Fantan',
			'Dzit’hank’ov',
			'Drakhtik',
			'Ch’arents’avan',
			'Arzakan',
			'Agarakavan',
			'Aygek',
			'Getap’',
			'Sardarapat',
			'Basen',
			'Madina',
			'Karchaghbyur',
			'Kamo',
			'Mayisyan',
			'Dvin',
			'Balahovit',
			'Ashtarak',
			'Archis',
			'Artashat',
			'Yeraskhahun',
			'Vaghashen',
			'Geghakert',
			'Karbi',
			'Getashen',
			'Haykavan',
			'Arbat’',
			'Haykashen',
			'Aghavnadzor',
			'Mrgashen',
			'Mrgashat',
			'Metsamor',
			'Marmashen',
			'Lichk’',
			'Hrazdan',
			'Aknashen',
			'Dzorastan',
			'Agarak',
			'Shnogh',
			'Zoravan',
			'Khoronk’',
			'K’anak’erravan',
			'Arrap’i',
			'Aragats',
			'Metsamor',
			'Baghramyan',
			'Vernashen',
			'Sisavan',
			'Akht’ala',
			'Tsovinar',
			'T’alin',
			'Shenavan',
			'Saramej',
			'Nor Geghi',
			'Hovtamej',
			'Gagarin',
			'Jrahovit',
			'Byureghavan',
			'Byuravan',
			'Abovyan',
			'Dalar',
			'Zovuni',
			'Yeghvard',
			'Oshakan',
			'Lerrnanist',
			'Astghadzor',
			'Alaverdi',
			'Darakert',
			'Angeghakot’',
			'Yeghegnut',
			'Solak',
			'Prroshyan',
			'Nor Gyugh',
			'Norashen',
			'Norashen',
			'Noratus',
			'Mrganush',
			'Mayakovski',
			'Maralik',
			'Gogaran',
			'Dilijan',
			'Ashnak',
			'Artsvaberd',
			'Mrgavan',
			'Gladzor',
			'Tsovasar',
			'Shirak',
			'Lerrnakert',
			'Aknalich',
			'Vardadzor',
			'Yeghegnavan',
			'Vostan',
			'Verishen',
			'Shaghat',
			'Aygepat',
			'Noramarg',
			'Geghamasar',
			'Arevashogh',
			'Surenavan',
			'Shatin',
			'Meghri',
			'Yeghegis',
			'Ararat',
			'Agarakadzor',
			'Spandaryan',
			'Lchashen',
			'Goght’',
			'Dzoragyugh',
			'Bagratashen',
			'Vosketap’',
			'Aygezard',
			'Tsovazard',
			'Stepanavan',
			'Sarigyugh',
			'Sarahart’',
			'Ptghni',
			'Mets Parni',
			'Meghrashen',
			'Martuni',
			'Lerrnapat',
			'Vanadzor',
			'Armavir',
			'Apaga',
			'Dimitrov',
			'Meghradzor',
			'Gugark',
			'Geghanist',
			'Bazum',
			'Anushavan',
			'Hovtashat',
			'Yeghegnadzor',
			'Rrind',
			'Yeranos',
			'Urrut',
			'Marmarashen',
			'Margahovit',
			'Ddmashen',
			'Azatan',
			'Norabats’',
			'Azatamut',
			'Shahumyan',
			'Kapan',
			'Burastan',
			'Yerazgavors',
			'Voskehask',
			'Shenavan',
			'Parravak’ar',
			'Noyemberyan',
			'Lukashin',
			'Kasakh',
			'Garrni',
			'Bjni',
			'Buzhakan',
			'Arteni',
			'Agarak',
			'Abovyan',
		],
	},
	Aruba: { country: 'Aruba', cities: ['Santa Cruz', 'Paradera', 'Tanki Leendert', 'San Nicolas', 'Savaneta', 'Oranjestad'] },
	Australia: {
		country: 'Australia',
		cities: [
			'Underwood',
			'Edensor Park',
			'Avondale Heights',
			'Wyndham Vale',
			'Wynn Vale',
			'Piara Waters',
			'Girraween',
			'Glenwood',
			'Carramar',
			'Glen Osmond',
			'Rockville',
			'Lamington',
			'Shorewell Park',
			'Reid',
			'Crestwood',
			'Double Bay',
			'Little Mountain',
			'Tanawha',
			'Beulah Park',
			'Alderley',
			'Padbury',
			'Park Grove',
			'Rosemeadow',
			'Coombabah',
			'Tranmere',
			'Vale Park',
			'Craigie',
			'Moorooka',
			'Flinders Park',
			'Maylands',
			'Westminster',
			'Coolbellup',
			'Kardinya',
			'Wannanup',
			'Palmerston',
			'Hillside',
			'St James',
			'Beverley',
			'Dudley Park',
			'Beaumont Hills',
			'Seaford Rise',
			'Ascot',
			'Mackenzie',
			'Upper Mount Gravatt',
			'Delaneys Creek',
			'Watsonia North',
			'Gowanbrae',
			'Cranbourne West',
			'Sunshine West',
			'Clemton Park',
			'Cremorne Point',
			'Renown Park',
			'North Wahroonga',
			'West Pennant Hills',
			'West Hindmarsh',
			'Woodville Park',
			'Alfred Cove',
			'West Mackay',
			'Carrington',
			'Cardiff Heights',
			'South Townsville',
			'Chatsworth',
			'Hodgson Vale',
			'Cooroibah',
			'East Innisfail',
			'Innisfail Estate',
			'Manly Vale',
			'Sapphire Beach',
			'South Kempsey',
			'Korora',
			'Meringandan West',
			'Woodside',
			'Victor Harbor',
			'Stoneville',
			'Port Elliot',
			'Parkerville',
			'Kersbrook',
			'Katherine',
			'Harvey',
			'Chidlow',
			'Wollongbar',
			'Wilberforce',
			'Tullamarine',
			'Tugun',
			'Tamworth',
			'Springsure',
			'Saint Helena',
			'Rockhampton',
			'Ripponlea',
			'Red Cliffs',
			'Portland',
			'Oakleigh',
			'Normanton',
			'Nerang',
			'Narre Warren North',
			'Narangba',
			'Mount Gravatt',
			'Mount Duneed',
			'Mount Druitt',
			'Mona Vale',
			'Mascot',
			'Leura',
			'Kingston',
			'Kinglake',
			'Keiraville',
			'Glenella',
			'Ettalong Beach',
			'Erina',
			'Epsom',
			'Dungog',
			'Davistown',
			'Carlton',
			'Carbrook',
			'Calliope',
			'Burnett Heads',
			'Budgewoi',
			'Buccan',
			'Broadmeadows',
			'Briar Hill',
			'Bermagui',
			'Bellbird',
			'Bay View',
			'Baulkham Hills',
			'Balranald',
			'Alfredton',
			'New Town',
			'North Brighton',
			'Randwick',
			'Blair Athol',
			'Kallangur',
			'Fortitude Valley',
			'Craiglie',
			'Hope Vale',
			'Wongaling Beach',
			'Sandstone Point',
			'Peterhead',
			'City',
			'Kensington Park',
			'Chifley',
			'Waratah West',
			'Eumemmerring',
			'Moil',
			'Winston Hills',
			'Wickham',
			'Heritage Park',
			'Macleay Island',
			'Newcomb',
			'Petrie Terrace',
			'South Kingsville',
			'Balga',
			'Greenwood',
			'Dallas',
			'Ashfield',
			'Wilson',
			'Yarralumla',
			'Barellan Point',
			'Holtze',
			'Oak Flats',
			'Mile End',
			'Silver Sands',
			'Zetland',
			'Condon',
			'Coolaroo',
			'Pelican Waters',
			'Kings Langley',
			'Girrawheen',
			'Cooloongup',
			'Pegs Creek',
			'Mount Nelson',
			'Casey',
			'McKellar',
			'Prospect',
			'Araluen',
			'Montrose',
			'Cranbrook',
			'Whyalla Stuart',
			'Whyalla Jenkins',
			'Bindoon',
			'Wadeye',
			'Mount Gravatt East',
			'Sadliers Crossing',
			'Altona Meadows',
			'Bexley North',
			'Marsfield',
			'Roseville Chase',
			'South Wentworthville',
			'Munno Para',
			'Red Hill',
			'Parkwood',
			'Parmelia',
			'Ormeau Hills',
			'Buff Point',
			'Newcastle East',
			'Belmont North',
			'Forest Glen',
			'Black River',
			'White Hills',
			'Sunshine Coast',
			'Burpengary East',
			'West Kempsey',
			'Ballarat North',
			'Bennett Springs',
			'The Hill',
			'Unley',
			'Reynella',
			'Nhulunbuy',
			'Maitland',
			'Kununurra',
			'Kapunda',
			'Clare',
			'Bassendean',
			'Worrigee',
			'Woodend',
			'Warrimoo',
			'Warracknabeal',
			'Wamberal',
			'Unanderra',
			'Tweed Heads',
			'Thirroul',
			'Tallebudgera',
			'Swan Hill',
			'Sunshine',
			'Renmark',
			'Redlynch',
			'Quirindi',
			'Preston',
			'Ouyen',
			'Oatley',
			'Newington',
			'Murrurundi',
			'Mullumbimby',
			'Moolap',
			'Mirboo North',
			'Millicent',
			'Melbourne',
			'Matraville',
			'Maffra',
			'Lithgow',
			'Lawrence',
			'Langwarrin',
			'Kilcoy',
			'Kallista',
			'Innisfail',
			'Helensburgh',
			'Heathcote',
			'Hampton Park',
			'Guyra',
			'Goodna',
			'Forbes',
			'Eulomogo',
			'Edmonton',
			'Dubbo',
			'Daylesford',
			'Casterton',
			'Burwood East',
			'Brunswick East',
			'Bowral',
			'Boondall',
			'Blair Athol',
			'Black Hill',
			'Batemans Bay',
			'Bass Hill',
			'Banora Point',
			'Ballarat',
			'Avoca Beach',
			'Ascot',
			'Bundeena',
			'Clovelly',
			'Shoal Bay',
			'Heathridge',
			'Herston',
			'The Gap',
			'Paddington',
			'Oxenford',
			'Melton West',
			'Blackburn North',
			'North Ipswich',
			'Old Erowal Bay',
			'Indented Head',
			'Murray',
			'Myrtle Bank',
			'Catherine Field',
			'St Ives Chase',
			'Stretton',
			'Woodvale',
			'Mickleham',
			'Golden Bay',
			'Fadden',
			'Mira Mar',
			'Pemulwuy',
			'Travancore',
			'Raby',
			'Newborough',
			'Riverside',
			'Ambarvale',
			'Buttaba',
			'Cranebrook',
			'Gailes',
			'Gaven',
			'Newtown',
			'Karabar',
			'East Victoria Park',
			'Palmyra',
			'Sadleir',
			'Machans Beach',
			'Tregear',
			'Jan Juc',
			'McCracken',
			'Falcon',
			'Landsdale',
			'Avoca',
			'Ooralea',
			'Hendra',
			'Floreat',
			'Glenroy',
			'Weston',
			'Tivoli',
			'Seddon',
			'Bardwell Valley',
			'Carramar',
			'Denistone',
			'Peakhurst Heights',
			'West Ryde',
			'Semaphore Park',
			'West Lakes Shore',
			'Somerton Park',
			'Oaklands Park',
			'Lower Mitcham',
			'Trinity Gardens',
			'Ferryden Park',
			'Salisbury Park',
			'Modbury Heights',
			'Acton Park',
			'Rankin Park',
			'East Launceston',
			'North Bendigo',
			'East Kempsey',
			'Wagin',
			'Trigg',
			'Sturt',
			'Seaton',
			'Port Lincoln',
			'Peterborough',
			'Merredin',
			'Mandurah',
			'Grasmere',
			'Glenelg',
			'Bedford Park',
			'Alyangula',
			'Wy Yung',
			'Williamstown',
			'Westbury',
			'Wantirna South',
			'Valley Heights',
			'Urraween',
			'Tuross Head',
			'Townsville',
			'Stockton',
			'Stawell',
			'Stanwell Park',
			'Saint Ives',
			'Rosebud',
			'Point Vernon',
			'Palmwoods',
			'Palm Beach',
			'Orange',
			'Oonoonba',
			'Barwon Heads',
			'Noosaville',
			'New Norfolk',
			'Mudgee',
			'Mount Macedon',
			'Mooloolaba',
			'Mont Albert',
			'Moggill',
			'Mitchelton',
			'Mareeba',
			'Malanda',
			'Malabar',
			'Lakes Entrance',
			'Lake Albert',
			'Kyabram',
			'Hughesdale',
			'Footscray',
			'Essendon',
			'Douglas Park',
			'Dorrigo',
			'Donald',
			'Cloncurry',
			'Caboolture',
			'Brisbane',
			'Boorowa',
			'Bongaree',
			'Bli Bli',
			'Belgian Gardens',
			'Bangalow',
			'Ascot Vale',
			'Sans Souci',
			'East Brisbane',
			'Maroochydore',
			'Umina Beach',
			'Wolli Creek',
			'Mount Buller',
			'Clayfield',
			'Rainbow Beach',
			'Salisbury East',
			'Ashwood',
			'Brassall',
			'Eastern Heights',
			'St Albans',
			'Summer Hill',
			'The Entrance North',
			'Burrum Heads',
			'Deeragun',
			'Saint Andrews Beach',
			'Tura Beach',
			'North Perth',
			'West Perth',
			'St Helens Park',
			'Horsley',
			'Subiaco',
			'Pinjarra',
			'Margaret River',
			'Manning',
			'Little Hampton',
			'Hope Valley',
			'Greenock',
			'Darlington',
			'Applecross',
			'Yerrinbool',
			'Wyee',
			'Wonthaggi',
			'West Wallsend',
			'Wandong',
			'Walloon',
			'Trafalgar',
			'Toongabbie',
			'Terrigal',
			'Tatura',
			'Somerset',
			'Schofields',
			'Rydalmere',
			'Rockbank',
			'Rochedale',
			'Raglan',
			// 'Queenstown',
			'Pittsworth',
			'Newnham',
			'Moonee Ponds',
			'Moama',
			'Lilydale',
			'Leichhardt',
			'Koroit',
			'Kingston',
			'Kingscliff',
			'Holsworthy',
			'Hallam',
			'Grenfell',
			'Geelong',
			'Eagleby',
			'Drouin',
			'Darra',
			'Croydon North',
			'Cooroy',
			'Casino',
			'Braidwood',
			'Boyne Island',
			'Bowen',
			'Boambee',
			'Berry',
			'Ballan',
			'Artarmon',
			'Appin',
			'Anna Bay',
			'Allora',
			'Acacia Ridge',
			'Sylvania',
			'Tarrawanna',
			'Cheltenham',
			'Balgowlah Heights',
			'Gladstone Park',
			'Klemzig',
			'Indooroopilly',
			'Kewarra Beach',
			'Fullarton',
			'Adelaide Hills',
			'Southbank',
			'Helensvale',
			'Bonny Hills',
			'South West Rocks',
			'Montello',
			'Spalding',
			'Girards Hill',
			'Maraylya',
			'Evandale',
			'Wagaman',
			'Windradyne',
			'Kangaroo Ground',
			'Cambridge Gardens',
			'Exeter',
			'Pimlico',
			'Leeming',
			'Allawah',
			'Austral',
			'Chelmer',
			'Queenscliff',
			'Pacific Paradise',
			'Glendenning',
			'Wulguru',
			'Kingsgrove',
			'Hobartville',
			'Dernancourt',
			'Mount Richon',
			'Tecoma',
			'Kawana',
			'Seaview Downs',
			'Quarry Hill',
			'Durack',
			'Doolandella',
			'Pacific Pines',
			'Sunrise Beach',
			'Tingalpa',
			'Hillsdale',
			'Hampstead Gardens',
			'Beckenham',
			'Lesmurdie',
			'Millars Well',
			'Barton',
			'Higgins',
			'Redcliffe',
			'Monash',
			'Crace',
			'Beldon',
			'Hawker',
			'Warabrook',
			'Williams Landing',
			'North Willoughby',
			'East Ipswich',
			'Russell Island',
			'Taylors Hill',
			'Horsley Park',
			'Kurraba Point',
			'Narellan Vale',
			'North Curl Curl',
			'Clovelly Park',
			'Norwood',
			'Payneham',
			'Angle Park',
			'Ethelton',
			'Para Hills West',
			'Bonner',
			'Stuart',
			'North Wollongong',
			'Mount Warrigal',
			'Roebuck',
			'Lismore Heights',
			'Coomera',
			'South Gladstone',
			'Romaine',
			'Skennars Head',
			'Pie Creek',
			'Murray Bridge',
			'Middle Swan',
			'Marino',
			'Karnup',
			'Ingle Farm',
			'Guildford',
			'Freeling',
			'Donnybrook',
			'Cowaramup',
			'Leanyer',
			'Wondai',
			'Wingham',
			'Tarro',
			'Seven Hills',
			'Saint Andrews',
			'Rockdale',
			'Raworth',
			'Queanbeyan',
			'Portland',
			'Pascoe Vale',
			'Nyora',
			'North Turramurra',
			'Noosa Heads',
			'Newstead',
			'Mulgoa',
			'Mordialloc',
			'Mirani',
			'Lorne',
			'Lismore',
			'Lisarow',
			'Lakemba',
			'Lake Illawarra',
			'Hurstbridge',
			'Howard',
			'Haberfield',
			'Epping',
			'Edithvale',
			'Dora Creek',
			'Deer Park',
			'Cronulla',
			'Creswick',
			'Cressy',
			'Coogee',
			'Cobar',
			'Cleveland',
			'Charlestown',
			'Carrum Downs',
			'Broken Hill',
			'Booker Bay',
			'Blackwater',
			'Bingara',
			'Bilinga',
			'Bexley',
			'Bairnsdale',
			'Bagdad',
			'Woolooware',
			'Mount Keira',
			'Seaforth',
			'Windsor',
			'East Melbourne',
			'Stirling',
			'Logan City',
			'Dawesville',
			'Caulfield South',
			'Mount Helen',
			'Galiwinku',
			'Long Beach',
			'Marcoola',
			'Maslin Beach',
			'Fingal Bay',
			'Freemans Reach',
			'Lake Cathie',
			'Leinster',
			'Thursday Island',
			'Wickham',
			'Moorebank',
			'Acacia Gardens',
			'Holt',
			'Gunbalanya',
			'Abbey',
			'Bokarina',
			'Plumpton',
			'Regency Downs',
			'Sheldon',
			'Onkaparinga Hills',
			'Safety Beach',
			'St Marys',
			'Glynde',
			'Banksia Beach',
			'Copacabana',
			'Ainslie',
			'Lethbridge Park',
			'Macquarie Fields',
			'Mawson',
			'Anstead',
			'Kuraby',
			'Maroochy River',
			'Karawara',
			'Cooee Bay',
			'Sunset Beach',
			'Braitling',
			'Amaroo',
			'Wattle Grove',
			'Kirwan',
			'Highgate',
			'Southern River',
			'Lakewood',
			'Lane Cove North',
			'Chandler',
			'Wynnum West',
			'Hadfield',
			'North Warrandyte',
			'Kilsyth South',
			'Frankston South',
			'South Turramurra',
			'Spring Farm',
			'Telopea',
			'Henley Beach',
			'Woodville South',
			'Elizabeth North',
			'Hamilton',
			'Tingira Heights',
			'West Woombye',
			'Maitland city centre',
			'Kalamunda',
			'Rossmore',
			'Rapid Creek',
			'Macquarie Park',
			'Reedy Creek',
			'Shortland',
			'Westleigh',
			'Invermay Park',
			'Jolimont',
			'Ballajura',
			'Kings Park',
			'Ridgewood',
			'Brighton',
			'Mundingburra',
			'Georgetown',
			'Bella Vista',
			'Coes Creek',
			'Eschol Park',
			'Five Dock',
			'Holland Park West',
			'Huntingdale',
			'Ellenbrook',
			'Gray',
			'Bellbowrie',
			'Mount Tarcoola',
			'Leppington',
			'Bell Park',
			'Nakara',
			'West Croydon',
			'Greenwith',
			'Huntfield Heights',
			'Camillo',
			'Lyons',
			'Meadowbank',
			'Bull Creek',
			'Punchbowl',
			'Brooklyn',
			'Kings Beach',
			'Highgate',
			'Milsons Point',
			'Coopers Plains',
			'Gumdale',
			'Westmeadows',
			'East Lindfield',
			'Fairfield East',
			'Fairfield Heights',
			'Miller',
			'South Penrith',
			'Wareemba',
			'Old Reynella',
			'East Cannington',
			'Wembley Downs',
			'South Hobart',
			'Currumbin Waters',
			'New Lambton Heights',
			'Bilambil Heights',
			'West Wodonga',
			'Strathmore',
			'Singleton Heights',
			'Trunding',
			'Busselton city cenre',
			'Meningie',
			'McLaren Flat',
			'Maylands',
			'Kojonup',
			'Golden Grove',
			'Darwin',
			'Belair',
			'Peppermint Grove',
			'Yarraman',
			'Whitebridge',
			'Warrandyte',
			'Warialda',
			'Upper Caboolture',
			'Trinity Beach',
			'Tongala',
			'Timboon',
			'The Oaks',
			'Tewantin',
			'Tallangatta',
			'Seaford',
			'Roma',
			'Rochester',
			'Riverstone',
			'Ringwood',
			'Richmond',
			'Pennant Hills',
			'Lancefield',
			'Oakdale',
			'North Sydney',
			'Newtown',
			'Newstead',
			'Mortdale',
			'Montville',
			'Montrose',
			'Mentone',
			'Maryborough',
			'Maroubra',
			'Lidcombe',
			'Leumeah',
			'Latrobe',
			'Largs',
			'Lane Cove',
			'Kingaroy',
			'Kendall',
			'Kelso',
			'Kandos',
			'Ipswich',
			'Iluka',
			'Gordonvale',
			'Glen Innes',
			'Fawkner',
			'Eastwood',
			'Earlwood',
			'Dimboola',
			'Devon Meadows',
			'Cunnamulla',
			'Coominya',
			'Clifton Springs',
			'Claremont',
			'Chinchilla',
			'Capalaba',
			'Brown Hill',
			'Bellerive',
			'Beaumaris',
			'Beachmere',
			'Bathurst',
			'Balmain',
			'Armidale',
			'Sandy Bay',
			'Bronte',
			'Airport West',
			'Hervey Bay',
			'Kelvin Grove',
			'Phillip Island',
			'Box Hill South',
			'Raceview',
			'Bohle Plains',
			'Jensen',
			'Balcatta',
			'Bonnells Bay',
			'Cooran',
			'Doomadgee',
			'Leschenault',
			'Nathalia',
			'Sellicks Beach',
			'Shailer Park',
			'Wellesley Islands',
			'Sydenham',
			'Woodroffe',
			'Kuluin',
			'Tatton',
			'Daisy Hill',
			'Orangeville',
			'Wamboin',
			'Warriewood',
			'Ardeer',
			'Hmas Cerberus',
			'Woodberry',
			'Drewvale',
			'Plumpton',
			'Macquarie Hills',
			'Dulwich',
			'Rushcutters Bay',
			'Boronia Heights',
			'Rostrevor',
			'Parkwood',
			'Upper Kedron',
			'Parrearra',
			'Dickson',
			'Blakeview',
			'Everton Hills',
			'Beechboro',
			'Chambers Flat',
			'Lathlain',
			'Soldiers Hill',
			'Eli Waters',
			'Burdell',
			'Highton',
			'Dulwich Hill',
			'Koongal',
			'Tinana',
			'Hamlyn Terrace',
			'Kiara',
			'Wanniassa',
			'Beaconsfield',
			'Holmview',
			'Bundoora',
			'Inglewood',
			'Seaford Meadows',
			'South Kolan',
			'Keilor Lodge',
			'Eynesbury',
			'Oran Park',
			'South Windsor',
			'Glenunga',
			'Gawler South',
			'Rosenthal Heights',
			'Novar Gardens',
			'Oakford',
			'South Fremantle',
			'Gateshead',
			'West Wollongong',
			'West Launceston',
			'Katherine South',
			'Perth city centre',
			'Currumbin',
			'North Coogee',
			'Healy',
			'Ocean Grove',
			'Wallaroo',
			'Virginia',
			'Port Noarlunga',
			'Nuriootpa',
			'Mundaring',
			'Hindmarsh Island',
			'Geraldton',
			'Crystal Brook',
			'Cleve',
			'Boulder',
			'Two Rocks',
			'Lower King',
			'Yoogali',
			'Yass',
			'Woodgate',
			'Williamtown',
			'Whittlesea',
			'Wheelers Hill',
			'Wellington',
			'Wamuran',
			'Wahroonga',
			'Toogoom',
			'Terang',
			'Sunnybank',
			'Springvale',
			'South Melbourne',
			'Sorell',
			'Somersby',
			'Ryde',
			'Regents Park',
			'Redfern',
			'Perth',
			'Panton Hill',
			'Officer',
			'North Melbourne',
			'North Mackay',
			'Narre Warren',
			'Narrabri',
			'Millthorpe',
			'Merriwa',
			'Medowie',
			'Margate',
			'Longford',
			'Lara',
			'Landsborough',
			'Kalbar',
			'Horsham',
			'Heathmont',
			'Gundaroo',
			'Gorokan',
			'Gilston',
			'George Town',
			'Geelong West',
			'Ettalong',
			'Edge Hill',
			'Eagle Point',
			'Dingley Village',
			'Darley',
			'Como',
			'Coledale',
			'Clifton Hill',
			'Chipping Norton',
			'Chinderah',
			'Canungra',
			'Bittern',
			'Beresfield',
			'Belmont',
			'Bellambi',
			'Baxter',
			'Aurukun',
			'Apollo Bay',
			'Alstonville',
			'Illawong',
			'Bulimba',
			'Paddington',
			'Yulara',
			'Karratha',
			'Springfield Lakes',
			'Iluka',
			'Tieri',
			'Kingsbury',
			'Mount Lawley',
			'Bracken Ridge',
			'Wongawallan',
			'North Ryde',
			'Jabiru',
			'Golden Beach',
			'Miami',
			'Collingwood Park',
			'Labrador',
			'Sandy Beach',
			'Murarrie',
			'Dalmeny',
			'Ellalong',
			'Mudjimba',
			'Sussex Inlet',
			'Paraburdoo',
			'Thurgoona',
			'Tapping',
			'Winmalee',
			'Grose Vale',
			'Maryland',
			'Calwell',
			'Evanston',
			'North Rocks',
			'Prestons',
			'Payneham South',
			'Nichols Point',
			'San Remo',
			'Lake Gardens',
			'Rosemount',
			'Black Forest',
			'Yennora',
			'Downer',
			'St Georges',
			'Lota',
			'Aroona',
			'Brookdale',
			'Greystanes',
			'Millers Point',
			'Bakewell',
			'Marayong',
			'Jubilee Pocket',
			'Currumbin Valley',
			'Frenchville',
			'Mundoolun',
			'New Auckland',
			'Rural View',
			'Sheidow Park',
			'Parkinson',
			'Halls Head',
			'Mount Melville',
			'St George Ranges',
			'Curtin',
			'Hemmant',
			'Middle Ridge',
			'Malvern',
			'Whyalla Norrie',
			'Willoughby East',
			'Greenslopes',
			'Morningside',
			'Thorneside',
			'Flemington',
			'Narre Warren South',
			'Rosebud West',
			'Cabramatta West',
			'Harris Park',
			'Picnic Point',
			'Albert Park',
			'Salisbury Plain',
			'Joondanna',
			'Woodbridge',
			'North Hobart',
			'East Bunbury',
			'Mermaid Waters',
			'Garden Suburb',
			'Bolwarra Heights',
			'Moffat Beach',
			'West Beach',
			'Rangeville',
			'South Toowoomba',
			'Queanbeyan East',
			'Culburra Beach',
			'Svensson Heights',
			'South Nowra',
			'Midway Point',
			'Twin Waters',
			'South Murwillumbah',
			'West Haven',
			'South Carnarvon',
			'Moorak',
			'Mount Compass',
			'Gawler',
			'Boddington',
			'Yenda',
			'Yarra Glen',
			'Westmead',
			'Wentworth Falls',
			'Wandin North',
			'Wallalong',
			'Victoria Point',
			'Tempe',
			'Temora',
			'Tathra',
			'Stanmore',
			'Somerville',
			'Smythesdale',
			'Silvan',
			'Sebastopol',
			'Penguin',
			'Nhill',
			'Mount Clear',
			'Monash',
			'Mansfield',
			'Mallacoota',
			'Camden Haven',
			'Kogarah',
			'Kew',
			'Karumba',
			'Kalimna',
			'Heddon Greta',
			'Hanwood',
			'Granton',
			'Gladstone',
			'Frankston',
			'Eaglehawk',
			'Doncaster',
			'Deagon',
			'Dalby',
			'Condobolin',
			'Cockatoo',
			'Cessnock',
			'Cardwell',
			'Capella',
			'Canberra',
			'Campbelltown',
			'Brewarrina',
			'Berridale',
			'Berkeley',
			'Beenleigh',
			'Barham',
			'Balwyn',
			'Austins Ferry',
			'Arcadia',
			'Allansford',
			'Towradgi',
			'New Farm',
			'Blairgowrie',
			'Burswood',
			'Auchenflower',
			'Chermside',
			'Ascot',
			'Blackburn',
			'Booval',
			'Eleebana',
			'Breakfast Point',
			'Yanchep',
			'Toodyay',
			'Denham',
			'Serpentine',
			'Rockingham',
			'Nullagine',
			'Mylor',
			'Moora',
			'Marion',
			'Maningrida',
			'Leonora',
			'Crafers',
			'Bridgewater',
			'Aldgate',
			'Wonga Park',
			'Windang',
			'Waurn Ponds',
			'Villawood',
			'Uralla',
			'Traralgon',
			'Thabeban',
			'Templestowe Lower',
			'Surry Hills',
			'Soldiers Point',
			'Saint Peters',
			'Rye',
			'Roseville',
			'Port Macquarie',
			'Port Fairy',
			'Pomona',
			'Pine Mountain',
			'Peak Hill',
			'Oakey',
			'Fitzroy North',
			'Noble Park',
			'Newcastle',
			'Mosman',
			'Mortlake',
			'Miners Rest',
			'Mildura',
			'Kyneton',
			'Keysborough',
			'Kawungan',
			'Ingleburn',
			'Hughenden',
			'Howlong',
			'Evandale',
			'Emerald',
			'Elderslie',
			'Edgecliff',
			'Deniliquin',
			'Davidson',
			'Charleville',
			'Cabarlah',
			'Bucasia',
			'Belmont',
			'Beaconsfield Upper',
			'Bankstown',
			'Ashgrove',
			'Aberglasslyn',
			'Abbotsford',
			'Woronora',
			'Kareela',
			'Slade Point',
			'Holloways Beach',
			'Ultimo',
			'Neutral Bay',
			'Currambine',
			'Chapel Hill',
			'Fitzgibbon',
			'Mermaid Beach',
			'Churchill',
			'Booral',
			'Chain Valley Bay',
			'Lennox Head',
			'Milingimbi',
			'Bonnyrigg Heights',
			'Denistone East',
			'Bourkelands',
			'Cherrybrook',
			'Old Guildford',
			'Elanora Heights',
			'Kapooka',
			'East Side',
			'Ottoway',
			'Rosetta',
			'Underdale',
			'Warradale',
			'Knoxfield',
			'Dean Park',
			'Greenacre',
			'Eden Hills',
			'Glen Forrest',
			'Johnston',
			'Minchinbury',
			'Panania',
			'Berrinba',
			'Viveash',
			'Burns Beach',
			'Annerley',
			'Hillvue',
			'Joyner',
			'Logan Central',
			'Soldiers Hill',
			'Runcorn',
			'Junction Village',
			'Warana',
			'Carine',
			'Lurnea',
			'Duncraig',
			'Chapman',
			'Dunlop',
			'Melba',
			'Bidwill',
			'Kirkwood',
			'Parafield Gardens',
			'Red Hill',
			'Joslin',
			'Mirrabooka',
			'Largs North',
			'Stafford',
			'Wilston',
			'Riverhills',
			'Waterford West',
			'Ferny Hills',
			'Samford Valley',
			'Essendon North',
			'Keilor East',
			'Coburg North',
			'Vermont South',
			'East Ryde',
			'Kensington Gardens',
			'Aberfoyle Park',
			'Reynella East',
			'Elizabeth Grove',
			'Elizabeth South',
			'Camden Park',
			'Mount Stuart',
			'Burleigh Waters',
			'Tuggerawong',
			'East Tamworth',
			'Newcastle city centre',
			'Risdon Park South',
			'West Lamington',
			'West Nowra',
			'Holland Park',
			'Gnangara',
			'Pooraka',
			'Ashby',
			'Southside',
			'St George',
			'Ivanhoe',
			'Richmond',
			'Teneriffe',
			'Thomson',
			'Darch',
			'Banks',
			'Booragoon',
			'Abbotsbury',
			'Halekulani',
			'Oakville',
			'Hove',
			'Junortoun',
			'Albert Park',
			'Minyama',
			'Goodwood',
			'Algester',
			'Kidman Park',
			'Willow Vale',
			'Melrose Park',
			'Mount Nasura',
			'Secret Harbour',
			'Wandal',
			'Millner',
			'Greenway',
			'Clinton',
			'Miandetta',
			'Valley View',
			'Mansfield Park',
			'Narrabundah',
			'Woodcroft',
			'Aldinga Beach',
			'East Geelong',
			'Nickol',
			'Seven Hills',
			'Arana Hills',
			'Croydon South',
			'Cranbourne North',
			'Burnside Heights',
			'Bondi Beach',
			'North Balgowlah',
			'Ramsgate Beach',
			'Strathfield South',
			'Crafers West',
			'Sefton Park',
			'Clarence Park',
			'Forestville',
			'Alkimos',
			'South Mackay',
			'Wilsonton Heights',
			'Adelaide city centre',
			'Geelong city centre',
			'Moore Park Beach',
			'Shepparton East',
			'Waterloo Corner',
			'Semaphore',
			'Roxby Downs',
			'Port Hedland',
			'Plympton',
			'Newman',
			'Mitcham',
			'Halls Creek',
			'Coober Pedy',
			'Caversham',
			'Augusta',
			'Armadale',
			'Quinns Rocks',
			'Bicton',
			'Attadale',
			'Nightcliff',
			'Wurruk',
			'Witta',
			'Wallsend',
			'Urangan',
			'Tully',
			'Torquay',
			'Toowong',
			'Thornton',
			'Thirlmere',
			'The Basin',
			'Sharon',
			'Rooty Hill',
			'Rathmines',
			'Port Kembla',
			'Plenty',
			'Pitt Town',
			'Penola',
			'Nunawading',
			'Naracoorte',
			'Mundubbera',
			'Moss Vale',
			'Moe',
			'McKinnon',
			'Lindfield',
			'Gympie',
			'Gulgong',
			'Gowrie Junction',
			'Gol Gol',
			'Glenview',
			'Emu Plains',
			'Elimbah',
			'Cumbalum',
			'Coorparoo',
			'Coolum Beach',
			'Coolah',
			'Coniston',
			'Charlton',
			'Canadian',
			'Blaxland',
			'Berwick',
			'Berriedale',
			'Barooga',
			'Bargo',
			'Atherton',
			'Ashburton',
			'Armadale',
			'Altona',
			'Sylvania Waters',
			'Blakehurst',
			'Fairy Meadow',
			'Norman Park',
			'Palmerston',
			'Enfield',
			'Hillarys',
			'Joondalup',
			'Clarkson',
			'Lutwyche',
			'Watanobbi',
			'Canterbury',
			'Grasmere',
			'Karana Downs',
			'Sippy Downs',
			'North Parramatta',
			'Macmasters Beach',
			'Robinvale',
			'Batehaven',
			'Bullaburra',
			'Casuarina',
			'Vasse',
			'Thebarton',
			'Port Augusta',
			'Perth',
			'Parap',
			'Marble Bar',
			'Lobethal',
			'Esperance',
			'Angaston',
			'Yungaburra',
			'Winton',
			'Winchelsea',
			'West Wyalong',
			'Wee Waa',
			'Waverton',
			'Wandoan',
			'Templestowe',
			'Sutton',
			'Strathfieldsaye',
			'Stratford',
			'Spring Hill',
			'Werribee South',
			'Sandringham',
			'Rutherglen',
			'Pyrmont',
			'Point Lonsdale',
			'Phillip',
			'Paynesville',
			'Old Bar',
			'Mount Colah',
			'Millgrove',
			'Maitland',
			'Maclean',
			'Longford',
			'Logan Village',
			'Lawson',
			'Kurri Kurri',
			'Kirribilli',
			'Kew',
			'Kerang',
			'Jindabyne',
			'Homebush',
			'Hill Top',
			'Henty',
			'Greta',
			'Granville',
			'Geeveston',
			'Frederickton',
			'Drysdale',
			'Croydon',
			'Corinda',
			'Coffs Harbour',
			'Broulee',
			'Bright',
			'Box Hill',
			'Blackmans Bay',
			'Benalla',
			'Ayr',
			'Aspendale',
			'Albany Creek',
			'Bonnet Bay',
			'Yorkeys Knob',
			'Greenwich',
			'Kaleen',
			'Kingsford',
			'Kinross',
			'Girraween',
			'Paradise',
			'Wooloowin',
			'Parkside',
			'Ferntree Gully',
			'Mollymook Beach',
			'Springwood',
			'Burwood',
			'Blackburn South',
			'Silkstone',
			'Yamanto',
			'Rothwell',
			'Coleambally',
			'Hope Island',
			'Pottsville Beach',
			'Leederville',
			'Endeavour Hills',
			'Annangrove',
			'Harrington Park',
			'Parkside',
			'Cowandilla',
			'Calista',
			'Tarcoola Beach',
			'Page',
			'Highland Park',
			'Usher',
			'Hillcrest',
			'South Hedland',
			'Queens Park',
			'Abercrombie',
			'Seville Grove',
			'Bardwell Park',
			'Carrara',
			'Newton',
			'Primbee',
			'Mount Sheridan',
			'Gowrie',
			'Lockleys',
			'Bertram',
			'Kingsley',
			'Kilburn',
			'Charnwood',
			'Blair Athol',
			'Carlton',
			'Oatlands',
			'Brunswick',
			'Mowbray',
			'Fisher',
			'Willmot',
			'California Gully',
			'Kambalda West',
			'Windsor Downs',
			'Robertson',
			'Burbank',
			'South Maclean',
			'Pascoe Vale South',
			'Kellyville Ridge',
			'Kemps Creek',
			'Kyle Bay',
			'North Bondi',
			'Walkley Heights',
			'Torrensville',
			'City Beach',
			'Dynnyrne',
			'Coal Point',
			'Cordeaux Heights',
			'Whittington',
			'Rockingham city centre',
			'South Kalgoorlie',
			'St Helena',
			'Mollymook',
			'Bungarribee',
			'Zuccoli',
			'Desert Springs',
			'Manningham',
			'Kotara South',
			'Stoneville',
			'Quorn',
			'Moana',
			'McLaren Vale',
			'Katanning',
			'Hamersley',
			'Cheltenham',
			'Clapham',
			'Yatala',
			'Wyreema',
			'West Tamworth',
			'Warburton',
			'Wantirna',
			'Vaucluse',
			'Upwey',
			'Ulladulla',
			'Toukley',
			'Tomakin',
			'Thornbury',
			'Thomastown',
			'Somers',
			'Saint Kilda',
			'Rosehill',
			'Rosanna',
			'Port Douglas',
			'Pakenham',
			'Newport',
			'Moura',
			'Mount Morgan',
			'Mooroolbark',
			'Malvern',
			'Leopold',
			'Kalorama',
			'Julatten',
			'Jannali',
			'Huonville',
			'Hawthorn',
			'Hastings',
			'Griffith',
			'Eltham North',
			'Dennington',
			'Cremorne',
			'Coonabarabran',
			'Cobden',
			'Clarence Town',
			'Charters Towers',
			'Caulfield North',
			'Campbellfield',
			'Buxton',
			'Burnie',
			'Broadford',
			'Blackwall',
			'Bendigo',
			'Bellingen',
			'Belconnen',
			'Ballarat East',
			'Aldavilla',
			'Brighton-Le-Sands',
			'Monterey',
			'Cammeray',
			'Point Piper',
			'Forestville',
			'Brookvale',
			'Athelstone',
			'Willoughby',
			'Smiths Lake',
			'Magnetic Island',
			'Docklands',
			'Shoalwater',
			'North Booval',
			'St Leonards',
			'Jacobs Well',
			'Buronga',
			'Gwandalan',
			'Mount Low',
			'Tascott',
			'Ashcroft',
			'Blue Haven',
			'Wonthella',
			'Erskine Park',
			'Larapinta',
			'Geebung',
			'Tanilba Bay',
			'Farrar',
			'Gwelup',
			'Springdale Heights',
			'Sunshine Bay',
			'Augustine Heights',
			'Trevallyn',
			'Mitchell',
			'Doubleview',
			'Wondunna',
			// 'Queenstown',
			'Carss Park',
			'Birrong',
			'Bardon',
			'Hamilton Hill',
			'Jamisontown',
			'Sinagra',
			'Niagara Park',
			'Spencer Park',
			'Turvey Park',
			'Munruben',
			'Griffin',
			'Wandi',
			'Carey Park',
			'Winthrop',
			'Kings Meadows',
			'Conder',
			'Isabella Plains',
			'Nicholls',
			'Kelso',
			'Heatley',
			'Ravenswood',
			'Aranda',
			'Rosebery',
			'Frankston North',
			'Ravenhall',
			'Fairlight',
			'Padstow Heights',
			'Stanhope Gardens',
			'Sydney Olympic Park',
			'North Brighton',
			'Hawthorn',
			'Royston Park',
			'Port Noarlunga South',
			'Millswood',
			'Clear Island Waters',
			'North Lambton',
			'East Toowoomba',
			'East Albury',
			'Mandurah city centre',
			'Killarney Vale',
			'East Devonport',
			'South Bathurst',
			'East Jindabyne',
			'Mount Kembla',
			'Woodcroft',
			'Roxburgh Park',
			'Long Gully',
			'Silverwater',
			'Para Hills',
			'Railway Estate',
			'Tamarama',
			'Athol Park',
			'Lakelands',
			'Carina Heights',
			'Barden Ridge',
			'Woodpark',
			'Birchgrove',
			'Campsie',
			'Dudley',
			'Emerton',
			'Kanahooka',
			'Kariong',
			'Alexandra Hills',
			'Langford',
			'Oxley Vale',
			'South Hurstville',
			'Mooroobool',
			'Lansvale',
			'Mount Riverview',
			'Herdsmans Cove',
			'Kilkenny',
			'Centennial Park',
			'Kearneys Spring',
			'Spence',
			'Graceville',
			'Hillbank',
			'Wulagi',
			'Casuarina',
			'City of Cockburn',
			'Ravenswood',
			'Yarrabilba',
			'Caboolture South',
			'Essendon West',
			'Williamstown North',
			'Blackett',
			'Glen Alpine',
			'Middleton Grange',
			'Old Toongabbie',
			'Seacliff Park',
			'Windsor Gardens',
			'Woodville Gardens',
			'Para Vista',
			'Salisbury Downs',
			'Brooklyn Park',
			'Rivervale',
			'Broadbeach Waters',
			'East Mackay',
			'South Yunderup',
			'Broadmeadow',
			'Mount Pleasant',
			'Wandana Heights',
			'Darling Heights',
			'Marangaroo',
			'Melbourne City Centre',
			'Yeppoon city centre',
			'East Bendigo',
			'West Ballina',
			'Waggrakine',
			'Two Wells',
			'Streaky Bay',
			'Strathalbyn',
			'Solomontown',
			'Quindalup',
			'Cowell',
			'Byford',
			'Beverley',
			'Bellevue',
			'Binningup',
			'Yeppoon',
			'Yarrawonga',
			'Woodridge',
			'Woodford',
			'White Rock',
			'Waitara',
			'Spotswood',
			'Rutherford',
			'Puckapunyal',
			'Prahran',
			'Pakenham Upper',
			'Orbost',
			'One Mile',
			'North Haven',
			'Moree',
			'Menai',
			'Melton',
			'Kurrajong Heights',
			'Jandowae',
			'Heidelberg',
			'Hay Point',
			'Gosford',
			'Frankston East',
			'Franklin',
			'Fitzroy',
			'Finley',
			'Eltham',
			'Elliott Heads',
			'East Ballina',
			'Currie',
			'Cobbitty',
			'Carlingford',
			'Carisbrook',
			'Buninyong',
			'Bundanoon',
			'Bordertown',
			'Berkeley Vale',
			'Benowa',
			'Baranduda',
			'Badger Creek',
			'Bacchus Marsh',
			'Ashfield',
			'Albion Park',
			'Yowie Bay',
			'Riverwood',
			'Goodwood',
			'Bellevue Hill',
			'Dover Heights',
			'Flinders',
			'Tom Price',
			'Brendale',
			'Keperra',
			'Beaconsfield',
			'Canning Vale',
			'Roselands',
			'Shoalhaven Heads',
			'Mount Coolum',
			'Northbridge',
			'Bouldercombe',
			'Palm Island',
			'Tennyson Point',
			'Safety Bay',
			'Pemberton',
			'Northam',
			'Nedlands',
			'Mount Barker',
			'Morphett Vale',
			'Grange',
			'Echunga',
			'Collie',
			'Jurien Bay',
			'Fannie Bay',
			'West Hoxton',
			'Werris Creek',
			'Wentworth',
			'Warwick',
			'Wallington',
			'Walcha',
			'Teesdale',
			'Surfers Paradise',
			'South Yarra',
			'Scoresby',
			'Rocklea',
			'Ringwood East',
			'Redbank',
			'Pymble',
			'Point Clare',
			'Picton',
			'Neerim South',
			'Narrandera',
			'Mount Cotton',
			'Moruya',
			'Moonah',
			'Molong',
			'Miles',
			'Marulan',
			'Macedon',
			'Lowood',
			'Lauderdale',
			'Kinglake West',
			'Hawthorn South',
			'Goulburn',
			'Gooburrum',
			'Glen Iris',
			'Gleneagle',
			'Gilgandra',
			'Forest Hill',
			'Fernvale',
			'Eglinton',
			'Echuca',
			'Doonside',
			'Culcairn',
			'Coolangatta',
			'Colo Vale',
			'Clermont',
			'Churchill',
			'Chelsea Heights',
			'Bunyip',
			'Bundaberg',
			'Brighton',
			'Boonah',
			'Bentleigh',
			'Balnarring',
			'Ararat',
			'Annandale',
			'Alphington',
			'Oyster Bay',
			'South Brisbane',
			'Laverton',
			'Agnes Water',
			'Toormina',
			'Kew East',
			'Silverdale',
			'Ningi',
			'Seabrook',
			'St Huberts Island',
			'Blackalls Park',
			'Tiwi',
			'Banksia Park',
			'Prairiewood',
			'Hunterview',
			'Fairview Park',
			'College Grove',
			'Prospect Vale',
			'Panorama',
			'Broadview',
			'Hermit Park',
			'Rosslyn Park',
			'Herne Hill',
			'Tranmere',
			'Cannon Hill',
			'Murrumba Downs',
			'Cameron Park',
			'Greenfield Park',
			'Mardi',
			'McMahons Point',
			'Mount Kuring-Gai',
			'Firle',
			'Summerland Point',
			'Buddina',
			'Marsden',
			'Maiden Gully',
			'Gidgegannup',
			'Ocean Reef',
			'Wakeley',
			'Cossack',
			'Bunya',
			'Munster',
			'Warwick',
			'Cranbourne East',
			'Killarney Heights',
			'Merrylands West',
			'Orchard Hills',
			'Virginia',
			'South Brighton',
			'Salisbury Heights',
			'Bedford',
			'Mount Pleasant',
			'Mackay City',
			'Bolton Point',
			'Black Mountain',
			'Albion Park Rail',
			'South Tamworth',
			'Bundaberg South',
			'Williamstown',
			'Port Pirie',
			'Pearce',
			'One Tree Hill',
			'North Beach',
			'Midland',
			'Medina',
			'Mannum',
			'Lower Chittering',
			'Lewiston',
			'Kingston South East',
			'Goolwa',
			'Derby',
			'Denmark',
			'Darlington',
			'Capel',
			'Broome',
			'Beaumont',
			'Wendouree',
			'Wellington Point',
			'Wallerawang',
			'Walgett',
			'Telarah',
			'Taree',
			'Sydney',
			'Swansea',
			'South Grafton',
			'Seymour',
			'Sandford',
			'Ross Creek',
			'Revesby',
			'Red Hill',
			'Raymond Terrace',
			'Parkes',
			'Nowra',
			'Narromine',
			'Moorabbin',
			'Moonee Beach',
			'Mittagong',
			'Merimbula',
			'Lovely Banks',
			'Koo-Wee-Rup',
			'Junee',
			'Grovedale',
			'Glenferrie',
			'Glenbrook',
			'Eumundi',
			'Esk',
			'Engadine',
			'Derrimut',
			'Daceyville',
			'Cundletown',
			'Berri',
			'Loftus',
			'Lilli Pilli',
			'Bangor',
			'Connells Point',
			'Bondi',
			'Corlette',
			'Como',
			'Taringa',
			'Mount Ommaney',
			'Bridgeman Downs',
			'Hoppers Crossing',
			'West Melbourne',
			'Doonan',
			'Shell Cove',
			'Box Hill North',
			'Golden Point',
			'Basin View',
			'Karalee',
			'Tannum Sands',
			'Turners Beach',
			'Surrey Downs',
			'Bow Bowing',
			'Googong',
			'Allenstown',
			'Woongarrah',
			'Glenside',
			'Highbury',
			'The Range',
			'Hyde Park',
			'Seaholme',
			'Surfside',
			'Blind Bight',
			'Dampier Peninsula',
			'Cape Woolamai',
			'Depot Hill',
			'The Gap',
			'Madeley',
			'North Ward',
			'Morphettville',
			'Gordon',
			'Ropes Crossing',
			'Wiley Park',
			'Crestmead',
			'Kepnock',
			'Meadowbrook',
			'Cockburn Central',
			'Hammond Park',
			'Hillman',
			'Yallambie',
			'Madora Bay',
			'Chigwell',
			'Shearwater',
			'Queens Park',
			'Bray Park',
			'Shelley',
			'Mornington',
			'Mission Beach',
			'Falls Creek',
			'Everton Park',
			'Eatons Hill',
			'Narraweena',
			'North Manly',
			'Revesby Heights',
			'Muirhead',
			'Royal Park',
			'Evanston Gardens',
			'Elizabeth East',
			'Modbury North',
			'Garran',
			'West Hobart',
			'Bar Beach',
			'Floraville',
			'Ashtonfield',
			'Peregian Springs',
			'Geraldton city centre',
			'North Nowra',
			'East Bairnsdale',
			'Albany city centre',
			'Swanbourne',
			'South Perth',
			'Seaford',
			'Kenwick',
			'Fitzroy Crossing',
			'Cardup',
			'Cannington',
			'Eaton',
			'Young',
			'Yackandandah',
			'Wynnum',
			'Woodford',
			'Wodonga',
			'Windsor',
			'Warragul',
			'Richmond',
			'Port Sorell',
			'Port Melbourne',
			'Palm Beach',
			'Nelson Bay',
			'Nabiac',
			'Muswellbrook',
			'Mount Gambier',
			'Morayfield',
			'Montmorency',
			'Little River',
			'Kootingal',
			'Jimboomba',
			'Ingham',
			'Holmesville',
			'Heidelberg West',
			'Healesville',
			'Gunnedah',
			'Gloucester',
			'Glenorchy',
			'Evans Head',
			'Epping',
			'Doncaster East',
			'Denman',
			'Cranley',
			'Coutts Crossing',
			'Corowa',
			'Cootamundra',
			'Cooranbong',
			'Cooktown',
			'Collingwood',
			'Collaroy',
			'Clyde North',
			'Castle Hill',
			'Canowindra',
			'Campbells Creek',
			'Bulli',
			'Buderim',
			'Bridgewater',
			'Blackall',
			'Bentleigh East',
			'Belgrave South',
			'Aldershot',
			'Lake Heights',
			'Hamilton',
			'Airlie Beach',
			'Biggera Waters',
			'Howrah',
			'Attwood',
			'Zillmere',
			'West End',
			'Highgate Hill',
			'Erskine',
			'Hawthorn East',
			'Chadstone',
			'Brighton East',
			'Hampton East',
			'Emerald Beach',
			'Bowen Mountain',
			'Gulmarrad',
			'Howard Springs',
			'Londonderry',
			'Oakdowns',
			'Cumberland Park',
			'Werrington',
			'Yarravel',
			'Turner',
			'Henley Beach South',
			'Avenell Heights',
			'Redan',
			'Maryville',
			'Ardross',
			'Youngtown',
			'Petersham',
			'Millbank',
			'Wishart',
			'Koondoola',
			'Booragul',
			'Casula',
			'Elizabeth Bay',
			'Tanah Merah',
			'Middle Cove',
			'North Avoca',
			'Glengowrie',
			'Battery Hill',
			'Golden Square',
			'Manunda',
			'Ninderry',
			'Risdon Park',
			'Wandina',
			'Wilsonton',
			'Sun Valley',
			'West Lakes',
			'Salter Point',
			'Riverview',
			'St Clair',
			'Tolland',
			'Grange',
			'Burnside',
			'Braybrook',
			'Mountain Creek',
			'Taperoo',
			'Bluewater',
			'Chermside West',
			'Yeronga',
			'Bayswater North',
			'Allambie Heights',
			'Cambridge Park',
			'Denham Court',
			'Lewisham',
			'Tusmore',
			'Elizabeth Downs',
			'Everard Park',
			'Eglinton',
			'Chittaway Bay',
			'Merewether Heights',
			'Jewells',
			'West Rockhampton',
			'Armstrong Creek',
			'North Albury',
			'Kalinga',
			'Mount Claremont',
			'North Boambee Valley',
			'Nowra Hill',
			'Caddens',
			'Kambalda East',
			'Nanum',
			'Sunshine Beach',
			'St Clair',
			'Wooroloo',
			'Port Willunga',
			'Port Adelaide',
			'Nairne',
			'Mount Barker',
			'Hackham',
			'Cottesloe',
			'Bridgetown',
			'Bakers Hill',
			'Woolgoolga',
			'Weetangera',
			'Wangaratta',
			'Trentham',
			'The Entrance',
			'Tenambit',
			'Tara',
			'Stanthorpe',
			'San Remo',
			'Sale',
			'Rhodes',
			'Redhead',
			'Potts Point',
			'Pearcedale',
			'Nicholson',
			'Narara',
			'Mount Pleasant',
			'Mount Eliza',
			'Mooroopna',
			'Macksville',
			'Lucknow',
			'Loxton',
			'Korumburra',
			'Hazelbrook',
			'Garfield',
			'Darlinghurst',
			'Corryong',
			'Brunswick West',
			'Bridport',
			'Boggabri',
			'Beauty Point',
			'Beaufort',
			'Beaconsfield',
			'Batlow',
			'Ballina',
			'Andergrove',
			'Fern Hill',
			'Bonbeach',
			'Crows Nest',
			'Paradise Point',
			'Tamborine Mountain',
			'Sanctuary Point',
			'Redbank Plains',
			'Woody Point',
			'Kurwongbah',
			'Cooloola Cove',
			'Gillieston Heights',
			'Lemon Tree Passage',
			'Ocean Shores',
			'Doveton',
			'Alfords Point',
			'Argenton',
			'Craigmore',
			'Berrimah',
			'Torrens Park',
			'Russell Lea',
			'Tennyson',
			'Whalan',
			'Botanic Ridge',
			'Orelia',
			'Green Valley',
			'Mission River',
			'Bellevue Heights',
			'Mornington',
			'Pioneer',
			'Paringa',
			'Darling Downs',
			'Westcourt',
			'Taranganba',
			'Brockman',
			'Putney',
			'Shelly Beach',
			'Aubin Grove',
			'Gunn',
			'St Morris',
			'Strathdale',
			'Forrestdale',
			'High Wycombe',
			'Port Kennedy',
			'Isaacs',
			'Kangaroo Point',
			'The Ponds',
			'Woodrising',
			'Pearce',
			'Gilmore',
			'St Peters',
			'Jesmond',
			'Rose Park',
			'Stafford Heights',
			'Sunnybank Hills',
			'Kenmore Hills',
			'North Maclean',
			'Noble Park North',
			'Sunshine North',
			'Balmain East',
			'Carnes Hill',
			'Gregory Hills',
			'Killara',
			'Kingswood Park',
			'South Coogee',
			'Erindale',
			'Hallett Cove',
			'Unley Park',
			'Kallaroo',
			'Watermans Bay',
			'Lower Beechmont',
			// 'Glendale',
			// 'Toronto',
			'Nulsen',
			'Summerhill',
			'Tweed Heads West',
			'Bowenfels',
			'Port Pirie South',
			'Metford',
			'Forde',
			'West Beach',
			'Bayview Heights',
			'Toorak Gardens',
			'Westlake',
			'Bateman',
			'Bulgarra',
			'St Albans Park',
			'Kooringal',
			'Oakhurst',
			'Clearview',
			'Delahey',
			'Ridleyton',
			'Butler',
			'Pennington',
			'Ellen Grove',
			'Claremont Meadows',
			'Chuwar',
			'Nollamara',
			'Liberty Grove',
			'Jandakot',
			'Milperra',
			'Narrawallee',
			'Mount Vernon',
			'North St Marys',
			'Birtinya',
			'Park Orchards',
			'Flora Hill',
			'Bellmere',
			'Bibra Lake',
			'Walkervale',
			'Granville',
			'Ngunnawal',
			'Hinchinbrook',
			'Valentine',
			'Queenton',
			'Shalvey',
			'Vincent',
			'McKail',
			'Marmion',
			'Duffy',
			'Forrest',
			'Yallourn North',
			'Croydon Hills',
			'Caringbah South',
			'Collaroy Plateau',
			'Darlington',
			'Dolls Point',
			'North Epping',
			'Wollstonecraft',
			'Karama',
			'Linden Park',
			'Noarlunga Downs',
			'Ferndale',
			'Henley Brook',
			'Jindalee',
			'The Junction',
			'Charters Towers City',
			'Townview',
			'Elizabeth Hills',
			'East Branxton',
			'Tennant Creek',
			'Maddington',
			'Gosnells',
			'Dunsborough',
			'Coromandel Valley',
			'Blackwood',
			'Birdwood',
			'Claremont',
			'Stuart Park',
			'Windsor',
			'Wentworthville',
			'Wahgunyah',
			'Upper Coomera',
			'Tumut',
			'Toogoolawah',
			'Tolga',
			'Strathpine',
			'Springvale South',
			'Snug',
			'Scone',
			'Scarness',
			'Saint Leonards',
			'Richmond',
			'Redland Bay',
			'Port Hacking',
			'Oberon',
			'Northcote',
			'Newport',
			'Nana Glen',
			'Moonbi',
			'Milton',
			'Lawnton',
			'Launceston',
			'Kyogle',
			'Kurnell',
			'Kangaroo Flat',
			'Huntly',
			'Glenorie',
			'Forster',
			'Deloraine',
			'Darlington Point',
			'Cringila',
			'Cornubia',
			'Colac',
			'Clifton',
			'Chippendale',
			'Chewton',
			'Camperdown',
			'Cambridge',
			'Cambewarra Village',
			'Burwood',
			'Bringelly',
			'Bourke',
			'Biloela',
			'Barmera',
			'McCrae',
			'Gladesville',
			'Osborne Park',
			'Arncliffe',
			'Mango Hill',
			'Zilzie',
			'Canterbury',
			'Peregian Beach',
			'McGraths Hill',
			'Lake Munmorah',
			'Ngukurr',
			'Calala',
			'Glenalta',
			'Yalyalup',
			'East Kurrajong',
			'Idalia',
			'Centenary Heights',
			'Pagewood',
			'Voyager Point',
			'Dharruk',
			'Banksia Grove',
			"O'Connor",
			'Ashford',
			'Kanimbla',
			'Piccadilly',
			'Redwood Park',
			'North Haven',
			'Park Holme',
			'Birmingham Gardens',
			'Meadow Springs',
			'Bligh Park',
			'Eagle Vale',
			'Edgeworth',
			'Wakerley',
			'Yarrabah',
			'Glandore',
			'Hurlstone Park',
			'Middle Park',
			'Calamvale',
			'Enoggera',
			'Forest Lodge',
			'Paralowie',
			'Banjup',
			'Edgewater',
			'Success',
			'Evatt',
			'Cabarita',
			'Jindalee',
			'Riverton',
			'Hillcrest',
			'Stirling',
			'Bald Hills',
			'Kiama Downs',
			'Springfield',
			'Logan Reserve',
			'Dandenong North',
			'Berowra Heights',
			'Castle Cove',
			'Dundas Valley',
			'Hazelwood Park',
			'Gawler East',
			'Trott Park',
			'Clarence Gardens',
			'Marleston',
			'Brabham',
			'Tallebudgera Valley',
			'Arcadia vale',
			'Mayfield',
			'Cardiff South',
			'Windale',
			'Mount Louisa',
			'Avondale',
			'East Corrimal',
			'Monkland',
			'South Launceston',
			'North Toowoomba',
			'Hobart city centre',
			'Wollongong city centre',
			'Woodville',
			'Wiluna',
			'Scarborough',
			'Maida Vale',
			'Mount Isa',
			'Middleton',
			'Meadows',
			'Jarrahdale',
			'Happy Valley',
			'Encounter Bay',
			'Dampier',
			'Angle Vale',
			'Shenton Park',
			'Warnbro',
			'Mullaloo',
			'Glen Iris',
			'Yea',
			'Worongary',
			'Wilton',
			'The Rock',
			'Sorrento',
			'Selby',
			'Pokolbin',
			'Notting Hill',
			'Narooma',
			'Narellan',
			'Nambucca Heads',
			'Murrumbeena',
			'Mount Waverley',
			'Monto',
			'Mitcham',
			'Miranda',
			'Marong',
			'Maldon',
			'Lightning Ridge',
			'Lake Cargelligo',
			'Katoomba',
			'Highett',
			'Heywood',
			'Hawks Nest',
			'Hatton Vale',
			'Haddon',
			'Greensborough',
			'Glenfield',
			'Gayndah',
			'Freshwater',
			'Euroa',
			'Eden',
			'East Gosford',
			'Drayton',
			'Clyde',
			'Childers',
			'Carlton North',
			'Bywong',
			'Burradoo',
			'Branxton',
			'Botany',
			'Belgrave',
			'Beerwah',
			'Bayswater',
			'Bamaga',
			'Aspley',
			'Abermain',
			'Morpeth',
			'New Lambton',
			'Little Bay',
			'Mount Saint Thomas',
			'Lilyfield',
			'Longueville',
			'Dee Why',
			'Sydney Central Business District',
			'Ballarat Central',
			'Rozelle',
			'Caravonica',
			'Withcott',
			'Glossodia',
			'Malua Bay',
			'Adamstown Heights',
			'Nailsworth',
			'East Killara',
			'Tanunda',
			'Kingscote',
			'Greenmount',
			'Ludmilla',
			'Woree',
			'Wesburn',
			'Wauchope',
			'Wallan',
			'Urunga',
			'Trangie',
			'Tocumwal',
			'Terranora',
			'Sutherland',
			'Smithton',
			'Slacks Creek',
			'Shellharbour',
			'Proserpine',
			'Phillip',
			'Park Ridge',
			'Ormiston',
			'Morwell',
			'Minden',
			'Millmerran',
			'Metung',
			'Loganholme',
			'Lake Haven',
			'Kiama',
			'Kenthurst',
			'Hurstville Grove',
			'Harrington',
			'Hadspen',
			'Glebe',
			'Faulconbridge',
			'Fairfield',
			'Emerald',
			'Elliminyt',
			'Dimbulah',
			'Crookwell',
			'Crescent Head',
			'Cranbourne',
			'Cowra',
			'Cowes',
			'Coonamble',
			'Cobram',
			'Caringbah',
			'Camp Mountain',
			'Bundamba',
			'Broadbeach',
			'Bowraville',
			'Bomaderry',
			'Beechworth',
			'Barnsley',
			'Bannockburn',
			'Balgowlah',
			'Burraneer',
			'Ramsgate',
			'Padstow',
			'Rose Bay',
			'Cremorne',
			'Inala',
			'Ferny Grove',
			'Vincentia',
			'Parramatta',
			'Hollywell',
			'Mont Albert North',
			'Delacombe',
			'Estella',
			'East Perth',
			'Turrella',
			'Kellyville',
			'Cromer',
			'Frenchs Forest',
			'Merriwa',
			'Princes Hill',
			'Glen Eden',
			'Manoora',
			'Menzies',
			'Ascot Park',
			'Merrimac',
			'Currajong',
			'Singleton',
			'Kambah',
			'Edens Landing',
			'Theodore',
			'Newtown',
			'Elanora',
			'Ashmore',
			'Colyton',
			'Islington',
			'Jerrabomberra',
			'Kilaben Bay',
			'Withers',
			'Lalor Park',
			'Leonay',
			'Narwee',
			'Speers Point',
			'Broadwater',
			'Sinnamon Park',
			'Lake Macdonald',
			'Dinner Plain',
			'Newtown',
			'Driver',
			'Kewdale',
			'Battery Point',
			'Humpty Doo',
			'Malak',
			'Florey',
			'Gungahlin',
			'Harrison',
			'Camira',
			'Huntingdale',
			'Scullin',
			'Sadadeen',
			'Hughes',
			'Bondi Junction',
			'Boambee East',
			'Keilor Downs',
			'Kogarah Bay',
			'Parklea',
			'Rodd Point',
			'Bellamack',
			'Evanston Park',
			'Hackham West',
			'Elizabeth Park',
			'Smithfield Plains',
			'Coombs',
			'Eden Hill',
			'West Moonah',
			'South Bunbury',
			'Bundaberg West',
			'Fern Bay',
			'Norman Gardens',
			'Ridgehaven',
			'Manifold Heights',
			'Junction Hill',
			'Oakden',
			'Willetton',
			'Coodanup',
			'Annandale',
			'Innaloo',
			'Christie Downs',
			'Ironbark',
			'Cartwright',
			'Chester Hill',
			'Pearsall',
			'Beachlands',
			'Lynwood',
			'Telina',
			'Albanvale',
			'Warners Bay',
			'Garbutt',
			'Dalyellup',
			'Murdoch',
			'Samson',
			'Jingili',
			'Felixstow',
			'Braddon',
			'Bradbury',
			'Norwood',
			'Flynn',
			'Bonogin',
			'Mawson Lakes',
			'Melville',
			'Somerville',
			'West End',
			'Victoria Park',
			'North Richmond',
			'Forest Lake',
			'Richlands',
			'Keilor Park',
			'Constitution Hill',
			'Ermington',
			'Macquarie Links',
			'Woronora Heights',
			'Hectorville',
			'Salisbury North',
			'Hyde Park',
			'Crawley',
			'Embleton',
			'Waratah',
			'Belmont South',
			'Hamlyn Heights',
			'Bathurst city centre',
			'Jordan Springs',
			'Hazelwood North',
			'South Granville',
			'Wembley',
			'Tailem Bend',
			'Salisbury',
			'Prospect',
			'Mosman Park',
			'Minlaton',
			'Laverton',
			'Kalbarri',
			'Fremantle',
			'Warrawee',
			'Wallacia',
			'Thornleigh',
			'Thornlands',
			'Strathfield',
			'Smythes Creek',
			'Saint Albans',
			'Ranelagh',
			'Petrie',
			'Old Beach',
			'North Wonthaggi',
			'Nambucca',
			'Murrumbateman',
			'Murgon',
			'Mudgeeraba',
			'Mount Pleasant',
			'Merrylands',
			'Marian',
			'Lysterfield',
			'Kingsthorpe',
			'Jindera',
			'Hornsby',
			'Harristown',
			'Hampton',
			'Gundagai',
			'Gracemere',
			'Gold Coast',
			'Glenroy',
			'Gembrook',
			'Eight Mile Plains',
			'East Maitland',
			'Doreen',
			'Devonport',
			'Dayboro',
			'Crib Point',
			'Collinsville',
			'Wollongong',
			'Chelsea',
			'Castlecrag',
			'Cardiff',
			'Caloundra',
			'Brunswick Heads',
			'Brookfield',
			'Austinmer',
			'Auburn',
			'Asquith',
			'Albury',
			'Aitkenvale',
			'Lambton',
			'Gymea Bay',
			'Niddrie',
			'Heyfield',
			'Salamander Bay',
			'Macgregor',
			'Medindie',
			'Highfields',
			'Hocking',
			'White Gum Valley',
			'Yakamia',
			'Taylors Lakes',
			'Razorback',
			'Beeliar',
			'King Creek',
			'St Andrews',
			'Bundall',
			'Watson',
			'Cabramatta',
			'Cecil Hills',
			'Hebersham',
			'Kensington Grove',
			'Eastlakes',
			'Rosslea',
			'Castletown',
			'Lockridge',
			'Bentley Park',
			'Saratoga',
			'Wyoming',
			'Park Avenue',
			'Earlville',
			'Brookwater',
			'Highvale',
			'Robina',
			'The Gemfields',
			'Woodend',
			'Middle Park',
			'Wurtulla',
			'Fulham Gardens',
			'Winston',
			'Tuart Hill',
			'Thornlie',
			'Deakin',
			'Hackett',
			'Richardson',
			'Regents Park',
			'Waterford',
			'Jane Brook',
			'Patterson Lakes',
			'Lammermoor',
			'Whyalla Playford',
			'Blacksmiths',
			'Meridan Plains',
			'Christies Beach',
			'Mansfield',
			'Yeerongpilly',
			'Fairfield',
			'Heidelberg Heights',
			'Warranwood',
			'Belfield',
			'Beverley Park',
			'Colebee',
			'Newington',
			'Woodville North',
			'Mount Nathan',
			'Caloundra West',
			'Lake Wendouree',
			'South Albury',
			'Garden Island',
			'Katherine East',
			'West Gladstone',
			'Trinity Park',
			'Willaston',
			'Wayville',
			'Waikerie',
			'Utakarra',
			'Swan View',
			'Robe',
			'Port Broughton',
			'North Fremantle',
			'Largs Bay',
			'Port Denison',
			'Ceduna',
			'Carnarvon',
			'Busselton',
			'Burra',
			'Boyanup',
			'Yarram',
			'Westcourt',
			'Watsonia',
			'Warrnambool',
			'Wagga Wagga',
			'Wacol',
			'Tarragindi',
			'Taroona',
			'Smithfield',
			'Shepparton',
			'Scottsdale',
			'Ravenshoe',
			'Penshurst',
			'Parkville',
			'Ormond',
			'Nanango',
			'Murwillumbah',
			'Mossman',
			'Moorooduc',
			'Mapleton',
			'Maddingley',
			'Mackay',
			'Longwarry',
			'Lalor',
			'Kilsyth',
			'Kensington',
			'Kahibah',
			'Inverell',
			'Home Hill',
			'Hay',
			'Goondiwindi',
			'Foster',
			'Forest Hill',
			'Diamond Creek',
			'Deception Bay',
			'Dandenong',
			'Cygnet',
			'Chiltern',
			'Camberwell',
			'Bulahdelah',
			'Briagolong',
			'South Bowenfels',
			'Beaconsfield',
			'Bakers Creek',
			'Avenel',
			'Gymea',
			'Yarrawarrah',
			'Taren Point',
			'Hunters Hill',
			'Curl Curl',
			'Moranbah',
			'Maidstone',
			'Seven Mile Beach',
			'Kenmore',
			'Main Beach',
			'Banyo',
			'Taigum',
			'Caroline Springs',
			'Burrill Lake',
			'St. Georges Basin',
			'Balwyn North',
			'Waterview Heights',
			'Bateau Bay',
			'Dysart',
			'Glenfield Park',
			'Tighes Hill',
			'Bossley Park',
			'Wadalba',
			'Daw Park',
			'Whitsundays',
			'Moulden',
			'Wundowie',
			'Willunga',
			'Normanville',
			'Mount Helena',
			'Gooseberry Hill',
			'Exmouth',
			'Larrakeyah',
			'Yarragon',
			'Yamba',
			'Wyong',
			'Wynyard',
			'Woonona',
			'Walkerston',
			'Vineyard',
			'Valla Beach',
			'Ulverstone',
			'Tumbi Vmbi',
			'Toowoomba',
			'Tomerong',
			'Tin Can Bay',
			'Strathmerton',
			'Shorncliffe',
			'Saint Leonards',
			'Pendle Hill',
			'Parkhurst',
			'Mount Martha',
			'Mount Evelyn',
			'Mortlake',
			'Monbulk',
			'Menangle',
			'Maribyrnong',
			'Leeton',
			'Kleinton',
			'Keilor',
			'Irymple',
			'Inverleigh',
			'Hobart',
			'Hillston',
			'Greenwell Point',
			'Grafton',
			'Glen Waverley',
			'Gisborne',
			'Gerringong',
			'Eimeo',
			'Eden Park',
			'D’Aguilar',
			'Curra',
			'Crows Nest',
			'Coolamon',
			'Clayton',
			'Castlemaine',
			'Bucca',
			'Boronia',
			'Bombala',
			'Blacks Beach',
			'Blackheath',
			'Benaraby',
			'Bega',
			'Beaudesert',
			'Bargara',
			'Balgownie',
			'Aberdare',
			'Mayfield',
			'Kirrawee',
			'Peakhurst',
			'Lutana',
			'Figtree',
			'Bellara',
			'Drummoyne',
			'Clifton Beach',
			'Middlemount',
			'Freshwater',
			'Milton',
			'Canada Bay',
			'West Gosford',
			'Clontarf',
			'Kippa-Ring',
			'Bushland Beach',
			'Dundowran Beach',
			'Innes Park',
			'Woorree',
			'Busby',
			'Kings Park',
			'Ourimbah',
			'Warilla',
			'Kialla',
			'Jackass Flat',
			'Bluff Point',
			'Edwardstown',
			"O'Sullivan Beach",
			'Douglas',
			'Alexander Heights',
			'Mount Warren Park',
			'Geilston Bay',
			'Netherby',
			'Hawthorndene',
			'Fraser',
			'Mount Lofty',
			'Condell Park',
			'Heckenberg',
			'Sunset',
			'Meadow Heights',
			'Torrens',
			'Stirling North',
			'Baldivis',
			'Churchlands',
			'Rangeway',
			'Blackstone Heights',
			'Llanarth',
			'Durack',
			'Leichhardt',
			'Wattle Grove',
			'Waverley',
			'Latham',
			'Lyons',
			'Salisbury',
			'Beecroft',
			'Gordon Park',
			'Cedar Vale',
			'Park Ridge South',
			'Concord West',
			'Brompton',
			'North Strathfield',
			'Werrington County',
			'Leabrook',
			'South Lake',
			'West Leederville',
			'Lakelands',
			'Diddillibah',
			'North Tamworth',
			'West Albury',
			'Launceston city centre',
			'Tweed Heads South',
			'Bundaberg North',
			'Queanbeyan West',
			'Kosciuszko National Park',
			'Farrer',
			'Lyneham',
			'Bellfield',
			'Jamboree Heights',
			'Netley',
			'Croydon Park',
			'Booie',
			'Bungalow',
			'Aberfeldie',
			'Mount Pleasant',
			'Rossmoyne',
			'Bonnyrigg',
			'Beacon Hill',
			'Beverly Hills',
			'Arundel',
			'Marrara',
			'New Beith',
			'Hammondville',
			'Horningsea Park',
			'Alawa',
			'Mayfield East',
			'Kedron',
			'Brinsmead',
			'Hayborough',
			'Yarrambat',
			'Rosewater',
			'Warner',
			'Gulfview Heights',
			'Tallai',
			'Djugun',
			'Coconut Grove',
			'Coppabella',
			'Bayswater',
			'Griffith',
			'Rangewood',
			'Burton',
			'Orana',
			'Wyee Point',
			'Risdon Vale',
			'Nathan',
			'Seventeen Mile Rocks',
			'Berala',
			'Hornsby Heights',
			'Mount Lewis',
			'Wheeler Heights',
			'Allenby Gardens',
			'Glenelg East',
			'Plympton Park',
			'North Plympton',
			'Midvale',
			'Myaree',
			'Charmhaven',
			'Dicky Beach',
			'Koonawarra',
			'South Lismore',
			'New Gisborne',
			'Holroyd',
			'Wattle Ponds',
			'Moonta Bay',
			'Kwinana',
			'Dongara',
			'Balhannah',
			'Seacliff',
			'Windaroo',
			'West Pymble',
			'Wangi Wangi',
			'Turramurra',
			'Tuncurry',
			'Tumbarumba',
			'Springwood',
			'Sheffield',
			'Sawtell',
			'Sarina',
			'St Helens',
			'Riverview',
			'Research',
			'Northmead',
			'Normanhurst',
			'Newmarket',
			'Nagambie',
			'Mornington',
			'Maudsland',
			'Margate',
			'Manly',
			'Lower Plenty',
			'Kotara',
			'Kingston Beach',
			'Kensington',
			'Karuah',
			'Jamberoo',
			'Inverloch',
			'Haymarket',
			'Greenvale',
			'Greenbank',
			'Galston',
			'Donvale',
			'Coburg',
			'Clunes',
			'Chatswood',
			'Cedar Grove',
			'Caulfield East',
			'Carrum',
			'Cairns',
			'Burpengary',
			'Balaclava',
			'Alton Downs',
			'Alexandra',
			'Aberdeen',
			'Grays Point',
			'Northbridge',
			'Darling Point',
			'Woollahra',
			'East Lismore',
			'Nudgee',
			'Carseldine',
			'Glenning Valley',
			'Kurunjang',
			'Altona North',
			'Mannering Park',
			'Ashmont',
			'Marks Point',
			'Menora',
			'Naremburn',
			'Brentwood',
			'Collinswood',
			'Greenacres',
			'Davoren Park',
			'Cotswold Hills',
			'Kalkie',
			'Brahma Lodge',
			'Atwell',
			'Helena Valley',
			'Chifley',
			'Adamstown',
			'Canton Beach',
			'Belmore',
			'Carwoola',
			'Kent Town',
			'Erskineville',
			'Connolly',
			'Willagee',
			'Giralang',
			'Stratton',
			'Oxley Park',
			'Point Frederick',
			'Ruse',
			'Coral Cove',
			'Kealba',
			'Viewbank',
			'McDowall',
			'Flagstaff Hill',
			'Whitfield',
			'Westbourne Park',
			'Cable Beach',
			'Cloverdale',
			'Hannans',
			'Morley',
			'Mount Hawthorn',
			'Rivett',
			'Chisholm',
			'Holder',
			'Glenvale',
			'St Johns Park',
			'Springvale',
			'Holden Hill',
			'Oxley',
			'Melrose Park',
			'Skye',
			'North Adelaide',
			'Banksia',
			'Koomooloo',
			'Werrington Downs',
			'Wattle Park',
			'South Plympton',
			'Alberton',
			'Acton',
			'Coolbinia',
			'Noraville',
			'Cooks Hill',
			'North Geelong',
			'East Carnarvon',
			'Brisbane central business district',
			'Bendigo city centre',
			'Cairns North',
			'Port Pirie West',
			'Bundaberg East',
			'West Bathurst',
			'Upper Burnie',
			'Wellard',
			'Waroona',
			'Smithfield',
			'Roleystone',
			'Northfield',
			'Kelmscott',
			'Kadina',
			'Brighton',
			'Birkenhead',
			'Bentley',
			'Balaklava',
			'Alice Springs',
			'Yarra Junction',
			'Woori Yallock',
			'Werribee',
			'Truganina',
			'The Patch',
			'Terry Hills',
			'Teralba',
			'Tarneit',
			'Tamborine',
			'Tahmoor',
			'Table Top',
			'Sydenham',
			'Seville',
			'Salt Ash',
			'Rushworth',
			'Ringwood North',
			'Plainland',
			'Pialba',
			'Parkdale',
			'Nyngan',
			'Nome',
			'Myrtleford',
			'Minto',
			'Lockhart',
			'Legana',
			'Lavington',
			'Laidley',
			'Kempsey',
			'Keith',
			'Jilliby',
			'Jerilderie',
			'Holbrook',
			'Helidon',
			'Green Point',
			'Gaythorne',
			'Eudlo',
			'Diggers Rest',
			'Croydon',
			'Clayton South',
			'Clarinda',
			'Carnegie',
			'Campbell',
			'Brandon',
			'Belrose',
			'Barraba',
			'Barcaldine',
			'Hawthorne',
			'Scarborough',
			'Varsity Lakes',
			'Elwood',
			'Oakleigh South',
			'Dodges Ferry',
			'River Heads',
			'Sorrento',
			'Alice River',
			'Sefton',
			'Strathalbyn',
			'Ross',
			'Llandilo',
			'Canley Vale',
			'Wanguri',
			'York',
			'Wanneroo',
			'Tumby Bay',
			'Tea Tree Gully',
			'Spearwood',
			'Port Augusta West',
			'O’Halloran Hill',
			'Kalgoorlie',
			'Herne Hill',
			'Findon',
			'Bedfordale',
			'Adelaide',
			'Drummond Cove',
			'Yandina',
			'Woolloomooloo',
			'Westbrook',
			'Weipa',
			'Warrane',
			'Virginia',
			'Vermont',
			'Tooradin',
			'The Rocks',
			'Tenterfield',
			'Southport',
			'Rowville',
			'Rokeby',
			'Renmark West',
			'Quakers Hill',
			'Penrith',
			'Oxley',
			'Olinda',
			'Oakleigh East',
			'Numurkah',
			'Nebo',
			'Nambour',
			'Mulwala',
			'Mount Dandenong',
			'Morisset',
			'Merbein',
			'Loganlea',
			'Lang Lang',
			'Kingston',
			'Kanwal',
			'Ivanhoe East',
			'Heatherton',
			'Glengarry',
			'Gatton',
			'Ferny Creek',
			'Emu Park',
			'Empire Bay',
			'Dural',
			'Dunedoo',
			'Dapto',
			'Dakabin',
			'Corrimal',
			'Cooma',
			'Concord',
			'Cannonvale',
			'Camperdown',
			'Camden',
			'Byron Bay',
			'Burleigh Heads',
			'Bulleen',
			'Blayney',
			'Belgrave Heights',
			'Gwynneville',
			'North Narrabeen',
			'Woolloongabba',
			'Dutton Park',
			'Fairfield West',
			'Forresters Beach',
			'Macquarie',
			'Glenmore Park',
			'Suffolk Park',
			'Brookfield',
			'Alligator Creek',
			'Callala Bay',
			'Launching Place',
			'Yaroomba',
			'Belmont',
			'Little Grove',
			'Warragamba',
			'Ashbury',
			'Clarendon Vale',
			'Russell Vale',
			'Lynbrook',
			'Hillcrest',
			'Colonel Light Gardens',
			'Magill',
			'Brinkin',
			'Cashmere',
			'Andrews Farm',
			'Stonyfell',
			'Burnside',
			'Aveley',
			'Munno Para West',
			'Herbert',
			'Pasadena',
			'Glendalough',
			'Bilingurr',
			'Kin Kora',
			'Champion Lakes',
			'Farmborough Heights',
			'Greenfields',
			'Claymore',
			'Branyan',
			'Hewett',
			'Bellbird Park',
			'Fletcher',
			'Eaglemont',
			'Martin',
			'Rocherlea',
			'Waramanga',
			'Northgate',
			'Wavell Heights',
			'Kingston',
			'Bruce',
			'Kamerunga',
			'Carrolls Creek',
			'Pullenvale',
			'Sandhurst',
			'Kingsville',
			'Camden South',
			'Emu Heights',
			'Guildford West',
			'Lane Cove West',
			'Sandringham',
			'Woodville West',
			'Wright',
			'Harrisdale',
			'Fennell Bay',
			'Bardia',
			'Pottsville',
			'North Gosford',
			'West Ulverstone',
			'Enfield',
			'Onslow',
			'Narrogin',
			'Modbury',
			'Manjimup',
			'Macclesfield',
			'Jamestown',
			'Hahndorf',
			'Gilles Plains',
			'Craigburn Farm',
			'Coogee',
			'Campbelltown',
			'Bullsbrook',
			'Australind',
			'Ardrossan',
			'Dalkeith',
			'Woy Woy',
			'Woombye',
			'Wollert',
			'Warren',
			'Tyabb',
			'Torquay',
			'Toorak',
			'Tinonee',
			'Sassafras',
			'Rosedale',
			'Queenscliff',
			'Point Cook',
			'Peachester',
			'Oakhurst',
			'Maleny',
			'Macleod',
			'Luddenham',
			'Lorn',
			'Longreach',
			'Long Jetty',
			'Lindisfarne',
			'Leongatha',
			'Kilmore',
			'Kennington',
			'Heathcote',
			'Gordon',
			'Gordon',
			'Goonellabah',
			'Glen Huntly',
			'Glenhaven',
			'Glass House Mountains',
			'Elsternwick',
			'East Hills',
			'Coldstream',
			'Cohuna',
			'Bungendore',
			'Brunswick',
			'Bonville',
			'Beveridge',
			'Berowra',
			'Bensville',
			'Anglesea',
			'Alexandria',
			'Abbotsford',
			'Invermay',
			'Warrawong',
			'Norlane',
			'Clontarf',
			'Kooralbyn',
			'Mindarie',
			'Bowen Hills',
			'Nundah',
			'Goolwa Beach',
			'Rouse Hill',
			'Malvern East',
			'Black Rock',
			'Aspendale Gardens',
			'Deebing Heights',
			'St Kilda West',
			'Gagebrook',
			'Cherbourg',
			'Nelly Bay',
			'Cook',
			'Wetherill Park',
			'Wentworth Point',
			'Kurralta Park',
			'Noranda',
			'Rasmussen',
			'Dover Gardens',
			'Molendinar',
			'St Agnes',
			'Carlisle',
			'Osborne',
			'Airds',
			'Berkshire Park',
			'Canley Heights',
			'Castlereagh',
			'Chiswick',
			'Hoxton Park',
			'Norville',
			'Mount Pritchard',
			'Tootgarook',
			'Bell Post Hill',
			'Currans Hill',
			'Gulliver',
			'Fig Tree Pocket',
			'Georges Hall',
			'Willowbank',
			'Kearns',
			'Dianella',
			'Forrestfield',
			'Yangebup',
			'Mount Austin',
			'Bonython',
			'Blackbutt',
			'Woodbine',
			'Millstream',
			'The Vines',
			'Cairnlea',
			'Cranbourne South',
			'Rochedale South',
			'Hassall Grove',
			'Homebush West',
			'Hurstville',
			'Glenelg North',
			'Seacombe Heights',
			'Marden',
			'Hilbert',
			'South Guildford',
			'Elermore Vale',
			'Cairns City',
			'Gladstone Central',
			'Enmore',
			'Yallingup',
			'Whyalla',
			'Stirling',
			'Old Noarlunga',
			'Mundijong',
			'Lyndoch',
			'Bunbury',
			'Albany',
			'East Fremantle',
			'Yarraville',
			'Wyongah',
			'Wulkuraka',
			'Woorim',
			'Wattleglen',
			'Tea Gardens',
			'Surrey Hills',
			'Sunbury',
			'Spreyton',
			'Singleton',
			'Sandgate',
			'Rosewood',
			'Romsey',
			'Robertson',
			'Reservoir',
			'Portarlington',
			'Pimpama',
			'Ormeau',
			'Nimbin',
			'Narrabeen',
			'Mulgrave',
			'Mount Crosby',
			'South Morang',
			'Mitchell',
			'Mill Park',
			'Mernda',
			'Melton South',
			'Maryborough',
			'Marrickville',
			'Manilla',
			'Liverpool',
			'Kuranda',
			'Harlaxton',
			'Hamilton',
			'Gin Gin',
			'Dromana',
			'Craigieburn',
			'Corio',
			'Coraki',
			'Caulfield',
			'Cambooya',
			'Brighton',
			'Blacktown',
			'Birkdale',
			'Berrigan',
			'Belmont',
			'Bahrs Scrub',
			'Babinda',
			'Avoca',
			'Albion',
			'Lugarno',
			'Mount Ousley',
			'Mangerton',
			'Palm Cove',
			'Fairfield',
			'Alexandra Headland',
			'North Lakes',
			'Carindale',
			'Macarthur',
			'St Kilda East',
			'Mount Annan',
			'Flinders View',
			'Newport',
			'Bogangar',
			'Craignish',
			'Gelorup',
			'Anula',
			'Mitchell Park',
			'Wurrumiyanga',
			'Warwick Farm',
			'Waterways',
			'Merewether',
			'Heathwood',
			'Baynton',
			'Seacombe Gardens',
			'Beresford',
			'North Lake',
			'Geographe',
			'Gilberton',
			'Catalina',
			'Caves Beach',
			'Blue Bay',
			'Leda',
			'Callaghan',
			'Berserker',
			'Yagoona',
			'Gillen',
			'Haven',
			'Hotham Heights',
			'Jacana',
			'Westdale',
			'Forestdale',
			'Chirnside Park',
			'Macgregor',
			'Runaway Bay',
			'Bayonet Head',
			'Karrinyup',
			'Waikiki',
			'Mount Hutton',
			'Yokine',
			'Lenah Valley',
			'Lockyer',
			'Fulham',
			'Franklin',
			'Currimundi',
			'Parramatta Park',
			'Millbridge',
			'Mulambin',
			'Balmoral',
			'Camp Hill',
			'Manly West',
			'Oak Park',
			'Langwarrin South',
			'West Footscray',
			'Bilgola Plateau',
			'Chatswood West',
			'Edmondson Park',
			'Elizabeth Vale',
			'Semaphore South',
			'Daglish',
			'Dayton',
			'Hilton',
			'Mayfield West',
			'Barrack Heights',
			'West Busselton',
			'Glenelg South',
			'Corindi Beach',
			'Barney Point',
		],
	},
	Austria: {
		country: 'Austria',
		cities: [
			'Wörschach',
			'Wolfurt',
			'Weyer',
			'Wettmannstätten',
			'Wartberg an der Krems',
			'Waidhofen an der Thaya',
			'Vöcklabruck',
			'Vils',
			'Utschtal',
			'Grünburg',
			'Trumau',
			'Strengberg',
			'Strassen',
			'Hall in Tirol',
			'Sieghartskirchen',
			'Schöndorf',
			'Schlierbach',
			'Schladming',
			'Sankt Stefan ob Leoben',
			'Sankt Martin an der Raab',
			'Sankt Margareten im Rosental',
			'Sankt Lorenz',
			'Sankt Katharein an der Laming',
			'Sankt Gallen',
			'Röschitz',
			'Rohrbach an der Lafnitz',
			'Raasdorf',
			'Podersdorf am See',
			'Pichling bei Köflach',
			'Pichl bei Wels',
			'Pichl',
			'Opponitz',
			'Oberwagram',
			'Neustift an der Lafnitz',
			'Mödling',
			'Mitterlabill',
			'Markersdorf an der Pielach',
			'Marchegg',
			'Lassing',
			'Köttmannsdorf',
			'Kobersdorf',
			'Klausen-Leopoldsdorf',
			'Klagenfurt am Wörthersee',
			'Kirchberg am Wagram',
			'Kapfenstein',
			'Hollabrunn',
			'Hohentauern',
			'Hoheneich',
			'Hirm',
			'Himmelreich',
			'Herzogsdorf',
			'Harland',
			'Günselsdorf',
			'Göss',
			'Glojach',
			'Glödnitz',
			'Gaweinstal',
			'Fernitz',
			'Eugendorf',
			'Egg',
			'Eckartsau',
			'Droß',
			'Bachmanning',
			'Auersthal',
			'Aschau',
			'Ansfelden',
			'Anras',
			'Anger',
			'Aigen im Mühlkreis',
			'Ahorn',
			'Abwinden',
			'Höf',
			'Baierdorf-Umgebung',
			'Aschau im Zillertal',
			'Wispl',
			'Langholzfeld',
			'Villacher Vorstadt',
			'Zederhaus',
			'Wimpassing im Schwarzatale',
			'Wilfersdorf',
			'Weitra',
			'Weissenbach an der Triesting',
			'Waidhofen an der Ybbs',
			'Volders',
			'Triebendorf',
			'Traun',
			'Traismauer',
			'Thumersbach',
			'Tannheim',
			'Sigless',
			'Seiersberg',
			'Sankt Stefan ob Stainz',
			'Sankt Johann am Tauern',
			'Rotenturm an der Pinka',
			'Reutte',
			'Purkersdorf',
			'Pruggern',
			'Proleb',
			'Pinsdorf',
			'Pillichsdorf',
			'Perwang am Grabensee',
			'Ort im Innkreis',
			'Oberndorf bei Salzburg',
			'Oberndorf an der Melk',
			'Nestelbach bei Graz',
			'Mürzzuschlag',
			'Mühlen',
			'Möllersdorf',
			'Matzen',
			'Matrei in Osttirol',
			'Maria Enzersdorf',
			'Lienz',
			'Labuch',
			'Kuchl',
			'Kematen an der Ybbs',
			'Kapfing',
			'Hagenberg im Mühlkreis',
			'Großrußbach',
			'Grimmenstein',
			'Golling an der Salzach',
			'Glanegg',
			'Gföhl',
			'Gampern',
			'Gaming',
			'Fügenberg',
			'Eschenau',
			'Eisenstadt',
			'Baden',
			'Au',
			'Apfelberg',
			'Andorf',
			'Ampass',
			'Altenberg bei Linz',
			'Alberndorf in der Riedmark',
			'Hochleithen',
			'Pfarrwerfen',
			'Holzgau',
			'Klausner',
			'Reinbach',
			'Lind',
			'Neu-Guntramsdorf',
			'Wolfsthal',
			'Wernersdorf',
			'Wängle',
			'Waldegg',
			'Unken',
			'Bad Traunstein',
			'Stronsdorf',
			'Steinbach am Attersee',
			'Stein an der Donau',
			'Stambach',
			'Spratzern',
			'Seyring',
			'Seggauberg',
			'Schönwies',
			'Schildorn',
			'Sattledt',
			'Sankt Oswald bei Freistadt',
			'Sankt Michael im Burgenland',
			'Sankt Margarethen im Burgenland',
			'Sankt Marein bei Graz',
			'Sankt Lorenzen bei Knittelfeld',
			'Sankt Georgen an der Gusen',
			'Sallingberg',
			'Röhrenbach',
			'Rohrbach in Oberösterreich',
			'Ritzing',
			'Rettenegg',
			'Pöttsching',
			'Parndorf',
			'Palting',
			'Ossiach',
			'Oberwang',
			'Obervellach',
			'Obersdorf',
			'Oberschützen',
			'Neusiedl an der Zaya',
			'Neumarkt im Hausruckkreis',
			'Mönchhof',
			'Mitterberghütten',
			'Mauerkirchen',
			'Maria Wörth',
			'Lunz am See',
			'Loipersbach im Burgenland',
			'Lilienfeld',
			'Lieboch',
			'Lang',
			'Kumberg',
			'Krumau am Kamp',
			'Kötschach',
			'Klingenbach',
			'Kirchdorf in Tirol',
			'Kierling',
			'Kaisersdorf',
			'Kaibing',
			'Jagerberg',
			'Inzersdorf im Kremstal',
			'Innerbraz',
			'Hopfgarten in Defereggen',
			'Hollersbach im Pinzgau',
			'Hippach',
			'Hatzendorf',
			'Haselbach',
			'Haiming',
			'Haag',
			'Grünbach am Schneeberg',
			'Grosssulz',
			'Gramastetten',
			'Götzendorf an der Leitha',
			'Gössendorf',
			'Gniebing',
			'Gneixendorf',
			'Gallspach',
			'Gallneukirchen',
			'Friedberg',
			'Ellmau',
			'Eggenburg',
			'Ebersdorf',
			'Eberndorf',
			'Bodensdorf',
			'Bildstein',
			'Bad Aussee',
			'Arnfels',
			'Altenburg',
			'Liebenfels',
			'Mellau',
			'Sticklberg',
			'Loitzenberg',
			'Zell',
			'Ybbs an der Donau',
			'Weitensfeld',
			'Weiden bei Rechnitz',
			'Völs',
			'Bad Vigaun',
			'Treibach',
			'Ternberg',
			'Steinakirchen am Forst',
			'Spielfeld',
			'Sonntagberg',
			'Sittersdorf',
			'Seeboden',
			'Schwarzenbach an der Pielach',
			'Scheffau am Tennengebirge',
			'Sankt Valentin',
			'Sankt Martin bei Lofer',
			'Sankt Leonhard am Forst',
			'Sankt Kathrein am Offenegg',
			'Sankt Jakob im Walde',
			'Sankt Georgen im Attergau',
			'Sankt Andrä',
			'Sachsenburg',
			'Saalbach',
			'Ruprechtshofen',
			'Rosenau am Sonntagberg',
			'Rietz',
			'Riefensberg',
			'Ravelsbach',
			'Rattenberg',
			'Ratsch an der Weinstraße',
			'Ranten',
			'Raiding',
			'Bad Radkersburg',
			'Rachau',
			'Prinzersdorf',
			'Prigglitz',
			'Pinggau',
			'Pettenbach',
			'Obervogau',
			'Obermieming',
			'Nüziders',
			'Nenzing',
			'Mogersdorf',
			'Mistelbach',
			'Mieming',
			'Mellach',
			'Lutzmannsburg',
			'Loosdorf',
			'Liesingtal',
			'Liebenau',
			'Leitersdorf im Raabtal',
			'Lanzenkirchen',
			'Krusdorf',
			'Kitzbühel',
			'Kaltenleutgeben',
			'Jaidhof',
			'Ilz',
			'Hörbranz',
			'Hollenthon',
			'Hohenruppersdorf',
			'Hof',
			'Hatting',
			'Hartkirchen',
			'Handenberg',
			'Halbturn',
			'Haibach im Mühlkreis',
			'Hackerberg',
			'Gurk',
			'Grünau im Almtal',
			'Großmugl',
			'Grambach',
			'Gallizien',
			'Freistadt',
			'Fisching',
			'Fischamend-Markt',
			'Erl',
			'Eidenberg',
			'Dürnstein',
			'Drasenhofen',
			'Donnerskirchen',
			'Breitenfeld am Tannenriegel',
			'Braunau am Inn',
			'Brandenberg',
			'Bocksdorf',
			'Blons',
			'Bleiburg/Pliberk',
			'Berg',
			'Au an der Traun',
			'Altenmarkt bei Fürstenfeld',
			'Aggsbach',
			'Eppenstein',
			'Wildschönau',
			'Favoriten',
			'Frastanz',
			'Längenfeld',
			'Grinzens',
			'Mauer',
			'Rohr im Kremstal',
			'Zöbern',
			'Zillingtal',
			'Wullersdorf',
			'Werndorf',
			'Viehhausen',
			'Unterpremstätten',
			'Unterperfuss',
			'Ungerdorf',
			'Tristach',
			'Thüringen',
			'Thaya',
			'Terfens',
			'Strasshof an der Nordbahn',
			'Straden',
			'Stössing',
			'Steyr',
			'Steeg',
			'Stadl-Paura',
			'Schwoich',
			'Schiefling am See',
			'Sankt Ruprecht',
			'Sankt Ilgen',
			'Salzburg',
			'Rudersdorf',
			'Roitham',
			'Rohrbach bei Mattersburg',
			'Pucking',
			'Pramet',
			'Prambachkirchen',
			'Pöllau',
			'Piesendorf',
			'Pflach',
			'Ottenthal',
			'Offenhausen',
			'Neukirchen an der Vöckla',
			'Mörtschach',
			'Mooskirchen',
			'Bad Pirawarth',
			'Ligist',
			'Lans',
			'Langenrohr',
			'Langen',
			'Kufstein',
			'Hintersee',
			'Hagenbrunn',
			'Gratwein',
			'Fuschl am See',
			'Freiland bei Deutschlandsberg',
			'Faistenau',
			'Erpfendorf',
			'Eisgarn',
			'Edelstal',
			'Ebenthal',
			'Dellach im Drautal',
			'Bad Schönau',
			'Bad Hofgastein',
			'Attnang-Puchheim',
			'Gerlosberg',
			'Nebelberg',
			'Eggendorf',
			'Wasserburg',
			'Hart',
			'Brandln',
			'Oberhaid',
			'Föderlach I',
			'Windigsteig',
			'Wiesfleck',
			'Werfenweng',
			'Weißkirchen in Steiermark',
			'Weissach',
			'Wartberg ob der Aist',
			'Waldzell',
			'Walchsee',
			'Vorau',
			'Völkermarkt',
			'Voldöpp',
			'Umhausen',
			'Tulbing',
			'Traunkirchen',
			'Trattenbach',
			'Tarrenz',
			'Tamsweg',
			'Stuhlfelden',
			'Studenzen',
			'Strallegg',
			'Stenzengreith',
			'Stein',
			'Statzendorf',
			'Schoppernau',
			'Sankt Leonhard',
			'Sankt Georgen ob Murau',
			'Sankt Blasen',
			'Salla',
			'Rutzenham',
			'Rettenschöss',
			'Rabenwald',
			'Prutz',
			'Pötting',
			'Pottendorf',
			'Poppendorf',
			'Pians',
			'Patsch',
			'Pasching',
			'Pack',
			'Ottenschlag im Mühlkreis',
			'Neumarkt an der Ybbs',
			'Mühlgraben',
			'Mühldorf',
			'Mühlau',
			'Medraz',
			'Matrei am Brenner',
			'Mariatal',
			'Mannersdorf am Leithagebirge',
			'Linz',
			'Leopoldsdorf im Marchfelde',
			'Krumbach',
			'Knittelfeld',
			'Klaus',
			'Kasten bei Böheimkirchen',
			'Kartitsch',
			'Kaltenbach',
			'Judendorf',
			'Hörsching',
			'Hochtregist',
			'Höchst',
			'Heiligeneich',
			'Hartheim',
			'Hardegg',
			'Guntramsdorf',
			'Grossarl',
			'Gröbming',
			'Grein',
			'Globasnitz',
			'Glasenbach',
			'Gerersdorf',
			'Gerasdorf bei Wien',
			'Gaißau',
			'Freidorf an der Laßnitz',
			'Fornach',
			'Fiss',
			'Enzenreith',
			'Enns',
			'Eltendorf',
			'Ebensee',
			'Dellach',
			'Blindenmarkt',
			'Berndorf bei Salzburg',
			'Attendorf',
			'Arnreit',
			'Altenmarkt bei Sankt Gallen',
			'Niederschöckl',
			'Bärnbach',
			'Bernardin',
			'Lingenau',
			'Wiesmath',
			'Wetzelsdorf',
			'Wernstein am Inn',
			'Weng im Innkreis',
			'Wendling',
			'Weikersdorf am Steinfelde',
			'Vorchdorf',
			'Vitis',
			'Tulln',
			'Sitzendorf an der Schmida',
			'Seitenstetten Markt',
			'Schleedorf',
			'Sarleinsbach',
			'Sankt Roman',
			'Sankt Martin',
			'Sankt Georgen an der Stiefing',
			'Ratschendorf',
			'Rastenfeld',
			'Raabs an der Thaya',
			'Pyhra',
			'Hofamt Priel',
			'Pfaffing',
			'Paldau',
			'Oberwölbling',
			'Obertrum am See',
			'Nöchling',
			'Neusiedl',
			'Muntlix',
			'Mötz',
			'Modriach',
			'Mitterdorf an der Raab',
			'Mauterndorf',
			'Maria Laach am Jauerling',
			'Leitzersdorf',
			'Laxenburg',
			'Lackendorf',
			'Kirchstetten',
			'Kirchschlag bei Linz',
			'Irrsdorf',
			'Inzing',
			'Hof bei Salzburg',
			'Hengsberg',
			'Haugsdorf',
			'Grossraming',
			'Gries am Brenner',
			'Gressenberg',
			'Graz',
			'Grafenstein',
			'Gleinstätten',
			'Garsten',
			'Fügen',
			'Freindorf',
			'Frankenmarkt',
			'Flatschach',
			'Eisenerz',
			'Deutschfeistritz',
			'Buchkirchen',
			'Böheimkirchen',
			'Biberwier',
			'Bad Gastein',
			'Audorf',
			'Altmünster',
			'Altenmarkt an der Triesting',
			'Adnet',
			'Hart bei Graz',
			'Seitenberg',
			'Unterjosefstal',
			'Tillmitsch',
			'Zwölfaxing',
			'Zwettl Stadt',
			'Zirl',
			'Zams',
			'Wundschuh',
			'Wolfsberg im Schwarzautal',
			'Wilfleinsdorf',
			'Wiesen',
			'Wies',
			'Weitersfelden',
			'Weilbach',
			'Wattenberg',
			'Wartberg',
			'Murau',
			'Übersaxen',
			'Tschagguns',
			'Trieben',
			'Stratzing',
			'Strasswalchen',
			'Stockerau',
			'Stegersbach',
			'Stans',
			'Spital am Pyhrn',
			'Seebenstein',
			'Scharndorf',
			'Sankt Marein im Mürztal',
			'Ried in der Riedmark',
			'Reifling',
			'Pfaffstätten',
			'Perlsdorf',
			'Penz',
			'Peggau',
			'Osterwitz',
			'Oslip',
			'Oberwölz Stadt',
			'Niederneukirchen',
			'Neufeld an der Leitha',
			'Mauerbach',
			'Matzendorf',
			'Martinsberg',
			'Sankt Florian',
			'Maria Saal',
			'Maria Rain',
			'Lustenau',
			'Leutschach',
			'Lend',
			'Leisach',
			'Langschlag',
			'Langenstein',
			'Langenlois',
			'Landeck',
			'Krumnussbaum',
			'Bad Kreuzen',
			'Klein Sankt Paul',
			'Kilb',
			'Katsdorf',
			'Jedenspeigen',
			'Hofstätten an der Raab',
			'Hof am Leithaberge',
			'Hitzendorf',
			'Heiterwang',
			'Haugschlag',
			'Hart',
			'Bad Häring',
			'Hannersdorf',
			'Gunskirchen',
			'Groß-Gerungs',
			'Grafenwörth',
			'Grafendorf bei Hartberg',
			'Gössenberg',
			'Gaspoltshofen',
			'Ferlach',
			'Fehring',
			'Euratsfeld',
			'Ebreichsdorf',
			'Desselbrunn',
			'Breitenau',
			'Bischofshofen',
			'Arzberg',
			'Aibl',
			'Finkenstein am Faaker See',
			'Berg',
			'Klaffenegg',
			'Rohrendorf bei Krems',
			'Neubau',
			'Zellerndorf',
			'Wolfsegg am Hausruck',
			'Wolfau',
			'Windhaag bei Freistadt',
			'Weibern',
			'Ungenach',
			'Traisen',
			'Taxenbach',
			'Sulz',
			'Strem',
			'Stattegg',
			'Schwarzau im Schwarzautal',
			'Schlitters',
			'Sankt Veit in Defereggen',
			'Sankt Ulrich bei Steyr',
			'Sankt Martin im Innkreis',
			'Sankt Jakob-Breitenau',
			'Sankt Aegidi',
			'Reingers',
			'Reichenau an der Rax',
			'Rassach',
			'Rappottenstein',
			'Raning',
			'Radfeld',
			'Prellenkirchen',
			'Preitenegg',
			'Poggersdorf',
			'Pill',
			'Pilgersdorf',
			'Pierbach',
			'Piberegg',
			'Paternion',
			'Orth an der Donau',
			'Oberpullendorf',
			'Neuzeug',
			'Natternbach',
			'Mutters',
			'Mürzsteg',
			'Mürzhofen',
			'Mitterndorf im Steirischen Salzkammergut',
			'Lendorf',
			'Leibnitz',
			'Lambach',
			'Kühnsdorf',
			'Kitzeck im Sausal',
			'Kirchschlag',
			'Jungholz',
			'Hundsheim',
			'Hinterbrühl',
			'Hernstein',
			'Hermagor',
			'Haunoldstein',
			'Gugging',
			'Groß-Engersdorf',
			'Göstling an der Ybbs',
			'Galtür',
			'Frauenberg',
			'Frankenfels',
			'Finkenstein',
			'Feistritz im Rosental',
			'Falkenburg',
			'Edt bei Lambach',
			'Edelsgrub',
			'Eberstalzell',
			'Dornbirn',
			'Döbriach',
			'Buch in Tirol',
			'Bergham',
			'Bad Gleichenberg',
			'Axams',
			'Aschach an der Donau',
			'Altenfelden',
			'Allerheiligen im Mühlkreis',
			'Oberperfuss',
			'Kleinedling',
			'Lockenhaus',
			'Assling',
			'Wagrain',
			'Haidl',
			'Esch',
			'Schachen bei Vorau',
			'Reitberg',
			'Zöblen',
			'Zell am Ziller',
			'Zagersdorf',
			'Winzendorf',
			'Westendorf',
			'Warth',
			'Waldkirchen am Wesen',
			'Uttendorf',
			'Trahütten',
			'Thomasberg',
			'Stumm',
			'Stinatz',
			'Steinerkirchen an der Traun',
			'Stadl-Traun',
			'Sommerein',
			'Schweiggers',
			'Schandorf',
			'Sankt Stefan',
			'Sankt Martin',
			'Ranggen',
			'Prägraten',
			'Pitschgau',
			'Pfons',
			'Persenbeug',
			'Pernegg an der Mur',
			'Pennewang',
			'Parbasdorf',
			'Ottenschlag',
			'Oberwart',
			'Obdach',
			'Müllendorf',
			'Mühldorf',
			'Möggers',
			'Miesenbach',
			'Micheldorf in Oberösterreich',
			'Mariazell',
			'Maria Alm am Steinernen Meer',
			'Ludesch',
			'Krakauhintermühlen',
			'Koblach',
			'Kapfenberg',
			'Kammern im Liesingtal',
			'Jenbach',
			'Heugraben',
			'Haringsee',
			'Hainsdorf im Schwarzautal',
			'Hafendorf',
			'Großharras',
			'Gallzein',
			'Fiecht',
			'Felixdorf',
			'Feistritz an der Gail',
			'Erpersdorf',
			'Drösing',
			'Deutsch Kaltenbrunn',
			'Deutsch Jahrndorf',
			'Deutsch Goritz',
			'Burgfried',
			'Bruck an der Leitha',
			'Brixlegg',
			'Behamberg',
			'Au am Leithaberge',
			'Anif',
			'Altmelon',
			'Altlichtenwarth',
			'Allentsteig',
			'Achau',
			'Untertilliach',
			'Igls',
			'Bürserberg',
			'Ratzersdorf an der Traisen',
			'Gries',
			'Priel',
			'Kreuzstetten',
			'Wildalpen',
			'Weppersdorf',
			'Weissenbach am Lech',
			'Waldhausen',
			'Unterlamm',
			'Tweng',
			'Trofaiach',
			'Tadten',
			'Stetten',
			'Sieggraben',
			'Schörfling',
			'Scheibbs',
			'Scharnstein',
			'Sankt Martin',
			'St Anton am Arlberg',
			'Rüstorf',
			'Roppen',
			'Reuthe',
			'Rabenstein an der Pielach',
			'Rabensburg',
			'Raach am Hochgebirge',
			'Purgstall',
			'Pinkafeld',
			'Pertlstein',
			'Oberzeiring',
			'Oberndorf in Tirol',
			'Niederöblarn',
			'Neutillmitsch',
			'Neusiedl am See',
			'Neuhofen an der Krems',
			'Naas',
			'Münzbach',
			'Merkendorf',
			'Mautern in Steiermark',
			'Mariasdorf',
			'Marchtrenk',
			'Lorüns',
			'Laussa',
			'Launsdorf',
			'Ladis',
			'Kukmirn',
			'Krispl',
			'Krieglach',
			'Krensdorf',
			'Klösterle',
			'Kleinsölk',
			'Kittsee',
			'Kirchdorf an der Krems',
			'Innervillgraten',
			'Horn',
			'Hofstetten',
			'Höflein',
			'Hirtenberg',
			'Hirschbach',
			'Hietzing',
			'Hieflau',
			'Haid',
			'Habach',
			'Gutenbrunn',
			'Greinsfurth',
			'Gramatneusiedl',
			'Gramais',
			'Gossendorf',
			'Gosdorf',
			'Gainfarn',
			'Gablitz',
			'Fußach',
			'Furth bei Göttweig',
			'Finkenberg',
			'Fieberbrunn',
			'Feistritz ob Bleiburg',
			'Endach',
			'Elmen',
			'Eggenberg',
			'Brixen im Thale',
			'Bischofstetten',
			'Berndorf',
			'Bad Deutsch-Altenburg',
			'Atzesberg',
			'Arzl',
			'Admont',
			'Abfaltersbach',
			'Leutasch',
			'St. Martin of Tennengebirge',
			'Kirchbichl',
			'Haus im Ennstal',
			'Hinterberg',
			'Regau',
			'Dürnau',
			'Höfling',
			'Laab',
			'Tux',
			'Kleinarl',
			'Neuhaus am Klausenbach',
			'Millstatt',
			'Zwettl an der Rodl',
			'Ybbsitz',
			'Wulkaprodersdorf',
			'Wolfsgraben',
			'Wiesing',
			'Weikendorf',
			'Warth',
			'Wallern an der Trattnach',
			'Waldkirchen an der Thaya',
			'Waidring',
			'Unterwart',
			'Untere Fellach',
			'Türnitz',
			'Tribuswinkel',
			'Strass im Zillertal',
			'Stadtschlaining',
			'Spillern',
			'Sinabelkirchen',
			'Semmering',
			'Schwarzenbach',
			'Sankt Nikolai ob Draßling',
			'Sankt Nikolai im Sölktal',
			'Sankt Margarethen an der Raab',
			'Sankt Lorenzen am Wechsel',
			'Sankt Aegyd am Neuwalde',
			'Röthis',
			'Riegersburg',
			'Riedlingsdorf',
			'Retznei',
			'Reichenfels',
			'Ramsau im Zillertal',
			'Puchenstuben',
			'Pram',
			'Pirching am Traubenberg',
			'Oberloisdorf',
			'Oberhofen im Inntal',
			'Niederwölz',
			'Niederdorf',
			'Neuhaus',
			'Naarn im Machlande',
			'Mühlbach am Hochkönig',
			'Mönichkirchen',
			'Mondsee',
			'Meiningen',
			'Markt Sankt Martin',
			'Maria Lankowitz',
			'Mannsdorf an der Donau',
			'Mank',
			'Loretto',
			'Ledenitzen',
			'Lavamünd',
			'Langau',
			'Laab im Walde',
			'Krottendorf',
			'Kohlschwarz',
			'Kobenz',
			'Kleinhöflein im Burgenland',
			'Kirchschlag in der Buckligen Welt',
			'Kapelln',
			'Innsbruck',
			'Hohenau an der Raab',
			'Hernals',
			'Hellmonsödt',
			'Haag',
			'Guntersdorf',
			'Gumpoldskirchen',
			'Groß Sankt Florian',
			'Grosspetersdorf',
			'Grosshöflein',
			'Gries im Sellrain',
			'Geboltskirchen',
			'Gaflenz',
			'Forchach',
			'Erlauf',
			'Eberau',
			'Dietersdorf am Gnasbach',
			'Atzbach',
			'Arzl im Pitztal',
			'Arbesbach',
			'Albersdorf',
			'Aflenz Kurort',
			'Neue Heimat',
			'Heinfels',
			'St. Georgen am Längsee',
			'Dünserberg',
			'Ufer',
			'Geidorf',
			'Zellberg',
			'Wilhelmsburg',
			'Weinzierl am Walde',
			'Wartmannstetten',
			'Waldenstein',
			'Villach',
			'Utzenaich',
			'Treglwang',
			'Trautmannsdorf in Oststeiermark',
			'Traboch',
			'Thalheim bei Wels',
			'Stadl-Hausruck',
			'Södingberg',
			'Söchau',
			'Soboth',
			'Seibersdorf',
			'Schruns',
			'Schrattenthal',
			'Schnifis',
			'Sankt Michael im Lungau',
			'Sankt Georgen ob Judenburg',
			'Sankt Gallenkirch',
			'Sankt Anton im Montafon',
			'Redlham',
			'Ranshofen',
			'Pradl',
			'Pirka',
			'Pfarrkirchen bei Bad Hall',
			'Palfau',
			'Öblarn',
			'Aurach',
			'Niederbreitenbach',
			'Neustift bei Güssing',
			'Neidling',
			'Namlos',
			'Mitterkirchen im Machland',
			'Mettersdorf am Saßbach',
			'Markt Allhau',
			'Markgrafneusiedl',
			'Mariastein',
			'Loich',
			'Lichtenegg',
			'Leonding',
			'Lembach im Mühlkreis',
			'Lannach',
			'Langenzersdorf',
			'Landl',
			'Klosterneuburg',
			'Kennelbach',
			'Judendorf',
			'Japons',
			'Irschen',
			'Irdning',
			'Weerberg',
			'Hüttschlag',
			'Horitschon',
			'Hartl',
			'Hadersdorf am Kamp',
			'Haag',
			'Güttenbach',
			'Güssing',
			'Groß-Enzersdorf',
			'Göpfritz an der Wild',
			'Fürnitz',
			'Fliess',
			'Fendels',
			'Ebbs',
			'Deutschlandsberg',
			'Deutsch Griffen',
			'Buchbach',
			'Brunn',
			'Bruck am Ziller',
			'Bichlbach',
			'Biberbach',
			'Barwies',
			'Ausserbraz',
			'Altheim',
			'Aistersheim',
			'Adlwang',
			'Achenkirch',
			'Absam',
			'Ellbögen',
			'Bad Waltersdorf',
			'Sonnberg im Mühlkreis',
			'Taugl',
			'Illitsch',
			'Zeutschach',
			'Zeltweg',
			'Windischgarsten',
			'Winden am See',
			'Wieselburg',
			'Vienna',
			'Weißenbach bei Liezen',
			'Weiler',
			'Unterkohlstätten',
			'Übersbach',
			'Stallhof',
			'Spielberg bei Knittelfeld',
			'Sierning',
			'Schwechat',
			'Schrattenbach',
			'Schmirn',
			'Schleinbach',
			'Sankt Peter im Sulmtal',
			'Sankt Paul im Lavanttal',
			'Sankt Martin',
			'Sankt Josef (Weststeiermark)',
			'Sankt Johann im Pongau',
			'Sankt Andrä im Lungau',
			'Sankt Andrä am Zicksee',
			'Rohr im Gebirge',
			'Ried im Innkreis',
			'Reichenau im Mühlkreis',
			'Ramingstein',
			'Raggal',
			'Raffelstetten',
			'Pusterwald',
			'Preding',
			'Pfunds',
			'Pattigham',
			'Oepping',
			'Oberwaltersdorf',
			'Oberhofen am Irrsee',
			'Niklasdorf',
			'Neukirchen bei Lambach',
			'Nesselwängle',
			'Meidling',
			'Mattighofen',
			'Mariahof',
			'Margarethen am Moos',
			'Lödersdorf',
			'Lochau',
			'Lichtenegg',
			'Leonstein',
			'Leobersdorf',
			'Leiben',
			'Kronstorf',
			'Kritzendorf',
			'Kaltenbach',
			'Jois',
			'Hohenweiler',
			'Hohenems',
			'Heiligenkreuz',
			'Heiligenbrunn',
			'Haslach an der Mühl',
			'Grafenegg',
			'Gols',
			'Göllersdorf',
			'Bad Gams',
			'Fritzens',
			'Ennsdorf',
			'Drassburg',
			'Dorfgastein',
			'Deutsch-Wagram',
			'Bretstein',
			'Bad Blumau',
			'Bezau',
			'Berndorf',
			'Wimsbach',
			'Aurach am Hongar',
			'Asperhofen',
			'Arnoldstein',
			'Auen',
			'Prottes',
			'Schärding Vorstadt',
			'Laahen',
			'Tiefenbach',
			'Innere Stadt',
			'Vasoldsberg',
			'Hinterleiten',
			'Ziersdorf',
			'Zell am Moos',
			'Wienersdorf',
			'Waltendorf',
			'Viehofen',
			'Uttendorf',
			'Taiskirchen im Innkreis',
			'Straß in Steiermark',
			'Stockenboi',
			'Stallehr',
			'Sierndorf',
			'Scharnitz',
			'Satteins',
			'Sankt Oswald bei Plankenwarth',
			'Sankt Martin am Ybbsfelde',
			'Rinn',
			'Reith im Alpbachtal',
			'Rehberg',
			'Pulkau',
			'Pressbaum',
			'Potzneusiedl',
			'Pöggstall',
			'Passail',
			'Ollersdorf im Burgenland',
			'Oehling',
			'Obertilliach',
			'Nikitsch',
			'Neustift im Stubaital',
			'Mönichwald',
			'Maria Neustift',
			'Marbach an der Donau',
			'Maierdorf',
			'Litschau',
			'Leitendorf',
			'Kremsdorf',
			'Krakauschatten',
			'Königstetten',
			'Klaus',
			'Kirchbach',
			'Kauns',
			'Judenburg',
			'Jennersdorf',
			'Hohenau',
			'Hennersdorf',
			'Heiligenkreuz am Waasen',
			'Hallein',
			'Großhofen',
			'Götzens',
			'Georgenberg',
			'Geistthal',
			'Gams bei Hieflau',
			'Furth an der Triesting',
			'Frohsdorf',
			'Frohnleiten',
			'Bad Erlach',
			'Edlitz',
			'Doren',
			'Dietach',
			'Bad Sankt Leonhard im Lavanttal',
			'Althofen',
			'Altendorf',
			'Ainet',
			'Aichkirchen',
			'Navis',
			'Ertl',
			'Hittisau',
			'Micheldorf in Kärnten',
			'Anger',
			'Lerchenfeld',
			'Wörterberg',
			'Wörgl',
			'Werfen',
			'Weiz',
			'Wallsee',
			'Waldprechting',
			'Voitsberg',
			'Velm',
			'Untertauern',
			'Tobaj',
			'Theresienfeld',
			'Sulzberg',
			'Strobl',
			'Straßgang',
			'Steinach am Brenner',
			'Stainz bei Straden',
			'Seekirchen am Wallersee',
			'Schwertberg',
			'Schwarzach',
			'Schwanenstadt',
			'Schwanberg',
			'Schönau an der Triesting',
			'Schattendorf',
			'Schärding',
			'Sautens',
			'Sankt Veit im Pongau',
			'Sankt Lorenzen im Mürztal',
			'Sankt Gotthard im Mühlkreis',
			'Sankt Gilgen',
			'Rufling',
			'Röns',
			'Rannersdorf',
			'Ragnitz',
			'Pöls',
			'Plesching',
			'Pamhagen',
			'Ohlsdorf',
			'Oberndorf in der Ebene',
			'Oberalm',
			'Nitscha',
			'Neuhofen an der Ybbs',
			'Neuberg an der Mürz',
			'Nassereith',
			'Mühldorf',
			'Muckendorf an der Donau',
			'Molln',
			'Mayrhofen',
			'Markt Piesting',
			'Mariapfarr',
			'Maria Ellend',
			'Luftenberg an der Donau',
			'Leogang',
			'Krottendorf bei Ligist',
			'Koppl',
			'Klöch',
			'Kirnberg an der Mank',
			'Kautzen',
			'Hönigsberg',
			'Hof bei Straden',
			'Groß-Schweinbarth',
			'Großdorf',
			'Göfis',
			'Gmünd',
			'Fohnsdorf',
			'Ferndorf',
			'Dorfbeuern',
			'Dietachdorf',
			'Burgau',
			'Brunn an der Schneebergbahn',
			'Bruck an der Mur',
			'Berwang',
			'Bad Ischl',
			'Asten',
			'Andlersdorf',
			'Amstetten',
			'Bad Leonfelden',
			'Alberschwende',
			'Edelstauden',
			'Zell am See',
			'Zell am Pettenfirst',
			'Wolkersdorf im Weinviertel',
			'Windhag',
			'Weisskirchen an der Traun',
			'Weinzierl bei Krems',
			'Weer',
			'Wald im Pinzgau',
			'Unterwaltersdorf',
			'Tragwein',
			'Thaur',
			'Sulztal an der Weinstraße',
			'Strengen',
			'Stainz',
			'Stainach',
			'Spital am Semmering',
			'Sistrans',
			'Simmering',
			'Schrems bei Frohnleiten',
			'Sankt Ulrich am Waasen',
			'Sankt Georgen bei Obernberg am Inn',
			'Sankt Anna am Aigen',
			'Rust',
			'Ried im Traunkreis',
			'Rechnitz',
			'Radstadt',
			'Parschlug',
			'Oedt',
			'Obernberg am Inn',
			'Neunkirchen',
			'Neukirchen am Großvenediger',
			'Neckenmarkt',
			'Muggendorf',
			'Mischendorf',
			'Michaelerberg',
			'Metnitz',
			'Lofer',
			'Liezen',
			'Lechaschau',
			'Lauterach',
			'Ladendorf',
			'Kulm am Zirbitz',
			'Kramsach',
			'Krakaudorf',
			'Kopfing im Innkreis',
			'Kleinzell',
			'Kindberg',
			'Keutschach am See',
			'Imsterberg',
			'Hutten',
			'Hürm',
			'Harmannsdorf',
			'Guttaring',
			'Großsölk',
			'Großkrut',
			'Gresten',
			'Grän',
			'Grafenschachen',
			'Grabersdorf',
			'Goldwörth',
			'Gnas',
			'Gerolding',
			'Gasen',
			'Fallbach',
			'Dienersdorf',
			'Burgfried',
			'Breitenbrunn',
			'Bramberg am Wildkogel',
			'Bludenz',
			'Blaindorf',
			'Bizau',
			'Bad Schallerbach',
			'Aurolzmünster',
			'Attnang',
			'Aspang Markt',
			'Annabichl',
			'Angath',
			'Altenmarkt im Pongau',
			'Allhartsberg',
			'Aderklaa',
			'Obernberg am Brenner',
			'Ehrwald',
			'Rain',
			'Afiesl',
			'Vandans',
			'Neuberg im Burgenland',
			'St. Veiter Vorstadt',
			'Rosental an der Kainach',
			'Jakomini',
			'Wimpassing',
			'Weiten',
			'Völkendorf',
			'Unterwagram',
			'Uderns',
			'Trössing',
			'Trins',
			'Trebesing',
			'Tauplitz',
			'Stummerberg',
			'Stubenberg',
			'Stoob',
			'Stephanshart',
			'Steinabrückl',
			'Stattersdorf',
			'Schwendau',
			'Schützen am Gebirge',
			'Schottwien',
			'Schönberg im Stubaital',
			'Schnepfau',
			'Sankt Pantaleon',
			'Sankt Nikolai im Sausal',
			'Ruden',
			'Rohrau',
			'Pregarten',
			'Pöttelsdorf',
			'Pörtschach am Wörthersee',
			'Oberneukirchen',
			'Oberdorf am Hochegg',
			'Oberaich',
			'Niederau',
			'Niederalm',
			'Natters',
			'Mühldorf bei Feldbach',
			'Lichtenwörth',
			'Leithaprodersdorf',
			'Lech',
			'Lamprechtshausen',
			'Kottingbrunn',
			'Kolsassberg',
			'Kirchberg ob der Donau',
			'Kirchberg an der Raab',
			'Kirchberg am Wechsel',
			'Inzenhof',
			'Hohenthurn',
			'Hainersdorf',
			'Haidershofen',
			'Grödig',
			'Flaurling',
			'Ferschnitz',
			'Fels am Wagram',
			'Eisbach',
			'Eferding',
			'Edelsbach bei Feldbach',
			'Diemlach',
			'Bromberg',
			'Breitenfeld an der Rittschein',
			'Auersbach',
			'Ampflwang',
			'Altach',
			'Allerheiligen bei Wildon',
			'Gerlos',
			'Fresen',
			'Gralla',
			'Niederstraß',
			'Braunau Neustadt',
			'Bergern',
			'Wörth an der Lafnitz',
			'Wenns',
			'Wallern im Burgenland',
			'Wagnitz',
			'Tulfes',
			'Telfes im Stubai',
			'Teesdorf',
			'Stollhofen',
			'Silbertal',
			'Seebach',
			'Schwarzenau',
			'Schöder',
			'Sankt Veit am Vogau',
			'Sankt Ruprecht an der Raab',
			'Sankt Peter am Kammersberg',
			'Sankt Peter am Hart',
			'Sankt Martin am Grimming',
			'Sankt Marein bei Knittelfeld',
			'Sankt Leonhard',
			'Sankt Johann bei Herberstein',
			'Sankt Georgen an der Leys',
			'Sankt Bartholomä',
			'Rosegg',
			'Riezlern',
			'Reichraming',
			'Rainbach im Mühlkreis',
			'Purbach am Neusiedler See',
			'Pühret',
			'Poysdorf',
			'Pöllauberg',
			'Pistorf',
			'Piringsdorf',
			'Peuerbach',
			'Petronell-Carnuntum',
			'Perchau am Sattel',
			'Pama',
			'Oberhausen',
			'Niederhollabrunn',
			'Muhr',
			'Mölbling',
			'Mittersill',
			'Mittelberg',
			'Micheldorf',
			'Meggenhofen',
			'Manning',
			'Losenstein',
			'Lassnitzhöhe',
			'Kundl',
			'Kleinlobming',
			'Kaumberg',
			'Heiligenblut',
			'Hart im Zillertal',
			'Gmunden',
			'Gloggnitz',
			'Glinzendorf',
			'Gaubitsch',
			'Gänserndorf',
			'Gaaden',
			'Friesach',
			'Feldkirch',
			'Enzersfeld',
			'Ehrenhausen',
			'Eggersdorf bei Graz',
			'Doppl',
			'Dalaas',
			'Bisamberg',
			'Baumgarten bei Gnas',
			'Bad Goisern',
			'Ardning',
			'Apetlon',
			'Thurn',
			'Lehen',
			'Krimml',
			'Schönkirchen-Reyersdorf',
			'Wolfern',
			'Vals',
			'Steinfeld',
			'Weidling',
			'Walchen',
			'Waiern',
			'Wagna',
			'Vösendorf',
			'Viktring',
			'Thalgau',
			'Stanz bei Landeck',
			'Stall',
			'Senftenberg',
			'Selzthal',
			'Seewalchen',
			'See',
			'Schwarzau am Steinfeld',
			'Schönering',
			'Schlatt',
			'Scheifling',
			'Schattwald',
			'Sankt Pölten',
			'Sankt Margarethen an der Sierning',
			'Sankt Georgen am Ybbsfelde',
			'Saalfelden am Steinernen Meer',
			'Raxendorf',
			'Rauris',
			'Rangersdorf',
			'Raabau',
			'Pottenbrunn',
			'Pfaffenhofen',
			'Pettneu',
			'Ottensheim',
			'Oggau',
			'Oberndorf bei Schwanenstadt',
			'Oberdrauburg',
			'Neulengbach',
			'Neukirchen',
			'Mortantsch',
			'Moosbrunn',
			'Melk',
			'Mautern',
			'Mariatrost',
			'Maria-Anzbach',
			'Maissau',
			'Laa an der Thaya',
			'Königswiesen',
			'Kainbach',
			'Ischgl',
			'Hötting',
			'Hochburg-Ach',
			'Himberg',
			'Heidenreichstein',
			'Groß-Siegharts',
			'Heimschuh',
			'Grafenschlag',
			'Gosau',
			'Gmünd',
			'Gedersdorf',
			'Gattendorf',
			'Ganz',
			'Filzmoos',
			'Eibiswald',
			'Eggern',
			'Ebergassing',
			'Dechantskirchen',
			'Bruck an der Großglocknerstraße',
			'Anthering',
			'Antau',
			'Andelsbuch',
			'Aich',
			'Sankt Jakob in Defereggen',
			'Kolsass',
			'Ried im Zillertal',
			'Langenlebarn-Oberaigen',
			'Zwentendorf',
			'Zeillern',
			'Wöllersdorf',
			'Wolfsbach',
			'Winklern bei Oberwölz',
			'Wald am Schoberpaß',
			'Viktorsberg',
			'Viechtwang',
			'Veitsch',
			'Unterfladnitz',
			'Thörl',
			'Ternitz',
			'Tattendorf',
			'Spatenhof',
			'Semriach',
			'Schwaz',
			'Schwadorf',
			'Schrems',
			'Schönbach',
			'Schachendorf',
			'Sankt Stefan im Rosental',
			'Sankt Peter',
			'Sankt Johann im Saggautal',
			'Sankt Dionysen',
			'Röthelstein',
			'Ratten',
			'Puch bei Weiz',
			'Oetz',
			'Ortgraben',
			'Oberrettenbach',
			'Oberkurzheim',
			'Ober-Grafendorf',
			'Nickelsdorf',
			'Neumarkt in Steiermark',
			'Mittertreffling',
			'Mieders',
			'Maria Lanzendorf',
			'Leobendorf',
			'Kohfidisch',
			'Kirchberg in Tirol',
			'Kappl',
			'Hausmening',
			'Hausbrunn',
			'Großebersdorf',
			'Greisdorf',
			'Gersdorf an der Feistritz',
			'Gastern',
			'Fürstenfeld',
			'Forstau',
			'Floing',
			'Feld am See',
			'Feistritz an der Drau',
			'Engerwitzdorf',
			'Eben am Achensee',
			'Dietersdorf',
			'Brunn am Gebirge',
			'Brückl',
			'Baumkirchen',
			'Bairisch Kölldorf',
			'Annaberg',
			'Amras',
			'Alland',
			'Bad Kleinkirchheim',
			'Abtenau',
			'Walserfeld',
			'Alpbach',
			'Berg im Drautal',
			'Damüls',
			'Zell-Arzberg',
			'Obergäu',
			'Eching',
			'Untereisenfeld',
			'Viktringer Vorstadt',
			'Würmla',
			'Willendorf am Steinfelde',
			'Wang',
			'Waidmannsfeld',
			'Vomp',
			'Untersiebenbrunn',
			'Rohrbach an der Gölsen',
			'Übelbach',
			'Thomatal',
			'Strassengel',
			'Steuerberg',
			'Stams',
			'Sooss',
			'Seeham',
			'Seckau',
			'Schwarzau im Gebirge',
			'Schlüsslberg',
			'Schlins',
			'Sankt Veit an der Gölsen',
			'Sankt Peter am Ottersbach',
			'Sankt Kathrein am Hauenstein',
			'Sankt Corona am Wechsel',
			'Ried im Oberinntal',
			'Reisenberg',
			'Pottschach',
			'Pischelsdorf in der Steiermark',
			'Pernersdorf',
			'Pernegg',
			'Oeynhausen',
			'Otterthal',
			'Oberhaag',
			'Münchendorf',
			'Möllbrücke',
			'Mitterndorf an der Fischa',
			'Mitterdorf im Mürztal',
			'Mattersburg',
			'Landskron',
			'Lafnitz',
			'Krumpendorf',
			'Kohlberg',
			'Klein-Pöchlarn',
			'Kirchbach in Steiermark',
			'Katzelsdorf',
			'Karrösten',
			'Kappel am Krappfeld',
			'Kapellen',
			'Ysper',
			'Illmitz',
			'Hüttau',
			'Hornstein',
			'Höfen',
			'Himmelberg',
			'Herrnbaumgarten',
			'Hausleiten',
			'Hallwang',
			'Hainzenberg',
			'Greifenburg',
			'Gratkorn',
			'Göttelsberg',
			'Gösting',
			'Fulpmes',
			'Fraxern',
			'Fladnitz im Raabtal',
			'Fischbach',
			'Feldkirchen bei Graz',
			'Feistritz bei Knittelfeld',
			'Feistritz am Wechsel',
			'Farrach',
			'Falkenstein',
			'Ehenbichl',
			'Eben im Pongau',
			'Ebenau',
			'Düns',
			'Dorfstetten',
			'Donnersbach',
			'Bürs',
			'Bärnkopf',
			'Baldramsdorf',
			'Amlach',
			'Aigen im Ennstal',
			'Mühlbachl',
			'Gschnitz',
			'Gaumberg',
			'Lanzendorf',
			'Hinterglemm',
			'Schröcken',
			'Thiersee',
			'Kirchenviertel',
			'Doppl',
			'Neualm',
			'St. Agathen und Perau',
			'Lend',
			'Andritz',
			'Angerberg',
			'Zistersdorf',
			'Zillingdorf',
			'Würflach',
			'Wolfpassing',
			'Wimpassing an der Leitha',
			'Weitersfeld',
			'Weißenkirchen in der Wachau',
			'Weingraben',
			'Weinburg am Saßbach',
			'Weinburg',
			'Unterauersbach',
			'Teufenbach',
			'Taxach',
			'Steinbrunn',
			'Steyregg',
			'Stetteldorf am Wagram',
			'Steinhaus',
			'Steinfeld',
			'Spannberg',
			'Serfaus',
			'Schwendt',
			'Scheffau am Wilden Kaiser',
			'Schafwiesen',
			'Bad Sauerbrunn',
			'Sankt Veit an der Glan',
			'Reichenthal',
			'Rauchenwarth',
			'Ramsau',
			'Puchenau',
			'Pitzenberg',
			'Niederthalheim',
			'Neumarkt am Wallersee',
			'Neudörfl',
			'Musau',
			'Münster',
			'Mitterpullendorf',
			'Mitterhofen',
			'Mehrnbach',
			'Mattsee',
			'Mannswörth',
			'Mannersdorf an der Rabnitz',
			'Tullnerbach-Lawies',
			'Langegg bei Graz',
			'Kremsmünster',
			'Krems an der Donau',
			'Kleinmürbisch',
			'Klaffer am Hochficht',
			'Kemeten',
			'Karlstetten',
			'Hollenegg',
			'Herzogenburg',
			'Heiligenkreuz im Lafnitztal',
			'Hauskirchen',
			'Griffen',
			'Goldegg',
			'Frannach',
			'Forchtenstein',
			'Faak am See',
			'Edelschrott',
			'Eberschwang',
			'Deutschkreutz',
			'Brunn an der Wild',
			'Birgitz',
			'Bartholomäberg',
			'Au',
			'Aschbach Markt',
			'Aschach an der Steyr',
			'Abtissendorf',
			'Ötztal-Bahnhof',
			'Kals am Großglockner',
			'Itter',
			'Thal',
			'Ottnang am Hausruck',
			'Hall bei Admont',
			'Puchkirchen am Trattberg',
			'Diesseits',
			'Villach-Innere Stadt',
			'Hochpoint',
			'Wudmath',
			'Donaustadt',
			'Zeiselmauer',
			'Wolfsberg',
			'Wippenham',
			'Wildermieming',
			'Wiener Neudorf',
			'Weyregg',
			'Weigelsdorf',
			'Weiden am See',
			'Vorderthiersee',
			'Unterbergla',
			'Ulrichsberg',
			'Trausdorf an der Wulka',
			'Steindorf am Ossiacher See',
			'Stadl an der Mur',
			'Sibratsgfäll',
			'Seewalchen',
			'Schweinbach',
			'Sankt Marien',
			'Sankt Lambrecht',
			'Sankt Johann in Tirol',
			'Ries',
			'Retz',
			'Reith bei Seefeld',
			'Reith bei Kitzbühel',
			'Ramsau am Dachstein',
			'Raab',
			'Plankenau',
			'Plainfeld',
			'Perchtoldsdorf',
			'Oberweg',
			'Oberhart',
			'Oberdorf im Burgenland',
			'Nikolsdorf',
			'Neumarkt im Mühlkreis',
			'Munderfing',
			'Mils bei Imst',
			'Ludmannsdorf',
			'Kössen',
			'Korneuburg',
			'Kloster',
			'Kematen in Tirol',
			'Kaprun',
			'Kaindorf',
			'Hörtendorf',
			'Hopfgarten im Brixental',
			'Holzhausen',
			'Hochwolkersdorf',
			'Hetzendorf',
			'Hartmannsdorf',
			'Haag am Hausruck',
			'Gutenstein',
			'Götzis',
			'Gars am Kamp',
			'Frankenburg',
			'Feldkirchen in Kärnten',
			'Feldkirchen an der Donau',
			'Engelhartstetten',
			'Emmersdorf an der Donau',
			'Elsbethen',
			'Ebenfurth',
			'Dürnkrut',
			'Dölsach',
			'Diex',
			'Debant',
			'Biedermannsdorf',
			'Bernstein',
			'Badersdorf',
			'Attersee',
			'Schlaiten',
			'Unternberg',
			'Tillmitsch Links der Laßnitz',
			'Schleben',
			'Zettling',
			'Zell an der Pram',
			'Wölfnitz',
			'Wildon',
			'Wiener Neustadt',
			'Weichselbaum',
			'Wattens',
			'Unterstinkenbrunn',
			'Timelkam',
			'Tiefgraben',
			'Strass',
			'Stotzing',
			'Stallhofen',
			'Staatz',
			'Spittal an der Drau',
			'Sollenau',
			'Sillian',
			'Siezenheim',
			'Siegendorf im Burgenland',
			'Seefeld in Tirol',
			'Schalchham',
			'Sankt Radegund bei Graz',
			'Sankt Peter in der Au Markt',
			'Sankt Marein bei Neumarkt',
			'Sankt Johann in der Haide',
			'Sankt Georgen am Fillmannsbach',
			'Sankt Andrä vor dem Hagenthale',
			'Reinsberg',
			'Reichendorf',
			'Raaba',
			'Puntigam',
			'Puchheim',
			'Pottenstein',
			'Pöchlarn',
			'Pitten',
			'Pernitz',
			'Paudorf',
			'Pabneukirchen',
			'Ottakring',
			'Oppenberg',
			'Obersiebenbrunn',
			'Oberpremstätten',
			'Pettnau',
			'Neutal',
			'Nauders',
			'Mitterbach am Erlaufsee',
			'Michelhausen',
			'Michaelnbach',
			'Markt Neuhodis',
			'Mailberg',
			'Lermoos',
			'Leopoldsdorf',
			'Leoben',
			'Lassee',
			'Langenwang',
			'Kraubath an der Mur',
			'Königsdorf',
			'Köflach',
			'Kematen an der Krems',
			'Karres',
			'Kalwang',
			'Kalsdorf bei Graz',
			'Kaindorf an der Sulm',
			'Warmbad-Judendorf',
			'Jerzens',
			'Innere Stadt',
			'Hüttenberg',
			'Henndorf am Wallersee',
			'Hausmannstätten',
			'Grieskirchen',
			'Gnesau',
			'Geras',
			'Gaal',
			'Fresach',
			'Floridsdorf',
			'Elbigenalp',
			'Eichfeld',
			'Eichenberg',
			'Echsenbach',
			'Eberstein',
			'Ebenthal',
			'Dürnstein in der Steiermark',
			'Bruckneudorf',
			'Breitenwang',
			'Breitenbach am Inn',
			'Birkfeld',
			'Bergheim',
			'Bad Vöslau',
			'Bad Tatzmannsdorf',
			'Bad Hall',
			'Bad Fischau',
			'Außervillgraten',
			'Atzenbrugg',
			'Arriach',
			'Andau',
			'Allhaming',
			'Allerheiligen im Mürztal',
			'Schwarzenberg',
			'Mitterndorf',
			'Mautbrücken',
			'Draschen',
			'Dickerldorf',
			'Neufurth',
			'Absdorf',
			'Bergland',
			'Sankt Leonhard',
			'Gries',
			'Vogau',
			'Wals',
			'Waldneukirchen',
			'Waizenkirchen',
			'Wagram',
			'Vordernberg',
			'Vorderhornbach',
			'Velden am Wörthersee',
			'Unterlangkampfen',
			'Ulrichskirchen',
			'Traiskirchen',
			'Straßburg-Stadt',
			'Stanzach',
			'Silz',
			'Schwarzach im Pongau',
			'Schardenberg',
			'Schäffern',
			'Sankt Wolfgang im Salzkammergut',
			'Sankt Peter ob Judenburg',
			'Sankt Peter-Freienstein',
			'Sankt Peter',
			'Sankt Martin im Mühlkreis',
			'Sankt Leonhard am Hornerwald',
			'Rum',
			'Reiterndorf',
			'Randegg',
			'Oberstorcha',
			'Neudau',
			'Nestelbach im Ilztal',
			'Münzkirchen',
			'Mils bei Solbad Hall',
			'Mauer bei Amstetten',
			'Maria Taferl',
			'Maishofen',
			'Lengenfeld',
			'Leitring',
			'Lavant',
			'Laakirchen',
			'Krumegg',
			'Kleinsöding',
			'Katzelsdorf',
			'Johnsbach',
			'Jochberg',
			'Imst',
			'Hollenstein an der Ybbs',
			'Hohenberg',
			'Hof',
			'Hirnsdorf',
			'Hartberg',
			'Hard',
			'Halbenrain',
			'Hainburg an der Donau',
			'Großweikersdorf',
			'Großriedenthal',
			'Großlobming',
			'Grossgmain',
			'Grins',
			'Gailitz',
			'Flirsch',
			'Feldbach',
			'Ernsthofen',
			'Enzersdorf an der Fischa',
			'Bürmoos',
			'Bockfließ',
			'Bernhardsthal',
			'Alkoven',
			'Gnadenwald',
			'Frauenstein',
			'Langkampfen',
			'Großkirchheim',
			'Zell-Markt',
			'Zurndorf',
			'Zerlach',
			'Winklarn',
			'Wilten',
			'Weistrach',
			'Walpersbach',
			'Virgen',
			'Turnau',
			'Tobadill',
			'Tieschen',
			'Taufkirchen an der Trattnach',
			'Stiwoll',
			'Spitz',
			'Söll',
			'Sölden',
			'Sebersdorf',
			'Sankt Ulrich am Pillersee',
			'Sankt Michael in Obersteiermark',
			'Sankt Margarethen bei Knittelfeld',
			'Sankt Magdalen',
			'Sankt Lorenzen bei Scheifling',
			'Sankt Georgen am Leithagebirge',
			'Sammersdorf',
			'Rennweg am Katschberg',
			'Rankweil',
			'Puchberg am Schneeberg',
			'Polling in Tirol',
			'Petzenkirchen',
			'Ostermiething',
			'Nußdorf am Haunsberg',
			'Mureck',
			'Mörbisch am See',
			'Moosdorf',
			'Moosburg',
			'Miesenbach bei Birkfeld',
			'Marz',
			'Maria Schmolln',
			'Loipersdorf bei Fürstenfeld',
			'Lenzing',
			'Lengfelden',
			'Landegg',
			'Lackenbach',
			'Köstendorf',
			'Kirchberg an der Pielach',
			'Kirchberg am Walde',
			'Kainach bei Voitsberg',
			'Jabing',
			'Hirschegg',
			'Hainfeld',
			'Haiden',
			'Hafnerbach',
			'Hadres',
			'Gutau',
			'Gschwandt',
			'Großklein',
			'Going',
			'Gnadendorf',
			'Gleisdorf',
			'Glanegg',
			'Gaschurn',
			'Garanas',
			'Gamlitz',
			'Gallmannsegg',
			'Gabersdorf',
			'Flattach',
			'St. Ruprecht-Falkendorf',
			'Ernstbrunn',
			'Empersdorf',
			'Eichgraben',
			'Donawitz',
			'Dobersberg',
			'Bludesch',
			'Angern an der March',
			'Aldrans',
			'Sellrain',
			'Obsteig',
			'Rohrberg',
			'Brand',
			'Bad Zell',
			'Sonntag',
			'Weißenkirchen im Attergau',
			'Wagnergraben',
			'Eggendorf',
			'Dörfl',
			'Neu-Feffernitz',
			'Langenegg',
			'Wördern',
			'Winklern',
			'Windhaag bei Perg',
			'Wernberg',
			'Wels',
			'Walding',
			'Wagrain',
			'Vöcklamarkt',
			'Viehhofen',
			'Unterfrauenhaid',
			'Tschanigraben',
			'Trautmannsdorf an der Leitha',
			'Telfs',
			'Strassham',
			'Sigmundsherberg',
			'Siegersdorf bei Herberstein',
			'Schrattenberg',
			'Schenkenfelden',
			'Schalchen',
			'Sankt Martin im Sulmtal',
			'Sankt Margarethen im Lungau',
			'Rottenmann',
			'Rohr im Burgenland',
			'Reding',
			'Radenthein',
			'Puch bei Hallein',
			'Perg',
			'Payerbach',
			'Olbendorf',
			'Oberlienz',
			'Oberau',
			'Niedernsill',
			'Niederndorf',
			'Niederleis',
			'Neukirchen am Walde',
			'Neudorf bei Staatz',
			'Moschendorf',
			'Mäder',
			'Litzelsdorf',
			'Krumbach Markt',
			'Innerschwand',
			'Hofkirchen im Traunkreis',
			'Hochfilzen',
			'Haus',
			'Häselgehr',
			'Gundersdorf',
			'Gießhübl',
			'Frauenkirchen',
			'Flachau',
			'Elixhausen',
			'Eichberg',
			'Dienten am Hochkönig',
			'Deuchendorf',
			'Breitenfurt bei Wien',
			'Bregenz',
			'Bierbaum am Auersbach',
			'Batschuns',
			'Asparn an der Zaya',
			'Ardagger Stift',
			'Altlichtenberg',
			'Altlengbach',
			'Altaussee',
			'Maurach',
			'Sankt Oswald ob Eibiswald',
			'Klein-Neusiedl',
			'Nöstlbach',
			'Völkermarkter Vorstadt',
		],
	},
	Azerbaijan: {
		country: 'Azerbaijan',
		cities: [
			'Yaycı',
			'Dünyamalılar',
			'Çalxanqala',
			'Quba',
			'Baş Göynük',
			'Qaraçala',
			'Sovetabad',
			'Yevlakh',
			'Sanqaçal',
			'İsmayıllı',
			'Yeni Suraxanı',
			'Altıağac',
			'Lankaran',
			'Qasım İsmayılov',
			'Mingelchaur',
			'Gilgilçay',
			'Bilajer',
			'Kyzyl-Burun',
			'Hacı Zeynalabdin',
			'Qaramanlı',
			'Qaxbaş',
			'Kizhaba',
			'Xıllı',
			'Saray',
			'Shamkhor',
			'Qutqashen',
			'Divichibazar',
			'Martakert',
			'Nakhchivan',
			'Zaqatala',
			'Sabunçu',
			'Nardaran',
			'Mamrux',
			'Korgöz',
			'Qarayeri',
			'Aliabad',
			'Geytepe',
			'Sedarak',
			'Fizuli',
			'Birinci Aşıqlı',
			'Yuxarı Aran',
			'Yanıqlı',
			'Faldarlı',
			'Çinarlı',
			'Balakhani',
			'Salyan',
			'Imishli',
			'Deste',
			'Xudat',
			'Xızı',
			'Zabrat',
			'Qusar',
			'Lokbatan',
			'Şuşa',
			'Laçın',
			'Qıvraq',
			'Khojasan',
			'Vurğun',
			'Pirallahı',
			'Ağdaş',
			'Hacıhüseynli',
			'Novyy Karanlug',
			'Əhmədbəyli',
			'Qubadlı',
			'Haftoni',
			'Beylagan',
			'Qobu',
			'Kilyazi',
			'Buzovna',
			'Arıqdam',
			'Şahbuz',
			'Culfa',
			'Vank',
			'Kyadabek',
			'Dolyar',
			'Qabaqçöl',
			'Badamdar',
			'Zyrya',
			'Qobustan',
			'Qaraxanlı',
			'Mughan',
			'Ramana',
			'Cahri',
			'Yukhary-Dashkesan',
			'Çatax',
			'Arıqıran',
			'Masally',
			'Hadrut',
			'Severo-Vostotchnyi Bank',
			'Tazakend',
			'Göygöl',
			'Verkhniy Dashkesan',
			'Hacıqabul',
			'Qax',
			'Maştağa',
			'Ganja',
			'Aghsu',
			'Yardımlı',
			'Qalaqayın',
			'Sumqayıt',
			'Oğuz',
			'Sumbatan-diza',
			'Pushkino',
			'Oğlanqala',
			'Samux',
			'Amirdzhan',
			'Agdzhabedy',
			'Böyük Qaramurad',
			'Samuxlu',
			'Xocalı',
			'Jalilabad',
			'Türkan',
			'Şuraabad',
			'Samur',
			'Saloğlu',
			'Gyuzdek',
			'Barda',
			'Neftçala',
			'Qobustan',
			'Goranboy',
			'Corat',
			'Belokany',
			'Sharur City',
			'Naftalan',
			'Ceyranbatan',
			'Aran',
			'Zardob',
			'Göyçay',
			'Heydarabad',
			'Zangilan',
			'Orjonikidze',
			'Astara',
			'Qazax',
			'Mardakan',
			'Dondar Quşçu',
			'Qırmızı Samux',
			'Saatlı',
			'Ordubad',
			'Avşar',
			'Xaçmaz',
			'Ujar',
			'Tovuz',
			'Terter',
			'Qaraçuxur',
			'Novosaratovka',
			'Qızılhacılı',
			'Kyurdarmir',
			'Qala',
			'Binagadi',
			'Basqal',
			'Xankandi',
			'Mincivan',
			'Qırmızı Bazar',
			'Askyaran',
			'Sheki',
			'Sabirabad',
			'Puta',
			'Kerbakhiar',
			'Qax İngiloy',
			'Çobansığnaq',
			'Bakıxanov',
			'Lerik',
			'Boradigah',
			'Şirvan',
			'Qandax',
			'Dzagam',
			'Biny Selo',
			'Horadiz',
			'Khirdalan',
			'Bilajari',
			'Baku',
			'Jebrail',
			'Ağdam',
			'Shamakhi',
			'Hövsan',
			'Digah',
			'Aghstafa',
		],
	},
	Bahamas: {
		country: 'Bahamas',
		cities: [
			'Governor’s Harbour',
			'High Rock',
			'Marsh Harbour',
			'Hard Bargain',
			'George Town',
			'Duncan Town',
			'Spanish Wells',
			'Nassau',
			'Cockburn Town',
			'Port Nelson',
			'Black Point',
			'Cooper’s Town',
			'Freeport',
			'San Andros',
			'Matthew Town',
			'Masons Bay',
			'Lucaya',
			'Dunmore Town',
			'Alice Town',
			'Colonel Hill',
			'Abraham’s Bay',
			'Clarence Town',
			'Arthur’s Town',
			'Andros Town',
			'West End',
		],
	},
	Bahrain: {
		country: 'Bahrain',
		cities: ['Al Muharraq', 'Al Ḩadd', 'Dār Kulayb', 'Manama', 'Sitrah', 'Sanābis', 'Jidd Ḩafş', 'Ar Rifā‘', 'Madīnat ‘Īsá', 'Madīnat Ḩamad'],
	},
	Bangladesh: {
		country: 'Bangladesh',
		cities: [
			'Thākurgaon',
			'Nālchiti',
			'Hājīganj',
			'Shibganj',
			'Bagerhat',
			'Pābna',
			'Bogra',
			'Rāmganj',
			'Parbatipur',
			'Ishurdi',
			'Teknāf',
			'Rājshāhi',
			'Rangpur',
			'Bāndarban',
			'Kālia',
			'Tungi',
			'Sylhet',
			'Muktāgācha',
			'Gaurnadi',
			'Chhāgalnāiya',
			'Lakshmīpur',
			'Sātkania',
			'Paotana Hat',
			'Netrakona',
			'Nageswari',
			'Lākshām',
			'Tāngāil',
			'Manikchari',
			'Bibir Hat',
			'Gafargaon',
			'Parvez Ali Hossain',
			'Swarpur',
			'Kālīganj',
			'Pālang',
			'Pār Naogaon',
			'Jahedpur',
			'Purbadhala',
			'Panchagarh',
			'Habiganj',
			'Khagrachhari',
			'Tungipāra',
			'Barishal',
			'Saidpur',
			'Parvez Ali',
			'Raojān',
			'Madaripur',
			'Azimpur',
			'Joymontop',
			'Shāhzādpur',
			'Comilla',
			'Sonārgaon',
			'Farīdpur',
			'Magura',
			'Shibganj',
			'Patiya',
			'Kushtia',
			'Jhingergācha',
			'Bherāmāra',
			'Bājitpur',
			'Nawābganj',
			'Narsingdi',
			'Khulna',
			'Natore',
			'Nilphamari',
			'Sayani',
			'Sirajganj',
			'Joypur Hāt',
			'Lālmohan',
			'Nowlamary',
			'Jamālpur',
			'Khanbaniara',
			'Nārāyanganj',
			'Burhānuddin',
			'Mahasthangarh',
			'Bhātpāra Abhaynagar',
			'Sātkhira',
			'Nāgarpur',
			'Mehendiganj',
			'Baniachang',
			'Badarganj',
			'Kesabpur',
			'Mymensingh',
			'Bera',
			'Bhola',
			'Sherpur',
			'Sarishābāri',
			'Ramnagar',
			'Jessore',
			'Gaibandha',
			'Paltan',
			'Char Golora',
			'Dohār',
			'Feni',
			'Rāipur',
			'Dhaka',
			'Chilmāri',
			'Bhairab Bāzār',
			'Nabīnagar',
			'Dinājpur',
			'Cox’s Bāzār',
			'Puthia',
			'Kashimnagar',
			'Narail',
			'Phultala',
			'Char Bhadrāsan',
			'Kishorganj',
			'Bhāndāria',
			'Mirzāpur',
			'Maulavi Bāzār',
			'Mathba',
			'Lalmonirhat',
			'Chhātak',
			'Sakhipur',
			'Uttar Char Fasson',
			'Chattogram',
			'Ujalpur',
			'Pirojpur',
			'Pīrgaaj',
			'Sandwīp',
			'Sarankhola',
			'Morrelgonj',
		],
	},
	Barbados: {
		country: 'Barbados',
		cities: ['Checker Hall', 'Four Cross Roads', 'Greenland', 'Bathsheba', 'Crane', 'Welchman Hall', 'Oistins', 'Holetown', 'Speightstown', 'Bridgetown'],
	},
	Belarus: {
		country: 'Belarus',
		cities: [
			'Valozhyn',
			'Slawharad',
			'Astrashytski Haradok',
			'Lyuban’',
			'Krasnapollye',
			'Kličaŭ',
			'Turets-Bayary',
			'Skidel’',
			'Slonim',
			'Shklow',
			'Plyeshchanitsy',
			'Krasnyy Bereg',
			'Palykavichy Pyershyya',
			'Borovlyany',
			'Zaslawye',
			'Astravyets',
			'Asnyezhytsy',
			'Nyakhachava',
			'Myadzyel',
			'Mir',
			'Lyubcha',
			'Kokhanava',
			'Khotsimsk',
			'Ivanava',
			'Buda-Kashalyova',
			'Peramoga',
			'Vyerkhnyadzvinsk',
			'Navapolatsk',
			'Damachava',
			'Bykhaw',
			'Byahoml’',
			'Horad Rechytsa',
			'Enyerhyetykaw',
			'Bal’shavik',
			'Zhytkavichy',
			'Vidzy',
			'Starobin',
			'Asipovichy',
			'Narach',
			'Lyepyel’',
			'Kastsyukowka',
			'Dukora',
			'Baruny',
			'Horad Krychaw',
			'Zel’va',
			'Yel’sk',
			'Vishow',
			'Staryya Darohi',
			'Syenitsa',
			'Parychy',
			'Krasnaye',
			'Kostyukovichi',
			'Hrodna',
			'Ramanavichy',
			'Horad Kobryn',
			'Zhabinka',
			'Stolin',
			'Smarhoń',
			'Slabada',
			'Sarachy',
			'Novogrudok',
			'Kryvichy',
			'Dubrowna',
			'Davyd-Haradok',
			'Baran’',
			'Balbasava',
			'Asvieja',
			'Novaya Huta',
			'Dowsk',
			'Schomyslitsa',
			'Yubilyeyny',
			'Smilavičy',
			'Shumilina',
			'Senno',
			'Myshkavichy',
			'Myazhysyatki',
			'Krasnosel’skiy',
			'Iwye',
			'Ivatsevichy',
			'Hotsk',
			'Gorodok',
			'Brahin',
			'Antopal’',
			'Druzhny',
			'Turaw',
			'Stowbtsy',
			'Sasnovy Bor',
			'Slutsk',
			'Syomkava',
			'Lahishyn',
			'Karanyowka',
			'Dzisna',
			'Charnawchytsy',
			'Byalynichy',
			'Horad Luninyets',
			'Pinsk',
			'Drybin',
			'Drahichyn',
			'Chashniki',
			'Ruzhany',
			'Pyetrykaw',
			'Luninyets',
			'Karelichy',
			'Kalinkavichy',
			'Hal’shany',
			'Hlusk',
			'Dyatlovo',
			'Khatsyezhyna',
			'Atolina',
			'Zhirovichi',
			'Snow',
			'Ross’',
			'Narach',
			'Minsk',
			'Mikashevichy',
			'Kruhlaye',
			'Korolëv Stan',
			'Konstantinovo',
			'Kalodzishchy',
			'Druja',
			'Dobrush',
			'Horad Pinsk',
			'Pryvol’ny',
			'Znamenka',
			'Volkovysk',
			'Talachyn',
			'Puchavičy',
			'Lyakhavichy',
			'Lida',
			'Kobryn',
			'Klyetsk',
			'Khal’ch',
			'Vasilyevichy',
			'Motal’',
			'Krupki',
			'Kosava',
			'Hyeranyony',
			'Cherykaw',
			'Blon’',
			'Byelaazyorsk',
			'Fanipol',
			'Voranava',
			'Vishnyeva',
			'Veyno',
			'Samakhvalavichy',
			'Mahilyow',
			'Lyntupy',
			'Chyrvonaya Slabada',
			'Kazhan-Haradok',
			'Kamyanyets',
			'Hlybokaye',
			'Svislach',
			'Zamostochye',
			'Pastavy',
			'Kadino',
			'Myory',
			'Krychaw',
			'Karma',
			'Октябрьский',
			'Novolukoml’',
			'Lyel’chytsy',
			'Kirawsk',
			'Khodosy',
			'Kamyennyya Lavy',
			'Ivyanyets',
			'Bobr',
			'Svir',
			'Salihorsk',
			'Rudzyensk',
			'Pyatryshki',
			'Nyasvizh',
			'Narowlya',
			'Loshnitsa',
			"Homyel'",
			'Hatava',
			'Ashmyany',
			'Horad Orsha',
			'Horad Zhodzina',
			'Sharkawshchyna',
			'Radaškovičy',
			'Orsha',
			'Machulishchy',
			'Haradzyeya',
			'Dokshytsy',
			'Buynichy',
			'Vyalikaya Byerastavitsa',
			'Ushachy',
			'Mosar',
			'Haradzishcha',
			'Hlusha',
			'Chachersk',
			'Byaroza',
			'Baranovichi',
			'Posëlok Voskhod',
			'Zhaludok',
			'Vitebsk',
			'Tsimkavichy',
			'Svyetlahorsk',
			'Horki',
			'Dashkawka',
			'Červień',
			'Brest',
			'Chyst’',
			'Zhdanovichy',
			'Malaryta',
			'Loyew',
			'Vyaliki Trastsyanets',
			'Michanovichi',
			'Vysokaye',
			'Vilyeyka',
			'Vyetka',
			'Stan’kava',
			'Rahachow',
			'Aktsyabrski',
			'Mscislau',
			'Maladziečna',
			'Lahoysk',
			'Kreva',
			'Kamyanyuki',
			'Chavusy',
			'Barysaw',
			'Indura',
			'Tsyelyakhany',
			'Pruzhany',
			'Polatsk',
			'Luhavaya Slabada',
			'Kapyl’',
			'Iĺja',
			'Dzyarzhynsk',
			'Byarozawka',
			'Bierazino',
			'Babruysk',
			'Lyasny',
			'Soly',
			'Shchuchyn',
			'Prawdzinski',
			'Uzda',
			'Urechcha',
			'Svislach',
			'Rakaw',
			'Ostryna',
			'Novy Svyerzhan’',
			'Novosel’ye',
			'Nasilava',
			'Mar’’ina Horka',
			'Kholopenichi',
			'Braslav',
			'Usiazh',
			'Zhlobin',
			'Yalizava',
			'Smaliavičy',
			'Rasony',
			'Rechytsa',
			'Azyartso',
			'Mazyr',
			'Mosty',
			'Lyozna',
			'Klimavichy',
			'Khoyniki',
			'Hantsavichy',
			'Lyeskawka',
		],
	},
	Belgium: {
		country: 'Belgium',
		cities: [
			'Genk',
			'Forchies-la-Marche',
			'Estinnes-au-Mont',
			'Émines',
			'Eben-Emael',
			'Carnières',
			'Brugge',
			'Broechem',
			'Biercée',
			'Berloz',
			'Berchem-Sainte-Agathe',
			'Bellem',
			'Basècles',
			'Adegem',
			'Zwijnaarde',
			'Zwevezele',
			'Zonnebeke',
			'Wijtschate',
			'Wierde',
			"Waret-l'Évêque",
			'Vosselare',
			'Velzeke-Ruddershove',
			'Vaux-sous-Chèvremont',
			'Uccle',
			'Turnhout',
			'Sivry',
			'Sint-Maria-Horebeke',
			'Sint-Baafs-Vijve',
			'Seraing',
			'Schoonaarde',
			'Schelderode',
			'Noiseux',
			'Neufchâteau',
			'Munkzwalm',
			'Moresnet',
			'Momalle',
			'Molenbeek-Wersbeek',
			'Moere',
			'Marchin',
			'Leke',
			'Latour',
			'Landegem',
			'Koekelare',
			'Kalmthout',
			'Jumet',
			'Jemelle',
			'Irchonwelz',
			'Héron',
			'Heldergem',
			'Gullegem',
			'Saint-Ghislain',
			'Rumst',
			'Riemst',
			'Oosteeklo',
			'Neuville-en-Condroz',
			'Neerharen',
			'Mortier',
			'Frasnes-lez-Gosselies',
			'Evregnies',
			'Corroy-le-Château',
			'Chiny',
			'Burdinne',
			'Brasschaat',
			'Bost',
			'Booischot',
			'Beyne-Heusay',
			'Berchem',
			'Beersel',
			'Attenhoven',
			'Arendonk',
			'Aiseau',
			'Agimont',
			'Zeveneken',
			'Wuustwezel',
			'Wommersom',
			'Wevelgem',
			'Westkapelle',
			'Warneton',
			'Warcoing',
			'Waimes',
			'Vlijtingen',
			'Vlamertinge',
			'Viersel',
			'Velm',
			'Velaine',
			'Stambruges',
			'Soy',
			'Soignies',
			'Scheldewindeke',
			'Roosbeek',
			'Ronse',
			'Quévy-le-Petit',
			'Petegem-aan-de-Leie',
			'Opbrakel',
			'Nivelles',
			'Niel',
			'Nethen',
			'Neerlinter',
			'Nederokkerzeel',
			'Munsterbilzen',
			'Mourcourt',
			'Montenaken',
			'Merksplas',
			'Meldert',
			'Malmédy',
			'Longlier',
			'Lombardsijde',
			'Lissewege',
			'Limelette',
			'Lierneux',
			'Knokke',
			'Isières',
			'Humbeek',
			'Houdeng-Aimeries',
			'Horion-Hozémont',
			'Hombeek',
			'Hollogne-aux-Pierres',
			'Herseaux',
			'Grivegnée',
			'Glons',
			'Genly',
			'Galmaarden',
			"Fontaine-l'Évêque",
			'Dilsen',
			'Deinze',
			'Buzet',
			'Buizingen',
			'Bévercé',
			'Bever',
			'Berchem',
			'Bazel',
			'Baisy-Thy',
			'As',
			'Anseroeul',
			'Achel',
			'Westkerke',
			'Wépion',
			'Wanfercée-Baulet',
			'Walhain-Saint-Paul',
			'Vreren',
			'Vrasene',
			'Vielsalm',
			'Tilleur',
			'Testelt',
			'Strépy-Bracquegnies',
			'Snaaskerke',
			'Seneffe',
			'Schulen',
			'Schaffen',
			'Saint-Sauveur',
			'Rocherath',
			'Raeren',
			'Paal',
			'Overmere',
			'Ouffet',
			'Opdorp',
			'Oosterzele',
			'Obaix',
			'Nederboelare',
			'Mont-Sainte-Aldegonde',
			'Molenstede',
			'Mol',
			'Melle',
			'Manderfeld',
			'Lint',
			'Letterhoutem',
			'Lens-sur-Geer',
			'Langdorp',
			'Kerkom',
			'Izel',
			'Huise',
			'Hollain',
			'Herselt',
			'Havinnes',
			'Hamois',
			'Hamoir',
			'Grâce-Berleur',
			'Ghlin',
			'Gent',
			'Gelrode',
			'Fosse',
			'Forges',
			'Farciennes',
			'Erps-Kwerps',
			'Duffel',
			'Dottignies',
			'Dion-le-Mont',
			'Denderleeuw',
			'Châtelineau',
			'Lasne-Chapelle-Saint-Lambert',
			'Borgloon',
			"Bois-d'Haine",
			'Baudour',
			'Baarle-Hertog',
			'Ayeneux',
			'Assebroek',
			'Arquennes',
			'Andenne',
			'Wezembeek-Oppem',
			'Wespelaar',
			'Vlissegem',
			'Vliermaal',
			'Terhagen',
			'Tamines',
			'Stokkem',
			'Spy',
			'Sint-Maria-Lierde',
			'Sint-Joris',
			'Sinaai',
			'Schoten',
			'Schorisse',
			'Schellebelle',
			'Roesbrugge-Haringe',
			'Saint-Gérard',
			'Le Roeulx',
			'Retie',
			'Reningelst',
			'Relegem',
			'Quevaucamps',
			'Pollare',
			'Péronnes',
			'Ougrée',
			'Oisquercq',
			'Natoye',
			'Namêche',
			'Mortsel',
			'Mere',
			'Mellet',
			'Mechelen',
			'Mariakerke',
			'Malderen',
			'Maillen',
			'Limbourg',
			'Libin',
			'Leers-Nord',
			'Leernes',
			'Landenne',
			'Jamoigne',
			'Ivoz-Ramet',
			'Huissignies',
			'Holsbeek',
			'Hoeleden',
			'Hechtel',
			'Ham-sur-Sambre',
			'Grand-Reng',
			'Temploux',
			'Schaerbeek',
			'Rosières',
			'Pironchamps',
			'Oreye',
			'Oppuurs',
			'Oplinter',
			'Onkerzele',
			'Oetingen',
			'Niel-bij-As',
			'Mussy-la-Ville',
			'Micheroux',
			'Meux',
			'Marchienne-au-Pont',
			'Liège',
			'Le Roux',
			'Kortenaken',
			'Kester',
			'Kersbeek-Miskom',
			'Hotton',
			'Herbeumont',
			'Hellebecq',
			'Haine-Saint-Pierre',
			'Godarville',
			'Zwijndrecht',
			'Zoerle-Parwijs',
			'Zichen-Zussen-Bolder',
			'Zandbergen',
			'Woumen',
			'Wolvertem',
			'Willemeau',
			'Wemmel',
			'Warsage',
			'Waasmunster',
			'Waardamme',
			'Tubize',
			'Thy-le-Château',
			'Thommen',
			'Tertre',
			'Teralfene',
			'Steenhuffel',
			'Solre-sur-Sambre',
			'Saintes',
			'Rotselaar',
			'Roksem',
			'Pulderbos',
			'Poulseur',
			'Othée',
			'Gembloux',
			'Froidchapelle',
			'Sint-Pieters-Voeren',
			'Florenville',
			'Etikhove',
			'Ensival',
			'Elene',
			'Damme',
			'Cortil-Noirmont',
			'Corroy-le-Grand',
			'Comblain-au-Pont',
			'Chaudfontaine',
			'Bovigny',
			'Bossut-Gottechain',
			'Boom',
			'Blaton',
			'Bilstain',
			'Bassevelde',
			'Barvaux',
			'Balen',
			'Antoing',
			'Acoz',
			'Gelbressée',
			'Forest',
			'Falisolle',
			'Essene',
			'Épinois',
			'Elverdinge',
			'Court-Saint-Étienne',
			'Clermont',
			'Champion',
			'Cambron-Saint-Vincent',
			'Bornem',
			'Boekhoute',
			'Bilzen',
			'Beverst',
			'Baileux',
			'Archennes',
			'Amougies',
			'Aartselaar',
			'Zandhoven',
			'Wommelgem',
			'Winenne',
			'Webbekom',
			'Wanzele',
			'Vottem',
			'Vlimmeren',
			'Velaines',
			'Tielrode',
			'Stoumont',
			"Sint-Job-in-'t-Goor",
			'Sijsele',
			'Saint-Symphorien',
			'Ruiselede',
			'Rouvreux',
			'Rillaar',
			'Piétrain',
			'Passendale',
			'Oud-Turnhout',
			'Olsene',
			'Oekene',
			'Noville',
			'Namur',
			'Mont-Saint-Guibert',
			'Mazy',
			'Lommersweiler',
			'Lens',
			'Lauwe',
			'La Bouverie',
			'Korbeek-Lo',
			'Kemzeke',
			'Houthalen',
			'Hombourg',
			'Hodeige',
			'Heule',
			'Herne',
			'Herentals',
			'Heers',
			'Halanzy',
			'Floreffe',
			'Flamierge',
			'Eernegem',
			'Donceel',
			'Crombach',
			'Burst',
			'Bras',
			'Boutersem',
			'Boortmeerbeek',
			'Blégny',
			'Bioul',
			'Béclers',
			'Bas-Warneton',
			'Baelen',
			'Antwerpen',
			'Aarsele',
			'Aaigem',
			'Jandrain',
			'Zeebrugge',
			'Wiers',
			'Wasseiges',
			'Waltwilder',
			'Villers-Poterie',
			'Veltem-Beisem',
			'Tielt',
			'Thimister',
			'Tessenderlo',
			'Strijpen',
			'Steenhuize-Wijnhuize',
			'Sombreffe',
			'Sint-Ulriks-Kapelle',
			'Sint-Jan-in-Eremo',
			'Reninge',
			'Rekem',
			'Lettelingen',
			'Péronnes',
			'Ooigem',
			'Nieuwkerken-Waas',
			'Mons',
			'Lustin',
			'Deux-Acren',
			'Kraainem',
			'Kortrijk',
			'Kleine-Spouwen',
			'Keerbergen',
			'Kapellen',
			'Jette',
			'Ieper',
			'Houffalize',
			'Heppen',
			'Hautrage',
			'Hamme',
			'Guigoven',
			'Grobbendonk',
			'Goutroux',
			'Ghoy',
			'Meerhout',
			'Mark',
			'Lochristi',
			'Limal',
			'Lambusart',
			'Florennes',
			'Fleurus',
			'Eugies',
			'Eke',
			'Dworp',
			'Denderhoutem',
			'Daverdisse',
			'Clabecq',
			'Chièvres',
			'Bomal',
			'Blaugies',
			'Bekegem',
			'Beauraing',
			'Astene',
			'Amblève',
			'Reuland',
			'Wortel',
			'Westrozebeke',
			'Weerde',
			'Wavreille',
			'Vollezele',
			'Viesville',
			'Uikhoven',
			'Thiaumont',
			'Tervuren',
			'Staden',
			'Sint-Jan',
			'Sart-Dames-Avelines',
			'Saint-Vaast',
			'Saint-Josse-ten-Noode',
			'Saint-Hubert',
			'Roclenge-sur-Geer',
			'Rendeux',
			'Racour',
			'Quaregnon',
			'Poppel',
			'Oud-Heverlee',
			'Ophain-Bois-Seigneur-Isaac',
			'Neerpelt',
			'Montegnée',
			'Moerbeke',
			'Meldert',
			'Marke',
			'Marche-les-Dames',
			'Kessel',
			'Impe',
			'Loupoigne',
			'Loppem',
			'Leopoldsburg',
			'Leerbeek',
			'Lauw',
			'Kozen',
			'Knokke-Heist',
			'Kemmel',
			'Jurbise',
			'Jeuk',
			'Jabbeke',
			'Heist-op-den-Berg',
			'Harchies',
			'Geer',
			'Ganshoren',
			'Fraire',
			'Fosses-la-Ville',
			'Fontaine-Valmont',
			'Flémalle-Haute',
			'Esen',
			'Engis',
			'Elsenborn',
			'Ellikom',
			'Écaussinnes-Lalaing',
			'Durnal',
			'De Pinte',
			'Couthuin',
			'Buvrinnes',
			'Bever',
			'Betekom',
			'Beerse',
			'Baasrode',
			'Arlon',
			'Angleur',
			'Aarschot',
			'Zevergem',
			'Zedelgem',
			'Wijchmaal',
			'Vissenaken',
			"Villers-l'Évêque",
			'Villers-la-Ville',
			'Tremelo',
			'Tintigny',
			'Sint-Martens-Latem',
			'Sint-Lenaarts',
			'Saive',
			'Perk',
			'Oostnieuwkerke',
			'Sint-Stevens-Woluwe',
			'Sint-Martens-Bodegem',
			'Sint-Katherina-Lombeek',
			'Rumes',
			'Pottes',
			'Pondrôme',
			'Oleye',
			'Brakel',
			'Maaseik',
			'Kuurne',
			'Koolkerke',
			'Kerkhove',
			'Hofstade',
			'Hever',
			'Haneffe',
			'Gistel',
			'Evrehailles',
			'Elsegem',
			'Eigenbilzen',
			'Dikkelvenne',
			'Diegem',
			'Clavier',
			'Chaineux',
			'Celles',
			'Bray',
			'Braine-le-Château',
			'Bierbeek',
			'Baardegem',
			'Amay',
			'Alken',
			'Zonhoven',
			'Zoersel',
			'Zelem',
			'Zarlardinge',
			'Xhoris',
			'Wijshagen',
			'Westouter',
			'Wasmuel',
			'Wardin',
			'Voroux-lez-Liers',
			'Vilvoorde',
			'Viane',
			'Vezon',
			'Verviers',
			'Vaux-et-Borset',
			'Tohogne',
			'Tisselt',
			'Thulin',
			'Thorembais-Saint-Trond',
			'Temse',
			'Templeuve',
			'Tarcienne',
			'Steendorp',
			'Soumagne',
			'Sint-Pauwels',
			'Sint-Martens-Lennik',
			'Sint-Joris-Weert',
			'Sint-Eloois-Winkel',
			'Schilde',
			'Sart-Saint-Laurent',
			'Saint-Pierre',
			'Saint-Amand',
			'Ruien',
			'Pommeroeul',
			'Plainevaux',
			'Perre',
			'Papignies',
			'Ouwegem',
			'Onhaye',
			'Noville',
			'Mopertingen',
			'Messelbroek',
			'Merelbeke',
			'Massenhoven',
			'Maisières',
			'Loverval',
			'Lembeek',
			'Ledeberg',
			'Labuissière',
			'Kieldrecht',
			'Kermt',
			'Jandrain-Jandrenouille',
			'Houtvenne',
			'Heverlee',
			'Heure-le-Romain',
			'Hastière-Lavaux',
			'Gooik',
			'Gesves',
			'Frameries',
			'Fexhe-le-Haut-Clocher',
			'Erquelinnes',
			'Doische',
			'Denderwindeke',
			'Cuesmes',
			'Bullange',
			'Bree',
			'Bouge',
			'Biez',
			'Bièvre',
			'Bierges',
			'Beuzet',
			'Beaufays',
			'Avelgem',
			'Autre-Église',
			'Asper',
			'Ardooie',
			'Aalter',
			'Aalbeke',
			'Mons-lez-Liège',
			'Moignelée',
			'Moerbeke',
			'Messancy',
			'Meerbeek',
			'Mechelen-aan-de-Maas',
			'Mater',
			'Martelange',
			'Londerzeel',
			'Linkhout',
			'Leuze',
			'Leignon',
			'La Louvière',
			'Koolskamp',
			'Klemskerke',
			'Kanegem',
			'Huldenberg',
			'Hoves',
			'Hove',
			'Hooglede',
			'Heffen',
			'Havré',
			'Haine-Saint-Paul',
			'Zomergem',
			'Zerkegem',
			'Wihéries',
			'Wiekevorst',
			'Werchter',
			'Waregem',
			'Wambeek',
			"Houtain-l'Évêque",
			'Vorselaar',
			'Tiegem',
			'Stene',
			'Soye',
			'Somzée',
			'Sleidinge',
			'Sint-Huibrechts-Lille',
			'Schuiferskapelle',
			'Rumbeke',
			'Roux',
			'Ronquières',
			'Recogne',
			'Quenast',
			'Putte',
			'Piéton',
			'Pellenberg',
			'Ottenburg',
			'Oostrozebeke',
			'Oordegem',
			'Olne',
			'Ninove',
			'Nieuwpoort',
			'Nieuwerkerken',
			'Nederhasselt',
			'Montigny-le-Tilleul',
			'Gellik',
			'Fléron',
			'Fauvillers',
			'Ettelgem',
			'Essen',
			'Erondegem',
			'Eksaarde',
			'Éghezée',
			'Dolembreux',
			'Dison',
			'Dadizele',
			'Chasse Royale',
			'Boezinge',
			'Bellevaux-Ligneuville',
			'Bambrugge',
			'Assesse',
			'Alveringem',
			'Adinkerke',
			'Zottegem',
			'Zaffelare',
			'Voorde',
			'Vieux-Genappe',
			'Uitbergen',
			'Tournai',
			'Theux',
			'Spiere',
			'Snellegem',
			'Sirault',
			'Sint-Maria-Oudenhove',
			'Sint-Gillis-Waas',
			'Semmerzake',
			'Roisin',
			'Rijmenam',
			'Plombières',
			'Plancenoit',
			'Otegem',
			'Orgeo',
			'Onze-Lieve-Vrouw-Waver',
			'Olmen',
			'Nieuwenrode',
			'Muizen',
			'Moerzeke',
			'Michelbeke',
			'Merksem',
			'Maria Aalter',
			'Lokeren',
			'Lincent',
			'Leut',
			'Lemberge',
			'Lebbeke',
			'La Roche-en-Ardenne',
			'Laplaigne',
			'Laneffe',
			'Laarne',
			'Koersel',
			'Kinrooi',
			'Kasterlee',
			'Helchteren',
			'Hauset',
			'Hamont',
			'Grez-Doiceau',
			'Gits',
			'Zillebeke',
			'Wellin',
			'Eynatten',
			'Estinnes-au-Val',
			'Embourg',
			'Desselgem',
			'Dessel',
			'Clermont-sous-Huy',
			'Brussels',
			'Bonheiden',
			'Bierset',
			'Beverlo',
			'Beveren',
			'Bertrix',
			'Bertogne',
			'Ben-Ahin',
			'Beervelde',
			'Bachte-Maria-Leerne',
			'Autelbas',
			'Arville',
			'Arsimont',
			'Appels',
			'Anderlues',
			'Zwevegem',
			'Yves-Gomezée',
			'Woluwe-Saint-Lambert',
			'Wodecq',
			'Wezemaal',
			'Westende',
			'Waudrez',
			'Watou',
			'Waarschoot',
			'Villers-le-Bouillet',
			'Trivières',
			'Tinlot',
			'Tielen',
			'Stembert',
			'Schepdaal',
			'Rupelmonde',
			'Rixensart',
			'Puurs',
			'Pont-à-Celles',
			'Petit-Rechain',
			'Oudenburg',
			'Opprebais',
			'Nijlen',
			'Naninne',
			'Moortsele',
			'Moerkerke',
			'Milmort',
			'Machelen',
			'Louvain-la-Neuve',
			'Linden',
			'Lichtaart',
			'Liberchies',
			'Lens-Saint-Remy',
			'Ledeberg',
			'Lanklaar',
			'Langemark',
			'Kumtich',
			'Kruibeke',
			'Koninksem',
			'Kanne',
			'Izegem',
			'Ixelles',
			'Heusden',
			'Herve',
			'Hensies',
			'Han-sur-Lesse',
			'Handzame',
			'Ham-sur-Heure',
			'Halle-Booienhoven',
			'Grand-Halleux',
			'Goetsenhoven',
			'Geest-Gérompont-Petit-Rosière',
			'Flémalle-Grande',
			'Ferrières',
			'Erwetegem',
			'Eindhout',
			'Durbuy',
			'Dudzele',
			'Drieslinter',
			'Deurne',
			'Chastre-Villeroux-Blanmont',
			'Bouwel',
			'Blaasveld',
			'Bihain',
			'Biesme',
			'Beveren',
			'Berlaar',
			'Beez',
			'Bastogne',
			'Awans',
			'Attert',
			'Achêne',
			'Zaventem',
			'Wonck',
			'Wetteren',
			'Welden',
			'Veurne',
			'Tongerlo',
			'Tongeren',
			'Tilly',
			'Thieu',
			'Sint-Pieters-Rode',
			'Sint-Andries',
			'Serskamp',
			'Rijkhoven',
			'Petigny',
			'Petegem',
			'Olen',
			'Naast',
			'Pecq',
			'Nismes',
			'Menen',
			'Massemen',
			'Longvilly',
			'Leefdaal',
			'La Bruyère',
			'Kalken',
			'Jehay',
			'Jambes',
			'Itterbeek',
			'Itegem',
			'Incourt',
			'Houtain-Saint-Siméon',
			'Hoboken',
			'Harelbeke',
			'Glabbeek',
			'Geetbets',
			'Gedinne',
			'Feluy',
			'Dentergem',
			'De Klinge',
			'Deerlijk',
			'Cheratte',
			'Charleroi',
			'Breendonk',
			'Brecht',
			'Beveren-Leie',
			'Bellefontaine',
			'Aubange',
			'Anzegem',
			'Ans',
			'Alsemberg',
			'Petegem-aan-de-Schelde',
			'Tielt',
			'Suarlée',
			'Stokrooie',
			'Souvret',
			"'s-Gravenwezel",
			'Ruisbroek',
			'Robertville',
			'Pittem',
			'Overijse',
			'Outer',
			'Ophasselt',
			'Nassogne',
			'Morkhoven',
			'Melsen',
			'Meerdonk',
			'Meer',
			'La Hulpe',
			'Kuringen',
			'Kortrijk-Dutsel',
			'Koekelberg',
			'Jemeppe-sur-Sambre',
			'Houyet',
			'Hognoul',
			'Heppenbach',
			'Hakendover',
			'Haillot',
			'Gruitrode',
			'Gozée',
			'Gougnies',
			'Gottignies',
			'Zuienkerke',
			'Zolder',
			'Zepperen',
			'Xhendremael',
			'Wortegem',
			'Wijer',
			'Westvleteren',
			'Welkenraedt',
			'Wauthier-Braine',
			'Watermael-Boitsfort',
			'Waanrode',
			'Vinkt',
			'Torhout',
			'Genappe',
			'Geel',
			'Eupen',
			'Duisburg',
			'Drongen',
			'Charneux',
			'Braives',
			'Bracquegnies',
			'Bouillon',
			'Blanden',
			'Beek',
			'Bas-Oha',
			'Anvaing',
			'Aische-en-Refail',
			'Sint-Pieters-Leeuw',
			'Sibret',
			'Ranst',
			'Ramsel',
			'Paliseul',
			'Overpelt',
			'Oudenaarde',
			'Ostend',
			'On',
			'Odeur',
			'Ochamps',
			'Noduwez',
			'Nieuwkerke',
			'Wilsele',
			'Westerlo',
			'Vremde',
			'Vlezenbeek',
			'Vinderhoute',
			'Vierset-Barse',
			'Vezin',
			'Trazegnies',
			'Toernich',
			'Saint-Remy',
			'Rouvroy',
			'Rollegem-Kapelle',
			'Moorslede',
			'Monceau-sur-Sambre',
			'Merkem',
			'Lovenjoel',
			'Liers',
			'Leest',
			'Landelies',
			'Lanaken',
			'Lambermont',
			'Kampenhout',
			'Kain',
			'Ittre',
			'Ingooigem',
			'Huy',
			'Hulste',
			'Horrues',
			'Herfelingen',
			'Geluveld',
			'Frasnes-lez-Buissenal',
			'Frasnes',
			'Flobecq',
			'Emblem',
			'Dinant',
			'Dilbeek',
			'Desteldonk',
			'Dalhem',
			'Corbais',
			'Chimay',
			'Cerfontaine',
			'Bossière',
			'Boncelles',
			'Beerzel',
			'Beauvechain',
			'Barry',
			'Andrimont',
			'Ampsin',
			'Zoutleeuw',
			'Zemst',
			'Zellik',
			'Xhendelesse',
			'Wellen',
			'Waremme',
			'Vliermaalroot',
			'Villers-le-Temple',
			'Vertrijk',
			'Tongrinne',
			'Slins',
			'Sint-Joris-Winge',
			'Rumillies',
			'Pont-de-Loup',
			'Ottignies',
			'Opwijk',
			'Oostmalle',
			'Oostkamp',
			'Oostakker',
			'Okegem',
			'Nukerke',
			'Nil-Saint-Vincent-Saint-Martin',
			'Ransart',
			'Oorbeek',
			'Ohey',
			'Neuville',
			'Moha',
			'Melsele',
			'Marche-lez-Écaussinnes',
			'Marche-en-Famenne',
			'Lo',
			'Limerlé',
			'Landen',
			'Kortenberg',
			'Koningshooikt',
			'Kessel-Lo',
			'Juprelle',
			'Jemeppe-sur-Meuse',
			'Ichtegem',
			'Huppaye',
			'Hertsberge',
			'Herstal',
			'Hanret',
			'Halen',
			'Grote-Spouwen',
			'Ertvelde',
			'Enghien',
			'Élouges',
			'Drogenbos',
			'Butgenbach',
			'Brussegem',
			'Bouffioulx',
			'Borsbeke',
			'Bonlez',
			'Blauwput',
			'Bevere',
			'Bevel',
			'Berg',
			'Berbroek',
			'Bekkevoort',
			'Basse Lasne',
			'Aye',
			'Yvoir',
			'Wilrijk',
			'Waterloo',
			'Warchin',
			'Wanze',
			'Vosselaar',
			'Virginal-Samme',
			'Vichte',
			'Vaux-sur-Sûre',
			'Tourinnes-Saint-Lambert',
			'Stabroek',
			'Sougné-Remouchamps',
			'Somme-Leuze',
			'Sint-Truiden',
			'Sint-Martens-Leerne',
			'Sint-Lievens-Houtem',
			'Sint-Laureins',
			'Sint-Agatha-Rode',
			'Serinchamps',
			'Seloignes',
			'Seilles',
			'Saint-Mard',
			'Saint-Léger',
			'Sainte-Marie-sur-Semois',
			'Rhisnes',
			'Reppel',
			'Piétrebais',
			'Perwez',
			'Oupeye',
			'Nossegem',
			'Nobressart',
			'Morialmé',
			'Montignies-sur-Sambre',
			'Molenbeek-Saint-Jean',
			'Modave',
			'Middelkerke',
			"Meslin-l'Évêque",
			'Meerbeke',
			'Mazenzele',
			'Mariembourg',
			'Marenne',
			'Lippelo',
			'Leeuwergem',
			'Kessenich',
			'Hoevenen',
			'Herzele',
			'Herderen',
			'Henri-Chapelle',
			'Haulchin',
			'Hansbeke',
			'Haltinne',
			'Grotenberge',
			'Evere',
			'Étalle',
			'Erpent',
			'Eppegem',
			'Elversele',
			'Eine',
			"Écaussinnes-d'Enghien",
			'Diepenbeek',
			'Couvin',
			'Courrière',
			'Champlon',
			'Céroux-Mousty',
			'Borsbeek',
			'Borgerhout',
			'Belgrade',
			'Beerst',
			'Bassenge',
			'Awirs',
			'Averbode',
			'Auderghem',
			'Ath',
			'Zarren',
			'Woesten',
			'Wichelen',
			'Welle',
			'Wechelderzande',
			'Wangenies',
			'Wakken',
			'Waarloos',
			'Vitrival',
			'Strée-lez-Huy',
			'Sint-Katelijne-Waver',
			'Sart-Bernard',
			'Rotheux-Rimière',
			'Rocourt',
			'Quiévrain',
			'Pulle',
			'Poederlee',
			'Péruwelz',
			'Oedelem',
			'Neufvilles',
			'Nazareth',
			'Moorsele',
			'Molenbeersel',
			'Masnuy-Saint-Jean',
			'Manhay',
			'Lubbeek',
			'Loyers',
			'Lesve',
			'Kleine-Brogel',
			'Kapellen',
			'Housse',
			'Houdeng-Goegnies',
			'Herent',
			'Hamme-Mille',
			'Halle',
			'Haccourt',
			'Haaltert',
			'Gijzegem',
			'Sint-Amands',
			'Schelle',
			'Remicourt',
			'Ramsdonk',
			'Ramillies',
			'Neu-Moresnet',
			'Mouscron',
			'Gaurain-Ramecroix',
			'Franière',
			'Everberg',
			'Estaimpuis',
			'Esneux',
			'Elen',
			'Edegem',
			'Courcelles',
			'Châtelet',
			'Cerexhe-Heuseux',
			'Buggenhout',
			'Bressoux',
			'Boussu',
			'Bierghes',
			'Berlare',
			'Beigem',
			'Beaumont',
			'Aartrijke',
			'Zelzate',
			'Wolkrange',
			'Winksele',
			'Wavre',
			'Wachtebeke',
			'Vinalmont',
			'Thisnes',
			'Tellin',
			'Sprimont',
			'Sint-Genesius-Rode',
			'Scherpenheuvel',
			'Schendelbeke',
			'Ressegem',
			'Reet',
			'Oostduinkerke',
			'Musson',
			'Meyerode',
			'Meulebeke',
			'Melsbroek',
			'Meerle',
			'Marquain',
			'Lovendegem',
			'Longchamps',
			'La Gleize',
			'Kachtem',
			'Jemappes',
			'Hévillers',
			'Heusy',
			'Herdersem',
			'Hekelgem',
			'Heinsch',
			'Hamipré',
			'Grand-Hallet',
			'Gavere',
			'Dergneau',
			'Dendermonde',
			'Ciplet',
			'Boussoit',
			'Bernissart',
			'Aywaille',
			'Assent',
			'Appelterre-Eichem',
			'Zandvoorde',
			'Wondelgem',
			'Wegnez',
			'Weelde',
			'Veldegem',
			'Vaulx-lez-Tournai',
			'Val-Meer',
			'Ternat',
			'Tenneville',
			'Stavelot',
			'Spalbeek',
			'Smetlede',
			'Sint-Martens',
			'Sint-Eloois-Vijve',
			'Saint-Denis',
			'Rèves',
			'Oostham',
			'Sint-Lievens-Esse',
			'Saint-Servais',
			'Rochefort',
			'Orp-le-Grand',
			'Noville-les-Bois',
			'Noorderwijk',
			'Neeroeteren',
			'Nalinnes',
			'Mielen-boven-Aalst',
			'Merendree',
			'Mechelen-Bovelingen',
			'Maldegem',
			'Mal',
			'Luingne',
			'Lommel',
			'Lixhe',
			'Lichtervelde',
			'Leuze',
			'La Reid',
			'Kaggevinne',
			'Hornu',
			'Hoegaarden',
			'Herchies',
			'Hemiksem',
			'Heestert',
			'Halle',
			'Habay-la-Vieille',
			'Godinne',
			'Geluwe',
			'Gelinden',
			'Froyennes',
			'Fexhe-Slins',
			'Ellezelles',
			'Eksel',
			'Eeklo',
			'De Haan',
			'Comblain-Fairon',
			'Boorsem',
			'Bois-de-Villers',
			'Bois-de-Lessines',
			'Binche',
			'Beselare',
			'Belsele',
			'Bellecourt',
			'Bassilly',
			'Barchon',
			'Aubel',
			'Aspelare',
			'Tihange',
			'Sint-Antonius',
			'Ruisbroek',
			'Romsée',
			'Recht',
			'Pepinster',
			'Opgrimbie',
			'Opglabbeek',
			'Ohain',
			'Néchin',
			'Membach',
			'Melen',
			'Magnée',
			'Luttre',
			'Léglise',
			'La Calamine',
			'Kruishoutem',
			'Kontich',
			'Kaulille',
			'Hofstade',
			'Hoepertingen',
			'Hermée',
			'Herk-de-Stad',
			'Hérinnes',
			'Grand-Leez',
			'Givry',
			'Gilly',
			'Watervliet',
			'Ville-sur-Haine',
			'Vellereille-les-Brayeux',
			'Veldwezelt',
			'Trooz',
			'Sint-Amandsberg',
			'Saint-Denis',
			'Ravels',
			'Prayon',
			'Poelkapelle',
			'Pâturages',
			'Overboelare',
			'Oudegem',
			'Evergem',
			'Everbeek',
			'Couture-Saint-Germain',
			'Couillet',
			'Ciney',
			'Chaumont-Gistoux',
			'Chapelle-lez-Herlaimont',
			'Bottelare',
			'Blankenberge',
			'Bissegem',
			'Bellegem',
			'Balegem',
			'Asse',
			'Antheit',
			'Zulte',
			'Wihogne',
			'Zétrud-Lumay',
			'Wijnegem',
			'Walem',
			'Vladslo',
			'Varsenare',
			'Vance',
			'Ursel',
			'Tourinnes-la-Grosse',
			'Tilff',
			'Stevoort',
			'Steenokkerzeel',
			'Sint-Denijs',
			'Schriek',
			'Saint-Georges-sur-Meuse',
			'Rummen',
			'Rotem',
			'Roselies',
			'Rekkem',
			'Rance',
			'Presles',
			'Poperinge',
			'Pipaix',
			'Obourg',
			'Nevele',
			'Montzen',
			'Maasmechelen',
			'Lot',
			'Loonbeek',
			'Lillois-Witterzée',
			'Lembeke',
			'La Hestre',
			'Jodoigne',
			'Hergenrath',
			'Genval',
			'Gentbrugge',
			'Gemmenich',
			'Fraipont',
			"'s-Gravenvoeren",
			'Floriffoux',
			'Faimes',
			'Escanaffles',
			'Erembodegem',
			'Ellignies-Sainte-Anne',
			'Elewijt',
			'Donk',
			'Destelbergen',
			'Denderbelle',
			'Brustem',
			"Braine-l'Alleud",
			'Bovekerke',
			'Bousval',
			'Bléharies',
			'Bertem',
			'Bavegem',
			'Baal',
			'Attenrode',
			'Athus',
			'Argenteau',
			'Aalst',
			'Zingem',
			'Willebroek',
			'Wervik',
			'Wasmes',
			'Waret-la-Chaussée',
			'Wandre',
			'Walsbets',
			'Verrebroek',
			'Vedrin',
			'Uitkerke',
			'Tildonk',
			'Thuin',
			'Sint-Lambrechts-Herk',
			'Sclayn',
			'Schaltin',
			'Rijkevorsel',
			'Rebecq-Rognon',
			'Orcq',
			'Orbais',
			'Nieuwerkerken',
			'Mornimont',
			'Mettet',
			'Merbes-le-Château',
			'Meensel-Kiezegem',
			'Mariekerke',
			'Lummen',
			'Lotenhulle',
			'Lontzen',
			'Lier',
			'Libramont',
			'Lendelede',
			'Leffinge',
			'Kortemark',
			'Klerken',
			'Kerksken',
			'Jupille-sur-Meuse',
			'Iddergem',
			'Huizingen',
			'Hoeselt',
			'Hillegem',
			'Haasdonk',
			'Grote-Brogel',
			'Grimbergen',
			'Grembergen',
			'Gingelom',
			'Geraardsbergen',
			'Francorchamps',
			'Forville',
			'Forêt',
			'Fayt-lez-Manage',
			'Faulx-Les Tombes',
			'Estaimbourg',
			'Erpe',
			'Ekeren',
			'De Panne',
			'Dave',
			'Dampremy',
			'Crisnée',
			'Budingen',
			'Borchtlombeek',
			'Beloeil',
			'Anthisnes',
			'Anhée',
			'Zichem',
			'Vurste',
			'Vucht',
			'Vorst',
			'Vivegnis',
			'Veerle',
			'Tongerlo',
			'Tienen',
			'Thuillies',
			'Thiméon',
			'Taintignies',
			'Stekene',
			'Sint-Gillis-bij-Dendermonde',
			'Saint-Gilles',
			'Moorsel',
			'Mont-sur-Marchienne',
			'Moen',
			'Minderhout',
			'Mignault',
			'Meldert',
			'Marcinelle',
			'Marbais',
			'Lobbes',
			'Linkebeek',
			'Lille',
			'Liedekerke',
			'Kaprijke',
			'Jalhay',
			'Idegem',
			'Hyon',
			'Hermalle-sous-Huy',
			'Herenthout',
			'Hallaar',
			'Haasrode',
			'Haacht',
			'Grand-Rechain',
			'Gouvy',
			'Glain',
			'Gierle',
			'Thieusies',
			'Tavier',
			'Sint-Denijs-Westrem',
			'Saint-Nicolas',
			'Polleur',
			'Ploegsteert',
			'Pamel',
			'Opitter',
			'Wenduine',
			'Walcourt',
			'Waha',
			'Visé',
			'Virton',
			'Villers-Perwin',
			'Trois-Ponts',
			'Trembleur',
			'Strombeek-Bever',
			'Silly',
			'Saint-Yvon',
			'Saint-Vith',
			'Saint-Marc',
			'Rollegem',
			'Queue-du-Bois',
			'Peutie',
			'Outrijve',
			'Ormeignies',
			'Oeselgem',
			'Neerijse',
			'Momignies',
			'Nieuwrode',
			'Neerheylissem',
			'Millen',
			'Merchtem',
			'Meeuwen',
			'Maurage',
			'Maffle',
			'Machelen-aan-de-Leie',
			'Loncin',
			'Loenhout',
			'Ligny',
			'Liezele',
			'Leuven',
			'Knesselare',
			'Kettenis',
			'Ingelmunster',
			'Houwaart',
			'Houthulst',
			'Hoeilaart',
			'Heppignies',
			'Hannut',
			'Groot-Bijgaarden',
			'Grandhan',
			'Gosselies',
			'Goeferdinge',
			'Godveerdegem',
			'Gleixhe',
			'Forrières',
			'Éthe',
			'Ernage',
			'Erbisoeul',
			'Elst',
			'Dikkebus',
			'Dhuy',
			'Cul-des-Sarts',
			'Comines',
			'Chênée',
			'Buissonville',
			'Braine-le-Comte',
			'Beringen',
			'Bellaire',
			'Battice',
			'Alleur',
			'Terkoest',
			'Etterbeek',
			'Eisden',
			'Egem',
			'Diksmuide',
			'Deurne',
			'Deurle',
			'Bunsbeek',
			'Brunehault',
			'Brugelette',
			'Bon-Secours',
			'Bonneville',
			'Bavikhove',
			'Auvelais',
			'Aulnois',
			'Anseremme',
			'Anderlecht',
			'Sint-Joris',
			'Ruddervoorde',
			'Roeselare',
			'Peer',
			'Oostvleteren',
			'Oelegem',
			'Neerwinden',
			'Mélin',
			'Meix-devant-Virton',
			'Louveigné',
			'Lodelinsart',
			'Kortessem',
			'Koksijde',
			'Kallo',
			'Jamioulx',
			'Hulshout',
			'Hingene',
			'Heusden',
			'Hermalle-sous-Argenteau',
			'Hennuyères',
			'Zutendaal',
			'Wilderen',
			'Wieze',
			'Wielsbeke',
			'Westmeerbeek',
			'Ways',
			'Walhorn',
			'Vroenhoven',
			'Strée',
			'Sterrebeek',
			'Sint-Pieters-Kapelle',
			'Sint-Niklaas',
			'Sint-Michiels',
			'Sint-Martens-Lierde',
			'Rulles',
			'Rongy',
			'Proven',
			'Opoeteren',
			'Flénu',
			'Flawinne',
			'Érezée',
			'Dour',
			'Diest',
			'Deftinge',
			'Corswarem',
			'Colfontaine',
			'Casteau',
			'Burcht',
			'Bredene',
			'Bonnert',
			'Boechout',
			'Bocholt',
			'Binkom',
			'Begijnendijk',
			'Beernem',
			'Assenois',
			'Assenede',
			'Opvelp',
			'Ollignies',
			'Moustier-sur-Sambre',
			'Morlanwelz-Mariemont',
			'Zele',
			'Wingene',
			'Westmalle',
			'Vossem',
			'Vlierzele',
			'Verlaine',
			'Tollembeek',
			'Spa',
			'Sint-Kwintens-Lennik',
			'Sint-Kruis',
			'Sauvenière',
			'Sainte-Ode',
			'Richelle',
			'Retinne',
			'Ressaix',
			'Profondeville',
			'Philippeville',
			'Ophoven',
			'Oevel',
			'Nimy',
			'Mollem',
			'Meise',
			'Maubray',
			'Manage',
			'Malonne',
			'Mainvault',
			'Leval-Trahegnies',
			'Lessines',
			'Ledegem',
			'Lede',
			'Kwaadmechelen',
			'Keiem',
			'Kapelle-op-den-Bos',
			'Jauche',
			'Houthem',
			'Hoogstraten',
			'Heist',
			'Havelange',
			'Hasselt',
			'Harzé',
			'Hanzinelle',
			'Habay-la-Neuve',
			'Grammene',
			'Gouy-lez-Piéton',
		],
	},
	Belize: {
		country: 'Belize',
		cities: [
			'Santa Familia',
			'San Pedro',
			'Carmelita',
			'Caye Caulker',
			'Little Belize',
			'Belmopan',
			'Xaibe',
			'Sarteneja',
			'San Narciso',
			'Orange Walk',
			'Ladyville',
			'San Antonio',
			'Valley of Peace',
			'San Jose',
			'Progresso',
			'Dangriga',
			'Corozal',
			'Belize City',
			'Independence and Mango Creek',
			'Placencia',
			'San Ignacio',
			'San Felipe',
			'Shipyard',
			'Punta Gorda',
			'Esperanza',
			'Benque Viejo el Carmen',
		],
	},
	Benin: {
		country: 'Benin',
		cities: [
			'Hinvi',
			'Bassila',
			'Banigbé',
			'Aplahoué',
			'Houinvigue',
			'So-Ava',
			'Pahou',
			'Goumori',
			'Comé',
			'Sakété',
			'Zagnanado',
			'Parakou',
			'Allada',
			'Tanguiéta',
			'Hévié',
			'Grand-Popo',
			'Bohicon',
			'Guilmaro',
			'Bétérou',
			'Angara-Débou',
			'Tchaourou',
			'Pobé',
			'Cotonou',
			'Bembèrèkè',
			'Avakpa',
			'Adjohon',
			'Semi',
			'Ouinhi',
			'Savalou',
			'Kandi',
			'Abomey',
			'Lokossa',
			'Savé',
			'Dogbo',
			'Kpomassè',
			'Sé',
			'Malanville',
			'Dassa-Zoumé',
			'Ouidah',
			'Natitingou',
			'Cové',
			'Nikki',
			'Porto-Novo',
			'Kétou',
			'Djougou',
			'Banikoara',
			'Abomey-Calavi',
		],
	},
	Bermuda: { country: 'Bermuda', cities: ['Saint George', 'Hamilton'] },
	Bhutan: {
		country: 'Bhutan',
		cities: [
			'Trongsa',
			'Gasa',
			'Panbang',
			'Daphu',
			'Phuntsholing',
			'Daga',
			'Samtse',
			'Pemagatshel',
			'Jakar',
			'Sarpang',
			'Pajo',
			'Trashigang',
			'Ha',
			'Paro',
			'Tsirang',
			'Laya',
			'Trashi Yangtse',
			'Punākha',
			'Mongar',
			'Thimphu',
			'Tsimasham',
			'Shemgang',
			'Samdrup Jongkhar',
			'Wangdue Phodrang',
			'Lhuentse',
			'Lungtenzampa',
		],
	},
	Bolivia: {
		country: 'Bolivia',
		cities: [
			'Totoral',
			'Portachuelo',
			'Okinawa Número Uno',
			'Guayaramerín',
			'Guanay',
			'Cotoca',
			'Cotoca',
			'Ascensión',
			'Aiquile',
			'Villazón',
			'Tarija',
			'Boyuibe',
			'Santa Ana de Yacuma',
			'Punata',
			'Huarina',
			'Quime',
			'Capinota',
			'Cochabamba',
			'Cliza',
			'Camargo',
			'San Matías',
			'Viloco',
			'Villamontes',
			'Villa Charcas',
			'Santa Rosa del Sara',
			'Santa Rita',
			'Limoncito',
			'La Bélgica',
			'Jorochito',
			'Irpa Irpa',
			'Poopó',
			'Los Negros',
			'Atocha',
			'Santa Rosa de Yacuma',
			'Huanuni',
			'Achacachi',
			'San Ignacio de Velasco',
			'Sacaba',
			'Rurrenabaque',
			'Entre Ríos',
			'Colcapirhua',
			'Betanzos',
			'Mineros',
			'Mecapaca',
			'Caranavi',
			'Abapó Viejo',
			'Puearto Pailas',
			'Eucaliptus',
			'Comarapa',
			'Colquechaca',
			'Andamarca',
			'Villa Serrano',
			'Trinidad',
			'Calchani',
			'Cobija',
			'Ascención de Guarayos',
			'Vinto',
			'El Torno',
			'Paurito',
			'Independencia',
			'Curahuara de Carangas',
			'Coripata',
			'Colchani',
			'Tupiza',
			'Sorata',
			'Sipe Sipe',
			'Monteagudo',
			'Yumani',
			'Buena Vista',
			'Batallas',
			'Sucre',
			'San Ramón',
			'Camiri',
			'Santa Fe',
			'San Pedro',
			'Villa Yapacaní',
			'Tarata',
			'Tarabuco',
			'Concepción',
			'Yamparáez',
			'Warnes',
			'Vallegrande',
			'Tiahuanaco',
			'Llallagua',
			'Uyuni',
			'Urubichá',
			'Machacamarca',
			'Charagua',
			'San Borja',
			'San Pablo de Tiquina',
			'Mairana',
			'Laja',
			'Campamento Colquiri',
			'Nueva Manoa',
			'Totora',
			'Santa Cruz de la Sierra',
			'San Juan del Surutú',
			'Chulumani',
			'Arani',
			'Viacha',
			'San Julian',
			'San Carlos',
			'Quillacollo',
			'Puerto Pailas',
			'Padilla',
			'Mapiri',
			'Amarete',
			'Achocalla',
			'Puerto Quijarro',
			'Yacuiba',
			'Santa Bárbara',
			'Pailón',
			'Mizque',
			'Bermejo',
			'Samaipata',
			'Lahuachaca',
			'Challapata',
			'Roboré',
			'Montero',
			'La Paz',
			'Huachacalla',
			'San Julian',
			'Tiquipaya',
			'Reyes',
			'Potosí',
			'Patacamaya',
			'Oruro',
			'Colomi',
			'Chimoré',
			'Cruz de Machacamarca',
			'San Pedro',
			'Riberalta',
			'Huatajata',
			'Coroico',
		],
	},
	'Bosnia and Herzegovina': {
		country: 'Bosnia and Herzegovina',
		cities: [
			'Živinice',
			'Stjepan-Polje',
			'Prnjavor',
			'Lokvine',
			'Gornji Ribnik',
			'Goražde',
			'Donja Dubica',
			'Vidoši',
			'Velagići',
			'Tasovčići',
			'Rodoč',
			'Drvar',
			'Polje',
			'Vrnograč',
			'Otoka',
			'Jezerski',
			'Ilijaš',
			'Žepče',
			'Velika Kladuša',
			'Travnik',
			'Podzvizd',
			'Gračanica',
			'Fojnica',
			'Bužim',
			'Brod',
			'Skokovi',
			'Gornja Breza',
			'Tržačka Raštela',
			'Klokotnica',
			'Hadžići',
			'Gostovići',
			'Dubravica',
			'Drinić',
			'Donja Mahala',
			'Pajić Polje',
			'Željezno Polje',
			'Potoci',
			'Pećigrad',
			'Novo Selo',
			'Ljubuški',
			'Kiseljak',
			'Tomislavgrad',
			'Drinovci',
			'Careva Ćuprija',
			'Konjic',
			'Zvornik',
			'Vukovije Donje',
			'Tojšići',
			'Sapna',
			'Petkovci',
			'Međugorje',
			'Lukavac',
			'Kotor Varoš',
			'Gromiljak',
			'Gornja Koprivna',
			'Brčko',
			'Bijeljina',
			'Banja Luka',
			'Lamovita',
			'Barice',
			'Bijela',
			'Široki Brijeg',
			'Kopači',
			'Ključ',
			'Hreša',
			'Bosanska Krupa',
			'Hotonj',
			'Polje-Bijela',
			'Krupa na Vrbasu',
			'Teočak',
			'Kreševo',
			'Kakanj',
			'Bihać',
			'Balatun',
			'Knežica',
			'Jablanica',
			'Vozuća',
			'Vogošća',
			'Tuzla',
			'Svodna',
			'Stijena',
			'Sanski Most',
			'Jezero',
			'Doboj',
			'Bileća',
			'Ilići',
			'Orašac',
			'Mrkonjić Grad',
			'Lukavica',
			'Kalenderovci Donji',
			'Gorica',
			'Gnojnica',
			'Čečava',
			'Čajniče',
			'Breza',
			'Kozarska Dubica',
			'Blatnica',
			'Jelah',
			'Piskavica',
			'Živinice',
			'Zenica',
			'Omarska',
			'Olovo',
			'Nevesinje',
			'Lopare',
			'Kočerin',
			'Bronzani Majdan',
			'Drežnica',
			'Zborište',
			'Sokolac',
			'Sanica',
			'Grude',
			'Dubrave Donje',
			'Dobrinje',
			'Bosanski Petrovac',
			'Bila',
			'Čelinac',
			'Liješnica',
			'Kačuni',
			'Zabrišće',
			'Vareš',
			'Šerići',
			'Gračanica',
			'Gornji Vakuf',
			'Donji Vakuf',
			'Čitluk',
			'Čelić',
			'Petrovo',
			'Malešići',
			'Ćoralići',
			'Voljevac',
			'Šturlić',
			'Šipovo',
			'Ravno',
			'Puračić',
			'Mostar',
			'Jajce',
			'Dobrljin',
			'Kostajnica',
			'Tešanjka',
			'Vitez',
			'Teslić',
			'Novi Travnik',
			'Potoci',
			'Glamoč',
			'Tešanj',
			'Srebrenica',
			'Kalesija',
			'Ilidža',
			'Čapljina',
			'Vlasenica',
			'Šekovići',
			'Prijedor',
			'Prača',
			'Pelagićevo',
			'Novi Šeher',
			'Bosansko Grahovo',
			'Šamac',
			'Banovići',
			'Koran',
			'Stanari',
			'Kovači',
			'Mejdan - Obilićevo',
			'Neum',
			'Ljubinje',
			'Kobilja Glava',
			'Kladanj',
			'Gornja Tuzla',
			'Bugojno',
			'Svojat',
			'Šumatac',
			'Stupari',
			'Solina',
			'Sarajevo',
			'Omanjska',
			'Maglajani',
			'Maglaj',
			'Kalinovik',
			'Janja',
			'Visoko',
			'Višegrad',
			'Trebinje',
			'Pale',
			'Milići',
			'Livno',
			'Gornje Moštre',
			'Dubrave Gornje',
			'Donja Međiđa',
			'Busovača',
			'Turbe',
			'Radišići',
			'Prozor',
			'Posušje',
			'Podhum',
			'Oštra Luka',
			'Marićka',
			'Mahala',
			'Laktaši',
			'Kiseljak',
			'Čelinac',
			'Gradiška',
			'Dobratići',
			'Podbrdo',
			'Vitina',
			'Varoška Rijeka',
			'Trn',
			'Pribinić',
			'Odžak',
			'Kupres',
			'Cim',
			'Cazin',
			'Pjanići',
			'Kneževo',
			'Rogatica',
			'Peći',
			'Oštra Luka',
			'Matuzići',
			'Gornje Živinice',
			'Derventa',
			'Brka',
			'Jezero',
			'Blagaj',
			'Hiseti',
			'Todorovo',
			'Trnovo',
			'Suho Polje',
			'Rumboci',
			'Priboj',
			'Pazarić',
			'Orahovica Donja',
			'Obudovac',
			'Han Pijesak',
			'Gacko',
			'Donji Dubovik',
			'Bratunac',
			'Berkovići',
			'Starcevica',
			'Crnići',
			'Velika Obarska',
			'Ugljevik',
			'Sladna',
			'Ostrožac',
			'Orašje',
			'Mionica',
			'Maslovare',
			'Izačić',
			'Donji Žabar',
			'Divičani',
			'Karadaglije',
			'Dvorovi',
			'Zavidovići',
			'Ustikolina',
			'Stolac',
			'Rudo',
			'Mramor',
			'Novi Grad',
			'Srebrenik',
			'Srbac',
			'Popovi',
			'Osmaci',
			'Orguz',
			'Mala Kladuša',
			'Lukavica',
			'Gradačac',
			'Foča',
			'Domaljevac',
		],
	},
	Botswana: {
		country: 'Botswana',
		cities: [
			'Sefophe',
			'Makaleng',
			'Gaphatshwe',
			'Tonota',
			'Kopong',
			'Francistown',
			'Dukwe',
			'Ratholo',
			'Palapye',
			'Mopipi',
			'Moijabana',
			'Mathakola',
			'Khakhea',
			'Janeng',
			'Hukuntsi',
			'Tobane',
			'Pandamatenga',
			'Nokaneng',
			'Manyana',
			'Makoba',
			'Pilikwe',
			'Mogapinyana',
			'Jwaneng',
			'Tamasane',
			'Mogapi',
			'Letlhakane',
			'Tsau',
			'Ramokgonami',
			'Gabane',
			'Nata',
			'Maunatlala',
			'Lenchwe Le Tau',
			'Letsheng',
			'Werda',
			'Thamaga',
			'Lehututu',
			'Gobojango',
			'Serowe',
			'Otse',
			'Makobeng',
			'Mmathubudukwane',
			'Molepolole',
			'Mogoditshane',
			'Gweta',
			'Maapi',
			'Tshabong',
			'Selebi-Phikwe',
			'Mosopa',
			'Mathathane',
			'Makwata',
			'Mabuli',
			'Khudumelapye',
			'Kasane',
			'Ghanzi',
			'Mmopone',
			'Maun',
			'Kurametsi',
			'Sua',
			'Mochudi',
			'Mahalapye',
			'Botlhapatlou',
			'Bokaa',
			'Metsemotlhaba',
			'Nkoyaphiri',
			'Ramotswa',
			'Rakops',
			'Mosetse',
			'Kanye',
			'Mookane',
			'Lokwabe',
			'Kalamare',
			'Dutlwe',
			'Sekoma',
			'Mathambgwane',
			'Kang',
			'Shakawe',
			'Pilane',
			'Orapa',
			'Masunga',
			'Sehithwa',
			'Machaneng',
			'Sebina',
			'Lobatse',
			'Dekar',
			'Magothla',
			'Letlhakeng',
			'Gaborone',
		],
	},
	Brazil: {
		country: 'Brazil',
		cities: [
			'Tianguá',
			'São João dos Inhamuns',
			'Taquarana',
			'Tangará',
			'Surubim',
			'Sertânia',
			'Santa Luzia',
			'Raposa',
			'Porto Calvo',
			'Pio IX',
			'Parambu',
			'Pão de Açúcar',
			'Novo Oriente',
			'Lavras da Mangabeira',
			'Juàzeirinho',
			'Independência',
			'Igarapé Açu',
			'Igaci',
			'Flexeiras',
			'Fagundes',
			'Cruz do Espírito Santo',
			'Camocim',
			'Barreirinha',
			'Vila Velha',
			'Vera Cruz',
			'Valença',
			'Vacaria',
			'Tremedal',
			'São Roque',
			'São Lourenço do Sul',
			'São Felipe',
			'Santa Terezinha',
			'Santa Cruz Cabrália',
			'Rio das Ostras',
			'Ribas do Rio Pardo',
			'Regente Feijó',
			'Ponta Porã',
			'Pitangueiras',
			'Pirajuí',
			'Penedo',
			'Paraíso das Águas',
			'Orleans',
			'Orlândia',
			'Mucuri',
			'Morro do Chapéu',
			'Maragogipe',
			'Malacacheta',
			'Mafra',
			'Lagarto',
			'Itamarandiba',
			'Itabaianinha',
			'Iguape',
			'Igreja Nova',
			'Guapimirim',
			'Guanhães',
			'Cruzeiro do Oeste',
			'Crixás',
			'Conchas',
			'Cidreira',
			'Caraguatatuba',
			'Capão da Canoa',
			'Capâo Bonito',
			'Cândido de Abreu',
			'Buritis',
			'Brumadinho',
			'Braço do Norte',
			'Bernardino de Campos',
			'Batatais',
			'Bariri',
			'Astorga',
			'Areal',
			'Antonina',
			'Uarini',
			'Campo Limpo Paulista',
			'Fernando de Noronha (Distrito Estadual)',
			'Triunfo',
			'Quixadá',
			'Porto',
			'Piripiri',
			'Pimenteiras',
			'Pesqueira',
			'Maués',
			'Lagoa Seca',
			'João Câmara',
			'Itainópolis',
			'Ipubi',
			'Exu',
			'Carolina',
			'Cariré',
			'Canindé',
			'Araripe',
			'Afuá',
			'Ubatã',
			'Ubaitaba',
			'Timóteo',
			'Timbó',
			'Tapes',
			'Taiobeiras',
			'São Simão',
			'São Sebastião do Paraíso',
			'Santos Dumont',
			'Santa Rosa de Viterbo',
			'Santa Helena de Goiás',
			'Santa Adélia',
			'Pontalina',
			'Pitangui',
			'Piranhas',
			'Penha',
			'Paracatu',
			'Muzambinho',
			'Monte Sião',
			'Monte Mor',
			'Marechal Cândido Rondon',
			'Jequitinhonha',
			'Iturama',
			'Ituaçu',
			'Irecê',
			'Ijuí',
			'Guia Lopes da Laguna',
			'Guararema',
			'Florestópolis',
			'Cristalina',
			'Castelo',
			'Carangola',
			'Campos Altos',
			'Campestre',
			'Camaquã',
			'Boquim',
			'Bom Jesus do Galho',
			'Boituva',
			'Biguaçu',
			'Baixo Guandu',
			'Bady Bassitt',
			'Auriflama',
			'Aparecida do Taboado',
			'Alto Araguaia',
			'Almenara',
			'Pontal do Paraná',
			'Porto Walter',
			'Vargem Grande Paulista',
			'Trindade',
			'Vicência',
			'Várzea Alegre',
			'Umarizal',
			'Serra Branca',
			'Nova Floresta',
			'Natal',
			'Massaranduba',
			'Jundiá',
			'Delmiro Gouveia',
			'Cumaru',
			'Colinas',
			'Cantanhede',
			'Araioses',
			'Alagoinha',
			'Viamão',
			'Tiradentes',
			'Tijucas',
			'Suzano',
			'São José do Rio Pardo',
			'São Joaquim da Barra',
			'São Caetano do Sul',
			'Santo Augusto',
			'Santa Maria da Vitória',
			"Santa Bárbara d'Oeste",
			'Rolândia',
			'Rodeio',
			'Propriá',
			'Porto Feliz',
			'Peruíbe',
			'Palmeira das Missões',
			'Oliveira dos Brejinhos',
			'Olímpia',
			'Monte Alegre de Sergipe',
			'Marilândia',
			'Mairinque',
			'Lavras',
			'Lagoa Vermelha',
			'Ladário',
			'Jandaia do Sul',
			'Jacarezinho',
			'Itororó',
			'Itaqui',
			'Imbituba',
			'Içara',
			'Guarulhos',
			'Goianésia',
			'Estância',
			'Erechim',
			'Curvelo',
			'Coxim',
			'Coronel Fabriciano',
			'Conselheiro Pena',
			'Conchal',
			'Conceição do Jacuípe',
			'Conceição da Barra',
			'Carmo do Rio Claro',
			'Capitólio',
			'Capim Grosso',
			'Cambará',
			'Bom Jesus do Itabapoana',
			'Barbacena',
			'Araguaiana',
			'Alfredo Chaves',
			'São Gabriel da Cachoeira',
			'Manaus',
			'Jaboatão dos Guararapes',
			'Xinguara',
			'Tabira',
			'São Mateus do Maranhão',
			'São Luís do Quitunde',
			'Redenção',
			'Pindaré Mirim',
			'Oriximiná',
			'Monte Alegre',
			'Montanhas',
			'Miguel Alves',
			'Lagoa Nova',
			'Jucurutu',
			'João Alfredo',
			'Icó',
			'Ibateguara',
			'Forquilha',
			'Feira Nova',
			'Eusébio',
			'Esperança',
			'Demerval Lobão',
			'Cabedelo',
			'Bequimão',
			'Belém',
			'Arcoverde',
			'Tupanciretã',
			'Teófilo Otoni',
			'Schroeder',
			'Sarandi',
			'São Mateus do Sul',
			'São Lourenço do Oeste',
			'São Lourenço',
			'Santo Antônio da Platina',
			'Santa Leopoldina',
			'Sananduva',
			'Rio Tavares',
			'Presidente Epitácio',
			'Praia Grande',
			'Poxoréo',
			'Ponta Grossa',
			'Piracicaba',
			'Piracanjuba',
			'Palotina',
			'Palmeira',
			'Osasco',
			'Oliveira',
			'Novo Cruzeiro',
			'Monte Aprazível',
			'Ituverava',
			'Guarujá',
			'Gandu',
			'Espera Feliz',
			'Encruzilhada',
			'Coruripe',
			'Cláudio',
			'Cândido Mota',
			'Cajamar',
			'Caetité',
			'Biritiba Mirim',
			'Bataiporã',
			'Barra dos Coqueiros',
			'Aracruz',
			'Alvorada',
			'Alegre',
			'Águas Vermelhas',
			'Afonso Cláudio',
			'Acajutiba',
			'Anori',
			'Freguesia do Ribeirao da Ilha',
			'Viseu',
			'Tamandaré',
			'São Miguel do Guamá',
			'São Gonçalo do Amarante',
			'São Bento',
			'Recife',
			'Prainha',
			'Pinheiro',
			'Parnarama',
			'Oeiras do Pará',
			'Murici',
			'Matias Olímpio',
			'Mari',
			'Juripiranga',
			'Itaíba',
			'Dona Inês',
			'Cacimbinhas',
			'Bonito',
			'Bezerros',
			'Belém de São Francisco',
			'Aurora',
			'Votorantim',
			'Veranópolis',
			'Varzelândia',
			'Unaí',
			'Tobias Barreto',
			'Silvânia',
			'Siderópolis',
			'Santa Maria do Suaçuí',
			'Rio Pardo',
			'Quatis',
			'Pradópolis',
			'Poções',
			'Pelotas',
			'Paranavaí',
			'Paranaguá',
			'Paraisópolis',
			'Palhoça',
			'Novo Hamburgo',
			'Nova Petrópolis',
			'Nova Londrina',
			'Mata de São João',
			'Maraú',
			'Manhumirim',
			'Mandaguaçu',
			'Lajinha',
			'Laguna',
			'Lagoa Formosa',
			'Jarinu',
			'Jales',
			'Itarana',
			'Itapuranga',
			'Itagi',
			'Irati',
			'Iraquara',
			'Ipirá',
			'Hortolândia',
			'Guapó',
			'Firminópolis',
			'Ferraz de Vasconcelos',
			'Esmeraldas',
			'Corbélia',
			'Contagem',
			'Cantagalo',
			'Campinas',
			'Caculé',
			'Caconde',
			'Cachoeirinha',
			'Cabo Frio',
			'Betim',
			'Barreiras',
			'Barra Velha',
			'Barra do Bugres',
			'Araxá',
			'Apiaí',
			'Adamantina',
			'Envira',
			'Xapuri',
			'Guajará Mirim',
			'Monte Verde',
			'Morro da Cruz',
			'Nova Olina do Marnhao',
			'Urucará',
			'Urbano Santos',
			'Teixeira',
			'Taipu',
			'Sousa',
			'Santo Antônio',
			'Santa Helena',
			'Presidente Dutra',
			'Parintins',
			'Orós',
			'Novo Lino',
			'Missão Velha',
			'Macapá',
			'Lagoa da Canoa',
			'Estreito',
			'Custódia',
			'Crato',
			'Caxias',
			'Cascavel',
			'Campos Sales',
			'Arara',
			'Amontada',
			'Alagoa Grande',
			'Água Branca',
			'Vitória',
			'Urupês',
			'Tupi Paulista',
			'Tapejara',
			'São Lourenço da Serra',
			'Rio Claro',
			'Rio Bananal',
			'Pouso Alegre',
			'Porangaba',
			'Pomerode',
			'Piritiba',
			'Piraquara',
			'Pato Branco',
			'Paracambi',
			'Otacílio Costa',
			'Nova Prata',
			'Nova Olímpia',
			'Nova Aurora',
			'Nossa Senhora das Dores',
			'Muriaé',
			'Mogi das Cruzes',
			'Mirante do Paranapanema',
			'Miguel Pereira',
			'Matão',
			'Marechal Floriano',
			'Junqueirópolis',
			'Jitaúna',
			'Jardinópolis',
			'Jaguarão',
			'Jaguaquara',
			'Itabuna',
			'Itabirito',
			'Irupi',
			'Iperó',
			'Guaranésia',
			'Garça',
			'Corupá',
			'Conceição do Castelo',
			'Charqueada',
			'Carlos Barbosa',
			'Campo Largo',
			'Cajati',
			'Brumado',
			'Belo Horizonte',
			'Barra Mansa',
			'Araucária',
			'Araçoiaba da Serra',
			'Araci',
			'Araçatuba',
			'Além Paraíba',
			'Santo Antônio do Içá',
			'Manaquiri',
			'Jutaí',
			'Barcelos',
			'Senador Guiomard',
			'Alta Floresta',
			'Lago dos Rodrigues',
			'Santa Maria de Jetibá',
			'Pantanal',
			'Carianos',
			'São Pedro',
			'União',
			'Uauá',
			'Timbiras',
			'Tavares',
			'Solânea',
			'São José de Piranhas',
			'Santa Quitéria',
			'Santa Cruz do Capibaribe',
			'Pilar',
			'Pacatuba',
			'Mogeiro',
			'Macaíba',
			'Itaueira',
			'Itatuba',
			'Itaquitinga',
			'Itapipoca',
			'Humberto de Campos',
			'Gurupá',
			'Grajaú',
			'Fortaleza',
			'Capoeiras',
			'Alcântara',
			'Tremembé',
			'Sombrio',
			'Saubara',
			'São Sebastião do Passé',
			'São Sebastião do Caí',
			'Santos',
			'Santana',
			'Santaluz',
			'Rio Casca',
			'Rio Branco do Sul',
			'Reserva',
			'Pau Brasil',
			'Patos de Minas',
			'Nova Viçosa',
			'Mongaguá',
			'Minas Novas',
			'Mimoso do Sul',
			'Martinho Campos',
			'Mairiporã',
			'Macatuba',
			'Lagoa da Prata',
			'Joaíma',
			'Ituiutaba',
			'Ituberá',
			'Itacarambi',
			'Ibicaraí',
			'Hidrolândia',
			'Guaxupé',
			'Guaraniaçu',
			'Foz do Iguaçu',
			'Elói Mendes',
			'Conceição do Mato Dentro',
			'Cerqueira César',
			'Cassilândia',
			'Campo Mourão',
			'Campina Verde',
			'Cachoeiro de Itapemirim',
			'Botelhos',
			'Bonito',
			'Bicas',
			'Baependi',
			'Arenápolis',
			'Rio Branco',
			'Eirunepé',
			'Paraíso',
			'Viçosa do Ceará',
			'Taperoá',
			'Santa Luzia',
			'Salinópolis',
			'Pilar',
			'Óbidos',
			'Mamanguape',
			'Elesbão Veloso',
			'Conceição do Araguaia',
			'Colônia Leopoldina',
			'Catende',
			'Capitão Poço',
			'Campina Grande',
			'Cajazeiras',
			'Brejo da Madre de Deus',
			'Araçoiaba',
			'Aracati',
			'Agrestina',
			'Umuarama',
			'Terra Nova',
			'Taquari',
			'São Leopoldo',
			'São João de Meriti',
			'São João da Barra',
			'São Gotardo',
			'Santana de Parnaíba',
			'Rio Negrinho',
			'Quatro Barras',
			'Prudentópolis',
			'Pontal',
			'Poconé',
			'Piraju',
			'Parobé',
			'Paraíso',
			'Pará de Minas',
			'Pancas',
			'Paiçandu',
			'Osório',
			'Nuporanga',
			'Nova Odessa',
			'Nova Friburgo',
			'Matelândia',
			'Lorena',
			'Lençóis Paulista',
			'Jaraguá do Sul',
			'Itambacuri',
			'Inhapim',
			'Iguaba Grande',
			'Goianápolis',
			'Francisco Beltrão',
			'Dois Vizinhos',
			'Cavalcante',
			'Capela do Alto',
			'Campos Novos',
			'Camanducaia',
			'Cacequi',
			'Caçapava',
			'Buritama',
			'Brasília',
			'Borrazópolis',
			'Boracéia',
			'Balneário Camboriú',
			'Araraquara',
			'Alagoinhas',
			'Tefé',
			'Feijó',
			'Jaru',
			'Campinas',
			'Costeira do Pirajubae',
			'Atins',
			'Planaltina',
			'Vitorino Freire',
			'Terra Santa',
			'Santo Antônio do Tauá',
			'Santana',
			'Porto Franco',
			'Pombos',
			'Orobó',
			'Nazaré da Mata',
			'Monção',
			'Mazagão',
			'Luzilândia',
			'Girau do Ponciano',
			'Jacareacanga',
			'Itaituba',
			'Gararu',
			'Castelo do Piauí',
			'Caruaru',
			'Brejo',
			'Beneditinos',
			'Barreirinhas',
			'Vargem Alta',
			'Una',
			'Três Rios',
			'Serro',
			'Seabra',
			'Sarandi',
			'São Vicente',
			'São Fidélis',
			'São Domingos do Prata',
			'São Domingos',
			'Santo Ângelo',
			'Santa Fé do Sul',
			'Posse',
			'Porangatu',
			'Pilar do Sul',
			'Piedade',
			'Petrópolis',
			'Posto da Mata',
			'Passo Fundo',
			'Paratinga',
			'Padre Bernardo',
			'Olindina',
			'Nova Iguaçu',
			'Nobres',
			'Nazaré',
			'Mineiros',
			'Mauá',
			'Janaúba',
			'Gaspar',
			'Diadema',
			'Correia Pinto',
			'Cornélio Procópio',
			'Cataguases',
			'Cássia',
			'Campos do Jordão',
			'Cambuci',
			'Camaçari',
			'Bela Vista do Paraíso',
			'Bela Vista',
			'Bambuí',
			'Arauá',
			'Aquidauana',
			'Anitápolis',
			'Américo Brasiliense',
			'Aguaí',
			'Carauari',
			'Presidente Médici',
			'Uruçuí',
			'Uruburetama',
			'Soledade',
			'Serra Talhada',
			'Santana do Mundaú',
			'Santana do Ipanema',
			'Santana do Acaraú',
			'Pastos Bons',
			'Nova Russas',
			'Mirador',
			'Limoeiro',
			'Juruti',
			'Juá dos Vieiras',
			'Gilbués',
			'Esperantina',
			'Escada',
			'Canto do Buriti',
			'Campo Maior',
			'Caicó',
			'Caetés',
			'Buíque',
			'Volta Redonda',
			'Trindade',
			'Três Lagoas',
			'Teresópolis',
			'Taboão da Serra',
			'Socorro',
			'Sete Lagoas',
			'São Sebastião',
			'São Miguel do Iguaçu',
			'São Miguel do Araguaia',
			'São Francisco do Sul',
			'Santiago',
			'Santa Rita do Passa Quatro',
			'Rio Grande da Serra',
			'Promissão',
			'Presidente Prudente',
			'Planaltina',
			'Piraí',
			'Paranhos',
			'Medeiros Neto',
			'Juatuba',
			'Jaboticabal',
			'Itumbiara',
			'Itatiaia',
			'Itacaré',
			'Ipiaú',
			'Inhambupe',
			'Ibatiba',
			'Guarapari',
			'Goiânia',
			'Farroupilha',
			'Encantado',
			'Diamantino',
			'Charqueadas',
			'Cascavel',
			'Barra Bonita',
			'Areia Branca',
			'Araranguá',
			'Alterosa',
			'Alegrete',
			'Manacapuru',
			'Humaitá',
			'Upanema',
			'União dos Palmares',
			'Taquaritinga do Norte',
			'Senador Pompeu',
			'São Paulo do Potengi',
			'Rio Tinto',
			'Quixeramobim',
			'Paulo Afonso',
			'Paulista',
			'Passira',
			'Paracuru',
			'Mombaça',
			'Maraial',
			'Junqueiro',
			'Gravatá',
			'Granja',
			'Governador Dix Sept Rosado',
			'Fortuna',
			'Caraúbas',
			'Bom Jardim',
			'Barras',
			'Altos',
			'Abreu e Lima',
			'Votuporanga',
			'Várzea Paulista',
			'Varginha',
			'Terra Roxa',
			'Tapejara',
			'Sidrolândia',
			'Serrana',
			'São Joaquim',
			'São João del Rei',
			'Santo Antônio de Jesus',
			'Santa Gertrudes',
			'Ribeirão das Neves',
			'Realeza',
			'Ponto Novo',
			'Pirassununga',
			'Pinheiros',
			'Penápolis',
			'Pedro Leopoldo',
			'Pacatuba',
			'Pacaembu',
			'Natividade',
			'Morro da Fumaça',
			'Morrinhos',
			'Monte Azul Paulista',
			'Marília',
			'Malhador',
			'Laranjeiras',
			'João Neiva',
			'Itaú de Minas',
			'Itapemirim',
			'Ipatinga',
			'Ibiá',
			'Guaratinga',
			'Fundão',
			'Faxinal',
			'Engenheiro Beltrão',
			'Embu',
			'Divino das Laranjeiras',
			'Cravinhos',
			'Chapada dos Guimarães',
			'Catanduva',
			'Cardoso',
			'Carazinho',
			'Caçador',
			'Brasília de Minas',
			'Boa Esperança',
			'Areado',
			'Anagé',
			'Alfenas',
			'Águas Formosas',
			'Águas de Lindóia',
			'Abaeté',
			'Minaçu',
			'Tuntum',
			'Senador José Porfírio',
			'São José da Laje',
			'São Gonçalo do Amarante',
			'Santana do Matos',
			'Salgueiro',
			'Picos',
			'Pentecoste',
			'Pedro Velho',
			'Parnaíba',
			'Mulungu',
			'Jaboatão',
			'Icatu',
			'Horizonte',
			'Floriano',
			'Esperantinópolis',
			'Chapadinha',
			'Caucaia',
			'Carpina',
			'Batalha',
			'Baraúna',
			'Araripina',
			'Amarante do Maranhão',
			'Xique Xique',
			'Uberaba',
			'Tubarão',
			'Torres',
			'Taquarituba',
			'Taquara',
			'Simão Dias',
			'Senhor do Bonfim',
			'Santa Cruz do Rio Pardo',
			'Riachão do Jacuípe',
			'Pinheiro Machado',
			'Panorama',
			'Nova Granada',
			'Macaúbas',
			'Londrina',
			'Jandira',
			'Jaguariaíva',
			'Itapirapuã',
			'Itambé',
			'Itaberá',
			'Indaiatuba',
			'Ibotirama',
			'Herval',
			'Guaíra',
			'Francisco Sá',
			'Fernandópolis',
			'Edéia',
			'Corumbá',
			'Cordeirópolis',
			'Caxambu',
			'Catalão',
			'Boquira',
			'Artur Nogueira',
			'Aracaju',
			'Anápolis',
			'Tabatinga',
			'Ji Paraná',
			'Viçosa',
			'Urucurituba',
			'Sobral',
			'Sirinhaém',
			'Simplício Mendes',
			'São José do Campestre',
			'Santa Rita',
			'Santarém',
			'Riachão',
			'Reriutaba',
			'Remígio',
			'Poço das Trincheiras',
			'Poção de Pedras',
			'Palmeirais',
			'Garanhuns',
			'Extremoz',
			'Dom Pedro',
			'Coroatá',
			'Coité do Nóia',
			'Carnaíba',
			'Canguaretama',
			'Bom Jesus',
			'Baturité',
			'Batalha',
			'Barreiros',
			'Aracoiaba',
			'Aquiraz',
			'Anadia',
			'Amaraji',
			'Ubaíra',
			'Tanguá',
			'Tabatinga',
			'Severínia',
			'São João Nepomuceno',
			'São Francisco de Assis',
			'São Bernardo do Campo',
			'Santana do Livramento',
			'Santa Cruz do Sul',
			'Rio Grande',
			'Quijingue',
			'Presidente Bernardes',
			'Porto Alegre',
			'Piúma',
			'Nova Era',
			'Nanuque',
			'Mutum',
			'Maringá',
			'Laranjeiras do Sul',
			'Lagoa',
			'Juiz de Fora',
			'Jeremoabo',
			'Itagibá',
			'Ibirama',
			'Feira de Santana',
			'Extrema',
			'Cruzília',
			'Carmo do Paranaíba',
			'Campo do Brito',
			'Barra do Garças',
			'Baixa Grande',
			'Arcos',
			'São Paulo de Olivença',
			'Mâncio Lima',
			'Pôsto Fiscal Rolim de Moura',
			'São Miguel',
			'São Geraldo do Araguaia',
			'Santa Maria da Boa Vista',
			'Salgado de São Félix',
			'Porto da Folha',
			'Pirpirituba',
			'Pirapemas',
			'Ocara',
			'Mata Grande',
			'Marco',
			'Juazeiro do Norte',
			'José de Freitas',
			'Itapecuru Mirim',
			'Ibiapina',
			'Glória do Goitá',
			'Capanema',
			'Canindé de São Francisco',
			'Bananeiras',
			'Assaré',
			'Areia',
			'Altinho',
			'Alagoa Nova',
			'Águas Belas',
			'Acopiara',
			'Valente',
			'Valença',
			'Uberlândia',
			'Taubaté',
			'Taquaritinga',
			'Tanhaçu',
			'Sertãozinho',
			'Serrinha',
			'São José',
			'São João Evangelista',
			'Santa Luzia',
			'Salinas',
			'Rio Piracicaba',
			'Rio Novo do Sul',
			'Rio de Janeiro',
			'Rio Brilhante',
			'Ribeirão Preto',
			'Ribeirão da Ilha',
			'Resplendor',
			'Recreio',
			'Pirapora do Bom Jesus',
			'Piracaia',
			'Pinheiral',
			'Peçanha',
			'Paraguaçu Paulista',
			'Pantano do Sul',
			'Monte Azul',
			'Mirandopólis',
			'Maricá',
			'Lauro Muller',
			'Lapão',
			'José Bonifácio',
			'Jacupiranga',
			'Itaboraí',
			'Itaberaba',
			'Iporã',
			'Frutal',
			'Curitiba',
			'Carmo do Cajuru',
			'Caratinga',
			'Capela',
			'Canguçu',
			'Candelária',
			'Canavieiras',
			'Campos Gerais',
			'Campos Belos',
			'Camamu',
			'Cafelândia',
			'Belmonte',
			'Barrinha',
			'Arinos',
			'Angra dos Reis',
			'Andradina',
			'Canutama',
			'Lucas do Rio Verde',
			'Corrego Grande',
			'Beira Rio',
			'Uiraúna',
			'Ubajara',
			'Simões',
			'Satuba',
			'São Bernardo',
			'Santa Cruz',
			'Ribeirão',
			'Quebrangulo',
			'Maribondo',
			'Macaparana',
			'Guarabira',
			'Cururupu',
			'Cupira',
			'Cuité',
			'Condado',
			'Cerro Corá',
			'Capela',
			'Cândido Mendes',
			'Abaetetuba',
			'Viana',
			'Vespasiano',
			'Venâncio Aires',
			'Vargem Grande do Sul',
			'Tramandaí',
			'Terenos',
			'São Sepé',
			'São Pedro do Sul',
			'São Francisco',
			'Santo Antônio do Monte',
			'Santa Rita do Sapucaí',
			'Rio Bonito',
			'Presidente Venceslau',
			'Piaçabuçu',
			'Peabiru',
			'Paraíba do Sul',
			'Palmas de Monte Alto',
			'Muniz Freire',
			'Moreira Sales',
			'Monte Carmelo',
			'Lambari',
			'Jetibá',
			'Itatiba',
			'Itapetininga',
			'Itapetinga',
			'Itapema',
			'Itapecerica da Serra',
			'Irará',
			'Ibiúna',
			'Guaratuba',
			'Coaraci',
			'Caarapó',
			'Brotas',
			'Botucatu',
			'Belo Oriente',
			'Bagé',
			'Araruama',
			'Araçuaí',
			'Apucarana',
			'Anastácio',
			'Plácido de Castro',
			'Rio Preto da Eva',
			'Cambebba',
			'Umbuzeiro',
			'Turiaçu',
			'São José da Tapera',
			'São Caetano de Odivelas',
			'Santa Maria do Pará',
			'Santa Inês',
			'Regeneração',
			'Princesa Isabel',
			'Poço Branco',
			'Pendências',
			'Marechal Deodoro',
			'Irituia',
			'Inhuma',
			'Guaiúba',
			'Goianinha',
			'Ananindeua',
			'Urussanga',
			'Tibagi',
			'Silva Jardim',
			'Senador Canedo',
			'Sapucaia',
			'São Desidério',
			'São Cristóvão',
			'Santo Estêvão',
			'Rio Verde de Mato Grosso',
			'Porteirinha',
			'Porecatu',
			'Pirenópolis',
			'Pedreira',
			'Novo Horizonte',
			'Niquelândia',
			'Miracatu',
			'Maruim',
			'Martinópolis',
			'Manga',
			'Loanda',
			'Joaçaba',
			'Jataí',
			'Jacareí',
			'Itapuí',
			'Itapecerica',
			'Igrejinha',
			'Ibirataia',
			'Ibaté',
			'Guiratinga',
			'Goiatuba',
			'General Salgado',
			'Formosa',
			'Encruzilhada do Sul',
			'Correntina',
			'Cícero Dantas',
			'Cerro Largo',
			'Capelinha',
			'Cambuquira',
			'Cafarnaum',
			'Cachoeira do Sul',
			'Birigui',
			'Barueri',
			'Bandeirantes',
			'Armação de Búzios',
			'Coari',
			'Ariquemes',
			'Parauapebas',
			'Paulo Ramos',
			'Portelândia',
			'Imbé',
			'Toritama',
			'Tamboril',
			'São Raimundo Nonato',
			'São Raimundo das Mangabeiras',
			'São Joaquim do Monte',
			'São João',
			'Pedras de Fogo',
			'Nísia Floresta',
			'Monsenhor Gil',
			'Mocajuba',
			'Matriz de Camaragibe',
			'Maracaçumé',
			'Luís Correia',
			'Joaquim Nabuco',
			'Irauçuba',
			'Ipueiras',
			'Igarassu',
			'Hidrolândia',
			'Guabiraba',
			'Cocal',
			'Chorozinho',
			'Carutapera',
			'Cajueiro',
			'Cachoeirinha',
			'Cabo',
			'Breves',
			'Barroquinha',
			'Barra de Santo Antônio',
			'Arapiraca',
			'Açu',
			'Acaraú',
			'Wenceslau Braz',
			'Vazante',
			'Valparaíso',
			'Tocantins',
			'Terra Boa',
			'Tatuí',
			'Soledade',
			'Serra',
			'São Manuel',
			'São José dos Pinhais',
			'Santa Inês',
			'Santa Cecília',
			'Ribeirão Bonito',
			'Pôrto Barra do Ivinheima',
			'Pompéia',
			'Poço Verde',
			'Paranapanema',
			'Mundo Novo',
			'Mariana',
			'Marau',
			'Mantenópolis',
			'Luziânia',
			'Linhares',
			'Jardim',
			'Jaguariúna',
			'Itiruçu',
			'Itirapina',
			'Itaguaçu',
			'Itaberaí',
			'Horizontina',
			'Fartura',
			'Euclides da Cunha',
			'Estrela',
			'Dores do Indaiá',
			'Castro Alves',
			'Capinópolis',
			'Canoas',
			'Campo Grande',
			'Campina Grande do Sul',
			'Caçapava do Sul',
			'Brodósqui',
			'Borborema',
			'Barra de São Francisco',
			'Assis',
			'Balneário Arroio do Silva',
			'Águia Branca',
			'Marechal Thaumaturgo',
			'Cruzeiro do Sul',
			'Aparecida de Goiânia',
			'Simões Filho',
			'Saco dos Limoes',
			'Alvorada',
			'Tucuruí',
			'Timon',
			'Severiano Melo',
			'São Lourenço da Mata',
			'São João Batista',
			'Penalva',
			'Patu',
			'Ouricuri',
			'Natuba',
			'Muaná',
			'Morada Nova',
			'Mossoró',
			'Miracema do Tocantins',
			'Milagres',
			'Limoeiro do Norte',
			'Lajedo',
			'Itaitinga',
			'Ibimirim',
			'Crateús',
			'Chã Grande',
			'Caaporã',
			'Buriti dos Lopes',
			'Bragança',
			'Bom Jardim',
			'Araruna',
			'Anajatuba',
			'Alenquer',
			'Afonso Bezerra',
			'União da Vitória',
			'Ubá',
			'Toledo',
			'Sobradinho',
			'Sarzedo',
			'São Paulo',
			'São José do Rio Preto',
			'São José do Calçado',
			'São Gabriel',
			'Santo Antônio de Posse',
			'Santa Cruz das Palmeiras',
			'Santa Branca',
			'Salto',
			'Rio Real',
			'Rio Pomba',
			'Resende',
			'Registro',
			'Porto Ferreira',
			'Pederneiras',
			'Paraty',
			'Papagaios',
			'Osvaldo Cruz',
			'Mucurici',
			'Montes Claros',
			'Miranda',
			'Matipó',
			'Jequié',
			'Itatinga',
			'Iracemápolis',
			'Ipauçu',
			'Ibiraçu',
			'Guaçuí',
			'Embu Guaçu',
			'Descalvado',
			'Cruzeiro',
			'Conceição da Feira',
			'Casa Branca',
			'Canela',
			'Cananéia',
			'Campanha',
			'Cambuí',
			'Camapuã',
			'Buriti Alegre',
			'Boa Esperança do Sul',
			'Belford Roxo',
			'Anguera',
			'Aimorés',
			'Cacoal',
			'Itacorubi',
			'Tapera',
			'Umirim',
			'Pocinhos',
			'Petrolina',
			'Paulista',
			'Patos',
			'Moju',
			'Minador do Negrão',
			'Marapanim',
			'Jacaraú',
			'Itapissuma',
			'Imperatriz',
			'Floresta',
			'Farias Brito',
			'Dois Riachos',
			'Coremas',
			'Catolé do Rocha',
			'Castanhal',
			'Cametá',
			'Belo Jardim',
			'Beberibe',
			'Barro',
			'Aroeiras',
			'Amarante',
			'Xanxerê',
			'Uruguaiana',
			'Turmalina',
			'Tupaciguara',
			'Tapiramutá',
			'Sertanópolis',
			'São Pedro da Aldeia',
			'São Joaquim de Bicas',
			'São João da Boa Vista',
			'Presidente Olegário',
			'Pompéu',
			'Espírito Santo do Pinhal',
			'Pereira Barreto',
			'Paraúna',
			'Niterói',
			'Não Me Toque',
			'Monte Santo de Minas',
			'Mairi',
			'Lages',
			'Juquitiba',
			'Jundiaí',
			'Jerônimo Monteiro',
			'Iúna',
			'Itaperuçu',
			'Itabira',
			'Inhumas',
			'Iconha',
			'Ibirapitanga',
			'Guaporé',
			'Goianira',
			'Francisco Morato',
			'Esplanada',
			'Conde',
			'Clevelândia',
			'Cerquilho',
			'Centralina',
			'Carmo do Rio Verde',
			'Carira',
			'Capivari',
			'Bom Despacho',
			'Bebedouro',
			'Barretos',
			'Arapongas',
			'Araguari',
			'Aquidabã',
			'Andradas',
			'Álvares Machado',
			'Altinópolis',
			'Santa Isabel do Rio Negro',
			'Ouro Preto do Oeste',
			'Traipu',
			'São José do Belmonte',
			'São José de Mipibu',
			'São Benedito do Rio Preto',
			'Pedra Branca',
			'Maracanaú',
			'Maceió',
			'João Pessoa',
			'Jaicós',
			'Itupiranga',
			'Gameleira',
			'Brejo Santo',
			'Arari',
			'Alhandra',
			'Umbaúba',
			'Teutônia',
			'Taperoá',
			'Sengés',
			'Sapiranga',
			'Rio Novo',
			'Rio Negro',
			'Ribeirópolis',
			'Rialma',
			'Riacho de Santana',
			'Rancharia',
			'Quaraí',
			'Nossa Senhora do Socorro',
			'Neópolis',
			'Navegantes',
			'Miracema',
			'Medianeira',
			'Mar de Espanha',
			'Marataizes',
			'Lucas',
			'Lima Duarte',
			'Japeri',
			'Ivoti',
			'Itupeva',
			'Itanhém',
			'Itanhaém',
			'Itajobi',
			'Igarapé',
			'Gravataí',
			'Forquilhinha',
			'Formiga',
			'Eunápolis',
			'Entre Rios',
			'Domingos Martins',
			'Dois Córregos',
			'Carinhanha',
			'Carapicuíba',
			'Carambeí',
			'Campos dos Goytacazes',
			'Bueno Brandão',
			'Barra',
			'Avaré',
			'Arroio do Meio',
			'Aparecida',
			'América Dourada',
			'Açucena',
			'Guaíba',
			'Palmas',
			'Tarauacá',
			'Pauini',
			'Aripuanã',
			'Ourilândia do Norte',
			'São Roque de Minas',
			'São Bento',
			'Messias',
			'Matinha',
			'Maracanã',
			'Inhapi',
			'Goiana',
			'Flores',
			'Currais Novos',
			'Curaçá',
			'Careiro da Várzea',
			'Camocim de São Félix',
			'Brejinho',
			'Balsas',
			'Afogados da Ingazeira',
			'Viçosa',
			'Triunfo',
			'Três Barras',
			'Tietê',
			'São Luiz Gonzaga',
			'São Gonçalo',
			'Santo Anastácio',
			'Riolândia',
			'Riachão das Neves',
			'Portão',
			'Poços de Caldas',
			'Paranaíba',
			'Mato Verde',
			'Mascote',
			'Louveira',
			'Juquiá',
			'Júlio de Castilhos',
			'João Pinheiro',
			'João Monlevade',
			'Japoatã',
			'Jaguaruna',
			'Jaciara',
			'Itapira',
			'Itaguaí',
			'Ipuã',
			'Ibirubá',
			'Giruá',
			'Franco da Rocha',
			'Duque de Caxias',
			'Duartina',
			'Dom Pedrito',
			'Cosmópolis',
			'Centenário do Sul',
			'Caraí',
			'Campo Verde',
			'Cambé',
			'Caieiras',
			'Bragança Paulista',
			'Boa Esperança',
			'Astolfo Dutra',
			'Aragarças',
			'Novo Aripuanã',
			'Boa Vista',
			'Iranduba',
			'Jardim Paulista',
			'Carvoeira',
			'Touros',
			'São José da Coroa Grande',
			'Santa Rita',
			'Porto de Moz',
			'Pio XII',
			'Pedro II',
			'Parnamirim',
			'Nova Olinda do Norte',
			'Nova Cruz',
			'Montes Altos',
			'Marabá',
			'Jardim',
			'Jaguaribe',
			'Ingá',
			'Canhotinho',
			'Barcarena',
			'Barbalha',
			'Arês',
			'Apodi',
			'Almeirim',
			'Água Branca',
			'Vinhedo',
			'Tambaú',
			'Taguatinga',
			'Siqueira Campos',
			'Serra Negra',
			'São Gonçalo dos Campos',
			'São Gonçalo do Sapucaí',
			'São Francisco de Paula',
			'Santa Vitória do Palmar',
			'Santa Vitória',
			'Salvador',
			'Sacramento',
			'Rondonópolis',
			'Rio Verde',
			'Raposos',
			'Porto União',
			'Porto Real',
			'Piquete',
			'Paty do Alferes',
			'Ouro Branco',
			'Nossa Senhora da Glória',
			'Monte Alegre de Minas',
			'Macaé',
			'Laranja da Terra',
			'Lagoa Santa',
			'Joanópolis',
			'Jaú',
			'Jaguarari',
			'Itajubá',
			'Itaí',
			'Iporá',
			'Imbituva',
			'Ibiporã',
			'Guariba',
			'Guapiaçu',
			'Espumoso',
			'Dourados',
			'Curitibanos',
			'Conceição do Coité',
			'Cipó',
			'Castro',
			'Castilho',
			'Campo Grande',
			'Buri',
			'Bom Sucesso',
			'Bocaiúva',
			'Blumenau',
			'Barro Alto',
			'Barão de Melgaço',
			'Arroio dos Ratos',
			'Amparo',
			'Amargosa',
			'Alto Paraná',
			'Agudos',
			'Porto Velho',
			'Alvarães',
			'Sinop',
			'Tutóia',
			'Tupanatinga',
			'São José do Egito',
			'São Domingos do Maranhão',
			'Quixeré',
			'Portel',
			'Monteiro',
			'Macau',
			'Lagoa do Itaenga',
			'Itacoatiara',
			'Itapagé',
			'Iguatu',
			'Igarapé Miri',
			'Desterro',
			'Curuçá',
			'Cabrobó',
			'Boqueirão',
			'Bela Cruz',
			'Bayeux',
			'Viradouro',
			'Vassouras',
			'Uruaçu',
			'Ubatuba',
			'Três Corações',
			'Tomar do Geru',
			'Sorocaba',
			'São Pedro',
			'Santa Rosa',
			'Salto de Pirapora',
			'Rubiataba',
			'Quirinópolis',
			'Porto Belo',
			'Pedra Azul',
			'Patrocínio',
			'Passos',
			'Palmital',
			'Palmares do Sul',
			'Nilópolis',
			'Mutuípe',
			'Miraí',
			'Medina',
			'Machado',
			'Lucélia',
			'Laranjal Paulista',
			'Joinville',
			'Japaratuba',
			'Itapeva',
			'Itaparica',
			'Itapaci',
			'Itajaí',
			'Ilhéus',
			'Iguatemi',
			'Ibicuí',
			'Iaciara',
			'Guaratinguetá',
			'Flores da Cunha',
			'Estância Velha',
			'Divinópolis',
			'Diamantina',
			'Cunha',
			'Cubatão',
			'Corinto',
			'Cordeiro',
			'Nova Xavantina',
			'Casimiro de Abreu',
			'Carlos Chagas',
			'Cachoeira',
			'Cabreúva',
			'Brusque',
			'Tonantins',
			'Manoel Urbano',
			'Vilhena',
			'Pimenta Bueno',
			'Pinhais',
			'Tucumã',
			'Santa Monica',
			'Residencia Moacir PU5BHV',
			'Butia Inferior',
			'Valença do Piauí',
			'Trindade',
			'Tomé Açu',
			'Timbaúba',
			'São Tomé',
			'São Luís',
			'São José de Ribamar',
			'Rio Formoso',
			'Piracuruca',
			'Picuí',
			'Paulo Ramos',
			'Paraipaba',
			'Nhamundá',
			'Morros',
			'Major Isidoro',
			'Guaraciaba do Norte',
			'Cruz',
			'Ceará Mirim',
			'Buriti Bravo',
			'Benevides',
			'Atalaia',
			'Altamira',
			'Chavantes',
			'Vitória da Conquista',
			'Várzea Grande',
			'Várzea da Palma',
			'Tucano',
			'Três de Maio',
			'Tanabi',
			'São Marcos',
			'São João Batista',
			'São Carlos',
			'São Borja',
			'Santo Antônio do Amparo',
			'Santo Amaro das Brotas',
			'Santo Amaro',
			'Salgado',
			'Rolante',
			'Rio do Sul',
			'Prado',
			'Porto Seguro',
			'Porto Real do Colégio',
			'Porto Murtinho',
			'Pontes e Lacerda',
			'Passa Quatro',
			'Pariquera Açu',
			'Paripiranga',
			'Ortigueira',
			'Orizona',
			'Nova Lima',
			'Nepomuceno',
			'Mozarlândia',
			'Montanha',
			'Mogi Guaçu',
			'Mandaguari',
			'Luz',
			'Leopoldina',
			'Laje',
			'Januária',
			'Itaocara',
			'Indaial',
			'Igarapava',
			'Ibipeba',
			'Governador Valadares',
			'Franca',
			'Coronel Vivida',
			'Coração de Maria',
			'Conselheiro Lafaiete',
			'Cocal',
			'Cianorte',
			'Carmo',
			'Capinzal',
			'Caldas Novas',
			'Cajuru',
			'Caiapônia',
			'Cachoeiras de Macacu',
			'Butiá',
			'Barão de Cocais',
			'Almirante Tamandaré',
			'Fonte Boa',
			'Boca do Acre',
			'Teixeira de Freitas',
			'Vitória do Mearim',
			'Varjota',
			'Trairi',
			'Soure',
			'São Pedro do Piauí',
			'São João do Piauí',
			'Pombal',
			'Pitimbu',
			'Passagem Franca',
			'Palmares',
			'Olivença',
			'Olinda',
			'Juru',
			'Itaporanga',
			'Itabaiana',
			'Ipu',
			'Guajará',
			'Coelho Neto',
			'Codó',
			'Caririaçu',
			'Cacimba de Dentro',
			'Bom Conselho',
			'Augusto Corrêa',
			'Araguaína',
			'Alto Longá',
			'Acará',
			'Vianópolis',
			'Valinhos',
			'Três Coroas',
			'Teodoro Sampaio',
			'Telêmaco Borba',
			'Seropédica',
			'Sapucaia',
			'São Francisco do Conde',
			'São Bento do Sul',
			'Santo André',
			'Santa Isabel',
			'Rosário do Sul',
			'Rio Pardo de Minas',
			'Rio das Pedras',
			'Ribeira do Pombal',
			'Queimadas',
			'Pindamonhangaba',
			'Petrolina de Goiás',
			'Pedregulho',
			'Paramirim',
			'Paraguaçu',
			'Moita Bonita',
			'Mogi Mirim',
			'Mirabela',
			'Marialva',
			'Maracaí',
			'Madre de Deus',
			'Lins',
			'Limeira',
			'Itaúna',
			'Itaporanga',
			'Itapicuru',
			'Itamaraju',
			'Ipameri',
			'Ipaba',
			'Indiaroba',
			'Ilha Solteira',
			'Ibitinga',
			'Guarapuava',
			'Guanambi',
			'Frei Paulo',
			'Formosa do Rio Preto',
			'Esteio',
			'Criciúma',
			'Concórdia',
			'Conceição das Alagoas',
			'Chuí',
			'Chapecó',
			'Caxias do Sul',
			'Canarana',
			'Buerarema',
			'Bauru',
			'Barroso',
			'Barreiro do Jaíba',
			'Ampére',
			'Alvinópolis',
			'Alto Piquiri',
			'Manicoré',
			'Extrema',
			'Vigia',
			'Sumé',
			'São Sebastião',
			'Quipapá',
			'Piancó',
			'Parelhas',
			'Paraibano',
			'Paragominas',
			'Moreno',
			'Maragogi',
			'Limoeiro de Anadia',
			'Jardim do Seridó',
			'Jaguaruana',
			'Itamaracá',
			'Imaculada',
			'Coreaú',
			'Bujaru',
			'Borba',
			'Belém do Brejo do Cruz',
			'Belém',
			'Barra do Corda',
			'Baião',
			'Bacabal',
			'Uruçuca',
			'Uruana',
			'Terra Rica',
			'Tarumã',
			'São José dos Campos',
			'São João da Ponte',
			'Santo Antônio de Pádua',
			'Santana do Paraíso',
			'Rosário Oeste',
			'Ribeirão Branco',
			'Riachão do Dantas',
			'Prata',
			'Porciúncula',
			'Ponte Nova',
			'Piraí do Sul',
			'Pindorama',
			'Pindobaçu',
			'Paulínia',
			'Palmas',
			'Morro Agudo',
			'Montenegro',
			'Matozinhos',
			'Livramento do Brumado',
			'Lapa',
			'Lajeado',
			'Itarantim',
			'Itápolis',
			'Ibirité',
			'Goiás',
			'Florianópolis',
			'Eldorado',
			'Ecoporanga',
			'Cuiabá',
			'Cruz Alta',
			'Conceição do Rio Verde',
			'Colatina',
			'Canoinhas',
			'Campo Belo',
			'Caçu',
			'Bom Jesus dos Perdões',
			'Bastos',
			'Assaí',
			'Arraial do Cabo',
			'Anicuns',
			'Angatuba',
			'Americana',
			'Benjamin Constant',
			'Brasiléia',
			'São João dos Patos',
			'Puxinanã',
			'Parnamirim',
			'Pacajus',
			'Ourém',
			'Limoeiro do Ajuru',
			'Lago da Pedra',
			'Jardim de Piranhas',
			'Ipanguaçu',
			'Gurinhém',
			'Cedro',
			'Campo Alegre',
			'Boa Viagem',
			'Alexandria',
			'Três Pontas',
			'Três Passos',
			'Sumaré',
			'Saquarema',
			'São Mateus',
			'São João do Paraíso',
			'Santa Teresa',
			'Ruy Barbosa',
			'Rio Claro',
			'Ribeirão Pires',
			'Queimados',
			'Piuí',
			'Pires do Rio',
			'Pirapozinho',
			'Panambi',
			'Ouro Preto',
			'Nova Venécia',
			'Naviraí',
			'Muritiba',
			'Monte Santo',
			'Mococa',
			'Mendes',
			'Maracás',
			'Maracaju',
			'Manhuaçu',
			'Mangaratiba',
			'Jataizinho',
			'Jacobina',
			'Itu',
			'Itaquaquecetuba',
			'Itapevi',
			'Itaperuna',
			'Itanhandu',
			'Itajuípe',
			'Ilhabela',
			'Igaraçu do Tietê',
			'Gurupi',
			'Guararapes',
			'Guaramirim',
			'Guará',
			'Paulista Flórida',
			'Elias Fausto',
			'Costa Rica',
			'Coromandel',
			'Congonhas',
			'Colorado',
			'Colombo',
			'Celso Ramos',
			'Buritizeiro',
			'Bom Jesus da Lapa',
			'Bento Gonçalves',
			'Barro Alto',
			'Barra do Piraí',
			'Barra da Estiva',
			'Araras',
			'Araguanã',
			'Lauro de Freitas',
			'Vitória de Santo Antão',
			'Conde',
			'Viana',
			'Vargem Grande',
			'Tucumã',
			'Teresina',
			'São Miguel do Tapuio',
			'São Miguel dos Campos',
			'São João de Pirabas',
			'São Félix do Xingu',
			'Santa Quitéria do Maranhão',
			'Russas',
			'Rio Largo',
			'Jatobá',
			'Pedra',
			'Oeiras',
			'Monte Alegre',
			'Manaíra',
			'Jucás',
			'Ipojuca',
			'Autazes',
			'Areia Branca',
			'Angicos',
			'Água Preta',
			'Visconde do Rio Branco',
			'Videira',
			'Tupã',
			'São Luís de Montes Belos',
			'São Jerônimo',
			'Santo Antônio do Sudoeste',
			'Santo Antônio do Leverger',
			'Santo Amaro da Imperatriz',
			'Santa Maria',
			'Sabinópolis',
			'Quatá',
			'Poá',
			'Pitanga',
			'Pirapora',
			'Pinhão',
			'Perdões',
			'Palmeiras de Goiás',
			'Padre Paraíso',
			'Ourinhos',
			'Nova Soure',
			'Nortelândia',
			'Nhandeara',
			'Nerópolis',
			'Monte Alto',
			'Miguelópolis',
			'Miguel Calmon',
			'Matias Barbosa',
			'Mateus Leme',
			'Leme',
			'Jaraguá',
			'Jacutinga',
			'Itinga',
			'Itararé',
			'Itabaiana',
			'Iguaí',
			'Ibaiti',
			'Iaçu',
			'Garibaldi',
			'Frederico Westphalen',
			'Espinosa',
			'Cruz das Almas',
			'Cristinápolis',
			'Cotia',
			'Coração de Jesus',
			'Conceição do Almeida',
			'Chopinzinho',
			'Ceres',
			'Catu',
			'Carandaí',
			'Campo Formoso',
			'Caeté',
			'Borda da Mata',
			'Bertioga',
			'Bela Vista de Goiás',
			'Atibaia',
			'Arujá',
			'Arroio Grande',
			'Arraias',
			'Armação',
			'Andrelândia',
			'Altãnia',
			'Alpinópolis',
			'Abadiânia',
			'Sena Madureira',
			'Maraã',
			'Codajás',
			'Redenção',
		],
	},
	'British Virgin Islands': { country: 'British Virgin Islands', cities: ['Road Town'] },
	'Brunei Darussalam': {
		country: 'Brunei Darussalam',
		cities: ['Kapok', 'Kuala Belait', 'Mentiri', 'Bandar Seri Begawan', 'Bangar', 'Tutong', 'Seria'],
	},
	Bulgaria: {
		country: 'Bulgaria',
		cities: [
			'Nedelino',
			'Lyubimets',
			'Kardzhali',
			'Gŭlŭbovo',
			'Gyovren',
			'Borino',
			'Bobov Dol',
			'Suvorovo',
			'Silistra',
			'Rakovski',
			'Novi Pazar',
			'Mineralni Bani',
			'Kotel',
			'Topolovgrad',
			'Teteven',
			'Sitovo',
			'Septemvri',
			'Pavlikeni',
			'Pavel Banya',
			'Opaka',
			'Rupite',
			'Levski',
			'Kiten',
			'Hayredin',
			'Karnobat',
			'Elin Pelin',
			'Byala',
			'Aytos',
			'Balgarevo',
			'Topolovo',
			'Ruse',
			'Pomorie',
			'Montana',
			'Lakatnik',
			'Kresna',
			'Koynare',
			'Ihtiman',
			'Bregovo',
			'Banite',
			'Asenovgrad',
			'Asen',
			'Suhindol',
			'Stara Zagora',
			'Satovcha',
			'Popovo',
			'Makresh',
			'Krivodol',
			'Gorna Malina',
			'Dalgopol',
			'Borovan',
			'Zlatni Pyasatsi',
			'Beloslav',
			'Dobrich',
			'Tervel',
			'Strumyani',
			'Shumen',
			'Samuil',
			'Perushtitsa',
			'Hisarya',
			'Glavinitsa',
			'Dolna Banya',
			'Zlatitsa',
			'Yablanitsa',
			'Rila',
			'Peshtera',
			'Haskovo',
			'Isperih',
			'Gulyantsi',
			'Gorichevo',
			'Dospat',
			'Brusartsi',
			'Bov',
			'Ahtopol',
			'Zlataritsa',
			'Targovishte',
			'Saedinenie',
			'Spasovo',
			'Smolyan',
			'Knezha',
			'Chelopech',
			'Blagoevgrad',
			'Balchik',
			'Antonovo',
			'Zavet',
			'Sofia',
			'Shabla',
			'Senovo',
			'Loznitsa',
			'Gotse Delchev',
			'Dunavtsi',
			'Bolyarovo',
			'Bansko',
			'Kyustendil',
			'Kolarovo',
			'Gabrovo',
			'Chiprovtsi',
			'Vetovo',
			'Tochilari',
			'Smyadovo',
			'Parvomaytsi',
			'Mizia',
			'Godech',
			'Breznik',
			'Yakimovo',
			'Batanovtsi',
			'Sarnitsa',
			'Veliki Preslav',
			'Pazardzhik',
			'Letnitsa',
			'Ivanovo',
			'Gurkovo',
			'Belene',
			'Bata',
			'Anton',
			'Zelenikovo',
			'Vratsa',
			'Slivnitsa',
			'Sandanski',
			'Samokov',
			'Ruzhintsi',
			'Ruen',
			'Plovdiv',
			'Harmanli',
			'Dolni Dabnik',
			'Berkovitsa',
			'Alfatar',
			'Venets',
			'Svoge',
			'Strelcha',
			'Sozopol',
			'Sevlievo',
			'Radomir',
			'Omurtag',
			'Mirkovo',
			'Krumovgrad',
			'Belovo',
			'Sapareva Banya',
			'Provadia',
			'Pravets',
			'Madzharovo',
			'Kubrat',
			'Kocherinovo',
			'Dulovo',
			'Dragoman',
			'Chuprene',
			'Boychinovtsi',
			'Sadovo',
			'Rudozem',
			'Polski Trambesh',
			'Lovech',
			'Kazanlak',
			'Aksakovo',
			'Zemen',
			'Pordim',
			'Malko Tarnovo',
			'Madan',
			'Kozloduy',
			'Kaolinovo',
			'Gorna Oryahovitsa',
			'Dolni Chiflik',
			'Etropole',
			'Boboshevo',
			'Belitsa',
			'Valchidol',
			'Valchedram',
			'Radnevo',
			'Pirdop',
			'Panagyurishte',
			'Oryahovo',
			'Medkovets',
			'Hadzhidimovo',
			'Bozhurishte',
			'Ardino',
			'Sveti Vlas',
			'Vetrino',
			'Veliko Tŭrnovo',
			'Ugarchin',
			'Tvarditsa',
			'Sliven',
			'Simitli',
			'Mezdra',
			'Lom',
			'Kalofer',
			'Chavdar',
			'Batak',
			'Velingrad',
			'Strazhitsa',
			'Stara Kresna',
			'Dupnitsa',
			'Slivo Pole',
			'Shipka',
			'Kameno',
			'Iskar',
			'Gara Hitrino',
			'Devnya',
			'Byala Slatina',
			'Boynitsa',
			'Asparuhovo',
			'Tsenovo',
			'Stamboliyski',
			'Petrich',
			'Nikolaevo',
			'Maglizh',
			'Lesichovo',
			'Krichim',
			'Elhovo',
			'Dolna Mitropolia',
			'Devin',
			'Chernomorets',
			'Brezovo',
			'Tutrakan',
			'Straldzha',
			'Razlog',
			'Obzor',
			'Novo Selo',
			'Nova Zagora',
			'Nikopol',
			'Koprivshtitsa',
			'Kiten',
			'Kavarna',
			'Kaspichan',
			'General Toshevo',
			'Elena',
			'Dimitrovgrad',
			'Cherven Bryag',
			'Borovo',
			'Aheloy',
			'Yambol',
			'Tran',
			'Svishtov',
			'Sungurlare',
			'Sarafovo',
			'Pernik',
			'Iskar',
			'Lukovit',
			'Laki',
			'Krushari',
			'Kermen',
			'Chirpan',
			'Chepelare',
			'Yakoruda',
			'Troyan',
			'Svilengrad',
			'Slavyanovo',
			'Roman',
			'Pliska',
			'Klisura',
			'Tsar Kaloyan',
			'Garmen',
			'Apriltsi',
			'Zlatograd',
			'Varna',
			'Razgrad',
			'Nevestino',
			'Tsarevo',
			'Kilifarevo',
			'Kaynardzha',
			'Ivaylovgrad',
			'Sredets',
			'Dzhebel',
			'Byala Cherkva',
			'Buhovo',
			'Varbitsa',
			'Vidin',
			'Kula',
			'Kaloyanovo',
			'Gramada',
			'Dve Mogili',
			'Dryanovo',
			'Bratsigovo',
			'Simeonovgrad',
			'Rakitovo',
			'Nesebar',
			'Lyaskovets',
			'Kostinbrod',
			'Gabrovo',
			'Elkhovo',
			'Dimovo',
			'Belogradchik',
			'Varshets',
			'Tryavna',
			'Ravda',
			'Parvomay',
			'Primorsko',
			'Pleven',
			'Medovene',
			'Kirkovo',
			'Karlovo',
			'Debelets',
			'Burgas',
			'Botevgrad',
		],
	},
	'Burkina Faso': {
		country: 'Burkina Faso',
		cities: [
			'Tenkodogo',
			'Kayao',
			'Saponé',
			'Pabré',
			'Léo',
			'Sourgoubila',
			'Dédougou',
			'Dano',
			'Pitmoaga',
			'Diapaga',
			'Boromo',
			'Titao',
			'Korsimoro',
			'Gayéri',
			'Garango',
			'Ourgou-Manéga',
			'Komki-Ipala',
			'Dori',
			'Boundoré',
			'Toéghin',
			'Salanso',
			'Houndé',
			'Diébougou',
			'Bingo',
			'Niou',
			'Kombissiri',
			"Fada N'gourma",
			'Tanghin-Dassouri',
			'Loumbila',
			'Koupéla',
			'Gaoua',
			'Mani',
			'Zorgo',
			'Gorom-Gorom',
			'Boussouma',
			'Nouna',
			'Djibo',
			'Dapélogo',
			'Boussé',
			'Sindou',
			'Ouargaye',
			'Koubri',
			'Kokologo',
			'Ouahigouya',
			'Kongoussi',
			'Kaya',
			'Gaongo',
			'Bogandé',
			'Bilanga',
			'Toma',
			'Siglé',
			'Nagréongo',
			'Mané',
			'Tin-Akoff',
			'Réo',
			'Komsilga',
			'Boulsa',
			'Batié',
			'Sebba',
			'Ouagadougou',
			'Déou',
			'Absouya',
			'Ziniaré',
			'Oursi',
			'Manga',
			'Zitenga',
			'Tougan',
			'Pô',
			'Goulouré',
			'Bobo-Dioulasso',
			'Banfora',
			'Sapouy',
			'Saaba',
			'Laye',
			'Gourcy',
			'Barani',
			'Yako',
			'Pama',
			'Koudougou',
		],
	},
	Burundi: {
		country: 'Burundi',
		cities: [
			'Muramvya',
			'Kirundo',
			'Mwaro',
			'Rutana',
			'Isale',
			'Ngozi',
			'Karuzi',
			'Buganda',
			'Makamba',
			'Bubanza',
			'Ruyigi',
			'Cibitoke',
			'Rumonge',
			'Bururi',
			'Bujumbura',
			'Muyinga',
			'Muzinda',
			'Kayanza',
			'Gitega',
			'Cankuzo',
		],
	},
	Cambodia: {
		country: 'Cambodia',
		cities: [
			'S’ang',
			'Lumphat',
			'Phnum Proek',
			'Stueng Saen',
			'Kampong Leav',
			'Angkor Thum',
			'Koas Krala',
			'Bavel',
			'Moung Ruessei',
			'Krong Kep',
			'Kampong Chhnang',
			'Srei Snam',
			'Mean Chey',
			'Aoral',
			'Svay Chek',
			'Battambang',
			'Dangkao',
			'Cheung Prey',
			'Suong',
			'Paoy Paet',
			'Banlung',
			'Bat Dambang',
			'Ruessei Kaev',
			'Svay Teab',
			'Angkor Chum',
			'Samraong',
			'Kampong Trach',
			'Koun Mom',
			'Pailin',
			'Kuleaen',
			'Phnum Kravanh',
			'Ta Khmau',
			'Koah Thum',
			'Ta Loas',
			'Tbeng Meanchey',
			'Samlout',
			'Phnum Sruoch',
			'Sen Monorom',
			'Batheay',
			'Sihanoukville',
			'Odongk',
			'Botum Sakor',
			'Soutr Nikom',
			'Mukh Kampul',
			'Sithor Kandal',
			'Ou Ya Dav',
			'Smach Mean Chey',
			'Kampot',
			'Kandal Stueng',
			'Ou Chum',
			'Andoung Meas',
			'Thpong',
			'Kiri Vongs',
			'Basedth',
			'Kaoh Soutin',
			'Sandan',
			'Bakong',
			'Srae Ambel',
			'Kampong Trabaek',
			'Sampov Lun',
			'Mittakpheap',
			'Stoung',
			'Preah Sdach',
			'Prey Veaeng',
			'Rumduol',
			'Stueng Trang',
			'Phnom Penh',
			'Svay Rieng',
			'Kamrieng',
			'Kralanh',
			'Banteay Meas',
			'Bourei Cholsar',
			'Chamkar Leu',
			'Siem Reap',
			'Sala Krau',
			'Kampong Speu',
			'Sesan',
			'Kampong Leaeng',
			'Kang Meas',
			'Bakan',
			'Tram Kak',
			'Sisophon',
			'Pursat',
			'Aek Phnum',
			'Thma Bang',
			'Damnak Chang’aeur',
			'Pechreada',
			'Chi Kraeng',
			'Kratié',
			'Me Sang',
			'Peam Ro',
			'Takeo',
			'Kampong Seila',
			'Banan',
			'Anlong Veaeng',
			'Veal Veaeng',
			'Ta Khmau',
			'Kampot',
			'Kampong Cham',
			'Preaek Prasab',
			'Koh Kong',
			'Kampong Thom',
			'Baray',
			'Mondul Seima',
			'Prey Chhor',
			'Chantrea',
			'Varin',
			'Kaev Seima',
			'Stung Treng',
			'Phumĭ Véal Srê',
			'Bak Khlang',
			'Chhloung',
			'Pal Hal',
			'Ou Chrov',
			'Bar Kaev',
			'Mongkol Borei',
			'Veun Sai',
			'Preah Netr Preah',
			'Sangkae',
			'Chum Kiri',
			'Peam Chor',
			'Thma Puok',
			'Ponhea Lueu',
			'Baribour',
			'Siem Pang',
			'Prey Veng',
			'Chhuk',
			'Phnum Srok',
			'Trapeang Prasat',
			'Sangkom Thmei',
			'Leuk Daek',
			'Prey Nob',
		],
	},
	Cameroon: {
		country: 'Cameroon',
		cities: [
			'Pitoa',
			'Nguti',
			'Meïganga',
			'Diang',
			'Bafang',
			'Mme-Bafumen',
			'Ébolowa',
			'Doumé',
			'Bali',
			'Tcholliré',
			'Nanga Eboko',
			'Mamfe',
			'Limbe',
			'Kribi',
			'Koza',
			'Garoua',
			'Belo',
			'Akom II',
			'Yagoua',
			'Rey Bouba',
			'Mbandjok',
			'Foumban',
			'Bétaré Oya',
			'Melong',
			'Mbanga',
			'Kumba',
			'Jakiri',
			'Fundong',
			'Fontem',
			'Bertoua',
			'Ambam',
			'Kontcha',
			'Dimako',
			'Okola',
			'Somié',
			'Yokadouma',
			'Kaélé',
			'Eséka',
			'Bana',
			'Nkongsamba',
			'Kousséri',
			'Bazou',
			'Yaoundé',
			'Tignère',
			'Mouanko',
			'Mbouda',
			'Mbengwi',
			'Buea',
			'Bafoussam',
			'Ndom',
			'Ngoro',
			'Ndikiniméki',
			'Mbalmayo',
			'Makary',
			'Dizangué',
			'Bogo',
			'Edéa',
			'Bandjoun',
			'Ombésa',
			'Okoa',
			'Kumbo',
			'Batibo',
			'Ngou',
			'Bamusso',
			'Babanki',
			'Mora',
			'Akono',
			'Yoko',
			'Ndelele',
			'Abong Mbang',
			'Penja',
			'Muyuka',
			'Évodoula',
			'Bekondo',
			'Wum',
			'Tonga',
			'Obala',
			'Ntui',
			'Mvangué',
			'Minta',
			'Maroua',
			'Batouri',
			'Ngaoundéré',
			'Bélel',
			'Njinikom',
			'Bonabéri',
			'Yabassi',
			'Tibati',
			'Bankim',
			'Bélabo',
			'Bafia',
			'Sangmélima',
			'Manjo',
			'Lagdo',
			'Dschang',
			'Tiko',
			'Mutengene',
			'Mindif',
			'Essé',
			'Dibombari',
			'Banyo',
			'Poli',
			'Nkoteng',
			'Mokolo',
			'Foumbot',
			'Bamendjou',
			'Bamenda',
			'Akonolinga',
			'Saa',
			'Lolodorf',
			'Garoua Boulaï',
			'Bansoa',
			'Ngomedzap',
			'Ngambé',
			'Mundemba',
			'Mbankomo',
			'Mbang',
			'Loum',
			'Guider',
			'Douala',
			'Djohong',
			'Bangangté',
		],
	},
	Canada: {
		country: 'Canada',
		cities: [
			'Azilda',
			'Canmore',
			'Charlemagne',
			'Cookstown',
			'Dauphin',
			'Disraeli',
			'Grimsby',
			'Hantsport',
			'Houston',
			'Ingersoll',
			'Mandeville',
			'Markdale',
			'Moose Jaw',
			'Okotoks',
			'Papineauville',
			'Saint-Colomban',
			'Sainte-Sophie',
			'Saint-Joseph-du-Lac',
			'Saint-Laurent',
			'Swift Current',
			'Trail',
			'Two Hills',
			'Ville-Marie',
			'Vulcan',
			'West Hill',
			'Halifax',
			'Saint-Henri',
			'Grand Centre',
			'Luceville',
			'Thorncliffe Park',
			'Wexford/Maryvale',
			'Keelesdale-Eglinton West',
			'Clairlea-Birchmount',
			'Barraute',
			'Cantley',
			'Carignan',
			'Carlyle',
			'Cedar',
			'Chambord',
			'Chemainus',
			'Coombs',
			'Cowansville',
			'Ferme-Neuve',
			'Greenwood',
			'High Level',
			'Irricana',
			'La Tuque',
			'Lively',
			'Mississauga',
			'Montréal',
			'Niverville',
			'North Saanich',
			'Oshawa',
			'Port Hawkesbury',
			'Richmond',
			'Roblin',
			'Saint-André-Avellin',
			'Saint-Mathieu',
			'Saint-Thomas',
			'Smoky Lake',
			'Venise-en-Québec',
			'Wadena',
			'Watford',
			'Windsor',
			"St. John's",
			'Jasper',
			'St-Jean-Port-Joli',
			'Baie-Saint-Paul',
			'Matane',
			'Niagara',
			'Eglinton East',
			'York University Heights',
			'Centennial Scarborough',
			'Yonge-Eglinton',
			'Brookhaven-Amesbury',
			'Aylmer',
			'Bedford',
			'Chilliwack',
			'Clarence-Rockland',
			'Coaticook',
			'Edmonton',
			'Fall River',
			'Fort Macleod',
			'Gananoque',
			'Happy Valley-Goose Bay',
			'Hawkesbury',
			'High River',
			'Iqaluit',
			'Moncton',
			'Nahanni Butte',
			'Notre-Dame-du-Mont-Carmel',
			'Paisley',
			'Peterborough',
			'Petrolia',
			'Prescott',
			'Saint-Barnabé-Sud',
			'Saint-Isidore',
			'Saint-Zotique',
			'Scarborough Village',
			'Seaforth',
			'The Beaches',
			'Truro',
			"Val-d'Or",
			'Vanderhoof',
			'Warman',
			'Waterford',
			'Wawa',
			'Portneuf',
			'Tofino',
			'Saint-Pascal',
			'McEwen',
			'Parc-Boutin',
			'South Riverdale',
			'Beechborough-Greenbrook',
			'Brant',
			'Brockville',
			'Chetwynd',
			'Cobourg',
			'Denman Island',
			'Deseronto',
			'Dolbeau-Mistassini',
			'Humbermede',
			'Gimli',
			'Hinton',
			'Invermere',
			'Kensington',
			'Lac-Simon',
			'Lions Bay',
			'Marystown',
			'Midland',
			'Osgoode',
			'Pont Rouge',
			'Red Deer',
			'Saint-Michel',
			'Saint-Prime',
			'St. Adolphe',
			'Timmins',
			'Wainwright',
			'Wellesley',
			'Weston',
			'Whistler',
			'Wingham',
			'Château-Richer',
			'Montmagny',
			'Sainte Catherine de la Jacques Cartier',
			'Carleton',
			'Lillooet',
			'La Malbaie',
			'Ancaster',
			'Maria',
			'Shediac Bridge-Shediac River',
			'Greater Lakeburn',
			'Dovercourt-Wallace Emerson-Junction',
			'Humber Heights-Westmount',
			'High Park-Swansea',
			'Trinity Bay North',
			'Altona',
			'Antigonish',
			'Beaconsfield',
			'Black Diamond',
			'Bluewater',
			'Bow Island',
			'Bridgewater',
			'Cap-Chat',
			'Chambly',
			'Crabtree',
			'Delaware',
			'Devon',
			'Dowling',
			'Foam Lake',
			'Granby',
			'Grande-Digue',
			'Guildwood',
			'Hanceville',
			'Hornepayne',
			'Ionview',
			'Jarvis',
			'Kenora',
			'Killarney',
			'Kirkland Lake',
			'Limoges',
			'Moose Lake',
			'Nanaimo',
			'Nicolet',
			"Notre-Dame-de-l'Île-Perrot",
			'Okanagan Falls',
			'Orillia',
			'Penticton',
			'Plattsville',
			'Ridgetown',
			'Rosetown',
			'Saint-Alexandre',
			'Sainte-Adèle',
			'Sainte-Anne-de-Bellevue',
			'Sainte-Marthe-sur-le-Lac',
			'Saint-Isidore-de-Laprairie',
			'Saint-Jacques-le-Mineur',
			'Saint-Tite',
			'Tavistock',
			'Thorold',
			'Jonquière',
			'Saint-Norbert',
			'Salt Spring Island',
			'Englemount-Lawrence',
			'Agassiz',
			'Asbestos',
			'Ashcroft',
			'Baie-Comeau',
			'Carstairs',
			'Conception Bay South',
			'Courtenay',
			'Cow Bay',
			'Deep River',
			'East Wellington',
			'Edson',
			'Elliot Lake',
			'Espanola',
			'Fairview',
			'Greenstone',
			'Henry Farm',
			'Kugluktuk',
			'Landmark',
			'Langham',
			'La Ronge',
			'Lincoln',
			'Little Current',
			'Mattawa',
			'Oakwood Village',
			'Raymond',
			'Rocky Mountain House',
			"Saint-Léonard-d'Aston",
			'Saint-Philippe-de-La Prairie',
			'Saint-Rémi',
			'Salmon Arm',
			'Springdale',
			'Summerland',
			'Thessalon',
			'Warwick',
			'Yellowknife',
			// 'Sydney',
			'Thetford-Mines',
			'Saint-Anselme',
			'Tracadie-Sheila',
			'Saint-Joseph-de-Beauce',
			'Ladner',
			'Saint-Léonard',
			'Morningside',
			'Pohénégamook',
			'Tamarack',
			'Behchokǫ̀',
			'Manawan',
			'Danforth',
			'Junction Area',
			'St.Andrew-Windfields',
			'Princess-Rosethorn',
			'Amigo Beach',
			'Angus',
			'Berthierville',
			'Coaldale',
			'Cornwall',
			'Digby',
			'Etobicoke West Mall',
			'Grunthal',
			'Humber Summit',
			'Lac du Bonnet',
			'Lake Cowichan',
			'Langford',
			'Laurentides',
			'Leamington',
			'Lutes Mountain',
			'New Glasgow',
			'Noonan',
			'Outlook',
			'Penhold',
			'Regina',
			'Rock Forest',
			'Sainte-Catherine',
			'Saint-Raphaël',
			'Stephenville',
			'Stettler',
			'Stirling',
			'St. Stephen',
			'Sylvan Lake',
			'West Lorne',
			'Wychwood',
			'Yarmouth',
			'Beaupré',
			'St. George',
			'Baie Ste. Anne',
			'South Pender Harbour',
			'Denman Island Trust Area',
			'North St.James Town',
			'Blake-Jones',
			'Eringate-Centennial-West Deane',
			'Mount Pleasant East',
			'Willowdale West',
			'Stonegate-Queensway',
			'Albanel',
			'Alberton',
			'Bassano',
			'Bath',
			'Binbrook',
			'Cache Creek',
			'Campbell River',
			'Cornwall',
			'Daveluyville',
			'Dollard-Des Ormeaux',
			'Fort St. John',
			'Kitimat',
			'Maniwaki',
			'Medicine Hat',
			'Metchosin',
			'Mistissini',
			'Notre-Dame-des-Prairies',
			'Piedmont',
			'Pointe-Calumet',
			'Port Colborne',
			'Richmond',
			'Saint-Constant',
			'Sainte-Agathe-des-Monts',
			'Saint-Édouard',
			'Saint-Félix-de-Valois',
			'Saint-Sauveur-des-Monts',
			'Salluit',
			'Saskatoon',
			'Summerside',
			'Sundre',
			'Torbay',
			'Trois-Rivières',
			'Waterloo',
			'West End',
			'Westville',
			'Yorkton',
			'Rivière-du-Loup',
			'Campbellton',
			'Aldergrove',
			'Little Portugal',
			'Fergus',
			'Willowdale',
			'Vallée-Jonction',
			'Hérouxville',
			'Duck Lake',
			'Eskasoni 3',
			'Valley East',
			'Fundy Bay',
			'Weedon Centre',
			'Palmerston-Little Italy',
			'Briar Hill-Belgravia',
			'Agincourt South-Malvern West',
			'Bathurst Manor',
			'Clanton Park',
			'Ange-Gardien',
			'Beausejour',
			'Burford',
			'Cookshire',
			'Delson',
			'Gaspé',
			'Humboldt',
			'Huntingdon',
			'Jasper Park Lodge',
			'Killam',
			'Kincardine',
			'Kitchener',
			'Lacolle',
			'Listowel',
			'Melita',
			'Montréal-Ouest',
			'Moosonee',
			'Morinville',
			'Mount Brydges',
			'Nanton',
			'Oliver',
			'Pickering',
			'Port Hope',
			'Saint-Damase',
			'Saint-Jacques',
			'Saint-Michel-des-Saints',
			'Saint-Pie-V',
			'South River',
			'Stoney Point',
			'Three Hills',
			'Tisdale',
			'Vermilion',
			'Victoriaville',
			'Weymontachie',
			'Chandler',
			'Trinity-Bellwoods',
			'Wild Rose',
			'Newtonbrook East',
			'Yorkdale-Glen Park',
			'Thistletown-Beaumond Heights',
			'Downsview-Roding-CFB',
			'Arviat',
			'Botwood',
			'Cold Lake',
			'Fruitvale',
			'Hampton',
			'Hay River',
			'Inuvik',
			'Labrador City',
			'Laval',
			'Leblanc',
			'Maple Ridge',
			'Martensville',
			'Melville',
			'Millet',
			'Montague',
			'Moose Factory',
			'Mount Albert',
			'Norman Wells',
			'Peace River',
			'Pitt Meadows',
			'Rossland',
			'Saint-Côme--Linière',
			'Sainte-Croix',
			'Saint-Sauveur',
			'Stephenville Crossing',
			'Tsawwassen',
			'Varennes',
			'Vineland',
			'Wells',
			'Laurel',
			'North Riverdale',
			'Victoria Village',
			'Cabbagetown-South St.James Town',
			"Harbour Main-Chapel's Cove-Lakeview",
			'Ajax',
			'Amherstburg',
			'Barrière',
			'Burgeo',
			'Canora',
			'Channel-Port aux Basques',
			'Corunna',
			'Estevan',
			'Grand Bank',
			'Hamilton',
			'Hornby Island',
			'Huntsville',
			'Kerrobert',
			'La Sarre',
			'Lebel-sur-Quévillon',
			'Magrath',
			'Nelson',
			'Oxbow',
			'Pangnirtung',
			'Pemberton',
			'Pincher Creek',
			'Port Rowan',
			'Gamèti',
			'Red Lake',
			'Regina Beach',
			'Repentigny',
			'Richmond',
			'Saguenay',
			'Saint-Bruno',
			'Saint-Cyrille-de-Wendover',
			'Senneterre',
			'Simcoe',
			'Smithers',
			'St. Paul',
			'Strathmore',
			'St. Thomas',
			'Témiscaming',
			'Valcourt',
			'Vauxhall',
			'Victoria',
			'White City',
			'Whitecourt',
			'Windsor',
			'Beauceville',
			// 'Paris',
			'Mont-Joli',
			'Cowichan Bay',
			'High Park North',
			'Breakeyville',
			'Rexdale-Kipling',
			'Three Rivers',
			'Armstrong',
			'Barrhead',
			'Bécancour',
			'Brampton',
			'Caledon',
			'Chapais',
			'Chibougamau',
			'Coteau-du-Lac',
			'Cumberland',
			'Deloraine',
			'Dryden',
			'Fort Saskatchewan',
			'Gibsons',
			'Headingley',
			'High Prairie',
			'Lacombe',
			'Lumsden',
			'Mercier',
			'Middleton',
			'Mimico',
			'Mississauga Beach',
			'Mont-Saint-Grégoire',
			'Mount Pearl',
			'Niagara Falls',
			'North Bay',
			'North Cowichan',
			'Oakridge',
			'Oka',
			'Petawawa',
			'Pond Inlet',
			'Powell River',
			'Princeville',
			'Rankin Inlet',
			'Richmond Hill',
			'Saint-Alexandre',
			'Saint-Ambroise',
			'Saint-Charles',
			'Sainte-Julie',
			'Saint-Léonard',
			'Saint-Nazaire',
			'Saltair',
			'Steinbach',
			'Surrey',
			'Thompson',
			'Val-Morin',
			'Wasaga Beach',
			'Welland',
			'Woburn',
			'Roncesvalles',
			'Skatepark',
			'Woodbine-Lumsden',
			'Silver Berry',
			'Hayes Subdivision',
			'Forest Hill North',
			"O'Connor-Parkview",
			'Bedford Park-Nortown',
			'Elms-Old Rexdale',
			'Trent Lakes',
			'Acton Vale',
			'Arbutus Ridge',
			'Barrie',
			'Bay St. George South',
			'Bells Corners',
			'Berwick',
			'Bois-des-Filion',
			'Bonavista',
			'Bowen Island',
			'Bracebridge',
			'Charlottetown',
			'Chute-aux-Outardes',
			'Farnham',
			'Fort-Coulonge',
			'Fort Smith',
			'Gatineau',
			'Godefroy',
			'Goulds',
			'Harriston',
			'Igloolik',
			'Kindersley',
			'Labelle',
			'La Conception',
			'Lanoraie',
			'Lavaltrie',
			'Leduc',
			'Lloydminster',
			'Mascouche',
			'Nepean',
			'Osoyoos',
			'Parksville',
			'Powassan',
			'Prince Edward',
			'Princeton',
			'Regent Park',
			'Saint-Denis-sur-Richelieu',
			'Sainte-Anne-des-Monts',
			'Sainte-Thérèse',
			'Sechelt',
			'Souris',
			'Steeles',
			'Tofield',
			'Tottenham',
			'Yamachiche',
			'Dawson City',
			'Sydney Mines',
			'Saint-Antoine-de-Tilly',
			'Mill Woods Town Centre',
			'Yonge-St.Clair',
			"L'Amoreaux",
			'Danforth East York',
			'Bridle Path-Sunnybrook-York Mills',
			'Airdrie',
			'Brooks',
			'Burlington',
			'Burns Lake',
			'Camlachie',
			'Carman',
			'Ganges',
			'Goderich',
			'Gravenhurst',
			'Kelowna',
			'King',
			'Lac-Lapierre',
			'Lewisporte',
			"L'Île-Perrot",
			'Melfort',
			'Milton',
			'Mont-Royal',
			'Napierville',
			'Oakville',
			'Parry Sound',
			'Pasadena',
			'Peachland',
			'Picton',
			'Richibucto',
			'Rimbey',
			'Saint-Boniface',
			'Saint-Jean-sur-Richelieu',
			'Saint-Marc-des-Carrières',
			'Smiths Falls',
			'St. Anthony',
			'Val-David',
			'Amqui',
			'Plessisville',
			'La Pocatière',
			'Saint-Lambert-de-Lauzon',
			'Florenceville-Bristol',
			'Corso Italia-Davenport',
			'Newtonbrook West',
			'Broadview North',
			'Church-Yonge Corridor',
			'100 Mile House',
			'Beaverlodge',
			'Burnaby',
			'Chertsey',
			'Dorset Park',
			'East Gwillimbury',
			'Fernie',
			'Glencoe',
			'Lac-Alouette',
			'Lac-Brome',
			'Macamic',
			'Macklin',
			'Mont-Laurier',
			'Niagara-on-the-Lake',
			'Norwood',
			'Pembroke',
			'Ponoka',
			'Port Stanley',
			'Prince Rupert',
			'Rideau Park',
			'Rosemère',
			'Saint Andrews',
			'Saint-Éphrem-de-Beauce',
			'Saint-Gabriel',
			'Saint-Lin-Laurentides',
			'Sherwood Park',
			'Sooke',
			'Stony Plain',
			'Ucluelet',
			'Virden',
			'Virgil',
			'Waterloo',
			'Winkler',
			'Lac-des-Aigles',
			"L'Ancienne-Lorette",
			'Bonaventure',
			'Greenwood-Coxwell',
			"L'Orignal",
			'Shilo',
			'Westlake',
			'Haliburton Village',
			'Mount Pleasant West',
			'Willowridge-Martingrove-Richview',
			'East End-Danforth',
			'Ballantrae',
			'Bayview Village',
			'Brantford',
			'Castlegar',
			'Cranbrook',
			'Creston',
			'Didsbury',
			'Dieppe',
			'Falher',
			'Fort Erie',
			'Fort Frances',
			'Gibbons',
			'Glace Bay',
			'Hampstead',
			'Hearst',
			'Kamloops',
			'Kennedy Park',
			'Lac La Biche',
			'Lakefield',
			'Les Coteaux',
			'Lethbridge',
			'Madoc',
			'Maple Ridge',
			'Napanee',
			'Ormstown',
			'Pelican Narrows',
			'Pierreville',
			'Saint-Liboire',
			'Sherbrooke',
			'Slave Lake',
			'St. Albert',
			'Wabana',
			'Wolfville',
			'Buckingham',
			'Sacré-Coeur',
			'Hauterive',
			'North Oyster/Yellow Point',
			'West Humber-Clairville',
			'Mount Olive-Silverstone-Jamestown',
			'Black Creek',
			'Arnprior',
			'Aurora',
			'Banff',
			'Blainville',
			'Bon Accord',
			'Camrose',
			'Châteauguay',
			'Claresholm',
			'Dorval',
			'Drummondville',
			'Hudson Bay',
			'Indian Head',
			'Kimberley',
			'Ladysmith',
			'Lanigan',
			'La Prairie',
			'Lunenburg',
			'Morden',
			'Plantagenet',
			'Port McNeill',
			'Renfrew',
			'Rouge',
			'Rougemont',
			'Saanichton',
			'Saint-Bruno-de-Guigues',
			'Saint-Pie',
			'Saint-Raymond',
			'Saint-Rémi-de-Tingwick',
			'Salmo',
			'Sept-Îles',
			'Shaunavon',
			'Shawinigan',
			'Sussex',
			'Vancouver',
			'Vanier',
			'Vernon',
			'Walpole Island',
			'Westmount',
			'Williams Lake',
			'Saint-Lazare-de-Bellechasse',
			'Cacouna',
			'Cabano',
			'Fossambault-sur-le-Lac',
			'Lower Sackville',
			'Cross Lake 19A',
			'Six Mile',
			'Edenbridge-Humber Valley',
			'Alma',
			'Atikokan',
			'Brandon',
			'Brownsburg-Chatham',
			'Clarenville-Shoal Harbour',
			'Greater Napanee',
			'Hanover',
			'Ile des Chênes',
			'Kentville',
			'Logan Lake',
			'Meaford',
			'Millbrook',
			'Mont-Saint-Hilaire',
			'Oromocto',
			'Saint-Antoine',
			'Saint-Césaire',
			'Saint-Elzéar',
			'Saint-Hippolyte',
			'Saint-Lazare',
			'Sicamous',
			'Souris',
			'Springhill',
			'Stratford',
			'Swan Hills',
			'Terrace',
			'Verchères',
			'Wetaskiwin',
			"L'Ange-Gardien",
			'Maliotenam',
			'Cap-Santé',
			'Donnacona',
			'Woodbine Corridor',
			'Fairwinds',
			'Pelmo Park-Humberlea',
			'Playter Estates-Danforth',
			'Don Valley Village',
			'Alderwood',
			'Cambridge Bay',
			'Chestermere',
			'Cookshire-Eaton',
			'Deux-Montagnes',
			'Fox Creek',
			'Hillcrest Village',
			'Hope',
			'Joliette',
			'Langley',
			'Lorette',
			'Lorraine',
			'Melocheville',
			'Olds',
			'Parkhill',
			'Quesnel',
			'Rivers',
			'Sault Ste. Marie',
			'Shelburne',
			'Vaughan',
			'Waskaganish',
			'Wembley',
			'Westlock',
			'Winnipeg',
			'Saint-Siméon',
			'Lambton Baby Point',
			'Lansing-Westgate',
			'Bayview Woods-Steeles',
			'Assiniboia',
			'Belfast',
			'Belmont',
			'Bromont',
			'Brussels',
			'Carberry',
			'Clyde River',
			'Corner Brook',
			'Dalmeny',
			'Deer Lake',
			'Delta',
			'Elkford',
			'Franklin',
			'Golden',
			'Greater Sudbury',
			'Kamsack',
			'Kapuskasing',
			'Keswick',
			'Kirkland',
			'Lindsay',
			'Louiseville',
			'Markham',
			'Mayerthorpe',
			'Métabetchouan',
			'Middleton',
			'Mont-Tremblant',
			'New Toronto',
			'Notre-Dame-du-Lac',
			'Pointe-Claire',
			'Prévost',
			'Queenswood Heights',
			'Rayside-Balfour',
			'Saint-Bruno-de-Montarville',
			'Saint-Donat-de-Montcalm',
			'Sainte-Thècle',
			'Saint-Félicien',
			'Saint-Gédéon',
			'Saint-Pierre-les-Becquets',
			'Selkirk',
			'Watson Lake',
			'Weyburn',
			'Whitehorse',
			'Lévis',
			'Bathurst',
			'Saint-Augustin-de-Desmaures',
			'Princeville',
			'Shippagan',
			'La Haute-Saint-Charles',
			'Fallingbrook',
			'Saint-Pacôme',
			'Maple Leaf',
			'Bay Street Corridor',
			'Amherst',
			"Baie-D'Urfé",
			'Blackfalds',
			'Candiac',
			'Elk Point',
			'Englehart',
			'Essex',
			'Fredericton',
			'Guelph',
			'Hudson',
			'Lac-Connelly',
			'Lamont',
			'Linière',
			'Malvern',
			'Marieville',
			'Maskinongé',
			'Morris',
			'Morrisburg',
			'Oak Bay',
			'Picture Butte',
			'Revelstoke',
			'Rockwood',
			'Sainte-Catherine',
			'Sainte-Julienne',
			'Saint-Germain-de-Grantham',
			'Saint-Jérôme',
			'Saint-Joseph',
			'Salisbury',
			'Sarnia',
			'Shediac',
			'St. Catharines',
			'Tweed',
			'Viking',
			'White Rock',
			'Rimouski',
			'Edmundston',
			'Okanagan',
			'Casa Loma',
			'Fogo Island',
			'Waterfront Communities-The Island',
			'Banbury-Don Mills',
			'Birchcliffe-Cliffside',
			'Lawrence Park North',
			'Lawrence Park South',
			'Willowdale East',
			'Kensington-Chinatown',
			'Kingsway South',
			'Brossard',
			'Carbonear',
			'Clinton',
			'Colwood',
			'Dorchester',
			'Esquimalt',
			'Fermont',
			'Flemingdon Park',
			'Flin Flon',
			'Grand Falls-Windsor',
			'Gravelbourg',
			'Highland Creek',
			'Huron East',
			'Iroquois Falls',
			'Keswick Ridge',
			'Kingston',
			"L'Assomption",
			'Les Cèdres',
			'Manitouwadge',
			'Meadow Lake',
			'Montréal-Est',
			'Morin-Heights',
			'Mount Dennis',
			'New Westminster',
			'Normandin',
			'North Vancouver',
			'Pilot Butte',
			'Pincourt',
			'Port-Cartier',
			'Port Moody',
			'Rawdon',
			'Rouyn-Noranda',
			'Sainte-Madeleine',
			'Saint John',
			'Salaberry-de-Valleyfield',
			'Sexsmith',
			'Sorel-Tracy',
			'Spruce Grove',
			'Terrasse-des-Pins',
			'Terrebonne',
			'Thurso',
			'Val-des-Monts',
			'Napanee Downtown',
			'Pont-Rouge',
			'Saint-Antonin',
			'Larkspur',
			'Islington-City Centre West',
			'Westminster-Branson',
			'Glenfield-Jane Heights',
			'Beaumont',
			'Beloeil',
			'Boisbriand',
			'Casselman',
			'Dawson Creek',
			'East Angus',
			'Grimshaw',
			'Harbour Breton',
			'Innisfil',
			'Magog',
			'Malartic',
			'Manning',
			'Mission',
			'Moosomin',
			'Nakusp',
			'Otterburn Park',
			'Prince Albert',
			'Prince George',
			'Provost',
			'Rosthern',
			'Royston',
			'Sackville',
			"Saint-Adolphe-d'Howard",
			'Saint-Basile-le-Grand',
			'Saint-Joseph-de-Coleraine',
			'Sparwood',
			'Sutton',
			'Taber',
			"Tam O'Shanter-Sullivan",
			'Windsor',
			'Carleton-sur-Mer',
			'Pleasant View',
			'Shannon',
			'Wendover',
			'Humewood-Cedarvale',
			'University',
			'Runnymede-Bloor West Village',
			'Athabasca',
			'Boissevain',
			'Chatham',
			'Contrecoeur',
			'Dartmouth',
			'Ellison',
			'Enderby',
			'Esterhazy',
			'Fort McMurray',
			'Gander',
			'Havre-Saint-Pierre',
			'Langenburg',
			'Lantz',
			'Merritt',
			'Miramichi',
			'Neebing',
			'North Battleford',
			'Port Alberni',
			'Red Lake',
			'Roberval',
			'Saint-Ambroise',
			'Saint-Augustin',
			'South Huron',
			'Spirit River',
			'Stonewall',
			'Temiskaming Shores',
			'Tobermory',
			'Vaudreuil-Dorion',
			'Welcome Beach',
			'Québec',
			'Forestville',
			'Cole Harbour',
			'Long Branch',
			'Forest Hill South',
			'Attawapiskat',
			'Bancroft',
			'Bay Roberts',
			'Beauharnois',
			'Bendale',
			'Biggar',
			'Cambridge',
			'Capreol',
			'Chase',
			'Constance Bay',
			'Danville',
			'Dunham',
			'Elmvale',
			'Fort Nelson',
			'French Creek',
			'Gambo',
			'Grand Forks',
			'Lumby',
			'Mackenzie',
			'Matagami',
			'Minnedosa',
			'Nipawin',
			'Notre-Dame-de-Grâce',
			'Orangeville',
			'Parrsboro',
			'Pointe-du-Lac',
			'Portage la Prairie',
			'Richelieu',
			'Saint-Canut',
			'Saint-Honoré',
			'Saint-Victor',
			'Shellbrook',
			'Uxbridge',
			'Vegreville',
			'Wakefield',
			'Watrous',
			'Wilkie',
			'Winchester',
			'Woodstock',
			'Wynyard',
			'Walnut Grove',
			'le Plateau',
			'Sainte-Élisabeth',
			'Parkwoods-Donalda',
			'South Parkdale',
			'Caledonia-Fairbank',
			'Agincourt North',
			'Belleville',
			'Bourget',
			'Calgary',
			'Cardston',
			'Cliffcrest',
			'Collingwood',
			'Concord',
			'Coquitlam',
			'Côte-Saint-Luc',
			'Drayton Valley',
			'English Corner',
			'Gjoa Haven',
			'Grande Cache',
			'Hanna',
			'Lac-Mégantic',
			'Lake Echo',
			'Maple Creek',
			'New Hamburg',
			'North Perth',
			'Ottawa',
			'Rigaud',
			'Russell',
			'Sainte-Martine',
			'Saint-Polycarpe',
			'Toronto',
			'Unity',
			'New-Richmond',
			'New Carlisle',
			'Moss Park',
			'West Vancouver',
			'Golden',
			'Le Bic',
			'Taylor-Massey',
			'Leaside-Bennington',
			'Rustic',
			'Alliston',
			'Amos',
			'Bonnyville',
			'Boucherville',
			'Cape Dorset',
			'Carleton Place',
			'Chester',
			'Coalhurst',
			'Crossfield',
			'Duncan',
			'Kingsey Falls',
			'Lambton Shores',
			'La Minerve',
			'Lappe',
			"L'Ascension-de-Notre-Seigneur",
			// 'London',
			'Longueuil',
			'Metcalfe',
			'Milliken',
			'Neepawa',
			'New Maryland',
			'Port Coquitlam',
			'Puntledge',
			'Quinte West',
			'Sainte-Anne-des-Plaines',
			'Sainte-Béatrix',
			'Saint-Eustache',
			'Saint-Hyacinthe',
			'Shawville',
			'Shelburne',
			'Sioux Lookout',
			'Tecumseh',
			'The Pas',
			'Upper Island Cove',
			'Valleyview',
			'Waswanipi',
			'Sainte-Marie',
			'Omemee',
			'East Broughton',
			'Springbrook',
			'Rosedale-Moore Park',
			'Annex',
			'Kingsview Village-The Westway',
			'Abbotsford',
			'Adstock',
			'Anmore',
			'Bouctouche',
			'Calmar',
			'Cochrane',
			'Grande Prairie',
			'Kanata',
			'La Broquerie',
			'Lachute',
			"L'Épiphanie",
			'Lucan',
			'Markland Wood',
			'Mildmay',
			'Mirabel',
			'Newmarket',
			'Norfolk County',
			'Owen Sound',
			'Oxford',
			'Perth',
			'Pictou',
			'Port Williams',
			'Preeceville',
			'Rivière-Rouge',
			'Saint-Amable',
			'Saint-Jean-Baptiste',
			'Squamish',
			'Swan River',
			'Thunder Bay',
			'Tumbler Ridge',
			'Neuville',
			'Saint-Georges',
			'Les Escoumins',
			'West Kelowna',
			'Metabetchouan-Lac-a-la-Croix',
			'Dufferin Grove',
			'Heritage Pointe',
			'Aldergrove East',
			'Starlight Village',
			'Old East York',
			'Rockcliffe-Smythe',
			'Weston-Pellam Park',
		],
	},
	'Cape Verde': {
		country: 'Cape Verde',
		cities: [
			'João Teves',
			'Assomada',
			'Porto Novo',
			'Mindelo',
			'Santa Cruz',
			'Ponta do Sol',
			'Pedra Badejo',
			'Praia',
			'Picos',
			'São Filipe',
			'Ribeira Brava',
			'Tarrafal',
			'Sal Rei',
			'Ribeira Grande',
			'Tarrafal de São Nicolau',
			'Igreja',
			'Pombas',
			'Nova Sintra',
			'Cidade Velha',
			'Santa Maria',
			'Espargos',
			'Cova Figueira',
			'Calheta',
			'São Domingos',
			'Vila do Maio',
		],
	},
	'Cayman Islands': { country: 'Cayman Islands', cities: ['Bodden Town', 'Little Cayman', 'North Side', 'West Bay', 'George Town', 'East End'] },
	'Central African Republic': {
		country: 'Central African Republic',
		cities: [
			'Bria',
			'Carnot',
			'Kembé',
			'Bimbo',
			'Mongoumba',
			'Mbaïki',
			'Kabo',
			'Bangui',
			'Bossangoa',
			'Boali',
			'Ouango',
			'Gambo',
			'Ndélé',
			'Mobaye',
			'Gamboula',
			'Zemio',
			'Alindao',
			'Nola',
			'Bozoum',
			'Sibut',
			'Bouca',
			'Boda',
			'Bambari',
			'Kaga Bandoro',
			'Bouar',
			'Kouango',
			'Berbérati',
			'Baoro',
			'Ippy',
			'Batangafo',
			'Paoua',
			'Ouadda',
			'Obo',
			'Birao',
			'Bangassou',
			'Damara',
		],
	},
	Chad: {
		country: 'Chad',
		cities: [
			'Moundou',
			'Massaguet',
			'Abéché',
			'Sarh',
			'Goundi',
			'Bousso',
			'Aozou',
			'Pala',
			'Massakory',
			"N'Djamena",
			'Iriba',
			'Mao',
			'Kyabé',
			'Goz Béïda',
			'Bitkine',
			'Fada',
			'Doba',
			'Bol',
			'Beïnamar',
			'Béré',
			'Koumra',
			'Bébédja',
			'Ngama',
			'Bongor',
			'Gounou Gaya',
			'Faya-Largeau',
			'Amdjarass',
			'Goz-Beida',
			'Melfi',
			'Mboursou Léré',
			'Massenya',
			'Kelo',
			'Dourbali',
			'Bokoro',
			'Biltine',
			'Adré',
			'Moïssala',
			'Moussoro',
			'Guelendeng',
			'Benoy',
			'Am-Timan',
			'Ati',
			'Oum Hadjer',
			'Mongo',
			'Béboto',
			'Laï',
			'Bardaï',
		],
	},
	Chile: {
		country: 'Chile',
		cities: [
			'Puyehue',
			'Illapel',
			'Parque Balmaceda',
			'Punta Arenas',
			'Puente Alto',
			'Ovalle',
			'Constitución',
			'Carahue',
			'Arica',
			'Tomé',
			'San Vicente',
			'Palena',
			'Machalí',
			'Lebu',
			'La Tirana',
			'Chicureo',
			'Alto Hospicio',
			'Goméz Carreño',
			'Vilcún',
			'Puerto Cisnes',
			'Limache',
			'Puerto',
			'Hospital',
			'Valparaíso',
			'San Javier',
			'San Felipe',
			'San Carlos',
			'Porvenir',
			'Parral',
			'Longaví',
			'El Monte',
			'Concepción',
			'Cañete',
			'La Tirana',
			'Puerto Natales',
			'Diego de Almagro',
			'Chiguayante',
			'San Vicente de Tagua Tagua',
			'La Unión',
			'Hacienda La Calera',
			'Bulnes',
			'Angol',
			'Taltal',
			'Talagante',
			'Coyhaique',
			'Iquique',
			'Chillán',
			'Victoria',
			'Santiago',
			'Rauco',
			'Quillota',
			'Penco',
			'La Serena',
			'Laja',
			'Huara',
			'Futaleufú',
			'Dalcahue',
			'Cuya',
			'Colbún',
			'Cochrane',
			'Chile Chico',
			'La Pintana',
			'Puerto Aysén',
			'Chañaral',
			'Camiña',
			'Villarrica',
			'Freire',
			'Chimbarongo',
			'Lo Prado',
			'Vicuña',
			'Temuco',
			'Pitrufquén',
			'Panguipulli',
			'Paine',
			'La Junta',
			'Vallenar',
			'Quilpué',
			'Mulchén',
			'Arauco',
			'San Antonio',
			'Puerto Montt',
			'Pica',
			'Lautaro',
			'Graneros',
			'Curanilahue',
			'Colchane',
			'Chonchi',
			'Cauquenes',
			'Hanga Roa',
			'Caupolicán',
			'Talcahuano',
			'Rancagua',
			'Putre',
			'Los Ángeles',
			'Lampa',
			'Corral',
			'Antofagasta',
			'Viña del Mar',
			'Quirihue',
			'Monte Patria',
			'Lota',
			'Coronel',
			'Coihueco',
			'Valdivia',
			'Tocopilla',
			'San Pedro de Atacama',
			'Mejillones',
			'Purranque',
			'Puerto Varas',
			'Pozo Almonte',
			'Llaillay',
			'Molina',
			'Calama',
			'Yumbel',
			'Villa Alemana',
			'Teno',
			'Río Bueno',
			'María Elena',
			'Baquedano',
			'Las Gaviotas',
			'Villa Presidente Frei',
			'San Clemente',
			'San Bernardo',
			'Pucón',
			'Loncoche',
			'La Ligua',
			'Collipulli',
			'Cabrero',
			'Buin',
			'Las Animas',
			'Barrio Industrial',
			'Talca',
			'Curicó',
			'Copiapó',
			'Cámeron',
			'Arturo Prat',
			'Santa Cruz',
			'Peñaflor',
			'Osorno',
			'Nueva Imperial',
			'Linares',
			'Cavancha',
			'Playa Brava',
			'Traiguén',
			'Salamanca',
			'Rengo',
			'Melipilla',
			'Coquimbo',
			'Chaitén',
			'Quellón',
			'Providencia',
			'Calbuco',
			'Ancud',
			'Nacimiento',
			'La Ensenada',
			'Cartagena',
			'Puerto Chacabuco',
			'Vallenar',
			'Los Andes',
			'Castro',
		],
	},
	China: {
		country: 'China',
		cities: [
			'Hualin',
			'Biruxong',
			'Dabagou',
			'Shaoyang',
			'Jingshan',
			'Longtian',
			'Shuanghe',
			'Yulin',
			'Baolong',
			'Qinglian',
			'Baiqiao',
			'Jiulongshan',
			'Qukou',
			'Zhoujiaba',
			'Huangbai',
			'Longsha',
			'Wangjia',
			'Huangsha',
			'Xumingsi',
			'Yajiang',
			'Shuanglong',
			'Wuma',
			'Shiqiao',
			'Pingqiao',
			'Houping',
			'Dandu',
			'Shijialiang',
			'Xiaojia',
			'Muxihe',
			'Huanggang',
			'Meilu',
			'Qintang',
			'Sandaoling Lutiankuang Wuqi Nongchang',
			'Heguan',
			'Yanshan',
			'Heshan',
			'Taoshan',
			'Luobupo',
			'Jiayi',
			'Mingshan',
			'Shuangqiao',
			'Sanyi',
			'Zibihu',
			'Yanduo',
			'Jiangzi',
			'Gengqing',
			'Molo',
			'Shayar',
			'Shihezi',
			'Qing’an',
			'Malingshan',
			'Xuzhuang',
			'Yushan',
			'Mengjin Chengguanzhen',
			'Nima',
			'Zhoujia',
			'Zhongzhai',
			'Zhaobaoshan',
			'Zhangjiapan',
			'Jicheng',
			'Yushan',
			'Yunfu',
			'Yongning',
			'Yanshan',
			'Yankou',
			'Xi Xian Chengguanzhen',
			'Xiugu',
			'Xituo',
			'Xinzhai',
			'Xinshi',
			'Fusui',
			'Xinkaikou',
			'Xindian',
			'Xindian',
			'Xiangtan',
			'Wuquan',
			'Fengzhou',
			'Wushan',
			'Dongyang',
			'Yakou',
			'Wenshui',
			'Wenheng',
			'Wenchang',
			'Tongbai Chengguanzhen',
			'Tieqiao',
			'Tangyang Chengguanzhen',
			'Si’en',
			'Laixi',
			'Shuanggang',
			'Shouguang',
			'Chongyang',
			'Sanyuan Chengguanzhen',
			'Sanshan',
			'Changsha',
			'Qiongshan',
			'Qingping',
			'Qincheng',
			'Qiaoguan',
			'Pingnan',
			'Nancang',
			'Nanbin',
			'Micheng',
			'Luzhai',
			'Luorong',
			'Luanchuan Chengguanzhen',
			'Liucheng',
			'Lishi',
			'Lishan',
			'Ruzhou',
			'Liangdang Chengguanzhen',
			'Laizhou',
			'Fucheng',
			'Kangle',
			'Aikou',
			'Jiuxian',
			'Echeng',
			'Jiazi',
			'Ningde',
			'Jiangjia',
			'Qionghai',
			'Chengguan',
			'Xintian',
			'Kaiyun',
			'Hengbei',
			'Helin',
			'Hede',
			'Fu’an',
			'Hanjiashu',
			'Hangou',
			'Gunan',
			'Gongcheng',
			'Gaoqiao',
			'Gaogu',
			'Gaocheng',
			'Fuchuan',
			'Fengyang Fuchengzhen',
			'Fengshan',
			'Fengchuan',
			'Dongtazhuang',
			'Diping',
			'Daixi',
			'Shanglin',
			'Chuzhou',
			'Chumi',
			'Changqing',
			'Changning',
			'Baokang Chengguanzhen',
			'Baitao',
			'Anren Chengguanzhen',
			'Tielou',
			'Yuanjue',
			'Hegeng',
			'Jiuchao',
			'Zhongshan',
			'Dongcheng',
			'Fuguo',
			'Xunzhong',
			'Yantai',
			'Mishan',
			'Zhaoyuan',
			'Chongli',
			'Songyuan',
			'Lianhe',
			'Kongjiazhuang',
			'Kaiyuan',
			'Fuyuan',
			'Baoqing',
			'Xiling',
			'Shuanglong',
			'Qindu',
			'Tongchuan',
			'Lianhu',
			'Songmai',
			'Liuku',
			'Lhasa',
			'Gyigang',
			'Yining',
			'Sandaohezi',
			'Dadamtu',
			'Zhumadian',
			'Zhong’ao',
			'Zhong’an',
			'Zhengzhou',
			'Zhengxing',
			'Zhaishi Miaozu Dongzuxiang',
			'Zengjia',
			'Yuanshan',
			'Youxi',
			'Yanggu',
			'Yangcheng',
			"Ya'an",
			'Xixi',
			'Baicheng',
			'Xiongshan',
			'Xinyu',
			'Xinshiba',
			'Xinmian',
			'Ximeicun',
			'Xichang',
			'Xianyang',
			'Yancheng',
			'Wujin',
			'Wucheng',
			'Weiji',
			'Weihai',
			'Tianzhou',
			'Tangwu',
			'Shuanghe',
			'Chujiang',
			'Shengrenjian',
			'Sanzhuang',
			'Dingchang',
			'Qibu',
			'Gutao',
			'Tiandong',
			'Pingdingshan',
			'Ning’er',
			'Nanpi',
			'Nanle Chengguanzhen',
			'Nanchangshan',
			'Maoping',
			'Maocun',
			'Maba',
			'Lizi',
			'Liangyaping',
			'Jingjiang',
			'Zhangping',
			'Jing’an',
			'Jinchang',
			'Jiming',
			'Jiexiu',
			'Jiawang',
			'Huoqiu Chengguanzhen',
			'Huangyangtan',
			'Hechuan',
			'Hechuan',
			'Hanyin Chengguanzhen',
			'Hanjiang',
			'Haicheng',
			'Guandu',
			'Gejiu',
			'Qingyang',
			'Fengming',
			'Lianjiang',
			'Eman',
			'Songyang',
			'Degan',
			'Dawukou',
			'Daokou',
			'Shangguan',
			'Dachang',
			'Chengjiang',
			'Changning',
			'Chaling Chengguanzhen',
			'Bengbu',
			'Baoying',
			'Shaoyang',
			'Baijian',
			'Baichihe',
			'Jiangyou',
			'Songling',
			'Ji’an',
			'Guochuan',
			'Xiaba',
			'Shilong',
			'Mutang',
			'Heshan',
			'Kutao',
			'Jitoucun',
			'Zhangjiakou',
			'Xinghe Chengguanzhen',
			'Suifenhe',
			'Songjianghe',
			'Pingzhuang',
			'Linjiang',
			'Liaoyang',
			'Jinzhou',
			'Jingyu',
			'Huangnihe',
			'Heihe',
			'Fujin',
			'Didao',
			'Huai’an',
			'Songling',
			'Hanbin',
			'Yongyang',
			'Yongchang',
			'Zhongba',
			'Sanyu',
			'Pingya',
			'Yiwa',
			'Mu’er',
			'Ha’ergai Dadui',
			'Pipa',
			'Changba',
			'Biru',
			'Chengzhong',
			'Jinrongjie',
			'Zuolan',
			'Wulong',
			'Zhuxian',
			'Dashu',
			'Caotang',
			'Bai’anba',
			'Sanxi',
			'Qingxichang',
			'Jinxi',
			'Anzi',
			'Tudi',
			'Gulong',
			'Bole',
			'Hengshan',
			'Changzhou',
			'Jinggou',
			'Mujia',
			'Jifeng',
			'Qiushan',
			'Qinting',
			'Sansha',
			'Aketikandun',
			"Kuoshi'airike",
			"Yigai'erqi",
			'Shangyi',
			'Samuyuzi',
			'Jiahanbage',
			'Zhongfeng',
			'Weixinghu',
			'Zhuhai',
			'Majie',
			'Sancang',
			'Mayuan',
			'Yangyong',
			'Wangzang',
			'Huatai',
			'Guanmian',
			'Maliu',
			'Changsha',
			'Houshan',
			'Changlong',
			'Shizhi',
			'Houping',
			'Huangxi',
			'Shipan',
			'Xiaochang',
			'Ping’an',
			'Fengcheng',
			'Cuiyun',
			'Wenhua',
			'Dongfeng',
			'Nanzhushan',
			'Fengyan',
			'Muliang',
			'Jinqiao',
			'Bailuquan',
			'Beitanzhuang',
			'Tianmen',
			'Yingwusitangcun',
			'Liubei',
			'Yunlong',
			'Wanggou',
			'Baicheng',
			'Hualong',
			'Xiufeng',
			'Zhangmianyi',
			'Guoye',
			'Kashitashi',
			'Akesalayi',
			'Caojie',
			'Puzi',
			'Yingpan',
			'Wendong',
			'Qamdo',
			'Mekit',
			'Hotan',
			'Kambaxoi',
			'Nansan',
			'Kalpin',
			'Yitang',
			'Zhaozhuang',
			'Ning’an',
			'Yuxia',
			'Yunmeng Chengguanzhen',
			'Yongning',
			'Tangxing',
			'Yandong',
			'Yajiang',
			'Xuchang',
			'Xiyan',
			'Xinji',
			'Jingxi',
			'Xiayi Chengguanzhen',
			'Xi’an',
			'Wuyun',
			'Changde',
			'Weizhou',
			'Touying',
			'Fuding',
			'Tongren',
			'Taipingchang',
			'Songyuan',
			'Song Xian Chengguanzhen',
			'Shilu',
			'Shanhu',
			'Shazikou',
			'Shaoguan',
			'Shangcang',
			'Qingquan',
			'Shaji',
			'Chengxiang',
			'Qianling',
			'Pucheng',
			'Pingyin',
			'Wuping',
			'Panlong',
			'Xinning',
			'Neixiang Chengguanzhen',
			'Nanzhao',
			'Nan’an',
			'Minquan Chengguanzhen',
			'Mengcheng Chengguanzhen',
			'Majiadian',
			'Luyang',
			'Longcheng',
			'Lixin',
			'Tian’e',
			'Linshui',
			'Linjiang',
			'Chongwen',
			'Lichuan',
			'Leping',
			'Tianlin',
			'Juye',
			'Jifeng',
			'Xiluodu',
			'Jin’e',
			'Jinbi',
			'Daqinglu',
			'Jiayue',
			'Jiangkouxu',
			'Hufeng',
			'Huazangsi',
			'Huangxikou',
			'Huangmaoyuan',
			'Huangjinjing',
			'Huagai',
			'Hezuo',
			'Hancheng',
			'Gulaobei',
			'Guanyin',
			'Guanyang',
			'Guangshun',
			'Zishui',
			'Gaozhuang',
			'Gaoleshan',
			'Gaoguan',
			'Gantang',
			'Fugou Chengguanzhen',
			'Fengpu',
			'Fangshan',
			'Enjiang',
			'Ehen Hudag',
			'Dongcun',
			'Dengzhou',
			'Dalu',
			'Dajie',
			'Da’an',
			'Langchi',
			'Beimeng',
			'Beihuaidian',
			'Fulilu',
			'Dahedian',
			'Bashan',
			'Tonggu',
			'Zhisheng',
			'Baofeng',
			'Dashi',
			'Leiwang',
			'Liuping',
			'Wanghe',
			'Zhangzi',
			'Xingsha',
			'Tianshan',
			'Sunjia Buzi',
			'Fendou',
			'Mandalt',
			'Lianshan',
			'Jilin',
			'Jidong',
			'Fengcheng',
			'Bei’an',
			'Tailong',
			'Yanshan',
			'Dingcheng',
			'Lianjiang',
			'Weiyang',
			'Zhuyuan',
			'Bazang',
			'Wuping',
			'Bailin',
			'Taoyan',
			'Shoushan',
			'Taishi',
			'Paosha',
			'Mangqu',
			'Kajiadao',
			'Yuanzhou',
			'Moluo',
			'Qinhong',
			'Xingfeng',
			'Yunyang',
			'Xianyi',
			'Tianxing',
			'Hongchun',
			'Baiquan',
			'Rangdu',
			'Huangshui',
			'Liutang',
			'Qixing',
			'Wenhua',
			'Liren',
			'Nanmu',
			'Banxi',
			'Qianjiang',
			'Sanhe',
			'Qingxi',
			'Qingsheng',
			'Aimin',
			'Qilong',
			'Dongtundu',
			'Tieganlike',
			'Xihu',
			'Dongdajie',
			'Sansheng',
			'Qipan',
			'Liangqing',
			'Shuangtang',
			'Shangchong',
			'Qingshui',
			'Xinhua',
			'Taobin',
			'Awati',
			"Ka'erdun",
			'Xiangfang',
			'Daowai',
			'Hefeng',
			'Heishan',
			'Pingwu County',
			'Zhedao',
			'Wangda',
			'Shangyun',
			'Karaki',
			'Kaqun',
			'Fengshan',
			'Altay',
			'Qulukay',
			'Xinglou',
			'Songlou',
			'Zhongxinqiao',
			'Zhongba',
			'Zhaozhen',
			'Zhaogezhuang',
			'Yulinshi',
			'Jiulong',
			'Yuanping',
			'Yongning',
			'Yongchun',
			'Yinliu',
			'Yangtou',
			'Yangjiao',
			'Xujiang',
			'Longxing',
			'Xincheng',
			'Wacheng Neighborhood',
			'Xiancun',
			'Wuda',
			'Wanzhi',
			'Wanchao',
			'Waina',
			'Fuyu',
			'Songyang',
			'Shuangshipu',
			'Shuanglu',
			'Dongsheng',
			'Shaodian',
			'Rongshui',
			'Qingkou',
			'Qingfeng Chengguanzhen',
			'Puji',
			'Pingyuan',
			'Niujiapai',
			'Nanding',
			'Mu’er',
			'Mingguang',
			'Mawang',
			'Lushi Chengguanzhen',
			'Luoning Chengguanzhen',
			'Long’an',
			'Linyi',
			'Lihou',
			'Leshan',
			'Lianyuan',
			'Kengyuan',
			'Juanshui',
			'Yancheng',
			'Jin’an',
			'Jiancheng',
			'Jianjiang',
			'Xiaopu',
			'Huangzhuang',
			'Yunzhong',
			'Honghe',
			'Hezheng Chengguanzhen',
			'Heyin',
			'Haojiaqiao',
			'Lingbao Chengguanzhen',
			'Gaozuo',
			'Gaohe',
			'Fuling',
			'Fengjia',
			'Fanzhuang',
			'Erlang',
			'Dongyuan',
			'Dongnigu',
			'Dongdu',
			'Dinghaicun',
			'Dingbian',
			'Dengzhou',
			'Fenghua',
			'Dabaozi',
			'Danyang',
			'Xinshancun',
			'Chongru',
			'Chiping',
			'Chengyang',
			'Chenggu',
			'Wu’an',
			'Caijia',
			'Bencheng',
			'Baoshan',
			'Langzhong',
			'Baojia',
			'Baixi',
			'Aoyang',
			'Anxiang',
			'Gaosheng',
			'Tuchang',
			'Qingshancun',
			'Rong’an',
			'Kuai’an',
			'Donghu',
			'Longsheng',
			'Zunhua',
			'Xinglongshan',
			'Tieli',
			'Suiling',
			'Suihua',
			'Shuangtashan',
			'Shacheng',
			'E’erguna',
			'Keshan',
			'Jiutai',
			'Fendou',
			'Ji’an',
			'Inder',
			'Hushitai',
			'Hunchun',
			'Hohhot',
			'Beichengqu',
			'Duolun',
			'Yijiang',
			'Nanhe',
			'Xinchengzi',
			'Shizi',
			'Hanban',
			'Zhuoluo',
			'Wuying',
			'Yinxingshu',
			'Xinchenglu',
			'Dingqing',
			'Gaoyan',
			'Dangyang',
			'Longsha',
			'Caohui',
			'Baijia',
			'Shi’an',
			'Haiyang',
			'Tushi',
			'Heixi',
			'Longqiao',
			'Qiantang',
			'Wanghong',
			'Sankeshu',
			'Zhuyi',
			'Qugaona',
			'Kalayagaqi',
			'Shahe',
			'Liangping',
			'Changsheng',
			'Qingnian',
			'Zhadong',
			'Waxxari',
			'Tengyue',
			'Qiemo',
			'Pubu',
			'Jiding',
			'Dartang',
			'Xinhe',
			'Wusu',
			'Jimsar',
			'Aksu',
			'Kax',
			'Xindu',
			'Yaxing',
			'Zigong',
			'Zhenping Chengguanzhen',
			'Zaoyang',
			'Kunshan',
			'Yuanjiazhuang',
			'Yongchang Chengguanzhen',
			'Yinma',
			'Yingshang Chengguanzhen',
			'Yinggen',
			'Yichang',
			'Xinzhi',
			'Xinji',
			'Xinglong',
			'Xindi',
			'Xincheng',
			'Xincheng',
			'Xin’an',
			'Xianxi',
			'Xianju',
			'Xianghu',
			'Xiangdong',
			'Wuxuan',
			'Wenjiang',
			'Tiantang',
			'Shuiche',
			'Shiye',
			'Shishi',
			'Shichuan',
			'Shanji',
			'Shancheng',
			'Sanming',
			'Rende',
			'Rongxian',
			'Renhua',
			'Qiongxi',
			'Qinnan',
			'Qianqiu',
			'Qiagai',
			'Longcheng',
			'Picheng',
			'Guang’an',
			'Nanzhou',
			'Nantong',
			'Hongshui',
			'Mingcun',
			'Mengyan',
			'Maima',
			'Ma’anshan',
			'Luocheng',
			'Luhua',
			'Longzhou',
			'Longtou’an',
			'Lixian',
			'Liquan Chengguanzhen',
			'Lintingkou',
			'Linkou',
			'Liancheng',
			'Ningshan Chengguanzhen',
			'Jingzhou',
			'Jingling',
			'Jinghai',
			'Jielong',
			'Jian’ou',
			'Jiangluo',
			'Jiang’an',
			'Hutang',
			'Longquan',
			'Huangshan',
			'Huaicheng',
			'Huacheng',
			'Hezhang',
			'Hebeitun',
			'Guyang',
			'Guozhen',
			'Guandu',
			'Gonghe',
			'Gaozhou',
			'Gaoliu',
			'Fuzhou',
			'Encheng',
			'Dongying',
			'Dongtai',
			'Dingshi',
			'Dakoutun',
			'Chuimatan',
			'Chongkan',
			'Chengtougu',
			'Chaotian',
			'Changyuan',
			'Chahe',
			'Basuo',
			'Baoyou',
			'Bamencheng',
			'Anfu',
			'Gupo',
			'Sanmiao',
			'Dazhaicun',
			'Huachuan',
			'Yanqing',
			'Yanji',
			'Xicheng',
			'Shulinzhao',
			'Qing’an',
			'Meihekou',
			'Jining',
			'Huanren',
			'Changshun',
			'Fushun',
			'Dongling',
			'Daban',
			'Changping',
			'Baokang',
			'Baiquan',
			'Baicheng',
			'Dachuan',
			'Changshu',
			'Mudan',
			'Bayan Nur',
			'Qixing',
			'Xiaoliang',
			'Jianshan',
			'Lianghe',
			'Guohe',
			'Ganquan',
			'Jindong',
			'Heping',
			'Wenquan',
			'Yuanhe',
			'Shilu',
			'Hongtu',
			'Fenhe',
			'Lihe',
			'Taibai',
			'Henghe',
			'Yinping',
			'Lirang',
			'Pengdong',
			'Lin’e',
			'Shiyan',
			'Longhe',
			'Shiwan',
			'Weilong',
			'Xiaoduchuan',
			'Jinshan',
			'Qinjiang',
			'Shazhou',
			'Nanjie',
			'Bageqi',
			'Gulou',
			'Wuzhi',
			'Renhuai',
			'Bazhong',
			'Dali Old Town',
			'Dongxia',
			'Ligang',
			'Xihu',
			'Hongqiao',
			'Larenguan',
			'Zhayou',
			'Azhatebage',
			'Bei’an',
			'Daxing’anling',
			'Tuwaite',
			'Guanglu',
			'Zangguy',
			'Xuelin',
			'Racaka',
			'Qümong',
			'Coka',
			'Baohe',
			'Onsu',
			'Qongkur',
			'Lanshan',
			'Daizhuang',
			'Xianshui',
			'Zuitai',
			'Zongyang',
			'Zhouzhuang',
			'Zhabei',
			'Youxi',
			'Yongjia',
			'Yongbei',
			'Yantian',
			'Xuyang',
			'Xinhua',
			'Xing’an',
			'Xindou',
			'Zhangwan',
			'Xialiang',
			'Wuzhen',
			'Wuyang',
			'Wujiashan',
			'Weishi Chengguanzhen',
			'Wayaobu',
			'Anping',
			'Tubu',
			'Tongzhou',
			'Tingdong',
			'Tanwan',
			'Tangba',
			'Taixing',
			'Songyuan',
			'Songjiang',
			'Shizuishan',
			'Chentuan',
			'Taining',
			'Seke',
			'Sangzi',
			'Rongmei',
			'Quanjiang',
			'Yongning',
			'Guanshan',
			'Nanxun',
			'Nanmaizhu',
			'Miluo Chengguanzhen',
			'Mianyang',
			'Mengyin',
			'Menglie',
			'Meijiang',
			'Luoyang',
			'Luolong',
			'Liuwudiancun',
			'Liulin',
			'Lingwu Nongchang',
			'Lin’an',
			'Liaocheng',
			'Anning',
			'Lianhecun',
			'Lianghekou',
			'Jiyuan',
			'Jinping',
			'Jingui',
			'Jingchuan Chengguanzhen',
			'Jiaozhou',
			'Tongzilin',
			'Jianjun',
			'Jiangna',
			'Jiachuan',
			'Huogezhuang',
			'Huazhou',
			'Huangzhou',
			'Dahuaishu',
			'Honghe',
			'Yixing',
			'Hecheng',
			'Handian',
			'Guilin',
			'Gongjing',
			'Gaotangling',
			'Fucheng',
			'Fenyi',
			'Fangcun',
			'Dongming Chengguanzhen',
			'Dongguan',
			'Damiao',
			'Dali',
			'Chunhua',
			'Chaozhou',
			'Beilizigu',
			'Aoshi',
			'Huilong',
			'Jinlong',
			'Dong’an',
			'Zhongliang',
			'Shuangliao',
			'Xiping',
			'Xinqing',
			'Xiaoshi',
			'Taonan',
			'Shanhecun',
			'Xunke',
			'Pingfang',
			'Lishu',
			'Lingyuan',
			'Lingdong',
			'Kulun',
			'Huadian',
			'Hailar',
			'Baishan',
			'Mositai',
			'Hexi',
			'Taihe',
			'Yushan',
			'Panzhihua',
			'Xilinji',
			'Hejiabao',
			'Baiguan',
			'Dachuan',
			'Baoziba',
			'Shaoyu',
			'Yuguan',
			'Dongjie',
			'Binhu',
			'Jiefanglu',
			'Lugu',
			'Gulou',
			'Changgui',
			'Jianping',
			'Ping’an',
			'Taizhou',
			'Pailou',
			'Longtan',
			'Huwei',
			'Ditang',
			'Wangjia',
			'Shuangfeng',
			'Baoding',
			'Guchang',
			'Xinxing',
			'Xi’an',
			'Fushou',
			'Wangyue',
			'Nan’an',
			'Jibenggang',
			'Lengshui',
			'Jinzhong',
			'Tumxuk',
			'Shunchang',
			'Xingning',
			'Wanqiao Zhen',
			'Youjiang',
			'Zheshan',
			'Shizuishan',
			'Amuquhu',
			'Paikeqi',
			'Wudalike',
			'Hudiyuzi',
			'Mazha',
			"Ka'ersai",
			'Zhawa',
			'Jiaping',
			'Tiedong',
			'Shanwangping',
			'Erdao',
			'Yopurga',
			'Geji',
			'Mengsuo',
			'Machali',
			'Luzhang',
			'Delingha',
			'Urho',
			'Jelilyüzi',
			'Hoxut',
			'Yengitam',
			'Liangji',
			'Hanwang',
			'Zhangzhuang',
			'Zoucheng',
			'Zhuxi',
			'Zhoucun',
			'Zhifang',
			'Zhengji',
			'Danzhu',
			'Yushan',
			'Yuntai',
			'Yucheng',
			'Youting',
			'Yong’an',
			'Yiyang Chengguanzhen',
			'Yinying',
			'Yicheng',
			'Yatou',
			'Yanjing',
			'Yangshi',
			'Yangshuo',
			'Xintian',
			'Xiazhuang',
			'Xiangxiang',
			'Xiajiang',
			'Wuming',
			'Weituo',
			'Weimiao',
			'Wangfen',
			'Tuanfeng',
			'Tongxu Chengguanzhen',
			'Tianchi',
			'Taishan',
			'Suizhou',
			'Quyang',
			'Songcheng',
			'Shuikou',
			'Shuanghe',
			'Yilong',
			'She Xian',
			'Shangkou',
			'Shaliuhe',
			'Shahejie',
			'Guwei',
			'Tanbei',
			'Jinjiang',
			'Qingfu',
			'Qiaozhuang',
			'Puyang',
			'Pisha',
			'Pingshan',
			'Ningzhou',
			'Nandan',
			'Mole',
			'Meichang',
			'Malianzhuang',
			'Lüshun',
			'Longxing',
			'Xiayang',
			'Linfeng',
			'Kuangshi',
			'Lanyi',
			'Jiuchenggong',
			'Jinshi',
			'Jingmen',
			'Tianning',
			'Huicheng',
			'Huayuan',
			'Huangshi',
			'Huanglong',
			'Huangcaotuo',
			'Huaibei',
			'Wenbi',
			'Hengdong Chengguanzhen',
			'Hebi',
			'Gongtan',
			'Gaoliu',
			'Lingchuan',
			'Fuliang',
			'Duru',
			'Dongshan',
			'Dingjia',
			'Daqiao',
			'Danjiangkou',
			'Chenyang',
			'Chengxi',
			'Bozhou',
			'Beitan',
			'Bayan Hot',
			'Baima',
			'Badaogu',
			'Qilian',
			'Xiliguantun',
			'Yongxi',
			'Tieshan',
			'Qianhu',
			'Wenquan',
			'Yunshan',
			'Chang’an',
			'Anlan',
			'Duohu',
			'Luogang',
			'Zhuozishan',
			'Tailai',
			'Qinglong',
			'Nantai',
			'Mingshui',
			'Genhe',
			'Changbai',
			'Benxi',
			'Acheng',
			'Zhongtang',
			'Tiekuang',
			'Qianjin',
			'Furong Beilu',
			'Damoujia',
			'Shanwang',
			'Katang Ailieke',
			'Wulong',
			'Yingqiu',
			'Leidong',
			'Donghe',
			'Lichuan',
			'Yanjia',
			"Ka'erqin",
			'Manglai',
			'Zhiping',
			'Yongping',
			'Xinsheng',
			'Dahe',
			'Zhangfeng',
			'Xangda',
			'Shigu',
			'Heshun',
			'Gyangkar',
			'Gaotai Chengguanzhen',
			'Yanqi',
			'Ürümqi',
			'Hami',
			'Burqin',
			'Baicheng',
			'Kipekyüzi',
			'Tangdong',
			'Zhongfang',
			'Zhicheng',
			'Zhaoyuan',
			'Yunxixian Chengguanzhen',
			'Yunling',
			'Yanjing',
			'Xun Xian Chengguanzhen',
			'Leping',
			'Xinzhou',
			'Yaofeng',
			'Zhuhai',
			'Xiabaishi',
			'Wuma',
			'Wangqinzhuang',
			'Tuncheng',
			'Songgai',
			'Shuijiang',
			'Shuibian',
			'Shucheng Chengguanzhen',
			'Shima',
			'Shilaoren Shequ',
			'Dongcheng',
			'Rucheng',
			'Zhuangyuan',
			'Qingdao',
			'Puzi',
			'Pingba',
			'Panlian',
			'Ningling Chengguanzhen',
			'Niangxi',
			'Nanping',
			'Nangong',
			'Nada',
			'Mianchi Chengguanzhen',
			'Meishan',
			'Mali',
			'Loufan',
			'Longmen',
			'Long’e',
			'Liuquan',
			'Liukuaizhuang',
			'Liu’anzhuang',
			'Linxia Chengguanzhen',
			'Lengshuijiang',
			'Jiyang',
			'Huayuan',
			'Qianzhou',
			'Jinhe',
			'Jinjiang',
			'Wulan',
			'Jiangwakou',
			'Ganting',
			'Huliao',
			'Huixian Chengguanzhen',
			'Huayuan',
			'Huangzhuang',
			'Huangtukuang',
			'Huanfeng',
			'Huaiyuan Chengguanzhen',
			'Houxiang',
			'Hengbanqiao',
			'Hejiang',
			'Haidian',
			'Guyuan',
			'Fubao',
			'Fengtai',
			'Fengshan',
			'Erqu',
			'Enshi',
			'Dushan',
			'Duobao',
			'Douba',
			'Dongxing',
			'Xuanhan',
			'Dong’erying',
			'Xinle',
			'Dashan',
			'Gushu',
			'Daliang',
			'Changyi',
			'Changning',
			'Caohe',
			'Weining',
			'Biyang',
			'Bazhong',
			'Batang',
			'Baisha',
			'Babu',
			'Anju',
			'Ala',
			'Guanshan',
			'Runsong',
			'Koujiang',
			'Suhe',
			'Yangba',
			'Gulu',
			'Zouma',
			'Zhuolu',
			'Yilan',
			'Xilin Hot',
			'Xifeng',
			'Togrog Ul',
			'Yuquan',
			'Sunwu',
			'Longhua',
			'Daihai',
			'Kouqian',
			'Khuma',
			'Huludao',
			'Hulan',
			'Helong',
			'Gongchangling',
			'Fuyu',
			'Baiquan',
			'Xihai',
			'Xiangjiaba',
			'Shilin',
			'Taifu',
			'Jiashan',
			'Leiba',
			'Lijie',
			'Guzhan',
			'Chengjiao',
			'Weidian',
			'Miba',
			'Huangchen',
			'Kangduo',
			'Xuejiawan',
			'Meiyuan Xincun',
			'Jizhou',
			'Nanchansi',
			'Dayu',
			'Beiyuan',
			'Hehai',
			'Taiping',
			'Yanxi',
			'Chongxing',
			'Zengfu',
			'Gulou',
			'Tianguan',
			'Dianshui',
			'Jindaoxia',
			'Yongrong',
			'Weixin',
			'Jianhua',
			'Muyu',
			'Gongnong',
			'Guhua',
			'Meixian',
			'Bianjiang',
			'Shilin',
			'Beizheng',
			'Xinjia',
			'Nu’erbage',
			'Taoyuan',
			'Hongguang Qidui',
			'Jiawa Airikecun',
			'Kanjia',
			'Taitou',
			'Hehua',
			'Guojia',
			'Xicang',
			'Dongshan',
			'Hanbin',
			"Yingye'er",
			'Daoli',
			'Jiuquan',
			'Ruoqiang',
			'Ruidian',
			'Nagqu',
			'Kangmar',
			'Yuanquan',
			'Tashan',
			'Sunlou',
			'Zhuzhou',
			'Zhuji',
			'Zhijiang',
			'Zhaodun',
			'Xiping',
			'Yuecheng',
			'Yijing',
			'Yancheng',
			'Xisa',
			'Xining',
			'Suohe',
			'Xin’an Chengguanzhen',
			'Xinli',
			'Xiaoxita',
			'Xiantan',
			'Tantou',
			'Tanggu',
			'Guanghe Chengguanzhen',
			'Suishan',
			'Shunhe',
			'Shuidong',
			'Shuangshu',
			'Shuangjiang',
			'Zhouning',
			'Shantou',
			'Shankou',
			'Shangluo',
			'Jiyuanlu',
			'Shangjie',
			'Shanghang',
			'Sangmu',
			'Renhe',
			'Qufu',
			'Putian',
			'Puji',
			'Pingtan',
			'Pianjiao',
			'Niyang',
			'Yutan',
			'Nanping',
			'Nanhu',
			'Mentougou',
			'Mengquan',
			'Majie',
			'Luzhou',
			'Lushar',
			'Luoyang',
			'Luocheng',
			'Lulou',
			'Lufeng',
			'Longmen',
			'Longju',
			'Lizhuangzi',
			'Lixin Chengguanzhen',
			'Lipu',
			'Laohekou',
			'Lanzhou',
			'Tafeng',
			'Jinping',
			'Jincheng',
			'Jimo',
			'Jieshou',
			'Jiaokui',
			'Jiangping',
			'Huicheng',
			'Huazhou',
			'Huayuan',
			'Huankou',
			'Huaiyang Chengguanzhen',
			'Hongjiang',
			'Yiyang',
			'Hepo',
			'Heilangkou',
			'Guxi',
			'Guiyang Chengguanzhen',
			'Gaoqiao',
			'Gantang',
			'Fenshui',
			'Fengtai',
			'Douhudi',
			'Dongdai',
			'Daqiao',
			'Dabachang',
			'Chongxing',
			'Chenzhou',
			'Changcheng',
			'Chaigou',
			'Chadian',
			'Longxing',
			'Baise',
			'Baishi',
			'Aoyang',
			'Suzhou',
			'Guoliang',
			'Guansheng',
			'Dundong',
			'Baliwan',
			'Gucheng',
			'Yongping',
			'Yangying',
			'Mapingcun',
			'Xiangyun',
			'Xinmin',
			'Weichang',
			'Shulan',
			'Shenyang',
			'Qiezihe',
			'Nong’an',
			'Nenjiang',
			'Mishan',
			'Hulan Ergi',
			'Haliut',
			'Fuyu',
			'Daqing',
			'Bailingmiao',
			'Nayun',
			'Dongzhen',
			'Luozhou',
			'Jincheng',
			'Nanyang',
			'Puchi',
			'Tangga’an',
			'Kaba',
			'Bali',
			'Yupan',
			'Nagqu',
			'Bala Ga’er Gaole',
			'Sanjiang',
			'Tianba',
			'Taihe',
			'Baihe',
			'Sanhuikou',
			'Zhonggulou',
			'Xinmin',
			'Jindai',
			'Shuanglongchang',
			'Banqiao',
			'Yudi',
			'Jianlong',
			'Jiangtai',
			'Xinghua',
			'Hanwang',
			'Shencang',
			'Shajin',
			'Yulong',
			'Luoyu',
			'Shangping',
			'Renwu',
			'Xarlung',
			'Longshan',
			'Shengli',
			'Chang’an',
			'Nanmen',
			'Xiongjia',
			'Danzi',
			'Luotian',
			'Xinxiang',
			'Dongjia',
			'Longhe',
			'Qingquan',
			'Yiju',
			'Muye',
			'Luming',
			'Lizhi',
			'Huixing',
			'Shuanglu',
			'Sanhe',
			'Tuanbao',
			'Alashankou',
			'Nanshan',
			'Haokou',
			'Nantong',
			'Anxiang',
			'Beijie',
			'Qingxiu',
			'Huanglou',
			'Gucheng',
			'Ningdong',
			'Heshan',
			'Shunzhou',
			'Xinyingpan',
			'Fanba',
			'Zhongmiao',
			'Wutamu',
			'Baishikante',
			"Wu'erqi",
			'Shuanglonghu',
			'Guangxing',
			'Dingshan',
			'Zhamog',
			'Rikaze',
			'Kequ',
			'Maindong',
			'Lop',
			'Houqiao',
			'Nilka',
			'Fuyun',
			'Zunyi',
			'Jiangpu',
			'Zhujiajiao',
			'Zhouqu Chengguanzhen',
			'Zhoukou',
			'Zhanjiang',
			'Yuyue',
			'Yuguzhuang',
			'Yuanling',
			'Yongxing Chengguanzhen',
			'Yizhuang',
			'Zhongxiang',
			'Yanghou',
			'Xunchang',
			'Zhushan',
			'Xinan',
			'Xiantao',
			'Wugang',
			'Laocheng',
			'Wenzhou',
			'Wenshang',
			'Weishan',
			'Tongling',
			'Zhangjiang',
			'Shuitu',
			'Shuangjiang',
			'Xingye Xian',
			'Shimen',
			'Rensha',
			'Shanwei',
			'Sanjiang',
			'Ruyang Chengguanzhen',
			'Ruoshui',
			'Rulin',
			'Renxian',
			'Wuxi',
			'Zhaoyu',
			'Qingshuping',
			'Peicheng',
			'Nuojiang',
			'Ninghai',
			'Pucheng',
			'Jishan',
			'Nankang',
			'Nanjiang',
			'Nancun',
			'Mingfeng',
			'Mianyang',
			'Huichang',
			'Mawu',
			'Ma’an',
			'Luoxiong',
			'Luohe',
			'Longnan',
			'Longchang',
			'Long’an',
			'Linjiacun',
			'Lianjiang',
			'Kunming',
			'Kaihua Chengguanzhen',
			'Junlian',
			'Jizhou',
			'Wucheng',
			'Jinshiqiao',
			'Jieshi',
			'Gujiang',
			'Cixi',
			'Huaqiu',
			'Donghe',
			'Hanzhong',
			'Guiping',
			'Guanyang',
			'Guangzhou',
			'Ganshui',
			'Gabasumdo',
			'Fuyang',
			'Feicheng',
			'Doucun',
			'Dongxi',
			'Donghai',
			'Decheng',
			'Dengjiapu',
			'Darong',
			'Darenzhuang',
			'Dadunqiu',
			'Cuijiamatou',
			'Chonglong',
			'Changqiao',
			'Cangling',
			'Caidian',
			'Baoluan',
			'Baisha',
			'Tuqiao',
			'Luoli',
			'Ersheng',
			'Chengmen',
			'Hongtang',
			'Huai’an Shequ',
			'Houyu',
			'Yichun',
			'Xingcheng',
			'Liaozhong',
			'Biancheng',
			'Dalai',
			'Kangping',
			'Shixing',
			'Lexiu',
			'Dananyu',
			'Shandan',
			'Laxi',
			'Tongyangdao',
			'Ordos',
			'Chaowai',
			'Huayang',
			'Daocheng',
			'Jiuchi',
			'Hezui',
			'Baojia',
			'Nantianhu',
			'Sanjian',
			'Guanzhuang',
			'Echi',
			'Shuishi',
			'Daya',
			'Shangan',
			'Lijia',
			'Yangcheng',
			'Biekou',
			'Youganning',
			'Dongshan',
			'Dong’an',
			'Toudu',
			'Binhe',
			'Shengli',
			'Dizhuang',
			'Ying’awati',
			'Gongqingcheng',
			'Longnan',
			"Yak'airike",
			'Wanxiu',
			'Xinhe',
			'Maguan',
			'Jianshan',
			'Damusi',
			'Zirefuxiati',
			'Awuliya',
			'Tuohula',
			'Dianzi',
			'Taiyuan',
			'Yongcheng',
			'Dazhou District',
			'Wanling',
			'Xiabao',
			'Yeniugou',
			'Darya Boyi',
			'Dianyang',
			'Guma Baziri',
			'Luobuqiongzi',
			'Xambabazar',
			'Hoxtolgay',
			'Qaba',
			'Emin',
			'Egiztobe',
			'Hegou',
			'Huzhai',
			'Zhucheng',
			'Zhongxin',
			'Wufeng',
			'Zhangzhou',
			'Zhamashi',
			'Yunyang',
			'Pizhou',
			'Yunhe',
			'Yitiaoshan',
			'Dexing',
			'Yanliang',
			'Yangquan',
			'Yangjinzhuang',
			'Yakoucun',
			'Tongshan',
			'Xunsi',
			'Xiuwu Chengguanzhen',
			'Xiushan',
			'Xitangtuo',
			'Xincheng',
			'Xiaoshan',
			'Tongxiang',
			'Qingyuan',
			'Weitang',
			'Wansheng',
			'Wangji',
			'Huangshan',
			'Tongyang',
			'Tianliu',
			'Tiandeng',
			'Tangdukou',
			'Shuikou',
			'Zherong',
			'Tongchuanshi',
			'Shiji',
			'Shihui',
			'Shahe',
			'Sanya',
			'Sanjiang',
			'Sanhe',
			'Sangke',
			'Runing',
			'Qihama',
			'Qingyuan',
			'Qiaotou',
			'Pingqiao',
			'Hanchang',
			'Xinguan',
			'Pandu',
			'Nanzhang Chengguanzhen',
			'Yongle',
			'Nanlong',
			'Nanfeng',
			'Mingyu',
			'Wenlan',
			'Mawu',
			'Maoping',
			'Malin',
			'Longquan',
			'Longde Chengguanzhen',
			'Lishi',
			'Hepu',
			'Laiyang',
			'Labuleng',
			'Jichang',
			'Jiasi',
			'Gangdong',
			'Ji’an',
			'Huishi',
			'Huaqiao',
			'Helie',
			'Hekou',
			'Heba',
			'Hanjia',
			'Haikou',
			'Gulang',
			'Ganjia',
			'Fufeng',
			'Foshan',
			'Fengcheng',
			'Donglan',
			'Dongxi',
			'Daying',
			'Daxi',
			'Dabao’anzhen',
			'Chaohu',
			'Puxi',
			'Changtun',
			'Caoqiao',
			'Caijiapu',
			'Beihai',
			'Bao’an',
			'Mashan',
			'Fucheng',
			'Andongwei',
			'Huaihelu',
			'Shiyu',
			'Zhaoxing',
			'Nanhai',
			'Baisha',
			'Zhaodong',
			'Yi’an',
			'Hepingjie',
			'Ulan Hua',
			'Tianyi',
			'Dorbod',
			'Ming’antu',
			'Kuancheng',
			'Fusong',
			'Dehui',
			'Dailing',
			'Chaoyang',
			'Changtu',
			'Bayan Huxu',
			'Liangshi',
			'Huohua',
			'Jinjiazhuang',
			'Dêqên',
			'Yengibag',
			'Kuqa',
			'Panjim',
			'Heqiao',
			'Shizhai',
			'Zhujiachang',
			'Ziyang',
			'Zhuxi Chengguanzhen',
			'Yuncheng',
			'Yudong',
			'Yuanba',
			'Yongchuan',
			'Yishan',
			'Yinchuan',
			'Yaoji',
			'Zhangjiagang',
			'Yangjiapo',
			'Yangdian',
			'Yanghe',
			'Yangcun',
			'Huamachi',
			'Yacheng',
			'Funan Chengguanzhen',
			'Xinhe',
			'Xindu',
			'Gulü',
			'Xiazhuang',
			'Wangpu',
			'Tongjing',
			'Dachang Shandao',
			'Shuizhai',
			'Shuitou',
			'Shuanglong',
			'Shoushan',
			'Shanghe',
			'Shanghai',
			'Sanhui',
			'Jieyang',
			'Renqiu',
			'Renhe',
			'Pulandian',
			'Puji',
			'Pingyi',
			'Pingjin',
			'Panzhuang',
			'Panggezhuang',
			'Naliang',
			'Meilin',
			'Mayanhe',
			'Luocheng',
			'Lucheng',
			'Kangding',
			'Longyan',
			'Longxian Chengguanzhen',
			'Longshui',
			'Linli',
			'Ligezhuang',
			'Lidu',
			'Licheng',
			'Junxi',
			'Liulin',
			'Jinggan',
			'Jiangkou',
			'Yong’an',
			'Xingyi',
			'Honglu',
			'Hetang',
			'Gongba',
			'Furong',
			'Fulu',
			'Fengzhou',
			'Fanshi',
			'Ezhou',
			'Dongkou',
			'Dongditou',
			'Dongcun',
			'Dazhou',
			'Dazhe',
			'Dawu',
			'Daotian',
			'Dajia',
			'Daiyue',
			'Cuntan',
			'Ninghua',
			'Badu',
			'Naxi',
			'Zhongzhan',
			'Sancha',
			'Chenshi',
			'Wangyin',
			'Wuzhishan',
			'Huangpu',
			'Lingcheng',
			'Xinzhuang',
			'Zhenlai',
			'Yebaishou',
			'Antu',
			'Jalai Nur',
			'Dawa',
			'Dandong',
			'Baoshan',
			'Ang’angxi',
			'Bowangshan',
			'Xiwai',
			'Ganjiangtou',
			'Linjiang',
			'Jiegan',
			'Wuku',
			'Luotang',
			'Sanheba',
			'Liuxiang',
			'Dongqu',
			'Xiongzhou',
			'Lantian',
			'Chengxiang',
			'Duping',
			'Tianhe',
			'Wushan',
			'Fengmu',
			'Jinling',
			'Xixin',
			'Yong’an',
			'Zhanpu',
			'Wanmu',
			'Wuli',
			'Shanling',
			'Tonglou',
			'Zhaojia',
			'Wulingshan',
			'Longxing',
			'Gaoping',
			'Shenglilu',
			'Erping',
			'Huancuilou',
			'Xuri',
			'Yanji',
			'Siyeke',
			'Fengfengkuang',
			'Jiangnan',
			'Yufeng',
			'Rencheng',
			'Dasheng',
			'Wushan',
			'Wangyuan',
			'Sanchuan',
			'Shilongba',
			'Laiwu Qu',
			'Wangqi',
			'Arele',
			'Guiyang, Guizhou',
			'Lanying',
			'Caoping',
			'Sheshu',
			'Dianzi',
			'Dabao',
			'Chanhe',
			'Shuiyang',
			'Ni’ao',
			'Tange',
			'Mali',
			'Yelin',
			'Xiayang',
			'Konggar',
			'Shangdu',
			'Jinping',
			'Zhushan',
			'Pushun',
			'Pingshan',
			'Zizhao',
			'Xiaoxi',
			'Liangzeng',
			'Guanqing',
			'Zhoubai',
			'Runxi',
			'Qiaozi',
			'Changtan',
			'Nantuo',
			'Yulong',
			'Jiguan',
			'Yangming',
			'Shixi',
			'Hexi',
			'Changjianglu Jiedao',
			'Yulong',
			'Luolun',
			'Tuantian',
			'Zhonghe',
			'Qiongkulecun',
			'Xixiangtang',
			'Xiaying',
			'Xiejiawan',
			'Alamaiti',
			'Arele',
			'Yawa',
			'Xireg',
			'Xêgar',
			'Yingjiang',
			'Gudong',
			'Temeke',
			'Korla',
			'Dunhuang',
			'Youyupu',
			'Ziyang Chengguanzhen',
			'Zhutuo',
			'Zhuangtou',
			'Zaozhuang',
			'Yuxi',
			'Yuping',
			'Wulingyuan',
			'Yucheng',
			'Youchou',
			'Xunyi Chengguanzhen',
			'Xiuying',
			'Xiongzhou',
			'Longquan',
			'Xinshan',
			'Xiazhuang',
			'Xiaoweizhai',
			'Xiaodongzhuang',
			'Xiamen',
			'Tongcheng',
			'Wangwu',
			'Yinzhu',
			'Linjiang',
			'Yuhai',
			'Tongxi',
			'Chengtangcun',
			'Binhe',
			'Taihe Chengguanzhen',
			'Shuikou',
			'Shijiang',
			'Shituo',
			'Shiquan',
			'Lingquan',
			'Shijiao',
			'Shenzhen',
			'Xincheng',
			'Shangli',
			'Shangcheng Chengguanzhen',
			'Pingxiang',
			'Paipu',
			'Niudaokou',
			'Sanlei',
			'Maoming',
			'Magitang',
			'Luxia',
			'Luqiao',
			'Longshi',
			'Longsheng',
			'Longchi',
			'Lishui',
			'Qingnian',
			'Cuifeng',
			'Liangzhai',
			'Kaiping',
			'Jianshan',
			'Huidong',
			'Donglai',
			'Huangmei',
			'Daxing',
			'Jianshe',
			'Haoxue',
			'Hai’an',
			'Gao’an',
			'Fengyi',
			'Dianbu',
			'Dezhou',
			'Datun',
			'Danxi',
			'Dala',
			'Dafengdong',
			'Liucheng',
			'Changsha',
			'Yiyang',
			'Baofeng Chengguanzhen',
			'Bangdong',
			'Baizi',
			'Baitu',
			'Baisha',
			'Aoxi',
			'Anshun',
			'Damiao',
			'Yufengshan',
			'Tongjiaxi',
			'Lanyang',
			'Lixi',
			'Hedong',
			'Yingshouyingzi',
			'Xiuyan',
			'Tumen',
			'Suizhong',
			'Siping',
			'Shunyi',
			'Shuangyashan',
			'Qitai',
			'Qitaihe',
			'Jiamusi',
			'Hoh Ereg',
			'Hailin',
			'Chicheng',
			'Chengnan',
			'Quwa',
			'Haolin',
			'Mu’er',
			'Fangsong',
			'Fuzhou',
			'Chengbei',
			'Fuying',
			'Zhendong',
			'Ganning',
			'Erong',
			'Tonggu',
			'Huangying',
			'Gulu',
			'Shuangfengqiao',
			'Jijia',
			'Xiangyang',
			'Hetu',
			'Kalabulegen',
			'Han’airike',
			'Taxkowrük',
			'Xingbin',
			'Mihe',
			'Tanfang',
			'Hechi',
			'Wuhe',
			'Chenghai',
			'Yintai',
			'Liubao',
			'Huanghua',
			'Xiwule',
			'Segezikule',
			'Aqto',
			'Shilian',
			'Longling County',
			'Zepu',
			'Qagan Us',
			'Motuo',
			'Jiashi',
			'Domartang',
			'Youdian',
			'Saybag',
			'Hekou',
			'Xujiaba',
			'Zhuzhoujiang Miaozuxiang',
			'Zhuyang',
			'Zhoukou',
			'Zhenzhou',
			'Zhenyang',
			'Zhaoguli',
			'Zhaogezhuang',
			'Chaoge',
			'Yuntai',
			'Zhihe',
			'Yongding',
			'Yisa',
			'Yibin',
			'Qihe',
			'Xunyang',
			'Xinmiao',
			'Xingren',
			'Xihe',
			'Xidu',
			'Xiangshui',
			'Kajiaman',
			'Wujia',
			'Wujia',
			'Fengcheng',
			'Wenping',
			'Wanshan',
			'Tuodian',
			'Qincheng',
			'Taoyuan',
			'Tangjiafang',
			'Tancheng',
			'Taihe',
			'Tai’an',
			'Sui Xian Chengguanzhen',
			'Shuozhou',
			'Shiwan',
			'Shikang',
			'Sanmenxia',
			'Xiaodu',
			'Rui’an',
			'Qinting',
			'Qinglang',
			'Qingguang',
			'Qidu',
			'Poyang',
			'Pengcheng',
			'Pan’an',
			'Ningbo',
			'Nanfeng',
			'Mucheng',
			'Jiannan',
			'Chengxiang',
			'Meijiang',
			'Luojiang',
			'Luocheng',
			'Longgang',
			'Licheng',
			'Beiliu',
			'Lanli',
			'Languan',
			'Laisu',
			'Liujiang',
			'Chengjiao',
			'Jiujiang',
			'Jingping',
			'Jiaozuo',
			'Jiangmen',
			'Huotong',
			'Huomachong',
			'Huizhou',
			'Huangzhuang',
			'Huanghua',
			'Hongyang',
			'Hepingyizu',
			'Heishui',
			'Hanyuan',
			'Changzhi',
			'Haishiwan',
			'Guiren',
			'Guanzhuang',
			'Gantang',
			'Gangtou',
			'Fuzhou',
			'Tiantan',
			'Fengyi',
			'Fengshan',
			'Fangcheng Chengguanzhen',
			'Duoba',
			'Liping',
			'Dazhou',
			'Dasi',
			'Dachang',
			'Cuijiaji',
			'Chunxi',
			'Chenying',
			'Chengjiang',
			'Changli',
			'Boyang',
			'Bola',
			'Benchu',
			'Dacheng',
			'Baoxing',
			'Bibo',
			'Xinluoqu',
			'Bojia',
			'Miaoba',
			'Mohe',
			'Sifangtai',
			'Panshi',
			'Mulan',
			'Lubei',
			'Longjing',
			'Harbin',
			'Haicheng',
			'Dongfeng',
			'Datong',
			'Dalain Hob',
			'Beipiao',
			'Nanzhao',
			'Wuhu',
			'Dianga',
			'Jinling',
			'Nalang',
			'Pangjia',
			'Longxi',
			'Kangle',
			'Tai’an',
			'Zhongyi',
			'Chengbei',
			'Zhongping',
			'Daxi',
			'Songnong',
			'Miaoquan',
			'Baitu',
			'Wenfu',
			'Baiyun',
			'Yihe',
			'Shitan',
			'Shayu',
			'Erlang',
			'Mixin',
			'Shaoyun',
			'Shuangfu',
			'Nanchuan',
			'Aihui',
			'Beijinglu',
			'Jiangzhou',
			'Liguo',
			'Xingdaohu',
			'Mingguang',
			'Wanzhou',
			'Liufeng',
			'Songshu',
			'Dayang',
			'Arou',
			"Qia'erbage",
			'Tukan',
			'Dengjia',
			'Shangpa',
			'Jomda',
			'Chongdui',
			'Xinyuan',
			'Zhonghe',
			'Zhongdeng',
			'Zhijin',
			'Zhenyuan Chengguanzhen',
			'Zhangwan',
			'Anyang',
			'Zhancheng',
			'Huaiyang',
			'Yongxing',
			'Yongding',
			'Yishi',
			'Eling',
			'Qingzhou',
			'Yaoshi',
			'Yangpu',
			'Xixiang',
			'Xinmin',
			'Shangmei',
			'Xibing',
			'Xiaodian',
			'Xiaochuan',
			'Wuqiao',
			'Wuling',
			'Taozhuang',
			'Taolin',
			'Tanjia',
			'Tangkou',
			'Suyangshan',
			'Shouxian',
			'Shajiang',
			'Sangzhe',
			'Qushui',
			'Qinhuangdao',
			'Qingquan',
			'Qiaogu',
			'Qianliu',
			'Pingluo Chengguanzhen',
			'Penguanzhuang',
			'Nanyang',
			'Nanmuping',
			'Mengshan',
			'Meizhou',
			'Luoyang',
			'Luohuang',
			'Longtan',
			'Longquan',
			'Lixiqiao',
			'Lincheng',
			'Langmusi',
			'Jinxiu',
			'Jinping',
			'Jinhua',
			'Jingyang',
			'Jingzhou',
			'Jingguan',
			'Jiangkou',
			'Yangjiang',
			'Huangqiao',
			'Hebian',
			'Hanji',
			'Yutai',
			'Guofu',
			'Gulin',
			'Guangming',
			'Gongjiang',
			'Gecheng',
			'Dongping',
			'Donghuang',
			'Dongguan',
			'Debao',
			'Daye',
			'Datianzhuang',
			'Xinxing',
			'Buhe',
			'Botou',
			'Bianzhuang',
			'Biantang',
			'Beijing',
			'Baocheng',
			'Anyuan',
			'Zhonghe',
			'Shiqiao',
			'Banan',
			'Shentong',
			'Yinglin',
			'Baiqi',
			'Zhaozhou',
			'Tieling',
			'Shiguai',
			'Pingquan',
			'Meixi',
			'Linkou',
			'Lanxi',
			'Kuandian',
			'Kailu',
			'Hengshan',
			'Shiqiao',
			'Wufu',
			'Jinghu',
			'Weicheng',
			'Xincheng',
			'Dehang',
			'Dengbu',
			'Dayu',
			'Nanyu',
			'Dianzi',
			'Longba',
			'Xiritala',
			'Binhe',
			'Zhonggang',
			'Sanxi',
			'Baidi',
			'Shuanghekou',
			'Lengshui',
			'Peixing',
			'Ansheng',
			'Xinsheng',
			'Gaotian',
			'Nanyaojie',
			'Huatian',
			'Lianhe',
			'Lianhu',
			'Xing’an',
			'Minzhu',
			'Taobei',
			'Xingning',
			'Tawakule',
			'Puyang',
			'Turpanyüzi',
			'Qinbeiqu',
			'Qinnan',
			'Yinhai',
			'Tuomuwusitang',
			'Bayandai',
			'Buzhake',
			'Jieshi',
			'Shache',
			'Jiajuedibu',
			'Ngamring',
			'Dangchengwan',
			'Toksun',
			'Yili',
			'Dabancheng',
			'Barkol',
			'Liaoyang',
			'Zitong',
			'Zhongwei',
			'Zhongduo',
			'Zhanggu',
			'Zaojiacheng',
			'Yujin',
			'Yuci',
			'Qianjiang',
			'Yima',
			'Yaowan',
			'Xuhui',
			'Xiuqi',
			'Xinhualu',
			'Xinyang',
			'Wuzhong',
			'Wuxue',
			'Weinan',
			'Wanning',
			'Wafangdian',
			'Tiaoshi',
			'Taopingxiang',
			'Suicheng',
			'Shuangxi',
			'Shijiazhuang',
			'Shedian',
			'Shazi',
			'Shaping',
			'Shanghuang',
			'Shahecheng',
			'Shagang',
			'Rucheng Chengguanzhen',
			'Renzhao',
			'Longgang',
			'Baiyang',
			'Fenghuang',
			'Napo Chengxiangzhen',
			'Nancaicun',
			'Mudong',
			'Luyuan',
			'Liancheng',
			'Leling',
			'Lazikou',
			'Laiwu',
			'Kou’an',
			'Kaiyuan',
			'Jinzhou',
			'Jinshi',
			'Tianchang',
			'Jincheng',
			'Jiaocheng',
			'Jiangkou',
			'Huolu',
			'Huanggang',
			'Huaidian',
			'Hetoudian',
			'Heng',
			'Hengyang',
			'Hanfeng',
			'Hanchuan',
			'Sanjiang',
			'Gongmen',
			'Gaotang',
			'Gaoyi',
			'Fenglu',
			'Shuangzhucun',
			'Dali',
			'Chengxian Chengguanzhen',
			'Chatian',
			'Changdian',
			'Biaokou',
			'Beicang',
			'Baiyashi',
			'Shuangshan',
			'Shima',
			'Conglin',
			'Huimin',
			'Dingtoucun',
			'Shuangyang',
			'Raohe',
			'Lindong',
			'Jishu',
			'Jingpeng',
			'Gannan',
			'Gaizhou',
			'Dashiqiao',
			'Changchun',
			'Oroqen Zizhiqi',
			'Yunji',
			'Jianghai',
			'Shunde',
			'Xinfa',
			'Pan’an',
			'Longgang',
			'Xarsingma',
			'Qiangqinxue',
			'Longshan',
			'Hongwansi',
			'Gengma',
			'Toli',
			'Khutubi',
			'Changji',
			'Nianzhuang',
			'Dazhu',
			'Zhongzhai',
			'Kaijiang',
			'Zhigou',
			'Zhangzhai',
			'Yuxi',
			'Yutian',
			'Yuncheng',
			'Youguzhuang',
			'Yongfeng',
			'Chengzhong',
			'Yaodu',
			'Anling',
			'Yuanyang Chengguanzhen',
			'Yancheng Chengguanzhen',
			'Xin’anjiang',
			'Xiling',
			'Xiaomian',
			'Xianghe',
			'Xianfeng',
			'Wutongshu',
			'Wucheng',
			'Wenquan',
			'Tushan',
			'Tongqian',
			'Tiandiba',
			'Tangshan',
			'Tangjiazhuang',
			'Simao',
			'Shuangshi',
			'Xianglong',
			'Shifang',
			'Gaolan Chengguanzhen',
			'Shenjiamen',
			'Shangde',
			'Shahe',
			'Sanyuan',
			'Qingshizui',
			'Qingshanquan',
			'Puzi',
			'Puqi',
			'Pingzhai',
			'Neijiang',
			'Luoshan Chengguanzhen',
			'Luojiu',
			'Longtan',
			'Liujiaping',
			'Lingcheng',
			'Lingcheng',
			'Linfen',
			'Liangshui',
			'Kunyang',
			'Juexi',
			'Jinhe',
			'Jiaogong',
			'Jiangkou',
			'Yancheng',
			'Huafeng',
			'Gufeng',
			'Guangrao',
			'Gaomi',
			'Gantang',
			'Fuzhuang',
			'Fengdie',
			'Enle',
			'Daming',
			'Daishan',
			'Dadeng',
			'Dabizhuang',
			'Cuihua',
			'Yiwu',
			'Bangjun',
			'Baimajing',
			'Bailin',
			'Mabai',
			'Zhagulu',
			'Pingjiang',
			'Jutou',
			'Tumen',
			'Yanhe',
			'Dianjun',
			'Sandu',
			'Heqing',
			'Zhaojia',
			'Ouyang',
			'Longquan',
			'Xinlin',
			'Xinhui',
			'Wumahe',
			'Shangzhi',
			'Salaqi',
			'Qiqihar',
			'Manzhouli',
			'Kangping',
			'Jiaohe',
			'Hobor',
			'Chengzihe',
			'Bayan',
			'Baotou',
			'Amgalang',
			'Haikuotiankong',
			'Haoti',
			'Moba',
			'Shaba',
			'Wangmo',
			'Yushu',
			'Qianyang Chengguanzhen',
			'Lichuan Zhen',
			'Zhu Cheng City',
			'Bieligutai',
			'Xoixar',
			'Pingjianglu',
			'Houping',
			'Shuangyang',
			'Longjing',
			'Tonggu',
			'Guojia',
			'Chenjiaba',
			'Fuping',
			'Yongdong',
			'Ganxi',
			'Jindong',
			'Dayingmen',
			'Xiaobailou',
			'Tongle',
			'Nandajie',
			'Huichang',
			'Yanqiao',
			'Nanchangshan',
			'Xuzhou',
			'Chuanwang',
			'Gulebage',
			'Chongzuo',
			'Cangwu',
			'Lingwu',
			'Changdao',
			'Longdong',
			"Bage'awati",
			"Tage'erqi",
			'Yanghe',
			'Tanghe',
			'Shiliu',
			'Shihao',
			'Chaoyang',
			'Koutouba',
			'Tianchi',
			'Shili',
			'Xinghua',
			'Liushun',
			'Yuanyang',
			'Beidajie',
			'Chaoyang',
			'Dongcheng',
			'Xinle',
			'Tiemen',
			'Huilong',
			'Zhongling',
			'Longtang',
			'Xintian',
			'Dawangzhuang',
			'Heshun',
			'Fenglai',
			'Baisheng',
			'Yunji',
			'Tianfu',
			'Dashi',
			'Longfeng',
			'Jiao',
			'Xingshan',
			'Zhongqiao',
			'Delong',
			'Xuri',
			'Guitang',
			'Mugala',
			'Dongping',
			'Baluntaicun',
			'Sadikyüzi',
			'Onyar',
			'Lianyungang',
			'Xindian',
			'Daxu',
			'Jitai',
			'Minning',
			'Shatian',
			'Fengping',
			'Puchuan',
			'Awu',
			'Fengwang',
			"Duo'er",
			'Bingtuan Sanshiliu Tuan',
			'Kalasu',
			'Yingwusitang',
			'Yishikuli',
			'Zhongshan',
			'Zouma',
			'Jinzhu',
			'Tungdor',
			'Shengping',
			'Gyamotang',
			'Gartog',
			'Langgar',
			'Aqqan',
			'Hom',
			'Fukang',
			'Sishilichengzi',
			'Wenchang',
			'Jiahe Chengguanzhen',
			'Zhongshu',
			'Zhongshan',
			'Zagunao',
			'Yunxian Chengguanzhen',
			'Yunjin',
			'Yonghe',
			'Yichuan Chengguanzhen',
			'Yaoshi',
			'Yanmen',
			'Yanjia',
			'Yangliuqing',
			'Xinhuang',
			'Xinyi',
			'Xichang',
			'Xiaoshajiang',
			'Xiaogan',
			'Xiaodian',
			'Wugui',
			'Mingzhou',
			'Wanshun',
			'Ulan',
			'Tianzhuang',
			'Tancheng',
			'Mingxing',
			'Taicang',
			'Suonan',
			'Sitou',
			'Shuangjiang',
			'Shixia',
			'Shijing',
			'Shidui',
			'Sanchakou',
			'Fuqing',
			'Renyi',
			'Qiaojiang',
			'Pucheng Chengguanzhen',
			'Pu’an',
			'Potou',
			'Guhuai',
			'Pingle',
			'Dadukou',
			'Neihuang Chengguanzhen',
			'Nandu',
			'Minqing',
			'Mazhang',
			'Xiguan',
			'Luoyang',
			'Lucheng',
			'Lubu',
			'Longcheng',
			'Liyang',
			'Linxi',
			'Linqu',
			'Wuling',
			'Liji',
			'Xincheng',
			'Lecheng',
			'Kuangyuan',
			'Jiushan',
			'Lin’an',
			'Jianguang',
			'Huozhuangzi',
			'Huguo',
			'Sanhe',
			'Guiyang',
			'Gaoping',
			'Ganzhou',
			'Fuji',
			'Dongshan',
			'Luocheng',
			'Dingtao',
			'Dawangtai',
			'Datong',
			'Daojiang',
			'Dangcheng',
			'Dahua',
			'Cizhu',
			'Zhiqing Songduo',
			'Chitu',
			'Chengzhong',
			'Changle',
			'Chadian',
			'Bobai',
			'Bayiji',
			'Shangyu',
			'Anju',
			'Aba',
			'Baiyang',
			'Jiangkou',
			'Mahe',
			'Dongcheng',
			'Jingfeng',
			'Meipucun',
			'Miaoxi',
			'Tuquan',
			'Meilisi',
			'Jiagedaqi',
			'Dashitou',
			'Fengning',
			'Baishishan',
			'Xigaoshan',
			'Chenyuan',
			'Liuxiang',
			'Luhe',
			'Xiyu',
			'Shangri-La',
			'Julun',
			'Songlong',
			'Liaozi',
			'Xujia',
			'Fenghuang',
			'Tangfang',
			'Yongle',
			'Yihe',
			'Lubei',
			'Linxi',
			'Lichang',
			'Dashi',
			'Langping',
			'Maoba',
			'Fuxing',
			'Diaoyucheng',
			'Longtanzi',
			'Xiangyang',
			'Maliuzui',
			'Guangrao',
			'Jianshui',
			'Yidu',
			'Quzhang',
			'Nuofu',
			'Beihai',
			'Peishi',
			"Ping'an",
			'Kawake',
			'Simianshan',
			'Songbei',
			'Tiexi',
			'Ningjiang',
			'Fuhuan',
			'Zhuantang',
			'Tianyuan',
			'Xiamengxiang',
			'Qira',
			'Kokyar',
			'Turpan',
			'Luntai',
			'Dure',
			'Awat',
			'Zouzhuang',
			'Zhuoshui',
			'Zhongzhou',
			'Gusong',
			'Yizhang Chengguanzhen',
			'Yichun',
			'Yanjin Chengguanzhen',
			'Yangsha',
			'Yangling',
			'Mingxi',
			'Zijin',
			'Zhongcheng',
			'Xinshi',
			'Gongyi',
			'Xiangzhou',
			'Wuyang',
			'Wangguan',
			'Wangbuzhuang',
			'Tuojiang',
			'Taozhou',
			'Taiyuan',
			'Taikang Chengguanzhen',
			'Suining',
			'Sishui',
			'Shucheng',
			'Chaoyang',
			'Shiwan',
			'Shaowu',
			'Sanjiao',
			'Rulong',
			'Qinhe',
			'Qingyuan',
			'Qingxi',
			'Puhechang',
			'Nianbo',
			'Nanma',
			'Duji',
			'Gaoyao',
			'Muxi',
			'Wuchuan',
			'Meicheng',
			'Malu',
			'Zhijiang',
			'Longfeng',
			'Longchuan',
			'Longcheng',
			'Guankou',
			'Liuxin',
			'Linquan Chengguanzhen',
			'Linjiang',
			'Xishan',
			'Langfang',
			'Juancheng',
			'Jiudian',
			'Xiangshui',
			'Jinan',
			'Huangyuan Chengguanzhen',
			'Hong’an Chengguanzhen',
			'Hanshou',
			'Guli',
			'Guigang',
			'Guancheng',
			'Gaoyou',
			'Gaogou',
			'Yong’an',
			'Fengrun',
			'Fanyang',
			'Dunhou',
			'Duchang',
			'Dongzhaogezhuang',
			'Dongling',
			'Dingzhou',
			'Dezhou',
			'Deqing',
			'Dayanggezhuang',
			'Dalian',
			'Chongqing',
			'Changpu',
			'Chaicun',
			'Cenxi',
			'Bozhou',
			'Bikou',
			'Beilun',
			'Beidaihehaibin',
			'Bazhai',
			'Qingyang',
			'Anqiu',
			'Anmenkou',
			'Anliu',
			'Anjiang',
			'Shimen',
			'Xinsheng',
			'Gaowu',
			'Anfu',
			'Fengsheng',
			'Dengyuantai',
			'Saihan Tal',
			'Longjiang',
			'Liaoyuan',
			'Jinshan',
			'Heishan',
			'Hailun',
			'Erdaojiang',
			'Daqin Tal',
			'Zhongcheng',
			'Luoda',
			'Baima',
			'Yumen',
			'Wushi',
			'Shanshan',
			'Oymak',
			'Baytokay',
			'Guandou',
			'Shangqiu',
			'Jinzhong',
			'Zhonghe',
			'Zhaxi',
			'Qianyou',
			'Zhaoren',
			'Zhangye',
			'Xiushui',
			'Yunxi',
			'Yunshan',
			'Yueyang',
			'Yuexi',
			'Kunyang',
			'Yeliguan',
			'Yatunpu',
			'Yanzibu',
			'Yantan',
			'Yangzhong',
			'Yacha',
			'Xuanzhou',
			'Xinyu',
			'Xinxiang',
			'Xinglong',
			'Ankang',
			'Xiema',
			'Xiedian',
			'Xiaolingwei',
			'Xianrenwan',
			'Wuqi',
			'Wuhu',
			'Wuhan',
			'Wenlin',
			'Wenfeng',
			'Wantouqiao',
			'Wanshui',
			'Wanmao',
			'Tuzhai',
			'Loushanguan',
			'Tongquan',
			'Tonggu',
			'Tengzhou',
			'Shanghe',
			'Tai’an',
			'Simenqian',
			'Xincheng',
			'Shanmen',
			'Shanmen',
			'Sangyuan',
			'Rizhuang',
			'Qujiang',
			'Qingjiangqiao',
			"Huai'an",
			'Qiaoxu',
			'Qiaowa',
			'Qiaotou',
			'Puting',
			'Pingjiang',
			'Shunling',
			'Guantao',
			'Nanchang',
			'Minggang',
			'Haimen',
			'Ma’ai',
			'Luxi',
			'Lushan',
			'Lujiao',
			'Wenquan',
			'Luancheng',
			'Liyuan',
			'Liyang',
			'Liuduzhai',
			'Lishi',
			'Liuzhou',
			'Linwu Chengguanzhen',
			'Lingxi',
			'Lingjiang',
			'Linghe',
			'Lingcheng',
			'Liaolan',
			'Lengshuiguan',
			'Leiyang',
			'Lantian',
			'Kaihua',
			'Jishui',
			'Guangyuan',
			'Huangjia',
			'Hengshui',
			'Helixi',
			'Haitou',
			'Jiaojiang',
			'Gushi Chengguanzhen',
			'Gupi',
			'Gedong',
			'Gaoniang',
			'Fengkou',
			'Dongguang',
			'Dongchongcun',
			'Daxie',
			'Danshui',
			'Xincheng',
			'Cuihuangkou',
			'Chikan',
			'Chengwu',
			'Chenghua',
			'Bijie',
			'Bashan',
			'Muping',
			'Balu',
			'Bailu',
			'Baihua',
			'Anlong',
			'Zhoushan',
			'Jinshan',
			'Xingfeng',
			'Dasheng',
			'Baiyang',
			'Kerencun',
			'Rongxi',
			'Yantongshan',
			'Wuchang',
			'Ulanhot',
			'Shanhaiguan',
			'Mujiayingzi',
			'Longfeng',
			'Hegang',
			'Chaoyang',
			'Muling',
			'E’zhou',
			'Jiantang',
			'Xia’er',
			'Guangzhoulu',
			'Fan Xian Chengguanzhen',
			'Changyuan County',
			'Babu',
			'Chengzhong',
			'Miaozi',
			'Baitugang',
			'Fazhanhe',
			'Pingding',
			'Zangbawa',
			'Lianhua',
			'Yuhe',
			'Foya',
			'Longxing',
			'Songping',
			'Nawu',
			'Ningnan',
			'Gongchen',
			'Gaonan',
			'Fengping',
			'Heqian',
			'Huanghe',
			'Sanxing',
			'Mingda',
			'Taiji',
			'Wanzu',
			'Qingyuan',
			"Alatan'molezhen",
			'Rizhao',
			'Fangchenggang',
			'Miaojie',
			'Caochuanpu',
			'Xincheng',
			'Qingyang',
			'Arewusitang',
			'Kuiya',
			'Xihu',
			'Mianzhu, Deyang, Sichuan',
			'Cizhu',
			'Tashqorghan',
			'Rutog',
			'Pagnag',
			'Jinhua',
			'Dongmazar',
			'Lintan Chengguanzhen',
			'Ziketan',
			'Zhushan Chengguanzhen',
			'Zhuanghe',
			'Zhenhaicun',
			'Zhaoqing',
			'Zhangji',
			'Yunmen',
			'Yuanshang',
			'Yongqing',
			'Yongdeng Chengguanzhen',
			'Yong’an',
			'Yingtan',
			'Yingdu',
			'Yanjing',
			'Yangezhuang',
			'Yan’an',
			'Xucheng',
			'Xinli',
			'Xinglong',
			'Feicheng',
			'Xiazhen',
			'Xianning',
			'Xiagezhuang',
			'Wuxi',
			'Huaibin Chengguanzhen',
			'Wudong',
			'Wenling',
			'Weiyuan',
			'Weiyuan',
			'Wuxue Shi',
			'Tiancheng',
			'Taizhou',
			'Jinxi',
			'Suqian',
			'Songkan',
			'Shangsi',
			'Shuidatian',
			'Shuangzhong',
			'Shijia',
			'Shijia',
			'Yunlong',
			'Shanhe',
			'Rongwo',
			'Rongcheng',
			'Lechang',
			'Qina',
			'Pingshan',
			'Hanyuan',
			'Ningchegu',
			'Nanjie',
			'Mingshui',
			'Meishan',
			'Lufu',
			'Longjin',
			'Longgang',
			'Liuyin',
			'Linshi',
			'Linqiong',
			'Lincang',
			'Liangcunchang',
			'Leizhou',
			'Kunyang',
			'Kongtan',
			'Kocê',
			'Kaili',
			'Juegang',
			'Jinjing',
			'Jingyang',
			'Jiangyan',
			'Jiangfang',
			'Huolu',
			'Huojia Chengguanzhen',
			'Hangu',
			'Haimen',
			'Guoyang Chengguanzhen',
			'Guantou',
			'Guangshui',
			'Gaozeng',
			'Gantang',
			'Fangxian Chengguanzhen',
			'Dawu Chengguanzhen',
			'Dongsheng',
			'Dongjituo',
			'Jinchang',
			'Dazhongzhuang',
			'Dayou',
			'Dashun',
			'Daliang',
			'Dajin',
			'Dahuangshan',
			'Shuren',
			'Chicheng',
			'Chefushan',
			'Changzhou',
			'Changleng',
			'Rong’an',
			'Cairima',
			'Banqiao',
			'Baiyin',
			'Bahuang',
			'Fulu',
			'Sanjiao',
			'Wutan',
			'Baijiawan',
			'Beizhai',
			'Wudan',
			'Tongliao',
			'Tonghua',
			'Tahe',
			'Qian’an',
			'Kangbao',
			'Jixi',
			'Jixian',
			'Chengde',
			'Boli',
			'Bin Xian',
			'Keda',
			'Hanyuan',
			'Wangba',
			'Baihe',
			'Hanlin',
			'Sitai',
			'Douping',
			'Daogao',
			'Hezhou',
			'Jishi',
			'Qianfeng',
			'Wenhe',
			'Mingzhong',
			'Pinghe',
			'Futian',
			'Gaoqiao',
			'Zhugang',
			'Xiaozhou',
			'Wanchao',
			'Baohe',
			'Wuping',
			'Baishi',
			'Longshe',
			'Laitan',
			'Xihe',
			'Jimai',
			'Wenshan City',
			'Longsha',
			'Beijinglu',
			'Louqiao',
			'Xingpinglu',
			'Xichuan',
			'Huocheng',
			'Gangkou',
			'Dashahe',
			'Fubang',
			'Huimin',
			'Jiujing',
			'Menghuan',
			'Nongzhang',
			'Mangbang',
			'Mazhan',
			'Yousuo',
			'Muhe',
			"Weiwu'eryuqiwen",
			'Kuoyiqi',
			'Haiyang',
			'Tianjia',
			'Jiahe',
			'Chagang',
			'Shaowa',
			'Suochi',
			'Jiangxi',
			'Zuogaiduoma',
			'Saga',
			'Dongcheng',
			'Shengli',
			'Sunjia',
			'Gaoliang',
			'Changping',
			'Wangchang',
			'Longkong',
			'Dudu',
			'Xiaohe',
			'Xiaonanhai',
			'Haitang',
			'Dawan',
			'Shuanghuai',
			'Nanjin',
			'Tongqiao',
			'Qinglong',
			'Qinggang',
			'Oula Xiuma',
			'Zhonghualu',
			'Jiayin',
			'Guanba',
			'Hongliuwan',
			'Nierong',
			'Anning Xilu',
			'Zhangyelu',
			'Sangpi',
			'Liuzhi',
			'Yanping',
			'Yinqiao Zhen',
			'Tieshangang',
			'Gangnan',
			"Jing'an",
			'Jinzhongzi',
			'Yingli',
			'Diecai',
			'Baisha',
			'Huangmen',
			'Jinji',
			'Dunbage',
			'Shuangluan',
			'Zaindainxoi',
			'Shiquanhe',
			'Nangxian',
			'Mengmeng',
			'Mengmao',
			'Menglang',
			'Langru',
			'Jinta',
			'Dedang',
			'Atush',
			'Zhaosu',
			'Qinghe',
			'Karamay',
			'Aykol',
			'Alakak',
			'Jiangzhuang',
			'Zhuoyang',
			'Zhenjiang',
			'Zhaotong',
			'Zhanhe',
			'Yisuhe',
			'Yantai',
			'Xuyong',
			'Xinzheng',
			'Xiasi',
			'Xiaohenglong',
			'Changning',
			'Wuyucun',
			'Weifang',
			'Leiyang',
			'Tianjin',
			'Taohong',
			'Suzhou',
			'Suicheng',
			'Sui’an',
			'Shiqiao',
			'Shaoxing',
			'Shancheng',
			'Sanhe',
			'Qiuji',
			'Qimeng',
			'Qilidian',
			'Pingdu',
			'Penglai',
			'Panghai',
			'Nanzhao Chengguanzhen',
			'Nanshu',
			'Nansha',
			'Chengjiao Chengguanzhen',
			'Min’an',
			'Loudi',
			'Longtan',
			'Longlin',
			'Dongta',
			'Xiangfeng',
			'Lianzhu',
			'Jinshan',
			'Jinchuan',
			'Jincheng',
			'Jincheng',
			'Jiamaying',
			'Humen',
			'Huantuo',
			'Huangpi',
			'Huangji',
			'Dingcheng',
			'Hekou',
			'Hangzhou',
			'Guangze',
			'Guye',
			'Guxian',
			'Guidong Chengguanzhen',
			'Gucheng Chengguanzhen',
			'Ganzi',
			'Gangu Chengguanzhen',
			'Fushi',
			'Fengtai Chengguanzhen',
			'Fengqiu Chengguanzhen',
			'Dushi',
			'Ducheng',
			'Dongkan',
			'Dingcheng',
			'Dazuocun',
			'Daxing',
			'Dashentang',
			'Daguan',
			'Shuanglong',
			'Baihecun',
			'Anping',
			'Anlu',
			'Anbu',
			'Hebao',
			'Anxi',
			'Wangyao',
			'Xinxing',
			'Dianbu',
			'Ciyun',
			'Shitan',
			'Honghu',
			'Xiaping',
			'Huangtian',
			'Yanta',
			'Yunxing',
			'Xinglong',
			'Wangqing',
			'Wangkui',
			'Sujiatun',
			'Sanchazi',
			'Mudanjiang',
			'Lishu',
			'Lindian',
			'Langxiang',
			'Huinan',
			'Datong',
			'Anshan',
			'Shigang',
			'Gufu',
			'Shirong',
			'Chela',
			'Tanping',
			'Liping',
			'Shijiba',
			'Longfeng',
			'Sangba',
			'Shimen',
			'Zuogaimanma',
			'Zhifang',
			'Liulin',
			'Yan’anxiang',
			'Wulaocun',
			'Zhouxi',
			'Longqiao',
			'Zhuxi',
			'Lishu',
			'Yuchi',
			'Xialu',
			'Gangjia',
			'Bishan',
			'Jiangchi',
			'Houxi',
			'Zhufo',
			'Longxi',
			'Dongyang',
			'Zhifeng',
			'Longji',
			'Gaolou',
			'Xiangyang',
			'Fudong',
			'Qinnanqu',
			'Ankang',
			'Qishan',
			'Danbao',
			'Hongbao',
			'Yanghe',
			'Puqiakeqi',
			'Lixianghu',
			'Shimen',
			'Sajia',
			'Oytograk',
			'Niya',
			'Mengdong',
			'Baoshan',
			'Layka',
			'Qapqal',
			'Tokkuztara',
			'Bohu',
			'Baijiantan',
			'Kostik',
			'Fanlou',
			'Anguo',
			'Sanchahe',
			'Zhongshu',
			'Zhan’erxiang',
			'Junyang',
			'Aihua',
			'Yulei',
			'Heyuan',
			'Youxian Chengguanzhen',
			'Yongning',
			'Yigou',
			'Yixing',
			'Yashan',
			'Heyou',
			'Yangliujie',
			'Xishan',
			'Xinzhou',
			'Xinzhai',
			'Gutian',
			'Xihuachi',
			'Xigang',
			'Xiawuqi',
			'Xiaojiang',
			'Xiaogang',
			'Xiangkou',
			'Xiacang',
			'Wuzhou',
			'Wenxian Chengguanzhen',
			'Wenquan',
			'Wanxian',
			'Tingjiang',
			'Daxin',
			'Taiping',
			'Taiping',
			'Suixi',
			'Longbo',
			'Shuangjiang',
			'Shiqiaozi',
			'Shiqian',
			'Yanta',
			'Shawan',
			'Shashi',
			'Shangtang',
			'Rongjiawan',
			'Renfengzhuang',
			'Qishan',
			'Qionghu',
			'Qinzhou',
			'Qinghua',
			'Qishan',
			'Nancun',
			'Qingping',
			'Mingtong',
			'Min’ancun',
			'Mawu',
			'Longgu',
			'Lintong',
			'Linping',
			'Linhai',
			'Wuwei',
			'Liangxiang',
			'Lengshuitan',
			'Kaijiang',
			'Jinsha',
			'Jingdezhen',
			'Jiaxian',
			'Jiading',
			'Huangzhai',
			'Hong’an',
			'Hefei',
			'Handan',
			'Guandu',
			'Gaojia',
			'Fujia',
			'Wofo',
			'Anxi',
			'Fangjiazhuang',
			'Erwangzhuang',
			'Xinyi',
			'Datangzhuang',
			'Dajianchang',
			'Ziyuan',
			'Yangchun',
			'Chixi',
			'Jiangyin',
			'Ceyu',
			'Beiwenquan',
			'Baisha',
			'Du’an',
			'Anhua',
			'Ahu',
			'Wuduan',
			'Caijiagang',
			'Huaxing',
			'Daxing',
			'Guangpu',
			'Qingfeng',
			'Kuanchuan',
			'Tatou',
			'Neikeng',
			'Shanxia',
			'Santangpu',
			'Weiwangzhuang',
			'Zhangbei',
			'Youyi',
			'Youhao',
			'Xuanhua',
			'Wudalianchi',
			'Shuangcheng',
			'Jinshan',
			'Dongning',
			'Chaihe',
			'Fula’erji',
			'Xinan',
			'Beilin',
			'Qaghiliq',
			'Shule',
			'Mangshi',
			'Luring',
			'Jietou',
			'Elixku',
			'Burang',
			'Arix',
			'Aral',
			'Turgun',
			'Yuqunweng',
			'Zizhuang',
			'Zhushi',
			'Ningxiang',
			'Xinghua',
			'Yujia',
			'Yining',
			'Yeyuan',
			'Yaozhou',
			'Yangzhou',
			'Yangxin',
			'Shangrao',
			'Xinzhou',
			'Weifen',
			'Xingtai',
			'Xindian',
			'Haining',
			'Xiaoxi',
			'Xiangyang',
			'Xiajin',
			'Wuyuan',
			'Taicheng',
			'Weixian',
			'Wangu',
			'Tuojiang',
			'Tongwan',
			'Tiantai Chengguanzhen',
			'Tianchang',
			'Taoluo',
			'Tangzhai',
			'Simen',
			'Shuanghekou',
			'Shouchun',
			'Shawan',
			'Shaping',
			'Shanting',
			'Qujing',
			'Qi Xian Chengguanzhen',
			'Wangge’ertang',
			'Pingdu',
			'Ningjin',
			'Ninghai',
			'Oula',
			'Nanning',
			'Nanjian',
			'Maoping',
			'Luanzhou',
			'Chang’an',
			'Linjiang',
			'Lijiang',
			'Lanxi',
			'Jiangshan',
			'Huayin',
			'Huashan',
			'Huainan',
			'Hongsipu',
			'Gongguan',
			'Gahai',
			'Fengcheng',
			'Donghua',
			'Lijiang',
			'Cili',
			'Chiba',
			'Changxin',
			'Changle',
			'Changchi',
			'Chabaihu',
			'Caiyuan',
			'Biyong',
			'Binzhou',
			'Beidao',
			'Baiyun',
			'Baituo',
			'Langxia',
			'Wujian',
			'Kaitang',
			'Dehua',
			'Dazhuang',
			'Jinshan',
			'Luoba',
			'Yuanmen',
			'Fengxiang',
			'Dalianwan',
			'Wuyishan',
			'Liulinzhou',
			'Yueyang',
			'Xin Bulag',
			'Xilin',
			'Uliastai',
			'Qinggang',
			'Nehe',
			'Nanpiao',
			'Luanping',
			'Hongshan',
			'Fuxin',
			'Xiqu',
			'Quchi',
			'Xikou',
			'Fenghuang',
			'Jiangpan',
			'Changchuan',
			'Zhongshan Donglu',
			'Madang',
			'Nanzheng Chengguanzhen',
			'Aletengxire',
			'Xinghua',
			'Shouxihu',
			'Pulian',
			'Houba',
			'Tiancheng',
			'Gaofeng',
			'Sanyi',
			'Jilong',
			'Shuitian',
			'Lishui',
			'Lutang',
			'Hutang',
			'Changhongjie',
			'Jiangnan',
			'Yanwo',
			'Tangxiang',
			'Awancang',
			'Tiefeng',
			'Liupanshui',
			'Xingfulu',
			'Huchuan',
			'Zhakou',
			'Haidong Zhen',
			'Haicheng',
			'Liushan',
			'Longchi',
			'Quankou',
			'Huangdi',
			'Tianxingsi',
			'Tongchuan',
			'Baleng',
			'Maying',
			'Azitan',
			'Shibao',
			'Suyuan',
			'Taishihe',
			'Qu’ao',
			'Mainling',
			'Naidong',
			'Zhengdonglu',
			'Chong’ansi',
			'Shishan',
			'Shuangta',
			'Heyu',
			'Liangping',
			'Heyan',
			'Fengle',
			'Longsheng',
			'Qinglong',
			'Sanhe',
			'Pingkai',
			'Mala',
			'Xinhua',
			'Canggou',
			'Changshouhu',
			'Qingliu',
			'Shiqiang',
			'Yanglong',
			'Hekou',
			'Laba',
			'Jiachuan',
			'Qiaotou',
			'Jicang',
			'Xiannüshan',
			'Dapeng',
			'Jiawang Zhen',
			'Lianwu',
			'Malu',
			'Axia',
			'Shuangcha',
			'Kezilexilike',
			'Dongwenquan',
			'Changling',
			'Zhefang',
			'Quxar',
			'Qushi',
			'Qarek',
			'Qahurt',
			'Dulayti',
			'Zhuzhai',
			'Yuxi',
			'Zhengding',
			'Yongtai',
			'Yuyao',
			'Yuanmou',
			'Yongxin',
			'Yezhou',
			'Yanling',
			'Yangtun',
			'Longquan',
			'Xiulin',
			'Xinling',
			'Xiannü',
			'Xiangzhou',
			'Xiahu',
			'Wushan',
			'Tianfu',
			'Tangping',
			'Taihe',
			'Suozhen',
			'Sihu',
			'Shuangjiang',
			'Shiyan',
			'Longquan',
			'Shayang',
			'Sanjiang',
			'Quzhou',
			'Pitong',
			'Pingluo',
			'Pingliang',
			'Nanrenfu',
			'Luyang',
			'Luwan',
			'Luhua',
			'Longxu',
			'Longshan',
			'Longlisuo',
			'Liutuan',
			'Puning',
			'Liuba',
			'Licha',
			'Liaogao',
			'Yangjiaping',
			'Jining',
			'Jingzhi',
			'Jinchuan',
			'Jijiang',
			'Jiaxiang',
			'Jiangshi',
			'Jiang’an Qu',
			'Huzhou',
			'Dasha',
			'Hexiangqiao',
			'Hengshui',
			'Hecun',
			'Hanting',
			'Guzhen Chengguanzhen',
			'Gongping',
			'Gelan',
			'Dongshan Dongzuxiang',
			'Dongping',
			'Tanchang Chengguanzhen',
			'Danfeng',
			'Chizhou',
			'Chengdu',
			'Cangzhou',
			'Boshan',
			'Beisu',
			'Beibei',
			'Bachuan',
			'Xipo',
			'Lüfeng',
			'Shouqiao',
			'Deshun',
			'Wujiahe',
			'Zhize',
			'Nanpeng',
			'Goutou',
			'Tonghe',
			'Tangyuan',
			'Pingdingbu',
			'Langtoucun',
			'Dunhua',
			'Changling',
			'Leicheng',
			'Liangshan',
			'Yuhuang',
			'Wangba',
			'Huangzhu',
			'Jialing',
			'Xianlong',
			'Longtai',
			'Gaolou',
			'Galutu',
			'Tianjiao',
			'Hanshang',
			'Lanjiang',
			'Tucheng',
			'Yanwan',
			'Zhen’an',
			'Dade',
			'Wutong',
			'Nanya',
			'Gucheng',
			'Daguan',
			'Panlong',
			'Xingyi',
			'Lanqiao',
			'Cikai',
			'Yuli',
			'Tacheng',
			'Koktokay',
			'Topterek',
			'Chenlou',
			'Gangshang',
			'Liuji',
			'Qinglong',
			'Zhuqi',
			'Zhouxi',
			'Zhangguizhuang',
			'Yunxi',
			'Yulin',
			'Yuelai',
			'Yishui',
			'Yaohua',
			'Yangzhou',
			'Yanguan',
			'Xinxing',
			'Xiditou',
			'Xiaolongmen',
			'Xianshuigu',
			'Wufeng',
			'Wenquan',
			'Pingxiang',
			'Tongcheng',
			'Tiefu',
			'Jianning',
			'Lingyun',
			'Shitanjing',
			'Shiqi',
			'Shijiao',
			'Shidi',
			'Shibuzi',
			'Quyang',
			'Chengguan',
			'Puyang Chengguanzhen',
			'Ningjin',
			'Nanjing',
			'Miyang',
			'Pingguo',
			'Mapo',
			'Luoqiaocun',
			'Luoping',
			'Lüeyang Chengguanzhen',
			'Kaiyuan',
			'Lingcheng',
			'Lianzhou',
			'Langao Chengguanzhen',
			'Kaifeng',
			'Jinjiang',
			'Jiekou',
			'Huilong',
			'Shuanghejiedao',
			'Houzhen',
			'Liangshan',
			'Hongkou',
			'Hongchuan',
			'Heze',
			'Haizhou',
			'Haiyang',
			'Guixi',
			'Guishan',
			'Huquan',
			'Fangting',
			'Dongchuan',
			'Dongchuan',
			'Dingxi',
			'Dingcheng',
			'Ningchang',
			'Dahuangpu',
			'Dazhasi',
			'Chetian',
			'Bishan',
			'Beiwangli',
			'Xiaolin',
			'Xianlong',
			'Xiasi',
			'Wubao',
			'Daxi',
			'Miaoya',
			'Yanshou',
			'Xiahuayuan',
			'Fuyu',
			'Qasq',
			'Kaitong',
			'Honggang',
			'Gongzhuling',
			'Anda',
			'Ulashan',
			'Zhongshan',
			'Pianbai',
			'Apengjiang',
			'Meiziya',
			'Guoyuan Xincun',
			'Bake',
			'Longfeng',
			'Qunli',
			'Zhongshanlu',
			'Qingyuan',
			'Sanquan',
			'Kalaxilike',
			'Datong',
			'Renminlu',
			'Gangba',
			'Yangzi Jianglu',
			'Yuquanying',
			'Haimen',
			'Pinggui',
			'Huiqu',
			'Lijun',
			'Xiangshan',
			'Zhutang',
			'Yongjian',
			'Mixia',
			"Ying'airike",
			"Yisilamu'awati",
			'Qitang',
			'Yuelai',
			'Xinglong',
			'Boyu',
			'Niba',
			'Yuezhao',
			'Nianba',
			'Huangping',
			'Bajiao',
			'Hadapu Zhen',
			'Samdo',
			'Xingong',
			'Xiangcheng',
			'Tongcheng',
			'Lingjiao',
			'Yunwu',
			'Qinglong',
			'Manyue',
			'Dibao',
			'Gaofeng',
			'Baijia',
			'Jukui',
			'Hucheng',
			'Cenxi',
			'Langxi',
			'Yandong',
			'Xiyuzhuang',
			'Jielong',
			'Luoyun',
			'Qingjiang',
			'Zhiping',
			'Sa’ertu',
			'Beilin',
			'Anwen',
			'Yinla',
			'Jianshelu',
			'Ganhechen',
			'Huoche Xizhan',
			'Chengguan',
			'Linhe',
			'Xiping',
			'Baoji',
			'Yulin',
			'Yishan',
			'Buzhuang',
			'Shaozhuang',
			'Siwei',
			'Nanling',
			'Taiping',
			'Dongchang',
			'Ganfeng',
			'Zongga',
			'Zhowagoin',
			'Ulughchat',
			'Segong',
			'Qaraqash',
			'Bonan',
			'Jiayuguan',
			'Kuytun',
			'Dushanzi',
			'Beigang',
			'Zhongxing',
			'Zhangjiachuan',
			'Yingchuan',
			'Yunnanyi',
			'Yongzhou',
			'Yongxing',
			'Yongcong',
			'Yi Xian',
			'Yingxian',
			'Yansi',
			'Yangri',
			'Xinzhan',
			'Xincheng',
			'Xinhua',
			'Xingguo',
			'Sanshui',
			'Nanjie',
			'Xiangcheng',
			'Menghai',
			'Xiangcheng Chengguanzhen',
			'Wayao',
			'Tuokou',
			'Leye',
			'Tongchuan',
			'Tianpeng',
			'Temuli',
			'Taoyang',
			'Taohuajiang',
			'Songbai',
			'Sigaozhuang',
			'Shuangxi',
			'Shuanggou',
			'Shizilu',
			'Qili',
			'Shima',
			'Shetan',
			'Shanyang',
			'Shangpai',
			'Sanmao',
			'Rongjiang',
			'Rongjiang',
			'Rifeng',
			'Quanzhou',
			'Yizhou',
			'Pukou',
			'Pingli',
			'Niushan',
			'Ningyang',
			'Ninghe',
			'Nanxi',
			'Nanchong',
			'Minjian',
			'Miaoyu',
			'Xinmatou',
			'Macheng',
			'Binyang',
			'Luoqi',
			'Daxing',
			'Lu’an',
			'Liuzikou',
			'Lankao Chengguanzhen',
			'Jinyuan',
			'Jinxiang',
			'Jingchuan',
			'Jingning Chengguanzhen',
			'Jiehu',
			'Jiaxing',
			'Jiaoshi',
			'Jiangzhuang',
			'Huarong',
			'Huaihua',
			'Haomen',
			'Haikou',
			'Jiangle',
			'Guanting',
			'Guanhu',
			'Gaojingzhuang',
			'Fuyang',
			'Fengyi',
			'Fenggao',
			'Feiluan',
			'Linshui',
			'Damai',
			'Chengyang',
			'Changzhi',
			'Beicaicun',
			'Baiyang',
			'Barkam',
			'Anqing',
			'Xinglong',
			'Yebao',
			'Taiping',
			'Lianghu',
			'Xiaochangshan',
			'Qiandengyingcun',
			'Yingkou',
			'Panshan',
			'Ning’an',
			'Nianzishan',
			'Mashan',
			'Liuhe',
			'Jiupu',
			'Luobei',
			'Fangzheng',
			'Linghai',
			'Shuguang',
			'Zhaoxian',
			'Dongjiang',
			'Fengxiang Chengguanzhen',
			'Diancun',
			'Shaijing',
			'Chubu',
			'Qianquan',
			'Bayinsai',
			'Guyang',
			'Beiping',
			'Fengling',
			'Jiagao',
			'Wuma',
			'Dunhao',
			'Jinfeng',
			'Tiefeng',
			'Changtan',
			'Guocun',
			'Zhushan',
			'Hexing',
			'Sanba',
			'Taipingba',
			'Guanzhou',
			'Xikou',
			'Shuangquan',
			'Zhengyang',
			'Shaba',
			'Wanghailou',
			'Damu',
			'Baolong',
			'Huayan',
			'Longshi',
			'Chengqu',
			'Damatou',
			'Ailan Mubage',
			'Gumudi',
			'Decheng',
			'Beidaihe',
			'Rugao',
			'Huoshilafu',
			'Liunan',
			'Donghui',
			'Nuozhadu',
			'Jiuhe',
			'Tuogerigaizi',
			'Dazeshan',
			'Zito',
			'Xinyuan',
			'Toding',
			'Namling',
			'Laojunmiao',
			'Kashgar',
			'Judian',
			'Jinding',
			'Golmud',
			'Tekes',
			'Kürti',
			'Hejing',
			'Aqchi',
			'Shuimogou',
			'Tangzhang',
			'Zuitou',
			'Zishui',
			'Zhuyuan',
			'Zhongmu Chengguanzhen',
			'Zhongfang',
			'Zhongchao',
			'Zhicheng',
			'Zhenxi',
			'Zhelou',
			'Zhecheng Chengguanzhen',
			'Zibo',
			'Yuzhong Chengguanzhen',
			'Jinghong',
			'Yuanyang',
			'Yongfu',
			'Yingcheng',
			'Yanzhou',
			'Xuantan',
			'Xixiang',
			'Guixi',
			'Xinzhou',
			'Hancheng',
			'Xintai',
			'Xingguo',
			'Wenfeng',
			'Xipu',
			'Guhan',
			'Wenxing',
			'Wuyuan',
			'Wujing',
			'Wuhe Chengguanzhen',
			'Wuhai',
			'Chang’an',
			'Tuxiang',
			'Tongzi',
			'Tianshui',
			'Siyang',
			'Sicheng',
			'Shunhua',
			'Shuangxi',
			'Shilong',
			'Shaping',
			'Sailaitang',
			'Sanqu',
			'Sanjiaocheng',
			'Ronglong',
			'Panlong',
			'Quanzhou',
			'Hongqiao',
			'Qiaotou',
			'Qiancheng',
			'Qabqa',
			'Qingyang',
			'Nuanquan',
			'Nanliang Nongchang',
			'Mengla',
			'Meixing',
			'Mazhuang',
			'Maogong',
			'Luomen',
			'Lukou',
			'Longhua',
			'Liujiaxia',
			'Linquan',
			'Lingao',
			'Jiangbei',
			'Laibin',
			'Jinji',
			'Jianchang',
			'Huili Chengguanzhen',
			'Huayang',
			'Huarong Chengguanzhen',
			'Yanshi Chengguanzhen',
			'Hongzhou',
			'Heping',
			'Gaocun',
			'Fenggang',
			'Duzhou',
			'Duyun',
			'Deyang',
			'Dazhong',
			'Zhangjiajie',
			'Xinshui',
			'Dangjiang',
			'Dabaizhuang',
			'Changsha',
			'Changba',
			'Bishui',
			'Baoding',
			'Bama',
			'Xilin',
			'Mudu',
			'Shuikou',
			'Pulü',
			'Nanping',
			'Zhongyuanlu',
			'Guangcun',
			'Zhoucheng',
			'Beishancun',
			'Zhalantun',
			'Yushu',
			'Yitong',
			'Yakeshi',
			'Linxi',
			'Huanan',
			'Erenhot',
			'Chifeng',
			'Bayan Tuohai',
			'Baiyin Chagan',
			'Jinniu',
			'Hongmiao',
		],
	},
	Colombia: {
		country: 'Colombia',
		cities: [
			'Vistahermosa',
			'Viotá',
			'Villavicencio',
			'Valle de San Juan',
			'Unguía',
			'Totoró',
			'Tipacoque',
			'Teorama',
			'Sutatenza',
			'Suesca',
			'Sucre',
			'Simití',
			'Sevilla',
			'Puerto Rondón',
			'Pinchote',
			'Pijiño del Carmen',
			'Belalcázar',
			'Ovejas',
			'Oicatá',
			'Nariño',
			'Morelia',
			'Mocoa',
			'Madrid',
			'Jericó',
			'Facatativá',
			'El Guacamayo',
			'Doima',
			'Coveñas',
			'Corozal',
			'Chimá',
			'Caucasia',
			'Carlosama',
			'Caicedo',
			'Buenavista',
			'Belalcázar',
			'Baranoa',
			'Aranzazu',
			'Anserma',
			'Achí',
			'Canalete',
			'Solita',
			'Samaná',
			'San Bernardo',
			'San Benito Abad',
			'Pulí',
			'Palmira',
			'Paispamba',
			'Padua',
			'Miraflores',
			'Medellín',
			'San Sebastián de Mariquita',
			'Herveo',
			'Hacarí',
			'Guachucal',
			'El Paujíl',
			'El Cairo',
			'Durania',
			'Coper',
			'Cerrito',
			'Cereté',
			'Candelaria',
			'Caimito',
			'Buenavista',
			'Briceño',
			'Belén de los Andaquíes',
			'Barrancabermeja',
			'Argelia',
			'Sabanalarga',
			'Carepa',
			'Puerto Libertador',
			'San Cayetano',
			'Zapatoca',
			'Villagómez',
			'Tumaco',
			'Sutamarchán',
			'Susa',
			'Santa Bárbara',
			'San Roque',
			'Puerto Escondido',
			'Polonuevo',
			'Zarzal',
			'Yacopí',
			'Venecia',
			'Turbaná',
			'Tarapacá',
			'Susacón',
			'Somondoco',
			'Socha Viejo',
			'Santa Marta',
			'Sandoná',
			'San Carlos',
			'Río Viejo',
			'Pupiales',
			'Puerto Rico',
			'Puerto Colombia',
			'Providencia',
			'Planeta Rica',
			'Planadas',
			'Piojó',
			'Santa Bárbara de Pinto',
			'Pijao',
			'Piedecuesta',
			'Payán',
			'Oporapa',
			'Nuquí',
			'Moñitos',
			'Macanal',
			'La Argentina',
			'Guavatá',
			'Galapa',
			'Fundación',
			'Espinal',
			'Cumaribo',
			'Confines',
			'Chita',
			'Chimichagua',
			'Cartagena',
			'Cañasgordas',
			'Calimita',
			'Ariguaní',
			'Villanueva',
			'El Dorado',
			'Yarumal',
			'Urrao',
			'Togüí',
			'Tibasosa',
			'Tibacuy',
			'Tarazá',
			'Sotomayor',
			'Sitionuevo',
			'Santuario',
			'Santo Domingo',
			'Santa María',
			'San Sebastián',
			'San Luis',
			'San José del Palmar',
			'San Jacinto del Cauca',
			'San Bernardo',
			'Puerto López',
			'Puerto Lleras',
			'Puente Nacional',
			'Pradera',
			'Pamplonita',
			'Olaya Herrera',
			'Marulanda',
			'Margarita',
			'La Cumbre',
			'Jamundí',
			'Guadalupe',
			'Fusagasugá',
			'Fúquene',
			'Fosca',
			'El Difícil',
			'Distracción',
			'Coromoro',
			'Chima',
			'Chaguaní',
			'Caracolí',
			'Bugalagrande',
			'Becerril',
			'Barranca de Upía',
			'Almaguer',
			'Albania',
			'Agrado',
			'Abejorral',
			'Uribia',
			'Tesalia',
			'Talaigua Viejo',
			'Supatá',
			'San Luis de Sincé',
			'Segovia',
			'San José de la Montaña',
			'San Cristóbal',
			'San Antonio',
			'San Andrés',
			'Restrepo',
			'Puerto Nariño',
			'Pueblo Nuevo',
			'Peque',
			'Pajarito',
			'Nunchía',
			'Medina',
			'Majagual',
			'Lourdes',
			'La Paz',
			'Iscuandé',
			'Guadalupe',
			'Gómez Plata',
			'Nueva Granada',
			'Funza',
			'Fómeque',
			'Floridablanca',
			'El Carmen de Chucurí',
			'Algarrobo',
			'Cruces de Anorí',
			'Cota',
			'Córdoba',
			'Coello',
			'Cértegui',
			'Carcasí',
			'Campo de la Cruz',
			'Beltrán',
			'Barranquilla',
			'Barbosa',
			'Andes',
			'Anapoima',
			'Albania',
			'Villanueva',
			'Útica',
			'Trinidad',
			'Suan',
			'Sotaquirá',
			'Prado-Sevilla',
			'San Onofre',
			'La Dorada',
			'San Cristóbal',
			'San Calixto',
			'Puerto Meluk',
			'Potosí',
			'Páramo',
			'Nuevo Colón',
			'Nóvita',
			'Murillo',
			'Málaga',
			'Los Palmitos',
			'Leiva',
			'La Primavera',
			'La Celia',
			'Honda',
			'Guamal',
			'Guachavés',
			'El Peñón',
			'El Peñón',
			'El Cerrito',
			'Cubará',
			'Convención',
			'Concordia',
			'Chiscas',
			'Chía',
			'Chalán',
			'Cabrera',
			'Bucaramanga',
			'Boyacá',
			'Betéitiva',
			'Arboleda',
			'Villa Rica',
			'Santa Isabel',
			'Zipacón',
			'Villa de Leyva',
			'Tenza',
			'Saravena',
			'Santa Rosalia',
			'Santa Rosa de Cabal',
			'Santa Isabel',
			'San Estanislao',
			'San Carlos de Guaroa',
			'Salento',
			'Salamina',
			'Pie de Pató',
			'Palestina',
			'Onzaga',
			'Mompós',
			'Milán',
			'Machetá',
			'La Virginia',
			'Ánimas',
			'Íquira',
			'Herrán',
			'Guapí',
			'Guachené',
			'Girardota',
			'Ginebra',
			'Galán',
			'Florián',
			'El Roble',
			'El Retén',
			'Chocontá',
			'Betulia',
			'Albania',
			'Vegachí',
			'Aguada',
			'La Macarena',
			'San Fernando',
			'Vigía del Fuerte',
			'Vergara',
			'Topaipí',
			'Tocancipá',
			'Tibaná',
			'Talaigua Nuevo',
			'Sonsón',
			'Silos',
			'Santana',
			'San Pablo de Borbur',
			'San Miguel',
			'San Marcos',
			'San Juan de Urabá',
			'San Eduardo',
			'San Cayetano',
			'San Agustín',
			'Restrepo',
			'Quebradanegra',
			'Puebloviejo',
			'Policarpa',
			'Paz de Río',
			'Pandi',
			'Manatí',
			'Los Patios',
			'La Uvita',
			'La Estrella',
			'Junín',
			'Hobo',
			'Guateque',
			'Gramalote',
			'Gaitania',
			'Frontino',
			'Frías',
			'El Dovio',
			'El Carmen',
			'Cucutilla',
			'Coyaima',
			'Corinto',
			'Contratación',
			'Contadero',
			'Chinácota',
			'Chaparral',
			'Cepitá',
			'Carmen de Apicalá',
			'Cantagallo',
			'Cáchira',
			'Bolívar',
			'Betania',
			'Belén de Umbría',
			'Barrancas',
			'Arcabuco',
			'Apartadó',
			'Alvarado',
			'Algeciras',
			'Morales',
			'Tibacuy',
			'La Merced',
			'Santa Cruz del Islote',
			'Yolombó',
			'Villanueva',
			'Villagarzón',
			'Uramita',
			'Tarso',
			'Santa Lucía',
			//'Santa Ana',
			'San Pedro de Urabá',
			'San Fernando',
			'Ricaurte',
			'Quimbaya',
			'Purísima de la Concepción',
			'Punta de Piedras',
			'Puerto Wilches',
			'Puerto Salgar',
			'Puerres',
			'Palermo',
			'Páez',
			'Orocué',
			'Maripí',
			'Manta',
			'Los Córdobas',
			'Linares',
			'Liborina',
			'La Vega',
			'La Unión',
			'La Playa',
			'González',
			'Elías',
			'Coello',
			'Carmen de Carupa',
			'Candelaria',
			'Calamar',
			'Briceño',
			'Bolívar',
			'Bagadó',
			'Arbeláez',
			'Arauquita',
			'Arauca',
			'Anzá',
			'Andalucía',
			'Campo Alegre',
			'Mesetas',
			'Altos del Rosario',
			'Isnos',
			'Valle del Guamuez',
			'San Martín de Loba',
			'Valencia',
			'Úmbita',
			'Timaná',
			'Sapuyes',
			'San Vicente',
			'Santa María',
			'San Ángel',
			'San Alberto',
			'Samaniego',
			'Salgar',
			'Salamina',
			'Rovira',
			'Puerto Bogotá',
			'Pisba',
			'Pinillos',
			'Pelaya',
			'Paimadó',
			'Paicol',
			'Otanche',
			'Ocamonte',
			'Nocaima',
			'Nariño',
			'Lloró',
			'Lérida',
			'Jambaló',
			'Ituango',
			'Hato',
			'Guamo',
			'Granada',
			'Gámbita',
			'Gachancipá',
			'Cómbita',
			'Cicuco',
			'Chitagá',
			'Calamar',
			'Cáceres',
			'Guayabal',
			'Armenia',
			'Ansermanuevo',
			'Angelópolis',
			'Puerto Guzmán',
			'Socha',
			'Tona',
			'Tame',
			'Supía',
			'Subachoque',
			'Santa Rosa',
			'San Jerónimo',
			'San Diego',
			'San Andrés de Sotavento',
			'San Andrés',
			'Sáchica',
			'Sabanalarga',
			'Sabana de Torres',
			'Rivera',
			'Ramiriquí',
			'Pizarro',
			'Pensilvania',
			'Paya',
			'Nueva Granada',
			'Morroa',
			'Molagavita',
			'Líbano',
			'El Espino',
			'Circasia',
			'Busbanzá',
			'Buenaventura',
			'Astrea',
			'Argelia',
			'Arboletes',
			'Aquitania',
			'Almeida',
			'Albán',
			'Santiago Pérez',
			'El Tarra',
			'Santa María',
			'Santa Helena del Opón',
			'Neiva',
			'Marinilla',
			'La Sierra',
			'Hatillo de Loba',
			'Guayabetal',
			'Cumbal',
			'Cucunubá',
			'Concordia',
			'Combita',
			'Caldono',
			'Caicedonia',
			'Cachipay',
			'Bosconia',
			'Barranco de Loba',
			'Ancuya',
			'Cartagena del Chairá',
			'La Tola',
			'La Esperanza',
			'Alpujarra',
			'Zaragoza',
			'Yuto',
			'Valdivia',
			'Turmequé',
			'Tunja',
			'Tierralta',
			'Támesis',
			'Santuario',
			'Santo Tomás',
			'Santa Isabel',
			'San Pedro',
			'San Antonio',
			'Roncesvalles',
			'Risaralda',
			'Inírida',
			'Pueblo Bello',
			'Paz de Ariporo',
			'Norcasia',
			'Neira',
			'Mountain',
			'Momil',
			'Melgar',
			'Hispania',
			'Enciso',
			'Yotoco',
			'Villarrica',
			'Venadillo',
			'Ubaque',
			'Tuchín',
			'Tópaga',
			'Toca',
			'Tauramena',
			'Tarqui',
			'Suárez',
			'Soracá',
			'Santa Sofía',
			'Santa Rosa de Viterbo',
			'San Pablo',
			'Pueblo Nuevo',
			'Pital',
			'Pedraza',
			'Pasto',
			'Panqueba',
			'Ospina',
			'Oiba',
			'Mosquera',
			'Mosquera',
			'Maceo',
			'La Unión',
			'La Pintada',
			'La Palma',
			'Inzá',
			'Guaranda',
			'Guaca',
			'Girón',
			'Génova',
			'El Carmen de Bolívar',
			'El Bordo',
			'Curumaní',
			'Condoto',
			'Chíquiza',
			'Charta',
			'Campamento',
			'Berbeo',
			'Barbosa',
			'Anaime',
			'Curillo',
			'Santa Rosa del Sur',
			'Puerto Guzmán',
			'Puerto Caicedo',
			'Villavieja',
			'Vianí',
			'Usiacurí',
			'Turbo',
			'Túquerres',
			'Santiago de Tolú',
			'Timbío',
			'Tibú',
			'Sibundoy',
			'Sesquilé',
			'Pore',
			'Pauna',
			'Padilla',
			'Norosí',
			'Mahates',
			'López',
			'La Salina',
			'Granada',
			'Génova',
			'Fresno',
			'Envigado',
			'El Peñón',
			'El Cocuy',
			'Corrales',
			'Coconuco',
			'Choachí',
			'Chivolo',
			'Caldas',
			'Cajibío',
			'Cajamarca',
			'Buesaco',
			'Buenavista',
			'Aldana',
			'Aguazul',
			'Génova',
			'Monterrey',
			'Fredonia',
			'Armero-Guyabal',
			'Zipaquirá',
			'Zetaquira',
			'Tutazá',
			'Tres Esquinas',
			'Toledo',
			'Toledo',
			'Tausa',
			'Suratá',
			'Soplaviento',
			'Sibaté',
			'Santa Rosa',
			'Santa Rita',
			'San Pedro',
			'Riofrío',
			'Retiro',
			'Regidor',
			'Ráquira',
			'Pailitas',
			'Ortega',
			'Murindó',
			'Miraflores',
			'Manaure',
			'Magangué',
			'Luruaco',
			'Lebrija',
			'La Belleza',
			'Giraldo',
			'Florida',
			'Cotorra',
			'Ciénaga de Oro',
			'Casabianca',
			'Carmen de Viboral',
			'Cajicá',
			'Cachipay',
			'Buenavista',
			'Buenaventura',
			'Ataco',
			'Alejandría',
			'Gachetá',
			'Zambrano',
			'La Victoria',
			'Valledupar',
			'San José de Uré',
			'Tasco',
			'Socorro',
			'Siachoque',
			'Santander de Quilichao',
			'Cubarral',
			'San Gil',
			'San Carlos',
			'San Carlos',
			'Rondón',
			'Remolino',
			'Quipile',
			'Palmar',
			'Palestina',
			'María la Baja',
			'Manizales',
			'Lenguazaque',
			'La Jagua del Pilar',
			'La Esperanza',
			'Ibagué',
			'Gutiérrez',
			'Granada',
			'Garzón',
			'Gámeza',
			'El Doncello',
			'Cravo Norte',
			'Ciénaga',
			'Carolina',
			'Caparrapí',
			'Betulia',
			'Baraya',
			'Bahía Solano',
			'Chivor',
			'Quípama',
			'San Andrés',
			'Santa Genoveva de Docordó',
			'Orito',
			'Barrio San Luis',
			'Playarrica',
			'Palmar de Varela',
			'Paime',
			'Maicao',
			'La Calera',
			'Imués',
			'Falan',
			'El Zulia',
			'El Rosal',
			'Dosquebradas',
			'Darien',
			'Cúcuta',
			'Chicoral',
			'Charalá',
			'Caloto',
			'Belén',
			'Barichara',
			'Aratoca',
			'El Colegio',
			'Villanueva',
			'Trujillo',
			'Timbiquí',
			'Tamalameque',
			'Sardinata',
			'Santa Rosa de Osos',
			'San Pablo',
			'Sahagún',
			'Rioblanco',
			'Puerto Berrío',
			'Suaza',
			'Suaita',
			'San Juanito',
			'Ragonvalia',
			'Purificación',
			'Popayán',
			'Managrú',
			'Los Santos',
			'La Llanada',
			'La Capilla',
			'Labranzagrande',
			'Jericó',
			'Güicán',
			'Gualmatán',
			'Gigante',
			'Gamarra',
			'El Rosario',
			'El Peñol',
			'Córdoba',
			'Chiriguaná',
			'Chachagüí',
			'Buenavista',
			'Providencia',
			'Bochalema',
			'Aipe',
			'Acacías',
			'Ábrego',
			'Laureles',
			'Puerto Concordia',
			'Piamonte',
			'Puerto Lleras',
			'Valdivia',
			'El Bagre',
			'Concepción',
			'Colón',
			'Ciénega',
			'Chinchiná',
			'Chigorodó',
			'Castilla La Nueva',
			'Cáqueza',
			'Bogotá',
			'Bello',
			'Barbacoas',
			'Acandí',
			'Bellavista',
			'Ventaquemada',
			'Une',
			'Tununguá',
			'Tangua',
			'Tadó',
			'Sasaima',
			'Santa Catalina',
			'San Juan Nepomuceno',
			'San Juan de Betulia',
			'San Francisco',
			'Saldaña',
			'Saboyá',
			'Riosucio',
			'Riohacha',
			'Repelón',
			'Olaya',
			'Nilo',
			'Mutiscua',
			'Moñitos',
			'Monguí',
			'Mongua',
			'La Unión',
			'La Jagua de Ibirico',
			'Jordán',
			'Güepsa',
			'Fuente de Oro',
			'Fortul',
			'Filandia',
			'Filadelfia',
			'Villeta',
			'Villanueva',
			'Vetas',
			'Valparaíso',
			'Tuta',
			'Tota',
			'Tocaima',
			'Tinjacá',
			'Teruel',
			'Taminango',
			'Soacha',
			'San Sebastián de Buenavista',
			'San Luis de Palenque',
			'Sabaneta',
			'Roldanillo',
			'Ricaurte',
			'Puerto Triunfo',
			'Puerto Carreño',
			'Pueblo Rico',
			'Prado',
			'Maní',
			'Malambo',
			'Macaravita',
			'La Ceja',
			'Icononzo',
			'Guaduas',
			'Guachetá',
			'Girardot City',
			'El Copey',
			'El Charco',
			'El Banco',
			'Cunday',
			'Cucaita',
			'Cocorná',
			'Cerro de San Antonio',
			'Cerinza',
			'California',
			'Bucarasica',
			'Bojacá',
			'Curbaradó',
			'Arboledas',
			'Anzoátegui',
			'Anolaima',
			'Puerto Gaitán',
			'La Cumbre',
			'Covarachía',
			'Remedios',
			'Pereira',
			'Palmas del Socorro',
			'Nobsa',
			'Mistrató',
			'Marmato',
			'La Florida',
			'Istmina',
			'Guapotá',
			'Granada',
			'Córdoba',
			'Concordia',
			'Cimitarra',
			'Buenavista',
			'Beté',
			'Belmira',
			'Ambalema',
			'Alcalá',
			'Aguachica',
			'Acevedo',
			'Paratebueno',
			'Mapiripán',
			'Yalí',
			'Victoria',
			'Venecia',
			'Valle de San José',
			'Sucre',
			'Sopó',
			'San Lorenzo',
			'San Juan de Rioseco',
			'San Francisco',
			'Villa Rica',
			'Villamaría',
			'Tenjo',
			'Sutatausa',
			'Soledad',
			'Soatá',
			'Simijaca',
			'Sativasur',
			'Santa Bárbara',
			'San Juan del Cesar',
			'San Bernardo del Viento',
			'Río de Oro',
			'Quibdó',
			'Puerto Parra',
			'Pitalito',
			'Piedras',
			'Payandé',
			'Pacho',
			'Pachavita',
			'Nemocón',
			'Montenegro',
			'Marquetalia',
			'La Peña',
			'La Mesa',
			'Jesús María',
			'Iza',
			'Iles',
			'Heliconia',
			'Guayatá',
			'Guática',
			'Guatapé',
			'Guadalupe',
			'Garagoa',
			'Fonseca',
			'El Guamo',
			'El Carmen',
			'Cumaral',
			'Cuítiva',
			'Copacabana',
			'Consacá',
			'Concepción',
			'Cartago',
			'Guadalajara de Buga',
			'Buenos Aires',
			'Balboa',
			'Apía',
			'Altamira',
			'Abriaquí',
			'Dolores',
			'Anserma',
			'Viracachá',
			'Villa del Rosario',
			'Uribe',
			'Ulloa',
			'Tibirita',
			'Tena',
			'Sucre',
			'Sora',
			'Sipí',
			'San Vicente del Caguán',
			'Santiago',
			'San Pedro',
			'San Miguel de Sema',
			'San Martín',
			'San Luis de Gaceno',
			'San José',
			'San Antero',
			'Rosas',
			'Puerto Tejada',
			'Pueblorrico',
			'Palocabildo',
			'Pácora',
			'Morales',
			'Montelíbano',
			'Lorica',
			'Leticia',
			'La Cruz',
			'Itagüí',
			'Guayabal de Síquima',
			'Guacarí',
			'Funes',
			'Encino',
			'El Águila',
			'Colombia',
			'Cogua',
			'Chinú',
			'Chámeza',
			'Calarcá',
			'Argelia',
			'Amagá',
			'Algarrobo',
			'Agua de Dios',
			'Los Santos',
			'Lejanías',
			'La Hormiga',
			'Sopetrán',
			'Chitaraque',
			'Ebéjico',
			'Dibulla',
			'Dagua',
			'Dabeiba',
			'Cácota',
			'Tiquisio',
			'El Cantón de San Pablo',
			'Carurú',
			'Yondó',
			'Villa de San Diego de Ubaté',
			'Tubará',
			'Titiribí',
			'Támara',
			'Solano',
			'Silvia',
			'San Zenón',
			'San Vicente de Chucurí',
			'San Mateo',
			'Isnos',
			'San José',
			'Sácama',
			'Ponedera',
			'Patía',
			'Motavita',
			'Montería',
			'Mitú',
			'Miranda',
			'Manzanares',
			'López',
			'La Merced',
			'La Chamba',
			'Junín',
			'Ipiales',
			'Guarne',
			'Gama',
			'Yopal',
			'Villapinzón',
			'Versalles',
			'Valparaíso',
			'Tello',
			'Suárez',
			'Socotá',
			'Simacota',
			'Silvania',
			'Santiago',
			'San Pelayo',
			'San José de Pare',
			'San Joaquín',
			'San Jacinto',
			'Saladoblanco',
			'Sabanalarga',
			'Puerto Yuca',
			'Obando',
			'Mutatá',
			'Montebello',
			'Moniquirá',
			'Matanza',
			'La Gloria',
			'Juan de Acosta',
			'Municipio Hato Corozal',
			'Guacamayas',
			'Firavitoba',
			'Duitama',
			'Donmatías',
			'Curití',
			'Cumbitara',
			'Colosó',
			'Cisneros',
			'Capitanejo',
			'Bolívar',
			'Bocas de Satinga',
			'Belén',
			'Anorí',
			'Aguadas',
			'San Carlos',
			'San José del Fragua',
			'Rionegro',
			'La Apartada',
			'San Benito',
			'Sampués',
			'Samacá',
			'Riosucio',
			'Apulo',
			'Puerto Santander',
			'Puerto Boyacá',
			'Puerres',
			'Pasca',
			'Pamplona',
			'Ocaña',
			'Necoclí',
			'Natagaima',
			'Mogotes',
			'La Plata',
			'Landázuri',
			'La Montañita',
			'Juradó',
			'Guataquí',
			'Florencia',
			'El Tambo',
			'El Paso',
			'Chivatá',
			'Campohermoso',
			'Campoalegre',
			'Caldas',
			'Cabuyaro',
			'Cabrera',
			'Arenal',
			'Amalfi',
			'Santacruz',
			'Labateca',
			'Buriticá',
			'Manaure Balcón del Cesar',
			'El Playón',
			'El Carmen de Atrato',
			'Yacuanquer',
			'Viterbo',
			'Villa Caro',
			'Turbaco',
			'Toro',
			'Tolú Viejo',
			'Tabio',
			'Sincelejo',
			'San Juan de Arama',
			'San Francisco',
			'San Francisco',
			'Guamal',
			'Yumbo',
			'Villahermosa',
			'Tuluá',
			'Tenerife',
			'Sativanorte',
			'San Pablo',
			'Sabanagrande',
			'Rionegro',
			'Recetor',
			'Puerto Leguízamo',
			'Puerto Asís',
			'Salahonda',
			'Piedrancha',
			'Pesca',
			'Paipa',
			'Nechí',
			'Nátaga',
			'Marsella',
			'La Dorada',
			'Jenesano',
			'Guaitarilla',
			'Gachalá',
			'Florencia',
			'Flandes',
			'Entrerríos',
			'El Tambo',
			'El Tablón',
			'El Piñón',
			'El Castillo',
			'El Calvario',
			'Chipatá',
			'Chinavita',
			'Caramanta',
			'Canalete',
			'Boavita',
			'Bituima',
			'Ayapel',
			'Arroyohondo',
			'Armenia',
			'Arjona',
			'Aracataca',
			'Santa Fe de Antioquia',
			'Angostura',
			'El Retorno',
			'Plato',
			'Yaguará',
			'Vijes',
			'Vélez',
			'Urumita',
			'Ubalá',
			'Toribío',
			'Sogamoso',
			'San Rafael',
			'San Luis',
			'San José de Miranda',
			'San José',
			'San Antonio del Tequendama',
			'Quinchía',
			'Quetame',
			'Pivijay',
			'Palmito',
			'Nimaima',
			'Nariño',
			'Muzo',
			'Montecristo',
			'Mercaderes',
			'La Victoria',
			'La Vega',
			'La Unión',
			'La Tebaida',
			'La Paz',
			'La Capilla',
			'Jerusalén',
			'Jardín',
			'Guatavita',
			'Guasca',
			'Gachantivá',
			'Floresta',
			'El Molino',
			'Clemencia',
			'Chiquinquirá',
			'Chipaque',
			'Cartago',
			'Cali',
			'Balboa',
			'El Dorado',
			'Ciudad Bolívar',
			'Agustín Codazzi',
			'San Martín',
			'San José del Guaviare',
			'Piendamo',
			'Ubalá',
		],
	},
	Comoros: {
		country: 'Comoros',
		cities: [
			'Oussivo',
			'Ntsoudjini',
			'Dziani',
			'Pajé',
			'Kangani',
			'Mavingouni',
			'Madjeouéni',
			'Assimpao',
			'Mvouni',
			'Vouani',
			'Mitsamiouli',
			'Foumbouni',
			'Chindini',
			'Bouni',
			'Kavani',
			'Ouzini',
			'Ouellah',
			'Dzahadjou',
			'Mandza',
			'Vanambouani',
			'Salimani',
			'Mohoro',
			'Chironkamba',
			'Bandajou',
			'Ouhozi',
			'Moutsamoudou',
			'Itsandzéni',
			'Hoani',
			'Hajoho',
			'Tsimbeo',
			'Mitsoudjé',
			'Barakani',
			'Mjimandra',
			'Chitrouni',
			'Mirontsi',
			'Lingoni',
			'Moroni',
			'Koni-Djodjo',
			'Daji',
			'Ouanani',
			'Koni-Ngani',
			'Boungouéni',
			'Magnassini-Nindri',
			'Douniani',
			'Bambadjani',
			'Singani',
			'Ntsaouéni',
			'Mbéni',
			'Héroumbili',
			'Vanadjou',
			'Ongoni',
			'Mtsamdou',
			'Mramani',
			'Maraharé',
			'Dembéni',
			'Antsahé',
			'Tsidjé',
			'Djoyézi',
			'Chezani',
			'Itsandra',
			'Bimbini',
			'Ouani',
			'Nioumamilima',
			'Kyo',
			'Moya',
			'Ivouani',
			'Adda-Douéni',
			'Koua',
			'Séléa',
			'Nioumachoua',
			'Mjamaoué',
			'Limbi',
			'Patsi',
			'Mtakoudja',
			'Harembo',
			'Bahani',
			'Ourovéni',
			'Koki',
			'Chandra',
			'Chouani',
			'Domoni',
			'Bandamadji-Domba',
			'Sima',
			'Mrémani',
			'Bambao',
			'Ziroudani',
			'Fomboni',
			'Hantsindzi',
			'Mnoungou',
		],
	},
	Congo: {
		country: 'Congo',
		cities: [
			'Kinkala',
			'Nkayi',
			'Impfondo',
			'Ewo',
			'Kayes',
			'Sibiti',
			'Loango',
			'Makoua',
			'Djambala',
			'Sémbé',
			'Owando',
			'Madingou',
			'Pointe-Noire',
			'Brazzaville',
			'Mossendjo',
			'Dolisie',
			'Ouésso',
			'Loandjili',
			'Gamboma',
		],
	},
	'Congo, Democratic Republic of the': {
		country: 'Congo, Democratic Republic of the',
		cities: [
			'Goma',
			'Basoko',
			'Ilebo',
			'Beni',
			'Libenge',
			'Lusambo',
			'Kindu',
			'Kananga',
			'Kambove',
			'Mushie',
			'Moanda',
			'Aketi',
			'Yangambi',
			'Mbandaka',
			'Kasongo',
			'Bosobolo',
			'Bolobo',
			'Mbuji-Mayi',
			'Lodja',
			'Boende',
			'Kasongo-Lunda',
			'Bondo',
			'Kamina',
			'Kabalo',
			'Lubumbashi',
			'Gbadolite',
			'Demba',
			'Lukolela',
			'Butembo',
			'Bukama',
			'Wamba',
			'Lisala',
			'Isiro',
			'Bukavu',
			'Kinshasa',
			'Luebo',
			'Kabinda',
			'Gandajika',
			'Kipushi',
			'Uvira',
			'Nioki',
			'Boma',
			'Bandundu',
			'Tshikapa',
			'Businga',
			'Likasi',
			'Kolwezi',
			'Mangai',
			'Kasangulu',
			'Masina',
			'Watsa',
			'Mwene-Ditu',
			'Kabare',
			'Tshela',
			'Lubao',
			'Bunia',
			'Sake',
			'Inongo',
			'Kongolo',
			'Kisangani',
			'Kalemie',
			'Bumba',
			'Bongandanga',
			'Mbanza-Ngungu',
			'Bulungu',
			'Mweka',
			'Kampene',
			'Buta',
			'Matadi',
			'Kikwit',
			'Gemena',
		],
	},
	'Cook Islands': { country: 'Cook Islands', cities: ['Avarua'] },
	'Costa Rica': {
		country: 'Costa Rica',
		cities: [
			'Sixaola',
			'San Pedro',
			'San Pablo',
			'Zapotal',
			'San Antonio',
			'Sámara',
			'Pital',
			'Calle Blancos',
			'Paraíso',
			'Palmichal',
			'Mercedes',
			'Llorente',
			'Santo Domingo',
			'Santa Ana',
			'Pacayas',
			'Nicoya',
			'La Virgen',
			'San Ramón',
			'San Isidro',
			'San Rafael',
			'San José',
			'Sabanilla',
			'Quepos',
			'Patarrá',
			'Monterrey',
			'Guápiles',
			'San Vicente de Moravia',
			'Tobosi',
			'San Marcos',
			'Granadilla',
			'Colón',
			'Buenos Aires',
			'Zarcero',
			'La Fortuna',
			'La Fortuna',
			'Cot',
			'Belén',
			'San Juan de Dios',
			'Miramar',
			'Golfito',
			'San Pablo',
			'San Juan',
			'Pocora',
			'La Asunción',
			'Alajuelita',
			'Tres Ríos',
			'Río Segundo',
			'Heredia',
			'Turrialba',
			'Sardinal',
			'Santiago',
			'Salitrillos',
			'Mercedes Norte',
			'Santiago',
			'San Rafael',
			'Naranjo',
			'Guácimo',
			'Atenas',
			'Upala',
			'Siquirres',
			'Sarchí',
			'Santa Cruz',
			'San Rafael Arriba',
			'Sabalito',
			'Parrita',
			'Esquipulas',
			'Corredor',
			'Colima',
			'Cañas',
			'Cariari',
			'San Rafael Abajo',
			'La Cruz',
			'Daniel Flores',
			'Concepción',
			'Canoas',
			'San Rafael',
			'San Ignacio',
			'Purral',
			'San Vicente',
			'San Diego',
			'Paquera',
			'Liberia',
			'Jacó',
			'Aserrí',
			'Tamarindo',
			'San Miguel',
			'San Felipe',
			'Pejibaye',
			'Juntas',
			'Bejuco',
			'Tucurrique',
			'Tilarán',
			'Roxana',
			'Puntarenas',
			'Limón',
			'Escazú',
			'Curridabat',
			'Orotina',
			'San José',
			'San Francisco',
			'Orosi',
			'La Suiza',
			'Ipís',
			'Bijagua',
			'Los Chiles',
			'Puerto Cortés',
			'Chacarita',
			'Alajuela',
			'Tejar',
			'Sabanilla',
			'Quesada',
			'Matina',
			'Ángeles',
			'Dulce Nombre de Jesus',
			'San Vito',
			'San Juan',
			'Salitral',
			'Esparza',
			'San Josecito',
			'Nandayure',
			'Hojancha',
			'Poco Sol',
			'Guadalupe',
			'Desamparados',
			'Cartago',
			'Carrillos',
			'Batán',
			'Barrio Jesús',
			'Bagaces',
		],
	},
	"Côte d'Ivoire": {
		country: "Côte d'Ivoire",
		cities: [
			'Tabou',
			'Guitry',
			'Bouaké',
			'Abobo',
			'Grand-Lahou',
			'Doropo',
			'Daloa',
			'Dabakala',
			'Attiégouakro',
			'San-Pédro',
			'Mankono',
			'Korhogo',
			'Guiglo',
			'Séguéla',
			'Zuénoula',
			'Sassandra',
			'Béoumi',
			'Abengourou',
			'Sakassou',
			'Dianra',
			'Bouna',
			'Téhini',
			'Ouangolodougou',
			'Kouto',
			'Kong',
			'Divo',
			'Dimbokro',
			'Grand-Bassam',
			'Botro',
			'Agnibilékrou',
			'Touba',
			'Koro',
			'Bonoua',
			'Béttié',
			'Anyama',
			'Madinani',
			'Jacqueville',
			'Biankouma',
			'Arrah',
			'M’Bahiakro',
			'Ouaninou',
			'Vavoua',
			'Dikodougou',
			'Buyo',
			'Boundiali',
			'Bouaflé',
			'Transua',
			'Tiassalé',
			'Man',
			'Kounahiri',
			'Katiola',
			'Danané',
			'Tengréla',
			'Sipilou',
			'Séguélon',
			'M’Bengué',
			'Gagnoa',
			'Bocanda',
			'Adiaké',
			'Sandégué',
			'Nassian',
			'Bongouanou',
			'Ayamé',
			'Adzopé',
			'Toulépleu',
			'Gbéléban',
			'Yamoussoukro',
			'Yakassé-Attobrou',
			'Toumodi',
			'Prikro',
			'Guibéroua',
			'Djékanou',
			'Taboitien',
			'Bangolo',
			'Sikensi',
			'Kani',
			'Guéyo',
			'M’Batto',
			'Kouibly',
			'Aboisso',
			'Soubré',
			'Zouan-Hounien',
			'Odienné',
			'Minignan',
			'Koun-Fao',
			'Affery',
			'Facobly',
			'Duekoué',
			'Didiévi',
			'Tanda',
			'Toulépleu Gueré',
			'Sinématiali',
			'Samatiguila',
			'Kaniasso',
			'Fresco',
			'Bondoukou',
			'Ahouanou',
			'Tiapoum',
			'Zoukougbeu',
			'Dabou',
			'Taabo',
			'Sinfra',
			'Oumé',
			'Marcory',
			'Tiébissou',
			'Taï',
			'Ferkessédougou',
			'Bingerville',
			'Akoupé',
			'Niakaramandougou',
			'Lakota',
			'Kouassi-Kouassikro',
			'Issia',
			'Agboville',
			'Daoukro',
			'Abidjan',
			'Bloléquin',
			'Alépé',
		],
	},
	'Country name EN': { country: 'Country name EN', cities: ['Name'] },
	Croatia: {
		country: 'Croatia',
		cities: [
			'Vrbanja',
			'Valpovo',
			'Tenja',
			'Preseka',
			'Petrijevci',
			'Obrovac',
			'Negoslavci',
			'Medulin',
			'Kozinščak',
			'Kapela',
			'Kamanje',
			'Kalnik',
			'Gornje Jesenje',
			'Dobrodol',
			'Crnac',
			'Cetingrad',
			'Cestica',
			'Cernik',
			'Čabar',
			'Brnaze',
			'Brinje',
			'Bošnjaci',
			'Barilović',
			'Lipovljani',
			'Mrežničke Poljice',
			'Vrginmost',
			'Tounj',
			'Svetvinčenat',
			'Strizivojna',
			'Šodolovci',
			'Ružić',
			'Rubeši',
			'Rakovica',
			'Pribislavec',
			'Pašman',
			'Pakrac',
			'Mraclin',
			'Marčana',
			'Lukač',
			'Komiža',
			'Knin',
			'Ivanec',
			'Gundinci',
			'Gornja Vrba',
			'Đurđevac',
			'Drnje',
			'Buzet',
			'Barbat',
			'Andrijaševci',
			'Kamen',
			'Kamenmost',
			'Valbandon',
			'Palit',
			'Brodić',
			'Zrinski Topolovac',
			'Vrbovsko',
			'Vižinada',
			'Strožanac - Donji',
			'Selce',
			'Samobor',
			'Remetinec',
			'Oriovac',
			'Lumbarda',
			'Lipik',
			'Križevci',
			'Jalžabet',
			'Jablanovec',
			'Hreljin',
			'Hlebine',
			'Gospić',
			'Okrug Gornji',
			'Goričan',
			'Donja Stubica',
			'Donja Pušća',
			'Donja Motičina',
			'Čađavica',
			'Brestovac',
			'Babino Polje',
			'Novi Zagreb',
			'Zmijavci',
			'Vrgorac',
			'Vojnić',
			'Unešić',
			'Trilj',
			'Solin',
			'Sokolovac',
			'Selci Ðakovački',
			'Rogoznica',
			'Ravna Gora',
			'Peklenica',
			'Orešje',
			'Opuzen',
			'Mičevec',
			'Markušica',
			'Markovac Trojstveni',
			'Lovreć',
			'Lepoglava',
			'Kuče',
			'Kraljevica',
			'Janjina',
			'Ivanska',
			'Hrašćina',
			'Donja Lomnica',
			'Cista Provo',
			'Buje',
			'Blato',
			'Bestovje',
			'Donja Dubrava',
			'Budinšćina',
			'Belišće',
			'Barban',
			'Antunovac',
			'Centar',
			'Zdenci',
			'Zadvorsko',
			'Viškovci',
			'Semeljci',
			'Pučišća',
			'Poličnik',
			'Pokupsko',
			'Osijek',
			'Nuštar',
			'Novi Vinodolski',
			'Novigrad Podravski',
			'Novi Golubovec',
			'Mursko Središće',
			'Martijanec',
			'Marija Gorica',
			'Martinska Ves',
			'Josipdol',
			'Jasenovac',
			'Hrvace',
			'Ðurđenovac',
			'Civljane',
			'Bistrinci',
			'Pobri',
			'Varaždinske Toplice',
			'Umag',
			'Tisno',
			'Šibenik',
			'Rakitje',
			'Pregrada',
			'Oprisavci',
			'Nedelišće',
			'Marinići',
			'Kaštel Gomilica',
			'Jalkovec',
			'Grubišno Polje',
			'Gradac',
			'Gornji Kneginec',
			'Podbablje Gornje',
			'Draž',
			'Domašinec',
			'Čačinci',
			'Bol',
			'Blato',
			'Benkovac',
			'Babina Greda',
			'Lobor',
			'Dubac-Varsan',
			'Zadvarje',
			'Vrlika',
			'Višnjevac',
			'Tribunj',
			'Tompojevci',
			'Suhopolje',
			'Ruščica',
			'Rabac',
			'Privlaka',
			'Primošten',
			'Popovača',
			'Podravske Sesvete',
			'Plaški',
			'Pakoštane',
			'Nova Bukovica',
			'Mali Lošinj',
			'Lekenik',
			'Gračac',
			'Dekanovec',
			'Bukovlje',
			'Bogdanovci',
			'Jankomir',
			'Donji Vinjani',
			'Borovo',
			'Brijest',
			'Dramalj',
			'Donji Stupnik',
			'Parag',
			'Klepeće Selo',
			'Kutina',
			'Udbina',
			'Sveti Martin na Muri',
			'Sveti Ðurđ',
			'Sesvete',
			'Saborsko',
			'Podvinje',
			'Perušić',
			'Pazin',
			'Ozalj',
			'Orle',
			'Mrkopalj',
			'Podravska Moslavina',
			'Makarska',
			'Majur',
			'Hrvatska Kostajnica',
			'Imotski',
			'Dugo Selo',
			'Brodski Varoš',
			'Starigrad',
			'Farkaševac',
			'Nin',
			'Vučilčevo',
			'Veliki Prokop',
			'Zagvozd',
			'Vrbje',
			'Vela Luka',
			'Stari Grad',
			'Sopje',
			'Selca',
			'Nerežišće',
			'Mravince',
			'Malinska',
			'Dicmo Kraj',
			'Koška',
			'Kloštar Podravski',
			'Jelisavac',
			'Gračišće',
			'Funtana',
			'Donji Andrijevci',
			'Bribir',
			'Bale',
			'Kampor',
			'Kastav',
			'Gornji Bogičevci',
			'Drenje',
			'Čeminac',
			'Bedekovčina',
			'Vlaka',
			'Lukarišće',
			'Zaton',
			'Vinež',
			'Velika Trnovitica',
			'Sutivan',
			'Runović',
			'Rakov Potok',
			'Punitovci',
			'Punat',
			'Postira',
			'Oklaj',
			'Legrad',
			'Koprivnica',
			'Ivankovo',
			'Gornja Rijeka',
			'Čibača',
			'Čavle',
			'Stari Grad',
			'Mladenići',
			'Podrebar',
			'Vranjic',
			'Vrsi',
			'Vis',
			'Vidovec',
			'Sućuraj',
			'Stobreč',
			'Sibinj',
			'Šenkovec',
			'Ražanac',
			'Privlaka',
			'Podgorač',
			'Pisarovina',
			'Marina',
			'Lovinac',
			'Komletinci',
			'Jastrebarsko',
			'Jadranovo',
			'Ivanovec',
			'Gunja',
			'Gornja Stubica',
			'Glavina Donja',
			'Delnice',
			'Cres',
			'Budrovci',
			'Brodski Stupnik',
			'Briješće',
			'Beretinec',
			'Viškovo',
			'Podstrana - Miljevac',
			'Sveti Ivan Zelina',
			'Vukovar',
			'Vrsar',
			'Vratišinec',
			'Višnjan - Visignano',
			'Trogir',
			'Topusko',
			'Tinjan',
			'Požega',
			'Satnica Ðakovačka',
			'Raša',
			'Popovac',
			'Piškorevci',
			'Oštarije',
			'Novska',
			'Metković',
			'Lučko',
			'Lopatinec',
			'Lopar',
			'Lišane Ostrovičke',
			'Labin',
			'Košute',
			'Kloštar Ivanić',
			'Kerestinec',
			'Breznički Hum',
			'Hrvatski Leskovac',
			'Gradište',
			'Generalski Stol',
			'Čazma',
			'Brodarica',
			'Buzdohanj',
			'Saršoni',
			'Zadar',
			'Vuka',
			'Vrhovine',
			'Viljevo',
			'Veprinac',
			'Tučepi',
			'Slavonski Kobaš',
			'Sinj',
			'Sali',
			'Reka',
			'Predavac',
			'Mokošica',
			'Marčelji',
			'Kuršanec',
			'Kraljevec na Sutli',
			'Kaštel Štafilić',
			'Karojba',
			'Kućan Gornji',
			'Ferdinandovac',
			'Dubravica',
			'Drenovci',
			'Drenova',
			'Donji Miholjac',
			'Davor',
			'Budaševo',
			'Bilice',
			'Bedenica',
			'Vidovci',
			'Nova Mokošica',
			'Ždralovi',
			'Vrpolje',
			'Seget Vranjica',
			'Vodice',
			'Vinkovci',
			'Velika Mlaka',
			'Turjaci',
			'Tovarnik',
			'Ston',
			'Sikirevci',
			'Rovinjsko Selo',
			'Rijeka',
			'Rab',
			'Petrijanec',
			'Orebić',
			'Okučani',
			'Mošćenica',
			'Mihovljan',
			'Matulji',
			'Luka Vrbovečka',
			'Lukavec',
			'Kaštel Lukšić',
			'Kali',
			'Grohote',
			'Gorjani',
			'Garešnica',
			'Dugopolje',
			'Dubrava',
			'Donji Seget',
			'Daruvar',
			'Breznica',
			'Bilje',
			'Stubičke Toplice',
			'Žrnovo',
			'Žrnovnica',
			'Varaždin Breg',
			'Tuhelj',
			'Trnava',
			'Stankovci',
			'Špišić-Bukovica',
			'Skrad',
			'Posedarje',
			'Nedeljanec',
			'Mali Bukovec',
			'Sveti Lovreč',
			'Lastovo',
			'Kumrovec',
			'Krnjak',
			'Klanjec',
			'Jelenje',
			'Ivanja Reka',
			'Ivanić-Grad',
			'Drniš',
			'Donji Vidovec',
			'Dobrinj',
			'Koprivnički Bregi',
			'Netretić',
			'Karlovac',
			'Mavrinci',
			'Lugarski Breg',
			'Veliko Selce',
			'Lipošćaki',
			'Sveti Ivan Žabno',
			'Vodnjan',
			'Velika Ludina',
			'Sveta Nedjelja',
			'Staro Petrovo Selo',
			'Škrljevo',
			'Rasinja',
			'Prigorje Brdovečko',
			'Pridraga',
			'Podhum',
			'Otok',
			'Kula Norinska',
			'Krk',
			'Klinča Sela',
			'Klana',
			'Kaštel Stari',
			'Kalinovac',
			'Ivanovac',
			'Jelsa',
			'Jagodnjak',
			'Hercegovac',
			'Gradec',
			'Fužine',
			'Dražice',
			'Donja Voća',
			'Đakovo',
			'Belica',
			'Bednja',
			'Zlatar bistrica',
			'Sveti Križ Začretje',
			'Virovitica',
			'Virje',
			'Štitar',
			'Sigetec',
			'Prgomet',
			'Prelog',
			'Poljanica Bistranska',
			'Polača',
			'Slatina',
			'Pleternica',
			'Orahovica',
			'Odranski Obrež',
			'Novo Čiče',
			'Ðulovac',
			'Lokve',
			'Kućan Marof',
			'Krasica',
			'Končanica',
			'Kaštel Kambelovac',
			'Jakšić',
			'Galižana',
			'Darda',
			'Cerovlje',
			'Banjol',
			'Bakar',
			'Krč Bosiljevski',
			'Žminj',
			'Sveti Petar u Šumi',
			'Strmec',
			'Stari Mikanovci',
			'Stara Gradiška',
			'Slobodnica',
			'Šišan',
			'Slavonski Šamac',
			'Rugvica',
			'Rovišće',
			'Poreč',
			'Pitomača',
			'Ogulin',
			'Odra',
			'Motovun',
			'Mošćenička Draga',
			'Marija Bistrica',
			'Kanfanar',
			'Dvor',
			'Dubrava',
			'Donja Bistra',
			'Bregana',
			'Brckovljani',
			'Bobota',
			'Mlinište',
			'Kopčevec',
			'Brezje',
			'Zdenci Brdovečki',
			'Vođinci',
			'Velika Kopanica',
			'Tordinci',
			'Slano',
			'Sisak',
			'Primorski Dolac',
			'Podturen',
			'Kraljevec Kupinečki',
			'Kašina',
			'Jesenice',
			'Gornja Bistra',
			'Feričanci',
			'Donje Ladanje',
			'Brtonigla',
			'Biograd na Moru',
			'Bebrina',
			'Novaki',
			'Kučine',
			'Veliko Polje',
			'Korčula',
			'Donji Muć',
			'Bizovac',
			'Berek',
			'Zagorska Sela',
			'Donji Kukuruzari',
			'Hrašćica',
			'Žumberak',
			'Žakanje',
			'Vinica',
			'Veliki Bukovec',
			'Sveta Marija',
			'Supetar',
			'Strahoninec',
			'Štefanje',
			'Stari Jankovci',
			'Srebreno',
			'Šandrovac',
			'Pula',
			'Podcrkavlje',
			'Petlovac',
			'Orehovica',
			'Sveti Petar Orehovec',
			'Mirkovci',
			'Milna',
			'Ližnjan',
			'Kršan',
			'Kravarsko',
			'Ježdovec',
			'Hrašće Turopoljsko',
			'Bjelovar',
			'Koprivnički Ivanec',
			'Voćin',
			'Slavonski Brod',
			'Sarvaš',
			'Rešetari',
			'Rakovec',
			'Otok',
			'Omišalj',
			'Nova Kapela',
			'Nijemci',
			'Marjanci',
			'Mačkovec',
			'Levanjska Varoš',
			'Kutjevo',
			'Kostrena',
			'Kijevo',
			'Kaštel Novi',
			'Josipovac',
			'Ilok',
			'Grožnjan',
			'Sveti Filip i Jakov',
			'Desinić',
			'Ðelekovec',
			'Cavtat',
			'Otrić-Seoci',
			'Postranje',
			'Naglići',
			'Visoko',
			'Veliki Grđevac',
			'Tar',
			'Slunj',
			'Šestanovac',
			'Senj',
			'Selnica',
			'Rude',
			'Pušćine',
			'Podstrana',
			'Ploče',
			'Otočac',
			'Oprtalj',
			'Nedeščina',
			'Maruševec',
			'Lovran',
			'Klis',
			'Kaštel Sućurac',
			'Kaptol',
			'Jesenice',
			'Gola',
			'Glina',
			'Dubrovnik',
			'Hrvatska Dubica',
			'Brela',
			'Crikvenica',
			'Čaglin',
			'Baška Voda',
			'Prosinec',
			'Voloder',
			'Trnovec Bartolovečki',
			'Sukošan',
			'Ribnik',
			'Repušnica',
			'Opatija',
			'Murter',
			'Mihovljan',
			'Mala Subotica',
			'Ludbreg',
			'Ladimirevci',
			'Kotoriba',
			'Jarmina',
			'Hvar',
			'Gornje Vratno',
			'Garčin',
			'Erdut',
			'Dugi Rat',
			'Cernik',
			'Brdovec',
			'Bosiljevo',
			'Biskupija',
			'Baška',
			'Gornji Stupnik',
			'Gornjaki',
			'Orišje',
			'Podstrana - Sita',
			'Sunja',
			'Gradina',
			'Smokvica',
			'Rovinj',
			'Rokovci',
			'Retkovci',
			'Novalja',
			'Markovac Našički',
			'Lovas',
			'Lasinja',
			'Duga Resa',
			'Novo Virje',
			'Buzin',
			'Buševec',
			'Bibinje',
			'Horvati',
			'Grabovci',
			'Magadenovac',
			'Podstrana - Žminjača',
			'Mače',
			'Lički Osik',
			'Kistanje',
			'Hodošan',
			'Donji Lapac',
			'Dalj',
			'Bijelo Brdo',
			'Županja',
			'Zabok',
			'Velika Ostrna',
			'Velika Gorica',
			'Trpinja',
			'Tkon',
			'Korenica',
			'Srinjine',
			'Skradin',
			'Pićan',
			'Petrovsko',
			'Omiš',
			'Novi Marof',
			'Novigrad',
			'Lanišće',
			'Križ',
			'Krapinske Toplice',
			'Krapina',
			'Kaštelir',
			'Karlobag',
			'Galovac',
			'Fažana',
			'Ðurmanec',
			'Cerna',
			'Cerić',
			'Čepin',
			'Beli Manastir',
			'Greda',
			'Gornji Vinjani',
			'Milanovac',
			'Savska Ves',
			'Sroki',
			'Doluš',
			'Vrbovec',
			'Vrbnik',
			'Vladislavci',
			'Vir',
			'Veliko Trojstvo',
			'Velika Pisanica',
			'Turanj',
			'Trpanj',
			'Starigrad',
			'Pirovac',
			'Peteranec',
			'Novo Selo Rok',
			'Nova Gradiška',
			'Molve',
			'Mikleuš',
			'Gradići',
			'Glavice',
			'Čakovec',
			'Brod Moravice',
			'Pucak',
			'Lokvičič',
			'Lećevica',
			'Kolan',
			'Kneževi Vinogradi',
			'Duće',
			'Draganići',
			'Sela Žakanjska',
			'Zlatar',
			'Veliko Trgovišće',
			'Velika',
			'Ugljan',
			'Sveti Ilija',
			'Štrigova',
			'Sračinec',
			'Split',
			'Škabrnje',
			'Sirač',
			'Povljana',
			'Pojatno',
			'Petrinja',
			'Oroslavje',
			'Novoselec',
			'Nova Rača',
			'Našice',
			'Ljubešćica',
			'Krašić',
			'Klakar',
			'Jakovlje',
			'Hum na Sutli',
			'Ervenik',
			'Ernestinovo',
			'Zemunik Donji',
			'Donji Proložac',
			'Kukljica',
			'Preko',
			'Korenica',
			'Zaprešić',
			'Dubrava',
			'Zagreb',
			'Varaždin',
			'Severin',
			'Rezovac',
			'Radoboj',
			'Podgora',
			'Pag',
			'Novigrad',
			'Njivice',
			'Mlini',
			'Lupoglav',
			'Klenovnik',
			'Horvati',
			'Gornji Mihaljevec',
			'Dragalić',
			'Donji Kraljevec',
			'Dežanovac',
			'Grljevac',
			'Konjšćina',
		],
	},
	Cuba: {
		country: 'Cuba',
		cities: [
			'San Juan y Martínez',
			'Limonar',
			'Lajas',
			'Guantánamo',
			'Fomento',
			'Florencia',
			'Carlos Manuel de Céspedes',
			'Caibarién',
			'Cabaiguán',
			'Topes de Collantes',
			'San Antonio de los Baños',
			'Placetas',
			'Manicaragua',
			'Chambas',
			'Caimito',
			'Bolondrón',
			'Abreus',
			'Varadero',
			'Santa Cruz del Norte',
			'Santa Clara',
			'Río Guayabal de Yateras',
			'Pilón',
			'Melena del Sur',
			'Majagua',
			'Jobabo',
			'Holguín',
			'Guanajay',
			'El Cobre',
			'Cruces',
			'Cerro',
			'Báguanos',
			'Sancti Spíritus',
			'Los Arabos',
			'La Sierpe',
			'Cabañas',
			'Jagüey Grande',
			'Cárdenas',
			'Vega Alta',
			'San Cristobal',
			'Regla',
			'Maisí',
			'Cauto Cristo',
			'La Habana Vieja',
			'Segundo Frente',
			'Remedios',
			'Cayo Mambí',
			'Alacranes',
			'Viñales',
			'Vertientes',
			'Unión de Reyes',
			'San Miguel del Padrón',
			'Morón',
			'Esperanza',
			'Corralillo',
			'San Luis',
			'Manzanillo',
			'Güines',
			'Guáimaro',
			'Bartolomé Masó',
			'Sibanicú',
			'Primero de Enero',
			'Niquero',
			'Madruga',
			'Jobabo',
			'Encrucijada',
			'Banes',
			'Artemisa',
			'Aguada de Moya',
			'Santa Lucía',
			'La Palma',
			'Jiguaní',
			'Guane',
			'Gaspar',
			'Esmeralda',
			'Luis Arcos Bergnes',
			'Trinidad',
			'Soroa',
			'Quemado de Güines',
			'Palma Soriano',
			'Los Palacios',
			'Isabela de Sagua',
			'Güira de Melena',
			'Zaza del Medio',
			'Santo Domingo',
			'Ranchuelo',
			'Pinar del Río',
			'Manatí',
			'Mañalich',
			'Camajuaní',
			'Baracoa',
			'Yaguajay',
			'Nueva Paz',
			'Cienfuegos',
			'Bejucal',
			'Batabanó',
			'Arroyo Naranjo',
			'Ciudad Camilo Cienfuegos',
			'San Germán',
			'Puerto Padre',
			'Najasa',
			'Cueto',
			'Contramaestre',
			'Condado',
			'Buey Arriba',
			'Antilla',
			'Alamar',
			'Diez de Octubre',
			'Rodas',
			'Palmira',
			'Mariel',
			'Jaruco',
			'Florida',
			'Río Cauto',
			'Nuevitas',
			'Minas de Matahambre',
			'Minas',
			'Mayarí',
			'Guayos',
			'Colombia',
			'Cifuentes',
			'Boyeros',
			'Rancho Veloz',
			'Perico',
			'Jatibonico',
			'Cumanayagua',
			'Colón',
			'Camagüey',
			'Calimete',
			'Bolivia',
			'Santiago de Cuba',
			'Santa Cruz del Sur',
			'Martí',
			'El Caney',
			'Bauta',
			'San Nicolás de Bari',
			'Las Tunas',
			'Centro Habana',
			'San Diego de Los Baños',
			'Matanzas',
			'Guara',
			'Camajuaní',
			'Cacocum',
			'Aguada de Pasajeros',
			'Yara',
			'Santiago de las Vegas',
			'Quivicán',
			'Manguito',
			'Jovellanos',
			'Bahía Honda',
			'Sagua la Grande',
			'Sagua de Tánamo',
			'Media Luna',
			'Puerto Esperanza',
			'Guanabacoa',
			'San Luis',
			'San José de las Lajas',
			'San Antonio del Sur',
			'Palenque',
			'Nueva Gerona',
			'Mantua',
			'Havana',
			'Consolación del Sur',
			'Ciro Redondo',
			'Ciego de Ávila',
			'Campechuela',
			'Baraguá',
			'Moa',
			'La Salud',
			'Jesús Menéndez',
			'Guisa',
			'Calabazar de Sagua',
			'Pedro Betancourt',
			'Bayamo',
			'Alquízar',
			'Cubitas',
			'La Máquina',
			'Venezuela',
			'Imías',
			'Candelaria',
			'Calixto',
			'Jimaguayú',
			'Taguasco',
			'Jamaica',
			'Gibara',
			'Amancio',
		],
	},
	Cyprus: {
		country: 'Cyprus',
		cities: [
			'Pégeia',
			'Leonárisso',
			'Lythrodóntas',
			'Ergátes',
			'Argáka',
			'Tríkomo',
			'Psimolofou',
			'Pérgamos',
			'Mesógi',
			'Derýneia',
			'Chlórakas',
			'Famagusta',
			'Astromerítis',
			'Kíti',
			'Avgórou',
			'Tsáda',
			'Pýla',
			'Peléndri',
			'Larnaca',
			'Kórnos',
			'Káto Pýrgos',
			'Alámpra',
			'Geroskípou (quarter)',
			'Meládeia',
			'Kolossi',
			'Méniko',
			'Meneou',
			'Ágios Týchon',
			'Sotíra',
			'Pissoúri',
			'Mosfilotí',
			'Klírou',
			'Xylofágou',
			'Troúlloi',
			'Tersefánou',
			'Kofínou',
			'Limassol',
			'Dhromolaxia',
			'Nicosia',
			'Kokkinotrimithiá',
			'Frénaros',
			'Kyperoúnta',
			'Lefkónoiko',
			'Pólis',
			'Páno Defterá',
			'Páchna',
			'Ayia Napa',
			'Geroskipou',
			'Aredioú',
			'Emba',
			'Rizokárpaso',
			'Polémi',
			'Parekklisha',
			'Mórfou',
			'Lápithos',
			'Ýpsonas',
			'Lýmpia',
			'Kyrenia',
			'Voróklini',
			'Perivólia',
			'Mámmari',
			'Livádia',
			'Koloni',
			'Akáki',
			'Tróodos',
			'Athíenou',
			'Psevdás',
			'Géri',
			'Xylotymbou',
			'Pyrgos',
			'Páno Polemídia',
			'Liopétri',
			'Léfka',
			'Kakopetriá',
			'Tála',
			'Péra',
			'Erími',
			'Acherítou',
			'Tsada - Tremithousa - Tala (Borders)',
			'Protaras',
			'Paphos',
			'Kissonerga',
			'Tséri',
			'Stróvolos',
			'Peristeróna',
			'Paralímni',
			'Mouttagiáka',
			'Konia',
			'Káto Defterá',
			'Dáli',
			'Áchna',
			'Aradíppou',
		],
	},
	'Czech Republic': {
		country: 'Czech Republic',
		cities: [
			'Zlonice',
			'Všeruby',
			'Velký Újezd',
			'Třebíč',
			'Sudkov',
			'Střelice',
			'Soběslav',
			'Skuteč',
			'Šestajovice',
			'Rybitví',
			'Rtyně v Podkrkonoší',
			'Přerov nad Labem',
			'Police nad Metují',
			'Podolí',
			'Opařany',
			'Oloví',
			'Oldřišov',
			'Nová Role',
			'Mšeno',
			'Meziboři',
			'Markvartovice',
			'Lukavec',
			'Lidečko',
			'Lázně Bělohrad',
			'Lanškroun',
			'Krucemburk',
			'Králíky',
			'Kašperské Hory',
			'Jistebnice',
			'Jedlová',
			'Janov nad Nisou',
			'Hrádek',
			'Hovorany',
			'Hostouň',
			'Hostomice',
			'Horní Počaply',
			'Drnovice',
			'Dolní Beřkovice',
			'Chvalšiny',
			'Březová nad Svitavou',
			'Bochov',
			'Blažovice',
			'Volary',
			'Velké Opatovice',
			'Velká Polom',
			'Valtice',
			'Stará Ves nad Ondřejnicí',
			'Solnice',
			'Prosek',
			'Proboštov',
			'Pražmo',
			'Olšany',
			'Nový Bydžov',
			'Mořkov',
			'Mokré Lazce',
			'Mníšek pod Brdy',
			'Miřetice',
			'Lomnice nad Lužnicí',
			'Lanžhot',
			'Kryry',
			'Karlovy Vary',
			'Jistebník',
			'Hostěradice',
			'Horoměřice',
			'Hnojník',
			'Grygov',
			'Domanín',
			'Dolní Újezd',
			'Dobšice',
			'Davle',
			'Chlumčany',
			'Český Rudolec',
			'Červenka',
			'Bukovany',
			'Božice',
			'Borová Lada',
			'Blučina',
			'Bezdružice',
			'Beroun',
			'Dvůr Králové nad Labem',
			'Žleby',
			'Žebrák',
			'Zdiby',
			'Velký Osek',
			'Velké Meziříčí',
			'Velká Hleďsebe',
			'Vacenovice',
			'Třeboň',
			'Tečovice',
			'Tasovice',
			'Studená',
			'Stochov',
			'Starý Kolín',
			'Staré Město',
			'Staré Hradiště',
			'Šlapanice',
			'Ronov nad Doubravou',
			'Psáry',
			'Prušánky',
			'Přibyslav',
			'Prachovice',
			'Pecka',
			'Ostroměř',
			'Osek',
			'Opatovice nad Labem',
			'Nové Syrovice',
			'Mosty u Jablunkova',
			'Mostek',
			'Mladé Buky',
			'Lipůvka',
			'Konice',
			'Klimkovice',
			'Karolinka',
			'Jihlava',
			'Hradec nad Svitavou',
			'Hošťka',
			'Horní Štěpánov',
			'Horní Sloupnice',
			'Horní Bříza',
			'Dub nad Moravou',
			'Domažlice',
			'Dolní Bousov',
			'Červený Kostelec',
			'Čerčany',
			'Bystřice nad Pernštejnem',
			'Břeclav',
			'Blížejov',
			'Bílovice nad Svitavou',
			'Bílá Třemešná',
			'Benešov',
			'Vnorovy',
			'Vimperk',
			'Vidnava',
			'Velký Beranov',
			'Varnsdorf',
			'Troubelice',
			'Třemošná',
			'Svitávka',
			'Studenec',
			'Strážnice',
			'Štěpánov',
			'Sedlice',
			'Šanov',
			'Proseč',
			'Otice',
			'Osečná',
			'Modřany',
			'Lubenec',
			'Loštice',
			'Lom u Mostu',
			'Libeň',
			'Libavské Údolí',
			'Kovářská',
			'Kout na Šumavě',
			'Kamenice',
			'Jirkov',
			'Hroznová Lhota',
			'Hrotovice',
			'Horní Stropnice',
			'Horní Moštěnice',
			'Horní Bečva',
			'Holýšov',
			'Hněvošice',
			'Dolní Černilov',
			'Doksy',
			'Brněnec',
			'Borek',
			'Všetaty',
			'Vojkovice',
			'Vladislav',
			'Úpice',
			'Uničov',
			'Tvarožná',
			'Trutnov',
			'Traplice',
			'Svratka',
			'Rýmařov',
			'Rapotín',
			'Příšovice',
			'Příbor',
			'Prague',
			'Počátky',
			'Písek',
			'Osík',
			'Nový Knín',
			'Miroslav',
			'Luby',
			'Liptál',
			'Lipovec',
			'Lipov',
			'Libiš',
			'Komárov',
			'Kobeřice',
			'Kadaň',
			'Jilemnice',
			'Husinec',
			'Hronov',
			'Hrádek',
			'Horní Počernice',
			'Herálec',
			'Frýdlant nad Ostravicí',
			'Frýdlant',
			'Dolní Lhota',
			'Dolní Čermná',
			'Chropyně',
			'Choceň',
			'Černošín',
			'Byšice',
			'Brno',
			'Březová',
			'Žeravice',
			'Zbiroh',
			'Zašová',
			'Verneřice',
			'Velké Hoštice',
			'Velehrad',
			'Včelná',
			'Uherské Hradiště',
			'Týniště nad Orlicí',
			'Trhový Štěpánov',
			'Trhové Sviny',
			'Tlučná',
			'Suchohrdly',
			'Štítná nad Vláří',
			'Roztoky',
			'Rajhradice',
			'Příkazy',
			'Přerov',
			'Poniklá',
			'Polná',
			'Polepy',
			'Podivín',
			'Plánice',
			'Obecnice',
			'Nové Hrady',
			'Nová Bystřice',
			'Neveklov',
			'Nejdek',
			'Mrákov',
			'Moravany',
			'Mistřice',
			'Mirovice',
			'Městec Králové',
			'Merklín',
			'Měcholupy',
			'Malá Strana',
			'Litoměřice',
			'Líbeznice',
			'Kunín',
			'Krnov',
			'Klenčí pod Čerchovem',
			'Kladno',
			'Kasejovice',
			'Holasovice',
			'Františkovy Lázně',
			'Dolní Loućky',
			'Chroustovice',
			'Chrást',
			'Černovice',
			'Boršice',
			'Borohrádek',
			'Adamov',
			'Zlaté Hory',
			'Zbůch',
			'Vřesina',
			'Vrbice',
			'Višňova',
			'Vilémov',
			'Větřní',
			'Velká Bíteš',
			'Velešín',
			'Těšany',
			'Sudoměřice',
			'Strakonice',
			'Stěbořice',
			'Stará Paka',
			'Spytihněv',
			'Samotíšky',
			'Rudná',
			'Rataje',
			'Radslavice',
			'Radnice',
			'Pustá Polom',
			'Pozlovice',
			'Petrov',
			'Pardubice',
			'Olomouc',
			'Nový Malín',
			'Nosislav',
			'Nehvizdy',
			'Milovice',
			'Město Touškov',
			'Loděnice',
			'Líně',
			'Kynšperk nad Ohří',
			'Kyjov',
			'Křešice',
			'Křemže',
			'Krásná Lípa',
			'Kralice na Hané',
			'Košťálov',
			'Kolín',
			'Jaroslavice',
			'Jablonné v Podještědí',
			'Hulín',
			'Hrušky',
			'Horní Město',
			'Habartov',
			'Fryčovice',
			'Desná',
			'Dalovice',
			'Český Brod',
			'Červené Pečky',
			'Buštěhrad',
			'Březolupy',
			'Braník',
			'Bohutín',
			'Bílá Lhota',
			'Žulová',
			'Zbýšov',
			'Zákupy',
			'Zaječí',
			'Vyškov',
			'Veřovice',
			'Vamberk',
			'Valdice',
			'Troubsko',
			'Trmice',
			'Třebechovice pod Orebem',
			'Terezín',
			'Tachov',
			'Svoboda nad Úpou',
			'Suchdol nad Lužnicí',
			'Šternberk',
			'Sokolov',
			'Smiřice',
			'Skuhrov nad Bělou',
			'Šilheřovice',
			'Sepekov',
			'Semily',
			'Rotava',
			'Rokycany',
			'Řež',
			'Ráječko',
			'Protivanov',
			'Předklášteří',
			'Plesná',
			'Otnice',
			'Ostrov',
			'Ostrava',
			'Oslavany',
			'Olešnice',
			'Ohrazenice',
			'Nýrsko',
			'Milotice',
			'Meziměstí',
			'Měčín',
			'Libice nad Cidlinou',
			'Liberec',
			'Letňany',
			'Karlín',
			'Jakartovice',
			'Jablonec nad Jizerou',
			'Hroznětín',
			'Hradištko',
			'Hrádek',
			'Horní Čermná',
			'Horní Branná',
			'Hodkovice nad Mohelkou',
			'Francova Lhota',
			'Doloplazy',
			'Dolní Sloupnice',
			'Dolany',
			'Dambořice',
			'Chotěbuz',
			'Čelechovice na Hané',
			'Buchlovice',
			'Brtnice',
			'Broumov',
			'Brloh',
			'Bolatice',
			'Bludov',
			'Vilémov',
			'Veverská Bítýška',
			'Velké Pavlovice',
			'Tovačov',
			'Tišnov',
			'Štěchovice',
			'Staré Město',
			'Staré Křečany',
			'Slavkov',
			'Sedlnice',
			'Řečany nad Labem',
			'Prostějov',
			'Plaňany',
			'Planá nad Lužnicí',
			'Paskov',
			'Osoblaha',
			'Okrouhlice',
			'Moutnice',
			'Mladá Boleslav',
			'Milíkov',
			'Litvínov',
			'Kvasiny',
			'Krumvíř',
			'Kroměříž',
			'Kokory',
			'Klatovy',
			'Kbely',
			'Jindřichův Hradec',
			'Hrušovany nad Jevišovkou',
			'Horní Jiřetín',
			'Hodslavice',
			'Hlučín',
			'Herálec',
			'Dolní Újezd',
			'Dobroměřice',
			'Dětmarovice',
			'Cvikov',
			'Čejč',
			'Albrechtice nad Orlicí',
			'Vysehrad',
			'Žlutice',
			'Zásmuky',
			'Žacléř',
			'Vyšší Brod',
			'Veselí nad Moravou',
			'Veselí nad Lužnicí',
			'Velké Přílepy',
			'Vejprty',
			'Týnec nad Labem',
			'Těšetice',
			'Stod',
			'Staré Nechanice',
			'Rudolfov',
			'Rožnov pod Radhoštěm',
			'Řehlovice',
			'Rakvice',
			'Přeštice',
			'Otaslavice',
			'Ostopovice',
			'Ořechov',
			'Nové Město pod Smrkem',
			'Němčice nad Hanou',
			'Náchod',
			'Načeradec',
			'Mohelnice',
			'Mladá Vožice',
			'Mikulášovice',
			'Majetín',
			'Lomnice',
			'Lichnov (o. Nový Jičín)',
			'Kralupy nad Vltavou',
			'Kněžice',
			'Jablunkov',
			'Horní Těrlicko',
			'Horní Bludovice',
			'Hanušovice',
			'Dýšina',
			'Duchcov',
			'Dolní Životice',
			'Dolní Žandov',
			'Dolní Cerekev',
			'Doksy',
			'Dobrá',
			'Čížkovice',
			'Chvaletice',
			'Černošice',
			'Brandýs nad Orlicí',
			'Blovice',
			'Bečov',
			'Lázně Bohdaneč',
			'Žihle',
			'Vrdy',
			'Vranovice',
			'Veltruby',
			'Strážov',
			'Strážná',
			'Štěpánov',
			'Špindlerův Mlýn',
			'Ševětín',
			'Říčany',
			'Radonice',
			'Povrly',
			'Poříčany',
			'Planá',
			'Petrovice u Karviné',
			'Pacov',
			'Osek',
			'Nová Paka',
			'Nižbor',
			'Moravský Krumlov',
			'Mníšek',
			'Mnichovo Hradiště',
			'Lučany nad Nisou',
			'Košťany',
			'Kopidlno',
			'Klobuky',
			'Jičín',
			'Hvozdná',
			'Horšovský Týn',
			'Horní Jelení',
			'Dubňany',
			'Dolní Poustevna',
			'Dolní Dvořiště',
			'Čimelice',
			'Chyšky',
			'Chotoviny',
			'Chlumec',
			'České Velenice',
			'Čáslav',
			'Černý Most',
			'Želiv',
			'Žamberk',
			'Všestary',
			'Vratimov',
			'Viničné Šumice',
			'Velký Šenov',
			'Velká Bystřice',
			'Telč',
			'Stráž pod Ralskem',
			'Šluknov',
			'Sezimovo Ústí',
			'Satalice',
			'Šakvice',
			'Příbram',
			'Přáslavice',
			'Poříčí nad Sázavou',
			'Petřvald',
			'Paseka',
			'Nová Včelnice',
			'Nedašov',
			'Nasavrky',
			'Moravský Písek',
			'Mikulčice',
			'Měnín',
			'Luže',
			'Lukov',
			'Loučná nad Desnou',
			'Lomnice',
			'Lípa',
			'Libčice nad Vltavou',
			'Kunčice pod Ondřejníkem',
			'Kouřim',
			'Komorní Lhotka',
			'Kardašova Řečice',
			'Kaplice',
			'Josefův Důl',
			'Jirny',
			'Jílové u Prahy',
			'Jevišovice',
			'Jemnice',
			'Jaroměřice nad Rokytnou',
			'Janovice nad Úhlavou',
			'Hromnice',
			'Hostomice',
			'Horažďovice',
			'Harrachov',
			'Dobrovice',
			'Dobratice',
			'Chuchelná',
			'Chrudim',
			'Chodová Planá',
			'Cerhenice',
			'Čeladná',
			'Bystřec',
			'Bukovec',
			'Blatná',
			'Benecko',
			'Benátky nad Jizerou',
			'Zadní Mostek',
			'Zábřeh',
			'Vrchlabí',
			'Volyně',
			'Velké Losiny',
			'Tuchoměřice',
			'Tábor',
			'Suchdol',
			'Smržice',
			'Rosice',
			'Raškovice',
			'Radomyšl',
			'Plasy',
			'Ostrov u Macochy',
			'Ostravice',
			'Nové Město nad Metují',
			'Nivnice',
			'Nemanice',
			'Lipník nad Bečvou',
			'Libochovice',
			'Kutná Hora',
			'Kunžak',
			'Kunštát',
			'Krásná Lípa',
			'Kopřivnice',
			'Kolinec',
			'Jindřichov',
			'Jesenice',
			'Ivančice',
			'Hrušovany u Brna',
			'Hostavice',
			'Hlubočky',
			'Hlinsko',
			'Dubicko',
			'Dolní Studénky',
			'Černilov',
			'Bzenec',
			'Žitenice',
			'Zdounky',
			'Zborovice',
			'Vroutek',
			'Vracov',
			'Věrovany',
			'Studénka',
			'Rychvald',
			'Rudník',
			'Rohatec',
			'Pečky',
			'Pavlíkov',
			'Moravský Žižkov',
			'Morávka',
			'Moravičany',
			'Meclov',
			'Kořenov',
			'Kelč',
			'Jevíčko',
			'Jarošov nad Nežárkou',
			'Hostinné',
			'Heřmanova Huť',
			'Dřiteň',
			'Dolní Dobrouč',
			'Cítov',
			'Chvalčov',
			'Březová',
			'Břasy',
			'Bernartice',
			'Bělotín',
			'Vlachovo Březí',
			'Veltrusy',
			'Velké Poříčí',
			'Úsov',
			'Určice',
			'Tršice',
			'Strašice',
			'Řepiště',
			'Ptení',
			'Prusinovice',
			'Přemyslovice',
			'Nové Sedlo',
			'Neratovice',
			'Napajedla',
			'Mýto',
			'Metylovice',
			'Měšice',
			'Lichnov',
			'Letovice',
			'Krouna',
			'Kladruby',
			'Kačice',
			'Hrochův Týnec',
			'Hřebeč',
			'Dolní Rychnov',
			'Dolní Počernice',
			'Dolní Bohdíkov',
			'Dobřichovice',
			'Čebín',
			'Březí',
			'Provodov-Šonov',
			'Zvole',
			'Zruč nad Sázavou',
			'Žirovnice',
			'Železný Brod',
			'Zeleneč',
			'Velké Němčice',
			'Velké Březno',
			'Třinec',
			'Šumice',
			'Strunkovice nad Blanicí',
			'Slavkov u Brna',
			'Skrbeň',
			'Rožďalovice',
			'Rouchovany',
			'Rokytnice',
			'Poběžovice',
			'Pilsen',
			'Písečná',
			'Ostrožská Nová Ves',
			'Machov',
			'Lysice',
			'Loučeň',
			'Kravaře',
			'Kněžmost',
			'Klecany',
			'Karviná',
			'Jedovnice',
			'Jaroměřice',
			'Jaroměř',
			'Hustopeče Nad Bečvou',
			'Hostivice',
			'Hlohovec',
			'Habry',
			'Frýdek-Místek',
			'Dobruška',
			'Dlouhá Třebová',
			'Chýnov',
			'Chotěboř',
			'Chabařovice',
			'Česká Lípa',
			'Brandýsek',
			'Bohdalov',
			'Bělá nad Radbuzou',
			'Albrechtice',
			'Žlutava',
			'Zbraslavice',
			'Votice',
			'Vlašim',
			'Vizovice',
			'Valašské Klobouky',
			'Vacov',
			'Ústí nad Labem',
			'Újezd',
			'Turnov',
			'Třebenice',
			'Toužim',
			'Štítina',
			'Smečno',
			'Slavonice',
			'Sedliště',
			'Sedlec',
			'Sadská',
			'Ratiboř',
			'Rajhrad',
			'Pustiměř',
			'Protivín',
			'Předměřice nad Labem',
			'Pozořice',
			'Nový Bor',
			'Moravský Beroun',
			'Mnichovice',
			'Mimoň',
			'Mělník',
			'Luka nad Jihlavou',
			'Lhenice',
			'Letonice',
			'Letohrad',
			'Ledeč nad Sázavou',
			'Kunvald',
			'Kralovice',
			'Kozmice',
			'Klobouky',
			'Klášterec nad Ohří',
			'Jiříkov',
			'Jílové',
			'Jablonec nad Nisou',
			'Hustopeče',
			'Hranice',
			'Hradec Králové',
			'Horní Cerekev',
			'Hejnice',
			'Golčův Jeníkov',
			'Fryšták',
			'Dubné',
			'Chlum u Třeboně',
			'Česká Ves',
			'Bystřice',
			'Bystřany',
			'Budyně nad Ohří',
			'Abertamy',
			'Žabčice',
			'Všenory',
			'Vřesina',
			'Vestec',
			'Velké Popovice',
			'Velká Dobrá',
			'Václavovice',
			'Tišice',
			'Teplice',
			'Sviadnov',
			'Stráž',
			'Slavičín',
			'Slaný',
			'Seč',
			'Prostřední Bečva',
			'Písek',
			'Perštejn',
			'Pěnčín',
			'Otrokovice',
			'Oskava',
			'Odolena Voda',
			'Nový Hrozenkov',
			'Nové Veselí',
			'Nová Cerekev',
			'Netolice',
			'Mohelno',
			'Milín',
			'Malonty',
			'Lužice',
			'Loučovice',
			'Kožlany',
			'Kamenné Žehrovice',
			'Hradec nad Moravicí',
			'Hovorčovice',
			'Horní Suchá',
			'Holoubkov',
			'Hluk',
			'Halenkovice',
			'Dolní Bojanovice',
			'Darkovice',
			'Chomutov',
			'Cheb',
			'Česká Skalice',
			'Býšť',
			'Brumovice',
			'Brantice',
			'Bošovice',
			'Borovany',
			'Bohuslavice',
			'Benešov nad Černou',
			'Zlín',
			'Želetava',
			'Zastávka',
			'Žandov',
			'Vysoké Mýto',
			'Vysočany',
			'Všemina',
			'Vítkov',
			'Vikýřovice',
			'Velké Hamry',
			'Valašská Polanka',
			'Týnec',
			'Trojanovice',
			'Suchá Loz',
			'Strání',
			'Starý Jičín',
			'Skalná',
			'Sezemice',
			'Rychnov nad Kněžnou',
			'Rumburk',
			'Rosice',
			'Říčany',
			'Řevnice',
			'Radvanice',
			'Postřekov',
			'Pomezí',
			'Polička',
			'Pelhřimov',
			'Ostrožská Lhota',
			'Nýdek',
			'Modřice',
			'Měřín',
			'Luhačovice',
			'Louny',
			'Kostice',
			'Kostelec nad Labem',
			'Klášter',
			'Kaznějov',
			'Katovice',
			'Karlovice',
			'Jalubí',
			'Ivanovice na Hané',
			'Hukvaldy',
			'Hrádek nad Nisou',
			'Hluboká nad Vltavou',
			'Heřmanův Městec',
			'Hazlov',
			'Havlíčkův Brod',
			'Hartmanice',
			'Fulnek',
			'Děčín',
			'Český Krumlov',
			'České Meziříčí',
			'Česká Třebová',
			'Budišov',
			'Březová',
			'Braňany',
			'Bedihošť',
			'Batelov',
			'Zlechov',
			'Želešice',
			'Vlachovice',
			'Višňové',
			'Třebotov',
			'Tlumačov',
			'Staré Město',
			'Šitbořice',
			'Přelouč',
			'Potštát',
			'Popovice',
			'Obrnice',
			'Nymburk',
			'Novosedlice',
			'Nové Město na Moravě',
			'Teplá',
			'Medlov',
			'Malšice',
			'Ludgeřovice',
			'Lochovice',
			'Litomyšl',
			'Libáň',
			'Křepice',
			'Jesenice',
			'Janovice',
			'Hýskov',
			'Humpolec',
			'Hošťálková',
			'Chrastava',
			'Chlebičov',
			'Červená Voda',
			'Bučovice',
			'Březnice',
			'Bořetice',
			'Bojkovice',
			'Blížkovice',
			'Bechlín',
			'Bartošovice',
			'Žiželice',
			'Zdice',
			'Vlčnov',
			'Velké Karlovice',
			'Valdice',
			'Unhošť',
			'Tvrdonice',
			'Teplice nad Metují',
			'Telnice',
			'Stráž nad Nisou',
			'Starý Plzenec',
			'Sázava',
			'Rousínov',
			'Raspenava',
			'Rakovník',
			'Podbořany',
			'Odry',
			'Novosedly',
			'Mostkovice',
			'Mariánské Lázně',
			'Lysá nad Labem',
			'Lutín',
			'Lukavice',
			'Lešná',
			'Lednice',
			'Lány',
			'Krmelín',
			'Kosmonosy',
			'Koryčany',
			'Kněždub',
			'Kamenický Šenov',
			'Jince',
			'Jimramov',
			'Hrob',
			'Horka nad Moravou',
			'Holešov',
			'Drahanovice',
			'Doubravice nad Svitavou',
			'Dolní Benešov',
			'Dašice',
			'Český Dub',
			'Černožice',
			'Černá Hora',
			'Čelákovice',
			'Bystré',
			'Budišov nad Budišovkou',
			'Břidličná',
			'Březno',
			'Bílovec',
			'Bělá pod Bezdězem',
			'Baška',
			'Aš',
			'Vrbno pod Pradědem',
			'Vodňany',
			'Vintířov',
			'Vinařice',
			'Velká nad Veličkou',
			'Úvaly',
			'Trnava',
			'Strmilov',
			'Štíty',
			'Stará Huť',
			'Slatiňany',
			'Šatov',
			'Řevničov',
			'Přimda',
			'Polešovice',
			'Plumlov',
			'Peruc',
			'Nýřany',
			'Nedakonice',
			'Mukařov',
			'Kojetín',
			'Kdyně',
			'Hranice',
			'Hodonice',
			'Hevlín',
			'Háj ve Slezsku',
			'Dolní Lutyně',
			'Dolní Kounice',
			'Dobřany',
			'Čkyně',
			'Chrast',
			'Český Těšín',
			'Častolovice',
			'Bystřice',
			'Brniště',
			'Bohumín',
			'Bílovice',
			'Bechyně',
			'Babice',
			'Zubří',
			'Znojmo',
			'Žďár',
			'Vendryně',
			'Velké Svatoňovice',
			'Vápenná',
			'Týnec nad Sázavou',
			'Topolná',
			'Stachy',
			'Spálené Poříčí',
			'Sobotka',
			'Šenov',
			'Ropice',
			'Rokytnice nad Jizerou',
			'Rájec-Jestřebí',
			'Pyšely',
			'Postupice',
			'Píšť',
			'Petřvald',
			'Petrovice',
			'Pchery',
			'Opatov',
			'Olbramovice',
			'Náměšť nad Oslavou',
			'Most',
			'Leština',
			'Lelekovice',
			'Kuřim',
			'Krupka',
			'Kostelec nad Orlicí',
			'Hodonín',
			'Drásov',
			'Dolní Němčí',
			'Dolní Dunajovice',
			'Dobříš',
			'České Budějovice',
			'Bohušovice nad Ohří',
			'Bánov',
			'Židlochovice',
			'Železnice',
			'Žatec',
			'Vrbovec',
			'Vrbátky',
			'Vidče',
			'Velvary',
			'Velké Bílovice',
			'Velemín',
			'Úštěk',
			'Uherský Ostroh',
			'Údlice',
			'Týn nad Vltavou',
			'Švihov',
			'Štěnovice',
			'Smržovka',
			'Rovensko pod Troskami',
			'Palkovice',
			'Osek nad Bečvou',
			'Orlová',
			'Nesovice',
			'Nepomuk',
			'Mutěnice',
			'Loket',
			'Kvasice',
			'Křižanov',
			'Kraslice',
			'Kájov',
			'Horní Slavkov',
			'Hořice',
			'Holice',
			'Drnovice',
			'Dolní Břežany',
			'Dobronín',
			'Divišov',
			'Brušperk',
			'Železná Ruda',
			'Zbraslav',
			'Zaječov',
			'Záhorovice',
			'Zabrušany',
			'Vysoké nad Jizerou',
			'Vlkoš',
			'Velim',
			'Únanov',
			'Újezd',
			'Tichá',
			'Tanvald',
			'Šumperk',
			'Střížkov',
			'Stříbro',
			'Štramberk',
			'Stonava',
			'Staříč',
			'Staňkov',
			'Sedlčany',
			'Ruda nad Moravou',
			'Ondřejov',
			'Okříšky',
			'Nové Strašecí',
			'Nechanice',
			'Náměšť na Hané',
			'Moravská Třebová',
			'Mokrá Hora',
			'Mirošov',
			'Milevsko',
			'Město Albrechtice',
			'Lovosice',
			'Křenovice',
			'Králův Dvůr',
			'Kostelec nad Černými Lesy',
			'Kosova Hora',
			'Kobylí',
			'Kněžpole',
			'Jáchymov',
			'Jablůnka',
			'Hudlice',
			'Hrabyně',
			'Hodkovičky',
			'Hněvotín',
			'Hať',
			'Dubí',
			'Doudleby nad Orlicí',
			'Doubrava',
			'Chotěšov',
			'Bystřice pod Hostýnem',
			'Brandýs nad Labem-Stará Boleslav',
			'Benešov nad Ploučnicí',
			'Lučina',
			'Žďár nad Sázavou',
			'Vrbice',
			'Vrané nad Vltavou',
			'Velký Týnec',
			'Uhlířské Janovice',
			'Třemošnice',
			'Světlá nad Sázavou',
			'Strančice',
			'Stařeč',
			'Sokolnice',
			'Sobotín',
			'Slušovice',
			'Sadov',
			'Roudnice nad Labem',
			'Ratíškovice',
			'Prachatice',
			'Postřelmov',
			'Opava',
			'Netvořice',
			'Nedvědice',
			'Mirotice',
			'Mikulovice',
			'Město Libavá',
			'Městečko Trnávka',
			'Manětín',
			'Malá Skála',
			'Luštěnice',
			'Lenešice',
			'Křinec',
			'Kamenný Přívoz',
			'Jeseník nad Odrou',
			'Javorník',
			'Jablonné nad Orlicí',
			'Hořovice',
			'Horní Planá',
			'Horní Lideč',
			'Frenštát pod Radhoštěm',
			'Dubá',
			'Drnholec',
			'Dřevohostice',
			'Dolní Bečva',
			'Dobřív',
			'Dačice',
			'Chyňava',
			'Chřibská',
			'Česká Kamenice',
			'Černčice',
			'Čejkovice',
			'Čechtice',
			'Brodek u Přerova',
			'Boskovice',
			'Bořitov',
			'Bílina',
			'Zliv',
			'Ždánice',
			'Zátor',
			'Vilémov',
			'Vejprnice',
			'Valašské Meziříčí',
			'Ústí nad Orlicí',
			'Uherský Brod',
			'Tupesy',
			'Tuchlovice',
			'Třešť',
			'Štoky',
			'Starý Bohumín',
			'Šťáhlavy',
			'Slatinice',
			'Senice na Hané',
			'Sedlice',
			'Roztoky',
			'Radiměř',
			'Poděbrady',
			'Pilníkov',
			'Opočno',
			'Nezvěstice',
			'Náklo',
			'Mikulov',
			'Malé Svatoňovice',
			'Lužec nad Vltavou',
			'Litovel',
			'Ledenice',
			'Lázně Kynžvart',
			'Kunovice',
			'Kunčina',
			'Krásná Hora nad Vltavou',
			'Kovářov',
			'Kamenice',
			'Jeseník',
			'Jeneč',
			'Hrdějovice',
			'Hovězí',
			'Horní Benešov',
			'Havířov',
			'Halenkov',
			'Dolní Roveň',
			'Dolní Bukovsko',
			'Církvice',
			'Chodov',
			'Bouzov',
			'Bohuňovice',
			'Bavorov',
			'Zdíkov',
			'Vsetín',
			'Vlčnov',
			'Velké Heraltice',
			'Valašská Bystřice',
			'Troubky',
			'Svitavy',
			'Svatava',
			'Sušice',
			'Stěžery',
			'Štětí',
			'Štěpánkovice',
			'Srubec',
			'Smidary',
			'Šardice',
			'Rybí',
			'Rožmitál pod Třemšínem',
			'Rokytnice v Orlických Horách',
			'Průhonice',
			'Postoloprty',
			'Pohořelice',
			'Plavy',
			'Nový Jičín',
			'Nezamyslice',
			'Nelahozeves',
			'Návsí u Jablunkova',
			'Moravské Budějovice',
			'Moravská Nová Ves',
			'Moravany',
			'Merklín',
			'Lužná',
			'Lomnice nad Popelkou',
			'Litvínovice',
			'Lišov',
			'Libušín',
			'Libouchec',
			'Liběšice',
			'Kozlovice',
			'Kostelec na Hané',
			'Kamenný Újezd',
			'Kamenice nad Lipou',
			'Frymburk',
			'Dvorce',
			'Dolní Podluží',
			'Chlumec nad Cidlinou',
			'Bruntál',
			'Březnice',
			'Bor',
			'Blansko',
			'Bakov nad Jizerou',
		],
	},
	Denmark: {
		country: 'Denmark',
		cities: [
			'Tylstrup',
			'Tranekær',
			'Struer',
			'Sæby',
			'Rørvig',
			'Rødbyhavn',
			'Neder Vindinge',
			'Løgten',
			'Lemvig',
			'Glamsbjerg',
			'Give',
			'Galten',
			'Fensmark',
			'Elling',
			'Egtved',
			'Birkerød',
			'Assens',
			'Århus',
			'Vindinge',
			'Thisted',
			'Stege',
			'Skanderborg',
			'Ørum',
			'Ølsted',
			'Låsby',
			'Højer',
			'Hjortshøj',
			'Glostrup',
			'Frederikssund',
			'Farsø',
			'Vinderup',
			'Vindeby',
			'Tommerup Stationsby',
			'Thurø By',
			'Taulov',
			'Svogerslev',
			'Svinninge',
			'Sunds',
			'Sabro',
			'Rønde',
			'Nødebo',
			'Kvistgård',
			'Knabstrup',
			'Kjellerup',
			'Jyllinge',
			'Juelsminde',
			'Jægerspris',
			'Humlebæk',
			'Holsted',
			'Haslev',
			'Hanstholm',
			'Framlev',
			'Ejby',
			'Christianshavn',
			'Tinglev',
			'Stoholm',
			'Stavtrup',
			'Rødvig',
			'Løsning',
			'Holeby',
			'Gistrup',
			'Fjerritslev',
			'Faxe',
			'Brøndbyvester',
			'Bramming',
			'Aalborg',
			'Vissenbjerg',
			'Væggerløse',
			'Ullerslev',
			'Tistrup',
			'Skals',
			'Løgstrup',
			'Hornbæk',
			'Hellebæk',
			'Fløng',
			'Avlum',
			'Assentoft',
			'Årup',
			'Vojens',
			'Viborg',
			'Vejen',
			'Svenstrup',
			'Hurup',
			'Herlev',
			'Gandrup',
			'Bellinge',
			'Vejle',
			'Vallensbæk',
			'Tranbjerg',
			'Strandby',
			'Store Heddinge',
			'Snejbjerg',
			'Smørumnedre',
			'Rødovre',
			'Køge',
			'Klitmøller',
			'Kirke Hvalsø',
			'Kalundborg',
			'Ikast',
			'Faaborg',
			'Vestbjerg',
			'Ulfborg',
			'Tørring',
			'Skive',
			'Nørre Åby',
			'Nivå',
			'Neder Holluf',
			'Maribo',
			'Malling',
			'Langeskov',
			'Kolt',
			'Haarby',
			'Haderslev',
			'Gundsømagle',
			'Grenaa',
			'Gelsted',
			'Christiansfeld',
			'Bække',
			'Ålestrup',
			'Vodskov',
			'Vildbjerg',
			'Vanløse',
			'Tune',
			'Svenstrup',
			'Strib',
			'Søften',
			'Skævinge',
			'Nyborg',
			'Nordborg',
			'Middelfart',
			'Korsør',
			'Jyderup',
			'Hundested',
			'Hammerum',
			'Hals',
			'Borup',
			'Spentrup',
			'Søllested',
			'Pandrup',
			'Outrup',
			'Ørslev',
			'Nakskov',
			'Løjt Kirkeby',
			'Hjordkær',
			'Hadsund',
			'Gilleleje',
			'Charlottenlund',
			'Brørup',
			'Bording Kirkeby',
			'Svinninge',
			'Svendborg',
			'Svejbæk',
			'Stenlille',
			'Skovby',
			'Nyråd',
			'Nykøbing Mors',
			'Kolding',
			'Kokkedal',
			'Idestrup',
			'Hørsholm',
			'Ganløse',
			'Ærøskøbing',
			'Vamdrup',
			'Tølløse',
			'Toftlund',
			'Them',
			'Stubbekøbing',
			'Skaerbaek',
			'Seden',
			'Nysted',
			'Nørre Bindslev',
			'Lejre',
			'Hornslet',
			'Hirtshals',
			'Havdrup',
			'Eskilstrup',
			'Brenderup',
			'Bogense',
			'Billund',
			'Årslev',
			'Virklund',
			'Tommerup',
			'Tårs',
			'Solrød',
			'Rødding',
			'Præstø',
			'Nørager',
			'Mern',
			'Mårslet',
			'Måløv',
			'Løkken',
			'Horsens',
			'Hammel',
			'Grindsted',
			'Glumsø',
			'Brande',
			'Børkop',
			'Vorbasse',
			'Trige',
			'Tarm',
			'Randers',
			'Nørre Nebel',
			'Liseleje',
			'Langå',
			'Kerteminde',
			'Jyderup',
			'Hvide Sande',
			'Hørning',
			'Hjallerup',
			'Hinnerup',
			'Hedensted',
			'Harlev',
			'Ebeltoft',
			'Arden',
			'Sindal',
			'Roskilde',
			'Ørbæk',
			'Højby',
			'Hedehusene',
			'Hatting',
			'Gørlev',
			'Frederiksberg',
			'Esbjerg',
			'Ejby',
			'Dybbøl',
			'Dragør',
			'Brovst',
			'Brønderslev',
			'Bredsten',
			'Stavnsholt',
			'Sønderborg',
			'Skørping',
			'Mariager',
			'Lille Skensved',
			'Copenhagen',
			'Højby',
			'Holte',
			'Hjørring',
			'Fuglebjerg',
			'Beder',
			'Ans',
			'Vemb',
			'Vadum',
			'Tønder',
			'Sulsted',
			'Sakskøbing',
			'Otterup',
			'Karup',
			'Holbæk',
			'Hasselager',
			'Hasle',
			'Greve',
			'Ejby',
			'Borup',
			'Allingåbro',
			'Tjæreborg',
			'Støvring',
			'Snoghøj',
			'Odder',
			'Karise',
			'Hørve',
			'Brejning',
			'Bjerringbro',
			'Videbæk',
			'Ramløse',
			'Ølstykke',
			'Ølgod',
			'Nexø',
			'Klarup',
			'Fredensborg',
			'Byrum',
			'Broager',
			'Ålbæk',
			'Åkirkeby',
			'Vrå',
			'Vordingborg',
			'Veksø',
			'Varde',
			'Tisvilde',
			'Tårnby',
			'Strøby Egede',
			'Storvorde',
			'Stige',
			'Sønder Bjert',
			'Skagen',
			'Ryomgård',
			'Ringe',
			'Oksbøl',
			'Nibe',
			'Løgumkloster',
			'Hillerød',
			'Helsingør',
			'Gevninge',
			'Frederiksværk',
			'Thyborøn',
			'Taastrup',
			'Sorø',
			'Ruds-Vedby',
			'Rødekro',
			'Ringkøbing',
			'Nørre Broby',
			'Værløse',
			'Høng',
			'Græsted',
			'Gråsten',
			'Gjellerup',
			'Gadstrup',
			'Forlev',
			'Buddinge',
			'Augustenborg',
			'Allerød',
			'Vipperød',
			'Undløse',
			'Ulstrup',
			'Starup',
			'Slangerup',
			'Ryslinge',
			'Ribe',
			'Over Holluf',
			'Kruså',
			'Kirke-Esbønderup',
			'Høruphav',
			'Dronninglund',
			'Bredebro',
			'Åbybro',
			'Stilling',
			'Rønnede',
			'Ringsted',
			'Nordby',
			'Nørre Alslev',
			'Munkebo',
			'Lunderskov',
			'Hvidovre',
			'Herning',
			'Hårlev',
			'Gjellerup',
			'Frederikshavn',
			'Bjæverskov',
			'Store Merløse',
			'Skibby',
			'Rødby',
			'Nørresundby',
			'Ishøj',
			'Hobro',
			'Frejlev',
			'Faxe Ladeplads',
			'Brædstrup',
			'Auning',
			'Asnæs',
			'Sundby',
			'Thorsø',
			'Svebølle',
			'Stenløse',
			'Søndersø',
			'Solbjerg',
			'Skodsborg',
			'Skælskør',
			'Osted',
			'Marstal',
			'Lystrup',
			'Lystrup',
			'Løgstør',
			'Lillerød',
			'Hinnerup',
			'Guderup',
			'Gedved',
			'Espergærde',
			'Bullerup',
			'Blovstrød',
			'Ballerup',
			'Aars',
			'Viby',
			'Vester-Skerninge',
			'Vester Hassing',
			'Trørød',
			'Stensved',
			'Slagelse',
			'Ry',
			'Padborg',
			'Lynge',
			'Lisbjerg',
			'Kås',
			'Jelling',
			'Hørning',
			'Hadsten',
			'Frederiksberg',
			'Solrød Strand',
			'Skjern',
			'Silkeborg',
			'Rønne',
			'Nykøbing Falster',
			'Nykøbing Sjælland',
			'Næstved',
			'Lind',
			'Kongens Lyngby',
			'Gram',
			'Gjern',
			'Fredericia',
			'Dianalund',
			'Ålsgårde',
			'Albertslund',
			'Valby',
			'Troense',
			'Tranebjerg',
			'Svaneke',
			'Rudkøbing',
			'Odense',
			'Kibæk',
			'Hornsyld',
			'Holstebro',
			'Helsinge',
			'Harboøre',
			'Farum',
			'Aabenraa',
			'Christiansø',
		],
	},
	Djibouti: {
		country: 'Djibouti',
		cities: ['Gâlâfi', 'Alaïli Ḏaḏḏa‘', 'Loyada', 'Arta', "'Ali Sabieh", 'Dorra', 'Tadjourah', 'Holhol', 'Goubétto', 'Obock', 'Dikhil', 'Djibouti'],
	},
	Dominica: {
		country: 'Dominica',
		cities: [
			'Mahaut',
			'Rosalie',
			'La Plaine',
			'Pont Cassé',
			'Wesley',
			'Calibishie',
			'Salisbury',
			'Soufrière',
			'Roseau',
			'Portsmouth',
			'Berekua',
			'Saint Joseph',
			'Marigot',
			'Colihaut',
			'Woodford Hill',
			'Castle Bruce',
			'Pointe Michel',
		],
	},
	'Dominican Republic': {
		country: 'Dominican Republic',
		cities: [
			'Villa Bisonó',
			'Sosúa',
			'Sabana de la Mar',
			'Monción',
			'Juan Santiago',
			'Yaguate',
			'Pepillo Salcedo',
			'Majagual',
			'La Descubierta',
			'Juan Adrián',
			'Joba Arriba',
			'Hatillo Palma',
			'Galván',
			'Ensanche Luperón',
			'El Palmar',
			'Bonao',
			'Boca de Yuma',
			'Yamasá',
			'Santiago de los Caballeros',
			'Pimentel',
			'Palmar de Ocoa',
			'Miches',
			'Matayaya',
			'Estebanía',
			'Duvergé',
			'Comendador',
			'Cayetano Germosén',
			'Castillo',
			'Cachón',
			'Boca Chica',
			'Vicente Noble',
			'Juan de Herrera',
			'Estero Hondo',
			'El Guayabal',
			'Don Juan',
			'Castañuelas',
			'Villa Tapia',
			'Tamayo',
			'San Francisco de Macorís',
			'Guananico',
			'Puerto Plata',
			'Pedernales',
			'Paya',
			'Luperón',
			'La Uvilla',
			'Cabrera',
			'Amina',
			'Sánchez',
			'Ramón Santana',
			'Polo',
			'Juan López Abajo',
			'El Pino',
			'Cabral',
			'Arroyo Salado',
			'Vallejuelo',
			'Sabana Iglesia',
			'Pueblo Viejo',
			'Padre Las Casas',
			'Juncalito Abajo',
			'Jicomé',
			'Cristóbal',
			'Pedro Santana',
			'Oviedo',
			'Mao',
			'Maizal',
			'Jaibón',
			'Guatapanal',
			'Canoa',
			'Bayaguana',
			'Bajos de Haina',
			'Baitoa',
			'Pizarrete',
			'Otra Banda',
			'Guaymate',
			'Gaspar Hernández',
			'Veragua Arriba',
			'Santo Domingo',
			'Río Grande',
			'Las Charcas',
			'Laguna Salada',
			'Hato Mayor del Rey',
			'Ciudad Nueva',
			'San Cristóbal',
			'Pedro García',
			'Monte Plata',
			'Licey al Medio',
			'Imbert',
			'El Cacao',
			'Cristo Rey',
			'Villa Riva',
			'San José de Las Matas',
			'Nizao',
			'Hondo Valle',
			'El Peñón',
			'Cana Chapetón',
			'Villarpando',
			'Loma de Cabrera',
			'Las Terrenas',
			'Jimaní',
			'Tenares',
			'Samaná',
			'Jima Abajo',
			'Jamao al Norte',
			'El Puerto',
			'Altamira',
			'Villa Francisca',
			'Piedra Blanca',
			'Partido',
			'La Canela',
			'Tábara Arriba',
			'San Pedro de Macorís',
			'San Gregorio de Nigua',
			'Sabana Grande de Palenque',
			'Sabana Grande de Boyá',
			'Palmar Arriba',
			'Bánica',
			'Santo Domingo Oeste',
			'Santa Cruz de Barahona',
			'Sabaneta',
			'Punta Cana',
			'Peralta',
			'El Carril',
			'Cotuí',
			'Cevicos',
			'Azua',
			'San Víctor Arriba',
			'Sabana del Puerto',
			'Río San Juan',
			'Los Ríos',
			'Los Hidalgos',
			'La Caya',
			'Jarabacoa',
			'Guerra',
			'Guayabal',
			'Dajabón',
			'Villa Isabela',
			'Las Matas de Santa Cruz',
			'La Ciénaga',
			'Santo Domingo Este',
			'Villa La Mata',
			'Villa Elisa',
			'Tamboril',
			'Mella',
			'Matanzas',
			'Jaquimeyes',
			'Fundación',
			'Enriquillo',
			'Bella Vista',
			'Yayas de Viajama',
			'Villa Jaragua',
			'San Fernando de Monte Cristi',
			'Salvaleón de Higüey',
			'Sabana Yegua',
			'Rincón',
			'Paraíso',
			'Las Guáranas',
			'La Romana',
			'Esperanza',
			'Cercado Abajo',
			'Cambita Garabitos',
			'Villa Vásquez',
			'Salcedo',
			'Río Limpio',
			'Postrer Río',
			'Pedro Sánchez',
			'Pedro Corto',
			'Neiba',
			'La Agustina',
			'Guayabo Dulce',
			'Concepción de La Vega',
			'San José de Ocoa',
			'San Carlos',
			'Río Verde Arriba',
			'Monte Llano',
			'Los Botados',
			'Las Salinas',
			'La Entrada',
			'El Llano',
			'Bohechío',
			'Agua Santa del Yuna',
			'Villa González',
			'Santa Cruz de El Seibo',
			'Pescadería',
			'Los Llanos',
			'Hostos',
			'Gonzalo',
			'Cabarete',
			'Arenoso',
			'Villa Consuelo',
			'San Rafael del Yuma',
			'Sabana Buey',
			'Quisqueya',
			'El Valle',
			'Constanza',
			'Villa Altagracia',
			'Tireo Arriba',
			'Nagua',
			'La Julia',
			'Fantino',
			'El Factor',
			'San Juan de la Maguana',
			'Salsipuedes',
			'Restauración',
			'Moca',
			'Las Matas de Farfán',
			'Juancho',
			'Esperalvillo',
			'Baní',
		],
	},
	Ecuador: {
		country: 'Ecuador',
		cities: [
			'Sucúa',
			'Huaquillas',
			'Ambato',
			'Palenque',
			'Naranjito',
			'Macará',
			'Catarama',
			//'Santa Ana',
			'Puerto Francisco de Orellana',
			'Píllaro',
			'Cotacachi',
			'Babahoyo',
			'Ventanas',
			'Palora',
			'Cañar',
			'Velasco Ibarra',
			'Portoviejo',
			'Tena',
			'Saquisilí',
			'Santa Lucía',
			'Gualaquiza',
			'El Ángel',
			'Boca Suno',
			'Balzar',
			'Tres Postes',
			'Tulcán',
			'Santa Elena',
			'Samborondón',
			'Naranjal',
			'Machala',
			'Guayaquil',
			'Bahía de Caráquez',
			'Zamora',
			'Sangolquí',
			'Puerto Ayora',
			'Nulti',
			'Muisne',
			'Loja',
			'Colimes',
			'Wilfrido Loor Moreira',
			'Sucre',
			'Puerto Villamil',
			'Montalvo',
			'Machachi',
			'Lomas de Sargentillo',
			'Eloy Alfaro',
			'Cayambe',
			'Santa Rosa',
			'Puerto Baquerizo Moreno',
			'La Unión',
			'Celica',
			'Baños',
			'San Miguel',
			'Quevedo',
			'Portovelo',
			'Cariamanga',
			'Taisha',
			'San Miguel de Salcedo',
			'Rocafuerte',
			'Otavalo',
			'Calceta',
			'Salinas',
			'Pedernales',
			'Montalvo',
			'La Libertad',
			'Baláo',
			'Rosa Zarate',
			'Playas',
			'La Maná',
			'Palestina',
			'Milagro',
			'Llacao',
			'Guaranda',
			'Coronel Marcelino Maridueña',
			'Catacocha',
			'Archidona',
			'Yaguachi Nuevo',
			'Santa Clara',
			'Puerto Bolívar',
			'Pasaje',
			'Manta',
			'Jipijapa',
			'Esmeraldas',
			'Tosagua',
			'Quito',
			'Cube',
			'Gonzalo Pizarro',
			'San Lorenzo de Esmeraldas',
			'Pedro Carbo',
			'Junín',
			'Azogues',
			'Vinces',
			'San Gabriel',
			'Paján',
			'Macas',
			'Pimampiro',
			'Ibarra',
			'Gualaceo',
			'Pampanal de Bolívar',
			'Zaruma',
			'Valdez',
			'Riobamba',
			'Pelileo',
			'Chone',
			'Yantzaza',
			'Puyo',
			'Montecristi',
			'Daule',
			'San Vicente',
			'Catamayo',
			'Alfredo Baquerizo Moreno',
			'Alausí',
			'Santo Domingo de los Colorados',
			'Quero',
			'Piñas',
			'La Troncal',
			'Latacunga',
			'Cuenca',
			'Atuntaqui',
			'Oyambarillo',
			'Pujilí',
			'Nueva Loja',
			'Guano',
			'El Triunfo',
		],
	},
	Egypt: {
		country: 'Egypt',
		cities: [
			'Sinnūris',
			'Qūş',
			'Qaşr al Farāfirah',
			'Al Badārī',
			'Shibīn al Kawm',
			'Samālūţ',
			'Mersa Matruh',
			'As Sinbillāwayn',
			'Girga',
			'Alexandria',
			'Al Bājūr',
			'Arish',
			'Abū Qurqāş',
			'Sīdī Barānī',
			'Isnā',
			'Aswan',
			'Ashmūn',
			'Al Khārjah',
			'Al Khānkah',
			'Shirbīn',
			'Mīt Ghamr',
			'Minyat an Naşr',
			'Mashtūl as Sūq',
			'Ţūkh',
			'Sharm el-Sheikh',
			'Rosetta',
			'El-Tor',
			'Aş Şaff',
			'Ibshawāy',
			'Ḩalwān',
			'Hihyā',
			'Damanhūr',
			'Būsh',
			'Bi’r al ‘Abd',
			'Madīnat Sittah Uktūbar',
			'Awsīm',
			'Ismailia',
			'Hurghada',
			'Al Bawīţī',
			'Abū al Maţāmīr',
			'El Gouna',
			'Kafr az Zayyāt',
			'Iţsā',
			'Basyūn',
			'Luxor',
			'Al Minyā',
			'Talā',
			'Samannūd',
			'Rafaḩ',
			'Quwaysinā',
			'Qinā',
			'Dikirnis',
			'Banī Mazār',
			'As Sallūm',
			'Al Qūşīyah',
			'Abnūb',
			'Ţalkhā',
			'Ash Shaykh Zuwayd',
			'Bilqās',
			'Zagazig',
			'At Tall al Kabīr',
			'Al Manzalah',
			'Ad Dilinjāt',
			'Kafr ash Shaykh',
			'Dayrūţ',
			'Port Said',
			'Banī Suwayf',
			'Asyūţ',
			'Al Qanāţir al Khayrīyah',
			'Makadi Bay',
			'New Cairo',
			'Marsa Alam',
			'Dishnā',
			'Al Jammālīyah',
			'Madīnat an Naşr',
			'‘Izbat al Burj',
			'Idfū',
			'Disūq',
			'Al Ḩawāmidīyah',
			'Munshāt ‘Alī Āghā',
			'Ţimā',
			'Sumusţā as Sulţānī',
			'Sohag',
			'Kawm Ḩamādah',
			'Safaga',
			'Sīdī Sālim',
			'Ras Gharib',
			'Kafr Şaqr',
			'Diyarb Najm',
			'Shibīn al Qanāṭir',
			'Munūf',
			'Kafr ad Dawwār',
			'Idkū',
			'Ḩawsh ‘Īsá',
			'Banhā',
			'Al Minshāh',
			'Akhmīm',
			'Qalyūb',
			'Al Qurayn',
			'Al Manşūrah',
			'Ain Sukhna',
			'Saint Catherine',
			'Zefta',
			'Ţanţā',
			'Shubrā al Khaymah',
			"Naja' Ḥammādī",
			'Dayr Mawās',
			'Cairo',
			'Al Maḩallah al Kubrá',
			'Al Fashn',
			'Al Balyanā',
			'Ţāmiyah',
			'Siwa Oasis',
			'Manfalūţ',
			'Al Wāsiţah',
			'Al Khuşūş',
			'Al Fayyūm',
			'Al ‘Alamayn',
			'Fāraskūr',
			'Ajā',
			'Sānt Kātrīnā',
			'Ţahţā',
			'Al ‘Āshir min Ramaḑān',
			'Farshūţ',
			'Damietta',
			'Dahab',
			'Suez',
			'Al Maţarīyah',
			'Giza',
			'Abū Kabīr',
			'Maţāy',
			'Maghāghah',
			'Juhaynah',
			'Al Qanāyāt',
			'Mallawī',
			'Kawm Umbū',
			'Ash Shuhadā’',
			'Al Quşayr',
			'Al Ḩāmūl',
			'Abū Tīj',
			'Kirdāsah',
			'Fāqūs',
			'Bilbays',
			'Al Ibrāhīmīyah',
			'Al ‘Ayyāţ',
			'Az Zarqā',
			"Al-'Ubūr",
			'Quţūr',
			'Nuwaybi‘a',
			'Fuwwah',
			'Abu Simbel',
		],
	},
	'El Salvador': {
		country: 'El Salvador',
		cities: [
			'Tacuba',
			'Santiago de María',
			'San Marcos',
			'San Alejo',
			'La Libertad',
			'Chinameca',
			'Victoria',
			'Sonzacate',
			'Sonsonate',
			'San Agustín',
			'Corinto',
			'San Pablo Tacachico',
			'San Francisco Menéndez',
			'Aguilares',
			'Soyapango',
			'Santa Elena',
			'San Francisco',
			'San Pedro Masahuat',
			'San Rafael Oriente',
			'Jiquilisco',
			'Ilopango',
			'Berlín',
			'Atiquizaya',
			//'Santa Ana',
			'Guazapa',
			'San Martín',
			'Santa Tecla',
			'El Congo',
			'Cojutepeque',
			'Chirilagua',
			'Ayutuxtepeque',
			'Antiguo Cuscatlán',
			'Ahuachapán',
			'Sociedad',
			'Santiago Nonualco',
			'Ozatlán',
			'Ciudad Arce',
			'Tenancingo',
			'Jucuarán',
			'Guaymango',
			'Ciudad Barrios',
			'Anamorós',
			'Acajutla',
			'Nueva Esparta',
			'Moncagua',
			'Delgado',
			'Concepción de Ataco',
			'Texistepeque',
			'San Miguel',
			'Pasaquina',
			'Intipucá',
			'El Rosario',
			'Chalchuapa',
			'Apopa',
			'Suchitoto',
			'Nueva Guadalupe',
			'Coatepeque',
			'Tecoluca',
			'Jucuapa',
			'El Tránsito',
			'Juayúa',
			'El Paisnal',
			'Zaragoza',
			'Santa Rosa de Lima',
			'San Juan Opico',
			'Puerto El Triunfo',
			'Metapán',
			'Lolotique',
			'Rosario de Mora',
			'Jocoro',
			'Chapeltique',
			'Candelaria de La Frontera',
			'Tonacatepeque',
			'La Unión',
			'Cuscatancingo',
			'Quezaltepeque',
			'Nuevo Cuscatlán',
			'Conchagua',
			'Sensuntepeque',
			'Panchimalco',
			'Mejicanos',
			'Armenia',
			'San Salvador',
			'Sesori',
			'San Sebastián',
			'Nueva Concepción',
			'Nahuizalco',
			'Izalco',
			'Zacatecoluca',
			'Guatajiagua',
			'Usulután',
			'San Vicente',
			'Santo Tomás',
			'Cacaopera',
			'Apastepeque',
			'San Antonio del Monte',
			'Concepción Batres',
			'Chalatenango',
			'Olocuilta',
			'Jujutla',
		],
	},
	'Equatorial Guinea': {
		country: 'Equatorial Guinea',
		cities: [
			'Bicurga',
			'Djibloho',
			'Aconibe',
			'Santiago de Baney',
			'Bitica',
			'Luba',
			'Acurenam',
			'San Antonio de Palé',
			'Mikomeseng',
			'Nsang',
			'Ebebiyin',
			'Bata',
			'Ncue',
			'Nsok',
			'Añisoc',
			'Mongomo',
			'Cogo',
			'Mbini',
			'Evinayong',
			'Rebola',
			'Ayene',
			'Machinda',
			'Río Campo',
			'Malabo',
		],
	},
	Eritrea: {
		country: 'Eritrea',
		cities: ['Massawa', 'Teseney', 'Edd', 'Asmara', 'Keren', 'Adi Keyh', 'Ak’ordat', 'Assab', 'Mai-Mne', 'Dek’emhāre', 'Mendefera', 'Barentu'],
	},
	Estonia: {
		country: 'Estonia',
		cities: [
			'Pärnu',
			'Kohtla-Järve',
			'Jõhvi',
			'Viljandi',
			'Iisaku',
			'Sõmeru',
			'Orissaare',
			'Värska',
			'Valga',
			'Sauga',
			'Ruhnu',
			'Narva-Jõesuu',
			'Haljala',
			'Harku',
			'Vändra',
			'Ülenurme',
			'Taebla',
			'Tabasalu',
			'Vinni',
			'Liiva',
			'Kehra',
			'Haabneeme',
			'Rummu',
			'Riisipere',
			'Rakvere',
			'Põltsamaa',
			'Pärnu-Jaagupi',
			'Mustvee',
			'Kurepalu',
			'Kärdla',
			'Hullo',
			'Alatskivi',
			'Vana-Antsla',
			'Puhja',
			'Laagri',
			'Raasiku',
			'Kuressaare',
			'Kõrveküla',
			'Kohtla-Nõmme',
			'Järvakandi',
			'Räpina',
			'Nõo',
			'Kilingi-Nõmme',
			'Sindi',
			'Kohila',
			'Jõgeva',
			'Tori',
			'Toila',
			'Rapla',
			'Paldiski',
			'Vaida',
			'Tapa',
			'Rõuge',
			'Narva',
			'Luunja',
			'Kose',
			'Kanepi',
			'Uulu',
			'Tehumardi',
			'Tamsalu',
			'Suure-Jaani',
			'Loksa',
			'Võhma',
			'Väike-Maarja',
			'Uuemõisa',
			'Sillamäe',
			'Paide',
			'Koeru',
			'Audru',
			'Viimsi',
			'Saue',
			'Särevere',
			'Põlva',
			'Aseri',
			'Turba',
			'Tartu',
			'Paikuse',
			'Kiviõli',
			'Türi',
			'Pajusti',
			'Otepää',
			'Kuusalu',
			'Abja-Paluoja',
			'Lihula',
			'Võru',
			'Pringi',
			'Maardu',
			'Kunda',
			'Keila',
			'Kadrina',
			'Tõrva',
			'Linaküla',
			'Kunda',
			'Kose',
			'Haapsalu',
			'Elva',
			'Tallinn',
			'Karksi-Nuia',
			'Märjamaa',
			'Antsla',
			'Vaiatu',
			'Järva-Jaani',
			'Aruküla',
			'Kiili',
			'Kehtna',
			'Jüri',
			'Nõmme',
			'Loo',
			'Viiratsi',
			'Saku',
			'Rakke',
			'Viimsi',
		],
	},
	Ethiopia: {
		country: 'Ethiopia',
		cities: [
			'Konso',
			'Batī',
			'Sebeta',
			'Metu',
			'Arba Minch',
			'Metahāra',
			'Gelemso',
			'Bishoftu',
			'Dabat',
			'Axum',
			'Tēpī',
			"Mek'ele",
			'Gēdo',
			'Bedēsa',
			'Mojo',
			'Jinka',
			'Jijiga',
			'Asaita',
			'Yamarugley',
			'Waal',
			'Were Īlu',
			'Dejen',
			'Bako',
			'Yabēlo',
			'Nazrēt',
			'Mendī',
			'Jimma',
			'Ginir',
			'Lasoano',
			'Tulu Bolo',
			'Felege Neway',
			'Dodola',
			'Butajīra',
			'Shakiso',
			'Mēga',
			'Dire Dawa',
			'Desē',
			'Debark’',
			'Shashemenē',
			'Maych’ew',
			'Duuban',
			'Gebre Guracha',
			'Deder',
			'Wenjī',
			'Turmi',
			'Kofelē',
			'Golwayn',
			'Debre Sīna',
			'Bedelē',
			'Ādīgrat',
			'Debre Tabor',
			'K’olīto',
			'Gimbi',
			'Ādīs ‘Alem',
			'Hosa’ina',
			'Genet',
			'Ādīs Zemen',
			'Semera',
			'Shambu',
			'Raqo',
			'Gewanē',
			'Āgaro',
			'Abomsa',
			'Harar',
			'Gīdolē',
			'Dembī Dolo',
			'Debre Birhan',
			'Bichena',
			'Lobuni',
			'Kemisē',
			'Bonga',
			'Bodītī',
			'Kibre Mengist',
			'Gondar',
			'Bahir Dar',
			'Hawassa',
			'Ādēt',
			'Yirga ‘Alem',
			'Sīrē',
			'Robīt',
			'Leku',
			'Hīrna',
			'Hāgere Selam',
			'Finote Selam',
			'Dubti',
			'Āsasa',
			'Fadhigaradle',
			'Waliso',
			'Sendafa',
			'Digih Habar Es',
			'Mīzan Teferī',
			'Hāgere Hiywet',
			'Gambēla',
			'Āsbe Teferī',
			'Āreka',
			'Ziway',
			'Korem',
			'Sodo',
			'Huruta',
			'Debre Werk’',
			'Qorof',
			'Neefkuceliye',
			'Nejo',
			'El Bahay',
			'Fichē',
			'Burē',
			'Kahandhale',
			'Werota',
			'Kombolcha',
			'Inda Silasē',
			'Gorē',
			'Goba',
			'Dīla',
			'Debre Mark’os',
			'Āsosa',
			'Hagere Maryam',
			'Addis Ababa',
			'Wendo',
			'Lalībela',
			'Āwash',
		],
	},
	'Faeroe Islands': {
		country: 'Faeroe Islands',
		cities: [
			'Fuglafjørður',
			'Sørvágur',
			'Tórshavn',
			'Eystur',
			'Porkeri',
			'Leirvík',
			'Vágur',
			'Hvalba',
			'Við Sjógv',
			'Skopun',
			'Miðvágur',
			'Argir',
			'Sandur',
			'Saltangará',
			'Hov',
			'Kirkja',
			'Kunoy',
			'Skúvoy',
			'Eiði',
			'Tvøroyri',
			'Sandavágur',
			'Hoyvík',
			'Klaksvík',
			'Viðareiði',
			'Skálavík',
			'Vestmanna',
		],
	},
	'Falkland Islands (Malvinas)': { country: 'Falkland Islands (Malvinas)', cities: ['Stanley'] },
	Fiji: { country: 'Fiji', cities: ['Suva', 'Nadi', 'Ba', 'Nasinu', 'Lautoka', 'Sigatoka', 'Labasa', 'Ahau', 'Levuka', 'Tubou'] },
	Finland: {
		country: 'Finland',
		cities: [
			'Tuulos',
			'Toivakka',
			'Siuntio',
			'Rautjärvi',
			'Multia',
			'Miehikkälä',
			'Lumijoki',
			'Liljendal',
			'Längelmäki',
			'Jäppilä',
			'Imatra',
			'Forssa',
			'Alajärvi',
			'Taivassalo',
			'Sodankylä',
			'Pyhäranta',
			'Pyhäjoki',
			'Puumala',
			'Oulunsalo',
			'Mouhijärvi',
			'Lohja',
			'Lemu',
			'Lammi',
			'Koria',
			'Konnevesi',
			'Kärsämäki',
			'Karjalohja',
			'Kalajoki',
			'Heinävesi',
			'Harjavalta',
			'Hankasalmi',
			'Kilo',
			'Nurmijärven kirkonkylä',
			'Vimpeli',
			'Vehmersalmi',
			'Tornio',
			'Ruokolahti',
			'Oitti',
			'Noormarkku',
			'Mikkeli',
			'Marttila',
			'Lieksa',
			'Lappajärvi',
			'Kannus',
			'Ivalo',
			'Ilomantsi',
			'Hirvensalmi',
			'Himanka',
			'Vuosaari',
			'Vuokatti',
			'Vinkkilä',
			'Vihanti',
			'Vammala',
			'Petäjävesi',
			'Perho',
			'Outokumpu',
			'Närpes',
			'Merijärvi',
			'Mäntyharju',
			'Leppävirta',
			'Kyyjärvi',
			'Kärkölä',
			'Hollola',
			'Halikko',
			'Rusko',
			'Ruovesi',
			'Rauma',
			'Pertteli',
			'Nivala',
			'Munkkiniemi',
			'Mänttä',
			'Lappeenranta',
			'Kisko',
			'Kaskinen',
			'Karstula',
			'Kangasala',
			'Jämsä',
			'Velkua',
			'Siilinjärvi',
			'Sauvo',
			'Sammatti',
			'Saarijärvi',
			'Vörå',
			'Utajärvi',
			'Suonenjoki',
			'Suomussalmi',
			'Säynätsalo',
			'Renko',
			'Reisjärvi',
			'Pyhtää',
			'Pielavesi',
			'Nurmijärvi',
			'Mynämäki',
			'Lahti',
			'Kuhmo',
			'Kuhmalahti',
			'Hämeenkoski',
			'Kokkola',
			'Klaukkala',
			'Kempele',
			'Kauhajoki',
			'Jakobstad',
			'Hauho',
			'Enonkoski',
			'Alavieska',
			'Alastaro',
			'Ylitornio',
			'Valtimo',
			'Uurainen',
			'Urjala',
			'Pohja',
			'Nagu',
			'Muurame',
			'Muhos',
			'Mellunkylä',
			'Luopioinen',
			'Loppi',
			'Lapinlahti',
			'Kuopio',
			'Kivijärvi',
			'Kitee',
			'Houtskär',
			'Hamina',
			'Hämeenkyrö',
			'Elimäki',
			'Alahärmä',
			'Ullava',
			'Tohmajärvi',
			'Nurmes',
			'Nickby',
			'Karvia',
			'Jurva',
			'Hyrynsalmi',
			'Aura',
			'Koukkuniemi',
			'Ylikiiminki',
			'Vuolijoki',
			'Tervola',
			'Sotkamo',
			'Ruukki',
			'Ranua',
			'Puolanka',
			'Malax',
			'Kustavi',
			'Kurikka',
			'Korpilahti',
			'Kihniö',
			'Kärkölä',
			'Joroinen',
			'Jämijärvi',
			'Hausjärvi',
			'Halsua',
			'Veikkari',
			'Akaa',
			'Sysmä',
			'Somero',
			'Raahe',
			'Muhos',
			'Merimasku',
			'Liperi',
			'Lempäälä',
			'Kannonkoski',
			'Kaarina',
			'Eurajoki',
			'Ristijärvi',
			'Punkalaidun',
			'Paimio',
			'Myrskylä',
			'Luumäki',
			'Lovisa',
			'Lappi',
			'Köyliö',
			'Kerava',
			'Kankaanpää',
			'Jämsänkoski',
			'Eura',
			'Yläne',
			'Vehmaa',
			'Vahto',
			'Vähäkyrö',
			'Vaasa',
			'Tuupovaara',
			'Tervo',
			'Taivalkoski',
			'Broby',
			'Sandsund',
			'Viljakkala',
			'Vihti',
			'Vieremä',
			'Varkaus',
			'Toholampi',
			'Simo',
			'Pyhäjärvi',
			'Polvijärvi',
			'Parikkala',
			'Luvia',
			'Kouvola',
			'Korsnäs',
			'Kiikoinen',
			'Kaustinen',
			'Kangasniemi',
			'Kajaani',
			'Huittinen',
			'Hailuoto',
			'Haapajärvi',
			'Alavus',
			'Vuores',
			'Röykkä',
			'Pyhäjärvi',
			'Pulkkila',
			'Lapinjärvi',
			'Kuusjoki',
			'Iitti',
			'Iisalmi',
			'Honkajoki',
			'Ähtäri',
			'Siikajoki',
			'Tuusniemi',
			'Tampere',
			'Saukkola',
			'Pudasjärvi',
			'Mellilä',
			'Laitila',
			'Kotka',
			'Kiukainen',
			'Jyväskylä',
			'Bennäs',
			'Virtasalmi',
			'Viiala',
			'Uusikaupunki',
			'Savukoski',
			'Pertunmaa',
			'Parola',
			'Nykarleby',
			'Naantali',
			'Lemi',
			'Laukaa',
			'Kylmäkoski',
			'Kontiolahti',
			'Kiikala',
			'Keuruu',
			'Keminmaa',
			'Humppila',
			'Haukivuori',
			'Gumböle',
			'Ekenäs',
			'Ristinummi',
			'Teeriniemi',
			'Hietalahti',
			'Vampula',
			'Turku',
			'Sulkava',
			'Raisio',
			'Pomarkku',
			'Piikkiö',
			'Merikarvia',
			'Lieto',
			'Kuusamo',
			'Kuortane',
			'Korsholm',
			'Kerimäki',
			'Kemi',
			'Karkkila',
			'Kaavi',
			'Jokioinen',
			'Ylivieska',
			'Ylihärmä',
			'Virolahti',
			'Vesanto',
			'Vantaa',
			'Ulvila',
			'Taavetti',
			'Suomusjärvi',
			'Sonkajärvi',
			'Savitaipale',
			'Särkisalo',
			'Säkylä',
			'Riihimäki',
			'Rantsila',
			'Pylkönmäki',
			'Posio',
			'Pirkkala',
			'Otaniemi',
			'Nousiainen',
			'Maaninka',
			'Luhanka',
			'Kestilä',
			'Kallio',
			'Juuka',
			'Juankoski',
			'Heinola',
			'Hämeenlinna',
			'Ylämaa',
			'Vilppula',
			'Viitasaari',
			'Varpaisjärvi',
			'Pyhäntä',
			'Pukkila',
			'Pernå',
			'Paltamo',
			'Padasjoki',
			'Oulu',
			'Oulainen',
			'Orivesi',
			'Kolari',
			'Kokemäki',
			'Kauhava',
			'Karis',
			'Joensuu',
			'Iniö',
			'Ii',
			'Eno',
			'Suolahti',
			'Parkano',
			'Nokia',
			'Kyrö',
			'Kirkkonummi',
			'Joutseno',
			'Ingå',
			'Espoo',
			'Artjärvi',
			'Tuusula',
			'Sievi',
			'Seinäjoki',
			'Pyhäsalmi',
			'Pieksämäki',
			'Pälkäne',
			'Nastola',
			'Muurla',
			'Loimaa',
			'Kristinestad',
			'Kinnula',
			'Kemijärvi',
			'Juva',
			'Järvenpää',
			'Haapavesi',
			'Evijärvi',
			'Enontekiö',
			'Auttoinen',
			'Vesilahti',
			'Tervakoski',
			'Taipalsaari',
			'Siikainen',
			'Rautavaara',
			'Pöytyä',
			'Nakkila',
			'Lestijärvi',
			'Kronoby',
			'Kiuruvesi',
			'Kauniainen',
			'Karijoki',
			'Juupajoki',
			'Ilmajoki',
			'Hartola',
			'Ristiina',
			'Rääkkylä',
			'Pihtipudas',
			'Perniö',
			'Pargas',
			'Laihia',
			'Kuhmoinen',
			'Kinnula',
			'Karhula',
			'Hanko',
			'Toijala',
			'Ytteresse',
			'Utsjoki',
			'Töysä',
			'Simpele',
			'Ypäjä',
			'Ylöjärvi',
			'Turenki',
			'Teuva',
			'Rantasalmi',
			'Mietoinen',
			'Lohtaja',
			'Liminka',
			'Lauttasaari',
			'Kittilä',
			'Kälviä',
			'Kållby',
			'Hollola',
			'Anjala',
			'Suodenniemi',
			'Soini',
			'Savonranta',
			'Rymättylä',
			'Rovaniemi',
			'Pori',
			'Pelkosenniemi',
			'Nilsiä',
			'Muonio',
			'Lapua',
			'Kellokoski',
			'Kangaslampi',
			'Järvelä',
			'Ikaalinen',
			'Yli-Ii',
			'Virrat',
			'Virojoki',
			'Tyrnävä',
			'Tammela',
			'Sumiainen',
			'Savonlinna',
			'Saari',
			'Replot',
			'Punkaharju',
			'Pedersöre',
			'Masku',
			'Mäntsälä',
			'Kullaa',
			'Kuivaniemi',
			'Kortesjärvi',
			'Kiihtelysvaara',
			'Keitele',
			'Kalvola',
			'Kaarela',
			'Joutsa',
			'Jaala',
			'Isokyrö',
			'Hyvinge',
			'Helsinki',
			'Dragsfjärd',
			'Äetsä',
			'Väståboland',
			'Västanfjärd',
			'Tarvasjoki',
			'Sibbo',
			'Sahalahti',
			'Rautalampi',
			'Orimattila',
			'Lavia',
			'Kuru',
			'Inari',
			'Porvoo',
			'Askola',
			'Askainen',
			'Älajärvi',
			'Pornainen',
			'Oravais',
			'Nuijamaa',
			'Larsmo',
			'Koski Tl',
			'Kimito',
			'Kesälahti',
			'Karvia',
			'Jalasjärvi',
			'Haukipudas',
			'Veteli',
			'Vaala',
			'Smedsby',
			'Ruotsinpyhtää',
			'Pyhäselkä',
			'Piippola',
			'Pello',
			'Oripää',
			'Leivonmäki',
			'Kiiminki',
			'Karttula',
			'Karinainen',
			'Janakkala',
			'Isojoki',
			'Asikkala',
			'Äänekoski',
			'Valkeakoski',
			'Salo',
			'Salla',
		],
	},
	France: {
		country: 'France',
		cities: [
			'Wasselonne',
			'Wargnies-le-Grand',
			'Vougy',
			'Vitry-en-Artois',
			'Violès',
			'Villiers-le-Bâcle',
			'Villemolaque',
			'Vestric-et-Candiac',
			'Vert-en-Drouais',
			'Vernet-les-Bains',
			'Vergigny',
			'Vendeuvre-du-Poitou',
			"Valence-d'Albigeois",
			'Tossiat',
			'Thueyts',
			'Thionville',
			'Thésée',
			'Thaon',
			'Sundhouse',
			'Sucy-en-Brie',
			'Steenvoorde',
			'Spézet',
			'Seysses',
			'Servas',
			'Sernhac',
			'Sérifontaine',
			'Sens',
			'Semussac',
			'Secondigny',
			'Sciez',
			'Savigny-en-Sancerre',
			'Sauveterre',
			'Sauvagnon',
			'Saulny',
			'Sarzeau',
			'Santeny',
			'Saizerais',
			'Saint-Victor-la-Coste',
			'Saint-Vaast-la-Hougue',
			'Saint-Uze',
			'Saint-Symphorien',
			'Saint-Sorlin-en-Bugey',
			'Saint-Sauveur',
			'Saint-Romain-de-Benet',
			'Saint-Python',
			'Saint-Pierre-des-Échaubrognes',
			'Saint-Méen-le-Grand',
			'Saint-Martin-du-Vivier',
			'Saint-Marc-Jaumegarde',
			'Saint-Macaire-en-Mauges',
			'Saint-Laurent-de-Chamousset',
			'Saint-Just',
			'Saint-Just-Luzac',
			'Saint-Joseph',
			'Saint-Jeannet',
			'Saint-Jean-de-Thouars',
			'Saint-Jean-de-Muzols',
			'Saint-Hippolyte-du-Fort',
			'Saint-Gervais',
			'Saint-Germain-sur-Avre',
			'Saint-Germain-du-Bois',
			'Saint-Germain-de-Prinçay',
			'Saint-Georges-du-Bois',
			'Saint-Erblon',
			'Saint-Éloy-les-Mines',
			'Sainte-Geneviève-des-Bois',
			'Sainte-Fortunade',
			'Sainte-Cécile-les-Vignes',
			"Sainte-Anne-d'Auray",
			'Saint-Christophe-du-Ligneron',
			"Saint-Chély-d'Apcher",
			'Saint-Barnabé',
			'Sailly-Flibeaucourt',
			'Sagy',
			'Sabran',
			'Rupt-sur-Moselle',
			'Rumilly',
			'Rully',
			'Ruffec',
			'Rochechouart',
			'Rivière',
			"Razac-sur-l'Isle",
			'Ranchicourt',
			'Pouxeux',
			'Pouilly-lès-Feurs',
			'Pordic',
			'Pompaire',
			'Polminhac',
			'Plurien',
			'Plougonvelin',
			'Pleyben',
			'Phalsbourg',
			'Perrigny-lès-Dijon',
			'Pérignac',
			'Péchabou',
			'Parmain',
			'Oudon',
			'Ottange',
			'Ortaffa',
			'Nuaillé',
			'Notre-Dame-des-Landes',
			'Normanville',
			'Noisy-sur-École',
			'Nohanent',
			'Nogent-sur-Vernisson',
			'Nissan-lez-Enserune',
			'Néville',
			'Neuvic',
			'Neuilly-lès-Dijon',
			'Neuf-Brisach',
			'Naours',
			'Moosch',
			'Montreuil-Bellay',
			'Montreuil-aux-Lions',
			'Montlaur',
			'Monthermé',
			'Montendre',
			'Montcenis',
			'Montardon',
			'Mons-en-Pévèle',
			'Mitry-Mory',
			'Metz-Tessy',
			'Metz',
			'Mérindol',
			'Mérignac',
			'Mensignac',
			'Ménétrol',
			'Mauzé-Thouarsais',
			'Martigues',
			'Marolles-les-Braults',
			'Marignane',
			'Maraussan',
			'Mandelieu-la-Napoule',
			'Malissard',
			'Malestroit',
			'Mâcot-la-Plagne',
			'Lussac-les-Châteaux',
			'Lumbin',
			'Louveciennes',
			'Lorette',
			'Longlaville',
			'Listrac-Médoc',
			'Lipsheim',
			'Libourne',
			'Lherm',
			'Leymen',
			'Le Vivier-sur-Mer',
			'Le Tholy',
			'Les Mées',
			'Les Ancizes',
			'Le Pradet',
			'Le Port-Marly',
			'Le Plessis-Grammoire',
			'Le Petit-Quevilly',
			'Le Havre',
			'Le Grau-du-Roi',
			'Le Doulieu',
			'Le Crotoy',
			'Bono',
			'Laxou',
			'La Vraie-Croix',
			'Lavoux',
			'Lavilledieu',
			'La Valentine',
			'La Tronche',
			'La Tour-de-Salvagny',
			'La Séguinière',
			'Lasalle',
			"L'Arbresle",
			'Lapalisse',
			'Laon',
			'Lanrivoaré',
			'Lamure-sur-Azergues',
			'La Motte-Saint-Jean',
			'La Mothe-Saint-Héray',
			'La Jarne',
			'La Guérinière',
			'La Couarde-sur-Mer',
			'La Chapelle-sur-Erdre',
			'La Capelle-lès-Boulogne',
			'La Capelle',
			'La Bâtie-Neuve',
			'La Bâthie',
			'Jullouville',
			'Jouy',
			'Ifs',
			'Hirsingue',
			'Herrlisheim',
			'Heillecourt',
			'Hagondange',
			'Guiscard',
			'Guipry',
			'Guillestre',
			'Guilherand-Granges',
			'Guénin',
			'Grainville-sur-Odon',
			'Gouvieux',
			'Gordes',
			'Gimont',
			'Gevrey-Chambertin',
			'Gensac-la-Pallue',
			'Génissieux',
			'Gasny',
			'Gaillard',
			'Gaillac-Toulza',
			'Fressenneville',
			'Franois',
			'Fontenay-le-Marmion',
			'Fontaine-Notre-Dame',
			'Flines-lès-Mortagne',
			'Eurre',
			'Ercuis',
			'Ensuès-la-Redonne',
			'Éloyes',
			'Écourt-Saint-Quentin',
			'Dourgne',
			'Dordives',
			'Dompierre-sur-Yon',
			'Dompierre-sur-Veyle',
			'Digne-les-Bains',
			'Dagneux',
			'Cussac',
			'Cubzac-les-Ponts',
			'Courmelles',
			'Courcelles-Chaussy',
			'Coulon',
			'Corzé',
			'Cornillon-Confoux',
			'Condat',
			'Communay',
			'Commercy',
			'Combrand',
			'Colombes',
			'Coësmes',
			'Cluny',
			'Clichy-sous-Bois',
			'Chevigny-Saint-Sauveur',
			'Chessy',
			'Chéraute',
			'Châtenay-Malabry',
			'Châteauponsac',
			'Châteauneuf-sur-Charente',
			'Châteauneuf-du-Faou',
			'Chargé',
			'Chapareillan',
			'Chantôme',
			'Chanteloup',
			'Chantelle',
			'Chanteheux',
			'Chanos-Curson',
			'Challuy',
			'Chaingy',
			'Cercy-la-Tour',
			'Cavalaire-sur-Mer',
			'Cantenay-Épinard',
			'Candé',
			'Cambronne-lès-Clermont',
			'Cahagnes',
			'Buxerolles',
			'Bruille-lez-Marchiennes',
			'Brignais',
			'Bretteville-sur-Odon',
			'Bozel',
			'Bordeaux',
			'Bonneuil-Matours',
			'Bizanet',
			'Beychac',
			'Betton',
			'Belz',
			'Bellême',
			'Bégrolles-en-Mauges',
			'Beaumont-en-Véron',
			'Beaumes-de-Venise',
			'Bazouges-la-Pérouse',
			'Baulne',
			'Basse-Goulaine',
			"Bailleau-l'Évêque",
			'Baguer-Morvan',
			'Avensan',
			'Avanne-Aveney',
			'Aussonne',
			'Aulnay',
			'Aubin',
			'Aubers',
			'Armoy',
			'Arles',
			'Argenton-sur-Creuse',
			'Argeles',
			'Arette',
			'Arc-lès-Gray',
			'Angy',
			'Andilly',
			'Albi',
			'Aigueblanche',
			'Lyon 07',
			"Pont-d'Ouilly",
			'Le Sequestre',
			'Wavrechain-sous-Denain',
			'Villiers-en-Plaine',
			'Villes-sur-Auzon',
			'Villerupt',
			'Villers-sous-Saint-Leu',
			'Villers-le-Lac',
			'Villerest',
			'Villenouvelle',
			'Villeneuve',
			'Villemoirieu',
			'Villefranche-de-Rouergue',
			"Villefranche-d'Allier",
			'Villedieu-la-Blouère',
			'Vicq',
			'Vernouillet',
			'Vergongheon',
			'Verdun',
			'Venette',
			'Velars-sur-Ouche',
			'Valence',
			'Valady',
			'Vailly-sur-Aisne',
			'Uberach',
			'Treignac',
			'Tréguier',
			'Tourrette-Levens',
			'Tilly-sur-Seulles',
			'Thurins',
			'Thierville-sur-Meuse',
			'Thélus',
			'Terville',
			'Ternay',
			'Teillé',
			'Tarascon-sur-Ariège',
			'Taponnat',
			'Talloires',
			'Suippes',
			'Soulgé-sur-Ouette',
			'Sillans',
			'Seignosse',
			'Savenay',
			'Sauve',
			'Saulxures-lès-Nancy',
			'Sarrola',
			'Salomé',
			'Salles-Curan',
			'Salaise-sur-Sanne',
			'Saint-Venant',
			'Saint-Thonan',
			'Saint-Sulpice-sur-Risle',
			'Saint-Samson-sur-Rance',
			'Saint-Priest-sous-Aixe',
			'Saint-Pierre-de-Chandieu',
			'Saint-Philibert',
			'Saint-Nazaire',
			'Saint-Maurice-Montcouronne',
			'Saint-Martin-de-Boscherville',
			'Saint-Léonard-de-Noblat',
			'Saint-Laurent-de-Mure',
			'Saint-Jouvent',
			'Saint-Jean-le-Blanc',
			'Saint-Jean-du-Cardonnay',
			'Saint-Jean-Brévelay',
			'Saint-Jans-Cappel',
			'Saint-Hilaire-Petitville',
			'Saint-Germain-la-Blanche-Herbe',
			'Saint-Germain-de-Marencennes',
			'Saint-Georges-sur-Loire',
			"Saint-Geniez-d'Olt",
			'Sainte-Gauburge-Sainte-Colombe',
			'Sainte-Eulalie',
			'Sainte-Croix-en-Plaine',
			'Saint-Dyé-sur-Loire',
			'Saint-Clair-de-la-Tour',
			'Saint-Brice-Courcelles',
			'Saint-Branchs',
			'Saint-Barthélemy-de-Vals',
			'Saint-Aupre',
			'Sant Andreu de Sureda',
			'Saint-Ambroix',
			'Saint-Amand-en-Puisaye',
			'Saint-Alban',
			'Sailly-sur-la-Lys',
			'Sablons',
			'Roye',
			'Roumare',
			'Roquebrune-sur-Argens',
			'Roost-Warendin',
			'Roncq',
			'Ronchin',
			'Romilly-sur-Andelle',
			'Rieux-en-Cambrésis',
			'Rieux',
			'Rexpoëde',
			'Remiremont',
			'Reillanne',
			'Reignac-sur-Indre',
			'Rebréchien',
			'Randan',
			'Quimperlé',
			'Pulnoy',
			'Provins',
			'Praz-sur-Arly',
			'Pontfaverger-Moronvilliers',
			'Pont-du-Casse',
			'Pommiers',
			'Pomarez',
			'Plourin-lès-Morlaix',
			'Plouisy',
			"Plouezoc'h",
			'Plouay',
			'Plonéis',
			'Plogonnec',
			'Pleslin-Trigavou',
			"Plan-d'Aups-Sainte-Baume",
			'Pierrepont',
			'Peyruis',
			'Péroy-les-Gombries',
			'Pépieux',
			'Outreau',
			'Ousse',
			'Orgères-en-Beauce',
			'Octeville-sur-Mer',
			'Nuillé-sur-Vicoin',
			'Noisy-le-Roi',
			'Nivillac',
			'Naucelle',
			'Muzillac',
			'Mouy',
			'Moutiers',
			'Montréal-la-Cluse',
			'Montmain',
			'Montfort-en-Chalosse',
			'Montesquieu-Volvestre',
			'Mondragon',
			'Mommenheim',
			'Mittelhausbergen',
			'Mireval',
			'Mézières-sur-Seine',
			'Meung-sur-Loire',
			'Méréville',
			'Ménerbes',
			'Mellac',
			'Matzenheim',
			'Massongy',
			'Massieux',
			'Marsilly',
			'Marignier',
			'Marcilly-sur-Tille',
			'Manziat',
			'Mansle',
			'Mansac',
			'Mamirolle',
			'Malemort-sur-Corrèze',
			'Magnac-Laval',
			'Magescq',
			'Lumes',
			'Ludres',
			'Lorrez-le-Bocage-Préaux',
			'Lombron',
			"L'Isle-en-Dodon",
			'Lieuran-lès-Béziers',
			'Léré',
			'Le Pont-de-Beauvoisin',
			'Le Grand-Bornand',
			'Le Fief-Sauvin',
			'Lécluse',
			'Le Cannet-des-Maures',
			'La Teste-de-Buch',
			'La Roche-Chalais',
			'La Regrippière',
			'La Panouse',
			'Landivisiau',
			'Lancieux',
			'La Murette',
			'Lamonzie',
			'La Madeleine-de-Nonancourt',
			'La Longueville',
			'La Garenne-Colombes',
			'La Flocellière',
			'La Ferté-Saint-Aubin',
			'La Cerlangue',
			'Labastide-Saint-Pierre',
			'La Bastide-des-Jourdans',
			'Kervignac',
			'Jumelles',
			'Javron-les-Chapelles',
			'Izeaux',
			'Ibos',
			'Horbourg-Wihr',
			'Hiersac',
			'Hérépian',
			'Ham-sous-Varsberg',
			'Guilvinec',
			'Guichainville',
			'Gruissan',
			'Grézieu-la-Varenne',
			'Grand-Fougeray',
			'Grandfontaine',
			'Grande-Synthe',
			'Goussainville',
			'Gosnay',
			'Gorcy',
			'Gisors',
			'Gignac',
			'Geudertheim',
			'Gesté',
			'Gerstheim',
			'Garchizy',
			'Garches',
			'Gannat',
			'Freigné',
			'Fourqueux',
			'Forcalqueiret',
			'Foix',
			'Fléville-devant-Nancy',
			'Fillinges',
			'Fère-en-Tardenois',
			'Fayence',
			'Èze',
			'Étriché',
			'Étoile-sur-Rhône',
			'Eslettes',
			'Épernay',
			'Égly',
			'Ecquevilly',
			'Échiré',
			'Échalas',
			'Dozulé',
			'Dommartin-lès-Toul',
			'Doingt',
			'Dignac',
			'Daux',
			'Darnieulles',
			'Cussac-sur-Loire',
			'Crézancy',
			'Couiza',
			'Coudekerque-Village',
			'Corneilla-la-Rivière',
			'Cormicy',
			'Condé-sur-Sarthe',
			'Colombey-les-Belles',
			'Cogny',
			"Ciré-d'Aunis",
			'Chevry',
			'Chevannes',
			'Chessy',
			'Cherré',
			'Chazelles',
			'Châtillon-sur-Cher',
			'Chasseneuil-du-Poitou',
			'Chanonat',
			'Champlitte',
			'Cépet',
			'Cauville-sur-Mer',
			'Castres',
			'Castelnau-le-Lez',
			'Castelculier',
			'Carry-le-Rouet',
			'Carrières-sur-Seine',
			'Calmont',
			'Bucquoy',
			'Brugheas',
			'Branoux-les-Taillades',
			'Bozouls',
			"la Guingueta d'Ix",
			'Boofzheim',
			'Bons-en-Chablais',
			'Bonnétable',
			'Bonnelles',
			'Bonchamp-lès-Laval',
			'Boigny-sur-Bionne',
			'Bléré',
			'Blagnac',
			'Biscarrosse',
			'Bining',
			'Billy-Montigny',
			'Bézu-Saint-Éloi',
			'Bessines-sur-Gartempe',
			'Benet',
			'Belpech',
			'Bellegarde',
			'Belgentier',
			'Beauzac',
			'Beauvoisin',
			'Bavay',
			'Barraux',
			'Aydat',
			'Avilly-Saint-Léonard',
			'Avelin',
			'Auvers-sur-Oise',
			'Aups',
			'Aulnay-sur-Mauldre',
			'Arbonne',
			'Angicourt',
			'Amboise',
			'Allan',
			'Alby-sur-Chéran',
			'Aizenay',
			'Achenheim',
			'Guidel-Plage',
			'Lyon 09',
			'Marseille 04',
			'Marseille 06',
			'Marseille 12',
			'La Page',
			'Malpassé',
			'La Cabucelle',
			'Yvignac-la-Tour',
			'Yébleron',
			'Winnezeele',
			'Widensolen',
			'Wallers',
			'Wahagnies',
			'Voves',
			'Viriat',
			'Vignot',
			'Vieux-Thann',
			'Le Vieux-Marché',
			'Veurey-Voroize',
			'Vertaizon',
			'Verlinghem',
			'Verberie',
			'Venizel',
			'Velaines',
			'Varades',
			"Val-d'Isère",
			'Uchaud',
			'Tucquegnieux',
			'Torcy',
			'Thoiry',
			'Souprosse',
			'Solliès-Pont',
			'Silly-le-Long',
			'Sillingy',
			'Sessenheim',
			'Septfonds',
			'Seilhac',
			'Schirrhein',
			'Sceaux',
			'Sauverny',
			'Saumur',
			'Saulx-les-Chartreux',
			'Sault-Brénaz',
			'Sarrewerden',
			'Sari-Solenzara',
			'Saint-Vallier',
			'Saint-Thuriau',
			'Saint-Savin',
			'Saint-Sauveur',
			'Saint-Rogatien',
			'Saint-Quay-Perros',
			'Saint-Pol-sur-Ternoise',
			'Saint-Piat',
			'Saint-Ours',
			'Saint-Nicolas-de-la-Grave',
			'Saint-Méloir-des-Ondes',
			'Saint-Melaine-sur-Aubance',
			'Saint-Martin-de-Belleville',
			'Saint-Mars-de-Coutais',
			'Saint-Léonard',
			'Saint-Léger-sur-Dheune',
			'Saint-Just-Sauvage',
			'Saint-Julien-de-Peyrolas',
			'Saint-Jean-Pied-de-Port',
			'Saint-Jean-en-Royans',
			'Saint-Jean-de-Boiseau',
			'Saint-Jean-Cap-Ferrat',
			'Saint-Jean',
			'Saint-Hilaire',
			'Saint-Gonnery',
			'Saint-Germain-Lembron',
			'Saint-Geniès-des-Mourgues',
			'Saint-Gengoux-le-National',
			'Saint-Genest-Lerpt',
			'Saint-Gelais',
			'Saint-Eusèbe',
			'Saint-Étienne-lès-Remiremont',
			'Saint-Estèphe',
			'Sainte-Marie-aux-Mines',
			'Sainte-Marguerite',
			'Sainte-Florine',
			'Sainte-Anastasie-sur-Issole',
			'Saint-Crespin-sur-Moine',
			'Saint-Cast-le-Guildo',
			'Saint-Benoît-sur-Loire',
			'Saint-Augustin',
			'Sagy',
			'Ruoms',
			'Rouffach',
			'Rouans',
			'Rochemaure',
			'Robecq',
			'Ribemont',
			'Replonges',
			'Remy',
			'Réhon',
			'Rang-du-Fliers',
			'Ramonville-Saint-Agne',
			'Radinghem-en-Weppes',
			'Quiévrechain',
			'Quemper-Guézennec',
			'Puget-sur-Argens',
			'Prissé',
			'Priay',
			'Précieux',
			'Prayssac',
			'Pornichet',
			'Pontivy',
			'Pontarlier',
			'Plumelec',
			'Plougastel-Daoulas',
			'Ploubezre',
			'Pleuven',
			'Plélan-le-Petit',
			'Pithiviers',
			'Piriac-sur-Mer',
			'Picauville',
			'Pénestin',
			'Palaja',
			'Pagny-sur-Moselle',
			'Orival',
			'Origny-Sainte-Benoite',
			'Obenheim',
			'Nozay',
			'Novéant-sur-Moselle',
			'Nouzilly',
			'Neuilly-le-Réal',
			'Naintré',
			'Murviel-lès-Montpellier',
			'Mûr-de-Bretagne',
			'Mouroux',
			'Mortagne-du-Nord',
			'Morée',
			'Montrevault',
			'Montreuil',
			'Montjoire',
			'Montigny-lès-Cormeilles',
			'Montaut',
			'Montataire',
			'Moirans',
			'Méziré',
			'Ménesplet',
			'Megève',
			'Mazet-Saint-Voy',
			'Mauron',
			'Marzy',
			'Marsannay-la-Côte',
			'Maromme',
			'Mareuil',
			'Marcoussis',
			'Lumio',
			'Lucé',
			'Louvigny',
			'Loon-Plage',
			'Longwy',
			'Longny-au-Perche',
			'Liévin',
			'Le Temple-de-Bretagne',
			'Les Ormes',
			'Les Artigues-de-Lussac',
			'Le Palais',
			'Le Monastier-sur-Gazeille',
			'Le Mesnil-Esnard',
			'Leers',
			'Le Cannet',
			'Laval',
			'Laval-en-Belledonne',
			'La Tourlandry',
			'Laruns',
			'Larchamp',
			'Lanmeur',
			'Lanester',
			'Lampaul-Guimiliau',
			'Lalinde',
			'La Jumellière',
			'La Jarrie',
			'La Gouesnière',
			'La Ferrière',
			'La Fère',
			'La Chapelle-sur-Loire',
			'Labastide-Saint-Sernin',
			'Labastide-Saint-Georges',
			'La Barre-de-Monts',
			'Jouy-le-Potier',
			'Jouarre',
			'Joigny',
			'Jarny',
			'Ivry-sur-Seine',
			'Istres',
			'Is-sur-Tille',
			'Igon',
			'Hirson',
			'Herblay',
			'Hautmont',
			'Haucourt',
			'Hatten',
			'Gurgy',
			'Gundershoffen',
			'Guéthary',
			'Guécélard',
			'Grésy-sur-Isère',
			'Grasse',
			'Grandvilliers',
			'Gleizé',
			'Giberville',
			'Gétigné',
			'Frouzins',
			'Frouard',
			'Frontenay-Rohan-Rohan',
			'Freneuse',
			'Fougères',
			'Forges-les-Eaux',
			'Forcalquier',
			'Fontaine-la-Guyon',
			'Flesselles',
			'Flavy-le-Martel',
			'Fismes',
			'Farébersviller',
			'Fains-Véel',
			'Eysines',
			'Étrépagny',
			'Étrelles',
			'Étainhus',
			'Estevelles',
			'Entrange',
			'Enghien-les-Bains',
			'Éguilles',
			'Écoyeux',
			'Échenevex',
			'Durtal',
			'Drain',
			'Donzenac',
			'Domalain',
			'Darvoy',
			'Curgies',
			'Cuffies',
			'Cruas',
			'Croissy-sur-Seine',
			'Crémieu',
			'Creissels',
			'Crêches-sur-Saône',
			'Couzeix',
			'Couternon',
			'Courlay',
			'Coupvray',
			"Coulonges-sur-l'Autize",
			'Corenc',
			'Claye-Souilly',
			'Civrieux',
			'Choisey',
			'Cherveux',
			'Chemaudin',
			'Chauvé',
			'Châtel-Saint-Germain',
			'Château-Landon',
			'Charvieu-Chavagneux',
			'Chars',
			'Champ-sur-Drac',
			'Champagne-sur-Seine',
			'Chailles',
			'Châbons',
			'Chablis',
			'Cesson',
			'Cérences',
			'Carpiquet',
			'Carpentras',
			'Caromb',
			'Carignan',
			'Carignan-de-Bordeaux',
			'Carcans',
			'Capvern',
			'Cancon',
			'Camphin-en-Pévèle',
			'Camphin-en-Carembault',
			'Cabannes',
			'Buzançais',
			'Buhl',
			'Briare',
			'Breuschwickersheim',
			'Brain-sur-Allonnes',
			'Boussois',
			'Bonson',
			'Bonneville',
			'Bonne',
			'Bonnac-la-Côte',
			'Bologne',
			'Boissise-le-Roi',
			'Bligny-lès-Beaune',
			'Blaison-Gohier',
			'Blainville-Crevon',
			'Blain',
			'Bièvres',
			'Belley',
			'Béligneux',
			'Bédoin',
			'Beaulieu-sous-la-Roche',
			'Beaucamps-le-Vieux',
			'Bazouges-sur-le-Loir',
			'Bayonne',
			'Baugy',
			'Barsac',
			'Barjac',
			'Barbaste',
			'Avrillé',
			'Avion',
			'Avallon',
			'Auzat-la-Combelle',
			'Aurillac',
			'Aubigny-sur-Nère',
			'Arudy',
			'Arpajon-sur-Cère',
			'Arc-et-Senans',
			'Apremont',
			'Appoigny',
			'Antrain',
			'Anould',
			'Angevillers',
			'Angerville',
			'Alençon',
			'Alba-la-Romaine',
			'Agon-Coutainville',
			'Agneaux',
			'Ablon',
			'Meudon-la-Forêt',
			'Le Chapitre',
			'Le Camas',
			'La Conception',
			'Saint-Jean du Désert',
			'Yzeures-sur-Creuse',
			'Wimmenau',
			'Vourles',
			'Vouillé',
			'Vitrolles',
			'Vion',
			'Vinay',
			'Vinassan',
			'Villers-sur-Coudun',
			'Villepinte',
			'Villeparisis',
			'Villeneuve-lès-Avignon',
			'Villemeux-sur-Eure',
			'Villedômer',
			"Ville-d'Avray",
			'Vielle-Saint-Girons',
			'Verson',
			'Vernou-sur-Brenne',
			'Vernoil-le-Fourrier',
			'Venoy',
			'Vénissieux',
			'Venarey-les-Laumes',
			'Vaulx-Milieu',
			'Vannes',
			'Vairé',
			'Urt',
			'Trie-sur-Baïse',
			'Tournon-Saint-Martin',
			'Tourcoing',
			'Toulon-sur-Allier',
			'Toufflers',
			'Tigy',
			'Tiffauges',
			'Tessé-la-Madeleine',
			'Taillades',
			'Surtainville',
			'Soussans',
			'Soultzmatt',
			'Sottevast',
			'Serre-les-Sapins',
			'Selongey',
			'Seissan',
			'Sébazac-Concourès',
			'Savasse',
			'Saulcy-sur-Meurthe',
			'Saint-Yrieix-sur-Charente',
			'Saint-Vincent-de-Paul',
			'Saint-Victor',
			'Saint-Viaud',
			'Saint-Sylvain',
			'Saint-Sulpice-la-Forêt',
			'Saint-Sorlin-en-Valloire',
			'Saint-Senoux',
			'Saint-Projet',
			'Saint-Privat',
			'Saint-Pourçain-sur-Sioule',
			'Saint-Pierre-des-Corps',
			'Saint-Pierre-de-Plesguen',
			'Saint-Omer',
			'Saint-Nazaire',
			'Saint-Michel-Mont-Mercure',
			'Saint-Maurice-en-Gourgois',
			'Saint-Maur-des-Fossés',
			'Saint-Mathurin',
			'Saint-Marcel',
			'Saint-Mamet-la-Salvetat',
			'Saint-Lyphard',
			'Saint-Lupicin',
			'Saint-Léger-des-Vignes',
			'Saint-Laurent-des-Arbres',
			'Saint-Laurent',
			'Saint-Josse',
			'Saint-Herblon',
			'Saint-Girons',
			'Saint-Gaudens',
			'Saint-Fargeau',
			'Sainte-Terre',
			'Sainte-Marthe',
			'Saint-Denis-de-Cabanne',
			'Saint-Cyr-en-Bourg',
			'Saint-Clément-des-Levées',
			'Saint-Chinian',
			'Saint-Cézaire-sur-Siagne',
			'Saint-Antoine-de-Breuilh',
			'Saint-André',
			'Saint-Amans-Soult',
			'Sains-du-Nord',
			'Rue',
			'Roz-Landrieux',
			'Rouffiac-Tolosan',
			'Roubaix',
			'Rœux',
			'Rodez',
			'Rivière',
			'Rieux',
			'Reynès',
			'Réding',
			'Raon-aux-Bois',
			'Rannée',
			'Quissac',
			'Quinssaines',
			'Quetigny',
			'Quessoy',
			'Quesnoy-sur-Deûle',
			'Puygouzon',
			'Puget-Ville',
			'Pont-Remy',
			'Ponthierry',
			'Pont-de-Veyle',
			'Pont-de-Larn',
			'Pontailler-sur-Saône',
			'Pontacq',
			'Plouguin',
			'Plougourvest',
			'Plouëc-du-Trieux',
			'Pezens',
			'Petit-Réderching',
			'Peri',
			'Pennautier',
			'Oullins',
			'Ossun',
			'Ossé',
			'Orgeval',
			'Olmeto',
			'Betschdorf',
			'Nouvion-sur-Meuse',
			'Nœux-les-Mines',
			'Niedernai',
			'Moussac',
			'Mortagne-au-Perche',
			'Mormant',
			'Moreuil',
			'Moréac',
			"Montesquiu d'Albera",
			'Montauban',
			'Montamisé',
			'Monestiés',
			'Mions',
			'Millery',
			'Messac',
			'Mercury',
			'Mellecey',
			'Mehun-sur-Yèvre',
			'Mazé',
			'Mazargues',
			'Margon',
			'Margny-lès-Compiègne',
			'Margency',
			'Lugrin',
			'Loury',
			'Lorgues',
			'Lopérec',
			'Lons-le-Saunier',
			'Longvic',
			'Lompret',
			'Lombez',
			'Lit-et-Mixe',
			'Liancourt',
			'Leyme',
			'Lèves',
			'Le Touquet-Paris-Plage',
			'Lessay',
			'Lésigny',
			'Les Clayes-sous-Bois',
			'Le Poujol-sur-Orb',
			'Le Pin-en-Mauges',
			'Le Malzieu-Ville',
			'Le Luc',
			'Le Haillan',
			'Le Blanc-Mesnil',
			'La Voulte-sur-Rhône',
			'Lavans-lès-Saint-Claude',
			'Lautenbachzell',
			'Laure-Minervois',
			'La Séauve-sur-Semène',
			'La Roche-Vineuse',
			'Larajasse',
			'Langres',
			'Landeronde',
			'La Mulatière',
			'La Glacerie',
			'La Force',
			'La Ferrière-aux-Étangs',
			'La Coquille',
			'La Chapelle-Saint-Luc',
			'La Chapelle-du-Genêt',
			'La Boissière-des-Landes',
			'Kernilis',
			'Juillan',
			'Jougne',
			'Jenlain',
			'Issoire',
			'Houdemont',
			'Hinges',
			'Heuringhem',
			'Henvic',
			'Haveluy',
			'Hautefort',
			'Hangenbieten',
			'Guiclan',
			'Grosseto-Prugna',
			'Gros-Réderching',
			'Grignols',
			'Gièvres',
			'Genech',
			'Gargas',
			'Ganges',
			'Fuveau',
			'Furiani',
			'Froideconche',
			'Frévent',
			'Francheleins',
			'Foucarmont',
			'Fontaines',
			'Flassans-sur-Issole',
			'Figeac',
			'Fellering',
			'Falaise',
			'Eyzin-Pinet',
			'Évenos',
			'Étival-Clairefontaine',
			'Équihen-Plage',
			'Épehy',
			'Emmerin',
			'Écaillon',
			'Durrenbach',
			'Dirac',
			'Denicé',
			'Crouy-en-Thelle',
			'Crissey',
			'Craon',
			'Coulombs',
			'Coulogne',
			'Corps-Nuds',
			'Coron',
			'Corneilhan',
			'Conquereuil',
			'Coincy',
			'Cognac-la-Forêt',
			'Clisson',
			'Clary',
			'Chouzé-sur-Loire',
			'Chazey-sur-Ain',
			'Chaussin',
			'Château-Renard',
			'Chassieu',
			'Charbuy',
			'Chapet',
			'Chantenay-Saint-Imbert',
			'Champs-sur-Tarentaine-Marchal',
			'Champfleur',
			'Chamboulive',
			'Châlonvillars',
			'Cérilly',
			'Cazes-Mondenard',
			'Cazères',
			'Caudry',
			'Caudan',
			'Capbreton',
			'Cadillac',
			'Buxy',
			'Buxières-les-Mines',
			'Bussac-sur-Charente',
			'Bully-les-Mines',
			'Bugeat',
			'Brouckerque',
			'Bouvignies',
			'Boutiers-Saint-Trojan',
			'Boussy-Saint-Antoine',
			'Bourg-Achard',
			'Bourbriac',
			'Bouray-sur-Juine',
			'Boujan-sur-Libron',
			'Bouffémont',
			'Bornel',
			'Bon-Encontre',
			'Boëge',
			'Bierne',
			'Bergholtz',
			'Berck-Plage',
			'Bénouville',
			'Beauvais',
			'Beaune-la-Rolande',
			'Beaumont-de-Lomagne',
			'Beaumont',
			'Beaucroissant',
			'Bazancourt',
			'Bassens',
			'Barlin',
			'Balma',
			'Bagard',
			'Azay-le-Brûlé',
			'Ayguemorte-les-Graves',
			'Auzeville-Tolosane',
			'Aumont-Aubrac',
			'Augy',
			'Aubry-du-Hainaut',
			'Athies-sous-Laon',
			'Arthaz-Pont-Notre-Dame',
			'Arcueil',
			'Arcangues',
			'Arbus',
			'Antonne',
			'Antigny',
			'Antibes',
			'Anthy-sur-Léman',
			'Anizy-le-Château',
			'Andernos-les-Bains',
			'Amnéville',
			'Amfreville-la-Mi-Voie',
			'Alfortville',
			'Alès',
			'Aimargues',
			'Aiffres',
			'Agde',
			'Afa',
			'Acigné',
			'Ablon-sur-Seine',
			'La Villette',
			'Palais de Justice',
			'Le Pharo',
			'Périer',
			'Les Trois-Lucs',
			'Saint-Barthélémy',
			'Willems',
			'Weitbruch',
			'Wardrecques',
			'Wangenbourg-Engenthal',
			'Wailly',
			'Vivy',
			'Viry-Châtillon',
			'Vimory',
			'Villeréal',
			'Villepreux',
			'Villemomble',
			'Villebret',
			'Vigneulles-lès-Hattonchâtel',
			'Vers-Pont-du-Gard',
			'Vern-sur-Seiche',
			"Vern-d'Anjou",
			'Velaux',
			'Vaux-sur-Mer',
			'Vallières',
			'Valff',
			'Vaires-sur-Marne',
			'Vacqueyras',
			'Unieux',
			'Ully-Saint-Georges',
			'Truyes',
			'Trévoux',
			'Tresques',
			'Touvois',
			'Thil',
			'Talence',
			'Talant',
			'Still',
			'Steenwerck',
			'Soues',
			'Soudan',
			'Soucht',
			'Sospel',
			'Sorel-Moussel',
			'Serris',
			'Serques',
			'Sérent',
			'Septème',
			'Sélestat',
			'Ségny',
			'Sayat',
			'Savigny-lès-Beaune',
			'Saussan',
			'Saulce-sur-Rhône',
			'Saugnac-et-Cambran',
			'Sarre-Union',
			'Sarrebourg',
			'Saint-Zacharie',
			'Saint-Vite',
			'Saint-Vincent-de-Mercuze',
			'Saint-Sulpice-sur-Lèze',
			'Saint-Romain-de-Jalionas',
			'Saint-Père-sur-Loire',
			'Saint-Michel-de-Maurienne',
			'Saint-Memmie',
			'Saint-Médard-de-Mussidan',
			'Saint-Mathurin-sur-Loire',
			'Saint-Martin-sur-Ocre',
			'Saint-Martin-du-Var',
			'Saint-Martin-de-Hinx',
			'Saint-Mandé',
			'Saint-Laurent-sur-Gorre',
			'Saint-Laurent-en-Grandvaux',
			'Saint-Just-la-Pendue',
			'Saint-Julien-de-Concelles',
			'St. Jean-Pla-de-Corts',
			'Saint-Jean-de-Liversay',
			'Saint-Jean-de-Braye',
			'Saint-Jacques-sur-Darnétal',
			'Saint-Hippolyte',
			'Saint-Germain-Laval',
			'Saint-Germain-des-Prés',
			'Saint-Germain-de-la-Grange',
			'Saint-Georges-du-Bois',
			'Saint-Genest-Malifaux',
			'Saint-Étienne-de-Saint-Geoirs',
			'Saint-Étienne-de-Montluc',
			'Saint-Étienne-de-Mer-Morte',
			'Saint-Étienne-de-Crossey',
			'Sainte-Maxime',
			'Sainte-Marie-Plage',
			'Sainte-Marie-aux-Chênes',
			'Sainte-Marguerite',
			'Sainte-Adresse',
			'Saint-Denis-les-Ponts',
			'Saint-Christophe-Vallon',
			'Saint-Cergues',
			'Saint-Caradec',
			'Saint-Avold',
			'Saint-Amour',
			'Ruy',
			'Rozay-en-Brie',
			'Richelieu',
			'Reuilly',
			'Rety',
			'Rémalard',
			'Ravenel',
			'Radon',
			'Quimper',
			'Quiévy',
			'Poulx',
			'Portvendres',
			'Pont-sur-Yonne',
			'Pont-sur-Sambre',
			'Pont-Sainte-Marie',
			'Pluméliau',
			'Plougoulm',
			'Plombières-lès-Dijon',
			'Plogoff',
			'Plestin-les-Grèves',
			'Pléhédel',
			'Pinet',
			'Pierre-Bénite',
			'Pibrac',
			'Perriers-sur-Andelle',
			'Périgny',
			'Périgneux',
			'Pédernec',
			'Paulhac',
			'Paray-Vieille-Poste',
			'Pannes',
			'Otterswiller',
			'Oppède le Vieux',
			'Opio',
			'Omissy',
			'Olonne-sur-Mer',
			'Nogent-le-Bas',
			'Niherne',
			'Neuilly-sur-Marne',
			'Nargis',
			'Moussy-le-Vieux',
			'Mours-Saint-Eusèbe',
			'Mouret',
			'Moulins-Engilbert',
			'Morvillars',
			'Morteau',
			'Montussan',
			'Montségur-sur-Lauzon',
			'Montréal',
			'Montmorot',
			'Montmirail',
			'Montéléger',
			'Montbard',
			'Moislains',
			'Migné',
			'Migennes',
			'Méréville',
			'Méré',
			'Méounes-lès-Montrieux',
			'Menneval',
			'Mécleuves',
			'Maxéville',
			'Maulévrier',
			'Maubourguet',
			'Marmagne',
			'Marlhes',
			'Mareuil-lès-Meaux',
			'Marconnelle',
			'Marcilly-sur-Eure',
			'Marcigny',
			'Marchiennes',
			'Manosque',
			'Mametz',
			'Mamers',
			'Malguénac',
			'Malay-le-Grand',
			'Magny-Cours',
			'Macheren',
			"L'Union",
			'Luneray',
			'Lormont',
			'Loriol-sur-Drôme',
			'Longeville-sur-Mer',
			'Longages',
			'Livarot',
			'Linxe',
			'Limours',
			'Liffol-le-Grand',
			'Lieusaint',
			'Lézigneux',
			'Le Val-Saint-Père',
			"L'Estaque",
			'Les Lilas',
			'Lescar',
			'Les Brouzils',
			'Les Attaques',
			'Lepuix',
			'Le Palais-sur-Vienne',
			'Le Donjon',
			'Le Crès',
			'Le Châtelet',
			'Le Breuil',
			'Lautenbach',
			'La Turbie',
			'La Salvetat-sur-Agout',
			'Laroque-des-Albères',
			'Lansargues',
			'Lanobre',
			'Langoat',
			'Landrévarzec',
			'Landerneau',
			'Landéhen',
			'La Londe-les-Maures',
			'La Haye-Pesnel',
			'Lagnieu',
			'Labry',
			'La Boussac',
			'Labastide-Rouairoux',
			'Kruth',
			'Kintzheim',
			'Kilstett',
			'Kédange-sur-Canner',
			'Juilly',
			'Jardin',
			'Issenheim',
			'Isques',
			'Isneauville',
			'Isigny-sur-Mer',
			'Igny',
			'Huningue',
			'Hautot-sur-Mer',
			'Harly',
			'Groix',
			'Grandcamp-Maisy',
			'Givry',
			'Gignac-la-Nerthe',
			'Gien',
			'Gémozac',
			'Gauchy',
			'Gap',
			'Gan',
			'Gambsheim',
			'Gallardon',
			'Fontvieille',
			'Fontaine-au-Pire',
			'Fondettes',
			'Florange',
			'Fleury',
			'Fleurieu-sur-Saône',
			'Féchain',
			'Falleron',
			'Fabrezan',
			'Eyguières',
			'Eybens',
			'Étalans',
			'Équemauville',
			'Elliant',
			'Eckbolsheim',
			'Duttlenheim',
			'Duisans',
			'Drumettaz',
			'Dreslincourt',
			'Dortan',
			'Domont',
			'Domart-en-Ponthieu',
			'Digosville',
			'Dieulouard',
			'Dieue-sur-Meuse',
			'Deville',
			'Damery',
			'Cresserons',
			'Courzieu',
			'Courville-sur-Eure',
			'Courteilles',
			'Courpalay',
			'Cormeray',
			'Connerré',
			'Congis-sur-Thérouanne',
			'Combrit',
			'Clérac',
			'Clarensac',
			'Clairoix',
			'Chimilin',
			'Cherbourg-Octeville',
			'Chécy',
			'Chauvigny',
			'Chauny',
			'Charquemont',
			'Chaptelat',
			'Chapdes-Beaufort',
			'Chantonnay',
			'Champagne-sur-Oise',
			'Chambly',
			'Castillon-la-Bataille',
			'Castanet-Tolosan',
			'Cassel',
			'Canet-en-Roussillon',
			'Campénéac',
			'Bretoncelles',
			'Breil-sur-Roya',
			'Brax',
			'Bout-du-Pont-de-Larn',
			'Boos',
			'Blainville-sur-Orne',
			'Blaincourt-lès-Précy',
			'Biarritz',
			'Bessières',
			'Belleville-sur-Loire',
			'Beautor',
			'Beaulieu-lès-Loches',
			'Beaulieu',
			'Bavent',
			'Bauvin',
			'Barenton',
			'Barby',
			'Barbizon',
			'Bais',
			'Baignes-Sainte-Radegonde',
			'Ay',
			'Avesnelles',
			'Authie',
			'Aureille',
			'Aunay-sur-Odon',
			'Aumetz',
			'Aulnoy-lez-Valenciennes',
			'Aubenas',
			'Attiches',
			'Aspremont',
			'Arzens',
			'Artres',
			'Argentat',
			'Arçonnay',
			'Anse',
			'Angles',
			'Allouagne',
			'Allonzier-la-Caille',
			'Airaines',
			'La Palme',
			'La Defense',
			'Saint-Giniez',
			'Belle de Mai',
			'La Calade',
			'Yves',
			'Yssingeaux',
			'Yainville',
			'Witry-lès-Reims',
			'Marsillargues',
			'Wervicq-Sud',
			'Wattwiller',
			'Viviers-lès-Montagnes',
			'Vitry-le-François',
			'Virazeil',
			'Vimy',
			'Villiers-sur-Morin',
			'Ville-sous-Anjou',
			'Villennes-sur-Seine',
			'Villegouge',
			'Veyras',
			'Vertus',
			'Vernon',
			'Valenciennes',
			'Tulle',
			'Troyes',
			'Tricot',
			'Treillières',
			'Tournehem-sur-la-Hem',
			'Toucy',
			'Tillières-sur-Avre',
			'Tillières',
			'Thiberville',
			'Thenon',
			'Theillay',
			'Templeuve-en-Pévèle',
			'Templemars',
			'Tavaux',
			'Taulignan',
			'Tanlay',
			'Sorèze',
			'Sonzay',
			'Solers',
			'Six-Fours-les-Plages',
			'Sissonne',
			'Seyssuel',
			'Seyne-les-Alpes',
			'Ladoix-Serrigny',
			'Séné',
			'Semblançay',
			'Seigy',
			'Séez',
			'Savigny-en-Véron',
			'Savigné-sur-Lathan',
			'Savennières',
			'Sarreguemines',
			'San-Martino-di-Lota',
			'Samer',
			'Saint-Yrieix-la-Perche',
			'Saint-Vivien-de-Médoc',
			'Saint-Sulpice-le-Guérétois',
			'Saint-Sulpice',
			'Saint-Sauveur-des-Landes',
			'Saint-Romans',
			'Saint-Quentin-sur-Isère',
			'Saint-Quentin-Fallavier',
			'Saint-Paul',
			'Saint-Paul-de-Varces',
			'Saint-Parize-le-Châtel',
			'Saint-Ouen-du-Tilleul',
			'Saint-Mitre-les-Remparts',
			'Saint-Menges',
			"Saint-Médard-d'Eyrans",
			'Saint-Maxire',
			'Saint-Martin-de-Valgalgues',
			'Saint-Martin-de-Nigelles',
			'Saint-Léonard',
			'Saint-Laurent-des-Autels',
			'Saint-Lary-Soulan',
			'Saint-Just',
			'Saint-Jean-la-Poterie',
			'Saint-Jean-de-Bournay',
			'Saint-Hilaire-la-Palud',
			'Saint-Gilles-Croix-de-Vie',
			'Saint-Gilles',
			'Saint-Germain-Nuelles',
			'Saint-Germain-du-Corbéis',
			'Saint-Germain-des-Fossés',
			'Saint-Geoire-en-Valdaine',
			'Saint-Geniès-de-Malgoirès',
			'Saint-Florent-le-Vieil',
			'Saint-Eustache-la-Forêt',
			'Saint-Étienne-du-Bois',
			'Sainte-Radegonde',
			'Sainte-Feyre',
			'Saint-Brice-sur-Vienne',
			'Saint-Brès',
			'Saint-Avé',
			'Saint-Amand-Longpré',
			'Saint-Agnant',
			'Saint-Affrique',
			'Sablons',
			'Russange',
			'Rumersheim-le-Haut',
			'Rouillac',
			'Roquebrune-Cap-Martin',
			'Romorantin-Lanthenay',
			'Rohrbach-lès-Bitche',
			'Rocroi',
			'Rieulay',
			'Ribérac',
			'Reugny',
			'Quilly',
			'Proville',
			'Prix-lès-Mézières',
			'Pouancé',
			'Portet-sur-Garonne',
			'Pont-Salomon',
			'Pontcharra',
			'Plumieux',
			'Plouguiel',
			'Plouaret',
			'Pleudaniel',
			'Plédéliac',
			'Pia',
			'Pézenas',
			'Pers-Jussy',
			'Périgueux',
			'Pendé',
			'Pamiers',
			'Orvault',
			'Oletta',
			'Nyons',
			'Nueil-les-Aubiers',
			'Noyal-sur-Vilaine',
			'Novillars',
			'Nontron',
			'Noé',
			'Neufchâtel-en-Bray',
			'Neauphle-le-Château',
			'Nans-les-Pins',
			'Nangis',
			'Nandy',
			'Nalliers',
			'Nailloux',
			'Muizon',
			'Moussy-le-Neuf',
			'Montsûrs',
			'Montry',
			'Montpezat-de-Quercy',
			'Montivilliers',
			'Montigny-sur-Loing',
			'Montfrin',
			'Montcresson',
			'Montarnaud',
			'Montagne',
			'Monchecourt',
			'Mimizan',
			'Mézières-en-Brenne',
			'Mercy-le-Bas',
			'Melrand',
			'Meilhan',
			'Maussane-les-Alpilles',
			'Maureillas-las-Illas',
			'Maurecourt',
			'Martillac',
			'Martignat',
			'Marsac-en-Livradois',
			'Mareil-Marly',
			'Marçon',
			'Marcillac',
			'Mantes-la-Jolie',
			'Mancieulles',
			'Mallemort',
			'Lutterbach',
			'Lourmarin',
			'Loos',
			'Lognes',
			'Lillers',
			'Ligny-en-Barrois',
			'Lièpvre',
			'Lézardrieux',
			'Le Thillot',
			"L'Escale",
			'Les Avenières',
			'Le Plessis-Macé',
			'Petit-Couronne',
			'Lencloître',
			'Le Houga',
			'Laussonne',
			"Laudun-l'Ardoise",
			'La Remaudière',
			'La Queue-les-Yvelines',
			'Laplume',
			'La Penne-sur-Huveaune',
			'Langeais',
			'Lamalou-les-Bains',
			'Lalbenque',
			'Lagnes',
			'La Garde',
			'Lacrouzette',
			'La Courtine',
			'La Chapelle-Saint-Ursin',
			'La Chapelle-Achard',
			'La Broque',
			'La Bernardière',
			'Labège',
			'Jujurieux',
			'Juigné-sur-Sarthe',
			'Jons',
			'Huriel',
			'Hirtzfelden',
			'Héric',
			'Hargarten-aux-Mines',
			'Hallennes-lez-Haubourdin',
			'Hagenthal-le-Bas',
			'Guignicourt',
			'Groslay',
			'Grenay',
			'Gragnague',
			'Grabels',
			'Gondrecourt-le-Château',
			'Givrand',
			'Ginasservis',
			'Genlis',
			'Gelos',
			'Galgon',
			'Gainneville',
			'Furdenheim',
			'Fonsorbes',
			'Fléac',
			'Fesches-le-Châtel',
			'Feneu',
			'Évran',
			'Espalion',
			'Ercé-près-Liffré',
			'Douzy',
			'Douvres-la-Délivrande',
			'Doubs',
			'Donzère',
			'Dommartin-lès-Remiremont',
			'Dhuizon',
			'Denain',
			'Dargnies',
			'Dampierre',
			'Damgan',
			'Cussac-Fort-Médoc',
			'Cunlhat',
			'Coudun',
			'Coubon',
			'Cornimont',
			'Conflans-en-Jarnisy',
			'Condat-sur-Vienne',
			'Conches-sur-Gondoire',
			'Colombiers',
			'Colomars',
			'Cloyes-sur-le-Loir',
			'Cléden-Poher',
			'Chouzy-sur-Cisse',
			'Cheux',
			'Chemazé',
			'Chauray',
			'Châtillon-sur-Thouet',
			'Châteauneuf-du-Rhône',
			'Chasse-sur-Rhône',
			'Changis-sur-Marne',
			'Champs-sur-Yonne',
			'Champcueil',
			'Chambourcy',
			'Chalon-sur-Saône',
			'Challes',
			'Chalifert',
			'Chaleins',
			'Chaillé-les-Marais',
			'Castries',
			'Carsac-Aillac',
			'Carqueiranne',
			'Canteleu',
			'Camon',
			'Cambremer',
			'Buironfosse',
			'Bruges',
			'Brissac-Quincé',
			"Bretteville-l'Orgueilleuse",
			'Brantôme',
			'Bourlon',
			'Bourg-lès-Valence',
			'Bourg-des-Comptes',
			'Bourg-Blanc',
			'Boulleret',
			'Bouillargues',
			'Bords',
			'Bonnat',
			'Boismé',
			'Blanzy',
			'Biéville-Beuville',
			'Bidache',
			'Biache-Saint-Vaast',
			'Bessancourt',
			'Bernardswiller',
			'Belcodène',
			'Beaumont-du-Gâtinais',
			'Baulon',
			'Battenheim',
			'Barbezieux-Saint-Hilaire',
			'Banyuls-dels-Aspres',
			'Ballainvilliers',
			'Baincthun',
			'Bailleval',
			'Ax-les-Thermes',
			'Avon',
			'Auvers-le-Hamon',
			'Ansauvillers',
			'Anor',
			'Anduze',
			'Andancette',
			'Biol',
			'Chutes-Lavie',
			'Bully',
			'Walbourg',
			'Visan',
			'Virey-le-Grand',
			'Villeneuve-la-Garenne',
			'Villemandeur',
			'Villefontaine',
			'Vert-Saint-Denis',
			'Vernouillet',
			'Verneuil-sur-Avre',
			'Vedène',
			'Varennes-sur-Loire',
			'Vals-les-Bains',
			'Valras-Plage',
			'Uriménil',
			'Tulette',
			'Triguères',
			'Trédrez-Locquémeau',
			'Thumeries',
			'Thomery',
			'Surzur',
			'Soumoulou',
			'Souffelweyersheim',
			'Sion-les-Mines',
			'Seyssins',
			'Seynod',
			'Sévignac',
			'Senonches',
			'Seilh',
			'Seignelay',
			'Schweighouse-sur-Moder',
			'Savigny-sur-Braye',
			'Saultain',
			'Sallaumines',
			'Salindres',
			'Salignac',
			'Saint-Yzan-de-Soudiac',
			'Saint-Viance',
			'Saint-Usage',
			'Saint-Trivier-sur-Moignans',
			'Saint-Sulpice-les-Feuilles',
			'Saint-Sulpice-de-Royan',
			'Saint-Sulpice-de-Cognac',
			'Saint-Sauveur',
			'Saint-Saturnin-sur-Loire',
			'Saint-Romain-le-Puy',
			'Saint-Pons-de-Thomières',
			'Saint-Pierre-la-Cour',
			'Saint-Paul-lès-Romans',
			'Saint-Pardoux-Isaac',
			'Saint-Paër',
			'Saint-Michel-sur-Orge',
			'Saint-Maurice-sur-Fessard',
			'Saint-Mammès',
			'Sant Llorenç de Cerdans',
			'Saint-Laurent-de-Brévedent',
			'Saint-Hilaire-des-Loges',
			'Saint-Hilaire',
			'Saint-Germain-les-Belles',
			'Saint-Étienne-de-Tinée',
			'Sainte-Suzanne',
			'Sainte-Marie-des-Champs',
			'Sainte-Colombe',
			'Saint-Désir',
			'Saint-Denis-de-Gastines',
			"Saint-Ciers-d'Abzac",
			'Saint-Christol-les-Alès',
			'Saint-Astier',
			'Saint-Antoine',
			'Saint-Andéol-le-Château',
			'Saint-Alban-de-Roche',
			'Saint-Alban',
			'Saâcy-sur-Marne',
			'Rozérieulles',
			'Roullet-Saint-Estèphe',
			'Romagné',
			'Rognac',
			'Roézé-sur-Sarthe',
			'Rodilhan',
			'Rives',
			'Riom',
			'Ribaute-les-Tavernes',
			'Rebais',
			'Réalmont',
			'Râches',
			'Rabastens',
			'Puylaurens',
			'Prigonrieux',
			'Preuilly-sur-Claise',
			'Pouillon',
			'Porcheville',
			'Pommeret',
			'Poligny',
			'Plozévet',
			'Ploumoguer',
			'Ploumagoar',
			'Plouider',
			'Plouézec',
			'Plouër-sur-Rance',
			'Ploubalay',
			'Plonévez-du-Faou',
			'Plomelin',
			'Plaisance',
			'Peyrolles-en-Provence',
			'Peyriac-Minervois',
			'Peypin',
			'Persan',
			'Pérouges',
			'Peipin',
			'Ouges',
			'Osséja',
			'Oissel',
			'Oermingen',
			'Oberschaeffolsheim',
			'Nozay',
			'Nogent-le-Phaye',
			'Niederhausbergen',
			'Niederhaslach',
			'Nevoy',
			'Narrosse',
			'Nanteuil-en-Vallée',
			'Naizin',
			'Nages-et-Solorgues',
			'Mussidan',
			'Mus',
			'Mouthiers-sur-Boëme',
			'Moulay',
			'Mortrée',
			'Morsbach',
			'Mont-Saint-Martin',
			'Montrodat',
			'Montrem',
			'Montréal',
			'Mont-près-Chambord',
			'Montfaucon-Montigné',
			'Montenay',
			'Montagny',
			'Monségur',
			'Monflanquin',
			'Moisdon-la-Rivière',
			'Mirepeix',
			'Millas',
			'Mettray',
			'Méry',
			'Méricourt',
			'Mées',
			'May-sur-Orne',
			'Mauriac',
			'Masevaux',
			'Martin-Église',
			'Marles-les-Mines',
			'Marlenheim',
			'Mareuil-sur-Lay-Dissais',
			'Marennes',
			'Marennes',
			'Marckolsheim',
			'Maillot',
			'Magny-le-Hongre',
			'Magné',
			'Magnanville',
			'Maché',
			'Luitré',
			'Lucenay',
			'Louargat',
			'Louannec',
			'Longuenesse',
			'Londinières',
			'Loisin',
			'Locoal-Mendon',
			'Ligny-le-Châtel',
			'Les Riceys',
			'Le Sappey-en-Chartreuse',
			'Les Abrets',
			'Le Pizou',
			'Le Grand-Pressigny',
			'Le Creusot',
			'Le Coudray-Montceaux',
			'Le Cellier',
			'Lavaur',
			'Lauris',
			'La Terrasse',
			'La Roche-sur-Yon',
			'La Roche-Derrien',
			'La Possonnière',
			'La Pommeraye',
			'Lannion',
			'Langueux',
			'Langon',
			'Langogne',
			'Lamotte-Beuvron',
			'La Motte',
			'La Milesse',
			'La Garde-Freinet',
			'La Forest-Landerneau',
			'La Colle-sur-Loup',
			'La Clayette',
			'La Ciotat',
			'La Chapelle-Launay',
			'La Chapelle-Janson',
			'La Celle-sur-Morin',
			'Knutange',
			'Juvisy-sur-Orge',
			'Jugon-les-Lacs',
			'Jouques',
			'Job',
			'Izernore',
			'Hoymille',
			'Houdan',
			'Holtzwihr',
			'Holtzheim',
			'Hauterive',
			'Hasparren',
			'Guéreins',
			'Guérard',
			'Gramat',
			'Gourdon',
			'Fublaines',
			'Foissiat',
			'Flines-lez-Raches',
			'Fleury-les-Aubrais',
			'Fleury',
			'Fitilieu',
			'Férolles',
			'Faches-Thumesnil',
			'Évires',
			'Étréchy',
			'Erdeven',
			'Epiniac',
			'Englefontaine',
			'Élancourt',
			'Éguzon-Chantôme',
			'Égreville',
			'Eaubonne',
			'Dunkerque',
			'Duclair',
			'Drap',
			'Douarnenez',
			'Donzy',
			'Donnery',
			'Dompierre-sur-Mer',
			'Diesen',
			'Denée',
			'Dangé-Saint-Romain',
			'Damprichard',
			'Dambach-la-Ville',
			'Culoz',
			'Cuise-la-Motte',
			'Crouy-sur-Ourcq',
			'Crouy',
			'Creysse',
			'Cravanche',
			'Courcelles-lès-Montbéliard',
			'Coulombiers',
			'Cossé-le-Vivien',
			'Corsept',
			'Corbarieu',
			'Condé-Sainte-Libiaire',
			'Coëtmieux',
			'Cires-lès-Mello',
			'Châtellerault',
			'Château-la-Vallière',
			'Château-Gaillard',
			'Charmes-sur-Rhône',
			'Champsecret',
			'Chamant',
			'Chamagnieu',
			'Ceyrat',
			'Ceton',
			'Cernay',
			'Caumont-sur-Durance',
			'Castets',
			'Casteljaloux',
			'Carmaux',
			'Carantec',
			'Cancale',
			'Butry-sur-Oise',
			'Canet',
			'Cabariot',
			'Bussières',
			'Buchères',
			'Bû',
			'Bréhand',
			'Bras',
			'Bram',
			'Bourth',
			'Bourg',
			'Boisseuil',
			'Boisset',
			'Berck',
			'Benquet',
			'Bellignat',
			'Beauvois-en-Cambrésis',
			'Bauné',
			'Batz-sur-Mer',
			'Bagnac-sur-Célé',
			'Avermes',
			'Auzances',
			'Ascain',
			'Arvert',
			'Arnay-le-Duc',
			'Arbonne-la-Forêt',
			'Aouste-sur-Sye',
			'Ambronay',
			'Amanlis',
			'Allex',
			'Allègre',
			'Algolsheim',
			'Wattignies',
			'Vred',
			'Volvic',
			'Vœuil-et-Giget',
			'Villevocance',
			'Villers-Pol',
			"Villeneuve-d'Olmes",
			'Villeneuve',
			'Villedieu-les-Poêles',
			'Villecroze',
			'Villar-Saint-Pancrace',
			'Vienne',
			'Vias',
			'Vezin-le-Coquet',
			'Versailles',
			'Vendin-lès-Béthune',
			'Vaux-sur-Seine',
			'Vaux-en-Bugey',
			'Vaulnaveys-le-Haut',
			'Turckheim',
			'Trémuson',
			'Trémery',
			'Trans-en-Provence',
			'Tournon-sur-Rhône',
			'Thil',
			'Terrasson-Lavilledieu',
			'Tennie',
			'Taulé',
			'Tartas',
			'Taluyers',
			'Talensac',
			'Tacoignières',
			'Soyaux',
			'Soustons',
			'Sorbiers',
			'Sonnaz',
			'Simiane-Collongue',
			'Simandres',
			'Seraincourt',
			'Sequedin',
			'Saône',
			'Salviac',
			'Sallertaine',
			'Salins-les-Bains',
			'Saint-Vincent-sur-Graon',
			'Saint-Sulpice-de-Pommeray',
			'Saint-Savinien',
			'Saint-Satur',
			'Saint-Quentin-en-Mauges',
			'Saint-Prouant',
			'Saint-Pierre-du-Vauvray',
			'Saint-Paterne',
			'Saint-Pargoire',
			'Saint-Pair-sur-Mer',
			'Saint-Même-le-Tenu',
			'Saint-Médard-sur-Ille',
			'Saint-Mathieu-de-Tréviers',
			'Saint-Laurent-sur-Saône',
			'Saint-Just-en-Chaussée',
			'Saint-Jean-du-Gard',
			'Saint-Jean-des-Champs',
			'Saint-Jean-des-Baisants',
			'Saint-Jean-de-Sauves',
			'Saint-Jean-de-Gonville',
			'Saint-Hippolyte',
			"Saint-Gervais-d'Auvergne",
			'Saint-Germain-du-Plain',
			'Saint-Georges-de-Luzençon',
			'Sainte-Pazanne',
			'Sainte-Marguerite-sur-Duclair',
			'Sainte-Lizaigne',
			'Saint-Didier-en-Velay',
			'Saint-Denis-sur-Sarthon',
			'Saint-Clément-de-la-Place',
			'Saint-Ciers-sur-Gironde',
			'Saint-Christophe-du-Bois',
			'Saint-Bonnet-les-Oules',
			'Saint-Aubin-sur-Scie',
			'Rurange-lès-Thionville',
			'Rots',
			'Rothau',
			'Rixheim',
			'Riez',
			'Rieux-Minervois',
			'Renage',
			'Remoulins',
			'Reims',
			'Régusse',
			'Rauzan',
			'Rai',
			'Quincy-sous-Sénart',
			'Pruniers-en-Sologne',
			'Préchac',
			'Pont-Saint-Martin',
			'Pont-de-Roide',
			'Pompey',
			'Plouasne',
			'Plénée-Jugon',
			'Pleaux',
			'Plaisir',
			'Pithiviers-le-Vieil',
			'Pinsaguel',
			'Pietranera',
			'Piennes',
			'Pfaffenhoffen',
			'Peyrins',
			'Peymeinade',
			'Pérols',
			'Pérignat-lès-Sarliève',
			'Peillonnex',
			'Pechbonnieu',
			'Ornaisons',
			'Orliénas',
			'Noyal-Muzillac',
			'Noirétable',
			'Niévroz',
			'Neuvy-sur-Barangeon',
			'Neuvy-Saint-Sépulchre',
			'Neuvic',
			'Nanteuil-le-Haudouin',
			'Morzine',
			'Morhange',
			'Mordelles',
			'Montsoult',
			'Montrichard',
			'Montpeyroux',
			'Montigny-en-Gohelle',
			'Montcy-Notre-Dame',
			'Montbrison',
			'Montady',
			'Monnaie',
			'Moidieu',
			'Meursac',
			'Meulan-en-Yvelines',
			'Mesquer',
			'Méry-sur-Oise',
			'Merdrignac',
			'Marquise',
			'Marigny',
			'Margaux',
			'Marcq-en-Barœul',
			'Marciac',
			'Marboué',
			'Mandres-les-Roses',
			'Malijai',
			'Magalas',
			'Lys-lez-Lannoy',
			'Lunel',
			'Lumbres',
			'Louvie-Juzon',
			'Lorient',
			'Longchaumois',
			"L'Isle-d'Abeau",
			'Limoux',
			'Limerzel',
			'Liffré',
			'Le Theil-de-Bretagne',
			'Lespinasse',
			'Les Moutiers-en-Retz',
			'Le Plessis-Trévise',
			'Le Perray-en-Yvelines',
			'Le Pêchereau',
			'Lentigny',
			'Le Nouvion-en-Thiérache',
			'Lempdes',
			"Le Mas-d'Agenais",
			'Le Gua',
			'Legé',
			'La Verrie',
			'Lautrec',
			'La Tour-du-Pin',
			'Lassay-les-Châteaux',
			'La Romagne',
			'La Rochette',
			'La Queue-en-Brie',
			'Lantic',
			'Langlade',
			'Landemont',
			'Lamothe-Montravel',
			'Lamagistère',
			'La Haye-Malherbe',
			'La Guyonnière',
			'La Fouillouse',
			'La Fouillade',
			'La Clusaz',
			'La Chaize-le-Vicomte',
			'Labastidette',
			'Jumilhac-le-Grand',
			'Juigné',
			'Jonzieux',
			'Jonchery-sur-Vesle',
			'Hourtin',
			'Hières-sur-Amby',
			'Hettange-Grande',
			'Hendaye',
			'Yutz',
			'Hauteville-lès-Dijon',
			'Hardinghen',
			'Habsheim',
			'Guémar',
			'Griesheim-sur-Souffel',
			'Gray-la-Ville',
			'Gorbio',
			'Galluis',
			'Gaillac',
			'Fos-sur-Mer',
			'Fort-Mardyck',
			'Forges-les-Bains',
			"Fontevraud-l'Abbaye",
			'Fontainebleau',
			'Fleurines',
			'Faverges-de-la-Tour',
			'Fampoux',
			'Exideuil-sur-Vienne',
			'Esquibien',
			'Ernée',
			'Éperlecques',
			'École-Valentin',
			'Droué',
			'Domancy',
			'Dollon',
			'Docelles',
			'Digoin',
			'Dieulefit',
			'Cruseilles',
			'Crespin',
			'Combronde',
			'Collonges-sous-Salève',
			'Cléry-Saint-André',
			'Clermont-Ferrand',
			'Cinqueux',
			'Chevry-Cossigny',
			'Cherrueix',
			'Châtel-sur-Moselle',
			'Châtel-Guyon',
			'Châtel',
			'Châteauneuf',
			'Chanteloup-en-Brie',
			'Chanac',
			'Champniers',
			'Champdieu',
			'Chagny',
			'Cauffry',
			'Castagniers',
			'Capinghem',
			'Cabrières',
			'Cabanac-et-Villagrains',
			'Bueil',
			'Brunoy',
			'Breuillet',
			'Brax',
			'Bouc-Bel-Air',
			'Blois',
			'Bletterans',
			'Betz',
			'Béthune',
			'Besse-sur-Issole',
			'Besné',
			'Berson',
			'Bernin',
			'Bergheim',
			'Bénéjacq',
			'Belleville',
			'Bélâbre',
			'Beaupuy',
			'Beaumont-la-Ronce',
			'Beaumont-Hague',
			'Beaumont',
			'Beaucé',
			'Bazet',
			'Balagny-sur-Thérain',
			'Baixas',
			'Bailly-Carrois',
			'Baillet-en-France',
			'Bagnols-sur-Cèze',
			'Bagnères-de-Bigorre',
			'Ay-sur-Moselle',
			'Auxon-Dessous',
			'Auberives-sur-Varèze',
			'Aubagne',
			'Arradon',
			'Ambrières-les-Vallées',
			'Ambès',
			'Anglet',
			'Ambérieu-en-Bugey',
			'Altkirch',
			'Ajain',
			'Ailly-sur-Noye',
			'Ableiges',
			'Abilly',
			'Lyon 04',
			'Bourgoin-Jallieu',
			"Le Bois-d'Oingt",
			'Marseille Roucas-Blanc',
			'Opéra',
			'Les Arnavaux',
			'Buzet-sur-Tarn',
			'Wassigny',
			'Volx',
			'Voisins-le-Bretonneux',
			'Vitry-sur-Seine',
			'Viroflay',
			'Vinon-sur-Verdon',
			'Vinça',
			'Villevêque',
			'Villers-sur-Mer',
			'Villeneuve-la-Rivière',
			'Villefranque',
			'Vieux-Condé',
			'Vieille-Toulouse',
			'Vibraye',
			'Vervins',
			'Vernioz',
			'Verdun-sur-Garonne',
			'Vasles',
			'Vanves',
			'Valleroy',
			'Vailhauquès',
			'Ussac',
			'Trieux',
			'Tresses',
			'Trept',
			'Treize-Septiers',
			'Toulaud',
			'Thouars',
			'Thiers-sur-Thève',
			'Thérouanne',
			'Théoule-sur-Mer',
			'Théding',
			'Tavel',
			'Soultz-sous-Forêts',
			'Sormiou',
			'Serpaize',
			'Segonzac',
			'Scey-sur-Saône-et-Saint-Albin',
			'Satolas-et-Bonce',
			'Sanvignes-les-Mines',
			'Santa-Lucia-di-Moriani',
			'Sancoins',
			'Saint-Selve',
			'Saint-Rémy-en-Rollat',
			'Saint-Paul-en-Pareds',
			'Saint-Ouen',
			'Saint-Nicolas-de-Bourgueil',
			'Saint-Maurice-de-Lignon',
			'Saint-Maurice-de-Gourdans',
			'Saint-Martin-de-Crau',
			'Saint-Marcellin-en-Forez',
			'Saint-Lumine-de-Coutais',
			'Saint-Léger-de-Montbrun',
			'Saint-Julien-en-Born',
			'Saint-Julien-Chapteuil',
			'Saint-Jean-de-Monts',
			'Saint-Hilaire-de-Clisson',
			'Saint-Henri',
			'Saint-Germain-Laprade',
			'Saint-Genis-Pouilly',
			'Saint-Genis-de-Saintonge',
			'Saint-Fort',
			'Saint-Florentin',
			'Saintes',
			'Saint-Épain',
			'Sainte-Colombe-en-Bruilhois',
			'Saint-Denis-la-Chevasse',
			'Saint-Crépin-Ibouvillers',
			'Saint-Christophe-sur-le-Nais',
			'Saint-Cannat',
			'Saint-Béron',
			"Saint-Benin-d'Azy",
			'Saint-Beauzire',
			'Saint-Aulaye',
			'Saint-Aubin-des-Châteaux',
			'Saint-Amand',
			'Saffré',
			'Saclay',
			'Royat',
			'Romagnat',
			'Rochefort',
			'Rivesaltes',
			'Rhinau',
			'Ressons-sur-Matz',
			'Reignac',
			'Reichstett',
			'Ramonchamp',
			'Quincié-en-Beaujolais',
			'Quelaines-Saint-Gault',
			'Puichéric',
			'Pouzac',
			'Pouru-Saint-Remy',
			'Pouilly-sur-Loire',
			'Port-Brillet',
			'Pontoise',
			'Plougasnou',
			'Plouédern',
			'Pipriac',
			'Picquigny',
			'Oyonnax',
			'Oye-Plage',
			'Othis',
			'Olonzac',
			'Oeyreluy',
			'Obernai',
			'Oberhoffen-sur-Moder',
			'Noyers-sur-Cher',
			'Noves',
			'Nortkerque',
			'Nomexy',
			'Noiseau',
			'Niort',
			'Niederbronn-les-Bains',
			'Neydens',
			'Nesles-la-Montagne',
			'Nesle',
			'Mouleydier',
			'Mortagne-sur-Sèvre',
			'Mornas',
			'Morainvilliers',
			'Montfaucon',
			'Montebourg',
			'Montbonnot-Saint-Martin',
			'Monein',
			'Mondelange',
			'Monchy-Saint-Éloi',
			'Miribel-les-Échelles',
			'Mirebeau-sur-Bèze',
			'Milizac',
			'Milhaud',
			'Meyzieu',
			'Meslay-du-Maine',
			'Meillonnas',
			'Mazamet',
			'Mathay',
			'Massay',
			'Marzan',
			'Martignas-sur-Jalle',
			'Maroilles',
			'Marigny-le-Châtel',
			'Marguerittes',
			'Maretz',
			'Malaunay',
			'Maillezais',
			'Magny-Vernois',
			'Lusigny-sur-Barse',
			'Lurcy-Lévis',
			'Luray',
			'Lorgies',
			'Livry-sur-Seine',
			'Limay',
			"L'Île-Rousse",
			'Lieu-Saint-Amand',
			'Lewarde',
			'Viviers-du-Lac',
			'Lévis-Saint-Nom',
			'Leudeville',
			'Le Trait',
			'Le Teich',
			'Lestrem',
			'Les Herbiers',
			'Le Russey',
			'Le Pré-Saint-Gervais',
			'Le Plessis-Pâté',
			'Le Plessis-Bouchard',
			'Le Plessis-Belleville',
			'Le Pin',
			'Le Monêtier-les-Bains',
			'Le Mesnil-Saint-Denis',
			'Le Gâvre',
			'Le Bourgneuf-la-Forêt',
			'Le Boupère',
			'Le Beausset',
			'La Ville-aux-Dames',
			'Laventie',
			'Lavelanet',
			'Lassigny',
			'La Salvetat-Saint-Gilles',
			'Laroque',
			'La Roche-de-Glun',
			'Laneuveville-devant-Nancy',
			'Landéda',
			'La Mailleraye-sur-Seine',
			'Laiz',
			'Lagord',
			'La Gaubretière',
			'La Fresnais',
			'Lacroix-Saint-Ouen',
			'La Chartre-sur-le-Loir',
			'La Chapelle-Heulin',
			'La Boissière-de-Montaigu',
			'Killem',
			'Juvigné',
			'Jurançon',
			'Jouy-en-Josas',
			'Joué-lès-Tours',
			'Jonage',
			'Javené',
			'Jaujac',
			'Jarnac',
			'Isbergues',
			'Hochstatt',
			'Hem',
			'Hégenheim',
			'Hauville',
			'Hauterives',
			'Haubourdin',
			'Guégon',
			'Gournay-en-Bray',
			'Gémenos',
			'Gasville-Oisème',
			'Garlin',
			'Garat',
			'Frontonas',
			'Fronsac',
			'Frelinghien',
			'Fourques',
			'Fouras',
			'Fouillard',
			'Fontoy',
			'Fontenay-le-Fleury',
			'Florac',
			'Flaviac',
			'Figanières',
			'Fégréac',
			'Fayl-Billot',
			'Euville',
			'Eulmont',
			'Esnandes',
			'Esbly',
			'Ercé-en-Lamée',
			'Épieds-en-Beauce',
			'Ennezat',
			'Écouen',
			'Échillais',
			'Duras',
			'Doullens',
			'Dinéault',
			'Déville-lès-Rouen',
			'Cuzieu',
			'Cublize',
			'Crossac',
			'Creuzier-le-Vieux',
			'Cranves-Sales',
			'Cousance',
			'Corcieux',
			'Contrexéville',
			'Congrier',
			'Condé-sur-Vire',
			'Comps',
			'Compiègne',
			'Colomiers',
			'Cléguer',
			'Chocques',
			'Chèvremont',
			'Cheval-Blanc',
			'Chenôve',
			'Chavagnes-en-Paillers',
			'Chaunay',
			'Châtillon',
			'Châtelaillon-Plage',
			'Château-Thierry',
			'Châteaurenard',
			'Châteauneuf-en-Thymerais',
			'Charny',
			'Changé',
			'Champlan',
			'Champeix',
			'Champagnole',
			'Chabeuil',
			'Cepoy',
			'Cavan',
			'Castres-Gironde',
			'Cast',
			'Cambronne-lès-Ribécourt',
			'Cadaujac',
			'Breuil-Magné',
			'Bretteville-du-Grand-Caux',
			'Brécé',
			'Brasparts',
			'Branges',
			'Bourg-Saint-Maurice',
			'Bouloc',
			'Bouafle',
			'Bohain-en-Vermandois',
			'Blénod-lès-Toul',
			'Blaringhem',
			'Blainville-sur-Mer',
			'Beuzec-Cap-Sizun',
			'Beuvry',
			'Besse-et-Saint-Anastaise',
			'Bellegarde-en-Forez',
			'Bégard',
			'Bégaar',
			'Beauvoir-de-Marc',
			'Beaurainville',
			'Beaulon',
			'Bart',
			'Avoine',
			'Avize',
			'Authon-du-Perche',
			'Audierne',
			'Atur',
			'Assérac',
			'Aspet',
			'Asnières-sur-Nouère',
			'Arnage',
			'Argeliers',
			'Arès',
			'Annay',
			'Angoulême',
			"els Banys d'Arles",
			'Aillant-sur-Tholon',
			'Les Deux Alpes',
			'Castelmaurou',
			'Marseille Bompard',
			'Les Accates',
			'Borel',
			'Wittelsheim',
			'Willerwald',
			'Yvré-le-Pôlin',
			'Wimille',
			'Wignehies',
			'Westhoffen',
			'Vouneuil-sous-Biard',
			'Voujeaucourt',
			'Vire',
			'Villenoy',
			'Villeneuve-le-Comte',
			'Villeneuve-de-Berg',
			'Villejuif',
			'Villard-Bonnot',
			'Vic-Fezensac',
			'Vétraz-Monthoux',
			'Verneuil-en-Halatte',
			'Vasselay',
			'Vacon',
			'Uzès',
			'Us',
			'Troarn',
			'Trélivan',
			'Trèbes',
			'Toulouse',
			'Tosse',
			'Tinqueux',
			'Thury-Harcourt',
			'Thorigné-sur-Dué',
			'Thonon-les-Bains',
			'Thaon-les-Vosges',
			'Taillecourt',
			'Steinbach',
			'Sours',
			'Souraïde',
			'Soullans',
			'Souligné-sous-Ballon',
			'Sizun',
			'Seyssinet-Pariset',
			'Serrières-de-Briord',
			'Serres-Castet',
			'Segré',
			'Seclin',
			'Scy-Chazelles',
			'Saze',
			'Sauzet',
			'Sassenage',
			'Saran',
			'Santa-Maria-di-Lota',
			'Sangatte',
			'Salins-les-Thermes',
			'Saint-Yvi',
			'Saint-Vincent-sur-Oust',
			'Saint-Vincent-de-Paul',
			'Saint-Victoret',
			'Saint-Sernin-du-Bois',
			'Saint-Riquier',
			'Saint-Paulien',
			'Saint-Parres-aux-Tertres',
			'Saint-Michel-Chef-Chef',
			'Saint-Martin-la-Plaine',
			'Saint-Martin-Bellevue',
			'Saint-Mariens',
			'Saint-Mandrier-sur-Mer',
			'Saint-Loup',
			'Saint-Léonard',
			'Saint-Jean-de-Fos',
			'Saint-James',
			'Saint-Ismier',
			'Saint-Hilaire-du-Harcouët',
			'Saint-Germain-en-Coglès',
			'Saint-Germain-du-Puy',
			'Saint-Germain',
			'Saint-Flour',
			'Sainte-Menehould',
			'Sainte-Féréole',
			'Saint-Didier-sous-Riverie',
			"Saint-Denis-d'Oléron",
			'Saint-Denis',
			'Saint-Clément-de-Rivière',
			'Saint-Brisson-sur-Loire',
			"Saint-Antoine-l'Abbaye",
			'Saint-Alexandre',
			'Sabres',
			'Rungis',
			'Ruffiac',
			'Roulans',
			'Rospez',
			'Rogerville',
			'Roche',
			'Richwiller',
			'Réquista',
			'Réguisheim',
			'Quincy-Voisins',
			'Questembert',
			'Quend',
			'Quarante',
			'Prémery',
			'Préfailles',
			'Poussan',
			'Port-en-Bessin-Huppain',
			'Port-de-Bouc',
			'Ponteilla',
			'Poix-du-Nord',
			'Pocé-les-Bois',
			'Pluduno',
			'Plouzévédé',
			'Plounéour-Trez',
			'Plessé',
			'Pierrevillers',
			'Pierrefeu-du-Var',
			'Pfetterhouse',
			'Pélissanne',
			'Pacy-sur-Eure',
			'Oisemont',
			'Ognes',
			'Offenheim',
			'Noyelles-sous-Lens',
			'Notre-Dame-de-Sanilhac',
			'Notre-Dame-de-Gravenchon',
			'Norroy-lès-Pont-à-Mousson',
			"Nogent-l'Artaud",
			'Neaufles-Saint-Martin',
			'Mussy-sur-Seine',
			'Muret',
			'Mougon',
			'Morre',
			'Morbier',
			'Montreuil-sous-Pérouse',
			'Montreuil-le-Gast',
			'Montredon',
			'Montpon-Ménestérol',
			'Montlouis-sur-Loire',
			'Montgiscard',
			'Montferrat',
			'Montélimar',
			'Montberon',
			'Montanay',
			'Mont',
			'Miramas',
			'Mézin',
			'Mézeray',
			'Mesnières-en-Bray',
			'Mériel',
			'Méribel',
			'Menucourt',
			'Ménilles',
			'Ménéac',
			'Médan',
			'Mazières-de-Touraine',
			'Mazan',
			'Maxent',
			'Maurepas',
			'Maureilhan',
			'Marssac-sur-Tarn',
			'Marsac-sur-Don',
			'Marnes-la-Coquette',
			'Marmoutier',
			'Margencel',
			'Mardeuil',
			'Maizières-lès-Metz',
			'Maisons-Alfort',
			'Maincy',
			'Mailly-le-Camp',
			'Mâcon',
			'Macau',
			'Lyaud',
			'Lusignan',
			'Lunay',
			'Luisant',
			'Lodève',
			'Lisieux',
			'Lézignan-Corbières',
			'Levroux',
			'Le Vésinet',
			'Les Vans',
			'Lespignan',
			'Lesneven',
			'Les Mages',
			'Les Allues',
			'Le Pouget',
			'Lembras',
			'Le Lavandou',
			'Le Lardin-Saint-Lazare',
			'Le Landreau',
			'Le Grand-Lemps',
			'La Vespière',
			'Lavalette',
			"La Roque-d'Anthéron",
			'La Riche',
			'Lanta',
			'Lans-en-Vercors',
			'Landres',
			'La Méaugon',
			'Lamballe',
			'Lallaing',
			'Lagorce',
			'La Ferté-Imbault',
			'La Bruffière',
			'Labarthe-Rivière',
			'La Balme-de-Sillingy',
			'Kersaint-Plabennec',
			'Hermies',
			'Herlies',
			'Haguenau',
			'Hagetmau',
			'Guise',
			'Guichen',
			'Guengat',
			'Guémené-Penfao',
			'Grane',
			'Grandvillars',
			'Grand-Fort-Philippe',
			'Gouzon',
			'Gerde',
			'Gassin',
			'Fourmies',
			'Follainville-Dennemont',
			'Ferrières-en-Brie',
			'Etzling',
			'Étreux',
			'Étreillers',
			'Éterville',
			'Essigny-le-Grand',
			'Erquinghem-Lys',
			'Entre-Deux-Guiers',
			'Émerchicourt',
			'Edern',
			'Écully',
			'Écueillé',
			'Dry',
			'Droue-sur-Drouette',
			'Drocourt',
			'Doyet',
			'Dol-de-Bretagne',
			'Dieppe',
			'Dasle',
			'Danjoutin',
			'Cusy',
			'Cuiseaux',
			'Cruet',
			'Croix',
			'Croissy-Beaubourg',
			'Croisilles',
			'Crevin',
			'Creully',
			'Creney-près-Troyes',
			'Craponne-sur-Arzon',
			'Courcouronnes',
			'Coudoux',
			'Cosne-Cours-sur-Loire',
			'Cornier',
			'Corneville-sur-Risle',
			'Cornebarrieu',
			'Contamine-sur-Arve',
			'Commer',
			'Combrée',
			'Colombier-Fontaine',
			'Colombe',
			'Collioure',
			'Cintegabelle',
			'Chissay-en-Touraine',
			'Chevillon-sur-Huillard',
			'Chéroy',
			"Châteauneuf-d'Ille-et-Vilaine",
			'Châteaugiron',
			'Chasseneuil-sur-Bonnieure',
			'Charolles',
			'Charny',
			'Charnay',
			'Champigny-sur-Marne',
			'Champagney',
			"Champagne-au-Mont-d'Or",
			'Chameyrat',
			'Chaligny',
			'Cessieu',
			'Castets-en-Dorthe',
			'Casseneuil',
			'Carcassonne',
			'Captieux',
			'Capdenac-Gare',
			'Candé-sur-Beuvron',
			'Calonne-Ricouart',
			'Calmont',
			'Callian',
			'Cadenet',
			'Cachan',
			'Briennon',
			'Brie',
			'Briatexte',
			'Brézins',
			'Bretteville-sur-Laize',
			'Brêmes',
			'La Crèche',
			'Boves',
			'Bourgtheroulde-Infreville',
			'Bourbourg',
			'Boulogne-sur-Mer',
			'Bonnières-sur-Seine',
			'Bonneval',
			'Bois-le-Roi',
			'Boeschepe',
			'Bœrsch',
			'Blaesheim',
			'Billère',
			'Bignan',
			'Bennecourt',
			'Belin-Béliet',
			'Beaufort',
			'Barberaz',
			'Ballancourt-sur-Essonne',
			'Balbigny',
			'Balan',
			'Bacqueville-en-Caux',
			'Avranches',
			'Auray',
			'Aubie',
			'Asson',
			'Armissan',
			'Armentières-en-Brie',
			'Armenonville-les-Gâtineaux',
			'Arinthod',
			'Ardin',
			'Anstaing',
			'Annezin',
			'Annesse-et-Beaulieu',
			'Angresse',
			'Ambon',
			'Ambillou',
			'Allassac',
			'Aix-les-Bains',
			'Aiserey',
			'Aime',
			'Saint-Manvieu-Norrey',
			'Lyon 01',
			'Cergy-Pontoise',
			'Cinq Avenues',
			'Les Caillols',
			'Bon-Secours',
			'Ychoux',
			'Wissant',
			'Wingen-sur-Moder',
			'Zutkerque',
			'Pompignac',
			'Plogastel-Saint-Germain',
			'Pionsat',
			'Peyrestortes',
			'Perrusson',
			'Percy',
			'Penta-di-Casinca',
			'Peillac',
			'Parcé-sur-Sarthe',
			'Pancé',
			'Palinges',
			'Orgon',
			'Orgelet',
			'Orchies',
			'Nommay',
			'Nomain',
			'Nieppe',
			'Neuville-en-Ferrain',
			'Neufchâteau',
			'Nesmy',
			'Nersac',
			'Muttersholtz',
			'Mundolsheim',
			'Mouchin',
			'Mouchamps',
			'Morigny-Champigny',
			'Montricoux',
			'Montjean-sur-Loire',
			'Montier-en-Der',
			'Monteils',
			'Montdidier',
			'Montbron',
			'Montbazens',
			'Montauban-de-Bretagne',
			'Montastruc-la-Conseillère',
			'Montaigut',
			'Miélan',
			'Meylan',
			'Mennecy',
			'Ménestreau-en-Villette',
			'Mauléon-Licharre',
			'Maubec',
			'Mandeure',
			'Malville',
			'Maisnil-lès-Ruitz',
			'Magny-le-Désert',
			'Machecoul',
			'Luz-Saint-Sauveur',
			'Luxeuil-les-Bains',
			'Lucenay-lès-Aix',
			'Louverné',
			'Loubert',
			'Lion-sur-Mer',
			'Limetz-Villez',
			'Lezoux',
			'Le Vigan',
			'Le Vaudreuil',
			'Leuville-sur-Orge',
			"Les Sables-d'Olonne",
			'Les Grandes-Ventes',
			'Les Angles',
			'Le Redon',
			'Le Poinçonnet',
			'Le Luart',
			'Le Fœil',
			'Launaguet',
			'La Saussaye',
			'La Roche-sur-Foron',
			'Laroche-Saint-Cydroine',
			'La Rochelle',
			'La Roche-des-Arnauds',
			'Lanvallay',
			'Lanrodec',
			'Landéan',
			'Lancrans',
			'La Mothe-Achard',
			'Lambesc',
			"Lalevade-d'Ardèche",
			'Lagor',
			'La Croix-Rouge',
			'La Chapelle-Thouarault',
			'La Chapelle-sur-Crécy',
			'La Chapelle-en-Serval',
			'La Chapelle-de-la-Tour',
			'La Buisse',
			'Laboissière-en-Thelle',
			'Labeuvrière',
			'Juvigny-sous-Andaine',
			'Jarville-la-Malgrange',
			'Jacou',
			'Irmstett',
			'Illzach',
			'Igoville',
			'Huelgoat',
			'Hochfelden',
			'Herserange',
			'Hénin-Beaumont',
			'Haute-Rivoire',
			'Guerlesquin',
			'Guebwiller',
			'Gresswiller',
			'Gray',
			'Grand-Couronne',
			'Grâces',
			'Graçay',
			'Gournay-sur-Marne',
			'Gorze',
			'Goderville',
			'Ginestas',
			'Gentilly',
			'Fronton',
			'Froges',
			'Floing',
			'Flize',
			'Fleurie',
			'Flers',
			'Fitz-James',
			'Ferrière-la-Grande',
			'Ferney-Voltaire',
			'Étupes',
			'Ermont',
			'Entraygues-sur-Truyère',
			'Durtol',
			'Dreux',
			'Dourdan',
			'Doué-la-Fontaine',
			'Doue',
			'Donville-les-Bains',
			'Donnemarie-Dontilly',
			'Dole',
			'Dives-sur-Mer',
			'Décines-Charpieu',
			'Damazan',
			'Cuisery',
			'Cuges-les-Pins',
			'Croth',
			'Crécy-sur-Serre',
			'Coutras',
			'Couhé',
			'Cosnes',
			'Clinchamps-sur-Orne',
			'Cléguérec',
			'Clamecy',
			'Ciry-le-Noble',
			'Cirey-sur-Vezouze',
			'Chirens',
			'Chevilly-Larue',
			'Chauriat',
			'Chatou',
			'Châteauvillain',
			'Chantepie',
			'Chandon',
			'Chanceaux-sur-Choisille',
			'Champagnier',
			'Cendras',
			'Cébazat',
			'Castelmoron-sur-Lot',
			'Cadolive',
			'Busnes',
			'Bully',
			'Brison-Saint-Innocent',
			'Brioux-sur-Boutonne',
			'Brézé',
			'Brette-les-Pins',
			'Bouxières-aux-Chênes',
			'Bourg-de-Péage',
			'Bormes-les-Mimosas',
			'Boran-sur-Oise',
			'Bollwiller',
			'Bolbec',
			"Bois-d'Arcy",
			'Bize-Minervois',
			'Biot',
			'Bessé-sur-Braye',
			'Besançon',
			'Béruges',
			'Benfeld',
			'Beaupuy',
			'Beaumont-Monteux',
			'Beaumont-lès-Valence',
			'Baule',
			'Bar-sur-Aube',
			'Barbechat',
			'Baldersheim',
			"Bagnoles-de-l'Orne",
			'Bachy',
			'Avignon',
			'Autry-le-Châtel',
			'Athis-Mons',
			"Athis-de-l'Orne",
			'Aspiran',
			'Arveyres',
			'Art-sur-Meurthe',
			'Arthez-de-Béarn',
			'Artas',
			'Argenteuil',
			'Appietto',
			'Annet-sur-Marne',
			'Annemasse',
			'Amplepuis',
			'Albertville',
			'Ahun',
			'Agonac',
			'Abzac',
			'La Blancarde',
			'La Capelette',
			'Vouillé',
			'Voisenon',
			'Vivier-au-Court',
			'Villiers-le-Morhier',
			'Villevaudé',
			'Villepinte',
			'Villeneuve-la-Guyard',
			'Vieillevigne',
			'Vic-le-Comte',
			'Verrières-le-Buisson',
			'Vernoux-en-Vivarais',
			'Vendin-le-Vieil',
			'Vauréal',
			'Vaudelnay',
			'Varengeville-sur-Mer',
			'Vallauris',
			'Urcuit',
			'Tourbes',
			'Toulenne',
			'Torcy',
			'Torcé',
			'Tonquédec',
			'Tinténiac',
			'Thorigny-sur-Oreuse',
			'Thilouze',
			'Thairé',
			'Tanneron',
			'Taninges',
			'Soisy-sur-École',
			'Soings-en-Sologne',
			'Sisteron',
			'Schœneck',
			'Sancé',
			'Saint-Valérien',
			'Saint-Trivier-de-Courtes',
			'Saint-Sylvestre-Cappel',
			'Saint-Savournin',
			'Saint-Savin',
			'Saint-Restitut',
			'Saint-Quentin-de-Baron',
			'Saint-Privat',
			'Saint-Priest',
			'Saint-Pierre-Quiberon',
			'Saint-Pierre-le-Moûtier',
			'Saint-Pierre-Église',
			'Saint-Pierre-du-Regard',
			'Saint-Philbert-du-Peuple',
			'Saint-Paul-en-Jarez',
			'Saint-Paul-en-Chablais',
			'Saint-Nicolas-de-la-Taille',
			'Saint-Nicolas',
			'Saint-Mars-la-Brière',
			'Saint-Maixant',
			'Saint-Laurent-du-Pont',
			'Saint-Jouan-des-Guérets',
			'Saint-Jean-le-Vieux',
			'Saint-Hilaire-lez-Cambrai',
			'Saint-Georges-les-Bains',
			'Saint-Etienne-de-Tulmont',
			'Saint-Égrève',
			'Sainte-Colombe',
			'Sainte-Bazeille',
			'Saint-Dizier',
			'Saint-Cyr-sur-Menthon',
			'Saint-Céré',
			'Saint-Cassien',
			'Saint-Brevin-les-Pins',
			'Saint-Bonnet-de-Mure',
			'Saint-Aubin-le-Cloud',
			'Saint-Aubin-des-Bois',
			'Saint-Amé',
			'Saint-Amand-Montrond',
			'Saignon',
			'Sablé-sur-Sarthe',
			'Rouziers-de-Touraine',
			'Rouillon',
			'Rouffignac-Saint-Cernin-de-Reilhac',
			'Roscanvel',
			'Rimogne',
			'Razès',
			'Quévert',
			'Putanges-Pont-Écrepin',
			'Pujaut',
			'Puiseux-en-France',
			'Prahecq',
			'Portets',
			"Pont-l'Évêque",
			'Pont-Évêque',
			'Pont-à-Vendin',
			'Poisat',
			'Plonévez-Porzay',
			'Ploeren',
			'Pleumeur-Bodou',
			'Plaisance-du-Touch',
			'Wassy',
			'Warluis',
			'Vix',
			'Viviez',
			'Vimines',
			'Villey-Saint-Étienne',
			'Villeveyrac',
			'Villeneuve-lès-Béziers',
			'Villenauxe-la-Grande',
			'Villefagnan',
			'Vidauban',
			'Vianne',
			'Vert-le-Grand',
			'Ver-sur-Launette',
			'Vermand',
			'Vaux-le-Pénil',
			'Vars',
			'Varilhes',
			'Varangéville',
			'Vallet',
			'Valentigney',
			'Valence',
			'Urmatt',
			'Uffholtz',
			'Trélazé',
			'Treffiagat',
			'Traînou',
			'Tourville-sur-Odon',
			'Tourville-sur-Arques',
			'Tourves',
			'Tournes',
			'Tournefeuille',
			'Tilloy-lès-Mofflaines',
			'Thorigny-sur-Marne',
			'Theys',
			'Targon',
			'Tarascon',
			'Tallard',
			'Taden',
			'Soultzeren',
			'Souillac',
			'Sézanne',
			'Sèvres-Anxaumont',
			'Servian',
			'Sennecey-le-Grand',
			'Scionzier',
			'Samois-sur-Seine',
			'Sammeron',
			'Samadet',
			'Salagnon',
			'Saint-Sulpice',
			"Saint-Seurin-sur-l'Isle",
			'Saint-Sauveur-le-Vicomte',
			'Saint-Sauveur-en-Rue',
			'Saint-Sauveur-de-Montagut',
			'Saint-Sauvant',
			'Saint-Rémy',
			'Saint-Rémy-de-Maurienne',
			'Saint-Pouange',
			'Saint-Pierre-lès-Nemours',
			'Saint-Pierre-du-Mont',
			'Saint-Paul',
			'Saint-Pardoux',
			'Saint-Palais-sur-Mer',
			'Saint-Orens-de-Gameville',
			'Saint-Maximin',
			'Saint-Martin sous Mouzeuil',
			'Saint-Martin-des-Besaces',
			'Saint-Martin-au-Laërt',
			'Saint-Marcellin',
			'Saint-Malô-du-Bois',
			'Saint-Léger-des-Bois',
			'Saint-Julien-en-Genevois',
			'Saint-Julien-des-Landes',
			'Saint-Jouin-Bruneval',
			'Saint-Jean-de-Losne',
			'Saint-Gobain',
			'Saint-Germain-lès-Corbeil',
			'Saint-Étienne-des-Oullières',
			'Saint-Étienne-de-Baïgorry',
			'Saint-Émilion',
			'Sainte-Luce-sur-Loire',
			'Saint-Éloi',
			'Sainte-Flaive-des-Loups',
			'Sainte-Cécile',
			'Saint-Didier',
			'Saint-Chef',
			'Saint-Aubin-en-Bray',
			'Saint-André-lez-Lille',
			'Saint-Agrève',
			'Rugles',
			'Roussay',
			'Rosporden',
			'Rosny-sur-Seine',
			'Roquefort-la-Bédoule',
			'Rœulx',
			'Remouillé',
			'Redon',
			'Rantigny',
			"Puy-l'Évêque",
			'Pujaudran',
			'Pradines',
			'Port-Sainte-Marie',
			'Pommeuse',
			'Poey-de-Lescar',
			'Plounéour-Ménez',
			'Pleubian',
			'Plestan',
			'Plédran',
			'Plan-de-Cuques',
			'Peynier',
			'Périgny',
			'Pellegrue',
			'Payzac',
			'Parigné',
			'Pargny-sur-Saulx',
			'Paray-le-Monial',
			'Palau-del-Vidre',
			'Ourville-en-Caux',
			'Origny-en-Thiérache',
			'Noyant-la-Gravoyère',
			'Notre-Dame-de-Riez',
			'Nointot',
			'Nivolas-Vermelle',
			'Neuville-sur-Sarthe',
			'Neufmoutiers-en-Brie',
			'Nérac',
			'Mouzillon',
			'Mouvaux',
			'Moulis-en-Médoc',
			'Mouguerre',
			'Montrond-les-Bains',
			'Montigny',
			'Montceau-les-Mines',
			'Monnetier-Mornex',
			'Monéteau',
			'Molsheim',
			'Miremont',
			'Millau',
			'Meythet',
			'Metzeral',
			'Méry-sur-Seine',
			'Mayenne',
			'Marpent',
			'Marolles-sur-Seine',
			'Maringues',
			'Malakoff',
			'Maisdon-sur-Sèvre',
			'Maignelay-Montigny',
			'Maîche',
			'Magnac-sur-Touvre',
			'Lucciana',
			'Lozinghem',
			'Loupian',
			'Longueil-Sainte-Marie',
			'Longueil-Annel',
			'Longjumeau',
			'Logonna-Daoulas',
			'Locquirec',
			'Loches',
			'Limonest',
			'Levens',
			'Le Thoronet',
			'Les Molières',
			'Les Camoins',
			'Les Andelys',
			'Le Revest-les-Eaux',
			'Le Muy',
			'Le Molay-Littry',
			'Le Louroux Béconnais',
			'Le Kremlin-Bicêtre',
			'Le Bouscat',
			'Le Bar-sur-Loup',
			'Lavérune',
			'La Tessoualle',
			'La Rochefoucauld',
			'La Rivière-de-Corps',
			'Lapugnoy',
			'Lanvollon',
			'Languidic',
			'Landrecies',
			'Landévant',
			'La Membrolle-sur-Longuenée',
			'La Madeleine',
			'La Loubière',
			'La Daguenière',
			'La Couture-Boussey',
			'La Châtre',
			'La Chapelle-Réanville',
			'La Chapelle-des-Marais',
			'La Bridoire',
			'La Bouilladisse',
			'Lablachère',
			'Jussy',
			'Jussey',
			'Janville-sur-Juine',
			'Jans',
			'Ittenheim',
			'Ingersheim',
			'Illkirch-Graffenstaden',
			'Illats',
			'Hermanville-sur-Mer',
			'Henrichemont',
			'Hélesmes',
			'Guilliers',
			'Guéret',
			'Grainville-la-Teinturière',
			'Gonneville-la-Mallet',
			'Gigean',
			'Gazeran',
			'Gambais',
			'Friville-Escarbotin',
			'Fréjus',
			'Francheville',
			'Fraize',
			'Fraisans',
			'Frahier-et-Chatebier',
			'Florensac',
			'Flins-sur-Seine',
			'Férel',
			'Évaux-les-Bains',
			'Esquerdes',
			'Eschau',
			'Épagny',
			'Entrammes',
			'Ebersheim',
			'Drusenheim',
			'Dorlisheim',
			'Donges',
			'Dolomieu',
			'Distroff',
			'Dirinon',
			'Diou',
			'Dettwiller',
			'Damigny',
			'Dadonville',
			'Cuisiat',
			'Crusnes',
			'Créteil',
			'Crespières',
			'Craponne',
			'Cours-les-Barres',
			'Courpière',
			'Courcité',
			'Courcelles-lès-Lens',
			'Corny-sur-Moselle',
			'Cormelles-le-Royal',
			"Condé-sur-l'Escaut",
			'Combs-la-Ville',
			'Chiché',
			'Chens-sur-Léman',
			'Châtillon-sur-Seiche',
			'Châtillon-le-Duc',
			'Châtillon-Coligny',
			'Châtenois',
			'Châteauneuf-Grasse',
			'Charmes',
			'Charly',
			'Charenton-du-Cher',
			'Chambourg-sur-Indre',
			'Chamarande',
			'Cerny',
			'Ceret',
			'Cenon-sur-Vienne',
			'Caveirac',
			'Caunes-Minervois',
			'Castelnaudary',
			'Cassagnes-Bégonhès',
			"Cap-d'Ail",
			'Camaret-sur-Mer',
			'Cabriès',
			'Bussière-Galant',
			'Burbure',
			'Brives-Charensac',
			'Brienne-le-Château',
			'Brié-et-Angonnes',
			'Bressuire',
			'Bray-Dunes',
			'Boussac',
			'Boissy-sous-Saint-Yon',
			'Bois-Grenier',
			"Bois-d'Amont",
			'Bitschwiller-lès-Thann',
			'Bischoffsheim',
			'Billy-Berclau',
			'Beuvrages',
			'Berric',
			'Bergues',
			'Bénodet',
			'Bellaing',
			'Bédarrides',
			'Beaurepaire',
			'Beauquesne',
			'Beaumont-de-Pertuis',
			'Bayon',
			'Barjouville',
			'Balaruc-le-Vieux',
			'Bais',
			'Bailleul',
			'Bagnols-en-Forêt',
			'Azé',
			'Ayguesvives',
			'Avrechy',
			'Aubigny-en-Artois',
			'Aoste',
			'Ancenis',
			'Alizay',
			'Alignan-du-Vent',
			'Agnetz',
			'Abbaretz',
			'Lyon 03',
			'Marseille 08',
			'Marseille 11',
			'Marseille Saint-Victor',
			'Thiers',
			'La Timone',
			'Woustviller',
			'Wittenheim',
			'Cargèse',
			'Camblanes',
			'Vougy',
			'Voglans',
			'Villeneuve-les-Sablons',
			'Villé',
			'Vigny',
			'Vieux-Boucau-les-Bains',
			'Vesseaux',
			'Vermelles',
			'Vergt',
			'Vercel-Villedieu-le-Camp',
			'Vendôme',
			'Vaulnaveys-le-Bas',
			'Varois-et-Chaignot',
			'Vandœuvre-lès-Nancy',
			"Vallon-Pont-d'Arc",
			'Vallon-en-Sully',
			'Valenton',
			'Vaivre-et-Montoille',
			'Vaas',
			'Uxem',
			'Unverre',
			'Trévou-Tréguignec',
			'Trévé',
			'Toutainville',
			'Tourville-la-Rivière',
			'Tourrettes-sur-Loup',
			'Torigni-sur-Vire',
			'Tonnerre',
			'Tinchebray',
			'Thyez',
			'Thuir',
			'Thiers',
			'Tercé',
			'Tancarville',
			'Taintrux',
			"Tain-l'Hermitage",
			'Spéracèdes',
			'Sourzac',
			'Sourdeval',
			'Solaize',
			'Sixt-sur-Aff',
			'Sireuil',
			'Sévérac',
			'Sérignan-du-Comtat',
			'Sergines',
			'Seiches-sur-le-Loir',
			'Saulieu',
			'Saugues',
			'Sarreinsming',
			'Saint-Thurial',
			'Saint-Thégonnec',
			'Saint-Sylvestre-sur-Lot',
			'Saint-Saëns',
			'Saint-Romain-de-Colbosc',
			'Saint-Rémy',
			'Saint-Privat-la-Montagne',
			'Saint-Pierre-des-Nids',
			'Saint-Paulet-de-Caisson',
			'Saint-Paul-des-Landes',
			'Saint-Paul-de-Fenouillet',
			"Saint-Ouen-l'Aumône",
			'Saint-Nicolas-du-Pélem',
			'Saint-Nauphary',
			'Saint-Médard-en-Jalles',
			'Saint-Martin-en-Campagne',
			'Saint-Martin-du-Fouilloux',
			'Saint-Léger-sur-Roanne',
			'Saint-Léger-les-Vignes',
			'Saint-Just-de-Claix',
			'Saint-Just-Chaleyssin',
			'Saint-Jory',
			'Saint-Héand',
			'Saint-Gervais-les-Bains',
			'Saint-Germain-Village',
			'Saint-Geours-de-Maremne',
			'Saint-Georges-de-Didonne',
			'Saint-Forgeux',
			'Saint-Florent',
			'Saint-Estève',
			'Sainte-Maure',
			'Sainte-Marie',
			'Sainte-Hélène-sur-Isère',
			'Sainte-Geneviève',
			'Sainte-Foy-de-Peyrolières',
			'Sainte-Anne',
			'Saint-Denis-de-Pile',
			'Saint-Cyr-sur-Morin',
			'Saint-Clément',
			'Saint-Christol',
			'Saint-Chamond',
			'Saint-Cernin',
			'Saint-Arnoult-en-Yvelines',
			'Saint-André-des-Eaux',
			'Sains-en-Gohelle',
			'Roussillon',
			'Roumazières-Loubert',
			'Rosheim',
			'Roquetoire',
			'Roquefort',
			'Roquefort',
			'Romagny',
			'Rocheservière',
			'Rivières',
			'Riaillé',
			'Ravières',
			'Quillan',
			'Pulligny',
			'Puget-Théniers',
			'Pringy',
			'Précigné',
			'Pouldergat',
			'Pougues-les-Eaux',
			'Porcelette',
			'Pont-Saint-Pierre',
			'Pontrieux',
			'Pont de Vivaux',
			'Pollionnay',
			'Polliat',
			'Pocé-sur-Cisse',
			'Plounévez-Quintin',
			'Fréhel',
			'Plailly',
			'Pierrevert',
			'Perrignier',
			'Parigny',
			'Pantin',
			'Palaiseau',
			'Ouzouer-le-Marché',
			'Ouches',
			'Orchamps-Vennes',
			'Onzain',
			'Ogeu-les-Bains',
			'Noyelles-lès-Vermelles',
			'Noyarey',
			'Noidans-lès-Vesoul',
			'Noailles',
			'Neuvy-le-Roi',
			'Neuville-sur-Ain',
			'Neuville-Saint-Vaast',
			'Naves',
			'Nanteuil-lès-Meaux',
			'Munster',
			'Mudaison',
			'Mouzeil',
			'Morbecque',
			'Montoison',
			'Montluel',
			'Montlhéry',
			'Montlebon',
			'Montigné-le-Brillant',
			'Montgivray',
			'Montcornet',
			'Montaigut',
			'Mondonville',
			'Mezel',
			'Meynes',
			'Meschers-sur-Gironde',
			'Merlevenez',
			'Marquillies',
			'Marmande',
			'Mareil-sur-Mauldre',
			'Malzéville',
			'Maizières-la-Grande-Paroisse',
			'Lozanne',
			'Lourches',
			'Longueau',
			'Longfossé',
			'Longecourt-en-Plaine',
			'Lissieu',
			'Le Teilleul',
			'Les Mureaux',
			'Les Gonds',
			'Les Ageux',
			'Mont-Dore',
			'Le Meux',
			'Lembach',
			'Le Lude',
			'Le Barp',
			'Lathus-Saint-Rémy',
			'Laroquebrou',
			'La Réole',
			'La Ravoire',
			'La Peyratte',
			'Lapalud',
			'Langon',
			'Langeac',
			'Landser',
			'La Motte',
			'La Mézière',
			'La Limouzinière',
			'Laguenne',
			'Lagardelle-sur-Lèze',
			'La Flotte',
			'La Chaussée-Saint-Victor',
			'La Chapelle-Saint-Mesmin',
			'La Chambre',
			'La Bouëxière',
			'La Bohalle',
			'La Bernerie-en-Retz',
			'Labenne',
			'Joyeuse',
			'Josselin',
			'Jonquières-Saint-Vincent',
			'Jonquières',
			'Illies',
			'Houssen',
			'Houppeville',
			'Houplin-Ancoisne',
			'Harnes',
			'Grièges',
			'Gretz-Armainvilliers',
			'Grenade',
			'Gorron',
			'Golbey',
			'Ghisonaccia',
			'Gagny',
			'Gacé',
			'Fruges',
			'Francheville',
			'Fontaine-lès-Luxeuil',
			'Folembray',
			'Fleury-sur-Orne',
			'Fleurbaix',
			'Feuquières',
			'Ferrals-les-Corbières',
			'Feignies',
			'Famars',
			'Fagnières',
			'Ézy-sur-Eure',
			'Eyragues',
			'Étretat',
			'Estivareilles',
			'Espéraza',
			'Escautpont',
			'Erre',
			'Épervans',
			'Égriselles-le-Bocage',
			'Écommoy',
			'Eauze',
			'Dun-le-Palestel',
			'Drefféac',
			'Domessin',
			'Domérat',
			'Diges',
			'Diémoz',
			'Dannes',
			'Cucuron',
			'Créances',
			'Cozes',
			'Courseulles-sur-Mer',
			'Coulounieix',
			'Corbeil-Essonnes',
			'Commentry',
			'Commelle',
			'Colpo',
			'Cognin',
			'Codognan',
			'Cléon',
			'Chorges',
			'Choisy-en-Brie',
			'Chaumont-sur-Tharonne',
			'Chauffailles',
			'Châteauneuf-du-Pape',
			'Charnècles',
			'Charlieu',
			'Charantonnay',
			'Châlus',
			'Cézac',
			'Caux',
			'Castillon-du-Gard',
			'Carlepont',
			'Carbonne',
			'Caraman',
			'Cappelle-en-Pévèle',
			'Cany-Barville',
			'Cantin',
			'Canohès',
			'Camiers',
			'Cambo-les-Bains',
			'Caen',
			'Altorf',
			'Aiglemont',
			'Abondance',
			'Lyon 02',
			'Marseille 14',
			'Rouet',
			'Saint-Lambert',
			'Burnhaupt-le-Bas',
			'Bubry',
			'Brive-la-Gaillarde',
			'Breuil-le-Sec',
			'Boynes',
			'Bouvron',
			'Bourgogne',
			'Boult-sur-Suippe',
			'Bouligny',
			'Bouliac',
			'Bollezeele',
			'Bois-de-Céné',
			'Bléneau',
			'Blausasc',
			'Bignoux',
			'Bersée',
			'Béning-lès-Saint-Avold',
			'Bellerive-sur-Allier',
			'Belfort',
			'Beaurepaire',
			'Beaumont-du-Périgord',
			'Beaulieu-sur-Layon',
			'Beaufort-en-Vallée',
			'Bains',
			'Aviron',
			'Avignonet-Lauragais',
			'Auneau',
			'Auffargis',
			'Auchel',
			'Auboué',
			'Aubigny',
			'Aubiet',
			'Aube-sur-Rîle',
			'Auberchicourt',
			'Assas',
			'Asnières-sur-Seine',
			'Artiguelouve',
			'Arthon-en-Retz',
			'Arnouville',
			'Arles',
			'Arcis-sur-Aube',
			'Annonay',
			'Annecy-le-Vieux',
			'Ambierle',
			'Zonza',
			'Zillisheim',
			'Yffiniac',
			'Wintzenheim',
			'Vrigne-aux-Bois',
			'Vouneuil-sur-Vienne',
			'Vonnas',
			'Volstroff',
			'Viry-Noureuil',
			'Violay',
			'Villiers-sur-Marne',
			'Ville-la-Grand',
			'Villejust',
			'Villandry',
			'Vigneux-de-Bretagne',
			'Vieux-Berquin',
			'Vieille-Brioude',
			'Vic-sur-Cère',
			'Vezins',
			'Verrières',
			'Vendays-Montalivet',
			'Venasque',
			'Vélines',
			'Vaujours',
			'Varennes-sur-Seine',
			'Valençay',
			'Trets',
			'Touvre',
			'Tournan-en-Brie',
			'Touques',
			'Tôtes',
			'Thuré',
			'Thilay',
			'Taverny',
			'Spay',
			'Soucieu-en-Jarrest',
			'Sorges',
			'Solgne',
			'Simandre',
			'Serquigny',
			'Sermoise-sur-Loire',
			'Sermaises',
			'Selles-Saint-Denis',
			'Sarras',
			'Saivres',
			'Saint-Saturnin',
			'Saint-Perreux',
			'Saint-Pée-sur-Nivelle',
			'Saint-Paul',
			'Saint-Pathus',
			'Saint-Nicolas-de-Port',
			'Saint-Mesmin',
			'Saint-Max',
			'Saint-Maurice-la-Clouère',
			'Saint-Martin-Osmonville',
			'Saint-Malo',
			'Saint-Lunaire',
			'Saint-Lô',
			'Saint-Laurent-en-Royans',
			'Saint-Julien-Molin-Molette',
			'Saint-Julien-lès-Metz',
			'Saint-Julien',
			'Saint-Joseph-de-Rivière',
			'Saint-Jean-sur-Couesnon',
			'Saint-Jean',
			'Saint-Germain-sur-Moine',
			'Saint-Germain-lès-Arpajon',
			'Saint-Germain-du-Puch',
			'Saint-Georges-sur-Allier',
			'Saint-Georges-lès-Baillargeaux',
			'Saint-Georges-de-Reneins',
			'Saint-Georges-de-Pointindoux',
			'Saint-Georges-de-Montaigu',
			'Saint-Genis-Laval',
			'Saint-Génis-des-Fontaines',
			'Saint-Fulgent',
			'Saint-Étienne-en-Coglès',
			'Sainte-Marie-de-Ré',
			'Sainte-Anne-sur-Brivet',
			'Saint-Drézéry',
			'Saint-Chamas',
			'Saint-André-le-Gaz',
			'Saint-Agathon',
			'Saclas',
			'Rozoy-sur-Serre',
			'Rougemont-le-Château',
			'Rognonas',
			'Rochefort-sur-Loire',
			'Pruillé-le-Chétif',
			'Prémesques',
			'Poulainville',
			'Pont-Sainte-Maxence',
			"Pont-l'Abbé-d'Arnoult",
			'Pontcharra-sur-Turdine',
			'Plourivo',
			'Plouigneau',
			'Plouénan',
			'Plouagat',
			'Plonéour-Lanvern',
			'Ploëzal',
			'Planguenoual',
			'Plaimpied-Givaudins',
			'Pierrelatte',
			'Peyrat-de-Bellac',
			'Petite-Forêt',
			'Perrigny',
			'Péronne',
			'Penvénan',
			'Paulhan',
			'Parçay-Meslay',
			'Panazol',
			'Pamproux',
			'Ouzouer-sur-Loire',
			'Orange',
			'Noyen-sur-Sarthe',
			'Noyelles-Godault',
			'Névez',
			'Nevers',
			'Neuville-aux-Bois',
			'Neuilly-sous-Clermont',
			'Neuf-Berquin',
			'Nègrepelisse',
			'Mutzig',
			'Mouchard',
			'Mornant',
			'Mont-Saint-Aignan',
			'Monts',
			'Montpont-en-Bresse',
			'Montournais',
			'Montluçon',
			'Mont-de-Lans',
			'Montcaret',
			'Montaigu',
			'Moissac',
			'Moisenay',
			'Mirande',
			'Mézériat',
			'Meysse',
			'Messei',
			'Meslan',
			'Méru',
			'Mende',
			'Meillac',
			'Méaudre',
			'Mazères-Lezons',
			'Martres-Tolosane',
			'Martigné-sur-Mayenne',
			'Marcoing',
			'Marcamps',
			'Malataverne',
			'Lunéville',
			'Lumigny-Nesles-Ormeaux',
			'Luçay-le-Mâle',
			'Louhans',
			'Loriol-du-Comtat',
			'Linars',
			'Levier',
			'Les Pennes-Mirabeau',
			'Les Fourgs',
			'Les Essarts-le-Roi',
			'Les Échelles',
			'Les Authieux-sur-le-Port-Saint-Ouen',
			'Léry',
			'Lérouville',
			'Le Pont-de-Claix',
			'Lentilly',
			'Le Grand-Lucé',
			'Le Fauga',
			'Le Champ-près-Froges',
			'Le Bugue',
			'Le Barcarès',
			'La Valette-du-Var',
			'Lauwin-Planque',
			'La Tour-du-Crieu',
			'La Sauve',
			'Larche',
			'Lantosque',
			'Langoiran',
			'Landaul',
			'Lailly-en-Val',
			'La Haye-du-Puits',
			'La Grande-Paroisse',
			'Ladignac-le-Long',
			'La Croix-en-Touraine',
			'La Courneuve',
			'Lacaune',
			'Kembs',
			'Issoudun',
			'Ingré',
			'Huismes',
			'Holnon',
			'Hillion',
			'Heudreville-sur-Eure',
			'Guipel',
			'Guer',
			'Grésy-sur-Aix',
			'Gœrsdorf',
			'Givors',
			'Gif-sur-Yvette',
			'Fréjairolles',
			'Fors',
			'Fontenay-le-Comte',
			'Folkling',
			'Flourens',
			'Flavin',
			'Fère-Champenoise',
			'Excideuil',
			'Évreux',
			'Esvres',
			'Essert',
			'Essars',
			'Éleu-dit-Leauwette',
			'Eguisheim',
			'Écouflant',
			'Drulingen',
			'Dinan',
			'Dijon',
			'Devecey',
			'Déols',
			'Decazeville',
			'Cuers',
			'Crépy',
			'Couterne',
			'Coutances',
			'Courthézon',
			'Cournonterral',
			'Courdimanche',
			'Coullons',
			'Coulaines',
			'Couchey',
			'Cormeilles',
			'Coquelles',
			'Contres',
			'Compertrix',
			'Colombiers',
			'Collobrières',
			'Choisy-au-Bac',
			'Chavenay',
			'Chaulgnes',
			'Chauconin-Neufmontiers',
			'Châtillon-sur-Chalaronne',
			'Châtillon-en-Vendelais',
			'Châteauneuf-le-Rouge',
			'Châteauneuf-de-Gadagne',
			'Châteaubernard',
			'Champier',
			'Champagné',
			'Chalonnes-sur-Loire',
			'Cervione',
			'Cauro',
			'Cannes',
			'Campsas',
			'Campan',
			'Campagne-lès-Hesdin',
			'Cagnes-sur-Mer',
			'Cagnac-les-Mines',
			'Broglie',
			'Brix',
			'Brion',
			'Bréviandes',
			'Bréval',
			'Breuillet',
			'Brazey-en-Plaine',
			'Bray-sur-Somme',
			'Braud-et-Saint-Louis',
			'Bouleurs',
			'Bouchain',
			'Blendecques',
			'Behren-lès-Forbach',
			'Beaucaire',
			'Barneville-Carteret',
			'Ballots',
			'Balazé',
			'Balan',
			'Bainville-sur-Madon',
			'Bages',
			'Azille',
			'Avesnes-les-Aubert',
			'Aulnois-sous-Laon',
			'Archettes',
			'Arcachon',
			'Anzin-Saint-Aubin',
			'Angervilliers',
			'Aire-sur-la-Lys',
			"Les Côtes-d'Arey",
			'Belsunce',
			'Blaye',
			'Zuydcoote',
			'Vinneuf',
			'Villeurbanne',
			'Villemoisson-sur-Orge',
			'Villelaure',
			'Villefranche-sur-Mer',
			'Villeblevin',
			'Villars',
			'Ver-sur-Mer',
			'Verny',
			'Verniolle',
			'Venansault',
			'Vaudry',
			'Uxegney',
			'Usson-du-Poitou',
			'Urrugne',
			'Tréméven',
			'Toury',
			'Tonnay-Charente',
			'Tocane-Saint-Apre',
			'Thourotte',
			'Thiviers',
			'Téteghem',
			'Tessy-sur-Vire',
			'Taradeau',
			'Sussargues',
			'Surgères',
			'Soultz-Haut-Rhin',
			'Songeons',
			'Sin-le-Noble',
			'Sillé-le-Guillaume',
			'Signy-le-Petit',
			'Sierck-les-Bains',
			'Semoy',
			'Sémalens',
			'Savy-Berlette',
			'Sauvian',
			'Satillieu',
			'Sartrouville',
			'Sallebœuf',
			'Saint-Vincent-de-Tyrosse',
			'Saint-Varent',
			'Saint-Thibault-des-Vignes',
			'Saint-Sever-Calvados',
			'Saints',
			'Saint-Romain-sur-Cher',
			'Saint-Rémy-sur-Durolle',
			'Saint-Pryvé-Saint-Mesmin',
			'Saint-Nicolas-de-Redon',
			"Saint-Nazaire-d'Aude",
			'Saint-Menet',
			'Saint-Maurice-la-Souterraine',
			'Saint-Martin-en-Bresse',
			'Saint-Loubès',
			'Saint-Just-Malmont',
			'Saint-Jérôme',
			'Saint-Jean-de-Moirans',
			'Saint-Jacut-les-Pins',
			'Saint-Hélen',
			'Saint-Gildas-des-Bois',
			"Saint-Féliu-d'Avall",
			'Sainte-Gemme-la-Plaine',
			'Saint-Denis-en-Val',
			'Saint-Cosme-en-Vairais',
			'Saint-Calais',
			'Saint-Aubert',
			'Sains-en-Amiénois',
			'Sahurs',
			'Rumegies',
			'Roye',
			'Roissy-en-Brie',
			'Roches-Prémarie-Andillé',
			'Richemont',
			'Rians',
			'Reventin-Vaugris',
			'Rémelfing',
			'Quiberon',
			'Quevauvillers',
			'Quettehou',
			'Querrien',
			'Prunelli-di-Fiumorbo',
			'Preignac',
			'Port-Sainte-Foy-et-Ponchapt',
			'Port à Binson',
			'Pont-Audemer',
			'Pons',
			'Pollestres',
			'Pluzunet',
			'Pluguffan',
			'Plounévézel',
			'Plounéventer',
			'Plomodiern',
			'Pleumeleuc',
			'Pleumartin',
			'Piégut-Pluviers',
			'Péaule',
			'Pavie',
			'Paris',
			'Paradou',
			'Ouveillan',
			'Orthez',
			'Ormoy',
			'Orleix',
			'Offranville',
			'Nostang',
			'Noisiel',
			'Nogent-le-Rotrou',
			'Nogentel',
			'Niderviller',
			'Neuilly-sur-Seine',
			'Néoules',
			'Munchhouse',
			'Morschwiller-le-Bas',
			'Morienval',
			'Mont-Saint-Éloi',
			'Montigny-lès-Metz',
			'Mont-de-Marsan',
			'Molinet',
			'Molières-sur-Cèze',
			'Moirans-en-Montagne',
			'Mirecourt',
			'Mimbaste',
			'Mézières-en-Drouais',
			'Mexy',
			'Meximieux',
			'Messeix',
			'Merville',
			'Mers-les-Bains',
			'Mercurol-Veaunes',
			'Maure-de-Bretagne',
			'Maule',
			'Martel',
			'Marly',
			'Marin',
			'Manthelan',
			'Manduel',
			'Malesherbes',
			'Lucq-de-Béarn',
			'Louzy',
			'Louvigné-de-Bais',
			'Loudun',
			'Lons',
			'Livet-et-Gavet',
			'Liesse-Notre-Dame',
			'Levallois-Perret',
			'Valdahon',
			'Le Val',
			'Les Salles-du-Gardon',
			'Les Rousses',
			'Les Arcs',
			'Le Pouzin',
			'Le Pertre',
			'Le Pecq',
			'Le Mée-sur-Seine',
			'Le Loroux-Bottereau',
			'Le Fuilet',
			'Leforest',
			'Leffrinckoucke',
			'Le Dorat',
			'Le Chesnay',
			"Le Bousquet-d'Orb",
			'Le Bourget-du-Lac',
			'Lattes',
			'Latillé',
			'La Talaudière',
			'Lapte',
			'La Norville',
			'Lanmeur',
			'Langrune-sur-Mer',
			'Landivy',
			'Lamastre',
			'La Génétouze',
			'Lafrançaise',
			'La Couture',
			'La Chevrolière',
			'Lachapelle-aux-Pots',
			'La Biolle',
			'Kerbach',
			'Jouy-le-Moutier',
			'Jouy-le-Châtel',
			'Jasseron',
			'Issé',
			'Idron',
			'Heugas',
			'Héry',
			'Herbignac',
			'Herbeys',
			'Herbault',
			'Hénon',
			'Haulchin',
			'Harfleur',
			'Harbonnières',
			'Hanvec',
			'Gouville-sur-Mer',
			'Gommegnies',
			'Glomel',
			'Gardouch',
			'Gamaches',
			'Fumel',
			'Frontignan',
			'Frontenex',
			'Forest-sur-Marque',
			'Fontenay-sur-Loing',
			'Fontaine-le-Bourg',
			'Feyzin',
			'Ferrette',
			'Faulquemont',
			'Ézanville',
			'Étang-sur-Arroux',
			'Étagnac',
			'Douvrin',
			'Doussard',
			'Douai',
			'Die',
			'Darnétal',
			'Dampierre-en-Burly',
			'Cysoing',
			'Custines',
			'Crottet',
			'Cran-Gevrier',
			'Coursan',
			'Couffé',
			'Condé-sur-Vesgre',
			'Condé-sur-Noireau',
			'Cocheren',
			'Clonas-sur-Varèze',
			'Civray',
			'Chênehutte-Trèves-Cunault',
			'Chaudron-en-Mauges',
			'Châtillon-en-Michaille',
			'Châteauneuf-sur-Isère',
			'Chasné-sur-Illet',
			'Charnay-lès-Mâcon',
			'Charleval',
			'Charenton-le-Pont',
			'Challes-les-Eaux',
			'Chadrac',
			'Chabris',
			'Cerelles',
			'Cauterets',
			'Cauchy-à-la-Tour',
			'Catenoy',
			'Carhaix-Plouguer',
			'Brioude',
			'Breuil-le-Vert',
			'Bretignolles-sur-Mer',
			'Breteil',
			'Bracieux',
			'Bournoncle-Saint-Pierre',
			'Bompas',
			'Bilieu',
			'Biars-sur-Cère',
			'Biard',
			'Berteaucourt-les-Dames',
			'Berstett',
			'Bennwihr',
			'Beaumetz-lès-Loges',
			'Beaucourt',
			'Bastia',
			'Basse-Ham',
			'Barcelonnette',
			'Bapaume',
			'Bailleul-Sir-Berthoult',
			'Baillargues',
			'Bagnères-de-Luchon',
			'Auxonne',
			'Aulnay-sous-Bois',
			'Arthès',
			'Artannes-sur-Indre',
			'Arc-sur-Tille',
			'Arbois',
			'Amblainville',
			'Allonnes',
			'Allanche',
			'Ahetze',
			'Abbeville',
			'Marseille 03',
			'Noailles',
			'Saint-Tronc',
			'La Fourragère',
			'La Rose',
			'Zimmersheim',
			'Yport',
			'Yenne',
			'Wizernes',
			'Woerth',
			'Wisches',
			'Wiesviller',
			'Watten',
			'Walincourt-Selvigny',
			'Vittel',
			'Vitteaux',
			'Villevieille',
			'Villers-Saint-Paul',
			'Villers-lès-Nancy',
			'Villeneuve-Saint-Georges',
			'Villeneuve',
			'Village-Neuf',
			'Vielmur-sur-Agout',
			'Vertou',
			'Verneuil-sur-Vienne',
			'Velaine-en-Haye',
			'Varrains',
			'Varetz',
			'Vaiges',
			'Ustaritz',
			'Trouillas',
			'Trévol',
			'Toulon-sur-Arroux',
			'Théza',
			'Tenteling',
			'Tauriac',
			'Tarbes',
			'Tallende',
			'Strasbourg',
			'Stains',
			'Souppes-sur-Loing',
			'Soulaire',
			'Sommières',
			'Soliers',
			'Serres',
			'Sergy',
			'Serémange-Erzange',
			'Saussay',
			'Sartilly',
			'Sarry',
			'Santec',
			'Saméon',
			'Saint-Thibéry',
			'Saintry-sur-Seine',
			'Saint-Raphaël',
			'Saint-Prix',
			'Saint-Pierre-du-Perray',
			'Saint-Père-en-Retz',
			'Saint-Père-Marc-en-Poulet',
			'Saint-Paul-lès-Dax',
			'Saint-Martin-de-Queyrières',
			'Saint-Leu',
			'Saint-Léger-lès-Domart',
			'Saint-Laurent-du-Var',
			'Saint-Lambert-la-Potherie',
			'Saint-Germain-en-Laye',
			'Saint-Georges-sur-Cher',
			'Saint-Gély-du-Fesc',
			'Saint-Félix-Lauragais',
			'Saint-Étienne-de-Fontbellon',
			'Sainte-Honorine-du-Fay',
			'Saint-Christoly-de-Blaye',
			'Saint-Aubin-Routot',
			'Saint-André-sur-Orne',
			'Saint-Aigulin',
			'Rosny-sous-Bois',
			'Rohan',
			'Roeschwoog',
			'Roche-la-Molière',
			'Rochefort-du-Gard',
			'Rion-des-Landes',
			'Rambouillet',
			'Quéven',
			'Quarouble',
			'Propriano',
			'Pouligny-Saint-Pierre',
			'Poses',
			'Pontlevoy',
			'Poisy',
			'Plerguer',
			'Plémet',
			'Pierry',
			'Pierre-de-Bresse',
			'Pertuis',
			'Perreux',
			'Payrin-Augmontel',
			'Patay',
			'Parçay-les-Pins',
			'Palavas-les-Flots',
			'Pabu',
			'Ormesson-sur-Marne',
			'Ollainville',
			'Oignies',
			'Ohlungen',
			'Oberhausbergen',
			'Nueil-sur-Layon',
			'Neuvy-Sautour',
			'Neuvy-Pailloux',
			'Neuville-Saint-Rémy',
			'Neuvic-Entier',
			'Neuves-Maisons',
			'Nesles',
			'Nemours',
			'Mulhouse',
			'Mortagne-sur-Gironde',
			'Moret-sur-Loing',
			'Monthyon',
			'Montfermeil',
			'Montescot',
			'Montbeton',
			'Montaure',
			'Mondoubleau',
			'Moisselles',
			'Mirebeau',
			'Mirambeau',
			'Minihy-Tréguier',
			'Massiac',
			'Mary-sur-Marne',
			'Martinvast',
			'Marsat',
			'Marly-le-Roi',
			'Marles-en-Brie',
			'Marcilly-le-Châtel',
			'Manzat',
			'Manéglise',
			'Maisse',
			'Lunery',
			'Louviers',
			'Loos-en-Gohelle',
			'Looberghe',
			'Locmariaquer',
			'Liverdy-en-Brie',
			'Linards',
			'Lille',
			'Ligny-le-Ribault',
			'Chambretaud',
			'Caumont',
			'Castelnau-de-Guers',
			'Casson',
			'Carbon-Blanc',
			'Caden',
			'Bruay-la-Buissière',
			'Brienon-sur-Armançon',
			'Bricy',
			'Brest',
			'Brebières',
			'Boussières',
			'Bousse',
			'Bourgneuf',
			'Bouloire',
			'Bouaye',
			'Bort-les-Orgues',
			'Bonnefamille',
			'Bondy',
			'Bois-Guillaume',
			'Bidart',
			'Bessines',
			'Berneval-le-Grand',
			'Bérat',
			'Bellengreville',
			'Bellegarde-sur-Valserine',
			'Bellac',
			'Beausoleil',
			'Beaulieu-sur-Dordogne',
			'Bayeux',
			'Bassussarry',
			'Bassens',
			'Bargemon',
			'Barbazan-Debat',
			'Bains-sur-Oust',
			'Ayron',
			'Autrans',
			'Arzon',
			'Ansouis',
			'Andrésy',
			'Andrest',
			'Ambleny',
			'Ambazac',
			'Algrange',
			'Albitreccia',
			'Alata',
			'Aix-en-Provence',
			'Ahuy',
			'Achiet-le-Grand',
			'Achères',
			'Lyon 05',
			'Marseille 10',
			'Les Médecins',
			'Palama',
			'Verduron',
			'Lesparre-Médoc',
			'Les Magnils-Reigniers',
			'Les Baumettes',
			'Le Rouret',
			'Le Raincy',
			'Le Portel',
			'Le Perrier',
			'Le Péage-de-Roussillon',
			'Le Mesnil-sur-Oger',
			'Le Houlme',
			'Le Grand-Bourg',
			'Le Fousseret',
			'Le Coudray',
			'Le Cheylas',
			'La Vaupalière',
			'La Tour',
			'La Roche-Maurice',
			'Larmor-Baden',
			'Lanvénégen',
			'Landas',
			'Lamarche',
			'La Jubaudière',
			'Laguiole',
			'La Grande-Motte',
			'La Croix-Valmer',
			'La Boisse',
			'Labaroche',
			'Juigné sur Loire',
			'Joux-la-Ville',
			'Itxassou',
			'Irvillac',
			'Irigny',
			'Hyères',
			'Houdain',
			'Hœrdt',
			'Hirel',
			'Hindisheim',
			'Hesdin',
			'Hérouville-Saint-Clair',
			'Héricy',
			'Héricourt',
			'Heimsbrunn',
			'Guewenheim',
			'Grosbreuil',
			'Grisy-Suisnes',
			'Grambois',
			'Gradignan',
			'Gouy-sous-Bellonne',
			'Gouézec',
			'Gestel',
			'Gardonne',
			'Garancières',
			'Fréthun',
			'Fresse-sur-Moselle',
			'Foucherans',
			'Fontenay-sous-Bois',
			'Fontannes',
			'Fleury-la-Vallée',
			'Feytiat',
			'Fenouillet',
			'Évry',
			'Escoutoux',
			'Erbray',
			'Épouville',
			'Ennetières-en-Weppes',
			'Enchenberg',
			'Écuisses',
			'Eckwersheim',
			'Dourges',
			'Donchery',
			'Domagné',
			"Dolus-d'Oléron",
			'Dingé',
			'Desvres',
			'Delle',
			'Cunac',
			'Cublac',
			'Crolles',
			'Crach',
			"Couzon-au-Mont-d'Or",
			'Corné',
			'Cormeilles-en-Parisis',
			'Corbigny',
			'Corbenay',
			'Contes',
			'Colmar',
			'Clohars-Carnoët',
			'Cléder',
			'Chevreuse',
			'Cheverny',
			'Chenoise',
			'Chelles',
			'Chaumontel',
			'Chatte',
			'Châtillon-sur-Cluses',
			'Châteauroux',
			'Château-Gontier',
			'Châteaudun',
			'Château-du-Loir',
			"Château-d'Olonne",
			'Chappes',
			'Chaource',
			'Chantilly',
			'Champigneulles',
			'Champagné-les-Marais',
			'Chamonix-Mont-Blanc',
			'Chambéry',
			'Cers',
			'Cénac',
			'Cellettes',
			'Carnoules',
			'Cantenac',
			'Cairon',
			'Caderousse',
			'Buzet-sur-Baïse',
			'Buros',
			'Burnhaupt-le-Haut',
			'Weyersheim',
			'Wasquehal',
			'Voulx',
			'Vitry-aux-Loges',
			'Villiers-sur-Loir',
			'Villers-Cotterêts',
			'Villers-Bocage',
			'Villeneuve-Tolosane',
			'Villeneuve-sur-Bellot',
			'Villeneuve-la-Comptal',
			"Villefranche-d'Albigeois",
			'Villebernier',
			'Vézelise',
			'Veyrac',
			'Vert-le-Petit',
			'Vernègues',
			'Vernantes',
			'Vergèze',
			'Véretz',
			'Venelles',
			'Vendenheim',
			'Veauche',
			'Vaulx-Vraucourt',
			'Vaugneray',
			'Valognes',
			'Valmont',
			'Valensole',
			'Valence-sur-Baïse',
			'Upie',
			'Tréon',
			'Toutlemonde',
			'Tonneins',
			'Tilques',
			'Tignieu',
			'Tigery',
			'Tassin-la-Demi-Lune',
			'Sundhoffen',
			'Souvigny',
			'Soulaines-sur-Aubance',
			'Sotteville-lès-Rouen',
			'Sorigny',
			'Sonnay',
			'Soignolles-en-Brie',
			'Sevrier',
			'Seurre',
			'Servon',
			'Sentheim',
			'Senlis',
			'Seillans',
			'Sedan',
			'Scorbé-Clairvaux',
			'Schnersheim',
			'Savigné',
			'Saverne',
			'Sauveterre-de-Béarn',
			'Sault',
			'Sars-Poteries',
			'Sarlat-la-Canéda',
			'Sansac-de-Marmiesse',
			'San-Nicolao',
			'Salies-du-Salat',
			'Saleilles',
			'Saint-Victurnien',
			'Saint-Vérand',
			'Saint-Symphorien-sur-Coise',
			'Saint-Senier-sous-Avranches',
			'Saint-Sébastien-sur-Loire',
			'Saint-Sauveur-sur-École',
			'Saint-Quentin-sur-le-Homme',
			'Saint-Pierre-Montlimart',
			'Saint-Pierre-du-Chemin',
			"Saint-Pierre-d'Oléron",
			'Saint-Pierre-de-Bœuf',
			'Saint-Paul-de-Varax',
			'Saint-Ouen-des-Alleux',
			'Saint-Maur',
			'Saint-Martin-Longueau',
			'Saint-Martin-le-Vinoux',
			'Saint-Martin-en-Haut',
			'Saint-Martin-des-Champs',
			'Saint-Martin-de-Fontenay',
			'Saint-Marcel-lès-Valence',
			'Saint-Lubin-des-Joncherets',
			'Saint-Laurent-de-la-Salanque',
			'Saint-Just-Saint-Rambert',
			'Saint-Julien-les-Villas',
			'Saint-Jean-sur-Veyle',
			'Saint-Jean-de-Niost',
			'Saint-Hippolyte',
			'Saint-Hilaire-de-Loulay',
			'Saint-Georges-de-Mons',
			'Saint-Genès-Champanelle',
			'Saint-Florent-sur-Auzonnet',
			'Saint-Étienne-sur-Chalaronne',
			'Sainte-Livrade-sur-Lot',
			'Sainte-Hélène',
			'Saint-Dié-des-Vosges',
			'Saint-Clair-du-Rhône',
			'Saint-Chéron',
			'Saint-Brice',
			'Saint-Aubin-lès-Elbeuf',
			'Saint-Antoine-du-Rocher',
			'Saint-André-de-la-Roche',
			'Saint-Amand-les-Eaux',
			'Ruelisheim',
			'Rouen',
			'Rosières-en-Santerre',
			'Rosières',
			'Roncherolles-sur-le-Vivier',
			'Romanèche-Thorins',
			'Romagnieu',
			'Roisel',
			'Riorges',
			'Rennes',
			'Renaison',
			'Reichshoffen',
			'Ramatuelle',
			'Puisserguier',
			'Provin',
			'Prades',
			'Poullan-sur-Mer',
			'Pouldreuzic',
			'Potigny',
			'Port-Saint-Père',
			'Pontvallain',
			'Pont-Saint-Vincent',
			'Pontpoint',
			'Pont-Hébert',
			'Pont-à-Mousson',
			'Poncin',
			'Pluvigner',
			'Plouha',
			'Plouguenast',
			'Plougonven',
			'Pleugriffet',
			'Pierrefort',
			'Pierrefitte-sur-Seine',
			'Pierrefitte-Nestalas',
			'Pierre-Châtel',
			'Phalempin',
			'Petite-Rosselle',
			'Peille',
			'Ostwald',
			'Orval',
			'Ornex',
			'Orgerus',
			'Oraison',
			'Oloron-Sainte-Marie',
			'Oinville-sur-Montcient',
			'Nousty',
			'Notre-Dame-de-Mésage',
			'Noisy-le-Grand',
			'Nogent-le-Roi',
			'Nîmes',
			'Nice',
			'Neuville-sur-Oise',
			'Neulise',
			'Neuillé-Pont-Pierre',
			'Neufgrange',
			'Neufchâtel-Hardelot',
			'Nesles-la-Vallée',
			'Narbonne',
			'Nantua',
			'Moûtiers',
			'Mortcerf',
			'Montreuil-sur-Ille',
			'Montmarault',
			'Montjoie-en-Couserans',
			'Montgeron',
			'Montenois',
			'Montalieu',
			'Montaigut-sur-Save',
			'Montagnat',
			'Monnières',
			'Mios',
			'Mézy-sur-Seine',
			'Meyrueis',
			'Messimy',
			'Mertzwiller',
			'Méreau',
			'Melle',
			'Meistratzheim',
			'Masseube',
			'Masny',
			'Marly-la-Ville',
			'Marly',
			'Marigny-Brizay',
			'Mareuil-sur-Ourcq',
			'Marcorignan',
			'Marange-Silvange',
			'Malemort-du-Comtat',
			'Magenta',
			'Maffliers',
			'Mably',
			'Lussac',
			'Lunel-Viel',
			'Losne',
			'Livinhac-le-Haut',
			'Lillebonne',
			'Ligugé',
			'Hôpital-Camfrout',
			'Le Vieil-Baugé',
			"L'Étang-la-Ville",
			'Les Peintures',
			'Les Loges',
			'Les Clouzeaux',
			'Le Relecq-Kerhuon',
			'Le Plessis-Brion',
			'Le Perréon',
			'Léon',
			'Le Monastère',
			'Le Gua',
			'Le Drennec',
			'Lecelles',
			'Le Breil-sur-Mérize',
			'Le Boulou',
			'La Verrière',
			'Latresne',
			'La Tremblade',
			'La Tranche-sur-Mer',
			'La Tour-en-Jarez',
			'Lanouée',
			'Descartes',
			'La Guerche-de-Bretagne',
			'La Gorgue',
			'La Francheville',
			'La Flèche',
			'La Feuillie',
			'La Ferté-Milon',
			'La Chapelle-Saint-Laurent',
			'La Chapelle-des-Fougeretz',
			'La Bourboule',
			'La Bégude-de-Mazenc',
			'Jebsheim',
			'Hœnheim',
			'Herrlisheim-près-Colmar',
			'Groissiat',
			'Gries',
			'Grenay',
			'Granges-sur-Vologne',
			'Gontaud-de-Nogaret',
			'Gonfaron',
			'Goetzenbruck',
			'Gières',
			'Ger',
			'Gelles',
			'Gabarret',
			'Fresnes',
			'Fresnay-sur-Sarthe',
			'Foug',
			'Fontaines-Saint-Martin',
			'Fleury-Mérogis',
			'Faverney',
			'Falicon',
			'Ergué-Gabéric',
			'Druelle',
			'Dreuil-lès-Amiens',
			'Drémil-Lafage',
			'Doudeville',
			'Dompierre-sur-Besbre',
			'Domme',
			'Divion',
			'Derval',
			'Davézieux',
			'Daumeray',
			'Damelevières',
			'Dainville',
			'Cuincy',
			'Crosne',
			'Coussac-Bonneval',
			'Coulommiers',
			'Coubron',
			'Corseul',
			'Corbreuse',
			'Conlie',
			'Collonges',
			'Coignières',
			'Clouange',
			'Cintré',
			'Chindrieux',
			'Chevanceaux',
			'Chépy',
			'Cheny',
			'Chaumont',
			'Châtillon-sur-Seine',
			'Châtillon',
			'Châtillon',
			'Châtenoy-le-Royal',
			'Châteauneuf-les-Martigues',
			'Chassagny',
			'Chartres-de-Bretagne',
			'Chancelade',
			'Champenoux',
			'Chamberet',
			'Chalabre',
			'Certines',
			'Cérons',
			"Cazères-sur-l'Adour",
			'Cattenom',
			'Carquefou',
			'Cambrai',
			'Cambes',
			'Calvi',
			'Callas',
			'Cabasse',
			'Burlats',
			'Bruyères-et-Montbérault',
			'Broût-Vernet',
			'Brinon-sur-Sauldre',
			'Briey',
			'Bricquebec',
			'Bretteville',
			'Breteuil',
			'Brando',
			'Brandérion',
			'Bouvesse-Quirieu',
			'Boeil-Bezing',
			'Blénod-lès-Pont-à-Mousson',
			'Billom',
			'Beuvillers',
			'Bertry',
			'Berrien',
			'Bernaville',
			'Belleville-sur-Meuse',
			'Beauval',
			'Beaulieu-sur-Mer',
			'Baziège',
			'Bavans',
			'Barbâtre',
			'Bain-de-Bretagne',
			'Bailly-Romainvilliers',
			'Ayse',
			'Avrillé',
			'Avanton',
			'Auxy',
			'Auxi-le-Château',
			'Auvillar',
			'Autun',
			'Aubais',
			'Artenay',
			'Ars-sur-Moselle',
			'Arques-la-Bataille',
			'Argentan',
			'Arenthon',
			'Archigny',
			'Annoeullin',
			'Andard',
			'Ancy-le-Franc',
			'Ampuis',
			'Aiton',
			'Ablain-Saint-Nazaire',
			'Saint-Mitre',
			'Saint-Quentin-en-Yvelines',
			'La Joliette',
			'Notre-Dame du Mont',
			'Vinsobres',
			'Villeneuve-Loubet',
			'Villedieu-sur-Indre',
			'Villebarou',
			'Viennay',
			'Veyrier-du-Lac',
			'Ventabren',
			'Vence',
			'Varennes-Jarcy',
			'Valfin-lès-Saint-Claude',
			'Vagney',
			'Uckange',
			'Trizay',
			'Trie-Château',
			'Tresbœuf',
			'Tournus',
			'Thorens-Glières',
			'Tatinghem',
			'Suèvres',
			'Steinbourg',
			'Soulac-sur-Mer',
			'Soubise',
			'Soorts',
			'Sochaux',
			'Smarves',
			'Sinceny',
			'Sigean',
			'Senones',
			'Seichamps',
			'Savigny-le-Temple',
			'Saulzoir',
			'Saulgé',
			'Salles-sur-Mer',
			'Sallanches',
			'Salernes',
			'Saint-Vrain',
			'Saint-Vigor-le-Grand',
			'Saint-Valery-sur-Somme',
			'Saint-Sulpice-la-Pointe',
			'Saint-Sauveur',
			'Saint-Saturnin',
			'Saint-Priest',
			'Saint-Pé-de-Bigorre',
			'Saint-Nom-la-Bretêche',
			'Saint-Nazaire-les-Eymes',
			'Saint-Maximin-la-Sainte-Baume',
			'Saint-Maurice-sur-Dargoire',
			'Saint-Martin-de-Valamas',
			'Saint-Martin-des-Noyers',
			'Saint-Martin-de-Londres',
			'Saint-Marcel',
			'Saint-Loup-sur-Semouse',
			'Saint-Léger-du-Bourg-Denis',
			'Saint-Just-le-Martel',
			"Saint-Just-d'Ardèche",
			'Saint-Just',
			'Saint-Jeoire',
			'Saint-Jean-de-la-Ruelle',
			'Saint-Hilaire-du-Rosier',
			'Saint-Gildas-de-Rhuys',
			'Saint-Gervais-la-Forêt',
			'Saint-Georges-sur-Eure',
			'Saint-Gence',
			'Saint-Folquin',
			'Saint-Félix',
			'Sainte-Verge',
			'Saint-Évarzec',
			'Sainte-Solange',
			'Saint-Cyr-sur-Loire',
			'Saint-Brice-sous-Forêt',
			'Saint-Baldoph',
			'Saint-Arnoult',
			'Saint-André-Treize-Voies',
			'Saint-Alban-Leysse',
			'Sainghin-en-Mélantois',
			'Ruffey-lès-Echirey',
			'Rouhling',
			'Rosières',
			'Roquemaure',
			'Roissy-en-France',
			'Rieux-Volvestre',
			'Ria-Sirach',
			'Réville',
			'Retournac',
			'Retonfey',
			'Restinclières',
			'Rédené',
			'Racquinghem',
			'Quistinic',
			'Quintin',
			'Quintenas',
			'Quettreville-sur-Sienne',
			'Prinquiau',
			'Port-Louis',
			'Pont-Saint-Esprit',
			'Pompignan',
			'Plouharnel',
			'Plombières-les-Bains',
			'Plœuc-sur-Lié',
			'Ploemel',
			'Pleucadeuc',
			'Plescop',
			'Pléchâtel',
			'Plancoët',
			'Plancher-les-Mines',
			'Pignan',
			'Péronnas',
			'Péron',
			'Pernes-les-Fontaines',
			'Paulx',
			'Pannecé',
			'Oytier-Saint-Oblas',
			'Orléans',
			'Oisseau',
			'Oderen',
			'Norrent-Fontes',
			'Nonancourt',
			'Nogaro',
			'Nieul-le-Dolent',
			'Neuwiller-lès-Saverne',
			'Neuvecelle',
			'Neuilly-en-Thelle',
			'Neufmanil',
			'Nay',
			'Nancray',
			'Mornac',
			'Montmélian',
			'Montbazon',
			'Montans',
			'Mons',
			'Moncontour',
			'Milly-sur-Thérain',
			'Meyrargues',
			'Meymac',
			'Mervent',
			'Merlimont',
			'Melgven',
			'Meilhan-sur-Garonne',
			'Mazères',
			'Mayet',
			'Marolles-en-Hurepoix',
			'Marigné-Laillé',
			'Mareuil-le-Port',
			'Mardié',
			'Maël-Carhaix',
			'Loyettes',
			'Lovagny',
			'Loupiac',
			'Longuyon',
			'Longnes',
			"L'Île-Bouchard",
			'Liergues',
			'Le Vigen',
			'Le Versoud',
			'Le Val-Saint-Germain',
			'Le Thor',
			'Les Ponts-de-Cé',
			'Le Sourn',
			'Les Mathes',
			'Les Epesses',
			"L'Escarène",
			'Le Puy-Notre-Dame',
			'Léognan',
			'Le Grand-Quevilly',
			'Le Cendre',
			'Le Broc',
			'Lavernose-Lacasse',
			'Lavault-Sainte-Anne',
			'Lannoy',
			'Lampaul-Plouarzel',
			'Lamorlaye',
			'Lambres-lez-Douai',
			'La Haie-Fouassière',
			'La Gacilly',
			'La Forêt-sur-Sèvre',
			'La Cluse-et-Mijoux',
			'La Chapelle-la-Reine',
			'La Celle',
			'Lacapelle-Marival',
			'La Bresse',
			'Labastide-Beauvoir',
			'La Bassée',
			'Jumièges',
			'Jonzac',
			'Joinville-le-Pont',
			'Iwuy',
			'Imphy',
			'Holving',
			'Heyrieux',
			'Hénouville',
			'Haspres',
			'Hasnon',
			'Guîtres',
			'Gueugnon',
			'Guesnain',
			'Gruchet-le-Valasse',
			'Gron',
			'Grimaud',
			'Grillon',
			'Grez-Neuville',
			'Gréoux-les-Bains',
			'Grandris',
			'Gosné',
			'Goincourt',
			'Gilley',
			'Garéoult',
			'Garennes-sur-Eure',
			'Gaillefontaine',
			'Fresnes-sur-Escaut',
			'Fraisses',
			'Fougerolles',
			'Fouesnant',
			'Fontenay-en-Parisis',
			'Fontaine-le-Comte',
			'Fontaine',
			'Fessenheim',
			'Férin',
			'Felleries',
			'Fargues-Saint-Hilaire',
			'Eyvigues-et-Eybènes',
			'Eymoutiers',
			'Évian-les-Bains',
			'Étauliers',
			'Essey-lès-Nancy',
			'La Baule-Escoublac',
			'Eschbach',
			'Escaudœuvres',
			'Escaudain',
			'Épinay-sur-Orge',
			'Éoures',
			'Elbeuf',
			'Écrouves',
			'Duppigheim',
			'Dogneville',
			'Dissay',
			'Didenheim',
			'Deyvillers',
			'Deûlémont',
			'Darney',
			'Dampmart',
			'Cuverville',
			'Créhen',
			'Courtisols',
			'Courrières',
			'Cour-Cheverny',
			'Courcelles-sur-Seine',
			'Coulanges-lès-Nevers',
			'Coucy-le-Château-Auffrique',
			'Pontault-Combault',
			'Colombiès',
			'Cogolin',
			'Clerval',
			'Clermont-Créans',
			'Chevillon',
			'Chermignac',
			'Chérac',
			'Chavigny',
			'Chaumont-en-Vexin',
			'Châtillon-la-Palud',
			'Châteaulin',
			'Château-Chinon(Ville)',
			'Châteaubourg',
			'Charmes',
			'Charly-sur-Marne',
			'Charbonnières-les-Varennes',
			'Champtoceaux',
			'Champhol',
			'Châlons-en-Champagne',
			'Chalindrey',
			'Cestas',
			'Céreste',
			'Cérans-Foulletourte',
			'Caylus',
			'Cauvigny',
			'Carentoir',
			'Camps-la-Source',
			'Cambes-en-Plaine',
			'Calais',
			'Cagny',
			'Busigny',
			'Burie',
			'Bruyères-le-Châtel',
			'Brunstatt',
			'Brionne',
			'Brigueuil',
			'Brécey',
			'Bray-en-Val',
			'Bouttencourt',
			'Bourogne',
			'Bourg-Saint-Andéol',
			'Bourges',
			'Bourganeuf',
			"Bourbon-l'Archambault",
			'Boulange',
			'Bouillé-Loretz',
			"Bosmie-l'Aiguille",
			'Bollène',
			'Boisseron',
			'Bohars',
			'Blérancourt',
			'Biesheim',
			'Bienville',
			'Bezannes',
			'Beuzeville-la-Grenier',
			'Bellevaux',
			'Beaupréau',
			'Beaumont-sur-Lèze',
			'Beaumont',
			'Bantzenheim',
			'Baho',
			'Avesnes-le-Comte',
			'Auxerre',
			'Aucamville',
			'Aubière',
			'Arnas',
			'Armentières',
			'Arches',
			'Archamps',
			'Arbent',
			'Andlau',
			'Ancy-sur-Moselle',
			'Ancône',
			'Albigny-sur-Saône',
			'Aixe-sur-Vienne',
			'Aigurande',
			'Le Ban Saint-Martin',
			'Les Ulis',
			'Marseille Prefecture',
			'La Viste',
			'Saint-Pierre',
			'Youx',
			'Ymare',
			'Wissembourg',
			'Wandignies-Hamage',
			'Vorey',
			'Viuz-la-Chiésaz',
			'Viriville',
			'Villers-en-Cauchies',
			'Villeneuve-le-Roi',
			'Villeneuve-de-Marsan',
			'Villebon-sur-Yvette',
			'Villabé',
			'Vienne-en-Val',
			'Vieille Chapelle',
			'Vézénobres',
			'Vescovato',
			'Verfeil',
			'Vénéjan',
			'Veigy',
			'Vauvert',
			'Varzy',
			'Tuffé',
			'Trouville-sur-Mer',
			'Trélon',
			'Tramoyes',
			'Thise',
			'Thiant',
			'Thiais',
			'Thénac',
			'Tencin',
			'Teloché',
			'Taupont',
			'Talange',
			'Steene',
			'Sorède',
			'Solignac-sur-Loire',
			'Socx',
			'Sisco',
			'Septèmes-les-Vallons',
			'Semur-en-Auxois',
			'Séméac',
			'Sées',
			'Sargé-sur-Braye',
			'Sargé-lès-le-Mans',
			'Sanguinet',
			'Sancerre',
			'Sales',
			'Saint-Vérand',
			'Saint-Vaury',
			'Saint-Siméon-de-Bressieux',
			'Saint-Sauveur',
			'Saint-Saulve',
			'Saint-Romain-de-Popey',
			"Saint-Pierre-d'Aurillac",
			'Saint-Péray',
			'Saint-Pantaléon-de-Larche',
			'Saint-Nolff',
			'Saint-Même-les-Carrières',
			'Saint-Martin-sur-Oust',
			'Saint-Martin-de-la-Place',
			'Saint-Marc-le-Blanc',
			'Saint-Macaire',
			'Saint-Léger-en-Yvelines',
			'Saint-Julien-du-Sault',
			'Saint-Julien-les-Rosiers',
			'Saint-Juéry',
			'Saint-Hilaire-de-Brethmas',
			'Saint-Georges-Buttavent',
			'Saint-Gatien-des-Bois',
			'Saint-François',
			'Saint-Etienne-de-Cuines',
			'Sainte-Maure-de-Touraine',
			'Sainte-Croix-aux-Mines',
			'Saint-Donan',
			'Saint-Cloud',
			'Saint-Aubin-de-Médoc',
			'Saint-André-de-Corcy',
			'Saint-Amant-de-Boixe',
			'Sadirac',
			'Sablonceaux',
			'Sablet',
			'Rougemont',
			'Rosselange',
			'Rosières-aux-Salines',
			'Roquebillière',
			'Rivedoux-Plage',
			'Riquewihr',
			'Quincieux',
			'Quiers-sur-Bézonde',
			'Puyloubier',
			'Puy-Guillaume',
			'Prémilhat',
			'Pré-en-Pail',
			'Port-Saint-Louis-du-Rhône',
			'Portieux',
			'Pont-en-Royans',
			'Pont-Croix',
			'Plouray',
			'Plounévez-Moëdec',
			'Ploudalmézeau',
			'Le Plan-de-la-Tour',
			'Piré-sur-Seiche',
			'Pignans',
			'Peschadoires',
			'Paslières',
			'Paron',
			'Paimboeuf',
			'Paillet',
			'Oulins',
			'Orcines',
			'Orbey',
			'Oradour-sur-Vayres',
			'Obermodern-Zutzendorf',
			'Oberbronn',
			'Neuville-les-Dames',
			'Nébian',
			'Nancy',
			'Moustoir-Ac',
			'Mouilleron-le-Captif',
			'Morannes',
			'Montfaucon',
			'Monterblanc',
			'Montélier',
			'Montbéliard',
			'Mollégès',
			'Missillac',
			'Meyssac',
			'Metzervisse',
			'Merville-Franceville-Plage',
			'Mens',
			'Melay',
			'Médréac',
			'Mazières-en-Mauges',
			'Maubec',
			'Massy',
			'Marvejols',
			'Marthod',
			'Marnaz',
			'Magny-en-Vexin',
			'Ludon-Médoc',
			'Lucinges',
			'Luché-Pringé',
			'Louvroil',
			'Longperrier',
			'Loire-sur-Rhône',
			'Lisses',
			'Lingolsheim',
			'Linas',
			'Lézignan-la-Cèbe',
			'Leyment',
			'Le Taillan-Médoc',
			'Les Loges-Marchis',
			'Le Ménil',
			'Le Mans',
			'Le Folgoët',
			'Le Fleix',
			'Le Cheylard',
			"Le Cap d'Agde",
			'Le Bosc-Roger-en-Roumois',
			'Layrac',
			'La Villedieu-du-Clain',
			'La Ville-aux-Clercs',
			'Launac',
			'Larringes',
			'La Roquebrussanne',
			'Landeleau',
			'La Membrolle-sur-Choisille',
			'La Meilleraye-de-Bretagne',
			'La Meignanne',
			'La Machine',
			'Lagny-le-Sec',
			'La Ferté-Alais',
			'Ladon',
			'La Calmette',
			'Labouheyre',
			'Labégude',
			'Kingersheim',
			'Joudreville',
			'Jargeau',
			'Jallais',
			'Ille-sur-Têt',
			'Houilles',
			'Hornoy-le-Bourg',
			'Hérimoncourt',
			'Hayange',
			'Guérande',
			'Grigny',
			'Grez-sur-Loing',
			'Grand-Charmont',
			'Gouzeaucourt',
			'Goudelin',
			'Gouaix',
			'Gennes',
			'Freyming-Merlebach',
			'Fréland',
			'Fouquières-lès-Lens',
			'Fontaine-lès-Dijon',
			'Fontaine-la-Mallet',
			'Flers-en-Escrebieux',
			'Flaxlanden',
			'Flamanville',
			'Fenain',
			'Feillens',
			'Fay-de-Bretagne',
			'Favières',
			'Faverges',
			'Fameck',
			'Évron',
			'Eu',
			'Ervy-le-Châtel',
			'Erquy',
			'Épinay-sur-Seine',
			'Envermeu',
			'Ennery',
			'Écrainville',
			'Dunières',
			'Douvaine',
			'Dizy',
			'Daoulas',
			'Dampierre-en-Yvelines',
			'Cugnaux',
			'Cournonsec',
			'Couëron',
			'Coudekerque-Branche',
			'Couches',
			'Corrèze',
			'Corquilleroy',
			'Corlay',
			'Congénies',
			'Combourg',
			'Civray-de-Touraine',
			'Civens',
			'Choisy-le-Roi',
			'Chiry-Ourscamp',
			'Chilly',
			'Chevilly',
			'Chavelot',
			'Chavagne',
			'Chauché',
			'Châtonnay',
			'Champigné',
			'Champdeniers-Saint-Denis',
			'Champagne-Mouton',
			'Chamalières',
			'Chalampé',
			'Cély',
			"Castelnau-d'Estrétefonds",
			'Carentan',
			'Carcès',
			'Canon',
			'Canéjan',
			'Calonne-sur-la-Lys',
			'Caissargues',
			'Cailloux-sur-Fontaines',
			'Bures-sur-Yvette',
			'Buchelay',
			'Brétigny-sur-Orge',
			'Bressols',
			'Bresles',
			'Braine',
			'Bouzillé',
			'Bourg-en-Bresse',
			'Boulieu-lès-Annonay',
			'Bouguenais',
			'Boufféré',
			'Bois-Colombes',
			'Boé',
			'Blaye-les-Mines',
			'Blavozy',
			'Bédée',
			'Beaurevoir',
			'Beaurains',
			'Beaumont-le-Roger',
			'Barcelonne-du-Gers',
			'Balaruc-les-Bains',
			'Bagneaux-sur-Loing',
			'Aytré',
			'Availles-Limouzine',
			'Aussillon',
			'Aurignac',
			'Augny',
			'Augan',
			'Audruicq',
			'Aubervilliers',
			'Aubergenville',
			'Arpajon',
			'Arnac-Pompadour',
			'Arbouans',
			'Anzin',
			'Annequin',
			'Andrezé',
			'Allevard',
			'Aléria',
			'Alénya',
			'Aix-Noulette',
			'Aigues-Vives',
			'Acquigny',
			'Marseille 16',
			'Marseille Endoume',
			'La Pointe Rouge',
			'Le Cabot',
			'Wissous',
			'Wingersheim',
			'Waldighofen',
			'Virieu-le-Grand',
			'Vireux-Molhain',
			'Vineuil-Saint-Firmin',
			'Vincey',
			'Villiers-en-Lieu',
			'Villers-la-Montagne',
			'Villeneuve-sur-Lot',
			'Villeneuve-lès-Maguelone',
			'Villeneuve-de-la-Raho',
			'Villeneuve',
			"Villenave-d'Ornon",
			'Vigy',
			'Vignacourt',
			'Vertheuil',
			'Véron',
			'Vernosc-lès-Annonay',
			'Vermenton',
			'Vennecy',
			'Vendres',
			'Vecoux',
			'Vayrac',
			'Vaucresson',
			'Ugine',
			'Trégastel',
			'Torreilles',
			'Thouarcé',
			'Thoissey',
			'Thiverval-Grignon',
			'Thézan-lès-Béziers',
			'Tende',
			'Tarnos',
			'Stenay',
			'Soucy',
			'Solre-le-Château',
			'Solliès-Toucas',
			'Sevrey',
			'Sermérieu',
			'Seine-Port',
			'Savigny-Lévescault',
			'Saverdun',
			'Sauxillanges',
			'Sauvigny-les-Bois',
			'Saulnes',
			'Sathonay-Camp',
			'Samoëns',
			'Salses-le-Château',
			'Saïx',
			'Saint-Yorre',
			'Saint-Vincent-des-Landes',
			'Saint-Vallier-de-Thiey',
			'Saint-Romain-la-Motte',
			'Saint-Porchaire',
			'Saint-Ouen',
			'Saint-Morillon',
			'Saint-Martin-Lacaussade',
			'Saint-Martin-du-Tertre',
			'Saint-Martin-du-Tertre',
			'Saint-Martin-de-Landelles',
			'Saint-Marcel-lès-Annonay',
			'Saint-Louis',
			'Saint-Louis',
			'Saint-Laurent-du-Pape',
			'Saint-Laurent-de-la-Plaine',
			'Saint-Junien',
			'Saint-Julien-en-Saint-Alban',
			'Saint-Julien',
			'Saint-Julien',
			"Saint-Jean-d'Angély",
			'Saint-Hilaire-de-la-Côte',
			'Saint-Gervasy',
			'Saint-Étienne-du-Grès',
			'Saint-Étienne-du-Bois',
			'Corcoué-sur-Logne',
			'Sainte-Radegonde',
			'Sainte-Hélène',
			'Saint-Didier-de-la-Tour',
			'Saint-Carreuc',
			'Saint-Broladre',
			'Saint-Briac-sur-Mer',
			'Saint-Bon-Tarentaise',
			'Saint-Berain-sous-Sanvignes',
			'Saint-Aunès',
			'Saint-Armel',
			'Saint-Agnant-de-Versillat',
			'Ruaudin',
			'Roz-sur-Couesnon',
			'Roybon',
			'Routot',
			'Rosières-près-Troyes',
			'Roquevaire',
			'Ronchamp',
			'Romilly-sur-Seine',
			'Romillé',
			'Romenay',
			'Rolampont',
			'Roche-lez-Beaupré',
			'Requeil',
			'Rabastens-de-Bigorre',
			'Puteaux',
			'Pugnac',
			'Porcieu',
			'Pont-du-Château',
			'Pommerit-le-Vicomte',
			'Plouzané',
			'Ploudaniel',
			'Pléneuf-Val-André',
			'Plainfaing',
			'Pierre-Buffière',
			'Perpignan',
			'Pérignat-sur-Allier',
			'Parthenay',
			'Ouzouer-sur-Trézée',
			'Ouroux-sur-Saône',
			'Ornans',
			'Orgères',
			'Onnaing',
			'Nuits-Saint-Georges',
			'Notre-Dame-de-Monts',
			'Noizay',
			'Nieul',
			'Niederschaeffolsheim',
			'Névian',
			'Neuville-sur-Escaut',
			'Neuville-de-Poitou',
			'Nassandres',
			'Mûrs-Erigné',
			'Moulins',
			'Morosaglia',
			'Morangis',
			'Montrottier',
			'Montierchaume',
			'Monticello',
			'Montfort-le-Gesnois',
			'Montescourt-Lizerolles',
			'Monterfil',
			'Montbronn',
			'Montayral',
			'Monsempron',
			'Molières',
			'Mirefleurs',
			'Miré',
			'Mirandol-Bourgnounac',
			'Menetou-Salon',
			'Melesse',
			'Meaux',
			'Mauves',
			'Marcillac-Vallon',
			'Marcellaz-Albanais',
			'Malaucène',
			'Maintenon',
			'Magny-les-Hameaux',
			'Luzinay',
			'Luc-la-Primaube',
			'Luant',
			'Loyat',
			'Loperhet',
			'Loivre',
			'Loison-sous-Lens',
			'Loiron',
			'Livron-sur-Drôme',
			'Limeil-Brévannes',
			'Ligny-en-Cambrésis',
			'Lignières',
			'Lezay',
			'Lézat-sur-Lèze',
			'Moutiers-les-Mauxfaits',
			'Les Matelles',
			'Les Lucs-sur-Boulogne',
			'Les Landes-Genusson',
			'Les Champs-Géraux',
			'Le Pontet',
			'Le Poiré-sur-Vie',
			'Le Pin',
			'Le Minihic-sur-Rance',
			'Le Mesnil-le-Roi',
			'Le Manoir',
			'Le Cailar',
			'Le Blanc',
			'La Turballe',
			'La Salle-et-Chapelle-Aubry',
			'Larressore',
			'La Roquette-sur-Siagne',
			'Lanton',
			'Lannilis',
			'La Montagne',
			'La Meilleraie-Tillay',
			'Lamarche-sur-Saône',
			'Laillé',
			'La Ferté-sous-Jouarre',
			'La Crau',
			'La Chapelle-de-Guinchay',
			'La Bazoche-Gouet',
			'La Baconnière',
			'Jussac',
			"Jouet-sur-l'Aubois",
			'Incheville',
			'Hornaing',
			'Horgues',
			'Hondschoote',
			'Hazebrouck',
			'Gueux',
			'Guenrouet',
			'Gréasque',
			'Granville',
			'Gallargues-le-Montueux',
			'Grandfresnoy',
			'Gonesse',
			'Givet',
			'Gillonnay',
			'Gidy',
			'Gerzat',
			'Genillé',
			'Génelard',
			'Gargenville',
			'Gardanne',
			'Frotey-lès-Vesoul',
			'Fourchambault',
			'Foulayronnes',
			'Fortschwihr',
			'Fontenay-Trésigny',
			'Fontaines-sur-Saône',
			'Flayosc',
			'Firminy',
			'Faverolles-sur-Cher',
			'Étables-sur-Mer',
			'Estrées',
			'Essômes-sur-Marne',
			'Erbrée',
			'Ensisheim',
			'Larequille',
			'Dugny-sur-Meuse',
			'Dormans',
			'Don',
			'Domgermain',
			'Dombasle-sur-Meurthe',
			'Dingsheim',
			'Désertines',
			'Decize',
			'Dachstein',
			'Cugand',
			"Criquetot-l'Esneval",
			'Cours-la-Ville',
			"Cournon-d'Auvergne",
			'Coublevie',
			'Corpeau',
			'Cornil',
			'Corbelin',
			'Commequiers',
			'Comines',
			'Coëx',
			'Clairac',
			'Cholet',
			'Choisy',
			'Chavanoz',
			'Charentay',
			'Charbonnières-les-Bains',
			'Chambon-sur-Voueize',
			'Ceyzériat',
			'Ceyreste',
			'Cervera de la Marenda',
			'Cavignac',
			'Cars',
			'Carling',
			'Capestang',
			'Candillargues',
			'Cadalen',
			'Bussière-Dunoise',
			'Buis-les-Baronnies',
			'Bruyères-sur-Oise',
			'Briollay',
			'Brignoles',
			'Briec',
			'Bréauté',
			'Bouzigues',
			'Bouxwiller',
			'Bouville',
			'Bourgueil',
			'Bourg-de-Thizy',
			'Bonnemain',
			'Blotzheim',
			'Bliesbruck',
			'Beure',
			'Béthisy-Saint-Pierre',
			"Berre-l'Étang",
			'Bernières-sur-Mer',
			'Belmont-sur-Rance',
			'Belleu',
			'Belle-Isle-en-Terre',
			'Bellegarde',
			'Beauchastel',
			'Beauchamp',
			'Bazoges-en-Pareds',
			'Bazeilles',
			'Bassan',
			'Barjols',
			'Baraqueville',
			'Bandol',
			'Bailleau-le-Pin',
			'Bagnolet',
			'Azé',
			'Avord',
			'Aureilhan',
			'Aulnoye-Aymeries',
			'Audun-le-Tiche',
			'Audincourt',
			'Arzano',
			'Artigues-près-Bordeaux',
			'Argelès-Gazost',
			'Argancy',
			'Annot',
			'Anhiers',
			'Andelnans',
			'Amfreville',
			'Allauch',
			'Aigueperse',
			'Agen',
			'Abeilhan',
			'Lyon 06',
			'Marseille 13',
			'La Valbarelle',
			'Wolfgantzen',
			'Yerres',
			'Wavrin',
			'Volonne',
			'Volmerange-les-Mines',
			'Vivonne',
			'Villers-Semeuse',
			'Villeneuve-sur-Yonne',
			'Vilallonga dels Monts',
			'Villargondran',
			'Villard-de-Lans',
			'Vignoc',
			'Vieux-Charmont',
			'Vierzon',
			'Vicq-sur-Breuilh',
			'Vesoul',
			'Vendœuvres',
			'Vendat',
			'Vaucouleurs',
			'Uzein',
			'Turretot',
			'Tronville-en-Barrois',
			'Trignac',
			'Tremblay',
			'Trédarzec',
			'Tours',
			'Tourouvre',
			'Tournay',
			'Touquin',
			'Tomblaine',
			'Tignes',
			'Thiron Gardais',
			'Theix',
			'Thann',
			'Surbourg',
			'Soufflenheim',
			'Sornay',
			'Solliès-Ville',
			'Soisy-sous-Montmorency',
			'Sèvres',
			'Seugy',
			'Sérigné',
			'Sauzé-Vaussais',
			'Sautron',
			'Sarralbe',
			'Salbert',
			'Saint-Valery-en-Caux',
			'Saint-Trojan-les-Bains',
			'Saint-Souplet',
			'Saint-Sever',
			'Saint-Sébastien-de-Morsent',
			'Saint-Sauveur',
			'Saint-Saturnin-lès-Apt',
			'Saint-Renan',
			'Saint-Porquier',
			'Saint-Pierre-lès-Elbeuf',
			'Saint-Ouen-de-Thouberville',
			'Saint-Omer-en-Chaussée',
			'Saint-Nizier-sous-Charlieu',
			'Saint-Martin-Lalande',
			'Saint-Martin-Boulogne',
			'Saint-Martin-Belle-Roche',
			"Saint-Marcel-d'Ardèche",
			'Saint-Marcel-sur-Aude',
			'Saint-Lyé',
			'Saint-Lumine-de-Clisson',
			"Saint-Léon-sur-l'Isle",
			'Saint-Laurent-Nouan',
			'Saint-Laurent-Blangy',
			'Saint-Lattier',
			'Saint-Jean-de-Védas',
			'Saint-Hippolyte',
			'Saint-Hilaire-Saint-Mesmin',
			'Saint-Germain-Lespinasse',
			'Saint-Germain-Laval',
			'Saint-Germain-des-Prés',
			'Saint-Georges-de-Reintembault',
			'Saint-Étienne-au-Mont',
			'Sainte-Marie-Kerque',
			'Saint-Cyprien',
			'Saint-Berthevin',
			'Saint-Benoît-de-Carmaux',
			'Saint-Baudelle',
			'Saint-André-sur-Vieux-Jonc',
			'Saint-André-de-Sangonis',
			'Saint-André-de-la-Marche',
			'Saint-Amant-Tallende',
			'Saint-Aignan',
			'Sail-sous-Couzan',
			'Ruminghem',
			'Rouvroy',
			'Romans-sur-Isère',
			'Riotord',
			"Richebourg-l'Avoué",
			'Rémilly',
			'Réguiny',
			'Réalville',
			'Quincey',
			'Quédillac',
			'Puymoyen',
			'Puilboreau',
			'Poullaouen',
			"Pontonx-sur-l'Adour",
			'Pontcarré',
			'Pompertuzat',
			'Pomérols',
			'Plumelin',
			'Plouescat',
			'Plouarzel',
			'Plomeur',
			'Ploërmel',
			'Pleugueneuc',
			'Plancher-Bas',
			'Piney',
			'Pierrefonds',
			'Pesmes',
			'Périers',
			'Pelousey',
			'Pégomas',
			'Paimpol',
			'Orsan',
			'Ondes',
			'Odos',
			'Octeville',
			'Objat',
			'Noyal-Pontivy',
			'Nogent-sur-Seine',
			'Néris-les-Bains',
			'Nantiat',
			'Mourmelon-le-Grand',
			'Mouriès',
			'Mouilleron-en-Pareds',
			'Mouans-Sartoux',
			'Morancez',
			'Montrouge',
			'Montredon-Labessonnié',
			'Val-de-Meuse',
			'Montigny-Lencoup',
			'Montfort-sur-Meu',
			'Montfavet',
			'Montévrain',
			'Montereau-Fault-Yonne',
			'Montcuq',
			'Montboucher-sur-Jabron',
			'Montbazin',
			'Montauville',
			'Monistrol-sur-Loire',
			'Mirepoix',
			'Mirabel-aux-Baronnies',
			'Mimet',
			'Meursault',
			'Mésanger',
			'Merxheim',
			'Mercus-Garrabet',
			'Menthon-Saint-Bernard',
			'Melun',
			'Mélisey',
			'Matignon',
			'Marquefave',
			'Marboz',
			'Maclas',
			'Luzech',
			'Luzarches',
			'Luceau',
			'Louplande',
			'Longueville',
			'Longeville-en-Barrois',
			'Lomme',
			'Liverdun',
			'Limésy',
			'Vernet',
			'Le Tignet',
			'Les Églisottes-et-Chalaures',
			'Les Contamines-Montjoie',
			'Le Pian-Médoc',
			'Lent',
			'Le Neubourg',
			'Le May-sur-Èvre',
			'Le Breuil-sur-Couze',
			'Le Bourget',
			'Lavit',
			'La Varenne',
			'La Tour-sur-Orb',
			'Lardy',
			'Larçay',
			'Lampertheim',
			'Lamarque',
			'Laigneville',
			'La Grigonnais',
			'La Ferté-Gaucher',
			'La Ferté-Bernard',
			'La Fare-les-Oliviers',
			'La Dominelais',
			'La Destrousse',
			'Lacroix-Falgarde',
			'La Côte-Saint-André',
			'La Chapelle-Basse-Mer',
			'La Barthe-de-Neste',
			'Kertzfeld',
			'Bourg de Joué-sur-Erdre',
			'Jausiers',
			'Jard-sur-Mer',
			'Izon',
			'Iteuil',
			'Illange',
			'Huez',
			'Hambach',
			'Habas',
			'Guignes',
			'Guénange',
			'Griesheim-près-Molsheim',
			'Graulhet',
			'Gourin',
			'Gometz-la-Ville',
			'Giromagny',
			'Garges-lès-Gonesse',
			'Fussy',
			'Frangy',
			'Feucherolles',
			'Fermanville',
			'Fareins',
			'Évin-Malmaison',
			'Étiolles',
			'Étaples',
			'Estrablin',
			'Estaires',
			'Esquéhéries',
			'Erstein',
			'Elne',
			'Écuelles',
			'Dugny',
			'Demigny',
			'Dax',
			'Dardilly',
			'Dannemarie-sur-Crête',
			'Dampierre-les-Bois',
			'Dammarie-les-Lys',
			'Cuttoli-Corticchiato',
			'Cuinchy',
			'Crégy-lès-Meaux',
			'Coux',
			'Coulans-sur-Gée',
			'Cordemais',
			'Corbie',
			'Condom',
			'Cluses',
			'Clohars-Fouesnant',
			'Clérieux',
			'Claville',
			'Claret',
			'Clairvaux-les-Lacs',
			'Chitenay',
			'Chirac',
			'Chilly-Mazarin',
			'Cherisy',
			'Chéreng',
			'Chaville',
			'Châteauneuf-sur-Loire',
			'Châteauneuf-la-Forêt',
			'Chanteloup-les-Vignes',
			'Chanteau',
			'Chailly-en-Brie',
			'Cerisy-la-Salle',
			'Celles-sur-Durolle',
			'Castillonnès',
			'Cappelle-la-Grande',
			'Cangey',
			'Campbon',
			'Calvisson',
			'Caluire-et-Cuire',
			'Cahors',
			'Cabris',
			'Cabestany',
			'Bussy-Saint-Georges',
			'Bussière-Poitevine',
			'Bucy-le-Long',
			'Brezolles',
			'Boutigny-sur-Essonne',
			'Bousbecque',
			'Bourron-Marlotte',
			'Bourgneuf-en-Retz',
			'Bourg-la-Reine',
			'Bourgbarré',
			'Boissy-le-Cutté',
			'Blauzac',
			'Bizanos',
			'Bischwiller',
			'Billy-sur-Aisne',
			'Biesles',
			'Bezouce',
			'Béziers',
			'Béville-le-Comte',
			'Bettancourt-la-Ferrée',
			'Berné',
			'Belleville-sur-Vie',
			'Belleneuve',
			'Béganne',
			'Bécon-les-Granits',
			'Beauvoir-sur-Niort',
			'Baud',
			'Ballon',
			'Auzebosc',
			'Aubigny-au-Bac',
			'Aubignan',
			'Attainville',
			'Ars-sur-Formans',
			'Arnèke',
			'Annecy',
			'Amilly',
			'Amilly',
			'Amiens',
			'Ambarès-et-Lagrave',
			'Allinges',
			'Alleins',
			'Albens',
			'Ainay-le-Château',
			'Abreschviller',
			"L'Île-d'Yeu",
			'Saint-Mauront',
			'Wettolsheim',
			'Vulaines-sur-Seine',
			'Viry',
			'Vincennes',
			'Villetelle',
			'Villers-Bocage',
			'Villeneuve-de-Rivière',
			'Villefranche-de-Lauragais',
			'Villecresnes',
			'Vic-en-Bigorre',
			'Verzy',
			'Vernaison',
			'Varreddes',
			'Trun',
			'Triel-sur-Seine',
			'Tours-sur-Marne',
			'Teyran',
			'Survilliers',
			'Sucé-sur-Erdre',
			'Soyons',
			'Sourdun',
			'Soucelles',
			'Sonchamp',
			'Sévérac-le-Château',
			'Sérézin-du-Rhône',
			'Seltz',
			'Savigneux',
			'Sault-lès-Rethel',
			'Saubrigues',
			'Sarrians',
			'Sancey-le-Grand',
			'Salles',
			'Saleux',
			'Saint-Victor-de-Cessieu',
			'Saint-Simon',
			'Saint-Savin',
			'Saint-Saturnin',
			'Saint-Romain-en-Gal',
			'Saint-Priest-en-Jarez',
			'Saint-Pierre-en-Val',
			'Saint-Pierre-des-Fleurs',
			'Saint-Médard-de-Guizières',
			'Saint-Maurice-sur-Moselle',
			'Saint-Maurice-de-Beynost',
			'Saint-Martin-Vésubie',
			"Saint-Martin-d'Auxigny",
			'Saint-Mamert-du-Gard',
			'Saint-Louis-de-Montferrand',
			'Saint-Just',
			'Saint-Jean-sur-Mayenne',
			'Saint-Jean-les-Deux-Jumeaux',
			'Saint-Jean-de-Maurienne',
			'Saint-Gratien',
			'Saint-Géréon',
			'Saint-Georges-de-Commiers',
			'Saint-Geniès-de-Comolas',
			'Saint-Geniès-Bellevue',
			'Saint-Galmier',
			'Saint-Fons',
			'Saint-Florent-des-Bois',
			'Sainte-Suzanne',
			'Sainte-Jamme-sur-Sarthe',
			'Sainte-Hermine',
			'Sainte-Foy-lès-Lyon',
			'Saint-Doulchard',
			'Saint-Didier-de-Formans',
			'Saint-Cyr',
			'Saint-Coulomb',
			'Saint-Contest',
			'Saint-Claude-de-Diray',
			'Saint-Caprais-de-Bordeaux',
			'Saint-Benoît',
			'Saint-Bauzille-de-Putois',
			'Sailly-lez-Lannoy',
			'Rubelles',
			'Rozier-en-Donzy',
			'Rouillé',
			'Rolleville',
			'Riom-ès-Montagnes',
			'Rilly-la-Montagne',
			'Riedisheim',
			'Richardménil',
			'Ribeauvillé',
			'Rezé',
			'Revel',
			'Reignier-Ésery',
			'Rambervillers',
			'Privas',
			'Portel-des-Corbières',
			'Ponchon',
			'Pluherlin',
			'Plounévez-Lochrist',
			'Ploubazlanec',
			'Pleudihen-sur-Rance',
			'Pissotte',
			'Pirey',
			'Pfulgriesheim',
			'Petit-Noir',
			'Pessac',
			'Peaugres',
			'Panissières',
			'Ozouer-le-Voulgis',
			'Ormes',
			'Orcet',
			'Onesse-Laharie',
			'Olivet',
			'Notre-Dame-de-Bondeville',
			'Nolay',
			'Nogent-sur-Oise',
			'Noaillan',
			'Nexon',
			'Neuvy-sur-Loire',
			'Neulliac',
			'Neufchef',
			'Navenne',
			'Mur-de-Sologne',
			'Moye',
			'Mouen',
			'Montréjeau',
			'Montmartin-sur-Mer',
			'Montlivault',
			'Montgermont',
			'Montferrand-le-Château',
			'Montchanin',
			'Montaigu-de-Quercy',
			'Monswiller',
			'Moncoutant',
			'Molac',
			'Mogneville',
			'Milly-la-Forêt',
			'Mignaloux-Beauvoir',
			'Mèze',
			'Messein',
			'Merville',
			'Mazingarbe',
			'Maxilly-sur-Léman',
			'Mauvezin',
			'Masnières',
			'Marseille',
			'Mallemoisson',
			'Lyon',
			'Louvres',
			'Lourdes',
			'Lorris',
			'Locminé',
			'Livré-sur-Changeon',
			'Ligné',
			'Licques',
			'Le Verdon-sur-Mer',
			'Le Theil-Bocage',
			'Le Teil',
			'Lesquin',
			'Les Forges',
			'Les Alluets-le-Roi',
			'Le Puy-en-Velay',
			'Port-des-Barques',
			'Léhon',
			'Ledeuix',
			'Lectoure',
			'Le Buisson-de-Cadouin',
			'Le Bignon',
			'Laurens',
			'Lasseube',
			"Laroque-d'Olmes",
			'La Plaine-sur-Mer',
			'Landunvez',
			'Landudec',
			'Lançon-Provence',
			'La Motte-Servolex',
			'La Motte-Saint-Martin',
			'La Monnerie-le-Montel',
			'Lambersart',
			'La Châtaigneraie',
			'Lachapelle-sous-Aubenas',
			'La Chapelle-Saint-Florent',
			'La Canourgue',
			'Labruguière',
			'La Bazoge',
			'Kuntzig',
			'Kœnigsmacker',
			'Keskastel',
			'Kerlouan',
			'Kaltenhouse',
			'Juziers',
			'Jouy-sur-Morin',
			'Jeumont',
			'Jaux',
			'Janzé',
			'Janville',
			'Itteville',
			'Itancourt',
			'Isigny-le-Buat',
			'Ingrandes',
			'Iffendic',
			'Houplines',
			'Hordain',
			'Hinx',
			'Hilsenheim',
			'Hénanbihen',
			'Hédé-Bazouges',
			'Hanches',
			'Hadol',
			'Guerville',
			'Guémené-sur-Scorff',
			'Grignon',
			"Grenade-sur-l'Adour",
			'Grand-Champ',
			'Gouarec',
			'Goncelin',
			'Givenchy-en-Gohelle',
			'Ghyvelde',
			'Gespunsart',
			'Génissac',
			'Geispolsheim',
			'Frans',
			'Foussais-Payré',
			'Fouquereuil',
			'Formerie',
			'Floirac',
			'Flavigny-sur-Moselle',
			'Ferques',
			'Fauville-en-Caux',
			'Faumont',
			'Farnay',
			'Fabrègues',
			'Étrembières',
			'Étampes',
			'Estrées-Saint-Denis',
			'Épron',
			'Épinouze',
			'Épinay-sous-Sénart',
			'Épinal',
			'Embrun',
			'Einville-au-Jard',
			'Échirolles',
			'Échenoz-la-Méline',
			'Doulaincourt-Saucourt',
			'Dinard',
			'Dieuze',
			'Cucq',
			'Créon',
			'Cransac',
			'Couvron-et-Aumencourt',
			'Corneilla-del-Vercol',
			'Cornas',
			'Conches-en-Ouche',
			'Combaillaux',
			'Colombelles',
			'Clichy',
			'Ciboure',
			'Chierry',
			'Chennevières-sur-Marne',
			'Chéméré',
			'Châtenois',
			'Châtenay-sur-Seine',
			'Châteaumeillant',
			'Charentilly',
			'Chaponost',
			'Changé',
			'Champs-sur-Marne',
			'Chamigny',
			'Chamarandes-Choignes',
			'Cernay-la-Ville',
			'Cassis',
			'Caro',
			'Bouvigny-Boyeffles',
			'Boulogne-Billancourt',
			'Bonneveine',
			'Bonifacio',
			'Bobigny',
			'Blangy-sur-Bresle',
			'Blancafort',
			'Bitche',
			'Biguglia',
			'Biganos',
			'Bezons',
			'Beynost',
			'Beuville',
			'Bessan',
			'Bernay',
			'Bergerac',
			'Beausemblant',
			'Beaujeu',
			'Batilly',
			'Indre',
			'Bas-en-Basset',
			'Barr',
			'Bar-le-Duc',
			'Bannalec',
			'Baisieux',
			'Bailleul-sur-Thérain',
			'Badonviller',
			'Baccarat',
			'Azay-le-Ferron',
			'Aydoilles',
			'Aveize',
			'Auzouer-en-Touraine',
			'Ault',
			'Attignat',
			'Aschères-le-Marché',
			'Artemare',
			'Argent-sur-Sauldre',
			'Apt',
			'Andance',
			'Allonne',
			'Alissas',
			'Aillevillers-et-Lyaumont',
			'Abscon',
			'Belle-Plagne',
			'Marseille 09',
			'Lodi',
			'Yzeure',
			'Xertigny',
			'Wittisheim',
			'Volgelsheim',
			'Viuz-en-Sallaz',
			'Vireux-Wallerand',
			'Villié-Morgon',
			'Villette-de-Vienne',
			'Villersexel',
			'Viesly',
			'Vichy',
			'Verzenay',
			'Vernou-la-Celle-sur-Seine',
			'Vendargues',
			'Vayres',
			'Vassy',
			'Valloire',
			'Valencin',
			'Vacquiers',
			'Uzerche',
			'Uchaux',
			'Trouy',
			'Troissereux',
			'Tresserve',
			'Tressange',
			'Trappes',
			'Tollevast',
			'Figari',
			'Thoiry',
			'Stotzheim',
			'Steenbecque',
			'Staffelfelden',
			'Souesmes',
			'Solignac',
			'Soissons',
			'Sillery',
			'Sierentz',
			'Sibiril',
			'Serrières',
			'Schwindratzheim',
			'Schiltigheim',
			'Scherwiller',
			'Sauveterre-de-Guyenne',
			'Sausheim',
			'Saubion',
			'Sare',
			'Sarbazan',
			'Sandillon',
			'Sand',
			'Salles-la-Source',
			'Saint-Symphorien-de-Lay',
			'Saint-Symphorien',
			'Saint-Sauveur-Lendelin',
			"Saint-Sauveur-d'Aunis",
			'Saint-Saturnin-lès-Avignon',
			'Saint-Rémy-sur-Avre',
			'Saint-Paul-Trois-Châteaux',
			'Saint-Paul-de-Vence',
			'Saint-Ouen-en-Belin',
			'Saint-Ouën-des-Toits',
			'Saint-Mihiel',
			'Saint-Michel-sur-Meurthe',
			"Saint-Michel-l'Observatoire",
			'Saint-Martin-du-Mont',
			"Saint-Mars-d'Outillé",
			'Saint-Mard',
			'Saint-Marcel',
			'Saint-Manvieu',
			'Saint-Malo-de-Guersac',
			'Saint-Loup-Cammas',
			'Saint-Lizier',
			'Saint-Leu-la-Forêt',
			'Saint-Lambert-du-Lattay',
			'Saint-Just-en-Chevalet',
			'Saint-Jean-du-Falga',
			'Saint-Jean-de-Sixt',
			'Saint-Jean-de-Luz',
			'Saint-Georges-sur-Baulche',
			'Saint-Florent-sur-Cher',
			'Saint-Étienne-du-Rouvray',
			'Sainte-Mère-Église',
			'Sainte-Gemmes-sur-Loire',
			'Sainte-Catherine',
			'Saint-Cyr-en-Val',
			'Saint-Clément',
			'Saint-Brandan',
			'Saint-Barnabé',
			'Saint-Amand-sur-Sèvre',
			'Sain-Bel',
			'Sailly-Labourse',
			'Sacy-le-Grand',
			'Ruitz',
			'Roussillon',
			'Rosenau',
			'Roquettes',
			'Romanswiller',
			'Romainville',
			'Roanne',
			'Riscle',
			'Retiers',
			'Régny',
			'Raillencourt-Sainte-Olle',
			'Quinsac',
			'Querqueville',
			'Quéménéven',
			'Pusey',
			'Presles-en-Brie',
			'Prats de Molló',
			'Pourrières',
			'Pouilly-les-Nonains',
			'Pouilly-en-Auxois',
			'Portes-lès-Valence',
			'Portbail',
			'Pont-Scorff',
			'Pontorson',
			'Pont-Écrepin',
			'Pont-de-Metz',
			'Pommerit-Jaudy',
			'Plouhinec',
			'Plobsheim',
			'Pleyber-Christ',
			'Plélo',
			'Plaudren',
			'Plappeville',
			'Piolenc',
			'Pierrelaye',
			'Pfaffenheim',
			'Peujard',
			'Pérenchies',
			'Paimpont',
			'Orsay',
			'Orchamps',
			'Oberhergheim',
			'Nouvion',
			'Neussargues',
			'Nazelles-Négron',
			'Navailles-Angos',
			'Mouzon',
			'Mouxy',
			'Moulins-lès-Metz',
			'Mouliets-et-Villemartin',
			'Mormoiron',
			'Mont-sous-Vaudrey',
			'Montmeyran',
			'Montmacq',
			'Miribel',
			'Meudon',
			'Marsanne',
			'Marle',
			'Malicorne-sur-Sarthe',
			'Maisons-Laffitte',
			'Mainvilliers',
			'Lutzelhouse',
			'Louvigné-du-Désert',
			'Loudéac',
			'Llupia',
			'Livry-Gargan',
			"L'Isle-Jourdain",
			"L'Isle-Adam",
			'Libercourt',
			'Lezennes',
			'Lévignac',
			'Levet',
			'Leval',
			"L'Étrat",
			'Le Tholonet',
			'Les Gets',
			'Les Aygalades',
			'Le Quesnoy',
			'Le Mesnil-en-Vallée',
			'Le Mesnil-en-Thelle',
			'Lemberg',
			'Le Longeron',
			'Le Fenouiller',
			'Le Faou',
			'Le Canet',
			"Le Bourg-d'Oisans",
			'La Souterraine',
			'La Roche-Posay',
			'Larmor-Plage',
			'La Ménitré',
			'La Meauffe',
			'Lagrave',
			'La Chapelle-Saint-Aubin',
			'Lacasse',
			'Labourse',
			'La Bonneville-sur-Iton',
			'La Bâtie-Montgascon',
			'Krautergersheim',
			'Kaysersberg',
			'Juvignac',
			'Jaunay-Clan',
			'Irodouër',
			'Honfleur',
			'Hirtzbach',
			'Hermes',
			'Hauteville-Lompnes',
			'Hames-Boucres',
			'Hallines',
			'Gujan-Mestras',
			'Guissény',
			'Groffliers',
			'Grigny',
			'Grignan',
			'Gratentour',
			'Gironde-sur-Dropt',
			'Giraumont',
			'Gattières',
			'Gandrange',
			'Gagnac-sur-Garonne',
			'Frossay',
			'Fosses',
			'Forbach',
			'Fontenay',
			'Fonbeauzard',
			'Flogny-la-Chapelle',
			'Feurs',
			'Férolles-Attilly',
			'Felletin',
			'Fécamp',
			'Évrecy',
			'Étival-lès-le-Mans',
			'Étel',
			'Espelette',
			'Eschentzwiller',
			'Eppeville',
			'Épône',
			'Ennery',
			'Elven',
			'Draguignan',
			'Dracy-le-Fort',
			'Divonne-les-Bains',
			'Diebling',
			'Dampierre-sur-Salon',
			'Creys-Mépieu',
			'Coutouvre',
			'Couilly-Pont-aux-Dames',
			'Cotignac',
			'Corbeilles',
			'Conflans-Sainte-Honorine',
			'Collégien',
			'Cluis',
			'Claix',
			'Varennes-Vauzelles',
			'Cheptainville',
			'Cheniménil',
			'Chemillé-Melay',
			'Chatuzange-le-Goubet',
			'Châtres-sur-Cher',
			'Châteauneuf-sur-Cher',
			'Chartres',
			'Charron',
			'Charleville-Mézières',
			'Chaponnay',
			'Champtocé-sur-Loire',
			'Champlitte-la-Ville',
			'Champagnac',
			'Challans',
			'Chalais',
			'Cerizay',
			'Celles-sur-Belle',
			'Ceaucé',
			'Caussade',
			'Caudebec-en-Caux',
			'Cantaron',
			'Camors',
			'Bretenoux',
			'Brens',
			'Boussay',
			'Bousies',
			'Bouilly',
			'Borgo',
			'Bonny-sur-Loire',
			'Boissy-Saint-Léger',
			'Boisgervilly',
			'Blagny',
			'Biviers',
			'Béthisy-Saint-Martin',
			'Bessèges',
			'Bélesta',
			'Bavilliers',
			'Baume-les-Dames',
			'Barneville-Plage',
			'Bardos',
			'Baden',
			'Avessac',
			'Auriac-sur-Vendinelle',
			'Auneuil',
			'Aumale',
			'Auchy-lès-Hesdin',
			'Aubusson',
			'Aubevoye',
			'Attigny',
			'Assat',
			'Aspach-le-Bas',
			'Asnières-sur-Oise',
			'Ars-en-Ré',
			'Arrou',
			'Arques',
			'Arenc',
			'Aniane',
			"Angerville-l'Orcher",
			'Amancy',
			'Alzonne',
			'Alsting',
			'Allières-et-Risset',
			'Albias',
			'Abondant',
			"Lugon-et-l'Île-du-Carnay",
			"Villeneuve-d'Ascq",
			'Carnoux-en-Provence',
			'Saint-Lazare',
			'Zegerscappel',
			'Warmeriville',
			'Vouvray',
			'Voutezac',
			'Vourey',
			'Voreppe',
			'Vizille',
			'Villiers-le-Bel',
			'Villemoustaussou',
			'Vignoux-sur-Barangeon',
			'Vic-sur-Seille',
			'Viarmes',
			'Verquin',
			'Verneuil-sur-Seine',
			'Ventiseri',
			'Vendegies-sur-Écaillon',
			'Vals-près-le-Puy',
			'Valréas',
			'Valdoie',
			'Tullins',
			'Trith-Saint-Léger',
			'Trangé',
			'Tillé',
			'Tercis-les-Bains',
			'Telgruc-sur-Mer',
			'Talmas',
			'Suze-la-Rousse',
			'Sumène',
			'Souchez',
			'Sorgues',
			'Solesmes',
			'Sevran',
			'Séreilhac',
			'Sens-de-Bretagne',
			'Sennecey-lès-Dijon',
			'Seboncourt',
			'Savigny-sur-Orge',
			'Sauviat-sur-Vige',
			'Sartène',
			'Santes',
			'Sannerville',
			'Samoreau',
			'Salins',
			'Saligny',
			'Salies-de-Béarn',
			'Saint-Xandre',
			'Saint-Wandrille-Rançon',
			'Saint-Vallier',
			'Saint-Urbain',
			'Saint-Tropez',
			'Saint-Soupplets',
			'Saint-Sernin',
			'Saint-Rambert-en-Bugey',
			'Saint-Privat-des-Vieux',
			'Saint-Perdon',
			'Saint-Paterne-Racan',
			'Saint-Ouen',
			'Saint-Nazaire',
			'Saint-Martin-du-Manoir',
			'Saint-Marcel',
			'Saint-Lys',
			'Saint-Léger-sous-Cholet',
			'Saint-Laurent-sur-Sèvre',
			'Saint-Jorioz',
			'Saint-Jean-Bonnefonds',
			'Saint-Grégoire',
			'Saint-Gervais',
			'Saint-Germer-de-Fly',
			'Saint-Gérand-le-Puy',
			'Saint-Georges-Haute-Ville',
			'Saint-Genis-les-Ollières',
			'Saint-Fiacre-sur-Maine',
			'Sainte-Soulle',
			'Saintes-Maries-de-la-Mer',
			'Sainte-Reine-de-Bretagne',
			'Sainte-Geneviève-sur-Argence',
			'Sainte-Foy-la-Grande',
			'Sainte-Consorce',
			'Saint-Dolay',
			'Saint-Denis-en-Bugey',
			'Saint-Cyprien-Plage',
			'Saint-Chaptes',
			'Saint-Brice-en-Coglès',
			'Saint-Ay',
			'Saint-Aubin',
			'Saint-Apollinaire',
			'Saint-André-le-Puy',
			'Saint-André-de-Seignanx',
			'Saint-Alban-sur-Limagnole',
			'Rumilly-en-Cambrésis',
			'Rostrenen',
			'Roscoff',
			'Rombas',
			'Roiffieux',
			'Rochegude',
			'Rocbaron',
			'Ris-Orangis',
			'Rioz',
			'Quincampoix',
			'Publier',
			'Prouvy',
			'Preignan',
			'Pouilley-les-Vignes',
			'Porspoder',
			"Pont-l'Abbé",
			'Pontchâteau',
			'Podensac',
			'Plumaugat',
			'Plouvorn',
			'Plouguerneau',
			'Pleine-Fougères',
			'Plabennec',
			'Pissy-Pôville',
			'Pietrosella',
			'Pernes',
			'Paulhaguet',
			'Pau',
			'Pasly',
			'Oucques',
			'Orly',
			'Noyant',
			'Nomeny',
			'Noisy-le-Sec',
			'Nohic',
			'Nogent-sur-Marne',
			'Neuville-sur-Saône',
			'Nérondes',
			'Nanteuil',
			'Murat',
			'Muides-sur-Loire',
			'Moyrazès',
			'Moyeuvre-Grande',
			'Moyenmoutier',
			'Morlaix',
			'Morancé',
			'Montreux-Château',
			'Montoire-sur-le-Loir',
			'Montferrier-sur-Lez',
			'Montbert',
			'Mondeville',
			'Monclar-de-Quercy',
			'Meyreuil',
			'Meyenheim',
			'Meurchin',
			'Messigny-et-Vantoux',
			'Mervans',
			'Mauves-sur-Loire',
			'Maubeuge',
			'Mathieu',
			'Matha',
			'Martizay',
			'Martigné-Ferchaud',
			'Marquion',
			'Marquette-lez-Lille',
			'Marœuil',
			'Marnay',
			'Marcilly-en-Villette',
			'Marcey-les-Grèves',
			'Mane',
			'Lure',
			'Lubersac',
			'Loctudy',
			'Linselles',
			'Lignan-sur-Orb',
			'Les Pieux',
			'Les Marches',
			'Le Rheu',
			'Le Langon',
			'Léguevin',
			'Le Faouët',
			'Lay-Saint-Christophe',
			'Lauzerte',
			'La Trinité',
			'Laroque-Timbaut',
			'La Planche',
			'Langonnet',
			'La Lande-Patry',
			'Laigné-en-Belin',
			'La Grand-Croix',
			'La Garnache',
			'La Charité-sur-Loire',
			'La Cavalerie',
			'Joncherey',
			'Jegun',
			'Jarrie',
			'Inguiniel',
			'Houlgate',
			'Houlbec-Cocherel',
			'Hombourg-Haut',
			'Herzeele',
			'Hérin',
			'Hergnies',
			'Herbitzheim',
			'Hennebont',
			'Helfaut',
			'Haybes',
			'Halluin',
			'Gumbrechtshoffen',
			'Guiscriff',
			'Grendelbruch',
			'Gondecourt',
			'Giroussens',
			'Gex',
			'Gévezé',
			'Garnay',
			'Fretin',
			'Frépillon',
			'Fort-Mahon-Plage',
			'Fontenay-lès-Briis',
			'Fontanil-Cornillon',
			'Fegersheim',
			'Farschviller',
			'Étaules',
			'Étain',
			'Esquelbecq',
			'Escalquens',
			'Épernon',
			'Entzheim',
			'Ecques',
			'Dury',
			'Dornes',
			'Deuil-la-Barre',
			'Deauville',
			'Damparis',
			'Cuxac-Cabardès',
			'Criel-sur-Mer',
			'Creutzwald',
			'Crest',
			'Crédin',
			'Courtry',
			'Courbevoie',
			'Connaux',
			'Condé-sur-Huisne',
			'Cognac',
			'Cléré-les-Pins',
			'Clapiers',
			'Chaulnes',
			'Châtillon-sur-Marne',
			'Château-Renault',
			'Charleval',
			'Charavines',
			'Changy-les-Bois',
			'Chailly-en-Bière',
			'Chailland',
			'Cézy',
			'Cercoux',
			'Cazouls-lès-Béziers',
			'Caulnes',
			'Castelnau-Montratier',
			'Carvin',
			'Capdenac',
			'Calenzana',
			'Cagny',
			'Buellas',
			'Buchy',
			'Buc',
			'Bruz',
			'Bruyères',
			'Bron',
			'Breteuil',
			'Bourguébus',
			'Bourghelles',
			'Bourcefranc-le-Chapus',
			'Bordes',
			'Bonnieux',
			'Bonneuil-sur-Marne',
			'Bondues',
			'Boiscommun',
			'Boën-sur-Lignon',
			'Blodelsheim',
			'Bethoncourt',
			'Berre-les-Alpes',
			'Belloy-en-France',
			'Belberaud',
			'Bédarieux',
			'Beauvoir-sur-Mer',
			'Beauvallon',
			'Beautiran',
			'Beaumont-sur-Oise',
			'Beaufay',
			'Bazas',
			'Bar-sur-Seine',
			'Barentin',
			'Auterive',
			'Antony',
			'Allennes-les-Marais',
			'Lyon 08',
			'Val-de-Reuil',
			'Marseille Vauban',
			'Saint-Charles',
			'Les Chartreux',
			'Yvrac',
			'Woincourt',
			'Wingles',
			'Walscheid',
			'Voiron',
			'Void-Vacon',
			'Vineuil',
			'Vimoutiers',
			'Villers-Bretonneux',
			'Villereversure',
			'Villegailhenc',
			'Villaz',
			'Villaudric',
			'Villaines-la-Juhel',
			'Vieille-Église',
			'Vic-la-Gardiole',
			'Veynes',
			'Vélizy-Villacoublay',
			'Vaumoise',
			'Vaulx-en-Velin',
			'Vatan',
			'Valliquerville',
			'Valleraugue',
			'Valbonne',
			'Urzy',
			'Ungersheim',
			'Trosly-Breuil',
			'Tremblay-en-France',
			'Treffléan',
			'Tracy-le-Mont',
			'Toussieu',
			'Torfou',
			'Tonnay-Boutonne',
			'Thénezay',
			'Theizé',
			'Tergnier',
			'Taissy',
			'Solesmes',
			'Sète',
			'Seloncourt',
			'Scaër',
			'Sanary-sur-Mer',
			'Samatan',
			'Salbris',
			'Saint-Witz',
			'Saint-Urbain',
			'Saint-Sauveur',
			'Saint-Quay-Portrieux',
			'Saint-Prest',
			'Saint-Pol-de-Léon',
			'Saint-Planchers',
			'Saint-Paul-de-Jarrat',
			'Saint-Molf',
			'Saint-Michel',
			'Saint-Mexant',
			'Saint-Maurice',
			'Saint-Mathieu',
			'Saint-Martin-de-Seignanx',
			'Saint-Martin-des-Champs',
			'Saint-Mars-la-Jaille',
			'Saint-Marcel-lès-Sauzet',
			'Saint-Julien',
			'Saint-Joachim',
			'Saint-Hilaire-de-Riez',
			'Saint-Gilles',
			'Saint-Germain-sur-Morin',
			'Saint-Germain-de-Lusignan',
			'Saint-Georges-des-Coteaux',
			'Saint-Geniès-de-Fontedit',
			'Saint-Gaultier',
			'Sainte-Geneviève-des-Bois',
			'Sainte-Foy',
			'Saint-Cyr-sous-Dourdan',
			'Royan',
			'Rouxmesnil-Bouteilles',
			'Rognes',
			'Rillieux-la-Pape',
			'Rignac',
			'Rieupeyroux',
			'Riantec',
			'Rethel',
			'Redessan',
			'Raismes',
			'Puyoô',
			'Pujols',
			'Puissalicon',
			'Préseau',
			'Pouilly-sous-Charlieu',
			'Port-sur-Saône',
			'Port-La Nouvelle',
			'Pont-de-Vaux',
			'Ploumilliau',
			'Plouguernével',
			'Pléguien',
			'Plaine-Haute',
			'Pierres',
			'Petiville',
			'Petit-Mars',
			'Perthes',
			'Pencran',
			'Payns',
			'Pavilly',
			'Parempuyre',
			'Ottmarsheim',
			'Ollioules',
			'Nyoiseau',
			'Nouan-le-Fuzelier',
			"Noirmoutier-en-l'Île",
			'Nilvange',
			'Nieul-lès-Saintes',
			'Neuilly-Plaisance',
			'Mozé-sur-Louet',
			'Mozac',
			'Mortain',
			'Morlaàs',
			'Montpellier',
			'Montmorillon',
			'Montmédy',
			'Montmagny',
			'Montlignon',
			'Montguyon',
			'Montesson',
			'Montbizot',
			'Montaren-et-Saint-Médiers',
			'Moigny-sur-École',
			'Miserey-Salines',
			'Miramont-de-Guyenne',
			'Mionnay',
			'Mieussy',
			'Meucon',
			'Menton',
			'Mauzé-sur-le-Mignon',
			'Matour',
			'Marines',
			'Mareau-aux-Prés',
			'Marck',
			'Mansigné',
			'Maillane',
			'Maidières',
			'Luynes',
			'Luc-sur-Mer',
			'Luçon',
			'Lormaison',
			'Longeville-lès-Saint-Avold',
			'Longeville-lès-Metz',
			'Lizy-sur-Ourcq',
			'Ligré',
			'Le Trévoux',
			'Le Touvet',
			'Les Sorinières',
			'Les Fins',
			"Les Aix-d'Angillon",
			'Le Rove',
			'Le Puy-Sainte-Réparade',
			'Le Pouliguen',
			'Le Plessis-Robinson',
			'Le Garric',
			'Le Chambon-Feugerolles',
			'La Verpillière',
			'La Selle-la-Forge',
			'La Roche-Blanche',
			'La Rivière-Saint-Sauveur',
			'Lanquetot',
			'Lanouaille',
			'Lannemezan',
			'Laloubère',
			'La Lande-de-Fronsac',
			'La Houssaye-en-Brie',
			'Lagny-sur-Marne',
			'La Farlède',
			'La Couronne',
			'La Celle-Saint-Avant',
			'Labatut',
			'Jouars-Pontchartrain',
			'Jacob-Bellecombette',
			'Issy-les-Moulineaux',
			'Hurigny',
			'Hundling',
			'Hésingue',
			'Hambye',
			'Haisnes',
			'Haillicourt',
			'Gy',
			'Gruffy',
			'Grenoble',
			'Gravelines',
			'Goven',
			'Goult',
			'Goudargues',
			'Gondreville',
			'Gometz-le-Châtel',
			'Godewaersvelde',
			'Gerbéviller',
			'Genas',
			'Gauriaguet',
			'Garidech',
			'Gaillon',
			'Froncles',
			'Froidfond',
			'Frignicourt',
			'Fournes-en-Weppes',
			'Folschviller',
			'Finhan',
			'Félines',
			'Fay-aux-Loges',
			'Exireuil',
			'Etaux',
			'Ernolsheim-Bruche',
			'Éragny',
			'Émerainville',
			'Ducey',
			'Doncourt-lès-Conflans',
			'Domène',
			'Dingy-Saint-Clair',
			'Dietwiller',
			'Dessenheim',
			'Denguin',
			'Dauendorf',
			'Dammarie',
			'Dallet',
			'Dabo',
			'Curgy',
			'Cuffy',
			'Creissan',
			'Courtenay',
			'Coursac',
			'Courchelettes',
			'Coufouleux',
			'Cosnac',
			'Cormery',
			'Cordes-sur-Ciel',
			'Corbehem',
			'Coray',
			'Conques-sur-Orbiel',
			'Connantre',
			'Confolens',
			'Condrieu',
			'Clermont',
			'Clécy',
			'Cinq-Mars-la-Pile',
			'Chuzelles',
			'Chomérac',
			'Chinon',
			'Cheillé',
			'Chef-Boutonne',
			'Châteauneuf-de-Galaure',
			'Châteaugay',
			'Chasselay',
			'Chaniers',
			'Chambœuf',
			'Cernay-lès-Reims',
			'Cenon',
			'Cénac',
			'Celle-Lévescault',
			'Cazilhac',
			'Cartignies',
			'Capendu',
			'Cannes-Écluse',
			'Camblain-Châtelain',
			'Bulgnéville',
			'Bruille-Saint-Amand',
			'Brie-Comte-Robert',
			'Bréhal',
			'Brains',
			'Bouzy-la-Forêt',
			'Bouxières-aux-Dames',
			'Bournezeau',
			'Bourg-Argental',
			'Bourbonne-les-Bains',
			'Bougival',
			'Bosc-le-Hard',
			'Blamont',
			'Bertrange',
			'Belleville',
			'Belbeuf',
			'Beauzelle',
			'Beaumont-sur-Sarthe',
			'Bazemont',
			'Balzac',
			'Ballan-Miré',
			'Bailly',
			'Azay-le-Rideau',
			'Avèze',
			'Aveizieux',
			'Auribeau-sur-Siagne',
			'Aurec-sur-Loire',
			'Aunay-sous-Auneau',
			'Aulnat',
			'Auchy-les-Mines',
			'Athée-sur-Cher',
			'Aspach',
			'Argenton-les-Vallées',
			'Apprieu',
			'Angoulins',
			'Anetz',
			'Andrézieux-Bouthéon',
			'Andouillé',
			'Andeville',
			'Ancerville',
			'Ammerschwihr',
			'Ambert',
			'Ambérieux-en-Dombes',
			'Aiguefonde',
			'Aigre',
			'Aigné',
			'Marseille 01',
			'Marseille 07',
			'Castellane',
			'Menpenti',
			'Ytrac',
			'Wolfisheim',
			'Waziers',
			'Wattrelos',
			'Vouzon',
			'Vitry-sur-Orne',
			'Violaines',
			'Vineuil',
			'Villiers-sur-Orge',
			'Villiers-Saint-Fréderic',
			'Villetaneuse',
			'Villebois',
			'Vic-sur-Aisne',
			'Veyre-Monton',
			'Vendrennes',
			'Varennes-sur-Allier',
			'Valros',
			'Valmondois',
			'Valleiry',
			'Valergues',
			'Uzemain',
			'Usson-en-Forez',
			'Trilport',
			'Trémentines',
			'Trélévern',
			'Trébeurden',
			'Toul',
			'Tiercé',
			'Thun-Saint-Amand',
			'Thouaré-sur-Loire',
			'Thônes',
			'Thizy-les-Bourgs',
			'Teting-sur-Nied',
			'Tavers',
			'Tabanac',
			'Sury-le-Comtal',
			'Spicheren',
			'Servon-sur-Vilaine',
			'Serqueux',
			'Sermaize-les-Bains',
			'Septeuil',
			'Selvigny',
			'Savigny',
			'Sausset-les-Pins',
			'Saujon',
			'Saucats',
			'Sannois',
			'Saint-Viâtre',
			'Pragoulin',
			'Saint-Rémy-en-Mauges',
			'Saint-Quentin-la-Poterie',
			'Saint-Philbert-de-Grand-Lieu',
			'Saint-Paul-Cap-de-Joux',
			'Saint-Pal-de-Mons',
			'Saint-Martin-de-Ré',
			"Saint-Jean-d'Illac",
			'Saint-Jean-de-Soudain',
			"Saint-Jean-d'Aulps",
			'Saint-Hilaire-de-Villefranche',
			'Saint-Hilaire de Talmont',
			'Saint-Georges-des-Groseillers',
			'Sainte-Sigolène',
			'Sainte-Savine',
			'Saint-Didier-sur-Chalaronne',
			'Saint-Didier',
			'Saint-Claud',
			'Saint-Chaffrey',
			'Saint-Bris-le-Vineux',
			'Saint-Brieuc',
			'Saint-Bonnet-près-Riom',
			'Saint-Avertin',
			'Saint-Aubin-sur-Gaillon',
			'Saint-Aubin-des-Ormeaux',
			'Saint-André-les-Vergers',
			'Saint-Andiol',
			'Russ',
			'Rosult',
			'Roquecourbe',
			'Rocquencourt',
			'Rochecorbon',
			'Robion',
			'Rivery',
			'Rions',
			'Rians',
			'Reyrieux',
			'Revin',
			'Revel',
			'Restigné',
			'Renwez',
			'Recquignies',
			"Raon-l'Étape",
			'Raedersheim',
			'Quaëdypre',
			'Pussay',
			'Priziac ( Priziac )',
			'Pringy',
			'Prat',
			'Pouzauges',
			'Portiragnes',
			'Pont-de-Chéruy',
			'Pluneret',
			'Plumergat',
			'Plougrescant',
			'Ploufragan',
			'Ploërdut',
			'Ploemeur',
			'Plémy',
			'Plélan-le-Grand',
			'Plauzat',
			'Plaintel',
			'Pissos',
			'Pirou',
			'Peyrehorade',
			'Perrecy-les-Forges',
			'Peltre',
			'Peillon',
			'Pauillac',
			'Passy',
			'Pacé',
			'Ozoir-la-Ferrière',
			'Ostricourt',
			'Ostheim',
			'Osny',
			'Ons-en-Bray',
			'Ondres',
			'Oisy-le-Verger',
			'Nouzonville',
			'Nordhouse',
			'Neuvéglise',
			'Neung-sur-Beuvron',
			'Naveil',
			'Naucelles',
			'Muron',
			'Mours',
			'Mourenx',
			'Mougins',
			'Morières-lès-Avignon',
			'Montois-la-Montagne',
			'Montmorency',
			'Montmerle-sur-Saône',
			'Montlieu-la-Garde',
			'Montigny-en-Ostrevent',
			'Montferrat',
			'Montech',
			'Montcourt-Fromonville',
			'Mont-Bernanchon',
			'Montargis',
			'Mons-en-Barœul',
			'Mons',
			'Moncé-en-Belin',
			'Méteren',
			'Messery',
			'Merten',
			'Mérignies',
			'Méaulte',
			'Marmagne',
			'Marigny-les-Usages',
			'Mareuil-sur-Cher',
			'Mareuil-sur-Ay',
			'Marbache',
			'Malintrat',
			'Luzy',
			'Lusigny',
			'Lusanger',
			'Lormes',
			'Longpré-les-Corps-Saints',
			"L'Isle-sur-la-Sorgue",
			'Linguizzetta',
			'Ligueil',
			'Leucate',
			'Le Thou',
			'Le Thillay',
			'Le Syndicat',
			'Les Touches',
			'Les Roches-de-Condrieu',
			'Les Montils',
			'Les Loges-en-Josas',
			'Lempdes-sur-Allagnon',
			'Le Mayet-de-Montagne',
			'Lédenon',
			'Le Crest',
			'Lécousse',
			'Le Châtelet-en-Brie',
			'Le Champ-Saint-Père',
			'Le Chambon-sur-Lignon',
			'Le Bois-Plage-en-Ré',
			'La Ville-du-Bois',
			'Lavardac',
			'La Trinité-sur-Mer',
			'La Tardière',
			'Lassy',
			'La Sentinelle',
			'La Ricamarie',
			'Largentière',
			'La Remuée',
			'La Pommeraie-sur-Sèvre',
			'La Pomme',
			'Lantriac',
			'Lamanon',
			'La Ferté-Macé',
			'La Chapelle-du-Noyer',
			'Labarthe-sur-Lèze',
			'Kunheim',
			'Juillac',
			'Janneyrias',
			'Issou',
			'Illfurth',
			'Igney',
			'Huttenheim',
			'Huisseau-sur-Mauves',
			'Homécourt',
			'Haverskerque',
			'Guingamp',
			'Gravigny',
			'Gourdan-Polignan',
			'Gouesnach',
			'Gilly-sur-Isère',
			'Générac',
			'Genay',
			'Gaël',
			'Fumay',
			'Franconville',
			'Fourques-sur-Garonne',
			'Fontenay-aux-Roses',
			'Fontcouverte',
			'Fontaine-Étoupefour',
			'Fleurance',
			'Firmi',
			'Fénay',
			'Faremoutiers',
			'Eymet',
			'Eurville-Bienville',
			'Étampes-sur-Marne',
			'Esternay',
			'Enval',
			'Égletons',
			'Ébreuil',
			'Draveil',
			'Dom-le-Mesnil',
			'Diemeringen',
			'Crisolles',
			'Crèvecœur-le-Grand',
			'Cousolre',
			'Cours-de-Pile',
			'Cormontreuil',
			'Combloux',
			'Colleville-Montgomery',
			'Colayrac-Saint-Cirq',
			'Clermont-en-Argonne',
			'Clères',
			'Chevaigné',
			'Cherves-Richemont',
			'Chavanay',
			'Châteauneuf-sur-Sarthe',
			'Châteaufort',
			'Châteaubriant',
			'Chassors',
			'Chârost',
			'Chancenay',
			'Champvans',
			'Champignelles',
			'Chalo-Saint-Mars',
			'Challex',
			'Chalamont',
			'Chaillevette',
			'Chacé',
			'Cessenon-sur-Orb',
			'Castelnau-de-Médoc',
			'Castelginest',
			'Bry-sur-Marne',
			'Bruguières',
			"Bruay-sur-l'Escaut",
			'Briscous',
			'Briis-sous-Forges',
			'Brenouille',
			'Bray-sur-Seine',
			'Bouzonville',
			'Boulay-Moselle',
			'Bouin',
			'Boué',
			'Boucau',
			'Bondoufle',
			'Boissy-le-Châtel',
			'Blonville-sur-Mer',
			'Bischheim',
			'Bihorel',
			'Beynes',
			'Bessenay',
			'Bernis',
			'Belligné',
			'Bellenaves',
			'Beignon',
			'Bègles',
			'Beaune',
			'Beaugency',
			'Beaucouzé',
			'Beauchamps',
			'Bartenheim',
			'Banyuls de la Marenda',
			'Bains-les-Bains',
			'Bagneux',
			'Bachant',
			'Auffay',
			'Auch',
			'Auby',
			'Aubigné-Racan',
			'Astaffort',
			'Aspach-le-Haut',
			'Arnac-la-Poste',
			'Arleux',
			'Argences',
			'Ardentes',
			'Arcey',
			'Arâches-la-Frasse',
			'Anneyron',
			'Aniche',
			'Andé',
			'Amou',
			'Amanvillers',
			'Althen-des-Paluds',
			'Allonnes',
			'Alixan',
			'Ajaccio',
			'Aiguillon',
			'Aigrefeuille-sur-Maine',
			'Ahuillé',
			'Achères-la-Forêt',
			'Abrest',
			'Ablis',
			'Val Thorens',
			'Baille',
			'Montolivet',
			'Ygos-Saint-Saturnin',
			'Yèvres',
			'Woippy',
			'Cayeux-sur-Mer',
			'Cahuzac-sur-Vère',
			'Wavignies',
			'Wambrechies',
			'Vue',
			'Vouziers',
			'Voulangis',
			'Viviers',
			'Ville-sous-la-Ferté',
			'Villers-Outréaux',
			'Villers-Écalles',
			'Villeneuve-Saint-Germain',
			'Villemur-sur-Tarn',
			'Villars-les-Dombes',
			'Vif',
			'Vendeuvre-sur-Barse',
			'Vémars',
			'Vaugrigneuse',
			'Vauchrétien',
			'Varennes-Saint-Sauveur',
			'Vallabrègues',
			'Valframbert',
			'Ussel',
			'Urville-Nacqueville',
			'Truchtersheim',
			'Tressin',
			'Treffort-Cuisiat',
			'Thuellin',
			'Sulniac',
			'Stiring-Wendel',
			'Sourcieux-les-Mines',
			'Somain',
			'Soisy-sur-Seine',
			'Sigolsheim',
			'Signes',
			'Sérignan',
			'Sebourg',
			'Saulxures-sur-Moselotte',
			'Sathonay-Village',
			'Sassenay',
			'Salouël',
			'Salignac-Eyvigues',
			'Saint-Yan',
			'Saint-Vit',
			'Saint-Usuge',
			'Saint-Sulpice-de-Faleyrens',
			"Saint-Sauves-d'Auvergne",
			'Saint-Quentin',
			'Saint-Pol-sur-Mer',
			'Saint-Pierre-sur-Dives',
			'Saint-Pierre-la-Palud',
			'Saint-Philbert-de-Bouaine',
			'Saint-Paul-en-Forêt',
			'Saint-Nabord',
			'Saint-Michel',
			'Saint-Martin-du-Frêne',
			'Saint-Laurent-de-la-Prée',
			'Saint-Jean-des-Mauvrets',
			'Saint-Jacques-de-la-Lande',
			'Saint-Hilaire-de-Chaléons',
			'Saint-Herblain',
			'Saint-Gervais-les-Trois-Clochers',
			'Saint-Genix-sur-Guiers',
			'Saint-Félicien',
			'Sainte-Tulle',
			'Saint-Erme-Outre-et-Ramecourt',
			"Sainte-Foy-l'Argentière",
			'Sainte-Euphémie',
			'Saint-Domineuc',
			'Saint-Cyr-sur-Mer',
			"Saint-Cyr-l'École",
			'Saint-Cyprien',
			'Saint-Christo-en-Jarez',
			'Saint-Bonnet-en-Champsaur',
			'Saint-Amarin',
			'Saché',
			'Ruillé-sur-Loir',
			'Ruelle-sur-Touvre',
			'Rueil-Malmaison',
			'Rousson',
			'Rousies',
			'Roujan',
			'Rinxent',
			'Rieumes',
			'Riec-sur-Belon',
			'Renescure',
			'Ranville',
			'Raimbeaucourt',
			'Quillebeuf-sur-Seine',
			'Pusignan',
			'Puiseaux',
			'Précy-sur-Oise',
			'Pourrain',
			'Porto-Vecchio',
			"Pont-d'Ain",
			'Pont-à-Marcq',
			'Poitiers',
			'Poissy',
			'Poilly-lez-Gien',
			'Plouvien',
			'Plougoumelen',
			'Plobannalec-Lesconil',
			'Plérin',
			'Pierrefontaine-les-Varans',
			'Pélussin',
			'Pellouailles-les-Vignes',
			'Pecquencourt',
			'Parentis-en-Born',
			'Orry-la-Ville',
			'Orléat',
			'Orgueil',
			'Orbec',
			'Olemps',
			'Oissery',
			'Offemont',
			'Œting',
			'Noyon',
			'Nouvoitou',
			'Nouaillé-Maupertuis',
			'Nort-sur-Erdre',
			'Neuvy',
			'Nantes',
			'Mugron',
			'Moyaux',
			'Moussan',
			'Moult',
			'Morez',
			'Montoir-de-Bretagne',
			'Montmoreau-Saint-Cybard',
			'Montfaucon-en-Velay',
			'Montblanc',
			'Montagnac',
			'Moëlan-sur-Mer',
			'Médis',
			'Maurs',
			'Mauguio',
			'Martigné-Briand',
			'Marquette-en-Ostrevant',
			'Marconne',
			'Marans',
			'Manneville-sur-Risle',
			'Malansac',
			'Magland',
			'Lucéram',
			'Lorquin',
			'Longpont-sur-Orge',
			'Locon',
			'Locmiquélic',
			'Liré',
			'Lieurey',
			'Le Vigan',
			'Le Thuit-Signol',
			'Les Rosiers-sur-Loire',
			'Les Essarts',
			'Le Pin',
			'Le Perreux-sur-Marne',
			'Le Pellerin',
			'Le Passage',
			'Le Merlan',
			'Le Croisic',
			'Le Conquet',
			'Le Brusquet',
			'La Wantzenau',
			'Laval-Pradel',
			'Latour-Bas-Elne',
			'La Suze-sur-Sarthe',
			'Laruscade',
			'La Rochette',
			'La Richardais',
			'Laragne-Montéglin',
			'La Pouëze',
			'Lapeyrouse-Fossat',
			'La Pacaudière',
			'Landiras',
			'La Mure',
			'Lahonce',
			'Lagarrigue',
			'La Garde-Adhémar',
			'La Celle-Saint-Cloud',
			'Lacanau',
			'Jouy-aux-Arches',
			'Jonquerettes',
			'Joinville',
			'Jœuf',
			'Jeanménil',
			'Jassans-Riottier',
			'Jarzé',
			'Ivry-la-Bataille',
			'Isle',
			'Innenheim',
			'Hulluch',
			'Hersin-Coupigny',
			'Herry',
			'Hermonville',
			'Haute-Goulaine',
			'Hallencourt',
			'Guyancourt',
			'Guilers',
			'Guérigny',
			'Groisy',
			'Graveson',
			'Gouesnou',
			'Gœulzin',
			'Gavray',
			'Gaillan-en-Médoc',
			'Fougerolles-du-Plessis',
			'Font-Romeu-Odeillo-Via',
			'Fleurey-sur-Ouche',
			'Feuchy',
			'Festubert',
			'Faulx',
			'Falck',
			'Estillac',
			'Estagel',
			'Équeurdreville-Hainneville',
			'Epfig',
			'Écouché',
			'Dun-sur-Auron',
			'Drancy',
			'Dossenheim-sur-Zinsel',
			'Domfront',
			'Distré',
			'Dinsheim-sur-Bruche',
			'Dammartin-en-Goële',
			'Daix',
			'Cusset',
			'Culhat',
			'Crozon',
			'Criquebeuf-sur-Seine',
			'Creil',
			'Créhange',
			'Crécy-en-Ponthieu',
			'Coye-la-Forêt',
			'Coubert',
			'Corme-Royal',
			'Cordon',
			'Corbas',
			'Condette',
			'Coarraze',
			'Clion',
			'Clamart',
			'Claira',
			'Chilleurs-aux-Bois',
			'Chevrières',
			'Chazelles-sur-Lyon',
			'Chavanod',
			'Chaumont-sur-Loire',
			'Chaudon',
			'Châtenois-les-Forges',
			'Château-Porcien',
			'Château-Guibert',
			'Chartrettes',
			'Chanas',
			'Champforgeuil',
			'Cesson-Sévigné',
			'Cergy',
			'Cellieu',
			'Cazaubon',
			'Caudebec-lès-Elbeuf',
			'Carspach',
			'Carrières-sous-Poissy',
			'Camaret-sur-Aigues',
			'Camarès',
			'Callac',
			'Caëstre',
			'Cabourg',
			'Bury',
			'Brumath',
			'Brou-sur-Chantereine',
			'Brou',
			'Broons',
			'Briançon',
			'Breuilpont',
			'Bréal-sous-Montfort',
			'Brassac-les-Mines',
			'Boulazac',
			'Blacé',
			'Binic',
			'Beuzeville',
			'Bessay-sur-Allier',
			'Berthecourt',
			'Bernes-sur-Oise',
			'Berlaimont',
			'Belmont-de-la-Loire',
			'Baugé-en-Anjou',
			'Barbentane',
			'Ballaison',
			'Baguer-Pican',
			'Auriol',
			'Audun-le-Roman',
			'Aubord',
			'Attichy',
			'Artix',
			'Arthon',
			'Arsac',
			'Arnières-sur-Iton',
			'Arlanc',
			'Anet',
			'Andolsheim',
			'Allaire',
			'Albert',
			'Airvault',
			'Geneston',
			'Marseille 02',
			'Marseille 05',
			'Marseille 15',
			'Yerville',
			'Wormhout',
			'Warhem',
			'Warcq',
			'Vitré',
			'Virieu',
			'Villiers-Saint-Georges',
			'Villeneuve-lès-Bouloc',
			'Villelongue-de-la-Salanque',
			'Villefranche-sur-Saône',
			'Villefranche-sur-Cher',
			'Vihiers',
			'Vigneux-sur-Seine',
			'Vigeois',
			'Verton',
			'Versonnex',
			'Vérines',
			'Verdun-sur-le-Doubs',
			'Veneux-les-Sablons',
			'Venerque',
			'Vendeville',
			'Velleron',
			'Veigné',
			'Vay',
			'Vauhallan',
			'Varennes-le-Grand',
			'Vaison-la-Romaine',
			'Triaize',
			'Trélissac',
			'Trégunc',
			'Tourlaville',
			'Toulouges',
			'Toulon',
			'Thorigné',
			'Thiverny',
			'Tence',
			'Tenay',
			'Tauxigny',
			'Tarare',
			'Suresnes',
			'Sully-sur-Loire',
			'Stosswihr',
			'Spycker',
			'Soual',
			'Sénas',
			'Selles-sur-Cher',
			'Seingbouse',
			'Schirmeck',
			'Savonnières',
			'Saubens',
			'Sarcelles',
			'Salon-de-Provence',
			'Saint-Rémy-lès-Chevreuse',
			'Saint-Rémy-de-Provence',
			'Saint-Priest-Taurion',
			'Saint-Pierre-de-Varengeville',
			'Saint-Pardoux-la-Rivière',
			'Saint-Palais',
			'Saint-Pabu',
			"Saint-Nicolas-d'Aliermont",
			'Saint-Michel-le-Cloucq',
			'Saint-Martin-le-Beau',
			"Saint-Martin-d'Hères",
			'Saint-Mars-du-Désert',
			'Saint-Gervais-en-Belin',
			'Saint-Germain',
			'Saint-Fargeau-Ponthierry',
			'Saint-Étienne-de-Chigny',
			'Saint-Étienne',
			'Sainte-Marie-de-Gosse',
			'Saint-Éloy-de-Gy',
			'Sainte-Agnès',
			'Saint-Denis-lès-Bourg',
			'Saint-Cyr-sur-le-Rhône',
			'Saint-Claude',
			'Saint-Bonnet-le-Château',
			'Saint-Aubin-sur-Mer',
			'Saint-Aubin-du-Cormier',
			'Saint-Amans-Valtoret',
			'Sainghin-en-Weppes',
			'Rousset',
			'Rougiers',
			'Rougé',
			'Rochetaillée-sur-Saône',
			'Rive-de-Gier',
			'Rilhac-Rancon',
			'Richebourg',
			'Revigny-sur-Ornain',
			'Renazé',
			'Reiningue',
			'Quingey',
			'Québriac',
			'Pulversheim',
			'Préaux',
			'Prades-le-Lez',
			'Pornic',
			'Pontenx-les-Forges',
			'Pont-de-Salars',
			'Pont-Aven',
			'Pomponne',
			'Polignac',
			'Poix-de-Picardie',
			'Pleurtuit',
			'Pleumeur-Gautier',
			'Plessala',
			'Pîtres',
			'Pinon',
			'Pfastatt',
			'Pézilla-la-Rivière',
			'Peyrilhac',
			'Peyriac-de-Mer',
			'Perros-Guirec',
			'Parigné-le-Pôlin',
			'Outarville',
			'Oursbelille',
			'Ouistreham',
			'Ottrott',
			'Oradour-sur-Glane',
			'Oberhaslach',
			'Novalaise',
			'Nieul-sur-Mer',
			'Neuilly-Saint-Front',
			'Nercillac',
			'Navarrenx',
			'Nanterre',
			'Mussig',
			'Murviel-lès-Béziers',
			'Mulsanne',
			'Morsang-sur-Orge',
			'Morestel',
			'Morcenx',
			'Mont-Saxonnex',
			'Montrevel-en-Bresse',
			'Montreuil',
			'Montrabé',
			'Montigny-le-Bretonneux',
			'Montignac',
			'Montgaillard',
			'Monteux',
			'Montauroux',
			'Monbazillac',
			'Moissy-Cramayel',
			'Modane',
			'Misérieux',
			'Miniac-Morvan',
			'Mer',
			"Les Martres-d'Artière",
			'Marseillan',
			'Marcheprime',
			'Mantes-la-Ville',
			'Manom',
			'Maing',
			'Loué',
			'Longchamp',
			'Locquémeau',
			'Lisle-sur-Tarn',
			'Limoges',
			'Limas',
			"L'Haÿ-les-Roses",
			'Lézan',
			'Lexy',
			'Le Tréport',
			'Le Tallud',
			'Lestelle-Bétharram',
			'Les Pavillons-sous-Bois',
			'Les Olives',
			'Le Soler',
			'Les Noës-près-Troyes',
			'Les Houches',
			'Les Hautes-Rivières',
			'Les Chères',
			'Le Porge',
			'Le Pallet',
			'Lens',
			'Gond-Pontouvre',
			'Lédignan',
			'Le Coteau',
			'Le Castellet',
			'Lauterbourg',
			'La Seyne-sur-Mer',
			'La Salvetat-Peyralès',
			'Lapoutroie',
			'Lanvéoc',
			"La Motte-d'Aveillans",
			'La Millère',
			'La Loupe',
			'Laissac',
			'La Gaude',
			'La Frette-sur-Seine',
			'La Forêt-Fouesnant',
			'La Flamengrie',
			'La Chapelle-Gauthier',
			'La Barasse',
			'Ingwiller',
			'Hussigny-Godbrange',
			'Husseren-Wesserling',
			'Huisseau-sur-Cosson',
			'Homblières',
			'Hombleux',
			'Hérouvillette',
			'Haussy',
			'Hardricourt',
			'Ham',
			'Guipavas',
			'Guînes',
			'Guillac',
			'Guignen',
			'Guern',
			'Guermantes',
			'Guarbecque',
			'Gruson',
			'Grosbliederstroff',
			'Grisolles',
			'Grans',
			'Gouvernes',
			'Gorges',
			'Gonnehem',
			'Gondrin',
			'Gilette',
			'Gergy',
			'Gérardmer',
			'Gennevilliers',
			'Garons',
			'Fromelennes',
			'Fresnoy-le-Grand',
			'Frasne',
			'Fouquières-lès-Béthune',
			'Fontenilles',
			'Fontaine-le-Dun',
			'Foëcy',
			'Flixecourt',
			'Fleury-sur-Andelle',
			'Fillé',
			'Feuquières-en-Vimeu',
			'Féternes',
			'Feigères',
			'Eygalières',
			'Exincourt',
			'Estissac',
			'Espaly-Saint-Marcel',
			'Esches',
			'Épinac',
			'Épaignes',
			'Entraigues-sur-la-Sorgue',
			'Ennevelin',
			'Eaunes',
			'Douchy-les-Mines',
			'Douchy',
			'Domarin',
			'Désaignes',
			'Démouville',
			'Dechy',
			'Dannemarie',
			'Damville',
			'Curtin',
			'Crépy-en-Valois',
			'Coutiches',
			'Courlon-sur-Yonne',
			'Courcy',
			'Courçon',
			'Courchevel',
			'Corte',
			'Conty',
			'Concarneau',
			'Compreignac',
			'Colleret',
			'Coligny',
			'Coise',
			'Cissé',
			'Chézy-sur-Marne',
			'Chaumes-en-Brie',
			'Chaudes-Aigues',
			'Châtillon-sur-Indre',
			'Châtillon-en-Bazois',
			'Château-Salins',
			'Charroux',
			'Chanu',
			'Chantraine',
			'Champigny',
			'Champcevinel',
			'Chambray-lès-Tours',
			'Châlette-sur-Loing',
			'Chabreloche',
			'Chabanais',
			'Cerdon',
			'Chaillac',
			'Cessy',
			'Cazilhac',
			'Cavaillon',
			"Caumont-l'Éventé",
			'Castelsarrasin',
			'Castelnau-de-Lévis',
			'Castellane',
			'Carros',
			'Carnac',
			'Cajarc',
			'Bussang',
			'Bullion',
			'Brûlon',
			'Briouze',
			'Brindas',
			'Brech',
			'Brassac',
			'Brasles',
			'Branne',
			'Boyard-Ville',
			'Bourbon-Lancy',
			'Boulogne-sur-Gesse',
			'Boulbon',
			'Bouchemaine',
			'Bosdarros',
			'Bonnes',
			'Bodilis',
			'Blanzat',
			'Blanquefort',
			'Bias',
			'Beynat',
			'Bétheny',
			'Béthencourt-sur-Mer',
			'Berrwiller',
			'Bénesse-Maremne',
			'Belvès',
			'Bazoches-les-Gallerandes',
			'Bazainville',
			'Bassillac',
			'Ban-de-Laveline',
			'Bâgé-la-Ville',
			'Azay-sur-Cher',
			'Avesnes-sur-Helpe',
			'Avesnes-le-Sec',
			'Auzielle',
			'Auvers-Saint-Georges',
			'Audenge',
			'Arras',
			'Aron',
			'Armbouts-Cappel',
			'Argentré-du-Plessis',
			'Argentré',
			'Ardres',
			'Aramon',
			'Antran',
			'Angres',
			'Angers',
			'Andres',
			'Andilly',
			'Ambleteuse',
			'Aix-en-Othe',
			'Ailly-sur-Somme',
			'Aigues-Mortes',
			'Agny',
			'Achicourt',
			'Mercurey',
			'Bayard-sur-Marne',
			'Les Grands Carmes',
			'Saint-Joseph',
			'Les Crottes',
			'La Delorme',
			'Notre-Dame Limite',
			'Peyrat-le-Château',
			'Yzernay',
			'Yvetot',
			'Ydes',
			'Xonrupt-Longemer',
			'Wimereux',
			'Wihr-au-Val',
		],
	},
	'French Guiana': {
		country: 'French Guiana',
		cities: [
			'Matoury',
			'Apatou',
			'Saint-Georges',
			'Roura',
			'Sinnamary',
			'Rémire',
			'Camopi',
			'Kourou',
			'Mana',
			'Maripasoula',
			'Rémire-Montjoly',
			'Macouria',
			'Saint-Laurent-du-Maroni',
			'Cayenne',
			'Grand-Santi',
			'Iracoubo',
			'Régina',
		],
	},
	'French Polynesia': {
		country: 'French Polynesia',
		cities: [
			'Taiohae',
			'Pirae',
			'Otutara',
			'Teavaro',
			'Papao',
			'Haapiti',
			'Fitii',
			'Rikitea',
			'Hitiaa',
			'Atuona',
			'Uturoa',
			'Tohautu',
			'Teahupoo',
			'Pueu',
			'Fare',
			'Vairao',
			'Tiarei',
			'Tautira',
			'Tapuarava',
			'Tevaitoa',
			'Punaauia',
			'Faanui',
			'Mahina',
			'Afareaitu',
			'Paopao',
			'Afaahiti',
			'Faaa',
			'Anau',
			'Paea',
			'Moerai',
			'Taunoa',
			'Papeete',
			'Faone',
			'Arue',
			'Vaitape',
			'Papetoai',
		],
	},
	Gabon: {
		country: 'Gabon',
		cities: [
			'Ndendé',
			'Port-Gentil',
			'Libreville',
			'Cocobeach',
			'Booué',
			'Bitam',
			'Mounana',
			'Moanda',
			'Mitzic',
			'Fougamou',
			'Koulamoutou',
			'Ndjolé',
			'Makokou',
			'Mayumba',
			'Lékoni',
			'Omboué',
			'Mbigou',
			'Franceville',
			'Mouila',
			'Lastoursville',
			'Gamba',
			'Tchibanga',
			'Ntoum',
			'Mimongo',
			'Lambaréné',
			'Okondja',
			'Oyem',
		],
	},
	Gambia: {
		country: 'Gambia',
		cities: [
			'Nyamanari',
			'Kerewan',
			'Gunjur',
			'Sara Kunda',
			'Karantaba',
			'Brifu',
			'Kumbija',
			'Bansang',
			'Sutukoba',
			'Sudowol',
			'Gunjur Kuta',
			'Gunjur',
			'Diabugu',
			'Saba',
			'Nioro',
			'Sankwia',
			'Perai',
			'Koina',
			'Katchang',
			'Serekunda',
			'Karantaba',
			'Sukuta',
			'Essau',
			'Bureng',
			'Toniataba',
			'Galleh Manda',
			'Banjul',
			'Pateh Sam',
			'Kuntaur',
			'Jakhaly',
			'Denton',
			'Daba Kunda',
			'Saruja',
			'Keneba',
			'Dankunku',
			'Bakau',
			'Sun Kunda',
			'Kaiaf',
			'Brikama Nding',
			'Wellingara Ba',
			'Farafenni',
			'No Kunda',
			'Janjanbureh',
			'Brikama',
			'Mansa Konko',
			'Basse Santa Su',
			'Bambali',
			'Abuko',
			'Chilla',
			'Daru Rilwan',
			'Barra',
			'Kass Wollof',
			'Jali',
			'Sutukung',
			'Si Kunda',
			'Jifarong',
			'Wassu',
			'Lamin',
			'Kunting',
			'Jarreng',
			'Demba Kunda',
			'Sukuta',
			'Soma',
			'Sabi',
			'Jenoi',
			'Diabugu Basilla',
			'Somita',
			'Baro Kunda',
			'Sami',
			'Bakadagy',
		],
	},
	Georgia: {
		country: 'Georgia',
		cities: [
			'Ts’inandali',
			'T’q’ibuli',
			'Mtskheta',
			'K’ulashi',
			'Dzegvi',
			'Tsageri',
			'Sartich’ala',
			'Keda',
			'St’epants’minda',
			'Gali',
			'Agara',
			'Telavi',
			'Oni',
			'Dedoplists’q’aro',
			'Akhalk’alak’i',
			'Vale',
			'Shuakhevi',
			'Makhinjauri',
			'Khoni',
			'Jvari',
			'Tbilisi',
			'Ch’lou',
			'Zhinvali',
			'Chiat’ura',
			'P’rimorsk’oe',
			'Bak’urianis Andezit’i',
			'Shemokmedi',
			'Diok’nisi',
			'Borjomi',
			'Asp’indza',
			'Narazeni',
			'Ts’nori',
			'Terjola',
			'Pasanauri',
			'Dusheti',
			'Zugdidi',
			'Och’amch’ire',
			'Gulrip’shi',
			'Dmanisi',
			'Tsqnet’i',
			'Tianeti',
			'Surami',
			'Sokhumi',
			'Samtredia',
			'K’atskhi',
			'Gardabani',
			'Ozurgeti',
			'Khelvachauri',
			'Khashuri',
			'Anaklia',
			'Akhali Atoni',
			'Mat’ani',
			'Khulo',
			'Ingiri',
			'Lent’ekhi',
			'Dranda',
			'Batumi',
			'Zest’aponi',
			'Urek’i',
			'K’urdghelauri',
			'Kojori',
			'Java',
			'Gurjaani',
			'Didi Lilo',
			'Akhaldaba',
			'Ts’aghveri',
			'Chokhat’auri',
			'Bagdadi',
			'Adigeni',
			'Sach’khere',
			'Ninotsminda',
			'Mart’q’opi',
			'Kobuleti',
			'K’asp’i',
			'Gudauri',
			'Aghaiani',
			'Abasha',
			'Marneuli',
			'Gudauta',
			'Tetri Ts’q’aro',
			'Lagodekhi',
			'Kutaisi',
			'Akhmet’a',
			'Kelasuri',
			'Sighnaghi',
			'Ochkhamuri',
			'Mest’ia',
			'Stantsiya Novyy Afon',
			'Ts’alenjikha',
			'Rustavi',
			'P’ot’i',
			'Bolnisi',
			'Ts’alk’a',
			'Shorap’ani',
			'Sagarejo',
			'K’ardenakhi',
			'Gori',
			'Ambrolauri',
			'Akhaltsikhe',
			'Vani',
			'Tsikhisdziri',
			"Tqvarch'eli",
			'Kveda Chkhorots’q’u',
			'Kharagauli',
			'Abastumani',
			'Orsant’ia',
			'Gagra',
			'Chakvi',
			'Akhalgori',
			'Naruja',
			'Jvara',
			'Bich’vinta',
			'Bak’uriani',
			'Akhaldaba',
			'Ts’q’alt’ubo',
			'Senak’i',
			'Mart’vili',
			'Manglisi',
			'Khobi',
			'Gantiadi',
			'Chkhorots’q’u',
			'Zahesi',
			'Ts’khinvali',
			'Shaumiani',
			'Q’vareli',
			'Lanchkhuti',
		],
	},
	Germany: {
		country: 'Germany',
		cities: [
			'Zschorlau',
			'Zeitlarn',
			'Wurmberg',
			'Wollbach',
			'Winterhausen',
			'Wetzendorf',
			'Westheim',
			'Westerkappeln',
			'Wennigsen',
			'Weitersburg',
			'Weißenhorn',
			'Weischlitz',
			'Weichs',
			'Wattmannshagen',
			'Wasserlosen',
			'Wartenberg',
			'Visbek',
			'Villmar',
			'Uetersen',
			'Unterensingen',
			'Unnau',
			'Ungerhausen',
			'Uehlfeld',
			'Uchtspringe',
			'Tutow',
			'Türnich',
			'Tülau',
			'Theuma',
			'Templin',
			'Teisnach',
			'Tangstedt',
			'Sulzbach',
			'Strausberg',
			'Stettfeld',
			'Stedten',
			'Stahnsdorf',
			'Sprockhövel',
			'Spelle',
			'Sonneborn',
			'Sohren',
			'Sinzheim',
			'Sigmaringen',
			'Seelbach',
			'Schwarzenberg',
			'Schwabmünchen',
			'Schwäbisch Hall',
			'Schönau',
			'Schnürpflingen',
			'Schlaitz',
			'Saulgrub',
			'Saldenburg',
			'Ruhpolding',
			'Rudow',
			'Röttenbach',
			'Roggendorf',
			'Ritterhude',
			'Busenberg',
			'Burg auf Fehmarn',
			'Büchel',
			'Buch',
			'Brühl',
			'Brücken',
			'Brücken',
			'Brome',
			'Bremerhaven',
			'Breitungen',
			'Breitnau',
			'Borstel',
			'Borrentin',
			'Bornich',
			'Born',
			'Borken',
			'Börger',
			'Bordesholm',
			'Bollendorf',
			'Bolanden',
			'Bockau',
			'Bliedersdorf',
			'Blankenrath',
			'Bismark',
			'Bad Bevensen',
			'Beeskow',
			'Bausendorf',
			'Bad Oldesloe',
			'Bad Neustadt an der Saale',
			'Bad Münster am Stein-Ebernburg',
			'Bad Lausick',
			'Bad Dürrenberg',
			'Bad Abbach',
			'Aystetten',
			'Auhausen',
			'Auggen',
			'Arnbruck',
			'Arenshausen',
			'Anrode',
			'Altrich',
			'Altomünster',
			'Altenstadt',
			'Alsdorf',
			'Aldingen',
			'Aldersbach',
			'Aiglsbach',
			'Aichelberg',
			'Lauchringen',
			'Burgstetten',
			'Katlenburg-Lindau',
			'Sailauf',
			'Liederbach',
			'Flöthe',
			'Buchforst',
			'Querum -  Wabenkamp',
			'Gartenstadt',
			'Ried',
			'Rheinberg',
			'Rennerod',
			'Regen',
			'Ramsin',
			'Rambin',
			'Rahnsdorf',
			'Puderbach',
			'Prien am Chiemsee',
			'Prenzlauer Berg',
			'Polling',
			'Podelzig',
			'Plaidt',
			'Pinneberg',
			'Philippsthal',
			'Pfronten',
			'Pfarrkirchen',
			'Pfaffenweiler',
			'Pfaffenhofen an der Roth',
			'Pfaffenhofen an der Ilm',
			'Peißen',
			'Pähl',
			'Oststeinbek',
			'Seeheilbad Graal-Müritz',
			'Ostseebad Dierhagen',
			'Olfen',
			'Oldenburg in Holstein',
			'Ochtendung',
			'Obernbreit',
			'Niederwiesa',
			'Neustadt in Holstein',
			'Neustadt an der Waldnaab',
			'Neunburg vorm Wald',
			'Neumarkt in der Oberpfalz',
			'Neuenkirchen',
			'Neuendettelsau',
			'Neuberend',
			'Nebelschütz',
			'Naumburg',
			'Nauen',
			'Murg',
			'Münchweiler an der Alsenz',
			'Memmingen',
			'Mehringen',
			'Meerbusch',
			'Meckenbeuren',
			'Martinsheim',
			'Marktsteft',
			'Marktschorgast',
			'Lützelbach',
			'Lonnig',
			'Longuich',
			'Lohmen',
			'Lindberg',
			'Lehesten',
			'Laubach',
			'Lassan',
			'Lappersdorf',
			'Laberweinting',
			'Kyritz',
			'Krautheim',
			'Krauchenwies',
			'Königheim',
			'Kohlberg',
			'Klink',
			'Kladow',
			'Kirchheim am Ries',
			'Kirchdorf',
			'Kirchbrak',
			'Kell',
			'Jork',
			'Jesberg',
			'Jameln',
			'Irchenrieth',
			'Insingen',
			'Ilmmünster',
			'Ichenhausen',
			'Hüttlingen',
			'Hünfeld',
			'Humboldtkolonie',
			'Hörnsheim',
			'Höchst im Odenwald',
			'Hochheim am Main',
			'Hochdorf',
			'Höchberg',
			'Hirschfelde',
			'Hilgertshausen-Tandern',
			'Hersbruck',
			'Herleshausen',
			'Hengersberg',
			'Hemmingen',
			'Heimertingen',
			'Heiligenhafen',
			'Hauenstein',
			'Haßloch',
			'Halfing',
			'Halbemond',
			'Hainichen',
			'Haag an der Amper',
			'Güsten',
			'Groß Rodensleben',
			'Großkugel',
			'Großholbach',
			'Groß Grönau',
			'Großeibstadt',
			'Greiling',
			'Grattersdorf',
			'Gramzow',
			'Grainet',
			'Gräfendorf',
			'Görmin',
			'Glan-Münchweiler',
			'Gettorf',
			'Gessertshausen',
			'Gerolsheim',
			'Georgsdorf',
			'Gemünden am Main',
			'Gedern',
			'Garrel',
			'Gägelow',
			'Gadheim',
			'Frittlingen',
			'Friedrichsbrunn',
			'Friedland',
			'Friedeburg',
			'Frickingen',
			'Fremdingen',
			'Freiberg',
			'Essingen',
			'Eschau',
			'Ermlitz',
			'Enzklösterle',
			'Eningen unter Achalm',
			'Emmendingen',
			'Elze',
			'Elze',
			'Eltmann',
			'Eisenberg',
			'Eibelstadt',
			'Ehringshausen',
			'Eging',
			'Edewecht',
			'Dörzbach',
			'Domsühl',
			'Dingelsdorf',
			'Dielheim',
			'Darmstadt',
			'Dachau',
			'Cremlingen',
			'Coesfeld',
			'Buchbach',
			'Brilon',
			'Breitenthal',
			'Brand-Erbisdorf',
			'Brande-Hörnerkirchen',
			'Brackenheim',
			'Boxberg',
			'Borgentreich',
			'Bonstetten',
			'Bleialf',
			'Blankenfelde',
			'Bissendorf',
			'Bispingen',
			'Bischberg',
			'Birkenfeld',
			'Bingen',
			'Bielefeld',
			'Biedenkopf',
			'Bestwig',
			'Berngau',
			// 'Bergen',
			'Berga',
			'Bentzin',
			'Bennewitz',
			'Bempflingen',
			'Beindersheim',
			'Beierfeld',
			'Beckum',
			'Barver',
			'Barßel',
			'Banteln',
			'Bad Wimpfen',
			'Bad Soden-Salmünster',
			'Bad Schwartau',
			'Bad Liebenzell',
			'Bad Iburg',
			'Bad Herrenalb',
			'Bad Gandersheim',
			'Bad Dürrheim',
			'Bad Ditzenbach',
			'Augustusburg',
			'Argenthal',
			'Annweiler am Trifels',
			'Albisheim',
			'Albersdorf',
			'Aichstetten',
			'Adelberg',
			'Herdwangen-Schönach',
			'Königsbach-Stein',
			'Walluf',
			'Lünne',
			'Marschacht',
			'Gallus',
			'Neustadt/Nord',
			'Rickert',
			'Retschow',
			'Rendsburg',
			'Rehden',
			'Regesbostel',
			'Rees',
			'Rantrum',
			'Radolfzell',
			'Raben Steinfeld',
			'Queis',
			'Prötzel',
			'Poppenhausen',
			'Plessa',
			'Philippsburg',
			'Pfaffenhofen',
			'Pellworm',
			'Pellingen',
			'Otterberg',
			'Ottenhofen',
			'Osterholz-Scharmbeck',
			'Olbersdorf',
			'Olbernhau',
			'Oftersheim',
			'Oerlinghausen',
			'Obrigheim',
			'Oberzissen',
			'Oberfell',
			'Nüdlingen',
			'Nudersdorf',
			'Nittendorf',
			'Niederschönenfeld',
			'Nieder-Olm',
			'Neusorg',
			'Neureichenau',
			'Neunkirchen',
			'Neumarkt-Sankt Veit',
			'Neuhausen auf den Fildern',
			'Mohlsdorf',
			'Mittel-Gründau',
			'Mihla',
			'Meitingen',
			'Mauer',
			'Maitenbeth',
			'Magdeburg',
			'Lychen',
			'Lunden',
			'Lostau',
			'Lörrach',
			'Lohne',
			'Lintig',
			'Lichtenau',
			'Leuchtenberg',
			'Lensahn',
			'Lemberg',
			'Leimbach',
			'Langlingen',
			'Landshut',
			'Kreuzberg',
			'Kreuzau',
			'Berlin Köpenick',
			'Kitzingen',
			'Kitzen',
			'Kirtorf',
			'Kindelbrück',
			'Kaufbeuren',
			'Karben',
			'Kaltennordheim',
			'Kaltenkirchen',
			'Kadenbach',
			'Jördenstorf',
			'Itterbeck',
			'Iserlohn',
			'Illmensee',
			'Hütschenhausen',
			'Hülsede',
			'Huisheim',
			'Horka',
			'Hohenpolding',
			'Hohenfurch',
			'Hohenburg',
			'Hofheim in Unterfranken',
			'Hofgeismar',
			'Höfen an der Enz',
			'Höchenschwand',
			'Hinte',
			'Heuchelheim bei Frankenthal',
			'Herscheid',
			'Herforst',
			'Herford',
			'Herdorf',
			'Hennigsdorf',
			'Heimbach',
			'Heidenau',
			'Hausach',
			'Hattstedt',
			'Hattorf',
			'Haldensleben I',
			'Hagen im Bremischen',
			'Hagenburg',
			'Großsolt',
			'Großrückerswalde',
			'Großkayna',
			'Großenlüder',
			'Großbeeren',
			'Groß Ammensleben',
			'Görzke',
			'Gondelsheim',
			'Golßen',
			'Goldbeck',
			'Glauchau',
			'Glashütte',
			'Gemünden an der Wohra',
			'Gemünden',
			'Gechingen',
			'Gadeland',
			'Freising',
			'Frasdorf',
			'Forstinning',
			'Flachslanden',
			'Feldkirchen-Westerham',
			'Eutin',
			'Euskirchen',
			'Esselbach',
			'Eslarn',
			'Eschershausen',
			'Eschede',
			'Erkenbrechtsweiler',
			'Eresing',
			'Enger',
			'Elsfleth',
			'Ellrich',
			'Eisenhüttenstadt',
			'Eisdorf am Harz',
			'Egglkofen',
			'Edesheim',
			'Ebhausen',
			'Durlangen',
			'Duderstadt',
			'Döhlau',
			'Doberlug-Kirchhain',
			'Dirmstein',
			'Dietersburg',
			'Destedt',
			'Zuzenhausen',
			'Zorge',
			'Zell unter Aichelberg',
			'Zaisenhausen',
			'Wört',
			'Wolferode',
			'Wohnbach',
			'Windhagen',
			'Weyarn',
			'Wetzlar',
			'Weiterstadt',
			'Weinböhla',
			'Weil',
			'Wehingen',
			'Wechselburg',
			'Warstein',
			'Wankendorf',
			'Wangen',
			'Walheim',
			'Waal',
			'Vorbach',
			'Volkstedt',
			'Vestenbergsgreuth',
			'Velten',
			'Veitsbronn',
			'Usingen',
			'Urbar',
			'Untersiemau',
			'Unterleinleiter',
			'Überlingen',
			'Twistringen',
			'Alt-Treptow',
			'Travemünde',
			'Traben-Trarbach',
			'Todtmoos',
			'Tinnum',
			'Tiefenbach',
			'Thalheim',
			'Tennenbronn',
			'Sulzheim',
			'Stühlingen',
			'Stubenberg',
			'Strehla',
			'Stralsund',
			'Stolpen',
			'Stetten am Kalten Markt',
			'Steinenbronn',
			'Steinbach am Taunus',
			'Stiefenhofen',
			'Stegaurach',
			'Stadtlohn',
			'Siersdorf',
			'Sierksdorf',
			'Siek',
			'Sexau',
			'Selbitz',
			'Seehof',
			'Schwaförden',
			'Schonungen',
			'Schnega',
			'Schlotheim',
			'Schleching',
			'Schlat',
			'Sasbach',
			'Sandhausen',
			'Sandau',
			'Salach',
			'Rüsselsheim',
			'Rümmelsheim',
			'Rudolstadt',
			'Rüdesheim',
			'Rommerskirchen',
			'Rittersdorf',
			'Rinchnach',
			'Zwickau',
			'Zappendorf',
			'Zangberg',
			'Zandt',
			'Wusterwitz',
			'Wülknitz',
			'Wolfen',
			'Wittingen',
			'Wismar',
			'Wirges',
			'Wilhelmsfeld',
			'Wilburgstetten',
			'Weste',
			'Werl',
			'Werftpfuhl',
			'Weitenhagen',
			'Weibern',
			'Weferlingen',
			'Wangels',
			'Wang',
			'Walschleben',
			'Wallersdorf',
			'Velden',
			'Unterwellenborn',
			'Tuningen',
			'Tuchenbach',
			'Tübingen',
			'Trostberg an der Alz',
			'Trollenhagen',
			'Train',
			'Tiefenbronn',
			'Tegernsee',
			'Tann',
			'Tamm',
			'Stuhr',
			'Stephanskirchen',
			'Stelle',
			'Steinkirchen',
			'Steinheim',
			'Stapelburg',
			'Sontheim',
			'Sömmerda',
			'Silberstedt',
			'Sennfeld',
			'Selters',
			'Dernbach',
			'Denkendorf',
			'Daun',
			'Danndorf',
			'Dahlen',
			'Dägeling',
			'Dachwig',
			'Charlottenburg',
			'Burgthann',
			'Burgstädt',
			'Burglengenfeld',
			'Bühlertal',
			'Bubesheim',
			'Brunnen',
			'Bromskirchen',
			'Brokstedt',
			'Breitenbrunn',
			'Braunlage',
			'Brake (Unterweser)',
			'Bottrop',
			'Bornhöved',
			'Bopfingen',
			'Bönebüttel',
			'Bolsterlang',
			'Bockhorn',
			'Birkenheide',
			'Bingen am Rhein',
			'Bernstadt',
			'Benningen am Neckar',
			'Beesten',
			'Bärnau',
			'Bad Oeynhausen',
			'Bad Lauchstädt',
			'Bad Langensalza',
			'Appenheim',
			'Ansbach',
			'Altentreptow',
			'Altenmünster',
			'Altenberge',
			'Altdorf',
			'Aichhalden',
			'Adelshofen',
			'Abenberg',
			'Höhn',
			'Katzwinkel',
			'Neukamperfehn',
			'Admannshagen-Bargeshagen',
			'Hochstadt',
			'Kaufungen',
			'Dallgow-Döberitz',
			'Rothenburgsort',
			'Bergedorf',
			'Berlin Treptow',
			'Barmbek-Süd',
			'Veddel',
			'Petritor - Ost',
			'Viewegs Garten',
			'Bebelhof',
			'Oberzent',
			'Rheurdt',
			'Rethen',
			'Reichertshofen',
			'Rehna',
			'Raßnitz',
			'Raisting',
			'Radeburg',
			'Prohn',
			'Pößneck',
			'Pommelsbrunn',
			'Plüderhausen',
			'Plötzky',
			'Pliening',
			'Plattenburg',
			'Pfaffing',
			'Petting',
			'Penkun',
			'Ostrau',
			'Osthofen',
			'Ortenburg',
			'Oranienbaum',
			'Offenau',
			'Oederan',
			'Oebisfelde',
			'Oberröblingen',
			'Obermichelbach',
			'Nindorf',
			'Niedernhausen',
			'Niederkrüchten',
			'Niederbreitbach',
			'Neustadt an der Aisch',
			'Neukirchen',
			'Neuburg',
			'Nauwalde',
			'Münstermaifeld',
			'Münchberg',
			'Mühlacker',
			'Mönchsdeggingen',
			'Mommenheim',
			'Mitterskirchen',
			'Minden',
			'Markt Rettenbach',
			'Markersdorf',
			'Marienwerder',
			'Manschnow',
			'Maierhöfen',
			'Lützow',
			'Loxstedt',
			'Lohsa',
			'Linau',
			'Lich',
			'Leutenberg',
			'Lengdorf',
			'Leiferde',
			'Leezen',
			'Laufen',
			'Laufach',
			'Lauenhagen',
			'Langenbogen',
			'Landstuhl',
			'Lamstedt',
			'Lambrechtshagen',
			'Ladenburg',
			'Küps',
			'Kuppenheim',
			'Kroppenstedt',
			'Kreuth',
			'Krempe',
			'Koserow',
			'Kolbermoor',
			'Kleinwallstadt',
			'Kinderbeuern',
			'Kieselbronn',
			'Ketzin',
			'Kayna',
			'Kassel',
			'Kaltenwestheim',
			'Kallmünz',
			'Kaiserslautern',
			'Juist',
			'Johanniskirchen',
			'Jatznick',
			'Ingoldingen',
			'Ichtershausen',
			'Hötensleben',
			'Hornburg',
			'Horhausen',
			'Horben',
			'Heubach',
			'Heringen',
			'Herbolzheim',
			'Heilsbronn',
			'Heidesheim',
			'Hechthausen',
			'Hechingen',
			'Hebertsfelden',
			'Hattersheim',
			'Haßfurt',
			'Halstenbek',
			'Haiterbach',
			'Großlangheim',
			'Groß Kummerfeld',
			'Großkorbetha',
			'Grönwohld',
			'Grefrath',
			'Greffern',
			'Gornau',
			'Glöthe',
			'Gliesmarode',
			'Gerzen',
			'Gerstetten',
			'Gemmingen',
			'Gau-Algesheim',
			'Garz',
			'Gangloffsömmern',
			'Gaienhofen',
			'Fürstenau',
			'Fröndenberg',
			'Freyung',
			'Frensdorf',
			'Finsterbergen',
			'Finnentrop',
			'Fichtenberg',
			'Felsberg',
			'Farnstädt',
			'Evessen',
			'Esthal',
			'Espelkamp',
			'Eschelbronn',
			'Eppendorf',
			'Engelsberg',
			'Elsterwerda',
			'Elsendorf',
			'Ellingen',
			'Eichwalde',
			'Ehrenburg',
			'Eckersdorf',
			'Ebstorf',
			'Ebergötzen',
			'Duvenstedt',
			'Dürbheim',
			'Dülmen',
			'Drolshagen',
			'Dörverden',
			'Didderse',
			'Schkölen',
			'Sankt Kilian',
			'Rutesheim',
			'Schwabstedt',
			'Schilksee',
			'Scherstetten',
			'Schafstädt',
			'Sankt Peter',
			'Rittersgrün',
			'Delmenhorst',
			'Cölbe',
			'Chieming',
			'Camburg',
			'Burow',
			'Burghausen',
			'Buchholz',
			'Brumby',
			'Broitzem',
			'Broderstorf',
			'Bremen',
			'Breitenborn',
			'Breckerfeld',
			'Bonndorf',
			'Bolheim',
			'Bokholt-Hanredder',
			'Bitterfeld-Wolfen',
			'Billstedt',
			'Biederitz',
			'Beverstedt',
			'Bevern',
			'Berkenthin',
			'Bergkamen',
			'Berg',
			'Berchtesgaden',
			'Bad Belzig',
			'Bassenheim',
			'Bad Neuenahr-Ahrweiler',
			'Bad Harzburg',
			'Bad Freienwalde',
			'Bad Bocklet',
			'Babenhausen',
			'Aschaffenburg',
			'Luftkurort Arendsee',
			'Altleiningen',
			'Altenburg',
			'Allstedt',
			'Allmersbach im Tal',
			'Alfeld',
			'Ahnsen',
			'Adelsdorf',
			'Höhenkirchen-Siegertsbrunn',
			'Bad Rippoldsau-Schapbach',
			'Ahrbrück',
			'Spay',
			'Emmelsbüll-Horsbüll',
			'St. Pauli',
			'Lewenberg',
			'Eisleben Lutherstadt',
			'Burg Unter-Falkenstein',
			'Westhagen',
			'Pappelberg',
			'Zierenberg',
			'Zerbst',
			'Zell am Main',
			'Zell',
			'Zeithain',
			'Wöllstein',
			'Wolfsegg',
			'Witzenhausen',
			'Wimsheim',
			'Willstätt',
			'Wilkau-Haßlau',
			'Wilhelmsthal',
			'Wilhelmsdorf',
			'Wiesthal',
			'Wiernsheim',
			'Weyerbusch',
			'Westheim',
			'Westerholt',
			'Westend',
			'Weilburg',
			'Wassenberg',
			'Warmensteinach',
			'Waldsee',
			'Waldmohr',
			'Waibstadt',
			'Volkmarsen',
			'Vöhringen',
			'Ursberg',
			'Uedem',
			'Udenhausen',
			'Überherrn',
			'Trusetal',
			'Treffurt',
			'Trebur',
			'Tirpersdorf',
			'Timmerlah',
			'Timmendorfer Strand',
			'Tholey',
			'Sülfeld',
			'Südergellersen',
			'Suderburg',
			'Süderbrarup',
			'Stapelfeld',
			'Stadt Wehlen',
			'Stadtroda',
			'Sitzendorf',
			'Sinzig',
			'Simmertal',
			'Simbach am Inn',
			'Sievershütten',
			'Selent',
			'Seevetal',
			'Seeg',
			'Schwarzhofen',
			'Schwallungen',
			'Schwaikheim',
			'Schriesheim',
			'Schornsheim',
			'Schöneberg',
			'Schnelldorf',
			'Schnaitsee',
			'Schillig',
			'Schaalby',
			'Sankt Goarshausen',
			'Salzhemmendorf',
			'Sagard',
			'Sachsen bei Ansbach',
			'Saara',
			'Ruhstorf',
			'Rückersdorf',
			'Rötz',
			'Rottenburg an der Laaber',
			'Rotherbaum',
			'Rogätz',
			'Rodalben',
			'Rieneck',
			'Reußen',
			'Reichenberg',
			'Recke',
			'Rattiszell',
			'Rastenberg',
			'Radibor',
			'Pullach im Isartal',
			'Prebitz',
			'Polsingen',
			'Polle',
			'Plötzkau',
			'Partenheim',
			'Ottobeuren',
			'Oschersleben',
			'Ortrand',
			'Heidstock',
			'Obertaufkirchen',
			'Oberseifersdorf',
			'Oberried',
			'Oberelbert',
			'Nossen',
			'Nordhastedt',
			'Niederndodeleben',
			'Niederlangen',
			'Niedercunnersdorf',
			'Nidda',
			'Neumünster',
			'Neukirch',
			'Neuhaus am Inn',
			'Neuhardenberg',
			'Neufahrn bei Freising',
			'Neubeuern',
			'Netphen',
			'Neidenstein',
			'Mühlenbach',
			'Mühlberg',
			'Mücheln',
			'Mochau',
			'Mietingen',
			'Michelsneukirchen',
			'Michelfeld',
			'Merzhausen',
			'Meissen',
			'Meeder',
			'Medebach',
			'Mecklenburg',
			'Maßbach',
			'Maintal',
			'Maihingen',
			'Lüneburg',
			'List',
			'Lilienthal',
			'Lichtenau',
			'Leubsdorf',
			'Lemsahl-Mellingstedt',
			'Laußig',
			'Lauscha',
			'Lathen',
			'Langwedel',
			'Langensendelbach',
			'Langendorf',
			'Langenbach',
			'Langeln',
			'Landsberg',
			'Landolfshausen',
			'Lahnstein',
			'Lage',
			'Krostitz',
			'Kreuztal',
			'Krauschwitz',
			'Kornwestheim',
			'Königsee',
			'Königsbronn',
			'Klein Wanzleben',
			'Kirchzell',
			'Kirchwalsede',
			'Kirchheimbolanden',
			'Kirchhaslach',
			'Kirchberg',
			'Kiebitzreihe',
			'Kempen',
			'Kefenrod',
			'Katzweiler',
			'Kapsweyer',
			'Kamp-Bornhofen',
			'Kalkhorst',
			'Julbach',
			'Inzigkofen',
			'Immendingen',
			'Illingen',
			'Iggensbach',
			'Hüllhorst',
			'Horrenberg',
			'Hohenleuben',
			'Höheinöd',
			'Helferskirchen',
			'Heiligenstedten',
			'Heidenau',
			'Havixbeck',
			'Hattenhofen',
			'Hänigsen',
			'Hähnichen',
			'Gutach',
			'Grünwald',
			'Grub',
			'Großweitzschen',
			'Groß Twülpstedt',
			'Großensee',
			'Großenhain',
			'Grolsheim',
			'Gräfenroda',
			'Gräfenhainichen',
			'Grabow',
			'Gottfrieding',
			'Gössenheim',
			'Goslar',
			'Görzig',
			'Gönnheim',
			'Gommern',
			'Gomaringen',
			'Gomadingen',
			'Glashütten',
			'Geraberg',
			'Garching',
			'Gaißach',
			'Gachenbach',
			'Friolzheim',
			'Friedewald',
			'Freyburg',
			'Freudenberg',
			'Forchheim',
			'Flacht',
			'Fischbachau',
			'Fellheim',
			'Felde',
			'Feldafing',
			'Faid',
			'Fahrenkrug',
			'Eving',
			'Ettringen',
			'Essen',
			'Erftstadt',
			'Eppstein',
			'Elsenfeld',
			'Ellerstadt',
			'Eisenburg',
			'Eggenfelden',
			'Ederheim',
			'Eddelak',
			'Eckartsberga',
			'Dünsen',
			'Drachselsried',
			'Dotzheim',
			'Döbeln',
			'Dippach',
			'Dierdorf',
			'Deuben',
			'Zwochau',
			'Ziertheim',
			'Wurzen',
			'Wörthsee',
			'Wolfegg',
			'Wittmund',
			'Wirsberg',
			'Wilhelmshaven',
			'Wildsteig',
			'Wildpoldsried',
			'Wiederstedt',
			'Wieda',
			'Weißig',
			'Weidenstetten',
			'Wehrheim',
			'Wechingen',
			'Wassertrüdingen',
			'Wasserburg',
			'Wasbek',
			'Wanderup',
			'Waldsieversdorf',
			'Waigolshausen',
			'Volkmarode',
			'Vechta',
			'Utting am Ammersee',
			'Upgant-Schott',
			'Uffenheim',
			'Uebigau',
			'Trulben',
			'Trittau',
			'Triftern',
			'Tremsbüttel',
			'Thune',
			'Thierstein',
			'Themar',
			'Teupitz',
			'Markt Taschendorf',
			'Tapfheim',
			'Tannhausen',
			'Tambach-Dietharz',
			'Täferrot',
			'Sulzburg',
			'Stuttgart',
			'Steinhagen',
			'Stegen',
			'Staig',
			'Staaken',
			'Sonthofen',
			'Simmerath',
			'Seyda',
			'Derschen',
			'Dahn',
			'Calvörde',
			'Busdorf',
			'Burgwerben',
			'Burglauer',
			'Bufleben',
			'Büdingen',
			'Braunsbedra',
			'Brandshagen',
			'Bösingen',
			'Bönnigheim',
			'Bogenhausen',
			'Blomberg',
			'Birresborn',
			'Birgte',
			'Binzen',
			'Bietigheim',
			'Beuren',
			'Bernhardswald',
			'Bennungen',
			'Battenberg',
			'Bastorf',
			'Barbing',
			'Bad König',
			'Bad Ems',
			'Bacharach',
			'Auleben',
			'Aplerbeck',
			'Angermünde',
			'Andechs',
			'Amelinghausen',
			'Alzenau in Unterfranken',
			'Alveslohe',
			'Althütte',
			'Altenkrempe',
			'Alesheim',
			'Albertshofen',
			'Bad Teinach-Zavelstein',
			'Rieschweiler-Mühlbach',
			'Bartenshagen-Parkentin',
			'Triebel',
			'Linkenheim-Hochstetten',
			'Altstadt Nord',
			'Neu-Hohenschönhausen',
			'Düsseldorf-Pempelfort',
			'Hirschberg an der Bergstraße',
			'Neues Hochschulviertel',
			'Riegsee',
			'Rehburg-Loccum',
			'Rattelsdorf',
			'Radis',
			'Radebeul',
			'Putlitz',
			'Pretzschendorf',
			'Pretzier',
			'Pratau',
			'Pirk',
			'Pillnitz',
			'Peine',
			'Pechbrunn',
			'Parkstetten',
			'Osterburken',
			'Ohlstadt',
			'Oberstaufen',
			'Oberotterbach',
			'Obernzell',
			'Ober-Mörlen',
			'Nobitz',
			'Niederau',
			'Neustadt am Main',
			'Neumühle',
			'Neukirchen',
			'Neuerburg',
			'Neudietendorf',
			'Nentershausen',
			'Nennhausen',
			'Neckartenzlingen',
			'Mülheim',
			'Mühlau',
			'Mössingen',
			'Möglingen',
			'Mittelneufnach',
			'Memmingerberg',
			'Meisenheim',
			'Mauth',
			'Massenbachhausen',
			'Mammendorf',
			'Ludwigshafen am Rhein',
			'Ludwigsburg',
			'Luckau',
			'Lübeck',
			'Lübars',
			'Loschwitz',
			'Löf',
			'Lieberose',
			'Leuna',
			'Lenzen',
			'Lengede',
			'Lehrte',
			'Leese',
			'Lauingen',
			'Langenburg',
			'Küsten',
			'Krumpa',
			'Kröpelin',
			'Kottweiler-Schwanden',
			'Korschenbroich',
			'Klein Reken',
			'Kleinfurra',
			'Kissing',
			'Kirrweiler',
			'Kirchehrenbach',
			'Kirchberg',
			'Kenn',
			'Kemnitz',
			'Kelheim',
			'Kandel',
			'Jena',
			'Jänschwalde',
			'Jahnsdorf',
			'Irrel',
			'Immenhausen',
			'Illingen',
			'Hopferau',
			'Holzheim',
			'Hohenstein-Ernstthal',
			'Höhenberg',
			'Hilzingen',
			'Hettstadt',
			'Hettenshausen',
			'Hessisch Oldendorf',
			'Herzberg am Harz',
			'Heroldsberg',
			'Hermeskeil',
			'Herbstein',
			'Hemdingen',
			'Hemau',
			'Heist',
			'Heimbach',
			'Heiligenroth',
			'Hayingen',
			'Hausen',
			'Harpstedt',
			'Halle',
			'Halberstadt',
			'Güstrow',
			'Groß-Umstadt',
			'Groß Rosenburg',
			'Groß-Rohrheim',
			'Großkarlbach',
			'Großbettlingen',
			'Großauheim',
			'Bad Griesbach',
			'Grafenhausen',
			'Glauburg',
			'Gingst',
			'Gimbsheim',
			'Gieboldehausen',
			'Geusa',
			'Geschwenda',
			'Gersten',
			'Germersheim',
			'Gerdau',
			'Gelting',
			'Geiselwind',
			'Gehrde',
			'Gebsattel',
			'Gars',
			'Furth',
			'Fuchsstadt',
			'Friedrichstadt',
			'Friedrichsort',
			'Friedland',
			'Framersheim',
			'Floß',
			'Feldkirchen',
			'Faulbach',
			'Ernsgaden',
			'Erlenbach am Main',
			'Erbes-Büdesheim',
			'Enkirch',
			'Elz',
			'Elbingerode',
			'Eisfeld',
			'Edersleben',
			'Eching',
			'Eberswalde',
			'Eberstadt',
			'Ebeleben',
			'Droyßig',
			'Dötlingen',
			'Dortmund',
			'Dollbergen',
			'Doberschau',
			'Dietingen',
			'Diespeck',
			'Spiegelau',
			'Schönsee',
			'Schöningen',
			'Schönau-Berzdorf',
			'Schönau am Königssee',
			'Schlüsselfeld',
			'Schernberg',
			'Sankt Martin',
			'Sankt Julian',
			'Salzkotten',
			'Rust',
			'Rothenbuch',
			'Rohrsen',
			'Rodewisch',
			'Seebergen',
			'Schulzendorf',
			'Schöneck',
			'Scheinfeld',
			'Satrup',
			'Sankt Sebastian',
			'Sankelmark',
			'Sand',
			'Rümmingen',
			'Roxheim',
			'Rohr',
			'Ziemetshausen',
			'Wörrstadt',
			'Worpswede',
			'Wölpinghausen',
			'Winklarn',
			'Weyhausen',
			'Wermelskirchen',
			'Werbach',
			'Wenden',
			'Wellen',
			'Weißenohe',
			'Weissach',
			'Weinsheim',
			'Weiden',
			'Wanna',
			'Waldkraiburg',
			'Waldböckelheim',
			'Waffenbrunn',
			'Vorwerk',
			'Vöhringen',
			'Villingen-Schwenningen',
			'Viernau',
			'Vettweiß',
			'Ustersbach',
			'Untermerzbach',
			'Unterhausen',
			'Unna',
			'Torgau',
			'Tirschenreuth',
			'Timmenrode',
			'Timmaspe',
			'Tegernheim',
			'Sulzfeld',
			'Stimpfach',
			'Sternberg',
			'Steinfeld',
			'Steinfeld',
			'Stadtsteinach',
			'Sonnen',
			'Solnhofen',
			'Sollstedt',
			'Sersheim',
			'Seebad Bansin',
			'Schwanebeck',
			'Schwaigern',
			'Schramberg',
			'Schopfloch',
			'Schneidlingen',
			'Schlehdorf',
			'Schlaitdorf',
			'Schiltach',
			'Sauensiek',
			'Sande',
			'Samswegen',
			'Salzweg',
			'Salching',
			'Saarbrücken',
			'Rudelzhausen',
			'Rottenburg',
			'Röthenbach',
			'Ronsberg',
			'Roden',
			'Rochlitz',
			'Rissen',
			'Rieste',
			'Dernbach',
			'Delbrück',
			'Dehrn',
			'Daisendorf',
			'Crimmitschau',
			'Cloppenburg',
			'Calbe',
			'Kalawa',
			'Burscheid',
			'Burgsolms',
			'Burgkirchen an der Alz',
			'Burgau',
			'Buchheim',
			'Büchen',
			'Bruckberg',
			'Bösel',
			'Bösdorf',
			'Börnichen',
			'Bobingen',
			'Bleicherode',
			'Bischofsmais',
			'Bischofsgrün',
			'Bexbach',
			'Bevenrode',
			'Betzdorf',
			'Berstadt',
			'Bergrheinfeld',
			'Berg im Gau',
			'Belm',
			'Bayrischzell',
			'Baumschulenweg',
			'Bammental',
			'Bad Schandau',
			'Bad Essen',
			'Bad Breisig',
			'Ascha',
			'Arzberg',
			'Apen',
			'Apelern',
			'Ankum',
			'Amberg',
			'Althegnenberg',
			'Alsenz',
			'Algermissen',
			'Albertstadt',
			'Weilersbach',
			'Vellmar',
			'Birken-Honigsessen',
			'Wittenberg',
			'Farmsen-Berne',
			'Dierkow-West',
			'Sülldorf',
			'Am Hagenring',
			'Rheinsberg',
			'Remlingen',
			'Rastatt',
			'Raguhn',
			'Probstzella',
			'Prettin',
			'Porta Westfalica',
			'Pölzig',
			'Plaue',
			'Pinzberg',
			'Pinnow',
			'Piding',
			'Petershausen-Ost',
			'Petershausen',
			'Peitz',
			'Pahlen',
			'Ostrach',
			'Ostelsheim',
			'Ostbevern',
			'Osdorf',
			'Orscholz',
			'Olsbrücken',
			'Ochsenhausen',
			'Oberrot',
			'Oberdorla',
			'Oberdischingen',
			'Oberbergkirchen',
			'Nohfelden',
			'Niederschöna',
			'Niederkirchen',
			'Neuötting',
			'Neunkirchen',
			'Neukloster',
			'Neuhausen',
			'Neuenkirchen',
			'Neuenhagen',
			'Neudorf-Bornstein',
			'Neubiberg',
			'Nettetal',
			'Munkbrarup',
			'Mundelsheim',
			'Münchhausen',
			'Münchenbernsdorf',
			'Mudersbach',
			'Moosinning',
			'Moorfleet',
			'Miehlen',
			'Merchweiler',
			'Mengen',
			'Maßweiler',
			'Marsberg',
			'Marl',
			'Markranstädt',
			'Marienrachdorf',
			'Manching',
			'Lieser',
			'Leer',
			'Lauenbrück',
			'Langenbernsdorf',
			'Kühndorf',
			'Krummesse',
			'Kriebitzsch',
			'Korntal',
			'Königswartha',
			'Kleinblittersdorf',
			'Kirchseelte',
			'Kirchentellinsfurt',
			'Kirchenlamitz',
			'Kempten (Allgäu)',
			'Kasel',
			'Kannawurf',
			'Kamp-Lintfort',
			'Kall',
			'Jürgenshagen',
			'Jüchsen',
			'Johannesberg',
			'Ismaning',
			'Ippesheim',
			'Huy-Neinstedt',
			'Hürth',
			'Hösbach',
			'Hondelage',
			'Holzwickede',
			'Hohenwarthe',
			'Hohenleipisch',
			'Hof',
			'Hilpoltstein',
			'Heyersum',
			'Hermsdorf',
			'Heltersberg',
			'Heidgraben',
			'Hasseldieksdamm',
			'Hallerndorf',
			'Hafenlohr',
			'Gundelfingen',
			'Groß Kreutz',
			'Großkarolinenfeld',
			'Großenaspe',
			'Gröden',
			'Greußen',
			'Gößnitz',
			'Göppingen',
			'Göda',
			'Garstedt',
			'Friedrichskoog',
			'Friedelsheim',
			'Fränkisch-Crumbach',
			'Stuttgart Feuerbach',
			'Ergolding',
			'Eppelsheim',
			'Bad Endbach',
			'Emstek',
			'Emskirchen',
			'Elsterberg',
			'Eimen',
			'Ebersdorf',
			'Ebelsbach',
			'Düngenheim',
			'Ditfurt',
			'Diez',
			'Dibbesdorf',
			'Zweiflingen',
			'Züssow',
			'Wulfsen',
			'Wolfersdorf',
			'Witzhave',
			'Wittnau',
			'Winterbach',
			'Windelsbach',
			'Wiemersdorf',
			'Weißenborn',
			'Weilmünster',
			'Weddersleben',
			'Warin',
			'Wardow',
			'Wanfried',
			'Walpertskirchen',
			'Wallmerod',
			'Wallhausen',
			'Waldkirchen',
			'Waldkirchen',
			'Wahlstedt',
			'Waging am See',
			'Wadern',
			'Unterkirnach',
			'Untereisesheim',
			'Unkel',
			'Undenheim',
			'Ulrichstein',
			'Uhyst',
			'Uehrde',
			'Uffing',
			'Tyrlaching',
			'Trippstadt',
			'Toppenstedt',
			'Tiefenort',
			'Thallwitz',
			'Thale',
			'Taura',
			'Tann',
			'Tangstedt',
			'Sulzfeld am Main',
			'Straupitz',
			'Soltau',
			'Simbach',
			'Sehlde',
			'Coswig',
			'Burghaun',
			'Burggen',
			'Büren',
			'Bunde',
			'Brennberg',
			'Bremervörde',
			'Boxberg',
			'Bogen',
			'Bodenmais',
			'Blankenheim',
			'Blankenheim',
			'Bissingen an der Teck',
			'Birenbach',
			'Biere',
			'Bienenbüttel',
			'Biebesheim',
			'Bestensee',
			'Bernried',
			'Beilstein',
			'Beilrode',
			'Behringen',
			'Barsbüttel',
			'Ballenstedt',
			'Bad Lauterberg im Harz',
			'Bad Buchau',
			'Bad Bergzabern',
			'Bachhagel',
			'Arzfeld',
			'Artern',
			'Arnschwang',
			'Ammerndorf',
			'Alpenrod',
			'Alerheim',
			'Adenbüttel',
			'Oy-Mittelberg',
			'Altes Lager',
			'Bad Peterstal-Griesbach',
			'Rödental',
			'Baar-Ebenhausen',
			'Finningen',
			'Dohren',
			'Rossau',
			'Rheinau',
			'Aachen',
			'Dittelsheim-Heßloch',
			'Mitte',
			'Schelfstadt',
			'Neu-Pattern',
			'Dierkow-Neu',
			'Altona-Altstadt',
			'Rothenburg',
			'Altstadt',
			'Riedlingen',
			'Rhinow',
			'Rhade',
			'Rethem',
			'Reinhardtsgrimma',
			'Reinberg',
			'Reichertshausen',
			'Reichersbeuern',
			'Rehling',
			'Raubling',
			'Ratzeburg',
			'Rammelsbach',
			'Rackwitz',
			'Prisdorf',
			'Pörnbach',
			'Poing',
			'Plößberg',
			'Pfungstadt',
			'Pfedelbach',
			'Pfaffenhausen',
			'Pettendorf',
			'Peißenberg',
			'Partenstein',
			'Padenstedt',
			'Otterwisch',
			'Ottensoos',
			'Ottendorf-Okrilla',
			'Ostseebad Prerow',
			'Osterode am Harz',
			'Oßmannstedt',
			'Oschatz',
			'Oppenweiler',
			'Oberreute',
			'Oberostendorf',
			'Ober-Olm',
			'Oberaudorf',
			'Nußdorf',
			'Nieder-Gründau',
			'Nieder-Florstadt',
			'Neuland',
			'Neuhaus an der Pegnitz',
			'Neuenbürg',
			'Neuburg an der Donau',
			'Neubörger',
			'Nattheim',
			'Müggelheim',
			'Moorrege',
			'Mönkeberg',
			'Mönchberg',
			'Mickhausen',
			'Mesekenhagen',
			'Mertingen',
			'Meitzendorf',
			'Meersburg',
			'Maulbronn',
			'Massing',
			'Märkisches Viertel',
			'Marienberg',
			'Malsfeld',
			'Malchow',
			'Mainaschaff',
			'Mahlberg',
			'Ludwigsstadt',
			'Longkamp',
			'Lindhorst',
			'Lindau',
			'Lichtenstein',
			'Lengenfeld',
			'Lehmen',
			'Lauf an der Pegnitz',
			'Landsberied',
			'Lalendorf',
			'Lägerdorf',
			'Kümmersbruck',
			'Kronshagen',
			'Kriftel',
			'Kraiburg am Inn',
			'Knittelsheim',
			'Klein Schwülper',
			'Kleinlangheim',
			'Kirchweidach',
			'Kirchlengern',
			'Kavelstorf',
			'Isernhagen Farster Bauerschaft',
			'Hünxe',
			'Horst',
			'Horneburg',
			'Homburg',
			'Holzgerlingen',
			'Holzdorf',
			'Hohenkammer',
			'Hofkirchen',
			'Hirschhorn',
			'Heyerode',
			'Herschdorf',
			'Herrsching am Ammersee',
			'Heringen',
			'Hemmoor',
			'Helpsen',
			'Heimsheim',
			'Heilbad Heiligenstadt',
			'Hawangen',
			'Haßmersheim',
			'Hameln',
			'Haltern am See',
			'Halblech',
			'Haina',
			'Haimhausen',
			'Haibach',
			'Hagen',
			'Gundremmingen',
			'Guben',
			'Großniedesheim',
			'Großhansdorf',
			'Großbodungen',
			'Gronau',
			'Greven',
			'Grebenstein',
			'Geisenhausen',
			'Gangelt',
			'Friedrichsfelde',
			'Freren',
			'Frankleben',
			'Falkenstein',
			'Erwitte',
			'Eppendorf',
			'Markt Einersheim',
			'Dreis',
			'Dörpen',
			'Dornhan',
			'Dittenheim',
			'Dippoldiswalde',
			'Dinklage',
			'Seebad Heringsdorf',
			'Sebnitz',
			'Schwarzheide',
			'Schwalbach am Taunus',
			'Schutterwald',
			'Schopfheim',
			'Schonstett',
			'Schleiden',
			'Schorndorf',
			'Schönwalde am Bungsberg',
			'Schönhausen',
			'Schöllkrippen',
			'Schobüll',
			'Bad Saulgau',
			'Sandersdorf',
			'Runkel',
			'Rott',
			'Zscherben',
			'Zeuthen',
			'Zehdenick',
			'Wrestedt',
			'Wolfschlugen',
			'Wittislingen',
			'Wittenhagen',
			'Winden',
			'Wilster',
			'Wilgartswiesen',
			'Wiesentheid',
			'Wiedensahl',
			'Westhausen',
			'Werneck',
			'Werder',
			'Weng',
			'Weißensee',
			'Weißenberg',
			'Weinsberg',
			'Weilheim',
			'Weidhausen bei Coburg',
			'Weida',
			'Wedding',
			'Wannweil',
			'Walldorf',
			'Waldkirch',
			'Waldeck',
			'Waidhaus',
			'Wacken',
			'Volksdorf',
			'Vöhl',
			'Vacha',
			'Uettingen',
			'Udenheim',
			'Trochtelfingen',
			'Trebsen',
			'Trappstadt',
			'Trabitz',
			'Torgelow',
			'Tönning',
			'Tiefenbach',
			'Theisseil',
			'Thedinghausen',
			'Thalmässing',
			'Tecklenburg',
			'Taufkirchen',
			'Sustrum',
			'Süplingen',
			'Südlohn',
			'Strande',
			'Stoßdorf',
			'Stödtlen',
			'Stöckse',
			'Steinhöring',
			'Steinheim am Albuch',
			'Staufen',
			'Stammham',
			'Sommerhausen',
			'Sölden',
			'Sohland',
			'Sinzing',
			'Simmelsdorf',
			'Sielenbach',
			'Seggebruch',
			'Schwerin',
			'Schwabenheim',
			'Schongau',
			'Schönau',
			'Schnelsen',
			'Schaafheim',
			'Sankt Wolfgang',
			'Sankt Wendel',
			'Sankt Georgen im Schwarzwald',
			'Sankt Egidien',
			'Roßdorf',
			'Rosenthal',
			'Rockenhausen',
			'Dannenberg',
			'Dachsenhausen',
			'Burg bei Magdeburg',
			'Buchen in Odenwald',
			'Breitenburg',
			'Braunsbach',
			'Bous',
			'Bötzingen',
			'Boll',
			'Bockenheim',
			'Bochum',
			'Blumberg',
			'Blieskastel',
			'Bischofferode',
			'Birstein',
			'Bippen',
			'Biesenthal',
			'Biebelried',
			'Berlin',
			'Bayerisch Eisenstein',
			'Baesweiler',
			'Bad Säckingen',
			'Bad Nauheim',
			'Backnang',
			'Aying',
			'Auma',
			'Auhagen',
			'Asendorf',
			'Asendorf',
			'Ascheberg',
			'Bad Arolsen',
			'Altdorf',
			'Allersberg',
			'Ainring',
			'Aidhausen',
			'Hattert',
			'Aukrug',
			'Salem',
			'Neunkirchen am Brand',
			'Neustadt an der Orla',
			'Hamburg-Nord',
			'Hohetor',
			'Brachenfeld-Ruthenberg',
			'Ortenberg',
			'Opfenbach',
			'Ohmden',
			'Oettingen in Bayern',
			'Oberwiera',
			'Oberstenfeld',
			'Obermarchtal',
			'Oberkotzau',
			'Nürnberg',
			'Nostorf',
			'Nördlingen',
			'Nittenau',
			'Nienstedten',
			'Niendorf',
			'Niederdorla',
			'Neustadt Vogtland',
			'Neulußheim',
			'Nesselwang',
			'Muschwitz',
			'Mülsen',
			'Mötzingen',
			'Motten',
			'Mönchsroth',
			'Mögglingen',
			'Mickten',
			'Messingen',
			'Messel',
			'Menden',
			'Meinerzhagen',
			'Meinersen',
			'Meckesheim',
			'Marktleugast',
			'Marklkofen',
			'Markdorf',
			'Malterdingen',
			'Lupburg',
			'Lünen',
			'Lommatzsch',
			'Litzelstetten',
			'Limbach-Oberfrohna',
			'Limbach',
			'Leisnig',
			'Leipheim',
			'Leinzell',
			'Lehrensteinsfeld',
			'Legau',
			'Lechbruck',
			'Laufdorf',
			'Langsur',
			'Langenorla',
			'Langendernbach',
			'Landesbergen',
			'Landau in der Pfalz',
			'Laboe',
			'Könitz',
			'Königsbrück',
			'Köln',
			'Kolbingen',
			'Kirschau',
			'Kirchlauter',
			'Eiselfing',
			'Kenzingen',
			'Kamen',
			'Jöhlingen',
			'Irlbach',
			'Inning am Ammersee',
			'Igensdorf',
			'Hundsangen',
			'Hoisdorf',
			'Hohenmölsen',
			'Hilgermissen',
			'Hermersberg',
			'Heide',
			'Haverlah',
			'Hausen',
			'Haselünne',
			'Hartenstein',
			'Hargesheim',
			'Hammerbrücke',
			'Altona',
			'Gütenbach',
			'Gundelfingen',
			'Grünkraut',
			'Großrudestedt',
			'Großörner',
			'Groß Kiesow',
			'Großhartmannsdorf',
			'Gornsdorf',
			'Goldenstedt',
			'Gersdorf',
			'Gelsenkirchen',
			'Freudenstadt',
			'Forst',
			'Fichtelberg',
			'Fehrbellin',
			'Falkensee',
			'Euerdorf',
			'Etzenricht',
			'Eschenlohe',
			'Erlangen',
			'Ennetach',
			'Bad Endorf',
			'Emden',
			'Ebringen',
			'Dunum',
			'Duisburg',
			'Dreetz',
			'Dorfen',
			'Donaustauf',
			'Dillingen',
			'Dietramszell',
			'Dettingen',
			'Rhumspringe',
			'Reuth',
			'Rettenberg',
			'Reichenbach an der Fils',
			'Reichenbach',
			'Reichelsheim',
			'Regensburg',
			'Rechenberg-Bienenmühle',
			'Ravensburg',
			'Rahlstedt',
			'Puschwitz',
			'Pölitz',
			'Pilsting',
			'Petersdorf',
			'Panschwitz-Kuckau',
			'Painten',
			'Ottersberg',
			'Östringen',
			'Osternienburg',
			'Derenburg',
			'Deinste',
			'Dankmarshausen',
			'Callenberg',
			'Burkhardtsdorf',
			'Burgbrohl',
			'Bruck',
			'Breitenfelde',
			'Bondorf',
			'Bodnegg',
			'Böbrach',
			'Billigheim',
			'Bickenbach',
			'Biberach an der Riß',
			'Berthelsdorf',
			'Bernbeuren',
			'Bergatreute',
			'Bellenberg',
			'Bad Sulza',
			'Bad Meinberg',
			'Bad Hersfeld',
			'Bad Feilnbach',
			'Augsburg',
			'Au am Rhein',
			'Asbach',
			'Artlenburg',
			'Alt Wallmoden',
			'Altmittweida',
			'Alt Meteln',
			'Altendiez',
			'Altenau',
			'Schönenberg-Kübelberg',
			'Kasbach-Ohlenberg',
			'Sankt Katharinen',
			'Klein Offenseth-Sparrieshoop',
			'Leingarten',
			'Mespelbrunn',
			'Altona-Nord',
			'Paradies',
			'Petershausen-West',
			'Altes Hochschulviertel',
			'Weinberg',
			'Wilhelmitor - Süd',
			'Zscherben',
			'Zschepplin',
			'Zimmern ob Rottweil',
			'Ziltendorf',
			'Zarrentin',
			'Wurzbach',
			'Worbis',
			'Wolpertshausen',
			'Wolframs-Eschenbach',
			'Woldegk',
			'Willingshausen',
			'Wildemann',
			'Wietzen',
			'Wetschen',
			'Westensee',
			'Wendelsheim',
			'Weisel',
			'Weinstadt-Endersbach',
			'Weilheim an der Teck',
			'Wehringen',
			'Weeze',
			'Waxweiler',
			'Wallgau',
			'Waldenburg',
			'Wachtberg',
			'Vrees',
			'Voltlage',
			'Vöhrenbach',
			'Unterdietfurt',
			'Triberg',
			'Treuen',
			'Tornesch',
			'Tittmoning',
			'Thomm',
			'Thiersheim',
			'Thanstein',
			'Tagmersheim',
			'Süstedt',
			'Surberg',
			'Stolberg',
			'Steinheim an der Murr',
			'Steinheid',
			'Sohland am Rotstein',
			'Sittensen',
			'Sinsheim',
			'Seehausen am Staffelsee',
			'Reisbach',
			'Reil',
			'Rechtenbach',
			'Rangsdorf',
			'Puschendorf',
			'Preetz',
			'Potsdam',
			'Pluwig',
			'Plön',
			'Plate',
			'Petershagen',
			'Pankow',
			'Ostritz',
			'Oßling',
			'Oerel',
			'Ohlsbach',
			'Obing',
			'Oberursel',
			'Oberreichenbach',
			'Oberpframmern',
			'Obermoschel',
			'Obermehler',
			'Nottuln',
			'Nordhausen',
			'Norderney',
			'Niemberg',
			'Niederschöneweide',
			'Niederbiel',
			'Neustadt bei Coburg',
			'Neustadt',
			'Neusitz',
			'Neukirchen-Balbini',
			'Neukirch/Lausitz',
			'Neuffen',
			'Neuenstadt am Kocher',
			'Neuenburg am Rhein',
			'Nentershausen',
			'Nebra',
			'Naunhof',
			'Nandlstadt',
			'Mutterstadt',
			'Münster',
			'Münchweiler an der Rodalb',
			'Stuttgart Mühlhausen',
			'Mühldorf',
			'Mönchweiler',
			'Möhrendorf',
			'Mogendorf',
			'Mixdorf',
			'Mittenaar',
			'Mittelhof',
			'Mettendorf',
			'Mengersgereuth-Hämmern',
			'Melchow',
			'Mehrhoog',
			'Markt Schwaben',
			'Markt Indersdorf',
			'Mainzweiler',
			'Mainz',
			'Lustadt',
			'Lunzenau',
			'Lübbecke',
			'Lorsch',
			'Loffenau',
			'Lienen',
			'Lichtenrade',
			'Laudenbach',
			'Lauchröden',
			'Lamspringe',
			'Lambsheim',
			'Ladelund',
			'Kusel',
			'Kumhausen',
			'Kronberg',
			'Königstein',
			'Kollmar',
			'Kleinkahl',
			'Kirchheim',
			'Kirchgellersen',
			'Kirchdorf am Inn',
			'Kippenheim',
			'Kindenheim',
			'Kienberg',
			'Kalefeld',
			'Immenstadt im Allgäu',
			'Illertissen',
			'Ilberstedt',
			'Ifta',
			'Hülben',
			'Holtsee',
			'Höhnstedt',
			'Hohenwestedt',
			'Hochdorf',
			'Hetzles',
			'Heretsried',
			'Herbsleben',
			'Hemslingen',
			'Heimenkirch',
			'Heilbronn',
			'Hassendorf',
			'Haselhorst',
			'Hanstedt Eins',
			'Hamminkeln',
			'Hamm (Sieg)',
			'Hamburg',
			'Hambühren',
			'Halle',
			'Gutenstetten',
			'Gundelsheim',
			'Gudensberg',
			'Grünheide',
			'Groß Quenstedt',
			'Großostheim',
			'Groß Nemerow',
			'Großlittgen',
			'Gröningen',
			'Grömitz',
			'Grävenwiesbach',
			'Grainau',
			'Glowe',
			'Glött',
			'Gerswalde',
			'Geroldsgrün',
			'Gernrode',
			'Gablingen',
			'Friedrichsdorf',
			'Friedewald',
			'Franzburg',
			'Frankenthal',
			'Falkenberg',
			'Eutingen an der Enz',
			'Eppingen',
			'Engelthal',
			'Engelsbrand',
			'Empfingen',
			'Ellwangen',
			'Ellgau',
			'Ebersbach',
			'Dorsten',
			'Dogern',
			'Dettingen an der Erms',
			'Schweinfurt',
			'Schuby',
			'Ruppichteroth',
			'Rohrenfels',
			'Roding',
			'Rimpar',
			'Schüttorf',
			'Schashagen',
			'Rügland',
			'Roth',
			'Ringsheim',
			'Rietberg',
			'Riepsdorf',
			'Damp',
			'Crostwitz',
			'Crinitz',
			'Claußnitz',
			'Buttenwiesen',
			'Büttelborn',
			'Burladingen',
			'Burgebrach',
			'Buch am Ahorn',
			'Bubsheim',
			'Bruckberg',
			'Brieselang',
			'Braunfels',
			'Brannenburg',
			'Brackel',
			'Börtlingen',
			'Bohmte',
			'Bodenwerder',
			'Bobbau',
			'Blindheim',
			'Blankenese',
			'Bilshausen',
			'Billwerder',
			'Bichl',
			'Bergstedt',
			'Berglern',
			'Berching',
			'Benshausen',
			'Bendorf',
			'Beiersdorf',
			'Bardowick',
			'Bad Reichenhall',
			'Bad Muskau',
			'Bad Driburg',
			'Aulendorf',
			'Attendorn',
			'Altkirchen',
			'Altenthann',
			'Altenmarkt',
			'Altenbeken',
			'Adorf',
			'Adendorf',
			'Abbesbüttel',
			'Bodman-Ludwigshafen',
			'Schieder-Schwalenberg',
			'Medlingen',
			'Wörth',
			'Jarplund-Weding',
			'Charlottenburg-Nord',
			'Altstadt Sud',
			'Zusmarshausen',
			'Zossen',
			'Zielitz',
			'Zella-Mehlis',
			'Wörth am Main',
			'Wolfratshausen',
			'Wolfertschwenden',
			'Wiednitz',
			'Werne',
			'Werben',
			'Wendisch Rietz',
			'Weiten',
			'Weißenthurm',
			'Wathlingen',
			'Watenbüttel',
			'Wasungen',
			'Wasbüttel',
			'Wallhausen',
			'Waake',
			'Vilsheim',
			'Vienenburg',
			'Velpke',
			'Vellberg',
			'Vegesack',
			'Veckenstedt',
			'Uttenweiler',
			'Untrasried',
			'Ulmen',
			'Uetze',
			'Tutzing',
			'Tüßling',
			'Tröstau',
			'Trittenheim',
			'Treuchtlingen',
			'Trautskirchen',
			'Tönisvorst',
			'Tengen',
			'Sulingen',
			'Stötten am Auerberg',
			'Steinach',
			'Sprakensehl',
			'Speyer',
			'Spaichingen',
			'Sögel',
			'Siggelkow',
			'Sickenhausen',
			'Sibbesse',
			'Rieden an der Kötz',
			'Rieden',
			'Recklinghausen',
			'Rathenow',
			'Rasdorf',
			'Rammenau',
			'Ralingen',
			'Raddestorf',
			'Rabenau',
			'Pressath',
			'Pöttmes',
			'Pottenstein',
			'Postau',
			'Poppenhausen',
			'Pirna',
			'Pforzen',
			'Pelm',
			'Peiting',
			'Parsau',
			'Oeversee',
			'Ötigheim',
			'Osterspai',
			'Orlamünde',
			'Oberwesel',
			'Obertshausen',
			'Oberstadion',
			'Oberschwarzach',
			'Obernheim-Kirchenarnbach',
			'Oberlichtenau',
			'Oberhausen',
			'Oberasbach',
			'Niederstotzingen',
			'Niederorschel',
			'Niederkassel',
			'Niederfell',
			'Nickenich',
			'Neuwied',
			'Neusalza-Spremberg',
			'Neuhütten',
			'Neuhofen',
			'Neugraben-Fischbek',
			'Neubrück',
			'Neubrandenburg',
			'Nennslingen',
			'Neckarbischofsheim',
			'Namborn',
			'Naila',
			'Nachterstedt',
			'Munich',
			'Mötzing',
			'Morsum',
			'Moosburg',
			'Miesbach',
			'Menteroda',
			'Mehlbach',
			'Marnheim',
			'Luhden',
			'Lüdersdorf',
			'Lichtenberg',
			'Leutenbach',
			'Lentföhrden',
			'Lennestadt',
			'Leißling',
			'Lankow',
			'Langeoog',
			'Langenneufnach',
			'Lamme',
			'Laichingen',
			'Kurort Oberwiesenthal',
			'Körperich',
			'Konradsreuth',
			'Könnern',
			'Königsfeld',
			'Kolitzheim',
			'Klingenthal',
			'Kirn',
			'Kerpen',
			'Kellmünz',
			'Kastl',
			'Kastel',
			'Karlsruhe',
			'Kamenz',
			'Kalchreuth',
			'Kalbe',
			'Jöhstadt',
			'Jesewitz',
			'Jerichow',
			'Jarmen',
			'Hüttenrode',
			'Hüfingen',
			'Hoyerswerda',
			'Hornhausen',
			'Hornberg',
			'Horn',
			'Horgenzell',
			'Holzminden',
			'Holzhausen an der Haide',
			'Holzappel',
			'Hohberg',
			'Heßheim',
			'Herrischried',
			'Hemer',
			'Heiningen',
			'Heiligensee',
			'Haßleben',
			'Hassel',
			'Harzgerode',
			'Hanerau-Hademarschen',
			'Handewitt',
			'Hambergen',
			'Gundersheim',
			'Gückingen',
			'Groß Pankow',
			'Groß Borstel',
			'Grafing bei München',
			'Grabenstätt',
			'Görisried',
			'Göggingen',
			'Glückstadt',
			'Glonn',
			'Geyer',
			'Gersthofen',
			'Georgenberg',
			'Gauting',
			'Furth im Wald',
			'Fuhlsbüttel',
			'Friedenfels',
			'Freudenburg',
			'Fraureuth',
			'Frauenneuharting',
			'Fockbek',
			'Falkenstein',
			'Falkenhain',
			'Ersingen',
			'Erpel',
			'Erolzheim',
			'Eppelborn',
			'Emmerich',
			'Embsen',
			'Einhausen',
			'Egeln',
			'Effelder',
			'Edemissen',
			'Edderitz',
			'Ebersbach an der Fils',
			'Dürrröhrsdorf',
			'Dudeldorf',
			'Dotternhausen',
			'Dohna',
			'Dingelstädt',
			'Dettingen',
			'Seedorf',
			'Schrecksbach',
			'Schmiechen',
			'Schleid',
			'Schapen',
			'Salzgitter',
			'Sachsenhausen',
			'Saarburg',
			'Ruderatshofen',
			'Schondra',
			'Schmöckwitz',
			'Schmalfeld',
			'Scheuring',
			'Rötha',
			'Riestedt',
			'Zwingenberg',
			'Zolling',
			'Zöblitz',
			'Wutöschingen',
			'Wunstorf',
			'Wulften',
			'Wülfrath',
			'Wittenberge',
			'Windeby',
			'Wiesen',
			'Westerrönfeld',
			'Westerburg',
			'Wertheim',
			'Welzheim',
			'Welsleben',
			'Wallertheim',
			'Walkertshofen',
			'Voigtstedt',
			'Vogtareuth',
			'Vierkirchen',
			'Viereck',
			'Velgast',
			'Velbert',
			'Urbach-Überdorf',
			'Unterwössen',
			'Unterkrozingen',
			'Treuenbrietzen',
			'Tollwitz',
			'Thierhaupten',
			'Teuchel',
			'Teterow',
			'Tespe',
			'Süßen',
			'Sulzbach am Main',
			'Stolzenau',
			'Sprendlingen',
			'Spiesen-Elversberg',
			'Sommersdorf',
			'Soest',
			'Siegenburg',
			'Schwanewede',
			'Schwabach',
			'Schotten',
			'Schopfloch',
			'Schleswig',
			'Schildau',
			'Schellhorn',
			'Sauldorf',
			'Sasel',
			'Saal',
			'Rosenfeld',
			'Romrod',
			'Roitzsch',
			'Rodenbach',
			'Rockenberg',
			'Demmin',
			'Deisenhausen',
			'Deining',
			'Dabel',
			'Clenze',
			'Calden',
			'Buxtehude',
			'Bützow',
			'Büttstedt',
			'Bürgel',
			'Brekendorf',
			'Brauneberg',
			'Bosau',
			'Borstendorf',
			'Borkum',
			'Borkheide',
			'Bocholt',
			'Bobitz',
			'Bitz',
			'Bischofswiesen',
			'Biessenhofen',
			'Berkum',
			'Bergisch Gladbach',
			'Beedenbostel',
			'Bayerisch Gmain',
			'Baruth',
			'Barum',
			'Bamenohl',
			'Bad Rothenfelde',
			'Bad Pyrmont',
			'Bad Klosterlausnitz',
			'Auerbach',
			'Au',
			'Annaberg-Buchholz',
			'Altenglan',
			'Alsterdorf',
			'Allershausen',
			'Aindling',
			'Ahrensburg',
			'Adelzhausen',
			'Adelsheim',
			'Ühlingen-Birkendorf',
			'Schönstedt',
			'Osann-Monzel',
			'Mohrkirch',
			'Risum-Lindholm',
			'Ostfildern',
			'Seeheim-Jugenheim',
			'Hagen',
			'Auf der Horst',
			'Alt - Lehndorf',
			'Rieden',
			'Rhede',
			'Rettenbach',
			'Rentweinsdorf',
			'Renningen',
			'Remse',
			'Reichenwalde',
			'Reichenbach',
			'Raubach',
			'Ramsthal',
			'Ramstein-Miesenbach',
			'Prackenbach',
			'Pleinfeld',
			'Pleidelsheim',
			'Petershagen',
			'Perlesreut',
			'Peißen',
			'Paulinenaue',
			'Owschlag',
			'Owingen',
			'Ottersweier',
			'Otter',
			'Ostseebad Kühlungsborn',
			'Ostheim',
			'Offingen',
			'Offenbach an der Queich',
			'Oelsnitz',
			'Obertraubling',
			'Obersontheim',
			'Oberding',
			'Norheim',
			'Nienburg/Saale',
			'Niedertaufkirchen',
			'Niedenstein',
			'Neu-Ulm',
			'Neuschönau',
			'Neuharlingersiel',
			'Mühlhausen',
			'Moringen',
			'Mönchenholzhausen',
			'Mertesdorf',
			'Merkendorf',
			'Merenberg',
			'Mannheim',
			'Mamming',
			'Malschwitz',
			'Malsch',
			'Lohberg',
			'Bad Lobenstein',
			'Lieskau',
			'Leutkirch',
			'Langula',
			'Langenhagen',
			'Langballig',
			'Bad Laer',
			'Kutzenhausen',
			'Kluse',
			'Klein Nordende',
			'Kirchberg',
			'Kelsterbach',
			'Keitum',
			'Karbach',
			'Kakenstorf',
			'Kaarst',
			'Illschwang',
			'Igersheim',
			'Iffezheim',
			'Holzmaden',
			'Holm',
			'Hollstadt',
			'Hohenlinden',
			'Hofstetten',
			'Himmelkron',
			'Hilders',
			'Hilchenbach',
			'Hiddenhausen',
			'Hesel',
			'Hermannsburg',
			'Hennef (Sieg)',
			'Hemsbach',
			'Heinsberg',
			'Havelberg',
			'Harsdorf',
			'Haren',
			'Harbke',
			'Hamberge',
			'Hallstadt',
			'Haina',
			'Haiger',
			'Hadmersleben',
			'Haan',
			'Gutenberg',
			'Gstadt am Chiemsee',
			'Großthiemig',
			'Großschönau',
			'Großschirma',
			'Groß Rheide',
			'Großlehna',
			'Großheirath',
			'Groß-Bieberau',
			'Groitzsch',
			'Grimma',
			'Gramkow',
			'Grafenrheinfeld',
			'Gräfelfing',
			'Gotteszell',
			'Gorbitz',
			'Gesundbrunnen',
			'Gersdorf',
			'Gatersleben',
			'Füssen',
			'Fuldatal',
			'Friesenhagen',
			'Freiensteinau',
			'Freiburg',
			'Frankenberg',
			'Flörsheim',
			'Flammersfeld',
			'Eystrup',
			'Estorf',
			'Erndtebrück',
			'Erbach',
			'Eppertshausen',
			'Ennigerloh',
			'Elmenhorst',
			'Eichstetten',
			'Effeltrich',
			'Edenkoben',
			'Durmersheim',
			'Dümmer',
			'Ducherow',
			'Drebach',
			'Dittelbrunn',
			'Würzburg',
			'Wrist',
			'Woltersdorf',
			'Wohldorf-Ohlstedt',
			'Wittenförden',
			'Willich',
			'Wiggensbach',
			'Wiedergeltingen',
			'Westerstetten',
			'Wester-Ohrstedt',
			'Wendeburg',
			'Weißkeißel',
			'Weißenstadt',
			'Weihmichl',
			'Weiherhammer',
			'Wandlitz',
			'Walsdorf',
			'Wald-Michelbach',
			'Waldheim',
			'Waldershof',
			'Wabern',
			'Vorra',
			'Volkach',
			'Viechtach',
			'Vettelschoß',
			'Verden',
			'Venusberg',
			'Velburg',
			'Unterbreizbach',
			'Ulm',
			'Trendelburg',
			'Treffelstein',
			'Töpen',
			'Thaleischweiler-Fröschen',
			'Tauche',
			'Sulzdorf',
			'Sulzberg',
			'Sulzbach an der Murr',
			'Strullendorf',
			'Stromberg',
			'Stendal',
			'Stellingen',
			'Steinhöfel',
			'Steilshoop',
			'Staad',
			'Spornitz',
			'Sperenberg',
			'Spangenberg',
			'Soderstorf',
			'Sindelsdorf',
			'Sillenstede',
			'Seeburg',
			'Bad Schussenried',
			'Schöngeising',
			'Schmelz',
			'Schmallenberg',
			'Schlitz',
			'Schlierbach',
			'Schleife',
			'Schkopau',
			'Schipkau',
			'Schierstein',
			'Schierling',
			'Schenkenzell',
			'Scheidegg',
			'Sassenberg',
			'Salz',
			'Rullstorf',
			'Rüdnitz',
			'Rüdesheim am Rhein',
			'Roßhaupten',
			'Rosenberg',
			'Röhrnbach',
			'Dargun',
			'Chorin',
			'Cappeln',
			'Bühl',
			'Buchbrunn',
			'Bruchköbel',
			'Brietlingen',
			'Briesen',
			'Brensbach',
			'Breitbrunn',
			'Braschwitz',
			'Bramstedt',
			'Borgstedt',
			'Boppard',
			'Bonn',
			'Bockhorst',
			'Blekendorf',
			'Bersenbrück',
			'Beckdorf',
			'Bartholomä',
			'Barth',
			'Balzhausen',
			'Bad Wildungen',
			'Bad Steben',
			'Bad Homburg vor der Höhe',
			'Badem',
			'Auerbach',
			'Arberg',
			'Appel',
			'Apollensdorf',
			'Amberg',
			'Aitrang',
			'Aichach',
			'Ahlbeck',
			'Aglasterhausen',
			'Adelmannsfelden',
			'Böhl-Iggelheim',
			'Hochdorf-Assenheim',
			'Bochum-Hordel',
			'Eidelstedt',
			'Rabenau',
			'Kleiner Grasbrook',
			'Waidmannslust',
			'Friedland',
			'Röchling-Höhe',
			'Rheinfelden',
			'Reppenstedt',
			'Reichertsheim',
			'Redwitz an der Rodach',
			'Rattenberg',
			'Rath',
			'Pressig',
			'Pfullingen',
			'Pforzheim',
			'Petersaurach',
			'Osdorf',
			'Orenhofen',
			'Oldenswort',
			'Öhningen',
			'Odernheim',
			'Oberthal',
			'Oberrieden',
			'Nierstein',
			'Nieheim',
			'Niederfüllbach',
			'Niederfischbach',
			'Niederaula',
			'Neustadt an der Donau',
			'Neukalen',
			'Neufahrn',
			'Neuenkirchen',
			'Neuenkirchen',
			'Neuenkirchen',
			'Neu Darchau',
			'Neubulach',
			'Nettersheim',
			'Nastätten',
			'Muldenstein',
			'Moorenweis',
			'Monzelfeld',
			'Mönchengladbach',
			'Möllenhagen',
			'Mehring',
			'Mehlmeisel',
			'Mauerstetten',
			'Malborn',
			'Lüder',
			'Lindow',
			'Limburgerhof',
			'Liebenwalde',
			'Leuben',
			'Langerringen',
			'Langenaltheim',
			'Lam',
			'Laer',
			'Kuchen',
			'Kranichfeld',
			'Kramerhof',
			'Konradshöhe',
			'Königstein im Taunus',
			'Kleinmaischeid',
			'Kirkel',
			'Kirchzarten',
			'Kerzenheim',
			'Kellinghusen',
			'Kelberg',
			'Kayhude',
			'Kappelrodeck',
			'Kamsdorf',
			'Kahla',
			'Jugenheim',
			'Isselburg',
			'Isenbüttel',
			'Ingersleben',
			'Hutthurm',
			'Hummelsbüttel',
			'Huglfing',
			'Hüde',
			'Hörden',
			'Hoogstede',
			'Homberg',
			'Hohen Neuendorf',
			'Hirschfeld',
			'Heudeber',
			'Heuchlingen',
			'Hetzerath',
			'Herne',
			'Heiligenhaus',
			'Heeßen',
			'Hausen',
			'Haste',
			'Hasloch',
			'Harthausen',
			'Hammelburg',
			'Hamburg-Mitte',
			'Hahnstätten',
			'Gruibingen',
			'Großröhrsdorf',
			'Großpösna',
			'Grettstadt',
			'Grebenau',
			'Granschütz',
			'Grafrath',
			'Goßwitz',
			'Göhl',
			'Gnutz',
			'Gnarrenburg',
			'Gerolsbach',
			'Georgenthal',
			'Geislingen an der Steige',
			'Geisingen',
			'Geisenheim',
			'Gehrden',
			'Gangkofen',
			'Gaiberg',
			'Fürth',
			'Friedrichshain',
			'Friedersdorf',
			'Friedberg',
			'Freudental',
			'Freisen',
			'Frankfurt am Main',
			'Frankenstein',
			'Föhren',
			'Flieden',
			'Fleckeby',
			'Feilitzsch',
			'Fambach',
			'Falkenberg',
			'Fahrenbach',
			'Essenbach',
			'Erlensee',
			'Emtmannsberg',
			'Elterlein',
			'Elstra',
			'Elmshorn',
			'Eldingen',
			'Eislingen',
			'Eisingen',
			'Eimke',
			'Eberhardzell',
			'Dußlingen',
			'Donzdorf',
			'Döllstädt',
			'Döbernitz',
			'Diekholzen',
			'Zwönitz',
			'Zschadrass',
			'Zeilarn',
			'Wülfershausen',
			'Wössingen',
			'Worms',
			'Wonsees',
			'Wolmirsleben',
			'Wistedt',
			'Windach',
			'Wiltingen',
			'Wiehl',
			'Westendorf',
			'Westendorf',
			'Werther',
			'Weitefeld',
			'Weisenbach',
			'Weilerbach',
			'Wandersleben',
			'Wallmenroth',
			'Waldfischbach-Burgalben',
			'Wain',
			'Wachenheim',
			'Voerde',
			'Verl',
			'Varel',
			'Vaihingen an der Enz',
			'Uslar',
			'Ummendorf',
			'Uhingen',
			'Trinwillershagen',
			'Trebendorf',
			'Todtenweis',
			'Titisee-Neustadt',
			'Taufkirchen',
			'Tangermünde',
			'Stulln',
			'Straßberg',
			'Stralendorf',
			'Storkow',
			'Stockelsdorf',
			'Stockach',
			'Steinen',
			'Steinbergkirche',
			'Steinach',
			'Steimel',
			'Stauchitz',
			'Soyen',
			'Bad Sobernheim',
			'Sinnersdorf',
			'Seibersbach',
			'Dedelstorf',
			'Darlingerode',
			'Clausen',
			'Büsingen',
			'Burgrieden',
			'Burgheim',
			'Burg',
			'Brunn',
			'Brunn',
			'Briedel',
			'Bretzfeld',
			'Breitenberg',
			'Brand',
			'Bötersen',
			'Bornheim',
			'Bodenfelde',
			'Bissingen',
			'Bischofswerda',
			'Beuern',
			'Bedburg',
			'Bechhofen',
			'Barsinghausen',
			'Barmstedt',
			'Bakum',
			'Baiersdorf',
			'Bad Wiessee',
			'Bad Salzungen',
			'Bad Rappenau',
			'Bad Münstereifel',
			'Bad Lippspringe',
			'Bad Düben',
			'Auerbach',
			'Aßling',
			'Aschau am Inn',
			'Antdorf',
			'Anklam',
			'Ampfing',
			'Amerang',
			'Hamburg-Altstadt',
			'Altenpleen',
			'Altenkirchen',
			'Alteglofsheim',
			'Alt Duvenstedt',
			'Aichen',
			'Affing',
			'Erdeborn',
			'Bruttig-Fankel',
			'Elbe',
			'Ubstadt-Weiher',
			'Niederrad',
			'Garbsen-Mitte',
			'Burglesum',
			'Schuntersdlg - West',
			'Petritor - West',
			'Rieder',
			'Rheine',
			'Rengsdorf',
			'Reinhausen',
			'Reichelsheim',
			'Rechtmehring',
			'Rangendingen',
			'Quedlinburg',
			'Preußisch Oldendorf',
			'Porz am Rhein',
			'Pleystein',
			'Pfeffenhausen',
			'Pfakofen',
			'Penzberg',
			'Pastetten',
			'Papenburg',
			'Oppenau',
			'Oldendorf',
			'Ofterschwang',
			'Ockenfels',
			'Oberschöneweide',
			'Obernheim',
			'Oberndorf',
			'Oberhaching',
			'Oberboihingen',
			'Nußdorf am Inn',
			'Nohra',
			'Nöbdenitz',
			'Nittel',
			'Niederstetten',
			'Niedereschach',
			'Niederelbert',
			'Niederalteich',
			'Newel',
			'Neutraubling',
			'Neustadt am Rübenberge',
			'Neue Neustadt',
			'Neukirch',
			'Neuensalz',
			'Neuenkirchen',
			'Neckartailfingen',
			'Möttingen',
			'Morsbach',
			'Monheim',
			'Moers',
			'Mitterteich',
			'Meyenburg',
			'Mettlach',
			'Merzalben',
			'Mertloch',
			'Mehrstetten',
			'Mehltheuer',
			'Meerdorf',
			'Meckenheim',
			'Meckenheim',
			'Mauern',
			'Marpingen',
			'Marktheidenfeld',
			'Marklohe',
			'Mariendorf',
			'Mammelzen',
			'Mainstockheim',
			'Lüchow',
			'Lohmar',
			'Löbnitz',
			'Liebstadt',
			'Lehe',
			'Lebus',
			'Lautenbach',
			'Laußnitz',
			'Langen',
			'Kurort Oybin',
			'Kriebstein',
			'Kremmen',
			'Kreiensen',
			'Klotten',
			'Klein Berßen',
			'Klausdorf',
			'Karstädt',
			'Karsbach',
			'Bad Karlshafen',
			'Jungingen',
			'Johanngeorgenstadt',
			'Isny',
			'Insheim',
			'Hückelhoven',
			'Höxter',
			'Bad Holzhausen',
			'Holle',
			'Hohenlockstedt',
			'Hofheim am Taunus',
			'Hochstadt am Main',
			'Höchheim',
			'Hochfeld',
			'Hennweiler',
			'Hemmingstedt',
			'Heek',
			'Hausham',
			'Hauptstuhl',
			'Hasloh',
			'Haselau',
			'Hasel',
			'Hahnbach',
			'Hage',
			'Gyhum',
			'Gunzenhausen',
			'Grunewald',
			'Grünbach',
			'Groß Santersleben',
			'Greußenheim',
			'Gräfenberg',
			'Grabowhöfe',
			'Glindenberg',
			'Ginsheim-Gustavsburg',
			'Gingen an der Fils',
			'Gilching',
			'Geroldshausen',
			'Gerbstedt',
			'Geltendorf',
			'Gammertingen',
			'Gaggenau',
			'Bad Füssing',
			'Fürstenberg',
			'Fünfstetten',
			'Friedrichshagen',
			'Gemeinde Friedland',
			'Freital',
			'Frankenstein',
			'Fischach',
			'Fintel',
			'Müllheim-Feldberg',
			'Faßberg',
			'Espenau',
			'Erxleben',
			'Erlau',
			'Erkrath',
			'Erkheim',
			'Ellhofen',
			'Egmating',
			'Egg',
			'Driedorf',
			'Dissen',
			'Detern',
			'Seebach',
			'Schweina',
			'Schwebheim',
			'Schwarzenbach an der Saale',
			'Schlangen',
			'Schillingen',
			'Rugendorf',
			'Rübeland',
			'Roßwein',
			'Röllbach',
			'Schweich',
			'Schwarzenbruck',
			'Sachsenkam',
			'Riol',
			'Zons',
			'Zeitlofs',
			'Wusterhausen',
			'Wulsbüttel',
			'Wittorf',
			'Wingst',
			'Windberg',
			'Willanzheim',
			'Wilhermsdorf',
			'Wiesenburg',
			'Wiesenbach',
			'Wienhausen',
			'Westerland',
			'Wessobrunn',
			'Wernersberg',
			'Werdau',
			'Weißenburg in Bayern',
			'Weiding',
			'Wassenach',
			'Wangerooge',
			'Waldenbuch',
			'Waldburg',
			'Wald',
			'Walchum',
			'Waischenfeld',
			'Wadersloh',
			'Wächtersbach',
			'Waabs',
			'Vohenstrauß',
			'Vohburg an der Donau',
			'Vetschau',
			'Uelsen',
			'Thür',
			'Teningen',
			'Tännesberg',
			'Sulzbach-Rosenberg',
			'Sülstorf',
			'Straußfurt',
			'Staufenberg',
			'Stadtprozelten',
			'Stade',
			'Spiegelberg',
			'Sindelfingen',
			'Siegburg',
			'Senden',
			'Seeshaupt',
			'Schwieberdingen',
			'Schwetzingen',
			'Schwandorf in Bayern',
			'Kleinschwabhausen',
			'Schönheide',
			'Schöneiche',
			'Schönberg',
			'Schnaittenbach',
			'Schleusingen',
			'Schillingsfürst',
			'Schechen',
			'Sauerlach',
			'Sankt Goar',
			'Sandesneben',
			'Sandersleben',
			'Samtens',
			'Rückersdorf',
			'Rothenburg',
			'Rostock',
			'Roßlau',
			'Roigheim',
			'Rohrdorf',
			'Rinteln',
			'Rieseby',
			'Demen',
			'Delitzsch',
			'Deiningen',
			'Chamerau',
			'Buttlar',
			'Bürstadt',
			'Bürgstadt',
			'Französisch Buchholz',
			'Buch am Buchrain',
			'Brüsewitz',
			'Brokdorf',
			'Brehme',
			'Bliesdorf',
			'Bischbrunn',
			'Billerbeck',
			'Biesdorf',
			'Bienrode',
			'Beetzendorf',
			'Beesenlaublingen',
			'Baunatal',
			'Bastheim',
			'Barntrup',
			'Barnstädt',
			'Bad Waldsee',
			'Bad Sassendorf',
			'Bad Heilbrunn',
			'Bad Doberan',
			'Aufhausen',
			'Arzbach',
			'Amöneburg',
			'Alten Buseck',
			'Allmendingen',
			'Alling',
			'Alfeld',
			'Aislingen',
			'Adlkofen',
			'Adenstedt',
			'Achslach',
			'Abstatt',
			'Aalen',
			'Missen-Wilhams',
			'Giesen',
			'Mallersdorf-Pfaffenberg',
			'Salmtal',
			'Schondorf am Ammersee',
			'Oberrotweil',
			'Reichenau',
			'Karlsdorf-Neuthard',
			'Filderstadt',
			'Altstadt',
			'HafenCity',
			'Osternienburger Land',
			'Lobbach',
			'Prinzenpark',
			'Rielasingen-Worblingen',
			'Rennertshofen',
			'Remptendorf',
			'Reinickendorf',
			'Reinfeld',
			'Raschau',
			'Radevormwald',
			'Probsteierhagen',
			'Porschdorf',
			'Pommersfelden',
			'Plattling',
			'Pausa',
			'Ottenstein',
			'Ostheim',
			'Olching',
			'Ochtrup',
			'Oberstdorf',
			'Oberschleißheim',
			'Ober-Ramstadt',
			'Oberhof',
			'Oberhausen',
			'Nordheim',
			'Nordenham',
			'Niederkirchen bei Deidesheim',
			'Neustadt (Hessen)',
			'Neuss',
			'Neunkirchen am Main',
			'Neuhaus-Schierschnitz',
			'Neuhausen',
			'Neuhausen',
			'Neresheim',
			'Mosbach',
			'Morbach',
			'Montabaur',
			'Möckern',
			'Mitteleschenbach',
			'Milmersdorf',
			'Meudt',
			'Merxheim',
			'Merseburg',
			'Menslage',
			'Mendig',
			'Masserberg',
			'Marzahn',
			'Markt Wald',
			'Marktredwitz',
			'Marktoffingen',
			'Marienhafe',
			'Lürschau',
			'Lüdersfeld',
			'Lohfelden',
			'Lochau',
			'Löbtau',
			'Lippstadt',
			'Ließem',
			'Leupoldsgrün',
			'Leubsdorf',
			'Leimersheim',
			'Lauterbach/Schwarzwald',
			'Laugna',
			'Lankwitz',
			'Langquaid',
			'Langenwetzendorf',
			'Langenhorn',
			'Langenargen',
			'Landscheid',
			'Lachendorf',
			'Künzelsau',
			'Kropstädt',
			'Königsdorf',
			'Kölleda',
			'Klitten',
			'Klingenstein',
			'Kleinzschocher',
			'Kleinostheim',
			'Kisdorf',
			'Kirchhundem',
			'Kirchenthumbach',
			'Kindsbach',
			'Kiel',
			'Kiedrich',
			'Kaltenengers',
			'Kaisersbach',
			'Iphofen',
			'Ihringen',
			'Höttingen',
			'Hohndorf',
			'Hirrlingen',
			'Hinterschmiding',
			'Himmelpforten',
			'Hilter',
			'Heroldsbach',
			'Hattenhofen',
			'Haßbergen',
			'Haslach',
			'Hamm',
			'Halle (Saale)',
			'Guxhagen',
			'Guttau',
			'Gusenburg',
			'Guntersblum',
			'Gummersbach',
			'Großolbersdorf',
			'Groß Oesingen',
			'Großkmehlen',
			'Großhabersdorf',
			'Großenseebach',
			'Greimerath',
			'Grassau',
			'Gransee',
			'Grafenwiesen',
			'Grafenau',
			'Grabsleben',
			'Golmbach',
			'Gießen',
			'Gevelsberg',
			'Gesees',
			'Gerwisch',
			'Gerstungen',
			'Gernsheim',
			'Gerach',
			'Gadebusch',
			'Fürstenfeldbruck',
			'Fritzlar',
			'Friedrichroda',
			'Freudenberg',
			'Flöha',
			'Fischeln',
			'Falkenau',
			'Ering',
			'Eisenach',
			'Eibenstock',
			'Egling',
			'Egestorf',
			'Efringen-Kirchen',
			'Eching',
			'Ebenweiler',
			'Dudenhofen',
			'Dorfprozelten',
			'Dörentrup',
			'Donauwörth',
			'Dommitzsch',
			'Domersleben',
			'Dettingen unter Teck',
			'Dersum',
			'Dennheritz',
			'Deilingen',
			'Deggingen',
			'Deckenpfronn',
			'Dasing',
			'Dahlem',
			'Creglingen',
			'Crailsheim',
			'Clingen',
			'Cavertitz',
			'Butzbach',
			'Burkau',
			'Buckow',
			'Buchholz',
			'Büchenbach',
			'Brockum',
			'Breitscheid',
			'Breitenbrunn',
			'Brehna',
			'Breddorf',
			'Bodenwöhr',
			'Bockum',
			'Blaufelden',
			'Bidingen',
			'Biblis',
			'Beutelsbach',
			'Bernsbach',
			'Bernau bei Berlin',
			'Bergwitz',
			'Beckedorf',
			'Baltmannsweiler',
			'Balingen',
			'Bad Salzdetfurth',
			'Bad Saarow',
			'Bad Kohlgrub',
			'Bad Kleinen',
			'Badenhausen',
			'Aura im Sinngrund',
			'Au in der Hallertau',
			'Attenkirchen',
			'Asperg',
			'Aschheim',
			'Arpke',
			'Andernach',
			'Altengottern',
			'Altenberg',
			'Altena',
			'Alheim',
			'Albstadt',
			'Ahrensbök',
			'Abtsgmünd',
			'Sankt Leonhard am Wonneberg',
			'Ölbronn-Dürrn',
			'Schöffengrund',
			'Asbach-Bäumenheim',
			'Ettringen',
			'Ilsede',
			'Kappel-Grafenhausen',
			'Mülheim',
			'Altstadt',
			'Remseck am Neckar',
			'Königsbau',
			'Zwenkau',
			'Zörbig',
			'Zingst',
			'Zahna',
			'Zachenberg',
			'Wurmannsquick',
			'Wörth am Rhein',
			'Wöhrden',
			'Winterhude',
			'Winhöring',
			'Windhausen',
			'Wilsdruff',
			'Wilnsdorf',
			'Bad Wildbad',
			'Westerhorn',
			'Westerhausen',
			'Wesselburen',
			'Wernigerode',
			'Weitramsdorf',
			'Weiskirchen',
			'Weisendorf',
			'Weihenzell',
			'Weigendorf',
			'Wattenheim',
			'Waltrop',
			'Vilseck',
			'Viernheim',
			'Veltenhof',
			'Veitshöchheim',
			'Unterjettingen',
			'Untergruppenbach',
			'Unseburg',
			'Uchte',
			'Trogen',
			'Thurnau',
			'Theilheim',
			'Taufkirchen',
			'Surwold',
			'Suddendorf',
			'Strasburg',
			'Stetten',
			'Steinsfeld',
			'Steinbach',
			'Stamsried',
			'Stadtilm',
			'Speicher',
			'Sörup',
			'Siegelsbach',
			'Schwelm',
			'Schwarzach',
			'Schmiedeberg',
			'Schmidgaden',
			'Schiffweiler',
			'Salzmünde',
			'Saffig',
			'Saarhölzbach',
			'Rickling',
			'Rheinau',
			'Rathmannsdorf',
			'Ramberg',
			'Raesfeld',
			'Quitzdorf',
			'Quickborn',
			'Poppenbüttel',
			'Ponitz',
			'Polleben',
			'Perkam',
			'Penzing',
			'Pentling',
			'Pegau',
			'Parkstein',
			'Sellin',
			'Osburg',
			'Oranienburg',
			'Oppenheim',
			'Öpfingen',
			'Oederquart',
			'Odenthal',
			'Obrigheim',
			'Oberschöna',
			'Oberscheinfeld',
			'Oberreichenbach',
			'Obernbeck',
			'Obermaßfeld-Grimmenthal',
			'Oberlungwitz',
			'Obergriesbach',
			'Obercunnersdorf',
			'Nußloch',
			'Nortrup',
			'Nordkirchen',
			'Norden',
			'Nonnenhorn',
			'Niederrieden',
			'Niedernwöhren',
			'Niederdorf',
			'Neustadt',
			'Neundorf',
			'Neukirchen',
			'Neuhof an der Zenn',
			'Neuenmarkt',
			'Netzschkau',
			'Neschwitz',
			'Münster',
			'Müllrose',
			'Morsum',
			'Monsheim',
			'Mittelstetten',
			'Minfeld',
			'Michelau',
			'Melsungen',
			'Melbeck',
			'Meerane',
			'Manderscheid',
			'Malliß',
			'Mainleus',
			'Lützen',
			'Lügde',
			'Lubmin',
			'Löwenstein',
			'Loiching',
			'Loddin',
			'Lissendorf',
			'Leutersdorf',
			'Lengenwang',
			'Lemförde',
			'Lehre',
			'Leegebruch',
			'Lauterbach',
			'Lalling',
			'Kusterdingen',
			'Kosel',
			'Köfering',
			'Klieken',
			'Kleinwenden',
			'Kleinrinderfeld',
			'Kirchdorf',
			'Kevelaer',
			'Kastorf',
			'Kastl',
			'Judenbach',
			'Jevenstedt',
			'Itzehoe',
			'Ispringen',
			'Irsch',
			'Ingolstadt',
			'Immenstaad am Bodensee',
			'Ilfeld',
			'Ihrlerstein',
			'Hoym',
			'Höpfingen',
			'Hohenwarsleben',
			'Hohenberg an der Eger',
			'Hochkirch',
			'Hiltenfingen',
			'Hilden',
			'Heßdorf',
			'Herxheim am Berg',
			'Herrnhut',
			'Herdecke',
			'Heidenheim',
			'Heidelberg',
			'Harburg',
			'Hankensbüttel',
			'Hakenfelde',
			'Haigerloch',
			'Habach',
			'Gundelsheim',
			'Grünberg',
			'Großreuth bei Schweinau',
			'Gronau',
			'Grevesmühlen',
			'Goseck',
			'Glatten',
			'Gifhorn',
			'Gerlingen',
			'Geitelde',
			'Geising',
			'Gebesee',
			'Fürstenhausen',
			'Friedrichshafen',
			'Friedberg',
			'Frankenwinheim',
			'Föritz',
			'Flintbek',
			'Felm',
			'Bad Fallingbostel',
			'Euerbach',
			'Ettenheim',
			'Emsdetten',
			'Elxleben',
			'Ellzee',
			'Ellenberg',
			'Eldena',
			'Eitorf',
			'Eiterfeld',
			'Eicklingen',
			'Ehingen',
			'Egloffstein',
			'Eggersdorf',
			'Edelsfeld',
			'Durach',
			'Drochtersen',
			'Drakenburg',
			'Dormitz',
			'Dormagen',
			'Deutz',
			'Zschornewitz',
			'Zinna',
			'Ziesendorf',
			'Ziesar',
			'Zell im Fichtelgebirge',
			'Zeitz',
			'Wörlitz',
			'Wolferstadt',
			'Wipfeld',
			'Wilmersdorf',
			'Wilhelmstadt',
			'Wildenberg',
			'Werneuchen',
			'Wenzenbach',
			'Wemding',
			'Weisweil',
			'Weddelbrook',
			'Waldstetten',
			'Walderbach',
			'Waghäusel',
			'Wackernheim',
			'Vagen',
			'Uttenreuth',
			'Uthlede',
			'Umkirch',
			'Troisdorf',
			'Triptis',
			'Trier',
			'Töging am Inn',
			'Thurmansbang',
			'Thüngen',
			'Thalmassing',
			'Tettenweis',
			'Teichwolframsdorf',
			'Taucha',
			'Süderlügum',
			'Struvenhütten',
			'Stolpe',
			'Stockstadt am Main',
			'Steingaden',
			'Steinfeld',
			'Stadtlengsfeld',
			'Stäbelow',
			'Sottrum',
			'Södel',
			'Langenhorn',
			'Siedenburg',
			'Seligenstadt',
			'Seifhennersdorf',
			'Schwedelbach',
			'Schwarme',
			'Schwangau',
			'Schwalbach',
			'Schwäbisch Gmünd',
			'Schönkirchen',
			'Schömberg',
			'Schöfweg',
			'Schnaittach',
			'Schmiedefeld am Rennsteig',
			'Schmargendorf',
			'Schluchsee',
			'Bad Schlema',
			'Schirgiswalde',
			'Sankt Augustin',
			'Alt-Sanitz',
			'Salzhausen',
			'Salzbergen',
			'Sallgast',
			'Saerbeck',
			'Ruppertshofen',
			'Ruppertsberg',
			'Rümpel',
			'Rühen',
			'Rotenburg',
			'Ronshausen',
			'Römhild',
			'Rohrbach',
			'Rohr',
			'Ringelai',
			'Deidesheim',
			'Dannewerk',
			'Cottbus',
			'Cleebronn',
			'Carlsberg',
			'Burgsalach',
			'Burgpreppach',
			'Buckow',
			'Brüggen',
			'Brey',
			'Bretten',
			'Boffzen',
			'Bischoffen',
			'Birkenau',
			'Billbrook',
			'Bermatingen',
			'Bergen auf Rügen',
			'Bad Suderode',
			'Bad Schmiedeberg',
			'Bad Salzschlirf',
			'Bad Nenndorf',
			'Bad Münder am Deister',
			'Babensham',
			'Aresing',
			'Altenholz',
			'Altbach',
			'Allmannsdorf',
			'Albshausen',
			'Albaching',
			'Aidlingen',
			'Aidenbach',
			'Ahsen-Oetzen',
			'Ahlen',
			'Rietheim-Weilheim',
			'Offenbach-Hundheim',
			'Gondershausen',
			'Kottgeisering',
			'Marktrodach',
			'Sankt Leon-Rot',
			'Haselbachtal',
			'Stadtrandsiedlung Malchow',
			'Neustadt/Süd',
			'Grafenau',
			'Kronberg Tal',
			'Petritor - Nord',
			'Rühme - Vorwerksdlg.',
			'Riegel',
			'Rhens',
			'Retgendorf',
			'Rehe',
			'Regenstauf',
			'Rastede',
			'Raitenbuch',
			'Raisdorf',
			'Putbus',
			'Priesendorf',
			'Pottum',
			'Postmünster',
			'Pleiskirchen',
			'Perach',
			'Pech',
			'Paunzhausen',
			'Patersdorf',
			'Ostseebad Boltenhagen',
			'Osterrönfeld',
			'Ossendorf',
			'Osnabrück',
			'Oldenburg',
			'Odelzhausen',
			'Ochsenfurt',
			'Oberriexingen',
			'Oberndorf',
			'Oberhausen-Rheinhausen',
			'Oberhausen',
			'Oberaula',
			'Niederroßla',
			'Niedernberg',
			'Niederlauer',
			'Niebüll',
			'Neustadt am Rennsteig',
			'Neukirchen',
			'Neuhäusel',
			'Neuenstein',
			'Neuburg',
			'Nersingen',
			'Nellingen',
			'Münsterhausen',
			'Munningen',
			'Mühlhausen',
			'Mücka',
			'Monakam',
			'Mindelheim',
			'Michelstadt',
			'Meuselbach',
			'Mellingen',
			'Mehlingen',
			'Mechterstädt',
			'Markgröningen',
			'Mainbernheim',
			'Löcknitz',
			'Loburg',
			'Legden',
			'Lauffen am Neckar',
			'Langewiesen',
			'Lähden',
			'Bad Laasphe',
			'Kupferzell',
			'Kührstedt',
			'Kronburg',
			'Kronau',
			'Krölpa',
			'Krakow am See',
			'Kötzschau',
			'Konradsreuth',
			'Kist',
			'Kirchenpingarten',
			'Kemnath',
			'Karlsfeld',
			'Jünkerath',
			'Jübek',
			'Jockgrim',
			'Jacobsdorf',
			'Immelborn',
			'Ilsfeld',
			'Homberg',
			'Hohenfels',
			'Höchstädt an der Donau',
			'Höchstadt an der Aisch',
			'Hildrizhausen',
			'Hilbersdorf',
			'Heusweiler',
			'Hettstedt',
			'Henfenfeld',
			'Hellersdorf',
			'Heinsen',
			'Heinbockel',
			'Heinade',
			'Hatten',
			'Haseldorf',
			'Hartmannsdorf',
			'Hartha',
			'Hartenholm',
			'Hanstedt',
			'Halbe',
			'Grünstadt',
			'Grünenbach',
			'Großgoltern',
			'Großdubrau',
			'Griesstätt',
			'Gersfeld',
			'Gerolstein',
			'Gerolfingen',
			'Gernsbach',
			'Gensingen',
			'Gemmrigheim',
			'Gehren',
			'Gaukönigshofen',
			'Frontenhausen',
			'Friesdorf',
			'Freienwill',
			'Freckenfeld',
			'Frauenprießnitz',
			'Frankenheim',
			'Flossenbürg',
			'Flechtingen',
			'Erkerode',
			'Erfde',
			'Erdmannhausen',
			'Ensdorf',
			'Ennepetal',
			'Endingen',
			'Emersacker',
			'Elsdorf',
			'Eimeldingen',
			'Ebnath',
			'Eberdingen',
			'Duingen',
			'Drentwede',
			'Dormettingen',
			'Donnersdorf',
			'Dohma',
			'Dobersdorf',
			'Ditzingen',
			'Dischingen',
			'Dipperz',
			'Zittau',
			'Zetel',
			'Zernien',
			'Wunsiedel',
			'Wriezen',
			'Wolsdorf',
			'Wolken',
			'Wolfhagen',
			'Wohltorf',
			'Winsen',
			'Winnenden',
			'Windischleuba',
			'Willmering',
			'Wiehe',
			'Wenzendorf',
			'Wellendingen',
			'Welle',
			'Weißenborn',
			'Weißandt-Gölzau',
			'Weilbach',
			'Wattenbek',
			'Wartenberg',
			'Wallendorf',
			'Waakirchen',
			'Völkenrode',
			'Vockerode',
			'Valley',
			'Bad Urach',
			'Unterlüß',
			'Untergriesbach',
			'Tschernitz',
			'Trechtingshausen',
			'Tolk',
			'Todtnau',
			'Tittling',
			'Tharandt',
			'Tempelhof',
			'Tauscha',
			'Tauberbischofsheim',
			'Steyerberg',
			'Stelzenberg',
			'Stallwang',
			'Spreenhagen',
			'Spardorf',
			'Spalt',
			'Sontra',
			'Sondershausen',
			'Selzen',
			'Demitz-Thumitz',
			'Dellfeld',
			'Dahme',
			'Cuxhaven',
			'Cunewalde',
			'Calberlah',
			'Buttenheim',
			'Burgoberbach',
			'Burgkunstadt',
			'Brunnthal',
			'Bruchhausen-Vilsen',
			'Brockel',
			'Brachbach',
			'Börnsen',
			'Borna',
			'Borgfelde',
			'Bomlitz',
			'Bodenkirchen',
			'Bodelshausen',
			'Bockhorn',
			'Bockelwitz',
			'Blomberg',
			'Bisingen',
			'Bettingen',
			'Bernitt',
			'Bernau',
			'Berg',
			'Bei der Höhne',
			'Bechtsrieth',
			'Baumholder',
			'Barwedel',
			'Barnstorf',
			'Barenburg',
			'Banzkow',
			'Bann',
			'Bad Überkingen',
			'Bad Sachsa',
			'Bad Kreuznach',
			'Bad Honnef',
			'Badenweiler',
			'Bächingen an der Brenz',
			'Ausleben',
			'Attenweiler',
			'Arzberg',
			'Amstetten',
			'Altenstadt',
			'Altendorf',
			'Altdorf',
			'Albig',
			'Aken',
			'Barby',
			'Hochstetten-Dhaun',
			'Hoppstädten-Weiersbach',
			'Riedstadt',
			'Hammerbrook',
			'Rühme - Lincolnsdlg.',
			'Wilhelmitor - Nord',
			'Günthersleben-Wechmar',
			'Rhede',
			'Rhaunen',
			'Retzstadt',
			'Renchen',
			'Reinsdorf',
			'Ratingen',
			'Rastow',
			'Rannungen',
			'Ramsau',
			'Quierschied',
			'Pullenreuth',
			'Prosselsheim',
			'Prichsenstadt',
			'Pouch',
			'Pielenhofen',
			'Pfofeld',
			'Pfaffen-Schwabenheim',
			'Palzem',
			'Palling',
			'Ottobrunn bei München',
			'Ottersheim',
			'Ortenberg',
			'Ornbau',
			'Oppin',
			'Ohorn',
			'Oberschweinbach',
			'Obernfeld',
			'Oberkirch',
			'Nufringen',
			'Nortmoor',
			'Nienstädt',
			'Niederzier',
			'Niederwerrn',
			'Niedermurach',
			'Niedermerz',
			'Niederbachem',
			'Neuweiler',
			'Neukirchen beim Heiligen Blut',
			'Neuhof',
			'Neubrück',
			'Nackenheim',
			'Müschenbach',
			'Mügeln',
			'Monheim am Rhein',
			'Mömbris',
			'Merkenbach',
			'Mellrichstadt',
			'Meddersheim',
			'Marxen',
			'Martfeld',
			'Marlow',
			'Maring-Noviand',
			'Marburg an der Lahn',
			'Maikammer',
			'Ludwigsfelde',
			'Lüderitz',
			'Lüdenscheid',
			'Lottstetten',
			'Lotte',
			'Lorch',
			'Litzendorf',
			'Linn',
			'Leutesdorf',
			'Leutenbach',
			'Leiwen',
			'Leezen',
			'Langerwehe',
			'Langenselbold',
			'Lachen',
			'Kurort Gohrisch',
			'Kupferberg',
			'Külsheim',
			'Kulmbach',
			'Kuhardt',
			'Kriegsfeld',
			'Kressbronn am Bodensee',
			'Kößlarn',
			'Königstein',
			'Klein Rönnau',
			'Klein Rogahn',
			'Jengen',
			'Imsbach',
			'Ilvesheim',
			'Ilsenburg',
			'Ilbesheim',
			'Husum',
			'Hungen',
			'Hügelsheim',
			'Hornstorf',
			'Horgau',
			'Holzkirchen',
			'Hohenthann',
			'Hodenhagen',
			'Hirschaid',
			'Heusenstamm',
			'Herbrechtingen',
			'Helmstadt',
			'Heiligkreuzsteinach',
			'Heiligenstadt',
			'Heigenbrücken',
			'Hebertshausen',
			'Hausen',
			'Haundorf',
			'Hartheim',
			'Harsefeld',
			'Harburg',
			'Hammersbach',
			'Eimsbüttel',
			'Halver',
			'Halsbrücke',
			'Hallenberg',
			'Halensee',
			'Hahnheim',
			'Haag in Oberbayern',
			'Gutach im Breisgau',
			'Gusterath',
			'Grünsfeld',
			'Großrinderfeld',
			'Groß Köris',
			'Großheubach',
			'Großbottwar',
			'Gries',
			'Görwihl',
			'Girod',
			'Giekau',
			'Geslau',
			'Gelnhausen',
			'Garching an der Alz',
			'Fuchsmühl',
			'Frose',
			'Frickenhausen',
			'Frickenhausen',
			'Freilassing',
			'Frechen',
			'Flintsbach',
			'Finsterwalde',
			'Farchant',
			'Ettlingen',
			'Eschborn',
			'Erdweg',
			'Eppishausen',
			'Eime',
			'Eichstätt',
			'Ebertsheim',
			'Düren',
			'Drübeck',
			'Dorndorf',
			'Dölbau',
			'Dirlewang',
			'Deutzen',
			'Schwarzenborn',
			'Schlettau',
			'Schellerten',
			'Scharbeutz',
			'Roth',
			'Rosdorf',
			'Rimsting',
			'Rimschweiler',
			'Riethnordhausen',
			'Schweitenkirchen',
			'Schwegenheim',
			'Schönefeld',
			'Schladen',
			'Saal an der Saale',
			'Rottleberode',
			'Roskow',
			'Rosche',
			'Riesa',
			'Buttelstedt',
			'Burgschwalbach',
			'Burgberg',
			'Burg',
			'Brunsbüttel',
			'Brüel',
			'Breidenbach',
			'Bredstedt',
			'Brakel',
			'Böttingen',
			'Bönen',
			'Bollingstedt',
			'Blankenburg',
			'Blankenburg',
			'Blaichach',
			'Birkenwerder',
			'Besigheim',
			'Bernstadt',
			'Berge',
			'Benneckenstein',
			'Beltheim',
			'Belgershain',
			'Barkelsby',
			'Balje',
			'Baiersbronn',
			'Bad Windsheim',
			'Bad Eilsen',
			'Babenhausen',
			'Außernzell',
			'Attenhofen',
			'Arnstadt',
			'Arneburg',
			'Alsheim',
			'Allendorf an der Lahn',
			'Allendorf',
			'Aholming',
			'Aholfing',
			'Abensberg',
			'Eggermühlen',
			'Apfeldorf',
			'Eggingen',
			'Niefern-Öschelbronn',
			'Viereth-Trunstadt',
			'Schwanau',
			'Weststadt',
			'Fehmarn',
			'Südstadt',
			'Helmstadt-Bargen',
			'Zell',
			'Zarrendorf',
			'Wriedel',
			'Wittorf',
			'Witterda',
			'Wittelshofen',
			'Wilthen',
			'Wildeshausen',
			'Wetter',
			'Wesseln',
			'Wernshausen',
			'Welzow',
			'Weißdorf',
			'Weisen',
			'Weinsheim',
			'Weingarten',
			'Weikersheim',
			'Weidenthal',
			'Weidenbach',
			'Weibersbrunn',
			'Weißensee',
			'Wegscheid',
			'Warnow',
			'Wanzleben',
			'Waldbrunn',
			'Wadgassen',
			'Volkenschwand',
			'Vinningen',
			'Urbach',
			'Unlingen',
			'Uftrungen',
			'Thiendorf',
			'Tettau',
			'Teistungen',
			'Tacherting',
			'Süpplingen',
			'Sulz am Neckar',
			'Suhl',
			'Stützerbach',
			'Stockheim',
			'Steinigtwolmsdorf',
			'Stammbach',
			'Bad Staffelstein',
			'Stadum',
			'Stadtkyll',
			'Spechbach',
			'Söchtenau',
			'Siershahn',
			'Siegsdorf',
			'Seelitz',
			'Schwenningen',
			'Schwenningen',
			'Schwarzenbek',
			'Schwanfeld',
			'Schrozberg',
			'Schortens',
			'Schorndorf',
			'Schönfeld',
			'Schloßvippach',
			'Schleiz',
			'Scharnebeck',
			'Sayda',
			'Sankt Johann',
			'Sankt Blasien',
			'Samerberg',
			'Röttenbach',
			'Rot am See',
			'Rohrbach',
			'Roggenburg',
			'Richtenberg',
			'Rheden',
			'Regnitzlosau',
			'Regis-Breitingen',
			'Ratekau',
			'Ramerberg',
			'Rain',
			'Räckelwitz',
			'Querfurt',
			'Püttlingen',
			'Prittriching',
			'Pretzsch',
			'Pockau',
			'Plochingen',
			'Plauen',
			'Plankstadt',
			'Pfullendorf',
			'Pettstadt',
			'Pattensen',
			'Parchim',
			'Papendorf',
			'Ottrau',
			'Otterbach',
			'Ottenbach',
			'Ostenfeld',
			'Orsingen-Nenzingen',
			'Opladen',
			'Oberweißbach',
			'Oberthulba',
			'Oberteuringen',
			'Obernkirchen',
			'Oberjettingen',
			'Oberderdingen',
			'Nümbrecht',
			'Niederwinkling',
			'Niederwerth',
			'Nidderau',
			'Neuruppin',
			'Neuler',
			'Neuhaus an der Oste',
			'Neufraunhofen',
			'Müllheim',
			'Muggensturm',
			'Moos',
			'Mönchhagen',
			'Molbergen',
			'Möckmühl',
			'Mitwitz',
			'Meschede',
			'Merching',
			'Meppen',
			'Mehren',
			'Maxdorf',
			'Markneukirchen',
			'Markersbach',
			'Margetshöchheim',
			'Mainhardt',
			'Magdala',
			'Luisenthal',
			'Lugau',
			'Löberitz',
			'Lichtenberg',
			'Lichtenau',
			'Leubnitz',
			'Leonberg',
			'Leimen',
			'Leichlingen',
			'Lehrberg',
			'Leck',
			'Lautertal',
			'Lauterhofen',
			'Laucha',
			'Lauben',
			'Langenpreising',
			'Langenlonsheim',
			'Langenenslingen',
			'Langenberg',
			'Langen',
			'Lambrecht',
			'Kulmain',
			'Krün',
			'Kröppelshagen-Fahrendorf',
			'Krombach',
			'Kralenriede',
			'Krailling',
			'Königswalde',
			'Königsbrunn',
			'Kirschweiler',
			'Kirchlinteln',
			'Kirchheim an der Weinstraße',
			'Kirchberg an der Murr',
			'Kirchberg an der Jagst',
			'Kirchberg an der Iller',
			'Kierspe',
			'Kessin',
			'Kehl',
			'Kaufering',
			'Karlskron',
			'Kaisersesch',
			'Ittlingen',
			'Issum',
			'Inden',
			'Iffeldorf',
			'Idar-Oberstein',
			'Hunding',
			'Hundeshagen',
			'Hüffelsheim',
			'Hückeswagen',
			'Horstedt',
			'Hordel',
			'Holzgünz',
			'Hinterzarten',
			'Himmelstadt',
			'Hillerse',
			'Herten',
			'Hermsdorf',
			'Hermaringen',
			'Hepstedt',
			'Helmbrechts',
			'Hellenthal',
			'Heitersheim',
			'Heidenheim an der Brenz',
			'Hauzenberg',
			'Hausbruch',
			'Haselbach',
			'Harvestehude',
			'Harrislee',
			'Hagnau',
			'Haarbach',
			'Güster',
			'Grünhainichen',
			'Groß Wittensee',
			'Groß Laasch',
			'Großerlach',
			'Großenkneten',
			'Großbreitenbach',
			'Grevenbroich',
			'Greiz',
			'Glandorf',
			'Gestratz',
			'Geseke',
			'Geismar',
			'Gau-Bischofsheim',
			'Fürsteneck',
			'Frohnau',
			'Friedenau',
			'Frei-Laubersheim',
			'Frankenthal',
			'Forchheim',
			'Falkenfels',
			'Eurasburg',
			'Esterwegen',
			'Esslingen',
			'Essen',
			'Ernstroda',
			'Elster',
			'Ellerhoop',
			'Eitting',
			'Eisingen',
			'Eil',
			'Eichenried',
			'Eichenbühl',
			'Eggebek',
			'Egestorf',
			'Donaueschingen',
			'Dobel',
			'Dingolshausen',
			'Dietenhofen',
			'Detmerode',
			'Zwiesel',
			'Zeulenroda',
			'Zenting',
			'Zeil',
			'Winningen',
			'Winkelhaid',
			'Wildflecken',
			'Wiesmoor',
			'Wiesenthau',
			'Wiesent',
			'Wiek',
			'Wetter',
			'Westerstede',
			'Wesendorf',
			'Wentorf bei Hamburg',
			'Welver',
			'Weingarten',
			'Weiltingen',
			'Wedel',
			'Warthausen',
			'Wansleben',
			'Wallerstein',
			'Waldsassen',
			'Waiblingen',
			'Wahlitz',
			'Wachenroth',
			'Vlotho',
			'Veltheim',
			'Vachendorf',
			'Untersteinach',
			'Unterreichenbach',
			'Unterhaching',
			'Unterföhring',
			'Übach-Palenberg',
			'Traunreut',
			'Titz',
			'Thüngersheim',
			'Teuchern',
			'Tellingstedt',
			'Teising',
			'Teicha',
			'Tautenhain',
			'Tabarz',
			'Sterup',
			'Stephansposching',
			'Steinach',
			'Steimbke',
			'Stadensen',
			'Stadelhofen',
			'Speinshart',
			'Sipplingen',
			'Simmern',
			'Sigmarszell',
			'Siemensstadt',
			'Seybothenreuth',
			'Sennewitz',
			'Seelze',
			'Seelingstädt',
			'Schwarza',
			'Schwalmtal',
			'Schülp',
			'Schrobenhausen',
			'Schraplau',
			'Schönebeck',
			'Schönaich',
			'Schonach im Schwarzwald',
			'Schmiedefeld',
			'Scheyern',
			'Scheeßel',
			'Satow-Oberhagen',
			'Salzwedel',
			'Saarwellingen',
			'Roßleben',
			'Ronnenberg',
			'Rodleben',
			'Röblingen am See',
			'Rimbach',
			'Dernau',
			'Delingsdorf',
			'Deißlingen',
			'Datteln',
			'Dänischenhagen',
			'Carlow',
			'Calw',
			'Bullay',
			'Bruck',
			'Brieskow-Finkenheerd',
			'Brandis',
			'Brandenburg an der Havel',
			'Borsdorf',
			'Borgholzhausen',
			'Bohnsdorf',
			'Bodenheim',
			'Bockenau',
			'Bergen an der Dumme',
			'Bentwisch',
			'Benndorf',
			'Bendestorf',
			'Belgern',
			'Bayerbach',
			'Baierbrunn',
			'Bahrdorf',
			'Bahlingen',
			'Bad Wilsnack',
			'Bad Sooden-Allendor',
			'Bad Marienberg',
			'Bad Brückenau',
			'Bad Berka',
			'Badbergen',
			'Bad Bellingen',
			'Altlandsberg',
			'Althengstett',
			'Altenriet',
			'Alfhausen',
			'Ahausen',
			'Aham',
			'Aach',
			'Seeon-Seebruck',
			'Böbingen an der Rems',
			'Urbach',
			'Ediger-Eller',
			'Mülheim-Kärlich',
			'Büddenstedt',
			'Heere',
			'Sassenburg',
			'Sickte',
			'Alsbach-Hähnlein',
			'Jettingen-Scheppach',
			'Rheinstetten',
			'Kröpeliner-Tor-Vorstadt',
			'Gartenstadt',
			'Reut',
			'Reischach',
			'Reinstorf',
			'Reinsfeld',
			'Reichenbach-Steegen',
			'Ranis',
			'Quellendorf',
			'Prüm',
			'Piesport',
			'Pfaffroda',
			'Pemfling',
			'Ostercappeln',
			'Oppum',
			'Oer-Erkenschwick',
			'Oedheim',
			'Obernburg am Main',
			'Oberharmersbach',
			'Nordleda',
			'Nordhorn',
			'Nordheim',
			'Nippes',
			'Nienburg',
			'Niedermohr',
			'Niedererbach',
			'Aschau im Chiemgau',
			'Neuwittenbek',
			'Neukölln',
			'Neuhaus',
			'Neubrunn',
			'Nehren',
			'Neckarsteinach',
			'Nauendorf',
			'Nahrendorf',
			'Münster',
			'Molschleben',
			'Moabit',
			'Mintraching',
			'Groß Miltzow',
			'Meißenheim',
			'Mayen',
			'Markkleeberg',
			'Malching',
			'Mainburg',
			'Lutzerath',
			'Lucka',
			'Löhnberg',
			'Linnich',
			'Limburg an der Lahn',
			'Lemgo',
			'Lehndorf',
			'Lauchheim',
			'Langwedel',
			'Langstedt',
			'Langenfeld',
			'Kröv',
			'Königsfeld im Schwarzwald',
			'Klötze',
			'Kirchheim bei München',
			'Kirchdorf',
			'Kettershausen',
			'Ketsch',
			'Kellenhusen',
			'Jeßnitz',
			'Jesenwang',
			'Ingelfingen',
			'Ilmenau',
			'Iggingen',
			'Hunderdorf',
			'Höslwang',
			'Hörstel',
			'Hornbach',
			'Hormersdorf',
			'Hollfeld',
			'Höhr-Grenzhausen',
			'Hohnhorst',
			'Hohenfelde',
			'Höchstädt bei Thiersheim',
			'Heuerßen',
			'Herrieden',
			'Hermsdorf',
			'Hermsdorf',
			'Hergensweiler',
			'Hemhofen',
			'Hauswalde',
			'Harxheim',
			'Harsewinkel',
			'Wandsbek',
			'Gützkow',
			'Gutenberg',
			'Groß-Zimmern',
			'Großschweidnitz',
			'Großmonra',
			'Großenwiehe',
			'Gremsdorf',
			'Grafengehaig',
			'Göttingen',
			'Goldkronach',
			'Goldberg',
			'Gleichamberg',
			'Glattbach',
			'Georgensgmünd',
			'Geisleden',
			'Geisenfeld',
			'Gau-Odernheim',
			'Gartow',
			'Garding',
			'Garbsen',
			'Friedrichstadt',
			'Fellbach',
			'Falkenberg',
			'Essingen',
			'Erbendorf',
			'Engeln',
			'Elsdorf-Westermühlen',
			'Eitelborn',
			'Eilsleben',
			'Eilbek',
			'Ebersbach',
			'Dresden',
			'Dransfeld',
			'Dossenheim',
			'Dömitz',
			'Dinkelscherben',
			'Deuerling',
			'Dersekow',
			'Dausenau',
			'Dattenberg',
			'Dassow',
			'Creuzburg',
			'Creußen',
			'Crawinkel',
			'Clausthal-Zellerfeld',
			'Celle',
			'Casekow',
			'Carpin',
			'Bühlerzell',
			'Buchenbach',
			'Bruckmühl',
			'Britz',
			'Bräunlingen',
			'Braubach',
			'Bothel',
			'Borstel-Hohenraden',
			'Börßum',
			'Boos',
			'Bönningstedt',
			'Blaibach',
			'Bindlach',
			'Bierstadt',
			'Biburg',
			'Biberach',
			'Beuna',
			'Betzigau',
			'Bernsdorf',
			'Belleben',
			'Bell',
			'Bell',
			'Beelitz',
			'Bechtheim',
			'Bahrenborstel',
			'Bad Tölz',
			'Bad Tennstedt',
			'Bad Liebenstein',
			'Bad Köstritz',
			'Bad Kissingen',
			'Bad Frankenhausen',
			'Bad Dürkheim',
			'Baddeckenstedt',
			'Aßlar',
			'Asselfingen',
			'Amtzell',
			'Altlußheim',
			'Altfraunhofen',
			'Altenstadt',
			'Altenahr',
			'Allermöhe',
			'Abtsdorf',
			'Absberg',
			'Kromsdorf',
			'Ludweiler-Warndt',
			'Lauda-Königshofen',
			'Neuehrenfeld',
			'Stuttgart-Ost',
			'Paulsstadt',
			'Halle Neustadt',
			'Kalk',
			'Sachsenheim',
			'Oppach',
			'Obersöchering',
			'Obermeitingen',
			'Notzingen',
			'Nortorf',
			'Norddeich',
			'Nonnweiler',
			'Niederstriegis',
			'Niedersachswerfen',
			'Niederbergkirchen',
			'Neulewin',
			'Neufra',
			'Neubukow',
			'Neckargerach',
			'Naumburg',
			'Nassau',
			'Nalbach',
			'Nabburg',
			'Mylau',
			'Murnau am Staffelsee',
			'Mühlen Eichsen',
			'Moritzburg',
			'Mitterfels',
			'Miltenberg',
			'Mettingen',
			'Meßkirch',
			'Merklingen',
			'Meiderich',
			'Martinshöhe',
			'Markt Nordheim',
			'Markt Bibart',
			'Marktbergel',
			'Ludwigslust',
			'Lebach',
			'Lauenau',
			'Langenstein',
			'Langenau',
			'Langelsheim',
			'Lahr',
			'Kürnbach',
			'Künzell',
			'Kröslin',
			'Kossa',
			'Kordel',
			'Königslutter am Elm',
			'Köngen',
			'Klingenberg am Main',
			'Kleinheubach',
			'Kirchberg',
			'Kettenkamp',
			'Kelkheim',
			'Kaub',
			'Katzenelnbogen',
			'Kasseedorf',
			'Karlshagen',
			'Jülich',
			'Jühnde',
			'Jetzendorf',
			'Isen',
			'Inzell',
			'Inning am Holz',
			'Idstein',
			'Hude',
			'Hövelhof',
			'Horn',
			'Holler',
			'Hohn',
			'Hohenpeißenberg',
			'Hochdonn',
			'Hipstedt',
			'Heustreu',
			'Hessisch Lichtenau',
			'Herzogenrath',
			'Herzlake',
			'Herschweiler-Pettersheim',
			'Herrenberg',
			'Hergisdorf',
			'Herborn',
			'Hendungen',
			'Hellenhahn-Schellenberg',
			'Heinersdorf',
			'Heddesheim',
			'Hecklingen',
			'Häusern',
			'Handorf',
			'Hain-Gründau',
			'Hainewalde',
			'Hagen',
			'Günthersdorf',
			'Gschwend',
			'Großrosseln',
			'Groß Börnecke',
			'Grasleben',
			'Grafenwöhr',
			'Golzow',
			'Gohlis',
			'Gladbeck',
			'Gerhardshofen',
			'Geretsried',
			'Gerbrunn',
			'Genderkingen',
			'Geisa',
			'Garlstorf',
			'Gablenz',
			'Fürfeld',
			'Frohburg',
			'Frielendorf',
			'Bad Fredeburg',
			'Fischbach',
			'Fell',
			'Falkenberg',
			'Essel',
			'Eschweiler',
			'Erligheim',
			'Erlenbach',
			'Erfweiler',
			'Eltville',
			'Elsdorf',
			'Ellefeld',
			'Eisenbach',
			'Eichenbarleben',
			'Dreieich',
			'Döllnitz',
			'Dinkelsbühl',
			'Dietzenbach',
			'Dietfurt',
			'Dieterskirchen',
			'Dieburg',
			'Deutsch Evern',
			'Rhodt unter Rietburg',
			'Rheinbach',
			'Reinheim',
			'Rehfelde',
			'Rehau',
			'Ramsen',
			'Pulheim',
			'Poxdorf',
			'Poseritz',
			'Penzlin',
			'Passau',
			'Owen',
			'Ovelgönne',
			'Zellingen',
			'Zell im Wiesental',
			'Xanten',
			'Wyhl',
			'Wustrow',
			'Wustermark',
			'Woltersdorf',
			'Wolpertswende',
			'Wolfach',
			'Witzmannsberg',
			'Wittmar',
			'Wittlich',
			'Wischhafen',
			'Wipperdorf',
			'Winterberg',
			'Winsen',
			'Willingen',
			'Wildenfels',
			'Wetterzeube',
			'Westerheim',
			'Wegeleben',
			'Waren',
			'Walsrode',
			'Waldstetten',
			'Waldbüttelbrunn',
			'Waldaschaff',
			'Velen',
			'Unterammergau',
			'Unter-Abtsteinach',
			'Üchtelhausen',
			'Treia',
			'Trausnitz',
			'Traunstein',
			'Todendorf',
			'Tiddische',
			'Teugn',
			'Teltow',
			'Tawern',
			'Sulzfeld',
			'Straßkirchen',
			'Stolberg',
			'Steinmauern',
			'Steeden',
			'Spenge',
			'Sondheim vor der Rhön',
			'Sellin',
			'Seefeld',
			'Seckach',
			'Schwörstadt',
			'Schwendi',
			'Schönthal',
			'Schönecken',
			'Schönberg',
			'Schneckenlohe',
			'Schmitten',
			'Schlegel',
			'Schenefeld',
			'Schauenstein',
			'Schapen',
			'Satteldorf',
			'Ruhla',
			'Rövershagen',
			'Rot',
			'Roßla',
			'Rosenow',
			'Rohrbach',
			'Rodenberg',
			'Dentlein am Forst',
			'Delligsen',
			'Dauchingen',
			'Colmberg',
			'Coburg',
			'Buttstädt',
			'Büsum',
			'Buchloe',
			'Brüssow',
			'Bruchsal',
			'Breitbrunn',
			'Breddin',
			'Bollschweil',
			'Blasewitz',
			'Bischweier',
			'Binswangen',
			'Berkheim',
			'Berghülen',
			'Bergheim',
			'Berg',
			'Bensheim',
			'Benediktbeuern',
			'Beimerstetten',
			'Bawinkel',
			'Bad Segeberg',
			'Bad Kösen',
			'Aue',
			'Ascheberg',
			'Altenkirchen',
			'Alpen',
			'Ahnsbeck',
			'Adelheidsdorf',
			'Aach',
			'Hohenau',
			'Emmingen-Liptingen',
			'Postbauer-Heng',
			'Kobern-Gondorf',
			'Waldbrunn',
			'Waldems',
			'Mühlanger',
			'Börgerende-Rethwisch',
			'Dannstadt-Schauernheim',
			'Gropiusstadt',
			'Werdervorstadt',
			'Barmbek-Nord',
			'Fennpfuhl',
			'Lenningen',
			'Schwarzer Berg',
			'Zaberfeld',
			'Wörth an der Donau',
			'Wolnzach',
			'Wincheringen',
			'Wilstedt',
			'Wiesloch',
			'Wiesau',
			'Wieren',
			'Wickede',
			'Westhofen',
			'Westeregeln',
			'Wellingsbüttel',
			'Weißenfels',
			'Weißenborn-Lüderode',
			'Weingarten',
			'Waltershausen',
			'Wallenhorst',
			'Walkenried',
			'Waggum',
			'Wagenhoff',
			'Visselhövede',
			'Villingendorf',
			'Unterschneidheim',
			'Unterpleichfeld',
			'Untermeitingen',
			'Untermaßfeld',
			'Türkenfeld',
			'Tuntenhausen',
			'Tucheim',
			'Tribsees',
			'Trebitz',
			'Tostedt',
			'Tiergarten',
			'Theißen',
			'Tessin',
			'Telgte',
			'Tegel',
			'Tarp',
			'Tannenbergsthal',
			'Suhlendorf',
			'Süderstapel',
			'Stützengrün',
			'Stößen',
			'Stollberg',
			'Steinau an der Straße',
			'Stadtbergen',
			'Sparneck',
			'Sörgenloch',
			'Sommerach',
			'Sippersfeld',
			'Seubersdorf',
			'Seßlach',
			'Sengenthal',
			'Sendenhorst',
			'Selsingen',
			'Selb',
			'Seinsheim',
			'Seedorf',
			'Seebach',
			'Seck',
			'Schönwald',
			'Schönewalde',
			'Schönau im Schwarzwald',
			'Schömberg',
			'Schirmitz',
			'Sassnitz',
			'Salgen',
			'Saalfeld',
			'Runding',
			'Rudersberg',
			'Rottweil',
			'Röthlein',
			'Rösrath',
			'Ribnitz-Damgarten',
			'Rheinbrohl',
			'Rheinböllen',
			'Rheda-Wiedenbrück',
			'Reiskirchen',
			'Reichenschwand',
			'Pürgen',
			'Prittitz',
			'Pracht',
			'Plettenberg',
			'Pfreimd',
			'Pasing',
			'Osterfeld',
			'Ohlsdorf',
			'Offenhausen',
			'Oberndorf',
			'Nübbel',
			'Nordrach',
			'Nordhalben',
			'Niemegk',
			'Niederaichbach',
			'Nideggen',
			'Neuzelle',
			'Neu Zauche',
			'Neutrebbin',
			'Neumark',
			'Münsingen',
			'Münnerstadt',
			'Müncheberg',
			'Mühltroff',
			'Mühlingen',
			'Mühlhausen',
			'Mühlhausen',
			'Moosthenning',
			'Mömlingen',
			'Mittenwalde',
			'Mistelgau',
			'Mildstedt',
			'Metten',
			'Merzig',
			'Mengenich',
			'Memmelsdorf',
			'Mellenbach-Glasbach',
			'Meiningen',
			'Mehring',
			'Meerbeck',
			'Marquartstein',
			'Luhe-Wildenau',
			'Lüdinghausen',
			'Loßburg',
			'Löningen',
			'Lindlar',
			'Liebenau',
			'Liebenau',
			'Letzlingen',
			'Letschin',
			'Leopoldshöhe',
			'Lengefeld',
			'Leinburg',
			'Lauterecken',
			'Lauter',
			'Lauf',
			'Laubach',
			'Lampertheim',
			'Kürten',
			'Kurort Jonsdorf',
			'Küllstedt',
			'Kritzmow',
			'Krickenbach',
			'Krebeck',
			'Kottenheim',
			'Korb',
			'Konz',
			'Koblenz',
			'Klostermansfeld',
			'Klettbach',
			'Klein Gusborn',
			'Kirchheim am Neckar',
			'Kirchhain',
			'Karlshuld',
			'Jerxheim',
			'Ilshofen',
			'Hoya',
			'Hohenbrunn',
			'Hohenaspe',
			'Höheischweiler',
			'Hitzacker',
			'Hirschbach',
			'Hillesheim',
			'Hettenleidelheim',
			'Hessigheim',
			'Herzhorn',
			'Hemsbünde',
			'Heistenbach',
			'Heimbuchenthal',
			'Heemsen',
			'Hattingen',
			'Handeloh',
			'Hanau am Main',
			'Hamdorf',
			'Haldenwang',
			'Haldenwang',
			'Haidmühle',
			'Hackenheim',
			'Grünau',
			'Großzschocher',
			'Groß Munzel',
			'Groß Ippener',
			'Großhennersdorf',
			'Großalmerode',
			'Gremberghoven',
			'Grafhorst',
			'Gößweinstein',
			'Gochsheim',
			'Gilserberg',
			'Giersleben',
			'Gersheim',
			'Gerolzhofen',
			'Gerabronn',
			'Gelenau',
			'Geldersheim',
			'Gefrees',
			'Geesthacht',
			'Gatow',
			'Gaildorf',
			'Fürstenstein',
			'Fridolfing',
			'Fridingen an der Donau',
			'Freystadt',
			'Freihung',
			'Frankenau',
			'Forst',
			'Forchtenberg',
			'Feuchtwangen',
			'Feilbingert',
			'Etzelwang',
			'Estorf',
			'Erpolzheim',
			'Erding',
			'Eppenbrunn',
			'Ensdorf',
			'Endenich',
			'Elkenroth',
			'Eigeltingen',
			'Eibau',
			'Ehrenfriedersdorf',
			'Ebermannstadt',
			'Ebermannsdorf',
			'Dürmentingen',
			'Dunningen',
			'Dranske',
			'Dorfhain',
			'Dommershausen',
			'Dingolfing',
			'Dienheim',
			'Dettenhausen',
			'Dettelbach',
			'Dessau',
			'Schönburg',
			'Schneizlreuth',
			'Schifferstadt',
			'Schiffdorf',
			'Scheuerfeld',
			'Scheibenberg',
			'Scheer',
			'Rottenacker',
			'Schwarzenbach',
			'Schönau',
			'Scheßlitz',
			'Ruhmannsfelden',
			'Rothenberg',
			'Dalheim',
			'Dahlenwarsleben',
			'Cornberg',
			'Coppenbrügge',
			'Burkardroth',
			'Burgwindheim',
			'Burgbernheim',
			'Bünde',
			'Büdelsdorf',
			'Buchholz in der Nordheide',
			'Brück',
			'Breitscheidt',
			'Braunschweig',
			'Boizenburg',
			'Böhlen',
			'Bobenheim-Roxheim',
			'Blender',
			'Bleckede',
			'Biberbach',
			'Berghaupten',
			'Bayerbach',
			'Bassum',
			'Bargteheide',
			'Bärenstein',
			'Baienfurt',
			'Bad Schwalbach',
			'Bad Mergentheim',
			'Axstedt',
			'Atzendorf',
			'Armsheim',
			'Altenbüren',
			'Alpirsbach',
			'Agathenburg',
			'Scheden',
			'Ruppach-Goldhausen',
			'Florstadt',
			'Ebersbach',
			'Ransbach-Baumbach',
			'Stadecken-Elsheim',
			'Rodgau',
			'Butjadingen',
			'Rodenkirchen',
			'Solms',
			'Altewiek',
			'Hauptbahnhof',
			'Kelkheim-Mitte',
			'Ostseebad Zinnowitz',
			'Zerf',
			'Wyk auf Föhr',
			'Wurmsham',
			'Wremen',
			'Wörth an der Isar',
			'Wolkramshausen',
			'Wolfsburg',
			'Wittorf',
			'Wippra',
			'Wipperfürth',
			'Windesheim',
			'Wimmelburg',
			'Wilsum',
			'Wettringen',
			'Westergellersen',
			'Wendlingen am Neckar',
			'Welschbillig',
			'Weißbach',
			'Wehrda',
			'Wefensleben',
			'Wasserleben',
			'Wäschenbeuren',
			'Waldrach',
			'Waldkappel',
			'Waldalgesheim',
			'Wald',
			'Vordorf',
			'Vechelde',
			'Üxheim',
			'Usedom',
			'Urmitz',
			'Tungendorf',
			'Thal',
			'Tanna',
			'Tangerhütte',
			'Taching am See',
			'Sulzheim',
			'Sulzemoos',
			'Störnstein',
			'Stockstadt am Rhein',
			'Stockheim',
			'Staudernheim',
			'Stadtoldendorf',
			'Spabrücken',
			'Sontheim an der Brenz',
			'Sinn',
			'Singhofen',
			'Siegen',
			'Selmsdorf',
			'Sehnde',
			'Sehlen',
			'Riederich',
			'Rieden',
			'Reutlingen',
			'Reinhardshausen',
			'Reeßum',
			'Rauen',
			'Ranstadt',
			'Rammingen',
			'Premnitz',
			'Poll',
			'Pöcking',
			'Piesteritz',
			'Pegnitz',
			'Pasewalk',
			'Othmarschen',
			'Ostseebad Binz',
			'Ostrhauderfehn',
			'Osterburg',
			'Oldendorf',
			'Ofterdingen',
			'Offstein',
			'Oelsnitz',
			'Ockenheim',
			'Ochsenwerder',
			'Obernzenn',
			'Oberhaid',
			'Oberdachstetten',
			'Oberbiel',
			'Nützen',
			'Nordendorf',
			'Niedernhall',
			'Niedergörsdorf',
			'Niederdorfelden',
			'Neunkirchen',
			'Neu-Isenburg',
			'Neuhaus am Rennweg',
			'Neuenkirchen',
			'Neuenfelde',
			'Neudrossenfeld',
			'Nauort',
			'Münsterdorf',
			'Münsing',
			'Mudau',
			'Much',
			'Möser',
			'Moosbach',
			'Mittweida',
			'Midlum',
			'Meuselwitz',
			'Mascherode',
			'Malchin',
			'Lütjensee',
			'Lößnitz',
			'Lonsee',
			'Lingenfeld',
			'Lieblos',
			'Lichtentanne',
			'Lengerich',
			'Leitzkau',
			'Leiblfing',
			'Lasbek',
			'Langenmosen',
			'Langen',
			'Ladbergen',
			'Konzell',
			'Köngernheim',
			'Kölln-Reisiek',
			'Kollnburg',
			'Kölbingen',
			'Köditz',
			'Kleinsendelbach',
			'Kißlegg',
			'Kirchheim',
			'Kirchensittenbach',
			'Kirchdorf im Wald',
			'Kirchdorf',
			'Karsdorf',
			'Jesteburg',
			'Jerrishoe',
			'Jävenitz',
			'Inchenhofen',
			'Husum',
			'Hüffenhardt',
			'Holzheim',
			'Hollingstedt',
			'Hohnstein',
			'Himbergen',
			'Hilgert',
			'Heuchelheim',
			'Herbertingen',
			'Hehlen',
			'Heede',
			'Hasbergen',
			'Hartmannsdorf',
			'Hanhofen',
			'Hammah',
			'Haiming',
			'Hagenbach',
			'Hachenburg',
			'Grünhain',
			'Großweil',
			'Großräschen',
			'Groß Lindow',
			'Groß Flottbek',
			'Großengottern',
			'Großbardau',
			'Görsbach',
			'Gommersheim',
			'Geringswalde',
			'Gera',
			'Garmisch-Partenkirchen',
			'Ganderkesee',
			'Gammelshausen',
			'Furtwangen',
			'Friedersdorf',
			'Fraunberg',
			'Frankenberg',
			'Förderstedt',
			'Flonheim',
			'Flensburg',
			'Fahrdorf',
			'Espenhain',
			'Eslohe',
			'Esens',
			'Epfenbach',
			'Elzach',
			'Elsnig',
			'Ellerbek',
			'Ellerau',
			'Eichendorf',
			'Eggolsheim',
			'Echem',
			'Ebensfeld',
			'Dürnau',
			'Drangstedt',
			'Dornstetten',
			'Zscherndorf',
			'Wuppertal',
			'Wulfen',
			'Wölfis',
			'Wittenburg',
			'Wittenau',
			'Witten',
			'Wissen',
			'Wirdum',
			'Wilhelmsdorf',
			'Wildberg',
			'Wiefelstede',
			'Wiedemar',
			'Wernau',
			'Werlte',
			'Weddingstedt',
			'Wangen',
			'Wallhausen',
			'Waldenburg',
			'Waidhofen',
			'Wackersberg',
			'Vreden',
			'Villenbach',
			'Vaterstetten',
			'Unterschleißheim',
			'Unteregg',
			'Türkheim',
			'Triebes',
			'Treis-Karden',
			'Traitsching',
			'Tappenbeck',
			'Tannenberg',
			'Syrau',
			'Steinhorst',
			'Steinberg',
			'Steinbach',
			'Stadtallendorf',
			'Sommerkahl',
			'Soltendieck',
			'Senden',
			'Selm',
			'Seehausen',
			'Schwarzach',
			'Schmidmühlen',
			'Scharfenstein',
			'Schalkau',
			'Sasbachwalden',
			'Sarstedt',
			'Sankt Märgen',
			'St. Georg',
			'Saarlouis',
			'Rüthen',
			'Rötgesbüttel',
			'Rosenthal',
			'Rohr',
			'Rödinghausen',
			'Denklingen',
			'Deensen',
			'Cochstedt',
			'Cochem',
			'Bad Camberg',
			'Buxheim',
			'Bühlertann',
			'Buckenhof',
			'Büchenbeuren',
			'Brühl',
			'Breese',
			'Bovenden',
			'Borne',
			'Borken',
			'Boostedt',
			'Bischofsheim',
			'Birkenfeld',
			'Binau',
			'Bergtheim',
			'Beesenstedt',
			'Bargfeld-Stegen',
			'Bad Soden am Taunus',
			'Bad Aibling',
			'Bach',
			'Appen',
			'Anröchte',
			'Anhausen',
			'Amendingen',
			'Altshausen',
			'Altötting',
			'Altdorf bei Nurnberg',
			'Altdöbern',
			'Aldenhoven',
			'Albersweiler',
			'Aitrach',
			'Ahlsdorf',
			'Ahlerstedt',
			'Adelsried',
			'Achstetten',
			'Bordelum',
			'Gattendorf',
			'Falkenhagener Feld',
			'Faldera',
			'Hoheluft-Ost',
			'Riegelsberg',
			'Rheinhausen',
			'Rettenbach',
			'Remagen',
			'Reichardtswerben',
			'Ramsau',
			'Quern',
			'Putzbrunn',
			'Poppenricht',
			'Pliezhausen',
			'Paderborn',
			'Overath',
			'Osteel',
			'Oelde',
			'Obhausen',
			'Obersüßbach',
			'Nürtingen',
			'Nörvenich',
			'Nieder-Ingelheim',
			'Neverin',
			'Neustadt in Sachsen',
			'Neukirchen',
			'Neukieritzsch',
			'Neuburg',
			'Nauroth',
			'Nanzdietschweiler',
			'Hannoversch Münden',
			'Münchsteinach',
			'Mühl Rosin',
			'Mirow',
			'Miltach',
			'Mildenau',
			'Michelau',
			'Merzen',
			'Melbach',
			'Mechernich',
			'Marktzeuln',
			'Marktschellenberg',
			'Marktoberdorf',
			'Marksuhl',
			'Lübow',
			'Lorup',
			'Loitz',
			'Lindewitt',
			'Lawalde',
			'Langenbach bei Marienberg',
			'Lampertswalde',
			'Kutenholz',
			'Kunreuth',
			'Kummerfeld',
			'Kühbach',
			'Kropp',
			'Kronach',
			'Köthen',
			'Königshain',
			'Ködnitz',
			'Klosterlechfeld',
			'Kirchham',
			'Kirchen',
			'Kettig',
			'Kessenich',
			'Kaulsdorf',
			'Karlshorst',
			'Kahl am Main',
			'Jemgum',
			'Jembke',
			'Hosenfeld',
			'Horstmar',
			'Hollenstedt',
			'Hohendodeleben',
			'Hofstetten',
			'Hirschfeld',
			'Hinterweidenthal',
			'Hille',
			'Hildburghausen',
			'Hetlingen',
			'Hemmingen',
			'Hellwege',
			'Hellingen',
			'Heiden',
			'Harsum',
			'Hardheim',
			'Halsenbach',
			'Hagenbüchach',
			'Günzach',
			'Budestecy',
			'Großenehrich',
			'Grossenbrode',
			'Gröbers',
			'Gröbenzell',
			'Griesheim',
			'Greifenberg',
			'Grebin',
			'Graben',
			'Goldbach',
			'Goch',
			'Glashütten',
			'Gelbensande',
			'Geithain',
			'Geiersthal',
			'Gammelsdorf',
			'Fürthen',
			'Fürstenwalde',
			'Friesenried',
			'Freden',
			'Frauenstein',
			'Frauenau',
			'Forbach',
			'Flein',
			'Fichtenwalde',
			'Ferdinandshof',
			'Eyendorf',
			'Eußenheim',
			'Etzbach',
			'Erlbach',
			'Erkner',
			'Eriskirch',
			'Erbach',
			'Egling',
			'Eggenthal',
			'Eckernförde',
			'Ebersberg',
			'Drage',
			'Dornstadt',
			'Doberschütz',
			'Dillingen an der Donau',
			'Dietersheim',
			'Diepholz',
			'Diedorf',
			'Diebach',
			'Dexheim',
			'Damme',
			'Crostau',
			'Contwig',
			'Cham',
			'Castrop-Rauxel',
			'Cadolzburg',
			'Cadenberge',
			'Burk',
			'Bundenbach',
			'Bühren',
			'Buggingen',
			'Brüggen',
			'Breitenfeld',
			'Breiholz',
			'Bramsche',
			'Bokel',
			'Böblingen',
			'Böbing',
			'Bitburg',
			'Birlenbach',
			'Binnen',
			'Bietigheim-Bissingen',
			'Biebrich',
			'Bernkastel-Kues',
			'Berga',
			'Bautzen',
			'Barendorf',
			'Baisweil',
			'Bad Wörishofen',
			'Bad Liebenwerda',
			'Baalberge',
			'Angersdorf',
			'Altenfeld',
			'Alsleben',
			'Alfter',
			'Albershausen',
			'Ahaus',
			'Straßlach-Dingharting',
			'Uhldingen-Mühlhofen',
			'Helgoland',
			'Lohe-Rickelshof',
			'Nessa',
			'Vogtsburg',
			'Brohl-Lützing',
			'Mörfelden-Walldorf',
			'Altenbuch',
			'Stadtkern',
			'Zwiefalten',
			'Zweibrücken',
			'Zornheim',
			'Zeven',
			'Zemmer',
			'Wünschendorf',
			'Wolfenbüttel',
			'Wittichenau',
			'Wietze',
			'Wiesenau',
			'Wewelsfleth',
			'Westerheim',
			'Werpeloh',
			'Werdohl',
			'Wendtorf',
			'Wendorf',
			'Wendisch Evern',
			'Weitnau',
			'Weißensberg',
			'Weismain',
			'Weilerswist',
			'Weichering',
			'Wasserburg am Inn',
			'Waldmünchen',
			'Waldfeucht',
			'Waldbreitbach',
			'Wald',
			'Wachtendonk',
			'Viersen',
			'Versmold',
			'Vallendar',
			'Untermünkheim',
			'Unterdießen',
			'Ummendorf',
			'Tuttlingen',
			'Tüttendorf',
			'Trebbin',
			'Thyrnau',
			'Thum',
			'Thuine',
			'Thalheim',
			'Teutschenthal',
			'Teublitz',
			'Tettnang',
			'Syke',
			'Sukow',
			'Ströbeck',
			'Stöckheim',
			'Stein',
			'Starnberg',
			'Stammham',
			'Stadtlauringen',
			'Springe',
			'Spergau',
			'Söhlde',
			'Siersleben',
			'Seukendorf',
			'Sehlem',
			'Seesen',
			'Schwedt (Oder)',
			'Schwarzenfeld',
			'Schopp',
			'Schlangenbad',
			'Schermen',
			'Schermbeck',
			'Schafflund',
			'Röttingen',
			'Rotthalmünster',
			'Rickenbach',
			'Rheinzabern',
			'Reichenbach/Vogtland',
			'Rauenberg',
			'Rain',
			'Quarnbek',
			'Pretzfeld',
			'Polling',
			'Pollhagen',
			'Pirmasens',
			'Pilsach',
			'Perleberg',
			'Penig',
			'Oyten',
			'Ötzingen',
			'Ottweiler',
			'Otterstadt',
			'Otterndorf',
			'Ottenhofen',
			'Ötisheim',
			'Osterhofen',
			'Osterhausen',
			'Oldendorf',
			'Oberkochen',
			'Oberammergau',
			'Nusse',
			'Nordstemmen',
			'Niederzissen',
			'Niederschönhausen',
			'Niederneisen',
			'Niederdürenbach',
			'Neustadt-Glewe',
			'Neuburg',
			'Bad Neualbenreuth',
			'Nerchau',
			'Negernbötel',
			'Mühlheim am Main',
			'Mörlenbach',
			'Monzingen',
			'Monschau',
			'Mittelbiberach',
			'Milz',
			'Mering',
			'Mengkofen',
			'Melle',
			'Maxhütte-Haidhof',
			'Maulburg',
			'Marzling',
			'Martinhagen',
			'Marktbreit',
			'Mariental',
			'Marienheide',
			'Malente',
			'Maisach',
			'Lübtheen',
			'Lohra',
			'Löchgau',
			'Löbichau',
			'Lindenberg',
			'Liebenburg',
			'Lichtenfels',
			'Lichtenberg',
			'Leutershausen',
			'Leezdorf',
			'Laudenbach',
			'Lauchhammer',
			'Langenzenn',
			'Kyllburg',
			'Kürnach',
			'Krummennaab',
			'Kreuzwertheim',
			'Korbach',
			'Königs Wusterhausen',
			'Königswinter',
			'Bad Königshofen im Grabfeld',
			'Klipphausen',
			'Kirchworbis',
			'Kirchseeon',
			'Kirchardt',
			'Kempenich',
			'Kasendorf',
			'Karow',
			'Jever',
			'Jagsthausen',
			'Irxleben',
			'Igel',
			'Hördt',
			'Holdorf',
			'Höingen',
			'Hohentengen',
			'Hohenhameln',
			'Hirschau',
			'Herzogenaurach',
			'Hennstedt',
			'Heikendorf',
			'Heeslingen',
			'Hedersleben',
			'Hausen',
			'Happurg',
			'Hamm',
			'Hadamar',
			'Güglingen',
			'Grünendeich',
			'Groß Wokern',
			'Großwallstadt',
			'Groß-Gerau',
			'Großenstein',
			'Groß Düben',
			'Großbothen',
			'Großbartloff',
			'Grimmen',
			'Grenzach-Wyhlen',
			'Greifenstein',
			'Greding',
			'Grabenstetten',
			'Glücksburg',
			'Glienicke',
			'Glaubitz',
			'Gladenbach',
			'Geldern',
			'Geiselhöring',
			'Gartz',
			'Fußgönheim',
			'Fürstenzell',
			'Fronhausen',
			'Friesack',
			'Freudenberg',
			'Freienbessingen',
			'Frauenwald',
			'Frammersbach',
			'Fladungen',
			'Escheburg',
			'Erzhausen',
			'Engelskirchen',
			'Emtinghausen',
			'Emmering',
			'Emmelshausen',
			'Emkendorf',
			'Eichenau',
			'Ehningen',
			'Ehekirchen',
			'Eggstätt',
			'Eggesin',
			'Eching',
			'Eberbach',
			'Ebendorf',
			'Durbach',
			'Dörrenbach',
			'Dombühl',
			'Dollern',
			'Deuna',
			'Dettum',
			'Zwota',
			'Zurow',
			'Zülpich',
			'Zorneding',
			'Zehlendorf',
			'Wurmlingen',
			'Wulkenzin',
			'Wolkenstein',
			'Wolgast',
			'Wolfstein',
			'Wingerode',
			'Windsbach',
			'Wildenhain',
			'Wietmarschen',
			'Wiesbaden',
			'Weßling',
			'Wersten',
			'Wermsdorf',
			'Wenden',
			'Wendelstein',
			'Weilheim',
			'Wehr',
			'Weener',
			'Warngau',
			'Warburg',
			'Waltenhofen',
			'Wallhausen',
			'Wallerfangen',
			'Wallenfels',
			'Waldthurn',
			'Wahrenholz',
			'Wachau',
			'Volxheim',
			'Vogt',
			'Velden',
			'Uthleben',
			'Uenglingen',
			'Ueckermünde',
			'Üdersdorf',
			'Uder',
			'Tussenhausen',
			'Trossin',
			'Trierweiler',
			'Todenbüttel',
			'Teisendorf',
			'Tannheim',
			'Talheim',
			'Süsel',
			'Sugenheim',
			'Straelen',
			'Steinwenden',
			'Steinfurt',
			'Sonsbeck',
			'Sonnefeld',
			'Sonneberg',
			'Sembach',
			'Schwalmstadt',
			'Schwalbach',
			'Schwabsoien',
			'Schwaan',
			'Schuttertal',
			'Schönbach',
			'Schmölln',
			'Schlieben',
			'Schenklengsfeld',
			'Schalksmühle',
			'Sankt Ingbert',
			'Sankt Gangloff',
			'Sachsenhagen',
			'Rottenbuch',
			'Röthenbach an der Pegnitz',
			'Röhrmoos',
			'Roggentin',
			'Rödelsee',
			'Rietschen',
			'Burghaslach',
			'Büchlberg',
			'Buchdorf',
			'Bruchweiler-Bärenbach',
			'Brotterode',
			'Breitenbrunn',
			'Bornheim',
			'Bodolz',
			'Blankenhain',
			'Bennstedt',
			'Balzfeld',
			'Balge',
			'Bad Zwischenahn',
			'Bad Wurzach',
			'Bad Sülze',
			'Bad Salzuflen',
			'Bad Hönningen',
			'Baden-Baden',
			'Bad Elster',
			'Bad Bramstedt',
			'Bad Blankenburg',
			'Bad Bibra',
			'Bad Berleburg',
			'Bad Bentheim',
			'Aufseß',
			'Arrach',
			'Arnsberg',
			'Apensen',
			'Annaburg',
			'Altglienicke',
			'Altenmedingen',
			'Altenkirchen',
			'Bad Alexandersbad',
			'Affalterbach',
			'Adenau',
			'Achtrup',
			'Achim',
			'Röslau',
			'Neumagen-Dhron',
			'Schweigen-Rechtenbach',
			'Riedenberg',
			'Ebersbach-Musbach',
			'Rödersheim-Gronau',
			'Plänterwald',
			'Bilderstöckchen',
			'Hansaviertel',
			'Lachen-Speyerdorf',
			'Sternschanze',
			'Querumer Forst',
			'Industriegebiet',
			'Riedenburg',
			'Rellingen',
			'Reit im Winkl',
			'Reimlingen',
			'Rednitzhembach',
			'Rechlin',
			'Rechberghausen',
			'Rautheim',
			'Randersacker',
			'Radeberg',
			'Radbruch',
			'Prenzlau',
			'Polch',
			'Pocking',
			'Planegg',
			'Kleinschmalkalden',
			'Oelixdorf',
			'Offenburg',
			'Offenbach',
			'Obertrubach',
			'Oberstreu',
			'Nörtershausen',
			'Niederviehbach',
			'Neudenau',
			'Neinstedt',
			'Neidlingen',
			'Munderkingen',
			'Münchsmünster',
			'Mühlheim am Bach',
			'Müden',
			'Mölln',
			'Möhlau',
			'Mittelschöntal',
			'Mittelherwigsdorf',
			'Mittegroßefehn',
			'Mettenheim',
			'Meßstetten',
			'Merdingen',
			'Melverode',
			'Melsdorf',
			'Meldorf',
			'Mastershausen',
			'Marxheim',
			'Marktgraitz',
			'Markt Berolzheim',
			'Mansfeld',
			'Malgersdorf',
			'Magstadt',
			'Lurup',
			'Lübz',
			'Losheim',
			'Lollar',
			'Lingen',
			'Leonberg',
			'Leidersbach',
			'Leibertingen',
			'Lehmkuhlen',
			'Langenfeld',
			'Langeneichstädt',
			'Lamerdingen',
			'Laar',
			'Laage',
			'Kuddewörde',
			'Kubschütz',
			'Kruft',
			'Kretzschau',
			'Krauthausen',
			'Kötzting',
			'Körle',
			'Knittlingen',
			'Klüsserath',
			'Kleve',
			'Kleinmachnow',
			'Klausen',
			'Kissenbrück',
			'Kirchheim',
			'Kemberg',
			'Kehrig',
			'Karlstadt',
			'Kaisheim',
			'Jüterbog',
			'Irsch',
			'Inzlingen',
			'Ingelheim am Rhein',
			'Ibbenbüren',
			'Hörselgau',
			'Holzweißig',
			'Hohenthurm',
			'Herrngiersdorf',
			'Heiligenberg',
			'Heideck',
			'Hatzenbühl',
			'Hartenstein',
			'Marienthal',
			'Günzburg',
			'Gumtow',
			'Guderhandviertel',
			'Großwoltersdorf',
			'Großnaundorf',
			'Großkrotzenburg',
			'Großharthau',
			'Großaitingen',
			'Gröbzig',
			'Grasbrunn',
			'Giebelstadt',
			'Georgsmarienhütte',
			'Genthin',
			'Gardelegen',
			'Gaimersheim',
			'Fischerbach',
			'Finsing',
			'Feucht',
			'Fedderwarden',
			'Fachbach',
			'Eydelstedt',
			'Estenfeld',
			'Ertingen',
			'Erlenmoos',
			'Ergersheim',
			'Sankt Englmar',
			'Emsbüren',
			'Emmering',
			'Emlichheim',
			'Elstertrebnitz',
			'Elgersburg',
			'Eißendorf',
			'Einbeck',
			'Eggenstein-Leopoldshafen',
			'Dürrlauingen',
			'Drensteinfurt',
			'Dreisen',
			'Drebkau',
			'Dorfchemnitz',
			'Dollerup',
			'Dobien',
			'Dietenheim',
			'Diesdorf',
			'Detmold',
			'Denzlingen',
			'Denkingen',
			'Deggendorf',
			'Dassendorf',
			'Daaden',
			'Crottendorf',
			'Bütthard',
			'Burtenbach',
			'Bundenthal',
			'Budenheim',
			'Bücken',
			'Bucha',
			'Brügge',
			'Britz',
			'Breuna',
			'Breitengüßbach',
			'Breitenbach',
			'Bonefeld',
			'Blowatz',
			'Blankensee',
			'Bad Birnbach',
			'Beverungen',
			'Betzendorf',
			'Berlstedt',
			'Berlingerode',
			'Bergneustadt',
			'Bergkirchen',
			// 'Bergen',
			'Berg',
			'Bebra',
			'Basdahl',
			'Barleben',
			'Bamberg',
			'Baindt',
			'Bad Orb',
			'Bad Grund',
			'Bad Berneck im Fichtelgebirge',
			'Aurich',
			'Aub',
			'Assamstadt',
			'Arnstorf',
			'Arnstein',
			'Apfelstädt',
			'Angern',
			'Altusried',
			'Alt Tucheband',
			'Alsdorf',
			'Alfdorf',
			'Aerzen',
			'Adlershof',
			'Thalfang',
			'Mühlhausen-Ehingen',
			'Muhr am See',
			'Lautertal',
			'Dreikirchen',
			'Kirchwald',
			'Kanzlerfeld',
			'Bensdorf',
			'Kranichstein',
			'Münstertal/Schwarzwald',
			'Alt-Hohenschönhausen',
			'Neustadt',
			'Fronreute',
			'Langenbrettach',
			'Siegfriedviertel',
			'Bürgerpark',
			'Zusamaltheim',
			'Zschopau',
			'Zabeltitz',
			'Wüstenrot',
			'Wonfurt',
			'Windischeschenbach',
			'Willebadessen',
			'Wilhelmsruh',
			'Wilhelmsburg',
			'Wietzendorf',
			'Weselberg',
			'Wertingen',
			'Wernberg-Köblitz',
			'Weißwasser',
			'Weißenbrunn',
			'Weil im Schönbuch',
			'Weil der Stadt',
			'Wehr',
			'Wegberg',
			'Wees',
			'Wasserliesch',
			'Warmsen',
			'Wannsee',
			'Wallerfing',
			'Walldürn',
			'Walldorf',
			'Waldshut-Tiengen',
			'Wackersdorf',
			'Vörstetten',
			'Volkertshausen',
			'Vögelsen',
			'Vilshofen',
			'Vilgertshofen',
			'Urspringen',
			'Uckerath',
			'Übersee',
			'Trebgast',
			'Todesfelde',
			'Thannhausen',
			'Teunz',
			'Taunusstein',
			'Tating',
			'Sundern',
			'Sünching',
			'Steinwiesen',
			'Steinweiler',
			'Steinheim',
			'Steinbach-Hallenberg',
			'Stadthagen',
			'Spraitbach',
			'Spiesheim',
			'Sonnewalde',
			'Simmozheim',
			'Simmern',
			'Sigmaringendorf',
			'Seth',
			'Senftenberg',
			'Seelow',
			'Schneverdingen',
			'Schneeberg',
			'Schlüchtern',
			'Schlagsdorf',
			'Schirnding',
			'Schiltberg',
			'Schelklingen',
			'Schechingen',
			'Schäftlarn',
			'Sandstedt',
			'Sandberg',
			'Saal',
			'Rummelsburg',
			'Rottendorf',
			'Rottenbach',
			'Rottach-Egern',
			'Rothenstein',
			'Rothenbergen',
			'Roßtal',
			'Rossbach',
			'Rositz',
			'Rosbach vor der Höhe',
			'Röfingen',
			'Rodenbach',
			'Röbel',
			'Rietz Neuendorf',
			'Ribbesbüttel',
			'Rheinbreitbach',
			'Reuth',
			'Reichling',
			'Rechtsupweg',
			'Rauschenberg',
			'Radegast',
			'Quakenbrück',
			'Pyrbaum',
			'Pritzwalk',
			'Pöhla',
			'Plau am See',
			'Pfeffelbach',
			'Pfarrweisach',
			'Pfalzgrafenweiler',
			'Pennigsehl',
			'Panker',
			'Otzing',
			'Ostseebad Göhren',
			'Osterwieck',
			'Oerlenbach',
			'Ölper',
			'Oldisleben',
			'Öhringen',
			'Offenberg',
			'Oderberg',
			'Oberviechtach',
			'Obersinn',
			'Oberpleichfeld',
			'Oberleichtersbach',
			'Obergünzburg',
			'Nister',
			'Niesky',
			'Niepars',
			'Nienhagen',
			'Niederfrohna',
			'Neu Wulmstorf',
			'Neunkirchen am Sand',
			'Neugersdorf',
			'Neuental',
			'Neckargemünd',
			'Nahe',
			'Mutzschen',
			'Mulda',
			'Mielkendorf',
			'Michelbach an der Bilz',
			'Mettenheim',
			'Mellinghausen',
			'Maxsain',
			'Maselheim',
			'Martensrade',
			'Maroldsweisach',
			'Marloffstein',
			'Marktleuthen',
			'Marktl',
			'Marienfelde',
			'Mariaposching',
			'Marbach am Neckar',
			'Malsch',
			'Lütjenburg',
			'Lunestedt',
			'Lubin',
			'Lörzweiler',
			'Lonnerstadt',
			'Lohr am Main',
			'Lohne',
			'Löhne',
			'Löbau',
			'Lindenberg',
			'Lichte',
			'Leverkusen',
			'Lenzkirch',
			'Lengerich',
			'Leinfelden-Echterdingen',
			'Leinefelde-Worbis',
			'Laupheim',
			'Laufenburg',
			'Lauenburg',
			'Lauben',
			'Langgöns',
			'Landsberg am Lech',
			'Laatzen',
			'Künzing',
			'Kodersdorf',
			'Klütz',
			'Klingenmünster',
			'Kitzscher',
			'Kirchheim unter Teck',
			'Kirchheim',
			'Kiefersfelden',
			'Kemmern',
			'Kelbra',
			'Katzhütte',
			'Kastl',
			'Kammerstein',
			'Joachimsthal',
			'Jestetten',
			'Innernzell',
			'Immenreuth',
			'Icking',
			'Horb am Neckar',
			'Holleben',
			'Hofbieber',
			'Hochspeyer',
			'Hitzhusen',
			'Hillscheid',
			'Hildesheim',
			'Hedersleben',
			'Hardegsen',
			'Hallbergmoos',
			'Hainsfarth',
			'Hagelstadt',
			'Gudow',
			'Grosselfingen',
			'Gottenheim',
			'Goldbach',
			'Glinde',
			'Gillenfeld',
			'Gengenbach',
			'Gailingen',
			'Fürstenberg',
			'Fürstenberg',
			'Friesoythe',
			'Freisbach',
			'Freiburg/Elbe',
			'Frankfurt (Oder)',
			'Flomborn',
			'Flessau',
			'Everswinkel',
			'Erlbach',
			'Erlabrunn',
			'Eppelheim',
			'Epfendorf',
			'Enkenbach-Alsenborn',
			'Engen',
			'Eichenzell',
			'Ehlscheid',
			'Ehingen',
			'Egglham',
			'Ebernhahn',
			'Dummerstorf',
			'Duggendorf',
			'Dietmannsried',
			'Dieskau',
			'Dieblich',
			'Zschortau',
			'Zöschen',
			'Zeiskam',
			'Wustrow',
			'Würselen',
			'Bad Wünnenberg',
			'Woringen',
			'Wölfersheim',
			'Wittstock',
			'Wintersdorf',
			'Winterlingen',
			'Winnweiler',
			'Wiesensteig',
			'Wesseling',
			'Weinheim',
			'Weiler',
			'Weil am Rhein',
			'Warnemünde',
			'Warendorf',
			'Wardenburg',
			'Wallwitz',
			'Wahn-Heide',
			'Wagenfeld',
			'Wackerow',
			'Völklingen',
			'Viöl',
			'Vilsbiburg',
			'Veringenstadt',
			'Veilsdorf',
			'Varrel',
			'Vaale',
			'Ursensollen',
			'Unterthingau',
			'Unterreit',
			'Uichteritz',
			'Uerdingen',
			'Uelversheim',
			'Trunkelsberg',
			'Trassem',
			'Thundorf in Unterfranken',
			'Teuschnitz',
			'Tarmstedt',
			'Straubing',
			'Steißlingen',
			'Steinhagen',
			'Steina',
			'Steglitz',
			'Stedesdorf',
			'Staudach-Egerndach',
			'Staßfurt',
			'Solingen',
			'Serrig',
			'Selters',
			'Schöppingen',
			'Schöppenstedt',
			'Schönwald',
			'Schönborn',
			'Schöllnach',
			'Schochwitz',
			'Schliersee',
			'Schlier',
			'Schliengen',
			'Schenkendöbern',
			'Schallstadt',
			'Schacht-Audorf',
			'Sasbach',
			'Sankt Michaelisdonn',
			'Sankt Margarethen',
			'Sankt Andreasberg',
			'Sangerhausen',
			'Rüningen',
			'Rülzheim',
			'Ruhwinkel',
			'Ruderting',
			'Rosenheim',
			'Rohrdorf',
			'Rimbach',
			'Dermbach',
			'Dahme',
			'Dahlem',
			'Coswig',
			'Colditz',
			'Chemnitz',
			'Buch',
			'Buch',
			'Britten',
			'Breklum',
			'Breitenworbis',
			'Breisach am Rhein',
			'Bredenbek',
			'Bovenau',
			'Bösenbrunn',
			'Bockenem',
			'Blaubeuren',
			'Birkenfeld',
			'Betheln',
			'Berumbur',
			'Bernau am Chiemsee',
			'Benningen',
			'Beerfelden',
			'Beelen',
			'Beckingen',
			'Bechtolsheim',
			'Bargstedt',
			'Barchfeld',
			'Bannewitz',
			'Bad Vilbel',
			'Bad Brambach',
			'Ayl',
			'Augustdorf',
			'Aschersleben',
			'Apolda',
			'Anzing',
			'Altrip',
			'Altenkunstadt',
			'Altefähr',
			'Alsfeld',
			'Aletshausen',
			'Ahorn',
			'Ahlden',
			'Adelebsen',
			'Achern',
			'Seitingen-Oberflacht',
			'Bruchmühlbach-Miesau',
			'Blankenbach',
			'Graben-Neudorf',
			'Billigheim-Ingenheim',
			'Bertsdorf-Hörnitz',
			'Böcklersiedlung-Bugenhagen',
			'Kraichtal',
			'Reinsberg',
			'Püchersreuth',
			'Prutting',
			'Pronstorf',
			'Pittenhart',
			'Pfatter',
			'Pappenheim',
			'Pampow',
			'Ottensen',
			'Osten',
			'Osloß',
			'Oppurg',
			'Olsberg',
			'Olpe',
			'Oberpöring',
			'Obergurig',
			'Nordholz',
			'Niederwangen',
			'Niederklein',
			'Niederdreisbach',
			'Neuried',
			'Neunkhausen',
			'Neu-Anspach',
			'Neckarsulm',
			'Naundorf',
			'Nagold',
			'Nagel',
			'Murrhardt',
			'Münzenberg',
			'Moosach',
			'Mönsheim',
			'Moisburg',
			'Mittenwald',
			'Mieste',
			'Masburg',
			'Mahlsdorf',
			'Mackenbach',
			'Machern',
			'Lübstorf',
			'Löffingen',
			'Lobstädt',
			'Löbejün',
			'Linz am Rhein',
			'Lindenfels',
			'Lindau',
			'Lichterfelde',
			'Lichtenberg',
			'Lenggries',
			'Lautrach',
			'Lastrup',
			'Lanstrop',
			'Landau an der Isar',
			'Kusey',
			'Kremperheide',
			'Kraftsdorf',
			'Körner',
			'Konstanz',
			'Königsfeld',
			'Kolkwitz',
			'Knetzgau',
			'Klotzsche',
			'Klietz',
			'Klein-Winternheim',
			'Kirchwerder',
			'Kirchtimke',
			'Kirchanschöring',
			'Katharinenberg',
			'Kappeln',
			'Kandern',
			'Kalkar',
			'Hürup',
			'Hurlach',
			'Hörgertshausen',
			'Hettingen',
			'Hespe',
			'Herrmannshöhe',
			'Heldrungen',
			'Helbra',
			'Heiligengrabe',
			'Hatzfeld',
			'Hasselfelde',
			'Hardt',
			'Hangard',
			'Hagenow',
			'Güntersleben',
			'Großbardorf',
			'Greifswald',
			'Grebenhain',
			'Grasberg',
			'Gottmadingen',
			'Görlitz',
			'Gmund am Tegernsee',
			'Giengen an der Brenz',
			'Gescher',
			'Geislingen',
			'Geilenkirchen',
			'Geeste',
			'Gärtringen',
			'Fürth',
			'Friedrichsthal',
			'Freinsheim',
			'Freiberg am Neckar',
			'Filsum',
			'Eurasburg',
			'Essing',
			'Eschenbach',
			'Eschach',
			'Ergoldsbach',
			'Erfurt',
			'Egenhofen',
			'Egelsbach',
			'Edingen-Neckarhausen',
			'Ebersdorf',
			'Dürrwangen',
			'Drelsdorf',
			'Dreierwalde',
			'Dillenburg',
			'Dießen am Ammersee',
			'Zirndorf',
			'Zarpen',
			'Zapfendorf',
			'Wusterhusen',
			'Wolmirstedt',
			'Wollmatingen',
			'Wittgensdorf',
			'Winzer',
			'Wiesenfelden',
			'Wiesa',
			'Widdern',
			'Wettin',
			'Wesenberg',
			'Wesel',
			'Wertach',
			'Werda',
			'Welden',
			'Weinbach',
			'Weimar',
			'Wartmannsroth',
			'Waldbröl',
			'Völpke',
			'Vollersode',
			'Völkershausen',
			'Unterneukirchen',
			'Ummern',
			'Uelzen',
			'Trossingen',
			'Trappenkamp',
			'Thomasburg',
			'Tarnow',
			'Talheim',
			'Sulzbach',
			'Sudwalde',
			'Struppen',
			'Stöttwang',
			'Sternenfels',
			'Steinkirchen',
			'Speichersdorf',
			'Sosa',
			'Singen',
			'Simmersfeld',
			'Sieverstedt',
			'Siefersheim',
			'Siebeldingen',
			'Seulingen',
			'Seehausen',
			'Schwindegg',
			'Schwerte',
			'Schwepnitz',
			'Schwarmstedt',
			'Schwalmtal',
			'Schwaig',
			'Schmalkalden',
			'Schkeuditz',
			'Schaufling',
			'Sankt Peter-Ording',
			'Ruppertsweiler',
			'Ruhland',
			'Rothenfels',
			'Rothenburg ob der Tauber',
			'Roetgen',
			'Rotenburg an der Fulda',
			'Ronneburg',
			'Ringleben',
			'Deizisau',
			'Dassel',
			'Dahlenburg',
			'Crivitz',
			'Colbitz',
			'Burg Stargard',
			'Burgsinn',
			'Burgdorf',
			'Burbach',
			'Bückeburg',
			'Buchenberg',
			'Bubenreuth',
			'Bretzenheim',
			'Breitenbach',
			'Brahmenau',
			'Borkwalde',
			'Böhmenkirch',
			'Bischofsheim in der Rhön',
			'Binsfeld',
			'Biendorf',
			'Biberach',
			'Bernsdorf',
			'Bernburg',
			'Berkenbrück',
			'Beratzhausen',
			'Bellheim',
			'Bayreuth',
			'Bayenthal',
			'Baunach',
			'Baudenbach',
			'Bark',
			'Bärenstein',
			'Balve',
			'Atting',
			'Alzey',
			'Altensteig',
			'Ammerthal',
			'Allensbach',
			'Albbruck',
			'Aiterhofen',
			'Weiler-Simmerberg',
			'Aspach',
			'Gutenzell-Hürbel',
			'Möhnesee',
			'Gossersweiler-Stein',
			'Zeltingen-Rachtig',
			'Hiddensee',
			'Feldstadt',
			'Spandau',
			'Äußere Neustadt',
			'Gartenstadt',
			'Luisenthal',
			'Heidberg',
			'Dulsberg',
			'Nordbahnhof',
			'Riehl',
			'Riedering',
			'Riede',
			'Remscheid',
			'Remlingen',
			'Reinbek',
			'Reilingen',
			'Reichartshausen',
			'Raunheim',
			'Rahden',
			'Queidersbach',
			'Puchheim',
			'Priestewitz',
			'Presseck',
			'Preetz',
			'Pobershau',
			'Pfronstetten',
			'Pfaffenhofen',
			'Parsberg',
			'Otterfing',
			'Oberwolfach',
			'Oberschneiding',
			'Ober-Saulheim',
			'Oberottmarshausen',
			'Langfurth',
			'Ober-Flörsheim',
			'Oberelsbach',
			'Nünchritz',
			'Nottensdorf',
			'Northeim',
			'Nörten-Hardenberg',
			'Nordwalde',
			'Norderstedt',
			'Nikolassee',
			'Niederzimmern',
			'Neustrelitz',
			'Neupotz',
			'Neukirchen',
			'Neukirchen',
			'Neu Kaliß',
			'Neuenrade',
			'Neckarzimmern',
			'Neckarwestheim',
			'Narsdorf',
			'Nachrodt-Wiblingwerde',
			'Mutlangen',
			'Murr',
			'Münster-Sarmsheim',
			'Munster',
			'Mulfingen',
			'Molfsee',
			'Mödingen',
			'Mockrehna',
			'Michendorf',
			'Metzingen',
			'Mettmann',
			'Metelen',
			'Mengerskirchen',
			'Melsbach',
			'Markt Erlbach',
			'Mantel',
			'Mähring',
			'Lutter am Barenberge',
			'Luckenwalde',
			'Lubnjow',
			'Lindwedel',
			'Linden',
			'Lindau',
			'Leun',
			'Lemwerder',
			'Leipzig',
			'Lauter',
			'Langweid',
			'Langenhahn',
			'Seiffen',
			'Krumbach',
			'Kreischa',
			'Krefeld',
			'Kranenburg',
			'Köpenick',
			'Kohlberg',
			'Kochel',
			'Kleinaitingen',
			'Kirchroth',
			'Kaulsdorf',
			'Kastellaun',
			'Kallstadt',
			'Jüchen',
			'Johannisthal',
			'Jessen',
			'Jersbek',
			'Jandelsbrunn',
			'Jagstzell',
			'Itzstedt',
			'Irsee',
			'Irschenberg',
			'Ipsheim',
			'Illerrieden',
			'Ihlienworth',
			'Hüttisheim',
			'Holtland',
			'Hollenbach',
			'Hohenwarth',
			'Hohentengen',
			'Hohenbocka',
			'Hohburg',
			'Hof',
			'Hockenheim',
			'Höckendorf',
			'Hirzenhain',
			'Hiltpoltstein',
			'Herzberg',
			'Heringsdorf',
			'Heppenheim an der Bergstrasse',
			'Helsa',
			'Helmstedt',
			'Hellerau',
			'Haunsheim',
			'Harsleben',
			'Hannover',
			'Hammoor',
			'Hambrücken',
			'Haar',
			'Gütersloh',
			'Gülzow',
			'Grube',
			'Großmaischeid',
			'Greppin',
			'Gremersdorf',
			'Gräfenhain',
			'Gotha',
			'Gosheim',
			'Göllheim',
			'Gnoien',
			'Gittelde',
			'Gilten',
			'Gielow',
			'Gernrode',
			'Germering',
			'Geiselbach',
			'Gebhardshain',
			'Gau-Bickelheim',
			'Fulda',
			'Friemar',
			'Friedrichstadt',
			'Friedenweiler',
			'Fahrenzhausen',
			'Essenheim',
			'Eschwege',
			'Eschlkam',
			'Erlenbach',
			'Erkelenz',
			'Elmstein',
			'Elmenhorst',
			'Elfershausen',
			'Eisenberg',
			'Einfeld',
			'Eilenburg',
			'Eickendorf',
			'Eichigt',
			'Egenhausen',
			'Echzell',
			'Düsseldorf',
			'Dürrhennersdorf',
			'Dorum',
			'Dornum',
			'Dobbertin',
			'Dinslaken',
			'Dettighofen',
		],
	},
	Ghana: {
		country: 'Ghana',
		cities: [
			'Kete Krachi',
			'Duayaw-Nkwanta',
			'Bekwai',
			'Anloga',
			'Agogo',
			'Aflao',
			'Odumase Krobo',
			'Wa',
			'Kintampo',
			'Foso',
			'Winneba',
			'Suhum',
			'Bolgatanga',
			'Bechem',
			'Nalerigu',
			'Elmina',
			'Dambai',
			'Cape Coast',
			'Sefwi Wiawso',
			'Sunyani',
			'Akim Oda',
			'Navrongo',
			'Kpandu',
			'Asamankese',
			'Saltpond',
			'Keta',
			'Japekrom',
			'Apam',
			'Konongo',
			'Yendi',
			'Tamale',
			'Atsiaman',
			'Mumford',
			'Axim',
			'Techiman',
			'Tarkwa',
			'Tafo',
			'Gbawe',
			'Wankyi',
			'Teshi Old Town',
			'Nungua',
			'Akropong',
			'Akim Swedru',
			'Takoradi',
			'Ho',
			'Aboso',
			'Zonno',
			'Ejura',
			'Begoro',
			'Sekondi-Takoradi',
			'Damongo',
			'Obuase',
			'Nsawam',
			'Koforidua',
			'Tema',
			'Prestea',
			'Wassa-Akropong',
			'Shama Junction',
			'Savelugu',
			'Dome',
			'Accra',
			'Aburi',
			'Kibi',
			'Hohoe',
			'Mamponteng',
			'Salaga',
			'Kumasi',
			'Kasoa',
			'Mampong',
			'Akwatia',
			'Swedru',
			'Mpraeso',
			'Goaso',
			'Dunkwa',
			'Berekum',
			'Kpandae',
			'Bawku',
			'Medina Estates',
			'Bibiani',
		],
	},
	Gibraltar: { country: 'Gibraltar', cities: ['Gibraltar'] },
	Greece: {
		country: 'Greece',
		cities: [
			'Zárkos',
			'Giánnouli',
			'Xylókastro',
			'Skalánion',
			'Psychikó',
			'Pýlos',
			'Periyiáli',
			'Perivóli',
			'Néa Stíra',
			'Néa Erythraía',
			'Náxos',
			'Náfplio',
			'Megálo Chorió',
			'Lefkáda',
			'Kokkíni Cháni',
			'Kýthnos',
			'Kyriáki',
			'Cholargós',
			'Chálki',
			'Khalándrion',
			'Keratsíni',
			'Karyés',
			'Kalývia Thorikoú',
			'Kálymnos',
			'Ierápetra',
			'Arkalochóri',
			'Anógeia',
			'Áno Liósia',
			'Aíyira',
			'Afrátion',
			'Ródos',
			'Perivolákion',
			'Perístasi',
			'Néoi Epivátes',
			'Mándalo',
			'Díon',
			'Kozáni',
			'Kleidí',
			'Chrysochórafa',
			'Loutráki',
			'Fíliro',
			'Elefthério - Kordelió',
			'Ágios Loukás',
			'Megálo Chorió',
			'Anthoúsa',
			'Geráki',
			'Stýpsi',
			'Stános',
			'Rodotópi',
			'Prokópi',
			'Portariá',
			'Panaitólion',
			'Néa Alikarnassós',
			'Mándra',
			'Makrísia',
			'Chalkída',
			'Chaïdári',
			'Filiatrá',
			'Ermoúpolis',
			'Dístomo',
			'Agía Triáda',
			'Ayía Triás',
			'Antikyra',
			'Agrínio',
			'Archángelos',
			'Souflí',
			'Néa Málgara',
			'Mavrothálassa',
			'Kilkís',
			'Chrysochóri',
			'Gázoros',
			'Évlalo',
			'Didymóteicho',
			'Agía Paraskeví',
			'Ássiros',
			'Aigínio',
			'Melíssi',
			'Faliraki',
			'Néa Pentéli',
			'Krókos',
			'Episkopí',
			'Dráma',
			'Ágios Athanásios',
			'Arnaía',
			'Néa Vrasná',
			'Kardamítsia',
			'Anakasiá',
			'Mosynopolis, Maximianopolis, Μαξιμιανούπολις',
			'Vareiá',
			'Tríkeri',
			'Ialysós',
			'Steíri',
			'Sikyón',
			'Prámanta',
			'Pigí',
			'Peristéri',
			'Oinófyta',
			'Marmárion',
			'Livanátes',
			'Ligourión',
			'Kentrí',
			'Kaména Voúrla',
			'Folégandros',
			'Magoúla',
			'Dhafní',
			'Agiá',
			'Ástros',
			'Asprángeloi',
			'Plagiári',
			'Chalástra',
			'Patrída',
			'Néa Sánta',
			'Megáli Panagía',
			'Keramotí',
			'Kallithéa',
			'Efkarpía',
			'Elaiochóri',
			'Echínos',
			'Askós',
			'Agía Foteiní',
			'Tílisos',
			'Thívai',
			'Stavrós',
			'Rízoma',
			'Río',
			'Pithári',
			'Piraeus',
			'Pappadátes',
			'Néa Péramos',
			'Néa Ionía',
			'Mólos',
			'Mégara',
			'Magoúla',
			'Lepenoú',
			'Krousón',
			'Kostakioí',
			'Chaniá',
			'Khalkoútsion',
			'Katochí',
			'Itháki',
			'Filótion',
			'Eloúnda',
			'Eláteia',
			'Dhrosiá',
			'Ágios Stéfanos',
			'Argyropoúli',
			'Arkhaía Kórinthos',
			'Andros',
			'Giannitsá',
			'Sfendámi',
			'Rizári',
			'Próchoma',
			'Zagorá',
			'Vounoplagiá',
			'Verdikoússa',
			'Vasilikón',
			'Thérmo',
			'Sofikón',
			'Skála',
			'Rethymno',
			'Polydéndri',
			'Pyrgetós',
			'Perivólia',
			'Párga',
			'Neochorópoulo',
			'Messíni',
			'Livadeiá',
			'Metamórfosi',
			'Kímolos',
			'Káto Asítai',
			'Kamárai',
			'Kainoúryion',
			'Grammatikó',
			'Erythrés',
			'Epitálio',
			'Emporeío',
			'Ambelókipoi',
			'Acharnés',
			'Adámas',
			'Vergína',
			'Thessaloníki',
			'Smínthi',
			'Potamiá',
			'Ouranoupolis',
			'Nigríta',
			'Neochoroúda',
			'Lagkadás',
			'Ágios Pétros',
			'Áthyra',
			'Asvestochóri',
			'Alepoú',
			'Vrilissia',
			'Saronída',
			'Vlycháda',
			'Geráni',
			'Vassilies',
			'Sofádes',
			'Skópelos',
			'Oreoí',
			'Néa Seléfkeia',
			'Néa Palátia',
			'Néa Artáki',
			'Míthymna',
			'Agía Triáda',
			'Megalópoli',
			'Lékhaio',
			'Kouvarás',
			'Koutsopódi',
			'Kéfalos',
			'Kavásila',
			'Katsikás',
			'Kardámaina',
			'Kalamiá',
			'Kalamata',
			'Glyfáda',
			'Galatás',
			'Galatás',
			'Gaïtánion',
			'Fry',
			'Archaía Olympía',
			'Áno Lekhónia',
			'Rodolívos',
			'Néa Magnisía',
			'Néa Karváli',
			'Néa Kallikráteia',
			'Krýa Vrýsi',
			'Korinós',
			'Kavallári',
			'Karyes',
			'Kalá Déndra',
			'Ierissós',
			'Galatiní',
			'Arsénio',
			'Argos Orestiko',
			'Áratos',
			'Alexándreia',
			'Pánormos',
			'Iliokentima',
			'Kallikomo',
			'Vágia',
			'Tsikalariá',
			'Pórto Chéli',
			'Plomári',
			'Pýrgos',
			'Petroúpolis',
			'Pérama',
			'Pámfila',
			'Néon Monastírion',
			'Markópoulo',
			'Manoláda',
			'Mandráki',
			'Kríkellos',
			'Kyrás Vrýsi',
			'Kiáto',
			'Kardamás',
			'Elassóna',
			'Ágios Nikólaos',
			'Maroúsi',
			'Akraifnía',
			'Néos Oropós',
			'Zagkliveri',
			'Tríkala',
			'Sochós',
			'Polykárpi',
			'Neochóri',
			'Néa Péramos',
			'Mavrovoúni',
			'Karyótissa',
			'Kardía',
			'Erátyra',
			'Agía Triáda',
			'Alistráti',
			'Georgioupolis',
			'Kítsi',
			'Dióni',
			'Eksochí',
			'Tsarítsani',
			'Thesprotikó',
			'Stylída',
			'Skópelos',
			'Préveza',
			'Platariá',
			'Néa Mákri',
			'Marathónas',
			'Loúros',
			'Irákleion',
			'Galátsi',
			'Fylí',
			'Rodópoli',
			'Ágios Efstrátios',
			'Áyios Adhrianós',
			'Agía Paraskeví',
			'Agía Marína',
			'Athíkia',
			'Árgos',
			'Áno Merá',
			'Anávra',
			'Sosándra',
			'Ptolemaḯda',
			'Panórama',
			'Náousa',
			'Melíti',
			'Kavála',
			'Kampánis',
			'Kalós Agrós',
			'Léchovo',
			'Galatádes',
			'Fotolívos',
			'Angelochóri',
			'Akriní',
			'Corfu',
			'Violí Charáki',
			'Makrochóri',
			'Lianovérgi',
			'Chortiátis',
			'Karítsa',
			'Ágios Spyrídon',
			'Drymós',
			'Axioúpoli',
			'Agía Kyriakí',
			'Néa Ionía',
			'Vamvakoú',
			'Témeni',
			'Sparta',
			'Pyrgetós',
			'Pediní',
			'Paralía',
			'Palaiópyrgos',
			'Palaiomonástiro',
			'Palaiókipos',
			'Néa Anchiálos',
			'Mouzáki',
			'Moíres',
			'Mílos',
			'Mantoúdi',
			'Makrychóri',
			'Levídion',
			'Kifisiá',
			'Chairóneia',
			'Káto Achaḯa',
			'Goúrnes',
			'Goúmero',
			'Áyios Konstandínos',
			'Atsipópoulo',
			'Atalánti',
			'Argalastí',
			'Anoixi',
			'Amfilochía',
			'Petrochóri',
			'Pentaplátano',
			'Nikísiani',
			'Vrontádos',
			'Vrakháti',
			'Vathý',
			'Skála',
			'Neápolis',
			'Pythagóreio',
			'Psará',
			'Mesolóngi',
			'Makrakómi',
			'Limín Khersonísou',
			'Lárisa',
			'Kinéta',
			'Kastráki',
			'Pefkochóri',
			'Kamaterón',
			'Kalpáki',
			'Kalloní',
			'Álimos',
			'Voulgaréli',
			'Dídyma',
			'Agía Varvára',
			'Megalochóri',
			'Andravída',
			'Tycheró',
			'Leptokaryá',
			'Néos Mylótopos',
			'Káto Nevrokópi',
			'Féres',
			'Epanomí',
			'Ávato',
			'Kanáli',
			'Ampelókipoi',
			'Tsiflikópoulo',
			'Zacháro',
			'Tínos',
			'Sérifos',
			'Plátanos',
			'Pérdika',
			'Pérama',
			'Parapótamos',
			'Pappádos',
			'Paianía',
			'Papágou',
			'Néa Kíos',
			'Moskháton',
			'Markópoulo Oropoú',
			'Lidoríki',
			'Lianokládhion',
			'Lávrio',
			'Lápas',
			'Kranídi',
			'Kalýves',
			'Isthmía',
			'Irákleio',
			'Igoumenítsa',
			'Gázi',
			'Ano Arhanes',
			'Drosiá',
			'Áyios Thomás',
			'Áris',
			'Aegina',
			'Kremastí',
			'Afántou',
			'Xylaganí',
			'Vasiliká',
			'Sérvia',
			'Néos Skopós',
			'Néa Iraklítsa',
			'Marína',
			'Litóchoro',
			'Kítros',
			'Kalochóri',
			'Évosmos',
			'Ágios Geórgios',
			'Amýntaio',
			'Ilioúpoli',
			'Néa Anatolí',
			'Argithéa',
			'Paralía Vérgas',
			'Neapoli',
			'Nea Lava',
			'Skiáthos',
			'Sámi',
			'Neochórion',
			'Malakónta',
			'Loutráki',
			'Krokeés',
			'Kýthira',
			'Chóra',
			'Kastélla',
			'Kaparéllion',
			'Kandíla',
			'Kallifóni',
			'Fársala',
			'Dhokímion',
			'Deskáti',
			'Agía Varvára',
			'Archontochóri',
			'Kopanáki',
			'Alivéri',
			'Aitolikó',
			'Vamvakófyto',
			'Trílofos',
			'Tagarádes',
			'Stavroúpoli',
			'Sourotí',
			'Rízia',
			'Néo Petrítsi',
			'Néa Michanióna',
			'Néa Mesimvría',
			'Mikrópolis',
			'Mesiméri',
			'Laimós',
			'Krinídes',
			'Kallithéa',
			'Grevená',
			'Virós',
			'Agía Marína',
			'Stathmós Mourión',
			'Lagós',
			'Kamariótissa',
			'Agkathiá',
			'Ágios Pávlos',
			'Kynopiástes',
			'Korydallós',
			'Neos Voutzás',
			'Pláka Dílesi',
			'Vraná',
			'Vartholomió',
			'Stamáta',
			'Sperchógeia',
			'Skarmagkás',
			'Néa Manoláda',
			'Mitrópoli',
			'Kíssamos',
			'Kyparissía',
			'Kálamos',
			'Akráta',
			'Aígio',
			'Akrolímni',
			'Síndos',
			'Sélero',
			'Rizómata',
			'Fteliá',
			'Páchni',
			'Néa Apollonía',
			'Mýki',
			'Limenária',
			'Kýria',
			'Néa Flogitá',
			'Diavatós',
			'Agios Georgis',
			'Gérakas',
			'Drapetsóna',
			'Néo Psychikó',
			'Aktaío',
			'Vári',
			'Valsamáta',
			'Traganón',
			'Terpsithéa',
			'Spercheiáda',
			'Spáta',
			'Roviés',
			'Pátmos',
			'Paralía Avlídhos',
			'Néa Tírins',
			'Morfovoúni',
			'Mataránga',
			'Marathókampos',
			'Límni',
			'Lakkí',
			'Kolympári',
			'Kerasochóri',
			'Káto Lekhónia',
			'Katastárion',
			'Karpochóri',
			'Filothéi',
			'Áyios Nikólaos',
			'Anéza',
			'Anávyssos',
			'Aigáleo',
			'Xinó Neró',
			'Xánthi',
			'Sérres',
			'Paralía',
			'Várda',
			'Tolón',
			'Stalís',
			'Schimatári',
			'Salamína',
			'Paravóla',
			'Moláoi',
			'Melíssia',
			'Meligalás',
			'Megalochóri',
			'Lithakiá',
			'Lechainá',
			'Koilás',
			'Chalkiádes',
			'Kalampáka',
			'Istiaía',
			'Dílesi',
			'Astakós',
			'Árma',
			'Ámfissa',
			'Ampelákia',
			'Almyrós',
			'Afidnés',
			'Vrontoú',
			'Strymonikó',
			'Próti',
			'Pontisméno',
			'Palaió Tsiflíki',
			'Palaiochóri',
			'Ólynthos',
			'Néa Moudhaniá',
			'Meneméni',
			'Kónitsa',
			'Kýmina',
			'Diavatá',
			'Aridaía',
			'Zográfos',
			'Ágioi Anárgyroi',
			'Néo Rýsi',
			'Mesopotamía',
			'Koufália',
			'Empório',
			'Ápsalos',
			'Ammochóri',
			'Áfytos',
			'Agios Dimítrios Kropiás',
			'Vrýses',
			'Vónitsa',
			'Rodhítsa',
			'Pentéli',
			'Omvriakí',
			'Neméa',
			'Mytikas',
			'Artémida',
			'Lykóvrysi',
			'Kritsá',
			'Kréstena',
			'Kyllíni',
			'Katoúna',
			'Grizáno',
			'Fíki',
			'Gerakaroú',
			'Xiropótamos',
			'Svorónos',
			'Skoútari',
			'Políchni',
			'Paranésti',
			'Zakynthos',
			'Géfyra',
			'Spétses',
			'Skýros',
			'Synoikismós Chavaríou',
			'Savália',
			'Politiká',
			'Pappadhátai',
			'Neápoli',
			'Náfpaktos',
			'Mytilene',
			'Menídi',
			'Mália',
			'Koutselió',
			'Kírra',
			'Chalandrítsa',
			'Káto Mazaráki',
			'Kanália',
			'Kainoúryion',
			'Itéa',
			'Gónnoi',
			'Filippiáda',
			'Arkoúdi',
			'Agía Galíni',
			'Argostólion',
			'Áno Kalentíni',
			'Antíparos',
			'Genisséa',
			'Vólakas',
			'Thérmi',
			'Sápes',
			'Petroússa',
			'Palaiochóri',
			'Oraiókastro',
			'Neochóri',
			'Néa Plágia',
			'Néa Éfesos',
			'Chrysavgí',
			'Káto Scholári',
			'Káto Miliá',
			'Eleftheroúpolis',
			'Ágios Vasíleios',
			'Arísvi',
			'Káto Goúves',
			'Ymittos',
			'Néa Pélla',
			'Lití',
			'Kolindrós',
			'Kateríni',
			'Kassándreia',
			'Exaplátanos',
			'Evropós',
			'Exochi',
			'Foúrnoi',
			'Loutrá Oraías Elénis',
			'Zevgolateió',
			'Gýtheio',
			'Vlachiótis',
			'Vélo',
			'Vathí',
			'Týrnavos',
			'Thouría',
			'Távros',
			'Skála Oropoú',
			'Sými',
			'Sardínia',
			'Palaió Fáliro',
			'Palaiá Epídavros',
			'Metsovo',
			'Mantamádos',
			'Kraniá Elassónas',
			'Khiliomódhi',
			'Kardítsa',
			'Kallíthiro',
			'Grammenítsa',
			'Fyteíes',
			'Evxinoúpolis',
			'Ellinikó',
			'Elefsína',
			'Drépanon',
			'Diónysos',
			'Dimitsána',
			'Astypálaia',
			'Angelókastro',
			'Ampelóna',
			'Polýgyros',
			'Pélla',
			'Ormýlia',
			'Vouliagméni',
			'Volos',
			'Varnávas',
			'Thymianá',
			'Selínia',
			'Póros',
			'Pallíni',
			'Pálairos',
			'Megála Kalývia',
			'Magoúla',
			'Lefkímmi',
			'Kryonéri',
			'Kompóti',
			'Kypséli',
			'Chios',
			'Chávari',
			'Chálki',
			'Kastrí',
			'Karpenísi',
			'Ioánnina',
			'Gómfoi',
			'Filiátes',
			'Darátsos',
			'Ágios Geórgios',
			'Áyios Vasílios',
			'Asopía',
			'Asíni',
			'Anthíli',
			'Amfíkleia',
			'Agnanteró',
			'Valteró',
			'Tsotíli',
			'Stavrós',
			'Platý',
			'Pylaía',
			'Néa Zíchni',
			'Néa Poteídaia',
			'Mikró Monastíri',
			'Lófos',
			'Lagyná',
			'Kolchikón',
			'Chrysó',
			'Káto Kamíla',
			'Irákleia',
			'Édessa',
			'Áno Kómi',
			'Angelochóri',
			'Yimnón',
			'Sykiá',
			'Royítika',
			'Palekastro',
			'Oinoússes',
			'Níkaia',
			'Nerokoúros',
			'Oichalía',
			'Neochóri',
			'Néa Filadélfeia',
			'Mouzourás',
			'Mesariá',
			'Megáli Khóra',
			'Lálas',
			'Kokkónion',
			'Káto Tithoréa',
			'Íos',
			'Fálanna',
			'Eresós',
			'Árta',
			'Terpní',
			'Sykiá',
			'Sevastianá',
			'Prosotsáni',
			'Orestiáda',
			'Néo Soúli',
			'Néa Raidestós',
			'Koryfí',
			'Kalí',
			'Iráklion',
			'Galátista',
			'Flórina',
			'Néa Kerasiá',
			'Dravískos',
			'Ágios Athanásios',
			'Anaráchi',
			'Ádendro',
			'Paralía Ofryníou',
			'Agios Ioannis Rentis',
			'Karellás',
			'Lýkeio',
			'Lákkoma',
			'Krithiá',
			'Chrysoúpolis',
			'Choristí',
			'Kallífytos',
			'Varybóbi',
			'Xinos',
			'Vlachópoulo',
			'Rododáfni',
			'Profítis Ilías',
			'Perachóra',
			'Myrsíni',
			'Mindilóglion',
			'Malesína',
			'Magoúla',
			'Leipsoí',
			'Taxiárches',
			'Kos',
			'Kastélli',
			'Kárystos',
			'Itéa',
			'Ágioi Theódoroi',
			'Asopós',
			'Argyroúpoli',
			'Arfará',
			'Áno Sýros',
			'Alíartos',
			'Thásos',
			'Siátista',
			'Palaiokómi',
			'Vokhaïkó',
			'Výronas',
			'Vília',
			'Schísma Eloúndas',
			'Sylivainiótika',
			'Sykoúrio',
			'Síkinos',
			'Rafína',
			'Pteleós',
			'Ílion',
			'Mokhós',
			'Mytilinioí',
			'Methóni',
			'Martínon',
			'Limín Mesoyaías',
			'Leonídio',
			'Koropí',
			'Kórinthos',
			'Kalávryta',
			'Galatás',
			'Fáros',
			'Farkadóna',
			'Émponas',
			'Ássos',
			'Amorgós',
			'Alfeioúsa',
			'Aiánteio',
			'Vathýlakkos',
			'Stratónion',
			'Sykiés',
			'Péfka',
			'Peteinós',
			'Péplos',
			'Kouloúra',
			'Komniná',
			'Eleftherés',
			'Ágios Nikólaos',
			'Ágio Pnévma',
			'Mitroúsi',
			'Anatolikó',
			'Néo Agionéri',
			'Néa Karyá',
			'Melíki',
			'Kastaniés',
			'Kalampáki',
			'Fillýra',
			'Aianí',
			'Triandría',
			'Néa Chalkidóna',
			'Perama',
			'Velestíno',
			'Tympáki',
			'Pýli',
			'Pétra',
			'Pérama',
			'Pátra',
			'Patitírion',
			'Ovriá',
			'Oía',
			'Mouzaki',
			'Mourniés',
			'Moúlki',
			'Megísti',
			'Lixoúri',
			'Kokkári',
			'Káto Soúlion',
			'Káto Glykóvrysi',
			'Galaxídhion',
			'Eleoúsa',
			'Delphi',
			'Ágioi Déka',
			'Áyioi Apóstoloi',
			'Agiásos',
			'Agía Marína',
			'Aráchova',
			'Amárynthos',
			'Skotoússa',
			'Sitagroí',
			'Prómachoi',
			'Polýkastro',
			'Platanórevma',
			'Pentálofos',
			'Néa Mádytos',
			'Melissochóri',
			'Maniákoi',
			'Loutrós',
			'Livaderó',
			'Lefkónas',
			'Komotiní',
			'Prínos',
			'Kalamariá',
			'Íasmos',
			'Gouménissa',
			'Ágios Matthaíos',
			'Acharávi',
			'Zarós',
			'Trípoli',
			'Soúrpi',
			'Pýrgos',
			'Pylí',
			'Pelasgía',
			'Proástio',
			'Paramythiá',
			'Pánormos',
			'Palamás',
			'Palaióchora',
			'Níkaia',
			'Néa Lámpsakos',
			'Mória',
			'Monastiráki',
			'Mykonos',
			'Kipséli',
			'Kými',
			'Chóra Sfakíon',
			'Gastoúni',
			'Fílla',
			'Ermióni',
			'Áyios Konstandínos',
			'Avlónas',
			'Athens',
			'Artesianó',
			'Anatolí',
			'Aidipsós',
			'Portariá',
			'Peraía',
			'Néa Výssa',
			'Néa Tríglia',
			'Kyprínos',
			'Kastoria',
			'Kalývia',
			'Ornós',
			'Péfki',
			'Gérgeri',
			'Triandaíika',
			'Thespiés',
			'Stefanovíkeio',
			'Póros',
			'Pikérmi',
			'Néon Karlovásion',
			'Loukísia',
			'Langádhia',
			'Koróni',
			'Chóra',
			'Kéa',
			'Karpathos',
			'Karditsomagoúla',
			'Kapandríti',
			'Kallithéa',
			'Examília',
			'Eleoúsa',
			'Agios Kirykos',
			'Véroia',
			'Velventós',
			'Sárti',
			'Provatás',
			'Profítis Ilías',
			'Néos Marmarás',
			'Mavrochóri',
			'Livádi',
			'Lávara',
			'Kontariótissa',
			'Kokkinóchoma',
			'Koímisi',
			'Chalkidóna',
			'Kavýli',
			'Símantra',
			'Kalýves Polygýrou',
			'Asproválta',
			'Aravissós',
			'Alexandroupoli',
			'Kontokáli',
			'Néa Potídhaia',
			'Chlói',
			'Aghios Panteleímon',
			'Thrakomakedónes',
			'Vrachnaíika',
			'Vathý',
			'Tríkala',
			'Psachná',
			'Pásion',
			'Oxílithos',
			'Orchomenós',
			'Lamía',
			'Domvraína',
			'Kanaláki',
			'Gavaloú',
			'Erétria',
			'Domokós',
			'Ágios Andréas',
			'Álli Meriá',
			'Agriá',
			'Zipári',
			'Sidirókastro',
			'Palaífyto',
			'Nisí',
			'Nestório',
			'Néa Róda',
			'Kopanós',
			'Kimméria',
			'Arrianá',
			'Eirinoúpoli',
			'Stavráki',
			'Pelópi',
			'Gáïos',
			'Páros',
			'Oropós',
			'Neochóri',
			'Néa Smýrni',
			'Náousa',
			'Mýrina',
			'Makrychóri',
			'Keratéa',
			'Káto Dhiminió',
			'Karátoula',
			'Kaisarianí',
			'Ýdra',
			'Gargaliánoi',
			'Dhráfi',
			'Agios Dimitrios',
			'Asprópyrgos',
			'Asímion',
			'Áno Kastrítsi',
			'Anáfi',
			'Zygós',
			'Géfyra',
			'Xilópolis',
			'Rizó',
			'Ritíni',
			'Zonianá',
			'Voúla',
			'Vasilikí',
			'Varvásaina',
			'Vári',
			'Vanáton',
			'Thrapsanón',
			'Firá',
			'Soúda',
			'Sitia',
			'Sísion',
			'Samos',
			'Rizómylos',
			'Polichnítos',
			'Platýkampos',
			'Péta',
			'Palaiá Fókaia',
			'Mavrommáti',
			'Loutrá Aidhipsoú',
			'Loutrá',
			'Limnokhórion',
			'Leondárion',
			'Kardamýli',
			'Gra Liyiá',
			'Graikochóri',
			'Ekáli',
			'Agía Paraskeví',
			'Aria',
			'Anthiró',
			'Antirrio',
			'Antimácheia',
			'Amaliáda',
			'Lárdos',
			'Stavrós',
			'Skýdra',
			'Samothráki',
			'Axós',
			'Níkiti',
			'Néa Fókaia',
			'Makrýgialos',
			'Koíla',
			'Kleítos',
			'Charopó',
			'Káto Lipochóri',
			'Kalí Vrýsi',
			'Filótas',
			'Árnissa',
			'Amygdaleónas',
			'Ampeleíes',
			'Potamós',
			'Zefyri',
		],
	},
	Greenland: {
		country: 'Greenland',
		cities: [
			'Aasiaat',
			'Upernavik',
			'Sisimiut',
			'Qaqortoq',
			'Nuuk',
			'Maniitsoq',
			'Nanortalik',
			'Tasiilaq',
			'Qasigiannguit',
			'Uummannaq',
			'Ilulissat',
			'Paamiut',
			'Narsaq',
		],
	},
	Grenada: { country: 'Grenada', cities: ['Gouyave', 'Victoria', 'Sauteurs', 'Hillsborough', "Saint George's", 'Grenville', 'Saint David’s'] },
	Guadeloupe: {
		country: 'Guadeloupe',
		cities: [
			'Basse-Terre',
			'Le Gosier',
			'Grand-Bourg',
			'Lamentin',
			'Sainte-Anne',
			'Capesterre-Belle-Eau',
			'Saint-Claude',
			'Vieux-Habitants',
			'Port-Louis',
			'Anse-Bertrand',
			'Petites Anses',
			'Bouillante',
			'Pointe-Noire',
			'Les Abymes',
			'Beauséjour',
			'Saint-François',
			'Pointe-à-Pitre',
			'Baillif',
			'Sainte-Rose',
			'Gourbeyre',
			'Petit-Bourg',
			'Baie-Mahault',
			'Petit-Canal',
			'Trois-Rivières',
			'Le Moule',
		],
	},
	Guam: {
		country: 'Guam',
		cities: [
			'Chalan Pago-Ordot Village',
			'Yona Village',
			'Guam Government House',
			'Dededo Village',
			'Mongmong-Toto-Maite Village',
			'Tamuning',
			'Hagåtña Village',
			'Mangilao Village',
			'Inarajan Village',
			'Tamuning-Tumon-Harmon Village',
			'Piti Village',
			'Merizo Village',
			'Yigo Village',
			'Santa Rita Village',
			'Sinajana Village',
			'Agana Heights Village',
			'Agat Village',
			'Umatac Village',
			'Asan-Maina Village',
			'Talofofo Village',
			'Barrigada Village',
			'Hagåtña',
		],
	},
	Guatemala: {
		country: 'Guatemala',
		cities: [
			'Zacapa',
			'Santo Domingo Xenacoj',
			'Santa Lucía Cotzumalguapa',
			'Santa Cruz Muluá',
			'San Rafael Las Flores',
			'San Juan Ixcoy',
			'San Francisco El Alto',
			'San Cristóbal Acasaguastlán',
			'Sacapulas',
			'Río Blanco',
			'Nuevo San Carlos',
			'El Quetzal',
			'Concepción',
			'Chahal',
			'Almolonga',
			'Aguacatán',
			'Santiago Atitlán',
			'Santa María de Jesús',
			'San Pedro Sacatepéquez',
			'San Luis Jilotepeque',
			'San Lucas Sacatepéquez',
			'San Gaspar Ixchil',
			'San Carlos Sija',
			'Retalhuleu',
			'Pasaco',
			'Panajachel',
			'Ostuncalco',
			'Ocós',
			'Guazacapán',
			'Zacualpa',
			'Siquinalá',
			'Santiago Sacatepéquez',
			'Santa Cruz Naranjo',
			'San Rafael Pie de la Cuesta',
			'San Pablo',
			'San Juan Chamelco',
			'San Juan Atitán',
			'San Gabriel',
			'Masagua',
			'La Unión',
			'La Libertad',
			'Jacaltenango',
			'El Estor',
			'Concepción Chiquirichapa',
			'Casillas',
			'Cantel',
			'Camotán',
			'Cabañas',
			'Zunilito',
			'Yepocapa',
			'Santa Bárbara',
			'San Miguel Sigüilá',
			'San Marcos La Laguna',
			'Cuilapa',
			'Chuarrancho',
			'Cabricán',
			'Unión',
			'Totonicapán',
			'San Diego',
			'San Andrés',
			'Quesada',
			'La Libertad',
			'Chajul',
			'Villa Canales',
			'Tejutla',
			'Tajumulco',
			'Sansare',
			'San José',
			'San Andrés Itzapa',
			'Quezaltepeque',
			'Palestina de los Altos',
			'La Tinta',
			'Huehuetenango',
			'Comalapa',
			'Champerico',
			'Taxisco',
			'Tamahú',
			'Tactic',
			'Santa Cruz del Quiché',
			'San Andrés Villa Seca',
			'Nueva Concepción',
			'Malacatancito',
			'Jutiapa',
			'Jocotán',
			'Chiquimulilla',
			'La Reforma',
			'El Tejar',
			'Concepción Huista',
			'Melchor de Mencos',
			'Chiantla',
			'Agua Blanca',
			'Santa Cruz La Laguna',
			'Quetzaltenango',
			'Villa Nueva',
			'Santa María Visitación',
			'Santa Catarina Barahona',
			'Santa Bárbara',
			'San Luis',
			'San Juan Tecuaco',
			'San Juan Bautista',
			'San Francisco la Unión',
			'San Cristóbal Verapaz',
			'San Carlos Alzatate',
			'Samayac',
			'Purulhá',
			'Playa Grande',
			'Patzité',
			'Malacatán',
			'El Chal',
			'Canillá',
			'Tecpán Guatemala',
			'Santo Domingo Suchitepéquez',
			'Santa María Chiquimula',
			'Santa Cruz Balanyá',
			'Santa Catarina Mita',
			'San Miguel Panán',
			'San Mateo Ixtatán',
			'San Juan Ermita',
			'San Antonio Suchitepéquez',
			'Olintepeque',
			'Flores Costa Cuca',
			'Santa Eulalia',
			'San Pedro Ayampuc',
			'San Pablo Jocopilas',
			'Patzún',
			'Monjas',
			'La Esperanza',
			'Cubulco',
			'San Juan Ostuncalco',
			'Zaragoza',
			'Sibinal',
			'Santa Lucía Utatlán',
			'Río Bravo',
			'Pajapita',
			'Jalpatagua',
			'Huitán',
			'Flores',
			'Cobán',
			'Chimaltenango',
			'Cajolá',
			'San Lorenzo',
			'San Antonio Aguas Calientes',
			'Nuevo Progreso',
			'Ciudad Tecún Umán',
			'Chicacao',
			'Barillas',
			'Alotenango',
			'Santiago Chimaltenango',
			'San Raimundo',
			'Morazán',
			'Jocotenango',
			'El Adelanto',
			'Conguaco',
			'Chiquimula',
			'Acatenango',
			'San Pedro Necta',
			'San Jorge',
			'San Antonio La Paz',
			'Salamá',
			'Palencia',
			'La Blanca',
			'Iztapa',
			'Cuyotenango',
			'Cahabón',
			'Sololá',
			'Sayaxché',
			'Santa Lucia La Reforma',
			'Santa Catarina Pinula',
			'Santa Catarina Palopó',
			'Santa Apolonia',
			'San Lorenzo',
			'San José Poaquil',
			'San Cristóbal Totonicapán',
			'San Antonio Ilotenango',
			'Tiquisate',
			'El Rodeo',
			'El Palmar',
			'Todos Santos Cuchumatán',
			'Tacaná',
			'Santa María Ixhuatán',
			'San Martín Zapotitlán',
			'Panzos',
			'Moyuta',
			'Mataquescuintla',
			'Los Amates',
			'Lívingston',
			'Lanquín',
			'El Progreso',
			'Barberena',
			'Usumatlán',
			'Tucurú',
			'Soloma',
			'San Miguel Chicaj',
			'San Miguel Acatán',
			'San José Ojetenán',
			'San Jacinto',
			'San Francisco Zapotitlán',
			'Pueblo Nuevo Viñas',
			'Magdalena Milpas Altas',
			'El Tumbador',
			'Atescatempa',
			'Zapotitlán',
			'Teculután',
			'San Pédro Jocopilas',
			'San Bartolomé Milpas Altas',
			'San Antonio Palopó',
			'San Agustín Acasaguastlán',
			'Poptún',
			'Parramos',
			'Nentón',
			'Momostenango',
			'Granados',
			'Concepción Tutuapa',
			'Antigua Guatemala',
			'Zunil',
			'Santo Tomás La Unión',
			'San Pablo La Laguna',
			'San Miguel Dueñas',
			'San Marcos',
			'San Jerónimo',
			'San Bernardino',
			'San Andrés Semetabaj',
			'San Andrés Sajcabajá',
			'Génova',
			'Cuilco',
			'Uspantán',
			'Santa Clara La Laguna',
			'San Sebastián Huehuetenango',
			'San José Ojetenam',
			'San José La Arada',
			'Raxruhá',
			'Rabinal',
			'Nueva Santa Rosa',
			'Ipala',
			'Gualán',
			'Colotenango',
			'Colomba',
			'Coatepeque',
			'Sibilia',
			'San Sebastián Coatán',
			'San Pedro Carchá',
			'San Felipe',
			'San Antonio Sacatepéquez',
			'Patzicía',
			'Mazatenango',
			'La Democracia',
			'La Democracia',
			'Joyabaj',
			'Guastatoya',
			'Comapa',
			'La Máquina',
			'Yupiltepeque',
			'Sumpango',
			//'Santa Ana',
			'San Sebastián',
			'San Mateo',
			'San Manuel Chaparrón',
			'San Juan Cotzal',
			'San José Chacayá',
			'San Francisco',
			'San Benito',
			'Río Hondo',
			'Puerto Barrios',
			'Palín',
			'Pachalum',
			'Nahualá',
			'Mixco',
			'Jerez',
			'El Chol',
			'El Asintal',
			'Dolores',
			'Cunén',
			'Concepción Las Minas',
			'Comitancillo',
			'Ciudad Vieja',
			'Chinautla',
			'Chicamán',
			'Asunción Mita',
			'Amatitlán',
			'Tectitán',
			'Santa Lucía Milpas Altas',
			'Santa Cruz Verapaz',
			'Santa Catarina Ixtahuacán',
			'Santa Ana Huista',
			'San Martín Sacatepéquez',
			'San José El Ídolo',
			'San José del Golfo',
			'San Cristóbal Cucho',
			'Sanarate',
			'Salcajá',
			'Pastores',
			'Morales',
			'Chisec',
			'Chiché',
			'Santa Rosa de Lima',
			'San Pedro Pinula',
			'San Pedro La Laguna',
			'Puerto San José',
			'Pochuta',
			'Patulul',
			'Olopa',
			'La Gomera',
			'Jalapa',
			'Huité',
			'Guanagazapa',
			'Escuintla',
			'Catarina',
			'Sipacapa',
			'San Vicente Pacaya',
			'San Rafael Petzal',
			'San Antonio Huista',
			'Oratorio',
			'Ixtahuacán',
			'Nuevo León',
			'Senahú',
			'San Pedro Sacatepéquez',
			'San Miguel Ixtahuacán',
			'San José Pinula',
			'San Bartolo',
			'San Andrés Xecul',
			'Petapa',
			'Esquipulas Palo Gordo',
			'El Jícaro',
			'Guatemala City',
			'Chinique',
			'Chichicastenango',
			'Fray Bartolomé de Las Casas',
			'San Rafael La Independencia',
			'San Martín Jilotepeque',
			'San Lucas Tolimán',
			'San Juan Sacatepéquez',
			'San Juan La Laguna',
			'San José Acatempa',
			'San Bartolomé Jocotenango',
			'Pueblo Nuevo',
			'Nebaj',
			'Ixchiguán',
			'Fraijanes',
			'Estanzuela',
			'Esquipulas',
		],
	},
	Guernsey: {
		country: 'Guernsey',
		cities: ['Saint Peter Port', 'Saint Sampson', 'St Martin', 'Castel', 'Saint Andrew', 'St Anne', 'Saint Saviour', 'Torteval'],
	},
	Guinea: {
		country: 'Guinea',
		cities: [
			'Samoé',
			'Norassouba',
			'Kouroussa',
			'Beyla',
			'Nyagassola',
			'Siguirini',
			'Coyah',
			'Gaoual',
			'Boffa',
			'Kissidougou',
			'Kindia',
			'Lélouma',
			'Karfamoria',
			'Nzérékoré',
			'Kérouané',
			'Kalinko',
			'Tondon',
			'Siguiri',
			'Doko',
			'Dialakoro',
			'Tomba kanssa',
			'Youkounkoun',
			'Dabola',
			'Niandan Koro',
			'Nabou',
			'Fidako',
			'Mali',
			'Conakry',
			'Macenta',
			'Lola',
			'Gueckedou',
			'Dalaba',
			'Diomabana',
			'Mignada',
			'Yomou',
			'Labé',
			'Dubréka',
			'Koumandjanbougou',
			'Tokonou',
			'Télimélé',
			'Mandiana',
			'Sanguéya',
			'Koundara',
			'Faranah',
			'Pita',
			'Kimbo',
			'Dinguiraye',
			'Touba',
			'Sansando',
			'Lansanaya',
			'Koubia',
			'Kintinian',
			'Banora',
			'Bate Nafadji',
			'Boké',
			'Kankan',
			'Mamou',
			'Fria',
			'Forécariah',
			'Tougué',
			'Foula Mori',
			'Camayenne',
		],
	},
	'Guinea-Bissau': {
		country: 'Guinea-Bissau',
		cities: [
			'Quinhámel',
			'Bafatá',
			'Quebo',
			'Canchungo',
			'Bissau',
			'Fulacunda',
			'Bissorã',
			'Buba',
			'Mansôa',
			'Pitche',
			'Gabú',
			'Catió',
			'Bolama',
			'Cacheu',
			'Bubaque',
			'Pirada',
			'Farim',
		],
	},
	Guyana: {
		country: 'Guyana',
		cities: [
			'Linden',
			'Rosignol',
			'Lethem',
			'Anna Regina',
			'New Amsterdam',
			'Mahdia',
			'Mahaica Village',
			'Skeldon',
			'Bartica',
			'Parika',
			'Mabaruma',
			'Georgetown',
			'Mahaicony Village',
			'Vreed-en-Hoop',
			'Fort Wellington',
		],
	},
	Haiti: {
		country: 'Haiti',
		cities: [
			'Perches',
			'Jérémie',
			'Chardonnière',
			'Cavaillon',
			'Carice',
			'Baie de Henne',
			'Tiburon',
			'Petite Anse',
			'Ouanaminthe',
			'Miragoâne',
			'Caracol',
			'Fort Liberté',
			'Port-Margot',
			'Port-de-Paix',
			'Mayisad',
			'Jacmel',
			'Petit Trou de Nippes',
			'Mirebalais',
			'Dérac',
			'Corail',
			'Gressier',
			'Delmas 73',
			'Les Cayes',
			'Anse-à-Veau',
			'Léogâne',
			'Borgne',
			'Kenscoff',
			'Hinche',
			'Fond Parisien',
			'Koto',
			'Cornillon',
			'Baradères',
			'Ti Port-de-Paix',
			'Ranquitte',
			'Tigwav',
			'Pétionville',
			'Môle Saint-Nicolas',
			'Chantal',
			'Limonade',
			'Cerca la Source',
			'Thomassique',
			'Les Anglais',
			'Grande Saline',
			'Gonaïves',
			'Petite Rivière de Nippes',
			'Grande Rivière du Nord',
			'Dessalines',
			'Moron',
			'Marmelade',
			'Bombardopolis',
			'Anse à Galets',
			'Trou du Nord',
			'Lascahobas',
			'Acul du Nord',
			'Verrettes',
			'Thiotte',
			'Milot',
			'Kotdefè',
			'Gros Morne',
			'Les Abricots',
			'Torbeck',
			'Saint-Raphaël',
			'Port-au-Prince',
			'Port-à-Piment',
			'Jean-Rabel',
			'Fond Bassin Bleu',
			'Dame-Marie',
			'Anse-à-Pitre',
			'Plaine du Nord',
			'Montòrganize',
			'Cabaret',
			'Thomonde',
			'Phaëton',
			'Les Irois',
			'Thomazeau',
			'Pilate',
			'Pignon',
			'Grangwav',
			'Dondon',
			'Marigot',
			'Belle-Anse',
			'Aquin',
			'Saint-Louis du Sud',
			'Roche-à-Bateau',
			'Désarmes',
			'Cayes-Jacmel',
			'Carrefour',
			'Fonds Verrettes',
			'Saint-Marc',
			'Saint-Louis du Nord',
			'Quartier Morin',
			'Plaisance',
			'Lenbe',
			'Fond des Blancs',
			'Ferrier',
			'Croix-des-Bouquets',
			'Chambellan',
			'Anse Rouge',
			'Ennery',
			'Cap-Haïtien',
			'Bahon',
			'Arcahaie',
		],
	},
	Honduras: {
		country: 'Honduras',
		cities: [
			'Veracruz',
			'San Nicolás',
			'San Francisco de la Paz',
			'Peña Blanca',
			'Nueva Armenia',
			'Monjarás',
			'Minas de Oro',
			'Los Planes',
			'Lamaní',
			'Jícaro Galán',
			'Concordia',
			'Callejones',
			'Auka',
			'Agua Caliente',
			'Villa de San Antonio',
			'Valle de Ángeles',
			'Santa Lucía',
			//'Santa Ana',
			'San Francisco del Valle',
			'San Antonio de Cortés',
			'Jutiapa',
			'Ilama',
			'El Tablón',
			'El Paraíso',
			'Dulce Nombre',
			'Copán Ruinas',
			'Choloma',
			'Armenta',
			'Talanga',
			'San José',
			'San Joaquín',
			'Orica',
			'Jutiquile',
			'El Puente',
			'Cucuyagua',
			'San Sebastián',
			'San Marcos de Colón',
			'Quebrada de Arena',
			'Pespire',
			'Oropolí',
			'Los Llanitos',
			'La Esperanza',
			'Guarizama',
			'El Escanito',
			'Arimís',
			'Aguas del Padre',
			'Wawina',
			'Savannah Bight',
			'Santa Rosa de Copán',
			'Sambo Creek',
			'Nuevo Chamelecón',
			'Loma Alta',
			'La Virtud',
			'Las Vegas, Santa Barbara',
			'Jericó',
			'Gualaco',
			'El Zapotal del Norte',
			'El Sauce',
			'El Guayabito',
			'El Escaño de Tepale',
			'Danlí',
			'Campamento',
			'Auas',
			'Yuscarán',
			'Sulaco',
			'Sinuapa',
			//'Santa Ana',
			'San Francisco de Yojoa',
			'San Diego',
			'Oropéndolas',
			'Ojo de Agua',
			'Naco',
			'Jocón',
			'El Socorro',
			'El Níspero',
			'Chotepe',
			'Buenos Aires',
			'Vallecillo',
			'Toyós',
			'Taulabé',
			'Sabá',
			'Santa Cruz de Yojoa',
			'San Matías',
			'San Luis',
			'Punuare',
			'Marcala',
			'Los Naranjos',
			'Lepaterique',
			'La Masica',
			'El Juncal',
			'Duyure',
			'Cuyalí',
			'Zamora',
			'Santa María del Real',
			'San Juan de Opoa',
			'Potrerillos',
			'Ocote Paulino',
			'La Trinidad',
			'La Lima',
			'La Ceiba',
			'Guayape',
			'Esquías',
			'El Terrero',
			'El Porvenir',
			'El Achiotal',
			'Zopilotepe',
			'Yorito',
			'Villa Nueva',
			'Tras Cerros',
			'Tegucigalpa',
			'San Nicolás',
			'San Luis',
			'San José del Potrero',
			'San Esteban',
			'Sandy Bay',
			'Río Esteban',
			'Río Bonito',
			'Prieta',
			'Pinalejo',
			'Pimienta Vieja',
			'Las Tejeras',
			'La Flecha',
			'La Brea',
			'Jesús de Otoro',
			'El Porvenir',
			'El Ocote',
			'El Guantillo',
			'Dulce Nombre de Culmí',
			'Concepción del Sur',
			'Ceguaca',
			'Camasca',
			'Santa Rita',
			'San José de Río Tinto',
			'San Buenaventura',
			'Marcovia',
			'Las Ánimas',
			'Jamalteca',
			'Francia',
			'El Triunfo',
			'El Perico',
			'El Milagro',
			'El Lolo',
			'El Ciruelo',
			'Dolores',
			'Chivana',
			'Arada',
			'Amapala',
			'Trinidad de Copán',
			'Taragual',
			'Santiago de Puringla',
			'Santa Rosa de Aguán',
			'Santa Lucía',
			'San Antonio del Norte',
			'Sabanagrande',
			'Quimistán',
			'Quebradas',
			'Omoa',
			'Laguna Seca',
			'Tepanguare',
			'Salamá',
			'Potrerillos',
			'Namasigüe',
			'Lucerna',
			'El Tigre',
			'El Corozal',
			'Casa Quemada',
			'Teguajinal',
			'Támara',
			'Santa Cruz',
			'Santa Bárbara',
			'San Juan de Flores',
			'San José',
			'San José de Colinas',
			'Puerto Alto',
			'Morocelí',
			'Los Tangos',
			'San José de Las Conchas',
			'La Guacamaya',
			'Jutiapa',
			'Iralaya',
			'El Rincón',
			'El Rancho',
			'El Negrito',
			'El Guapinol',
			'El Guante',
			'El Cubolero',
			'Correderos',
			'Carbajales',
			'Agualote',
			'Agua Azul Rancho',
			'Agalteca',
			'Yaruchel',
			'Siguatepeque',
			'San Nicolás',
			'San Lucas',
			'San Jerónimo',
			'San Jerónimo',
			'Mojimán',
			'La Rosa',
			'Laguna Verde',
			'La Criba',
			'La Concepción',
			'Guanaja',
			'El Progreso',
			'El Plan',
			'El Marañón',
			'Corozal',
			'Bejuco',
			'Baja Mar',
			'Atenas de San Cristóbal',
			'Victoria',
			'San José',
			'San Antonio',
			'Río Chiquito',
			'Río Blanquito',
			'Reitoca',
			'Petoa',
			'Mateo',
			'Mangulile',
			'Lejamaní',
			'La Playona',
			'La Libertad',
			'Güinope',
			'El Chichicaste',
			'Barra Patuca',
			'Travesía',
			'Tocoa',
			'Santa Rita, Copan',
			'San Jerónimo',
			'Paptalaya',
			'Nueva Jalapa',
			'Nueva Esperanza',
			'Manto',
			'La Labor',
			'La Estancia',
			'Jiquinlaca',
			'Guaimitas',
			'Gracias',
			'French Harbor',
			'El Tránsito',
			'El Rusio',
			'Cerro Grande',
			'Arenal',
			'Agua Azul',
			'Yaguacire',
			'Tepusteca',
			'San José de Comayagua',
			'Quebrada Seca',
			'Punta Ocote',
			'Lepaera',
			'La Unión',
			'Las Trojes',
			'Las Lajas',
			'La Esperanza',
			'La Entrada',
			'Guaimaca',
			'Goascorán',
			'El Porvenir',
			'El Ocotón',
			'El Corpús',
			'Cusuna',
			'Cololaca',
			'Bálsamo Oriental',
			'Agua Blanca Sur',
			'Yamaranguila',
			'Silca',
			'Santa Rita',
			'San Juan de Planes',
			'San José de Tarros',
			'San Francisco de Becerra',
			'Coxen Hole',
			'Puerto Castilla',
			'La Unión',
			'Guacamaya',
			'Cane',
			'Belén Gualcho',
			'Araulí',
			'Trinidad',
			'San Agustín',
			'Pueblo Nuevo',
			'Paujiles',
			'La Sarrosa',
			'Langue',
			'La Ermita',
			'Juticalpa',
			'Intibucá',
			'Gualjoco',
			'Casa Quemada',
			'Baracoa',
			'Agua Fría',
			'Tornabé',
			'Tela',
			'Santa Ana de Yusguare',
			'San Pedro de Tutule',
			'Nombre de Jesús',
			'Mata de Plátano',
			'Joconal',
			'El Suyatal',
			'El Olivar',
			'Cuyamel',
			'Cofradía',
			'Cofradía',
			'Azacualpa',
			'Ayapa',
			'Atima',
			'Antigua Ocotepeque',
			'Ajuterique',
			'San Pedro Sula',
			'San Manuel',
			'San Antonio de la Cuesta',
			'Río Abajo',
			'Quebrada Larga',
			'Potrerillos',
			'Nueva Ocotepeque',
			'Mezapa',
			'Lomitas',
			'El Porvenir',
			'El Pino',
			'Elíxir',
			'El Bálsamo',
			'Ciudad Choluteca',
			'Berlín',
			'Armenia',
			'Aramecina',
			'Villanueva',
			'Trojas',
			'Sula',
			'San Vicente Centenario',
			'San Francisco de Coray',
			'Río Lindo',
			'Punta Piedra',
			'Naranjito',
			'Monterrey',
			'Marale',
			'Las Vegas',
			'La Encarnación',
			'El Tular',
			'Rancho Grande',
			'El Mochito',
			'El Llano',
			'El Chimbo',
			'El Agua Dulcita',
			'Cerro Blanco',
			'La Alianza',
			'Trujillo',
			'Teupasenti',
			'San Marcos',
			'San Luis de Planes',
			'Quezailica',
			'Morazán',
			'Liure',
			'La Sabana',
			'La Guama',
			'Erandique',
			'Corocito',
			'Camalote',
			'Texíguat',
			'Yoro',
			'Yarumela',
			'Santa Elena',
			'San Ignacio',
			'Ojojona',
			'Nacaome',
			'La Guata',
			'Coyoles Central',
			'Concepción de la Barranca',
			'Cañaveral',
			'Valle de Ángeles',
			'Sensenti',
			'San Pedro Zacapa',
			'Pueblo Nuevo',
			'Protección',
			'Orocuina',
			'Ojos de Agua',
			'La Zumbadora',
			'La Bueso',
			'El Pedernal',
			'Corquín',
			'Puerto Cortez',
			'Zambrano',
			'San Lorenzo',
			'San José del Boquerón',
			'Salamá',
			'Olanchito',
			'Ojos de Agua',
			'Florida',
			'El Obraje',
			'Comayagua',
			'Chalmeca',
			'Brus Laguna',
			'Arizona',
			'Villa de San Francisco',
			'Taujica',
			'Subirana',
			'San Marcos',
			'San Juan Pueblo',
			'Puerto Lempira',
			'Los Caminos',
			'La Libertad',
			'La Estancia',
			'Flores',
			'El Triunfo de la Cruz',
			'El Rosario',
			'Corpus',
			'El Benque',
			'Cedros',
			'Bonito Oriental',
			'Santa Cruz',
			'San Francisco de Cones',
			'San Francisco',
			'San Fernando',
			'San Antonio',
			'La Paz',
			'La Mina',
			'La Jutosa',
			'Jacaleapa',
			'El Obraje',
			'Concepción de Guasistagua',
			'Azacualpa',
		],
	},
	'Hong Kong, China': {
		country: 'Hong Kong, China',
		cities: [
			'Ma On Shan',
			'Kowloon',
			'Tai Po',
			'Pok Fu Lam',
			'Victoria',
			'Tseung Kwan O',
			'Yuen Long Kau Hui',
			'Tsing Yi Town',
			'Tai O',
			'Ping Shan',
			'Sham Shui Po',
			'Hong Kong',
			'Fanling',
			'Tin Shui Wai',
			'Tuen Mun',
			'Aberdeen',
			'Tung Chung',
			'Sok Kwu Wan',
			'Sha Tin',
			'Central',
			'Sai Kung',
			'Yung Shue Wan',
			'Ngong Ping',
			'Wan Chai',
			'Tsuen Wan',
			'Wong Tai Sin',
			'Kwai Chung',
		],
	},
	Hungary: {
		country: 'Hungary',
		cities: [
			'Tiszaroff',
			'Tarnalelesz',
			'Sarkad',
			'Polgár',
			'Nyírcsaholy',
			'Mátészalka',
			'Maklár',
			'Kunszentmárton',
			'Kunhegyes',
			'Füzesabony',
			'Veszprém',
			'Tompa',
			'Tolna',
			'Tokod',
			'Szászvár',
			'Pétfürdő',
			'Mágocs',
			'Komárom',
			'Kistarcsa',
			'Ják',
			'Herend',
			'Győr',
			'Dombóvár',
			'Döbrököz',
			'Bükkösd',
			'Bátonyterenye',
			'Balatonalmádi',
			'Bábolna',
			'Ágasegyháza',
			'Ács',
			'Tiszavasvári',
			'Szolnok',
			'Ópályi',
			'Maroslele',
			'Köröstarcsa',
			'Gyomaendrőd',
			'Füzesgyarmat',
			'Földes',
			'Felsőtárkány',
			'Döge',
			'Doboz',
			'Arló',
			'Vasvár',
			'Taksony',
			'Szurdokpüspöki',
			'Siófok',
			'Sellye',
			'Ráckeve',
			'Mélykút',
			'Mecseknádasd',
			'Kazár',
			'Decs',
			'Csurgó',
			'Celldömölk',
			'Bácsbokod',
			'Albertirsa',
			'Budapest XIII. kerület',
			'Budapest XVI. kerület',
			'Kerepes',
			'Tótkomlós',
			'Tiszabő',
			'Tiszabezdéd',
			'Szentistván',
			'Sajóbábony',
			'Mezőhegyes',
			'Kunmadaras',
			'Hortobágy',
			'Hajdúnánás',
			'Edelény',
			'Ebes',
			'Biharnagybajom',
			'Abony',
			'Zalaegerszeg',
			'Zagyvaszántó',
			'Szigetszentmiklós',
			'Szany',
			'Somogyvár',
			'Simontornya',
			'Piliscsév',
			'Perbál',
			'Mocsa',
			'Kőbánya',
			'Kisbér',
			'Győrújbarát',
			'Gyönk',
			'Forráskút',
			'Előszállás',
			'Cserszegtomaj',
			'Csemő',
			'Bugyi',
			'Bodajk',
			'Ádánd',
			'Budapest XXI. kerület',
			'Tiszasüly',
			'Mezőtúr',
			'Mezőkövesd',
			'Magyarbánhegyes',
			'Kerecsend',
			'Gádoros',
			'Felsőzsolca',
			'Csanytelek',
			'Zamárdi',
			'Villány',
			'Telki',
			'Tahitótfalu',
			'Szigetvár',
			'Szentendre',
			'Szank',
			'Ruzsa',
			'Pécsvárad',
			'Mihályi',
			'Kiskőrös',
			'Harkány',
			'Gyöngyöspata',
			'Gara',
			'Ercsi',
			'Dunaszentgyörgy',
			'Bőny',
			'Boldog',
			'Beled',
			'Bajna',
			'Tömörkény',
			'Tiszabercel',
			'Taktaszada',
			'Szendrő',
			'Petneháza',
			'Pátroha',
			'Monostorpályi',
			'Miskolc',
			'Kétegyháza',
			'Kemecse',
			'Kálmánháza',
			'Szigetcsép',
			'Sóskút',
			'Sióagárd',
			'Nyúl',
			'Kincsesbánya',
			'Kaposmérő',
			'Kápolnásnyék',
			'Isaszeg',
			'Hidas',
			'Heréd',
			'Halászi',
			'Fülöpszállás',
			'Fertőrákos',
			'Felsőpakony',
			'Farmos',
			'Dunaszekcső',
			'Bokod',
			'Barcs',
			'Alsónémedi',
			'Adony',
			'Budapest III. kerület',
			'Ózd',
			'Nyíregyháza',
			'Nyírbogdány',
			'Nagyszénás',
			'Mindszent',
			'Kondoros',
			'Izsófalva',
			'Fehérgyarmat',
			'Cigánd',
			'Balkány',
			'Zugló',
			'Vál',
			'Taszár',
			'Tapolca',
			'Tab',
			'Sümeg',
			'Sárkeresztúr',
			'Mohács',
			'Mezőszilas',
			'Kozármisleny',
			'Karád',
			'Kaposvár',
			'Hévíz',
			'Fertőszentmiklós',
			'Dunabogdány',
			'Böhönye',
			'Berhida',
			'Bátaszék',
			'Balatonszabadi',
			'Babócsa',
			'Budapest XVIII. kerület',
			'Verpelét',
			'Tiszaújváros',
			'Tiszapüspöki',
			'Szeghalom',
			'Szamosszeg',
			'Sajóvámos',
			'Megyaszó',
			'Makó',
			'Csongrád',
			'Zomba',
			'Várpalota',
			'Tököl',
			'Tar',
			'Sükösd',
			'Solymár',
			'Pásztó',
			'Parádsasvár',
			'Pannonhalma',
			'Mány',
			'Kimle',
			'Jánoshalma',
			'Hosszúhetény',
			'Hévízgyörk',
			'Gyöngyös',
			'Forrópuszta',
			'Budaörs',
			'Budakeszi',
			'Budapest I. kerület',
			'Tiszakécske',
			'Nyírlugos',
			'Nyírbogát',
			'Mezőkeresztes',
			'Hosszúpályi',
			'Hajdúszovát',
			'Csökmő',
			'Zirc',
			'Zalaszentgrót',
			'Vecsés',
			'Somoskőújfalu',
			'Révfülöp',
			'Nagyoroszi',
			'Nagydorog',
			'Kispest',
			'Kisláng',
			'Dunaújváros',
			'Devecser',
			'Császártöltés',
			'Ajka',
			'Budapest IV. kerület',
			'Héhalom',
			'Túrkeve',
			'Tolcsva',
			'Tiszafüred',
			'Tállya',
			'Szajol',
			'Rákóczifalva',
			'Olaszliszka',
			'Napkor',
			'Nagyecsed',
			'Mikepércs',
			'Mezőzombor',
			'Fegyvernek',
			'Fábiánsebestyén',
			'Eger',
			'Dombrád',
			'Cserkeszőlő',
			'Aszaló',
			'Táplánszentkereszt',
			'Tápióbicske',
			'Százhalombatta',
			'Sásd',
			'Sármellék',
			'Pilisszentkereszt',
			'Pilisszántó',
			'Pilismarót',
			'Orgovány',
			'Neszmély',
			'Mogyoród',
			'Madaras',
			'Kecskemét',
			'Jászberény',
			'Iregszemcse',
			'Iklad',
			'Hőgyész',
			'Gyöngyöstarján',
			'Dunaföldvár',
			'Dávod',
			'Császár',
			'Buják',
			'Ballószög',
			'Alap',
			'Budapest XXII. kerület',
			'Szatymaz',
			'Röszke',
			'Nyírmártonfalva',
			'Nagyhalász',
			'Monok',
			'Mezőberény',
			'Kék',
			'Bőcs',
			'Abasár',
			'Sződ',
			'Szalkszentmárton',
			'Sárszentmihály',
			'Pázmánd',
			'Pánd',
			'Pálmonostora',
			'Nógrád',
			'Nagybaracska',
			'Mórahalom',
			'Letenye',
			'Kistelek',
			'Fadd',
			'Dömsöd',
			'Dánszentmiklós',
			'Csepreg',
			'Budakalász',
			'Baracs',
			'Balatonlelle',
			'Balatonberény',
			'Aba',
			'Szihalom',
			'Sándorfalva',
			'Sajószöged',
			'Rakamaz',
			'Ófehértó',
			'Nyíradony',
			'Mátraderecske',
			'Kengyel',
			'Kaba',
			'Hajdúszoboszló',
			'Hajdúhadház',
			'Gyulaháza',
			'Elek',
			'Balástya',
			'Zámoly',
			'Vép',
			'Segesd',
			'Pellérd',
			'Ozora',
			'Nagykáta',
			'Mátraterenye',
			'Lőrinci',
			'Lakitelek',
			'Kőszeg',
			'Kiskunhalas',
			'Kerekegyháza',
			'Inárcs',
			'Hajmáskér',
			'Gyenesdiás',
			'Gomba',
			'Dány',
			'Csávoly',
			'Csány',
			'Cece',
			'Aszód',
			'Bácsalmás',
			'Ásványráró',
			'Budapest XX. kerület',
			'Újfehértó',
			'Nyírmeggyes',
			'Nyírkarász',
			'Ibrány',
			'Harsány',
			'Erdőtelek',
			'Encs',
			'Demecser',
			'Csenger',
			'Velence',
			'Tass',
			'Szigethalom',
			'Soltvadkert',
			'Sárvár',
			'Piliscsaba',
			'Nagybajom',
			'Környe',
			'Kéthely',
			'Kesztölc',
			'Kelebia',
			'Fülöpjakab',
			'Hajós',
			'Gyál',
			'Csabrendek',
			'Cegléd',
			'Budapest',
			'Biatorbágy',
			'Báta',
			'Ásotthalom',
			'Mosonszentmiklós',
			'Záhony',
			'Tiszabura',
			'Tarcal',
			'Parád',
			'Nyírtass',
			'Nyírbátor',
			'Jászdózsa',
			'Hernádnémeti',
			'Boldva',
			'Ajak',
			'Vértesszőlős',
			'Sárosd',
			'Polgárdi',
			'Pilisborosjenő',
			'Péteri',
			'Perkáta',
			'Nagytarcsa',
			'Mezőfalva',
			'Mátraverebély',
			'Lébény',
			'Lajoskomárom',
			'Keszthely',
			'Katymár',
			'Jászszentlászló',
			'Farád',
			'Diósd',
			'Csömör',
			'Bercel',
			'Almásfüzitő',
			'Újkígyós',
			'Tunyogmatolcs',
			'Tornyospálca',
			'Tiszakeszi',
			'Tarpa',
			'Ónod',
			'Nagykálló',
			'Mándok',
			'Hajdúböszörmény',
			'Hajdúbagos',
			'Berettyóújfalu',
			'Városföld',
			'Újhartyán',
			'Pacsa',
			'Nyergesújfalu',
			'Nyáregyháza',
			'Nagykovácsi',
			'Lepsény',
			'Lenti',
			'Kunszentmiklós',
			'Körmend',
			'Kakucs',
			'Jánossomorja',
			'Halásztelek',
			'Gyöngyöshalász',
			'Fehérvárcsurgó',
			'Etyek',
			'Érsekvadkert',
			'Csolnok',
			'Tiszanagyfalu',
			'Szikszó',
			'Orosháza',
			'Onga',
			'Nyírmihálydi',
			'Karcsa',
			'Hodász',
			'Halmaj',
			'Békéssámson',
			'Bagamér',
			'Aranyosapáti',
			'Zalalövő',
			'Úri',
			'Tóalmás',
			'Szécsény',
			'Pusztavám',
			'Pusztaszer',
			'Nagykanizsa',
			'Kiskunlacháza',
			'Kalocsa',
			'Felsőszentiván',
			'Érsekcsanád',
			'Bóly',
			'Balatonszárszó',
			'Balatonföldvár',
			'Ágfalva',
			'Budapest VIII. kerület',
			'Tiszadob',
			'Szilvásvárad',
			'Sajószentpéter',
			'Rákócziújfalu',
			'Jászapáti',
			'Borsodnádasd',
			'Zsámbék',
			'Zákányszék',
			'Vácszentlászló',
			'Tihany',
			'Tápiószőlős',
			'Szentkirály',
			'Rábapatona',
			'Petőfibánya',
			'Pécel',
			'Őrbottyán',
			'Nagyréde',
			'Mende',
			'Kocsér',
			'Kecskéd',
			'Karancskeszi',
			'Akasztó',
			'Székkutas',
			'Sáránd',
			'Nyírgyulaj',
			'Medgyesegyháza',
			'Jászszentandrás',
			'Jászkarajenő',
			'Földeák',
			'Baks',
			'Alsózsolca',
			'Zsámbok',
			'Zánka',
			'Verőce',
			'Vác',
			'Türje',
			'Tét',
			'Tázlár',
			'Tarján',
			'Tápióság',
			'Sződliget',
			'Szada',
			'Sárbogárd',
			'Rétság',
			'Pincehely',
			'Pécs',
			'Nagycenk',
			'Nagyatád',
			'Murakeresztúr',
			'Maglód',
			'Kapuvár',
			'Jobbágyi',
			'Iváncsa',
			'Győrszemere',
			'Gyöngyössolymos',
			'Dunavarsány',
			'Csopak',
			'Bicske',
			'Budapest VI. kerület',
			'Budapest XXIII. kerület',
			'Vaja',
			'Szakoly',
			'Püspökladány',
			'Ostoros',
			'Nyírvasvári',
			'Máriapócs',
			'Mályi',
			'Kocsord',
			'Egyek',
			'Biharkeresztes',
			'Bélapátfalva',
			'Tata',
			'Tamási',
			'Pusztaszabolcs',
			'Kiskunfélegyháza',
			'Jánosháza',
			'Gencsapáti',
			'Dusnok',
			'Dunapataj',
			'Csokonyavisonta',
			'Bősárkány',
			'Berzence',
			'Becsehely',
			'Bakonycsernye',
			'Baja',
			'Adács',
			'Sirok',
			'Sátoraljaújhely',
			'Okány',
			'Nyírtelek',
			'Mád',
			'Konyár',
			'Kisvárda',
			'Jánoshida',
			'Buj',
			'Bogács',
			'Besenyszög',
			'Békésszentandrás',
			'Bekecs',
			'Tengelic',
			'Rózsaszentmárton',
			'Paks',
			'Ősi',
			'Oroszlány',
			'Kunfehértó',
			'Fonyód',
			'Erdőkertes',
			'Ecséd',
			'Dunavecse',
			'Csetény',
			'Csengőd',
			'Ceglédbercel',
			'Beremend',
			'Budapest XVII. kerület',
			'Szelevény',
			'Józsefváros',
			'Vésztő',
			'Szabadkígyós',
			'Sajóörös',
			'Porcsalma',
			'Nyírpazony',
			'Nyírmada',
			'Nyékládháza',
			'Kaszaper',
			'Heves',
			'Csanádpalota',
			'Andornaktálya',
			'Abaújszántó',
			'Vajszló',
			'Üröm',
			'Úrkút',
			'Törökbálint',
			'Szob',
			'Szentkirályszabadja',
			'Örkény',
			'Őcsény',
			'Mór',
			'Mátranovák',
			'Leányfalu',
			'Dunaharaszti',
			'Domony',
			'Diósjenő',
			'Balatonkenese',
			'Balatonfenyves',
			'Balatonboglár',
			'Apostag',
			'Budapest XIX. kerület',
			'Újszász',
			'Tuzsér',
			'Tiszalök',
			'Tarnaörs',
			'Szentes',
			'Sárrétudvari',
			'Putnok',
			'Prügy',
			'Pocsaj',
			'Nagyrábé',
			'Levelek',
			'Hejőbába',
			'Gégény',
			'Deszk',
			'Balmazújváros',
			'Vámosgyörk',
			'Sülysáp',
			'Szárliget',
			'Szabadbattyán',
			'Salgótarján',
			'Rajka',
			'Ráckeresztúr',
			'Pilisszentiván',
			'Pér',
			'Pápa',
			'Palotás',
			'Németkér',
			'Nemesvámos',
			'Nagymaros',
			'Mosonmagyaróvár',
			'Lábatlan',
			'Kóka',
			'Káloz',
			'Harta',
			'Gyömrő',
			'Érd',
			'Dunakeszi',
			'Délegyháza',
			'Bogyiszló',
			'Apc',
			'Angyalföld',
			'Budapest XI. kerület',
			'Tiszalúc',
			'Tiszaeszlár',
			'Ököritófülpös',
			'Nagycserkesz',
			'Nádudvar',
			'Méhkerék',
			'Lőkösháza',
			'Létavértes',
			'Kompolt',
			'Komádi',
			'Kenderes',
			'Járdánháza',
			'Hajdúsámson',
			'Gyula',
			'Görbeháza',
			'Fényeslitke',
			'Emőd',
			'Csorvás',
			'Veresegyház',
			'Tápiógyörgye',
			'Rimóc',
			'Madocsa',
			'Lovasberény',
			'Komló',
			'Kadarkút',
			'Dunaalmás',
			'Csorna',
			'Bugac',
			'Abda',
			'Budapest XV. kerület',
			'Tiszaszőlős',
			'Vásárosnamény',
			'Tokaj',
			'Öcsöd',
			'Múcsony',
			'Mérk',
			'Körösladány',
			'Kántorjánosi',
			'Kál',
			'Hódmezővásárhely',
			'Dévaványa',
			'Csanádapáca',
			'Baktalórántháza',
			'Alattyán',
			'Vonyarcvashegy',
			'Visegrád',
			'Valkó',
			'Üllő',
			'Törtel',
			'Töltéstava',
			'Tatabánya',
			'Táborfalva',
			'Szentgotthárd',
			'Szedres',
			'Soponya',
			'Pákozd',
			'Ócsa',
			'Nemesnádudvar',
			'Naszály',
			'Karancslapujtő',
			'Hort',
			'Hernád',
			'Hercegszántó',
			'Helvécia',
			'Gödöllő',
			'Fót',
			'Fertőd',
			'Enying',
			'Bordány',
			'Bonyhád',
			'Balassagyarmat',
			'Badacsonytomaj',
			'Acsa',
			'Tyukod',
			'Taktaharkány',
			'Szerencs',
			'Szarvas',
			'Poroszló',
			'Ópusztaszer',
			'Nagydobos',
			'Mezőcsát',
			'Kiszombor',
			'Kisújszállás',
			'Kisköre',
			'Békés',
			'Báránd',
			'Apagy',
			'Abádszalók',
			'Zsombó',
			'Vaskút',
			'Szentlőrinckáta',
			'Öttevény',
			'Nyárlőrinc',
			'Kecel',
			'Kartal',
			'Jászárokszállás',
			'Galgahévíz',
			'Erzsébetváros',
			'Csengele',
			'Tiszakarád',
			'Tiszaföldvár',
			'Tiszacsege',
			'Téglás',
			'Szegvár',
			'Sárospatak',
			'Rudabánya',
			'Pétervására',
			'Kunágota',
			'Kótaj',
			'Jászalsószentgyörgy',
			'Békéscsaba',
			'Anarcs',
			'Üllés',
			'Tárnok',
			'Tápiószele',
			'Tápiószecső',
			'Szombathely',
			'Szentgálpuszta',
			'Székesfehérvár',
			'Szabadszállás',
			'Seregélyes',
			'Sárisáp',
			'Romhány',
			'Pilis',
			'Páty',
			'Monor',
			'Lánycsók',
			'Lajosmizse',
			'Kisszállás',
			'Galgamácsa',
			'Dorog',
			'Bölcske',
			'Bakonyszentlászló',
			'Baj',
			'Tiszadada',
			'Sajókaza',
			'Recsk',
			'Nyíracsád',
			'Mezőkovácsháza',
			'Kisléta',
			'Karácsond',
			'Kállósemjén',
			'Jászladány',
			'Jászjákóhalma',
			'Gesztely',
			'Farkaslyuk',
			'Encsencs',
			'Egerszalók',
			'Domaszék',
			'Debrecen',
			'Cibakháza',
			'Bucsa',
			'Besenyőtelek',
			'Arnót',
			'Szentlőrinc',
			'Sopron',
			'Solt',
			'dunaújváros',
			'Marcali',
			'Hatvan',
			'Göd',
			'Ecser',
			'Tiszaalpár',
			'Budapest XII. kerület',
			'Tószeg',
			'Törökszentmiklós',
			'Tiszaszentimre',
			'Tiszanána',
			'Szirmabesenyő',
			'Ricse',
			'Pusztaföldvár',
			'Nyírbéltek',
			'Nyírábrány',
			'Karcag',
			'Hajdúdorog',
			'Gönc',
			'Domoszló',
			'Battonya',
			'Zalakomár',
			'Újszilvás',
			'Tát',
			'Tardos',
			'Tápiószentmárton',
			'Szomód',
			'Szigetújfalu',
			'Pilisvörösvár',
			'Nagykőrös',
			'Nagyigmánd',
			'Martonvásár',
			'Litér',
			'Lábod',
			'Kosd',
			'Kocs',
			'Kiskunmajsa',
			'Izsák',
			'Dabas',
			'Csobánka',
			'Bük',
			'Bátya',
			'Baracska',
			'Balatonfüred',
			'Budapest II. kerület',
			'Kismaros',
			'Zagyvarékas',
			'Vámospércs',
			'Szeged',
			'Sály',
			'Sajólád',
			'Kevermes',
			'Kazincbarcika',
			'Jászkisér',
			'Dombegyház',
			'Derecske',
			'Bököny',
			'Apátfalva',
			'Tura',
			'Szentmártonkáta',
			'Szekszárd',
			'Süttő',
			'Siklós',
			'Répcelak',
			'Rácalmás',
			'Pomáz',
			'Nagymányok',
			'Lengyeltóti',
			'Kóny',
			'Gárdony',
			'Esztergom',
			'Dég',
			'Csákvár',
			'Bag',
			'Algyő',
		],
	},
	Iceland: {
		country: 'Iceland',
		cities: [
			'Ísafjörður',
			'Hvolsvöllur',
			'Keflavík',
			'Álftanes',
			'Hafnarfjörður',
			'Sandgerði',
			'Grindavík',
			'Sauðárkrókur',
			'Vestmannaeyjar',
			'Borgarnes',
			'Höfn',
			'Eskifjörður',
			'Seltjarnarnes',
			'Egilsstaðir',
			'Laugar',
			'Kópavogur',
			'Garður',
			'Borgarnes',
			'Reykjavík',
			'Þorlákshöfn',
			'Reykjanesbær',
			'Vogar',
			'Stykkishólmur',
			'Neskaupstaður',
			'Akureyri',
			'Dalvík',
			'Mosfellsbær',
			'Norðurþing',
			'Selfoss',
			'Reyðarfjörður',
			'Hveragerði',
			'Siglufjörður',
			'Ólafsvík',
			'Garðabær',
			'Akranes',
		],
	},
	India: {
		country: 'India',
		cities: [
			'Pūnch',
			'Keelakarai',
			'Verāval',
			'Vejalpur',
			'Vedaraniyam',
			'Usehat',
			'Un',
			'Trāl',
			'Tiruttangal',
			'Sulur',
			'Srivilliputhur',
			'Karanpur',
			'Siwān',
			'Sirsi',
			'Sirsā',
			'Sīrkāzhi',
			'Sīkar',
			'Siliguri',
			'Shāhābād',
			'Sawāi Mādhopur',
			'Sairang',
			'Pulgaon',
			'Pratāpgarh',
			'Piploda',
			'Pīpalkoti',
			'Pilāni',
			'Peranāmpattu',
			'Pawāyan',
			'Parol',
			'Parādīp Garh',
			'Pāli',
			'Nihtaur',
			'Nawābganj',
			'Narsīpatnam',
			'Narasapur',
			'Nangal',
			'Nandigāma',
			'Namli',
			'Nāmagiripettai',
			'Nādbai',
			'Muhammadābād',
			'Moram',
			'Meethari Marwar',
			'Maudaha',
			'Mānsa',
			'Mannargudi',
			'Māngrol',
			'Mandasa',
			'Mānāvadar',
			'Malkāpur',
			'Malīhābād',
			'Mākhjan',
			'Maindargi',
			'Madukkarai',
			'Madhugiri',
			'Māchhīwāra',
			'Lālsot',
			'Lakhipur',
			'Kunnamangalam',
			'Kulpahār',
			'Kuchera',
			'Kothi',
			'Kotā',
			'Kondapalle',
			'Kishangarh',
			'Khopoli',
			'Kendrāparha',
			'Kattanam',
			'Kāsganj',
			'Kāsaragod',
			'Karumbākkam',
			'Kartārpur',
			'Kārkala',
			'Karera',
			'Kānkon',
			'Kalpatta',
			'Kakching',
			'Kailāshahar',
			'Jharsuguda',
			'Jhārgrām',
			'Jhanjhārpur',
			'Jetpur',
			'Jetalsar',
			'Jāmadoba',
			'Jaisinghnagar',
			'Jagdalpur',
			'Itimādpur',
			'Isāgarh',
			'Iglās',
			'Howli',
			'Himatnagar',
			'Hāthras',
			'Hariāna',
			'Gulābpura',
			'Gohadi',
			'Garhi Pūkhta',
			'Gannavaram',
			'Farah',
			'Etāwah',
			'Eral',
			'Doda',
			'Dhūlia',
			'Dewas',
			'Devgarh',
			'Daurāla',
			'Cuncolim',
			'Colonelganj',
			'Chopan',
			'Chikitigarh',
			'Chhibrāmau',
			'Chandauli',
			'Cavelossim',
			'Botād',
			'Borsad',
			'Etāwa',
			'Bilāra',
			'Bijnor',
			'Bhowali',
			'Betamcherla',
			'Behror',
			'Bāwal',
			'Basi',
			'Barwāla',
			'Barwādih',
			'Bārdoli',
			'Budge Budge',
			'Ayyampettāi',
			'Ashti',
			'Ashta',
			'Anta',
			'Anakāpalle',
			'Alwar',
			'Ajjampur',
			'Agartala',
			'Bhandārdaha',
			'Gho Brāhmanān de',
			'Greater Noida',
			'Lal Bahadur Nagar',
			'Deoli',
			'Kalamassery',
			'Zunheboto',
			'Wāris Alīganj',
			'Uttamapālaiyam',
			'Umarga',
			'Tonk',
			'Tiruvottiyūr',
			'Tezu',
			'Suchindram',
			'Sidhpura',
			'Siddhapur',
			'Sendhwa',
			'Satānā',
			'Saraikela',
			'Sanquelim',
			'Sāndi',
			'Sānand',
			'Saktī',
			'Saint Thomas Mount',
			'Sahaspur',
			'Rasrā',
			'Rāmgarh',
			'Rāmganj Mandi',
			'Rām Dās',
			'Kollam',
			'Punāsa',
			'Pudūr',
			'Pāonta Sāhib',
			'Panara',
			'Palle',
			'Orai',
			'Nūrmahal',
			'Niwāri',
			'Nīlokheri',
			'Narsimhapur',
			'Namchi',
			'Mārahra',
			'Mānpur',
			'Mangalore',
			'Mallasamudram',
			'Majītha',
			'Madanapalle',
			'Lormi',
			'Kuppam',
			'Kunigal',
			'Kumbhrāj',
			'Kollegāl',
			'Kolanukonda',
			'Khirkiyān',
			'Khem Karan',
			'Kharkhauda',
			'Kelamangalam',
			'Kantilo',
			'Kannauj',
			'Kankipādu',
			'Kangayam',
			'Kādīpur',
			'Jīnd',
			'Jewar',
			'Jawāla Mukhi',
			'Jamālpur',
			'Jalālābad',
			'Honavar',
			'Hātā',
			'Guledagudda',
			'Govindgarh',
			'Gopālpur',
			'Ghāziābād',
			'Gadhinglaj',
			'Fort Gloster',
			'Dhupgāri',
			'Dhuburi',
			'Daulatpur',
			'Cochin',
			'Churāchāndpur',
			'Changanācheri',
			'Bongaigaon',
			'Bihār Sharīf',
			'Bhīmunipatnam',
			'Bhālki',
			'patamda',
			'Bangarapet',
			'Bambolim',
			'Āthagarh',
			'Ambājogāi',
			'Amarpātan',
			'Ālangulam',
			'Ālangudi',
			'Ahmedabad',
			'Adra',
			'Haripur',
			'Gobindapur',
			'Banbasa',
			'Aluva',
			'Baga',
			'Jaigaon',
			'Bellampalli',
			'Bahula',
			'Kalimpong, Крукети',
			'Savarkundla',
			'Resubelpara',
			'Wadgaon Tejan',
			'Walhur',
			'Nautan',
			'Zafarābād',
			'Vikārābād',
			'Vepagunta',
			'Ugu',
			'Tūndla',
			'Thānesar',
			'Tarāna',
			'Tankāra',
			'Tambaram',
			'Srīvardhan',
			'Sopur',
			'Sohna',
			'Sinnar',
			'Simga',
			'Shirgaon',
			'Shimoga',
			'Shillong',
			'Sheikhpura',
			'Shāhi',
			'Shahdol',
			'Sattur',
			'Sargūr',
			'Sankeshwar',
			'Samthar',
			'Sālūmbar',
			'Sāitlaw',
			'Safidon',
			'Robertsganj',
			'Revelganj',
			'Closepet',
			'Rāmachandrapuram',
			'Qādiān',
			'Punalūr',
			'Puliyangudi',
			'Porsa',
			'Ponnampet',
			'Pithora',
			'Pātuli',
			'Pātan',
			'Pāsighāt',
			'Pāliyād',
			'Padampur',
			'Padam',
			'Needamangalam',
			'Navalgund',
			'Nashik',
			'Nāgothana',
			'Nāgireddipalli',
			'Murshidābād',
			'Mūl',
			'Moth',
			'Morbi',
			'Modāsa',
			'Mau Aimma',
			'Mataundh',
			'Masinigudi',
			'Margherita',
			'Manthani',
			'Mangrūl Pīr',
			'Māndleshwar',
			'Mākum',
			'Mailāni',
			'Mahur',
			'Mahīshādal',
			'Machilīpatnam',
			'Lucknow',
			'Lonavla',
			'Lākheri',
			'Kokrajhar',
			'Kizhake Chālakudi',
			'Kishanpur',
			'Khuldābād',
			'Khandela',
			'Khāchrod',
			'Kerūr',
			'Karol Bāgh',
			'Kapadvanj',
			'Kandukūr',
			'Jeypore',
			'Jalor',
			'Itaunja',
			'Ichchāpuram',
			'Honnāli',
			'Hingoli',
			'Hilsa',
			'Hatta',
			'Hāsimāra',
			'Gurgaon',
			'Ghosī',
			'Garui',
			'Garhmuktesar',
			'Gangoh',
			'Ettaiyapuram',
			'Durgāpur',
			'Dam Dam',
			'Dugadda',
			'Dimāpur',
			'Dicholi',
			'Dhing',
			'Dhekiajuli',
			'Davorlim',
			'Chopda',
			'Chatrapur',
			'Champawat',
			'Chakia',
			'Brājarājnagar',
			'Bodināyakkanūr',
			'Beed',
			'Bijāwar',
			'Begowāl',
			'Batoti',
			'Barpāli',
			'Barhiya',
			'Barāgaon',
			'Bantvāl',
			'Bandora',
			'Bāndīkūi',
			'Bakreswar',
			'Bahjoi',
			'Bagdogra',
			'Bāgepalli',
			'Badlapur',
			'Badagara',
			'Asifābād',
			'Ankleshwar',
			'Amroha',
			'Amarwāra',
			'Allāhganj',
			'Aduthurai',
			'Ābu Road',
			'Maheshtala',
			'Chīma',
			'Vadigenhalli',
			'Vengavasal',
			'Neelankarai',
			'Sector',
			'7LC',
			'Zamānia',
			'Yol',
			'Warud',
			'Vinukonda',
			'Vasind',
			'Tulsīpur',
			'Toshām',
			'Thān',
			'Tendūkheda',
			'Tāoru',
			'Tājpur',
			'Sultānpur',
			'Suār',
			'Sonāri',
			'Sironj',
			'Sihor',
			'Sherkot',
			'Shegaon',
			'Shāhganj',
			'Sabrūm',
			'Rura',
			'Rāyadrug',
			'Rānia',
			'Rāmpur',
			'Rāmgarh',
			'Raghunāthpur',
			'Ponda',
			'Pināhat',
			'Pīlibhīt',
			'Pendra',
			'Palwal',
			'Pahlgām',
			'Nirmal',
			'Navsari',
			'Navelim',
			'Narasannapeta',
			'Najībābād',
			'Mudukulattūr',
			'Mawāna',
			'Maurānwān',
			'Mangalam',
			'Mandāwar',
			'Māndal',
			'Mahē',
			'Maddūr',
			'Leteri',
			'Kuju',
			'Kotputli',
			'Kotdwāra',
			'Kota',
			'Kolasib',
			'Kolāras',
			'Kodīnar',
			'Kodala',
			'Kirākat',
			'Khowai',
			'Khāmgaon',
			'Katpur',
			'Katghora',
			'Karmāla',
			'Kanniyākumāri',
			'Kāndi',
			'Kāman',
			'Kāmākhyānagar',
			'Kalghatgi',
			'Kaithal',
			'Jalgaon Jamod',
			'Jainpur',
			'Ikauna',
			'Hosanagara',
			'Hole Narsipur',
			'Hojāi',
			'Hīrāpur Hamesha',
			'Hīrākud',
			'Hassan',
			'Harduāganj',
			'Harda Khās',
			'Hanumāngarh',
			'Gunupur',
			'Gumlā',
			'Gūdūr',
			'Gohāna',
			'Giddarbāha',
			'Ghorāwal',
			'Gauribidanur',
			'Gangāwati',
			'Ferokh',
			'Sakharkherda',
			'Dohrighāt',
			'Dharamsala',
			'Dhāri',
			'Dhārchula',
			'Devaprayāg',
			'Daulatpur',
			'Cumbum',
			'Kadapa',
			'Chinchani',
			'Chichli',
			'Chhāpar',
			'Chandla',
			'Chābua',
			'Bilsi',
			'Bilhaur',
			'Bihpuriāgaon',
			'Bhīm Tāl',
			'Bharūch',
			'Bhādra',
			'Behat',
			'Barhi',
			'Barddhamān',
			'Bālotra',
			'Bāh',
			'Bagasra',
			'Anūpgarh',
			'Amudālavalasa',
			'Amethī',
			'Allāpalli',
			'Alīpur',
			'Achhnera',
			'Rāmchandrapur',
			'Gurdaha',
			'Canacona',
			'Pithampur',
			'Barpeta Road',
			'Bishnupur',
			'Seethanagaram',
			'Virajpet',
			'Villupuram',
			'Vartej',
			'Utraula',
			'Uppiliyapuram',
			'Unnāo',
			'Una',
			'Ullal',
			'Tondi',
			'Tirupattur',
			'Thandla',
			'Thenkasi',
			'Tālbahat',
			'Suriāpet',
			'Sringeri',
			'Sonegaon',
			'Sohāgpur',
			'Siswā Bāzār',
			'Sinor',
			'Shāhpur',
			'Shāhāda',
			'Sehore',
			'Sarāi Mīr',
			'Sāngola',
			'Sancoale',
			'Sanaur',
			'Safīpur',
			'Rewa',
			'Repalle',
			'Rangāpāra',
			'Rāmpura',
			'Rameswaram',
			'Puri',
			'Pudukkottai',
			'Pirāwa',
			'Pīpri',
			'Pipili',
			'Perūr',
			'Pātan',
			'Panvel',
			'Pandhāna',
			'Pallappatti',
			'Noāmundi',
			'Mundra',
			'Mohanūr',
			'Medak',
			'Māttūr',
			'Mattanur',
			'Mahobā',
			'Mācherla',
			'Kulu',
			'Kulti',
			'Kovilpatti',
			'Koelwār',
			'Kodumudi',
			'Khātegaon',
			'Khārupatia',
			'Khargone',
			'Kāvali',
			'Kalmeshwar',
			'Kalanaur',
			'Jhālrapātan',
			'Jānsath',
			'Jammu',
			'Jalgaon',
			'Jalālī',
			'Hukeri',
			'Hamīrpur',
			'Haldaur',
			'Hājipur',
			'Gudlavalleru',
			'Gua',
			'Gondiā',
			'Golāghāt',
			'Gokak',
			'Gingee',
			'Giddalūr',
			'Garhākota',
			'Fīrozābād',
			'Fatehgarh',
			'Farrukhābād',
			'Erode',
			'Dwārka',
			'Dondaicha',
			'Doiwāla',
			'Dibrugarh',
			'Darlawn',
			'Dariba',
			'Damoh',
			'Dadra',
			'Chechat',
			'Buguda',
			'Mumbai',
			'Bokajān',
			'Bobbili',
			'Bindki',
			'Bhīmavaram',
			'Bharatpur',
			'Bhadarwāh',
			'Bastī',
			'Barela',
			'Banat',
			'Bālurghāt',
			'Bālugaon',
			'Bakloh',
			'Bāgeshwar',
			'Attili',
			'Anthiyur',
			'Ambad',
			'Alūr',
			'Alappuzha',
			'Akbarpur',
			'Abhayāpuri',
			'Rāmnagar',
			'Noida',
			'Kalavoor',
			'Pherzawl',
			'Virār',
			'Vetapālem',
			'Vedasandūr',
			'Vandavāsi',
			'Tirur',
			'Tirunelveli',
			'Tirumala',
			'Tiruchchendur',
			'Thanjavur',
			'Thāne',
			'Telhāra',
			'Tāramangalam',
			'Talipparamba',
			'Takhatgarh',
			'Srīrāmnagar',
			'Shrīrāmpur',
			'Solāpur',
			'Shirpur',
			'Shiggaon',
			'Sheoganj',
			'Shamsābād',
			'Satwās',
			'Sattenapalle',
			'Sardulgarh',
			'Saraipali',
			'Sanivārsante',
			'Sahaswān',
			'Rāver',
			'Rasūlābād',
			'Rangpo',
			'Rampur Hat',
			'Rājpīpla',
			'Rāj-Nāndgaon',
			'Puruliya',
			'Punganūru',
			'Pithāpuram',
			'Phulbāni',
			'Periyapatti',
			'Panruti',
			'Pandua',
			'Padra',
			'New Delhi',
			'Nārāyanavanam',
			'Naraura',
			'Nanjangūd',
			'Najafgarh',
			'Nābha',
			'Muzaffarnagar',
			'Mūvattupula',
			'Murlīganj',
			'Muddebihāl',
			'Morār',
			'Mārāndahalli',
			'Manmād',
			'Māndvi',
			'Manāwar',
			'Malavalli',
			'Malappuram',
			'Māgām',
			'Madhupur',
			'Madhubani',
			'Lansdowne',
			'Lakhyabad',
			'Kumbakonam',
			'Kūkatpalli',
			'Kottaiyūr',
			'Kotkhai',
			'Korwai',
			'Korāput',
			'Khūtār',
			'Khatauli',
			'Kharar',
			'Kavalūr',
			'Karīmnagar',
			'Kāngar',
			'Homnābād',
			'Hisar',
			'Haringhāta',
			'Hālol',
			'Gudāri',
			'Gosāba',
			'Gopālur',
			'Gomoh',
			'Gokarna',
			'Ghazīpur',
			'Ganjām',
			'Gangāpur',
			'Ferozepore',
			'Fāzilka',
			'Doddaballapura',
			'Dharapuram',
			'Dera Gopipur',
			'Depālpur',
			'Deoria',
			'Delhi',
			'Chotila',
			'Borivli',
			'Bilāspur',
			'Bhānpurī',
			'Beri Khās',
			'Beliātor',
			'Beāwar',
			'Bāsudebpur',
			'Bārāsat',
			'Bāntva',
			'Bangaon',
			'Bangaon',
			'Banganapalle',
			'Ballālpur',
			'Bahādurgarh',
			'Bāgha Purāna',
			'Babugarh',
			'Attingal',
			'Ateli Mandi',
			'Ambāh',
			'Chinnasekkadu',
			'Gaddi Annaram',
			'Yanamalakuduru',
			'Gangadharpur',
			'Hnahthial',
			'Anjani Budruk',
			'Ziro',
			'Wardha',
			'Vijāpur',
			'Vettaikkaranpudur',
			'Vadnagar',
			'Uniāra',
			'Thiruvananthapuram',
			'Tilhar',
			'Tarn Tāran',
			'Tanuku',
			'Tālīkota',
			'Sūratgarh',
			'Sūrandai',
			'Srikakulam',
			'Sorab',
			'Sompeta',
			'Solim',
			'Sirohi',
			'Sikka',
			'Shujālpur',
			'Shikārpūr',
			'Shāhkot',
			'Saundatti',
			'Saoner',
			'Samdari',
			'Sambhal',
			'Sabalgarh',
			'Rānīpur',
			'Rānāghāt',
			'Rāmtek',
			'Rāmpura',
			'Rāmpur',
			'Rāmgundam',
			'Rājsamand',
			'Rajpur',
			'Rājgurunagar',
			'Rāhuri',
			'Puliyūr',
			'Ponnamarāvati',
			'Perya',
			'Pawni',
			'Pathanāmthitta',
			'Panaji',
			'Pallattūr',
			'Obra',
			'Nilakottai',
			'Nellikkuppam',
			'Nautanwa',
			'Nāndūra Buzurg',
			'Nāmrup',
			'Nāmakkal',
			'Mursān',
			'Mohiuddinnagar',
			'Mihona',
			'Melur',
			'Medchal',
			'Masaurhi Buzurg',
			'Mānvi',
			'Mangan',
			'Māndvi',
			'Manapparai',
			'Makrāna',
			'Mahārāganj',
			'Madurai',
			'Lāwar Khās',
			'Latur',
			'Koothanallur',
			'Kundgol',
			'Kudachi',
			'Kothāpet',
			'Koppal',
			'Kuchinda',
			'Khunti',
			'Kheri',
			'Kayattār',
			'Kārsiyāng',
			'Karauli',
			'Kāramadai',
			'Kallakkurichchi',
			'Kālka',
			'Kālimpong',
			'Joshīmath',
			'Jangaon',
			'Jalakandapuram',
			'Hospet',
			'Hoskote',
			'Hodal',
			'Hastināpur',
			'Haridwar',
			'Hamīrpur',
			'Haldwani',
			'Gundlupēt',
			'Gudiyatham',
			'Gudivāda',
			'Ghātsīla',
			'Gharaunda',
			'Gangārāmpur',
			'Gadwāl',
			'Fatehābād',
			'Erraguntla',
			'Ellenabad',
			'Dumka',
			'Dhāriwāl',
			'Daryāpur',
			'Dankaur',
			'Chūru',
			'Chincholi',
			'Chharra',
			'Channapatna',
			'Chānda',
			'Chāndbāli',
			'Bishnupur',
			'Bilthra',
			'Bilāspur',
			'Bhachāu',
			'Bawāna',
			'Bārsi',
			'Barjala',
			'Bāpatla',
			'Banihāl',
			'Bagra',
			'Badvel',
			'Ārangaon',
			'Anekal',
			'Anamalais',
			'Ambāgarh Chauki',
			'Akhnūr',
			'Ahmadpur',
			'Ahiri',
			'Pānchla',
			'Birpara',
			'Chemmumiahpet',
			'Quthbullapur',
			'Anjani Khurd',
			'Akasahebpet',
			'Azhiyūr',
			'Alangad',
			'Ajani Khurd',
			'Waghāi',
			'Wadgaon',
			'Vuyyūru',
			'Vikravāndi',
			'Uravakonda',
			'Udalguri',
			'Todaraisingh',
			'Tindwāri',
			'Tharād',
			'Thanniyam',
			'Taloda',
			'Sukma',
			'Sonepur',
			'Silchar',
			'Sidhaulī',
			'Serula',
			'Satara',
			'Sāmalkot',
			'Salem',
			'Saidpur',
			'Rāya',
			'Rāmamangalam',
			'Rājgarh',
			'Pūranpur',
			'Pupri',
			'Pūndri',
			'Pukhrāyān',
			'Pipraich',
			'Perambalur',
			'Pathalgaon',
			'Palani',
			'Ongole',
			'North Vanlaiphai',
			'Nongstoin',
			'Napāsar',
			'Nalhāti',
			'Nakodar',
			'Nagpur',
			'Nāgercoil',
			'Nāgar Karnūl',
			'Morwa',
			'Mohgaon',
			'Marakkanam',
			'Maniar',
			'Mandapeta',
			'Mālpura',
			'Mālpur',
			'Malpe',
			'Mahārājgani',
			'Laungowāl',
			'Lālgola',
			'Lakhipur',
			'Lahār',
			'Kumhāri',
			'Kulittalai',
			'Kūdligi',
			'Krishnarājpet',
			'Koppa',
			'Kolhāpur',
			'Kishtwār',
			'Kherālu',
			'Khānpur',
			'Khadki',
			'Kattivākkam',
			'Jodhpur',
			'Jīran',
			'Jalālābād',
			'Hinjilikatu',
			'Hasanpur',
			'Gurmatkāl',
			'Gawān',
			'Fatehābād',
			'Elūr',
			'Elumalai',
			'Dīglūr',
			'Dhilwan',
			'Dharmābād',
			'Dharampur',
			'Dhār',
			'Deoraniān',
			'Deolāli',
			'Deoband',
			'Daulatpur',
			'Cuttack',
			'Coondapoor',
			'Vikāsnagar',
			'Chidawa',
			'Chhaprauli',
			'Channarāyapatna',
			'Chāndūr Bāzār',
			'Challakere',
			'Birmitrapur',
			'Biloli',
			'Bhulath Gharbi',
			'Bhābhra',
			'Berasia',
			'Belonia',
			'Basmat',
			'Barki Saria',
			'Bānāvar',
			'Bagar',
			'Babrāla',
			'Atraulia',
			'Adirampattinam',
			'Atarra',
			'Norīa',
			'Antu',
			'Akot',
			'Srirāmpur',
			'Mahiari',
			'Solap',
			'Ula',
			'Madambakkam',
			'Powai',
			'Ponnur',
			'Aistala',
			'Jhulasan',
			'Kumarapalayam',
			'Inda Chhoi',
			'Cherthala',
			'Zahirābād',
			'Ūttukkuli',
			'Titābar',
			'Tiptūr',
			'Thāna Bhawan',
			'Tekanpur',
			'Tarakeswar',
			'Tānda',
			'Soro',
			'Sirāthu',
			'Sindgi',
			'Shorāpur',
			'Shāhāpur',
			'Sāyalkudi',
			'Sāvda',
			'Sausar',
			'Sakīt',
			'Saharsa',
			'Sabāthu',
			'Rohru',
			'Rāmānuj Ganj',
			'Ramanathapuram',
			'Rāhon',
			'Rādhanpur',
			'Rabūpura',
			'Purwā',
			'Polūr',
			'Piro',
			'Pindwāra',
			'Pennādam',
			'Pawai',
			'Parshādepur',
			'Pāndoh',
			'Pāndātarai',
			'Pāli',
			'Paithan',
			'Nepānagar',
			'Nāspur',
			'Nārsingi',
			'Nārnaul',
			'Naregal',
			'Nanded',
			'Nainwa',
			'Naduvattam',
			'Mungeli',
			'Mau',
			'Malhārgarh',
			'Maheshwar',
			'Lāsalgaon',
			'Lakshmeshwar',
			'Kurnool',
			'Kundarkhi',
			'Colachel',
			'Kodoli',
			'Kiri Buru',
			'Kinwat',
			'Khamaria',
			'Kemrī',
			'Kawardha',
			'Katra',
			'Kātoya',
			'Katangi',
			'Karambakkudi',
			'Kankauli',
			'Kanigiri',
			'Kākori',
			'Kaimori',
			'Kānchrāpāra',
			'Jugsālai',
			'Jogindarnagar',
			'Jainagar',
			'Jaspur',
			'Jagtiāl',
			'Hātod',
			'Gwalior',
			'Gulāothi',
			'Gudalur',
			'Gorakhpur',
			'Ghiror',
			'Ghansor',
			'Farrukhnagar',
			'Farīdnagar',
			'Farakka',
			'Egra',
			'Dombivli',
			'Dhaurahra',
			'Dalkola',
			'Dahegām',
			'Cuddalore',
			'Chiknāyakanhalli',
			'Cherrapunji',
			'Charkhi Dādri',
			'Chandannagar',
			'Chānasma',
			'Chaklāsi',
			'Bilāsipāra',
			'Bijapur',
			'Bhuban',
			'Bhor',
			'Bhojudih',
			'Bhiwāni',
			'Belūr',
			'Belsand',
			'Begusarai',
			'Baswa',
			'Basi',
			'Barpeta',
			'Barnāla',
			'Barjora',
			'Bariārpur',
			'Bānsgāon',
			'Bānka',
			'Banjār',
			'Balāngīr',
			'Baisi',
			'Byndoor',
			'Ausa',
			'Ashoknagar',
			'Annāmalainagar',
			'Amroli',
			'Āmlāgora',
			'Amethi',
			'Alīpur Duār',
			'Ādilābād',
			'Bālāpur',
			'Chandūr',
			'Jorethang',
			'Malkajgiri',
			'Palwancha',
			'Bhawanipur',
			'Kunnumma',
			'Tengnoupal',
			'Balrampur',
			'Kallakurichi',
			'S. Rayavaram',
			'Yamunānagar',
			'Wani',
			'Walajapet',
			'Velankanni',
			'Unhel',
			'Umred',
			'Umarkhed',
			'Ujhāni',
			'Tuni',
			'Tīra Sujānpur',
			'Thakurdwara',
			'Talwandi Bhai',
			'Sulya',
			'Srivaikuntam',
			'Soygaon',
			'Rajpur Sonarpur',
			'Solan',
			'Siwāna',
			'Sirsi',
			'Sirsa',
			'Singtam',
			'Singoli',
			'Shīshgarh',
			'Shirhatti',
			'Sherghāti',
			'Shāhpura',
			'Sāsvad',
			'Sangariā',
			'Sachīn',
			'Ratnagiri',
			'Ratanpur',
			'Rangia',
			'Rājahmundry',
			'Pusad',
			'Ponnāni',
			'Pithorāgarh',
			'Patti',
			'Patna',
			'Pāthardi',
			'Pāpireddippatti',
			'Palakkad',
			'Ozar',
			'Nongpoh',
			'Nīmāj',
			'Nawābganj',
			'Nawābganj',
			'Nargund',
			'Narasimharājapura',
			'Narasaraopet',
			'Nāndgaon',
			'Naduvannūr',
			'Munnar',
			'Mulbāgal',
			'Mukher',
			'Mauganj',
			'Māndalgarh',
			'Mādhogarh',
			'Lunglei',
			'Limbdi',
			'Lāla',
			'Khurda',
			'Khetia',
			'Kharela',
			'Khāpa',
			'Karamsad',
			'Kanpur',
			'Kannod',
			'Kampil',
			'Kālāgarh Project Colony',
			'Jodiya Bandar',
			'Jintūr',
			'Jhālu',
			'Jhālāwār',
			'Jāwad',
			'Jandiāla Gurū',
			'Jammalamadugu',
			'Jambusar',
			'Jalpāiguri',
			'Jākhal',
			'Jagatsinghapur',
			'Islāmpur',
			'Indore',
			'Hugli',
			'Hirekerūr',
			'Hālīsahar',
			'Guntakal Junction',
			'Goyerkāta',
			'Gopālganj',
			'Ghoga',
			'Ghātampur',
			'Garautha',
			'Ganj Murādābād',
			'Gajraula',
			'Fatehganj West',
			'Erandol',
			'Dumra',
			'Dirba',
			'Dhārūr',
			'Devanhalli',
			'Deshnoke',
			'Deoli',
			'Curchorem',
			'Chikodi',
			'Chātakonda',
			'Chamrajnagar',
			'Bomdila',
			'Bolpur',
			'Bisenda Buzurg',
			'Bilgrām',
			'Bhūm',
			'Bhanjanagar',
			'Barāgaon',
			'Bālāghāt',
			'Bairāgnia',
			'Baikunthpur',
			'Badhni Kalān',
			'Atraulī',
			'Ajnāla',
			'Abhaneri',
			'Kirandul',
			'Mushalpur',
			'Khliehriat',
			'Pedda bhogili',
			'Chetlat',
			'Yavatmāl',
			'Wānkāner',
			'Udumalaippettai',
			'Tiruvannāmalai',
			'Teonthar',
			'Tharangambadi',
			'Swāmimalai',
			'Sonīpat',
			'Sivagiri',
			'Sibsāgar',
			'Shergarh',
			'Sāsni',
			'Rudraprayāg',
			'Rūdarpur',
			'Rāzole',
			'Rāwatbhāta',
			'Rānipet',
			'Rānikhet',
			'Rājgīr',
			'Rāīwāla Bara',
			'Raipur',
			'Periyakulam',
			'Pen',
			'Pātan',
			'Parola',
			'Paravūr Tekkumbhāgam',
			'Pallevāda',
			'Padampur',
			'Nipāni',
			'Nedumangād',
			'Narsinghgarh',
			'Naīgarhi',
			'Negapatam',
			'Morādābād',
			'Mohanpur',
			'Māpuca',
			'Manoharpur',
			'Mankāpur',
			'Manjeri',
			'Mānikpur',
			'Lātehār',
			'Lar',
			'Lādnūn',
			'Kurud',
			'Kurinjippādi',
			'Kosi',
			'Kosamba',
			'Korba',
			'Kombai',
			'Koāth',
			'Kharsia',
			'Khalīlābād',
			'Keshod',
			'Kamuthi',
			'Kalānaur',
			'Kadambūr',
			'Junnar',
			'Jhumri Telaiya',
			'Iklehra',
			'Hungund',
			'Harbatpur',
			'Harihar',
			'Hāpur',
			'Hansot',
			'Hājan',
			'Gursarāi',
			'Gobindpur',
			'Gaya',
			'Gauripur',
			'Gādarwāra',
			'Forbesganj',
			'Fatehpur Sīkri',
			'Farīdkot',
			'Dungarpur',
			'Dhorāji',
			'Dhanaura',
			'Dhamtari',
			'Dehu',
			'Debīpur',
			'Dārwha',
			'Dagshai',
			'Dādri',
			'Chittoor',
			'Chillupār',
			'Chhindwāra',
			'Chandia',
			'Chakrāta',
			'Bulandshahr',
			'Bithūr',
			'Bisauli',
			'Bihārīganj',
			'Bhīnmāl',
			'Bharwāri',
			'Bhainsdehi',
			'Barkhera Kalān',
			'Bari Sādri',
			'Bārh',
			'Bārān',
			'Bengaluru',
			'Bamna',
			'Bail-Hongal',
			'Bahraigh',
			'Auraiya',
			'Āsika',
			'Arukutti',
			'Arāria',
			'Annigeri',
			'Amreli',
			'Ammāpettai',
			'Amānganj',
			'Alībāg',
			'Krishnapur',
			'Shivaji Nagar',
			'Avanoor',
			'Shiraguppi',
			'Garha Brahman',
			'Shahdara',
			'Shi Yomi',
			'Mulugu',
			'Yeola',
			'Vizianagaram',
			'Vadakku Viravanallur',
			'Vijayawada',
			'Valabhīpur',
			'Uttarkāshi',
			'Uppal Kalan',
			'Umri',
			'Todabhim',
			'Tīkri',
			'Thiruvaiyaru',
			'Thāsra',
			'Terdāl',
			'Teni',
			'Tāsgaon',
			'Talni',
			'Sultānpur',
			'Sivaganga',
			'Sītāmarhi',
			'Siruguppa',
			'Singapperumālkovil',
			'Sholinghur',
			'Shāhpur',
			'Seorīnārāyan',
			'Sanāwad',
			'Riāsi',
			'Raybag',
			'Polavaram',
			'Peranamallūr',
			'Pattukkottai',
			'Pāthardih',
			'Parli Vaijnāth',
			'Pārīchha',
			'Nawanshahr',
			'Narela',
			'Narasingāpuram',
			'Naliya',
			'Naihāti',
			'Nādaun',
			'Mundargi',
			'Morānha',
			'Mīrānpur Katra',
			'Mandsaur',
			'Mandi',
			'Mahābaleshwar',
			'Chennai',
			'Madhyamgram',
			'Lohārdagā',
			'Lālpur',
			'Kūmher',
			'Khurai',
			'Khamharia',
			'Kathua',
			'Katangi',
			'Kāranja',
			'Kāpren',
			'Kalakkādu',
			'Kadaura',
			'Jorhāt',
			'Jhunjhunūn',
			'Jhīnjhak',
			'Jhānsi',
			'Jasdan',
			'Jarwal',
			'Indāpur',
			'Hārij',
			'Hājīpur',
			'Goa Velha',
			'Gaurela',
			'Gariadhar',
			'Gangolli',
			'Gangavalli',
			'Gandevi',
			'Durg',
			'Dhrol',
			'Dhone',
			'Dhāna',
			'Datia',
			'Darsi',
			'Daitari',
			'Dāhānu',
			'Dabhoi',
			'Chhoti Sādri',
			'Chengam',
			'Chandrakona',
			'Burhar',
			'Budhlāda',
			'Bodhan',
			'Kīl Bhuvanagiri',
			'Bhitarwār',
			'Bhatgaon',
			'Bhaisa',
			'Beldānga',
			'Bayāna',
			'Baud',
			'Bāsoda',
			'Bargi',
			'Bannūr',
			'Babīna',
			'Baberu',
			'Ashta',
			'Āsandh',
			'Annavāsal',
			'Anjār',
			'Anjangaon',
			'Ambāla',
			'Akbarpur',
			'Akaltara',
			'Akalkot',
			'Ahmadnagar',
			'Haldia',
			'Dhulagari',
			'Gopālpur',
			'Singāpur',
			'Periyapattinam',
			'Sathupalli',
			'Naharlagun',
			'Sarupathar',
			'Lumding Railway Colony',
			'pedda nakkalapalem',
			'Nangilickondan',
			'V.S.K.Valasai (Dindigul-Dist.)',
			'Nagarukhra City',
			'Amarāvati',
			'Yellāpur',
			'Wazīrganj',
			'Virudunagar',
			'Utrān',
			'Udhampur',
			'Turuvekere',
			'Tumsar',
			'Tohāna',
			'Tikaitnagar',
			'Thenzawl',
			'Tezpur',
			'Tehri',
			'Tanakpur',
			'Srinagar',
			'Sivagiri',
			'Shōranūr',
			'Shirdi',
			'Sheohar',
			'Shāmli',
			'Shāhpur',
			'Sandūr',
			'Saidpur',
			'Sāhibganj',
			'Ron',
			'Rājula',
			'Phirangipuram',
			'Phagwāra',
			'Pernem',
			'Peravurani',
			'Penukonda',
			'Peddapalli',
			'Pātūr',
			'Palmaner',
			'Pallikondai',
			'Pālitāna',
			'Pālakkodu',
			'Nīlēshwar',
			'Nasrullāhganj',
			'Narwar',
			'Nangavalli',
			'Nambiyūr',
			'Mūlki',
			'Moirāng',
			'Mohpa',
			'Mayāng Imphāl',
			'Mārkāpur',
			'Mariāni',
			'Māngrol',
			'Manāli',
			'Mahwah',
			'Madanpur',
			'Māchalpur',
			'Lohogaon',
			'Leh',
			'Lakhnādon',
			'Kushtagi',
			'Kulattūr',
			'Kovvūr',
			'Kodaikānāl',
			'Kiraoli',
			'Khonsa',
			'Khilchipur',
			'Khed',
			'Kharod',
			'Kharagpur',
			'Khair',
			'Karīmpur',
			'Kantābānji',
			'Cumbum',
			'Kāgal',
			'Kadiri',
			'Jutogh',
			'Jāmai',
			'Harrai',
			'Hājo',
			'Guna',
			'Gummidipundi',
			'Govardhan',
			'Gorantla',
			'Gopāmau',
			'Ghoti Budrukh',
			'Faizpur',
			'Eraniel',
			'Durgapur',
			'Diu',
			'Dīnānagar',
			'Dhemāji',
			'Dharampuri',
			'Colgong',
			'Chinchinim',
			'Chicalim',
			'Chāndil',
			'Chāībāsa',
			'Bodri',
			'Bhānder',
			'Bāsugaon',
			'Barkā Kānā',
			'Bāri',
			'Bargarh',
			'Banga',
			'Balasore',
			'Bakāni',
			'Baikunthpur',
			'Arambol',
			'Arakkonam',
			'Āmli',
			'Alīganj',
			'Akivīdu',
			'Perungudi',
			'Mohali',
			'Perumbavoor',
			'Vapi',
			'Edakkulam',
			'Vagator',
			'Kanuru',
			'Gajuwaka',
			'Silapathar',
			'Defence Colony',
			'Pakke Kessang',
			'Wādi',
			'Visakhapatnam',
			'Valpoy',
			'Valavanur',
			'Vādippatti',
			'Tuljāpur',
			'Tuensang',
			'Tijāra',
			'Surgāna',
			'Sorada',
			'Sodpur',
			'Sirumugai',
			'Shankargarh',
			'Shāhpura',
			'Sāyla',
			'Saurikh',
			'Sangamner',
			'Sakleshpur',
			'Rohtak',
			'Revadanda',
			'Raurkela',
			'Rasipuram',
			'Rāmnagar',
			'Rāmban',
			'Rāzampeta',
			'Pushkar',
			'Phillaur',
			'Phek',
			'Penugonda',
			'Patti',
			'Pātrasāer',
			'Patnāgarh',
			'Pāndhurnā',
			'Panāgar',
			'Pālkonda',
			'Odugattūr',
			'Nawābganj',
			'Nāngloi Jāt',
			'Nandurbar',
			'Muluppilagadu',
			'Mothīhāri',
			'Mon',
			'Mīrānpur',
			'Mhasla',
			'Majalgaon',
			'Mangaldai',
			'Mamit',
			'Malkāpur',
			'Malakanagiri',
			'Māler Kotla',
			'Mahbūbnagar',
			'Mahbūbābād',
			'Lāharpur',
			'Kodlipet',
			'Kīranūr',
			'Khurja',
			'Khekra',
			'Khardah',
			'Khailār',
			'Kesinga',
			'Kariapatti',
			'Kānt',
			'Kāndri',
			'Kānkānhalli',
			'Kākināda',
			'Kadayanallur',
			'Jua',
			'Jalārpet',
			'Jogbani',
			'Jobat',
			'Jawhār',
			'Jāmuria',
			'Jais',
			'Indergarh',
			'Harpālpur',
			'Goālpāra',
			'Gīrīdīh',
			'Gautampura',
			'Garhwa',
			'Gangānagar',
			'Gadhada',
			'Fatwa',
			'Dongargarh',
			'Dighwāra',
			'Dhanbad',
			'Dergaon',
			'Dalmau',
			'Chuāri Khās',
			'Chitradurga',
			'Chirgaon',
			'Chetwayi',
			'Chāpar',
			'Chākan',
			'Burhānpur',
			'Bokāro',
			'Bhudgaon',
			'Bhandāra',
			'Bhadrāvati',
			'Bhadrakh',
			'Bhadohi',
			'Bewar',
			'Basna',
			'Baranagar',
			'Bamora',
			'Bāli Chak',
			'Asarganj',
			'Anantnag',
			'Amod',
			'Amla',
			'Amarnāth',
			'Alandi',
			'Parbatipur',
			'Pujali',
			'Baddi',
			'Bhiwadi',
			'Shahuwadi',
			'Jaoli',
			'Yanam',
			'Wanparti',
			'Vidisha',
			'Vemalwāda',
			'Vallam',
			'Vadodara',
			'Vadakku Valliyūr',
			'Thanna Mandi',
			'Sūlūru',
			'Sītāmau',
			'Sirsilla',
			'Shirwal',
			'Shāhābād',
			'Serchhīp',
			'Sāngli',
			'Renukūt',
			'Rānībennur',
			'Rāmnagar',
			'Rājgarh',
			'Rajaori',
			'Quepem',
			'Pūnāhāna',
			'Proddatūr',
			'Pippara',
			'Phūlpur',
			'Phaphūnd',
			'Petlād',
			'Pattan',
			'French Rocks',
			'Pālamedu',
			'Pālakollu',
			'Odlābāri',
			'Nawalgarh',
			'Nawāda',
			'Nāwa',
			'Nāsriganj',
			'Narendranagar',
			'Musāfir-Khāna',
			'Muhammadābād',
			'Mirzāpur',
			'Melukote',
			'Manor',
			'Seven Pagodas',
			'Majholi',
			'Mairang',
			'Maibong',
			'Mahudha',
			'Loni',
			'Lakhtar',
			'Kuchāman',
			'Krishnagiri',
			'Kovalam',
			'Kota',
			'Konārka',
			'Kolār',
			'Khaur',
			'Kharsāwān',
			'Khargupur',
			'Khargāpur',
			'Kharakvasla',
			'Kāveripatnam',
			'Kāttupputtūr',
			'Kānodar',
			'Kāndhla',
			'Kanadukattan',
			'Kalavai',
			'Kālāvad',
			'Kālānwāli',
			'Jubbal',
			'Jobner',
			'Jhā-Jhā',
			'Jaynagar Majilpur',
			'Jamnagar',
			'Itānagar',
			'Ghātāl',
			'Guwahati',
			'Ganguvāda',
			'Gadag',
			'Dugda',
			'Dudhani',
			'Dhāruhera',
			'Derā Nānak',
			'Deoghar',
			'Dākor',
			'Dābhol',
			'Chittaurgarh',
			'Chittaranjan',
			'Chāndpur',
			'Chanderi',
			'Chāmpua',
			'Kannur',
			'Bolānīkhodān',
			'Birbhaddar',
			'Bīlāspur',
			'Bhusāval',
			'Bhasāwar',
			'Bhānvad',
			'Begūn',
			'Bānda',
			'Baloda',
			'Bāli',
			'Bagalkot',
			'Badnāwar',
			'Bābra',
			'Avanigadda',
			'Aurās',
			'Arni',
			'Anantapur',
			'Prayagraj',
			'Alīgarh',
			'Ajaigarh',
			'Afzalpur',
			'Bankra',
			'Chhutmalpur',
			'Kupwāra',
			'Manuguru',
			'Ramanayyapeta',
			'Zira',
			'Thoothukudi',
			'Injambakkam',
			'Naigaon Dattapur',
			'Wadgaon Sarhad',
			'Lalganj',
			'Visnagar',
			'Vettavalam',
			'Varanasi',
			'Valangaiman',
			'Uri',
			'Uran',
			'Turaiyūr',
			'Tura',
			'Thrissur',
			'Titāgarh',
			'Thoubāl',
			'Tawang',
			'Tamlūk',
			'Tālgrām',
			'Supaul',
			'Sūjāngarh',
			'Srīnagar',
			'Sri Mādhopur',
			'Sonāmukhi',
			'Sojat',
			'Sirpur',
			'Sikandra',
			'Siddāpur',
			'Shāhgarh',
			'Sāruberā',
			'Sardhana',
			'Sarai Ekdil',
			'Sapatgrām',
			'Sānchi',
			'Sāmbhar',
			'Rusera',
			'Rāyachoti',
			'Rāth',
			'Ratangarh',
			'Rānāpur',
			'Rāmpura',
			'Rāmpur',
			'Rāmnagar',
			'Rājūra',
			'Rājpura',
			'Rājgarh',
			'Rājāpur',
			'Puttūr',
			'Puducherry',
			'Pokaran',
			'Pīpār',
			'Perumpāvūr',
			'Payyannūr',
			'Pataudi',
			'Pasān',
			'Pārvatipuram',
			'Panamaram',
			'Omalur',
			'North Lakhimpur',
			'Nattam',
			'Nārnaund',
			'Nandgaon',
			'Nagda',
			'Muttupet',
			'Morinda',
			'Mundi',
			'Muhammadābād',
			'Mudkhed',
			'Mokameh',
			'Marayur',
			'Manipal',
			'Mairwa',
			'Mahendragarh',
			'Mādhoganj',
			'Kutiatodu',
			'Kurgunta',
			'Kudāl',
			'Kottapalli',
			'Kopāganj',
			'Karrāpur',
			'Karjat',
			'Kannad',
			'Kālundri',
			'Jatāra',
			'Islāmpur',
			'Ingrāj Bāzār',
			'Husainābād',
			'Handiā',
			'Gyalshing',
			'Kalaburagi',
			'Godhra',
			'Godda',
			'Garhshankar',
			'Gandhinagar',
			'Galiākot',
			'Dīnhāta',
			'Digapahandi',
			'Dhrāngadhra',
			'Deori',
			'Calangute',
			'Chīrāla',
			'Chiplūn',
			'Chēlakara',
			'Budhāna',
			'Bhopāl',
			'Bhinga',
			'Bela',
			'Basni',
			'Bāsār',
			'Bārākpur',
			'Bāli',
			'Bakhtiyārpur',
			'Arsikere',
			'Annur',
			'Anjad',
			'Andol',
			'Amguri',
			'Amet',
			'Akodia',
			'Aklera',
			'Agra',
			'vadlamuru',
			'Parwanoo',
			'Paikpara',
			'Pangin',
			'Andrott',
			'Kanayannur',
			'Vyāra',
			'Vīsāvadar',
			'Varkala',
			'Vaniyambadi',
			'Umreth',
			'Ukwā',
			'Udayagiri',
			'Thiruvidaimaruthur',
			'Tiruvallur',
			'Tirupparangunram',
			'Tīkamgarh',
			'Teghra',
			'Susner',
			'Soalkuchi',
			'Sivakasi',
			'Sijua',
			'Shikohābād',
			'Seram',
			'Satna',
			'Sarauli',
			'Sambalpur',
			'Sāmba',
			'Salāya',
			'Sāgar',
			'Sādri',
			'Roorkee',
			'Roha',
			'Richha',
			'Rengāli',
			'Rānīr Bāzār',
			'Ranchi',
			'Rāiganj',
			'Raebareli',
			'Kasba',
			'Pūrna',
			'Porbandar',
			'Ponneri',
			'Pehowa',
			'Patiāli',
			'Pāppinisshēri',
			'Pandharpur',
			'Pallippatti',
			'Pallipattu',
			'Pālampur',
			'Pakur',
			'Ottappālam',
			'Numāligarh',
			'Nizāmābād',
			'Nichlaul',
			'Neyyāttinkara',
			'Nāyudupet',
			'Navadwīp',
			'Nannilam',
			'Nanguneri',
			'Naini Tāl',
			'Mustafābād',
			'Murtajāpur',
			'Morsi',
			'Mokokchūng',
			'Mīnjūr',
			'Mehndāwal',
			'Mau',
			'Mānwat',
			'Mānsa',
			'Manjēshvar',
			'Mandlā',
			'Manalūrpettai',
			'Maināguri',
			'Mahemdāvād',
			'Maghar',
			'Madgaon',
			'Lādwa',
			'Kuttampuzha',
			'Kot Īsa Khān',
			'Korukollu',
			'Kishangarh',
			'Kīranūr',
			'Kharkhauda',
			'Khanna',
			'Khambhāliya',
			'Khairāgarh',
			'Karnāl',
			'Kānker',
			'Kamalganj',
			'Kallupatti',
			'Kaintragarh',
			'Jalandhar',
			'Jashpurnagar',
			'Jaisalmer',
			'Jagraon',
			'Itārsi',
			'Iringal',
			'Indi',
			'Idappadi',
			'Hyderābād',
			'Hadagalli',
			'Guskhara',
			'Gondal',
			'Gobārdānga',
			'Ghogha',
			'Elamanchili',
			'Dum Duma',
			'Dornakal',
			'Digras',
			'Digboi',
			'Dhenkānāl',
			'Devarkonda',
			'Deori Khās',
			'Dayāl Bāgh',
			'Cholapuram',
			'Chakradharpur',
			'Bīsalpur',
			'Bilsanda',
			'Bhuma',
			'Bhayandar',
			'Bhattiprolu',
			'Basi',
			'Bārmer',
			'Bareilly',
			'Banda',
			'Balod',
			'Balimila',
			'Baldev',
			'Baidyabāti',
			'Bahsūma',
			'Baharampur',
			'Āvadi',
			'Aurād',
			'Arantāngi',
			'Anūppur',
			'Ambikāpur',
			'Alwaye',
			'Alot',
			'Ādoni',
			'Addanki',
			'Raghudebbati',
			'Auroville',
			'Kotkapura',
			'Mandideep',
			'Kyathampalle',
			'Cherpulassery',
			'Phusro',
			'Jiribam',
			'Koonimedu',
			'Amaravati',
			'Pimpalkhuta',
			'Chirmiri',
			'Yelbarga',
			'Yelahanka',
			'Varangaon',
			'Usilampatti',
			'Una',
			'Ujjain',
			'Bara Uchāna',
			'Tufānganj',
			'Titlāgarh',
			'Tinnanūr',
			'Tāttayyangārpettai',
			'Tāndā',
			'Talen',
			'Sūrajgarh',
			'Sini',
			'Shāhpur',
			'Shāhābād',
			'Sarka Ghāt',
			'Sarīla',
			'Sarāi Ākil',
			'Sankheda',
			'Saligao',
			'Sadashivpet',
			'Rehli',
			'Rāmāpuram',
			'Rāmāpuram',
			'Rājkot',
			'Rāipur',
			'Pune',
			'Pudūr',
			'Port Blair',
			'Patharia',
			'Parvatsar',
			'Ponmana',
			'Pānihāti',
			'Nīmbāhera',
			'Nārāyangarh',
			'Naraini',
			'Nandyāl',
			'Naksalbāri',
			'Nagrām',
			'Nagari',
			'Nadiād',
			'Murgūd',
			'Mugma',
			'Mudhol',
			'Milak',
			'Mettupalayam',
			'Mangalagiri',
			'Manavālakurichi',
			'Mainpuri',
			'Maham',
			'Ludhiāna',
			'Losal',
			'Lalitpur',
			'Kutiyāna',
			'Kurandvād',
			'Kovūr',
			'Koratagere',
			'Kishni',
			'Kheda',
			'Khed',
			'Kenda',
			'Kāyankulam',
			'Kātol',
			'Karād',
			'Kānth',
			'Kānnangād',
			'Kāmalāpuram',
			'Kālīnagar',
			'Kālikāpur',
			'Kailāras',
			'Kabrāi',
			'Jogīghopa',
			'Jhinjhāna',
			'Jasrāsar',
			'Jasrāna',
			'Jamshedpur',
			'Jalalpore',
			'Jalālābād',
			'Haraiya',
			'Gīdam',
			'Garhdiwāla',
			'Gangāpur',
			'Farīdpur',
			'Fālākāta',
			'Dūdhi',
			'Dhuwaran',
			'Dhāmnod',
			'Denkanikota',
			'Curti',
			'Chhota Udepur',
			'Chāpra',
			'Bissāu',
			'Bikramganj',
			'Bhiwandi',
			'Bhadrāchalam',
			'Bhādāsar',
			'Beypore',
			'Belaguntha',
			'Begamganj',
			'Bānapur',
			'Bāg',
			'Badūria',
			'Ārvi',
			'Angul',
			'Angamāli',
			'Ambahta',
			'Amalāpuram',
			'Ābu',
			'Sankarpur',
			'Monoharpur',
			'Manappakkam',
			'Chowari',
			'Palackattumala',
			'Williamnagar',
			'Dasarahalli',
			'Namsai',
			'Kiltan',
			'Yellandu',
			'Wer',
			'Vite',
			'Vāsudevanallūr',
			'Vasa',
			'Udangudi',
			'Titron',
			'Thākurganj',
			'Tāndūr',
			'Tāki',
			'Surat',
			'Sundargarh',
			'Someshwar',
			'Sirmaur',
			'Sidhi',
			'Shrīgonda',
			'Seoni',
			'Seohāra',
			'Satrikh',
			'Sarwār',
			'Sarāhan',
			'Sandīla',
			'Salon',
			'Sailāna',
			'Ratangarh',
			'Rāmnagar',
			'Rajapalaiyam',
			'Phaltan',
			'Perundurai',
			'Pātan',
			'Parbhani',
			'Panhāla',
			'Oran',
			'Olpād',
			'Nokha',
			'Nohar',
			'Nellore',
			'Nāravārikuppam',
			'Naraina',
			'Nahorkatiya',
			'Murwāra',
			'Mundgod',
			'Mulgund',
			'Muktsar',
			'Mughal Sarāi',
			'Morjim',
			'Mettuppālaiyam',
			'Memāri',
			'Meghraj',
			'Medinīpur',
			'Mavoor',
			'Mormugao',
			'Madikeri',
			'Lohāru',
			'Lālganj',
			'Laksar',
			'Kuttālam',
			'Kumsi',
			'Krishnanagar',
			'Kottagūdem',
			'Kodār',
			'Khujner',
			'Khatīma',
			'Khambhāt',
			'Khallikot',
			'Kāti',
			'Karwar',
			'Kalyani',
			'Kālādhūngi',
			'Jodhpur',
			'Jhajjar',
			'Jānjgīr',
			'Jālaun',
			'Jalālpur',
			'Jahānābād',
			'Hosdurga',
			'Hirānagar',
			'Heggadadevankote',
			'Harūr',
			'Haldībāri',
			'Gubbi',
			'Govindgarh',
			'Goshāīnganj',
			'Gopināthpur',
			'Gobichettipalayam',
			'Farrukhnagar',
			'Dwārāhāt',
			'Dīsa',
			'Diphu',
			'Dholka',
			'Dharangaon',
			'Dhanera',
			'Devgadh Bāriya',
			'Dātāganj',
			'Dabra',
			'Colva',
			'Chik Ballāpur',
			'Chhachhrauli',
			'Chāndūr',
			'Chanduasi',
			'Candolim',
			'Buxar',
			'Bīkaner',
			'Bhawānīgarh',
			'Bhadaur',
			'Betma',
			'Baruipur',
			'Bānsbāria',
			'Baloda Bāzār',
			'Bālāchor',
			'Bakshwāho',
			'Bāgli',
			'Bādāmi',
			'Arki',
			'Aruvankad',
			'Ammāpettai',
			'Aldona',
			'Ālappākkam',
			'Alangāyam',
			'Ālampur',
			'Puttaparthi',
			'Panchkula',
			'Pavuluru',
			'Kumbalam',
			'Hamren',
			'Madhavkampa',
			'Wārāseonī',
			'Vaijāpur',
			'Umaria',
			'Udaipura',
			'Tisaiyanvilai',
			'Tirupati',
			'Tirumullaivāsal',
			'Tinsukia',
			'Takhatpur',
			'Sunel',
			'Sirūr',
			'Singānallūr',
			'Sidlaghatta',
			'Srāvana Belgola',
			'Shivrājpur',
			'Shamsābād',
			'Shāhpura',
			'Shāhpur',
			'Sathyamangalam',
			'Sārangarh',
			'Sānwer',
			'Sangod',
			'Saiha',
			'Rāy',
			'Rambha',
			'Rāja Sānsi',
			'Rājāpur',
			'Rabkavi',
			'Puduvāyal',
			'Peddāpuram',
			'Pathānkot',
			'Papanasam',
			'Paliā Kalān',
			'Nayāgarh',
			'Naya Bāzār',
			'Nawānshahr',
			'Narauli',
			'Nagrota',
			'Nagīna',
			'Mūndwa',
			'Mātherān',
			'Mannārakkāt',
			'Mancherāl',
			'Manamadurai',
			'Maihar',
			'Mahālingpur',
			'Māgadi',
			'Lūnāvāda',
			'Luckeesarai',
			'Londa',
			'Lonar',
			'Lodhīkheda',
			'Lāthi',
			'Kushālnagar',
			'Kulgam',
			'Kotla',
			'Koratla',
			'Kohīma',
			'Kodarmā',
			'Kithor',
			'Khetri',
			'Khānāpur',
			'Khagaul',
			'Kavaratti',
			'Karārī',
			'Kānke',
			'Kāndla',
			'Kampli',
			'Kalugumalai',
			'Kallidaikurichi',
			'Kadūr',
			'Kadi',
			'Jhābua',
			'Jahāngīrābād',
			'Jahānābād',
			'Jaggayyapeta',
			'Jabalpur',
			'Indri',
			'Ichhāwar',
			'Hoshiārpur',
			'Harnai',
			'Hāflong',
			'Gola Gokarannāth',
			'Gharghoda',
			'Gevrai',
			'Gangtok',
			'Gandai',
			'Fatehpur',
			'Fatehpur',
			'Diguvametta',
			'Dhaulpur',
			'Daudnagar',
			'Cortalim',
			'Chītāpur',
			'Chikhli',
			'Chennimalai',
			'Chās',
			'Chālisgaon',
			'Carapur',
			'Buddh Gaya',
			'Bishnāh',
			'Bilimora',
			'Bidhūna',
			'Bharthana',
			'Basavakalyān',
			'Barwāni',
			'Barauli',
			'Bānswāda',
			'Bāmor Kalān',
			'Bamboo Flat',
			'Badarpur',
			'Āron',
			'Anūpshahr',
			'Ankola',
			'Anand',
			'Ambattūr',
			'Contai',
			'Sāhāpur',
			'Barbil',
			'Gadag-Betageri',
			'Kalpeni',
			'Vilattikulam',
			'Vellore',
			'Vayalār',
			'Unjha',
			'Udpura',
			'Udgīr',
			'Udaipur',
			'Tori-Fatehpur',
			'Tiruchengode',
			'Tekkalakote',
			'Tāl',
			'Sundarnagar',
			'Srīsailain',
			'Singarāyakonda',
			'Shivpuri',
			'Sāvantvādi',
			'Sārangpur',
			'Sahāranpur',
			'Remuna',
			'Rāmnagar',
			'Rājmahal',
			'Rājgarh',
			'Rāichūr',
			'Rādhākund',
			'Phulera',
			'Partūr',
			'Partāpur',
			'Parlākimidi',
			'Pāppārappatti',
			'Pānchgani',
			'Pallāvaram',
			'Pahāsu',
			'Nūrpur Kalān',
			'Nirsā',
			'Neem ka Thana',
			'Nelamangala',
			'Niwai',
			'Nāthdwāra',
			'Narāyangarh',
			'Nandikotkūr',
			'Mysore',
			'Monghyr',
			'Mukeriān',
			'Morena',
			'Mayiladuthurai',
			'Mātābhānga',
			'Marhaura',
			'Maksi',
			'Mahmudābād',
			'Mahgawān',
			'Bishunpur Urf Mahārājganj',
			'Mahāban',
			'Lālam',
			'Lakhnā',
			'Kyelang',
			'Kurāra',
			'Kotma',
			'Kotār',
			'Kotagiri',
			'Konnagar',
			'Kondalwādi',
			'Kichha',
			'Khedbrahma',
			'Khātra',
			'Kharagpur',
			'Kayalpattinam',
			'Kawānt',
			'Karīmganj',
			'Kāraikāl',
			'Kaikalūr',
			'Jūnāgarh',
			'Jevargi',
			'Iluppūr',
			'Ilampillai',
			'Hinganghāt',
			'Hayuliang',
			'Harsūd',
			'Hailākāndi',
			'Gurh',
			'Goshaingaon',
			'Gohpur',
			'Gobindpur',
			'Ghanaur',
			'Gariāband',
			'Gangaikondān',
			'Erumaippatti',
			'Dindigul',
			'Dhanwār',
			'Dhāka',
			'Dandeli',
			'Coimbatore',
			'Chiria',
			'Chīpurupalle',
			'Chikmagalūr',
			'Chhabra',
			'Channagiri',
			'Chandīgarh',
			'Chāmpa',
			'Chākuliā',
			'Chākia',
			'Byādgi',
			'Būriya',
			'Buldāna',
			'Bilkha',
			'Bhuj',
			'Bhongīr',
			'Bhāyāvadar',
			'Betūl Bazār',
			'Beswān',
			'Barghāt',
			'Bar Bigha',
			'Baraut',
			'Banūr',
			'Bājna',
			'Bābai',
			'Attur',
			'Arimalam',
			'Anandpur',
			'Amritsar',
			'Amloh',
			'Ambur',
			'Ambasamudram',
			'Along',
			'Alangānallūr',
			'Aland',
			'Akola',
			'Abirāmam',
			'Ghatkesar',
			'Adampur',
			'Madipakkam',
			'Nandambakkam',
			'Aroor',
			'Longleng',
			'Chandel',
			'Bishramganj',
			'Harina Pashdal Bar',
			'Reddivaripalle',
			'Agatti',
			'Haveli',
			'Thang',
			'Wāngjing',
			'Wai',
			'Vīraganūr',
			'Vasco da Gama',
			'Udupi',
			'Tirodi',
			'Tindivanam',
			'Tellicherry',
			'Tālcher',
			'Talāja',
			'Surajpur',
			'Suntikoppa',
			'Songadh',
			'Sonāmura',
			'Sojītra',
			'Singur',
			'Shrīrangapattana',
			'Sheopur',
			'Sardārshahr',
			'Santokhgarh',
			'Sānchor',
			'Sādāt',
			'Rewāri',
			'Reoti',
			'Ratia',
			'Rāni',
			'Rajpur',
			'Rājnagar',
			'Rājgarh',
			'Raipur',
			'Purushottampur',
			'Ponnūru',
			'Piriyāpatna',
			'Pennāthur',
			'Palladam',
			'Palera',
			'Padmanābhapuram',
			'North Guwāhāti',
			'Neral',
			'Nānpāra',
			'Nālāgarh',
			'Nāhan',
			'Nāgod',
			'Nadīgaon',
			'Nādāpuram',
			'Murud',
			'Murbād',
			'Mūdbidri',
			'Mohān',
			'Mehkar',
			'Māyakonda',
			'Mansar',
			'Manohar Thāna',
			'Mandya',
			'Māndal',
			'Mālegaon',
			'Mahroni',
			'Kukshi',
			'Kotra',
			'Kopargaon',
			'Konganāpuram',
			'Konch',
			'Koch Bihār',
			'Kishanganj',
			'Kasauli',
			'Kakrāla',
			'Kachhwa',
			'Jhalidā',
			'Jetpur',
			'Jejūri',
			'Jaorā',
			'Jālna',
			'Jaleshwar',
			'Ilkal',
			'Hunsūr',
			'Hindoria',
			'Hāngal',
			'Hadgāon',
			'Gumia',
			'Guirim',
			'Gudibanda',
			'Ghumārwīn',
			'George Town',
			'Elāyirampannai',
			'Ganj Dundwāra',
			'Dubrājpur',
			'Dhūri',
			'Dhuliān',
			'Dhola',
			'Dhandhuka',
			'Dhāmpur',
			'Dehra Dūn',
			'Dāmnagar',
			'Damān',
			'Daboh',
			'Chittūr',
			'Chinnamanūr',
			'Chhala',
			'Cheyyur',
			'Chettipālaiyam',
			'Chaksu',
			'Chāndur',
			'Chamba',
			'Bhubaneshwar',
			'Bhavāni',
			'Bhagwantnagar',
			'Beltangadi',
			'Bellūru',
			'Bāzpur',
			'Bānswāra',
			'Balrāmpur',
			'Bahua',
			'Ajodhya',
			'Arcot',
			'Antri',
			'Anandnagar',
			'Āmta',
			'Āmbāsa',
			'Amarpur',
			'Amarkantak',
			'Akālgarh',
			'Kesabpur',
			'Masila',
			'Murudeshwara',
			'Mandamarri',
			'Kalyandurg',
			'Artist Village',
			'Kadakkavoor',
			'Hatsingimari',
			'Podaturpet',
			'Vattalkundu',
			'Valsād',
			'Ūn',
			'Uklāna',
			'Thiruthani',
			'Tiruppur',
			'Thiruvarur',
			'Taleigao',
			'Sumbal',
			'Srīmushnam',
			'Soron',
			'Sīra',
			'Sindhnūr',
			'Sillod',
			'Shertallai',
			'Seoni',
			'Sathankulam',
			'Samrāla',
			'Sagauli',
			'Saugor',
			'Renigunta',
			'Rāzām',
			'Raxaul',
			'Rānāvāv',
			'Rāmjībanpur',
			'Rājaldesar',
			'Raisen',
			'Rahā',
			'Radaur',
			'Puduppatti',
			'Pilibangan',
			'Pihānī',
			'Periyanayakkanpalaiyam',
			'Peraiyur',
			'Patāmundai',
			'Parīchhatgarh',
			'Pāchora',
			'Nambutalai',
			'Mushābani',
			'Mungaoli',
			'Mubarakpur',
			'Miriālgūda',
			'Mangawān',
			'Maner',
			'Malkera',
			'Mādugula',
			'Machhlīshahr',
			'Lingsugūr',
			'Lānja',
			'Kuchaiburi',
			'Kozhikode',
			'Korampallam',
			'Khānpur',
			'Keshorai Pātan',
			'Kasrāwad',
			'Kareli',
			'Kapūrthala',
			'Kānor',
			'Kāngpokpi',
			'Kāndra',
			'Kāmāreddi',
			'Kamalpur',
			'Kālna',
			'Kanīna Khās',
			'Kalamb',
			'Jūnāgadh',
			'Shādīpur Julāna',
			'Jayamkondacholapuram',
			'Jamkhandi',
			'Jagdīshpur',
			'Jagalūr',
			'Islāmnagar',
			'Idukki',
			'Ichalkaranji',
			'Narmadapuram',
			'Hāveri',
			'Haliyal',
			'Gunnaur',
			'Gorūr',
			'Goregaon',
			'Ghugus',
			'Ghātanji',
			'Gāndhī Nagar',
			'Fīrozpur Jhirka',
			'Fatehgarh Chūriān',
			'Emmiganūr',
			'Dūsi',
			'Dongargaon',
			'Digha',
			'Dharmanagar',
			'Devadānappatti',
			'Colovale',
			'Chinna Salem',
			'Chikhli',
			'Chidambaram',
			'Chhāta',
			'Charthāwal',
			'Brahmapur',
			'Bijni',
			'Bīghāpur Khurd',
			'Bīdar',
			'Bhīkhi',
			'Betūl',
			'Beohāri',
			'Barsāna',
			'Bara Bazar',
			'Banmankhi',
			'Bānki',
			'Bakewar',
			'Bagulā',
			'Bagaha',
			'Awantipur',
			'Āsind',
			'Āsansol',
			'Amānpur',
			'Alwa Tirunagari',
			'Adūr',
			'Abohar',
			'Soyībug',
			'Porur',
			'Airoli',
			'Kagaznāgār',
			'Neemuch',
			'Palavakkam',
			'Hawai',
			'Sonari',
			'Paramathi Velur',
			'Yairipok',
			'Yādgīr',
			'Wellington',
			'Wāshīm',
			'Wālājābād',
			'Venkatagiri',
			'Vandalūr',
			'Uttiramerūr',
			'Ulhasnagar',
			'Trimbak',
			'Cheyyar',
			'Tiruvalla',
			'Tarikere',
			'Tārānagar',
			'Talwāra',
			'Tādpatri',
			'Sultānpur',
			'Suket',
			'Srīperumbūdūr',
			'Srīnivāspur',
			'Simaria',
			'Silao',
			'Sikandarābād',
			'Shupīyan',
			'Shikārpur',
			'Shāhjānpur',
			'Seoni Mālwa',
			'Savanūr',
			'Sānkrāil',
			'Sanguem',
			'Selu',
			'Sadalgi',
			'Sadābād',
			'Roha',
			'Robertsonpet',
			'Rīngas',
			'Queula',
			'Punjai Puliyampatti',
			'Pratāpgarh',
			'Polasara',
			'Phalodi',
			'Pārdi',
			'Paramagudi',
			'Panna',
			'Pālghar',
			'Palāsa',
			'Pachperwa',
			'Nūh',
			'Nīlgiri',
			'Nidadavole',
			'Neturhāt',
			'Naugachhia',
			'Nasīrābād',
			'Nakūr',
			'Nāgaur',
			'Nagar',
			'Mudgere',
			'Mowād',
			'Misrikh',
			'Mendarda',
			'Mālūr',
			'Mallāpuram',
			'Makhu',
			'Mahāsamund',
			'Madukkūr',
			'Lalgudi',
			'Kunnattūr',
			'Kumaralingam',
			'Kotamangalam',
			'Kosigi',
			'Kodungallūr',
			'Khusropur',
			'Khudāganj',
			'Kharhiāl',
			'Kharar',
			'Khandwa',
			'Khammam',
			'Khairābād',
			'Kenduadīh',
			'Kekri',
			'Katihar',
			'Karhal',
			'Kāmārhāti',
			'Kālol',
			'Kalamnūri',
			'Iāwar',
			'Jaunpur',
			'Jājpur',
			'Jaito',
			'Jaitāran',
			'Jahāngīrpur',
			'Jagdīspur',
			'Hosūr',
			'Hisuā',
			'Hesla',
			'Hazāribāgh',
			'Hampi',
			'Gyānpur',
			'Guhāgar',
			'Golakganj',
			'Gokul',
			'Faridabad',
			'Erāttupetta',
			'Dharmapuri',
			'Dhali',
			'Dattāpur',
			'Dasūya',
			'Dāsna',
			'Dinapore',
			'Clement Town',
			'Chilakalūrupet',
			'Chetput',
			'Chatrā',
			'Chail',
			'Kolkata',
			'Binka',
			'Bilaspur',
			'Bīkāpur',
			'Bhindār',
			'Bhikangaon',
			'Bhānpura',
			'Benaulim',
			'Bānsdīh',
			'Āttayyāmpatti',
			'Atmakūr',
			'Arāmbāgh',
			'Anshing',
			'Alandur',
			'Aizawl',
			'Ahwa',
			'Borkhera',
			'Dasnapur',
			'Serilingampalle',
			'Govindapuram',
			'Khanpur',
			'Zaidpur',
			'Vriddhāchalam',
			'Vinchia',
			'Vengurla',
			'Vallabh Vidyanagar',
			'Vaikam',
			'Vāghodia',
			'Vadlapūdi',
			'Vadamadurai',
			'Umarkot',
			'Tiruppuvanam',
			'Tiruppālaikudi',
			'Tirukkoyilur',
			'Thirukattupalli',
			'Tarabha',
			'Tādepalle',
			'Sultanpur',
			'Sultanpur',
			'Sindi',
			'Sihorā',
			'Shyamnagar',
			'Shāntipur',
			'Shāhpur',
			'Secunderabad',
			'Sanvordem',
			'Sangāreddi',
			'Samāstipur',
			'Samālkha',
			'Ropar',
			'Rishra',
			'Rehti',
			'Rāwatsār',
			'Rahimatpur',
			'Raghunathpur',
			'Rāghogarh',
			'Qāzigund',
			'Purnia',
			'Pollachi',
			'Pilkhua',
			'Periyanegamam',
			'Pennāgaram',
			'Pāngāla',
			'Pandaria',
			'Dharashiv',
			'Nūrpur',
			'Nizāmābād',
			'Nirmāli',
			'Neyveli',
			'Nāzirā',
			'Narharpur',
			'Nanauta',
			'Naldurg',
			'Nabīnagar',
			'Muri',
			'Munirābād',
			'Mūlanūr',
			'Mudgal',
			'Mhāsvād',
			'Merta',
			'Mehnagar',
			'Māvelikara',
			'Mariāhu',
			'Manoharpur',
			'Mandholi Kalān',
			'Mālvan',
			'Kuzhithurai',
			'Kunda',
			'Kumta',
			'Kūd',
			'Koynanagar',
			'Kottūru',
			'Kottayam',
			'Koregaon',
			'Kondagaon',
			'Khamānon Kalān',
			'Kātrās',
			'Katra',
			'Kātpādi',
			'Kāmthi',
			'Kalyān',
			'Kakdwip',
			'Kaimganj',
			'Jora',
			'Jharia',
			'Jāmtāra',
			'Jaisingpur',
			'Jaipur',
			'Jahāzpur',
			'Jagnair',
			'Hosangadi',
			'Hindupur',
			'Guru Har Sahāi',
			'Guntur',
			'Gola Bāzār',
			'Gangākher',
			'Gāndhīdhām',
			'Gāndarbal',
			'Gagret',
			'Fatehpur Chaurāsi',
			'Fyzābād',
			'Dūngarpur',
			'Dindori',
			'Dīdwāna',
			'Dhanaula',
			'Dewā',
			'Deogarh',
			'Davangere',
			'Chhatarpur',
			'Chengannūr',
			'Charkhāri',
			'Burla',
			'Birūr',
			'Bilgi',
			'Bilariāganj',
			'Bhīlwāra',
			'Bhilai',
			'Bhigvan',
			'Bhawāni Mandi',
			'Bhavnagar',
			'Bhāgalpur',
			'Bhadreswar',
			'Benīganj',
			'Belagavi',
			'Basavana Bāgevādi',
			'Bāruni',
			'Barpathār',
			'Bānposh',
			'Bāngarmau',
			'Baldeogarh',
			'Balarāmpur',
			'Bālāpur',
			'Bada Barabīl',
			'Azamgarh',
			'Aurangābād',
			'Arang',
			'Aonla',
			'Amrāvati',
			'Amalner',
			'Ahraura',
			'Achalpur',
			'Kheri Sāmpla',
			'Muvattupuzha',
			'Singrauli',
			'Morigaon',
			'Bhagirathpur',
			'Kamjong',
			'Kadmat',
			'Koyali',
			'Vrindāvan',
			'Virpur',
			'Vānsada',
			'Valparai',
			'Umrāla',
			'Udaipur',
			'Tumkūr',
			'Tsrār Sharīf',
			'Tirumakūdal Narsipur',
			'Theog',
			'Tārāpur',
			'Sunām',
			'Sītāpur',
			'Sirsāganj',
			'Sirhind',
			'Simdega',
			'Sikandarpur',
			'Shājāpur',
			'Shāhpur',
			'Sarkhej',
			'Rishīkesh',
			'Rāmkola',
			'Rāisinghnagar',
			'Raigarh',
			'Puttūr',
			'Pullambādi',
			'Pulivendla',
			'Poonamalle',
			'Pinjaur',
			'Pimpri',
			'Pharihā',
			'Phalauda',
			'Pauri',
			'Pāthri',
			'Parnera',
			'Pānīpat',
			'Orchha',
			'Nyāmti',
			'Nūzvīd',
			'Narwāna',
			'Naroda',
			'Nagar',
			'Nāgamangala',
			'Murādnagar',
			'Mirik',
			'Mathura',
			'Manjhanpur',
			'Manihāri',
			'Mandapam',
			'Manali',
			'Māllūr',
			'Madurāntakam',
			'Lālganj',
			'Lakshettipet',
			'Lakhīmpur',
			'Lachhmangarh Sīkar',
			'Kushālgarh',
			'Kurduvādi',
			'Kundla',
			'Kotwa',
			'Kodigenahalli',
			'Kīratpur',
			'Khawhai',
			'Khajuraho Group of Monuments',
			'Khairāgarh',
			'Khāga',
			'Kāliyāganj',
			'Kairāna',
			'Jatani',
			'Jaswantnagar',
			'Jasidih',
			'Jalesar',
			'Jagannāthpur',
			'Jagādhri',
			'Irinjālakuda',
			'Holalkere',
			'Hindaun',
			'Hasanpur',
			'Harpanahalli',
			'Hardoī',
			'Hāora',
			'Hānsi',
			'Hābra',
			'Guruvāyūr',
			'Duliajan',
			'Dīg',
			'Deūlgaon Rāja',
			'Dehri',
			'Daund',
			'Dārjiling',
			'Dalhousie',
			'Chodavaram',
			'Rampachodavaram',
			'Chhātāpur',
			'Chengalpattu',
			'Būndi',
			'Budaun',
			'Boisar',
			'Biswān',
			'Bīrpur',
			'Bīlāspur',
			'Bijrauni',
			'Bathinda',
			'Bhātāpāra',
			'Bettiah',
			'Batāla',
			'Barkot',
			'Bāramūla',
			'Bāghpat',
			'Bachhraon',
			'Arumbāvūr',
			'Arkalgūd',
			'Ariyalūr',
			'Almora',
			'Alāwalpur',
			'Alagāpuram',
			'Afzalgarh',
			'Adalaj',
			'Vellānūr',
			'Navi Mumbai',
			'Pitampura',
			'Ashoknagar Kalyangarh',
			'Ramagundam',
			'Shahbazpur',
			'Minicoy',
			'Yelandūr',
			'Yāval',
			'Yārāda',
			'Wokha',
			'Warora',
			'Warangal',
			'Vettūr',
			'Velur',
			'Azhikkal',
			'Tiruchirappalli',
			'Tīrthahalli',
			'Tekāri',
			'Surendranagar',
			'Sri Dūngargarh',
			'Sorbhog',
			'Somvārpet',
			'Sitārganj',
			'Silvassa',
			'Siddipet',
			'Sangrūr',
			'Sahāwar',
			'Risod',
			'Rāpar',
			'Rāmgarh',
			'Rāikot',
			'Raia',
			'Rāhatgarh',
			'Rafiganj',
			'Pulwama',
			'Petlāwad',
			'Pedana',
			'Parāsia',
			'Pāloncha',
			'Paddhari',
			'Nowrangapur',
			'Nilanga',
			'Nāttarasankottai',
			'Nainpur',
			'Mussoorie',
			'Musiri',
			'Muragācha',
			'Multai',
			'Mīrganj',
			'Mettur',
			'Mankāchar',
			'Manchar',
			'Manāsa',
			'Madhipura',
			'Lohaghāt',
			'Kotapārh',
			'Korādi',
			'Konnūr',
			'Konanūr',
			'Kashipur',
			'Karur',
			'Kāraikkudi',
			'Captainganj',
			'Jhūsi',
			'Jamūī',
			'Irugūr',
			'Imphāl',
			'Igatpuri',
			'Hosakote',
			'Hiriyūr',
			'Gūduvāncheri',
			'Goshāinganj',
			'Gorakhpur',
			'Gondā City',
			'Gogāpur',
			'Gangāpur',
			'Gajendragarh',
			'Fatehpur',
			'Dumraon',
			'Dorāha',
			'Dispur',
			'Deoli',
			'Delvāda',
			'Darbhanga',
			'Daltonganj',
			'Dohad',
			'Chunār',
			'Chorhat',
			'Chittarkonda',
			'Chintāmani',
			'Chhuīkhadān',
			'Chaupāl',
			'Chāndor',
			'Būndu',
			'Bokākhāt',
			'Bilāri',
			'Bijbehara',
			'Bhatkal',
			'Bhabhua',
			'Bemetāra',
			'Bedi',
			'Bārāmati',
			'Bānsi',
			'Baihar',
			'Bahādurganj',
			'Bagaha',
			'Ayakudi',
			'Avinashi',
			'Sambhaji Nagar',
			'Āndippatti',
			'Amarpur',
			'Ajra',
			'Ajmer',
			'Ahmadpur',
			'Agar',
			'Bāgnān',
			'Jauriān',
			'Dayapar',
			'Malkapur',
			'Garamur',
			'Ampati',
			'Rohini',
			'Amini',
			'Vijayapuri',
			'Varca',
			'Vāda',
			'Upleta',
			'Udaipur',
			'Ooty',
			'Topchānchi',
			'Tekkali',
			'Talegaon Dābhāde',
			'Tādepallegūdem',
			'Suriānwān',
			'Sohāgi',
			'Siuri',
			'Sisauli',
			'Shimla',
			'Sikandra Rao',
			'Shāmgarh',
			'Shām Churāsi',
			'Shāhpur',
			'Shāhābād',
			'Seondha',
			'Sālūr',
			'Sainthia',
			'Ratlām',
			'Rānīganj',
			'Rājur',
			'Rājākhera',
			'Piravam',
			'Phulpur',
			'Pāvugada',
			'Patiāla',
			'Patancheru',
			'Pariyāpuram',
			'Pānsemāl',
			'Pāli',
			'Palāsbāri',
			'Pālanpur',
			'Pākāla',
			'Padrauna',
			'Pachmarhi',
			'Okha',
			'Nimāparha',
			'Nārāyanpet',
			'Nalgonda',
			'Muzaffarpur',
			'Moga',
			'Meerut',
			'Maur',
			'Manglaur',
			'Mandāwar',
			'Malaut',
			'Maholi',
			'Mahārājganj',
			'Mahād',
			'Madhogarh',
			'Kunnamkulam',
			'Koradāchcheri',
			'Kilvelur',
			'Khagaria',
			'Khada',
			'Kāthor',
			'Kargil',
			'Kannavam',
			'Kanchipuram',
			'Kālpi',
			'Kalas',
			'Kalaīkunda',
			'Kadod',
			'Jangipur',
			'Jandiāla',
			'Jalgaon',
			'Jaithāri',
			'Hubli',
			'Halvad',
			'Gursahāiganj',
			'Gūdūr',
			'Gokavaram',
			'Gohānd',
			'Etikoppāka',
			'Ellore',
			'Dostpur',
			'Dibai',
			'Diamond Harbour',
			'Dharmavaram',
			'Dharmadam',
			'Devakottai',
			'Desūr',
			'Dausa',
			'Daulatābād',
			'Dalsingh Sarai',
			'Dabwāli',
			'Chicholi',
			'Challapalle',
			'Chalāla',
			'Canning',
			'Biaora',
			'Bhongaon',
			'Bhogpur',
			'Bhind',
			'Bhawānipatna',
			'Bhātpāra',
			'Belūr',
			'Bellary',
			'Bānkura',
			'Bandipura',
			'Baheri',
			'Badarwās',
			'Bachhrāwān',
			'Athni',
			'Aruppukkottai',
			'Arumuganeri',
			'Arrah',
			'Alnāvar',
			'Alampur',
			'Begampur',
			'Dumjor',
			'Chakapara',
			'Akkarampalle',
			'Chinnachowk',
			'Biswanath Chariali',
			'Abrama',
			'Shivani Pisa',
		],
	},
	Indonesia: {
		country: 'Indonesia',
		cities: [
			'Sinabang',
			'Simpang Empat',
			'Sibigo',
			'Ujungpangkah',
			'Tideng Pale',
			'Bandar Lampung',
			'Teluk Kuantan',
			'Sukasari',
			'Insrom',
			'Sokobanah',
			'Rantau',
			'Randudongkal',
			'Raba',
			'Poigar',
			'Pemangkat',
			'Pare',
			'Pakisaji',
			'Muraloka',
			'Matur',
			'Labuhanmaringgai',
			'Kota Pinang',
			'Kotabumi',
			'Korem',
			'Kapan',
			'Jojogan',
			'Jatinagara',
			'Jangkat',
			'Gorontalo',
			'Enarotali',
			'Dumai',
			'Cirebon',
			'Cileunyi',
			'Cilacap',
			'Cikeusik',
			'Cikalong',
			'Buniseuri',
			'Brakas',
			'Baluburlimbangan',
			'Bintuni',
			'Banjarwangi',
			'Bade',
			'Astanajapura',
			'Argamakmur',
			'Uluale',
			'Amparita',
			'Beringinjaya',
			'Babau',
			'Watulumbung',
			'Lubukalung',
			'Sumberrejo',
			'Naru',
			'Nggongi',
			'Janapria',
			'Welamosa',
			'Polos',
			'Gedongombo',
			'Ledeunu',
			'Pekan Bahapal',
			'Getentiri',
			'Pulosari',
			'Pulo Ie',
			'Babah Rot',
			'Pulau Tiga',
			'Long Beluah',
			'Susoh',
			'Sigli',
			'Sibuhuan',
			'Rantauprapat',
			'Mogang',
			'Meulaboh',
			'Limapuluh',
			'Berastagi',
			'Wotu',
			'Weru',
			'Windesi',
			'Watansoppeng',
			'Tentena',
			'Tembuku',
			'Tegal',
			'Tayu',
			'Tanjung',
			'Tana Paser',
			'Tamiang Layang',
			'Sulang Tengah',
			'Soko',
			'Sijunjung',
			'Sangatta',
			'Sabang Barat',
			'Rantepao',
			'Raha',
			'Raduria',
			'Pimpi',
			'Patimuan',
			'Pecangaan',
			'Panjalu',
			'Penajam',
			'Ngawen',
			'Muarakomam',
			'Mengkalip',
			'Malinau',
			'Maba',
			'Lumajang',
			'Lolak',
			'Lebaksiu',
			'Kuningan',
			'Ketapang',
			'Kendahe',
			'Kedungwuni',
			'Karangharjo Kidul',
			'Jebus',
			'Gerung',
			'Gampengrejo',
			'Driyorejo',
			'Cikajang',
			'Ciemas',
			'Bokat',
			'Batusangkar',
			'Barapas',
			'Ambon',
			'Alahanpanjang',
			'Adipala',
			'Gununganyar',
			'Baranti',
			'Kasihan',
			'Ubrub',
			'Sentani',
			'Tirtopuro',
			'Pauhambar',
			'Sikur',
			'Detung',
			'Sipintuangin',
			'Pasanggrahan',
			'Kuripan',
			'Limusnunggal',
			'Bangsalsari',
			'Bendo',
			'Takari',
			'Samalantan',
			'Seupakat',
			'Peukanbada',
			'Kutapanjang',
			'Margasari',
			'Sitimerto',
			'Siborong-Borong',
			'Siabu',
			'Pangkalan Brandan',
			'Padangtiji',
			'Kuala Bhee',
			'Wajak',
			'Waingapu',
			'Ternate',
			'Terbanggi Besar',
			'Tarub',
			'Tanjung Balai',
			'Tambak',
			'Talise',
			'Subaim',
			'Sorendiweri',
			'Singaparna',
			'Sila',
			'Sekongkang Bawah',
			'Sekampung',
			'Riung',
			'Parit Malintang',
			'Panji',
			'Panggungrejo',
			'Pagak',
			'Narmada',
			'Munjungan',
			'Muara Beliti',
			'Mendaha',
			'Lubuk Sikaping',
			'Lemahputih',
			'Kutoarjo',
			'Kendari',
			'Kameri',
			'Isimu',
			'Indramayu',
			'Gajrug',
			'Gandusari',
			'Diwek',
			'Bula',
			'Baturiti',
			'Banyuresmi',
			'Balikpapan',
			'Babirik',
			'Atap',
			'Asembagus',
			'Tompasobaru',
			'Tancung',
			'Tuktuk Sonak',
			'Sarimatondang',
			'Sukarama',
			'Gudang',
			'Cibongas',
			'Glagahwero',
			'Bolou',
			'Rundeng',
			'Rantau Prapat',
			'Warmare',
			'Samanente',
			'Kota Trieng',
			'Sungai Raya',
			'Blang Mane',
			'Kerangagung',
			'Waru',
			'Sungaitatas',
			'Mungkuruyam',
			'Aifat',
			'Masohi',
			'Bandar Seri Bentan',
			'Tanjungbalai',
			'Tangse',
			'Kabanjahe',
			'Celala',
			'Uta',
			'Tolitoli',
			'Tanusan',
			'Tajinan',
			'Susupu',
			'Sungaikakap',
			'Sofifi',
			'Sleman',
			'Situbondo',
			'Saketi',
			'Putussibau',
			'Purwoharjo',
			'Polewali',
			'Pantai',
			'Painan',
			'Padang',
			'Negararatu',
			'Muntok',
			'Muncang',
			'Mukomuko',
			'Manganitu',
			'Manado',
			'Luragung',
			'Kotawaringin',
			'Kelua',
			'Kiruru',
			'Kebomas',
			'Kasui',
			'Karanggeneng',
			'Kaimana',
			'Hadakewa',
			'Gunem',
			'Dompu',
			'Comal',
			'Cisaga',
			'Cikoneng',
			'Cikatomas',
			'Cikampek',
			'Biak',
			'Besuki',
			'Baki',
			'Aitinyo',
			'Rantepang',
			'Unaaha',
			'Baa',
			'Tanah Merah',
			'Patrang',
			'Sungaisarik',
			'Sungayang',
			'Nusa Dua',
			'Kebonsari',
			'Soa',
			'Tigarunggu',
			'Cigugur',
			'Ngulakan',
			'Banjaranyar',
			'Siso',
			'Merdey',
			'Homeyo',
			'Indrapuri',
			'Banda Layung',
			'Pasir Panjang',
			'Waipia',
			'Kenyam',
			'Teluk Nibung',
			'Sondi',
			'Nainggolan',
			'Sikabaluan',
			'Wiradesa',
			'Wanaraja',
			'Tanjung Pandan',
			'Tagolu',
			'Taccipi',
			'Surakarta',
			'Sungairaya',
			'Sumber',
			'Sukadana',
			'Saparua',
			'Sampit',
			'Ponorogo',
			'Pengandonan',
			'Pangkajene',
			'Pamanukan',
			'Padangtikar',
			'Ngrayun',
			'Ngabang',
			'Masamba',
			'Lebakwangi',
			'Labuha',
			'Kubu',
			'Kroya',
			'Kokas Kota',
			'Kencong',
			'Kema',
			'Jombang',
			'Gedangan',
			'Cisayong',
			'Cikarang',
			'Ciampea',
			'Buduran',
			'Bojonegara',
			'Biha',
			'Betaf',
			'Banjarmasin',
			'Bangli',
			'Bangkinang',
			'Bangilan',
			'Sanur',
			'Jangkar',
			'Lampa',
			'Teppo',
			'Kelapalima',
			'Karangbadar Kidul',
			'Angkup',
			'Pule',
			'Ulakan',
			'Sirukam',
			'Sukamaju Kidul',
			'Sinargalih',
			'Jamong',
			'Sukorejo',
			'Apui',
			'Data Dian',
			'Eci',
			'Kumopa',
			'Wasior',
			'Sawang',
			'Peuleumat',
			'Idi Cut',
			'Kajeung',
			'Sangir',
			'Ulee Gle',
			'Binalawan',
			'Matangai',
			'Allealle',
			'Tuapejat',
			'Pamolokan',
			'Ujung Gading',
			'Waisai',
			'Tulungagung',
			'Tiris',
			'Tanjungagung',
			'Tabing',
			'Sungai Pinyuh',
			'Semurup',
			'Senori',
			'Sedinginan',
			'Sarebe',
			'Piru',
			'Pinrang',
			'Pangaron',
			'Pemalang',
			'Pamekasan',
			'Obano',
			'Nganjuk',
			'Nangaroro',
			'Nabire',
			'Menes',
			'Mamuju',
			'Mamasa',
			'Leuwidamar',
			'Klaten',
			'Klangenan',
			'Ketanggungan',
			'Kandangan',
			'Kalibunder',
			'Jelbuk',
			'Jekulo',
			'Gunungwungkal',
			'Elopada',
			'Durenan',
			'Dongko',
			'Cisolok',
			'Cimanggu',
			'Boroko',
			'Elat',
			'Pekalongan',
			'Lamadong',
			'Malunda',
			'Apala',
			'Batuputih',
			'Aek Kanopan',
			'Bahoi',
			'Terara',
			'Pakuwon',
			'Sukaraja',
			'Sukadana',
			'Krajan A Wonorejo',
			'Kie',
			'Muting',
			'Pulo Batal',
			'Beusa Seberang',
			'Adil Makmur',
			'Lageun',
			'Matangkuli',
			'South Tangerang',
			'Pasingkan',
			'Terentang',
			'Loklaga',
			'Fef',
			'Masyeta',
			'Raas',
			'Singkil',
			'Waren',
			'Tangga',
			'Tebing Tinggi',
			'Tatelu',
			'Tanjungbatu',
			'Tamako',
			'Takalar',
			'Sukabumi',
			'Sidemen',
			'Selong',
			'Salinbatu',
			'Salakan',
			'Rumbia',
			'Purworejo',
			'Plumbon',
			'Pelaihari',
			'Pati',
			'Pasarkemis',
			'Pariaman',
			'Kuaro',
			'Pakong',
			'Pagatan',
			'Ngantru',
			'Muara Tebo',
			'Muarapinang',
			'Mataram',
			'Manokwari',
			'Magetan',
			'Madiun',
			'Lenteng',
			'Lawang',
			'Lamongan',
			'Laiwui',
			'Klampis',
			'Kediri',
			'Kelarik',
			'Kabir',
			'Grogol',
			'Gayam',
			'Enemawira',
			'Dukuhturi',
			'Donomulyo',
			'Depok',
			'Citeureup',
			'Cianjur',
			'Bogorejo',
			'Binuang',
			'Bintuhan',
			'Belimbing',
			'Batibati',
			'Banyuates',
			'Asera',
			'Amahai',
			'Gunung Kendil',
			'Lamasi',
			'Kartasura',
			'Pandan',
			'Menden',
			'Pengkok',
			'Subagan',
			'Mantang',
			'Sulahan',
			'Cisiih',
			'Simpang',
			'Jayapura',
			'Hegarsari',
			'Wates',
			'Oebobo',
			'Cot Baroh',
			'Suka Makmue',
			'Danaupanggang',
			'Tapintengah',
			'Doom',
			'Labungkari',
			'Yogyakarta',
			'Makassar',
			'Tekung',
			'Taliwang',
			'Sorong',
			'Sindangbarang',
			'Sidoarjo',
			'Serui',
			'Sampang',
			'Sambas',
			'Purwokerto',
			'Parepare',
			'Pangkalan Balai',
			'Pancur',
			'Mauponggo',
			'Buakayu',
			'Manonjaya',
			'Manalu',
			'Lenangguar',
			'Kwandang',
			'Kediri',
			'Kalidawir',
			'Jepon',
			'Gresik',
			'Gapura',
			'Dumoga',
			'Dofa',
			'Darma',
			'Boyolali',
			'Binangun',
			'Bebandem',
			'Batubantar',
			'Bantaeng',
			'Banding Agung',
			'Balung',
			'Bajawa',
			'Irarututiga',
			'Baraka',
			'Dlemmer',
			'Salimpaung',
			'Ndona',
			'Wolowona',
			'Palas',
			'Warungbanten',
			'Pasirluhur',
			'Pasirpanjang',
			'Tlogowungu',
			'Likioen',
			'Menanga',
			'Labuhan Bajau',
			'Bagok',
			'Pulau Temiang',
			'Kurau',
			'Harapan Jaya',
			'Tubei',
			'Kigamani',
			'Serawai',
			'Natal',
			'Lhoong',
			'Kisaran',
			'Isak',
			'Batangtoru',
			'Wonorejo',
			'Witihama',
			'Tumpang',
			'Surade',
			'Sungailiat',
			'Sumedang',
			'Sukowono',
			'Sukaraja',
			'Selat Panjang',
			'Sedanau',
			'Sapat',
			'Ruteng',
			'Robatal',
			'Purbalingga',
			'Pringsewu',
			'Pota',
			'Pamulang',
			'Pagar Alam',
			'Nikiniki',
			'Muara Limun',
			'Muara Bungo',
			'Muarabadak',
			'Muaro',
			'Mensalong',
			'Martapura',
			'Marabahan',
			'Leles',
			'Laikit, Laikit II (Dimembe)',
			'Kolonodale',
			'Katobu',
			'Karangan',
			'Jambula',
			'Gambiran Satu',
			'Boawae',
			'Bekasi',
			'Baturaja',
			'Balong',
			'Balapulang',
			'Amuntai',
			'Lupak',
			'Ciranjang-hilir',
			'Guguk',
			'Jabajero',
			'Harian',
			'Sumbergedong',
			'Momanalu',
			'Talango',
			'Tarus',
			'Karubaga',
			'Terbangan',
			'Idi Rayeuk',
			'Mata Ie',
			'Juli',
			'Suek',
			'Sungaiboh',
			'Palaran',
			'Awayan',
			'Margasarihilir',
			'Barikin',
			'Tambakrejo',
			'Teluk Dalam',
			'Sipirok',
			'Parlilitan',
			'Padangsidempuan',
			'Laweueng',
			'Dolok Sanggul',
			'Blangpidie',
			'Wolowaru',
			'Wahai',
			'Tunjungan',
			'Toho',
			'Tegalalang',
			'Tanjung',
			'Tongkiling',
			'Tabanan',
			'Sungguminasa',
			'Sumpur Kudus',
			'Sumberanyar',
			'Sukawening',
			'Somba',
			'Sodong Hilir',
			'Sape',
			'Sadananya',
			'Rokan',
			'Pulau Kijang',
			'Prigen',
			'Plampang',
			'Pekanbaru',
			'Pademawu',
			'Merakurak',
			'Malang',
			'Mafa',
			'Lemito',
			'Kualakapuas',
			'Krui',
			'Kesugihan',
			'Kerang',
			'Kayu Agung',
			'Kalianda',
			'Jatibarang',
			'Gunungmegang Dalam',
			'Dukuhseti',
			'Cikijing',
			'Ciamis',
			'Ceper',
			'Bluto',
			'Baranusa',
			'Bayongbong',
			'Batulicin',
			'Bantur',
			'Banjaran',
			'Padalarang',
			'Gamping Lor',
			'Sengge',
			'Tanjungampalu',
			'Legian',
			'Puncaktugu',
			'Nagarasari',
			'Mandarahan',
			'Senayan',
			'Tanjung Palas',
			'Sampuran',
			'Sungairaya',
			'Binawara',
			'Lontar',
			'Kenyau',
			'Matak',
			'Batang',
			'Sunggal',
			'Pematang Raya',
			'Muara',
			'Medan',
			'Calang',
			'Wonogiri',
			'Ubud',
			'Tembuni',
			'Tasikmalaya',
			'Sulitair',
			'Sukasada',
			'Selajambe',
			'Saukorem',
			'Sapeken',
			'Saonek',
			'Sumberbaba',
			'Rengasdengklok',
			'Rawalo',
			'Pulang Pisau',
			'Candi Prambanan',
			'Pekalongan',
			'Pasarkuok',
			'Pandaan',
			'Nyalindung',
			'Muaralakitan',
			'Masat',
			'Khairiahmandah',
			'Malangbong',
			'Longnawang',
			'Labuan Lombok',
			'Lampihung',
			'Kumai',
			'Kepoh',
			'Kawali',
			'Kasarangan',
			'Kajen',
			'Jimbaran',
			'Jailolo',
			'Haruyan',
			'Godo',
			'Ciruas',
			'Cermee',
			'Campor',
			'Bonebone',
			'Beo',
			'Banjar',
			'Bajo',
			'Arjawinangun',
			'Air Molek',
			'Rindingallo',
			'Matango',
			'Klapagada',
			'Senggigi',
			'Kaburon',
			'Pacarkeling',
			'Sebaren',
			'Tempurejo',
			'Bentengjawa',
			'Penengahan',
			'Cibitung',
			'Bangkle',
			'Gupakan',
			'Kebonsari',
			'Siliragung',
			'Kemang',
			'Kuala Bintang',
			'Alue Glumpang',
			'Beutong Ateuh',
			'Mane',
			'Sukarama',
			'Bantul',
			'Anjirserapat',
			'Putatbasiun',
			'Malifud',
			'Tigaraksa',
			'Pulau Pramuka',
			'Onimsari',
			'Tomok Bolon',
			'Tebingtinggi',
			'Pangururan',
			'Krueng Luak',
			'Deli Tua',
			'Belawan',
			'Kenyaran',
			'Telukpakedai',
			'Tanjung Pinang',
			'Sukoharjo',
			'Stagen',
			'Sluke',
			'Salem',
			'Salawu',
			'Rajapolah',
			'Pontianak',
			'Petakbehandang',
			'Pauh',
			'Pameungpeuk',
			'Palu',
			'Padarincang',
			'Muara Teweh',
			'Muaralabuh',
			'Muara Bulian',
			'Moro',
			'Molibagu',
			'Margasari',
			'Lewolaga',
			'Lengkong',
			'Labuan',
			'Kotabunan',
			'Kotabaru',
			'Kepanjen',
			'Padangtepung',
			'Kalianget',
			'Gianyar',
			'Enok',
			'Ende',
			'Brebes',
			'Bunobogu',
			'Bengkulu',
			'Blambangan Umpu',
			'Baturaden',
			'Batauga',
			'Banyuwangi',
			'Balung',
			'Alabio',
			'Bangunrejo',
			'Getengan',
			'Teluknaga',
			'Camplong',
			'Seririt',
			'Aikmel',
			'Donggo',
			'Dajan Tangluk',
			'Cempa Pasar',
			'Gardutanjak',
			'Bejagung Kidul',
			'Moanemani',
			'Pulau Punjung',
			'Kuala Baru',
			'Lampuuk',
			'Uleeglee',
			'Sungai Iyu',
			'Manunggulama',
			'Buli',
			'Mangupura',
			'Kucir',
			'Terangun',
			'Pining',
			'Werinama',
			'Wanci',
			'Tulangan Utara',
			'Tinabogan',
			'Tanjungpati',
			'Talangbetutu',
			'Surabaya',
			'Suban Jeriji',
			'Sumberjambe',
			'Sragen',
			'Sintang',
			'Senduro',
			'Semuda',
			'Rubaru',
			'Pemenang',
			'Pasean',
			'Paseh',
			'Paringin',
			'Padangbatung',
			'Oransbari',
			'Nanga Bulik',
			'Mersam',
			'Manna',
			'Manggar',
			'Lubuk Basung',
			'Lewoleba',
			'Kresek',
			'Klungkung',
			'Kedungwaru',
			'Kebonarun',
			'Kasonawejo',
			'Kamal',
			'Garawangi',
			'Delanggu',
			'Dampit',
			'Ciomas',
			'Cimahi',
			'Cicurug',
			'Cibinong',
			'Cibeureum',
			'Bolaang',
			'Bengkayang',
			'Bayunglincir',
			'Batang',
			'Bangko',
			'Ankola',
			'Ambarawa',
			'Aenganyar',
			'Betun',
			'Batanghari',
			'Kauditan',
			'Eahun',
			'Depapre',
			'Kebon Gunung',
			'Darungan Lor',
			'Kebonan',
			'Tangratte',
			'Kalianyar Selatan',
			'Ritapiret',
			'Boru',
			'Gadingrejo',
			'Kedondong',
			'Cigeulis',
			'Cibungur',
			'Cibitung',
			'Bakung',
			'Pucanglaban',
			'Umbulan Kapiak',
			'Polen',
			'Panite',
			'Baniona',
			'Angan',
			'Geumpang',
			'Lampoih',
			'Halong',
			'Telagalangsat',
			'Kobakma',
			'Pemalang',
			'Saribudolok',
			'Peureulak',
			'Montasik',
			'Asan Kumbang',
			'Wardo',
			'Tondano',
			'Tepas',
			'Tarogong',
			'Tanjungbumi',
			'Tambaksari',
			'Surabaya',
			'Sengeti',
			'Sarilamak',
			'Salopa',
			'Prabumulih',
			'Pembuanghulu',
			'Pangkalan Bunut',
			'Palimanan',
			'Padaherang',
			'Nipah Panjang',
			'Ngoro',
			'Mojokerto',
			'Mangkutana',
			'Longikis',
			'Lirung',
			'Larompong',
			'Langgur',
			'Kimaam',
			'Ketahun',
			'Kapongan',
			'Hampang',
			'Kalipang',
			'Kadugede',
			'Gunung Sugih',
			'Galesong',
			'Dabra',
			'Ciwaru',
			'Caruban',
			'Bondokodi',
			'Banjarsari',
			'Banjar',
			'Balaiberkuak',
			'Bagan Si Api-api',
			'Armopa',
			'Arjasa',
			'Airmadidi',
			'Aimere',
			'Adiwerna',
			'Majennang',
			'Merauke',
			'Abepura',
			'Kuala Tuha',
			'Banjar Wangsian',
			'Wonotirto',
			'Durenan',
			'Kampungladang',
			'Kumanis',
			'Kebonan',
			'Pakel',
			'Moru',
			'Ritaebang',
			'Waiklibang',
			'Rantau Ambacang',
			'Rantau Panjang',
			'Buket Teukuh',
			'Kota Binjai',
			'Matangpayang',
			'Batusopang',
			'Woha',
			'Karang Tinggi',
			'Sigi Biromaru',
			'Buranga',
			'Amlapura city',
			'Moskona Barat',
			'Jagiro',
			'Sei Rampah',
			'Sipiongot',
			'Salak',
			'Penaron',
			'Pematangsiantar',
			'Lubuk Pakam',
			'Lhoknga',
			'Utan',
			'Ulu',
			'Tanjung Selor',
			'Tanjung',
			'Taniwel',
			'Tamberu',
			'Sungsang',
			'Singojuruh',
			'Seteluk',
			'Semarang',
			'Sebulu',
			'Sausapor',
			'Sangasangadalam',
			'Passo',
			'Palattae',
			'Palangkaraya',
			'Padangguci',
			'Ngawi',
			'Munduk',
			'Mujur',
			'Muarauya',
			'Muara Siau',
			'Muara Dua',
			'Kesamben',
			'Amlapura',
			'Karangampel',
			'Cibaregbeg',
			'Jenggawah',
			'Jalang',
			'Indralaya',
			'Dalududalu',
			'Cipari',
			'Cilegong',
			'Cepu',
			'Bungku',
			'Bulakamba',
			'Bontang',
			'Bandung',
			'Babat',
			'Atambua',
			'Anggana',
			'Sokarame',
			'Gili Air',
			'Sambopinggir',
			'Sukorambi',
			'Abang',
			'Rambatan',
			'Kotabaru',
			'Waipare',
			'Tamanan',
			'Panimbang',
			'Buniasih',
			'Ciwaru',
			'Sindangratu',
			'Latsari',
			'Prupuh',
			'Tempursari',
			'Oganlima',
			'Siulak Deras Mudik',
			'Paya Dapur',
			'Luthu',
			'Long Bang',
			'Sungaitabuk',
			'Tiakur',
			'Elelim',
			'Padang Aro',
			'Patipi Pasir',
			'Kramongmongga',
			'Seulimeum',
			'Parmonangan',
			'Waiwerang',
			'Tual',
			'Tongutisungi',
			'Tobelo',
			'Tenga',
			'Tarakan',
			'Tangerang',
			'Sungai Raya',
			'Sungailimau',
			'Sumedang Utara',
			'Serasan',
			'Sedan',
			'Sale',
			'Sagaranten',
			'Rengat',
			'Rappang',
			'Purwodadi',
			'Pandeglang',
			'Panawangan',
			'Panaragan',
			'Ngunut',
			'Muarakuang',
			'Meliau',
			'Lubuk Jambi',
			'Loakulu',
			'Kuta',
			'Kebumen',
			'Karanganom',
			'Malaju',
			'Jepara',
			'Jatiwangi',
			'Jatiroto',
			'Haruai',
			'Gongdanglegi Kulon',
			'Galis',
			'Buol',
			'Bulukumba',
			'Boyolangu',
			'Bonjol',
			'Bola',
			'Bima',
			'Banjar',
			'Bangkalan',
			'Balai Pungut',
			'Airbuaya',
			'Modayag',
			'Oekabiti',
			'Mungkid',
			'Ngraho',
			'Sukamulia',
			'Rowokangkung',
			'Tawali',
			'Allu',
			'Mulyosari',
			'Sukacai',
			'Kahuripan',
			'Bantengan',
			'Pragaan',
			'Mainang',
			'Bomomani',
			'Kokop',
			'Simpang Ulim',
			'Keudee Aron',
			'Jumpangdua',
			'Kalde Panga',
			'Karangbaru',
			'Kotabahagia',
			'Tanta',
			'Astambul',
			'Basiong',
			'Panarukan',
			'Talu',
			'Rikitgaib',
			'Muara Sipongi',
			'Yosowilangun',
			'Wonopringgo',
			'Wanurian',
			'Kolaka',
			'Sengkang',
			'Tanjungsamalantakan',
			'Ranai',
			'Pasar Wajo',
			'Pagelaran',
			'Negara',
			'Mojosari',
			'Masmambang',
			'Majalengka',
			'Maesan',
			'Longkali',
			'Karangintan',
			'Juwana',
			'Dungkek',
			'Ipuh',
			'Geser',
			'Gedong Tataan',
			'Fakfak',
			'Daruba',
			'Cisompet',
			'Bunga Mas',
			'Bulu',
			'Bandung',
			'Sukamaju',
			'Kadai',
			'Kebayakan',
			'Drien Rampak',
			'Pohgending Kawan',
			'Talibura',
			'Werang',
			'Parabon',
			'Dibee',
			'Batakte',
			'Kuanfatu',
			'Rambangaru',
			'Negeriagung',
			'Kota Agung',
			'Sibreh',
			'Alue Bilie',
			'Wiralaga Mulya',
			'Aimas',
			'Jembrana Subdistrict',
			'Pintupadang',
			'Gosong Telaga',
			'Blangkejeren',
			'Trenggalek',
			'Tayan',
			'Tapalang',
			'Tanjunggadang',
			'Tanjungampalu',
			'Tanggulangin',
			'Sungaikupang',
			'Sumber',
			'Srono',
			'Singaraja',
			'Simpur',
			'Sawangan',
			'Sampalan',
			'Rantaupulut',
			'Pulaupinang',
			'Probolinggo',
			'Pelabuhanratu',
			'Pasarbaru',
			'Panggul',
			'Palopo',
			'Pakuan Agung',
			'Pakel',
			'Pabuaran',
			'Ondong Siau',
			'Mempawah',
			'Mega',
			'Margahayukencana',
			'Mambi',
			'Lekebai',
			'Lais',
			'Labuan Bajo',
			'Pangkalan Kerinci',
			'Kopang Satu',
			'Kelapa',
			'Kawangu',
			'Kasiguncu',
			'Kambang',
			'Kaliori',
			'Jampang Kulon',
			'Jambi City',
			'Gunungkencana',
			'Gondang',
			'Bolangitang',
			'Blitar',
			'Baubau',
			'Ampana',
			'Belawang',
			'Ngamprah',
			'Simabur',
			'Kotawaikabubak',
			'Toroloji',
			'Gili Trawangan',
			'Kebonkai',
			'Sindangtamu',
			'Dawang',
			'Woja',
			'Tanjung Lubuk',
			'Trimuris',
			'Sumberan',
			'Gondang',
			'Rimo',
			'Mersak',
			'Seuneubok Bayu',
			'Keumala',
			'Geuleumpangminyeuk',
			'Tiro',
			'Seunudon',
			'Blang Ara',
			'Sungi Liput',
			'Sungaiturak',
			'Angkimang',
			'Barimba',
			'Pontianak Barat',
			'Botawa',
			'Ullong',
			'Sipagimbar',
			'Reuleuet',
			'Kotanopan',
			'Air Bangis',
			'Wuluhan',
			'Wlingi',
			'Welahan',
			'Tombatu',
			'Tanggeung',
			'Lasusua',
			'Siwa',
			'Semamung',
			'Satui',
			'Saruaso',
			'Sakra',
			'Pedamaran',
			'Payakumbuh',
			'Pasuruan',
			'Padangcermin',
			'Mangaran',
			'Manding',
			'Lembar',
			'Larantuka',
			'Krian',
			'Kraksaan',
			'Kerek',
			'Kawangkoan',
			'Jogonalan',
			'Duri',
			'Depok',
			'Colomadu',
			'Cilawu',
			'Cibalong',
			'Cakke',
			'Buntok',
			'Bingintelok',
			'Bilungala',
			'Banyumas',
			'Bantul',
			'Ayamaru',
			'Meral',
			'Maroanging',
			'Gubengairlangga',
			'Oelamasi',
			'Demta',
			'Lueng Putu',
			'Simpang Tiga Redelong',
			'Kloncing',
			'Olehsari',
			'Manola',
			'Harapankarya',
			'Arjasari',
			'Tebing',
			'Larangan',
			'Oeekam',
			'Ngalu',
			'Banda',
			'Samadua',
			'Pante Raja',
			'Sungaigampa',
			'Mapurujaya',
			'Ilaga',
			'Sumohai',
			'Arosuka',
			'Ujungbatu',
			'Wonosari',
			'Wangon',
			'Waigete',
			'Ujoh Bilang',
			'Turen',
			'Tanahwangko',
			'Sungaiduri',
			'Sukadana',
			'Soe',
			'Slawi',
			'Singosari',
			'Sidareja',
			'Selorejo',
			'Sidayu',
			'Sampang',
			'Rantau Panjang',
			'Pule',
			'Peranap',
			'Parung',
			'Paciran',
			'Muara Lembu',
			'Makbon',
			'Magelang',
			'Kedaton',
			'Karangrejo',
			'Kalipucang',
			'Kalabahi',
			'Jeneponto',
			'Jaten',
			'Serkos',
			'Glumbang',
			'Garut',
			'Gandusari',
			'Gandrungmangu',
			'Cipatujah',
			'Cibaliung',
			'Camming',
			'Brondong',
			'Bogor',
			'Birayang',
			'Benteng',
			'Belakangpadang',
			'Batu Panjang',
			'Batu',
			'Baso',
			'Walenrang',
			'Waris',
			'Tutuyan',
			'Gunung Talang',
			'Lokokrangan',
			'Pane Tongah',
			'Kopibera',
			'Prigi',
			'Loboae',
			'Watugolok',
			'Waiwadan',
			'Topo',
			'Danauparis',
			'Pantai Ceuremen',
			'Basarang',
			'Anjirmuara',
			'Juai',
			'Batuampar',
			'Sungai Raya',
			'Langsa',
			'Lampuyang',
			'Lamno',
			'Gunung Tua',
			'Binanga',
			'Wedarijaksa',
			'Ketapang',
			'Tiom',
			'Tigi',
			'Temanggung',
			'Teluk Dalam',
			'Tanjungsakti',
			'Tanjungraja',
			'Tanjung Lubuk',
			'Surian',
			'Sungai Penuh',
			'Sukanagara',
			'Subang',
			'Sesayap',
			'Sebuku',
			'Sawang',
			'Kore',
			'Pasongsongan',
			'Pangkalan Kasai',
			'Pangkalanbuun',
			'Pamotan',
			'Pameungpeuk',
			'Nangah Pinoh',
			'Muararupit',
			'Marisa',
			'Maria',
			'Makale',
			'Likupang',
			'Langnga',
			'Komodo',
			'Koba',
			'Karangnunggal',
			'Karangan',
			'Kabare',
			'Jatiroto',
			'Dobo',
			'Curug',
			'Cisewu',
			'Ciputat',
			'Cihaurbeuti',
			'Buaran',
			'Bosnik',
			'Mentarang',
			'Arosbaya',
			'Tobadak',
			'Arso',
			'Onolimbu',
			'Kototujuh',
			'Petoosang',
			'Bandaragung',
			'Tanjungjaya',
			'Ciriri',
			'Sumberan',
			'Neonmat',
			'Maritaing',
			'Uwa',
			'Bofuer',
			'Guay',
			'Suak Bakung',
			'Lauke',
			'Pegasing',
			'Simpang Teritit',
			'Long Loreh',
			'Kayan Hulu',
			'Jenamas',
			'Pudi',
			'Cemaga',
			'Sugapa',
			'Meyado',
			'Krucil Timur',
			'Pagatan',
			'Tarutung',
			'Parapat',
			'Muara Soma',
			'Bayeuen',
			'Bakungan',
			'Wori',
			'Teminabuan',
			'Tarempa',
			'Tanjunggadang',
			'Sumenep',
			'Subang',
			'Sinjai',
			'Simpang',
			'Semboro Lor',
			'Selogiri',
			'Rembangan',
			'Rambipuji',
			'Pundong',
			'Payunga',
			'Palembang',
			'Padangulaktanding',
			'Pacitan',
			'Natar',
			'Namrole',
			'Munse',
			'Mojoagung',
			'Melati',
			'Mangaran',
			'Majene',
			'Lodoyo',
			'Liwa',
			'Karawang',
			'Kepahiang',
			'Kahala',
			'Dulanpokpok',
			'Donggala',
			'Bukittinggi',
			'Bondowoso',
			'Batuputih',
			'Banjarejo',
			'Agats',
			'Tabunganen',
			'Tanggunggunung',
			'Amahusu',
			'Maurole',
			'Pringgabaya',
			'Waelengga',
			'Sukamaju',
			'Pasirgeleng',
			'Kota Ternate',
			'Gununglimbangan',
			'Pananjung',
			'Solokuro',
			'Busalangga',
			'Uitao',
			'Oekamusa',
			'Klubagolit',
			'Mindiptana',
			'Lawesigalagala Timur',
			'Jagongjeget',
			'Caloue',
			'Lambaro',
			'Blangpulo',
			'Sumbul',
			'Gambut',
			'Senayang',
			'Ngasem',
			'Oksibil',
			'Lotu',
			'Weriagar',
			'Modisi',
			'Tongging',
			'Ambarita',
			'Waimangura',
			'Trucuk',
			'Tilamuta',
			'Tembilahan',
			'Talang Ubi',
			'Sungairotan',
			'Sungaigerong',
			'Sonder',
			'Singkawang',
			'Serpong',
			'Sekupang',
			'Sarolangun',
			'Sanana',
			'Rainis',
			'Purwakarta',
			'Pelau',
			'Pelabuhan Dagang',
			'Parigi',
			'Panti',
			'Naringgul',
			'Mranggen',
			'Metro',
			'Masbagik',
			'Mandor',
			'Mandalawangi',
			'Luwuk',
			'Lubukalung',
			'Leksula',
			'Kuala Tungkal',
			'Korido',
			'Kokar',
			'Ketapang',
			'Kendal',
			'Kalangbret',
			'Jepara',
			'Gebog',
			'Ganding',
			'Dayeuhluhur',
			'Cibingbin',
			'Campurdarat',
			'Bungkal',
			'Bojongmanik Girang',
			'Bojonegoro',
			'Batangan',
			'Bambanglipuro',
			'Sidenreng',
			'Kananggar',
			'Genyem',
			'Rejanegara',
			'Jambi Baru',
			'Curug',
			'Bentung',
			'Songgon',
			'Kabukarudi',
			'Detusoko',
			'Bunisari',
			'Batutua',
			'Kebar',
			'Teluk Pinang',
			'Ngkan',
			'Kute Panang',
			'Blang Dalam',
			'Sarulla',
			'Tambarangan',
			'Guruafin',
			'Tungoo',
			'Laworo',
			'Tiku',
			'Tanjungtiram',
			'Lintongnihuta',
			'Labuhan Deli',
			'Kruengraya',
			'Binjai',
			'Wonosobo',
			'Weleri',
			'Wamena',
			'Surulangun Rawas',
			'Sungai Salak',
			'Sungaibali',
			'Sumberjati',
			'Sumbawa Besar',
			'Solok',
			'Singkarak',
			'Simpangempat',
			'Benua Baru',
			'Ratahan',
			'Rasiei',
			'Rantau Panjang',
			'Praya',
			'Pendolo',
			'Pasirian',
			'Panjang',
			'Palembaian',
			'Mulia',
			'Muara Sabak',
			'Menggala',
			'Borong',
			'Mayang',
			'Majenang',
			'Lubukbergalung',
			'Long Pujungan',
			'Letung',
			'Lelogama',
			'Lasem',
			'Kotabaru Hilir',
			'Kotaagung',
			'Kadugedong',
			'Jampang Tengah',
			'Jakarta',
			'Alung',
			'Godean',
			'Kempo',
			'Denpasar',
			'Cileungsir',
			'Cijulang',
			'Cijeungjing',
			'Caringin',
			'Bungbulang',
			'Bengkalis',
			'Bejubang Dua',
			'Bangil',
			'Bancar',
			'Balauring',
			'Punggur',
			'Sidomulyo',
			'Lempokasi',
			'Bilalang',
			'Cikupa',
			'Batam',
			'Bungalawang',
			'Onan Ganjang Satu',
			'Pekutatan',
			'Sambelia',
			'Mbay',
			'Senadan',
			'Arbais',
			'Tenggulun',
			'Sekatak Bengara',
			'Telagaselaba',
			'Suwawa',
			'Sendawar',
			'Tualangcut',
			'Muara Siberut',
			'Langgapayung',
			'Gunungsitoli',
			'Bireun',
			'Balige',
			'Wedi',
			'Weda',
			'Wates',
			'Watampone',
			'Wanareja',
			'Waipukang',
			'Ungaran',
			'Tuban',
			'Taraju',
			'Tanjungbatu',
			'Tanggul',
			'Takokak',
			'Tais',
			'Sokaraja',
			'Soreang',
			'Simpang',
			'Semilat',
			'Sekadau',
			'Samboja',
			'Samarinda',
			'Ransiki',
			'Rajadesa',
			'Poso',
			'Pasir Pengaraian',
			'Passi',
			'Pamarican',
			'Pagat',
			'Muntilan',
			'Mertoyudan',
			'Menjalin',
			'Martapura',
			'Lasehao',
			'Daik Lingga',
			'Kotaagung',
			'Kefamenanu',
			'Kairatu',
			'Japah',
			'Gunungbatubesar',
			'Gombong',
			'Genteng',
			'Kalumpang',
			'Enrekang',
			'Demak',
			'Cimerak',
			'Cimaragas',
			'Cempaka',
			'Bukitkemuning',
			'Besuki Satu',
			'Belopa',
			'Banggai',
			'Tulehu',
			'Bulubulu',
			'Ngemplak',
			'Jayapura',
			'Kromengan',
			'Semarapura',
			'Aelande',
			'Kamalaputi',
			'Cipadung Timur',
			'Jetis',
			'Lakbok',
			'Ayotupas',
			'Kampungraja',
			'Wurigelebur',
			'Luan Balu',
			'Tanah Merah',
			'Long Peso',
			'Sungai Nyamuk',
			'Handilenam',
			'Pasararba',
			'Bakau',
			'Sabang Mawang Barat',
			'Stabat',
			'Sidikalang',
			'Percut',
			'Pakkat',
			'Banda Aceh',
			'Wongsorejo',
			'Waigama',
			'Tomu',
			'Tomohon',
			'Talawi',
			'Sumberpucung',
			'Seget',
			'Sanggau',
			'Salatiga',
			'Randuagung',
			'Rancah',
			'Puruk Cahu',
			'Pineleng',
			'Pototano',
			'Pantaihambawang',
			'Pandak',
			'Pagerageung',
			'Noemuti',
			'Maninjau',
			'Malili',
			'Lubuklinggau',
			'Limboto',
			'Lahat',
			'Kumurkek',
			'Kuma',
			'Kudus',
			'Kubu',
			'Kota Tengah',
			'Karangsembung',
			'Karanganyar',
			'Kampak',
			'Jember',
			'Jakenan',
			'Hila',
			'Curup',
			'Bobong',
			'Bitung',
			'Barabai',
			'Baekrajan',
			'Babana',
			'Andoolo',
			'Ambuten',
			'Naisano Dua',
			'Sasi',
			'Ogotua',
			'Beuma',
			'Olafulihaa',
			'Kupang',
			'Lolayan',
			'Basa',
			'Busungbiu',
			'Iteng',
			'Karyadharma',
			'Senggapi',
			'Pasuruan',
			'Sajir',
			'Kertajadi',
			'Jamanis',
			'Sidowayah Kidul',
			'Sekotong Tengah',
			'Bara Datu',
			'Waghete',
			'Beoga',
			'Pulopaya',
			'Meukek',
			'Kemil',
			'Blang Ara',
			'Bulahblangaro',
			'Lawepakam',
			'Kuala Baru Sungai',
			'Sipoholon',
			'Teritip',
			'Siulak',
			'Biscoop',
			'Sibolga',
			'Sabang',
			'Perbaungan',
			'Lhokseumawe',
			'Lhokkruet',
			'Barus',
			'Waru',
			'Sumbermanjing Wetan',
			'Srandakan',
			'Siak Sri Indrapura',
			'Serang',
			'Sepatan',
			'Semongkat',
			'Sekayu',
			'Sekernan',
			'Sepulu',
			'Palakka',
			'Pakuniran',
			'Paga',
			'Namlea',
			'Muncar',
			'Muara Enim',
			'Molobulahe',
			'Mlonggo',
			'Maumere',
			'Loa Janan',
			'Lembang',
			'Lela',
			'Langara',
			'Kwantisore',
			'Kotabesi',
			'Kertosono',
			'Kepi',
			'Gunungmenang',
			'Gulukguluk',
			'Gampang',
			'Essang',
			'Dasuk',
			'Cerenti',
			'Blora',
			'Banjarnegara',
			'Ansus',
			'Kali',
			'Padangsappa',
			'Kotabaru',
			'Gatak',
			'Gambarsari',
			'Poigar',
			'Duda',
			'Penataban Krajan Satu',
			'Bedugul',
			'Kotatua',
			'Tanjung Mas',
			'Banjar Mandung',
			'Tambolaka',
			'Karang Daye',
			'Totumbang',
			'Malahar',
			'Anyar',
			'Mataloko',
			'Kalirejo',
			'Pulaupanggung',
			'Argasari',
			'Lovina',
			'Rembang',
			'Gedangan',
			'Eban',
			'Oinlasi',
			'Loang',
			'Bilogai',
			'Sawoi',
			'Rusip',
			'Pendawanbaru',
			'Sungaipuntik',
			'Kuala Pembuang',
			'Petani',
			'Taman',
			'Takengon',
			'Panyambungan',
			'Meureubo',
			'Bandar',
			'Waikabubak',
			'Terawas',
			'Tehoru',
			'Talun',
			'Sumberbaru',
			'Sewon',
			'Saronggi',
			'Sarmi',
			'Rangkasbitung',
			'Pulauberingin',
			'Puger',
			'Peninjawan',
			'Parigi',
			'Pangkalpinang',
			'Pompanua',
			'Pakenjeng',
			'Muara Tembesi',
			'Maros',
			'Manismata',
			'Lubukgadang',
			'Ledokombo',
			'Kragan',
			'Kenali',
			'Kawalu',
			'Karangpawitan',
			'Kadupandak',
			'Jorong',
			'Jatirogo',
			'Jabung',
			'Galunggalung',
			'Ciracap',
			'Cikelet',
			'Bikondini',
			'Batumandi',
			'Baserah',
			'Barru',
			'Babat',
			'Arjasa',
			'Aluhaluh',
			'Seba',
			'Tangantangancut',
			'Belajen',
			'Sidorejo',
			'Sumandang',
			'Sinarjaya',
			'Sukoharjo Satu',
			'Biudukfoho',
			'Wairiang',
			'Bontang Baru',
			'Bambol',
			'Trienggadeng',
			'Long Layu',
			'Pasarujungbatu',
			'Dufa-Dufa',
			'Burmeso',
			'Buduan Ghejeh',
		],
	},
	'Iran, Islamic Rep. of': {
		country: 'Iran, Islamic Rep. of',
		cities: [
			'Sirjan',
			'Shūsh',
			'Shādegān',
			'Ravānsar',
			'Farrokh Shahr',
			'Naqadeh',
			'Jājarm',
			'Ārān Bīdgol',
			'Abadeh',
			'Tīrān',
			'Dorcheh Pīāz',
			'Āq Qalā',
			'Nīr',
			'Kalāt-e Nāderī',
			'Yasuj',
			'Sīāhkal',
			'Shahr-e Kord',
			'Shabestar',
			'Sarāb',
			'Oshnavīyeh',
			'Nā’īn',
			'Hamadān',
			'Farīmān',
			'Şafāshahr',
			'Borāzjān',
			'Ahram',
			'Eslāmshahr',
			'Shahrak-e Bākharz',
			'Jīroft',
			'Sonqor',
			'Rasht',
			'Poldasht',
			'Orūmīyeh',
			'Malāyer',
			'Kangāvar',
			'Īlām',
			'Gālīkesh',
			'Fereydūnshahr',
			'Eqlīd',
			'Dāmghān',
			'Bū’īn Zahrā',
			'Behshahr',
			'Bandar-e Gaz',
			'Bāft',
			'Bābol',
			'Zahedan',
			'Zābolī',
			'Eslāmābād',
			'Āq Qāyeh',
			'Pā’īn-e Bāzār-e Rūdbār',
			'Zarand',
			'sedeyen-e Yek',
			'Sāveh',
			'Reẕvānshahr',
			'Rāmhormoz',
			'Qom',
			'Qal‘eh Ganj',
			'Marvdasht',
			'Malekān',
			'Lordegān',
			'Joghtāy',
			'Khārk',
			'Galūgāh',
			'Bīrjand',
			'Bonāb',
			'Sūrān',
			'Bostānābād',
			'Kahrīz',
			'Qarchak',
			'Hashtpar',
			'Shahr-e Herāt',
			'Shaft',
			'Salmās',
			'Pol-e Sefīd',
			'Khorramabad',
			'Kāzerūn',
			'Jongīyeh',
			'Gorgān',
			'Ferdows',
			'Farāshband',
			'Dargaz',
			'Bīleh Savār',
			'Takāb',
			'Sharīfābād',
			'Soleh Bon',
			'Neyshābūr',
			'Nashtārūd',
			'Mollās̄ānī',
			'Manūjān',
			'Kūhdasht',
			'Dīvāndarreh',
			'Āstāneh-ye Ashrafīyeh',
			'Āshtīān',
			'Ardestān',
			'Najafābād',
			'Şowme‘eh Sarā',
			'Sardasht',
			'Roshtkhvār',
			'Manjīl',
			'Mahābād',
			'Khalkhāl',
			'Karaj',
			'Haftkel',
			'Fereydūn Kenār',
			'Fasā',
			'Fāmenīn',
			'Dezful',
			'Chādegān',
			'Bandar-e Māhshahr',
			'Asadīyeh',
			'Torbat-e Jām',
			'Qā’em Shahr',
			'Rey',
			'Gomīshān',
			'Ziabar (Gaskar)',
			'Vasīān',
			'Pāveh',
			'Omīdīyeh',
			'Mīāndoāb',
			'Chelgard',
			'Bam',
			'Andīmeshk',
			'‘Anbarābād',
			'Kūh Sefīd',
			'Rehnān',
			'Māmūnīyeh',
			'Āz̄arshahr',
			'Tāzehābād',
			'Kāshān',
			'Dalgān',
			'Boshrūyeh',
			'Pādegān-e Manjīl',
			'Tonekābon',
			'Semnan',
			'Sardasht',
			'Sarāb-e Dūreh',
			'Rāvar',
			'Mohr',
			'Kīsh',
			'Gīlān-e Gharb',
			'pamas',
			'Shahrak-e Kūlūrī',
			'Ardabīl',
			'Ābdānān',
			'Eqbālīyeh',
			'Pasragad Branch',
			'Shāhīn Dezh',
			'Neyrīz',
			'Marāgheh',
			'Bandar-e Genāveh',
			'Fūman',
			'Borūjerd',
			'Akbarābād',
			'Ābyek',
			'Sarvābād',
			'Sabzevar',
			'Sa‘ādat Shahr',
			'Poldokhtar',
			'Nowshahr',
			'Naz̧arābād',
			'Khowy',
			'Khowrmūj',
			'Kujuvar',
			'Eyvān',
			'Fahraj',
			'Dowlatābād',
			'Darreh Shahr',
			'Bastak',
			'Arzū’īyeh',
			'Sarakhs',
			'Chabahar',
			'Tehran',
			'Solţānābād',
			'Rāmsar',
			'Qarnābād',
			'Meshgīn Shahr',
			'Mehrān',
			'Kāshmar',
			'Kangān',
			'Farmahīn',
			'Chenārān',
			'Bandar-e ‘Asalūyeh',
			'Pāsārgād',
			'Kelīshād va Sūdarjān',
			'Dowlatābād',
			'Abrīsham',
			'Shahrak-e Emām Ḩasan',
			'Bavanat',
			'Sorkheh',
			'Shūshtar',
			'Sarābleh',
			'Narmāshīr',
			'Qeshm',
			'Gonābād',
			'Deyr',
			'Dehgolān',
			'Bījār',
			'Bāfq',
			'Hashtrūd',
			'Arsanjān',
			'Abhar',
			"'Abās Ābād",
			'Isfahan',
			'Konārak',
			'Kavār',
			'Torbat-e Ḩeydarīyeh',
			'Tabriz',
			'Shahr-e Bābak',
			'Semīrom',
			'Saqqez',
			'Rābor',
			'Qaşr-e Shīrīn',
			'Rūdān',
			'Qal‘eh-ye Khvājeh',
			'Sarpol-e Z̄ahāb',
			'Neqāb',
			'Mashhad',
			'Malārd',
			'Hashtgerd',
			'Dorūd',
			'Bandar-e Lengeh',
			'Bābolsar',
			'Anūch',
			'Abadan',
			'Tajrīsh',
			'Şaḩneh',
			'Namīn',
			'Līkak',
			'Kermanshah',
			'Gāvbandī',
			'Garmsār',
			'Dehāqān',
			'Bāneh',
			'Asadābād',
			'Bozghān',
			'Āzādshahr',
			'Tūyserkān',
			'Tākestān',
			'Taft',
			'Shīrvān',
			'Shalamzār',
			'Sarvestān',
			'Sanandaj',
			'Robāţ Karīm',
			'Shahr-e Qods',
			'Pārsābād',
			'Khomeyn',
			'Javānrūd',
			'Estahbān',
			'Dehdasht',
			'Būkān',
			'Borūjen',
			'Bojnūrd',
			'Bāsht',
			'Shahrīār',
			'Aghajari',
			'Shahrak-e Pābedānā',
			'Ţāleqān',
			'Qarah Āghāj',
			'Zarrīnābād',
			'Ţorqabeh',
			'Qorveh',
			'Khomārlū',
			'Hoveyzeh',
			'Bandar-e Torkaman',
			'Aznā',
			'Falāvarjān',
			'Nehbandān',
			'Iranshahr',
			'Tabas',
			'Arakvāz-e Malekshāhī',
			'Mīāneh',
			'Bardsīr',
			'Kord Kūy',
			'Kabūdarāhang',
			'Jask',
			'Esfarāyen',
			'Bāgh-e Malek',
			'Āstārā',
			'Zarrīn Shahr',
			'Shahreẕā',
			'Javānrūd',
			'Zanjān',
			'Varāmīn',
			'Sūrak',
			'Sari',
			'Shahr-e Qadīm-e Lār',
			'Khvānsār',
			'Kalāleh',
			'Garmeh',
			'Fārsān',
			'Sūsangerd',
			'Dārān',
			'Dārāb',
			'Bandar Abbas',
			'Aznā',
			'Āmol',
			'Ahar',
			'Showţ',
			'Zābol',
			'Tafresh',
			'Rūdsar',
			'Masjed Soleymān',
			'Marand',
			'Māhneshān',
			'Lamerd',
			'Germī',
			'Fīrūzābād',
			'Feyẕābād',
			'Anār',
			'Ajab Shīr',
			'Tāybād',
			'Nīkshahr',
			'Dūst Moḩammad Khān',
			'Nūrābād',
			'Qazvin',
			'Nūrābād',
			'Nahāvand',
			'Marīvān',
			'Maḩmūdābād',
			'Komījān',
			'Khondāb',
			'Jam',
			'Jahrom',
			'Sīsakht',
			'Chālūs',
			'Behbahān',
			'Arāk',
			'Mobārakeh',
			'Famast',
			'Pīshvā',
			'Hendījān',
			'Ḩājjīābād',
			'Eshtehārd',
			'Dogonbadan',
			'Alīgūdarz',
			'Aliabad-e Katul',
			'Ahvaz',
			'Ābyek',
			'Shāhīn Shahr',
			'Khāsh',
			'Shahre Jadide Andisheh',
			'Ţāleb ābād',
			'Qūchān',
			'Qohūrd-e ‘Olyā',
			'Qarah Ẕīā’ od Dīn',
			'Qā’en',
			'Piranshahr',
			'Mahrīz',
			'Kūhbanān',
			'Khorramshahr',
			'Bandar-e Khamīr',
			'Kerman',
			'Kerend-e Gharb',
			'Bajestān',
			'Shāzand',
			'Ardal',
			'Kahnūj',
			'Bahābād',
			'Īstgāh-e Rāh Āhan-e Garmsār',
			'Yazd',
			'Seyah Cheshmeh',
			'Sarbīsheh',
			'Moḩammadābād',
			'Langarūd',
			'Lālī',
			'Khonj',
			'Fārūj',
			'Bandar-e Anzalī',
			'Aleshtar',
			'Abarkūh',
			'Zehak',
			'Varazqān',
			'Shiraz',
			'Rāmīān',
			'Oskū',
			'Nekā',
			'Maku',
			'Herīs',
			'Fāryāb',
			'Shahrud',
			'Damāvand',
			'Māsāl',
			'Sepīdān',
			'Khomeynī Shahr',
			'Rāsak',
			'Khvāf',
			'Ḩājjīābād',
			'Mahdishahr',
			'Bandar-e Emam Khomeyni',
			'Taft',
			'Rāmshīr',
			'Qīr',
			'Naţanz',
			'Maşīrī',
			'Khūr',
			'Kherāmeh',
			'Fannūj',
			'Eslāmābād-e Gharb',
			'Bushehr',
			'Āshkhāneh',
			'Amlash',
			'Alvand',
			'Khorramdarreh',
			'Nūr',
			'Sarāyān',
			'Razan',
			'Rafsanjān',
			'Noşratābād',
			'Khalīlābād',
			'Kāmyārān',
			'Gonbad-e Kāvūs',
			'Delījān',
			'Bardaskan',
			'Bandar-e Deylam',
			'Ārādān',
			'Alvand',
			'Alavijeh',
			'Qaşr-e Qand',
			'Pākdasht',
			'Gīvī',
			'Āzādshahr',
			'Omīdcheh',
			'Marāveh Tappeh',
			'Mīnūdasht',
			'Mīnāb',
			'Meybod',
			'Maḩallāt',
			'Lāhījān',
			'Kaleybar',
			'Jūybār',
			'Harsīn',
			'Ḩājjīābād',
			'Golpāyegān',
			'Gerāsh',
			'Fīrūzkūh',
			'Dehlorān',
			'Bahār',
			'Ardakān',
			'Ābbar',
			'Qahderījān',
		],
	},
	Iraq: {
		country: 'Iraq',
		cities: [
			'Zaxo',
			'Baynjiwayn',
			'Baghdad',
			'Ar Riyāḑ',
			'An Nu‘mānīyah',
			'Al Fallūjah',
			'Al ‘Amārah',
			'Al Başrah al Qadīmah',
			'Sāmarrā’',
			'Ṟuwandiz',
			'Koysinceq',
			'Hīt',
			'Batifa',
			'Baladrūz',
			'As Sulaymānīyah',
			'As Salmān',
			'Al Maymūnah',
			'Al Ḩayy',
			'Dare Tû',
			'‘Anat al Qadīmah',
			'Al Kūt',
			'Mandalī',
			'Mosul',
			'Basrah',
			'Kasnazān',
			'Al Jabāyish',
			'As Samawah',
			'Nasiriyah',
			'‘Anah',
			'Nāḩiyat ‘Atbah',
			'Qal‘at Şāliḩ',
			'Ḩalabja',
			'Xebat',
			'Al Miqdādīyah',
			'Al Ḩamzah',
			'Al ‘Amādīyah',
			'Sūq ash Shuyūkh',
			'Mexmur',
			'Ash Shāmīyah',
			'Ramadi',
			'Al Maḩmūdīyah',
			'Al ‘Azīzīyah',
			'Abū Ghurayb',
			'Rāwah',
			'Mawet',
			'‘Aynkāwah',
			'Al Ba‘āj',
			'Ad Dujayl',
			'AlZwya',
			'Hawe Ben Alean',
			'Sīnah',
			'Khānaqīn',
			'Dihok',
			'Nāḩiyat al Fuhūd',
			'Halshaw',
			'Nāḩiyat Hīrān',
			'Aş Şuwayrah',
			'Khāliş',
			'Hajiawa',
			'Tallkayf',
			'Bayjī',
			'Al Mishkhāb',
			'Ṟaniye',
			'Al Fāw',
			'Abī al Khaşīb',
			'Zāwītah',
			'Tall ‘Afar',
			'Bāmarnī',
			'Al Khāliş',
			'Ar Rifā‘ī',
			'Al Qayyārah',
			'‘Ayn Tamr',
			'Seyid Sadiq',
			'Qeredagh',
			'Qeładizê',
			'Mêrgasur',
			'Aţ Ţārmīyah',
			'Ad Dīwānīyah',
			'Al Ḩawījah',
			'Sinjār',
			'Dāqūq',
			'Kirkuk',
			'Dibis',
			'Najaf',
			'Al Mawşil al Jadīdah',
			'Al Madīnah',
			'Nāḩiyat Baḩār',
			'Tikrīt',
			'Ḩadīthah',
			'Al Kaḩlā’',
			'Al ‘Awjah',
			'Ad Dawr',
			'Erbil',
			'Beḧirke',
			'Badrah',
			'Ash Shaţrah',
			'Qushtepe',
			'Kifrī',
			'Al Ḩaqlānīyah',
			'Dukan',
			'Al Qurnah',
			'Al Ḩaḑar',
			'Kānī Māsī',
			'Ar Ruţbah',
			'‘Aqrah',
			'Al Musayyib',
			'Al Maḩāwīl',
			'Derbendîxan',
			'Umm Qaşr',
			'Balad',
			'Nāḩiyat ash Shināfīyah',
			'Al Hindīyah',
			'Ibrāhīm al Khalīl',
			'Shaqlāwah',
			'Nāḩīyat Saddat al Hindīyah',
			'Al Ḩillah',
			'Al-Hamdaniya',
			'Jamjamāl',
			'Al Manādhirah',
			'Hayraw',
			'Karbala',
			'Derkar',
			'Al Hāshimīyah',
			'Ţūz Khūrmātū',
			'Saymayl',
			'Kelar',
			'Al ‘Abbāsī',
			'Hīrān',
			'Chwarta',
			'Baqubah',
			'Kufa',
			'Al Hārithah',
			'Ash Sharqāt',
			'Soran',
			'Ash Shaykhān',
			'‘Alī al Gharbī',
			'‘Afak',
			'Al Qā’im',
			'Az Zubayr',
			'Ar Rumaythah',
			'Imam Qasim',
			'Nahiyat Ghammas',
			'Choman',
			'Nāḩiyat Alī ash Sharqī',
		],
	},
	Ireland: {
		country: 'Ireland',
		cities: [
			'Graiguenamanagh',
			'Dungloe',
			'Carlow',
			'Ballinrobe',
			'Castletroy',
			'Termonfeckin',
			'Rosslare',
			'Ratoath',
			'New Ross',
			'Kilworth',
			'Dunshaughlin',
			'Dunboyne',
			'Castleblayney',
			'Ballincollig',
			'Ardee',
			'Beaumont',
			'Thurles',
			'Rathcoole',
			'Mitchelstown',
			'Newmarket on Fergus',
			'Mooncoin',
			'Letterkenny',
			'Kiltamagh',
			'Inishcrone',
			'Ferns',
			'Castlemartyr',
			'Carnew',
			'Boyle',
			'Ballybofey',
			'Firhouse',
			'Clonskeagh',
			'Glasnevin',
			'Claregalway',
			'Carrick-on-Shannon',
			'Belmullet',
			'Baldoyle',
			'Castletown',
			'Portraine',
			'Ballygerry',
			'Sixmilebridge',
			'Portlaoise',
			'Newtown Cunningham',
			'Newcastle West',
			'Maynooth',
			'Howth',
			'Crossmolina',
			'Cobh',
			'Cloyne',
			'Cherryville',
			'Prosperous',
			'Kilcullen',
			'Fethard',
			'Dollymount',
			'Cashel',
			'Caherconlish',
			'Blarney',
			'Ballygar',
			'Ballybunnion',
			'Eadestown',
			'Bonnybrook',
			'Darndale',
			'Balally',
			'Swords',
			'Swinford',
			'Portmarnock',
			'Muff',
			'Mallow',
			'Leixlip',
			'Kinlough',
			'Kanturk',
			'Glanmire',
			'Edgeworthstown',
			'Cavan',
			'Ballyjamesduff',
			'Jobstown',
			'Wexford',
			'Mountmellick',
			'Listowel',
			'Edenderry',
			'Dunlewy',
			'Dungarvan',
			'Dublin',
			'Drogheda',
			'Derrybeg',
			'Dalkey',
			'Crosshaven',
			'Clondalkin',
			'Clifden',
			'Cahersiveen',
			'Ballybay',
			'Athy',
			'Donnycarney',
			'Youghal',
			'Tullow',
			'Tuam',
			'Rathmines',
			'Kinsale',
			'Kingscourt',
			'Kilcock',
			'Cork',
			'Cabinteely',
			'Balrothery',
			'Ballysadare',
			'Bailieborough',
			'Askeaton',
			'Aghada',
			'Cabra',
			'Kentstown',
			'Skibbereen',
			'Roscommon',
			'An Muileann gCearr',
			'Millstreet',
			'Laytown',
			'Whitegate',
			'Waterford',
			'Sutton',
			'Oldcastle',
			'Newtown Trim',
			'Droichead Nua',
			'Longford',
			'Kells',
			'Celbridge',
			'Blackrock',
			'Blackrock',
			'Arklow',
			'Walkinstown',
			'Mulhurddart',
			'Cherry Orchard',
			'Foxford',
			'Dundrum',
			'Donnybrook',
			'Chapelizod',
			'Castledermot',
			'Cahir',
			'Ballyconnell',
			'Ballina',
			'Aughrim',
			'Adare',
			'Valleymount',
			'Sallynoggin',
			'Kinsealy-Drinan',
			'Westport',
			'Moville',
			'Tullyallen',
			'Tipperary',
			'Slane',
			'Rathnew',
			'Strandhill',
			'Galway',
			'Enniscorthy',
			'Donegal',
			'Clogherhead',
			'Claremorris',
			'Clane',
			'Carrigaline',
			'Ardnacrusha',
			'Kilmacanoge',
			'Coolock',
			'Ballylinan',
			'Johnstown',
			'Gort',
			'Dromiskin',
			'Callan',
			'Belturbet',
			'Ballyhaunis',
			'Bagenalstown',
			'Annacotty',
			'Castleconnell',
			'Kilpedder',
			'Ballinteer',
			'Newtownmountkennedy',
			'Tullamore',
			'Stamullin',
			'Sallins',
			'Ringsend',
			'Rathdrum',
			'Newcastle',
			'Nenagh',
			'Loughrea',
			'Greystones',
			'Fermoy',
			'Bray',
			'Athlone',
			'Oldbawn',
			'Greenhills',
			'Watergrasshill',
			'Thomastown',
			'An Ros',
			'Raphoe',
			'Monkstown',
			'Lucan',
			'Gweedore',
			'Clones',
			'Clara',
			'Castleisland',
			'Bandon',
			'Ballymote',
			'Ballyboden',
			'South Dublin',
			'Palmerstown',
			'Terenure',
			'Navan',
			'Mount Merrion',
			'Kilrush',
			'Kenmare',
			'Ennistimon',
			'Dunmanway',
			'Carrigtwohill',
			'Carlingford',
			'Birr',
			'Ballyshannon',
			'Ballivor',
			'Kilquade',
			'Buncrana',
			'Sandymount',
			'Piltown',
			'Oranmore',
			'Midleton',
			'Courtown',
			'Banagher',
			'Abbeyleix',
			'Nenagh Bridge',
			'Drumcondra',
			'Donaghmede',
			'Killester',
			'Confey',
			'Trim',
			'Templemore',
			'Portlaw',
			'Naas',
			'Moate',
			'Lusk',
			'Dún Laoghaire',
			'Duleek',
			'Convoy',
			'Collooney',
			'Cluain Meala',
			'Charlestown',
			'Athboy',
			'Ballyfermot',
			'Templeogue',
			'Wicklow',
			'Tralee',
			'Rathcormac',
			'Old Kilcullen',
			'Killumney',
			'Castleknock',
			'Ballymahon',
			'Oughterard',
			'Booterstown',
			'Bayside',
			'Tobercurry',
			'Saggart',
			'Rathangan',
			'Oughterard',
			'Manorhamilton',
			'Limerick',
			'Kilkenny',
			'Dundalk',
			'Derrinturn',
			'Bunclody',
			'Athgarvan',
			'Rathgar',
			'Rathfarnham',
			'Rathdowney',
			'Mullagh',
			'Moroe',
			'Loughlinstown',
			'Enniskerry',
			'Dunmore East',
			'Dunleer',
			'Dingle',
			'Cootehill',
			'Castlerea',
			'Carrickmacross',
			'Bantry',
			'Athenry',
			'Skerries',
			'Ferbane',
			'Donabate',
			'Castlebellingham',
			'Castlebar',
			'Blessington',
			'Ballaghaderreen',
			'Shannon',
			'Kill',
			'Fairview',
			'Courtbrack',
			'Trá Mhór',
			'Rathwire',
			'Portarlington',
			'Monasterevin',
			'Kilmallock',
			'Bearna',
			'Baltinglass',
			'Moate',
			'Milltown',
			'Urlingford',
			'Sligo',
			'Shankill',
			'Rochfortbridge',
			'Rathkeale',
			'Raheny',
			'Portumna',
			'Newport',
			'Moycullen',
			'Longwood',
			'Lanesborough',
			'Killorglin',
			'Killaloe',
			'Kilcoole',
			'Kilbeggan',
			'Finglas',
			'Enfield',
			'Charleville',
			'Castlecomer',
			'Carndonagh',
			'Ballyragget',
			'Abbeyfeale',
			'Ballinroad',
			'Hartstown',
			'Knocklyon',
			'Virginia',
			'Stradbally',
			'Roscrea',
			'Monaghan',
			'Killucan',
			'Crumlin',
			'Clonakilty',
			'Castlebridge',
			'Carrick-on-Suir',
			'Bundoran',
			'Ashbourne',
			'Sandyford',
			'Artane',
			'Ballymun',
			'Rialto',
			'Charlesland',
			'Moyross',
			'Ballisodare',
			'Tallaght',
			'Leifear',
			'Kinnegad',
			'Blanchardstown',
			'Ballina',
			'Little Bray',
			'Tower',
			'Daingean',
			'Malahide',
			'Cill Airne',
			'Ennis',
			'Croom',
			'Balbriggan',
			'Foxrock',
			'Toormakeady',
			'Mountrath',
			'Marino',
			'Gorey',
			'Douglas',
			'Castlepollard',
			'Ballinasloe',
			'Ashford',
			'Ramelton',
			'Passage West',
			'Moone',
			'Macroom',
			'Killybegs',
			'Kildare',
		],
	},
	'Isle of Man': {
		country: 'Isle of Man',
		cities: [
			'Lezayre',
			'Onchan',
			'Jurby',
			'Bride',
			'Kirkmichael',
			'Port Erin',
			'Douglas',
			'Kirk Braddan',
			'Peel',
			'Castletown',
			'Port Saint Mary',
			'Santon',
			'Ramsey',
			'Crosby',
			'Ballasalla',
			'Ballaugh',
			'Andreas',
			'Patrick',
			'Ballabeg',
			'Maughold',
			'Laxey',
		],
	},
	Israel: {
		country: 'Israel',
		cities: [
			'Sha‘alvim',
			'Qiryat Ye‘arim',
			'Qiryat Yam',
			'Nir Tsevi',
			'Nir Gallim',
			'Netanya',
			'Mazkeret Batya',
			'Kfar H̱abad',
			'Jisr ez Zarqā',
			'Ibṭīn',
			'Herzliya',
			'Gilat',
			'Fassūta',
			'Elishama‘',
			'Bīr el Maksūr',
			'Zetan',
			'Tūbā Zangarīya',
			'Sitriyya',
			'Shoresh',
			'Sde Neẖemya',
			'Rinnatya',
			'Ramat Yoẖanan',
			'Netivot',
			'Mishmeret',
			'Ma’or',
			'Li On',
			'Giv‘ati',
			'Geva‘ Karmel',
			'Gesher HaZiv',
			'Eṭ Ṭaiyiba',
			'‘Arugot',
			'Rumat Heib',
			'Beit Horon',
			'Tel Sheva‘',
			'Fīq',
			'Tsur Hadassa',
			'Jaffa',
			'Tifraẖ',
			'Savyon',
			'Ramat HaKovésh',
			'Qalansuwa',
			'Nehora',
			'Nahariyya',
			'Mitzpe Ramon',
			'Ganei Tikva',
			'Bet HaShitta',
			'Bat Yam',
			"Giv'on HaHadasha",
			'‘Ein Qunīya',
			'El‘azar',
			'Safed',
			'Yoqne‘am ‘Illit',
			'Metulla',
			'H̱ulda',
			'H̱osen',
			'H̱olon',
			'Even Shemu’el',
			'‘Eilabun',
			'Bu‘eina',
			'Bet Rabban',
			'Gilon',
			'El‘ad',
			'Tirabin al-Sana',
			'El Ghajar',
			'Pardesiyya',
			'Or Akiva',
			'Muqeibila',
			'Kfar Yasif',
			'Kafr Mandā',
			'H̱emed',
			'Biẕẕaron',
			'Bet Shemesh',
			'Kammon',
			'Laqiyya',
			'Hashmonaim',
			'Yoqneam',
			'Tselafon',
			'Rosh Pinna',
			'Rekhasim',
			'Ramat Yishay',
			'Migdal',
			'Menaẖemya',
			'Lod',
			'Ḥurfeish',
			'Daliyat al Karmel',
			'Bnei Brak',
			'Shibli',
			'Mazor',
			'H̱arish',
			'Nili',
			'Allon HaGalil',
			'Yehud-Monosson',
			"Be'er Ganim",
			'Mevasseret Tsiyyon',
			'Kfar Bilu',
			'‘Ein Māhil',
			'Ashdod',
			'Afula',
			'Midreshet Ben-Gurion',
			'West Jerusalem',
			'Aẖuzzat Baraq',
			'Karmé Yosef',
			'Nofit',
			'Manshīyet Zabda',
			'Basma',
			'Har Adar',
			'Yirkā',
			'Nazareth',
			'Mi‘ilyā',
			'Kfar Netter',
			'Kafr Miṣr',
			'Givatayim',
			'Bet Yitsẖaq',
			'Bet Dagan',
			'Berekhya',
			'Ayyelet HaShaẖar',
			'Hosha‘ya',
			'Mitspe Aviv',
			"Bu'ayna-Nujaydat",
			'Modi‘in Makkabbim Re‘ut',
			'Be’er Toviyya',
			'Sde H̱emed',
			'Ramot Menashe',
			'Ora',
			'Maghār',
			'Meisir',
			'Kefar Warburg',
			'Kafr Qāsim',
			'‘Ilūṭ',
			'Iksāl',
			'Giv‘at Brenner',
			'Bet Yehoshua‘',
			'Acre',
			'Bet H̱ashmonay',
			'Merkaz Shapira',
			'Umm Batin',
			'Bir Hadaj',
			'Al Buţayḩah',
			'Jerusalem',
			'Yagur',
			'Tirat Karmel',
			'Sha‘ar Efrayim',
			'Ramla',
			'Kiryat Gat',
			'Motsa ‘Illit',
			'Ma‘galim',
			'Kfar H̱ittim',
			'Judeida Makr',
			'H̱erut',
			'Ein Vered',
			'Deir Ḥannā',
			'Bet ‘Arif',
			'‘Azriqam',
			'Aẖituv',
			'Rahat',
			'Giv‘at Ela',
			'H̱amam',
			'Gan Ner',
			'Kuseifa',
			'Herzliya Pituah',
			'Shibli–Umm al-Ghanam',
			'Naẖal Teqoa‘',
			'‘Uzeir',
			'Tel Mond',
			'Tamra',
			'Ramat Gan',
			'Muṣmuṣ',
			'Mishmarot',
			'Kfar Saba',
			'Kafr Kammā',
			'Bet ‘Ezra',
			"Binyamina-Giv'at Ada",
			'Tel Qatsir',
			'Sde ‘Uziyyahu',
			'Ṣandala',
			'Er Reina',
			'‘En HaMifraẕ',
			'Dabbūrīya',
			'Bet Qama',
			'H̱aspin',
			'Moreshet',
			'Maale Iron',
			'Yesud HaMa‘ala',
			'Nā‘ūra',
			'Bi’na',
			'Segev Shalom',
			'Kfar NaOranim',
			'Kadima Zoran',
			"Kisra - Sume'a",
			'Yif‘at',
			'Neẖusha',
			'Maẕẕuva',
			'Kefar Vitqin',
			'Kfar Shmaryahu',
			'Elyakhin',
			'Dvira',
			'Moledet',
			'Aẖisamakh',
			'Lehavim',
			'Shimshit',
			'Kiryat Ono',
			'Tirat Yehuda',
			'Rishpon',
			'Qiryat Shmona',
			'Nein',
			'Naẕerat ‘Illit',
			'Kokhav Michael Sobell',
			'Niẕẕan',
			'‘En Ya‘aqov',
			'El Fureidīs',
			'Be’erotayim',
			'Batsra',
			'Ashkelon',
			'Arad',
			'Caesarea',
			'Nof Ayalon',
			'Tal El',
			'Zarzir',
			'Zalafa',
			'Bet Yitsẖaq Sha’ar H̱efer',
			'Qiryat Mal’akhi',
			'Qidron',
			'Poria Illit',
			'Mikhmoret',
			'Jaljūlya',
			'Haifa',
			'Hadera',
			'Eshta’ol',
			'‘En Dor',
			'Dimona',
			'Beersheba',
			'Azor',
			'Afiqim',
			'Bat H̱efer',
			'Giv‘at H̱ayyim Me’uẖad',
			'Kokhav HaShaẖar',
			'Tel Yitsẖaq',
			'Regba',
			'Ramat HaSharon',
			'Nesher',
			'Neẖalim',
			'Na‘an',
			'Majd el Kurūm',
			'Kfar Rosh HaNiqra',
			'Kfar Hess',
			'‘En Ayyala',
			'Be’er Ya‘aqov',
			'‘Amminadav',
			'Mitspe Netofa',
			'Zemer',
			'Ariel',
			'H̱ura',
			'Yad Rambam',
			'Tel Aviv',
			'Shave Tsiyyon',
			'Sderot',
			'Rishon LeTsiyyon',
			'Ofaqim',
			'Nevve Yaraq',
			'I‘billīn',
			'Giv‘at H̱ayyim Iẖud',
			'Burgata',
			'Abū Ghaush',
			'Yuvallim',
			'‘Adi',
			'Majdal Shams',
			'Tsofit',
			'Ness Ziona',
			'Mabbu‘im',
			'Ma‘agan Mikha’el',
			'Lahavot H̱aviva',
			'H̱atsor HaGelilit',
			'Gimzo',
			'Elon',
			'Bet H̱ilqiyya',
			'Bne Dror',
			'Bareqet',
			'Shoham',
			'Matan',
			'Yanuah Jat',
			'Zekharya',
			'Shtulim',
			'Shlomi',
			'Sha‘av',
			'Rummāna',
			"Ra'anana",
			'Matsliaẖ',
			'Kefar HaRo’e',
			'Giv‘at Ye‘arim',
			"Giv'at Shmuel",
			'Taiyiba',
			'Esh Sheikh Dannūn',
			'‘Ein Rāfa',
			'Bet Alfa',
			'Bnei Ayish',
			'Bāqa el Gharbīya',
			'Meitar',
			'‘Ar‘ara BaNegev',
			'Yinnon',
			'Yehud',
			'Yavné',
			'Umm el Faḥm',
			'Sakhnīn',
			'Ramat HaShofet',
			'Qiryat Motsqin',
			'‘Omer',
			'Nordiyya',
			'Karmi’el',
			'Bustan HaGalil',
			'Bet Gamli’él',
			'Beit Jann',
			'Atlit',
			'Katzrin',
			'Giv‘at Avni',
			'Lapid',
			"Na'ale",
			'Reshafim',
			'Qiryat Ata',
			'‘Olesh',
			'Megadim',
			'Qiryat ‘Eqron',
			'Kafr Barā',
			'Eilat',
			'Deir el Asad',
			'Be’er Ora',
			'Ka‘abiyya',
			'Zikhron Ya‘aqov',
			'Yashresh',
			'Tel ‘Adashim',
			'maalot Tarshīhā',
			'Tal Shaẖar',
			'Shefayim',
			'Sde Ya‘aqov',
			'Sde Warburg',
			'Riẖaniya',
			'Rama',
			'Poriyya',
			'Niẕẕané ‘Oz',
			'Nevve Yamin',
			'Kfar Pines',
			'‘Isfiyā',
			'Ashdot Ya‘aqov Iẖud',
			'‘Aramsha',
			'Bar Yuẖay',
			'Tsur Moshe',
			'Tsrufa',
			'Yāfā',
			'Sūlam',
			'Qiryat Tiv‘on',
			'Maggal',
			'Kfar Yona',
			'Jatt',
			'H̱agor',
			'Binyamina',
			'Bet Guvrin',
			'Raqqefet',
			'Kfar Veradim',
			'Modiin Ilit',
			'Kammana',
			'Yanuh-Jat',
			'Shefar‘am',
			'Petaẖ Tiqva',
			'Or Yehuda',
			'Nes Harim',
			'Mash‘én',
			'Kafr Qari‘',
			'HaZore‘im',
			'Gedera',
			'Gan Yavne',
			'Bne Re’em',
			'‘Ar‘ara',
			'Abū Sinān',
			'Sallama',
			'Harduf',
			'Rosh Ha‘Ayin',
			'Migdal Ha‘Emeq',
			'Mesillat Tsiyyon',
			'Kfar Tavor',
			'Kaukab Abū el Hījā',
			'Eṭ Ṭīra',
			'Ein Sarid',
			'Bet El‘azari',
			'Ben Zakkay',
			'Be’eri',
			'Kokhav Ya’ir',
			'Talmon',
			'Dolev',
			'Revava',
			'AlSayid Tribe',
			'Miẕpé Yeriẖo',
			'Yeroẖam',
			'Yavne’el',
			'Yarẖiv',
			'Yad Binyamin',
			'Tiberias',
			'Sājūr',
			'Revivim',
			'Mishmar Ha‘Emeq',
			'Mefallesim',
			'Kefar Sirkin',
			'Kfar Menaẖem',
			'Kafr Kannā',
			'Kabri',
			'Hod HaSharon',
			'Buqei‘a',
			'Basmat Ṭab‘ūn',
			'Baẖan',
			'Aẖi‘ezer',
			'‘Ein Naqūbā',
			'Khirbat Abu Tulūl',
			"Giv'ot Bar",
			'Tsur Itshak',
			'Al Khushnīyah',
			'Umm el Quṭūf',
			'Udim',
			'Timrat',
			'Segev',
			'Sdot Yam',
			'Reẖovot',
			'Qiryat Bialik',
			'Naḥf',
			'Mishmar HaNegev',
			'Kābūl',
			'Jūlis',
			'Jīsh',
			'Even Yehuda',
			'‘En Boqeq',
			'El Mazra‘a',
			'Bet Zayit',
			'Bet She’an',
			'Drijat',
		],
	},
	Italy: {
		country: 'Italy',
		cities: [
			'Daone',
			'Cusercoli',
			'Cunico',
			"Costa Sant'Abramo",
			'Corvara',
			'Corropoli',
			'Cormons',
			'Civitella Casanova',
			'Cirimido',
			"Cingia de' Botti",
			'Cheremule',
			'Cesano Maderno',
			'Cesa',
			'Cervino',
			'Cervia',
			'Cerveteri',
			'Cento',
			'Centallo',
			"Cazzano Sant'Andrea",
			'Cavriglia-Monastero',
			'Castignano',
			'Castenedolo',
			'Castelfranco Veneto',
			'Castagnole',
			'Casei',
			'Casalduni',
			'Carignano',
			'Carapelle Calvisio',
			'Capriati A Volturno',
			'Cappelle sul Tavo',
			'Canosa di Puglia',
			'Campoformido',
			'Camalò',
			'Caluso',
			'Calò',
			'Calcinaia',
			"Ca' degli Oppi",
			'Busseto',
			'Bucine',
			'Braone',
			'Borgoforte',
			'Bonavigo',
			'Bisegna',
			'Bibbiano',
			'Bevilacqua',
			'Bellona',
			'Bella Farnia',
			"Bastida de' Dossi",
			'Bassiano',
			'Barisciano',
			'Bargagli',
			'Baia',
			'Bagnoregio',
			'Avezzano',
			'Aversa',
			'Auronzo',
			'Asola',
			'Ariccia',
			'Ardea',
			'Arconate',
			'Apricena',
			'Aosta',
			'Antegnate',
			'Andorno Cacciorna',
			'Anacapri',
			'Alseno',
			'Albareto',
			'Acuto',
			'Acerenza',
			'Abriola',
			'Gruaro-Bagnara',
			'Mareno di Piave',
			'Col San Martino',
			'Montenars',
			'Caronno Pertusella',
			'Cassola',
			'Bellizzi',
			'Mombasiglio',
			'Monale',
			'Pontechianale',
			'Malgesso',
			'Odalengo Piccolo',
			'Castelnuovo Bozzente',
			'Castelveccana',
			'Peia',
			'Ome',
			'Zuclo',
			"Boschi Sant'Anna",
			'Roveredo di Guà',
			'Monfumo',
			'Gazzola',
			'Castelletto di Branduzzo',
			'Sovramonte',
			'Ceranesi',
			'Isole Tremiti',
			'Conflenti',
			'Fontainemore',
			'Daverio',
			'Gorla Minore',
			"Costa de' Nobili",
			'Mossano',
			'Monte Vidon Combatte',
			'Montelibretti',
			'Spineto Scrivia',
			'Perloz',
			"Villa d'Ogna",
			'Bondone',
			'Don',
			'Cassano Irpino',
			'Calco',
			'Bruzolo',
			'Cavallerleone',
			'Pagno',
			'Roaschia',
			"Castelletto d'Orba",
			'Zerba',
			'Bugnara',
			'Torre Pallavicina',
			'Ranica',
			'Azzano Mella',
			'Pizzale',
			'Varisella',
			'Bosia',
			'Sambuca',
			'Castellina Scalo',
			"Trinità d'Agultu",
			'Lodrone-Darzo',
			'Ponte San Marco',
			'Praticello di Gattatico',
			'Caseo',
			'San Mamete',
			'San Bernardo',
			'Lesna',
			'Catena',
			'Roccelletta',
			'Breo',
			'San Donnino',
			'Setteville',
			'Carobbio',
			'Parco Leonardo',
			'Antessano',
			'Taggì',
			'Sinigo',
			'Amodio-Massariola',
			'Fornase',
			'Stazione',
			'Pozzale-Case Nuove',
			'San Giuseppe le Prata-Cotropagno',
			'San Martino',
			'Belvedere-Piano Tavola',
			'Cavazzona',
			'Pianura Vomano',
			'Valmadonna',
			'Uggia-Pazzera-Bizzarrino',
			'Quattro Strade',
			'Gabella',
			'Villafranca',
			'Viadana',
			'Alberi',
			'Capitan Loreto',
			'Villassio',
			'Bargano',
			'Barone',
			'Buso',
			'Vescovado',
			'Rocchetta Nuova',
			'Borgo',
			'Vignola',
			'Santa Maria dei Sabbioni',
			'Santino',
			'Ponte di Nanto',
			'Marina',
			'Falerna Scalo',
			'Arbia',
			'Orbetello Scalo',
			'Mercato',
			'San Lorenzo',
			'Arcella',
			'Zambrone',
			'Zaccanopoli',
			'Villasmundo',
			'Umbriatico',
			'Torrenova',
			'Torano Castello',
			'Terranova Sappo Minulio',
			'Tarsia',
			'Sutera',
			'Sini',
			'Setzu',
			'Santo Stefano Quisquina',
			"Sant'Onofrio",
			'Santa Caterina Albanese',
			'San Pietro Apostolo',
			'Samo',
			'Ruinas',
			'Riace Marina',
			'Pioppo',
			'Perdaxius',
			'Pellaro',
			'Palizzi',
			'Nurri',
			'Nunziata',
			'Marinella',
			'Mandas',
			'Mandanici',
			'Longobardi',
			'Librizzi',
			'Isola di Capo Rizzuto',
			'Isnello',
			'Giardini-Naxos',
			'Galatro',
			'Domusnovas',
			'Cerda',
			'Cefalà Diana',
			"Cava d'Aliga",
			'Castellammare del Golfo',
			'Careri',
			'Campobello di Licata',
			'Caloveto',
			'Bovalino Superiore',
			'Bagheria',
			'Badolato Marina',
			'Augusta',
			'Amendolara',
			'Aiello Calabro',
			'Zepponami',
			'Zanica',
			'Vomero',
			'Vinchiaturo',
			'Villotta',
			"Villanova d'Ardenghi",
			'Villalago',
			'Vigo di Fassa',
			'Vigasio',
			'Vezzano Ligure',
			'Verdello',
			'Ventotene',
			'Vallemaio',
			'Vada',
			'Troviggiano',
			'Treviglio',
			'Tortorella',
			'Torrile',
			'Torre le Nocelle',
			'Tolmezzo',
			'Tollegno',
			'Tires',
			'Thiesi',
			'Telve',
			'Tavernelle',
			'Taglio',
			'Stroppiana',
			'Spresiano',
			'Spinete',
			'Le Casine-Perignano-Spinelli',
			'Sovere',
			'Solaro',
			'Sogliano Cavour',
			'Settimo Torinese',
			'Serino',
			'Seneghe',
			'Scandicci',
			'Sarezzo',
			'San Zenone al Po',
			'Celat-San Tomaso Agordino',
			"Sant'Antonio",
			"Sant'Angelo in Formis",
			'Santa Luce',
			'Santa Cristina Valgardena',
			'San Pietro Mosezzo',
			'San Pietro al Tanagro',
			'San Felice sul Panaro',
			'Sandrigo',
			'San Damiano al Colle',
			'San Benigno Canavese',
			'Sambuci',
			'Salorno',
			'Rosolina',
			'Rocca Sinibalda',
			'Roccaromana',
			'Roccafranca',
			'Rivoli',
			'Rivergaro',
			'Riardo',
			'Radda in Chianti',
			'Punta Marina',
			'Pulsano',
			'Presenzano',
			'Posina',
			'Portoferraio',
			'Pontinia',
			'Polvica',
			'Poggio Mirteto',
			'Pieve Porto Morone',
			'Pietrelcina',
			'Piantedo',
			'Peccioli',
			'Paupisi',
			'Passo di Mirabella-Pianopantano',
			'Piazza di Pandola',
			'Palazzo',
			'Paderno Franciacorta',
			'Ozzero',
			'Ostra',
			'Orbassano',
			'Novellara',
			'Nerviano',
			'Morsano',
			'Monterotondo',
			'Monterchi',
			'Monte Giberto',
			'Montaguto',
			'Molini',
			'Mirano',
			"Massa d'Albe-Corona",
			'Maruggio',
			'Manfredonia',
			'Mandello del Lario',
			'Magliano di Tenna',
			'Lumignano',
			'Lido',
			'Lesegno',
			'Lequile',
			'La Spezia',
			'Casinina',
			'Isola Vicentina',
			'Jolanda di Savoia',
			'Guastalla',
			'Guardiaregia',
			'Gualdo Cattaneo',
			'Graglia',
			'Giuggianello',
			'Ghislarengo',
			'Gais',
			'Forano',
			'Fontanella',
			'Follonica',
			'Foiano di Val Fortore',
			'Fiavè',
			'Faiano',
			'Serre',
			'Duronia',
			'Durazzano',
			'Dubino',
			'Drena',
			'Taio',
			'Cupello',
			'Crodo',
			'Creazzo',
			"Costigliole d'Asti",
			'Costermano',
			'Cornuda',
			'Corno Giovine',
			'Corio',
			'Cordenons',
			'Compiobbi',
			'Collobiano',
			"Colle d'Anchise",
			'Cocconato',
			'Cividate Camuno',
			'Cisliano',
			'Ciserano',
			'Chiavenna',
			'Cessalto',
			'Ceppaloni',
			'Cecchina',
			"Ca' Tiepolo",
			'Castilenti',
			'Castelseprio',
			'Castel San Vincenzo',
			'Castel San Pietro Romano',
			"Castelnovo ne'Monti",
			'Castelli',
			'Castelforte',
			'Castel di Sangro',
			'Castagnole delle Lanze',
			'Castagneto Po',
			'Pieve al Toppo',
			'Caselle Lurani',
			'Casalromano',
			'Casalmorano',
			'Caravonica',
			'Caprino',
			'Candiolo',
			'Camporgiano',
			'Campomorone',
			'Cala Gonone',
			'Cadè-Gaida',
			'Busso',
			'Bultei',
			'Bricherasio',
			'Brentonico',
			'Brembilla',
			'Brembate',
			'Breia',
			'Borgo Velino',
			"Borgofranco d'Ivrea",
			'Borgo Valsugana',
			'Borello',
			'Bologna',
			'Biccari',
			'Bassano in Teverina',
			'Azzone',
			'Avigliano',
			'Ausonia',
			'Ascea',
			'Arquata del Tronto',
			'Argelato',
			'Arcevia',
			'Apice Vecchio',
			'Andalo',
			'Amaro',
			'Altavilla Vicentina',
			'Albizzate',
			'Alberona',
			'Ailano',
			"Gatteo-Sant'Angelo",
			'Serravalle',
			'Maserada sul Piave',
			'Lughignano',
			"Sant'Anna",
			'Castion',
			"Sant'Agata Li Battiati",
			'Piedimonte Matese',
			'Carrara San Giorgio',
			'Magliano Alfieri',
			'Cella Monte',
			"Monticello d'Alba",
			'Mesenzana',
			'Chamois',
			'Pieve di Zignago',
			'Brezzo di Bedero',
			'Veniano',
			'Veddasca',
			'Fara Olivana',
			'Vigano San Martino',
			'Persico Dosimo',
			'Terrassa Padovana',
			'Cerignale',
			'Samolaco',
			'Pertica Alta',
			'Provaglio Val Sabbia',
			'Albaredo Arnaboldi',
			'Lettere',
			'Cortina sulla Strada del Vino',
			'Valle di Casies - Gsies',
			'Momperone',
			'Moncestino',
			'Alserio',
			'Magherno',
			'Brogliano',
			'San Prospero',
			'Rivarone',
			'San Giacomo Filippo',
			'Ronco Briantino',
			'Chiuduno',
			'Oliva Gessi',
			'Villa Biscossi',
			'Carano',
			'Ciconio',
			'Colleretto Castelnuovo',
			'Perledo',
			'Arizzano',
			'Rassa',
			'Dormelletto',
			'Battifollo',
			'Aramengo',
			'Roccaverano',
			'Sessame',
			'Carbonara Scrivia',
			'Mercatino Conca',
			'Trivigliano',
			'Vestreno',
			'Pagazzano',
			'Pradalunga',
			'Cura Carpignano',
			'Galliavola',
			'Imer',
			'Meana di Susa',
			'Civiasco',
			'Gattico',
			'Cardè',
			'Porto Cervo',
			'Chiassa-Tregozzano',
			'Sieci',
			'Oscano',
			'Fratta Terme',
			'Poggetto',
			'San Bartolomeo',
			'Progresso',
			"Piattoni-Villa Sant'Antonio",
			'Mercatale-San Quirico',
			'Manoppello Scalo',
			'Crocetta-Nogarè',
			'Mombretto',
			'San Giacomo',
			'Cascine-La Croce',
			'San Bartolomeo',
			'San Francesco',
			'Fogliano',
			'Cappone',
			'Meledo',
			'Bivio Santa Cecilia',
			'Colli di Enea',
			'Matinella',
			'Litta Parodi-Cascinagrossa',
			'Padiglione',
			'Sciarborasca',
			'Sasi',
			'Velasca',
			'San Jacopo al Girone',
			'San Pietro Belvedere',
			'Roggione',
			'Boscochiaro',
			'Pietre',
			'Brongio',
			'Pennisi',
			'Pilastro',
			'Prepezzano',
			'Bettola',
			'San Nicola',
			'Camoneone',
			'Villa-Nabian',
			'Meiern',
			'Chiesa',
			'Sciaves',
			'San Venanzio',
			'Gazzolo-Volpino',
			'Villaspeciosa',
			'Villafrati',
			'Villacidro',
			'Taurisano',
			'Spezzano Albanese',
			'Santa Cristina Gela',
			'Roseto Capo Spulico',
			'Quattromiglia',
			'Panettieri',
			'Marzi',
			'Lucugnano',
			'Lercara Friddi',
			'Lanusei',
			'Goni',
			'Gioiosa Ionica',
			'Furtei',
			'Floresta',
			'Fiumefreddo Bruzio',
			'Civita',
			"Capo d'Orlando",
			'Canicattini Bagni',
			'Buscemi',
			'Buonvicino',
			'Briatico',
			'Belmonte Mezzagno',
			'Baressa',
			'Avola',
			'Ardore Marina',
			'Aidone',
			'Agira',
			'Villar Pellice',
			'Villanova Monteleone',
			'Villanova',
			'Villamaina',
			'Villadossola',
			'Villa Carcina',
			'Vignacastrisi',
			'Verrone',
			'Venzone',
			'Vastogirardi',
			'Valpelline',
			'Vallecupa',
			'Valenzano',
			'Valdieri',
			'Trofarello',
			'Trigolo',
			'Trieste',
			'Tresnuraghes',
			'Torriana',
			"Torre de' Busi",
			'Torchiara',
			'Torano Nuovo',
			'Tonara',
			'Todi',
			'Thiene',
			'Tessera',
			'Tesero',
			'Tavullia',
			'Tavernola Bergamasca',
			'Suzzara',
			'Suardi',
			'Soragna',
			'Siziano',
			'Seveso',
			'Sesto ed Uniti',
			'Serravalle Langhe',
			'Sergnano',
			'San Vito',
			"Sant'Elia a Pianisi",
			"Sant'Arsenio",
			'Santa Paolina',
			"Sant'Antonio Abate",
			"Sant'Angelo in Lizzola",
			'Santa Maria Maggiore',
			'Santa Maria',
			'Santa Giuletta',
			'San Prisco',
			'San Pietro in Casale',
			'San Paolo di Civitate',
			'San Marzano sul Sarno',
			'San Marcello Pistoiese',
			'San Giuliano del Sannio',
			'San Giovanni Lipioni',
			'San Giovanni del Dosso',
			'San Giorgio di Livenza',
			'San Giorgio Canavese',
			'San Ginesio',
			'San Casciano in Val di Pesa',
			'Sala Consilina',
			'Roveredo in Piano',
			'Rocchetta a Volturno',
			'Ripe San Ginesio',
			'Remedello di Sopra',
			'Ravello',
			'Rasura',
			'Quargnento',
			'Predazzo',
			'Prato',
			'Posta',
			'Ponte di Barbarano',
			'Poggio Imperiale',
			'Piteglio',
			'Pincara',
			'Pieve di Teco',
			'Pieve di Cento',
			'Pietracuta',
			'Piandimeleto',
			'Pescantina',
			'Percoto',
			'Penna San Giovanni',
			'Paolisi',
			'Pamparato',
			'Palù',
			'Osidda',
			'Orria',
			'Onano',
			'Oggiono',
			'Naples',
			'Mozzecane',
			'Morigerati',
			'Mordano',
			'Monte Santa Maria Tiberina',
			'Monte San Pietrangeli',
			'Montelongo',
			'Montefalcone di Val Fortore',
			'Montecchio',
			'Montecalvo in Foglia',
			'Montebelluna',
			'Montebello di Bertona',
			'Montagnana',
			'Montagano',
			'Monno',
			'Molteno',
			'Moimacco',
			'Mezzana Rabattone',
			'Mezzana Bigli',
			'Mercato',
			'Moriondo Torinese',
			'Marano Ticino',
			'Mantova',
			'Macugnaga',
			'Lucera',
			'Lizzano',
			'Livorno',
			'Lei',
			'Latronico',
			'Lastebasse',
			'Laigueglia',
			'Giudecca',
			'Isola del Piano',
			'Ialmicco',
			'Guidonia',
			'Grumo Nevano',
			'Grottole',
			'Greggio',
			'Grazzano Badoglio',
			'Golasecca',
			'Giavera del Montello',
			'Gambara',
			'Galliera Veneta',
			'Gabicce Mare',
			'Fratta Todina',
			'Frascaro',
			'Fossato di Vico',
			'Fossalta di Piave',
			'Fornacette',
			'Forlì',
			'Forchia',
			'Fiè Allo Sciliar',
			'Ficulle',
			'Ferno',
			'Fascia',
			'Fara Novarese',
			'Faleria',
			'Esanatoglia',
			'Ercolano',
			'Envie',
			'Edolo',
			'Dueville',
			'Dorgali',
			'Doberdò del Lago',
			'Desenzano del Garda',
			'Darfo',
			'Creto',
			'Crescentino',
			'Corvaro',
			'Cornedo Vicentino',
			'Collio',
			'Collalbo',
			'Civitavecchia',
			'Cicerale',
			'Chieti',
			'Chialamberto',
			'Cherasco',
			'Cesiomaggiore',
			'Cesio',
			'Cengio Alto',
			'Cazzago San Martino-Calino',
			'Castiglione del Genovesi',
			'Castelpagano',
			'Castelnuovo dei Sabbioni',
			'Castelmassa',
			'Castelluccio Superiore',
			'Castelfranco Emilia',
			'Castelfranci',
			'Casalecchio di Reno',
			'Carpignano Sesia',
			'Capurso',
			'Capannori',
			'Cantù',
			'Cantalupo nel Sannio',
			'Cansano',
			"Canneto sull'Oglio",
			'Candia Lomellina',
			'Campospinoso',
			'Campoli del Monte Taburno',
			'Campo di Carne',
			'Campiglia Marittima',
			'Camogli',
			'Buttigliera Alta',
			'Bulzi',
			'Brivio',
			'Brissago-Valtravaglia',
			'Brennero',
			'Bozzole',
			'Botrugno',
			'Bosco',
			'Bosaro',
			'Borore',
			'Besenello',
			'Bentivoglio',
			'Belvedere Langhe',
			'Beinette',
			'Barile',
			"Baldichieri d'Asti",
			'Bagnolo in Piano',
			'Bagnarola',
			'Badia',
			'Azzate',
			'Azzano Decimo',
			'Attigliano',
			'Arce',
			'Aquara',
			'Anduins',
			'Altedo',
			'Allerona',
			'Alife',
			'Alice Castello',
			'Albinea',
			"Albiano d'Ivrea",
			'Adro',
			'Abano Terme',
			'Sevegliano',
			"San Canzian d'Isonzo",
			'Mignagola',
			'Basaldella',
			'Isola',
			'Cogorno',
			'Porto Viro',
			'Coassolo Torinese',
			'Roascio',
			'Briaglia',
			'Valloriate',
			'Rialto',
			'Cerrina',
			'Bulgarograsso',
			'San Bartolomeo Val Cavargna',
			'Pognano',
			'Manerba del Garda',
			'Montagne',
			'Valfloriana',
			'Travacò Siccomario',
			'Redondesco',
			'Stazione Valmozzola',
			'Montegioco',
			'Sardigliano',
			'Isolaccia',
			'Pezzaze',
			'Ville Sur Sarre',
			'Proves - Proveis',
			'Curino',
			'Fraconalto',
			'Varano Borghi',
			'Cavallasca',
			'Briosco',
			'Boissano',
			'Casale Litta',
			'Grezzago',
			'Vedano al Lambro',
			'Adrara San Martino',
			'Roncobello',
			'Linarolo',
			'Pratiglione',
			'Veruno',
			'Liscia',
			'Castelluccio dei Sauri',
			'Sellia Marina',
			'Donato',
			'Civate',
			'Parlasco',
			'Rueglio',
			'Monasterolo di Savigliano',
			"Serralunga d'Alba",
			'San Pietro di Morubio',
			'Tambre',
			'Castel Castagna',
			'Campo Calabro',
			'Viddalba',
			'Cabiate',
			'Osio Sopra',
			'Nave San Rocco',
			'Villarbasse',
			'Civesio',
			'Lisanza',
			'Castelletto',
			'Basilicagoiano',
			'Latina Scalo',
			'Piane di Morro',
			'Marina di Casal Velino',
			'Selcetta',
			'Ceresane-Curanuova',
			'Brognoligo-Costalunga',
			'Stazione',
			'Città Giardino',
			'San Giovanni-San Bernardino',
			"Ponton dell'Elce",
			'Villaggio del Sole',
			'Ballò',
			'Ludriano',
			'Osteria',
			'San Pietro in Volta',
			'Lambrinia',
			'Caldierino-Rota',
			'Capirro',
			'Forette',
			'San Michele',
			'Gonte',
			'Villanuova',
			"Villa d'Oneta",
			'Ariola',
			'Campolongo Maggiore',
			'Villapiana Lido',
			'Muzza di Cornegliano Laudense',
			'Vago',
			'Porto',
			'Villaputzu',
			'Teulada',
			'Terravecchia',
			'Taviano',
			'Simbario',
			'Siderno',
			'Settimo San Pietro',
			'San Vito Lo Capo',
			'Santa Caterina dello Ionio',
			'San Costantino Calabro',
			'San Cosmo Albanese',
			'Portopalo di Capo Passero',
			'Petronà',
			'Nuxis',
			'Nissoria',
			'Mezzojuso',
			'Ispica',
			'Gagliano del Capo',
			'Gagliano Castelferrato',
			'Fagnano Castello',
			'Donigala Fenugheddu',
			'Diamante',
			'Crotone',
			'Contessa Entellina',
			'Cittadella del Capo',
			'Caulonia',
			'Bonifati',
			'Bompietro',
			'Bivona',
			'Bianco',
			'Belvedere',
			'Bacu Abis',
			'Andali',
			'Alessandria del Carretto',
			'Volvera',
			'Visciano',
			'Villanova Mondovì',
			'Villa Literno',
			'Villa Bartolomea',
			'Vigliano Biellese',
			'Vidigulfo',
			'Ventimiglia',
			'Vedano Olona',
			'Vanzone',
			'Valsinni',
			"Valle dell'Angelo",
			'Valle Castellana',
			'Vallebona',
			'Urbania',
			'Turi',
			'Trescore Cremasco',
			'Travesio',
			'Trana',
			'Torre Boldone',
			'Toirano',
			'Toffia',
			'Tissi',
			'Ticineto',
			'Tarantasca',
			'Strambino',
			'Sperlonga',
			'Sondalo',
			'Sissa',
			'Sinalunga',
			'Silvi',
			'Sestola',
			'Secinaro',
			'Scansano',
			'Saviano',
			'Sasso Marconi',
			"Santa Margherita d'Adige",
			'Santa Giustina in Colle',
			'Santa Croce di Magliano',
			'San Sebastiano Curone',
			'San Pietro di Feletto',
			'San Pietro Capofiume',
			'San Nazario',
			'San Martino sulla Marrucina',
			'San Lorenzo Maggiore',
			'San Leucio del Sannio-Cavuoti',
			'San Gregorio da Sassola',
			'San Gregorio Matese',
			'San Giovanni a Teduccio',
			'Sangano',
			'San Francesco al Campo',
			'San Floriano',
			'San Fedele Superiore',
			"San Demetrio Ne' Vestini",
			'San Daniele del Friuli',
			'San Colombano al Lambro',
			'San Benedetto del Tronto',
			'Salmour',
			'Salassa',
			'Rotondella',
			'Roscigno',
			'Rosciano',
			'Ronsecco',
			'Roncofreddo',
			"Ronco All'Adige",
			'Ronciglione',
			'Roccavione',
			'Roccanova',
			'Rivarolo del Re',
			'Rionero in Vulture',
			'Rimini',
			'Riale',
			'Revello',
			"Reggio nell'Emilia",
			'Rancio Valcuvia',
			'Quartesana',
			'Pradleves',
			'Pont-Saint-Martin',
			'Ponte Caffaro',
			'Ponsacco',
			'Poggio Rusco',
			'Poggioreale',
			'Piumazzo',
			'Piombino',
			'Pietrasanta',
			'Pietracamela',
			'Pietrabbondante',
			'Piasco',
			'Piana Battolla',
			'Pernumia',
			'Pedaso',
			'Paliano',
			'Paderna',
			'Ottone',
			'Ornica',
			'Oriolo',
			'Orgiano',
			'Oratino',
			'Offida',
			'Nola',
			'Negrar',
			'Mottalciata',
			'Monticchio',
			'Montenerodomo',
			'Monteleone Sabino',
			'Montecelio',
			'Montebello',
			'Montano Antilia',
			'Montale',
			'Montalbano Jonico',
			'Mogliano',
			'Missaglia',
			'Mezzolombardo',
			'Mergo',
			'Melpignano',
			'Medicina-Buda',
			'Martignano',
			'Marina di Montemarciano',
			'Mariglianella',
			'Mariano del Friuli',
			'Marcetelli',
			'Mango',
			'Malmantile',
			'Malles Venosta',
			'Maccacari',
			'Livo',
			'Limite',
			'Lendinara',
			'Laurito',
			'Lauco',
			'Larino',
			'Lariano',
			'Ispra',
			'Ischia',
			'Guasticce',
			'Gonars',
			'Giugliano in Campania',
			'Ghemme',
			'Garda',
			'Gaiba',
			'Fuorigrotta',
			'Fratte',
			'Frasso Sabino',
			'Fortunago',
			'Forni di Sotto',
			'Fiumicino',
			'Figline Valdarno',
			'Ficarolo',
			'Ferrara di Monte Baldo',
			'Felizzano',
			'Faedis',
			'Esperia',
			'Dugenta',
			'Dualchi',
			'Diano Marina',
			"Diano d'Alba",
			'Dazio',
			'Cupra Marittima',
			'Cressa',
			'Crespiatica',
			'Cremeno',
			'Cori',
			'Coggiola',
			'Cittareale',
			'Chiusaforte',
			'Chiomonte',
			'Cetona',
			'Certaldo',
			'Centa San Nicolò',
			'Cavallermaggiore',
			'Cavalese',
			'Castorano',
			'Castions di Strada',
			'Castiglione Messer Raimondo',
			'Castiglione a Casauria',
			'Castelvetere in Val Fortore',
			'Castelraimondo',
			'Castel di Ieri',
			'Castel del Rio',
			'Cascina',
			'Cascia',
			'Casale Marittimo',
			'Carmagnola',
			'Carife',
			'Carasco',
			'Capranica',
			'Capodrise',
			'Cantiano',
			'Campo San Martino',
			'Campello sul Clitunno',
			'Campagnola',
			'Cagli',
			'Caerano di San Marco',
			'Caderzone Terme',
			'Busto Arsizio',
			'Buia',
			'Bozzolo',
			'Borgomaro',
			'Fosso Ghiaia',
			'Bollengo',
			'Besano',
			'Berlingo',
			'Bergeggi',
			'Bellante',
			'Bareggio',
			'Balvano',
			'Bagno a Ripoli',
			'Avio',
			'Aquino',
			'Antey-Saint-Andrè',
			'Alvito',
			'Aldeno',
			'Albignasego',
			'Albaredo',
			'Acquasanta Terme',
			'Acquapendente',
			'Acquafredda',
			'Visco',
			'Beccacivetta-Azzano',
			'Teverola',
			'Pramollo',
			'Rittana',
			'Scagnello',
			'Davagna',
			'Orero',
			'Casnate Con Bernate',
			'Cadegliano-Viconago',
			'Taleggio',
			'Tenna',
			'Pontey',
			'Tribogna',
			'Carrodano',
			"Provaglio d'Iseo",
			'Verano - Voeran',
			'San Bernardino Verbano',
			'Pianfei',
			"Vezza d'Alba",
			'Altavilla Monferrato',
			"Sant'Agata Fossili",
			'Treville',
			'Lasnigo',
			'Aviatico',
			'Rocca Canterano',
			'Donnas',
			'Gignod',
			'Inarzo',
			'Capovalle',
			'Monticelli Pavese',
			'Torrevecchia Pia',
			'Gombito',
			'Monte Cremasco',
			'Felonica',
			'San Carlo Canavese',
			'Riva Valdobbia',
			'Gargallo',
			'Montaldo Roero',
			'Montanera',
			'Valmala',
			"Chiusano d'Asti",
			'San Giorgio Scarampi',
			'Samone',
			'Spera',
			'Sorgà',
			'Gambugliano',
			'Boara Pisani',
			'Masi',
			'Barbara',
			'Graniti',
			'Intragna',
			'Lanzada',
			'Pontirolo Nuovo',
			'Ceranova',
			'Chiaravalle',
			'San Polo',
			'Lido dei Pini',
			'Fossoli',
			'Ponte Cingoli',
			'Lancusi-Penta-Bolano',
			'Pedemonte',
			'Bormida-Genepro',
			'Chiesa',
			'Albuccione',
			'Cintolese',
			'Gallo',
			'Baratte',
			'San Pierino',
			'Cuasso al Piano',
			'Stazione Montalto-Coretto',
			'Figino',
			'Carifi-Torello-Priscoli',
			'Ponterio-Pian di Porto',
			'Villanova',
			'Fossa',
			'Franche',
			'Sacca',
			'Mazara II',
			'Prato',
			'Mantiglia di Ardea',
			'Strada in Casentino',
			'Arlesega',
			'Santa Fiora',
			'Occagno',
			'Bedero',
			'Mure',
			'Canneto',
			'Piazzolla',
			'Villa-Borgo',
			'Chianchitta-Pallio',
			'Villapiana',
			'Vallelonga',
			'Vallefiorita',
			'Ugento',
			'Trapani',
			'Staletti',
			'Sperlinga',
			'Sortino',
			'Silì',
			'Siapiccia',
			'Santo Stefano di Camastra',
			"Sant'Alfio",
			'Santadi',
			"San Nicolo'Gerrei",
			'Sangineto',
			'San Basilio',
			'Rovito',
			'Rossano Stazione',
			'Roccella Ionica',
			'Porto Empedocle',
			'Pazzano',
			'Parenti',
			'Orroli',
			'Modica',
			'Misterbianco',
			'Melissa',
			'Lotzorai',
			'Limbadi-Caroni',
			'Laureana di Borrello',
			'Laino Castello-Nuovo Centro',
			'Grimaldi',
			'Giba',
			'Gesico',
			'Francofonte',
			'Fiumefreddo Sicilia',
			'Faro Superiore',
			'Cotronei',
			'Chiusa Sclafani',
			'Canneto',
			'Camporeale',
			'Assemini',
			"Granarolo dell'Emilia e Viadagola",
			'Zapponeta',
			'Vitulano',
			'Vipiteno',
			'Villetta Barrea',
			'Villarotta',
			'Villanova del Battista',
			"Villanova d'Asti",
			'Villa Guardia',
			'Villa Faraldi',
			'Villachiara',
			'Villa Castelli',
			'Vignolo',
			'Venafro',
			'Vallata',
			'Valenza',
			'Usini',
			'Urzulei',
			'Turbigo',
			'Tribano',
			'Trezzano Rosa',
			'Treponti',
			'Trento',
			'Travedona Monate',
			'Torrioni',
			'Torricella in Sabina',
			'Torre Orsaia',
			'Torreano',
			'Torino di Sangro',
			'Torbole sul Garda',
			'Soci',
			'Siror',
			'Seravezza',
			'Scarlino',
			'Sassofeltrio',
			'Sassari',
			'Sarsina',
			'Saronno',
			'San Vito Romano',
			'San Vero Milis',
			"Sant'Antonio di Gallura",
			"Sant'Angelo dei Lombardi",
			"Sant'Anastasia",
			'Santa Maria del Molise',
			'Santa Maria degli Angeli',
			'San Mauro Forte',
			'San Mauro di Saline',
			'San Martino in Pensilis',
			'San Giovanni Valdarno',
			'San Giovanni A Piro',
			'San Giorgio',
			'San Gennaro Vesuviano',
			'San Genesio Atesino',
			'Dolina',
			"San Damiano d'Asti",
			'San Cassiano',
			'San Bellino',
			'San Leonardo',
			'Sacile',
			'La Caletta',
			'Pragelato-Ruà',
			'Roseto Valfortore',
			'Roncade',
			'Roccagloriosa',
			'Roana',
			'Renazzo',
			'Ramiseto',
			'Quart',
			'Quadrelle',
			'Premeno',
			'Porto Tolle',
			'Portogruaro',
			'Pornassio',
			'Ponte San Nicolò',
			'Ponte nelle Alpi',
			'Ponna Superiore',
			'Pognana Lario',
			'Podenzana',
			'Pistrino',
			'Pinocchio di Ancona',
			'Pignataro Interamna',
			'Pienza',
			'Piazza al Serchio-San Michele',
			'Pescorocchiano',
			'Perrero',
			'Pedavena',
			'Patrica',
			'Passignano sul Trasimeno',
			'Paspardo',
			'Palestrina',
			'Paciano',
			'Pacentro',
			'Ortezzano',
			'Oliveto Citra',
			'Oldenico',
			'Noragugume',
			'None',
			'Montorso Vicentino',
			'Montevarchi',
			'Monteu da Po',
			'Monteleone di Puglia',
			"Monteforte d'Alpone",
			'Montasola',
			'Mogliano Veneto',
			'Melizzano',
			'Massa Marittima',
			'Massa Fermana',
			'Martinengo',
			'Marina di Andora',
			'Marciano della Chiana',
			'Manta',
			'Malalbergo',
			'Magnacavallo',
			'Macerata Campania',
			'Lubriano',
			'Lozzolo',
			'Lentigione-Sorbolo a Mane',
			'Lecce Nei Marsi',
			'Lavagna',
			'Issogne',
			'Granze',
			'Giuliano di Roma',
			"Giano dell'Umbria",
			'Gessopalena',
			'Genola',
			'Gemonio',
			'Gallinaro',
			'Gallarate',
			'Galliano',
			'Gaggiano',
			'Framura',
			'San Vendemiano-Fossamerlo',
			'Fossacesia',
			'Fornelli',
			'Fontaniva',
			'Ferriere',
			'Ferrandina',
			'Este',
			'Erchie',
			'Erba',
			'Endine',
			'Dolceacqua',
			'Dervio',
			'Cusio',
			'Cuccaro Vetere',
			"Craco-Sant'Angelo",
			'Costa di Rovigo',
			'Costacciaro',
			"Corte de' Cortesi",
			'Corpolò',
			'Copertino',
			'Concorezzo',
			'Comunanza',
			'Colledimezzo',
			"Città Sant'Angelo",
			'Città di Castello',
			'Cinzano',
			'Chiusi',
			'Chiusavecchia',
			'Chiusa di Pesio',
			'Chioggia',
			'Chiesa in Valmalenco',
			'Chianni',
			'Cevo',
			'Cerreto di Spoleto',
			'Ceriano Laghetto',
			'Centola',
			"Castronuovo di Sant'Andrea",
			"Castrignano De' Greci",
			'Castions',
			'Castiglion Fiorentino',
			'Castiglion Fibocchi',
			"Castelnuovo Bocca d'Adda",
			'Castelnuovo Calcea',
			'Castelnovetto',
			'Castello Tesino',
			'Castello di Annone',
			"Castel d'Aiano",
			'Castegnato',
			'Casella',
			'Casape',
			'Casalincontrada',
			'Canzo',
			'Canino',
			'Camposanto',
			'Calvanico',
			'Calcio',
			'Calciano',
			'Calamandrana',
			'Cairate',
			'Brunate',
			'Brinzio',
			'Braccagni',
			'Bovezzo',
			'Bovegno',
			'Borzano',
			'Borgo Val di Taro',
			'Borgorose',
			'Borghetto Lodigiano',
			'Bonea',
			'Bondeno',
			'Bisenti',
			'Berzo Inferiore',
			'Bertiolo',
			'Atina',
			'Arenzano',
			'Aprilia',
			'Anghiari',
			'Alpette',
			'Almè',
			'Alessandria',
			'Agropoli',
			'Acceglio',
			'Summaga',
			'San Polo di Piave',
			'Verano Brianza',
			'Pollena Trocchia',
			'Tor Lupara',
			'Giusvalla',
			'Novedrate',
			'Faloppio',
			'Schignano',
			'Trezzone',
			'Mottella',
			'La Valle - Wengen',
			"Sant'Alessio Con Vialone",
			'San Pietro Valdastico',
			'Cagnò',
			'Portico e San Benedetto',
			'Trebbio',
			'Aldino',
			'Viarigi',
			'Borghetto di Borbera',
			'Rosignano Monferrato',
			'Dresano',
			'Lesmo',
			'Ambivere',
			'Riva di Solto',
			'Montefelcino',
			'Jenne',
			'Albairate',
			'Bagnatica',
			'Barbariga',
			'Brandico',
			'Nuvolera',
			'Roncadelle',
			'Cavizzana',
			'Cis',
			'Faver',
			'Rossa',
			'Salasco',
			'Castello di Cisterna',
			'Gualtieri Sicaminò',
			'Aurano',
			'Lagnasco',
			'Marsaglia',
			'Neviglie',
			"Cerreto d'Asti",
			'Frinco',
			'Grana',
			'Loazzolo',
			'Cartosio',
			'Bagnolo di Po',
			'Canda',
			'Vallesaccarda',
			'Dorzano',
			'Zumaglia',
			'Banchette',
			'Vaie',
			'Riscone',
			'Cavalcaselle',
			'Mantignano-Ugnano',
			'Canova-San Zeno',
			"Sant'Antonio",
			'Tivolille Pasquali-Merenzata',
			'Valcanneto',
			'Starza Vecchia',
			'Valleggia',
			'Moiano',
			'Penitro',
			'Tonengo-Casale',
			'Drubiaglio-Grangia',
			'Grignano',
			'Cavino',
			'Fraviano',
			'Cavoni-Ginestreto',
			'Camnago-Boscone',
			'Raffa',
			'Felina',
			'Carrozziere',
			'Castelmassimo',
			'Campiglione',
			'Villaggio Residenziale',
			'Cellore',
			'Crispi Cavour',
			'Ardole San Marino',
			'San Defendente',
			'Madrano-Canzolino',
			'Salino',
			'Isoverde',
			'Cimbro',
			'Pitelli',
			'Barbiano',
			'San Giuseppe',
			'San Romano',
			'Villa',
			'Canistro Inferiore',
			'Preara-Moraro-Levà Nord',
			'Villanova del Ghebbo Canton',
			'Saponara Marittima',
			'Molinetto',
			'Ponte a Poppi',
			'Poasco-Sorigherio',
			'Stella',
			'Villanovaforru',
			'Vaccarizzo Albanese',
			'Terranova di Pollino',
			'Surdo',
			'Serra Pedace',
			'Segariu',
			'Scaletta Zanclea',
			'San Pietro in Guarano',
			'San Leone Mosè',
			'San Giovanni di Gerace',
			'Rose',
			'Rizziconi',
			'Riesi',
			'Pizzoni',
			'Pernocari-Presinaci',
			'Paterno Calabro',
			'Palma di Montechiaro',
			'Pallagorio',
			'Palermo',
			'Paceco',
			'Naso',
			'Montedoro',
			'Martirano Lombardo',
			'Lungro',
			'Lazzaro',
			'Laurignano',
			'Aprigliano',
			'Erice',
			'Cropani',
			'Cinque Frondi',
			'Cerisano',
			'Cerchiara di Calabria',
			"Castell'Umberto",
			'Campofranco',
			'Baucina',
			'Arzana',
			'Alia',
			'Acquaformosa',
			'Zocca',
			'Zermeghedo',
			'Voltana',
			'Voltaggio',
			'Villongo',
			'Villareggia',
			'Villabassa',
			'Villa Basilica',
			'Vignanello',
			'Vermiglio',
			'Venice',
			'Vedelago',
			'Varallo',
			'Vairano-Patenora',
			'Uliveto Terme',
			'Turrivalignani',
			'Tricesimo',
			'Trevi nel Lazio',
			'Torri in Sabina',
			'Torre Santa Susanna',
			'Torre Beretti',
			'Tornata',
			'Torlino Vimercati',
			'Terrarossa',
			'Teano',
			'Suno',
			'Suni',
			'Sueglio',
			'Stellanello',
			'Stagno Lombardo',
			'Sporminore',
			'Spineda',
			'Spianate',
			'Sorbara',
			'Sorano',
			'Soleto',
			'Solero',
			'Sluderno',
			'Sestino',
			'Serralunga di Crea',
			'Sennariolo',
			'Sellano',
			'Scortichino',
			'Scorrano',
			'Naz-Sciaves - Natz-Schabs',
			"Scandolara Ripa d'Oglio",
			'San Zenone degli Ezzelini',
			'Santa Valburga',
			'Santa Marina',
			"Sant'Alberto",
			"Sant'Agata Feltria",
			'Santa Cristina',
			'San Pancrazio Salentino',
			'Sannicola',
			'San Giorgio di Piano',
			'San Clemente',
			'La Salute di Livenza',
			'Sala Monferrato',
			'Saint-Oyen',
			'Rubano',
			'Roviano',
			'Rovereto',
			'Roverè della Luna',
			'Rosignano Marittimo',
			'Rolo',
			'Rodigo',
			'Roccantica',
			'Roccalbegna',
			'Rocca Imperiale',
			'Riolunato',
			"Rignano sull'Arno",
			'Rescaldina',
			'Reggiolo',
			'Rapone',
			'Rapolla',
			'Radicofani',
			'Quattro Castella',
			'Procida',
			'Prezza',
			'Pressana',
			'Premilcuore',
			'Preci',
			'Pozzoleone',
			'Pozzillo',
			'Postal',
			'Porto Fuori',
			'Portofino',
			'Portocannone',
			'Pordenone',
			'Ponte di Piave',
			'Pontassieve',
			'Pomarico',
			'Polinago',
			'Polesine Parmense',
			'Pisa',
			'Piazza',
			'Piano',
			'Piangipane',
			'Pian di Scò',
			'Petrella Tifernina',
			'Petrella Salto',
			'Petina',
			'Pertosa',
			'Pancalieri',
			'Pagani',
			'Ostiglia',
			'Ortucchio',
			'Omignano',
			'Vassena',
			'Nuvolento',
			'Nogarole Rocca',
			'Nimis',
			'Conscenti',
			'Murano',
			'Motta di Livenza',
			"Morro d'Alba",
			'Morano sul Po',
			'Montopoli',
			'Monteu Roero',
			'Monteriggioni',
			'Montegrosso',
			'Montecassiano',
			'Montecarlo',
			'Monselice',
			'Molinara',
			'Moie',
			'Mirandola',
			'Miranda',
			'Minturno',
			'Miglionico',
			'Mercatale',
			'Meltina',
			'Mello',
			'Melito di Napoli',
			'Meggiano',
			'Mazzano Romano',
			'Marola',
			'Marano di Valpolicella',
			'Mantignana',
			'Maiori',
			'Luogosanto',
			'Loreo',
			'Limena',
			'Lido di Jesolo',
			'Leno',
			'Le Grazie di Ancona',
			'Latina',
			'Landiona',
			'Laives',
			'Guazzora',
			'Grosseto',
			'Gropparello',
			'Cravagliana',
			'Ginestra',
			'Gildone',
			'Gerenzago',
			'Genga',
			'Gemona',
			'Gavorrano',
			'Gavoi',
			'Gallese',
			'Gaeta',
			'Gabiano',
			'Frassineto Po',
			'Fosdinovo',
			'Forni Avoltri',
			'Fornacelle',
			'Fontecchio',
			'Follina',
			'Farnese',
			'Farini',
			'Farindola',
			'Fallo',
			'Duino',
			'Dronero',
			'Dosson',
			'Corridonia',
			'Corciano',
			'Confienza',
			'Collagna',
			'Colico Piano',
			'Cisano Bergamasco',
			'Cipressa',
			'Chiuppano',
			'Porto Cesareo',
			'Cervignano del Friuli',
			'Cervaro',
			'Cernobbio',
			'Ceriana',
			'Ceriale',
			'Castiglione di Garfagnana',
			'Castelvetere sul Calore',
			'Castelnuovo Magra',
			'Castel Mella',
			'Castellarano',
			'Castelfranco in Miscano',
			'Cassiglio',
			'Casorate Primo',
			'Casarile',
			'Carlantino',
			'Carema',
			'Caraglio',
			'Capo di Ponte',
			'Capodarco',
			'Capistrello',
			'Caorso',
			'Cantalice',
			'Candide',
			'Candelo',
			'Campotosto',
			'Camposampiero',
			'Campo di Trens',
			'Campobasso',
			'Busto Garolfo',
			'Bruino',
			"Brignano Gera d'Adda",
			'Bottrighe',
			'Borghetto di Vara',
			'Borgaro Torinese',
			'Blera',
			'Bisceglie',
			'Beura',
			'Bettona',
			'Bedonia',
			'Balocco',
			'Averara',
			'Arquata Scrivia',
			'Arona',
			'Armento',
			'Armeno',
			'Apiro',
			"Anzola d'Ossola",
			'Amorosi',
			'Albaredo',
			'Alanno',
			'Agugliano',
			'Acerra',
			'Acerno',
			'Ruda',
			"Terzo d'Aquileia",
			'Badoere',
			'Viganella',
			'Cassano Magnago',
			'Castelspina',
			'Oviglio',
			'Montezemolo',
			'Incisa Scapaccino',
			'Rondanina',
			'Pozzol Groppo',
			'Blevio',
			'Borgio',
			'Piario',
			"Sant'Omobono Terme",
			'Longhena',
			"Corte de' Cortesi con Cignone",
			'Vigo di Ton',
			'Ossana',
			'Magrè sulla Strada del Vino',
			'Oggebbio',
			'Ponzano Monferrato',
			'Azzio',
			'Ollomont',
			'Bardello',
			'Bisuschio',
			'Cantello',
			'Albiolo',
			'Castelmarte',
			'Dosso del Liro',
			'Calvagese della Riviera',
			"Corte de' Frati",
			'Cavedine',
			'Casamicciola Terme',
			'Scisciano',
			'Cesinali',
			'Feroleto Antico',
			'Piatto',
			'Pasturo',
			'Caresanablot',
			"Cavaglio D'Agogna",
			'Rifreddo',
			'Scurzolengo',
			'Viale',
			'Pareto',
			'Zugliano',
			'Treppo Carnico',
			'Casapesenna',
			'Montella',
			'Cassina Valsassina',
			'Montevecchia',
			'Cornovecchio',
			'Zuni',
			'Ramponio Verna',
			'Ceretto Lomellina',
			'Bastia Mondovì',
			'Bonvicino',
			'Castiglione Tinella',
			'Luco Mugello',
			'Casal Palocco',
			'Monasterolo',
			'Santa Maria Apparente',
			'Montedecoro',
			'Bandito',
			'Mulino',
			'Montelera',
			'Gabbio-Cereda-Ramate',
			'Folzano',
			'Riva',
			'Casone',
			'Montalbano',
			'Garbagnate',
			'Stazione Masotti',
			'Nosadello',
			'Stazione di Allerona',
			'Molella',
			'Brozzo',
			'Colle del Pino',
			'Rifiano',
			'Villa',
			'Coassolo',
			'San Giovanni',
			'Chiesa Nuova',
			'Serone',
			"Sant'Orsola",
			'Taverna Ravindola',
			'Spigno Saturnia Inferiore',
			'Ponte Sasso',
			'San Giuseppe',
			"Sant'Eufemia della Fonte",
			'Tusa',
			"Sparta'",
			'Sorianello',
			'Solarussa',
			'Siano',
			'Seulo',
			'Serra San Bruno',
			'Santa Domenica Vittoria',
			'San Nicolò',
			"San Nicola dell'Alto",
			'San Demetrio Corone',
			'Rombiolo',
			'Resuttano',
			'Presicce',
			'Pedara',
			'Osini',
			'Mongiana',
			'Mirabella Imbaccari',
			'Monasterace Marina',
			'Licata',
			'Joppolo Giancaxio',
			'Guspini',
			'Gioiosa Marea',
			'Gerace',
			'Francica',
			'Elini',
			'Crichi',
			'Castroregio',
			'Carlentini',
			'Cardinale',
			'Cardeto',
			'Caraffa del Bianco',
			'Bova Marina',
			'Baradili',
			'Alessano',
			'Albidona',
			'Aci Bonaccorsi',
			'Zandobbio',
			'Volano',
			'Vistrorio',
			'Villastellone',
			"Villa d'Almè",
			'Villa Celiera',
			'Vidor',
			"Vezza d'Oglio",
			'Verzuolo',
			'Vertemate Con Minoprio',
			'Verolanuova',
			'Vejano',
			"Varano De' Melegari",
			'Valstagna',
			'Valmacca',
			'Vallese',
			"Vall'Alta",
			'Valgrana',
			'Uggiano la Chiesa',
			'Tuenno',
			'Treglio',
			'Trecasali',
			'Torviscosa',
			'Torralba',
			'Tombolo',
			'Sustinente',
			'Supino',
			'Strada in Chianti',
			'Stia',
			'Staranzano',
			'Soresina',
			'Sonnino',
			'Siviano',
			'Sillavengo',
			'Sesto Imolese',
			'Sepino',
			'Selvatelle',
			'Sellero',
			'Scopello',
			"Savogna d'Isonzo",
			'Sava',
			'Sassocorvaro',
			'Sartirana Lomellina',
			'Sarmato',
			"Sant'Egidio alla Vibrata",
			"Sant'Arcangelo Trimonte",
			"Sant'Angelo in Pontano",
			'Santandrà',
			'Santa Maria Maddalena',
			'Santa Maria Coghinas',
			'San Secondo Parmense',
			'San Polo dei Cavalieri',
			'San Michele di Piave',
			'San Martino',
			'San Marco dei Cavoti',
			'San Marcello',
			'San Giovanni in Marignano',
			'San Giorgio di Nogaro',
			'San Giorgio della Richinvelda',
			'San Felice',
			'Saluggia',
			'Sacco',
			'Roccaspinalveti-Santa Giusta',
			'Rocca Canavese',
			"Robecco d'Oglio",
			"Rio nell'Elba",
			'Rezzago',
			'Re',
			'Ravenna',
			'Raldon',
			'Pulfero',
			'Prosecco-Contovello',
			'Prata di Principato Ultra',
			'Porto Potenza Picena',
			'Ponza',
			'Ponso',
			'Ponte nelle Alpi-Polpet',
			'Policoro',
			'Poggio Picenze',
			'Poggio Nativo',
			'Piobesi Torinese',
			'Piglio',
			'Pievebovigliana',
			'Pietramontecorvino',
			'Pietraferrazzana',
			"Pietra de' Giorgi",
			'Pietrabruna',
			'Piedimonte San Germano',
			'Peschiera del Garda',
			'Pavarolo',
			'Pannarano',
			'Palombaro',
			'Palo del Colle',
			'Orciano di Pesaro',
			'Offanengo',
			'Offagna',
			'Nizza Monferrato',
			'Navelli',
			'Morcone',
			'Monticelli',
			'Montelupo Albese',
			'Montecorvino Rovella',
			"Monforte d'Alba",
			'Mombaroccio',
			'Molfetta',
			'Minerbe',
			'Mezzanego',
			'Meina',
			'Marzano di Nola',
			'Martina Franca',
			'Marsicovetere',
			'Marliana',
			'Marcheno',
			'Marano di Napoli',
			'Malvicino',
			'Mairano',
			'Serra di Maiolo',
			'Magliano Vetere',
			'Lusiana',
			'Lurate Caccivio',
			'Lumellogno',
			'Lonato',
			'Lomagna',
			'Lizzano in Belvedere',
			'Licenza',
			'Liberi',
			'Lezzeno',
			'Lemie',
			'Lauriano',
			'Latera',
			"Lanzo d'Intelvi",
			'Landriano',
			'Lamon',
			'Ittireddu',
			'Invorio',
			'Grezzana',
			'Gressan',
			'Gradara',
			'Godo',
			'Giussano',
			'Giungano',
			'Gesualdo',
			'Gambettola',
			'Gallipoli',
			'Fossalunga',
			'Formignana',
			'Formigine',
			'Formia',
			'Florence',
			'Filignano',
			'Fidenza',
			'Fiamignano',
			'Faeto',
			'Etroubles',
			'Cavargna',
			'Castropignano',
			'Castiglione delle Stiviere',
			'Castiglione dei Pepoli',
			'Castelmezzano',
			'Campomolino',
			'Castelcucco',
			'Castel Chiodato',
			'Cassine',
			'Casola in Lunigiana',
			"Casirate d'Adda",
			'Casine',
			'Casalzuigno',
			'Carzago Riviera',
			'Corneno-Galliano-Carella Mariaga',
			'Carcare',
			'Caposele',
			'Canzano',
			'Canegrate',
			'Campogalliano',
			'Campagna',
			'Cameri',
			'Camerata Picena',
			'Caldogno-Rettorgole-Cresole',
			'Caldaro sulla Strada del Vino',
			'Busalla',
			'Burolo',
			'Brugnato',
			'Bressanvido',
			'Bosentino-Migazzone',
			'Bosconero',
			'Bono',
			'Bisaccia',
			'Bertinoro',
			'Bari',
			'Asolo',
			'Asciano',
			'Arzignano',
			'Arosio',
			'Andezeno',
			'Albignano',
			'Glorie',
			'Camino al Tagliamento',
			'Falze',
			'Colloredo di Monte Albano-Lauzzana',
			'Pieve a Nievole',
			'Basiglio',
			'Crispano',
			'Tremestieri Etneo',
			'Castelletto Molina',
			'Corsione',
			'Pleyne',
			'Verrayes',
			'Cosio di Arroscia',
			'Bormida',
			'Beregazzo con Figliaro',
			'Castione Andevenno',
			'Carobbio degli Angeli',
			'Vilminore di Scalve',
			'Brione',
			'Pieve di Bono',
			"Gerre de' Caprioli",
			'Pedemonte',
			'Brennero - Brenner',
			'Renon - Ritten',
			'Vandoies - Vintl',
			'Terme',
			'Perletto',
			'Arnad',
			'Macherio',
			'Vizzolo Predabissi',
			'Gandellino',
			'Carrè',
			'Alano di Piave',
			'Cartura',
			'Visone',
			'Cusino',
			'Busnago',
			'Azzano San Paolo',
			"Terno d'Isola",
			'San Zeno Naviglio',
			'Succivo',
			'Sirtori',
			'Verderio Inferiore',
			'Valera Fratta',
			'Levone',
			'Oncino',
			"Buttigliera d'Asti",
			'San Marzano Oliveto',
			'Roverchiara',
			'Palmoli',
			'Limina',
			'Vendrogno',
			'Abbadia Cerreto',
			'Borgo San Giovanni',
			'Galgagnano',
			'Livraga',
			'Massiola',
			'Pomarolo',
			'San Pietro Val Lemina',
			'Guardabosone',
			'Moncrivello',
			'Tortoreto Lido',
			'Monti',
			'Pianoconte',
			'Caulonia Marina',
			'Santa Lucia',
			'Ponte Pattoli',
			'Fenile',
			'Osteria Grande',
			'Prato Perillo',
			'Laghetto',
			'Borgo Santa Maria Immacolata',
			'Poggio dei Pini',
			'Manesseno',
			'Casco',
			'Macchia',
			'Montano',
			'Fonte Umano-San Martino Alta',
			'San Biagio',
			'Venegazzù',
			'Abbazia',
			'Pulce',
			'La Forma',
			'Monte Caminetto',
			'Colleranesco',
			"Sant'Apollinare",
			'Poianella',
			'San Vito-Cerreto',
			'Valle San Bartolomeo',
			'Regolelli',
			'Isca Marina',
			'Campo',
			'Casate',
			'Azzano',
			'Calmasino',
			'Fornaci',
			'Brovello',
			'Loreto Stazione',
			'Vilminore',
			'Ponte di Castegnero',
			'Orvieto Scalo',
			'Cetraro Marina',
			'Montecalvario',
			'Specchia',
			'Satriano',
			"Sant'Anna Arresi",
			'San Michele di Ganzaria',
			'San Carlo-Condofuri Marina',
			'Racale',
			'Priolo Gargallo',
			'Platania',
			'Piana degli Albanesi',
			'Petilia Policastro',
			'Nurallao',
			'Moio Alcantara',
			'Maierà',
			'Lipari',
			'Lampedusa',
			'Nuova Gibellina',
			'Furci Siculo',
			'Favara',
			'Doria',
			'Delia',
			'Castel di Judica',
			'Caronia',
			'Botricello',
			'Biancavilla',
			'Arena',
			'Aragona',
			'Anoia Inferiore',
			'Altilia',
			'Aci Castello',
			'Siurgus Donigala',
			'Vogogna',
			'Viticuso',
			'Villesse',
			'Villar Focchiardo',
			'Villanova Monferrato',
			'Vignale Monferrato',
			'Varco Sabino',
			'Valmadrera-Caserta',
			'Vallinfreda',
			'Valgioie',
			'Valganna',
			'Usseglio',
			'Urbino',
			'Umbertide',
			'Udine',
			'Turania',
			'Tubre',
			'Trodena',
			'Triei',
			'Tricerro',
			'Trarivi',
			'Tornolo',
			'Torbole Casaglia',
			'Tivoli',
			'Tiglieto',
			'Taranto',
			'Tamai',
			'Stresa',
			'Stradella',
			'Stanghella',
			'Sora',
			'Solbiate Arno',
			'Soddì',
			'Silea',
			'Sesto',
			'Serre',
			'Serravalle Scrivia',
			'Serole',
			'Sequals',
			'Secugnago',
			'Sarzana',
			"Sant'Angelo A Scala",
			"Sant'Agapito",
			'San Potito Sannitico',
			'San Michele Salentino',
			'San Giovanni Lupatoto',
			'San Giovanni al Natisone',
			'San Gimignano',
			'San Gemini',
			'San Gabriele-Mondonuovo',
			'San Biagio Saracinisco',
			'Saluzzo',
			'Salisano',
			'Salionze',
			'Rovigo',
			'Roccavivi',
			'Ripe',
			'Riparbella',
			'Revò',
			'Quattordio',
			'Quarto Inferiore',
			'Premana',
			'Pozza di Fassa',
			'Portico di Romagna',
			'Porotto-Cassama',
			"Ponte dell'Olio",
			'Ponte Buggianese',
			'Pomponesco',
			'Poggio Bustone',
			'Pisciotta',
			"Piobesi d'Alba",
			'Piobbico',
			'Pieve Santo Stefano',
			'Pieve Fosciana',
			'Pietra Ligure',
			'Piedimonte',
			'Pianillo',
			'Piaggine',
			'Pettoranello del Molise',
			'Pessinetto',
			'Pescocostanzo',
			'Pavone Canavese',
			'Pastena',
			'Paratico',
			'Paluzza',
			'Palma Campania',
			'Ozegna',
			'Ottati',
			'Ostuni',
			'Orio Canavese',
			'Orgosolo',
			'Oneta',
			'Omegna',
			'Olmo al Brembo',
			'Ogliastro Cilento',
			'Nole',
			'Nociglia',
			'Niella Belbo',
			'Monterosso al Mare',
			'Montenero Val Cocchiara',
			'Montemagno',
			'Monteciccardo',
			'Monsano',
			'Monsampietro Morico',
			'Moncalvo',
			'Molini di Triora',
			'Mirabello Sannitico',
			'Minucciano',
			'Migliarino',
			'Micigliano',
			'Mezzomerico',
			'Mercatello sul Metauro',
			'Meleti',
			'Mede',
			'Marradi',
			'Malnate',
			'Mafalda',
			'Maccagno',
			'Longare',
			'Lierna',
			'Levada',
			"Lesignano de'Bagni",
			'Lerma',
			'Leini',
			'Laveno',
			'Lacchiarella',
			'Grumolo delle Abbadesse',
			'Grauno',
			'Godega',
			'Gavi',
			"Gaibanella-Sant'Edigio",
			'Fossa',
			'Fombio',
			'Fiano Romano',
			'Ferrera Erbognone',
			'Fano',
			'Erli',
			'Deliceto',
			'Cuorgnè',
			'Croviana',
			"Crotta d'Adda",
			'Crispiano',
			'Crespino',
			'Craveggia',
			'Cravanzana',
			'Costigliole Saluzzo',
			'Corte Franca',
			'Cornale',
			'Coreglia Antelminelli',
			'Cordovado',
			'Conselve',
			'Colobraro',
			'Collepasso',
			'Collemeto',
			'Civitella Marittima',
			'Cimolais',
			'Chiusa',
			'Ceres',
			'Ceregnano',
			'Cerbaia',
			'Cataeggio',
			'Castel San Lorenzo',
			'Domegge di Cadore',
			'Dicomano',
			'Curiglia',
			'Corvara in Badia',
			'Cortandone',
			'Corno di Rosazzo',
			'Controne',
			'Cologno al Serio',
			'Colle Santa Lucia',
			'Collalto Sabino',
			'Cocullo',
			'Cinisello Balsamo',
			'Chiaramonti',
			'Cerreto Laziale',
			'Cerea',
			'Cercola',
			'Cercino',
			'Castelsaraceno',
			'Castelsantangelo sul Nera',
			'Castelpetroso',
			'Castelnuovo Scrivia',
			'Castelnuovo di Conza',
			'Castelmauro',
			'Castellucchio',
			"Castello d'Argile",
			'Castellino del Biferno',
			'Cassano delle Murge',
			'Casalnuovo di Napoli',
			'Casale Monferrato',
			'Carmiano',
			'Carbonara di Po',
			'Caravate',
			'Caprie',
			'Campo Tures',
			'Campolongo al Torre',
			'Camerata Cornello',
			'Camburzano',
			'Camairago',
			'Calcinelli',
			'Calalzo di Cadore',
			'Caggiano',
			"Ca' di Sola",
			'Buriasco',
			'Bossolasco',
			'Bosa',
			'Borgo Grappa',
			'Boccioleto',
			'Binasco',
			'Bernate Ticino',
			'Berbenno di Valtellina',
			'Balzola',
			'Avetrana',
			'Austis',
			'Asiago',
			'Arsiè',
			'Argegno',
			'Arese',
			'Aprica',
			'Anguillara Sabazia',
			'Ampezzo',
			'Alviano',
			'Alagna',
			'San Martino dei Mulini',
			'Gatteo a Mare',
			'Corbolone',
			'San Giorgio al Tagliamento-Pozzi',
			'Trichiana',
			'Osio Sotto',
			'Bussero',
			'San Felice A Cancello',
			'San Pietro in Cariano',
			'Cesate',
			'Roatto',
			'Perlo',
			'Mioglia',
			'Moltrasio',
			'Tresivio',
			'Pianello del Lario',
			"Rota d'Imagna",
			'Lodrino',
			'Malegno',
			'Megliadino San Fidenzio',
			'Colognola ai Colli',
			'San Gregorio nelle Alpi',
			'Cortino',
			'Monticelli Brusati',
			'Barbiano - Barbian',
			'Roghudi',
			'Montaldeo',
			'Duno',
			'Cogliate',
			'Masate',
			'Filago',
			'Gaverina Terme',
			'Motteggiana',
			'Cunevo',
			'Asigliano Veneto',
			'Urbana',
			"Vighizzolo d'Este",
			'Solignano',
			'Castelvecchio di Rocca Barbena',
			'Magreglio',
			'Nicorvo',
			'Fiesco',
			'Quintano',
			'San Germano Chisone',
			'Villaperuccio',
			'Valgreghentino',
			'Montegridolfo',
			'Castelsilano',
			'Mombello di Torino',
			'Vische',
			'Boca',
			'Cervasca',
			'Portacomaro',
			'Rocchetta Tanaro',
			'Camagna Monferrato',
			'Montaldo Bormida',
			'Terres',
			'Crandola Valsassina',
			'Marudo',
			'Brognaturo',
			'Solza',
			'Lusernetta',
			'Pettenasco',
			'Spadola',
			'Aci Trezza',
			'Castromediano',
			'Lavinio',
			'Villaseta',
			'Sala',
			'Lanzara',
			'Formica',
			'Pizzano',
			'Piano Maglio-Blandino',
			'Nocera Scalo',
			'Quattro Strade',
			'Cà Dè Fabbri',
			'Albareto',
			'Bivio di Capanelle',
			'Battaglione-Bagnara',
			'Castelceriolo',
			'San Paolo',
			'Fornole',
			'Cannizzaro-Favara',
			'Monte',
			'Furato',
			'Bivio San Polo',
			'Bora Bassa',
			'Fellegara',
			'Colle Spina',
			'Beregazzo',
			'Verla',
			'La Pieve-Molino',
			'San Fidenzio',
			'Tortora Marina',
			'Villasor',
			'Villa San Giovanni',
			'Villanovafranca',
			'Usellus',
			'Tricase',
			'Soriano Calabro',
			'Siamanna',
			'Scala Coeli',
			'Savelli',
			'Sardara',
			'San Vito Sullo Ionio',
			'Santo Stefano di Rogliano',
			'San Sperate',
			"Sant'Ilario dello Ionio",
			"Sant'Antioco",
			'Santa Flavia',
			"Santa Cristina d'Aspromonte",
			'San Cono',
			'Salaparuta',
			'Sadali',
			'Ruffano',
			'Quartucciu',
			'Petralia Sottana',
			'Pentone',
			'Pau',
			'Partanna',
			'Nizza di Sicilia',
			'Morgongiori',
			'Montallegro',
			'Mongrassano',
			'Monastir',
			'Mogoro',
			'Milo',
			'Milena',
			'Marineo',
			'Marina di Ragusa',
			'Mangone',
			'Maierato',
			'Laconi',
			'Guasila',
			'Gizzeria',
			'Geraci Siculo',
			"Gairo Sant'Elena",
			'Delianuova',
			'Carbonia',
			'Calascibetta',
			'Cagliari',
			'Bivongi',
			'Atzara',
			'Arborea',
			'Amica',
			'Alliste',
			'Aieta',
			'Zevio',
			'Virle Piemonte',
			'Vicenza',
			'Verolavecchia',
			'Valle Agricola',
			'Treviso',
			'Tresigallo-Final di Rero',
			'Treggiaia',
			'Trecchina',
			'Trecate',
			'Transacqua',
			'Torrice',
			'Torre di Santa Maria',
			'Tavenna',
			'Tarano',
			'Stio',
			'Stelvio',
			'Spinetoli',
			'Solignano Nuovo',
			'Soldano',
			'Scarmagno',
			'Scafa',
			"Sauze d'Oulx",
			'San Vito dei Normanni',
			"Sant'Arpino",
			"Sant'Andrea del Garigliano",
			'Santa Giustina',
			"Sant'Agata de'Goti",
			'San Salvo',
			"San Polo d'Enza",
			'San Pietro al Natisone',
			'Sannicandro di Bari',
			'San Michele di Serino',
			'San Massimo',
			'San Marco Evangelista',
			'San Lorenzo',
			'San Giacomo delle Segnate',
			'San Gervasio Bresciano',
			'Sandigliano',
			'Salvaterra',
			'Salussola',
			'Saline',
			'Saliceto',
			'Salento',
			'Rottanova',
			'Romeno',
			'Romanengo',
			'Rocchetta di Vara',
			'Rocca San Felice',
			'Riva Ligure',
			'Remanzacco',
			'Recale',
			"Puos d'Alpago",
			'Puianello',
			'Prato',
			'Postioma',
			'Positano',
			'Porto Torres',
			'Porano',
			'Pontremoli',
			'Ponte Gardena',
			'Pollone',
			'Poggiomarino',
			'Piazza Brembana',
			'Piazza',
			'Piacenza',
			'Petruro Irpino',
			'Pesco Sannita',
			'Paruzzaro',
			'Parabita',
			'Palmariggi',
			'Palmanova',
			'Palazzolo dello Stella',
			'Pagnona',
			'Padova',
			'Paderno Ponchielli',
			'Ozzano Monferrato',
			'Osteno-Claino',
			'Olevano di Lomellina',
			'Oglianico',
			'Notaresco',
			'Noci',
			'Narzole',
			'Mosnigo',
			"Morro d'Oro",
			'Montone',
			'Montesarchio',
			'Montemesola',
			'Monte Grimano',
			'Monteferrante',
			'Montanaso Lombardo',
			'Moglia',
			'Modolo',
			'Merano',
			'Roccasecca Stazione',
			'Marzano Appio',
			'Magliano',
			'Lorenzago di Cadore',
			'Ligonchio',
			'Lavenone',
			'Latiano',
			'Lastra a Signa',
			'Introbio',
			'Barba',
			'Guardistallo',
			'Gressoney-Saint-Jean',
			'Gioia dei Marsi',
			'Giano Vetusto',
			'Gavirate',
			'Galluccio',
			'Frattamaggiore',
			'Frascati',
			'Forno Canavese',
			'Formello',
			'Foppolo',
			'Fontechiari',
			'Fonni',
			'Flavon',
			'Falerone',
			'Vibo Valentia',
			'Serrata',
			'Scido',
			'Saracena',
			'Santa Venerina',
			'Gerocarne',
			"Sant'Andrea Frius",
			'Santa Elisabetta',
			'Villa San Pietro',
			"San Mango d'Aquino",
			'San Basile',
			'Pozzallo',
			'Plataci',
			'Pachino',
			'Nardodipace',
			'Motta San Giovanni',
			'Mosorrofa',
			'Morano Calabro',
			'Monreale',
			'Mogorella',
			'Mineo',
			'Miggiano',
			'Mammola',
			'Letojanni',
			'Guardavalle',
			'Gratteri',
			'Gaggi',
			'Finale',
			'Ficarazzi',
			'Cerva',
			'Capizzi',
			'Caloppezzati',
			'Bruzzano Zeffirio',
			'Bovalino',
			'Bolognetta',
			'Bagaladi',
			'Aritzo',
			'Zovencedo',
			'Vergano-Villa',
			'Villavallelonga',
			'Villafranca in Lunigiana',
			'Viguzzolo',
			'Vigarano Pieve',
			'Veggiano',
			'Vecchiano-Nodica',
			'Varazze',
			'Vallecrosia',
			'Valentano',
			'Vaglio Basilicata',
			'Urgnano',
			'Tuglie',
			'Tromello',
			'Triuggio',
			'Torgiano',
			'Tolve',
			'Tito',
			'Tione di Trento',
			'Tiana',
			'Testico',
			'Terni',
			'Taipana',
			'Sternatia',
			'Spilamberto',
			'Sommariva del Bosco',
			'Sforzacosta',
			'Settimo Vittone',
			'Sennori',
			'Segni',
			'Secondigliano',
			'Scoppito',
			'Scarnafigi',
			'Savona',
			'Satriano di Lucania',
			'Sasso di Castalda',
			'Sarezzano',
			'San Vincenzo Valle Roveto',
			'San Stino di Livenza',
			'Santerno',
			'Santa Margherita Ligure',
			'San Polo',
			'San Piero in Bagno',
			'San Paolo Albanese',
			'San Mauro Pascoli',
			"San Maurizio D'Opaglio",
			'San Marco in Lamis',
			'San Leonardo in Passiria',
			'San Donaci',
			'San Biagio di Callalta',
			'San Benedetto in Perillis',
			'San Bartolomeo in Galdo',
			'Saccolongo',
			'Sabbia',
			'Sabaudia',
			'Rufina',
			'Rovato',
			"Rive d'Arcano",
			'Rionero Sannitico',
			'Rezzoaglio',
			'Rezzo',
			'Ravarino',
			'Prato Sesia',
			'Pratola Peligna',
			'Possagno',
			'Portovenere',
			'Porto San Giorgio',
			'Poppi',
			'Ponte A Elsa',
			'Poggio Renatico',
			'Pofi',
			'Pisoniano',
			'Pimonte',
			'Pieve Torina',
			'Pieve',
			'Piegaro',
			'Pianezze',
			'Piancastagnaio',
			'Pian Camuno',
			'Lama Pezzoli',
			'Pettinengo',
			'Pessina Cremonese',
			'Pescosolido',
			"Penna Sant'Andrea",
			'Paullo',
			'Passarella',
			'Parzanica',
			'Palombara Sabina',
			'Palinuro',
			'Palanzano',
			'Padula',
			'Ostellato',
			"Ospedaletto d'Alpinolo",
			'Osoppo',
			'Orune',
			'Ortisei',
			'Olginate',
			'Nova Ponente',
			'Nocera Superiore',
			'Murazzano',
			'Mulazzo',
			'Montieri',
			'Montichiari',
			'Monteviale',
			'Montese',
			'Monte San Biagio',
			'Monte Roberto',
			'Monteiasi',
			'Montegiorgio',
			'Montefredane',
			'Montalcino',
			'Mezzoldo',
			'Mestrino',
			'Mercallo',
			'Meolo',
			'Massazza',
			'Massa',
			'Marco',
			'Marcignana',
			'Marano Lagunare',
			'Mara',
			'Malgrate',
			'Magliano in Toscana',
			'Luzzana',
			'Lugagnano',
			'Lucinasco',
			'Loculi',
			'Loano',
			'Lerici',
			'La Maddalena',
			'Lagonegro',
			'Isola del Liri',
			'Imperia',
			'Grignasco',
			'Gradoli',
			'Genzano di Lucania',
			'Gambassi Terme',
			'Fonzaso',
			'Fontanetto Po',
			'Fiume Veneto',
			'Ferrada',
			'Farra di Soligo',
			'Falvaterra',
			'Falmenta',
			'Fabrica di Roma',
			'Episcopia',
			'Entracque',
			'Denno',
			'Dambel',
			'Cusano',
			'Crugnola',
			'Crema',
			'Cortemilia',
			'Cormano',
			'Coriano',
			'Colturano',
			'Cologna Veneta',
			'Collevecchio',
			'Collegiove',
			'Cicognolo',
			'Cervere',
			"Cerreto d'Esi",
			'Cerano',
			'Celenza Valfortore',
			'Ceggia',
			'Cavriana',
			'Cavezzo',
			'Cave',
			'Castel Vittorio',
			'Castel San Niccolò',
			'Castelnuovo Don Bosco',
			'Castelnuovo Berardenga',
			'Castel Maggiore',
			'Castello di Godego',
			'Casteldelfino',
			'Castel Condino',
			'Cassacco',
			'Casasco',
			'Casapulla',
			'Capri',
			'Caprarica di Lecce',
			'Cantalupo Ligure',
			'Canneto Pavese',
			'Campochiaro',
			'Campiglia Cervo',
			'Camparada',
			'Calvenzano',
			'Cagnano Varano',
			'Cadelbosco di Sopra',
			'Buonalbergo',
			'Buccino',
			'Brosso',
			'Boscoreale',
			'Borgo Santa Maria',
			'Borgo Podgora',
			'Boffalora Sopra Ticino',
			'Darfo Boario Terme',
			'Bibano',
			'Berchidda',
			'Belmonte Castello',
			'Beinasco',
			'Bassignana',
			'Barete',
			'Bagnoli',
			'Rabbi Fonti',
			'Baceno',
			'Avigliana',
			'Arsiero',
			'Arpaia',
			'Arborio',
			'Anzio',
			'Antrodoco',
			'Annone Veneto',
			'Ameglia',
			'Amaseno',
			'Amalfi',
			'Almese-Rivera',
			'Albuzzano',
			'Albiano Magra',
			'Alatri',
			'Aielli',
			'Agnone',
			'Accumoli',
			'Accettura',
			'Abetone',
			'Abbasanta',
			'Orsago',
			'Povegliano',
			'Mediglia',
			'Arcisate',
			'Frignano',
			'Brozolo',
			'Campiglione',
			'Roddi',
			'Pinasca-Dubbione',
			'Castel Boglione',
			'Conzano',
			'Agliano Terme',
			'Bodio Lomnago',
			'Bresimo',
			'Verretto',
			'Caines - Kuens',
			'Maniace',
			'Mezzana Mortigliengo',
			'Villar San Costanzo',
			'Albugnano',
			'Soglio',
			'Cuasso al Monte',
			'Gessate',
			'Boltiere',
			'Marcignago',
			'Gualdo',
			'Giuncugnano',
			'Mele',
			'Talamona',
			'Pontoglio',
			'Frassilongo',
			"Villa Sant'Antonio",
			'Ronco Biellese',
			'Sagliano Micca',
			"Cervignano d'Adda",
			'Filandari',
			'Castagnole Piemonte',
			'Casalbeltrame',
			'Cureggio',
			'Canosio',
			'Cerreto Langhe',
			'Farigliano',
			'Frabosa Sottana',
			'Cerreto Grue',
			'San Germano dei Berici',
			'Monte Porzio',
			'Roppolo',
			'Ello',
			'Somaglia',
			'Ruino',
			'Trausella',
			'Lisio',
			'Cavagnolo',
			'Scena - Schenna',
			'Triscina',
			'Villa Fornace',
			'Trestina',
			'San Giacomo',
			'San Nicolò a Tordino',
			'Toscanella',
			"Casette d'Ete",
			'S.P. in Palazzi',
			'Monte Migliore La Selvotta',
			'Caldine',
			'Piediripa',
			'Galta',
			'Villa Musone',
			'Zoccorino-Vergo',
			'Roreto',
			'Marcellina',
			'Santo Stefano',
			'Case Campoli-Panetta',
			'Niviano',
			'Strada',
			'Sam Marino',
			'Tetti Neirotti',
			'Capriglia',
			'Fuorni',
			'Cantinella',
			'Foresta',
			'Triginto',
			'Cartiera-Stazione',
			'Campese',
			'Stradella',
			'Santa Croce Scuole',
			'Lassolaz',
			'Centro Urbano',
			'Fleccia-Chianavasso',
			'Camerana',
			'Ghiaie',
			'Chiusi Scalo',
			"Ca' Morosini",
			'Mappano',
			'Dozza',
			'Dimaro',
			'Cuglieri',
			'Crova',
			'Crognaleto',
			'Crespina',
			'Correggio',
			'Corgeno',
			'Cordignano',
			'Corbetta',
			'Corana',
			'Consandolo',
			'Condove',
			'Comiziano',
			'Civitaquana',
			'Civitaluparella',
			'Gionghi-Cappella',
			'Chienes',
			'Champdepraz',
			'Cerasolo',
			'Cellole',
			'Celle di San Vito',
			'Celle Ligure',
			'Castino',
			"Castel Sant'Angelo",
			'Castel Ritaldi',
			'Castellina in Chianti',
			'Castel Fusano',
			'Castelfranco di Sotto',
			'Castel del Piano',
			'Caselette',
			'Casazza',
			'Casalpusterlengo',
			'Casalmoro',
			'Casali',
			'Casale sul Sile',
			'Casalborgone',
			'Carpasio',
			'Carassai',
			'Caprarola',
			'Camporosso',
			'Campi Bisenzio',
			'Calendasco',
			'Busca',
			'Brusasco',
			'Bresso',
			'Bovolone',
			'Bosisio Parini',
			'Boscotrecase',
			'Borgo Sabotino-Foce Verde',
			'Borghi',
			'Bolognola',
			'Bergantino',
			'Bergamo',
			'Bereguardo',
			'Belluno',
			'Basiliano',
			'Banzi',
			'Badia Calavena',
			'Aulla',
			'Arienzo',
			'Arezzo',
			'Ardara',
			'Arcore',
			'Alezio',
			'Albino',
			'Ala',
			'Airasca',
			'Agerola',
			'Acquanegra sul Chiese',
			'San Vito al Torre',
			'Pero',
			'Bressa',
			'Colugna',
			'Vacchereccia',
			'Santena',
			'Carezzano Maggiore',
			'Coniolo Bricco',
			'Champorcher',
			'Introd',
			'Gironico al Piano',
			'Nobile-Monguzzo',
			'Pellio Intelvi',
			'Spinone al Lago',
			'Segonzano',
			'Frazione Chiesa',
			'Saint-Nicolas',
			'Cavaso del Tomba',
			'Valledoria',
			'Colle Brianza',
			'Jonadi',
			'San Martino Alfieri',
			'Francavilla Bisio',
			'Piovera',
			'Jerago Con Orago',
			'Corrido',
			'Albosaggia',
			'San Vittore Olona',
			'Derovere',
			'Malagnino',
			'Pove del Grappa',
			'Castelnovo Bariano',
			'Villamiroglio',
			'Buguggiate',
			'Germignaga',
			'Carugo',
			'Montagna in Valtellina',
			'Niardo',
			'Zone',
			'Mariana Mantovana',
			'Bondo',
			'Carzano',
			'Chiusa di San Michele',
			"Villa Sant'Angelo",
			'Belcastro',
			'Furnari',
			'Magnano',
			'Pieve Fissiraga',
			'Perosa Canavese',
			'Strambinello',
			'Vocca',
			'Sale San Giovanni',
			'Gremiasco',
			'Loreglia',
			'Valda',
			'San Pietro in Cerro',
			'Carinaro',
			'Piana di Monte Verna',
			'Roccabascerana',
			'Gagliato',
			'Castelletto Cervo',
			'Ponderano',
			'Caselle Landi',
			'Blello',
			'Onore',
			'Cecima',
			'Cigliè',
			'La California',
			'Cazzago-Ex Polo',
			'Marina di Cerveteri',
			'Cerbara',
			'Tre Fontane',
			'San Vigilio',
			'Spicchio-Sovigliana',
			'Canalicchio',
			'Pontasserchio',
			"Piazza del Galdo-Sant'Angelo",
			'Solarolo',
			'Garino',
			"Villa d'Agri",
			'Carrara-Pontenuovo',
			'Fabrizio',
			'Banzano',
			'Ottava',
			'Domio',
			'Sala al Barro',
			'Brenno Useria',
			'Dragonea',
			'Villanova',
			'Mantegazza',
			'Monticello di Fara',
			'Bancali',
			'Berzantina',
			"Sant'Antonio",
			'San Rocco',
			'Petrosino',
			'Pradelle',
			'Ozzano Taro',
			'Poggio Ellera',
			'Pugliano',
			'Piano',
			'Melia',
			'Piccarello',
			'Montariolo',
			'Saint-Rhémy',
			'Borgata Marina',
			"San Carlo All'Arena",
			'Scampia',
			"Farra d'lsonzo",
			'Torretta',
			'Santa Teresa di Riva',
			'San Martino di Finita',
			'San Floro',
			'Ravanusa',
			'Randazzo',
			'Perdasdefogu',
			'Palizzi Marina',
			'Montebello Jonico',
			'Montagnareale',
			'Menfi',
			'Loceri',
			'Lattarico',
			'Giffone',
			'Frascineto',
			'Cirò Marina',
			'Castiadas',
			'Carpanzano',
			'Capo Rizzuto',
			'Candidoni',
			'Campana',
			'Anoia Superiore',
			'Zeme',
			'Volongo',
			'Vitulazio',
			'Villalfonsina',
			'Villafranca Piemonte',
			'Viggiano',
			'Vibonati',
			'Vezzano',
			'Vernio',
			'Vercelli',
			'Varallo Pombia',
			'Vanzago',
			'Bondione',
			'Trivignano Udinese',
			'Tredozio',
			'Traversella',
			'Trasaghis',
			'Trani',
			'Tragliatella Campitello',
			'Torreglia',
			'Terrazzo',
			'Terento',
			'Teora',
			'Tavazzano',
			'Taggia',
			'Spinazzola',
			'Sorbo Serpico',
			'Sigillo',
			'Siena',
			'Siano',
			'Sesto Fiorentino',
			'Selva dei Molini',
			'Sedrina',
			'Scontrone',
			'Schio',
			'Scheggino',
			'Savigno',
			'Santorso',
			"Sant'Eufemia a Maiella",
			'Sante Marie',
			'Santa Teresa Gallura',
			'Santarcangelo',
			"Sant'Angelo le Fratte",
			'Santa Maria a Monte',
			"Sant'Albano Stura",
			"Sant'Agata sui Due Golfi",
			'Santa Croce',
			'San Pancrazio',
			'San Mauro',
			'San Giovanni Ilarione',
			'San Germano Vercellese',
			'San Fiorano',
			'San Felice',
			'San Biagio',
			'San Benedetto Po',
			'San Benedetto dei Marsi',
			'Salcedo',
			'Sagama',
			'Russi',
			'Rubiana',
			'Roccapiemonte',
			'Rocca di Botte',
			'Ripalta Nuova',
			'Rio di Pusteria',
			'Riccò del Golfo',
			'Resiutta',
			'Quero',
			'Quarrata',
			'Priverno',
			'Priola',
			'Prascorsano',
			'Posatora',
			'Pont-Canavese',
			'Poncarale',
			'Pomarance',
			'Pollenza',
			'Polino',
			'Ploaghe',
			'Pisticci',
			'Pietra la Croce',
			'Pianiga',
			'Pergine Valsugana',
			'Pennadomo',
			'Pavullo nel Frignano',
			'Parella',
			'Palaia',
			'Paglieta',
			'Nusco',
			'Novello',
			'Nichelino',
			'Nepi',
			'Bricco di Neive',
			'Muros',
			'Muggiò',
			'Mottola',
			'Motta Montecorvino',
			'Mornago',
			'Mori',
			'Mores',
			'Montiano',
			'Montefalcone nel Sannio',
			'Montaquila',
			'Monsummano Terme',
			'Momo',
			'Melfi',
			'Meduno',
			'Mattinata',
			'Matino',
			'Maslianico',
			'Martano',
			'Marina di Carrara',
			'Mapello',
			'Manoppello',
			'Maerne',
			'Macerata',
			'Lula',
			'Lucca',
			'Lorenzana',
			'Lonigo',
			'Livigno',
			'Licusati',
			'Levanto',
			'Lesina',
			'Lavezzola',
			'Laurenzana',
			'Lacedonia',
			'Imola',
			'Guardea',
			'Guarcino',
			'Gualdo Tadino',
			'Grumello Cremonese',
			'Grottazzolina',
			'Groscavallo',
			'Gorgoglione',
			'Giussago',
			'Ginosa',
			'Giaveno',
			'Germagnano',
			'Genzano di Roma',
			'Gardone Val Trompia',
			'Galliate',
			'Galatone',
			'Gaiola',
			'Gaggio Montano',
			'Fortezza',
			'Flero',
			'Finale Emilia',
			'Ferentino',
			'Faggiano',
			'Fabbriche di Vallico',
			'Villafranca Sicula',
			'Soverato Marina',
			'Sibari',
			'Sartano',
			'Santa Domenica',
			'San Piero Patti',
			'San Lucido',
			'San Ferdinando',
			'Ragusa',
			'Portigliola',
			'Pedivigliano',
			'Pedace-Perito',
			'Patù',
			'Palmi',
			'Nicosia',
			'Musei',
			'Monterosso Calabro',
			'Mazzarino',
			'Marausa',
			'Malito',
			'Leonforte',
			'Itala',
			'Grammichele',
			'Gonnostramatza',
			'Gonnosnò',
			'Gemini',
			'Gangi',
			"Forza d'Agrò",
			'Firmo',
			'Cinisi',
			'Celico',
			'Carini',
			'Cariati',
			'Caccuri',
			'Acquedolci',
			'Olia Speciosa',
			'Santa Maria Navarrese',
			'Zogno',
			'Zanè',
			'Vobarno',
			'Vittorio Veneto',
			'Vinci',
			'Villa Santo Stefano',
			'Villaricca',
			'Vigolzone',
			'Vicarello',
			'Vestone',
			'Verceia',
			'Varmo',
			'Varese',
			'Varedo',
			'Valduggia',
			'Ururi',
			'Tricarico',
			'Traversetolo',
			'Tossicia',
			'Torre Pellice',
			'Turin',
			'Torgnon',
			'Tonadico',
			'Tirano',
			'Teor',
			'Sulzano',
			'Soverzene',
			'Sotto il Monte Giovanni XXIII',
			'Sossano',
			'Sorisole',
			'Soncino',
			'Sillano',
			'Serravalle di Chienti',
			'Selvino',
			'Schiavi di Abruzzo',
			'Scarperia',
			'Scapoli',
			'Scanzano Jonico',
			'Scano di Montiferro',
			'Sauze di Cesana',
			'Sassinoro',
			'San Valentino in Abruzzo Citeriore',
			'San Pietro in Lama',
			'San Michele Mondovì',
			'San Martino in Argine',
			'San Martino della Battaglia',
			'San Lorenzo Nuovo',
			'Sanguinetto',
			'San Giorgio del Sannio',
			'San Castrese',
			'San Candido',
			'San Biagio della Cima',
			'Ronco Canavese',
			'Romano Canavese',
			'Roccadaspide',
			'Robbio',
			'Rivara',
			'Vignale Riotorto',
			'Rignano Flaminio',
			'Reano',
			"Quinzano d'Oglio",
			'Quassolo',
			'Pozza',
			'Porpetto',
			'Popoli',
			'Pombia',
			'Pogno',
			'Podenzano',
			'Pieve del Cairo',
			'Piansano',
			'Pianoro',
			'Piano di Follo',
			'Pescia Romana',
			'Bettola-Zeloforomagno',
			'Pellizzano',
			'Pasian di Prato',
			'Paganica-Tempera',
			'Paderno Dugnano',
			'Padergnone',
			'Ottaviano',
			'Ospedaletto',
			'Osnago',
			'Osimo',
			'Orzinuovi',
			'Ortignano Raggiolo',
			'Orsogna',
			'Occimiano',
			'Noventa Vicentina',
			'Nonantola',
			'Nocera Umbra',
			'Nettuno',
			'Mortara',
			'Monte San Giovanni Campano',
			'Montepulciano Stazione',
			'Monte Porzio Catone',
			'Montelabbate',
			'Montebello Vicentino',
			'Montale',
			'Montà',
			'Monopoli',
			'Miglianico',
			'Mel',
			'Marzio',
			'Martinsicuro',
			'Martellago',
			'Malesco',
			'Maglie',
			'Loranzè',
			'Lomazzo',
			'Locana',
			'Lizzanello',
			'Lioni',
			'Lido di Ostia',
			'Leverano',
			'La Morra',
			'Laghetti',
			'Ladispoli',
			'Grottolella',
			'Fontane-Zurane-Gresine',
			'Golfo Aranci',
			'Gera Lario',
			'Gamalero',
			'Front',
			'Forno di Zoldo',
			'Fonteblanda',
			'Fiumalbo-Dogana',
			'Fiorano',
			'Fardella',
			'Falcade Alto',
			'Erbezzo',
			'Elice',
			'Cretone',
			'Cozzana',
			'Costa Valle Imagna',
			'Correzzola',
			'Copparo',
			'Contursi Terme',
			'Conca Casale',
			'Como',
			'Cologne',
			'Civitella del Tronto',
			'Cirigliano',
			'Chiaravalle',
			'Cesenatico',
			'Cencenighe Agordino',
			'Ceglie Messapica',
			'Castri di Lecce',
			'Castiglione del Lago',
			'Castelvecchio Calvisio',
			'Castelnuovo di Farfa',
			'Castel Madama',
			'Castel di Tora',
			'Castagnole Monferrato',
			'Casperia',
			'Casoni',
			'Casciana Terme',
			'Casalserugo',
			'Casalfiumanese',
			'Casaleone',
			'Cartignano',
			'Carpi Centro',
			'Carlino',
			'Capua',
			'Capoliveri',
			'Candela',
			'Campodipietra',
			'Campanarello',
			'Camigliano',
			'Camaiore',
			'Calvizzano',
			'Calice al Cornoviglio',
			'Buttrio',
			'Buttapietra',
			'Buglio in Monte',
			'Brienno',
			'Borgo Vercelli',
			'Borgo a Buggiano',
			'Borghetto',
			'Boretto',
			'Bonassola',
			'Bobbio',
			'Biandrate',
			'Bettolle',
			'Bene Vagienna',
			'Belmonte Piceno',
			'Bellagio',
			'Baunei',
			'Barco',
			'Barberino di Mugello',
			'Bagnoli del Trigno',
			'Bagnara di Romagna',
			'Badia Polesine',
			'Auditore',
			'Ardesio',
			'Angolo Terme',
			'Andria',
			'Albano Vercellese',
			'Aiello del Sabato',
			'Marina Romea',
			'Puia-Villanova',
			"Fiesso d'Artico",
			'Clodig',
			'Dolegna del Collio',
			'Pieve Emanuele',
			'Lumezzane',
			"Rocca De' Baldi",
			'Val della Torre',
			'Refrancore',
			'Blessagno',
			'San Siro',
			'Sernio',
			'Assago',
			'Pedrengo',
			'Piazzolo',
			'Ubiale Clanezzo',
			'Incudine',
			"Velo d'Astico",
			'Larciano',
			'Castel di Sasso',
			'Rodengo-Saiano',
			'Villagrande',
			'Castello di Brianza',
			"Montechiaro d'Acqui",
			'Castello Cabiaglio',
			'Fenegrò',
			'Mezzago',
			'Daiano',
			'Altissimo',
			'Sarego',
			'Valtopina',
			'Cuvio',
			'Morazzone',
			'Sangiano',
			'Cassina Rizzardi',
			'Bellinzago Lombardo',
			'Cortenuova',
			"Torre d'Arese",
			'Villa Poma',
			'Pila',
			'Ospedaletto Lodigiano',
			'Chiaverano',
			'Macello',
			'Castelnuovo di Ceva',
			'Lequio Berria',
			'Santo Stefano Roero',
			'Somano',
			'Olmo Gentile',
			'Nogarole Vicentino',
			'Serrungarina',
			'Tufo',
			'Ollastra',
			'Coreglia Ligure',
			'Sorico',
			'Andalo Valtellino',
			'Noviglio',
			'Pregnana Milanese',
			'Barone Canavese',
			'Castellar',
			'Feisoglio',
			'Passoscuro',
			'Lu Bagnu',
			'Valgatara',
			'Ponte Felcino',
			'San Marco',
			'Fontana delle Monache',
			'Weather Station',
			'Piazzola',
			'Roveleto',
			'San Rocco',
			'Gallo-Tre Re-Mezzana Corti',
			'Lucino',
			'Montan-Angelin-Arensod',
			'Dodici Morelli',
			'Geromina',
			'Gello',
			'Buffalora-Bettole',
			'Borgo Melano',
			'Guidomandri Marina',
			'Zorlesco',
			'Cinzano',
			'Rivotorto',
			'Musci',
			'Spinimbecco',
			'Pievedizio',
			'Costa Lambro',
			'Rosario',
			'Favari-Avatanei',
			'San Nico',
			'Dosso-Ville',
			'La Villa',
			'Longhi',
			'Santa Caterina dello Ionio Marina',
			'Meano',
			'Fabro Scalo',
			'Calvi Risorta',
			'Vicaria',
			'San Ferdinando',
			'Eboli',
			'Dossobuono',
			'Dolcè',
			'Decima',
			'Cugnoli',
			'Cuggiono',
			'Costanzana',
			'Cossato',
			'Cortona',
			'Comun Nuovo',
			'Cologno Monzese',
			'Cogolo',
			'Classe',
			'Civitella di Romagna',
			'Città della Pieve',
			'Cislago',
			'Cineto Romano',
			'Chiusi della Verna',
			'Cessole',
			"Cavenago d'Adda",
			'Cavacurta',
			'Castelplanio',
			"Castell'Alfero",
			'Castel del Giudice',
			'Casalnoceto',
			'Casalbore',
			'Casacalenda',
			'Carmignano',
			'Carcoforo',
			'Caravino',
			'Capriana',
			'Caporciano',
			"Canale d'Agordo",
			'Campodolcino',
			'Camerano',
			'Calizzano',
			'Caira',
			'Brugine',
			'Borgosatollo',
			'Borgone Susa',
			'Bordolano',
			'Bevagna',
			'Bellegra',
			'Baselice',
			'Bascapè',
			'Balmuccia',
			'Atessa',
			'Ascoli Piceno',
			'Arola',
			'Arena Po',
			'San Michele',
			'Anzano di Puglia',
			'Alzano Lombardo',
			'Altare',
			'Albonese',
			'Abbadia Lariana',
			'Rivazzurra',
			'Santo Stefano-Carraie',
			'Casier',
			'Biancade',
			'Orsaria',
			'Tramonti di Sotto',
			'Concesio',
			"San Cipriano d'Aversa",
			'Alba Adriatica',
			'Cesano Boscone',
			'Cellarengo',
			'Ferrere',
			'Montabone',
			'Saltrio',
			'Bregnano',
			'Grandola ed Uniti',
			'Cenate Sopra',
			"San Paolo d'Argon",
			'Scano al Brembo',
			'Corvino San Quirico',
			'Bastida Pancarana',
			'Vallarsa',
			'Brenzone',
			'Sarnonico',
			'Angiari',
			'Monte Grimano Terme',
			'Osteria dei Cacciatori-Stella',
			'Molino Vecchio-Scapitola-Baio',
			'Funes - Villnoess',
			'San Martino in Badia',
			'Soprana',
			'Tigliole',
			'Cugliate-Fabiasco',
			'Colzate',
			'Fuipiano Valle Imagna',
			'Mason Vicentino',
			'Capolona',
			'Baldissero Canavese',
			'Doues',
			'Marchirolo',
			'Sovico',
			'Berbenno',
			"Villa d'Adda",
			'Sommo',
			'Cantalupa',
			'Colazza',
			'Aisone',
			'San Vitaliano',
			'Sostegno',
			'Villanova Biellese',
			'Verderio Superiore',
			'Mattie',
			'San Colombano Belmonte',
			'Guarene',
			'Macra',
			'Priocca',
			'Torresina',
			'Treiso',
			'Alzano Scrivia',
			'Crevoladossola',
			'Strembo',
			'Villa Collemandina',
			'San Nazzaro',
			'Morino',
			'Miagliano',
			'Quaregna',
			'Sirone',
			'Santo Stefano Lodigiano',
			'Monte Colombo',
			'Zungri',
			'Toceno',
			'Branzi',
			'Brembate di Sopra',
			'Castel Rozzone',
			'Vialfrè',
			'Lequio Tanaro',
			'Focene',
			'Calerno',
			'Donoratico',
			'Acitrezza',
			'Caselle',
			'Massa Finalese',
			'Villanova',
			"Sant'Andrea Ionio Marina",
			'Andreotta',
			'Ciaculli',
			'Villa Raspa',
			'Laghetto',
			'Budrio',
			'Taverna',
			'Ponticella',
			'Pascarola',
			'Piano dei Geli',
			'San Giovanni',
			'Casola',
			'Pagliare',
			'Gorgo della Chiesa',
			'Agliandroni-Paternella',
			"Monti d'Arena-Bosco Caggione",
			'Sabbionara',
			'Tavernelle',
			'Borgo',
			'Casa del Diavolo',
			'Sfaranda',
			'Masseria Vecchia Ovest',
			'Pezzan',
			'Gaiano',
			'Montale',
			'Covelo',
			'San Zenone',
			'Camerano',
			'Antagnod',
			'Fontanafredda',
			'Piedimonte San Germano Alta',
			'Dimaro-Folgarida',
			'Arenella',
			'Tor Vergata',
			'Valdina',
			'Trecastagni',
			'Soleminis',
			'Serradifalco',
			'Scoglitti',
			'Scandale',
			'Santa Maria di Licodia',
			'Santa Caterina Villarmosa',
			'San Marco Argentano',
			'San Lorenzo',
			'San Giovanni in Fiore',
			'San Filippo del Mela',
			'Sambiase',
			'Roccabernarda',
			'Pedagaggi',
			'Nureci',
			'Novara di Sicilia',
			'Nocera Terinese',
			'Nicastro',
			'Mazara del Vallo',
			'Marano Marchesato',
			'Guamaggiore',
			'Giardinello',
			'Falerna',
			'Collinas',
			'Ciavolo',
			'Cesarò',
			'Casignana',
			'Capaci',
			'Belvedere Marittimo',
			'Badolato',
			'Allai',
			'Villa Verucchio',
			"Villanova d'Albenga",
			"Villafranca d'Asti",
			'Villa',
			'Vigo di Cadore',
			'Vellezzo Bellini',
			'Vallecorsa',
			'Vaiano Cremasco',
			'Vacone',
			'Tronzano Lago Maggiore',
			"Trinità d'Agultu e Vignola",
			'Tramonti di Sopra',
			'Torricella',
			'Terracina',
			'Taceno',
			'Susa',
			'Subbiano',
			'Storo',
			'Solarolo Rainerio',
			'Sogliano al Rubicone',
			'Soave',
			'Settefrati',
			'Sesta Godano',
			'Servigliano',
			'Senago',
			'Semiana',
			'Scheggia',
			'Scaldasole',
			'Sarule',
			'Sarno',
			'Sarentino',
			'San Vito',
			'San Valentino',
			'Santo Stefano Ticino',
			'Santa Rufina',
			"Sant'Apollinare",
			"Sant'Angelo Limosano",
			"Sant'Andrea in Casale",
			"Sant'Anatolia di Narco",
			'Santa Maria Capua Vetere',
			'Sansepolcro',
			'San Polomatese',
			'San Pietro Viminario',
			'San Martino Valle Caudina',
			"San Martino Dall'Argine",
			'San Martino Canavese',
			'San Giusto Canavese',
			'San Giovanni in Persiceto',
			'San Giorgio di Pesaro',
			'San Donato Milanese',
			'San Bartolomeo In Bosco',
			'Ruffia',
			'Rudiano',
			'Rubiera',
			'Roverbella',
			'Ronzone',
			'Roncone',
			"Romans d'Isonzo",
			'Rome',
			'La Cretaz-Roisan',
			'Roccasecca dei Volsci',
			'Roccacasale',
			'Rivarolo Mantovano',
			'Riva Presso Chieri',
			'Ripacandida',
			'Rimasco',
			'Scena',
			'Pusiano',
			'Prarolo',
			'Prali',
			'Pozzuolo',
			"Porto Sant'Elpidio",
			'Pontecorvo',
			'Polverigi',
			'Polesella',
			'Poggibonsi',
			'Piode',
			'Pieve San Giacomo',
			"Pieve d'Olmi",
			'Pettorano sul Gizio',
			'Petriolo',
			'Pescosansonesco Nuovo',
			'Pescolanciano',
			'Pescaglia',
			'Perca',
			'Parona',
			'Parma',
			'Paese',
			'Otricoli',
			'Oschiri',
			'Orsara di Puglia',
			'Orani',
			'Oppido Lucano',
			'Ofena',
			'Oderzo',
			'Nocelleto',
			'Nespolo',
			'Narni Scalo',
			'Mozzanica',
			'Motta Dè Conti',
			'Mosso Santa Maria',
			'Montefalcione',
			'Montedinove',
			'Montecchia di Crosara',
			'Montecatini-Terme',
			'Montecalvoli',
			'Mollia',
			'Mühlen in Taufers',
			'Molazzana',
			'Molare',
			'Mignano Monte Lungo',
			'Merlara',
			'Melara',
			'Massello',
			'Massa',
			'Martis',
			'Magnago',
			'Magenta',
			'Lu',
			'Lodine',
			'Livo',
			'Levice',
			'Lestans',
			'Lavis',
			'Lasa',
			'Lanciano',
			'La Loggia',
			'Lacco Ameno',
			'Isernia',
			'Inverigo',
			'Borgo Hermada',
			'Guarda Veneta',
			'Grotte Santo Stefano',
			'Gosaldo',
			'Gioia del Colle',
			'Gallio',
			'Gaglianico',
			'Fornovo di Taro',
			'Fivizzano',
			'Filiano',
			'Filettole',
			'Filettino',
			'Ferrazzano',
			'Favale di Malvaro',
			'Fara in Sabina',
			"Fara Gera d'Adda",
			'Faicchio',
			'Cernusco sul Naviglio',
			'Ceprano',
			'Cavajon Veronese',
			'Castelvecchio Subequo',
			'Castelnuovo Nigra',
			'Castel Gabbiano',
			'Castel Frentano',
			'Castel di Casio',
			'Casalino',
			'Villalba',
			'Ventimiglia di Sicilia',
			'Vallelunga Pratameno',
			'Valledolmo',
			'Ucria',
			'Taverna',
			'Sinopoli',
			'Silius',
			'Senis',
			'Selargius',
			'Santa Ninfa',
			'San Sostene',
			'San Pietro a Maida',
			'San Lorenzo del Vallo',
			'San Gavino Monreale',
			'San Donato di Ninea',
			'San Cataldo',
			'Salemi',
			'Rota Greca',
			'Roccalumera',
			'Raccuja',
			'Polia',
			'Piedimonte Etneo',
			'Piazza Armerina',
			'Olivadi',
			'Nuraminis',
			'Mottafollone',
			'Mesoraca',
			'Marittima',
			'Marano Principato',
			'Longobucco',
			'Las Plassas',
			'Gonnosfanadiga',
			'Crucoli',
			'Colosimi',
			'Ciminna',
			'Castrolibero',
			'Caraffa di Catanzaro',
			'Camastra',
			'Caccamo',
			'Buccheri',
			'Brancaleone-Marina',
			'Ballao',
			'Altofonte',
			'Zuglio',
			'Zoagli',
			'Ziano Piacentino',
			'Villa San Secondo',
			'Villaga',
			'Villa Caldari',
			'Vigolo Vattaro',
			'Vietri sul Mare',
			'Vico Canavese',
			'Vescovana',
			'Vertova',
			'Verres',
			'Valvasone',
			'Vallerotonda',
			'San Nicolò',
			'Tufino',
			'Trovo',
			'Trevico',
			'Trevi',
			'Torrita Tiberina',
			'Torrevecchia',
			'Torrazzo',
			'Tollo',
			'Tizzano Val Parma',
			'Tirrenia',
			'Terlago',
			'Terenzo',
			'Telgate',
			'Taurano',
			'Staffolo',
			'Stabbia',
			'Spinetta Marengo',
			'Sori',
			'Soprabolzano',
			'Solofra',
			'Solesino',
			'Sermide',
			'Scerne',
			'Scanno',
			'Savignano Irpino',
			'Sassoferrato',
			'Sassetta',
			'Sarcedo',
			'Sapri',
			'San Vito al Tagliamento',
			"Santa Vittoria d'Alba",
			"Sant'Angelo Romano",
			'Santa Maria A Vico',
			'San Sebastiano',
			'San Salvatore Monferrato',
			'San Mauro la Bruca',
			'San Martino in Freddana-Monsagrati',
			'San Mango Piemonte',
			'San Gregorio Magno',
			'San Casciano dei Bagni',
			'Rozzano',
			'Rosello',
			'Rosà',
			'Ronco Scrivia',
			'Quagliuzzo',
			'Predosa',
			'Predoi',
			'Pratella',
			'Civitanova Marche',
			'Porto Azzurro',
			'Pontedera',
			'Pontecagnano',
			'Pompei',
			'Poggio San Lorenzo',
			'Battifolle-Ruscello-Poggiola',
			'Poggio A Caiano',
			'Poggiardo',
			'Pizzighettone',
			'Pietralunga',
			'Pietradefusi',
			'Pieris',
			'Piazza',
			"Piano-Molini d'Isola",
			'Pianella',
			'Pescina',
			'Paternopoli',
			'Parrano',
			'Pallare',
			'Palagano',
			'Osiglia',
			'Opi',
			'Onifai',
			'Numana',
			'Novoli',
			'Novi Ligure',
			'Netro',
			'Musano',
			'Monte San Savino',
			'Montelanico',
			'Montegiordano',
			'Montefalcone Appennino',
			'Monte di Malo',
			'Montecorice',
			'Montalenghe',
			'Monfalcone',
			'Moena',
			'Misinto',
			'Mezzolara',
			'Melzo',
			'Marino',
			'Mardimago',
			'Maenza',
			'Macchia Valfortore',
			'Lusevera',
			'Lugnano in Teverina',
			'Lodi Vecchio',
			'Licciana Nardi',
			'Lettopalena',
			'Lauro',
			'Langosco',
			'Selci-Lama',
			'Issime',
			"Isola Sant'Antonio",
			"Isola del Gran Sasso d'Italia",
			'Iseo',
			'Jelsi',
			'Gussola',
			'Guardia Sanframondi',
			'Gropello Cairoli',
			'Gromlongo',
			'Gressoney-La-Trinitè',
			'Gravedona-San Gregorio',
			'Gozzano',
			'Gorizia',
			'Giglio Castello',
			'Giave',
			'Ghisalba',
			'Gargnano',
			'Gandino',
			'Futani',
			'Frisa',
			'Frigento',
			'Fregene',
			'Francolise',
			'Francavilla in Sinni',
			'Fossano',
			'Forenza',
			'Fontevivo',
			'Fontanigorda',
			"Fiorenzuola d'Arda",
			'Fiesse',
			'Feltre',
			'Falzè di Piave',
			'Fabriano',
			'Esine',
			'Erto',
			'Egna',
			'Curon Venosta',
			'Curno',
			'Maglione-Crosa',
			'Crespadoro',
			'Covo',
			'Corniglio',
			'Cornaiano',
			'Corinaldo',
			"Corigliano d'Otranto",
			'Coppito',
			'Colonna',
			'Colle Sannita',
			'Codevilla',
			'Cocquio',
			'Clauzetto',
			'Civezzano',
			'Civezza',
			'Cimitile',
			'Cilavegna',
			"Sant'Andrea-Pizzone-Ciamprisco",
			'Chions',
			'Charvensod',
			'Ceva',
			'Cesarolo',
			'Cereseto',
			'Cene',
			'Cecchini',
			'Catignano',
			"Castell'Azzara",
			'Castellaro',
			'Castellafiume',
			"Cassano d'Adda",
			'Casina',
			'Caselle',
			'Casal Velino',
			'Casalvecchio di Puglia',
			'Carvico',
			'Carlazzo',
			'Carceri',
			'Carano',
			'Capalbio',
			'Candida',
			'Canal San Bovo',
			'Campitello di Fassa',
			'Campitello',
			'Campalto',
			'Camerino',
			'Calangianus',
			'Caivano',
			'Cairano',
			'Bussi sul Tirino',
			'Burano',
			'Marchesino-Bovo',
			'Bosco Marengo',
			'Bosco Chiesanuova',
			'Borrello',
			'Bioglio-Portula-Andrè',
			'Berzano di San Pietro',
			'Belmonte del Sannio',
			'Belforte',
			'Progetto Case Bazzano',
			'Barbarasco',
			'Bacoli',
			'Aurelia',
			'Atri',
			'Angri',
			'Alvignano',
			'Alberobello',
			'Ala di Stura',
			'Agnadello',
			'Affile',
			'Acqualagna',
			'Acilia-Castel Fusano-Ostia Antica',
			'Lido Adriano',
			'Scardovari',
			'Pramaggiore',
			'Santa Lucia di Piave',
			'Marano',
			'Savogna',
			'Bedizzole',
			'Settimo Milanese',
			'San Nicola la Strada',
			'Volla',
			'Bernareggio',
			'Rodello',
			'Sinio',
			'Belveglio',
			'Maranzana',
			'Verrua Savoia',
			'Ostana',
			'Rovegno',
			'Carate Urio',
			'Prelà Castello',
			'Algua',
			"Urago d'Oglio",
			'Torcegno',
			'Terranova dei Passerini',
			'Castel Morrone',
			'Valdisotto',
			'Valtournenche',
			'Braies',
			'Capitignano',
			'Calasca-Castiglione',
			'Cavaglio-Spoccia',
			'Costa Vescovato',
			'Masio',
			'Mesero',
			'Roccagorga',
			"Cerano d'Intelvi",
			'Cucciago',
			'Isso',
			'Suisio',
			'Castrezzato',
			'Montirone',
			'Castelvisconti',
			'Amblar',
			'Calceranica al Lago',
			'Faedo',
			'Roccafiorita',
			'Tavigliano',
			'Costa Masnaga',
			'Garzigliana',
			'Pertusio',
			'Porte',
			'Moransengo',
			'Carrega Ligure',
			'Cazzano di Tramigna',
			'Sovizzo',
			'Filattiera',
			'Pace del Mela',
			'Gifflenga',
			'Cremella',
			'Pastorano',
			'Casciago',
			'San Fedele Intelvi',
			'Osasio',
			'Isasca',
			'Tonnarella',
			'Torvaianica',
			'Marina San Nicola',
			'Santo Stefano',
			'Cavallino',
			'Vado Centro',
			'Grunuovo-Campomaggiore San Luca',
			'San Floriano-Olmi',
			'Pratole',
			'Le Rughe',
			'San Damaso',
			'Pezzano-Filetta',
			'Onigo',
			'Torrion Quartara',
			'Pagliarone',
			'Quaderni',
			'Palazzolo',
			'Cologna-Caraverio',
			'San Martino in Trignano',
			'Castelletto',
			'Piane',
			'Piazza',
			'Osteria del Gatto',
			'Montebonello',
			'Sambughe',
			'La Rosa',
			'Borgo',
			'Santa Corinna',
			'Pontelangorino',
			'Giavenale',
			'Case Nuove',
			"Sant'Elena Irpina",
			'Crava',
			'Rivalta',
			'Quincod',
			'San Rocco',
			'Torvaianica Alta',
			'Dosso',
			'Dosolo',
			'Dogliola',
			'Deiva Marina',
			'Cusano Mutri',
			'Crevalcore',
			'Cottanello',
			'Costarainera',
			'Costa di Mezzate',
			'Cossombrato',
			'Conegliano',
			'Colledara',
			'Collazzone',
			'Civitacampomarano',
			'Citerna',
			'Cintano',
			'Cessapalombo',
			'Cerrione',
			'Ceraso',
			'Cepagatti',
			'Cava Manara',
			'Castiglione Olona',
			"Castiglione d'Intelvi",
			'Castel San Giorgio',
			'Castelnuovo della Daunia',
			'Castelnuovo Cilento',
			'Castelnuovo',
			"Castello dell'Acqua",
			'Castellalto',
			'Castelfidardo',
			'Castano Primo',
			'Casarsa della Delizia',
			'Casalnuovo Monterotaro',
			'Casalbuono',
			'Carugate',
			'Carsoli',
			'Carpino',
			'Carapelle',
			'Canischio',
			'Candia Canavese',
			'Campofilone',
			'Campodoro',
			'Campagna Lupia',
			'Camino',
			'Calto',
			'Calolziocorte',
			'Calestano',
			'Calcinato',
			'Caiazzo',
			'Buscate',
			'Budrio',
			'Budoia',
			'Brunico',
			'Boves',
			'Borzonasca',
			'Bortigali',
			"Borgoricco-San Michele delle Badesse-Sant'Eufemia",
			'Borgonovo Valtidone',
			'Borgiallo',
			'Bonito',
			'Bolzano',
			'Bocenago',
			'Bistagno',
			'Belvedere Ostrense',
			'Bassano del Grappa',
			'Barlassina',
			'Bardineto',
			'Baranzate',
			'Baranello',
			'Balangero',
			'Badalucco',
			'Avigliano Umbro',
			'Avelengo',
			'Atripalda',
			'Asti',
			'Arzergrande',
			'Arba',
			'Aquileia',
			'Annone di Brianza',
			'Anchione',
			'Altopascio',
			'Abbiategrasso',
			'Ormelle',
			'Torreselle',
			'Cercivento',
			'Pieria-Prato Carnico',
			'Granvilla',
			'Basiliano-Vissandone',
			'Leivi',
			'Noventa',
			'Montoro Superiore',
			'Casavatore',
			'Roddino',
			'Mombaruzzo',
			'Robella',
			'Aquila di Arroscia',
			'Casarza Ligure',
			'Beverino',
			'Andora',
			'Gornate Olona',
			'Valbrona',
			'Foresto Sparso',
			"Torre de' Roveri",
			'Montescano',
			'Bleggio Superiore',
			'Trambileno',
			'Serravalle a Po',
			'Soraga',
			'San Romano in Garfagnana',
			'Callabiana - Chiesa',
			'Orco Feglino',
			'Muscoline',
			"Cornedo All'Isarco",
			'Veglio',
			'Torre San Giorgio',
			'San Paolo Solbrito',
			'Alice Bel Colle',
			'Berzano di Tortona',
			'Gudo Visconti',
			'Sulbiate',
			'Bonate Sotto',
			'Madone',
			'Villadeati',
			'Jovencan',
			'Tovo San Giacomo',
			'Alzate Brianza',
			'Colonno',
			'Luisago',
			'Zelo Surrigone',
			'Montello',
			'Caino',
			'Briona',
			'Comignago',
			'San Pietro Clarenza',
			'Erve',
			'Gravere',
			'Montaldo Torinese',
			'Pecco',
			'Cavaglietto',
			'Castel Rocchero',
			'Penango',
			"Vigliano d'Asti",
			'Melazzo',
			"Farra d'Isonzo",
			'Orciano Pisano',
			'Curti',
			'Oppido Mamertina',
			'Merlino',
			'Salerano sul Lambro',
			'Vercana',
			'Lombriasco',
			'Massino Visconti',
			'Cissone',
			'Gottasecca',
			'Mosso',
			"Sant'Angelo",
			'Villa Adriana',
			'Ried',
			'Montepaone Lido',
			'Marco Simone',
			'Rablà',
			'Paolo VI',
			'Fiesso',
			'Ferriera',
			'Talponada',
			'Rilievo',
			'Zona 179',
			'Pionca',
			'Longara',
			'Area Produttiva',
			'Cavallina',
			'San Michele dei Mucchietti',
			'Pontegradella',
			'Montesolaro',
			'San Nicola',
			'Ronco',
			'Casale',
			'Marina',
			"Sant'Antonio",
			'Rosegaferro',
			'Passaggio',
			'Amato',
			'Cominio',
			'Calchera-Frontale',
			'Borgo',
			'Canove di Roana',
			'Castelbello',
			'Nucleo Industriale di Bazzano',
			'Villasalto',
			'Villanova Truschedu',
			'Viggianello',
			'Tripi',
			'Tramatza',
			'Tortora',
			'Terranova da Sibari',
			'Suelli',
			'Sinnai',
			'Simala',
			'Sersale',
			'Serri',
			'Scordia',
			'Sarroch',
			"Sant'Andrea Apostolo dello Ionio",
			'San Sosti',
			'Sanluri',
			'San Gregorio di Catania',
			'Roggiano Gravina',
			'Rende',
			'Raddusa',
			'Prizzi',
			'Pietrapaola',
			'Nicolosi',
			'Montesardo',
			'Montepaone',
			'Montelepre',
			'Militello Rosmarino',
			'Masullas',
			'Marrubiu',
			'Lentini',
			'Isola delle Femmine',
			'Grotteria',
			'Genuri',
			'Gallodoro',
			'Cortale',
			'Cirò',
			'Cenadi',
			'Castanea delle Furie',
			'Cassibile',
			'Cassaro',
			'Carolei',
			'Campofelice di Fitalia',
			'Cabras',
			'Belmonte Calabro',
			'Barrali',
			'Assolo',
			'Arbus',
			'Altomonte',
			'Agnana Calabra',
			'Zoppola',
			'Zola Predosa',
			'Zerbo',
			'Zambana',
			'Volturino',
			'Vivaro Romano',
			'Vivaro',
			'Viù',
			'Vitorchiano',
			'Villa Minozzo',
			'Vicopisano',
			'Viano',
			'Verghereto',
			'Vercurago',
			"Vaprio d'Adda",
			'Valva',
			'Valle Lomellina',
			'Vailate',
			'Ulà Tirso',
			'Tula',
			"Trezzo sull'Adda",
			'Trarego',
			'Torriglia',
			'Torricella',
			'Torre del Mangano',
			'Torrebruna',
			'Tezze',
			'Terzorio',
			'Terranuova Bracciolini',
			'Temù',
			'Tarcento',
			'Taneto',
			'Soccavo',
			'Siniscola',
			'Scandiano',
			'San Vito',
			'Santo Stefano del Sole',
			"Sant'Eusanio del Sangro",
			"Sant'Arcangelo",
			'Santa Maria di Sala',
			"Sant'Agostino",
			'San Rufo',
			'San Remo',
			'San Pietro In Vincoli',
			"San Martino d'Agri",
			'San Godenzo',
			'San Giorgio a Cremano',
			'San Cesareo',
			'Salzano',
			'Salvitelle',
			'Saletto-San Bartolomeo',
			'Romagnano',
			'Roio del Sangro',
			'Rocchetta',
			'Roccaraso',
			'Rocca di Papa',
			'Ro',
			'Riolo Terme',
			'Resana',
			'Quiliano',
			'Prè Saint Didier',
			'Prata Sannita Centro',
			"Prata d'Ansidonia",
			'Pozzuoli',
			'Pozzilli',
			'Posada',
			'Porto Valtravaglia',
			'Portico di Caserta',
			'Porcari',
			'Ponzone',
			'Ponte di Legno',
			'Polverara',
			'Piombino Dese',
			'Pieve Tesino',
			'Pieve Albignola',
			'Pietrastornina',
			'Pietracatella',
			'Piea',
			'Pianola',
			'Pianello Val Tidone',
			'Petacciato',
			'Perinaldo',
			'Pereto',
			'Pederobba',
			'Passirano',
			'Parabiago',
			'Pallerone',
			'Ovindoli',
			'Cursolo',
			'Occhieppo Superiore',
			'Noale',
			'Neviano degli Arduini',
			'Murlo',
			'Mugnano di Napoli',
			'Moscufo',
			'Monteverdi Marittimo',
			'Montechiarugolo',
			'Montecastrilli',
			'Montazzoli',
			'Montagna',
			'Moncucco Torinese',
			'Monastier di Treviso',
			'Monasterolo del Castello',
			'Merine',
			'Meldola',
			'Medole',
			'Matrice',
			'Mathi',
			'Massa Fiscaglia',
			'Masera',
			'Marti',
			'Marta',
			'Marina di Campo',
			'Manduria',
			'Mamoiada',
			'Malcesine',
			'Maiolati Spontini',
			'Magnano in Riviera',
			'Lustra',
			'Loseto',
			'Lograto',
			'Lenno',
			'Lecco',
			'Laterina',
			'Lama dei Peligni',
			'Lallio',
			'Izano',
			'Isola del Cantone',
			'Guazzino',
			'Grassano',
			'Grado',
			'Gordona',
			'Giulianello',
			'Ghedi',
			'Gemmano',
			'Gambarana',
			'Galatina',
			'Gaiarine',
			'Gagliole',
			'Fusine',
			'Fumone',
			'Fraine',
			'Fontegreca',
			'Fondo',
			'Folignano',
			'Foglizzo',
			'Feletto Umberto',
			'Falconara Marittima',
			'Crone',
			'Corato',
			'Conscio',
			'Condino',
			'Comerio',
			'Colleferro',
			'Codemondo-Quaresimo',
			'Civitella San Paolo',
			'Chiavari',
			'Chiari',
			'Cetara',
			'Cesano',
			'Cervo',
			'Certosa',
			'Cerreto Guidi',
			'Celleno',
			'Cavriago',
			'Castelnuovo Rangone',
			'Castelnuovo di Porto',
			'Castello',
			'Castelfiorentino',
			'Castel Bolognese',
			'Casalmaiocco',
			'Carenno',
			'Cantoira',
			'Cannobio',
			'Camporotondo di Fiastrone',
			'Camponogara',
			'Campomarino',
			'Campli',
			'Camisano',
			'Calenzano',
			'Caianello',
			'Cadine',
			'Cadelbosco di Sotto',
			'Burgos',
			'Bottidda',
			'Borgo a Mozzano',
			'Bibione',
			'Bianzone',
			'Bettola',
			'Bersezio',
			'Bastiglia',
			'Barghe',
			"Barano d'Ischia",
			'Baragiano',
			'Baiso',
			'Bagnolo del Salento',
			'Bagnolo Cremasco',
			'Bagnasco',
			'Aviano',
			'Bannio',
			'Andrate',
			'Amatrice',
			'Altidona',
			'Allumiere',
			'Alice Superiore',
			'Albiano',
			'Adria',
			'Bagnarola',
			'Pavia di Udine',
			'Bagnaria Arsa',
			'Candelù',
			'Padernello',
			'Dese',
			'San Liberale',
			'Sambruson',
			'Prepotto',
			'Chiaulis',
			'Marsure',
			'Campolongo al Torre-Cavenzano',
			'Quarto',
			'Trecase',
			'Gravina di Catania',
			'Monasterolo Casotto',
			'Torre Mondovì',
			'Consiglio di Rumo',
			'Val Rezzo',
			"Moio de' Calvi",
			'Santa Margherita di Staffora',
			'Casale Cremasco',
			'Gadesco-Pieve Delmona',
			'Prevalle',
			'Robecco Pavese',
			'Rovescala',
			'Villa-Agnedo',
			'Lavagno',
			'Pieve di Coriano',
			"Massanzago-Ca' Baglioni-San Dono",
			'Castelbianco',
			'Broccostella',
			'Cosio Valtellino',
			'Val Masino',
			'Puegnago sul Garda',
			'Rodengo - Rodeneck',
			'Cerreto Castello',
			'Garbagnate Monastero',
			'Monterosso Grana',
			'Olivola',
			'Pietra Marazzi',
			'Cassinetta di Lugagnano',
			'Barbata',
			'Frascarolo',
			'Enego',
			'Casteldelci',
			'Lambrugo',
			'Grone',
			'Morengo',
			'Trenzano',
			'Lirio',
			'Pinarolo Po',
			'Pianengo',
			'Schivenoglia',
			'Fornace',
			'Chiesanuova',
			'Fiorano Canavese',
			'Givoletto',
			'Meugliano',
			'Barengo',
			'Calosso',
			'Denice',
			'Grognardo',
			'Parodi Ligure',
			'Villa Rendena',
			'San Possidonio',
			'Lisciano Niccone',
			'Buseto Palizzolo',
			'Rometta',
			'Esporlatu',
			'Zubiena',
			'Barzago',
			'Barzio',
			'Nonio',
			'Senna Comasco',
			'Tavernerio',
			'Turate',
			'Paitone',
			'Mezzenile',
			'Fulgatore-Torretta',
			'Stiava',
			'Lisiera',
			'Casale di Mezzani',
			'Vico',
			'Tornimparte',
			'Cologna Spiaggia',
			'Monterusciello',
			'Gerbole',
			'Eremo',
			'Torelli-Torrette',
			'Genio Civile',
			'Chianchitta-Trappitello',
			'San Brancato',
			'Bojon-Lova',
			'Bellante Stazione',
			'Baganzola',
			'Arino',
			'Metato',
			'San Donato',
			'Maresso',
			'Locara',
			'Quattro Strade',
			'Villanova',
			'Costa-Barco',
			'Val di Cava',
			'Terradura',
			'Padiglione',
			'Cerquotti-Madonna del Piano',
			'Canavaccio',
			'Cascina Elisa',
			'Borgo Lotti',
			'Calozzo',
			'Casale',
			'Livera',
			'Codogna-Cardano',
			'Verano',
			'Atina Inferiore',
			'Paderno',
			'Pendino',
			'Monte Rosello',
			"Casal de' Pazzi",
			'Tor Tre Teste',
			'Verbicaro',
			'Tortolì',
			'Spilinga',
			'Sorbo San Basile',
			'Sinagra',
			'Selegas',
			'Santa Domenica Talao',
			"San Marco d'Alunzio",
			"San Gregorio d'Ippona",
			'Saline Ioniche',
			'Raffadali',
			'Pabillonis',
			'Niscemi',
			"Motta Sant'Anastasia",
			'Marsala',
			'Maletto',
			'Fiumedinisi',
			'Escolca',
			'Elmas',
			'Castelmola',
			'Camini',
			'Bari Sardo',
			'Armungia',
			'Antonimina',
			'Alì',
			'Alcara Li Fusi',
			"Aci Sant'Antonio",
			'Volpago del Montello',
			'Zibello',
			'Ziano di Fiemme',
			'Zelo Buon Persico',
			'Vobbia',
			'Visso',
			'Villimpenta',
			'Villefranche',
			'Villa del Conte',
			'Vignate',
			'Vicovaro',
			'Viadana',
			'Vergato',
			'Venturina',
			'Venaus',
			'Velturno',
			'Veleso',
			'Veglie',
			'Vattaro',
			'Varna',
			'Valle di Maddaloni',
			'Valdagno',
			'Vado Ligure',
			'Turriaco',
			'Tronzano Vercellese',
			'Trisobbio',
			'Tortona',
			'Torri del Benaco',
			'Torrebelvicino',
			'Toro',
			'Ticengo',
			'Terrossa',
			'Termeno',
			'Telese',
			'Tavernelle',
			'Tavarnuzze',
			'Talamello',
			'Tadasuni',
			'Supersano',
			'Strangolagalli',
			'Stella Cilento',
			'Sorso',
			'Sommariva Perno',
			'Siusi',
			'Sindia',
			'Signa',
			'Serramezzana',
			'Seren del Grappa',
			'Senna Lodigiana',
			'Sefro',
			'Sedegliano',
			'Scandriglia',
			'Scaltenigo',
			'Sassuolo',
			'Sassello',
			'Saracinesco',
			'San Vittore',
			'Santo Stefano Belbo',
			"Sant'Eusanio Forconese",
			"Sant'Elia Fiumerapido",
			'Santa Sofia',
			"Sant'Antonino di Susa",
			"Sant'Andrea di Conza",
			'Santa Lucia di Serino',
			"Sant'Agata sul Santerno",
			'San Paolo di Jesi',
			'San Martino Sannita',
			'San Martino del Lago',
			'San Leonardo',
			'San Giorgio la Molara',
			'San Cesario di Lecce',
			'San Benedetto Val di Sambro',
			'Salice Salentino',
			'Salerno',
			'Sairano',
			'Sagrado',
			'Rosia',
			'Rondissone',
			'Romana',
			'Romagnano Sesia',
			'Rognano',
			'Roccascalegna',
			'Robecchetto Con Induno',
			'Riva del Garda',
			'Rigolato',
			'Riese Pio X',
			'Ricigliano',
			'Riccione',
			'Rapino',
			'Racines',
			'Racconigi',
			'Quistello',
			'Quincinetto',
			'Quercianella',
			'Precenicco',
			'Prata Camportaccio',
			'Ponte San Pietro',
			'Ponte della Venturina',
			'Pontecurone',
			'Ponte a Ema',
			'Poiano',
			'Plaus',
			'Pietramurata',
			'Pietragalla',
			'Picciano',
			'Piazzola sul Brenta',
			'Pianura',
			'Pessione',
			'Perito',
			'Perfugas',
			'Pennabilli',
			'Pavone del Mella',
			'Palata',
			'Ottana',
			'Ornago',
			'Ordona',
			'Noha',
			'Neirone',
			'Narbolia',
			'Musso',
			'Muro Leccese',
			'Muggia',
			'Morolo',
			'Morbegno',
			'Monzuno',
			'Montorio Nei Frentani',
			'Montoggio',
			"Monte Sant'Angelo",
			'Montesano sulla Marcellana',
			'Monterado',
			'Montegaldella',
			'Montegabbione',
			'Montefalco',
			'Montecalvo Irpino',
			'Monclassico',
			'Milanere',
			'Mestre',
			'Massa Lubrense',
			'Masone',
			'Marnate',
			'Mandela',
			'Malè',
			'Magliano Alpi',
			'Lugugnana',
			'Stallavena-Lugo',
			'Lozzo di Cadore',
			'Lozzo Atestino',
			'Loro Piceno',
			'Loreggiola',
			'Loreggia',
			'Lessona',
			'Laureana Cilento',
			'Panighina',
			'Istrana',
			'Ischitella',
			'Ischia di Castro',
			'Irsina',
			'Impruneta',
			'Iesolo',
			'Gubbio',
			'Gualtieri',
			'Grumello del Monte',
			'Gorgonzola',
			'Giovinazzo',
			'Ghiffa',
			'Gerola Alta',
			'Genzone',
			'Genivolta',
			'Gazzo',
			'Gavardo-Sopraponte',
			'Garaguso',
			'Gallicano nel Lazio',
			'Fusignano',
			'Frontone',
			'Frassinoro',
			'Foza',
			'Fornaci di Barga',
			'Formicola',
			'Foligno',
			'Cavour',
			'Casaletto Spartano',
			'Carrara',
			'Carosino',
			'Carbognano',
			'Capannoli',
			'Caorle',
			'Cannara',
			'Calvignano',
			'Cairo Montenotte',
			'Buti',
			'Brusson',
			'Bosco Mesola',
			'Bondeno',
			'Barzanò',
			'Barcis',
			'Barbarano Romano',
			'Banari',
			'Atena Lucana',
			'Arignano',
			'Aradeo',
			'Antronapiana',
			'Andretta-Mattinella',
			'Alfedena',
			'Alà dei Sardi',
			'Aidomaggiore',
			'Acquanegra Cremonese',
			'Li Punti-San Giovanni',
			'Medea',
			'Cosniga-Zoppè',
			'Verbania',
			'Mazzano',
			'Buccinasco',
			'Lamezia Terme',
			"Ozzano dell'Emilia",
			'Sale delle Langhe',
			'Niella Tanaro',
			'Albavilla',
			'Faggeto Lario',
			'Montemezzo',
			'Pozzuolo Martesana',
			'Viadanica',
			'Lozio',
			'Inverno e Monteleone',
			'San Nicolò Comelico',
			'Menarola',
			'Valsavarenche',
			'Racines - Ratschings',
			'Ultimo - Ulten',
			'Montescheno',
			'Valstrona',
			'Carpeneto',
			'Bedero Valcuvia',
			'Leggiuno',
			'Campagnola Cremasca',
			'Isera',
			'Montecchio Precalcino',
			'Villafranca Padovana',
			'Smerillo',
			'Bairo',
			'Tagliolo Monferrato',
			'Tassarolo',
			'Nus',
			'Pont-Bozet',
			'Villeneuve',
			'Grantola',
			'Settala',
			'Gandosso',
			'Ranzanico',
			'Roncola',
			'Polaveno',
			'Casanova Lonati',
			'Moschiano',
			"Sant'Angelo All'Esca",
			'Brienza',
			'Santa Maria del Cedro',
			'Camporotondo Etneo',
			'Stefanaconi',
			'Castiglione Torinese',
			'Rivarossa',
			'Usseaux',
			'Mandello Vitta',
			'Marmora',
			'Rocca Cigliè',
			'Pasturana',
			'Blufi',
			'Cernusco Lombardone',
			'Introzzo',
			'Viganò',
			'Paladina',
			'Grinzane Cavour',
			'Vitinia',
			'Marina di Vasto',
			'Bornato',
			'Marconia',
			'Santa Lucia',
			'Veggia-Villalunga',
			'Castelnuovo Vomano',
			'Ravina',
			'Torchione-Moia',
			'Bastia',
			'Gavarno-Tribulina',
			'Lamie',
			'Premenugo',
			'Giovenzano',
			'Vadue',
			"Sant'Andrea",
			'Colle Mainello',
			'Prata Centro',
			'Moggio di Sotto',
			'Badia al Pino',
			'Molino-Mozzi-Bittarelli',
			'San Vito',
			'Cipolleto',
			'Berriat',
			'Bivio Mortola',
			'Prati',
			'Colla-Muggiasca',
			'San Genesio',
			'San Lorenzo',
			'Casavecchia',
			'Poggiridenti Piano',
			'Villa',
			'Prignano',
			'Leporano Marina',
			'Licinella-Torre di Paestum',
			'Villabate',
			'Ussassai',
			'Ussaramanna',
			'Stilo',
			'Seui',
			'Sciacca',
			"Sant'Agata di Militello",
			"San Nicolò d'Arcidano",
			'Sambuca di Sicilia',
			'Roccapalumba',
			'Roccamena',
			'Reggio Calabria',
			'Piscopio',
			'Piscinas',
			'Partinico',
			'Montevago',
			'Montalto Uffugo',
			'Monserrato',
			'Masainas',
			'Le Castella',
			'Laino Borgo',
			'Giuliana',
			'Escalaplano',
			'Dasà',
			'Corsano',
			'Comiso',
			'Cicala',
			'Chiaravalle Centrale',
			'Castrovillari',
			'Baratili San Pietro',
			'Villa Verde',
			'Zungoli',
			'Zinasco Vecchio',
			'Zero Branco',
			'Villaverla',
			'Villata',
			'Villa Potenza',
			'Villanova Marchesana',
			'Villaganzerla',
			'Vico Equense',
			'Viciomaggio',
			'Veronella',
			'Venarotta',
			'Venaria Reale',
			'Vasanello',
			'Varzo',
			'Varese Ligure',
			'Turano Lodigiano',
			'Tufillo',
			'Trezzano sul Naviglio',
			'Trevignano',
			'Tezze',
			'Tegoleto',
			'Tavagnasco',
			'Tarquinia',
			'Taranta Peligna',
			'La Saletta-Tamara',
			'Surano',
			'Sturno',
			'Strudà',
			'Strozza',
			'Stornarella',
			'Stornara',
			'Stintino',
			'Stimigliano',
			'Spoleto',
			'Spinoso',
			'Soriano nel Cimino',
			'Sona',
			'Solagna',
			'Sirmione',
			'Serrone',
			'Serravalle Sesia',
			'Sermoneta',
			'Schilpario',
			'Savignano sul Panaro',
			'Sarmeola',
			"Santo Stefano d'Aveto",
			"Sant'Angelo Lodigiano",
			'San Pietro Vernotico',
			'San Pietro di Cadore',
			'San Pietro',
			"San Pier d'Isonzo",
			'San Miniato',
			'San Michele al Tagliamento',
			'San Martino in Campo',
			'San Martino di Lupari',
			'San Mango sul Calore',
			'San Giuliano Milanese',
			'San Giorgio a Liri',
			'Salitto-Valle',
			'Salgareda',
			'Saletto',
			'Sale Marasino',
			'Sala Biellese',
			'Sacrofano',
			'Sabbio Chiese',
			'Rotello',
			'Rosate',
			'Roncegno',
			'Romano di Lombardia',
			'Rocchetta Nervina',
			'Rocca Priora',
			'San Rocco',
			'Ribolla',
			'Rapolano Terme',
			'Prignano sulla Secchia',
			'Preturo',
			'Pozzuolo del Friuli',
			'Postua',
			'Postiglione',
			'Portula',
			'Porto Ceresio',
			'Pompiano',
			'Polla',
			'Pisogne',
			'Pinzolo',
			'Pignone',
			"Piacenza d'Adige",
			'Pecorara',
			'Passo di Treia',
			'Palestro',
			'Paduli',
			'Ornavasso',
			'Ora',
			'Oltre Il Colle',
			'Olgiate Comasco',
			'Nughedu San Nicolò',
			'Nosate',
			'Narni',
			'Nardò',
			'Nago-Torbole',
			'Montodine',
			'Montjovet',
			"Monticelli d'Ongina",
			'Montemilone',
			'Monteforte Irpino',
			'Montecompatri',
			'Mongrando',
			'Moneglia',
			'Mondovì',
			'Monchio delle Corti',
			'Molina di Ledro',
			'Minervino Murge',
			'Mezzano',
			'Mereto di Tomba',
			'Menaggio',
			'Meda',
			'Mazzin',
			'Massa',
			'Marsciano',
			'Marina di Ravenna',
			'Marina di Grosseto',
			'Marigliano',
			"Madonna dell'Acqua",
			'Lupara',
			'Lunano',
			'Lugo',
			"Lugagnano Val d'Arda",
			'Leffe',
			'Lauro',
			'Lapio',
			'Lamporo',
			'Lama',
			'Inveruno',
			'Guardiagrele',
			'Grottaminarda',
			'Grosio',
			'Glorenza',
			'Ginestra Fiorentina',
			'Gazzo',
			'Gavello',
			'Francavilla Fontana',
			"Fragneto L'Abate",
			'Forlì del Sannio',
			'Forcoli',
			'Fontana Nuova-Bevia',
			'Fiesole',
			'Fiera di Primiero',
			'Ferrara',
			'Ferentillo',
			'Fagagna',
			'Fabro',
			'Empoli',
			'Delebio',
			'Cutigliano',
			'Cursi',
			'Cremolino',
			'Cozzo',
			'Coseano',
			'Cornalba',
			'Corleto Perticara',
			'Coredo',
			'Corchiano',
			'Copiano',
			'Colonnella',
			'Colloredo di Prato',
			'Colli sul Velino',
			'Colli a Volturno',
			'Colletorto',
			'Collepepe',
			'Codrongianos',
			'Cizzago',
			'Civitanova del Sannio',
			'Cimbergo',
			'Cerro Maggiore',
			'Cermes',
			'Cerchio',
			'Cercepiccola',
			'Cedegolo',
			'Cavaglià',
			"Cassina de' Pecchi",
			'Carbonara al Ticino',
			'Druento',
			'Dosimo',
			'Domicella',
			'Domaso',
			'Demonte',
			'Cuccurano',
			'Cossoine',
			'Nuova Conza della Campania',
			'Controguerra',
			'Collecchio',
			'Cittaducale',
			'Capanne-Prato-Cinquale',
			'Chianche',
			'Cenaia',
			'Cavazzo Carnico',
			'Cavarzere',
			'Castiglione Messer Marino',
			'Castenaso',
			'Castelpoto',
			'Castellar Guidobono',
			'Castelfondo',
			'Castagneto Carducci',
			'Castagnaro',
			'Casoria',
			'Cascano',
			'Semonte-Casamorcia',
			'Capriglia Irpina',
			'Capralba',
			'Canale Monterano',
			'Camugnano',
			'Cambiago',
			'Calvisano',
			'Calliano',
			'Calimera',
			'Calcara',
			'Caglio',
			'Cabella Ligure',
			'Brescello',
			'Borgo Tossignano',
			"Borgo d'Ale",
			'Fornace Zarattini',
			'Bonorva',
			'Bolzano Vicentino',
			'Bitti',
			'Bibbiena',
			'Belgioioso',
			'Bassano Bresciano',
			'Barrea',
			"Barberino Val d'Elsa",
			'Baone',
			'Bagolino',
			'Bagnoli di Sopra',
			'Badia Pavese',
			'Arpaise',
			'Arielli',
			'Arcugnano-Torri',
			'Arcidosso',
			'Anela',
			'Ameno',
			'Amandola',
			'Alfiano Natta',
			'Agrate Brianza',
			'Agordo',
			'Agliè',
			'Acqui Terme',
			'Lido degli Estensi',
			'Chiopris',
			'Crepaldo',
			'Falzè-Signoressa',
			"Sant'Alberto",
			'Spinea-Orgnano',
			'Merso di Sopra',
			'Bordano',
			'Ramuscello',
			'Cloz',
			'Uboldo',
			'Casagiove',
			'Mombarcaro',
			'Rocchetta Belbo',
			'Caprauna',
			'Saint-Denis',
			'Mendatica',
			'Lozza',
			'Orino',
			"Caslino d'Erba",
			'Arnasco',
			'Ossuccio',
			'Isola di Fondra',
			'Polpenazze del Garda',
			'Zenevredo',
			'Lisignago',
			'Nogaredo',
			'Montecorvino Pugliano',
			'Montemale di Cuneo',
			'Agra',
			'Correzzana',
			'Lazzate',
			'Castana',
			'Casaletto Vaprio',
			'Drizzona',
			'Arcinazzo Romano',
			'Armo',
			'Ferrera di Varese',
			'Bubbiano',
			'San Giorgio Su Legnano',
			'Bolgare',
			'Grassobbio',
			'Casaletto di Sopra',
			'Cercenasco',
			"Sant'Angelo A Cupolo",
			'Sperone',
			'Bulciago',
			'Garlate',
			'Rogeno',
			'Villanova del Sillaro',
			'Cafasse',
			'Piscina',
			'Alto',
			"Pino d'Asti",
			'Spiazzo',
			'Cervarese Santa Croce',
			'Semproniano',
			'Roccamorice',
			'Alì Terme',
			'Ternengo',
			'Letino',
			'Lovero',
			'Bergolo',
			'Ceresole Alba',
			'Mercatale',
			'Avane',
			'Tagliuno',
			'Terraverde-Corte Palasio',
			'Centobuchi',
			'Bassone',
			'Arpino',
			"Vill'Albese",
			'Rometta Marea',
			'Strà-Montanara-Pieve',
			'San Vigilio',
			'Lido di Fermo',
			'Marzanello',
			'Chef-Lieu',
			'Praticello',
			'Vidardo',
			'Pedalino',
			'San Salvo Marina',
			'Cepina',
			'Forche',
			"Sant'Albino",
			'Belverde',
			'Provezze',
			"Sant'Andrea",
			'Fane',
			'Pasteria-Lapide',
			'Brezza',
			'Agrate',
			'San Prospero',
			'Nava',
			'Pederiva',
			'Fontanella-Ozino',
			'Zoppè di Cadore',
			'Don',
			'Scalo di Baragiano',
			'Chiaiano',
			'Vita',
			'Uta',
			'Torregrotta',
			'Soveria Mannelli',
			'Sommatino',
			'Solanas',
			'Siamaggiore',
			'Scillato',
			'Scarcelli',
			'Santa Giusta',
			'San Martino',
			'San Giovanni Gemini',
			'Ricadi',
			'Pizzo',
			'Paludi',
			'Albagiara',
			'Mongiuffi Melia',
			'Militello in Val di Catania',
			'Melicucco',
			'Meana Sardo',
			'Marcedusa',
			'Locri',
			'Linera',
			'Lappano',
			'Laganadi',
			'Ilbono',
			'Gimigliano',
			'Gela',
			'Decimomannu',
			'Cosenza',
			'Condofuri',
			'Cerzeto',
			'Ceramida-Pellegrina',
			'Castelbuono',
			'Caltavuturo',
			'Borgetto',
			'Belvì',
			'Barumini',
			'Ardore',
			'Zerbolò',
			'Zenson di Piave',
			'Voltago Agordino',
			'Villamagna',
			'Vigarano Mainarda',
			'Vicofertile',
			'Vicchio',
			'Vezzano sul Crostolo',
			'Verona',
			'Venegono Superiore',
			'Venasca',
			'Valtorta',
			'Valeggio',
			'Tribiano',
			'Travagliato',
			'Torricella Peligna',
			'Torchiarolo',
			'Terruggia',
			'Terelle',
			'Teglio',
			'Teggiano-Macchiaroli',
			'Teana',
			'Tarzo',
			'Summonte',
			'Strigno',
			'Striano',
			'Staffa',
			'Spilimbergo',
			'Sovicille',
			'Sondrio',
			'Soave',
			'Sesto San Giovanni',
			'Sesto Calende',
			'Serra Riccò',
			'Serrara Fontana',
			'Selvazzano Dentro',
			'Scorzè',
			'Scomigo',
			'Scafati',
			'Savigliano',
			'Sassano',
			"Sant'Anna d'Alfaedo",
			"Sant'Ambrogio di Torino",
			'Santa Maria la Longa',
			'San Miniato Basso',
			'Borgo San Michele',
			'San Giustino Valdarno',
			'San Giuseppe',
			'San Giorgio in Bosco',
			'Aglientu',
			'San Damiano Macra',
			'San Costantino Albanese',
			'San Chirico Nuovo',
			'San Cassiano',
			'Salle',
			'Salara',
			'Sabbioneta',
			'Rovetta',
			'Rovello Porro',
			'Ronta',
			'Roncà',
			'Rivarolo Canavese',
			'Recco',
			'Quarna Sopra',
			'Pratovecchio',
			'Pralormo',
			'Praiano',
			'Pozzolo Formigaro',
			'Lavena Ponte Tresa',
			'Pizzoferrato',
			'Piscinola',
			'Perdifumo',
			'Patigno',
			'Panzano in Chianti',
			'Paludea',
			'Palidano',
			'Palau',
			'Padulle',
			'Stazione di Padule',
			'Padenghe sul Garda',
			'Ossona',
			'Ospedaletto',
			'Ortueri',
			'Olgiate Olona',
			'Olcenengo',
			'Occhiobello',
			'Nucetto',
			'Novi Velia',
			'Novi di Modena',
			'Noventa di Piave',
			'Nova Levante',
			'Nocciano',
			'Nave',
			'Murisengo',
			'Monzambano',
			'Montorio Romano',
			'Montiglio',
			'Monte San Martino',
			'Montegranaro',
			'Montefano',
			'Monte di Procida',
			'Monte Cerignone',
			'Moggio',
			'Modena',
			'Millesimo',
			'Migliaro',
			'Mesola',
			'Ruffrè',
			'Maser',
			'Martignacco',
			'Marostica',
			'Marina di San Vito',
			'Marcaria',
			'Magliano Romano',
			'Luzzano',
			'Lovere',
			'Lorsica',
			'Loro Ciuffenna',
			'Loreto',
			'Lombardore',
			'Livorno Ferraris',
			'Limosano',
			'Laterza',
			'Illorai',
			'Gravellona',
			'Goro',
			'Gonzaga',
			'Giove',
			'Ginestra degli Schiavoni',
			'Giarole',
			'Gerosa',
			'Gargazzone',
			'Gallicchio',
			'Frontino',
			"Francavilla d'Ete",
			'Frabosa Soprana',
			'Fossalto',
			'Forni di Sopra',
			'Fontignano',
			'Fontanelice',
			'Fondi',
			'Firenzuola',
			'Fara Vicentino',
			'Fara San Martino',
			'Erbè',
			'Credera',
			'Crecchio',
			'Costano',
			'Configni',
			'Conca dei Marini',
			'Collepardo',
			'Colere',
			'Codogno',
			'Codisotto',
			'Ciorlano',
			'Cicciano',
			"Ciano d'Enza",
			'Certosa di Pavia',
			'Cerro al Volturno',
			'Centrale',
			'Ceneselli',
			'Cembra',
			'Ceccano',
			'Cava Dè Tirreni',
			'Cattolica',
			'Castro',
			'Castiglione della Pescaia',
			'Castelvetro Piacentino',
			'Castelluccio Valmaggiore',
			'Aviano-Castello',
			'Castelleone',
			'Castel Giorgio',
			'Casoli',
			'Casoli',
			'Casatisma',
			'Carbonera',
			'Capestrano',
			'Capena',
			'Canosa Sannita',
			'Cancello-Arnone',
			'Campodenno',
			'Campagnatico',
			'Camisano Vicentino',
			'Busachi',
			'Buonconvento',
			'Brugherio',
			'Broni',
			'Brindisi',
			'Bovino',
			'Borgo di Terzo',
			'Bonferraro',
			'Bomba',
			'Bomarzo',
			'Bojano',
			'Bionaz',
			'Bientina',
			'Biandronno',
			'Bertonico',
			'Berra',
			'Benevento',
			'Begliano',
			'Battipaglia',
			'Battaglia Terme',
			'Barbianello',
			'Bagnoli Irpino',
			'Arvier',
			'Arluno',
			'Anzano del Parco',
			'Antella',
			'Amelia',
			'Alghero',
			"Acquaviva d'Isernia",
			'Acquaviva Collecroce',
			'Pertegada',
			'Veternigo',
			'Claut',
			'Mompantero',
			'Challand-Saint-Anselme',
			'San Nazzaro Val Cavargna',
			"Canonica d'Adda",
			'Palazzago',
			'Pianico',
			'Collebeato',
			'Corteno Golgi',
			'Mombelli',
			'Brentino Belluno',
			'Bolbeno',
			'Smarano',
			'Cerro Veronese',
			'Fluminata',
			'Sambuca Pistoiese',
			'Tavarnelle Val di Pesa',
			"Campo nell'Elba",
			'Forcola',
			'Soiano',
			'Rasun Anterselva - Rasen-Antholz',
			'Lucoli',
			'Verduno',
			'Casal Cermelli',
			'Laino',
			'Premolo',
			'Borgo San Siro',
			'Spinadesco',
			'Castelbelforte',
			'Voghiera',
			'Ragoli',
			'Ponzano Romano',
			'Valgrisenche',
			'Calice Ligure',
			'Cuveglio',
			'Figino Serenza',
			'Longone al Segrino',
			'Dossena',
			'Fino del Monte',
			'Mornico al Serio',
			'Songavazzo',
			'Capriano del Colle',
			"Castello d'Agogna",
			"Ca' d'Andrea",
			'Cossano Canavese',
			'Valdengo',
			'Imbersago',
			'Quarna Sotto',
			'Vignone',
			'Rosta',
			'Roccasparvera',
			'Cerro Tanaro',
			'Monleale',
			'Mornese',
			'Germagno',
			'Scurelle',
			'Isola Rizza',
			'Predappio',
			'Cartoceto',
			'Careggine',
			'Settingiano',
			'Maropati',
			'San Pietro di Caridà',
			'Joppolo',
			'Trontano',
			'Pieve di Ledro',
			'Vidracco',
			'Bernezzo',
			'Montemerlo',
			'Rastignano-Carteria di Sesto',
			'Valle Santa',
			'Bastardo',
			'Pedemonte',
			'Scarlino Scalo',
			'Montignano-Marzocca',
			'San Zeno-San Giuseppe',
			'Caselle',
			'Torretta-Scalzapecora',
			'Santa Lucia',
			'Guardavalle Marina',
			"Sant'Angelo in Villa-Giglio",
			'La Rotta',
			'Oltre Brenta',
			'Villaguattera',
			'Via Lippia',
			'Cereda-Cozza Cornedo',
			'Negrone',
			'Lavinaio-Monterosso',
			'Villapinta',
			'Guinzano',
			'Giacalone',
			'Barberi',
			'Lido',
			'Badile',
			'Vaccheria',
			'San Giacomo',
			'Navedano',
			'Castione',
			'Cedessano',
			'Roapiana',
			'Barquedo',
			'Grizzana',
			'Passo Ripe',
			'Natile Nuovo',
			'Colleverde II',
			'Corcagnano',
			'Vittoria',
			'Villafranca Tirrena',
			'Vicari',
			'Valguarnera Caropepe',
			'Ussana',
			'Taurianova',
			'Taormina',
			'San Benedetto Ullano',
			'Rossano',
			'Pula',
			'Paola',
			'Palermiti',
			'Palazzolo Acreide',
			'Nuraxinieddu',
			'Montalbano Elicona',
			'Merì',
			'Mascali',
			'Malvito',
			'Malfa',
			'Luzzi',
			'Licodia Eubea',
			'Gesturi',
			'Francavilla Angitola',
			'Ferla',
			'Dinami',
			'Cianciana',
			'Cerami',
			'Castronuovo di Sicilia',
			'Castrignano del Capo',
			'Cassano Allo Ionio',
			'Capri Leone',
			'Canolo',
			'Calatafimi',
			'Burcei',
			'Belvedere Spinello',
			'Assoro',
			'Aci Catena',
			'Zimone',
			'Vizzola Ticino',
			'Vimercate',
			'Villa Santina',
			'Villa di Serio',
			'Vigonovo-Fontanafredda',
			'Vestignè',
			'Verolengo',
			'Vernole',
			'Vernate',
			'Ventoso',
			'Vasto',
			'Varzi',
			'Valdobbiadene',
			'Vacri',
			'Uri',
			'Trinità',
			'Trepuzzi',
			'Trentola-Ducenta',
			'Torrenieri',
			"Torre de' Picenardi",
			'Torrazza Coste',
			'Toritto',
			'Tora',
			'Tocco Caudio',
			'Tesimo',
			'Spotorno',
			"Spino d'Adda",
			'Spigno Saturnia Superiore',
			'Spello',
			'Solarolo',
			'Sirignano',
			'Silvano Pietra',
			'Scario',
			'San Zenone al Lambro',
			"Sant'Ambrogio sul Garigliano",
			'Santa Marinella',
			'Venezzano',
			'Santa Cesarea Terme',
			'San Salvatore Telesino',
			'San Quirino',
			'San Quirico',
			'San Pio delle Camere',
			"San Pietro d'Olba",
			'San Donato Val di Comino',
			'San Donato di Lecce',
			'San Cristoforo',
			'Saludecio',
			'Rutigliano',
			'Rovagnate',
			'Lucino-Rodano',
			'Roccamandolfi',
			"Rocca d'Arce",
			'Quarantoli',
			'Pozzaglio ed Uniti',
			'Postalesio',
			'Portobuffolè',
			'Porlezza',
			'Porcellengo',
			'Ponte Nizza',
			'Giovi-Ponte alla Chiassa',
			"Pomigliano d'Arco",
			'Poiana Maggiore',
			'Pocenia',
			'Piubega',
			'Pineto',
			'Pignola',
			'Pieve Vergonte',
			'Pietravairano',
			'Petriano',
			'Parolise',
			'Papozze',
			'Palombaio',
			'Palazzo San Gervasio',
			'Ostiano',
			'Orte',
			'Orsenigo',
			'Oria',
			'Nove',
			'Novate Mezzola',
			'Nova Siri',
			'Nerola',
			'Nereto',
			'Nemoli',
			'Nemi',
			'Motta Baluffi',
			'Moscazzano',
			'Morro Reatino',
			'Morimondo',
			'Moretta',
			'Monte Rinaldo',
			'Monteleone Rocca Doria',
			'Montegrotto Terme',
			'Montefiorino',
			'Montefino',
			'Montebuono',
			'Montalto delle Marche',
			'Monguelfo',
			'Molise',
			"Misano di Gera d'Adda",
			'Mese',
			'Merate',
			'Mazzo di Valtellina',
			'Marano Vicentino',
			'Marano Equo',
			'Mansuè',
			'Maissana',
			'Magreta',
			'Magliano Sabina',
			"Lurago d'Erba",
			'Lucignano',
			'Longano',
			'Loiano',
			'Livinallongo del Col di Lana',
			'Lissone',
			'Levico Terme',
			'Lettomanoppello',
			'Lenna',
			'Legnaro',
			'Lecce',
			'La Valle Agordina',
			'La Salle',
			'Guiglia',
			'Grumento Nova',
			'Grottaferrata',
			'Grosotto',
			'Gioi',
			'Genoa',
			'Gallo',
			'Galbiate',
			'Francavilla al Mare',
			'Fragagnano',
			'Folgaria',
			'Flussio',
			'Flumeri',
			'Falzes',
			'Dovera',
			'Colorno',
			'Collarmele',
			'Coli',
			'Codevigo',
			'Coazze',
			'Clusone',
			'Cles',
			'Claviere',
			'Cividate al Piano',
			"Cisterna d'Asti",
			'Cismon del Grappa',
			'Cisano',
			'Cingoli',
			'Chatillon',
			'Cesena',
			'Cellino San Marco',
			'Celle di Bulgheria',
			'Cautano',
			"Castiglione d'Orcia",
			'Castelvetro di Modena',
			'Castel Campagnano',
			'Castelbellino',
			'Caspoggio',
			'Casorezzo',
			'Casola Valsenio',
			'Casaprota',
			'Casandrino',
			'Casaletto Ceredano',
			'Casal di Principe',
			'Casacanditella',
			'Carrù',
			'Carro',
			'Carpinone',
			'Carovigno',
			'Cappadocia',
			'Cantagallo',
			'Campo Jemini',
			'Campertogno',
			'Campegine',
			'Calvello',
			'Calcinate',
			'Brossasco',
			'Botticino',
			'Bortigiadas',
			'Borca',
			'Bomporto',
			'Bolsena',
			"Boffalora d'Adda",
			'Biella',
			'Biassono',
			'Belgirate',
			'Belforte del Chienti',
			'Barletta',
			'Baricella',
			'Bagnolo',
			'Aurisina',
			'Arpino',
			'Arnesano',
			'Arcade',
			'Appignano',
			"Anzola dell'Emilia",
			'Altino',
			'Altavilla Irpina',
			'Almenno San Bartolomeo',
			'Aiello del Friuli',
			'Accadia',
			'Piavon',
			'Francenigo',
			'Valle di Cadore',
			'Scanzo-Rosciate',
			'Santa Maria La Carità',
			'Cinaglio',
			'Coazzolo',
			'Maretto',
			'Chiesa di Macra',
			'Cadorago-Caslino al Piano',
			'Carbonate',
			'Oggiona-Santo Stefano',
			'Idro',
			'Erbusco',
			'Lona-Lases',
			'Verrua Po',
			'Terzolas',
			"Capriata d'Orba",
			'Montemignaio',
			'Roccavignale',
			'Preseglie',
			'Vinchio',
			'Bosio',
			'Morsasco',
			'Barasso',
			'Alonte',
			'Rivalta Bormida',
			'Massimino',
			'Bregano',
			'Rogno',
			'Grosso',
			'Albaretto della Torre',
			'Cortenova',
			'Santa Maria Hoè',
			'Sordio',
			'Cambiasca',
			'Frossasco',
			'Moncenisio',
			'Vallo Torinese',
			'Carentino',
			"Castelletto d'Erro",
			"Sant'Agata del Bianco",
			'Casapinta',
			'Margno',
			'Gurro',
			'Binago',
			'Cagno',
			'Cigognola',
			'Osasco',
			'Noverasco-Sporting Mirasole',
			'Montalbano',
			'Sandrà',
			'Baggiovara',
			'Arbizzano-Santa Maria',
			'Torchiati',
			'Cona',
			'Villa Pigna',
			'Mejaniga',
			'La Massimina-Casal Lumbroso',
			'Villatora',
			'Rogoredo-Valaperta-Rimoldo',
			'Borgo Stazione',
			'Capaccio Scalo',
			'Lunetta-Frassino',
			'Parco Scizzo-Parchitello',
			'Castelnovo',
			'Roteglia',
			'Vighizzolo',
			'Coperchia',
			'Osteria Nuova',
			'Bodio',
			'Villa Ceccolini',
			'Piano di Conca',
			'San Vincenzo',
			'Canonica',
			'Tronca',
			'La Botte',
			'Arlate',
			"Madonna Dell'Albero",
			'Monte San Giovanni',
			'Ghiare-Madonna',
			'Mimosa-Poggio Verde-Nuova Comunità',
			'Chiesino-Collodi',
			'Colubro',
			'Argine',
			'San Giovanni-Patoni',
			"Porto d'Adda",
			'Laxolo',
			'Maccoli-Perrillo',
			'Braglia',
			'Villandro',
			'Valzemola',
			"Plan d'Introd",
			'Lanvario',
			'Molino-Pera',
			'Vauda Canavese Superiore',
			'bishopric of Perugia',
			'Chiaia',
			'Cerratina',
			'Cavasso Nuovo',
			'Castro dei Volsci',
			"Castel Sant'Elia",
			'Casteldidone',
			'Tortorici',
			'Campora San Giovanni',
			'Sestu',
			'Sellia',
			'Scilla',
			"Sant'Elia",
			"Sant'Angelo di Brolo",
			'San Mauro Marchesato',
			'San Lorenzo Bellizzi',
			'San Giorgio',
			'Salve',
			'Pompu',
			'Pianopoli',
			'Patti',
			'Parghelia',
			'Nurachi',
			'Nicotera',
			'Lago',
			'Isili',
			'Girifalco',
			'Giardina Gallotti',
			'Fuscaldo',
			'Drapia',
			'Crosia',
			'Cittanova',
			'Cerenzia',
			'Carfizzi',
			'Bronte',
			'Bocchigliero',
			'Aliminusa',
			'Agrigento',
			'Zibido San Giacomo',
			'Villafranca di Verona',
			'Villa',
			'Villa di Chiavenna',
			'Vigonovo',
			'Vigone',
			'Vignola',
			'Vicoli',
			'Vico del Gargano',
			'Vicalvi',
			'Viareggio',
			'Vesime',
			'Vescovato',
			'Venosa',
			'Valprato Soana',
			'Valfabbrica',
			'Valdaora di Mezzo',
			'Vaglia',
			'Vado',
			'Tuoro sul Trasimeno',
			'Trequanda',
			'Torrita di Siena',
			'Tonco',
			'Termoli',
			'Tavernole',
			'Tarvisio',
			'Talmassons',
			'Sumirago',
			'Stroppo',
			'Statte',
			'Spormaggiore',
			'Sgonico',
			'Serrano',
			'Seggiano',
			'Sedico',
			'Savio',
			'Sarteano',
			'Santo Stefano di Magra',
			'San Romano',
			'San Martino di Venezze',
			'San Martino in Passiria',
			'San Giuliano Terme',
			'San Felice del Molise',
			'Salsomaggiore Terme',
			'Saint-Vincent',
			'Romentino',
			'Roccaforzata',
			'Rivalta di Torino',
			'Ripi',
			'Rimella',
			'Rhemes-Notre-Dame',
			'Rezzato',
			'Revine',
			'Rapallo',
			'Putignano',
			'Povoletto',
			'Porretta Terme',
			'Ponte Ronca',
			'Pometo',
			'Pietramelara',
			'Piedimulera',
			"Pie' del Colle",
			'Piazzatorre',
			'Pesaro',
			'Perugia',
			'Pernate',
			'Perano',
			'Pegolotte',
			'Pedesina',
			'Pantigliate',
			'Panni',
			'Pancarana',
			'Palagiano',
			'Palagianello',
			'Ospedaletto Euganeo',
			'Orotelli',
			'Olmedo',
			'Nule',
			'Nomaglio',
			'Noceto',
			'Mozzagrogna',
			"Mosciano Sant'Angelo",
			"Monteroni d'Arbia",
			'Monte Romano',
			'Monterenzio',
			'Montemaggiore al Metauro',
			'Montebruno',
			'Montanara',
			'Mondragone',
			'Moliterno',
			'Mignanego',
			'Mezzano Inferiore',
			'Mercato Saraceno',
			'Melle',
			'Melendugno',
			'Melegnano',
			'Massarosa',
			'Marcellina',
			'Marano sul Panaro',
			'Manocalzati',
			'Manerbio',
			'Manciano',
			'Malo',
			'Magomadas',
			'Longarone',
			'Lodè',
			'Locorotondo',
			'Legnano',
			'Laviano',
			'Lapedona',
			'La Magdeleine',
			'Lajatico',
			'Illasi',
			'Guagnano',
			'Grumolo Pedemonte',
			'Grumo Appula',
			'Grottaglie',
			'Grantorto',
			'Grandate',
			'Gragnano Trebbiense',
			'Piano di Coreglia-Ghivizzano',
			'Gerano',
			'Garbagnate Milanese',
			'Gamberale',
			'Galeata',
			'Gaby',
			'Fratta Polesine',
			'Frattaminore',
			'Frassino',
			'Frassinetto',
			'Forino',
			'Fontanellato',
			'Fogliano',
			'Foggia',
			'Filighera',
			'Fanna',
			'Desio',
			'Dalmine',
			'Conselice',
			'Concordia Sagittaria',
			'Comazzo',
			'Comacchio',
			'Collesalvetti',
			'Cisterna di Latina',
			'Chiusdino',
			'Chiusano di San Domenico',
			'Chianciano Terme',
			'Cervesina',
			'Cellamare',
			'Celenza sul Trigno',
			'Cavallirio',
			'Castrocaro Terme',
			"Castiglione d'Adda",
			'Castellazzo Bormida',
			'Castel Guelfo di Bologna',
			'Castel Gandolfo',
			'Castelcivita',
			'Cassolnovo',
			'Caserta',
			'Casalvolone',
			'Casaloldo',
			'Casalgrasso',
			'Carrosio',
			'Carpineto Romano',
			'Cantalupo in Sabina',
			'Cancellara',
			'Canaro',
			"Calvi dell'Umbria",
			'Calvera',
			'Brusciano',
			'Brez',
			'Bressanone',
			'Bovolenta',
			'Borriana',
			'Borgo Priolo',
			'Borgomasino',
			'Bordighera',
			'Bonavicina',
			'Bitetto',
			'Bicinicco',
			'Bibbona',
			'Besate',
			'Bernalda',
			'Beltiglio-San Giovanni',
			'Bellocchi',
			'Giacciano con Baruchella',
			'Barbania',
			'Balsorano Nuovo',
			'Bagno Roselle',
			'Bagni di Tivoli',
			'Badesi',
			'Arquà Petrarca',
			'Argenta',
			'Apollosa',
			'Angera',
			'Anfo',
			'Ancona',
			'Almenno San Salvatore',
			'Agliana',
			'Acquafondata',
			'Sala',
			'Gorgo al Monticano',
			'Covolo-Levada',
			'Passons',
			'Pero',
			'Valderice',
			'Lusciano',
			'Inverso Pinasca',
			'Montafia',
			"Rocca d'Arazzo",
			'Magliolo',
			'Montegrino Valtravaglia',
			'Morbello',
			'Petit Fenis',
			'Basiano',
			'Bedulita',
			'Oltressenda Alta',
			"Torre de' Negri",
			'Velezzo Lomellina',
			'Villanova',
			'Ronzo-Chienis',
			'Calvignasco',
			'Pertica Bassa',
			'Roè Volciano',
			'Andriano',
			'Africo Nuovo',
			'Madonna del Sasso',
			'Vaglio Serra',
			'Cunardo',
			'Fonteno',
			'Presezzo',
			'Marzano',
			'Cella Dati',
			'Campolongo sul Brenta',
			'Barchi',
			'Terzo',
			'Savignone',
			'Monvalle',
			'Limido Comasco',
			'Bariano',
			'Bosnasco',
			'Martignana di Po',
			'Giustino',
			'Cagnano Amiterno',
			'Roletto',
			'Albera Ligure',
			'Laghi',
			'Rotzo',
			'Montenero Sabino',
			'Cesana Brianza',
			'Mulazzano',
			'Bee',
			'Ronago',
			'Praso',
			'Marentino',
			'Villar Dora',
			'Castiglione Falletto',
			'Faule',
			'Carruba',
			'San Teodoro',
			'Cancello ed Arnone',
			'Corpo Reno',
			'Marina di Ardea-Tor San Lorenzo',
			'Sezze Scalo',
			'San Nicolò',
			"Mandriola-Sant'Agostino",
			'Colle Verde',
			'Villa Rosa',
			'Piazza Caduti',
			'Parmezzana Calzana',
			'Gerbido',
			'San Gregorio',
			'Carchitti',
			'San Tommaso Tre Archi',
			'Villaggio del Pino-Le Ginestre',
			'Piana San Raffaele',
			'San Michele Tiorre',
			'San Biagio',
			'Sesto Cremonese',
			'Girardi-Bellavista-Terrazze',
			'Ramiola',
			'Bubano',
			'Lodetto',
			'Valle di Vado',
			'Caprara',
			'Campochiesa',
			'Ricca',
			'Lacugnano',
			'Il Romito',
			'Duomo',
			'San Zeno',
			'Faraldo-Nocelleto',
			'Bulgorello',
			'Voltago',
			'Piane di Montegiorgio',
			'Villagrande Strisaili',
			'Varapodio',
			'Vallermosa',
			'San Ciro-Ulmi-Filci',
			'Tuili',
			'Tratalias',
			'Torre di Ruggiero',
			'Tiriolo',
			'Staiti',
			'Spezzano della Sila',
			'Spadafora',
			"Sant'Alessio in Aspromonte",
			'San Giuseppe Jato',
			'San Cipirello',
			'Ribera',
			'Piraino',
			'Petrosino',
			'Paternò',
			'Nuragus',
			'Narcao',
			'Mistretta',
			'Martirano',
			'Marianopoli',
			'Kamma',
			'Isca sullo Ionio',
			'Gasperina',
			'Francavilla di Sicilia',
			'Fossato Ionico-Fossatello-San Luca Marcelluzzo',
			'Donnalucata',
			'Depressa',
			'Cosoleto',
			'Ciminà',
			'Castrofilippo',
			'Castiglione Cosentino',
			'Castellana Sicula',
			'Campofelice di Roccella',
			'Cammarata',
			'Calasetta',
			'Benestare',
			'Barcellona Pozzo di Gotto',
			'Asuni',
			'Argusto',
			'Antillo',
			'Africo Vecchio',
			'Acquappesa',
			'Zavattarello',
			'Volterra',
			'Vodo',
			'Villa Santa Maria',
			'Villanova',
			'Villafalletto',
			'Vigatto',
			'Varago',
			'Vandoies di Sotto',
			'Vallo della Lucania',
			'Uzzano',
			'Uscio',
			'Trivento',
			'Trino',
			'Tremezzo',
			'Torella del Sannio',
			'Terontola',
			'Strettoia',
			'Sparanise',
			'Sospirolo',
			'Sorbolo',
			'Sonico',
			'Solferino',
			"Silvano d'Orba",
			'Silandro',
			'Sesto Campano',
			'Sessa Cilento',
			'Sernaglia della Battaglia',
			'Senerchia',
			'Sedilo',
			'Scandolara Ravara',
			'Scala',
			'San Vito di Leguzzano',
			'Santhià',
			"Sant'Antimo",
			"Sant'Angelo di Piove di Sacco",
			'Santa Maria Nuova',
			'San Severino Lucano',
			'Sannicandro Garganico',
			'San Giovanni Rotondo',
			'San Giorio',
			'San Genesio ed Uniti',
			'San Floriano del Collio',
			'San Cipriano Po',
			'Sala Bolognese',
			'Rovellasca',
			'Rosciano',
			'Roccaforte Mondovì',
			'Roccaforte Ligure',
			'Roccabianca',
			'Robilante',
			'Rivodutri',
			'Rive',
			'Ripalimosani',
			'Riomaggiore',
			'Rima',
			'Rignano Garganico',
			'Rho',
			'Reggello',
			'Refrontolo',
			'Rapagnano',
			'Quarata',
			'Provvidenti',
			'Prignano Cilento',
			'Pontelongo',
			'Poggio San Vicino',
			'Poggiodomo',
			'Pisignano',
			'Pigna',
			'Picerno',
			'Pescasseroli',
			'Pescara',
			'Pelago',
			'Pavia',
			'Paulilatino',
			'Palosco',
			'Oriolo Romano',
			'Orio al Serio',
			'Opera',
			'Oniferi',
			'Oltrona di San Mamette',
			'Occhieppo Inferiore',
			'Nova Milanese',
			'Nogara',
			'Noepoli',
			'Mura',
			'Mozzate',
			'Morgex',
			'Moresco',
			'Montorio al Vomano',
			'Monti',
			'Monte San Vito',
			'Monterubbiano',
			"Monteleone d'Orvieto",
			'Montelapiano',
			'Montefortino',
			'Montefiore Conca',
			"Montechiaro d'Asti",
			'Montanaro',
			'Montalto Ligure',
			'Montalto Dora',
			'Moniga del Garda',
			'Monesiglio',
			'Molina Aterno',
			'Minerbio',
			'Milis',
			'Massimeno',
			'Masi-Torello',
			'La Martella',
			'Magugnano',
			'Magno',
			'Macchiagodena',
			'Loiri Porto San Paolo',
			'Limatola',
			'Lessolo',
			'Leporano',
			'Lenola',
			'Laurino',
			'Laglio',
			'Ivrea',
			'Itri',
			"Istia D'Ombrone-Le Stiacciole",
			'Irgoli',
			'Guilmi',
			'Guidizzolo',
			'Guardamiglio',
			'Greve in Chianti',
			'Gazzuolo',
			'Gardone Riviera',
			'Galtellì',
			'Frisanco',
			'Fresagrandinaria',
			'Fontanarosa',
			'Florinas',
			'Felino',
			'Fara Filiorum Petri',
			'Castrocielo',
			'Enemonzo-Quinis',
			'Dragoni',
			'Donzella',
			'Domodossola',
			'Deruta',
			'Conversano',
			'Contigliano',
			'Commessaggio',
			'Comabbio',
			'Colombaro',
			'Colliano',
			'Codigoro',
			'Civitella Messer Raimondo',
			'Cimadolmo',
			'Ciampino',
			'Chiarano',
			'Cesara',
			'Cerveno',
			'Cellere',
			'Casto',
			'Castelliri',
			'Castel Goffredo',
			'Castelbottaccio',
			"Casole d'Elsa",
			'Caselle in Pittari',
			'Casamarciano',
			'Casalmaggiore',
			'Casalanguida',
			'Carate Brianza',
			'Capracotta',
			"Cappella de' Picenardi",
			'Olgiate Molgora',
			'Cannole',
			'Cannero Riviera',
			'Canna',
			'Canelli',
			'Campolattaro',
			'Campo di Giove',
			'Campi Salentina',
			'Campione',
			'Caltignaga',
			'Buddusò',
			'Brandizzo',
			'Borgo San Giacomo',
			'Borgo San Dalmazzo',
			"Borghetto d'Arroscia",
			'Bonnanaro',
			'Bonefro',
			'Bolotana',
			'Bollate',
			'Bobbio Pellice',
			'Bianzè',
			'Besenzone',
			'Besana in Brianza',
			'Berzo',
			'Berceto',
			'Barzana',
			'Bardonecchia',
			'Bard',
			'Barbona',
			'Barbarano Vicentino',
			'Balme',
			'Bagnone',
			'Avellino',
			'Auletta',
			'Arzachena',
			'Arta Terme',
			'Arsita',
			'Arsago Seprio',
			'Ardenno',
			'Anversa degli Abruzzi',
			'Alleghe',
			'Albettone',
			'Airuno',
			'Adelfia',
			'Abbazia Pisani',
			'Abbadia San Salvatore',
			'Pramaggiore Blessaglia',
			'Santa Maria',
			'Lamosano',
			'Colloredo di Monte Albano',
			'San Salvatore',
			'Saonara',
			'Cervinara',
			'Gerenzano',
			'Pessano Con Bornago',
			'San Marcellino',
			'Prarostino',
			'Capriglio',
			'Montaldo Scarampi',
			'Plodio',
			'Passerano Marmorito',
			'San Fermo della Battaglia',
			"Pozzo d'Adda",
			'Renate',
			'Roncello',
			'Cenate di Sotto',
			'Mozzo',
			'Bione',
			'Verdellino',
			'Cappella Cantone',
			'Vò',
			'Isola del Giglio',
			'Vezzi Portio',
			'Marmentino',
			'Bajardo',
			'Antrona Schieranco',
			'Casaleggio Boiro',
			'Cuccaro Monferrato',
			'Entratico',
			'Castelcovati',
			'Altivole',
			'Comano',
			'Montescudaio',
			'Avise',
			"Arzago d'Adda",
			'Maclodio',
			'Trivolzio',
			'Colleretto Giacosa',
			'Salerano Canavese',
			'Soriso',
			'Perego',
			'Graffignana',
			'Mairago',
			'San Martino in Strada',
			'Monastero di Lanzo',
			'Piobesi Torinese',
			'Miasino',
			'Roburent',
			"Azzano d'Asti",
			'Dernice',
			'Gambellara',
			'Majano',
			'Pago del Vallo di Lauro',
			'Rocca San Giovanni',
			'Villanova Tulo',
			'Trasquera',
			'Zelbio',
			'Torre Canavese',
			'Camo',
			'Torre San Giovanni',
			'Granarolo',
			'Roncanova',
			'Bellaria-Igea Marina',
			'Priula-Colfosco',
			'Osteno',
			'Romano Banco',
			'Chiugiana-La Commenda',
			'Quadrivio',
			'Frescada',
			'Monticello',
			'Selino Basso',
			'Barbisano',
			'Bertipaglia',
			'Campagnola',
			'Bellavista',
			'Gorzano',
			'San Vito al Mantico',
			'San Michele',
			'Le Forna',
			'Monte San Marino',
			'Villa',
			"Cassino d'Alberi",
			'Stravignino',
			'Arci-Empolitana',
			'Monasterolo',
			'Cadipietra',
			'Sachet',
			'Ubiale',
			'Velo',
			'Santa Maria la Stella',
			'Zumpano',
			'Zerfaliu',
			'Villarosa',
			'Verzino',
			'Terrasini',
			'Seminara',
			'Samatzai',
			'Rosolini',
			'Roccella Valdemone',
			'Rocca di Neto',
			'Polizzi Generosa',
			'Nuovo Centro Urbano Poggioreale',
			'Palagonia',
			'Oliveri',
			'Noto',
			'Misilmeri',
			'Messignadi',
			'Guardia Piemontese',
			'Gergei',
			'Galati Mamertino',
			'Fordongianus',
			'Dolianova',
			'Diso',
			'Curcuris',
			'Cropalati',
			'Cetraro',
			'Catanzaro',
			'Casalvecchio Siculo',
			'Carloforte',
			'Brancaleone',
			'Belsito',
			'Amaroni',
			'Zollino',
			'Zagarolo',
			'Villarboit',
			'Vigolo',
			'Vignola',
			'Veroli',
			'Vazzola',
			'Varsi',
			'Valdottavo',
			'Uggiate Trevano',
			'Trissino',
			'Tres',
			'Tregnago',
			'Torre San Patrizio',
			'Torpè',
			'Tornaco',
			'Tessennano',
			'Terzigno',
			'Terricciola',
			'Suvereto',
			'Stezzano',
			'Sozzago',
			'Sorradile',
			'Somma Lombardo',
			'Sgurgola',
			'Scalenghe',
			'Sauris di Sotto',
			'Sarconi',
			'San Vito di Fagagna',
			'San Vincenzo',
			'San Venanzo',
			'San Valentino Torio',
			"Sant'Elena Sannita",
			"Sant'Elena",
			'Santa Tecla-Castelpagano',
			"Sant'Angelo in Vado",
			'San Ponso',
			'San Lorenzo di Sebato',
			'San Giovanni Incarico',
			'Sala Baganza',
			'Rosora',
			'Rosa',
			'Rocca Massima',
			'Rassina',
			'Quinto Vicentino',
			'Quingentole',
			'Quindici',
			"Quarto d'Altino",
			'Pumenengo',
			'Puglianello',
			'Prazzo Superiore',
			'Pralboino',
			'Pozzomaggiore',
			'Povegliano Veronese',
			'Potenza',
			'Portici',
			'Porto Ercole',
			'Ponticelli',
			'Pompeiana',
			'Poli',
			'Poggio Sannita',
			'Pioraco',
			'Pinarella',
			'Pievepelago',
			'Piagge',
			'Peglio',
			'Palazzuolo sul Senio',
			'Ovodda',
			'Oppeano',
			'Olbia',
			'Nocera Inferiore',
			'Noasca',
			'Nibbiano',
			'Mossa',
			'Morra de Sanctis',
			'Montopoli in Sabina',
			'Montesilvano Marina',
			'Montereale Valcellina',
			'Montereale',
			'Montepulciano',
			'Montefiascone',
			'Montecchio Maggiore-Alte Ceccato',
			'Monte Castello di Vibio',
			'Montebello sul Sangro',
			'Montalto di Castro',
			'Moncalieri',
			'Moiano',
			'Modugno',
			'Mezzocorona',
			'Mergozzo',
			'Mentana',
			'Medolla',
			'Medesano',
			'Marzabotto',
			'Marsia',
			'Marsaglia',
			'Margarita',
			'Marene',
			"Magliano De'Marsi",
			'Magasa',
			'Lusia',
			'Lucito',
			'Montano Lucino',
			'Lonate Pozzolo',
			'Limbiate',
			'Lignana',
			'Lentiai',
			'Lazise',
			"L'Aquila",
			'Laion',
			'Lagundo',
			'Labro',
			'Inzago',
			'Guardia Perticara',
			'Gromo',
			'Grizzana Morandi',
			'Gragnano',
			'Gorga',
			'Marina di Ginosa',
			'Gassino Torinese',
			'Gambolò',
			'Gambatesa',
			'Galugnano',
			'Gallo Matese',
			'Gallicano',
			'Fusine',
			'Furore',
			'Furci',
			'Fossombrone',
			'Forlimpopoli',
			'Trebbio',
			'Fano Adriano',
			'Faedo Valtellino',
			'Zagarise',
			'Villamassargia',
			'Venetico Superiore',
			'Troina / Draginai',
			'Trebisacce',
			'Torretta',
			'Termini Imerese',
			'Stignano',
			'Siracusa',
			'Siliqua',
			'Serramanna',
			'Santo Stefano in Aspromonte',
			'Santa Eufemia Lamezia',
			'San Teodoro',
			'Santa Margherita di Belice',
			'Santa Croce Camerina',
			'San Pier Niceto',
			'San Luca',
			'Rosarno',
			'Rodì',
			'Roccaforte del Greco',
			'Portoscuso',
			'Pollina',
			'Piane Crati',
			'Pavigliana',
			'Pannaconi',
			'Naro',
			'Mirto',
			'Mendicino',
			'Mascalucia',
			'Marcellinara',
			'Mandatoriccio',
			'Maida',
			'Magisano',
			'Lucca Sicula',
			'Linguaglossa',
			'La Maddalena',
			'Iglesias',
			'Grisolia',
			'Gonnesa',
			'Girasole',
			'Frazzanò',
			'Francavilla Marittima',
			'Feroleto della Chiesa',
			'Custonaci',
			'Cellara',
			'Cattolica Eraclea',
			'Casarano',
			'Calatabiano',
			'Butera',
			'Brucoli',
			'Brolo',
			'Bianchi',
			'Belpasso',
			'Andrano',
			'Alimena',
			'Alcamo',
			'Volpiano',
			'Vimodrone',
			'Villa Pedergnano',
			'Vicomoscano',
			'Vetto',
			'Vespolate',
			'Vermezzo',
			'Vedeseta',
			"Vaprio D'Agogna",
			'Valperga',
			'Troia',
			'Trivero-Prativero-Ponzone',
			'Triora',
			'Tramutola',
			'Tradate',
			'Toscolano Maderno',
			'Torricella del Pizzo',
			'Torrecuso',
			'Tolentino',
			'Tempio Pausania',
			'Telti',
			'Taurasi',
			'Stroncone',
			'Stregna',
			'Stienta',
			'Staggia',
			'Spigno Monferrato',
			'Solbiate Olona',
			'Sicignano degli Alburni',
			"Serra Sant'Abbondio",
			'Seregno',
			'Senigallia',
			'Segrate',
			'Secchiano',
			'Seborga',
			'Sciolze',
			'Savignano sul Rubicone',
			'Sanzeno',
			'San Terenziano',
			'Santeramo in Colle',
			"Sant'Angelo d'Alife",
			'San Sossio Baronia',
			'San Pietro Avellana',
			'San Paolo',
			'San Maurizio',
			'San Lorenzo in Campo',
			'San Giustino',
			"San Giovanni d'Asso",
			'San Giovanni',
			'San Giorgio in Salici',
			'San Giorgio di Lomellina',
			'San Clemente',
			'San Biase',
			'Salizzole',
			'Sali Vercellese',
			'Rovereto',
			'Romagnese',
			'Rogolo',
			"Rocchetta Sant'Antonio",
			'Susella',
			'Rocca Pia',
			'Roccamonfina',
			'Roccagiovine',
			'Rocca di Cambio',
			'Ripatransone',
			'Rio Saliceto',
			'Retorbido',
			'Raiano',
			'Ripa-Pozzi-Querceta-Ponterosso',
			'Pretoro',
			'Preore',
			'Pray',
			'Potenza Picena',
			'Porto Corsini',
			'Pontevico',
			'Pontebba',
			'Pomaretto',
			'Pollica',
			'Pieve Ligure',
			'Pieve di Soligo',
			'Pieve di Cadore',
			'Picinisco',
			'Pianazzo',
			'Piana Crixia',
			'Pezzana',
			'Peveragno',
			'Pescia',
			'Pellestrina',
			'Pegognaga',
			'Pago Veiano',
			'Otranto',
			'Ossago Lodigiano',
			'Ospedalicchio',
			'Ortona dei Marsi',
			'Ormea',
			'Olmeneta',
			'Odolo',
			'Nuoro',
			'Novara',
			'Norcia',
			'Nomi',
			'Noicattaro',
			'Naturno',
			'Morterone',
			'Morfasso',
			'Montorio',
			'Monticello Conte Otto',
			'Montespertoli',
			'Monte San Giovanni in Sabina',
			'Monteprandone',
			'Montemurro',
			'Montemitro',
			'Montemarano',
			'Montelparo',
			'Monteleone di Spoleto',
			'Monteflavio',
			'Montecosaro',
			'Montecatini Val di Cecina',
			'Mongiardino Ligure',
			'Mirabello',
			'Mira Taglio',
			'Minori',
			'Migliarino',
			'Matera',
			'Masserano',
			'Marone',
			'Margherita di Savoia',
			'Marcallo',
			'Maleo',
			'Madignano',
			'Maddaloni',
			'Macomer',
			'Macerata Feltria',
			'Maccarese',
			'Luino',
			'Longastrino',
			'Londa',
			'Villa Lempa',
			'Legnago',
			'Lavello',
			'Lauzacco',
			"Isola D'Arbia",
			'Introdacqua',
			'Guardia Lombardi',
			'Grumes',
			'Grotte di Castro',
			'Greci',
			'Granozzo con Monticello',
			'Gorno',
			'Gorle',
			'Gignese',
			'Fubine',
			'Frosinone',
			'Francolino',
			'Forte dei Marmi',
			'Flaibano',
			'Fiuggi',
			'Fermignano',
			'Dumenza',
			'Dolcedo',
			'Divignano',
			'Desana',
			'Cutrofiano',
			'Costabissara',
			'Corcolle',
			'Colle San Magno',
			"Civitella d'Agliano",
			'Cison di Valmarino',
			'Cinte Tesino',
			'Cigole',
			'Ciciliano',
			'Chiusanico',
			'Chiesina Uzzanese',
			'Cesana Torinese',
			'Cerignola',
			'Celano',
			'Cedrasco',
			'Cecina',
			'Castelverrino',
			'Castel San Pietro Terme',
			'Castellamonte',
			'Castelgrande',
			'Casorzo',
			'Casatenovo',
			'Carovilli',
			'Caravaggio',
			'Caramanico Terme',
			'Caprino Veronese',
			'Capriate San Gervasio',
			'Capitignano',
			'Campagnola Emilia',
			'Calvene',
			"Calusco d'Adda",
			'Caldonazzo',
			'Bucciano',
			'Brittoli',
			'Brignano',
			'Boschetto',
			'Borgo San Lorenzo',
			'Borgomale',
			'Borgagne',
			'Bidonì',
			'Bellinzago Novarese',
			'Belfiore',
			'Azeglio',
			'Ascoli Satriano',
			'Arrone',
			'Ari',
			'Ardauli',
			'Arcola',
			'Arceto',
			'Aquilonia',
			'Anticoli Corrado',
			'Annicco',
			'Ancarano',
			'Altamura',
			"Albano Sant'Alessandro",
			'Agugliaro',
			'Vallonga',
			'Sutrio',
			'Bortolot',
			'Soccher-Paiane-Casan-Arsie',
			'Brusaporto',
			'Parete',
			'Mombercelli',
			'Briga Alta',
			'Diano Arentino',
			'Castelletto Merli',
			'Cimoneri',
			'Garniga Nuova',
			'Giovo',
			'Roncaro',
			'Rumo',
			'Sagron Mis',
			"Sant'Orsola Terme",
			'Papozze',
			'Pelugo',
			'Caminata',
			'Nibionno',
			'Paderno del Grappa',
			'Prati',
			'Vallada Agordina',
			'Murello',
			'Fresonara',
			'Grondona',
			'Ricaldone',
			'Clivio',
			'Albese Con Cassano',
			'Veduggio Con Colzano',
			'Adrara San Rocco',
			'Dorsino',
			'Arquà Polesine',
			'Crosio della Valle',
			'Galliate Lombardo',
			'Mantello',
			'Tartano',
			'Gianico',
			'Bonemerse',
			'Binetto',
			'Suello',
			'Castelnuovo Belbo',
			'Celle Enomondo',
			'Settime',
			'Basaluzzo',
			'Castelletto Monferrato',
			'Merana',
			'Montecastello',
			'Velo Veronese',
			'Fosciandora',
			'Torre Caietani',
			'Riace',
			'Muzzano',
			'Viverone',
			'Primaluna',
			'Cossogno',
			'Rea',
			'Govone',
			'Cantagrillo-Casalguidi',
			'Orentano',
			'Ara Nova',
			'Tuturano',
			'Cortoghiana',
			'Carraia',
			'Mezzate',
			'Casa Santa',
			'Airali',
			'Santa Margherita',
			'Pagliare',
			'Valle Martella',
			'Santa Teresa',
			'Pieve',
			'San Pietro',
			'Casali-San Potito',
			'Castelminio',
			'Gaggino',
			'Fornaci',
			'Santa Maria di Non',
			'Tagliaferro',
			'Pomaretto',
			'Sferracavallo',
			'Moniego',
			'Ariano',
			'Pineta',
			'Casette Verdini',
			'Country Park',
			'Nigoline-Bonomelli',
			'Manera',
			'Fantasina',
			'Gavarno Rinnovata',
			'Novoledo',
			'Puginate',
			'Vigliatore 2',
			'Ronchi di Campanile',
			'Lases',
			'Centrale',
			"Pie' Falcade",
			'Rocca Imperiale Marina',
			'Bisaccia Nuova',
			'Gerbole-Zucche',
			'San Pietro a Patierno',
			'Zeddiani',
			'Villasimius',
			'Uras',
			'Spezzano Piccolo',
			'Siris',
			'Siculiana',
			'Serrastretta',
			'Sclafani Bagni',
			'San Mauro Castelverde',
			'San Biagio Platani',
			'Reitano',
			'Realmonte',
			'Polistena',
			'Petrizzi',
			'Pauli Arbarei',
			'Pantelleria',
			'Palazzo Adriano',
			'Mussomeli',
			'Montesano Salentino',
			'Monasterace',
			'Milazzo',
			'Maracalagonis',
			'Marina di Schiavonea',
			'Longi',
			'Decimoputzu',
			'Corleone',
			'Cessaniti',
			'Centrache',
			'Castroreale',
			'Castelluzzo',
			'Casabona',
			'Canicattì',
			'Campofiorito',
			'Caltanissetta',
			'Caltagirone',
			'Calamonaci',
			'Balestrate',
			'Acireale',
			'Acate',
			'Zuccarello',
			'Zolla',
			'Lancenigo-Villorba',
			'Villa Vicentina',
			'Villa Santa Lucia degli Abruzzi',
			'Villa Santa Lucia',
			'Villanova',
			'Villadose',
			'Vieste',
			'Vico nel Lazio',
			'Vicolungo',
			'Verucchio',
			'Valle San Nicolao',
			'Valle Mosso',
			'Vadena',
			'Tursi',
			'Triggiano',
			'Trevenzuolo',
			'Torremaggiore',
			'Tonezza del Cimone',
			'Tolfa',
			'Tinnura',
			'Zugliano-Terenzano-Cargnacco',
			'Terdobbiate',
			'Teramo',
			'Tencarola',
			'Teglio Veneto',
			"Taverne D'Arbia",
			'Tassullo',
			'Tagliacozzo',
			'Stigliano',
			'Stanghe',
			'Sospiro',
			'Sordevolo',
			'Solopaca',
			'Solaro',
			'Settimo Rottaro',
			'Serre di Rapolano',
			'Serramonacesca',
			'Sedriano',
			'Gazzada Schianno',
			'Sappada',
			'Santu Lussurgiu',
			'Santopadre',
			"Sant'Ippolito",
			'San Vittoria in Matenano',
			"Sant'Angelo Lomellina",
			"Sant'Angelo",
			'Santa Maria Nuova',
			'San Secondo',
			'San Paolo Bel Sito',
			'Olivetta San Michele',
			'San Mauro Torinese',
			'San Giovanni in Galdo',
			'San Giorgio Lucano',
			'San Giacomo',
			'San Fior di Sopra',
			'San Felice Circeo',
			'Santa Croce del Sannio',
			'Salcito',
			'Ruvo di Puglia',
			'Ruviano',
			'Rottofreno',
			'Rossano Veneto',
			'Rossana',
			'Ronchi dei Legionari',
			'Rodengo',
			'Robecco sul Naviglio',
			'Rivamonte Agordino',
			'Rieti',
			'Reino',
			'Ravascletto',
			'Quasano',
			'Quarona',
			'Posillipo',
			'Porporano',
			'Ponzano di Fermo',
			'Ponte Nossa',
			'Pontelandolfo',
			'Ponte Arche',
			'Pontedassio',
			'Polignano a Mare',
			'Polcenigo',
			'Poirino',
			'Poggiridenti Alto',
			'Villa Opicina',
			'Piozzano',
			'Pioltello',
			'Pigra',
			'Pignataro Maggiore',
			'Pico',
			'Piano di Sorrento',
			'Pianezza',
			'Petrignano',
			'Pescopennataro',
			'Pescopagano',
			'Penne',
			'Penna in Teverina',
			'Pellezzano',
			'Pasiano',
			'Palomonte',
			'Palazzo Canavese',
			'Paesana',
			'Padivarma',
			'Ostra Vetere',
			'Ortovero',
			'Orta San Giulio',
			'Orio Litta',
			'Ollolai',
			'Nulvi',
			'Novalesa',
			'Novaledo',
			'Norma',
			'Norbello',
			'Nerito',
			'Muzzana del Turgnano',
			'San Pietro Mussolino',
			'Morrovalle',
			'Morlupo',
			'Montescaglioso',
			'Monte San Giacomo',
			'Monterosi',
			'Monteroduni',
			'Montemurlo',
			'Montemonaco',
			'Montemiletto',
			'Montelupo Fiorentino',
			'Montegrosso Pian Latte',
			'Montefusco',
			"Montefiore dell'Aso",
			'Montappone',
			'Montaione',
			'Monsampolo del Tronto',
			'Monghidoro',
			'Monacilioni',
			'Miradolo Terme',
			'Miane',
			'Mercogliano',
			'Luzzara',
			'Luras',
			'Lungavilla',
			'Luco dei Marsi',
			'Longone Sabino',
			'Liscate',
			'Limone sul Garda',
			'Ligosullo',
			'Lenta',
			'Lazzeretto',
			'Lasino',
			'Lari',
			'Langhirano',
			'Lagosanto',
			'Laerru',
			'Isorella',
			'Isola Dovarese',
			'Induno Olona',
			'Grugliasco',
			'Gruaro',
			'Grottammare',
			'Gottolengo',
			'Goito',
			'Godiasco',
			'Ghilarza',
			'Genazzano',
			'Gavignano',
			'Garzeno',
			'Ganda',
			'Gagliano Aterno',
			'Fumane',
			'Fornaci',
			"Fontaneto D'Agogna",
			'Fobello',
			'Fanzolo',
			'Faenza',
			'Fabbrico',
			'Dovadola',
			'Dongo',
			'Diano Castello',
			'Curtarolo',
			'Cuceglio',
			'Crevacuore',
			'Cossignano',
			"Cortina d'Ampezzo",
			'Corteolona',
			'Corte',
			'Corbola',
			'Colmurano',
			'Cinto Caomaggiore',
			'Cigliano',
			'Chivasso',
			'Chiaromonte',
			'Chiampo',
			'Cersosimo',
			'Castiglione',
			'Casteltodino',
			'Castellaneta',
			'Castelgomberto',
			'Cassinasco',
			'Casaleggio Novara',
			'Casalbuttano',
			'Casalattico',
			'Carpineto Sinello',
			'Carpignano Salentino',
			'Carnago',
			'Capaccio',
			'Campomaggiore',
			'Campo Ligure',
			'Campodarsego',
			'Campiglia dei Berici',
			'Campagnano di Roma',
			'Cambiano',
			'Calcata Nuova',
			'Cadoneghe',
			'Buonabitacolo',
			'Brumano',
			'Brisighella',
			'Breno',
			'Brebbia',
			'Bracciano',
			'Borno',
			'Bormio',
			'Borgolavezzaro',
			'Bonate Sopra',
			'Bitonto',
			'Bienno',
			'Bessude',
			'Besozzo',
			'Bellosguardo',
			'Bazzano',
			'Basilicanova',
			'Barge',
			'Barga',
			'Ballabio',
			'Aurigo',
			'Asso',
			'Ascrea',
			'Arola-Preazzano',
			'Arlena di Castro',
			'Ariano Irpino-Martiri',
			'Apricale',
			'Appiano Gentile',
			'Andreis',
			'Alfonsine',
			'Alassio',
			'Ailoche',
			'Agosta',
			'Afragola',
			'Lutrano',
			'Selva del Montello',
			'Campolongo Maggiore Liettoli',
			'Sottoselva',
			"Romano d'Ezzelino",
			'Monte Argentario',
			'Guidonia Montecelio',
			"Sant'Egidio del Monte Albino",
			'Moiola',
			'Quaranti',
			"Isola d'Asti",
			'Challand-Saint-Victor',
			'Plesio',
			'Stazzona',
			'Irma',
			'Bagnaria',
			'Santa Maria della Versa',
			'Nanno',
			'Sfruz',
			'Lugo di Vicenza',
			'Palazzo Pignano',
			'Tresana',
			'Ossimo Superiore',
			'Saint Marcel',
			'Venticano',
			'Senales',
			'Villandro - Villanders',
			'Calvisi',
			'Gavazzana',
			'Orsara Bormida',
			'Bianzano',
			'Bossico',
			"Chignolo d'Isola",
			'Rivoli Veronese',
			'Montegalda',
			'Frassinelle Polesine',
			'Casanova Lerrone',
			'Ponte in Valtellina',
			"Tovo di Sant'Agata",
			'Vervio',
			'Cavernago',
			'Pieranica',
			'San Didero',
			'Tufara',
			'Jacurso',
			'Desulo',
			'Casaletto Lodigiano',
			'Bibiana',
			'Asigliano Vercellese',
			"Baldissero d'Alba",
			'Prunetto',
			'Rocchetta Palafea',
			'San Giorgio delle Pertiche',
			'Castello del Matese',
			'Massa di Somma',
			'Trappeto',
			'Golfo Aranci',
			'Dolzago',
			'Caiolo',
			'Maglione',
			'Torrazza Piemonte',
			'Nebbiuno',
			'Povo',
			'Corrubbio',
			'Fizzonasco',
			'Vidalengo',
			'Margine Coperta-Traversagna',
			'Osteria Nuova',
			'Piazza',
			'San Martino Bassa',
			'Vighignolo',
			'Bolladello-Peveranza',
			'Annunziata',
			'Vigliano-Bettolino',
			'Stella',
			'Grignano Polesine',
			'Robegano',
			'Capezzano Inferiore',
			'Tregasio',
			'Bottega',
			'Fénis',
			'Vergnasco',
			'Rio Salso-Case Bernardi',
			'Vigardolo',
			'Campofiorenzo-California',
			'Bosco Ex Parmigiano',
			'Armetta',
			'Badalasco',
			'Era',
			'Castelletto Po',
			'Mongnod',
			'San Lorenzo di Rovetta',
			'Campo dei Fiori',
			'Cerlongo',
			'Cergnago',
			'Cavaria Con Premezzo',
			'Castel Volturno',
			'Castelnuovo Parano',
			'Castellana',
			'Cellio',
			'Cavenago di Brianza',
			'Cavedago',
			'Castel San Giovanni',
			'Castelnuovo Bormida',
			'Castelli Calepio',
			'Castelguglielmo',
			"Castel d'Ario",
			'Castel Baronia',
			'Cassino',
			"Cascinette d'Ivrea",
			'Casargo',
			'Casalgrande',
			'Carisolo',
			'Caresana',
			'Canepina',
			'Canale',
			'Camucia-Monsigliolo',
			'Calitri',
			'Caldarola',
			'Calci',
			'Calavino',
			'Bucchianico',
			'Bubbio',
			'Brusnengo',
			'Bruna',
			'Brugnera',
			'Bronzolo',
			'Brescia',
			'Bra',
			'Bottanuco',
			'Borutta',
			'Boroneddu',
			'Borgomanero',
			'Borbona',
			'Santo Stefano-Bonaldo',
			'Bogliasco',
			'Bassano Romano',
			'Bardolino',
			'Baiano',
			'Avella',
			'Arzano',
			'Arsoli',
			'Arre',
			'Arnara',
			'Appignano del Tronto',
			'Anterivo',
			'Anitrella-Chiaiamari',
			'Ambra',
			'Airole',
			'Agna',
			'Abbateggio',
			'Trivignano',
			'Forgaria nel Friuli',
			'Fratte Rosa',
			'Treviolo',
			'Pino Torinese',
			'Montaldo di Mondovì',
			'Salza di Pinerolo',
			'Masciago Primo',
			'Cremia',
			'Ponteranica',
			'Commezzadura',
			'Terragnolo',
			'San Daniele Po',
			'Ponti sul Mincio',
			'Malosco',
			'Zeri',
			'Paisco Loveno',
			'Gardola',
			'Odalengo Grande',
			'Gorlago',
			'Golferenzo',
			'Canterano',
			'Vignole Borbera',
			'Volpeglino',
			'Brunello',
			'Proserpio',
			'Spriana',
			'Cellatica',
			'Battuda',
			'Valle Salimbene',
			'Chieve',
			'Ricengo',
			'Pietraroja',
			'Pescate',
			'Giaglione',
			'San Giacomo Vercellese',
			'Barolo',
			'Moasca',
			'Tonengo',
			'Ispani',
			'Caldiero',
			'Cartigliano',
			'Villa San Giovanni in Tuscia',
			'San Nicola Manfredi',
			'Rivisondoli',
			'Maccastorna',
			'Caprezzo',
			'Valmorea',
			'Caponago',
			'San Sebastiano da Po',
			'Garbagna Novarese',
			'Oleggio Castello',
			'Monticelli Terme',
			'Cadenabbia',
			'Marcelli',
			'Ciconia',
			'Piazza-Tralia-Pendolo',
			'Su Planu',
			'Carrubazza-Motta',
			'Lamie di Olimpie-Selva',
			'San Pietro',
			'Mussotto',
			'Asciano',
			'Saint Maurice',
			'Faella',
			'Mairano',
			'Acconia',
			'Caniezza',
			'Bagno',
			'Asparetto',
			'Manzolino',
			'Mandrogne',
			'Campocavallo',
			'Barbiano',
			'Fanano',
			'Martelli-Laganosa',
			'Valli',
			'Camilleri-Vallelata',
			'Cascinare',
			'Tonnara di Bonagia',
			'Prosto',
			'San Giorgio',
			'Mornico',
			'Santa Marta',
			'Aurogna',
			'Ferriere',
			'Corigliano Scalo',
			'Marciano',
			'Botta',
			'Codiverno',
			'Zafferana Etnea',
			'Villamar',
			'Tropea',
			'Trenta',
			'Squillace',
			'Soverato Superiore',
			'Senorbì',
			'Sciara',
			"Sant'Eufemia d'Aspromonte",
			'San Salvatore di Fitalia',
			'San Nicola Arcella',
			'Samassi',
			'Riposto',
			'Racalmuto',
			'Mormanno',
			'Morciano di Leuca',
			'Monforte San Giorgio',
			'Molochio',
			'Mileto',
			'Melilli',
			'Melicuccà',
			'Leuca',
			'Leni',
			'Genoni',
			'Fossato Serralta',
			'Floridia',
			'Davoli',
			'Cutro',
			'Chiaramonte Gulfi',
			'Centuripe',
			'Marina di Caronia',
			'Dipignano',
			'Bisacquino',
			'Volturara Irpina',
			'Volturara Appula',
			'Volargne',
			'Vitigliano',
			'Viola',
			'Vinadio',
			'Villasanta',
			'Villar Perosa',
			'Villa Latina',
			'Vietri di Potenza',
			'Vervò',
			'Vernazza',
			'Varenna',
			'Valnegra',
			'Vajont',
			'Tremignon',
			'Travo',
			'Torre di Mosto',
			'Tione degli Abruzzi',
			'Tecchiena',
			'Terlano',
			'Talla',
			'Sutri',
			'Stra',
			'Squinzano',
			'Spoltore',
			'Sormano',
			'Soliera',
			'Sizzano',
			'Sessa Aurunca',
			'Serramazzoni',
			'Serracapriola',
			'Serle',
			'Seniga',
			'Scampitella',
			'Sarnico',
			'Santo Stefano al Mare',
			"Sant'Omero",
			"Sant'Ilario d'Enza",
			'Santi Cosma e Damiano',
			'Roncofreddo-Santa Paola',
			"Sant'Antonio",
			"Sant'Angelo A Fasanella",
			'Santa Giustina',
			"Santa Croce sull'Arno",
			'Santa Cristina',
			'San Severo',
			'San Potito Ultra',
			'San Pietro Infine',
			"San Panfilo d'Ocre",
			'San Nicola Baronia',
			"Sannazzaro de' Burgondi",
			'San Martino',
			'San Giorgio Piacentino',
			'Sammichele di Bari',
			'Salice Terme',
			'Rutino',
			'Ruoti',
			'Rosasco',
			'Roncocesi',
			'Rocchetta Ligure',
			'Roccavivara',
			'Roccastrada',
			"Rivolta d'Adda",
			'Ripabottoni',
			'Rio Marina',
			'Riano',
			'Revigliasco',
			'Revere',
			'Rauscedo-Domanins',
			'Predore',
			'Pratola Serra',
			'Prato Allo Stelvio',
			'Posta Fibreno',
			'Pontinvrea',
			'Ponticino',
			'Pollein',
			'Pitigliano',
			'Pistoia',
			'Pino sulla Sponda del Lago Maggiore',
			'Pila',
			"Pie' del Sasso",
			'Piateda Centro',
			'Pescarolo',
			'Percile',
			'Pecetto',
			'Pavona',
			'Paterno',
			'Palmiano',
			'Pallanzeno',
			'Ovada',
			'Oulx',
			'Ottiglio',
			'Ortelle',
			'Origgio',
			'Olzai',
			'Novate Milanese',
			'Nepezzano',
			'Neoneli',
			'Nembro',
			'Nalles',
			'Musile di Piave',
			'Murialdo',
			'Motta Visconti',
			'Motta',
			'Montù Beccaria',
			'Mondavio',
			'Molino del Pallone',
			'Missanello',
			'Misano Adriatico',
			'Milan',
			'Mezzana',
			'Melito Irpino',
			'Medolago',
			'Mediis',
			'Maserà di Padova',
			'Marina di Pisa',
			'Manzano',
			'Malonno',
			'Malborghetto',
			'Loria Bessica',
			'Locate Varesino',
			'Lentella',
			'Lardaro',
			'Inverno',
			"Incisa in Val d'Arno",
			'Capitello',
			'Guglionesi',
			'Giulianova',
			'Giffoni Valle Piana',
			'Quartiano',
			'Garlenda',
			'Frassinello Monferrato',
			'Formazza',
			'Fiumana',
			'Fiano',
			'Esino Lario',
			'Druogno',
			'Dro',
			'Dolo',
			'Danta',
			'Cuneo',
			'Crocefieschi',
			'Courmayeur',
			'Cotignola',
			'Costa Volpino',
			'Cortazzone',
			'Cortanze',
			"Cornate d'Adda",
			'Conco',
			'Comeglians',
			'Collecorvino',
			'Codaruina',
			'Civitella in Val di Chiana',
			'Chiauci',
			'Chambave',
			'Castelvenere',
			'Castelrotto',
			'Castelnuovo di Garfagnana',
			'Castellammare di Stabia',
			'Castel del Monte',
			'Emarese',
			'Dego',
			'Crissolo',
			'Crespano del Grappa',
			'Concordia sulla Secchia',
			'Collegno',
			"Colle di Val d'Elsa",
			'Colledimacine',
			'Colle di Fuori',
			'Colà',
			'Cogollo del Cengio',
			'Clusane',
			'Civitanova Alta',
			'Cimego',
			'Cicagna',
			'Ceto',
			'Cesa',
			'Cerese',
			'Cavareno',
			'Cavallino',
			'Castel Viscardo',
			'Castelverde',
			'Castelnovo di Sotto',
			'Castelguidone',
			'Castelbaldo',
			'Casorate Sempione',
			'Casalvieri',
			'Casalciprano',
			'Carpineti',
			'Carpaneto Piacentino',
			'Carmignano di Brenta',
			'Carinola',
			'Cargeghe',
			'Cardano',
			'Carbone',
			'Capriolo',
			'Caprese Michelangelo',
			'Capranica Prenestina',
			'Cannalonga',
			'Caneva',
			'Canazei',
			'Campora',
			'Caltrano',
			'Caldes',
			'Calderara di Reno',
			'Busana',
			'Grand Brissogne',
			'Breguzzo',
			'Pregola',
			'Boville Ernica',
			'Borgo Ticino',
			'Borgonuovo',
			'Bore',
			'Bigolino',
			'Benetutti',
			'Baselga di Pinè',
			'Barni',
			'Bardi',
			'Balestrino',
			'Bagnolo San Vito',
			'Bagni di Lucca',
			'Badia Tedalda',
			'Attimis',
			'Ateleta',
			'Anagni',
			'Albanella',
			'Agazzano',
			'Affi',
			'Acquaviva delle Fonti',
			'Acquaviva',
			'Acquasparta',
			'Aurisina Cave',
			'San Lorenzo Isontino',
			'Prata di Sotto',
			'Limana',
			'Pradamano',
			'San Giacomo',
			'Moraro',
			'Motta Camastra',
			'Castelnuovo del Garda',
			'Cardano al Campo',
			'Vinovo',
			'San Benedetto Belbo',
			'Roure',
			'Camerano Casasco',
			'Ayas',
			'Montorfano',
			'Nesso',
			'Bema',
			'Mezzegra',
			'Montecalvo Versiggia',
			'Casa Ponte',
			'Redavalle',
			'Ripalta Arpina',
			'Ripalta Guerina',
			'Brione',
			'Valle e Castello',
			'Capraia e Limite',
			'Tirolo',
			'Fiumara',
			'Castiraga Vidardo',
			'Pocapaglia',
			'Avolasca',
			'Sezzadio',
			'Brenta',
			'Segusino',
			'Rocca Santo Stefano',
			'Angrogna',
			'Capiago-Intimiano-Olmeda',
			'Guanzate',
			'Lipomo',
			'Berzo San Fermo',
			'Credaro',
			'Lurano',
			'Borgarello',
			'Bieno',
			'Poggiorsini',
			"Motta d'Affermo",
			'Valverde',
			'Ragalna',
			'Seppiana',
			'La Cassa',
			'Cervatto',
			'Cossano Belbo',
			'Trezzo Tinella',
			'Vicoforte',
			'Villanova Solaro',
			'Vottignasco',
			'Castelleone di Suasa',
			'Force',
			'Carbonara di Nola',
			'Rosazza',
			'Rodero',
			'San Secondo di Pinerolo',
			'Arguello',
			'Brondello',
			'Caramagna Piemonte',
			'Nova Siri Scalo',
			'Grottola',
			"Ca' Savio",
			'Colombiera-Molicciara',
			'Belvedere',
			'Regoledo',
			'Onè',
			'Capezzano-Cologna',
			'Limidi',
			'Bolognano-Vignole',
			'Capriano',
			"Sant'Agata Martesana",
			'Colle Campano-Scrima',
			'Savarna-Conventello',
			'Campanella-Gianforma',
			"Ca' Rainati",
			'San Fedele-Lusignano',
			'Piegolelle-San Bartolomeo',
			'Villanova',
			'San Bartolomeo',
			'Villa Fontana',
			'Costaroni',
			'Rettifilo-Vannullo',
			'Borgo Massano',
			'Belforte',
			'Casalini',
			'Villaggio Montegrappa',
			'Leone-Santa Elisabetta',
			'La Place',
			"Sant'Antonio",
			'Tettorosso',
			'Agliano',
			'Feglino',
			'Sirta',
			'Progetto Case Sassa Nsi',
			'Marina di Altidona',
			'Neive-Borgonovo',
			'Viagrande',
			'Tiggiano',
			'Serrenti',
			'Scalea',
			'San Vito',
			"Sant'Angelo Muxaro",
			'San Pietro in Amantea',
			'San Fili',
			"Quartu Sant'Elena",
			'Pietrafitta',
			'Pettineo',
			'Muravera',
			'Motta Santa Lucia',
			'Montemaggiore Belsito',
			'Messina',
			'Melissano',
			'Marina di Fuscaldo',
			'Marina di Camerota',
			'Malvagna',
			'Rocca di Capri Leone',
			'Larderia',
			'Giarratana',
			'Favignana',
			'Fabrizia',
			'Esterzili',
			'Domus de Maria',
			'Decollatura',
			'Cervicati',
			'Catania',
			'Capoterra',
			'Capistrano',
			'Basicò',
			'Amantea',
			'Ales',
			'Albi',
			'Acri',
			'Acquaviva Platani',
			'Volpedo',
			'Vigonza',
			'Vigodarzere',
			'Vigevano',
			'Vessalico',
			'Venegono Inferiore',
			'Velletri',
			'Vasia',
			'Vanzaghello',
			'Valmontone',
			'Vallerano',
			'Usmate-Velate',
			'Treia',
			'Trecenta',
			'Trebaseleghe',
			'Torri di Quartesolo',
			"Torre d'Isola",
			"Torre de' Passeri",
			'Torre del Lago Puccini',
			'Torno',
			'Tornareccio',
			'Tocco da Casauria',
			'Toano',
			'Ternate',
			'Terlizzi',
			'Sulmona',
			'Subiaco',
			'Solto Collina',
			'Siligo',
			'Sesto al Reghena',
			'Senise',
			'Senale',
			'Scerni',
			'Saviore',
			'Sassa',
			'San Zeno di Montagna',
			'San Vendemiano',
			"Sant'Oreste",
			"Sant'Elpidio a Mare",
			"Sant'Agata di Puglia",
			'Santa Fiora',
			"San Quirico d'Orcia",
			'San Pietro in Gu',
			'San Nazzaro Sesia',
			'San Martino Buon Albergo',
			'San Lorenzo al Mare',
			'San Leo',
			'San Giuseppe Vesuviano',
			'San Giuliano di Puglia',
			'San Giovanni Bianco',
			'San Gillio',
			'San Felice del Benaco',
			'San Chirico Raparo',
			'San Cesario sul Panaro',
			'Ruvo del Monte',
			'Roncoferraro',
			'Rodi Garganico',
			'Rodeano',
			'Roccasecca',
			'Rocca di Mezzo',
			'Rocca di Cave',
			'Robbiate',
			'Roasio',
			'Rivello',
			'Rivanazzano',
			'Raveo',
			'Qualiano',
			'Preganziol',
			'Pratantico-Indicatore',
			'Prata di Pordenone',
			'Pozzolengo',
			'Porcia',
			'Pontida',
			'Pontestura',
			'Pontenure',
			'Pomaro Monferrato',
			'Pietraporzio',
			'Pietrapertosa',
			'Pietracupa',
			'Pettorazza Grimani',
			'Pertengo',
			'Pergine Valdarno',
			'Perarolo di Cadore',
			'Pellegrino Parmense',
			'Pandino',
			"Palazzolo sull'Oglio",
			'Padru',
			'Ovaro',
			'Ossi',
			'Ospitaletto',
			'Ospedaletti',
			'Orvinio',
			'Oricola',
			'Orbetello',
			'Vignole-Olmi',
			'Oliena',
			'Olevano sul Tusciano',
			'Nocara',
			'Mugnano del Cardinale',
			'Moso in Passiria',
			'Moruzzo',
			'Morciano di Romagna',
			'Monza',
			'Monticiano',
			'Monteverde',
			'Monte Urano',
			'Montesegale',
			'Montescudo',
			'Monteparano',
			'Montemarciano',
			'Montelupone',
			'Montefranco',
			'Monteforte Cilento',
			'Montalto Pavese',
			'Mongardino',
			'Molveno',
			'Molino del Piano',
			'Mirabella Eclano',
			'Minervino di Lecce',
			'Milano Marittima',
			'Miazzina',
			'Mezzanino',
			'Mercenasco',
			'Mercato San Severino',
			'Menconico',
			'Mazze',
			'Matelica',
			'Massenzatico',
			'Massa Martana',
			'Maschito',
			'Marsico Nuovo',
			'Marotta',
			'Marlengo',
			'Mariano Comense',
			'Marcon-Gaggio-Colmello',
			'Maranello',
			'Mairano',
			'Magione',
			"Macchia d'Isernia",
			'Loreto Aprutino',
			'Lomello',
			'Leonessa',
			'Lardirago',
			'Lanuvio',
			'Isolabona',
			'Grazzanise',
			'Gorla Maggiore',
			'Giurdignano',
			'Gazzaniga',
			'Garlasco',
			'Garessio',
			'Ganna',
			'Collefontana-Fontana Liri Inferiore',
			'Fognano',
			'Fisciano',
			'Filottrano',
			'Fasano',
			'Exilles',
			'Eraclea',
			'Dorno',
			'Dignano',
			'Diano San Pietro',
			'Cumignano sul Naviglio',
			'Corleto Monforte',
			'Corbara',
			'Cizzago-Comezzano',
			'Colombaro-Timoline',
			'Colle di Tora',
			'Cogne',
			'Civita Castellana',
			'Cividale del Friuli',
			'Cinigiano',
			'Chieuti',
			'Chieri',
			'Cervara di Roma',
			'Castelletto Sopra Ticino',
			"Castell'Arquato",
			'Castelbello-Ciardes - Kastelbell-Tschars',
			'Campoli Appennino',
			'Camerota',
			'Calabritto',
			'Bussoleno',
			'Bruno',
			'Briga Novarese',
			'Brenna',
			'Brembio',
			'Breda',
			'Bracigliano',
			'Borso del Grappa',
			'Borgosesia',
			'Bonarcado',
			'Bolzano Novarese',
			'Benna',
			'Benevello',
			'Belmonte in Sabina',
			'Bauladu',
			'Artena',
			'Arcole',
			'Altavilla Silentina',
			'Alpignano',
			'Aliano',
			'Albisola Marina',
			'Albiate',
			'Albano Laziale',
			'Albano di Lucania',
			'Alba',
			'Alagna Valsesia',
			'Tergu',
			'Pisignano',
			'Ponzano',
			'Caselle',
			'Preone',
			'San Martino al Tagliamento',
			'San Raffaele Cimena',
			'Agrate Conturbia',
			'Monastero di Vasco',
			'Roccabruna',
			'Cassinelle-Concentrico',
			'Bizzarone',
			'Merone',
			'Borgo di Ranzo',
			'Sala Comacina',
			'Valgoglio',
			'Costa di Serina',
			'Milzano',
			'Zeccone',
			'Bersone',
			'Fiumicello',
			'Giustenice',
			'Avegno',
			'Selve Marcone',
			'Torre Bormida',
			'Belforte Monferrato',
			'Bergamasco',
			'Molino dei Torti',
			'Mombello Monferrato',
			'Montemarzino',
			'Solonghello',
			'Hone',
			'Brusimpiano',
			'Caronno Varesino',
			'Corna Imagna',
			'Ronchi Valsugana',
			'Selva di Progno',
			'Cadrezzate',
			'Cremenaga',
			'Luvinate',
			'Spessa',
			'Cremosano',
			'Borgofranco sul Po',
			'Bogogno',
			'Bova',
			'Mazzarrone',
			'Villette',
			'Isolabella',
			'Issiglio',
			'Lusigliè',
			'Scopa',
			'Castelletto Stura',
			'Piozzo',
			'Castellania',
			'Garbagna',
			'Telve di Sopra',
			'Massalengo-Motta Vigana',
			'Mondaino',
			'Montecrestese',
			'Cassano Valcuvia',
			'Colorina',
			'Cerro al Lambro',
			'Prezzo',
			'Barbaresco',
			'Castellinaldo',
			'Castellino Tanaro',
			'Albinia',
			'Trebbo',
			'Vigonzone',
			'Calderino',
			'Cras',
			'Sambuceto',
			'Villalba',
			'San Bovio-San Felice',
			'Centinarola',
			'San Paolo',
			'Torre Caracciolo',
			'Marina di Davoli',
			'Stazione-Fornola',
			'Passirana',
			'Cantalupo',
			'Riozzo',
			'Giammoro',
			'Malavicina',
			'Villa Ciambra',
			'Selva',
			'Piangaiano',
			'Casalazzara',
			'Figliaro',
			"Villa d'Asolo",
			'Rotta',
			'Pian di Mugnone',
			'Spartimento',
			'Ronco',
			'Sassacci',
			'Montalto',
			'Villa San Filippo',
			'Caines',
			'Pontestazzemese',
			'Padule-San Marco',
			'Venetico Marina',
			'Avvocata',
			'Zona Industriale',
			'Giardinetti-Tor Vergata',
			'Vizzini',
			'Villaurbana',
			'Trabia',
			'Solarino',
			"Serra d'Aiello",
			'Serdiana',
			'Scicli',
			'San Roberto',
			'San Giovanni Suergiu',
			'San Giorgio Morgeto',
			'San Giorgio Albanese',
			'Riola Sardo',
			'Platì',
			'Pietraperzia',
			'Paravati',
			'Papanice',
			'Palmas Arborea',
			'Pagliarelle',
			'Ortacesus',
			'Oristano',
			'Montauro',
			'Godrano',
			'Gioia Tauro',
			'Gadoni',
			'Filadelfia',
			'Ferruzzano',
			'Falcone',
			'Curinga',
			'Condrò',
			'Comitini',
			'Campobello di Mazara',
			'Alessandria della Rocca',
			'Adrano',
			'Acquaro',
			'Acquarica del Capo',
			'Cardedu',
			'Panchià',
			'Volpara',
			'Voghera',
			'Vittorito',
			'Villalvernia',
			'Villa Estense',
			'Vetralla',
			'Vestenanova',
			'Vas',
			'Valli del Pasubio',
			'Valeggio sul Mincio',
			'Truccazzano',
			'Trivigno',
			'Trinitapoli',
			'Traona',
			'Torre del Greco',
			'Torraca',
			'Teti',
			'Tavoleto',
			'Tavagnacco',
			'Talana',
			'Taino',
			'Strevi',
			'Sestriere',
			'Serrapetrona',
			'Semestene',
			'Selva',
			'Selci',
			'Seano',
			'Scurcola Marsicana',
			'Sanza',
			'San Vito Chietino',
			'Santo Stefano di Sessanio',
			'San Terenzo',
			'Santa Vittoria',
			"Sant'Angelo del Pesco",
			"Sant'Agata Bolognese",
			'San Sebastiano al Vesuvio',
			'San Piero a Sieve',
			'San Pellegrino Terme',
			'San Niccolò di Celle',
			'San Martino Siccomario',
			'San Martino al Cimino',
			'San Lorenzello',
			'San Giorgio Ionico',
			'San Giacomo degli Schiavoni',
			'Sanfront',
			'San Fele',
			'San Donà di Piave',
			'San Costanzo',
			'Samone',
			'Sambuco',
			'Salza Irpina',
			'Saltara',
			'Sale',
			'Saint-Pierre',
			'Rotondi',
			'Rotella',
			'Rosignano Solvay-Castiglioncello',
			'Roseto degli Abruzzi',
			'Ronchis',
			'Rivignano',
			'Recoaro Terme',
			'Recetto',
			'Raviscanina',
			'Ranco',
			'Ramon',
			'Quadri',
			'Prossedi',
			'Premosello-Chiovenda',
			'Prato Carnico',
			'Porto Garibaldi',
			'Ponte Lambro',
			'Ponte Galeria-La Pisana',
			'Pollutri',
			'Policastro Bussentino',
			'Poggiofiorito',
			'Poggio Berni',
			'Pizzone',
			'Pergola',
			'Pella',
			'Parona',
			'Parcines',
			'Palù del Fersina',
			'Palena',
			'Padria',
			'Oyace',
			'Ortona',
			'Orosei',
			'Orcenico Inferiore',
			'Onanì',
			'Novafeltria',
			'Noli',
			'Neviano',
			'Nervesa della Battaglia',
			'Mortegliano',
			'Montignoso',
			'Montenero di Bisaccia',
			'Mondolfo',
			'Mompeo',
			'Molinella',
			'Moggio Udinese',
			'Mirabello Monferrato',
			'Migliarina',
			'Miano',
			'Mezzogoro',
			'Mesagne',
			'Megliadino San Vitale',
			'Mattarello',
			'Massignano',
			'Martiniana Po',
			'Marina di Massa',
			'Marciana',
			'Maranola-Trivio',
			'Manziana',
			'Macere',
			'Lurago Marinone',
			'Luogosano',
			'Longiano',
			'Lonate Ceppino',
			'Limone Piemonte',
			'Lillianes',
			'Lestizza',
			'Lentate sul Seveso',
			'Latisana',
			'Ittiri',
			'Ischia Porto',
			'Jesi',
			'Gussago',
			'Grontardo',
			'Grisignano di Zocco',
			'Gravina in Puglia',
			'Graffignano',
			'Gorzegno',
			'Giuliano Teatino',
			'Frugarolo',
			'Frasso Telesino',
			'Fragneto Monforte',
			'Fornovo San Giovanni',
			'Formigliana',
			'Monticelli-Fontana',
			'Foglianise',
			'Filacciano',
			'Fermo',
			'Felegara',
			'Ceresole Reale',
			'Castelletto Uzzone',
			'Castellazzo Novarese',
			'Casteggio',
			'Caselle Torinese',
			'Casaluce',
			'Carunchio',
			'Carpiano',
			'Carpesica',
			'Capraia Isola',
			'Capodimonte',
			'Vazzano',
			'Ulassai',
			'Turri',
			'Tertenia',
			'Terralba',
			'Simaxis',
			'Siddi',
			'Sferracavallo',
			'San Vincenzo la Costa',
			'Santa Severina',
			'Santa Lucia del Mela',
			"Sant'Alessio Siculo",
			'San Procopio',
			'San Nicola da Crissa',
			'San Fratello',
			'San Calogero',
			'Samugheo',
			'Rotonda',
			'Ramacca',
			'Placanica',
			'Pimentel',
			'Petralia Soprana',
			'Papasidero',
			'Pagliara',
			'Orsomarso',
			'Melito di Porto Salvo',
			'Martone',
			'Marina di Gioiosa Ionica',
			'Lunamatrona',
			'Lascari',
			'Grotte',
			'Gonnoscodina',
			'Ficarra',
			'Felline',
			'Falconara Albanese',
			'Donorì',
			'Domanico',
			'Corigliano Calabro',
			'Collesano',
			'Cleto',
			'Catenanuova',
			'Casteldaccia',
			'Caltabellotta',
			'Calanna',
			'Burgio',
			'Bompensiere',
			'Barrafranca',
			'Bagnara Calabra',
			'Arbatax',
			'Altavilla Milicia',
			'Vittuone',
			'Viterbo',
			'Visano',
			'Vinzaglio',
			'Villanterio',
			'Villanova',
			'Villa Lagarina',
			'Villa del Bosco',
			'Viggiù',
			'Vernante',
			'Vergiate',
			'Vaiano',
			'Urbisaglia',
			'Tuscania',
			'Trevignano Romano',
			'Treppo Grande',
			'Trentinara',
			'Trasacco',
			'Tramonti',
			'Torre Annunziata',
			'Taglio di Po',
			'Surbo',
			'Stenico',
			'Stazzano',
			'Spongano',
			'Sparone',
			'Somma Vesuviana',
			'Sommacampagna',
			'Sirolo',
			'Silanus',
			'Settimo',
			'Sestri Levante',
			'Sessano del Molise',
			'Serravalle Pistoiese',
			'Serra San Quirico',
			"Serra de' Conti",
			'Serina',
			'Schiavon',
			'Sarnano',
			'San Vittore del Lazio',
			'Santo Stefano di Cadore',
			'Santa Maria la Fossa',
			"Sant'Agnello",
			'San Severino Marche',
			"San Michele All'Adige",
			'San Mauro Cilento',
			'San Marzano di San Giuseppe',
			'San Lupo',
			'San Giuliano Vecchio',
			'San Giovanni in Croce',
			'San Carlo',
			'San Bonifacio',
			'San Bassano',
			'Sanarica',
			'Samarate',
			'Salvirola',
			'Salvatronda',
			'Rossiglione',
			'Scalo Romagnano al Monte',
			'Rofrano',
			'Roccarainola',
			'Robassomero',
			'Ripa Teatina',
			'Riofreddo',
			'Riccia',
			'Radicondoli',
			'Propata',
			'Proceno',
			'Pravisdomini',
			'Pralungo',
			'Poviglio',
			'Portomaggiore',
			'Portalbera',
			'Ponte Taro',
			'Pontelatone',
			'Pontecchio Polesine',
			'Ponte A Tressa',
			'Pogliano Milanese',
			'Poggio Moiano',
			'Poggio Catino',
			'Piovene Rocchette',
			'Piove di Sacco-Piovega',
			'Pinerolo',
			'Pietranico',
			'Piedicavallo',
			'Piadena',
			'Pezze di Greco',
			'Petritoli',
			'Perosa Argentina',
			'Paularo',
			'Pastrengo',
			'Pantalla',
			'Panicale',
			'Palazzolo Vercellese',
			"Paderno d'Adda",
			'Ospitale di Cadore',
			'Osilo',
			'Orzivecchi',
			'Orta Nova',
			'Oliveto Lucano',
			'Oleggio',
			'Offlaga',
			'Nughedu Santa Vittoria',
			'Nazzano',
			'Nasino-Borgo',
			'Morrone del Sannio',
			'Montresta',
			'Monterotondo Marittimo',
			'Montecchio Emilia',
			'Montecavolo',
			'Mercato Vecchio',
			'Massa Lombarda',
			'Massafra',
			'Urbe',
			'Marmirolo',
			'Mariotto',
			'Marciana Marina',
			'Maniago',
			'Luson - Luesen',
			'Luserna',
			'Luserna',
			'Lucrezia',
			'Locate di Triulzi',
			'Liveri',
			'Limiti di Greccio',
			'Lesa',
			'Le Grazie',
			'Lanzo Torinese',
			'Lamporecchio',
			'Lainate',
			'Laces',
			'Cappelletta',
			'Labico',
			'Isola della Scala',
			'Pallanza-Intra-Suna',
			'Ingria',
			'Guardialfiera',
			'Grigno',
			'Gricignano di Aversa',
			'Griante',
			'Gravellona Toce',
			'Gorreto',
			'Goriano Sicoli',
			'Gazoldo degli Ippoliti',
			'Gambasca',
			'Galzignano',
			'Gaggio',
			'Fregona',
			'Fontanile',
			'Fontanelle',
			'Foiano della Chiana',
			'Finale Ligure',
			'Filetto',
			'Feletto',
			"Farra d'Alpago",
			'Fagnano Olona-Bergoro',
			'Erula',
			'Dogna',
			'Dogliani',
			'Dobbiaco',
			'Dizzasco-Biazzeno',
			'Dello',
			'Cupramontana',
			'Crocetta del Montello',
			'Crespellano',
			'Cremona',
			'Cortiglione',
			'Cortemaggiore',
			'Cornaredo',
			'Corfinio',
			'Corbanese',
			'Contrada',
			'Compiano',
			'Comeana',
			'Colli del Tronto',
			'Collepietro',
			'Colle Isarco',
			'Cogoleto',
			'Codognè',
			'Coccaglio',
			'Civitella Roveto',
			'Cittadella',
			'Cisternino',
			'Chitignano',
			'Cermignano',
			'Cerfignano',
			'Cerete Alto',
			'Cercemaggiore',
			'Cellino Attanasio',
			'Cavolano-Schiavoi',
			'Castelsardo',
			'Castelpizzuto',
			'Castelnuovo di Val di Cecina',
			'Castellanza',
			'Castagnito',
			'Cassano Spinola',
			'Casnigo',
			'Casanova',
			'Casamassima',
			'Casamassella',
			'Casale Corte Cerro',
			'Carona',
			'Carnate',
			'Cardito',
			'Cappella Maggiore',
			'Capergnanica',
			'Calvatone',
			'Calliano',
			'Calascio',
			'Busano',
			'Bressana',
			'Breme',
			'Breganze',
			'Bornasco',
			'Borgo San Martino',
			'Borgoratto Mormorolo',
			'Borgoratto Alessandrino',
			'Borgo Pace',
			'Bolano',
			'Bitritto',
			'Bella',
			'Baveno',
			'Basciano',
			'Baschi',
			'Barra',
			'Baronissi',
			'Balzo',
			'Baldissero Torinese',
			'Bagnolo Piemonte',
			'Bagnolo Mella',
			'Bagnacavallo',
			'Atrani',
			'Assisi',
			'Artogne',
			'Archi',
			'Arcene',
			'Apecchio',
			"Albaredo d'Adige",
			'Airola',
			'Aggius',
			'Acquaviva Picena',
			'Fossò',
			'Peseggia-Gardigiano',
			'Stigliano',
			'Reana del Roiale',
			'Pagnacco',
			'Albisola Superiore',
			'Cantarana',
			'Traves',
			'Monastero Bormida',
			'Cosseria',
			'Mallare',
			'Moconesi',
			'Parè',
			'Peglio',
			'Locatello',
			'Valleve',
			'Mornico Losana',
			'Fierozzo',
			'Reana del Rojale',
			'Sover',
			'Roverè Veronese',
			'Villagrande',
			'Rhemes-Saint-Georges',
			'Vallio Terme',
			'Saint-Christophe',
			'Balsorano',
			'Fondachelli-Fantina',
			'Camandona',
			'Vallanzengo',
			'Monticello Brianza',
			'Paroldo',
			'Polonghera',
			'Antignano',
			'Chignolo Po',
			'Mussolente',
			'Rocca Grimalda',
			'Villaromagnano',
			'Allein',
			'Cazzago Brabbia',
			'Aicurzio',
			'Burago di Molgora',
			'Corzano',
			'Ribordone',
			'Amato',
			'Piverone',
			'Rorà',
			'Villanova Canavese',
			'Nibbiola',
			'Pezzolo Valle Uzzone',
			"Revigliasco d'Asti",
			'Mezzane di Sotto',
			'Poggio San Marcello',
			'Villa di Briano',
			'Falciano del Massico',
			'Calvi',
			'Castro',
			'Budoni',
			'Ceppo Morelli',
			'Cusago',
			'Bracca',
			'Ono San Pietro',
			"Corneliano d'Alba",
			'Giorgilorio',
			'Musestre',
			'Roata Rossi',
			'Castrocaro Terme e Terra del Sole',
			'Cropani Marina',
			'Casano-Dogana-Isola',
			'Roè',
			'Cava-Cuculera Nobile',
			'Trecella',
			'Settimo',
			'Romito Magra',
			'Scannabue-Cascine Capri',
			'Millepini',
			'Pasta',
			'San Cipriano-S.Barbara-Centinale',
			'Macine-Borgo Loreto',
			'Valenzatico',
			'Savonera',
			'Castello',
			'San Biagio',
			'Laura',
			'Saletto',
			'Pratissolo',
			'Torre Colonna-Sperone',
			'Prato di Coppola',
			'Zona Industriale',
			'Cerello-Battuello',
			'Novagli',
			'Montelarco',
			'Solara',
			'Gliaca',
			'Marocchi',
			'San Lorenzo',
			'Tavo',
			'San Maurizio',
			'Gallo',
			'Servo',
			'Progetto Case Coppito 3',
			'Piano di Mommio',
			'Due Carrare',
			'Casal Bertone',
			'Dusino',
			'Dorio',
			'Dairago',
			'Cumiana',
			'Cortaccia sulla Strada del Vino',
			'Corsico',
			'Concerviano',
			'Conca della Campania',
			'Collelongo',
			'Coldragone',
			'Colbordolo',
			'Codroipo',
			'Civitella Alfedena',
			"Civita d'Antino",
			'Cittiglio',
			'Ciriè',
			'Circello',
			'Cibiana',
			'Chiuro',
			'Coccanile-Cesta',
			'Cerreto Sannita',
			'Cermenate',
			'Ceresara',
			'Ceparana-Carpena',
			'Castronno',
			'Castione della Presolana',
			'Castiglione in Teverina',
			'Castello Molina di Fiemme',
			'Castellina Marittima',
			'Castelfranco di Sopra',
			'Casola di Napoli',
			'Casanova Elvo',
			'Casale di Scodosia',
			'Casalbordino-Miracoli',
			'Carpineto della Nora',
			'Carpenedolo',
			'Carpegna',
			'Carisio',
			'Carimate',
			'Candiana',
			'Campo Tizzoro',
			'Camposano',
			'Campolieto',
			'Campoleone',
			'Camerata Nuova',
			'Caltana',
			'Bussolengo',
			'Buscoldo',
			'Buronzo',
			'Brindisi Montagna',
			'Brendola',
			'Bovisio-Masciago',
			'Borghetto Santo Spirito',
			'Bolognano',
			'Birori',
			'Gabbioneta',
			'Besnate',
			'Bellano',
			'Bastia umbra',
			'Azzanello',
			'Aymavilles',
			'Atella',
			'Arzene',
			'Artegna',
			'Ariano',
			'Arco',
			'Anzi',
			'Anguillara Veneta',
			'Alfano',
			'Albenga',
			'Acciano',
			'San Mauro a Mare',
			'Lido di Pomposa-Lido degli Scacchi',
			'Capriva del Friuli',
			'Meduna di Livenza',
			'Colle Umberto',
			'Ciano',
			'Casacorba',
			'Bocco',
			'San Bartolomeo al Mare',
			'Orta di Atella',
			'Priero',
			'Castellero',
			'Piovà Massaia',
			'San Giorgio Monferrato',
			'Solbiate',
			'Cino',
			'Casasco Intelvi',
			'Bellusco',
			'Agnosine',
			'Alfianello',
			'Losine',
			'Torricella Verzate',
			'Grancona',
			'Castel di Lama',
			'Onzo',
			'Vendone',
			'Remedello',
			'Valvestino',
			'Valle Aurina - Ahrntal',
			'Imposte',
			'Brovello-Carpugnino',
			'Montacuto',
			'Fiorano al Serio',
			'Montecreto',
			'Maltignano',
			'Coreno Ausonio',
			'Levate',
			'Vistarino',
			'Figline Vegliaturo',
			'Savoca',
			'Cassago Brianza',
			'Chianocco',
			'Lugnacco',
			'Maggiora',
			'Pisano',
			'Clavesana',
			'Igliano',
			'Cavatore',
			'Prasco',
			'San Lorenzo in Banale',
			'Rocca Pietore',
			'Monteleone di Fermo',
			'Campodimele',
			'Felitto',
			'San Rocco al Porto',
			'Premia',
			'Bene Lario',
			'Ospedaletto',
			'Vambolieri',
			'Caleppio',
			'Novegro-Tregarezzo-San Felice',
			'Pianello Vallesina',
			'Trodica',
			'Miola di Pinè',
			'Arcavacata',
			'Corsanico-Bargecchia',
			'Piazza Roma',
			'Fossignano',
			'Campo Limpido-Favale',
			'Levata',
			'Colombella',
			'Cerza',
			'Osigo',
			'Casarea',
			'Maugeri',
			'Villa Raverio',
			'Alpo',
			'Santa Monica-Cella',
			'Villa San Giuseppe',
			'Materdomini',
			'Borghetto-Melara',
			'Villair-Amerique',
			'Tabiago-Cibrone',
			'Baraggia',
			'Moncucco',
			'Cunettone-Villa',
			'Riccò',
			'Squillace Lido',
			'Tombelle',
			'Borgo Fornari-Pieve',
			'Malche-Santa Croce-Serroni',
			'Cendon',
			'Torrazza dei Mandelli',
			'Orzignano',
			'Pianello',
			'Paquier',
			'Chiesa',
			'Sottocastello',
			'Capriglia',
			'Evangelisti-Rubino',
			'Rossi',
			'Moscheri',
			'Lemna',
			'Valentino',
			'Baruchella',
			'Pozzaglio',
			'Orte Scalo',
			'Ustica',
			'Torre Melissa',
			'Strongoli',
			'Soveria Simeri',
			'Saponara',
			"Santa Sofia d'Epiro",
			'Santa Marina Salina',
			"Sant'Agata di Esaro",
			'San Giovanni la Punta',
			'Rogliano',
			'Roccavaldina',
			'Regalbuto',
			'Praia a Mare',
			'Portella di Mare',
			'Monterosso Almo',
			'Mirto',
			'Miglierina',
			"Mazzarrà Sant'Andrea",
			'Maratea',
			'Jerzu',
			'Giarre',
			'Fluminimaggiore',
			'Filogaso',
			'Enna',
			'Donnici Inferiore',
			'Cefalù',
			'Castiglione',
			'Castiglione di Sicilia',
			'Castelvetrano',
			'Casteltermini',
			'Castelluccio Inferiore',
			'Castel di Lucio',
			'Casole Bruzio',
			'Carlopoli',
			'Buggerru',
			'Borgia',
			'Bisignano',
			'Amato',
			'Zelarino',
			'Voltido',
			'Volta Mantovana',
			'Vione',
			'Villanuova sul Clisi',
			'Villammare',
			'Villamarzana',
			'Villa Cortese',
			'Villabruna-Umin',
			'Vernasca',
			'Vallepietra',
			'Vallà',
			'Valfenera',
			'Uggiano Montefusco',
			'Trescore Balneario',
			'Trescine',
			'Tremenico',
			'Tortoreto',
			'Torella dei Lombardi',
			'Tenno',
			'Taibon Agordino',
			'Susegana',
			'Spirano',
			'Sorrento',
			'Sorgono',
			'Sistiana-Visogliano',
			'Sezze',
			'Seriate',
			'Selva di Cadore',
			'Sedini',
			'Seclì',
			'Savoia di Lucania',
			'Sarmede',
			'Santomenna',
			'San Teodoro',
			'San Tammaro',
			"Sant'Ambrogio di Valpollicella",
			'Santa Maria Imbaro',
			'Santa Maria Codifiume',
			'Santa Brigida',
			'San Pancrazio',
			'San Martino in Rio',
			'San Marco la Catola',
			'San Lazzaro',
			'Sanfrè',
			'San Ferdinando di Puglia',
			'San Cipriano Picentino',
			'San Buono',
			'Sampeyre',
			'Salò',
			'Salandra',
			'Salbertrand',
			'Rovasenda',
			'Roiate',
			'Roccasicura',
			'Rocca San Casciano',
			'Rivalba',
			'Recanati',
			'Quinto Vercellese',
			'Quinto di Treviso',
			'Putifigari',
			'Premariacco',
			'Pozzonovo',
			'Pozzaglia Sabino',
			'Porto Santo Stefano',
			'Porto Recanati',
			'Ponti',
			'Ponte',
			'Pomezia',
			'Pizzoli',
			'Piuro',
			'Pitigliano',
			'Piossasco',
			'Pinzano al Tagliamento',
			"Pieve d'Alpago",
			'Piamborno',
			'Peschici',
			'Pesche',
			'Pennapiedimonte',
			'Pecetto di Valenza',
			'Pattada',
			'Parre',
			'Paganico Sabino',
			'Ozieri',
			'Ottobiano',
			'Ospedaletto',
			'Osmate',
			'Orvieto',
			'Olevano Romano',
			'Muro Lucano',
			'Muccia',
			'Morozzo',
			'Moricone',
			'Moriago della Battaglia',
			'Montottone',
			'Monte Vidon Corrado',
			'Monte San Giusto',
			'Monteroni di Lecce',
			'Monteodorisio',
			'Monte Marenzo',
			'Montecilfone',
			'Montecarotto',
			'Monchiero Borgonuovo',
			'Mombaldone',
			'Mola di Bari',
			'Moio della Civitella-Pellare',
			'Modigliana',
			'Mezzano',
			'Meta',
			'Marcianise',
			'Lodi',
			'Linate',
			'Lignano Sabbiadoro',
			'Lauria',
			'Lauregno',
			'La Thuile',
			'Lana',
			'Gratacasolo',
			"Gradisca d'Isonzo",
			'Gossolengo',
			'Gissi',
			'Gioia Sannitica',
			'Gattinara',
			'Garadassi',
			'Gaiole in Chianti',
			'Fucecchio',
			'Frosolone',
			'Fossalta di Portogruaro',
			'Formigara',
			'Forio',
			'Fino Mornasco',
			'Fiesso Umbertiano',
			'Fenestrelle',
			'Favria',
			'Fauglia',
			'Fanano',
			'Fai della Paganella',
		],
	},
	Jamaica: {
		country: 'Jamaica',
		cities: [
			'Oracabessa',
			'New Kingston',
			'Half Way Tree',
			'Albert Town',
			'Stony Hill',
			'Savanna-la-Mar',
			'Maroon Town',
			'Sandy Bay',
			'Limit',
			'Williamsfield',
			'Morant Bay',
			'Easington',
			'Richmond',
			'Moneague',
			'Malvern',
			'Hayes',
			'Quickstep',
			'Siloah',
			'Kellits',
			'Golden Grove',
			'Ewarton',
			'Wakefield',
			'Trinity Ville',
			'Mona Heights',
			'Mavis Bank',
			'Lluidas Vale',
			'Bull Savanna',
			'Mocho',
			'Buff Bay',
			'Southfield',
			'Seaforth',
			'Duncans',
			'Bamboo',
			'Annotto Bay',
			'Linstead',
			'Montego Bay',
			'Lionel Town',
			'Petersfield',
			'Dalvey',
			'Ulster Spring',
			'Spanish Town',
			'Discovery Bay',
			'Bath',
			'Alligator Pond',
			'Rock Spring',
			'Darliston',
			'Constant Spring',
			'Runaway Bay',
			'Old Harbour',
			'Lucea',
			'Kingston',
			'Islington',
			'Balaclava',
			'Rocky Point',
			'Riversdale',
			'Lacovia',
			'Chapelton',
			'Cascade',
			'Bluefields',
			'Race Course',
			'Portmore',
			'Old Harbour Bay',
			'Manchioneal',
			'Anchovy',
			'Santa Cruz',
			'Moore Town',
			'Hope Bay',
			'Gordon Town',
			'Coleyville',
			'Bog Walk',
			'Alexandria',
			'Yallahs',
			'Nain',
			'Falmouth',
			'Porus',
			'Point Hill',
			'Negril',
			'Gayle',
			'Port Maria',
			'May Pen',
			'Maggotty',
			'Black River',
			'Bethel Town',
			'Saint Ann’s Bay',
			'Port Antonio',
			'Port Royal',
			'Mt Peto',
			'Ocho Rios',
			'Mandeville',
			'Cambridge',
		],
	},
	Japan: {
		country: 'Japan',
		cities: [
			'Yaizu',
			'Tsuruga',
			'Toyokawa',
			'Soeda',
			'Shiojiri',
			'Shiida',
			'Ōmura',
			'Ojiya',
			'Nonoichi',
			'Maebaru-chūō',
			'Machida',
			'Komono',
			'Kōfu',
			'Kaseda-shirakame',
			'Kannabechō-yahiro',
			'Iwatsuki',
			'Inazawa',
			'Ijūin',
			'Ichinomiya',
			'Hamada',
			'Chiryū',
			'Chino',
			'Arai',
			'Shinjō',
			'Kitaibaraki',
			'Funehikimachi-funehiki',
			'Shiranuka',
			'Chippubetsu',
			'Asahikawa',
			'Abira',
			'Musashimurayama',
			'Iruma',
			'Yachiyo',
			'Susono',
			'Omaezaki',
			'Kanmaki',
			'Ichikawa-minami',
			'Ukiha',
			'Yasu',
			'Kodaira',
			'Nankoku',
			'Imari',
			'Chūō',
			'Yoshino-chō',
			'Yonabaru',
			'Ueda',
			'Tsushima',
			'Tatsuno',
			'Tamano',
			'Takedamachi',
			'Takamori',
			'Tahara',
			'Sukumo',
			'Shitara',
			'Ono',
			'Nago',
			'Kurio',
			'Kariya',
			'Kaizuka',
			'Kagoshima',
			'Kadena',
			'Isahaya',
			'Hino',
			'Hamamatsu',
			'Arita',
			'Yanagawamachi-saiwaichō',
			'Murata',
			'Koori',
			'Kawasaki',
			'Iwaizumi',
			'Funagata',
			'Otofuke',
			'Ikeda',
			'Suzu',
			'Oga',
			'Kawachi-Nagano',
			'Kirishima',
			'Ozora',
			'Eniwa',
			'Minami-Alps',
			'Arao',
			'Takasago',
			'Ogōri',
			'Katano',
			'Tome',
			'Kamagaya',
			'Bizen',
			'Higashiōmi',
			'Tosa',
			'Shūnan',
			'Yuza',
			'Yoshii',
			'Yaotsu',
			'Tsurusaki',
			'Tochio-honchō',
			'Tanashichō',
			'Takasaki',
			'Ōita',
			'Niimi',
			'Nagasu',
			'Maruoka',
			'Kasagi',
			'Itoman',
			'Ina',
			'Hekinan',
			'Hayama',
			'Hakone',
			'Ginowan',
			'Gifu-shi',
			'Fukiage-fujimi',
			'Ie',
			'Kushima',
			'Hikari',
			'Sagae',
			'Ōmagari',
			'Kogota',
			'Akita',
			'Uryū',
			'Toyokoro',
			'Shosanbetsu',
			'Otaru',
			'Date',
			'Tamura',
			'Minamiise',
			'Kakogawa',
			'Etajima',
			'Kazuno',
			'Yuasa',
			'Yawata',
			'Uken',
			'Suita',
			'Shiki',
			'Ogano',
			'Nishio',
			'Nakano',
			'Musashino',
			'Nagiso',
			'Kiyosu',
			'Kashihara',
			'Kamogatachō-kamogata',
			'Kameyama',
			'Kajiki',
			'Izumi',
			'Ishiki',
			'Imaichi',
			'Ibara',
			'Hondomachi-hondo',
			'Hiji',
			'Hakui',
			'Fukumitsu',
			'Ama',
			'Hasuda',
			'Ōzu',
			'Fukaura',
			'Motegi',
			'Moriya',
			'Mashiko',
			'Ishioka',
			'Tōbetsu',
			'Shinshimokawa',
			'Kuzumaki',
			'Hiranai',
			'Hitachiomiya',
			'Kita-Akita',
			'Kani',
			'Yonedacho Sendo',
			'Tosashimizu',
			'Mitoyo',
			'Aira',
			'Nishitōkyō',
			'Minamibōsō',
			'Mizuho',
			'Kami',
			'Atsugi',
			'Akashi',
			'Yatsuomachi-higashikumisaka',
			'Yasugichō',
			'Yao',
			'Ureshinomachi-shimojuku',
			'Toyoshina',
			'Tamana',
			'Setakamachi-takayanagi',
			'Sakawa',
			'Sakai',
			'Ōbu',
			'Obita',
			'Miyajima',
			'Miyata',
			'Mitsuke',
			'Mibu',
			'Maizuru',
			'Maibara',
			'Kitsuki',
			'Kitakyushu',
			'Kisai',
			'Kanie',
			'Ikedachō',
			'Hōryūji',
			'Gōtsuchō',
			'Fukuchiyama',
			'Yabuki',
			'Narutō',
			'Mizusawa',
			'Karasuyama',
			'Hitachi',
			'Chōnan',
			'Tomari',
			'Takinoue',
			'Haboro',
			'Takashima',
			'Nanto',
			'Higashi-Matsuyama',
			'Ninohe',
			'Nagaoka',
			'Miyota',
			'Kuwana',
			'Matsusaka',
			'Fujimino',
			'Kunitachi',
			'Shimanto',
			'Awaji',
			'Miyawaka',
			'Minamishimabara',
			'Hakusan',
			'Urayasu',
			'Tennō',
			'Tatsugō',
			'Sōja',
			'Shirahama',
			'Shibukawa',
			'Ōtake',
			'Wakasa',
			'Ube',
			'Tonoshō',
			'Suzaka',
			'Shimabara',
			'Sasebo',
			'Saga',
			'Omi',
			'Obama',
			'Nishinoomote',
			'Niigata',
			'Nakanojōmachi',
			'Nakama',
			'Morohongō',
			'Mino',
			'Kukichūō',
			'Kōnosu',
			'Kitahama',
			'Kitagata',
			'Kawara',
			'Kamigōri',
			'Izumi',
			'Itoigawa',
			'Itō',
			'Hadano',
			'Gotenba',
			'Gosen',
			'Futtsu',
			'Aki',
			'Shimotoda',
			'Hōjō',
			'Onagawa Chō',
			'Kesennuma',
			'Katsuura',
			'Yūbari',
			'Tomamae',
			'Takikawa',
			'Ōma',
			'Oketo',
			'Niikappu',
			'Mikasa',
			'Hirosaki',
			'Chitose',
			'Ikeda',
			'Chikusei',
			'Sakuragawa',
			'Kashiba',
			'Akishima',
			'Kunigami',
			'Towada',
			'Uonuma',
			'Niseko Town',
			'Kihoku',
			'Toda',
			'Kizugawa',
			'Nakatsu',
			'Miyoshi',
			'Kamiamakusa',
			'Youkaichi',
			'Takefu',
			'Tagawa',
			'Tachiarai',
			'Shiozawa',
			'Shimoichi',
			'Shido',
			'Ōzu',
			'Onomichi',
			'Niitsu-honchō',
			'Manazuru',
			'Kyoto',
			'Isehara',
			'Ise',
			'Ikoma',
			'Anamizu',
			'Wakayama',
			'Ōtaki',
			'Mobara',
			'Hobaramachi',
			'Shizunai-furukawachō',
			'Honchō',
			'Pippu',
			'Honchō',
			'Asahimachi',
			'Kamikawa',
			'Horokanai',
			'Hiroo',
			'Kanaura',
			'Iheya',
			'Sōsa',
			'Nasukarasuyama',
			'Isumi',
			'Chita',
			'Nagawa',
			'Taketa',
			'Kan’onji',
			'Akaiwa',
			'Yoshinogawa',
			'Koganei',
			'Daitōchō',
			'Yanagawa',
			'Yamaga',
			'Toyonaka',
			'Tara',
			'Tanuma',
			'Saiki',
			'Ōmuta',
			'Nishinomiya-hama',
			'Miyoshi',
			'Mifune',
			'Maebashi',
			'Katsuyama',
			'Kasamatsuchō',
			'Kanekomachi',
			'Kamiichi',
			'Kamakura',
			'Ikeda',
			'Hiratachō',
			'Hayashima',
			'Hatsukaichi',
			'Kamifukuoka',
			'Yokote',
			'Tendō',
			'Miyako',
			'Ichihara',
			'Asakawa',
			'Wassamu',
			'Teshikaga',
			'Bifuka',
			'Koshimizu',
			'Hachinohe',
			'Fukushima',
			'Fujisaki',
			'Aomori',
			'Kasumigaura',
			'Murayama',
			'Matsuura',
			'Tarama',
			'Daisen',
			'Inba',
			'Fujikawaguchiko',
			'Katashina',
			'Himi',
			'Taku',
			'Ōshima',
			'Ogasawara',
			'Miyoshi',
			'Minamiawaji',
			'Minamikyushu',
			'Ōsaka-sayama',
			'Kanzaki',
			'Kisarazu',
			'Kashiwa',
			'Kameda-honchō',
			'Ishii',
			'Ashiya',
			'Takahagi',
			'Shiroishi',
			'Ōtawara',
			'Okunoya',
			'Narita',
			'Furukawa',
			'Yokohama',
			'Wakkanai',
			'Tsukigata',
			'Takanosu',
			'Honmachi',
			'Noshiro',
			'Noda',
			'Namioka',
			'Makubetsu',
			'Kamiiso',
			'Iwanai',
			'Biei',
			'Asahi',
			'Hidaka',
			'Ena',
			'Jōetsu',
			'Yawatahama',
			'Sue',
			'Aka',
			'Motoyoyogichō',
			'Matsubushi',
			'Motobu',
			'Katō',
			'Yatomi',
			'Sakura',
			'Shirakawa',
			'Shibetsu',
			'Saikai',
			'Unzen',
			'Komae',
			'Ichikikushikino',
			'Yukuhashi',
			'Yokkaichi',
			'Yamazakichō-nakabirose',
			'Yamakita',
			'Tokuyama',
			'Tenri',
			'Sueyoshichō-ninokata',
			'Satte',
			'Sakata',
			'Moriguchi',
			'Miyazu',
			'Mishima',
			'Masuda',
			'Kodamachō-kodamaminami',
			'Zentsujichó',
			'Tsu',
			'Tomioka',
			'Tanushimarumachi-toyoki',
			'Tano',
			'Tamamura',
			'Takanabe',
			'Suwa',
			'Osaka',
			'Ōno-hara',
			'Niihama',
			'Menuma',
			'Matsudo',
			'Kudamatsu',
			'Kanazawa',
			'Itami',
			'Honchō',
			'Chōfu',
			'Saijō',
			'Sukagawa',
			'Kuroiso',
			'Kōriyama',
			'Chiba',
			'Toyotomi',
			'Shibetsu',
			'Sapporo',
			'Ōmu',
			'Ōdate',
			'Niki',
			'Kōka',
			'Miyakojima',
			'Shimotsuke',
			'Higashimatsushima',
			'Kitanagoya',
			'Hashima',
			'Motosu',
			'Tagami',
			'Shikokuchūō',
			'Ōamishirasato',
			'Tanba',
			'Tōkai',
			'Inagi',
			'Sanyōonoda',
			'Hita',
			'Hirakata',
			'Himimachi',
			'Hannō',
			'Fujioka',
			'Anan',
			'Shizukuishi',
			'Rifu',
			'Higashine',
			'Gojōnome',
			'Muroran',
			'Ashoro',
			'Kurobe-shi',
			'Kasuga',
			'Jōsō',
			'Kinokawa',
			'Nikaho',
			'Kamigyō-ku',
			'Fujimi',
			'Nakano',
			'Kamikawa',
			'Hyūga',
			'Jōyō',
			'Usa',
			'Itoshima',
			'Ebina',
			'Yūki',
			'Yaita',
			'Tsuyama',
			'Tsuruoka',
			'Toki',
			'Shima',
			'Oyama',
			'Ōta',
			'Okazaki',
			'Okayama',
			'Okaya',
			'Numata',
			'Nanao',
			'Nagareyama',
			'Mikuni',
			'Kamimaruko',
			'Kokubunji',
			'Kobayashi',
			'Kishiwada',
			'Kiryū',
			'Kanzakimachi-kanzaki',
			'Shingū',
			'Yonakuni',
			'Yokosuka',
			'Yanai',
			'Tsubata',
			'Toyohashi',
			'Tosu',
			'Tomigusuku',
			'Tatebayashi',
			'Bungo-Takada-shi',
			'Sobue',
			'Shin’ichi',
			'Shingū',
			'Sasayama',
			'Ōyama',
			'Muramatsu',
			'Miyakonojō',
			'Minamirinkan',
			'Matsutō',
			'Matsue',
			'Kushimoto',
			'Kobe',
			'Kanoya',
			'Kadoma',
			'Ishige',
			'Iijima',
			'Iida',
			'Higashi-ōsaka',
			'Hagi',
			'Chigasaki',
			'Amagi',
			'Sayama',
			'Asaka',
			'Yamoto',
			'Tanagura',
			'Takahata',
			'Ryūgasaki',
			'Natori-shi',
			'Katori-shi',
			'Motomachi',
			'Teshio',
			'Shimo-furano',
			'Shikabe',
			'Minamishibetsuchō',
			'Misawa',
			'Ajigasawa',
			'Chikuma',
			'Tainai',
			'Furudate',
			'Tsushima',
			'Arida',
			'Narashino',
			'Echizen',
			'Yamagata',
			'Uki',
			'Izumisano',
			'Koshigaya',
			'Innoshima',
			'Hirado',
			'Hino',
			'Hanyū',
			'Asuka',
			'Imabari-shi',
			'Yotsukaidō',
			'Yamada',
			'Shisui',
			'Ōmiya',
			'Itako',
			'Hokota',
			'Choshi',
			'Utashinai',
			'Sōbetsu',
			'Shintoku',
			'Biratori',
			'Ashibetsu',
			'Hamatonbetsu',
			'Inabe',
			'Nasushiobara',
			'Yurihonjō',
			'Aisai',
			'Yamanashi',
			'Saito',
			'Nasushiobara',
			'Mima',
			'Tajiri',
			'Uji',
			'Tokushima',
			'Tanabe',
			'Shimonoseki',
			'Shimada',
			'Sakaidechō',
			'Sakai-nakajima',
			'Sakai',
			'Onoda',
			'Ono',
			'Odawara',
			'Noda',
			'Nakatsugawa',
			'Nagoya',
			'Kozakai-chō',
			'Yunomae',
			'Yashio',
			'Takehara',
			'Shirone',
			'Minato',
			'Ōtsu',
			'Ogawa',
			'Ninomiya',
			'Nakamura',
			'Mizunami',
			'Miyazaki',
			'Mitake',
			'Kusatsu',
			'Kurihashi',
			'Kōnan',
			'Komaki',
			'Kashima',
			'Honjō',
			'Nishifukuma',
			'Fukayachō',
			'Chichibu',
			'Annaka',
			'Kimitsu',
			'Tomobe',
			'Shiroi',
			'Omigawa',
			'Ōfunato',
			'Kitakami',
			'Iwanuma',
			'Iwaki',
			'Ami',
			'Urakawa',
			'Honchō',
			'Itayanagi',
			'Mashike',
			'Bandō',
			'Inashiki',
			'Tama',
			'Kaizu',
			'Shijōnawate',
			'Tsuruta',
			'Minamisatsuma',
			'Muroto',
			'Gotō',
			'Konan',
			'Kunisaki',
			'Yudomari',
			'Usuki',
			'Tsuiki',
			'Tsubame',
			'Tawaramoto',
			'Takarazuka',
			'Sumoto',
			'Naze',
			'Nakatsu',
			'Nagano',
			'Koga',
			'Kawasaki',
			'Kawamoto',
			'Kashiwazaki',
			'Iwakura',
			'Ishikawa',
			'Ikeda',
			'Heda',
			'Haibara-akanedai',
			'Ōmihachiman',
			'Gushikawa',
			'Gojō',
			'Gobō',
			'Ageoshimo',
			'Ōsaki',
			'Kanegasaki',
			'Kamaishi',
			'Iwase',
			'Sunagawa',
			'Rikubetsu',
			'Rankoshimachi',
			'Okoppe',
			'Numata',
			'Nemuro',
			'Kuji',
			'Kimobetsu',
			'Ishikari',
			'Goshogawara',
			'Hitachi-ota',
			'Izu',
			'Katagami',
			'Fujiidera',
			'Hakuba',
			'Kofu',
			'Toyone',
			'Ogi',
			'Yasugi',
			'Unnan',
			'Ueno-ebisumachi',
			'Tsurugi-asahimachi',
			'Taketoyo',
			'Shiraoka',
			'Satsumasendai',
			'Ōmachi',
			'Numazu',
			'Nirasaki',
			'Moriyama',
			'Kawaminami',
			'Kasaoka',
			'Kan’onjichō',
			'Kameoka',
			'Kashima-shi',
			'Ishigaki',
			'Hioki',
			'Chizu',
			'Awara',
			'Ashikaga',
			'Obanazawa',
			'Motomiya',
			'Ichinoseki',
			'Funaishikawa',
			'Asahi',
			'Ōhira',
			'Furubira',
			'Sai',
			'Iwamizawa',
			'Hokuryū',
			'Kitahiroshima',
			'Aso',
			'Umeda',
			'Namegata',
			'Minamiashigara',
			'Kumano',
			'Inzai',
			'Ōsaki',
			'Minamiuonuma',
			'Higashimurayama',
			'Amami',
			'Dazaifu',
			'Kuroishi',
			'Yamato',
			'Ako',
			'Miyama',
			'Sanda',
			'Higashikagawa',
			'Kyōtango',
			'Bungo-ōno',
			'Rikuzentakata',
			'Wajima',
			'Utsunomiya',
			'Ushibukamachi',
			'Uekimachi-mōno',
			'Toyota',
			'Toyohama',
			'Tokorozawa',
			'Tajimi',
			'Sugito',
			'Shinshiro',
			'Sakurai',
			'Narutochō-mitsuishi',
			'Miyada',
			'Mino',
			'Kusatsu',
			'Kumamoto',
			'Komatsushimachō',
			'Kin',
			'Kawagoe',
			'Kariwa',
			'Izumisano',
			'Inuyama',
			'Iizuka',
			'Fujisawa',
			'China',
			'Akune',
			'Ojika',
			'Iyo',
			'Yamagata',
			'Shiogama',
			'Marumori',
			'Kemigawa',
			'Katsuta',
			'Ishinomaki',
			'Ishikawa',
			'Iizakamachi',
			'Shiraoi',
			'Obirachō',
			'Nayoro',
			'Nakashibetsu',
			'Kitami',
			'Kikonai',
			'Abashiri',
			'Kahoku',
			'Takizawa',
			'Shimonoseki',
			'Chikugo',
			'Isa',
			'Maniwa',
			'Yamato-Takada',
			'Umi',
			'Toyama',
			'Tōkamachi',
			'Takaishi',
			'Sakado',
			'Ogōri-shimogō',
			'Ōgaki',
			'Nabari',
			'Muroto-misakicho',
			'Murakami',
			'Muikamachi',
			'Mitsukaidō',
			'Matsubara',
			'Koga',
			'Karatsu',
			'Kanbara',
			'Kakogawachō-honmachi',
			'Hirara',
			'Fuchūchō',
			'Ashiya',
			'Fussa',
			'Shibayama',
			'Shimizu',
			'Otobe',
			'Obihiro',
			'Naie',
			'Yoichi',
			'Kuroishi',
			'Kaminokuni',
			'Imabetsu',
			'Aibetsu',
			'Kawage',
			'Tsukubamirai',
			'Midori',
			'Tsurugashima',
			'Tsugaru',
			'Oda',
			'Isen',
			'Taishi',
			'Fujiyoshida',
			'Hamura',
			'Funabashi',
			'Akiruno',
			'Kyōtanabe',
			'Yugawara',
			'Toba',
			'Takeo',
			'Takamatsu',
			'Suibara',
			'Shimodate',
			'Ōiso',
			'Ogose',
			'Nobeoka',
			'Nikkō',
			'Nambu',
			'Kurayoshi',
			'Kazo',
			'Kasukabe',
			'Iwata',
			'Katsuren-haebaru',
			'Anjō',
			'Yachimata',
			'Ōishida',
			'Morioka',
			'Kakunodatemachi',
			'Tomakomai',
			'Nishiokoppe',
			'Fukagawa',
			'Akkeshi',
			'Aizu-Wakamatsu',
			'Komagane',
			'Hirakawa',
			'Hokuto',
			'Miyake',
			'Fuji-yoshida',
			'Tsuru',
			'Zushi',
			'Yorii',
			'Yashiro',
			'Yamaguchi',
			'Toyooka',
			'Tatsunochō-tominaga',
			'Shōbu',
			'Shimoda',
			'Seto',
			'Sasaguri',
			'Sandachō',
			'Saku',
			'Ryōtsu-minato',
			'Ōkawa',
			'Nishiwaki',
			'Miyanoura',
			'Miki',
			'Kokubu-matsuki',
			'Kamojimachō-jōgejima',
			'Kainan',
			'Iwade',
			'Itsukaichi',
			'Hitoyoshi',
			'Fukuechō',
			'Chatan',
			'Wako',
			'Kawanoechō',
			'Watari',
			'Ushiku',
			'Tōgane',
			'Ōkawara',
			'Ōami',
			'Naka',
			'Mooka',
			'Kōzaki',
			'Hasaki',
			'Shimokizukuri',
			'Horonobe',
			'Kamisu',
			'Azumino',
			'Imizu',
			'Higashiyamato',
			'Sōma',
			'Tōmi',
			'Kikugawa',
			'Nanjō',
			'Amakusa',
			'Buzen',
			'Oshino',
			'Yufu',
			'Nomi',
			'Kōshi',
			'Agano',
			'Yuzawa',
			'Honmachi',
			'Tsunō',
			'Tsunagi',
			'Nishi-Tokyo-shi',
			'Taira',
			'Tadotsu',
			'Ryūō',
			'Onna',
			'Kure',
			'Kurashiki',
			'Kochi',
			'Kasugai',
			'Kashihara-shi',
			'Kanaya',
			'Kamo',
			'Kaminokawa',
			'Hiroshima',
			'Hashimoto',
			'Hamanoichi',
			'Daitō',
			'Beppu',
			'Miura',
			'Tako',
			'Kaneyama',
			'Inawashiro',
			'Hanamaki',
			'Fukushima',
			'Daigo',
			'Honchō',
			'Mutsu',
			'Suttsu',
			'Kushiro',
			'Furano',
			'Ebetsu',
			'Neyagawa',
			'Tsuchiura',
			'Toyoake',
			'Yamagata',
			'Saitama',
			'Ikata-chō',
			'Yomitan',
			'Munakata',
			'Ama',
			'Iki',
			'Setouchi',
			'Yoshikawa',
			'Yoshida-kasugachō',
			'Yanaizu',
			'Ujiie',
			'Tottori',
			'Tondabayashichō',
			'Takaoka',
			'Takahama',
			'Takahama',
			'Sōka',
			'Sano',
			'Sagara',
			'Ōme',
			'Ōkuchi-shinohara',
			'Ōgimi',
			'Nyūzen',
			'Nagasaki',
			'Nagaoka',
			'Naganohara',
			'Mukō',
			'Motoyama',
			'Minamata',
			'Kuroda',
			'Kawanishi',
			'Kanuma',
			'Izumo',
			'Ibusuki',
			'Hotaka',
			'Handa',
			'Fujinomiya',
			'Chikushino-shi',
			'Matsuyama',
			'Masaki-chō',
			'Tomioka',
			'Kamogawa',
			'Kakuda',
			'Date',
			'Noboribetsu',
			'Kosaka',
			'Ichinohe',
			'Kōya',
			'Niiza',
			'Kitamoto',
			'Izunokuni',
			'Habikino',
			'Hokuto',
			'Inakadate',
			'Misasa',
			'Nishiawakura',
			'Mimasaka',
			'Higashikurume',
			'Sanuki',
			'Kai',
			'Warabi',
			'Shisō',
			'Kōshū',
			'Higashiyamato',
			'Fukutsu',
			'Asakura',
			'Gero',
			'Yakage',
			'Wake',
			'Uto',
			'Tsuma',
			'Togitsu',
			'Tarui',
			'Takayama',
			'Takatsuki',
			'Susaki',
			'Shōbara',
			'Shibushi',
			'Settsu',
			'Sanjō',
			'Sakaki',
			'Nara-shi',
			'Nagata',
			'Nagahama',
			'Mihara',
			'Matsuzaki',
			'Maki',
			'Kushikino',
			'Koseda',
			'Kanda',
			'Kakamigahara',
			'Isawa',
			'Imarichō-kō',
			'Ibaraki',
			'Hiratsuka',
			'Fukuyama',
			'Fujioka',
			'Okinawa',
			'Yuzawa',
			'Yonezawa',
			'Yōkaichiba',
			'Ōtsuchi',
			'Miharu',
			'Makabe',
			'Abiko',
			'Rausu',
			'Nakatonbetsu',
			'Rittō',
			'Misato',
			'Uruma',
			'Hachimantai',
			'Arashiyama',
			'Hatsudai',
			'Iga',
			'Ayase',
			'Mine',
			'Ebino',
			'Seiyo',
			'Nantan',
			'Kasado',
			'Yono',
			'Yasuda',
			'Yabu',
			'Wadomari',
			'Tochigi',
			'Nishishinminato',
			'Shibata',
			'Sakaiminato',
			'Sabae',
			'Ōtsuki',
			'Okegawa',
			'Namerikawa',
			'Matsubase',
			'Koshima',
			'Komoro',
			'Kikuchi',
			'Kawaguchi',
			'Hamakita',
			'Gyōda',
			'Enzan',
			'Arai',
			'Amagi',
			'Tagajō-shi',
			'Edosaki',
			'Rumoi',
			'Nakagawa',
			'Kosai',
			'Minokamo',
			'Myoko',
			'Tachikawa',
			'Sagamihara',
			'Sakai',
			'Fujimi',
			'Hida',
			'Ureshino',
			'Tokoname',
			'Tanabe',
			'Shirahamachō-usazakiminami',
			'Ōi',
			'Mori',
			'Minano',
			'Marugame',
			'Makinohara',
			'Komatsu',
			'Kōchi',
			'Kamirenjaku',
			'Kadogawa',
			'Izumiōtsu',
			'Iwai',
			'Hikone',
			'Hachiōji',
			'Fukuoka',
			'Aya',
			'Abu',
			'Wada',
			'Toride',
			'Tōno',
			'Sakura',
			'Toyoura',
			'Oshamambe',
			'Kuromatsunai',
			'Hanawa',
			'Bibai',
			'Akabira',
			'Yomogita',
			'Omitama',
			'Oyabe',
			'Urasoe',
			'Kurihara',
			'Sennan',
			'Yoshitomi',
			'Inuotose',
			'Izumizaki',
			'Nagatoro',
			'Yame',
			'Nagakute',
			'Fuchū',
			'Sodegaura',
			'Kōnan',
			'Semboku',
			'Wakimachi',
			'Utazu',
			'Uenohara',
			'Tsukumiura',
			'Takahashi',
			'Shimo-tsuma',
			'Shimonita',
			'Saka',
			'Owase',
			'Ōuda',
			'Ogunimachi',
			'Naha',
			'Minakuchichō-matoba',
			'Minabe',
			'Matsumoto',
			'Makurazaki',
			'Kumagaya',
			'Kitakata',
			'Kawasaki',
			'Izena',
			'Iwakuni',
			'Inami',
			'Hatogaya-honchō',
			'Gōdo',
			'Fukuroi',
			'Fukura',
			'Kariya',
			'Nagato',
			'Uwajima',
			'Wakuya',
			'Sendai',
			'Ōhara',
			'Ōarai',
			'Nihommatsu',
			'Namie',
			'Nagai',
			'Mito',
			'Kasama',
			'Mobetsu',
			'Esashi',
			'Nanto-shi',
			'Hitachi-Naka',
			'Katsuragi',
			'Tonami',
			'Ōnojō',
			'Shonai',
			'Tōon',
			'Yamatokōriyama',
			'Nanyō',
			'Aki-takata',
			'Kama',
			'Asago',
			'Kasai',
			'Soo',
			'Fuefuki',
			'Asakuchi',
			'Yokohama',
			'Tokyo',
			'Tateyama',
			'Sekimachi',
			'Kurume',
			'Ino',
			'Iiyama',
			'Hisai-motomachi',
			'Hinokage',
			'Gose',
			'Ginoza',
			'Fukui-shi',
			'Fujieda',
			'Fuji',
			'Ayabe',
			'Atami',
			'Tsukuba',
			'Tomiya',
			'Ichinomiya',
			'Hanawa',
			'Fujishiro',
			'Sarabetsu',
			'Ōwani',
			'Kamishihoro',
			'Sanmu',
			'Tomisato',
			'Nisshin',
			'Sado',
			'Hannan',
			'Urayasu',
			'Hachijō',
			'Awa',
			'Yatsushiro',
			'Mitaka',
			'Zama',
			'Yonago',
			'Uozu',
			'Tsukawaki',
			'Tarumizu',
			'Suzuka',
			'Shizuoka',
			'Saijō',
			'Ōmamachō-ōmama',
			'Ōdachō-ōda',
			'Nōgata',
			'Nichinan',
			'Kameshima',
			'Kakegawa',
			'Isesaki',
			'Hōfu',
			'Himeji',
			'Gamagōri',
			'Amagasaki',
			'Aioi',
			'Seirō',
			'Yokoshiba',
			'Shinmachi',
			'Sawara',
			'Matsushima',
			'Kaminoyama',
			'Shimukappu',
			'Rusutsu',
			'Mombetsu',
			'Hakodate',
			'Akaigawa',
			'Kihoku-chō',
			'Kaga',
			'Minami-Sōma',
			'Ōshū',
			'Gujō',
			'Agano',
			'Rishiri Town',
			'Ide',
			'Kawanishi',
			'Higashihiroshima',
			'Fukaya',
			'Kiyose',
			'Tatsuno',
		],
	},
	Jersey: { country: 'Jersey', cities: ['Saint Helier', 'Saint John', 'Le Hocq'] },
	Jordan: {
		country: 'Jordan',
		cities: [
			'Sūf',
			'Qumaym',
			'Aş Şubayḩī',
			'Kafr Sawm',
			'Ar Ramthā',
			'Al Kittah',
			'Yarqā',
			'Umm as Summāq',
			'Şabḩā',
			"Ma'an",
			'Ḩakamā',
			'Burmā',
			'Bayt Yāfā',
			'Bayt Īdis',
			'Ad Dīsah',
			'Rukban',
			'‘Izrā',
			'Ḩātim',
			'Dhībān',
			'‘Ayn al Bāshā',
			'Mafraq',
			'Al Ḩasā',
			'Qafqafā',
			'Buşayrā',
			'Ar Ruwayshid',
			'Ghawr al Mazra‘ah',
			'Az̧ Z̧ulayl',
			'Dayr al Kahf',
			'‘Irjān',
			'Safi',
			'Al ‘Arīḑ',
			'Sakib',
			'Juraynah',
			'Aydūn',
			'Al Qaţrānah',
			'Kharjā',
			'Kafr Asad',
			'Irbid',
			'Dayr Yūsuf',
			'Dayr ‘Allā',
			'Ash Shūnah ash Shamālīyah',
			'Ar Rabbah',
			'Al Ḩusaynīyah',
			'Umm al Jimāl',
			'Mulayḩ',
			'Zarqa',
			'Al Khinzīrah',
			'Al Juwayyidah',
			'Adir',
			'Tibnah',
			'Al Murayghah',
			'Judita',
			'Ḩalāwah',
			'Al Quwayrah',
			'Al Karāmah',
			'Al Jīzah',
			'Al Ḩişn',
			'Russeifa',
			'Al Mughayyir',
			'Bīrayn',
			'Balīlā',
			'Ash Shawbak',
			'Rujm ash Shāmī al Gharbī',
			'Fuqū‘',
			'Al Maşţabah',
			'Sammā ar Rawsān',
			'Saḩāb',
			'Mā‘īn',
			'Mādabā',
			'Muthallath al Azraq',
			'Manshīyat Banī Ḩasan',
			'Aqaba',
			'Ḩawshā',
			'Aţ Ţayyibah',
			'Umm al Qiţţayn',
			'Rehab',
			'Al Qaşr',
			'Al Jafr',
			'‘Ajlūn',
			'Wādī as Sīr',
			'Samā as Sirḩān',
			'Sāl',
			'Kitim',
			'Ḩisbān',
			'As Salţ',
			'Qīr Moāv',
			'Al Jubayhah',
			'Muthallath Şabḩā',
			'Jāwā',
			'Ash Shūnah al Janūbīyah',
			'Şakhrah',
			'Al Fayşalīyah',
			'‘Ayn Jannā',
			'Al Hāshimīyah',
			'Şammā',
			'Jarash',
			'Bal‘amā',
			'Aţ Ţurrah',
			'Aţ Ţayyibah',
			'Al Ḩusaynīyah',
			'Umm Qays',
			'Umm al Basātīn',
			'Ar Ruşayfah',
			'Al Mazār ash Shamālī',
			'Al Mazār al Janūbī',
			'Al Khālidīyah',
			'Ar Rīshah',
			'Al ‘Abdallī',
			'Umm ar Raşāş',
			'Adhruḩ',
			'Kafr Abīl',
			'Ḩayy al Bunayyāt',
			'Al Muwaqqar',
			'Muthallath Jāmi‘at Āl al Bayt',
			'Dayr Abū Sa‘īd',
			'‘Ayy',
			'Ash Shajarah',
			'‘Anjarah',
			'Ḩayy al Quwaysimah',
			'Ayl',
			'Aţ Ţafīlah',
			'Al Fuḩayş',
			'Tala Bay',
			'Zaḩar',
			'Wādī Mūsá',
			'Kurayyimah',
			'Kufrinjah',
			'Amman',
			'Nā‘ūr',
			'Raymūn',
			'Malkā',
			'Al Azraq ash Shamālī',
			'Al Ḩamrā’',
			'Waqqāş',
			'Saḩam al Kaffārāt',
			'‘Allān',
			'Karak City',
		],
	},
	Kazakhstan: {
		country: 'Kazakhstan',
		cities: [
			'Makhambet',
			'Karaton',
			'Novokazalinsk',
			'Shymkent',
			'Qashar',
			'Konayev',
			'Osakarovka',
			'Zhabagly',
			'Moyynty',
			'Bauyrzhan Momyshuly',
			'Shalqīya',
			'Ozernyy',
			'Atbasar',
			'Khromtau',
			'Zhangatas',
			'Vozvyshenka',
			'Arshaly',
			'Turkestan',
			'Mayqayyng',
			'Lenger',
			'Burunday',
			'Bishkul',
			'Belousovka',
			'Bayzhansay',
			'Aksuat',
			'Tasty-Taldy',
			'Temir',
			'Akkol’',
			'Yrghyz',
			'Verkhniye Kayrakty',
			'Tobol',
			'Mynaral',
			'Ridder',
			'Kantagi',
			'Peremetnoe',
			'Miyaly',
			'Maloye Ganyushkino',
			'Shyngyrlau',
			'Bautino',
			'Umirzak',
			'Urzhar',
			'Talshik',
			'Shchuchinsk',
			'Presnovka',
			'Kīevka',
			'Mangistau',
			'Chapayev',
			'Burlin',
			'Batamshinskiy',
			'Tekeli',
			'Suykbulak',
			'Shantobe',
			'Kostanay',
			'Karatau',
			'Satpayev',
			'Lepsy',
			'Balkashino',
			'Aral',
			'Birlestik',
			'Zhezqazghan',
			'Zerenda',
			'Shortandy',
			'Soran',
			'Rudnyy',
			'Pervorossiyskoye',
			'Sarykemer',
			'Esik',
			'Bulayevo',
			'Bakanas',
			'Novoishimskiy',
			'Terekti',
			'Troyebratskiy',
			'Sayram',
			'Ust-Kamenogorsk',
			'Kulan',
			'Kushoky',
			'Qamysty',
			'Kalkaman',
			'Irtyshsk',
			'Shardara',
			'Arkalyk',
			'Prigorodnoye',
			'Zhana Kiima',
			'Stepnogorsk',
			'Krugloozërnoye',
			'Zharyk',
			'Turgen',
			'Timiryazevo',
			'Sharbaqty',
			'Petropavl',
			'Aqmol',
			'Glubokoye',
			'Shiyeli',
			'Chemolgan',
			'Shalqar',
			'Say-Utes',
			'Taraz',
			'Astana',
			'Maqat',
			'Balykshi',
			'Zaysan',
			'Zhangaqorghan',
			'Ushtobe',
			'Talghar',
			'Saryshaghan',
			'Saryozek',
			'Samarskoye',
			'Novaya Shūl’ba',
			'Qarabalyq',
			'Kokpekty',
			'Yereymentau',
			'Otegen Batyra',
			'Dolinka',
			'Marqaköl',
			'Shubarköl',
			'Zhaksy',
			'Mamlyutka',
			'Leninskiy',
			'Kishkenekol’',
			'Atakent',
			'Belogorskiy',
			'Atbasar',
			'Aqshataū',
			'Amangeldi',
			'Karatobe',
			'Baychunas',
			'Ūst’-Talovka',
			"Oktyabr'",
			'Asyqata',
			'Dzhambul',
			'Druzhba',
			'Zhangaarqa',
			'Akbakay',
			'Shu',
			'Matay',
			'Kurchum',
			'Aqtoghay',
			'Kokpekty',
			'Esil',
			'Derzhavinsk',
			'Auezov',
			'Aktogay',
			'Abay',
			'Zhetybay',
			'Shubarkuduk',
			'Shaul’der',
			'Sergeyevka',
			'Sastobe',
			'Lugovoy',
			'Kyzylzhar',
			'Kokshetau',
			'Ekibastuz',
			'Aksu-Ayuly',
			'Abay',
			'Chiganak',
			'Inderbor',
			'Bayganin',
			'Qasym Qaysenov',
			'Shakhtinsk',
			'Aksu',
			'Zhetysay',
			'Aksu',
			'Oral',
			'Kuryk',
			'Zhanibek',
			'Balpyk Bi',
			'Khantau',
			'Karabas',
			'Bayanaul',
			'Akkol',
			'Zhanaozen',
			'Taūshyq',
			'Algha',
			'Tobyl',
			'Yavlenka',
			'Terenozek',
			'Temirtau',
			'Akkuly',
			'Qorghalzhyn',
			'Kegen',
			'Karasu',
			'Zhalagash',
			'Sholakkorgan',
			'Shayan',
			'Ashchysay',
			'Aktau',
			'Ülken',
			'Priozersk',
			'Tyuratam',
			'Saykhin',
			'Munayshy',
			'Embi',
			'Zhangaqala',
			'Aqsay',
			'Torghay',
			'Tasbuget',
			'Belköl',
			'Shetpe',
			'Saykhin',
			'Zholymbet',
			'Saryaghash',
			'Sarqant',
			'Ognyovka',
			'Narynkol',
			'Kurchatov',
			'Kazalinsk',
			'Qogham',
			'Shalkar',
			'Kaztalovka',
			'Karagandy',
			'Turar Ryskulov',
			'Ulytau',
			'Ayat',
			'Shakhan',
			'Denisovka',
			'Makinsk',
			'Krasnogorskiy',
			'Bestobe',
			'Aktas',
			'Maleyevsk',
			'Obaghan',
			'Fort-Shevchenko',
			'Dossor',
			'Altay',
			'Zhezkent',
			'Yul’yevka',
			'Tyul’kubas',
			'Kyzylorda',
			'Karaaul',
			'Pavlodar',
			'Novoishimskoye',
			'Egindiköl',
			'Asubulak',
			'Altayskiy',
			'Saumalkol’',
			'Stepnyak',
			'Sayaq',
			'Qusmuryn',
			'Novaya Bukhtarma',
			'Lisakovsk',
			'Kazygurt',
			'Taiynsha',
			'Borodulikha',
			'Aktau',
			'Zhumysker',
			'Tasqala',
			'Beyneu',
			'Aktobe',
			'Zhosaly',
			'Zhitikara',
			'Ul’ba',
			'Sarykamys',
			'Qarqaraly',
			'Pervomayka',
			'Novodolinskiy',
			'Kokterek',
			'Korday',
			'Besqaraghay',
			'Aqtoghay',
			'Aksu',
			'Kandyagash',
			'Zhympity',
			'Zhalghyztobe',
			'Uspenka',
			'Smirnovo',
			'Shar',
			'Saksaul’skiy',
			'Qarazhal',
			'Merke',
			'Koshi',
			'Kalbatau',
			'Astrakhanka',
			'Martuk',
			'Atyrau',
			'Taldykorgan',
			'Semey',
			'Tughyl',
			"Uzunkol'",
			'Baikonur',
			'Granitogorsk',
			'Fyodorov',
			'Borovskoy',
			'Boko',
			'Asa',
			'Arys',
			'Arykbalyk',
			'Aksuat',
			'Zavodskoy',
			'Qulsary',
			"Sarykol'",
			"Auliyekol'",
			'Leningradskoye',
			'Koktal',
			'Kentau',
			'Moyynkum',
			'Koktobe',
			'Aqadyr',
			'Almaty',
			'Akkol’',
			'Shubarshi',
			'Zhelezinka',
			'Temirlanovka',
			'Tayynsha',
			'Myrzakent',
			'Chernak',
			'Shemonaikha',
			'Zharkent',
			'Oytal',
			'Qaramengdi',
			'Balqash',
			'Ayagoz',
			'Aktogay',
			'Shevchenko',
			'Tokarevka',
			'Terengköl',
			'Būrabay',
			'Ulken Naryn',
		],
	},
	Kenya: {
		country: 'Kenya',
		cities: [
			'Mbale',
			'Keroka',
			'Kajiado',
			'Embu',
			'Mariakani',
			'Konza',
			'Mtito Andei',
			'Kakamega',
			'Butere',
			'Kathwana',
			'Thika',
			'Kijabe',
			'Busia',
			'Bondo',
			'Mwingi',
			'Kitui',
			'Port Victoria',
			'Sotik Post',
			'Karuri',
			'Kabarnet',
			'Malikisi',
			'Rumuruti',
			'Maragua',
			'Magadi',
			'Limuru',
			'Kerugoya',
			'Kericho',
			'Ruiru',
			'Rongai',
			'Nakuru',
			'Makueni Boma',
			'Kwale',
			'Kilifi',
			'Kapenguria',
			'Kangundo',
			'Bungoma',
			'Voi',
			'Takaungu',
			'Eldoret',
			'Ogembo',
			'Nyeri',
			'Nyahururu',
			'Naivasha',
			'Muhoroni',
			'Eldama Ravine',
			'Bungoma',
			'Naro Moru',
			'Nanyuki',
			'Moyale',
			'Malaba',
			'Lodwar',
			'Baringo',
			'Sotik',
			'Shimoni',
			'Ngong',
			'Migori',
			'Molo',
			'Mandera',
			'Kipkelion',
			'Lugulu',
			'Oyugis',
			'Nyamira',
			'Kisii',
			'Nambare',
			'Mwatate',
			'Kikuyu',
			'Kiambu',
			'Kapsabet',
			'Iten',
			'Sawa Sawa',
			'Chepareria',
			'Nairobi',
			'Isiolo',
			'Hola',
			'Wajir',
			'Nandi Hills',
			'Murang’a',
			'Meru',
			'Homa Bay',
			'Ol Kalou',
			'Mumias',
			'Malindi',
			'Luanda',
			'Garissa',
			'Narok',
			'Kitale',
			'Chuka',
			'Webuye',
			'Machakos',
			'Londiani',
			'Kinango',
			'Kapsowar',
			'Athi River',
			'Yala',
			'Wundanyi',
			'Pumwani',
			'Lamu',
			'Sagana',
			'Marsabit',
			'Kipini',
			'Gazi',
			'Taveta',
			'Kangema',
			'Ahero',
			'Litein',
			'Kihancha',
			'Wote',
			'Mombasa',
			'Siaya',
			'Witu',
			'Othaya',
			'Maua',
			'Maralal',
			'Lokichokio',
			'Kisumu',
		],
	},
	Kiribati: {
		country: 'Kiribati',
		cities: [
			'Buota Village',
			'Makin Village',
			'Nawerewere Village',
			'Betio Village',
			'Rawannawi Village',
			'Teaoraereke Village',
			'Banana Village',
			'Temaiku Village',
			'Bikenibeu Village',
			'Eita Village',
			'Ambo Village',
			'Bonriki Village',
			'London Village',
			'Napari Village',
			'Tabwakea Village',
			'Bairiki Village',
			'Tarawa',
			'Banraeaba Village',
		],
	},
	"Korea, Dem. People's Rep. of": {
		country: "Korea, Dem. People's Rep. of",
		cities: [
			'Pyongyang',
			'Ŭndŏk',
			'Sakchu-ŭp',
			'Ryongdang',
			'P’yŏngsŏng',
			'Koksan',
			'Sŏnbong',
			'Kanggye',
			'Hyesan-dong',
			'Hongwŏn',
			'Kogŏnwon',
			'Samho-rodongjagu',
			'Kangdong-ŭp',
			'Kyŏngwŏn',
			'Kyŏngsŏng',
			'Yŏnan-ŭp',
			'Sunch’ŏn',
			'Kusŏng',
			'Kowŏn-ŭp',
			'Hŭkkyo-ri',
			'Ŭijin-dong',
			'Namyang',
			'Ungsang',
			'Yŏngbyŏn',
			'Changyŏn',
			'Ayang-ni',
			'Yonggang-ŭp',
			'Puryŏng',
			'Nongnim',
			'Hoeyang',
			'Chunghwa',
			'Manp’o',
			'P’ungin',
			'Yŏnggwang-ŭp',
			'Musan-ŭp',
			'Kujang-ŭp',
			'Hau-ri',
			'Hahoe-dong',
			'Sŭngho 1-tong',
			'Hyesan',
			'Chongjin',
			'Wŏnsan',
			'Onsŏng',
			'Iwŏn-ŭp',
			'Sŭngjibaegam',
			'Kapsan-ŭp',
			'Yŏmju-ŭp',
			'Namp’o',
			'Haeju',
			'Sinsang-ni',
			'Sinanju',
			'Chŏngju',
			'Anak',
			'Sil-li',
			'Yuktae-dong',
			'Uiju',
			'Sŏng-dong',
			'Sariwŏn',
			'Sambong',
			'Chaeryŏng-ŭp',
			'Pukchil-lodongjagu',
			'Aoji',
			'Kwaksan',
			'Ungsang-nodongjagu',
			'Songnim-ni',
			'Pyŏksŏng-ŭp',
			'Ongjin',
			'Kaesŏng',
			'Anbyŏn-ŭp',
			'Sunan',
			'Panghyŏn-dong',
			'Ranam',
			'Sinŭiju',
			'Kosan',
			'Rimgan-ni',
			'Hoemul-li',
			'Komusan Il-tong',
			'Namsan',
			'Hŭngju-dong',
			'Kwirak',
			'Sangsŏng-ni',
			'Hŭngnam',
			'Sŭngam-nodongjagu',
			'Kimch’aek-si',
			'Kilju',
			'Chasŏng',
			'Rajuk',
			'Sinmak',
			'Anju',
			'Sech’ŏn',
			'Rajin',
			'Yongha',
			'T’ongch’ŏn-ŭp',
			'Hwangju-ŭp',
			'Hamhŭng',
			'Hoeryŏng',
			'Chungbong',
		],
	},
	'Korea, Republic of': {
		country: 'Korea, Republic of',
		cities: [
			'Hancheolli',
			'Cheongsong gun',
			'Cheongsan',
			'Hamyeol',
			'Dongmyeon',
			'Yureo',
			'Simwon',
			'Beopseong',
			'Yeonggwang',
			'Yesan',
			'Yangju',
			'Tangjin',
			'Sangju',
			"Koch'ang",
			'Haenam',
			'Jumunjin',
			'Cheongju-si',
			"Chinch'ŏn",
			'Gaigeturi',
			'Gyuam',
			'Seonwon',
			'Tanhyeon',
			'Yeoncheon',
			'Dongbok',
			'Santyoku',
			'Nangen',
			'Jeju City',
			'Annam',
			'Geumjeong',
			'Sangsa',
			'Seungju',
			'Bulgap',
			'Yeongju',
			'Yeongdeok',
			'Jenzan',
			'Buyeo',
			'Busan',
			'Osan',
			'Kosong',
			'Gyeongsan-si',
			'Kunwi',
			'Jangheung',
			'Seongnam-si',
			'Mijo',
			'Okgok',
			'Biin',
			'Gongju',
			'Chungju',
			'Anyang-si',
			'Jangpyeong',
			'Haean',
			'Mundeok',
			'Miryeok',
			'Haeri',
			'Myoryang',
			'Gurim',
			'Neietsu',
			'Sintansin',
			'Kinzan',
			'Chupungnyeong',
			'Andong',
			'Cheongnam',
			'Hwanggan',
			'Changnyeong',
			'Yeoju',
			'Miryang',
			'Hongch’ŏn',
			'Cheorwon',
			'Hanam',
			'Sejong',
			'Bannam',
			'Geumseong',
			'Hadong',
			'Suncheon',
			'Gunsan',
			'Cheongpyeong',
			'Songgangdong',
			'Su-dong',
			'Oeseo',
			'Nagwol',
			'Gangjin',
			'Wŏnju',
			'Beolgyo',
			'Beobwon',
			'Gwangju',
			'Sinan',
			'Gunseo',
			'Gunbuk',
			'Sindong',
			'Joseong',
			'Eisen',
			'Pohang',
			'Mokpo',
			'Gumi',
			'Kimhae',
			'Cheonan',
			'Ilgwang',
			'Sanseo',
			'Sannae',
			'Deokjin',
			'Daema',
			'Daejeon',
			'Goseong',
			'Kyosai',
			'Yŏnmu',
			'Hwanam',
			'Seosang',
			'Seoseok',
			'Nammyeon',
			'Haeryong',
			'Songgwang',
			'Sangha',
			'Donggye',
			'Yonghwa',
			'Seonghwan',
			'Gampo',
			'Singwang',
			'Jeongeup',
			'Gangdong',
			'Cheongpung',
			'Jinsang',
			'Ongnyong',
			'Bongnae',
			'Ingye',
			'Yeongdong',
			'Baekjeon',
			'Toseong',
			'Gongeum',
			'Seosan',
			'Gyeongju',
			'Gwanchon',
			'Sangwol',
			'Sijong',
			'Gujeong',
			'Dongmyeon',
			'Yanggu',
			'Illo',
			'Ocheon',
			'Sinseo',
			'Bupyeong',
			'Hwayang',
			'Seji',
			'Haebo',
			'Gunnam',
			'Ssangchi',
			'Yecheon',
			'Chuncheon',
			'Baeksu',
			'Seongsu',
			'Haksan',
			'Daegu',
			'Kimje',
			'Gimcheon',
			'Iksan',
			'Gwangyang',
			'Donghae City',
			'Dongi',
			'Onam',
			'Bonggang',
			'Mungyeong',
			'Imsil',
			'Yongsan-dong',
			'Haseong',
			'Gyeombaek',
			'Yangsan',
			'Okcheon',
			'Kurye',
			'Pubal',
			'Samseung',
			'Samsan',
			'Sinan',
			'Jeongnyang',
			'Haeje',
			'Damyang',
			'Boryeong',
			'Sokcho',
			'Gwangju',
			'Guryongpo',
			'Ganghwa-gun',
			'Cheongyang',
			'Chinju',
			'Jinjam',
			'Ansan-si',
			'Palgeum',
			'Byeollyang',
			'Nammyeon',
			'Yongan',
			'Dongnae',
			'Naju',
			'Munsan',
			'Guri-si',
			'Changpyeong',
			'Hajeom',
			'Apae',
			'Dopo',
			'Gunseo',
			'Wanju',
			'Suwon',
			'Pyeongchang',
			'Gunpo',
			'Incheon',
			'Hwaseong-si',
			'Changwon',
			'Changsu',
			'Hongnong',
			'Yangsa',
			'Gwangtan',
			'Yuchi',
			'Yeonil',
			"Yangp'yŏng",
			'Waegwan',
			'Simcheon',
			'Muan',
			'Gangneung',
			'Hwado',
			'Wabu',
			'Seolcheon',
			'Daehap',
			'Dongnae',
			'Heunghae',
			'Yuseong',
			'Iyang',
			'Taesal-li',
			'Bucheon-si',
			'Nonsan',
			'Kwangyang',
			'Icheon-si',
			'Yeosu',
			'Ungsang',
			'Naesŏ',
			'Paju',
			'Cheongseong',
			'Masan',
			'Goyang-si',
			'Gijang',
			'Anseong',
			'Cheongha',
			'Gunbuk',
			'Songhae',
			'Gwangjeok',
			'Gwanin',
			'Bangsan',
			'Nodong',
			'Nasan',
			'Imja',
			'T’aebaek',
			'Puan',
			'Hwasun',
			'Hwacheon',
			'Jeonju',
			'Jido',
			'Iwon',
			'Hwawŏn',
			'Seogwipo',
			'Byeonggok',
			'Tongjin',
			'Jinjeop',
			'Boseong',
			'Yeongam',
			'Yeomsan',
			'Chilbo',
			'Ulsan',
			'Uijeongbu-si',
			'Sangju',
			'Mungyeong',
			'Gapyeong',
			'Hyeonpung',
			'Hongseong',
			'Jeongok',
			'Jangseong',
			'Sinhyeon',
			'Gwangmyeong',
			'Hoenam',
			'Yongsan',
			'Beonam',
			'Wolgot',
			'Jangheung',
			'Seoul',
			'Asan',
			'Koesan',
			'Hayang',
			'Jinan-gun',
			'Jangan',
			'Namyangju',
			'Annae',
			'Gyodong',
			'Jinwol',
			'Juam',
			'Daesan',
		],
	},
	Kuwait: {
		country: 'Kuwait',
		cities: [
			'Şabāḩ as Sālim',
			'Ar Rumaythīyah',
			'Al-Masayel',
			'Ḩawallī',
			'Al Manqaf',
			'Al Jahrā’',
			'Ad Dasmah',
			'Ash Shāmīyah',
			'Mubārak al Kabīr',
			'Al Finţās',
			'Az Zawr',
			'As Sālimīyah',
			'Al Aḩmadī',
			'Al Farwānīyah',
			'Abu Fatira',
			'Al Funayţīs',
			'Ar Riqqah',
			'Ar Rābiyah',
			'Al Mahbūlah',
			'Janūb as Surrah',
			'Kuwait City',
			'Bayān',
			'Salwá',
			'Al Wafrah',
			'Al Faḩāḩīl',
			'Abu Al Hasaniya',
		],
	},
	Kyrgyzstan: {
		country: 'Kyrgyzstan',
		cities: [
			'Uch-Korgon',
			'Sosnovka',
			'Uzgen',
			'Kant',
			'Ak-Say',
			'Suzak',
			'Kochkor-Ata',
			'Batken',
			'Alga',
			'Kyzyl-Bulak',
			'Cholpon-Ata',
			'Pulgon',
			'Kazarman',
			'Kemin',
			'Toktogul',
			'Markaz',
			'Suluktu',
			'Daroot-Korgon',
			'Tyup',
			'Tash-Kumyr',
			'Kaindy',
			'Chayek',
			'Toguz-Bulak',
			'Sokuluk',
			'Iradan',
			'Baetovo',
			'Kara-Bak',
			'Osh',
			'Orozbekovo',
			'Bokombayevskoye',
			'Bishkek',
			'Katran',
			'Bakai-Ata',
			'Kerben',
			'Talas',
			'Üch-Terek',
			'Jalal-Abad',
			'Nookat',
			'Khalmion',
			'Ivanovo-Alekseyevka',
			'Kulundu',
			'Kara-Dzhygach',
			'Ivanovka',
			'Kyzyl-Eshme',
			'Razzakov',
			'Tokmok',
			'Ak-Suu',
			'Lebedinovka',
			'Tayan',
			'Kyzyl-Adyr',
			'Ala-Buka',
			'Kara-Balta',
			'Naryn',
			'Ak-Suu',
			'Kochkor',
			'Karavan',
			'Karakol',
			'Nizhnyaya Ala-Archa',
			'Kara-Kulja',
			'Andarak',
			'Kara Suu',
			'Belovodskoye',
			'Kara-Dëbë',
			'Aydarken',
			'Massy',
			'Beshkent',
			'Ak-Tatyr',
			'Chuy',
			'Kyzyl-Kyya',
			'Kadzhi-Say',
			'Balykchy',
			'Korgon',
			'Talas',
			'Pokrovka',
			'At-Bashi',
			'Kyzyl-Suu',
			'Gul’cha',
			'Kanysh-Kyya',
			'Bazar-Korgon',
			'Mayevka',
			'Samarkandyk',
			'Boz-Adyr',
			'Ormosh',
			'Aravan',
			'Chek',
			'Kyrgyz-Kyshtak',
		],
	},
	"Lao People's Dem. Rep.": {
		country: "Lao People's Dem. Rep.",
		cities: [
			'Muang Sing',
			'Ban Nahin',
			'Muang Phin',
			'Thakhèk',
			'Luang Prabang',
			'Salavan',
			'Pakkading',
			'Pakxan',
			'Muang Phônsavan',
			'Dak Cheung',
			'Muang Mok',
			'Ban Hatgnao',
			'Ban Dônghén',
			'Sanaxy',
			'Borikhan',
			'Khoa',
			'Lamam',
			'Attapeu',
			'Muang Phôn-Hông',
			'Phônthong',
			'Pakxong',
			'Luang Namtha',
			'Muang Khamkeut',
			'Vang Vieng',
			'Ban Pakla',
			'Xaysetha',
			'Xam Nua',
			'Sainyabuli',
			'Ban Houayxay',
			'Viangxai',
			'Huameung',
			'Xamtay',
			'Vientiane',
			'Muang Nalè',
			'Ban Houakhoua',
			'Ban Fangdèng',
			'Ban Namnga',
			'Viengxay',
			'Muang Hinboun',
			'Viengthong',
			'Sekong',
			'Pakse',
			'Muang Sanakham',
			'Muang Kènthao',
			'Muang Xay',
			'Muang Long',
			'Muang Không',
			'Champasak',
			'Ban Xiang-Ngeun',
			'Ban Thatèng',
			'Muang Kasi',
			'Savannakhet',
			'Phôngsali',
		],
	},
	Latvia: {
		country: 'Latvia',
		cities: [
			'Garkalne',
			'Ludza',
			'Līgatne',
			'Daugavpils',
			'Daugavgrīva',
			'Baloži',
			'Sigulda',
			'Mazsalaca',
			'Bolderaja',
			'Alsunga',
			'Aknīste',
			'Tīreļi',
			'Seda',
			'Preiļi',
			'Engure',
			'Dobele',
			'Zelmeņi',
			'Nīca',
			'Ķegums',
			'Aloja',
			'Ainaži',
			'Vangaži',
			'Valdemārpils',
			'Vaiņode',
			'Mežaparks',
			'Jēkabpils',
			'Ikšķile',
			'Riebiņi',
			'Krāslava',
			'Kārsava',
			'Jaunpils',
			'Ērgļi',
			'Brocēni',
			'Alūksne',
			'Viļaka',
			'Varakļāni',
			'Talsi',
			'Riga',
			'Grobiņa',
			'Vecrīga',
			'Viļāni',
			'Stalbe',
			'Rūjiena',
			'Ozolnieki',
			'Kocēni',
			'Ķekava',
			'Kalnciems',
			'Jelgava',
			'Lielvārde',
			'Aizpute',
			'Salaspils',
			'Madona',
			'Kandava',
			'Iecava',
			'Roja',
			'Piņķi',
			'Ogre',
			'Naukšēni',
			'Limbaži',
			'Jaunciems',
			'Cēsis',
			'Aizkraukle',
			'Zilupe',
			'Stende',
			'Skrunda',
			'Skrīveri',
			'Jūrmala',
			'Vecumnieki',
			'Priekuļi',
			'Mērsrags',
			'Līvāni',
			'Kuldīga',
			'Dārzciems',
			'Rēzekne',
			'Priekule',
			'Inčukalns',
			'Saldus',
			'Pāvilosta',
			'Jaunjelgava',
			'Ulbroka',
			'Tērvete',
			'Smārde',
			'Saulkrasti',
			'Pļaviņas',
			'Lubāna',
			'Mārupe',
			'Viesīte',
			'Valka',
			'Rucava',
			'Mūrmuiža',
			'Koknese',
			'Ilūkste',
			'Gulbene',
			'Dreiliņi',
			'Sabile',
			'Bauska',
			'Strenči',
			'Smiltene',
			'Nereta',
			'Dagda',
			'Balvi',
			'Baldone',
			'Krustpils',
			'Drabeši',
			'Cesvaine',
			'Valmiera',
			'Rauna',
			'Ādaži',
			'Ventspils',
			'Tukums',
			'Liezēre',
			'Jaunaglona',
			'Sala',
			'Ragana',
			'Olaine',
			'Liepāja',
			'Karosta',
			'Ape',
			'Vecpiebalga',
			'Jumprava',
			'Berģi',
			'Auce',
			'Salacgrīva',
			'Matīši',
			'Lieģi',
		],
	},
	Lebanon: {
		country: 'Lebanon',
		cities: [
			'Beït ed Dîne',
			'Tyre',
			'Hrajel',
			'Baalbek',
			'Bhamdoun',
			'Baabda',
			'Aaley',
			'Amioûn',
			'Ghazieh',
			'Batroûn',
			'Bhamdoûn el Mhatta',
			'Bcharré',
			'Sidon',
			'Halba',
			'Ain Ebel',
			'Beirut',
			'Nabatîyé et Tahta',
			'En Nâqoûra',
			'Aanjar',
			'Zahlé',
			'Jbaïl',
			'Habboûch',
			'Jdaidet el Matn',
			'Bent Jbaïl',
			'Hâsbaïya',
			'El Hermel',
			'Joubb Jannîne',
			'Marjayoûn',
			'Jounieh',
			'Jezzîne',
			'Tripoli',
			'Sîr ed Danniyé',
			'Ra’s Bayrūt',
			'Zghartā',
			'Râchaïya el Ouadi',
		],
	},
	Lesotho: {
		country: 'Lesotho',
		cities: [
			'Maseru',
			'Mafeteng',
			'Nako',
			'Butha-Buthe',
			'Teyateyaneng',
			'Thaba-Tseka',
			'Leribe',
			'Qacha’s Nek',
			'Mokhotlong',
			'Mohale’s Hoek',
			'Maputsoe',
			'Quthing',
		],
	},
	Liberia: {
		country: 'Liberia',
		cities: [
			'Greenville',
			'New Yekepa',
			'Kakata',
			'Harper',
			'Sanniquellie',
			'Ganta',
			'Buchanan',
			'Robertsport',
			'Bopolu',
			'Cestos City',
			'Gbarnga',
			'Zwedru',
			'Barclayville',
			'Buutuo',
			'Monrovia',
			'Voinjama',
			'Tubmanburg',
			'Fish Town',
			'Bensonville',
		],
	},
	'Libyan Arab Jamahiriya': {
		country: 'Libyan Arab Jamahiriya',
		cities: [
			'At Tāj',
			'Al Qubbah',
			'Zuwārah',
			'Sirte',
			'Al Bardīyah',
			'Sabhā',
			'Mizdah',
			'Al Abyār',
			'Bani Walid',
			'Az Zāwīyah',
			'Brak',
			'Al Jaghbūb',
			'Zliten',
			'Ghat',
			'Şabrātah',
			'Az Zuwaytīnah',
			'Tarhuna',
			'Qaryat Sulūq',
			'Waddān',
			'Al Qaţrūn',
			'Jālū',
			'Al Jumayl',
			'Idrī',
			'Marādah',
			'Hūn',
			'Al Marj',
			'Yafran',
			'Tripoli',
			'Al ‘Azīzīyah',
			'Jādū',
			'Darnah',
			'Masallātah',
			'Zalţan',
			'Al Burayqah',
			'Ghadāmis',
			'Murzuq',
			'Qasr Abu Hadi',
			'Awjilah',
			'Tūkrah',
			'Mişrātah',
			'Tājūrā’',
			'Zawiya',
			'Ubari',
			'Gharyan',
			'Al Khums',
			'Şurmān',
			'Zintan',
			'Tobruk',
			'Al Ajaylat',
			'Al Jadīd',
			'Al Bayḑā’',
			'Ajdabiya',
			'Nālūt',
			'Benghazi',
			'Al Jawf',
		],
	},
	Liechtenstein: {
		country: 'Liechtenstein',
		cities: ['Triesen', 'Schellenberg', 'Schaan', 'Planken', 'Eschen', 'Vaduz', 'Balzers', 'Gamprin', 'Ruggell', 'Mauren', 'Triesenberg'],
	},
	Lithuania: {
		country: 'Lithuania',
		cities: [
			'Zarasai',
			'Pabradė',
			'Šeškinė',
			'Vievis',
			'Venta',
			'Palanga',
			'Gargždai',
			'Šventoji',
			'Lentvaris',
			'Lazdijai',
			'Anykščiai',
			'Kazlų Rūda',
			'Kaunas',
			'Ežerėlis',
			'Lazdynai',
			'Veisiejai',
			'Neringa',
			'Kretinga',
			'Grigiškės',
			'Vilkaviskis',
			'Šakiai',
			'Aleksotas',
			'Šilainiai',
			'Kuršėnai',
			'Jurbarkas',
			'Utena',
			'Naujoji Akmene',
			'Daugai',
			'Pilaitė',
			'Rokiškis',
			'Ramučiai',
			'Kulautuva',
			'Eišiškės',
			'Justiniškės',
			'Būgai',
			'Priekulė',
			'Panevėžys',
			'Nida',
			'Linkuva',
			'Kudirkos Naumiestis',
			'Kaišiadorys',
			'Jieznas',
			'Birštonas',
			'Baltoji Vokė',
			'Rasos',
			'Ramygala',
			'Pervalka',
			'Kybartai',
			'Dainava (Kaunas)',
			'Šilalė',
			'Akmenė',
			'Viekšniai',
			'Tytuvėnėliai',
			'Skaidiškės',
			'Salantai',
			'Nemenčinė',
			'Klaipėda',
			'Elektrėnai',
			'Karoliniškės',
			'Eiguliai',
			'Švenčionėliai',
			'Rietavas',
			'Radviliskis',
			'Naujamiestis',
			'Varėna',
			'Trakai',
			'Pakruojis',
			'Kelmė',
			'Kėdainiai',
			'Joniškis',
			'Vilkpėdė',
			'Vilnius',
			'Šalčininkai',
			'Prienai',
			'Karmėlava',
			'Garliava',
			'Akademija (Kaunas)',
			'Žagarė',
			'Telsiai',
			'Obeliai',
			'Juodupė',
			'Švenčionys',
			'Naujoji Vilnia',
			'Mastaiciai',
			'Širvintos',
			'Simnas',
			'Seda',
			'Plunge',
			'Jonava',
			'Plateliai',
			'Molėtai',
			'Marijampolė',
			'Birzai',
			'Alytus',
			'Rūdiškės',
			'Pagėgiai',
			'Vilkija',
			'Druskininkai',
			'Žiežmariai',
			'Žemaičių Naumiestis',
			'Šiauliai',
			'Rusnė',
			'Raseiniai',
			'Pašilaičiai',
			'Skuodas',
			'Silute',
			'Fabijoniškės',
			'Mazeikiai',
			'Visaginas',
			'Taurage',
			'Šeduva',
			'Sargėnai',
			'Pasvalys',
			'Kupiskis',
			'Kalvarija',
			'Ignalina',
			'Ariogala',
			'Naujininkai',
			'Ukmerge',
			'Senieji Trakai',
			'Matuizos',
			'Gelgaudiškis',
			'Antakalnis',
		],
	},
	Luxembourg: {
		country: 'Luxembourg',
		cities: [
			'Rambrouch',
			'Niederanven',
			'Merl',
			'Weiler-la-Tour',
			'Obercorn',
			'Larochette',
			'Kopstal',
			'Kirchberg',
			'Flaxweiler',
			'Beckerich',
			'Mertert',
			'Kayl',
			'Grosbous',
			'Esch-sur-Alzette',
			'Ell',
			'Colmar',
			'Warken',
			'Waldbredimus',
			'Lenningen',
			'Weiswampach',
			'Waldbillig',
			'Vianden',
			'Niedercorn',
			'Hosingen',
			'Goesdorf',
			'Dalheim',
			'Bettembourg',
			'Bergem',
			'Beaufort',
			'Moutfort',
			'Gonderange',
			'Ettelbruck',
			'Esch-sur-Sûre',
			'Capellen',
			'Aspelt',
			'Fentange',
			'Betzdorf',
			'Berdorf',
			'Pétange',
			'Mamer',
			'Bridel',
			'Walferdange',
			'Schuttrange',
			'Reisdorf',
			'Wincrange',
			'Tuntange',
			'Tétange',
			'Lamadelaine',
			'Heisdorf',
			'Hautcharage',
			'Wormeldange',
			'Sandweiler',
			'Rosport',
			'Bavigne',
			'Wiltz',
			'Medernach',
			'Junglinster',
			'Helmsange',
			'Bissen',
			'Wilwerwiltz',
			'Troisvierges',
			'Wasserbillig',
			'Steinsel',
			'Steinfort',
			'Mersch',
			'Hesperange',
			'Grevenmacher',
			'Eischen',
			'Dippach',
			'Differdange',
			'Crauthem',
			'Bous',
			'Bascharage',
			'Saeul',
			'Roeser',
			'Fischbach',
			'Bettendorf',
			'Strassen',
			'Schieren',
			'Hobscheid',
			'Bertrange',
			'Alzingen',
			'Septfontaines',
			'Reckange-sur-Mess',
			'Nommern',
			'Leudelange',
			'Clervaux',
			'Schengen',
			'Lintgen',
			'Heffingen',
			'Bettborn',
			'Rumelange',
			'Müllendorf',
			'Echternach',
			'Consdorf',
			'Boevange-sur-Attert',
			'Belvaux',
			'Bech',
			'Stadtbredimus',
			'Pontpierre',
			'Olm',
			'Putscheid',
			'Schrassig',
			'Schouweiler',
			'Redange-sur-Attert',
			'Diekirch',
			'Winseler',
			'Remich',
			'Mondercange',
			'Mertzig',
			'Kehlen',
			'Garnich',
			'Niederfeulen',
			'Contern',
			'Mondorf-les-Bains',
			'Canach',
			'Tandel',
			'Schifflange',
			'Sanem',
			'Manternach',
			'Lorentzweiler',
			'Frisange',
			'Bourscheid',
			'Boulaide',
			'Béreldange',
			'Senningerberg',
			'Vichten',
			'Rollingen',
			'Mompach',
			'Clemency',
			'Biwer',
			'Wahl',
			'Useldange',
			'Uebersyren',
			'Rodange',
			'Erpeldange',
			'Soleuvre',
			'Luxembourg',
			'Koerich',
			'Itzig',
			'Dudelange',
		],
	},
	'Macau, China': { country: 'Macau, China', cities: ['Macau'] },
	'Macedonia, The former Yugoslav Rep. of': {
		country: 'Macedonia, The former Yugoslav Rep. of',
		cities: [
			'Resen',
			'Radishani',
			'Obršani',
			'Ivankovci',
			'Forino',
			'Capari',
			'Blatec',
			'Tetovo',
			'Sedlarevo',
			'Kadino',
			'Јурумлери',
			'Delcevo',
			'Bosilovo',
			'Studeničani',
			'Stojakovo',
			'Pirava',
			'Bogdanci',
			'Bedinje',
			'Čair',
			'Centar Župa',
			'Mešeišta',
			'Labunista',
			'Kriva Palanka',
			'Ilovica',
			'Dolna Banjica',
			'Bojane',
			'Žitoše',
			'Rankovce',
			'Miravci',
			'Kondovo',
			'Gorno Orizari',
			'Dolno Palčište',
			'Demir Kapija',
			'Debar',
			'Šuto Orizare',
			'Sušica',
			'Star Dojran',
			'Batinci',
			'Vrapčište',
			'Valandovo',
			'Srbica',
			'Sekirnik',
			'Krušopek',
			'Dračevo',
			'Dobruševo',
			'Čelopek',
			'Poroj',
			'Zelenikovo',
			'Vevčani',
			'Plasnica',
			'Ognjaci',
			'Lipkovo',
			'Dolneni',
			'Dobri Dol',
			'Vrutok',
			'Strelci',
			'Dolno Srpci',
			'Radolista',
			'Otlja',
			'Mogila',
			'Makedonska Kamenica',
			'Gostivar',
			'Čaška',
			'Tuin',
			'Sopište',
			'Novaci',
			'Marino',
			'Lažani',
			'Kamenjane',
			'Jegunovce',
			'Jankovec',
			'Drugovo',
			'Delogožda',
			'Чучер - Сандево',
			'Makedonski Brod',
			'Bogovinje',
			'Velesta',
			'Петровец',
			'Ohrid',
			'Novo Selo',
			'Strimnica',
			'Ilinden',
			'Pehčevo',
			'Belčišta',
			'Sredno Konjari',
			'Šipkovica',
			'Oktisi',
			'Oblesevo',
			'Lojane',
			'Češinovo',
			'Zajas',
			'Vladimirovo',
			'Veles',
			'Shtip',
			'Slatino',
			'Kukurečani',
			'Kanatlarci',
			'Dolno Količani',
			'Kisela Voda',
			'Romanovci',
			'Kochani',
			'Golema Rečica',
			'Gevgelija',
			'Dolno Orizari',
			'Vaksince',
			'Tearce',
			'Sveti Nikole',
			'Morani',
			'Dobrošte',
			'Markov Grad',
			'Skopje',
			'Krani',
			'Grnčari',
			'Gradec',
			'Gjorče Petro',
			'Vataša',
			'Nerašte',
			'Miladinovci',
			'Cresevo',
			'Bitola',
			'Butel',
			'Zrnovci',
			'Veljusa',
			'Saraj',
			'Rusinovo',
			'Pirok',
			'Gurgurnica',
			'Brvenica',
			'Bogomila',
			'Vasilevo',
			'Srbinovo',
			'Rosoman',
			'Karbinci',
			'Gorobinci',
			'Gorno Orizari',
			'Desovo',
			'Cegrane',
			'Bulacani',
			'Bučin',
			'Dobarce',
			'Zelenikovo',
			'Usje',
			'Sopotnica',
			'Prilep',
			'Orizari',
			'Negotino',
			'Miletino',
			'Izvor',
			'Beranci',
			'Slepče',
			'Lisičani',
			'Kumanovo',
			'Krivogashtani',
			'Gradsko',
			'Gradec',
			'Vratnica',
			'Selce',
			'Pršovce',
			'Podareš',
			'Mislesevo',
			'Konče',
			'Kičevo',
			'Bukovik',
			'Berovo',
			'Арачиново',
			'Topolčani',
			'Lukovo',
			'Kratovo',
			'Struga',
			'Matejce',
			'Larce',
			'Kosel',
			'Grčec',
			'Zletovo',
			'Vraneštica',
			'Samokov',
			'Radovis',
			'Demir Hisar',
			'Logovardi',
			'Crnilište',
			'Bistrica',
			'Zelino',
			'Strumica',
			'Слупчане',
			'Rostusa',
			'Probishtip',
			'Orashac',
			'Oraovica',
			'Murtino',
			'Ljubin',
			'Kavadarci',
			'Lozovo',
			'Vinica',
			'Старо Нагоричане',
			'Negotino',
			'Kuklis',
			'Krusevo',
			'Идризово',
			'Džepčište',
		],
	},
	Madagascar: {
		country: 'Madagascar',
		cities: [
			'Sadabe',
			'Ifatsy',
			'Bemaitso',
			'Ampanihy',
			'Tranovaho',
			'Nosy Varika',
			'Mahavelona',
			'Maevatanana',
			'Faratsiho',
			'Anakao',
			'Ampasimanolotra',
			'Ambohitseheno',
			'Morondava',
			'Toliara',
			'Andovoranto',
			'Ambodifotatra',
			'Ambalasoa',
			'Tsaratanana',
			'Moramanga',
			'Ikongo',
			'Fianarantsoa',
			'Antanifotsy',
			'Tôlanaro',
			'Sambaina',
			'Miandrivazo',
			'Mantasoa',
			'Ampahana',
			'Vohimanitra',
			'Soanierana Ivongo',
			'Miadanandriana',
			'Mandritsara',
			'Antsirabe Afovoany',
			'Ambodivoara',
			'Amboasary',
			'Antsampanimahazo',
			'Vavatenina',
			'Sahavato',
			'Ankazobe',
			'Ikalamavony',
			'Fandrandava',
			'Antsohimbondrona',
			'Mananara',
			'Arivonimamo',
			'Alarobia',
			'Miarinarivo',
			'Manato',
			'Fandriana',
			'Antsirabe',
			'Ambovombe',
			'Soavinandriana',
			'Marolambo',
			'Mahanoro',
			'Belo sur Tsiribihina',
			'Bealanana',
			'Antsohihy',
			'Ankazoabo',
			'Vondrozo',
			'Kopoky',
			'Ifanadiana',
			'Ankazondandy',
			'Hell-Ville',
			'Andapa',
			'Ambatolaona',
			'Ambato Boeny',
			'Ambanja',
			'Toamasina',
			'Antsiranana',
			'Antanimena',
			'Antalaha',
			'Ambilobe',
			'Ambarakaraka',
			'Savana',
			'Sadjoavato',
			'Maroantsetra',
			'Behompy',
			'Anjepy',
			'Ambohitrolomahitsy',
			'Vohipaho',
			'Ranohira',
			'Maintirano',
			'Andramasina',
			'Amparafaravola',
			'Ambatondrazaka',
			'Ambalavao',
			'Tsiombe',
			'Ihosy',
			'Fenoarivo Atsinanana',
			'Beteza',
			'Ambatolampy',
			'Merikanjaka',
			'Mahalina',
			'Amboasary',
			'Ambatofinandrahana',
			'Manakara',
			'Mahajanga',
			'Andilamena',
			'Amboanjo',
			'Sahatavy',
			'Manjakandriana',
			'Ambositra',
			'Vohibinany',
			'Soanindrariny',
			'Miandrarivo',
			'Manja',
			'Farafangana',
			'Antakotako',
			'Anandravy',
			'Antananarivo',
			'Sitampiky',
			'Nandihizana',
			'Mahabe',
			'Fenoarivo Be',
			'Betioky',
			'Betafo',
			'Anjozorobe',
			'Ambohitrandriamanitra',
			'Tsiroanomandidy',
			'Sakaraha',
			'Marovoay',
			'Beroroha',
			'Vangaindrano',
			'Sambava',
			'Mananjary',
			'Beloha',
		],
	},
	Malawi: {
		country: 'Malawi',
		cities: [
			'Ntchisi',
			'Mangochi',
			'Chikwawa',
			'Mponela',
			'Nkhata Bay',
			'Neno',
			'Mulanje',
			'Blantyre',
			'Phalombe',
			'Balaka',
			'Chiradzulu',
			'Machinga',
			'Chitipa',
			'Luchenza',
			'Liwonde',
			'Lilongwe',
			'Likoma',
			'Nkhotakota',
			'Mchinji',
			'Thyolo',
			'Dowa',
			'Makata',
			'Rumphi',
			'Livingstonia',
			'Dedza',
			'Karonga',
			'Zomba',
			'Mzimba',
			'Mwanza',
			'Mzuzu',
			'Monkey Bay',
			'Salima',
			'Nsanje',
			'Kasungu',
			'Chipoka',
			'Ntcheu',
		],
	},
	Malaysia: {
		country: 'Malaysia',
		cities: [
			'Kulai',
			'Labuan',
			'Beaufort',
			'Sungai Udang',
			'Kapit',
			'Lawas',
			'Bentong Town',
			'Kertih',
			'Taiping',
			'Buloh Kasap',
			'George Town',
			'Kuala Lumpur',
			'Bakri',
			'Kota Bharu',
			'Kuang',
			'Kampong Masjid Tanah',
			'Batu Berendam',
			'Malacca',
			'Sungai Besar',
			'Tanah Rata',
			'Permatang Kuching',
			'Pantai Remis',
			'Juru',
			'Batu Arang',
			'Alor Gajah',
			'Kuala Kedah',
			'Kampung Baharu Nilai',
			'Yong Peng',
			'Kulim',
			'Pelabuhan Klang',
			'Kuala Pilah',
			'Lumut',
			'Bagan Serai',
			'Banting',
			'Jenjarum',
			'Ketereh',
			'Putrajaya',
			'Batu Pahat',
			'Pontian Kechil',
			'Muar',
			'Klang',
			'Jertih',
			'Marudi',
			'Ayer Hangat',
			'Taman Senai',
			'Johor Bahru',
			'Ranau',
			'Butterworth',
			'Kepala Batas',
			'Pulai Chondong',
			'Simanggang',
			'Kampung Sungai Ara',
			'Kluang',
			'Semenyih',
			'Kampung Bukit Tinggi, Bentong',
			'Bidur',
			'Sarikei',
			'Peringat',
			'Putra Heights',
			'Skudai',
			'Tangkak',
			'Kuala Terengganu',
			'Tampin',
			'Kampung Tekek',
			'Kangar',
			'Tumpat',
			'Sungai Pelek New Village',
			'Long Ampan Aing or Abanang',
			'Cukai',
			'Putatan',
			'Mentekab',
			'Rawang',
			'Sabak Bernam',
			'Kuala Teriang',
			'Petaling Jaya',
			'Batu Gajah',
			'Mukah',
			'Tanah Merah',
			'Pasir Mas',
			'Ladang Seri Kundang',
			'Kota Tinggi',
			'Batang Berjuntai',
			'Kuala Lipis',
			'Temerluh',
			'Gurun',
			'Jitra',
			'Kampong Dungun',
			'Kampung Bukit Baharu',
			'Kampung Ayer Molek',
			'Kampung Kok',
			'Bandar Labuan',
			'Shah Alam',
			'Keningau',
			'Paka',
			'Tanjung Sepat',
			'Tawau',
			'Ipoh',
			'Serendah',
			'Kuantan',
			'Padang Mat Sirat',
			'Kampung Baru Subang',
			'Subang Jaya',
			'Kota Kinabalu',
			'Bahau',
			'Alor Setar',
			'Kampung Batu Feringgi',
			'Kampung Kuala Besut',
			'Lahad Datu',
			'Simpang Empat',
			'Kuala Kangsar',
			'Bukit Mertajam',
			'Sibu',
			'Miri',
			'Tanjung Tokong',
			'Kelapa Sawit',
			'Bagan Pulau Ketam',
			'Pekan Nenas',
			'Segamat',
			'Kampung Ayer Keroh',
			'Batu Feringgi',
			'Tasek Glugor',
			'Sungai Petani',
			'Kuala Besut',
			'Bemban',
			'Donggongon',
			'Kinarut',
			'Seremban',
			'Ampang',
			'Kampar',
			'Tapah Road',
			'Kuala Perlis',
			'Limbang',
			'Telaga Batu',
			'Pulau Sebang',
			'Kampung Tanjung Karang',
			'Teluk Intan',
			'Bintulu',
			'Kampung Simpang Renggam',
			'Kuah',
			'Papar',
			'Pekan',
			'Nibong Tebal',
			'Parit Buntar',
			'Klebang Besar',
			'Kampong Kadok',
			'Jerantut',
			'Kampung Pasir Gudang Baru',
			'Mersing',
			'Gua Musang',
			'Perai',
			'Kota Belud',
			'Kampong Pangkal Kalong',
			'Bukit Rambai',
			'Data Kakus',
			'Parit Raja',
			'Labis',
			'Bedong',
			'Kampung Kilim',
			'Semporna',
			'Marang',
			'Port Dickson',
			'Sepang',
			'Kuching',
			'Lidung Jelo',
			'Chaah',
			'Kuala Selangor',
			'Ulu Tiram',
			'Kuala Sungai Baru',
			'Kampong Baharu Balakong',
			'Kampung Lemal',
			'Taman Rajawali',
			'Raub',
			'Sandakan',
			'Kudat',
			'Pantai Cenang',
		],
	},
	Maldives: {
		country: 'Maldives',
		cities: [
			'Hulhumale',
			'Guraidhoo',
			'Dhihdhoo',
			'Eydhafushi',
			'Fuvahmulah',
			'Madifushi',
			'Kudahuvadhoo',
			'Gadhdhoo',
			'Hithadhoo',
			'Vilufushi',
			'Open Stage',
			'Fonadhoo',
			'Meedhoo',
			'Mahibadhoo',
			'Kulhudhuffushi',
			'Naifaru',
			'Muli',
			'Nilandhoo',
			'Funadhoo',
			'Vaadhoo',
			'Viligili',
			'Guraidhoo',
			'Thinadhoo',
			'Felidhoo',
			'Maafushi',
			'Male',
			'Manadhoo',
			'Veymandoo',
			'Un’goofaaru',
		],
	},
	Mali: {
		country: 'Mali',
		cities: [
			'Yélimané',
			'Koro',
			'Koula',
			'Kinmparana',
			'Kidal',
			'Goundam',
			'Bourem',
			'Figuira-Toma',
			'Kayes',
			'Djénné',
			'Diré',
			'Sikasso',
			'Markala',
			'Timbuktu',
			'San',
			'Mopti',
			'Kènènkoun',
			'Gourma Rharous',
			'Leleni',
			'Dioila',
			'Sagala',
			'Ntossoni',
			'Koutiala',
			'Kadiolo',
			'Doumba',
			'Douentza',
			'Tessalit',
			'Nara',
			'Abeïbara',
			'Ténenkou',
			'Diéma',
			'Araouane',
			'Ségou',
			'Kita',
			'Bafoulabé',
			'Werekela',
			'Kolondiéba',
			'Bougouni',
			'Youwarou',
			'Yorosso',
			'Sagalo',
			'Bandiagara',
			'Yanfolila',
			'Gao',
			'Kati',
			'Ansongo',
			'Inékar',
			'Niafunké',
			'Kokofata',
			'Bamako',
			'Taoudenni',
			'Bla',
			'Ti-n-Essako',
			'Nioro',
			"N'Débougou",
			'Molodo',
			'Koulikoro',
			'Ké-Macina',
			'Bankass',
			'Sokolo',
			'Barouéli',
			'Tominian',
			'Niono',
			'Ménaka',
			'Kolokani',
			'Kangaba',
			'Banamba',
		],
	},
	Malta: {
		country: 'Malta',
		cities: [
			'Luqa',
			'Cospicua',
			'Żebbuġ',
			'Ta’ Xbiex',
			'Hal Gharghur',
			'Paola',
			'Imsida',
			'Mosta',
			'Imġarr',
			'Floriana',
			'Żurrieq',
			'Imdina',
			'Gżira',
			'Birkirkara',
			'Marsaxlokk',
			'San Ġiljan',
			'Imtarfa',
			'Xgħajra',
			'Ħamrun',
			'San Pawl il-Baħar',
			'Rabat',
			'Mqabba',
			'Għarb',
			'Balzan',
			'Siġġiewi',
			'San Ġwann',
			'Gudja',
			'Marsaskala',
			'Marsa',
			'Għajnsielem',
			'Attard',
			'Pembroke',
			'San Lawrenz',
			'Mellieħa',
			'Vittoriosa',
			'Sannat',
			'Qala',
			'Kerċem',
			'Kalkara',
			'Dingli',
			'Santa Venera',
			'Naxxar',
			'Lija',
			'Kirkop',
			'Xewkija',
			'Nadur',
			'Sliema',
			'Qrendi',
			'Valletta',
			'Qormi',
			'Senglea',
			'Għaxaq',
			'Victoria',
			'Pietà',
			'Swieqi',
			'Safi',
			'Munxar',
			'Żejtun',
			'Xagħra',
			'Mġarr',
			'Birżebbuġa',
			'Haz-Zebbug',
			'Żabbar',
			'L-Iklin',
			'Santa Luċija',
			'Fgura',
			'Fontana',
			'Tarxien',
		],
	},
	'Marshall Islands': {
		country: 'Marshall Islands',
		cities: [
			'Loen',
			'Lib',
			'Kili',
			'Ailuk',
			'Lae',
			'Enewetak',
			'Namdrik',
			'Taroa',
			'RMI Capitol',
			'Mili',
			'Arno',
			'Ebaye',
			'Aur',
			'Likiep',
			'Majuro',
			'Rongelap',
			'Wotje',
			'Mejit',
			'Jabat',
			'Utrik',
			'Ujae',
			'Wotho',
			'Ebon',
			'Airuk',
			'Jabor',
		],
	},
	Martinique: {
		country: 'Martinique',
		cities: [
			"L'Ajoupa-Bouillon",
			'Rivière-Pilote',
			'Ducos',
			'Saint-Pierre',
			'Sainte-Marie',
			'Le Vauclin',
			'Saint-Esprit',
			'Saint-Joseph',
			'Le Marin',
			'Le Lamentin',
			'Le Lorrain',
			'Les Trois-Îlets',
			'Le Robert',
			'Basse-Pointe',
			'Le Morne-Rouge',
			'Le François',
			'Fort-de-France',
			'La Trinité',
			'Le Gros-Morne',
			'Sainte-Luce',
		],
	},
	Mauritania: {
		country: 'Mauritania',
		cities: [
			'Boulenouar',
			'El Gheddiya',
			'Tevragh Zeina',
			'Dar Naim',
			'Tidjikja',
			'Kiffa',
			'Akjoujt',
			'Arafat',
			'Tîchît',
			'Bîr Mogreïn',
			'Sélibaby',
			'Nouakchott',
			'Choûm',
			'Zouerate',
			'’Elb el Jmel',
			'Aleg',
			'KeurMassène',
			'Nouadhibou',
			'Bogué',
			'Tamchekket',
			'Néma',
			'Çangarâfa',
			'Timbedgha',
			'Rosso',
			'Ouâd Nâga',
			'Nbeïka',
			'Tékane',
			'Fdérik',
			'Ndiago',
			'Aioun',
			'Chingueṭṭi',
			'Barkéwol',
			'Kaédi',
			'Atar',
			'Nouâmghâr',
		],
	},
	Mauritius: {
		country: 'Mauritius',
		cities: [
			'Port Louis',
			'Olivia',
			'New Grove',
			'Grande Rivière Sud Est',
			'Grand Gaube',
			'Souillac',
			'Curepipe',
			'The Vale',
			'Moka',
			'Grand Baie',
			'Crève Coeur',
			'Beau Vallon',
			'Albion',
			'Petit Raffray',
			'Brisée Verdière',
			'Cluny',
			'Amaury',
			'Sebastopol',
			'Rivière du Rempart',
			'Plaine Magnien',
			'Pamplemousses',
			'Piton',
			'Dagotière',
			'Beau Bassin-Rose Hill',
			'Cottage',
			'Dubreuil',
			'Quartier Militaire',
			'Espérance Trébuchet',
			'Vacoas',
			'Saint Pierre',
			'Quatre Bornes',
			'Plaine des Papayes',
			'Camp Ithier',
			'Ebene',
			'Petite Case Noyale',
			'Rose Belle',
			'Rivière des Anguilles',
			'Petite Rivière',
			'Cargados Carajos',
			'Morcellement Saint André',
			'Clémencia',
			'Le Hochet',
			'Roches Noire',
			'Calebasses',
			'Tamarin',
			'Bois des Amourettes',
			'Congomah',
			'Triolet',
			'Poste de Flacq',
			'Long Mountain',
			'Fond du Sac',
			'Camp Diable',
			'Mapou',
			'Mare La Chaux',
			'Ecroignard',
			'Arsenal',
			'Saint Julien',
			'Vingt Cinq',
			'Grande Rivière Noire',
			'Flic en Flac',
			'Laventure',
			'Bambous Virieux',
			'Roche Terre',
			'Montagne Blanche',
			'Grand Bois',
			'Camp Thorel',
			'Providence',
			'Pailles',
			'Notre Dame',
			'Mahébourg',
			'Goodlands',
			'Bon Accueil',
			'Bel Air Rivière Sèche',
			'Bambous',
			'Saint Aubin',
			'Centre de Flacq',
			'Gros Cailloux',
			'Nouvelle France',
			'Midlands',
			'Grand Sable',
			'Verdun',
			'Chamouny',
			'Quatre Cocos',
			'Surinam',
			'Pointe aux Piments',
			'Saint Hubert',
			'Queen Victoria',
			'Quatre Soeurs',
			'Chemin Grenier',
			'Cascavelle',
			'Melrose',
			'Camp de Masque',
			'Plaines des Roches',
			'Port Mathurin',
			'Baie aux Huîtres',
			'Terre Rouge',
			'Lalmatie',
			'Cap Malheureux',
		],
	},
	Mexico: {
		country: 'Mexico',
		cities: [
			'López Rayón',
			'Nueva Apolonia',
			'Magdaleno Cedillo',
			'Higinio Olivo',
			'Zitlala',
			'Zinacantán',
			'Zapoapan de Amapan',
			'Zacatlán',
			'Villa de Zaachila',
			'Xalpatlahuac',
			'El Volador',
			'Tesechoacan',
			'Tultitlán de Mariano Escobedo',
			'Tonayán',
			'Tlatenchi',
			'Tlalpan',
			'Tizayuca',
			'Tepanco',
			'Tecpatán',
			'Tamuín',
			'Solosuchiapa',
			'Zacatepec',
			'Texcaltitlán',
			'Pinotepa Nacional',
			'Zaniza',
			'Santa María del Monte',
			'Santa María Chachoápam',
			'Santa María Huamelula',
			'Santa Isabel Cholula',
			'Santa Catarina Tayata',
			'Santa Catarina',
			'Santa Ana Coatepec',
			'Tecomaxtlahuaca',
			'San Raymundo Jalpam',
			'San Pedro Taviche',
			'Jaltepetongo',
			'San Pedro el Alto',
			'San Pablo de las Tunas',
			'San Pablo Autopan',
			'Zinacantepec',
			'Villa Talea de Castro',
			'San Juan Chilateca',
			'San Juan Atepec',
			'San José Tlacuitlapan',
			'San José las Chicharras',
			'San Gabriel',
			'San Francisco Ozomatlán',
			'San Dionisio Ocotlán',
			'San Buenaventura',
			'San Buenaventura',
			'San Bartolo Tutotepec',
			'San Antonio Zaragoza',
			'San Antonio de la Cal',
			'Quiamoloapan',
			'Pueblo Nuevo',
			'Pozuelos',
			'Popola',
			'Pijijiapan',
			'Palmillas',
			'Palmillas',
			'Orilla del Monte',
			'Ocoyoacac',
			'Nuevo Morelos',
			'Motzorongo',
			'Mezquititlán',
			'Metlapa',
			'Maxcanú',
			'Mata Loma',
			'Mahuixtlan',
			'Almagres',
			'Lázaro Cárdenas',
			'Las Lomas',
			'Landero y Coss',
			'Jojutla',
			'Jicolapa',
			'Ixhuatlancillo',
			'Ignacio Zaragoza',
			'Huichapan',
			'Hueypoxtla',
			'Tancanhuitz',
			'Euán',
			'Eloxochitlán',
			'El Higo',
			'El Ciruelo',
			'Ejido Nuevo',
			'Cuncunul',
			'Cuichapa',
			'Coxquihui',
			'Comapa',
			'Chuburná',
			'Chilpancingo',
			'Chicoasén',
			'Chichén-Itzá',
			'Chalcatzingo',
			'Cerritos',
			'Los Cavazos',
			'Caucel',
			'Cabeza de Toro',
			'Bernal',
			'Balancán',
			'Axutla',
			'Soledad Atzompa',
			'Atoyatempan',
			'Atotonilco de Tula',
			'Atlacomulco de Fabela',
			'Santa María Atexcac',
			'Atempan',
			'Aquila',
			'Apaxtla de Castrejón',
			'Amatenango del Valle',
			'Villa Aldama',
			'Ahuacuotzingo',
			'Congregación Calles',
			'San Cristóbal Honduras',
			'Santa Lucía Teotepec',
			'Santa Cruz Yucucani',
			'Santa María del Rosario',
			'Tierra Blanca',
			'Huamúchil',
			'Jalupa',
			'Huapacal 1ra. Sección',
			'Arena de Hidalgo',
			'Huitzitzilingo',
			'Julián Villagrán',
			'Colonia Teñhe',
			'Río Seco Puente de Doria',
			'Santa María Asunción',
			'Cañada',
			'La Trinidad Tenexyecac',
			'Huamantla',
			'San Juan Huactzinco',
			'San Damián Texoloc',
			'Loma Bonita',
			'El Mango',
			'Pasté',
			'Chacaljocóm',
			'Miguel Hidalgo',
			'Unión Buena Vista',
			'Azteca',
			'Las Brisas',
			'San Marcos Huixtoco',
			'Cuautlacingo',
			'San Luis Anáhuac (Toriles)',
			'Tlaxpanaloya',
			'Máximo Serdán',
			'San Martín Esperilla',
			'Dolores Hidalgo',
			'Santa Rosa',
			'Tepetzitzintla',
			'Santa Cruz Cuautomatitla',
			'Tehuitzingo',
			'San Juan de Dios (Naranjas de Dios)',
			'Yetla',
			'Leona Vicario',
			'Alborada',
			'Estanzuela',
			'Los Altos',
			'San Rafael Río Seco',
			'Saltillo',
			'Plan de Ayala',
			'La Horqueta (Poblado Doce)',
			'Villa Guerrero',
			'Jesús María',
			'Tercera Manzana de Zaragoza',
			'Las Manzanas',
			'El Cerrillo Vista Hermosa',
			'San Francisco Cuaxusco',
			'San José el Llanito',
			'Santiago Tejocotillos',
			'Santa Catarina',
			'La Ballena',
			'Dulce Grande',
			'San Francisco de Tacuichamona',
			'Las Aguamitas',
			'Ciudad de Villaldama',
			'Valle de Allende',
			'Unión de Tula',
			'Tetitlán',
			'Tepechitlán',
			'Tejupilco de Hidalgo',
			'Serrano',
			'Sáric',
			'Santa Anita',
			'San Sebastián del Oeste',
			'San Miguel Xoltepec',
			'San Marcos',
			'San Luis',
			'San Juan de Abajo',
			'San José el Alto',
			'San Jeronimito',
			'San Isidro',
			'San Gregorio',
			'San Felipe de Jesús',
			'San Elías',
			'San Cristóbal',
			'San Antonio de Romerillo',
			'Rincón de Tamayo',
			'Purísima de Bustos',
			'Praxedis G. Guerrero',
			'Pitiquito',
			'Palmito del Verde',
			'Milagros',
			'Mezquital del Oro',
			'Mezcala',
			'Mesa Grande',
			'Matanzas',
			'Marroquín',
			'Marcol',
			'Los Limones',
			'Los Álamos',
			'Lo Arado',
			'La Vega',
			'La Trinidad',
			'Las Quemazones',
			'La Quemada',
			'La Piedad',
			'La Palma',
			'La Noria',
			'Guadalupe',
			'Ciudad General Escobedo',
			'General Cepeda',
			'Galeana',
			'Las Varas (Estación Babícora)',
			'Escuinapa',
			'El Tule',
			'El Sabino',
			'El Mentidero',
			'Ejido Toluca',
			'Cumpas',
			'Cuautla',
			'Cruz Blanca',
			'Primera Fracción de Crespo',
			'Coyuca de Catalán',
			'Coyotillos',
			'La Concepción',
			'Charay',
			'Celaya',
			'Capácuaro',
			'Cananea',
			'Camichín de Jauja',
			'Buenavista',
			'San Martín de Bolaños',
			'Bermejillo',
			'Basúchil',
			'Bácum',
			'Bachoco',
			'Amatitán',
			'Allende',
			'Ahuisculco',
			'Adolfo Ruíz Cortínes',
			'Ciudad Satélite del Norte',
			'La Sabana',
			'Río Laja',
			'Guadalupe del Monte',
			'Rincón del Centeno',
			'San Vicente de Flores',
			'Valencianita',
			'San Javier',
			'San José de Llanos',
			'San Antonio Molinos',
			'Tepetongo',
			'Cuto del Porvenir',
			'La Mojonera',
			'El Espinal',
			'El Escondido',
			'San José Buenavista',
			'Ejido Ojo de Agua',
			'Fraccionamiento Ciudad Olmeca',
			'Venceremos',
			'Villa Luvianos',
			'La Aldea',
			'Héctor Caballero',
			'Quinto Barrio (Ejido Cahuacán)',
			'Lomas de Altavista',
			'2da. Sección de Medio Monte',
			'Ex-Viñedos Guadalupe',
			'PRI Chacón',
			'Samarkanda',
			'Unidad Habitacional Rinconada Acolapa',
			'San Isidro Vista Hermosa',
			'Prados de San Juan',
			'Llano Suchiapa',
			'Cherán Atzicuirín (Cheranástico)',
			'Paso de Arena',
			'Acatepec',
			'Santa Catarina Villanueva',
			'Doctor Rodulfo Figueroa (Tierra Blanca)',
			'La Mesa de Chosto',
			'Ladrillera (Entronque Pesquería)',
			'San Buenaventura Atempan',
			'San Bartolomé Hueyapan',
			'Olintepec',
			'Plan de Agua Prieta',
			'El Encanto',
			'Solalpan 1ra. Sección',
			'El Jazmín',
			'San Lorenzo Joya de Rodríguez',
			'Melitón Albáñez Domínguez',
			'Vicente Guerrero',
			'Veintiuno de Agosto',
			'Colonia la Libertad',
			'Fraccionamiento del Magisterio Tulancinguense',
			'Fraccionamiento la Noria',
			'Tanhuixco',
			'Barrio del Cajón',
			'Rancho de las Lomas',
			'San Antonio de Corrales',
			'Bautista Chico',
			'Estrella de Belén',
			'La Isla (Kilómetro 10)',
			'San Cristóbal Chacón',
			'Agua Señora',
			'Sección del Cerrito',
			'Barranca de Otates (Barranca de Otatán)',
			'El Roble',
			'Zoyotla',
			'La Nueva Era',
			'Álvaro Obregón 2da. Sección (El Lechugal)',
			'Puentecillas (Tercera Manzana de Zirahuato)',
			'Santa Rosa Caxtlahuaca',
			'Colonia Rafael Corrales Ayala',
			'San Pedro Tlapacoyan',
			'El Nigromante',
			'Tlamacazapa',
			'Ticul',
			'Santa María Tatetla',
			'Tepeitic',
			'Tekom',
			'Sihochac',
			'San Vicente Coyotepec',
			'Santiago Nejapilla',
			'Santiago Ixcuintepec',
			'Santiago Amoltepec',
			'Santander Jiménez',
			'Petapa',
			'Ayoquezco de Aldama',
			'San Rafael Zaragoza',
			'San Pedro Los Baños',
			'San Nicolás',
			'San Miguel Oxtotilpan',
			'San Miguel Almoloyan',
			'San Mateo',
			'Totoltepec',
			'Texmelucan',
			'San José Chiapa',
			'San José del Progreso',
			'San Jerónimo Tecóatl',
			'San Carlos',
			'San Baltazar Atlimeyaya',
			'El Rosario',
			'Rioverde',
			'El Refugio',
			'Santa María Quiegolani',
			'Pueblo Nuevo',
			'Piedras Negras',
			'Peto',
			'Paraiso',
			'Pánuco',
			'Palo Blanco',
			'Pahuatlán de Valle',
			'Oxkutzkab',
			'Otates',
			'Ostuacán',
			'Ocotlán de Betancourt',
			'Nepantla de Sor Juana Inés de la Cruz',
			'Necaxa',
			'Montecillos',
			'Metlaltoyuca',
			'Mata Tenatito (Casco Hacienda)',
			'Manuel Lazos',
			'Magdalena Ocotlán',
			'Reyes Acozac',
			'Lomas del Real',
			'Reforma',
			'Gilberto Camacho',
			'Ex-hacienda de Xalpa',
			'Jalcomulco',
			'1ra. Sección de Izapa',
			'Iztapalapa',
			'Ixmiquilpan',
			'Huautla',
			'Huajintlán',
			'Hidalgo',
			'Hampolol',
			'El Arenal',
			'Villa Díaz Ordaz',
			'Cuauchichinola',
			'Cuatolol',
			'Coyotillos',
			'Colucan',
			'Ciudad Madero',
			'Cholul Cantón',
			'Chilcuautla',
			'Chignahuapan',
			'Chichatla',
			'Chiautla',
			'Chamulapita',
			'Cepeda',
			'Canalejas',
			'Caltimacan',
			'Calkiní',
			'Calderitas',
			'Ayotuxtla',
			'Axapusco',
			'Ángel R. Cabada',
			'Anenecuilco',
			'Amatlán',
			'Ahuatepec Pueblo',
			'Acoyotla',
			'Acolman de Netzahualcóyotl',
			'Achichipico',
			'Huecahuasco',
			'Lázaro Cárdenas',
			'San Antonio Tepetlapa',
			'San José Piedras Negras',
			'San Juan Ñumí',
			'Silacayoápam',
			'Tamazola',
			'San Juan Tabaá',
			'El Paraíso',
			'Encinal Colorado',
			'San Nicolás Quialana',
			'La Unión',
			'Libertad',
			'Tlalchiyahualica',
			'Tenango',
			'Palmillas',
			'Carboneras',
			'Vicente Guerrero (San Javier)',
			'Atlangatepec',
			'San Simeón Xipetzingo',
			'Villa de El Carmen Tequexquitla',
			'Tzajalá',
			'Patosil',
			'La Laguna',
			'Frontera Corozal',
			'La Esperanza',
			'Efraín A. Gutiérrez',
			'Villa Hidalgo',
			'Agrónomos Mexicanos',
			'Tierra y Libertad',
			'San Antonio Tlaltecahuacán',
			'Santiago Tepetitlán',
			'San Pablo Ixquitlán',
			'San Juan Daxthi',
			'Xochicuautla',
			'Pericotepec',
			'Michapa',
			'San Pablo Atzompa',
			'Emiliano Zapata',
			'La Presumida',
			'Carlos A. Madrazo',
			'Sonora',
			'Loma de los Carmona',
			'Puente Jula',
			'Rincón de Buena Vista',
			'Villa Unión',
			'Tenantitla',
			'Loma Bonita',
			'Vista Hermosa',
			'Santa Rosa de Lima',
			'Tejalpa',
			'San Lorenzo Tlacotepec',
			'San José del Tunal',
			'Agua Escondida',
			'La Esperanza',
			'San Antonio del Puente',
			'La Cabecera',
			'Santa María Totoltepec',
			'Venustiano Carranza',
			'Zacamulpa',
			'Zacamulpa Tlalmimilolpan',
			'San Francisco Xochicuautla',
			'Santa María Magdalena',
			'El Obraje',
			'La Zacatecana',
			'General Enrique Estrada',
			'Sain Bajo',
			'Emiliano Zapata',
			'Tecualilla',
			'El Cambio',
			'Lázaro Cárdenas',
			'Estación Pescaderos',
			'Sauta',
			'Yécora',
			'Virginias',
			'Villa Morelos',
			'Villa Hidalgo',
			'Veinte de Noviembre',
			'Valle de Guadalupe',
			'Uruachi',
			'Tepetongo',
			'Tecualtitán',
			'Teacapan',
			'Tancítaro',
			'Tacoaleche',
			'Soyopa',
			'Santa Rosa',
			'Santa Rita',
			'Santa Fe',
			'Santa Ana Amatlán',
			'San Pedro de la Cueva',
			'San Miguel Totolapan',
			'San Miguel de Horcasitas',
			'San Lucas del Pulque',
			'San José de las Flores',
			'San Ignacio',
			'Pichátaro',
			'San Francisco de Arriba',
			'Tlaltenango de Sánchez Román',
			'Orranteño',
			'Puerta de Mangos',
			'Pedernales',
			'Parangarico',
			'Palos Verdes',
			'Opodepe',
			'Nuevo San Juan Parangaricutiro',
			'Moris',
			'Bejucos',
			'Lombardía',
			'Loma de Guamúchil',
			'Las Juntas',
			'La Paz',
			'Lagunillas',
			'La Esperanza',
			'La Cruz',
			'Ceja de Bravo',
			'La Cañada',
			'La Barca',
			'Ignacio Ramírez',
			'Heroica Guaymas',
			'Guanajuatillo',
			'Guadalupe Victoria',
			'Guadalupe Septién',
			'Transporte',
			'El Rincón',
			'El Consuelo',
			'Campo Pesquero el Colorado',
			'La Despensa',
			'Corerepe',
			'Comanjá',
			'Ciénega de Flores',
			'Cerro Agudo',
			'Carboneras',
			'Caltzontzín',
			'Calamanda',
			'Bacubirito',
			'Bacerac',
			'Antonio Amaro',
			'Ansihuacuaro',
			'Aguascalientes',
			'San Antonio de los Horcones',
			'Santa Anita',
			'Palo Colorado',
			'Santa Rosa de Lima',
			'El Zapote',
			'La Lobera',
			'Otates',
			'San Antonio Calichar',
			'El Copalillo',
			'Plan Guanajuato (La Sandía)',
			'Las Liebres',
			'Potrerillos (Guanajal)',
			'El Sabino',
			'Capula',
			'Santa Juana',
			'San Jerónimo',
			'Cerritos de Cárdenas',
			'San Nicolás el Oro',
			'Buenavista',
			'Zacapuato',
			'Colonia Luces en el Mar',
			'El Colomo',
			'El Lindero',
			'San Antonio Tecómitl',
			'Valle Dorado Inn',
			'Misión San Pablo',
			'Los Ruiseñores',
			'Kilómetro 30',
			'La Pastoría',
			'Tlamaco (San Gerónimo Tlamaco)',
			'Belén Atzitzimititlán',
			'Río Medio [Granja]',
			'San Martín Coapaxtongo',
			'Fraccionamiento Misión de San Javier',
			'Poblado Número Cinco',
			'Charco Blanco',
			'Fraccionamiento Rinconada del Valle',
			'Metepec',
			'Llano de la Y',
			'San Gregorio Aztotoacan',
			'Paseos del Pedregal',
			'San Juan Ahuehueyo',
			'San José Carpinteros',
			'Las Cañadas',
			'Barrio de Centro del Cerrillo',
			'Octaviano López',
			'La Escalera',
			'Chichicaxtle',
			'San Pedro Ozumacín',
			'San Isidro Alta Huerta',
			'Zacapalco',
			'San Andrés Ocotepec',
			'Chula Vista',
			'Aguas Calientes',
			'Fracción Milpillas',
			'Arroyo de Enmedio',
			'Toxtla',
			'Francisco Sarabia',
			'Ejido del Tejocote',
			'El Águila (La Mesa)',
			'Colonia las Arboledas',
			'Las Masas (La Luz Masas)',
			'Apalani',
			'Barrio Chiquichuca',
			'San Juan Tomasquillo Herradura',
			'Banderas',
			'Ixtahuiata (La Legua)',
			'Las Nieves',
			'Los Encinos',
			'Tierra Blanca',
			'San José de Abajo [Unidad Habitacional]',
			'El Mirador',
			'La Colonia',
			'El Pedregal',
			'La Palmita (La Palmita de San Gabriel)',
			'San Francisco Javier',
			'Los Achotes',
			'Ventoquipa',
			'Colinas del Sol',
			'Estación de San Francisco',
			'Coyahualco',
			'San Isidro',
			'Tazaquil',
			'General Alatriste (San Joaquín)',
			'Agua Blanca',
			'Kilómetro 40',
			'El Cortijo',
			'Acuaco',
			'Tierra Larga (Campo Nuevo)',
			'El Espinalillo',
			'Maya Tecún I',
			'San Miguel Totoltepec',
			'La Reforma',
			'Santiago Pojcol',
			'El Naranjito',
			'Nuevo Tantoán',
			'Matlapa',
			'Picholco',
			'La Subida',
			'Zapotitlán',
			'Xul',
			'Xoxocotla',
			'Xococapa',
			'La Concepción Xochicuautla',
			'Xico',
			'Santa María Xadani',
			'Ucú',
			'Tuxtla Chico',
			'Transfiguración',
			'Las Jarretaderas',
			'Abdenago C. García',
			'La Constitución Toltepec',
			'Fraccionamiento Metrópolis II',
			'Nueva Santa Rosa',
			'Ciudad Gustavo Díaz Ordaz',
			'Tampate',
			'Zacualtipán',
			'Yotholin',
			'Xopilapa',
			'Xicotepec de Juárez',
			'Xaltianguis',
			'Santa Ana Xalmimilulco',
			'Otatitlán',
			'Totomixtlahuaca',
			'Totolapa',
			'Tlapa de Comonfort',
			'Tezoatlán de Segura y Luna',
			'Tecamachalco',
			'Tanquián de Escobedo',
			'Santiago Yaitepec',
			'Santa Rosalía',
			'Coyomeapan',
			'Ahuatempan',
			'San Juan Guichicovi',
			'San Juan Evangelista',
			'San Joaquín',
			'Sabancuy',
			'Río Lagartos',
			'Reyes Etla',
			'Raymundo Enríquez',
			'Potrero Viejo',
			'Piaxtla',
			'Pacho Viejo',
			'Oxolotán',
			'Mazatepec',
			'Martínez de la Torre',
			'Mancuernas',
			'Los Reyes Acaquilpan',
			'Limones',
			'La Estancia',
			'La Concepción de los Baños',
			'Ixcuinquitlapilco',
			'Ilamatlán',
			'Paseos de Itzincab',
			'Hunucmá',
			'Hueycantenango',
			'Huazulco',
			'Hopelchén',
			'Hidalgo',
			'Hidalgo',
			'Hacienda Blanca',
			'Espita',
			'Españita',
			'El Rodeo',
			'El Porvenir de Velasco Suárez',
			'Eloxochitlán de Flores Magón',
			'El Cuyo',
			'Colotepec',
			'Coatepec Costales',
			'Ciudad Serdán',
			'Chicola',
			'Chemax',
			'Champotón',
			'San José Chacalapa',
			'Cenotillo',
			'Catazajá',
			'Casitas',
			'Cacao',
			'Buenavista',
			'Bajos del Ejido',
			'Atzala',
			'Atlzayanca',
			'Atencingo',
			'Amozoc de Mota',
			'Álvaro Obregón',
			'Ajacuba',
			'Arroyo Limón',
			'Álvaro Obregón',
			'San Vicente Piñas',
			'Santiago Textitlán',
			'Santiago Xanica',
			'Villa Vicente Guerrero',
			'Tepetitla',
			'La Cruz',
			'El Rosario',
			'Benito Juárez',
			'San Luis Apizaquito',
			'Santa Cruz el Porvenir',
			'Xocoyucan',
			'San Miguel la Sardina',
			'Viva Cárdenas',
			'La Patria',
			'Nueva Libertad',
			'Querétaro',
			'San Gaspar',
			'San José Chalmita',
			'Santa Cruz Atizapán',
			'San Lucas Amalinalco',
			'San Luis Tecuhautitlán',
			'San Felipe Teotitlán',
			'Santa Ana Ixtlahuaca (Santa Ana Ixtlahuacingo)',
			'Guadalupe Libertad',
			'Cuesta Blanca',
			'La Purísima',
			'Xochitepec',
			'Tierra Colorada',
			'Las Ánimas',
			'Santo Domingo',
			'Xohuayan',
			'Cabezas',
			'Cerro Gordo',
			'La Laguna',
			'Trapiche Viejo',
			'La Cuesta',
			'San José Súchil',
			'Tapalapan',
			'Santa Juana Centro',
			'San Jerónimo Bonchete',
			'El Carmen Ocotepec',
			'Jalpa de los Baños',
			'San José Comalco',
			'Santiaguito Tlalcilalcalli',
			'San Miguel Vindho',
			'Santa María de la Paz',
			'El Sitio',
			'Emiliano Zapata',
			'Suspiro Picacho',
			'Colonia Felipe Ángeles',
			'El Huajote',
			'Benito Juárez',
			'Villa Zaragoza',
			'Zacatecas',
			'Villa Corona',
			'Villachuato',
			'Venado',
			'Valle de Santiago',
			'Valencia de Cerro Gordo',
			'Tzintzuntzán',
			'Tuxcacuesco',
			'Tequesquitlán',
			'Tengüecho',
			'Sonoita',
			'San Vicente',
			'Santiago Maravatío',
			'Santa Catarina',
			'Xichú',
			'Oscar Soto Maynez',
			'San Pedro Tenango',
			'San Miguel Zapotitlan',
			'San Miguel de la Paz',
			'San Luis de Agua Caliente',
			'San Juan de los Lagos',
			'San José del Castillo',
			'San Blas',
			'San Bernardo',
			'San Andrés Ixtlán',
			'Sacramento',
			'Ciudad Sabinas Hidalgo',
			'Romero de Guzmán',
			'Ramos Arizpe',
			'Puente de Camotlán',
			'Pueblo Viejo',
			'Petatlán',
			'Petacalco',
			'Pánuco de Coronado',
			'Onavas',
			'Nazas',
			'Moctezuma',
			'Los Hucuares',
			'Loreto',
			'Loreto',
			'San José Tepuzas',
			'La Soledad',
			'Quesería',
			'La Punta',
			'La Labor',
			'La Garita',
			'La Esperanza',
			'Jiménez',
			'La Herradura',
			'Heroica Caborca',
			'Felipe Carrillo Puerto',
			'Escalerillas',
			'El Sauzal de Rodríguez',
			'El Refugio',
			'El Jicote',
			'El Carmen',
			'Puebla',
			'Esperanza',
			'Dieciocho de Marzo',
			'Colonia Nicolás Bravo (Kilómetro Noventa y Dos)',
			'Allende',
			'Colón',
			'Cocula',
			'Coalcomán de Vázquez Pallares',
			'Los Cedros',
			'Estación Chimaneco',
			'Benito Juárez',
			'Atolinga',
			'Villa de Arriaga',
			'Ario de Rosales',
			'Antonio Escobedo',
			'Altata',
			'Agua Prieta',
			'Agua Caliente Grande (De Gastélum)',
			'Jaltomate',
			'Chametla',
			'Calzada del Tepozán',
			'El Moral',
			'La Angostura',
			'San Miguel Epejan',
			'Zopoco',
			'Chapa',
			'San Antonio Solís',
			'Santa Rosa de Lima',
			'San Nicolás Guadalupe',
			'Cofradía de Juárez',
			'San Vicente',
			'Carbonera',
			'Santa María Begoña',
			'El Milagro',
			'Estación Santa Engracia',
			'Parque Industrial Ciudad Mitras',
			'Alborada Jaltenco',
			'Fraccionamiento la Trinidad',
			'San Bartolomé Cuahuixmatlac',
			'Tlacolula',
			'Tlacoachistlahuaca',
			'Tlachichuca',
			'Tixcacalcupul',
			'Texhuacán',
			'San Juan Texcalpan',
			'Tetipac',
			'Temax',
			'Telchac Pueblo',
			'Tecali',
			'Tatahuicapá',
			'Socoltenango',
			'Sitilpech',
			'Seyé',
			'Santiago Nundíche',
			'Santiago Jocotepec',
			'Santa Catarina Quiané',
			'Santa Catarina Mechoacán',
			'Santa Ana Hueytlalpan',
			'San Sebastián',
			'San Rafael',
			'San Nicolás',
			'San Miguel Tetepelcingo',
			'San Miguel Tenango',
			'San Miguel Canoa',
			'San Mateo Tecalco',
			'San Lucas Quiavini',
			'San Juan Yatzona',
			'San Juan Xiutetelco',
			'San Juan Juquila Vijanos',
			'San Francisco Zacacalco',
			'San Cristóbal Amoltepec',
			'San Andrés Huayápam',
			'Veintidós de Febrero',
			'Jorge Jiménez Cantú',
			'Fraccionamiento Lomas de Ahuatlán',
			'Los Torrentes',
			'Villa Licenciado Jesús Terán (Calvillito)',
			'Alianza Real',
			'Valle de Vaquerías',
			'El Ámbar (El Ámbar de Echeverría)',
			'Suchilapan del Río',
			'Progreso',
			'Pantitlán',
			'San Francisco Temetzontla',
			'Loma Bonita',
			'El Romereño (INFONAVIT)',
			'El Salto',
			'Macario J. Gómez [Colonia]',
			'Progreso de Juárez',
			'Fraccionamiento Hacienda del Bosque',
			'Los Dolores (Las Quince Letras)',
			'Ejido Cajón Ojo de Agua Número Dos',
			'Santana 2da. Sección B (La Palma)',
			'Pueblo Nuevo',
			'La Cabecera Concepción',
			'Estación Obispo',
			'San Isidro',
			'Matzam',
			'Las Margaritas',
			'San Juan Pancoac',
			'Nuevo Pumpuapa (Cereso)',
			'San Antonio Xoquitla',
			'Villa Rotaria',
			'Ciudad Chemuyil',
			'Ignacio Zaragoza',
			'Zapuyo',
			'José María Morelos (San José)',
			'Cuapinolito (Azulillo)',
			'Tonalapan',
			'Patlicha',
			'San Bernardo Peña Blanca',
			'Ignacio Zaragoza',
			'Tulimanca',
			'Rincón del Porvenir',
			'Zolonquiapa',
			'Arena 6ta. Sección (La Bolsa)',
			'Lázaro Cárdenas',
			'El Triunfo 1ra. Sección (Cardona)',
			'San Pedro Denxhi Centro',
			'Ciudad Guzmán [CERESO]',
			'Metlac Hernández (Metlac Primero)',
			'Monte Blanco',
			'Joconoxtle (La Tuna)',
			'Arroyo San Isidro',
			'Colonia San Luis',
			'Aguas Buenas',
			'Derramadero Segundo (Infiernillo)',
			'La Junta',
			'Peña Colorada',
			'El Huidero',
			'Loma del Refugio',
			'Moyotepec (Moyotepec de Juárez)',
			'Arroyo Vista Hermosa',
			'San Agustín Tlaxco',
			'La Compañia',
			'San Lorenzo Itzícuaro',
			'Santa Apolonia',
			'El Control',
			'San Francisco',
			'Damian Carmona',
			'Santa María Yucunicoco',
			'Santiago Yogana',
			'Xaltepec',
			'Xaaga',
			'Villa Mainero',
			'Tuzantán',
			'Tultepec',
			'Trinidad Zaachila',
			'Tolome',
			'Tlalcozotitlán',
			'Tila',
			'Teteles de Avila Castillo',
			'Tesoco',
			'Tekit',
			'Tecolutla',
			'Tamulte de las Sabanas',
			'Tampemoche',
			'Santa Cruz Ex-Hacienda',
			//'Santa Ana',
			'Ixcapa',
			'San Rafael',
			'San Pedro Ocotepec',
			'San Pablo',
			'San Pablo Anicano',
			'Villa Sola de Vega',
			'San Miguel Enyege',
			'Xaltocan',
			'San Martín de las Pirámides',
			'San Juan Guelavía',
			'Ixtapa',
			'San José Estancia Grande',
			'San José Acateno',
			'San Jerónimo Coatlán',
			'San Gabriel Chilac',
			'Mixtla',
			'San Francisco Coatlán',
			'San Dionisio del Mar',
			'Xochitlaxco (San Baltazar)',
			'San Agustín Tlaxiaca',
			'Sabanillas',
			'Puxcatán',
			'Progreso',
			'Potrero del Llano',
			'Perote',
			'Papalotla',
			'Papaloctipan',
			'Pablo L. Sidar',
			'Nueva Ciudad Guerrero',
			'Nogales',
			'Nejapa de Madero',
			'Naucalpan de Juárez',
			'Naranjos',
			'Moralillo',
			'Moloacán',
			'Minzapan',
			'Méndez',
			'Medellín de Bravo',
			'Mazatlán Villa de Flores',
			'Malinalco',
			'Lagunillas',
			'Kantunil',
			'Jicayán de Tovar',
			'Ixtacamaxtitlán',
			'Iturbide',
			'Huichihuayan',
			'Huauchinango',
			'Ciudad General Terán',
			'El Pital',
			'El Nopal',
			'Ciudad Mante',
			'El Cazadero',
			'Cuetzala del Progreso',
			'Cuanalá',
			'Córdoba',
			'Chapulhuacán',
			'Carrizal',
			'Carrillo Puerto',
			'Carpinteros',
			'Cacaloxúchitl',
			'Bondojito',
			'Blasillo 1ra. Sección (Nicolás Bravo)',
			'Blanca Espuma',
			'Bienvenido',
			'Barra de Cazones',
			'Atasta',
			'Astacinga',
			'Ahuehuepan',
			'Agua Zarca',
			'Acatlán',
			'Acamilpa',
			'Santiago Tlacotepec',
			'San Miguel Huautla',
			'Quelové',
			'La Humedad',
			'Santo Domingo Yodohino',
			'San Pedro Sochiápam',
			'Paso Real de Sarabia',
			'Santa Inés Yatzeche',
			'Zacatal',
			'Transito Tular',
			'Nuevo Torno Largo',
			'Lomitas',
			'La Libertad',
			'Acatepec',
			'Los Sabinos',
			'Xochitlán',
			'Mangas',
			'Camelia (Barrio la Camelia)',
			'La Boveda',
			'Francisco Villa',
			'Santa Cruz Pocitos',
			'Juárez',
			'La Ceiba',
			'Maravillas',
			'El Porvenir Agrarista',
			'Yasha',
			'Arriaga',
			'El Terrero',
			'Cerro La Calera',
			'Colonia Cuauhtémoc',
			'Los Saucos',
			'Zula',
			'Celayita',
			'San Lorenzo Malacota',
			'Ixtepec',
			'San Antonio Juárez',
			'Tzicatlán',
			'Xmabén',
			'San Martín',
			'Chiepetepec',
			'El Tejocote',
			'Texan de Palomeque',
			'Kantunilkín',
			'Alfredo V. Bonfil',
			'Álvaro Obregón',
			'Tenixtepec',
			'Moyoapan',
			'Poblado Dos',
			'San Juan Seco de Valencia',
			'San Pedro Tejalpa',
			'Benito Juárez',
			'San Antonio de las Huertas',
			'San Juan de los Jarros',
			'El Rincón de la Candelaria',
			'Santa María Jajalpa',
			'San Juan la Isla',
			'La Victoria',
			'Rancho Nuevo',
			'Chaparrosa',
			'Enramadas',
			'Ignacio Zaragoza',
			'Ejido Benito Juárez',
			'Colonia Venustiano Carranza',
			'Nuevo Zirosto',
			'Yurécuaro',
			'Yavaros',
			'Villa González Ortega',
			'Coronado',
			'Valparaíso',
			'Tupátaro',
			'Tepezalá',
			'Tangancícuaro de Arista',
			'Sevina',
			'Sauz de Calera',
			'Santa Rosalía',
			'Santa María del Oro',
			'Santa Elena',
			'San José de Agua Azul',
			'San Jerónimo de Araceo',
			'San Diego de la Unión',
			'Francisco Serrato (San Bartolo)',
			'Samachique',
			'Saltillo',
			'Rincón de Romos',
			'Quetchehueca',
			'Potrerillos',
			'Plateros',
			'Peña Blanca',
			'Parácuaro',
			'Nuevo Casas Grandes',
			'El Nio',
			'Navolato',
			'Momax',
			'Los Tepames',
			'Los Barriles',
			'La Victoria',
			'Las Grullas Margen Izquierda',
			'Villa Las Esperanzas',
			'Las Bocas',
			'Rosa Blanca',
			'La Ribera',
			'San Antonio de la Laguna',
			'La Concepción',
			'J. Jesús Díaz Tzirio',
			'Jiquílpan de Juárez',
			'Jiquilpan',
			'Jiménez del Teul',
			'Jesús María',
			'Xalisco',
			'Jacona de Plancarte',
			'Vicente Guerrero',
			'Francisco I. Madero',
			'El Verde',
			'El Rosario',
			'El Jazmín',
			'El Fuerte',
			'Ejutla',
			'Hermosillo',
			'Cuatro Ciénegas de Carranza',
			'Coyula',
			'Coyotitán',
			'Comanjilla',
			'Augusto Gómez Villanueva',
			'Cinco de Mayo',
			'Jitonhueca',
			'Cañitas de Felipe Pescador',
			'Campo Acosta',
			'Betania',
			'Bachigualatito',
			'Atacco',
			'Arroyos',
			'Aratichanguío',
			'Epigmenio González',
			'Ramón Corona',
			'La Labor',
			'Ojocaliente',
			'Cuesta de Peñones',
			'El Gallinero',
			'Fábrica de Melchor',
			'Chirimoya (Estación Chirimoya)',
			'Urireo',
			'El Sauz (El Sauz de Villaseñor)',
			'Los Ocotes',
			'La Estrella',
			'Huitzatarito',
			'San Martín',
			'Felipe Carrillo Puerto',
			'Parahuén',
			'Guacamayas',
			'Casas Viejas',
			'Dotegiare',
			'Cedro de la Manzana',
			'Los Almendros',
			'La Barreta',
			'Ojo de Agua',
			'Apapátaro',
			'La Colonia Guadalupe',
			'Pórticos de San Antonio',
			'San Rafael Tlanalapan',
			'Tecámac de Felipe Villanueva',
			'Real del Valle (El Paraíso) [Fraccionamiento]',
			'Benito García (El Zorrillo)',
			'Emiliano Zapata',
			'Adolfo López Mateos (El Tamarindo)',
			'San Francisco Tepeyecac',
			'Axotlán',
			'San Antonio',
			'Ichán',
			'San Francisco Ayotuzco',
			'Barrio Tlatenco',
			'Poblado C-33 20 de Noviembre',
			'San Isidro',
			'Colonia Paraíso Escondido',
			'Fraccionamiento Ex-Hacienda Catano',
			'Alfonso G. Calderón Velarde',
			'Tonalá',
			'Alfredo V. Bonfil (Chacampalco)',
			'El Cahulote de Santa Ana',
			'Fraccionamiento San Miguel',
			'Ampliación la Hincada',
			'Alpuyecancingo de las Montañas',
			'Geovillas Laureles del Campanario',
			'Los Parques',
			'San Antonio Texas',
			'Parque de Poblamiento Solidaridad',
			'San Pedro Tulixtlahuaca',
			'Paso Blanco',
			'San José de Cervera',
			'El Palmar',
			'San Lucas',
			'Colonia Pedro María Anaya',
			'El Porvenir I',
			'Chixtontic',
			'La Cañada',
			'Estación Mogoñé',
			'Las Trojas',
			'Cuauhtamingo',
			'Orba (Infiernito)',
			'Lomas de Tepemecatl',
			'Xochitepec',
			'Granadillas (La Colonia)',
			'Tlahuapa',
			'La Manga 2da. Sección (El Jobal)',
			'La Mesa (La Mesa de Cedano)',
			'San Isidro Xoteapan (San Isidro Texcaltitán)',
			'Francisco Primo de Verdad (Ciénega de Mata)',
			'Paso de Núñez (Buenavista)',
			'Los Palacios',
			'Xalticpac',
			'El Colegio',
			'Soyata',
			'El Escribano',
			'Fraccionamiento Colinas Universidad',
			'San Felipe Tílpam',
			'Colonia 3 de Mayo',
			'Jolsibaquil',
			'Colonia los Cerritos',
			'La Trinidad',
			'Paraje Trejo (El Chaparral)',
			'10 de Abril',
			'Loma Chapultepec',
			'Barrio de San Ramón',
			'Nuevo Refugio de Afuera (Nuevo Refugio)',
			'Santiago Tepopula',
			'Tlacolulan',
			'Santa María Nenetzintla',
			'San Luis Ajajalpan',
			'Minatitlán',
			'Tiltepec',
			'Tetla',
			'Tetecala',
			'Tepetzintla',
			'Tepapayeca',
			'Tecax',
			'Soyaló',
			'Sierra Papacal',
			'Sayula de Alemán',
			'Hueyotlipan',
			'Santo Domingo Zanatepec',
			'Santiago Matatlán',
			'Temaxcaltepec',
			'Santa Isabel Chalma',
			'Zimapan',
			'Zihuateutla',
			'Villa Zempoala',
			'Xochiltepec',
			'Xochihuehuetlán',
			'Xochicoatlán',
			'Xcanatún',
			'Xaloztoc',
			'Villa del Carbón',
			'Unión Hidalgo',
			'Tlaquiltepec',
			'San Francisco Tepexoxica',
			'San Pedro de los Baños',
			'San Antonio',
			'Saltillo',
			'San Juan Volador',
			'Potrerillo',
			'Zamora Pico de Oro',
			'Paso del Correo',
			'Palmillas',
			'Pachuca de Soto',
			'Ozuluama de Mascareñas',
			'Oxcum',
			'Oteapan',
			'Oxtotitlán',
			'Orizabita',
			'Ocotitlán',
			'Nuevo Laredo',
			'Nueva América',
			'Nepopualco',
			'Moyos',
			'Morelos Cañada',
			'Mecapalapa',
			'Mayanalán',
			'Mapastepec',
			'Mani',
			'Malinaltepec',
			'Los Ramones',
			'Las Delicias',
			'Las Choapas',
			'Juchique de Ferrer',
			'Jiutepec',
			'Jaltepec de Candayoc',
			'Santiago Ixtayutla',
			'Huatusco',
			'Nuevo Juan del Grijalva',
			'El Palmar',
			'San Marcos Eloxochitlán',
			'Lázaro Cárdenas',
			'El Laurel',
			'El Corte',
			'Dos Arroyos',
			'Dolores Jaltenango',
			'Cupilco',
			'Santa María Cuevas',
			'Coatzingo',
			'Ciudad del Carmen',
			'Chila de la Sal',
			'Chilacachapa',
			'Chinautla',
			'Las Chicharras',
			'Chenalhó',
			'Chamula',
			'El Chacal',
			'Caxhuacán',
			'Camotepec',
			'Bokoba',
			'Ayotzinapa',
			'Atlahuilco',
			'Alpuyeca',
			'Acultzingo',
			'Acayuca',
			'San José Río Manzo',
			'El Barrio de la Soledad',
			'Zaragoza',
			'Almolonga',
			'El Porvenir',
			'Zarzal',
			'Merced del Potrero',
			'El Congo',
			'Santa Ana Nopalucan',
			'Vicente Guerrero',
			'Narciso Mendoza',
			'Los Pozos',
			'La Independencia',
			'Villahermosa Yaluma',
			'Paraíso del Grijalva',
			'Joaquín Miguel Gutiérrez',
			'Cristóbal Obregón',
			'San Andrés Nicolás Bravo',
			'La Purificación',
			'San Pedro Tepetitlán',
			'Santiago Casandeje',
			'Santiago Yancuitlalpan',
			'Soledad Morelos',
			'Hermenegildo Galeana',
			'Zoh-Laguna',
			'La Libertad',
			'División del Norte',
			'Zacacoyuca',
			'Julián Blanco (Dos Caminos)',
			'Coachimalco',
			'Plan del Río',
			'Ejidal',
			'Tlilapan',
			'José F. Gutiérrez',
			'Tlacuilolapan',
			'Alto Lucero',
			'Fresno Nichi',
			'San Agustín Mextepec',
			'Emilio Portes Gil',
			'San Marcos Tlazalpan',
			'San Bartolomé',
			'San Pedro Atlapulco',
			'José María Morelos y Pavón',
			'Miguel Alemán (La Doce)',
			'Lázaro Cárdenas',
			'General Pánfilo Natera',
			'Colonia Felipe Ángeles',
			'Ermita de Guadalupe',
			'Palma Pegada',
			'Pueblo Nuevo',
			'Betulia',
			'Licenciado Gustavo Díaz Ordaz',
			'Francisco Zarco',
			'Zináparo',
			'Zapotitán de Hidalgo',
			'Vista Hermosa',
			'Venustiano Carranza',
			'Topia',
			'Todos Santos',
			'Teúl de González Ortega',
			'Teuchitlán',
			'Tepusco',
			'Temósachic',
			'Techaluta de Montenegro',
			'Tanaquillo',
			'Sotelo',
			'Laguna de Santa Rita',
			'Santa Matilde',
			'Santa María del Refugio',
			'Santa Ana Pacueco',
			//'Santa Ana',
			'San Pedro Piedra Gorda',
			'San Nicolás',
			'San José de la Paz',
			'San Jose Solís',
			'Curungueo',
			'San Cristóbal',
			'San Bernardo',
			'San Antonio',
			'San Agustín de las Palmas',
			'Zalamea',
			'Sahuaripa',
			'Rancho Nuevo de la Luz',
			'Santa María Pipioltepec (Pipioltepec)',
			'Pinzándaro',
			'José Guadalupe Rodríguez',
			'Nuevo Urecho',
			'Matancillas (San Isidro Matancillas)',
			'Maravillas',
			'Llano Grande',
			'Las Taponas',
			'La Popular',
			'Hacienda de la Bolsa',
			'Jerécuaro',
			'Jamay',
			'Ixtlán de los Hervores',
			'Huitrón',
			'General Zuazua',
			'San Pedro Garza García',
			'Empalme Escobedo',
			'El Botadero',
			'El Alvareño',
			'El Ahuacate',
			'San Bartolo Cuitareo',
			'Castro Urdiales',
			'Camotlán de Miraflores',
			'Atapaneo',
			'Alcaraces',
			'Agostitlán',
			'Aduana del Sásabe',
			'Loza de los Padres',
			'Zapote de Peralta',
			'Delgado de Arriba',
			'Don Diego',
			'Estación Joaquín',
			'Capulín de Bustos',
			'San Juan Grande',
			'Janambo',
			'San Juan de Viña',
			'San Pedro Limón',
			'Vare Chiquichuca',
			'Valle Luz',
			'San Isidro Buenavista',
			'Los Cerritos',
			'Sinapan',
			'Morocoy',
			'Las Sabinas',
			'Santa Cruz Amilpas',
			'Galaxia Bonito Jalisco',
			'Rincón de Nicolás Romero (Cedros Tercera Manzana)',
			'El Centenario',
			'Tungareo',
			'Panuaya',
			'Jicaltepec Cuexcontitlán',
			'Cristeros [Fraccionamiento]',
			'Sosa',
			'Colonia las Flores',
			'Juan Aldama (El Tigre)',
			'Los Mangos',
			'Portal de las Salinas',
			'Coahuixco',
			'Apango de Zaragoza',
			'San Miguel Tianguizolco',
			'Amacuitlapilco',
			'Llano Largo',
			'Fredepo',
			'San Francisco (Mata Clara)',
			'Tinajitas',
			'CEFERESO Número 3',
			'Seis de Octubre (Santo Niño)',
			'San Pedro Xalcaltzinco',
			'Ninguno [CERESO]',
			'Barrio de España',
			'Zahuatlán de Morelos (San José)',
			'Nuevo Milenio Valdivia',
			'Colonia Militar',
			'Colonia Ignacio Allende',
			'Buena Vista (Apasco)',
			'Fraccionamiento Santa Cruz',
			'Nueva División del Bayo (Guatemala)',
			'Unidad Habitacional 10 de Abril',
			'Fraccionamiento la Virgen',
			'Fraccionamiento las Liebres',
			'Tapias de Santa Cruz (Pedro Ruiz González)',
			'La Concepción',
			'San José Cuaro',
			'Tetecolala',
			'Benito Juárez (La Playita)',
			'Abrevadero',
			'Ignacio Manuel Altamirano',
			'Loma de los Hoyos',
			'San Pedro Cacahuatepec',
			'Cerritos',
			'La Calera (Nacimientos)',
			'Tzoeptic',
			'Colonia Guadalupe',
			'Molinos',
			'San Antonio Bulujib',
			'Cuesta de Laja',
			'Tacuba Nueva',
			'Nuevo Cereso Regional de Altamira',
			'El Canario',
			'San Miguel Amazcala',
			'Aurora Ermita',
			'Montaña Monarca (Punta Altozano)',
			'Chulum Cárdenas',
			'Caxitepec',
			'Octavio Paz',
			'El Metlapil',
			'Unidad Habitacional Mariano Matamoros',
			'El Recodo de San José Axalco',
			'18 de Marzo',
			'Colonia la Calzada (La Olla)',
			'Rinconadas de San Francisco',
			'Cenobio Aguilar (La Trinidad)',
			'Texalpan de Abajo',
			'San Lucas Atzala',
			'Santa Cruz Quilehtla',
			'Tixtla de Guerrero',
			'Teziutlan',
			'Texcalyacac',
			'Tequisquiapan',
			'Tepetzintla',
			'Tepalcingo',
			'Tepakán',
			'Teoloyucan',
			'San Francisco Tenopalco',
			'Tehuetlán',
			'Tecomatepec',
			'Teayo',
			'Tahdzibichen',
			'Soyataco',
			'Santiago Acatlán',
			'Santa Mónica',
			'Santa Gertrudis',
			'Santa Cruz Xitla',
			'Santa Cruz Lachixolana',
			'San Salvador el Verde',
			'San Pedro Huaquilpan',
			'San Miguel el Grande Villa Juárez',
			'San Matias Tlalancaleca',
			'Sindihui',
			'Toxpalan',
			'San Marcos',
			'San Julián',
			'San Juan Tetla',
			'San Juan del Estado',
			'Tlachichilco',
			'Animas Trujano',
			'Miahuatlán',
			'San Félix Hidalgo',
			'San Diego Huehuecalco',
			'San Antonio Rayón',
			'Zapotlán de Juárez',
			'Xochipala',
			'Villagrán',
			'Trinidad de Viguera',
			'Veinte de Noviembre',
			'Uayalceh de Peón',
			'Tzicatlacoyan',
			'San Bernardino Tlaxcalancingo',
			'La Estancia',
			'La Concepción',
			'Jaumave',
			'Ixcatepec',
			'Huixtán',
			'Huejonapan',
			'Huazuntlán',
			'Hool',
			'Gutiérrez Zamora',
			'Guadalupe Tlachco',
			'Frontera Hidalgo',
			'El Molino',
			'Dzoncauich',
			'Cuautitlán',
			'Citlaltépec',
			'Chumayel',
			'China',
			'Chichiquila',
			'San Agustín Chayuco',
			'Chahuites',
			'Chablé',
			'Cantamayec',
			'San Martín Caltenco',
			'Boca del Monte',
			'Benito Juárez',
			'Ayutla de los Libres',
			'Axochiapan',
			'Axixintla',
			'Atliaca',
			'Ciudad López Mateos',
			'Atenango del Río',
			'Arroyo del Potrero',
			'Apapantilla',
			'Almolonga',
			'Acazónica',
			'Atarjea',
			'Santa María Lachixío',
			'Santiago Yolomécatl',
			'Las Margaritas',
			'San Jacinto Amilpas',
			'San Francisco del Mar',
			'El Recreo',
			'Tecoluta 2da. Sección',
			'Palo Mulato',
			'Pueblo Viejo',
			'Rancho Nuevo',
			'Tucta',
			'Río de Teapa',
			'San Juan Ahuehueco',
			'San Isidro',
			'Colonia Benito Juárez',
			'Tetlatlahuca',
			'Totolac',
			'Tenancingo',
			'Benito Juárez',
			'Francisco I. Madero',
			'Vicente Guerrero',
			'El Jobo',
			'Gabriel Esquinca',
			'Venustiano Carranza',
			'Ajilhó',
			'San Juan Cancuc',
			'Diamante de Echeverría',
			'Tonalá',
			'Paredón',
			'El Carmen (El Desierto del Carmen)',
			'San Francisco Tetetla',
			'Santiago Cuautenco',
			'San Martín Cuautlalpan',
			'Tlaltecahuacán',
			'Santa Rita',
			'Las Lajas',
			'El Veladero',
			'Constitución',
			'Haro',
			'Coacoyulillo',
			'Tres Palos',
			'Cuanacaxtitlán',
			'El Conejo',
			'Mesa de Guadalupe',
			'El Lencero',
			'Pacho Nuevo',
			'Mata Obscura',
			'Mundo Nuevo',
			'Guadalupe Victoria',
			'La Palma',
			'El Arenal',
			'Yosphí',
			'San Bartolo el Viejo',
			'Cuadrilla Vieja',
			'Manto del Río Ejido',
			'Tecoac (Santa María Nativitas)',
			'Buenos Aires',
			'Benito Juarez',
			'San Agustín Huitzizilapan',
			'San Miguel Hila',
			'San Bartolo',
			'Buenavista',
			'Francisco Javier Mina',
			'San Carlos',
			'Mi Patria es Primero',
			'Granadas',
			'Las Catarinas',
			'Cañas',
			'La Concepción',
			'El Saladito',
			'Paso de Cuarenta',
			'Huachichil',
			'Villa Hidalgo',
			'Villagrán',
			'Villa de Cos',
			'Valle de Zaragoza',
			'Tuxpan',
			'Tingambato',
			'Tanganhuato',
			'Tacámbaro de Codallos',
			'Suchitlán',
			'Soyatlán del Oro',
			'Sombrerete',
			'Silao',
			'Santiago Tangamandapio',
			'Santiago Papasquiaro',
			'Santiago',
			'Santa Bárbara',
			'San Marcos',
			'San José de Bacum',
			'San Isidro',
			'San Francisco',
			'San Buenaventura',
			'Tepuxtepec',
			'El Salitre',
			'Querobabi',
			'Santiago de Querétaro',
			'Progreso',
			'Portezuelo',
			'Pinos',
			'Piñícuaro',
			'Panindícuaro',
			'Nadadores',
			'Nacozari Viejo',
			'Mineral de Angangueo',
			'Los Reyes de Salgado',
			'Los Pocitos',
			'Los Galvan',
			'La Laja',
			'La Laborcita',
			'Laguna Larga de Cortés',
			'La Fuente',
			'Jesús María',
			'Irapuato',
			'San Pedro de Ibarra',
			'Huáscato',
			'Flor Azul',
			'Felipe Carrillo Puerto',
			'Escolasticas',
			'El Walamo',
			'El Refugio',
			'El Naranjo',
			'El Guaco',
			'El Capulín',
			'Ejido Saltillo',
			'Duarte',
			'Costa Azul',
			'Concepción del Oro',
			'Ejido Cohuibampo',
			'Churipitzeo',
			'Cerano',
			'Mora Villalobos',
			'Estación Camacho',
			'Cacalotán',
			'San Buenaventura',
			'Baviácora',
			'Barretos',
			'Bacabachi',
			'Angostura',
			'Amazcala',
			'Ajuchitlán del Progreso',
			'El Tunal',
			'Sarabia',
			'Los Medranos',
			'Don Francisco',
			'La Cruz del Palmar',
			'Corral de Piedras de Arriba',
			'El Cuenqueño',
			'Cabecera de Indígenas',
			'Los Baños',
			'La Solana',
			'El Paraíso',
			'Huapinol',
			'San José',
			'La Unión Paso Largo',
			'Terrazas del Valle',
			'La Ermita',
			'Jacarandas',
			'Tenango de las Flores',
			'Chanal',
			'Villa Jiménez',
			'Santa María de Guadalupe',
			'San Pedro Zacachimalpa',
			'General Felipe Ángeles (Los Ángeles)',
			'Alfonso G. Calderón (Poblado Siete)',
			'La Ciénega',
			'Rinconada de los Ángeles',
			'San Juan Benito Juárez (San Juan Tararameo)',
			'Anacleto Canabal 3ra. Sección',
			'San Salvador (Naranjillo)',
			'Guadalupe Victoria',
			'El Curtidor',
			'San Andrés',
			'Los Laureles',
			'Nuevo Vicente Guerrero (El Chichonal)',
			'Brisas del Carrizal',
			'Hermenegildo Galeana 2da. Sección',
			'El Esclavo',
			'Mecoacán 2da. Sección (San Lorenzo)',
			'El Pozo',
			'Sebastián Lerdo de Tejada',
			'San José la Hacienda',
			'San Bernabé de las Canteras',
			'Gómez Poniente',
			'Chiquinshulum',
			'Ciudad Satélite',
			'San Francisco Tláloc',
			'Lázaro Cárdenas (Santana)',
			'Zoquiapa',
			'San Lucas Huarirapeo (La Mesa de San Lucas)',
			'Colonia San Juan',
			'Santa Anita',
			'La Glorieta',
			'San José el Nuevo',
			'San José de Durán (Los Troncoso)',
			'Jerusalén',
			'Fraccionamiento los Álamos',
			'Turuachi',
			'De Parral',
			'Barrio el Boncho',
			'Cuauhtamazaco',
			'El Paraíso',
			'Los Olivos',
			'El Tigre (Segunda Manzana de Crescencio Morales)',
			'El Dorado',
			'El Capulo (La Quebradora)',
			'Ignacio Zaragoza',
			'Juamave',
			'Zacapechpan',
			'San Diego',
			'San Jerónimo Coyula',
			'San Isidro de los Sauces',
			'Santiago Jicayán',
			'Tlaltizapán',
			'San Martín Tilcajete',
			'Tierra Colorada',
			'Tezoyuca',
			'Tenango de Doria',
			'Tenango',
			'Tenampa',
			'Temozon',
			'Tekik de Regil',
			'Tecozautla',
			'Teabo',
			'Soconusco',
			'Simón Sarlat',
			'Santo Domingo Tehuantepec',
			'Santo Domingo Shomege (Shomege)',
			'Santo Domingo Jalieza',
			'Santa María Cortijo',
			'Santa María Acú',
			'Santa Catarina Otzolotepec',
			'Santa Catarina Juquila',
			'Teloxtoc',
			'San Simón',
			'San Rafael',
			'San Miguel Ajusco',
			'San Mateo Cajonos',
			'San Juan Atenco',
			'San Juan',
			'Tecuanipan',
			'San Antonio el Grande',
			'San Andrés Tuxtla',
			'Sabanilla',
			'Pustunich',
			'Presidio',
			'San Martín Pachivia (Pachivia)',
			'Venta de Ocotillos',
			'Nopalapan',
			'Nicolás Ruiz',
			'Multé',
			'Mazatlán',
			'Los Ídolos',
			'Los Cerritos',
			'Los Ángeles Tetela',
			'Lerdo de Tejada',
			'Las Rosas',
			'Ciudad Valles',
			'Zontecomatlán de López y Fuentes',
			'Santa María Zolotepec',
			'Zimatlán de Álvarez',
			'Zapotitlán',
			'Yecuatla',
			'Vista Hermosa',
			'Vallecillo',
			'Tuzamapan',
			'Tulancingo',
			'Tlaxco',
			'Tlalnepantla',
			'Tlacolula de Matamoros',
			'Texcala',
			'Cuautitlán Izcalli',
			'Tepeojuma',
			'Teopisca',
			'Tamalín',
			'Talol',
			'Soyatitán',
			'Soyaniquilpan',
			'San Sebastián del Monte',
			'Santa Isabel Xiloxoxtla',
			'Santo Domingo de Guzmán',
			'Santiago Tlaltepoxco',
			'Santa Rita Tlahuapan',
			'Santa María Atlihuetzian',
			'Santa Lucía',
			'Santa Cecilia Jalieza',
			'Pantelhó',
			'San Pedro Yólox',
			'Tonalixco',
			'San Miguel Quetzaltepec',
			'San Mateo Xoloc',
			'San Martín Tlapala',
			'San Lorenzo Cuaunecuiltitla',
			'San Juan Mixtepec',
			'San Juan Mixtepec',
			'San José Tenango',
			'Soyaltepec',
			'San Baltazar Loxicha',
			'Texcala',
			'San Andrés Calpan',
			'Rincón de Guadalupe',
			'Reforma de Pineda',
			'Quechultenango',
			'Santa Cruz Pueblo Nuevo',
			'Paso Hondo',
			'Oviedo',
			'Otatitlán',
			'Olintla',
			'Nicolás Bravo',
			'Nealtican',
			'Nauzontla',
			'Ciudad de Nanacamilpa',
			'Motozintla',
			'Mazatecochco',
			'Matzaco',
			'Mariano Escobedo',
			'Magdalena Teitipac',
			'La Magdalena Chichicaspa',
			'Jalpa de Méndez',
			'Ixhuatlán del Café',
			'Huixquilucan de Degollado',
			'Hueyapan',
			'Huehuetoca',
			'Hidalgo',
			'El Jagüey',
			'El Blanco',
			'Comoapan',
			'Cohuecán',
			'Ciénega de Zimatlán',
			'Chunhuhub',
			'Chilapa de Álvarez',
			'Cacahoatán',
			'Bustamante',
			'Buenavista de Cuéllar',
			'Bochil',
			'Berriozábal',
			'Bella Vista',
			'Alpatláhuac',
			'Ahuajutla',
			'Acopinalco del Peñón',
			'Acatlán',
			'San Miguel Abejones',
			'San Lorenzo Tlalmimilolpan',
			'Santiago Malacatepec',
			'San Juan Colorado',
			'San José del Progreso',
			'San Pedro Martir',
			'Jalapita',
			'Saloya 2da. Sección',
			'Dos Montes',
			'Veinte de Noviembre',
			'Texcaco',
			'Bomanxotha',
			'Santa Úrsula',
			'Huitel',
			'San José Xicohténcatl',
			'Santa María Ixtulco',
			'San Miguel Analco',
			'El Tumbo',
			'Nuevo Xochimilco',
			'Guadalupe Victoria',
			'Galecio Narcia',
			'Tzajalchén',
			'Río Blanco',
			'Lomantán',
			'El Puerto',
			'Dieciséis de Septiembre',
			'Calzada Larga',
			'Nuevo México',
			'Ahuacatitlán',
			'La Soledad Barrio',
			'Ganzda',
			'La Unión',
			'Hueyapán',
			'San Mateo Soltepec',
			'Libertad Álvaro Obregón',
			'Ukúm',
			'Ocotillo',
			'Cuyuxtlahuaca',
			'Francisco I. Madero',
			'Tierra Colorada',
			'Manuel León',
			'Peñuela',
			'Buenavista',
			'San Francisco',
			'Tetzacual',
			'Felipe Carrillo Puerto',
			'La Sombra',
			'Calvario Buenavista',
			'San Lucas Ocotepec',
			'Santa Ana la Ladera',
			'San Isidro Boxipe',
			'Santa María del Llano',
			'La Concepción de Hidalgo',
			'San Martín Toltepec',
			'San Nicolás Tlazala',
			'Santa Cruz Huitzizilapan',
			'Ojo de Agua',
			'Villa Juárez',
			'Santa Isabel',
			'San Martín de Zula',
			'Cañadas de Obregón',
			'Tuxpan',
			'Tuxpan',
			'San Martín Totolán',
			'Tolimán',
			'Tequisquiapan',
			'Tejocote de Calera',
			'Tecuala',
			'Tanaco',
			'Santa Rosa',
			'Santa María del Río',
			'Santa María de los Ángeles',
			'Santa Bárbara',
			'San Pedro del Gallo',
			'San Martín Hidalgo',
			'Lo de Marcos',
			'San Antonio de Fernández',
			'Sain Alto',
			'Roque',
			'Ricardo Flores Magón',
			'Puruarán',
			'Primero de Mayo',
			'Piedras Negras',
			'Paso Nacional',
			'Minatitlán',
			'Mesa del Nayar',
			'Mazamitla',
			'Marín',
			'Lomas de Tejeda',
			'La Loma',
			'Allende',
			'Imuris',
			'Tetillas',
			'Ciudad Guadalupe Victoria',
			'Emiliano Zapata',
			'El Tequesquite',
			'El Rucio',
			'El Quince',
			'Porvenir',
			'El Peñasco',
			'El Molino',
			'El Molino',
			'Ejido Sonora',
			'El Porvenir',
			'San Miguel Cuyutlán',
			'Cuitzeo (La Estancia)',
			'Copándaro de Galeana',
			'Jecopaco',
			'Anáhuac',
			'Cerritos',
			'Buenavista',
			'Buenavista',
			'Bavispe',
			'Bariometo',
			'Badiraguato',
			'Ayotitlán',
			'Ario de Rayón',
			'Apaseo el Grande',
			'Ahuirán',
			'San Ignacio',
			'San Francisco de Durán',
			'San José del Potrero',
			'Santa Catarina',
			'Gavia de Rionda',
			'Los Fierros',
			'Guándaro',
			'Jesús del Monte',
			'Dios Padre',
			'San Luis de La Loma',
			'La Nueva Victoria',
			'Xbacab',
			'Aurelio Manrique',
			'Molino de Camou',
			'Providencia',
			'Mitras Poniente',
			'San Lucas el Grande',
			'Chiconcuac',
			'Lomas Verdes',
			'Guadalupe Victoria',
			'Ciudad de Cuetzalan',
			'Valle de Lincoln',
			'El Pedregal de Guadalupe Hidalgo',
			'Poblado 10',
			'San Miguel Tianguistenco',
			'Colonia Emiliano Zapata',
			'Arena 1ra. Sección',
			'Barrio San Joaquín el Junco',
			'Colorines',
			'Rizos de la Joya (Rizos del Saucillo)',
			'Tlacotepec de José Manzo',
			'Loma Larga (Barrio de Loma Larga)',
			'San José Monte Chiquito',
			'Xonalpu',
			'Bocaneo (San Pedro)',
			'Heriberto Valdez Romero (El Guayabo)',
			'El Pozole',
			'Leacaman',
			'San Antonio la Portilla',
			'Fraccionamiento Real del Valle',
			'Guásimas (De Belem)',
			'Vamos Tamaulipas',
			'Buenos Aires',
			'El Llano Santa María',
			'Santa Martha Hidalgo',
			'Cuonetzingo',
			'Cañada del Tabaco',
			'Paseos de las Haciendas [Fraccionamiento]',
			'Primera de Analco',
			'El Chinaco (El Pujido)',
			'Fermín Rabadán Cervantes',
			'Usipa',
			'Polvillos (San Bartolo Quinta Sección)',
			'Mérida',
			'Cebadilla 1ra. Sección',
			'Lázaro Cárdenas',
			'Sinahua',
			'Santa Rita',
			'San Andrés Cohamiata',
			'Los Bahuises',
			'San Pedro Matamoros',
			'Empaque Tarriba',
			'Los Dulces Nombres',
			'Lomas de Tlatelolco',
			'Barrio de Puentecillas',
			'Colonia Hidalgo (El Tecolote)',
			'Colonia Constitución',
			'Arroyo Zapotillo',
			'La Concepción (La Concha)',
			'Las Trancas',
			'Lomas de Romero',
			'Santiago Tlacochcalco',
			'San José Buena Vista',
			'Zacamulpa',
			'Tetelcingo',
			'Colonia Alborada',
			'Colonia Palo Prieto (Chipitongo)',
			'Melchor Ocampo',
			'Monclova Segundo Sector',
			'Laguna del Mante',
			'Zapotitlán Palmas',
			'Yanga',
			'Xoampolco',
			'Álvaro Obregón',
			'Villa Hidalgo',
			'Villahermosa',
			'Unión Juárez',
			'Unión Buenavista',
			'Tlacotepec de Mejía',
			'Tihuatlan',
			'Tezontepec de Aldama',
			'Tepetitla',
			'Tenenexpan',
			'Tempoal de Sánchez',
			'Congregación el Tajín',
			'Brisas Barra de Suchiate',
			'Sinanche',
			'Tlahuitoltepec',
			'Texcalac',
			'Santa María Chicometepec',
			'Santa Cruz Xoxocotlán',
			'Villa de Etla',
			'San Pedro Tlanixco',
			'San Juan y San Pedro Tezompa',
			'San Pedro Buenavista',
			'Ciudad de Atlixco',
			'San Nicolás el Chico',
			'San Miguel Tlacamama',
			'San Juan Cacahuatepec',
			'Teacalco',
			'San Juan Atzompa',
			'San Isidro Apango',
			'San Ildefonso Villa Alta',
			'San Germán',
			'Salinas',
			'Salina Cruz',
			'Saladero',
			'Rayón',
			'Pololcingo',
			'Pisaflores',
			'Perla de Acapulco',
			'Omoa',
			'Nueva Independencia',
			'Nicolás Romero',
			'Naolinco de Victoria',
			'Molango',
			'Michac',
			'Miahuatlán de Porfirio Díaz',
			'Miahuatlán',
			'Las Margaritas',
			'La Laja',
			'Lagunilla',
			'Kimbila',
			'Jalacingo',
			'Ixtolco de Morelos',
			'Ixil',
			'Hornitos',
			'San Gabriel Azteca',
			'Fontezuelas',
			'San Lucas',
			'Cuapiaxtla',
			'Comitán',
			'Cocula',
			'Ciudad del Maíz',
			'Cholul',
			'Chiquihuitlán de Benito Juárez',
			'Chikindzonot',
			'Chapultenango',
			'Cerro Gordo',
			'Nuevo Balsas',
			'Ayutla',
			'Ayapango',
			'San Miguel Axoxuca',
			'Atlapexco',
			'Atitalaquia',
			'Asunción Ixtaltepec',
			'La Ascensión',
			'Allende',
			'Ahuatepec de Camino',
			'Acambay',
			'San Nicolás Tecomatlán',
			'San Nicolás',
			'San Marcos Zacatepec',
			'San Andrés Dinicuiti',
			'Mazín Grande',
			'Paso Canoa',
			'San Sebastián Tutla',
			'Palomas',
			'Xiteje de Zapata',
			'San Juan Achichilco',
			'Cañada',
			'El Jiadi',
			'Santa María la Calera',
			'Carboneras',
			'Chimalpa y Tlalayote',
			'Lázaro Cárdenas',
			'Chapallal Grande',
			'Yaltem',
			'Aldama',
			'Chalam',
			'Justo Sierra',
			'Benemérito de las Américas',
			'El Edén',
			'Venustiano Carranza',
			'Nuevo Vicente Guerrero',
			'Manuel Ávila Camacho',
			'Doctor Domingo Chanona',
			'Doctor Belisario Domínguez (La Barra)',
			'Porfirío Díaz',
			'San Mateo Huitzilzingo',
			'Santo Domingo Aztacameca',
			'Conejeras',
			'Nanacatlán',
			'San José Buenavista',
			'Santa Cruz Ajajalpan',
			'Teotlalco',
			'Huachinantla',
			'San Antonio Cárdenas',
			'El Fresno',
			'El Bejuco',
			'Los Saucitos',
			'Marquelia',
			'Zoyatlán',
			'Tihosuco',
			'Zacalaca',
			'La Palma',
			'La Guadalupe',
			'La Libertad',
			'La Guadalupe',
			'Poblado Tres',
			'La Cerquilla',
			'La Chinantla',
			'Visthá',
			'Mayorazgo de León',
			'Barrio de San Isidro',
			'Santa Cruz Bombatevi',
			'Dongu',
			'Santa Clara de Juárez',
			'San Miguel Yuxtepec',
			'San Marcos Yachihuacaltepec',
			'San Miguel Totocuitlapilco',
			'Miguel Hidalgo',
			'Cañada de Madero',
			'Emiliano Zapata (San José Bata)',
			'Rosario',
			'Villa Hidalgo',
			'Tlachichila',
			'Sánchez',
			'La Esperanza',
			'Benito Juárez',
			'Guadalupe Victoria',
			'Portaceli',
			'San José de los Reynoso',
			'José María Pino Suárez',
			'Villa García',
			'La Tesorera',
			'Tecolots',
			'Soledad Nueva',
			'Soledad de Graciano Sánchez',
			'Sisoguichi',
			'Sierra Mojada',
			'Senguio',
			'Santiago Undameo',
			'Santa Cruz Luján',
			'San Nicolás Tolentino',
			'San Marcos',
			'San Lucas Texcaltitlán',
			'San Juan de Sabinas',
			'San José Temascatío',
			'San José Iturbide',
			'San José Casas Caídas',
			'San Gabriel',
			'San Francisco de Borja',
			'San Felipe',
			'San Felipe Aztatán',
			'Romita',
			'Santiago de Pochotitán',
			'Patambó',
			'Pastora',
			'El Paracho',
			'Ejido Ohuira',
			'Ocumicho',
			'Nextipac',
			'Nácori Chico',
			'Monte Escobedo',
			'La Orilla',
			'La Luz',
			'Lagunillas',
			'La Fortuna',
			'Puente Grande',
			'Jucutacato',
			'Jesús María',
			'Irámuco',
			'Huandacareo',
			'Higueras',
			'Hidalgo',
			'Felipe Angeles',
			'El Tecolote',
			'El Saúz',
			'El Llanito',
			'El Dorado',
			'El Colorado',
			'Mochis',
			'Cocucho',
			'Ciudad Altamirano',
			'Cieneguillas',
			'Chinobampo',
			'Carmen',
			'Capilla de Guadalupe',
			'Canatlán',
			'Campo Carretero',
			'Bucerías',
			'Bacanora',
			'Ayutla',
			'Atoyac de Álvarez',
			'Atotonilquillo',
			'Ascención',
			'Arteaga',
			'Ajijic',
			'Agiabampo Uno',
			'Santa Casilda',
			'Emiliano Zapata',
			'Paseos de la Providencia [Fraccionamiento]',
			'Villa Morelos',
			'Los Lorenzos',
			'Santa Rosa',
			'Jocoqui',
			'San Antonio el Chico',
			'Estación Pénjamo',
			'La Compañía',
			'Cuadrilla de Dolores',
			'Santiago Coachochitlan',
			'Concepción del Monte',
			'Las Rosas',
			'Guayameo',
			'Villa Madero',
			'Aguas Blancas',
			'Colonia Lindavista',
			'San José Guadalupe Otzacatipan',
			'El Diez',
			'Emiliano Zapata',
			'La Lima',
			'San Felipe',
			'Villas de la Loma',
			'Villas de la Laguna',
			'Unidad Habitacional José María Morelos y Pavón',
			'Ocopulco',
			'Fraccionamiento las Fuentes',
			'Leona Vicario',
			'San José el Saladillo (El Saladillo)',
			'Chicahuaxtla',
			'Unidad Habitacional Santa Teresa',
			'Colonia Emiliano Zapata Ejido de Tenancingo',
			'Chiloljá',
			'San Miguel del Progreso',
			'Santa Rosa de Lima',
			'Lindavista',
			'San Francisco Zacapexpan',
			'Colonia Latinoamericana',
			'Guadalupe Minerva',
			'San Vicente Zoyatlán',
			'Colonia Tulteca Teopan',
			'San Atenógenes (La Villita)',
			'Buena Vista',
			'La Joya',
			'La Mohonera',
			'Colonia Adolfo López Mateos',
			'Loma Alta Taxhimay',
			'Los Reyes Tlanechicolpan',
			'Temalacaco',
			'Emilio Carranza (Santa Cruz)',
			'Benito Juárez II (San Martín)',
			'San José Ozumba',
			'Tzintzimeo',
			'Puxtla',
			'La Laguna',
			'San Pablo de los Gallos',
			'Rancho Nuevo de Morelos (De Guadalupe)',
			'El Copetillo',
			'Tierra Blanca',
			'Pueblo Nuevo',
			'El Progreso',
			'Las Lomas',
			'San Cristóbal Hidalgo',
			'Banderas (Guatacalca 2da. Sección)',
			'San Antonio Matlahuacales',
			'San Ignacio',
			'El Salvador (Ranchito San José del Carmen)',
			'Santa Ana de Guerrero (El Cascabel)',
			'Boquiapa',
			'El Ojuelo',
			'Colonia el Pirame',
			'Ojite Rancho Nuevo',
			'El Salitre',
			'El Progreso',
			'El Pueblito (Garbanzal)',
			'San José Manzanitos',
			'La Toma',
			'Centro de Readaptación Social',
			'Calpanería Atezquilla',
			'Las Pintas',
			'Tetlatzinga',
			'Unidos Avanzamos',
			'San Simón Atzitzintla',
			'Ejido de la Finca',
			'Barrio de San Miguel',
			'Tequisistlán Primero',
			'El Paraíso',
			'La Isla Km 10',
			'Guayalejo',
			'Pujal-Coy',
			'La Palma',
			'Villas de la Hacienda [Fraccionamiento]',
			'Ex-Rancho San Dimas',
			'Tlamatoca',
			'Tlahuelilpan',
			'Tláhuac',
			'Tilapa',
			'San Marcos Tecomaxusco',
			'Teapa',
			'Tatatila',
			'Sisal',
			'Simojovel de Allende',
			'Santiago Zoochila',
			'Santiago Nuyoó',
			'Miahuatlán',
			'Santa María Huazolotitlán',
			'Santa María Chimalhuacán',
			'Santa María Apazco',
			'Santa Cruz Ozolotepec',
			'San Simón',
			'Huixcolotla',
			'San Rafael Ixtapalucan',
			'San Pedro Mixtepec',
			'San Pedro Huilotepec',
			'San Miguel Balderas',
			'San Mateo Almomoloha',
			'San Martín Chalchicuautla',
			'Teolocholco',
			'San Luis Ayucán',
			'San Juan Zitlaltepec',
			'San José de las Flores',
			'San Jerónimo Xayacatlán',
			'San Hipólito',
			'San Francisco Oxtotilpan',
			'San Cristóbal de las Casas',
			'San Baltazar Guelavila',
			'San Antonio',
			'Tepetlapa',
			'San Andrés Solaga',
			'Ricardo Flores Magón',
			'Ramón F. Balboa',
			'Quecholac',
			'Poza Rica de Hidalgo',
			'Pluma Hidalgo',
			'Papantla de Olarte',
			'Ometepec',
			'Oluta',
			'Nopala de Villagran',
			'Nanchital de Lázaro Cárdenas del Río',
			'Motul',
			'Matías Romero',
			'Magdalena Contreras',
			'Valle de Vázquez',
			'La Tigrilla',
			'La Palmilla',
			'La Misión',
			'Laguna del Cofre',
			'Jumiltepec',
			'Jopala',
			'Iguala de la Independencia',
			'Santa Cruz Huitziltepec',
			'Huejutla de Reyes',
			'Huajintepec',
			'Ex-Hacienda el Hospital',
			'Puntilla Aldama',
			'El Espinal',
			'El Ocotito',
			'Cucuyulapa Primera Sección',
			'Cocotitlán',
			'Ciudad Miguel Alemán',
			'San Pedro Chimay',
			'Chicxulub Puerto',
			'Chicontla',
			'San Andrés Chicahuaxtla',
			'Celestún',
			'Benito Juárez',
			'Baca',
			'Atzacoaloya',
			'Atizapán',
			'Apizaco',
			'Amatenango de la Frontera',
			'Altamira',
			'Alcozauca de Guerrero',
			'Álamo',
			'Acatlán de Pérez Figueroa',
			'Magdalena Apasco',
			'Santa Rosa',
			'Magdalena Zahuatlán',
			'Chila',
			'Tamazulapam',
			'Valdeflores',
			'Aquiles Serdán',
			'Benito Juárez',
			'El Tular',
			'Guatacalca',
			'Chalahuiyapa',
			'Zoquitipán',
			'Álvaro Obregón',
			'Bangandhó',
			'San Pedro Tlacotepec',
			'El Carmen Xalpatlahuaya',
			'Nicolás Bravo',
			'Toluca de Guadalupe',
			'Maravillas',
			'Arroyo Grande',
			'Rincón Chamula',
			'Alfonso Moguel',
			'El Censo',
			'Jalisco',
			'Vicente Guerrero',
			'San Nicolás',
			'San Gaspar Tlahuelilpan',
			'Santiago Mamalhuazuca',
			'Zentlalpan',
			'Tlaltepango',
			'El Fuerte de la Unión',
			'Telpatlán',
			'Santa Cruz',
			'La Joya',
			'Las Cruces',
			'Horcasitas',
			'San Francisco Grande',
			'Kinil',
			'Tronconal',
			'Dehesa',
			'El Potrero',
			'Lindavista',
			'Nuevo San Martín',
			'Cuendo',
			'San Antonio Enchisi',
			'La Esperanza',
			'San Antonio Nixini',
			'Tlacuitlapa',
			'Ignacio Zaragoza',
			'Colonia Veinte de Noviembre',
			'Cristo Rey',
			'El Limón de los Ramos',
			'Francisco I. Madero',
			'Villa Hidalgo',
			'Valtierrilla',
			'Valle de Juárez',
			'El Vado de San Pedro',
			'Uzeta',
			'Tlazazalca',
			'Tesistán',
			'Tepache',
			'San Antonio Tariácuri',
			'Tamazula de Victoria',
			'Talpa de Allende',
			'Sayulilla',
			'San Vicente',
			'Santo Tomás de los Plátanos',
			'Santa Rita',
			'Santa Isabel',
			'Santa Catarina',
			'El Salvador',
			'San Quintín',
			'San Pedro Guasave',
			'Ibarra',
			'San Lorenzo',
			'San José de Viñedo',
			'San Cristóbal',
			'San Andrés Ziróndaro',
			'El Sahuaral',
			'Ruíz',
			'Morelos',
			'Pueblo Nuevo',
			'Penjamillo de Degollado',
			'La Peñita de Jaltomba',
			'Pantanal',
			'Palo Alto',
			'Pabellón de Hidalgo',
			'Oquitoa',
			'Nuevo León',
			'Santa Cruz',
			'Moroleón',
			'Mexquitic de Carmona',
			'Taxtes',
			'La Paz',
			'La Manzanilla de la Paz',
			'La Manzanilla',
			'La Labor',
			'Laguna de Guadalupe',
			'La Calle',
			'La Aldea',
			'Jamaica',
			'Hostotipaquillo',
			'Ejido Gogorrón (Ex-Hacienda de Gogorrón)',
			'Escuadrón Doscientos Uno',
			'El Tejocote',
			'El Salto',
			'El Salto',
			'Purísima Concepción Mayorazgo',
			'El Huizache',
			'El Carrizal',
			'La Purísima',
			'Diez de Octubre',
			'Cucurpe',
			'Cotija de la Paz',
			'Villa de Costa Rica',
			'Cortazar',
			'Estación Conchos',
			'Concepción de Buenos Aires',
			'Siviral',
			'Cítala',
			'Cieneguitas',
			'Cenobio Moreno',
			'Casas Grandes',
			'Carranco',
			'Candela',
			'Buenavista',
			'Bacobampo',
			'Basaseachic',
			'Barrio Nuevo',
			'Atengo',
			'Atapán',
			'Arandas',
			'Ciudad Apodaca',
			'Angamacutiro de la Unión',
			'Amanalco de Becerra',
			'Ahuatlán',
			'Agua Nueva',
			'Villa Nicolás Bravo',
			'Noria del Borrego (Norias)',
			'San Juan de Abajo',
			'Alfaro',
			'El Varal',
			'Los Prietos',
			'Victoria de Cortazar',
			'Chitejé de Garabato',
			'Belisario Domínguez',
			'Zurumbeneo',
			'San José del Rincón Centro',
			'Santiago Huitlapaltepec',
			'Puerto de Aguirre',
			'Ponciano Arriaga',
			'San Antonio Ocopetlatlán',
			'San Buenaventura',
			'Crucecita',
			'Conjunto Habitacional Villas del Pedregal',
			'Real del Sol',
			'Atoluca',
			'Paseo del Prado',
			'Zacualpan de Amilpas',
			'Colonia Santa Cecilia (La Sauceda)',
			'Colinas de Santa Cruz Segunda Sección',
			'Simeprodeso (Colectivo Nuevo)',
			'Rincones de la Hacienda',
			'Constitución',
			'Colonia San Luis Rey',
			'Gabriel Leyva Velázquez',
			'San José Tenería (Tenería)',
			'Poblado C-21 Licenciado Benito Juárez García',
			'Barrio de Jesús Fracción Primera',
			'Colonia 18 de Marzo',
			'El Copalar',
			'Constancio Farfán (La Pascuala)',
			'San Pedro de los Sauces',
			'Lagartera 1ra. Sección',
			'Hacienda Vieja del Castillo (Castillo Viejo)',
			'Jardines del Edén',
			'Emiliano Zapata (El Ranchito)',
			'El Arco',
			'Huapacal 2da. Sección (Punta Brava)',
			'Sitalá',
			'Colonia Adolfo Ruiz Cortines (Colonia Obrera)',
			'Colonia Guadalupe Victoria',
			'El Porvenir (El Porvenir de Arriba)',
			//'Santa Ana',
			'El Muey',
			'Martínez Domínguez',
			'Colonia Doctor Gustavo Baz',
			'La Rosa',
			'Ninguno [CERESO]',
			'Gaviotas Sur (El Cedral)',
			'Santo Tomás',
			'Norte 1ra. Sección (San Julián)',
			'Lipuntahuaca',
			'El Palmar (San Gabriel)',
			'Ex-Hacienda del Copal',
			'Déxtho de Victoria',
			'Javier Rojo Gómez',
			'Cuauhtémoc',
			'Tlacoaxtla',
			'Cala Norte',
			//'Santa Ana',
			'Oriente 1ra. Sección (Santo Domingo)',
			'Zizicazapa',
			'Atlamajalcingo del Río',
			'Pascala del Oro',
			'El Césped',
			'San Carlos Autopan',
			'Lázaro Cárdenas',
			'Cupuán del Río',
			'Pechucalco',
			'San José Yashitinín',
			'Fraccionamiento Privadas del Sol',
			'Gregorio Méndez',
			'Los Sauces',
			'Colonia Fraccionamiento el Puente',
			'Cerro del Murciélago',
			'Las Brisas',
			'Llano de las Flores (Barrio del Hueso)',
			'Fraccionamiento la Mezquitera',
			'Paso Blanco',
			'Colonia San Francisco',
			'Ejido Zaragoza',
			'Zacapu',
			'La Joya',
			'Prados de Santa Rosa',
			'Tres Valles',
			'Tezapotla',
			'Zumpango del Río',
			'Zoogocho',
			'Zacatla',
			'Yaxcopoil',
			'Xochinanacatlán',
			'Xaltepec',
			'Tzucacab',
			'Esteros',
			'Cuauhtémoc',
			'Plan de Iguala',
			'Yaonahuac',
			'Xochitlan Todos Santos',
			'Xochitepec',
			'Xochistlahuaca',
			'Salvador Urbina',
			'Santa Rosa Treinta',
			'Tonalapa del Sur',
			'Tlanchinol',
			'Tlahuelompa (San Francisco Tlahuelompa)',
			'Tlacuilotepec',
			'Tixkokob',
			'San Juan Tilcuautla',
			'Texcapa',
			'Tetepango',
			'Teloloapan',
			'Soto la Marina',
			'San Vicente Lachixío',
			'Chautla',
			'Santiago Coltzingo',
			'Santa María Rayón',
			'Santa María Apaxco',
			'Santa Ana Nextlalpan',
			//'Santa Ana',
			'Zinacatepec',
			'San Sebastián',
			'San Sebastián',
			'San Pablo Tecalco',
			'San Miguel Mimlapan',
			'San Marcos Tlacoyalco',
			'San Lorenzo Oyamel',
			'San Lorenzo',
			'San Juan Tepa',
			'San Juán Lachigalla',
			'San Juan Diuxi',
			'San José Lachiguirí',
			'San Joaquín',
			'San Francisco del Mar Viejo',
			'Teotlaltzingo',
			'Amaxac de Guerrero',
			'Zoyatzingo',
			'San Agustín Amatengo',
			'Samahil',
			'Salitrillo',
			'Santa Catalina Quieri',
			'Piedra Pinta',
			'Pachiquita',
			'Ocotepec',
			'Ocosingo',
			'Nuevo Pacayal',
			'Nolo',
			'Muna',
			'Mozomboa',
			'Misantla',
			'Metlatónoc',
			'Mazatán',
			'Heroica Matamoros',
			'Martinez',
			'Mafafas',
			'La Venta',
			'La Tortuga',
			'Vegas de la Soledad y Soledad Dos',
			'La Laguna',
			'Jitotol',
			'Ciudad de Huitzuco',
			'Huitzila',
			'Huixtepec',
			'San Juan Huiluco',
			'Huehuetán',
			'Huajoyuca',
			'Guadalupe Hidalgo',
			'General Treviño',
			'Dzidzantun',
			'Domingo Arenas',
			'Cuetzalan',
			'Coacotla',
			'Chocamán',
			'Chiautla de Tapia',
			'Chavarrillo',
			'Cazones de Herrera',
			'Candelaria Loxicha',
			'Calcehtok',
			'Buena Vista',
			'Belén',
			'Atalpan',
			'Arroyo Hondo',
			'Arcelia de Rodríguez',
			'Alto Lucero',
			'Villa de Almoloya de Juárez',
			'Axtla de Terrazas',
			'Acuitlapan',
			'Altzayanca',
			'Cuauhtempan (San Andrés Cuauhtempan)',
			'San Juan Jicayán',
			'Santiago Llano Grande',
			'Teojomulco',
			'San Pedro Coxcaltepec Cántaros',
			'San Juan Sayultepec',
			'Macín Chico',
			'San Felipe Cihualtepec',
			'Quintín Arauz',
			'Gregorio Méndez',
			'Caxuxi',
			'La Sabinita',
			'Santa María Batha',
			'Pedregal de San José',
			'San Lorenzo Sóltepec',
			'Topilco de Juárez',
			'San Francisco Cuexcontzi',
			'La Aurora',
			'Arroyo Granizo',
			'América Libre',
			'Concepción',
			'Cash',
			'Villamorelos',
			'Manuel Ávila Camacho (Ponte Duro)',
			'El Palmarcito',
			'Santa Cruz Pueblo Nuevo (Pueblo Nuevo)',
			'San Juan Tepecoculco',
			'Tecamachalco',
			'Santa María Tecuanulco',
			'Tulantongo',
			'Santa María Nativitas',
			'Jaltocan',
			'Cuacuila',
			'Allende',
			'Xonocuautla',
			'Tula',
			'Huehuetlan el Chico',
			'Bolonchén de Rejón',
			'Lagunillas',
			'Zumpango',
			'Guadalupe Victoria',
			'El Hatito',
			'Apantéopan',
			'Colonia Lealtad',
			'La Guadalupe',
			'Calería',
			'Nuevo Ixcatlán',
			'Aguilera',
			'San Lorenzo Tenochtitlán',
			'San Juan Jalpa Centro',
			'San Jerónimo de los Jarros',
			'Huemetla',
			'San Andrés Cuexcontitlán',
			'El Puerto Magú',
			'Emiliano Zapata',
			'Alberto Carrera Torres',
			'Chihuahuita',
			'Lamadrid',
			'La Esmeralda',
			'Urén',
			'Tuzantla',
			'Trejos',
			'Teremendo',
			'Siqueros',
			'Sapioris',
			'Santo Domingo',
			'Santiago',
			'José Guadalupe Aguilera (Santa Lucía)',
			'San Simón de Guerrero',
			'San Nicolás de los Agustinos',
			'San Luis Potosí',
			'San Lorenzo',
			'San Julián',
			'San Juan Bautista',
			'San José Itho',
			'San Jerónimo',
			'San Felipe',
			'San Carlos',
			'San Antonio de Rivas',
			'Rincón de Parangueo',
			'Rayón',
			'Plancarte',
			'Ojo de Agua de Solano',
			'Obrajuelo',
			'Obrajuelo',
			'Nuevo México',
			'Nocupétaro',
			'Nochistlán de Mejía',
			'Naica',
			'Rodolfo Sánchez Taboada',
			'Malagana (San Antonio del Monte)',
			'Los Otates',
			'Loma Tendida',
			'Unión de Corrientes',
			'La Unión',
			'Las Pintas de Arriba',
			'La Rivera',
			'La Presa',
			'La Perla',
			'La Huacana',
			'Ejido Lagunitas',
			'Lagunillas',
			'Ixtlahuacán de los Membrillos',
			'Hidalgo',
			'Francisco I. Madero',
			'Estancia de Ánimas',
			'El Potrero de Sataya',
			'Ejido Nuevo León',
			'Islita',
			'Culiacán',
			'Cuauhtémoc',
			'Contepec',
			'Compuertas',
			'Ampliación Colonia Lázaro Cárdenas',
			'Chamácuaro',
			'Bustamante',
			'Boquillas',
			'Santiago Azajo',
			'Villa de Arista',
			'Ameche',
			'Amatlán de Cañas',
			'Ahualulco de Mercado',
			'Ahuacatlán',
			'Agua Verde',
			'Abasolo',
			'San Nicolás de la Condesa',
			'Franco Tavera',
			'San José de la Montaña',
			'San Roque',
			'Carrizal Grande',
			'Bajío de Bonillas',
			'Los Desmontes',
			'San Andrés Coru',
			'El Chauz',
			'Pantla',
			'San José Poliutla',
			'La Piedad',
			'Col. Bosques de las Lomas',
			'Úrsulo Galván',
			'San Antonio del Coyote',
			'Barrio de México',
			'San Sebastián del Sur',
			'Santa Cruz del Monte',
			'Galaxia la Noria',
			'Calera Chica',
			'Pocitos',
			'Palomar',
			'Unidad Acaquilpan',
			'Santa María la Asunción',
			'Campestre Flamboyanes',
			'Nejapa',
			'El Saucillo (Fraccionamiento)',
			'Colonia 2 de Septiembre',
			'Toreo Bajo (El Toreo Bajo)',
			'Chalchocoyo',
			'Fraccionamiento Valle Dorado',
			'Tlatempan',
			'Bosques de la Magdalena',
			'Conjunto Habitacional Ecológico SUTEYM',
			'El Varal (San Sebastián Número Uno)',
			'Fraccionamiento Ciudad Yagul',
			'San Bartolo del Llano (San Isidro)',
			'Luis Rodríguez (El Vergel)',
			'Ciudad Morelos',
			'Valle Huejúcar (Fraccionamiento Popular) [Fraccionamiento]',
			//'Santa Ana',
			'Villas Fundadores [Fraccionamiento]',
			'Paso Nacional',
			'Pueblo Nuevo Tlalmimilolpan',
			'San José del Jagüey',
			'Rancho Bellavista [Fraccionamiento]',
			'Arroyo Choápam',
			'San Martín',
			'Jolotichán',
			'Colonia los Aguiluchos',
			'Barrio de San Juan',
			'Barrio de Canales',
			'Rincón de Cedeños (Rincón de Dolores)',
			'Colinas del Sol',
			'Artículo Primero Constitucional',
			'Paseos del Marqués',
			'San Andrés Playa Encantada (El Podrido)',
			'Banus Vallarta (Verde Vallarta)',
			'Progreso',
			'La Ibérica (La Gotera)',
			'Fraccionamiento Laureles Eréndira',
			'Nicolás Bravo 5ta. Sección (Punta Brava)',
			'Ranchería de Pocitos',
			'Acatla',
			'Chiconamel',
			'La Palmita y Anexos (La Presita)',
			'Macutzio',
			'El Carmen',
			'Loma Bonita',
			'Constitución',
			'Guadalupe Calderón',
			'Barrio de Guadalupe del Mezquitillo',
			'Nueva Frontera',
			'Sargento López 2da. Sección (El Chuzo)',
			'La Estancia Sector Uno (La Estancia)',
			'La Presita Segundo Cuartel',
			'Santa Rosa Segundo',
			'Rincón de los Reyes',
			'Praderas del Potrero',
			'La Sala (La Caliente)',
			'Ignacio Zaragoza 1ra. Sección',
			'Texocoyohuac',
			'La Cañada',
			'Santa Fe de la Purísima',
			'San Clemente de Lima',
			'El Progreso',
			'Santa María Acatepec',
			'San Juan Bautista la Raya',
			'Tlacojalpan',
			'Tiholop',
			'Tibolón',
			'Texcatepec',
			'Tetecalita',
			'Tequixquiac',
			'San Martín Tequesquipan (Tequesquipan)',
			'Tepetitán',
			'Tepehuacán de Guerrero',
			'Tekal de Venegas',
			'Tecolapan',
			'Tapilula',
			'Tampico',
			'Tamán',
			'Zelocotitlán',
			'Señor',
			'Santo Domingo',
			'Santiago Laollaga',
			'Santiago Comaltepec',
			'Santiago Tulantepec',
			'Ipalapa',
			'Santa María Huatulco',
			'Santa María Ecatepec',
			'Santa María',
			'Santa Rita',
			'Sanata Lucía',
			'Santa Inés Varela la Luz',
			'San Rafael Calería',
			'San Pedro y San Pablo Tequistepec',
			'San Pedro Ixtlahuaca',
			'San Pedro Ixcatlán',
			'San Pablo Etla',
			'Apetatitlán Antonio Carbajal',
			'Temextla',
			'San Nicolás',
			'San Marcos Guaquilpan',
			'Temalacayuca',
			'San Juan Solís',
			'San Juan Ixcaquixtla',
			'Tuxtepec',
			'San Joaquín Coapango',
			'San Francisco Tetlanohcan',
			'San Francisco Logueche',
			'San Esteban Tizatlán',
			'San Andrés Tenejapan',
			'San Andrés',
			'San Agustin de las Juntas',
			'Reforma',
			'Rafael Lucio',
			'Puerto Madero',
			'Paso de Ovejas',
			'San Felipe Orizatlán',
			'Nuevo Progreso',
			'Montecristo',
			'Mezcala',
			'Metepec',
			'Mérida',
			'Melchor Ocampo',
			'Reyes Mantecón',
			'Mama',
			'Magdalena Tlacotepec',
			'Luis Gil Pérez',
			'Larráinzar',
			'La Joya',
			'La Concepción',
			'La Cañada',
			'Joaquín Miguel Gutiérrez',
			'Jalpan',
			'Huautla de Jiménez',
			'Huaquechula',
			'Hidalgotitlán',
			'Guadalupe Grijalva',
			'Francisco I. Madero',
			'Villa Emilio Carranza',
			'Melchor Ocampo',
			'El Maguey',
			'Doctor Coss',
			'Cocoyotla',
			'Chontalcoatlán',
			'Cholula',
			'Chicontepec',
			'Chetumal',
			'Chanal',
			'Catemaco',
			'Capula',
			'Calimaya',
			'Cacalchen',
			'Banderilla',
			'Atengo',
			'Apipilulco',
			'Ameluca',
			'Altepexi',
			'Ahuehuetzingo',
			'Aguacatenango',
			'Acuitlapilco',
			'Acteopan',
			'Acatzingo',
			'Santa Teresa',
			'El Molino',
			'Vicente Camalote',
			'Santa María Guelacé',
			'Ocuapan',
			'Monte Grande',
			'Ignacio Zaragoza',
			'Tepojaco',
			'Acxotla del Monte',
			'San Miguel Xochitecatitla',
			'Agua Blanca Serranía',
			'San Miguel',
			'José María Morelos y Pavón',
			'Luis Espinoza',
			'Emiliano Zapata',
			'San Alejo',
			'San Miguel Laderas',
			'San Juan Coxtocan',
			'San Juan Totolapan',
			'El Carmen',
			'San Agustín Buenavista',
			'Tlatempa',
			'Mazapiltepec',
			'Francisco Ignacio Madero',
			'Acatepec',
			'Santo Domingo Kesté',
			'El Cortés',
			'Santa Cruz',
			'Tlatzala',
			'José María Morelos',
			'El Terrero',
			'Cuiyachapa',
			'Paraje Nuevo',
			'Las Lomas de Tacamichapan',
			'Doctor Montes de Oca',
			'Gutiérrez Zamora',
			'Santa Rosa Abata',
			'Xoteapan',
			'Ojo de Agua',
			'La Guadalupana',
			'Tixmadeje Chiquito',
			'Mina México',
			'San Pedro Atocpan',
			'Santa Ana Jilotzingo',
			'Cañada de Cisneros',
			'San Pablo Huantepec',
			'Vito',
			'San Sebastián',
			'Bajío de San Nicolás',
			'Chichimequillas',
			'Dieciocho de Marzo',
			'Progreso',
			'Zapotlanejo',
			'Zapotlán del Rey',
			'Zacualpan',
			'Zacualpan',
			'Zacoalco',
			'Miguel Ahumada',
			'Trancoso',
			'Tiríndaro',
			'Tepatitlán de Morelos',
			'Teocuitatlán de Corona',
			'Tarandacuao',
			'Tanhuato de Guerrero',
			'Tacuro (Santa María Tacuro)',
			'San Sebastián el Grande',
			'San Pedro Nuevo',
			'San José de Zaragoza',
			'San Jacinto',
			'San Francisco de los Romo',
			'San Carlos',
			'Presa del Rosario',
			'Piscila',
			'Pericos',
			'Peñón Blanco',
			'Noria de Ángeles',
			'Naranja de Tapia',
			'Monterrey',
			'Santa María del Oro',
			'Malpaso',
			'Madrid',
			'Los Ramírez',
			'Los Charcos',
			// 'Los Angeles',
			'Lázaro Cárdenas',
			'La Unión del Cuatro',
			'San Nicolás de la Torre',
			'Las Ranas',
			'La Reforma',
			'La Partida',
			'La Libertad',
			'La Laguna',
			'La Concha',
			'La Calera',
			'Juraré',
			'Juchitlán',
			'Jilotlán de los Dolores',
			'Jerez de García Salinas',
			'Hormiguero',
			'Gómez Farías',
			'Encarnación de Díaz',
			'El Tuito',
			'Salado',
			'El Retiro',
			'El Papayo',
			'El Habal',
			'El Carmen',
			'Derramaderos',
			'El Crucero de Santa María',
			'Cloete',
			'Charapendo',
			'Cedros',
			'Atil',
			'Arturo Martínez Adame',
			'El Arenal',
			'Aranza',
			'Altar',
			'Ciudad de Allende',
			'Ajuno',
			'Agua Caliente',
			'Acachuén',
			'Puerta del Monte',
			'Presa Blanca',
			'San Isidro de la Concepción',
			'Rancho Nuevo de la Cruz',
			'Los Rodríguez',
			'Palmillas de San Juan',
			'Buenavista de Cortés',
			'Santiago Capitiro',
			'Cuparátaro',
			'El Porvenir',
			'Pie de Gallo',
			'Poblado Lázaro Cárdenas (La Veintiocho)',
			'Las Delicias',
			'Carbo',
			'Córdoba (Santa Leticia)',
			'Independencia',
			'El Campanario y Oradel',
			'La Pradera',
			'Javier Rojo Gómez',
			'Villas de Alcalá',
			'Los Tuzos',
			'Tianguistengo (La Romera)',
			'La Trinidad Chautenco',
			'La Joya',
			'Fraccionamiento Costa Dorada',
			'Atecucario de la Constitución (Atecuario)',
			'Colonia los Cedros',
			'El Saucillo',
			'Miguel Hidalgo',
			'El Progreso',
			'Colonia Agrícola México (Palmitas)',
			'Santa Cruz Tetela',
			'Polígonos',
			'El Nabo',
			'Betania',
			'San Miguel Espejo',
			'Veinte de Noviembre',
			'Xhixhata',
			'Tecpantzacoalco',
			'San Isidro (El Reservado)',
			'Altus Bosques',
			'La Azozuca',
			'Hacienda Tecate',
			'San Pedro Atmatla',
			'Apatauyan',
			'Guadalupe Coté',
			'Primero de Mayo',
			'Monte Alegre',
			'Villa Hermosa (Las Pozas)',
			'Guadalupe Victoria',
			'Paseo de San Javier',
			'Lázaro Cárdenas (El Empalme)',
			'Taza de Agua Ojo Zarco',
			'Benito Juárez',
			'31 de Octubre',
			'Rio de la Soledad',
			'Colonia Obrera',
			'Jilotzingo',
			'Morelos',
			'Atotonilco',
			'Zaragoza (Puerto de Medina)',
			'San Andrés de la Cal',
			'Xochicalco (Cirenio Longares)',
			'San Antonio el Paso',
			'Colonia Obrera',
			'Francisco Sarabia',
			'Plaza Nueva',
			'Yibeljoj',
			'La Magdalena Tenexpan',
			'La Soledad',
			'El Refugio',
			'San Cristóbal los Nava',
			'Piedra Blanca',
			'Ococh',
			'Colonia Francisco Javier Mina',
			'Octeyuco Dos Mil',
			'Santa Isabel',
			'Río Seco 2da. Sección (Santiaguito)',
			'Colonia Rincón Villa del Valle',
			'La Albarrada (San Francisco la Albarrada)',
			'Los Pozos',
			'Ahuehueyo Primero Centro',
			'Francisco Villa',
			'San Benito Encinal',
			'El Colorado',
			'El Tintal',
			'Ocuiltzapoyo',
			'Loma Bonita',
			'Loma Alta',
			'Nuevo Necaxa',
			'Ejido de Coscomate del Progreso',
			'Primero de Mayo',
			'San Fernando',
			'Las Palmas',
			'Zozocolco de Hidalgo',
			'Villa Victoria',
			'Tochtepec',
			'Tlaxcalantongo',
			'Tlatlauquitepec',
			'Santa Ana Jilotzingo',
			'San Sebastián Tenochtitlán',
			'San Pedro Tetitlán',
			'San Pedro Quiatoni',
			'San Pablo Villa de Mitla',
			'San Miguel Ixitlán',
			'San Miguel Aloápam',
			'San Miguel Tecuiciapan',
			'Tlaixpan',
			'San Martín Ojo de Agua',
			'San Jerónimo Taviche',
			'San Gregorio',
			'Occidente (San Francisco)',
			'San Felipe del Progreso',
			'Yauhquemehcan',
			'San Carlos',
			'Teontepec',
			'San Baltazar Chichicapam',
			'Salto de Agua',
			'Saltabarranca',
			'Puerta de Palmillas',
			'Puebla',
			'Portezuelo',
			'Pichucalco',
			'Mextepec',
			'La Mesilla',
			'La Ventosa',
			'Las Higueras',
			'Sabaneta',
			'La Guadalupe',
			'San Antonio de la Cal',
			'Jalpa',
			'Huhi',
			'Hueytamalco',
			'Huayacocotla',
			'Huamuxtitlán',
			'Güémez',
			'General Bravo',
			'Esperanza',
			'Dzityá',
			'Cuautotola',
			'Cuapiaxtla de Madero',
			'Cuajinicuilapa',
			'Collantes',
			'Concepción Capulac (La Ex-Hacienda)',
			'Colipa',
			'Coatepec',
			'Cintalapa de Figueroa',
			'Chontla',
			'Chocholá',
			'Chiltoyac',
			'Chiapa de Corzo',
			'Chan Cenote',
			'Calpulalpan',
			'Aquixtla',
			'Antiguo Morelos',
			'Jaltenango de la Paz',
			'Santa María Amajac',
			'Alfajayucan',
			'Agustín de Iturbide',
			'San Miguel Tecomatlán',
			'Flor Batavia',
			'San Francisco Loxicha',
			'Santiago Huaxolotipac',
			'San Mateo Etlatongo',
			'San Miguel Tlacotepec',
			'Cosoltepec',
			'San Andrés Hidalgo',
			'Santa Ana Zegache',
			'El Coyul',
			'Occidente 4ta. Sección',
			'Amado Gómez',
			'Nicolás Bravo',
			'Xiquila',
			'San Francisco',
			'El Huixmí',
			'Nuevo Centro de Población Agrícola el Chacón',
			'Santa Bárbara',
			'Zumpango',
			'José María Morelos',
			'Tlaxcala',
			'San Rafael Tenanyecac',
			'Villa Alta',
			'Nuevo Nicapa',
			'Las Maravillas',
			'Tziscao',
			'Rafael Ramírez',
			'Benito Juárez',
			'Emiliano Zapata',
			'Lázaro Cárdenas',
			'Texcapilla',
			'Chalchihuapan',
			'San Andrés Metla',
			'San Bernardo Tlalmimilolpan',
			'Santiago Tolman',
			'Santa María Palapa',
			'La Comunidad',
			'Coronel Tito Hernández',
			'Xalacapan de Lucke',
			'Chapulco',
			'Tepetzitzintla',
			'San Pablo Pixtún',
			'Nuevo Progreso',
			'Abelardo L. Rodríguez',
			'Xpujil',
			'Tototepec',
			'Rancho Viejo (Rancho Nuevo de la Democracia)',
			'El Pueblito',
			'La Victoria (La Peñita)',
			'Tierra Nueva',
			'Presidio (Plan de Libres)',
			'San Juan de las Manzanas',
			'La Concepción Enyege',
			'Tlaltenanguito',
			'Tlachaloya',
			'San Lorenzo Huehuetitlán',
			'Dos Ríos',
			'Loma Alta',
			'El Saneal',
			'San Ignacio Río Muerto',
			'El Capulín',
			'Lázaro Cárdenas',
			'Julimes',
			'Adolfo López Mateos',
			'Ojo de Agua de Palmillas',
			'Cubiri de Portelas',
			'Carricitos',
			'Guadalupe Victoria',
			'El Zapote',
			'Villa Unión',
			'Villa Juárez',
			'Escobedo',
			'El Pueblito',
			'Topolobampo',
			'Tomochic',
			'Témoris',
			'Tecolotlán',
			'Tacátzcuaro',
			'Santa Rosalía',
			'Santa María de Enmedio',
			'San Tadeo',
			'Santa Cruz el Grande',
			'Santa Catarina',
			'Santa Ana Maya',
			'San Rafael',
			'San Lorenzo',
			'Xoconoxtle el Grande',
			'San José de Gracia',
			'San Jose de Lourdes',
			'San Jerónimo Purenchecuaro',
			'San Felipe Santiago',
			'Puerto Palomas',
			'Pénjamo',
			'Palo Alto',
			'Ojo de Agua de Ballesteros',
			'Ocampo',
			'Nurío',
			'Negritas',
			'Monteón',
			'Monclova',
			'Marfil',
			'Magdalena de Kino',
			'Estación Capomas',
			'La Venta del Astillero',
			'La Presa',
			'La Pinta',
			'La Noria de San Antonio',
			'La Loma (La Loma de Quila)',
			'La Cantera',
			'Juventino Rosas',
			'Juárez',
			'Ixtapa',
			'Heróica Zitácuaro',
			'Guasave',
			'Guadalupe Victoria',
			'Guadalajara',
			'Granados',
			'Gómez Farías',
			'El Salitre',
			'El Roble',
			'Testerazo',
			'El Quelite',
			'El Plan',
			'El Perú',
			'El Loreto Occidental',
			'El Fortín',
			'El Coacoyul',
			'Ejido Plan de Ayala',
			'Cutzamala de Pinzón',
			'Culiacancito',
			'El Coto',
			'Cosalá',
			'Copala',
			'Concordia',
			'San José de Gracia',
			'Ciudad Hidalgo',
			'Ciudad Delicias',
			'Chínipas',
			'Chavinda',
			'Cedral',
			'Canelas',
			'Buenos Aires',
			'Buenavista de Trujillo',
			'Barrón',
			'Bacadéhuachi',
			'Atonalisco',
			'Aporo',
			'Amacueca',
			'Arellano',
			'El Refugio de Providencia (Providencia)',
			'Guerrero Negro',
			'Victoria',
			'San Agustín de las Flores',
			'Los Ramírez',
			'La Escondida',
			'San Juan Bautista Cacalote',
			'La Cuevita',
			'Puentecillas',
			'Ziquítaro',
			'Irapeo',
			'Jaripeo',
			'Zicuirán',
			'La Mira',
			'Santa Ana Zicatecoyan',
			'Cerritos del Pilar',
			'Santa Teresa',
			'El Terrero',
			'El Ticui',
			'Arenal de Álvarez (Arenal de Paco)',
			'Nuevo Vallarta',
			'Colonia del Valle',
			'Las Higuerillas',
			'Don Samuel',
			'Chililico',
			'Unidad Minera 11 de Julio',
			'Ex-Hacienda Santa Inés',
			'Barrio Santa Cruz',
			'Lomas de Río Medio Cuatro',
			'Fraccionamiento San Miguel',
			'Zona Urbana Ejido Isla Mujeres',
			'El Llano',
			'San Diego la Huerta',
			'Analco de Ponciano Arriaga (Santa Cruz Analco)',
			'Viñas del Sol',
			'Ignacio Zaragoza',
			'Nuevo Paso Nazareno (Chichicazapa)',
			'Pañhé',
			'Barrio de Guadalupe',
			'San Diego',
			'Techachaltitla',
			'El Cortijo',
			'La Versolilla',
			'Colonia Emiliano Zapata (San Juan Zitácuaro)',
			'San Cayetano',
			'Guadalupe Totoltepec',
			'Loma de San Miguel',
			'Galaxias Toluca',
			'San Antonio Coyahuacán',
			'Barrio San Miguel Dorami',
			'Habanero 1ra. Sección (Venustiano Carranza)',
			'La Candelaria Teotlalpan',
			'Colonia Aviación Autopan',
			'Poblado Chulavista (El Chorizo)',
			'La Magdalena de los Reyes (La Magdalena)',
			'Colonia Ecológica Asociación de Lucha Social (Lucha Social)',
			'Tlixco',
			'Santa Clara del Tule',
			'Catishtic',
			'Colonia San Francisco (San Francisco)',
			'Mitzitón',
			'San Roque',
			'Tlanipatla',
			'Huixcolotla',
			'La Providencia',
			'Colonia Ángel Bocanegra (Adolfo López Mateos)',
			'Oriente 2da. Sección (Palma Huaca)',
			'Cumbre de Barranca Honda',
			'Tequila 1ra. Sección (La Aurora)',
			'Prados del Rosario',
			'Las Yerbitas [Aserradero]',
			'José María Morelos',
			'Lázaro Cárdenas (Chilil)',
			'El Ámbar',
			'Los Benitos',
			'La Herradura',
			'Hoya de Cintora (La Hoya de Arriba)',
			'Atenguillo',
			'El Moreno (San Miguel Moreno)',
			'San José Barbabosa',
			'San Antonio Primero',
			'Chalchihuapan',
			'El Guayabito',
			'Nicolás Bravo',
			'Orduña de Arriba',
			'Candelaria',
			'Tarímbaro',
			'González',
			'San Francisco Cuayalab',
			'Yautepec',
			'Xochimilco',
			'X-Cán',
			'San Antonio Virreyes',
			'Villaflores',
			'Tunkás',
			'Tres Zapotes',
			'Tlapacoyan',
			'San Pedro Tlaolantongo',
			'Tlanalapa',
			'Tilzapotla',
			'Ticopó',
			'Tetelilla',
			'Tepexco',
			'Tepatlaxco de Hidalgo',
			'Telixtac',
			'Tehuixtla',
			'Villa Tecolutilla',
			'Tecalpulco',
			'Tancoco',
			'Tampamolón Corona',
			'Santo Domingo Petapa',
			'Santiago Tlapacoya',
			'Santiago Huajolotitlán',
			'Santa Mónica',
			'Santa María Citendejé',
			'Huiloapan',
			'Real de Catorce',
			'Nueva Palestina',
			'Fraccionamiento Misión del Valle',
			'Arenal',
			'Colinas del Aeropuerto',
			'La Colonia',
			'Zacualpan',
			'Xochiatipan de Castillo',
			'Xitlama',
			'Xico',
			'Verapaz',
			'Veracruz',
			'Tochimilco',
			'Tlayehualancingo',
			'Tlaxco',
			'Tlatlauquitepec',
			'Tlalixtac de Cabrera',
			'Tlalixcoyan',
			'Temoac',
			'Tehuipango',
			'Taxco el Viejo',
			'Tatahuicapan',
			'Tampico Alto',
			'Tambaca',
			'San Juan Tahitic',
			'Tahdziu',
			'Tacotalpa',
			'San Vicente Coatlán',
			'Xagacía',
			'Jamiltepec',
			'Santiago Astata',
			'Santa María Tepantlali',
			'Manzana Tercera de Santa Cruz Tepexpan',
			'Santa Cruz Tlaxcala',
			'Teutila',
			'San Pedro Mártir',
			'San Pedro Huamelula',
			'San Pedro Ecatepec',
			'Chalchihuitán',
			'Coatepec',
			'Huautepec',
			'San Mateo Texcalyacac',
			'San Mateo Ixtacalco',
			'Tianguismanalco',
			'San Juan Tejaluca',
			'San Juan Ihualtepec',
			'San Juan del Río',
			'San Jerónimo Sosola',
			'San Francisco Telixtlahuaca',
			'San Francisco',
			'San Bartolo Yautepec',
			'San Bartolo Cuautlalpan',
			'San Antonio Cañada',
			'San Andrés Paxtlán',
			'San Andrés del Pedregal',
			'San Agustín Oapan',
			'San Roque',
			'Juan Rodríguez Clara',
			'Río Blanco',
			'Colonia Rincón Viejo',
			'Rafael Delgado',
			'Progreso de Zaragoza',
			'Pocboc',
			'Pich',
			'Palo Bendito',
			'Palmar de Bravo',
			'Ocotlán de Morelos',
			'Oaxtepec',
			'Oaxaca',
			'Nunkiní',
			'Nueva Libertad',
			'Nicolás Bravo',
			'Minatitlán',
			'Matlahuacales Aquiles Serdán',
			'Mapachapa',
			'La Magdalena Tetela Morelos',
			'Macuspana',
			'Los Reyes',
			'La Loma',
			'Santo Tomás la Concordia',
			'Juan Marcos (San José Buenavista)',
			'Jonacatepec',
			'Jilotepec de Molina Enríquez',
			'Jaltocan',
			'Jacala',
			'Ixhuatán',
			'San Lucas Huitzilhuacán',
			'Filomeno Mata',
			'Ezequiel Montes',
			'Escuintla',
			'El Tejar',
			'El Limón',
			'El Cocuite',
			'Ecatepec de Morelos',
			'Dziuche',
			'Doxhicho',
			'Cozumel',
			'Corozal',
			'Copoya',
			'Copainalá',
			'Comales',
			'Cieneguilla',
			'Chicomuselo',
			'Chicavasco',
			'Chapopote Núñez',
			'Chapantongo',
			'Cancún',
			'Cochoapa',
			'Bajucu',
			'Ayapa',
			'San Antonio Atotonilco',
			'Atlacholoaya',
			'Aramberri',
			'Aquismón',
			'Apazapan',
			'Ahuazotepec',
			'Acapetahua',
			'Bajos de Chila',
			'San Miguel Mixtepec',
			'Santa María Yucuhiti',
			'Teotitlán',
			'San Pedro Ocopetatillo',
			'San Miguel del Valle',
			'Los Ángeles',
			'San Bartolomé Quialana',
			'Agua del Espino',
			'Puerto Ceiba',
			'Nicolás Bravo',
			'Ignacio Gutiérrez Gómez',
			'Medellín y Madero Segunda Sección',
			'El Cedro',
			'Paso de la Mina 3ra. Sección',
			'Pahactla',
			'Gandhó',
			'San Bartolo Ozocalpan',
			'El Susto',
			'Santa María Ilucan',
			'Zitlaltépec',
			'Concepción Chimalpa',
			'San Isidro Buen Suceso',
			'Rafael Pascacio Gamboa',
			'Lacandón',
			'Hidalgo Joshil',
			'Aurora Esquipulas',
			'San Antonio Buenavista',
			'José María Morelos',
			'Saltillo',
			'El Progreso',
			'Ochusjob',
			'Tiltepec',
			'Hermenegildo Galeana',
			'San Sebastián Chimalpa',
			'Santa Catarina',
			'San Antonio de las Palmas',
			'Ahuacatlán',
			'Guadalupe Victoria',
			'José María Morelos',
			'San Antonio Soledad',
			'Ciudad de Chiautla de Tapia',
			'Calipan',
			'Alfredo V. Bonfil',
			'Villa Madero',
			'El Salto',
			'La Palma',
			'Pochutla',
			'Xalpatláhuac',
			'Xochapa',
			'Zilacayotitlán',
			'Tixhualactún',
			'Presidente Juárez',
			'El Chico',
			'Tetlaxco',
			'Calzadas',
			'Benito Juárez',
			'Zacate Colorado',
			'Totolapa',
			'Acontitla',
			'El Huérfano',
			'Venustiano Carranza (Peña Blanca)',
			'San Mateo Tlalchichilpan',
			'San Marcos de la Loma',
			'San Agustín Citlali',
			'Dolores Hidalgo',
			'San Sebastián Buenos Aires',
			'San Mateo Capulhuac',
			'La Huanica',
			'San Felipe Tlalmimilolpan',
			'San Pedro Tultepec',
			'San Juan Tilapa',
			'San Pedro Tlaltizapan',
			'Santiago Tílapa',
			'El Ocotal',
			'La Cruz y Carrizal',
			'Bacame Nuevo',
			'Cicacalco',
			'Pardo',
			'El Refugio',
			'El Tecuán',
			'La Fe',
			'Zirándaro de los Chávez',
			'Zapotanito',
			'Zamorano',
			'Juárez',
			'Tuxpan de Bolaños',
			'Trapiche de Abra',
			'Tarejero',
			'Guadalupe de Tambula',
			'Súchil',
			'Saucillo',
			'Santo Tomás Huatzindeo',
			'Santa Teresa',
			'Otaéz',
			'San Pablo Pejo',
			'Balleza',
			'San Miguel Octopan',
			'San Miguel el Alto',
			'San Luis Soyatlán',
			'San Juan del Rio del Centauro del Norte',
			'San José de Aura',
			'Estación San José',
			'San Ignacio',
			'San Francisco del Rincón',
			'San Bernardo',
			'San Agustín',
			'El Coyote',
			'Pueblito de Allende',
			'Plan de Ayala (Campo Cinco)',
			'Pamatácuaro',
			'Ojo de Rana',
			'Ocampo',
			'Nava',
			'Naranjo',
			'San Isidro Miranda',
			'Maravatío de Ocampo',
			'Los Arquitos',
			'Los Aguajes',
			'Loma Blanca',
			'Jícamas',
			'Las Ánimas',
			'La Libertad',
			'Laguna Seca',
			'Ciénega de Nuestra Señora de Guadalupe',
			'Juanacatlán',
			'Jalostotitlán',
			'Ibarra',
			'Huanusco',
			'Huachinera',
			'Hermosillo',
			'Guadalupe',
			'General Simón Bolívar',
			'Felipe Carrillo Puerto',
			'Etúcuaro',
			'Etchojoa',
			'El Salero',
			'Catarino Rodríguez',
			'Paxtle',
			'El Lequeitio',
			'Golfo de Santa Clara',
			'Coyote',
			'Ejido Jiquilpan',
			'Cucuchucho',
			'Cuanajo',
			'Cuamio',
			'Las Compuertas',
			'Cofradía de Suchitlán',
			'Cuamiles',
			'Charcas',
			'Ceuta',
			'Colonia Michoacana',
			'Bledos',
			'Bellavista',
			'Basconcobe',
			'Bamoa',
			'Arcinas',
			'Arcelia',
			'Aquiles Serdán',
			'Amapa',
			'Altamira',
			'Alista',
			'Alcholoa',
			'Ahualulco del Sonido Trece',
			'Acuítzio del Canje',
			'Malpaso',
			'Cuchicuato',
			'Loza de Barrera',
			'Taretán',
			'La Ortiga',
			'La Loma',
			'San José Ixtapa (Barrio Viejo)',
			'Cuyutlán',
			'Agua Azul',
			'Manuel Ojinaga',
			'Progreso',
			'San Francisco Tecoxpa',
			'Villa Alberto Andrés Alvarado Arámburo',
			'San Sebastián de Aparicio',
			'Parque Urbano Napateco',
			'La Providencia',
			'Colonia Lomas de San Ramón (Triquis)',
			'Hacienda San Pedro',
			'Santa Rosa (Santa Bárbara)',
			'San Nicolás Zoyapetlayoca',
			'Jardines de San Sebastián',
			'Colonia Gustavo Baz Prada',
			'Jagüey de Téllez (Estación Téllez)',
			'Corral Falso',
			'San Francisco',
			'Colonia Progreso',
			'Macuilxóchitl de Artigas Carranza',
			'Kotolte',
			'La Roca',
			'San Isidro Gallinero (El Gallinero)',
			'San Antonio del Monte',
			'Colonia Morelos (El Nueve)',
			'Gunyo Poniente (San José Gunyo)',
			'Cliserio Alanís (San Gaspar)',
			'Tzeltal',
			'Barrio de Nuevo León',
			'San Francisco Tlacuilohcan',
			'San Antonio Tecolco',
			'Tatauzoquico',
			'San Lorenzo Tlaxipehuala',
			'Abelardo L. Rodríguez',
			'Palmillas',
			'Texcaltepec',
			'Pénjamo',
			'San Martín Ahuatepec',
			'Héroes de Chapultepec (Rodeo de San Antonio)',
			'Santa Lucía Potrerillo',
			'Texmola',
			'Salvador Rosas Magallón',
			'Los Nicolases',
			'Vista Hermosa (Cuasinada)',
			'El Mirador',
			'San Andrés Enguaro',
			'Álamos Tepetitlán',
			'San Bernardo',
			'San Juan Hueyapan',
			'Barrio de San Miguel',
			"Ahuacachahue (Ndog'yo Itún Tichi)",
			'El Triunfo de las Tres Maravillas',
			'Palo Seco (El Gato)',
			'Estación Dobladero',
			'Puerto de Providencia',
			'Los Pinos',
			'San Miguel Tenango',
			'San Pedro Cuitlapan',
			'Colonia Wenceslao Labra',
			'San Miguel del Arenal',
			'Zequentic',
			'San José de Bernalejo (El Guayabo)',
			'El Silencio',
			'Alfonso Garzón [Granjas Familiares]',
			'Chimalpa Viejo',
			'Betania',
			'La Corregidora',
			'La Loma Cuexcontitlán',
			'Colonia Doctor Gustavo Baz',
			'San Isidro Palotal',
			'Rancho Alegre',
			'Santa Rosa (El Huizache)',
			'Jalmolonga (La Hacienda)',
			'La Providencia',
			'San Lucas Totolmaloya',
			'La Ceja',
			'San Marcos Contla',
			'San Francisco',
			'Bara de Chachalacas',
			'San Nicolás Tolentino',
			'Graciano Sánchez',
			'Ramírez',
			'Alfredo V. Bonfil',
			'Zozocolco de Guerrero',
			'Yahualica',
			'Xonacatlán',
			'Xocotla',
			'Tzompantepec',
			'San José Toxi',
			'Tlaquilpa',
			'Santa Cruz Ayotuxco',
			'San Simón Almolongas',
			'San Pedro Tidaá',
			'San Pablito',
			'San Miguel el Grande',
			'San Miguel de La Victoria',
			'Chapultepec',
			'San Mateo Yoloxochitlán',
			'San Mateo Atenco',
			'San Martín Cachihuapan',
			'San Martín Tuchicuitlapilco',
			'San Marcos',
			'San Marcial Ozolotepec',
			'San Juan de las Huertas',
			'Tlacoatzintepec',
			'San José de Gracia',
			'San Gaspar Tonatico',
			'San Gabriel Mixtepec',
			'Cuautempan',
			'San Esteban',
			'Xochimanca',
			'San Andrés Tepetitlán',
			'San Andrés Teotilalpam',
			'Emancipación Quetzalapa',
			'Quetzalapa',
			'Purísima de Arista',
			'Piedra Parada',
			'Calichar Palma Sola',
			'El Palmar Grande',
			'Motovatha',
			'Montecillo',
			'Medias Aguas',
			'Maitinez',
			'Manlio Fabio Altamirano',
			'Los Molinos',
			'Las Lomas',
			'La Tinaja',
			'La Pesca',
			'La Joya',
			'Jiquipilas',
			'Santiago',
			'Guadalupe Victoria',
			'Guadalupe Santa Ana',
			'Villa Emiliano Zapata',
			'El Rincón',
			'El Pintor',
			'El Galaneño',
			'El Anono',
			'Dzula',
			'Dzibikak',
			'Dzibalchén',
			'Cuitláhuac',
			'Corralillos',
			'Coba',
			'Coacuilco',
			'Citilcum',
			'Cintalapa',
			'Chinampa de Gorostiza',
			'Chiná',
			'Chapopote Chico',
			'Caristay',
			'Capulhuac de Mirafuentes',
			'Campeche',
			'Calnali',
			'Buenavista',
			'Bolón',
			'Arenal Santa Ana',
			'Almoloya',
			'El Aguacate',
			'Acahuizotla',
			'San Antonio Acahualco',
			'Galeana',
			'San Miguel Tecomatlán',
			'Santo Domingo Armenta',
			'Rancho Viejo',
			'San Pablo Cuatro Venados',
			'Santiago Tilantongo',
			'San Marcos Arteaga',
			'Isla Soyaltepec',
			'La Tabaquera',
			'Cuauhtémoc',
			'San José la Garzona',
			'Mecoacán',
			'Nicolás Bravo',
			'El Nith',
			'Ocampo',
			'Ojo de Agua',
			'Tinajas',
			'Benito Juárez',
			'Villa Vicente Guerrero',
			'Los Naranjos',
			'Independencia',
			'Nueva Palestina',
			'Pilcaya',
			'Acuitlapilco',
			'Totolmajac',
			'Lomas de San Sebastián',
			'Tequexquináhuac',
			'Tepexpan',
			'El Rosal',
			'San Antonio Tlatenco',
			'Mamantel',
			'Campanario',
			'Pochotillo',
			'Ocotlán',
			'Topiltepec',
			'Popolnah',
			'Cuch Holoch',
			'Tepich',
			'Jose Narciso Rovirosa',
			'Isla Holbox',
			'Cerro Guzmán',
			'Las Amapolas',
			'San Fernando',
			'Ixhuapan',
			'El Tulín',
			'El Águila',
			'Polutla',
			'Caxapa',
			'Buenos Aires',
			'Chuniapan de Arriba',
			'Bordo Blanco',
			'San Nicolás Amealco',
			'San Joaquín del Monte',
			'Santa Juana Primera Sección',
			'Rancho Alegre',
			'San Mateo Otzacatipan',
			'San Pedro Zictepec',
			'Santa Lucía',
			'Cuauhtémoc',
			'San Bartolomé Coatepec',
			'San José el Vidrio',
			'San Ignacio Nopala',
			'El Diamante (La Retranca)',
			'Crisóstomos',
			'José María Morelos y Pavón',
			'Ojo de Agua',
			'Rancho Nuevo',
			'La Mesa de los Conejos',
			'Vaquerías',
			'Jauja',
			'Zaragoza',
			'Zapopan',
			'Yerbabuena',
			'Viesca',
			'Tepalcatepec',
			'Técpan de Galeana',
			'Tecalitlán',
			'Ejido el Saucillo',
			'San Roque de Torres',
			'San Pedro de los Naranjos',
			'San Pedro',
			'San Miguel de Allende',
			'San José del Cabo',
			'San Joaquín Zorrillos',
			'San Gabriel y San Ignacio',
			'San Francisco Javier',
			'San Diego de Alejandría',
			'San Cristóbal',
			'Salvatierra',
			'Salitral de Carrera',
			'Salinas Victoria',
			'Rincón de López',
			'Lázaro Cárdenas (Rancho Grande)',
			'Quiroga',
			'Purísima de Covarrubias',
			'Puga',
			'Potrerillo del Norote',
			'El Pochotal',
			'Picardías',
			'Pesqueira',
			'Pedriceña',
			'El Paredoncito',
			'Paredón',
			'Papanoa',
			'Opopeo',
			'Nueva Italia de Ruiz',
			'Mesillas',
			'Mazapil',
			'La Unión',
			'Las Grullas Margen Derecha',
			'Las Cuevas',
			'Las Arenitas',
			'La Rinconoda',
			'La Palma',
			'La Moncada',
			'La Lira',
			'La Calera',
			'Juan Aldama',
			'Janitzio',
			'Guadalupe Victoria',
			'General Andrés Figueroa',
			'Guaymitas',
			'Gargantillo',
			'Fronteras',
			'Esqueda',
			'El Venado',
			'El Tizate',
			'El Sauz',
			'El Grullo',
			'San Lorenzo',
			'Copándaro (Copándaro del Cuatro)',
			'Vicente Guerrero',
			'Colonia Abraham González (La Quemada)',
			'Camargo',
			'Charo',
			'Chametla',
			'Cerro Santa Fe',
			'Víctor Rosales',
			'Benjamín Hill',
			'San Juanito de Escobedo',
			'Acaponeta',
			'Villa Juárez',
			'Dolores Hidalgo',
			'Plan de Ayala',
			'Hacienda Arriba',
			'La Ciénega',
			'San Francisco',
			'Ojo de Agua de la Trinidad',
			'Santa Teresa',
			'Tomelopitos',
			'Silva',
			'El Acebuche',
			'Amanalco de Becerra',
			'San Francisco Solis',
			'San Juan Xoconusco',
			'San Jerónimo el Grande',
			'Palo Alto',
			'Santa María Magdalena',
			'La Valla',
			'Puerto Aventuras',
			'La Unión',
			'Fuentes del Valle',
			'San Jerónimo Cuatro Vientos',
			'Centro Familiar la Soledad',
			'San Jorge Pueblo Nuevo',
			'Don Antonio',
			'Ampliación San Mateo',
			'Juan Morales',
			'Buenos Aires',
			'General Ángel Flores (La Palma)',
			'La Alameda',
			'La Selva',
			'Lomas de Santa Anita',
			'Jicaltepec Autopan',
			'Anacleto Canabal 2da. Sección',
			'Colinas de Plata',
			'Melchor Ocampo',
			'Arboledas Paso Blanco [Fraccionamiento]',
			'Juárez Coronaco',
			'Iquinuapa',
			'Javier Rojo Gómez',
			'Bahía de Lobos',
			'Rancho Verde',
			'Entronque Laredo-Salinas Victoria',
			'Sitio Ejido',
			'Acxotla del Río',
			'Colonia las Malvinas (Colonia Antorcha)',
			'Mismaloya (Fraccionamiento Pedregal de Santa Martha)',
			'Papatlazolco',
			'Chimalapa',
			'San Rafael Tepatlaxco',
			'Río Grande',
			'El Rincón de San Felipe (Tercera Manzana San Felipe)',
			'Fraccionamiento Monte Olivo',
			'Cuatzoquitengo',
			'Marcelino Rodríguez (San Ignacio)',
			'Francisco I. Madero',
			'Colonia el Refugio',
			'José María Morelos (La Yegüería)',
			'Capitán Felipe Castellanos Díaz (San Pedro)',
			'Tepetates',
			'San Martín Tlamapa',
			'Cristóbal Colón',
			'Ex-Hacienda de Guadalupe',
			'La Península',
			'Agua Zarca',
			'Zoquitlán',
			'Colonia Chalchihuecan',
			'Santiago',
			'Santa Anita',
			'Buenavista',
			'Cajelitos',
			'Villa Hermosa',
			'Fraccionamiento Villas de la Llave',
			'José Refugio Salcido',
			'Colonia Obrera',
			'Las Huertas Tercera Sección',
			'Cuatro Caminos',
			'Colonia Arboledas (San Andrés)',
			'Colonia Jordán',
			'Reforma y Planada',
			'Tentic',
			'Doxteje Centro',
			'Lacapan Camallagne',
			'San Félix Rijo',
			'Zacate Colorado Segundo (Fraternidad)',
			'El Cabi',
			'Ejido San Cristóbal',
			'Melchor Ocampo',
			'Unidad Grajales INFONAVIT',
			'Adjuntas del Río',
			'Jesús Tepactepec',
			'Apantla',
			'Colonia Nueva Revolución',
			'El Siviral (Jigica)',
			'San José Mezapa Sección I',
			'Las Torres de Guadalupe',
			'Rancho de Guadalupe',
			'El Bajío (La Laguna)',
			'San Rafael Tecario',
			'Tianguismanalco',
			'Tlacotepec',
			'Santiago Tilapa',
			'Texcatepec',
			'Tequesquitengo',
			'Tekanto',
			'Tecama',
			'Tecajec',
			'Tancazahuela',
			'Sumidero',
			'Sotuta',
			'Soledad de Doblado',
			'Seybaplaya',
			'Sasaltitla',
			'San Vicente de Juárez',
			'Mazaltepec',
			'Santo Domingo Tlatayapam',
			'Santiago Yeché',
			'Santiago Tepextla',
			'Santiago de Anaya',
			'Santiago Atzitzihuacán',
			'Santa María Teopoxco',
			'Santa María Nduayaco',
			'Santa María del Tule',
			'Santa María Amajac',
			'Santa Cruz Nundaco',
			'Los Aztecas',
			'Rascón',
			'Zumpango',
			'Zotoluca',
			'Zongolica',
			'Xacaxomulco',
			'Yoloxóchitl',
			'Valadeces',
			'Umán',
			'Tomatlán',
			'San Felipe Tizapa',
			'Tepeapulco',
			'Temimilcingo',
			'Temapache',
			'San José del Tapanco',
			'Tantima',
			'Santo Tomás Jalieza',
			'Santiago el Pinar',
			'Santa María Xonacatepec',
			'Santa María Colotepec',
			'Santa María Atzompa',
			'Santa Isabel Ixtapan',
			'Santa Ana Necoxtla',
			'Santa Ana de Allende',
			'Santa Ana Chiautempan',
			'San Sebastián Río Hondo',
			'San Sebastián Coatlán',
			'San Pedro Itztla',
			'Yaganiza',
			'San Nicolás de los Ranchos',
			'San Nicolás',
			'San Miguel Tenextatiloyan',
			'San Juan del Río',
			'San Juan Cotzocón',
			'San Cristóbal',
			'San Bartolomé Ayautla',
			'Cuaxomulco',
			'San Andrés Ixtlahuaca',
			'Santiago Quiavicuzas',
			'Puerto Escondido',
			'Pueblo Nuevo',
			'Primero de Mayo',
			'Peñamiller',
			'Pantepec',
			'Panotla',
			'Olcuatitán',
			'Ocampo',
			'Nueva Palestina',
			'Nautla',
			'Naupan',
			'El Morro',
			'Mocochá',
			'Mazapa',
			'Los Aldamas',
			'La Concepción Coatipac (La Conchita)',
			'Joachín',
			'Jesús Carranza',
			'Jaltepec',
			'Iliatenco',
			'Huitzilac',
			'San Antonio Huitepec',
			'Hueytlalpan',
			'Huejotzingo',
			'Hualahuises',
			'Huahuaxtla',
			'Hidalgo',
			'Guadalupe',
			'La Estanzuela',
			'Emiliano Zapata',
			'El Portal',
			'El Estudiante',
			'Ekpedz',
			'Dzitás',
			'Dzemul',
			'Cruz del Milagro',
			'Corral Nuevo',
			'Colotlipa',
			'Cocoyol',
			'Ciudad Fernández',
			'Chachahuantla',
			'Chacalapa',
			'Cacahuatal',
			'Barrancas',
			'Bacabchén',
			'Ayotzintepec',
			'Soledad Atzompa',
			'Atzitzintla',
			'Atlacahualoya',
			'Asunción Nochixtlán',
			'Apatzingán',
			'San Pedro Apatlaco',
			'Antón Lizardo',
			'Amayuca',
			'Amatitlán',
			'Aldama',
			'Ahuacatán',
			'Tepenixtlahuaca',
			'Santos Reyes Nopala',
			'La Luz',
			'San Francisco Nuxaño',
			'Papaloapan',
			'Benemérito Juárez',
			'Arroyo de Banco',
			'Santo Domingo Roayaga',
			'Rojas de Cuauhtémoc',
			'La Pe',
			'Villa la Venta',
			'Aquiles Serdán 1ra. Sección',
			'La Curva',
			'Juan Aldama',
			'Acoapa',
			'El Tepeyac',
			'Los Cides',
			'La Trinidad',
			'Capula',
			'San Matías Tepetomatitlán',
			'Zimatepec',
			'Concepción Hidalgo',
			'Nuevo Limar',
			'Nuevo Sitalá',
			'San Andrés Duraznal',
			'Salvador Urbina',
			'La Línea',
			'Emiliano Zapata',
			'San Isidro',
			'La Finca',
			'San Juan Tehuixtitlán',
			'San Cristóbal Nexquipayac',
			'San Lorenzo Tlalmimilolpan',
			'Ixtlahuaca de Cuauhtémoc',
			'Tlacomulco',
			'San Miguel Atlapulco',
			'Las Compuertas',
			'Cerro de Piedra',
			'Acalco',
			'La Dicha',
			'Ekmul',
			'X Cabil',
			'Palo Gacho',
			'Mata de Indio',
			'Vargas',
			'Zapoapan',
			'Comején',
			'Antonio J Bermúdez',
			'Estero de Milpas',
			'Estero del Ídolo',
			'Adolfo Ruíz Cortines',
			'Manantiales',
			'El Panorama',
			'Gabino Barreda',
			'Niños Héroes',
			'El Potrero',
			'Turcio Segunda Sección',
			'El Rincón de los Perales',
			'Ejido del Tunal Nenaxi',
			'San Mateo el Viejo',
			'San Francisco Putla',
			'San Mateo Atarasquíllo',
			'Antonio Rosales',
			'Los Zacatones',
			'Jesús María',
			'Melchor Ocampo',
			'Adolfo Ruiz Cortines',
			'La Luz',
			'Seis de Octubre',
			'Zapotán',
			'Villamar',
			'Villa de Guadalupe',
			'San Vicente',
			'Uriangato',
			'Tecomatán',
			'Tapalpa',
			'Susticacán',
			'Sinahuiza',
			'Santa Teresa',
			'San Juan Pan de Arriba',
			'San Gabriel',
			'San Francisco de Horizonte (Horizonte)',
			'San Diego de Alcalá',
			'San Clemente',
			'San Cayetano',
			'San Blas',
			'San Antonio de Peñuelas',
			'Nicolás R Casillas',
			'Rosamorada',
			'Rodeo',
			'Rio de Medina',
			'Quila',
			'Puerto Vallarta',
			'Poturo',
			'Patuán',
			'Parras de la Fuente',
			'Ortiz',
			'Nonoava',
			'Nogales',
			'Nicolás Bravo',
			'Moroncarit',
			'Morelos',
			'Miraflores',
			'Mechoacanejo',
			'San José de Mendoza',
			'Ciudad Melchor Múzquiz',
			'Matamoros',
			'Manalisco',
			'Magdalena',
			'La Sauceda',
			'La Paz de Ordaz',
			'La Calera',
			'La Joya de Calvillo',
			'El Higueral',
			'Higuera Blanca',
			'Guatimapé',
			'Carretas',
			'Juan José Ríos',
			'Gachupines',
			'Franco',
			'Estrada',
			'El Naranjito',
			'El Maguey',
			'El Lobo',
			'Estación Llano',
			'El Habillal',
			'Monte Verde',
			'El Ceñidor',
			'El Capulín',
			'El Cabezón',
			'Ejido Yucatán',
			'Ciudad Insurgentes',
			'Ejido Vicente Guerrero',
			'Cuaracurío',
			'El Corcovado',
			'La Concha (La Concepción)',
			'Cocorit',
			'Ciudad Acuña',
			'Chucarit',
			'Chacala',
			'Cañada de Ramírez',
			'Bellavista',
			'Ciudad de Armería',
			'Arandas',
			'Apo',
			'Acapetlahuaya',
			'La Presa',
			'Ibarrilla',
			'La Calera',
			'Delgado de Abajo',
			'San José de las Pilas',
			'Mezquite Gordo',
			'Loma de Zempoala',
			'Manzana de San Luis',
			'Emiliano Zapata',
			'Ocurio',
			'Santa María Canchesdá',
			'San Miguel Agua Bendita',
			'Colonia los Ángeles',
			'Charco Blanco',
			'Bravo',
			'Licenciado Benito Juárez',
			'Jesús del Monte',
			'Palmira',
			'Hércules',
			'Arroyo del Maíz Uno',
			'Tezoquipa',
			'San Lucas Pío',
			'Xoloateno',
			'Jesús Nazareno',
			'Guadalupe Zaragoza',
			'El Huaxtho',
			'Prados San Francisco',
			'Zoatecpan',
			'Ninguno [Centro de Readaptación Social de Atlacholoaya]',
			'Colonia San Isidro',
			'Colonia Río Lerma (Tic Ti)',
			'El Encanto del Cerril',
			'Colonia Agrícola de Ocotepec (Colonia San José)',
			'Tlaxinga (Clatzinga)',
			'Tezhuatepec',
			'Cruztón',
			'El Refugio',
			'El Cerrito',
			'Tikinmul',
			'Nueva Tenochtitlán (Rizo de Oro)',
			'Joaquín Miguel Gutiérrez (Margaritas)',
			'Tlaltempanapa',
			'Villa de los Niños',
			'Gildardo Magaña (Los Ángeles)',
			'La Soledad',
			'San Rafael',
			'Sección 23',
			'Colonia Aquiles Córdoba Morán',
			'El Porvenir',
			'Buenavista',
			'Majastic',
			'Eureka de Media Luna (Eureka)',
			'Río Jordán',
			'Playa de la Libertad',
			'Nueva Libertad (El Colorado)',
			'La Independencia (Las Pilas)',
			'Tenexio',
			'Pochálcatl',
			'Colonia Independencia',
			'Ex-Hacienda de Guadalupe',
			'San José del Caliche (El Caliche)',
			'Paseos de la Pradera',
			'Ojo de Agua de Morán',
			'Tzinil',
			'Vallejo (Porvenir Vallejo)',
			'Misija',
			'Progreso',
			'Veracruz',
			'General Luis Felipe Domínguez Suárez',
			'Yopi',
			'Ejido Miraflores',
			'Unión Agropecuarios Lázaro Cárdenas del Norte',
			'Estación Corralejo',
			'San Jacinto',
			'San Juan Tuxco',
			'San Francisco Tutla',
			'Ejido de San Mateo Coapexco',
			'Nezahualcóyotl',
			'Tlamanca',
			'Tlacotalpan',
			'Tixpéhual',
			'San Gabriel Tetzoyocán',
			'Tetela de Ocampo',
			'Tepecoacuilco de Trujano',
			'Teotitlán del Valle',
			'Tenango del Aire',
			'Tenancingo de Degollado',
			'Temoaya',
			'Temixco',
			'San Juan Teitipac',
			'Tahmek',
			'Santo Domingo Atoyatempan',
			'Santiago Choápam',
			'Santa Teresa',
			'Nativitas',
			'Santa María Tiltepec',
			'Santa María Jacatepec',
			'Santa María Guenagati',
			'Tecuexcomac',
			'Santa Cruz Tacache de Mina',
			'Santa Catarina',
			'Santa Catalina',
			'San Miguel Tenochtitlán',
			'Mexicaltzingo',
			'Tejupa',
			'San Juan Jaltepec',
			'San Juan de los Reyes (Luis Valenzuela)',
			'San Isidro',
			'San Felipe',
			'San Andrés Yaá',
			'San Andrés Huaxpaltepec',
			'Sanahcat',
			'Los Plátanos',
			'San Francisco Ocotlán',
			'Ocotal Chico',
			'Magdalena Mixtepec',
			'Llano de Enmedio',
			'Laguna Chica (Pueblo Nuevo)',
			'Santa Bárbara de La Cueva',
			'San Martín Lachila',
			'Colonia Juárez',
			'Jaltenco',
			'Ixtlahuaca de Rayón',
			'Iztacalco',
			'Iturbide',
			'Igualapa',
			'Huixtla',
			'Escárcega',
			'Los Cenotes',
			'El Carmen Aztama',
			'El Arenal',
			'Dzitnup',
			'Dantzibojay',
			'Cuautepec',
			'Copala',
			'El Venado',
			'Santiago Camotlán',
			'Chila de Juárez',
			'Chiconquiaco',
			'Chicoloapan',
			'Chiapilla',
			'Chalmita',
			'Chablekal',
			'San Miguel Cerezo (El Cerezo)',
			'Castillo de Teayo',
			'El Carrizo',
			'Calixtlahuaca',
			'Ayahualulco',
			'Ayahualulco',
			'San Pablo Atotonilco',
			'Asunción Ocotlán',
			'San Antonio Arrazola',
			'Aquiles Serdán',
			'Ahuacatlán',
			'Ixpantepec Nieves',
			'Chicahua',
			'Xochitonalco',
			'Guadalupe Etla',
			'San Lorenzo Albarradas',
			'Santa María Zapotitlán',
			'La Estrella',
			'Reforma',
			'Irolo',
			'San José Tetel',
			'San Andrés Ahuashuatepec',
			'José María Morelos',
			'El Limar',
			'Nueva Esperanza',
			'Carmen Yalchuch',
			'Agua Azul',
			'Álvaro Obregón',
			'Roblada Grande',
			'Huizachal',
			'Tepetitlán',
			'Denjhi',
			'Adolfo López Mateos',
			'Venustiano Carranza',
			'Atecax',
			'Villa Cuauhtémoc',
			'San Sebastián Teteles',
			'Guadalupe Enríquez',
			'Necoxtla',
			'Jolalpan',
			'El Aguacatal',
			'Llano Grande',
			'Cuauhtenango',
			'El Terrero',
			'Santa Cruz',
			'Paraje Montero (Paraje Montero de Zaragoza)',
			'El Carmen',
			'Kanxoc',
			'Yaxhachen',
			'Huay Max',
			'La Reforma',
			'Coyolito',
			'General Miguel Alemán',
			'Cucharas',
			'Ricardo Flores Magón',
			'Macedonio Alonso',
			'Colonia Libertad',
			'San Miguel Tlalpoalán',
			'Rodríguez Tejeda',
			'Las Sabanetas',
			'Las Peñas',
			'San Felipe Pueblo Nuevo',
			'Coscomate del Progreso',
			'San Diego Alcalá',
			'Colonia Juárez',
			'Santiago Analco',
			'Loma del Río',
			'Salitrillo',
			'San Miguel',
			'Santo Tomás Chiconautla',
			'La Pendencia',
			'Carrillo',
			'Pozuelos',
			'Galeana',
			'La Presita',
			'El Huitusi',
			'Jaboncillo',
			'General Lázaro Cárdenas',
			'Yustis',
			'Yahualica de González Gallo',
			'Villa Victoria',
			'Villa Unión',
			'Villa Ocampo',
			'San Gabriel',
			'Urique',
			'Tzintzingareo',
			'Tumbiscatío de Ruiz',
			'Tequila',
			'Tenexpa',
			'Temascaltepec de González',
			'Tarécuato',
			'San Vicente',
			'Santo Niño Aguanaval',
			'Santiaguito',
			'Santa Rosa los Angeles',
			'Santa Mónica',
			'Veinte de Noviembre (Santa Lucía)',
			'Santa Cruz de la Soledad',
			'San Rafael',
			'San Nicolás Solís',
			'San Juan Tecomatlán',
			'San Francisco',
			'San Clemente',
			'Puerto San Carlos',
			'Sabinas',
			'Rodrígo M. Quevedo',
			'El Rodeo',
			'San Andrés',
			'Rayones',
			'Quinceo',
			'Purépero de Echáiz',
			'Puerto de Nieto',
			'Portes Gil',
			'Pesquería',
			'Numarán',
			'Monteleón',
			'Misión de Chichimecas',
			'San Bernardino de Milpillas Chico',
			'Capilla de Milpillas',
			'Mecatán',
			'Mazatlán',
			'Masiaca',
			'Lourdes',
			'La Gavia',
			'La Estacada',
			'La Brecha',
			'Kilómetro Noventa y Nueve',
			'Juchipila',
			'Jaral del Progreso',
			'Jalipa',
			'El Jabalí',
			'La Guásima',
			'El Salto',
			'El Colorado',
			'División del Norte',
			'Morelos',
			'Estación Consuelo',
			'Coneto de Comonfort',
			'José Mariano Jiménez',
			'Benito Juárez',
			'Bachíniva',
			'Aquila',
			'Amealco',
			'Álvaro Obregón',
			'Alfajayucan',
			'Aldama',
			'Álamos',
			'Acatic',
			'Abasolo',
			'Camalotita',
			'Jesús Gómez Portugal (Margaritas)',
			'San Jacinto',
			'Norias del Paso Hondo',
			'El Pescadero',
			'Los Remedios',
			'Potreros',
			'Zapote de Barajas',
			'Santa Rosa de Rivas',
			'Cañada de Bustos',
			'Landín',
			'La Cieneguita',
			'San José de Ayala',
			'Cerro Colorado',
			'Zapotillo de Mogotes',
			'Cuatro Caminos',
			'La Placita de Morelos',
			'Otzoloapan',
			'San Pedro Mártir',
			'El Gallo',
			'Arcila',
			'Santa Fé',
			'Ricardo Flores Magón',
			'San Salvador Tizatlalli',
			'Casa Blanca',
			'Santa María Zacatepec',
			'Puerto Escondido (Tepeolulco Puerto Escondido)',
			'Brisas de Zicatela',
			'Los Arcos',
			'San Lorenzo Axocomanitla',
			'Lo de Juárez',
			'San Buenaventura Tecaltzingo',
			'Unidades Habitacionales',
			'Sector H Tres',
			'Fraccionamiento los Prados',
			'La Planada',
			'San Lorenzo Queréndaro',
			'Cereso 14 (El Amate)',
			'Chulum Juárez',
			'San José Chapayal',
			'Paseo de las Cañadas',
			'Colonia Diana Laura Riojas de Colosio',
			'San Pedro Nichtalucum',
			'Centro de Readaptación Social',
			'Los Cues',
			'La Lima',
			'Barrio de la Barranca',
			'La Loma de los Negritos',
			'Chichicastle 1ra. Sección',
			'Ángel Albino Corzo (Guadalupe)',
			'Monte Largo 1ra. Sección',
			'Tacubaya',
			'Nuevo Michoacán (Estación Riíto)',
			'Fraccionamiento Praderas de la Venta',
			'Los Condes',
			'Oriente 6ta. Sección (Los Mulatos)',
			'La Joya',
			'Ixcatla',
			'San José Viborillas',
			'Los Mancera',
			'Santa Cruz de la Loma (Tepetates)',
			'El Palacio',
			'Privada del Álamo',
			'Ejido Hidalgo',
			'Tonalixco',
			'Ladrilleras de Ocoro',
			'Praderas de San Francisco',
			'Alejo González (Bilbao)',
			'Colonia Guadalupana',
			'El Mollejon',
			'La Esperanza [Granjas Familiares]',
			'Nigromante',
			'Las Pilas',
			'General Lauro G. Caloca (El Rascón)',
			'La Rinconada',
			'El Ramal (Porvenir)',
			'Cantón Villaflor',
			'Coahuixtla',
			'Oxinam',
			'Doxteje Barrio Primero',
			'Daxtha',
			'Espinal',
			'Casa Blanca',
			'Rincón de Curungueo',
			'El Coecillo',
			'Parrilla II',
			'Reynosa',
			'Rayón',
			'Platanillo',
			'Pavencul',
			'Panales',
			'Panabá',
			'Oyameles de Hidalgo',
			'Nuevo Amatenango',
			'Zacatepec',
			'San Agustín Yatareni',
			'Yatzachi',
			'San José Vista Hermosa',
			'Villa Guerrero',
			'El Triunfo',
			'El Consuelo Ulapa',
			'Tuxtla',
			'Tozihuic',
			'Tlayacapan',
			'Maclovio Herrera',
			'Gómez Farías',
			'Alfredo V. Bonfil',
			'Zamachihue',
			'Zacualpan',
			'San Pedro Mártir Yucuxaco',
			'San José Yocnajab',
			'Yalkoba',
			'Xicoténcatl',
			'Xanabá',
			'Valle Hermoso',
			'Unión Roja',
			'Tzimol',
			'Tuxpanguillo',
			'Tulum',
			'Totoltepec de Guerrero',
			'Toluca',
			'Tlaxocoyucan',
			'Tinum',
			'Tepeyehualco de Hidalgo',
			'Tepexi',
			'Tepetlán',
			'Temascalapa',
			'Temango',
			'Tekoh',
			'Sultepec',
			'Sucilá',
			'Santiago Tuxtla',
			'Santiago Juxtlahuaca',
			'Santiago Acutzilapan',
			'Santiago',
			'Santa Maria Macua',
			'Pino Suárez',
			'Santa Gertrudis Miramar',
			'Tecámac',
			'Santa Ana Acozautla',
			'San Sebastian Teitipac',
			'San Salvador Atenco',
			'San Pedro Totolápam',
			'San Pedro Pochutla',
			'San Mateo del Mar',
			'San Mateo',
			'Peras',
			'San Lucas Tecopilco',
			'San Jerónimo Axochitlán',
			'San Gregorio Cuautzingo',
			'Cuecuecuatitla',
			'Nanahuatípam',
			'Puente de Ixtla',
			'Plan de las Hayas',
			'Santo Domingo Ozolotepec',
			'Ocuilan de Arteaga',
			'Santa María Nativitas',
			'Moyotepec',
			'Monterrey',
			'Molas',
			'Mayapan',
			'Maltrata',
			'Los Reyes Acatlixhuayán',
			'Los Reyes',
			'La Victoria',
			'Las Barrillas',
			'La Perla',
			'La Magdalena',
			'San Pedro Huitzizilapan',
			'Huitepec',
			'El Realito',
			'El Ciervo',
			'Paso Morelos',
			'Santiago Cuautlalpan',
			'Coxcatlán',
			'Cosoleacaque',
			'Coatzintla',
			'Ciudad Sahagun',
			'Ciudad Nezahualcoyotl',
			'Chumatlán',
			'Chapab',
			'Casahuatlán',
			'Caobas',
			'Cancuc',
			'Cahuatache',
			'Boyé',
			'Bonifacio García',
			'Bejucal de Ocampo',
			'Azumbilla',
			'Atzalan',
			'Amixtlán',
			'San Jerónimo Acazulco',
			'Jicarero',
			'Pueblo Viejo',
			'Santa María Temaxcalapa',
			'Yetla de Juárez',
			'San Lucas Zoquiápam',
			'María Lombardo de Caso',
			'Santa María Coyotepec',
			'Río Viejo Primera Sección',
			'Tlaxcalilla',
			'San José Ocotillos',
			'San José Tepeyahualco',
			'Ignacio Zaragoza',
			'San Pedro Xochiteotla',
			'Zaragoza',
			'Damasco',
			'Adolfo López Mateos',
			'La Candelaria',
			'Francisco I. Madero',
			'Jesús Carranza (Rancho de Jesús)',
			'Tepoxtepec',
			'Santiago Zacualuca',
			'San José del Sitio',
			'González Ortega',
			'San José Cuyachapa',
			'La Purísima',
			'San Bartolomé',
			'Tepazolco',
			'Ixcamilpa',
			'Xicotlán',
			'Suc-Tuc',
			'Pueblo Viejo',
			'El Conchero',
			'Jalapa',
			'Becanchén',
			'La Union',
			'Tlaltetela',
			'Hueytepec',
			'El Arenal',
			'Zamora',
			'San Martín',
			'La Llave',
			'San Pedro de la Hortaliza (Ejido Almoloyán)',
			'Barrio de Boyecha',
			'San Antonio Bonixi',
			'Pothé',
			'San Agustín Mimbres',
			'San José Buenavista el Grande',
			'San Mateo Mozoquilpan',
			'San Bartolito Tlaltelolco',
			'San Andrés Ocotlán',
			'San Miguel Tecpan',
			'Tonanitla',
			'Vicam',
			'Santa María de Guaymas',
			'Genaro Codina',
			'Paso de Méndez',
			'Monte Obscuro',
			'Palmar Primero',
			'Mesa del Huracán',
			'José Esteban Coronado',
			'Dautillos',
			'El Progreso',
			'Tres Garantías',
			'Ejido Cinco de Mayo',
			'Cihuatlán',
			'Bajío de San José',
			'Zacapú',
			'Yerbabuena',
			'Villa Pesqueira',
			'Villa Juárez',
			'San José Villa de Allende',
			'El Vegil',
			'Totatiche',
			'Tonila',
			'Tizapán el Alto',
			'Terminal de Providencia',
			'Tacicuaro',
			'Suchitlán',
			'Socavón (El Carmen)',
			'Santiago Ixcuintla',
			'Santiaguillo',
			'Santa Ana del Pilar',
			'San Rafael',
			'San Pedro Jácuaro',
			'San Luis de la Paz',
			'San Juan de Guadalupe',
			'San Jerónimo Totoltepec',
			'San Isidro Mazatepec',
			'Conchos',
			'San Antonio del Rosario',
			'San Ángel',
			'Sanalona',
			'Río Bravo',
			'Villa de Ramos',
			'Portugués de Gálvez',
			'Palmar Chico',
			'Pajacuarán',
			'Ocampo',
			'Nueva Rosita',
			'Mexicali',
			'Ejido Mayocoba',
			'Mascota',
			'Margaritas',
			'Los Hoyos',
			'Llano Grande',
			'General Francisco Villa',
			'La Ordeña',
			'La Higuera',
			'La Cruz',
			'La Ciudad',
			'La Ciénega',
			'La Angostura',
			'Pueblo Juárez (La Magdalena)',
			'Ixtapan de la Concepción',
			'Ignacio Allende',
			'Huejúcar',
			'Huatabampo',
			'Huaniqueo de Morales',
			'Guasavito',
			'Guachinango',
			'Granjenal',
			'Gilita',
			'Etchoropo',
			'El Vicarlo',
			'El Tamarindo',
			'El Salvador',
			'El Rayo',
			'El Carrizo',
			'Cuxpala',
			'Curimeo',
			'Comonfort',
			'Ciudad Juárez',
			'Chucándiro',
			'Chiquilistlán',
			'Cerro Gordo',
			'Cerritos',
			'Capula',
			'Caleras',
			'Gonzales Ortega',
			'Bahuichivo',
			'Ajuchitlancito',
			'San José de Gracia',
			'La Panadera',
			'Las Norias de Ojocaliente',
			'Lázaro Cárdenas',
			'El Carretón',
			'San Felipe Quiriceo',
			'El Divisador',
			'Frías',
			'San José Huipana',
			'Dolores',
			'Cerro Colorado de Ocampo',
			'Aputzio de Juárez',
			'Las Cañas',
			'Infiernillo (Morelos de Infiernillo)',
			'San Diego del Cerrito',
			'Pueblo Viejo',
			'Urecho',
			'Mesillas',
			'San Juan De Los Platanos',
			'Ejido Javier Rojo Gómez',
			'Maya Balam',
			'Centro de Readaptación Social',
			'La Tijera',
			'Las Veredas',
			'Cuichapa',
			'Campestre Villas del Álamo',
			'Arbolada los Sauces',
			'Fraccionamiento Ocuiltzapotlán Dos',
			'Delfino Victoria (Santa Fe)',
			'Puerto Esmeralda',
			'Vistas del Maguey [Fraccionamiento]',
			'Santa María Texmelucan',
			'La Libertad',
			'Jardínes del Grijalva',
			'La Aurora',
			'San Vicente la Mesilla',
			'Xochimilco',
			'Tlalnepantla',
			'Manzana Quinta (La Cañada)',
			'Tehuilotepec',
			'El Tigre',
			'Cipreses',
			'Unidad Habitacional Antonio Osorio de León (Bojay)',
			'Morelos Piedra 3ra. Sección',
			'Vallecitos de Zaragoza',
			'Pejelagartero 1ra. Sección (Plataforma)',
			'Coamilpa de Juárez',
			'Colonia San Francisco de Asís',
			'La Cruz',
			'Barrio Bordo Nuevo',
			'Ladrilleras del Refugio',
			'San Lorenzo',
			'San Francisco Atotonilco',
			'Querendas',
			'Miguel Valdez Quintero (El Corazón)',
			'San Pablo Huacano',
			'San Isidro Monterrosas',
			'Ejido los Huastecos',
			'Estanzuela',
			'Tepeteno de Iturbide',
			'Huitzmaloc',
			'Tierra Blanca',
			'Colonia Emiliano Zapata',
			'La Loma',
			'San Martín Itunyoso',
			'Guadalupe Hidalgo',
			'Cerro Armadillo Grande',
			'Quintana Roo',
			'Sergio Villaseñor',
			'Tres Cerros',
			'Colonia Patria Nueva',
			'Boquilla de Babisas (La Boquilla de Conchos)',
			'Ignacio Allende (Concepción)',
			'Buenavista Tetela',
			'El Barrido',
			'Las Golondrinas [Fraccionamiento]',
			'Rancho Viejo',
			'Chiquinival',
			'San Miguelito',
			'Tinajas 1ra. Sección',
			'Rinconadas del Bosque',
			'Santa Ana Chapitiro',
			'Pueblo Madero (El Playón)',
			'Buenavista',
			'Rancho Grande',
			'Colonia el Florido',
			'San Juan del Llanito',
			'San Jose de los Olvera',
			'Estación Tuzantán',
			'Zacamixtle',
			'Xul-Ha',
			'Xometla',
			'Xalitla',
			'Vega del Rosario',
			'Vega de Alatorre',
			'Tulcingo de Valle',
			'Tulcingo',
			'Trapiche del Rosario',
			'Tlaquiltenango',
			'Tixmehuac',
			'Tixcacaltuyub',
			'Texistepec',
			'Santiago Tepeyahualco',
			'Tenosique',
			'Tenango',
			'Tenabo',
			'Telchaquillo',
			'Sudzal',
			'Soteapan',
			'Singuilucan',
			'Siltepec',
			'Santiago Tepatlaxco',
			'Santiago Miltepec',
			'Santiago Cuaula',
			'Santa Rosa',
			'Santa Martha Chichihualtepec',
			'Acuexcomac',
			'Santa Apolonia Teacalco',
			'San Simonito',
			'San Sebastián Abasolo',
			'Teposcolula',
			'Ayutla',
			'San Pablo Huixtepec',
			'San Pablo Atlazalpan',
			'San Miguel Peras',
			'San Mateo Piñas',
			'San Mateo Coapexco',
			'Tejaluca',
			'San Jerónimo Silacayoapilla',
			'San Jerónimo',
			'San Francisco Lachigoló',
			'San Francisco Chindúa',
			'San Cristóbal Amatlán',
			'San Andrés Sinaxtla',
			'Saban',
			'Rafael J. García',
			'Quintana Roo',
			'Pueblillo',
			'Playa Vicente',
			'Playa del Carmen',
			'Pathé',
			'Papatlatla',
			'Oxchuc',
			'Ocozocoautla de Espinosa',
			'Osumacinta',
			'Pueblo Nuevo',
			'Paraiso Novillero',
			'Naranjal',
			'La Laguna y Monte del Castillo',
			'Mochitlán',
			'Santa María Mixtequilla',
			'San Andrés Mixquic',
			'Michimaloya',
			'Mexcalcuautla',
			'Mecayapan',
			'Los Reyes de Juárez',
			'Loma de Sogotegoyo',
			'Llera de Canales',
			'Libre Unión',
			'Lepan',
			'La Concordia',
			'Kini',
			'Kinchil',
			'José Cardel',
			'Ixtapaluca',
			'Ixcateopan de Cuauhtémoc',
			'Ixcateopan',
			'Isla Aguada',
			'Higuerillas',
			'Fortín de las Flores',
			'Felipe Carrillo Puerto',
			'Emiliano Zapata',
			'El Paredón',
			'El Pacayal',
			'El Bosque',
			'Cuernavaca',
			'Coyutla',
			'Coyotepec',
			'Coatlán del Río',
			'Ciudad Mendoza',
			'Chunchintok',
			'San Francisco Chimalpa',
			'Chietla',
			'Chichimila',
			'Chicapa de Castro',
			'Chalma',
			'Villa Chalcatongo de Hidalgo',
			'Carlos A. Carrillo',
			'Santiago Cacaloxtepec',
			'Azcapotzalco',
			'Atlixtac',
			'Anahuac',
			'Amatán',
			'Amacuzac',
			'Alaquines',
			'Ahuatlán',
			'Los Corazones',
			'Santiago Tetepec',
			'Tataltepec de Valdés',
			'El Jicaral',
			'Tecominoacán',
			'Santa Ana Batha',
			'Zothé',
			'Vicente Guerrero',
			'Guadalupe Victoria',
			'Santa Elena Paliseca',
			'El Rosario',
			'La Soledad',
			'Xicohtzinco',
			'San Bartolomé Tenango',
			'Santa Cruz',
			'Santa María Huexoculco',
			'Guadalupe Sarabia',
			'Ciudad de Tlatlauquitepec',
			'Santa Lucía Cosamaloapan',
			'Axuxco',
			'Tehuixtla',
			'Tecuexcontitlán (Tecoescontitlán)',
			'Maxela',
			'Tulimán',
			'Lomas de Chapultepec',
			'Lomas de San Juan',
			'San Francisco Oxtutla',
			'Las Vigas',
			'Nacuche',
			'Kancab',
			'Tlalconteno',
			'Excola',
			'Loma Angosta',
			'Emiliano Zapata',
			'Ignacio Zaragoza',
			'Pahua Hueca',
			'Nuevo San José Independencia',
			'Novara',
			'Juan Díaz Covarrubias',
			'Guadalupe Cachi',
			'Palos Amarillos (Palos Amarillos Yebuciví)',
			'San Francisco Chalchihuapan',
			'Las Huertas',
			'Ejido de Dolores',
			'San Bartolomé Tlaltelulco',
			'San Marcos de la Cruz',
			'San Juan Xochiaca',
			'San Pedro Cholula',
			'Santiago Tlapanaloya',
			'San Pedro Xalpa',
			'Pozo de Jarillas',
			'Casa de Cerros',
			'El Rosario',
			'Ejido Sinaloa',
			'Frontera',
			'Villa de García Márquez',
			'Ejido Tosalibampo',
			'Tlaquepaque',
			'Tlapehuala',
			'Tierra Blanca',
			'Tierra Blanca',
			'Tenería del Santuario',
			'Sinaloa de Leyva',
			'Santiago de Cuenda',
			'Santa Rosa Jauregui',
			'Santa Cruz',
			'San Luis de Lozada',
			'San José de Guaymas',
			'San Ignacio',
			'San Cristóbal de la Barranca',
			'Romero de Torres',
			'Punta de la Vega',
			'Puerto Peñasco',
			'La Providencia',
			'Pozo Dulce',
			'Peribán de Ramos',
			'Paracho de Verduzco',
			'Palos Altos',
			'Morelia',
			'Mojarras',
			'Merino',
			'Madera',
			'Los Pilares',
			'Los Guajes',
			'Loreto',
			'Loma Pelada',
			'Las Moras',
			'La Huerta',
			'La Estancia de Amezcua',
			'Ciudad Benito Juárez',
			'Juan Martín',
			'Jaluco',
			'Jalcocotán',
			'Jala',
			'Parral',
			'Guadalupe',
			'Guachochi',
			'Galindo',
			'Estipa',
			'Esmeralda',
			'El Tule',
			'El Puesto',
			'Ejido El Largo',
			'Victoria de Durango',
			'Dos de Abril',
			'Cuitzeo del Porvenir',
			'Condémbaro',
			'Ciudad Guzmán',
			'Ciudad Constitución',
			'Cinco de Febrero',
			'Chihuahua',
			'Charco de Pantoja',
			'Cárdenas',
			'Capacho',
			'Briseñas de Matamoros',
			'Buaysiacobe',
			'Atongo',
			'Antúnez',
			'General Juan José Ríos',
			'La Concepción',
			'El Refugio de Peñuelas',
			'Salitrillo',
			'Los Arcos',
			'San Juan Jaripeo',
			'Mezquite de Luna',
			'Loma de la Esperanza',
			'Venta de Bravo',
			'Agua Caliente',
			'Mariano Escobedo',
			'Los Cajones',
			'Donaciano Ojeda',
			'Salguero',
			'Coahuayana de Hidalgo',
			'Colonia Cuauhtémoc',
			'Presa de Rayas',
			'Las Lajitas',
			'El Castillo',
			'Miguel Hidalgo',
			'Las Palmas',
			'Ejido San Lorenzo Cuauhtenco',
			'Santa Justina Ecatepec',
			'La Galarza',
			'Loma Larga',
			'C-32 (Licenciado Francisco Trujillo Gurría)',
			'San Diego los Padres Cuexcontitlán Sección 5 B',
			'Nuevo San Miguel',
			'El Hielo',
			'La Colonia',
			'Santiago Acozac',
			'Amuco de la Reforma',
			'Cerrillos (Campo 35)',
			'Capoluca',
			'Uruétaro',
			'Las Esperanzas (El Ranchito)',
			'Ignacio Zaragoza',
			'Tulipanes',
			'Tocob Leglemal',
			'Colonia Montemariana (Colonia Mariana)',
			'Atenxoxola',
			'San José Atoyatenco',
			'La Trinidad Tianguismanalco',
			'Las Flechas',
			'Colonia el Pedregal',
			'Francisco Rueda',
			'San Antonio',
			'Apas',
			'Primer Cantón',
			'Santa María Urapicho',
			'Goros Número Dos',
			'Orizaba',
			'La Amistad',
			'Rancho Nuevo',
			'Acachapan y Colmena 3ra. Sección',
			'Ejido Palma (Ejido San Francisco)',
			'Ixtlilco el Chico',
			'Barrio de Ensido',
			'Putlunichuchut (Vista Hermosa)',
			'Ahuihuiyuco',
			'Barrio San Diego',
			'San Pedro Huitzapula Norte (Huitzapula Norte)',
			'Colonia Bellavista',
			'Colonia 3 de Mayo (La Cruz)',
			'Mixtlalcingo',
			'Arimatea',
			'Santo Domingo de las Palmas',
			'Cementeras del Pital',
			'Tzopilja',
			'Arroyo Chical (Nuevo Arroyo Chicali)',
			'Las Letras',
			'Barrio Nuevo de los Muertos',
			'Colonia de Guadalupe',
			'San Juan Tezongo',
			'El Cuitzillo Grande',
			'Paseos del Valle [Fraccionamiento]',
			'Tierra Negra',
			'Tocoy',
			'San José Boxay',
			'Cuartel la Mesa (El Asoleadero)',
			'Las Primaveras [Invernadero]',
			'San Andrés Yahuitlalpan',
			'Pueblo Nuevo',
			'Candelaria Portezuelo',
			'Unión Juárez',
			'San Miguel Tlamahuco',
			'Puerto de Chiquihuite',
			'San Miguel Ixtapan',
			'Zumpahuacán',
			'Tlacotepec',
			'Tepoztlán',
			'Tepakan',
			'Tenango de Arista',
			'Sunuapa',
			'Albarradas',
			'Santiago Oxthoc',
			'Santiago Huauclilla',
			'Moyotzingo',
			'Santa María Jicaltepec',
			'Santa Fe Tepetlapa',
			'Santa Catarina del Monte',
			'San Salvador',
			'San Quintín',
			'San Pedro Tapanatepec',
			'San Pedro el Alto',
			'San Pablo Güilá',
			'San Pablo Coatlán',
			'San Miguel Tilquiapam',
			'San Miguel del Puerto',
			'San Martín de los Canseco',
			'San Luis Tehuiloyocan',
			'San Juan Yaee',
			'San Juan Quiotepec',
			'San Juan Juquila',
			'Alchichica',
			'San Francisco Sola',
			'San Bartolo del Llano',
			'San Andrés Zautla',
			'San Andrés Hueyacatitla',
			'Río Frío de Juárez',
			'Putla Villa de Guerrero',
			'Polyuc',
			'Polotitlán de la Ilustración',
			'Pinal de Amoles',
			'Paxtepec',
			'Palmas de Abajo',
			'Santa María Magdalena Ocotitlán',
			'Ocotepec',
			'Natívitas',
			'Miramar',
			'Mecatlán',
			'Mazatepec',
			'Maravilla Tenejapa',
			'Maguey Blanco',
			'Santa Rosa Loma Larga',
			'Loché',
			'Lázaro Cárdenas',
			'Las Lomas',
			'Nueva Morelia',
			'Landa de Matamoros',
			'La Magdalena Yancuitlalpan',
			'La Libertad',
			'La Esperanza',
			'La Sidra',
			'Xalapa de Enríquez',
			'Ixcatlán',
			'Ixcapuzalco',
			'Huazantlán del Río',
			'Huay-Pix',
			'Homun',
			'Hecelchakán',
			'Ecatzingo de Hidalgo',
			'Dzilam de Bravo',
			'Dos Bocas',
			'Cuentepec',
			'Coajomulco',
			'Delegación Cuajimalpa de Morelos',
			'Cosautlán',
			'Cosamaloapan',
			'Constancia del Rosario',
			'Colatlán',
			'Coatzacoalcos',
			'Coatepec',
			'Coacoatzintla',
			'Mexico City',
			'Ciudad Cuauhtémoc',
			'Cárdenas',
			'Camocuautla',
			'San Matías Atzala',
			'Atoyac',
			'Atlatongo',
			'Atlatlahucan',
			'Arbolillo',
			'Apango',
			'Ignacio Allende',
			'Ajalpan',
			'Santiago Acayutlán',
			'Acaxtlahuacán de Albino Zertuche',
			'Acatlán',
			'Acahuasco',
			'Abasolo',
			'Talismán',
			'Santa Teresa',
			'Río Pachiñe',
			'San Agustín Atenango',
			'Río Chiquito',
			'San Antonino Castillo Velasco',
			'Miguel Hidalgo',
			'Chichicapa',
			'Dos Ceibas',
			'Vicente Guerrero',
			'Acoxcatlán',
			'San Ildefonso',
			'Santa Ana Azcapotzaltongo',
			'San José Teacalco',
			'Papalotla',
			'Nuevo Francisco León',
			'La Gloria',
			'Plan de Ayala',
			'Navenchauc',
			'Tzontehuitz',
			'Santa Elena',
			'Miguel Hidalgo',
			'Unión Juárez',
			'Cuauhtémoc',
			'La Esperanza',
			'Buenavista',
			'Tepalcatepec',
			'San Simón el Alto',
			'San Juan Tlacotompa (Tlacotompa)',
			'Coatepec',
			'San Marcos Nepantla',
			'San Sebastián Xolalpa',
			'Santa María Ajoloapan',
			'La Magdalena',
			'Bobashi de Guadalupe',
			'Villa Lázaro Cárdenas',
			'San Andrés Cholula',
			'Paso Carretas',
			'San Isidro Huilotepec',
			'Colonia Emiliano Zapata',
			'San Miguel de las Palmas',
			'Valle del Río',
			'Llano de la Puerta',
			'Santa Catarina',
			'Los Tepetates',
			'San Francisco',
			'Colombia de Guadalupe',
			'Cochoapa el Grande',
			'Juchitán',
			'Agua Zarca',
			'El Escobillo',
			'Los Pescados',
			'El Espinal',
			'Tlapala',
			'La Gloria',
			'Pisaflores',
			'Nuevo Progreso',
			'San Marcos Atesquilapan',
			'Helio García Alfaro',
			'San Juanico Sector Uno',
			'San Diego',
			'Maxtleca de Galeana',
			'Trinidad García de la Cadena',
			'Piedra Gorda',
			'La Capilla',
			'El Jaralito',
			'Tanques',
			'Vista Hermosa de Negrete',
			'Villa Madero',
			'Villa Guerrero',
			'Villa Emiliano Zapata',
			'Ejido Venustiano Carranza',
			'Vanegas',
			'Ucareo',
			'Tototlán',
			'Tonalá',
			'Tlalchapa',
			'Tixtlancingo',
			'Tierra Blanca',
			'Tamazula de Gordiano',
			'San Vicente el Alto',
			'Santiago Bayacora',
			'José María Morelos (Santa Mónica)',
			'Santa Elena',
			'San Simón de la Laguna',
			'Apulco',
			'San Nicolás Tolentino',
			'San Marcos',
			'San Luis de Letras',
			'San Luis del Cordero',
			'Cabo San Lucas',
			'San Ignacio de Hidalgo',
			'San Gabriel',
			'San Antonio del Cipres',
			'Rancho La Gloria',
			'Primero de Mayo',
			'Pegueros',
			'Páreo',
			'Ojinaga',
			'Navojoa',
			'Navajas',
			'Morales',
			'Moctezuma',
			'Medina',
			'Matachí',
			'Jesús Gómez Portugal',
			'León de los Aldama',
			'Puerto Adolfo Lopez Mateos',
			'Las Cieneguitas',
			'La Isla',
			'La Estancia',
			'Barra de Navidad',
			'Huajicori',
			'Higuera de Zaragoza',
			'Hernández',
			'Esfuerzos Unidos',
			'Escaleras',
			'El Trapiche',
			'Pueblo Nuevo',
			'Cayaco',
			'Cuencamé de Ceniceros',
			'El Corte',
			'Cofradia',
			'Chiquilistlán',
			'Chinampas',
			'Chichimequillas',
			'Carichí',
			'Benito Juárez',
			'California',
			'Bocoyna',
			'Barrón',
			'Banámichi',
			'Atotonilco el Alto',
			'Santa Eulalia',
			'Angahuán',
			'Amatepec',
			'Alcocer',
			'San Antonio de los Ríos',
			'Peñuelas (El Cienegal)',
			'Ojo de Agua de Crucitas',
			'Hidalgo',
			'Ojo de Agua del Refugio',
			'Maravatío del Encinal',
			'Las Ánimas',
			'Providencia',
			'La Luz',
			'Santa Catarina',
			'Peralta',
			'Ojo de Agua de Bucio',
			'Zirimícuaro',
			'Chupio',
			'Huanguitío',
			'Macho de Agua',
			'Tenería',
			'San Miguel del Centro',
			'Tres Estrellas',
			'La Puerta del Pilar',
			'San Ildefonso',
			'Colonia Emiliano Zapata',
			'La Gotera',
			'Joaquín Herrera',
			'Ejido Doctor Alberto Oviedo Mota (El Indiviso)',
			'Estación Zamora',
			'Fraccionamiento Real Palmas',
			'Unidad Habitacional José María Morelos y Pavón',
			'Tenextepango',
			'Vida Mejor I',
			'Fraccionamiento Arboledas San Ramón',
			'Villas de Irapuato',
			'Tatoxcac',
			'Los Tigres (San Marcos)',
			'Colonia Nueva Era',
			'Los Valles',
			'Pochahuizco',
			'Santa María Aranzazú (Santa María)',
			'Cuauhtémoc (Campo Cinco)',
			'San Francisco Independencia (Santa María Aserradero)',
			'Santa María Chimalapa',
			'San Francisco (El Calvito)',
			'Ejido la Soledad',
			"Tz'Aquiviljok",
			'Taniperla',
			'Belisario Domínguez',
			'Santa Elena',
			'Fraccionamiento Universo',
			'Bosques de San Pedro',
			'Los Ángeles',
			'El Limón',
			'Mezapa la Fábrica',
			'Manzana Sexta Parte Centro',
			'Artemio Treviño',
			'Cuautotolapan (San José)',
			'Crescencio Morales (San Mateo)',
			'Colonia Azteca',
			'El Tzay',
			'Zaragoza',
			'Piletas',
			'Cuaxoxpan',
			'Nuevo Guerrero',
			'Tandzumadz',
			'Santa Anita',
			'Pilotos',
			'Doctor Samuel León Brindis',
			'La Unión Ejido Mexcaltepec',
			'Alcozacán',
			'Puerta del Llano',
			'Fracción San Roque (El Prieto)',
			'Oriente 2da. Sección (San Cayetano)',
			'Fraccionamiento Riberas de San Jerónimo',
			'Fraccionamiento Paraíso Real',
			'Las Mercedes [Agropecuaria]',
			'Chimhucum',
			'Cantinela',
			'Cerrito de Jaral',
			'Los Garcías',
			'Álvaro Obregón',
			'Ahuexotitlán',
			'El Bajío',
			'Colonia Padre Hidalgo',
			'San Felipe Sultepec',
			'Llano de Agua',
			'Luis Espinoza',
			'J. Jesús González Ortega (San Mateo)',
			'Quetzalapa',
			'San Martín Mazateopan',
			'Tutepec',
			'Ejido el Castillo',
			'Camémbaro',
			'Fraccionamiento Carlos Salinas de Gortari',
			'Joltealal',
			'La Unidad Huitzizilapan',
			'Yecapixteca',
			'Lomas de Tenopalco',
			'El Pueblito',
			'San Martín Ejido',
			'Ixhuatlán del Sureste',
			'Jalpilla',
			'Nuevo Tampaón',
			'General Zaragoza',
			'Yolotepec',
			'Yaxe',
			'Ciudad Cuauhtémoc',
			'Tonalixco',
			'Tlamixtlahuacan',
			'Xicoténcatl',
			'Zocea',
			'Zacapala',
			'Magdalena Yodocono de Porfirio Díaz',
			'Yaxcabá',
			'Xilitla',
			'Villa Comaltitlán',
			'Tzinacapan',
			'Santa Ana Tzacuala',
			'Totutla',
			'Tonalá',
			'Tomatlán',
			'Tequila',
			'Tepetlaoxtoc de Hidalgo',
			'Tanetze de Zaragoza',
			'San Felipe Tejalápam',
			'Santiago Lalopa',
			'Santa Rosa Xajay',
			'Santa Ana Ixtlahuatzingo (Santa Ana)',
			'Villanueva',
			'San Salvador El Seco',
			'San Pedro Arriba',
			'San Miguel Contla',
			'San Miguel',
			'San Martin Texmelucan de Labastida',
			'San Lucas',
			'San Lorenzo Cuauhtenco',
			'San Juan de los Cues',
			'San Juan Bautista Lo de Soto',
			'Cuicatlan',
			'San Isidro',
			'San Isidro',
			'San Gregorio Atzompa',
			'San Gabriel las Palmas',
			'Atexcatzingo',
			'San Fernando',
			'Villa Mariano Matamoros',
			'San Bernardino Lagunas',
			'San Antonio Ocotlán',
			'Salazar',
			'Pucté',
			'Progreso de Alvaro Obregon',
			'Pixoy',
			'Patla',
			'Santa Cruz Papalutla',
			'Palomares',
			'Nueva Villa de Padilla',
			'Nopaltepec',
			'Montemorelos',
			'Mohoneras',
			'Mixcum',
			'Milpa Alta',
			'Mier',
			'Metztitlán',
			'San Miguel Mecatepec',
			'Ursulo Galván',
			'Lerma',
			'La Trinitaria',
			'La Isla',
			'Carboneras',
			'Kopomá',
			'Jocotitlán',
			'Ixtepec',
			'Villa Independencia',
			'Huimanguillo',
			'El Huexco',
			'Hoctun',
			'Comalapa',
			'El Triunfo',
			'El Jobo',
			'El Espinal',
			'El Castillo',
			'El Águila',
			'Doxey',
			'Cuautlancingo',
			'Cuautinchán',
			'Coyoacán',
			'Cotaxtla',
			'Copanatoyac',
			'San Pedro Comitancillo',
			'Colonia Yucatán',
			'Cocoyoc',
			'Ciudad Victoria',
			'Ciudad Pemex',
			'Ciudad Hidalgo',
			'Chunchucmil',
			'San Pedro Chochula',
			'Cadereyta Jiménez',
			'Cadereyta',
			'Bécal',
			'Apan',
			'Altotonga',
			'Actopan',
			'Acaxochitlán',
			'Acatempan',
			'Acala',
			'Acacoyagua',
			'La Pahua',
			'San Pablo de los Remedios',
			'La Mina',
			'Zocoteaca de León',
			'Tezonapa',
			'Francisco I. Madero',
			'Huacapa y Amestoy',
			'La Ceiba',
			'Raya Zaragoza',
			'La Reforma',
			'Chatipán',
			'Tepexititla',
			'Taxadho',
			'Pueblo Nuevo',
			'El Tephé',
			'Nantzha',
			'Presas',
			'Munitepec de Madero',
			'Lagunilla',
			'San Pedro Muñoztla',
			'Santo Domingo',
			'Ubilio García',
			'Chilón',
			'Cuauhtémoc',
			'Pomposo Castellanos',
			'Jesús María Garza',
			'San Luqueño',
			'Aquiles Serdán',
			'Llano Grande',
			'San Gaspar',
			'San Matías Cuijingo',
			'Los Arana',
			'San Juan Cuauhtémoc',
			'San Agustín Atzompa',
			'Álvaro Obregón',
			'Alhuaca',
			'San Juan Raboso',
			'San Diego el Organal',
			'Altamira de Zináparo',
			'Cruz Quemada',
			'Pueblo Hidalgo',
			'El Paraíso (La Charca)',
			'Llano Grande',
			'Ixcatla',
			'Palmillas',
			'Emiliano Zapata',
			'La Concepción',
			'Tilapan',
			'Poblado Cinco',
			'Hermanos Cedillo',
			'San Bartolo Lanzados',
			'San José Boqui',
			'Enthavi',
			'San Bartolo del Progreso',
			'Ignacio Allende',
			'San Luis Taxhimay',
			'Santiago Teyahualco',
			'El Plateado de Joaquín Amaro',
			'San José de Castellanos',
			'Estancia de Guadalupe',
			'Pedregoso',
			'Rodrigo',
			'San Rafael',
			'Las Brisas',
			'Santa María Transpontina',
			'San Esteban de Abajo',
			'Santa Fé',
			'Zirahuén',
			'Zamora',
			'Zacatula',
			'Villa Union',
			'Villa Juárez',
			'Ciudad de Villa de Álvarez',
			'Vicente Guerrero',
			'Vetagrande',
			'El Vergel',
			'Uspero',
			'Tupataro',
			'Tingüindín',
			'Tierra Blanca',
			'El Tepetate',
			'Tamazula',
			'Santa Teresa',
			'Santa Catarina de Tepehuanes',
			'San Miguel de Cruces',
			'San Jerónimo de Juárez',
			'San Felipe de Híjar',
			'San Agustín',
			'Rosales',
			'Río Grande',
			'Quiriego',
			'Queréndaro',
			'Pozos de Gamboa',
			'Paracuaro',
			'Nuxco',
			'Churumuco de Morelos',
			'Mochicahui',
			'Milpas Viejas',
			'Mier y Noriega',
			'Mazatán',
			'El Maluco',
			'Maguarichi',
			'Los Remedios',
			'Lo de Lamedo',
			'Ejido La Quemada',
			'La Primavera',
			'La Palma',
			'La Monja',
			'Caimanero',
			'Banderas del Águila',
			'Ascensión',
			'Jiménez',
			'Janos',
			'Jalpa',
			'Ixtapan del Oro',
			'Huiramba',
			'Huepaco',
			'Guerrero',
			'La Estancia de San José del Carmen',
			'Emiliano Zapata',
			'Seis de Enero',
			'El Nayar',
			'El Limón',
			'El Chante',
			'Villa Insurgentes (El Calabazal)',
			'Ejido Tabasco',
			'Ejido Quintana Roo',
			'Gambino',
			'El Durazno',
			'Degollado',
			'Cuautitlán',
			'Carona',
			'Cofradía de Navolato (Cofradía de los Rocha)',
			'Chapala',
			'Cantuna',
			'Buenavista',
			'Bocas',
			'Bagojo Colectivo',
			'Bachomobampo Número Dos',
			'Asientos',
			'Agua Gorda',
			'Valladolid',
			'La Punta',
			'Santa María de la Paz',
			'La Escondida',
			'Doctor Mora',
			'Mezquite de Sotelo',
			'Zapote de Cestao',
			'El Puesto',
			'El Escoplo',
			'Epitacio Huerta',
			'San Antonio Villalongín',
			'Parícuaro',
			'Corral Falso',
			'Los Olvera',
			'Dolores Cuadrilla de Enmedio',
			'El Rosario',
			'Laguna de Vaquerías',
			'El Barrancón del Tío Blas (El Barrancón)',
			'La Isla de Chapachapa',
			'Santa Teresa',
			'Lomas del Sur',
			'Fraccionamiento Geovillas los Pinos',
			'Pomoca',
			'Marte R. Gómez (Tobarito)',
			'Santa Catarina Ayometla',
			'Ciudad Coahuila (Kilómetro Cincuenta y Siete)',
			'Campestre las Palomas [Fraccionamiento]',
			'San Bernabé Temoxtitla',
			'Tacopan',
			'Peña Flores (Palo Verde)',
			'San Antonio Gallardo',
			'Cuarta Brigada',
			'El Huarache (El Guarache)',
			'El Cid',
			'San Jerónimo Almoloya',
			'Jorobas',
			'Hacienda la Cruz [Fraccionamiento]',
			'Colonia Campesina',
			'Pueblos Unidos',
			'Texalpan de Arriba',
			'Mariano Balleza',
			'Otilpan',
			'Manuel Villalongín',
			'Buenavista de Juárez',
			'San Sebastián [Fraccionamiento]',
			'Cerro las Iguanas',
			'Atlamajac',
			'Colonia Agrícola Analco',
			'Monclova Primer Sector',
			'Hacienda Sotavento',
			'Istaro',
			'Xocoyolzintla',
			'Camelia Roja',
			'Cerro de San Francisco',
			'Cozotlán Norte',
			'Santa Teresita de Don Diego',
			'Tlacoapa',
			'San Martín Obispo (San Martín San Pedro)',
			'Entronque de Matehuala (El Huizache)',
			'El Potrerillo (Potrerillo del Rincón)',
			'Cantioc',
			'Sibaniljá Pocolum',
			'Adolfo Moreno',
			'Colonia Reforma Tlalmimilolpan',
			'San José de Abajo',
			'Lomas de San Pablo',
			'Cumbres III',
			'Residencial Tajín',
			'Potrerillo',
			'Gabriel Hernández (Mancinas)',
			'Loma de San Francisco',
			'Licenciado Gustavo Díaz Ordaz (18 de Marzo)',
			'Izotepec',
			'El Llano del Compromiso',
			'Jagüey Blanco',
			'Emiliano Zapata (Casahuates)',
			'San Antonio de los Tepetates',
			'Fraccionamiento Villas de Guanajuato',
			'Miguel Utrilla (Los Chorros)',
			'Arvenza Uno',
			'Santiago Centro',
			'Guadalupe Victoria',
			'Concentración 5 de Febrero',
			'Tlatilpa',
			'Loma de Yerbabuena',
			'Guadalupe Morelos',
			'San José Independencia',
			'Fraccionamiento del Valle',
			'El Camalote',
			'Tlacuilola',
			'Campo de Águila',
			'La Esperanza (El Zapotal)',
			'Tlaminca',
			'El Colorado',
			'La Concepción',
			'Teocalco',
			'Colonia Morelos',
			'Tierra Blanca Booxter',
			'San Antonio Tedzidz',
			'Tecoltepec',
			'Tapachula',
			'Zautla',
			'Santiago Chazumba',
			'Santa María Camotlán',
			'Santa Cruz Cuauhtenco',
			'Las Armas',
			'Zoquitlán',
			'Zoquiapan',
			'Zapoapan de Cabañas',
			'Zacacuautla',
			'Yextla',
			'Yajalón',
			'Xochitlán',
			'Xhitey',
			'Xaya',
			'Valladolid',
			'Tunititlán',
			'San Miguel Topilejo',
			'Tlazala de Fabela',
			'Tlaxcoapan',
			'Tlaltenango',
			'San Jerónimo Tlacochahuaya',
			'Tixcancal',
			'Texca',
			'Tepeji del Río de Ocampo',
			'Santiago Tenango',
			'Tenampulco',
			'Tapotzingo',
			'Tapijulapa',
			'Sierra de Agua',
			'Sergio Butrón Casas',
			'Los Sauces',
			'Atzingo',
			'Santo Domingo Huehuetlán',
			'Santiago Tezontlale',
			'Santiago Lachiguiri',
			'Santiago del Monte',
			'Santa María Ixtiyucán',
			'Santa María Actipac',
			'Santa Margarita Huitepec',
			'Santa Elena',
			'Santa Catarina Tlaltempan',
			'Santa Catarina Loxicha',
			'Santa Ana del Valle',
			'San Pedro Mártir Quiechapa',
			'San Pedro de los Metates',
			'San Pedro Atoyac',
			'Zozutla',
			'San Lucas Ojitlán',
			'Almecatla',
			'San Juan Quiahue',
			'San Juan Atzingo',
			'San Francisco Ozolotepec',
			'San Francisco Cajonos',
			'San Felipe Jalapa de Díaz',
			'San Bernardo Mixtepec',
			'San Antonio',
			'San Antonio la Isla',
			'San Andrés Payuca',
			'San Andrés',
			'San Agustín Etla',
			'Ciudad Río Bravo',
			'Cantón Rancho Nuevo',
			'Pomuch',
			'Piste',
			'Pinotepa de Don Luis',
			'Pencuyut',
			'Parás',
			'Oxiacaque',
			'Omitlán de Juárez',
			'Ucum',
			'Mixtla de Altamirano',
			'Mineral del Monte',
			'Mecatlán',
			'Magdalena Tequisistlán',
			'Lerma de Villada',
			'Río Verde',
			'Komchén',
			'Jaltepec',
			'Izamal',
			'Ixtaczoquitlán',
			'Hunuku',
			'Hueyapan de Ocampo',
			'Huatlatlauca',
			'Ciudad de Huajuapan de León',
			'Guaytalpa',
			'Epazoyucan',
			'Elotepec',
			'Cuzama',
			'Cosolapa',
			'Coscomatepec de Bravo',
			'Concá',
			'Chicxulub Pueblo',
			'Chacalapa',
			'Calcahualco',
			'Cacalomacan',
			'Burgos',
			'Benito Juárez',
			'Benito Juárez',
			'Ayometitla',
			'Ayotla',
			'Axochío',
			'Atlixco',
			'Anáhuac',
			'Amecameca',
			'Amatlán de Quetzalcoatl',
			'Amatitlán de Azueta',
			'San Antonio Alpanocan',
			'Almoloya del Río',
			'Ahuatitla',
			'Agua Buena',
			'Acayucan',
			'San Juan del Río',
			'Santo Tomás Tamazulapam',
			'Ahuehuetitlán',
			'Tamazulapam Villa del Progreso',
			'Constitución Mexicana',
			'Libertad',
			'Pueblo Nuevo de las Raíces',
			'Playas del Rosario',
			'Ohuatipa',
			'Tecacahuaco',
			'Hualula',
			'Teofani',
			'Tlalminulpa',
			'Emiliano Zapata',
			'Francisco I. Madero',
			'San Felipe Hidalgo',
			'Muñoz',
			'San Lorenzo Xaltelulco',
			'Colonia Venustiano Carranza',
			'Tetlanohcán',
			'San Cosme Atlamaxac',
			'Santa María',
			'Ignacio Zaragoza',
			'Nicolás Bravo',
			'La Floresta',
			'El Triunfo',
			'Zaragoza la Montaña',
			'Francisco Villa',
			'El Sacrificio',
			'San Jerónimo Amanalco',
			'Tequisistlán',
			'Tezoyuca',
			'Atla',
			'Chichicuautla',
			'San Pedro Temamatla',
			'El Naranjo',
			'Buenavista de Allende',
			'Holcá',
			'Bella Esperanza',
			'Chavaxtla',
			'Rancho del Padre',
			'Ex-hacienda la Concepción',
			'Veinte de Noviembre',
			'Xochimilco',
			'Isla',
			'Agua Fría',
			'San José de la Laja',
			'San José las Lomas',
			'Tlacomulco',
			'San Nicolás Peralta',
			'Colonia Álvaro Obregón',
			'Ignacio Zaragoza',
			'Cerrito de la Cruz',
			'Hacienda Nueva',
			'Seis de Enero',
			'La Reforma',
			'Piaxtla de Abajo',
			'Islas Agrarias Grupo B',
			'Zirahuato de los Bernal',
			'Ignacio Zaragoza',
			'Yuriria',
			'Santa Clara del Cobre',
			'Villa de Reyes',
			'Venecia',
			'Tubutama',
			'Tonaya',
			'Tlacote el Bajo',
			'Tepic',
			'Tala',
			'San Pedro Lagunillas',
			'San Pedro',
			'San Juan de los Arcos',
			'Moras',
			'San Ignacio Cerro Gordo',
			'San Francisco Peribán',
			'San Francisco de Rivas',
			'San Antonio la Labor',
			'Recoveco',
			'Purificación',
			'Bahía Tortugas',
			'Pozos',
			'Pótam',
			'Pericos',
			'Peotillos',
			'Pátzcuaro',
			'Otatlán',
			'Ojuelos de Jalisco',
			'Ojo Caliente',
			'Narciso Mendoza',
			'Milpillas',
			'Mezcala',
			'León Guzmán',
			'La Yesca',
			'La Ventilla',
			'Lampazos de Naranjo',
			'La Laja',
			'La Florida',
			'La Esperanza',
			'La Colorada',
			'La Cantera',
			'Juan Gil Preciado',
			'Juan Escutia (Borbollón)',
			'Juanacatlán',
			'Jarácuaro',
			'Huetamo de Núñez',
			'Huanímaro',
			'Huajimic',
			'Nogales',
			'Guadalcázar',
			'Etzatlán',
			'Ensenada',
			'El Molino',
			'El Lucero',
			'El Cuije',
			'El Capulín',
			'El Bajío',
			'Cuexcomatitlán',
			'Palmar de Cuautla',
			'Colonia Plenitud',
			'Chilapa',
			'Chichimequillas',
			'Cerrito de Zavala',
			'Cerocahui',
			'Belén del Refugio',
			'Aticama',
			'Atequiza',
			'Ameca',
			'Alhuey',
			'Ahome',
			'San Sebastián de Salitre',
			'San Isidro de la Estacada',
			'El Refugio de los Sauces',
			'Labor de Peralta',
			'San Antonio de los Morales',
			'La Tinaja',
			'Medio Sitio',
			'San José del Rodeo',
			'Colonia Morelos',
			'Mesón Nuevo (Cañada de la Magdalena)',
			'Purechucho',
			'Barrio de Arriba de San Juan Xoconusco',
			'Coyuquilla Norte',
			'La Luz',
			'Jofrito',
			'La Noria',
			'Machetla',
			'San Lorenzo',
			'El Niño',
			'Rancho Alegre [Fraccionamiento]',
			'San Antonio Mihuacán',
			'Pedro Amaro',
			'Las Torres',
			'San José el Cuartel',
			'Santiago Etla',
			'Plutarco Elías Calles (La Y Griega)',
			'Chipilo de Francisco Javier Mina',
			'Galaxia la Calera',
			'Tamazulápam del Espíritu Santo',
			'Manuel Ávila Camacho',
			'Tepetzingo',
			'El Rincón (Santa Cruz del Rincón)',
			'El Recuerdo de Ancón (Xoconoxtle de Arriba)',
			'Colonia Ricardo Flores Magón',
			'Colonia Guadalupe Victoria (La Capilla)',
			'Cima del Sol (Fraccionamiento Cima del Sol)',
			'Magisterio Digno',
			'El Carmen (El Limón)',
			'San Francisco Tlaltica',
			'Xoxonacatla',
			'San José del Cerrito de Camargo',
			'Santa Cruz Acapa',
			'Campo la Arrocera',
			'Vista Hermosa',
			'Colonia Veintitrés de Mayo',
			'Mezquitic de la Magdalena',
			'Tierras Coloradas',
			'La Estancia',
			'Conjunto Habitacional el Trébol',
			'San Jerónimo Coaltepec',
			'Residencial las Olas',
			'El Embarcadero',
			'Miguel Hidalgo 2da. Sección B (La Natividad)',
			'Colonia Sesenta y Dos',
			'San Francisco Jaconá',
			'Romerillo',
			'Playa Chica',
			'El Calvario',
			'La Luz de Juárez',
			'Tepozcuautla',
			'Xalatlaco',
			'Escalerilla Lagunas',
			'Chichicapa',
			'Profresor Roberto Barrios',
			'Progreso (Campo 47)',
			'Santiago Ixtaltepec',
			'Estación la Piedad',
			'Valle de Juárez',
			'Miravalle',
			'El Olvera',
			'Pueblo Benito García',
			'Cruz Verde',
			'Iratzio',
			'Estacion Bamoa',
			'Tulillos de Abajo',
			'San Bartolo Coyotespec',
			'Benito Juárez',
			'San Sebastián Alcomunga',
			'Yehualtepec',
			'Xayacatlán de Bravo',
			'Gustavo Adolfo Madero',
			'Villa Aldama',
			'Uci',
			'Uayma',
			'San Javier',
			'Tuzamapan',
			'Túxpam de Rodríguez Cano',
			'Tuxpan',
			'Tulapam',
			'Tula de Allende',
			'Tlanepantla',
			'Tlalixtaquilla',
			'San Andrés Tlalamac',
			'Tlacotepec',
			'Tlachichilco',
			'Santiago Tianguistenco de Galeana',
			'Tepeyahualco',
			'Taxco de Alarcón',
			'Tamiahua',
			'Santo Tomas',
			'Santiago Texcalcingo',
			'Santiago Ayuquililla',
			'Apoala',
			'Santa María Chilapa de Díaz',
			'Atarasquillo',
			'Santa María',
			'Santa Cruz Tayata',
			'Santa Catarina Cuixtla',
			'San Pedro Topiltepec',
			'San Nicolas Buenos Aires',
			'San Miguel Xoxtla',
			'San Miguel Panixtlahuaca',
			'San Miguel Ejutla',
			'San Mateo Parra',
			'San Juan Tuxtepec',
			'Valle Nacional',
			'San José Corral Blanco',
			'San José Chiltepec',
			'San José Ayuquila',
			'San Francisco Tlalcilalcalpan',
			'San Francisco',
			'San Francisco',
			'San Diego Linares',
			'Ejido San Diego',
			'San Agustín Huixaxtla',
			'Río Grande',
			'Piedra Labrada',
			'Petaquillas',
			'Paso Nacional',
			'Pachuquilla',
			'San Mateo Ozolco',
			'San Pablo Oztotepec',
			'Orizaba',
			'Ohuilapam',
			'Ohuapan',
			'Nueva Colombia',
			'Niltepec',
			'Miquihuana',
			'Magdalena Jaltepec',
			'Los Órganos de San Agustín',
			'Jonuta',
			'Jonotla',
			'Jilotzingo',
			'Ixhuapán',
			'Ignacio López Rayón',
			'Villa Hidalgo',
			'Halachó',
			'El Mirador',
			'Cumpich',
			'Cuexpala',
			'Comaltepec',
			'Chinantla',
			'Chinameca',
			'Chiconcuac',
			'Chiaucingo',
			'Chelem',
			'Chacsinkin',
			'Cerro Azul',
			'Calmeca',
			'Buctzotz',
			'Boca del Rio',
			'Astapa',
			'Arroyo Seco',
			'Colonia Álvaro Obregón',
			'Lomas de Ahuatepec',
			'Agualeguas',
			'Acapulco de Juárez',
			'Acanceh',
			'Miraflores',
			'San Lorenzo Vista Hermosa',
			'Santa Cruz de Juárez',
			'La Junta',
			'Piedra de Amolar',
			'Donaji',
			'Oriente 1ra. Sección',
			'Mazateupa',
			'Benito González',
			'Pueblo Hidalgo',
			'Cuapaxtitla',
			'La Esquina',
			'Patria Nueva',
			'Ignacio Zaragoza',
			'San Simón Tlatlahuquitepec',
			'Los Pilares',
			'Acuamanala',
			'La Magdalena Tlaltelulco',
			'San Mateo Ayecac',
			'Guadalupe Texcalac',
			'Punta Arena',
			'Río Chancalá',
			'Pacú',
			'El Copal',
			'Ojo de Agua',
			'Chihuahua',
			'Señor del Pozo',
			'Plan de Ayala',
			'Los Riegos',
			'El Parral',
			'Libertad Melchor Ocampo',
			'El Carmen',
			'Tepetlixpa',
			'San Pedro Nexapa',
			'San Andrés de las Peras',
			'San Bartolo',
			'San Miguel Atepoxco',
			'San Miguel Atlamajac',
			'San Lorenzo Nenamicoyan',
			'Yetlancingo',
			'X-Hazil Sur',
			'Playa de Chachalacas',
			'Colonia Úrsulo Galván',
			'La Capilla',
			'Mata Naranjo',
			'Campo Grande',
			'Cecilio Terán',
			'San Pedro Coyutla',
			'Mequetla',
			'Carrillo Puerto',
			'Emiliano Zapata',
			'Palma Sola',
			'Santa Cruz Escandón',
			'Paso de Mata',
			'San Andrés de los Gama',
			'El Palmito',
			'Tenjay',
			'San Pedro Abajo',
			'Tres Marías',
			'San Lorenzo Tlacoyucan',
			'Huixquilucan',
			'Cantera de Villagrán',
			'Tenayuca',
			'El Nigromante',
			'Colonia José María Morelos',
			'Chupaderos',
			'Emilio Carranza',
			'San Diego',
			'Enrique Estrada',
			'Matamoros',
			'La Constitución',
			'Ventana',
			'Ocho de Enero',
			'Primo Tapia',
			'Estación Coahuila',
			'Guadalupe Victoria',
			'Zoquite',
			'Ixtapa-Zihuatanejo',
			'Villa Hidalgo',
			'Torreón',
			'Tlajomulco de Zúñiga',
			'Tierra Fría',
			'Tazumbo',
			'Tamazula',
			'Sentispac',
			'Sayulita',
			'Ejido la Pitahaya (Santo Domingo)',
			'Santiaguito (Santiaguito de Velázquez)',
			'Santa Cruz del Valle',
			'Santa Cruz de las Flores',
			'Santa Bárbara',
			'San Pablo',
			'San Nicolás de los Garza',
			'San Nicolás del Cármen',
			'San Miguelito',
			'San Lorenzo',
			'San Benito',
			'San Antonio de los Vázquez',
			'Salamanca',
			'Sahuaral de Otero',
			'Estación Queréndaro',
			'Pomacuarán',
			'Paso de Hidalgo (Paso de Álamos)',
			'Palos Blancos',
			'Pabellón de Arteaga',
			'Ojo Seco',
			'Ojo de Agua de Mendoza',
			'Oconahua',
			'Nahuatzén',
			'Mina',
			'Menores',
			'Magdalena de Araceo',
			'Las Varas',
			'Las Cañas',
			'La Sauceda',
			'Ejido la Joya',
			'Jomulco',
			'Ixtlahuacán del Río',
			'Indaparapeo',
			'General Calixto Contreras',
			'Estación Rosales',
			'Empalme',
			'Emiliano Zapata',
			'El Tequesquite',
			'Cusihuiriachi',
			'Cupareo',
			'Cumuato',
			'Antonio Rosales',
			'Choix',
			'Chaparaco',
			'La Resolana',
			'Casa Blanca',
			'Carapán',
			'Carácuaro',
			'El Capomal',
			'Cañada de Caracheo',
			'La Bocana',
			'Atotonilco el Bajo',
			'Abasolo',
			'Montoro',
			'Bahía Asunción',
			'Estanzuela de Romero',
			'Piedras de Lumbre',
			'El Fresno',
			'La Luz',
			'San Pedro Tarímbaro',
			'San Francisco Mihualtepec',
			'San José del Valle',
			'Santa Catarina',
			'Saldarriaga',
			'Colonia Nativitas',
			'Jardines de la Silla (Jardines)',
			'Tres de Mayo',
			'Bosque de Saloya',
			'San Andrés Azumiatla',
			'Granjas Ampliación Santa Rosa',
			'San Francisco de Asís',
			'La Trinidad',
			'Yoshib',
			'Barrio Cuarto (La Loma)',
			'San Antonio Portezuelo',
			'Changata',
			'Loma de San José',
			'La Ermita (Nueva Jerusalén)',
			'Centro de Readaptación Social',
			'La Noria',
			'Palma Grande',
			'Loma Bonita',
			'Progreso',
			'Campo Real',
			'Acatempa',
			'Monte Grande',
			'Huehuetán',
			'Apeo',
			'San Roque de Montes',
			'Ahuacatlán de Guadalupe',
			'Cuaxuxpa',
			'San Jerónimo Yahuiche',
			'Sebastián Lerdo de Tejada',
			'Valle Verde',
			'Llano de la Lima',
			'Laguna de Tabernillas (El Resbaloso)',
			'Soyatlán de Afuera',
			'Santa Cruz Xaltetela',
			'San Miguel Papaxtla',
			'Encrucijada 3ra. Sección (Las Calzadas)',
			'Corriente 1ra. Sección',
			'Muquén',
			'El Molino',
			'Chacoma',
			'Ignacio Allende',
			'CERESO Nuevo',
			'El Obraje',
			'Primero de Mayo (Chupaderos)',
			'Colonia los Remedios',
			'Rincón Verde',
			'San Vicente (Calpulalpan Tercera Manzana)',
			'San José de Rancho Nuevo (Los Arrieros)',
			'Sierra de Agua',
			'Santa Cruz Otlatla',
			'Residencial Arboledas',
			'Nicolás Blanco (San Pancho)',
			'Molino de San José',
			'Santiago Chilixtlahuaca',
			'Mariscal Subikuski',
			'Nenehuaca',
			'Miguel Negrete',
			'Las Plazuelas',
			'El Progreso Hidalgo',
			'Caobanal 1ra. Sección (Mezcalapa)',
			'Tlamaya Grande',
			'Ampliación Tezoyuca',
			'San Nicolás Temascatío',
			'Macvilhó',
			'Juan de la Granja',
			'El Castillo',
			'Ojo de Agua',
			'Zoquiopan',
			'Zaragoza',
			'Zaragoza',
			'Yecapixtla',
			'Yaxkukul',
			'Xoxolpa',
			'Xochiapulco',
			'Xilocuautla',
			'Xaltepuxtla',
			'Tolimán',
			'Tlanepantla',
			'Tierra Nueva',
			'Tianguistengo',
			'Tetela del Volcán',
			'San Juan Tepulco',
			'Barrio Tepetitlán Emilio Portes Gil',
			'Tepatepec',
			'Tepango',
			'Tecomatlán',
			'Tantoyuca',
			'Tankuché',
			'Santo Domingo Tepuxtepec',
			'Santo Domingo Chihuitán',
			'Santa María del Monte',
			'Heroica Ciudad de Tlaxiaco',
			'Santa María Ajoloapan',
			'Santa Cruz',
			'Santa Cruz Pueblo',
			'Santa Ana Tlapacoyan',
			'Santa Ana Tlacotenco',
			'San Pedro Apóstol',
			'Tecuanipa',
			'San Mateo Ixtlahuaca',
			'Teotihuacán',
			'San Juan Ixtayopan',
			'San Juan Cuautla',
			'San Acateno',
			'Ixtapantongo',
			'San Francisco',
			'San Cristóbal Lachirioag',
			'Sacalum',
			'Rincón Moreno',
			'Rinconada',
			'Grajales',
			'Quintero',
			'Quebrantadero',
			'Polanco',
			'Plan de Arroyos',
			'Palomas',
			'Palenque',
			'Oriental',
			'Once de Abril',
			'Omealca',
			'Santo Domingo Ocotitlán',
			'Nuevo San Juan Chamula',
			'Nacajuca',
			'Muxupip',
			'Monte Redondo',
			'Santiago Momoxpan',
			'Metepec',
			'Metapa',
			'Colonia Manuel González',
			'Los Romeros',
			'Los Reyes',
			'Los Naranjos',
			'Libres',
			'Lázaro Cárdenas',
			'La Pedrera',
			'La Grandeza',
			'La Defensa',
			'Kanasín',
			'San Leoncio Jamaya',
			'Jamapa',
			'Jaleaca de Catalán',
			'Ixpila',
			'Itzamatitlán',
			'Huitzililla',
			'Huitchila',
			'Huilacapixtla',
			'Huehuetla',
			'Huehuetla',
			'Guadalupe',
			'Estapilla',
			'Los Robles',
			'Ébano',
			'Cuautla',
			'Coxcatlán',
			'Conejos',
			'Coatepec Harinas',
			'Coapilla',
			'Ciudad Camargo',
			'Chicualoque',
			'Chiconamel',
			'Chicbul',
			'Chacaltianguis',
			'San Lorenzo Cacaotepec',
			'Bacalar',
			'Azoyú',
			'Ayotoxco de Guerrero',
			'Ciudad Ayala',
			'Atlequizayan',
			'Atlautla',
			'Apaxco de Ocampo',
			'San Pedro Amuzgos',
			'Almolonga',
			'Ahuateno',
			'Agua Dulce',
			'San Lorenzo Acopilco',
			'José María Morelos',
			'La Barra de Colotepec',
			'San Francisco Cozoaltepec',
			'Camotinchan',
			'Zapotitlán Lagunas',
			'Santa María Jalapa del Marqués',
			'Taniche',
			'San Blas Atempa',
			'Jiménez',
			'Tres Bocas 1ra. Sección',
			'Emiliano Zapata',
			'Cuazahuatl',
			'Dajiedi',
			'Dios Padre',
			'Ahuehuetitla',
			'Lázaro Cárdenas',
			'Nuevo Volcán Chichonal',
			'Francisco I. Madero',
			'Benito Juárez',
			'Aztlán',
			'Mariano Matamoros',
			'Ignacio Zaragoza',
			'San Pablo Tejalpa',
			'Santiago Oxtotitlán',
			'San Agustín',
			'Xocotlán',
			'San Miguel Tlaixpan',
			'Santa Ana Tlachiahualpa',
			'Venta Grande',
			'San Miguel Zoapan',
			'San Buenaventura',
			'Concepción Cuautla',
			'San Pablo Ahuatempa',
			'San Ángel',
			'Las Coloradas',
			'Rancho Viejo',
			'Tonalaco',
			'El Palmar',
			'Morelos',
			'Puente de Piedra',
			'María de la Torre',
			'Salvador Díaz Mirón',
			'Melchor Ocampo',
			'San Agustín del Palmar',
			'Palmar Grande',
			'Estación Juanita',
			'Boxasní',
			'Villa Progreso',
			'Santa María Endare',
			'San Francisco Tepeolulco',
			'Xhimojay',
			'El Magueyal',
			'San José Pathé',
			'San Pedro Totoltepec',
			'San Felipe el Mirasol',
			'Unidad San Miguel Jagüeyes',
			'Melchor Ocampo',
			'La Luz',
			'Cerro Gordo',
			'Isla del Bosque',
			'Lo de Jesús (Campo Romero)',
			'Los Azulitos',
			'Zipiajo',
			'Villanueva',
			'Valle del Rosario',
			'Ures',
			'Turicato',
			'Colonia Terrazas',
			'Tepec',
			'Solima',
			'Santa María del Valle',
			'Santa Clara de Valladares',
			'San Juanito',
			'San Javier',
			'San Gregorio',
			'San Felipe de los Herreros',
			'San Andrés',
			'Samalayuca',
			'Rancho Alegre',
			'Puroagua',
			'Pihuamo',
			'Pasaje',
			'Nicolas Bravo',
			'Jesús María',
			'Namiquipa',
			'Morelos',
			'Mezcales',
			'Meoqui',
			'Maravillas',
			'Luchanas',
			'Los Rodríguez',
			'Los Conos',
			'Los Algodones',
			'Trincheras',
			'La Sauceda',
			'Purísima',
			'La Palma',
			'Laguna de San Vicente',
			'Constancia',
			'Juriquilla',
			'Jungapeo de Juárez',
			'Ixtlán del Río',
			'Ixtapa',
			'Guadalupe y Calvo',
			'Guadalupe de Paso Blanco',
			'Guadalupe de Rivera',
			'Villa Gregorio García',
			'Francisco Sarabia (Cerrito Pelón)',
			'Erongarícuaro',
			'El Salitre',
			'El Rosario de Arriba',
			'El Platanal',
			'El Pinto',
			'Cuquío',
			'Cuauhtemoc',
			'Coyame',
			'Congregación Ortíz',
			'Comala',
			'Cofradía',
			'Churintzio',
			'Cherán',
			'Cerro de San Pedro',
			'Ceballos',
			'San Cayetano',
			'Cañada de Negros',
			'Cajititlán',
			'Bacoachi',
			'Autlán de Navarro',
			'Atoyac',
			'Atacheo de Regalado',
			'Arantepacua',
			'Apozol',
			'Jaltiche de Arriba',
			'San José del Torreón',
			'Pastor Ortíz',
			'Galerade Panales',
			'Estancia del Llano',
			'El Picacho',
			'San José de Guanajuato',
			'El Caracol',
			'El Carrizalito',
			'San Diego',
			'Jamaica',
			'El Durazno',
			'La Compañía',
			'Rincón de los Pirules',
			'Fábrica Concepción',
			'Guadalupe Buenavista',
			'San Agustín Berros',
			'San Antonio de las Huertas',
			'El Jaral',
			'Santiago Mexquititlán Barrio 4to.',
			'Ahuaxintitla',
			'Antúnez (Morelos)',
			'San Miguel Jaltocan',
			'Santa María Huiramangaro (San Juan Tumbio)',
			'Tuncingo',
			'Pueblo Mayo',
			'Bellavista de Victoria (San José Bellavista)',
			'Bonifacio Moreno (El Aguaje)',
			'Colonia el Renacimiento',
			'San Francisco (Baños de Agua Caliente)',
			'Francisco I. Madero',
			'Ricardo Flores Magón',
			'Colonia 28 de Mayo (Santa Rosa)',
			'Fraccionamiento y Club de Golf los Encinos',
			'Colonia Antorcha Campesina (Santa Rosa)',
			'Fraccionamiento la Florida',
			'La Pimienta',
			'Tanamacoyan',
			'Shoctic',
			'Rancho Chico',
			'Villa de las Flores',
			'San Antonio las Palmas',
			'Chicumtantic',
			'Eureka y Belén',
			'Tepechicotlán',
			'Colonia Isidro Fabela',
			'Purísima de Cubos (La Purísima)',
			'Paisanos',
			'Juan del Grijalva',
			'Zangarro (Zangarro Nuevo)',
			'Felipe Neri (Cuatepec)',
			'Fraccionamiento Villa Jardín',
			'El Coloso',
			'Almoloya',
			'Colonia Morelos',
			'Cuchulumtic',
			'Puebla',
			'San Antonio Xahuento',
			'Lucio Blanco (Los Gavilanes)',
			'La Purísima',
			'Patria Nueva',
			'La Trinidad Chimalpa',
			'San Lorenzo',
			'Morelia (Victórico Rodolfo Grajales)',
			'Los Caños',
			'La Soledad',
			'Los Naranjos Esquipulas',
			'General Juan José Baz (San José del Corral)',
			'Ejido la Piña (Maromilla)',
			'Nuevo Naranjo',
			'Nexticapan',
			'Santa Fe y la Mar',
			'San Antonio el Llanito',
			'Los Liros',
			'Peña del Panal',
			'Rinconadas del Venado I',
			'Tlatzala',
			'Manuel Edgardo Ávalos (San Isidro)',
			'La Trinidad',
			'Ejido la Guayana (Rancho Seco)',
			'La Mintzita (Piedra Dura)',
			'Honey',
			'Actipan',
			'Francisco Villa',
			'Nueva Sesecapa',
			'Santos Reyes Tepejillo',
			'San Dionisio Ocotepec',
			'San Ciro de Acosta',
			'El Chote',
			'El Pino',
			'Monte Kristal',
			'Aquiles Serdán (San Fernando)',
			'General Lázaro Cárdenas (El Colorado)',
			'San Bartolomé Aguas Calientes',
			'C-41 (Licenciado Carlos A. Madrazo)',
			'Villa URBI del Rey',
			'Pemucho',
			'Nueva Primavera',
			'El Naranjo',
			'El Zapotal',
			'Zacapoaxtla',
			'Yucuyachi',
			'Yobain',
			'Viva México',
			'Villa Corzo',
			'Tumbala',
			'Tozongo',
			'Tlapanaloya',
			'Tlacotepec',
			'Teopantlán',
			'Texin',
			'Tecaxic',
			'Subteniente López',
			'Sinaloa',
			'Tetla',
			'Santa Rita',
			'Santa María Yavesía',
			'Santa María Tonameca',
			'Tecomavaca',
			'Nopaltepec',
			'Coronango',
			'Santa María Huecatitla',
			'Santa Catarina Minas',
			'San Sebastián Etla',
			'San Pedro Mixtepec',
			'San Pedro Jicayán',
			'San Miguel Tecuitlapa',
			'San Miguel Chimalapa',
			'San Juan Epatlán',
			'San José Atlán',
			'San Carlos Yautepec',
			'San Antonio Sahcabchén',
			'San Andrés Cacaloapan',
			'San Agustín Loxicha',
			'Sabidos',
			'Rizo de Oro',
			'La Resurrección',
			'Quechulac',
			'Pueblo Nuevo',
			'Progreso',
			'Petlalcingo',
			'Pahuatlán',
			'Ozumba de Alzate',
			'Otumba',
			'Oacalco',
			'Mamithi',
			'Santa María Malacatepec',
			'Magdalena Cuayucatepec',
			'Héroes de Chapultepec',
			'Lázaro Cárdenas',
			'Zacualpa',
			'La Lagunilla',
			'Laguna de Farfán',
			'Juchitepec',
			'Joquicingo',
			'Jilotepec',
			'Jáltipan de Morelos',
			'Xalostoc',
			'Ixtlán de Juárez',
			'Ixtlahuaca',
			'Ixtacomitán',
			'San Francisco Ixhuatan',
			'Huitiupán',
			'Huiloapan de Cuauhtémoc',
			'Huaxcaleca',
			'Gutiérrez Zamora',
			'El Vergel',
			'El Palmar',
			'Corralero',
			'Dzununcán',
			'Dzilam González',
			'Cunduacán',
			'Santiago Cuixtla',
			'Cuautlapán',
			'San Luis Coyotzingo',
			'Santa Maria Coatepec',
			'Coacoyula de Álvarez',
			'Chaucingo',
			'Chalma',
			'Chahuite',
			'Kancabchén',
			'Cacaté',
			'Cacalotenango',
			'San Vicente Boquerón',
			'San Miguel Ameyalco',
			'Alpoyeca',
			'San Miguel Almaya',
			'Achotal de Moreno',
			'Abasolo del Valle',
			'Abasolo',
			'Tlaltetelco (San Miguel Tlaltetelco)',
			'Quinicuena',
			'Sitio de Xitlapehua',
			'San Miguel Figueroa',
			'Santiago Yosondúa',
			'Santa Cruz de Bravo',
			'San Juan Petlapa',
			'El Tortuguero',
			'Poblado C-11 José María Morelos y Pavón',
			'Libertad',
			'La Lucha',
			'Arroyo Hondo Abejonal',
			'Francisco I. Madero',
			'Teltipán de Juárez',
			'El Capulín',
			'San Lorenzo Sayula',
			'Los Reyes Quiahuixtlan',
			'San Jorge Tezoquipan',
			'El Jardín',
			'Lázaro Cárdenas',
			'Nuevo León',
			'Presidente Echeverría (Laja Tendida)',
			'Texcaltic',
			'Huitzoltepec',
			'El Mirador',
			'Santa María Nepopualco',
			'Tlacamilco',
			'Miravalles',
			'Itzoteno',
			'Francisco I. Madero',
			'San Gregorio',
			'Tequicuilco',
			'Buena Vista de la Salud',
			'Oztotitlán',
			'El Durazno',
			'Tlaxcalixtlahuaca',
			'El Pericón',
			'Buena Vista',
			'Los Divorciados',
			'Dos Ríos',
			'Aquiles Serdán',
			'Las Lomas',
			'Coahuitlán',
			'Ocotepec',
			'Monte Verde Chivería',
			'Manuel María Contreras',
			'Tlapacoyan',
			'Juan Jacobo Torres [Bodega de Totontepec]',
			'El Salto de Eyipantla',
			'Vicente Guerrero',
			'San Martín Florida',
			'Cañada de Guadarrama',
			'San Bartolo Morelos',
			'Santa María Nativitas',
			'San Gabriel Zepayautla',
			'Caja de Agua',
			'Llano de Zacapexco',
			'Florencia',
			'El Lampotal',
			'Praxédis Guerrero',
			'Gustavo Díaz Ordaz',
			'Villa Montemorelos',
			'Islas Agrarias Grupo A',
			'Zapotiltic',
			'López',
			'García',
			'Velardeña',
			'Turícuaro',
			'Tocumbo',
			'Tlahualilo de Zaragoza',
			'Tijuana',
			'Tenamaxtlán',
			'Tecomates',
			'Tarimoro',
			'Campestre Tarímbaro',
			'Tamándaro',
			'Santa Fe',
			'Santa Clara',
			'San Pedro',
			'San Martin',
			'San Juan',
			'San Juan Atezcapan',
			'El Oro',
			'Sahuayo de Morelos',
			'Rosarito',
			'El Rosario',
			'Ojitos',
			'Nuevo Ideal',
			'Navarrete',
			'El Naranjillo',
			'Nacozari de García',
			'Mompaní',
			'Milpillas',
			'Miguel Auza',
			'Las Mieleras',
			'Mapimí',
			'Macapule',
			'La Sardina',
			'Rumorosa',
			'La Paz',
			'La Negreta',
			'La Junta',
			'La Griega',
			'Lagos de Moreno',
			'La Cruz',
			'Jalpilla',
			'Indé',
			'Pozo de Ibarra',
			'Huejotitán',
			'Higueras de Abuya',
			'Jitzamuri',
			'La Plaza del Limón',
			'El Verde',
			'Cuerámaro',
			'Coeneo de la Libertad',
			'Ciénega Grande',
			'Cantabria',
			'Villa Juárez',
			'Boquilla de las Perlas',
			'Bimbaletes Aguascalientes (El Álamo)',
			'Bacorehuis',
			'Arteaga',
			'Albia',
			'Crucero Las Pilas',
			'Rincón de Cano',
			'El Espejo',
			'San Isidro de Gamboa',
			'Colonia Morelos de Guadalupe de Rivera',
			'San Antonio el Rico',
			'San Antonio Eménguaro',
			'Ejido el Rosario',
			'El Letrero',
			'Playa Azul',
			'San Pedro Tenayac',
			'Zacazonapan',
			'Loma de Juárez',
			'Montenegro',
			'El Rosario',
			'Loma Linda',
			'El Zapote Bravo',
			'Emiliano Zapata',
			'Lomas de San Agustín',
			'Los Olivos',
			'El Cóporo',
			'Tenango',
			'Molino Abajo',
			'Colonia Revolución',
			'Real Hacienda (Metrópolis)',
			'Santiago Puriatzícuaro',
			'Colonia Lázaro Cárdenas (Los Hornos)',
			'Agrícola Lázaro Cárdenas',
			'Joshil',
			'Ixtlahuaca Barrio',
			'Colonia Morelos',
			'Cala Sur',
			'El Cuervero (Cuerveros)',
			'Santa Martha',
			'San Francisco',
			'Santiago Jaltepec',
			'Colonia Lázaro Cárdenas',
			'San José del Puente',
			'Paintla',
			'Santa Catarina Roatina',
			'Villas del Campo',
			'Ocotlán de Venustiano Carranza',
			'Ixticpan',
			'Colonia 24 de Febrero',
			'Gabriel Leyva Solano (Zapotillo Dos)',
			'Estación de Apulco',
			'Belisario Domínguez',
			'San Juan Colón',
			'Jolochero 2da. Sección',
			'Colonia Renacimiento',
			'Patria Nueva (San José el Contento)',
			'Villa Hidalgo (El Cubo)',
			'Metepec Primero',
			'La Herradura',
			'El Mogote',
			'Hidalgo Amajac',
			'Maclovio Herrera (Colonia Aviación)',
			'Potrero de los Sánchez (Estación Techa)',
			'La Sábana',
			'Xolostitla de Morelos (Xolostitla)',
			'Ignacio Bastida (Santa Catarina Tlayca)',
			'Barrio de San Miguel',
			'Arroyo Palenque',
			'Colonia la Central',
			'Nuevo Xcán',
			'Cerro Quemado',
			'Jiquilpan (Estación Bonanza)',
			'Barrancas y Amate 3ra. Sección',
			'El Plan',
			'El Capulín (La Nueva Pochota)',
			'La Palma (Las Palmas)',
			'Jarillas',
			'Lodo Grande',
			'Pintillo',
			'Villa Tejúpam de la Unión',
			'Rancho Nuevo del Llanito',
			'Oriental de Zapata',
			'San Juan Totolcintla',
			'Santa Lucía Ocotlán',
			'Cruz de Huanacaxtle',
			'Ejido de Guadalupe',
			'Guadalupe Victoria',
			'Zempoala',
			'Xocchel',
			'Tuxtla',
			'Totontepec Villa de Morelos',
			'Totolapan',
			'Tocatlán',
			'Tlalnepantla',
			'Tlacotepec (San Mateo)',
			'Tezonteopan de Bonilla',
			'Texcoco de Mora',
			'Tetelco',
			'Tepepa',
			'Santa María Guadalupe Tecola',
			'Barra de Tecoanapa',
			'San Pedro Techuchulco',
			'Tasquillo',
			'Tanute',
			'Tanlajás',
			'Suma de Hidalgo',
			'Suchiapa',
			'Sontecomapan',
			'Yalina',
			'Santa María Sola',
			'Santa María Quelites',
			'Santa María Magdalena Cahuacán',
			'Guillermo Zúñiga',
			'Santa Catarina Yecahuizotl',
			'Santa Catarina Ayotzingo',
			'Santa Anita Huiloac',
			'San Pedro Juchaltengo',
			'San Pedro Cuayuca',
			'San Pedro Tlachichilco',
			'Tepetzingo',
			'San Nicolás',
			'San Miguel Coatlán',
			'San Marcos de León',
			'San Luis Acatlán',
			'Ometepec',
			'San Lorenzo Chiautzingo',
			'San Juan Ozolotepec',
			'San Francisco Huehuetlán',
			'San Francisco Acuautla',
			'San Felipe Otlaltepec',
			'Sanctórum',
			'San Agustín Zapotlán',
			'Progreso Industrial',
			'Platón Sánchez',
			'Petalcingo',
			'Colonias Pedernales',
			'Paso del Macho',
			'Palmillas',
			'Palmarito Tochapan',
			'San Vicente Palapa',
			'Oxtotipac',
			'Ocotlamanic',
			'Nueva Independencia',
			'Netzahualcóyotl',
			'Mixquiahuala de Juarez',
			'Matamoros',
			'Los Altos',
			'La Victoria',
			'Las Minas',
			'Cantón las Delicias',
			'Magdalena Atlicpac',
			'San Francisco Kobén',
			'Juchitán de Zaragoza',
			'Juan Sarabia',
			'Huitzilan',
			'Huitzilac',
			'Huehuetlán',
			'Hocaba',
			'Guadalupe Victoria',
			'Guadalupe',
			'Flor de Cacao',
			'El Limonar',
			'Dzitbalché',
			'Doctor González',
			'Cuyoaco',
			'Cuautepec de Hinojosa',
			'Nuevo Crucitas',
			'Copalillo',
			'Contla',
			'Conkal',
			'Coatetelco',
			'Coacalco',
			'Ciudad Cerralvo',
			'Casas',
			'Cárdenas',
			'Calotmul',
			'Buenavista de Benito Juárez',
			'San Melchor Betaza',
			'Alvarado',
			'Altamirano',
			'Akil',
			'Ahuatepec',
			'Actopan',
			'Acatepec',
			'Valente Diaz',
			'Bethania',
			'El Bajío',
			'San Francisco Jaltepetongo',
			'San Jorge Nuchita',
			'Temascal',
			'El Camarón',
			'La Blanca',
			'Gobernador Cruz',
			'La Palma',
			'Parilla',
			'Jalapa',
			'Barrio Aztlán',
			'Bomintzha',
			'San Lucas Tlacochcalco',
			'Felipe Carrillo Puerto',
			'San José Aztatla',
			'San Jerónimo Zacualpan',
			'San Hipólito Chimalpa',
			'José María Pino Suárez',
			'Lázaro Cárdenas',
			'Emiliano Zapata',
			'Ignacio Zaragoza (El Morro)',
			'Belisario Domínguez',
			'Altamirano',
			'Emiliano Zapata',
			'Revolución Mexicana',
			'Ignacio Ramírez',
			'Popo Park',
			'Tianguistongo',
			'Nuevo México',
			'San Miguel Ocotenco',
			'San Francisco Cuautlancingo',
			'San Carlos',
			'Tlancualpican',
			'El Mesón',
			'El Pitahayo',
			'Dzonot Carretero',
			'San José Tenejapa',
			'San Francisco Nacaxtle',
			'Rincón de Barrabás',
			'San José de Tapia',
			'Monte Salas',
			'Campo Chico',
			'Guillermo Prieto',
			'Tincontlán',
			'La Concepción',
			'Laguneta',
			'La Trinidad',
			'San Juan Cote Ejido',
			'Mavoro',
			'San Luis Boro',
			'San Pedro del Rosal',
			'San Antonio Buenavista',
			'San Sebastián',
			'Santa María de los Ángeles',
			'Hidalgo',
			'Lázaro Cárdenas',
			'La Paz',
			'Mesón de los Sauces',
			'Tziritzícuaro',
			'Villa Hidalgo',
			'Tzitzio',
			'Tomendán',
			'Teocaltiche',
			'Tamazulita',
			'Susupuato de Guerrero',
			'Sauceda de La Borda',
			'San Vicente',
			'Santa Ana Zirosto',
			'San Lucas',
			'San Juan Cosalá',
			'San Diego',
			'San Bartolo de Berrios',
			'Quitupan',
			'Puerto de Carroza',
			'Piedras Negras',
			'Pedro Escobedo',
			'Pastoría',
			'Paredón Colorado',
			'Palizada',
			'San Juan de Otates',
			'San José de Gracia',
			'Mezquitic',
			'Mexticacán',
			'Maripa',
			'La Sauceda',
			'La Palma',
			'La Goma',
			'La Goleta',
			'La Encarnación',
			'Huimilpan',
			'Huejuquilla el Alto',
			'Huaxtla',
			'Granada',
			'Santa Isabel',
			'Emiliano Zapata',
			'El Tule',
			'El Tule',
			'El Terrero',
			'El Rodeo',
			'El Oro de Hidalgo',
			'Ignacio Pérez',
			'Cuisillos',
			'Cosío',
			'Coroneo',
			'Corcovado',
			'Coquimatlán',
			'Comachuén',
			'Colorines',
			'Hidalgo',
			'Cojumatlán de Régules',
			'Charapán',
			'Bellas Fuentes',
			'Baborigame',
			'Atemajac de Brizuela',
			'Aquiles Serdán',
			'Apoderado',
			'Anáhuac',
			'Aguililla',
			'Acatlán de Juárez',
			'Acalpican de Morelos',
			'Tavera',
			'General Ignacio Zaragoza',
			'Mineral de la Luz',
			'Providencia de Nápoles',
			'Tenango el Nuevo',
			'La Norita',
			'Cerrito de Gasca',
			'La Capilla',
			'Peñuelas',
			'El Mezquitillo',
			'Jesús del Monte',
			'San Luis San Pedro',
			'Hacienda de Cabañas',
			'El Carmen',
			'El Organal',
			'Benito Juárez',
			'Teotihuacán de Arista',
			'Villa del Prado 2da Sección',
			'Unión y Progreso',
			'Maclovio Rojas',
			'Conjunto Urbano la Loma I',
			'Quinta del Cedro',
			'Loma la Paz',
			'El Rincón Citlaltépetl',
			'Colonia Venustiano Carranza',
			'Cereso del Hongo',
			'Zapotlán',
			'San Felipe los Alzati (Colonia Nueva)',
			'Paseo Puente Viejo',
			'Paraje el Mirador',
			'Hualcaltzinco',
			'Rinconada la Loma [Fraccionamiento]',
			'Carrillo Puerto',
			'Villa de Guadalupe',
			'Hermenegildo J. Aldana',
			'Colonia San Ramón',
			'Rafael Yáñez Sosa (El Mezquite)',
			'Sierra de Guadalupe',
			'San José',
			'Purísima del Progreso',
			'El Jacal',
			'Colonia Agrícola Álvaro Obregón',
			'Fraccionamiento Paseo de las Torres',
			'Yashanal',
			'Caparroso',
			'San Miguel Monteverde',
			'Caserío de Cortés',
			'San Francisco de la Palma',
			'Estación Corral',
			'Guadalupe Victoria',
			'La Candelaria',
			'San Isidro Monjas',
			'Praxedis de Guerrero',
			'Santa Clara',
			'Santiago Tepeticpac',
			'San Isidro',
			'Las Tazas',
			'Barrio el Vivero',
			'Buen Retiro (El Retiro)',
			'Saclamantón',
			'Lázaro Cárdenas (La Purísima)',
			'Ejido Jesús María',
			'Vega del Sol',
			'Paso Bonito',
			'Higuera de los Vega',
			'El Pozo',
			'Oxeloco',
			'Santa Ana Nichi Ejido',
			'Buenavista (Matasanos)',
			'Lomas de Santa Cruz',
			'Xiloxochico de Rafael Ávila Camacho',
			'Tierras Coloradas (San Pedro)',
			'Jomanichim',
			'San Miguel Aguacomulican',
			'Ocotequila',
			'Santa María Xigui',
			'San Marcos',
			'San Cristóbal Xochimilpa',
			'Colonia el Mirador (Llano del Ejido)',
			'Cuto de la Esperanza',
			'Tecoanapa',
			'Caurio de Guadalupe',
			'San Antonio Tlacamilco',
			'Colonia del Sol',
			'Zongozotla',
			'Zacatepec',
			'Santo Domingo Yanhuitlán',
			'Santiago Yancuictlalpan',
			'Xochiltepec',
			'Xalitzintla',
			'Venustiano Carranza',
			'Úrsulo Galván',
			'Tzicatlán',
			'Tuzuapan',
			'Santo Domingo Tomaltepec',
			'Tolcayuca',
			'Tlaxmalac',
			'San Pedro Tlaquilpan',
			'Tlanalapan',
			'Tlalmanalco',
			'San José Tilapa',
			'Tetiz',
			'Tepetzingo',
			'Tepetlacolco',
			'Tepeaca',
			'Temascalcingo',
			'Temamatla',
			'Tampiquito',
			'Tampacán',
			'Tamápatz',
			'San Vicente Tancuayalab',
			'Santos Reyes Pápalo',
			'Santiago de la Peña',
			'Santiago Alseseca',
			'Santa María Zoquitlán',
			'Xadani',
			'Techachalco',
			'Santa María Nativitas',
			'Santa María Mazatla',
			'Alotepec',
			'Santa Inés del Monte',
			'Santa Gertrudis',
			'Santa Cruz Mixtepec',
			'Santa Clara Huitziltepec',
			'Santa Ana Ahuehuepan',
			'San Pedro Petlacotla',
			'San Pablo de las Salinas',
			'San Miguel Coatlinchán',
			'San Miguel Achiutla',
			'San Mateo Nejápam',
			'San Luis Amatlán',
			'San Lorenzo Toxico',
			'San Juan Mazatlán',
			'San Juan Coatzospam',
			'San Ildefonso Tultepec',
			'Sanctorum',
			'San Caralampio',
			'San Bernardino',
			'San Bartolomé Loxícha',
			'San Bartolome Xicomulco',
			'San Andrés Timilpan',
			'Puerto Ángel',
			'Nicolás Bravo',
			'Ocozotepec',
			'Nopalucán',
			'Monte Blanco',
			'San Juan Metaltepec',
			'Mazapa',
			'Santa Ana Mayorazgo',
			'Los Lirios',
			'Loma Grande',
			'Libertad Ventanas',
			'Las Vigas de Ramírez',
			'Jiquipilco',
			'Jalapilla',
			'Izúcar de Matamoros',
			'Ixcapantla',
			'Ignacio de la Llave',
			'Hueyapa',
			'Huasca de Ocampo',
			'Fuentezuelas',
			'Emiliano Zapata',
			'Dos Ríos',
			'Cuacnopalan',
			'El Contadero de Matamoros',
			'Chinameca',
			'Chilapa',
			'Chapulhuacanito',
			'Chapa de Mota',
			'Chalco',
			'Cansahcab',
			'Cadereyta',
			'Barretal',
			'Atzacan',
			'Atotonilco el Grande',
			'Atotonilco',
			'San Bartolomé Atlatlahuca',
			'Juan N. Méndez',
			'Asunción Tlacolulita',
			'Ahuehuetitla',
			'Ahuacatitlán Cuarto y Quinto Cuartel',
			'Abalá',
			'Concepción Jolalpan',
			'Chiltepec de Hidalgo',
			'Tapanalá',
			'Cerro del Aire',
			'La Reforma',
			'San Vicente Nuñu',
			'Santo Domingo Tonalá',
			'Santa Cruz Acatepec',
			'Andrés Quintana Roo',
			'Zacualtipanito',
			'Santa Catarina',
			'San Pablo el Grande',
			'San Benito Xaltocan',
			'San Juan Quetzalcoapan',
			'Santa Cruz Aquiahuac',
			'San Miguel del Milagro',
			'Estación San Manuel',
			'San Jerónimo Tulijá',
			'Amado Nervo',
			'Hermenegildo Galeana',
			'Las Ollas',
			'Guaquitepec',
			'Francisco Sarabia',
			'Julián Grajales',
			'La Gloria',
			'El Salitre',
			'San Vicente Chimalhuacán',
			'Santiago Cuautlalpan',
			'Boshindó',
			'Xolotla',
			'Actipan de Morelos',
			'Francisco Ibarra Ramos',
			'Huamuchapa',
			'Ixhuacán de los Reyes',
			'Chonegal',
			'Ixtacapa el Chico',
			'Rancho Nuevo',
			'Sihuapan',
			'Huayacanes',
			'La Campanilla',
			'Santa Martha',
			'San Juan Yautepec',
			'San Francisco Magú',
			'Pueblo Nuevo de Morelos',
			'Tayahua',
			'Jaula de Abajo',
			'San Blas',
			'Escamillas',
			'Concordia',
			'Eldorado',
			'La Florida',
			'Minas de Barroterán',
			'Delta',
			'Zapotitlán de Vadillo',
			'Viborillas',
			'Venustiano Carranza',
			'Tres Mezquites',
			'San Salvador Torrecillas',
			'Tomatlán',
			'Tlalpujahua de Rayón',
			'Tequepexpan',
			'Telcruz',
			'Tayoltita',
			'Santo Tomás',
			'Santa Cruz de las Flores',
			'Santa Cruz de Bárcenas',
			//'Santa Ana',
			'San Pedro Tesistán',
			'San Pedro Itzicán',
			'San José de Gracia',
			'San Agustín del Pulque',
			'Pitahayal',
			'Piedra Pesada',
			'Pánuco',
			'Nombre de Dios',
			'Nieves',
			'Naco',
			'Méxpan',
			'Matatlán',
			'Hacienda de Márquez',
			'Los Nogales',
			'Loma de Bácum',
			'La Flor',
			'San José de la Era',
			'La Central',
			'Juan E. García',
			'Jauregui',
			'Ihuatzio',
			'Huejotitán',
			'Huajúmbaro',
			'Guadalupe de Lerma',
			'Fresnillo',
			'San Miguel Eménguaro',
			'El Ranchito',
			'El Molino',
			'El Guayabo',
			'El Chayote',
			'El Barril',
			'Durango',
			'Ecuandureo',
			'Compostela',
			'Colotlán',
			'Colima',
			'Corupo',
			'Coahuayutla de Guerrero',
			'Coahuayana Viejo',
			'Ciudad Obregón',
			'Ciudad Manuel Doblado',
			'Guerrero',
			'Chiquimitío',
			'Chapalilla',
			'Campo Balbuena',
			'Calvillo',
			'Cacalutla',
			'Aután',
			'Arizpe',
			'Arivechi',
			'Apatzingán',
			'Apaseo el Alto',
			'Acámbaro',
			'Abasolo',
			'Cotorina',
			'Puertecito de la Virgen',
			'Guadalupe',
			'El Salto de Espejo',
			'Laguna Larga',
			'Molino de Santa Ana',
			'Janamuato',
			'San Nicolás Obispo',
			'San Gregorio',
			'Ciudad Lázaro Cárdenas',
			'Calvario del Carmen',
			'El Potrero de San Diego',
			'San Gabriel Ixtla',
			'Los Mogotes',
			'Noria Nueva',
			'Plan de Ayala',
			'Villa del Campo',
			'Vicente Guerrero',
			'Profesor Carlos Hank González',
			'Lomas de Barrillas',
			'San Nicolás Tolentino',
			'Fraccionamiento Galaxia Tarímbaro',
			'Villa Magna',
			'Hacienda los Fresnos',
			'Las Colonias de Hidalgo',
			'San Pedro Benito Juárez',
			'Colonia Vista Bella (Lomas del Peaje)',
			'Fraccionamiento Cosmópolis Octavo Sector',
			'Santa Mónica',
			'San Pedro Yancuitlalpan',
			'Almoloya',
			'Laguna del Rey (Químicas del Rey)',
			'Fraccionamiento Ex-Hacienda el Refugio',
			'Carmen Zacatal',
			'Centro de Readaptación Social Nuevo',
			'Oso Viejo',
			'Bella Vista del Río',
			'Ahuacatlán',
			'San Pedro Arriba 3ra. Sección',
			'San José Neria',
			'El CERESO',
			'Los Cristales (Talican)',
			'Valle de los Girasoles [Fraccionamiento]',
			'El Sibal',
			'Barrio los Tules',
			'Majomut',
			'Paraje la Pera',
			'Buena Vista (Puxcatán)',
			'Colonia Gómez Morín',
			'Alfredo V. Bonfil (Siete Ejidos)',
			'Tepetitán Reyeshogpan de Hidalgo',
			'Álvaro Obregón (Santa Cruz)',
			'San Antonio Chiverías',
			'Zoyatitla',
			'Cálido',
			'Pechucalco 2da. Sección (Las Cruces)',
			'Ejido General Leandro Valle',
			'Tierra Blanca',
			'Los Naranjos',
			'Zapote de Palomas',
			'Nexpan',
			'Los Pinos',
			'Corral de Piedra',
			'Manzana Segunda',
			'Ixtlahuaca',
			'Santa Bárbara',
			'El Recodo',
			'San Julio',
			'Corralejo de Arriba',
			'José Colomo',
			'Nueva Ameyalco',
			'Buena Vista',
			'Villa Azueta',
			'Chimaltitán',
			'San Cristóbal',
			'Villa Santiago',
			'San José Tlacotitlán',
			'Tizimín',
			'Tierra Blanca',
			'Ticumán',
			'Tezontepec',
			'Teteltzingo',
			'Terrenate',
			'Tepotzotlán',
			'Soltepec',
			'Sitpach',
			'San Vicente',
			'Zacatepec',
			'Santa Cecilia Tepetitlán',
			'Tepexco',
			'Tepatlaxco',
			'Tenejapa',
			'Telchac Puerto',
			'Tehuacán',
			'Tapalapa',
			'Perseverancia',
			'Tamasopo',
			'Santillán',
			'Santiago Suchilquitongo',
			'Nopalera',
			'San Pablo Ixayoc',
			'Zacaola',
			'San Pedro la Joya',
			'San Lucas Camotlán',
			'San Juan',
			'San Juan Achiutla',
			'San Ildefonso Amatlán',
			'San Felipe Usila',
			'Necoxcalco',
			'San Esteban Atatlahuca',
			'San Cristóbal',
			'El Remolino',
			'Paso del Toro',
			'Pantepec',
			'Palizada',
			'Pajapan',
			'Ozelonacaxtla',
			'Opichén',
			'Ocuituco',
			'Mixquiapan',
			'Marte R. Gómez',
			'Macultepec',
			'Linares',
			'La Orduña',
			'Kaua',
			'San Juan Bautista Jayacatlán',
			'Jantetelco',
			'Xalatlaco',
			'Ixtapangajoya',
			'Ixtapan de la Sal',
			'Ixtapa',
			'Ixhuatlán de Madero',
			'Isla Mujeres',
			'Huitziltepec',
			'Hueyotlipan',
			'Huexotitla',
			'Huatecalco',
			'Guixé',
			'Frontera',
			'Entabladero',
			'Sabinalito',
			'El Juile',
			'Cuilapan de Guerrero',
			'Cualác',
			'Cruillas',
			'Copila',
			'Comalcalco',
			'Coetzala',
			'Coatecas Altas',
			'Coacoyulichán',
			'Chigmecatitlán',
			'Chiconcuautla',
			'Chavarría',
			'San Carlos',
			'Belem',
			'Bachajón',
			'Agua Dulce',
			'Aculco de Espinoza',
			'Acajete',
			'Villa Avila Camacho',
			'Higuerón',
			'Santa María Pápalo',
			'Montenegro la Lana',
			'Yucuita',
			'Boca del Monte',
			'El Chocolate',
			'Santa María Velato',
			'Santo Domingo de Morelos',
			'San Juan Cabeza del Río',
			'San Sebastián Nicananduta',
			'Texcatitlán',
			'Tetela',
			'Soledad Salinas',
			'La Erradura',
			'General Pascual Fentes',
			'Cocohital',
			'Cumuapa 1ra. Sección',
			'El Bingú',
			'El Rincón',
			'General Pedro María Anaya',
			'Colonia Cuauhtémoc',
			'Tepeyanco',
			'Zacatelco',
			'Zacatonal de Juárez',
			'El Zapotillo',
			'Sibacá',
			'Jericó',
			'Buenos Aires',
			'Acatzingo (Acatzingo de la Piedra)',
			'San José del Progreso',
			'San Pedro Chiautzingo',
			'Icxotitla',
			'Tlaola',
			'Emilio Portes Gil',
			'Tlapanalá',
			'Carrillo Puerto',
			'Benito Juárez Uno',
			'San Pedro las Playas',
			'La Estación',
			'Tecolcuautla',
			'Xocotepéc',
			'Zaragoza',
			'Congregación Hidalgo',
			'La Camelia',
			'Vicente Herrera',
			'Paso de Valencia',
			'Ahueyahualco',
			'Ocelota',
			'Emiliano Zapata (Santo Domingo)',
			'Mina Vieja',
			'Detiña (San Antonio Detiña)',
			'Chosto de los Jarros',
			'Barrio de San Pedro la Cabecera',
			'Calle Real',
			'San José',
			'San Salvador Cuauhtenco',
			'Santa Cruz',
			'San Bartolo Tlaxihuicalco',
			'Río Florido',
			'Leyva Solano',
			'Benito Juárez (Vinatería)',
			'Mayran',
			'Camalú',
			'Doctor Alberto Oviedo Mota',
			'Zinapécuaro',
			'Venustiano Carranza',
			'Valle de Bravo',
			'Valle de Banderas',
			'Usmajac',
			'Uruapan',
			'Uricho',
			'Tiquicheo',
			'Tepetixtla',
			'Tecario',
			'Tapeixtles',
			'Suaqui Grande',
			'Sayula',
			'Santo Domingo',
			'Santiago',
			'Santa Cruz del Astillero',
			'San Patricio',
			'San Miguel',
			'San Luis Río Colorado',
			'San Lucas Evangelista',
			'San Lucas',
			'San José de Raíces',
			'Doctor Miguel Silva (San Guillermo)',
			'San Cristóbal Zapotitlán',
			'San Cristóbal',
			'San Bartolo',
			'San Antonio',
			'Salinas de Hidalgo',
			'Rincón de Guayabitos',
			'Rinconada',
			'Rancho Viejo',
			'Quimichis',
			'Puruándiro',
			'Pueblo Yaqui',
			'Peñuelas',
			'Ocampo',
			'Mocorito',
			'Mixtlán',
			'Matehuala',
			'Manzanillo',
			'Luis Moya',
			'Lobatos',
			'Las Cruces',
			'La Pila',
			'La Palma',
			'La Luz',
			'La Cruz de Loreto',
			'Jocotepec',
			'Ixtlahuacán',
			'San Juanito Itzícuaro',
			'Illescas',
			'Huépac',
			'Huásabas',
			'Guamúchil',
			'Gómez Palacio',
			'Galeras',
			'Colonia Francisco García Salinas',
			'Etúcuaro',
			'Esperanza',
			'El Tobarito',
			'El Nacimiento',
			'El Llano',
			'El Fuerte',
			'El Fuerte',
			'El Blanco',
			'El Arenal',
			'El Aguajito',
			'Creel',
			'Coyuca de Benítez',
			'Cieneguillas',
			'Cieneguilla',
			'Chilchota',
			'Cerro de Ortega',
			'El Cazadero',
			'Castaños',
			'Capulín',
			'Bahía de Kino',
			'Ayotlán',
			'Atenguillo',
			'Armadillo de los Infante',
			'Aldama',
			'Centro de Arriba',
			'Gasca',
			'Mexicanos',
			'San Ignacio de Rivera (Ojo de Agua)',
			'El Jaguey',
			'Colonia Nuevo México',
			'Santa María de los Ángeles',
			'Irimbo',
			'Tafetán',
			'Lázaro Cárdenas',
			'Gambara',
			'La Mesa',
			'Los Berros',
			'Tetitlán',
			'Las Tunas',
			'Llano del Tigre',
			'La D',
			'San Luis',
			'Rancho Nuevo',
			'Buayums',
			'San Isidro',
			'La Providencia Siglo XXI',
			'Morelos',
			'Colonia Santa Bárbara',
			'Fraccionamiento Colinas del Sol',
			'Santiago Chimalpa (Chimalpa)',
			'Zacango',
			'Atzingo (La Cumbre)',
			'Oasis',
			'Corral de Barrancos',
			'Santo Nombre',
			'Fraccionamiento Real de San Pablo',
			'Necoxtla',
			'Colonia la Asunción',
			'El Boxtha',
			'San José Tejamanil',
			'San Nicolás Zecalacoayan',
			'José María Pino Suárez (Nuevo Nahuapa)',
			'Lomas de la Maestranza',
			'San Mateo Capultitlán',
			'Panales Jamaica (Cañones)',
			'Unión Ejidal Tierra y Libertad',
			'Texcaltitán Xoteapan (Texcaltitán)',
			'Colonia Felipe Ángeles',
			'Taxco',
			'Tlamanca de Hernández',
			'Ceiba 1ra. Sección (Jahuactal)',
			'Tenochtitlán',
			'Cinco de Mayo',
			'El Mirador',
			'Monte Calvario',
			'La Fundición (Quinta Manzana)',
			'Buenos Aires',
			'Gobernadores',
			'La Y',
			'Narciso Mendoza',
			'Casa Nueva',
			'El Sauz de Abajo',
			'Mexcala',
			'El Tepetatal',
			'San José Villarreal',
			'Tronconal',
			'La Playa',
			'El Polvorín',
			'Paso de la Mina 2da. Sección (Barrial)',
			'Atzalán',
			'Pezmatlán',
			'Coxolico',
			'Ocoxaltepec',
			'San Isidro las Banderas',
			'San Andrés (San Andrés Chichayotla)',
			'Ejido San José Xilatzén',
			'Loma de Guadalupe (La Biznaga)',
			'Carmen Serdán',
			'Santa Cruz Texmalaquilla',
			'Paso de Pirules',
			'Colonia Guadalupe',
			'Cuesta Amarilla',
			'Colonia el Mirador',
			'Matacapan',
			'Guadalupe Victoria',
			//'Santa Ana',
			'El Bellote (Miguel de la Madrid)',
			'Concepción Caro',
			'Techichili',
			'Bochojbo Alto',
			'José María Pino Suárez',
			'La Soledad',
			'Santa Isabel [Fraccionamiento]',
			'Miguel Hidalgo',
			'La Concordia',
			'Nuevo Carmen Tonapac',
			'Aquiles Córdova Morán',
			'El Abra',
			'Zumpahuacán',
			'Zacoalpan',
			'Xoxocotla',
			'Camarón de Tejeda',
			'Villa Cuauhtémoc',
			'Tlaxco (Santiago Tlaxco)',
			'Santa Martha',
			'Tamcuime',
			'Zempoala',
			'Yutanduchi de Guerrero',
			'Xuchapa',
			'X-pichil',
			'Xocen',
			'Vizarrón',
			'Tuxtilla',
			'Ciudad Tula',
			'Tres Picos',
			'Santiago Tlautla',
			'San Ildefonso',
			'San Bartolo Oxtotitlán',
			'Sahcaba',
			'Sabanas de Xalostoc',
			'Rodulfo Figueroa',
			'Plan de Ayala',
			'Olinalá',
			'Monte Blanco',
			'Miacatlán',
			'Mecatepec',
			'Mazumiapam',
			'Mactún',
			'Los Herreras',
			'Los Guerra',
			'Las Mesas',
			'Congregación Reforma',
			'La Libertad',
			'La Gloria',
			'Kochol',
			'Jonacapa',
			'Ignacio Zaragoza',
			'Huitzila',
			'Huepalcalco (San Miguel)',
			'Francisco I. Madero',
			'Faja de Oro',
			'Escolín de Olarte',
			'El Llano',
			'El Humo',
			'Ejutla de Crespo',
			'Ejido Viejo',
			'Dzan',
			'San José Cuamantzingo',
			'Cruz Grande',
			'Cruz Blanca',
			'San Juan Coajomulco',
			'Chontalpa',
			'Chilón',
			'Chicuasen',
			'Chichihualco',
			'Cerro Gordo',
			'Calihualá',
			'Beristain',
			'Benito Juárez',
			'Ayahualulco',
			'Amilcingo',
			'Santa Maria Amealco',
			'Amatlán de los Reyes',
			'Almoloya de Alquisiras',
			'Aljojuca',
			'Akumal',
			'Ahuacatlán',
			'Acula',
			'San Lorenzo Achiotepec',
			'Acatlán de Osorio',
			'Acamixtla',
			'Acajete',
			'Santa María Puxmetacán',
			'El Arador',
			'San Juan Lachao',
			'San Juan Lagunas',
			'Santiago Tenango',
			'San Lorenzo',
			'Mariscala de Juárez',
			'Teococuilco de Marcos Pérez',
			'Guadalupe Victoria',
			'Cúlico 2da. Sección',
			'Dengantzha',
			'Cardonal',
			'Jesús Huitznahuac',
			'Egipto',
			'Joljá',
			'La Competencia',
			'San Francisco Pujiltic',
			'Ricardo Flores Magón',
			'Julián Grajales',
			'Tamaulipas',
			'La Candelaria Tlapala',
			'San Francisco Mazapa',
			'Puentecillas',
			'Ejido Loma de Malacota',
			'Quamila',
			'El Progreso',
			'Teacalco de Dorantes (San José Teacalco)',
			'Texcalapa de Juárez',
			'Castamay',
			'Carrizalillo',
			'Axaxacualco',
			'Zotoltitlán',
			'Xalatzala',
			'Chiquilá',
			'San Angel',
			'El Triunfo',
			'Santa Rita',
			'San Isidro el Berro',
			'Manlio Fabio Altamirano',
			'Miguel Hidalgo',
			'Tula',
			'San Francisco de Guzmán',
			'Santa Cruz Mextepec',
			'Doxtejé Centro',
			'La Caridad',
			'Santa María Tlalmimilolpan',
			'Pérez de Galeana',
			'San Juan Pueblo Nuevo',
			'Huilango',
			'Ojo de Agua',
			'Independencia',
			'Ranchito de Castro',
			'Primero de Mayo',
			'Zacualpan',
			'Yago',
			'Villa Unión',
			'Unión de San Antonio',
			'Tuxcueca',
			'Tlatlaya',
			'Tlacuitapan',
			'Tirípetio',
			'Tierra Nueva',
			'Tecomán',
			'Tecate',
			'Taretán',
			'Tabasco',
			'Zicuicho',
			'San Vicente Ferrer',
			'Santiago Oxtempan',
			'Santa María del Oro',
			'Santa María del Buáraje',
			'Santa Cruz',
			'Santa Clara',
			'San Sebastián',
			'San Miguel Zapotitlán',
			'San Marcos',
			'San Juan de la Vega',
			'San Juan de la Vaquería',
			'San Isidro',
			'San Antonio Guaracha',
			'San Antonio de las Alazanas',
			'San Agustín del Maíz',
			'Rosales',
			'Puácuaro',
			'Portezuelo',
			'Poncitlán',
			'Pastor Rovaix',
			'Pastelera',
			'Paredes',
			'Palau',
			'Orduña de Abajo',
			'Ocotlán',
			'Norias del Refugio',
			'Neutla',
			'Nazareno',
			'Mulegé',
			'Moyahua de Estrada',
			'San Francisco del Mezquital',
			'Maravillas',
			'Los Pocitos',
			'Placeres del Oro',
			'Los Mochis',
			'Los Gavilanes',
			// 'Los Angeles',
			'Las Varas',
			'Higueras de los Natoches',
			'Villa de la Paz',
			'José María Morelos',
			'Huancito',
			'Guanajuato',
			'Guanaceví',
			'La Estanzuela',
			'Ejido El Vergel',
			'El Rosario',
			'El Nilo',
			'El Nacimiento',
			'El Fuerte',
			'El Colomo',
			'El Castillo',
			'El Carmen',
			'El Burrión',
			'Ejido Michoacán de Ocampo',
			'Ejido Lázaro Cárdenas',
			'Doctor Arroyo',
			'Divisaderos',
			'Cuauhtémoc',
			'Ciudad Lerdo',
			'José María Morelos',
			'Chalchihuites',
			'Buenavista Tomatlán',
			'Bellavista',
			'Batopilas',
			'Baburia',
			'Araró',
			'Ajuchitlán',
			'Aconchi',
			'Maravillas',
			'Guadalupe de Atlas',
			'El Tejocote (El Domingo)',
			'San Ignacio de San José Parangueo',
			'Caleras de Ameche',
			'La Caja',
			'Monte del Coecillo',
			'Galeana',
			'Isaac Arriaga (Santa Ana Mancera)',
			'El Pilar',
			'Huajúmbaro',
			'Uripitio',
			'Pueblo Viejo',
			'Rancho Viejo',
			'Rincón de Jaimes',
			'San Juan',
			'Concepción la Venta',
			'San Pedro del Rincón',
			'Morelita',
			'Cofradía de Morelos',
			'Cerro de la Cruz',
			'Jesús María',
			'San Ildefonso',
			'Zapopan2',
			'Estación Tamuín',
			'La Guásima',
			'Hacienda Santa Fe',
			'San Martín Azcatepec',
			'Las Pintitas',
			'Paseos de San Juan',
			'El Súchil',
			'Raudales Malpaso',
			'Fraccionamiento los Ángeles',
			'Colinas del Roble',
			'San Esteban (San Miguel Tateposco)',
			'Forjadores de Pachuca',
			'San Bartolo',
			'Genaro Estrada',
			'Francisco Sarabia',
			'Amatillo',
			'Nachig',
			'El Saucito (El Horno)',
			'Los Cafetales',
			'Buena Vista 1ra. Sección',
			'La Mesa',
			'Puerto de Buenavista (Lázaro Cárdenas)',
			'Cartagena [Fraccionamiento]',
			'Ejido de Mozoquilpan',
			'Corral del Risco (Punta de Mita)',
			'Arboledas',
			'La Gloria',
			'Coachiti',
			'Zapotitlán Tablas',
			'La Cuchilla (Extramuros de Tesistán)',
			'Zona Urbana Ejidal',
			'Hueyitlalpan',
			'Colonia Aeropuerto',
			'Ejido Pátzcuaro',
			'Colonia Palma',
			'Temastián (La Cantera)',
			'La Higuerita (Colonia San Rafael)',
			'Álvaro Obregón',
			'Colonia Juárez',
			'Lomas de San Martín',
			'Barrio la Tenería',
			'Iztacapa',
			'Colonia Lázaro Cárdenas',
			'Barrio la Joya',
			'Pablo L. Sidar',
			'Cerro Grande',
			'Tomaquilapa',
			'Emiliano Zapata',
			'Colonia Miguel Hidalgo',
			'Chipahuatlán',
			'La Planada (El Arenal)',
			'La Higuerilla (Los Lirios)',
			'Banco Nacional',
			'Rincón de Aguirre',
			'San Juan Zapotitlán',
			'Ejido Netzahualcóyotl',
			'La Mezquitera',
			'Pugchén Mumuntic',
			'Buenos Aires (San Isidro)',
			'Manzana la Cofradía',
			'Tonalapa',
			'Tlacpac',
			'Benito Juárez',
			'Estación Huehuetán',
			'San Juan Sautla',
			'San Francisco Cuapa',
			'San José las Palmas',
			'Tlalnelhuayocan',
			'Timucuy',
			'Santiago Tillo',
			'Tikuch',
			'Teya',
			'Teocelo',
			'Tecamalucan',
			'Tamazunchale',
			'Sucopó',
			'Soconusco',
			'Xochiapa',
			'Sochiapa',
			'Santiago Apostol',
			'Santiago Michac',
			'Santiago Laxopa',
			'Santa María la Alta',
			'Santa María la Asunción',
			'Santa Clara',
			'Santa Ana Coapan',
			'Santa Adelaida',
			'San Sebastián de Las Barrancas Sur',
			'Cajonos',
			'San Pablo Macuiltianguis',
			'San Pablo Huitzo',
			'San Miguel Suchixtepec',
			'San Lucas el Viejo',
			'Ixtenco',
			'Coixtlahuaca',
			'San Juan Amecac',
			'San José Tzal',
			'San Jerónimo Ocotitla',
			'San Jacinto',
			'San Felipe Coamango',
			'Colonia el Salado',
		],
	},
	'Moldova, Republic of': {
		country: 'Moldova, Republic of',
		cities: [
			'Pogăneşti',
			'Maiac',
			'Ghindești',
			'Sîngerei',
			'Drochia',
			'Comrat',
			'Edineţ',
			'Ciorescu',
			'Slobozia',
			'Soroca',
			'Rîşcani',
			'Stăuceni',
			'Merenii Noi',
			'Iargara',
			'Teleneşti',
			'Şoldăneşti',
			'Rezina',
			'Dolna',
			'Cantemir',
			'Bălţi',
			'Hînceşti',
			'Sîngera',
			'Cocieri',
			'Glodeni',
			'Dubăsari',
			'Basarabeasca',
			'Biruinţa',
			'Nisporeni',
			'Strășeni',
			'Orhei',
			'Mîndreşti',
			'Cricova',
			'Căuşeni',
			'Fălești',
			'Cahul',
			'Bilicenii Vechi',
			'Vadul lui Vodă',
			'Pervomaisc',
			'Hryhoriopol',
			'Chiţcani',
			'Taraclia',
			'Codru',
			'Bender',
			'Rîbniţa',
			'Leova',
			'Bucovăţ',
			'Tiraspolul Nou',
			'Anenii Noi',
			'Ceadîr-Lunga',
			'Vulcăneşti',
			'Varniţa',
			'Ungheni',
			'Cimişlia',
			'Briceni',
			'Ştefan Vodă',
			'Mărculeşti',
			'Crasnoe',
			'Floreşti',
			'Chisinau',
			'Camenca',
			'Vatra',
			'Ustia',
			'Criuleni',
			'Congaz',
			'Tiraspol',
			'Giurgiuleşti',
			'Vişniovca',
			'Ialoveni',
			'Dnestrovsc',
			'Tvardița',
			'Saharna',
			'Călăraşi',
			'Otaci',
			'Ocniţa',
			'Donduşeni',
			'Dancu',
			'Bugeac',
			'Briceni',
		],
	},
	Monaco: { country: 'Monaco', cities: ['La Condamine', 'Fontvieille', 'Monte-Carlo', 'Monaco', 'Moneghetti', 'Saint-Roman'] },
	Mongolia: {
		country: 'Mongolia',
		cities: [
			'Tsenher',
			'Tseel',
			'Har-Us',
			'Ulaanhad',
			'Saynshand',
			'Öndörhoshuu',
			'Ihbulag',
			'Dzüünharaa',
			'Bodĭ',
			'Jargalant',
			'Kharkhorin',
			'Tosontsengel',
			'Hödrögö',
			'Dzöölön',
			'Altanteel',
			'Aldar',
			'Tsetserleg',
			'Tsengel',
			'Jargalant',
			'Dzüünbulag',
			'Dundbürd',
			'Bulgan',
			'Bayasgalant',
			'Erdenet',
			'Bayanbulag',
			'Ulan Bator',
			'Teel',
			'Naranbulag',
			'Mardzad',
			'Jargalant',
			'Hovd',
			'Havirga',
			'Dashbalbar',
			'Chonogol',
			'Bugant',
			'Bat-Öldziyt',
			'Tsalgar',
			'Höhtolgoy',
			'Duut',
			'Ulaan-Uul',
			'Darhan',
			'Javhlant',
			'Tögrög',
			'Halban',
			'Sört',
			'Nomgon',
			'Mörön',
			'Tsagaan-Owoo',
			'Enhtal',
			'Dzaanhoshuu',
			'Bulagiyn Denj',
			'Bayanhoshuu',
			'Hushaat',
			'Zuunmod',
			'Tögrög',
			'Tegsh',
			'Talshand',
			'Shiree',
			'Ölgii',
			'Darvi',
			'Chihertey',
			'Hulstay',
			'Erdenetsogt',
			'Dzuunmod',
			'Shiveegovĭ',
			'Tahilt',
			'Tsagaanhayrhan',
			'Dzür',
			'Örgön',
			'Ongi',
			'Mönhbulag',
			'Jargalant',
			'Darhan',
			'Bayasgalant',
			'Ulaangom',
			'Tsagaantüngi',
			'Naranbulag',
			'Harmod',
			'Buyant',
			'Bor-Üdzüür',
			'Suugaant',
			'Orgil',
			'Hutag',
			'Erdenet',
			'Bayan',
			'Uujim',
			'Sharga',
			'Sharbulag',
			'Haliun',
			'Buyant',
			'Buyant',
			'Bayan-Ovoo',
			'Altan',
			'Üydzen',
			'Sergelen',
			'Undurkhaan',
			'Nart',
			'Herlen',
			'Choyr',
			'Bayanbulag',
			'Bayanbaraat',
			'Bayan',
			'Bayan',
			'Altanbulag',
			'Uliastay',
			'Havtsal',
			'Dzadgay',
			'Altraga',
			'Altai',
			'Altanbulag',
			'Tsagaan-Ovoo',
			'Tsagaanders',
			'Khujirt',
			'Hongor',
			'Hajuu-Us',
			'Eg-Uur',
			'Buyant',
			'Bulagtay',
			'Taygan',
			'Nogoonnuur',
			'Sangiyn Dalay',
			'Nüden',
			'Javarthushuu',
			'Hövsgöl',
			'Delgerhaan',
			'Bulag',
			'Bayantsagaan',
			'Bayanhongor',
			'Avdzaga',
			'Ar-Asgat',
			'Har-Us',
			'Buga',
			'Rashaant',
			'Dund-Urt',
			'Altan-Ovoo',
			'Bayantal',
			'Üyönch',
			'Tooromt',
			'Orgil',
			'Jargalant',
			'Ider',
			'Dzüünhövöö',
			'Bayan-Uhaa',
			'Uubulan',
			'Tavanbulag',
			'Shiree',
			'Sharga',
			'Mörön',
			'Mandal',
			'Undur Sume',
			'Saynshand',
			'Ulaantolgoy',
			'Jargalant',
			'Dzüyl',
			'Tsagaannuur',
			'Ulaanhudag',
			'Övt',
			'Mandal',
			'Maanĭt',
			'Maanĭt',
			'Hotont',
			'Hanhöhiy',
			'Erdenetsogt',
			'Bayan-Ovoo',
			'Bayan',
			'Baruun-Urt',
			'Arhust',
			'Jargalant',
			'Dzalaa',
			'Buga',
			'Teshig',
			'Öldziyt',
			'Lün',
			'Bayan Uula Sumu',
			'Ergel',
			'Dzüünbulag',
			'Choibalsan',
			'Tolbo',
			'Sangiyn Dalay',
			'Höshööt',
			'Harhiraa',
			'Baruunturuun',
			'Balgatay',
			'Uldz',
			'Ulaan-Uul',
			'Sühbaatar',
			'Sangiyn Dalay',
			'Mayhan',
			'Dzegstey',
			'Bayan-Ulaan',
			'Höshigiyn-Ar',
			'Tsetserleg',
			'Tsetsegnuur',
			'Sayn-Ust',
			'Tsagaan-Ovoo',
			'Ögöömör',
			'Hujirt',
			'Bayantöhöm',
			'Altanbulag',
			'Buyant',
			'Bürenhayrhan',
			'Bugat',
			'Bayanhoshuu',
			'Ulaanjirem',
			'Tsomog',
			'Sümiyn Bulag',
			'Sergelen',
			'Senj',
			'Dzogsool',
			'Dalay',
			'Bornuur',
			'Baruunsuu',
			'Övögdiy',
			'Namir',
			'Buyanbat',
			'Darvi',
			'Bayshint',
			'Horgo',
			'Tavin',
			'Ovoot',
			'Ongi',
			'Dzüünkharaa',
			'Javhlant',
			'Hüremt',
			'Höshööt',
			'Horiult',
			'Bugat',
			'Urdgol',
			'Tsahir',
			'Dzuunmod',
			'Tsagaandörvölj',
			'Shireet',
			'Selenge',
			'Jargalant',
			'Huurch',
			'Höhbürd',
			'Hashaat',
			'Darhan',
			'Sümber',
			'Oygon',
			'Hungiy',
			'Dzag',
			'Bayansayr',
			'Hüremt',
			'Haylaastay',
			'Ereencav',
			'Burgaltay',
			'Delgermörön',
			'Bayan',
			'Dashinchilling',
			'Ihsüüj',
			'Hovd',
			'Eg',
			'Dalandzadgad',
			'Tögöl',
			'Sharga',
			'Altay',
			'Ulaandel',
			'Onon',
			'Öldziyt',
			'Hatavch',
			'Bulgan',
			'Bayandelger',
			'Argatay',
			'Argalant',
			'Tsagaanchuluut',
			'Jargalant',
			'Bilüü',
			'Tsul-Ulaan',
			'Sangiyn Dalay',
			'Mandalgovi',
			'Bumbat',
			'Bayan',
			'Nuga',
			'Höviyn Am',
			'Turt',
			'Ongon',
			'Jargalant',
			'Hujirt',
			'Noyon Suma',
			'Hatansuudal',
			'Asgat',
			'Hongor',
			'Tsagaan-Olom',
			'Hunt',
			'Holboo',
			'Dzel',
			'Bayangol',
			'Urt',
			'Ulaan-Ereg',
			'Sharïngol',
			'Rashaant',
			'Manhan',
			'Dzelter',
			'Bulag',
			'Bayshint',
			'Arvayheer',
			'Rashaant',
			'Bulgan',
			'Tsoohor',
			'Töhöm',
			'Sharhulsan',
			'Orhontuul',
			'Ingettolgoy',
			'Hoolt',
			'Hongor',
			'Orhon',
			'Bulgan',
			'Böhöt',
			'Badrah',
			'Mandal',
			'Khovd',
			'Chandmanĭ',
			'Bayan',
			'Tsant',
			'Modot',
			'Haraat',
			'Erhet',
			'Doloon',
			'Chandmanĭ',
			'Avraga',
			'Arguut',
			'Amardalay',
			'Ulaanshiveet',
		],
	},
	Montenegro: {
		country: 'Montenegro',
		cities: [
			'Petnjica',
			'Gusinje',
			'Mataguži',
			'Bijelo Polje',
			'Berane',
			'Golubovci',
			'Danilovgrad',
			'Sutomore',
			'Prčanj',
			'Plav',
			'Goričani',
			'Žabljak',
			'Pljevlja',
			'Budva',
			'Igalo',
			'Bijela',
			'Plužine',
			'Mojanovići',
			'Kolašin',
			'Spuž',
			'Risan',
			'Podgorica',
			'Lipci',
			'Petrovac na Moru',
			'Cetinje',
			'Ulcinj',
			'Tuzi',
			'Mojkovac',
			'Dobrota',
			'Tivat',
			'Bar',
			'Šušanj',
			'Šavnik',
			'Nikšić',
			'Rožaje',
			'Kotor',
			'Herceg Novi',
			'Andrijevica',
			'Stari Bar',
		],
	},
	Montserrat: { country: 'Montserrat', cities: ['Saint Peters', 'Plymouth', 'Brades'] },
	Morocco: {
		country: 'Morocco',
		cities: [
			'Telouet',
			'Missour',
			'Itzer',
			'Iguidi',
			'El Jadid',
			'Argana',
			'Almis Marmoucha',
			'Tamorot',
			'Senada',
			'Goulmima',
			'Errachidia',
			'Sidi Slimane',
			'Midelt',
			'Kerrouchen',
			'Inezgane',
			'Imilchil',
			'Guisser',
			'Bzou',
			'Al Hoceïma',
			'Agadir',
			'Tidili Mesfioua',
			'Touama',
			'Tazouta',
			'Targuist',
			'Sidi Redouane',
			'Sidi Amar',
			'Ghouazi',
			'Oulmes',
			'Oulad Tayeb',
			'Oued Zem',
			'Ifrane',
			'Jorf',
			'Zerkten',
			'Assoul',
			'Assads',
			'Sidi Abdallah Ghiat',
			'Tabia',
			'Moulay Brahim',
			'Demnate',
			'Bouznika',
			'Bouabout',
			'Beni Mellal',
			'Mhamid',
			'Tirhanimîne',
			'Tiouli',
			'Taznakht',
			'Taliouine',
			'Skoura',
			'Ratba',
			'Ouezzane',
			'Mechraa Bel Ksiri',
			'Marrakesh',
			'Amalou',
			'Mosquée',
			'Saddina',
			'Setti Fatma',
			'Tiztoutine',
			'Timezgadiouine',
			'Temara',
			'Sidi Yahya Ou Saad',
			'Sidi Rahhal',
			'Sidi Moussa',
			'Fès al Bali',
			'Derdara',
			'Boulaouane',
			'Aïn Beni Mathar',
			'Imlili',
			'Zaïo',
			'Zag',
			'Tazarine',
			'Tamanar',
			'Matmata',
			'Jerada',
			'Guenfouda',
			'Boukhalef',
			'Gueltat Zemmour',
			'Zoumi',
			'Timoulilt',
			'Tiflet',
			'Rouadi',
			'Rabat',
			'Moulay Yacoub',
			'Dar Ould Zidouh',
			'Tiout',
			'Sidi Bennour',
			'Ouarzazat',
			'Karia Ba Mohamed',
			'Ezzhiliga',
			'Dar Bouazza',
			'Dar Chaoui',
			'Bouarfa',
			'Berrechid',
			'Aourir',
			'Amouguer',
			'Adassil',
			'Ait Khallouf',
			'Midar',
			'Galaz',
			'Berkane',
			'Aknoul',
			'Agadir Melloul',
			'Zawit Al Bour',
			'Zawyat ech Cheïkh',
			'Tizguine',
			'Tinghir',
			'Taroudant',
			'Tafraout',
			'Souq Larb’a al Gharb',
			'Reggada',
			'Zawyat an Nwaçer',
			'Chefchaouen',
			'Tarsouat',
			'Taouima',
			'Tadrart',
			'Sidi Ifni',
			'Sidi Harazem',
			'Sidi Bou Othmane',
			'Saidia',
			'Reçani',
			'Ourtzagh',
			'Madagh',
			'Larache',
			'Kenitra',
			'Isseksi',
			'Boulemane',
			'Boudinar',
			'Taghazout',
			'Tilmi',
			'Tifni',
			'Taounate',
			'Sidi Yahia El Gharb',
			'Sidi Abdallah',
			'Sale',
			'Khenifra',
			'Kariat Arkmane',
			'Guelmim',
			'Bouskoura',
			'Azemmour',
			'Arbaoua',
			'Aoulouz',
			'Bouarouss',
			'Jebel Tiskaouine',
			'Smara',
			'Zinat',
			'Ksar El Kebir',
			'Ifrane',
			'Fifi',
			'Kelaat Mgouna',
			'Bhalil',
			'Aguelmous',
			'Tmourghout',
			'Timezgana',
			'Oulad Teïma',
			'Oued Amlil',
			'Mohammedia',
			'Essaouira',
			'Bigoudine',
			'Azrou',
			'Anergui',
			'Ahfir',
			'Teroual',
			'Tarfaya',
			'Taourirt',
			'Taouloukoult',
			'Oujda-Angad',
			'Al Aaroui',
			'Mediouna',
			'Guercif',
			'Al Fqih Ben Çalah',
			'Riah',
			'Asilah',
			'Amizmiz',
			'Amerzgane',
			'Ajdir',
			'Tizgane',
			'Akhfennir',
			'Martil',
			'Tata',
			'Tan-Tan',
			'Oukaïmedene',
			'Fès',
			'El Ksiba',
			'Chichaoua',
			'Bab Taza',
			'Alnif',
			'Aïn Leuh',
			'Tamri',
			'Salé Al Jadida',
			'Cap Negro II',
			'Selouane',
			'Sefrou',
			'Kasba Tadla',
			'Had Kourt',
			'Casablanca',
			'Bni Bouayach',
			'Tazemmourt',
			'Tanalt',
			'Sidi Smai’il',
			'Oualidia',
			'Oulad Frej',
			'Fezna',
			'Brikcha',
			'Khemisset',
			'Abadou',
			'Smimou',
			'Boujniba',
			'Tit Mellil',
			'Tissa',
			'Taounza',
			'Oued Laou',
			'Moulay Abdallah',
			'Khouribga',
			'Fnidek',
			'Azgour',
			'Adar',
			'Youssoufia',
			'Tabouda',
			'Tabia',
			'Tabant',
			'Sidi Jaber',
			'Settat',
			'Ouijjane',
			'Mdiq',
			'Lalla Mimouna',
			'Imider',
			'Figuig (Centre)',
			'Arfoud',
			'Azilal',
			'Dakhla',
			'Aousserd',
			'Tangier',
			'Talmest',
			'Sidi Lamine',
			'Moulay Bouchta',
			'Amersid',
			'Skhirate',
			'Zagora',
			'Tahla',
			'Rislane',
			'Nador',
			'Meknès',
			'El Aïoun',
			'Talzemt',
			'Oulad Daoud',
			'El Hajeb',
			'Boumia',
			'Aoufous',
			'Tétouan',
			'Sidi Qacem',
			'Imzouren',
			'Debdou',
			'Arazane',
			'Tiznit',
			'Taza',
			'Sidi Bousber',
			'Safi',
			'Oulad Ayad',
			'Hassi Berkane',
			'Ouaoula',
			'Agdz',
			'Mellila',
			'Azla',
		],
	},
	Mozambique: {
		country: 'Mozambique',
		cities: [
			'Nacala',
			'Manhiça',
			'Quelimane',
			'Bairro 25 de Junho A',
			'Mutuáli',
			'Tete',
			'Mozambique',
			'Dondo',
			'Chokwé',
			'Chibuto',
			'Maputo',
			'Macia',
			'Maxixe',
			'Chimoio',
			'Montepuez',
			'Mocímboa',
			'Bairro 25 de Junho B',
			'Nampula',
			'Chinde',
			'Beira',
			'Ressano Garcia',
			'Inhambane',
			'Cuamba',
			'Lichinga',
			'Xai-Xai',
			'Pemba',
			'Matola',
			'Mandimba',
			'António Enes',
		],
	},
	Myanmar: {
		country: 'Myanmar',
		cities: [
			'Lashio',
			'Hakha',
			'Mawlamyinegyunn',
			'Kyaukse',
			'Pakokku',
			'Thongwa',
			'Klonhtoug',
			'Yenangyaung',
			'Mikenaungea',
			'Tagondaing',
			'Pyapon',
			'Bogale',
			'Tamoowoug',
			'Magway',
			'Yamethin',
			'Maubin',
			'Wakema',
			'Taungoo',
			'Tharyarwady',
			'Nyaunglebin',
			'Myingyan',
			'Pyin Oo Lwin',
			'Martaban',
			'Kyaiklat',
			'Twante',
			'Myanaung',
			'Myeik',
			'Myitkyina',
			'Kanbe',
			'Nyaungdon',
			'Thanatpin',
			'Myaydo',
			'Thaton',
			'Taungdwingyi',
			'Mawlaik',
			'Yangon',
			'Loikaw',
			'Kyaikto',
			'Kyaikkami',
			'Falam',
			'Myawadi',
			'Bhamo',
			'Tachilek',
			'Pyinmana',
			'Mudon',
			'Taunggyi',
			'Hpa-An',
			'Hinthada',
			'Dawei',
			'Paungde',
			'Mandalay',
			'Minbu',
			'Kawthoung',
			'Thayetmyo',
			'Letpandan',
			'Pathein',
			'Syriam',
			'Mawlamyine',
			'Kēng Tung',
			'Chauk',
			'Nay Pyi Taw',
			'Kyain Seikgyi Township',
			'Dellok',
			'Nyaungshwe',
			'Pulei',
			'Sagaing',
			'Sittwe',
			'Shwebo',
			'Pyu',
			'Pyay',
			'Bago',
			'Monywa',
			'Mogok',
			'Meiktila',
			'Kayan',
		],
	},
	Namibia: {
		country: 'Namibia',
		cities: [
			'Katima Mulilo',
			'Bethanie',
			'Okakarara',
			'Outapi',
			'Tses',
			'Oranjemund',
			'Eenhana',
			'Grootfontein',
			'Omuthiya',
			'Windhoek',
			'Usakos',
			'Otjimbingwe',
			'Henties Bay',
			'Okahao',
			'Lüderitz',
			'Tsumeb',
			'Omaruru',
			'Maltahöhe',
			'Karasburg',
			'Ondangwa',
			'Gobabis',
			'Arandis',
			'Otjiwarongo',
			'Bagani',
			'Outjo',
			'Khorixas',
			'Karibib',
			'Walvis Bay',
			'Warmbad',
			'Hoachanas',
			'Otavi',
			'Ongandjera',
			'Swakopmund',
			'Okahandja',
			'Nkurenkuru',
			'Aranos',
			'Opuwo',
			'Mariental',
			'Oshakati',
			'Ongwediva',
			'Keetmanshoop',
			'Rundu',
			'Katutura',
			'Rehoboth',
			'Oshikango',
		],
	},
	Nauru: { country: 'Nauru', cities: ['Denigomodu', 'Yangor', 'Yaren', 'Arijejen', 'Uaboe', 'Baiti', 'Anibare', 'Anabar', 'Menen', 'Ijuw'] },
	Nepal: {
		country: 'Nepal',
		cities: [
			'Pyūthān',
			'Lahān',
			'Bhadrapur',
			'Panauti',
			'Dihi',
			'Panauti̇̄',
			'Dadeldhurā',
			'Lamjung',
			'Gaur',
			'Birgañj',
			'Hetauda',
			'Besisahar',
			'Chitre',
			'Dārchulā',
			'Bardiyā',
			'Rājbirāj',
			'Īṭahari̇̄',
			'Tānsen',
			'Kodāri̇̄',
			'Khā̃dbāri̇̄',
			'Pātan',
			'Malaṅgawā',
			'Kirtipur',
			'Madhyapur Thimi',
			'Rāmechhāp',
			'Butwāl',
			'Banepā',
			'kankrabari Dovan',
			'Triyuga',
			'Jumla',
			'Dailekh',
			'Birendranagar',
			'Surkhet',
			'Bhojpur',
			'Bāglung',
			'Siraha',
			'Pokhara',
			'Bhojpur',
			'Achhām',
			'Gulariyā',
			'Inaruwa',
			'Kathmandu',
			'Wāliṅ',
			'Dhangaḍhi̇̄',
			'Tulsīpur',
			'Ṭikāpur',
			'Janakpur',
			'Dharān',
			'Ilām',
			'Namche Bazaar',
			'Biratnagar',
			'Nepalgunj',
			'Nagarkot',
			'Jaleshwar',
			'Bharatpur',
			'Bhattarai Danda',
			'Dipayal',
			'Bhaktapur',
			'Salyān',
			'Mahendranagar',
			'Lobuche',
			'Hari Bdr Tamang House',
			'Dhulikhel',
			'Dhankutā',
			'Siddharthanagar',
		],
	},
	Netherlands: {
		country: 'Netherlands',
		cities: [
			'Zesgehuchten',
			'Witmarsum',
			'Wijk bij Duurstede',
			'Westerzicht',
			'Westerlee',
			'Waspik',
			'Wanssum',
			'Volkel',
			'Tynaarlo',
			'Terbregge',
			'Sint Philipsland',
			'Schiedam',
			'Ravenstein',
			'Overschie',
			'Oosthuizen',
			'Oosterzij',
			'Oostelbeers',
			'Oirlo',
			'Nieuw-Vossemeer',
			'Nes',
			'Neede',
			'Moesel',
			'Lage Mierde',
			'Krimpen aan den IJssel',
			'Klarenbeek',
			'Kijkduin',
			'Kerkelanden',
			'IJlst',
			'Hoensbroek',
			'Heusdenhout',
			'Hattem',
			'Hatert',
			'Harlingen',
			'Haamstede',
			'Griffioen',
			'Feijenoord',
			'Emmerhout',
			'Eelde',
			'Duiven',
			'Donkerbroek',
			'Dongen',
			'Dirksland',
			'De Wijert',
			'De Maer',
			'Cothen',
			'Commandeurs',
			'Coendersborg',
			'Bunschoten',
			'Budel',
			'Borculo',
			'Beusichem',
			'Besoijen',
			'Beesel',
			'Barger-Oosterveld',
			'Baardwijk',
			'Andelst',
			'Amby',
			'Alkmaar',
			'Hoogkamp',
			'Spechtenkamp',
			'Wisselaar',
			'Zuidhorn',
			'Zegveld',
			'Waubach',
			'Waddinxveen',
			'Voerendaal',
			'Vlierden',
			'Vijfhuizen',
			'Valthe',
			'Someren-Eind',
			'Schoonoord',
			'Scherpenisse',
			'Roosteren',
			'Raam',
			'Pottenberg',
			'Pernis',
			'Oudemirdum',
			'Oosterbeek',
			'Noordgeest',
			'Nieuwendijk',
			'Mierlo',
			'Middelsluis',
			'Melderslo',
			'Mariarade',
			'Makkum',
			'Loon op Zand',
			'Leesten',
			'Leersum',
			'Laren',
			'Langweer',
			'Langeheit',
			'Kerensheide',
			'Husken',
			'Horssen',
			'Hippolytushoef',
			'Hapert',
			'Halfweg',
			'Gasselte',
			'Fijnaart',
			'Ekenrooi',
			'Doesburg',
			'Cuijk',
			'Boekel',
			'Blaricum',
			'Berlicum',
			'Berkum',
			'Bakkeveen',
			'Ameide',
			'Aalsmeer',
			'Sluisoord',
			'Spainkbos',
			'Wisch',
			'Weesp',
			'Waalre',
			'Vlokhoven',
			'Twijzel',
			'Tuk',
			'Sint Annaparochie',
			'Schildwolde',
			'Rijnsburg',
			'Oud-Beijerland',
			'Orthen',
			'Oirsbeek',
			'Nieuwkuijk',
			'Nederhemert-Noord',
			'Moerdijk',
			'Mijdrecht',
			'Maarheeze',
			'Kerkdriel',
			'Marken',
			'Hofgeest',
			'Herten',
			'Heesch',
			'Hedel',
			'Gouwsluis',
			'Genoenhuis',
			'Geenhoven',
			'Gageldonk',
			'Ellecom',
			'Doornspijk',
			'Domburg',
			'De Meern',
			'De Loo',
			'Capelle aan den IJssel',
			'Brabander',
			'Bolsward',
			'Bloemendaal',
			// 'Bergen',
			'Beegden',
			'Arkel',
			'Appingedam',
			'Alblasserdam',
			'Matendonk',
			'De Bouwhof',
			'Reitdiep',
			'Zeewolde',
			'Woubrugge',
			'Waarde',
			'Vriezenveen',
			'Vrieheide',
			'Vorstenbosch',
			'Venray',
			'Udenhout',
			'Tuindorp',
			'Stiens',
			'Sliedrecht',
			"'s-Heerenberg",
			'Schijndel',
			'Schiermonnikoog',
			'Rugge',
			'Rozendaal',
			'Quirijnstok',
			'Ophemert',
			'Odoorn',
			'Nijkerkerveen',
			'Nieuwland',
			'Monnickendam',
			'Milsbeek',
			'Middenmeer',
			'Merselo',
			'Mekkelholt',
			'Mariahout',
			'Maasdijk',
			'Korrewegwijk',
			'Ilpendam',
			'IJmuiden',
			'Holz',
			'Heythuysen',
			'Hengelo',
			'Hendrik-Ido-Ambacht',
			'Heerewaarden',
			'Heerde',
			'Haanrade',
			'Groessen',
			'Giessenburg',
			'Epe',
			'Enschot',
			'Egmond aan Zee',
			'Duinzigt',
			'Driebruggen',
			'De Kruiskamp',
			'Colijnsplaat',
			'Bunde',
			'Buitenpost',
			'Brinkhorst',
			'Brakel',
			'Boxtel',
			'Blitterswijck',
			'Ballast',
			'Aduard',
			'Abcoude',
			'Zuiderburen',
			'Werkhoven',
			'Vlodrop',
			'Twisk',
			'Spakenburg',
			'Soerendonk',
			'Sittard',
			'Sintjohannesga',
			'Sint Anthonis',
			'The Hague',
			'Schoondijke',
			'Puiflijk',
			'Poeldijk',
			'Piershil',
			'Peij',
			'Oostkapelle',
			'Oldenzaal',
			'Noordeinde',
			'Nijnsel',
			'Nijenheim',
			'Montfoort',
			'Marum',
			'Lopik',
			'Kootwijkerbroek',
			'Koningslust',
			'Kedichem',
			'Katwijk aan Zee',
			'Honselersdijk',
			'Holtum',
			'Herkenbosch',
			'Heemskerk',
			'Goudswaard',
			'Gasselternijveen',
			'Everdingen',
			'Ens',
			'Elden',
			'Eext',
			'De Heeg',
			'Coevering',
			'Capelle-West',
			'Broekhoven',
			'Breukelen',
			'Bleiswijk',
			'Bleijerheide',
			'Almere Stad',
			'Matengaarde',
			'Brummelhof',
			'De Haven',
			'Zonnemaat',
			'Weert',
			'Wapenveld',
			'Valkenswaard',
			'Ugchelen',
			'Steenwijkerwold',
			'Steenderen',
			'Son',
			'Scharnegoutum',
			'Ruinen',
			'Rhoon',
			'Oudkarspel',
			'Oosterhoogebrug',
			'Olst',
			'Oldebroek',
			'Obergum',
			'Noordbroek',
			'Middelrode',
			'Leuken',
			'Leens',
			'Hummelo',
			'Het Loo',
			'Hagestein',
			'Gorinchem',
			'Enkhuizen',
			'Emst',
			'Eindhoven',
			'Eersel',
			'Ede',
			'Bussum',
			'Broeksterwâld',
			'Bangert',
			'Groenswaard',
			'Zoeterwoude-Dorp',
			'Zevenhuizen',
			'Zeddam',
			'Wijhe',
			'Ulrum',
			'Ten Boer',
			'Stolwijk',
			'Staphorst',
			'Sleen',
			'Scheveningen',
			'Rijswijk',
			'Petten',
			'Peelo',
			'Paterswolde',
			'Ospel',
			'Opeinde',
			'Onstwedde',
			'Nieuwehorne',
			'Montfort',
			'Maasland',
			'Loenen',
			'Leusden',
			'Leeuwen',
			'Kudelstaart',
			'Koningsbosch',
			'IJzendijke',
			'Hilversumse Meent',
			'Hilversum',
			'Heijplaat',
			'Heemstede',
			'Hardenberg',
			'Haarle',
			'Haaksbergen',
			'Groningen',
			'Gracht',
			'Giethoorn',
			'Geldermalsen',
			'Eexta',
			'Dronryp',
			'Dirkshorn',
			'Delden',
			'De Hoven',
			'De Drait',
			'De Domp',
			'Broekhem',
			'Brielle',
			'Breugel',
			'Bosschenhoofd',
			'Borgharen',
			'Boornbergum',
			'Beers',
			'Baarn',
			'Austerlitz',
			'Almkerk',
			'Op Buuren',
			'Zuilichem',
			'Zoetermeer',
			'Zeelst',
			'Zeeland',
			'Wolder',
			'Waalwijk',
			'Vijlen',
			'Veltum',
			'Ulestraten',
			'Teteringen',
			'Terschuur',
			'Strijp',
			'Steensel',
			'Stavenisse',
			'Spekholzerheide',
			'Spangen',
			'Schelluinen',
			'Roelofarendsveen',
			'Ridderkerk',
			'Raamsdonk',
			'Papenveer',
			'Pannerden',
			'Ouderkerk aan de Amstel',
			'Onderdijk',
			'Nieuw-Dordrecht',
			'Neerbeek',
			'Mook',
			'Minnertsga',
			'Menaam',
			'Meezenbroek',
			'Luyksgestel',
			'Lunteren',
			'Losser',
			'Liempde',
			'Leunen',
			'Leerdam',
			'Kwintsheul',
			'Kessel',
			'Keijenborg',
			'Kakert',
			'Huizum',
			'Heer',
			'Hasselt',
			'Harderwijk',
			'Emmer-Compascuum',
			'Elburg',
			'Duindorp',
			'Dorst',
			'Doorn',
			'Doenrade',
			'Diepenheim',
			'Diemen',
			'Deuteren',
			'Amstenrade',
			'Binnenstad',
			'Westenenk',
			'Gulden Bodem',
			'Besterd',
			'Wouw',
			'Woerden',
			'Wijnandsrade',
			'Warmond',
			'Waardenburg',
			'Vierpolders',
			'Utrecht',
			'Tiel',
			'Tholen',
			"'t Hofke",
			'Sint Odiliënberg',
			"'s-Hertogenbosch",
			'Ruinerwold',
			'Rijsoord',
			'Rheden',
			'Renswoude',
			'Prinsenbeek',
			'Overasselt',
			'Ouderkerk aan den IJssel',
			'Othene',
			'Oppenhuizen',
			'Oostermeenthe',
			'Odiliapeel',
			'Noord-Scharwoude',
			'Nieuwkoop',
			'Nieuwe-Niedorp',
			'Nederweert',
			'Naastenbest',
			'Naaldwijk',
			'Malberg',
			'Loven',
			'Linschoten',
			'Leek',
			'Koudum',
			'Koewacht',
			'Klimmen',
			'Kadoelen',
			'Hoogwoud',
			'Hoofddorp',
			'Het Oostrik',
			'Heerjansdam',
			'Groot-Ammers',
			'Gouda',
			'Geitenkamp',
			'Emmen',
			'Elshout',
			'Diever',
			'De Rompert',
			'De Kwakel',
			'De Koog',
			'Dauwendaele',
			'Budel-Dorplein',
			'Buchten',
			'Brukske',
			'Born',
			'Borger',
			'Bodegraven',
			'Beetsterzwaag',
			'Barneveld',
			'Baalder',
			'Assendelft',
			'Zuid-Scharwoude',
			'Zoutkamp',
			'Zijtaart',
			'Zaamslag',
			'Wommels',
			'Woenselse Heide',
			'Wijlre',
			'Warga',
			'Vreewijk',
			'Vinkeveen',
			'Veenendaal',
			'Tricht',
			'Tivoli',
			'Ruurlo',
			'Reusel',
			'Purmerend',
			'Posterholt',
			'Oranjewoud',
			'Opijnen',
			'Ophoven',
			'Oosteinde',
			'Ommen',
			'Numansdorp',
			'Nistelrode',
			'Niekerk',
			'Mijnsheerenland',
			'Maarssen',
			'Langenoord',
			'Langenboom',
			'Kruisland',
			'Korvel',
			'Kortenhoef',
			'Klein Driene',
			'Kerkehout',
			'Katendrecht',
			'Hoek van Holland',
			'Heeze',
			'Heeswijk-Dinther',
			'Haps',
			'Groenewoud',
			'Grafhorst',
			'Ginneken',
			'Geertruidenberg',
			'Gameren',
			'Elspeet',
			'De Peulen',
			'Dalen',
			'Brummen',
			'Biddinghuizen',
			'Zuid-Berghuizen',
			'Rivierenkwartier',
			'Jubbega',
			'Zeilberg',
			'Wessem',
			'Well',
			'Vledder',
			'Valkenburg',
			"'t Zand",
			'Twekkelerveld',
			'Tweede Exloërmond',
			'Surhuisterveen',
			'Soest',
			'Silvolde',
			'Sellingen',
			'Rossum',
			'Rockanje',
			'Renkum',
			'Oosterland',
			'Oosterhout',
			'Oegstgeest',
			'Obbicht',
			'Noordhoek',
			'Nispen',
			'Middenbeemster',
			'Meteren',
			'Medemblik',
			'Maasbracht',
			'Loppersum',
			'Kollumerzwaag',
			'Jagershoef',
			'Hoeven',
			'Haren',
			'Gemonde',
			'Epse',
			'Egmond-Binnen',
			'Driel',
			'De Glip',
			'Bosch en Duin',
			'Bocholtz',
			'Baarle-Nassau',
			'Avenhorn',
			'Matenveld',
			'Matenhoeve',
			'Zuidlaren',
			'Wolphaartsdijk',
			'Wijk aan Zee',
			'West-Souburg',
			'Wemeldinge',
			'Wellerlooi',
			'Voorst',
			'Urmond',
			'Ulvenhout',
			'Tijnje',
			'Tytsjerk',
			'Stampersgat',
			'Sprundel',
			'Sevenum',
			'Reeuwijk',
			'Poortvliet',
			'Oud-Loosdrecht',
			'Oud-Caberg',
			'Orden',
			'Opperdoes',
			'Oostrum',
			'Oostendorp',
			'Nuenen',
			'Nieuw-Roden',
			'Nieuw-Namen',
			'Nieuw-Lekkerland',
			'Andel',
			'Molenhoek',
			'Mill',
			'Middelburg',
			'Lichtenvoorde',
			'Lelystad',
			'Leest',
			'Krakeel',
			'Ingen',
			'Hoogland',
			'Hoogblokland',
			'Holwerd',
			'Hoeven',
			'Heino',
			'Gulpen',
			'Giesbeek',
			'Gerwen',
			'Geldermalsen-West',
			'Essesteijn',
			'Ermelo',
			'Eerschot',
			'Dussen',
			'Driemanspolder',
			'Deventer',
			'De Noord',
			'Den Burg',
			'Borgele',
			'Beuningen',
			'Beringe',
			'Bergeijk',
			'Balk',
			'Abcoven',
			'Abbekerk',
			'Aalden',
			'Matendreef',
			'Woudhuis',
			'Speelheide',
			'Sterrenberg',
			'Zwolle',
			'Zwaanshoek',
			'Woudrichem',
			'Winsum',
			'Westervoort',
			'Weiteveen',
			'Waarland',
			'Vollenhove',
			'Valendries',
			'Tzummarum',
			'Tongelre',
			'Theereheide',
			'Terwolde',
			'Ter Apel',
			'Stein',
			'Statenkwartier',
			'Sint Nicolaasga',
			'Simpelveld',
			'Selwerd',
			'Schagen',
			'Rijswijk',
			'Ossendrecht',
			'Noordwijk-Binnen',
			'Noordhorn',
			'Nes',
			'Melick',
			'Marrum',
			'Margraten',
			'Lombardijen',
			'Lochem',
			'Lisse',
			'Laren',
			'Huizen',
			'Hoogezand',
			'Hengstdal',
			'Heide',
			'Heesterakker',
			'Esch',
			'Enschede',
			'Edam',
			'Druten',
			'Driehuizen',
			'Dommelen',
			'Diessen',
			'De Wijk',
			'De Rijp',
			'Boxmeer',
			'Borne',
			'Bijvanck',
			'Bergharen',
			'Beek gem Montferland',
			'Bavel',
			'Camminghaburen',
			'Sprenkelaar',
			'Sprengenweg-Noord',
			'Aarle-Rixtel',
			'Zaanstad',
			'Wolfsbos',
			'West-Terschelling',
			'Westeinde',
			'Vorden',
			'Varsseveld',
			'Uitgeest',
			'Stadbroek',
			'Spaubeek',
			'Sint-Oedenrode',
			'Sibbe',
			"'s-Gravenzande",
			'Sappemeer',
			'Overloon',
			'Ooyerhoek',
			'Oosterpark',
			'Ooij',
			'Aldeboarn',
			'Nijrees',
			'Nieuwenhoorn',
			'Middelharnis',
			'Limmel',
			'Lemmer',
			'Koudekerke',
			'Klein-Zundert',
			'Kerschoten',
			'Heteren',
			'Heiloo',
			'Heerlen',
			'Haelen',
			'Haarlem',
			'Groesbeek',
			'Geleen',
			'Geffen',
			'Garyp',
			'Echtenerbrug',
			'Dordrecht',
			'De Uithof',
			'Den Ham',
			'Bunnik',
			'Blaarthem',
			'Berkhout',
			'Burgum',
			'Beesd',
			'Babberich',
			'Amerongen',
			'Abdissenbosch',
			'Villapark',
			'Zandweg-Oostwaard',
			'Zuidbroek',
			'Workum',
			'Westmaas',
			'Werkendam',
			'Vuren',
			'Vliedberg',
			'Vlagtwedde',
			'Velp',
			'Vaassen',
			'Ureterp',
			'Stramproy',
			'Steyl',
			'Stadskanaal',
			'Spierdijk',
			'Soestdijk',
			'Sint Pancras',
			'Sexbierum',
			'Scharn',
			'Schalkhaar',
			'Rottevalle',
			'Pierik',
			'Ommelanderwijk',
			'Odijk',
			'Noardburgum',
			'Noordbarge',
			'Nieuw-Beijerland',
			'Middelbeers',
			'Meliskerke',
			'Markelo',
			'Maartensdijk',
			'Kesteren',
			'Kampen',
			'Joure',
			'Jirnsum',
			'Hooge Zwaluwe',
			'Hoogeloon',
			'Hoge Mors',
			'Herwijnen',
			'Hem',
			'Helpman',
			'Havelte',
			'Grijpskerke',
			'Franeker',
			'Etten',
			'Dwingeloo',
			'Driebergen-Rijsenburg',
			'Donk',
			'De Hagen',
			'Bruinisse',
			'Bruchem',
			'Broek in Waterland',
			'Breskens',
			'Boskoop',
			'Blijham',
			'Biesdonk',
			'Benthuizen',
			'Bargeres',
			'Adegeest',
			'Chevremont',
			'Staatsliedenkwartier',
			'De Westereen',
			'Zuid-Beijerland',
			'Yerseke',
			'Winschoten',
			'Wijchen',
			'Wieringerwaard',
			'Weijpoort',
			'Weerestein',
			'Warnsveld',
			'Wagenberg',
			'Vught',
			'Vogelenzang',
			'Vlissingen',
			'Tuikwerd',
			'Terwinselen',
			'Slochteren',
			'Sint Laurens',
			'Roosendaal',
			'Putten',
			'Papendrecht',
			'Oudewater',
			'Oosterholt',
			'Oirschot',
			'Noordeloos',
			'Nederhemert',
			'Meerssen',
			'Maurik',
			'Linne',
			'Lierop',
			'Helden',
			'Harskamp',
			'Haaren',
			'Grou',
			'Gorredijk',
			'Glimmen',
			'Giessen',
			'Einighausen',
			'Egchel',
			'Driemond',
			'Doorwerth',
			'Doesburg',
			'Budschop',
			'Budel-Schoot',
			'Bloemhof',
			'Bleskensgraaf',
			'Burdaard',
			'Best',
			'Beekbergen',
			'Baambrugge',
			'Axel',
			'Annen',
			'Ankeveense Rade',
			'Angerlo',
			'Almelo',
			'Akkrum',
			'Akert',
			'Aalst',
			'Matenhorst',
			'Kelpen-Oler',
			'Zandvoort',
			'Wilhelminadorp',
			'Wijnjewoude',
			'Voorthuizen',
			'Tolkamer',
			'Tilburg',
			'Stegeslag',
			'Stadsfenne',
			'Schoonhoven',
			'Scharendijke',
			'Putte',
			'Pathmos',
			'Oudega',
			'Ottersum',
			'Otterlo',
			'Oosterhout',
			'Noord-Hofland',
			'Midwolda',
			'Meppel',
			'Limmen',
			'Liessel',
			'Leeuwarden',
			'Leende',
			'Koekange',
			'Hoogeveen',
			'Hensbroek',
			'Hengevelde',
			'Heel',
			'Harkstede',
			'Handel',
			'Haalderen',
			'Grootegast',
			'Gytsjerk',
			'Gennep',
			'Gendringen',
			'Geldrop',
			'Delfzijl',
			'Brunnepe',
			'Broeksittard',
			'Boven-Hardinxveld',
			'Biesland',
			'Berg',
			'Arnhem',
			'Aalburg',
			'De Heeze',
			"'s-Gravenland",
			'Dapperbuurt',
			'Zwijndrecht',
			'Zeist',
			'Zaandijk',
			'Woensdrecht',
			'Westerhaar-Vriezenveensewijk',
			'Warffum',
			'Vries',
			'Velsen-Zuid',
			'Uithuizen',
			'Uden',
			'Twello',
			'Ternaard',
			'Stompetoren',
			'Stein',
			'Steenwijk',
			'Sluis',
			'Sint Willebrord',
			'Schoonrewoerd',
			'Raamsdonksveer',
			'Oud-Vossemeer',
			'Meijel',
			'Loosbroek',
			'Lienden',
			'Lent',
			'Leimuiden',
			'Leiderdorp',
			'Ysselsteyn',
			'Hulst',
			'Huijbergen',
			'Hank',
			'Haastrecht',
			'Groot IJsselmonde',
			'Grashoek',
			'Gouderak',
			'Elsloo',
			'Eenrum',
			'Eefde',
			'Drumpt',
			'Doonheide',
			'Damwâld',
			'Breezand',
			'Bredevoort',
			'Boskamp',
			'Bitswijk',
			'Bergstoep',
			'Bakel',
			'Augustinusga',
			'Angeren',
			'Angelslo',
			'Salderes',
			'Meerhoven',
			'Zonderwijk',
			'Zierikzee',
			'Zegge',
			'Wirdum',
			'Westkapelle',
			'Voorschoten',
			'Veen',
			'Valthermond',
			'Usquert',
			'Uithoorn',
			'Terneuzen',
			'Terborg',
			'Spijkenisse',
			'Sleeuwijk',
			'Schipluiden',
			'Schinnen',
			'Rozenburg',
			'Rotterdam',
			'Rolde',
			'Rhenen',
			'Renesse',
			'Raalte',
			'Oudehaske',
			'Oosterblokker',
			'Oldemarkt',
			'Oentsjerk',
			'Nunspeet',
			'Noordwijkerhout',
			'Nieuw-Buinen',
			'Muiderberg',
			'Muiden',
			'Maaskantje',
			'Maasbree',
			'Klundert',
			'Kerkrade',
			'Helvoirt',
			'Heerle',
			'Heeg',
			'Harenkarspel',
			'Haaften',
			'Ederveen',
			'Duivendrecht',
			'Delft',
			'Brakkenstein',
			'Bergschenhoek',
			'Berg en Dal',
			'Beltrum',
			'Anklaar',
			'Alverna',
			'Hoek',
			'Vondelwijk',
			'Kop van Zuid',
			'Zwaagdijk-Oost',
			'Zutphen',
			'Zundert',
			'Zandberg',
			'Zaltbommel',
			'Woudsend',
			'Westlaren',
			'Waterakkers',
			'Wanroij',
			'Wageningen',
			'Vroomshoop',
			'Ven',
			'Stepekolk',
			'Schermerhorn',
			'Schaijk',
			'Overveen',
			'Overberg',
			'Oudeschoot',
			'Nieuwolda',
			'Nieuw- en Sint Joosland',
			'Mantgum',
			'Magele',
			'Lottum',
			'Lisserbroek',
			'Lauradorp',
			'Kollum',
			'Kamperland',
			'Hilvarenbeek',
			'Haulerwijk',
			'Harkema',
			'Gronsveld',
			'Grijpskerk',
			'Dronten',
			'De Goorn',
			'De Bilt',
			'Beverwijk',
			'Berkenwoude',
			'Bergen op Zoom',
			'Barendrecht',
			'Ammerstol',
			'Aalten',
			'Muschberg en Geestenberg',
			'Zoutelande',
			'Zevenaar',
			'Westerbork',
			'Vianen',
			'Uithuizermeeden',
			'Standdaarbuiten',
			'Sluiskil',
			'Siddeburen',
			'Sas van Gent',
			'Peize',
			'Oude Wetering',
			'Oss',
			'Oost-Vlieland',
			'Ommoord',
			'Nieuwoord',
			'Nederwoud',
			'Mechelen',
			'Maastricht',
			'Lievendaal',
			'Lieshout',
			'Kunrade',
			'Kaalheide',
			'Heusden',
			'Ferwert',
			'Emmermeer',
			'Emmer-Erfscheidenveen',
			'Eerde',
			'Den Oever',
			'Culemborg',
			'Chaam',
			'Breda',
			'Bakenberg',
			'Asten',
			'Arcen',
			'Amstelveen',
			'Winkewijert',
			'Berkel en Rodenrijs',
			'Corlaer',
			'Zuidwijk',
			'Zelhem',
			'Westdorpe',
			'Wernhout',
			'Wekerom',
			'Voorburg',
			'Venhorst',
			'Vaartbroek',
			'Tienray',
			'Tegelen',
			'Strijen',
			'Sneek',
			'Sint Jansklooster',
			'Sint Jacobiparochie',
			'Scheemda',
			'Schagerbrug',
			'Rossum',
			'Puth',
			'Oosterhesselen',
			'Oisterwijk',
			'Nuth',
			'Nieuw-Lotbroek',
			'Maarn',
			'Lobith',
			'Lindenholt',
			'Landsmeer',
			'Katwijk aan den Rijn',
			'Houthem',
			'Hoorn',
			'Hoogmade',
			'Hooge Mierde',
			'Hollum',
			'Heumen',
			'Heksenberg',
			'Hattemerbroek',
			'Doornenburg',
			'Dokkum',
			'De Lier',
			'Delfshaven',
			'De Kieviet',
			'Berg en Bos',
			'Berg',
			'Bennekom',
			'Beilen',
			'Bedum',
			'Arnemuiden',
			'Amsterdam',
			'Ammerzoden',
			'Afferden',
			'Aardenburg',
			'Welgelegen',
			'Zevenbergschen Hoek',
			'Wolvega',
			'Wijdenes',
			'Wierden',
			'Vredenburg',
			'Vogelwijk',
			'Vlaardingen',
			'Veldhuizen',
			'Urk',
			'Steenbergen',
			'Schinveld',
			'Riethoven',
			'Ouddorp',
			'Offenbeek',
			'Nieuwveen',
			'Nieuw-Loosdrecht',
			'Melle',
			'Maassluis',
			'Liesveld',
			'Kerckebosch',
			'IJsselstein',
			'Hulsberg',
			'Hopel',
			'Groenekan',
			'Gemert',
			'Elst',
			'Duizel',
			'Doetinchem',
			'De Reit',
			'Coevorden',
			'Brachterbeek',
			'Binnenhof',
			'Aalst',
			'Amsterdam-Zuidoost',
			'Ypenburg',
			'Kapelle',
			'Marsdijk',
			'Zoelen',
			'Wolfheze',
			'Winkel',
			'Wagenborgen',
			'Voorhout',
			'Voldijn',
			'Spoorwijk',
			'Rucphen',
			'Palenstein',
			'Overhoven',
			'Oudeschild',
			'Oosteind',
			'Oostdorp',
			'Oldehove',
			'Obdam',
			'Milheeze',
			'Midwoud',
			'Middelstum',
			'Meerveldhoven',
			'Lutjebroek',
			'Limbricht',
			'Lammerenburg',
			'Kloetinge',
			'Klaaswaal',
			'Hoogvliet',
			'Heiligerlee',
			'Heechterp',
			'Hallum',
			'Gendt',
			'Garderen',
			'Emmerschans',
			'Den Helder',
			'De Blaak',
			'Brunssum',
			'Bolnes',
			'Bloemendaal',
			'Bilgaard',
			'Bellingwolde',
			'Belfort',
			'Bakhuizen',
			'Appelscha',
			'Vogelkwartier',
			'Lakerlopen',
			'Aldlân-Oost',
			'Oranjewijk',
			'Randenbroek',
			'Woolde',
			'Wieringerwerf',
			'Vreeswijk',
			'Vleuten',
			'Valkenburg',
			'Ubachsberg',
			'Tubbergen',
			'Someren',
			'Sint Joost',
			'Schimmert',
			'Sassenheim',
			'Oud Gastel',
			'Oostburg',
			'Ooy',
			'Ochten',
			'Nieuwegein',
			'Naarden',
			'Lith',
			'Lepelstraat',
			'Krooswijk',
			'Kootstertille',
			'Keent',
			'Houten',
			'Hoogerheide',
			'Heusden',
			'Heugem',
			'Hengelo',
			'Goirle',
			'Gieten',
			'Eibergen',
			'Drachten',
			'Dinteloord',
			'Deil',
			'Deest',
			'De Doornakkers',
			'Carnisse',
			'Callantsoog',
			'Brouwershaven',
			'Bredeweg',
			'Brand',
			'Bennebroek',
			'Bemmel',
			'Beek',
			'Baflo',
			'Baarlo',
			'Apeldoorn',
			'Aagtekerke',
			'Wooldrik',
			'Winterswijk',
			'Westeneng',
			'Volendam',
			'Vlietwijk',
			'Veldhoven',
			'Feanwâlden',
			'Veenoord',
			'Valburg',
			'Ulft',
			'Sint-Michielsgestel',
			'Scherpenzeel',
			'Rijnsaterwoude',
			'Rijen',
			'Reuver',
			'Pendrecht',
			'Panningen',
			'Opmeer',
			'Oosterwolde',
			'Nijmegen',
			'Nijkerk',
			'Nieuwerkerk',
			'Nieuwdorp',
			'Neerkant',
			'Munstergeleen',
			'Merum',
			'Merkelbeek',
			'Malburgen West',
			'Kruisberg',
			'Klazienaveen',
			'Kalsdonk',
			'Herpen',
			'Heerenveen',
			'Halsteren',
			'Haarsteeg',
			'Guttecoven',
			'Goutum',
			'Gorssel',
			'Gilze',
			'Frankhuis',
			'Eijsden',
			'Drachtstercompagnie',
			'Dieren',
			'De Mheen',
			'Cranendonck',
			'Borssele',
			'Berltsum',
			'Berghem',
			'Banholt',
			'Assen',
			'Amersfoort',
			'America',
			'Componistenkwartier',
			'Sprengenbos',
			'Grasrijk',
			'Burgemeesterswijk',
			'Hunnerberg',
			'Son en Breugel',
			'Zevenhoven',
			'Wilnis',
			'Wijbosch',
			'Westwoud',
			'Welberg',
			'Waarder',
			'Venlo',
			'Uffelte',
			'Twijzelerheide',
			'Thorn',
			'Terheijden',
			'Slootdorp',
			"'s Gravenmoer",
			'Serooskerke',
			'Schoonebeek',
			'Roden',
			'Reek',
			'Pijnacker',
			'Ootmarsum',
			'Oostvoorne',
			'Oog in Al',
			'Oldeberkoop',
			'Oerle',
			'Norg',
			'Noordwolde',
			'Nieuwpoort',
			'Nieuwe Pekela',
			'Merenwijk',
			'Meerlo',
			'Meeden',
			'Marsum',
			'Malta',
			'Lindenheuvel',
			'Lichtenberg',
			'Leuth',
			'Hollandsche Rading',
			'Helmond',
			'Hellevoetsluis',
			'Hees',
			'Heelsum',
			'Hurdegaryp',
			'Halfweg',
			'Grevenbicht',
			'Grave',
			'Goor',
			'Goes',
			'Flevowijk',
			'Farmsum',
			'Exloo',
			'Erp',
			'Den Dungen',
			'Dalfsen',
			'Castricum',
			'Broek op Langedijk',
			'Blokzijl',
			'Bladel',
			'Belcrum',
			'Beersdal',
			'Alphen aan den Rijn',
			'Alphen',
			'Zetten',
			'Zaandam',
			'Woudenberg',
			'Wissenkerke',
			'Wildervank',
			'Westerhoven',
			'Wassenaar',
			'Veghel',
			'Veendam',
			'Vaals',
			'Uddel',
			'Tinga',
			'Spijk',
			'Slikkerveer',
			'Schutsboom',
			'Schilberg',
			'Schiebroek',
			'Rothem',
			'Roermond',
			'Rijsbergen',
			'Rijpwetering',
			'Princenhage',
			'Passart',
			'Oude Pekela',
			'Opheusden',
			'Eastermar',
			'Neder-Hardinxveld',
			'Monster',
			'Megen',
			'Made',
			'Leiden',
			'Hoge Vucht',
			'Hillegom',
			'Heijen',
			'Hegelsom',
			'Heerhugowaard',
			'Goedereede',
			'Giessendam',
			'Genderen',
			'Emmeloord',
			'Eerbeek',
			'Eckart',
			'Drogeham',
			'Doornsteeg',
			'De Greiden',
			'Beek',
			'De Knipe',
			'Lunetten',
			'Waterdonken',
		],
	},
	'New Caledonia': {
		country: 'New Caledonia',
		cities: [
			'Ponérihouen',
			'Kaala-Gomén',
			'Fayaoué',
			'Bouloupari',
			'Poya',
			'Voh',
			'Ouégoa',
			'Dumbéa',
			'Canala',
			'Vao',
			'Poindimié',
			'Koné',
			'Koumac',
			'Bourail',
			'Houaïlou',
			'Yaté-Barrage',
			'Mont-Dore',
			'La Foa',
			'Tadine',
			'Hienghène',
			'Pouébo',
			'Wé',
			'Thio',
			'Nouméa',
			'Wala',
			'Pouembout',
			'Touho',
			'Poum',
			'Païta',
		],
	},
	'New Zealand': {
		country: 'New Zealand',
		cities: [
			'Wellington',
			'Waipu',
			'Titahi Bay',
			'Wanaka',
			'Northland',
			'Clive',
			'Balclutha',
			'Queenstown',
			'East Gore',
			'Andersons Bay',
			'Kaitaia',
			'Greenhithe',
			'Balmoral',
			'Te Atatu Peninsula',
			'Oneroa',
			'Marybank',
			'Mount Victoria',
			'Maraenui',
			'Whitianga',
			'Waitoa',
			'Taupo',
			'Moturoa',
			'Kawerau',
			'Greerton',
			'Frankton',
			'Albany',
			'Eltham',
			'Riverton',
			'Clifton',
			'Takapuna',
			'Ilam',
			'Richmond',
			'Aranui',
			'Mangere East',
			'Papatoetoe',
			'Kingsland',
			'Owhiro Bay',
			'Maupuia',
			'Brown Owl',
			'Taradale',
			'Seatoun',
			'Riverhead',
			'Pukerua Bay',
			'Orewa',
			'Haumoana',
			'Dargaville',
			'Hawera',
			'Frankleigh Park',
			'Shelly Park',
			'Grey Lynn',
			'Morningside',
			'Acacia Bay',
			// 'Glendale',
			'Waiwhetu',
			'Waikiwi',
			'Rangiriri',
			'Pirongia',
			'Paremata',
			'Onerahi',
			'Onekawa',
			'New Plymouth',
			'Murupara',
			'Kelburn',
			'Invercargill',
			'Dairy Flat',
			'Auckland',
			'Palmerston',
			'Bexley',
			'Rothesay Bay',
			'Manurewa',
			'New Windsor',
			'Glen Innes',
			'Bishopdale',
			'Moana',
			'Grenada North',
			'Wellington Central',
			'Woodend',
			'Wainui',
			'Turangi',
			'Tiniroto',
			'Tairua',
			'Rapaura',
			'Parnell',
			'Ngatea',
			'Belfast',
			'Arthurs Point',
			'Otaki',
			'Ashburton',
			'Redwood',
			'Hurdon',
			'Mornington',
			'Wilton',
			'Ngaruawahia',
			'Wigram',
			'Whitford',
			'Tawa',
			'Tangiteroria',
			'Tahunanui',
			'Silverdale',
			'Ponsonby',
			'Otorohanga',
			'Mapua',
			'Kumeu',
			'Kihikihi',
			'Favona',
			'Wairoa',
			'Burnside',
			'Opawa',
			'Stratford',
			'Awapuni',
			'Browns Bay',
			'Eastern Beach',
			'Chatswood',
			'Titirangi',
			'Hamilton West',
			'Waikanae',
			'Spreydon',
			'Rawene',
			'Motueka',
			'Plimmerton',
			'Leeston',
			'Masterton',
			'Hokitika',
			'Avonhead',
			'Hokowhitu',
			'West End',
			'Wiri',
			'Hauraki',
			'Lincoln',
			'Massey',
			'Moera',
			'Kelson',
			'Heretaunga',
			'Wakari',
			'Waiuku',
			'Ohinemutu',
			'Oakura',
			'Kelvin Grove',
			'Karori',
			'Fitzroy',
			'Fendalton',
			'Darfield',
			'Gore',
			'Strathern',
			'Red Hill',
			'Glendowie',
			'Beach Haven',
			'Three Kings',
			'Henderson',
			'Mayfield',
			'Hamilton East',
			'Pegasus',
			'Aro Valley',
			'Kopuaranga',
			'Kawakawa',
			'East Taieri',
			'Coromandel',
			'Claudelands',
			'Opunake',
			'Levin',
			'Whakatane',
			'Rosedale',
			'Konini',
			'Otahuhu',
			'Pakuranga Heights',
			'Owairaka',
			'Cambridge',
			'Cannons Creek',
			'Churton Park',
			'Rosebank',
			'West Melton',
			'Wakefield',
			'Taupiri',
			'Takaka',
			'Rolleston',
			'Oxford',
			'Newton',
			'Manukau City',
			'Khandallah',
			'Brooklyn',
			'Waihi',
			'Terrace End',
			'Takaro',
			'Takanini',
			'Highland Park',
			'Green Bay',
			'Hillsborough',
			'Remuera',
			'Newmarket',
			'Matamata',
			'Nelson South',
			'Waihi Beach',
			'Templeton',
			'Raumati Beach',
			'Pauanui',
			'Ladbrooks',
			'Judgeford',
			'Burnham',
			'Ahipara',
			'Milton',
			'Karoro',
			'Geraldine',
			'Upper Riccarton',
			'Bryndwr',
			'Waltham',
			'Beckenham',
			'Saint Marys Bay',
			'Blockhouse Bay',
			'Sandringham',
			'Te Hapara',
			'Blenheim',
			'Mount Cook',
			'Petone',
			'Naenae',
			'Upper Hutt',
			'Tinwald',
			'Prebbleton',
			'Portobello',
			'Otatara',
			'Oropi',
			'Mangere',
			'Kerikeri',
			'Corstorphine',
			'Murrays Bay',
			'Lyall Bay',
			'Epuni',
			'Avalon',
			'Oriental Bay',
			'Linton Military Camp',
			'Weston',
			'Te Kauwhata',
			'Raglan',
			'Omokoroa',
			'Kaukapakapa',
			'Bethlehem',
			'Papakura',
			'Thames',
			'Green Island',
			'Milson',
			'Whangarei',
			'Narrow Neck',
			'Strathmore Park',
			'Onepoto',
			'Riverstone Terraces',
			'Kennedys Bush',
			'Whangamata',
			'Waimate North',
			'Waikowhai',
			'Redcliffs',
			'Rakaia',
			'Ngunguru',
			'Napier',
			'Frankton Junction',
			'Campbells Bay',
			'Musselburgh',
			'Otangarei',
			'Waterview',
			'Epsom',
			'Kaiti',
			'Witherlea',
			'Maoribank',
			'Flaxmere',
			'Wainuiomata',
			'Waiatarua',
			'Ravensbourne',
			'Papanui',
			'Maungaturoto',
			'Lower Hutt',
			'Lincoln',
			'Ealing',
			'Foxton',
			'Waimate',
			'Dallington',
			'Wainoni',
			'Parklands',
			'Kaiapoi',
			'Southshore',
			'Waima',
			'Massey East',
			'Grafton',
			'Rotorua',
			'Springlands',
			'Hataitai',
			'Pinehaven',
			'Karaka Bays',
			'Point Chevalier',
			'Hastings',
			'Hanmer Springs',
			'Hamilton',
			'Ashhurst',
			'Paeroa',
			'Sockburn',
			'Kohimarama',
			'Roseneath',
			'Totara Park',
			'Belmont',
			'Newlands',
			'Alicetown',
			'Paekakariki',
			'New Brighton',
			'Moerewa',
			'Mangorei',
			'Maketu',
			'Halfway Bush',
			'Brightwater',
			'Shiel Hill',
			'Brockville',
			'Oamaru',
			'Pahiatua',
			'Bucklands Beach',
			'Redwoodtown',
			'Raupunga',
			'Paraparaumu',
			'Paihia',
			'North Shore',
			'Clifton',
			'Maryhill',
			'Bishopdale',
			'Mairehau',
			'Lynmouth',
			'Otara',
			'Birkenhead',
			'Freemans Bay',
			'Panmure',
			'Enner Glynn',
			'Snells Beach',
			'Stokes Valley',
			'Papakowhai',
			'Stoke',
			'Pukekohe East',
			'Hamurana',
			'Colville',
			'Christchurch',
			'Atawhai',
			'Belmont',
			'Rangiora',
			'Tikipunga',
			'Farm Cove',
			'New Lynn',
			'Oranga',
			'Island Bay',
			'Melrose',
			'Dannevirke',
			'Temple View',
			'Woodridge',
			'Whangarei Heads',
			'Whakarongo',
			'Taneatua',
			'Silverstream',
			'Richmond',
			'Rahotu',
			'Ngaio',
			'Mornington',
			'Maungatapere',
			'Mangatainoka',
			'Halswell',
			'Foxton Beach',
			'Dunedin',
			'Drury',
			'Awakeri',
			'Tauranga',
			'Saint Kilda',
			'Sawyers Bay',
			'Cashmere',
			'Vogeltown',
			'Raumanga',
			'Morningside',
			'Botany Downs',
			'Cockle Bay',
			'Mellons Bay',
			'Te Atatu South',
			'Days Bay',
			'Woburn',
			'Crofton Downs',
			'Aidanfield',
			'Warkworth',
			'Wairau Valley',
			'Ruawai',
			'Roslyn',
			'Oratia',
			'Edgecumbe',
			'Clevedon',
			'Castlepoint',
			'Bell Block',
			'Beachlands',
			'South Dunedin',
			'Opoho',
			'Hillsborough',
			'Inglewood',
			'Wattle Downs',
			'Pakuranga',
			'Eden Terrace',
			'Houghton Bay',
			'Homedale',
			'Whitby',
			'Boulcott',
			'Whanganui',
			'Waitakere',
			'Waikawa',
			'Renwick',
			'Ramarama',
			'Porirua',
			'Pokeno',
			'Pleasant Point',
			'Parakai',
			'Manutuke',
			'Gisborne',
			'Winton',
			'Waitara',
			'Riccarton',
			'Waimairi Beach',
			'Hoon Hay',
			'Lyttelton',
			'Welbourn',
			'Merrilands',
			'Ruakaka',
			'Milford',
			'Hillcrest',
			'Te Papapa',
			'Royal Oak',
			'West Harbour',
			'Woodlands Park',
			'Normandale',
			'Wellsford',
			'Opua',
			'Ngongotaha',
			'Muriwai Beach',
			'Katikati',
			'Hicks Bay',
			'Amberley',
			'Mataura',
			'Pine Hill',
			'Saint Martins',
			'Western Heights',
			'Waitangirua',
			'Te Aro',
			'Washdyke',
			'Heretaunga',
			'Bulls',
			'Westport',
			'Arrowtown',
			'Maori Hill',
			'Westown',
			'Forrest Hill',
			'Tamaki',
			'Paparangi',
			'Mahora',
			'Raureka',
			'Wallaceville',
			'Paremoremo',
			'Papatowai',
			'Otumoetai',
			'Opaheke',
			'Maramarua',
			'Kaharoa',
			'Howick',
			'Twizel',
			'Somerfield',
			'Carterton',
			'Lynfield',
			'Glendene',
			'Ostend',
			'Mangapapa',
			'Picton',
			'Parkway',
			'Arakura',
			'Waharoa',
			'Tokoroa',
			'Te Anau',
			'Jerusalem',
			'Patea',
			'Eastbourne',
			'Greymouth',
			'Bluff',
			'Port Chalmers',
			'Caversham',
			'Avonside',
			'Camborne',
			'Cloverlea',
			'Kelston',
			'Waterloo',
			'West Eyreton',
			'Taipa',
			'Pauatahanui',
			'Normanby',
			'Nelson',
			'Methven',
			'Leigh',
			'Homai',
			'Avondale',
			'Waipawa',
			'Opotiki',
			'Waitangi',
			'Hawthorndale',
			'Cromwell',
			'Waverley',
			'Spotswood',
			'Roslyn',
			'Helensville',
			'Manurewa East',
			'Saint Johns',
			'Meadowbank',
			'Whataupoko',
			'Sunnyhills',
			'Strowan',
			'Timaru',
			'Palmerston North',
			'Loburn',
			'Bay View',
			'Mission Bay',
			'Shirley',
			'Sunnyvale',
			'Birkdale',
			'Ellerslie',
			'Westmere',
			'McLaren Park',
			'Tamarau',
			'Solway',
			'Monaco',
			'Taita',
			'Maungaraki',
		],
	},
	Nicaragua: {
		country: 'Nicaragua',
		cities: [
			'El Ayote',
			'Belén',
			'Waslala',
			'San Rafael del Norte',
			'Greytown',
			'San Jorge',
			'Ocotal',
			'Matagalpa',
			'Juigalpa',
			'Jinotepe',
			'El Rosario',
			'Totogalpa',
			'Santo Tomás del Norte',
			'Santa María',
			'San Miguelito',
			'San Juan del Sur',
			'Nueva Guinea',
			'Morrito',
			'Waspán',
			'Waslala',
			'San Marcos',
			'San Francisco Libre',
			'Quezalguaque',
			'Puerto Morazán',
			'Muelle de los Bueyes',
			'Las Sabanas',
			'La Concepción',
			'Villa El Carmen',
			'Altagracia',
			'Somotillo',
			'San José de los Remates',
			'La Paz Centro',
			'El Crucero',
			'Niquinohomo',
			'Nandasmo',
			'Pearl Lagoon',
			'Chinandega',
			'La Paz de Carazo',
			'Camoapa',
			'Acoyapa',
			'Tipitapa',
			'Siuna',
			'Santa Teresa',
			'San Ramón',
			'Nindirí',
			'Masatepe',
			'Karawala',
			'El Sauce',
			'Bocana de Paiwas',
			'Villanueva',
			'LLano de La Cruz',
			'Posoltega',
			'Mulukukú',
			'Mateare',
			'Jalapa',
			'Esquipulas',
			'Telica',
			'San José de Bocay',
			'San Carlos',
			'Prinzapolka',
			'Macuelizo',
			'Estelí',
			'El Rosario',
			'El Jicaral',
			'El Jícaro',
			'Bonanza',
			'Villa Sandino',
			'Santa Rosa del Peñón',
			'Santa Lucía',
			'San Fernando',
			'Masachapa',
			'Ciudad Darío',
			'Quilalí',
			'Potosí',
			'Malpaisillo',
			'Diriá',
			'Buenos Aires',
			'Terrabona',
			'Puerto Cabezas',
			'La Trinidad',
			'Chichigalpa',
			'San Rafael del Sur',
			'San Juan de Río Coco',
			'San Dionisio',
			'Murra',
			'Granada',
			'Dolores',
			'Condega',
			'El Coral',
			'Rama',
			'Matiguás',
			'Larreynaga',
			'Diriamba',
			'Corinto',
			'Comalapa',
			'Ciudad Antigua',
			'Boaco',
			'Wiwilí',
			'Sébaco',
			'Santo Domingo',
			'San Juan de Limay',
			'Great Corn Island',
			'Santa Maía de Pantasma',
			'La Dalia',
			'Ticuantepe',
			'Somoto',
			'San Pedro del Norte',
			'San Juan de Oriente',
			'San José de Cusmapa',
			'Jinotega',
			'Cuapa',
			'Cárdenas',
			'Achuapa',
			'San Nicolás',
			'San Isidro',
			'El Cuá',
			'El Tortuguero',
			'La Libertad',
			'Kukra Hill',
			'Valle San Francisco',
			'Tola',
			'Telpaneca',
			'Moyogalpa',
			'Teustepe',
			'Santa Teresa',
			'Diriomo',
			'Ciudad Sandino',
			'Wiwilí de Jinotega',
			'El Ayote',
			'Santo Tomás',
			'La Cruz de Río Grande',
			'La Concordia',
			'San Francisco del Norte',
			'Río Blanco',
			'Mozonte',
			'La Conquista',
			'Rancho Grande',
			'Yalagüina',
			'Tonalá',
			'Dipilto',
			'Cinco Pinos',
			'Las Praderas',
			'Tisma',
			'Pueblo Nuevo',
			'Managua',
			'San Sebastián de Yalí',
			'Nagarote',
			'El Almendro',
			'Nandaime',
			'Boca de Sábalos',
			'Bluefields',
			'San Pedro de Lóvago',
			'San Lucas',
			'San Lorenzo',
			'Muy Muy',
			'Masaya',
			'León',
			'Jiquilillo',
			'El Viejo',
			'El Realejo',
			'Catarina',
			'Rivas',
			'Palacagüina',
		],
	},
	Niger: {
		country: 'Niger',
		cities: [
			'Ouallam',
			'Maradi',
			'Madaoua',
			'Arlit',
			'Mayahi',
			'Tibiri',
			'Niamey',
			'Magaria',
			'Guidan Roumdji',
			'Gaya',
			'Bouza',
			'Tillabéri',
			'Nguigmi',
			'Matamey',
			'Tessaoua',
			'Kollo',
			'Dogondoutchi',
			'Ayorou',
			'Alaghsas',
			'Birni N Konni',
			'Bilma',
			'Tanout',
			'Say',
			'Maïné Soroa',
			'Diffa',
			'Agadez',
			'Téra',
			'Illéla',
			'Aguié',
			'Loga',
			'Birnin Gaouré',
			'Madarounfa',
			'Tchirozérine',
			'Keïta',
			'Filingué',
			'Gouré',
			'Kantché',
			'Abalak',
			'Dakoro',
			'Tchintabaraden',
			'Mirriah',
			'Zinder',
			'Tahoua',
			'Dosso',
		],
	},
	Nigeria: {
		country: 'Nigeria',
		cities: [
			'Zonkwa',
			'Tambuwal',
			'Surulere',
			'Rogo',
			'Ogbere',
			'Odoro Ikpe',
			'Ode',
			'Ndeaboh',
			'Lau',
			'Kwatarkwashi',
			'Kutigi',
			'Kusheriki',
			'Koton-Karfe',
			'Kauru',
			'Kano',
			'Jajimaji',
			'Ita-Ogbolu',
			'Iseyin',
			'Ile-Oluji',
			'Ikot Ibritam',
			'Ikenne',
			'Ifo',
			'Gwio Kura',
			'Gwagwalada',
			'Gudumbali',
			'Fika',
			'Eket',
			'Donga',
			'Doma',
			'Bwari',
			'Birnin Gwari',
			'Batagarawa',
			'Arochukwu',
			'Abagana',
			'Isiala Oboro',
			'Eti-Osa',
			'Warri',
			'Ungogo',
			'Umunede',
			'Tokombere',
			'Sankwala',
			'Patani',
			'Oye-Ekiti',
			'Oji River',
			'Ogwashi-Uku',
			'Odot',
			'Nkwerre',
			'Ningi',
			'Moniya',
			'Miringa',
			'Matazu',
			'Kiri Kasamma',
			'Kari',
			'Kamba',
			'Kalgo',
			'Kagara',
			'Issele-Uku',
			'Isiokolo',
			'Ikot Ekpene',
			'Ikire',
			'Ihiala',
			'Dass',
			'Ugbokpo',
			'Bomadi',
			'Bara',
			'Araromi-Opin',
			'Akko',
			'Ayetoro',
			'Agulu',
			'Ago-Are',
			'Ado-Odo',
			'Adani',
			'Umuguma',
			'Rumuodomaya',
			'Zadawa',
			'Yenagoa',
			'Wudil',
			'Urualla',
			'Umuahia',
			'Paiko',
			'Omu-Aran',
			'Ogbomoso',
			'Makoko',
			'Lalupon',
			'Karim Lamido',
			'Jimeta',
			'Ikoyi-Ile',
			'Ikot Nakanda',
			'Ikare',
			'Iguobazuwa',
			'Ibeto',
			'Hong',
			'Tsafe',
			'Bunkure',
			'Bukkuyum',
			'Buguma',
			'Bodinga',
			'Auchi',
			'Amagunze',
			'Aliero',
			'Ajalli',
			'Ojota',
			'Yana',
			'Umumma',
			'Oke-Ikpe',
			'Zungeru',
			'Sule Tankarkar',
			'Silame',
			'Onyedega',
			'Offa',
			'Nnewi',
			'Ngo',
			'Maru',
			'Maiyama',
			'Kware',
			'Kosubosu',
			'Karkarna',
			'Iyara',
			'Ijebu-Ode',
			'Gwandu',
			'Guri',
			'Gabarin',
			'Enwang',
			'Ejigbo',
			'Eberi',
			'Daura',
			'Birnin Kebbi',
			'Agaie',
			'Afam',
			'Achalla',
			'Abua',
			'Aboh',
			'Abaji',
			'Naka',
			'Birnin Magaji',
			'Aboh',
			'Ikot Akpa Nkuk',
			'Eyofin',
			'Zalanga',
			'Yandev',
			'Wamba',
			'Udi',
			'Shelleng',
			'Rijau',
			'Ode-Ekiti',
			'Obudu',
			'Obolo-Eke (1)',
			'Mopa',
			'Mashi',
			'Kwoi',
			'Kumagunnam',
			'Lissam',
			'Kabba',
			'Iwere-Ile',
			'Itu',
			'Ipetumodu',
			'Ikotun',
			'Ikom',
			'Ikeja',
			'Idogbo',
			'Ibokun',
			'Goniri',
			'Ganye',
			'Funtua',
			'Malam Madori',
			'Dawakin Tofa',
			'Adoru',
			'Adogo',
			'Ezillo',
			'Gawu Babangida',
			'Gagarawa',
			'Degema',
			'Deba',
			'Bornu Yassu',
			'Boje',
			'Birniwa',
			'Atani',
			'Amper',
			'Abuja',
			'Aba',
			'Oghara',
			'Ugbodo',
			'Isinweke',
			'Tunkus',
			'Zing',
			'Zango',
			'Yabo',
			'Wurno',
			'Ukpo',
			'Tudun Wada',
			'Sokoto',
			'Pankshin',
			'Omoku',
			'Okpoga',
			'Oke Mesi',
			'Oguma',
			'Odukpani',
			'Nafada',
			'Michika',
			'Kaugama',
			'Kafur',
			'Isua',
			'Iloffa',
			'Igbo-Ora',
			'Gwoza',
			'Gwaram',
			'Gbongan',
			'Ezza-Ohu',
			'Enagi',
			'Eha Amufu',
			'Ebute-Metta',
			'Dange',
			'Damaturu',
			'Chibok',
			'Bogoro',
			'Bakura',
			'Awo',
			'Akwete',
			'Agbabu',
			'Egbema',
			'Omoba',
			'Yashikira',
			'Vom',
			'Umuneke-Ngor',
			'Tsanyawa',
			'Tafawa Balewa',
			'Rimin Gado',
			'Rimi',
			'Port Harcourt',
			'Okwe',
			'Oko Ita',
			'Oke-Oyi',
			'Ogu',
			'Maradun',
			'Mallam Sidi',
			'Kura',
			'Kumbotso',
			'Kajuru',
			'Kabo',
			'Lajere',
			'Jada',
			'Ilobu',
			'Ilesa',
			'Idu',
			'Yelwa',
			'Yamrat',
			'Wukari',
			'Wawa',
			'Shendam',
			'Patigi',
			'Orita-Eruwa',
			'Oke-Agbe',
			'Okada',
			'Oka',
			'Ogidi',
			'Ogaminana',
			'Odo-Ere',
			'Ochobo',
			'Makurdi',
			'Langtang',
			'Lame',
			'Kibiya',
			'Kangiwa',
			'Jega',
			'Ijebu-Jesa',
			'Igbara-Odo',
			'Hunkuyi',
			'Ukata',
			'Talata Mafara',
			'Bunza',
			'Saminaka',
			'Owerri',
			'Otuocha',
			'Okehi',
			'Oju',
			'Obonoma',
			'Nguzu Edda',
			'Kwaya Kusar',
			'Kafin Hausa',
			'Iwo',
			'Itu',
			'Isieke',
			'Ijebu-Igbo',
			'Igbekebo',
			'Gwasoro',
			'Etinan',
			'Charanchi',
			'Balle',
			'Bakori',
			'Askira',
			'Asaba',
			'Akwanga',
			'Aguata',
			'Nasko',
			'Atan',
			'Akpafa',
			'Goronyo',
			'Gora',
			'Gembu',
			'Gakem',
			'Faskari',
			'Egbeda',
			'Dindima',
			'Dankalwa',
			'Bassa',
			'Anka',
			'Amaigbo',
			'Ajaawa',
			'Ajegunle',
			'Degema Hulk',
			'Obangede',
			'Urue Offong',
			'Kanamma',
			'Saakpenwa',
			'Uselu',
			'Uromi',
			'Taura',
			'Shaffa',
			'Sankera',
			'Riyom',
			'Rano',
			'Owa-Oyibu',
			'Oron',
			'Opobo',
			'Oleh',
			'Okene',
			'Ogoja',
			'Obubra',
			'Nsukka',
			'Kaduna',
			'Illushi',
			'Ikorodu',
			'Hadejia',
			'Gwaram',
			'Gulma',
			'Epe',
			'Ehor',
			'Ebute Ikorodu',
			'Danja',
			'Dan Gora',
			'Bichi',
			'Benin City',
			'Bama',
			'Azare',
			'Anaku',
			'Albasu',
			'Afikpo',
			'Abudu',
			'Buni Yadi',
			'Lessel',
			'Festac Town',
			'Sabon Wuse',
			'Upenekang',
			'Wasagu',
			'Sagbama',
			'Safana',
			'Sade',
			'Ota',
			'Ohafia-Ifigh',
			'Mberubu',
			'Mbalano',
			'Maiha',
			'Maigatari',
			'Kwolla',
			'Kujama',
			'Keana',
			'Angware',
			'Ipoti',
			'Afaha Ikot Ebak',
			'Ido',
			'Ibi',
			'Gboko',
			'Gassol',
			'Gashua',
			'Enugu-Ezike',
			'Dutse',
			'Dandume',
			'Dakingari',
			'Bonny',
			'Sofo-Birnin-Gwari',
			'Beli',
			'Bebeji',
			'Obagaji',
			'Ahoada',
			'Afuze',
			'Abat',
			'Onueke',
			'Nwaorieubi',
			'Nnenasa',
			'Toto',
			'Shagari',
			'Owu-Isin',
			'Osu',
			'Onitsha',
			'Okuta',
			'Oguta',
			'Neni',
			'Mubi',
			'Lekki',
			'Kurfi',
			'Kebbe',
			'Kazaure',
			'Katcha',
			'Kaita',
			'Isanlu',
			'Iperindo',
			'Ikirun',
			'Ikara',
			'Ifaki',
			'Gumel',
			'Giade',
			'Dawakin Kudu',
			'Dadiya',
			'Calabar',
			'Bokkos',
			'Babban Gida',
			'Ogbia',
			'Zaria',
			'Yola',
			'Yajiwa',
			'Uquo',
			'Uba',
			'Tegina',
			'Sumaila',
			'Ore',
			'Olupona',
			'Okpoma',
			'Nkpor',
			'Imeko',
			'Madagali',
			'Warji',
			'Kafarati',
			'Ode-Irele',
			'Igede-Ekiti',
			'Garki',
			'Gantsa',
			'Ete',
			'Umunze',
			'Enugu-Ukwu',
			'Effium',
			'Biliri',
			'Bara',
			'Bagudo',
			'Babura',
			'Auna',
			'Aramoko-Ekiti',
			'Ajingi',
			'Agbor',
			'Adikpo',
			'Mashegu',
			'Yuli',
			'Wuyo',
			'Wamako',
			'Wagini',
			'Umulona',
			'Uga',
			'Shagamu',
			'Rann',
			'Owo',
			'Otan Ayegbaju',
			'Odogbolu',
			'Obi',
			'New Shagunnu',
			'Mushin',
			'Mangu',
			'Lamurde',
			'Kwale',
			'Kusada',
			'Jikamshi',
			'Ifetedo',
			'Gujba',
			'Gidan Madi',
			'Garko',
			'Dikwa',
			'Dan Musa',
			'Damasak',
			'Biu',
			'Birnin Kudu',
			'Baro',
			'Baissa',
			'Apomu',
			'Alkaleri',
			'Aku',
			'Akamkpa',
			'Agenebode',
			'Urua Inyang',
			'Sabon Garin Nangere',
			'Chakwama',
			'Tofa',
			'Riti',
			'Panyam',
			'Ozubulu',
			'Oke Ila',
			'Bolorunduro',
			'Minna',
			'Mallammaduri',
			'Makarfi',
			'Auyo',
			'Jibia',
			'Jebba',
			'Isu',
			'Iragbiji',
			'Ilare',
			'Ifon',
			'Giwa',
			'Gada',
			'Ezzamgbo',
			'Benisheikh',
			'Bagwai',
			'Badeggi',
			'Ajaka',
			'Afaha Offiong',
			'Ifako',
			'Baap',
			'Kwal',
			'Boh',
			'Tureta',
			'Shanono',
			'Samamiya',
			'Omuo-Ekiti',
			'Okigwe',
			'Odeda',
			'Obollo-Afor',
			'Monguno',
			'Misau',
			'Maigana',
			'Maiduguri',
			'Madala',
			'Katsina-Ala',
			'Katagum',
			'Kaiama',
			'Jobele',
			'Itas',
			'Ise-Ekiti',
			'Igumale',
			'Igbor',
			'Igabi',
			'Guyuk',
			'Gezawa',
			'Gamawa',
			'Esuk Oron',
			'Effraya',
			'Buga',
			'Twon-Brass',
			'Awgu',
			'Apapa',
			'Alapa',
			'Akodo',
			'Ado-Ekiti',
			'Abocho',
			'Abak',
			'Khaddamari',
			'Ubiaja',
			'Suya',
			'Owode',
			'Oporoma',
			'Obiaruku',
			'Nung Udoe',
			'Nguru',
			'Malumfashi',
			'Lemu',
			'Kuta',
			'Kuje',
			'Kisi',
			'Kiru',
			'Kende',
			'Kafin Madaki',
			'Jama’are',
			'Idi-Ayunre',
			'Gwiwa',
			'Effurun',
			'Burumburum',
			'Briyel',
			'Besse',
			'Badagry',
			'Babana',
			'Awo-Idemili',
			'Ajaokuta',
			'Akpet Central',
			'Abigi',
			'Irrua',
			'Osisioma',
			'Nchia',
			'Vandeikya',
			'Utu Etim Ekpo',
			'Sabuwa',
			'Ringim',
			'Ribah',
			'Otor-Udu',
			'Orlu',
			'Isiokpo',
			'Nasarawa Egon',
			'Karaye',
			'Kafanchan',
			'Ipokia',
			'Ile-Ogbo',
			'Ilawe-Ekiti',
			'Ikoyi',
			'Igbeti',
			'Igbara-Oke',
			'Gubio',
			'Girei',
			'Gbajimba',
			'Elele',
			'Ejirin',
			'Demsa',
			'Dapchi',
			'Boju',
			'Baure',
			'Tse-Agberagba',
			'Igueben',
			'Bangi',
			'Yanda Bayo',
			'Talasse',
			'Takum',
			'Shani',
			'Serti',
			'Sapele',
			'Sandamu',
			'Roni',
			'Rabah',
			'Otu',
			'Osogbo',
			'Opi',
			'Okpo',
			'Numan',
			'Nembe',
			'Nasarawa',
			'Musawa',
			'Mayo-Belwa',
			'Madara',
			'Kankia',
			'Kankara',
			'Kaiama',
			'Kagoro',
			'Ingawa',
			'Illela',
			'Idiroko',
			'Dutsin-Ma',
			'Dambam',
			'Bununu',
			'Bende',
			'Akwukwu-Igbo',
			'Ajasse Ipo',
			'Iyana-Ofa',
			'Obiozara',
			'Lagos Island',
			'Uruobo-Okija',
			'Siluko',
			'Somolu',
			'Oyo',
			'Otu-Jeremi',
			'Orodo',
			'Ngurore',
			'New Bussa',
			'Miga',
			'Mgbidi',
			'Marte',
			'Lapai',
			'Kiyawa',
			'Kirfi',
			'Jahun',
			'Ijebu-Ife',
			'Gamboru',
			'Billiri',
			'Azare',
			'Anchau',
			'Akure',
			'Akanran',
			'Abejukolo',
			'Onuebonyi Echara',
			'Sunkani',
			'Gwantu',
			'Fiditi',
			'Emuoha',
			'Argungu',
			'Amassoma',
			'Agbani',
			'Afon',
			'Ibiaku Ntok Okpo',
			'Akinima',
			'Nkwo Nike',
			'Wushishi',
			'Ugba',
			'Toro',
			'Share',
			'Sauri',
			'Pantisawa',
			'Otukpa',
			'Afor-Oru',
			'Okeho',
			'Ojoto',
			'Mokwa',
			'Machina',
			'Makera',
			'Maikunkele',
			'Lere',
			'Lagos',
			'Kagarko',
			'Iye-Ekiti',
			'Ilorin',
			'Ikole-Ekiti',
			'Ikere-Ekiti',
			'Icheu',
			'Ugwolawo',
			'Gusau',
			'Garko',
			'Fugar',
			'Ekpoma',
			'Egbe',
			'Bode Saadu',
			'Binji',
			'Bida',
			'Barkin Ladi',
			'Bugana',
			'Awka',
			'Augie',
			'Amaigbo',
			'Aliade',
			'Ayete',
			'Agwara',
			'Abraka',
			'Kaura',
			'Zuru',
			'Tede',
			'Shinkafi',
			'Runka',
			'Otun-Ekiti',
			'Nto Edino',
			'Moriki',
			'Mando',
			'Mahuta',
			'Lokoja',
			'Kpor',
			'Koko',
			'Kaura Namoda',
			'Katsina',
			'Kaltungo',
			'Kachia',
			'Isara',
			'Isa',
			'Iboko',
			'Ugep',
			'Suleja',
			'Okrika',
			'Okopedi',
			'Mkpat Enin',
			'Mafa',
			'Lafia',
			'Jalingo',
			'Obarike-Ito',
			'Iresa-Adu',
			'Iperu',
			'Ilemona',
			'Ijero-Ekiti',
			'Idah',
			'Holma',
			'Gombe',
			'Gella',
			'Fufore',
			'Ekeremor',
			'Ede',
			'Darazo',
			'Dambatta',
			'Mallam Fatori',
			'Batsari',
			'Isiaka',
			'Umuelemai',
			'Toungo',
			'Takai',
			'Song',
			'Sarkin Pawa',
			'Sakaba',
			'Ririwai',
			'Oyan',
			'Oshodi',
			'Ogurugu',
			'Wara',
			'Mutum Biyu',
			'Mani',
			'Magumeri',
			'Jos',
			'Itori',
			'Ikang',
			'Ile-Ife',
			'Ibadan',
			'Gombi',
			'Gaya',
			'Gandi',
			'Fufu',
			'Ebem Ohafia',
			'Bajoga',
			'Ake-Eze',
			'Ago-Amodu',
			'Okuku',
			'Karu',
			'Ughelli',
			'Sabon Birni',
			'Modakeke',
			'Mai’Adua',
			'Madobi',
			'Lere',
			'Lafiagi',
			'Jakusko',
			'Ila Orangun',
			'Igbo-Ukwu',
			'Gorgoram',
			'Emure-Ekiti',
			'Dutsen Wai',
			'Daura',
			'Dankama',
			'Damboa',
			'Burutu',
			'Bindawa',
			'Awe',
			'Akankpa',
			'Abeokuta',
			'Zurmi',
			'Zakirai',
			'Uyo',
			'Shanga',
			'Okoroete',
			'Minjibir',
			'Kukawa',
			'Konkwesso',
			'Isanlu-Itedoijowa',
			'Inisa',
			'Ilaro',
			'Igbokoda',
			'Igboho',
			'Igarra',
			'Ibagwa-Aka',
			'Hinna',
			'Gwarzo',
			'Buruku',
			'Gajiram',
			'Efon-Alaaye',
			'Dukku',
			'Dekina',
			'Damagum',
			'Bungudu',
			'Bori',
			'Bode Osi',
			'Agege',
			'Abonnema',
			'Okpuala-Ngwa',
			'Nkwoagu Isuochi',
			'Gummi',
			'Gulak',
			'Geidam',
			'Garun Malam',
			'Garaku',
			'Eruwa',
			'Dutsi',
			'Duku',
			'Dengi',
			'Bin Yauri',
			'Bena',
			'Bauchi',
			'Ankpa',
			'Abuochiche',
			'Aguobu-Owa',
			'Mabudi',
			'Yelwa',
			'Wase',
			'Ozoro',
			'Ukpor',
			'Soba',
			'Potiskum',
			'Orerokpe',
			'Ondo',
			'Nteje',
			'Kunchi',
			'Keffi',
			'Itigidi',
			'Ifon',
			'Idanre',
			'Zaki Biam',
			'Wannune',
			'Saki',
			'Sabongida-Ora',
			'Ruma',
			'Pindiga',
			'Ikot Abasi',
			'Ogbede',
			'Nzam',
			'Ngala',
			'Kwali',
			'Kumo',
			'Kontagora',
			'Konduga',
			'Ikem',
			'Iho',
			'Ido-Ekiti',
			'Gwadabawa',
			'Enugu',
			'Disina',
			'Dikenafai',
			'Dan Sadau',
			'Dabai',
			'Bukuru',
			'Bokani',
			'Boi',
			'Umundugba',
			'Abakaliki',
			'Koguna',
			'Ikot Edibon',
		],
	},
	Niue: { country: 'Niue', cities: ['Alofi'] },
	'Norfolk Island': { country: 'Norfolk Island', cities: ['Kingston'] },
	'Northern Mariana Islands': { country: 'Northern Mariana Islands', cities: ['San Jose Village', 'Saipan'] },
	Norway: {
		country: 'Norway',
		cities: [
			'Berlevåg',
			'Strai',
			'Vigrestad',
			'Tingvatn',
			'Steinkjer',
			'Skudeneshavn',
			'Sæveland',
			'Noresund',
			'Nesttun',
			'Lervik',
			'Grua',
			'Gravdal',
			'Dovre',
			'Myre',
			'Vik',
			'Hol',
			'Ensjø',
			'Lyngseidet',
			'Burfjord',
			'Tveit',
			'Syfteland',
			'Svelgen',
			'Storebø',
			'Stange',
			'Sogndalsfjøra',
			'Sistranda',
			'Sande',
			'Røst',
			'Roa',
			'Rena',
			'Rensvik',
			'Nodeland',
			'Mandal',
			'Kongsvinger',
			'Kolbotn',
			'Knappskog',
			'Hjelset',
			'Geilo',
			'Blakstad',
			'Aas',
			'Vormedal',
			'Leknes',
			'Kjøllefjord',
			'Ulvik',
			'Risør',
			'Reine',
			'Rakkestad',
			'Notodden',
			'Løten',
			'Leland',
			'Halden',
			'Frekhaug',
			'Fredrikstad',
			'Borkenes',
			'Aure',
			'Kirkebygda',
			'Ringebu',
			'Valderøy',
			'Elnesvågen',
			'Vikeså',
			'Varhaug',
			'Tomter',
			'Stavanger',
			'Solfjellsjøen',
			'Sjøvegan',
			'Rosendal',
			'Porsgrunn',
			'Mo i Rana',
			'Gvarv',
			'Grong',
			'Gratangen',
			'Florø',
			'Brønnøysund',
			'Berkåk',
			'Berger',
			'Årnes',
			'Arendal',
			'Å i Åfjord',
			'Bismo',
			'Gaupne',
			'Sortland',
			'Skogn',
			'Ski',
			'Ramberg',
			'Jevnaker',
			'Flekkefjord',
			'Evenskjer',
			'Bygland',
			'Åmot',
			'Kinsarvik',
			'Tennevoll',
			'Tofte',
			'Sveio',
			'Stryn',
			'Skien',
			'Moss',
			'Moelv',
			'Melhus',
			'Larvik',
			'Kviteseid',
			'Kristiansund',
			'Kongsberg',
			'Hauknes',
			'Flateby',
			'Eidsvoll',
			'Dokka',
			'Ørsta',
			'Frydenberg',
			'Bergset',
			'Straumen',
			'Røros',
			'Krokstadøra',
			'Jessheim',
			'Bodø',
			'Austrheim',
			'Ålgård',
			'Vedavågen',
			'Støren',
			'Vinstra',
			'Vikersund',
			'Tynset',
			'Skarnes',
			'Sauda',
			'Røyken',
			'Rindal',
			'Ølen',
			'Moldjord',
			'Hov',
			'Gladstad',
			'Dalen',
			'Brumunddal',
			'Brekstad',
			'Åneby',
			'Ålesund',
			'Sauland',
			'Klæbu',
			'Bjørnevatn',
			'Vennesla',
			'Vågåmo',
			'Tysvær',
			'Tvedestrand',
			'Seljord',
			'Sarpsborg',
			'Ranemsletta',
			'Lunde',
			'Korgen',
			'Kolvereid',
			'Heggenes',
			'Hardbakke',
			'Granvin',
			'Botngård',
			'Bogen',
			'Bjerkvik',
			'Birkeland',
			'Ryggebyen',
			'Stjørdal',
			'Teigebyen',
			'Steinsdalen',
			'Hattfjelldal',
			'Husøya',
			'Sørreisa',
			'Vardø',
			'Stavern',
			'Skjønhaug',
			'Osøyro',
			'Midsund',
			'Meråker',
			'Lonevåg',
			'Lillehammer',
			'Kyrksæterøra',
			'Hauge i Dalane',
			'Finnsnes',
			'Bagn',
			'Åndalsnes',
			'Vestnes',
			'Lierbyen',
			'Børsa',
			'Svolvær',
			'Storsteinnes',
			'Skotterud',
			'Skoger',
			'Råholt',
			'Prestfoss',
			'Masfjorden',
			'Løpsmarka',
			'Lærdalsøyri',
			'Knarvik',
			'Hvittingfoss',
			'Høyanger',
			'Hansnes',
			'Eidfjord',
			'Svortland',
			'Nesoddtangen',
			'Kleppe',
			'Lurøy',
			'Tonstad',
			'Tjøme',
			'Rubbestadneset',
			'Nordfjordeid',
			'Naustdal',
			'Namsskogan',
			'Myra',
			'Moi',
			'Kopervik',
			'Hamar',
			'Gjerstad',
			'Fetsund',
			'Kirkebygda',
			'Lampeland',
			'Revetal',
			'Straume',
			'Vadsø',
			'Våler',
			'Ulefoss',
			'Skui',
			'Siljan',
			'Myre',
			'Malvik',
			'Løding',
			'Lillestrøm',
			'Lillesand',
			'Knappstad',
			'Jondal',
			'Holmestrand',
			'Dale',
			'Barkåker',
			'Ask',
			'Tingvoll',
			'Elvestad',
			'Høylandet',
			'Honningsvåg',
			'Tomra',
			'Tangvall',
			'Sola',
			'Sandnessjøen',
			'Nærbø',
			'Mjøndalen',
			'Leirvik',
			'Hommelvik',
			'Hemnesberget',
			'Haugesund',
			'Harstad',
			'Glomfjord',
			'Folldal',
			'Dombås',
			'Dale',
			'Åros',
			'Vik',
			'Sykkylven',
			'Falkhytta',
			'Breivikbotn',
			'Alta',
			'Rypefjord',
			'Vik',
			'Vigeland',
			'Vanse',
			'Tønsberg',
			'Straumen',
			'Stjørdalshalsen',
			'Skoppum',
			'Skaun',
			'Setermoen',
			'Sandnes',
			'Randaberg',
			'Os',
			'Mosterhamn',
			'Moen',
			'Langevåg',
			'Langesund',
			'Kabelvåg',
			'Fillan',
			'Evje',
			'Bjørkelangen',
			'Åmli',
			'Lesja',
			'Hjelmelandsvågen',
			'Leinesfjorden',
			'Hatteng',
			'Tana bru',
			'Kárášjohka',
			'Hammerfest',
			'Vestbygd',
			'Spetalen',
			'Reinsvoll',
			'Otta',
			'Ørnes',
			'Karlshus',
			'Hønefoss',
			'Frogner',
			'Auli',
			'Årnes',
			'Ål',
			'Stordal',
			'Mehamn',
			'Treungen',
			'Snåase',
			'Sjøholt',
			'Sand',
			'Rotnes',
			'Mysen',
			'Fossbergom',
			'Kragerø',
			'Kleppestø',
			'Judaberg',
			'Herre',
			'Espeland',
			'Indre Arna',
			'Åkrehamn',
			'Frosta',
			'Aurlandsvangen',
			'Bruhagen',
			'Skjervøy',
			'Olderdalen',
			'Voss',
			'Vikøyri',
			'Tau',
			'Skjærhalden',
			'Sandvika',
			'Rollag',
			'Øystese',
			'Narvik',
			'Maura',
			'Lyngdal',
			'Kristiansand',
			'Hermansverk',
			'Farsund',
			'Fagernes',
			'Eidsvåg',
			'Drammen',
			'Oppeid',
			'Hommersåk',
			'Neskollen',
			'Sogndal',
			'Ulsteinvik weather pws station',
			'Storslett',
			'Vestby',
			'Vatne',
			'Ulsteinvik',
			'Sørumsand',
			'Sandvika',
			'Sandefjord',
			'Rørvik',
			'Rjukan',
			'Raufoss',
			'Oppdal',
			'Odda',
			'Kirkenær',
			'Grimstad',
			'Gjøvik',
			'Fossby',
			'Elverum',
			'Bruflat',
			'Aursmoen',
			'Segalstad bru',
			'Fiskå',
			'Nordstranda',
			'Kautokeino',
			'Svelvik',
			'Ørje',
			'Molde',
			'Fyresdal',
			'Lena',
			'Kløfta',
			'Inndyr',
			'Hovden',
			'Drøbak',
			'Batnfjordsøra',
			'Askim',
			'Ålen',
			'Sjølyststranda',
			'Ytrebygda',
			'Sandsli',
			'Engerdal',
			'Borgheim',
			'Aksdal',
			'Hornindal',
			'Trondheim',
			'Sem',
			'Selvik',
			'Rygge',
			'Prestestranda',
			'Nesna',
			'Mosjøen',
			'Melsomvik',
			'Måløy',
			'Malm',
			'Lindås',
			'Liknes',
			'Hareid',
			'Justvik',
			'Fosnavåg',
			'Fedje',
			'Eivindvik',
			'Eike',
			'Bryne',
			'Meieribyen',
			'Fjerdingby',
			'Slidre',
			'Blakstad',
			'Ydstebøhamn',
			'Vuonnabahta',
			'Ávanuorri',
			'Skålevik',
			'Tromsø',
			'Stranda',
			'Orkanger',
			'Manger',
			'Leirsund',
			'Jørpeland',
			'Jaren',
			'Førde',
			'Fauske',
			'Ås',
			'Birketveit',
			'Kirkenes',
			'Ytre Arna',
			'Innbygda',
			'Tranby',
			'Sørland',
			'Rognan',
			'Oslo',
			'Lauvsnes',
			'Koppang',
			'Hemsedal',
			'Fitjar',
			'Fevik',
			'Etne',
			'Åsgårdstrand',
			'Ågotnes',
			'Fjellfoten',
			'Kjenn',
			'Sand',
			'Brøstadbotn',
			'Søgne',
			'Skreia',
			'Skodje',
			'Sandane',
			'Sætre',
			'Farnes',
			'Namsos',
			'Lundamo',
			'Lødingen',
			'Larkollen',
			'Hyllestad',
			'Hylkje',
			'Hagavik',
			'Gullhaug',
			'Brattvåg',
			'Bø',
			'Asker',
			'Alvdal',
			'Kyrkjebygda',
			'Silvalen',
			'Vågaholmen',
			'Trofors',
			'Tretten',
			'Terråk',
			'Sunndalsøra',
			'Stokke',
			'Røyrvik',
			'Lysaker',
			'Leknes',
			'Horten',
			'Hokksund',
			'Flå',
			'Fagerstrand',
			'Eide',
			'Egersund',
			'Årøysund',
			'Hurdal',
			'Flisa',
			'Rissa',
			'Lyefjell',
			'Båtsfjord',
			'Verdal',
			'Tolga',
			'Skjeberg',
			'Sagvåg',
			'Levanger',
			'Larsnes',
			'Evjen',
			'Vang',
			'Askvoll',
			'Mebonden',
			'Billingstad',
			'Øksfjord',
			'Lakselv',
			'Valle',
			'Uggdal',
			'Norheimsund',
			'Nesbyen',
			'Mo',
			'Isdalstø',
			'Hopen',
			'Hamnvik',
			'Bergen',
			'Balestrand',
			'Årdalstangen',
			'Rødberg',
			'Bokn',
			'Volda',
			'Vevelstad',
			'Utsira',
			'Tysse',
			'Tananger',
			'Strømmen',
			'Straume',
			'Stokmarknes',
			'Spydeberg',
			'Melbu',
			'Hundorp',
			'Gol',
			'Andenes',
			'Trøim',
			'Surnadal',
		],
	},
	Oman: {
		country: 'Oman',
		cities: [
			'Al Qābil',
			'Muscat',
			'Nizwá',
			'Bayt al ‘Awābī',
			'Yanqul',
			'Bawshar',
			'Al Buraymī',
			'Sur',
			'Khasab',
			'Bidbid',
			'Haymā’',
			'Badīyah',
			'Madḩā’ al Jadīdah',
			'Seeb',
			'Liwá',
			'Izkī',
			'Rustaq',
			'As Suwayq',
			'Shināş',
			'Al Khābūrah',
			'Şaḩam',
			'Bahlā’',
			'Sohar',
			'‘Ibrī',
			'Ibrā’',
			'Al Mazyūnah',
			'Şalālah',
			'Dib Dibba',
			'Barkā’',
			'Sufālat Samā’il',
			'Adam',
			'Oman Smart Future City',
		],
	},
	Pakistan: {
		country: 'Pakistan',
		cities: [
			'Ziarat',
			'Zhob',
			'Tandlianwala',
			'Rustam',
			'Nawabshah',
			'Musa Khel Bazar',
			'Muridke',
			'Miro Khan',
			'Chak One Hundred Twenty Nine Left',
			'Jhawarian',
			'Haripur',
			'Hafizabad',
			'Goth Garelo',
			'Dhoro Naro',
			'Dhanot',
			'Chamber',
			'Bahawalpur',
			'Khairpur Mir’s',
			'Toba Tek Singh',
			'Tank',
			'Sukheke Mandi',
			'Shabqadar',
			'Sarai Naurang',
			'Ratodero',
			'Peshawar',
			'Mitha Tiwana',
			'Kundian',
			'Kharan',
			'Jandiala Sher Khan',
			'Gwadar',
			'Daur',
			'Bakhri Ahmad Khan',
			'Badin',
			'Attock City',
			'Aman Garh',
			'Rahim Yar Khan',
			'Qila Abdullah',
			'Matli',
			'Kunri',
			'Karor',
			'Karaundi',
			'Kanganpur',
			'Fatehpur',
			'Daulatpur',
			'Daira Din Panah',
			'Bhit Shah',
			'Bhera',
			'Khadan Khak',
			'Barishal',
			'Amirabad',
			'Topi',
			'Tharu Shah',
			'Thamewali',
			'Naudero',
			'Naukot',
			'Mehrabpur',
			'Karak',
			'Khairpur Tamewah',
			'Keshupur',
			'Garh Maharaja',
			'Garhi Khairo',
			'Dokri',
			'Dijkot',
			'Charsadda',
			'Baffa',
			'Jhang City',
			'New Badah',
			'Malakwal City',
			'Uthal',
			'Talamba',
			'Shahdad Kot',
			'Sehwan',
			'Rojhan',
			'Quetta',
			'Parachinar',
			'Nushki',
			'Mustafabad',
			'Lakki',
			'Kotli Loharan',
			'Khanewal',
			'Khandowa',
			'Kamar Mushani',
			'Jauharabad',
			'Jamshoro',
			'Dadu',
			'Bulri',
			'Bhiria',
			'Abbottabad',
			'Dera Murad Jamali',
			'Pir Jo Goth',
			'Wana',
			'Tangi',
			'Surab',
			'Sohbatpur',
			'Shujaabad',
			'Shahpur',
			'Murree',
			'Larkana',
			'Jacobabad',
			'Fazilpur',
			'Chichawatni',
			'Cherat Cantonement',
			'Chak',
			'Tolti',
			'Talagang',
			'Sodhri',
			'Sahiwal',
			'Rohri',
			'Raiwind',
			'Okara',
			'Nowshera',
			'Mithi',
			'Khewra',
			'Jiwani',
			'Jhelum',
			'Faisalabad',
			'Begowala',
			'Gulishah Kach',
			'Shahr Sultan',
			'Naushahro Firoz',
			'Narang Mandi',
			'Mailsi',
			'Kot Radha Kishan',
			'Kohlu',
			'Khuzdar',
			'Kallar Kahar',
			'Islamkot',
			'Hazro City',
			'Haveli Lakha',
			'Digri',
			'Daud Khel',
			'Daggar',
			'Chilas',
			'Bagarji',
			'Timargara',
			'Utmanzai',
			'Thul',
			'Thatta',
			'Tangwani',
			'Shorkot',
			'Sann',
			'Pindi Bhattian',
			'Ormara',
			'Muzaffarābād',
			'Mirwah Gorchani',
			'Mianwali',
			'Lala Musa',
			'Ladhewala Waraich',
			'Kunjah',
			'Kotli',
			'Jalalpur Pirwala',
			'Zafarwal',
			'Sanghar',
			'Renala Khurd',
			'Nabisar',
			'Mirpur Sakro',
			'Mirpur Khas',
			'Malakand',
			'Khairpur',
			'Jand',
			'Gujar Khan',
			'Dera Bugti',
			'Chaman',
			'Bozdar Wada',
			'Bhakkar',
			'Nowshera Cantonment',
			'Setharja Old',
			'Pakpattan',
			'Pad Idan',
			'Kohat',
			'Kahror Pakka',
			'Jati',
			'Jalalpur Jattan',
			'Harnoli',
			'Gharo',
			'Gambat',
			'Doaba',
			'Bat Khela',
			'Battagram',
			'Basirpur',
			'Mughalabad',
			'Warah',
			'Swabi',
			'Shekhupura',
			'Samaro',
			'Sahiwal',
			'Nankana Sahib',
			'Mardan',
			'Mamu Kanjan',
			'Lodhran',
			'Kamra',
			'Dera Allahyar',
			'Hujra Shah Muqim',
			'Hingorja',
			'Gahkuch',
			'Fort Abbas',
			'Eminabad',
			'Chak Azam Sahu',
			'Bandhi',
			'Bagh',
			'Dambudas',
			'Chuhar Jamali',
			'Tordher',
			'Shikarpur',
			'Shigar',
			'Muzaffargarh',
			'Multan',
			'Mandi Bahauddin',
			'Mach',
			'Kharian',
			'Khanpur',
			'Kalat',
			'Kahna Nau',
			'Garhiyasin',
			'Dhaunkal',
			'Dajal',
			'Chhor',
			'Chitral',
			'Chishtian',
			'Bahawalnagar',
			'Alizai',
			'Sargodha',
			'Patan',
			'Naushahra Virkan',
			'Moro',
			'Mankera',
			'Layyah',
			'Keti Bandar',
			'Kamalia',
			'Jaranwala',
			'Islamabad',
			'Harnai',
			'Bhopalwala',
			'Ahmadpur Sial',
			'Basti Dosa',
			'Eidghah',
			'Zaida',
			'Tando Adam',
			'Thal',
			'Rajanpur',
			'Pithoro',
			'Mirpur Mathelo',
			'Kot Malik Barkhurdar',
			'Kandiari',
			'Havelian',
			'Harunabad',
			'Duki',
			'Darya Khan Marri',
			'Chuhar Kana',
			'Baddomalhi',
			'Alik Ghund',
			'Athmuqam',
			'Vihari',
			'Tando Allahyar',
			'Pindi Gheb',
			'Pind Dadan Khan',
			'Miran Shah',
			'Mangla',
			'Loralai',
			'Khangah Dogran',
			'Kalaswala',
			'Chak Pindi',
			'Tando Muhammad Khan',
			'Tando Jam',
			'Talhar',
			'Surkhpur',
			'Shinpokh',
			'Sanjwal',
			'Sangla Hill',
			'Pasrur',
			'Nasirabad',
			'Malakwal',
			'Lalian',
			'Khanpur Mahar',
			'Kashmor',
			'Aliabad',
			'Bahawalnagar',
			'Sook Kalan',
			'Kakad Wari Dir Upper',
			'Sukkur',
			'Sibi',
			'Shahpur Chakar',
			'Saidu Sharif',
			'Pishin',
			'Narowal',
			'Mirpur Bhtoro',
			'Lakhi',
			'Kot Addu',
			'Khurrianwala',
			'Khalabat',
			'Khairpur Nathan Shah',
			'Johi',
			'Faqirwali',
			'Upper Dir',
			'Daromehar',
			'Risalpur Cantonment',
			'Usta Muhammad',
			'Turbat',
			'Tando Mitha Khan',
			'Tando Bago',
			'Rasulnagar',
			'Goth Radhan',
			'Chenab Nagar',
			'Kot Rajkour',
			'Kot Mumin',
			'Jhang Sadr',
			'Chak Thirty-one -Eleven Left',
			'Dinan Bashnoian Wala',
			'Chawinda',
			'Akora',
			'Dandot RS',
			'Chowki Jamali',
			'Nazir Town',
			'Chak Five Hundred Seventy-five',
			'Skardu',
			'Sambrial',
			'Qadirpur Ran',
			'Panjgur',
			'Kotri',
			'Kot Diji',
			'Kabirwala',
			'Jatoi Shimali',
			'Hangu',
			'Gandava',
			'Dalbandin',
			'Dadhar',
			'Banian',
			'Shingli Bala',
			'Adilpur',
			'Umarkot',
			'Ubauro',
			'Sita Road',
			'Sinjhoro',
			'Sillanwali',
			'Sarai Sidhu',
			'Saddiqabad',
			'Raja Jang',
			'Liliani',
			'Kulachi',
			'Kario Ghanwar',
			'Kalur Kot',
			'Kadhan',
			'Gujranwala',
			'Dunga Bunga',
			'Diplo',
			'Chiniot',
			'Chakwal',
			'Bhimber',
			'Barkhan',
			'Ashanagro Koto',
			'Phalia',
			'Pabbi',
			'Mehar',
			'Mananwala',
			'Landi Kotal',
			'Lahore',
			'Khaplu',
			'Kandiaro',
			'Kahuta',
			'Hala',
			'Dullewala',
			'Dera Ismail Khan',
			'Dera Ghazi Khan',
			'Daultala',
			'Daska Kalan',
			'Bhalwal',
			'Mianke Mor',
			'Bannu',
			'Mehmand Chak',
			'Sialkot',
			'Sharqpur Sharif',
			'Ranipur',
			'Rajo Khanani',
			'Goth Phulji',
			'Malir Cantonment',
			'Madeji',
			'Lachi',
			'Kasur',
			'Kaleke Mandi',
			'Kalabagh',
			'Dasu',
			'Hattian Bala',
			'Shahdadpur',
			'Rawalpindi',
			'Qila Saifullah',
			'Kambar',
			'Pasni',
			'Paharpur',
			'New Mirpur',
			'Mastung',
			'Kot Ghulam Muhammad',
			'Khushāb',
			'Karachi',
			'Jampur',
			'Jahanian Shah',
			'Hasilpur',
			'Ghauspur',
			'Bhan',
			'Bhag',
			'Berani',
			'Alpurai',
			'Wazirabad',
			'Pano Aqil',
			'Mingora',
			'Mian Channun',
			'Mansehra',
			'Khangarh',
			'Kandhkot',
			'Hadali',
			'Gojra',
			'Ghotki',
			'Dunyapur',
			'Burewala',
			'Ahmadpur East',
			'Muhammad Moryal Sahito Jo Goth',
			'Zahir Pir',
			'Yazman',
			'Shahkot',
			'Sarai Alamgir',
			'Sakrand',
			'Matiari',
			'Kot Samaba',
			'Khadro',
			'Jhol',
			'Hyderabad',
			'Faruka',
			'Chunian',
			'Alipur',
			'Rawalakot',
			'Sobhodero',
			'Shakargarh',
			'Pattoki',
			'Nagir',
			'Minchianabad',
			'Kamoke',
			'Chak Jhumra',
			'Gadani',
			'Dittewal',
			'Dipalpur',
			'Darya Khan',
			'Choa Saidan Shah',
			'Awaran',
			'Arifwala',
			'Moza Shahwala',
			'Taunsa',
			'Pir Mahal',
			'Pallandri',
			'Kot Sultan',
			'Gujrat',
			'Gilgit',
			'Dinga',
			'Bhawana',
			'Bela',
			'Jām Sāhib',
			'Noorabad',
		],
	},
	Palau: {
		country: 'Palau',
		cities: [
			'Melekeok Village',
			'Ngerkeai',
			'Angaur State',
			'Koror Town',
			'Kloulklubed',
			'Ngerulmud',
			'Ngchemiangel',
			'Sonsorol Village',
			'Koror',
			'Ulimang',
			'Kayangel',
			'Ngchesar Hamlet',
			'Mengellang',
			'Ngardmau',
			'Ngereklmadel',
			'Ngetkib',
			'Imeong Hamlet',
			'Tobi Village',
		],
	},
	Panama: {
		country: 'Panama',
		cities: [
			'Vista Hermosa',
			'San José',
			'Río Grande',
			'Río Abajo',
			'Piedras Gordas',
			'Pesé',
			'Panamá',
			'Mulatupo',
			'Llano Grande',
			'Llano Bonito',
			'El Porvenir',
			'Pilón',
			'El Espinal',
			'Oria Arriba',
			'Boca de Balsa',
			'Sitio Prado',
			'Santa Cruz',
			'San Bartolo',
			'San Andrés',
			'Río Hato',
			'Potrerillos Arriba',
			'Paya',
			'Los Olivos',
			'Los Canelos',
			'Llano Grande',
			'Limones',
			'Las Lomas',
			'Hato Jobo',
			'El Espino de Santa Rosa',
			'El Calabacito',
			'Boca de Río Chiriquí',
			'Boca de Cupe',
			'Alto Caballero',
			'Villa Unida',
			'El Porvenir',
			'Los Pollos',
			'Río Luís',
			'Güibale',
			'Alto de Jesús',
			'San Pablo Nuevo',
			'Río Rita',
			'Los Higos',
			'Llano Largo',
			'La Tiza',
			'Guayabal',
			'El Tejar',
			'El Rincón',
			'Cañazas',
			'Buenos Aires',
			'Bajos de Güera',
			'Arosemena',
			'Lajas Blancas',
			'Villa Lourdes',
			'Vista Hermosa',
			'Agua Fría Número Uno',
			'Valle de Las Minas',
			'Las Zangüengas',
			'Río Duque',
			'Peñas Chatas',
			'Natá',
			'María Chiquita',
			'Las Marias',
			'Hato Culantro',
			'Guarumal',
			'Guadalupe',
			'Gobea',
			'El Guásimo',
			'Chumical',
			'Chepillo',
			'Caimitillo',
			'Boca del Monte',
			'Antón',
			'Unión de Azuero',
			'Tres Quebradas',
			'Rodeo Viejo',
			'Potrerillos Abajo',
			'Pocrí',
			'Nuario',
			'Los Castillos',
			'La Ensenada',
			'Horconcitos',
			'Guabal',
			'El Peñón',
			'El Espino',
			'Cordillera',
			'Cochea Abajo',
			'Bejuco',
			'Bayano',
			'Alto de La Estancia',
			'Pásiga',
			'Nueva Esperanza',
			'Corozal',
			'Coronte',
			'Santiago de Veraguas',
			'Santa Rita',
			'Santa Catalina',
			'San Lorenzo',
			'San Francisco',
			'Río Hondo',
			'Rincón Hondo',
			'Quebrada Bonita Adentro',
			'Potuga',
			'Playa Leona',
			'Parita',
			'Mendoza',
			'Las Palmas',
			'Guzmán',
			'El Progreso',
			'Entradero',
			'El Carate',
			'Ailigandí',
			'Agua Buena',
			'Barriales',
			'Bajo Solís',
			'Guayabito',
			'San Carlos',
			'Sabana Grande',
			'Querévalo',
			'Puerto Armuelles',
			'Parque Lefevre',
			'Los Santos',
			'Los Pozos',
			'Los Castillos',
			'La Mitra',
			'La Laguna',
			'La Esperanza',
			'Guarumal',
			'Escobal',
			'El Muñoz',
			'Salto Dupí',
			'Llano Culebra',
			'Cermeño',
			'Cartí Sugtupu',
			'Cabuya',
			'Gonzalillo',
			'Paso Canoas Arriba',
			'Manaca Civil',
			'Los Algarrobos Arriba',
			'Quebrada Tula',
			'El Puerto',
			'Zapotillo',
			'Utirá',
			'San Juan',
			'Valle de Riscó',
			'Quebrada del Rosario Arriba',
			'Puerto Caimito',
			'Paritilla',
			'Paraíso',
			'Montijo',
			'Lolá',
			'Llano Largo',
			'Limón de Tijeras',
			'Las Huacas del Quije',
			'La Pasera',
			'La Garceana',
			'La Colorada',
			'El Pájaro',
			'El Hato',
			'El Cañafístulo',
			'El Cacao',
			'El Alto',
			'Cerro Viejo',
			'San Isidro',
			'Cambutal',
			'Bajo Boquete',
			'Ave María',
			'Manaca Norte',
			'San Juan de Dios',
			'Ciricito Abajo',
			'Villa Carmen',
			'Santa Clara',
			'Canoa',
			'Pueblo Viejo',
			'Perales',
			'Nombre de Dios',
			'La Montañuela',
			'Miramar',
			'Los Corotúes',
			'Los Cerritos',
			'Llano Grande',
			'Las Ollas Arriba',
			'La Peña',
			'La Loma',
			'La Chorrera',
			'La Boca del Guásimo',
			'Guacá Arriba',
			'El Uvito',
			'El Toro',
			'El Ejido',
			'Divalá',
			'Cascabel',
			'Bahía Azul',
			'Arraiján',
			'Unión Chocó',
			'Norteño',
			'Pueblo Nuevo',
			'Tolé',
			'Tocumen',
			'San Pablo Viejo Abajo',
			'San José',
			'Panama City',
			'Potrero Grande',
			'Los Naranjos',
			'Los Cerros de Paja',
			'Los Ángeles',
			'Gobernadora',
			'La Enea',
			'La Concepción',
			'Garachiné',
			'El Retiro',
			'Farallón',
			'El Espino',
			'El Cedro',
			'Boca de Río Indio',
			'Alto del Espino',
			'Viento Frío',
			'Ustupo',
			'Tucutí',
			'San Juan',
			'San Francisco',
			'Sabanitas',
			'Playón Chico',
			'Palmira',
			'Palmas Bellas',
			'Nueva Providencia',
			'Los Anastacios',
			'Las Cumbres',
			'La Palma',
			'Hato Pilón',
			'Gatuncillo',
			'Flores',
			'Finca Blanco',
			'Espavé',
			'El Pájaro',
			'Kusapín',
			'Chitré',
			'Changuinola',
			'Chame',
			'Cerro Plata',
			'Calobre',
			'Bastimentos',
			'Achutupo',
			'El Palmar',
			'Sardinilla',
			'Sabana Grande',
			'Quebrada de Loro',
			'Nuevo Arraiján',
			'Mogollón',
			'Miguel de La Borda',
			'Margarita',
			'Las Uvas',
			'Las Minas',
			'Juay',
			'El Sesteadero',
			'El Empalme',
			'Ciricito de Los Sotos',
			'Chilibre',
			'Cerro Punta',
			'Caballero',
			'Barranco',
			'Agua de Salud',
			'San Antonio',
			'Santo Domingo',
			'San José',
			'San Ignacio de Tupile',
			'Rincón',
			'Punta Róbalo',
			'Potrero de Caña',
			'Pacora',
			'Nuevo Emperador',
			'Mariabé',
			'Llano Grande',
			'Lajas Adentro',
			'La Herradura',
			'La Espigadilla',
			'Guararé Arriba',
			'El Piro',
			'El Limón',
			'Dolega District',
			'Cuipo',
			'Cañas',
			'Calidonia',
			'Calabacito',
			'Caimito',
			'Cabuya',
			'Boró',
			'Betania',
			'Ancón',
			'Rambala',
			'La Pesa',
			'Santa Fé',
			'Penonomé',
			'Palenque',
			'Mortí',
			'Leones Arriba',
			'Las Cabras',
			'La Pintada',
			'El María',
			'El Coco',
			'Corozal',
			'Chiriquí Grande',
			'Cerro Cama',
			'Kankintú',
			'Cañita',
			'Calidonia',
			'Buenos Aires',
			'Aguadulce',
			'Howard',
			'Cedro Arriba',
			'Volcán',
			'Vista Alegre',
			'Sioguí Abajo',
			'Santa Rosa Número Uno',
			'San José',
			'Quebrada de Piedra',
			'Quebrada de Oro',
			'Burica',
			'Puerto Obaldía',
			'Piedra Roja',
			'Pedregal',
			'Ocú',
			'Nuevo Vigía',
			'Monagrillo',
			'Miramar',
			'Los Algarrobos',
			'La Yeguada',
			'Las Lajas',
			'Las Colinas',
			'La Encantada',
			'Líbano',
			'El Capurí',
			'El Caño',
			'El Barrero',
			'Chupá',
			'Cabra Número Uno',
			'Brujas',
			'Atalaya',
			'Santa Rita Arriba',
			'Platanillo',
			'Bella Vista',
			'Vallerriquito',
			'Tubualá',
			'Tijeras',
			'Soná',
			'San Vicente',
			'Santo Tomás',
			'Santa Isabel',
			'Río Grande',
			'Progreso',
			'Ponuga',
			'Plaza de Caisán',
			'Nueva Gorgona',
			'Llano Abajo',
			'Las Palmitas',
			'Las Cruces',
			'Pitaloza Arriba',
			'La Miel',
			'El Valle de La Unión',
			'El Pedregoso',
			'El Manantial',
			'El Cristo',
			'El Barrito',
			'El Bale',
			'David',
			'Coclecito',
			'Cerro Azul',
			'Bágala',
			'Alanje',
			'Camogantí',
			'Yaviza',
			'Río Alejandro',
			'Punta Laurel',
			'Puerto Vidal',
			'Los Llanitos',
			'Las Minas',
			'Churuquita Grande',
			'Chupampa',
			'Calzada Larga',
			'Calovébora',
			'Boquerón',
			'Bahía Honda',
			'Barranco Adentro',
			'Veracruz',
			'San Félix',
			'Punta Peña',
			'Puerto Pilón',
			'Peña Blanca',
			'Palmira',
			'Olá',
			'Llano Marín',
			'Llano de Piedra',
			'Las Trancas',
			'Las Margaritas',
			'Las Huacas',
			'La Arena',
			'Hato Julí',
			'El Copé',
			'Cerro Iglesia',
			'Camarón Arriba',
			'Cacique',
			'Nuevo Belén',
			'Contadora',
			'La Mesa',
			'Nuevo San Carlitos',
			'Mirones',
			'Cerro Pelado',
			'Villa Rosario',
			'Tobobe',
			'Sioguí Arriba',
			'Santa Marta',
			'San Antonio',
			'Río Sereno',
			'Pedregal',
			'Pedasí',
			'Paraíso',
			'Monjarás',
			'Altos de San Francisco',
			'La Palma',
			'La Laguna',
			'La Esmeralda',
			'El Higo',
			'El Coco',
			'Coclé',
			'Chepo',
			'Cerro Largo',
			'Cauchero',
			'Cativá',
			'Canto del Llano',
			'Bijagual',
			'Bajo Corral',
			'Santa María',
			'San Francisco',
			'San Felipe',
			'Piedras Gordas',
			'Palmira Centro',
			'Llano Tugrí',
			'Llano Ñopo',
			'Las Palmas',
			'La Palma',
			'La Cabima',
			'Jaramillo Arriba',
			'El Rincón',
			'El Potrero',
			'El Picador',
			'El Pedregoso',
			'El Nancito',
			'Cristóbal',
			'Chimán',
			'Chiguirí Arriba',
			'Cerro Silvestre',
			'La Arenosa',
			'Lajero',
			'Valle Rico',
			'Sorá',
			'San Miguelito',
			'San Miguel',
			'San José',
			'Paja de Sombrero',
			'Nuevo San Juan',
			'Narganá',
			'Monte Lirio',
			'Llano de La Cruz',
			'Las Guabas',
			'La Raya de Santa María',
			'La Mesa',
			'La Laguna',
			'La Ermita',
			'La Carrillo',
			'Juan Díaz',
			'Jaqué',
			'Hato Montaña',
			'Guayabito',
			'Gualaca',
			'Gómez',
			'Puerto Lindo',
			'Colón',
			'Chiriquí',
			'Chigoré',
			'Chichica',
			'Chacarero',
			'Burunga',
			'Boquerón',
			'Bella Vista',
			'Altos de Güera',
			'Tortí',
			'Loma Yuca',
			'Tinajas',
			'Tebario',
			'Pueblo Nuevo',
			'San Pedro del Espino',
			'Gatún',
			'Puerto Escondido',
			'Portobelo',
			'Portobelillo',
			'Playa Chiquita',
			'Pinogana',
			'Metetí',
			'Macaracas',
			'Los Asientos',
			'La Soledad',
			'Lajas de Tolé',
			'Gonzalo Vásquez',
			'El Espino Amarillo',
			'El Chirú',
			'El Bebedero',
			'Cerro Plata',
			'Celmira',
			'Caimito',
			'Isla Bastimentos',
			'Achiote',
			'Yape',
			'Setegantí',
			'Río Grande',
			'Nueva Arenosa',
			'Maraca',
			'Los Algarrobos',
			'Leones Arriba',
			'Las Sabanas',
			'La Mesa',
			'Lajamina',
			'La Estrella',
			'El Silencio',
			'El Giral',
			'El Chorrillo',
			'Cuango',
			'Bugabita Arriba',
			'Bocas del Toro',
			'Almirante',
			'Veladero',
			'Tonosí',
			'Sortová',
			'Soloy',
			'San Vicente de Bique',
			'Santo Domingo',
			'Santa Ana Arriba',
			'San Marcelo',
			'Mata del Nance',
			'Los Valles',
			'Las Guabas',
			'La Mesa',
			'Las Guías Abajo',
			'El Quiteño',
			'El Pantano',
			'El Marañón',
			'El Macano',
			'El Cocla',
			'Barrio Guadalupe',
			'Caldera',
			'Boca de Parita',
			'Bisira',
			'La Colorada',
			'Unión Santeña',
			'Paredón Arriba',
			'Santa Rosa',
			'Salud',
			'Río de Jesús',
			'Pocrí',
			'Plan de Chorcha',
			'Peña Blanca',
			'París',
			'Palo Grande',
			'Nuestro Amo',
			'Lídice',
			'La Trinidad',
			'La Laja',
			'La Arena',
			'Gatú',
			'El Potrero',
			'El Guabo',
			'El Copé',
			'Cacao',
			'Coclé del Norte',
			'Cañas Gordas',
			'Bugaba',
			'Boquete',
			'Boca Chica',
			'Bisvalles',
			'Aserrío de Gariché',
			'Toabré',
			'Rovira Arriba',
			'Río Congo',
			'Remedios',
			'Nuevo Chagres',
			'Las Tablas',
			'Las Lajas',
			'La Raya de Calobre',
			'Quebro',
			'La Guinea',
			'La Colorada',
			'La Cabuya',
			'Hato Corotú',
			'Hato Chamí',
			'Guararé',
			'Guabito',
			'El Roble',
			'El Prado',
			'El Cortezo',
			'Cerro Banco',
			'Breñón',
			'Alcalde Díaz',
			'Santa Lucía',
			'Toza',
			'Santa Rita',
			'Santa Fe',
			'Santa Cruz',
			'Santa Clara',
			'Púcuro',
			'Palmira',
			'Los Lotes',
			'Los Ángeles',
			'El Cocal',
			'Cucunatí',
			'Churuquita Chiquita',
			'Chepigana',
			'Cerro Iglesia',
			'Capira',
			'Cabuya',
			'Barranco Colorado',
			'Jingurudo',
			'Bayamón',
			'Quebrada del Rosario',
			'Cabecera de Cerro Puerco',
			'Viguí',
			'San Carlos',
			'La Tronosa',
			'Lagarterita',
			'Juan Díaz',
			'El Cortezo',
			'Dos Ríos',
			'Curundú',
			'Cerro Venado',
			'Cerro Caña',
			'Capellanía',
			'Cañaveral',
			'Caimito',
			'Buena Vista',
			'Bella Vista',
			'Barnizal',
			'Arenas',
			'Alto Boquete',
			'Aguas Blancas',
			'San Juan Bautista',
			'Oma',
		],
	},
	'Papua New Guinea': {
		country: 'Papua New Guinea',
		cities: [
			'Ihu',
			'Goroka',
			'Mount Hagen',
			'Kokoda',
			'Wau',
			'Rabaul',
			'Mendi',
			'Alotau',
			'Arawa',
			'Kieta',
			'Kavieng',
			'Kandrian',
			'Angoram',
			'Lae',
			'Finschhafen',
			'Tari',
			'Ambunti',
			'Kimbe',
			'Wewak',
			'Madang',
			'Kurumul',
			'Bulolo',
			'Port Moresby',
			'Kerema',
			'Ialibu',
			'Kokopo',
			'Kainantu',
			'Buka',
			'Kwikila',
			'Kundiawa',
			'Daru',
			'Porgera',
			'Morehead',
			'Lorengau',
			'Popondetta',
			'Samarai',
			'Rauna',
			'Panguna',
			'Minj',
			'Wabag',
			'Vanimo',
			'Kiunga',
			'Aitape',
		],
	},
	Paraguay: {
		country: 'Paraguay',
		cities: [
			'Tacuatí',
			'Lambaré',
			'Caazapá',
			'Atyrá',
			'Caraguatay',
			'Villarrica',
			'San Pedro de Ycuamandiyú',
			'San Lorenzo',
			'Carayaó',
			'Villa Florida',
			'Tobatí',
			'San Miguel',
			'Encarnación',
			'Capitán Bado',
			'Ayolas',
			'San Cosme y Damián',
			'Repatriación',
			'Jesús',
			'Doctor Juan León Mallorquín',
			'Caacupé',
			'San Isidro de Curuguaty',
			'Mbocayaty',
			'Limpio',
			'General Higinio Morínigo',
			'Ciudad del Este',
			'Santiago',
			'San Juan Nepomuceno',
			'Nemby',
			'Coronel Martínez',
			'Puerto Mayor Otaño',
			'Nueva Italia',
			'La Paloma del Espíritu Santo',
			'General Artigas',
			'Carapeguá',
			'Isla Pucú',
			'General José Eduvigis Díaz',
			'Concepción',
			'Carlos Antonio López',
			'Asunción',
			'San Patricio',
			'San Antonio',
			'Hohenau',
			'Colonia General Alfredo Stroessner',
			'Capiibary',
			'Obligado',
			'General José María Delgado',
			'Ypacarai',
			'Puerto Rosario',
			'Natalicio Talavera',
			'Edelira',
			'Caaguazú',
			'Fernando de la Mora',
			'Areguá',
			'Filadelfia',
			'Nueva Esperanza',
			'Unión',
			'Santa Rosa',
			'Independencia',
			'Villa Hayes',
			'General Elizardo Aquino',
			'Colonia Menno',
			'Belén',
			'Fulgencio Yegros',
			'Ybycuí',
			'Pirayú',
			'Natalio',
			'Altos',
			'Piribebuy',
			'Pedro Juan Caballero',
			'Cerrito',
			'Caapucú',
			'Benjamín Aceval',
			'Tomás Romero Pereira',
			'Capitán Pablo Lagerenza',
			'Yaguarón',
			'Trinidad',
			'Itauguá',
			'Mariano Roque Alonso',
			'Bella Vista',
			'Villa Ygatimí',
			'San Joaquín',
			'Eusebio Ayala',
			'Corpus Christi',
			'Chore',
			'Sapucaí',
			'San Juan Bautista',
			'Naranjal',
			'Nanawa',
			'Los Cedrales',
			'Itacurubí de la Cordillera',
			'Itá',
			'Buena Vista',
			'Colonia San Lorenzo',
			'San Pedro del Paraná',
			'Pirapó',
			'Iturbe',
			'Itapé',
			'Guarambaré',
			'Minga Porá',
			'Capiatá',
			'Bella Vista',
			'Santa María',
			'Mbuyapey',
			'Doctor Cecilio Báez',
			'Arroyos y Esteros',
			'Antequera',
			'Yuty',
			'Villa Elisa',
			'San José de los Arroyos',
			'Quiindy',
			'Paraguarí',
			'Itakyry',
			'Capitán Miranda',
			'Horqueta',
			'Santa Rita',
			'Presidente Franco',
			'Lima',
			'Itacurubí del Rosario',
			'Doctor Juan Manuel Frutos',
			'Yguazú',
			'Colonia Mauricio José Troche',
			'Abaí',
			'Yhú',
			'Santa Elena',
			'Salto del Guairá',
			'San Bernardino',
			'Katueté',
			'Yataity del Norte',
			'Colonia Nueva Germania',
			'La Colmena',
			'Fram',
			'Emboscada',
			'Coronel Oviedo',
			'Acahay',
			'San Juan del Paraná',
			'San Alberto',
			'Pilar',
			'Mbutuý',
			'Guajayvi',
			'Fuerte Olimpo',
			'Carmen del Paraná',
			'Colonia Neuland',
		],
	},
	Peru: {
		country: 'Peru',
		cities: [
			'Yuracyacu',
			'Tambo Grande',
			'Succha',
			'Salas',
			'Quivilla',
			'Pomalca',
			'Oxamarca',
			'Levanto',
			'Lagunas',
			'Jangas',
			'Huata',
			'Guadalupe',
			'Chuquibamba',
			'Cachachi',
			'Agallpampa',
			'Yaurisque',
			'Vilavila',
			'Turpay',
			'San Vicente de Cañete',
			'Santa Rita de Siguas',
			'Sañayca',
			'Pocohuanca',
			'Pichirhua',
			'Patambuco',
			'Ocoyo',
			'Margos',
			'Maca',
			'Locroja',
			'Lamay',
			'Julcan',
			'Independencia',
			'Umamarca',
			'Huayllabamba',
			'Huancarqui',
			'Cruz Blanca',
			'Chipao',
			'Carabayllo',
			'Capaya',
			'Acoria',
			'Acolla',
			'Huayllo',
			'Llata',
			'Sausa',
			'La Rinconada',
			'Yanacancha',
			'San Antonio',
			'Tabaloslos',
			'Yauya',
			'Tingo',
			'Tantamayo',
			'Simbal',
			'Shupluy',
			'San Marcos',
			'San José',
			'Quinuabamba',
			'Quillo',
			'Puerto Inca',
			'Puerto Galilea',
			'Paltashaco',
			'Papayal',
			'Pallasca',
			'Paiján',
			'Oyotún',
			'Orellana',
			'Motupe',
			'Mancos',
			'Longotea',
			'Juan Guerra',
			'Chongoyape',
			'Casa Grande',
			'Carabamba',
			'Cañaris',
			'Buenos Aires',
			'Anta',
			'Amotape',
			'Acomayo',
			'Alianza Cristiana',
			'Yanaquihua',
			'Tuti',
			'Tomas',
			'Tambobamba',
			'Sepahua',
			'San Salvador',
			'Sacsamarca',
			'Río Grande',
			'Putina',
			'Pozuzo',
			'Pacaycasa',
			'Omas',
			'Lloque',
			'Lambrama',
			'El Descanso',
			'Corani',
			'Conima',
			'Concho',
			'Colquepata',
			'Chosica',
			'Chojata',
			'Cerro de Pasco',
			'Cauri',
			'Castrovirreyna',
			'Carania',
			'Cairani',
			'Baños',
			'Ayo',
			'Antaparco',
			'Shapaja',
			'San Lucas ( Pueblo Nuevo de Colan)',
			'Salitral',
			'Guadalupito',
			'Rondos',
			'Monsefú',
			'Milpuc',
			'Macate',
			'Llama',
			'Huaranchal',
			'Chota',
			'Chisquilla',
			'Chachapoyas',
			'Bretaña',
			'Yunguyo',
			'Yauyos',
			'Végueta',
			'Tinta',
			'Sicaya',
			'San Ramón',
			'Sama Grande',
			'Putina',
			'Pujocucho',
			'Paucarcolla',
			'Pativilca',
			'Ollachea',
			'Ocros',
			'Nazca',
			'Machupicchu',
			'Junín',
			'Jarpa',
			'Islay (Matarani)',
			'Huambalpa',
			'Congas',
			'Combapata',
			'Chincho',
			'Calapuja',
			'Antauta',
			'Ayrihuanca',
			'Ayahuay',
			'Queromarca',
			'Nuevo Progreso',
			'Paucartambo',
			'Uco',
			'Sucre',
			'Sinchao',
			'Santa Cruz',
			'Ranrahirca',
			'Hacienda Pucala',
			'Nueva Arica',
			'Matapalo',
			'Luya',
			'Ferreñafe',
			'El Porvenir',
			'Colcabamba',
			'Chiriaco',
			'Yucay',
			'Tungasuca',
			'Tapay',
			'Sincos',
			'Quilcata',
			'Pusi',
			'Pocsi',
			'Pisac',
			'Pilpichaca',
			'Pillcopata',
			'Parco',
			'Palpa',
			'Paccha',
			'Oyolo',
			'Nicasio',
			'Muqui',
			'Mazamari',
			'Lahuaytambo',
			'Humay',
			'Huaquirca',
			'Huanipaca',
			'Coporaque',
			'Colta',
			'Chincha Alta',
			'Chaccrampa',
			'Carhuanca',
			'Caicay',
			'Bolognesi',
			'Apata',
			'Acobamba',
			'San Antonio',
			'Kelluyo',
			'Daguas',
			'Rapayan',
			'Paragsha- San Andres - Jose Carlos Mariategui',
			'Mazuko',
			'Pampas Grande',
			'Sondorillo',
			'Sillapata',
			'Santa Rosa',
			'Santa Rosa',
			'San Benito',
			'Pucallpa',
			'Mendoza',
			'Licupis',
			'La Unión',
			'La Arena',
			'Juanjuí',
			'Huacaybamba',
			'Huacapampa',
			'El Parco District',
			'Contamana',
			'Chulucanas',
			'Chiclayo',
			'Cascas',
			'Cajamarquilla',
			'Tauria',
			'Sunampe',
			'Socos',
			'Santa Teresa',
			'Santa Lucía',
			'San Pedro',
			'Pucusana',
			'Pomabamba',
			'Molinos',
			'Maras',
			'La Curva',
			'Iñapari',
			'Huaro',
			'Huanoquite',
			'Huachocolpa',
			'Gorgor',
			'Estique',
			'El Carmen',
			'Cuenca',
			'Circa',
			'Cieneguilla',
			'Cayna',
			'Callalli',
			'Arma',
			'Ananea',
			'Huarancante',
			'Mosoc Cancha',
			'Carhuac',
			'Ullulluco',
			'El Pedregal',
			'Puente Viru',
			'Tinco',
			'Tarapoto',
			'Singa',
			'Sartimbamba',
			'Santa Isabel',
			'San Pedro de Lloc',
			'San Jacinto',
			'Paclas',
			'Nanchoc',
			'Lonya Chico',
			'Llama',
			'Inguilpata',
			'Curgos',
			'Chirinos',
			'Cajabamba',
			'Zapatero',
			'Yanque',
			'Toro',
			'Tarucani',
			'Tambo',
			'San Pedro',
			'San Miguel',
			'Puquio',
			'Pirca',
			'Orurillo',
			'Minas de Marcona',
			'Progreso',
			'El Cardo',
			'Colcabamba',
			'Coalaque',
			'Chivay',
			'Chinchero',
			'Chaupimarca',
			'Calca',
			'Sabaino',
			'Huaccana',
			'Huancaspata',
			'José Leonardo Ortiz',
			'Mariano Melgar',
			'Jesus Maria',
			'Yanas',
			'Uticyacu',
			'Tayabamba',
			'Santa Cruz De Succhabamba',
			'San Carlos',
			'Recuay',
			'Puños',
			'Pampas',
			'Montero',
			'Mollepata',
			'Llata',
			'Llacanora',
			'Las Palmas',
			'La Jalca',
			'Jamalca',
			'Colcamar',
			'Chepén',
			'Chavinillo',
			'Caraz',
			'Buenos Aires',
			'Bagua',
			'Aco',
			'Yauri',
			'Ubinas',
			'Ticaco',
			//'Santa Ana',
			'San Rafael',
			'Quinistaquillas',
			'Punta de Bombón',
			'Mariposa',
			'Llipa',
			'Layo',
			'Jucul',
			'Iquipi',
			'Ilave',
			'Huasahuasi',
			'Huarmey',
			'Huamatambo',
			'Huamali',
			'Huachos',
			'Condoroma',
			'Colca',
			'Cocachacra',
			'Chupa',
			'Chinchaypujio',
			'Chala',
			'Carapo',
			'Calana',
			'Ajoyani',
			'Chalamarca',
			'Santa Catalina',
			'Anra',
			'Indiana',
			'La Banda',
			'Breña',
			'Ucuncha',
			'Uchiza',
			'Tembladera',
			'San Juan',
			'Quiruvilca',
			'Quiches',
			'Parinari',
			'Nepeña',
			'Namora',
			'La Legua - San Jacinto',
			'Huacrachuco',
			'Eten',
			'El Alto',
			'Cocabamba',
			'Bambamarca',
			'Arenal',
			'Yura',
			'Tupac Amaru',
			'Usicayos',
			'Sandia',
			'Quillabamba',
			'Phara',
			'Pampamarca',
			'Pacocha',
			'Mollebamba',
			'Mazo Cruz',
			'Llacllin',
			'Las Yaras',
			'Huaribamba',
			'Hacienda Huancane',
			'Huacho',
			'Cayarani',
			'Cayara',
			'Asillo',
			'El Algarrobal',
			'Bajo Naranjillo',
			'Jacas Grande',
			'Cayhuayna',
			'Alto Salaverry',
			'Yanama',
			'Yanac',
			'Tocache',
			'Tacabamba',
			'Tablazo Norte',
			'Santiago de Cao',
			'Sacanche',
			'Puerto Santa',
			'Poroto',
			'Pantoja',
			'Pamparomas',
			'Olto',
			'Olleros',
			'Nauta',
			'Mocupe',
			'Miracosta',
			'La Peca',
			'Habana',
			'Chumuch',
			'Chimbote',
			'Catache',
			'Caspisapa',
			'Canchaque',
			'Balsas',
			'Yauli',
			'Vilcanchos',
			'Uripa',
			'Tomay Kichwa',
			'Susapaya',
			'Santiago de Surco',
			'Ricardo Palma',
			'Quinua',
			'Quebrada Honda',
			'Puerto Maldonado',
			'Palpacachi',
			'Paccaritambo',
			'Paca',
			'Locumba',
			'Lircay',
			'Lari',
			'La Pampa',
			'Lampian',
			'Huayllay',
			'Huaros',
			'Cusco',
			'Curahuasi',
			'Cochas',
			'Chipispaya',
			'Cangallo',
			'Cabanaconde',
			'Atiquipa',
			'Andarapa',
			'Huambo',
			'Masma Chicche',
			'Cochabamba',
			'Sayapullo',
			'Rioja',
			'Requena',
			'Pacora',
			'Olmos',
			'Lucma',
			'Lonya Grande',
			'Lobitos',
			'Jeberos',
			'Inahuaya',
			'Chalaco',
			'Celendín',
			'Barranca',
			'Ayabaca',
			'Vischongo',
			'Umachiri',
			'Tarma',
			'Tarata',
			'Santa Rosa',
			'Quinocay',
			'Pangoa',
			'Pampas Chico',
			'Paico',
			'Ongoy',
			'Matucana',
			'Maray',
			'Mangas',
			'La Tinguiña',
			'Izcuchaca',
			'Ihuari',
			'Callanmarca',
			'Ataura',
			'Andajes',
			'Santo Tomas',
			'Cahuanuyo',
			'Bajo Pichanaqui',
			'Urpay',
			'Pampamarca',
			'Paucarbamba',
			'Chilca',
			'San Luis',
			'San Antonio',
			'Querocotillo',
			'Los Organos',
			'Huambos',
			'Huacaschuque',
			'Granada',
			'Chocope',
			'Bellavista',
			'Yanaoca',
			'Vitarte',
			'Vilca',
			'Urcos',
			'Uchumayo',
			'Soras',
			'Sina',
			'Samegua',
			'Quiñota',
			'Quicacha',
			'Paratia',
			'Paccha',
			'Omate',
			'Muñani',
			'Moquegua',
			'Limatambo',
			'Lanlacuni Bajo',
			'Langui',
			'La Merced',
			'Kishuara',
			'Julcamarca',
			'Huayllapampa',
			'El Ingenio',
			'Coasa',
			'Chuquibamba',
			'Chambara',
			'Ccapi',
			'Carumas',
			'Capacmarca',
			'Cabana',
			'Asquipata',
			'Arapa',
			'Acobamba',
			'Acarí',
			'Mosoc Llacta',
			'Huarichancara',
			'Lima Pampa',
			'Coviriali',
			'Parcoy',
			'Mucllo',
			'Carmen De La Legua Reynoso',
			'Fonavi',
			'Yorongos',
			'Talara',
			'Sucre',
			'Saposoa',
			'Santiago de Chuco',
			'San Ignacio',
			'Pacasmayo',
			'Pacaipampa',
			'Mochumí',
			'Mache',
			'Lluchubamba',
			'Guzmango',
			'Frias',
			'Cajamarca',
			'Las Pirias',
			'Urubamba',
			'Supe',
			'San Mateo',
			'Rosaspata',
			'Ocuviri',
			'Mungui',
			'Macachacra',
			'Leticia',
			'Huasta',
			'Cupi',
			'Colpas',
			'Colcha',
			'Chuquitira',
			'Collonce',
			'Quilcas',
			'Nuevo Sullana',
			'Huamancaca Chico',
			'Ilo',
			'Huancarama',
			'Hongos',
			'Chontabamba',
			'Camaná',
			'Cajacay',
			'Barranca',
			'Ayna',
			'Achoma',
			'Jayobamba',
			'Yambrasbamba',
			'Usquil',
			'Tabalosos',
			'Sitabamba',
			'Sihuas',
			'Shucushuyacu',
			'Sexi',
			'Pomahuaca',
			'Picota',
			'Pebas',
			'Paita',
			'Lajas',
			'Huallanca',
			'Cabana',
			'Bernal',
			'Aucayacu',
			'Aramango',
			'Buenos Aires',
			'Vizcachane',
			'Ticrapo',
			'San Miguel de Cauri',
			'San Lorenzo',
			'San Juan',
			'Puerto Ocopa',
			'Potoni',
			'Pomata',
			'Paucar',
			'Pancan',
			'Pampa Cangallo',
			'Pachaconas',
			'Oropesa',
			'Orcopampa',
			'Ocobamba',
			'Ocaña',
			'Mara',
			'Huaral',
			'Huangascar',
			'Huacar',
			'Cotaparaco',
			'Copa',
			'Concepción',
			'Chungui',
			'Chiara',
			'Canta',
			'Atuncolla',
			'Arequipa',
			'Ancahuasi',
			'Ambar',
			'Ccaquiracunca',
			'Caujul',
			'Las Nazarenas',
			'Vice',
			'Trujillo',
			'Trinidad',
			'Tournavista',
			'San Gregorio',
			'Roque',
			'Punchao',
			'Obas',
			'Negritos',
			'Moyobamba',
			'Huangala',
			'Jilili',
			'Iberia',
			'Florida ( Pomacochas)',
			'Coishco',
			'Chicama',
			'Cajaruro',
			'Barranquita',
			'Aija',
			'Utcurarca',
			'Zepita',
			'Yauca',
			'Vilcabamba',
			'Tiabaya',
			'Tacna',
			'Santa Cruz',
			'San Pedro',
			'San Lorenzo',
			'San Bartolo',
			'Quehue',
			'Puno',
			'Puyca',
			'Pomacanchi',
			'Palca',
			'Ocoruro',
			'Marcas',
			'Marca',
			'Maranura',
			'Mala',
			'Laraqueri',
			'Lampa',
			'Huayacundo Arma',
			'Huancapi',
			'Crucero',
			'Chincheros',
			'Chicche',
			'Challhuahuacho',
			'Cachimayo',
			'Curpahuasi',
			'Masma',
			'Hualhuas',
			'Quichuay',
			'Wanchaq',
			'Centenario',
			'Cono Norte',
			'Pilcomayo',
			'Horacio Zeballos Gamez',
			'Sondor',
			'Sapillica',
			'San Marcos',
			'San Bernardino',
			'Samanco',
			'Salpo',
			'Puerto Rico',
			'Pimentel',
			'Navarro',
			'Lamas',
			'La Huaca',
			'Jaén',
			'Huaylillas',
			'Huancabamba',
			'Cochabamba',
			'Cochabamba',
			'Chugay',
			'Bambas',
			'Arancay',
			'Angasmarca',
			'La Coipa',
			'Yarabamba',
			'Vilque Chico',
			'Quilahuani',
			'Pallanchacra',
			'Pacaraos',
			'Ocucaje',
			'Mollendo',
			'Mayocc',
			'Marcapata',
			'Luricocha',
			'Juliaca',
			'Huampara',
			'Huamanquiquia',
			'Cuyocuyo',
			'Chichas',
			'Chacapampa',
			'Chacapalpa',
			'Cahuacho',
			'Andahuaylas',
			'Huayllati',
			'Duraznopampa',
			'Kimbiri',
			'Urb. Santo Domingo',
			'Cono Norte',
			'Miramar',
			'Yungay',
			'Shunqui',
			'Sapalache',
			'Salaverry',
			'Llapa',
			'Jepelacio',
			'Choros',
			'Bellavista',
			'Amashca',
			'Aczo',
			'Yauli',
			'Viraco',
			'Tiquillaca',
			'Tauripampa',
			'Surco',
			'Sumbilca',
			'Sibayo',
			'Sayán',
			'San Juan',
			'San Buenaventura',
			'Salcabamba',
			'Quilca',
			'Puquina',
			'Pullo',
			'Pisco',
			'Pilchaca',
			'Paucartambo',
			'Oropesa',
			'Marco',
			'Incuyo',
			'Ica',
			'Guadalupe',
			'Colcabamba',
			'Carampoma',
			'Cabana',
			'Acomayo',
			'Acobambilla',
			'Llongasora',
			'Huayna Alcalde',
			'Echarate',
			'Cochamal',
			'Challas',
			'Segunda Jerusalén - Azunguillo',
			'El Triunfo ( El Cruce)',
			'La Rinconada Ana Maria (La Rinconada)',
			'Cacra',
			'Ayrampuni',
			'Aucallama',
			'Acos',
			'Callanca',
			'Santa Rosa de Sacco',
			'Ledoy',
			'Santa Anita - Los Ficus',
			'San Alejandro',
			'Totora',
			'Sojo',
			'Saquena',
			'San Pablo',
			'Querocoto',
			'Piura',
			'Namballe',
			'Lucmapampa',
			'Lucma',
			'La Pampa',
			'Incahuasi',
			'Conchucos',
			'Chiguirip',
			'Cauday',
			'Calzada',
			'Zurite',
			'Yanacancha',
			'Uñon',
			'Tisco',
			'Tambo Quemado',
			'Subtanjalla',
			'Sangallaya',
			'San Agustin',
			'Quito-Arma',
			'Quellouno',
			'Paracas',
			'Pampas',
			'Omacha',
			'Macari',
			'Lluta',
			'Llusco',
			'Llipata',
			'Llaclla',
			'Livitaca',
			'Lincha',
			'Laraos',
			'La Capilla',
			'Huaripampa',
			'Huancayo',
			'Huancaya',
			'Haquira',
			'Curibaya',
			'Cotabambas',
			'Coata',
			'Checacupe',
			'Chaclacayo',
			'Canchayllo',
			'Canaria',
			'Callao',
			'Caleta de Carquín',
			'Calacoa',
			'Cabanilla',
			'Ccuntuma',
			'Qquea',
			'Ticapampa',
			'Tauca',
			'Sorochuco',
			'Shirac',
			'Santo Domingo',
			'Santa',
			'Ramón Castilla',
			'Posic',
			'Montevideo',
			'Marcavelica',
			'Marcabal',
			'Huarmaca',
			'Catacaos',
			'Casma',
			'Caleta Cruz',
			'Agua Blanca',
			'Zarumilla',
			'Yunga',
			'Vilque',
			'Tibillo',
			'Tarucachi',
			'Sapallanga',
			'Santa Rosa',
			'Querobamba',
			'Poroy',
			'Pomacocha',
			'Perené',
			'Palca',
			'Ollantaytambo',
			'Ocros',
			'Nuñoa',
			'Moho',
			'Macusani',
			'Lucanas',
			'Laraos',
			'La Oroya',
			'Lampa',
			'Ichupampa',
			'Huanza',
			'Churcampa',
			'Chuquibambilla',
			'Chucatamani',
			'Chamaca',
			'Chalhuanca',
			'Caracoto',
			'Andahuaylillas',
			'Ahuac',
			'Accocunca',
			'Jayune',
			'Taurija',
			'Pueblo Nuevo',
			'Paucas',
			'Paccha',
			'Mórrope',
			'Las Lomas',
			'Lagunas',
			'Huicungo',
			'Huandoval',
			'Huanchaco',
			'Encañada',
			'Colasay',
			'Chuquis',
			'Chilete',
			'Cancas',
			'Aricapampa',
			'Totos',
			'Taurisma',
			'Tapairihua',
			'Quequeña',
			'Pilcomayo',
			'Paucarbamba',
			'Pampas',
			'Palcamayo',
			'Asentamiento Humano Nicolas de Pierola',
			'Lima',
			'Lares',
			'Inchupalla',
			'Imperial',
			'Huanta',
			'Huancavelica',
			'Cocas',
			'Chiguata',
			'Aniso',
			'Choropampa',
			'Huabal',
			'Huancano',
			'Huambo',
			'Huacrapuquio',
			'Cotahuasi',
			'Coporaque',
			'Chilca',
			'Changuillo',
			'Atico',
			'Ambo',
			'Acocro',
			'Achanizo',
			'Taray',
			'Coyllurqui',
			'Cojata',
			'Cocharcas',
			'Ayacucho',
			'Providencia',
			'Yanahuanca',
			'Saño',
			'San Francisco De Borja',
			'La Pradera',
			'Huanchaquito Alto',
			'Yurimaguas',
			'Túcume',
			'Tabaconas',
			'Sechura',
			'Salinera Colán',
			'Magdalena',
			'La Cuesta',
			'Chillia',
			'Chacas',
			'Cañaveral',
			'Aguas Verdes',
			'Puerto Callao',
			'Yangas',
			'Villa Rica',
			'Velille',
			'Santa María',
			'San Pedro de Cajas',
			'San Jerónimo',
			'Quilmaná',
			'Queropalca',
			'Ninacaca',
			'Maranganí',
			'La Esmeralda',
			'Huayucachi',
			'Huanuara',
			'Cullhuas',
			'Cotaruse',
			'Chilcas',
			'Chasquitambo',
			'Caraybamba',
			'Carac',
			'Canis',
			'Alis',
			'Aucara',
			'Vista Alegre',
			'Trompeteros',
			'Tamarindo',
			'San Rafael',
			'Sisa',
			'Pomabamba',
			'Pira',
			'Pelejo',
			'Moche',
			'Masisea',
			'Longuita',
			'La Merced',
			'Jayanca',
			'Huacchis',
			'Chaglla',
			'Caballococha',
			'Utco',
			'Yanahuaya',
			'Vitis',
			'Totoral',
			'Taquile',
			'Tanta',
			'Tambo',
			'Tambillo',
			'Socabaya',
			'Saylla',
			'Satipo',
			'Punta Hermosa',
			'Supe Puerto',
			'Pucyura',
			'Pomacocha',
			'Otoca',
			'Miraflores',
			'Matalaque',
			'La Molina',
			'Huayrapata',
			'Colonia',
			'Cocachacra',
			'Chapimarca',
			'Carmen Alto',
			'Carhuamayo',
			'Callahuanca',
			'Caja',
			'Cachora',
			'Acos',
			'Aco',
			'Chignayhua',
			'Matacoto',
			'Saramiriza',
			'Matahuasi',
			'San Fernando',
			'Victor Raul',
			'Tres Unidos',
			'Tocmoche',
			'Suyo',
			'Socota',
			'Pachas',
			'Iquitos',
			'Huaraz',
			'Huancas',
			'Huallanca',
			'Cumba',
			'Concordia',
			'Chazuta',
			'Ascope',
			'Campo Verde',
			'Cuñumbuqui',
			'Yanahuanca',
			'Velinga',
			'Tupe',
			'Tocota',
			'Tapacocha',
			'San Pablo',
			'San Jeronimo De Tunan',
			'Pichari',
			'Pausa',
			'Pampacolca',
			'Pacucha',
			'Paccho',
			'Orcotuna',
			'Nuevo Imperial',
			'Madrigal',
			'Juli',
			'Copa',
			'Colquemarca',
			'Candarave',
			'Azángaro',
			'Aquia',
			'Antabamba',
			'Andamarca',
			'Huancapallac',
			'Huertas',
			'Shatoja',
			'Pedro Ruiz Gallo',
			'Salvación',
			'San Martin',
			'Puerto Rosario de Laberinto',
			'Zorritos',
			'Tunal',
			'Shamboyacu',
			'Sauce',
			'San Jacinto',
			'Reque',
			'Puerto Casma',
			'Pueblo Nuevo',
			'Pilluana',
			'Pátapo',
			'Pampa Hermosa',
			'Olleros',
			'Miraflores',
			'Magdalena',
			'Huari',
			'Charat',
			'Bagua Grande',
			'Tirapata',
			'Tintay',
			'Ticllos',
			'Sabandia',
			'Pomacancha',
			'Pillpinto',
			'Pazos',
			'Pampa de Tate',
			'Pampachiri',
			'Pacobamba',
			'Madean',
			'Jivia',
			'Jatun Orcochiri',
			'Huayana',
			'Huamanguilla',
			'Cusipata',
			'Coya',
			'Coracora',
			'Colca',
			'Chupamarca',
			'Challabamba',
			'Jacobo Hunter',
			'Pueblo Nuevo',
			'Selva Alegre',
			'Santiago',
			'Chupuro',
			'Jauja',
			'Huancabamba',
			'Chuschi',
			'Aco',
			'Nueva Cajamarca',
			'El Rosario',
			'Santa Rosa',
			'Virú',
			'Tingo María',
			'Tarica',
			'Soritor',
			'San Lorenzo',
			'Quinjalca',
			'Piscoyacu',
			'Morropón',
			'Maino',
			'Laredo',
			'La Esperanza',
			'Huánuco',
			'Cuispes',
			'Corongo',
			'Cascapara',
			'Carhuaz',
			'Bolognesi',
			'La Sacilia',
			'Papayal',
			'Yanacancha',
			'Tinyahuarco',
			'Ticllas',
			'Tambopata',
			'Sarhua',
			'Santa Rosa',
			'San Juan',
			'Pocollay',
			'Oyón',
			'Ondores',
			'Ocoña',
			'Mariatana',
			'Marcapomacocha',
			'Mamara',
			'Ingenio',
			'Ichuña',
			'El Tambo',
			'Characato',
			'Chalcos',
			'Catahuasi',
			'Calango',
			'Arahuay',
			'Alca',
			'Yungar',
			'Yamango',
			'Santa Rosa',
			'Saña',
			'Pucacaca',
			'Picsi',
			'Pacanga',
			'Otuzco',
			'Moro',
			'La Grama',
			'Lacabamba',
			'Huacachi',
			'San Antonio del Estrecho',
			'El Prado',
			'Churubamba',
			'Chugur',
			'Chetilla',
			'Cahuac',
			'Breu',
			'Nueva Requena',
			'Belen',
			'Ventanilla',
			'Turpo',
			'Tomepampa',
			'Taraco',
			'Tapo',
			'Tamburco',
			'Sitajara',
			'Santiago',
			'Santa Eulalia',
			//'Santa Ana',
			'San Francisco',
			'Pucara',
			'Palca',
			'Muquiyauyo',
			'Mosca',
			'Iray',
			'Independencia',
			'Huariaca',
			'Goyllarisquizga',
			'Desaguadero',
			'Corire',
			'Comas',
			'Chaviña',
			'Charcana',
			'Chancay',
			'Ccatca',
			'Capachica',
			'Caminaca',
			'Ayaviri',
			'Apongo',
			'Aplao',
			'Accha',
			'Cheto',
			'Naranjos',
			'Buenos Aires',
			'Yuyapichis',
			'Santa Rosa',
			'Bolívar',
			'San Jose De Sisa',
			'Soloco',
			'Sinsicap',
			'Shilla',
			'San Juan',
			'Querecotillo',
			'Paimas',
			'Niepos',
			'Máncora',
			'Longar',
			'Lambayeque',
			'Huaripampa',
			'Huanchay',
			'Huachis',
			'La Florida',
			'Cutervo',
			'Yanaca',
			'Sicuani',
			'Saurama',
			'Quiaca',
			'Pichigua',
			'Paruro',
			'Morcolla',
			'Lucre',
			'Llauta',
			'Lachaqui',
			'Ilabaya',
			'Iberia',
			'Huacaña',
			'Corpanqui',
			'Camilaca',
			'Andamarca',
			'Achaya',
			'Abancay',
			'Ccolo',
			'Conchopata',
			'Genaro Herrera',
			'San Pedro De Cachora',
			'Ciudad Satelite',
			'Huancaray',
			'Cuchumbaya',
			'Chupaca',
			'Chocos',
			'Chincha Baja',
			'Checca',
			'Asia',
			'Accomarca',
			'Cullcuyre',
			'Tumbes',
			'Tamanco',
			'Santa Elena',
			'San Pablo',
			'Chao',
			'La Matanza',
			'Marcara District',
			'Malvas',
			'Iparia',
			'Honoria',
			'La Caleta Culebras',
			'Cujillo',
			'Coris',
			'Chingas',
			'Upahuacho',
			'Surcubamba',
			'Suitucancha',
			'Soraya',
			'San Juan Bautista',
			'San Isidro',
			'San Clemente',
			'Pueblo Nuevo',
			'Pitumarca',
			'Pilcuyo',
			'Pataypampa',
			'Oxapampa',
			'Ocongate',
			'Morococha',
			'Mollepampa',
			'Lucre',
			'Los Aquijes',
			'Llocllapampa',
			'Llalli',
			'Limbani',
			'Laramate',
			'Huamantanga',
			'Hualmay',
			'Conchamarca',
			'Chicla',
			'Ccorca',
			'Acas',
			'San Juan',
			'Ascencion',
			'Palianñuñas',
			'Limoncarro',
			'Anchonga',
			'Pajarillo',
			'Coasa',
			'Huepetuhe',
			'Cerro Verde',
			'Soplín Vargas',
			'Sullana',
			'San Felipe',
			'Sallique',
			'Panao',
			'Pampas',
			'Omia',
			'La Unión',
			'Lamud',
			'Huamachuco',
			'Hualgayoc',
			'Dos Pueblos',
			'Churuja',
			'Catac',
			'Acopampa',
			'Manuel Antonio Mesones Muro',
			'La Breita',
			'Vinchos',
			'Viñac',
			'Vilcabamba',
			'Urasqui',
			'Toraya',
			'Torata',
			'Talavera',
			'San Luis',
			'Ranracancha',
			'Quiquijana',
			'Quince Mil',
			'Paramonga',
			'Pacapausa',
			'Marcabamba',
			'Mañazo',
			'Huayllahuara',
			'Huaura',
			'Huata',
			'Huarocondo',
			'Corculla',
			'Coayllo',
			'Chumpi',
			'Choco',
			'Chiara',
			'Anta',
			'Andaray',
			'Aco',
		],
	},
	Philippines: {
		country: 'Philippines',
		cities: [
			'Wawa',
			'Vizal Santo Niño',
			'Biga',
			'Tubaran',
			'Totolan',
			'Timpas',
			'Tigum',
			'Taytay',
			'Tapas',
			'Tanza',
			'Talospatang',
			'Tagbacan Ibaba',
			'Tayabas Ibaba',
			'Subic',
			'Socorro',
			'Sison',
			'Sinisian',
			'Sinait',
			'Sibulan',
			'Sibalom',
			'Siaton',
			'Siasi',
			'Sapol',
			'San Vicente',
			'Santo Niño',
			'Santo Cristo',
			'Santa Cruz',
			'San Rafael',
			'San Pedro One',
			'San Miguel',
			'San Mateo',
			'San Luis',
			'San Luis',
			'San Jose',
			'San Isidro',
			'San Eduardo',
			'San Diego',
			'San Carlos',
			'San Antonio',
			'San Andres',
			'Salamanca',
			'Sagud-Bahley',
			'Roxas',
			'Rissing',
			'Rio Tuba',
			'Recodo',
			'Quiling',
			'Quibal',
			'Poona-Piagapo',
			'Pung-Pang',
			'Pinamungahan',
			'Pinamalayan',
			'Pililla',
			'Patin-ay',
			'Simbahan',
			'Panalo-on',
			'Panabingan',
			'Pamplona',
			'Palacpalac',
			'Pagudpud',
			'Pagsabangan',
			'Padong',
			'Paatan',
			'Ozamiz City',
			'Oras',
			'Namboongan',
			'Minante Segundo',
			'Minanga Norte',
			'Maragondon',
			'Mangarine',
			'Mamala',
			'Malhiao',
			'Malamig',
			'Malabor',
			'Lupon',
			'Lumbatan',
			'Lubuagan',
			'Lubigan',
			'Lope de Vega',
			'Lipahan',
			'Linoan',
			'Lingasan',
			'Liliongan',
			'Libon',
			'Liberty',
			'Leyte',
			'Lazi',
			'Laya',
			'Lampari',
			'Lalab',
			'Lacaron',
			'Kitcharao',
			'Kansipati',
			'Kaliling',
			'Japitan',
			'Jandayan Norte',
			'Ipil',
			'Ilangay',
			'Iba',
			'Hukay',
			'Guimbal',
			'Enrile',
			'Dulao',
			'Dimiao',
			'Dayapan',
			'Dawa-Dawa',
			'Dangcalan',
			'Dalwangan',
			'Dagohoy',
			'Cut-cut Primero',
			'Curuan',
			'Corella',
			'Consuegra',
			'Claveria',
			'Cayanga',
			'Catmon',
			'Cataingan',
			'Carusucan',
			'Gambalidio',
			'San Isidro',
			'Calauag',
			'Calamagui East',
			'Caditaan',
			'Cabadiangan',
			'Butig',
			'Bularit',
			'Bual',
			'Bolila',
			'Bokod',
			'Buayan',
			'Bigaa',
			'Bibiclat',
			'Belison',
			'Bayombong',
			'Bato',
			'Basco',
			'Baquero Norte',
			'Banaba',
			'Bamban',
			'Balogo',
			'Balabag',
			'Baguer',
			'Bagu',
			'Bacon',
			'Bacolor',
			'Arminia',
			'Antipolo',
			'Alegria',
			'Alabat',
			'Aglalana',
			'Abulug',
			'Tigpalay',
			'Yubo',
			'Vargas',
			'Valencia',
			'Uyugan',
			'Tinalmud',
			'Tigbinan',
			'Tiep',
			'Tibiao',
			'Tariji',
			'Tandoc',
			'Tambalan',
			'Platagata',
			'Tal I Mun Doc',
			'Tagapul-an',
			'Taft',
			'Sulucan',
			'Sulop',
			'Suba',
			'Simimbaan',
			'Saraza',
			'Sapadun',
			'Santol',
			'Santa Monica',
			'Santa Fe',
			'Santa Catalina',
			'San Roque',
			'San Ricardo',
			'San Rafael',
			'San Rafael',
			'San Quintin',
			'San Pedro',
			'San Miguel',
			'San Luis',
			'San Juan',
			'San Jorge',
			'San Isidro',
			'San Felipe',
			'San Benito',
			'San Antonio',
			'San Agustin',
			'San Salvador',
			'Salinungan Proper',
			'Salcedo',
			'Sadsalan',
			'Rizal',
			'Rizal',
			'Ragandang',
			'Quirino',
			'Punta Silum',
			'Pudoc',
			'Polo',
			'Plaridel',
			'Panadtaban',
			'Pilar',
			'Pantubig',
			'Paniqui',
			'Pangulayan',
			'Panay',
			'Padada',
			'Oracon',
			'Nueva Fuerza',
			'New Washington',
			'New Panay',
			'Nangka',
			'Rodriguez',
			'Moalboal',
			'Merida',
			'Mercedes',
			'Mayabon',
			'Manticao',
			'Manoc-Manoc',
			'Mangas',
			'Maluko',
			'Malinaw',
			'Malasugui',
			'Malainen Luma',
			'Malabag',
			'Magpet',
			'Macatbong',
			'Loyola',
			'Leganes',
			'Lapi',
			'Lao',
			'Lagawe',
			'Kauran',
			'Kalugmanan',
			'Josefina',
			'Jolo',
			'Jaro',
			'Jaen',
			'Jabonga',
			'Iriga City',
			'Impalutao',
			'Idio',
			'Gutalac',
			'Guisguis',
			'Guihing Proper',
			'Glan Peidu',
			'Estefania',
			'Dumarais',
			'Dumalag',
			'Dulangan',
			'Dulag',
			'Diadi',
			'De la Paz',
			'Dalipuga',
			'Concepcion',
			'Concepcion',
			'Clarin',
			'Cayanguan',
			'Cavinti',
			'Casisang',
			'Carpenter Hill',
			'Carmen',
			'Caramutan',
			'Caramoran',
			'Canmaya Diot',
			'Caminauit',
			'Calbayog City',
			'Calayaan',
			'Caigangan',
			'Caburan',
			'Cabiao',
			'Cabatuan',
			'Cabarroguis',
			'Cabannungan Second',
			'Cabanbanan',
			'Burias',
			'Burgos',
			'Bulusan',
			'Buga',
			'Bued',
			'Borbon',
			'Bone South',
			'Boliney',
			'Bobonan',
			'Bobon',
			'Bitaogan',
			'Biking',
			'Bignay Uno',
			'Bialong',
			'Baugo',
			'Batobalane',
			'Batasan',
			'Bani',
			'Bangkal',
			'Bangad',
			'Banaybanay',
			'Balungao',
			'Bal-os',
			'Balocawehay',
			'Baloc',
			'Baliuag Nuevo',
			'Balayang',
			'Balas',
			'Bailan',
			'Bah-Bah',
			'Bagombong',
			'Argao',
			'Aquino',
			'Apoya',
			'Luna',
			'Anoring',
			'Anda',
			'Alpaco',
			'Alangilanan',
			'Alabug',
			'Aganan',
			'Margos',
			'Maranding',
			'Damabalas',
			'Dado',
			'Mataya',
			'Wañgan',
			'Vista Alegre',
			'Vigan',
			'East Valencia',
			'Unzad',
			'Tucuran',
			'Tibigan',
			'Tubajon',
			'Tortosa',
			'Tombod',
			'Tiring',
			'Taytay',
			'Tarong',
			'Tambo',
			'Talisay',
			'Tabinay',
			'Tabiauan',
			'Sual',
			'Sinacaban',
			'Semut',
			'Santol',
			'Santa Monica',
			'Santa Maria',
			'Santa Maria',
			'Santa Cruz',
			'San Pablo',
			'San Juan de Mata',
			'San Jose',
			'San Jose',
			'San Fernando',
			'San Dionisio',
			'Sampaloc',
			'Sagbayan',
			'Sablig',
			'Quinarayan',
			'Quezon',
			'Punta',
			'Pozorrubio',
			'Port Area',
			'Polopina',
			'Pogonsili',
			'Paulba',
			'Patique',
			'Paranaque City',
			'Panganiban',
			'Pandacaqui',
			'Oton',
			'Orion',
			'Olingan',
			'Nueva Vida Sur',
			'Niugan',
			'Nibaliw Central',
			'Nasugbu',
			'Masonogan',
			'Narra',
			'Nagbukel',
			'Nagbalaye',
			'Morong',
			'Montilla',
			'Mataas Na Kahoy',
			'Miliroc',
			'Maripipi',
			'Maribong',
			'Maribojoc',
			'Manuel Roxas',
			'Mantampay',
			'Mandaon',
			'Maluñgun',
			'Malicboy',
			'Malangas',
			'Mahayag',
			'Mahayag',
			'Magdiwang',
			'Mabitac',
			'Mabini',
			'Mabini',
			'Kabayan',
			'Lunec',
			'Lunas',
			'Lumil',
			'Locmayan',
			'Loma de Gato',
			'Loqueb Este',
			'Linaon',
			'Linabuan Sur',
			'Libuganon',
			'Libas',
			'Lasam',
			'Lantangan',
			'Lanigay',
			'Lamitan City',
			'Lalawigan',
			'Lagonoy',
			'Kulaman',
			'Kudanding',
			'Kibungan',
			'Kiamba',
			'Kaytitinga',
			'Kanlagay',
			'Kabankalan',
			'Jose Pañganiban',
			'Jones',
			'Irosin',
			'Imelda',
			'Hingatungan',
			'Hermosa',
			'Hapao',
			'Halog West',
			'Halapitan',
			'Guinisiliban',
			'Guinsang-an',
			'Guiguinto',
			'Gatbo',
			'Estancia',
			'Esperanza East',
			'Dumanjog',
			'Dugongan',
			'Doyong',
			'Dos Hermanas',
			'Doña Remedios Trinidad',
			'Dampol',
			'Damortis',
			'Consuelo',
			'Conner',
			'Compostela',
			'Communal',
			'Columbio',
			'Clarin',
			'Caronoan West',
			'Carcar',
			'Caramoan',
			'Cambuga',
			'Camalig',
			'Calintaan',
			'Calilayan',
			'Calanasan',
			'Cagmanaba',
			'Kaliliog',
			'Cabayangan',
			'Bulan',
			'Bugaan',
			'Bucloc',
			'Bolo',
			'Biga',
			'Betwagan',
			'Bautista',
			'Bauan',
			'Bateria',
			'Batan',
			'Basing',
			'Barcelona',
			'Banaba',
			'Balucuc',
			'Mamungan',
			'Balucawi',
			'Baliwagan',
			'Balingoan',
			'Bagontapay',
			'Bagac',
			'Bacabac',
			'Ambuclao',
			'Alubijid',
			'Alua',
			'Almaguer North',
			'Alitagtag',
			'Adela',
			'Abuyog',
			'East Migpulao',
			'Hanggan',
			'Ilaya',
			'Virac',
			'Valladolid',
			'Uyong',
			'Usab',
			'Ula',
			'Tunga',
			'Tuba',
			'Torrijos',
			'Tigtabon',
			'Tayasan',
			'Taungoh',
			'Tariric',
			'Tamorong',
			'Tambo',
			'Taloy',
			'Talitay',
			'Talisay',
			'Tagburos',
			'Surigao',
			'Songculan',
			'Sogod',
			'Sipocot',
			'Sinubong',
			'Siniloan',
			'Sikatuna',
			'Santor',
			'Santa Lucia',
			'Santa Cruz',
			'Santa Cruz',
			'Santa Catalina',
			'San Pedro',
			'San Nicolas',
			'San Nicolas',
			'San Miguel',
			'San Luis',
			'San Isidro',
			'San Gregorio',
			'San Felipe',
			'Poblacion, San Felipe',
			'San Antonio',
			'San Andres',
			'San Agustin',
			'Salvacion',
			'Salvacion',
			'Sabang',
			'Rotonda',
			'Rosario',
			'Rosario',
			'Romblon',
			'Quezon',
			'Pontian',
			'Pulungmasle',
			'Pulilan',
			'Pualas',
			'Patong',
			'Pilar',
			'Pawing',
			'Pawili',
			'Pau',
			'Patuto',
			'Palayan City',
			'Pagatin',
			'Pagalungan',
			'Odiong',
			'Norzagaray',
			'Naga',
			'Munai',
			'Minapasoc',
			'Matiompong',
			'Mati',
			'Matanao',
			'Mantalongon',
			'Mankayan',
			'Mambago',
			'Malaybalay',
			'Mabini',
			'Mabini',
			'Mabinay',
			'Lun Pequeño',
			'Lourdes',
			'Los Arcos',
			'Lopez',
			'Linmansangan',
			'Liloan',
			'Liloan',
			'Lila',
			'Liciada',
			'Layog',
			'Laurel',
			'Lapuz',
			'La Paz',
			'Laoang',
			'Kinabhangan',
			'Kibawe',
			'Cawayan',
			'Jayubó',
			'Janagdong',
			'Ilaya',
			'Guiling',
			'Garcia Hernandez',
			'El Nido',
			'Dualing',
			'Dapdap',
			'Dapa',
			'Damayan',
			'Consuelo',
			'Casay',
			'Carmelo',
			'Carles',
			'Caramay',
			'Capuluan',
			'Camingawan',
			'Calomboyan',
			'Calauag',
			'Calape',
			'Cabaritan East',
			'Cabangahan',
			'Cabacuñgan',
			'Cabacungan',
			'Caabiangan',
			'Butag',
			'Buruanga',
			'Burgos',
			'Buray',
			'Bungahan',
			'Buawan',
			'Bulualto',
			'Buliran Segundo',
			'Bulasa',
			'Bula',
			'Buguias',
			'Bugas',
			'Buga',
			'Bungabon',
			'Bolo Bolo',
			'Binay',
			'Biñan',
			'Bauko',
			'Batana',
			'Baracatan',
			'Banilad',
			'Baculin',
			'Bacnor East',
			'Babak',
			'Baao',
			'Algeciras',
			'Aguilar',
			'Abuyog',
			'Lumbog',
			'Binuatan',
			'Nunguan',
			'Kapaya',
			'Victoria',
			'Union',
			'Tupi',
			'Tungawan',
			'Tubay',
			'Tuao',
			'Tomado',
			'Taytay',
			'Tarangnan',
			'Tanay',
			'Talisay',
			'Taypano',
			'Tagbina',
			'Tacadang',
			'Tabuk',
			'Satan',
			'Sariaya',
			'Sapian',
			'San Vicente',
			'San Vicente',
			'Santa Rosa',
			'Santa Maria',
			'Santa Filomena',
			'Santa Fe',
			'San Simon',
			'San Nicolas',
			'San Narciso',
			'San Luis',
			'San Juan',
			'San Juan',
			'Tabacao',
			'San Isidro',
			'San Francisco',
			'San Fernando',
			'San Fernando',
			'San Carlos',
			'Sampiro',
			'Sagunto',
			'Sagasa',
			'Rosario',
			'Hacienda Refugio',
			'Ramon',
			'Ragay',
			'Putat',
			'Pulangbato',
			'President Roxas',
			'Polangui',
			'Plaridel',
			'Pili',
			'Pili',
			'Pias',
			'Peñaranda',
			'Patpata Segundo',
			'Pasay',
			'Paulba',
			'Pambisan',
			'Palagao Norte',
			'Osiao',
			'Natonin',
			'Naguilian',
			'Mina',
			'Mayngaran',
			'Maygatasan',
			'Masantol',
			'Maramag',
			'Manlucahoc',
			'Manibaug Pasig',
			'Mangga',
			'Mamatid',
			'Malidong',
			'Malabonot',
			'Mainit',
			'Lungog',
			'Luna',
			'Loreto',
			'Lingig',
			'Linamon',
			'Liloan',
			'Libog',
			'Libagon',
			'La Paz',
			'De la Paz',
			'Laguitas',
			'Lacson',
			'Labney',
			'Cogon',
			'Kiupo',
			'Kinalansan',
			'Kinalaglagan',
			'Katidtuan',
			'Jovellar',
			'Ipil',
			'Ineangan',
			'Imbatug',
			'Iloilo',
			'Ilihan',
			'Iligan City',
			'Iguig',
			'Iba',
			'Hinundayan',
			'Guinsadan',
			'Guibodangan',
			'Guadalupe',
			'Gadu',
			'Dumabato',
			'Dukay',
			'Dueñas',
			'Dobdoban',
			'Diamantina',
			'Cuartero',
			'Concordia',
			'Cawayan',
			'Cardona',
			'Capalayan',
			'Canauay',
			'Campoyo',
			'Camiling',
			'Calongbuyan',
			'Calatagan',
			'Calasgasan',
			'Calaoagan',
			'Cadlan',
			'Cabatuan',
			'Cabadbaran',
			'Butubut Norte',
			'Burgos',
			'Brgy. Bulwang, Numancia',
			'Buldon',
			'Bugcaon',
			'Bugallon',
			'Buenavista',
			'Buenavista',
			'Bood',
			'Binitinan',
			'Binitayan',
			'Bilog-Bilog',
			'Biasong',
			'Bayawan',
			'Batad',
			'Baud',
			'Bantay',
			'Bangkirohan',
			'Banog Sur',
			'Banugao',
			'Balutakay',
			'Balimbing',
			'Balatan',
			'Bacnar',
			'Asturga',
			'Apurawan',
			'Anticala',
			'Alubijid',
			'Alicante',
			'Ag-ambulong',
			'Adtugan',
			'Accusilian',
			'Abucay',
			'Laparay',
			'Busok',
			'Biay',
			'Valencia',
			'Valencia',
			'Uyaan',
			'Uson',
			'Upi',
			'Uling',
			'Tutubigan',
			'Tubod',
			'Tiwi',
			'Tiguion',
			'Tanolong',
			'Talisayan',
			'Tabuan',
			'Suso',
			'Sulat',
			'Sulangan',
			'Soyung',
			'Solo',
			'Sitangkai',
			'Sinala',
			'Silanga',
			'Siay',
			'San Vicente',
			'San Vicente',
			'Santo Domingo',
			'Santa Angel',
			'Santa Fe',
			'San Pedro',
			'San Pascual',
			'San Mariano',
			'San Juan',
			'San Antonio',
			'Salvacion',
			'Sagurong',
			'Rizal',
			'Quinagaringan',
			'Punang',
			'Prieto Diaz',
			'Polong Norte',
			'Polanco',
			'Pitogo',
			'Pintuyan',
			'Pilar',
			'Payao',
			'Pasibi West',
			'Parang',
			'Pantay Na Matanda',
			'Pantar',
			'Pajo',
			'Ocaña',
			'New Visayas',
			'New Lagao',
			'Napalitan',
			'Nangan',
			'Nagtipunan',
			'Mungo',
			'Minuri',
			'Matagbak',
			'Masaya Sur',
			'Masaya',
			'Masarayao',
			'Maricaban',
			'Marbel',
			'Marawi City',
			'Marantao',
			'Mabahin',
			'Maltana',
			'Malingin',
			'Maliig',
			'Makati City',
			'Mahala',
			'Magalang',
			'Madamba',
			'Maanas',
			'Luuk Datan',
			'Lumatil',
			'Lucero',
			'Lucapon',
			'Lobo',
			'Linabo',
			'Limpapa',
			'Libertad',
			'Lemery',
			'Lawa-an',
			'Langtad',
			'Lampitak',
			'La Hacienda',
			'Labuñgan',
			'Kilim',
			'Kiangan',
			'Kapingkong',
			'Jibao-an',
			'Irasan',
			'Indang',
			'Hinlayagan Ilaud',
			'Hamtic',
			'Gumaus',
			'Guiong',
			'Guadalupe',
			'Goma',
			'Esperanza',
			'Dorongan Punta',
			'Digal',
			'Dapdap',
			'Culianan',
			'Catuday',
			'Caracal',
			'Canturay',
			'Cansolungon',
			'Candelaria',
			'Canayan',
			'Camambugan',
			'Caloc-an',
			'Cabilauan',
			'Cabanbanan',
			'Caba',
			'Busing',
			'Burirao',
			'Burabod',
			'Buluan',
			'Bukal Sur',
			'Buguey',
			'Buenavista',
			'Bued',
			'Buanoy',
			'Bolilao',
			'Binalan',
			'Basiad',
			'Barcelona',
			'Barahan',
			'Banquero',
			'Bañga',
			'Balsic',
			'Calamundingan',
			'Balele',
			'Bahay',
			'Bagumbayan',
			'Baguio',
			'Bagtic',
			'Bagong-Sikat',
			'Bagong Barrio',
			'Bagacay',
			'Bagacay',
			'Badlan',
			'Bactad Proper',
			'Bacalan',
			'Antequera',
			'Angatel',
			'Anao',
			'Aloleng',
			'Alcantara',
			'Agoo',
			'Adlay',
			'Abut',
			'Tablas',
			'Sumalig',
			'Kapalong',
			'Bonbon',
			'Santo Niño',
			'Basiawan',
			'Upig',
			'Umanday Centro',
			'Uddiawan',
			'Uacon',
			'Tondol',
			'Taytayan',
			'Tara',
			'Tampilisan',
			'Sugbongkogon',
			'Sinolon',
			'Sinait',
			'Simanu Sur',
			'Sillon',
			'Sigma',
			'Sapang',
			'Santo Domingo',
			'Santo Domingo',
			'Santiago',
			'San Teodoro',
			'Santa Teresa',
			'Santa Maria',
			'Santa Maria',
			'Santa Lucia',
			'Santa Elena',
			'Santa Cruz',
			'Santa Catalina Sur',
			'San Sebastian',
			'Lloren',
			'San Miguel',
			'San Juan',
			'San Joaquin',
			'Del Monte',
			'San Emilio',
			'Salvacion',
			'Salawagan',
			'Sagrada',
			'Sabang',
			'Sabang',
			'Saavedra',
			'Rosario',
			'Rizal',
			'Quipot',
			'Pulo',
			'Prosperidad',
			'Pinagsabangan',
			'Pilar',
			'Paoay',
			'Panikian',
			'Panabuan',
			'Palangue',
			'Palampas',
			'Pagadian',
			'Paagahan',
			'Osmeña',
			'Oslob',
			'Olongapo',
			'New Bohol',
			'Bantacan',
			'Montevista',
			'Mogpog',
			'Mileb',
			'Mexico',
			'Medina',
			'McKinley',
			'Mayantoc',
			'Masaguisi',
			'Mapaniqui',
			'Mambusao',
			'Mambulo',
			'Malinao',
			'Malawa',
			'Malabuyoc',
			'Magsalangi',
			'Magdug',
			'Lurugan',
			'Luntal',
			'Luksuhin',
			'Lucap',
			'Lopez Jaena',
			'Limay',
			'Libertad',
			'Libas',
			'Leon',
			'Lapu-Lapu City',
			'Lantangan',
			'Rancheria Payau',
			'La Granja',
			'Labnig',
			'Kaputian',
			'Kapatan',
			'Kampokpok',
			'Kalamansig',
			'Jaguimitan',
			'Itogon',
			'City of Isabela',
			'Inawayan',
			'Inabanga',
			'Imus',
			'Ilioilio',
			'Igcocolo',
			'Ibajay',
			'Hipasngo',
			'Dolores',
			'Guba',
			'Guadalupe',
			'Gainza',
			'Dumalneg',
			'Domampot',
			'Dologon',
			'Dingalan',
			'Dinahican',
			'Dicamay',
			'Dagumba-an',
			'Culianin',
			'Cuenca',
			'Coronon',
			'Corcuera',
			'Colonia',
			'Causwagan',
			'Caterman',
			'Carmen',
			'Caraycaray',
			'Canhandugan',
			'Canaman',
			'Calape',
			'Calape',
			'Calaba',
			'Cabatang',
			'Cabangan',
			'Cabagan',
			'Boroon',
			'Bulihan',
			'Buenavista',
			'Buenavista',
			'Bolboc',
			'Bocana',
			'Beddeng',
			'Basak',
			'Basak',
			'Barotac Nuevo',
			'Barobo',
			'Baro',
			'Bankaw',
			'Bangahan',
			'Bangad',
			'Banayoyo',
			'Bambang',
			'Baliuag',
			'Balite',
			'Baler',
			'Balanga',
			'Balabag',
			'Bagamanoc',
			'Bacuyangan',
			'Bacundao Weste',
			'Babatngon',
			'Aurora',
			'Cambanugoy',
			'Apitong',
			'Antipolo',
			'Altavas',
			'Alilem',
			'Alamada',
			'Agbannawag',
			'Sangali',
			'Lapining',
			'Bulihan',
			'Pagalungan',
			'Pandan',
			'Yeban Norte',
			'Wao',
			'Valle Hermoso',
			'Ubay',
			'Toritori',
			'Tamayong',
			'Tipolo',
			'Tiaong',
			'Telabastagan',
			'Taysan',
			'Tapel',
			'Talavera',
			'Tagoytoy',
			'Tagana-an',
			'Tabio',
			'Sumisip',
			'Solsona',
			'Sinuknipan',
			'Silongin',
			'Silae',
			'San Vicente',
			'San Vicente',
			'Santo Tomas',
			'Santa Nino',
			'Santiago',
			'Santander Poblacion',
			'Santa Maria',
			'San Roque Dau First',
			'San Roque',
			'San Roque',
			'San Policarpo',
			'San Miguel',
			'San Mateo',
			'Sankanan',
			'San Juan',
			'San Jose',
			'San Jose',
			'San Jacinto',
			'San Francisco',
			'San Felipe Old',
			'San Fabian',
			'Saint Bernard',
			'Sagana',
			'Rizal',
			'Quezon',
			'Puting Kahoy',
			'Pualas',
			'Prosperidad',
			'Peña',
			'Pasacao',
			'Pansol',
			'Panlaitan',
			'Panayacan',
			'Palanas',
			'Paitan Este',
			'Ormoc',
			'Nueva Valencia',
			'Muti',
			'Motiong',
			'Mondragon',
			'Monbon',
			'Mianay',
			'Mendez-Nuñez',
			'Maulawin',
			'Maticmatic',
			'Mataywanac',
			'Masinloc',
			'Masbate',
			'Mariano',
			'Manukan',
			'Manay',
			'Maloh',
			'Malitbog',
			'Malita',
			'Malasiqui',
			'Malaiba',
			'Mahanob',
			'Maguilling',
			'Magtangol',
			'Magsaysay',
			'Maglamin',
			'Magatos',
			'Madalag',
			'Maasin',
			'Lourdes',
			'Limbo',
			'Limbaan',
			'Libjo',
			'Leones East',
			'Lemery',
			'Larap',
			'Larap',
			'Lapolapo',
			'Tairan Camp',
			'Lanat',
			'Lanas',
			'Lamba',
			'Labayug',
			'Kolape',
			'Kitobo',
			'Kalilangan',
			'Kalandagan',
			'Jetafe',
			'Janiuay',
			'Isabang',
			'Inobulan',
			'Himensulan',
			'Harrison',
			'Haligue',
			'Guijalo',
			'Guagua',
			'Gocoton',
			'Gines-Patay',
			'Gappal',
			'Gamu',
			'Fuerte',
			'Evangelista',
			'Esperanza',
			'Esperanza',
			'Esperanza',
			'Dulangan',
			'Diplahan',
			'Dipolo',
			'Dinalupihan',
			'Dimasalang',
			'Dicabisagan',
			'Viejo Daan Banua',
			'Cavite',
			'Casiguran',
			'Camp Flora',
			'Calape',
			'Cagamotan',
			'Cabusao',
			'Cabalawan',
			'Busdi',
			'Bunguiao',
			'Buluang',
			'Botigues',
			'Bongued',
			'Bolitoc',
			'Bogo',
			'Buan',
			'Bitangan',
			'Bintacay',
			'Binabalian',
			'Bau',
			'Batal',
			'Basa',
			'Balutu',
			'Balinsacayao',
			'Balibago',
			'Balagunan',
			'Baka',
			'Bacsay',
			'Bacong',
			'Aroroy',
			'Aramayuan',
			'Antipolo',
			'Anahawan',
			'Amuñgan',
			'Aglayan',
			'Mangusu',
			'City of Ligao',
			'Wawa',
			'Viga',
			'Tubli',
			'Titay',
			'Tangke',
			'Tangal',
			'Tambong',
			'Tambac',
			'Taloy',
			'Talisay',
			'Taganak',
			'Sarangani',
			'San Vicente',
			'Santo Niño',
			'Santo Domingo',
			'Santiago',
			'Santa Maria',
			'Santa Barbara',
			'San Roque',
			'San Rafael',
			'San Miguel',
			'San Miguel',
			'San Martin',
			'San Juan',
			'San Isidro',
			'San Isidro',
			'San Clemente',
			'San Andres',
			'Sampagar',
			'Sagada',
			'Sadanga',
			'Rominimbang',
			'Punta',
			'Pulo',
			'Ponso',
			'Polonoling',
			'Paypay',
			'Pasil',
			'Parangan',
			'Panukulan',
			'Pangapisan',
			'Panganiban',
			'Padre Burgos',
			'Paco Roman',
			'Owak',
			'Napuro',
			'Nangka',
			'Nampicuan',
			'Nambalan',
			'Naga',
			'Nabulao',
			'Muñoz East',
			'Moriones',
			'Meycauayan',
			'Manuk Mangkaw',
			'Manubul',
			'Malilinao',
			'Manicahan',
			'Manaloal',
			'Mandili',
			'Malusac',
			'Malanday',
			'Malabago',
			'Magsaysay',
			'Magallanes',
			'Madulao',
			'Madrid',
			'Macalong',
			'Macalamcam A',
			'Luba',
			'Lipay',
			'Linao',
			'Lilio',
			'Ligaya',
			'Libertad',
			'Laylay',
			'La Roxas',
			'La Paz',
			'Langatian',
			'Laiya',
			'Labangan',
			'Katangawan',
			'Kalamangog',
			'Jasaan',
			'Iraya',
			'Hilotongan',
			'Guinacotan',
			'Gibong',
			'General Mamerto Natividad',
			'Gabi',
			'Furao',
			'Feliciano',
			'Dumaran',
			'Dumalinao',
			'Dinagat',
			'Dancagan',
			'Dalumangcob',
			'Dagupan',
			'Dagatan',
			'Cullalabo del Sur',
			'Culasi',
			'Coron',
			'Cawayan',
			'Catarman',
			'Catanauan',
			'Castañas',
			'Carmen',
			'Caridad',
			'Candoni',
			'Candijay',
			'Candating',
			'Canaoalan',
			'Calumpang',
			'Calumpang',
			'Calaba',
			'Cabucgayan',
			'Cabanglasan',
			'Bustos',
			'Bulit',
			'Bugsoc',
			'Bugang',
			'Bolo',
			'Bolo',
			'Boac',
			'Bitin',
			'Biri',
			'Binanwanaan',
			'Bil-Loca',
			'Besao',
			'Bayang',
			'Bayambang',
			'Batarasa',
			'Baroy',
			'Bangar',
			'Balud',
			'Balogo',
			'Balite Segundo',
			'Balingueo',
			'Balinad',
			'Balibago',
			'Balagui',
			'Baguinge',
			'Bagacay',
			'Buadtasan',
			'Baculongan',
			'Bakulong',
			'Anilao',
			'Alupay',
			'Alijis',
			'Alac',
			'Alabel',
			'Lamisahan',
			'Dalangin',
			'Binuang',
			'Del Pilar',
			'Makir',
			'Paranas',
			'Union',
			'Tuding',
			'Tubod',
			'Tococ East',
			'Taywanak Ilaya',
			'Tawan tawan',
			'Tanza',
			'Tamiso',
			'Taluksangay',
			'Talon',
			'Talisay',
			'Tala',
			'Tacub',
			'Tabu',
			'Tubao',
			'Tabina',
			'Suyan',
			'Sumilao',
			'Sinayawan',
			'Sevilla',
			'Sapu Padidu',
			'Santo Niño',
			'Santa Teresita',
			'Santa Maria',
			'Santa Juliana',
			'Santa Cruz',
			'Santa Clara',
			'San Sebastian',
			'San Rafael',
			'San Juan',
			'San Juan',
			'San Jose',
			'San Isidro',
			'San Francisco',
			'San Francisco',
			'Rosario',
			'Rizal',
			'Rapu-Rapu',
			'Pinit',
			'Pines',
			'Pigcawayan',
			'Perrelos',
			'Parang',
			'Paniqui',
			'Palimbang',
			'Palguyod',
			'Padre Garcia',
			'Orong',
			'Novallas',
			'New Leyte',
			'Nalus',
			'Brgy. Nalook, kalibo',
			'Nabannagan West',
			'Mozon',
			'Miranda',
			'Minien East',
			'Matlang',
			'Malinao',
			'Matalom',
			'Matalam',
			'Masipi West',
			'Marogong',
			'Mapolopolo',
			'Mantiquil',
			'Manaulanan',
			'Managa',
			'Mampurog',
			'Malitbog',
			'Maligaya',
			'Malbug',
			'Malay',
			'Magapit, Aguiguican',
			'Maddarulug Norte',
			'Mabini',
			'Maayon',
			'Lugait',
			'Loboc',
			'Llorente',
			'Lianga',
			'La Trinidad',
			'Labason',
			'Comagascas',
			'Kinogitan',
			'Kagay',
			'Inicbulan',
			'Guiwanon',
			'Guimba',
			'Guadalupe',
			'General Santos',
			'General MacArthur',
			'Ganassi',
			'Gabaldon',
			'Eraan',
			'Enrique Villanueva',
			'Dimaluna',
			'Digdig',
			'Dibuluan',
			'Daraitan',
			'Damulog',
			'Daanbantayan',
			'Cotabato',
			'Cordova',
			'Conversion',
			'Cogon Cruz',
			'Catayauan',
			'Catarman',
			'Catarman',
			'Cardona',
			'Caranan',
			'Capaga',
			'Cansuje',
			'Camalobalo',
			'Camaley',
			'Calolbon',
			'Calauan',
			'Calasiao',
			'Calantas',
			'Calanogas',
			'Calamba',
			'Calaca',
			'Bugasong',
			'Bonga',
			'Binonga',
			'Biao',
			'Bel Air',
			'Batasan Bata',
			'Batasan',
			'Basiao',
			'Basdiot',
			'Barugo',
			'Barong Barong',
			'Barbaza',
			'Bantilan',
			'Balogo',
			'Balilihan',
			'Balibago',
			'Balete',
			'Balangiga',
			'Balangbalang',
			'Balabagan',
			'Bao',
			'Bagumbayan',
			'Bacolod City',
			'Aurora',
			'Arcangel',
			'Anambongan',
			'Amdos',
			'Alibug',
			'Alanib',
			'Aborlan',
			'Talabaan',
			'Kajatian',
			'Kauswagan',
			'Tagnanan',
			'Lim-oo',
			'Cambarus',
			'Zarraga',
			'Odala',
			'Tubod',
			'Tubigan',
			'Tuban',
			'Tranca',
			'Tiglauigan',
			'Taytay',
			'Tañgo',
			'Tambongon',
			'Talomo',
			'Talahib Payap',
			'Tajao',
			'Tagusao',
			'Suklayin',
			'Sindangan',
			'Silvino Lobos',
			'Sibutao',
			'Sibul',
			'Siayan',
			'Santol',
			'Santa Paz',
			'Santa Cruz',
			//'Santa Ana',
			'San Sebastian',
			'Sonquil',
			'San Juan',
			'Malino',
			'San Ignacio',
			'San Eugenio',
			'Sandolot',
			'San Andres',
			'San Agustin',
			'San Agustin',
			'Sampaloc',
			'Salogon',
			'Salcedo',
			'Sabtang',
			'Rosario',
			'Rizal',
			'Ransang',
			'Quisao',
			'Quirino',
			'Putlod',
			'Prinza',
			'Polloc',
			'Payocpoc Sur',
			'Pasil',
			'Parista',
			'Paradahan',
			'Pananaw',
			'Pamantingan',
			'Palo',
			'Paitan Norte',
			'Ogod',
			'Odiong',
			'Marawis',
			'Nahawan',
			'Naagas',
			'Minallo',
			'Matingain',
			'Masaling',
			'Manito',
			'Malasin',
			'Malaruhatan',
			'Macayug',
			'Lumuyon',
			'Lumbia',
			'Lumangbayan',
			'Luisiana',
			'Looc',
			'Lono',
			'Lombog',
			'Lingating',
			'Linabuan',
			'Lapuyan',
			'Labo',
			'Kimanuit',
			'Kalayaan',
			'Casuguran',
			'Hipona',
			'Guintubhan',
			'Guihulñgan',
			'Guiddam',
			'Goa',
			'Saravia',
			'Dumarao',
			'Dimataling',
			'Digos',
			'Digkilaan',
			'Dasmariñas',
			'Comillas',
			'Catbalogan',
			'Capul',
			'Canomoy',
			'Corocotan',
			'Can-asujan',
			'Ganapi',
			'Calepaan',
			'Calatrava',
			'Calantac',
			'Cabugao',
			'Cabayaoasan',
			'Busilak',
			'Busay',
			'Burgos',
			'Bunol',
			'Bunawan',
			'Bulalacao',
			'Bitoon',
			'Bitadtun',
			'Binubusan',
			'Binondo',
			'Binon-an',
			'Bilwang',
			'Begang',
			'Bato',
			'Basak',
			'Bantayan',
			'Bangao',
			'Bancasi',
			'Ballesteros',
			'Baligang',
			'Brgy. Bachaw Norte, Kalibo',
			'Bagroy',
			'Bagan',
			'Bagakay',
			'New Baclayon',
			'Baay',
			'Ayusan Uno',
			'Aurora',
			'Anuling',
			'Andres Bonifacio',
			'Allen',
			'Albuera',
			'Alae',
			'Agno',
			'Tubo',
			'Adlaon',
			'Abilay',
			'Tolosa',
			'Monching',
			'Tiguha',
			'Lambontong',
			'Villarosa',
			'Tungol',
			'Tugos',
			'Tobuan',
			'Tinaan',
			'Tibanbang',
			'Tayud',
			'Tapon',
			'Talisay',
			'Talakag',
			'Tagbita',
			'Tabonok',
			'Tabuelan',
			'Subusub',
			'Sogod',
			'Sierra Bullones',
			'San Vicente',
			'San Vicente',
			'Santo Tomas',
			'Santa Rosa',
			'Santa Fe',
			'San Ramon',
			'San Nicolas',
			'San Julian',
			'San Jose',
			'San Jose',
			'San Jose',
			'San Isidro',
			'San Fernando',
			'San Enrique',
			'San Enrique',
			'San Carlos',
			'Samon',
			'Sambuluan',
			'Samal',
			'Salingogan',
			'Sagang',
			'Ramos West',
			'Purac',
			'President Quirino',
			'Potia',
			'Pontevedra',
			'Puloypuloy',
			'Polong',
			'Pola',
			'Plaridel',
			'Pio',
			'Pinugay',
			'Pasong Kawayan Primero',
			'Pantao',
			'Pandan',
			'Palompon',
			'Paitan',
			'Pag-asa',
			'Osias',
			'Obong',
			'Noveleta',
			'New Sibonga',
			'Lucena',
			'Natalungan',
			'Nanyo',
			'Muntinlupa',
			'Maya',
			'Mawab',
			'Mantang',
			'Mantalongon',
			'Maninihon',
			'Manquiring',
			'Mangoso',
			'Mandaue City',
			'Manatal',
			'Manapa',
			'Mambatangan',
			'Malitbog',
			'Malibago',
			'Malabog',
			'Mahabang Parang',
			'Magdalena',
			'Mabuttal East',
			'Mabuhay',
			'Mabiton',
			'Lucena',
			'Lingion',
			'Liboro',
			'Liberty',
			'Lian',
			'Lawa-an',
			'Las Navas',
			'Laoag',
			'Lalig',
			'La Libertad',
			'Lagangilang',
			'La Curva',
			'La Castellana',
			'Kumalarang',
			'Kolumbug',
			'Kidapawan',
			'Kananya',
			'Kabulohan',
			'Isulan',
			'Infanta',
			'Igbon',
			'Himaya',
			'Halayhayin',
			'Giawang',
			'Guindulman',
			'General Trias',
			'General Nakar',
			'Gapan',
			'Flora',
			'Espiritu',
			'Doljo',
			'Daraga',
			'Dao',
			'Cogon',
			'Carranglan',
			'Caraga',
			'Canubing No 2',
			'Cantapoy',
			'Caningay',
			'Candon',
			'Cama Juan',
			'Calabaca',
			'Cajidiocan',
			'Cagbang',
			'Cabaluay',
			'Bulacan',
			'Bukal',
			'Borongan',
			'Bodega',
			'Binantocan',
			'Binulasan',
			'Basey',
			'Bantogon',
			'Balanacan',
			'Baheli',
			'Bahay Pare',
			'Badiangan',
			'Bacungan',
			'Bacong',
			'Bacarra',
			'Bacag',
			'Arayat',
			'Antipolo',
			'Angad',
			'Amagbagan',
			'Amacalan',
			'Almagro',
			'Alegria',
			'Alannay',
			'Yumbing',
			'Wright',
			'Vinzons',
			'Veruela',
			'Tuao',
			'Tinago',
			'Tigao',
			'Tayum',
			'Tayabo',
			'Tawala',
			'Tapikan',
			'Tanza',
			'Tanza',
			'Tamparan',
			'Tampakan',
			'Tambo',
			'Tacurong',
			'Tacloban',
			'Taal',
			'Siguinon',
			'Santo Domingo',
			'Santa Josefa',
			'Santa Ignacia',
			'Santa Fe',
			'Santa Cruz',
			'Santa Catalina Norte',
			'San Nicolas',
			'San Mariano',
			'San Isidro',
			'San Diego',
			'San Anton',
			'Salvacion',
			'Salvacion',
			'Rizal',
			'Ramon Magsaysay',
			'Puerto Galera',
			'Ponong',
			'Polo',
			'Pinambaran',
			'Piat',
			'Pato-o',
			'Patindeguen',
			'Pantijan No 2',
			'Panikian',
			'Pangian',
			'Panalipan',
			'Palian',
			'Palauig',
			'Pagangan',
			'Olutanga',
			'Oaqui',
			'New Batu Batu',
			'Nena',
			'Nabunturan',
			'Montecillo',
			'Moises Padilla',
			'Mindupok',
			'Midsalip',
			'Miagao',
			'Mayorga',
			'Mayo',
			'Maxingal',
			'Matti',
			'Matanog',
			'Marcos',
			'Mapulot',
			'Mapandan',
			'Maonon',
			'Mambog',
			'Mamali',
			'Maloma',
			'Malibong East',
			'Malayo-an',
			'Malacampa',
			'Makilala',
			'Mainit',
			'Magsaysay',
			'Magsaysay',
			'Madalag',
			'Macrohon',
			'Macaas',
			'Maasim',
			'Lupi',
			'Lupao',
			'Lucsuhin',
			'Lomboy',
			'Linay',
			'Licuan',
			'Lepanto',
			'Lawigan',
			'Lacaron',
			'Cumadcad',
			'Kisolon',
			'Quipot',
			'Kibangay',
			'Kasibu',
			'Kabasalan',
			'Jaclupan',
			'Ibarra',
			'Hinunangan',
			'Hindang',
			'Himaao',
			'Herrera',
			'Garchitorena',
			'Ganyangan',
			'El Salvador',
			'El Pardo',
			'Eden',
			'Dungon',
			'Don Carlos',
			'Dingras',
			'Dancalan',
			'Cuyapo',
			'Cervantes',
			'Cayungnan',
			'Cauayan',
			'Carig',
			'Caridad',
			'Candaba',
			'Calatrava',
			'Burgos',
			'Bunga',
			'Bulaon',
			'Babug',
			// 'Boston',
			'Bontoc',
			'Bungsuan',
			'Binguang',
			'Payabon',
			'Binahaan',
			'Baybay',
			'Bayabas',
			'Bauang',
			'Battung',
			'Basilisa',
			'Bantayan',
			'Banilad',
			'Baliton',
			'Balete',
			'Bayanga',
			'Badian',
			'Bacolod',
			'Babag',
			'Apud',
			'Alfonso',
			'Agay',
			'Panubigan',
			'Badak',
			'Malandag',
			'Mariana',
			'Wines',
			'Valencia',
			'Urbiztondo',
			'Unidad',
			'Tuyum',
			'Tulay',
			'Tomas Oppus',
			'Tinoto',
			'Ticala-an',
			'Tangub',
			'Talangnan',
			'Patikul',
			'Tablac',
			'Tabaco',
			'Sula',
			'Suay',
			'Sinubong',
			'Sexmoan',
			'Sebaste',
			'San Vicente',
			'Santa Maria',
			'Santa Marcela',
			'Santa Clara',
			'San Pascual',
			'San Mateo',
			'San Juan',
			'San Jose',
			'San Joaquin',
			'San Gabriel',
			'San Francisco',
			'San Fernando',
			'San Esteban',
			'San Casimiro',
			'San Antonio',
			'Salvacion I',
			'Salimbao',
			'Sagay',
			'Rosario',
			'Kinatakutan',
			'Puricay',
			'Pulong Sampalok',
			'Ponot',
			'Plaridel',
			'Pio Duran',
			'Pedtad',
			'Payapa',
			'Patria',
			'Pasian',
			'Pangyan',
			'Pandi',
			'Paldit',
			'Paco',
			'Opol',
			'Naujan',
			'Namayan',
			'Naguilian',
			'Nabua',
			'Monamon',
			'Masaraway',
			'Maria Cristina',
			'Mapalacsiao',
			'Managok',
			'Maloco',
			'Malimono',
			'Malasin',
			'Mahinog',
			'Magdalena',
			'Madridejos',
			'Lapase',
			'Lucban',
			'Loay',
			'Llanera',
			'Little Baguio',
			'Litayan',
			'Libertad',
			'Lagindingan',
			'Labu-o',
			'Labasan',
			'Kulay-Kulay',
			'Kuanos',
			'Kipalbig',
			'Kalibo (poblacion)',
			'Kadingilan',
			'Kabacan',
			'Tugas',
			'Jubasan',
			'Jimalalud',
			'Iraray',
			'Inapatan',
			'Igbaras',
			'Idtig',
			'Guyam Malaki',
			'Guinticgan',
			'General Luna',
			'Gandara',
			'Gamut',
			'Estancia',
			'España',
			'Erenas',
			'Dumingag',
			'Dayap',
			'Davao',
			'Daragutan',
			'Dapitan',
			'Damilag',
			'Cordova',
			'Consolacion',
			'Cauayan',
			'Casambalangan',
			'Carriedo',
			'Cantilan',
			'Camalaniugan',
			'Camachile',
			'Calapan',
			'Caibiran',
			'Cagayan de Oro',
			'Cabog',
			'Cabay',
			'Burias',
			'Burgos',
			'Bukid',
			'Bulacan',
			'Buensuseso',
			'Bualan',
			'Bonga',
			'Bonbon',
			'Binodegahan',
			'Binidayan',
			'Binalonan',
			'Bayanan',
			'Bauan',
			'Batas',
			'Baras',
			'Bagong Tanza',
			'Baganga',
			'Badian',
			'Atok',
			'Araceli',
			'Angeles City',
			'Akle',
			'Agsungot',
			'Agdangan',
			'Aga',
			'Abuyon',
			'Abucay',
			'Abaca',
			'Maduao',
			'Malusay',
			'Tuka',
			'NIA Valencia',
			'Upper Klinan',
			'Unidos',
			'Tumarbong',
			'Tumalaytay',
			'Tuhian',
			'Tubao',
			'Topdac',
			'Tongouson',
			'Toledo',
			'Tiwi',
			'Tinutulan',
			'Tinongan',
			'Tinawagan',
			'Tinambac',
			'Tignapalan',
			'Tigbauan',
			'Tiblawan',
			'Tapon',
			'Tapia',
			'Tandayag',
			'Tamnag',
			'Tambilil',
			'Talipao',
			'Tagdanua',
			'Tabon',
			'Surup',
			'Sumagui',
			'Solano',
			'Sison',
			'San Vicente',
			'Santiago',
			'Santa Fe',
			'Santa Fe',
			'Santa Barbara',
			//'Santa Ana',
			'Santa',
			'San Patricio',
			'San Nicolas',
			'San Miguel',
			'San Jose',
			'San Francisco',
			'San Francisco',
			'San Fernando',
			'San Fabian',
			'Salinas',
			'Salimbalan',
			'Salaza',
			'Sagrada Familia',
			'Sagay',
			'Sagasa',
			'Sagana',
			'Sabang Indan',
			'Roxas',
			'Rimus',
			'Quinipot',
			'Pongol',
			'Progreso',
			'Pantukan',
			'Polillo',
			'Poctol',
			'Limbuhan',
			'Pindangan Centro',
			'Pikit',
			'Paringao',
			'Pantabangan',
			'Panitian',
			'Panique',
			'Pangpang',
			'Pangil',
			'Pandan Niog',
			'Pandan',
			'Pancol',
			'Pambujan',
			'Pakil',
			'Padapada',
			'Ogtongon',
			'Ocampo',
			'Nagrumbuan',
			'Moog',
			'Minuyan',
			'Mayana',
			'Mauhao',
			'Matungao',
			'Matayumtayum',
			'Maquiapo',
			'Maputi',
			'Mapili',
			'Mandih',
			'Mancilang',
			'Manaring',
			'Maluso',
			'Malway',
			'Malisbeng',
			'Malawag',
			'Malatap',
			'Malabanan',
			'Magsaysay',
			'Maganoy',
			'Mabini',
			'Maasim',
			'Lumbang',
			'Lookan',
			'Ligaya',
			'Libmanan',
			'Libertad',
			'Libertad',
			'Libas',
			'Libacao',
			'Lennec',
			'La Paz',
			'Lambakin',
			'Kolambugan',
			'Kawayan',
			'Karungdong',
			'Kapatagan',
			'Kalbugan',
			'Kabugao',
			'Irahuan',
			'Ipilan',
			'Ilagan',
			'Yuni',
			'Hibaiyo',
			'Haguimit',
			'Hagdan',
			'Glad',
			'General Tinio',
			'Gabao',
			'Esperanza',
			'Talagutong',
			'Dansuli',
			'Danglas',
			'Dalican',
			'Da-an Sur',
			'Cotmon',
			'Cortez',
			'Culacling',
			'Claveria',
			'Chambrey',
			'Kauit',
			'Catubig',
			'Cato',
			'Catmon',
			'Castilla',
			'Carmen',
			'Carmen',
			'Carigara',
			'Capucnasan',
			'Candiis',
			'Camias',
			'Calituban',
			'Colipapa',
			'Calinog',
			'Cabungan',
			'Buyo',
			'Buliok',
			'Bulalacao',
			'Buhi',
			'Buenavista',
			'Boton',
			'Bogo',
			'Bilar',
			'Bilao',
			'Bayabas',
			'Batutitik',
			'Basay',
			'Barlig',
			'Bantay',
			'Bansalan',
			'Banganan',
			'Balud',
			'Balogo',
			'Baleno',
			'Baggabag B',
			'Atabayan',
			'Arenas',
			'Santa Rita Aplaya',
			'Anuling',
			'Andili',
			'Amucao',
			'Amlimay',
			'Aliaga',
			'Agoncillo',
			'Taguitic',
			'Lopez Jaena',
			'Ayala Alabang',
			'Vito',
			'Umiray',
			'Ugac Sur',
			'Tumcon Ilawod',
			'Tucdao',
			'Tuburan',
			'Tomingad',
			'Tiparak',
			'Telafas',
			'Tangnan',
			'Tanauan',
			'Tamayo',
			'Talacogon',
			'Tagbilaran City',
			'Taclobo',
			'Tabuating',
			'Taboc',
			'Tabid',
			'Sungai',
			'Sorsogon',
			'Santo Tomas',
			'Santo Domingo',
			'Santa Rita',
			'Santa Maria',
			'Santa Cruz',
			'San Roque',
			'San Pedro',
			'San Pedro',
			'San Narciso',
			'San Jose de Buan',
			'Sangat',
			'San Gabriel',
			'San Antonio',
			'Samboan',
			'Salay',
			'Sadsadan',
			'Rizal',
			'Robonkon',
			'Quezon',
			'Parabcan',
			'Placer',
			'Pilar',
			'Pawican',
			'Patonan',
			'Paraiso',
			'Panikihan',
			'Pangpang',
			'Pañgobilian',
			'Panglao',
			'Pambuhan',
			'Palaroo',
			'Pacol',
			'Ondoy',
			'Numancia',
			'Noling',
			'Narvacan',
			'Naisud',
			'Nagbacalan',
			'Murcia',
			'Morales',
			'Minanga Norte',
			'Milaor',
			'Miaray',
			'Maypangdan',
			'Maugat West',
			'Masiu',
			'Mandaluyong City',
			'Malilipot',
			'Malbug',
			'Malbang',
			'Magnaga',
			'Magbay',
			'Magallon Cadre',
			'Macarse',
			'Macabugos',
			'Macabuboni',
			'Mabasa',
			'Mabalacat City',
			'Lunen',
			'Lucero',
			'Lucban',
			'Lourdes',
			'Lourdes',
			'Looc',
			'Lobong',
			'Lintangan',
			'Ligao',
			'Las Piñas',
			'Lantangan',
			'Languyan',
			'Lamitan',
			'Kinablangan',
			'Kayapa',
			'Calumboyan',
			'Kalawit',
			'Jalajala',
			'Igpit',
			'Ibaan',
			'Gupitan',
			'Guiset East',
			'Guipos',
			'Guindarohan',
			'Gubat',
			'Hilantagaan',
			'General Luna',
			'Estacion',
			'Estaca',
			'Esperanza',
			'Dunguan',
			'Ditsaan',
			'Dilan',
			'Del Rosario',
			'Daliciasao',
			'Dasol',
			'Damolog',
			'Cortes',
			'Cayang',
			'Capoocan',
			'Cantel',
			'Candabong',
			'Camohaguin',
			'Cambanay',
			'Camangcamang',
			'Calumpang',
			'Cajimos',
			'Cainta',
			'Cabuyao',
			'Cabalaoangan',
			'Cabadiangan',
			'Bulod',
			'Bulacnin',
			'Bogtong',
			'Bay-ang',
			'Bautista',
			'Batitang',
			'Basud',
			'Basicao Coastal',
			'Barucboc Norte',
			'Barcelona',
			'Baon',
			'Bantiqui',
			'Banate',
			'Baloy',
			'Balagtas',
			'Balagon',
			'Bagong Sikat',
			'Bagabag',
			'Bacnotan',
			'Ayungon',
			'Anonang',
			'Amulung',
			'Alicia',
			'Aliang',
			'Ajuy',
			'Agoo',
			'Afusing Centro',
			'Abucayan',
			'Katipunan',
			'Sionogan',
			'Payuhan',
			'Del Monte',
			'Katipunan',
			'Banco Filipino Homes',
			'Villaviciosa',
			'Tutay',
			'Tuod',
			'Tuban',
			'Trinidad',
			'Timonan',
			'Tikiw',
			'Tawagan',
			'Tartaro',
			'Tapilon',
			'Tombongon',
			'Talusan',
			'Talubatib',
			'Talisayan',
			'Talisay',
			'Talahiban I',
			'Tagum Norte',
			'Tagoloan',
			'Tagcatong',
			'Tabug',
			'Sulangan',
			'Sugod',
			'Sugal',
			'Sudipen',
			'Sinamar',
			'Silang',
			'Santo Tomas',
			'Santo Niño',
			'Santo Niño',
			'Santa Rosa',
			'Santa Maria',
			'Santa Cruz',
			'San Miguel',
			'Aurora',
			'San Basilio',
			'San Antonio',
			'Saguday',
			'Rizal',
			'Recodo',
			'Puro Pinget',
			'Pawak',
			'Patnanungan',
			'Parang',
			'Pang',
			'Palkan',
			'Palanit',
			'Pagaluñgan',
			'Oroquieta',
			'Ochanado',
			'Nugas',
			'New Corella',
			'Nenita',
			'Nancamarinan',
			'Naguelguel',
			'Mercedes',
			'Medellin',
			'Mat-i',
			'Marupit',
			'Marao',
			'Mambajao',
			'Malvar',
			'Malitubog',
			'Mabini',
			'Mabilao',
			'Mabay',
			'Maasin',
			'Maan',
			'Lupi Viejo',
			'Lumbayanague',
			'Lubao',
			'Lombocan',
			'Libertad',
			'Libato',
			'Libas',
			'Lanuza',
			'Lañgub',
			'Lanao',
			'Lamut',
			'Kipit',
			'Kinangan',
			'Kimaya',
			'Kalbay',
			'Jugno',
			'Juban',
			'Joroan',
			'Jiabong',
			'Jagna',
			'Isabela',
			'Irirum',
			'Inayauan',
			'Ichon',
			'Hobo',
			'Hernani',
			'Guinlo',
			'Gregorio del Pilar',
			'Gayaman',
			'Gamay',
			'Dumalaguing',
			'Dumaguil',
			'Dugcal',
			'Donsol',
			'Disod',
			'Dinganen',
			'Dinaig',
			'Dimayon',
			'Datu Paglas',
			'Datagon',
			'Daram',
			'Dalirig',
			'Culaba',
			'Consuelo',
			'Cogtong',
			'Cebu City',
			'Cassanayan',
			'Canjulao',
			'Calsib',
			'Calizo',
			'Calibungan',
			'Calabugao',
			'Cagayan',
			'Buyo',
			'Buluangan',
			'Bulog',
			'Buenlag',
			'Buadiposo-Buntong',
			'Botao',
			'Boot',
			'Boljoon',
			'Blingkong',
			'Bitanjuan',
			'Binuangan',
			'Biga',
			'Upper Bicutan',
			'Bayas',
			'Batuan',
			'Bato',
			'Banocboc',
			'Bangui',
			'Bangonay',
			'Bancal',
			'Banaue',
			'Banalo',
			'Baluyot',
			'Balayong',
			'Bagumbayan',
			'Bagumbayan',
			'Bagulin',
			'Bacacay',
			'Aritao',
			'Aringay',
			'Aanislag',
			'Amas',
			'Alicia',
			'Aggugaddah',
			'Abra de Ilog',
			'Talipaw',
			'Sagacad',
			'Camudmud',
			'Bungad',
			'Vizal San Pablo',
			'Viriato',
			'Valencia',
			'Umaganhan',
			'Tulunan',
			'Tubod-dugoan',
			'Tingloy',
			'Tinampo',
			'Tiguisan',
			'Tigaon',
			'Tarusan',
			'Tandag',
			'Tampakan',
			'Tambulig',
			'Talang',
			'Talaban',
			'Taguig',
			'Tagudin',
			'Tabaan',
			'Surallah',
			'Socorro',
			'Sibaguan',
			'Salunayan',
			'San Vicente',
			'Santo Tomas',
			'Santo Niño',
			'Santo Niño',
			'Santo Domingo',
			'Santa Maria',
			'Santa Lucia',
			'Santa Lucia',
			'Santa Cecilia',
			//'Santa Ana',
			'San Miguel',
			'San Marcelino',
			'San Manuel',
			'San Juan',
			'San Isidro',
			'Batobato',
			'San Benito',
			'San Alejandro',
			'Salcedo',
			'Real',
			'Quiapo',
			'Putol',
			'Prado Siongco',
			'Pidigan',
			'Pawa',
			'Patabog',
			'Pangil',
			'Pando',
			'Panalanoy',
			'Pamatawan',
			'Palapag',
			'Pagsanahan Norte',
			'Paete',
			'Obando',
			'Nangalisan',
			'Naawan',
			'Maslog',
			'Marintoc',
			'Maria Aurora',
			'Maquiling',
			'Mapulo',
			'Maño',
			'Manikling',
			'Manaoag',
			'Mamburao',
			'Malabon',
			'Mahatao',
			'Maguinao',
			'Maddela',
			'Macapsing',
			'Mabini',
			'Maagnas',
			'Lutayan',
			'Luklukan',
			'Los Baños',
			'Loreto',
			'Looc',
			'Lonoy',
			'Lipayran',
			'Liozon',
			'Limulan',
			'Libertad',
			'Lapinigan',
			'Lambayong',
			'Lagasit',
			'Koronadal',
			'Kambing',
			'Julita',
			'Ivisan',
			'Imelda',
			'Ignit',
			'Hiju, Maco',
			'Gumaca',
			'Guinayangan, Fourth District of Quezon',
			'Gitagum',
			'Gigmoto',
			'Gattaran',
			'Gabi',
			'Dumpay',
			'Don Pedro',
			'Dolores',
			'Dingle',
			'Del Carmen',
			'Dapawan',
			'Damawato',
			'Daguit',
			'Cuyo',
			'Calabasa',
			'Cabcab',
			'Burgos',
			'Bulalacao',
			'Bugko',
			'Buenlag',
			'Buenavista',
			'Bukay Pait',
			'Bucay',
			'Bolaoit',
			'Bocaue',
			'Bobon',
			'Bituñgol',
			'Binlod',
			'Biga',
			'Barangobong',
			'Balogo',
			'Balogo',
			'Balangonan',
			'Balabac',
			'Bachauan',
			'Bacoor',
			'Bacayawan',
			'Awallan',
			'Arteche',
			'Armenia',
			'Aramawayan',
			'Anda',
			'Alibago',
			'Abiera',
			'Kalang',
			'Kaligutan',
			'Capitan Ramon',
			'Esperanza',
			'Vitali',
			'Vintar',
			'Villa Isla',
			'Upi',
			'Tungao',
			'Tunasan',
			'Tumauini',
			'Tumalim',
			'Tuli',
			'Tuguegarao',
			'Tuganay',
			'Tudela',
			'Tudela',
			'Tublay',
			'Toong',
			'Tinogboc',
			'Brgy. Tinigao, Kalibo',
			'Tigbao',
			'Tidman',
			'Teresita',
			'Tarragona',
			'Taluya',
			'Talokgañgan',
			'Tagbubungang Diot',
			'Sumpong',
			'Suz-on',
			'Siclong',
			'Sibucao',
			'Sibonga',
			'Sapad',
			'Santo Domingo',
			'Santo Domingo',
			'Santa Ines West',
			'Santa Cruz',
			'San Roque',
			'San Remigio',
			'San Nicolas',
			'San Miguel',
			'San Luis',
			'San Jose',
			'San Jose',
			'Catigbian',
			'San Ildefonso',
			'Sandayong Sur',
			'San Aquilino',
			'San Antonio',
			'Sampao',
			'Liwan',
			'Quipot',
			'Quinapundan',
			'Quezon City',
			'Pugo',
			'Pitogo',
			'Pandasan',
			'Pinamopoan',
			'Patayac',
			'Patawag',
			'Patao',
			'Pangdan',
			'Pangantocan',
			'Pance',
			'Pamplona',
			'Pagsanjan',
			'Pagbilao',
			'Padang',
			'Orani',
			'Olea',
			'Ninoy',
			'Navotas',
			'Naro',
			'Nancalobasaan',
			'Nagsaing',
			'Naghalin',
			'Nabas',
			'Mozzozzin Sur',
			'Molugan',
			'Miaga',
			'Matnog',
			'Mati',
			'Matala',
			'Maslog',
			'Marihatag',
			'Maricalom',
			'Mapanas',
			'Mansilingan',
			'Manggahan',
			'Malinta',
			'Malabon',
			'Mailag',
			'Maigo',
			'Magtaking',
			'Luzon',
			'Luna',
			'Lupo',
			'Limon',
			'Libas',
			'Laguilayan',
			'Klinan',
			'Katuli',
			'Katipunan',
			'Kanluran',
			'Kabalen',
			'Hamoraon',
			'Ipil',
			'Himamaylan',
			'Guisguis',
			'Guiljungan',
			'General Emilio Aguinaldo',
			'Gabao',
			'Estipona',
			'Egaña',
			'Duran',
			'Dumlan',
			'Duero',
			'Dolores',
			'Dolores',
			'Dicayong',
			'Diaz',
			'Del Pilar',
			'Del Carmen, Surigao del Norte',
			'Davan',
			'Dassun',
			'Dagami',
			'Carmona',
			'Cogan',
			'Candelaria',
			'Callaguip',
			'Calamba',
			'Cagsiay',
			'Cabugao',
			'Cabalitian',
			'Cabodiongan',
			'Buyuan',
			'Buenavista',
			'Borbon',
			'Bongabon',
			'Bocos',
			'Blinsung',
			'Binangonan',
			'Biliran',
			'Baybayin',
			'Bayaoas',
			'Bato',
			'Barra',
			'Bariw',
			'Banisilan',
			'Bangan-Oda',
			'Bañga',
			'Balugo',
			'Bais',
			'Bagalangit',
			'Bacolod',
			'Anulid',
			'Anopog',
			'Anilao',
			'Alicia',
			'Alcoy',
			'Alaminos',
			'Dulian',
			'Andalan',
			'Rebe',
			'Simuay',
			'Simod',
			'Zamboanga',
			'Villaba',
			'Tupsan',
			'Tondod',
			'Tiniguiban',
			'Tiling',
			'Telbang',
			'Tapayan',
			'Tantangan',
			'Tambak',
			'Talibon',
			'Talaga',
			'Talaga',
			'Tagasilay',
			'Tagkawayan Sabang',
			'Tagaytay',
			'Tagas',
			'Tabuc Pontevedra',
			'Tabunok',
			'Sumag',
			'Sumabnit',
			'Siraway',
			'Siocon',
			'Sinilian First',
			'Siempre Viva',
			'Sibulan',
			'Sergio Osmeña Sr',
			'Seres',
			'Sapang Buho',
			'Sapa',
			'Santo Tomas',
			'Santo Rosario',
			'Santo Niño',
			'Santiago',
			'Santa Teresa First',
			'Santa Teresa',
			'Santa Rosa Sur',
			'Santa Lucia',
			'San Miguel',
			'San Ildefonso',
			'San Antonio',
			'San Agustin',
			'Sampao',
			'Sabang',
			'Ramain',
			'Puro',
			'Porac',
			'Pondaguitan',
			'Pinokawan',
			'Pilar',
			'Pavia',
			'Passi',
			'Pangao',
			'Panalingaan',
			'Utabi',
			'Naval',
			'Namuac',
			'Nagiba',
			'Nagcarlan',
			'Muladbucad',
			'Montaneza',
			'Minalabac',
			'Mercedes',
			'Matnog',
			'Mansalay',
			'Malinao Ilaya',
			'Malanday',
			'Malagos',
			'Malabugas',
			'Mahaplag',
			'Maguling',
			'Magallanes',
			'Magalalag',
			'Lumbayan',
			'Lubang',
			'Limaong',
			'Lanna',
			'Lagayan',
			'Koronadal',
			'Katubao',
			'Kapangan',
			'Kamanga',
			'Kalaisan',
			'Bugho',
			'Inabaan Sur',
			'Gimampang',
			'Hagonoy',
			'Guyong',
			'Gumian',
			'Gabi',
			'Gabas',
			'Ermita',
			'Ebcor Town',
			'Dauin',
			'Tobias Fornier',
			'Danao',
			'Dalorong',
			'Dacudao',
			'Consolacion',
			'Concepcion',
			'Cogon',
			'Caruray',
			'Carmen Grande',
			'Calumpit',
			'Calumpang',
			'Calinaoan Malasin',
			'Cagwait',
			'Cabugao',
			'Cabinuangan',
			'Cabano',
			'Bulihan',
			'Bolanon',
			'Buagsong',
			'Bolinao',
			'Biwang',
			'Bislig',
			'Bical Norte',
			'Bayog',
			'Baylo',
			'Baunu-Timbangan',
			'Batangas',
			'Barong',
			'Banag',
			'Balete',
			'Asingan',
			'Aranas Sur',
			'Antagan Segunda',
			'Anuling',
			'Angadanan',
			'Anahawan',
			'Allangigan Primero',
			'Allacapan',
			'Alegria',
			'Buansa',
			'Guiniculalay',
			'Balas',
			'Motrico',
			'San Pascual',
			'Magsaysay',
			'Lumbac',
			'Pasig City',
			'Villareal',
			'Victoria',
			'Unisan',
			'Union',
			'Ualog',
			'Tumbagaan',
			'Tubungan',
			'Toboso',
			'Tindog',
			'Tinagacan',
			'Tayaman',
			'Tampocon',
			'Tamontaka',
			'Tamisan',
			'Tambo',
			'Talisayan',
			'Magugpo Poblacion',
			'Tabonoc',
			'Sulangan',
			'Sirib',
			'Sinonoc',
			'Sico Uno',
			'Saysain',
			'Sara',
			'Santo Niño',
			'Santa Teresita',
			'Santa Rosa',
			'Santa Cruz',
			'Santa Cruz',
			'San Pascual',
			'San Miguel',
			'San Mateo',
			'San Manuel',
			'San Francisco',
			'San Fernando',
			'San Fernando',
			'San Andres',
			'Salcedo',
			'Sablan',
			'Sabangan',
			'Roxas City',
			'Rizal',
			'Rizal',
			'Rizal',
			'Rajal Norte',
			'Quezon',
			'Prinza',
			'Poro',
			'Pinoma',
			'Pila',
			'Perez',
			'Pawa',
			'Pateros',
			'Parion',
			'Panacan',
			'Pamplona',
			'Paluan',
			'Paclolo',
			'Odiongan',
			'Ocoy',
			'Niugan',
			'Nailong',
			'Nabangig',
			'Matacon',
			'Maravilla',
			'Manjoy',
			'Mandangoa',
			'Manalongon',
			'Mamonit',
			'Malasila',
			'Malabanban Norte',
			'Maibu',
			'Magallanes',
			'Maco',
			'Macalaya',
			'Mabilog',
			'Mabilbila Sur',
			'Lusong',
			'Lunao',
			'Lumbangan',
			'Libungan',
			'Legaspi',
			'Legrada',
			'Lallayug',
			'Lagonglong',
			'Lacag',
			'Kumalisquis',
			'Kulempang',
			'Kisante',
			'Kinamayan',
			'Kibureau',
			'Kabuynan',
			'Kabulusan',
			'Jovellar',
			'Jampang',
			'Jalaud',
			'Initao',
			'Infanta',
			'Indulang',
			'Igmaya-an',
			'Guinoaliuan',
			'Guinabsan',
			'Guadalupe',
			'Sigaboy',
			'Gigaquit',
			'Gibato',
			'Getulio',
			'Gerona',
			'Gasan',
			'Estrella',
			'Entablado',
			'Dumaguete',
			'Dulangan',
			'Datu Piang',
			'Dapdapan',
			'Danao',
			'Curry',
			'Kotkot',
			'Cortes',
			'Cordon',
			'Concepcion Ibaba',
			'Culasian',
			'Cogon',
			'Codcod',
			'Cawayan',
			'Caticugan',
			'Katico',
			'Capuy',
			'Cantoria',
			'Camandag',
			'Kabilauan',
			'Brgy. New Buswang, Kalibo',
			'Burdeos',
			'Buhatan',
			'Bugasan',
			'Buenavista',
			'Buayan',
			'Bolisong',
			'Binucayan',
			'Binalbagan',
			'Bilad',
			'Bigaan',
			'Bien Unido',
			'Bayugan',
			'Bawison',
			'Barotac Viejo',
			'Bantog',
			'Baliwagan',
			'Balili',
			'Balibagan Oeste',
			'Bagupaye',
			'Bagahanlad',
			'Azagra',
			'Atimonan',
			'Anini-y',
			'Anibongan',
			'Angono',
			'Ampusungan',
			'Amio',
			'Aloguinsan',
			'Alimono',
			'Alangalang',
			'Agupit',
			'Kungtad',
			'Aguisan',
			'Aglipay',
			'Sibulao',
			'Pandakan',
			'Kulase',
			'Andop',
			'Bualan',
			'Subic Bay Freeport Zone',
			'Sabang',
			'Tran',
			'Tolosa',
			'Tinubuan',
			'Tinglayan',
			"T'boli",
			'Tayug',
			'Taytayan',
			'Tayabas',
			'Tangalan',
			'Tamugan',
			'Tambalisa',
			'Taloc',
			'Tagudin',
			'Sinawilan',
			'Santisimo Rosario',
			'Santa Teresita',
			'Santa Margarita',
			'San Roque',
			'San Miguel',
			'San Mariano',
			'San Juan',
			'San Jose',
			'San Isidro',
			'San Isidro',
			'San Isidro',
			'San Isidro',
			'San Francisco',
			'San Fernando',
			'San Bernardo',
			'San Antonio',
			'San Alfonso',
			'Salvacion',
			'Reina Mercedes',
			'Real',
			'Ranao',
			'Ramos',
			'Quintong',
			'Quinabigan',
			'Quezon',
			'Quezon',
			'Puerto Bello',
			'Port Barton',
			'Polañge',
			'Pitogo',
			'Piña',
			'Pastrana',
			'Papaya',
			'Pansoy',
			'Pangdan',
			'Pangascasan',
			'Pandan',
			'Pan-an',
			'Panan',
			'Odicon',
			'New Iloilo',
			'Nasipit',
			'Napnapan',
			'Murcia',
			'Mulanay',
			'Morong',
			'Moncada',
			'Maydolong',
			'Maria',
			'Manup',
			'Manila',
			'Mangaldan',
			'Manga',
			'Manat',
			'Malanay',
			'Maimbung',
			'Magdalena',
			'Magarao',
			'Macalelon',
			'Mabusag',
			'Lumangbayan',
			'Lugo',
			'Lukatan',
			'Limbalod',
			'Limao',
			'Lasang',
			'Lapinig',
			'Lapaz',
			'Langob',
			'Langtad',
			'Langiden',
			'Kalian',
			'Javier',
			'Jantianon',
			'Ivana',
			'Isla',
			'Hinigaran',
			'Hinapalanan',
			'Hilongos',
			'Jarigue',
			'Hagnaya',
			'Guindapunan',
			'Glan',
			'Famy',
			'Dodan',
			'Divisoria',
			'Diliman Primero',
			'Bonifacio',
			'Dao',
			'Dalaguete',
			'Daguioman',
			'Dadus',
			'Cuevas',
			'Corrooy',
			'Concepcion',
			'Cogon',
			'Causip',
			'Catablan',
			'Catabangan',
			'Carmen',
			'Carmelo',
			'Karligan',
			'Caraycayon',
			'Capissayan Sur',
			'Canlaon',
			'Camindangan',
			'Calumpang',
			'Calubian',
			'Calamba',
			'Cabittaogan',
			'Cabcaben',
			'Buliwao',
			'Buliran',
			'Bulihan',
			'Bulatukan',
			'Bula',
			'Buang',
			'Bombon',
			'Bolong',
			'Bulo',
			'Bolingit',
			'Binday',
			'Biao',
			'Bayasong',
			'Basud',
			'Barili',
			'Baras',
			'Bangued',
			'Balitoc',
			'Balite',
			'Balaoang',
			'Balading',
			'Bago City',
			'Bacuag',
			'Bacolod',
			'Babo-Pangulo',
			'Avila',
			'Aurora',
			'Ampatuan',
			'Alegria',
			'Villanueva',
			'Villamor',
			'Urdaneta',
			'Tupang',
			'Tuburan',
			'Trapiche',
			'Tignoan',
			'Talalora',
			'Tadian',
			'Tabon',
			'Tabango',
			'Sultan Kudarat',
			'Sugpon',
			'Saaban',
			'Sipalay',
			'Simala',
			'Silway 7',
			'Silago',
			'Sibulan',
			'Santiago',
			'Santa Rosa',
			'Santa Rita',
			'Santa Cruz',
			'San Roque',
			'San Remigio',
			'San Miguel',
			'San Mariano',
			'San Manuel',
			'San Isidro',
			'Sanghan',
			'Sangay',
			'Sampaloc',
			'Salug',
			'Salapungan',
			'Saguing',
			'Ronda',
			'Punolu',
			'San Vicente',
			'Polomolok',
			'Polo',
			'Polahongon',
			'Pinukpuk',
			'Pinagsibaan',
			'Pawa',
			'Patrocinio',
			'Patnongon',
			'Paracelis',
			'Panitan',
			'Palomoc',
			'Palestina',
			'Palahanan Uno',
			'Pagsanghan',
			'New Agutaya',
			'Nagpandayan',
			'Muricay',
			'Morobuan',
			'Monpon',
			'Maturanoc',
			'Masalipit',
			'Mariveles',
			'Makiwalo',
			'Manuangan',
			'Manolo Fortich',
			'Manika',
			'Manga',
			'Manaul',
			'Manalad',
			'Malamote',
			'Malalag',
			'Macalva Norte',
			'Mabunga',
			'Maao',
			'Lut-od',
			'Lumazal',
			'Looc',
			'Logon',
			'Libona',
			'Libertad',
			'Libacao',
			'Lavezares',
			'La Union',
			'La Paz',
			'La Paz',
			'Labog',
			'Kawayan',
			'Kauswagan',
			'Kalanganan',
			'Jamabalod',
			'Jagupit',
			'Inayagan',
			'Imbang',
			'Guinobatan',
			'Gonzaga',
			'Gloria',
			'Gang',
			'Gamut',
			'Ferrol',
			'Dolores',
			'Dinalongan',
			'Diffun',
			'Dorsalanam',
			'Cuambog',
			'Conduaga',
			'Concepcion',
			'Claver',
			'Cervantes',
			'Cavite City',
			'Castillo',
			'Capas',
			'Canan Norte',
			'Caloocan City',
			'Calibutbut',
			'Calero',
			'Calantas',
			'Calangain',
			'Calancuasan Norte',
			'Calampisauan',
			'Cafe',
			'Cabilao',
			'Cabiguan',
			'Cabanatuan City',
			'Buyabod',
			'Buracan',
			'Bungoy',
			'Bunga',
			'Buenavista',
			'Botolan',
			'Bulawin',
			'Binmaley',
			'Bincoñgan',
			'Batac City',
			'Basag',
			'Barira',
			'Banos',
			'Bani',
			'Banga',
			'Banawang',
			'Balingasag',
			'Balbalan',
			'Balaoan',
			'Balagtasin',
			'Bail',
			'Leon Postigo',
			'Baclayon',
			'Awang',
			'Aumbay',
			'Atipuluhan',
			'Asturias',
			'Aras-asan',
			'Aranda',
			'Aparri',
			'Alemanguan',
			'Alacaygan',
			'Agusan',
			'Abaca',
			'Landang Laum',
			'Sanga-Sanga',
			'Lanipao',
			'Del Pilar',
			'Molundo',
			'Daguma',
			'Zumarraga',
			'Zamboanguita',
			'Villasis',
			'Villanueva',
			'Villa Aglipay',
			'Vigo',
			'Valenzuela',
			'Valderrama',
			'Upper San Mateo',
			'Tubalan',
			'Toboy',
			'Tipo-Tipo',
			'Tigbao',
			'Tibagon',
			'Taviran',
			'Tarlac City',
			'Tamlang',
			'Talisay',
			'Takub',
			'Tago',
			'Tabon',
			'Tabing',
			'Tabalong',
			'Soledad',
			'Sogod',
			'Siruma',
			'Sillawit',
			'Silab',
			'Cigaras',
			'Sarrat',
			'San Vincente',
			'San Vicente',
			'Santo Tomas',
			'Santa Maria',
			'Santa Brigida',
			'San Ramon',
			'San Policarpio',
			'San Pablo',
			'San Pablo',
			'San Lorenzo',
			'San Jose',
			'San Jacinto',
			'San Gabriel First',
			'Sampaloc',
			'Saguiaran',
			'Sablayan',
			'Rimpeso',
			'Pura',
			'Pulong Gubat',
			'Plaridel',
			'Piñahan',
			'Pimbalayan',
			'Pilig',
			'Pilar',
			'Piña',
			'Pata',
			'New Pandanon',
			'Palali',
			'Nuing',
			'Nieves',
			'New Panamao',
			'Navotas',
			'Nato',
			'Nattapian',
			'Nama',
			'Nalsian Norte',
			'Naguilayan',
			'Muñoz',
			'Milagros',
			'Mauraro',
			'Mauban',
			'Matiao',
			'Masoli',
			'Masaba',
			'Marawa',
			'Mangili',
			'Manga',
			'Manamrag',
			'Manabo',
			'Mambagatan',
			'Maluid',
			'Mallig',
			'Malajog',
			'Malabang',
			'Majayjay',
			'Maindang',
			'Mahaba',
			'MacArthur',
			'Lungaog',
			'Luna',
			'Lugui',
			'Lopez Jaena',
			'Lipa City',
			'Lingayen',
			'Libertad',
			'Libertad',
			'Laur',
			'Larion Alto',
			'La Paz',
			'La Libertad',
			'Lala',
			'Lajong',
			'Lais',
			'Laguit Centro',
			'Kitango',
			'Kiblawan',
			'Kapatalan',
			'Iraga',
			'Ilog',
			'Ilihan',
			'Halayhay',
			'Gutad',
			'Guruyan',
			'Gueset',
			'Giporlos',
			'General Luna',
			'Galimuyod',
			'Gadung',
			'Gabuyan',
			'Floridablanca',
			'Escalante',
			'Echague',
			'Dipolog',
			'Damatulan',
			'Dalupaon',
			'Dagatan',
			'Culubasa',
			'Constancia',
			'Colonia',
			'Cawayan Bugtong',
			'Cartagena',
			'Carmen',
			'Carabalan',
			'Caoayan',
			'Kandabong',
			'Campusong',
			'Calubcub Dos',
			'Calog Norte',
			'Calabanga',
			'Cadiz',
			'Cabitan',
			'Buug',
			'Butuan',
			'Butazon',
			'Bunog',
			'Buenavista',
			'Bual',
			'Bontoc',
			'Bonawon',
			'Biabas',
			'Baybay',
			'Basista',
			'Barayong',
			'Banaybanay',
			'Balamban',
			'Bagay',
			'Baclaran',
			'Aya',
			'Aplaya',
			'Aplaya',
			'Apalit',
			'Antipolo',
			'Almendras',
			'Alcala',
			'Agbannawag',
			'Acao',
			'Tunggol',
			'La Dicha',
			'San Remigio',
			'Bagong Pagasa',
			'Bagong Silangan',
			'Tabuk',
			'Apas',
			'Zaragoza',
			'Villa Verde',
			'Tuburan',
			'Tubigagmanoc',
			'Trento',
			'Ternate',
			'Tebag East',
			'Tugaya',
			'Tagakpan',
			'Tabontabon',
			'Tabogon',
			'Sulit',
			'Subic',
			'Sapang Dalaga',
			'San Vicente',
			'San Vicente',
			'San Vicente',
			'Santo Rosario',
			'Santo Cristo',
			'Santa Maria',
			'Santa Magdalena',
			'Santa Cruz',
			'Santa Barbara',
			'San Rafael',
			'San Manuel',
			'San Lucas',
			'San Juan',
			'San Joaquin',
			'San Jacinto',
			'San Francisco',
			'San Enrique',
			'San Agustin',
			'Salvador',
			'Salvacion',
			'Sagpon',
			'Rizal',
			'Punta',
			'Punay',
			'Puelay',
			'Potot',
			'Pontevedra',
			'Placer',
			'Pitogo',
			'Piddig',
			'Pias',
			'Piape I',
			'Payao',
			'Paraiso',
			'Paracale',
			'Paombong',
			'Pangal Sur',
			'Panabo',
			'Palsong',
			'Pakiad',
			'Padre Zamora',
			'Osmeña',
			'Odiongan',
			'Naili',
			'Monreal',
			'Monkayo',
			'Molave',
			'Minglanilla',
			'Matabao',
			'Masiga',
			'Masalukot Uno',
			'Mangatarem',
			'Mambayaan',
			'Malolos',
			'Malayal',
			'Malaya',
			'Malapag',
			'Malaga',
			'Mainit Norte',
			'Mahayag',
			'Magapit',
			'Mabua',
			'Mabini',
			'Mabayo',
			'Looc',
			'Limot',
			'Limanancong',
			'Lawang Kupang',
			'La Paz',
			'Lanot',
			'Langcangan',
			'Lalagsan',
			'La Carlota',
			'Kitapak',
			'Kiloloran',
			'Kawit',
			'Kauswagan',
			'Kaongkod',
			'Kabalantian',
			'Javalera',
			'Itbayat',
			'Isabel',
			'Hondagua',
			'Guisijan',
			'Ginatilan',
			'Eguia',
			'Domalanoan',
			'Dolo',
			'Dian-ay',
			'Darapidap',
			'Danao',
			'Dalipey',
			'Daliao',
			'Dalaoig',
			'Daet',
			'Currimao',
			'Concepcion',
			'Catungawan Sur',
			'Caticlan',
			'Casian',
			'Casala-an',
			'Caluya',
			'Calaya',
			'Calatugas',
			'Cagdianao',
			'Cagayancillo',
			'Cadagmayan Norte',
			'Kablalan',
			'Cabiraoan',
			'Cabangahan',
			'Cabacao',
			'Busuanga',
			'Barurao',
			'Burgos',
			'Burgos',
			'Bundoc',
			'Buluang',
			'Bulu',
			'Bulata',
			'Bosdak',
			'Bolo',
			'Bobon Second',
			'Bingawan',
			'Binabaan',
			'Beberon',
			'Batuan',
			'Bato',
			'Bato',
			'Batiano',
			'Basud',
			'Barra',
			'Bantuanon',
			'Balasing',
			'Balangkayan',
			'Bakung',
			'Astorga',
			'Asia',
			'Apad',
			'Ani-e',
			'Anao',
			'Ambulong',
			'Alugan',
			'Alimodian',
			'Alim',
			'Agutaya',
			'Agpangi',
			'Mauboh',
			'Kalian',
			'Gubaan',
			'Concepcion',
			'Gulod',
			'Ibung',
			'Nuyo',
			'Villahermosa',
			'Victorias',
			'Victoria',
			'Vallehermoso',
			'Unidos',
			'Tugbong',
			'Tocok',
			'Tinaogan',
			'Tinambacan',
			'Tigbaw',
			'Tanauan',
			'Talugtug',
			'Talayan',
			'Taguisa',
			'Taguing',
			'Tagbak',
			'Tabon',
			'Suyo',
			'Sindon',
			'Simunul',
			'Sibul',
			'Sibuco',
			'Semirara',
			'San Vicente',
			'Santo Tomas',
			'Santo Niño',
			'Santo Domingo',
			'Santa Rita',
			'Santa Praxedes',
			'Santa Monica',
			//'Santa Ana',
			'San Rafael',
			'San Rafael',
			'San Pedro',
			'San Pedro',
			'San Nicolas',
			'San Miguel',
			'San Miguel',
			'San Luis',
			'San Lorenzo',
			'San Isidro',
			'San Isidro',
			'San Francisco',
			'San Francisco',
			'San Francisco',
			'San Cristobal',
			'San Celestio',
			'San Carlos',
			'San Antonio',
			'San Agustin',
			'Salvacion',
			'Sabang',
			'Rosales',
			'Quilo-quilo',
			'Putiao',
			'Pudoc North',
			'Polo',
			'Pinaring',
			'Pili',
			'Pili',
			'Pidsandawan',
			'Parioc Segundo',
			'Parian Dakula',
			'Parang',
			'Panlinlang',
			'Pandan',
			'Palusapis',
			'Palhi',
			'Paiisa',
			'Padre Burgos',
			'Norala',
			'Nilombot',
			'Navatat',
			'Natubleng',
			'Napoles',
			'Nangka',
			'Nañgka',
			'Nangka',
			'Nalundan',
			'Naga',
			'Mobo',
			'Minapan',
			'Minalin',
			'Mangero',
			'Manacsac',
			'Malinao',
			'Malinao',
			'Mainit',
			'Maguyam',
			'Magsingal',
			'Magsaysay',
			'Makato',
			'Mabilang',
			'Maayong Tubig',
			'Lu-uk',
			'Lusacan',
			'Luna',
			'Luna',
			'Loacan',
			'San Miguel',
			'Linao',
			'Lidlidda',
			'Licab',
			'Liboran',
			'Libertad',
			'La Union',
			'Latung',
			'Lambunao',
			'La Fortuna',
			'Lacdayan',
			'Jimenez',
			'Jalaud',
			'Ipil',
			'Intampilan',
			'Inangatan',
			'Hinatuan',
			'Hagonoy',
			'Ginabuyan',
			'Gansing',
			'Eustaquio Lopez',
			'Dumangas',
			'Dulig',
			'Del Pilar',
			'Del Gallego',
			'Dauis',
			'Dagupan',
			'Dagup',
			'Culasi',
			'Cosina',
			'Conel',
			'Comillas Norte',
			'Claveria',
			'Cebuano',
			'Catmondaan',
			'Castillejos',
			'Carrascal',
			'Carmen',
			'Carasi',
			'Capandanan',
			'Camaligan',
			'Calbiga',
			'Calachuchi',
			'Cabulay',
			'Cabatuan',
			'Kabac',
			'Butigui',
			'Burgos',
			'Bugo',
			'Buenavista',
			'Yapak',
			'Biton',
			'Bilaran',
			'Bicos',
			'Benito Soliven',
			'Belance',
			'Bayuin',
			'Bato',
			'Bataquil',
			'Bangad',
			'Banawa',
			'Balut',
			'Balingcanaway',
			'Balingasay',
			'Balindong',
			'Balila',
			'Balayan',
			'Balagtas',
			'Bagumbayan',
			'Baco',
			'Aurelliana',
			'Alibunan',
			'Alcala',
			'Alaminos',
			'Alad',
			'Aguining',
			'Adams',
			'Magliman',
			'Malingao',
			"Sambayanihan People's Village",
			'Wawa',
			'Union',
			'Ungca',
			'Umabay',
			'Tuyan',
			'Tulay na Lupa',
			'Tubod',
			'Tominhao',
			'Tinang',
			'Tilik',
			'Tiguib',
			'Tigui',
			'Tampayan',
			'Taltal',
			'Talisay',
			'Talaibon',
			'Tacligan',
			'South Upi',
			'Solana',
			'Sogod',
			'Siquijor',
			'Sibagat',
			'Siari',
			'Santa Justina',
			'Santa Cruz',
			'Santa Cruz',
			'Santa Cruz',
			'San Roque',
			'San Quintin',
			'San Miguel',
			'San Jose del Monte',
			'San Jose',
			'San Jose',
			'Laug',
			'San Guillermo',
			'San Francisco',
			'Sandiat Centro',
			'San Antonio',
			'San Andres',
			'San Agustin',
			'Sabang',
			'Rogongon',
			'Ragan Norte',
			'Quitang',
			'Porais',
			'Pulupandan',
			'Pulong Santa Cruz',
			'President Roxas',
			'Pototan',
			'Plaridel',
			'Pitogo',
			'Pinayagan Norte',
			'Peñarrubia',
			'Peñablanca',
			'Pattao',
			'Panognawan',
			'Panay',
			'Paddaya',
			'Okiot',
			'New Cebu',
			'Mulauin',
			'Mayapusi',
			'Marilao',
			'Mapalad',
			'Manogpi',
			'Maluanluan',
			'Magay',
			'Magallanes',
			'Madridejos',
			'Mababanaba',
			'Luna',
			'Lugus',
			'Los Angeles',
			'Longos',
			'Liloy',
			'Liberty',
			'Leuteboro',
			'Lepa',
			'Lebak',
			'Larena',
			'Lanas',
			'Laminusa',
			'Lamak',
			'Labuan',
			'Labrador',
			'Culit',
			'Kitaotao',
			'Kibonsod',
			'Casay',
			'Kabungahan',
			'Kabasalan',
			'Jordan',
			'Hipadpad',
			'Japitan',
			'Jaena',
			'Iligan',
			'Igang',
			'Ibabang Tayuman',
			'Hacienda',
			'Gumalang',
			'Gibgos',
			'Gambang',
			'Fabrica',
			'Esperanza',
			'Doos',
			'Danao',
			'Culaman',
			'Cauayan',
			'Catungan',
			'Carriedo',
			'Cantao-an',
			'Cansilayan',
			'Calatagan',
			'Cabul-an',
			'Cabra',
			'Cabay',
			'Cabognon',
			'Bumbaran',
			'Bulad',
			'Buenavista',
			'Budta',
			'Buclad',
			'Boñgalon',
			'Bolong',
			'Bitag Grande',
			'Bintawan',
			'Becerril',
			'Baras',
			'Banhigan',
			'Balud',
			'Balasan',
			'Balaogan',
			'Badoc',
			'Atulayan',
			'Antipolo',
			'Ambulong',
			'Amaga',
			'Alejal',
			'Alegria',
			'Alcantara',
			'Alayao',
			'Aguada',
			'Acli',
			'Abangay',
			'Marsada',
			'Ungus-Ungus',
			'Bato Bato',
			'Buan',
			'Danlugan',
			'Yook',
			'Viga',
			'Victoria',
			'Umingan',
			'Ugad',
			'Tuy',
			'Tugdan',
			'Tipaz',
			'Teresa',
			'Taysan',
			'Tanudan',
			'Tanlad',
			'Tanjay',
			'Talisay',
			'Talipan',
			'Taligaman',
			'Sugcad',
			'Solana',
			'Sigay',
			'Lake Sebu',
			'Sebaste',
			'Santiago',
			'Hacienda Santa Rosa',
			'Santa Catalina',
			'San Ricardo',
			'San Ramon',
			'San Pedro Apartado',
			'San Miguel',
			'San Miguel',
			'Sanlibo',
			'San Leonardo',
			'Agcogon',
			'San Francisco',
			'San Antonio',
			'San Antonio',
			'Samal',
			'Salvacion',
			'Sagnay',
			'Roxas',
			'Rizal',
			'Quezon',
			'Putingkahoy',
			'Paddaya',
			'Puncan',
			'Punao',
			'Pulung Santol',
			'Puerto Princesa',
			'Pulo',
			'Plaridel',
			'Pinili',
			'Pasuquin',
			'Pantao-Ragat',
			'Panguiranan',
			'Pangabuan',
			'Panaytayon',
			'Palma Gil',
			'Pagsañgahan',
			'Odiong',
			'Nueva Era',
			'Nato',
			'Natividad',
			'Naic',
			'Minolos',
			'Minlagas',
			'Midsayap',
			'Matangad',
			'Masapang',
			'Mariano Marcos',
			'Margosatubig',
			'Margen',
			'Manay',
			'Manat',
			'Manapla',
			'Mananum',
			'Maluno Sur',
			'Malocloc',
			'Malapatan',
			'Malangabang',
			'Maitum',
			'Madalum',
			'Macabebe',
			'Mabuhay',
			'Brgy. Mabilo, New Washington',
			'Lumbayao',
			'Loon',
			'Linan',
			'Limon',
			'Ligayan',
			'Lapuan',
			'Lantapan',
			'Langpas',
			'Lamian',
			'Lambakin',
			'Kawit',
			'Kauswagan',
			'Caromatan',
			'Janopol',
			'Jamindan',
			'Isugod',
			'Gulod',
			'Guiuan',
			'Guirang',
			'Granada',
			'Glamang',
			'Gammad',
			'Gabawan',
			'Estrella',
			'Esmeralda',
			'Doong',
			'Daykitin',
			'Davila',
			'Dapitan',
			'Dalupirip',
			'Dahay',
			'Concepcion',
			'Compostela',
			'Colongulo',
			'Cervantes',
			'Cayhagan',
			'Cateel',
			'Carot',
			'Capulaan',
			'Capalonga',
			'Canroma',
			'Canhaway',
			'Can-Avid',
			'Calingcuan',
			'Calidñgan',
			'Calamba',
			'Butulan',
			'Burauen',
			'Bukal',
			'Buhangin',
			'Buenavista',
			'Bongao',
			'Bislig',
			'Bay',
			'Bascaron',
			'Barongis',
			'Barcelona',
			'Bansud',
			'Balatero',
			'Bakun',
			'Bairan',
			'Baikingon',
			'Bagacay',
			'Ayugan',
			'Atop-atop',
			'Araal',
			'Anito',
			'Angat',
			'Anakan',
			'Amadeo',
			'Almeria',
			'Alburquerque',
			'Alangilan',
			'Agos',
			'Abis',
			'Batu',
			'Malim',
		],
	},
	Poland: {
		country: 'Poland',
		cities: [
			'Regimin',
			'Pniewy',
			'Piszczac',
			'Opinogóra Górna',
			'Milanów',
			'Miłakowo',
			'Łopuszno',
			'Łaskarzew',
			'Łapczyca',
			'Krościenko Wyżne',
			'Kostomłoty Pierwsze',
			'Konstancin-Jeziorna',
			'Komorów',
			'Kasina Wielka',
			'Karczew',
			'Kałuszyn',
			'Jastrzębia',
			'Jasienica Rosielna',
			'Jadów',
			'Grajewo',
			'Gorliczyna',
			'Góra Kalwaria',
			'Gielniów',
			'Giedlarowa',
			'Drohiczyn',
			'Dębowa Kłoda',
			'Czyżew',
			'Borkowice',
			'Borek',
			'Biszcza',
			'Belsk Duży',
			'Baniocha',
			'Banie Mazurskie',
			'Adamówka',
			'Złoty Stok',
			'Zławieś Wielka',
			'Zabór',
			'Zabłocie',
			'Wymiarki',
			'Wola Filipowska',
			'Wieluń',
			'Widawa',
			'Tuchomie',
			'Tłuchowo',
			'Sulęczyno',
			'Strzałkowo',
			'Skórcz',
			'Skomielna Czarna',
			'Rzepin',
			'Ręczno',
			'Rawicz',
			'Rąbino',
			'Przechlewo',
			'Potęgowo',
			'Piła',
			'Osięciny',
			'Olszanica',
			'Oleśnica',
			'Nysa',
			'Nowe Skalmierzyce',
			'Nakło nad Notecią',
			'Murowana Goślina',
			'Maków Podhalański',
			'Łubnice',
			'Lubin',
			'Licheń Stary',
			'Leśnica',
			'Łękawica',
			'Kunice Żarskie',
			'Krzywiń',
			'Konstantynów Łódzki',
			'Kołczygłowy',
			'Kobiór',
			'Klecza Dolna',
			'Kłecko',
			'Kąty Wrocławskie',
			'Kaszów',
			'Kamieniec Wrocławski',
			'Jeziora Wielkie',
			'Jaworzno',
			'Głogówek',
			'Dziwnów',
			'Darłowo',
			'Czerniejewo',
			'Cerkwica',
			'Brojce',
			'Brenna',
			'Bolesław',
			'Bojszowy',
			'Bojano',
			'Biała',
			'Andrespol',
			'Broniszewice',
			'Wilczyce',
			'Widawa-Lipa Piotrowska-Polanowice',
			'Wyszogród',
			'Wola Batorska',
			'Wisznice',
			'Wąchock',
			'Tymbark',
			'Trąbki',
			'Targówek',
			'Stary Wiśnicz',
			'Śródmieście',
			'Spiczyn',
			'Purda',
			'Puchaczów',
			'Parysów',
			'Pantalowice',
			'Ostrów Lubelski',
			'Orły',
			'Obsza',
			'Młodzieszyn',
			'Międzyrzec Podlaski',
			'Michałowice',
			'Łaziska',
			'Kunów',
			'Korczyna',
			'Kielce',
			'Kańczuga',
			'Iwkowa',
			'Garbów',
			'Filipów',
			'Działdowo',
			'Bieniewice',
			'Białowieża',
			'Baranów Sandomierski',
			'Żory',
			'Żelistrzewo',
			'Zator',
			'Zaniemyśl',
			'Wolsztyn',
			'Wolin',
			'Wijewo',
			'Wierzchucino',
			'Tworków',
			'Twardawa',
			'Trzebnica',
			'Tczew',
			'Szczecinek',
			'Sulęcin',
			'Śrem',
			'Sosnowice',
			'Sławięcice',
			'Skorogoszcz',
			'Skoki',
			'Skała',
			'Sarnów',
			'Sączów',
			'Ryczów',
			'Rozprza',
			'Rogowo',
			'Racławice',
			'Pszczółki',
			'Porąbka',
			'Pilchowice',
			'Pierściec',
			'Pcim',
			'Paszowice',
			'Pakosławice',
			'Osielec',
			'Olszanka',
			'Nowy Duninów',
			'Nędza',
			'Miedzichowo',
			'Lutomiersk',
			'Lubsza',
			'Libertów',
			'Leńcze',
			'Lasek',
			'Kraków',
			'Kawęczyn',
			'Kamesznica',
			'Iłowa',
			'Hażlach',
			'Gołkowice',
			'Dopiewo',
			'Dębowa Łąka',
			'Czarny Bór',
			'Cieplice Śląskie Zdrój',
			'Bytom Odrzański',
			'Baruchowo',
			'Koszyce Wielkie',
			'Lubicz Górny',
			'Strachocin-Wojnów',
			'Rejon placu Świętego Macieja',
			'Tyczyn',
			'Szydłów',
			'Sułów',
			'Strachówka',
			'Stary Zamość',
			'Stanisławów Pierwszy',
			'Sokolniki',
			'Radomyśl Wielki',
			'Pokrówka',
			'Płońsk',
			'Pleśna',
			'Perlejewo',
			'Ochotnica Dolna',
			'Nowy Żmigród',
			'Nowy Dwór Mazowiecki',
			'Mirocin',
			'Miedziana Góra',
			'Łukowa',
			'Kuczbork-Osada',
			'Końskowola',
			'Grzybowa Góra',
			'Grodzisko Dolne',
			'Goworowo',
			'Gorzyce',
			'Garwolin',
			'Garbatka-Letnisko',
			'Dzwola',
			'Dźwierzuty',
			'Czarnocin',
			'Czarna',
			'Ciężkowice',
			'Boguchwała',
			'Baboszewo',
			'Złotów',
			'Zblewo',
			'Zagórze',
			'Zagórów',
			'Wysoka',
			'Wilkowice',
			'Wilczyn',
			'Wieruszów',
			'Węgry',
			'Wałbrzych',
			'Tuliszków',
			'Trzebinia',
			'Szydłowo',
			'Świdnica',
			'Sułoszowa',
			'Sosnowiec',
			'Skalmierzyce',
			'Sieniawa Żarska',
			'Rydułtowy',
			'Rudziczka',
			'Rudniki',
			'Rogóźno',
			'Przedecz',
			'Pruszcz',
			'Prószków',
			'Police',
			'Pisarzowice',
			'Piechowice',
			'Paniówki',
			'Olkusz',
			'Nowa Wieś',
			'Namysłów',
			'Myślenice',
			'Mogilany',
			'Milicz',
			'Mierzęcice',
			'Mazańcowice',
			'Lyski',
			'Lipce Reymontowskie',
			'Krzyżanowice',
			'Kostrzyn nad Odrą',
			'Kobylnica',
			'Kłodzko',
			'Kępno',
			'Kędzierzyn-Koźle',
			'Jerzmanowice',
			'Jasienica',
			'Gryfice',
			'Grudziądz',
			'Grojec',
			'Granowo',
			'Goleszów',
			'Filipowice',
			'Czajków',
			'Cisownica',
			'Choroń',
			'Chodecz',
			'Chełmża',
			'Bystra',
			'Brody',
			'Brdów',
			'Borzytuchom',
			'Bobolice',
			'Bierzwnik',
			'Bęczarka',
			'Bardo',
			'Aleksandrów Kujawski',
			'Żółkiewka',
			'Zarzecze',
			'Wyszki',
			'Wólka Podleśna',
			'Ułęż',
			'Tylicz',
			'Trzemeśnia',
			'Teresin',
			'Solec Nad Wisłą',
			'Sokołów Podlaski',
			'Sochocin',
			'Śniadowo',
			'Siemiatycze',
			'Rzeczyca',
			'Rogów',
			'Pomiechówek',
			'Olesno',
			'Narew',
			'Mysiadło',
			'Mirów',
			'Michałów',
			'Maciejowice',
			'Łopuszka Wielka',
			'Księżomierz',
			'Krzeczowice',
			'Krzeczów',
			'Krasnosielc',
			'Kobyłka',
			'Kłoczew',
			'Kampinos',
			'Kamionka',
			'Józefów',
			'Janów',
			'Grabownica Starzeńska',
			'Gierzwałd',
			'Dukla',
			'Duczki',
			'Dołhobyczów',
			'Długie',
			'Dęblin',
			'Chrzanów',
			'Brody',
			'Złocieniec',
			'Zebrzydowice',
			'Zalewo',
			'Wróblew',
			'Wierzchowo',
			'Węgliniec',
			'Truskolasy',
			'Tarnowskie Góry',
			'Borne Sulinowo',
			'Suchy Dąb',
			'Słupia pod Kępnem',
			'Skrwilno',
			'Siedlisko',
			'Sidzina',
			'Rzgów Pierwszy',
			'Rychtal',
			'Rudnik',
			'Resko',
			'Ratowice',
			'Radzanowo',
			'Radostowice',
			'Piotrków Trybunalski',
			'Pątnów',
			'Ośno Lubuskie',
			'Osiek Mały',
			'Nowogródek Pomorski',
			'Nowa Ruda',
			'Miłomłyn',
			'Lipnica Wielka',
			'Łęczyce',
			'Lewin Kłodzki',
			'Łaziska',
			'Kuźnia Raciborska',
			'Kurzętnik',
			'Krzymów',
			'Krośnice',
			'Koszęcin',
			'Klikuszowa',
			'Kiszkowo',
			'Kietrz',
			'Inwałd',
			'Imielin',
			'Gościcino',
			'Gogolin',
			'Głubczyce',
			'Duszniki',
			'Dmosin',
			'Dalachów',
			'Cisek',
			'Chrościna',
			'Brójce',
			'Bolków',
			'Grzybowo',
			'Czarna Dąbrówka',
			'Grabiszynek',
			'Zgorzelisko',
			'Księże Małe-Księże Wielkie',
			'Zaleszany',
			'Zakrzew',
			'Wólka Pełkińska',
			'Wola Jachowa',
			'Wojciechów',
			'Włochy',
			'Tuchów',
			'Trzciana',
			'Supraśl',
			'Sobków',
			'Sadowie',
			'Rząśnik',
			'Rudnik nad Sanem',
			'Ropczyce',
			'Pozezdrze',
			'Piskorowice',
			'Piekoszów',
			'Otwock',
			'Olszana',
			'Ojrzeń',
			'Nur',
			'Nowy Targ',
			'Nowa Dęba',
			'Niedźwiedź',
			'Mińsk Mazowiecki',
			'Milejów',
			'Milejczyce',
			'Łopiennik Górny',
			'Łomża',
			'Lipnik',
			'Krzemienica',
			'Komarów-Osada',
			'Jodłówka',
			'Jedwabno',
			'Jawornik Polski',
			'Jarczów',
			'Haczów',
			'Grybów',
			'Dubienka',
			'Domanice',
			'Długosiodło',
			'Czerwonka',
			'Czerniewice',
			'Czarnochowice',
			'Cisna',
			'Błędów',
			'Białystok',
			'Biała Podlaska',
			'Zgierz',
			'Zduńska Wola',
			'Zakopane',
			'Wołów',
			'Wodzierady',
			'Witnica',
			'Większyce',
			'Twardogóra',
			'Tuszyn',
			'Turza Śląska',
			'Tarnowo Podgórne',
			'Szlichtyngowa',
			'Syców',
			'Swarzędz',
			'Suchy Las',
			'Strzelin',
			'Strzelce Wielkie',
			'Stężyca',
			'Stargard',
			'Stara Kiszewa',
			'Środa Wielkopolska',
			'Smętowo Graniczne',
			'Skrzyszów',
			'Skarszewy',
			'Sieroszewice',
			'Sierakowice',
			'Rudawa',
			'Puck',
			'Psary',
			'Przeworno',
			'Piaski',
			'Pakość',
			'Ostrów Wielkopolski',
			'Ostrężnica',
			'Olza',
			'Myślachowice',
			'Lutocin',
			'Łazy',
			'Krzepice',
			'Kryspinów',
			'Krosno',
			'Korbielów',
			'Kobylanka',
			'Kartuzy',
			'Jenin',
			'Jedlina-Zdrój',
			'Januszkowice',
			'Janowice Wielkie',
			'Jankowice',
			'Gronowo Górne',
			'Gomunice',
			'Golczewo',
			'Gliwice',
			'Gidle',
			'Gaszowice',
			'Dziergowice',
			'Dobrzyń nad Wisłą',
			'Dobieszowice',
			'Cybinka',
			'Ciechocin',
			'Bytoń',
			'Buczek',
			'Borucin',
			'Bobrowice',
			'Sępolno',
			'Rejon ulicy Mieleckiej',
			'Żurawiczki',
			'Zbuczyn',
			'Wieczfnia Kościelna',
			'Tryńcza',
			'Sokółka',
			'Skórzec',
			'Sarnaki',
			'Rudka',
			'Rembertów',
			'Przędzel',
			'Pogórska Wola',
			'Osiek Jasielski',
			'Nowe Miasto nad Pilicą',
			'Łukowica',
			'Lipsk',
			'Lelkowo',
			'Kosina',
			'Stara Kornica',
			'Kłaj',
			'Kije',
			'Gołdap',
			'Gietrzwałd',
			'Frysztak',
			'Dynów',
			'Ceranów',
			'Brzóza Stadnicka',
			'Brzostek',
			'Biłgoraj',
			'Barczewo',
			'Baćkowice',
			'Żuromin',
			'Żelazków',
			'Zawidów',
			'Wilkowice',
			'Wilków',
			'Wągrowiec',
			'Unisław',
			'Trzebiel',
			'Targanice',
			'Szklarska Poręba',
			'Szczytna',
			'Szczepanów',
			'Świecie nad Osą',
			'Starowa Góra',
			'Starogard Gdański',
			'Sadlinki',
			'Rzyki',
			'Rycerka Dolna',
			'Rusocice',
			'Rozwadza',
			'Regulice',
			'Rajsko',
			'Radzionków',
			'Pszczyna',
			'Przelewice',
			'Przecław',
			'Poznań',
			'Poraj',
			'Piątek',
			'Osiek',
			'Osiek',
			'Osieczna',
			'Nowogrodziec',
			'Naprawa',
			'Miłoradz',
			'Mikołajki Pomorskie',
			'Michałowice',
			'Lubliniec',
			'Łobżenica',
			'Legnica',
			'Lądek',
			'Kwaczała',
			'Krobia',
			'Korfantów',
			'Kleczew',
			'Jaworze',
			'Jawor',
			'Jastrzębia Góra',
			'Istebna',
			'Inowrocław',
			'Grodzisk Wielkopolski',
			'Gorzyce Wielkie',
			'Gnieżdżewo',
			'Giebułtów',
			'Fabianki',
			'Dobra',
			'Daszewice',
			'Czernica',
			'Choczewo',
			'Chałupki',
			'Bytnica',
			'Bydgoszcz',
			'Brzeszcze',
			'Bralin',
			'Borek Wielkopolski',
			'Bolesławiec',
			'Banie',
			'Izabelin',
			'Kocierzew Południowy',
			'Żoliborz',
			'Zagórz',
			'Wola Rębkowska',
			'Wawrzeńczyce',
			'Wasilków',
			'Tłuszcz',
			'Szydłowiec',
			'Sypniewo',
			'Świętajno',
			'Strawczyn',
			'Sosnowica',
			'Rybczewice',
			'Przedmieście Dubieckie',
			'Płoniawy-Bramura',
			'Piątnica',
			'Olsztynek',
			'Nowe Miasto',
			'Niebieszczany',
			'Nidzica',
			'Mielec',
			'Michałów-Reginów',
			'Manasterz',
			'Małogoszcz',
			'Kowiesy',
			'Hyżne',
			'Grębów',
			'Gościeradów',
			'Górno',
			'Domaszowice',
			'Czchów',
			'Bieliny',
			'Biała Piska',
			'Zawadzkie',
			'Żarów',
			'Zadzim',
			'Wodzisław Śląski',
			'Władysławowo',
			'Wartkowice',
			'Warnice',
			'Turek',
			'Trzebunia',
			'Szczutowo',
			'Świnice Warckie',
			'Spytkowice',
			'Sośnie',
			'Sobótka',
			'Słupca',
			'Sławno',
			'Siekierczyn',
			'Rudziniec',
			'Rozdrażew',
			'Rokiciny',
			'Radomin',
			'Przyrów',
			'Przyborów',
			'Przecieszyn',
			'Pokój',
			'Pietrzykowice',
			'Pieszyce',
			'Pasłęk',
			'Ostroróg',
			'Osielsko',
			'Ornontowice',
			'Olesno',
			'Ochla',
			'Nekla',
			'Mszana',
			'Modlniczka',
			'Mirosławiec',
			'Międzylesie',
			'Mętków',
			'Luszowice',
			'Łubowo',
			'Łubowo',
			'Lubawka',
			'Łobez',
			'Krzyż Wielkopolski',
			'Krzanowice',
			'Kórnik',
			'Konopiska',
			'Kobylin',
			'Kłobuck',
			'Jordanów Śląski',
			'Jeżów Sudecki',
			'Jasień',
			'Janowice',
			'Gniezno',
			'Gilowice',
			'Gdynia',
			'Drawno',
			'Dolsk',
			'Dalików',
			'Czyżowice',
			'Czersk',
			'Czernichów',
			'Bronów',
			'Bieżuń',
			'Bielawy',
			'Bielawa',
			'Białośliwie',
			'Będzin',
			'Ostrówek',
			'Tomaszkowice',
			'Wiśniewo',
			'Osiedle Kosmonautów',
			'Zakrzew',
			'Wola Sernicka',
			'Wielkie Oczy',
			'Wadowice Górne',
			'Tyszowce',
			'Tyrawa Wołoska',
			'Turobin',
			'Telatyn',
			'Szumowo',
			'Strachocina',
			'Stąporków',
			'Ruciane-Nida',
			'Regnów',
			'Odrzykoń',
			'Obryte',
			'Nowosielce-Gniewosz',
			'Niemce',
			'Mniszków',
			'Łukowa',
			'Kosów Lacki',
			'Koprzywnica',
			'Kołaczyce',
			'Kock',
			'Kleszczele',
			'Kawęczyn Nowy',
			'Jodłówka',
			'Jastrzębia',
			'Jabłonna',
			'Husów',
			'Grodzisk Mazowiecki',
			'Czermin',
			'Brzeźnica',
			'Blizne',
			'Żórawina',
			'Zebrzydowice',
			'Zapolice',
			'Zagórnik',
			'Wolbórz',
			'Witkowo',
			'Witkowice',
			'Szemud',
			'Strzyżowice',
			'Strzegom',
			'Stronie',
			'Skomlin',
			'Skępe',
			'Sieniawa',
			'Rzeczenica',
			'Reńska Wieś',
			'Rędziny',
			'Radocza',
			'Przodkowo',
			'Przemków',
			'Pełczyce',
			'Parzęczew',
			'Ostrowy nad Okszą',
			'Orzesze',
			'Orle',
			'Opole',
			'Olszówka',
			'Lubrza',
			'Łobodno',
			'Lipno',
			'Lipinki Łużyckie',
			'Ligota',
			'Krupski Młyn',
			'Kramsk',
			'Koszalin',
			'Jordanów',
			'Jastrzębie Zdrój',
			'Grabowo Kościerskie',
			'Gozdowo',
			'Górzno',
			'Gniazdów',
			'Główczyce',
			'Gdańsk',
			'Drobin',
			'Drawsko Pomorskie',
			'Dłutów',
			'Czapury',
			'Chocz',
			'Buczkowice',
			'Brzeźnica',
			'Boniewo',
			'Bierutów',
			'Bielice',
			'Białogard',
			'Stabłowice',
			'Żyraków',
			'Zwoleń',
			'Zamch',
			'Wojsławice',
			'Wawer',
			'Turze Pole',
			'Szczuczyn',
			'Suchedniów',
			'Stoczek',
			'Staszów',
			'Sochaczew',
			'Rudnik',
			'Rejowiec Fabryczny',
			'Puszcza Mariańska',
			'Przyborów',
			'Pokrzywnica',
			'Parczew',
			'Paprotnia',
			'Ostrowsko',
			'Opatowiec',
			'Olszanica',
			'Łomianki',
			'Lisia Góra',
			'Łącko',
			'Łabowa',
			'Krasocin',
			'Konieczkowa',
			'Klembów',
			'Kętrzyn',
			'Jadowniki Mokre',
			'Gruszów Wielki',
			'Gołymin-Ośrodek',
			'Golcowa',
			'Drelów',
			'Dobre',
			'Czudec',
			'Borzęcin',
			'Biskupiec',
			'Żarki',
			'Września',
			'Walim',
			'Wąbrzeźno',
			'Tolkmicko',
			'Świnna',
			'Świerzno',
			'Strzelce',
			'Sośno',
			'Rypin',
			'Rudna',
			'Rogów',
			'Rogów',
			'Raszowa',
			'Rakoniewice',
			'Radziechowy',
			'Raba Wyżna',
			'Przybiernów',
			'Prudnik',
			'Płock',
			'Pawonków',
			'Osiek nad Notecią',
			'Oporów',
			'Olsztyn',
			'Niedobczyce',
			'Myszków',
			'Morąg',
			'Miękinia',
			'Lututów',
			'Lubawa',
			'Lubasz',
			'Lisków',
			'Libiąż',
			'Łęki Szlacheckie',
			'Książenice',
			'Kępice',
			'Kargowa',
			'Gostynin',
			'Gorzyce',
			'Gorzkowice',
			'Głuszyca',
			'Czerwieńsk',
			'Czarne',
			'Chojnów',
			'Chmielno',
			'Brzuze',
			'Brzeziny',
			'Borzęta',
			'Bolesławiec',
			'Blachownia',
			'Bierdzany',
			'Bachowice',
			'Lubicz Dolny',
			'Łętownia',
			'Nowe Grocholice',
			'Grabiszyn',
			'Złotniki',
			'Zbójna',
			'Zakrzówek',
			'Zabierzów Bocheński',
			'Wiśniowa',
			'Waśniów',
			'Urszulin',
			'Truskaw',
			'Słupno',
			'Skarżysko Kościelne',
			'Skarżysko-Kamienna',
			'Siemiątkowo',
			'Rutki',
			'Siennica Różana',
			'Różan',
			'Trzebielino',
			'Trzcinica',
			'Świdnica',
			'Subkowy',
			'Stegna',
			'Starcza',
			'Sompolno',
			'Smołdzino',
			'Skoczów',
			'Sanniki',
			'Rycerka Górna',
			'Pyskowice',
			'Przeginia',
			'Pruchna',
			'Popielów',
			'Polska Cerekiew',
			'Pelplin',
			'Pecna',
			'Parchowo',
			'Pajęczno',
			'Ozorków',
			'Ogrodzieniec',
			'Nowe',
			'Mieścisko',
			'Malec',
			'Luboń',
			'Lubomia',
			'Lubień Kujawski',
			'Leśna',
			'Łęka Opatowska',
			'Łęgowo',
			'Lachowice',
			'Kunice',
			'Koźle',
			'Koło',
			'Kłomnice',
			'Kłodawa',
			'Kleszczewo',
			'Kamieniec Ząbkowicki',
			'Wielki Kack',
			'Jabłonna',
			'Gostyń',
			'Gościno',
			'Golub-Dobrzyń',
			'Gniewkowo',
			'Dziemiany',
			'Budziszewice',
			'Brześć Kujawski',
			'Brąszewice',
			'Bojszowy Nowe',
			'Bobowo',
			'Bielsk',
			'Bedlno',
			'Andrychów',
			'Jelcz Laskowice',
			'Chotcza',
			'Rokiciny-Kolonia',
			'Proszowice',
			'Porąbka Uszewska',
			'Osieck',
			'Modliborzyce',
			'Masłów',
			'Jeziorzany',
			'Lubaczów',
			'Łochów',
			'Lipinki',
			'Łęki Górne',
			'Kraśniczyn',
			'Kowale Oleckie',
			'Jeziorany',
			'Jagiełła',
			'Guzów',
			'Gorzków',
			'Gorlice',
			'Głuchów',
			'Czaszyn',
			'Czarna',
			'Chlewiska',
			'Brzesko',
			'Brok',
			'Bojanów',
			'Stara Błotnica',
			'Żukowo',
			'Ziębice',
			'Zbytków',
			'Zbójno',
			'Żagań',
			'Włoszakowice',
			'Wierzbno',
			'Wielka Nieszawka',
			'Warlubie',
			'Waganiec',
			'Tworóg',
			'Tuczępy',
			'Terespol',
			'Tarnobrzeg',
			'Szczytno',
			'Stupsk',
			'Sokołów Małopolski',
			'Skrzyszów',
			'Skaryszew',
			'Sitno',
			'Sieniawa',
			'Rogóźno',
			'Radom',
			'Przemyśl',
			'Połaniec',
			'Niwiska',
			'Naruszewo',
			'Mszana Górna',
			'Mikołajki',
			'Maniowy',
			'Łosice',
			'Nowe Lipiny',
			'Łajski',
			'Krynica-Zdrój',
			'Kraśnik',
			'Komarówka Podlaska',
			'Kobylanka',
			'Klimontów',
			'Janowiec',
			'Imielno',
			'Gowarczów',
			'Głogów Małopolski',
			'Glinojeck',
			'Frydman',
			'Dzierzgowo',
			'Domaradz',
			'Dębów',
			'Cyców',
			'Cmolas',
			'Boćki',
			'Białołeka',
			'Aleksandrów',
			'Zagrodno',
			'Ząbkowice Śląskie',
			'Tuchola',
			'Tarnawa Dolna',
			'Sułkowice',
			'Strzebiń',
			'Stryków',
			'Stanisław Dolny',
			'Słubice',
			'Sławoborze',
			'Reda',
			'Radziszów',
			'Radlin',
			'Pogrzebień',
			'Podgórzyn',
			'Ostrowite',
			'Opalenica',
			'Kołaczkowo',
			'Ochojno',
			'Nowy Dwór Gdański',
			'Myślibórz',
			'Moszczanka',
			'Malczyce',
			'Lubniewice',
			'Lipusz',
			'Linia',
			'Kwilcz',
			'Kuślin',
			'Koźmin Wielkopolski',
			'Koluszki',
			'Kalisz',
			'Janków Przygodzki',
			'Grojec',
			'Gostyń',
			'Gniechowice',
			'Głuchołazy',
			'Garcz',
			'Dębowiec',
			'Dąbrowice',
			'Czarny Dunajec',
			'Chojnice',
			'Bolszewo',
			'Bełsznica',
			'Aleksandrów',
			'Podebłocie',
			'Świercze',
			'Zacisze',
			'Sołtysowice',
			'Maślice Wielkie',
			'Zielonka',
			'Zgłobień',
			'Zambrów',
			'Zagnańsk',
			'Wiślica',
			'Uście Gorlickie',
			'Trzebieszów',
			'Swiętajno',
			'Sułkowice',
			'Stoczek Łukowski',
			'Sępopol',
			'Rybie',
			'Podłęże',
			'Pionki',
			'Pawlikowice',
			'Ostrówek',
			'Osiek',
			'Orla',
			'Nowe Sioło',
			'Nieborów',
			'Mońki',
			'Michów',
			'Łuków',
			'Lubenia',
			'Łopuszna',
			'Łączna',
			'Kortowo',
			'Klimontów',
			'Jeżowe',
			'Iwaniska',
			'Grębków',
			'Gózd',
			'Gnojno',
			'Dębe Wielkie',
			'Czerwonka',
			'Czemierniki',
			'Czarna',
			'Ćmielów',
			'Chorzele',
			'Brzeziny',
			'Brańszczyk',
			'Borzechów',
			'Błażowa',
			'Bircza',
			'Żmigród',
			'Zabrzeg',
			'Wschowa',
			'Wola Krzysztoporska',
			'Wola',
			'Włosienica',
			'Wleń',
			'Wilków',
			'Warta',
			'Ujazd',
			'Szczawno-Zdrój',
			'Świedziebnia',
			'Świątniki Górne',
			'Sulejów',
			'Strzeleczki',
			'Środa Śląska',
			'Słupsk',
			'Sieraków',
			'Rybnik',
			'Rumia',
			'Pruszcz Gdański',
			'Polanka Wielka',
			'Pniewy',
			'Oświęcim',
			'Osjaków',
			'Opatów',
			'Odolanów',
			'Niegowonice',
			'Mieroszów',
			'Łysomice',
			'Łanięta',
			'Łączany',
			'Kuków',
			'Kościan',
			'Konin',
			'Koczała',
			'Kleszczów',
			'Jelenia Góra',
			'Henryków',
			'Gromadka',
			'Grabów',
			'Dziadowa Kłoda',
			'Dobrzyca',
			'Dobrzeń Wielki',
			'Dobiegniew',
			'Dąbrowa Zielona',
			'Czastary',
			'Czarnocin',
			'Brzozie',
			'Boleszkowice',
			'Bobrowo',
			'Blizanów',
			'Bełk',
			'Baranów',
			'Praga Południe',
			'Zalesie',
			'Rejon ulicy Borowskiej-Południe',
			'Zarzecze',
			'Ząbki',
			'Wohyń',
			'Werbkowice',
			'Szczebrzeszyn',
			'Susiec',
			'Stary Sącz',
			'Starachowice',
			'Sławno',
			'Osiedle-Nowiny',
			'Przytyk',
			'Przytoczno',
			'Poniatowa',
			'Owczarnia',
			'Opoczno',
			'Obrazów',
			'Nowosielce',
			'Nowe Brzesko',
			'Nieporęt',
			'Młynarze',
			'Miączyn',
			'Maków Mazowiecki',
			'Lutoryż',
			'Lubomino',
			'Łososina Dolna',
			'Łęki Dolne',
			'Kostomłoty Drugie',
			'Konstantynów',
			'Kołbiel',
			'Kazimierz Dolny',
			'Kamienica',
			'Jedwabne',
			'Jastrząb',
			'Handzlówka',
			'Grodzisko Górne',
			'Gnojnik',
			'Dzików Stary',
			'Dębno',
			'Dąbrowa Białostocka',
			'Czarna Białostocka',
			'Bukowsko',
			'Borowie',
			'Bisztynek',
			'Bielsk Podlaski',
			'Besko',
			'Złotoryja',
			'Zelów',
			'Zębowice',
			'Zbrosławice',
			'Wieleń',
			'Ustronie Morskie',
			'Uciechów',
			'Tuczno',
			'Trzciel',
			'Trąbki Wielkie',
			'Szubin',
			'Święciechowa',
			'Suszec',
			'Strzelno',
			'Stare Czarnowo',
			'Słupia',
			'Sidzina',
			'Sanka',
			'Rzozów',
			'Rymań',
			'Rajcza',
			'Radomsko',
			'Przewóz',
			'Poręba Wielka',
			'Połomia',
			'Pogorzyce',
			'Piekary Śląskie',
			'Pewel Wielka',
			'Ostroszowice',
			'Nowe Miasteczko',
			'Mrzeżyno',
			'Mosty',
			'Mirsk',
			'Maszewo',
			'Marciszów',
			'Lubraniec',
			'Lubiewo',
			'Lidzbark',
			'Lgota Wielka',
			'Lelów',
			'Lędziny',
			'Krzyżowice',
			'Krokowa',
			'Kozielice',
			'Kotla',
			'Kolsko',
			'Kołobrzeg',
			'Koleczkowo',
			'Kamienica',
			'Jeżów',
			'Jeleśnia',
			'Janikowo',
			'Grzmiąca',
			'Gorzów Śląski',
			'Goczałkowice Zdrój',
			'Drogomyśl',
			'Dobrzany',
			'Dobroń',
			'Dobre',
			'Dąbie',
			'Czułów',
			'Chocznia',
			'Burzenin',
			'Buk',
			'Babiak',
			'Żarki-Letnisko',
			'Zakrzów',
			'Marszowice',
			'Zalesie i Stadion',
			'Wojszyce',
			'Żołynia',
			'Załuski',
			'Zagórzyce',
			'Żabno',
			'Wydminy',
			'Winnica',
			'Wilków',
			'Wilkołaz',
			'Wierzbno',
			'Tomaszów Mazowiecki',
			'Sulejówek',
			'Stawiski',
			'Stanisławice',
			'Siemień',
			'Rzeczniów',
			'Raniżów',
			'Puławy',
			'Przesmyki',
			'Poświętne',
			'Pierzchnica',
			'Myszyniec',
			'Mogielnica',
			'Miechów',
			'Łomazy',
			'Lipowiec Kościelny',
			'Łętownia',
			'Łęczna',
			'Łaszczów',
			'Łańcut',
			'Krasnobród',
			'Jaśliska',
			'Jarocin',
			'Inowłódz',
			'Horodło',
			'Grzęska',
			'Grudusk',
			'Górzno',
			'Górno',
			'Godzianów',
			'Gdów',
			'Frampol',
			'Chorkówka',
			'Bystra',
			'Busko-Zdrój',
			'Bobrowniki',
			'Baranów',
			'Zbąszyń',
			'Zarzecze',
			'Wilczogóra',
			'Węgorzyno',
			'Wąsosz',
			'Toporzysko',
			'Stanowice',
			'Skąpe',
			'Siemkowice',
			'Radzyń Chełmiński',
			'Psary',
			'Przyszowice',
			'Popów',
			'Plewiska',
			'Pielgrzymowice',
			'Panki',
			'Ostrowite',
			'Ostróda',
			'Oława',
			'Nidek',
			'Miedzno',
			'Łyszkowice',
			'Legnickie Pole',
			'Lanckorona',
			'Krzyżowa',
			'Krzeszów',
			'Kraszewice',
			'Kolbudy',
			'Kaniów',
			'Kamienica Polska',
			'Juszczyna',
			'Jastarnia',
			'Harbutowice',
			'Dzięgielów',
			'Duszniki-Zdrój',
			'Dobroszyce',
			'Dobrodzień',
			'Deszczno',
			'Dębno',
			'Czerwonak',
			'Czarnków',
			'Czaplinek',
			'Ciechów',
			'Ciechocinek',
			'Bolechowice',
			'Bojadła',
			'Bogatynia',
			'Bobrowniki',
			'Białe Błota',
			'Barwałd Górny',
			'Praga Północ',
			'Sztutowo',
			'Leśnica-Ratyń-Pustki',
			'Partynice',
			'Psie Pole Południe-Kiełczów',
			'Powstańców Śląskich Wschód',
			'Żyrzyn',
			'Wietrzychowice',
			'Wielka Wieś',
			'Węgrów',
			'Waksmund',
			'Tarczyn',
			'Stromiec',
			'Sobolew',
			'Skołyszyn',
			'Sieradza',
			'Sienno',
			'Siennica',
			'Sanok',
			'Ryczywół',
			'Żurowa',
			'Wyśmierzyce',
			'Wólka Tanewska',
			'Wiśniowa',
			'Węgorzewo',
			'Jodłówka-Wałki',
			'Szarów',
			'Srokowo',
			'Siedliska',
			'Sawin',
			'Radoszyce',
			'Raczki',
			'Pruchnik',
			'Paprotnia',
			'Ostrów',
			'Opatów',
			'Niedźwiada',
			'Maszkienice',
			'Łapy',
			'Krzywda',
			'Krynki',
			'Krasnopol',
			'Korczew',
			'Karczmiska',
			'Józefów nad Wisłą',
			'Jastków',
			'Jakubów',
			'Iłża',
			'Hrubieszów',
			'Grójec',
			'Godziszów',
			'Firlej',
			'Dziekanów Leśny',
			'Dwikozy',
			'Dobre Miasto',
			'Ciepielów',
			'Chmielnik',
			'Cegłów',
			'Borowa',
			'Bolimów',
			'Biała Rawska',
			'Baranowo',
			'Żywiec',
			'Zwierzyń',
			'Złotniki Kujawskie',
			'Zielona Góra',
			'Zdzieszowice',
			'Zarzecze',
			'Zaborze',
			'Wrząsowice',
			'Wręczyca Wielka',
			'Witaszyce',
			'Wielgie',
			'Wapno',
			'Wąpielsk',
			'Ujście',
			'Tychy',
			'Trzcińsko Zdrój',
			'Szlachta',
			'Stepnica',
			'Stawiszyn',
			'Sokolniki',
			'Śmiłowo',
			'Sieniawa',
			'Rogoźnik',
			'Polkowice',
			'Połajewo',
			'Pogwizdów',
			'Płoty',
			'Obrzycko',
			'Młynary',
			'Mikołów',
			'Mielno',
			'Mieleszyn',
			'Miejska Górka',
			'Międzybórz',
			'Małomice',
			'Lipiany',
			'Łąck',
			'Krzyszkowice',
			'Kowal',
			'Kotlin',
			'Kornowac',
			'Kęty',
			'Kaźmierz',
			'Kamienna Góra',
			'Janowiec Wielkopolski',
			'Huta Stara B',
			'Cielmice',
			'Gąbin',
			'Frombork',
			'Fordon',
			'Dziećmorowice',
			'Daszyna',
			'Dąbie',
			'Człopa',
			'Czarna Woda',
			'Czarków',
			'Cewice',
			'Biały Bór',
			'Aleksandrów Łódzki',
			'Rzgów',
			'Rejon ulicy Saperów',
			'Swojczyce',
			'Rejowiec',
			'Rajgród',
			'Radziejowice',
			'Przecław',
			'Poświętne',
			'Pilawa',
			'Ostrołęka',
			'Opole Lubelskie',
			'Nasielsk',
			'Mława',
			'Medyka',
			'Mędrzechów',
			'Małkinia Górna',
			'Lubomierz',
			'Kozienice',
			'Kolbuszowa',
			'Kazimierza Wielka',
			'Kaniów',
			'Jedlnia-Letnisko',
			'Janów Lubelski',
			'Dywity',
			'Dębowiec',
			'Dębica',
			'Bobowa',
			'Augustów',
			'Żernica',
			'Żarki',
			'Zaborze',
			'Wysoka',
			'Władysławów',
			'Ujsoły',
			'Trzebinia',
			'Trzebiechów',
			'Tenczynek',
			'Świnoujście',
			'Święta Katarzyna',
			'Stróża',
			'Stare Kurowo',
			'Stare Bogaczowice',
			'Racibórz',
			'Przemęt',
			'Pabianice',
			'Ostrowy',
			'Olszyna',
			'Murów',
			'Międzyrzecz',
			'Malbork',
			'Lisewo Malborskie',
			'Lipno',
			'Liniewo',
			'Lewin Brzeski',
			'Łęczyca',
			'Łambinowice',
			'Laliki',
			'Krzeszowice',
			'Krajenka',
			'Koszarawa',
			'Koronowo',
			'Koniaków',
			'Kluki',
			'Kluczbork',
			'Kęsowo',
			'Izbicko',
			'Gorzyczki',
			'Godów',
			'Dzierżoniów',
			'Drawsko',
			'Czarnowąsy',
			'Bujaków',
			'Bolęcin',
			'Białka',
			'Baborów',
			'Chróścina',
			'Wysokie',
			'Wola',
			'Wodynie',
			'Wilanów',
			'Wierzbna',
			'Wiązownica',
			'Węgrzce Wielkie',
			'Uherce Mineralne',
			'Trzcinica',
			'Szreńsk',
			'Stare Juchy',
			'Sońsk',
			'Sokoły',
			'Rzepedź',
			'Rudna Wielka',
			'Rudna Mała',
			'Rokitno',
			'Raszyn',
			'Radecznica',
			'Poręba Spytkowska',
			'Poraż',
			'Połomia',
			'Piwniczna-Zdrój',
			'Pawłosiów',
			'Ostrów Mazowiecka',
			'Ostrówek',
			'Olszówka',
			'Mordy',
			'Mały Płock',
			'Lesko',
			'Łapsze Niżne',
			'Komańcza',
			'Kolno',
			'Kazanów',
			'Jonkowo',
			'Jedlińsk',
			'Janów Podlaski',
			'Hańsk',
			'Dębno',
			'Czarnia',
			'Chełmiec',
			'Borki',
			'Biecz',
			'Żary',
			'Zamarski',
			'Zabrze',
			'Zabierzów',
			'Wielichowo',
			'Tychowo',
			'Świerzawa',
			'Stara Kamienica',
			'Sosnówka',
			'Solec Kujawski',
			'Siepraw',
			'Santok',
			'Rudy',
			'Rudniki',
			'Przytoczna',
			'Przykona',
			'Połczyn-Zdrój',
			'Palcza',
			'Pakosław',
			'Osiek',
			'Oborniki',
			'Nowa Wieś Lęborska',
			'Mokrsko',
			'Lubiszyn',
			'Lipka',
			'Lipie',
			'Lębork',
			'Łabiszyn',
			'Kup',
			'Ksawerów',
			'Krzykosy',
			'Krzemieniewo',
			'Krzęcin',
			'Kruszwica',
			'Konary',
			'Kochanowice',
			'Kobierzyce',
			'Klonowa',
			'Kazimierz Biskupi',
			'Kamieniec',
			'Jarocin',
			'Jabłonka',
			'Górzyca',
			'Golina',
			'Godziszka',
			'Galewice',
			'Dolice',
			'Debrzno',
			'Damasławek',
			'Czernica',
			'Chybie',
			'Chrzypsko Wielkie',
			'Chełmek',
			'Bukowno',
			'Brusy',
			'Bojanowo',
			'Błaszki',
			'Bestwina',
			'Bębło',
			'Ursynów',
			'Bartniczka',
			'Zalesie',
			'Wola Żarczycka',
			'Wolanów',
			'Ulhówek',
			'Tuczna',
			'Tarnowiec',
			'Szynwałd',
			'Szczurowa',
			'Strzyżów',
			'Stalowa Wola',
			'Solec-Zdrój',
			'Sobienie Jeziory',
			'Słotowa',
			'Słopnice',
			'Słomniki',
			'Skierbieszów',
			'Serniki',
			'Rzeszów',
			'Rozbórz',
			'Olsztyn',
			'Niedzica',
			'Narol',
			'Miejsce Piastowe',
			'Ludwin',
			'Lidzbark Warmiński',
			'Lecka',
			'Kurów',
			'Krzynowłoga Mała',
			'Krempna',
			'Kraczkowa',
			'Kamionka Wielka',
			'Józefów',
			'Joniec',
			'Jasieniec',
			'Jadowniki',
			'Iłów',
			'Hornówek',
			'Grudki',
			'Fredropol',
			'Dziewin',
			'Działoszyce',
			'Abramów',
			'Żabnica',
			'Szczyrk',
			'Studzionka',
			'Studzienice',
			'Stronie Śląskie',
			'Stoszowice',
			'Słońsk',
			'Śliwice',
			'Siewierz',
			'Sieraków Śląski',
			'Sadki',
			'Rusiec',
			'Rokitno Szlacheckie',
			'Rokiciny',
			'Rączna',
			'Przyborów',
			'Przedbórz',
			'Pogorzela',
			'Pobiedziska',
			'Ozimek',
			'Osiek',
			'Nowy Tomyśl',
			'Mrzezino',
			'Mników',
			'Lubanie',
			'Łagiewniki',
			'Krotoszyn',
			'Koziegłowy',
			'Kościerzyna',
			'Kościelisko',
			'Kołbaskowo',
			'Kamień',
			'Kaczory',
			'Gryfino',
			'Górażdże',
			'Gilowice',
			'Domaniewice',
			'Dębnica Kaszubska',
			'Czernichów',
			'Czermin',
			'Chyżne',
			'Chwaszczyno',
			'Chorzów',
			'Budzów',
			'Brzeg',
			'Braniewo',
			'Boguszów-Gorce',
			'Bogdaniec',
			'Bierawa',
			'Bieńkówka',
			'Balin',
			'Pogórze',
			'Ruda-Huta',
			'Godziszów Pierwszy',
			'Osiedle Henrykowskie',
			'Biała Róża',
			'Żurawica',
			'Wyszków',
			'Urzędów',
			'Tarnogród',
			'Suwałki',
			'Stare Babice',
			'Smęgorzów',
			'Sękowa',
			'Rytro',
			'Policzna',
			'Podkowa Leśna',
			'Pińczów',
			'Piecki',
			'Piaseczno',
			'Niedrzwica Duża',
			'Muszyna',
			'Mszczonów',
			'Mrozy',
			'Miedzna',
			'Lublin',
			'Krzeszów',
			'Kozłowo',
			'Kozłów',
			'Korsze',
			'Jasienica',
			'Jabłonna Lacka',
			'Humniska',
			'Głuchów',
			'Dorohusk',
			'Chynów',
			'Bukowina Tatrzańska',
			'Brzączowice',
			'Bodzentyn',
			'Bielany',
			'Annopol',
			'Witonia',
			'Wieprz',
			'Tułowice',
			'Toruń',
			'Sztum',
			'Ślesin',
			'Skawinki',
			'Skawina',
			'Siedlec',
			'Ryjewo',
			'Rybno',
			'Pszczew',
			'Potok Złoty',
			'Poręba',
			'Piekielnik',
			'Pęczniew',
			'Paczków',
			'Otyń',
			'Orzech',
			'Mosina',
			'Miasteczko Krajeńskie',
			'Lubowidz',
			'Leśna',
			'Łagów',
			'Krzęcin',
			'Kryry',
			'Krynica Morska',
			'Krośniewice',
			'Kowary',
			'Kiernozia',
			'Kiełczów',
			'Kamiennik',
			'Jeżewo',
			'Izdebnik',
			'Gorzów Wielkopolski',
			'Gniewino',
			'Gąsawa',
			'Dobra',
			'Dąbrowa',
			'Czechowice-Dziedzice',
			'Cisiec',
			'Cieszków',
			'Brodnica',
			'Brodnica',
			'Brodła',
			'Boronów',
			'Bełchatów',
			'Babice',
			'Radwanice',
			'Karłowice',
			'Złota',
			'Złota',
			'Żabieniec',
			'Wysoka',
			'Wołomin',
			'Wola Dębińska',
			'Wilga',
			'Wierzchosławice',
			'Trzciana',
			'Tomaszów Lubelski',
			'Tczów',
			'Szczawnica',
			'Sucha',
			'Strzegowo',
			'Sławatycze',
			'Siedleczka',
			'Rzezawa',
			'Ryglice',
			'Rawa Mazowiecka',
			'Radziłów',
			'Przysucha',
			'Przysietnica',
			'Poronin',
			'Padew Narodowa',
			'Ożarów Mazowiecki',
			'Ożarów',
			'Oksa',
			'Nowa Słupia',
			'Niechobrz',
			'Mszana Dolna',
			'Mircze',
			'Lubochnia',
			'Liw',
			'Lipnica',
			'Latowicz',
			'Kuryłówka',
			'Kramarzówka',
			'Kończyce',
			'Klwów',
			'Kabaty',
			'Jabłonica Polska',
			'Goszczyn',
			'Dąbrówka',
			'Czerwin',
			'Czeremcha',
			'Chodel',
			'Bulkowo',
			'Białobrzegi',
			'Bełżyce',
			'Bartoszyce',
			'Albigowa',
			'Zubrzyca Górna',
			'Żnin',
			'Żerków',
			'Zembrzyce',
			'Zebrzydowice',
			'Zduny',
			'Zakrzów',
			'Zakrzewo',
			'Włodowice',
			'Wieszowa',
			'Więcbork',
			'Walce',
			'Tomice',
			'Szamotuły',
			'Świętochłowice',
			'Świebodzin',
			'Sulmierzyce',
			'Stara Dąbrowa',
			'Stanowice',
			'Somonino',
			'Skoroszyce',
			'Rząska',
			'Rydzyna',
			'Rokietnica',
			'Raszków',
			'Radków',
			'Pyzdry',
			'Prabuty',
			'Piotrków Kujawski',
			'Ożarowice',
			'Ochaby',
			'Oborniki Śląskie',
			'Mysłakowice',
			'Mogilno',
			'Margonin',
			'Łubianka',
			'Lipowa',
			'Łask',
			'Krzyżanów',
			'Konotop',
			'Kamień Krajeński',
			'Jastrowie',
			'Gubin',
			'Gruta',
			'Góra',
			'Gołuchów',
			'Golina',
			'Głuszyca Górna',
			'Gardeja',
			'Dygowo',
			'Drużbice',
			'Domaszowice',
			'Dąbrowa Górnicza',
			'Czernikowo',
			'Czarnożyły',
			'Choceń',
			'Cedynia',
			'Cedry Wielkie',
			'Bystrzyca Kłodzka',
			'Bystra',
			'Budzyń',
			'Brenno',
			'Bielsko-Biala',
			'Babice',
			'Mała Wieś',
			'Rotmanka',
			'Rejon placu Grunwaldzkiego',
			'Różanka-Polanka',
			'Zahutyń',
			'Wólka Niedźwiedzka',
			'Wizna',
			'Wiśniew',
			'Wiskitki',
			'Tarnów',
			'Tarnawatka',
			'Strzyżowice',
			'Sonina',
			'Skierniewice',
			'Sędziszów',
			'Rozogi',
			'Potok Wielki',
			'Pisz',
			'Orońsko',
			'Oleszyce',
			'Nowy Korczyn',
			'Nielisz',
			'Niedźwiada',
			'Lipnik',
			'Kruklanki',
			'Kotuń',
			'Kodeń',
			'Józefosław',
			'Janowice',
			'Iwierzyce',
			'Górowo Iławeckie',
			'Goraj',
			'Goniadz',
			'Brochów',
			'Brańsk',
			'Bliżyn',
			'Biskupice Radłowskie',
			'Włoszczowa',
			'Wieprz',
			'Uniejów',
			'Tarnów Opolski',
			'Sypniewo',
			'Strzelce Krajeńskie',
			'Stryszawa',
			'Strumień',
			'Starokrzepice',
			'Skwierzyna',
			'Rościszewo',
			'Przytkowice',
			'Pogórze',
			'Pieńsk',
			'Perzów',
			'Okonek',
			'Niemcza',
			'Nakło',
			'Mykanów',
			'Lubrza',
			'Lubomierz',
			'Łubniany',
			'Lublewo Gdańskie',
			'Lniano',
			'Krzeszyce',
			'Kroczyce',
			'Krapkowice',
			'Kożuchów',
			'Koźminek',
			'Klucze',
			'Kikół',
			'Kalwaria Zebrzydowska',
			'Grzechynia',
			'Gryfów Śląski',
			'Grębocice',
			'Górki Wielkie',
			'Głogoczów',
			'Gaworzyce',
			'Gardawice',
			'Dzierzgoń',
			'Dobra',
			'Dąbrowa Chełmińska',
			'Cięcina',
			'Cekcyn',
			'Bażanowice',
			'Banino',
			'Skarbimierz Osiedle',
			'Radwanice',
			'Łęki Dukielskie',
			'Krzyki',
			'Wysokie Mazowieckie',
			'Wieniawa',
			'Ustrzyki Dolne',
			'Trzebownisko',
			'Troszyn',
			'Trąbki',
			'Szelków',
			'Świdnik',
			'Sułów',
			'Rzewnie',
			'Rzekuń',
			'Rokietnica',
			'Żelechlinek',
			'Zaklików',
			'Żabia Wola',
			'Wilczyce',
			'Wieliszew',
			'Targowisko',
			'Szydłowo',
			'Szepietowo',
			'Suraż',
			'Stare Miasto',
			'Ropa',
			'Rakszawa',
			'Radzanów',
			'Pysznica',
			'Puńsk',
			'Prostki',
			'Podedwórze',
			'Pasym',
			'Pacanów',
			'Ostrów',
			'Orzysz',
			'Orneta',
			'Nawojowa',
			'Mniów',
			'Mirzec',
			'Mełgiew',
			'Leżajsk',
			'Laskowa',
			'Kuźnica',
			'Kozubszczyzna',
			'Kępie Żaleszańskie',
			'Jawornik',
			'Izbica',
			'Horyniec-Zdrój',
			'Gierłoż',
			'Fajsławice',
			'Dzierzkowice',
			'Chwałowice',
			'Chęciny',
			'Brzyska',
			'Brzóza Królewska',
			'Bochnia',
			'Biały Dunajec',
			'Białka Tatrzańska',
			'Bejsce',
			'Złoczew',
			'Zawiercie',
			'Wysoka',
			'Wisła',
			'Wierzchosławice',
			'Wiązów',
			'Walichnowy',
			'Wadowice',
			'Ustka',
			'Trzemeszno',
			'Toszek',
			'Szczerców',
			'Szczekociny',
			'Syrynia',
			'Sułów',
			'Sulechów',
			'Stryszów',
			'Spytkowice',
			'Słupno',
			'Ślesin',
			'Siechnice',
			'Sianów',
			'Ruda Śląska',
			'Rewal',
			'Radziejów',
			'Radowo Małe',
			'Pszów',
			'Polanica-Zdrój',
			'Pawłowice',
			'Osiek',
			'Nieszawa',
			'Niechanowo',
			'Modlnica',
			'Milówka',
			'Miłkowice',
			'Miedźna',
			'Małdyty',
			'Lipnica Mała',
			'Krosno Odrzańskie',
			'Kozy',
			'Kościelec',
			'Koniecpol',
			'Kobylnica',
			'Karnice',
			'Kamieńsk',
			'Jaworzynka',
			'Jawornik',
			'Jasienica',
			'Iława',
			'Herby',
			'Gozdnica',
			'Góra',
			'Gołańcz',
			'Damnica',
			'Chróścice',
			'Chełm Śląski',
			'Bukowiec',
			'Brzezinka',
			'Brudzeń Duży',
			'Bieruń',
			'Bestwinka',
			'Balice',
			'Krościenko nad Dunajcem',
			'Iłowo -Osada',
			'Jarnołtów-Jerzmanowo',
			'Gądów Mały',
			'Poświętne',
			'Radymno',
			'Radłów',
			'Przybyszówka',
			'Podegrodzie',
			'Ostrowiec Świętokrzyski',
			'Niebylec',
			'Mrągowo',
			'Michałów',
			'Michałów',
			'Markuszów',
			'Lipsko',
			'Limanowa',
			'Leszno',
			'Krasne',
			'Krasne',
			'Kluszkowce',
			'Kamień',
			'Józefów',
			'Grabowiec',
			'Giżycko',
			'Gąsocin',
			'Ełk',
			'Dubiecko',
			'Daleszyce',
			'Brzeźnica',
			'Binarowa',
			'Bieliny',
			'Bielany',
			'Zawidz',
			'Zalas',
			'Wrocław',
			'Wolbrom',
			'Wola Radziszowska',
			'Włocławek',
			'Wilcza',
			'Wałcz',
			'Szczecin',
			'Szczaniec',
			'Świerklany Górne',
			'Świecie',
			'Świebodzice',
			'Stęszew',
			'Stary Targ',
			'Stare Miasto',
			'Stanisław Górny',
			'Smolec',
			'Słupia',
			'Słubice',
			'Skawica',
			'Siemianowice Śląskie',
			'Secemin',
			'Pyrzyce',
			'Puszczykowo',
			'Psary',
			'Powidz',
			'Ponikiew',
			'Podwilk',
			'Płużnica',
			'Pawłowiczki',
			'Orchowo',
			'Obrowo',
			'Nowa Sól',
			'Mrozów',
			'Miłosław',
			'Mieszkowice',
			'Miękinia',
			'Międzylesie',
			'Maszewo Duże',
			'Markłowice',
			'Luzino',
			'Ludźmierz',
			'Lubsko',
			'Łodygowice',
			'Łaziska Górne',
			'Lądek-Zdrój',
			'Kudowa-Zdrój',
			'Krzeszów',
			'Kościelec',
			'Kosakowo',
			'Kończyce Wielkie',
			'Karpacz',
			'Kamyk',
			'Kaczyce',
			'Jemielno',
			'Grzegorzew',
			'Głogów',
			'Gizałki',
			'Frydek',
			'Doruchów',
			'Cieszyn',
			'Bytom',
			'Brójce',
			'Brody',
			'Bojanowo Stare',
			'Barwałd Średni',
			'Barcin',
			'Alwernia',
			'Biskupiec',
			'Biała',
			'Bełżec',
			'Zdziechowice Drugie',
			'Biskupin',
			'Żarnów',
			'Zalesie Górne',
			'Wola Zabierzowska',
			'Wodzisław',
			'Włodawa',
			'Wierzawice',
			'Wielka Wieś',
			'Uścimów Stary',
			'Tykocin',
			'Szastarka',
			'Suchowola',
			'Słupiec',
			'Siemiechów',
			'Siedliska',
			'Sandomierz',
			'Rybno',
			'Rusinów',
			'Rossosz',
			'Rogóźno',
			'Radzyń Podlaski',
			'Racławice',
			'Raciąż',
			'Przyłęk',
			'Piastów',
			'Piaski',
			'Pawłów',
			'Otrębusy',
			'Oleśnica',
			'Nowa Sarzyna',
			'Morawica',
			'Mokotów',
			'Mokobody',
			'Miastków Kościelny',
			'Markowa',
			'Leśnica',
			'Laszki',
			'Jodłowa',
			'Dąbrówno',
			'Czarna Góra',
			'Bychawa',
			'Błonie',
			'Białobrzegi',
			'Adamów',
			'Złotniki',
			'Zgorzelec',
			'Wronki',
			'Wińsko',
			'Wierzchlas',
			'Widuchowa',
			'Tyniec Mały',
			'Tuplice',
			'Trzebiatów',
			'Tarnówka',
			'Szprotawa',
			'Szamocin',
			'Świeradów-Zdrój',
			'Świekatowo',
			'Świdwin',
			'Sulików',
			'Sucha Beskidzka',
			'Strzelce Opolskie',
			'Straszyn',
			'Staroźreby',
			'Sól',
			'Śmigiel',
			'Sława',
			'Sierpc',
			'Sępólno Krajeńskie',
			'Ścinawa',
			'Sąspów',
			'Rudnik',
			'Psary',
			'Przygodzice',
			'Polanów',
			'Pietrowice Wielkie',
			'Piasek',
			'Pamiątkowo',
			'Otmuchów',
			'Nowogród Bobrzański',
			'Niemodlin',
			'Nakło',
			'Mnich',
			'Malanów',
			'Łowicz',
			'Lipnica',
			'Leszno',
			'Łeba',
			'Kwidzyn',
			'Komprachcice',
			'Klenica',
			'Katowice',
			'Kalisz Pomorski',
			'Kaliska',
			'Kalety',
			'Jemielnica',
			'Jelcz',
			'Jankowice Rybnickie',
			'Ińsko',
			'Hel',
			'Grabica',
			'Góra Świętej Małgorzaty',
			'Goleniów',
			'Gierałtowice',
			'Drezdenko',
			'Długomiłowice',
			'Czaniec',
			'Ciepłowody',
			'Chrzanów',
			'Chodzież',
			'Bobrowniki',
			'Bledzew',
			'Sulbiny Górne',
			'Krzczonów',
			'Nowe Ostrowy',
			'Maślice Małe',
			'Kowale-Popiele',
			'Zakliczyn',
			'Wola Uhruska',
			'Wojcieszków',
			'Tereszpol',
			'Straszydle',
			'Stanisławów',
			'Sosnówka',
			'Smyków',
			'Ryn',
			'Ryki',
			'Reszel',
			'Raków',
			'Radomyśl',
			'Radgoszcz',
			'Pułtusk',
			'Potworów',
			'Polańczyk',
			'Pilzno',
			'Nozdrzec',
			'Nowy Wiśnicz',
			'Nienadowa',
			'Mrozy',
			'Miętne',
			'Majdan Królewski',
			'Łyse',
			'Lubycza Królewska',
			'Łoniów',
			'Lipnica Wielka',
			'Lesznowola',
			'Leśna Podlaska',
			'Łagów',
			'Księżpol',
			'Krzywcza',
			'Krosno',
			'Kokotów',
			'Kielanówka',
			'Jabłonka',
			'Harasiuki',
			'Hajnówka',
			'Gzy',
			'Gwoźnica Górna',
			'Dobczyce',
			'Dąbrowa Tarnowska',
			'Czerwińsk Nad Wisłą',
			'Czernice Borowe',
			'Ciechanów',
			'Chełm',
			'Celestynów',
			'Bodzechów',
			'Wierzbinek',
			'Udanin',
			'Tokarnia',
			'Świerklany Dolne',
			'Świerklaniec',
			'Ślemień',
			'Sieradz',
			'Rogoźno',
			'Prusice',
			'Prochowice',
			'Poddębice',
			'Pobierowo',
			'Nowy Staw',
			'Mosty',
			'Moryń',
			'Miastko',
			'Liszki',
			'Lisewo',
			'Łagów',
			'Kowalewo Pomorskie',
			'Karlino',
			'Jutrosin',
			'Jabłonowo Pomorskie',
			'Grodziec',
			'Grabów nad Prosną',
			'Gostycyn',
			'Gierałtowice',
			'Elbląg',
			'Drzycim',
			'Częstochowa',
			'Czempiń',
			'Czeladź',
			'Ciasna',
			'Chocianów',
			'Chłapowo',
			'Chełmno',
			'Bielany Wrocławskie',
			'Bąków',
			'Pilczyce',
			'Dąbie',
			'Żyrardów',
			'Zwierzyniec',
			'Żegocina',
			'Wysoka Strzyżowska',
			'Wojnicz',
			'Wojaszówka',
			'Wąsosz',
			'Trojanów',
			'Trawniki',
			'Stopnica',
			'Stężyca',
			'Sterdyń',
			'Stara Wieś',
			'Sośnica',
			'Sadkowice',
			'Sabnie',
			'Rzepiennik Strzyżewski',
			'Rytwiany',
			'Rymanów',
			'Repki',
			'Radzymin',
			'Radzanów',
			'Prażmów',
			'Potok Górny',
			'Poręba',
			'Osieczany',
			'Orzechówka',
			'Odrzywół',
			'Niebocko',
			'Milanówek',
			'Marki',
			'Łubnice',
			'Legionowo',
			'Kowala',
			'Knyszyn',
			'Kiełpin',
			'Jasło',
			'Iwonicz-Zdrój',
			'Gręboszów',
			'Granica',
			'Dydnia',
			'Dobra',
			'Choroszcz',
			'Białobrzegi',
			'Barciany',
			'Wyry',
			'Wożniki',
			'Wołczyn',
			'Wojkowice',
			'Wielowieś',
			'Wielka Wieś',
			'Wejherowo',
			'Węgierska Górka',
			'Ujazd',
			'Szadek',
			'Sypniewo',
			'Susz',
			'Suchań',
			'Sławków',
			'Sicienko',
			'Rybarzowice',
			'Rogalinek',
			'Recz',
			'Puńców',
			'Praszka',
			'Pleszew',
			'Płaza',
			'Pilica',
			'Piława Górna',
			'Pewel Ślemieńska',
			'Ostrzeszów',
			'Ostaszewo',
			'Osie',
			'Nowe Miasto nad Wartą',
			'Nowa Góra',
			'Mochowo',
			'Lwówek Śląski',
			'Lwówek',
			'Lubień',
			'Książ Wielkopolski',
			'Książki',
			'Krzczonów',
			'Kobyla Góra',
			'Kłodawa',
			'Kisielice',
			'Kcynia',
			'Kalej',
			'Juszczyn',
			'Jugów',
			'Jastrząb',
			'Izbica Kujawska',
			'Działoszyn',
			'Dominowo',
			'Długołęka',
			'Dąbrowa',
			'Chojna',
			'Chąśno',
			'Budzów',
			'Brzeźnio',
			'Brudzew',
			'Branice',
			'Barlinek',
			'Babimost',
			'Rutki-Kossaki',
			'Pawłowice-Kłokoczyce',
			'Powstańców Śląskich Zachód-Centrum Południow',
			'Lipkowo',
			'Żelechów',
			'Wysoka Głogowska',
			'Wojciechowice',
			'Wielbark',
			'Węglówka',
			'Warsaw',
			'Trzciana',
			'Trablice',
			'Tarłów',
			'Szczucin',
			'Stubno',
			'Sieniawa',
			'Siedlce',
			'Serokomla',
			'Serock',
			'Ruda Maleniecka',
			'Zawichost',
			'Zatory',
			'Zamość',
			'Wieliczka',
			'Wesoła',
			'Wesoła',
			'Wąwolnica',
			'Ursus',
			'Szaflary',
			'Suchożebry',
			'Stawiguda',
			'Słupia',
			'Skrzydlna',
			'Sietesz',
			'Sejny',
			'Sadowne',
			'Rzeczyca',
			'Rachanie',
			'Przeworsk',
			'Olecko',
			'Okocim',
			'Ochota',
			'Nowogród',
			'Nagłowice',
			'Nadarzyn',
			'Maków',
			'Lutowiska',
			'Łapanów',
			'Łabunie',
			'Krośnica',
			'Krasiczyn',
			'Koszyce',
			'Końskie',
			'Karniewo',
			'Jedlicze',
			'Jasionów',
			'Jarosław',
			'Jakubowice Murowane',
			'Gromnik',
			'Gać',
			'Fałków',
			'Dzierzążnia',
			'Cieszanów',
			'Cielądz',
			'Ciechanowiec',
			'Brzozów',
			'Boguty-Pianki',
			'Bałtów',
			'Baligród',
			'Żychlin',
			'Zielonki',
			'Żerniki Wrocławskie',
			'Zbąszynek',
			'Wojcieszów',
			'Trzcianka',
			'Świerczów',
			'Sulmierzyce',
			'Sułkowice',
			'Strzelno',
			'Sośnicowice',
			'Sopotnia Wielka',
			'Sopot',
			'Szczerbice',
			'Skulsk',
			'Rychwał',
			'Roczyny',
			'Raszczyce',
			'Racławice Śląskie',
			'Przeciszów',
			'Cynków',
			'Pisarzowice',
			'Ożarów',
			'Opatówek',
			'Opatów',
			'Nowogard',
			'Nowe Warpno',
			'Nowe Miasto Lubawskie',
			'Nowa Wieś Wielka',
			'Mysłowice',
			'Mstów',
			'Mikstat',
			'Międzyzdroje',
			'Międzychód',
			'Miechów Charsznica',
			'Miasteczko Śląskie',
			'Maszewo',
			'Marianowo',
			'Ludwikowice Kłodzkie',
			'Lubichowo',
			'Łosiów',
			'Łódź',
			'Łasin',
			'Łąka Prudnicka',
			'Kutno',
			'Komorniki',
			'Kolonowskie',
			'Kobiernice',
			'Kamień Pomorski',
			'Jejkowice',
			'Gorenice',
			'Głowno',
			'Gałków Mały',
			'Człuchów',
			'Czerwionka-Leszczyny',
			'Czerniewice',
			'Chrząstowice',
			'Chmielowice',
			'Chodów',
			'Bystrzyca',
			'Bulowice',
			'Brzeziny Śląskie',
			'Bozkow',
			'Borowno',
			'Będków',
			'Barwice',
			'Baranowo',
			'Mirków',
			'Rejon ulicy Klęczkowskiej',
			'Rejon alei Kromera',
			'Przasnysz',
			'Pruszków',
			'Promna',
			'Pieniężno',
			'Paradyż',
			'Ołpiny',
			'Nurzec-Stacja',
			'Nowy Sącz',
			'Nowodwór',
			'Nisko',
			'Niepołomice',
			'Niedomice',
			'Nałęczów',
			'Moszczenica',
			'Michałowo',
			'Łużna',
			'Lubartów',
			'Łęki',
			'Krynice',
			'Krasnystaw',
			'Korzenna',
			'Korytnica',
			'Kadzidło',
			'Jędrzejów',
			'Jednorożec',
			'Jasień',
			'Jabłonna',
			'Jabłoń',
			'Halinów',
			'Gorzyce',
			'Górki',
			'Dzikowiec',
			'Dziadkowice',
			'Drzewica',
			'Dobrzyniewo Duże',
			'Chmielnik',
			'Brwinów',
			'Bogoria',
			'Bodzanów',
			'Białaczów',
			'Bemowo',
			'Batorz',
			'Babica',
			'Zubrzyca Dolna',
			'Zawoja',
			'Wyrzysk',
			'Woźniki',
			'Wołowice',
			'Witanowice',
			'Wilamowice',
			'Ustroń',
			'Torzym',
			'Topólka',
			'Stare Pole',
			'Sidzina',
			'Sędziejowice',
			'Rzeszotary',
			'Ryczywół',
			'Rogowo',
			'Rekowo Dolne',
			'Raciążek',
			'Rabka-Zdrój',
			'Przywidz',
			'Przystajń',
			'Poniec',
			'Pławno',
			'Piekary',
			'Pępowo',
			'Pacyna',
			'Osina',
			'Murcki',
			'Mrocza',
			'Moszczenica',
			'Międzybrodzie Bialskie',
			'Lubań',
			'Łęknica',
			'Krzywaczka',
			'Kowale',
			'Kostrzyn',
			'Kończyce Małe',
			'Knurów',
			'Karsin',
			'Jaworzyna Śląska',
			'Jaroszowice',
			'Jaraczewo',
			'Gronowo Elbląskie',
			'Grodków',
			'Goszczanów',
			'Gniew',
			'Frydrychowice',
			'Chruszczobród',
			'Chrostkowo',
			'Choszczno',
			'Chociwel',
			'Bytów',
			'Byczyna',
			'Brzeg Dolny',
			'Brody-Parcele',
			'Rejon ulicy Traugutta',
			'Pracze Odrzańskie-Janówek',
			'Kozanów',
			'Zmiennica',
			'Zarszyn',
			'Zakroczym',
			'Zabłudów',
			'Wierzbica',
			'Wielopole Skrzyńskie',
			'Warka',
			'Urzejowice',
			'Ulanów',
			'Turośń Kościelna',
			'Trzydnik Duży',
			'Tarnowiec',
			'Szerzyny',
			'Świlcza',
			'Skalbmierz',
			'Sędziszów Małopolski',
			'Samborzec',
		],
	},
	Portugal: {
		country: 'Portugal',
		cities: [
			'Vila Nova de Milfontes',
			'Valado de Frades',
			'Sobral de Monte Agraço',
			'Póvoa e Meadas',
			'Monte Redondo',
			'Caranguejeira',
			'Bucelas',
			'Bombarral',
			'Armação de Pêra',
			'Vizela',
			'Vilar do Paraíso',
			'Valbom',
			'Tocha',
			'Serzedo',
			'Mangualde',
			'Lavos',
			'Custoias',
			'Covilhã',
			'Aguiar da Beira',
			'Vila Franca do Campo',
			'Fonte Bastardo',
			'Castelo Branco',
			'Vilamoura',
			'Vila Chã de Ourique',
			'São Brás de Alportel',
			'Santo Antão do Tojal',
			'Santarém',
			'Santa Luzia',
			'Quinta do Anjo',
			'Paialvo',
			'Mexilhoeira Grande',
			'Lavradio',
			'Ferreira do Alentejo',
			'Fazendas de Almeirim',
			'Cartaxo',
			'Cadafais',
			'Boaventura',
			'Atouguia da Baleia',
			'Alcochete',
			'Vila Nova da Telha',
			'Várzea',
			'Sernancelhe',
			'Riba de Ave',
			'Oliveira do Douro',
			'Nelas',
			'Mirandela',
			'Macieira de Rates',
			'Guisande',
			'Fafe',
			'Canelas',
			'Anta',
			'Águeda',
			'Águas Santas',
			'São Vicente',
			'Ribeira Grande',
			'Prior Velho',
			'Vila Verde',
			'Silveira',
			'Pontinha',
			'Peniche',
			'Palmela',
			'Lourinhã',
			'Grândola',
			'Carcavelos',
			'Alegrete',
			'Vilela',
			'Semelhe',
			'Ponte de Lima',
			'Moreira',
			'Montemor-o-Velho',
			'Margaride',
			'Lousada',
			'Apúlia e Fão',
			'Esposende',
			'Baguim do Monte',
			'Argivai',
			'Aradas',
			'Abraveses',
			'São Mateus',
			'Ribeira Seca',
			'Relva',
			'Vila Baleira',
			'Torres Novas',
			'Serpa',
			'São Teotónio',
			'Santa Margarida da Coutada',
			'Samouco',
			'Marinha Grande',
			'Loureira',
			'Cascais',
			'Vilar de Andorinho',
			'Valpaços',
			'Valongo',
			'Tondela',
			'Teixoso',
			'Serzedo',
			'Seia',
			'Santo António dos Olivais',
			'Rio Meão',
			'Póvoa de Lanhoso',
			'Penedono',
			'Penacova',
			'Pedrouços',
			'Pampilhosa da Serra',
			'Nogueira da Regedoura',
			'Gafanha da Encarnação',
			'Ferreiros',
			'Branca',
			'Belmonte',
			'Armamar',
			'Alijó',
			'Santo Isidoro',
			'Vale de Figueira',
			'São Roque',
			'Monchique',
			'Milharado',
			'Gavião',
			'Coruche',
			'Caxias',
			'Castanheira do Ribatejo',
			'Camacha',
			'Cabanas de Torres',
			'Bemfica',
			'Barrancos',
			'Almograve',
			'Almeirim',
			'Alcobaça',
			'Vila do Conde',
			'São Roque',
			'Santo Tirso',
			'Santa Clara',
			'Rebordosa',
			'Mesão Frio',
			'Meinedo',
			'Lousã',
			'Lavra',
			'Grijó',
			'Estela',
			'Esporões',
			'Carrazeda de Anciães',
			'Cabeceiras de Basto',
			'Belver',
			'Arrifana',
			'Areosa',
			'Arcos',
			'Aldeia de Joanes',
			'Santa Bárbara',
			'Ribeira Grande',
			'Porto Judeu',
			'Angústias',
			'Água de Pau',
			'Santos-o-Velho',
			'Azeitão',
			'São Julião',
			'Salvada',
			'Rio de Mouro',
			'Porches',
			'Ponta do Sol',
			'Olhão',
			'Minas de São Domingos',
			'Leiria',
			'Lagos',
			'Juncal',
			'Foz do Arelho',
			'Famões',
			'Avelar',
			'Amadora',
			'Altura',
			'Almancil',
			'Real',
			'Penafiel',
			'Paços de Ferreira',
			'Oliveira',
			'Leça da Palmeira',
			'Guarda',
			'Ervedosa do Douro',
			'Buarcos',
			'Vila do Bispo',
			'Vidais',
			'Venda do Pinheiro',
			'Tunes',
			'Trafaria',
			'Torres Vedras',
			'Santa Catarina da Serra',
			'Portel',
			'Ourique',
			'Óbidos',
			'Nazaré',
			'Montalvo',
			'Monforte',
			'Mira',
			'Meia Via',
			'Maceira',
			'Castro Marim',
			'Castelo de Vide',
			'Castanheira de Pêra',
			'Bobadela',
			'Ansião',
			'Alvorninha',
			'Alhandra',
			'Alcácer do Sal',
			'Alandroal',
			'A dos Cunhados',
			'Abrigada',
			'Vieira do Minho',
			'Válega',
			'Sátão',
			'São Pedro de Alva',
			'Oliveira de Frades',
			'Mariz',
			'Marco de Canavezes',
			'Jovim',
			'Freamunde',
			'Fornos de Algodres',
			'Chaves',
			'Calendário',
			'Caldelas',
			'Boticas',
			'Arazede',
			'Apúlia',
			'Ançã',
			'Alpendurada',
			'Alfragide',
			'Vila Nova da Barquinha',
			'Senhora da Luz',
			'Santo André',
			'Olivais',
			'Oleiros',
			'Nadadouro',
			'Meca',
			'Laranjeiro',
			'Charneca',
			'Caparica',
			'Almada',
			'Vila Nova de Cerveira',
			'Rio Mau',
			'Oliveira do Hospital',
			'Melres',
			'Lourosa',
			'Lordelo',
			'Gueral',
			'Fradelos',
			'Coimbra',
			'Canas de Senhorim',
			'Argoncilhe',
			'Santa Cruz das Flores',
			'Lajes das Flores',
			'Vialonga',
			'Sardoal',
			'Santo António da Charneca',
			'Santiago do Cacém',
			'Santa Bárbara de Nexe',
			'Queluz',
			'Póvoa de Santo Adrião',
			'Monte Gordo',
			'Minde',
			'Milagres',
			'Idanha-a-Nova',
			'Figueiró dos Vinhos',
			'Faro',
			'Estômbar',
			'Colares',
			'Castelo Branco',
			'Caniço',
			'Caniçal',
			'Arraiolos',
			'Vilaça',
			'Treixedo',
			'Trancoso',
			'São Pedro do Sul',
			'Rebordões',
			'Oliveira do Mondego',
			'Mindelo',
			'Barrosas',
			'Arcos',
			'Ponta Delgada',
			'Cabanas de Tavira',
			'Vieira de Leiria',
			'Urra',
			'Serra de El-Rei',
			'Olival Basto',
			'Nisa',
			'Monte Estoril',
			'Moncarapacho',
			'Lagoa',
			'Guia',
			'Fátima',
			'Curral das Freiras',
			'Aljustrel',
			'Albufeira',
			'Urgeses',
			'Soure',
			'Sobreira',
			'Sobrado',
			'São Pedro da Cova',
			'Monção',
			'Gulpilhares',
			'Guimarães',
			'Frazão',
			'Fânzeres',
			'Espargo',
			'Ermesinde',
			'Cucujães',
			'Castelo de Paiva',
			'Canidelo',
			'Avanca',
			'Alhadas',
			'Fenais da Ajuda',
			'Cais do Pico',
			'Vila Velha de Ródão',
			'Vila Franca de Xira',
			'Turquel',
			'São Martinho',
			'São Bartolomeu',
			'Santa Cruz',
			'Moura',
			'Moscavide e Portela',
			'Castro Verde',
			'Carreira',
			'Beringel',
			'Alcantarilha',
			'Viseu',
			'Vila Verde',
			'Vagos',
			'Tebosa',
			'Sever do Vouga',
			'Serzedelo',
			'São Mamede de Infesta',
			'Sanfins',
			'Ribeirão',
			'Manteigas',
			'Maia',
			'Foz do Sousa',
			'Felgueiras',
			'Castro Daire',
			'Taipas',
			'Cabanas de Viriato',
			'Avintes',
			'Arouca',
			'Anadia',
			'Amorim',
			'Aguada de Cima',
			'Adaúfe',
			'Maia',
			'Lajes',
			'Vila Nova De Cacela',
			'Tramagal',
			'Silves',
			'Sertã',
			'Odivelas',
			'Montalvinho',
			'Marinhais',
			'Laranjeiro',
			'Cuba',
			'Cercal',
			'Canhas',
			'Beato António',
			'Barcarena',
			'Aldeia de Paio Pires',
			'Alagoa',
			'Vinhais',
			'Tavarede',
			'Tabuaço',
			'Silvalde',
			'Selho',
			'São Miguel do Couto',
			'São João de Areias',
			'Sande',
			'Meadela',
			'Madalena',
			'Lamas',
			'Gondizalves',
			'Foz do Douro',
			'Baltar',
			'Vila Flor',
			'Vidigueira',
			'Sobreda',
			'Praia do Ribatejo',
			'Machico',
			'Charneca de Caparica',
			'Campelos',
			'Bensafrim',
			'Barreiro',
			'Almargem',
			'Alcabideche',
			'São Félix da Marinha',
			'Santa Cruz do Bispo',
			'Salreu',
			'Penamacor',
			'Montalegre',
			'Lordelo',
			'Frossos',
			'Azenha',
			'Aves',
			'Aveleda',
			'Alfena',
			'Albergaria-a-Velha',
			'Santa Cruz da Graciosa',
			'Pêra',
			'Moita',
			'Fortios',
			'Entroncamento',
			'Corroios',
			'A dos Francos',
			'Trofa',
			'Cinfães',
			'Seroa',
			'Santa Comba Dão',
			'Póvoa de Varzim',
			'Perelhal',
			'Luso',
			'Ílhavo',
			'Gandra',
			'Figueiró',
			'Favaios',
			'Eixo',
			'Cortegaça',
			'Celorico da Beira',
			'Árvore',
			'Velas',
			'Praia da Vitória',
			'Povoação',
			'Vila Viçosa',
			'São Marcos da Serra',
			'Paço de Arcos',
			'Mafra',
			'Câmara de Lobos',
			'Azinhaga',
			'Aveiras de Cima',
			'Amor',
			'Alter do Chão',
			'Alcoentre',
			'Água de Pena',
			'Vilarinho',
			'Vila Frescainha',
			'São Romão',
			'Rio Tinto',
			'Freixo de Espada à Cinta',
			'Faria',
			'Campo de Besteiros',
			'Tavira',
			'Santo André',
			'Salir de Matos',
			'Sagres',
			'Reguengo',
			'Mértola',
			'Loulé',
			'Fuzeta',
			'Ferreira do Zêzere',
			'Conceição',
			'Calheta',
			'Boliqueime',
			'Boavista dos Pinheiros',
			'Benedita',
			'Avis',
			'Alpiarça',
			'Alhos Vedros',
			'Aldeia Nova',
			'Alcanhões',
			'Alcains',
			'Vila Nova de Foz Côa',
			'Torre de Moncorvo',
			'Tarouca',
			'Souto',
			'São João',
			'Prado',
			'Negrelos',
			'Milheirós',
			'Galegos',
			'Castelões de Cepeda',
			'Carregal do Sal',
			'Carapinheira',
			'Candoso',
			'Campo',
			'Barcelos',
			'Alfarelos',
			'Vila de Rei',
			'Sarilhos Pequenos',
			'São João dos Montes',
			'São João das Lampas',
			'Pataias',
			'Olho Marinho',
			'Linda-a-Velha',
			'Alvaiázere',
			'Alcanede',
			'Vizela',
			'Vairão',
			'Travanca',
			'Sermonde',
			'Sangalhos',
			'Pardilhó',
			'Moimenta da Beira',
			'Loriga',
			'Lamego',
			'Góis',
			'Estarreja',
			'Bragança',
			'Bougado',
			'Barqueiros',
			'Amares',
			'São Roque',
			'Arrifes',
			'Sines',
			'São Martinho do Porto',
			'São Bartolomeu de Messines',
			'Reguengos de Monsaraz',
			'Ramada',
			'Manta Rota',
			'Mação',
			'Loures',
			'Lisbon',
			'Estoril',
			'Comporta',
			'Carnaxide',
			'Cacilhas',
			'Arronches',
			'Arrentela',
			'Amora',
			'Almodôvar',
			'Algés',
			'Vimioso',
			'Valença',
			'Resende',
			'Olival',
			'Negreiros',
			'Mozelos',
			'Miranda do Douro',
			'Miranda do Corvo',
			'Lorvão',
			'Gemunde',
			'Esgueira',
			'Darque',
			'Chavão',
			'Celorico de Basto',
			'Carvalhosa',
			'Arões',
			'Unhos',
			'Samora Correia',
			'Queijas',
			'Ponte de Sôr',
			'Parchal',
			'Ourém',
			'Odemira',
			'Montijo',
			'Benavente',
			'Apelação',
			'Alenquer',
			'São João da Pesqueira',
			'Samil',
			'Pinhel',
			'Oliveira do Bairro',
			'Oliveira de Azemeis',
			'Mêda',
			'Macedo de Cavaleiros',
			'Arganil',
			'São Sebastião',
			'Vendas Novas',
			'São Luis',
			'São Domingos de Rana',
			'Proença-a-Nova',
			'Pero Pinheiro',
			'Oeiras',
			'Montemor-o-Novo',
			'Malveira',
			'Louriçal',
			'Famalicão',
			'Carregado',
			'Benfica',
			'Atalaia',
			'Algueirão',
			'Vila Real',
			'São João da Madeira',
			'Santa Cruz',
			'Ronfe',
			'Pedroso',
			'Mosteirô',
			'Merelim',
			'Lustosa',
			'Lobão',
			'Lago',
			'Gafanha',
			'Ferreiros',
			'Cristelo',
			'Campo',
			'Baião',
			'Arcozelo',
			'Amarante',
			'Lajes',
			'Vila',
			'Vila Real de Santo António',
			'Seixal',
			'Quarteira',
			'Ota',
			'Monte Real',
			'Mem Martins',
			'Luz',
			'Estreito da Calheta',
			'Ericeira',
			'Cabeça Gorda',
			'Amiães de Baixo',
			'Sabrosa',
			'Rio Mau',
			'Rendufe',
			'Refojos de Basto',
			'Ponte da Barca',
			'Perafita',
			'Murtosa',
			'Joane',
			'Figueira de Castelo Rodrigo',
			'Figueira da Foz',
			'Caldas de Vizela',
			'Beduido',
			'Aver-o-Mar',
			'Aveiro',
			'Água Longa',
			'Senhora do Rosário',
			'Madalena',
			'Vale de Santarém',
			'Sobralinho',
			'Santana',
			'Ribeira Brava',
			'Porto da Cruz',
			'Monsanto',
			'Fajã da Ovelha',
			'Faial',
			'Estremoz',
			'Costa de Caparica',
			'Caneças',
			'Caldas da Rainha',
			'Batalha',
			'Arco da Calheta',
			'Aljubarrota',
			'Alcanena',
			'Vouzela',
			'Telões',
			'Sendim',
			'São João de Ver',
			'Porto',
			'Paredes',
			'Mortágua',
			'Moreira',
			'Marinhas',
			'Gondomar',
			'Godim',
			'Fiães',
			'Brito',
			'Arcos de Valdevez',
			'Alfândega da Fé',
			'Rosto de Cão',
			'Ribeira Seca',
			'Furnas',
			'Ventosa',
			'Tomar',
			'Redondo',
			'Pragal',
			'Porto Covo',
			'Pontével',
			'Piedade',
			'Montargil',
			'Madalena',
			'Fronteira',
			'Estói',
			'Alvor',
			'Alcoutim',
			'Vila Chã',
			'Terras de Bouro',
			'Coronado',
			'Peso da Régua',
			'Ovar',
			'Gandra',
			'Balazar',
			'Mosteiros',
			'Feteira',
			'Calheta',
			'Vestiaria',
			'Santa Iria da Azóia',
			'Salvaterra de Magos',
			'Sacavém',
			'Rosairinho',
			'Porto de Mós',
			'Poceirão',
			'Pinhal Novo',
			'Penedo Gordo',
			'Pedrógão Grande',
			'Neves',
			'Camarate',
			'Cacém',
			'Beja',
			'Vila Meã',
			'Valongo',
			'Sandim',
			'Poiares',
			'Penela',
			'Paredes de Coura',
			'Murça',
			'Mondim de Basto',
			'Mealhada',
			'Matosinhos',
			'Feira',
			'Condeixa-a-Nova',
			'Ribeirinha',
			'Rabo de Peixe',
			'Porto Martins',
			'Ponta Garça',
			'Fenais da Luz',
			'Biscoitos',
			'Zambujeira do Mar',
			'Vimeiro',
			'Viana do Alentejo',
			'Vale da Amoreira',
			'São Vicente do Paul',
			'São Miguel de Rio Torto',
			'Riachos',
			'Póvoa de Santa Iria',
			'Portimão',
			'Pinheiro Grande',
			'Pego',
			'Paderne',
			'Massamá',
			'Marvão',
			'Marmelete',
			'Maceirinha',
			'Carvoeiro',
			'Campo Maior',
			'Boa Vista',
			'Aljezur',
			'Alferrarede',
			'Alfeizerão',
			'Vilar de Figos',
			'Viana do Castelo',
			'Santo Izidoro',
			'Ribeira de Pena',
			'Pampilhosa',
			'Mira',
			'Ferreira',
			'Espinho',
			'Esmoriz',
			'São Roque do Pico',
			'São Bartolomeu',
			'Ribeira Seca',
			'Quinta Do Conde',
			'Valenza',
			'Alvalade',
			'Sintra',
			'Setúbal',
			'São Vicente',
			'São Pedro da Cadeira',
			'Rio Maior',
			'Porto Moniz',
			'Pombal',
			'Parede',
			'Fontanelas',
			'Ferrel',
			'Constância',
			'Belas',
			'Azambuja',
			'Arranhó',
			'Vila Pouca de Aguiar',
			'Vila Nova de Famalicão',
			'Valadares',
			'Sequeira',
			'Santa Marta de Penaguião',
			'Nogueira',
			'Moreira de Conegos',
			'Macieira de Cambra',
			'Assafarge',
			'Horta',
			'Souto da Carpalhosa',
			'Olhos de Água',
			'Nossa Senhora do Monte',
			'Moita dos Ferreiros',
			'Ferragudo',
			'Cela',
			'Campanário',
			'Cadaval',
			'Borba',
			'Arruda dos Vinhos',
			'Alvito',
			'Alqueidão da Serra',
			'Abrantes',
			'Vila Nova de Gaia',
			'Senhora da Hora',
			'Sabugal',
			'Rio de Loba',
			'Ponte',
			'Perozinho',
			'Paços de Brandão',
			'Guifões',
			'Fundão',
			'Braga',
			'Aguçadoura',
			'Fajã de Baixo',
			'Cabouco',
			'Angra do Heroísmo',
			'Terrugem',
			'Sesimbra',
			'São Jorge',
			'São João da Talha',
			'Porto Salvo',
			'Portalegre',
			'Mourão',
			'Mora',
			'Golegã',
			'Funchal',
			'Ferreiras',
			'Évora',
			'Elvas',
			'Algoz',
			'Vila Seca',
			'Vila Praia de Âncora',
			'Vila Nova de Paiva',
			'Vila Cova',
			'Vale de Cambra',
			'Tábua',
			'Recarei',
			'Oliveirinha',
			'Oiã',
			'Mogadouro',
			'Milheirós de Poiares',
			'Leça do Bailio',
			'Cantanhede',
			'Beiriz de Baixo',
			'Lagoa',
		],
	},
	'Puerto Rico': {
		country: 'Puerto Rico',
		cities: [
			'Aceitunas',
			'Bayamón',
			'La Dolores',
			'Lluveras',
			'Yaurel',
			'Adjuntas',
			'Arecibo',
			'Coto Norte',
			'El Negro',
			'Fajardo',
			'Guayama',
			'Las Ollas',
			'Maricao',
			'Río Grande',
			'San Lorenzo',
			'Villalba',
			'Aguilita',
			'Aibonito',
			'Capitanejo',
			'Coco',
			'Levittown',
			'Loíza',
			'Luyando',
			'Monte Grande',
			'Sabana',
			'San Germán',
			'Tierras Nuevas Poniente',
			'Cayey',
			'Guayanilla',
			'Indios',
			'Rafael Capo',
			'Rafael Hernandez',
			'Rincón',
			'La Alianza',
			'Trujillo Alto',
			'Boquerón',
			'Camuy',
			'Celada',
			'Imbery',
			'La Fermina',
			'Los Llanos',
			'Palo Seco',
			'San Antonio',
			'Aguas Claras',
			'Campo Rico',
			'Daguao',
			'Guaynabo',
			'Pájaros',
			'Quebrada',
			'Sabana Seca',
			'Carolina',
			'Central Aguirre',
			'Luis Llorens Torres',
			'Monserrate',
			'Mora',
			'Salinas',
			'Cayuco',
			'Ceiba',
			'Coquí',
			'Olimpo',
			'Playa Fortuna',
			'Pueblito del Rio',
			'Ramos',
			'San José',
			'Vega Baja',
			'Buena Vista',
			'Cabo Rojo',
			'Duque',
			'Isabel Segunda',
			'Maria Antonia',
			'Naranjito',
			'Palmer',
			'Rosa Sanchez',
			'Animas',
			'Bartolo',
			'Esperanza',
			'Estancias de Florida',
			'Orocovis',
			'Playita',
			'Playita Cortada',
			'Rafael Gonzalez',
			'Yauco',
			'El Ojo',
			'Guánica',
			'Luis M. Cintron',
			'Maunabo',
			'Ponce',
			'San Sebastián',
			'Santa Barbara',
			'Vázquez',
			'Bufalo',
			'Isabela',
			'Jauca',
			'Bajadero',
			'Jagual',
			'Las Marias',
			'Las Marías',
			'Parcelas La Milagrosa',
			'San Juan',
			'Suárez',
			'Yabucoa',
			'Bajandas',
			'Benitez',
			'Carrizales',
			'Cataño',
			'Ciales',
			'Emajagua',
			'Jobos',
			'Pastos',
			'Punta Santiago',
			'Bairoa',
			'Boqueron',
			'Parcelas Nuevas',
			'Potala Pastillo',
			'San Isidro',
			'Santo Domingo',
			'Caban',
			'Comunas',
			'Corazón',
			'Moca',
			'Parcelas Peñuelas',
			'Dorado',
			'El Mangó',
			'Galateo',
			'Ingenio',
			'Las Ochenta',
			'Palomas',
			'Peñuelas',
			'Sabana Eneas',
			'San Antonio',
			'Cacao',
			'Comerío',
			'Guayabal',
			'Mariano Colón',
			'Mayagüez',
			'Palmas',
			'Vega Alta',
			'Aguas Buenas',
			'Añasco',
			'Bayamon',
			'Brenas',
			'Candelero Arriba',
			'Ceiba',
			'Corcovado',
			'Florida',
			'Franquez',
			'Jayuya',
			'Naguabo',
			'Patillas',
			'Puerto Real',
			'Santa Isabel',
			'Aguada',
			'Aguadilla',
			'Antón Ruiz',
			'Cidra',
			'Coto Laurel',
			'Fuig',
			'Liborio Negron Torres',
			'Magas Arriba',
			'Santa Clara',
			'Arroyo',
			'Corozal',
			'G. L. Garcia',
			'Humacao',
			'Juncal',
			'Lamboglia',
			'Manatí',
			'Quebradillas',
			'Sabana Grande',
			'Barahona',
			'Campanilla',
			'Coamo',
			'Espino',
			'Gurabo',
			'Las Piedras',
			'Utuado',
			'Barceloneta',
			'Culebra',
			'Hatillo',
			'Juncos',
			'La Plena',
			'Palmarejo',
			'Piedra Gorda',
			'Río Blanco',
			'Río Cañas Abajo',
			'Río Lajas',
			'Toa Alta',
			'Barranquitas',
			'Caguas',
			'Miranda',
			'Sabana Hoyos',
			'Stella',
			'Garrochales',
			'La Luisa',
			'La Parguera',
			'Mucarabones',
			'Palmarejo',
			'San Antonio',
			'Sumidero',
			'Candelaria',
			'H. Rivera Colon',
			'Hato Candal',
			'Hormigueros',
			'Juana Díaz',
			'Luquillo',
			'Palomas',
			'Pole Ojea',
			'Toa Baja',
			'Candelaria Arenas',
			'Canovanas',
			'La Playa',
			'Lajas',
			'Lares',
			'Martorell',
			'Morovis',
			'Peña Pobre',
			'Tallaboa',
			'Tallaboa Alta',
			'Vieques',
		],
	},
	Qatar: {
		country: 'Qatar',
		cities: [
			'Az̧ Z̧a‘āyin',
			'Ar Ruways',
			'Ash Shīḩānīyah',
			'Umm Şalāl Muḩammad',
			'Madīnat ash Shamāl',
			'Umm Şalāl ‘Alī',
			'Musay‘īd',
			'Dukhān',
			'Al Ghuwayrīyah',
			'Al Wukayr',
			'Doha',
			'Al Wakrah',
			'Al Jumaylīyah',
			'Umm Bāb',
			'Ar Rayyān',
			'Fuwayriţ',
			'Al Khawr',
		],
	},
	Réunion: {
		country: 'Réunion',
		cities: [
			'Saint-Paul',
			'Le Tampon',
			'Salazie',
			'Entre-Deux',
			'Le Port',
			'Bras-Panon',
			'Saint-Louis',
			'Saint-Philippe',
			'Les Avirons',
			'Saint-André',
			'Saint-Pierre',
			'Saint-Leu',
			'Sainte-Marie',
			'Cilaos',
			'Saint-Denis',
			'Saint-Benoît',
			'Piton Saint-Leu',
			'Petite-Île',
			'Sainte-Suzanne',
			'Sainte-Rose',
			'La Possession',
			'Saint-Joseph',
			'Les Trois-Bassins',
		],
	},
	Romania: {
		country: 'Romania',
		cities: [
			'Zamostea',
			'Voluntari',
			'Vlăsineşti',
			'Vlădaia',
			'Vârteju',
			'Vânători-Neamţ',
			'Viişoara',
			'Victoria',
			'Verșești',
			'Verendin',
			'Vătava',
			'Văleni-Dâmbovița',
			'Valea Sării',
			'Valea Părului',
			'Valea Danului',
			'Valea Ciorii',
			'Valea Călugărească',
			'Vale',
			'Ulmu',
			'Ulmeni',
			'Tuzla',
			'Turţ',
			'Tomşani',
			'Titești',
			'Ţânţăreni',
			'Tansa',
			'Talpa-Trivalea',
			'Tălmaciu',
			'Suseni Socetu',
			'Şugag',
			'Sudiţi',
			'Stroiești',
			'Strejeşti',
			'Stânceni',
			'Socond',
			'Smulţi',
			'Şiria',
			'Sineşti',
			'Siliștea Snagovului',
			'Şieu-Odorhei',
			'Şelimbăr',
			'Săuca',
			'Sat-Șugatag',
			'Satu Nou',
			'Săliştea de Sus',
			'Săliştea-Deal',
			'Rusăneşti',
			'Roşiori',
			'Reviga',
			'Răuceşti',
			'Ramna',
			'Racu',
			'Racoviţa',
			'Puțuri',
			'Prajila',
			'Praid',
			'Poşaga de Sus',
			'Poplaca',
			'Ponor',
			'Pojogeni',
			'Poiana Crăcăoani',
			'Poiana',
			'Poienarii Apostoli',
			'Plăvălari',
			'Pietroşiţa',
			'Peștiș',
			'Peșteana de Jos',
			'Parva',
			'Pantelimon',
			'Palanca',
			'Pădureni',
			'Orbeasca de Jos',
			'Olteneşti',
			'Ograda',
			'Nimigea de Sus',
			'Năsturelu',
			'Movileni',
			'Moisei',
			'Mirşid',
			'Mihalţ',
			'Mihălăşeni',
			'Merenii de Sus',
			'Mehadia',
			'Mărunțișu',
			'Marca',
			'Măneşti',
			'Mahmudia',
			'Măgeşti',
			'Lemnia',
			'Leicești',
			'Jurilovca',
			'Igriș',
			'Iana',
			'Hodoșa',
			'Hodora',
			'Hoceni',
			'Hălchiu',
			'Gurghiu',
			'Gulia',
			'Grindu',
			'Grăniceşti',
			'Gighera',
			'Gherteniș',
			'Ghelinţa',
			'Gemeni',
			'Găvănești',
			'Garoafa',
			'Galicea Mare',
			'Găiceana',
			'Fundu Răcăciuni',
			'Fântânele',
			'Făget',
			'Drăgăești Ungureni',
			'Dobroteasa',
			'Dealu Frumos',
			'Dealu',
			'Cujmir',
			'Croitori',
			'Cristeşti',
			'Crişan',
			'Covasna',
			'Costeştii din Vale',
			'Costeşti',
			'Cornuțel',
			'Cornățel',
			'Comani',
			'Cochirleanca',
			'Coaș',
			'Ciucurova',
			'Câţcău',
			'Cârligi',
			'Cireşu',
			'Ciofliceni',
			'Cândeşti Vale',
			'Chinteni',
			'Căscioarele',
			'Căpreni',
			'Butimanu',
			'Bustuchin',
			'Bucșenești',
			'Bucoşniţa',
			'Buceş',
			'Bratca',
			'Braniștea',
			'Bragadiru',
			'Brădeanu',
			'Bozovici',
			'Bălcaciu',
			'Bixad',
			'Bârnova',
			'Beleţi',
			'Bănia',
			'Balcani',
			'Balaci',
			'Bălăceana',
			'Baia',
			'Bădeuți',
			'Baciu',
			'Bacea',
			'Băcani',
			'Arbore',
			'Albeştii Pământeni',
			'Toaca',
			'Zărand',
			'Vurpăr',
			'Voitinel',
			'Vlădeşti',
			'Vlădeni-Deal',
			'Vităneşti',
			'Vârlezi',
			'Verșeni',
			'Valea Mare',
			'Utvin',
			'Urziceanca',
			'Urleasca',
			'Ungureni',
			'Ungheni',
			'Traian',
			'Titu',
			'Târgu Secuiesc',
			'Tinosu',
			'Ţăndărei',
			'Șurdești',
			'Sulița',
			'Suhuleț',
			'Suceviţa',
			'Ştefan cel Mare',
			'Stăniţa',
			'Someş-Odorhei',
			'Slobozia Bradului',
			'Slobozia',
			'Şişeşti',
			'Sălcuța',
			'Salcia Tudor',
			'Roșiorii de Vede',
			'Roşieşti',
			'Români',
			'Rogoz',
			'Rogova',
			'Roeşti',
			'Remeţi',
			'Rași',
			'Rapoltu Mare',
			'Rafaila',
			'Rădești',
			'Răchitova',
			'Purani',
			'Puieni',
			'Probota',
			'Prapor',
			'Potlogi',
			'Popeşti',
			'Poiana Lacului',
			'Poieni',
			'Poieni',
			'Podgoria',
			'Ploștina',
			'Plopana',
			'Pârscov',
			'Pilu',
			'Pietroşani',
			'Pietroasa',
			'Pietreni',
			'Piatra Şoimului',
			'Peșteana Jiu',
			'Pătrăuţi',
			'Păsăreni',
			'Pânceşti',
			'Otopeni',
			'Oroftiana',
			'Olteniţa',
			'Oinacu',
			'Ocoliş',
			'Nistoreşti',
			'Nireș',
			'Niculești',
			'Nadab',
			'Moscu',
			'Monor',
			'Mogoşani',
			'Moara Grecilor',
			'Mizil',
			'Mândra',
			'Micăsasa',
			'Meseşenii de Jos',
			'Meri',
			'Mățău',
			'Marin',
			'Măluşteni',
			'Lunca de Jos',
			'Libotin',
			'Leorda',
			'Ileana',
			'Hunia',
			'Horodnic de Jos',
			'Gura Şuţii',
			'Greceanca',
			'Grădiştea',
			'Gârbou',
			'Ghioroiu',
			'Ghergheasa',
			'Găneşti',
			'Fizeș',
			'Fărău',
			'Ezeriş',
			'Erbiceni',
			'Dumitrița',
			'Dumbrăviţa',
			'Drobeta-Turnu Severin',
			'Drajna de Jos',
			'Dorolţ',
			'Cumpăna',
			'Cucueți',
			'Crângurile de Sus',
			'Crăciunelu de Jos',
			'Cotu Vameș',
			'Coţuşca',
			'Cotumba',
			'Cotnari',
			'Coșula',
			'Costeşti',
			'Coșna',
			'Corund',
			'Coroisânmartin',
			'Corlăţel',
			'Copăceni',
			'Conțești',
			'Ciuruleasa',
			'Ciuani',
			'Cârja',
			'Cârcea',
			'Ciclova-Română',
			'Checea',
			'Cernăteşti',
			'Ceamurlia de Jos',
			'Cătina',
			'Căpleni',
			'Căpâlna',
			'Căpeni',
			'Cadea',
			'Budeasa Mică',
			'Brâncoveni',
			'Brebu Nou',
			'Brăduleţ',
			'Brădeni',
			'Bogdana',
			'Bârsana',
			'Bârlogu',
			'Bâlvăneşti',
			'Biliești',
			'Biia',
			'Bichiş',
			'Berteştii de Jos',
			'Belin-Vale',
			'Başcov',
			'Barza',
			'Bălteni',
			'Bălan',
			'Băișești',
			'Baia de Fier',
			'Băceşti',
			'Arsura',
			'Ardusat',
			'Aluniş',
			'Albeşti',
			'Zetea',
			'Vulcăneasa',
			'Vrănești',
			'Voinești',
			'Vlăsceni',
			'Vâlcele',
			'Vâlcăneşti',
			'Viile Satu Mare',
			'Vicovu de Jos',
			'Valea Vinului',
			'Valea Stanciului',
			'Valea Mare',
			'Urziceni',
			'Unirea',
			'Unirea',
			'Ungureni',
			'Ulma',
			'Trivalea-Moşteni',
			'Tiur',
			'Tismana',
			'Târgu Neamţ',
			'Telega',
			'Tătaru',
			'Stoeneşti',
			'Stoenești',
			'Stânca',
			'Spiru Haret',
			'Şovarna',
			'Sodomeni',
			'Smeeni',
			'Șimișna',
			'Sihlea',
			'Secusigiu',
			'Scorniceşti',
			'Scheia',
			'Satu Nou',
			'Săpânţa',
			'Săcuieu',
			'Săcele',
			'Rotunda',
			'Roşia de Secaş',
			'Roman',
			'Râmești',
			'Răzvad',
			'Răteşti',
			'Răscăeți',
			'Radu Vodă',
			'Racovița',
			'Proviţa de Sus',
			'Pristol',
			'Poroschia',
			'Poiana Mărului',
			'Poboru',
			'Plopșoru',
			'Pietroasa',
			'Pietrarii de Sus',
			'Piatra',
			'Pianu de Jos',
			'Peştişu Mic',
			'Parava',
			'Padina Mică',
			'Padea',
			'Oveselu',
			'Olari',
			'Nucşoara',
			'Nicolae Titulescu',
			'Nicolae Bălcescu',
			'Moroeni',
			'Mirceștii Noi',
			'Mândrești',
			'Mihoveni',
			'Miheşu de Câmpie',
			'Mihai Viteazu',
			'Mărtineşti',
			'Lunca',
			'Lunca',
			'Livezi',
			'Livada de Bihor',
			'Lăpuş',
			'Jorăști',
			'Ipotești',
			'Iacobeni',
			'Iablaniţa',
			'Husnicioara',
			'Humoreni',
			'Hudeşti',
			'Hârşova',
			'Hinova',
			'Hălmagiu',
			'Gurbăneşti',
			'Gura Vulcanei',
			'Gura Teghii',
			'Gugeşti',
			'Greoni',
			'Gratia',
			'Grădiştea',
			'Golești',
			'Gohor',
			'Gogoşu',
			'Ghirdoveni',
			'Ghiorac',
			'Ghergheşti',
			'Gavojdia',
			'Găiseni',
			'Furcenii Noi',
			'Iepureşti',
			'Dumbrăveni',
			'Drăgoteşti',
			'Dragomireşti',
			'Dragodănești',
			'Drăgăneşti',
			'Dorobanți',
			'Domnița',
			'Dolhasca',
			'Dărmăneşti',
			'Cubulcut',
			'Covrigi',
			'Costâna',
			'Coşoveni',
			'Cornu Luncii',
			'Corneşti',
			'Corlăteni',
			'Condeești',
			'Comana de Sus',
			'Ciumani',
			'Ciorăşti',
			'Ciocârlia de Sus',
			'Ciocănești',
			'Ciceu-Giurgeşti',
			'Chirnogi',
			'Cernat',
			'Ceanu Mare',
			'Căstău',
			'Carani',
			'Cărand',
			'Calvini',
			'Călăraşi',
			'Căiuți',
			'Budureasa',
			'Budești',
			'Bucova',
			'Buciumi',
			'Bucești',
			'Brezoaele',
			'Breb',
			'Bran',
			'Braloştiţa',
			'Boţeşti',
			'Borosoaia',
			'Borduşani',
			'Bogdand',
			'Biled',
			'Bătrâni',
			'Băseşti',
			'Bărcăneşti',
			'Băneşti',
			'Babța',
			'Avrămeşti',
			'Apahida',
			'Alexandru Vlăhuţă',
			'Slobozia',
			'Făgetu',
			'Ion Creangă',
			'Zau de Câmpie',
			'Vulturești',
			'Vânjuleţ',
			'Vânători',
			'Vâlcelele',
			'Veţca',
			'Valea Seacă',
			'Valea Rece',
			'Valea Izvoarelor',
			'Urleta',
			'Turnu Roşu',
			'Tudor Vladimirescu',
			'Topraisar',
			'Tântava',
			'Tichileşti',
			'Tăut',
			'Tătărăni',
			'Taşca',
			'Tanacu',
			'Tămădău Mare',
			'Tălmăcel',
			'Sviniţa',
			'Ştefan cel Mare',
			'Şoimari',
			'Slobozia-Mândra',
			'Șipotu',
			'Şintereag',
			'Sânpetru Mare',
			'Ștefan Vodă',
			'Scobinţi',
			'Șanț',
			'Sănduleni',
			'Sadu',
			'Rânzești',
			'Reghiu',
			'Rediu',
			'Rediu',
			'Rădăuți',
			'Racoviţa',
			'Răchita',
			'Răcarii de Sus',
			'Pui',
			'Pufeşti',
			'Porumbenii Mari',
			'Popricani',
			'Poduri',
			'Podoleni',
			'Plugari',
			'Ploscuțeni',
			'Piatra Olt',
			'Petrăchioaia',
			'Peștișu Mare',
			'Oituz',
			'Ocna Dejului',
			'Obârşia-Cloşani',
			'Nou',
			'Noşlac',
			'Negreni',
			'Negoiești',
			'Naipu',
			'Movila',
			'Moldoviţa',
			'Moftinu Mic',
			'Moara Domnească',
			'Mârşa',
			'Călăraşi',
			'Mănăstirea',
			'Milişăuţi',
			'Mihăileşti',
			'Mehadica',
			'Medieşu Aurit',
			'Maglavit',
			'Măgherani',
			'Lunca Corbului',
			'Lunca',
			'Luminiș',
			'Letea Veche',
			'Lehliu',
			'Jupânești',
			'Jariştea',
			'Jabenița',
			'Izvoare',
			'Iscroni',
			'Imper',
			'Iloviţa',
			'Iclod',
			'Ianoșda',
			'Ianca',
			'Herla',
			'Helegiu',
			'Hangu',
			'Gurba',
			'Gura Padinii',
			'Groși',
			'Greci',
			'Goştinari-Văcăreşti',
			'Giulvăz',
			'Girișu de Criș',
			'Gârcov',
			'Gâlgău',
			'Ghidigeni',
			'Onesti',
			'Găgeşti',
			'Găgești',
			'Fulga de Sus',
			'Dumbrava',
			'Dragomireşti',
			'Doştat',
			'Doftana',
			'Dobrosloveni',
			'Dobroeşti',
			'Dobârceni',
			'Denta',
			'Deleni',
			'Dărăşti-Ilfov',
			'Creţeni',
			'Creaca',
			'Cozma',
			'Cornești',
			'Corbu',
			'Cobadin',
			'Cloșani',
			'Ciugheș',
			'Ciucsângeorgiu',
			'Cicănești',
			'Ciocăneşti',
			'Ciceu-Mihăiești',
			'Chițorani',
			'Chirpăr',
			'Chiliile',
			'Chiheru de Jos',
			'Cherechiu',
			'Didești',
			'Cepleniţa',
			'Cavadineşti',
			'Căuaş',
			'Cătina',
			'Catane',
			'Castrele Traiane',
			'C.a. Rosetti',
			'Călugăreni',
			'Căiuţi-Sat',
			'Budăi',
			'Breaza de Jos',
			'Brăeşti',
			'Boldu',
			'Boiu',
			'Boişoara',
			'Boinești',
			'Bogata',
			'Berezeni',
			'Beltiug',
			'Beiuş',
			'Batoş',
			'Bărăști',
			'Bălcești',
			'Bălăuşeri',
			'Băileşti',
			'Băicoi',
			'Baia de Criş',
			'Apostolache',
			'Anina',
			'Agigea',
			'Adea',
			'Adamclisi',
			'1 Decembrie',
			'Sector 6',
			'Zlătunoaia',
			'Zagavia',
			'Vorona Teodoru',
			'Viişoara',
			'Vierșani',
			'Vidra',
			'Veţel',
			'Verguleasa',
			'Vatra Moldoviţei',
			'Valea Măcrișului',
			'Văgiuleşti',
			'Unirea',
			'Uda-Paciurea',
			'Turia',
			'Tulcea',
			'Topolovăţu Mare',
			'Topana',
			'Tomeşti',
			'Tisa',
			'Țintea',
			'Timişoara',
			'Şuşani',
			'Stroești',
			'Strâmtura',
			'Sohodol',
			'Slobozia-Ciorăşti',
			'Slobozia',
			'Slimnic',
			'Sândominic',
			'Serdanu',
			'Scurtești',
			'Satu Mare',
			'Şandra',
			'Sagna',
			'Săcălășeni',
			'Romuli',
			'Românaşi',
			'Râu Alb de Jos',
			'Râşnov',
			'Râșești',
			'Rediu',
			'Răsuceni',
			'Răcăciuni',
			'Poiana Țapului',
			'Poiana Stampei',
			'Poiana Sibiului',
			'Poiana Câmpina',
			'Poian',
			'Pişchia',
			'Pârşcoveni',
			'Pietroşani',
			'Picior de Munte',
			'Piatra Neamţ',
			'Perișoru',
			'Păușești',
			'Păuleşti',
			'Odaia Manolache',
			'Nereju Mic',
			'Nădrag',
			'Moviliţa',
			'Moșneni',
			'Moşna',
			'Mitrofani',
			'Minieri',
			'Milcoiu',
			'Mihăileni',
			'Mavrodin',
			'Mărășești',
			'Manoleasa',
			'Malu Roșu',
			'Mădârjac',
			'Lunca Jariștei',
			'Lunca Bradului',
			'Lunca',
			'Lunca',
			'Livezi',
			'Lipovăţ',
			'Lilieci',
			'Letca Veche',
			'Lespezi',
			'Lăcusteni',
			'Izvoarele',
			'Iugani',
			'Ip',
			'Ibrianu',
			'Iacobeni',
			'Heci',
			'Gura Văii',
			'Gura Suhașului',
			'Grinţieş',
			'Goiești',
			'Ghindăoani',
			'Gherghiţa',
			'Ghelari',
			'Finta Mare',
			'Filia',
			'Făclia',
			'Dumbrăviţa',
			'Dumbrăvița',
			'Drajna de Sus',
			'Drăgăneşti',
			'Dolheștii-Mari',
			'Dobric',
			'Dâmbroca',
			'Diculești',
			'Dealu Mare',
			'Cuza Vodă',
			'Crușovu',
			'Crucea',
			'Cristeşti',
			'Ciupercenii Noi',
			'Ciumeghiu',
			'Cilibia',
			'Chichiş',
			'Cetăţeni',
			'Cernişoara',
			'Cerbăl',
			'Ceahlău',
			'Ceacu',
			'Cătunele',
			'Catanele',
			'Căpâlniţa',
			'Călugăreni',
			'Călineşti-Oaş',
			'Călineşti',
			'Călacea',
			'Cacica',
			'Broșteni',
			'Bretea Română',
			'Braniștea',
			'Brăhăşeştii de Sus',
			'Botești',
			'Boldeşti-Scăeni',
			'Bolătău',
			'Bogaţi',
			'Bodeștii de Jos',
			'Bobohalma',
			'Bâscoveni',
			'Bâra',
			'Berzasca',
			'Berghin',
			'Bătești',
			'Bălăciţa',
			'Baia',
			'Bahna',
			'Băduleasa',
			'Bădești',
			'Băbiciu',
			'Aşchileu Dorna',
			'Arpaşu de Jos',
			'Andrieşeni',
			'Alma',
			'Alexandru I. Cuza',
			'Aghireș',
			'Acriș',
			'Bucuresti',
			'Sighișoara',
			'Sector 5',
			'Zărnești',
			'Zagon',
			'Vlădeşti',
			'Vedea',
			'Valea lui Mihai',
			'Valea Leurzii',
			'Valea Bolvașnița',
			'Vadu Sorești',
			'Văcăreni',
			'Unguriu',
			'Ungureni',
			'Uda',
			'Turnu Măgurele',
			'Topliţa',
			'Todireşti',
			'Tecuci',
			'Şuici',
			'Stulpicani',
			'Strâmtura',
			'Strehaia',
			'Speriețeni',
			'Solovăstru',
			'Socol',
			'Slon',
			'Slivileşti',
			'Sârbi',
			'Sâncrăieni',
			'Sâmbureşti',
			'Sibiu',
			'Sfântu Gheorghe',
			'Sfântu Gheorghe',
			'Scundu',
			'Schitu',
			'Şcheia',
			'Sauca',
			'Satu Nou Calopăr',
			'Sascut',
			'Salcia',
			'Săcel',
			'Roseţi',
			'Râșca',
			'Râncăciov',
			'Râmnicu Vâlcea',
			'Porumbești',
			'Pocola',
			'Pleniţa',
			'Petelea',
			'Pănet',
			'Olteanca',
			'Olari',
			'Ogra',
			'Odorheiu Secuiesc',
			'Nocrich',
			'Nazna',
			'Năruja',
			'Nămoloasa',
			'Moișeni',
			'Moara Nica',
			'Mitocu Dragomirnei',
			'Mironu',
			'Mirceşti',
			'Micleşti',
			'Maxenu',
			'Mamaia-Sat',
			'Malu cu Flori',
			'Lunca Mureşului',
			'Loloiasca',
			'Lisa',
			'Lăceni',
			'Ioneşti',
			'Ionășeni',
			'Ileanda',
			'Iecea Mică',
			'Iazu',
			'Hotar',
			'Holboca',
			'Hanu Conachi',
			'Gura Beliei',
			'Grumăzeşti',
			'Griviţa',
			'Grădinari',
			'Gornet',
			'Goleşti',
			'Glodeni',
			'Ghindari',
			'Gheaba',
			'Gepiu',
			'Găneasa',
			'Floreşti',
			'Fitioneşti',
			'Fârdea',
			'Fântânele',
			'Filipeşti',
			'Fărcaşa',
			'Fălticeni',
			'Epureni',
			'Eforie Nord',
			'Dragu',
			'Drăgăneasa',
			'Dracea',
			'Dorna Cândrenilor',
			'Domneşti',
			'Dolhești',
			'Demacușa',
			'Dealu Dănicei',
			'Dăeşti',
			'Cuca',
			'Crovu',
			'Craiva',
			'Coropceni',
			'Corbu',
			'Copăcel',
			'Conop',
			'Comăneşti',
			'Coarnele Caprei',
			'Cârţa',
			'Câlnic',
			'Chibed',
			'Cheșereu',
			'Cervenia',
			'Cerăt',
			'Cehu Silvaniei',
			'Casimcea',
			'Caraclău',
			'Căianu',
			'Bușca',
			'Bulz',
			'Buhoci',
			'Buda',
			'Bucov',
			'Broșteni',
			'Brebu',
			'Brădicești',
			'Bordei Verde',
			'Bolvașnița',
			'Bod',
			'Blăjeni',
			'Bistra',
			'Bârsa',
			'Birchiş',
			'Bezdead',
			'Becicherecu Mic',
			'Bătrâna',
			'Bărcăneşti',
			'Bălțești',
			'Bădeana',
			'Balta Verde',
			'Bălceşti',
			'Armeniş',
			'Ardeoani',
			'Arcuș',
			'Arad',
			'Agriș',
			'Constantin Brâncoveanu',
			'Hulubești',
			'Vulturu',
			'Viscri',
			'Vâlcelele',
			'Viişoara',
			'Viișoara',
			'Vidra',
			'Vărşag',
			'Valea Popii',
			'Urși',
			'Ursa',
			'Urdari',
			'Tuta',
			'Tritenii de Jos',
			'Târgu Ocna',
			'Ticuşu Vechi',
			'Terebeşti',
			'Telcișor',
			'Tăşnad',
			'Stârciu',
			'Stâncești',
			'Ştefeşti',
			'Stăvaru',
			'Stănileşti',
			'Stăncești',
			'Slatina-Timiş',
			'Sânger',
			'Şinca Veche',
			'Şercaia',
			'Șerbăuți',
			'Şerbăneşti',
			'Schitu',
			'Satu Nou',
			'Sătmărel',
			'Săteni',
			'Sălcioara',
			'Sasca Montană',
			'Sălard',
			'Ruginoasa',
			'Retevoiești',
			'Rădești',
			'Raciu',
			'Gârlenii de Sus',
			'Prigor',
			'Popoveni',
			'Poiana Mărului',
			'Podari',
			'Plutonița',
			'Plosca',
			'Plopu',
			'Pârâu',
			'Pâncota',
			'Pânceşti',
			'Pesceana',
			'Păltinoasa',
			'Palazu Mare',
			'Osoi',
			'Odoreu',
			'Odăile',
			'Negoești',
			'Neagra Șarului',
			'Nanov',
			'Murfatlar',
			'Mogoș',
			'Mihălceni',
			'Mihai Viteazu',
			'Micfalău',
			'Miceşti',
			'Mica',
			'Mereşti',
			'Maxut',
			'Mărginenii de Sus',
			'Măldăreşti',
			'Măceşu de Sus',
			'Luduş',
			'Leu',
			'Largu',
			'Lăpuşnicel',
			'Jilavele',
			'Jiana',
			'Izvoarele',
			'Izvoarele',
			'Iordăcheanu',
			'Horia',
			'Gulia',
			'Grozeşti',
			'Grojdibodu',
			'Greaca',
			'Grăniceri',
			'Grămeşti',
			'Gorbăneşti',
			'Glina',
			'Glimboca',
			'Girov',
			'Gârceni',
			'Ghiroda',
			'Ghioroc',
			'Gălăneşti',
			'Frumoasa',
			'Fratoștița',
			'Ferești',
			'Fărcașu de Jos',
			'Fărcaș',
			'Făcăi',
			'Cârna',
			'Dumeşti',
			'Băile Drânceni',
			'Drănic',
			'Drăguşeni',
			'Drăgușeni',
			'Drăguşeni',
			'Domaşnea',
			'Dolheștii Mici',
			'Ștei',
			'Dobroteşti',
			'Desa',
			'Decebal',
			'Daia',
			'Cuza Vodă',
			'Curtuișeni',
			'Cucuteni',
			'Crucea',
			'Crişeni',
			'Crevedia',
			'Crăciuneşti',
			'Coșani',
			'Corneşti',
			'Cornăţelu',
			'Copăceni',
			'Copăceni',
			'Conduratu',
			'Comănești',
			'Colelia',
			'Colceag',
			'Ciugud',
			'Câmpia Turzii',
			'Cerdac',
			'Ceptura de Sus',
			'Ceauru',
			'Carpen',
			'Caporal Alexa',
			'Căciulați',
			'Bunila',
			'Bumbeşti-Jiu',
			'Bujoru',
			'Bucovineni',
			'Brusturoasa',
			'Boţeşti',
			'Borăscu',
			'Bogata',
			'Bodoc',
			'Blândeşti',
			'Bistriţa',
			'Bârna',
			'Bâcu',
			'Berveni',
			'Batăr',
			'Basarabi',
			'Balşa',
			'Băgaciu',
			'Avrig',
			'Aţel',
			'Ariceştii-Rahtivani',
			'Alimpeşti',
			'Albeşti',
			'Albeşti',
			'Agrij',
			'Agapia',
			'Adâncata',
			'Abrămuţ',
			'Mioveni',
			'Izvoarele',
			'Călmățuiu de Sus',
			'Sector 3',
			'Zorleni',
			'Vutcani',
			'Vlădila',
			'Vârteşcoiu',
			'Victoria',
			'Valea Roșie',
			'Valea Arini',
			'Urlaţi',
			'Uricani',
			'Ulmi',
			'Turnu',
			'Tupilaţi',
			'Tritenii de Sus',
			'Tormac',
			'Târnava',
			'Ţibucani',
			'Teasc',
			'Tazlău',
			'Tariverde',
			'Tarcea',
			'Tămăşeni',
			'Surdulești',
			'Suatu',
			'Storobăneasa',
			'Ştiubieni',
			'Ştefăneşti',
			'Stăneşti',
			'Slăveni',
			'Slatina',
			'Sântimbru',
			'Sintești',
			'Sântana de Mureş',
			'Sânmărtin',
			'Sâncraiu',
			'Siliștea Gumești',
			'Scorţaru Nou',
			'Scărişoara',
			'Sărata',
			'Sălacea',
			'Rusca',
			'Runcu',
			'Rubla',
			'Rozavlea',
			'Ripiceni',
			'Redea',
			'Războieni-Cetate',
			'Rădeni',
			'Racşa',
			'Răcari',
			'Proviţa de Jos',
			'Predeşti',
			'Popeşti-Leordeni',
			'Popeşti',
			'Pomârla',
			'Pojorâta',
			'Poieneşti',
			'Podu Iloaiei',
			'Plugova',
			'Piscu Vechi',
			'Piatra',
			'Petriş',
			'Petreştii de Jos',
			'Periş',
			'Părhăuți',
			'Pantelimon de Jos',
			'Panaci',
			'Ostrovu',
			'Oglinzi',
			'Oancea',
			'Negomir',
			'Nedeia',
			'Nămoloasa-Sat',
			'Muşeniţa',
			'Mișca',
			'Mineri',
			'Mănăstirea Humorului',
			'Mileanca',
			'Melineşti',
			'Măceşu de Jos',
			'Lunca de Sus',
			'Livada',
			'Lanurile',
			'Lămășeni',
			'Jidvei',
			'Izvoru Dulce',
			'Izvoarele',
			'Izvoare',
			'Întorsura',
			'Ibănești',
			'Huruiești',
			'Humulești',
			'Homorod',
			'Hociungi',
			'Hereclean',
			'Gura Foii',
			'Gura Căluiu',
			'Groșii Țibleșului',
			'Griviţa',
			'Greci',
			'Ghizdăvești',
			'Gălbinaşi',
			'Galaţii Bistriţei',
			'Fișer',
			'Filipeştii de Pădure',
			'Filioara',
			'Fierbinți',
			'Fieni',
			'Faraoaní',
			'Dumbrava de Sus',
			'Drânceni',
			'Drăgeşti',
			'Dobrotu',
			'Dobreni',
			'Dârza',
			'Dângeni',
			'Derna',
			'Daia Română',
			'Cornetu',
			'Cordău',
			'Copălău',
			'Colţi',
			'Colonia Bod',
			'Cluj-Napoca',
			'Clit',
			'Ciumești',
			'Ciucea',
			'Cireșoaia',
			'Ciorăști',
			'Câlnic',
			'Chevereşu Mare',
			'Cerţeşti',
			'Ceica',
			'Ceuașu de Câmpie',
			'Cazaci',
			'Catalina',
			'Caşin',
			'Căldăraru',
			'Călăraşi',
			'Buhuşi',
			'Bughea de Sus',
			'Bucşani',
			'Bucşani',
			'Broşteni',
			'Brăeşti',
			'Brabova',
			'Bogdăneşti',
			'Blideşti',
			'Blaj',
			'Bistra',
			'Bâsca Rozilei',
			'Bâlta',
			'Belciugatele',
			'Beclean',
			'Băuţar',
			'Barcani',
			'Băniţa',
			'Băneasa',
			'Baia Mare',
			'Avram Iancu',
			'Aţintiş',
			'Bujoreni',
			'Apold',
			'Albeşti',
			'Agrișu Mare',
			'Tarna Mare',
			'Zencani',
			'Zăvoaia',
			'Vurpăr',
			'Vorţa',
			'Vârghiş',
			'Viişoara',
			'Viișoara',
			'Veza',
			'Verbiţa',
			'Umbrărești-Deal',
			'Vama',
			'Vălenii de Munte',
			'Valea Strâmbă',
			'Valea lui Ion',
			'Vadu Paşii',
			'Vadu Părului',
			'Ungheni',
			'Tudor Vladimirescu',
			'Tigveni',
			'Ţigăneşti',
			'Teliu',
			'Suseni',
			'Suharău',
			'Strunga',
			'Stolnici',
			'Stâlpu',
			'Stejaru',
			'Stejaru',
			'Spermezeu',
			'Şotrile',
			'Şopotu Nou',
			'Solonţ',
			'Sohatu',
			'Smârdan',
			'Slobozia-Câmpineanca',
			'Slobozia',
			'Şirna',
			'Sânnicolau Român',
			'Sinești',
			'Simoneşti',
			'Siliştea',
			'Şeica Mică',
			'Şeíca Mare',
			'Seaca',
			'Satu Mare',
			'Săsciori',
			'Sarichioi',
			'Sărăuad',
			'Saligny',
			'Șaeș',
			'Săcălaz',
			'Săcădat',
			'Ruşeţu',
			'Râmnicelu',
			'Rebricea',
			'Răsmireşti',
			'Racoviţa',
			'Pucheni',
			'Prigoria',
			'Pomezeu',
			'Poienarii Burchii',
			'Pianu de Sus',
			'Pietriceaua',
			'Perișor',
			'Otetelișu',
			'Orţişoara',
			'Ormeniş',
			'Orăştioara de Sus',
			'Olteni',
			'Ocna Sibiului',
			'Oar',
			'Negreni',
			'Negoi',
			'Naidăș',
			'Murgași',
			'Moţca',
			'Morunglav',
			'Moacşa',
			'Mândruloc',
			'Magula',
			'Măerişte',
			'Lupeni',
			'Lungani',
			'Lunga',
			'Lipova',
			'Jiblea Veche',
			'Izvoru Berheciului',
			'Intregalde',
			'Igoiu',
			'Herăști',
			'Hărțăgani',
			'Gura Putnei',
			'Greceşti',
			'Grebenişu de Câmpie',
			'Gârbovi',
			'Ghimpați',
			'Gioseni',
			'Gelu',
			'Găujani',
			'Floroaia',
			'Flămânzi',
			'Fântânele',
			'Filipeştii de Târg',
			'Făurei',
			'Eforie Sud',
			'Drăgoiești',
			'Dragalina',
			'Dodești',
			'Dârvari',
			'Deleni',
			'Curtişoara',
			'Crucea',
			'Cristolţ',
			'Crăiești',
			'Coşteiu',
			'Coşereni',
			'Cornu de Jos',
			'Cormaia',
			'Comarnic',
			'Colibaşi',
			'Cocorăștii Colț',
			'Ciurea',
			'Chiraftei',
			'Chendrea',
			'Cernica',
			'Cernele',
			'Caraula',
			'Căianu Mic',
			'Burzuc',
			'Buneşti',
			'Bulzeşti',
			'Bucșenești-Lotași',
			'Bucium',
			'Breţcu',
			'Breaza',
			'Brabeți',
			'Boureni',
			'Bodeşti',
			'Bistra Mureșului',
			'Bârghiş',
			'Bârca',
			'Bilca',
			'Beceni',
			'Bănești',
			'Baloteşti',
			'Bălăşeşti',
			'Bălănești',
			'Arieşeni',
			'Arefu',
			'Amărăşti',
			'Alțâna',
			'Aiton',
			'Adjudu Vechi',
			'Acâş',
			'Vințu de Jos',
			'Zmeu',
			'Zerind',
			'Zănoaga',
			'Zăneşti',
			'Volovăţ',
			'Vlad Ţepeş',
			'Vizantea-Mânăstirească',
			'Vişeu de Sus',
			'Vârfuri',
			'Vânătorii Mari',
			'Videle',
			'Vereşti',
			'Valea Ursului',
			'Valea Moldovei',
			'Valea Lupului',
			'Valea Dacilor',
			'Vadu Crişului',
			'Unirea',
			'Uda-Clocociov',
			'Turcoaia',
			'Traian',
			'Tomeşti',
			'Tocileni',
			'Târşolţ',
			'Teiu',
			'Teişani',
			'Tecuci',
			'Talea',
			'Ţaga',
			'Şuţeşti',
			'Şura Mare',
			'Șupitca',
			'Sulina',
			'Străoane',
			'Straja',
			'Stelnica',
			'Stejaru',
			'Ştefăneştii de Jos',
			'Soloneț',
			'Slava Cercheză',
			'Sânpetru German',
			'Şimnicu de Sus',
			'Şimand',
			'Scoarţa',
			'Şaru Dornei',
			'Sarmizegetusa',
			'Sărățeni',
			'Săliștea',
			'Săcelu',
			'Roata de Jos',
			'Răchiteni',
			'Purcăreni',
			'Prundeni',
			'Priponeşti',
			'Prăjani',
			'Potlogeni',
			'Poiana Blenchii',
			'Pogonești',
			'Plopiş',
			'Plopeni',
			'Pleașov',
			'Piteasca',
			'Pitaru',
			'Pădureni',
			'Padeş',
			'Ostroveni',
			'Osica de Sus',
			'Orlea',
			'Ocnița',
			'Obârşia',
			'Nuci',
			'Moţăţei',
			'Moraviţa',
			'Misentea',
			'Mârşani',
			'Mihail Kogălniceanu',
			'Merișani',
			'Măzănăești',
			'Matca',
			'Mărgău',
			'Măgura Ilvei',
			'Măgura',
			'Lunguleţu',
			'Lueta',
			'Livezeni',
			'Lisa',
			'Lăpușel',
			'Joseni',
			'Izvoru de Sus',
			'Isaccea',
			'Independenţa',
			'Iezeru',
			'Ibănești-Pădure',
			'Iazurile',
			'Iadăra',
			'Horodniceni',
			'Horia',
			'Horgeşti',
			'Homorâciu',
			'Hârseşti',
			'Havârna',
			'Gorban',
			'Ghimpați',
			'Frunzi',
			'Frecăţei',
			'Filipeni',
			'Eremitu',
			'Dumbrava Roşie',
			'Dumbrava',
			'Drajna Nouă',
			'Drăgăneşti-Vlaşca',
			'Domneşti-Sârbi',
			'Doljeşti',
			'Dobrun',
			'Dobreşti',
			'Dobra',
			'Dârjiu',
			'Dâmbău',
			'Dărăști-Vlașca',
			'Cuza Vodă',
			'Cuza Vodă',
			'Curăţele',
			'Cuciulata',
			'Cristuru Secuiesc',
			'Cricău',
			'Costuleni',
			'Costești',
			'Corbeanca',
			'Comanda',
			'Cogeasca',
			'Ciulnița',
			'Cârniceni',
			'Ciocârlia',
			'Certeze',
			'Cenei',
			'Caracal',
			'Căpățânenii Pământeni',
			'Călăţele',
			'Buznea',
			'Buteni',
			'Burla',
			'Burca',
			'Bujoreni',
			'Bucovăț',
			'Breaza',
			'Braneț',
			'Brad',
			'Boteni',
			'Blăjel',
			'Baru Mic',
			'Bărbulești',
			'Băneasa',
			'Balta Doamnei',
			'Balintești',
			'Băleşti',
			'Bădoși',
			'Bacău',
			'Băcălești',
			'Auşeu',
			'Andrei Șaguna',
			'Almaşu Mare',
			'Alba',
			'Horea',
			'Baranca',
			'Ghioca',
			'Zdrapți',
			'Zalha',
			'Zalău',
			'Vulturu',
			'Voievodeasa',
			'Voiteg',
			'Vlăhiţa',
			'Vişani',
			'Vârtoapele de Sus',
			'Vintere',
			'Viişoara',
			'Veseuș',
			'Varnița',
			'Valea Viilor',
			'Valea Lungă Alba Romania',
			'Vălcani',
			'Vaida-Cămăraș',
			'Unirea',
			'Ungra',
			'Ulmi',
			'Ulmeni',
			'Toteşti',
			'Topalu',
			'Tâmna',
			'Ticvaniu Mare',
			'Țelna',
			'Tătărăști',
			'Tălpaș',
			'Stornești',
			'Stoeneşti',
			'Stăneşti',
			'Sprâncenata',
			'Smârdioasa',
			'Smârdan',
			'Slătioara',
			'Şipote',
			'Sântimbru',
			'Şinteu',
			'Singureni',
			'Sâncel',
			'Siminoc',
			'Scurtu Mare',
			'Scheiu de Sus',
			'Scăeşti',
			'Saschiz',
			'Săpunari',
			'Săpoca',
			'Santău',
			'Şamşud',
			'Rociu',
			'Rediu',
			'Rast',
			'Putna',
			'Purani',
			'Puchenii Moșneni',
			'Prunişor',
			'Poroina Mare',
			'Polovragi',
			'Poienile de sub Munte',
			'Pogăceaua',
			'Podenii Vechi',
			'Peregu Mare',
			'Peceiu',
			'Pardoşi',
			'Păltiniş',
			'Obreja',
			'Oarja Sat',
			'Nufăru',
			'Novaci',
			'Năsăud',
			'Mozăceni',
			'Moşna',
			'Mogoşoaia',
			'Mischii',
			'Miorcani',
			'Meteş',
			'Merei',
			'Medgidia',
			'Măneşti',
			'Malu',
			'Lupşa',
			'Lunca Cetățuii',
			'Lucieni',
			'Logreşti',
			'Livezile',
			'Limpeziș',
			'Limanu',
			'Leţcani',
			'Letca',
			'Lespezi',
			'Leordeni',
			'Lăpoș',
			'Joldești',
			'Jiu-Paroșeni',
			'Viile Tecii',
			'Ilva Mare',
			'Ieud',
			'Iernut',
			'Heleșteni',
			'Hărmăneasa',
			'Hamcearca',
			'Halmeu',
			'Guşoeni',
			'Gorneşti',
			'Godeanu',
			'Ghindăreşti',
			'Gherţa Mică',
			'Gălbinași',
			'Galbenu',
			'Foglaş',
			'Fetești',
			'Feleacu',
			'Fărcaşele',
			'Grădinari',
			'Făgetu',
			'Dumbrăveşti',
			'Drăcşani',
			'Dorobanţu',
			'Dorobanți',
			'Deseşti',
			'Deja',
			'Dedrad',
			'Dăneţi',
			'Cuzdrioara',
			'Curtea de Argeş',
			'Cucuieți',
			'Crivăț',
			'Cristeşti',
			'Coşbuc',
			'Cojocna',
			'Coada Izvorului',
			'Cleja',
			'Cârligele',
			'Ciomăgeşti',
			'Ciocani',
			'Cintei',
			'Cândeşti',
			'Câmpeni',
			'Chiuiești',
			'Chișoda',
			'Chişlaz',
			'Chirnogeni',
			'Chieşd',
			'Căscioarele',
			'Cămăraşu',
			'Călimăneşti',
			'Buzescu',
			'Burjuc',
			'Buciumeni',
			'Brebu Megieșesc',
			'Brazii',
			'Bragadiru',
			'Boroaia',
			'Boghicea',
			'Bogdan Vodă',
			'Bogdăniţa',
			'Bobâlna',
			'Blândiana',
			'Bicazu Ardelean',
			'Bicaz',
			'Berzunţi',
			'Berești',
			'Belin',
			'Beciu',
			'Bărăganu',
			'Bălteni',
			'Armăşeşti',
			'Argel',
			'Apa',
			'Alimănești',
			'Alexeni',
			'Albiș',
			'Albac',
			'Aghireșu-Fabrici',
			'Uileacu de Beiuș',
			'Zvoriştea',
			'Zăpodeni',
			'Voiceşti',
			'Vizurești',
			'Vintileasca',
			'Vânători',
			'Viile',
			'Veștem',
			'Vărbilău',
			'Vărăști',
			'Văleni',
			'Vădăstriţa',
			'Văculeşti',
			'Uzunu',
			'Ucea de Jos',
			'Ţuţora',
			'Trei Sate',
			'Tomeşti',
			'Târşolţel',
			'Târgu Trotuş',
			'Târgu-Mureş',
			'Ţibăneşti',
			'Teliucu Inferior',
			'Telciu',
			'Supuru de Jos',
			'Şuncuiuş',
			'Subcetate',
			'Străteni',
			'Stoeneşti',
			'Şotânga',
			'Şomcuta Mare',
			'Şimleu Silvaniei',
			'Segarcea',
			'Scorţoasa',
			'Saraiu',
			'Sarafinești',
			'Sălsig',
			'Săgeata',
			'Sacu',
			'Ruja',
			'Rugineşti',
			'Roşia',
			'Râu de Mori',
			'Rebrişoara',
			'Războieni',
			'Rădoiești-Deal',
			'Rădeni',
			'Popești',
			'Poloboc',
			'Poiana Codrului',
			'Poiana',
			'Poieni-Solca',
			'Poienari',
			'Pociovaliștea',
			'Plopu',
			'Pişcolt',
			'Pârjol',
			'Pârgăreşti',
			'Pietrari',
			'Petrova',
			'Păuleni-Ciuc',
			'Păcureţi',
			'Oţeleni',
			'Orbeni',
			'Oniceni',
			'Onceşti',
			'Oltina',
			'Ocland',
			'Nisiporești',
			'Negrileasa',
			'Negraşi',
			'Moineşti',
			'Mogoşeşti',
			'Mociu',
			'Mișca',
			'Mânău',
			'Mihăeşti',
			'Miceştii de Câmpie',
			'Mediaş',
			'Măguri-Răcătău',
			'Mogești',
			'Lunca',
			'Letca Nouă',
			'Leleasca',
			'Lechinţa',
			'Izvoare',
			'Iveşti',
			'Ivănești',
			'Islaz',
			'Ioneşti',
			'Întorsura Buzăului',
			'Ilva Mică',
			'Igneşti',
			'Huşi',
			'Hârlău',
			'Hida',
			'Hăbeni',
			'Groşi',
			'Greblești',
			'Grebănu',
			'Glogova',
			'Giubega',
			'Gârnic',
			'Gârda de Sus',
			'Gherla',
			'Gherăeşti',
			'Galșa',
			'Fruntișeni',
			'Forău',
			'Fântânele',
			'Finiş',
			'Făurei',
			'Fărtăţeşti',
			'Epureni',
			'Iedera de Jos',
			'Dumitra',
			'Dumeni',
			'Dudu',
			'Dudașu',
			'Dorobanţu',
			'Dorneşti',
			'Dobromir',
			'Dezmir',
			'Deva',
			'Dara',
			'Dăneşti',
			'Dalboșeț',
			'Dăeni',
			'Cudalbi',
			'Cuca',
			'Crucişor',
			'Crăcăoani',
			'Coţofenii din Dos',
			'Costache Negri',
			'Cosoba',
			'Cosmina de Jos',
			'Cosmeşti',
			'Cordăreni',
			'Copaciu',
			'Comişani',
			'Comana',
			'Colibași',
			'Ciușlea',
			'Ciupercenii Vechi',
			'Ciuchici',
			'Cisnădie',
			'Chiojdeni',
			'Cergău Mic',
			'Cefa',
			'Căzăneşti',
			'Cătămărești-Deal',
			'Camăr',
			'Buza',
			'Buşteni',
			'Budeşti',
			'Bucium',
			'Brezoi',
			'Brazii de Sus',
			'Boureni',
			'Boureni',
			'Borş',
			'Borod',
			'Bolboşi',
			'Boianu Mare',
			'Bohotin',
			'Bâsca Chiojdului',
			'Biharia',
			'Berești',
			'Bengești',
			'Bălţaţi',
			'Balta Albă',
			'Balş',
			'Băleşti',
			'Băiculeşti',
			'Aninoasa',
			'Aliman',
			'Plopeni',
			'Zărneşti',
			'Vultureni',
			'Vulcan',
			'Voineasa',
			'Vișina Nouă',
			'Vişeu de Jos',
			'Vânători',
			'Vălişoara',
			'Văleni',
			'Valea Seacă',
			'Valea Crişului',
			'Vadu Moţilor',
			'Văcăreşti',
			'Tuluceşti',
			'Ţuglui',
			'Traian Vuia',
			'Traian',
			'Timişeşti',
			'Terpeziţa',
			'Tășad',
			'Suplacu de Barcău',
			'Steierdorf',
			'Ştefăneşti-Sat',
			'Stănişeşti',
			'Smirna',
			'Sita Buzăului',
			'Sânpetru de Câmpie',
			'Sânmărtin',
			'Sâmbăta de Sus',
			'Şieu-Măgheruş',
			'Șepreuș',
			'Seini',
			'Sebeş',
			'Salcia',
			'Ruşii Munţi',
			'Rucăr',
			'Râmniceni',
			'Rieni',
			'Recea',
			'Recaş',
			'Rebra',
			'Racova',
			'Puieşti',
			'Plevna',
			'Petreşti',
			'Perșunari',
			'Pătârlagele',
			'Palanca',
			'Ovidiu',
			'Oţelu Roşu',
			'Ostra',
			'Oșorhei',
			'Oreavu',
			'Oleșești',
			'Ocniţa',
			'Ocna de Jos',
			'Oarda',
			'Nuntași',
			'Nucet',
			'Negreşti',
			'Năvodari',
			'Motoşeni',
			'Moldoveni',
			'Moeciu de Jos',
			'Mireşu Mare',
			'Merghindeal',
			'Merenii de Jos',
			'Mătăsari',
			'Mărişelu',
			'Margina',
			'Măraşu',
			'Malu Mare',
			'Măgura',
			'Mădulari',
			'Lungeşti',
			'Lunca Priporului',
			'Luna de Sus',
			'Lozna',
			'Lopătari',
			'Liteni',
			'Liţa',
			'Liebling',
			'Lăzărești',
			'Jugureni',
			'Jichişu de Jos',
			'Ineu',
			'Ilia',
			'Iernuțeni',
			'Icușeni',
			'Iazu Nou',
			'Hunedoara',
			'Hăneşti',
			'Gvardinița',
			'Gura Râului',
			'Gruiu',
			'Grozeşti',
			'Grozăvești',
			'Gogoşu',
			'Glodeni',
			'Gârliciu',
			'Ghimeş-Făget',
			'Găneasa',
			'Frumoasa',
			'Frătăuţii Noi',
			'Fântânele',
			'Fierbinții de Sus',
			'Fierbinții de Jos',
			'Fibiș',
			'Felnac',
			'Fărcășeni',
			'Eftimie Murgu',
			'Drăgoteşti',
			'Draxini',
			'Dolheşti',
			'Doclin',
			'Dobreşti',
			'Doba',
			'Dârvari',
			'Dâmbovicioara',
			'Densuş',
			'Davideşti',
			'Darova Nouă',
			'Dăneşti',
			'Dalnic',
			'Dăești',
			'Cut',
			'Curtici',
			'Cristur',
			'Cozmeşti',
			'Corabia',
			'Comana de Jos',
			'Ciuperceni',
			'Ciumbrud',
			'Cârlogani',
			'Cireșoaia',
			'Ciochina',
			'Chișcăreni',
			'Chiscani',
			'Chilia Veche',
			'Ceraşu',
			'Cărpiniş',
			'Capu Piscului',
			'Cămin',
			'Bungetu',
			'Braşov',
			'Botiz',
			'Bosia',
			'Boița',
			'Boghiș',
			'Bogheşti',
			'Bocicoiu Mare',
			'Blânzi',
			'Bârzești',
			'Bâlta',
			'Bilbor',
			'Bertea',
			'Bărăbanț',
			'Banloc',
			'Băneasa',
			'Bălţăteşti',
			'Bala',
			'Băcia',
			'Băbeni-Oltețu',
			'Băbeni',
			'Apele Vii',
			'Apalina',
			'Andreești',
			'Alunișu',
			'Alexandria',
			'Albeşti-Paleologu',
			'Adămuş',
			'Acăţari',
			'Horia',
			'Sector 1',
			'Vulcana de Sus',
			'Voineşti',
			'Vlăduleni',
			'Vitomireşti',
			'Vişina',
			'Vânătorii Mici',
			'Vâlcelele de Sus',
			'Vâlcele',
			'Victor Vlad Delamarina',
			'Vermeş',
			'Valea Seacă',
			'Valea Nucarilor',
			'Valea Ierii',
			'Valea Dragului',
			'Vaideeni',
			'Unţeni',
			'Ungureni',
			'Tunari',
			'Tulca',
			'Tufeni',
			'Troianul',
			'Topile',
			'Todireşti',
			'Târlişua',
			'Teslui',
			'Tătuleşti',
			'Tăriceni',
			'Tamaşi',
			'Sudiți',
			'Studina',
			'Strejeștii de Sus',
			'Stâlpeni',
			'Snagov',
			'Sânmihaiu Almaşului',
			'Silivașu de Câmpie',
			'Sighetu Marmaţiei',
			'Sic',
			'Seaca de Pădure',
			'Șard',
			'Sărățeni',
			'Săbăoani',
			'Rucăreni',
			'Romanu',
			'Rădeşti',
			'Puieștii de Jos',
			'Prundu',
			'Pribești',
			'Potcoava Fălcoeni',
			'Poseștii-Pământeni',
			'Poiana Ilvei',
			'Poiana',
			'Poiana',
			'Plopeni',
			'Pleşcuţa',
			'Pleașa',
			'Pieleştí',
			'Petroşani',
			'Petrești',
			'Pechea',
			'Pădureni',
			'Orășa',
			'Odobești',
			'Nicolae Bălcescu',
			'Nicolae Bălcescu',
			'Nicolae Bălcescu',
			'Moreni',
			'Miroslovești',
			'Milcovățu',
			'Mihai Vodă',
			'Mihai Bravu',
			'Micești',
			'Mărgineni',
			'Mareș',
			'Mal',
			'Măgureni',
			'Măgurele',
			'Luncaviţa',
			'Luncani',
			'Lunca Leșului',
			'Lunca la Tisa',
			'Lunca Ilvei',
			'Livezile',
			'Lespezi',
			'Jimbolia',
			'Ilieni',
			'Ideciu de Jos',
			'Huta Certeze',
			'Hurjuieni',
			'Homorodu de Jos',
			'Herculian',
			'Hășmaș',
			'Halmăşd',
			'Hăghig',
			'Gurbediu',
			'Gurahonţ',
			'Greci',
			'Greci',
			'Golăiești',
			'Glăvăneşti',
			'Gârbești',
			'Gângiova',
			'Gheorghe Lazăr',
			'Gemenele',
			'Gărăgău',
			'Galda de Jos',
			'Frăteşti',
			'Feldioara',
			'Feisa',
			'Făget',
			'Enisala',
			'Iedera de Sus',
			'Dumbrăveni',
			'Dragoș Vodă',
			'Dorohoi',
			'Dobrița',
			'Doboșeni',
			'Dâmbu',
			'Crângeni',
			'Crasna',
			'Crăciunești',
			'Cordun',
			'Corbeni',
			'Conceşti',
			'Colonia Fabricii',
			'Cârjiţi',
			'Ciprian Porumbescu',
			'Cipău',
			'Ciceu',
			'Chiselet',
			'Chircești',
			'Cetariu',
			'Cărpinet',
			'Carcaliu',
			'Cărășeu',
			'Căianu Mic',
			'Buriaș',
			'Buneşti',
			'Bucecea',
			'Brebeni',
			'Brătila',
			'Borcea',
			'Boloteşti',
			'Bodești',
			'Bobolia',
			'Beuca',
			'Berleşti',
			'Berbeşti',
			'Beba Veche',
			'Băiuţ',
			'Băile Tuşnad',
			'Băile Olăneşti',
			'Băile Govora',
			'Baia de Arieş',
			'Bădeni',
			'Andrăşeşti',
			'Aluniş',
			'Poienari',
			'Sâniob',
			'Ungureni',
			'Drăcșenei',
			'Zădăreni',
			'Zăbrani',
			'Viziru',
			'Vârvoru de Jos',
			'Vima Mică',
			'Viforâta',
			'Vela',
			'Vaslui',
			'Văleni',
			'Valea Târsei',
			'Valea Șoșii',
			'Valea Seacă',
			'Valea Mărului',
			'Urecheşti',
			'General Berthelot',
			'Ulmeni',
			'Ulieşti',
			'Tutova',
			'Turceni',
			'Topoloveni',
			'Toflea',
			'Teiuş',
			'Tămășeu',
			'Tămașda',
			'Susenii Bârgăului',
			'Studinița',
			'Stremţ',
			'Stolniceni',
			'Ştefăneşti',
			'Sohodol',
			'Slănic-Moldova',
			'Sârbi',
			'Sânmihaiu Român',
			'Sâncrai',
			'Sânandrei',
			'Simeria',
			'Şendriceni',
			'Şendreni',
			'Seaca de Câmp',
			'Scheia',
			'Ruscova',
			'Runcu',
			'Roşiori',
			'Roșiori',
			'Roşiile',
			'Români',
			'Remetea Mare',
			'Răstoliţa',
			'Radna',
			'Rădăşeni',
			'Racovița',
			'Pricaz',
			'Predeal',
			'Potelu',
			'Ploscoș',
			'Pâraie',
			'Pietrosu',
			'Petrila',
			'Peştera',
			'Perieţi',
			'Periam',
			'Păuca',
			'Panticeu',
			'Oporelu',
			'Oneaga',
			'Oncești',
			'Odobeşti',
			'Ocina de Sus',
			'Oboga',
			'Nistru',
			'Neudorf',
			'Negrilești',
			'Morteni',
			'Morăreşti',
			'Mitoc',
			'Mihăileni',
			'Mihai Bravu',
			'Micula',
			'Mailat',
			'Mădăraș',
			'Lumina',
			'Luciu',
			'Lacu Sărat',
			'Jirov',
			'Izvoarele',
			'Izvoarele',
			'I. L. Caragiale',
			'Icoana',
			'Hopârta',
			'Holbav',
			'Hemeiuș',
			'Hălmăgel',
			'Gura Caliţei',
			'Goruna',
			'Glodenii Gândului',
			'Gârdani',
			'Ghindeni',
			'Gheorghieni',
			'Gheboaia',
			'Fundeni',
			'Frumuşica',
			'Frata',
			'Foieni',
			'Filiaşi',
			'Durneşti',
			'Dumitrești',
			'Drăgăneşti-Olt',
			'Dobreşti',
			'Dârlos',
			'Deveselu',
			'Beznea',
			'Cungrea',
			'Crasna',
			'Crăieşti',
			'Cracăul Negru',
			'Cozieni',
			'Cotenești',
			'Costineşti',
			'Costeşti',
			'Corbiţa',
			'Copalnic Mănăştur',
			'Copăcelu',
			'Comana',
			'Cogealac',
			'Codăeşti',
			'Cocora',
			'Cireşu',
			'Ciobanu',
			'Câmpurile de Jos',
			'Câmpineanca',
			'Chisindia',
			'Ceardac',
			'Cerchezu',
			'Cehei',
			'C.A. Rosetti',
			'Căprioru',
			'Călui',
			'Călinești',
			'Căldărăști',
			'Bulzeștii de Sus',
			'Buciumeni',
			'Bruiu',
			'Broscăuţi',
			'Brezniţa-Motru',
			'Breazu',
			'Brastavățu',
			'Brăniștari',
			'Brăneşti',
			'Bozieni',
			'Bosanci',
			'Bogdăneşti',
			'Bobiceşti',
			'Bâscenii de Sus',
			'Beștepe',
			'Belinţ',
			'Batârăşti',
			'Băţanii Mari',
			'Bălușești',
			'Bahnea',
			'Baba Ana',
			'Axintele',
			'Arghira',
			'Teslui',
			'Argeșelu',
			'Ardud',
			'Amărăştii de Sus',
			'Alparea',
			'Albota',
			'Agârbiciu',
			'Abram',
			'Vetrişoaia',
			'Albeştii de Argeş',
			'Dragoslavele',
			'Bijghir',
			'Zărneștii de Slănic',
			'Zam',
			'Vultureşti',
			'Voineşti',
			'Vlașca',
			'Vinga',
			'Viişoara',
			'Vedea',
			'Variaş',
			'Vadu Roșca',
			'Ususău',
			'Ungureni',
			'Udupu',
			'Tufeşti',
			'Traian',
			'Tohanu Nou',
			'Todireşti',
			'Târgşoru Vechi',
			'Țibeni',
			'Suseni',
			'Sultana',
			'Suciu de Jos',
			'Sterpoaia',
			'Ștefan cel Mare',
			'Şona',
			'Slava Rusă',
			'Slatina',
			'Sânmihaiu de Câmpie',
			'Sâncrai',
			'Siliştea Crucii',
			'Şieuţ',
			'Șiclău',
			'Sicheviţa',
			'Sfârcea',
			'Șerboeni',
			'Seimeni',
			'Secui',
			'Schitu-Goleşti',
			'Scărişoara',
			'Satu Nou',
			'Satu Mare',
			'Satulung',
			'Saravale',
			'Şagu',
			'Săcueni',
			'Săbăreni',
			'Rupea',
			'Roznov',
			'Rovinari',
			'Rona de Jos',
			'Româneşti',
			'Răşinari',
			'Rădăuți-Prut',
			'Răchitoasa',
			'Putineiu',
			'Punghina',
			'Pucioasa',
			'Produleşti',
			'Prăjeni',
			'Poiana',
			'Pângărăcior',
			'Petreşti',
			'Păuleşti',
			'Păulești',
			'Pantelimon',
			'Padina',
			'Oteşani',
			'Olcea',
			'Negru Vodă',
			'Muntenii de Jos',
			'Muereasca',
			'Moftinu Mare',
			'Mijlocenii Bârgăului',
			'Mija',
			'Mărgineni',
			'Mărăcineni',
			'Mangalia',
			'Mănăştiur',
			'Mănăilești',
			'Loamneş',
			'Livezile',
			'Livezile',
			'Liteni',
			'Laloşu',
			'Josenii Bârgăului',
			'Joiţa',
			'Jina',
			'Izimșa',
			'Ilișești',
			'Ighișu Nou',
			'Iclănzel',
			'Ibăneşti',
			'Ibăneşti',
			'Huedin',
			'Holt',
			'Hârtop',
			'Gura Ialomiței',
			'Gradiștea',
			'Gologanu',
			'Glod',
			'Gârleni',
			'Gârbău',
			'Ghighișeni',
			'Gheorgheni',
			'Frumoasa',
			'Floreşti',
			'Fălcoiu',
			'Eforie',
			'Drăguţeşti',
			'Drăghinești',
			'Dobreni',
			'Deleni',
			'Dăscălești',
			'Dăbâca',
			'Cuci',
			'Cristineşti',
			'Crețești',
			'Covasna',
			'Coţofăneşti',
			'Costești',
			'Cornereva',
			'Constanţa',
			'Cârjoaia',
			'Chițoc',
			'Chiojdeanca',
			'Chendu',
			'Ceptura de Jos',
			'Cazasu',
			'Căzăneşti',
			'Cavnic',
			'Cățelu',
			'Castranova',
			'Căpățânești',
			'Calafat',
			'Cajvana',
			'Budești',
			'Budeşti',
			'Bucinişu',
			'Broşteni',
			'Brânceni',
			'Brănişca',
			'Blăgeşti',
			'Bivolărie',
			'Bivolari',
			'Bistra',
			'Bâldana',
			'Berca',
			'Berbești',
			'Bărcăneşti',
			'Băneasa',
			'Balinţ',
			'Baldovineşti',
			'Balda',
			'Băile Herculane',
			'Bacova',
			'Băbeni',
			'Azuga',
			'Avram Iancu',
			'Archiş',
			'Apoldu de Jos',
			'Andrid',
			'Alexandru Odobescu',
			'Aiud',
			'Agnita',
			'Adjudeni',
			'Abrud',
			'Luna',
			'Valea Voievozilor',
			'Zorile',
			'Vulpeni',
			'Vorona',
			'Voivodeni',
			'Vlădeni',
			'Vârfurile',
			'Vintilă Vodă',
			'Viișoara',
			'Viișoara',
			'Vășad',
			'Vărbila',
			'Vama Buzăului',
			'Văliug',
			'Văleni',
			'Valea Mare',
			'Valea Argovei',
			'Vădastra',
			'Urecheşti',
			'Turda',
			'Turcineşti',
			'Turcești',
			'Tortoman',
			'Topolog',
			'Târnava',
			'Târgu Lăpuş',
			'Târgu Cărbuneşti',
			'Tâmboeşti',
			'Tiha Bârgăului',
			'Ţibana',
			'Teiș',
			'Strâmbeni',
			'Straja',
			'Ștefănești',
			'Somova',
			'Şiştarovăţ',
			'Sârbi',
			'Sântion',
			'Sânpaul',
			'Sinaia',
			'Șilindru',
			'Şicula',
			'Secu',
			'Sebeșel',
			'Scurta',
			'Sărmaşu',
			'Şărmăşag',
			'Sarasău',
			'Săcueni',
			'Sacoşu Turcesc',
			'Rusca Montană',
			'Pecica',
			'Râfov',
			'Remuș',
			'Radovan',
			'Priseaca',
			'Prisăcani',
			'Prelipca',
			'Popeşti',
			'Poiana Teiului',
			'Poiana Mărului',
			'Poiana Cristei',
			'Podenii Noi',
			'Piscu Sadovei',
			'Pietrosu',
			'Pietroșani',
			'Petreu',
			'Părteştii de Jos',
			'Orbeasca de Sus',
			'Ohaba',
			'Obârșia de Câmp',
			'Nicşeni',
			'Negrilești',
			'Negostina',
			'Năneşti',
			'Nădlac',
			'Nadeş',
			'Murighiol',
			'Murgeşti',
			'Moldoveneşti',
			'Moldova Nouă',
			'Mogoşeşti-Siret',
			'Moara Vlăsiei',
			'Mâsca',
			'Mircea Vodă',
			'Mânăstirea',
			'Mihăileşti',
			'Miercurea-Ciuc',
			'Măstăcani',
			'Măgura',
			'Lugoj',
			'Lucăceni',
			'Leşu',
			'Leordina',
			'Lenauheim',
			'Lelese',
			'Işalniţa',
			'Iecea Mare',
			'Icoana',
			'Hodac',
			'Hănțești',
			'Gura Văii',
			'Gura Humorului',
			'Gropniţa',
			'Grajduri',
			'Giera',
			'Gătaia',
			'Găinești',
			'Fulga de Jos',
			'Foeni',
			'Fântâna Mare',
			'Fierbinţi-Târg',
			'Fărăgău',
			'Dumbrăveni',
			'Dumbrava',
			'Dudeştii Vechi',
			'Drauț',
			'Dragosloveni',
			'Dragomireşti',
			'Dognecea',
			'Crevedia Mare',
			'Cosmeștii-Vale',
			'Cornu de Sus',
			'Colacu',
			'Colacu',
			'Cizer',
			'Ciurila',
			'Câmpuri',
			'Chişineu-Criş',
			'Chiojdu',
			'Chier',
			'Căteasca',
			'Carastelec',
			'Căpuşu Mare',
			'Călan',
			'Bunești',
			'Târgu Bujor',
			'Budeasa',
			'Bucșoaia',
			'Brebu Mânăstirei',
			'Breaza de Sus',
			'Rădulești',
			'Brăhășești',
			'Brăduţ',
			'Boldur',
			'Bogdana',
			'Boboiești',
			'Bârzava',
			'Bârza',
			'Bicaz',
			'Beriu',
			'Bereşti-Bistriţa',
			'Beclean',
			'Barați',
			'Bălcăuţi',
			'Bălăceanu',
			'Băla',
			'Băiţa',
			'Băcioiu',
			'Băbeni',
			'Avrămeni',
			'Amara',
			'Alunu',
			'Almăj',
			'Agăş',
			'Afumaţi',
			'Sector 4',
			'Zlătărei',
			'Zidurile',
			'Vânători',
			'Vetiş',
			'Vasilaţi',
			'Valea Râmnicului',
			'Valea Lungă Ogrea',
			'Valea Iaşului',
			'Valea Borcutului',
			'Vadu Moldovei',
			'Urmeniş',
			'Tușnadu Nou',
			'Turda',
			'Tulgheş',
			'Toporu',
			'Țipar',
			'Tihău',
			'Teșila',
			'Techirghiol',
			'Surani',
			'Şura Mică',
			'Suceava',
			'Starchiojd',
			'Stăncuţa',
			'Spătărei',
			'Şopârliţa',
			'Sireţel',
			'Santa Mare',
			'Sângeorz-Băi',
			'Şieu',
			'Secuieni',
			'Secăria',
			'Săvineşti',
			'Sălcioara',
			'Sălcioara',
			'Salcia',
			'Sălăţig',
			'Ruseni',
			'Rudeni',
			'Rotunda',
			'Rodna',
			'Râca',
			'Ribiţa',
			'Răpănaşu',
			'Răducăneni',
			'Racoviţeni',
			'Răchiţi',
			'Răcăşdia',
			'Pusta',
			'Pungeşti',
			'Preuteşti',
			'Potcoava',
			'Popești',
			'Pomi',
			'Poienile Izei',
			'Podoleni',
			'Plopşoru',
			'Piteşti',
			'Pârcovaci',
			'Petrești',
			'Perșinari',
			'Păpăuți',
			'Pantazi',
			'Ozun',
			'Orevița Mare',
			'Olari',
			'Ocna Mureş',
			'Nucet',
			'Nepos',
			'Moțăieni',
			'Moșteni',
			'Moşoaia',
			'Miron Costin',
			'Miloşeşti',
			'Milcovul',
			'Mihăeşti',
			'Măureni',
			'Mătești',
			'Malnaş',
			'Malcoci',
			'Luncșoara',
			'Lunca',
			'Livezi-Vale',
			'Lipova',
			'Izvoarele',
			'Izbiceni',
			'Iteşti',
			'Ineu',
			'Independenţa',
			'Ighiu',
			'Iaslovăț',
			'Hoghiz',
			'Haţeg',
			'Hărman',
			'Gura Vadului',
			'Greci',
			'Gostavăţu',
			'Gornet-Cricov',
			'Glodeanu-Siliştea',
			'Ghizela',
			'Ghioșești',
			'George Enescu',
			'Fundulea',
			'Fundata',
			'Folteşti',
			'Fizeşu Gherlii',
			'Ferestrău-Oituz',
			'Fălciu',
			'Dumbrava',
			'Dulcești',
			'Dudeşti',
			'Domnești-Târg',
			'Domnești',
			'Dochia',
			'Dițești',
			'Deta',
			'Deparați',
			'Deleni',
			'Dărmăneşti',
			'Dămoc',
			'Cuza Vodă',
			'Cuejdiu',
			'Crușeț',
			'Crivina',
			'Covăsinţ',
			'Coteana',
			'Costeşti',
			'Corbii Mari',
			'Conțești',
			'Cociuba Mare',
			'Cociu',
			'Ciuperceni',
			'Cislău',
			'Câmpurelu',
			'Câlnic',
			'Cilieni',
			'Chilii',
			'Chesinț',
			'Cerneți',
			'Cărbuneşti',
			'Capu Codrului',
			'Capu Câmpului',
			'Bughea de Jos',
			'Budila',
			'Brateiu',
			'Botoşana',
			'Bocsig',
			'Bircii',
			'Bâlteni',
			'Bichigiu',
			'Berzovia',
			'Bereni',
			'Bălteni',
			'Baldovinești',
			'Balc',
			'Băița',
			'Araci',
			'Vrani',
			'Moieciu de Jos',
			'Zlatna',
			'Zimandu Nou',
			'Zagăr',
			'Vulcana-Pandele',
			'Vorniceni',
			'Vârşolţ',
			'Vârciorova',
			'Vinerea',
			'Vărgata',
			'Vărăşti',
			'Valea Cânepii',
			'Uriu',
			'Umbrărești',
			'Tutana',
			'Turnu Ruieni',
			'Tulnici',
			'Suceveni',
			'Trifeşti',
			'Trestieni',
			'Topleţ',
			'Toboliu',
			'Târnăveni',
			'Teaca',
			'Tăuții de Sus',
			'Tărlungeni',
			'Tarcău',
			'Tărcaia',
			'Trestiana',
			'Storeşti',
			'Stoeneşti',
			'Sticlăria',
			'Stancea',
			'Solca',
			'Sântandrei',
			'Sânpetru',
			'Secuieni',
			'Sculia',
			'Scorţeni',
			'Scorţeni',
			'Şăulia',
			'Sănduleşti',
			'Sălcuța',
			'Sălciile',
			'Sadova',
			'Roșcani',
			'Remetea Chioarului',
			'Reci',
			'Preajba',
			'Poşta Câlnău',
			'Poienari',
			'Poiana Copăceni',
			'Poiana',
			'Pleşoiu',
			'Piscoiu',
			'Pipirig',
			'Peştişani',
			'Pardina',
			'Păncești',
			'Păltiniş',
			'Olteni',
			'Ojdula',
			'Oeștii Pământeni',
			'Nuşfalău',
			'Nicolești',
			'Negreşti-Oaş',
			'Nedelea',
			'Necşeşti',
			'Muntenii de Sus',
			'Movila Banului',
			'Moldova Suliţa',
			'Mofleni',
			'Mitreni',
			'Miroslăvești',
			'Mirăslău',
			'Mihai Bravu',
			'Miercurea Sibiului',
			'Mereni',
			'Mera',
			'Matei',
			'Mateeşti',
			'Maşloc',
			'Mărgăriteşti',
			'Mărculești',
			'Mărcești',
			'Mălâncrav',
			'Mădăras',
			'Ludoş',
			'Ludeşti',
			'Luciu',
			'Lipovu',
			'Leleşti',
			'Lăculețe',
			'Izvin',
			'Ilovăț',
			'Horoatu Crasnei',
			'Horleşti',
			'Horia',
			'Hârtoape',
			'Hârşeni',
			'Hărău',
			'Guruieni',
			'Griviţa',
			'Goreni',
			'Goranu',
			'Giarmata',
			'Gălășești',
			'Fundu Moldovei',
			'Frumuşiţa',
			'Frumuşani',
			'Frecăţei',
			'Frăsinet',
			'Forăşti',
			'Fântânele',
			'Feneș',
			'Feldru',
			'Fărcaşa',
			'Ernei',
			'Drăgușeni',
			'Drăgoești',
			'Dofteana',
			'Dobra',
			'Dealu',
			'Dărmăneşti',
			'Curcani',
			'Crăiești',
			'Corbu',
			'Corbasca',
			'Comoșteni',
			'Coloneşti',
			'Cioroiași',
			'Ciorogârla',
			'Câmpulung Moldovenesc',
			'Câmpulung la Tisa',
			'Chiajna',
			'Cerna',
			'Cegani',
			'Cașoca',
			'Călineşti',
			'Calafindeşti',
			'Budacu de Jos',
			'Bucerdea-Grânoasă',
			'Brâncoveanca',
			'Brezniţa Ocol',
			'Breaza',
			'Breasta',
			'Brădeşti',
			'Borsec',
			'Boldeşti',
			'Bârsăneşti',
			'Bârlad',
			'Bilciureşti',
			'Bicaz-Chei',
			'Bethausen',
			'Berceni',
			'Bazna',
			'Bărăștii de Vede',
			'Bărăganul',
			'Balta',
			'Bălăceanca',
			'Bălăbănești',
			'Aninoasa',
			'Amărăştii de Jos',
			'Albești-Muru',
			'Albeşti',
			'Agighiol',
			'Adâncata',
			'Ocolna',
			'Sector 2',
			'Zăuan',
			'Vrâncioaia',
			'Voineşti',
			'Viile',
			'Vieru',
			'Văratec',
			'Valea Vișeului',
			'Valea Lungă-Cricov',
			'Vadu Săpat',
			'Vădurele',
			'Urzicuţa',
			'Ulmu',
			'Turluianu',
			'Tudor Vladimirescu',
			'Târnova',
			'Tileagd',
			'Tia Mare',
			'Tătărăștii de Jos',
			'Tamași',
			'Tălpigi',
			'Sutești',
			'Stroiești',
			'Strejnicu',
			'Stolniceni-Prăjescu',
			'Ştiuca',
			'Ştefan Vodă',
			'Sopot',
			'Soleşti',
			'Şoimi',
			'Sohodol',
			'Slobozia',
			'Sângeorgiu de Mureş',
			'Șinca Nouă',
			'Şimian',
			'Şibot',
			'Seciu',
			'Schiulești',
			'Săveni',
			'Satu Nou',
			'Sărulești-Gară',
			'Săruleşti',
			'Săhăteni',
			'Sadina',
			'Rus',
			'Românești',
			'Râu Sadului',
			'Remetea',
			'Radovanu',
			'Rachelu',
			'Pustiana',
			'Prundu',
			'Prohozești',
			'Probota',
			'Potoceni',
			'Pogoanele',
			'Podu Turcului',
			'Podolenii de Sus',
			'Pleșoi',
			'Pietrișu',
			'Perii Broșteni',
			'Parincea',
			'Panciu',
			'Pălatca',
			'Ostrov',
			'Oşeşti',
			'Ogrezeni',
			'Ocna de Sus',
			'Nuşeni',
			'Nicolae Bălcescu',
			'Nereju',
			'Nenciulești',
			'Mârzăneşti',
			'Merii Petchii',
			'Mereni',
			'Mărginenii de Jos',
			'Lunca Cernii de Jos',
			'Luica',
			'Logrești Moșteni',
			'Lereşti',
			'Lazuri de Beiuş',
			'Jibou',
			'Jibert',
			'Jiana Veche',
			'Jegălia',
			'Ionești',
			'Ighiel',
			'Iapa',
			'Iancu Jianu',
			'Hulubeşti',
			'Hodod',
			'Hlipiceni',
			'Hăghiac',
			'Grunji',
			'Grădinari',
			'Gottlob',
			'Gorgota',
			'Goești',
			'Giroc',
			'Gherăseni',
			'Găeşti',
			'Fundeni',
			'Frumușelu',
			'Frătăuţii Vechi',
			'Forotic',
			'Florești',
			'Florești',
			'Fetești-Gară',
			'Dumeşti',
			'Dragomirești-Deal',
			'Dobra',
			'Dej',
			'Dedulești',
			'Dăneşti',
			'Daneş',
			'Cuvin',
			'Cut',
			'Crivina',
			'Criştioru de Jos',
			'Crângu',
			'Craiova',
			'Costeștii din Deal',
			'Coroieni',
			'Corod',
			'Corbi',
			'Copăcele',
			'Comloşu Mare',
			'Coconi',
			'Ciocăneşti',
			'Câmpina',
			'Chelința',
			'Cetate',
			'Cernăteşti',
			'Ceamurlia de Sus',
			'Călmăţuiu',
			'Buturugeni',
			'Bursuceni',
			'Buda',
			'Buda',
			'Bucharest',
			'Buciumi',
			'Buchin',
			'Broșteni',
			'Broşteni',
			'Brodoc',
			'Brăila',
			'Brăiești',
			'Botoşani',
			'Botiza',
			'Boşorod',
			'Borşa',
			'Borşa',
			'Borca',
			'Bolintin Vale',
			'Bobota',
			'Blăgeşti',
			'Bistrița',
			'Berlişte',
			'Beiu',
			'Băjești',
			'Ariniş',
			'Andreiaşu de Jos',
			'Almaşu',
			'Almaş',
			'Alba Iulia',
			'Frumușeni',
			'Zâmbreasca',
			'Zimbor',
			'Zăval',
			'Zătreni',
			'Voloiac',
			'Voineasa',
			'Vlădeni',
			'Vârtop',
			'Valu lui Traian',
			'Valea Grecului',
			'Tuşnad',
			'Tureni',
			'Truşeşti',
			'Traian',
			'Târgovişte',
			'Ţifeşti',
			'Ţepu',
			'Teleormanu',
			'Tăuteu',
			'Tăcuta',
			'Surdila-Găiseanca',
			'Spinuş',
			'Soporu de Câmpie',
			'Siret',
			'Sineşti',
			'Șindrilari',
			'Şimian',
			'Seleuș',
			'Segarcea-Deal',
			'Scorțaru Vechi',
			'Scânteia',
			'Scheiu de Jos',
			'Sărmaş',
			'Sanislău',
			'Runcu',
			'Roșu',
			'Roșiori',
			'Roșia Montană',
			'Râciu',
			'Reşiţa',
			'Progresu',
			'Potău',
			'Popeni',
			'Poiana Vadului',
			'Poiana',
			'Poiana',
			'Pogănești',
			'Podeni',
			'Pietroasele',
			'Pietrari',
			'Petroșnița',
			'Pesac',
			'Perieni',
			'Peceneaga',
			'Parepa-Rușani',
			'Pănătău',
			'Oniceni',
			'Odaia Turcului',
			'Ocna Şugatag',
			'Negrilești',
			'Muşeteşti',
			'Movileni',
			'Mireșu Mare',
			'Mircea Vodă',
			'Mânzăleşti',
			'Mihăileni',
			'Mihai Bravu',
			'Meziad',
			'Mărişel',
			'Mărgineni-Munteni',
			'Manasia',
			'Mălureni',
			'Măcin',
			'Lăpugiu de Jos',
			'Lăcusteni',
			'Jugur',
			'Islaz',
			'Ion Roată',
			'Iazu',
			'Iacobeni',
			'Holod',
			'Gorgota',
			'Godineşti',
			'Giarmata-Vii',
			'Floreşti',
			'Falaștoaca',
			'Emil Racoviță',
			'Eliseni',
			'Dumbrăvița',
			'Dubova',
			'Drăguș',
			'Dragomireşti',
			'23 August',
			'Dobrovăţ',
			'Dimăcheni',
			'Dezna',
			'Darabani',
			'Dămieneşti',
			'Curteşti',
			'Cristian',
			'Cristești',
			'Crâmpoia',
			'Cotmeana',
			'Costești',
			'Copşa Mică',
			'Coloneşti',
			'Codlea',
			'Ciucani',
			'Ciofrângeni',
			'Ciocile',
			'Ciochiuța',
			'Câmpani de Pomezeu',
			'Chiriacu',
			'Cheia',
			'Cernu',
			'Castelu',
			'Căneşti',
			'Călinești',
			'Căldăraru',
			'Călăraşi',
			'Buruienești',
			'Buliga',
			'Bulgăruș',
			'Brodina',
			'Brâncoveneşti',
			'Brezoaia',
			'Breaza',
			'Breaza',
			'Brătești',
			'Brateş',
			'Botoroaga',
			'Bordenii Mari',
			'Bogza',
			'Blejoi',
			'Bistrița',
			'Bârsău de Sus',
			'Birda',
			'Biertan',
			'Bereşti-Tazlău',
			'Berceni',
			'Benesat',
			'Beliu',
			'Bata',
			'Barza',
			'Baru',
			'Bărbuleţu',
			'Balș',
			'Bălnaca',
			'Bălan',
			'Băbăiţa',
			'Babadag',
			'Asău',
			'Apateu',
			'Apa Asău',
			'Amzacea',
			'Alămor',
			'Aita Mare',
			'Bonțida',
			'Gurasada',
			'Zorești',
			'Zăvoi',
			'Zagra',
			'Voșlăbeni',
			'Voetin',
			'Vladimirescu',
			'Viștea de Jos',
			'Vâlcele',
			'Văleni',
			'Valea Cucului',
			'Urziceni',
			'Tureac',
			'Turdaş',
			'Trip',
			'Târnova',
			'Târguşor',
			'Tiream',
			'Tinca',
			'Teregova',
			'Tătăranu',
			'Surduc',
			'Suraia',
			'Stoicăneşti',
			'Ştefan cel Mare',
			'Soci',
			'Slobozia Conachi',
			'Sânzieni',
			'Sânnicoară',
			'Sînmartin',
			'Sânmartin',
			'Sâncraiu de Mureş',
			'Siliştea',
			'Segarcea Vale',
			'Scânteia',
			'Săvârşin',
			'Sascut-Sat',
			'Salcea',
			'Saelele',
			'Sacoșu Mare',
			'Rugășești',
			'Rotunda',
			'Rojiște',
			'Reteag',
			'Răstoaca',
			'Răscruci',
			'Rasa',
			'Radomireşti',
			'Rachieri',
			'Prejmer',
			'Poporogi',
			'Pojejena',
			'Poduri',
			'Pocruia',
			'Poarta Albă',
			'Pângăraţi',
			'Petricani',
			'Glăvile',
			'Perieţi',
			'Pecineaga',
			'Păuşeşti',
			'Pătulele',
			'Păstrăveni',
			'Papiu Ilarian',
			'Padina Matei',
			'Ologeni',
			'Niculiţel',
			'Nerău',
			'Nemțișor',
			'Nana',
			'Murgeni',
			'Munteni',
			'Movila Miresii',
			'Moşniţa Nouă',
			'Moldoveni',
			'Mislea',
			'Miroşi',
			'Mintiu Gherlii',
			'Mânăstirea Caşin',
			'Mihăiești',
			'Mera',
			'Mastacăn',
			'Mărunţei',
			'Măru',
			'Măriței',
			'Marghita',
			'Măldăeni',
			'Maia',
			'Lunca Banului',
			'Ludași',
			'Liliești',
			'Licurici',
			'Larga',
			'Lancrăm',
			'Jirlău',
			'Jijila',
			'Izvoru Crişului',
			'Izvoru Bârzii',
			'Istria',
			'Hoghilag',
			'Hodoni',
			'Gostinari',
			'Goruni',
			'Gorganu',
			'Godeni',
			'Giurgiu',
			'Giurgeni',
			'Ghimpețeni',
			'Geamăna',
			'Dâlga-Gară',
			'Galicea',
			'Focuri',
			'Fâstâci',
			'Fântânele',
			'Făgăraș',
			'Estelnic',
			'Eşelniţa',
			'Dumbrăvița',
			'Dragodana',
			'Drăgănești',
			'Dobrin',
			'Dobreni',
			'Dobra',
			'Ditrău',
			'Dioşti',
			'Hurdugi',
			'Decindeni',
			'Cuzăplac',
			'Cucuruzu',
			'Cozia',
			'Coțatcu',
			'Corunca',
			'Comarna',
			'Comandău',
			'Cișmele',
			'Ciolpani',
			'Ciohorăni',
			'Câmpani',
			'Cicârlău',
			'Cenade',
			'Căşeiu',
			'Cămărzana',
			'Călineşti',
			'Buziaş',
			'Butea',
			'Buda',
			'Bradu',
			'Bradu',
			'Bozioru',
			'Botoşeşti-Paia',
			'Borcut',
			'Bistrița',
			'Bisoca',
			'Bârla',
			'Bixad',
			'Bereşti-Sat',
			'Beidaud',
			'Barcea',
			'Baraolt',
			'Băleni',
			'Băiţa de sub Codru',
			'Bădeni',
			'Alunișu',
			'Albota de Jos',
			'Albeşti',
			'Adjud',
			'Abrud-Sat',
			'Oarja',
			'Gropeni',
			'Culmea',
			'Urzica',
			'Zăvoiu',
			'Vultureşti',
			'Vulcana Băi',
			'Voivozi',
			'Vlad Țepeș',
			'Vârciorog',
			'Vânători',
			'Vânători',
			'Viișoara',
			'Vatra Dornei',
			'Vaşcău',
			'Vălenii Șomcutei',
			'Valea Părului',
			'Valea Mare-Podgoria',
			'Valea Dulce',
			'Vadu Izei',
			'Unirea',
			'Uioara de Jos',
			'Udeşti',
			'Tunelu-Teliu',
			'Trifeşti',
			'Țolici',
			'Titeşti',
			'Ţicleni',
			'Teremia Mare',
			'Talpoș',
			'Talpa-Ogrăzile',
			'Tabăra',
			'Suplac',
			'Stoina',
			'Stâna',
			'Stamate',
			'Socodor',
			'Sângeru',
			'Sfinţeşti',
			'Scutelnici',
			'Scurtești',
			'Săveni',
			'Săvădisla',
			'Satu Mare',
			'Satchinez',
			'Săruleşti',
			'Salonta',
			'Sălătrucu',
			'Romos',
			'Recea Cristur',
			'Racoviţa',
			'Prundu Bârgăului',
			'Prăjești',
			'Poienile-Mogoş',
			'Poeni',
			'Plopeni',
			'Plătăreşti',
			'Petriș',
			'Pericei',
			'Peregu Mic',
			'Paleu',
			'Pădurea Neagră',
			'Ostrovu Mare',
			'Ostrov',
			'Orlea Nouă',
			'Oraşu Nou',
			'Orășeni-Deal',
			'Oprişor',
			'Novaci',
			'Negri',
			'Neaua',
			'Munteni Buzău',
			'Muntele Mic',
			'Muncelu de Sus',
			'Motru',
			'Moldoveni',
			'Milaş',
			'Merișani',
			'Mătăsaru',
			'Marga',
			'Mălini',
			'Lunca',
			'Livezeni',
			'Livadia',
			'Liubcova',
			'Lieşti',
			'Laza',
			'Laslea',
			'Jorăşti',
			'Jitia',
			'Jilava',
			'Ipatele',
			'Ineu',
			'Icuseşti',
			'Humele',
			'Horezu',
			'Hârtieşti',
			'Hidişelu de Sus',
			'Gugești',
			'Glodeanu-Sărat',
			'Gârcina',
			'Gâldău',
			'Geoagiu',
			'Galbeni',
			'Fundeni',
			'Frăsinet',
			'Dunăreni',
			'Duda',
			'Dranovățu',
			'Drăguşeni',
			'Dragomireşti-Vale',
			'Dragomir',
			'Drăghici',
			'Drăghiceni',
			'Drăgăneşti de Vede',
			'Deaj',
			'Dărmăneşti',
			'Cuzap',
			'Curtea',
			'Crizbav',
			'Cristian',
			'Crevenicu',
			'Craidorolţ',
			'Costişa',
			'Coşeşti',
			'Coşeiu',
			'Cornetu',
			'Corcova',
			'Conţeşti',
			'Cocorăștii Mislii',
			'Ciuperceni',
			'Ciocârlia',
			'Chiuza',
			'Cheţani',
			'Cezieni',
			'Cetatea de Baltă',
			'Cergău Mare',
			'Celaru',
			'Cehal',
			'Cataloi',
			'Caţa',
			'Cărpiniș',
			'Butoieşti',
			'Buneşti',
			'Budeşti',
			'Budeni',
			'Budacu de Sus',
			'Bucureşci',
			'Brăneşti',
			'Borleşti',
			'Bogei',
			'Bogdăneşti',
			'Bogda',
			'Boboc',
			'Bâcleș',
			'Berevoeşti',
			'Bechet',
			'Bara',
			'Băile Borșa',
			'Babeţi',
			'Arpașu de Sus',
			'Argetoaia',
			'Aninoasa',
			'Anieș',
			'Amara',
			'Adunații-Copăceni',
			'I. C. Brătianu',
			'Maliuc',
			'Haneș Mină',
			'Zorlenţu Mare',
			'Zebil',
			'Zăbala',
			'Vintileanca',
			'Viişoara',
			'Vidra',
			'Victoria',
			'Vărădia de Mureş',
			'Vama',
			'Vălenii de Mureș',
			'Ştefan Cel Mare',
			'Valea Merilor',
			'Valea Largă',
			'Vadu Izei',
			'Vădeni',
			'Urca',
			'Arini',
			'Traian',
			'Traian',
			'Târgu Jiu',
			'Telec',
			'Tăbărăști',
			'Surdila-Greci',
			'Suciu de Sus',
			'Stoileşti',
			'Stoenești',
			'Şoldanu',
			'Sârbeni',
			'Sânsimion',
			'Sânpaul',
			'Secaş',
			'Schitu',
			'Văleni',
			'Sărata',
			'Samarineşti',
			'Salcia',
			'Rovine',
			'Roșcani',
			'Râşca',
			'Remetea',
			'Reghin',
			'Rediu',
			'Răzvani',
			'Răuseni',
			'Răcoasa',
			'Putineiu',
			'Stănești',
			'Popeşti',
			'Pogonele',
			'Pogana',
			'Pochidia',
			'Ploieşti',
			'Ploieștiori',
			'Piscu Nou',
			'Pildești',
			'Perieni',
			'Pătuleni',
			'Parța',
			'Paltin',
			'Otelec',
			'Orăştie',
			'Optaşi',
			'Obrejița',
			'Nicoreşti',
			'Nehoiu',
			'Munteni',
			'Mândrești',
			'Mărăcineni',
			'Malovăţ',
			'Malaia',
			'Măieruş',
			'Măguri',
			'Luncoiu de Jos',
			'Luizi-Călugăra',
			'Livadea',
			'Jidoștița',
			'Izvoarele Sucevei',
			'Hurezani',
			'Hodoşa',
			'Gruia',
			'Grăjdana',
			'Grădiştea',
			'Gostinu',
			'Goruia',
			'Goicea',
			'Giuleşti',
			'Gârbova',
			'Ghimbav',
			'Ghermănești',
			'Gheorghe Doja',
			'Găzărie',
			'Garvăn',
			'Găgeni',
			'Furtunești',
			'Furculești',
			'Frumosu',
			'Falcău',
			'Făgeţelu',
			'Drăgăşani',
			'Darova',
			'Dăneasa',
			'Dănciuleşti',
			'Dămăcușeni',
			'Cucerdea',
			'Crivești',
			'Cristeștii Ciceului',
			'Corocăiești',
			'Conțești',
			'Cocu',
			'Cârţişoara',
			'Cioranii de Jos',
			'Cheț',
			'Cenad',
			'Cătina',
			'Carei',
			'Cărbunari',
			'Caransebeş',
			'Calomfirești',
			'Buhalnița',
			'Buești',
			'Buduslău',
			'Budeşti',
			'Bucovăţ',
			'Brusturi',
			'Brazi',
			'Bratovoești',
			'Braniştea',
			'Brăeşti',
			'Brădeşti',
			'Boroşneu Mare',
			'Borlova',
			'Bâscenii de Jos',
			'Bârseşti',
			'Berislăveşti',
			'Berchișești',
			'Beica de Jos',
			'Barticești',
			'Bârgăuani',
			'Băleni Sârbi',
			'Bajura',
			'Băbana',
			'Apaţa',
			'Aiudul de Sus',
			'Agrieș',
			'Afumaţi',
			'Dorna-Arini',
			'Ziduri',
			'Vulcan',
			'Vlădeni',
			'Vânju-Mare',
			'Vinderei',
			'Vânători',
			'Vicovu de Sus',
			'Vedea',
			'Vaţa de Jos',
			'Valea Teilor',
			'Valea Mare',
			'Valea Mare',
			'Valea Caselor',
			'Valcău de Jos',
			'Vădurele',
			'Urseni',
			'Uda',
			'Ucea de Sus',
			'Turulung',
			'Trăisteni',
			'Traian',
			'Totoești',
			'Târgu Frumos',
			'Tătărani',
			'Tănăsoaia',
			'Slobozia',
			'Slănic',
			'Sineşti',
			'Siliștea',
			'Şilindia',
			'Șerbănești',
			'Seleuş',
			'Şelaru',
			'Sebiş',
			'Seaca',
			'Schitu Frumoasa',
			'Săucești',
			'Șaroș pe Târnave',
			'Sălcuţa',
			'Sadova',
			'Sabasa',
			'Ruginoasa',
			'Roșiori',
			'Purcăreni',
			'Priboieni',
			'Porumbacu de Jos',
			'Poienile',
			'Poiana',
			'Piscu',
			'Păuneşti',
			'Păltineni',
			'Pădureni',
			'Orlat',
			'Odobeşti',
			'Oarţa de Jos',
			'Niţchidorf',
			'Nicolae Bălcescu',
			'Năeni',
			'Mușătești',
			'Mlenăuți',
			'Merişani',
			'Măxineni',
			'Marginea',
			'Măieru',
			'Măicăneşti',
			'Măgireşti',
			'Măderat',
			'Lupşanu',
			'Luncaviţa',
			'Lugaşu de Jos',
			'Lovrin',
			'Lopadea Nouă',
			'Lăzarea',
			'Lădeşti',
			'Jugureanu',
			'Ipoteşti',
			'Iojib',
			'Inotești',
			'Independenţa',
			'Igești',
			'Hilişeu-Horia',
			'Hărmăneștii Vechi',
			'Halânga',
			'Gura Viţioarei',
			'Giuvărăşti',
			'Gâlgău Almaşului',
			'Gâdinţi',
			'Ghimeș',
			'Ghilad',
			'Ghermănești',
			'Gheja',
			'Geaca',
			'Gănești',
			'Găleşti',
			'Fundeni',
			'Fântânele',
			'Fântânele',
			'Dumitrești',
			'Dumbrăveni',
			'Dumbrava',
			'Dumbrava',
			'Drăgoeşti',
			'Dobridor',
			'Doanca',
			'Dâmbovicioara',
			'Dieci',
			'Dealu Morii',
			'Dagâţa',
			'Cupşeni',
			'Crişcior',
			'Crăciunei',
			'Cosâmbeşti',
			'Clocotici',
			'Clinceni',
			'Ciulniţa',
			'Cârlibaba',
			'Ciacova',
			'Chiochiş',
			'Chereluș',
			'Ceru-Băcăinţi',
			'Certeju de Sus',
			'Cernavodă',
			'Cermei',
			'Cepari',
			'Ceatalchioi',
			'Cartojani',
			'Caraşova',
			'Căbeşti',
			'Buda',
			'Brusturi',
			'Brestovăț',
			'Brăneşti',
			'Boiu Mare',
			'Bocşa',
			'Bistriţa Bârgăului',
			'Band',
			'Bancu',
			'Balaciu',
			'Baia Sprie',
			'Axente Sever',
			'Atid',
			'Aştileu',
			'Aroneanu',
			'Ariceștii Zeletin',
			'Aninoasa',
			'Aluniş',
			'Aleşd',
			'Albeni',
			'Adășeni',
			'Mârșa',
			'Paşcani',
			'Crișan',
			'Zemeş',
			'Vrata',
			'Voiteștii din Vale',
			'Vlădești',
			'Vitănești',
			'Vișeu de Mijloc',
			'Vârtoapele de Jos',
			'Vârfu Câmpului',
			'Victoria',
			'Văleni-Podgoria',
			'Valea Mare Pravăț',
			'Valea Arinilor',
			'Ungureni-Jianu',
			'Umbrăreşti',
			'Tur',
			'Tufeni',
			'Tilişca',
			'Ţigănaşi',
			'Tăuții-Măgherăuș',
			'Tauţ',
			'Tăureni',
			'Tătaru',
			'Tătărăștii de Sus',
			'Tărtăşeşti',
			'Suseni',
			'Stroeşti',
			'Stângăceaua',
			'Stăuceni',
			'Spulber',
			'Sovata',
			'Somușca',
			'Socetu',
			'Şoarş',
			'Slobozia',
			'Sântămăria-Orlea',
			'Şincai',
			'Șimon',
			'Siminicea',
			'Sâmbotin',
			'Sâmbăteni',
			'Siliştea',
			'Şeitin',
			'Scrioaştea',
			'Scânteiești',
			'Schela',
			'Salva',
			'Sălişte',
			'Sălcioara',
			'Sălaşu de Sus',
			'Şag',
			'Săcele',
			'Săcel',
			'Rona de Sus',
			'Robeasca',
			'Rimetea',
			'Repedea',
			'Recea',
			'Perişani',
			'Peciu Nou',
			'Patru Frați',
			'Osebiți',
			'Oradea',
			'Olanu',
			'Ogretin',
			'Oanțu',
			'Nicolae Bălcescu',
			'Negrești',
			'Mugeni',
			'Movileni',
			'Mihai Viteazu',
			'Mihail Kogălniceanu',
			'Mihăeşti',
			'Mavrodin',
			'Mărtiniş',
			'Marpod',
			'Măneciu-Ungureni',
			'Măgurele',
			'Lupeni',
			'Lunca',
			'Lipniţa',
			'Lipia',
			'Lehliu-Gară',
			'Lazuri',
			'Jupâneşti',
			'Jamu Mare',
			'Ion Corvin',
			'Însurăţei',
			'Iara',
			'Hotarele',
			'Homocea',
			'Gura Văii',
			'Gropșani',
			'Grădina',
			'Glodeni',
			'Gârla-Mare',
			'Gilău',
			'Găvănești',
			'Galoșpetreu',
			'Focșani',
			'Florica',
			'Filiaș',
			'Fildu de Jos',
			'Făcăeni',
			'Dudeștii Noi',
			'Drăgugești',
			'Drăgăneşti',
			'Dorobanțu',
			'Doiceşti',
			'Dobrotești',
			'Derșida',
			'Deleşti',
			'Dejești',
			'Dascălu',
			'Dancu',
			'Dămuc',
			'Dăbuleni',
			'Culciu Mic',
			'Crețești',
			'Coțofenii din Față',
			'Cosmeşti',
			'Corlătești',
			'Cleanov',
			'Ciudanoviţa',
			'Chitila',
			'Ceparii Pământeni',
			'Călina',
			'Buzoeşti',
			'Bughea de Jos',
			'Bucșești',
			'Brehuiești',
			'Bordeşti',
			'Boian',
			'Bocşa',
			'Blejeşti',
			'Beregsău Mare',
			'Beliş',
			'Bărbăteşti',
			'Bănişor',
			'Baia de Aramă',
			'Augustin',
			'Amaru',
			'Albeștii Ungureni',
			'Adunaţi',
			'Adânca',
			'Smârdan',
			'Gheorghe Doja',
			'Zimnicea',
			'Vultureşti',
			'Vişineşti',
			'Vâlcele',
			'Verneşti',
			'Valea Salciei',
			'Valea Mare',
			'Valea Chioarului',
			'Vad',
			'Urecheni',
			'Uivar',
			'Tudor Vladimirescu',
			'Toplița',
			'Topliceni',
			'Tomşani',
			'Tomești',
			'Todireni',
			'Tetoiu',
			'Tețcoiu',
			'Tansa',
			'Suhaia',
			'Strugari',
			'Stejari',
			'Ştefan cel Mare',
			'Spanţov',
			'Şoimuş',
			'Şofronea',
			'Șivița',
			'Şirineasa',
			'Sârbii-Măgura',
			'Sintea Mare',
			'Sântana',
			'Sânmartin',
			'Sângeorgiu de Pădure',
			'Sâg',
			'Șieu',
			'Semlac',
			'Săuleşti',
			'Sălătrucel',
			'Săcăşeni',
			'Roşia de Amaradia',
			'Roşia',
			'Roma',
			'Rediu',
			'Recea',
			'Rasova',
			'Radomir',
			'Răcăuți',
			'Răbăgani',
			'Puchenii Mari',
			'Priboiu',
			'Potigrafu',
			'Potcoava',
			'Popeşti',
			'Plopu',
			'Pir',
			'Păuşeşti-Măglaşi',
			'Păuliş',
			'Pădureni',
			'Osica de Jos',
			'Orodel',
			'Olănești',
			'Ohaba Lungă',
			'Negoiești',
			'Năvodari',
			'Năpradea',
			'Nalbant',
			'Moviliţa',
			'Moșnița Veche',
			'Moneasa',
			'Moceşti',
			'Moara Carp',
			'Miroslava',
			'Mânăstireni',
			'Miercurea Nirajului',
			'Mătăcina',
			'Mădăraş',
			'Macea',
			'Lupac',
			'Lunca Prahovei',
			'Livada',
			'Lipia',
			'Lipăneşti',
			'Izvoru',
			'Leliceni',
			'Lăzăreni',
			'Lăpuşnicu Mare',
			'Jebel',
			'Izvoarele',
			'Izvoarele',
			'Irești',
			'Ion Neculce',
			'Iaşi',
			'Ianca',
			'Hălărești',
			'Grindu',
			'Grabăț',
			'Goșmani',
			'Giurtelecu Șimleului',
			'Ghinești',
			'Ghidici',
			'Gherța Mare',
			'Gheorghe Doja',
			'Gemenea Brătulești',
			'Gălăţeni',
			'Frasin',
			'Fârliug',
			'Dridu',
			'Drăganu-Olteni',
			'Dorobanțu',
			'Dor Mărunt',
			'Domănești',
			'Dobârlău',
			'Dioșod',
			'Diosig',
			'Dersca',
			'Dedulești',
			'Cugir',
			'Criciova',
			'Crasna Vișeului',
			'Cozmești',
			'Cozmeni',
			'Corni-Albești',
			'Cornea',
			'Corbu Nou',
			'Corbu',
			'Comanca',
			'Coltău',
			'Cocoreni',
			'Clejani',
			'Ciutelec',
			'Cioroboreni',
			'Ciocănari',
			'Cincu',
			'Câinenii Mici',
			'Chintinici',
			'Cetatea',
			'Cernetu',
			'Buzău',
			'Burueneşti',
			'Bulbucata',
			'Buftea',
			'Bucium-Orlea',
			'Buciumeni',
			'Brătești',
			'Bordeasca Veche',
			'Borănești',
			'Bolintin Deal',
			'Bobulești',
			'Bistreț',
			'Bârza',
			'Belceşti',
			'Bătarci',
			'Basarabi',
			'Banca',
			'Bălileşti',
			'Băişoara',
			'Baciu',
			'Arcani',
			'Aghireșu',
			'Zizin',
			'Zimnicele',
			'Vultureni',
			'Voila',
			'Vlădeni',
			'Vişina',
			'Vipereşti',
			'Vermești',
			'Vărădia',
			'Valea Seacă',
			'Valea Cireșului',
			'Ungureni',
			'Unguraş',
			'Turburea',
			'Tudora',
			'Treznea',
			'Topolița',
			'Tomnatic',
			'Ţinteşti',
			'Tânganu',
			'Teleşti',
			'Tătăruşi',
			'Tătărani',
			'Suseni',
			'Şuletea',
			'Suhurlui',
			'Suceagu',
			'Şpring',
			'Slobozia Moara',
			'Slănic',
			'Șisești',
			'Sânnicolau Mare',
			'Sânmărghita',
			'Sâmbăta',
			'Siculeni',
			'Sfântu Gheorghe',
			'Șerbănești',
			'Schitu-Duca',
			'Săsar',
			'Sălciua de Sus',
			'Săceni',
			'Runcu Salvei',
			'Roșiori',
			'Românești',
			'Românești',
			'Râmnicu Sărat',
			'Râmnicelu',
			'Remetea',
			'Reghin-Sat',
			'Recea',
			'Războienii de Jos',
			'Pușcași',
			'Posada',
			'Popești',
			'Ponoarele',
			'Poiana Mare',
			'Plopii Slăviţeştí',
			'Platonești',
			'Pietrele',
			'Piatra',
			'Coronini',
			'Perșani',
			'Perişoru',
			'Peretu',
			'Palanca',
			'Orşova',
			'Orleşti',
			'Oraviţa',
			'Nojorid',
			'Nisipari',
			'Nimigea de Jos',
			'Nadăș',
			'Modelu',
			'Mârţeşti',
			'Mironeasa',
			'Mihail Kogălniceanu',
			'Mica',
			'Mărgineni Slobozia',
			'Mara',
			'Mănăștur',
			'Malu Spart',
			'Mădăraș',
			'Lozna',
			'Liteni',
			'Lișteava',
			'Lazuri',
			'Lapoș',
			'Jieni',
			'Izvoru',
			'Iveşti',
			'Isverna',
			'Iratoşu',
			'Ilba',
			'Husasău de Tinca',
			'Horodnic de Sus',
			'Hlăpești',
			'Hălăuceşti',
			'Gura Ocniței',
			'Groșani',
			'Grădiştea',
			'Grădiștea',
			'Gogoşari',
			'Giurgiţa',
			'Ghidfalău',
			'Gheboieni',
			'Galiciuica',
			'Gălăuţaş',
			'Galaţi',
			'Fârţăneşti',
			'Fântânele',
			'Feteşti',
			'Feliceni',
			'Făureşti',
			'Fărcăşeşti',
			'Dumbrăviţa',
			'Dulceşti',
			'Drăgoeşti-Snagov',
			'Dracșani',
			'Dorgoş',
			'Dodeni',
			'Dobrușa',
			'Dobrotinet',
			'Dobreţu',
			'Dichiseni',
			'Devesel',
			'Deleni',
			'Deda',
			'Dealu Aluniș',
			'Dănceu',
			'Cucuieți',
			'Coteşti',
			'Coroieşti',
			'Corni',
			'Corni',
			'Constantin Daicoviciu',
			'Cojasca',
			'Cârţa',
			'Ciorteşti',
			'Cioranii de Sus',
			'Ciorani',
			'Cândeşti',
			'Câmpulung',
			'Cidreag',
			'Cerneşti',
			'Calopăr',
			'Burila Mare',
			'Bunteşti',
			'Bucu',
			'Braniştea',
			'Braniştea',
			'Bolovăniș',
			'Bogdănești',
			'Blăjani',
			'Bărbăteşti',
			'Băluşeni',
			'Asuaju de Sus',
			'Aprozi',
			'Anghelești',
			'Racoș',
		],
	},
	'Russian Federation': {
		country: 'Russian Federation',
		cities: [
			'Zmiyëvka',
			'Zlatoust',
			'Zelenokumsk',
			'Zavetnoye',
			'Zaprudnya',
			'Yepifan’',
			'Yelan’-Kolenovskiy',
			'Yavas',
			'Vostochnyy',
			'Velikodvorskiy',
			'Ust’-Donetskiy',
			'Tula',
			'Tlyarata',
			'Taman’',
			'Svetlogorsk',
			'Sukhodol',
			'Staroye Drozhzhanoye',
			'Staroshcherbinovskaya',
			'Starodub',
			'Stanovoye',
			'Sosnovaya Polyana',
			'Solomenskoye',
			'Snegiri',
			'Siukh',
			'Mikhaylovsk',
			'Shemursha',
			'Shaturtorf',
			'Shalushka',
			'Semikarakorsk',
			'Sebezh',
			'Sazonovo',
			'Sadovoye',
			'Putyatino',
			'Protvino',
			'Primorka',
			'Primorsk',
			'Pochep',
			'Pechory',
			'Pamyat’ Parizhskoy Kommuny',
			'Oster',
			'Osinki',
			'Omutninsk',
			'Ol’gino',
			'Nurma',
			'Nurlat',
			'Novoye Leushino',
			'Nikulino',
			'Neverkino',
			'Nelazskoye',
			"Nazar'yevo",
			'Murino',
			'Muranovo',
			'Mozdok',
			'Maykop',
			'Manas',
			'Malakhovka',
			'Lopatinskiy',
			'Lopandino',
			'Liski',
			'Levashevo',
			'Lesnoy',
			'Lesnoy',
			'Lefortovo',
			'Kungur',
			'Kuleshovka',
			'Krasnyy Oktyabr’',
			'Krasnoyarskiy',
			'Krasnoslobodsk',
			'Kozel’sk',
			'Kotovo',
			'Kopanskaya',
			"Komsomol'skoye",
			'Kikerino',
			'Khosta',
			'Kez',
			'Kenzhe',
			'Karagach',
			'Kamenka',
			'Kamenka',
			'Kamenetskiy',
			'Izmalkovo',
			'Elin-Yurt',
			'Gergebil’',
			'Engels',
			'Elektrougli',
			'Dzerzhinskiy',
			'Dyurtyuli',
			'Deshovki',
			'Danilovka',
			'Chertkovo',
			'Chërnaya Kholunitsa',
			'Borovoy',
			'Bor',
			'Bol’shoye Gryzlovo',
			'Berezayka',
			'Bavleny',
			'Barsukovskaya',
			'Balakovo',
			'Apastovo',
			'Anastasiyevka',
			'Anapskaya',
			'Aleksandrovskaya',
			'Alatyr’',
			'Staraya Akkermanovka',
			'Achikulak',
			'Il’inskoye',
			'Atlashevo',
			'Ileza',
			'Znamensk',
			'Tyube',
			'Zarechnyy',
			'Yarovoye',
			'Vorgashor',
			'Verkhneye Dubrovo',
			'Uspenka',
			'Turukhansk',
			'Toguchin',
			'Tisul’',
			'Tinskoy',
			'Teeli',
			'Roza',
			'Promyshlennovskiy',
			'Nyda',
			'Nizhnyaya Omka',
			'Linëvo',
			'Khulimsunt',
			'Kemerovo',
			'Kedrovoye',
			'Dvurechensk',
			'Dikson',
			'Chesma',
			'Asino',
			'Antipino',
			'Abaza',
			'Abagur',
			'Lyantor',
			'Zalari',
			'Yakutsk',
			'Vozdvizhenka',
			'Ust’-Ilimsk',
			'Solnechnyy',
			'Sivaki',
			'Rettikhovka',
			'Razdol’noye',
			'Novobureyskiy',
			'Nerchinsk',
			'Mishelevka',
			'Leninskoye',
			'Kurumkan',
			'Khilok',
			'Karymskoye',
			'Gornyy',
			'Dzhebariki-Khaya',
			'Bol’shaya Tura',
			'Balakhninskiy',
			'Ayan',
			'Severo-Kuril’sk',
			'Chokurdakh',
			'Arman’',
			'Stan-Bekhtemir',
			'Kievskiy',
			'Lesnoy',
			'Blyasino',
			'Kusak',
			'Chernaya Rechka',
			'Im. Telmana Posyolok',
			'Zilair',
			'Zhukovsky',
			'Zhiryatino',
			'Zarechnyy',
			'Yertsevo',
			'Yemetsk',
			'Yel’digino',
			'Vsevolozhsk',
			'Vorob’yovo',
			'Urukh',
			'Urshel’skiy',
			'Udel’naya',
			'Tuma',
			'Tsentoroy',
			'Tosno',
			'Tolyatti',
			'Tambovka',
			'Talashkino',
			'Syrskoye',
			'Sura',
			'Starokorsunskaya',
			'Spas-Klepiki',
			'Kashkhatau',
			'Solotcha',
			'Skopin',
			'Shvartsevskiy',
			'Shëlkovskaya',
			'Severnyy',
			'Sengiley',
			'Sebrovo',
			'Satinka',
			'Sarany',
			'Sachkovichi',
			'Rzhaksa',
			'Ryzdvyanyy',
			'Ruzayevka',
			'Roshal’',
			'Rogovskaya',
			'Pushkin',
			'Pskov',
			'Prochnookopskaya',
			'Pokrovskoye',
			'Poim',
			'Podnov’ye',
			'Plavsk',
			'Platonovka',
			'Petrovo-Dal’neye',
			'Pervomayskiy',
			'Perepravnaya',
			'Pelagiada',
			'Pavelets',
			'Otradnaya',
			'Orsha',
			'Odintsovo',
			'Ochër',
			'Novyy Karachay',
			'Novyye Cherëmushki',
			'Novodugino',
			'Novaya Lyada',
			'Nizhneye Kazanishche',
			'Nikol’sk',
			'Mytishchi',
			'Murmashi',
			'Mullovka',
			'Milyutinskaya',
			'Miloslavskoye',
			'Mariinskiy Posad',
			'Lyubovsho',
			'Luga',
			'Likhovskoy',
			'Lianozovo',
			'Laptëvo',
			'Kuzino',
			'Kumysh',
			'Kubanskiy',
			'Biryulëvo Zapadnoye',
			'Krasnaya Polyana',
			'Krasnaya Gorka',
			'Kraskovo',
			'Kotel’niki',
			'Koptëvo',
			'Kolpino',
			'Kolomyagi',
			'Klyavlino',
			'Kirovo-Chepetsk',
			'Khoroshëvo-Mnevniki',
			'Khatukay',
			'Khabez',
			'Kaspiyskiy',
			'Kashira',
			'Kapustin Yar',
			'Kamenolomni',
			'Kaduy',
			'Irgakly',
			'Gubkin',
			'Gubden',
			'Golubitskaya',
			'Giaginskaya',
			'El’ton',
			'Dorgeli',
			'Donskoye',
			'Dolgoye',
			'Divnoye',
			'Demyansk',
			'Demidov',
			'Davydkovo',
			'Danki',
			'Chesnokovka',
			'Chermoz',
			'Buturlino',
			'Blagodatnoye',
			'Belgatoy',
			'Batetskiy',
			'Babino',
			'Anopino',
			'Alkhan-Kala',
			'Akhtyrskiy',
			'Surok',
			'Belidzhi',
			'Priladozhskiy',
			'Yermakovskoye',
			'Ulagan',
			'Ust’-Koksa',
			'Tavricheskoye',
			'Talnakh',
			'Sukhobuzimskoye',
			'Sheregesh',
			'Poykovskiy',
			'Poltavka',
			'Omsk',
			'Novaya Zaimka',
			'Novosineglazovskiy',
			'Mugur-Aksy',
			'Mel’nikovo',
			'Lokosovo',
			'Kyzyl-Khaya',
			'Krasnogorskiy',
			'Klyuchevsk',
			'Kedrovka',
			'Kargasok',
			'Idrinskoye',
			'Dolgoderevenskoye',
			'Biskamzha',
			'Beloyarsk',
			'Ak-Dovurak',
			'Vershino-Darasunskiy',
			'Tulyushka',
			'Spassk-Dal’niy',
			'Raychikhinsk',
			'Peleduy',
			'Novopavlovka',
			'Nizhniy Tsasuchey',
			'Kyakhta',
			'Kropotkin',
			'Kraskino',
			'Chernyshëvka',
			'Baykal’sk',
			'Vilyuchinsk',
			'Susuman',
			'Sinegorsk',
			'Esso',
			'Izmaylovo',
			'Langepas',
			'Znamensk',
			'Zayukovo',
			'Zavetnoye',
			'Yëlkino',
			'Yazykovo',
			'Yanishpole',
			'Yandyki',
			'Vykhino-Zhulebino',
			'Vygonichi',
			'Vuktyl',
			'Vol’sk',
			'Volgograd',
			'Vardane',
			'Ulyanovsk',
			'Tyulyachi',
			'Tugolesskiy Bor',
			'Surovikino',
			'Subkhankulovo',
			'Stroitel',
			'Staraya Sunzha',
			'Staraya',
			'Sosnovka',
			'Solikamsk',
			'Shlissel’burg',
			'Sheksna',
			'Tugulym',
			'Spassk',
			'Sovetskoye',
			'Sos’va',
			'Sokolovo',
			'Slavgorodskoye',
			'Shadrinsk',
			'Shabrovskiy',
			'Prokop’yevsk',
			'Okoneshnikovo',
			'Mayma',
			'Kyzyl',
			'Karagayla',
			'Inya',
			'Igrim',
			'Cherëmushki',
			'Bor',
			'Berëzovka',
			'Baykalovo',
			'Bayevo',
			'Askiz',
			'Malinovskiy',
			'Gubkinskiy',
			'Zharikovo',
			'Zavodskoy',
			'Yerofey Pavlovich',
			'Yekaterinoslavka',
			'Ust’-Uda',
			'Russkiy',
			'Rudnogorsk',
			'Putyatin',
			'Orlovskiy',
			'Okino-Klyuchi',
			'Neryungri',
			'Lyalichi',
			'Londoko',
			'Livadiya',
			'Kul’dur',
			'Khandyga',
			'Khabarovsk',
			'Irkutsk',
			'Chuguyevka',
			'Borogontsy',
			'Bol’shoy Kunaley',
			'Bayanday',
			'Balyaga',
			'Amga',
			'Yelizovo',
			'Leonidovo',
			'Kholodnyy',
			'Kholmsk',
			'Bykov',
			'Donskoye',
			'Izluchinsk',
			'Blagovetschenskaya',
			'Tanais',
			'Serafimovskiy',
			'Sel’tso',
			'Saransk',
			'Sapërnyy',
			'Rybatskoye',
			'Rozhdestveno',
			'Romanovka',
			'Revda',
			'Rakhmanovo',
			'Pogar',
			'Podolsk',
			'Pochinok',
			'Petrovsk',
			'Petrovsk',
			'Pestovo',
			'Pervomayskoye',
			'Bakhchivandzhi',
			'Pavlovskaya',
			'Palekh',
			'Staryye Ozinki',
			'Ol’ginskaya',
			'Nizhnyaya Irga',
			'Nikolayevka',
			'Neftegorsk',
			'Mukhtolovo',
			'Mizur',
			'Medvedovskaya',
			'Malaya Dubna',
			'Lys’va',
			'Lysogorskaya',
			'Lokot’',
			'Levikha',
			'Leninsk',
			'Kushnarënkovo',
			'Kurovskoye',
			'Krivtsovo',
			'Krasnyye Chetai',
			'Krasnye Baki',
			'Kozhva',
			'Kovdor',
			'Konstantinovo',
			'Koltushi',
			'Kobrinskoye',
			'Kirzhach',
			'Kimry',
			'Kapotnya',
			'Kamyshevatskaya',
			'Kaluga',
			'Kalnibolotskaya',
			'Korolev',
			'Kagal’nitskaya',
			'Issa',
			'Il’skiy',
			'Gritsovskiy',
			'Grakhovo',
			'Gordeyevka',
			'Girey',
			'Filimonki',
			'Fili',
			'Engel’-Yurt',
			'Dukhovshchina',
			'Dubovyy Umyot',
			'Druzhba',
			'Deyskoye',
			'Dankov',
			'Chontaul',
			'Chamzinka',
			'Bykovo',
			'Borskoye',
			'Borisovka',
			'Bolokhovo',
			'Bizhbulyak',
			'Bessonovka',
			'Berëznik',
			'Beloretsk',
			'Beloostrov',
			'Belomorsk',
			'Bekovo',
			'Bazarnyy Syzgan',
			'Armavir',
			'Arkhipovka',
			'Argun',
			'Abinsk',
			'Stepnoye',
			'Bologoye-4',
			'Zubutli-Miatli',
			'Uchkent',
			'Yurginskoye',
			'Yetkul’',
			'Vengerovo',
			'Ust’-Ishim',
			'Serzhen’-Yurt',
			'Selezni',
			'Sapozhok',
			'Samara',
			'Safonovo',
			'Rumyantsevo',
			'Rudnya',
			'Rasskazovo',
			'Rabotki',
			'Pyra',
			'Priozërsk',
			'Primorsk',
			'Porech’ye-Rybnoye',
			'Podyuga',
			'Plastunovskaya',
			'Peski',
			'Pervomaysk',
			'Pavlovka',
			'Pankovka',
			'Novyy Yegorlyk',
			'Novyye Lyady',
			'Novyye Burasy',
			'Nesterov',
			'Nerekhta',
			'Nekrasovskiy',
			'Neftegorsk',
			'Navoloki',
			'Alpatovo',
			'Naberezhnyye Chelny',
			'Muchkapskiy',
			'Menzelinsk',
			'Melikhovo',
			'Melenki',
			'Medyn',
			'Mayskiy',
			'Mayrtup',
			'Maromitsa',
			'Maksatikha',
			'Lyubuchany',
			'Lyambir’',
			'Luzhniki',
			'Lukino',
			'Lovlinskaya',
			'Livenka',
			'Likhobory',
			'Ladushkin',
			'Kyakhulay',
			'Kuznechnoye',
			'Kuzhorskaya',
			'Kuvandyk',
			'Kukoboy',
			'Kudymkar',
			'Kresttsy',
			'Krasnovka',
			'Krasnogvardeyskoye',
			'Konobeyevo',
			'Komsomolets',
			'Knyaginino',
			'Klimovsk',
			'Khomutovka',
			'Kazaki',
			'Kardailovo',
			'Kantyshevo',
			'Kamyshla',
			'Ikryanoye',
			'Ibresi',
			'Abram Mys',
			'Gorbunki',
			'Glushkovo',
			'Gamovo',
			'Dombarovskiy',
			'Devitsa',
			'Derbent',
			'Churovichi',
			'Cherepet’',
			'Chekhov',
			'Chechen-Aul',
			'Chashnikovo',
			'Buzdyak',
			'Orekhovo-Borisovo Yuzhnoye',
			'Bol’shoye Murashkino',
			'Bogorodskoye',
			'Beslan',
			'Berdyaush',
			'Belëv',
			'Barybino',
			'Ashukino',
			'Arti',
			'Arkhangel’sk',
			'Ardon’',
			'Ardatov',
			'Andzhiyevskiy',
			'Altukhovo',
			'Alakurtti',
			'Abdulino',
			'Svetlyy',
			'Udarnyy',
			'Putëvka',
			'Khankala',
			'Zelenoborsk',
			'Yaya',
			'Vvedenskoye',
			'Verkh-Suetka',
			'Tolmachëvo',
			'Tashanta',
			'Sysert’',
			'Shira',
			'Moskovskiy',
			'Mochishche',
			'Mezhdurechensk',
			'Melioratorov',
			'Malysheva',
			'Kudryashovskiy',
			'Irbeyskoye',
			'Chervishevo',
			'Chany',
			'Bobrovskiy',
			'Belokurikha',
			'Bayunovskiye Klyuchi',
			'Azovo',
			'Altayskoye',
			'Vidim',
			'Sivakovka',
			'Shelopugino',
			'Seryshevo',
			'Priamurskiy',
			'Poyarkovo',
			'Meget',
			'Lensk',
			'Leninskiy',
			'Kazachinskoye',
			'Kavalerovo',
			'Istok',
			'Gorin',
			'Dzhida',
			'Chernyshevskiy',
			'Bukachacha',
			'Amursk',
			'Aldan',
			'Vanino',
			'Ossora',
			'Klyuchi',
			'Cherskiy',
			'Metrogorodok',
			'Tsiolkovskiy',
			"Vasyl'evsky Ostrov",
			'Zmeyskaya',
			'Zamishevo',
			'Zagoryanskiy',
			'Vyazniki',
			'Vyatskiye Polyany',
			'Vilenka',
			'Ves’yegonsk',
			'Verkhniy Avzyan',
			'Vereya',
			'Valerianovsk',
			'Urmary',
			'Tuchkovo',
			'Tsagan Aman',
			'Syktyvkar',
			'Sviyazhsk',
			'Sulak',
			'Strizhi',
			'Stolbovaya',
			'Sterlitamak',
			'Starozhilovo',
			'Sosnogorsk',
			'Solginskiy',
			'Shchëkino',
			'Sharkan',
			'Shamary',
			'Severnyy-Kospashskiy',
			'Sapernoye',
			'Sambek',
			'Sakharovo',
			'Rublëvo',
			'Reshetnikovo',
			'Radishchevo',
			'Povenets',
			'Poselki',
			'Ponyri Vtoryye',
			'Pervomayskiy',
			'Pereslavl’-Zalesskiy',
			'Peremyshl’',
			'Pashiya',
			'Parma',
			'Otradnyy',
			'Orsk',
			'Orshanka',
			'Orichi',
			'Orël',
			'Oktyabr’sk',
			'Obninsk',
			'Novoul’yanovsk',
			'Novosheshminsk',
			'Novaya Malykla',
			'Novolabinskaya',
			'Novaya Ladoga',
			'Nizhnepavlovka',
			'Nizhniye Sergi',
			'Muyezerskiy',
			'Mostovskoy',
			'Mikhaylovskoye',
			'Mikhaylovka',
			'Mikhalkovo',
			'Michurinskoye',
			'Mayna',
			'Mayachnyy',
			'Mari-Turek',
			'Malka',
			'Lodeynoye Pole',
			'Komendantsky aerodrom',
			'Kumertau',
			'Kulary',
			'Krasnyy Luch',
			'Krasnyy Kut',
			'Krasnomayskiy',
			'Krasnokamsk',
			'Krasnoarmeysk',
			'Kotel’nich',
			'Kochkurovo',
			'Kinel’',
			'Kichmengskiy Gorodok',
			'Khrenovoye',
			'Kayasula',
			'Kama',
			'Kalininsk',
			'Ivot',
			'Inzer',
			'Ignatovka',
			'Gremyachevo',
			'Grecheskoye',
			'Gorskaya',
			'Gornyy',
			'Gornozavodsk',
			'Goragorskiy',
			'Glotovka',
			'Frolovo',
			'Ertil’',
			'Emmaus',
			'Duvan',
			'Dubki',
			'Dolzhanskaya',
			'Diveyevo',
			'Dinskaya',
			'Chemodanovka',
			'Cheboksary',
			'Chapayevsk',
			'Bykovo',
			'Bolkhov',
			'Bolgatovo',
			'Blagodarnyy',
			'Besleney',
			'Berezniki',
			'Berendeyevo',
			'Beloozyorskiy',
			'Bekhteyevka',
			'Bazarnyy Karabulak',
			'Botayurt',
			'Bammatyurt',
			'Babayurt',
			'Atkarsk',
			'Arkul’',
			'Apatity',
			'Alkhan-Yurt',
			'Aleksin',
			'Alekseyevskaya',
			'Net’inka',
			'Lermontovo',
			'Yemel’yanovo',
			'Ust’yanka',
			'Ust’-Bagaryak',
			'Zelenogradsk',
			'Zaymishche',
			'Zainsk',
			'Vyshniy Volochëk',
			'Vostryakovo',
			'Voskresenskoye',
			'Volokonovka',
			'Volodarskogo',
			'Kishcha',
			'Verkhniye Kigi',
			'Verkhnedneprovskiy',
			'Vedeno',
			'Vatutino',
			'Upornaya',
			'Tumbotino',
			'Tumak',
			'Tersa',
			'Temizhbekskaya',
			'Tarnogskiy Gorodok',
			'Svetlyy Yar',
			'Staryye Atagi',
			'Staryy Cherek',
			'Spiridonovka',
			'Sovetskoye',
			'Sobinka',
			'Shipitsyno',
			'Shimsk',
			'Shatki',
			'Shatalovo',
			'Shakhun’ya',
			'Shakhty',
			'Shaburnovo',
			'Shablykino',
			'Tselinnoye',
			'Sychëvka',
			'Sheberta',
			'Severnoye',
			'Partizanskoye',
			'Orlik',
			'Odesskoye',
			'Novikovo',
			'Maslyanino',
			'Mamontovo',
			'Krutikha',
			'Krasnozërskoye',
			'Bograd',
			'Priob’ye',
			'Il’ichevo',
			'Tygda',
			'Petropavlovka',
			'Ovsyanka',
			'Novoraychikhinsk',
			'Novaya Igirma',
			'Sergeyevka',
			'Litovko',
			'Lesozavodsk',
			'Kyren',
			'Klyuchevskiy',
			'Khomutovo',
			'Gusinoozyorsk',
			'Borzya',
			'Berkakit',
			'Berëzovyy',
			'Batagay-Alyta',
			'Alekseyevskaya',
			'Markova',
			'Nogliki',
			'Nikolayevsk-on-Amure',
			'Mamonovo',
			'Zagor’ye',
			'Oktyabr’skiy',
			'Uk',
			'Uglovskoye',
			'Sovkhoznyy',
			'Novochernorechenskiy',
			'Yugorsk',
			'Kharp',
			'Gornyy',
			'Cheremshanka',
			'Serebryanyye Prudy',
			'Savino',
			'Sars',
			'Sarov',
			'Sarmakovo',
			'Sarana',
			'Roshchino',
			'Repino',
			'Radchenko',
			'Pyshchug',
			'Pryamitsyno',
			'Pronsk',
			'Prokhorovka',
			'Priupskiy',
			'Prigorodnoye',
			'Polazna',
			'Planovskoye',
			'Pesochnoye',
			'Peskovka',
			'Perm',
			'Novo-Peredelkino',
			'Ol’khovatka',
			'Oboyan’',
			'Nudol’',
			'Novy',
			'Novoukrainskoye',
			'Novokhopërsk',
			'Novoblagodarnoye',
			'Nizhniy Cherek',
			'Nikolo-Berëzovka',
			'Nelidovo',
			'Nekrasovskoye',
			'Nekrasovskaya',
			'Nalchik',
			'Myatlevo',
			'Mochalishche',
			'Mikulino',
			'Maykopskoye',
			'Maslova Pristan’',
			'Lyubokhna',
			'Lukovetskiy',
			'Luknovo',
			'Lugovaya',
			'Lotoshino',
			'Lev Tolstoy',
			'Levashovo',
			'Kurumoch',
			'Kurskaya',
			'Kurdzhinovo',
			'Kudinovo',
			'Krasnoye-na-Volge',
			'Kovrov',
			'Kortkeros',
			'Kokorevka',
			'Khvoynaya',
			'Kholm-Zhirkovskiy',
			'Khanskaya',
			'Kavkazskaya',
			'Katunino',
			'Ivanovo',
			'Ishkhoy-Yurt',
			'Inza',
			'Imeni Stepana Razina',
			'Il’inskoye',
			'Ilyinogorsk',
			'Gus’-Khrustal’nyy',
			'Grayvoron',
			'Gorodovikovsk',
			'Gorelovo',
			'Gagatli',
			'Glazunovka',
			'Gekhi',
			'Ferapontovo',
			'Dyat’kovo',
			'Darovskoy',
			'Chernolesskoye',
			'Cherkizovo',
			'Bol’shaya Dobrinka',
			'Bokino',
			'Blagoyevo',
			'Bezopasnoye',
			'Belyayevka',
			'Barysh',
			'Barvikha',
			'Aysha',
			'Antipovka',
			'Alekseyevskaya',
			'Russkiy Aktash',
			'Svetlopolyansk',
			'Moskovskiy',
			'Tsibanobalka',
			'Galashki',
			'Zaykovo',
			'Vlasikha',
			'Ust’-Abakan',
			'Turochak',
			'Tayginka',
			'Sukpak',
			'Sukhoy Log',
			'Shitkino',
			'Polovinnoye',
			'Pavlovsk',
			'Novopesterevo',
			'Myski',
			'Megion',
			'Kurgan',
			'Kanashevo',
			'Ivdel’',
			'Plotnikovo',
			'Gari',
			'Dubrovino',
			'Blagoveshchenka',
			'Berëzovskiy',
			'Belyy Yar',
			'Bay-Khaak',
			'Artëmovskiy',
			'Tanzybey',
			'Sibirskiy',
			'Zhemchug',
			'Vershino-Shakhtaminskiy',
			'Ulety',
			'Taldan',
			'Solovjevsk',
			'Solnechnyy',
			'Rudnyy',
			'Onokhoy',
			'Manzurka',
			'Mama',
			'Kimil’tey',
			'Khurba',
			'Kabansk',
			'Chara',
			'Almaznyy',
			'Deputatsky',
			'Kuanda',
			'Talaya',
			'Petropavlovsk-Kamchatsky',
			'Kuril’sk',
			'Aniva',
			'Lorino',
			'Voznesenskiy',
			'Novoorlovsk',
			'Roschinskiy',
			'Zemetchino',
			'Yuzha',
			'Yumaguzino',
			'Yemtsa',
			'Yelat’ma',
			'Yazykovo',
			'Yasnyy',
			'Voznesenskaya',
			'Volzhskiy',
			'Volochayevskoye',
			'Vokhtoga',
			'Vichuga',
			'Vetluzhskiy',
			'Tyrnyauz',
			'Velizh',
			'Valerik',
			'Utevka',
			'Usol’ye',
			'Tsimlyansk',
			'Tolstopal’tsevo',
			'Tëmkino',
			'Tatsinskiy',
			'Tarumovka',
			'Talovskiy',
			'Staraya Mayna',
			'Spitsevka',
			'Sosnovyy Bor',
			'Sol’-Iletsk',
			'Sinyavskoye',
			'Shuya',
			'Sharanga',
			'Shamkhal',
			'Sevsk',
			'Trudarmeyskiy',
			'Tabory',
			'Stukovo',
			'Sayanogorsk',
			'Saryg-Sep',
			'Safakulevo',
			'Rudnichnyy',
			'Prosvet',
			'Osinniki',
			'Nizhnevartovsk',
			'Nalobikha',
			'Manzya',
			'Kurtamysh',
			'Krasnyy Yar',
			'Kozhevnikovo',
			'Kochki',
			'Khovu-Aksy',
			'Kansk',
			'Mys-Kamennyy',
			'Kaltay',
			'Kalinovo',
			'Irtyshskiy',
			'Gramoteino',
			'Elekmonar',
			'Dubinino',
			'Dorogino',
			'Novobirilyussy',
			'Bastan',
			'Asbest',
			'Agirish',
			'Shuvakish',
			'Novoural’sk',
			'Starokamyshinsk',
			'Zima',
			'Vysokogornyy',
			'Ust-Maya',
			'Tura',
			'Srednebelaya',
			'Sotnikovo',
			'Mokhsogollokh',
			'Magan',
			'Kysyl-Syr',
			'Kichera',
			'Kholtoson',
			'Il’inka',
			'Gusinoye Ozero',
			'Dauriya',
			'Chumikan',
			'Arsen’yev',
			'Aginskoye',
			'Yagodnoye',
			'Tilichiki',
			'Posëlok Mar’ino',
			'Chertanovo Yuzhnoye',
			'Kogalym',
			'Semiozerje',
			'Pravohettinskiy',
			'Sary-Tyuz',
			'Salmi',
			'Romodanovo',
			'Pshada',
			'Privodino',
			'Poputnaya',
			'Polyane',
			'Pikalëvo',
			'Petrov Val',
			'Pervomayskoye',
			'Peno',
			'Pechenga',
			'Opechenskiy Posad',
			'Okulovka',
			'Novosemeykino',
			'Novorzhev',
			'Novorozhdestvenskaya',
			'Novopavlovsk',
			'Novyy Izborsk',
			'Nizhniy Chir',
			'Nikolayevsk',
			'Natyrbovo',
			'Myshkin',
			'Mugi',
			'Monino',
			'Mikhaylovskaya',
			'Mikhaylovka',
			'Meshcherino',
			'Mamontovka',
			'Makhalino',
			'Makhachkala',
			'Lyubertsy',
			'Lisiy Nos',
			'Kupavna',
			'Kubinka',
			'Krasavino',
			'Kozlovka',
			'Kizner',
			'Kiyasovo',
			'Kaminskiy',
			'Isakly',
			'Il’inskoye',
			'Il’inskiy Pogost',
			'Gryazi',
			'Gorodishche',
			'Gornyatskiy',
			'Golitsyno',
			'Gofitskoye',
			'Fryazino',
			'Edissiya',
			'Drakino',
			'Digora',
			'Buynaksk',
			'Buturlinovka',
			'Buribay',
			'Bratovshchina',
			'Bogolyubovo',
			'Bedeyeva Polyana',
			'Bataysk',
			'Barsuki',
			'Balashov',
			'Ar’ya',
			'Endirey',
			'Levoberezhnyy',
			'Vysokoye',
			'Yasnaya Polyana',
			'Mirnyy',
			'Dvubratskiy',
			'Usinsk',
			'Yar-Sale',
			'Verkh-Neyvinskiy',
			'Uzhur',
			'Ust’-Kan',
			'Ust’-Isha',
			'Ust’-Charyshskaya Pristan’',
			'Zuyevka',
			'Zimovniki',
			'Zheleznogorsk',
			'Zelenchukskaya',
			'Zalukokoazhe',
			'Yelkhovka',
			'Yartsevo',
			'Yaksatovo',
			'Vydropuzhsk',
			'Vychegodskiy',
			'Voznesenskoye',
			'Voyvozh',
			'Visim',
			'Vishnyakovskiye Dachi',
			'Vinogradnyy',
			'Verkhniy Fiagdon',
			'Verbilki',
			'Uspenskaya',
			'Uritsk',
			'Ulu-Telyak',
			'Tsentral’nyy',
			'Tovarkovo',
			'Torzhok',
			'Tolstoy-Yurt',
			'Tishchenskoye',
			'Adygeysk',
			'Tarasovskiy',
			'Syntul',
			'Sverdlovskiy',
			'Surazh',
			'Suna',
			'Sudogda',
			'Staropavlovskaya',
			'Staronizhestebliyevskaya',
			'Starodubskoye',
			'Siva',
			'Sinyavino',
			'Novosin’kovo',
			'Sibay',
			'Shchigry',
			'Sargazy',
			'Novoasbest',
			'Gal’bshtadt',
			'Nadym',
			'Monetnyy',
			'Mikhaylovskoye',
			'Mayna',
			'Lesnoye',
			'Kupino',
			'Gryaznovskoye',
			'Golyshmanovo',
			'Chunskiy',
			'Shipunovo',
			'Serov',
			'Krasnyy Yar',
			'Bystryanka',
			'Bulanash',
			'Biysk',
			'Belovo',
			'Balakhta',
			'Snezhinsk',
			'Zakamensk',
			'Tarbagatay',
			'Taksimo',
			'Sretensk',
			'Novokruchininskiy',
			'Monastyrishche',
			'Luchki',
			'Lipovtsy',
			'Ekimchan',
			'Berdigestyakh',
			'Anuchino',
			'Amurzet',
			'Shebunino',
			'Boshnyakovo',
			'Mosrentgen',
			'Sredniy',
			'Kalininskiy',
			"Ol'yavidovo",
			'Severnoye',
			'Sernur',
			'Semkhoz',
			'Sandata',
			'Samoylovka',
			'Rozhdestveno',
			'Rossosh’',
			'Ramenskoye',
			'Psedakh',
			'Psebay',
			'Prudy',
			'Priyutnoye',
			'Primorsk',
			'Ponezhukay',
			'Polotnyany Zavod',
			'Podborki',
			'Plyos',
			'Perelëshinskiy',
			'Otradnoye',
			'Ostrov',
			'Novomyshastovskaya',
			'Novomichurinsk',
			'Novokhovrino',
			'Novobessergenovka',
			'Nizhniy Ufaley',
			'Nizhniy Mamon',
			'Niny',
			'Naurskaya',
			'Mstikhino',
			'Misheronskiy',
			'Mineralnye Vody',
			'Mikhnëvo',
			'Melekhovo',
			'Lesnoy',
			'Leninskiy',
			'Leninkent',
			'Lechinkay',
			'Lebyazh’ye',
			'Lashma',
			'Kuloy',
			'Krymsk',
			'Krylovskaya',
			'Kremenki',
			'Krasnogorskiy',
			'Korzhovka-Golubovka',
			'Konyshevka',
			'Kolomenskoye',
			'Kokoshkino',
			'Kashin',
			'Karabulak',
			'Karabanovo',
			'Kamennomostskiy',
			'Kalinovskaya',
			'Ivanteyevka',
			'Isheyevka',
			'Ipatovo',
			'Ilovka',
			'Iksha',
			'Gunib',
			'Goreloye',
			'Germenchuk',
			'Gay',
			'Fastovetskaya',
			'Falyonki',
			'Elektrostal’',
			'Dzhubga',
			'Chishmy',
			'Chiri-Yurt',
			'Chyorny Yar',
			'Cherdyn’',
			'Chaplygin',
			'Budyonnovsk',
			'Botashyurt',
			'Aushiger',
			'Atemar',
			'Arsk',
			'Druzhba',
			'Chupryakovo',
			'Zhitnevo',
			'Petra-Dubrava',
			'Zheleznodorozhnyy',
			'Yalutorovsk',
			'Verkhniy Bekhtemir',
			'Uray',
			'Zhukov',
			'Yurovka',
			'Yelizavetino',
			'Yamkino',
			'Vyazovaya',
			'Verkhnyaya Tura',
			'Vel’sk',
			'Usogorsk',
			'Urkarakh',
			'Ufimskiy',
			'Tul’skiy',
			'Toropets',
			'Agidel’',
			'Synya',
			'Sven’',
			'Surgut',
			"Strel'na",
			'Sozimskiy',
			'Shatoy',
			'Sosnovskoye',
			'Sosnovka',
			'Sorochinsk',
			'Sonkovo',
			'Sokolovyy',
			'Sigayevo',
			'Shonguy',
			'Shikhazany',
			'Shchyolkovo',
			'Sharlyk',
			'Starobachaty',
			'Sargatskoye',
			'Makushino',
			'Krasnyy Oktyabr’',
			'Krapivinskiy',
			'Kol’tsovo',
			'Kodinsk',
			'Kalachinsk',
			'Grishkovka',
			'Belozërskoye',
			'Barzas',
			'Zarubino',
			'Ust’-Karsk',
			'Nizhniy Kuranakh',
			'Nikolayevskiy',
			'Mukhorshibir’',
			'Lazo',
			'Khorol’',
			'Khatanga',
			'Il’ka',
			'Ikey',
			'Gadaley',
			'Budagovo',
			'Barabash',
			'Baklashi',
			'Sayansk',
			'Sokol',
			'Palana',
			'Atlasovo',
			'Novyy',
			'Nezhdaninskoe',
			'Pribrezhnyy',
			'Petrogradka',
			'Korotchaevo',
			'Selishche',
			'Satka',
			'Satis',
			'Rybinsk',
			'Rudnya',
			'Razumnoye',
			'Pyatigorsk',
			'Pridonskoy',
			'Poshekhon’ye',
			'Pizhanka',
			'Pitelino',
			'Petro-Slavyanka',
			'Palkino',
			'Orlovka',
			'Oktyabrsky',
			'Nuradilovo',
			'Novyy Ropsk',
			'Novyye Kuz’minki',
			'Novo-Talitsy',
			'Novosmolinskiy',
			'Novokubansk',
			'Novodvinsk',
			'Noginsk',
			'Miskindzha',
			'Mirskoy',
			'Mikhaylovsk',
			'Miatli',
			'Mezhdurechensk',
			'Medvezh’i Ozëra',
			'Maloarkhangel’sk',
			'Magnitogorsk',
			'Maginsk',
			'Lysyye Gory',
			'Lopatino',
			'Kurba',
			'Kugesi',
			'Krasnyy Profintern',
			'Krasnyy Oktyabr’',
			'Krasnoznamensk',
			'Krasnoyarskaya',
			'Krasnogorodsk',
			'Kozhukhovo',
			'Kovylkino',
			'Kovernino',
			'Kostrovo',
			'Komsomol’skiy',
			'Kommunar',
			'Kinel’-Cherkassy',
			'Kantemirovka',
			'Gukovo',
			'Grebenskaya',
			'Gimry',
			'Erpeli',
			'Dedovichi',
			'Dedenëvo',
			'Cheremushskiy',
			'Borovichi',
			'Borok',
			'Bol’shaya Izhora',
			'Bogorodskoye',
			'Blizhne-Pesochnoye',
			'Al’met’yevsk',
			'Alekseyevka',
			'Aleksandrovskoye',
			'Aleksandrov',
			'Aktyubinskiy',
			'Agapovka',
			'Gerga',
			'Trëkhgornyy',
			'Zarechnyy',
			'Yuzhnyy',
			'Solnechnoye',
			'Yekaterinburg',
			'Volchikha',
			'Vkhodnoy',
			'Vershina Tei',
			'Verkh-Tula',
			'Ust’-Kalmanka',
			'Urengoy',
			'Zlynka',
			'Zakharovo',
			'Zakan-Yurt',
			'Yershov',
			'Yelabuga',
			'Volya',
			'Volot',
			'Vladimirskaya',
			'Verkhov’ye',
			'Vadinsk',
			'Utsmiyurt',
			'Utamysh',
			'Unecha',
			'Ulluaya',
			'Tëplaya Gora',
			'Taganskiy',
			'Sukhobezvodnoye',
			'Solnechnoye',
			'Sloboda',
			'Slavyansk-na-Kubani',
			'Shimorskoye',
			'Shatsk',
			'Shangaly',
			'Tyumentsevo',
			'Russkinskiye',
			'Rubtsovsk',
			'Inskoy',
			'Igarka',
			'Chebarkul’',
			'Tyumen',
			'Tyubuk',
			'Strezhevoy',
			'Shubenka',
			'Samus’',
			'Promyshlennyy',
			'Podgornoye',
			'Pioner',
			'Nizhnyaya Tavda',
			'Nagornyy',
			'Martyush',
			'Malinovoye Ozero',
			'Listvyanskiy',
			'Kunashak',
			'Kommunisticheskiy',
			'Itatskiy',
			'Dalmatovo',
			'Beryozovsky',
			'Berëzovo',
			'Beregovoy',
			'Bachatskiy',
			'Kedrovyy',
			'Timiryazevskiy',
			'Zvëzdnyy',
			'Volchanets',
			'Udachny',
			'Priargunsk',
			'Namtsy',
			'Mirny',
			'Kuytun',
			'Knevichi',
			'Khatassy',
			'Dunay',
			'Cheremkhovo',
			'Chegdomyn',
			'Bureya',
			'Uglegorsk',
			'Paratunka',
			'Mgachi',
			'Bogorodskoye',
			'Egvekinot',
			'Parnas',
			'Segezha',
			'Sagopshi',
			'Sabnova',
			'Ryazanskaya',
			'Rogachëvo',
			'Remontnoye',
			'Pushkino',
			'Priyutovo',
			'Pokrov',
			'Plesetsk',
			'Petrovskiy',
			'Petrovskaya',
			'Pervouralsk',
			'Perelëshino',
			'Orenburg',
			'Opalikha',
			'Oktyabr’skiy',
			'Novopodrezkovo',
			'Novomalorossiyskaya',
			'Nizhnyaya Tura',
			'Nikol’skoye',
			'Mumra',
			'Morozovsk',
			'Mindyak',
			'Michurinsk',
			'Mendeleyevsk',
			'Manaskent',
			'Luza',
			'Lukino',
			'Lipin Bor',
			'Kushchyovskaya',
			'Kultayevo',
			'Kuchugury',
			'Krym',
			'Krasnyy Bor',
			'Krasnokumskoye',
			'Krasnaya Gora',
			'Konosha',
			'Konokovo',
			'Kolobovo',
			'Koksovyy',
			'Kirya',
			'Khadyzhensk',
			'Kazan',
			"Katyn'",
			'Kardzhin',
			'Kanadey',
			'Gvardeysk',
			'Grazhdanka',
			'Goryachevodskiy',
			'Gol’yanovo',
			'Galyugayevskaya',
			'Dubrovka',
			'Dubrovka',
			'Dno',
			'Cherdakly',
			'Burlatskoye',
			'Bologoye',
			'Bogatoye',
			'Bilimbay',
			'Beloye',
			'Begichevskiy',
			'Bazarnyye Mataki',
			'Azov',
			'Argudan',
			'Andi',
			'Lutkun',
			'Novyy Khushet',
			'Orël-Izumrud',
			'Vorotynsk',
			'Maloye Isakovo',
			'Zvëzdnyy',
			'Novyy Chirkey',
			'Verkhnyaya Inta',
			'Tsementnyy',
			'Troitskoye',
			'Troitskiy',
			'Surgut',
			'Staropesterevo',
			'Sovkhoznyy',
			'Shumikha',
			'Polysayevo',
			'Zolotukhino',
			'Znamenka',
			'Zadonsk',
			'Yuzhnyy-Kospashskiy',
			'Yuryuzan’',
			'Yefimovskiy',
			'Yayva',
			'Yaroslavl',
			'Yanaul',
			'Volosovo',
			'Verkhniy Mamon',
			'Vasil’sursk',
			'Valuyki',
			'Usukhchay',
			'Urma',
			'Uren’',
			'Uchaly',
			'Tirlyanskiy',
			'Ternovka',
			'Tyoply Stan',
			'Temizhbekskaya',
			'Tambov',
			'Stupino',
			'Storozhevaya',
			'Staraya Stanitsa',
			'Sredniy Ikorets',
			'Spassk-Ryazanskiy',
			'Sovetskaya',
			'Smolino',
			'Sizyy Bugor',
			'Shumikhinskiy',
			'Shemysheyka',
			'Zyukayka',
			'Zarubino',
			'Zarechnyy',
			'Zabolotovka',
			'Yur’yevets',
			'Yessentuki',
			'Yermolino',
			'Yekimovichi',
			'Yefremov',
			'Voronezh',
			'Volzhsk',
			'Volovo',
			'Verkhovazh’ye',
			'Velikiy Ustyug',
			'Usvyaty',
			'Untsukul’',
			'Tsivil’sk',
			'Troitsko-Pechorsk',
			'Tpig',
			'Tashla',
			'Svobodnyy',
			'Svatkovo',
			'Sukko',
			'Strogino',
			'Staritsa',
			'Sortavala',
			'Siverskiy',
			'Sinodskoye',
			'Shuyskoye',
			'Shun’ga',
			'Shovgenovskiy',
			'Shikhany',
			'Nikolo-Pavlovskoye',
			'Mendeleyevo',
			'Lebyazh’ye',
			'Kolosovka',
			'Karasuk',
			'Charyshskoye',
			'Seshcha',
			'Sennoy',
			'Satis',
			'Rzhanitsa',
			'Mariyenburg',
			'Rodniki',
			'Rochegda',
			'Rassvet',
			'Pytalovo',
			'Poretskoye',
			'Pokrovskoye-Streshnëvo',
			'Plekhanovo',
			'Pinerovka',
			'Pestyaki',
			'Ozerki',
			'Osyno',
			'Orekhovo-Zuyevo',
			'Orekhovo-Borisovo Severnoye',
			'Obukhovo',
			'Nyandoma',
			'Novyy Rogachik',
			'Novoanninskiy',
			'Novoalekseyevskaya',
			'Murmansk',
			'Mosal’sk',
			'Mezhevoy',
			'Matyushkino',
			'Matveyev Kurgan',
			'Markovo',
			'Manturovo',
			'Losino-Petrovskiy',
			'Levashi',
			'Letnyaya Stavka',
			'Islamey',
			'Kuskovo',
			'Kushelevka',
			'Kusa',
			'Kudrovo',
			'Kuba-Taba',
			'Krylovskaya',
			'Krasnyy',
			'Krasnozavodsk',
			'Krasnoye Selo',
			'Krasnaya Polyana',
			'Kostek',
			'Korablino',
			'Kondol’',
			'Kolyshley',
			'Khvatovka',
			'Khvalynsk',
			'Khelyulya',
			'Karmaskaly',
			'Karinskoye',
			'Kameshkovo',
			'Ishley',
			'Il’ichëvo',
			'Il’ich',
			'Grivenskaya',
			'Gornyy',
			'Gatchina',
			'Fershampenuaz',
			'Dvinskoy',
			'Donskoy',
			'Cherneya',
			'Cherkasskoye',
			'Chekalin',
			'Brateyevo',
			'Bamut',
			'Bibirevo',
			'Belozërsk',
			'Arkhipo-Osipovka',
			'Antropovo',
			'Annino',
			'Aleksandriyskaya',
			'Ageyevo',
			'Afrikanda',
			'Afonino',
			'Afipskiy',
			'Ryl’sk',
			'Snezhnogorsk',
			'Vyshestebliyevskaya',
			'Kondratovo',
			'Pravokubanskiy',
			'Sokol',
			'Zmeinogorsk',
			'Vargashi',
			'Topki',
			'Tal’menka',
			'Talitsa',
			'Sut-Khol’',
			'Shakhi',
			'Prokudskoye',
			'Onokhino',
			'Novaya Lyalya',
			'Biryusinsk',
			'Andra',
			'Aban',
			'Ursk',
			'Solnechnyy',
			'Vostok',
			'Vladivostok',
			'Verkh-Usugli',
			'Topolëvo',
			'Tankhoy',
			'Tabaga',
			'Smolenshchina',
			'Pos’yet',
			'Pokrovka',
			'Naushki',
			'Mugun',
			'Mamakan',
			'Kultuk',
			'Konstantinovka',
			'Artëm',
			'Myaundzha',
			'Krasnogorsk',
			'Logovskoye',
			'Vas’kovo',
			'Imeni M. I. Kalinina',
			'Tonshalovo',
			'Budenovetc',
			'Solnechniy',
			'Sergokala',
			'Serafimovich',
			'Rudnichnyy',
			'Roshni-Chu',
			'Razliv',
			'Pudozh',
			'Pregradnaya',
			'Pravdinskiy',
			'Pozhva',
			'Poyakonda',
			'Povarovo',
			'Pontonnyy',
			'Pizhma',
			'Pesochnyy',
			'Parfino',
			'Ozërnyy',
			'Ovoshchi',
			'Otrado-Kubanskoye',
			'Ostashkov',
			'Orda',
			'Olenino',
			'Novyy Buyan',
			'Novocherkassk',
			'Novobeysugskaya',
			'Velikiy Novgorod',
			'Nolinsk',
			'Nemchinovka',
			'Myskhako',
			'Mulino',
			'Mtsensk',
			'Mozhga',
			'Moshenskoye',
			'Mendeleyevo',
			'Mayskiy',
			'Maslovka',
			'Marfino',
			'Malmyzh',
			'Magnitka',
			'Lyudinovo',
			'Lubyany',
			'Lobanovo',
			'Linda',
			'Leskolovo',
			'Kushva',
			'Kurganinsk',
			'Krasnyy Tkach',
			'Koshekhabl’',
			'Koryazhma',
			'Korobitsyno',
			'Kondrovo',
			'Kokino',
			'Klimovo',
			'Kirovskiy',
			'Kiritsy',
			'Kevsala',
			'Kavkazskiy',
			'Karata',
			'Iznoski',
			'Ivangorod',
			'Ishimbay',
			'Inozemtsevo',
			'Imeni Vorovskogo',
			'Igra',
			'Gubakha',
			'Germenchik',
			'Etoka',
			'Elektrogorsk',
			'Domodedovo',
			'Dobroye',
			'Dmitriyevka',
			'Chusovoy',
			'Chernukha',
			'Chernomorskiy',
			'Chermen',
			'Cherkessk',
			'Chaykovskiy',
			'Bol’shoye Selo',
			'Bol’shoye Kozino',
			'Bokovskaya',
			'Belyy',
			'Bakal',
			'Avtury',
			'Arzamas',
			'Arkhangel’skoye',
			'Andreyevka',
			'Aksay',
			'Adamovka',
			'Gedzhukh',
			'Rabocheostrovsk',
			'Privolzhskiy',
			'Novyy Sulak',
			'Yushala',
			'Yeniseysk',
			'Volchansk',
			'Ubinskoye',
			'Turinsk',
			'Tashtagol',
			'Tashara',
			'Staropyshminsk',
			'Sosnovoborsk',
			'Shul’gin Log',
			'Sayansk',
			'Russkaya Polyana',
			'Predivinsk',
			'Novoaltaysk',
			'Nefteyugansk',
			'Moshkovo',
			'Kuzedeyevo',
			'Kuminskiy',
			'Krasnoye',
			'Kiselëvsk',
			'Kazachinskoye',
			'Kataysk',
			'Karatuzskoye',
			'Izumrud',
			'Ishim',
			'Beloyarskiy',
			'Bagan',
			'Aksarka',
			'Aginskoye',
			'Abatskoye',
			'Seversk',
			'Zavitinsk',
			'Vyazemskiy',
			'Vikhorevka',
			'Vanavara',
			'Urusha',
			'Terney',
			'Shestakovo',
			'Shamanka',
			'Romanovka',
			'Mnogoudobnoye',
			'Kokuy',
			'Kangalassy',
			'Kurilovo',
			'Severomorsk-3',
			'Krasnaya Glinka',
			'Zhizdra',
			'Yoshkar-Ola',
			'Yakovlevo',
			'Yablonovskiy',
			'Vyshneye Dolgoye',
			'Voznesen’ye',
			'Vorotynets',
			'Verkhnyaya Toyma',
			'Verkhniy Tagil',
			'Verkhniy Lomov',
			'Verkhneural’sk',
			'Vel’ye',
			'Uzlovaya',
			'Urdoma',
			'Udobnaya',
			'Tselina',
			'Troitskaya',
			'Tonkino',
			'Temnikov',
			'Tarusa',
			'Sviblovo',
			'Sursk',
			'Suponevo',
			'Stulovo',
			'Strunino',
			'Staraya Poltavka',
			'Star’',
			'Spokoynaya',
			'Soldatskaya',
			'Tretiy Severnyy',
			'Kirovgrad',
			'Kamensk-Ural’skiy',
			'Erzin',
			'Divnogorsk',
			'Degtyarsk',
			'Akademgorodok',
			'Novyye Zori',
			'Bazhovo',
			'Yelantsy',
			'Turuntayevo',
			'Tokur',
			'Tayturka',
			'Svobodnyy',
			'Sokol',
			'Serebryanyy Bor',
			'Selenduma',
			'Rudnaya Pristan’',
			'Novoshakhtinskiy',
			'Nakhodka',
			'Knyaze-Volkonskoye',
			'Dostoyevka',
			'Barguzin',
			'Babushkin',
			'Afanas’yeva',
			'Troitskoye',
			'Dolinsk',
			'Lavrentiya',
			'Isakogorka',
			'Staroye Arakchino',
			'Vostochnoe Degunino',
			'Dzerzhinsky',
			'Avtopoligon',
			'Ult-Ugun',
			'Loza',
			'Sergach',
			'Semiluki',
			'Savasleyka',
			'Saratov',
			'Rozhdestveno',
			'Rostokino',
			'Rossosh’',
			'Rakitnoye',
			'Pychas',
			'Puteyets',
			'Pshekhskaya',
			'Pristen’',
			'Port-Katon',
			'Porosozero',
			'Podsolnechnoye',
			'Petushki',
			'Peshkovo',
			'Perevolotskiy',
			'Orud’yevo',
			'Orlovskiy',
			'Orgtrud',
			'Takhtamukay',
			'Novyye Atagi',
			'Novotitarovskaya',
			'Novoplatnirovskaya',
			'Novominskaya',
			'Novogireyevo',
			'Nizhniy Dzhengutay',
			'Nizhniy Arkhyz',
			'Nekhayevskiy',
			'Morki',
			'Mokrous',
			'Millerovo',
			'Mendeleyevo',
			'Makushino',
			'L’vovskiy',
			'Lomovka',
			'Likhoslavl’',
			'Lermontovo',
			'Leninskoye',
			'Lakhtinskiy',
			'Ladovskaya Balka',
			'Labinsk',
			'Kuzhenkino',
			'Kurmanayevka',
			'Kuli',
			'Krasnyy Yar',
			'Krasnoye',
			'Kozeyevo',
			'Kosa',
			'Kolodeznyy',
			'Kol’chugino',
			'Kishpek',
			'Kirishi',
			'Khiv',
			'Khislavichi',
			'Kayakent',
			'Kardonikskaya',
			'Kamenskiy',
			'Kamennomostskoye',
			'Kadyy',
			'Ivanino',
			'Is',
			'Irkliyevskaya',
			'Ilovlya',
			'Gundorovskiy',
			'Gorshechnoye',
			'Golynki',
			'Fornosovo',
			'Dzhalka',
			'Dubna',
			'Druzhba',
			'Drezna',
			'Doschatoye',
			'Dorokhovo',
			'Donskoye',
			'Divnomorskoye',
			'Dem’yanovo',
			'Dagestanskiye Ogni',
			'Dachnoye',
			'Chufarovo',
			'Chirkey',
			'Chelbasskaya',
			'Chaadayevka',
			'Bryansk',
			'Besleneyevskaya',
			'Beshpagir',
			'Arkhangel’skoye',
			'Andreapol’',
			'Alekseyevka',
			'Aleksandriya',
			'Achisu',
			'Vasil’yevo',
			'Ivanovka',
			'Zaozërsk',
			'Kochubey',
			'Zelënyy Bor',
			'Zdvinsk',
			'Yagunovskiy',
			'Verkhotur’ye',
			'Togur',
			'Tayzhina',
			'Svetlogorsk',
			'Salair',
			'Razdolinsk',
			'Onguday',
			'Mundybash',
			'Cheremnoye',
			'Zernograd',
			'Zavolzhsk',
			'Zapolyarnyy',
			'Yuzhno-Sukhokumsk',
			'Yelizavetinskoye',
			'Yashalta',
			'Volgodonsk',
			'Vinnitsy',
			'Verkhnyaya Tishanka',
			'Vazhiny',
			'Ust’-Shonosha',
			'Ust’-Luga',
			'Urazovka',
			'Trudobelikovskiy',
			'Troparëvo',
			'Syzran',
			'Sukhinichi',
			'Staryy Oskol',
			'Staraya Vichuga',
			'Staraya Toropa',
			'Sovetskoye',
			'Sotnikovskoye',
			'Sosnovo',
			'Soligalich',
			'Smolenskaya',
			'Shugurovo',
			'Shirokovskiy',
			'Shiringushi',
			'Semiletka',
			'Sandovo',
			'Sanchursk',
			'Sal’sk',
			'Ruskeala',
			'Romanovskaya',
			'Rognedino',
			'Raditsa-Krylovka',
			'Pyatnitskoye',
			'Purekh',
			'Kotlovka',
			'Pokrovo-Prigorodnoye',
			'Pichayevo',
			'Petropavlovskaya',
			'Perelyub',
			'Pavshino',
			'Pavlovskiy Posad',
			'Pavino',
			'Ozërsk',
			'Ostrovskoye',
			'Oranzherei',
			'Obsharovka',
			'Novoutkinsk',
			'Neman',
			'Nedvigovka',
			'Murom',
			'Mrakovo',
			'Melikhovskaya',
			'Medveditskiy',
			'Manturovo',
			'Lesnoye',
			'Tsaritsyno',
			'Ledmozero',
			'Ladozhskaya',
			'Kubachi',
			'Kshenskiy',
			'Krotovka',
			'Krasnoslobodsk',
			'Krasnoarmeyskaya',
			'Kostroma',
			'Konakovo',
			'Kolpna',
			'Kletnya',
			'Orlov',
			'Kem’',
			'Kashary',
			'Kanayevka',
			'Kamenka',
			'Izhevsk',
			'Ishcherskaya',
			'Irganay',
			'Inzhavino',
			'Il’insko-Podomskoye',
			'Gus’-Zheleznyy',
			'Gostagayevskaya',
			'Golovchino',
			'Glubokoye',
			'Glubokiy',
			'Glinishchevo',
			'Galich',
			'Furmanov',
			'Dzhayrakh',
			'Dneprovskaya',
			'Dmitrovskiy Pogost',
			'Dalakovo',
			'Bykovo',
			'Borovoy',
			'Bol’shoy Samovets',
			'Boguchar',
			'Bogoslovka',
			'Bogorodsk',
			'Bezhanitsy',
			'Betlitsa',
			'Belyy Gorodok',
			'Belyye Berega',
			'Belaya Rechka',
			'Belaya Rechka',
			'Belaya Kholunitsa',
			'Begunitsy',
			'Barashevo',
			'Askino',
			'Ashitkovo',
			'Arsaki',
			'Ali-Yurt',
			'Alikovo',
			'Aktanysh',
			'Aknada',
			'Akhunovo',
			'Adler',
			'Vostochny',
			'Sovetskoye',
			'Ferma',
			'Zyryanskoye',
			'Zudilovo',
			'Zarinsk',
			'Yagunovo',
			'Verkhnyaya Salda',
			'Verkhnyaya Pyshma',
			'Ural',
			'Tomsk',
			'Tabuny',
			'Strelka',
			'Stantsionno-Oyashinskiy',
			'Sladkovo',
			'Shirokaya Rechka',
			'Shalinskoye',
			'Selezyan',
			'Poletayevo',
			'Podsineye',
			'Pervomayskoye',
			'Nizhnyaya Poyma',
			'Mortka',
			'Mariinsk',
			'Kuragino',
			'Krasnoural’sk',
			'Kormilovka',
			'Gur’yevsk',
			'Bol’sherech’ye',
			'Bogdanovich',
			'Lesogorsk',
			'Asbestovskiy',
			'Anzhero-Sudzhensk',
			'Alapayevsk',
			'Aktash',
			'Sadovyy',
			'Ust’-Ordynskiy',
			'Ust’-Kut',
			'Sosnovo-Ozerskoye',
			'Smolyaninovo',
			'Shtykovo',
			'Petrovsk-Zabaykal’skiy',
			'Nizhniy Bestyakh',
			'Nikol’sk',
			'Mogocha',
			'Klichka',
			'Khuzhir',
			'Chernigovka',
			'Bayangol',
			'Vostok',
			'Sokol',
			'Seymchan',
			'De-Kastri',
			'Zarech’ye',
			'Pad’ Mel’nichnaya',
			'Pyt-Yakh',
			'Norkino',
			'Krasnogvargeisky',
			'Admiralteisky',
			'Krestovskiy ostrov',
			'Finlyandskiy',
			'Petrovskoye',
			'Vniissok',
			'Zvony',
			'Zubova Polyana',
			'Zhemtala',
			'Zheleznovodsk',
			'Zelenets',
			'Zavety Il’icha',
			'Yur’yev-Pol’skiy',
			'Yukhnov',
			'Yubileynyy',
			'Yelizavetinka',
			'Yekaterinovka',
			'Votkinsk',
			'Altuf’yevskiy',
			'Volokolamsk',
			'Volkhovskiy',
			'Volgo-Kaspiyskiy',
			'Yutsa',
			'Verkhozim',
			'Velikiye Luki',
			'Vatutinki',
			'Vad',
			'Ust’-Izhora',
			'Umba',
			'Ukhta',
			'Tyarlevo',
			'Totskoye',
			'Taytsy',
			'Suvorov',
			'Starobaltachevo',
			'Staraya Kupavna',
			'Srednyaya Akhtuba',
			'Solyanka',
			'Sol’vychegodsk',
			'Shchukino',
			'Zubovo',
			'Zhiguli',
			'Zhigulevsk',
			'Zheshart',
			'Zaraysk',
			'Zaplavnoye',
			'Zapadnaya Dvina',
			'Zandak',
			'Yurino',
			'Yukamenskoye',
			'Yessentukskaya',
			'Yaroslavskaya',
			'Yaroslavichi',
			'Yarensk',
			'Yarega',
			'Vytegra',
			'Vysha',
			'Vyritsa',
			'Vostryakovo',
			'Voskresenskoye',
			'Vorobyovka',
			'Volodarskiy',
			'Vol’nyy Aul',
			'Vityazevo',
			'Vilya',
			'Verkhneye Kazanishche',
			'Valday',
			'Ustyuzhna',
			'Urazovo',
			'Ul’yanovka',
			'Troitsk',
			'Tolmachevo',
			'Tim',
			'Temryuk',
			'Sylva',
			'Suslonger',
			'Staryy Urukh',
			'Staromyshastovskaya',
			'Spirovo',
			'Spas-Demensk',
			'Sovetskiy',
			'Soluno-Dmitriyevskoye',
			'Sholokhovskiy',
			'Sheltozero',
			'Sestroretsk',
			'Serëdka',
			'Saint Petersburg',
			'Samoded',
			'Saltykovka',
			'Russkiy Kameshkir',
			'Repnoye',
			'Rameshki',
			'Primorsko-Akhtarsk',
			'Primalkinskoye',
			'Pokhvistnevo',
			'Podkumskiy',
			'Podkletnoye',
			'Podgornaya',
			'Pinega',
			'Pervomayskaya',
			'Pereyaslovskaya',
			'Olenegorsk',
			'Oktyabr’skiy',
			'Novozavedennoye',
			'Novotroitsk',
			'Novorossiysk',
			'Novokuybyshevsk',
			'Oyskhara',
			'Novocheboksarsk',
			'Novaya Chigla',
			'Nikol’skoye',
			'Nartkala',
			'Nakhabino',
			'Mutsalaul',
			'Molodezhnoye',
			'Molochnoye',
			'Mshinskaya',
			'Mirnyy',
			'Mikun’',
			'Medvezh’yegorsk',
			'Medvedevo',
			'Mar’ino',
			'Malino',
			'Lunino',
			'Losevo',
			'Letnik',
			'Letka',
			'Leonovo',
			'Leninskiye Gory',
			'Yubileyny',
			'Lakinsk',
			'Lakhdenpokh’ya',
			'Kuz’minskiye Otverzhki',
			'Kurchaloy',
			'Kun’ya',
			'Kresty',
			'Krasnogvardeyskoye',
			'Krasnaya Gorka',
			'Krapivna',
			'Kozlovka',
			'Kotovsk',
			'Kostomuksha',
			'Kondopoga',
			'Kizlyar',
			'Kizema',
			'Kirs',
			'Kirovsk',
			'Khvorostyanka',
			'Kharitonovo',
			'Khadzhalmakhi',
			'Kargalinskaya',
			'Kanelovskaya',
			'Kandry',
			'Kandalaksha',
			'Kalininskaya',
			'Kakhun',
			'Izoplit',
			'Izmaylovo',
			'Ivanteyevka',
			'Ivanishchi',
			'Il’inskiy',
			'Gostilitsy',
			'Goritsy',
			'Glebychevo',
			'Gigant',
			'Gelendzhik',
			'Galitsy',
			'Gagarin',
			'Dobrun’',
			'Chkalovsk',
			'Chernyakhovsk',
			'Cheremshan',
			'Burmakino',
			'Burayevo',
			'Bugulma',
			'Bronnitsy',
			'Borzoy',
			'Bogatyye Saby',
			'Birsk',
			'Bernovo',
			'Belebey',
			'Baranchinskiy',
			'Astrakhan',
			'Ardon',
			'Razvilka',
			'Yuzhnyy',
			'Pervoye Maya',
			'Molochnyy',
			'Chinar',
			'Yuzhnyy',
			'Yemanzhelinka',
			'Vinzili',
			'Vagay',
			'Tyukalinsk',
			'Topchikha',
			'Tashtyp',
			'Sumkino',
			'Shumskiy',
			'Novoseleznëvo',
			'Rudnichnyy',
			'Pionerskiy',
			'Noyabrsk',
			'Novyy Urengoy',
			'Manzherok',
			'Luzino',
			'Lobva',
			'Listvyagi',
			'Kytmanovo',
			'Krivosheino',
			'Kazanskoye',
			'Kamyshlov',
			'Izhmorskiy',
			'Gornyak',
			'Chunoyar',
			'Baryshevo',
			'Achinsk',
			'Zheleznogorsk',
			'Iogach',
			'Muravlenko',
			'Zhigansk',
			'Vrangel’',
			'Vol’no-Nadezhdinskoye',
			'Urik',
			'Trudovoye',
			'Tel’ma',
			'Tataurovo',
			'Tarbagatay',
			'Takhtamygda',
			'Shirokiy',
			'Severobaykal’sk',
			'Kutulik',
			'Gornorechenskiy',
			'Chernyshevsk',
			'Blagoveshchensk',
			'Birobidzhan',
			'Balagansk',
			'Smirnykh',
			'Ola',
			'Chekhov',
			'Vzmorye',
			'Sudoverf’',
			'Rybnoye',
			'Sernovodsk',
			'Ryabovo',
			'Russko-Vysotskoye',
			'Rovnoye',
			'Roven’ki',
			'Rostov-na-Donu',
			'Rostov',
			'Romanovka',
			'Rayevskaya',
			'Puksoozero',
			'Proletariy',
			'Privolzhskoye',
			'Polyarnyye Zori',
			'Pinyug',
			'Peterhof',
			'Pavlovsk',
			'Parkovyy',
			'Pargolovo',
			'Olonets',
			'Nyrob',
			'Novoselitskoye',
			'Novomoskovsk',
			'Nizhnyaya Maktama',
			'Nizhniye Vyazovyye',
			'Nesterovskaya',
			'Nerl’',
			'Myurego',
			'Murygino',
			'Monastyrshchina',
			'Mirnyy',
			'Meshchovsk',
			'Medvedok',
			'Mamadysh',
			'Lomonosov',
			'Listopadovka',
			'Likhoy',
			'Lezhnevo',
			'Levokumka',
			'Leshukonskoye',
			'Kurakh',
			'Kulebaki',
			'Kruglolesskoye',
			'Krasnyy Gulyay',
			'Krasnoufimsk',
			'Krasnokholm',
			'Krasnoborsk',
			'Krasnoarmeysk',
			'Kotlas',
			'Kosino',
			'Korocha',
			'Kopor’ye',
			'Komarovo',
			'Kizlyar',
			'Kirgiz-Miyaki',
			'Kireyevsk',
			'Khvalovo',
			'Khomutovo',
			'Khlevnoye',
			'Kazinka',
			'Kardymovo',
			'Karaidel’',
			'Karabash',
			'Kalinin',
			'Kadoshkino',
			'Kadnikov',
			'Izberbash',
			'Ivanovskoye',
			'Gudermes',
			'Gorodnya',
			'Glembochino',
			'Garbolovo',
			'El’brus',
			'Dukhovnitskoye',
			'Burtunay',
			'Bolgar',
			'Bondari',
			'Bogatyr’',
			'Bershet’',
			'Belaya Berëzka',
			'Bavly',
			'Arbazh',
			'Alkhazurovo',
			'Alexeyevka',
			'Abramovka',
			'Kontenko',
			'Yuzhnyy',
			'Dagomys',
			'Partsa',
			'Novyy Kostek',
			'Pervomayskiy',
			'Staryy Malgobek',
			'Zverinogolovskoye',
			'Zonal’noye',
			'Vakhrushevo',
			'Zelenogorsk',
			'Tevriz',
			'Tazovsky',
			'Tayshet',
			'Srostki',
			'Shushenskoye',
			'Reftinskiy',
			'Rassvet',
			'Polovinnoye',
			'Polevskoy',
			'Pokrovskoye',
			'Petrokamenskoye',
			'Pankrushikha',
			'Obukhovskoye',
			'Novoagansk',
			'Nizhneudinsk',
			'Nev’yansk',
			'Krasnoyarka',
			'Krasnoshchekovo',
			'Kopeysk',
			'Khudoyelanskoye',
			'Beya',
			'Armizonskoye',
			'Istok',
			'Ulan-Ude',
			'Perfilovo',
			'Oyëk',
			'Olyokminsk',
			'Novaya Bryan’',
			'Kachug',
			'Ivolginsk',
			'Belogorsk',
			'Novaya Chara',
			'Ust’-Kamchatsk Staryy',
			'Zavety Il’icha',
			'Orotukan',
			'Gornozavodsk',
			'Zvenigorod',
			'Zubtsov',
			'Zol’noye',
			'Znamenka',
			'Zhirnovsk',
			'Zemlyansk',
			'Yashkul’',
			'Yasenskaya',
			'Vysokaya Gora',
			'Vyaz’ma',
			'Volodarsk',
			'Vokhma',
			'Vinogradovo',
			'Vidnoye',
			'Vagonoremont',
			'Uva',
			'Ust’-Kulom',
			'Ust’-Kachka',
			'Ust’-Dzheguta',
			'Urzhum',
			'Uglich',
			'Troitskiy',
			'Tot’ma',
			'Terekli-Mekteb',
			'Tatarskaya Pishlya',
			'Taldom',
			'Svetlyy',
			'Suvorovskaya',
			'Susanino',
			'Suoyarvi',
			'Stromyn’',
			'Staraya Ladoga',
			'Staraya Kulatka',
			'Srednyaya Yelyuzan’',
			'Spasskoye-Lutovinovo',
			'Sovetskiy',
			'Sovetskaya',
			'Solntsevo',
			'Shilovo',
			'Shalakusha',
			'Severomorsk',
			'Togul',
			'Timiryazevskoye',
			'Sovetskiy',
			'Shebalino',
			'Nyagan',
			'Nauchnyy Gorodok',
			'Mokrousovo',
			'Malougrenevo',
			'Belyashi',
			'Argayash',
			'Zeya',
			'Ussuriysk',
			'Tulun',
			'Svetlaya',
			'Shkotovo',
			'Popova',
			'Novopokrovka',
			'Mikhaylovka',
			'Markha',
			'Lebedinyy',
			'Kizhinga',
			'Kadaya',
			'Churapcha',
			'Bira',
			'Aksha',
			'Yantal’',
			'Yuzhno-Kurilsk',
			'Poronaysk',
			'Palatka',
			'Mago',
			'Oktyabr’skiy',
			'Untolovo',
			'Krasnaya Pahra',
			'Severnoye',
			'Sergiyev Posad',
			'Selty',
			'Sakmara',
			'Ruza',
			'Roslyakovo',
			'Pokrovskoye',
			'Podgorodnyaya Pokrovka',
			'Plyussa',
			'Platnirovskaya',
			'Ozherel’ye',
			'Osinovo',
			'Ol’ginka',
			'Novopetrovskoye',
			'Novoleushkovskaya',
			'Grebnevo',
			'Novogagatli',
			'Novodzhereliyevskaya',
			'Novocheremshansk',
			'Novaya Tavolzhanka',
			'Nizhniy Baskunchak',
			'Nikolayevskaya',
			'Nema',
			'Mordovo',
			'Mitrofanovka',
			'Mirnyy',
			'Mga',
			'Maloye Verevo',
			'Malgobek',
			'Lyublino',
			'Log',
			'Lipetsk',
			'Leninogorsk',
			'Laishevo',
			'Dugulubgey',
			'Kuz’minki',
			'Kudepsta',
			'Krasnogvardeyets',
			'Krasnofarfornyy',
			'Krasnoarmeyskiy',
			'Krasnokholmskiy',
			'Klin',
			'Kilemary',
			'Khvastovichi',
			'Kastanayevo',
			'Karpogory',
			'Karakulino',
			'Kamennogorsk',
			'Kalach-na-Donu',
			'Kadom',
			'Ostrovnoy',
			'Gusevskiy',
			'Gumrak',
			'Grushevskaya',
			'Gribanovskiy',
			'Gorodoviki',
			'Gorodishche',
			'Gorodets',
			'Gorki Vtoryye',
			'Gorki-Leninskiye',
			'Glubokiy',
			'Glinka',
			'Glazov',
			'Georgiyevka',
			'Fosforitnyy',
			'Enem',
			'Dubovskoye',
			'Dubki',
			'Donskoy',
			'Chudovo',
			'Chernushka',
			'Cheremisinovo',
			'Buguruslan',
			'Nogamerzin-Yurt',
			'Bol’shoye Skuratovo',
			'Blechepsin',
			'Blagoveshchensk',
			'Biryulëvo',
			'Bezhetsk',
			'Belogor’ye',
			'Bekeshevskaya',
			'Batyrevo',
			'Bakaly',
			'Bachi-Yurt',
			'Babugent',
			'Avdon',
			'Assinovskaya',
			'Arkhonskaya',
			'Alabushevo',
			'Akhty',
			'Abzakovo',
			'Krasnoyarskaya',
			'Zmeyka',
			'Iskateley',
			'Raduzhnyy',
			'Yurgamysh',
			'Yertarskiy',
			'Vostochnyy',
			'Verkh-Chebula',
			'Uvel’skiy',
			'Zyablikovo',
			'Zhavoronki',
			'Zelyony Gorod',
			'Yugo-Kamskiy',
			'Vysokinichi',
			'Voskresenskoye',
			'Vorsma',
			'Vorontsovka',
			'Vilovatovo',
			'Vetluzhskiy',
			'Verkhnyaya Khava',
			'Verkhnyaya Balkariya',
			'Verkhniy Kurkuzhin',
			'Verkhniye Osel’ki',
			'Ventsy',
			'Vavozh',
			'Vasil’yevskiy Mokh',
			'Uvarovka',
			'Ust’-Labinsk',
			'Usman’',
			'Urussu',
			'Uni',
			'Ulukulevo',
			'Uglovka',
			'Udimskiy',
			'Troitskoye',
			'Tomilino',
			'Syava',
			'Syamzha',
			'Strugi-Krasnyye',
			'Sosnovoborsk',
			'Soskovo',
			'Sol’tsy',
			'Sokolovo-Kundryuchenskiy',
			'Slobodka',
			'Sista-Palkino',
			'Shebekino',
			'Shalazhi',
			'Shakhovskaya',
			'Krutaya Gorka',
			'Krasnoturinsk',
			'Ketovo',
			'Kaltan',
			'Dudinka',
			'Cherlak',
			'Sernovodsk',
			'Semënovskoye',
			'Sechenovo',
			'Samashki',
			'Sadovoye',
			'Rtishchevo',
			'Pyatovskiy',
			'Proletarskiy',
			'Prigorodnyy',
			'Ponazyrevo',
			'Poddor’ye',
			'Pestravka',
			'Peshki',
			'Perevoz',
			'Ozëry',
			'Olym',
			'Tsotsin-Yurt',
			'Nytva',
			'Novozhivotinnoye',
			'Novoukrainskiy',
			'Novo-Nikol’skoye',
			'Novogurovskiy',
			'Nizhny Tagil',
			'Nizhniy Lomov',
			'Mokshan',
			'Mikhaylov',
			'Mezhdurechensk',
			'Marfino',
			'Maloyaroslavets',
			'Lomintsevskiy',
			'Lesogorskiy',
			'Kuznechikha',
			'Kuybyshevskiy Zaton',
			'Kur’yanovo',
			'Kurkent',
			'Kurchatov',
			'Krasnyy Oktyabr’',
			'Krasnyye Tkachi',
			'Krasnoarmeyskoye',
			'Kozlovo',
			'Kosmynino',
			'Korzhevskiy',
			'Kideksha',
			'Kholmskiy',
			'Kerva',
			'Katyr-Yurt',
			'Gereykhanovskoye',
			'Gorelki',
			'Georgiyevskaya',
			'Gdov',
			'Foki',
			'Energetik',
			'Elista',
			'Dubna',
			'Donskoye',
			'Dondukovskaya',
			'Davydovo',
			'Davlekanovo',
			'Chikola',
			'Chegem Vtoroy',
			'Bulgakovo',
			'Brusyanka',
			'Bisert’',
			'Berëza',
			'Novaya Balakhna',
			'Balabanovo',
			'Arshan’',
			'Ardatov',
			'Ansalta',
			'Anna',
			'Amin’yevo',
			'Agryz',
			'Agalatovo',
			'Karamakhi',
			'Kurovskoye',
			'Kazachka',
			'Zavodouspenskoye',
			'Zalesovo',
			'Vikulovo',
			'Ust’-Tarka',
			'Uporovo',
			'Turan',
			'Tsementnozavodskiy',
			'Suslovo',
			'Sosnovka',
			'Shatrovo',
			'Salekhard',
			'Pervomayskoye',
			'Pangody',
			'Ous',
			'Moryakovskiy Zaton',
			'Losinyy',
			'Kuybyshev',
			'Krivodanovka',
			'Koyelga',
			'Kosikha',
			'Khabary',
			'Kayyerkan',
			'Iskitim',
			'Gorno-Altaysk',
			'Chernogorsk',
			'Cherga',
			'Barnaul',
			'Artybash',
			'Turtas',
			'Zaigrayevo',
			'Yekaterinovka',
			'Rechka-Vydrino',
			'Suntar',
			'Slyudyanka',
			'Priiskovyy',
			'Partizansk',
			'Osa',
			'Novonikol’sk',
			'Novokizhinginsk',
			'Mukhen',
			'Mogoytuy',
			'Magistral’nyy',
			'Lermontovka',
			'Kamenka',
			'Kalga',
			'Guran',
			'Domna',
			'Aykhal',
			'Arkhara',
			'Zyryanka',
			'Uptar',
			'Pevek',
			'Krasnoznamensk',
			'Zarya',
			'Noginsk-9',
			'Sagan-Nur',
			'Akademicheskoe',
			'Centralniy',
			'Sampsonievskiy',
			'Poselok Turisticheskogo pansionata "Klyazminskoe vodohranilische"',
			'Zhilëvo',
			'Zenzeli',
			'Yermolayevo',
			'Yermish’',
			'Yanis’yarvi',
			'Yambirno',
			'Vysotsk',
			'Vyborg',
			'Vorontsovka',
			'Vladikavkaz',
			'Vizinga',
			'Vesëlyy',
			'Troyel’ga',
			'Torbeyevo',
			'Tlyustenkhabl’',
			'Taremskoye',
			'Sychëvo',
			'Svetlograd',
			'Svecha',
			'Suzdal’',
			'Surkhakhi',
			'Stolbishchi',
			'Stepnoye',
			'Starodzhereliyevskaya',
			'Staraya Stanitsa',
			'Sosenskiy',
			'Shuvalovo',
			'Shcheglovo',
			'Shatura',
			'Severnyy',
			'Rybnaya Sloboda',
			'Rodniki',
			'Privolzhskiy',
			'Prigorodka',
			'Pravokumskoye',
			'Povorino',
			'Podstepki',
			'Podlesnyy',
			'Paran’ga',
			'Otkaznoye',
			'Ostankinskiy',
			'Opochka',
			'Novyye Gorki',
			'Novoye',
			'Novorudnyy',
			'Novobelokatay',
			'Nikolina Gora',
			'Nezhinka',
			'Nevinnomyssk',
			'Neftekamsk',
			'Nebolchi',
			'Lobnya',
			'Liman',
			'Kumukh',
			'Golovinskiy',
			'Krasnogorsk',
			'Konstantinovskoye',
			'Komsomolets',
			'Kholmogory',
			'Kholm',
			'Khimki',
			'Kazanskaya',
			'Kamenka',
			'Kamenka',
			'Kambarka',
			'Gayduk',
			'Fëdorovskoye',
			'Dyshne-Vedeno',
			'Dylym',
			'Dmitriyevskoye',
			'Chegem',
			'Bystrogorskiy',
			'Buinsk',
			'Bryukhovetskaya',
			'Borinskoye',
			'Borgustanskaya',
			'Belinskiy',
			'Baryatino',
			'Baksheyevo',
			'Bagrationovsk',
			'Azovskaya',
			'Atamanskaya',
			'Anastasiyevskaya',
			'Alnashi',
			'Abadzekhskaya',
			'Neftekumsk',
			'Otradnoye',
			'Yelanskiy',
			'Tyukhtet',
			'Turgoyak',
			'Troitsk',
			'Toora-Khem',
			'Teya',
			'Tatarsk',
			'Talitsa',
			'Svetlyy',
			'Solton',
			'Pyshma',
			'Plast',
			'Souzga',
			'Kvitok',
			'Krasnoturansk',
			'Khanty-Mansiysk',
			'Iset’',
			'Chelyabinsk',
			'Belogorsk',
			'Balgazyn',
			'Atagay',
			'Vladimiro-Aleksandrovskoye',
			'Vilyuysk',
			'Olovyannaya',
			'Nyurba',
			'Korfovskiy',
			'Ilir',
			'Dalnerechensk',
			'Bodaybo',
			'Bikin',
			'Bada',
			'Ust’-Omchug',
			'Shakhtersk',
			'Mayskiy',
			'Bilibino',
			'Provideniya',
			'Obolensk',
			'Zykovo',
			'Zolotkovo',
			'Zherdevka',
			'Masalovka',
			'Yurla',
			'Yenotayevka',
			'Yar',
			'Yalkhoy-Mokhk',
			'Vodstroy',
			'Novovladykino',
			'Verkhniy Uslon',
			'Varygino',
			'Varenikovskaya',
			'Uvary',
			'Ust’ye',
			'Ust-Tsilma',
			'Urvan’',
			'Tyul’gan',
			'Tsil’na',
			'Tomarovka',
			'Teribërka',
			'Ten’gushevo',
			'Temiraul',
			'Tatishchevo',
			'Tatarka',
			'Sylva',
			'Sovetsk',
			'Sokol',
			'Shil’da',
			'Sheremet’yevskiy',
			'Shar’ya',
			'Severoural’sk',
			'Tayga',
			'Snezhnogorsk',
			'Sharypovo',
			'Severo-Yeniseyskiy',
			'Rezh',
			'Promyshlennaya',
			'Ovsyanka',
			'Nikolayevka',
			'Nikitinskiy',
			'Miasskoye',
			'Miass',
			'Lugovskoy',
			'Lesosibirsk',
			'Lesnikovo',
			'Labytnangi',
			'Krasnogorskoye',
			'Kalmanka',
			'Ikovka',
			'Chistogorskiy',
			'Chadan',
			'Brodokalmak',
			'Borovskiy',
			'Aya',
			'Abalak',
			'Abakan',
			'Krasnyy Chikoy',
			'Smidovich',
			'Sergeyevka',
			'Krasnorechenskiy',
			'Komsomolsk-on-Amur',
			'Dul’durga',
			'Drovyanaya',
			'Lososina',
			'Kozyrevsk',
			'Yantarnyy',
			'Elitnyy',
			'Pervomayskoye',
			'Svetlanovskiy',
			'Slavniy',
			'Sasykoli',
			'Sarayi',
			'Rodionovo-Nesvetayskaya',
			'Rasshevatskaya',
			'Pyatigorskiy',
			'Pushkino',
			'Purshevo',
			'Pugachev',
			'Pokrovskoye',
			'Pokoynoye',
			'Podporozh’ye',
			'Pitkyaranta',
			'Pindushi',
			'Perevoloki',
			'Pavlovsk',
			'Pashkovskiy',
			'Sunzha',
			'Nyuksenitsa',
			'Novy Oskol',
			'Novovoronezh',
			'Novotroitskaya',
			'Novopokrovskaya',
			'Nizhniye Achaluki',
			'Nizhnekamsk',
			'Nikel',
			'Navashino',
			'Nadvoitsy',
			'Mostishche',
			'Morgaushi',
			'Mglin',
			'Mesyagutovo',
			'Matveyevka',
			'Marfino',
			'Lukhovka',
			'Loyga',
			'Livny',
			'Leninsk',
			'Leningradskaya',
			'Leninaul',
			'Lebedyan’',
			'Kuvshinovo',
			'Kursavka',
			'Kumëny',
			'Korenëvo',
			'Konëvo',
			'Kolyubakino',
			'Kletskaya',
			'Khodz’',
			'Kharovsk',
			'Kaspiysk',
			'Kashirskoye',
			'Kanash',
			'Kamyzyak',
			'Kaltasy',
			'Kabanovo',
			'Gryazovets',
			'Gizel’',
			'Girvas',
			'Georgiyevskoye',
			'Firovo',
			'Dzhalil’',
			'Druzhinino',
			'Dedovsk',
			'Chern’',
			'Bytosh’',
			'Bol’shoye Boldino',
			'Bezhta',
			'Belaya Glina',
			'Belaya',
			'Bavtugay',
			'Baksanenok',
			'Babushkin',
			'Avtovo',
			'Arzgir',
			'Alekseyevskoye',
			'Aleksandrovskaya',
			'Alandskoye',
			'Agvali',
			'Persianovka',
			'Gorod Shebekino',
			'Kochubeyevskoye',
			'Obukhovo',
			'Zav’yalovo',
			'Yarkovo',
			'Verkh-Katunskoye',
			'Udomlya',
			'Znamenskoye',
			'Zirgan',
			'Zelenogradskiy',
			'Zavoronezhskoye',
			'Yermekeyevo',
			'Yemva',
			'Yaropolets',
			'Vurnary',
			'Voyskovitsy',
			'Nagornyy',
			'Vidyayevo',
			'Veshkayma',
			'Veshchevo',
			'Verkhnyaya Maksakovka',
			'Verkhniy Landekh',
			'Verkhniye Achaluki',
			'Vereshchagino',
			'Velikooktyabr’skiy',
			'Ugleural’skiy',
			'Uchkulan',
			'Tuzha',
			'Terskol',
			'Tëploye',
			'Sychëvo',
			'Svetogorsk',
			'Staraya Derevnya',
			'Smolensk',
			'Slobodskoy',
			'Skhodnya',
			'Shuya',
			'Shemëtovo',
			'Severskaya',
			'Savinskaya',
			'Samur',
			'Rzhavki',
			'Revda',
			'Rayevskiy',
			'Pyaozerskiy',
			'Pustoshka',
			'Puchezh',
			'Kipen’',
			'Prokhladnyy',
			'Pokrovskoye',
			'Podosinovets',
			'Ryazanskiy',
			'Pionerskiy',
			'Petrozavodsk',
			'Petrovskaya',
			'Pervomayskiy',
			'Pavlovka',
			'Novospasskoye',
			'Novosil’',
			'Novokuz’minki',
			'Novoderevyankovskaya',
			'Nizhniy Odes',
			'Nizhniy Novgorod',
			'Nizhnetroitskiy',
			'Nikol’sk',
			'Nezlobnaya',
			'Nekrasovka',
			'Narimanov',
			'Moscow',
			'Molokovo',
			'Metallostroy',
			'Maykor',
			'Marks',
			'Magaramkent',
			'Lermontov',
			'Kuyeda',
			'Kuybyshevo',
			'Kursk',
			'Nizhniy Kurkuzhin',
			'Krasnyy Yar',
			'Kotlyarevskaya',
			'Kosaya Gora',
			'Korenëvo',
			'Kommunar',
			'Kochevo',
			'Klintsy',
			'Kislovodsk',
			'Kirillov',
			'Kiknur',
			'Khokhlovo',
			'Kharabali',
			'Kesova Gora',
			'Kemlya',
			'Karalat',
			'Kanevskaya',
			'Kambileyevskoye',
			'Kalikino',
			'Kachkanar',
			'Ivanovskoye',
			'Iglino',
			'Gundelen',
			'Grigoropolisskaya',
			'Gidrotorf',
			'Gaptsakh',
			'Fokino',
			'Firsanovka',
			'Dzhiginka',
			'Dubrovka',
			'Dolgoprudnyy',
			'Dobryatino',
			'Dmitrov',
			'Dergachi',
			'Chukhloma',
			'Chkalovskiy',
			'Chernitsyno',
			'Chastyye',
			'Bylym',
			'Burevestnik',
			'Bogovarovo',
			'Benoy-Yurt',
			'Ilaskhan-Yurt',
			'Bashmakovo',
			'Babayevo',
			'Ayutinskiy',
			'Asekeyevo',
			'Amzya',
			'Aleksandro-Nevskiy',
			'Aksakovo',
			'Agoy',
			'Vidyayevo',
			'Gornoye Loo',
			"Bol'shoe Isakovo",
			'Belozërnyy',
			'Sovkhoznyy',
			'Znamenskoye',
			'Zelenogorskiy',
			'Yashkino',
			'Troitsk',
			'Tavda',
			'Smolenskoye',
			'Potanino',
			'Petukhovo',
			'Pavlogradka',
			'Ordynskoye',
			'Novolugovoye',
			'Nizhnyaya Salda',
			'Nazyvayevsk',
			'Mezhdurechenskiy',
			'Krasnoobsk',
			'Kaz',
			'Inta',
			'Ilanskiy',
			'Gornyy Shchit',
			'Bredy',
			'Borodino',
			'Aleksandrovskoye',
			'Zhireken',
			'Zarechnyy',
			'Tommot',
			'Tavrichanka',
			'Talakan',
			'Spasskoye',
			'Sharalday',
			'Nizhniy Sayantuy',
			'Sangar',
			'Pogranichnyy',
			'Pervomayskiy',
			'Nerchinskiy Zavod',
			'Makkaveyevo',
			'Kurort-Darasun',
			'Khingansk',
			'Kamen’-Rybolov',
			'Bokhan',
			'Bagdarin',
			'Babstovo',
			'Dalnegorsk',
			"Sinegor'ye",
			'Okhotsk',
			'Mil’kovo',
			'Pervoye Maya',
			'Pokachi',
			'Shevlyakovo',
			'Podosinki',
			'Novozavidovskiy',
			'Krasnolesnyy',
			'Zheleznodorozhnyy',
			'Zhankhoteko',
			'Yelovo',
			'Yelan’',
			'Yaransk',
			'Novaya Maka',
			'Yam',
			'Vacha',
			'Uvarovo',
			'Undory',
			'Tver',
			'Tsurib',
			'Trubchevsk',
			'Troitskaya',
			'Temirgoyevskaya',
			'Tekstil’shchiki',
			'Tatarskaya Kargala',
			'Tarki',
			'Tarasovka',
			'Syrostan',
			'Sukhaya Buyvola',
			'Strelka',
			'Stavropol’',
			'Starokucherganovka',
			'Spasskoye',
			'Khebda',
			'Sochi',
			'Skolkovo',
			'Shudayag',
			'Shenkursk',
			'Sharan',
			'Shal’skiy',
			'Savvinskaya Sloboda',
			'Sarmanovo',
			'Rodnikovskaya',
			'Revyakino',
			'Reshetikha',
			'Prikubanskiy',
			'Pravdinsk',
			'Pavlovskaya Sloboda',
			'Pavlodol’skaya',
			'Pachelma',
			'Otradnoye',
			'Ostrovtsy',
			'Oparino',
			'Ol’gino',
			'Odoyev',
			'Oblivskaya',
			'Novoye Devyatkino',
			'Novouzensk',
			'Novoivanovskoye',
			'Novki',
			'Nizhneivkino',
			'Nikologory',
			'Nadezhda',
			'Nachalovo',
			'Myaksa',
			'Molokovo',
			'Mednogorsk',
			'Makar’yev',
			'Loknya',
			'Lesnoy Gorodok',
			'Lal’sk',
			'Kyzburun Pervyy',
			'Kuyvozi',
			'Kurlovo',
			'Kukushtan',
			'Kukmor',
			'Kromy',
			'Krasnyye Barrikady',
			'Krasnogorskoye',
			'Presnenskiy',
			'Koygorodok',
			'Koshki',
			'Konstantinovskaya',
			'Konstantinovsk',
			'Kola',
			'Kharlu',
			'Karpushikha',
			'Kalyazin',
			'Kalach',
			'Itum-Kali',
			'Insar',
			'Imeni Vorovskogo',
			'Il’inskoye-Khovanskoye',
			'Martan-Chu',
			'Goyty',
			'Gorbatov',
			'Glafirovka',
			'Fedurnovo',
			'Ertil’',
			'Ekazhevo',
			'Duminichi',
			'Dubovka',
			'Demikhovo',
			'Dal’neye Konstantinovo',
			'Chagoda',
			'Borodinskiy',
			'Borisoglebskiy',
			'Borisoglebsk',
			'Belyye Stolby',
			'Balezino',
			'Aykino',
			'Avsyunino',
			'Ateptsevo',
			'Adil’-Yangiyurt',
			'Kovalevskoye',
			'Udarnyy',
			'Novyye Lapsary',
			'Mursalimkino',
			'Verkhniy Ufaley',
			'Turinskaya Sloboda',
			'Tuim',
			'Tobolsk',
			'Temirtau',
			'Taseyevo',
			'Podsosnovo',
			'Parabel’',
			'Martynovo',
			'Kolpashevo',
			'Chulym',
			'Bystryy Istok',
			'Zhiletovo',
			'Zheleznodorozhnyy',
			'Zaokskiy',
			'Yelizavetinskaya',
			'Yedrovo',
			'Yakovlevskoye',
			'Vyartsilya',
			'Volga',
			'Vladimir',
			'Vinsady',
			'Verkhniye Sergi',
			'Verkhnechusovskiye Gorodki',
			'Venëv',
			'Uspenskoye',
			'Urus-Martan',
			'Uchkeken',
			'Tuymazy',
			'Tubinskiy',
			'Tomsino',
			'Toksovo',
			'Teryayevo',
			'Terek',
			'Syas’stroy',
			'Svobody',
			'Sudislavl’',
			'Starocherkasskaya',
			'Sosnovka',
			'Slantsy',
			'Skoropuskovskiy',
			'Silikatnyy',
			'Shushary',
			'Kvartsitnyy',
			'Shepsi',
			'Shemordan',
			'Shedok',
			'Severo-Zadonsk',
			'Severodvinsk',
			'Rybnoye',
			'Ryazan’',
			'Rutul',
			'Reutov',
			'Pushchino',
			'Porkhov',
			'Polessk',
			'Poldnëvoye',
			'Petrodvorets',
			'Oktyabr’skiy',
			'Okhansk',
			'Novyy Nekouz',
			'Novozybkov',
			'Novolakskoye',
			'Novokorsunskaya',
			'Neya',
			'Nebug',
			'Muslyumovo',
			'Murmino',
			'Mezen’',
			'Yaroslavskiy',
			'Lyangasovo',
			'Luppolovo',
			'Loukhi',
			'L’govskiy',
			'Levokumskoye',
			'Lesnyye Polyany',
			'Latnaya',
			'Kyzyl-Oktyabr’skiy',
			'Kupchino',
			'Kropachëvo',
			'Krasyukovskaya',
			'Krasnyy Yar',
			'Krasnyy Klyuch',
			'Krasnaya Yaruga',
			'Koz’modem’yansk',
			'Korenovsk',
			'Kommunarka',
			'Kolchanovo',
			'Khuchni',
			'Khasavyurt',
			'Karagay',
			'Kamyshin',
			'Kamenka',
			'Kaliningrad',
			'Izhevskoye',
			'Gusev',
			'Golovino',
			'Gavrilov-Yam',
			'Dzerzhinsk',
			'Dorogobuzh',
			'Donetsk',
			'Detchino',
			'Dachnoye',
			"Bol'shiye Vyazëmy",
			'Belorechensk',
			'Bagramovo',
			'Atig',
			'Ashil’ta',
			'Arkadak',
			'Andreyevskoye',
			'Aleksandrov Gay',
			'Aksay',
			'Akhtubinsk',
			'Achkhoy-Martan',
			'Abrau-Dyurso',
			'Pervomayskoye',
			'Alagir',
			'Zavetnyy',
			'Khazar',
			'Mednogorskiy',
			'Nezhinskiy',
			'Yemanzhelinsk',
			'Verkhnyaya Sinyachikha',
			'Uyar',
			'Sherkaly',
			'Severka',
			'Saranpaul’',
			'Rostovka',
			'Polunochnoye',
			'Pervomayskiy',
			'Pelym',
			'Novobiryusinskiy',
			'Novichikha',
			'Norilsk',
			'Muslyumovo',
			'Motygino',
			'Mishkino',
			'Minusinsk',
			'Krasnogvardeyskiy',
			'Klyuchi',
			'Isetskoye',
			'Choya',
			'Burla',
			'Borovikha',
			'Bogashevo',
			'Bogandinskiy',
			'Berëzovka',
			'Belyy Yar',
			'Lokomotivnyy',
			'Zabaykal’sk',
			'Volochayevka Vtoraya',
			'Verkhnevilyuysk',
			'Ushumun',
			'Tyret’ Pervaya',
			'Tynda',
			'Tiksi',
			'Solnechnyy',
			'Slavyanka',
			'Shimanovsk',
			'Primorskiy',
			'Pivovarikha',
			'Novyy Zagan',
			'Mnogovershinnyy',
			'Magdagachi',
			'Kotik',
			'Blagodatnoye',
			'Khabarovsk Vtoroy',
			'Uglezavodsk',
			'Tymovskoye',
			'Ozernovskiy',
			'Muromtsevo',
			'Kurortnyy',
			'LMS',
			'Obruchevo',
			'Zyuzino',
			'Zhulebino',
			'Zasosna',
			'Zamoskvorech’ye',
			'Yeyskoye Ukrepleniye',
			'Yeysk',
			'Yershichi',
			'Yantikovo',
			'Vyksa',
			'Vostochnyy',
			'Volzhsky',
			'Veshenskaya',
			'Lakkha Nëvre',
			'Urman',
			'Uglegorskiy',
			'Turgenevo',
			'Troitskoye',
			'Tolbazy',
			'Tetyushi',
			'Terskaya',
			'Taganrog',
			'Sychëvka',
			'Sukkozero',
			'Stepantsevo',
			'Starotitarovskaya',
			'Staroderevyankovskaya',
			'Staraya Russa',
			'Spas-Zaulok',
			'Sovetskaya',
			'Sokol’skoye',
			'Slashchevskaya',
			'Skorodnoye',
			'Sinegorskiy',
			'Sim',
			'Shentala',
			'Shamkhal-Termen',
			'Sokur',
			'Novokuznetsk',
			'Nazarovo',
			'Kyshtovka',
			'Koshurnikovo',
			'Kamen’-na-Obi',
			'Bobrovka',
			'Barabinsk',
			'Bakchar',
			'Artyshta',
			'Aleysk',
			'Uyskoye',
			'Ust-Kuyga',
			'Svirsk',
			'Romny',
			'Pokosnoye',
			'Novosysoyevka',
			'Krasnokamensk',
			'Khonkholoy',
			'Kholbon',
			'Bol’shoy Kamen’',
			'Bereznyaki',
			'Atamanovka',
			'Angarsk',
			'Otrada',
			'Omsukchan',
			'Nevel’sk',
			'Makarov',
			'Beringovskiy',
			'Orekhovo-Borisovo',
			'Zarya',
			'Peresvet',
			'Fedorovskiy',
			'Vysokiy',
			'Semibratovo',
			'Selizharovo',
			'Ryazhsk',
			'Ramenki',
			'Pushkinskiye Gory',
			'Popovo',
			'Pleshanovo',
			'Pirogovo',
			'Pervoavgustovskiy',
			'Pechora',
			'Pechersk',
			'Ordzhonikidzevskiy',
			'Ol’ginskaya',
			'Ochakovo-Matveyevskoye',
			'Nozhay-Yurt',
			'Novopistsovo',
			'Novodmitriyevskaya',
			'Novaya Derevnya',
			'Neklyudovo',
			'Mishkino',
			'Mesker-Yurt',
			'Mechetinskaya',
			'Malaya Serdoba',
			'Lyubytino',
			'Lyuban’',
			'Lyaskelya',
			'Lukh',
			'Kuzovatovo',
			'Kuzino',
			'Kstovo',
			'Krasnoarmeyskoye',
			'Krasnaya Zarya',
			'Korsakovo',
			'Komsomol’skiy',
			'Kirsanov',
			'Kirov',
			'Kineshma',
			'Khrabrovo',
			'Kholm',
			'Kargopol’',
			'Kamensk-Shakhtinsky',
			'Kalinovskoye',
			'Istra',
			'Imeni Tsyurupy',
			'Imeni Sverdlova',
			'Ikon-Khalk',
			'Iki-Burul',
			'Gur’yevsk',
			'Gerzel’-Aul',
			'Gayny',
			'Gadzhiyevo',
			'Frolishchi',
			'Ferzikovo',
			'Fatezh',
			'Fakel',
			'Dvortsy',
			'Dolgorukovo',
			'Dimitrovgrad',
			'Dedino',
			'Debesy',
			'Danilov',
			'Chistoye',
			'Chernyshkovskiy',
			'Cherepovets',
			'Chaltyr',
			'Buinsk',
			'Boksitogorsk',
			'Bogorodskoye',
			'Bogoroditsk',
			'Belorechensk',
			'Barda',
			'Baltay',
			'Balakhonovskoye',
			'Bakhilovo',
			'Asanovo',
			'Aprelevka',
			'Aleksandrovsk',
			'Kokrek',
			'Kara-Tyube',
			'Imeni Pervogo Maya',
			'Rozhdestvenskiy',
			'Annino',
			'Pasha',
			'Vnukovo',
			'Zapolyarnyy',
			'Vorkuta',
			'Veseloyarsk',
			'Tyazhinskiy',
			'Tara',
			'Suzun',
			'Sorsk',
			'Novosilikatnyy',
			'Novoomskiy',
			'Neyvo-Rudyanka',
			'Molchanovo',
			'Leninsk-Kuznetsky',
			'Kyshtym',
			'Kolyvan’',
			'Kochenëvo',
			'Khandagayty',
			'Gornyak',
			'Gornopravdinsk',
			'Cherepanovo',
			'Chemal',
			'Boguchany',
			'Baraba',
			'Purpe',
			'Usol’ye-Sibirskoye',
			'Uglovoye',
			'Saskylakh',
			'Never',
			'Mayya',
			'Kirovskiy',
			'Khrebtovaya',
			'Izvestkovyy',
			'Gornyye Klyuchi',
			'Darasun',
			'Birakan',
			'Novyy Urgal',
			'Bratsk',
			'Yablochnyy',
			'Vakhrushev',
			'Ust-Nera',
			'Tomari',
			'Magadan',
			'Lazarev',
			'Anadyr',
			'Baltiysk',
			'Magas',
			'Gorshkovo',
			'Filippovka',
			"Mezgor'e",
			'Zhukovka',
			'Zharkovskiy',
			'Zelenograd',
			'Zavolzh’ye',
			'Zavidovo',
			'Yushkozero',
			'Yuganets',
			'Yug',
			'Vysotskoye',
			'Vypolzovo',
			'Vologda',
			'Vodnyy',
			'Vetluga',
			'Verkhoshizhem’ye',
			'Verkhniye Tatyshly',
			'Verkhniy Baskunchak',
			'Vereya',
			'Velikovechnoye',
			'Velikent',
			'Vakhtan',
			'Uralets',
			'Ugra',
			'Uglyanets',
			'Ufa',
			'Tyrnyauz',
			'Tutayev',
			'Turki',
			'Trudfront',
			'Trosna',
			'Teykovo',
			'Terbuny',
			'Temyasovo',
			'Sunzha',
			'Sterlibashevo',
			'Staroutkinsk',
			'Sovetsk',
			'Solntsevo',
			'Solnechnogorsk',
			'Sokolovskoye',
			'Sofrino',
			'Skuratovskiy',
			'Sitniki',
			'Seredeyskiy',
			'Semënov',
			'Sedkyrkeshch',
			'Sarapul',
			'Rodionovo-Nesvetaiskoye',
			'Ramon’',
			'Psygansu',
			'Privolzhsk',
			'Podlesnoye',
			'Pistsovo',
			'Petropavlovskaya',
			'Pallasovka',
			'Orlovo',
			'Nyazepetrovsk',
			'Novyy Byt',
			'Novoshcherbinovskaya',
			'Novoshakhtinsk',
			'Novopokrovka',
			'Nizhniy Kislyay',
			'Nikol’skoye-na-Cheremshane',
			'Nevel’',
			'Navlya',
			'Morshansk',
			'Monchegorsk',
			'Mikhaylovka',
			'Mezinovskiy',
			'Mayskoye',
			'Mayskiy',
			'Mayskiy',
			'Matveyevskoye',
			'Madzhalis',
			'Lyamino',
			'Lozhki',
			'Kumylzhenskaya',
			'Krivyanskaya',
			'Krasnyy Sulin',
			'Krasnaya Polyana',
			'Kotel’nikovo',
			'Korotoyak',
			'Kormëzhka',
			'Konstantinovskaya',
			'Komsomol’sk',
			'Kolomna',
			'Kodino',
			'Kizel',
			'Kitovo',
			'Kislyakovskaya',
			'Kirovskaya',
			'Kirovsk',
			'Kingisepp',
			'Khumalag',
			'Kerchevskiy',
			'Kazanskaya',
			'Kalino',
			'Izobil’nyy',
			'Izhma',
			'Izborsk',
			'Ivnya',
			'Inderka',
			'Imeni Zhelyabova',
			'Imeni Morozova',
			'Imeni Babushkina',
			'Ilek',
			'Groznyy',
			'Gorokhovets',
			'Gornyy Balykley',
			'Gorbatovka',
			'Ezhva',
			'Dulyapino',
			'Chuchkovo',
			'Chernyanka',
			'Chernoyerkovskaya',
			'Chernoistochinsk',
			'Chornoye',
			'Buzuluk',
			'Bol’shaya Martynovka',
			'Bobrov',
			'Berezanskaya',
			'Belousovo',
			'Belgorod',
			'Baturinskaya',
			'Babynino',
			'Altud',
			'Almaznyy',
			'Akusha',
			'Aksarayskiy',
			'Pavlovo',
			'Novoterskiy',
			'Raduzhnyy',
			'Povolzhskiy',
			'Zykovo',
			'Yuzhnoural’sk',
			'Vishnëvogorsk',
			'Varna',
			'Usyatskoye',
			'Topol’noye',
			'Staryy Nadym',
			'Sorokino',
			'Soloneshnoye',
			'Slavgorod',
			'Shchelkun',
			'Salym',
			'Petropavlovskoye',
			'Nizhniy Ingash',
			'Krasnyy Yar',
			'Krasnoyarsk',
			'Krasnokamensk',
			'Kosh-Agach',
			'Korkino',
			'Kommunar',
			'Karabash',
			'Irtyshskiy',
			'Irbit',
			'Borovoy',
			'Bogotol',
			'Zhigalovo',
			'Turka',
			'Novonukutskiy',
			'Tambovka',
			'Skovorodino',
			'Shilka',
			'Severomuysk',
			'Pokrovsk',
			'Luchegorsk',
			'Kyra',
			'Kitoy',
			'Khorinsk',
			'Ivanovka',
			'Bichura',
			'Amazar',
			'Srednekolymsk',
			'Evensk',
			'Dukat',
			'Aleksandrovsk-Sakhalinskiy',
			'Raduzhnyy',
			'Grazhdanka',
			'Chistyye Bory',
			'Algatuy',
			'Zarech’ye',
			'Kosterevo',
			'Zhirnov',
			'Zhdankovskiy',
			'Zarechnyy',
			'Zalegoshch’',
			'Yelshanka',
			'Yelan’-Koleno',
			'Voznesenskaya',
			'Voloshka',
			'Volgorechensk',
			'Vladimirskoye',
			'Veydelevka',
			'Varnavino',
			'Vakhrushi',
			'Uzunovo',
			'Ust’-Katav',
			'Usisha',
			'Uryv-Pokrovka',
			'Umet',
			'Tukan',
			'Tikhvin',
			'Tereze',
			'Tëmkino',
			'Tarskoye',
			'Suda',
			'Slavsk',
			'Shumyachi',
			'Shali',
			'Severnyy',
			'Serpukhov',
			'Selyatino',
			'Saraktash',
			'Samarskoye',
			'Safonovo',
			'Radumlya',
			'Pryazha',
			'Proletarsk',
			'Pregradnoye',
			'Prechistoye',
			'Pomary',
			'Polyarnyy',
			'Podgorenskiy',
			'Pirogovskiy',
			'Parfen’yevo',
			'Paraul',
			'Panino',
			'Overyata',
			'Ostrogozhsk',
			'Ostashëvo',
			'Onega',
			'Novovelichkovskaya',
			'Novonikolayevskiy',
			'Novobataysk',
			'Nizhnedevitsk',
			'Nikolayevka',
			'Nikola-Lenivets',
			'Nazran’',
			"Nar'yan-Mar",
			'Naro-Fominsk',
			'Nadterechnoje',
			'Mozhaysk',
			'Moskovskoye',
			'Mordino',
			'Marevo',
			'Malyye Derbety',
			'Lytkarino',
			'Lyskovo',
			'Lovozero',
			'Lenina',
			'Kuznetsk',
			'Kulotino',
			'Kudeyevskiy',
			'Krechevitsy',
			'Krasnyy Kurgan',
			'Krasnoye',
			'Kostino',
			'Kologriv',
			'Kiyevskoye',
			'Kimovsk',
			"Khot'kovo",
			'Khorlovo',
			'Khokhryaki',
			'Kastornoye',
			'Kasimov',
			'Karsun',
			'Karachev',
			'Karachayevsk',
			'Kalininaul',
			'Kafyr-Kumukh',
			'Kabardinka',
			'Kabakovo',
			'Izmaylovo',
			'Ishnya',
			'Imeni Vladimira Il’icha Lenina',
			'Grazhdanskoye',
			'Grabovo',
			'Gavrilov Posad',
			'Fryanovo',
			'Dubrovitsy',
			'Druzhnaya Gorka',
			'Dorogomilovo',
			'Dobryanka',
			'Desnogorsk',
			'Cherëmukhovo',
			'Chelno-Vershiny',
			'Chaykovskaya',
			'Chamlykskaya',
			'Buy',
			'Budogoshch’',
			'Breytovo',
			'Borovsk',
			'Borisova Griva',
			'Bol’shoy Khomutets',
			'Bereslavka',
			'Beloomut',
			'Baltasi',
			'Balasheyka',
			'Balakirevo',
			'Bagayevskaya',
			'Arkhangel’skoye',
			'Ali-Berdukovskiy',
			'Alekseyevka',
			'Aleksandrovka',
			'Ak”yar',
			'Adyge-Khabl',
			'Achit',
			'Verkhnebakanskiy',
			'Krasnobrodskiy',
			'Yurty',
			'Yurga',
			'Yarkovo',
			'Uvat',
			'Tselinnoye',
			'Sannikovo',
			'Rebrikha',
			'Pritomskiy',
			'Omutinskiy',
			'Ob’',
			'Novosibirsk',
			'Novogornyy',
			'Neyvo-Shaytanskiy',
			'Lesogorsk',
			'Kulunda',
			'Krutinka',
			'Krasnogorskiy',
			'Inya',
			'Anton’yevka',
			'Kedrovyy',
			'Roshchino',
			'Zheleznodorozhnyy',
			'Vydrino',
			'Preobrazheniye',
			'Novyy Uoyan',
			'Markovo',
			'Listvyanka',
			'Kudara-Somon',
			'Kalanguy',
			'Batagay',
			'Dzerzhinsk',
			'Yuzhno-Sakhalinsk',
			'Pravda',
			'Belaya Gora',
			'Povedniki',
			'Emar',
			'Stantsia Staritsa',
			'Zagorskie Dali',
			'Zverevo',
			'Zvenigovo',
			'Zelenoborskiy',
			'Zasechnoye',
			'Yermolino',
			'Yegorlykskaya',
			'Yagry',
			'Vyshkov',
			'Vyselki',
			'Vozhega',
			'Voroshnëvo',
			'Vasyurinskaya',
			'Vaskelovo',
			'Varlamovo',
			'Utorgosh',
			'Urozhaynoye',
			'Ullubiyaul',
			'Trunovskoye',
			'Tovarkovskiy',
			'Tonshayevo',
			'Timashyovsk',
			'Tikhoretsk',
			'Tbilisskaya',
			'Syrtych',
			'Staroleushkovskaya',
			'Somovo',
			'Soldato-Aleksandrovskoye',
			'Smyshlyayevka',
			'Shumerlya',
			'Shkurinskaya',
			'Shigony',
			'Setun’',
			'Serdobsk',
			'Savinka',
			'Saratovskaya',
			'Radovitskiy',
			'Proletarskiy',
			'Praskoveya',
			'Annino',
			'Polyany',
			'Podgornoye',
			'Pil’na',
			'Pestretsy',
			'Peschanokopskoye',
			'Pavlovskiy',
			'Pasegovo',
			'Partizan',
			'Novosokol’niki',
			'Novoorsk',
			'Novokayakent',
			'Novoaleksandrovsk',
			'Novaya Usman’',
			'Nogir',
			'Nartan',
			'Murashi',
			'Mamedkala',
			'Malyshevo',
			'Malaya Vishera',
			'Malaya Purga',
			'Lyubim',
			'Lukoyanov',
			'Lukhovitsy',
			'Likino-Dulevo',
			'Leninskoye',
			'Kvarkeno',
			'Kuzhener',
			'Kurkino',
			'Kropotkin',
			'Kronstadt',
			'Kratovo',
			'Krasnyy Kholm',
			'Krasnyy Bogatyr’',
			'Krasnoye',
			'Krasnogvardeyskoye',
			'Krasnodar',
			'Konstantinovskiy',
			'Komarikhinskiy',
			'Komarichi',
			'Yanino-1',
			'Kizilyurt',
			'Kirovskiy',
			'Kirov',
			'Kil’mez’',
			'Khryashchevka',
			'Khotynets',
			'Khanino',
			'Khamamatyurt',
			'Katav-Ivanovsk',
			'Kasumkent',
			'Karabudakhkent',
			'Kangly',
			'Kalashnikovo',
			'Isyangulovo',
			'Issad',
			'Gurbuki',
			'Gul’kevichi',
			'Gubskaya',
			'Fryazevo',
			'Froly',
			'Essoyla',
			'Duba-Yurt',
			'Dmitrovsk',
			'Chistopol’',
			'Chernogolovka',
			'Chekmagush',
			'Chebsara',
			'Bogorodskoye',
			'Bezenchuk',
			'Bessonovka',
			'Baymak',
			'Balashikha',
			'Baksan',
			'Asha',
			'Apsheronsk',
			'Andreyevo',
			'Anapa',
			'Akhtanizovskaya',
			'Agronom',
			'Dubovka',
			'Bugry',
			'Lesnoy',
			'Shamil’kala',
			'Volovo',
			'Biorki',
			'Zyuzel’skiy',
			'Zavodoukovsk',
			'Tegul’det',
			'Tarko-Sale',
			'Svetlyy',
			'Staryy Togul',
			'Sredneuralsk',
			'Sherbakul’',
			'Shagonar',
			'Severka',
			'Samagaltay',
			'Novovarshavka',
			'Novotyryshkino',
			'Muzhi',
			'Muromtsevo',
			'Lebyazh’ye',
			'Kyzyl-Mazhalyk',
			'Kungurtug',
			'Kondinskoye',
			'Kasli',
			'Kartaly',
			'Kargat',
			'Kaa-Khem',
			'Filimonovo',
			'Butka',
			'Bolotnoye',
			'Bobrovka',
			'Berdsk',
			'Beloyarskiy',
			'Baykit',
			'Barsovo',
			'Aramil',
			'Alzamay',
			'Svobodnyy',
			'Raduzhny',
			'Zheleznogorsk-Ilimskiy',
			'Zhatay',
			'Ytyk-Kyuyël’',
			'Yerbogachen',
			'Yaroslavskiy',
			'Verkhoyansk',
			'Fokino',
			'Sheragul',
			'Selikhino',
			'Ol’ga',
			'Nizhneangarsk',
			'Kirensk',
			'Khor',
			'Imeni Poliny Osipenko',
			'Gazimurskiy Zavod',
			'Chul’man',
			'Chita',
			'Aleksandrovskiy Zavod',
			'Tungor',
			'Tigil’',
			'Sovetskaya Gavan’',
			'Gastello',
			'Shikotan',
			'Raduzhnyy',
			'Nizhnesortymskiy',
			'Singapay',
			'Sosnovka',
			'Znamenskoye',
			'Zhukovka',
			'Zhadovka',
			'Zaterechnyy',
			'Yershovo',
			'Yel’nya',
			'Yegor’yevsk',
			'Yasnogorsk',
			'Yakovlevo',
			'Yadrin',
			'Vysokovsk',
			'Vybor',
			'Vorsha',
			'Volkhov',
			'Veshnyaki',
			'Uryupinsk',
			'Ukholovo',
			'Uglerodovskiy',
			'Torfyanoy',
			'Ternevskaya',
			'Suzëmka',
			'Surovatikha',
			'Sudzha',
			'Strelitsa',
			'Stodolishche',
			'Stavrovo',
			'Starovelichkovskaya',
			'Starominskaya',
			'Shcherbinka',
			'Shalya',
			'Shakhta',
			'Shaami-Yurt',
			'Sertolovo',
			'Salavat',
			'Rzhev',
			'Roslavl’',
			'Rogovatoye',
			'Redkino',
			'Rechitsy',
			'Pudem',
			'Psyzh',
			'Podgornoye',
			'Pochinki',
			'Piterka',
			'Peredovaya',
			'Penza',
			'Pavlovo',
			'Pavlovka',
			'Osel’ki',
			'Osa',
			'Oksovskiy',
			'Novovyazniki',
			'Novosergiyevka',
			'Novomikhaylovskiy',
			'Novaya Mayna',
			'Naryshkino',
			'Narovchat',
			'Nagutskoye',
			'Nagorsk',
			'Mstera',
			'Mendeleyevskiy',
			'Meleuz',
			'Matrosy',
			'Lesnyye Polyany',
			'Lazarevskoye',
			'Krasnyy Yar',
			'Krasnovishersk',
			'Krasnaya Poyma',
			'Krasnaya Polyana',
			'Krasnaya Gorbatka',
			'Kostino',
			'Koslan',
			'Koltubanovskiy',
			'Kokhma',
			'Preobrazhenskaya',
			'Khunzakh',
			'Kalevala',
			'Ispravnaya',
			'Idritsa',
			'Gzhel’',
			'Gremyachinsk',
			'Goryachiy Klyuch',
			'Georgiyevsk',
			'Gavrilovka Vtoraya',
			'Gagino',
			'Dobrinka',
			'Dmitriyevskaya',
			'Davydovka',
			'Chupa',
			'Cherusti',
			'Cherëmushki',
			'Bol’shoy Karay',
			'Bolshevo',
			'Bol’shaya Setun’',
			'Bogorodskoye',
			'Bilyarsk',
			'Besskorbnaya',
			'Belaya Kalitva',
			'Spassk',
			'Atyashevo',
			'Arsen’yevo',
			'Alleroy',
			'Alkhan-Churt',
			'Khambi-Irze',
			'Korkmaskala',
			'Lepley',
			'Krasnyy Kommunar',
			'Zaton',
			'Verkhnyaya Sysert’',
			'Velikopetrovka',
			'Staroaleyskoye',
			'Starobelokurikha',
			'Sovetskiy',
			'Shelabolikha',
			'Romanovo',
			'Rodino',
			'Pospelikha',
			'Pirovskoye',
			'Parizh',
			'Lyubinskiy',
			'Karpinsk',
			'Irsha',
			'Gon’ba',
			'Glyadyanskoye',
			'Dzerzhinskoye',
			'Azanka',
			'Askiz',
			'Aromashevo',
			'Ozersk',
			'Yedogon',
			'Yakovlevka',
			'Vitim',
			'Ust’-Barguzin',
			'Uglekamensk',
			'Tyrma',
			'Smolenka',
			'Shelekhov',
			'Plastun',
			'Olenyok',
			'Nekrasovka',
			'Mogzon',
			'Lazo',
			'Chkalovskoye',
			'Bol’shaya Rechka',
			'Baley',
			'Badar',
			'Okha',
			'Korsakov',
			'Shishkin Les',
			'Petrovskiy',
			'Sasovo',
			'Zelenogorsk',
			'Zelenodolsk',
			'Yelets',
			'Yasenevo',
			'Yakhroma',
			'Vyyezdnoye',
			'Voskresensk',
			'Vistino',
			'Verkhneyarkeyevo',
			'Verkhnetulomskiy',
			'Vereya',
			'Uyemskiy',
			'Ust’-Kishert’',
			'Uinskoye',
			'Udel’naya',
			'Tunoshna',
			'Tuapse',
			'Troitskoye',
			'Tarkhovka',
			'Tamala',
			'Talovyy',
			'Talitsy',
			'Syumsi',
			'Surskoye',
			'Suksun',
			'Staroyur’yevo',
			'Starotimoshkino',
			'Starosubkhangulovo',
			'Sokol’niki',
			'Sokol',
			'Sindor',
			'Shumeyka',
			'Shirochanka',
			'Shafranovo',
			'Shabel’skoye',
		],
	},
	Rwanda: {
		country: 'Rwanda',
		cities: [
			'Eglise Catholique, Centrale GIKO',
			'Kibungo',
			'Byumba',
			'Musanze',
			'Cyangugu',
			'Kibuye',
			'Nyanza',
			'Kigali',
			'Rwamagana',
			'Gikongoro',
			'Butare',
			'Gitarama',
			'Gisenyi',
			'Nzega',
		],
	},
	'Saint Helena': { country: 'Saint Helena', cities: ['Edinburgh of the Seven Seas', 'Jamestown', 'Georgetown'] },
	'Saint Kitts and Nevis': {
		country: 'Saint Kitts and Nevis',
		cities: [
			'Market Shop',
			'Newcastle',
			'Dieppe Bay Town',
			'Cotton Ground',
			'Charlestown',
			'Trinity',
			'Middle Island',
			'Sandy Point Town',
			'Saint Paul’s',
			'Nicola Town',
			'Fig Tree',
			'Basseterre',
			'Monkey Hill',
			'Cayon',
		],
	},
	'Saint Lucia': {
		country: 'Saint Lucia',
		cities: [
			'Choiseul',
			'Canaries',
			'Micoud',
			'Soufrière',
			'Bisee',
			'Gros Islet',
			'Dennery',
			'Castries',
			'Praslin',
			'Laborie',
			'Vieux Fort',
			'Anse La Raye',
		],
	},
	'Saint Pierre and Miquelon': { country: 'Saint Pierre and Miquelon', cities: ['Miquelon', 'Saint-Pierre'] },
	'Saint Vincent and the Grenadines': {
		country: 'Saint Vincent and the Grenadines',
		cities: ['Kingstown', 'Chateaubelair', 'Georgetown', 'Biabou', 'Kingstown Park', 'Port Elizabeth', 'Byera Village', 'Barrouallie', 'Layou'],
	},
	Samoa: {
		country: 'Samoa',
		cities: [
			'Solosolo',
			'Siusega',
			'Lufilufi',
			'Asau',
			'Gataivai',
			'Safotu',
			'Lotofagā',
			'Vailoa',
			'Vailima',
			'Falefa',
			'Safotulafai',
			'Nofoali‘i',
			'Vaiusu',
			'Satapuala',
			'Faleula',
			'Apia',
			'Mulifanua',
			'Fasito‘outa',
			'Matavai',
			'Satupa‘itea',
			'Samamea',
			'Malie',
			'Leulumoega',
			'Afega',
		],
	},
	'San Marino': {
		country: 'San Marino',
		cities: ['Fiorentino', 'Monte Giardino', 'Borgo Maggiore', 'Poggio di Chiesanuova', 'San Marino', 'Faetano', 'Acquaviva', 'Serravalle', 'Domagnano'],
	},
	'Sao Tome and Principe': {
		country: 'Sao Tome and Principe',
		cities: ['São João dos Angolares', 'Guadalupe', 'Trindade', 'São Tomé', 'Santo António'],
	},
	'Saudi Arabia': {
		country: 'Saudi Arabia',
		cities: [
			'Al Wajh',
			'Al Qaţīf',
			'Tabālah',
			'Hafar Al-Batin',
			'Ta’if',
			'Al Khafjī',
			'At Tūbī',
			'Abqaiq',
			'Badr Ḩunayn',
			'Al Qurayn',
			'Al Muwayh',
			'Raḩīmah',
			'Wed Alnkil',
			'Al Hadā',
			'Sabt Alalayah',
			'Umm as Sāhik',
			'Al Majma‘ah',
			'Ad Darb',
			'Jeddah',
			'shokhaibٍ',
			'Bariq',
			'Sulţānah',
			'Al Munayzilah',
			'Al Jarādīyah',
			'Tanūmah',
			'Ar Rass',
			'Qurayyat',
			'Al Kharj',
			'Al Bukayrīyah',
			'Dammam',
			'Abha',
			'Ain AlBaraha',
			'Şabyā',
			'Rābigh',
			'Aţ Ţaraf',
			'Al-`Ula',
			'Al Muţayrifī',
			'Al Markaz',
			'Al Jubayl',
			'Ad Dawādimī',
			'Ash Shafā',
			'Şāmitah',
			'Qal‘at Bīshah',
			'Şuwayr',
			'Tārūt',
			'Mislīyah',
			'Julayjilah',
			'Qaisumah',
			'Tumayr',
			'Turaif',
			'Ţubarjal',
			'Najrān',
			'An Nimas',
			'Al Majāridah',
			'Şafwá',
			'Al Mithnab',
			'Al Ju‘aymah',
			"Ha'il",
			'Arar',
			'Sakakah',
			'Mulayjah',
			'Az Zulfī',
			'Al Jubayl',
			'Buraydah',
			'Yanbu',
			'Turabah',
			'As Saffānīyah',
			'Al Baţţālīyah',
			'Marāt',
			'Jizan',
			'Sājir',
			'Abū ‘Arīsh',
			'Al Mubarraz',
			'Al Mindak',
			'Unaizah',
			'As Sulayyil',
			'Al Qārah',
			'Medina',
			'Mecca',
			'Al Jafr',
			'Mizhirah',
			'Khobar',
			'Ad Dilam',
			'Umluj',
			'Duba',
			'Al Jumūm',
			'Sayhāt',
			'Farasān',
			'Dhahran',
			'Al Bahah',
			'Afif',
			'Tabuk',
			'Khamis Mushait',
			'Riyadh',
			'Al Hufūf',
			'Al Arţāwīyah',
			'Al Awjām',
			'Adh Dhibiyah',
			'Al Fuwayliq',
		],
	},
	Senegal: {
		country: 'Senegal',
		cities: [
			'Sémé',
			'Polel Diaoubé',
			'Kédougou',
			'Goléré',
			'Warang',
			'Kolda',
			'Ziguinchor',
			'Sokone',
			'Saint-Louis',
			'Nioro du Rip',
			'Guéoul',
			'Ranérou',
			'Pikine',
			'Ndofane',
			'Kaolack',
			'Dakar',
			'Tionk Essil',
			'Diofior',
			'Dara',
			'Marsassoum',
			'Adéane',
			'Ouro Sogui',
			'Vélingara',
			'Tiébo',
			'Oussouye',
			'Matam',
			'Khombole',
			'Guinguinéo',
			'Tambacounda',
			'Tiadiaye',
			'Rosso',
			'Louga',
			'Thiès Nones',
			'Sédhiou',
			'Ndioum',
			'Kayar',
			'Touba',
			'Foundiougne',
			'Bignona',
			'Passi',
			'Thiès',
			'Pout',
			'Pourham',
			'Kaffrine',
			'Joal-Fadiout',
			'Fatick',
			'Nguékhokh',
			'Koungheul',
			'Richard-Toll',
			'Mékhé',
			'Diawara',
			'Gandiaye',
			'N’diareme limamoulaye',
			'Mbaké',
			'Waoundé',
			'Ndibène Dahra',
			'Kanel',
			'Diourbel',
			'Mermoz Boabab',
		],
	},
	Serbia: {
		country: 'Serbia',
		cities: [
			'Vojvoda Stepa',
			'Velika Plana',
			'Uzdin',
			'Trgovište',
			'Petrovac',
			'Mošorin',
			'Ivanjica',
			'Golubac',
			'Bela Crkva',
			'Bečej',
			'Banatski Dvor',
			'Aranđelovac',
			'Prislonica',
			'Palilula',
			'Sjenica',
			'Putinci',
			'Kamenica',
			'Vrnjačka Banja',
			'Smederevo',
			'Rajince',
			'Ostojićevo',
			'Nikolinci',
			'Jarkovac',
			'Dimitrovgrad',
			'Ćuprija',
			'Boka',
			'Belgrade',
			'Belo Blato',
			'Vrbas',
			'Kupusina',
			'Bački Petrovac',
			'Sumulicë',
			'Majur',
			'Kolut',
			'Despotovo',
			'Samoljica',
			'Ripanj',
			'Paraćin',
			'Obrenovac',
			'Novi Pazar',
			'Konak',
			'Janošik',
			'Duboka',
			'Višnjićevo',
			'Šašinci',
			'Ugrinovci',
			'Topola',
			'Ražanj',
			'Kuršumlija',
			'Krčedin',
			'Kovačica',
			'Farkaždin',
			'Despotovac',
			'Čukarica',
			'Čenta',
			'Bačko Gradište',
			'Ada',
			'Uzveće',
			'Sremska Mitrovica',
			'Joševa',
			'Drenovac',
			'Rumska',
			'Tomaševac',
			'Rušanj',
			'Rakovica',
			'Preševo',
			'Novi Karlovci',
			'Kumane',
			'Kovin',
			'Ečka',
			'Čestereg',
			'Maglić',
			'Gornja Bukovica',
			'Gložan',
			'Bogosavac',
			'Pocerski Pričinović',
			'Vlasotince',
			'Varvarin',
			'Sečanj',
			'Samoš',
			'Radovnica',
			'Požarevac',
			'Novi Kneževac',
			'Negotin',
			'Čačak',
			'Beška',
			'Bašaid',
			'Knić',
			'Vrdnik',
			'Veternik',
			'Temerin',
			'Kula',
			'Badovinci',
			'Veliko Gradište',
			'Velika Moštanica',
			'Toba',
			'Stubline',
			'Stari Lec',
			'Miratovac',
			'Valjevo',
			'Lipnički Šor',
			'Krivaja',
			'Apatin',
			'Jaša Tomić',
			'Gardinovci',
			'Donji Milanovac',
			'Blace',
			'Voždovac',
			'Varna',
			'Sakule',
			'Šajkaš',
			'Lokve',
			'Doljevac',
			'Crna Trava',
			'Bojnik',
			'Hrtkovci',
			'Čokešina',
			'Lagja e Poshtme',
			'Vojka',
			'Stari Banovci',
			'Srpski Itebej',
			'Pirot',
			'Neuzina',
			'Krajišnik',
			'Iđoš',
			'Ðurđevo',
			'Biljača',
			'Aradac',
			'Ruma',
			'Ravno Selo',
			'Čelarevo',
			'Banovo Polje',
			'Odžaci',
			'Kisač',
			'Crna Bara',
			'Bogojevo',
			'Belotić',
			'Sokolovo Brdo',
			'Osječenik',
			'Stara Pazova',
			'Srpska Crnja',
			'Sopot',
			'Perlez',
			'Orlovat',
			'Novi Slankamen',
			'Novi Kozarci',
			'Novi Bečej',
			'Mokrin',
			'Klek',
			'Ćićevac',
			'Bečmen',
			'Prijepolje',
			'Zrenjanin',
			'Žitorađa',
			'Stajićevo',
			'Padina',
			'Ovča',
			'Melenci',
			'Lukićevo',
			'Lok',
			'Alibunar',
			'Savski Venac',
			'Stepanovićevo',
			'Sombor',
			'Šid',
			'Mačvanska Mitrovica',
			'Žabari',
			'Pančevo',
			'Opovo',
			'Boljevac',
			'Bočar',
			'Baranda',
			'Raška',
			'Stari Grad',
			'Stanišić',
			'Kulpin',
			'Irig',
			'Vilovo',
			'Svrljig',
			'Surdulica',
			'Rekovac',
			'Malo Crniće',
			'Kruševac',
			'Ilandža',
			'Gudurica',
			'Gornji Milanovac',
			'Gadžin Han',
			'Čoka',
			'Belegiš',
			'Arilje',
			'Sokolovica',
			'Rusko Selo',
			'Tršić',
			'Štitar',
			'Sonta',
			'Platičevo',
			'Petkovica',
			'Vladičin Han',
			'Ub',
			'Radojevo',
			'Rača',
			'Ostružnica',
			'Lajkovac',
			'Kraljevo',
			'Kladovo',
			'Jazovo',
			'Jagodina',
			'Elemir',
			'Bor',
			'Aleksandrovac',
			'Rumenka',
			'Petrovaradin',
			'Mali Zvornik',
			'Lešnica',
			'Kosjerić',
			'Jarak',
			'Dobrić',
			'Bajina Bašta',
			'Metković',
			'Mali Iđoš',
			'Jadranska Lešnica',
			'Dublje',
			'Zlatibor',
			'Turija',
			'Stepojevac',
			'Seleuš',
			'Prokuplje',
			'Novi Beograd',
			'Kikinda',
			'Kanjiža',
			'Bujanovac',
			'Barajevo',
			'Banatska Topola',
			'Tabanović',
			'Šabac',
			'Zvečka',
			'Zaječar',
			'Vlajkovac',
			'Izbište',
			'Hajdučica',
			'Čurug',
			'Banatski Karlovac',
			'Vladimirci',
			'Sremski Karlovci',
			'Osečina',
			'Novi Sad',
			'Klenak',
			'Bukor',
			'Bački Breg',
			'Prnjavor',
			'Žitište',
			'Vranjska Banja',
			'Sutjeska',
			'Soko Banja',
			'Smederevska Palanka',
			'Mol',
			'Mionica',
			'Merošina',
			'Lučani',
			'Ljukovo',
			'Kragujevac',
			'Kovilj',
			'Gornji Breg',
			'Golubinci',
			'Debeljača',
			'Crepaja',
			'Batočina',
			'Banatsko Veliko Selo',
			'Banatsko Karađorđevo',
			'Aleksinac',
			'Salaš Noćajski',
			'Salaš Crnobarski',
			'Grabovci',
			'Žagubica',
			'Vranić',
			'Novi Banovci',
			'Nova Crnja',
			'Lapovo',
			'Kučevo',
			'Dobrica',
			'Barič',
			'Sinošević',
			'Ravnje',
			'Brdarica',
			'Žujince',
			'Velika Greda',
			'Novo Miloševo',
			'Mihajlovo',
			'Zminjak',
			'Nikinci',
			'Donji Dobrić',
			'Beočin',
			'Bačka Palanka',
			'Žabalj',
			'Vračar',
			'Požega',
			'Medveđa',
			'Jermenovci',
			'Hetin',
			'Zmajevo',
			'Užice',
			'Novo Selo',
			'Jarebice',
			'Glušci',
			'Senta',
			'Kozjak',
			'Niš',
			'Mala Moštanica',
			'Lugavčina',
			'Grabovac',
			'Čortanovci',
			'Barice',
			'Prigrevica',
			'Obrovac',
			'Buđanovci',
			'Bač',
			'Lipolist',
			'Vršac',
			'Veliki Gaj',
			'Ševica',
			'Nakovo',
			'Grocka',
			'Bačko Petrovo Selo',
			'Subotica',
			'Ribari',
			'Nova Varoš',
			'Krupanj',
			'Gakovo',
			'Čajetina',
			'Bačka Topola',
			'Torda',
			'Sajan',
			'Radenka',
			'Padej',
			'Nova Pazova',
			'Adorjan',
			'Kuštilj',
			'Knićanin',
			'Banatski Despotovac',
			'Zvezdara',
			'Kozjak',
			'Vladimirovac',
			'Umka',
			'Trstenik',
			'Titel',
			'Sremčica',
			'Pavliš',
			'Međa',
			'Lazarevac',
			'Inđija',
			'Zemun',
			'Aleksandrovo',
			'Taraš',
			'Surčin',
			'Sanad',
			'Ljig',
			'Lazarevo',
			'Idvor',
			'Bela Palanka',
			'Pećinci',
			'Jevremovac',
			'Draginje',
			'Ðurići',
			'Uljma',
			'Šimanovci',
			'Sefkerin',
			'Novi Itebej',
			'Lebane',
			'Botoš',
			'Srbobran',
			'Doroslovo',
			'Koceljeva',
			'Klenje',
			'Jelenča',
			'Knjazevac',
			'Vranje',
			'Veliko Središte',
			'Tutin',
			'Svilajnac',
			'Ravni Topolovac',
			'Margita',
			'Majdanpek',
			'Kupinovo',
			'Bosilegrad',
			'Torak',
			'Niška Banja',
			'Ševarice',
			'Radenković',
			'Priboj',
			'Surduk',
			'Plandište',
			'Mladenovac',
			'Leskovac',
			'Dobanovci',
			'Riđica',
			'Ljubovija',
			'Irig',
			'Bosut',
			'Bogatić',
			'Boljevci',
		],
	},
	Seychelles: {
		country: 'Seychelles',
		cities: ['Beau Vallon', 'Cascade', 'Anse Royale', 'La Passe', 'Anse Boileau', 'Victoria', 'Bel Ombre', 'Takamaka', 'Port Glaud'],
	},
	'Sierra Leone': {
		country: 'Sierra Leone',
		cities: [
			'Zimmi',
			'Koribundu',
			'Kenema',
			'Kamakwie',
			'Hastings',
			'Alikalia',
			'Tongole',
			'Segbwema',
			'Rotifunk',
			'Bonthe',
			'Wima',
			'Tefeya',
			'Sumbuya',
			'Jojoima',
			'Bumpe',
			'Baiima',
			'Tombodu',
			'Serabu',
			'Port Loko',
			'Panguma',
			'Bo',
			'Tombu',
			'Potoru',
			'Magburaka',
			'Binkolo',
			'Simbakoro',
			'Kassiri',
			'Masingbi',
			'Mamboma',
			'Lunsar',
			'Hangha',
			'Gandorhun',
			'Daru',
			'Bumbuna',
			'Blama',
			'Gbewebu',
			'Kent',
			'Mange',
			'Koyima',
			'Motema',
			'Yonibana',
			'Waterloo',
			'Rokupr',
			'Giehun',
			'Foindu',
			'Palima',
			'Pepel',
			'Masaka',
			'Koidu',
			'Pujehun',
			'Yengema',
			'Giehun',
			'Tintafor',
			'Makeni',
			'Boajibu',
			'Bunumbu',
			'Konakridee',
			'Masoyila',
			'Barma',
			'Makali',
			'Largo',
			'Kailahun',
			'Goderich',
			'Mogbwemo',
			'Manowa',
			'Moyamba',
			'Mobai',
			'Kambia',
			'Gorahun',
			'Gandorhun',
			'Gberia Fotombu',
			'Seidu',
			'Bomi',
			'Pendembu',
			'Freetown',
			'Mambolo',
			'Kayima',
			'Loma',
			'Buedu',
			'Sawkta',
			'Bindi',
			'Kukuna',
			'Kabala',
			'Baoma',
		],
	},
	Singapore: { country: 'Singapore', cities: ['Marine Parade', 'Woodlands', 'Singapore', 'Queenstown Estate', 'Geylang'] },
	Slovakia: {
		country: 'Slovakia',
		cities: [
			'Tornaľa',
			'Vysoká nad Kysucou',
			'Svätý Jur',
			'Lazany',
			'Krupina',
			'Kľačno',
			'Hurbanovo',
			'Svit',
			'Tisovec',
			'Šahy',
			'Rajec',
			'Malacky',
			'Hlohovec',
			'Humenné',
			'Gelnica',
			'Lehota pod Vtáčnikom',
			'Turčianske Teplice',
			'Sliač',
			'Rusovce',
			'Pribylina',
			'Nové Mesto nad Váhom',
			'Kováčová',
			'Bratislava',
			'Sečovce',
			'Nová Lesná',
			'Kežmarok',
			'Sládkovičovo',
			'Olešná',
			'Levice',
			'Kolárovo',
			'Handlová',
			'Bratislava - Vajnory',
			'Vinné',
			'Sobrance',
			'Šamorín',
			'Liptovský Hrádok',
			'Dudince',
			'Ždiar',
			'Poltár',
			'Makov',
			'Čachtice',
			'Biely Kostol',
			'Ľubica',
			'Zvolen',
			'Zborov nad Bystricou',
			'Staškov',
			'Nedožery - Brezany',
			'Leopoldov',
			'Vyšné Ružbachy',
			'Sabinov',
			'Vrútky',
			'Oravský Podzámok',
			'Krasňany',
			'Čierny Balog',
			'Spišská Belá',
			'Rožňava',
			'Lipany',
			'Košice',
			'Skalité',
			'Nováky',
			'Martin',
			'Krásno nad Kysucou',
			'Stropkov',
			'Strážske',
			'Medzev',
			'Modra',
			'Svrčinovec',
			'Nová Dubnica',
			'Komárno',
			'Brezno',
			'Kavečany',
			'Vráble',
			'Senec',
			'Liptovský Mikuláš',
			'Hriňová',
			'Fiľakovo',
			'Čierne',
			'Vrbov',
			'Michalovce',
			'Medzilaborce',
			'Zákopčie',
			'Vrbové',
			'Tvrdošín',
			'Smolenice',
			'Nová Baňa',
			'Nitra',
			'Námestovo',
			'Gbely',
			'Galanta',
			'Dubnica nad Váhom',
			'Bánovce nad Bebravou',
			'Turňa nad Bodvou',
			'Rimavská Sobota',
			'Klokočov',
			'Senica',
			'Kremnica',
			'Veľké Kapušany',
			'Revúca',
			'Trenčianske Teplice',
			'Tlmače',
			'Púchov',
			'Podvysoká',
			'Pezinok',
			'Kysucké Nové Mesto',
			'Bytča',
			'Prešov',
			'Dobšiná',
			'Nemšová',
			'Čadca',
			'Vranov nad Topľou',
			'Trebišov',
			'Giraltovce',
			'Stupava',
			'Prievidza',
			'Nová Bystrica',
			'Jarovce',
			'Spisska Sobota',
			'Žarnovica',
			'Veľký Krtíš',
			'Oščadnica',
			'Detva',
			'Jelšava',
			'Čierna nad Tisou',
			'Želiezovce',
			'Trenčín',
			'Skalica',
			'Hrochoť',
			'Dunajov',
			'Dunajská Lužná',
			'Krompachy',
			'Bardejov',
			'Žilina',
			'Tužina',
			'Trstená',
			'Trnava',
			'Topoľčany',
			'Svodín',
			'Lučenec',
			'Dolný Kubín',
			'Brezová pod Bradlom',
			'Svidník',
			'Terchová',
			'Pravenec',
			'Banská Štiavnica',
			'Vinosady',
			'Partizánske',
			'Nižná',
			'Ivanka pri Dunaji',
			'Hybe',
			'Žehra',
			'Štrba',
			'Chlmec',
			'Holíč',
			'Stará Ľubovňa',
			'Spišská Nová Ves',
			'Snina',
			'Podolínec',
			'Zlaté Moravce',
			'Žiar nad Hronom',
			'Šurany',
			'Šaľa',
			'Nové Zámky',
			'Marianka',
			'Banská Bystrica',
			'Piešťany',
			'Nitrianske Pravno',
			'Kanianka',
			'Dunajská Streda',
			'Poprad',
			'Moldava nad Bodvou',
			'Veľký Meder',
			'Svätý Anton',
			'Staré Mesto',
			'Raková',
			'Oravská Lesná',
			'Vysoké Tatry',
			'Turzovka',
			'Štúrovo',
			'Stará Turá',
			'Považská Bystrica',
			'Poruba',
			'Lúčky',
			'Komárno',
			'Ilava',
			'Spišské Podhradie',
			'Levoča',
			'Stará Bystrica',
			'Ružomberok',
			'Myjava',
			'Gabčíkovo',
			'Bojnice',
		],
	},
	Slovenia: {
		country: 'Slovenia',
		cities: [
			'Vuzenica',
			'Spodnja Idrija',
			'Šoštanj',
			'Sežana',
			'Prebold',
			'Pameče',
			'Koper',
			'Černelavci',
			'Zreče',
			'Rožna Dolina',
			'Lavrica',
			'Zgornje Jezersko',
			'Zabukovica',
			'Videm',
			'Sevnica',
			'Šentilj v Slov. Goricah',
			'Šenčur',
			'Šalovci',
			'Kostanjevica na Krki',
			'Hodoš',
			'Cerkvenjak',
			'Lucija',
			'Žalec',
			'Sveti Tomaž',
			'Preserje pri Radomljah',
			'Lukovica pri Domžalah',
			'Log pri Brezovici',
			'Ljubljana',
			'Dobrna',
			'Nazarje',
			'Zgornje Bitnje',
			'Šmartno pri Litiji',
			'Šmarjeta',
			'Pragersko',
			'Notranje Gorice',
			'Fram',
			'Dekani',
			'Borovnica',
			'Solkan',
			'Logatec',
			'Jagodje',
			'Zavrč',
			'Velenje',
			'Spodnji Duplek',
			'Šentjur',
			'Portorož',
			'Podvelka',
			'Gornji Petrovci',
			'Gornji Grad',
			'Golnik',
			'Apače',
			'Kisovec',
			'Kostel',
			'Središče ob Dravi',
			'Šmarje pri Jelšah',
			'Šmarca',
			'Rečica ob Savinji',
			'Nova Gorica',
			'Murska Sobota',
			'Moravske Toplice',
			'Kromberk',
			'Križevci pri Ljutomeru',
			'Kočevje',
			'Izlake',
			'Gančani',
			'Dol pri Hrastniku',
			'Črenšovci',
			'Celje',
			'Brežice',
			'Bakovci',
			'Šentrupert na Dolenjskem',
			'Prvačina',
			'Pekre',
			'Mengeš',
			'Kobarid',
			'Gotovlje',
			'Dornava',
			'Spodnje Škofije',
			'Prade',
			'Štore',
			'Preddvor',
			'Mozirje',
			'Maribor',
			'Lovrenc na Pohorju',
			'Krog',
			'Izola',
			'Dobrovnik',
			'Razvanje',
			'Muta',
			'Mokronog',
			'Zgornja Kungota',
			'Železniki',
			'Velike Lašče',
			'Turnišče',
			'Trzin',
			'Slovenske Konjice',
			'Senovo',
			'Rakičan',
			'Rače',
			'Podlehnik',
			'Mlaka pri Kranju',
			'Markovci',
			'Kranj',
			'Dravograd',
			'Bistrica ob Sotli',
			'Selnica ob Dravi',
			'Ivančna Gorica',
			'Ormož',
			'Miren',
			'Ljutomer',
			'Lenart v Slov. Goricah',
			'Krško',
			'Kobilje',
			'Sv. Trojica v Slov. Goricah',
			'Destrnik',
			'Bovec',
			'Žužemberk',
			'Zgornji Duplek',
			'Vitanje',
			'Starše',
			'Šmartno pri Slovenj Gradcu',
			'Medvode',
			'Hrušica',
			'Domžale',
			'Selnica ob Muri',
			'Oplotnica',
			'Mojstrana',
			'Metlika',
			'Legen',
			'Radizel',
			'Zgornje Pirniče',
			'Vipava',
			'Veržej',
			'Škocjan',
			'Ribnica',
			'Juršinci',
			'Ig',
			'Bistrica pri Tržiču',
			'Benedikt',
			'Ankaran',
			'Videm pri Ptuju',
			'Vojnik',
			'Trnovlje pri Celju',
			'Šempeter v Savinj. Dolini',
			'Polzela',
			'Piran',
			'Odranci',
			'Mirna',
			'Laško',
			'Kotlje',
			'Jesenice',
			'Dol pri Ljubljani',
			'Bled',
			'Zagorje ob Savi',
			'Vnanje Gorice',
			'Radeče',
			'Mislinja',
			'Komen',
			'Hrastje',
			'Črnomelj',
			'Slovenski Javornik',
			'Vrhnika',
			'Vransko',
			'Rakek',
			'Radlje ob Dravi',
			'Postojna',
			'Moste',
			'Morje',
			'Loški Potok',
			'Kanal',
			'Sveti Jurij',
			'Hrib-Loški Potok',
			'Grad',
			'Gornja Radgona',
			'Straža',
			'Dolenjske Toplice',
			'Dobrova',
			'Cirkulane',
			'Bistrica ob Dravi',
			'Vodice',
			'Verd',
			'Trbovlje',
			'Ravne',
			'Prevalje',
			'Pesnica',
			'Mežica',
			'Jurovski Dol',
			'Ilirska Bistrica',
			'Gorišnica',
			'Divača',
			'Sv. Anton',
			'Škofja Loka',
			'Rogašovci',
			'Radenci',
			'Makole',
			'Leskovec pri Krškem',
			'Kuzma',
			'Topolšica',
			'Šmarje-Sap',
			'Škofljica',
			'Ruše',
			'Miklavž na Dravskem Polju',
			'Litija',
			'Kozje',
			'Kidričevo',
			'Hrastnik',
			'Deskle',
			'Cerknica',
			'Razkrižje',
			'Pobegi',
			'Šentvid pri Stični',
			'Rogaška Slatina',
			'Novo Mesto',
			'Lendava',
			'Idrija',
			'Grosuplje',
			'Seča',
			'Tabor',
			'Ravne na Koroškem',
			'Lokavec',
			'Hrvatini',
			'Zgornja Hajdina',
			'Tolmin',
			'Tišina',
			'Spodnje Hoče',
			'Sodražica',
			'Šmartno ob Paki',
			'Šentjernej',
			'Lesce',
			'Kamnica',
			'Cankova',
			'Braslovče',
			'Zasip',
			'Žiri',
			'Slovenska Bistrica',
			'Renče',
			'Radovljica',
			'Majšperk',
			'Kranjska Gora',
			'Kamnik',
			'Gorenja Vas',
			'Dobrovo',
			'Velika Polana',
			'Tržič',
			'Solčava',
			'Rogoza',
			'Ljubečna',
			'Bilje',
			'Žetale',
			'Vrtojba',
			'Sv. Ana v Slov. Goricah',
			'Puconci',
			'Podčetrtek',
			'Mekinje',
			'Dragomer',
			'Dobje pri Planini',
			'Dob',
			'Koroška Bela',
			'Zgornje Gorje',
			'Trnovska Vas',
			'Trebnje',
			'Ribnica na Pohorju',
			'Ljubno ob Savinji',
			'Limbuš',
			'Cerklje na Gorenjskem',
			'Beltinci',
			'Vir',
			'Slovenj Gradec',
			'Šempeter pri Gorici',
			'Šempas',
			'Radomlje',
			'Ptuj',
			'Poljčane',
			'Osilnica',
			'Nova Vas',
			'Mirna Peč',
			'Luče',
			'Lipovci',
			'Kokrica',
			'Hotinja Vas',
			'Horjul',
			'Črna na Koroškem',
			'Ajdovščina',
			'Cerkno',
			'Leskova Dolina',
			'Komenda',
			'Zgornja Polskava',
			'Vitomarci',
			'Sv. Duh',
			'Semič',
			'Rogatec',
			'Pivka',
			'Naklo',
			'Moravče',
			'Britof',
			'Brezovica pri Ljubljani',
			'Bresternica',
			'Bohinjska Bistrica',
		],
	},
	'Solomon Islands': { country: 'Solomon Islands', cities: ['Lata', 'Gizo', 'Kirakira', 'Buala', 'Tulagi', 'Malango', 'Honiara', 'Taro', 'Auki'] },
	Somalia: {
		country: 'Somalia',
		cities: [
			'Waajid',
			'Mahaddayweyne',
			'Garoowe',
			'Caluula',
			'Buulobarde',
			'Saacow',
			'Kismayo',
			'Beledweyne',
			'Burao',
			'Afgooye',
			'Xuddur',
			'Jalalaqsi',
			'Laascaanood',
			'Dhabad',
			'Gaalkacyo',
			'Buurhakaba',
			'Diinsoor',
			'Bereeda',
			'Baki',
			'Beled Hawo',
			'Xarardheere',
			'Qandala',
			'Oodweyne',
			'Bargaal',
			'Baardheere',
			'Baligubadle',
			'Lughaye',
			'Yeed',
			'Ceek',
			'Cadale',
			'Tayeeglow',
			'Ceeldheer',
			'Saaxo',
			'Mogadishu',
			'Bandarbeyla',
			'Balanbale',
			'Diga gaw',
			'Wanlaweyn',
			'Ceerigaabo',
			'Adado',
			'Luuq',
			'Iskushuban',
			'Eyl',
			'Baidoa',
			'Hargeysa',
			'Dhuusamarreeb',
			'Marka',
			'Bu’aale',
			'Bosaso',
			'Las Khorey',
			'Jawhar',
			'Jamaame',
			'Ceelbuur',
			'Qoryooley',
			'Jilib',
			'Hobyo',
			'Buur Gaabo',
			'Berbera',
			'Dujuuma',
			'Garbahaarrey',
			'Cabudwaaq',
		],
	},
	'South Africa': {
		country: 'South Africa',
		cities: [
			'eMkhomazi',
			'Siyabuswa',
			'Richards Bay',
			'Reitz',
			'Pongola',
			'Polokwane',
			'Parys',
			'Nkandla',
			'Mmabatho',
			'Kroonstad',
			'Elliot',
			'Hermanus',
			'Rondebosch',
			'Whittlesea',
			'Wartburg',
			'Tembisa',
			'Sundumbili',
			'Phalaborwa',
			'Marquard',
			'Jan Kempdorp',
			'Hoopstad',
			'Hluhluwe',
			'Hartswater',
			'Bhisho',
			'Barkly East',
			'Aliwal North',
			'Wellington',
			'Eden Glen',
			'Emalahleni',
			'Nongoma',
			'Marble Hall',
			'Mabopane',
			'Ga-Rankuwa',
			'Dewetsdorp',
			'Christiana',
			'Bergville',
			'Belfast',
			'Port Nolloth',
			'Atlantis',
			'Khaya Mnandi',
			'Standerton',
			'Saint Lucia Estuary',
			'Kutloanong',
			'Fochville',
			'Colesberg',
			'Calvinia',
			'Noupoort',
			'Newcastle',
			'Koffiefontein',
			'Jansenville',
			'Glencoe',
			'Fraserburg',
			'Ermelo',
			'Ballito',
			'Garies',
			'Hardys Memories of Africa',
			'Vereeniging',
			'Thohoyandou',
			'Lady Grey',
			'Kimberley',
			'Izingolweni',
			'Eshowe',
			'Breyten',
			'Allanridge',
			'White River',
			'Williston',
			'Warrenton',
			'Villiers',
			'Mondlo',
			'Mkuze',
			'Kruisfontein',
			'Komatipoort',
			'Brakpan',
			'Bloemhof',
			'Bloemfontein',
			'Apel',
			'Vredenburg',
			'Clanwilliam',
			'Mandeni',
			'Mthatha',
			'Makapanstad',
			'Howick',
			'Greytown',
			'Graaff-Reinet',
			'Camperdown',
			'Lephalale',
			'Diepsloot',
			'Uitenhage',
			'Taung',
			'Prieska',
			'Postmasburg',
			'Peddie',
			'Hoedspruit',
			'Flagstaff',
			'Driefontein',
			'Cullinan',
			'Vredendal',
			"Simon's Town",
			'Thornhill',
			'Volksrust',
			'Van Wyksvlei',
			'Senekal',
			'Schweizer-Reneke',
			'Laingsburg',
			'Kenhardt',
			'Heidelberg',
			'Berea',
			'Albertina',
			'Vryburg',
			'Port Saint John’s',
			'Mtubatuba',
			'Louis Trichardt',
			'Kriel',
			'Kareedouw',
			'Impendle',
			'Harding',
			'Germiston',
			'Barkly West',
			'Ekangala',
			'Ventersdorp',
			'Thaba Nchu',
			'Tarkastad',
			'Swellendam',
			'Ladybrand',
			'Heilbron',
			'Butterworth',
			'Bultfontein',
			'Worcester',
			'Bergvliet',
			'Eastleigh',
			'Tzaneen',
			'Randfontein',
			'Port Alfred',
			'Plettenberg Bay',
			'Orkney',
			'Mossel Bay',
			'Montagu',
			'Middelburg',
			'Krugersdorp',
			'Calitzdorp',
			'Burgersfort',
			'Barberton',
			'Alice',
			'Piketberg',
			'Arniston',
			'Randburg',
			'Port Shepstone',
			'Maile',
			'Mahikeng',
			'Kakamas',
			'Boshof',
			'Bizana',
			'Adelaide',
			'Ceres',
			'Ventersburg',
			'Utrecht',
			'Pinetown',
			'Komga',
			'Kokstad',
			'Johannesburg',
			'Ganyesa',
			'Fort Beaufort',
			'eSikhaleni',
			'East London',
			'Delmas',
			'Cofimvaba',
			'Carnarvon',
			'Carletonville',
			'Saldanha',
			'Pofadder',
			'Thabazimbi',
			'Stutterheim',
			'Riversdale',
			'Port Elizabeth',
			'Nigel',
			'Ilinge',
			'Bochum',
			'Cape Town',
			'Eden Glen Ext 60',
			'Secunda',
			'Meyerton',
			'Edenvale',
			'Daniëlskuil',
			'Boksburg',
			'Bethal',
			'Grabouw',
			'KwaDukuza',
			'Pretoria',
			'Pietermaritzburg',
			'Middelburg',
			'Mankoeng',
			'Lydenburg',
			'Libode',
			'Ladismith',
			'Hopetown',
			'Dannhauser',
			'Bredasdorp',
			'Bethlehem',
			'Caledon',
			'Riverlea',
			'Willowmore',
			'Vryheid',
			'Springs',
			'Paulpietersburg',
			'Oudtshoorn',
			'Orania',
			'Orange Farm',
			'Nkowakowa',
			'Ndwedwe',
			'Molteno',
			'Lindley',
			'Kuruman',
			'Koppies',
			'Kirkwood',
			'Bronkhorstspruit',
			'Brandvlei',
			'Balfour',
			'Ashton',
			'Stellenbosch',
			'Lansdowne',
			'Zastron',
			'Vanderbijlpark',
			'Tugela Ferry',
			'Thulamahashi',
			'Potchefstroom',
			'Piet Retief',
			'Mutale',
			'Kathu',
			'Frankfort',
			'Empangeni',
			'Midrand',
			'Roodepoort',
			'Victoria West',
			'Sasolburg',
			'Queensdale',
			'Mpophomeni',
			'Koster',
			'Durban',
			'Springbok',
			'Musina',
			'Modimolle',
			'Muldersdriseloop',
			'Mogwase',
			'Jane Furse',
			'Himeville',
			'Harrismith',
			'Douglas',
			'Dendron',
			'Cala',
			'Burgersdorp',
			'Bothaville',
			'Rosebank',
			'Wesselsbron',
			'Bela Bela',
			'Trompsburg',
			'Soweto',
			'Scottburgh',
			'Richmond',
			'Mokopane',
			'Nelspruit',
			'Mooirivier',
			'Hennenman',
			'Hendrina',
			'Giyani',
			'George',
			'Deneysville',
			'Clocolan',
			'Benoni',
			'Kraaifontein',
			'Bela-Bela',
			'Vrede',
			'Somerset East',
			'Qumbu',
			'Phuthaditjhaba',
			'Matatiele',
			'Maclear',
			'Lady Frere',
			'Jozini',
			'Grahamstown',
			'De Aar',
			'Botshabelo',
			'Beaufort West',
			'Paarl',
			'Constantia',
			'Newlands',
			'Zeerust',
			'Wolmaransstad',
			'Westonaria',
			'Welkom',
			'Vredefort',
			'Stilfontein',
			'Rustenburg',
			// 'Queenstown',
			'Petrusville',
			'Mount Frere',
			'Melmoth',
			'Ixopo',
			'Estcourt',
			'Delareyville',
			'Alberton',
			'Centurion',
			'Robertson',
			'Sunset Beach',
			'Midstream',
			'Upington',
			'Ulundi',
			'Ritchie',
			'Pampierstad',
			'Lebowakgomo',
			'Groblershoop',
			'Ficksburg',
			'Dordrecht',
			'De Rust',
			'Creighton',
			'Carolina',
			'Stanford',
			'Malmesbury',
			'Zoar',
			'Winburg',
			'Virginia',
			'Viljoenskroon',
			'Theunissen',
			'Margate',
			'Lichtenburg',
			'Ga-Kgapane',
			'eMbalenhle',
			'Dundee',
			'Cradock',
			'Moorreesburg',
			'Retreat',
			'Prince Albert',
			'Mpumalanga',
			'Mount Ayliff',
			'Modderfontein',
			'Ladysmith',
			'KwaMbonambi',
			'Knysna',
			'Klerksdorp',
			'Hlabisa',
			'Groblersdal',
			'Ekuvukeni',
			'Duiwelskloof',
			'Brits',
			'Brandfort',
			'Claremont',
		],
	},
	'South Sudan, The Republic of': {
		country: 'South Sudan, The Republic of',
		cities: [
			'Torit',
			'Wau',
			'Aweil',
			'Maridi',
			'Raja',
			'Pajok',
			'Kuacjok',
			'Winejok',
			'Tonj',
			'Leer',
			'Bor',
			'Yei',
			'Kodok',
			'Gogrial',
			'Bentiu',
			'Malakal',
			'Rumbek',
			'Juba',
			'Tambura',
			'Kapoeta',
			'Yirol',
			'Kafia Kingi',
			'Yambio',
		],
	},
	Spain: {
		country: 'Spain',
		cities: [
			'Zurgena',
			'Villatoya',
			'Villamayor de Calatrava',
			'Villalgordo del Marquesado',
			'Usagre',
			'Torre Alháquime',
			'Tomares',
			'Tinajo',
			'Talayuela',
			'Talavera de la Reina',
			'Talaván',
			'Santa Marta',
			'Santa Eugènia',
			'Santa Ana la Real',
			'Sant Joan de Labritja',
			'San Bartolomé de Tirajana',
			'San Bartolomé de las Abiertas',
			'Saelices',
			'Rosal de la Frontera',
			'Pollença',
			'Paterna del Río',
			'Moya',
			'Monreal del Llano',
			'Mengabril',
			'Masalavés',
			'Marmolejo',
			'Marinaleda',
			'Maguilla',
			'Los Palacios y Villafranca',
			'Los Hinojosos',
			'Lo Pagán',
			'La Codosera',
			'La Antilla',
			'Xixona',
			'Iniesta',
			'Illar',
			'Hondón de las Nieves',
			'Guadix',
			'Graja de Iniesta',
			'Garrovillas',
			'Gabaldón',
			'Frontera',
			'Fiñana',
			'El Rompido',
			'Corralejo',
			'Conquista',
			'Chipiona',
			'Chera',
			'Castillo de Garcimuñoz',
			'Casar de Cáceres',
			'Carmena',
			'Carboneros',
			'Carboneras de Guadazaón',
			'Canillas de Aceituno',
			'Calera de León',
			'Beniparrell',
			'Beniel',
			'Begíjar',
			'Aspe',
			'Añora',
			'Almócita',
			'Algatocín',
			'Algámitas',
			'Alfauir',
			'Alcalá del Valle',
			'Alboraya',
			'Albaladejo del Cuende',
			'Alaró',
			'Agüimes',
			'Abla',
			'Zarzuela de Jadraque',
			'Zaratán',
			'Iurreta',
			'Vilvestre',
			'Villodrigo',
			'Villaseco de los Reyes',
			'Villarquemado',
			'Villarmuerto',
			'Villar del Cobo',
			'Villaquejida',
			'Villamedianilla',
			'Villalón de Campos',
			'Villalobón',
			'Villalbilla de Burgos',
			'Villahermosa del Río',
			'Vilafranca del Penedès',
			'Villabona',
			'Verges',
			'Vega de Infanzones',
			'Valpalmas',
			'Valdezate',
			'Valderrábano',
			'Valdeprados',
			'Valdemora',
			'Valdelagua del Cerro',
			'Utande',
			'Uña de Quintana',
			'Tortosa',
			'Torre los Negros',
			'Torre de las Arcas',
			'Torrecilla del Monte',
			'Tormellas',
			'Tormantos',
			'Toreno',
			'Soto del Barco',
			'Soria',
			'Sojuela',
			'Sobradillo',
			'Seva',
			'Sarreaus',
			'Sargentes de la Lora',
			'Santa Cruz de Pinares',
			'Santa Croya de Tera',
			'Santa Cristina de Valmadrigal',
			'Sant Pere Pescador',
			'San Pedro de Ceque',
			'Sant Gregori',
			'San Adrián de Juarros',
			'Sama',
			'Saelices de Mayorga',
			'Sacedón',
			'Sabadell',
			'Robregordo',
			'Robleda-Cervantes',
			'Revenga de Campos',
			'Requejo',
			'Regencós',
			'Pozo de Urama',
			'Poza de la Sal',
			'Poveda de la Sierra',
			'Pola de Lena',
			'Pinseque',
			'Pinofranqueado',
			'Piñel de Arriba',
			'Peralejos de Arriba',
			'Paracuellos de Jiloca',
			'Palmeira',
			'Orexa',
			'Orea',
			'Onzonilla',
			'Olmedo',
			'Oliete',
			'Ojos Negros',
			'Novales',
			'Navia de Suarna',
			'Navarrete',
			'Navares de Ayuso',
			'Navalafuente',
			'Monzón de Campos',
			'Mont-roig del Camp',
			'Montornès del Vallès',
			'Arrasate / Mondragón',
			'Miranda de Arga',
			'Miralrío',
			'Miengo',
			'Melque de Cercos',
			'Mas de Barberans',
			'Mara',
			'Manciles',
			'Mambrillas de Lara',
			'Malpartida',
			'Mahamud',
			'Madroñal',
			'Longares',
			'Linares de Mora',
			"la Tallada d'Empordà",
			'Lapuebla de Labarca',
			'Langa',
			'Laguna de Contreras',
			'La Frontera',
			'La Bañeza',
			'A Baña',
			'Ituero y Lama',
			'Irun',
			'Igualada',
			'Huérguina',
			'Hoyorredondo',
			'Hoyales de Roa',
			'Hospital de Órbigo',
			'Hornillos de Cerrato',
			'Horche',
			'Hontangas',
			'Güeñes',
			'Gelsa',
			'Garrafe de Torío',
			'Garcihernández',
			'Fuentelsaz de Soria',
			'Fresno de Sayago',
			'Fresno de la Fuente',
			'Foixà',
			'Fiscal',
			'Estriégana',
			'Estepa de San Juan',
			'Entrala',
			'Encinas de Esgueva',
			'Elorz',
			'Ferrol',
			'Deba',
			'Cubillas de los Oteros',
			'Kortezubi',
			'Cordovilla la Real',
			'Colldejou',
			'Collado Hermoso',
			'Cogolludo',
			'Cobreros',
			'Cilleros de la Bastida',
			'Chillarón del Rey',
			'Chía',
			'Cerdido',
			'el Catllar',
			'Castroserracín',
			'Castillazuelo',
			'Castellserà',
			"Castelló d'Empúries",
			'Castejón de las Armas',
			'Castejón de Henares',
			'Caspueñas',
			'Caspe',
			'Carrizo de la Ribera',
			'Carenas',
			'O Carballiño',
			'Carballeda de Avia',
			'Candeleda',
			'Campillo de Aranda',
			'Camañas',
			'Cadalso de los Vidrios',
			'Cabezabellosa de la Calzada',
			'Buitrago',
			'Buenavista',
			'Budia',
			'Bermillo de Sayago',
			'Beizama',
			'Becerril de Campos',
			'Bayubas de Arriba',
			'Bande',
			'Ayoó de Vidriales',
			'Avellaneda',
			'Ausejo',
			'Aspariegos',
			'Arruazu',
			'Armañanzas',
			'Ares',
			'Arbo',
			'Anquela del Pedregal',
			'Ambel',
			'Alpartir',
			'Almenara de Adaja',
			'Aldeanueva de Guadalajara',
			'Alconchel de Ariza',
			'Alconada de Maderuelo',
			'Agüero',
			'Adrada de Haza',
			'Les Corts',
			'Salamanca',
			'el Poblenou',
			'Fort Pienc',
			'Sant Joan de Mediona',
			'Senterada',
			'Moriles',
			'Castroviejo',
			'Provenals del Poblenou',
			'Baró de Viver',
			"el Camp de l'Arpa del Clot",
			'Yunquera',
			'Villarrubia de Santiago',
			'Vilamarxant',
			'Villalpardo',
			'Villajoyosa',
			'Villafranca de los Barros',
			'Vera',
			'Valverdejo',
			'Valle de la Serena',
			'Vall de Ebo',
			'Valdehúncar',
			'Uclés',
			'Totana',
			'Torrenueva',
			'Torremegía',
			'Sorvilán',
			'San Vicente de Alcántara',
			'Santanyí',
			'Santa Cruz de Tenerife',
			'Santa Cruz',
			'Rugat',
			'Ribarroja del Turia',
			'Ráfol de Salem',
			'Quintana de la Serena',
			'Purchena',
			'Puntallana',
			'Pujerra',
			'Paterna del Madera',
			'Oropesa',
			'Olivares',
			'Nigüelas',
			'Navalpino',
			'Motilla del Palancar',
			'Medellín',
			'Mairena del Alcor',
			'Magán',
			'Los Alcázares',
			'La Unión',
			'La Puebla de Almoradiel',
			'sa Pobla',
			'La Parra',
			'Júzcar',
			'Huétor Vega',
			'Huerta de Valdecarábanos',
			'Hornachos',
			'Higueras',
			'Guaro',
			'Guadamur',
			'Gor',
			'Gibraleón',
			'Garafía',
			'Fuencaliente',
			'Frailes',
			'Fonelas',
			'Favara',
			'El Arahal',
			'Dúrcal',
			'Daimiel',
			'Cullera',
			'Cortegana',
			'Colònia de Sant Jordi',
			'Ciruelos',
			'Xirivella',
			'Caudete',
			'Castell de Castells',
			'Casas de los Pinos',
			'Casas de Juan Núñez',
			'Cártama',
			'Carcaixent',
			'Cañaveral de León',
			'Camas',
			'Biar',
			'Benzú',
			'Benimarfull',
			'Benamocarra',
			'Benacazón',
			'Betxí',
			'Arrecife',
			'Antas',
			'Andújar',
			'Almussafes',
			'Alhambra',
			'Alhama de Almería',
			'Alfarrasí',
			'Alcantarilla',
			'Zuera',
			'Zaorejas',
			'Vivel del Río Martín',
			'Vistabella del Maestrazgo',
			'Villel de Mesa',
			'Villel',
			'Villaveza de Valverde',
			'Villasayas',
			'Villasarracino',
			'Villasana de Mena',
			'Villarejo de Órbigo',
			'Villar del Olmo',
			'Villar de la Yegua',
			'Villar de Ciervo',
			'Villaralbo',
			'Villanueva de Campeán',
			'Villamontán de la Valduerna',
			'Villalonso',
			'Villafamés',
			'Velascálvaro',
			'Vega de Espinareda',
			'Valverde de la Virgen',
			'Valtierra',
			'Valtablado del Río',
			'Vallromanes',
			'Valdarachas',
			'Tudela',
			'Tronchón',
			'Trespaderne',
			'Tortuero',
			'Torrevelilla',
			'Torregamones',
			'Tormón',
			'Tamames',
			'Tamajón',
			'Tafalla',
			'Tabuenca',
			'Somozas',
			'Serradilla del Arroyo',
			'Sentmenat',
			'Sartaguda',
			'Sariñena',
			'Barberà del Vallès',
			'Santa Eugènia de Berga',
			'Santa Cruz del Retamar',
			'Santa Colomba de Somoza',
			'Donostia / San Sebastián',
			'San Millán de la Cogolla',
			'Sant Martí de Tous',
			'San Juan del Monte',
			'Sant Hilari Sacalm',
			'Saelices de la Sal',
			'Riells i Viabrea',
			'Ribota',
			'Rezmondo',
			'Retuerta',
			'Remondo',
			'Quicena',
			'Prades',
			'Pontós',
			'Pina de Ebro',
			'Peníscola',
			'Pedrosillo el Ralo',
			'Paradela',
			'Palencia de Negrilla',
			'Oñati',
			'Olombrada',
			'Olba',
			'Noreña',
			'Nombrevilla',
			'Navalperal de Tormes',
			'Nava',
			'Narros de Saldueña',
			'Murias de Paredes',
			'Mutriku',
			'Moralina',
			'Monasterio de Rodilla',
			'Molezuelas de la Carballeda',
			'Miraveche',
			'Mirafuentes',
			'Mieza',
			'Melgar de Fernamental',
			'Marracos',
			'Manzanal de Arriba',
			'Manquillos',
			'Manjabálago',
			'Mamblas',
			'Magallón',
			'Los Molinos',
			'Lledó',
			'La Pesga',
			'Lanestosa',
			'La Adrada',
			'Jarque',
			'Iscar',
			'Huesa del Común',
			'Huélaga',
			'Horta de Sant Joan',
			'Herguijuela de la Sierra',
			'Haza',
			'Gúdar',
			'Gatika',
			'Gallinero de Cameros',
			'Fuentesoto',
			'Hondarribia',
			'Fuentepinilla',
			'Fuente la Reina',
			'Fresno de Caracena',
			'Fonollosa',
			'Ferreruela',
			'Farlete',
			'Falset',
			'Escarabajosa de Cabezas',
			'Eratsun',
			'Épila',
			'El Escorial',
			'Destriana',
			'Curiel de Duero',
			'Cosa',
			'Condemios de Abajo',
			'Colmenar de Montemayor',
			'Colera',
			'Cirujales del Río',
			'Ciriza',
			'Cihuela',
			'Cidones',
			'Cervillego de la Cruz',
			'Cervera de Pisuerga',
			'Cepeda la Mora',
			'Cebrecos',
			'Castroserna de Abajo',
			'Castellfort',
			'Castejón de Sos',
			'Caseres',
			'Casalarreina',
			'Cartelle',
			'Campo Real',
			'Campo de Caso',
			'Cambrils',
			'Cambados',
			'Calzada de Valdunciel',
			'Calafell',
			'Buciegas',
			'Brunete',
			'Bliecos',
			'Berrueces',
			'Benassal',
			'Bellvís',
			'Bareyo',
			'Balaguer',
			'Baides',
			'Avinyó',
			'Arganza',
			'Arenas de San Pedro',
			'Arañuel',
			'Elexalde',
			'Antzuola',
			'Angüés',
			'Altable',
			'Alhama de Aragón',
			'Algar de Mesa',
			'Alfántega',
			'Aldeanueva de la Vera',
			'Aldealengua',
			'Alcolea del Pinar',
			'Alar del Rey',
			'Aguilar de Campos',
			'Ablanque',
			'Usera',
			'El Carmel',
			'Vall de Gallinera',
			'Sagrada Família',
			'El Cotillo',
			'Castellar del Riu',
			'Etxebarria',
			'Ermitagaña',
			'San Tirso de Abres',
			'Natahoyo',
			'Vallvidrera, el Tibidabo i les Planes',
			'Zújar',
			'Zahinos',
			'Yepes',
			'Villena',
			'Villanueva del Arzobispo',
			'Villanueva de Bogas',
			'Es Castell',
			'Valverde de Llerena',
			'Valencina de la Concepción',
			'Torre de la Horadada',
			'Tíjola',
			'Telde',
			'Siete Aguas',
			'Sayalonga',
			'Rafelcofer',
			'Quero',
			'Puebla de Sancho Pérez',
			'Pozo Alcón',
			'Portezuelo',
			'Polopos',
			'Pescueza',
			'Ontígola',
			'Munera',
			'Motril',
			'Montuïri',
			'Monteagudo de las Salinas',
			'Marchena',
			'Lubrín',
			'Lorca',
			'La Línea de la Concepción',
			'Inca',
			'Fuente Palmera',
			'Fuentelespino de Moya',
			'Firgas',
			'Esparragosa de la Serena',
			"s'Arenal",
			'Cúllar-Vega',
			'Quartell',
			'Corral-Rubio',
			'Corbera',
			'Coín',
			'Chilches',
			'Castilleja del Campo',
			'Casas de Guijarro',
			'Cartaya',
			'Carmonita',
			'Càrcer',
			'Canillas de Albaida',
			'Cáñar',
			'Burujón',
			'Burguillos del Cerro',
			'Botija',
			'Berrocalejo',
			'Benamejí',
			'Barbate',
			'Badajoz',
			'Antella',
			'Almáchar',
			'Alhaurín el Grande',
			'Alconchel de la Estrella',
			'Alcoba',
			'Yeles',
			'Vizmanos',
			'Villaviudas',
			'Villarmentero de Esgueva',
			'Villarluengo',
			'Villanueva de Oscos',
			'Villamol',
			'Villalcázar de Sirga',
			'Villalbilla de Gumiel',
			'Villafuerte',
			'Villafranca de Duero',
			'Villafeliche',
			'Villacastín',
			'Viana de Jadraque',
			'Verdú',
			'Velilla de los Ajos',
			'Vega de Tera',
			'Valtorres',
			'Balmaseda',
			'Vallmoll',
			'Valdemaqueda',
			'Valdastillas',
			'Umbrías',
			'Trabazos',
			'Tornadizos de Ávila',
			'Susinos del Páramo',
			'Sorihuela',
			'Sitges',
			'Sesma',
			'Sariego',
			'Santo Domingo de Pirón',
			'Santibáñez de Béjar',
			'Santa Eulalia Bajera',
			'Sant Martí Sarroca',
			'San Leonardo de Yagüe',
			'San Justo de la Vega',
			'San Esteban de Nogales',
			'San Cristóbal de la Polantera',
			'Salvador de Zapardiel',
			'Rosselló',
			'Rollamienta',
			'Robliza de Cojos',
			'Rillo',
			"Quart d'Onyar",
			'Pobra de Trives',
			'Pradejón',
			'Prádanos de Ojeda',
			'Planoles',
			'Pereiro de Aguiar',
			'Peral de Arlanza',
			'Peracense',
			'Peñacaballera',
			'Pelabravo',
			'Pastrana',
			'Palo',
			'Orio',
			'Orera',
			'Olmedillo de Roa',
			'Olite',
			'Nuño Gómez',
			'Navarredonda de la Rinconada',
			'Nava del Barco',
			'Nájera',
			'Muros',
			'Muras',
			'Monteagudo de las Vicarías',
			'Monleras',
			'Monleón',
			'Molinos de Duero',
			'Mogarraz',
			'Milagro',
			'Miedes de Atienza',
			'Manzanera',
			'Manganeses de la Lampreana',
			'Málaga del Fresno',
			'Lesaka',
			'Leache',
			'Lardero',
			'La Iglesuela del Cid',
			'La Ginebrosa',
			'la Garriga',
			'La Fuliola',
			"la Bisbal d'Empordà",
			'Bastida / Labastida',
			'Javier',
			'Izurtza',
			'Iturmendi',
			'Ibarra',
			'Hoyos de Miguel Muñoz',
			'Hornos de Moncalvillo',
			'Herrera de Soria',
			'Hernialde',
			'Herguijuela del Campo',
			'Hazas de Cesto',
			'Guils de Cerdanya',
			'Gualta',
			'Gotarrendura',
			'Godojos',
			'Gijón',
			'Gatón de Campos',
			'Gata',
			'Gandesa',
			'Gallegos de Argañán',
			'Fuentebureba',
			'Fuenferrada',
			'Fresneda de la Sierra',
			'Fornells de la Selva',
			'Fariza',
			'Ezcaray',
			'Estollo',
			'Espeja de San Marcelino',
			'Escobar de Polendos',
			'Ermua',
			'Erla',
			'Echarri',
			'Crémenes',
			'Cortes',
			'el Cogul',
			'Cogollor',
			'Cogeces del Monte',
			'Ciria',
			'Cercedilla',
			'Cerbón',
			'Cellorigo',
			'Cebreros',
			'Castropol',
			'Casillas',
			'Cardona',
			'Canillas de Abajo',
			'Calzada de Don Diego',
			'Calatorao',
			'Cadrete',
			'Cadalso',
			'Cabra del Camp',
			'Cabañas de Sayago',
			'Burbáguena',
			'Botorrita',
			'Bogajo',
			'Bierge',
			'Berrobi',
			'Berga',
			'Bàscara',
			'Barromán',
			'Ataquines',
			'Arévalo',
			'Arbancón',
			'Arauzo de Miel',
			'Arano',
			'Anguciana',
			'Amorebieta',
			'Alovera',
			'Algora',
			'Aldehuela del Codonal',
			'Alba de Yeltes',
			'Agón',
			'Aduna',
			'Adahuesca',
			'Abadía',
			'Retamar',
			'Poble Sec',
			'Pueblonuevo de Miramontes',
			'Liendo',
			"el Camp d'en Grassot i Gràcia Nova",
			'el Congrés i els Indians',
			'Albentosa',
			'Beranuy',
			'Yeste',
			'Viso del Marqués',
			'Viñuela',
			'Villamanrique de la Condesa',
			'Valverde',
			'Valdefuentes',
			'Tresjuncos',
			'Somontín',
			'Serón',
			'Santa Pola',
			'San Pedro del Pinatar',
			'Salinas',
			'Puebla de la Calzada',
			'Pozohondo',
			'Pinos Genil',
			'Olula de Castro',
			'Nogales',
			'Mirandilla',
			'Miguel Esteban',
			'Los Santos de Maimona',
			'Ledaña',
			'La Coronada',
			'Jamilena',
			'Infantes',
			'Holguera',
			'Hinojosa del Duque',
			'Higuera de Arjona',
			'Formentera de Segura',
			'Estepona',
			'Encinasola',
			'El Robledo',
			'El Coronil',
			'El Bosque',
			'Cristina',
			'Cortes de Baza',
			'Cobatillas',
			'Castillejo de Iniesta',
			'Casasbuenas',
			'Estación de Cártama',
			'Carcabuey',
			'Campillo de Arenas',
			'El Campello',
			'Calles',
			'Busquístar',
			'Bohonal de Ibor',
			'Benifairó de les Valls',
			'Beneixama',
			'Benagéber',
			'Bayarque',
			'Barx',
			'Ayamonte',
			'Algeciras',
			'Albondón',
			'Adsubia',
			'Zúñiga',
			'Yecla de Yeltes',
			'Yebra',
			'Yanguas',
			'Viveiro',
			'Vita',
			'Villar de Samaniego',
			'Villar del Buey',
			'Villanueva de los Caballeros',
			'Villalube',
			'Villalba de los Alcores',
			'Vileña',
			'Vegaquemada',
			'Valsalabroso',
			'Vallibona',
			'Valderrodilla',
			'Valdemaluque',
			'Vadocondes',
			'Trabada',
			'Torrelapaja',
			'Torralba de Ribota',
			'Tordellego',
			'Tona',
			'Teià',
			'Tartanedo',
			'Tarazona',
			'Sierra-Engarcerán',
			'Sevilla La Nueva',
			'Sauquillo de Cabezas',
			'Santorcaz',
			'Santoña',
			'Sant Joan les Fonts',
			'Santiurde de Reinosa',
			'Santa Olalla de Bureba',
			'Santa Marta de Ortigueira',
			'Santa María del Páramo',
			'Santa Cruz de Yanguas',
			'Santa Cruz de Moncayo',
			'San Román de la Cuba',
			'San Román de Hornija',
			'San Mateo de Gállego',
			'San Martín del Pimpollar',
			'San Llorente',
			'San Justo',
			'Samos',
			'Samaniego',
			'Salmoral',
			'Rublacedo de Abajo',
			'O Rosal',
			'Riodeva',
			'Reinoso de Cerrato',
			'Puebla de Sanabria',
			'Puebla de Beleña',
			'Puebla de Alfindén',
			'Pontils',
			'Piracés',
			'Perosillo',
			'Peralveche',
			'Peñalén',
			'Palomeque',
			'Palanques',
			'Palaciosrubios',
			'Navas de Oro',
			'Navalquejigo',
			'Muñomer del Peco',
			'Montferri',
			'Montejo de la Sierra',
			'Mondéjar',
			'Monasterio de la Sierra',
			'Mezalocha',
			'Mesia',
			'Matilla de Arzón',
			'Maranchón',
			'Malgrat de Mar',
			'Majadahonda',
			'Luna',
			'Lobera de Onsella',
			'Llinars del Vallès',
			'Llançà',
			'Leitza',
			'Legazpi',
			'Lécera',
			'Laza',
			'Las Navas del Marqués',
			'Lagueruela',
			'La Cabrera',
			'Jatiel',
			'Iniéstola',
			'Igea',
			'Huesca',
			'Huerta de Arriba',
			'Honrubia de la Cuesta',
			'Híjar',
			'Gironella',
			'Gea de Albarracín',
			'Gavà',
			'Gallur',
			'Galende',
			'Fuentespina',
			'Fuentes de Oñoro',
			'Fuentes de Año',
			'Fuentes Claras',
			'Fuendetodos',
			'Fornelos de Montes',
			'Forfoleda',
			'Farrera',
			"l'Estartit",
			'Espot',
			'Esplugues de Llobregat',
			'Escorihuela',
			'Encinacorba',
			'Embid',
			'Donvidas',
			'Cuevas de San Clemente',
			'Cuevas de Almudén',
			'Cubo de la Solana',
			'Cubillas de Santa Marta',
			'Cistierna',
			'Cimanes del Tejar',
			'Cigudosa',
			'Cervià de Ter',
			'Cerveruela',
			'Cerecinos del Carrizal',
			'Castropodame',
			'Castrocontrigo',
			'Castielfabib',
			'Castellfollit de Riubregós',
			'Castellar de la Muela',
			'Casafranca',
			'Carrascal del Obispo',
			'Cariño',
			'Cànoves i Samalús',
			'Cañizares',
			'Cañete',
			'Calatañazor',
			'Bustillo del Páramo de Carrión',
			'Burón',
			'Burganes de Valverde',
			'Brunyola',
			'Brieva de Cameros',
			'Breda',
			'Boñar',
			'Boborás',
			'Binéfar',
			'Berrocal de Huebra',
			'Berlanga de Duero',
			'Bercianos del Real Camino',
			'Bellpuig',
			'Barbuñales',
			'Bárboles',
			'Baños de Molgas',
			'Baltanás',
			'Baells',
			'Ayllón',
			'Artazu',
			'Aretxabaleta',
			'Anglès',
			'Alesón',
			'Alesanco',
			'Aldehuela de Liestos',
			'Aldehuela de la Bóveda',
			'Aldealpozo',
			'Albornos',
			'Albiztur',
			'Albelda de Iregua',
			'Albares',
			'Camp de Mar',
			'San Blas-Canillejas',
			'Madrid Centro',
			'Hostafrancs',
			"Dreta de l'Eixample",
			'la Guineueta',
			'el Turó de la Peira',
			'Villamayor de Gállego',
			'Valenzuela de Calatrava',
			'Torrijos',
			'Torre del Mar',
			'Teguise',
			'Sisante',
			'Santa Cruz de Moya',
			'Santa Ana de Pusa',
			'Salares',
			'Sacañet',
			'Ronda',
			'Real de Gandía',
			'Puerto de la Cruz',
			'Puebla del Príncipe',
			'Piñar',
			'Piedras Albas',
			'Peñalsordo',
			'Paterna del Campo',
			'Palos de la Frontera',
			'Orihuela',
			'Oria',
			'Olmeda del Rey',
			'Noblejas',
			'Monóvar',
			'Mazo',
			'Manzanilla',
			'Manzaneque',
			'Manuel',
			'Mancha Real',
			'Malagón',
			'Madrigueras',
			'Madridejos',
			'Los Yébenes',
			'Losa del Obispo',
			'Lomo de Arico',
			'Lebrija',
			'Las Palmas de Gran Canaria',
			'Lanjarón',
			'La Guardia de Jaén',
			'Jubrique',
			'Iznatoraf',
			'Guadasequies',
			'Gerena',
			'Gáldar',
			'Erustes',
			'Cuerva',
			'Cox',
			'Cotillas',
			'Cotes',
			'Chirivel',
			'Castilblanco de los Arroyos',
			'Castilblanco',
			'Carrión de Calatrava',
			'Cañada del Hoyo',
			'Campillo de Deleitosa',
			'Campillo de Altobuey',
			'Calañas',
			'Cabañas del Castillo',
			'Beniarbeig',
			'Benarrabá',
			'Archidona',
			'Anna',
			'Almuñécar',
			'Algimia de Alfara',
			'Alcudia de Monteagud',
			'Albox',
			'Alarcón',
			'Zaragoza',
			'Vinaròs',
			'Vilopriu',
			'Villanueva del Campillo',
			'Villanueva de las Peras',
			'Villanueva de las Manzanas',
			'Villamor de los Escuderos',
			'Villamiel',
			'Villalba de Guardo',
			'Villacarralón',
			'Viladecavalls',
			'Vellisca',
			'Velilla de Ebro',
			'Valverde-Enrique',
			'Valluércanes',
			'Valdemanco',
			'Valdefuentes del Páramo',
			'Valdeaveruelo',
			'Valdaracete',
			'Uruñuela',
			'Urriés',
			'Unzué',
			'Ullà',
			'Ujué',
			'Tórtola de Henares',
			'Torre del Bierzo',
			'Torrecilla en Cameros',
			'Tordelrábano',
			'Terrer',
			'Tamariz de Campos',
			'Talamanca',
			'Sunyer',
			'Subirats',
			'Sotoserrano',
			'Solosancho',
			'Sierra de Luna',
			'Segurilla',
			'Sardón de los Frailes',
			'Santiz',
			'Santa María de Sando',
			'Santa Cruz de Paniagua',
			'Santa Coloma',
			'Santacara',
			'San Pedro Manrique',
			'Sant Feliu Sasserra',
			'San Antolín',
			'San Andrés del Congosto',
			'Samper del Salz',
			'Salas de los Infantes',
			'Ruesga',
			'Roses',
			'Romanones',
			'Robledillo de la Jara',
			'Roales',
			'Ribadesella',
			'Ráfales',
			'Quintana y Congosto',
			'Pinedas',
			'Pesquera',
			'Pereruela',
			'El Perelló',
			'Perales de Tajuña',
			'Peñafiel',
			'Palamós',
			'Oviedo',
			'Oropesa del Mar',
			'Orbita',
			'Ondarroa',
			'Olmos de Ojeda',
			'Navalmoral',
			'Navalcarnero',
			'Montcada i Reixac',
			'Monasterio de Vega',
			'Molinaseca',
			'Mironcillo',
			'Medina del Campo',
			'Matalebreras',
			'el Masroig',
			'Maraña',
			'Mantinos',
			'Manlleu',
			'Loscorrales',
			'Legorreta',
			'les Llosses',
			'Lagunaseca',
			'Laguna de Cameros',
			'Lagata',
			'Labuerda',
			'La Bouza',
			"l'Ametlla de Mar",
			'La Almunia de Doña Godina',
			'Irixoa',
			'Igüeña',
			'Ikaztegieta',
			"Ivars d'Urgell",
			'Ibarrangelu',
			'Hurtumpascual',
			'Hueva',
			'Huérmeces del Cerro',
			'Herguijuela de Ciudad Rodrigo',
			'Guadalajara',
			'Grañón',
			'Grado',
			'Garganta del Villar',
			'Garaioa',
			'Fuentenebro',
			'Fuentemolinos',
			'Fuentecambrón',
			'Frías de Albarracín',
			'Fontioso',
			'Foncea',
			'Fombuena',
			'Flores de Ávila',
			'Flix',
			'Espinosa de Villagonzalo',
			'Esparreguera',
			'El Franco',
			'Degaña',
			'Das',
			'Daroca',
			'Cubelles',
			'Congostrina',
			'Colomers',
			'Colmenar del Arroyo',
			'Codorniz',
			'Cisneros',
			'Cincovillas',
			'Zierbena',
			'Chiloeches',
			'Cerezal de Peñahorcada',
			'Cazurra',
			'Castildelgado',
			'Castelló de Farfanya',
			'Castelldefels',
			'Carpio',
			'Capillas',
			'Cantallops',
			'Campolara',
			'Camós',
			"Caldes d'Estrac",
			'Calcena',
			'Calaceite',
			'Bronchales',
			'Boecillo',
			'Bovera',
			'Boadilla del Camino',
			'Blancas',
			'Berbinzana',
			'Belmonte de Campos',
			'Bello',
			'Belbimbre',
			'Beire',
			'Bárcabo',
			'Barbadillo de Herreros',
			'Bahabón',
			'Bagüés',
			'Bakaiku',
			'Azuqueca de Henares',
			'Asteasu',
			'Armenteros',
			'Aribe',
			'Arcicóllar',
			'Anquela del Ducado',
			'Anglesola',
			'Añe',
			'Amayuelas de Arriba',
			'Alloza',
			'Aldea del Obispo',
			'Alcubilla de Nogales',
			'Alcocéber',
			'Alcalá de la Vega',
			'Albalate de Cinca',
			'Agallas',
			'Adiós',
			'Las Rosas',
			'Basauri',
			'La Gangosa Vistasol',
			"la Nova Esquerra de l'Eixample",
			'la Trinitat Nova',
			'Zorita',
			'Villanueva del Duque',
			'Villamanrique',
			'Villa del Río',
			'Villacarrillo',
			'Valdepeñas de Jaén',
			'Urda',
			'Torre de Santa María',
			'Tocina',
			'Sellent',
			'Santa Margalida',
			'Santa Cruz de la Sierra',
			'Santa Bárbara de Casa',
			'Roquetas de Mar',
			'Relleu',
			'Periana',
			'Peraleda de la Mata',
			'Palomares del Campo',
			'Pájara',
			'Onda',
			"L'Olleria",
			'Oliva de la Frontera',
			'Noalejo',
			'Museros',
			'Mutxamel',
			'Montecorto',
			'Montánchez',
			'Monserrat',
			'Monesterio',
			'Moncofa',
			'Mesas de Ibor',
			'Madrigalejo',
			'Los Martínez',
			'Lora de Estepa',
			'Loja',
			'Llíria',
			'La Solana',
			'Iznalloz',
			'Horcajo de Santiago',
			'Hontanaya',
			'Guadalcanal',
			'Grazalema',
			'Fuenllana',
			'Freila',
			'Entrín Bajo',
			'Emperador',
			'Dos Torres',
			'Dalías',
			'Cuevas del Becerro',
			'Quart de Poblet',
			'Consuegra',
			'Chueca',
			'Chauchina',
			'Castro de Filabres',
			'Castellar de la Frontera',
			'Casas de Garcimolina',
			'Casares',
			'Capdepera',
			'Campillos',
			'Cambil',
			'Cala Rajada',
			'Cabezas Rubias',
			'Bienvenida',
			'Benitachell',
			'Benisuera',
			'Benimuslem',
			'Benijofar',
			'Benifallim',
			'Beas de Guadix',
			'Bargas',
			'Azután',
			'Arenas de San Juan',
			'Archena',
			'Aliseda',
			'Algarra',
			'Aldeanueva de Barbarroya',
			'Aldea del Rey',
			'Alcudia de Veo',
			'Alcolea',
			'Albolote',
			'Zas',
			'Gasteiz / Vitoria',
			'Villavaquerín',
			'Villarejo',
			'Villanueva de San Mancio',
			'Villanueva de Argecilla',
			'Villalba de la Loma',
			'Villalán de Campos',
			'Vilaxoán',
			'Villaherreros',
			'Villafranca del Bierzo',
			'Villada',
			'Eskuernaga / Villabuena de Álava',
			'Villabrágima',
			'Vidreres',
			'Viana de Duero',
			'Viana de Cega',
			'Valdunquillo',
			'Valdescorriel',
			'Valdemorillo de la Sierra',
			'Ulldemolins',
			'Ujados',
			'Tosos',
			'Torre Val de San Pedro',
			'Torremormojón',
			'Torrelaguna',
			'Torrecilla de la Abadesa',
			'Torralba',
			'Tirapu',
			'Terradillos de Esgueva',
			'Taradell',
			'Tamarit de Llitera / Tamarite de Litera',
			'Susqueda',
			'Santo Domingo de Silos',
			'Santo Domingo de las Posadas',
			'Sant Joan de Vilatorrada',
			'Santa Maria de Palautordera',
			'Santa María del Berrocal',
			'Santa Maria de Corcó',
			'Santa Eulàlia de Ronçana',
			'Santa Colomba de Curueño',
			'San Pedro de Gaíllos',
			'Sant Fruitós de Bages',
			'Sanchorreja',
			'Sanchón de la Sagrada',
			'Salillas de Jalón',
			'Salamanca',
			'Robleda',
			'Riudecols',
			'Reocín',
			'Rasines',
			'Ramales de la Victoria',
			'Rajadell',
			'Rabanera del Pinar',
			'Quintanapalla',
			'Ponte Caldelas',
			'Puebla de San Miguel',
			'Portonovo',
			'Portas',
			'Pomer',
			'Plenas',
			'Pleitas',
			'el Pla del Penedès',
			'El Pla de Santa Maria',
			'Pino del Río',
			'Piedras Blancas',
			'Perdiguera',
			'Peranzanes',
			'Peñalver',
			'Peguerinos',
			'Pedrezuela',
			'Pedraza',
			'Páramo del Sil',
			'Parada de Rubiales',
			'Padilla de Abajo',
			'Ossó de Sió',
			'Ortigosa',
			'Olmillos de Muñó',
			'Ojacastro',
			'Odón',
			'Nuévalos',
			'Noia',
			'Nogal de las Huertas',
			'Nepas',
			'Navares de las Cuevas',
			'Navamorales',
			'Navaluenga',
			'Navalmoral de Béjar',
			'Nalec',
			'Muñotello',
			'Muñico',
			'Mudá',
			'Morón de Almazán',
			'Mora de Rubielos',
			'Montenegro de Cameros',
			'Molinillo',
			'Méntrida',
			'Masueco',
			'Martiherrero',
			'Mallén',
			'Loeches',
			'Loarre',
			'Leaburu',
			'Larrodrigo',
			'Lagartos',
			'Ladrillar',
			'Jerte',
			'Jaramillo de la Fuente',
			'Infiesto',
			'Hijes',
			'Herrera de Pisuerga',
			'Garralda',
			'Gallifa',
			'Funes',
			'Fuentes de Valdepero',
			'Fuentes Calientes',
			'Fuente de Santa Cruz',
			'Esquivias',
			'Escurial de la Sierra',
			'Escucha',
			'Escariche',
			'Elorrio',
			'Elgeta',
			'Donjimeno',
			'Cuéllar',
			'Cuarte de Huerva',
			'Corcubión',
			'Corbalán',
			'Condemios de Arriba',
			'Codos',
			'Cobos de Fuentidueña',
			'Siurana',
			'Cetina',
			'Cervera del Río Alhama',
			'Cervelló',
			'Catoira',
			'Castro-Urdiales',
			'Castrillo-Tejeriego',
			'Castrillo del Val',
			'Castraz',
			'Castillo de Villamalefa',
			'Castejón de Valdejasa',
			'Castejón de Monegros',
			'Cascajares de la Sierra',
			'Carrias',
			'Carrascal de Barregas',
			'Carranque',
			'Caparroso',
			'Canredondo',
			'Cañizal',
			'Canillas de Río Tuerto',
			'Candelario',
			'Cañamaque',
			'Campillo de Dueñas',
			'Caminreal',
			'Calzada de los Molinos',
			'Cabrils',
			'Cabizuela',
			'Brieva',
			'Bonilla de la Sierra',
			'Binaced',
			'Belinchón',
			'Beleña',
			'Bayubas de Abajo',
			'Barcelona',
			'Barbués',
			'Barbastro',
			'Baños de Río Tobía',
			'Begur',
			'Azofra',
			'Avellanosa de Muñó',
			'Arnuero',
			'Argoños',
			'Argentona',
			'Arbeca',
			'Anguiano',
			'Andosilla',
			'Ambite',
			'Almaluez',
			'Aliaga',
			'Alfajarín',
			'Aldehuela de Yeltes',
			'Aldeadávila de la Ribera',
			"l'Alcora",
			'Alcocer',
			'Alcanar',
			'Alberite',
			'Albendea',
			'Aínsa',
			'Aguasal',
			'Horta-Guinardó',
			'Sant Martí de Provençals',
			'Son Ferrer',
			'Sant Julià de Ramis',
			'Costacabana',
			'Vilapicina i la Torre Llobeta',
			'Vinalesa',
			'Villarrubia',
			'Vila-real',
			'Villar de Rena',
			'Villagarcía del Llano',
			'Vilches',
			'Valverde de Leganés',
			'Umbrete',
			'Uleila del Campo',
			'Torredonjimeno',
			'Torre del Campo',
			'Sierra de Yeguas',
			'Santiponce',
			'Santa Úrsula',
			'Santa Maria del Camí',
			'Santa Cruz de Mudela',
			'Sanlúcar la Mayor',
			'Sanet y Negrals',
			'La Laguna',
			'San Clemente',
			'Rota',
			'Pozorrubio',
			'Ontur',
			'Navalmoralejo',
			'Murla',
			'Montilla',
			'Montearagón',
			'Mogente',
			'Minaya',
			'Mácher',
			'Lora del Río',
			'Logrosán',
			'Llosa de Ranes',
			'Larva',
			'La Orotava',
			'Jérez del Marquesado',
			'Higuera de Vargas',
			'Higuera',
			'Haría',
			'Guillena',
			'Granada',
			'Godella',
			'Gelves',
			'Garvín',
			'Fuentelespino de Haro',
			'Fuente la Lancha',
			'Ferreries',
			'Eslida',
			'Écija',
			'Don Benito',
			'Cútar',
			'Cobisa',
			'Cehegín',
			'Casatejada',
			'Casas Ibáñez',
			'Casas de Haro',
			'Capilla',
			'Boniches',
			'Baños de la Encina',
			'Aielo de Malferit',
			'Aroche',
			'Alhama de Granada',
			'Algueña',
			'Alfara del Patriarca',
			'Alcóntar',
			'Alcolea de Calatrava',
			'Albaida del Aljarafe',
			'Alaior',
			'Ador',
			'Zubieta',
			'Zamudio',
			'Viniegra de Arriba',
			'Villavieja de Yeltes',
			'Villaviciosa de Odón',
			'Villaverde de Medina',
			'Villarroya de los Pinares',
			'Villardiegua de la Ribera',
			'Villaquirán de los Infantes',
			'Villamediana',
			'Villamayor de Monjardín',
			'Villaluenga de la Vega',
			'Villalobos',
			'Villalba del Rey',
			'Vilafant',
			'Vega de Villalobos',
			'Valtajeros',
			'Valacloche',
			'Trigueros del Valle',
			'Tremp',
			'Torrejón de Ardoz',
			'Torrecilla del Rebollar',
			'Torreblanca',
			'Torralba de los Sisones',
			'Topas',
			'Tomiño',
			'Tolocirio',
			'Tauste',
			'Sotresgudo',
			'Somosierra',
			'Sobradiel',
			'Sequera de Fresno',
			'Sardón de Duero',
			'Santo Domingo de la Calzada',
			"Santa Maria d'Oló",
			'Santa Cruz de Nogueras',
			'San Pedro de Rozados',
			'San Pedro del Romeral',
			'San Julián de Muskiz',
			'San Juan del Molinillo',
			'San Esteban del Molar',
			'San Agustín del Pozo',
			'Agurain / Salvatierra',
			'Salomó',
			'Salmerón',
			'Rueda de la Sierra',
			'Roperuelos del Páramo',
			'Riba de Saelices',
			'Rebollosa de Jadraque',
			'Ranón',
			'Rábano',
			'Quinto',
			'Quintanilla del Monte',
			'Quintana Redonda',
			'Pueblica de Valverde',
			'Priaranza del Bierzo',
			'Poyales del Hoyo',
			'el Pont de Suert',
			'Plasencia de Jalón',
			'Pitarque',
			'Pedrosa del Páramo',
			'Parres',
			'Palazuelos de Eresma',
			'Palau-sator',
			'Palacios del Arzobispo',
			'Ourol',
			'Orrios',
			'Orendain',
			'Oco',
			'Ochánduri',
			'Navata',
			'Nalda',
			'Murero',
			'Muniesa',
			'Munébrega',
			'Moreruela de Tábara',
			'Morata de Tajuña',
			'Monterroso',
			'Muntanyola',
			'Mondoñedo',
			'Momblona',
			'Mochales',
			'Mendavia',
			'Masegoso de Tajuña',
			'Masegosa',
			'Marín',
			'Marazoleja',
			'Mañaria',
			'Luesma',
			'La Lastrilla',
			'Laguna de Negrillos',
			'La Fuente de San Esteban',
			'A Estrada',
			'La Alberca',
			'Juncosa',
			'Igriés',
			'Ivorra',
			'Huélamo',
			"L'Hospitalet de Llobregat",
			'Guadalaviar',
			'Griñón',
			'Gómara',
			'Garcia',
			'Galindo y Perahuy',
			'Fuentespreadas',
			'Fuensaldaña',
			'Fuendejalón',
			'Fresno de la Polvorosa',
			'Frechilla de Almazán',
			'Frandovínez',
			'Fontanilles',
			'Figueroles',
			'Espinosa de Cerrato',
			'Escamilla',
			'Elizondo',
			'El Castellar',
			'Duruelo',
			'Cubo de Benavente',
			'Cubillo del Campo',
			'Coscurita',
			'Collazos de Boedo',
			'Clarés de Ribota',
			'Cigales',
			'Chozas de Canales',
			'Cervera de la Cañada',
			'Cerecinos de Campos',
			'Castiliscar',
			'Castellote',
			'Castellet',
			'Castellar del Vallès',
			'Cantalojas',
			'Caminomorisco',
			'Càlig',
			'Bulbuente',
			'Berzosilla',
			'Berastegi',
			'Azagra',
			'Ayuela',
			'Arroyo de las Fraguas',
			'Argujillo',
			'Amoeiro',
			'Almazul',
			'Almajano',
			'Algodre',
			'Alcorcón',
			'Alcolea de Cinca',
			'Alberuela de Tubo',
			'Albarracín',
			'Alameda del Valle',
			'Aguatón',
			'Aguarón',
			'Adzaneta',
			'Adanero',
			'Acebedo',
			'La Manga del Mar Menor',
			'Moncloa-Aravaca',
			'Eixample',
			'Forcarei',
			'La Bonanova',
			'Capolat',
			'Fuencarral-El Pardo',
			'Castellnou de Bages',
			'la Maternitat i Sant Ramon',
			'Zufre',
			'Villanueva de San Carlos',
			'Villamalea',
			'Villalonga',
			'Valverde de Burguillos',
			'Valle de Matamoros',
			'Ugíjar',
			'Torre-Cardela',
			'Tobarra',
			'Tembleque',
			'Tales',
			'Tavernes Blanques',
			'Sot de Ferrer',
			'Serrato',
			'Senija',
			'Santa María del Campo Rus',
			'Retuerta de Bullaque',
			'Puerto de Santa Cruz',
			'Puebla de Almenara',
			'Pozoamargo',
			'Posadas',
			'Portocolom',
			'Paracuellos',
			'Órgiva',
			'Olvera',
			'Ogíjares',
			'Nívar',
			'Nazaret',
			'Muro',
			'Moncada',
			'Meliana',
			'Mata de Alcántara',
			'Malpica',
			'Macael',
			'La Rambla',
			'La Pueblanueva',
			'La Mojonera',
			'La Garrovilla',
			'Jerez de los Caballeros',
			'Ítrabo',
			'Ingenio',
			'Hontanar',
			'Hondón de los Frailes',
			'Higuera de Calatrava',
			'Gandia',
			'Fresneda de Altarejos',
			'Finestrat',
			'Ferreira',
			'Espinoso del Rey',
			'Espera',
			'Cieza',
			'Caudete de las Fuentes',
			'Cachorrilla',
			'Burriana',
			'Beniarrés',
			'Benalmádena',
			'Balsa de Ves',
			'Arroyo de la Luz',
			'Ariany',
			'Alpuente',
			'Almenara',
			'Almadén de la Plata',
			'Algimia de Almonacid',
			'Algar',
			'Alfafara',
			"L'Alcúdia de Crespìns",
			'Alconchel',
			'Alcántara',
			'Albatana',
			'Albacete',
			'Águilas',
			'Villaviciosa',
			'Villavicencio de los Caballeros',
			'Villasdardo',
			'Villasandino',
			'Villarroya',
			'Villar del Campo',
			'Villanueva de Valdegovía',
			'Villanueva de la Condesa',
			'Villaldemiro',
			'Villagonzalo-Pedernales',
			'Villadiego',
			'Villaconejos de Trabaque',
			'Vilamacolum',
			'Vidayanes',
			'Velliza',
			'Velilla de San Antonio',
			'Velilla',
			'Valverde del Fresno',
			'Valle de Cerrato',
			'Valdeolmillos',
			'Valdefuentes de Sangusín',
			'Valdeavellano de Tera',
			'Valdealgorfa',
			'Valbuena de Duero',
			'Ucero',
			'Trijueque',
			'Trazo',
			'Trasmoz',
			'Tramaced',
			'Torremenga',
			'Torrelodones',
			'Torrejón de Velasco',
			'Torre del Burgo',
			'Torre de Arcas',
			'Torà de Riubregós',
			'Tetuán de las Victorias',
			'Terrades',
			'Tabanera de Cerrato',
			'Sesa',
			'Serranillos del Valle',
			'Serrada',
			'Sena de Luna',
			'Saúca',
			'Santovenia',
			'San Torcuato',
			'Santa Inés',
			'Santa Gadea del Cid',
			'Santa Elena de Jamuz',
			'Santa Cruz de la Serós',
			'Sansol',
			'San Román',
			'San Pedro de Latarce',
			'Sant Miquel de Campmajor',
			'San Martín de Elines',
			'Sant Jordi',
			'Sanchidrián',
			'Sant Carles de la Ràpita',
			'Sant Iscle de Vallalta',
			'Sales de Llierca',
			'Salas Altas',
			'Ruesca',
			'Robres del Castillo',
			'Ripoll',
			'Reyero',
			'Retortillo',
			'Redecilla del Camino',
			'Rairiz de Veiga',
			'Rábanos',
			'Quiruelas de Vidriales',
			'Quintanilla del Olmo',
			'Quintanar de la Sierra',
			'Quintanaortuño',
			'Quintana del Pidio',
			'Puigpelat',
			'A Pobra do Brollon',
			'Pradosegar',
			'Poleñino',
			'la Pobla de Massaluca',
			'Piña de Esgueva',
			'Peque',
			'Paracuellos de la Ribera',
			'Palol de Revardit',
			'Pajarejos',
			'Ortigosa del Monte',
			'Orbara',
			'Oimbra',
			'Otxandio',
			'Muros de Nalón',
			'Murillo el Fruto',
			'Muñogrande',
			'Mozota',
			'Miravet',
			'Mingorría',
			'Mezquita de Jarque',
			'Melón',
			'Mazuela',
			'Mazarete',
			'Matillas',
			'Maçanet de Cabrenys',
			'Marchamalo',
			'Manzanares el Real',
			'Manzanal del Barco',
			'Majaelrayo',
			'Mahide',
			'Lumpiaque',
			'Lucena de Jalón',
			'Lobios',
			'Lizartza',
			'Liédena',
			'Lazkao',
			'La Selva del Camp',
			'Lantadilla',
			"L'Ametlla del Vallès",
			'Junciana',
			'Jaca',
			'Ituren',
			'Ituero de Azaba',
			'Igúzquiza',
			'Hormilleja',
			'Hormilla',
			'Hernani',
			'Herbés',
			'Guirguillano',
			'O Grove',
			'Grisel',
			'Garrigoles',
			'Galinduste',
			'Galapagar',
			'Fuentes de Jiloca',
			'Friera de Valverde',
			'Fresneda de Cuéllar',
			'Fréscano',
			'Forcall',
			'la Fatarella',
			'Espirdo',
			'Esguevillas de Esgueva',
			'Dicastillo',
			'Ciudad Rodrigo',
			'Cimballa',
			'Chodes',
			'Chert/Xert',
			'Castejón de Tornos',
			'Carreña',
			'Cangas del Narcea',
			'Cangas do Morrazo',
			'Canet lo Roig',
			'Camarena de la Sierra',
			'Camaleño',
			'Calvos',
			'Cabra de Mora',
			'Caballar',
			'Bustares',
			'Buberos',
			'Brea de Tajo',
			'Bóveda',
			'Benegiles',
			'Benavente',
			'Barrios de Colina',
			'Barracas',
			'Balboa',
			'Ávila',
			'Argavieso',
			'Arenys de Mar',
			'Arapiles',
			'Añover de Tormes',
			'Amusco',
			'Altsasu',
			'Almenara de Tormes',
			'Alfarràs',
			'Aldeanueva de Ebro',
			'Aldealafuente',
			'Aldea del Fresno',
			'Alcubierre',
			'Albillos',
			'Alba de Tormes',
			'Alarba',
			'Alagón',
			'Aguilar de Bureba',
			'Aguaviva',
			'Santa Ponsa',
			'Playa del Ingles',
			'Can Pastilla',
			'Playa de las Américas',
			'Camarles',
			'Sant Guim de Freixenet',
			'Corvera de Asturias',
			'el Baix Guinardó',
			'Sant Gervasi - Galvany',
			'el Bon Pastor',
			'Villanueva del Río y Minas',
			'Villanueva de la Jara',
			'Villamayor de Santiago',
			'Valverde de Mérida',
			'Vall de Almonacid',
			'Vallada',
			'Torralba del Pinar',
			'Tollos',
			'Tarifa',
			'Tanque',
			'Táliga',
			'Suflí',
			'Santomera',
			'San Nicolás del Puerto',
			"Sant Joan d'Alacant",
			'Sant Joan',
			'San Fernando',
			'Prado del Rey',
			'Potríes',
			'Peligros',
			'Olula del Río',
			'Olías del Rey',
			'Montesa',
			'Montefrío',
			'Membrilla',
			'Mazagón',
			'Macastre',
			'Layos',
			'Jimena',
			'Istán',
			'Huéneja',
			'Gálvez',
			'Fuensanta',
			'Fuencaliente de la Palma',
			'Estubeny',
			'Encinas Reales',
			'El Pedernoso',
			'Elche',
			'Denia',
			'Corral de Almaguer',
			'Castillo de Locubín',
			'Castelló de la Plana',
			'Cañamero',
			'Bunyola',
			'Bornos',
			'Bienservida',
			'Benissa',
			'Bayárcal',
			'Balones',
			'Aznalcóllar',
			'Arjonilla',
			'Arjona',
			'Almoines',
			'Almería',
			'Alfara de Algimia',
			'Alfafar',
			'Alzira',
			'Alberic',
			'Albaladejo',
			'Zorraquín',
			'Zamarra',
			'Viñuelas',
			'Vinaixa',
			'Vilviestre del Pinar',
			'Villota del Páramo',
			'Villoslada de Cameros',
			'Villoría',
			'Villaverde de Montejo',
			'Villán de Tordesillas',
			'Villamañán',
			'Villafranca de la Sierra',
			'Villabuena del Puente',
			'Villabraz',
			'Vilabertran',
			'Vidrà',
			'Bergara',
			'Valverde de Campos',
			'Valderrey',
			'Valdeolmos',
			'Valdelinares',
			'Uterga',
			'Ultramort',
			'Tosantos',
			'Torrijo de la Cañada',
			'Tivissa',
			'Terradillos',
			'Tejeda y Segoyuela',
			'Taravilla',
			'Tabanera de Valdavia',
			'Súria',
			'Solórzano',
			'Sinlabajos',
			'Seno',
			'San Vicente de la Sonsierra',
			'Santurdejo',
			'Sants',
			'Santibáñez de Ecla',
			'Santervás de Campos',
			'Santa Coloma de Farners',
			'San Millán de los Caballeros',
			'San Miguel de Corneja',
			'San Miguel de Aguayo',
			'San Mamés de Burgos',
			'Sanchonuño',
			'Sallent de Gállego',
			'Salas Bajas',
			'Sabiñánigo',
			'Roda de Barà',
			'Robres',
			'Ribadavia',
			'Rasquera',
			'Quiroga',
			'Purujosa',
			'Puertas',
			'Puente la Reina',
			'Puebla de San Medel',
			'Prados Redondos',
			'Pozanco',
			'Pozalmuro',
			'Pizarral',
			'Pelarrodríguez',
			'Pedrosa de la Vega',
			'Pedrajas de San Esteban',
			'Pardilla',
			'Páramo de Boedo',
			'Pajares de Adaja',
			'Otero de Bodas',
			'Oseja de Sajambre',
			'Ortuella',
			'Oronz',
			'Orihuela del Tremedal',
			'Navatejares',
			'Moriscos',
			'Moratalaz',
			'Morales de Toro',
			'Monzón',
			'Montejo',
			'Monsalupe',
			'Milles de la Polvorosa',
			'Mieres',
			'Medranda',
			'Marzales',
			'Martín de Yeltes',
			'Mandayona',
			'Machacón',
			'Luzmela',
			'Lumbrales',
			'Lladó',
			'Linyola',
			'Ledigos',
			'La Pedraja de Portillo',
			'Langayo',
			'Jaulín',
			'Jasa',
			'Ibrillos',
			'Hontanares de Eresma',
			'Guadarrama',
			'Gósol',
			'Golmés',
			'Gistaín',
			'Gargantilla',
			'Gallegos de Sobrinos',
			'Gallegos del Río',
			'Galilea',
			'Fuentesecas',
			'Fuembellida',
			'Fonzaleche',
			'Ferreras de Arriba',
			'Ferreras de Abajo',
			'Estremera',
			'Espejón',
			'Empuriabrava',
			'Zalla',
			'Ea',
			'Cuelgamures',
			'Cubla',
			'Cualedro',
			'Crespià',
			'Corera',
			'Cordovín',
			'Contamina',
			'Collado Mediano',
			'Coaña',
			'Ziordia',
			'Cevico de la Torre',
			'Cernadilla',
			'Cerezo de Río Tirón',
			'Celrà',
			'Castillejo de Robledo',
			'Casla',
			'Carrascosa de la Sierra',
			'Cardeñajimeno',
			'Cantabrana',
			'Canencia',
			'Cabanes',
			'Brincones',
			'Brazuelo',
			'Brazacorta',
			'Boada de Campos',
			'Blesa',
			'Blascosancho',
			'Blascomillán',
			'la Bisbal del Penedès',
			'Besalú',
			'Bermeo',
			'Berge',
			'Bercianos del Páramo',
			'Benuza',
			'Belascoáin',
			'Baliarrain',
			'Ausejo de la Sierra',
			'Aulesti',
			'Arconada',
			'Aranjuez',
			'Aragüés del Puerto',
			'Anguita',
			'Anadón',
			'Ampudia',
			'Algete',
			'Aldeatejada',
			'Aldea de San Miguel',
			'Alconaba',
			'Alcolea de las Peñas',
			'Alcázar del Rey',
			'Alcantud',
			'Albelda',
			'Adobes',
			'Abusejo',
			'Pinar de Chamartín',
			'Magaluf',
			'Sant Martí',
			'Sedella',
			'Barreiros',
			'Navarredonda de Gredos',
			'Jete',
			'Valdezorras',
			'Zarza la Mayor',
			'Villapalacios',
			'Villablanca',
			'Valencia del Mombuey',
			'Valencia de Alcántara',
			'Valdemanco del Esteras',
			'Trujillo',
			'Tribaldos',
			'Torrenueva',
			'Torre de Miguel Sesmero',
			'Torrecillas de la Tiesa',
			'Tébar',
			'Solana de los Barros',
			'Socuéllamos',
			'Sierra de Fuentes',
			'Segura de la Sierra',
			'Segorbe',
			'Santaella',
			'Santa Cruz de la Zarza',
			'San Martín de Boniches',
			'Sanlúcar de Barrameda',
			'Salvatierra de los Barros',
			'Reina',
			'Quintanar de la Orden',
			'Puerto Lápice',
			'Puente-Genil',
			'Polop',
			'Pinarejo',
			'Pedrera',
			'Partaloa',
			'Onil',
			'Ojós',
			'Ohanes',
			'Monachil',
			'Miramar',
			'Maria de la Salut',
			'Librilla',
			'La Victoria',
			'Las Torres de Cotillas',
			'La Alberca de Záncara',
			'Jumilla',
			'Jimena de la Frontera',
			'Illán de Vacas',
			'Huélago',
			'Herrera de Alcántara',
			'Herrera',
			'Herguijuela',
			'Guadassuar',
			'Garrucha',
			'Enix',
			'Enguera',
			'El Gastor',
			'El Cerro de Andévalo',
			'Quatretonda',
			'Chumillas',
			'Castellonet de la Conquesta',
			'Castelló de Rugat',
			'Castellar de Santiago',
			'Cartajima',
			'Carataunas',
			'Cañete la Real',
			'Cádiar',
			'Brozas',
			'Bétera',
			'Benitagla',
			'Benisanó',
			'Beniarjó',
			'Bélmez de la Moraleda',
			'Baena',
			'Arucas',
			'Argamasilla de Alba',
			'Antequera',
			'Almedina',
			'Alía',
			'Alhama de Murcia',
			'Albalat dels Tarongers',
			'Ajofrín',
			'Zarautz',
			'Zamora',
			'Zambrana',
			'Yesa',
			'Yélamos de Abajo',
			'Vinuesa',
			'Villaveza del Agua',
			'Villatuelda',
			'Villarreal de Huerva',
			'Villares de Yeltes',
			'Villares de la Reina',
			'Villardondiego',
			'Villar de Torre',
			'Villaquilambre',
			'Villanueva de la Vera',
			'Villangómez',
			'Villafranca',
			'Villaescusa',
			'Villaconejos',
			'Vilada',
			'Vezdemarbán',
			'Verea',
			'Valencia de Don Juan',
			'Valdesotos',
			'Val de San Lorenzo',
			'Valdeavero',
			'Valdeande',
			'Truchas',
			'Triollo',
			'Trasmiras',
			'Trabadelo',
			'Torres de Barbués',
			'Toloriu',
			'Tinajas',
			'Taroda',
			'Soto y Amío',
			'Sotillo de la Adrada',
			'Soses',
			'Siete Iglesias de Trabancos',
			'Senan',
			'Sayatón',
			'Sartajada',
			'Sarracín',
			'Santiso',
			'Santa María de Huerta',
			'Santa Cruz de Grío',
			'Sant Quirze del Vallès',
			'Sant Martí de Centelles',
			'Sant Llorenç de la Muga',
			'San García de Ingelmos',
			'Sant Cugat del Vallès',
			'San Cebrián de Campos',
			'Sant Boi de Lluçanès',
			'Sant Andreu Salou',
			'Sant Andreu',
			'Samper de Calanda',
			'Santpedor',
			'Salvadiós',
			'Saldeana',
			'Sahagún',
			'Romanos',
			'Reinoso',
			'Recuerda',
			'Quintanilla del Molar',
			'Quintanilla de Arriba',
			'Puebla de Yeltes',
			'Pozoantiguo',
			'Poio',
			'Poveda',
			'Potes',
			'Plentzia',
			'Pioz',
			'Pinarejos',
			'Piloña',
			'Pedrosillo de los Aires',
			'Pedro Bernardo',
			'Parets del Vallès',
			'Paracuellos de Jarama',
			'Palazuelos de la Sierra',
			'Palafrugell',
			'Palacios del Sil',
			'Padilla de Arriba',
			'Oia',
			'Ormaiztegi',
			'Olius',
			'Olesa de Bonesvalls',
			'Negredo',
			'Navia',
			'Navalcán',
			'Nava de Sotrobal',
			'Muruzábal',
			'Muro de Aguas',
			'Murchante',
			'Muñogalindo',
			'Montemayor de Pililla',
			'Montán',
			'Mombeltrán',
			'Mequinensa / Mequinenza',
			'Melgar de Tera',
			'Medinaceli',
			'Matilla la Seca',
			'Masquefa',
			'Margalef',
			'Madrigal de las Altas Torres',
			'Ludiente',
			'Logroño',
			'Llívia',
			'Litago',
			'Letux',
			'Lechón',
			'Lastras de Cuéllar',
			'Juneda',
			'Jaraba',
			'Isuerre',
			'Huete',
			'Hontoria de Cerrato',
			'Haro',
			'Guisando',
			'Genevilla',
			'Gelida',
			'Gascueña de Bornova',
			'Garínoain',
			'Gargallo',
			'Fuentes de Ayódar',
			'Fresno de la Vega',
			'Florida de Liébana',
			'Figueruelas',
			'Fermoselle',
			'Espinosa de Cervera',
			'Escobosa de Almazán',
			'Encinas',
			'Embid de Ariza',
			'Elciego',
			'El Casar de Escalona',
			'El Barco de Ávila',
			'Cuevas de Vinromá',
			'Cubillas de Cerrato',
			'Cubas',
			'Cruïlles',
			'Covaleda',
			'Contreras',
			'Conesa',
			'Combarro',
			'Cobeña',
			'Cilleruelo de San Mamés',
			'Ciguñuela',
			'Chiprana',
			'Cerralbo',
			'Cedillo de la Torre',
			'Castro Caldelas',
			'Castillo de Bayuela',
			'Castel de Cabra',
			'Casaseca de las Chanas',
			'Casar de Palomero',
			'Capella',
			'Cañaveruelas',
			'Camprodon',
			'Cambre',
			'Cabredo',
			'Cabezuela del Valle',
			'Burgohondo',
			'Brabos',
			'Bolaños de Campos',
			'Bigues i Riells',
			'Berriobeiti',
			'Berriatua',
			'Berceo',
			'Benavarri / Benabarre',
			'Belmonte de Tajo',
			'Beade',
			'Bardallur',
			'Bárcena de Cicero',
			'Baños de Rioja',
			'Badalona',
			'Ataun',
			'Arrigorriaga',
			'Arnedillo',
			'Arevalillo de Cega',
			'Arcos de la Sierra',
			'Arcenillas',
			'Arbúcies',
			'Araia',
			'Aranarache',
			'Amavida',
			'Alpeñés',
			'Alpanseque',
			'Almazán',
			'Allo',
			'Algorta',
			'Alforque',
			'Alcañices',
			'Alcalá de Ebro',
			'Alcaine',
			'Aizarnazabal',
			'Ahigal de los Aceiteros',
			'Abezames',
			"Cala d'Or",
			'Alquerías del Niño Perdido',
			'Ballesteros de Calatrava',
			'Carabanchel',
			'Vallcarca',
			'Sondika',
			'Villanova',
			'Andosilla',
			'Viveros',
			'Villarta',
			'Villar de Cañas',
			'Ventas con Peña Aguilera',
			'Vejer de la Frontera',
			'Valencia del Ventoso',
			'Úbeda',
			'Trevélez',
			'Torremocha',
			'Torrejón el Rubio',
			'Tamurejo',
			'Talarrubias',
			'San Roque',
			'San Lorenzo de Calatrava',
			'San Juan del Puerto',
			'San Juan de la Rambla',
			'Playa de San Juan',
			'Sant Josep de sa Talaia',
			'Sant Antoni de Portmany',
			'Rozalén del Monte',
			'Rocafort',
			'Robledo',
			'Riola',
			'Rioja',
			'Rincón de la Victoria',
			'Padules',
			'Olivares de Júcar',
			'Obejo',
			'Murcia',
			'Mota de Altarejos',
			'Montoro',
			'Montemayor',
			'Molvízar',
			'Mojacar',
			'Millena',
			'Madroñera',
			'Lucillos',
			'Lobras',
			'Liétor',
			'Lepe',
			"L'Eliana",
			'Jabalquinto',
			'Icod de los Vinos',
			'Huerta de la Obispalía',
			'Herencia',
			'Gines',
			'Gavarda',
			'Fuentes de León',
			'Fuenteheridos',
			'Fortuna',
			'Dílar',
			'Coria',
			'Ceclavín',
			'Casasimarro',
			'Cañaveral',
			'Cáceres',
			'Buenavista del Norte',
			'Beas',
			'Barcarrota',
			'Balazote',
			'Artà',
			'Almendralejo',
			'Alcúdia',
			'Alcázar de San Juan',
			'Alcalá de los Gazules',
			'Albanchez',
			'Aguadulce',
			'Agrón',
			'Zazuar',
			'Zarapicos',
			'Vozmediano',
			'Villatoro',
			'Villaseca de Henares',
			'Urretxu',
			'Villarino de los Aires',
			'Villaobispo de Otero',
			'Villanueva del Pardillo',
			'Villanueva del Campo',
			'Villanueva de Alcorón',
			'Villamejil',
			'Villamediana de Iregua',
			'Villamayor',
			'Villalpando',
			'Villahán',
			'Villaescusa la Sombría',
			'Vilanova de Sau',
			'Vicálvaro',
			'Valsalobre',
			'Valdoviño',
			'Valdegrudas',
			'Valdefresno',
			'Valdeavellano',
			'Trabanca',
			'Torremochuela',
			'Torrelles de Llobregat',
			'Torrelavega',
			'Torrefarrera',
			'Torrecaballeros',
			'Torrebesses',
			'Tordómar',
			'Toén',
			'Todolella',
			'Tiurana',
			'Tirgo',
			'Tejadillos',
			'Tapia de Casariego',
			'Sordillos',
			'Silleda',
			"La Seu d'Urgell",
			'Seira',
			'Saro',
			'Sariegos',
			'Sant Julià de Vilatorta',
			'Santa Susanna',
			'Santa Oliva',
			'Santa María del Val',
			'Santa Eulalia de Oscos',
			'Santa Uxía de Ribeira',
			'San Sadurniño',
			'San Román de Cameros',
			'San Pablo de la Moraleja',
			'San Martín de Valdeiglesias',
			'San Lorenzo de Tormes',
			'Sant Feliu de Codines',
			'Sant Celoni',
			'San Bartolomé de Corneja',
			'San Andrés del Rey',
			'Salou',
			'Sallent',
			'Salinillas de Bureba',
			'Royuela de Río Franco',
			'Rincón de Soto',
			'Ribas de Campos',
			'Riaguas de San Bartolomé',
			'Renera',
			'Regumiel de la Sierra',
			'Recas',
			'Quintanilla del Coco',
			'Puertomingalvo',
			'Puente Viesgo',
			'Poza de la Vega',
			'Plasencia',
			'Pinilla de los Barruecos',
			'Pinilla de Jadraque',
			'Pertusa',
			'Penelles',
			'Peñaparda',
			'Pedraza de Campos',
			'Pazuengos',
			'Parla',
			'Paredes de Sigüenza',
			'Orís',
			'Olmos de Peñafiel',
			'Olmedo de Camaces',
			'Navacepedilla de Corneja',
			'Morata de Jiloca',
			'Moraleja de Sayago',
			'Montalbán',
			'Mirambel',
			'Miño de San Esteban',
			'Meneses de Campos',
			'Melgar de Arriba',
			'Melgar de Abajo',
			'Mediona',
			'Matilla de los Caños del Río',
			'Magaz de Cepeda',
			'Maderuelo',
			'Llambilles',
			'Polanco',
			'Lagrán',
			'Jorba',
			'Jalón de Cameros',
			'Huércanos',
			'Herrera de los Navarros',
			'Hernán-Pérez',
			'Hermisende',
			'Gràcia',
			'Gaztelu',
			'Garcillán',
			'Gamonal',
			'Gallipienzo',
			'Fulleda',
			'Fuentes de Rubielos',
			'Fuentepiñel',
			'Fuentelcésped',
			'Frías',
			'Fresno de Rodilla',
			'Fresno de la Ribera',
			'Fortià',
			'Fonsagrada',
			'Ferreruela de Huerva',
			'Fene',
			'Esgos',
			'Escatrón',
			'Entrena',
			'Duesaigües',
			'Coreses',
			'Corduente',
			'el Clot',
			'Ciutadilla',
			'Chinchón',
			'Cevico Navero',
			'Cesuras',
			'Cerezo de Abajo',
			'Cerceda',
			'Catí',
			'Castroponce',
			'Castro de Rei',
			'Castrillo de Don Juan',
			'Castilfrío de la Sierra',
			'Castelflorite',
			'Casas Altas',
			'Casarejos',
			'Cariñena',
			'Cardeñadijo',
			'Carballo',
			'Cantalejo',
			'Campillos-Sierra',
			'Calahorra de Boedo',
			'Cabanelles',
			'Borja',
			'Becilla de Valderaduey',
			'Batres',
			'Barcial del Barco',
			'Barbadillo del Pez',
			'Balsareny',
			'Bahabón de Esgueva',
			'Báguena',
			'Autillo de Campos',
			'Ariza',
			'Arenys de Munt',
			'Anguix',
			'Almarza de Cameros',
			'Alentisque',
			'Aldeaseca',
			'Alcover',
			'Abajas',
			'Pedralbes',
			'Almozara',
			'la Vila de Gràcia',
			'les Roquetes',
			'Sarriguren',
			'Villanueva de la Serena',
			'Villanueva de la Reina',
			'Valenzuela',
			'Torrox',
			'Teba',
			'Tavernes de la Valldigna',
			'Son Servera',
			'Simat de la Valldigna',
			'Silla',
			'Serrejón',
			'Segart',
			'Santiago del Teide',
			'Santa Cruz de la Palma',
			'San Miguel De Abona',
			'San Lorenzo de la Parrilla',
			'Sant Llorenç des Cardassar',
			'Sagunto',
			'Rágol',
			'Rafelguaraf',
			'Puigpunyent',
			'Puente de Génave',
			'Montaberner',
			'Moguer',
			'Miguelturra',
			'Mercadal',
			'Maspalomas',
			'Lloret de Vistalegre',
			'La Puebla de Montalbán',
			'Xàtiva',
			'Huétor-Tájar',
			'Golosalvo',
			'Galisteo',
			'Fuerte del Rey',
			'Fondón',
			'Espelúy',
			'El Altet',
			'Comares',
			'Cocentaina',
			'Chucena',
			'Castalla',
			'Casinos',
			'Casillas de Coria',
			'Cantoria',
			'Canena',
			'Campiña',
			'Campanet',
			'Calonge',
			'Binissalem',
			'Benifaió',
			'Benidorm',
			'Beneixida',
			'Benalúa de las Villas',
			'Belvis de la Jara',
			'Ayna',
			'Alsodux',
			"L'Alqueria de la Comtessa",
			'Algorfa',
			'Aldeacentenera',
			'Alcaucín',
			'Alcaracejos',
			'Alameda',
			'Aigües',
			'Yebes',
			'Villarroya de la Sierra',
			'Areatza',
			'Villanueva de Perales',
			'Villameriel',
			'Villalengua',
			'Villablino',
			'Viguera',
			'Venialbo',
			'Vega de Pas',
			'Valgañón',
			'Valga',
			'Valdemoro-Sierra',
			'Vadillo',
			'Used',
			'Toses',
			'Torremontalbo',
			'Torreiglesias',
			'Torre de Esgueva',
			'Taramundi',
			'Sotalbo',
			'Sigeres',
			'Siétamo',
			'Sesué',
			'Barakaldo',
			'Santovenia de Pisuerga',
			'Santibáñez el Bajo',
			'Santibáñez del Val',
			'Santibáñez de la Peña',
			'San Pelayo de Guareña',
			'San Muñoz',
			'Sant Mori',
			'San Millán de Lara',
			'Sant Julià de Cerdanyola',
			'Sanxenxo',
			'San Felices',
			'San Esteban del Valle',
			'San Cebrián de Castro',
			'Sant Boi de Llobregat',
			'San Asensio',
			'San Adrián',
			'Saldes',
			'Saelices el Chico',
			'Riudarenes',
			'Riós',
			'Renau',
			'Rebolledo de la Torre',
			'Portilla',
			'Pollos',
			'Polentinos',
			'Pelayos',
			'Pedrosa del Rey',
			'Pedrola',
			'Oion / Oyón',
			'Outes',
			'Ourense',
			'Oña',
			'Olost',
			'Olmeda de Cobeta',
			'Ocón',
			'Nuñomoral',
			'Negrilla de Palencia',
			'Morentin',
			'Morenilla',
			'Moraleja del Vino',
			'Montorio',
			'Montejo de la Vega de la Serrezuela',
			'Mondariz-Balneario',
			'Miranda de Azán',
			'Milmarcos',
			'Micieces de Ojeda',
			'Mazariegos',
			'Matadepera',
			'Maçanet de la Selva',
			'Masdenverge',
			'Mas de las Matas',
			'Marugán',
			'Madrigal de la Vera',
			'Luesia',
			'Lobios',
			'Llano de Bureba',
			'Les',
			'León',
			'Legarda',
			"les Planes d'Hostoles",
			'La Robla',
			'La Pola de Gordón',
			'La Pobla de Claramunt',
			'Lanaja',
			'La Llagosta',
			'Labajos',
			'Juià',
			'Josa',
			'Isar',
			'Illueca',
			'Ibdes',
			'Hoyos del Espino',
			'Hoyos',
			'Griegos',
			'Gordaliza del Pino',
			'Geria',
			'Gallegos',
			'Gajanejos',
			'Fuenmayor',
			'Fresnillo de las Dueñas',
			'Foz',
			'Estépar',
			'Estella-Lizarra',
			'Esponellà',
			'Esplús',
			'O Barco de Valdeorras',
			'Cuevas de Provanco',
			'Coslada',
			'Chillarón de Cuenca',
			'Chapinería',
			'Cedillo del Condado',
			'Castronuevo',
			'Castrojimeno',
			'Castrillo de la Vega',
			'Castrillo de la Reina',
			'Castellanos de Moriscos',
			'Casbas de Huesca',
			'Carucedo',
			'Cartes',
			'Carrocera',
			'Cardeñosa de Volpejera',
			'Carcastillo',
			'Carcaboso',
			'Carballedo',
			'Carabaña',
			'Camarena',
			'Calvarrasa de Arriba',
			'Caleruega',
			'Calella',
			'Cabrerizos',
			'Briones',
			'Brime de Urz',
			'Bolvir',
			'Boca de Huérgano',
			'Boal',
			'Berzosa de Bureba',
			'Berrocalejo de Aragona',
			'Berlangas de Roa',
			'Benavides',
			'Becerril de la Sierra',
			'Avión',
			'Atapuerca',
			'Artés',
			'Arahuetes',
			'Ambía',
			'Alfés',
			'Aldeamayor de San Martín',
			'Alba de Cerrato',
			'Alacón',
			'Ahigal de Villarino',
			'Pego',
			'Mendexa',
			'Totalán',
			'Liérganes',
			'La Pobla de Farnals',
			'Los Morillas',
			'Zafra',
			'Víznar',
			'Villarejo de Fuentes',
			'Villar de la Encina',
			'Villaralto',
			'Villanueva del Rosario',
			'Vergel',
			'Valsequillo de Gran Canaria',
			'Valencia',
			'Urrácal',
			'Torrubia del Castillo',
			'Torreorgaz',
			'Torre de Juan Abad',
			'Teror',
			'San Nicolás',
			'San Bartolomé',
			'Salvatierra de Santiago',
			'Ruanes',
			'Rotglá y Corbera',
			'Rojales',
			'Robledollano',
			'Ríolobos',
			'Redován',
			'Puerto Serrano',
			'Playa Blanca',
			'Pinet',
			'Pechina',
			'Nerpio',
			'Navahermosa',
			'Monturque',
			'Montalbanejo',
			'Medina Sidonia',
			'Matet',
			'Massamagrell',
			'Mairena del Aljarafe',
			'Luque',
			'Lújar',
			'Los Navalmorales',
			'Llubí',
			'Llaurí',
			'Lillo',
			'Lezuza',
			'Jalance',
			'Iznate',
			'Ibahernando',
			'Huéscar',
			'Higuera de la Serena',
			'Hellín',
			'Guadalupe',
			'Gestalgar',
			'Fuente de Piedra',
			'Fuente-Álamo de Murcia',
			'El Paso',
			'Elda',
			'Elche de la Sierra',
			'Doña Mencía',
			'Cortes de la Frontera',
			'Cenizate',
			'Can Picafort',
			'Candelaria',
			'Calicasas',
			'Búger',
			'Benferri',
			'Andilla',
			'Almendral',
			'Almansa',
			'Aldeaquemada',
			'Alcabón',
			'Alburquerque',
			'Albuñuelas',
			'Alajeró',
			'Aceuchal',
			'Yuncler',
			'Viloria',
			'Villasila de Valdavia',
			'Villasbuenas de Gata',
			'Villarejo de Salvanés',
			'Villardefrades',
			'Villanázar',
			'Villafufre',
			'Villaeles de Valdavia',
			'Villacid de Campos',
			'Villacarriedo',
			'Villabáñez',
			'Vilademuls',
			'El Vendrell',
			'Vedra',
			'Valseca',
			'Valmala',
			'Vallarta de Bureba',
			'Valdorros',
			'Valderrebollo',
			'Valdenebro de los Valles',
			'Utrillas',
			'Urrea de Jalón',
			'Undués de Lerda',
			'Turégano',
			'Tudelilla',
			'Tubilla del Agua',
			'Traspinedo',
			'Torremocha del Campo',
			'Toga',
			'Térmens',
			'Tarancón',
			'Taragudo',
			'Sunbilla',
			'Seròs',
			'Sepúlveda',
			'Santibáñez el Alto',
			'Santa Pau',
			'San Martín de Rubiales',
			'San Martín de Oscos',
			'San Juan de Moró',
			'Sando',
			'Salvacañete',
			'Salinas del Manzano',
			'Rupià',
			'Rucandio',
			'Rubiales',
			'Romanillos de Atienza',
			'Remolinos',
			'Quintanilla de Urz',
			'Puebla de Pedraza',
			'Pradillo',
			'Pradales',
			'Pozuelo del Rey',
			'Portillo',
			'Pont de Molins',
			'Plan',
			'Piornal',
			'Piñel de Abajo',
			'Petilla de Aragón',
			'Peralejos de las Truchas',
			'Pancrudo',
			'Pajares de los Oteros',
			'Otero de Herreros',
			'Orés',
			'Olea de Boedo',
			'Oencia',
			'Ocentejo',
			'Noviercas',
			'Nieva de Cameros',
			'Nava de Arévalo',
			'Mura',
			'Morasverdes',
			'Miranda de Ebro',
			'Mazaricos',
			'Mayorga',
			'Matadeón de los Oteros',
			'Manzaneda',
			'Lubián',
			'Los Santos de la Humosa',
			'Libros',
			'Lerma',
			'Layana',
			'Laguna del Marquesado',
			'A Coruña',
			'La Alameda de la Sagra',
			'Jaramillo Quemado',
			'Illas',
			'Idiazabal',
			'Hervás',
			'Gemuño',
			'Garganta de los Montes',
			'Fuentidueña',
			'Fuentecén',
			'Fompedraza',
			'Flaçà',
			'Fago',
			'Espinosa del Camino',
			'Escalona del Prado',
			'El Real de San Vicente',
			'Durango',
			'Cuadros',
			'Cordovilla',
			'Coca de Alba',
			'Cimanes de la Vega',
			'Cilleruelo de Arriba',
			'Chamartín',
			'Cervera',
			'Castromembibre',
			'Castrogonzalo',
			'Castellnou de Seana',
			'Casas del Monte',
			'Cármenes',
			'Cantavieja',
			'Cañizar del Olivar',
			'Cañada de Benatanduz',
			'Campillo de Ranas',
			'Calahorra',
			'Broto',
			'Bretó',
			'Borredà',
			'Boiro',
			'Bobadilla del Campo',
			'Berrocal de Salvatierra',
			'Belmonte',
			'Beamud',
			'la Barceloneta',
			'Bañuelos',
			'Bañares',
			'Ballobar',
			'Azara',
			'Avià',
			'Avellaneda',
			'Atienza',
			'Aspa',
			'Aria',
			'Argente',
			'Arenzana de Arriba',
			'Allueva',
			'Aldealengua de Santa María',
			'Alcubilla de las Peñas',
			'Alcarràs',
			'Alcalá de Moncayo',
			'Albero Bajo',
			'Albalate del Arzobispo',
			'Aladrén',
			'Aguilar del Alfambra',
			'Aguilar de Codés',
			'Adradas',
			'Ábalos',
			'Sopuerta',
			'Ciutat Vella',
			'Latina',
			'Santutxu',
			'la Font de la Guatlla',
			'Cazalla',
			'Villarrubia de los Ojos',
			'Villar del Rey',
			'Villanueva de Alcardete',
			'Villagarcía de la Torre',
			'Valhermoso de la Fuente',
			'Torreblascopedro',
			'Titaguas',
			'Sorihuela del Guadalimar',
			'Santa Eulària des Riu',
			'Santa Cruz de los Cáñamos',
			'San Miguel de Salinas',
			'San Bartolomé de la Torre',
			'Sabiote',
			'Paymogo',
			'Montichelvo',
			'Mijas',
			'Mestanza',
			'Marbella',
			'Los Barrios',
			'Llucmajor',
			'La Romana',
			'La Roda',
			'La Haba',
			'La Gineta',
			'Jalón',
			'Guía de Isora',
			'Gorga',
			'Fuente del Maestre',
			'Frigiliana',
			'Domeño',
			'Deifontes',
			'Corteconcepción',
			'Ciudad Real',
			'Cerdà',
			'Castilleja de Guzmán',
			'Casas de Don Antonio',
			'Campos del Río',
			'Calasparra',
			'Bogarra',
			'Benquerencia',
			'Atalaya',
			'Atajate',
			'Arenas del Rey',
			'Aljaraque',
			'Alcublas',
			'Alcàntera de Xúquer',
			'Alcalá del Júcar',
			'Albatera',
			'Abengibre',
			'Zorita del Maestrazgo',
			'Zaldibar',
			'Zaidín',
			'Zael',
			'Vizcaínos',
			'Vimianzo',
			'Villavelayo',
			'Villaturiel',
			'Vilariño',
			'Villanueva de Gállego',
			'Villanueva de Carazo',
			'Villamantilla',
			'Villalcón',
			'Villagarcía de Campos',
			'Villafrechós',
			'Vertavillo',
			'Venturada',
			'Ventosa',
			'Valverdón',
			'Vallirana',
			'Valleruela de Pedraza',
			'Vallecillo',
			'Valdunciel',
			'Val de San Martín',
			'Valdemoro',
			'Valcabado',
			'Vadillo de la Sierra',
			'Uncastillo',
			'Tulebras',
			'Tordillos',
			'Tolbaños',
			'Tiñosillos',
			'Teruel',
			'Tejado',
			'Sotillo de la Ribera',
			'Sora',
			'Sopela',
			'Porto do Son',
			'Selas',
			'Segovia',
			'Santo Tomé de Zabarcos',
			'Santa María de Ordás',
			'San Miguel de Meruelo',
			'San Miguel del Pino',
			'Sant Jordi Desvalls',
			'Sant Jaume dels Domenys',
			'Sangüesa/Zangoza',
			'Sangarrén',
			'Sanchón de la Ribera',
			'San Amaro',
			'Salvatierra de Tormes',
			'Salvatierra de Esca',
			'Salinas de Oro',
			'Robledo de Corpes',
			'Robledillo de la Vera',
			'Revellinos',
			'Retortillo de Soria',
			'Punxín',
			'Pozuelo de la Orden',
			'Plou',
			'Piérnigas',
			'Piedratajada',
			'Peralejos',
			'Penagos',
			'Pedrosa de Duero',
			'Parlavà',
			'Pallejà',
			'Padiernos',
			'Paderne',
			'Outeiro de Rei',
			'Ordis',
			'Olvega',
			'Olivella',
			'Oliana',
			'Olazagutía',
			'Nuevo Baztán',
			'Niharra',
			'Navascués',
			'Navalosa',
			'Navaconcejo',
			'Muriel Viejo',
			'Muñopedro',
			'Montamarta',
			'Montgat',
			'Miranda del Castañar',
			'Millana',
			'Mendigorría',
			'Membrillera',
			'Membibre de la Hoz',
			'Megina',
			'Medina de Pomar',
			'Mecerreyes',
			'Manzanal de los Infantes',
			'Mantiel',
			'Mansilla de las Mulas',
			'Manchones',
			'Malpica',
			'Malón',
			'Luzón',
			'Lucena del Cid',
			'Llagostera',
			'Ledesma',
			'Lazagurría',
			'Las Ventas de Retamosa',
			'La Secuita',
			'Lasarte',
			'Laredo',
			'Justel',
			'Jafre',
			'Hoyos del Collado',
			'Echo',
			'Gurrea de Gállego',
			'Guadalix de la Sierra',
			'Gradefes',
			'Gimialcón',
			'Garde',
			'Fuentestrún',
			'Fuenteguinaldo',
			'Fresnedilla',
			'Falces',
			'Fabara',
			'Estercuel',
			'Eskoriatza',
			'Encinasola de los Comendadores',
			'Encinas de Arriba',
			'El Viso de San Juan',
			'Dios le Guarde',
			'Dehesa de Montejo',
			'Cuzcurrita de Río Tirón',
			'Cueva del Hierro',
			'Cristóbal',
			'Colungo',
			'Colmenarejo',
			'Cobeja',
			'Cistérniga',
			'Chimillas',
			'Chañe',
			'Cervera de los Montes',
			'Centelles',
			'Celanova',
			'Ceinos de Campos',
			'Cebrones del Río',
			'Cayuela',
			'Castroverde de Cerrato',
			'Castillejo de Mesleón',
			'Castilforte',
			'Castilfalé',
			'Castellfollit del Boix',
			'Capafonts',
			'Cantagallo',
			'Caldes de Montbui',
			'Cacabelos',
			'Cabezón',
			'Cabañas de Polendos',
			'Busto de Bureba',
			'Bustillo de Chaves',
			'Boimorto',
			'Bohoyo',
			'Blanes',
			'Blancos',
			'Bezares',
			'Benafigos',
			'Belauntza',
			'Baiona',
			'Basardilla',
			'Bañón',
			'Badules',
			'Artesa de Segre',
			'Arcos',
			'Arancón',
			'Alfaro',
			'Alcobendas',
			'Alborge',
			'Aguilón',
			'Aguilar de Segarra',
			'Abanto',
			'Abaltzisketa',
			'Port de Pollença',
			'Coma-ruga',
			'Las Tres Torres',
			'Castrillón',
			'Balanegra',
			'el Parc i la Llacuna del Poblenou',
			'Verdun',
			"la Font d'en Fargues",
			'Barri de les Corts',
			'Argensola',
			'Zafarraya',
			'Villaverde y Pasaconsol',
			'Villavaliente',
			'Villatobas',
			'Villaseca de la Sagra',
			'Villar del Pedroso',
			'Villanueva de los Castillejos',
			'Villaluenga del Rosario',
			'Villafranca de Córdoba',
			'Valencia de las Torres',
			'Valdeverdeja',
			'Sempere',
			'Sant Lluís',
			'Ricote',
			'Quesa',
			'Puebla del Maestre',
			'Priego de Córdoba',
			'Pórtugos',
			'Porto Cristo',
			'Pinos Puente',
			'Picón',
			'Pedralba',
			'Pavías',
			'Palma de Gandía',
			'Otero',
			'Moratalla',
			'Moral de Calatrava',
			'Mirabel',
			'Millares',
			'Menasalbas',
			'Manilva',
			'Manacor',
			'Los Corrales',
			'Linares',
			'La Lantejuela',
			'Juviles',
			'Jérica',
			'Ibros',
			'Huelva',
			'Guardamar del Segura',
			'Fuente Vaqueros',
			'Fuenterrobles',
			'Faura',
			'Fasnia',
			'Famorca',
			'Escúzar',
			'El Viso',
			'Port de Sóller',
			'El Bonillo',
			'Dos Hermanas',
			'Dosbarrios',
			'Crevillente',
			'Cordobilla de Lácara',
			'Confrides',
			'Colmenar',
			'Castellar de Santisteban',
			'Casas de Reina',
			'Carrizal',
			'Carratraca',
			'Carrascalejo',
			'Campo de Criptana',
			'Berrocal',
			'Benatae',
			'Benamargosa',
			'Baza',
			'Andratx',
			'Altura',
			'Almodóvar del Río',
			'Almaraz',
			'Alcocer de Planes',
			'Albalá',
			'Alange',
			'Agulo',
			"Atzeneta d'Albaida",
			'Abarán',
			'Zumaia',
			'Zaldibia',
			'Vilobí del Penedès',
			'Villarejo del Valle',
			'Villárdiga',
			'Villar de Fallaves',
			'Villanueva de la Sierra',
			'Villanueva de Duero',
			'Villanueva de Azoague',
			'Villadepera',
			'Villadecanes',
			'Viandar de la Vera',
			'Velilla del Río Carrión',
			'Valverde de Alcalá',
			'Valdeconcha',
			'Valbona',
			'Turcia',
			'Tricio',
			'Torrecuadrada de Molina',
			'Torrecilla de la Torre',
			'Toro',
			'Torija',
			'Tardáguila',
			'Taboada',
			'Tabanera la Luenga',
			'Soto de la Vega',
			'Sigüés',
			'Sestrica',
			'Segura de Toro',
			'Sant Vicenç dels Horts',
			'Santiurde de Toranzo',
			'Santillana',
			'Santa Cruz del Valle Urbión',
			'Santa Cristina de la Polvorosa',
			'Santa Colomba de las Monjas',
			'San Sebastián de los Reyes',
			'Sant Quirze Safaja',
			'San Pedro Bercianos',
			'San Pascual',
			'Vilassar de Mar',
			'Sant Joan Despí',
			'Sant Feliu de Pallerols',
			'Samir de los Caños',
			'Saldaña de Burgos',
			'Salce',
			'Roda de Eresma',
			'Ríofrío de Aliste',
			'Retascón',
			'Rello',
			'Rágama',
			'Rabé de las Calzadas',
			'Rabanales',
			'Quintana del Castillo',
			'Quijorna',
			'Puértolas',
			'Puente de Vallecas',
			'Pozuelo del Páramo',
			'Pozuel de Ariza',
			'Porto',
			'Polinyà',
			'Perilla de Castro',
			'Paniza',
			'Palacios de la Sierra',
			'Oristà',
			'Ollauri',
			'Oliva de Plasencia',
			'Olvan',
			'Olaberria',
			'Novallas',
			'Nogueruelas',
			'Negreira',
			'Nazar',
			'Navaquesera',
			'Navaescurial',
			'Murieta',
			'Muñana',
			'Mont-ral',
			'Monterrubio de Armuña',
			'Montehermoso',
			'Monteagudo',
			'Monreal de Ariza',
			'Monforte de la Sierra',
			'Mondoñedo',
			'Molledo',
			'Mojados',
			'Melgar de Yuso',
			'Mansilla Mayor',
			'Mambrilla de Castrejón',
			'Lugones',
			'Lominchar',
			'Llers',
			'Lezáun',
			'Lleida',
			'Leioa',
			'Lekunberri',
			'Las Rozas de Madrid',
			'A Guarda',
			'Jarque de la Val',
			'Irurtzun',
			'Illano',
			'Hontoria del Pinar',
			'Hinojosa de San Vicente',
			'Hinojosa de Duero',
			'Granollers',
			"la Granja d'Escarp",
			'Gargüera',
			'Galisancho',
			'Fresnedillas',
			'Fraga',
			'Fontanar',
			'Fitero',
			'Espadañedo',
			'El Burgo de Ebro',
			'Echarri-Aranaz',
			'Derio',
			'Culleredo',
			'Cudillero',
			'Covelo',
			'Coruña del Conde',
			'Cortes de Arenoso',
			'Corrales de Duero',
			'Corgo',
			'Zizur Mayor',
			'Cirueña',
			'Cintruénigo',
			'Cinco Olivas',
			'Cihuri',
			'Checa',
			'Cenicero',
			'Cazalegas',
			'Castronuevo de Esgueva',
			'Castrelo de Miño',
			'Castil de Vela',
			'Castelnou',
			'Castejón',
			'Cáseda',
			'Casares de las Hurdes',
			'Casa de Uceda',
			'Capellades',
			'Cantaracillo',
			'Cantalapiedra',
			'Camarenilla',
			'Calzadilla de Tera',
			'Cabrejas del Campo',
			'Cabezas del Pozo',
			'Cabezas de Alambre',
			'Cabezabellosa',
			'Braojos',
			'Bot',
			'Borobia',
			'Bonansa',
			'Boalo',
			'Berlanga del Bierzo',
			'Benicàssim',
			'Belver de los Montes',
			'Belorado',
			'Becedillas',
			'Bascuñana',
			'Báscones de Ojeda',
			'Barceo',
			'Barbadillo del Mercado',
			'Azkoitia',
			'Autol',
			'Auñón',
			'Armuña',
			'Ariño',
			'Arellano',
			'Aranda de Duero',
			'Ancín',
			'Aldeavieja de Tormes',
			'Aldeanueva de la Sierra',
			'Aldeacipreste',
			'Alcoroches',
			'Alcorisa',
			'Alcocero de Mola',
			'Alcalà de Xivert',
			'Alberite de San Juan',
			'Alàs i Cerc',
			'Alarilla',
			'Alaraz',
			'Alaminos',
			'Ajalvir',
			'Abejar',
			'Abegondo',
			'Areny de Noguera / Arén',
			'Cabanes',
			"Port d'Alcúdia",
			"L'Entregu/El Entrego",
			'Primer Ensanche',
			'Segundo Ensanche',
			'Montbau',
			'Canyelles',
			'Navas',
			'Cap Martinet',
			'Blimea',
			'Villamesías',
			'Villalba del Alcor',
			'Viator',
			'Ventas de Huelma',
			'Valle de Santa Ana',
			'Valdetorres',
			'Utiel',
			'Turrillas',
			'Torremayor',
			'Torrella',
			'Sinarcas',
			'Sanlúcar de Guadiana',
			'San Juan de Aznalfarache',
			'Sencelles',
			'Ruidera',
			'Reíllo',
			'Rafal',
			'Puntagorda',
			'Puebla de Alcocer',
			'Peñarroya-Pueblonuevo',
			'Peñaflor',
			'Pajaroncillo',
			'Ondara',
			'Mota del Cuervo',
			'Montizón',
			'Mengibar',
			'Llombai',
			'La Victoria de Acentejo',
			'Honrubia',
			'Higueruelas',
			'Higuera de la Sierra',
			'Güevéjar',
			'Gerindote',
			'Garaballa',
			'Fuentes',
			'Escurial',
			'El Viso del Alcor',
			'El Grao',
			'Domingo Pérez',
			'Diezma',
			'Cogollos de Guadix',
			'Castilleja de la Cuesta',
			'Casas de Benítez',
			'Camporrobles',
			'Cabeza del Buey',
			'Burguillos de Toledo',
			'Buñol',
			'Brenes',
			'Beniatjar',
			'Benalúa de Guadix',
			'Beires',
			'Beas de Segura',
			'Barlovento',
			'Artana',
			'Arroyo de la Miel',
			'Armuña de Almanzora',
			'Añover de Tajo',
			'Almiserà',
			'Alcolea de Tajo',
			'Alcalá la Real',
			'Alcadozo',
			'Alborea',
			'Ahillones',
			'Adra',
			'Zarzuela',
			'Vistabella',
			'Villar de Plasencia',
			'Villar de Peralonso',
			'Villar del Salz',
			'Villanueva de la Torre',
			'Villamuriel de Cerrato',
			'Villamuriel de Campos',
			'Villahermosa del Campo',
			'Villagatón',
			'Villaciervos',
			'Vilajuïga',
			'Venta de Baños',
			'Vegalatrave',
			'Vega de Ruiponce',
			'Vallesa de la Guareña',
			'Valle de Tabladillo',
			'Valdenebro',
			'Utebo',
			'Tresviso',
			'Tossa de Mar',
			'Torroella de Montgrí',
			'Torres de Alcanadre',
			'Torredembarra',
			'Torre de Don Miguel',
			'Torrecilla de los Ángeles',
			'Titulcia',
			'Tierz',
			'Terzaga',
			'Terroba',
			'Tajueco',
			'Tabera de Abajo',
			'Sotragero',
			'Solanillos del Extremo',
			'Sestao',
			'Selaya',
			'Sant Vicenç de Montalt',
			'San Vicente de Arévalo',
			'Santiago de la Puebla',
			'Santa María del Campo',
			'San Pelayo',
			'Sant Pere de Ribes',
			'Premià de Dalt',
			'San Mamés de Campos',
			'Sanchotello',
			'Sant Andreu de la Barca',
			'Sacecorbo',
			'Rábade',
			'Quintana del Puente',
			'Quer',
			'Puerto Seguro',
			'Puebla de Lillo',
			'Puebla de Albortón',
			'Pinto',
			'Pinilla del Campo',
			'Pinarnegrillo',
			'Peralada',
			'Perales',
			'Peñarandilla',
			'Pastores',
			'Pals',
			'Palacios de Sanabria',
			'Olmillos de Castro',
			'Nonaspe',
			'Nieva',
			'Navahondilla',
			'Navacarros',
			'Narón',
			'Nafría de Ucero',
			'Montmeló',
			'Montesquiu',
			'Monfarracinos',
			'Mombuey',
			'Molina de Aragón',
			'Mesones de Isuela',
			'Mendaro',
			'Medina de Ríoseco',
			'Meaño',
			'Matabuena',
			'Martín del Río',
			'Marazuela',
			'Malpartida de Corneja',
			'Maján',
			'Maello',
			'Maella',
			'Luelmo',
			'Losar de la Vera',
			'Llanera',
			'Lidón',
			'Lezama',
			'Lekeitio',
			"Les Cases d'Alcanar",
			'La Sagrera',
			'Laracha',
			'Lagunilla del Jubera',
			'La Granada',
			'Laxe',
			'La Carrera',
			'Huertahernando',
			'Hérmedes de Cerrato',
			'Guijo de Galisteo',
			'Grisaleña',
			'Gallegos de Hornija',
			'Fuenterroble de Salvatierra',
			'Frumales',
			'Fortanete',
			'Fonfría',
			'Fisterra',
			'Figueres',
			'Fanzara',
			'Establés',
			'Espolla',
			'Entrimo',
			'Enériz',
			'Elgoibar',
			'Doñinos de Ledesma',
			'Descargamaría',
			'Cubilla',
			'Crespos',
			'Corral de Ayllón',
			'Coristanco',
			'Constanzana',
			'Ciutadella',
			'Cervo',
			'Cendejas de la Torre',
			'Cedrillas',
			'Castro',
			'Castellví de Rosanes',
			'Castellolí',
			'Casas de San Galindo',
			'Casas del Castañar',
			'Carrión de los Condes',
			'Cabrillas',
			'Cabezón de Valderaduey',
			'Bugedo',
			'Brea de Aragón',
			'Bóveda del Río Almar',
			'Boltaña',
			'Biscarrués',
			'Bisaurri',
			'Biota',
			'Bijuesca',
			'Baralla',
			'Baños de Valdearados',
			'Bañobárez',
			'Balconchán',
			'Ayerbe',
			'Astigarraga',
			'Arrancacepas',
			'Ardón',
			'Arauzo de Salce',
			'Añorbe',
			'Amurrio',
			'Amposta',
			'Amoroto',
			'Almendra',
			'Alique',
			'Algerri',
			'Alfambra',
			'Aldeonte',
			'Aldearrubia',
			'Aldearrodrigo',
			'Aldealcorvo',
			'Albalate de las Nogueras',
			'Aguilar de Campoo',
			'Ademuz',
			'Islantilla',
			'Olivenza',
			'Sants - Badal',
			'Can Peguera',
			'Zarza de Montánchez',
			'Zarra',
			'Yátova',
			'Villaverde del Río',
			'Villanueva de las Torres',
			'Villamuelas',
			'Villa de Ves',
			'Villacañas',
			'Vallés',
			'Valdelarco',
			'Valdeganga',
			'Ulea',
			'Turís',
			'Torre-Pacheco',
			'Torralba de Calatrava',
			'Tarazona de la Mancha',
			'Santa Eufemia',
			'Santa Brígida',
			'San Javier',
			'Rena',
			'Quéntar',
			'Pozuelo de Calatrava',
			'Polinyà de Xúquer',
			'Piles',
			'Picanya',
			'Peal de Becerro',
			'Palomas',
			'Ocaña',
			'Náquera',
			'Moraleda de Zafayona',
			'Montroy',
			'Montejaque',
			'Montalbo',
			'Miajadas',
			'Mérida',
			'Marines',
			'María',
			'Málaga',
			'Lorquí',
			'Las Mesas',
			'La Oliva',
			'Jayena',
			'Huelma',
			'Guareña',
			'Fuencaliente',
			'Fregenal de la Sierra',
			'Estepa',
			'Espartinas',
			'Enguídanos',
			'El Cuervo',
			'Dúdar',
			'Cuevas del Campo',
			'Cuevas del Almanzora',
			'Cortes de Pallás',
			"Coll d'en Rabassa",
			'Chulilla',
			'Chóvar',
			'Chiclana de la Frontera',
			'Cenes de la Vega',
			'Castilléjar',
			'Cástaras',
			'Casas de Don Pedro',
			'Casariche',
			'Capileira',
			'Cantillana',
			'Caniles',
			'Burjassot',
			'Bugarra',
			'Bubión',
			'Benizalón',
			'Benicolet',
			'Barxeta',
			'Ayódar',
			'Atarfe',
			'Arafo',
			'Alquife',
			'Alozaina',
			'Albal',
			'Alamillo',
			'Agudo',
			'Agaete',
			'Abenójar',
			'Viniegra de Abajo',
			'Villarramiel',
			'Villar de los Navarros',
			'Villar de Corneja',
			'Villardeciervos',
			'Villanueva del Rebollar de la Sierra',
			'Villanueva de Argaño',
			'Villalmanzo',
			'Villalba de la Lampreana',
			'Villaflores',
			'Bera',
			'Ventosa de la Cuesta',
			'Velilla de Jiloca',
			'Veganzones',
			'Valladolid',
			'Valdetorres de Jarama',
			'Valdesamario',
			'Valdepeñas de la Sierra',
			'Valdelosa',
			'Uña',
			'Torres del Río',
			'Torremocha del Pinar',
			'Tornabous',
			'Tordera',
			'San Vicente del Palacio',
			'Sant Vicenç de Castellet',
			'Sant Just Desvern',
			'Santiago del Collado',
			'Santiago de Compostela',
			'Santa María de los Caballeros',
			'Santa Coloma de Cervelló',
			'Santa Clara de Avedillo',
			'San Salvador',
			'San Ildefonso',
			'Salillas',
			'Roturas',
			'Rodezno',
			'Robledo de Chavela',
			'Riudecanyes',
			'Renieblas',
			'Rábano de Aliste',
			'Prats de Lluçanès',
			'Pozondón',
			'Pozo de Guadalajara',
			'Portell de Morella',
			'Población de Arroyo',
			'Pitillas',
			'Pesoz',
			'Peñaranda de Duero',
			'Pedrosillo de Alba',
			'Paredes de Nava',
			'Pardos',
			'Padrones de Bureba',
			'Ortigosa de Pestaño',
			'Ordes',
			'Oitz',
			'Numancia de la Sagra',
			'Nombela',
			'Nigrán',
			'Navatalgordo',
			'Navarcles',
			'Navadijos',
			'Narros de Matalayegua',
			'Narrillos del Álamo',
			'Muelas de los Caballeros',
			'Moià',
			'Moreruela de los Infanzones',
			'Morales de Valverde',
			'Moradillo de Roa',
			'Montesclaros',
			'Monterde',
			'Monistrol de Montserrat',
			'Monasterio',
			'Molinos',
			'Moeche',
			'Milagros',
			'Meis',
			'Mazuecos',
			'Matamala de Almazán',
			'Matallana de Torío',
			'María de Huerva',
			'Manzanares de Rioja',
			'Luyego',
			'Losacino',
			'Loranca de Tajuña',
			'Lodosa',
			'Llimiana',
			'Lles de Cerdanya',
			'Lizoáin',
			'Lezo',
			'La Roca del Vallès',
			'Sant Pere, Santa Caterina i La Ribera',
			'Langa del Castillo',
			'Laluenga',
			'Laguardia',
			'Jadraque',
			'Illa de Arousa',
			'Irura',
			'Huerto',
			'Huecas',
			'Guissona',
			'la Granadella',
			'Ginestar',
			'Gavilanes',
			'Fuentes de Magaña',
			'Fuentecantos',
			'Fresno El Viejo',
			'Foz-Calanda',
			'Fontcoberta',
			'Folgoso de la Ribera',
			'Figueruela de Arriba',
			'Ejulve',
			'Doñinos de Salamanca',
			'Cuenca',
			'Creixell',
			'Corçà',
			'Constantí',
			'Cisla',
			'Ciruelas',
			'Cidamón',
			'Zegama',
			'Cebanico',
			'Castromonte',
			'Casillas de Flores',
			'Casas Bajas',
			'Casarrubuelos',
			'Carracedelo',
			'Carbonero el Mayor',
			'Canales de la Sierra',
			'Canalejas de Peñafiel',
			'Cabrillanes',
			'Burgos',
			'Burela de Cabo',
			'Borrassà',
			'Bezas',
			'Berriozar',
			'Bergasa',
			'Berango',
			'Benlloch',
			'Benafarces',
			'Barruecopardo',
			'Baños de Montemayor',
			'Bádenas',
			'Arredondo',
			'Armallones',
			'Arguedas',
			'Argelita',
			'Arcos de Jalón',
			'Arama',
			'Alobras',
			'Almenar',
			'Almaraz de Duero',
			'Aldeanueva de Santa Cruz',
			'Aldeanueva del Camino',
			'Las Tablas',
			'Costa Calma',
			'el Coll',
			'Bakio',
			'Iturrama',
			'Muxika',
			'Sant Genís dels Agudells',
			'Can Baró',
			'Torre Baró',
			'Asin',
			'Villavieja',
			'Villarta de San Juan',
			'Villarrasa',
			'Villanueva de la Fuente',
			'Villanueva de Córdoba',
			'Villagordo del Júcar',
			'Vilafranca de Bonany',
			'Villa de Don Fadrique',
			'Velada',
			'Vara de Rey',
			'Valdés',
			'Valdelacasa de Tajo',
			'Torrejoncillo del Rey',
			'Torás',
			'Talavera La Real',
			'Soportújar',
			'Socovos',
			'Sierro',
			'Sevilla',
			'Serra',
			'Santo Tomé',
			'Santa Elena',
			'San Pedro de Mérida',
			'Salar',
			'Ríogordo',
			'Pulgar',
			'La Pobla Llarga',
			'Puebla de Don Rodrigo',
			'Marjaliza',
			'Manises',
			'Loriguilla',
			'Llera',
			'Letur',
			'La Palma del Condado',
			'La Guardia',
			'Jorquera',
			'Hornachuelos',
			'Gilet',
			'Gérgal',
			'Fuente Obejuna',
			'Fuente el Fresno',
			'Fresnedoso de Ibor',
			'Foios',
			'Fornalutx',
			'Férez',
			'Estellencs',
			'Esparragalejo',
			'El Ejido',
			'Domingo Pérez',
			'Coripe',
			'Conil de la Frontera',
			'Catarroja',
			'Catadau',
			'Carrascosa de Haro',
			'Canjáyar',
			'Cañada de Calatrava',
			'Cañada',
			'Campillo de Llerena',
			'Callosa de Segura',
			'Cala',
			'Cadiz',
			'Benaoján',
			'Belmonte',
			'Baterno',
			'Armilla',
			'Almogía',
			'Almedinilla',
			'Almargen',
			'Aldea del Cano',
			'Albuixech',
			'Alanís',
			'Aguadulce',
			'Abrucena',
			'Zarzuela del Monte',
			'Zarratón',
			'Zapardiel de la Cañada',
			'Yebra de Basa',
			'Biure',
			'Vindel',
			'Villovieco',
			'Villatuerta',
			'Villarmayor',
			'Villaquirán de la Puebla',
			'Villanuño de Valdavia',
			'Vilanova de Arousa',
			'Villanúa',
			'Villaferrueña',
			'Villafáfila',
			'Villaco',
			'Vilanova de Bellpuig',
			'Vilamalla',
			'Vilabella',
			'Verín',
			'Vega de Tirados',
			'Valverde de Valdelacasa',
			'Valls',
			'Vallanca',
			'Valderrobres',
			'Valdeprado',
			'Valdepiélago',
			'Valdehijaderos',
			'Usurbil',
			'Torrejón de la Calzada',
			'Torrecuadradilla',
			'Tavertet',
			'Soto de Cerrato',
			'Sos del Rey Católico',
			'Soliedra',
			'Sarrià de Ter',
			'Santibáñez de Tera',
			'Santed',
			'Santa María del Monte de Cea',
			'Santa María de la Isla',
			'Santa María de la Alameda',
			'Santa Eulalia',
			'San Miguel de Valero',
			'San Martín de Valvení',
			'San Martín de Valderaduey',
			'San Martín de Trevejo',
			'San Juan de la Nava',
			'San Esteban de los Patos',
			'San Esteban de Gormaz',
			'San Cristóbal de Cuéllar',
			'San Cristóbal de Boedo',
			'Salvatierra de Miño',
			'Salinas de Pisuerga',
			'Saldías',
			'Salcedillo',
			'Ruente',
			'Rollán',
			'Roa',
			'Rivilla de Barajas',
			'Rillo de Gallo',
			'Riego de la Vega',
			'Riaza',
			'Reznos',
			'Pueyo de Santa Cruz',
			'Pravia',
			'Pinilla de los Moros',
			'Pedroso',
			'Pamplona',
			'Pálmaces de Jadraque',
			'Osornillo',
			'Olmos de Esgueva',
			'Ojos-Albos',
			'Nigüella',
			'Nava de Roa',
			'Navacerrada',
			'Murillo de Río Leza',
			'Mugardos',
			'Morales del Vino',
			'Montederramo',
			'Montarrón',
			'Mollerussa',
			'Miraflores de la Sierra',
			'Mejorada',
			'Mataró',
			'Mataporquera',
			'El Masnou',
			'Marcilla',
			'Manjarrés',
			'Mañeru',
			'Maleján',
			'Malaguilla',
			'Madrid',
			'Leganiel',
			'Ledrada',
			'Laguna de Duero',
			'Laguna Dalga',
			'Itero de la Vega',
			'Illescas',
			'Ibeas de Juarros',
			'Husillos',
			'Humanes de Madrid',
			'Huarte-Uharte',
			'Hornillos de Cameros',
			'Herreros de Suso',
			'Herramélluri',
			'Gamones',
			'Fuentidueña de Tajo',
			'Fuentespalda',
			'Fuentes de Carbajal',
			'Fonfría',
			'Fogars de Montclús',
			'Fayón',
			'Espinosa de Henares',
			'Espadilla',
			'Escopete',
			'Escalona',
			'Erandio',
			'Encinas de Abajo',
			'El Prat de Llobregat',
			'Figaró',
			'Etxalar',
			'Driebes',
			'Deza',
			'Darnius',
			'Daganzo de Arriba',
			'Cubillos del Sil',
			'Covarrubias',
			'Cosuenda',
			'Comillas',
			'Coca',
			'Cirat',
			'Chamartín',
			'Celada del Camino',
			'Castilnuevo',
			'Castillejo de Martín Viejo',
			'Castandiello',
			'Canicosa de la Sierra',
			'Canet de Mar',
			'Campazas',
			'Calzada del Coto',
			'Cabezón de la Sierra',
			'Cabezas del Villar',
			'Brihuega',
			'Bretocino',
			'Bordils',
			'Bocos de Duero',
			'Bimenes',
			'Bergondo',
			'Bergasillas Bajera',
			'Berdejo',
			'Benifallet',
			'Benasque',
			'Barjas',
			'Barásoain',
			'Barajas de Madrid',
			'Argecilla',
			'Arenzana de Abajo',
			'Arandilla',
			'Antillón',
			'Altzaga',
			'Alpicat',
			'Almonacid de la Cuba',
			'Allepuz',
			'Alcanadre',
			'Albons',
			'Ahigal',
			'Acebo',
			'Abizanda',
			'Abiego',
			'Puerto del Carmen',
			'Sarrià-Sant Gervasi',
			'Oliver-Valdefierro',
			'Villa de Vallecas',
			'Sant Antoni',
			'el Guinardó',
			'Zubia',
			'Zahara de los Atunes',
			'Zahara',
			'Zagra',
			'Villaviciosa de Córdoba',
			'Villardompardo',
			'Villanueva de Mesía',
			'Velefique',
			'Válor',
			'Valleseco',
			'Valdecañas de Tajo',
			'Ubrique',
			'Torrubia del Campo',
			'Torres',
			'Toledo',
			'Siles',
			'Santa Olalla del Cala',
			'Robledillo de Trujillo',
			'Punta de Mujeres',
			'Puebla de Don Fadrique',
			'Plasenzuela',
			'Pinoso',
			'Pilar de la Horadada',
			'Peraleda de San Román',
			'Pegalajar',
			'Parauta',
			'Olocau',
			'Olmedilla de Alarcón',
			'Novelda',
			'Navas de Jorquera',
			'Navalvillar de Pela',
			'Navalmoral de la Mata',
			'Motilleja',
			'Martos',
			'Malpartida de Plasencia',
			'Malcocinado',
			'Puerto Lumbreras',
			'Lucena del Puerto',
			'Los Llanos de Aridane',
			'Lopera',
			'La Puebla de Cazalla',
			'Humilladero',
			'Huétor Santillán',
			'Huércal de Almería',
			'Guarromán',
			'Gójar',
			'Genalguacil',
			'Fuente-Tójar',
			'Fuentealbilla',
			'Fuensanta de Martos',
			'Escorca',
			'El Casar',
			'Cortelazor',
			'Chilluévar',
			'Chella',
			'Cazorla',
			'Castro del Río',
			'Carrizosa',
			'Carboneras',
			'Caravaca',
			'Campillos-Paravientos',
			'Benimodo',
			'Beniflá',
			'Benavites',
			'Benahadux',
			'Bailén',
			'Azuébar',
			'Ayora',
			'Atamaría',
			'Arriate',
			'Arguisuelas',
			'Ardales',
			'Arboleas',
			'Almoradí',
			'Alicante',
			'Aliaguilla',
			'Alhabia',
			'Albuñol',
			'Albalat de la Ribera',
			'Alájar',
			'Agullent',
			'Zuñeda',
			'Villaverde-Mogina',
			'Villanueva del Rebollar',
			'Villamandos',
			'Vilalba dels Arcs',
			'Valverde de la Vera',
			'Valdevacas de Montejo',
			'Valbuena de Pisuerga',
			'Urueña',
			'Tremedal de Tormes',
			'Tramacastilla',
			'Tortuera',
			'Torresandino',
			'Torrelara',
			'Torrejón del Rey',
			'Torre de Peñafiel',
			'Torrecilla del Pinar',
			'Tordesilos',
			'Tejeda de Tiétar',
			'Tejada',
			'Tardajos',
			'Suances',
			'Sotobañado y Priorato',
			'Solsona',
			'Sigüenza',
			'Sienes',
			'Santoyo',
			'Santa Coloma de Gramenet',
			'Santa Cecilia del Alcor',
			'Sant Pol de Mar',
			'Sant Joan de les Abadesses',
			'San Juan de la Encinilla',
			'Sangarcía',
			'Sant Feliu de Llobregat',
			'San Cristóbal de la Vega',
			'San Cristóbal de la Cuesta',
			'Sancedo',
			'San Andrés del Rabanedo',
			'Salt',
			'Sádaba',
			'Rozas de Puerto Real',
			'Royuela',
			'Rois',
			'Riumors',
			'Riudoms',
			'Riocavado de la Sierra',
			'Ramiro',
			'Puigcerdà',
			'Prádena del Rincón',
			'Pozo de Almoguera',
			'Poveda de las Cintas',
			'Portillo de Toledo',
			'Portbou',
			'Pobladura de Valderaduey',
			'Piqueras',
			'Pinilla de Molina',
			'Pías',
			'Peñausende',
			'Padrón',
			'Olocau del Rey',
			'Olmeda de la Cuesta',
			'Nestares',
			'Navas del Rey',
			'Navaridas',
			'Navardún',
			'Narros',
			'Muñosancho',
			'Mucientes',
			'Morcillo',
			'Montejo de Tiermes',
			'Monroyo',
			'Monreal del Campo',
			'Modúbar de la Emparedada',
			'Mijares',
			'Micereces de Tera',
			'Mejorada del Campo',
			'Megeces',
			'Medinilla',
			'Meco',
			'Mayalde',
			'Mamolar',
			'Maire de Castroponce',
			'Macotera',
			'Llano de Olmedo',
			'la Cellera de Ter',
			'Larraul',
			'Lanzuela',
			'la Jonquera',
			"l'Escala",
			"L'Ampolla",
			'Ilche',
			'Hoyo de Manzanares',
			'Herrín de Campos',
			'Gusendos de los Oteros',
			'Guimerà',
			'Guijo de Santa Bárbara',
			'Granucillo',
			'Gascueña',
			'Fuentenovilla',
			'Fuentelapeña',
			'Fuente el Sol',
			'Friol',
			'El Molar',
			'Éller',
			'Eljas',
			'Desojo',
			'Curtis',
			'Cuevas Labradas',
			'Cubel',
			'Cornellà de Llobregat',
			'Corbera de Llobregat',
			'Cogollos',
			'Cerezo',
			'Zerain',
			'Castromocho',
			'Castrocalbón',
			'Castrobol',
			'Castrillo de la Valduerna',
			'Castrillo de Duero',
			'Castrejón de la Peña',
			'Castillo-Albaráñez',
			'Castigaleu',
			'Castell de Cabres',
			'Carrascal del Río',
			'Cardiel de los Montes',
			'Capdesaso',
			'Canovelles',
			'Cañas',
			'Campisábalos',
			'Campdevànol',
			'Calvarrasa de Abajo',
			'Cabreros del Monte',
			'Cabrero',
			'Cabanillas',
			'Cabañas de la Sagra',
			'Bujalaro',
			'Buendía',
			'Buenavista de Valdavia',
			'Boquiñeni',
			'Bernardos',
			'Beratón',
			'Belchite',
			'Béjar',
			'Becerreá',
			"Sant Esteve d'en Bas",
			'Bargota',
			'Bailo',
			'Aiguafreda',
			'Aia',
			'Arguis',
			'Arganda',
			'Arcones',
			'Arándiga',
			'Ameyugo',
			'Almonacid de Zorita',
			'Almenar de Soria',
			'Alcalá del Obispo',
			'Ainzón',
			'Ágreda',
			'Adrada de Pirón',
			'Blancafort',
			'Benalup-Casas Viejas',
			'Barri Gòtic',
			'Navas de San Juan',
			'Taberno',
			'Porta',
			'Villaminaya',
			'Villagonzalo',
			'Villafranca de los Caballeros',
			'Vega de San Mateo',
			'Vallehermosa',
			'Valldemossa',
			'Valdecaballeros',
			'Utrera',
			'Tuineje',
			'Torrequemada',
			'Torrecilla de la Jara',
			'Sax',
			'Santisteban del Puerto',
			'Santiago de Calatrava',
			//'Santa Ana',
			'San Juan de Énova',
			'San Carlos del Valle',
			'Salobreña',
			'Puebla de Obando',
			'Portaje',
			'Porcuna',
			'Piqueras del Castillo',
			'Pedroso de Acim',
			'Pedro Abad',
			'Paterna',
			'Paradas',
			'Osuna',
			'Mora',
			'Mogán',
			'Marchal',
			'Mancor de la Vall',
			'Majadas',
			'Lugros',
			'Llutxent',
			'Los Navalucillos',
			'Lobón',
			'Linares de la Sierra',
			'Lentegí',
			'La Yesa',
			'la Nucia',
			'La Albuera',
			'Jimera de Líbar',
			'Javea',
			'Hinojosa del Valle',
			'Herreruela de Oropesa',
			'Güimar',
			'Gualchos',
			'Garachico',
			'Galera',
			'Espejo',
			'Escacena del Campo',
			'El Puerto de Santa María',
			'Castellnovo',
			'Casas de Miravete',
			'Cañete de las Torres',
			'Bormujos',
			'Blanca',
			'Benigànim',
			'Benejúzar',
			'Belmontejo',
			'Bellreguard',
			'Banyalbufar',
			'Arquillos',
			'Arenas',
			'Arcos de las Salinas',
			'Altarejos',
			'Almodóvar del Campo',
			'Almagro',
			'Alicún de Ortega',
			'Alcalá de Guadaira',
			'Punta Umbría',
			'Zugarramurdi',
			'Zapardiel de la Ribera',
			'Zamayón',
			'Vinebre',
			'Villazala',
			'Villaumbrales',
			'Villar del Infantado',
			'Villaprovedo',
			'Villanueva de Viver',
			'Villanueva de la Cañada',
			'Villanueva de Jiloca',
			'Villanueva de Gómez',
			'Villamanta',
			'Villafranca de Ebro',
			'Villabaruz de Campos',
			'Viana',
			'Velamazán',
			'Valoria la Buena',
			'Valleruela de Sepúlveda',
			'Valjunquera',
			'Valdestillas',
			'Valdelacasa',
			'Urones de Castroponce',
			'Torrubia de Soria',
			'Torre del Compte',
			'Torrecilla de la Orden',
			'Tornos',
			'Tordehumos',
			'Tielmes',
			'Tarragona',
			'Sotodosos',
			'Solarana',
			'Sobradelo',
			'Segura de los Baños',
			'Sástago',
			'Cerdanyola del Vallès',
			'Doneztebe-Santesteban',
			'Santa Eulalia',
			'Santa Cruz del Valle',
			'Santa Cruz de Bezana',
			'Santa Cecilia',
			"Sant Llorenç d'Hortons",
			'San Lorenzo de El Escorial',
			'San Fernando de Henares',
			'San Cristóbal de Entreviñas',
			'Sant Andreu de Llavaneres',
			'Sada',
			'Saceda-Trasierra',
			'Rueda',
			'Rubí de Bracamonte',
			'Ríotorto',
			'Revilla de Collazos',
			'Rabós',
			'Quintanaélez',
			'Querol',
			'Pujalt',
			'Portomarín',
			'Puente Nuevo',
			'Ponteareas',
			'Puebla de Azaba',
			'Presencio',
			'Pradoluengo',
			'Porqueres',
			'Pomar de Valdivia',
			'Pola de Somiedo',
			'el Poal',
			'Pitiegua',
			'Piera',
			'Piedrahita de Castro',
			'Pelahustán',
			'Pedrosa del Príncipe',
			'Patones',
			'Pantoja',
			'Palomero',
			'els Pallaresos',
			'Outeiro',
			'Oria',
			'Urduña / Orduña',
			'Obón',
			'Nuez de Ebro',
			'Noja',
			'Nogueras',
			'Neila de San Miguel',
			'Navàs',
			'Navamorcuende',
			'Nava del Rey',
			'Mugia',
			'Muel',
			'Moyuela',
			'Moreda Araba / Moreda de Álava',
			'Moraleja de las Panaderas',
			'Montejo de Arévalo',
			'Monfero',
			'Metauten',
			'Martín Miguel',
			'Maqueda',
			'Maceda',
			'Lumbreras',
			'Lousame',
			'Lloret de Mar',
			'Liceras',
			'Lerín',
			'Ledanca',
			'Larraga',
			'Lapoblación',
			'la Morera de Montsant',
			'Lakuntza',
			'Xove',
			'Jarilla',
			'Jambrina',
			'Izagre',
			'O Incio',
			'Illana',
			'Huelves',
			'Hontanas',
			'Herrera de Valdecañas',
			'Hacinas',
			'Getxo',
			'Guaza de Campos',
			'Gotor',
			'Gomecello',
			'Golmayo',
			'Garganta la Olla',
			'Garcibuey',
			'Fuentes de Béjar',
			'Fuentesaúco de Fuentidueña',
			'Fresno de Cantespino',
			'Fresneña',
			'Espeja',
			'Escobar de Campos',
			'Elduain',
			'Durón',
			'Cornellà del Terri',
			'Coomonte',
			'Collbató',
			'Coles',
			'Ciudad Lineal',
			'Cenlle',
			'Castroverde',
			'Castrillo de Onielo',
			'Castellcir',
			'Castellbisbal',
			'Cardeñosa',
			'Carcedo de Burgos',
			'Cantalpino',
			'Cañizar',
			'Canfranc',
			'Cañada Vellida',
			'Camproviín',
			'Campaspero',
			'Caldas de Reis',
			'Calaf',
			'Bujaraloz',
			'les Borges del Camp',
			'Boadilla del Monte',
			'Bisimbre',
			'Bielsa',
			'Beuda',
			'Berninches',
			'Beriáin',
			'Bercero',
			'Benicarló',
			'Basconcillos del Tozo',
			'Barro',
			'Bárcena de Pie de Concha',
			'Arteixo',
			'Artajona',
			'Arenillas',
			'Anoeta',
			'Almuniente',
			'Almudévar',
			'Almorox',
			'Almendral de la Cañada',
			'Almarza',
			'Almadrones',
			'Allariz',
			'Alcubilla de Avellaneda',
			'Alcañiz',
			'Abrera',
			'Abánades',
			'Nou Barris',
			'Peguera',
			'Costa Teguise',
			'el Pont de Bar',
			'Vecindario',
			"l'Alfàs del Pi",
			'Las Gabias',
			'San José del Valle',
			'Tres Cantos',
			"la Vall d'Hebron",
			'Zalamea de la Serena',
			'Villarrubio',
			'Villanueva del Fresno',
			'Villamartín',
			'Tuéjar',
			'Totanés',
			'Torreperogil',
			'Torrejoncillo',
			'Talayuelas',
			'Senyera',
			'Segura de León',
			'Sant Francesc de Formentera',
			'Sagra',
			'Rótova',
			'Rosalejo',
			'Real de Montroi',
			'Quesada',
			'Pruna',
			'Pozo-Cañada',
			'Poblete',
			'Picassent',
			'Pétrola',
			'Palmera',
			'Otívar',
			'Ossa de Montiel',
			'Orcera',
			'Noez',
			'Montillana',
			'Mocejón',
			'Mira',
			'Malpartida de Cáceres',
			'Llocnou de Sant Jeroni',
			'Los Montesinos',
			'Llanera de Ranes',
			'Landete',
			'La Carlota',
			'Instinción',
			'Herrera del Duque',
			'Guia',
			'Güéjar-Sierra',
			'Granadilla de Abona',
			'Gilena',
			'Geldo',
			'Gádor',
			'Fuente-Álamo',
			'Fines',
			'Estivella',
			'Cuevas Bajas',
			'Consell',
			'Chinchilla de Monte Aragón',
			'Chimeneas',
			'Chelva',
			'Catral',
			'Casabermeja',
			'Canals',
			'Cabezarados',
			'Bufali',
			'Benimantell',
			'Benetússer',
			'Arroyomolinos de León',
			'Alpandeire',
			'Algemesí',
			'Algarinejo',
			'Alfarp',
			'Alfacar',
			'Albalat dels Sorells',
			'Acedera',
			'Zumarraga',
			'Zorita de los Canes',
			'Zarzuela del Pinar',
			'Yélamos de Arriba',
			'Vitigudino',
			'Villegas',
			'Vilaión',
			'Villaseco de los Gamitos',
			'Villarmentero de Campos',
			'Villares de Jadraque',
			'Villar de Domingo García',
			'Villanueva de Teba',
			'Villanueva del Conde',
			'Villalba de Duero',
			'Villalazán',
			'Villafruela',
			'Villafranca del Cid',
			'Villa del Campo',
			'Vila-seca',
			'Vilaseca',
			'Vilaplana',
			'Vierlas',
			'Vegas de Matute',
			'Vega de Valdetronco',
			'Vega de Valcarce',
			'Valmadrid',
			'Vallfogona de Balaguer',
			'Valdilecha',
			'Valdepolo',
			'Valdemadera',
			'Valdelcubo',
			'Valdecuenca',
			'Tui',
			'Torralbilla',
			'Torquemada',
			'Tolosa',
			'Tobed',
			'Tendilla',
			'Tábara',
			'Serranillos',
			'Sequeros',
			'San Vitero',
			'Santibáñez de Valcorba',
			'Sant Aniol de Finestres',
			"Santa Cristina d'Aro",
			'Santa Comba',
			'San Miguel de Serrezuela',
			'Sant Martí Vell',
			'Sant Joan de Mollet',
			'San Esteban de la Sierra',
			'Samboal',
			'Ródenas',
			'Rodeiro',
			'Robladillo',
			'Rianxo',
			'Reus',
			'Renedo de la Vega',
			'Quintela de Leirado',
			'Quintanilla de Onsoña',
			'Puig-reig',
			'Puente del Congosto',
			'Premià de Mar',
			'Pradilla de Ebro',
			'Pozuelo de Zarzón',
			'Población de Campos',
			'Piñor',
			'Pinillos',
			'Pinilla del Valle',
			'Pineda de Mar',
			'Peñalba de Ávila',
			'Peleagonzalo',
			'Pastoriza',
			'Parrillas',
			'Palacios de Goda',
			'Oroso',
			'Nueva Villa de las Torres',
			'Nebreda',
			'Navalmanzano',
			'Navajún',
			'Nava de Francia',
			'Muiños',
			'Muga de Sayago',
			'Mota del Marqués',
			'Móstoles',
			'Moros',
			'Moratinos',
			'Monforte de Lemos',
			'Mohernando',
			'Mediana de Voltoya',
			'Maside',
			'Mancera de Abajo',
			'Lumbier',
			'Lituénigo',
			'Lascuarre',
			'A Rúa',
			'Lalueza',
			'Isòvol',
			'Iruelos',
			'Hurones',
			'Hoyocasero',
			'Horcajo de Montemayor',
			'Hita',
			'Guijuelo',
			'Sant Salvador de Guardiola',
			'Guadramiro',
			'Granera',
			'Grajal de Campos',
			'Galve de Sorbe',
			'Galve',
			'Gajates',
			'Fuentelisendo',
			'Fuentelencina',
			'Fuente Encalada',
			'Fuensalida',
			'Frómista',
			'Fresno del Río',
			'Fresneda de la Sierra Tirón',
			'Frechilla',
			'Frades de la Sierra',
			'Fontellas',
			'Fabero',
			'Espadaña',
			'Cucalón',
			'Costur',
			'Corella',
			'Congosto de Valdavia',
			'Colmenar Viejo',
			'Collado',
			'Cirauqui',
			'Cilleros',
			'Chantada',
			'Cervatos de la Cueza',
			'Centenera',
			'Cascante del Río',
			'Carazo',
			'Caracena',
			'Cangas de Onís',
			'Canales',
			'Campo de San Pedro',
			'Landa',
			'Calomarde',
			'Cabrera de Mar',
			'Cabezón de la Sal',
			'Bordón',
			'Bobadilla',
			'Blacos',
			'Betanzos',
			'Beasain',
			'Barruelo de Santullán',
			'Barcones',
			'Barañáin',
			'Banyoles',
			'Artieda',
			'Arrúbal',
			'Arenas de Iguña',
			'Aranzueque',
			'Arandilla del Arroyo',
			'Ampuero',
			'Alonsotegi',
			'Almanza',
			'Alfamén',
			'Aldeanueva del Codonal',
			'Alcoletge',
			'Alcalá de la Selva',
			'Alba',
			'Aceituna',
			'Abadín',
			'Ababuj',
			'Yerri',
			'Valdelacalzada',
			'el Torricó / Altorricon',
			'La Bordeta',
			'Llefià',
			'Vega del Codorno',
			'Santiago de Alcántara',
			'Ubide',
			'la Trinitat Vella',
			'Vilarnaz',
			'Zarza de Alange',
			'Villar del Pozo',
			'Villanueva del Ariscal',
			'Villamiel de Toledo',
			'Vélez-Málaga',
			'Tous',
			'Torremolinos',
			'Torralba de Oropesa',
			'Tormos',
			'Sumacàrcer',
			'Sot de Chera',
			'Sóller',
			'Sollana',
			'Sineu',
			'Selva',
			'Sauzal',
			'Santa Lucía',
			'San José',
			'Saceruela',
			'Rus',
			'Quintanar del Rey',
			'Pulianas',
			'Puerto Real',
			'Puertollano',
			'La Pobla de Vallbona',
			'Puebla del Salvador',
			'Puebla del Prior',
			'Piedrabuena',
			'Pedro Muñoz',
			'Palomares del Río',
			'Palma del Río',
			'Pajarón',
			'Otura',
			'Orcheta',
			'Nueva-Carteya',
			'Navajas',
			'Nambroca',
			'Montemolín',
			'Molinicos',
			'Mislata',
			'Mazarrón',
			'Mascaraque',
			'Manchita',
			'Malpartida de la Serena',
			'Lucena',
			'Lanteira',
			'La Luisiana',
			'La Guancha',
			'Láchar',
			'Jerez de la Frontera',
			'Xeresa',
			'Huécija',
			'Guadalcázar',
			'Garachico',
			'Fuenlabrada de los Montes',
			'Facheca',
			'Esparragosa de Lares',
			'El Carpio de Tajo',
			'Cózar',
			'Cóbdar',
			'Cartagena',
			'Carmona',
			'Carlet',
			'Cardenete',
			'Campofrío',
			'Calp',
			'Caleruela',
			'Cabra del Santo Cristo',
			'Cabra',
			'Cabeza la Vaca',
			'Burguillos',
			'Bolulla',
			'Bolbaite',
			'Bodonal de la Sierra',
			'Bérchules',
			'Benimeli',
			'Beas de Granada',
			'Barrax',
			'Barchín del Hoyo',
			'Baeza',
			'Atalaya del Cañavate',
			'Arroyo del Ojanco',
			'Argamasilla de Calatrava',
			'Anchuras',
			'Almegíjar',
			'Alfarnatejo',
			'Aledo',
			'Alcuéscar',
			'Alconera',
			'Alcolea del Río',
			'Adeje',
			'Zorita de la Frontera',
			'Zafrilla',
			'Yésero',
			'Visiedo',
			'Viñegra de Moraña',
			'Vimbodí',
			'Villoruebo',
			'Villazopeque',
			'Villaverde',
			'Villastar',
			'Villarrín de Campos',
			'Villares de Órbigo',
			'Villanueva de Gormaz',
			'Villamartín de Don Sancho',
			'Villalcampo',
			'Villalbarba',
			'Villalba de Rioja',
			'Villalar de los Comuneros',
			'Villahoz',
			'Villagalijo',
			"Vilanova d'Escornalbou",
			'Vilanant',
			'Vega de Santa María',
			'Valverde del Majano',
			'Valfermoso de Tajuña',
			'Valdepiélagos',
			'Valdeobispo',
			'Valdemierque',
			'Rivas-Vaciamadrid',
			'Ullastret',
			'Ulldecona',
			'Torre en Cameros',
			'Torelló',
			'Tordesillas',
			'Tivenys',
			'Tajahuerce',
			'Sotillo del Rincón',
			'Sisamón',
			'Sanzoles',
			'Santibáñez de la Sierra',
			'Santa Olalla',
			'Santander',
			'Santa María de Cayón',
			'Santa Magdalena de Pulpis',
			'Santa Eulalia de Gállego',
			'Santa Cruz de la Salceda',
			'Sant Miquel de Fluvià',
			'San Martín de la Vega',
			'Sant Adrià de Besòs',
			'Sajazarra',
			'Sagàs',
			'Sacramenia',
			'Errigoiti',
			'Revillarruz',
			'Reinosa',
			'Errezil',
			'Rasueros',
			'Prado',
			'Pozuelo de Alarcón',
			'Pola de Laviana',
			'Pola de Allande',
			'Peralta',
			'Peralejos de Abajo',
			'Payo de Ojeda',
			'O Páramo',
			'Pantón',
			'Palomar de Arroyos',
			'Palafolls',
			'Padrenda',
			'Olvés',
			'Olot',
			'Navianos de Valverde',
			'Navasfrías',
			'Navalperal de Pinares',
			'Navalagamella',
			'Navalacruz',
			'Navafría',
			'Narros del Puerto',
			'Narros del Castillo',
			'Morales de Campos',
			'Moraleja de Enmedio',
			'Mollet del Vallès',
			'Miralcamp',
			'Mianos',
			'Mesegar de Corneja',
			'Marañón',
			'Manresa',
			'Luceni',
			'Luanco',
			'Leza',
			'Lerga',
			'Lastras del Pozo',
			'Laspuña',
			'Las Matas',
			'La Plaza',
			'Láncara',
			'San Juan de la Arena',
			'Juarros de Voltoya',
			'Jarandilla de la Vera',
			'Humada',
			'Hortaleza',
			'Hontoria de la Cantera',
			'Grijalba',
			'Graus',
			'Galar',
			'Fuente el Olmo de Fuentidueña',
			'Frades',
			'Fontihoyuelo',
			'Faramontanos de Tábara',
			'Enciso',
			'Encinillas',
			'Elgorriaga',
			'Dueñas',
			'Cueva de Ágreda',
			'Cubillo',
			'Cubillas de Rueda',
			'Cubells',
			'Cortegada',
			'Corrales',
			'Colmenar de Oreja',
			'Cobos de Cerrato',
			'Zestoa',
			'Cervera del Maestre',
			'Cereceda de la Sierra',
			'Cenicientos',
			"Castell-Platja d'Aro",
			'Casas de Don Gómez',
			'Carrascosa de Abajo',
			'Carral',
			'Cárcar',
			'Carbajosa de la Sagrada',
			'Camporredondo',
			'Campins',
			'Campillo de Azaba',
			'Campillo de Aragón',
			'Camarillas',
			'Camarasa',
			'Bustillo del Oro',
			'Borjabad',
			'Biescas',
			'Bescanó',
			'Berzosa del Lozoya',
			'Bercimuel',
			'Bellver de Cerdanya',
			'Begonte',
			'Bea',
			'Batea',
			'Barillas',
			'Bañuelos de Bureba',
			'Baltar',
			'Bagà',
			'Azlor',
			'Aveinte',
			'Autilla del Pino',
			'Ateca',
			'Astudillo',
			'Arraya de Oca',
			'Arrabalde',
			'Argañín',
			'Alcalá de Gurrea',
			'Albalatillo',
			'Ajamil',
			'Agoncillo',
			'Abella de la Conca',
			'Abades',
			'Sopeira',
			'Puerto Rico',
			'Ceuta',
			'Chamberí',
			'Retiro',
			'Los Realejos',
			'Pasaia',
			'la Marina del Prat Vermell',
			"l'Antiga Esquerra de l'Eixample",
			'la Salut',
			'la Teixonera',
			'Rayaces',
			'Puente la Reina de Jaca',
			'Villares del Saz',
			'Villarejo de Montalbán',
			'Villar del Humo',
			'Villanueva de San Juan',
			'Vilaflor',
			'Vélez de Benaudalla',
			'Trujillanos',
			'Torrevieja',
			'Torrent',
			'Torrecampo',
			'Tijarafe',
			'Tazacorte',
			'Soneja',
			'Es Migjorn Gran',
			'Santafé',
			'San Silvestre de Guzmán',
			'San Sebastián de los Ballesteros',
			'San Sebastián de la Gomera',
			'Ribera del Fresno',
			'Puerto del Rosario',
			'Pozuelo',
			'Polán',
			'Petra',
			'Palenciana',
			'Paiporta',
			'Ontinyent',
			'Oliva de Mérida',
			'Navarrés',
			'Navalvillar de Ibor',
			'Murtas',
			'Moya',
			'Moraira',
			'Montiel',
			'Montealegre del Castillo',
			'Medina de las Torres',
			'Mazarambroz',
			'Masegoso',
			'Marratxí',
			'Maracena',
			'Lloseta',
			'Laroya',
			'La Iruela',
			'La Herradura',
			'La Algaba',
			'Jun',
			'Jaraicejo',
			'Isla Cristina',
			'Ibi',
			'Hinojales',
			'Higueruela',
			'Granja de Torrehermosa',
			'Godelleta',
			'Garbayuela',
			'Galaroza',
			'Gaibiel',
			'Fuente de Cantos',
			'Espiel',
			'El Toboso',
			'El Plan',
			'Don Álvaro',
			'Córdoba',
			'Cheste',
			'Cedillo',
			'Casas de Ves',
			'Casarabonela',
			'Carrión de los Céspedes',
			'Cardeña',
			'Calamonte',
			'Cabezamesada',
			'Bollullos de la Mitación',
			'Benirredrà',
			'Benalauría',
			'Benahavís',
			'Bellús',
			'Artenara',
			'Aracena',
			'Almoharín',
			'Aldaia',
			'Alcaudete',
			'Albudeite',
			'Alborache',
			'Alamedilla',
			'Zaratamo',
			'Villores',
			'Villoldo',
			'Villeguillo',
			'Villaverde del Monte',
			'Villavellid',
			'Villariezo',
			'Villalba de los Llanos',
			'Villadangos del Páramo',
			'Vilamaniscle',
			'Viladrau',
			'Vera de Moncayo',
			'Velayos',
			'Vegacervera',
			'Vecinos',
			'Valverde de los Arroyos',
			'Vallejera de Riofrío',
			'Valhermoso',
			'Valero',
			'Valdevimbre',
			'Valderrodrigo',
			'Valderas',
			'Valdemorillo',
			'Valdemeca',
			'Urzainqui',
			'Urdiales del Páramo',
			'Tubilla del Lago',
			'Treviana',
			'Traiguera',
			'Torremocha de Jarama',
			'Torreadrada',
			'Toral de los Guzmanes',
			'Tobar',
			'Taboadela',
			'Suellacabras',
			'Sotosalbos',
			'Soto en Cameros',
			'Sotillo de las Palomas',
			'Sorzano',
			'Sorlada',
			'Simancas',
			'Seseña',
			'Sebúlcor',
			'Sasamón',
			'San Vicente del Valle',
			'Santervás de la Vega',
			'Santa Perpètua de Mogoda',
			'Santa María de las Hoyas',
			'Santa Eufemia del Arroyo',
			'Santa Cruz de Boedo',
			'San Martín de Unx',
			'Sant Feliu de Guíxols',
			'San Cristóbal de Segovia',
			'San Bartolomé de Béjar',
			'Salobral',
			'Salduero',
			'Riberos de la Cueza',
			'Regueras de Arriba',
			'Quintanilla de la Mata',
			'Pueyo',
			'As Pontes de García Rodríguez',
			'Pozán de Vero',
			'Pozal de Gallinas',
			'Portugalete',
			'Porriño',
			'Ponts',
			'Ponferrada',
			'Piedralaves',
			'Pezuela de las Torres',
			'Peraltilla',
			'Papatrigo',
			'Palazuelos de Muñó',
			'Pajares de la Laguna',
			'Os de Balaguer',
			'Orkoien',
			'Oquillas',
			'Olloniego',
			'Navares de Enmedio',
			'Nava de la Asunción',
			'Muriel de la Fuente',
			'Muñopepe',
			'Munilla',
			'Mos',
			'Mollet de Peralada',
			'Martiago',
			'Mañón',
			'Mancera de Arriba',
			'Magaña',
			'Los Corrales de Buelna',
			'Linares de Riofrío',
			'Legaria',
			'Leciñena',
			'La Torre de Esteban Hambrán',
			'Languilla',
			'Lalín',
			'Jabaloyas',
			'Itsasondo',
			'Horcajo de las Torres',
			'Hontoba',
			'Hombrados',
			'Hiendelaencina',
			'Guitiriz',
			'Granja de Moreruela',
			'Grajera',
			'Gordoncillo',
			'Gondomar',
			'Golpejas',
			'Xinzo de Limia',
			'Ger',
			'Garriguella',
			'Gallocanta',
			'Fuentes de Nava',
			'Fuentepelayo',
			'Fuentelviejo',
			'Fontiveros',
			'Ezkurra',
			'Eulate',
			'Escalante',
			'Encinedo',
			'Cuntis',
			'Corcos',
			'Corbins',
			'Ciempozuelos',
			'Ciadoncha',
			'Chalamera',
			'Cervera de Buitrago',
			'Centenera de Andaluz',
			'Castroverde de Campos',
			'Castrodeza',
			'Castro de Fuentidueña',
			'Castejón del Puente',
			'Casarrubios del Monte',
			'Cárdenas',
			'Cantiveros',
			'Cañizo',
			'Canillas de Esgueva',
			'Cañamares',
			'Canalejas del Arroyo',
			'Camporrells',
			'Camponaraya',
			'Campo',
			'Camariñas',
			'Calmarza',
			'Cadaqués',
			'Cabezuela',
			'Cabezón de Liébana',
			'Cabanillas del Campo',
			'Cabañas de Ebro',
			'Boada',
			'Berbegal',
			'Berantevilla',
			'Barcial de la Loma',
			'Baquerín de Campos',
			'Badarán',
			'Ayegui',
			'Axpe-San Bartolome',
			'Avinyonet de Puigventós',
			'Arroyomolinos de la Vera',
			'Arnedo',
			'Almoster',
			'Almonacid de la Sierra',
			'Almoguera',
			'Almochuel',
			'Aliud',
			'Alió',
			'Algadefe',
			'Alconada',
			'Alcohujate',
			'Albinyana',
			'Agramunt',
			'Abáigar',
			'Urús',
			'Sants-Montjuïc',
			'Arganzuela',
			'Deltebre',
			'San Isidro',
			'Font-Rubí',
			'Ciutat Meridiana',
			'la Vila Olímpica del Poblenou',
			'San Enrique de Guadiaro',
			'Zalamea la Real',
			'Zafra de Záncara',
			'Yémeda',
			'Villarta de los Montes',
			'Villaharta',
			'Villa del Rey',
			'Valdepeñas',
			'Trebujena',
			'Torrico',
			'Tomelloso',
			'Tolox',
			'Sueca',
			'Sorbas',
			'Sonseca',
			'Ses Salines',
			'Santiago del Campo',
			'Santiago de la Ribera',
			'Rubite',
			'Realejo Alto',
			'Puçol',
			'Puerto de San Vicente',
			'Petrés',
			'Pedreguer',
			'Nerva',
			'Navas de Estena',
			'Muro del Alcoy',
			'Montellano',
			'Es Molinar',
			'Maó',
			'Los Molares',
			'Laujar de Andarax',
			'Las Cabezas de San Juan',
			'La Puebla del Río',
			'Jarafuel',
			'Ibiza',
			'Hinojal',
			'Henarejos',
			'Guadahortuna',
			'Graja de Campalbo',
			'Fuengirola',
			'Faraján',
			'Corral de Calatrava',
			'Churriana de la Vega',
			'Casas de Fernando Alonso',
			'Calvià',
			'Bullas',
			'Bujalance',
			'Breña Alta',
			'Bonares',
			'Bolaños de Calatrava',
			'Benimassot',
			'Belalcázar',
			'Badolatosa',
			'Arona',
			'Arcos de la Frontera',
			'Alosno',
			'Almassora',
			'Alcaraz',
			'Alcàsser',
			'Albarreal de Tajo',
			'Igantzi',
			'Wamba',
			'Villoruela',
			'Villasbuenas',
			'Villarrabé',
			'Villanueva de Sigena',
			'Villanubla',
			'Villamoronta',
			'Villamoratiel de las Matas',
			'Villamayor de Campos',
			'Villalobar de Rioja',
			'Villalbilla',
			'Villalba de la Sierra',
			'Villageriz',
			'Villafranca del Campo',
			'Villafrades de Campos',
			'Videmala',
			'Valtiendas',
			'Valmojado',
			'Vallfogona de Riucorb',
			'Valles de Palenzuela',
			'Vallbona de les Monges',
			'Valderrueda',
			'Valdelageve',
			'Valdearcos de la Vega',
			'Urdazubi / Urdax',
			'Trillo',
			'Touro',
			'Tórtoles',
			'Torres del Carrizal',
			'Torrelobatón',
			'Torrehermosa',
			'Tornavacas',
			'Tierzo',
			'Tapioles',
			'Sober',
			'Singra',
			'Senés de Alcubierre',
			'Semillas',
			'Segura',
			'San Vicente de la Cabeza',
			'Santiago Millas',
			'Santa Marta de Tormes',
			'San Miguel de la Ribera',
			'San Cebrián de Mudá',
			'Leintz-Gatzaga',
			'Saldón',
			'Saldaña',
			'Salas de Bureba',
			'Rojas',
			'Riudellots de la Selva',
			'Ribesalbes',
			'Ribeira',
			'Ribatejada',
			'Redondela',
			'Rebollo',
			'Rapariegos',
			'Rabanera',
			'Quismondo',
			'Quintanavides',
			'Puente de Domingo Flórez',
			'Préjano',
			'Pozos de Hinojo',
			'Poio',
			'Pontevedra',
			'Pobladura del Valle',
			'Pineda de Gigüela',
			'Piedramillera',
			'Paredes de Escalona',
			'Paredes',
			'Paradinas de San Juan',
			'Parada de Arriba',
			'Palenzuela',
			'Oteiza',
			'Òrrius',
			'Ontiñena',
			'Oncala',
			'Obanos',
			'Nogueira de Ramuín',
			'Muduex',
			'Moratilla de los Meleros',
			'Morata de Jalón',
			'Monterrubio de la Sierra',
			'Monterde de Albarracín',
			'Monreal',
			'Medrano',
			'Maials',
			'Matarrubia',
			'Martinamor',
			'Marcilla de Campos',
			'Mainar',
			'Madrigal del Monte',
			'Longás',
			'Lomas',
			'La Seca',
			'Lama',
			'La Cuesta',
			'Juzbado',
			'Jaraíz de la Vera',
			'Itero del Castillo',
			'Iglesias',
			'Hortigüela',
			'Horcajuelo de la Sierra',
			'Herce',
			'Gernika-Lumo',
			'Guardiola de Berguedà',
			'Grañén',
			'Goizueta',
			'Girona',
			'Garcirrey',
			'Galbarros',
			'Fuertescusa',
			'Fuentes de Ebro',
			'Etayo',
			'Estada',
			'Espronceda',
			'Espinosa de los Monteros',
			'Eslava',
			'El Pardo',
			'Gurb',
			'Ejea de los Caballeros',
			'Cozuelos de Fuentidueña',
			'Cornago',
			'Corbillos de los Oteros',
			'Collado del Mirón',
			'Coirós',
			'Cedeira',
			'Castrillo de Cabrera',
			'Castilruiz',
			'Castillonuevo',
			'Castañares de Rioja',
			'Casasola de Arión',
			'Carpio de Azaba',
			'Carnota',
			'Capmany',
			'Cañaveras',
			'Campo de Villavidel',
			'Camargo',
			'Calatayud',
			'Cabreros del Río',
			'Cabezón de Cameros',
			'Bubierca',
			'Borox',
			'Beteta',
			'Berriz',
			'Bascuñana de San Pedro',
			'Barrio de Muñó',
			'Banastás',
			'Azuelo',
			'Aitona',
			'Arzúa',
			'Arroyomolinos',
			'Arbeteta',
			'Arauzo de Torre',
			'Antigüedad',
			'Anchuelo',
			'Amusquillo',
			'Amieva',
			'Alpedrete',
			'Alp',
			'Alguaire',
			'Aldea Real',
			'Alcanó',
			'Albeta',
			'Albesa',
			'Albendiego',
			"S'Agaró",
			'Diagonal Mar',
			'Gordexola',
			'Tiétar',
			'Yecla',
			'Villaverde de Guadalimar',
			'Villasequilla de Yepes',
			'Villarejo-Periesteban',
			'Villar del Arzobispo',
			'Villalba de los Barros',
			'Villahermosa',
			'Venta del Moro',
			'Velez Rubio',
			'Valverde de Júcar',
			'Valderrubio',
			'Valdemorales',
			'Tías',
			'Teulada',
			'Sevilleja de la Jara',
			'Sedaví',
			'San Martín de Montalbán',
			'Puig',
			'Porzuna',
			'Pliego',
			'Osa de la Vega',
			'Orellana la Vieja',
			'Orce',
			'Níjar',
			'Niebla',
			'Nerja',
			'Montijo',
			'Monterrubio de la Serena',
			'Montejicar',
			'Montalvos',
			'Montalbán de Córdoba',
			'Monda',
			'Mohedas de la Jara',
			'Moclín',
			'Martín de la Jara',
			'Magacela',
			'Los Villares',
			'La Roda de Andalucía',
			'La Carolina',
			'Xeraco',
			'Hinojosas de Calatrava',
			'Hinojos',
			'Higuera la Real',
			'Guadalmez',
			'Garlitos',
			'Felanitx',
			'Escañuela',
			'Escalonilla',
			'El Provencio',
			'El Carpio',
			'Dos Aguas',
			'Dolores',
			'Dehesas Viejas',
			'Daimús',
			'Coria del Río',
			'Constantina',
			'Colomera',
			'Cijuela',
			'Chiva',
			'Chillón',
			'Cazalla de la Sierra',
			'Castril',
			'Carriches',
			'Carcelén',
			"Canet d'En Berenguer",
			'Cañada Rosal',
			'Cájar',
			'Brazatortas',
			'Bocairent',
			'Benillup',
			'Benidoleig',
			'Benasau',
			'Benamaurel',
			'Azuaga',
			'Arroyo de San Serván',
			'Antigua',
			'Altea',
			'Almonte',
			'Almadén',
			'Aljucén',
			'Alicún',
			'Alhendín',
			'Algarrobo',
			'Alfarnate',
			'Aldeire',
			'Alcoy',
			'Alaquàs',
			'Yelo',
			'Yanguas de Eresma',
			'Viñas',
			'Villaverde de Guareña',
			'Villasrubias',
			'Villasexmir',
			'Villaseca de Uceda',
			'Villasabariego',
			'Villarejo de la Peñuela',
			'Villamayor de Treviño',
			'Villamanrique de Tajo',
			'Villagómez la Nueva',
			'Ordizia',
			'Villaespasa',
			'Villadoz',
			'Vila-sacra',
			'Vilablareix',
			'Ventalló',
			'Veguillas de la Sierra',
			'Vegadeo',
			'Vallclara',
			'Vallcebre',
			'Valdeltormo',
			'Valdecarros',
			'Valdearenas',
			'Uceda',
			'Torrubia',
			'Torroella de Fluvià',
			'Torresmenudas',
			'Torres de Segre',
			'Torrescárcela',
			'Torrent',
			'Torregalindo',
			'Torreblacos',
			'Torralba de los Frailes',
			'Torralba de Aragón',
			'Tiana',
			'Terriente',
			'Tamarón',
			'Talamantes',
			'Tagamanent',
			'Setiles',
			'San Vicente de la Barquera',
			'Santurtzi',
			'Santiuste',
			'Santa María del Invierno',
			'Santa María de la Vega',
			"Sant Sadurní d'Anoia",
			'San Millán de Yécora',
			'San Miguel del Arroyo',
			'San Martín del Castañar',
			'San Felices de los Gallegos',
			'San Cebrián de Mazote',
			'Rubielos de la Cérida',
			'Rubí',
			'Robledillo de Mohernando',
			'Ribes de Freser',
			'Requena de Campos',
			'Redecilla del Campo',
			'Quintanas de Gormaz',
			'Puras',
			'Puerto Castilla',
			'Prádena de Atienza',
			'Prádanos de Bureba',
			'Pozuelo de Aragón',
			'Pozuel del Campo',
			'Porqueira',
			'Pobladura de Pelayo García',
			'Petín',
			'Perales del Puerto',
			'Peñalba',
			'Palacios del Pan',
			'Palacios de la Valduerna',
			'Orcajo',
			'Oltza',
			'Oleiros',
			'Nolay',
			'Neila',
			'Navarredondilla',
			'Navales',
			'Naval',
			'Nava de Béjar',
			'Mutiloa',
			'Murgia',
			'Moraleja',
			'Moral de la Reina',
			'Montemayor del Río',
			'Molacillos',
			'Moaña',
			'Membribe de la Sierra',
			'Mélida',
			'Mazuecos de Valdeginate',
			'Matilla de los Caños',
			'Markina-Xemein',
			'Manzanillo',
			'Manganeses de la Polvorosa',
			'Malva',
			'Lugo',
			'Lozoya',
			'Loscos',
			'Los Arcos',
			'Loporzano',
			'Lliçà de Vall',
			'Llanes',
			'Llanera',
			'Leiva',
			'Laperdiguera',
			'Jorcas',
			'Joarilla de las Matas',
			'Irueste',
			'Iglesiarrubia',
			'Ibieca',
			'Humanes',
			'Huerta',
			'Horta',
			'Hormigos',
			'Horcajo de la Sierra',
			'Hontalbilla',
			'Hinojosa del Campo',
			'Henche',
			'Guntín',
			'Guijo de Granadilla',
			'Grandas de Salime',
			'Garrigàs',
			'Galápagos',
			'Fustiñana',
			'Fuentesaúco',
			'Fuencemillán',
			'Formiche Alto',
			'Fombellida',
			'El Tiemblo',
			'El Hoyo de Pinares',
			'Dosrius',
			'Cuevas del Valle',
			'Cuenca de Campos',
			'Crivillén',
			'Collado-Villalba',
			'Cobeta',
			'Ciruelos de Cervera',
			'Chequilla',
			'Cerezo de Arriba',
			'Castillejo-Sierra',
			'Castellanos de Zapardiel',
			'Casaseca de Campeán',
			'Candín',
			'Camarma de Esteruelas',
			'Cabañes de Esgueva',
			'Bustarviejo',
			'Buniel',
			'Buitrago del Lozoya',
			'Briviesca',
			'Briñas',
			'Brime de Sog',
			'Brañosera',
			'Borau',
			'Bonastre',
			'Betelu',
			'Berberana',
			'Bellprat',
			'Begues',
			'Barriopedro',
			'Barrado',
			'Barraco',
			'Barajas de Melo',
			'Baños de Tajo',
			'Azuara',
			'Avinyonet del Penedès',
			'Arroyo de la Encomienda',
			'Arlanzón',
			'Arantza',
			'Ansó',
			'Alustante',
			'Alocén',
			'Alella',
			'Albalate de Zorita',
			'Adalia',
			'Abia de las Torres',
			'Abadiño',
			'Palmanova',
			'Novés',
			'els Poblets',
			'Callús',
			'el Putxet i el Farró',
			'la Marina de Port',
			'La Torrecilla',
			'Cami Vell de Sant Mateu',
			'Zuheros',
			'Villarrobledo',
			'Villargordo del Cabriel',
			'Villanueva de las Cruces',
			'Vícar',
			'Tibi',
			'Terrateig',
			'Tegueste',
			'Tahal',
			'Tabernas',
			'Sella',
			'San Vicent del Raspeig',
			'Santa Marta de Magasca',
			'Santa Amalia',
			'San Pedro de Alcántara',
			'Sangonera la Verde',
			'Salvaleón',
			'Salorino',
			'Salobre',
			'Romangordo',
			'Requena',
			'Puebla de Guzmán',
			'Povedilla',
			'Pilas',
			'Peñas de San Pedro',
			'Parcent',
			'Pampaneira',
			'Palma',
			'Padul',
			'Orba',
			'Oliva',
			'Ojén',
			'Mula',
			'Monforte del Cid',
			'Lúcar',
			'Llerena',
			'La Roca de la Sierra',
			'La Puerta de Segura',
			'La Matanza de Acentejo',
			'Jódar',
			'Jaén',
			'Jacarilla',
			'Illora',
			'Genovés',
			'Fuentes de Andalucía',
			'Fuente de Pedro Naharro',
			'Feria',
			'Esporles',
			'Esparragal',
			'El Varadero',
			'El Puente del Arzobispo',
			'El Castillo de las Guardas',
			'Deleitosa',
			'Daya Vieja',
			'Costitx',
			'Cómpeta',
			'Cofrentes',
			'Chiclana de Segura',
			'Cheles',
			'Ceuti',
			'Cervera del Llano',
			'Castañar de Ibor',
			'Casas de Millán',
			'Casas de Lázaro',
			'Campos',
			"Callosa d'En Sarrià",
			'Cabañas de Yepes',
			'Buenache de Alarcón',
			'Bonete',
			'Berja',
			'Bélmez',
			'Barcience',
			'Cruce de Arinaga',
			'Alpera',
			'Alora',
			'Almonacid del Marquesado',
			'Alhaurín de la Torre',
			'Alguazas',
			'Algaida',
			"L'Alcúdia",
			'Alcubillas',
			'Alcalalí',
			'Albuñán',
			'Agres',
			'Abertura',
			'Zotes del Páramo',
			'Zarza de Tajo',
			'Yunquera de Henares',
			"Vilobí d'Onyar",
			'Viloria de Rioja',
			'Villavendimio',
			'Villaselán',
			'Villarta-Quintana',
			'Villarroya del Campo',
			'Villar de Gallimazo',
			'Villanueva de Gumiel',
			'Villamayor de los Montes',
			'Villamartín de Campos',
			'Villalba de Perejil',
			'Vilalba',
			'Villalaco',
			'Villagonzalo de Tormes',
			'Villaflor',
			'Villaescusa',
			'Villa del Prado',
			'Vilasantar',
			'Vilanova del Camí',
			'Viladecans',
			'Vigo',
			'Ventrosa',
			'Ventosa del Río Almar',
			'Valdecasa',
			'Vadillo de la Guareña',
			'Trasobares',
			'Torrijo del Campo',
			'Torrijas',
			'Torres de la Alameda',
			'Torrellas',
			'Tobía',
			'Tineo',
			'Tenebrón',
			'Terrassa',
			'Sobrado',
			'Sils',
			'Serra de Daró',
			'Sarria',
			'Santiuste de San Juan Bautista',
			'Santibáñez de Vidriales',
			'Santas Martas',
			'Santa Marina del Rey',
			'Santa María la Real de Nieva',
			'Santa Coloma de Queralt',
			'San Pedro del Valle',
			'Sant Ferriol',
			'Luintra',
			'Ribaforada',
			'Ribadumia',
			'Revilla del Campo',
			'Quintana del Marco',
			'Quintanabureba',
			'Quemada',
			'A Pobra do Caramiñal',
			'Puebla de Arenoso',
			'Proaza',
			'Priego',
			'Prado de la Guzpeña',
			'Posada de Valdeón',
			'Pol',
			'Población de Cerrato',
			'Pelayos del Arroyo',
			'Pedraza de Alba',
			'Pau',
			'Olivares de Duero',
			'Òdena',
			'Nueno',
			'Novillas',
			'Navalilla',
			'Muro en Cameros',
			'Mozoncillo',
			'Morés',
			'Monterrubio',
			'Montblanc',
			'Monsagro',
			'Monforte de Moyuela',
			'Moneva',
			'Molins de Rei',
			'Mirabueno',
			'Mengamuñoz',
			'Melide',
			'Meira',
			'Mazaleón',
			'Matapozuelos',
			'Mata de Cuéllar',
			'Martín Muñoz de las Posadas',
			'Mariana',
			'Marchagaz',
			'Meranges',
			'Malanquilla',
			'Madrigalejo del Monte',
			'Madremanya',
			'Leza de Río Leza',
			'Lantz',
			'La Muela',
			'La Canonja',
			'Irañeta',
			'Huerta del Marquesado',
			'Hontoria de Valdearados',
			'Higuera de las Dueñas',
			'Hernansancho',
			'Grijota',
			'Goñi',
			'Gimileo',
			'Gascones',
			'Gaintza',
			'Fuentelsaz',
			'Fresno de Torote',
			'Fresnedoso',
			'Fórnoles',
			'Forès',
			'Estadilla',
			'el Raval',
			'El Álamo',
			'Eibar',
			'Dozón',
			'Domingo García',
			'Culla',
			'Corullón',
			'Colunga',
			'Cipérez',
			'Cinctorres',
			'Cilleruelo de Abajo',
			'Cillán',
			'Chozas de Abajo',
			'Cee',
			'Zeanuri',
			'Castrillo de Villavega',
			'Castelserás',
			'Castejón de Alarba',
			'Cascante',
			'Carrascosa',
			'Carbellino',
			'Carabantes',
			'Candilichera',
			'Candás',
			'Camarzana de Tera',
			'Cabeza del Caballo',
			'Bustillo del Páramo',
			'Borriol',
			'Borrenes',
			'Bocigas',
			'Boceguillas',
			'Biel',
			'Bercial de Zapardiel',
			'Berceruelo',
			"Bellcaire d'Urgell",
			'Bárzana',
			'Barbalos',
			'Babilafuente',
			'Azpeitia',
			'Avilés',
			'Atea',
			'Armiñón',
			'Anento',
			'Andorra',
			'Anaya',
			'Alquézar',
			'Almohaja',
			'Alhóndiga',
			'Alerre',
			'Aguilar del Río Alhama',
			'Adrados',
			'Ablitas',
			'Sant Cristòfol de les Fonts',
			'Los Gigantes',
			'Atanzón',
			'Barri de Sant Andreu',
			'Yaiza',
			'Villanueva de Castellón',
			'Villanueva de Algaidas',
			'Valverde del Camino',
			'Turleque',
			'Trasierra',
			'Toril',
			'Tejeda',
			'Siruela',
			'Setenil de las Bodegas',
			'Saucedilla',
			'San Martín de Pusa',
			'Salteras',
			'Rielves',
			'Puebla de la Reina',
			'Porreres',
			'Polícar',
			'Pizarra',
			'Otos',
			'Nules',
			'Navezuelas',
			'Narboneta',
			'Moclinejo',
			'Minglanilla',
			'Melilla',
			'Mahora',
			'Lupión',
			'Lucainena de las Torres',
			'Las Pedroñeras',
			'La Rinconada',
			'La Puebla de los Infantes',
			'La Campana',
			'Igualeja',
			'Huércal-Overa',
			'Hontecillas',
			'Hermigua',
			'Génave',
			'Gata de Gorgos',
			'Fortaleny',
			'Fontanarejo',
			'El Saucejo',
			'El Rubio',
			'El Ronquillo',
			'Deià',
			'Darro',
			'Cuevas de San Marcos',
			'Corte de Peleas',
			'Cebolla',
			'Castuera',
			'Carrícola',
			'Carbajo',
			'Camuñas',
			'Campotéjar',
			'Calera y Chozas',
			'Busot',
			'Bollullos par del Condado',
			'Bicorp',
			'Berlanga',
			'Benissoda',
			'Benaguasil',
			'Bèlgida',
			'Bédar',
			'Barrio de la Concepción',
			'Aznalcázar',
			'Árchez',
			'Almedíjar',
			'Alginet',
			'Alfondeguilla',
			'Aldeanueva de San Bartolomé',
			'Albaida',
			'Alatoz',
			'Aguilar',
			'Adamuz',
			'Grao de Murviedro',
			'Zucaina',
			'Zarzalejo',
			'Zarza de Granadilla',
			'Villazanzo de Valderaduey',
			'Villavieja del Lozoya',
			'Villaverde de Rioja',
			'Villaturde',
			'Villaseca de Arciel',
			'Villar del Río',
			'Villar del Ala',
			'Vilanova i la Geltrú',
			'Villanueva del Aceral',
			'Villamuera de la Cueza',
			'Villamiel de la Sierra',
			'Villaescusa de Roa',
			'Villademor de la Vega',
			'Villaconancio',
			'Villabrázaro',
			'Atarrabia',
			'Vilanova de Prades',
			'Vielha',
			'Vic',
			'Vallgorguina',
			'Valdeolivas',
			'Valdehorna',
			'Urnieta',
			'Ullastrell',
			'Tudela de Duero',
			'Trescasas',
			'Tramacastiel',
			'Torremocha de Jadraque',
			'Torrecilla sobre Alesanco',
			'Torrecilla de Alcañiz',
			'Torrechiva',
			'Teo',
			'Tardienta',
			'Tardelcuende',
			'Talamanca de Jarama',
			'Sort',
			'Somolinos',
			'Secastilla',
			'Saucelle',
			'Sarrión',
			'Santa Marta del Cerro',
			'Santa María de Valverde',
			'Santa Eufemia del Barco',
			'Santa Cecília de Voltregà',
			'San Pedro Palmiches',
			'Sant Pere de Vilamajor',
			'San Pedro del Arroyo',
			'San Morales',
			'San Martín del Río',
			'San Martín de la Vega del Alberche',
			'San Emiliano',
			'San Agustín del Guadalix',
			'San Agustín',
			'San Adrián del Valle',
			'Sabero',
			'Robledillo de Gata',
			'Ripollet',
			'Ribadeo',
			'Riaño',
			'Retiendas',
			'Redueña',
			'Quel',
			'Puentedura',
			'Prioro',
			'Prádena',
			'Pozuelo de Tábara',
			'Portillo de Soria',
			'Pineda de la Sierra',
			'Piña de Campos',
			'Perales del Alfambra',
			'Peleas de Abajo',
			'Pelayos de la Presa',
			'Pascualcobo',
			'Oseja',
			'Orpí',
			'Olóriz',
			'Olesa de Montserrat',
			'Olejua',
			'Navaleno',
			'Narrillos del Rebollar',
			'Mungia',
			'Mozárbez',
			'Mosqueruela',
			'Moronta',
			'Morille',
			'Montón',
			'Montanejos',
			'Mondariz',
			'Migueláñez',
			'Mieres',
			'Massanes',
			'Martínez',
			'Maicas',
			'Madridanos',
			'Laudio / Llodio',
			"Lliçà d'Amunt",
			'Llamas de la Ribera',
			'Limpias',
			'Laspaúles',
			'la Pobla de Mafumet',
			'La Pineda',
			'Langa de Duero',
			'Jirueque',
			'Hoz de Jaca',
			'Hinojosa de Jarque',
			'Herrería',
			'Gutierre-Muñoz',
			'Guijo de Coria',
			'Getaria',
			'Grávalos',
			'Gormaz',
			'Elexalde',
			'Gema',
			'Gabiria',
			'Garray',
			'Fuentes de Ropel',
			'Fuente el Saz',
			'Fuenlabrada',
			'Forua',
			'Esplegares',
			'Entrambasaguas',
			'Endrinal',
			'Encina de San Silvestre',
			'El Astillero',
			'Cortes de Aragón',
			'Corpa',
			'Congosto',
			'Collsuspina',
			'Colindres',
			'Cistella',
			'Cifuentes',
			'Cerratón de Juarros',
			'Cella',
			'Castrillo de la Guareña',
			'Castil de Peones',
			'Castiello de Jaca',
			'Castellanos de Villiquera',
			'Cassà de la Selva',
			'Cardedeu',
			'Cantimpalos',
			'Candasnos',
			'Calanda',
			'Calamocha',
			'Cadreita',
			'Bustillo de la Vega',
			'Burlata',
			'Bueu',
			'Buenaventura',
			'Bordalba',
			'Astorga',
			'Ascó',
			'Armuña de Tajuña',
			'Areso',
			'Ares del Maestre',
			'Arenys de Lledó / Arens de Lledó',
			'Arcos de la Polvorosa',
			'Arbizu',
			'Aras',
			'Aranga',
			'Aranda de Moncayo',
			'Aoiz',
			'Andoain',
			'Amezketa',
			'Altafulla',
			'Alkiza',
			'Alpens',
			'Aldeaseca de Alba',
			'Albero Alto',
			'Alaejos',
			'Agullana',
			'Aguilafuente',
			'Riu de Cerdanya',
			'Delicias',
			'El Toro',
			'Sahún',
			'Les Franqueses del Vallès',
			'Sarrià',
			'la Prosperitat',
			'Vallbona',
			'el Besòs i el Maresme',
			'Acered',
			'Viver',
			'Villanueva de Tapia',
			'Villanueva del Trabuco',
			'Villamalur',
			'Vélez-Blanco',
			'Turre',
			'Trigueros',
			'Terrinches',
			'Terque',
			'Tacoronte',
			'Serradilla',
			'Santa Fe de Mondújar',
			'San Pedro',
			'San Isidro',
			'Sant Carles de Peralta',
			'Rute',
			'Riópar',
			'Rada de Haro',
			'Purullena',
			'Pulpí',
			'Pozoblanco',
			'Planes',
			'Pedro Martínez',
			'Pedroche',
			'Paterna de Rivera',
			'Orgaz',
			'Navas del Madroño',
			'Morón de la Frontera',
			'Mollina',
			'Molina de Segura',
			'Millanes',
			'Manzanares',
			'Los Silos',
			'Los Gabatos',
			'Lagartera',
			'La Calzada de Calatrava',
			'Jabugo',
			'Iznájar',
			'Huesa',
			'Hoya-Gonzalo',
			'Hinojares',
			'Granja de Rocamora',
			'Gobernador',
			'Gaucín',
			'La Font de la Figuera',
			'Fontanars dels Alforins',
			'Fernán-Núñez',
			'El Perelló',
			'Dehesas de Guadix',
			'Daya Nueva',
			'Cumbres Mayores',
			'Conquista de la Sierra',
			'Chercos',
			'Cazalilla',
			'Castell de Ferro',
			'Campanario',
			'Breña Baja',
			'Bonrepòs i Mirambell',
			'Bigastro',
			'Bentarique',
			'Benilloba',
			'Beniardá',
			'Benaocaz',
			'Almudaina',
			'Almodóvar del Pinar',
			'Almensilla',
			'Almendricos',
			'Algodonales',
			'Alcollarín',
			'Alcañizo',
			'Agost',
			'Alcalá del Río',
			'Abanilla',
			'Zarzosa',
			'Yuncos',
			'Villodre',
			'Villar de Olalla',
			'Villanueva de Cameros',
			'Villamanín',
			'Vilagarcía de Arousa',
			'Villacidaler',
			'Viladasens',
			'Vallelado',
			'Valfarta',
			'Valdefinjas',
			'Urueñas',
			'Ugena',
			'Trefacio',
			'Torres de Berrellén',
			'Torres de Albarracín',
			'Torlengua',
			'Tiedra',
			'Tàrrega',
			'Tarazona de Guareña',
			'Sotillo',
			'Sobrado',
			'Sidamon',
			'Serradilla del Llano',
			'Sediles',
			'Sarratella',
			'Santa Bárbara',
			'Sant Pere de Riudebitlles',
			'Salas',
			'Rubielos de Mora',
			'Rubena',
			'Ricla',
			'Respenda de la Peña',
			'Errenteria',
			'Rascafría',
			'Quintanilla de Trigueros',
			'Quintanilla de Onésimo',
			'Puerto de Béjar',
			'Pola de Siero',
			'Pinilla de Toro',
			'Pina de Montalgrao',
			'Peromingo',
			'Peñaranda de Bracamonte',
			'Peñaflor de Hornija',
			'Pareja',
			'Pancorbo',
			'Pampliega',
			'Palencia',
			'Palazuelo de Vedija',
			'Pajares de la Lampreana',
			'Orísoain',
			'Olmedilla de Eliz',
			'Navas de Bureba',
			'Navarrevisca',
			'Muñoveros',
			'Mundaka',
			'Moscardón',
			'Morella',
			'Moraña',
			'Moralzarzal',
			'Moraleja de Matacabras',
			'Moral de Sayago',
			'Monteagudo del Castillo',
			'Monegrillo',
			'Moncalvillo',
			'Miño',
			'Matute',
			'Masarac',
			'Martorell',
			'Martín Muñoz de la Dehesa',
			'Maluenda',
			'Luzaga',
			'Lupiana',
			'Losacio',
			'Leganés',
			'Ledesma de la Cogolla',
			'Larraona',
			'Lagunilla',
			'Huerta del Rey',
			'Huérmeces',
			'Hostalric',
			'Hornillos del Camino',
			'Hervías',
			'Guijo de Ávila',
			'Guardo',
			'Grisén',
			'Getafe',
			'Gejuelo del Barro',
			'Gallegos del Pan',
			'Galdakao',
			'Galbárruli',
			'Fuentelahiguera de Albatages',
			'Fuentearmegil',
			'Fonz',
			'Espino de la Orbada',
			'Encío',
			'El Vellón',
			'El Burgo de Osma',
			'Elantxobe',
			'Ejeme',
			'Duruelo de la Sierra',
			'Dumbría',
			'Donhierro',
			'Dehesa de Romanos',
			'Cunit',
			'Cubo de Bureba',
			'Cubillos',
			'Corporales',
			'Copernal',
			'Coll de Nargó',
			'Collado de Contreras',
			'Clavijo',
			'Cepeda',
			'Cea',
			'Castronuño',
			'Castellterçol',
			'Castellanos de Castro',
			'Cascajares de Bureba',
			'Casavieja',
			'Carcedo de Bureba',
			'Carbajales de Alba',
			'Calzadilla',
			'Caltojar',
			'Calonge',
			'Caldes de Malavella',
			'Cabrejas del Pinar',
			'Cabolafuente',
			'Cabanillas de la Sierra',
			'Bureta',
			'Buñuel',
			'Buenamadre',
			'Bueña',
			'Botarell',
			'Bilbao',
			'Bermellar',
			'Bercial',
			'Bembibre',
			'Belver de Cinca',
			'Becedas',
			'Barrachina',
			'Bárcena de Campos',
			'Barca',
			'Barbolla',
			'Asturianos',
			'Arróniz',
			'Arquillinos',
			'Arija',
			'Argelaguer',
			'Arévalo de la Sierra',
			'Arcediano',
			'Anaya de Alba',
			'Almacelles',
			'Alforja',
			'Alegia',
			'Aldeasoña',
			'Aldeaseca de la Frontera',
			'Aldeanueva de Figueroa',
			'Aldealseñor',
			'Alcazarén',
			'Alcalá de Henares',
			'Albocàsser',
			'Aiguaviva',
			'Àger',
			'Montecanal',
			'Daroca de Rioja',
			'la Verneda i la Pau',
		],
	},
	'Sri Lanka': {
		country: 'Sri Lanka',
		cities: [
			'Wattegama',
			'Sri Jayewardenepura Kotte',
			'Kalutara',
			'Colombo',
			'Dehiwala-Mount Lavinia',
			'Ella Town',
			'Horawala Junction',
			'Trincomalee',
			'Weligama',
			'Wattala',
			'Peliyagoda',
			'Hanwella Ihala',
			'Vakarai',
			'Kelaniya',
			'Kadugannawa',
			'Chilaw',
			'Ambalangoda',
			'Welisara',
			'Tangalle',
			'Mawalgama',
			'Kotikawatta',
			'Homagama',
			'Gampola',
			'Galle',
			'Koggala',
			'Katunayaka',
			'Hendala',
			'Bentota',
			'Mulleriyawa',
			'Kandy',
			'Hikkaduwa',
			'Batticaloa',
			'Valvedditturai',
			'Polonnaruwa',
			'Talawakele',
			'Moratuwa',
			'Jaffna',
			'Eravur Town',
			'Kalmunai',
			'Beruwala',
			'Nuwara Eliya',
			'Devinuwara',
			'Vavuniya',
			'Sigiriya',
			'Ratnapura',
			'Monaragala',
			'Horana South',
			'Wellawaya',
			'Talpe',
			'Kuliyapitiya',
			'Kegalle',
			'Gampaha',
			'Padaviya Divisional Secretariat',
			'Kilinochchi',
			'Puttalam',
			'Matale',
			'Kurunegala',
			'Kolonnawa',
			'Maharagama',
			'Mirissa city',
			'Ja Ela',
			'Bogahakumbura',
			'Badulla',
			'Unawatuna',
			'Pita Kotte',
			'Kandana',
			'Point Pedro',
			'Minuwangoda',
			'Mihintale',
			'Matara',
			'Haputale',
			'Panadura',
			'Hatton',
			'Dambulla',
			'Anuradhapura',
			'Negombo',
			'Kataragama',
			'Battaramulla South',
			'Ampara',
		],
	},
	'Sudan, The Republic of': {
		country: 'Sudan, The Republic of',
		cities: [
			'Talodi',
			'Marabba',
			'El Obeid',
			'Al Manshiya',
			'Wad az Zāki',
			'Rabak',
			'Al Manāqil',
			'El Bauga',
			'Sinnar',
			'Wad Medani',
			'Kadugli',
			'Bārah',
			'Al Hilāliyya',
			'Al Ḩawātah',
			'Gebeit',
			'Najaru قرية نجرو',
			'El Daein',
			'Al Giref Gharb',
			'Ad-Damazin',
			'Abū Zabad',
			'Ad Dabbah',
			'Zalingei',
			'Umm Ruwaba',
			'Doka',
			'Port Sudan',
			'Al Masallamiyya',
			'Al Kiremit al ‘Arakiyyīn',
			'Kuraymah',
			'Atbara',
			'ِAl Taif',
			'Shendi',
			'Argo',
			'Al Fūlah',
			'Ed Damer',
			'Karmah an Nuzul',
			'As Sūkī',
			'Al Qadarif',
			'El Fula',
			'Riyadh',
			'Al Hasaheisa',
			'Burri Al Drayssah',
			'El Matama',
			'Wagar',
			'Singa',
			'Al Mijlad',
			'Maiurno',
			'Ad Dindar',
			'Nasir Extension',
			'Burri Al Mahas',
			'Al Qiţena',
			'Al Lagowa',
			'Ad Douiem',
			'Tandaltī',
			'Nyala',
			'Dilling',
			'Berber',
			'Geneina',
			'Burri Al Lamab',
			'Garden City',
			'Wad Rāwah',
			'Omdurman',
			'An Nuhūd',
			'Khartoum',
			'El Fasher',
			'Um Jar Al Gharbiyya',
			'Sawākin',
			'Merowe',
			'Kutum',
			'Kosti',
			'Jalqani',
			'Ar Rahad',
			'Aroma',
			'Burri Al Shreef',
			'Tokār',
			'Kināna',
			'Abu Jibeha',
			'Gereida',
			'Nagaro',
			'Dongola',
			'Ar Ruseris',
			'Al Kawa',
			'Umm Kaddadah',
			'Kurmuk',
			'Kassala',
		],
	},
	Suriname: {
		country: 'Suriname',
		cities: [
			'Totness',
			'Lelydorp',
			'Nieuw Nickerie',
			'Mariënburg',
			'Brokopondo',
			'Wageningen',
			'Nieuw Amsterdam',
			'Meerzorg',
			'Paramaribo',
			'Onverwacht',
			'Albina',
			'Groningen',
			'Moengo',
			'Brownsweg',
		],
	},
	Swaziland: {
		country: 'Swaziland',
		cities: [
			'Big Bend',
			'Manzini',
			'Malkerns',
			'Mhlume',
			'Hluti',
			'Sidvokodvo',
			'Nhlangano',
			'Kubuta',
			'Mbabane',
			'Tshaneni',
			'Mhlambanyatsi',
			'Bhunya',
			'Nsoko',
			'Ntfonjeni',
			'Siteki',
			'Lavumisa',
			'Kwaluseni',
			'Lobamba',
			'Darkton',
			'Bulembu',
			'Vuvulane',
			'Piggs Peak',
			'Hlatikulu',
		],
	},
	Sweden: {
		country: 'Sweden',
		cities: [
			'Viken',
			'Gammelstad',
			'Vännäs',
			'Tumba',
			'Timmernabben',
			'Surte',
			'Storfors',
			'Stenstorp',
			'Gamla Stan',
			'Sörforsa',
			'Skultuna',
			'Sätila',
			'Råtorp',
			'Påskallavik',
			'Malmö',
			'Johannedal',
			'Järna',
			'Hestra',
			'Gunnebo',
			'Grebbestad',
			'Glimåkra',
			'Färgelanda',
			'Fagersta',
			'Bjästa',
			'Billesholm',
			'Bälinge',
			'Alsike',
			'Majorna',
			'Rannebergen',
			'Ursviken',
			'Långsele',
			'Vittsjö',
			'Växjö',
			'Vänersborg',
			'Vaggeryd',
			'Vadstena',
			'Torsby',
			'Tillberga',
			'Stockholm',
			'Skivarp',
			'Skänninge',
			'Oxelösund',
			'Överum',
			'Olofstorp',
			'Lerum',
			'Leksand',
			'Kvissleby',
			'Kummelnäs',
			'Kopparberg',
			'Kävlinge',
			'Herrestad',
			'Gemla',
			'Furulund',
			'Eskilstuna',
			'Bunkeflostrand',
			'Björlanda',
			'Saxtorpsskogen',
			'Piteå',
			'Västra Frölunda',
			'Vara',
			'Gimo',
			'Tranemo',
			'Teckomatorp',
			'Svenljunga',
			'Strömsund',
			'Storebro',
			'Sibbhult',
			'Rönnäng',
			'Örnsköldsvik',
			'Nora',
			'Mjällby',
			'Lindsdal',
			'Jokkmokk',
			'Högsby',
			'Herrljunga',
			'Gagnef',
			'Bjurholm',
			'Årjäng',
			'Anderstorp',
			'Älta',
			'Kiruna',
			'Burträsk',
			'Vilhelmina',
			'Viken',
			'Vellinge',
			'Ulricehamn',
			'Trosa',
			'Svedala',
			'Strömstad',
			'Stavsnäs',
			'Skattkärr',
			'Östervåla',
			'Myggenäs',
			'Mörbylånga',
			'Mjällby',
			'Lindö',
			'Lenhovda',
			'Kimstad',
			'Hyllinge',
			'Hölö',
			'Hällabrottet',
			'Häljarp',
			'Garphyttan',
			'Bjärnum',
			'Arboga',
			'Vindeln',
			'Västerås',
			'Trönninge',
			'Tanumshede',
			'Strömsnäsbruk',
			'Stockaryd',
			'Stångby',
			'Skepplanda',
			'Öckerö',
			'Nykvarn',
			'Nättraby',
			'Mörarp',
			'Mjölby',
			'Mariestad',
			'Mantorp',
			'Ljusne',
			'Jordbro',
			'Jonstorp',
			'Hallstahammar',
			'Enbacka',
			'Bua',
			'Braås',
			'Anderstorp',
			'Åhus',
			'Sävar',
			'Backa',
			'Älvsbyn',
			'Vårgårda',
			'Vännäsby',
			'Uddevalla',
			'Tullinge',
			'Trollhättan',
			'Trekanten',
			'Tingsryd',
			'Styrsö',
			'Slottsbron',
			'Simrishamn',
			'Rimforsa',
			'Löddeköpinge',
			'Långvik',
			'Lagan',
			'Jönköping',
			'Husum',
			'Gustavsberg',
			'Götene',
			'Gislaved',
			'Genarp',
			'Färila',
			'Bredbyn',
			'Malmberget',
			'Luleå',
			'Vintrosa',
			'Torshälla',
			'Svärdsjö',
			'Strängnäs',
			'Sollentuna',
			'Söderfors',
			'Smedby',
			'Skottsund',
			'Segeltorp',
			'Säter',
			'Saltsjöbaden',
			'Örebro',
			'Olstorp',
			'Odensbacken',
			'Nyköping',
			'Lammhult',
			'Insjön',
			'Ingelstad',
			'Hovsta',
			'Höganäs',
			'Hallstavik',
			'Förslöv',
			'Degerfors',
			'Bredaryd',
			'Borlänge',
			'Åsele',
			'Annelund',
			'Fisksätra',
			'Billdal',
			'Ekeby',
			'Storvik',
			'Pajala',
			'Virserum',
			'Vattholma',
			'Tvååker',
			'Tierp',
			'Södra Vi',
			'Skrea',
			'Skinnskatteberg',
			'Skara',
			'Siljansnäs',
			'Sandarne',
			'Nässjö',
			'Munkedal',
			'Mölnbo',
			'Hedesunda',
			'Hälleforsnäs',
			'Grebo',
			'Grästorp',
			'Deje',
			'Dalarö',
			'Broby',
			'Bollnäs',
			'Billingsfors',
			'Bergshamra',
			'Åsa',
			'Årsta',
			'Hortlax',
			'Vetlanda',
			'Veddige',
			'Stehag',
			'Romme',
			'Påarp',
			'Öregrund',
			'Mullsjö',
			'Mellerud',
			'Märsta',
			'Lövstalöt',
			'Lomma',
			'Lidingö',
			'Krokek',
			'Kopparmora',
			'Katrineholm',
			'Kallinge',
			'Jursla',
			'Fritsla',
			'Fränsta',
			'Falköping',
			'Eksjö',
			'Ekshärad',
			'Ekängen',
			'Edsbyn',
			'Brevik',
			'Åseda',
			'Årsunda',
			'Ankarsrum',
			'Västervik',
			'Varberg',
			'Tollarp',
			'Tenhult',
			'Stenhamra',
			'Sorsele',
			'Sävsjö',
			'Reftele',
			'Osby',
			'Nynäshamn',
			'Näsum',
			'Mönsterås',
			'Molkom',
			'Moheda',
			'Köpingebro',
			'Klintehamn',
			'Handen',
			'Gnosjö',
			'Frillesås',
			'Falun',
			'Övertorneå',
			'Holmsund',
			'Boden',
			'Ystad',
			'Visby',
			'Vi',
			'Veinge',
			'Torekov',
			'Svalöv',
			'Surahammar',
			'Sölvesborg',
			'Ösmo',
			'Norrtälje',
			'Njurundabommen',
			'Landskrona',
			'Kvidinge',
			'Kungälv',
			'Krokom',
			'Kristinehamn',
			'Kolbäck',
			'Knäred',
			'Karlstad',
			'Hultsfred',
			'Hölö',
			'Hittarp',
			'Grängesberg',
			'Glommen',
			'Forserum',
			'Fjälkinge',
			'Djursholm',
			'Bromölla',
			'Bräkne-Hoby',
			'Berg',
			'Avesta',
			'Älvdalen',
			'Skanör med Falsterbo',
			'Onsala',
			'Umeå',
			'Roknäs',
			'Gällivare',
			'Byske',
			'Vrigstad',
			'Vislanda',
			'Torslanda',
			'Sturkö',
			'Stenungsund',
			'Stenkullen',
			'Sollefteå',
			'Södra Sandby',
			'Söderhamn',
			'Smygehamn',
			'Skegrie',
			'Östhammar',
			'Önnestad',
			'Nordmaling',
			'Nacka',
			'Ljungskile',
			'Lindholmen',
			'Hököpinge',
			'Halmstad',
			'Fjärås kyrkby',
			'Fittja',
			'Enköping',
			'Ed',
			'Aneby',
			'Andalen',
			'Akalla',
			'Rolfs',
			'Haparanda',
			'Torsås',
			'Tibro',
			'Söderfors',
			'Rönninge',
			'Lit',
			'Laxå',
			'Jakobsberg',
			'Iggesund',
			'Hillerstorp',
			'Henån',
			'Hammerdal',
			'Hällefors',
			'Hällbybrunn',
			'Gnesta',
			'Gärsnäs',
			'Djurås',
			'Hoverberg',
			'Axvall',
			'Arnö',
			'Abborrberget',
			'Råneå',
			'Alunda',
			'Vejbystrand',
			'Töreboda',
			'Tärnsjö',
			'Svängsta',
			'Stora Höga',
			'Solna',
			'Södra Sandby',
			'Smedjebacken',
			'Österbymo',
			'Ornäs',
			'Nossebro',
			'Norra Åsum',
			'Matfors',
			'Markaryd',
			'Målilla',
			'Linghem',
			'Landvetter',
			'Jörlanda',
			'Horred',
			'Hörnefors',
			'Hönö',
			'Hjo',
			'Heby',
			'Haninge',
			'Hagfors',
			'Brunflo',
			'Brålanda',
			'Bollstabruk',
			'Bodafors',
			'Bjursås',
			'Båstad',
			'Älvängen',
			'Älmhult',
			'Robertsfors',
			'Kåge',
			'Ersmark',
			'Valla',
			'Södertälje',
			'Ryd',
			'Rottne',
			'Oxie',
			'Löberöd',
			'Kungsör',
			'Köpmanholmen',
			'Kalmar',
			'Hudiksvall',
			'Huddinge',
			'Hörby',
			'Hammarstrand',
			'Gullbrandstorp',
			'Gävle',
			'Enebyberg',
			'Bro',
			'Borås',
			'Bollebygd',
			'Bålsta',
			'Ärla',
			'Ängelholm',
			'Åmål',
			'Alby',
			'Täfteå',
			'Södra Sunderbyn',
			'Vinninga',
			'Valla',
			'Valje',
			'Tygelsjö',
			'Tormestorp',
			'Särö',
			'Ryd',
			'Ramnäs',
			'Örkelljunga',
			'Lund',
			'Lindesberg',
			'Lilla Edet',
			'Hässleholm',
			'Gånghester',
			'Forsbacka',
			'Finspång',
			'Ekeby-Almby',
			'Charlottenberg',
			'Bor',
			'Arvika',
			'Arvidsjaur',
			'Angered',
			'Åkersberga',
			'Röbäck',
			'Vålberg',
			'Svanesund',
			'Strömma',
			'Storvik',
			'Söråker',
			'Södermalm',
			'Söderköping',
			'Smålandsstenar',
			'Skultorp',
			'Skövde',
			'Sjömarken',
			'Sigtuna',
			'Rydsgård',
			'Rosersberg',
			'Ronneby',
			'Orsa',
			'Öjersjö',
			'Nyhammar',
			'Motala',
			'Morgongåva',
			'Mora',
			'Mölndal',
			'Laholm',
			'Klågerup',
			'Fyllinge',
			'Floby',
			'Falkenberg',
			'Diseröd',
			'Blomstermåla',
			'Björneborg',
			'Bengtsfors',
			'Ås',
			'Arbrå',
			'Åkarp',
			'Olstorp',
			'Marielund',
			'Skellefteå',
			'Obbola',
			'Norrfjärden',
			'Anneberg',
			'Vinslöv',
			'Vingåker',
			'Tjuvkil',
			'Svalsta',
			'Sundsbruk',
			'Stockvik',
			'Stallarholmen',
			'Skoghall',
			'Sandared',
			'Säffle',
			'Östersund',
			'Norsjö',
			'Malmköping',
			'Kristianstad',
			'Kramfors',
			'Källby',
			'Kågeröd',
			'Irsta',
			'Hanaskog',
			'Gränna',
			'Gamleby',
			'Dorotea',
			'Degeberga',
			'Bräcke',
			'Bergsjö',
			'Bergkvara',
			'Åtvidaberg',
			'Åstorp',
			'Alafors',
			'Ingared',
			'Rosvik',
			'Vaxholm',
			'Tidaholm',
			'Sävja',
			'Östermalm',
			'Ödsmål',
			'Munkfors',
			'Mellbystrand',
			'Mariefred',
			'Malung',
			'Linköping',
			'Kungsholmen',
			'Kisa',
			'Järna',
			'Hunnebostrand',
			'Hammar',
			'Gusum',
			'Gråbo',
			'Glumslöv',
			'Getinge',
			'Furulund',
			'Frövi',
			'Brevik',
			'Bjuv',
			'Bergby',
			'Barkarö',
			'Alunda',
			'Åby',
			'Gårdsten',
			'Sävast',
			'Vibble',
			'Valdemarsvik',
			'Valbo',
			'Uppsala',
			'Tomelilla',
			'Skutskär',
			'Skogstorp',
			'Rydebäck',
			'Pershagen',
			'Olofstorp',
			'Mariannelund',
			'Ludvika',
			'Långsele',
			'Kvicksund',
			'Kungsängen',
			'Kaxholmen',
			'Karlskoga',
			'Karlsborg',
			'Gantofta',
			'Fellingsbro',
			'Bromma',
			'Brastad',
			'Björboholm',
			'Bjärred',
			'Billeberga',
			'Arlöv',
			'Åled',
			'Åkarp',
			'Eriksbo',
			'Töre',
			'Tranås',
			'Torup',
			'Töcksfors',
			'Sveg',
			'Sturefors',
			'Stenstorp',
			'Smögen',
			'Skänninge',
			'Sätofta',
			'Sandviken',
			'Partille',
			'Oskarshamn',
			'Nybro',
			'Lönsboda',
			'Lödöse',
			'Ljungbyholm',
			'Kristdala',
			'Köping',
			'Järbo',
			'Irsta',
			'Hovmantorp',
			'Hedemora',
			'Hällevik',
			'Dalsjöfors',
			'Skelleftehamn',
			'Kalix',
			'Bergsviken',
			'Björklinge',
			'Vimmerby',
			'Vårsta',
			'Vargön',
			'Sunne',
			'Sundbyberg',
			'Storå',
			'Sjöbo',
			'Sala',
			'Rinkabyholm',
			'Perstorp',
			'Norrköping',
			'Nolby',
			'Marieholm',
			'Malå',
			'Lysekil',
			'Ljunghusen',
			'Lindome',
			'Lessebo',
			'Kvänum',
			'Kista',
			'Kilafors',
			'Karlshamn',
			'Jämjö',
			'Hofterup',
			'Hjärnarp',
			'Hestra',
			'Hallsberg',
			'Gamla Uppsala',
			'Fristad',
			'Färlöv',
			'Bara',
			'Ånge',
			'Enhagen-Ekbacken',
			'Åre',
			'Boliden',
			'Vikarbyn',
			'Veberöd',
			'Värnamo',
			'Tollarp1',
			'Tallboda',
			'Storuman',
			'Sösdala',
			'Mörtnäs',
			'Marieholm',
			'Malmbäck',
			'Lycksele',
			'Ljungbyhed',
			'Lidköping',
			'Kil',
			'Karlskrona',
			'Karlholmsbruk',
			'Järpen',
			'Höviksnäs',
			'Hova',
			'Hökåsen',
			'Hittarp',
			'Haverdal',
			'Hästveda',
			'Grycksbo',
			'Grums',
			'Dalby',
			'Brunna',
			'Asmundtorp',
			'Åsbro',
			'Arnö',
			'Lövgärdet',
			'Forsbacka',
			'Vansbro',
			'Vallda',
			'Täby',
			'Skurup',
			'Skärblacka',
			'Rydaholm',
			'Rimbo',
			'Råsunda',
			'Pålsboda',
			'Örsundsbro',
			'Knislinge',
			'Jonstorp',
			'Järvsö',
			'Insjön',
			'Hemse',
			'Härnösand',
			'Forsheda',
			'Ekenässjön',
			'Dingtuna',
			'Brunn',
			'Boxholm',
			'Askersund',
			'Ås',
			'Nolvik',
			'Tensta',
			'Viskafors',
			'Vårby',
			'Vallentuna',
			'Träslövsläge',
			'Stigtomta',
			'Staffanstorp',
			'Resarö',
			'Örbyhus',
			'Ljungsbro',
			'Limmared',
			'Långshyttan',
			'Landsbro',
			'Kode',
			'Höör',
			'Hofors',
			'Hindås',
			'Hemmesta',
			'Hasslö',
			'Djurö',
			'Boo',
			'Björkö',
			'Arjeplog',
			'Åmotfors',
			'Alvesta',
			'Alfta',
			'Västra Hagen',
			'Hammarkullen',
			'Marieberg',
			'Vagnhärad',
			'Trelleborg',
			'Timrå',
			'Sundsvall',
			'Strövelstorp',
			'Storvreta',
			'Sollebrunn',
			'Sköllersta',
			'Skillingaryd',
			'Skåre',
			'Rödeby',
			'Östra Ljungby',
			'Oskarström',
			'Olofström',
			'Ödeshög',
			'Odensjö',
			'Norberg',
			'Munka-Ljungby',
			'Mölnlycke',
			'Malmslätt',
			'Ljungby',
			'Kungshamn',
			'Krylbo',
			'Kolsva',
			'Jämshög',
			'Hyltebruk',
			'Hjuvik',
			'Göteborg',
			'Gnarp',
			'Forshaga',
			'Eslöv',
			'Ellös',
			'Ekerö',
			'Delsbo',
			'Borensberg',
			'Anderslöv',
			'Älvkarleby',
			'Alingsås',
			'Vi',
			'Björkskatan',
			'Överkalix',
			'Tyringe',
			'Taberg',
			'Stöpen',
			'Steninge',
			'Skärhamn',
			'Skåre',
			'Sjuntorp',
			'Sålanda',
			'Rävlanda',
			'Ödåkra',
			'Mölltorp',
			'Kinna',
			'Kållered',
			'Horndal',
			'Höllviken',
			'Harplinge',
			'Habo',
			'Göta',
			'Frösakull',
			'Flen',
			'Filipstad',
			'Emmaboda',
			'Dals Långed',
			'Brunna',
			'Bollmora',
			'Blentarp',
			'Bergeforsen',
			'Bankeryd',
			'Åkers Styckebruk',
			'Bureå',
			'Bergnäset',
			'Vikingstad',
			'Vasastan',
			'Upplands Väsby',
			'Hjärup',
			'Svärtinge',
			'Smedby',
			'Rindö',
			'Rättvik',
			'Österbybruk',
			'Ockelbo',
			'Nykvarn',
			'Mörrum',
			'Mockfjärd',
			'Ljusdal',
			'Länghem',
			'Kungsbacka',
			'Kumla',
			'Knivsta',
			'Klippan',
			'Huskvarna',
			'Helsingborg',
			'Gullspång',
			'Grillby',
			'Fjugesta',
			'Färjestaden',
			'Eriksberg',
			'Donsö',
			'Borgholm',
			'Bårslöv',
			'Bara',
			'Älmsta',
		],
	},
	Switzerland: {
		country: 'Switzerland',
		cities: [
			'Wangen an der Aare',
			'Uttwil',
			'Sulz',
			'Staufen',
			'Seon',
			'Schönenbuch',
			'Rhäzüns',
			'Promontogno',
			'Plan-les-Ouates',
			'Obersiggenthal',
			'Nunningen',
			'Mühlethurnen',
			'Montana',
			'Malvaglia',
			'Linden',
			'Leuk',
			'Les Brenets',
			'Krummenau',
			'Inwil',
			'Andelfingen',
			'Giubiasco',
			'Genolier',
			'Gachnang',
			'Füllinsdorf',
			'Frick',
			'Flums',
			'Eschenbach',
			'Emmetten',
			'Domat',
			'Deitingen',
			'Churwalden',
			'Chiasso',
			'Charmey',
			'Bösingen',
			'Benken',
			'Ballens',
			'Avenches',
			'Adliswil',
			'Affoltern / Oberdorf',
			'Kleinandelfingen',
			'Regensdorf / Obstgarten',
			'Grüt',
			'Männedorf / Dorfkern',
			'Zollikerberg',
			'Uster / Nossikon',
			'Zürich (Kreis 2) / Unter-Leimbach',
			'Seuzach Dorf',
			'Wimmis',
			'Weiningen',
			'Walzenhausen',
			'Wagenhausen',
			'Wädenswil',
			'Uster',
			'Untersiggenthal',
			'Turgi',
			'Turbenthal',
			'Thayngen',
			'Silenen',
			'Schwaderloch',
			'Salmsach',
			'Sâles',
			'Saint-Prex',
			'Rubigen',
			'Reconvilier',
			'Elsau-Räterschen / Räterschen',
			'Oron-la-Ville',
			'Madiswil',
			'Kriens',
			'Kirchberg',
			'Hohenrain',
			'Hittnau / Hittnau (Dorf)',
			'Herrliberg',
			'Fraubrunnen',
			'Fontainemelon',
			'Eschenz',
			'Ennetbürgen',
			'Engelberg',
			'Einsiedeln',
			'Celerina',
			'Burgdorf',
			'Buchs',
			'Brütten',
			'Bettingen',
			'Au',
			'Arzier',
			'Altishofen',
			'Aarau',
			'Adliswil / Hündli-Zopf',
			'Thalwil / Nord',
			'Gutenswil',
			'Oberwinterthur (Kreis 2) / Talacker',
			'Veltheim (Kreis 5) / Blumenau',
			'Dietikon / Vorstadt',
			'Dietikon / Guggenbühl',
			'Schlieren / Boden',
			'Zürich (Kreis 12) / Hirzenbach',
			'Zürich (Kreis 3) / Friesenberg',
			'Zürich (Kreis 9) / Altstetten',
			'Zürich (Kreis 1)',
			'Muralto',
			'Löhningen',
			'Lauffohr (Brugg)',
			'Blécherette',
			'Les Clées',
			'Zürich (Kreis 3) / Alt-Wiedikon',
			'Wangen',
			'Villaz-Saint-Pierre',
			'Steckborn',
			'Speicher',
			'Silvaplana',
			'Sigriswil',
			'Saint-Cergue',
			'Effretikon / Rikon',
			'Pura',
			'Pfyn',
			'Orsières',
			'Olten',
			'Wichtrach',
			'Neuenkirch',
			'Neudorf',
			'Muttenz',
			'Menznau',
			'Malans',
			'Konolfingen',
			'Huttwil',
			'Hildisrieden',
			'Greifensee',
			'Grand-Savagnier',
			'Grabs',
			'Flamatt',
			'Feldmeilen',
			'Erlen',
			'Embrach',
			'Courroux',
			'Carouge',
			'Alle',
			'Bülach / Gstückt',
			'Kloten / Hostrass',
			'Niederhasli',
			'Adliswil / Oberleimbach',
			'Adliswil / Tal',
			'Wädenswil / Büelen',
			'Wädenswil / Eichweid',
			'Wädenswil / Boller-Giessen',
			'Mönchaltorf / Dorf',
			'Uster / Kirch-Uster',
			'Seen (Kreis 3) / Waser',
			'Seen (Kreis 3) / Ganzenbühl',
			'Zürich (Kreis 1) / City',
			'Zürich (Kreis 9) / Albisrieden',
			'Oberurnen',
			'Oberhünigen',
			'Zollikofen',
			'Wohlen',
			'Zürich (Kreis 7) / Witikon',
			'Wil',
			'Vechigen',
			'Vallorbe',
			'Ehrendingen',
			'Trubschachen',
			'Trimbach',
			'Seuzach / Seuzach (Dorf)',
			'Schwellbrunn',
			'Schwarzenberg',
			'Schübelbach',
			'Schönenwerd',
			'Santa Maria in Calanca',
			'Sankt Antoni',
			'Samedan',
			'Saillon',
			'Sachseln',
			'Saanen',
			'Oberriet',
			'Niederhelfenschwil',
			'Neunkirch',
			'Mumpf',
			'Mogelsberg',
			'Mesocco',
			'Merenschwand',
			'Martigny-Ville',
			'Luterbach',
			'Lumino',
			'Les Geneveys-sur-Coffrane',
			'Küssnacht',
			'Interlaken',
			'Hilterfingen',
			'Frauenfeld',
			'Corsier',
			'Buus',
			'Bütschwil',
			'Biberist',
			'Bäretswil',
			'Appenzell',
			'Altstätten',
			'Altnau',
			'Aarwangen',
			'Benglen',
			'Wülflingen (Kreis 6) / Oberfeld',
			'Dietikon / Schönenwerd',
			'Schlieren / Zentrum',
			'Zürich (Kreis 12) / Auzelg',
			'Zürich (Kreis 4) / Langstrasse',
			'Pompaples',
			'Rueyres',
			'Oberhof',
			'Zollikon',
			'Vicques',
			'Unterägeri',
			'Uitikon',
			'Stettfurt',
			'Savigny',
			'Sankt Peterzell',
			'Rüschlikon',
			'Radelfingen',
			'Porrentruy',
			'Plaffeien',
			'Othmarsingen',
			'Oftringen',
			'Oberdiessbach',
			'Moudon',
			'Meisterschwanden',
			'Meinisberg',
			'Lyssach',
			'Luzern',
			'Lützelflüh',
			'Luchsingen',
			'Kerns',
			'Hochwald',
			'Egnach',
			'Dübendorf',
			'Dietlikon / Dietlikon (Dorf)',
			'Dietikon',
			'Courtételle',
			'Courfaivre',
			'Cornaux',
			'Castel San Pietro',
			'Bronschhofen',
			'Berg',
			'Affoltern am Albis',
			'Embrach / Kellersacker',
			'Au / Mittel-Dorf',
			'Kindhausen / Kindhausen (Dorf)',
			'Schlieren / Engstringerquartier',
			'Zürich (Kreis 11) / Schwandenholz',
			'Zürich (Kreis 10) / Rütihof',
			'Sulz',
			'Haag (Rheintal)',
			'Besenbüren',
			'Zunzgen',
			'Wyssachen',
			'Windisch',
			'Wiedlisbach',
			'Verbier',
			'Urnäsch',
			'Uhwiesen',
			'Sursee',
			'Sulgen',
			'Spreitenbach',
			'Sierre',
			'Seftigen',
			'Schiers',
			'Saxon',
			'Savièse',
			'Saint-Livres',
			'Rümlang',
			'Rüderswil',
			'Romont',
			'Riedholz',
			'Reinach',
			'Rapperswil',
			'Pfäffikon',
			'Penthalaz',
			'Oberschrot',
			'Oberbipp',
			'Nürensdorf',
			'Muotathal',
			'Mühleberg',
			'Moutier',
			'Märstetten-Dorf',
			'Le Chenit',
			'Knonau',
			'Kerzers',
			'Hermiswil',
			'Felsberg',
			'Eggiwil',
			'Chalais',
			'Buchrain',
			'Breíl',
			'Binningen',
			'Ballwil',
			'Aubonne',
			'Alterswil',
			'Agno',
			'Aesch',
			'Affoltern / Unterdorf',
			'Uetikon / Grossdorf',
			'Hinteregg',
			'Seen (Kreis 3) / Waldegg',
			'Ebmatingen',
			'Zürich (Kreis 7)',
			'Chéserex',
			'Surpierre',
			'Wynigen',
			'Welschenrohr',
			'Walenstadt',
			'Waldstatt',
			'Uzwil',
			'Illnau / Unter-Illnau',
			'Udligenswil',
			'Teufen',
			'Tegerfelden',
			'Tamins',
			'Zürich (Kreis 11) / Seebach',
			'Schötz',
			'Ruswil',
			'Russikon',
			'Ringgenberg',
			'Richterswil',
			'Rebstein',
			'Randogne',
			'Oberegg',
			'Nebikon',
			'Mosnang',
			'Montagny',
			'Menziken',
			'Meiringen',
			'Marsens',
			'Linthal',
			'Köniz',
			'Igis',
			'Hochdorf',
			'Fällanden',
			'Ebnat-Kappel',
			'Burgistein',
			'Brüttisellen',
			'Bellmund',
			'Baar',
			'Kloten / Kloten (Zentrum)',
			'Birchwil',
			'Niederglatt / Niederglatt (Dorfkern)',
			'Regensdorf / Hofacher-Geeren',
			'Regensdorf / Feldblumen-Riedthofstrasse',
			'Dübendorf / Wasserfurren',
			'Stadt Winterthur (Kreis 1) / Altstadt',
			'Stadt Winterthur (Kreis 1) / Heiligberg',
			'Mattenbach (Kreis 7) / Endliker',
			'Zürich (Kreis 5) / Gewerbeschule',
			'Crans-Montana',
			'Lancy',
			'Versoix',
			'Vals Platz',
			'Therwil',
			'Strengelbach',
			'Siebnen',
			'Schafisheim',
			'Schaffhausen',
			'Saint-Léonard',
			'Safenwil',
			'Pontresina',
			'Pailly',
			'Orvin',
			'Obergösgen',
			'Nänikon',
			'Muri',
			'Monthey',
			'Lütisburg',
			'Losone',
			'Lichtensteig',
			'Lausen',
			'La Roche',
			'Kirchberg',
			'Haslen',
			'Grossaffoltern',
			'Glarus',
			'Gimel',
			'Flawil',
			'Faido',
			'Estavayer-le-Lac',
			'Erlenbach',
			'Eiken',
			'Degersheim',
			"Château-d'Oex",
			'Bulle',
			'Birr',
			'Attalens',
			'Apples',
			'Adligenswil',
			'Bülach / Soligänter',
			'Rüti / Oberdorf',
			'Thalwil / Dorfkern',
			'Küsnacht / Goldbach',
			'Töss (Kreis 4) / Vorder-Dättnau',
			'Dietikon / Hofacker',
			'Oberengstringen / Rauchacher',
			'Stadt Winterthur (Kreis 1)',
			'Thalwil / See',
			'Aire-la-Ville',
			'Cuarnens',
			'Matran',
			'Wynau',
			'Wauwil',
			'Wängi',
			'Wetzikon / Unter-Wetzikon',
			'Turtmann',
			'Trachselwald',
			'Tafers',
			'Suhr',
			'Stalden',
			'Sankt Niklaus',
			'Reiden',
			'Puidoux',
			'Obfelden',
			'Oberbuchsiten',
			'Liestal',
			'Le Landeron',
			'Laupen',
			'Langrickenbach',
			'Kölliken',
			'Killwangen',
			'Kaisten',
			'Jenaz',
			'Himmelried',
			'Gonten',
			'Goldach',
			'Gipf-Oberfrick',
			'Froideville',
			'Freienbach',
			'Ersigen',
			'Eriswil',
			'Dürnten',
			'Confignon',
			'Chardonne',
			'Chancy',
			'Bolligen',
			'Birmenstorf',
			'Bassecourt',
			'Arlesheim',
			'Aarburg',
			'Kloten / Freienberg (Chanzler-Chlini Chaseren)',
			'Küsnacht / Schiedhalden',
			'Tagelswangen',
			'Greifensee / Pfisterhölzli',
			'Uster / Nieder-Uster',
			'Uster / Ober-Uster',
			'Oberwinterthur (Kreis 2) / Zinzikon',
			'Zürich (Kreis 5) / Escher-Wyss',
			'Saint Saphorin',
			'Gansingen',
			'Rüegsau',
			'Wattwil',
			'Waldenburg',
			'Villmergen',
			'Villars-sur-Ollon',
			'Trun',
			'Tesserete',
			'Steinen',
			'Seltisberg',
			'Schüpfen',
			'Sankt Gallen',
			'Rüeggisberg',
			'Romanshorn',
			'Renens',
			'Pampigny',
			'Wetzikon / Ober-Wetzikon',
			'Oberkirch',
			'Endingen',
			'Novazzano',
			'Morges',
			'Meggen',
			'Liesberg',
			'Leytron',
			'Küttigen',
			'Hettlingen',
			'Henggart',
			'Heiden',
			'Gampel',
			'Ependes',
			'Domdidier',
			'Cheseaux',
			'Cazis',
			'Bubendorf',
			'Bottighofen',
			'Blonay',
			'Biglen',
			'Arzo',
			'Altendorf',
			'Allschwil',
			'Meinier',
			'Thônex',
			'Neerach',
			'Oberglatt / Oberglatt (Dorfkern)',
			'Horgen / Horgen (Dorfkern)',
			'Erlenbach / links des Dorfbachs oberhalb Bahnlinie',
			'Dübendorf / Vogelquartier',
			'Nänikon / Nänikon (Dorfkern)',
			'Zürich (Kreis 12) / Schwamendingen-Mitte',
			'Dänikon',
			'Niederglatt',
			'Braggio',
			'Vuarrens',
			'Uebeschi',
			'Zernez',
			'Vernayaz',
			'Uetikon',
			'Stein',
			'Stansstad',
			'Stans',
			'Schöftland',
			'Schinznach Bad',
			'Rohr',
			'Riddes',
			'Reichenburg',
			'Perroy',
			'Oberdorf',
			'Neuchâtel',
			'Netstal',
			'Monte Carasso',
			'Mollens',
			'Magadino',
			'Leukerbad',
			'Lengnau',
			'Le Locle',
			'Langnau',
			'Grindelwald',
			'Gravesano',
			'Gams',
			'Flüelen',
			'Evolène',
			'Ettingen',
			'Erlach',
			'Dagmersellen',
			'Buttwil',
			'Bagnes',
			'Attiswil',
			'Adelboden',
			'Itingen',
			'Wädenswil / Hangenmoos',
			'Wangen',
			'Stadt Winterthur (Kreis 1) / Lind',
			'Kollbrunn / Kollbrunn (Dorfkern)',
			'Zürich (Kreis 12) / Saatlen',
			'Zürich (Kreis 6) / Unterstrass',
			'Zürich (Kreis 7) / Hottingen',
			'Thal',
			'Tägertschi',
			'Kirchleerau',
			'Auswil',
			'Weggis',
			'Waltenschwil',
			'Vionnaz',
			'Vevey',
			'Thundorf',
			'Täuffelen',
			'Tann',
			'Sissach',
			'Schinznach Dorf',
			'Sargans',
			'Salgesch',
			'Sainte-Croix',
			'Romanel-sur-Lausanne',
			'Rheinfelden',
			'Oensingen',
			'Niedergösgen',
			'Neuhausen',
			'Münchwilen',
			'Männedorf',
			'Luthern',
			'Ligornetto',
			'Lens',
			'Le Noirmont',
			'Läufelfingen',
			'Horgen',
			'Herzogenbuchsee',
			'Dardagny',
			'Court',
			'Cortaillod',
			'Camorino',
			'Buttisholz',
			'Boudry',
			'Belfaux',
			'Bavois',
			'Riehen',
			'Ecublens',
			'Richterswil / Dorfkern',
			'Uster / Gschwader',
			'Dietikon / Oberdorf',
			'Zürich (Kreis 1) / Lindenhof',
			'Zürich (Kreis 7) / Hirslanden',
			'Schwerzenbach',
			'Obermeilen',
			'Wettswil',
			'Worben',
			'Wollerau',
			'Trimmis',
			'Sementina',
			'Schmitten',
			'Schlieren',
			'Schattdorf',
			'St. Moritz',
			'Sankt Gallenkappel',
			'Saint-Aubin-Sauges',
			'Rupperswil',
			'Rudolfstetten',
			'Pfäffikon',
			'Oberhelfenschwil',
			'Naters',
			'Mollis',
			'Lungern',
			'Laupersdorf',
			'Langenthal',
			'Küsnacht',
			'Kollbrunn',
			'Kleinlützel',
			'Kirchlindach',
			'Kappelen',
			'Ingenbohl',
			'Homburg',
			'Oberwinterthur (Kreis 2) / Hegi',
			'Hägglingen',
			'Grandson',
			'Gossau',
			'Genève',
			'Fiesch',
			'Eichberg',
			'Effretikon',
			'Diessenhofen',
			'Chavannes',
			'Boniswil',
			'Biasca',
			'Beckenried',
			'Ascona',
			'Arosa',
			'Chêne-Bougeries',
			'Wallisellen / Wallisellen-Ost',
			'Binzikon',
			'Thalwil / Berg',
			'Küsnacht / Itschnach',
			'Dübendorf / Sonnenberg',
			'Seuzach Dorf / Breite-Weid',
			'Wülflingen (Kreis 6) / Härti',
			'Zürich (Kreis 2) / Mittel-Leimbach',
			'Zürich (Kreis 7) / Fluntern',
			'Zürich (Kreis 8) / Seefeld',
			'Zürich (Kreis 12)',
			'Elsau-Räterschen',
			'Chevilly',
			'Vuisternens-devant-Romont',
			'Servion',
			'Zwingen',
			'Zofingen',
			'Wolfwil',
			'Wohlen',
			'Villeneuve',
			'Vernier',
			'Ueberstorf',
			'Tramelan',
			'Stettlen',
			'Schüpfheim',
			'Rue',
			'Riva San Vitale',
			'Rickenbach bei Wil',
			'Praroman',
			'Péry',
			'Les Ponts-de-Martel',
			'Lenk',
			'Leibstadt',
			'Krauchthal',
			'Koppigen',
			'Klosters Serneus',
			'Jona',
			'Ilanz',
			'Ibach',
			'Hinwil',
			'Hindelbank',
			'Heimberg',
			'Gränichen',
			'Grächen',
			'Glovelier',
			'Giffers',
			'Geuensee',
			'Fully',
			'Flurlingen',
			'Corminboeuf',
			'Cadro',
			'Brügg',
			'Bremgarten',
			'Bonaduz',
			'Bauen',
			'Bätterkinden',
			'Baltschieder',
			'Arth',
			'Andermatt',
			'Puplinge',
			'Obfelden / Oberlunnern',
			'Obfelden / Toussen',
			'Wettswil / Ausser-Dorf',
			'Bülach / Seematt',
			'Glattbrugg / Wydacker/Bettacker/Lättenwiesen',
			'Dällikon / Dällikon (Dorf)',
			'Kilchberg / Kilchberg (Dorfkern)',
			'Richterswil / Burghalde',
			'Pfäffikon / Pfäffikon (Dorfkern)',
			'Binz',
			'Schwerzenbach / Blatten',
			'Töss (Kreis 4) / Schlosstal',
			'Wülflingen (Kreis 6) / Lindenplatz',
			'Zürich (Kreis 1) / Rathaus',
			'Rochefort',
			'Zweisimmen',
			'Wittenbach',
			'Weisslingen',
			'Vorderthal',
			'Verscio',
			'Teufenthal',
			'Sennwald',
			'Schenkon',
			'Samstagern',
			'Saint-Blaise',
			'Saas-Fee',
			'Rüthi',
			'Rehetobel',
			'Ramsen',
			'Oberlunkhofen',
			'Nidau',
			'Lotzwil',
			'La Tour-de-Trême',
			'Zürich (Kreis 10) / Höngg',
			'Gstaad',
			'Grône',
			'Grenchen',
			'Gland',
			'Frenkendorf',
			'Fleurier',
			'Ermatingen',
			'Courtelary',
			'Courgevaux',
			'Cauco',
			'Buchholterberg',
			'Brislach',
			'Bonstetten',
			'Bière',
			'Bettlach',
			'Berikon',
			'Belp',
			'Anières',
			'Amden',
			'Sonnhalde',
			'Tann / Tann (Dorfkern)',
			'Wetzikon / Kempten',
			'Gattikon',
			'Seen (Kreis 3) / Büelwiesen',
			'Zürich (Kreis 5)',
			'Zürich (Kreis 10)',
			'Rottenschwil',
			'Bas-Vully',
			'Corcelles-le-Jorat',
			'Zürich',
			'Zuoz',
			'Wikon',
			'Varen',
			'Vandœuvres',
			'Utzenstorf',
			'Unterlunkhofen',
			'Tuggen',
			'Subingen',
			'Stabio',
			'Spiez',
			'Sevelen',
			'Schmerikon',
			'Röschenz',
			'Poschiavo',
			'Pfeffingen',
			'Opfikon',
			'Ollon',
			'Oberwil',
			'Muri',
			'Mörschwil',
			'Mézières',
			'Meierskappel',
			'Martigny-Combe',
			'Lenzburg',
			'La Neuveville',
			'La Chaux-de-Fonds',
			'Kloten',
			'Hombrechtikon',
			'Hasle',
			'Gunzwil',
			'Grellingen',
			'Gossau',
			'Goldau',
			'Glattfelden',
			'Flims',
			'Ebikon',
			'Dielsdorf',
			'Dallenwil',
			'Dachsen',
			'Corgémont',
			'Brissago',
			'Bilten',
			'Bévilard',
			'Bernex',
			'Begnins',
			'Arvigo',
			'Kloten / Geissberg',
			'Mühlehalde',
			'Richterswil / Richterswil (Dorfkern)',
			'Au / Unter-Dorf',
			'Zürich (Kreis 11) / Affoltern',
			'Zürich (Kreis 2) / Enge',
			'Zürich (Kreis 4) / Werd',
			'Steinmaur',
			'Gockhausen',
			'Tenniken',
			'Aeugst am Albis',
			'Cheseaux-Noréaz',
			'Zermatt',
			'Willisau',
			'Werthenstein',
			'Walkringen',
			'Visp',
			'Uznach',
			'Triengen',
			'Travers',
			'Thusis',
			'Steffisburg',
			'Sottens',
			'Selzach',
			'Seedorf',
			'Root',
			'Rechthalten',
			'Raron',
			'Pully',
			'Urdorf',
			'Oberrieden',
			'Meyrin',
			'Marthalen',
			'Magden',
			'Locarno',
			'Laax',
			'Kreuzlingen',
			'Gorgier',
			'Fulenbach',
			'Founex',
			'Elgg',
			'Dombresson',
			'Diemtigen',
			'Cugnasco',
			'Comano',
			'Bürglen',
			'Buchs',
			'Breitenbach',
			'Beringen',
			'Andwil',
			'Aeschi b. Spiez',
			'Affoltern / Hasenbüel',
			'Embrach / Embrach (Dorfkern)',
			'Kloten / Horainli',
			'Kloten / Balsberg',
			'Stadt Winterthur (Kreis 1) / Tössfeld',
			'Oberengstringen / Sonnenberg',
			'Schlieren / Kamp',
			'Zürich (Kreis 8) / Weinegg',
			'Wülflingen (Kreis 6)',
			'Seen (Kreis 3)',
			'Oberwinterthur (Kreis 2)',
			'Les Avanchets',
			'Berolle',
			'Savosa',
			'Kleinbösingen',
			'Niedermuhlern',
			'Zizers',
			'Weinfelden',
			'Unterkulm',
			'Sumiswald',
			'Staffelbach',
			'Sorengo',
			'Sempach',
			'Sarmenstorf',
			'Sankt Stephan',
			'Saint-Imier',
			'Rolle',
			'Regensdorf',
			'Pieterlen',
			'Payerne',
			'Oberburg',
			'Nyon',
			'Möhlin',
			'Marly',
			'Lutry',
			'Littau',
			'Le Vaud',
			'Les Breuleux',
			"L'Abbaye",
			'Kriegstetten',
			'Jonen',
			'Goldingen',
			'Gelterkinden',
			'Frutigen',
			'Fontenais',
			'Fischingen',
			'Coppet',
			'Cham',
			'Canobbio',
			'Boncourt',
			'Birsfelden',
			'Bevaix',
			'Beatenberg',
			'Bassersdorf',
			'Bachenbülach',
			'Amriswil',
			'Hochfelden',
			'Höri',
			'Langnau / Vitaquartier',
			'Effretikon / Rappenhalde-Bannhalde',
			'Greifensee / Müllerwis / Seilerwis',
			'Hegnau / Dammboden-Grindel',
			'Veltheim (Kreis 5) / Rosenberg',
			'Wülflingen (Kreis 6) / Niederfeld',
			'Zürich (Kreis 4) / Hard',
			'Veyras',
			'Biberstein',
			'Mauraz',
			'Moiry',
			'Penthéréaz',
			'Wolhusen',
			'Wilderswil',
			'Wetzikon',
			'Walchwil',
			'Villnachern',
			'Vex',
			'Unteriberg',
			'Troistorrents',
			'Tiefencastel',
			'Tanay',
			'Tägerwilen',
			'Steinhausen',
			'Rotkreuz',
			'Rorschach',
			'Pratteln',
			'Münchenstein',
			'Müllheim',
			'Mönchaltorf',
			'Melide',
			'Lauterbrunnen',
			'Lachen',
			'Knutwil',
			'Kilchberg',
			'Kaltbrunn',
			'Jegenstorf',
			'Hérémence',
			'Grancy',
			'Gontenschwil',
			'Giswil',
			'Gerlafingen',
			'Gebenstorf',
			'Flühli',
			'Feuerthalen',
			'Ettiswil',
			'Escholzmatt',
			'Egg',
			'Egerkingen',
			'Delémont',
			'Claro',
			'Bellevue',
			'Bauma',
			'Avry-sur-Matran',
			'Zürich (Kreis 4) / Aussersihl',
			'Attinghausen',
			'Affeltrangen',
			'Acquarossa',
			'Glattbrugg / Rohr/Platten-Balsberg',
			'Hadlikon',
			'Küsnacht / Heslibach',
			'Hegnau / Sunnebüel-Eich',
			'Seen (Kreis 3) / Oberseen',
			'Dietikon / Almend',
			'Schlieren / Freiestrasse',
			'Zürich (Kreis 6)',
			'Langnau am Albis',
			'Münchenwiler',
			'Chavannes-le-Veyron',
			'Rümligen',
			'Bad Zurzach',
			'Zumikon',
			'Zell',
			'Worb',
			'Wittnau',
			'Wahlen',
			'Thun',
			'Sins',
			'Scuol',
			'Saignelégier',
			'Riggisberg',
			'Rafz',
			'Pfaffnau',
			'Oetwil',
			'Neftenbach / Dorf Neftenbach',
			'Mettmenstetten',
			'Mendrisio',
			'Melano',
			'Malleray',
			'Lostorf',
			'Leysin',
			'Hüttwilen',
			'Hergiswil',
			'Heimiswil',
			'Evionnaz',
			'Ennenda',
			'Ecublens',
			'Dornach',
			'Cully',
			'Cadempino',
			'Bürglen',
			'Bubikon',
			'Bottens',
			'Birmensdorf',
			'Arisdorf',
			'Kloten / Spitz',
			'Adliswil / Sood',
			'Schwerzenbach / Chimli',
			'Töss (Kreis 4) / Eichliacker',
			'Mattenbach (Kreis 7) / Gutschick',
			'Zürich (Kreis 2) / Wollishofen',
			'Hegnau',
			'Zürich (Kreis 2)',
			'Troinex',
			'Busswil bei Melchnau',
			'Wilchingen',
			'Weesen',
			'Wald',
			'Vouvry',
			'Uerikon',
			'Toffen',
			'Seengen',
			'Schindellegi',
			'Sarnen',
			'Sankt Margrethen',
			'Roggwil',
			'Quarten',
			'Nottwil',
			'Erlinsbach',
			'Morbio Inferiore',
			'Melchnau',
			'Lucens',
			'Lodrino',
			'Lauperswil',
			'Laufen',
			'Langnau / Langnau (Dorf)',
			'St-Légier-La Chiésaz',
			'Koblenz',
			'Pfäffikon / Irgenhausen',
			'Herisau',
			'Gordola',
			'Gommiswald',
			'Fribourg',
			'Epalinges',
			'Emmen',
			'Disentis',
			'Chavornay',
			'Chamoson',
			'Auw',
			'Grossacker/Opfikon',
			'Horgen / Oberdorf',
			'Kilchberg / Bächler-Stocken',
			'Thalwil / Süd',
			'Mattenbach (Kreis 7) / Deutweg',
			'Oberengstringen / Zentrum',
			'Zürich (Kreis 11)',
			'Känerkinden',
			'Bünzen',
			'Saubraz',
			'Ferreyres',
			'Bedigliora',
			'Walliswil bei Niederbipp',
			'Wolfenschiessen',
			'Wildhaus',
			'Wiesendangen / Wiesendangen (Dorf)',
			'Vitznau',
			'Viganello',
			'Unterseen',
			'Trogen',
			'Stäfa',
			'Sitten',
			'Rorbas',
			'Rohrbach',
			'Reigoldswil',
			'Prilly',
			'Palézieux',
			'Oberrüti',
			'Niederurnen',
			'Niederrohrdorf',
			'Neuenhof',
			'Muolen',
			'Mauensee',
			'Massagno',
			'Le Mont-sur-Lausanne',
			'Lausanne',
			'Kehrsatz',
			'Jonschwil',
			'Häggenschwil',
			'Gais',
			'Fehraltorf',
			'Eschlikon',
			'Davos',
			'Cossonay',
			'Conthey',
			'Chur',
			"Chermignon-d'en Haut",
			'Champéry',
			'Brugg',
			'Boltigen',
			'Beromünster',
			'Bern',
			'Bäriswil',
			'Arbon',
			'Affoltern / Sonnenberg',
			'Dietlikon / Eichwiesen',
			'Wallisellen / Wallisellen-West',
			'Zürich (Kreis 9)',
			'Zürich (Kreis 8)',
			'Givisiez',
			'Yvonand',
			'Würenlingen',
			'Wila',
			'Wigoltingen',
			'Waldkirch',
			'Vordemwald',
			'Stein am Rhein',
			'Solothurn',
			'Schwanden',
			'Scharans',
			'Schänis',
			'Rüschegg',
			'Roggwil',
			'Riaz',
			'Pregassona',
			'Onex',
			'Oberuzwil',
			'Niederbipp',
			'Münsterlingen',
			'Montreux',
			'Minusio',
			'Meilen',
			'Matzingen',
			'Lenzerheide',
			'Kaiseraugst',
			'Horn',
			'Hägendorf',
			'Le Grand-Saconnex',
			'Glattbrugg',
			'Erlenbach im Simmental',
			'Cugy',
			'Chexbres',
			'Caux',
			'Cadenazzo',
			'Brittnau',
			'Brienz',
			'Bowil',
			'Bex',
			'Belmont-sur-Lausanne',
			'Ayent',
			'Ardon',
			'Alpnach',
			'Aigle',
			'Adliswil / Sonnenberg',
			'Wädenswil / Leihof-Mühlebach',
			'Küsnacht / Dorf',
			'Dorf',
			'Esslingen',
			'Aesch',
			'Elgg / Städtchen und Umgebung',
			'Schlieren / Spital',
			'Zürich (Kreis 6) / Oberstrass',
			'Hausen',
			'Oberrohrdorf',
			'Zug',
			'Vétroz',
			'Unterengstringen',
			'Uetendorf',
			'Trélex',
			'Thierachern',
			'Schleitheim',
			'Salvan',
			'Rüti',
			'Rheineck',
			'Pfaffhausen',
			'Otelfingen',
			'Ostermundigen',
			'Orbe',
			'Oberglatt',
			'Niederbüren',
			'Näfels',
			'Messen',
			'Matten',
			'Malters',
			'Luzein',
			'Lugano',
			'Les Bois',
			'La Sarraz',
			'Klingnau',
			'Güttingen',
			'Frauenkappelen',
			'Feusisberg',
			'Erstfeld',
			'Entlebuch',
			'Derendingen',
			'Densbüren',
			'Därligen',
			'Cressier',
			'Chézard-Saint-Martin',
			'Buochs',
			'Biel/Bienne',
			'Auvernier',
			'Kloten / Rütlen',
			'Wädenswil / Untermosen-Fuhr',
			'Préverenges',
			'La Chaux',
			'Rüti bei Lyssach',
			'Zuchwil',
			'Würenlos',
			'Wattenwil',
			'Wallisellen',
			'Vuadens',
			'Villigen',
			'Veyrier',
			'Thalwil',
			'Sirnach',
			'Seedorf',
			'Seeberg',
			'Sattel',
			'Saas-Grund',
			'Rothenthurm',
			'Peseux',
			'Paudex',
			'Ormalingen',
			'Oberentfelden',
			'Niederlenz',
			'Murgenthal',
			'Lavertezzo',
			'Kallnach',
			'Hitzkirch',
			'Gryon',
			'Gruyères',
			'Evilard',
			'Echallens',
			'Couvet',
			'Chippis',
			'Charrat',
			'Caslano',
			'Buseno',
			'Bodio',
			'Balsthal',
			'Baden',
			'Schachen',
			'Hedingen',
			'Hofstetten',
			'Rüti / Dorfzentrum, Südl. Teil',
			'Adliswil / Adliswil (Stadtkern)',
			'Horgen / Allmend',
			'Oberrieden / Mitte',
			'Effretikon / Watt',
			'Stadt Winterthur (Kreis 1) / Brühlberg',
			'Dietikon / Kreuzacker',
			'Urdorf / Moos',
			'Zürich (Kreis 10) / Wipkingen',
			'Zürich (Kreis 3)',
			'Geroldswil',
			'Wolfhausen',
			'Zäziwil',
			'Villars-sur-Glâne',
			'Urtenen',
			'Treyvaux',
			'Töss (Kreis 4)',
			'Schwyz',
			'Satigny',
			'Saint-Maurice',
			'Rothrist',
			'Rodersdorf',
			'Oberengstringen',
			'Müntschemier',
			'Münchenbuchsee',
			'Mellingen',
			'Maienfeld',
			'La Tour-de-Peilz',
			'Jussy',
			'Ins',
			'Hölstein',
			'Hausen am Albis / Hausen (Dorf)',
			'Hallau',
			'Grolley',
			'Grimisuat',
			'Gottlieben',
			'Egliswil',
			'Dottikon',
			'Courgenay',
			'Collombey',
			'Brig',
			'Bönigen',
			'Bioggio',
			'Basel',
			'Bad Ragaz',
			'Aristau',
			'Wettswil / Wettswil (Dorf)',
			'Erlenbach / rechts des Dorfbachs oberhalb Bahnlinie',
			'Männedorf / Ausserfeld',
			'Mattenbach (Kreis 7)',
			'Langendorf',
			'Hohentannen',
			'Zuzwil',
			'Winterthur',
			'Wegenstetten',
			'Visperterminen',
			'Untervaz',
			'Uerkheim',
			'Tavannes',
			'Römerswil',
			'Prangins',
			'Pfungen',
			'Ottenbach',
			'Zürich (Kreis 11) / Oerlikon',
			'Murten/Morat',
			'Leuzigen',
			'Langenbruck',
			'Landquart',
			'Illnau',
			'Hünenberg',
			'Gunzgen',
			'Ganterschwil',
			'Ferenbalm',
			'Eich',
			'Eggersriet',
			'Dürrenäsch',
			'Düdingen',
			'Diepoldsau',
			'Diegten',
			'Crissier',
			'Chêne-Bourg',
			'Châtel-Saint-Denis',
			'Bussigny',
			'Büsserach',
			'Büron',
			'Brusio',
			'Bottmingen',
			'Basse-Nendaz',
			'Balgach',
			'Balerna',
			'Aarberg',
			'Aadorf',
			'Freienstein',
			'Kloten / Holberg',
			'Wallisellen / Rieden',
			'Wetzikon / Robenhausen',
			'Oberrieden / Berg',
			'Wädenswil / Dorf (Wädenswil)',
			'Stadt Winterthur (Kreis 1) / Neuwiesen',
			'Urdorf / Oberurdorf',
			'Zürich (Kreis 8) / Mühlebach',
			'Seglingen',
			'Stetten',
			'Saint-George',
			'Donat',
			'Yverdon-les-Bains',
			'Wölflinswil',
			'Wettingen',
			'Volketswil / Volketswil (Dorf)',
			'Veltheim (Kreis 5)',
			'Veltheim',
			'Uttigen',
			'Trub',
			'Signau',
			'La Punt Chamues-ch',
			'Orpund',
			'Münsingen',
			'Muhen',
			'Montagnola',
			'Menzingen',
			'Matzendorf',
			'Lyss',
			'Laufenburg',
			'Künten',
			'Kandersteg',
			'Horw',
			'Hinterrhein',
			'Heitenried',
			'Guggisberg',
			'Grüsch',
			'Grosswangen',
			'Gersau',
			'Dürrenroth',
			'Courrendlin',
			'Cevio',
			'Cernier',
			'Bülach',
			'Bühler',
			'Broc',
			'Blumenstein',
			'Bellinzona',
			'Bäch',
			'Au',
			'Arch',
			'Altdorf',
			'Airolo',
			'Sellenbüren',
			'Oberglatt / Bahnhofquartier',
			'Rümlang / Rümlang (Dorfkern)',
			'Rüti / Westlicher Dorfteil',
			'Horgen / Scheller-Stockerstrasse',
			'Dübendorf / Kunklerstrasse',
			'Oberwinterthur (Kreis 2) / Guggenbühl',
			'Urdorf / Bodenfeld',
			'Zürich (Kreis 3) / Sihlfeld',
			'Jongny',
			'Orny',
			'Marin-Epagnier',
			'Oberkulm',
			'Pont-la-Ville',
			'Valeyres-sous-Montagny',
		],
	},
	'Syrian Arab Republic': {
		country: 'Syrian Arab Republic',
		cities: [
			'Rasm al Ḩarmal',
			'Khirbat Tīn Nūr',
			'Ghabāghib',
			'Aḑ Ḑumayr',
			'Dā‘il',
			'Mismīyah',
			'An Nāşirah',
			'Al Jawādīyah',
			'Shaykh al Ḩadīd',
			'Saraqib',
			'Şabbūrah',
			'Mazra‘at Bayt Jinn',
			'Idlib',
			'‘Ayn ash Sharqīyah',
			'Al Mayādīn',
			'Ad Dālīyah',
			'Māri‘',
			'Ra’s al Khashūfah',
			'Ḩārim',
			'Ath Thawrah',
			'Ash Shajarah',
			'Al Ḩawāsh',
			'Ra’s al ‘Ayn',
			'Al Qaḩţānīyah',
			'Qaţanā',
			'Ma‘dān',
			'Jawbat Burghāl',
			'Ad Duraykīsh',
			'Buşrá ash Shām',
			'As Sūsah',
			'Ash Shaykh Badr',
			'Al ‘Annāzah',
			'Ālbū Kamāl',
			'Ţayyibat al Imām',
			'Tallkalakh',
			'Khirbat Ghazālah',
			'Kafr Şaghīr',
			'Al Jarnīyah',
			'Jarābulus',
			'Jindayris',
			'Ḩarastā',
			'I‘zāz',
			'Latakia',
			'Ad Dīmās',
			'‘Ayn an Nasr',
			'Subaykhān',
			'Kafr Zaytā',
			'Duwayr Raslān',
			'Bulbul',
			'As Sīsnīyah',
			'Al Kiswah',
			'Al ‘Ashārah',
			'Wādī al ‘Uyūn',
			'Salqīn',
			'Mas‘adah',
			'Al Jīzah',
			'Darkūsh',
			'Al Mālikīyah',
			'‘Arīshah',
			'Şāfītā',
			'Qārah',
			'Mukharram al Fawqānī',
			'Kafr Lāhā',
			'Jayrūd',
			'Ḩammām Wāşil',
			'Iḩsim',
			'Nāḩiyat as Sab‘ Biyār',
			'Şaydnāyā',
			'Kabbasin',
			'Ar Rawḑah',
			'Al Ḩirāk',
			'Abū Qalqal',
			'‘Ayn Ḩalāqīm',
			'Şlinfah',
			'Maskanah',
			'Kurnāz',
			'Al Qabw',
			'Kafr Baţnā',
			'‘Ayn al Bayḑā',
			'Arīḩā',
			'Al Qadmūs',
			'Akhtarīn',
			'Al Ḩajar al Aswad',
			'Al Manşūrah',
			'Kafr Mūsá Al-Hmidiya',
			'Tall Rif‘at',
			'Tall Ḩamīs',
			'Tall aḑ Ḑamān',
			'Taftanāz',
			'Tadmur',
			'Jāsim',
			'Ma‘arrat an Nu‘mān',
			'Ḩalfāyā',
			'‘Assāl al Ward',
			'Al Qardāḩah',
			'Hajīn',
			'Qenterê',
			'Shīn',
			'Ar Riqāmā',
			'Manbij',
			'Malaḩ',
			'Al Ma‘baţlī',
			'Khanāşir',
			'Ḩarbinafsah',
			'As Si‘in',
			'‘Afrīn',
			'Abū az̧ Z̧uhūr',
			'Mūḩ Ḩasan',
			'Şirrīn ash Shamālīyah',
			'Sinjār',
			'Şadad',
			'Al Qamşīyah',
			'‘Ayn at Tīnah',
			'At Tibnī',
			'As Sawdā',
			'Al Qurayyā',
			'Al Qaryatayn',
			'Al Ghizlānīyah',
			'Al Bāriqīyah',
			'Shuyūkh Taḩtānī',
			'Qadsayyā',
			'Al Muzayrīb',
			'Al Jalā’',
			'Bdāmā',
			'Bāniyās',
			'Aş Şanamayn',
			'Al Buşayrah',
			'Al Bahlūlīyah',
			'Tasīl',
			'Şaḩnāyā',
			'Kuwayris Sharqī',
			'Khān Shaykhūn',
			'Aleppo',
			'Dhībbīn',
			'Babīlā',
			'At Tall',
			'Ar Raqqah',
			'Al Qāmishlī',
			'Tremseh',
			'‘Arīqah',
			'‘Uqayribāt',
			'Tall Salḩab',
			'Şūrān',
			'Sirghāyā',
			'Maḑāyā',
			'Kinnsibbā',
			'Khushām',
			'Kafr Takhārīm',
			'Junaynat Raslān',
			'Damascus',
			'Brummānat al Mashāyikh',
			'Bi’r al Ḩulw al Wardīyah',
			'‘Awaj',
			'As Sukhnah',
			'As Sabkhah',
			'Al ‘Arīmah',
			'Maşyāf',
			'Mashtá al Ḩulw',
			'Qabbāsīn',
			'Dayr Ḩāfir',
			'Dayr ‘Aţīyah',
			'Ad Darbāsīyah',
			'Al Quşayr',
			'Al Qunayţirah',
			'Al Mulayḩah',
			'Al Jānūdīyah',
			'As Sab‘ Biyār',
			'Rabī‘ah',
			'Kafranbel',
			'Jinnīyah',
			'Ḩisyā’',
			'Homs',
			'Ḩadīdah',
			'Aţ Ţawāḩīn',
			'At Tamāni‘ah',
			'Ar Rastan',
			'Al Atārib',
			'Tālīn',
			'Şalkhad',
			'Rankūs',
			'Al Furqlus',
			'Deir ez-Zor',
			'Dārat ‘Izzah',
			'Sabbah',
			'‘Ayn Shiqāq',
			'Al Ghanţū',
			'Al Quţayfah',
			'Al Ḩaffah',
			'Ad Dānā',
			'Bayt Yāshūţ',
			'Ţafas',
			'Şūrān',
			'Qal‘at al Maḑīq',
			'Muḩambal',
			'Khān Arnabah',
			'Al Karīmah',
			'Inkhil',
			'Dārayyā',
			'Buq‘ātā',
			'Banān',
			'As-Suwayda',
			'Al Ḩamrā’',
			'Al Bāb',
			'Şalākhid',
			'Qasţal Ma‘āf',
			'Kassab',
			'Ar Rā‘ī',
			'Az Ziyārah',
			'‘Ayn al Fījah',
			'Aş Şafşāfah',
			'Al Ḩawl',
			'Al Kasrah',
			'Al Karāmah',
			'Nubl',
			'Mūrak',
			'Jaramānā',
			'Barrī ash Sharqī',
			'Az Zabadānī',
			'‘Ayn al ‘Arab',
			'Al Ghāriyah',
			'Aş Şūrah aş Şaghīrah',
			'Tādif',
			'Markadah',
			'Khirbat al Ma‘azzah',
			'Jubb al Jarrāḩ',
			'Jablah',
			'Izra‘',
			'‘Irbīn',
			'Dhībān',
			'Yabrūd',
			'Al Quţaylibīyah',
			'Al Muzayri‘ah',
			'Muḩradah',
			'Jubb Ramlah',
			'Ḩarrān al ‘Awāmīd',
			'Az Zarbah',
			'Kaff al-Jaa',
			'Tall Tamr',
			'Talldaww',
			'Tallbīsah',
			'Shaqqā',
			'Shahbā',
			'Sa‘sa‘',
			'Sulūk',
			'Jisr ash Shughūr',
			'Al Fākhūrah',
			'Dar‘ā',
			'Binnish',
			'Al Mazra‘ah',
			'Arwād',
			'Al Ḩasakah',
			'Al Ḩājib',
			'Sharān',
			'As Salamīyah',
			'Rājū',
			'Ma‘lūlā',
			'Al Khafsah',
			'Ḩimmīn',
			'Dūmā',
			'Dayr al ‘Aşāfīr',
			'‘Ayn ‘Īsá',
			'Ash Shaddādah',
			'An Nashābīyah',
			'Al Ya‘rubīyah',
			'Ţarţūs',
			'Tall Abyaḑ',
			'As Suqaylibīyah',
			'Shaykh Miskīn',
			'Sarmīn',
			'Qūrqīnā',
			'Mahīn',
			'Ma‘arratmişrīn',
			'Al Ghandūrah',
			'Ḩīsh',
			'Ḩuraytān',
			'Al Ḩāḑir',
			'As Safīrah',
			'Ar Ruḩaybah',
			'Armanāz',
			'An Nabk',
			'‘Āmūdā',
			'Al Musayfirah',
			'Shaţḩah',
			'Nawá',
			'Ḩarf al Musaytirah',
			'Ḩamāh',
			'Batabo',
			'Al Mushannaf',
			'Al Hinādī',
			'Al Ḩamīdīyah',
		],
	},
	'Taiwan, China': {
		country: 'Taiwan, China',
		cities: [
			'Yingge',
			'Pizitou',
			'Pinglin',
			'Yilan',
			'Xizhi',
			'Hengchun',
			'Yuanlin',
			'Fengyuan',
			'Taibao',
			'Bade',
			'Chang-hua',
			'Tainan',
			'Hsinchu',
			'Jincheng',
			'Miaoli',
			'Taitung',
			'Shulin',
			'Magong',
			'Wufeng',
			'Taipei',
			'Zhubei',
			'Taoyuan',
			'Zhongxing New Village',
			'Fengshan',
			'Shimen',
			'Kaohsiung',
			'Hualien City',
			'Yujing',
			'Sanzhi',
			'Pingtung',
			'Donggang',
			'Taichung',
			'Lugu',
			'Banqiao',
			'Puli',
			'Jiufen',
			'Neihu',
			'Yongkang',
			'Keelung',
			'Xindian',
			'Jiayi Shi',
			'Nangan',
			'Xinying',
			'Taoyuan City',
			'Wulai',
			'Daxi',
			'Nantou',
			'Douliu',
			'Shiding',
			'Sanxia',
		],
	},
	Tajikistan: {
		country: 'Tajikistan',
		cities: [
			'Orzu',
			'Somoniyon',
			'Bokhtariyon',
			'Khodzha-Maston',
			'Shŭrobod',
			'Obigarm',
			'Qalaikhumb',
			'Ismoili Somoní',
			'Isfara',
			'Buston',
			'Abdurahmoni Jomí',
			'Qayroqqum',
			'Tartiki',
			'Moskva',
			'Fayzobod',
			'Neftobod',
			'Nov',
			'Shaydon',
			'Rŭshon',
			'Baljuvon',
			'Adrasmon',
			'Norak',
			'Murghob',
			'Levakant',
			'Dŭstí',
			'Konibodom',
			'Darband',
			'Khorugh',
			'Ishqoshim',
			'Rasht',
			'Dushanbe',
			'Ayní',
			'Kŭlob',
			'Hisor',
			'Jilikŭl',
			'Kim',
			'Novobod',
			'Jirghatol',
			'Danghara',
			'Bahorí',
			'Taboshar',
			'Mŭ’minobod',
			'Kirov',
			'Khovaling',
			'Gharavŭtí',
			'Bŭstonqal’a',
			'Istaravshan',
			'Sarikhosor',
			'Qubodiyon',
			'Quruqsoy',
			'Hulbuk',
			'Karakenja',
			'Ghonchí',
			'Bŭston',
			'Oltintopkan',
			'Shahriston',
			'Panj',
			'Boshchorbogh',
			'Shŭrob',
			'Mehron',
			'Khujand',
			'Shahritus',
			'Bokhtar',
			'Boshkengash',
			'Tursunzoda',
			'Roghun',
			'Ghafurov',
			'Vakhsh',
			'Tavildara',
			'Shahrinav',
			'Konsoy',
			'Vorukh',
			'Pakhtakoron',
			'Moskovskiy',
			'Varzob',
			'Vahdat',
			'Obikiik',
			'Avzikent',
			'Zafarobod',
			'Yovon',
			'Tajikobod',
			'Sovet',
			'Roghun',
			'Panjakent',
			'Farkhor',
			'Kolkhozobod',
			'Chubek',
			'Tagob',
			'Roshtqal’a',
			'Proletar',
			'Vanj',
			'Palos',
		],
	},
	'Tanzania, United Republic of': {
		country: 'Tanzania, United Republic of',
		cities: [
			'Tukuyu',
			'Singida',
			'Same',
			'Nyakabindi',
			'Nkoaranga',
			'Ngudu',
			'Mtinko',
			'Makuyuni',
			'Kihangara',
			'Lugoba',
			'Laela',
			'Kisasa',
			'Namanga',
			'Matamba',
			'Mafinga',
			'Issenye',
			'Gamba',
			'Madimba',
			'Tanga',
			'Nyalikungu',
			'Nsunga',
			'Ngorongoro',
			'Msowero',
			'Micheweni',
			'Longido',
			'Kilosa',
			'Kilimatinde',
			'Kidatu',
			'Izazi',
			'Igunga',
			'Utete',
			'Sirari',
			'Mlandizi',
			'Malangali',
			'Lushoto',
			'Kyela',
			'Kijini',
			'Kibaya',
			'Karema',
			'Bungu',
			'Nanyamba',
			'Shelui',
			'Mwembe',
			'Makanya',
			'Mahenge',
			'Liwale',
			'Kongwa',
			'Konde',
			'Kisanga',
			'Mingoyo',
			'Liuli',
			'Sokoni',
			'Nansio',
			'Mungaa',
			'Mgandu',
			'Matai',
			'Kondoa',
			'Kihurio',
			'Ikwiriri',
			'Zanzibar',
			'Poli',
			'Ngerengere',
			'Mkokotoni',
			'Mbuguni',
			'Kyaka',
			'Kiomboi',
			'Kintinku',
			'Kibaha',
			'Kabanga',
			'Dongobesh',
			'Mbekenyera',
			'Manda',
			'Urambo',
			'Tumbi',
			'Rulenge',
			'Nyanguge',
			'Naberera',
			'Mwanza',
			'Mwandiga',
			'Maneromango',
			'Katoro',
			'Katesh',
			'Igugunu',
			'Chimala',
			'Ruangwa',
			'Merelani',
			'Mvomero',
			'Ndungu',
			'Mtwango',
			'Maswa',
			'Magomeni',
			'Mabama',
			'Lembeni',
			'Inyonga',
			'Geiro',
			'Kitama',
			'Sepuka',
			'Puma',
			'Nakatunguru',
			'Mahanje',
			'Kigoma',
			'Kasamwa',
			'Ilula',
			'Igurusi',
			'Igurubi',
			'Butiama',
			'Tandahimba',
			'Newala Kisimani',
			'Kitangari',
			'Nyamuswa',
			'Nguruka',
			'Moshi',
			'Mhango',
			'Mahonda',
			'Mabamba',
			'Lupiro',
			'Kirya',
			'Kibiti',
			'Buseresere',
			'Masasi',
			'Usagara',
			'Pangani',
			'Matonga',
			'Kisesa',
			'Kimamba',
			'Ilongero',
			'Geita',
			'Arusha',
			'Matiri',
			'Kigonsera',
			'Vikindu',
			'Misungwi',
			'Kishapu',
			'Kidodi',
			'Katumba',
			'Kasulu',
			'Hedaru',
			'Dodoma',
			'Lindi',
			'Nangwa',
			'Mlowo',
			'Mlalo',
			'Kaliua',
			'Isaka',
			'Ifakara',
			'Chala',
			'Mtama',
			'Usoke',
			'Majengo',
			'Kisarawe',
			'Basotu',
			'Ngara',
			'Kibakwe',
			'Chanika',
			'Bukoba',
			'Namikupa',
			'Somanda',
			'Ndago',
			'Masoko',
			'Koani',
			'Kingori',
			'Itumba',
			'Ipinda',
			'Galappo',
			'Bukene',
			'Nachingwea',
			'Ulenje',
			'Nzega',
			'Nyakahanga',
			'Nkove',
			'Mtimbira',
			'Kirando',
			'Ikungi',
			'Dareda',
			'Bagamoyo',
			'Ushirombo',
			'Tarime',
			'Rujewa',
			'Mbeya',
			'Usevia',
			'Nganane',
			'Muriti',
			'Magomeni',
			'Dunda',
			'Mahuta',
			'Uwelini',
			'Uvinza',
			'Tinde',
			'Old Shinyanga',
			'Njombe',
			'Mugumu',
			'Mto wa Mbu',
			'Mtambile',
			'Morogoro',
			'Malinyi',
			'Malampaka',
			'Kiwira',
			'Kisiwani',
			'Kandete',
			'Kakonko',
			'Iringa',
			'Ilembo',
			'Bugene',
			'Babati',
			'Tingi',
			'Nangomba',
			'Wete',
			'Soni',
			'Nshamba',
			'Musoma',
			'Maramba',
			'Makumbako',
			'Kamachumu',
			'Nanganga',
			'Namanyere',
			'Mwaya',
			'Mikumi',
			'Mbumi',
			'Magole',
			'Kilindoni',
			'Ilembula',
			'Bukonyo',
			'Bariadi',
			'Songea',
			'Maposeni',
			'Mwanga',
			'Muheza',
			'Mlimba',
			'Misasi',
			'Matui',
			'Masumbwe',
			'Kwakoa',
			'Katerero',
			'Kiratu',
			'Bunda',
			'Nyangao',
			'Mbinga',
			'Lulindi',
			'Luchingu',
			'Tunduma',
			'Mugango',
			'Mpwapwa',
			'Monduli',
			'Mazinde',
			'Koani Ndogo',
			'Katoro',
			'Chato',
			'Chalinze',
			'Nanhyanga',
			'Lukuledi',
			'Chiungutwa',
			'Nungwi',
			'Songwa',
			'Mvomero',
			'Muleba',
			'Malya',
			'Makungu',
			'Kibara',
			'Itigi',
			'Dar es Salaam',
			'Mtwara',
			'Masuguru',
			'Vwawa',
			'Uyovu',
			'Usa River',
			'Tabora',
			'Sumbawanga',
			'Sikonge',
			'Shinyanga',
			'Mwadui',
			'Mpanda',
			'Mlangali',
			'Mkuranga',
			'Magugu',
			'Lalago',
			'Kibondo',
			'Kahama',
			'Endasak',
			'Biharamulo',
			'Bashanet',
			'Namalenga',
			'Mbamba Bay',
			'Msanga',
			'Mbulu',
			'Kiwengwa',
			'Chake Chake',
			'Bugarama',
		],
	},
	Thailand: {
		country: 'Thailand',
		cities: [
			'Mae Taeng',
			'Thung Yai',
			'Thap Than',
			'Thap Put',
			'Tha Chang',
			'Phato',
			'Pa Sang',
			'Mae Tha',
			'Lan Saka',
			'Khiri Mat',
			'Khian Sa',
			'Damnoen Saduak',
			'Ban Na',
			'Samran',
			'Wichian Buri',
			'Udon Thani',
			'Thon Buri',
			'Seka',
			'Prasat',
			'Prang Ku',
			'Pom Prap Sattru Phai',
			'Phibun Mangsahan',
			'Maha Rat',
			'Laem Ngop',
			'Kut Bak',
			'Kranuan',
			'Kaeng Khoi',
			'Hua Sai',
			'Dong Luang',
			'Don Chedi',
			'Ban Lam Luk Ka',
			'Bang Khla',
			'Amphoe Sikhiu',
			'Sai Mai',
			'Suk Samran',
			'Bueng Samakkhi',
			'Ban Pa Pae',
			'Na Yong',
			'Thung Song',
			'Tha Chana',
			'Sop Prap',
			'Khlong Thom',
			'Chai Buri',
			'Wang Chin',
			'Ban Laem',
			'Ban Nong Wua So',
			'Na Tan',
			'Tamot',
			'Takhli',
			'Sapphaya',
			'Sanom',
			'Samut Sakhon',
			'Sam Phran',
			'Sadao',
			'Pua',
			'Prakhon Chai',
			'Phra Pradaeng',
			'Phetchabun',
			'Phatthalung',
			'Pa Bon',
			'Pa Mok',
			'Kham Sakae Saeng',
			'Hat Yai',
			'Chumphon Buri',
			'Chok Chai',
			'Bueng Sam Phan',
			'Bo Rai',
			'Ban Na',
			'Don Phut',
			'Ban Bang Kadi Pathum Thani',
			'Saladan',
			'Thung Khru',
			'Lam Thap',
			'Wiang Haeng',
			'Wiang Chai',
			'Wang Nuea',
			'Lamphun',
			'Hot',
			'Sa Bot',
			'Huai Rat',
			'Yaring',
			'Wang Thong',
			'Uthumphon Phisai',
			'Taphan Hin',
			'Su-ngai Kolok',
			'Songkhla',
			'Si Rattana',
			'Senangkhanikhom',
			'Phu Ruea',
			'Phana',
			'Phak Hai',
			'Phachi',
			'Non Sung',
			'Nikhom Nam Un',
			'Lahan Sai',
			'Krasang',
			'Khlong Hat',
			'Huai Thalaeng',
			'Chian Yai',
			'Mae Lan',
			'Ban Lueam',
			'Kaeng Sanam Nang',
			'Bueng Bun',
			'Ko Pha Ngan',
			'Bang Sao Thong',
			'Phra Phrom',
			'Nikhom Phattana',
			'Suwanna Khuha',
			'Sam Roi Yot',
			'San Pa Tong',
			'Samoeng',
			'Khao Yoi',
			'Chawang',
			'Charoen Sin',
			'Yarang',
			'Wihan Daeng',
			'That Phanom',
			'Si Chiang Mai',
			'Phrai Bueng',
			'Phon Charoen',
			'Nong Khai',
			'Nong Bua Rawe',
			'Nakhon Luang',
			'Chon Daen',
			'Bang Pla Ma',
			'Bang Mun Nak',
			'Bangkok Noi',
			'Nai Harn',
			'Santi Suk',
			'Phu Phiang',
			'Na Wang',
			'Phlapphla Chai',
			'Si Wilai',
			'Na Kluea',
			'Mae Chai',
			'Wang Wiset',
			'Sikao',
			'Pak Tho',
			'Kapong',
			'Ban Ao Nang',
			'Ko Pha Ngan',
			'Wan Yai',
			'Wang Chan',
			'Tha Ruea',
			'Tha Luang',
			'Tao Ngoi',
			'Tan Sum',
			'Sankhaburi',
			'Roi Et',
			'Renu Nakhon',
			'Phutthaisong',
			'Non Thai',
			'Nong Saeng',
			'Nong Saeng',
			'Nikhom Kham Soi',
			'Na Thawi',
			'Narathiwat',
			'Na Mon',
			'Min Buri',
			'Lat Lum Kaeo',
			'Kut Chum',
			'Kamalasai',
			'Chiang Klang',
			'Buntharik',
			'Muak Lek',
			'Chaiyo',
			'Khlong Toei',
			'Suan Luang',
			'Mae Poen',
			'Kosamphi Nakhon',
			'Pakkhat',
			'Phra Thong Kham',
			'Yan Ta Khao',
			'Phrasaeng',
			'Phanom',
			'Nong Chang',
			'Mae Mo',
			'Bo Phloi',
			'Ban Rai',
			'Ban Lat',
			'Ban Kata',
			'Bang Saphan Noi',
			'Don Tum',
			'Tha Bo',
			'Phu Pha Man',
			'Phrom Phiram',
			'Phasi Charoen',
			'Phanna Nikhom',
			'Na Chaluai',
			'Khuan Kalong',
			'Khao Saming',
			'Kantharawichai',
			'Chon Buri',
			'Nong Muang Khai',
			'Na Yai Am',
			'Lao Suea Kok',
			'Ban Fang',
			'Chun',
			'Ko Chang Tai',
			'Pathum Ratchawongsa',
			'Pran Buri',
			'Phop Phra',
			'Nong Kha Yang',
			'Lan Krabue',
			'Ko Yao',
			'Khao Phanom',
			'Huai Yot',
			'Thung Wa',
			'Ban Na San',
			'Ban Na Doem',
			'Ban Hong',
			'Sak Lek',
			'Warin Chamrap',
			'Uthai Thani',
			'Suphan Buri',
			'Sukhirin',
			'Si Songkhram',
			'Sawaeng Ha',
			'Phra Nakhon Si Ayutthaya',
			'Nakhon Thai',
			'Maha Sarakham',
			'Krathum Baen',
			'Kham Ta Kla',
			'Huai Thap Than',
			'Cha-uat',
			'Betong',
			'Ban Sang',
			'Ratchasan',
			'Ban Phe',
			'Bang Khun Thian',
			'Bang Kapi',
			'Wichit',
			'Ban Ratsada',
			'Laplae',
			'Chuen Chom',
			'Khao Kho',
			'Phra Samut Chedi',
			'Wiang Pa Pao',
			'Thung Tako',
			'Thoen',
			'Tha Sae',
			'Phuket',
			'Na Bon',
			'Kong Krailat',
			'Fang',
			'Cha-am',
			'Wang Chao',
			'Nong Mamong',
			'Kaeng Krachan',
			'Lam Sonthi',
			'Thung Fon',
			'Sung Noen',
			'Rong Kham',
			'Pho Sai',
			'Phichai',
			'Phen',
			'Pattani',
			'Pak Phli',
			'Nong Chik',
			'Nang Rong',
			'Nakhon Phanom',
			'Mae Charim',
			'Lom Kao',
			'Khong Chiam',
			'Chaturaphak Phiman',
			'Chaloem Phra Kiat',
			'Ban Sam Chuk',
			'Ban Phaeo',
			'Ban Nang Sata',
			'Chiang Muan',
			'Ban Kruat',
			'Bangkok Yai',
			'Chakkarat',
			'Bamnet Narong',
			'Huai Khwang',
			'Thawi Watthana',
			'Watthana',
			'Ubonratana',
			'Sam Sung',
			'Wang Yang',
			'Dong Charoen',
			'Doi Luang',
			'Bang Bon',
			'Don Sak',
			'Wiang Sa',
			'Tak',
			'Si Banphot',
			'Phrom Khiri',
			'Phayao',
			'Pathio',
			'Ko Lanta',
			'Khura Buri',
			'Khun Yuam',
			'Khanu Woralaksaburi',
			'Chae Hom',
			'Bang Saphan',
			'Ban Mai',
			'Waeng Yai',
			'Tha Wang Pha',
			'Sung Men',
			'Rat Burana',
			'Prachantakham',
			'Phra Khanong',
			'Pho Thong',
			'Non Sang',
			'Noen Maprang',
			'Kuchinarai',
			'Khuan Khanun',
			'Khok Charoen',
			'Khlung',
			'Su-ngai Padi',
			'Cho-airong',
			'Bua Yai',
			'Na Thom',
			'Na Khu',
			'Na Haeo',
			'Khao Chakan',
			'Bang Krathum',
			'Bang Phlat',
			'Din Daeng',
			'Sawaengha',
			'Nathom',
			'Pho Tak',
			'Saraphi',
			'Mae Ramat',
			'Lao Khwan',
			'La-ngu',
			'Chaiya',
			'Bang Khan',
			'Doi Lo',
			'Ban Dan Lan Hoi',
			'Thepharak',
			'Ban Haet',
			'Thep Sathit',
			'Tha Khantho',
			'Sing Buri',
			'Sanam Chai Khet',
			'Rueso',
			'Rong Kwang',
			'Pong Nam Ron',
			'Pluak Daeng',
			'Phrom Buri',
			'Pho Thale',
			'Panare',
			'Pak Thong Chai',
			'Pak Kret',
			'Mueang Nonthaburi',
			'Nakhon Nayok',
			'Mancha Khiri',
			'Khon Sawan',
			'Chaiyaphum',
			'Pattaya',
			'Na Di',
			'Ban Khok',
			'Bueng Na Rang',
			'Bang Klam',
			'Lat Krabang',
			'Wang Thonglang',
			'Prachamtakham',
			'Si Somdet',
			'Thung Sai',
			'Si Nakhon',
			'Mae Chaem',
			'Li',
			'Ko Kha',
			'Huai Khot',
			'Ban Phru Nai',
			'Na Mom',
			'Ban Khlong Bang Sao Thong',
			'Suwannaphum',
			'Si Thep',
			'Si Maha Phot',
			'Sao Hai',
			'Samut Prakan',
			'Sa Kaeo',
			'Phayakkhaphum Phisai',
			'Phan Thong',
			'Non Kho',
			'Nong Hong',
			'Makham',
			'Lop Buri',
			'Kut Chap',
			'Dusit',
			'Chiang Yuen',
			'Chana',
			'Chai Nat',
			'Bueng Khong Long',
			'Bo Kluea',
			'Wang Pong',
			'Ban Muang',
			'Ban Luang',
			'Kut Rang',
			'At Samat',
			'Koh Tao',
			'Bang Sue',
			'Salaya',
			'Chang Klang',
			'Chum Ta Bong',
			'Mae Wong',
			'U Thong',
			'Umphang',
			'Si Sawat',
			'Si Satchanalai',
			'Sawang Arom',
			'Lampang',
			'Nong Prue',
			'Noen Kham',
			'Phayu',
			'Nong Hin',
			'Watthana Nakhon',
			'Wang Noi',
			'Tron',
			'Tha Yang',
			'Tha Pla',
			'Si Sa Ket',
			'Rayong',
			'Non Sa-at',
			'Nong Chok',
			'Khun Han',
			'Khu Khan',
			'Kaset Wisai',
			'Kanthararom',
			'Krong Pi Nang',
			'Chiang Saen',
			'Ban Selaphum',
			'Ban Khai',
			'Chamni',
			'Lue Amnat',
			'Mae Fa Luang',
			'Mae Lao',
			'Ratsada',
			'Sap Yai',
			'Sa Khrai',
			'Bua Lai',
			'Phrao',
			'Mae Ai',
			'Tha Muang',
			'Tha Maka',
			'Tha Kham',
			'Pai',
			'Mae Chan',
			'Lan Sak',
			'Kong Ra',
			'Khlong Lan',
			'Kantang',
			'Nuea Khlong',
			'Ban Dan',
			'Yang Chum Noi',
			'Yaha',
			'Wiset Chaichan',
			'Wang Sai Phun',
			'Khuean Ubonrat',
			'Song',
			'Prachin Buri',
			'Phayuha Khiri',
			'Phanom Phrai',
			'Nong Bua Lamphu',
			'Muang Sam Sip',
			'Mayo',
			'Lamduan',
			'Kosum Phisai',
			'Bang Rachan',
			'Bang Pahan',
			'Bang Lamung',
			'Chom Thong',
			'Khan Na Yao',
			'Ban Na Muang',
			'Thung Khao Luang',
			'Chaem Luang',
			'Hat Samran',
			'Thalang',
			'Long',
			'La-Un',
			'Uttaradit',
			'Sam Ngam',
			'Phu Khiao',
			'Waeng',
			'Nong Phok',
			'Bangkok',
			'Khueang Nai',
			'Khon San',
			'Kham Thale So',
			'Kaeng Khro',
			'Doembang Nangbuat',
			'Bang Sai',
			'Thung Chang',
			'Chaloem Phra Kiat',
			'Khao Chamao',
			'Non Din Daeng',
			'Pa Kham',
			'Pho Si Suwan',
			'Bung Khla',
			'Pa Phayom',
			'Trang',
			'Sawankhalok',
			'Ron Phibun',
			'Photharam',
			'Mae Tha',
			'Hang Chat',
			'Chom Thong',
			'Mueang Yang',
			'Fao Rai',
			'Trat',
			'Si Khoraphum',
			'Si Chomphu',
			'Sattahip',
			'Ra-ngae',
			'Pla Pak',
			'Phu Luang',
			'Phang Khon',
			'Na Noi',
			'Nam Nao',
			'Na Chueak',
			'Lap Lae',
			'Kho Wang',
			'Hua Taphan',
			'Chaiyo',
			'Buri Ram',
			'Ban Thaen',
			'Bang Kruai',
			'Akat Amnuai',
			'Phon Charoen',
			'Nong Yasai',
			'Ban Mai Chaiyaphot',
			'Non Narai',
			'Tha Sala',
			'Suan Phueng',
			'Ratchaburi',
			'Phipun',
			'Kra Buri',
			'Ban Ko Kaeo',
			'Ban Karon',
			'Bang Phae',
			'Yang Si Surat',
			'Yi-ngo',
			'Wanon Niwat',
			'Tak Bai',
			'Sam Khok',
			'Sai Buri',
			'Phon Sai',
			'Phai Sali',
			'Nong Yai',
			'Nong Phai',
			'Nakhon Sawan',
			'Khu Mueang',
			'Chiang Khong',
			'Nong Don',
			'Khlong Hoi Khong',
			'Ban Talat Bueng',
			'Aranyaprathet',
			'Patong',
			'Pathum Wan',
			'Saphan Sung',
			'Phu Kam Yao',
			'Tha Takiap',
			'Khok Pho Chai',
			'Sirindhorn',
			'Sam Chai',
			'Thap Sakae',
			'Takua Pa',
			'Si Samrong',
			'Phanom Thuan',
			'Nong Ya Plong',
			'Mueang Pan',
			'Mae Suai',
			'Mae Sot',
			'Chiang Dao',
			'Ban Kha',
			'Tha Wung',
			'Somdet',
			'Si Prachan',
			'Selaphum',
			'Phu Kradueng',
			'Pak Khat',
			'Nong Sung',
			'Makkasan',
			'Chum Phuang',
			'Chonnabot',
			'Chiang Kham',
			'Chat Trakan',
			'Chanthaburi',
			'Sawang Wirawong',
			'Ban Phue',
			'Ban Pho',
			'Pak Chom',
			'Ban Mi',
			'Ban Dung',
			'Bang Kho Laem',
			'Wiang Kaen',
			'Pang Sila Thong',
			'Wang Sombun',
			'Lam Thamen Chai',
			'Mae Hi',
			'Ban Huai I Huak',
			'Takua Thung',
			'Sai Ngam',
			'Phran Kratai',
			'Phang Nga',
			'Phan',
			'Mae La Noi',
			'Dok Kham Tai',
			'Phatthana Nikhom',
			'Yan Nawa',
			'Trakan Phut Phon',
			'Tha Tum',
			'Ban Su-ngai Pa Di',
			'Soeng Sang',
			'Si That',
			'Sangkha',
			'Ranot',
			'Raman',
			'Phon Phisai',
			'Phitsanulok',
			'Nong Ruea',
			'Na Muen',
			'Nam Phong',
			'Lom Sak',
			'Klaeng',
			'Ban Mo',
			'Ban Ko Lan',
			'Bang Yai',
			'Takuk Nuea',
			'Ban Thi',
			'Manang',
			'Wiang Kao',
			'Singhanakhon',
			'Tham Phannara',
			'Soem Ngam',
			'Siao',
			'Nong Na Kham',
			'Yala',
			'Wat Sing',
			'Wapi Pathum',
			'Surin',
			'Si Racha',
			'Phanom Sarakham',
			'Phaya Mengrai',
			'Nong Khae',
			'Nong Bua',
			'Maha Chana Chai',
			'Kut Khaopun',
			'Krasae Sin',
			'Khuan Niang',
			'Khon Buri',
			'Hankha',
			'Ban Thai Tan',
			'Khlong Khuean',
			'Kaeng Hang Maeo',
			'Ban Bueng',
			'Ang Thong',
			'Song Dao',
			'Phaya Thai',
			'Ratchathewi',
			'Mueang Phuket',
			'Wiang Nong Long',
			'Chumsaeng',
			'Phakdi Chumphon',
			'Phibun Rak',
			'Ban Talat Yai',
			'Thai Mueang',
			'Sawi',
			'Sam Ngao',
			'Palian',
			'Lat Yao',
			'Kanchanadit',
			'Kamphaeng Phet',
			'Chiang Mai',
			'Wiang Nuea',
			'Ban Khao Lak',
			'Na Dun',
			'Wang Sam Mo',
			'Wang Hin',
			'Waeng Noi',
			'Ubon Ratchathani',
			'Thap Khlo',
			'Thanyaburi',
			'Suwannakhuha',
			'Saraburi',
			'Sam Ko',
			'Sai Noi',
			'Rattanaburi',
			'Pong',
			'Phimai',
			'Pathum Rat',
			'Nong Khaem',
			'Na Wa',
			'Mueang Suang',
			'Lam Luk Ka',
			'Kumphawapi',
			'Khlong Yai',
			'Kham Khuean Kaeo',
			'Khamcha-i',
			'Huai Mek',
			'Fak Tha',
			'Ban Phan Don',
			'Sop Pong',
			'Ban Chalong',
			'Ban Takhun',
			'Ko Chan',
			'Wang Nam Khiao',
			'Non Sila',
			'Phon Na Kaeo',
			'Wat Phleng',
			'Thung Saliam',
			'Sangkhla Buri',
			'Sai Yok',
			'Plai Phraya',
			'Ngao',
			'Chumphon',
			'Ban Pong',
			'Ban Mai Khao',
			'Nong Muang',
			'Ban Huai Thalaeng',
			'Tha Li',
			'Sawang Daen Din',
			'Satun',
			'Sakon Nakhon',
			'Pakham',
			'Phrae',
			'Chai Wari',
			'Pak Phanang',
			'Moeiwadi',
			'Kusuman',
			'Khong',
			'Khlong Luang',
			'Khao Wong',
			'Kabin Buri',
			'Chaiwan',
			'Song Khwae',
			'Ban Khwao',
			'Bang Bua Thong',
			'Bang Ban',
			'Amnat Charoen',
			'Samrong',
			'Doem Bang Nang Buat',
			'Erawan',
			'Soi Dao',
			'Changhan',
			'Sila Lat',
			'Ko Kut',
			'Om Koi',
			'Mae Hong Son',
			'Chom Bueng',
			'Banphot Phisai',
			'Nong Kung Si',
			'Tak Fa',
			'Wat Bot',
			'Than To',
			'Song Phi Nong',
			'Sahatsakhan',
			'Rattaphum',
			'Plaeng Yao',
			'Pa Tio',
			'Pak Phayun',
			'Manorom',
			'Lam Plai Mat',
			'Kao Liao',
			'Ban Phraek',
			'Khok Sung',
			'Lat Phrao',
			'Noen Sa-nga',
			'Si Narong',
			'Chiang Khwan',
			'Phu Phan',
			'Ban Talat Nua',
			'Nopphitam',
			'Sukhothai',
			'Prachuap Khiri Khan',
			'Nakhon Si Thammarat',
			'Mae Phrik',
			'Khlong Khlung',
			'Hang Dong',
			'Ao Luek',
			'Non Suwan',
			'Waeng',
			'Prakhon Chai',
			'Taling Chan',
			'So Phisai',
			'Si Bun Rueang',
			'Samut Songkhram',
			'Samrong Thap',
			'Pueai Noi',
			'Prathai',
			'Phra Yuen',
			'Phanat Nikhom',
			'Pathum Thani',
			'Ongkharak',
			'Nong Bun Nak',
			'Nam Som',
			'Laem Sing',
			'Krok Phra',
			'Ka Bang',
			'Chai Badan',
			'Chachoengsao',
			'Bueng Kan',
			'Borabue',
			'Bang Rak',
			'Chaloem Phra Kiat',
			'Don Chan',
			'Thung Si Udom',
			'Hua Hin',
			'Dan Chang',
			'Rawai',
			'Bang Khon Thi',
			'Pha Khao',
			'Nong Wua So',
			'Na Yung',
			'Sikhio',
			'Sena',
			'Nakhon Chai Si',
			'Loeng Nok Tha',
			'Khok Si Suphan',
			'Khok Pho',
			'Det Udom',
			'Dan Khun Thot',
			'Chiang Khan',
			'Bang Phli',
			'Bang Len',
			'Ban Chang',
			'Don Mueang',
			'Bang Khae',
			'Sathon',
			'Don Mot Daeng',
			'Mae Wang',
			'Srinagarindra',
			'Chai Prakan',
			'Ranong',
			'Lang Suan',
			'Ban Tak',
			'Huai Krachao',
			'Amphawa',
			'Nong Hi',
			'Mueang Chan',
			'Na Klang',
			'Thoeng',
			'Sang Khom',
			'Sai Mun',
			'Saba Yoi',
			'Phu Wiang',
			'Pak Chong',
			'Na Pho',
			'Nan',
			'Nakhon Ratchasima',
			'Na Kae',
			'Lat Bua Luang',
			'Ko Si Chang',
			'Khok Samrong',
			'Khlong San',
			'Khao Chaison',
			'Kham Muang',
			'Kamphaeng Saen',
			'Kalasin',
			'Huai Phueng',
			'Dan Sai',
			'Chanuman',
			'Wang Muang',
			'Ban Phaeng',
			'Kap Choeng',
			'Bang Rakam',
			'Bang Pa-in',
			'Wiang Chiang Rung',
			'Wachira Barami',
			'Phonphisai',
			'Phu Sing',
			'Sida',
			'Sop Moei',
			'Phra Nakhon',
			'Tha Song Yang',
			'Lamae',
			'Tha Khanon',
			'Kanchanaburi',
			'Chiang Rai',
			'Thung Hua Chang',
			'Bacho',
			'Wiang Sa',
			'Wang Saphung',
			'Wang Nam Yen',
			'Thawat Buri',
			'Tha Tako',
			'Sathing Phra',
			'Rasi Salai',
			'Nong Suea',
			'Nong Ki',
			'Nong Han',
			'Nam Pat',
			'Mukdahan',
			'Khemarat',
			'Kaset Sombun',
			'Don Tan',
			'Chatturat',
			'Chanae',
			'Ban Phai',
			'Bang Racham',
			'Bang Khen',
			'Bang Bo',
			'Bang Na',
			'Khao Khitchakut',
			'Ku Kaeo',
			'Nam Khun',
			'Phetchaburi',
			'Thong Pha Phum',
			'Tha Phae',
			'Krabi',
			'Kathu',
			'Kapoe',
			'Doi Tao',
			'Dan Makham Tia',
			'Thepha',
			'Yasothon',
			'Yang Talat',
			'Uthai',
			'Tha Mai',
			'Tha Chang',
			'Sangkhom',
			'Samphanthawong',
			'Phon',
			'Phichit',
			'Pa Daet',
			'Nong Bua Daeng',
			'Loei',
			'Kae Dam',
			'Den Chai',
			'Bua Chet',
			'Na Yia',
			'Bang Pakong',
			'Bang Nam Priao',
			'Bang Kaeo',
			'Phanom Dong Rak',
			'Bueng Kum',
			'Khun Tan',
			'Chaloem Phra Kiat',
			'Chulabhorn',
			'Thong Saen Khan',
			'Si Mahosot',
			'Chaloem Phra Kiat',
			'Non Daeng',
			'Khong Chai',
			'Khwao Sinarin',
			'Thai Charoen',
			'Benchalak',
			'Surat Thani',
			'San Sai',
			'San Kamphaeng',
			'Mae Sai',
			'Kui Buri',
			'Tha Maka',
			'Ko Samui',
			'Ban Nam Yuen',
			'Warichaphum',
			'Tha Uthen',
			'Ta Phraya',
			'Si Sakhon',
			'Si Mueang Mai',
			'Satuek',
			'Sam Chuk',
			'Phra Phutthabat',
			'Nam Kliang',
			'Nakhon Pathom',
			'Mai Kaen',
			'Khuan Don',
			'Khon Kaen',
			'Kantharalak',
			'In Buri',
			'Chum Phae',
			'Chom Phra',
			'Bo Thong',
			'Kapho',
			'Bang Bo District',
			'Thung Yang Daeng',
			'Chatuchak',
			'Khaen Dong',
			'Rattanawapi',
			'Mae On',
			'Phu Sang',
		],
	},
	'Timor-Leste': {
		country: 'Timor-Leste',
		cities: [
			'Suai',
			'Manatutu',
			'Lospalos',
			'Metinaro',
			'Maubara',
			'Dili',
			'Ainaro',
			'Venilale',
			'Viqueque',
			'Likisá',
			'Gleno',
			'Maliana',
			'Aileu',
			'Same',
			'Baukau',
			'Pante Makasar',
		],
	},
	Togo: {
		country: 'Togo',
		cities: [
			'Atakpamé',
			'Pagouda',
			'Bafilo',
			'Aného',
			'Vogan',
			'Niamtougou',
			'Mango',
			'Kara',
			'Dapaong',
			'Badou',
			'Amlamé',
			'Tchamba',
			'Tsévié',
			'Sotouboua',
			'Tabligbo',
			'Bassar',
			'Lomé',
			'Sokodé',
			'Kpalimé',
			'Notsé',
			'Kandé',
		],
	},
	Tokelau: { country: 'Tokelau', cities: ['Fale old settlement', 'Nukunonu', 'Atafu Village'] },
	Tonga: {
		country: 'Tonga',
		cities: [
			'Tatakamotonga',
			'Lapaha',
			'‘Ohonua',
			'Houma',
			'Haveluloto',
			'Pangai',
			'Nuku‘alofa',
			'Neiafu',
			'Vaini',
			'Hihifo',
			'Fangale’ounga',
			'Kolonga',
		],
	},
	'Trinidad and Tobago': {
		country: 'Trinidad and Tobago',
		cities: [
			'Arouca',
			'Siparia',
			'Scarborough',
			'Mon Repos',
			'Sangre Grande',
			'Peñal',
			'Mucurapo',
			'Rio Claro',
			'Chaguanas',
			'Diego Martin',
			'Debe',
			'Couva',
			'Laventille',
			'Princes Town',
			'Port of Spain',
			'Arima',
			'Point Fortin',
			'Paradise',
			'Tunapuna',
			'Tabaquite',
			'San Fernando',
			'Petit Valley',
			'Marabella',
		],
	},
	Tunisia: {
		country: 'Tunisia',
		cities: [
			'Hammam Sousse',
			'Degache',
			'Zaouiet Kountech',
			'Medenine',
			'Jendouba',
			'Dar Chabanne',
			'Béja',
			'El Hamma',
			'Chebika',
			'Skanes',
			'Haffouz',
			'Al Matlīn',
			'El Jem',
			'Sousse',
			'Kebili',
			'Gabès',
			'Djebeniana',
			'Douar Tindja',
			'Zouila',
			'Er Regueb',
			'Tataouine',
			'Korba',
			'Gafour',
			'Jemna',
			'Ben Arous',
			'As Sars',
			'Sahline',
			'Mesdour',
			'Wadi Maliz',
			'La Sebala du Mornag',
			'Kairouan',
			'El Mida',
			'Salakta',
			'Manouba',
			'Ouardenine',
			'Rouhia',
			'Sidi Bou Rouis',
			'Kélibia',
			'El Fahs',
			'Banbalah',
			'Skhira',
			'Sidi Bou Saïd',
			'Mateur',
			'Beni Hassane',
			'Rohia',
			'Metlaoui',
			'Akouda',
			'Zaouiat Djedidi',
			'Chorbane',
			'Sejenane',
			'Ksour Essaf',
			'Ksar Hellal',
			'Maktar',
			'El Haouaria',
			'Zahānah',
			'Zaghouan',
			'Sidi Ben Nour',
			'Sbiba',
			'Jilma',
			'Kasserine',
			'Mahdia',
			'El Alia',
			'Douane',
			'Tamaghzah',
			'Menzel Jemil',
			'Melloulèche',
			'Zarzis',
			'Douz',
			'El Kef',
			'Tabarka',
			'Midoun',
			'Mennzel Bou Zelfa',
			'Medjez el Bab',
			'Ar Rudayyif',
			'Ezzouhour',
			'Touza',
			'Takelsa',
			'El Maamoura',
			'El Borma',
			'Galaat el Andeless',
			'Sbikha',
			'El Ksour',
			'Jedelienne',
			'Tunis',
			'Radès',
			'Menzel Heurr',
			'Gremda',
			'Monastir',
			'Port el Kantaoui',
			'Harqalah',
			'Rhar el Melah',
			'Ben Gardane',
			'Beni Kheddache',
			'Ariana',
			'Al Marsá',
			'Bekalta',
			'Thala',
			'Siliana',
			'Sidi el Hani',
			'Remada',
			'Hammam-Lif',
			'La Goulette',
			'Bou Arkoub',
			'Erriadh',
			'Tozeur',
			'Tajerouine',
			'Sidi Alouane',
			'Ksibet el Mediouni',
			'Nefta',
			'Bir el Hafey',
			'Matmata',
			'Sidi Bou Ali',
			'Nabeul',
			'Menzel Salem',
			'Kesra',
			'Beni Khiar',
			'Chebba',
			'Hammamet',
			'Testour',
			'Tabursuq',
			'Carthage',
			'Menzel Kamel',
			'Mezzouna',
			'Oued Lill',
			'Rafrāf',
			'Goubellat',
			'Bou Arada',
			'Seïada',
			'Menzel Bourguiba',
			'Djemmal',
			'Fernana',
			'Sidi Bouzid',
			'Nibbar',
			'Bizerte',
			'Le Krib',
			'El Battan',
			'Kondhar',
			'Korbous',
			'Bir Ali Ben Khalifa',
			'Beni Khalled',
			'Sfax',
			'Gafsa',
			'Msaken',
			'Kalaat Khasba',
			'Bou Attouche',
			'La Mohammedia',
			'Menzel Abderhaman',
			'As Sanad',
			'El Golaa',
			'Sakiet Sidi Youssef',
			'Lemta',
			'Houmt El Souk',
			'Ghardimaou',
		],
	},
	Turkey: {
		country: 'Turkey',
		cities: [
			'Zeytindağ',
			'Yeşilova',
			'Yaygın',
			'Tutak',
			'Türkoğlu',
			'Taşkent',
			'Seyhan',
			'Pınarbaşı',
			'Pazaryeri',
			'Ovacık',
			'Karaova',
			'Elmalıdere',
			'Kuruçay',
			'Beykonak',
			'Kızılcasöğüt',
			'Kireç',
			'Kazımkarabekir',
			'Kaypak',
			'Karahallı',
			'Karadirek',
			'Kangal',
			'Kabahaydar',
			'Gölören',
			'Gölbaşı',
			'Gökbudak',
			'Erçek',
			'Kocasinan',
			'Doğanbeyli',
			'Derebucak',
			'Dere',
			'Cihanbeyli',
			'Çiçekdağı',
			'Cevizli',
			'Bünyan',
			'Bozyazı',
			'Bostancı',
			'Bekirhan',
			'Yoğun',
			'Bala',
			'Babayağmur',
			'Aşağıçiğil',
			'Ağın',
			'Afşin',
			'Denizciler',
			'Çıplak',
			'Yığılca',
			'Yeniçağa',
			'Yalakdere',
			'Ulubey',
			'Trabzon',
			'Sarıgöl',
			'Maltepe',
			'Mahmutbey',
			'Korgan',
			'Kırcasalih',
			'Kefken',
			'Karapürçek',
			'Kâmil',
			'İyidere',
			'Filyos',
			'Kırık',
			'Gümüşhacıköy',
			'Gökçeli',
			'Dumlu',
			'Doğantepe',
			'Değirmendere',
			'Artvin',
			'Ardahan',
			'Büyükorhan',
			'Yenice',
			'Yayla',
			'Cimin',
			'Tut',
			'Seki',
			'Seferihisar',
			'Sarıveliler',
			'Sabuncu',
			'Pamukören',
			'Osmaniye',
			'Oğuzeli',
			'Küçükbahçe',
			'Koçarlı',
			'Kaymakçı',
			'Kavakbaşı',
			'Karaköy',
			'Karaköprü',
			'İkizce',
			'Hisar',
			'Gürün',
			'Geriş',
			'Genç',
			'Gelembe',
			'Fevzipaşa',
			'Erkilet',
			'Elbeyli',
			'Doğanyol',
			'Direkli',
			'Dicle',
			'Dadaşköy',
			'Çine',
			'Burç',
			'Bismil',
			'Belek',
			'Bucak',
			'Bağışlı',
			'Altınekin',
			'Turluk',
			'Karasüleymanlı',
			'Işıklar',
			'Gülyalı',
			'Tepecik',
			'Sulakyurt',
			'Sinekçi',
			'Merzifon',
			'Maden',
			'Kozlu',
			'Kovanlık',
			'Karakurt',
			'Karabiga',
			'Hendek',
			'Fatsa',
			'Erenler',
			'Çıldır',
			'Cide',
			'Çan',
			'Bozkurt',
			'Aydıncık',
			'Ayaş',
			'Arhavi',
			'Akdağ',
			'Yolağzı',
			'Gölcük',
			'Yenimehmetli',
			'Yenicekale',
			'Torbalı',
			'Toklar',
			'Sivrice',
			'Sinanpaşa',
			'Şenköy',
			'Sekili',
			'Pınarönü',
			'Pınarlar',
			'Pazarcık',
			'Ortaklar',
			'Mermer',
			'Levent',
			'Sarıkavak',
			'Kula',
			'Kozan',
			'Kösreli',
			'Konaklı',
			'Kırkağaç',
			'Kırıkkale',
			'Kemerhisar',
			'Karayün',
			'Karatoprak',
			'Karamanlı',
			'Kanlıavşar',
			'İsabeyli',
			'İnönü',
			'İncirliova',
			'Güzelyurt',
			'Başköy',
			'Gücük',
			'Gözne',
			'Timar',
			'Ezine',
			'Etimesgut',
			'Elbaşı',
			'Düzova',
			'Doğansu',
			'Dazkırı',
			'Çeltikçi',
			'Bulanık',
			'Bolvadin',
			'Binatlı',
			'Batman',
			'Başkale',
			'Baklan',
			'Atça',
			'Alanya',
			'Gülağaç',
			'Nurdağı',
			'Kızkalesi',
			'Doruklu',
			'Bulutlu',
			'Yoğuntaş',
			'Yakakent',
			'Vezirköprü',
			'Vakfıkebir',
			'Tekkiraz',
			'Suluova',
			'Süloğlu',
			'Ortacalar',
			'Öğdem',
			'Lâlapaşa',
			'Kumru',
			'Kirazlı',
			'Kınalı',
			'Karabük',
			'İğneada',
			'Havsa',
			'Dedeköy',
			'Gaziler',
			'Eynesil',
			'Borçka',
			'Bolayır',
			'Akkuş',
			'güngören merter',
			'Karatay',
			'Sarigerme',
			'Çatalpınar',
			'Sultanbeyli',
			'Kamışlı',
			'Yakutiye',
			'Merkez',
			'Salat',
			'Yiğityolu',
			'Yenifakılı',
			'Yazyurdu',
			'Yahyalı',
			'Uzungeçit',
			'Tuzlagözü',
			'Şambayat',
			'Salihli',
			'Refahiye',
			'Pınardere',
			'Pervari',
			'Nurettin',
			'Niğde',
			'Kuzucubelen',
			'Kozaklı',
			'Kovancılar',
			'Konyaaltı',
			'Konakpınar',
			'Kocaköy',
			'Kayseri',
			'Kavak',
			'Güneren',
			'Gülnar',
			'Göreme',
			'Gazipaşa',
			'Erköklü',
			'Eğirdir',
			'Duruca',
			'Çumra',
			'Çermik',
			'Çatalçam',
			'Çağlayan',
			'Boğazören',
			'Başpınar',
			'Bağkonak',
			'Bağarası',
			'Altınkum',
			'Alacahan',
			'Akarsu',
			'Akcakışla',
			'Kastal',
			'Uzunkavak',
			'Koyunluca',
			'Sultanköy',
			'Silivri',
			'Pınarhisar',
			'Pazaryolu',
			'Yenikonak',
			'Orhangazi',
			'Maçka',
			'Küçükkumla',
			'Keşap',
			'Kaymas',
			'Karacaköy',
			'Gökçeada',
			'Esençay',
			'Elbeyli',
			'Çırçır',
			'Çarşıbaşı',
			'Beşpınar',
			'Bayramören',
			'Arpaçay',
			'Akyurt',
			'Ağva',
			'Bağlar',
			'Yolbaşı',
			'Yarbasan',
			'Yalıkavak',
			'Üçpınar',
			'Turgut',
			'Şemdinli',
			'Özdere',
			'Ovakent',
			'Osmanpaşa',
			'Narlıdere',
			'Mollakendi',
			'Kuyulusebil',
			'Köprübaşı',
			'Kazancı',
			'Karşıyaka',
			'Ağrı',
			'Karacaören',
			'Isparta',
			'Hekimhan',
			'Hasankeyf',
			'Gözpınar',
			'Göktepe',
			'Divriği',
			'Dikili',
			'Develi',
			'Denizli',
			'Serhatta',
			'Çiğil',
			'Çeşme',
			'Çakırbeyli',
			'Menteşe',
			'Boğaziçi',
			'Beşkonak',
			'Beğendik',
			'Bağgöze',
			'Ayvalık',
			'Ayvacık',
			'Avsallar',
			'Ağaçlı',
			'Çardaklı',
			'Yemişli',
			'Zeytinburnu',
			'Suşehri',
			'Şile',
			'Seydim',
			'Kırbaşı',
			'Karasu Mahallesi',
			'Karadere',
			'İzmit',
			'Hasköy',
			'Dokurcun',
			'Çiftlik',
			'Gemlik',
			'Düzköy',
			'Devrek',
			'Boğazkaya',
			'Okurcalar',
			'Yüksekova',
			'Yeşilli',
			'Urla',
			'Uluyatır',
			'Uludere',
			'Tuzlukçu',
			'Siverek',
			'Serinyol',
			'Şenyurt',
			'Şenköy',
			'Edremit',
			'Palamutlu',
			'Ödemiş',
			'Nurettin',
			'Kırmızıköprü',
			'Kumlu',
			'Konya',
			'Kocatepe',
			'Kavacık',
			'Karıncalı',
			'Karaoğlanlı',
			'Karaoğlan',
			'Kabahaydar',
			'İkiköprü',
			'Güllü',
			'Gördes',
			'Gölcük',
			'Göksun',
			'Geyikpınar',
			'Gelendost',
			'Feke',
			'Etili',
			'Elazığ',
			'Döşemealtı',
			'Deliktaş',
			'Çaylarbaşı',
			'Çavdarhisar',
			'Çalışkan',
			'Beyşehir',
			'Demirtaş',
			'Başmakçı',
			'Bahşılı',
			'Bahçesaray',
			'Altınözü',
			'Albayrak',
			'Akkent',
			'Afyonkarahisar',
			'Acırlı',
			'Oltu',
			'Mihalgazi',
			'Kumluca',
			'Kızılırmak',
			'Karaşar',
			'İhsaniye',
			'Ereğli',
			'Eminönü',
			'Edirne',
			'Dörtdivan',
			'Dikbıyık',
			'Daday',
			'Çanakkale',
			'Aybastı',
			'Artova',
			'Abana',
			'Yenişehir',
			'Yenice',
			'Yamaç',
			'Yahşihan',
			'Arsuz',
			'Tercan',
			'Suvarlı',
			'Senirkent',
			'Sarıkemer',
			'Özonak',
			'Orhaneli',
			'Okçular',
			'Nazimiye',
			'Muş',
			'Mansurlu',
			'Köşk',
			'Kelekçi',
			'Kızılağaç',
			'Karahasanlı',
			'Karacasu',
			'Işıklar',
			'İlyaslı',
			'Hassa',
			'Harmanlı',
			'Gülveren',
			'Bisbin',
			'Gölbaşı',
			'Gökçekoru',
			'Eskil',
			'Emet',
			'Dutluca',
			'Doğanhisar',
			'Bölme',
			'Boğazkent',
			'Beypınarı',
			'Kızılin',
			'Başyurt',
			'Dağlıca',
			'Ambar',
			'Aktepe',
			'Aksu',
			'Akpınar',
			'Akdere',
			'Ağaçören',
			'Toygarlı',
			'Anayazı',
			'Tepehan',
			'Yukarı Taşyalak',
			'Başyayla',
			'Yenice',
			'Sinekli',
			'Seyitler',
			'Şebin Karahisar',
			'Pursaklar',
			'İspir',
			'Demirkent',
			'Darıca',
			'Çaybaşı',
			'Çakırca',
			'Büyükkarıştıran',
			'Boğazkale',
			'Bartın',
			'Bandırma',
			'Azdavay',
			'Armutlu',
			'Büyükçekmece',
			'Yolboyu',
			'Yıldızeli',
			'Yeşilalan',
			'Temelli',
			'Taşucu',
			'Sulak',
			'Sivrice',
			'Saruhanlı',
			'Ortaköy',
			'Mersin',
			'Meram',
			'Malazgirt',
			'Lice',
			'Kösefakılı',
			'Konakpınar',
			'Kemah',
			'Kazancı',
			'Karalar',
			'Karakeçi',
			'Karaburun',
			'İncesu',
			'Hozat',
			'Hanköy',
			'Hamdibey',
			'Gülpınar',
			'Gölova',
			'Gökoğlan',
			'Eşme',
			'Dumlupınar',
			'Doğanca',
			'Turanlı',
			'Demirci',
			'Datça',
			'Darende',
			'Dağ',
			'Çınaraltı',
			'Çeltik',
			'Anamas',
			'Boztepe',
			'Bozdoğan',
			'Beşiri',
			'Yeşilyurt',
			'Alpköy',
			'Yürük',
			'Yenice',
			'Uruş',
			'Kuzyaka',
			'Köseköy',
			'Koruköy',
			'Kelkit',
			'Kadıköy',
			'Gümüşhane',
			'Esenyurt',
			'Çanta',
			'Büyükçavuşlu',
			'Ayvacık',
			'Yaniklar',
			'Kazımkarabekir',
			'Yunak',
			'Yenipazar',
			'Yedisu',
			'Yazıhan',
			'Yayladere',
			'Yayladağı',
			'Yağcılar',
			'Umurlu',
			'Tepehan',
			'Susurluk',
			'Söğüt',
			'Sincik',
			'Savaştepe',
			'Sason',
			'Samandağ',
			'Otlukbeli',
			'Örencik',
			'Ömerli',
			'Mezitli',
			'Kuyucak',
			'Kızıltepe',
			'Kazanlı',
			'Kayapınar',
			'Karataş',
			'İçme',
			'Düziçi',
			'Han',
			'Hani',
			'Gaziantep',
			'Felahiye',
			'Esendere',
			'Ergani',
			'Emirgazi',
			'Demiryol',
			'Akziyaret',
			'Çay',
			'Çatakköprü',
			'Nikfer',
			'Buca',
			'Bölükyazı',
			'Bodrum',
			'Babatorun',
			'Altınyayla',
			'Akçapınar',
			'Ahmetli',
			'Yeşildere',
			'Kuzeytepe',
			'Uzunbağ',
			'Batikent',
			'Zile',
			'Yavuzkemal',
			'Turhal',
			'Pendik',
			'Peçenek',
			'Osmancık',
			'Mudanya',
			'Marmara Ereğlisi',
			'Koyulhisar',
			'Kızılcahamam',
			'Kars',
			'Kabataş',
			'Hasköy',
			'Gölyaka',
			'Kömürlü',
			'Gölköy',
			'Evren',
			'Çandır',
			'Çamlıkaya',
			'Eminbey',
			'Biga',
			'Beyçayırı',
			'Aydıntepe',
			'Halıdere',
			'Ataşehir',
			'Sultangazi',
			'Ovacık',
			'Yarpuzlu',
			'Yakapınar',
			'Tepealtı',
			'Taşlı',
			'Şuhut',
			'Söke',
			'Selendi',
			'Sarısu',
			'Patnos',
			'Kırıkhan',
			'Karpuzlu',
			'Karakoçan',
			'İslahiye',
			'İskenderun',
			'İmamoğlu',
			'Hisarcık',
			'Hekimdağ',
			'Hatip',
			'Hasançelebi',
			'Harran',
			'Harput',
			'Hamzalı',
			'Güroymak',
			'Gönen',
			'Gölhisar',
			'Gölcük',
			'Göksu',
			'Gökçen',
			'Gelinkaya',
			'Evrenseki',
			'Ermenek',
			'Elmadağ',
			'Ekinözü',
			'Üçpınar',
			'Edremit',
			'Dörtyol',
			'Dombay',
			'Diyarbakır',
			'Davulga',
			'Dağbaşı',
			'Cizre',
			'Ceylanpınar',
			'Çelebi',
			'Çaylar',
			'Çamlıyayla',
			'Bucakkışla',
			'Bozoğlak',
			'Bademli',
			'Gerger',
			'Akdağmadeni',
			'Akçadağ',
			'Uzunisa',
			'Torul',
			'Tirebolu',
			'Sergen',
			'Şarköy',
			'Pazar',
			'Mecitözü',
			'Mahmut Şevket Paşa',
			'Karacabey',
			'İhsangazi',
			'Gürsu',
			'Esiroğlu',
			'Erdek',
			'Durağan',
			'Dökmetepe',
			'Doğanyurt',
			'Derince',
			'Çekerek',
			'Çayırhan',
			'Çakıralan',
			'Bulancak',
			'Bolu',
			'Arsin',
			'Alaca',
			'Akçaabat',
			'Espiye',
			'Çayırkent',
			'Ağabeyli',
			'Adana',
			'Büyük Dalyan',
			'Şenyurt',
			'Tonya',
			'Sürmene',
			'Sölöz',
			'Seydiler',
			'Sarıköy',
			'Ovacık',
			'Meydancık',
			'Küre',
			'Saltukova',
			'Kaptanpaşa',
			'Akkaya',
			'Gökdere',
			'Devrekani',
			'Dereköy',
			'Derecik',
			'Çerkezköy',
			'Çayeli',
			'Çatalzeytin',
			'Ardanuç',
			'Alaçam',
			'Polateli',
			'Yerkesik',
			'Yatağan',
			'Viranşehir',
			'Üçkavak',
			'Tekman',
			'Tahir',
			'Sivrihisar',
			'Side',
			'Sarayönü',
			'Şaphane',
			'Samsat',
			'Özpınar',
			'Ovacık',
			'Obruk',
			'Kutlubey',
			'Kuluncak',
			'Kızılağaç',
			'Kayapınar',
			'Kayabağlar',
			'Kaman',
			'Kahramanmaraş',
			'Ilıcalar',
			'Honaz',
			'Havran',
			'Güre',
			'Güllük',
			'Göllü',
			'Gökçeyazı',
			'Gediz',
			'Durak',
			'Dorumali',
			'Domaniç',
			'Doğanpınar',
			'Derbent',
			'Davutlar',
			'Çığlı',
			'Çığır',
			'Çat',
			'Çameli',
			'Buldan',
			'Bozova',
			'Böğürtlen',
			'Bayraklı',
			'Barla',
			'Alakamış',
			'Akpınar',
			'Yalangöz',
			'Talas',
			'Silopi',
			'Silifke',
			'Sarıoğlan',
			'Sağkaya',
			'Reis',
			'Ovakışla',
			'Ortaköy',
			'Öncül',
			'Oğuz',
			'Nusaybin',
			'Nevşehir',
			'Mut',
			'Malatya',
			'Korkut',
			'Kemalpaşa',
			'Keban',
			'Kayadibi',
			'Karayazı',
			'Kale',
			'Kale',
			'Kadışehri',
			'Ilıca',
			'Hazro',
			'Güzelbağ',
			'Yenisu',
			'Güleçler',
			'Girmeli',
			'Gerger',
			'Gebiz',
			'Erzincan',
			'Elvanlı',
			'Diyadin',
			'Danişment',
			'Çayıralan',
			'Buharkent',
			'Bozüyük',
			'Beylikova',
			'Bağlıca',
			'Ayrancı',
			'Aslanapa',
			'Antakya',
			'Hacıpaşa',
			'Samankaya',
			'Topçam',
			'Susuz',
			'Söğütpınar',
			'Şenkaya',
			'Şalpazarı',
			'Perşembe',
			'Pehlivanköy',
			'Pazarköy',
			'Ortaköy',
			'Mimarsinan',
			'Mecidiye',
			'Kumbağ',
			'Kıbrıscık',
			'Kaytazdere',
			'Beycuma',
			'Karaurgan',
			'Kağızman',
			'İkizören',
			'Nurluca',
			'Gürgentepe',
			'Gölcük',
			'Gökçebey',
			'Eğerci',
			'Eflani',
			'Doğanşar',
			'Doğankent',
			'Çubuk',
			'Çamlıdere',
			'Büyükçekmece',
			'Buğdaylı',
			'Boyalıca',
			'Bayat',
			'Ayancık',
			'Sancaktepe',
			'Karabağlar',
			'Cumayeri',
			'İlkadım',
			'Kepez',
			'Demirözü',
			'Çiftlikköy',
			'Cerrah',
			'Çekmeköy',
			'Beşikdüzü',
			'Banarlı',
			'Aksu',
			'Çatalhöyük',
			'Bahçelievler',
			'Taşburun',
			'Ümraniye',
			'Yenişakran',
			'Yeniceoba',
			'Yaylı',
			'Ula',
			'Tecirli',
			'Tavşanlı',
			'Suruç',
			'Sorgun',
			'Sivas',
			'Tililan',
			'Sille',
			'Şenoba',
			'Sarıbeyler',
			'Saimbeyli',
			'Mahmutlar',
			'Kurtalan',
			'Kocahasanlı',
			'Kemer',
			'Hafik',
			'Adala',
			'Çiftlikköyü',
			'Cevizlik',
			'Çetinkaya',
			'Çatak',
			'Besni',
			'Belevi',
			'Araban',
			'Altınyayla',
			'Acarlar',
			'Basaran',
			'Körfez',
			'Uğurludağ',
			'Tuzluca',
			'Tacir',
			'Selim',
			'Samandıra',
			'Şahin',
			'Piraziz',
			'Osmaneli',
			'Narlıca',
			'Mudurnu',
			'Küplü',
			'Küçükdere',
			'Kılıçkaya',
			'İnegol',
			'Hacıhamza',
			'Güneyce',
			'Gümüşçay',
			'Göllüce',
			'Murgul',
			'Yayvantepe',
			'Yeşilüzümlü',
			'Üçdam',
			'Turunçova',
			'Tufanbeyli',
			'Sülüklü',
			'Soma',
			'Sındırgı',
			'Sarıgöl',
			'Sancak',
			'Sağınlı',
			'Palu',
			'Nazilli',
			'Mursal',
			'Muradiye',
			'Mahmudiye',
			'Kırobası',
			'Maden',
			'Kuşadası',
			'Konalga',
			'Kiğı',
			'Karaçoban',
			'Ilıca',
			'Hilal',
			'Gedikler',
			'Dorutay',
			'Özvatan',
			'Çırpı',
			'Cengerli',
			'Çelikhan',
			'Çağlarca',
			'Bigadiç',
			'Belören',
			'Belen',
			'Behram',
			'Avanos',
			'Akören',
			'Adilcevaz',
			'Gündeş',
			'Zeytinbağı',
			'Boztepe',
			'Nallıhan',
			'Kurtkale',
			'Evreşe',
			'İnece',
			'Görükle',
			'Eskipazar',
			'Dambaslar',
			'Boyalık',
			'Balçık',
			'Akyaka',
			'Arıköy',
			'Pamukkale',
			'Zonguldak',
			'Yenipazar',
			'Tütüncü',
			'Şeyhli',
			'Saraydüzü',
			'Ovacuma',
			'Kurucaşile',
			'Kötek',
			'Kilimli',
			'Kestel',
			'Kemalpaşa',
			'Kandıra',
			'Kale',
			'Ilgaz',
			'İkizdere',
			'Havza',
			'Hanak',
			'Gündoğdu',
			'Güdül',
			'Göynük',
			'Enez',
			'Cemilbey',
			'Çakırlı',
			'Alaplı',
			'Köprübaşı',
			'Yıldırım',
			'Kozak',
			'Yeşilyurt',
			'Tepecikören',
			'Taşpınar',
			'Bakırdağı',
			'Şenocak',
			'Selçuk',
			'Savcılı',
			'Pertek',
			'Ortyakent Yahşi',
			'Midyat',
			'Meydan',
			'Mazıdağı',
			'Mazgirt',
			'Kulp',
			'Köprüören',
			'Karaman',
			'İçmeler',
			'Hizan',
			'Hınıs',
			'Göynük',
			'Doğankavak',
			'Doğanbey',
			'Değirmendere',
			'Çiftlik',
			'Ceyhan',
			'Çaltılıbük',
			'Çakırlar',
			'Bozcaada',
			'Boğazlıyan',
			'Beşpınar',
			'Bekilli',
			'Bağlıca',
			'Atburgazı',
			'Arslanköy',
			'Altınoluk',
			'Akseki',
			'Akçay',
			'Yozgat',
			'Yerköy',
			'Yeniköy',
			'Yaprakbaşı',
			'Yalıhüyük',
			'Siirt',
			'Sarayköy',
			'Pasinler',
			'Örenşehir',
			'Ören',
			'Narlı',
			'Mağaralı',
			'Kuyucak',
			'Küçüksu',
			'Kilis',
			'Keskin',
			'Kalkan',
			'İkizce',
			'İçören',
			'Selçuklu',
			'Hilvan',
			'Haydarlı',
			'Hacıbektaş',
			'Payamlı',
			'Gaziemir',
			'Foça',
			'Ertuğrul',
			'Dicle',
			'Dedeli',
			'Dağlıca',
			'Çavuşlu',
			'Büyük Kardeş',
			'Bozkurt',
			'Bozkır',
			'Borlu',
			'Argıthanı',
			'Aralık',
			'Anamur',
			'Aşağıokçular',
			'Yakuplu',
			'Tekkeköy',
			'Reşadiye',
			'Pınarbaşı',
			'Niksar',
			'Meriç',
			'Kumburgaz',
			'Kocadere',
			'Kastamonu',
			'Kargı',
			'Oğuzlar',
			'İntepe',
			'Gümüşova',
			'Göynücek',
			'Gölova',
			'Gediksaray',
			'Eymir',
			'Digor',
			'Çöpköy',
			'Çamoluk',
			'Adalar',
			'Bilecik',
			'Atkaracalar',
			'Arnavutköy',
			'Amasra',
			'Ortaköy',
			'Zeytinliova',
			'Yanarsu',
			'Pınarbaşı',
			'Taşağıl',
			'Sultanhanı',
			'Seyitgazi',
			'Şehit Nusretbey',
			'Pütürge',
			'Ovakavağı',
			'Narince',
			'Manavgat',
			'Kumdanlı',
			'Köprülü',
			'Koçali',
			'Kırkgeçit',
			'Kemer',
			'Kaymaz',
			'İşgören',
			'Gülyazı',
			'Gesi',
			'Doğanşehir',
			'Devecikonağı',
			'Derik',
			'Dağkızılca',
			'Çınar',
			'Çiftehan',
			'Çamlıdere',
			'Birecik',
			'Aras',
			'Ahmetli',
			'Ahırlı',
			'Acıpınar',
			'Mahmutlar',
			'Yusufeli',
			'Yeşilvadi',
			'Yeşilce',
			'Yağlıdere',
			'Ulaşlı',
			'Taşköprü',
			'Sarıbuğday',
			'Muratlı',
			'Mezraa',
			'Istanbul',
			'İnhisar',
			'Durusu',
			'Kürtün',
			'Çamlıhemşin',
			'Boyabat',
			'Asarcık',
			'Bakacak',
			'Alıcık',
			'Akyazı',
			'Adapazarı',
			'Beylikdüzü',
			'Termal',
			'Tepebaşı',
			'Yumurtalık',
			'Yiğitler',
			'Yeşildere',
			'Yaylak',
			'Uluborlu',
			'Tunceli',
			'Tekirova',
			'Sultandağı',
			'Şarkışla',
			'Reyhanlı',
			'Pınarbaşı',
			'Ortaköy',
			'Kumlu',
			'Kumçatı',
			'Kulu',
			'Köprüköy',
			'Kınık',
			'Karapınar',
			'Hüyük',
			'Halfeti',
			'Gündoğmuş',
			'Gümüşkent',
			'Gölgelikonak',
			'Göktepe',
			'Geyikli',
			'Germencik',
			'Erentepe',
			'Bulucan',
			'Çolaklı',
			'Çokak',
			'Celâlli',
			'Çaylı',
			'Bozova',
			'Bozhüyük',
			'Bitlis',
			'Banaz',
			'Aydın',
			'Andırın',
			'Alpu',
			'Aktuzla',
			'Aksu',
			'Akköy',
			'Akhisar',
			'Büyükçat',
			'Yalova',
			'Tavşancıl',
			'Taflan',
			'Seben',
			'Posof',
			'Ormanlı',
			'Ordu',
			'Of',
			'Marmara',
			'Kullar',
			'Dikmen',
			'Karakasım',
			'Hasayaz',
			'Gümüş',
			'Gökçesu',
			'Gökçekent',
			'Gerede',
			'Gelibolu',
			'Erfelek',
			'Edincik',
			'Karşıyaka',
			'Çanakçı',
			'Ballı',
			'Balıklıçeşme',
			'Arıt',
			'Çamiçi',
			'Altınova',
			'Muratbey',
			'Nilüfer',
			'Yenipazar',
			'Reşadiye',
			'Yardımcı',
			'Şanlıurfa',
			'Ulukışla',
			'Üçtepe',
			'Toprakkale',
			'Tahtaköprü',
			'Süleymanlı',
			'Sarız',
			'Pulumer',
			'Poyraz',
			'Oyalı',
			'Mutki',
			'Murat',
			'Muradiye',
			'Mordoğan',
			'Söylemez',
			'Göcek',
			'Kocaaliler',
			'Kaynakkaya',
			'İvrindi',
			'Iğdır',
			'Hasanbeyli',
			'Tuzla',
			'Fethiye',
			'Evciler',
			'Ereğli',
			'Güneysınır',
			'Dilektepe',
			'Çifteler',
			'Çan',
			'Çamardı',
			'Çal',
			'Büyükyurt',
			'Burdur',
			'Bozburun',
			'Bozalan',
			'Barıştepe',
			'Tillo',
			'Aksaray',
			'Akköy',
			'Ahlat',
			'Pekmezli',
			'Zeytinlik',
			'Üsküdar',
			'Tokat',
			'Sungurlu',
			'Şerefiye',
			'Selimpaşa',
			'Olur',
			'Manyas',
			'Ladik',
			'Kavak',
			'Kabalı',
			'İğdir',
			'Horasan',
			'Hamidiye',
			'Göle',
			'Dereli',
			'Çağlayan',
			'Bereketli',
			'Belören',
			'Bayburt',
			'Bağcılar',
			'Akçakoca',
			'Celâliye',
			'Adalan',
			'Başakşehir',
			'Muratpaşa',
			'Erzin',
			'Yenipınar',
			'Yalınca',
			'Turgutlu',
			'Tomarza',
			'Taşlıçay',
			'Taraksu',
			'Sürgü',
			'Solhan',
			'Sarıköy',
			'Pozantı',
			'Akpazar',
			'Ortaköy',
			'Muratlı',
			'Kurthasanlı',
			'Kozluk',
			'Korkuteli',
			'Kızılsu',
			'Karlıova',
			'Karakoyunlu',
			'Kadirli',
			'İshaklı',
			'İhsaniye',
			'Alacakaya',
			'Harmancık',
			'Hankendi',
			'Halkapınar',
			'Güzeloluk',
			'Gözler',
			'Gömeç',
			'Eskişehir',
			'Düvertepe',
			'Çandarlı',
			'Bayat',
			'Bağdere',
			'Antalya',
			'Akarsu',
			'Ağlasun',
			'Çırtıman',
			'Açıkdere',
			'Yomra',
			'Velimeşe',
			'Veliköy',
			'Sinop',
			'Muratlı',
			'Kofçaz',
			'Kocaali',
			'Boyalı',
			'Hamzabey',
			'Gürpınar',
			'Güneysu',
			'Görele',
			'Fındıklı',
			'Eldivan',
			'Doğanyurt',
			'Destek',
			'Çilimli',
			'Çaykara',
			'Çamaş',
			'Bahçecik',
			'Mollafeneri',
			'İçmeler',
			'Merkezefendi',
			'Yeşilova',
			'Yenifoça',
			'Ulubey',
			'Tepecik',
			'Tanır',
			'Sulak',
			'Şefaatlı',
			'Savur',
			'Şarkîkaraağaç',
			'Sarıyahşi',
			'Pazarören',
			'Oymataş',
			'Oğuz',
			'Mürşitpınar',
			'Güneşli',
			'Kışlak',
			'Kılbasan',
			'Keles',
			'Salmanlı',
			'Kasımlar',
			'Karacadağ',
			'Kandilli',
			'İscehisar',
			'Ilgın',
			'Hocalar',
			'Hıdırbaba',
			'Günyüzü',
			'Güney',
			'Güçlükonak',
			'Gökçeören',
			'Gemerek',
			'Dursunbey',
			'Doğubayazıt',
			'Dinar',
			'Darıkent',
			'Dalyan',
			'Çukurca',
			'Çivril',
			'Çevrimova',
			'Çatalan',
			'Çaldıran',
			'Baskil',
			'Arguvan',
			'Akşehir',
			'Akkışla',
			'Akçaova',
			'Adaklı',
			'Acıgöl',
			'Ballı',
			'Yuvacık',
			'Yeşilyurt',
			'Yalnızçam',
			'Üsküp',
			'Türkeli',
			'Tortum',
			'Taşköprü',
			'Susuzmüsellim',
			'Sırpsındığı',
			'Sapanca',
			'Safranbolu',
			'Pazar',
			'Ortaköy',
			'Mengen',
			'Kurşunlu',
			'Kozcağız',
			'Korgun',
			'Keşan',
			'Kazan',
			'Göbel',
			'Gaziler',
			'Doğançay',
			'Başgedikler',
			'Araç',
			'Osmangazi',
			'Sur',
			'Küçükkendirci',
			'Dursunlu',
			'Günyazı',
			'Yenişehir',
			'Yağlıdere',
			'Vize',
			'Ovacık',
			'Tekke',
			'Teke',
			'Taşova',
			'Serdivan',
			'Kozlu',
			'Kıyıköy',
			'Kirazlı',
			'İnebolu',
			'Hanönü',
			'Dağbaşı',
			'Büyükköy',
			'Alanyurt',
			'Akıncılar',
			'Ağlı',
			'Merter Keresteciler',
			'Palandöken',
			'Doğruyol',
			'Yoncalı',
			'Yeşilyurt',
			'Yenişarbademli',
			'Yeniköy',
			'Yavuzeli',
			'Yarpuz',
			'Üzümlü',
			'Ürgüp',
			'Toptepe',
			'Tepe',
			'Avine',
			'Yenice',
			'Servi',
			'Serik',
			'Şereflikoçhisar',
			'Sarıçam',
			'Saraykent',
			'Salavatlı',
			'Özalp',
			'Ortabağ',
			'Ömerköy',
			'Oğuz',
			'Milas',
			'Mihalıçcık',
			'Menemen',
			'Kuşsarayı',
			'Kocakoç',
			'Kaynar',
			'Kayalıpınar',
			'Karaisalı',
			'Gyundyukoru',
			'Hamur',
			'Gülşehir',
			'Gölmarmara',
			'Göldüzü',
			'Geçitli',
			'Elbistan',
			'Hakkâri',
			'Evren',
			'Bucak',
			'Bozan',
			'Bornova',
			'Belören',
			'Balışeyh',
			'Aydıncık',
			'Arıcak',
			'Yüreğir',
			'Altınova',
			'Adıyaman',
			'Gümüşgöze',
			'Balıklıdere',
			'Türkler',
			'Tavşanlı',
			'Şavşat',
			'Sarıkamış',
			'Saraycık',
			'Ortaköy',
			'Orta',
			'Karacaköy',
			'İğdir',
			'Hopa',
			'Hamamözü',
			'Geyve',
			'Gebze',
			'Erbaa',
			'Dernekpazarı',
			'Demirtaş',
			'Almus',
			'Ölüdeniz',
			'Kürecik',
			'Yavu',
			'Payas',
			'Turgut',
			'Topaklı',
			'Tatvan',
			'Suluçem',
			'Sultanhisar',
			'Kindirip',
			'Şirvan',
			'Silvan',
			'Seydişehir',
			'Seksenören',
			'Şekerli',
			'Sarıkonak',
			'Özbek',
			'Ovabağ',
			'Musabeyli',
			'Musabeyli',
			'Kumköy',
			'Kızılören',
			'Kızılkaya',
			'Kırka',
			'Kaş',
			'Aladağ',
			'Karaağıl',
			'Kadınhanı',
			'İzmir',
			'İliç',
			'Hıdırbey',
			'Hasbek',
			'Güzelsu',
			'Gökçe',
			'Fındık',
			'Sumbas',
			'Dargeçit',
			'Cumaçay',
			'Çiçekli',
			'Beyçayırı',
			'Bergama',
			'Bedirli',
			'Bayındır',
			'Balıkesir',
			'Akçakent',
			'Yeşilhisar',
			'Yenice',
			'Uzunkuyu',
			'Ulaş',
			'Tire',
			'Tepeüstü',
			'Taraksu',
			'Sarıkaya',
			'Saray',
			'Polatlı',
			'Pazarköy',
			'Osmancalı',
			'Ortaca',
			'Nizip',
			'Muğla',
			'Kumluca',
			'Serinhisar',
			'Kızılcadağ',
			'Kavaklıdere',
			'Karkamış',
			'İdil',
			'Horsunlu',
			'Hasköy',
			'Aydınlar',
			'Gökdere',
			'Gökçedağ',
			'Gökçebağ',
			'Göçbeyli',
			'Gencek',
			'Eymirli',
			'Erdemli',
			'Delice',
			'Deliçay',
			'Çobanlar',
			'Çemişgezek',
			'Çardak',
			'Bayramiç',
			'Bayır',
			'Balpayam',
			'İbradı',
			'Aralık',
			'Ankara',
			'Akıncılar',
			'Uzunköprü',
			'Tosya',
			'Söğütalan',
			'Şiran',
			'Şabanözü',
			'Piraziz',
			'Pamukova',
			'Mustafakemalpaşa',
			'Lüleburgaz',
			'Kurşunlu',
			'Köprübaşı',
			'Konuralp',
			'Kırklareli',
			'Kılıç',
			'Kaynarca',
			'Karamürsel',
			'Karabulduk',
			'Yarhisar',
			'İkizce',
			'Güzelkent',
			'Perşembe',
			'Esenler',
			'Doğruyol',
			'Dodurga',
			'Darıca',
			'Çamlıbel',
			'Camili',
			'Babaeski',
			'Aşağı Irmaklar',
			'Ardeşen',
			'Akmeşe',
			'Turgutreis',
			'Çankaya',
			'Yenice',
			'Umraniye',
			'Tepebaşı',
			'Şırnak',
			'Sevinç',
			'Selimiye',
			'Selçikler',
			'Payallar',
			'Pamukkale',
			'Nurhak',
			'Narlı',
			'Mucur',
			'Meydankapı',
			'Marmaris',
			'Kuşkayası',
			'Köseli',
			'Korucu',
			'Kırşehir',
			'Keçiborlu',
			'Karakeçili',
			'Karaçay',
			'Hotamış',
			'Hadim',
			'Gözeli',
			'Göynük',
			'Gevaş',
			'Erzurum',
			'Erciş',
			'Emirdağ',
			'Eğil',
			'Doğanköy',
			'Doğankent',
			'Melikgazi',
			'Çatalarmut',
			'Çardak',
			'Çandır',
			'Çakırhüyük',
			'Çağlayancerit',
			'Bingöl',
			'Beyağaç',
			'Bahçe',
			'Aşkale',
			'Danişment',
			'Aliağa',
			'Aşağı Karafakılı',
			'Uzungöl',
			'Ünye',
			'Ulus',
			'Terme',
			'Şevketiye',
			'Saray',
			'Pazar',
			'Kemerburgaz',
			'Kaynaşlı',
			'Kavaklı',
			'Karasu',
			'Kalecik',
			'Hayrat',
			'Gönen',
			'Gölpazarı',
			'Gerze',
			'Eceabat',
			'Düzce',
			'Çorum',
			'Çorlu',
			'Çınarcık',
			'Çerçiler',
			'Çeltikçi',
			'Çarşamba',
			'Beypazarı',
			'Arifiye',
			'Aksakal',
			'Güce',
			'Akçaova',
			'Çaybağı',
			'Yeniköy',
			'Yeditepe',
			'Yarma',
			'Varto',
			'Van',
			'Sütlüce',
			'Taşdelen',
			'Sivaslı',
			'Kayacık',
			'Mezraa',
			'Mercimekkale',
			'Mamak',
			'Küçükkuyu',
			'Kemaliye',
			'Kâhta',
			'Kabala',
			'Yuntdağ',
			'İmranlı',
			'Halitpaşa',
			'Halilçavuş',
			'Gercüş',
			'Gedikbaşı',
			'Evciler',
			'Eruh',
			'Elmalı',
			'Dereyanı',
			'Dağyolu',
			'Çüngüş',
			'Menderes',
			'Çayırlı',
			'Çaybağı',
			'Çağış',
			'Bor',
			'Birgi',
			'Beytüşşebap',
			'Babadağ',
			'Atabey',
			'Aşağıpınarbaşı',
			'Arapgir',
			'Altınyaka',
			'Akşar',
			'Akıncı',
			'Akçakale',
			'Acıpayam',
			'Yağmurdere',
			'Umurbey',
			'Tatkavaklı',
			'Taraklı',
			'Hemşin',
			'Ortakent',
			'Marmaracık',
			'Malkara',
			'Küplü',
			'Kışlaköy',
			'Karayaka',
			'Kabadüz',
			'İznik',
			'İskilip',
			'Hereke',
			'Giresun',
			'Çatalca',
			'Bursa',
			'Bolaman',
			'Bafra',
			'Amasya',
			'Battalgazi',
			'Salıpazarı',
			'Altınordu',
			'Zara',
			'Yücebağ',
			'Yeşilyurt',
			'Didim',
			'Yalvaç',
			'Uşak',
			'Tefenni',
			'Tarsus',
			'Sulusaray',
			'Sincan',
			'Sinanköy',
			'Şamlı',
			'Demirci',
			'Minare',
			'Kurşunlu',
			'Kocapınar',
			'Kılavuz',
			'Kepsut',
			'Kayapa',
			'Karaköprü',
			'Kalkım',
			'İnönü',
			'Himmetdede',
			'Haymana',
			'Harbiye',
			'Hacılar',
			'Haberli',
			'Güzelsu',
			'Gümüşsu',
			'Dallıbahçe',
			'Dalama',
			'Dağardı',
			'Çiftlik',
			'Çavdır',
			'Çalpınar',
			'Burhaniye',
			'Başkavak',
			'Balya',
			'Balcı',
			'Balaban',
			'Aydınkonak',
			'Kale',
			'Aşağı Beğdeş',
			'Aran',
			'Andaç',
			'Altunhisar',
			'Alaçatı',
			'Güvenç',
			'Boynuyoğun',
			'Yapraklı',
			'Vezirhan',
			'Umraniye',
			'Söğütlü',
			'Samsun',
			'Rize',
			'Narman',
			'Lapseki',
			'Köse',
			'Kapaklı',
			'İpsala',
			'İbriktepe',
			'Çayırova',
			'Güvem',
			'Gözyeri',
			'Büyükderbent',
			'Damal',
			'Çerkeş',
			'Çankırı',
			'Ondokuzmayıs',
			'Ezinepazarı',
			'Araklı',
			'Kazımpaşa',
			'Akçasır',
			'Odunpazarı',
			'Demre',
			'Yeşilyazı',
			'Altpınar',
			'Yenibaşak',
			'Tavas',
			'Tanyeri',
			'Sütçüler',
			'Söğütlü',
			'Simav',
			'Sandıklı',
			'Sakçagöz',
			'Pazarlar',
			'Ortaca',
			'Balveren',
			'Mardin',
			'Manisa',
			'Kütahya',
			'Kızılyaka',
			'Kızılören',
			'Kızılcabölük',
			'Kiraz',
			'Kemer',
			'Hazar',
			'Kargı',
			'Hatunsaray',
			'Güvercinlik',
			'Gürpınar',
			'Gümüşakar',
			'Finike',
			'Eşme',
			'Eşen',
			'Emiralem',
			'Eleşkirt',
			'Dodurga',
			'Dinek',
			'Derinkuyu',
			'Damlacık',
			'Çavuş',
			'Armutlu',
			'Bozarmut',
			'Baykan',
			'Balpınar',
			'Bağpınar',
			'Altıntaş',
			'Alaşehir',
			'Akdiken',
			'Dalaman',
			'Yeniköy',
			'Uzundere',
			'Umurbey',
			'Tekirdağ',
			'Şişli',
			'Şenpazar',
			'Mesudiye',
			'Kolay',
			'Kalkandere',
			'İnecik',
			'Hayrabolu',
			'Gündoğdu',
			'Ferizli',
			'Derepazarı',
			'Çaycuma',
			'Çalı',
			'Laçin',
			'Sarıcakaya',
			'Beyazköy',
			'Başçiftlik',
			'Alucra',
			'Akşar',
			'Şehitkamil',
			'Şahinbey',
		],
	},
	Turkmenistan: {
		country: 'Turkmenistan',
		cities: [
			'Saýat',
			'Abadan',
			'Yolöten',
			'Balkanabat',
			'Ashgabat',
			'Magtymguly',
			'Yylanly',
			'Tagta',
			'Akdepe',
			'Türkmenbaşy',
			'Serhetabat',
			'Änew',
			'Serdar',
			'Arçabil',
			'Boldumsaz',
			'Türkmenabat',
			'Gumdag',
			'Mary',
			'Gowurdak',
			'Seydi',
			'Farap',
			'Gazojak',
			'Kaka',
			'Baharly',
			'Kerki',
			'Bayramaly',
			'Köneürgench',
			'Daşoguz',
			'Tejen',
			'Murgab',
			'Bereket',
		],
	},
	'Turks and Caicos Islands': { country: 'Turks and Caicos Islands', cities: ['Cockburn Town'] },
	Tuvalu: {
		country: 'Tuvalu',
		cities: ['Kulia Village', 'Fakaifou Village', 'Funafuti', 'Savave Village', 'Asau Village', 'Tanrake Village', 'Toga Village', 'Alapi Village'],
	},
	Uganda: {
		country: 'Uganda',
		cities: [
			'Mukono',
			'Kisoro',
			'Kigorobya',
			'Bwizibwera',
			'Busia',
			'Bundibugyo',
			'Nakapiripirit',
			'Mbarara',
			'Kamwenge',
			'Kamuli',
			'Iganga',
			'Lyantonde',
			'Ibanda',
			'Buhweju',
			'Wobulenzi',
			'Mitoma',
			'Hoima',
			'Adjumani',
			'Kyankwanzi',
			'Napak',
			'Lamwo',
			'Kiboga',
			'Kasese',
			'Buikwe',
			'Namasuba',
			'Kaliro',
			'Bugembe',
			'Rukungiri',
			'Kyenjojo',
			'Kampala',
			'Jinja',
			'Bududa',
			'Abim',
			'Moroto',
			'Masindi Port',
			'Kanungu',
			'Isingiro',
			'Mbale',
			'Lira',
			'Kilembe',
			'Kayunga',
			'Apac',
			'Ntungamo',
			'Nsika',
			'Kyegegwa',
			'Bweyogerere',
			'Bushenyi',
			'Amolatar',
			'Kole',
			'Rubirizi',
			'Pader Palwo',
			'Kiryandongo',
			'Bukedea',
			'Serere',
			'Njeru',
			'Masindi',
			'Kanoni',
			'Arua',
			'Amudat',
			'Oyam',
			'Wakiso',
			'Nebbi',
			'Luuka Town',
			'Kotido',
			'Kajansi',
			'Entebbe',
			'Byakabanda',
			'Agago',
			'Nyachera',
			'Kitgum',
			'Kibale',
			'Kaberamaido',
			'Kaabong',
			'Paidha',
			'Namutumba',
			'Maracha',
			'Koboko',
			'Kalungu',
			'Binyiny',
			'Kampala Central Division',
			'Moyo',
			'Kumi',
			'Kalangala',
			'Dokolo',
			'Bukwa',
			'Budaka',
			'Amuria',
			'Amuru',
			'Ntungamo',
			'Ngora',
			'Nakasongola',
			'Mpigi',
			'Katakwi',
			'Buwenge',
			'Butebo',
			'Otuke',
			'Yumbe',
			'Mayuge',
			'Kiruhura',
			'Kabale',
			'Butaleja',
			'Soroti',
			'Rubanda',
			'Mubende',
			'Luwero',
			'Gulu',
			'Mityana',
			'Kireka',
			'Kibingo',
			'Kagadi',
			'Pader',
			'Margherita',
			'Tororo',
			'Sironko',
			'Muhororo',
			'Kyotera',
			'Buliisa',
			'Zombo',
			'Rakai',
			'Lugazi',
			'Alebtong',
			'Kitamilo',
			'Pallisa',
			'Nakaseke',
			'Lwengo',
			'Kibuku',
			'Kapchorwa',
			'Buyende',
			'Bukomansimbi',
			'Kakumiro',
			'Gombe',
			'Fort Portal',
			'Sembabule',
			'Ntoroko',
			'Masaka',
			'Busembatia',
			'Bugiri',
			'Nwoya',
			'Manafwa',
			'Namayingo',
			'Bulambuli',
		],
	},
	Ukraine: {
		country: 'Ukraine',
		cities: [
			'Shyrokolanivka',
			'Shypyntsi',
			'Savran',
			'Sadove',
			'Roylyanka',
			'Rodnikovo',
			'Ripky',
			'Rakiv Lis',
			'Pukhivka',
			'Ivanivka',
			'Pohrebyshche',
			'Pysarivka',
			'Perehonivka',
			'Orativ',
			'Novoselivka',
			'Poltavka',
			'Neresnytsia',
			'Moloha',
			'Myrne',
			'Mena',
			'Lysyanka',
			'Lutsk',
			'Lepetykha',
			'Khrustalnyi',
			'Krasne',
			'Kolochava',
			'Karapchiv',
			'Kamiane',
			'Iza',
			'Ispas',
			'Hnizdychiv',
			'Handrabury',
			'Furmanivka',
			'Mirnovka',
			'Doroshivka',
			'Chudniv',
			'Chernihivka',
			'Chelyadinovo',
			'Bystrytsia',
			'Buryn',
			'Brovary',
			'Borodianka',
			'Yakymivka',
			'Varash',
			'Zolochiv',
			'Zmiiv',
			'Zaporizhzhya',
			'Zaliznychne',
			'Yuryivka',
			'Vvedenka',
			'Voznesenka Persha',
			'Vinogradnoye',
			'Verkhniy Yasenov',
			'Velykodolynske',
			'Velyki Sorochyntsi',
			'Velyka Buda',
			'Velyka Bilozerka',
			'Turgenevka',
			'Turbiv',
			'Tsvitkove',
			'Trykraty',
			'Talne',
			'Strumok',
			'Staryi Krym',
			'Starokostyantyniv',
			'Stal’noye',
			'Voznesenske',
			'Shklo',
			'Shyroke',
			'Sharhorod',
			'Rodynske',
			'Rafalivka',
			'Pohreby',
			'Pidhaitsi',
			'Pionerskoye',
			'Peschanoye',
			'Perechyn',
			'Osychky',
			'Nove Davydkovo',
			'Novoukrayinka',
			'Novosamarka',
			'Novohryhorivka',
			'Novobohdanivka',
			'Nove-Misto',
			'Nyzhnia Krynka',
			'Nyzhnia Duvanka',
			'Kryve Ozero Druhe',
			'Matviivka',
			'Massandra',
			'Marfovka',
			'Lyman',
			'Kupiansk',
			'Kruty',
			'Krasnotorka',
			'Krasnopillia',
			'Krasnopavlivka',
			"Krasna Zor'ka",
			'Korytne',
			'Koropets',
			'Kompaniyivka',
			'Kivertsi',
			'Kyrnasivka',
			'Holovanivsk',
			'Chutove',
			'Chervonopartyzansk',
			'Chervonohrad',
			'Pulyny',
			'Chernivtsi',
			'Cherneche',
			'Bannivka',
			'Zalizne',
			'Oleksandrivka',
			'Nova Dolyna',
			'Ahronomiya',
			'Zelenivka',
			'Zelenohirske',
			'Yuzhynets',
			'Yermakovo',
			'Yemilchyne',
			'Voznesensk',
			'Vitrianka',
			'Veselyy Kut',
			'Verkhorechye',
			'Veresayevo',
			'Velykyi Dalnyk',
			'Vary',
			'Uvarovo',
			'Ulychne',
			'Tsarychanka',
			'Ternopil',
			'Teplytsia',
			'Stara Nekrasivka',
			'Slavnoye',
			'Syniukhyn Brid',
			'Shchebetovka',
			'Shatsk',
			'Serpneve',
			'Pryluky',
			'Prymors’ke',
			'Prymorsk',
			'Pokotylivka',
			'Pobuzke',
			'Pniv',
			'Petrivske',
			'Pishchanka',
			'Pischchana',
			'Pershotravneve',
			'Perehinske',
			'Pavlysh',
			'Parutyne',
			'Pantayivka',
			'Ozerna',
			'Ostrivne',
			'Vilshanka',
			'Novopetrivske',
			'Novopetrivka',
			'Novomykolaivka',
			'Novogrigoryevka',
			'Lashkivka',
			'Nyzy',
			'Mykolayivka',
			'Mykolaivka',
			'Nestoita',
			'Nadrichne',
			'Mohyliv-Podilskyy',
			'Mikhaylovka',
			"Mar'yinka",
			'Malokaterynivka',
			'Stantsiyne',
			'Livyntsi',
			'Leninskoye',
			'Krutenky',
			'Pokrovsk',
			'Horbova',
			'Komarivtsi',
			'Koloski',
			'Khotiv',
			'Khotyn',
			'Ichnia',
			'Eskhar',
			'Dobromyl',
			'Dykanka',
			'Davydivka',
			'Chunkiv',
			'Chornianka',
			'Chernivtsi',
			'Chereshenka',
			'Vesnyane',
			'Borodino',
			'Krynychne',
			'Shchaslyve',
			'Beryslav',
			'Barvinkove',
			'Oleksandrivka',
			'Smyha',
			'Nekrasovo',
			'Suvorovo',
			'Chervonoye',
			'Stepove',
			'Motovylivka',
			'Lativka',
			'Smotrych',
			'Tarasivtsi',
			'Zahnitkiv',
			'Vysokopillya',
			'Volovets',
			'Vilne',
			'Vyshneve',
			'Vinogradovo',
			'Verkhni Petrivtsi',
			'Verblyany',
			'Velykokomarivka',
			'Usatove',
			'Vuhlehirsk',
			"Tur'i Remety",
			'Dovzhansk',
			'Stara Tsarychanka',
			'Solonka',
			'Shyroke',
			'Sloboda-Komarivtsi',
			'Sevastopol',
			'Serhiivka',
			'Serby',
			'Romny',
			'Ralivka',
			"P'yatypill'ya",
			'Pidbuzh',
			'Onokivtsi',
			'Okunevka',
			'Okhotskoye',
			'Nechayane',
			'Molodohvardiisk',
			'Molochansk',
			'Mizoch',
			'Maly Mayak',
			'Muromskoye',
			'Mahdalynivka',
			'Ladan',
			'Kutsurub',
			'Kushuhum',
			'Kurakhovo',
			'Hrushivka',
			'Krymskoye',
			'Krynychne',
			'Krynychky',
			'Kremenchuk',
			'Krasnohorivka',
			'Kotlovyna',
			'Komarno',
			'Kobyletska Polyana',
			'Podviryvka',
			'Kaniv',
			'Kamiani Potoky',
			'Kalinino',
			'Hirnyk',
			'Horishni Sherivtsi',
			'Drabiv',
			'Chornivka',
			'Bytkiv',
			'Boikivshchyna',
			'Martynivske',
			'Berezivka',
			'Bilohirya',
			'Bilyne',
			'Bilyky',
			'Bazaliya',
			'Barativka',
			'Aromatnoye',
			'Karakurt',
			'Stara Zhadova',
			'Yasky',
			'Yahilnytsia',
			'Vvedenka',
			'Vilnohirsk',
			'Vikno',
			'Velykyy Kuchuriv',
			'Vashkivtsi',
			'Stebnyk',
			'Staroye Selo',
			'Sovetskiy',
			'Simeiz',
			'Shyroke',
			'Shepetivka',
			'Sataniv',
			'Safyany',
			'Rybakivka',
			'Ruzhyn',
			'Roksolany',
			'Rokosovo',
			'Pustomyty',
			'Pushkino',
			'Polyanetske',
			'Petrove',
			'Perebykivtsi',
			'Orlivka',
			'Novodnistrovsk',
			'Nedra',
			'Moshanets',
			'Morshyn',
			'Molodizhne',
			'Myrne',
			'Mykhaylivka',
			'Usatove',
			'Lutuhyne',
			'Kulykivka',
			'Krasnyi Mak',
			'Krasnohrad',
			'Krasnoarmeyskoye',
			'Kiliya',
			'Kacha',
			'Iziaslav',
			'Izobil’noye',
			'Horlivka',
			'Gurzuf',
			'Horodnytsia',
			'Hodynivka',
			'Henichesk',
			'Doroshivtsi',
			'Dobroye',
			'Dykhtynets',
			'Krasnoye',
			'Chornukhyne',
			'Chernopolye',
			'Chahor',
			'Bilshivtsi',
			'Inhulka',
			'Bernove',
			'Velyki Berehy',
			'Berdychiv',
			'Balamutivka',
			'Balabyne',
			'Arkhanhelske',
			'Alushta',
			'Kukushkino',
			'Salhany',
			'Myrne',
			'Hlybochok',
			'Zorkino',
			'Zaliznyy Port',
			'Yenakiieve',
			'Voskresenske',
			'Vorzel',
			'Veselovka',
			'Shevchenkove',
			'Velyki Mosty',
			'Velyka Novosilka',
			'Tsybuliv',
			'Teofipol',
			'Sary-Bash',
			'Svarychiv',
			'Susanino',
			'Ilyinka',
			'Skhidnytsia',
			'Skadovsk',
			'Partizany',
			'Snovsk',
			'Rivne',
			'Rososhany',
			'Rozsypne',
			'Radcha',
			'Prybuzhzhya',
			'Petrivka',
			'Pavlohrad',
			'Ozerne',
			'Odesa',
			'Novopoltavka',
			'Nova Odesa',
			'Myronivka',
			'Petrashivka',
			'Melekyne',
			'Maryevka',
			'Makiivka',
			'Lutovynivka',
			'Lypcha',
			'Kryzhopil',
			'Kovel',
			'Korosten',
			'Koloniya Zastav’ye',
			'Klivodyn',
			'Komyshuvakha',
			'Kamyanka',
			'Kalinovka',
			'Skosarivka',
			'Ilarionove',
			'Illinka',
			'Hrozyntsi',
			'Hostomel',
			'Horinchovo',
			'Holmivskyi',
			'Hoholeve',
			'Halych',
			'Dvorichna',
			'Dnipryany',
			'Derhachi',
			'Derazhnya',
			'Demydove',
			'Chynadiyovo',
			'Cherniiv',
			'Chorna',
			'Cherkaske',
			'Chemerivtsi',
			'Bryukhovychi',
			'Brodetske',
			'Velyka Balka',
			'Bohdan',
			'Donskoye',
			'Bershad',
			'Baksha',
			'Skorokhodove',
			'Arbuzynka',
			'Novosel’skoye',
			'Teplodar',
			'Komsomol’skoye',
			'Senokosnoye',
			'Molodizhne',
			'Zhydachiv',
			'Zelenogorskoye',
			'Yarove',
			'Vyshchetarasivka',
			'Vynohradivka',
			'Vesele',
			'Verchnia Rozhanka',
			'Oleshky',
			'Trapivka',
			'Zhuravychi',
			'Zhmerynka',
			'Zhemchuzhina',
			'Zavorychi',
			'Zarechnoye',
			'Bunhe',
			'Yalta',
			'Vorobyovo',
			'Uglovoye',
			'Turka',
			'Truskavets',
			'Sutysky',
			'Stizhkivske',
			'Botanicheskoye',
			'Stayky',
			'Stari Bohorodchany',
			'Starokozache',
			'Stara Sil',
			'Synevyrska Poliana',
			'Rzhyshchiv',
			'Richky',
			'Radens’k',
			'Pryyutivka',
			'Primorskiy',
			'Pryazovske',
			'Koktebel',
			'Otyniia',
			'Oleksandriya',
			'Novyi Yarychiv',
			'Novovorontsovka',
			'Novohuivynske',
			'Nevytske',
			'Nedoboyvtsi',
			'Myhove',
			'Mala Vyska',
			'Magazinka',
			'Lymany',
			'Lenkivtsi',
			'Lanchyn',
			'Krasnolesye',
			'Teple',
			'Kostryzhivka',
			'Kosmach',
			'Kostiantynivka',
			'Kolomyia',
			'Kolchyno',
			'Kobleve',
			'Kyslytsya',
			'Kehychivka',
			'Kapitanivka',
			'Kalynivka',
			'Kadubivtsi',
			'Hrubna',
			'Grishino',
			'Hradyzk',
			'Holma',
			'Hnidyn',
			'Filatovka',
			'Fastiv',
			'Dunayivtsi',
			'Dubliany',
			'Dmytrivka',
			'Dalnyk',
			'Chopovychi',
			'Oskil',
			'Cherniakhiv',
			'Chornoliztsi',
			'Kalynove',
			'Bushtyno',
			'Brayiliv',
			'Boianchuk',
			'Bochkivtsi',
			'Bobovo',
			'Bezliudivka',
			'Belaya',
			'Bairachky',
			'Askaniya-Nova',
			'Andriivka',
			'Ilichanka',
			'Kopashnovo',
			'Kirove',
			'Orekhovo',
			'Pochetnoye',
			'Sovkhoznoye',
			'Khlibodarske',
			'Husiatyn',
			'Stalnivtsi',
			'Stakhanovka',
			'Spaske',
			'Spas',
			'Sloviansk',
			'Skelivka',
			'Skalat',
			'Shramkivka',
			'Shershentsi',
			'Rubizhne',
			'Rovenky',
			'Prybuzske',
			'Petrovske',
			'Petrykivka',
			'Ozerne',
			'Novi Broskivtsi',
			'Novotroyitske',
			'Novokrymskoye',
			'Hreyhove',
			'Nova Kakhovka',
			'Nadvirna',
			'Naddnipryanske',
			'Molodyozhnoye',
			'Melnytsya-Podilska',
			'Melioratyvne',
			'Makiv',
			'Liutizh',
			'Lomachyntsi',
			'Labushne',
			'Kumari',
			'Krasnyi Kut',
			'Krasnopillya',
			'Koson',
			'Kherson',
			'Soledar',
			'Kamyanka',
			'Ivanivka',
			'Ivankivtsi',
			'Gornostayevka',
			'Zelenyy Hay',
			'Hlobyne',
			'Haysyn',
			'Frunze',
			'Drahovo',
			'Dolynyany',
			'Dobrianka',
			'Oleksandriyske',
			'Chervona Sloboda',
			'Chernihiv',
			'Cherepkivtsi',
			'Buzke',
			'Broska',
			'Blizhneye',
			'Berezovka',
			'Bereznehuvate',
			'Baybuzivka',
			'Artemivsk',
			'Abrikosovka',
			'Lupareve',
			'Orshivtsi',
			'Tayirove',
			'Trudovoye',
			'Novoozyornoye',
			"Nikol's’ke",
			'Volochysk',
			'Staryy Vovchynets',
			'Verkhovyna',
			'Verkhnesadovoye',
			'Velyka Mykhaylivka',
			'Vatutine',
			'Udobne',
			'Tysovets',
			'Tsybulivka',
			'Irmino',
			'Stebliv',
			'Stavchany',
			'Shebutyntsi',
			'Seredyna-Buda',
			'Sencha',
			'Rozvadiv',
			'Prudianka',
			'Prybuzhany',
			'Pnikut',
			'Pavlivka',
			'Ovidiopol',
			'Orlinoye',
			'Ordzhonikidze',
			'Obertyn',
			'Novy Svet',
			'Novhorodka',
			'Navariia',
			'Molochnoye',
			'Mykhaylo-Laryne',
			'Medvedevo',
			'Medvedevka',
			'Maryanivka',
			'Krinichnoye',
			'Lypovets',
			'Lykhivka',
			'Lanivtsi',
			'Kvasy',
			'Kryva Balka',
			'Krasyliv',
			'Kondratyevo',
			'Komariv',
			'Klenovyi',
			'Holubivka',
			'Khriatska',
			'Khmelnytskyi',
			'Yarivka',
			'Novofedorivka',
			'Komyshivka',
			'Kamyanka',
			'Hradenytsi',
			'Hayvoron',
			'Trybukhivtsi',
			'Dniprovka',
			'Znamyanka',
			'Boiarka',
			'Borzna',
			'Borivtsi',
			'Berezan',
			"Bilovods'k",
			'Bilousivka',
			'Bilozirka',
			'Avdiivka',
			'Oleksandrivka',
			'Tavriysk',
			'Solnechnoye',
			'Vinnitskoye',
			'Zheleznodorozhnoye',
			'Lymanka',
			'Hrushivka',
			'Zhdeniyevo',
			'Zinkiv',
			'Zavetnoye',
			'Zamostia',
			'Yaroslavka',
			'Vynohradiv',
			'Vlasivka',
			'Vyzyrka',
			'Vinogradnoye',
			'Vilino',
			'Velyki Korovyntsi',
			'Velykyy Bereznyy',
			'Vasylivka',
			'Varva',
			'Uzhgorod',
			'Tysmenytsia',
			'Tukhlia',
			'Talalaivka',
			'Stepnohirsk',
			'Stroyntsi',
			'Stara Vyzhivka',
			'Slavuta',
			'Skole',
			'Shelkovichnoye',
			'Shabo',
			'Serbychany',
			'Seliatyn',
			'Rusakovka',
			'Rozdilna',
			'Romankivtsi',
			'Rohatyn',
			'Razdol’noye',
			'Pidvynohradiv',
			'Pochayiv',
			'Petrovka',
			'Petropavlivka',
			'Novyi Svit',
			'Novoyelizavetivka',
			'Novoselovskoye',
			'Novooleksiyivka',
			'Mostyska',
			'Morskoye',
			'Mirny',
			'Yantarnoye',
			'Lukiv',
			'Litvinenkovo',
			'Lypniazhka',
			'Lypetske',
			'Lenino',
			'Kushnytsia',
			'Kreminna',
			'Kozyryany',
			'Koreiz',
			'Knyazhichi',
			'Zrub-Komarivskyy',
			'Katiuzhanka',
			'Glazovka',
			'Frontovoye',
			'Demnya',
			'Dobre',
			'Cherlenivka',
			'Cherkasy',
			'Chaplynka',
			'Pozharskoye',
			'Brylivka',
			'Bereznyky',
			'Berezan',
			'Bilousivka',
			'Bazaryanka',
			'Azovskoye',
			'Antoniny',
			'Oleksiivka',
			'Andreyevka',
			'Verkhni Stanivtsi',
			'Kurskoye',
			'Razdol’noye',
			'Horenka',
			'Avanhard',
			'Kostychany',
			'Zhelyabovka',
			'Zabolotiv',
			'Yampil’',
			'Vyhoda',
			'Voronkiv',
			'Volnovakha',
			'Vyzhenka',
			'Vytylivka',
			'Vasylivka',
			'Varvarivka',
			'Utkivka',
			'Tovste',
			'Tochylove',
			'Maslovo',
			'Tenistoye',
			'Tankovoye',
			'Svyatogorsk',
			'Stryzhavka',
			'Stari Kuty',
			'Stara Syniava',
			'Slatyne',
			'Sentianivka',
			'Saki',
			'Rynhach',
			'Rozhniv',
			'Rozivka',
			'Popilnia',
			'Poltava',
			'Plodovoye',
			'Pershotravneve',
			'Ostroh',
			'Oleshnyk',
			'Novovolynsk',
			'Novostepnoye',
			'Novopavlovka',
			'Nova Praha',
			'Nova Nekrasivka',
			'Nova Ivanivka',
			'Monastyryska',
			'Mykulyntsi',
			'Chudei',
			'Matroska',
			'Malyy Kuchuriv',
			'Lozno-Oleksandrivka',
			'Lazi',
			'Kuchurhan',
			'Kruhlyk',
			'Sorokyne',
			'Korotych',
			'Konotop',
			'Kodra',
			'Kobyzhcha',
			'Obukhivka',
			'Khust',
			'Kelmentsi',
			'Karapyshi',
			'Kamyanske',
			'Kaharlyk',
			'Izium',
			'Ivanivka',
			'Ivanovka',
			'Ivankiv',
			'Hupalivka',
			'Horodnye',
			'Golubinka',
			'Faraonivka',
			'Dudchany',
			'Dokuchaievsk',
			'Dniprorudne',
			'Valya Kuzmyna',
			'Dachnoye',
			'Bykivka',
			'Budey',
			'Bobryk Pershyy',
			'Blahodatne',
			'Blahodatne',
			'Berizky',
			'Bilozerka',
			'Auly',
			'Agrarnoye',
			'Roshchino',
			'Slobozhanske',
			'Chornomorske',
			'Blahodatne',
			'Zernovoye',
			'Zavet-Leninskiy',
			'Zalishchyky',
			'Yarkoye',
			'Voykovo',
			'Vishnyovka',
			'Vilnyansk',
			'Valyava',
			'Ukrainka',
			'Uhryniv',
			'Turiatka',
			'Staryi Saltiv',
			'Starobesheve',
			'Sokolinoye',
			'Snihurivka',
			'Smoline',
			'Serbka',
			'Semypolky',
			'Rzhavyntsi',
			'Rozivka',
			'Roskoshnoye',
			'Rivne',
			'Prostornoye',
			'Pryvilne',
			'Prylymanske',
			'Prykolotne',
			'Preobrazhenka',
			'Pokrovka',
			'Pidvolochysk',
			'Pidhorodna',
			'Petranka',
			'Oshykhliby',
			'Orikhivka',
			'Ochakiv',
			'Novyi Starodub',
			'Novosvitlivka',
			'Novoselivka',
			'Mamayvtsi',
			'Novomoskovsk',
			'Novoborysivka',
			'Nyzhni Stanivtsi',
			'Mykolaivka',
			'Moshny',
			'Molodiia',
			'Milove',
			'Zhuravlyovka',
			'Marynivka',
			'Maniava',
			'Malorechenskoye',
			'Lviv',
			'Luchistoye',
			'Lisne',
			'Mariia',
			'Kyiv',
			'Kuteinykove',
			'Kulynychi',
			'Lyman',
			'Krymskaya Roza',
			'Kozyrka',
			'Kozelshchyna',
			'Kostyantynivka',
			'Kostiantynivka',
			"Kal'mius'ke",
			'Kol’tsovo',
			'Kyryakivka',
			'Hubynykha',
			'Grushevka',
			'Gaspra',
			'Kalynivka',
			'Drobysheve',
			'Dovbysh',
			'Dolynske',
			'Dynivtsy',
			'Chechelnyk',
			'Bohodukhiv',
			'Bahate',
			'Velykyi Buialyk',
			'Bilyayivka',
			'Bila Krynytsia',
			'Baraboi',
			'Borshchi',
			'Kholmovka',
			"Hlyns'k",
			"Novoyavorivs'k",
			'Partyzanske',
			'Kalahliia',
			'Dovhopillya',
			'Shyshkivtsi',
			'Zastavna',
			'Yevpatoriya',
			'Yasinya',
			'Yamnytsia',
			'Vradiyivka',
			'Voznesenka',
			'Volodarka',
			'Verbivka',
			'Ustynivka',
			'Trykhaty',
			'Tryduby',
			'Tabaky',
			'Suvorovskoye',
			'Sursko-Mykhailivka',
			'Stara Ushytsya',
			'Murafa',
			'Sribne',
			'Solone',
			'Snizhne',
			'Synevyr',
			'Shyshkivtsi',
			'Shyrivtsi',
			'Shchyrets',
			'Shcherbynivka',
			'Ruska Poliana',
			'Rozkishne',
			'Rakoshyno',
			'Radekhiv',
			'Privetnoye',
			'Pohorilivka',
			'Ostrovskoye',
			'Orikhiv',
			'Opishnya',
			'Novi Bilokorovychi',
			'Novopokrovka',
			'Novokrasne',
			'Novoarkhanhelsk',
			'Nosivka',
			'Netishyn',
			'Nerubayske',
			'Nepolokivtsi',
			'Mysovoe',
			'Mazanka',
			'Markivka',
			'Novoorzhytske',
			'Lyubotyn',
			'Lyubymivka',
			'Krutoiarivka',
			'Krasnoznamenka',
			'Krasnosilka',
			'Krasnogvardeyskoye',
			'Kotsiubynske',
			'Kopaihorod',
			'Kolinkivtsy',
			'Klevan',
			'Khorol',
			'Kharkiv',
			'Kaydaki',
			'Kamyanka',
			'Mirnoye',
			'Ivanopil',
			'Romaniv',
			'Dymka',
			'Dovzhok',
			'Dmytrivka',
			'Debaltseve',
			'Chortomlyk',
			'Chernyakhivka',
			'Chornobay',
			'Cheresh',
			'Bilohirsk',
			'Baherove',
			'Oleksandrivka',
			'Alchevsk',
			'Levadki',
			'Prolisky',
			'Krasnokamenka',
			'Troiandove',
			'Kovyl’noye',
			'Zhashkiv',
			'Yevhenivka',
			'L’govskoye',
			'Vorokhta',
			'Vovchansk',
			'Vyshneve',
			'Vinkivtsi',
			'Velyka Bahachka',
			'Vasylivka',
			'Uspenivka',
			'Blahovishchenske',
			'Ukromnoye',
			'Tselinnoye',
			'Trypillia',
			'Striletskyy Kut',
			'Strabychovo',
			'Temriuk',
			'Serednie Vodiane',
			'Solotvyn',
			'Sniatyn',
			'Sizovka',
			'Shpykiv',
			'Mykhaylivka',
			'Shevchenkove',
			'Sheshory',
			'Shcherbanka',
			'Ruch’i',
			'Romodan',
			'Porohy',
			'Poshtove',
			'Pishcha',
			'Piskivka',
			'Parkhomivka',
			'Orzhytsya',
			'Olesko',
			'Novomyrhorod',
			'Voskhod',
			'Novoaidar',
			'Novoestoniia',
			'Nyzhni Sirohozy',
			'Mykolayivka',
			'Mashivka',
			'Mar’yanovka',
			'Malodolynske',
			'Mala Tokmachka',
			'Luhanske',
			'Lypova Dolyna',
			'Lymans’ke',
			'Lisky',
			'Lebedyn',
			'Lazurne',
			'Kulykiv',
			'Krinichnaya',
			'Krynychky',
			'Ostrytsya',
			'Kosiv',
			'Korniyivka',
			'Kamyanyy Mist',
			'Kaplivka',
			'Kamyana',
			'Irpin',
			'Ilychyovo',
			'Ilyichevo',
			'Hvardiiske',
			'Dzhankoi',
			'Yaremche',
			'Dobrovelychkivka',
			'Dobropillia',
			'Dobrynivtsi',
			'Chop',
			'Viitivka',
			'Boromlia',
			'Bohdanivka',
			'Blahodatne',
			'Bezimenne',
			'Bila Krynytsya',
			'Baryshivka',
			'Bahna',
			'Babyn',
			'Babanka',
			'Andrushky',
			'Dumeny',
			'Lebedyn',
			'Zolotyi Potik',
			'Zhvanets',
			'Yezupil',
			'Zatoka',
			'Vovkovyntsi',
			'Vyshnivets',
			'Vynohradivka',
			'Vynohradne',
			'Velykyi Burluk',
			'Tysmenychany',
			'Torkanivka',
			'Terny',
			'Terny',
			'Teresva',
			'Teplovka',
			'Tamaryne',
			'Talalaivka',
			'Ivanove',
			'Studenok',
			'Striukove',
			'Strogonovka',
			'Stepnoye',
			'Stanislav',
			'Sokal',
			'Simeikyne',
			'Semenivka',
			'Selyshche',
			'Sarata',
			'Salkove',
			'Potelych',
			'Popasna',
			'Polohy',
			'Kurisove',
			'Orzhiv',
			'Olyshivka',
			'Obodivka',
			'Novi Troyany',
			'Novi Sanzhary',
			'Novozhilovka',
			'Novosvitlivka',
			'Nova Dofinivka',
			'Nedryhailiv',
			'Murovani Kurylivtsi',
			'Malyntsi',
			'Malyn',
			'Maliyivka',
			'Luhansk',
			'Lugovoye',
			'Lymanske',
			'Leninskoye',
			'Kupka',
			'Kryva Balka',
			'Krasnoyilsk',
			'Kramatorsk',
			'Kolodeznoye',
			'Kitsman',
			'Khyriv',
			'Petrivka',
			'Hulyaypole',
			'Hrebinka',
			'Horodnia',
			'Horodyshche',
			'Toretsk',
			'Kubey',
			'Chernelytsia',
			'Dolishniy Shepit',
			'Buda',
			'Bilky',
			'Bekhtery',
			'Balkivtsi',
			'Armyansk',
			'Ananyiv',
			'Amurskoye',
			'Alupka',
			'Aleksandrovka',
			'Aeroflotskiy',
			'Malen’koye',
			'Prudovoye',
			'Staryi Dobrotvir',
			'Zelena',
			'Zakupne',
			'Velykoploske',
			'Velyka Mechetnya',
			'Turiisk',
			'Tomakivka',
			'Terpinnia',
			'Tarashcha',
			'Svitlodolynske',
			'Suvorove',
			'Sukhyi Lyman',
			'Storozhynets',
			'Stetseva',
			'Stanytsia Luhanska',
			'Slov`yanoserbsk',
			'Slavske',
			'Simferopol',
			'Shpola',
			'Pshenichnoye',
			'Serhiyivka',
			'Sedniv',
			'Sambir',
			'Sakhnovshchyna',
			'Yurkivtsi',
			'Rozivka',
			'Ropcha',
			'Reshetylivka',
			'Ridkivtsi',
			'Pyatykhatky',
			'Pryvoroky',
			'Poroshkovo',
			'Poninka',
			'Pidhirne',
			'Plavni',
			'Pervomaysk',
			'Perevalsk',
			'Osypenko',
			'Oleksandrivka',
			'Kobzartsi',
			'Novofedorivka',
			'Nemyriv',
			'Mykolaiv',
			'Miusynsk',
			'Mikhaylovka',
			'Medenychi',
			'Liubeshiv',
			'Ladyzhyn',
			'Bilmak',
			'Kulishivka',
			'Kovalivka',
			'Koteleve',
			'Kerstentsi',
			'Horoshivtsi',
			'Kalynivka',
			'Ivano-Frankivsk',
			'Dzvinogrud',
			'Dolynska',
			'Chornukhy',
			'Chasiv Yar',
			'Bucha',
			'Broshniv-Osada',
			'Bolekhiv',
			'Berezivka',
			'Bashtanka',
			'Balaklava',
			'Apostolove',
			'Akimovka',
			'Lekarstvennoye',
			'Vol’noye',
			'Nova Pryluka',
			'Olenevka',
			'Yarmolyntsi',
			'Yarkoye Pole',
			'Kopani',
			'Verkhivtseve',
			'Velyka Berezovytsya',
			'Tsvetochnoye',
			'Trudove',
			'Trostyanets',
			'Chystyakove',
			'Tokmak',
			'Terebleche',
			'Tarutyne',
			'Sudak',
			'Sosnytsia',
			'Sobolivka',
			'Slavyanskoye',
			'Shevchenkove',
			'Shcherbani',
			'Semisotka',
			'Silets',
			'Sebyne',
			'Rozhniativ',
			'Roztoky',
			'Sartana',
			'Pomichna',
			'Polianka',
			'Pyriatyn',
			'Petrivka',
			'Petropavlivka',
			'Pavlivka',
			'Panyutyne',
			'Olyka',
			'Novoselytsia',
			'Nova Haleshchyna',
			'Nikolayevka',
			'Monastyryshche',
			'Mykhaylivka',
			'Mishkovo-Pohorilove',
			'Merefa',
			'Mayskoye',
			'Liubomyrka',
			'Lozova',
			'Lokachi',
			'Livadia',
			'Lisovi Sorochyntsi',
			'Krasnoflotskoye',
			'Kotelva',
			'Slobozhans’ke',
			'Kol’chugino',
			'Khrystoforivka',
			'Kholodna Balka',
			'Khodoriv',
			'Kozatske',
			'Kazanka',
			'Kamyane',
			'Izyumovka',
			'Yosypivka',
			'Husiatyn',
			'Partenit',
			'Dunayivtsi',
			'Duboviazivka',
			'Donetsk',
			'Dnistrivka',
			'Delzhyler',
			'Desantne',
			'Chulakivka',
			'Chistopolye',
			'Esman',
			'Chornohuzy',
			'Buzovytsya',
			'Bukachivtsi',
			'Bratske',
			'Bohorodchany',
			'Berehove',
			'Bilenke',
			'Baranivka',
			'Balky',
			'Adzhamka',
			'Narkevychi',
			'Perekhrestove',
			'Pershotravneve',
			'Novi Chobruchi',
			'Horodok',
			'Druzhkivka',
			'Vasylivka',
			'Znamyanka Druha',
			'Znamyanka',
			'Zhuravno',
			'Zemlyanichnoye',
			'Yurkivka',
			'Vorozhba',
			'Voloka',
			'Voinka',
			'Velyka Dymerka',
			'Uman',
			'Tyvriv',
			'Tuzly',
			'Troyitske',
			'Troyitske',
			'Tymoshivka',
			'Tovtry',
			'Tarakaniv',
			'Svalyava',
			'Stavkove',
			'Stari Broskivtsi',
			'Shalyhyne',
			'Siversk',
			'Serebryanka',
			'Savyntsi',
			'Romashkino',
			'Pereiaslav',
			'Peresichna',
			'Pechenizhyn',
			'Ostrytsya',
			'Novoselivka',
			'Novoselivka',
			'Novytsia',
			'Nova Borova',
			'Nizhyn',
			'Nasypnoe',
			'Mityayevo',
			'Lysa Hora',
			'Lubny',
			'Lopatyn',
			'Krest’yanovka',
			'Kozova',
			'Kinetspil',
			'Kakhovka',
			'Izvaryne',
			'Horodok',
			'Horodenka',
			'Foros',
			'Buran',
			'Dolinnoye',
			'Dovhe',
			'Dobrooleksandrivka',
			'Cherniatyn',
			'Chornobaivka',
			'Chapayevka',
			'Berehomet',
			'Trudovoye',
			'Barturyn',
			'Balovne',
			'Sterche',
			'Orzhiv',
			'Rovnoye',
			'Kostochkovka',
			'Novofedorovka',
			'Kulykiv',
			'Brytivka',
			'Yarkoye Pole',
			'Pervomayske',
			'Urzuf',
			'Zolotonosha',
			'Mostove',
			'Zhytomyr',
			'Zavallya',
			'Karolino-Buhaz',
			'Zahvizdia',
			'Yats’kivka',
			'Vyshkovo',
			'Vysunsk',
			'Valky',
			'Tynne',
			'Tsebrykove',
			'Troyitske',
			'Terebovlia',
			'Stepanivka',
			'Syvaske',
			'Shturmovoye',
			'Rudky',
			'Rodatychi',
			'Rakhiv',
			'Putyla',
			'Pryamobalka',
			'Manhush',
			"Pereval'noye",
			'Pavlivka',
			'Ovruch',
			"Oktyabr'skoye",
			'Nyrkiv',
			'Nuino',
			'Dubki',
			'Novohradkivka',
			'Novopokrovka',
			'Nova Mayachka',
			'Mliiv',
			'Miliyeve',
			'Mikhalkove',
			'Michurinskoye',
			'Makariv',
			'Luzhany',
			'Lotskyne',
			'Loshchynivka',
			'Kuyalnyk',
			'Krolevets',
			'Kryva Hora',
			'Konoplyane',
			'Klavdiievo-Tarasove',
			'Kyrnychky',
			'Izmail',
			'Ilovays’k',
			'Hvardiyske',
			'Lesnovka',
			'Duliby',
			'Dubliany',
			'Domanivka',
			'Dmytrivka',
			'Chuhynka',
			'Buzinove',
			'Brianka',
			'Borysivka',
			'Bilhorod-Dnistrovskyi',
			'Ostanino',
			'Brusenky',
			'Mel’nichnoye',
			'Pervomayskoye',
			'Chabany',
			'Soltanivka',
			'Zybiny',
			'Zvenihorodka',
			'Zorynsk',
			'Zolotoye Pole',
			'Zolochiv',
			'Zlynka',
			'Zghurivka',
			'Zarechnoye',
			'Yasynuvata',
			'Voinyliv',
			'Voyevodske',
			'Vilne',
			'Vyshneve',
			'Vinnytsya',
			'Velyki Birky',
			'Velykosillya',
			'Vasyshcheve',
			'Vasyliv',
			'Ukrainsk',
			'Trokhizbenka',
			'Toshkivka',
			'Tetiiv',
			'Boykivske',
			'Taborivka',
			'Syrove',
			'Stanivtsi',
			'Solotvyno',
			'Sokyryany',
			'Sofiyivka',
			'Skalistoye',
			'Serebriia',
			'Rudnytsya',
			'Repuzhyntsi',
			'Rozdory',
			'Privetnoye',
			'Priozyornoye',
			'Pomoriany',
			'Pervomayskoye',
			'Rykove',
			'Vilshana',
			'Novoyehorivka',
			'Novopokrovka',
			'Murovane',
			'Mykhalcha',
			'Minyaylivka',
			'Malokakhovka',
			'Lokhvytsya',
			'Lyubashivka',
			'Krasne',
			'Kotel’nikovo',
			'Kormovoye',
			'Kobelyaky',
			'Kirovo',
			'Khmilnyk',
			'Khartsyzk',
			'Kamyane',
			'Kalyny',
			'Kalanchak',
			'Isayeve',
			'Illintsi',
			'Hlyboka',
			'Huty',
			'Hola Prystan',
			'Dymer',
			'Dubrovytsia',
			'Dnipro',
			'Dmytrivka',
			'Chkalovo',
			'Chaykino',
			'Burlacha Balka',
			'Bohuslav',
			'Bohdanivka',
			'Bogatoye',
			'Berezanka',
			'Bile',
			'Bilivtsi',
			'Banyliv',
			'Banyliv-Pidhirnyi',
			'Bakhmut',
			'Oleksandrivka',
			'Lisove',
			'Shkol’noye',
			'Yuzhnoukrayinsk',
			'Krasna Kosa',
			'Reni',
			'Hvozdavka Druha',
			'Zuhres',
			'Zimino',
			'Zboriv',
			'Zarozhany',
			'Yalta',
			'Yabluniv',
			'Vylkove',
			'Vozsiyatske',
			'Vynohradivka',
			'Uspenka',
			'Tsybli',
			'Trebukhiv',
			'Tokarevo',
			'Tyshkivka',
			'Klepinino',
			'Druzhba',
			'Stavchany',
			'Staryy Merchyk',
			'Stari Troyany',
			'Stara Kulna',
			'Serednye',
			'Solnechnogorskoye',
			'Slobidka',
			'Skala-Podilska',
			'Shumsk',
			'Shevchenkove',
			'Rudne',
			'Kurakhove',
			'Rozkishna',
			'Rava-Ruska',
			'Radushne',
			'Radisne',
			'Poliana',
			'Pavlivka',
			'Pashkivtsi',
			'Pasichna',
			'Partizanskoye',
			'Pokrov',
			'Novovasylivka',
			'Novosilske',
			'Novomykolayivka',
			'Novodonetske',
			'Nova Vodolaha',
			'Nekrasovka',
			'Shchyolkino',
			'Molnytsya',
			'Mezhvodnoye',
			'Mayaky',
			'Bayraky',
			'Liuboml',
			'Kuibyshevo',
			'Kryva',
			'Podilsk',
			'Kostyntsi',
			'Koriukivka',
			'Korsun-Shevchenkivskyy',
			'Korostyshiv',
			'Kolodenka',
			'Zarichne',
			'Khrystynivka',
			'Kerch',
			'Komyshnya',
			"Kam'yanka",
			'Kalinino',
			'Hvizd',
			'Huryivka',
			'Horodok',
			'Horodyshche',
			'Kyrylivka',
			'Holoby',
			'Hlynytsya',
			'Chortkiv',
			'Burylove',
			'Bratslav',
			'Nikita',
			'Berestechko',
			'Bilopillia',
			'Bilohorivka',
			'Avhustivka',
			'Antratsyt',
			'Okhtyrka',
			'Brusnytsia',
			'Fontany',
			'Konstantinovka',
			'Uvarovka',
			'Listvennoye',
			'Kamenolomnia',
			'Zernovoye',
			'Myrne',
			'Polihon',
			'Vartykivtsi',
			'Zhovti Vody',
			'Zarichne',
			'Zaozyornoye',
			'Zaliztsi',
			'Zaliznychne',
			'Yerky',
			'Yelyzavethradka',
			'Voronivka',
			'Vendychany',
			'Velykyy Bychkiv',
			'Velyka Pysarivka',
			'Vasilyevka',
			'Vasylkiv',
			'Vashkivtsi',
			'Vanchikivtsi',
			'Ustyluh',
			'Uralo-Kavkaz',
			'Trostianets',
			'Topory',
			'Tabachnoye',
			'Sychavka',
			'Stavrove',
			'Stavyshche',
			'Starobilsk',
			'Starychi',
			'Stanislavka',
			'Smila',
			'Sloboda',
			'Rashkiv',
			'Pryvillia',
			'Pryozerne',
			'Pravda',
			'Pidhirtsi',
			'Pylypets',
			'Petrivsk',
			'Pervomayskoye',
			'Peremyshliany',
			'Oprysheny',
			'Obukhiv',
			'Obroshyne',
			'Novoselytsya',
			'Novoselivka',
			'Novoselytsya',
			'Nyzhnye Selyshche',
			'Mykolayivka',
			'Koshulyany',
			'Mospyne',
			'Myrhorod',
			'Markova',
			'Mardarivka',
			'Malyatyntsi',
			'Liubech',
			'Lozuvatka',
			'Lobanovo',
			'Lysychovo',
			'Lyman',
			'Kryzhanivka',
			'Krymka',
			'Kremenets',
			'Krasne',
			'Yany Kapu',
			'Krasnokutsk',
			'Kotlyareve',
			'Kostopil',
			'Kosmach',
			'Kopychyntsi',
			'Kolomak',
			'Kholmske',
			'Hodyliv',
			'Karnaukhivka',
			'Hlukhiv',
			'Hvardiiske',
			'Hryshkivtsi',
			'Holovyne',
			'Hnivan',
			'Hadyach',
			'Enerhodar',
			'Dolyna',
			'Dyviziya',
			'Dihtiari',
			'Davydivtsi',
			'Chaykino',
			'Slobozhanske',
			'Chernyshevo',
			'Bobrovytsia',
			'Blyzniuky',
			'Bila Tserkva',
			'Berezhany',
			'Beregovoye',
			'Belz',
			'Bilytske',
			'Bashtankiv',
			'Antonivka',
			'Oleksandrivka',
			'Chernovo',
			'Bratskoye',
			'Polyushko',
			'Kremidivka',
			'Kirove',
			'Maiory',
			'Mykolayivske',
			'Zhovkva',
			'Zherebkove',
			'Yuzhne',
			'Yampil',
			'Voronovytsya',
			'Vladislavovka',
			'Vyzhnytsya',
			'Veselynove',
			'Nyzhni Petrivtsi',
			'Tyachiv',
			'Teplyk',
			'Svitiaz',
			'Sukhoverkhiv',
			'Sukholuzhzhia',
			'Sudova Vyshnia',
			'Stryi',
			'Stolbovoye',
			'Stepove',
			'Stary Krym',
			'Solonytsivka',
			'Sofiivka',
			'Sytkivtsi',
			'Shylivtsi',
			'Shakhtarsk',
			'Serebrianka',
			'Ratne',
			'Rokytne',
			'Pryshyb',
			'Pokrovske',
			'Pasytsely',
			'Novosyolovka',
			'Zviahel',
			'Novodruzhesk',
			'Nikopol',
			'Naydenovka',
			'Velyki Mezhyrichi',
			'Melitopol',
			'Larzhanka',
			'Letychiv',
			'Krayneye',
			'Krasnogvardeyskoye',
			'Krasnogorka',
			'Kozhanka',
			'Kornyn',
			'Kirovskoye',
			'Khorostkiv',
			'Khorosheve',
			'Kamyanka-Dniprovska',
			'Hrabove',
			'Hoshcha',
			'Horokhivske',
			'Hertsa',
			'Dolyns’ke',
			'Brody',
			'Bolhrad',
			'Bendzary',
			'Bilolissya',
			'Artsyz',
			'Sofiivka',
			'Izumrudnoye',
			'Pakharevka',
			'Luganskoye',
			'Sovkhoznoye',
			'Nove',
			'Pavlivka',
			'Ivano-Frankove',
			'Zhuravka',
			'Petrovirivka',
			'Zachepylivka',
			'Yasenove Druhe',
			'Vysokyi',
			'Vylok',
			'Vodiane',
			'Volodymyr-Volynskyi',
			'Vesela Dolyna',
			'Verkhnodniprovsk',
			'Velyka Lepetykha',
			'Vapnyarka',
			'Desna',
			'Sievierodonetsk',
			'Sofiyivka',
			'Smotrych',
			'Synelnykove',
			'Sekretarivka',
			'Rozhyshche',
			'Rivne',
			'Pyatikhatka',
			'Podvirne',
			'Perovo',
			'Perkivtsi',
			'Peresadivka',
			'Oster',
			'Olshanske',
			'Okhotnikovo',
			'Novi Petrivtsi',
			'Novopskov',
			'Novopetrivka',
			'Novoivanovka',
			'Novhorod-Siverskyi',
			'Nizhnegorskiy',
			'Muskatnoye',
			'Muravlivka',
			'Mezhova',
			'Oleksiyvka',
			'Marshyntsi',
			'Manchenky',
			'Lukovytsya',
			'Lozove',
			'Krasnoyarskoye',
			'Korop',
			'Korets',
			'Kobolchyn',
			'Klishkivtsi',
			'Kholmy',
			'Komysh-Zorya',
			'Kalynivske',
			'Ishun’',
			'Irshava',
			'Hrebenyky',
			'Dubno',
			'Drohobych',
			'Drachyntsi',
			'Dmitrovka',
			'Myrnohrad',
			'Desna',
			'Chyhyryn',
			'Chernozemnoye',
			'Voskresenka',
			'Berezyne',
			'Berdyansk',
			'Inkerman',
			'Bar',
			'Amvrosiivka',
			'Malynivka',
			'Horishni Plavni',
			'Kashtanovoye',
			'Zhuravli',
			'Kashtany',
			'Ivanovka',
			'Aviatorske',
			'Tsentralne',
			'Zavetnoye',
			'Yavkyne',
			'Verkhniy Rohachyk',
			'Velykyi Liubin',
			'Ternovka',
			'Ternavka',
			'Tabachnoye',
			'Starohorozhene',
			'Skvyra',
			'Shubranets',
			'Shyshaky',
			'Serhiyi',
			'Semenivka',
			'Sarny',
			'Sadzhavka',
			'Sadovoye',
			'Ruskoivanivka',
			'Rokytne',
			'Putyvl',
			'Petrodolynske',
			'Petrikov',
			'Pervomaisk',
			'Pereshchepyne',
			'Ozheve',
			'Novyy Buh',
			'Novomykolayivka',
			'Nelipyno',
			'Nadlymanske',
			'Mitrofanovka',
			'Mynai',
			'Mizhhirya',
			'Mezenivka',
			'Medzhybizh',
			'Mariupol',
			'Marazliyivka',
			'Mamalyha',
			'Malynivka',
			'Mala Bilozerka',
			'Lazeshchyna',
			'Kryvyy Rih',
			'Krymka',
			'Krasnen’koye',
			'Krasna',
			'Kochetok',
			'Shirokoye',
			'Kyselivka',
			'Kyrykivka',
			'Kozyatyn',
			'Kamianets-Podilskyi',
			'Kalush',
			'Kairy',
			'Hvizdets',
			'Hornostayivka',
			'Hlukhivtsi',
			'Hlevakha',
			'Shtormovoye',
			'Dobrushino',
			'Zavodske',
			'Chervone',
			'Radyvyliv',
			'Chornorudka',
			'Chernomorskoye',
			'Cheponosy',
			'Burshtyn',
			'Brusyliv',
			'Borova',
			'Borova',
			'Boryspil',
			'Balakliia',
			'Bakhmach',
			'Bahrynivka',
			'Andriyevo-Ivanivka',
			'Andriyivka',
			'Alekseyevka',
			'Okny',
			'Denyshi',
			'Kriukivschina',
			'Dranytsya',
			'Vladychna',
			'Zavodske',
			'Zdovbytsia',
			'Zbarazh',
			'Yampil’',
			'Yablunytsia',
			'Vyhoda',
			'Voyutychi',
			'Vynohradar',
			'Verkhnyachka',
			'Velyki Kopani',
			'Vasylivka',
			'Batiovo',
			'Uzyn',
			'Utkonosivka',
			'Urozhaynoye',
			'Ulaniv',
			'Topaly',
			'Tomashpil',
			'Tlumach',
			'Ternivka',
			'Svetlodarsk',
			'Sukhovolya',
			'Storozhnytsia',
			'Yampil',
			'Sloboda',
			'Shyryayeve',
			'Shchastia',
			'Rykhtychi',
			'Rohan',
			'Radisnyy Sad',
			'Prosyana',
			'Prymorske',
			'Pobednoye',
			'Plakhtiyivka',
			'Pivdenne',
			'Parafiivka',
			'Onufriyivka',
			'Novonikolayevka',
			'Novoandreyevka',
			'Nova Ushytsya',
			'Mykolayivka',
			'Nerushay',
			'Muzhiievo',
			'Liubar',
			'Lopukhiv',
			'Lysets',
			'Kuty',
			'Kuriachi Lozy',
			'Tokarivka',
			'Kryve Ozero',
			'Krasnokamenka',
			'Koroviia',
			'Kyseliv',
			'Kirovskoye',
			'Katerynopil',
			'Kamianytsia',
			'Kalcheva',
			'Kalanchak',
			'Ivanivtsi',
			'Chornomors’k',
			'Hrytsiv',
			'Hrymailiv',
			'Gresovskiy',
			'Dubivtsi',
			'Dubove',
			'Druzhba',
			'Raukhivka',
			'Chuhuiv',
			'Chisten’koye',
			'Chervonohryhorivka',
			'Buky',
			'Borshchiv',
			'Bibrka',
			'Bobivtsi',
			'Berlohy',
			'Berezna',
			'Belinskoye',
			'Sinitsyno',
			'Abrikosovo',
			'Aromatnoye',
			'Sofiyivska Borschagivka',
			'Sakharnaya Golovka',
			'Zelenyi Hai',
			'Dubynove',
			'Zdolbuniv',
			'Zalesnoye',
			'Yemelyanovka',
			'Dneprovka',
			'Vypasne',
			'Vovchynets',
			'Vyshnivchyk',
			'Vynohradove',
			'Vynohradne',
			'Verkhnie Synevydne',
			'Velykyi Kliuchiv',
			'Velyka Oleksandrivka',
			'Ust-Chorna',
			'Troyitsko-Safonove',
			'Ternuvate',
			'Svetloye',
			'Svitlovodsk',
			'Subottsi',
			'Stavyshche',
			'Kadiyivka',
			'Solnechnaya Dolina',
			'Snyachiv',
			'Slavhorod',
			'Skvortsovo',
			'Shostka',
			'Selydove',
			'Sekretarka',
			'Siedove',
			'Sarazhinka',
			"Rybach'e",
			'Rukshyn',
			'Rozdil',
			'Rokytne',
			'Rechka',
			'Puzhaykovo',
			'Dyakivtsi',
			'Pidhorodne',
			'Pershotravensk',
			'Makariv Yar',
			'Olenivka',
			'Oktyabr’skoye',
			'Novopokrovka',
			'Mykolayivka-Novorosiyska',
			'Nesvoya',
			'Nelypivtsi',
			'Nebyliv',
			'Narodychi',
			'Mykolayiv',
			'Mlyniv',
			'Myrivka',
			'Myhiia',
			'Marhanets',
			'Mahala',
			'Lukanivka',
			'Lozovoye',
			'Lysychansk',
			'Kutkivtsi',
			'Kripenskyi',
			'Tatariv',
			'Krasne',
			'Kozliv',
			'Kozelets',
			'Kivsharivka',
			'Korchivtsi',
			'Kulevcha',
			'Khrestivka',
			'Khrystoforivka',
			'Khymchyn',
			'Karlivka',
			'Karapchiv',
			'Komyshany',
			'Kaharlyk',
			'Ivashkiv',
			'Dachne',
			'Hryshyne',
			'Hnizdychne',
			'Hlavani',
			'Zakharivka',
			'Feodosiya',
			'Dniprovske',
			'Dashiv',
			'Dalekoye',
			'Chornomorka',
			'Bouzke',
			'Budy',
			'Budenets',
			'Boryslav',
			'Bobrynets',
			'Batal’noye',
			'Bakhchysarai',
			'Kosy',
			'Slavutych',
			'Beloglinka',
			'Yasnopolyanskoye',
			'Vladimirovka',
			'Geroyskoye',
			'Solnechniy',
			'Rozkvit',
			'Zelyonoye',
			'Zatyshshya',
			'Zorya',
			'Yelanets',
			'Yastrubynove',
			'Voykovo',
			'Volodymyrivka',
			'Volodymyrets',
			'Vynohradivka',
			'Verkhnia Syrovatka',
			'Verenchanka',
			'Uyutnoye',
			'Tulchyn',
			'Svatove',
			'Sumy',
			'Sucheveny',
			'Stepkivka',
			'Staryi Sambir',
			'Starosillya',
			'Sholokhove',
			'Semenivka',
			'Revne',
			'Radomyshl',
			'Prudy',
			'Polonne',
			'Pidkamin',
			'Pyrizhna',
			'Pervomaiskyi',
			'Panka',
			'Orlovskoye',
			'Oktyabr’skoye',
			"Novoazovs'k",
			'Nahirne',
			'Mukachevo',
			'Myrnopillia',
			'Mankivka',
			'Mala Danylivka',
			'Lukavtsi',
			'Luhyny',
			'Lityn',
			'Kryva Luka',
			'Krasnaya Polyana',
			'Korsuntsi',
			'Korolevo',
			'Dobroslav',
			'Kodyma',
			'Klembivka',
			'Kropyvnytskyy',
			'Kozatske',
			'Kozachi Laheri',
			'Kamyanyy Mist',
			'Kamin-Kashyrskyi',
			'Izobil’noye',
			'Yizhivtsi',
			'Yordaneshty',
			'Hvizdivtsi',
			'Hirske',
			'Hlyboke',
			'Halytsynove',
			'Fontanka',
			'Kamyanske',
			'Dmytrivka',
			'Deliatyn',
			'Buchach',
			'Boiany',
			'Berezovo',
			'Bili Oslavy',
			'Bilokurakyne',
			'Balta',
			'Avdiivka',
			'Andrushivka',
			'Zalesye',
			'Urozhaynoye',
			'Drofino',
			'Prypruttya',
			'Zuya',
			'Zymohiria',
			'Zhuravki',
			'Zavodivka',
			'Zarichchia',
			'Yavoriv',
			'Yahotyn',
			'Vyshhorod',
			'Vishennoye',
			'Vesele',
			'Verkhnia Bilka',
			'Verkhnotoretske',
			'Ukrainka',
			'Ukrayinka',
			'Toporivtsi',
			'Tatarbunary',
		],
	},
	'United Arab Emirates': {
		country: 'United Arab Emirates',
		cities: [
			'Musaffah',
			'Khawr Fakkān',
			'Ajman City',
			'Ras Al Khaimah City',
			'Umm Al Quwain City',
			'Zayed City',
			'Adh Dhayd',
			'Khalifah A City',
			'Al Wiqan',
			'Sharjah',
			'Al Ain City',
			'Maşfūţ',
			'Dibba Al-Hisn',
			'Dibba Al-Fujairah',
			'Ar Ruways',
			'Abu Dhabi',
			'Reef Al Fujairah City',
			'Muzayri‘',
			'Bani Yas City',
			'Al Shamkhah City',
			'Al Fujairah City',
			'Al Manāmah',
			'Murbaḩ',
			'Dubai',
		],
	},
	'United Kingdom': {
		country: 'United Kingdom',
		cities: [
			'Wrea Green',
			'Woolley',
			'Woodford Green',
			'Woodbridge',
			'Wiveliscombe',
			'Wingate',
			'Whyteleafe',
			'Whaplode',
			'West Coker',
			'Ware',
			'Wallasey',
			'Upper Poppleton',
			'Ufford',
			'Tring',
			'Trewen',
			'Trefnant',
			'Thurso',
			'Thruxton',
			'Thorne',
			'Thornbury',
			'Tempsford',
			'Tayport',
			'Tattenhall',
			'Swanley',
			'Sutton upon Derwent',
			'Steeton',
			'Stainborough',
			'Spofforth',
			'Southall',
			'Smethwick',
			'Shipham',
			'Shildon',
			'Shifnal',
			'Sherington',
			'Sedbergh',
			'Seahouses',
			'Saltburn-by-the-Sea',
			'Saint Osyth',
			'Saint Clears',
			'Ryton',
			'Ryhill',
			'Ruthin',
			'Renton',
			'Randalstown',
			'Ramsey',
			'Puriton',
			'Poundstock',
			'Poringland',
			'Pontarddulais',
			'Pencoyd',
			'Pembury',
			'Overstrand',
			'Ossett',
			'Olney',
			'Oakengates',
			'Nutfield',
			'Notton',
			'North Wraxall',
			'Newtownabbey',
			'Newquay',
			'Newport',
			'New Malden',
			'Newbridge',
			'Mundesley',
			'Mullion',
			'Mintlaw',
			'Minchinhampton',
			'Milton Keynes',
			'Mickleton',
			// 'Melbourne',
			'Masham',
			'Marston',
			'Market Warsop',
			'Long Sutton',
			'Long Ditton',
			'Long Clawson',
			'City of London',
			'Lockington',
			'Lochgilphead',
			'Loanhead',
			'Llanvaches',
			'Llanharry',
			'Llangynidr',
			'Llangwm',
			'Llanarth',
			'Leuchars',
			'Lennoxtown',
			'Leeds',
			'Kinnersley',
			'Kings Sutton',
			'Kings Langley',
			'Kingsland',
			'Kensington',
			'Kendal',
			'Kelty',
			'Keighley',
			'Irthlingborough',
			'Horndon on the Hill',
			'Holwick',
			'Hillside',
			'Higham Ferrers',
			'Hartley Wintney',
			'Halsham',
			'Great Torrington',
			'Driffield',
			'Great Barford',
			'Gosberton',
			'Gillingham',
			'Galston',
			'Flimby',
			'Fishguard',
			'Fernhurst',
			'Exhall',
			'Enniskillen',
			'Easington',
			'Earls Court',
			'Drybrook',
			'Draycott',
			'Dormansland',
			'Ditchingham',
			'Disley',
			'Datchworth',
			'Cropwell Bishop',
			'Corsley',
			'Coalburn',
			'Chesterfield',
			'Chelsfield',
			'Castlereagh',
			'Burwell',
			'Bulkington',
			'Bugbrooke',
			'Bryn',
			'Broughton Astley',
			'Brockhampton',
			'Briston',
			'Brecon',
			'Bramford',
			'Bow',
			'Bonnybridge',
			'Bolton',
			'Bierton',
			'Berwick-Upon-Tweed',
			'Barton-le-Clay',
			'Balmedie',
			'Aylesbury',
			'Auchtermuchty',
			'Ashington',
			'Almondsbury',
			'Airth',
			'Accrington',
			'Aboyne',
			'Aberdour',
			'Tumble',
			'Brynna',
			'Thorpe Hamlet',
			'Bradley Cross',
			'Collier Row',
			'Winscombe',
			"St Mary's",
			'Holloway',
			'Ballintoy Harbour',
			'Abridge',
			'Canning Town',
			'Yealmpton',
			'Worksop',
			'Wincanton',
			'White Waltham',
			'Whitburn',
			'Weybridge',
			'City of Westminster',
			'West End',
			'Ventnor',
			'Thornley',
			'Swadlincote',
			'Stithians',
			'Stevenage',
			'Spixworth',
			'Southam',
			'Sleights',
			'Sissinghurst',
			'Silverstone',
			'Silloth',
			'Shurdington',
			'Shanklin',
			'Salcombe',
			'Saint Columb Major',
			'Rutherglen',
			'Rugby',
			'Rossington',
			'Rochdale',
			'Ripponden',
			'Repton',
			'Plymouth',
			'Pilsley',
			'Papworth Everard',
			'Oxshott',
			'Orsett',
			'Orgreave',
			'Old Windsor',
			'North Elmsall',
			'Niton',
			'Newport-on-Tay',
			'Newarthill',
			'Nairn',
			'Moneymore',
			'Milnthorpe',
			'Marske-by-the-Sea',
			'Market Harborough',
			'Marden',
			'Mansfield',
			'Madeley',
			'Macduff',
			'Long Crendon',
			'Long Ashton',
			'Locharbriggs',
			'Llantrisant',
			'Llansawel',
			'Llanfairfechan',
			'Llandybie',
			'Llanddowror',
			'Little Amwell',
			'Lichfield',
			'Lesmahagow',
			'Lenham',
			'Ladybank',
			'Kirkham',
			'Kirk Ella',
			'Kintore',
			'Kilham',
			'Kilgetty',
			'Kempston',
			'Ingleby Greenhow',
			'Ibstock',
			'Huntingdon',
			'Hungerford',
			'Horwich',
			'Horsted Keynes',
			'Highbury',
			'Heysham',
			'Haverhill',
			'Hastings',
			'Hardingstone',
			'Gunnislake',
			'Guiseley',
			'Gretna',
			'Great Paxton',
			'Great Eccleston',
			'Grantham',
			'Giffnock',
			'Garvagh',
			'Garforth',
			'Fulwood',
			'Featherstone',
			'Farndon',
			'Eyton',
			'Exning',
			'Endon',
			'Emneth',
			'Easton',
			'Duntocher',
			'Dungannon',
			'Dunchurch',
			'Downton',
			'Dovercourt',
			'Dover',
			'Doddington',
			'Derby',
			'Delabole',
			'Deanshanger',
			'Darwen',
			'Dalmellington',
			'Cwmbran',
			'Curry Rivel',
			'Currie',
			'Cumnock',
			'Cosham',
			'Conon Bridge',
			'Cleator Moor',
			'Cemaes Bay',
			'Catterick',
			'Catford',
			'Castlemartin',
			'Castle Donington',
			'Camelford',
			'Cambuslang',
			'Caldercruix',
			'Burton Constable',
			'Burneside',
			'Brynmawr',
			'Bromley',
			'Bradfield',
			'Bottisham',
			'Borough Green',
			'Bolton upon Dearne',
			'Bolsover',
			'Billingshurst',
			'Bideford',
			'Bexley',
			'Barton under Needwood',
			'Barnard Castle',
			'Barkham',
			'Ballycastle',
			'Bacton',
			'Axminster',
			'Ashwell',
			'Ashley',
			'Arlesey',
			'Alfreton',
			'Aldbourne',
			'Flexbury',
			'Tonypandy',
			'Blaengwynfi',
			'Eastington',
			'Peasedown Saint John',
			'Camden Town',
			'Lamesley',
			'Copthorne',
			'East Ayton',
			'Maidenbower',
			'Kingston upon Thames',
			'Burnage',
			'Essington',
			'Chalton',
			'Blackheath',
			'Westergate',
			'Pontyclun',
			'Sonning Common',
			'Great Corby',
			'Rusthall',
			'Thamesmead',
			'Winchburgh',
			'Tullibody',
			'Corby Glen',
			'Yoxall',
			'Wrexham',
			'Withington',
			'Wing',
			'Wimborne Minster',
			'Willesden',
			'Wigginton',
			'Whittlesey',
			'Whitehaven',
			'Westonzoyland',
			'Waterlooville',
			'Wadhurst',
			'Twickenham',
			'Totternhoe',
			'Totland',
			'Tonbridge',
			'Titchfield',
			'Tarleton',
			'Tangmere',
			'Sutton Courtenay',
			'Stornoway',
			'Stony Stratford',
			'Stapleford',
			'Ellerker',
			'Eattington',
			'East Wittering',
			'Eastry',
			'East Hanney',
			'Eastchurch',
			'East Bridgford',
			'Earby',
			'Durrington',
			'Dunipace',
			'Dundry',
			'Doveridge',
			'Dagenham',
			'Cudworth',
			'Croft',
			'Credenhill',
			'Craven Arms',
			'Connor',
			'Compton',
			'Colnbrook',
			'Collingham',
			'Clipsham',
			'Clare',
			'Chulmleigh',
			'Chorleywood',
			'Chester',
			'Chatburn',
			'Chartham',
			'Charlbury',
			'Charfield',
			'Castle Douglas',
			'Capel',
			'Bugthorpe',
			'Bramley',
			'Bracknell',
			'Bourne End',
			'Boddam',
			'Blisworth',
			'Bishops Stortford',
			'Bilsthorpe',
			'Bicton',
			'Betley',
			'Bedworth',
			'Bedlington',
			'Barmouth',
			'Ballingry',
			'Badsworth',
			'Bacton',
			'Atherton',
			'Ash Vale',
			'Arne',
			'Armitage',
			'Annalong',
			'Annahilt',
			'Ancaster',
			'Abertillery',
			'Aberfeldy',
			'Abercarn',
			'Abercanaid',
			'East Cowes',
			'Camberwell',
			'Langstone',
			'Furnace Green',
			'Belsize Park',
			'Heywood',
			'Wimbledon Park',
			'Brixton Hill',
			'Benwell',
			'Llanrhaeadr-ym-Mochnant',
			'Appleby-in-Westmorland',
			'Water Eaton',
			'Perranarworthal',
			'Maryburgh',
			'Stroud Green',
			'Shepperton',
			'Sawbridgeworth',
			'Rowlstone',
			'Rosyth',
			'Ripley',
			'Ringwood',
			'Reading',
			'Rawcliffe',
			'Portishead',
			'Ponders End',
			'Pollington',
			'Plumpton Green',
			'Pittington',
			'Pilning',
			'Pevensey',
			'Peterlee',
			'Oxenhope',
			'Overton',
			'Oadby',
			'North Hill',
			'Newton Poppleford',
			'Narborough',
			'Mulbarton',
			'Mossley',
			'Middlestown',
			'Methil',
			'Markfield',
			'Magheralin',
			'Longwick',
			'Long Melford',
			'Long Lawford',
			'Llysfaen',
			'Llantwit Major',
			'Llanfair Caereinion',
			'Little Weighton',
			'Linthwaite',
			'Leigh-on-Sea',
			'Lechlade',
			'Layer de la Haye',
			'Langport',
			'Kirkbymoorside',
			'Kimbolton',
			'Kelvedon',
			'Keele',
			'Irvinestown',
			'Horam',
			'High Legh',
			'Hawarden',
			'Haughley',
			'Hartlepool',
			'Haltwhistle',
			'Haigh',
			'Great Gonerby',
			'Grantown on Spey',
			'Godstone',
			'Glasgow',
			'Germoe',
			'Garelochhead',
			'Fulham',
			'Freuchie',
			'Fowlmere',
			'Ford',
			'Ford',
			'Fleet',
			'Ferryhill',
			'Felixstowe',
			'Enfield Town',
			'Empingham',
			'Yeadon',
			'Yate',
			'Wickham Bishops',
			'Whittlesford',
			'West Molesey',
			'Washington',
			'Waddesdon',
			'Verwood',
			'Torphins',
			'Tong',
			'Three Legged Cross',
			'Tarvin',
			'Sutton',
			'Staindrop',
			'Spratton',
			'Snaresbrook',
			'Sidcup',
			'Sellack',
			'Seaview',
			'Ryhall',
			'Ringway',
			'Rickinghall',
			'Rhymney',
			'Rhosllanerchrugog',
			'Redruth',
			'Port Glasgow',
			'Port Erroll',
			'Pontypool',
			'Podington',
			'Pattingham',
			'Northwich',
			'Newmarket',
			'Netley',
			'Needingworth',
			'Mylor Bridge',
			'Moulsoe',
			'Morriston',
			'Monmouth',
			'Mitchel Troy',
			'Mistley',
			'Mayland',
			'Mathry',
			'Martham',
			'Mansfield Woodhouse',
			'Machen',
			'Long Eaton',
			'Llangollen',
			'Llandudno',
			'Llanddeusant',
			'Llanboidy',
			'Lisburn',
			'Leven',
			'Kilrea',
			'Kilmacolm',
			'Kelso',
			'Kearsley',
			'Ironbridge',
			'Inverkip',
			'Ilford',
			'Horsley',
			'Hook Norton',
			'Holmwood',
			'Holmes Chapel',
			'Hindley',
			'Hetton-Le-Hole',
			'Hebron',
			'Healing',
			'Haywards Heath',
			'Haxey',
			'Harwell',
			'Hamble-le-Rice',
			'Great Malvern',
			'Great Glen',
			'Great Bedwyn',
			'Glenfield',
			'Gildersome',
			'Frimley',
			'Four Marks',
			'Foulridge',
			'Finningley',
			'Fenwick',
			'Erlestoke',
			'Ellesmere',
			'Edington',
			'East Horsley',
			'Earls Colne',
			'Dinton',
			'Denholme',
			'Deighton',
			'Cowfold',
			'Colwich',
			'Caerleon',
			'Bury',
			'Burwash',
			'Burntwood',
			'Brooke',
			'Bromfield',
			'Bristol',
			'Brinklow',
			'Brimscombe',
			'Brent',
			'Bowdon',
			'Blyton',
			'Bletchley',
			'Blaenau-Ffestiniog',
			'Billingham',
			'Beadnell',
			'Bardney',
			'Banchory',
			'Balintore',
			'Balcombe',
			'Ashwell',
			'Ambleside',
			'Adlington',
			'Aberlady',
			'Crofty',
			'Stockton',
			'Camblesforth',
			'Featherstone',
			'Carden',
			'Grappenhall',
			'Mottram St. Andrew',
			'New Marske',
			'Letchworth Garden City',
			'Hayling Island',
			'Blantyre',
			'Castle Vale',
			'Yeovil',
			'Yarm',
			'Wroxall',
			'Worlaby',
			'Wollaston',
			'Withington',
			'Windlesham',
			'Wimbledon',
			'Wickford',
			'Wick',
			'Whitton',
			'Whitchurch',
			'Whaley Bridge',
			'Westerham',
			'Wavendon',
			'Walton-on-the-Naze',
			'Valley',
			'Ulverston',
			'Tytherington',
			'Tynemouth',
			'Totnes',
			'Thurston',
			'Thrapston',
			'Thorngumbald',
			'Taibach',
			'Swansea',
			'Sutton Coldfield',
			'Sunderland',
			'Stromness',
			'Strensall',
			'Strathaven',
			'Stoke Ferry',
			'Stepney',
			'Staines',
			'Spitalfields',
			'Southend-on-Sea',
			'Soham',
			'Skipsea',
			'Silkstone',
			'Shrewsbury',
			'Shap',
			'Sevenoaks',
			'Saxilby',
			'Sawston',
			'Sandy',
			'Saltcoats',
			'Ryde',
			'Rushall',
			'Roxton',
			'Roehampton',
			'Ratho',
			'Ramsgate',
			'Probus',
			'Pontycymer',
			'Pittenweem',
			'Penicuik',
			'Pant',
			'Ormskirk',
			'Oldbury',
			'Nuneaton',
			'Norton Canes',
			'North Somercotes',
			'North Collingham',
			'Ninfield',
			'Newry',
			'Nafferton',
			'Moy',
			'Mauchline',
			'Marr',
			'March',
			'Lytchett Matravers',
			'Luton',
			'Lowestoft',
			'Longridge',
			'Longhope',
			'Longdendale',
			'Linton',
			'Limavady',
			'Leyton',
			'Leiston',
			'Leigh',
			'Larne',
			'Langley Park',
			'Knowle',
			'Kirkcaldy',
			'Kington',
			'Kingsteignton',
			'Kinghorn',
			'Kessingland',
			'Ivybridge',
			'Husbands Bosworth',
			'Hoylake',
			'Horrabridge',
			'Holt',
			'Hoddesdon',
			'Hingham',
			'Haverigg',
			'Harley',
			'Haddenham',
			'Guildford',
			'Grosmont',
			'Great Hanwood',
			'Garswood',
			'Galgate',
			'Fulford',
			'Flitwick',
			'Fishburn',
			'Fenstanton',
			'Embleton',
			'Elgin',
			'Egremont',
			'Edgware',
			'Eccles',
			'East Bergholt',
			'Dulverton',
			'Dewsbury',
			'Cwm',
			'Cullybackey',
			'Croft',
			'Cringleford',
			'Coltishall',
			'Codsall',
			'Clifford',
			'Cleckheaton',
			'Chryston',
			'Chatteris',
			'Charlton Marshall',
			'Chacewater',
			'Carnaby',
			'Burton upon Stather',
			'Burton on the Wolds',
			'Brynamman',
			'Broughton',
			'Brighton',
			'Braunston',
			'Boreham',
			'Bluntisham',
			'Birdwell',
			'Berkeley',
			'Bawtry',
			'Barton on Sea',
			'Barnstaple',
			'Banks',
			'Auchterarder',
			'Atherstone',
			'Ashton Keynes',
			'Ashtead',
			'Anstey',
			'Amlwch',
			'Glandwr',
			'West End of London',
			'Barnsbury',
			'St. Georges',
			'Lytham St Annes',
			'Milton of Campsie',
			'Chelmsley Wood',
			'Mendip',
			'Burpham',
			'Lightwater',
			'North Baddesley',
			'Stockton Heath',
			'Cambourne',
			'Holywell Green',
			'Fallin',
			'Westhill',
			'Emerson Park',
			'Yazor',
			'Yaxley',
			'Yatton',
			'Woldingham',
			'Willaston',
			'Willaston',
			'Wilburton',
			'Wentworth',
			'Wells',
			'Warlingham',
			'Waltham Abbey',
			'Upton',
			'Tyldesley',
			'Treherbert',
			'Tickhill',
			'Thornton',
			'Thornbury',
			'Tanfield',
			'Sudbrooke',
			'Stowmarket',
			'Stokesley',
			'Stirling',
			'Steventon',
			'Stagsden',
			'Southgate',
			'Southery',
			'Skinningrove',
			'Sandwich',
			'Saint Neots',
			'St Helens',
			'Royal Tunbridge Wells',
			'Romford',
			'Risca',
			'Richmond',
			'Rhu',
			'Preesall',
			'Pirbright',
			'Penzance',
			'Pentyrch',
			'Penryn',
			'Pangbourne',
			'Overton',
			'Oban',
			'Normanton',
			'Newent',
			'Mountsorrel',
			'Milverton',
			'Milngavie',
			'Mildenhall',
			'Mayfield',
			'Markinch',
			'Maidstone',
			'Maidenhead',
			'Lynton',
			'Longton',
			'Longniddry',
			'Llanfaethlu',
			'Llandegla',
			'Llanbadoc',
			'Liverpool',
			'Little Lever',
			'Linton',
			'Lingfield',
			'Leven',
			'Ledsham',
			'Laurencekirk',
			'Kirknewton',
			'Kirkliston',
			'Kirkcudbright',
			'Kilsyth',
			'Killyleagh',
			'Kilbarchan',
			'Keyingham',
			'Ketton',
			'Kedington',
			'Ingrave',
			'Howwood',
			'Hove',
			'Horton',
			'Hope',
			'Hengoed',
			'Hemingbrough',
			'Heddon on the Wall',
			'Hazel Grove',
			'Hathersage',
			'Hampton',
			'Haddenham',
			'Grimston',
			'Greetham',
			'Great Horkesley',
			'Great Bentley',
			'Gourock',
			'Gnosall',
			'Gillingham',
			'Friern Barnet',
			'Fowey',
			'Fishtoft',
			'Feltwell',
			'Faringdon',
			'Eythorne',
			'Evanton',
			'Etton',
			'Epsom',
			'Elwick',
			'Elswick',
			'Eglinton',
			'Earlston',
			'Durnford',
			'Dunstable',
			'Dunloy',
			'Deganwy',
			'Culmore',
			'Fair Oak',
			'Crosskeys',
			'Cricklade',
			'Crickhowell',
			'Cray',
			'Costessey',
			'Corfe Castle',
			'Corby',
			'Chilworth',
			'Child Okeford',
			'Chesham',
			'Chalfont Saint Peter',
			'Cawood',
			'Cardross',
			'Cardington',
			'Caerwent',
			'Byfield',
			'Burnopfield',
			'Burley',
			'Burham',
			'Buckhurst Hill',
			'Bridlington',
			'Brewood',
			'Brantingham',
			'Bradford',
			'Bourton',
			'Boroughbridge',
			'Bolton le Sands',
			'Bodelwyddan',
			'Bishton',
			'Bishopton',
			'Bishopthorpe',
			'Bishopston',
			'Bisham',
			'Biggin Hill',
			'Bere Regis',
			'Batley',
			'Badsey',
			'Ascot',
			'Amesbury',
			'Amersham',
			'Allington',
			'Allanton',
			'Alford',
			'Alexandria',
			'Pensilva',
			'Kewstoke',
			'Marston',
			'Sharlston',
			'Broadfield',
			'St. Buryan',
			'Hornchurch',
			'Ewell',
			'Charvil',
			'Darras Hall',
			'Guilden Sutton',
			'Golders Green',
			'Shirley',
			'Hope Valley',
			'Stoke-sub-Hamdon',
			'Four Lanes',
			'Dalgety Bay',
			'Moreton Jeffries',
			'Carronshore',
			'Linwood',
			'Crofton Park',
			'Glenmavis',
			'Denton Holme',
			'Seven Sisters',
			'Hayton',
			'Harbury',
			'Hale',
			'Haddington',
			'Godalming',
			'Fortuneswell',
			'Folkestone',
			'Fleetwood',
			'Farnborough',
			'Exmouth',
			'East Boldon',
			'Dunoon',
			'Dudley',
			'Droylsden',
			'Downpatrick',
			'Dickleburgh',
			'Dalston',
			'Cwmafan',
			'Crymych',
			'Croydon',
			'Crewkerne',
			'Crawley',
			'Coxhoe',
			'Cove',
			'Clayton West',
			'Chatham',
			'Chasetown',
			'Chalford',
			'Castleside',
			'Capel Saint Mary',
			'Camrose',
			'Burley',
			'Builth Wells',
			'Brora',
			'Bromsgrove',
			'Bromham',
			'Brixton',
			'Brampton',
			'Borehamwood',
			'Bletchingley',
			'Black Notley',
			'Bishopstrow',
			'Bishopsteignton',
			'Birdham',
			'Bildeston',
			'Bembridge',
			'Beckingham',
			'Barkisland',
			'Bangor',
			'Banff',
			'Audley',
			'Ashurst',
			'Arnside',
			'Arnold',
			'Armthorpe',
			'Appleby',
			'Alveston',
			'Alloa',
			'Alconbury',
			'Airdrie',
			'Ogmore Vale',
			'Blackmoorfoot',
			'Polzeath',
			'Bayswater',
			'Chorlton cum Hardy',
			'Coatham Mundeville',
			'Calcot',
			'Catcliffe',
			'Cowplain',
			'Aylesham',
			'Isle of South Uist',
			'Osterley',
			'Town Row',
			'Wormley',
			'Culloden',
			'Great Haywood',
			'Mortlake',
			'Ratho Station',
			'Torrance',
			"King's Clipstone",
			'Tunstall',
			'Bradwell',
			'Furzedown',
			'South Shields',
			'St. Helens',
			'Sabden',
			'Royston',
			'Roos',
			'Romaldkirk',
			'Pwllheli',
			'Putney',
			'Porthleven',
			'Pontypridd',
			'Pencoed',
			'Pen-clawdd',
			'Paulton',
			'Painswick',
			'Oughtibridge',
			'Oakington',
			'North Tawton',
			'North Queensferry',
			'North Cave',
			'Northampton',
			'Normandy',
			'Newburgh',
			'Nailsea',
			'Mundford',
			'Mountain Ash',
			'Motherwell',
			'Minsterley',
			'Middlewich',
			'Messingham',
			'Meltham',
			'Martock',
			'Marston',
			'Manorbier',
			'Malmesbury',
			'Lymm',
			'Lymington',
			'Lowdham',
			'Lossiemouth',
			'Long Whatton',
			'Llanfynydd',
			'Llandeilo',
			'Liss',
			'Lartington',
			'Lacock',
			'Kirkwall',
			'Kingussie',
			'Kilmarnock',
			'Irlam',
			'Hutton Magna',
			'Huish',
			'Hugh Town',
			'Hoyland Nether',
			'Houghton Conquest',
			'Horsforth',
			'Hornsea',
			'Hoo',
			'Holyhead',
			'Hockley',
			'High Ongar',
			'High Etherley',
			'High Coniscliffe',
			'Heston',
			'Hereford',
			'Hemsby',
			'Helensburgh',
			'Hartley',
			'Harrogate',
			'Handcross',
			'Halesworth',
			'Fraserburgh',
			'York',
			'Winkleigh',
			'Wing',
			'Wigmore',
			'Wickham Market',
			'Whittington',
			'West Horsley',
			'West Haddon',
			'Wemyss Bay',
			'Welwyn',
			'Waltham',
			'Thwing',
			'Thetford',
			'Stratford-upon-Avon',
			'Stocksfield',
			'Stewkley',
			'Steeple Bumpstead',
			'Stalham',
			'Spilsby',
			'Ferndale',
			'Felton',
			'Faversham',
			'Elland',
			'Edworth',
			'Eastleigh',
			'Duns',
			'Dundrum',
			'Dunbar',
			'Dreghorn',
			'Downton',
			'Downside',
			'Delph',
			'Cumbernauld',
			'Crewe',
			'Cranfield',
			'Cowes',
			'Cottingham',
			'Conisbrough',
			'Coningsby',
			'Cold Ash',
			'Cliffe',
			'Clenchwarton',
			'Chichester',
			'Chertsey',
			'Cawston',
			'Castleford',
			'Castle Cary',
			'Carfin',
			'Carcroft',
			'Calverton',
			'Cadnam',
			'Buxton',
			'Bushey',
			'Burton Joyce',
			'Broadway',
			'Bredbury',
			'Boxgrove',
			'Bournemouth',
			'Boston Spa',
			'The Boldons',
			'Blaydon-on-Tyne',
			'Blairgowrie',
			'Bingham',
			'Bassingham',
			'Barnham',
			'Barnetby le Wold',
			'Barmston',
			'Ballykelly',
			'Bainton',
			'Bagworth',
			'Awsworth',
			'Astwood Bank',
			'Askham Richard',
			'Ashton in Makerfield',
			'Appley Bridge',
			'Alnmouth',
			'Abingdon',
			'Cwmbach',
			'Whitley',
			'East Keswick',
			'Clerkenwell',
			'Chalk Farm',
			'Battersea',
			'Hainault',
			'Failsworth',
			'Husborne Crawley',
			'Lower Earley',
			'Bedlinog',
			'Menstrie',
			'Isle of Islay',
			'Norbiton',
			'Highams Park',
			'Windygates',
			'Logan',
			'Gronant',
			'Keyworth',
			'Greasby',
			'Brockley',
			'Coaltown of Balgonie',
			'Hale Barns',
			'Slough',
			'Skidby',
			'Sittingbourne',
			'Silsoe',
			'Shrewton',
			'Sheringham',
			'Salford',
			'St. Day',
			'Ryhope',
			'Roydon',
			'Ross on Wye',
			'Rosehearty',
			'Rochester',
			'Ripon',
			'Rimswell',
			'Reedham',
			'Raunds',
			'Queensbury',
			'Prestwich',
			'Pott Shrigley',
			'Potterspury',
			'Petworth',
			'Perranporth',
			'Owston Ferry',
			'Northleach',
			'Northborough',
			'New Tredegar',
			'Newtown',
			'Newmains',
			'Newchurch',
			'Newcastle Emlyn',
			'Motcombe',
			'Moreton in Marsh',
			'Modbury',
			'Methley',
			'Merthyr Tydfil',
			'Melton Mowbray',
			'Meldon',
			'Manningtree',
			'Luddenden Foot',
			'Low Bradley',
			'Longstanton',
			'Lofthouse',
			'Llanddarog',
			'Lisnaskea',
			'Leasingham',
			'Lauder',
			'Knowsley',
			'Kirton in Lindsey',
			'Kirk Sandall',
			'Kirkleatham',
			'Kirkby in Ashfield',
			'Kingswood',
			'Kingsbridge',
			'Johnston',
			'Iver',
			'Holsworthy',
			'Hertford',
			'Hemel Hempstead',
			'Hayfield',
			'Harefield',
			'Halton',
			'Halton',
			'Hadlow',
			'Hadley',
			'Greenock',
			'Greenford',
			'Greenfield',
			'Great Waldingfield',
			'Goodwick',
			'Full Sutton',
			'Frampton on Severn',
			'Foulsham',
			'Flamborough',
			'Fintona',
			'Filey',
			'Evesham',
			'Wiston',
			'Wimblington',
			'Willand',
			'Wick',
			'Whitehead',
			'West Bromwich',
			'Wendron',
			'Wellow',
			'Watlington',
			'Warminster',
			'Wandsworth',
			'Walthamstow',
			'Waddington',
			'Upton upon Severn',
			'Upchurch',
			'Tuxford',
			'Trimdon',
			'Totton',
			'Tickencote',
			'Tibshelf',
			'Thornhill',
			'Templecombe',
			'Swindon',
			'Sunningdale',
			'Streatham',
			'Stoke Prior',
			'Staveley',
			'Stanwell',
			'Youlgreave',
			'Yapton',
			'Wroughton',
			'Woodcote',
			'Wittering',
			'Wisbech',
			'Wigan',
			'Whitford',
			'Whitchurch',
			'Weeting',
			'Waterbeach',
			'Waringstown',
			'Waltham Cross',
			'Wadebridge',
			'Upper Langwith',
			'Thornton Dale',
			'Templeton',
			'Storrington',
			'Stamford Bridge',
			'Southsea',
			'Southport',
			'Sanderstead',
			'Rothbury',
			'Resolven',
			'Redditch',
			'Redbourn',
			'Raglan',
			'Radstock',
			'Puddletown',
			'Preston',
			'Polesworth',
			'Pinner',
			'Pembroke',
			'Palmers Green',
			'Paddock Wood',
			'Olveston',
			'Norton',
			'Newtonhill',
			'Newton Aycliffe',
			'New Quay',
			'Newbury',
			'Mold',
			'Moira',
			'Minster Lovell',
			'Midhurst',
			'Meldreth',
			'Manston',
			'Luncarty',
			'Lower Halstow',
			'Longfield',
			'Londonderry County Borough',
			'Llangan',
			'Limekilns',
			'Lewes',
			'Letterston',
			'Leek Wootton',
			'Kirby Underdale',
			'Kingston Bagpuize',
			'Kettering',
			'Insch',
			'Hutton',
			'Hook',
			'Hirwaun',
			'Hermitage',
			'Henstridge',
			'Hay',
			'Harthill',
			'Hartford',
			'Great Harwood',
			'Great Chesterford',
			'Goole',
			'Garstang',
			'Frome',
			'Feltham',
			'Farnborough',
			'Fairlie',
			'Evercreech',
			'East Dulwich',
			'Earls Barton',
			'Dyce',
			'Dunfermline',
			'Diss',
			'Dinnington',
			'Deri',
			'Deal',
			'Cullompton',
			'Cuffley',
			'Cross Hands',
			'Corris',
			'Coleraine',
			'Coleford',
			'Clayton le Moors',
			'Cinderford',
			'Cholsey',
			'Chiswick',
			'Chiddingfold',
			'Chale',
			'Carrickfergus',
			'Carnwath',
			'Cairnryan',
			'Burton',
			'Bubwith',
			'Brundall',
			'Brough',
			'Brockenhurst',
			'Brignall',
			'Brierley Hill',
			'Bow Street',
			'Bosham',
			'Bodenham',
			'Bishop Middleham',
			'Bishopbriggs',
			'Binfield',
			'Beeford',
			'Barnet',
			'Banstead',
			'Astwood',
			'Aston Clinton',
			'Ashington',
			'Ashburton',
			'Ardrossan',
			'Ardersier',
			'Arbroath',
			'Annfield Plain',
			'Alvechurch',
			'Alva',
			'Aldridge',
			'Aberdare',
			'Pennard',
			'Bishop Sutton',
			'Meltham Mills',
			'Lambeth',
			'Churt',
			'Hollingworth',
			'Haughton Green',
			'Roslin',
			'Gartcosh',
			'Peckham',
			'Waterloo',
			'Catterick Garrison',
			'Frimley Green',
			'Five Oak Green',
			'Swarthmoor',
			'West Kingsdown',
			'Portswood',
			'Westquarter',
			'Northumberland Park',
			'Worcester Park',
			'Woodford',
			'Wombourne',
			'Winslow',
			'Winsford',
			'Whitton',
			'West Walton',
			'Weston Underwood',
			'West Mersea',
			'Wellington',
			'Welford',
			'Wateringbury',
			'Walkden',
			'Uckfield',
			'Truro',
			'Torpoint',
			'Ticehurst',
			'Thurlby',
			'Thatcham',
			'Swavesey',
			'Sudbury',
			'Stretton',
			'Stone',
			'Stone',
			'Stilton',
			'South Wingfield',
			'Southwater',
			'Southorpe',
			'Skelmorlie',
			'Siston',
			'Silverdale',
			'Sible Hedingham',
			'Shinfield',
			'Sherburn in Elmet',
			'Shalfleet',
			'Shaftesbury',
			'Seaham',
			'Scotter',
			'Sawtry',
			'Sandown',
			'Saffron Walden',
			'Royston',
			'Rothesay',
			'Rochester',
			'Richmond',
			'Quorndon',
			'Portglenone',
			'Portaferry',
			'Portadown',
			'Pillaton',
			'Oldham',
			'Oakley',
			'Oakham',
			'Neyland',
			'Newport',
			'Newport',
			'New Pitsligo',
			'Newhaven',
			'Newcastle under Lyme',
			'Newark on Trent',
			'Muirkirk',
			'Morley',
			'Millington',
			'Marple',
			'Lympstone',
			'Lydd',
			'Lydbrook',
			'Longhoughton',
			'Letham',
			'Launceston',
			'Lark Hill',
			'Kings Worthy',
			'Kimberley',
			'Kilsby',
			'Kemnay',
			'Inverbervie',
			'Ince-in-Makerfield',
			'Howden',
			'Hotham',
			'Horncastle',
			'Hopton',
			'Hollym',
			'Henfield',
			'Haynes',
			'Hathern',
			'Hartwell',
			'Harlow',
			'Halkyn',
			'Hadleigh',
			'Grundisburgh',
			'Grimsby',
			'Great Ness',
			'Gravenhurst',
			'Grassington',
			'Gosfield',
			'Gobowen',
			'Glenariff',
			'Freshwater',
			'Fordingbridge',
			'Flookburgh',
			'Findon',
			'Farnham Royal',
			'Eynsford',
			'Eston',
			'Ellon',
			'Edith Weston',
			'Easton on the Hill',
			'East Markham',
			'Draperstown',
			'Douglas',
			'Daresbury',
			'Crowborough',
			'Crossgates',
			'Cranwell',
			'Cramlington',
			'Crail',
			'Cornholme',
			'Cople',
			'Conwy',
			'Claydon',
			'Clarborough',
			'Churchdown',
			'Chippenham',
			'Brockley',
			'Broadwater',
			'Bridge of Earn',
			'Bradford-on-Avon',
			'Bothwell',
			'Bishopstone',
			'Birchington-on-Sea',
			'Biddestone',
			'Bexhill-on-Sea',
			'Betws-y-Coed',
			'Betws',
			'Berrington',
			'Belvedere',
			'Belbroughton',
			'Bebington',
			'Bathford',
			'Ballygowan',
			'Backworth',
			'Ardglass',
			'Ansty',
			'Alveley',
			'Allhallows',
			'Acton',
			'Abbots Bromley',
			'Clydach Vale',
			'Rogiet',
			'Neston',
			'Oakmere',
			'Kings Hill',
			'Grange Hill',
			'Elm Park',
			'Tooting',
			'Surbiton',
			'Harold Wood',
			'Heaton Chapel',
			'Appleton Thorn',
			'Y Felinheli',
			"St Mary's Bay",
			'Easington Colliery',
			'Shotts',
			'Earlsfield',
			'Eastriggs',
			'Peacehaven',
			'Chopwell',
			'Anna Valley',
			'Bridge Sollers',
			'Compton',
			'New Stevenston',
			'Shieldhill',
			'Tidworth',
			'Southminster',
			'South Littleton',
			'South Brent',
			'Simpson',
			'Shotwick',
			'Seghill',
			'Seend',
			'Scawby',
			'Saint Dennis',
			'Runcorn',
			'Pyrford',
			'Pudsey',
			'Prestonpans',
			'Polmont',
			'Pinchbeck',
			'Pelton',
			'Overtown',
			'Over',
			'Oswestry',
			'Ormesby St Margaret',
			'Northorpe',
			'Norbury',
			'New Romney',
			'Needham Market',
			'Mitcheldean',
			'Minety',
			'Milltimber',
			'Millbrook',
			'Mid Calder',
			'Marchwiel',
			'Madeley',
			'Macmerry',
			'Lyneham',
			'Ludlow',
			'Longtown',
			'Loftus',
			'Loddon',
			'Llanrhian',
			'Livingston',
			'Littleport',
			'Lanchester',
			'Lampeter',
			'Knottingley',
			'Knighton',
			'Kippen',
			'Kingston upon Hull',
			'Kingsley',
			'Kimbolton',
			'Kenley',
			'Kelsall',
			'Huyton',
			'Hurst',
			'Honiton',
			'Hessle',
			'Heath and Reach',
			'Hayle',
			'Haydon Bridge',
			'Hatherleigh',
			'Hampton in Arden',
			'Gurnard',
			'Groombridge',
			'Gosport',
			'Goring-by-Sea',
			'Goring',
			'Gilfach Goch',
			'Gelligaer',
			'Gayhurst',
			'Fairlight',
			'Ewyas Harold',
			'Etwall',
			'Errol',
			'Elstead',
			'Ellerton',
			'Edinburgh',
			'Edenbridge',
			'Eccleston',
			'Eastrington',
			'East Chevington',
			'Eastbourne',
			'Donnington',
			'Debenham',
			'Dearham',
			'Datchet',
			'Darlaston',
			'Dalry',
			'Crumlin',
			'Crigglestone',
			'Countesthorpe',
			'Colne',
			'Clophill',
			'Clevedon',
			'Church Fenton',
			'Choppington',
			'Cannock',
			'Calverton',
			'Caldicot',
			'Butterwick',
			'Burrington',
			'Burgess Hill',
			'Burford',
			'Bungay',
			'Broad Blunsdon',
			'Briton Ferry',
			'Brightlingsea',
			'Bradley',
			'Bow Brickhill',
			'Botley',
			'Bognor Regis',
			'Blaenavon',
			'Bilston',
			'Billingborough',
			'Belper',
			'Bedwas',
			'Barrow in Furness',
			'Banbury',
			'Astley',
			'Armagh',
			'Alton',
			'Abergele',
			'Severn Beach',
			'Tankerton',
			'South Chailey',
			'Bidford-on-avon',
			'Mottingham',
			'Bay Horse',
			'Greenhill',
			'Kensal Green',
			'Bilsdale',
			'Isle of Cumbrae',
			'Aston-on-Trent',
			'Houghton',
			'Head of Muir',
			'Lenzie',
			'Mayfield',
			'Ballypatrick',
			'Wrotham',
			'Wootton',
			'Woolston',
			'Woodley',
			'Woodford',
			'Wivenhoe',
			'Witley',
			'Williton',
			'Westhoughton',
			'Weaverham',
			'Wargrave',
			'Warfield',
			'Wantage',
			'Wacton',
			'Treharris',
			'Tredegar',
			'Tranent',
			'Tisbury',
			'Thorney',
			'Theale',
			'Thaxted',
			'Teddington',
			'Stockport',
			'Stanbridge',
			'Stalbridge',
			'Ystrad Mynach',
			'Worcester',
			'Wootton',
			'Witney',
			'Winnersh',
			'Wigton',
			'Whitley Bay',
			'Whitefield',
			'Weston',
			'West Kilbride',
			'Wenvoe',
			'Wellesbourne Mountford',
			'Wellesbourne',
			'Wedmore',
			'Wallingford',
			'Treeton',
			'Tilehurst',
			'Templepatrick',
			'Swinton',
			'Stourbridge',
			'Stoke',
			'Stocksbridge',
			'Stanley',
			'Solihull',
			'Slinfold',
			'Sileby',
			'Shilbottle',
			'Shalbourne',
			'Scorton',
			'Saint Stephen',
			'Rugeley',
			'Rothley',
			'Rotherhithe',
			'Roche',
			'Redbourne',
			'Porlock',
			'Pitstone',
			'Peterborough',
			'Pertenhall',
			'Penygroes',
			'Patna',
			'Over',
			'Oundle',
			'Ormiston',
			'North Elmham',
			'Newborough',
			'Newbold Verdon',
			'Narborough',
			'Mytchett',
			'Moulton',
			'Milston',
			'Millom',
			'Middle Rasen',
			'Merrow',
			// 'Melbourne',
			'Mappleton',
			'Malpas',
			'Maerdy',
			'Lundin Links',
			'Lowick',
			'Longtown',
			'Llangathen',
			'Llanbedr',
			'Liskeard',
			'Latchingdon and Snoreham',
			'Lambourn',
			'Knutsford',
			'Kilwinning',
			'Irchester',
			'Invergordon',
			'Ilkley',
			'Huntington',
			'Houston',
			'Houghton-Le-Spring',
			'Horning',
			'Hope under Dinmore',
			'Holt',
			'Holbrook',
			'High Wycombe',
			'High Halstow',
			'Hethersett',
			'Helmsley',
			'Hedon',
			'Harthill',
			'Harpenden',
			'Gunness',
			'Grove',
			'Griston',
			'Grindon',
			'Greyabbey',
			'Great Yeldham',
			'Great Bardfield',
			'Glinton',
			'Freckleton',
			'Fortrose',
			'Fenwick',
			'Farndon',
			'Eaton',
			'Earley',
			'Dunswell',
			'Dartmouth',
			'Dalrymple',
			'Dalkeith',
			'Dalbeattie',
			'Crowland',
			'Crosshouse',
			'Crediton',
			'Colmworth',
			'Cold Norton',
			'Clydach',
			'Chudleigh Knighton',
			'Chipstead',
			'Chigwell',
			'Chester-le-Street',
			'Cheltenham',
			'Chelmsford',
			'Chalgrove',
			'Castledawson',
			'Carryduff',
			'Carnoustie',
			'Cardenden',
			'Cantley',
			'Buckfastleigh',
			'Brymbo',
			'Broughshane',
			'Branston',
			'Bovington Camp',
			'Botesdale',
			'Blackburn',
			'Belford',
			'Beighton',
			'Bedford',
			'Bayston Hill',
			'Bath',
			'Basford',
			'Barston',
			'Barrowby',
			'Bardsey',
			'Banbridge',
			'Ballymoney',
			'Bagshot',
			'Auchinleck',
			'Aston',
			'Althorne',
			'Hadston',
			'Looe',
			'Telford',
			'Erskine',
			'Canary Wharf',
			'Babworth',
			'Canvey Island',
			'Asfordby',
			'Cradley Heath',
			'Isle of North Uist',
			'Lower Broadheath',
			'Mount Hawke',
			'Ashgill',
			'East Whitburn',
			'Viewpark',
			'Tottenham Hale',
			'St. Helier',
			'Falmouth',
			'Woburn Sands',
			'Winwick',
			'Wigston Magna',
			'Whiston',
			'Wheaton Aston',
			'Weymouth',
			'West Linton',
			'West Kirby',
			'West Ham',
			'Wellingborough',
			'Wednesbury',
			'Wattisham',
			'Washington',
			'Walworth',
			'Wadworth',
			'Ulceby',
			'Tiptree',
			'Tetney',
			'Tenbury Wells',
			'Tain',
			'Tadworth',
			'Street',
			'Stranraer',
			'Stonehaven',
			'Stewarton',
			'Steppingley',
			'Stafford',
			'South Ockendon',
			'South Collingham',
			'Somerton',
			'Shrivenham',
			'Shawbury',
			'Send',
			'Scunthorpe',
			'Scarborough',
			'Rustington',
			'Rostrevor',
			'Redhill',
			'Puckeridge',
			'Poulton-le-Fylde',
			'Portslade',
			'Plean',
			'Pinxton',
			'Penybont',
			'Penarth',
			'Par',
			'Padiham',
			'Nunthorpe',
			'North Leigh',
			'Newbiggin-by-the-Sea',
			'Narberth',
			'Mostyn',
			'Morden',
			'Moffat',
			'Milborne St Andrew',
			'Middle Winterslow',
			'Middlesbrough',
			'Melrose',
			'Market Rasen',
			'Market Deeping',
			'Lydiard Tregoze',
			'Llangwm',
			'Llanfechain',
			'Little Dunmow',
			'Little Clacton',
			'Ledbury',
			'Lea',
			'Larkhall',
			'Larbert',
			'Langford',
			'Knaresborough',
			'Kirby Muxloe',
			'Kilmington',
			'Ince',
			'Huntspill',
			'Humberston',
			'Histon',
			'Herstmonceux',
			'Henllan',
			'Haslingfield',
			'Great Sankey',
			'Great Dunmow',
			'Gotham',
			'Glenrothes',
			'Girton',
			'Frizington',
			'Forfar',
			'Findochty',
			'Felling',
			'Farnworth',
			'Fareham',
			'Eye',
			'Eton',
			'Enderby',
			'Ely',
			'Doddington',
			'Devizes',
			'Desford',
			'Desborough',
			'Deepcut',
			'Cwm',
			'Cults',
			'Croston',
			'Coundon',
			'Compton Dando',
			'Colerne',
			'Coedpoeth',
			'Cobham',
			'Clydebank',
			'Church Stretton',
			'Churchill',
			'Chipping Sodbury',
			'Chipping Norton',
			'Charlton Kings',
			'Caythorpe',
			'Cawthorne',
			'Carnmoney',
			'Carmarthen',
			'Cambridge',
			'Burton',
			'Broxburn',
			'Brighouse',
			'Boynton',
			'Bonnyrigg',
			'Bollington',
			'Bloxwich',
			'Blagdon',
			'Blackwall',
			'Billinge',
			'Bickley',
			'Beckenham',
			'Old Basing',
			'Upper Basildon',
			'Barrow upon Humber',
			'Barlaston',
			'Balmullo',
			'Bacup',
			'Ashford',
			'Pontlliw',
			'Croeserw',
			'Pont Rhyd-y-cyff',
			'Pucklechurch',
			'Boyton',
			'Caol',
			'Hedge End',
			'Chadwell Heath',
			'Earl Shilton',
			'Hoole',
			'Walton',
			'Prenton',
			'Crawley Down',
			'Eton Wick',
			'Cross Hills',
			'Pevensey Bay',
			'Thornton-Cleveleys',
			'Tidbury Green',
			'Ravenshead',
			'Ratby',
			'Salsburgh',
			'Dennyloanhead',
			'Wood Green',
			'Wingerworth',
			'Willington',
			'West Wellow',
			'Waverton',
			'Uppingham',
			'Twyford',
			'Trowbridge',
			'Totteridge',
			'Theydon Bois',
			'Sunbury-on-Thames',
			'Stockton-on-Tees',
			'Stamford',
			'Snodland',
			'Silsden',
			'Shipley',
			'Sherborne St John',
			'Sherborne',
			'Settle',
			'Selby',
			'Seaton',
			'Scarcroft',
			'Saltford',
			'Saltash',
			'Ruddington',
			'Ringstead',
			'Ramsbottom',
			'Rainham',
			'Preston',
			'Prees',
			'Potton',
			'Portlethen',
			'Peebles',
			'Ochiltree',
			'Northwood',
			'North Sunderland',
			'Millisle',
			'Mere',
			'Mattishall',
			'Manton',
			'Mangotsfield',
			'Maltby',
			'Maghull',
			'Lyminge',
			'Llanwnda',
			'Llanveynoe',
			'Llanrothal',
			'Llancillo',
			'Linlithgow',
			'Lingen',
			'Leicester',
			'Lakenheath',
			'Laceby',
			'Kinsham',
			'Kingswinford',
			'Kimpton',
			'Keadby',
			'Ingoldmells',
			'Inchture',
			'Ilchester',
			'Hinton Charterhouse',
			'Hightown',
			'Highley',
			'Henley-on-Thames',
			'Healey',
			'Hamsterley',
			'Hadleigh',
			'Goudhurst',
			'Goldcliff',
			'Gateshead',
			'Forest Hill',
			'Falkirk',
			'Evenwood',
			'Eltham',
			'Ellingham',
			'Easingwold',
			'Eaglesham',
			'Dumbarton',
			'Drighlington',
			'Diggle',
			'Didcot',
			'Darlington',
			'Crossmaglen',
			'Cromer',
			'Crieff',
			'Cookstown',
			'Congleton',
			'Compton',
			'Colyton',
			'Colchester',
			'Coggeshall',
			'Coates',
			'Chirk',
			'Cardington',
			'Burry Port',
			'Burniston',
			'Brentford',
			'Bray',
			'Brandon',
			'Bramley',
			'Bovingdon',
			'Blockley',
			'Blackwood',
			'Bitton',
			'Bishopstone',
			'Birtley',
			'Beverley',
			'Baschurch',
			'Barry',
			'Bardon Mill',
			'Bagillt',
			'Aughnacloy',
			'Aslockton',
			'Alderholt',
			'Aldeburgh',
			'Aberkenfig',
			'Rock',
			"King's Cross",
			'Tolworth',
			'Hulme',
			'Heavitree',
			'Silver End',
			'Byram',
			'Newcastle',
			'Summerhouse',
			'Charlton',
			'Hoveton',
			'Bowburn',
			'Hyde Heath',
			'Coven',
			'Stoneyburn',
			'Sinfin',
			'Ystradgynlais',
			'Ystalyfera',
			'Wymeswold',
			'Wye',
			'Wrington',
			'Workington',
			'Withernsea',
			'Witchford',
			'Willenhall',
			'Wheathampstead',
			'Westgate on Sea',
			'Westbury',
			'West Bridgford',
			'Wareham',
			'Warboys',
			'Urmston',
			'Upminster',
			'Ulrome',
			'Tregaron',
			'Thirsk',
			'Terrington St Clement',
			'Talgarth',
			'Swanage',
			'Sundridge',
			'Stevenston',
			'Stallingborough',
			'Sleaford',
			'Skelton',
			'Skelton',
			'Skelmersdale',
			'Skellingthorpe',
			'Selkirk',
			'Seascale',
			'Saundersfoot',
			'Saint Monans',
			'Rothienorman',
			'Rode',
			'Roade',
			'Remenham',
			'Reepham',
			'Rawtenstall',
			'Ramsbury',
			'Purfleet-on-Thames',
			'Prudhoe',
			'Prestbury',
			'Potterne',
			'Portknockie',
			'Pontyberem',
			'Pickworth',
			'Okehampton',
			'Oakley',
			'Newton Abbot',
			'Nelson',
			'Mouldsworth',
			'Mochdre',
			'Mickle Trafford',
			'Merstham',
			'Lund',
			'Longforgan',
			'Londesborough',
			'Lochwinnoch',
			'Llanilar',
			'Liversedge',
			'Littlebourne',
			'Limehouse',
			'Leeswood',
			'Lamlash',
			'Kirkintilloch',
			'Kidlington',
			'Kidderminster',
			'Hyde',
			'Horbury',
			'Hitchin',
			'Hinckley',
			'Hemingford Grey',
			'Heighington',
			'Heckington',
			'Hebburn',
			'Heanor',
			'Hatfield Heath',
			'Harrow',
			'Halifax',
			'Greenhead',
			'Goodmayes',
			'Friston',
			'Frinton-on-Sea',
			'Flore',
			'Flint',
			'Ferrybridge',
			'Ewhurst',
			'Everton',
			'Emberton',
			'Eggleston',
			'Edwinstowe',
			'Eccleshall',
			'East Leake',
			'East Ham',
			'East Grinstead',
			'Dungiven',
			'Dromore',
			'Downham Market',
			'Cromford',
			'Cowley',
			'Cottenham',
			'Cookley',
			'Cookham',
			'Collingbourne Kingston',
			'Coalisland',
			'Clive',
			'Cleland',
			'Cherry Burton',
			'Chard',
			'Chalfont St Giles',
			'Caterham',
			'Carmunnock',
			'Burley in Wharfedale',
			'Bugle',
			'Brompton',
			'Broadstairs',
			'Brierfield',
			'Bradwell',
			'Bradninch',
			'Boyton',
			'Bowes',
			'Bonhill',
			'Blidworth',
			'Bishop Auckland',
			'Belfast',
			'Barrhead',
			'Barking',
			'Ashby-de-la-Zouch',
			'Andover',
			'Ampthill',
			'Ammanford',
			'Altrincham',
			'Acocks Green',
			'Abbey Dore',
			'Steynton',
			'Clifford',
			'Barlby',
			'Becontree',
			'Winterbourne',
			'Bordon',
			'Woodbury',
			'Whiston',
			'Eaglescliffe',
			'Ruardean',
			'Iver Heath',
			'North Duffield',
			'High Peak',
			'Merriott',
			'Hinton',
			'Greenham',
			'Sheerwater',
			'St Just',
			'Ailsworth',
			'Drongan',
			'Cruden Bay',
			'Wortley',
			'Witton Gilbert',
			'Wilton',
			'Whitworth',
			'West Calder',
			'West Byfleet',
			'Wardle',
			'Walkern',
			'Uddingston',
			'Treorchy',
			'Toddington',
			'Thorp Arch',
			'Thornton',
			'Tavistock',
			'Takeley',
			'Sunninghill',
			'Stow on the Wold',
			'South Nutfield',
			'South Benfleet',
			'Southampton',
			'Skegness',
			'Scalby',
			'St Ives',
			'Saint Cyrus',
			'Renfrew',
			'Radcliffe on Trent',
			'Pulloxhill',
			'Publow',
			'Plumstead',
			'Pitsea',
			'Pershore',
			'Ottringham',
			'Old Kilpatrick',
			'North Newbald',
			'Newbridge',
			'Mobberley',
			'Milnrow',
			'Millport',
			'Milford on Sea',
			'Mexborough',
			'Melling',
			'Market Weighton',
			'Macclesfield',
			'Mablethorpe',
			'Lostwithiel',
			'Longnor',
			'Locking',
			'Llansadwrn',
			'Llanfachraeth',
			'Llanarth',
			'Littlehampton',
			'Langho',
			'Lamberhurst',
			'Kirkburton',
			"King's Lynn",
			'Kingskerswell',
			'Kilmaurs',
			'Kidsgrove',
			'Keady',
			'Islington',
			'Ilkeston',
			'Hythe',
			'Humber',
			'Houghton Regis',
			'Horley',
			'Haxby',
			'Hawthorn',
			'Havant',
			'Hassocks',
			'Harrold',
			'Halling',
			'Halkirk',
			'Hackleton',
			'Great Missenden',
			'Gravesend',
			'Fort William',
			'Forest Row',
			'Falkland',
			'Eyton upon the Weald Moors',
			'Edgmond',
			'Eccleston',
			'East Dereham',
			'Dymchurch',
			'Dundonald',
			'Dronfield',
			'Dove Holes',
			'Cushendall',
			'Cuddington',
			'Cranbrook',
			'Corsham',
			'Compton Martin',
			'Coity',
			'Codford',
			'Chetwynd',
			'Catterall',
			'Carrington',
			'Callander',
			'Busby',
			'Burringham',
			'Bridge',
			'Brandesburton',
			'Bower Chalke',
			'Bottesford',
			'Bishops Lydeard',
			'Bircotes',
			'Billinghay',
			'Bethesda',
			'Bentley',
			'Benson',
			'Bellaghy',
			'Bearsden',
			'Alford',
			'Camber',
			'Bowthorpe',
			'Eight Ash Green',
			'Peaslake',
			'Chiseldon',
			'Isle Of Mull',
			'Seer Green',
			'Deeside',
			'Cheswick Green',
			'Ynysybwl',
			'Allanton',
			'Faifley',
			'Maxwellheugh',
			'Washwood Heath',
			'Basford, Stoke-on-Trent',
			'Yatton',
			'Winford',
			'Wilmcote',
			'Willerby',
			'West Malling',
			'Weedon Bec',
			'Watton',
			'Wallsend',
			'Ushaw Moor',
			'Upwell',
			'Todmorden',
			'Symington',
			'Sudbury',
			'Stillington',
			'Staplehurst',
			'Stansted Mountfitchet',
			'Standon',
			'Stainton',
			'Spittal',
			'South Witham',
			'Shipston on Stour',
			'Shipdham',
			'Salisbury',
			'Saline',
			'Saint Boswells',
			'Ruskington',
			'Royton',
			'Rothes',
			'Romsley',
			'Rhosneigr',
			'Reigate',
			'Redcar',
			'Presteigne',
			'Prestatyn',
			'Pickering',
			'Petton',
			'Patrington',
			'Paignton',
			'Oxford',
			'Orleton',
			'Old Leake',
			'North Thoresby',
			'North Shields',
			'North Petherton',
			'North Berwick',
			'Newtownstewart',
			'Newtonmore',
			'New Cross',
			'Netherton',
			'Muirhead',
			'Morpeth',
			'Mollington',
			'Market Lavington',
			'Marchwood',
			'Lockerbie',
			'Lochmaben',
			'Lidlington',
			'Ledsham',
			'Leatherhead',
			'Kingstone',
			'Kineton',
			'Kennoway',
			'Kenilworth',
			'Jarrow',
			'Jacobstow',
			'Isleworth',
			'Huntley',
			'Hounslow',
			'Hill',
			'Henley in Arden',
			'Hatfield Peverel',
			'Haslemere',
			'Hartlebury',
			'Halesowen',
			'Gullane',
			'Guilsfield',
			'Great Gransden',
			'Great Ayton',
			'Glenboig',
			'Girvan',
			'Friockheim',
			'Four Crosses',
			'Fishbourne',
			'Finedon',
			'Eyemouth',
			'Esher',
			'Erith',
			'Elstree',
			'Dyserth',
			'Dorchester',
			'Doncaster',
			'Distington',
			'Dale',
			'Cupar',
			'Creswell',
			'Coylton',
			'Cottingham',
			'Corston',
			'Collington',
			'Clapham',
			'Chipping Campden',
			'Carterton',
			'Campbeltown',
			'Callington',
			'Burwell',
			'Burton Latimer',
			'Burnham-on-Sea',
			'Bunbury',
			'Broadstone',
			'Brading',
			'Box',
			'Boreham',
			'Bo’ness',
			'Blackpool',
			'Berkswell',
			'Bempton',
			'Beauly',
			'Banwell',
			'Banham',
			'Balloch',
			'Bakewell',
			'Aylsham',
			'Alrewas',
			'Aldford',
			'Airmyn',
			'Crosby',
			'Lynemouth',
			'Grovesend',
			'Craigavon',
			'Bar Hill',
			'Moodiesburn',
			'Smithton',
			'Brixton',
			'Billington',
			'Knaphill',
			'Southchurch Village',
			'High Barnet',
			'Burgh Heath',
			'Bellsbank',
			'Wood Street Village',
			'Wolvercote',
			'Shortlands',
			'Blackridge',
			'Canonbury',
			'Six Bells',
			'Woodsetts',
			'Wombwell',
			'Wivelsfield Green',
			'Winterbourne',
			'Winston',
			'Winchester',
			'Westwood',
			'Westoning',
			'Wales',
			'Ullapool',
			'Turriff',
			'Troon',
			'Tregarth',
			'Tonyrefail',
			'Tobermory',
			'Thorpe le Soken',
			'Thorpe',
			'Tetbury',
			'Sutton in Ashfield',
			'Sutton Bridge',
			'Sutton',
			'Sutton',
			'Stretford',
			'Strathblane',
			'Southill',
			'Slamannan',
			'Shirebrook',
			'Sherston',
			'Sheriff Hutton',
			'Shepley',
			'Seven Sisters',
			'Scotby',
			'Sapcote',
			'Sandbach',
			'Sale',
			'Rufford',
			'Romsley',
			'Ringmer',
			'Redlynch',
			'Ranskill',
			'Radlett',
			'Portstewart',
			'Portsoy',
			'Pool',
			'Penkridge',
			'Penistone',
			'Penally',
			'Paull',
			'Pateley Bridge',
			'Odiham',
			'Nottingham',
			'Northiam',
			'Newtownards',
			'Scone',
			'Newport',
			'Newburn',
			'Moulton Chapel',
			'Moss',
			'Morecambe',
			'Monifieth',
			'Mochdre',
			'Minehead',
			'Milford Haven',
			'Manea',
			'Malton',
			'Loudwater',
			'Leyland',
			'Leyburn',
			'Lavendon',
			'Kingswood',
			'Johnstone',
			'Isleham',
			'Ilminster',
			'Humbleton',
			'Hopeman',
			'Holmpton',
			'Hockliffe',
			'Hinton',
			'Hindhead',
			'High Blantyre',
			'Heswall',
			'Hebden Bridge',
			'Hayes',
			'Harwich',
			'Hartburn',
			'Hambleton',
			'Govilon',
			'Godmanchester',
			'Finchley',
			'Farnborough',
			'Fakenham',
			'Epping',
			'Elsenham',
			'Edenfield',
			'Donaghadee',
			'Derwen',
			'Denton',
			'Denny',
			'Dalserf',
			'Crossford',
			'Crook',
			'Cranleigh',
			'Copplestone',
			'Cockermouth',
			'Clitheroe',
			'Cleveleys',
			'Church',
			'Chudleigh',
			'Chirnside',
			'Chepstow',
			'Carron',
			'Caernarfon',
			'Burgh le Marsh',
			'Brotton',
			'Bridge of Allan',
			'Bredon',
			'Bradfield',
			'Boughton',
			'Blackwell',
			'Blackrod',
			'Bishops Waltham',
			'Bidford-on-Avon',
			'Bicknacre',
			'Bewdley',
			'Barton upon Humber',
			'Barnburgh',
			'Barlborough',
			'Bamburgh',
			'Avening',
			'Aveley',
			'Aspley Guise',
			'Anstruther',
			'Ambrosden',
			'Cefn Cribwr',
			'Merthyr Mawr',
			'Chapmanslade',
			'Wideopen',
			'Hazlerigg',
			'Walton',
			'Winchelsea Beach',
			'Virginia Water',
			'Barbican',
			'Portland',
			'Canford Heath',
			'Bartley Green',
			'Elmstead Market',
			'Deiniolen',
			'Bulphan',
			"St. Ann's",
			'Wrawby',
			'Wool',
			'Woodchurch',
			'Wolverhampton',
			'Wold Newton',
			'Woburn',
			'Wilmslow',
			'Wilden',
			'Wilberfoss',
			'Whitland',
			'Whitechapel',
			'West Hallam',
			'West Bergholt',
			'Wendover',
			'Welton',
			'Washingborough',
			'Warsop',
			'Walkington',
			'Wainfleet All Saints',
			'Undy',
			'Topsham',
			'Timsbury',
			'Terrington Saint John',
			'Tarporley',
			'Swallowfield',
			'Sutterton',
			'Stratfield Mortimer',
			'Stradbroke',
			'Stondon',
			'Stoke Gifford',
			'Stoke Gabriel',
			'Stickney',
			'Steeple Claydon',
			'Standlake',
			'Stalybridge',
			'Southwick',
			'Shenley Church End',
			'Sharnbrook',
			'Sandwick',
			'Rowley Regis',
			'Rowlands Gill',
			'Rhuddlan',
			'Reepham',
			'Queensferry',
			'Prestwick',
			'Preston',
			'Poynton',
			'Potsgrove',
			'Outwell',
			'Oakley',
			'Norbury',
			'Newtownhamilton',
			'Newmilns',
			'New Mills',
			'Newick',
			'New Buildings',
			'Navenby',
			'Muir of Ord',
			'Mildenhall',
			'Milborne Port',
			'Middleton',
			'Methven',
			'Meriden',
			'Measham',
			'Maybole',
			'Lympne',
			'Lower Bullingham',
			'Llanrwst',
			'Llanelli',
			'Linton upon Ouse',
			'Leigh',
			'Largs',
			'Landewednack',
			'Kirkby Lonsdale',
			'Kilbirnie',
			'Kennington',
			'Jeffreyston',
			'Ixworth',
			'Inverness',
			'Invergowrie',
			'Innerleithen',
			'Inkberrow',
			'Holton le Clay',
			'Holtby',
			'Henlow',
			'Helpston',
			'Haswell',
			'Hale',
			'Goosnargh',
			'Glanamman',
			'Gamlingay',
			'Gainsborough',
			'Gainford',
			'Fulbourn',
			'Fochriw',
			'Fleet',
			'Farnsfield',
			'Eynsham',
			'Emsworth',
			'Embsay',
			'Elton',
			'Elderslie',
			'Edmonton',
			'Dundee',
			'Dunblane',
			'Drumnadrochit',
			'Dodworth',
			'Denton',
			'Denham',
			'Dartford',
			'Crick',
			'Cottesmore',
			'Coldstream',
			'Cleethorpes',
			'Chobham',
			'Chinnor',
			'Chingford',
			'Chilcompton',
			'Chicklade',
			'Chew Magna',
			'Chessington',
			'Catrine',
			'Carnforth',
			'Caistor',
			'Bursledon',
			'Bottesford',
			'Bodmin',
			'Bodedern',
			'Birmingham',
			'Bellingham',
			'Bedale',
			'Battlesden',
			'Basildon',
			'Barnoldswick',
			'Bannockburn',
			'Ballinamallard',
			'Ashill',
			'Alderbury',
			'Acle',
			'Abernant',
			'Aberfan',
			'Aberchirder',
			'Hundleton',
			'Abertridwr',
			'Cheadle Heath',
			'Bewbush',
			'Westhill',
			'Colden Common',
			'Blacon',
			'Ingleton',
			'Isle of Bute',
			'Isles of Scilly',
			'Dickens Heath',
			'Stratton',
			'Fleckney',
			'Croy',
			'Hallglen',
			'Reddingmuirhead',
			'Burngreave',
			'Chaddleworth',
			'Barkingside',
			'Rowledge',
			'Wotton-under-Edge',
			'Woolpit',
			'Wokingham',
			'Windsor',
			'Windermere',
			'Winchcombe',
			'Willingham',
			'Willingham',
			'Whalley',
			'Wetherby',
			'West Thurrock',
			'Werrington',
			'Wem',
			'Welwyn Garden City',
			'Wednesfield',
			'Warton',
			'Wallington',
			'Walford',
			'Wakefield',
			'Uttoxeter',
			'Trimdon Grange',
			'Tottington',
			'Torquay',
			'Tolleshunt Knights',
			'Tollesbury',
			'Teynham',
			'Taunton',
			'Swanscombe',
			'Sutton on Trent',
			'Sutton',
			'Stonesfield',
			'Stone',
			'Stoke Goldington',
			'Stainburn',
			'Sproatley',
			'South Hayling',
			'South Elmsall',
			'Slaley',
			'Sion Mills',
			'Sherburn',
			'Shepherds Bush',
			'Shenfield',
			'Shefford',
			'Sharpness',
			'Shafton',
			'Scalloway',
			'Saxmundham',
			'Saint Leonards-on-Sea',
			'Rye',
			'Ripley',
			'Ridlington',
			'Rhyl',
			'Rathfriland',
			'Queniborough',
			'Purley',
			'Portscatho',
			'Porthmadog',
			'Poplar',
			'Pewsey',
			'Pengam',
			'Parkstone',
			'Oxted',
			'Norton',
			'North Wootton',
			'North Walsham',
			'North Bradley',
			'Nefyn',
			'Necton',
			'Moira',
			'Mitcham',
			'Mere',
			'Marshfield',
			'Marnhull',
			'Marlborough',
			'Markyate',
			'Maentwrog',
			'Lugwardine',
			'Ludgershall',
			'London',
			'Llwynypia',
			'Lledrod',
			'Llansteffan',
			'Llansantffraid Glan Conwy',
			'Llanidloes',
			'Llanfyllin',
			'Lingwood',
			'Lincoln',
			'Leslie',
			'Leconfield',
			'Kensworth',
			'Keelby',
			'Ingleton',
			'Inchinnan',
			'Hurworth',
			'Hunstanton',
			'Horsham',
			'Hockwold cum Wilton',
			'Heathfield',
			'Haworth',
			'Hawkhurst',
			'Haslingden',
			'Hartshill',
			'Hanworth',
			'Hanslope',
			'Grindale',
			'Greenisland',
			'Gorseinon',
			'Glossop',
			'Fochabers',
			'Falfield',
			'Fairford',
			'Evington',
			'Euxton',
			'Elmswell',
			'Ellesmere Port Town',
			'Egham',
			'Eckington',
			'Eccleston',
			'East Tilbury',
			'East Linton',
			'Dunnington',
			'Dorstone',
			'Crowthorne',
			'Creech Saint Michael',
			'Cowie',
			'Clackmannan',
			'Cirencester',
			'Cheshunt',
			'Chelford',
			'Cheddleton',
			'Cheadle',
			'Chapel en le Frith',
			'Castlewellan',
			'Carlisle',
			'Campsall',
			'Caddington',
			'Buxted',
			'Bury St Edmunds',
			'Burrington',
			'Burntisland',
			'Burbage',
			'Budleigh Salterton',
			'Bramhope',
			'Bourton on the Water',
			'Borrowash',
			'Bishops Cleeve',
			'Biggleswade',
			'Belton',
			'Beaminster',
			'Bathgate',
			'Barrowford',
			'Barningham',
			'Barnes',
			'Bargoed',
			'Bampton',
			'Baldock',
			'Askern',
			'Ardingly',
			'Ahoghill',
			'Adderbury',
			'Aberdeen',
			'Scleddau',
			'Beddau',
			'Shadwell',
			'Tintern',
			'Little Chalfont',
			'Chadwell St Mary',
			'Culverstone Green',
			'Bethnal Green',
			'Muswell Hill',
			'Great Marton',
			'Rossendale',
			'Isle of Arran',
			'Mayford',
			'Balloch',
			'Mossblown',
			'Waterfoot',
			'Laurieston',
			'Maddiston',
			'Polbeth',
			'Thornliebank',
			'Wishaw',
			'Whitchurch',
			'Wheldrake',
			'West Wickham',
			'Westbury',
			'Warrington',
			'Wanborough',
			'Usk',
			'Uny Lelant',
			'Uffculme',
			'Tyberton',
			'Trawsfynydd',
			'Todwick',
			'Tinwell',
			'Thornton',
			'Tenby',
			'Teignmouth',
			'Tamworth',
			'Swineshead',
			'Sutton Bonington',
			'Upper Stoke',
			'Stannington',
			'Stanhope',
			'Stanford in the Vale',
			'Spalding',
			'Sowerby Bridge',
			'Southowram',
			'Skelton',
			'Sherburn Hill',
			'Shepton Mallet',
			'Shelley',
			'Shaw',
			'Sanquhar',
			'Saintfield',
			'Sacriston',
			'Romsey',
			'Ridgmont',
			'Ravenstone',
			'Purton',
			'Plymstock',
			'Petersfield',
			'Partington',
			'Otley',
			'Orwell',
			'Oldmeldrum',
			'Ocle Pychard',
			'Norwich',
			'Newtown St Boswells',
			'Newburgh',
			'Newbiggin',
			'Netheravon',
			'Mytholmroyd',
			'Moretonhampstead',
			'Misterton',
			'Milnathort',
			'Maryport',
			'Marston Moretaine',
			'Marsden',
			'Marlow',
			'Marazion',
			'Machynlleth',
			'Little Paxton',
			'Royal Leamington Spa',
			'Kintbury',
			'Kinloss',
			'Kilburn',
			'Keynsham',
			'Kegworth',
			'Ingatestone',
			'Ince Blundell',
			'Ilfracombe',
			'Hunwick',
			'Hook',
			'Holywood',
			'Holborn',
			'Hemsworth',
			'Helston',
			'Helland',
			'Heacham',
			'Hawkinge',
			'Hatfield',
			'Hatfield',
			'Harlech',
			'Great Leighs',
			'Greatham',
			'Great Coates',
			'Great Amwell',
			'Gilford',
			'Gilberdyke',
			'Fivemiletown',
			'Farnham',
			'Enfield Lock',
			'Eastwood',
			'Retford',
			'East Molesey',
			'East Harptree',
			'East Harling',
			'Earith',
			'Dingwall',
			'Dersingham',
			'Darenth',
			'Danbury',
			'Cullingworth',
			'Crumlin',
			'Cricklewood',
			'Corsenside',
			'Copmanthorpe',
			'Consett',
			'Colsterworth',
			'Clowne',
			'Camerton',
			'Bures Saint Mary',
			'Buntingford',
			'Brownhills',
			'Broseley',
			'Brooke',
			'Bromborough',
			'Brinscall',
			'Brill',
			'Brigg',
			'Bridgnorth',
			'Bridgend',
			'Bozeat',
			'Blunham',
			'Blaby',
			"Bishop's Castle",
			'Billericay',
			'Bere Alston',
			'Aston',
			'Aspatria',
			'Adwick le Street',
			'Acomb',
			'Acklington',
			'Southgate',
			'Muirhead',
			'Gerrards Cross',
			'Clayton-le-Woods',
			'Belmont',
			'Curdworth',
			'Hadley Wood',
			'Crystal Palace',
			'Bodle Street',
			'Grange-over-Sands',
			'Balsall Common',
			'Murton',
			'Ovington',
			'Nanpean',
			'Penyffordd',
			'Lee',
			'Stanton Drew',
			'Monkton Farleigh',
			'Manor Park',
			'Milton of Leys',
			'Plains',
			'Netherlee',
			'Archway',
			'Old Harlow',
			'Perry Vale',
			'Yelverton',
			'Yarmouth',
			'Writtle',
			'Wouldham',
			'Wilstead',
			'Wickwar',
			'Whitby',
			'Wembury',
			'Welshpool',
			'Watchet',
			'Warton',
			'Warden',
			'Wanstead',
			'Wall',
			'Turnastone',
			'Sway',
			'Studley',
			'Startforth',
			'Stainforth',
			'South Molton',
			'Shenley',
			'Scholes',
			'Salfords',
			'Saint Peters',
			'Saint Asaph',
			'Rudgwick',
			'Rowde',
			'Rochford',
			'Rainhill',
			'Rainham',
			'Rainford',
			'Pulborough',
			'Prescot',
			'Pelsall',
			'Overton',
			'Northop',
			'Nash',
			'Murton',
			'Monk Fryston',
			'Mirfield',
			'Meikle Earnock',
			'Marshfield',
			'Market Drayton',
			'Margate',
			'Manby',
			'Lydham',
			'Llangefni',
			'Little Houghton',
			'Little Eaton',
			'Littleborough',
			'Laxton',
			'Lathbury',
			'Kiveton Park',
			'Kislingbury',
			'Kircubbin',
			'Kingskettle',
			'Killearn',
			'Irvine',
			'Inverkeithing',
			'Hunmanby',
			'Holmfirth',
			'High Halden',
			'Hexham',
			'Herne Bay',
			'Heighington',
			'Hamilton',
			'Halstead',
			'Hackney',
			'Great Wyrley',
			'Great Wakering',
			'Great Houghton',
			'Glyn-neath',
			'Gloucester',
			'Garsington',
			'Galashiels',
			'Gaerwen',
			'Freystrop',
			'Duxford',
			'Dunlop',
			'Dorridge',
			'Dornoch',
			'Dobwalls',
			'Denbigh',
			'Darvel',
			'Cullen',
			'Coupar Angus',
			'Cosby',
			'Comberton',
			'Combe Martin',
			'Colwyn Bay',
			'Clacton-on-Sea',
			'Chinley',
			'Chickerell',
			'Cheddington',
			'Cheam',
			'Canewdon',
			'Caerphilly',
			'Bushmills',
			'Burton Pidsea',
			'Brigstock',
			'Brechin',
			'Bratton',
			'Brandon',
			'Brampton',
			'Brackley',
			'Blyth',
			'Bloxham',
			'Bleadon',
			'Blandford Forum',
			'Blackburn',
			'Bingley',
			'Biddulph',
			'Biddenham',
			'Biddenden',
			'Beccles',
			'Beaconsfield',
			'Barnsley',
			'Ballywalter',
			'Ballynahinch',
			'Balham',
			'Avebury',
			'Auckley',
			'Askam in Furness',
			'Appledore',
			'Alness',
			'Abram',
			'Aberaeron',
			'Trimsaran',
			'Cameley',
			'Hale',
			'Blackley',
			'Radcliffe',
			'Adlington',
			'Penparcau',
			'Stewartby',
			'Grimethorpe',
			'Glapwell',
			'Maple Cross',
			'Benbecula',
			'Harringay',
			'Hampton Wick',
			'Talysarn',
			'Calderbank',
			'Greenhill',
			'West Ilsley',
			'New Basford',
			'Rastrick',
			'Yarnton',
			'Wylam',
			'Woolwich',
			'Wolston',
			'Witheridge',
			'Wingham',
			'Wilsford',
			'Wickham',
			'Wick',
			'Whitehills',
			'Wheatley',
			'Weston-super-Mare',
			'Westcott',
			'Weobley',
			'Welford',
			'Warkworth',
			'Wansford',
			'Upton',
			'Ulley',
			'Treuddyn',
			'Trafford Park',
			'Townhill',
			'Tarbert',
			'Tandragee',
			'Swillington',
			'Swaffham',
			'Sturminster Marshall',
			'Streatley',
			'Strabane',
			'Stoney Stanton',
			'Stoke-on-Trent',
			'Stock',
			'Stevington',
			'South Milford',
			'South Bank',
			'Shipton under Wychwood',
			'Saint Davids',
			'Saint Bees',
			'St Albans',
			'Ruyton-XI-Towns',
			'Robertsbridge',
			'Pyle',
			'Princetown',
			'Princes Risborough',
			'Porthcawl',
			'Portavogie',
			'Pocklington',
			'Plympton',
			'Peakirk',
			'Padstow',
			'Otterburn',
			'Newton Ferrers',
			'New Milton',
			'Nettleton',
			'Neston',
			'Neilston',
			'Neath',
			'Nantwich',
			'Montrose',
			'Milford',
			'Mickleton',
			'Melksham',
			'Matlock',
			'Marks Tey',
			'Manton',
			'Manchester',
			'Maesycwmmer',
			'Derry',
			'Lochgelly',
			'Llanwinio',
			'Llangybi',
			'Llangain',
			'Llanbradach',
			'Limpley Stoke',
			'Law',
			'Llangwm',
			'Langholm',
			'Landore',
			'Kingsclere',
			'Kenn',
			'Jordanstown',
			'Iwade',
			'Inverurie',
			'Huddersfield',
			'Horton Kirby',
			'Hibaldstow',
			'Heslington',
			'Hellifield',
			'Heckmondwike',
			'Hatton',
			'Harvington',
			'Harrietsham',
			'Harleston',
			'Hailsham',
			'Hagley',
			'Guisborough',
			'Gresford',
			'Goxhill',
			'Goostrey',
			'Glusburn',
			'Gedney Hill',
			'Forres',
			'Finchampstead',
			'Ffestiniog',
			'Felbridge',
			'Exton',
			'Edgworth',
			'Duloe',
			'Dukinfield',
			'Ducklington',
			'Dorking',
			'Doagh',
			'Dinas Powys',
			'Darfield',
			'Culcheth',
			'Cranford',
			'Cowbit',
			'Cotgrave',
			'Cold Ashton',
			'Clayton',
			'Chislehurst',
			'Cheddar',
			'Cheadle Hulme',
			'Carnlough',
			'Caergwrle',
			'Burtonwood',
			'Burstwick',
			'Burscough',
			'Broughton',
			'Bridgwater',
			'Bridge of Weir',
			'Brent Knoll',
			'Brenchley',
			'Bream',
			'Brayton',
			'Braintree',
			'Bishopton',
			'Beith',
			'Barnt Green',
			'Ayr',
			'Aylesford',
			'Ashton-under-Lyne',
			'Ashford',
			'Ash',
			'Annan',
			'Alresford',
			'Almondbank',
			'Benllech',
			'Overcombe',
			'Tywyn',
			'Yateley',
			'Didsbury',
			'Longsight',
			'Chorlton',
			'Larkfield',
			'Kempston Hardwick',
			'Denmead',
			'Tweedbank',
			'Tal-y-bont',
			'East Sheen',
			'Redding',
			'Grayshott',
			'Stamford Hill',
			'Hardwick Village',
			'Yetminster',
			'Yalding',
			'Wragby',
			'Royal Wootton Bassett',
			'Woodborough',
			'Wolsingham',
			'Witham',
			'Winterton',
			'Winterbourne Stoke',
			'Wingfield',
			'Willington',
			'Wickham',
			'Whitburn',
			'Wetheral',
			'West Cornforth',
			'Walberton',
			'Towcester',
			'Sutton Benger',
			'Strood',
			'Stone',
			'Staveley',
			'Starcross',
			'Stanmore',
			'South Petherton',
			'Snaith',
			'Sibsey',
			'Shoreham-by-Sea',
			'Shillingstone',
			'Seaford',
			'St Leonards',
			'Saint Agnes',
			'Ruislip',
			'Riccall',
			'Rhoose',
			'Rayne',
			'Ravenstone',
			'Rainworth',
			'Radyr',
			'Queensferry',
			'Port Talbot',
			'Peterculter',
			'Penrith',
			'Penmaenmawr',
			'Pencader',
			'Pannal',
			'Orpington',
			'Newington',
			'Much Hadham',
			'Moelfre',
			'Metheringham',
			'Meppershall',
			'Melbourn',
			'Market Overton',
			'Market Bosworth',
			'Marcham',
			'Lydiard Millicent',
			'Lutterworth',
			'Luckington',
			'Loughborough',
			'Long Itchington',
			'Llanwern',
			'Llangynog',
			'Llangoed',
			'Lingdale',
			'Leysdown-on-Sea',
			'Leominster',
			'Lanark',
			'Kirton',
			'Kirkconnel',
			'Kingsbury',
			'Kinglassie',
			'Kemsing',
			'Immingham',
			'Hythe',
			'Hucknall',
			'Hornsey',
			'Holywell',
			'Highworth',
			'High Valleyfield',
			'Herbrandston',
			'Hemyock',
			'Hawick',
			'Harpole',
			'Harlington',
			'Grangemouth',
			'Grain',
			'Gorleston-on-Sea',
			'Golspie',
			'Glenavy',
			'Frodsham',
			'Freshford',
			'Fremington',
			'Forth',
			'Fordham',
			'Exminster',
			'Eversholt',
			'Epworth',
			'Emley',
			'Elton',
			'Dursley',
			'Durham',
			'Dufftown',
			'Donisthorpe',
			'Donington',
			'Cuxton',
			'Cuckfield',
			'Crossgar',
			'Cove',
			'Corse',
			'Coppull',
			'Congresbury',
			'Cockfield',
			'Clutton',
			'Honeybourne',
			'Chilworth',
			'Charlton',
			'Charing',
			'Chagford',
			'Castlethorpe',
			'Carshalton',
			'Carluke',
			'Carlton',
			'Caister-on-Sea',
			'Burghead',
			'Broughton',
			'Boxted',
			'Borth',
			'Bickenhill',
			'Beaumaris',
			'Baston',
			'Barlestone',
			'Bankfoot',
			'Bangor',
			'Balfron',
			'Ardrishaig',
			'Alston',
			'Albrighton',
			'Addlestone',
			'Charlestown of Aberlour',
			'Abbotts Ann',
			'Llangynog',
			'Fontwell',
			'Westcliff-on-Sea',
			'Gossops Green',
			'Amersham on the Hill',
			'Cranham',
			'Chafford Hundred',
			'South Croydon',
			'Chapel Allerton',
			'Ash',
			'Donnington',
			'Bilston',
			'Hameldon Hill',
			'Fetcham',
			'Danderhall',
			'Old Woking',
			'Brightons',
			'Seafield',
			'Vale of Leven',
			'Saint Andrews Quay',
			'Cwmtillery',
			'Wroxall',
			'Wootton',
			'Woodstock',
			'Wilsden',
			'Widnes',
			'Whitwell',
			'Whitstable',
			'Water Orton',
			'Waddington',
			'Uxbridge',
			'Tottenham',
			'Tipton',
			'Tickton',
			'Thames Ditton',
			'Tadcaster',
			'Syston',
			'Swineshead',
			'Stroud',
			'Stonehouse',
			'Steyning',
			'Speldhurst',
			'Sonning',
			'Somersham',
			'Shirley',
			'Shevington',
			'Shenstone',
			'Shadoxhurst',
			'Selsey',
			'Sedgefield',
			'Sandford',
			'St Austell',
			'Saint Andrews',
			'Rothley',
			'Rotherfield Peppard',
			'Rokeby',
			'Rillington',
			'Queenborough',
			'Potters Bar',
			'Portrush',
			'Porth',
			'Pontyates',
			'Pontefract',
			'Pembroke Dock',
			'Parbold',
			'Newton Longville',
			'Newcastle upon Tyne',
			'New Alresford',
			'Nether Stowey',
			'Muggleswick',
			'Much Wenlock',
			'Mossend',
			'Moreton',
			'Morchard Bishop',
			'Menai Bridge',
			'Marstow',
			'Marholm',
			'Maldon',
			'Longhorsley',
			'Long Buckby',
			'Llantwit Fardre',
			'Llandrindod Wells',
			'Llandrillo',
			'Llanberis',
			'Little Bookham',
			'Litherland',
			'Landrake',
			'Knebworth',
			'Kirriemuir',
			'Kirkby Stephen',
			'Kirkby',
			'Kington',
			'Kingston Seymour',
			'Kincardine',
			'Kilpin',
			'Killamarsh',
			'Keresley',
			'Kempsey',
			'Kelvedon Hatch',
			'Horton',
			'Holt',
			'Hilton',
			'Hillingdon',
			'Highclere',
			'Harlesden',
			'Hapton',
			'Great Yarmouth',
			'Godshill',
			'Glyncorrwg',
			'Gargrave',
			'Framlingham',
			'Flockton',
			'Fimber',
			'Fauldhouse',
			'Eaton Bray',
			'East Wemyss',
			'Easington',
			'Ealing',
			'Dundonald',
			'Dumfries',
			'Duffield',
			'Dollar',
			'Ditchling',
			'Crynant',
			'Cogenhoe',
			'Cockenzie',
			'Chipping Ongar',
			'Chicksands',
			'Chicheley',
			'Castlerock',
			'Camborne',
			'Camberley',
			'Cairneyhill',
			'Butcombe',
			'Burnley',
			'Bulford',
			'Bude',
			'Bucknell',
			'Buckley',
			'Buckden',
			'Broughton',
			'Bromyard',
			'Brentwood',
			'Bremhill',
			'Bramley',
			'Bramhall',
			'Boosbeck',
			'Bishopstoke',
			'Bicester',
			'Bellshill',
			'Baslow',
			'Ballyclare',
			'Ballater',
			'Balerno',
			'Bala',
			'Baildon',
			'Atworth',
			'Appleton',
			'Amble',
			'Alsager',
			'Aldershot',
			'Alderley Edge',
			'Acton',
			'Abergavenny',
			'Three Crosses',
			'Hook',
			'Blackwood',
			'Kesgrave',
			'Kennington',
			'Wychbold',
			'Shadwell',
			'Ditton Hill',
			'Crumpsall',
			'Greenfield',
			'Barra',
			'Fairlands',
			'Stenhousemuir',
			'Monkhams',
			'East Peckham',
			'Dunkeswell',
			'Dinmore',
			'Deddington',
			'Dawlish',
			'Daventry',
			'Dalton in Furness',
			'Croxton',
			'Crofton',
			'Criccieth',
			'Cresswell',
			'Cooling',
			'Comrie',
			'Colinton',
			'Coddington',
			'Coatbridge',
			'Coalville',
			'Cleobury Mortimer',
			'Claydon',
			'Chorley',
			'Chilton Foliat',
			'Chelsea',
			'Charmouth',
			'Chapeltown',
			'Chapel Saint Leonards',
			'Castor',
			'Castlederg',
			'Canterbury',
			'Buxton',
			'Burton upon Trent',
			'Broomfleet',
			'Brixworth',
			'Brixham',
			'Bourne',
			'Bootle',
			'Bexleyheath',
			'Berkhamsted',
			'Battle',
			'Basingstoke',
			'Barrow upon Soar',
			'Barnack',
			'Barmby on the Marsh',
			'Balsham',
			'Aviemore',
			'Atwick',
			'Antrim',
			'Ancroft',
			'Alyth',
			'Alcester',
			'Aberystwyth',
			'Aberporth',
			'Abercynon',
			'Abbots Langley',
			'Abbotskerswell',
			'Shiremoor',
			'New Inn',
			'Easton-in-Gordano',
			'Hellaby',
			'Danby',
			'Langley Green',
			'Fallowfield',
			'Ferndown',
			'Leytonstone',
			'Kelloe',
			'Abbey Wood',
			'Romney Marsh',
			'Tintagel',
			'Dyffryn Ardudwy',
			'Belmont',
			'East Rainton',
			'Capel le Ferne',
			'Hockley Heath',
			'Cockington',
			'Ladywell',
			'Bargeddie',
			'Kingswells',
			'Whitecraig',
			'Shotley Gate',
			'Shillington',
			'Shepherdswell',
			'Scole',
			'Saughall',
			'Sampford Peverell',
			'Ryton on Dunsmore',
			'Rushden',
			'Ruabon',
			'Rotherham',
			'Rosneath',
			'Rishton',
			'Rhayader',
			'Rayleigh',
			'Rake',
			'Priston',
			'Poole',
			'Ponthir',
			'Polperro',
			'Pitlochry',
			'Pimperne',
			'Peterhead',
			'Perth',
			'Perranwell',
			'Penllyn',
			'Paisley',
			'Northolt',
			'Lancing',
			'Northallerton',
			'Newton Stewart',
			'Newton Mearns',
			'Newport',
			'New Ferry',
			'Nether Poppleton',
			'Nelson',
			'Nailsworth',
			'Musselburgh',
			'Midsomer Norton',
			'Meopham',
			'Menston',
			'Maulden',
			'Maiden Newton',
			'Magherafelt',
			'Lower Brailes',
			'Low Ackworth',
			'Llanllwchaiarn',
			'Llanharan',
			'Llangeler',
			'Llanfairpwllgwyngyll',
			'Llanfair',
			'Llanerchymedd',
			'Llandysul',
			'Little Hulton',
			'Lesbury',
			'Leighton Buzzard',
			'Lavenham',
			'Langtoft',
			'Kidwelly',
			'Kew',
			'Ipswich',
			'Ipplepen',
			'Holytown',
			'Hillsborough',
			'Hendon',
			'Headcorn',
			'Haverfordwest',
			'Harston',
			'Harrow on the Hill',
			'Hankerton',
			'Greenhithe',
			'Great Bookham',
			'Great Barton',
			'Grays',
			'Gorebridge',
			'Glazebury',
			'Glastonbury',
			'Fylde',
			'Exeter',
			'Evesbatch',
			'Worton',
			'Worthing',
			'Wirksworth',
			'Watton',
			'Warwick',
			'Walsall',
			'Tycroes',
			'Tow Law',
			'Tingewick',
			'Tillicoultry',
			'Tilbury',
			'Thornaby-on-Tees',
			'Sturminster Newton',
			'Streetly',
			'Stratford',
			'Stotfold',
			'Stonehouse',
			'Stepps',
			'Stanwick',
			'Spennymoor',
			'Southwell',
			'Wootton',
			'Woodhall Spa',
			'Woking',
			'Wingrave',
			'Whiteparish',
			'Whimple',
			'Wetwang',
			'West Rainton',
			'Weston Turville',
			'Wells-next-the-Sea',
			'Wellington',
			'Watton at Stone',
			'Wath upon Dearne',
			'Warrington',
			'Warnham',
			'Walton-on-Thames',
			'Walsden',
			'Upton Scudamore',
			'Upper Norwood',
			'Trelech',
			'Tregoney',
			'Timperley',
			'Thornhaugh',
			'Thorner',
			'Tewkesbury',
			'Tenterden',
			'Tansley',
			'Sunk Island',
			'Streatley',
			'Stoke Poges',
			'Stokenchurch',
			'Stoke Golding',
			'Springside',
			'South Hill',
			'South Harting',
			'South Cerney',
			'South Cave',
			'Snettisham',
			'Skipton',
			'Shotton',
			'Sheerness',
			'Seaton Delaval',
			'Sandhurst',
			'Rowhedge',
			'Rothwell',
			'Rossett',
			'Rickmansworth',
			'Radley',
			'Pontesbury',
			'Pitmedden',
			'Peover Superior',
			'Penrhyndeudraeth',
			'Pencaitland',
			'Partridge Green',
			'Ottery St Mary',
			'Ottershaw',
			'Odell',
			'Newton-le-Willows',
			'Newport Pagnell',
			'Newmacher',
			'Nettleham',
			'Much Birch',
			'Morcott',
			'Millbrook',
			'Magor',
			'Lyndhurst',
			'Lyme Regis',
			'Lydney',
			'Lower Kingswood',
			'Loughton',
			'Long Stratton',
			'Long Bennington',
			'Liphook',
			'Lee-on-the-Solent',
			'Leek',
			'Ledston',
			'Kippax',
			'Kinvere',
			'Kinross',
			'Kilkeel',
			'Kidbrooke',
			'Kenton',
			'Kenley',
			'Hurley',
			'Horsford',
			'Holbeach',
			'Hindon',
			'Highbridge',
			'High Bentham',
			'Helsby',
			'Hayes',
			'Haydock',
			'Harthill',
			'Hannington',
			'Hammersmith',
			'Hallow',
			'Grimston',
			'Great Horwood',
			'Gosforth',
			'Golborne',
			'Glemsford',
			'Gilwern',
			'Gedling',
			'Geddington',
			'Formby',
			'Findern',
			'Fernhill Heath',
			'Farnborough',
			'Essendine',
			'Elvington',
			'Elton',
			'Eaton Socon',
			'East Kilbride',
			'Earlswood',
			'Dunvant',
			'Dunholme',
			'Dinnington',
			'Denby Dale',
			'Dedham',
			'Darton',
			'Darrington',
			'Cowpen',
			'Cowdenbeath',
			'Coventry',
			'Coulsdon',
			'Claypole',
			'Christchurch',
			'Chapelhall',
			'Cardiff',
			'Callow',
			'Burnham-on-Crouch',
			'Buckhaven',
			'Bridport',
			'Bransgore',
			'Blindley Heath',
			'Blewbury',
			'Blean',
			'Blackburn',
			'Birkenhead',
			'Biggar',
			'Beeston',
			'Axbridge',
			'Audlem',
			'Attleborough',
			'Attleborough',
			'Armadale',
			'Aldbrough',
			'Addingham',
			'Ambleston',
			'Woolavington',
			'Barham',
			'Clarkston',
			'Hinchley Wood',
			'Cheetham Hill',
			'Eversley',
			'Bracebridge Heath',
			'Stanley',
			'Pentre',
			'Rosewell',
			'Bishopsworth',
			'Walton',
			'Twechar',
			'South Norwood',
			'South Luffenham',
			'South Hetton',
			'Silverton',
			'Sidmouth',
			'Siddington',
			'Shirland',
			'Shepshed',
			'Sheffield',
			'Sennen',
			'Sandbank',
			'Rottingdean',
			'Rode Heath',
			'Riseley',
			'Rhondda',
			'Rendlesham',
			'Redwick',
			'Rawmarsh',
			'Rackheath',
			'Portsmouth',
			'Portree',
			'Port Bannatyne',
			'Ponteland',
			'Pirton',
			'Pegswood',
			'Otford',
			'Omagh',
			'Norton',
			'North Luffenham',
			'North Ferriby',
			'Northam',
			'Newport',
			'New Cumnock',
			'Nevern',
			'Milton Bryan',
			'Mevagissey',
			'Marldon',
			'Mainstone',
			'Maghera',
			'Maesteg',
			'Low Etherley',
			'Louth',
			'Llanrug',
			'Llangybi',
			'Llandovery',
			'Little Barford',
			'Lifton',
			'Lhanbryde',
			'Lerwick',
			'Langham',
			'Lancaster',
			'Kirknewton',
			'Kingswood',
			'Kilcreggan',
			'Kibworth Harcourt',
			'Keswick',
			'Keith',
			'Jedburgh',
			'Ivinghoe',
			'Hurstpierpoint',
			'Huntly',
			'Houghton on the Hill',
			'Horsmonden',
			'Hook',
			'Highgate',
			'Heage',
			'Fyfield',
			'Fitzwilliam',
			'Fazeley',
			'Elstow',
			'Ebbw Vale',
			'Eastoft',
			'East Dean',
			'East Calder',
			'Dunkeld',
			'Droitwich',
			'Doune',
			'Dolgellau',
			'Crowle',
			'Crondall',
			'Cowbridge',
			'Corbridge',
			'Comber',
			'Codicote',
			'Clifton',
			'Charminster',
			'Caton',
			'Castle Hedingham',
			'Cardigan',
			'Calne',
			'Burgh by Sands',
			'Buckingham',
			'Buckie',
			'Bryneglwys',
			'Bruton',
			'Broxbourne',
			'Brighstone',
			'Braunton',
			'Bramham',
			'Bow',
			'Bovey Tracey',
			// 'Boston',
			'Bickley',
			'Bassingbourn',
			'Ballymena',
			'Astwick',
			'Ashbourne',
			'Arundel',
			'Alnwick',
			'Abernethy',
			'Aberford',
			'Aber',
			'Price Town',
			'Crouch End',
			'Kilburn',
			'Nether Heyford',
			'Little Hallingbury',
			'Barnwood',
			'Isle of Lewis',
			'Flimwell',
			'Stakeford',
			'Addiebrownhill',
			'Banknock',
			'Holbeck',
			'Gipsy Hill',
			'Wooler',
			'Wold Newton',
			'Whittington',
			'Whittingham',
			'Whickham',
			'West Drayton',
			'West Clandon',
			'Wembley',
			'Welling',
			'Watford',
			'Warrenpoint',
			'Tiverton',
			'Tiverton',
			'Tideswell',
			'Thurlton',
			'Thornton Heath',
			'Thame',
			'Tarbolton',
			'Tadley',
			'Sywell',
			'Swanmore',
			'Sturry',
			'Stretham',
			'Strathpeffer',
			'Stourport-on-Severn',
			'Stoke upon Tern',
			'Stockton',
			'Stanford-le-Hope',
			'Southwold',
		],
	},
	'United States': {
		country: 'United States',
		cities: [
			'Waterboro',
			'Yarmouth',
			'Belmont',
			'Colon',
			'Croswell',
			'East Jordan',
			'Greenville',
			'Leland',
			'Milan',
			'New Haven',
			'Paw Paw',
			'Lake Isabella',
			'Waverly',
			'Wayne',
			'Anoka',
			'Apple Valley',
			'Babbitt',
			'Bemidji',
			'Breckenridge',
			'Glenwood',
			'Lake Shore',
			'Mapleton',
			'North Mankato',
			'Prior Lake',
			'Saint Augusta',
			'Shoreview',
			'Sleepy Eye',
			'Spring Lake Park',
			'Waverly',
			'Grafton',
			'Sheldon',
			'Valley',
			'Wahoo',
			'Barrington',
			'Colebrook',
			'Effingham',
			'Orford',
			'Salisbury',
			'Seabrook',
			'Annandale',
			'Brass Castle',
			'Cliffwood Beach',
			'Freehold',
			'Garwood',
			'Hillsborough',
			'North Bergen',
			'Ocean Grove',
			'Old Bridge',
			'Riverton',
			'Society Hill',
			'Union Beach',
			'Victory Gardens',
			'Ardsley',
			'Bainbridge',
			'Bronxville',
			'Cambridge',
			'Canastota',
			'Chappaqua',
			'Chatham',
			'Clarence Center',
			'Clinton Corners',
			'Cohoes',
			'Deansboro',
			'Dongan Hills',
			'East Hampton',
			'East Tremont',
			'Elmont',
			'Flatlands',
			'Franklin Square',
			'Fredonia',
			'Herricks',
			'Hicksville',
			'Holley',
			'Houghton',
			'Lake Luzerne',
			'Latham',
			'Lindenhurst',
			'Manlius',
			'Marbletown',
			'Massapequa Park',
			'New Cassel',
			'Newburgh',
			'North Bay Shore',
			'Olean',
			'Oriskany',
			'Queensbury',
			'Rego Park',
			'Richfield Springs',
			'Walton',
			'Washington Mills',
			'Williamson',
			'Amherst',
			'Bellaire',
			'Forest',
			'Garrettsville',
			'Geneva',
			'Greenville',
			'Newton Falls',
			'Parma Heights',
			'Rockford',
			'Smithville',
			'Stryker',
			'Avalon',
			'Belmont',
			'Bowmansville',
			'Brackenridge',
			'Bressler',
			'Castle Shannon',
			'Clarks Green',
			'Derry',
			'Dunnstown',
			'Forest Hills',
			'Fountain Hill',
			'Hilldale',
			'Honey Brook',
			'Lock Haven',
			'Loyalhanna',
			'Marshallton',
			'Mayfield',
			'Mifflinville',
			'Muhlenberg Park',
			'Pleasant Hills',
			'Pottstown',
			'Richboro',
			'Robesonia',
			'Sand Hill',
			'Schnecksville',
			'South Williamsport',
			'Southmont',
			'Spangler',
			'Stowe',
			'Trooper',
			'Village Shires',
			'West Lawn',
			'Whitehall',
			'Womelsdorf',
			'Woodside',
			'Cumberland Hill',
			'Valley Falls',
			'Flandreau',
			'Volga',
			'Bridport',
			'Antigo',
			'Cadott',
			'Clear Lake',
			'Como',
			'Cross Plains',
			'De Forest',
			'Delavan Lake',
			'Eldorado',
			'Ellsworth',
			'Friendship',
			'Hartford',
			'Janesville',
			'Mequon',
			'Mondovi',
			'New Berlin',
			'Reedsville',
			'Bethany',
			'Chester',
			'Carmel',
			'Green Valley',
			'Saint Michaels',
			'Agua Dulce',
			'Angwin',
			'Artesia',
			'Blythe',
			'Buena Vista',
			'Canoga Park',
			'Carpinteria',
			'Clay',
			'Dinuba',
			'Earlimart',
			'El Granada',
			'Elk Grove',
			'Folsom',
			'Healdsburg',
			'Lancaster',
			// 'London',
			'Los Alamos',
			'Madera Acres',
			'Magalia',
			'Mayflower Village',
			'Novato',
			'Oakland',
			'Pacific Grove',
			'Redwood City',
			'San Miguel',
			'Saratoga',
			'Summerland',
			'West Rancho Dominguez',
			'Williams',
			'Willits',
			'Woodlake',
			'Central City',
			'Gunnison',
			'Monument',
			'Sherrelwood',
			'Elkhart',
			'Lakin',
			'Alamogordo',
			'Doña Ana',
			'El Valle de Arroyo Seco',
			'Estancia',
			'Santa Clara',
			'Vado',
			'Gardnerville',
			'Big Lake',
			'Big Spring',
			'Gail',
			'Horizon City',
			'Tahoka',
			'Westway',
			'Pojoaque',
			'Ferndale',
			'Lewiston',
			'Fort Collins',
			'Dubois',
			'Lewiston',
			'Pinehurst',
			'Post Falls',
			'Ekalaka',
			'Glendive',
			'Hysham',
			'Sun Prairie',
			'Linton',
			'Tioga',
			'Mullen',
			'Winnemucca',
			'Bunker Hill',
			'Junction City',
			'Oregon City',
			'Pendleton',
			'Phoenix',
			'Cedar Hills',
			'Dayton',
			'East Renton Highlands',
			'Fife',
			'Gig Harbor',
			'Lynden',
			'Parkwood',
			'Port Townsend',
			'Sammamish',
			'Seabeck',
			'Steilacoom',
			'Tacoma',
			'Toppenish',
			'Vashon',
			'Venersborg',
			'Lyman',
			'Rawlins',
			'Kalāheo',
			'Kīlauea',
			'Mā‘ili',
			'Waikoloa',
			'Captain Cook',
			'Cordova',
			'Palmer',
			'Akutan',
			'Morningside Heights',
			'Dayton',
			'Gramercy Park',
			'Simsbury Center',
			'Cortland West',
			'Paramount-Long Meadow',
			'Greenport West',
			'Lutherville-Timonium',
			'Hutchinson Island South',
			'Bonner-West Riverside',
			'Fairchild Air Force Base',
			'Maple Heights-Lake Desire',
			'Lakeland North',
			'Dixon Lane-Meadow Creek',
			'West Clarkston-Highland',
			'Haiku-Pauwela',
			'Viera West',
			'Paloma Creek South',
			'Beech Mountain Lakes',
			'Phoenix Lake',
			'Holly Hills',
			'Apple Valley',
			'Edna',
			'Ocean View',
			'Bridgeport',
			'Fyffe',
			'Glencoe',
			'Henagar',
			'Hokes Bluff',
			'Jacksonville',
			'Jasper',
			'Ladonia',
			'Sylvan Springs',
			'Tarrant',
			'Cedarville',
			'Diaz',
			'Dover',
			'Riverview',
			'Apalachicola',
			'Bay Pines',
			'Bee Ridge',
			'Biscayne Park',
			'Carrollwood',
			'Cheval',
			'Chuluota',
			'East Naples',
			'East Perrine',
			'Glenvar Heights',
			'Haverhill',
			'Hobe Sound',
			'Lacoochee',
			"Land O' Lakes",
			'Lely Resort',
			'Miami Gardens',
			'Mulberry',
			'Osprey',
			'Pahokee',
			'Palm Harbor',
			'Palmetto Estates',
			'Princeton',
			'Rock Island',
			'Royal Palm Beach',
			'St. Petersburg',
			'Sawgrass',
			'Silver Springs Shores',
			'Springfield',
			'Tiger Point',
			'Alamo',
			'Folkston',
			'Franklin Springs',
			'Mountain Park',
			'Redan',
			'St. Marys',
			'Sandersville',
			'Skidaway Island',
			'Talbotton',
			'Arthur',
			'Central City',
			'East Alton',
			'Herrin',
			'Holiday Shores',
			'Metropolis',
			'Murphysboro',
			'Sparta',
			'Waverly',
			'Darmstadt',
			'Fairview Park',
			'Monrovia',
			'Shelbyville',
			'Belle Plaine',
			'Cheney',
			'Columbus',
			'Ellis',
			'Great Bend',
			'Hillsboro',
			'Rose Hill',
			'Sabetha',
			'Solomon',
			'Westwood',
			'Danville',
			'Dixon',
			'Franklin',
			'Georgetown',
			'Lebanon Junction',
			'South Shore',
			'Wilmore',
			'Alexandria',
			'Banks Springs',
			'Chauvin',
			'Grand Isle',
			'Jonesville',
			'Mathews',
			'Oberlin',
			'Ponchatoula',
			'Supreme',
			'Zwolle',
			'Annapolis',
			'Bowleys Quarters',
			'California',
			'Capitol Heights',
			'Cockeysville',
			'Darnestown',
			'Middletown',
			'North Potomac',
			'Odenton',
			'Riverside',
			'Saint Michaels',
			'Silver Hill',
			'Snow Hill',
			'Suitland',
			'Towson',
			'Battlefield',
			'Branson',
			'Crestwood',
			'Dellwood',
			'Esther',
			'Garden City',
			'Glasgow',
			'Grain Valley',
			'Lexington',
			'Licking',
			'Linneus',
			'Marionville',
			'Oran',
			'Shrewsbury',
			'De Lisle',
			'Edwards',
			'Meadville',
			'New Albany',
			'Petal',
			'University',
			'Vancleave',
			'Winona',
			'Ayden',
			'Clinton',
			'Elkin',
			'Fletcher',
			'Franklinton',
			'Kenly',
			'La Grange',
			'Mars Hill',
			'Mayodan',
			'Mount Pleasant',
			'Roanoke Rapids',
			'Summerfield',
			'Tryon',
			'Weldon',
			'West Marion',
			'Williamston',
			'Wrightsboro',
			'Yadkinville',
			'Ocean Acres',
			'Pomona',
			'Seabrook Farms',
			'Woodstown',
			'Aberdeen',
			'Circleville',
			'Farmersville',
			'Forest Park',
			'New Lebanon',
			'New Richmond',
			'Oakwood',
			'Chouteau',
			'Hugo',
			'Lone Grove',
			'Norman',
			'Shawnee',
			'Tecumseh',
			'Tonkawa',
			'East Uniontown',
			'Folcroft',
			'Greencastle',
			'Cheraw',
			'Great Falls',
			'Liberty',
			'Union',
			'Walterboro',
			'Belle Meade',
			'Condon',
			'Farragut',
			'Forest Hills',
			'Franklin',
			'Gainesboro',
			'Harrison',
			'Hendersonville',
			'Martin',
			'Morristown',
			'Barrett',
			'Briar',
			'Cut and Shoot',
			'Duncanville',
			'El Lago',
			'Garfield',
			'George West',
			'Grandview',
			'Gun Barrel City',
			'Hemphill',
			'Hewitt',
			'Hudson Oaks',
			'Ingleside',
			'Joshua',
			'La Grange',
			'Lake Jackson',
			'Lake Worth',
			'Lone Star',
			'Marshall',
			'Mathis',
			'Nassau Bay',
			'Ovilla',
			'Port Neches',
			'Quinlan',
			'Raymondville',
			'Smithville',
			'South Point',
			'Southside Place',
			'Talty',
			'Tilden',
			'Universal City',
			'Victoria',
			'Burke',
			'Ferrum',
			'Leesburg',
			'Occoquan',
			'Onancock',
			'Prince George',
			'Shenandoah',
			'Waverly',
			'Woodstock',
			'Beckley',
			'Lavalette',
			'Mabscott',
			'Oceana',
			'Saint Marys',
			'Vienna',
			'Auburn',
			'Inkster',
			'Genoa',
			'East Norwalk',
			'Madison',
			'Middletown',
			'Suffield Depot',
			'Algona',
			'Arnolds Park',
			'Atlantic',
			'Clive',
			'Granger',
			'Grimes',
			'Lake View',
			'Montezuma',
			'Perry',
			'Sumner',
			'Antioch',
			'Bloomington',
			'Capron',
			'Erie',
			'Goodings Grove',
			'Hennepin',
			'Lincolnshire',
			'McKinley Park',
			'Mendota',
			'Paxton',
			'Rochelle',
			'Saint Anne',
			'Streamwood',
			'Wauconda',
			'West Town',
			'Winnetka',
			'Cicero',
			'Lake Station',
			'Markle',
			'Middletown',
			'Milford',
			'Remington',
			'Westville',
			'Dalton',
			'Fall River',
			'Framingham',
			'Hardwick',
			'Lawrence',
			'North Plymouth',
			'Ocean Grove',
			'South Lancaster',
			'Stoneham',
			'Sudbury',
			'Swampscott',
			'Turners Falls',
			'Acton',
			'Auburn',
			'China',
			'Easton',
			'Falmouth Foreside',
			'Greenbush',
			'Jay',
			'Kennebunkport',
			'Kingfield',
			'Old Town',
			'Stockton Springs',
			'Sebewaing',
			'Shepherd',
			'Stevensville',
			'Westland',
			'Woodhaven',
			'Albany',
			'Alexandria',
			'Andover',
			'Becker',
			'Golden Valley',
			'Grand Marais',
			'Kenyon',
			'Zimmerman',
			'Minnetonka',
			'Mountain Iron',
			'Osseo',
			'Preston',
			'Richmond',
			'Sandstone',
			'Starbuck',
			'Wabasha',
			'Wayzata',
			'Winsted',
			'Canton',
			'Milan',
			'Brewster',
			'Creighton',
			'Gibbon',
			'Loup City',
			'East Kingston',
			'East Merrimack',
			'Henniker',
			'Hooksett',
			'Littleton',
			'Webster',
			'Beattystown',
			'Bordentown',
			'Clifton',
			'East Hanover',
			'Glen Ridge',
			'Hamburg',
			'Haledon',
			'Hasbrouck Heights',
			'Lake Como',
			'Manasquan',
			'Manville',
			'Palmyra',
			'Sussex',
			'Trenton',
			'Watchung',
			'West New York',
			'Amherst',
			'Bardonia',
			'Barryville',
			'Bellport',
			'Bloomfield',
			'Borough Park',
			'Branchport',
			'Central Square',
			'Eggertsville',
			'Flanders',
			'Galeville',
			'Harlem',
			'Hauppauge',
			'Huntington Station',
			'Lake Success',
			'Lima',
			'Manchester',
			'Millbrook',
			'Minetto',
			'Morris Park',
			'North Lindenhurst',
			'Peach Lake',
			'Pelham',
			'Piermont',
			'Richmond Hill',
			'Rome',
			'Sanborn',
			'Saranac Lake',
			'Springville',
			'Union Springs',
			'Voorheesville',
			'Williamsville',
			'Akron',
			'Broadview Heights',
			'Fairlawn',
			'Gibsonburg',
			'Lagrange',
			'Lisbon',
			'Monroeville',
			'North Baltimore',
			'Richwood',
			'Sandusky',
			'Steubenville',
			'Wadsworth',
			'West Lafayette',
			'Allison Park',
			'Avis',
			'Bakerstown',
			'Chesterbrook',
			'East Greenville',
			'Edgeworth',
			'Elverson',
			'Freeland',
			'Friedens',
			'Hamburg',
			'Indian Mountain Lake',
			'Kingston',
			'Kulpmont',
			'Laureldale',
			'McKeesport',
			'Murrysville',
			'New Cumberland',
			'Norristown',
			'Oak Hills',
			'Port Vue',
			'Roseto',
			'Salix',
			'Shenandoah',
			'Valley View',
			'Charlestown',
			'Beresford',
			'Brandon',
			'Arlington',
			'Brandon',
			'Jamaica',
			'Lunenburg',
			'Rockingham',
			'Burlington',
			'Chetek',
			'Colfax',
			'Cottage Grove',
			'Cumberland',
			'Deerfield',
			'Green Lake',
			'Ixonia',
			'Jackson',
			'Luxemburg',
			'Marathon',
			'Menasha',
			'Pulaski',
			'Shorewood',
			'Spring Valley',
			'Theresa',
			'Hooverson Heights',
			'Black Canyon City',
			'Clarkdale',
			'Lake Montezuma',
			'Morenci',
			'Star Valley',
			'Taylor',
			'Tucson',
			'Adelanto',
			'Bethel Island',
			'Boonville',
			'Brea',
			'Broadmoor',
			'Chico',
			'Clayton',
			'Cudahy',
			'East Los Angeles',
			'El Sobrante',
			'Greenacres',
			'Huntington Park',
			'Interlaken',
			'Kettleman City',
			'Lake Elsinore',
			'Lake Isabella',
			'Linda',
			'Lodi',
			'Lucerne Valley',
			'Menifee',
			'Moss Beach',
			'Redwood Shores',
			'Running Springs',
			'San Bernardino',
			'Sea Ranch',
			'Seeley',
			'Solvang',
			'Stockton',
			'Vista Santa Rosa',
			'Walnut Grove',
			'Clifton',
			'Denver',
			'Idaho Springs',
			'Ordway',
			'Perry Park',
			'Telluride',
			'Twin Lakes',
			'Dexter',
			'Fort Sumner',
			'Placitas',
			'Alamo',
			'Mesquite',
			'North Las Vegas',
			'Cienegas Terrace',
			'Dickens',
			'Lubbock',
			'Plainview',
			'Robert Lee',
			'Stanton',
			'Wolfforth',
			'Sitka',
			'Cottonwood',
			'Fortuna',
			'Greenville',
			'Willow Creek',
			'Campion',
			'Laporte',
			'Blackfoot',
			'Kamiah',
			'Lapwai',
			'Ucon',
			'Victor',
			'Red Lodge',
			'McClusky',
			'Surrey',
			'Harrison',
			'Hyannis',
			'Imperial',
			'Wells',
			'West Wendover',
			'Clackamas',
			'Eugene',
			'Lincoln Beach',
			'Merlin',
			'Oakridge',
			'Ontario',
			'Riddle',
			'Sisters',
			'Sunriver',
			'Benjamin',
			'Grantsville',
			'Hooper',
			'Midvale',
			'Providence',
			'Randolph',
			'Saratoga Springs',
			'Bremerton',
			'Cottage Lake',
			'Everett',
			'Grand Coulee',
			'Indianola',
			'La Center',
			'Manchester',
			'Meadowdale',
			'North Yelm',
			'Oak Harbor',
			'Snohomish',
			'Sultan',
			'Westport',
			'Ethete',
			'Lander',
			'Wheatland',
			'Punalu‘u',
			'Hālawa Heights',
			'Chattahoochee Hills',
			'Mattapoisett Center',
			'Andrews AFB',
			'Lebanon South',
			'Shanor-Northvue',
			'Champion Heights',
			'West End-Cobb Town',
			'Leadville North',
			'Skyline-Ganipa',
			'Marine Corps Base Hawaii - MCBH',
			'Napili-Honokowai',
			'Kodiak Station',
			'Donovan Estates',
			'Meadowbrook',
			'Greenbriar',
			'Loudoun Valley Estates',
			'Hidden Spring',
			'Doe Valley',
			'Castle Pines North',
			'La Porte',
			'Meadowbrook',
			'New Pekin',
			'Brookside',
			'Calera',
			'Cordova',
			'Eclectic',
			'Horton',
			'Huguley',
			'Indian Springs Village',
			'Livingston',
			'Russellville',
			'Helena',
			'Jacksonville',
			'Mountain View',
			'Pine Bluff',
			'Stuttgart',
			'Sulphur Springs',
			'Chevy Chase',
			'Hockessin',
			'Apopka',
			'Avon Park',
			'Bartow',
			'Casselberry',
			'Cedar Grove',
			'Conway',
			'Cutler Ridge',
			'Cypress Quarters',
			'DeBary',
			'Jensen Beach',
			'Kendall',
			'Kissimmee',
			'Lake Forest',
			'Lake Sarasota',
			'Miami Gardens',
			'Nocatee',
			'North Miami',
			'Ocoee',
			'Pelican Bay',
			'Ponce Inlet',
			'Saint Leo',
			'San Antonio',
			'Tedder',
			'Bainbridge',
			'Braselton',
			'Carnesville',
			'Georgetown',
			'Manchester',
			'Martinez',
			'Saint Simon Mills',
			'Villa Rica',
			'Walnut Grove',
			'Woodbine',
			'Charleston',
			'Johnston City',
			'Pana',
			'Saint Elmo',
			'Saint Jacob',
			'Batesville',
			'Bright',
			'Greensburg',
			'Pittsboro',
			'Sullivan',
			'Carbondale',
			'Grandview Plaza',
			'Haysville',
			'Hutchinson',
			'Osage City',
			'Peabody',
			'Silver Lake',
			'Winfield',
			'Barbourville',
			'Beechwood Village',
			'Corbin',
			'Hurstbourne Acres',
			'Liberty',
			'Louisa',
			'Pikeville',
			'Pioneer Village',
			'Smithland',
			'Tompkinsville',
			'Charenton',
			'Deville',
			'Erwinville',
			'Minorca',
			'Ruston',
			'Sorrento',
			'Cabin John',
			'Centreville',
			'Charlestown',
			'Denton',
			'Edgemere',
			'Frederick',
			'Grasonville',
			'Ilchester',
			'Kensington',
			'Olney',
			'Pocomoke City',
			'Riva',
			'South Laurel',
			'Takoma Park',
			'Barnhart',
			'Buckner',
			'Fenton',
			'Flat River',
			'Fredericktown',
			'Gainesville',
			'Harrisonville',
			'Hollister',
			'Olivette',
			'Peculiar',
			'Riverside',
			'Saint John',
			'Spanish Lake',
			'Warsaw',
			'Washington',
			'Lucedale',
			'Lyman',
			'Marion',
			'Nellieburg',
			'Pickens',
			'Woodville',
			'Bethlehem',
			'Conover',
			'Cove Creek',
			'Ellerbe',
			'Elm City',
			'Enfield',
			'Fairview',
			'Greenville',
			'Lumberton',
			'Masonboro',
			'Morehead City',
			'Pumpkin Center',
			'Pumpkin Center',
			'Rocky Point',
			'Sylva',
			'Wanchese',
			'Waynesville',
			'Westport',
			'Bellmawr',
			'Cinnaminson',
			'Collings Lakes',
			'Glendora',
			'Laurel Springs',
			'Vineland',
			'Wildwood',
			'Cedarville',
			'Holiday Valley',
			'Lockland',
			'Lynchburg',
			'New Miami',
			'Wheelersburg',
			'Winchester',
			'Arnett',
			'Caddo',
			'Choctaw',
			'Colbert',
			'Commerce',
			'Heavener',
			'Owasso',
			'Pocola',
			'Poteau',
			'Sayre',
			'Seminole',
			'Vinita',
			// 'Berlin',
			'Jacobus',
			'Prospect Park',
			'Anderson',
			'Calhoun Falls',
			'Dillon',
			'Forestbrook',
			'Joanna',
			'Lancaster Mill',
			'Langley',
			'Lincolnville',
			'North Myrtle Beach',
			'Ridgeland',
			'Saint George',
			'Wade Hampton',
			'Williston',
			'Adamsville',
			'Blaine',
			'Fairfield Glade',
			'Fayetteville',
			'Greenback',
			'Grimsley',
			'Kingsport',
			'Maryville',
			'New Union',
			'Shackle Island',
			'Alice',
			'Allen',
			'Azle',
			'Bridge City',
			'China Grove',
			'Columbus',
			'Corpus Christi',
			'East Bernard',
			'Hallsville',
			'Jarrell',
			'Killeen',
			'Lancaster',
			'Los Fresnos',
			'Lyford',
			'McQueeney',
			'Nash',
			'North Alamo',
			'Penitas',
			'Premont',
			'Roman Forest',
			'San Saba',
			'Sarita',
			'Spring',
			'Tomball',
			'Troy',
			'West',
			'Wild Peach Village',
			'Wolfe City',
			'Amherst',
			'Chester',
			'Dryden',
			'Dumbarton',
			'Falls Church',
			'King George',
			'Newport News',
			'South Suffolk',
			'Clendenin',
			'Culloden',
			'Wayne',
			'Chillicothe',
			'Devine',
			'Alabaster',
			'Anniston',
			'Argo',
			'Berwick',
			'Georgetown',
			'Greenwich',
			'Manchester',
			'Meriden',
			'Rockville',
			'Storrs',
			'Wauregan',
			'Dysart',
			'Eldora',
			'Greenfield',
			'Lake City',
			'Red Oak',
			'Shell Rock',
			'Woodward',
			'Cicero',
			'Depue',
			'Ford Heights',
			'Geneva',
			'Glenview',
			'Hoopeston',
			'Kenwood',
			'Kildeer',
			'Lansing',
			'Lombard',
			'Long Lake',
			'Monmouth',
			'North Riverside',
			'Pingree Grove',
			'Riverwoods',
			'Roscoe',
			'South Barrington',
			'Stillman Valley',
			'Sugar Grove',
			'Andrews',
			'Converse',
			'Edgewood',
			'Gulivoire Park',
			'Knox',
			'Auburn',
			'Beverly Cove',
			'Concord',
			'Foxborough',
			'Franklin',
			'Haverhill',
			'Maynard',
			'Milford',
			'Monument Beach',
			'Montague',
			'Rehoboth',
			'Royalston',
			'Spencer',
			'Sutton',
			'Wellfleet',
			'West Concord',
			'Wilmington',
			'Frankfort',
			'Madison',
			'North Berwick',
			'Parsonsfield',
			'Pittsfield',
			'Strong',
			'Waterville',
			'Algonac',
			'Alpena',
			'Atlanta',
			'Auburn',
			'Bellaire',
			'Beverly Hills',
			'Canton',
			'Dundee',
			'East Grand Rapids',
			'Jenison',
			'Leslie',
			'Morenci',
			'Niles',
			'East Sandwich',
			'Holbrook',
			'Marion',
			'Needham',
			'North Brookfield',
			'North Westport',
			'Orange',
			'Raynham',
			'Seekonk',
			'South Deerfield',
			'Sterling',
			'Sunderland',
			'Wenham',
			'Westwood',
			'Bristol',
			'Brooks',
			'Chesterville',
			'Edgecomb',
			'Garland',
			'Limerick',
			'Pittston',
			'Birmingham',
			'Caro',
			'Center Line',
			'Coloma',
			'Davison',
			'East Tawas',
			'Grosse Pointe',
			'Gwinn',
			'Lakeview',
			'Lakewood Club',
			'Lexington',
			'Midland',
			'Dodson Branch',
			'Gallatin',
			'Knoxville',
			'Ludington',
			'North Muskegon',
			'Paw Paw Lake',
			'Union City',
			'Wakefield',
			'Cannon Falls',
			'Dawson',
			'Jackson',
			'Monticello',
			'Norwood Young America',
			'Oak Park Heights',
			'Pipestone',
			'Trenton',
			'Harvey',
			'Wahpeton',
			'Beatrice',
			'Burwell',
			'Friend',
			'Hartington',
			'Holdrege',
			'Papillion',
			'Pierce',
			'Superior',
			'Wilber',
			'Danbury',
			'Farmington',
			'New London',
			'Richmond',
			'Rindge',
			'Wilmot',
			'Avenel',
			'Califon',
			'Carteret',
			'Fair Haven',
			'Fanwood',
			'Haworth',
			'Hillsdale',
			'Kearny',
			'Maywood',
			'Ogdensburg',
			'Roselle Park',
			'Shark River Hills',
			'Spotswood',
			'Summit',
			'Wanaque',
			'West Orange',
			'Wood-Ridge',
			'Wyckoff',
			'Atlantic Beach',
			'Auburn',
			'Balmville',
			'Beacon',
			'Broad Channel',
			'Buffalo',
			'Canajoharie',
			'Canarsie',
			'Carle Place',
			'Crugers',
			'Deposit',
			'Dexter',
			'Dyker Heights',
			'Eastchester',
			'Geneva',
			'Greece',
			'Harrison',
			'Herkimer',
			'Highland Mills',
			'Horseheads',
			'Island Park',
			'Jordan',
			'Larchmont',
			'Laurelton',
			'Lloyd Harbor',
			'Lynbrook',
			'Macedon',
			'Manhattan',
			'Marcellus',
			'North Elba',
			'North Patchogue',
			'Penn Yan',
			'Ridge',
			'Riverdale',
			'Roessleville',
			'Roslyn Heights',
			'Sands Point',
			'Sleepy Hollow',
			'South Floral Park',
			'Stewart Manor',
			'Stillwater',
			'Stone Ridge',
			'Terryville',
			'Upper Brookville',
			'Upper Nyack',
			'West Glens Falls',
			'Wyandanch',
			'Yorkshire',
			'Anna',
			'Antwerp',
			'Ashtabula',
			'Curtice',
			'Elida',
			'Fairport Harbor',
			'Fort Shawnee',
			'Gahanna',
			'Hough',
			'Lincoln Heights',
			'Medina',
			'Milan',
			'Oregon',
			'Ottawa',
			'Salem',
			'Stony Prairie',
			'Utica',
			'Valley View',
			'Versailles',
			'Wakeman',
			'Barnesboro',
			'Bedford',
			'Bellefonte',
			'Bensalem',
			'Blue Ball',
			'Blue Bell',
			'Coplay',
			'Eastlawn Gardens',
			'Easton',
			'Edgewood',
			'Elizabethtown',
			'Emerald Lakes',
			'Greensburg',
			'Harveys Lake',
			'Highland Park',
			'Lehighton',
			'Orwigsburg',
			'Palmdale',
			'Pottsville',
			'Quakertown',
			'Summit Hill',
			'Sunrise Lake',
			'Tarentum',
			'Titusville',
			'Topton',
			'West Easton',
			'Barrington',
			'Greenville',
			'Harrisville',
			'Gettysburg',
			'Sisseton',
			'Clarendon',
			'Guildhall',
			'North Hero',
			'Springfield',
			'Arcadia',
			'Bloomer',
			'Cedarburg',
			'Eau Claire',
			'Lake Wissota',
			'New Lisbon',
			'North Hudson',
			'Sheboygan Falls',
			'Somers',
			'Stratford',
			'Wauwatosa',
			'Baltic',
			'Blue Hills',
			'Fillmore',
			'Casa Grande',
			'Cienega Springs',
			'El Mirage',
			'Parker',
			'Peoria',
			'Sacaton',
			'Saint Johns',
			'Alpine',
			'Alum Rock',
			'Bakersfield',
			'Bermuda Dunes',
			'Beverly Hills',
			'Bodega Bay',
			'Boron',
			'Chino Hills',
			'Cutler',
			'Easton',
			'El Cajon',
			'Frazier Park',
			'Hesperia',
			'Irvine',
			'Jamul',
			'Joshua Tree',
			'La Selva Beach',
			'Laguna Hills',
			'Lebec',
			'Lompico',
			'Los Banos',
			'Ojai',
			'Oroville',
			'Piru',
			'San Dimas',
			'San Rafael',
			'San Ramon',
			'Santa Monica',
			'Santa Susana',
			'Strathmore',
			'Temecula',
			'Tracy',
			'West Athens',
			'Wheatland',
			'Roxborough Park',
			'Woodland Park',
			'Dodge City',
			'Carrizozo',
			'Jarales',
			'Valencia',
			'Moapa Town',
			'Hooker',
			'Borger',
			'Grape Creek',
			'Lamesa',
			'Rosita South',
			'Socorro Mission Number 1 Colonia',
			'Beaver',
			'Saint George',
			'Lea Hill',
			'Pine Hills',
			'Weed',
			"Coeur d'Alene",
			'Garden City',
			'Marsing',
			'Spirit Lake',
			'Sun Valley',
			'Wendell',
			'Billings',
			'Fort Yates',
			'Stanley',
			'Cambridge',
			'Canyonville',
			'Hines',
			'Joseph',
			'Lyons',
			'Buffalo',
			'Colonial Pine Hills',
			'Rapid City',
			'Sturgis',
			'Duchesne',
			'Sunset',
			'Camano',
			'Centralia',
			'Fords Prairie',
			'Kenmore',
			'Lake Shore',
			'Maple Valley',
			'Moses Lake',
			'Napavine',
			'Prosser',
			'Rainier',
			'Redmond',
			'Tonasket',
			'Union Gap',
			'Yakima',
			'Newcastle',
			'Kēōkea',
			'Pearl City',
			'Volcano',
			'Hāwī',
			'Ainaloa',
			'Dillingham',
			'Columbus',
			'Bayview',
			'Green Harbor-Cedar Crest',
			'Ashton-Sandy Spring',
			'Fort Leonard Wood',
			'Underwood-Petersville',
			'Arden-Arcade',
			'Highlands-Baywood Park',
			'Lake Marcel-Stillwater',
			'Roseburg North',
			'Vandenberg Air Force Base',
			'Highland Lakes',
			'Nocatee',
			'Oak Cliff Place',
			'Contra Costa Centre',
			'Silver Lakes',
			'Greenfields',
			'Hill Air Force Base',
			'St Marys',
			'Yorktown',
			'Guin',
			'Hackleburg',
			'Kinsey',
			'Lake Purdy',
			'Leesburg',
			'Mignon',
			'Moody',
			'Prichard',
			'Earle',
			'Elkins',
			'Flippin',
			'Leachville',
			'Manila',
			'Siloam Springs',
			'Southside',
			'Bridgeville',
			'Dover',
			'Bronson',
			'Doctor Phillips',
			'Eatonville',
			'Edgewood',
			'Fussels Corner',
			'Harlem',
			'Howey-in-the-Hills',
			'Kathleen',
			'Lake Butler',
			'Lake Lorraine',
			'Lake Placid',
			'Lauderdale Lakes',
			'McGregor',
			'North DeLand',
			'Palmetto Bay',
			'Palmona Park',
			'Poinciana',
			'Punta Gorda',
			'Seminole',
			'South Daytona',
			'South Miami Heights',
			'Taylor Creek',
			'University Park',
			'Villages of Oriole',
			'Winston',
			'Athens',
			'Cochran',
			'Conley',
			'Cumming',
			'Ellijay',
			'Fitzgerald',
			'Glennville',
			'Jeffersonville',
			'Ludowici',
			'Lumber City',
			'McCaysville',
			'Pembroke',
			'Perry',
			'Smyrna',
			'Tifton',
			'Carbondale',
			'Mount Vernon',
			'Olney',
			'Royalton',
			'Williamsville',
			'Beech Grove',
			'Ferdinand',
			'Georgetown',
			'Morristown',
			'New Whiteland',
			'Poseyville',
			'Chetopa',
			'Galena',
			'Kiowa',
			'Olathe',
			'Oxford',
			'Saint Marys',
			'Sedan',
			'Elkfork',
			'Hawesville',
			'Hindman',
			'Manchester',
			'Massac',
			'Brusly',
			'Lafayette',
			'Maurice',
			'Meraux',
			'Vienna Bend',
			'Chillum',
			'Croom',
			'Federalsburg',
			'Frostburg',
			'Lake Shore',
			'Marlow Heights',
			'Milford Mill',
			'Montgomery Village',
			'Point of Rocks',
			'Somerset',
			'South Bel Air',
			'Upper Marlboro',
			'Billings',
			'Bolivar',
			'Creve Coeur',
			'East Prairie',
			'Forsyth',
			'Higginsville',
			'Houston',
			'Moscow Mills',
			'Richmond',
			'Riverview',
			'Slater',
			'Steelville',
			'Belzoni',
			'Byram',
			'Carrollton',
			'Gautier',
			'Hillsboro',
			'Holly Springs',
			'Kiln',
			'Macon',
			'Ocean Springs',
			'Oxford',
			'Tylertown',
			'Bakersville',
			'Cajahs Mountain',
			'Carthage',
			'Charlotte',
			'Enochville',
			'Flat Rock',
			'Fruitland',
			'Garner',
			'Grifton',
			'Harkers Island',
			'Jackson',
			'Kitty Hawk',
			'Lake Norman of Catawba',
			'Mineral Springs',
			'Mount Airy',
			'Murraysville',
			'Nashville',
			'Robbinsville',
			'Rockingham',
			'Roseboro',
			'Rutherford College',
			'Spencer',
			'Tobaccoville',
			'Troy',
			'Valdese',
			'Audubon Park',
			'Beachwood',
			'Browns Mills',
			'Cape May Court House',
			'Olivet',
			'West Cape May',
			'Belpre',
			'Cincinnati',
			'Crooksville',
			'Crystal Lakes',
			'Franklin',
			'Grandview',
			'Groveport',
			'Loveland',
			'Northridge',
			'Wellston',
			'Yellow Springs',
			'Blanchard',
			'Glenpool',
			'Healdton',
			'Okeene',
			'Oologah',
			'Glen Rock',
			'Guilford Siding',
			'Republic',
			'Camden',
			'Gaston',
			'Hilton Head Island',
			'Hollywood',
			'Homeland Park',
			'Iva',
			'Murrells Inlet',
			'Ridgeville',
			'Rock Hill',
			'Wilkinson Heights',
			'York',
			'Chattanooga',
			'Hartsville',
			'Humboldt',
			'Norris',
			'Pigeon Forge',
			'South Cleveland',
			'Spring City',
			'Benbrook',
			'Berryville',
			'Brazoria',
			'Brushy Creek',
			'Chandler',
			'Cross Mountain',
			'Elmendorf',
			'Flatonia',
			'Fresno',
			'Georgetown',
			'Gilmer',
			'Ingram',
			'Jollyville',
			'Josephine',
			'League City',
			'Meadows Place',
			'Mesquite',
			'Morgans Point Resort',
			'Newton',
			'Paint Rock',
			'Pinewood Estates',
			'Roma-Los Saenz',
			'Salado',
			'San Diego',
			'Schulenburg',
			'Sinton',
			'Wake Village',
			'Atkins',
			'Chamberlayne',
			'Christiansburg',
			'Crimora',
			'Fort Lee',
			'Hillsville',
			'Hollymead',
			'Horse Pasture',
			'Sterling',
			'Tuckahoe',
			'Winchester',
			'Lewisburg',
			'Ronceverte',
			'White Sulphur Springs',
			'Alexandria',
			'Lena',
			'North Andover',
			'East Brooklyn',
			'Carnegie',
			'Ellington',
			'New London',
			'South Windsor',
			'Weatogue',
			'Bedford',
			'Buffalo (historical)',
			'Camanche',
			'Coon Rapids',
			'Earlham',
			'Fairfield',
			'Hartley',
			'La Porte City',
			'Marengo',
			'Saylorville',
			'Atlanta',
			'Aurora',
			'Burbank',
			'Burnham',
			'Central City',
			'Cherry Valley',
			'Farmer City',
			'Forest Lake',
			'Germantown Hills',
			'Grandwood Park',
			'Hodgkins',
			'Lake Villa',
			'Lakemoor',
			'Lisle',
			'Malta',
			'Nauvoo',
			'New City',
			'Plano',
			'Port Barrington',
			'Posen',
			'Sterling',
			'Alexandria',
			'Frankton',
			'Kouts',
			'New Carlisle',
			'North Judson',
			'South Whitley',
			'Athol',
			'Barnstable',
			'Bellingham',
			'Burlington',
			'Carver',
			'Chicopee',
			'Gill',
			'Lenox',
			'North Seekonk',
			'Northfield',
			'Reading',
			'Wayland',
			'West Springfield',
			'Williamsburg',
			'Bath',
			'Gouldsboro',
			'Milbridge',
			'Saint Albans',
			'South Portland',
			'Baldwin',
			'Big Rapids',
			'Birch Run',
			'Bronson',
			'Clare',
			'Concord',
			'Fenton',
			'Fowler',
			'Frankenmuth',
			'Grand Haven',
			'Grayling',
			'Highland Park',
			'Lowell',
			'Manton',
			'Prudenville',
			'Saranac',
			'Shelby',
			'South Monroe',
			'Stambaugh, Iron River',
			'Stanton',
			'Stony Point',
			'Temperance',
			'Caledonia',
			'Chanhassen',
			'Clara City',
			'Grand Meadow',
			'Ham Lake',
			'Hawley',
			'Mantorville',
			'Morris',
			'New Hope',
			'Otsego',
			'Plainview',
			'Redwood Falls',
			'Rice',
			'Rock Creek',
			'Cando',
			'Langdon',
			'West Fargo',
			'Elwood',
			'Madison',
			'Yutan',
			'Harrisville',
			'Hill',
			'Newton',
			'Peterborough',
			'Whitefield',
			'Bloomfield',
			'Eatontown',
			'Elizabeth',
			'Florence',
			'Frenchtown',
			'Highland Park',
			'Kingston',
			'Lakewood',
			'Lyndhurst',
			'Madison',
			'Marlboro',
			'Oceanport',
			'Ringwood',
			'Rossmoor',
			'Verona',
			'Baxter Estates',
			'Belle Harbor',
			'Bolivar',
			'Broadalbin',
			'East Glenville',
			'East Massapequa',
			'Fallsburg',
			'Great Neck',
			'Greenlawn',
			'Harbor Isle',
			'Haverstraw',
			'Hempstead',
			'Highland',
			'Kenmore',
			'Kingston',
			'Laurel',
			'Levittown',
			'Long Island City',
			'Mechanicville',
			'Menands',
			'Mill Neck',
			'Milton',
			'Mott Haven',
			'Nesconset',
			'North Babylon',
			'Pelham Manor',
			'Queens Village',
			'Ransomville',
			'Rosedale',
			'Rye',
			'Sodus',
			'Stony Point',
			'Tappan',
			'Thomaston',
			'Wantagh',
			'Watertown',
			'Woodbury',
			'Burlington',
			'Cambridge',
			'Dover',
			'Dublin',
			'Gambier',
			'Holland',
			'Millersburg',
			'Olmsted Falls',
			'Pepper Pike',
			'Ravenna',
			'Sidney',
			'Uhrichsville',
			'Weston',
			'Akron',
			'Aliquippa',
			'Almedia',
			'Ambler',
			'Baldwin',
			'Bally',
			'Big Beaver',
			'Boswell',
			'Brentwood',
			'Burnham',
			'Church Hill',
			'Connellsville',
			'Dravosburg',
			'Eddington',
			'Fairhope',
			'Fairless Hills',
			'Forest City',
			'Huntingdon',
			'Ivyland',
			'Light Street',
			'Minersville',
			'Oil City',
			'Plains',
			'Plumsteadville',
			'Tacony',
			'Tatamy',
			'Towamensing Trails',
			'West Homestead',
			'Zelienople',
			'Hartford',
			'Lake Andes',
			'Poultney',
			'Randolph',
			'Ashwaubenon',
			'Belle Plaine',
			'Brookfield',
			'Elm Grove',
			'Evansville',
			'Evergreen',
			'Independence',
			'Nashville',
			'New London',
			'Oak Creek',
			'Portage',
			'Random Lake',
			'Somerset',
			'Two Rivers',
			'Phillips',
			'Bullhead City',
			'Cactus Flat',
			'Flagstaff',
			'Quartzsite',
			'San Manuel',
			'Spring Valley',
			'Amesti',
			'Boyes Hot Springs',
			'Calexico',
			'Canyon Lake',
			'Carmel Valley Village',
			'Compton',
			'Del Monte Forest',
			'El Cerrito Corona',
			'El Verano',
			'Foothill Farms',
			'Glendora',
			'Gold River',
			'Granite Bay',
			'Imperial',
			'Isla Vista',
			'La Puente',
			'La Quinta',
			'Las Flores',
			'Lomita',
			'Menlo Park',
			'Midway City',
			'Mission Hills',
			'Monte Sereno',
			'Mountain Ranch',
			'North Edwards',
			'North El Monte',
			'North Richmond',
			'Pasatiempo',
			'Petaluma',
			'Plumas Lake',
			'Poway',
			'Rancho San Diego',
			'Rancho Santa Fe',
			'Salton City',
			'Saranap',
			'Saticoy',
			'Searles Valley',
			'Simi Valley',
			'Strawberry',
			'Tahoma',
			'Universal City',
			'Dove Creek',
			'Fowler',
			'Lakewood',
			'Lone Tree',
			'Manitou Springs',
			'Montrose',
			'Stonegate',
			'Atwood',
			'El Rancho',
			'Eunice',
			'Jal',
			'Las Vegas',
			'Caliente',
			'Winchester',
			'Hale Center',
			'Littlefield',
			'Paducah',
			'Tulia',
			'Kanab',
			'Milford',
			'Toquerville',
			'Ralls',
			'Avondale',
			'Wrangell',
			'Shingletown',
			'Johnstown',
			'Yuma',
			'Ashton',
			'Bellevue',
			'Boulder',
			'Chinook',
			'Colstrip',
			'Thompson Falls',
			'Bowbells',
			'Benkelman',
			'Gladstone',
			'Lakeview',
			'Saint Helens',
			'Salem',
			'Stayton',
			'Holladay',
			'Tooele',
			'West Mountain',
			'Ahtanum',
			'Dishman',
			'Friday Harbor',
			'Langley',
			'Liberty Lake',
			'Riverbend',
			'Tulalip',
			'Douglas',
			'Laramie',
			'Mills',
			'Sundance',
			'Leilani Estates',
			'Pepeekeo',
			'Waimea',
			'He‘eia',
			'Hōlualoa',
			'‘Āhuimanu',
			'Houston',
			'Alafaya',
			'Peppermill Village',
			'Captains Cove',
			'Middleborough Center',
			'Northwest Harwich',
			'Glens Falls North',
			'Weissport East',
			'Manitou Beach-Devils Lake',
			'Poplar-Cotton Center',
			'Makakilo',
			'San Tan Valley',
			'Tellico Village',
			'Shaw Heights',
			'Tanglewilde',
			'Heritage Lake',
			'Reynoldstown',
			'Berry',
			'Monticello',
			'Blountsville',
			'Bynum',
			'Centre',
			'Centreville',
			'Coosada',
			'Eufaula',
			'Foley',
			'Fultondale',
			'Hamilton',
			'Hazel Green',
			'Headland',
			'New Hope',
			'Pleasant Grove',
			'Point Clear',
			'Saks',
			'Sheffield',
			'Arkadelphia',
			'Camden',
			'Dierks',
			'Lake City',
			'West Helena',
			'Seaford',
			'Selbyville',
			'Beverly Hills',
			'Brookridge',
			'Charlotte Park',
			'Clearwater',
			'Crystal Springs',
			'Cudjoe Key',
			'Fairview Shores',
			'Ferry Pass',
			'Florida City',
			'Fort Meade',
			'Goulds',
			'Gretna',
			'Highland Beach',
			'Hilliard',
			'Indiantown',
			'Lake Panasoffkee',
			'Lakes by the Bay',
			'Largo',
			'Lauderdale-by-the-Sea',
			'Lynn Haven',
			'Micco',
			'Naples Park',
			'New Port Richey',
			'Palm Springs',
			'Pine Hills',
			'Samoset',
			'Sebring',
			'Sunshine Ranches',
			'West Gate',
			'Zolfo Springs',
			'Claxton',
			'Danielsville',
			'Hannahs Mill',
			'Hartwell',
			'Mableton',
			'North Atlanta',
			'Porterdale',
			'Sylvester',
			'Unadilla',
			'Granite City',
			'New Baden',
			'Odin',
			'Tuscola',
			'Avon',
			'Brooklyn',
			'Centerville',
			'Ellettsville',
			'Owensville',
			'Rushville',
			'Buhler',
			'Chanute',
			'Haven',
			'Hays',
			'Kansas City',
			'La Cygne',
			'Lincoln',
			'Lyndon',
			'Smith Center',
			'Wathena',
			'Westmoreland',
			'Buckner',
			'Buechel',
			'Burlington',
			'Fulton',
			'Hardinsburg',
			'Pleasure Ridge Park',
			'Saint Regis Park',
			'Warsaw',
			'Wilder',
			'Worthington Hills',
			'Batchelor',
			'Belle Rose',
			'Bogalusa',
			'DeQuincy',
			'Eden Isle',
			'Garyville',
			'Golden Meadow',
			'Hackberry',
			'Lacombe',
			'Marksville',
			'Marrero',
			'Monticello',
			'Napoleonville',
			'Waggaman',
			'Westminster',
			'Arbutus',
			'Baltimore',
			'Bladensburg',
			"Butcher's Hill",
			'Chester',
			'Crofton',
			'Elkridge',
			'Fallston',
			'Glenarden',
			'Overlea',
			'Potomac Heights',
			'Anderson',
			'Gerald',
			'Hillsboro',
			'Moberly',
			'Nevada',
			'Normandy',
			'Saint Joseph',
			'Saint Peters',
			'Shelbina',
			'Winchester',
			'Ellisville',
			'Lexington',
			'Water Valley',
			'Apex',
			'Broad Creek',
			'East Rockingham',
			'East Spencer',
			'Laurel Hill',
			'Long Beach',
			'Lowell',
			'Mount Holly',
			'Red Springs',
			'Rocky Mount',
			'Salem',
			'Swansboro',
			'Wendell',
			'Youngsville',
			'Island Heights',
			'Oaklyn',
			'Ocean City',
			'Surf City',
			'Arcanum',
			'Bethel',
			'Fairfax',
			'Grove City',
			'Marietta',
			'New Matamoras',
			'North Zanesville',
			'Trotwood',
			'Waverly',
			'Coweta',
			'Forest Park',
			'Holdenville',
			'Maud',
			'Maysville',
			'Moore',
			'Morris',
			'Spiro',
			'East Berlin',
			'Eddystone',
			'Lake Meade',
			'McConnellsburg',
			'Parkville',
			'State Line',
			'Upland',
			'Wharton',
			'Chester',
			'Columbia',
			'India Hook',
			'Laurens',
			'Lyman',
			'Port Royal',
			'Surfside Beach',
			'Bon Aqua Junction',
			'Dickson',
			'Falling Water',
			'Germantown',
			'Green Hill',
			'Harrogate',
			'Milan',
			'Red Boiling Springs',
			'Spurgeon',
			'Alamo',
			'Arlington',
			'Big Sandy',
			'Bulverde',
			'Carrizo Springs',
			'Cinco Ranch',
			'De Leon',
			'Decatur',
			'Elgin',
			'Fulton',
			'Garland',
			'Hubbard',
			'Hudson',
			'Inez',
			'Jonestown',
			'Keene',
			'Kenedy',
			'Kempner',
			'Lone Star',
			'Lopezville',
			'Mart',
			'Milam',
			'Piney Point Village',
			'Ranger',
			'San Antonio',
			'Shamrock',
			'Shepherd',
			'Shoreacres',
			'Silsbee',
			'Sunnyvale',
			'Watauga',
			'Wells Branch',
			'Yorktown',
			'Buchanan',
			'Chesapeake',
			'Dale City',
			'East Highland Park',
			'Fairlawn',
			'Farmville',
			'Hampden Sydney',
			'Lorton',
			'Montclair',
			'Monterey',
			'Richmond',
			'South Hill',
			'Hamlin',
			'Mineral Wells',
			'Moorefield',
			'Morgantown',
			'Pennsboro',
			'Pleasant Valley',
			'Sophia',
			'West Barnstable',
			'Hope',
			'Heritage Village',
			'Kent',
			'North Grosvenor Dale',
			'Pemberwick',
			'Quinebaug',
			'Salmon Brook',
			'Stafford Springs',
			'Washington',
			'Wilton',
			'Clear Lake',
			'Des Moines',
			'Elk Run Heights',
			'Le Claire',
			'Mason City',
			'Ogden',
			'Polk City',
			'Rock Rapids',
			'Sibley',
			'Abingdon',
			'Addison',
			'Coal City',
			'Evanston',
			'Flanagan',
			'Galva',
			'Harvey',
			'Hillside',
			'Kenilworth',
			'Lewistown',
			'Milan',
			'Morgan Park',
			'Mount Carroll',
			'Oak Forest',
			'Oregon',
			'Park City',
			'Sleepy Hollow',
			'Third Lake',
			'West Dundee',
			'West Elsdon',
			'West Lawn',
			'Westmont',
			'Akron',
			'Culver',
			'Fairmount',
			'Goshen',
			'Kentland',
			'Mishawaka',
			'Redkey',
			'Rome City',
			'Benton',
			'Hope',
			'Midway',
			'Walnut Ridge',
			'Camden',
			'Ashburnham',
			'Belchertown',
			'Beverly',
			'Bliss Corner',
			'Canton',
			'Dedham',
			'Dracut',
			'Granville',
			'Harwich',
			'Harwich Port',
			'Lowell',
			'New Bedford',
			'Northborough',
			'Northbridge',
			'South Dennis',
			'Whitman',
			'Bangor',
			'Burnham',
			'Deer Isle',
			'Dexter',
			'Dixmont',
			'Greene',
			'Hartford',
			'Lisbon',
			'Monmouth',
			'Searsmont',
			'Skowhegan',
			'Topsham',
			'Winslow',
			'Armada',
			'Bessemer',
			'Brooklyn',
			'Carleton',
			'Clio',
			'East Lansing',
			'Harbor Beach',
			'Iron Mountain',
			'Mount Pleasant',
			'Perry',
			'Pleasant Ridge',
			'Roseville',
			'Saint Johns',
			'Sturgis',
			'West Ishpeming',
			'Wolverine Lake',
			'Atwater',
			'Centerville',
			'Dilworth',
			'East Bethel',
			'Glencoe',
			'Inver Grove Heights',
			'Moorhead',
			'Mounds View',
			'Princeton',
			'Ramsey',
			'Redby',
			'Saint Louis Park',
			'Sherburn',
			'Waite Park',
			'Carrington',
			'Cavalier',
			'Jamestown',
			'Mayville',
			'Napoleon',
			'Ashland',
			'Franklin',
			'Ord',
			'Seward',
			'York',
			'Canterbury',
			'Deering',
			'Dover',
			'East Concord',
			'Holderness',
			'Pinardville',
			'Rollinsford',
			'Tuftonboro',
			'Fords',
			'Guttenberg',
			'Harrison',
			'Hawthorne',
			'Irvington',
			'Morganville',
			'Neptune City',
			'Paramus',
			'Plainfield',
			'Point Pleasant Beach',
			'Ramtown',
			'South Amboy',
			'Union City',
			'Whitehouse Station',
			'Albion',
			'Alabama',
			'Alexandria Bay',
			'Bolivar',
			'Brightwaters',
			'Caledonia',
			'Cedarhurst',
			'Chenango Bridge',
			'Cropseyville',
			'East Islip',
			'Fairview',
			'Freeport',
			'Fresh Meadows',
			'Hamburg',
			'Long Beach',
			'Malone',
			'Middletown',
			'Neponsit',
			'Newark',
			'North Gates',
			'Oakfield',
			'Oceanside',
			'Oneida',
			'Oyster Bay',
			'Parkchester',
			'Plainedge',
			'Potsdam',
			'Riverhead',
			'Salisbury',
			'Shelter Island',
			'Shokan',
			'Spring Valley',
			'Stamford',
			'Suffern',
			'Syosset',
			'Ticonderoga',
			'Tillson',
			'Uniondale',
			'Vestal',
			'Waterford',
			'West Hurley',
			'West Sand Lake',
			'West Seneca',
			'Westerleigh',
			'Youngstown',
			'Avon Lake',
			'Bethesda',
			'Boston Heights',
			'Botkins',
			'Brookfield Center',
			'Campbell',
			'Haskins',
			'Liberty Center',
			'Mount Gilead',
			'Navarre',
			'New Middletown',
			'Shaker Heights',
			'Alleghenyville',
			'Ardmore',
			'Athens',
			'Claysburg',
			'Cleona',
			'Collinsburg',
			'Conshohocken',
			'Dillsburg',
			'East Earl',
			'Enlow',
			'Gold Key Lake',
			'Greenock',
			'Laurys Station',
			'Limerick',
			'Lorane',
			'Lower Allen',
			'Mercer',
			'Middletown',
			'Mohnton',
			'Monaca',
			'Nesquehoning',
			'Northern Cambria',
			'Philipsburg',
			'Pine Grove',
			'Reynolds Heights',
			'Reynoldsville',
			'Slippery Rock',
			'State College',
			'Versailles',
			'West Mifflin',
			'Whitaker',
			'Windber',
			'Melville',
			'Harrisburg',
			'Tyndall',
			'Enosburg Falls',
			'Montpelier',
			'Newport',
			'Pawlet',
			'West Brattleboro',
			'White River Junction',
			'Bevent',
			'Dane',
			'Darlington',
			'Edgerton',
			'Hazel Green',
			'Mauston',
			'Port Edwards',
			'Shorewood Hills',
			'Union Grove',
			'Watertown',
			'Whitefish Bay',
			'Bethlehem',
			'Mora',
			'Cave Creek',
			'Coolidge',
			'East Sahuarita',
			'Grand Canyon Village',
			'Marana',
			'Prescott',
			'Rio Rico',
			'Alpaugh',
			'Belvedere',
			'Camp Meeker',
			'Castaic',
			'Coarsegold',
			'Colton',
			'Coto De Caza',
			'Crest',
			'Crockett',
			'Cupertino',
			'Dana Point',
			'Day Valley',
			'El Rio',
			'Firebaugh',
			'Foothill Ranch',
			'Georgetown',
			'Gustine',
			'Hidden Hills',
			'Laguna',
			'Lake Los Angeles',
			'Marysville',
			'Merced',
			'Millbrae',
			'Newman',
			'North Hollywood',
			'Pinole',
			'San Mateo',
			'Seaside',
			'Shandon',
			'Sutter',
			'Tamalpais Valley',
			'Tiburon',
			'Toro Canyon',
			'Ukiah',
			'Valencia',
			'Valinda',
			'Vista',
			'Westwood',
			'Winter Gardens',
			'Wrightwood',
			'Yorba Linda',
			'Cimarron Hills',
			'Coal Creek',
			'Columbine Valley',
			'Georgetown',
			'Ken Caryl',
			'Ouray',
			'Holcomb',
			'Oakley',
			'Belen',
			'Black Rock',
			'Boles Acres',
			'La Luz',
			'Raton',
			'Waterflow',
			'Goldfield',
			'Mogul',
			'Boise City',
			'Brownfield',
			'Childress',
			'Clint',
			'Dumas',
			'Marfa',
			'Seagraves',
			'Seminole',
			'Enterprise',
			'Junction',
			'Richfield',
			'Sheridan',
			'Beaver Dam',
			'Evans',
			'Julesburg',
			'Chubbuck',
			'Meridian',
			'Orofino',
			'Plummer',
			'Saint Anthony',
			'Lame Deer',
			'Stanford',
			'Winnett',
			'Jackpot',
			'Banks',
			'Corvallis',
			'Independence',
			'Oak Grove',
			'Troutdale',
			'Wood Village',
			'Box Elder',
			'Fruit Heights',
			'Honeyville',
			'Lindon',
			'Sandy',
			'Algona',
			'Belfair',
			'Bryant',
			'Cle Elum',
			'East Wenatchee Bench',
			'Felida',
			'Hockinson',
			'Kelso',
			'Medina',
			'Sisco Heights',
			'Sumner',
			'Sunnyside',
			'Sunnyslope',
			'Lovell',
			'Kapa‘a',
			'Princeville',
			'Honalo',
			'St Johnsbury',
			'Desert Edge',
			'Madison Center',
			'Ocean Bluff-Brant Rock',
			'Fort Lee',
			'South Park Township',
			'Whiteman Air Force Base',
			'Meridian Station',
			'Bangor Trident Base',
			'Lucas Valley-Marinwood',
			'Malmstrom Air Force Base',
			'Idyllwild-Pine Cove',
			'Larkfield-Wikiup',
			'West Side Highway',
			'Sierra View',
			'Siesta Acres',
			// 'Hobart',
			'Lake Holiday',
			'West Warrenton',
			'Johnston',
			'Hot Springs National Park',
			'Cordova',
			'Brundidge',
			'Chalkville',
			'Dothan',
			'Grand Bay',
			'Heflin',
			'Helena',
			'Margaret',
			'Meridianville',
			'Midfield',
			'Ozark',
			'Summerdale',
			'Bull Shoals',
			'Gravel Ridge',
			'Holiday Island',
			'Lake Village',
			'Luxora',
			'McCrory',
			'Pocahontas',
			'Springdale',
			'Tuckerman',
			'White Hall',
			'Adams Morgan',
			'Milton',
			'Altamonte Springs',
			'Citrus Ridge',
			'Cleveland',
			'Daytona Beach',
			'Eagle Lake',
			'Florida Ridge',
			'Hawthorne',
			'Hudson',
			'Immokalee',
			'Islamorada',
			'Lake Alfred',
			'Lake Helen',
			'Leesburg',
			'Loughman',
			'Middleburg',
			'Molino',
			'North Key Largo',
			'North Miami Beach',
			'Palm Valley',
			'Plantation',
			'Port Charlotte',
			'Redington Beach',
			'Ruskin',
			'West Little River',
			'West Palm Beach',
			'Adel',
			'Brooklet',
			'Broxton',
			'Dahlonega',
			'Donalsonville',
			'Druid Hills',
			'Eastman',
			'Hinesville',
			'Jasper',
			'Nelson',
			'Oglethorpe',
			'Statenville',
			'Stone Mountain',
			'Toccoa',
			'Bethalto',
			'Divernon',
			'South Jacksonville',
			'South Roxana',
			'Broad Ripple',
			'Cannelton',
			'Madison',
			'Coffeyville',
			'Leawood',
			'Manhattan',
			'Valley Falls',
			'Bedford',
			'Dry Ridge',
			'Glasgow',
			'Inez',
			'Ledbetter',
			'Morganfield',
			'Watterson Park',
			'Cottonport',
			'Delcambre',
			'Farmerville',
			'French Settlement',
			'Hammond',
			'Harahan',
			'Lake Arthur',
			'Lockport',
			'Metairie',
			'Montegut',
			'Terrytown',
			'Urania',
			'Vivian',
			'Walker',
			'West Ferriday',
			'Bowie',
			'College Park',
			'Columbia',
			'Edgewater',
			'Edgewood',
			'Eldersburg',
			'Fulton',
			'Garrison',
			'Glen Burnie',
			'Greensboro',
			'Hampstead',
			'Landover',
			'Landover Hills',
			'Langley Park',
			'Linthicum',
			'West Elkridge',
			'Ballwin',
			'Cuba',
			'Eminence',
			'Imperial',
			'Lamar',
			'Oregon',
			'Pagedale',
			'Terre du Lac',
			'Batesville',
			'Bay Springs',
			'Belmont',
			'Fayette',
			'Hurley',
			'Senatobia',
			'Andrews',
			'Angier',
			'Boone',
			'Burnsville',
			'Denton',
			'Fairfield Harbour',
			'Gamewell',
			'Halifax',
			'James City',
			'Kannapolis',
			'Lake Junaluska',
			'Longview',
			'Moravian Falls',
			'Mountain Home',
			'Pine Knoll Shores',
			'Scotland Neck',
			'Sneads Ferry',
			'Snow Hill',
			'Audubon',
			'Bridgeton',
			'Lindenwold',
			'Manahawkin',
			'Pemberton',
			'Pine Hill',
			'Runnemede',
			'Sewell',
			'Wildwood Crest',
			'Burlington',
			'Camden',
			'Eaton',
			'Greenhills',
			'Hillsboro',
			'Jackson',
			'Mariemont',
			'Miamitown',
			'Peebles',
			'Salem Heights',
			'Sciotodale',
			'Whitehall',
			'Fort Gibson',
			'Langston',
			'Mangum',
			'Quinton',
			'Tahlequah',
			'Turley',
			'Vian',
			'Pennsport',
			'Scotland',
			'South Coatesville',
			'South Uniontown',
			'Trainer',
			'Valley View',
			'Cherryvale',
			'Ninety Six',
			'Pageland',
			'Saluda',
			'Charlotte',
			'Dover',
			'East Cleveland',
			'Jefferson City',
			'McMinnville',
			'Nashville',
			'New Johnsonville',
			'Rockwood',
			'Whiteville',
			'Alamo Heights',
			// 'Paris',
			'Springfield',
			'Barton Creek',
			'Benavides',
			'Caldwell',
			'Castroville',
			'Coldspring',
			'Combes',
			'Diboll',
			'Dripping Springs',
			'Electra',
			'Goliad',
			'Groesbeck',
			'Highland Park',
			'Indian Hills',
			'Katy',
			'Kemp',
			'Kilgore',
			'Laguna Heights',
			'Lewisville',
			'Llano',
			'Mabank',
			'Nevada',
			'Northlake',
			'Palacios',
			'Pittsburg',
			'Plum Grove',
			'Roanoke',
			'Runaway Bay',
			'South Padre Island',
			'Three Rivers',
			'West Orange',
			'Whitehouse',
			'Windcrest',
			'Concord',
			'Fairfax',
			'Galax',
			'Gloucester Point',
			'Hayfield',
			'Herndon',
			'Jolivue',
			'Lawrenceville',
			'Mantua',
			'Narrows',
			'Stanley',
			'Staunton',
			'Stephens City',
			'Tazewell',
			'West Point',
			'Belle',
			'Franklin',
			'Princeton',
			'Summersville',
			'Westover',
			'Linn',
			'Andalusia',
			'Attalla',
			'Wayland',
			'Long Hill',
			'Akron',
			'Avoca',
			'Cedar Rapids',
			'Holstein',
			'Independence',
			'Le Mars',
			'Lone Tree',
			'Missouri Valley',
			'Monroe',
			'Prairie City',
			'Sheffield',
			'Slater',
			'Traer',
			'Woodbine',
			'Bellwood',
			'Bloomingdale',
			'Des Plaines',
			'Dixmoor',
			'Dwight',
			'Elwood',
			'Frankfort Square',
			'Hamilton',
			'Hanover Park',
			'Hickory Hills',
			'Ladd',
			'Lake Catherine',
			'Lincoln Park',
			'Marquette Heights',
			'North Aurora',
			'North Lawndale',
			'Oquawka',
			'Peru',
			'Pistakee Highlands',
			'Rockford',
			'Rolling Meadows',
			'Sheldon',
			'Stockton',
			'Woodridge',
			'Crawfordsville',
			'Delphi',
			'Grabill',
			'Harlan',
			'Lafayette',
			'Lagrange',
			'Portage',
			'Charlton',
			'Chelsea',
			'Grafton',
			'Jamaica Plain',
			'Marblehead',
			'Phillipston',
			'Shrewsbury',
			'South Amherst',
			'Taunton',
			'Weweantic',
			'Woburn',
			'Damariscotta',
			'East Millinocket',
			'Harrison',
			'Hermon',
			'Mount Vernon',
			'Newport',
			'Owls Head',
			'Presque Isle',
			'Readfield',
			'Rockport',
			'Avoca',
			'Bad Axe',
			'Belding',
			'Berkley',
			'Brownlee Park',
			'Dexter',
			'Farmington Hills',
			'Fowlerville',
			'Galesburg',
			'Houghton Lake',
			'Ionia',
			'Keego Harbor',
			'Lake Orion',
			'Lambertville',
			'Newberry',
			'Parchment',
			'Pigeon',
			'Rochester',
			'South Lyon',
			'Vandercook Lake',
			'Brainerd',
			'Carlton',
			'Ely',
			'Eveleth',
			'Hastings',
			'Hibbing',
			'Lonsdale',
			'Proctor',
			'Rogers',
			'Saint Charles',
			'Fessenden',
			'Hickman',
			'Plainview',
			'Claremont',
			'Manchester',
			'Wilton',
			'Bloomingdale',
			'Hanover',
			'Harrington Park',
			'Lake Mohawk',
			'Lambertville',
			'New Milford',
			'Phillipsburg',
			'Rochelle Park',
			'Saddle Brook',
			'Scotch Plains',
			'Spring Lake',
			'Angola on the Lake',
			'Barnum Island',
			'Bellaire',
			'Brownsville',
			'City Island',
			'Clarence',
			'Cooperstown',
			'Dover Plains',
			'Dunkirk',
			'East Moriches',
			'East Setauket',
			'Eastport',
			'Elbridge',
			'Floral Park',
			'Gasport',
			'Goldens Bridge',
			'Great Neck Plaza',
			'Hillcrest',
			'Jamesport',
			'Kings Point',
			'Lake Ronkonkoma',
			'Le Roy',
			'Lockport',
			// 'Naples',
			'North New Hyde Park',
			'Old Bethpage',
			'Old Brookville',
			'Purchase',
			'Rock Hill',
			'South Ozone Park',
			'Valatie',
			'Valley Cottage',
			'Victor',
			'Walden',
			'Whitestone',
			'Brook Park',
			'Brooklyn Heights',
			'Canal Fulton',
			'Lakewood',
			'Lordstown',
			'Mantua',
			'Munroe Falls',
			'North Randall',
			'Oak Harbor',
			'Oak Hill',
			'Ottawa Hills',
			'Payne',
			'Port Clinton',
			'Powell',
			'Shreve',
			'University Heights',
			'Black Lick',
			'Boyertown',
			'Brodheadsville',
			'Brownsville',
			'Carlisle',
			'Centerville',
			'Chester Springs',
			'Conyngham',
			'Corry',
			'Cresson',
			'Economy',
			'Elizabethville',
			'Evansburg',
			'Factoryville',
			'Garden View',
			'Hellertown',
			'Hermitage',
			'Horsham',
			'Hughestown',
			'Hummels Wharf',
			'Imperial',
			'Kittanning',
			'Langhorne Manor',
			'Luzerne',
			'Manchester',
			'Mars',
			'Montrose',
			'Montrose',
			'Moosic',
			'North East',
			'Palmerton',
			'Penn Hills',
			'Port Allegany',
			'Pocono Ranch Lands',
			'Rennerdale',
			'Renovo',
			'Ridgway',
			'Royalton',
			'Sayre',
			'Sharpsville',
			'Shenandoah Heights',
			'Souderton',
			'Tamaqua',
			'Towanda',
			'Trexlertown',
			'Wayne',
			'West Mayfield',
			'Wilson',
			'Wilson',
			'Woodland Heights',
			'Wyomissing',
			'Ashaway',
			'Bristol',
			'Lincoln',
			'Bohners Lake',
			'Brooklyn',
			'Dodgeville',
			'Glenmore',
			'Grantsburg',
			'Hammond',
			'Hayward',
			'Kaukauna',
			'Paddock Lake',
			'Platteville',
			'Prescott',
			'Roxbury',
			'Sauk City',
			'Schofield',
			'Sharon',
			'Viroqua',
			'West Milwaukee',
			'West Kennebunk',
			'Bisbee',
			'Bylas',
			'Claypool',
			'First Mesa',
			'Litchfield Park',
			'Lukachukai',
			'Willow Valley',
			'Acton',
			'Alondra Park',
			'August',
			'Avocado Heights',
			'Berry Creek',
			'Brooktrails',
			'Carmichael',
			'Clovis',
			'Downey',
			'Fairview',
			'La Mirada',
			'Lompoc',
			'Monterey',
			'Murrieta',
			'Oxnard',
			'Romoland',
			'Roseville',
			'San Anselmo',
			'San Marino',
			'San Martin',
			'Santa Fe Springs',
			'Shafter',
			'Sky Valley',
			'Solana Beach',
			'Sonora',
			'Sunnyvale',
			'Thousand Palms',
			'Turlock',
			'Tustin',
			'Basalt',
			'Centennial',
			'Colorado City',
			'Columbine',
			'Federal Heights',
			'Fort Carson',
			'Meridian',
			'Northglenn',
			'Redlands',
			'Strasburg',
			'Trinidad',
			'Johnson',
			'Oberlin',
			'Alamo',
			'Las Cruces',
			'Shiprock',
			'Silver City',
			'Taos Pueblo',
			'Smith',
			'Las Quintas Fronterizas',
			'Lockney',
			'Pampa',
			'Petersburg',
			'Presidio',
			'Rosita North',
			'Silverton',
			'Sundown',
			'Tornillo',
			'Vinton',
			'Helper',
			'Manti',
			'Washington',
			'Beach',
			'Metlakatla',
			'Crescent City',
			'Redway',
			'Ammon',
			'Nampa',
			'New Plymouth',
			// 'Paris',
			'Pocatello',
			'Anaconda',
			'Hamilton',
			'Orchard Homes',
			'Terry',
			'Wibaux',
			'Belfield',
			'Athena',
			'Beaverton',
			'Clatskanie',
			'Dundee',
			'Fairview',
			'Harbor',
			'Lebanon',
			'Silverton',
			'Stafford',
			'Stanfield',
			'Mapleton',
			'Roosevelt',
			'South Jordan Heights',
			'Boulevard Park',
			'Cheney',
			'Esperance',
			'Kent',
			'Suquamish',
			'Lusk',
			'Moorcroft',
			'Wright',
			'Fruitdale',
			'Pukalani',
			'Girdwood',
			'Saddlebrooke',
			'Marion Center',
			'Northwest Ithaca',
			'Whittingham',
			'Fort Riley North',
			'Erlands Point-Kitsap Lake',
			'LeChee',
			'Lemoore Station',
			'Otis Orchards-East Farms',
			'Security-Widefield',
			'Farm Loop',
			'Salcha',
			'Red Corral',
			'Old Jamestown',
			'Bunk Foss',
			'Crocker',
			'Teviston',
			'University Park',
			'South Kingstown',
			'Pantops',
			'Echo Park',
			'Butler',
			'Chatom',
			'Clay',
			'Danville',
			'Eutaw',
			'Gulf Shores',
			'Harvest',
			'Holtville',
			'Marbury',
			'Montevallo',
			'Montgomery',
			'Northport',
			'Priceville',
			'Sardis City',
			'Ash Flat',
			'Fordyce',
			'Glenwood',
			'Goshen',
			'Marvell',
			'Prairie Creek',
			'Broward Estates',
			'Burnt Store Marina',
			'Charlotte Harbor',
			'Cypress Lake',
			'Gotha',
			'Goulding',
			'Groveland',
			'Holly Hill',
			'Kensington Park',
			'Laurel',
			'Loxahatchee Groves',
			'Mangonia Park',
			'Marco Island',
			'Mount Plymouth',
			'Oak Ridge',
			'Pierson',
			'Plantation',
			'Sunset',
			'Elberton',
			'Fairview',
			'Jackson',
			'Jefferson',
			'Jonesboro',
			'Lake City',
			'Lawrenceville',
			'Lumpkin',
			'McRae',
			'Ocilla',
			'Pine Mountain',
			'Port Wentworth',
			'Reidsville',
			'Union City',
			'Albion',
			'Aviston',
			'Carlyle',
			'Effingham',
			'Mount Carmel',
			'Neoga',
			'Nokomis',
			'White Hall',
			'McCordsville',
			'Fredonia',
			'Medicine Lodge',
			'Pratt',
			'Spring Hill',
			'Troy',
			'Clay City',
			'Florence',
			'Indian Hills',
			'Lexington',
			'McKee',
			'Morgantown',
			'New Castle',
			'Orchard Grass Hills',
			'West Buechel',
			'Wickliffe',
			'Winchester',
			'Worthington',
			'Grand Point',
			'Iota',
			'Laplace',
			'Lecompte',
			'Monroe',
			'New Roads',
			'Roseland',
			'Slidell',
			'Sulphur',
			'Beltsville',
			'Brooklyn Park',
			'Clarksburg',
			'Deale',
			'Fort Washington',
			'Laurel',
			'Mellwood',
			'Saint Charles',
			'Sykesville',
			'Waldorf',
			'Charleston',
			'Kirkwood',
			"Lee's Summit",
			'Monett',
			'New Haven',
			'Owensville',
			'Portageville',
			'Raytown',
			'Saint Paul',
			'Decatur',
			'Diamondhead',
			'Farmington',
			'Marks',
			'McComb',
			'Richland',
			'Creedmoor',
			'Cricket',
			'Hampstead',
			'Hays',
			'Laurinburg',
			'Manteo',
			'Marvin',
			'Matthews',
			'Monroe',
			'Siler City',
			'Sunset Beach',
			'Warsaw',
			'Washington',
			'Clementon',
			'Egg Harbor City',
			'Golden Triangle',
			'Mount Laurel',
			'Seaside Heights',
			'South Vineland',
			'Woodbury',
			'Englewood',
			'Fort McKinley',
			'Pleasant Grove',
			'Skyline Acres',
			'Summerside',
			'Terrace Park',
			'White Oak',
			'Anadarko',
			'Bartlesville',
			'Byng',
			'Catoosa',
			'Copeland',
			'Cushing',
			'Cyril',
			'Elgin',
			'Miami',
			'Waurika',
			'Woodward',
			'Broomall',
			'Colwyn',
			'Batesburg',
			'Chapin',
			'Inman',
			'Johnsonville',
			'Lexington',
			'Sans Souci',
			'Cross Plains',
			'Ellendale',
			'Englewood',
			'Etowah',
			'Gordonsville',
			'Greeneville',
			'Hickory Withe',
			'Hohenwald',
			'Huntsville',
			'Lakeland',
			'Memphis',
			'Tusculum',
			'Westmoreland',
			'Abram',
			'Albany',
			'Cisco',
			'Cloverleaf',
			'Coppell',
			'Doffing',
			'Eastland',
			'El Cenizo',
			'Florence',
			'Fort Worth',
			'Godley',
			'Greenville',
			'Holiday Lakes',
			'La Homa',
			'Laguna Park',
			'Las Lomas',
			'Lexington',
			'Magnolia',
			'Moody',
			'Newark',
			'Orange',
			'Rio Grande City',
			'Rosebud',
			'San Augustine',
			'The Colony',
			'Adwolf',
			'Annandale',
			'Brookneal',
			'Bull Run',
			'Cedar Bluff',
			'Claypool Hill',
			'Exmore',
			'Gate City',
			'Kilmarnock',
			'Lincolnia',
			'Oakton',
			'Alum Creek',
			'Bluefield',
			'Brush Fork',
			'Elkview',
			'Pea Ridge',
			'Rand',
			'Union',
			'Welch',
			'Abbeville',
			'Albertville',
			'East Hartford',
			'Litchfield',
			'Oxford',
			'Shelton',
			'Somers',
			'Wolcott',
			'Ames',
			'Bellevue',
			'Boone',
			'Central City',
			'Mediapolis',
			'Melcher-Dallas',
			'Norwalk',
			'Parkersburg',
			'Rockwell City',
			'Sioux Center',
			'Vinton',
			'West Liberty',
			'Amboy',
			'Berwyn',
			'Cary',
			'Diamond',
			'Englewood',
			'Gurnee',
			'Henry',
			'Inverness',
			'Kewanee',
			'Lily Lake',
			'Lockport',
			'Midlothian',
			'Mokena',
			'Newark',
			'Richton Park',
			'Winthrop Harbor',
			'Fowler',
			'Highland',
			'LaPorte',
			'Lynn',
			'Monticello',
			'Pierceton',
			'Roanoke',
			'Rossville',
			'Saint John',
			'Attleboro',
			'Bedford',
			'Bolton',
			'Cochituate',
			'Conway',
			'Essex',
			'Fairhaven',
			'Gardner',
			'Harvard',
			'Holliston',
			'Middleborough',
			'Millbury',
			'Millis',
			'Millville',
			'North Eastham',
			'Sandwich',
			'Tewksbury',
			'West Bridgewater',
			'Fort Kent',
			'Kittery Point',
			'Livermore',
			'Machias',
			'Oakland',
			'Orrington',
			'Vassalboro',
			'Atlantic Mine',
			'Belleville',
			'Bloomfield Hills',
			'DeWitt',
			'Eaton Rapids',
			'Franklin',
			'Harvey',
			'Hudsonville',
			'Kent City',
			'Kingsley',
			'Menominee',
			'Northville',
			'Pontiac',
			'Rockford',
			'Saint Helen',
			'Shelby',
			'South Gull Lake',
			'Three Oaks',
			'Troy',
			'Utica',
			'Watervliet',
			'Aurora',
			'Detroit Lakes',
			'Grant',
			'Hugo',
			'Maple Lake',
			'Marshall',
			'Robbinsdale',
			'Staples',
			'Taylors Falls',
			'Vineland',
			'Waconia',
			'Warren',
			'Wheaton',
			'Bellevue',
			'Bennington',
			'Hebron',
			'Louisville',
			'Springview',
			'Francestown',
			'Greenland',
			'Greenville',
			'Hampton Falls',
			'Lempster',
			'Plaistow',
			'Plymouth',
			'Sandwich',
			'Strafford',
			'Budd Lake',
			'Closter',
			'Crandon Lakes',
			'Jamesburg',
			'Lodi',
			'Netcong',
			'Raritan',
			'Ridgewood',
			'South Bound Brook',
			'South Plainfield',
			'Baychester',
			'Bayville',
			'Brewster',
			'Brownville',
			'Buchanan',
			'Cambria Heights',
			'Centerport',
			'Crown Point',
			'Cumberland Head',
			'Delevan',
			'East Norwich',
			'Granville',
			'Hadley',
			'Hampton Bays',
			'Harriman',
			'Hastings-on-Hudson',
			'Heritage Hills',
			'Hilton',
			'Jackson Heights',
			'Kinderhook',
			'Lackawanna',
			'Lake Carmel',
			'Middleport',
			'Mountain Lodge Park',
			'New Hyde Park',
			'New Paltz',
			'Niskayuna',
			'North Bellmore',
			'North Merrick',
			'Oakwood',
			'Pearl River',
			'Schuylerville',
			'Skaneateles',
			'South Hill',
			'Spuyten Duyvil',
			'Utica',
			'Volney',
			'Westvale',
			'Yorkville',
			'Archbold',
			'Ashland',
			'Avon',
			'Beach City',
			'Centerburg',
			'Collinwood',
			'Cuyahoga Falls',
			'Delaware',
			'East Canton',
			'Johnstown',
			'Moreland Hills',
			'New Albany',
			'New California',
			'North Madison',
			'Northwood',
			'Toledo',
			'Beaverdale',
			'Bedminster',
			'Bell Acres',
			'Bridgeport',
			'Castanea',
			'DuBois',
			'Elysburg',
			'Glenside',
			'Jenkintown',
			'Lafayette Hill',
			'Laflin',
			'Lionville',
			'Mayfield',
			'Milford',
			'Millvale',
			'Milroy',
			'Mount Joy',
			'North Belle Vernon',
			'North Braddock',
			'Olyphant',
			'Penbrook',
			'Salunga',
			'Somerset',
			'Sun Valley',
			'Susquehanna',
			'Tannersville',
			'Throop',
			'Tyrone',
			'Waterford',
			'Willow Grove',
			'Jamestown',
			'Newport',
			'North Providence',
			'Aberdeen',
			'Plankinton',
			'Vermillion',
			'Yankton',
			'Johnson',
			'Montgomery',
			'Woodstock',
			'Baraboo',
			'Campbellsport',
			'Concord',
			'Fredonia',
			'Hillsboro',
			'Johnson Creek',
			'Kenosha',
			'Milwaukee',
			'Pewaukee',
			'Prairie du Chien',
			'Shell Lake',
			'Silver Lake',
			'Slinger',
			'Stanley',
			'Westby',
			'Windsor',
			'Cool',
			'Eagar',
			'Houck',
			'Lake Havasu City',
			'Mohave Valley',
			'Pima',
			'Pine',
			'South Tucson',
			'Window Rock',
			'Alta Sierra',
			'Armona',
			'Atwater',
			'Chinatown',
			'Chualar',
			'Encinitas',
			'Fair Oaks',
			'Fairbanks Ranch',
			'Fremont',
			'Indian Wells',
			'Jamestown',
			'Koreatown',
			'La Cañada Flintridge',
			'La Riviera',
			'Ladera',
			'Las Lomas',
			'Lincoln',
			'Loma Linda',
			'Lucerne',
			'Mentone',
			'Mesa Verde',
			'Modesto',
			'Mojave',
			'Needles',
			'Occidental',
			'Palm Desert',
			'Palos Verdes Estates',
			'Pedley',
			'Portola Hills',
			'Ripon',
			'San Juan Bautista',
			'Selma',
			'Trabuco Canyon',
			'Vallejo',
			'Waldon',
			'Aurora',
			'Del Norte',
			'Englewood',
			'Glenwood Springs',
			'Littleton',
			'Leoti',
			'Capitan',
			'Chama',
			'La Union',
			'South Valley',
			'Texico',
			'University Park',
			'Eureka',
			'Fallon',
			'Agua Dulce',
			'Andrews',
			'Aspermont',
			'Miami',
			'Sterling City',
			'Van Horn',
			'Hurricane',
			'Bayside',
			'Burney',
			'Chester',
			'Johnstonville',
			'Craig',
			'Erie',
			'Hudson',
			'Mead',
			'Severance',
			'Arco',
			'Filer',
			'Hailey',
			'Hansen',
			'Harlowton',
			'Lolo',
			'Velva',
			'Chadron',
			'North Platte',
			'Amity',
			'Brookings',
			'Cascade Locks',
			'Central Point',
			'Damascus',
			'King City',
			'Lincoln City',
			'Scappoose',
			'Sherwood',
			'Benson',
			'Bountiful',
			'Elk Ridge',
			'Blaine',
			'Colfax',
			'Darrington',
			'Freeland',
			'Grandview',
			// 'Hobart',
			'Port Orchard',
			'Raymond',
			'Wenatchee',
			'Pine Bluffs',
			'Kahului',
			'Wailua',
			'‘Ewa Villages',
			'Hawaiian Paradise Park',
			'Dutch Harbor',
			'Stevenson Ranch',
			'Peaceful Valley',
			'Oxoboxo River',
			'Braintree',
			'Carnot-Moon',
			'Fort Campbell North',
			'Fort Polk South',
			'Four Corners',
			'Zephyrhills West',
			'Cannon Air Force Base',
			'Helena Valley West Central',
			'Horizon West',
			'Key Vista',
			'Queenland',
			'Summit View',
			'Lake Camelot',
			'Little Rock Air Force Base',
			'Washington Street Courthouse Annex',
			'Cottonwood',
			'Cullman',
			'Daleville',
			'East Florence',
			'Falkville',
			'Inverness',
			'Muscle Shoals',
			'Bald Knob',
			'Clarksville',
			'Dermott',
			'Eureka Springs',
			'Gravette',
			'Greenland',
			'Lincoln',
			'Lowell',
			'McGehee',
			'Monticello',
			'North Little Rock',
			'Pottsville',
			'Prairie Grove',
			'Waldron',
			'Wrightsville',
			'Washington',
			'Cheswold',
			'Bellview',
			'Brooksville',
			'Brownsville',
			'Buckingham',
			'Bushnell',
			'Coral Gables',
			'De Leon Springs',
			'Deerfield Beach',
			'Ellenton',
			'Fort Walton Beach',
			'Fruit Cove',
			'Homestead',
			'Mango',
			'Palm Coast',
			'River Park',
			'Saint Cloud',
			'Siesta Key',
			'The Crossings',
			'Aragon',
			'Augusta',
			'Byron',
			'Colquitt',
			'Dawsonville',
			'Evans',
			'Indian Springs',
			'Macon',
			'Omega',
			'Rossville',
			'Savannah',
			'Statesboro',
			'Sugar Hill',
			'Temple',
			'Vidalia',
			'Watkinsville',
			'Whitemarsh Island',
			'Woodstock',
			'Alorton',
			'Centreville',
			'Chrisman',
			'Christopher',
			'Hillsboro',
			'Maryville',
			'Morrisonville',
			'Payson',
			'Sherman',
			'Villa Grove',
			'Windsor',
			'Austin',
			'Cambridge City',
			'Columbus',
			'Country Squire Lakes',
			'Knightstown',
			'Linton',
			'Mooresville',
			'Mount Vernon',
			'Newport',
			'Seymour',
			'Clearwater',
			'Maize',
			'Burkesville',
			'Eminence',
			'Fort Wright',
			'Graymoor-Devondale',
			'Henderson',
			'Indian Hills Cherokee Section',
			'La Center',
			'Lexington-Fayette',
			'Meads',
			'Middletown',
			'Oak Grove',
			'Prestonsburg',
			'Salyersville',
			'Shively',
			'Broussard',
			'Grambling',
			'Lawtell',
			'Livonia',
			'Montegut',
			'Morgan City',
			'Plaquemine',
			'Port Allen',
			'Rayville',
			'Saint Joseph',
			'Stonewall',
			'Watson',
			'Brentwood',
			'Friendship Village',
			'Queen Anne',
			'Lanham',
			'Lexington Park',
			'Mountain Lake Park',
			'New Carrollton',
			'Perry Hall',
			'Poolesville',
			'Princess Anne',
			'Reisterstown',
			'Timonium',
			'Williamsport',
			'Adrian',
			'Affton',
			'Berkeley',
			'Bourbon',
			'Byrnes Mill',
			'Chillicothe',
			'Elsberry',
			'Florissant',
			'Gideon',
			'Hannibal',
			'Hartville',
			'Macon',
			'Marceline',
			'Pevely',
			'Purdy',
			'Saint Clair',
			'Stover',
			'Tipton',
			'Weatherby Lake',
			'Wright City',
			'Conehatta',
			'Crystal Springs',
			'Gulfport',
			'Magee',
			'Nicholson',
			'Rawls Springs',
			'Sardis',
			'Taylorsville',
			'Yazoo City',
			'Bayboro',
			'Bayshore',
			'Beaufort',
			'Bladenboro',
			'Burgaw',
			'Edneyville',
			'Glen Raven',
			'Greensboro',
			'Harrisburg',
			'Lake Lure',
			'Locust',
			'Maury',
			'Morrisville',
			'Pine Level',
			'Ramseur',
			'Rural Hall',
			'Saint Pauls',
			'Spruce Pine',
			'Taylorsville',
			'Windsor',
			'Cape May',
			'Pennsville',
			'Seaside Park',
			'Vincentown',
			'Five Points',
			'Lancaster',
			'Newport',
			'Frederick',
			'Guthrie',
			'Haskell',
			'Hinton',
			'Jay',
			'Longtown',
			'Madill',
			'Rush Springs',
			'Stigler',
			'Tishomingo',
			'Lake Heritage',
			'Mont Alto',
			'Mount Pleasant',
			'Swarthmore',
			'Willow Street',
			'Burnettown',
			'Garden City',
			'Hilton Head',
			'Holly Hill',
			'Lake City',
			'Saint Stephen',
			'Church Hill',
			'Cowan',
			'Lakewood',
			'McKenzie',
			'Midway',
			'Pulaski',
			'Rural Hill',
			'Tracy City',
			'Troy',
			'Unicoi',
			'Abilene',
			'Addison',
			'Arcola',
			'Bayou Vista',
			'Beaumont',
			'Brownsville',
			'Buffalo',
			'Bunker Hill Village',
			'Channelview',
			'Cleburne',
			'Clyde',
			'Comanche',
			'Franklin',
			'Howe',
			'Jourdanton',
			'La Coste',
			'La Villa',
			'Laguna Vista',
			'Leakey',
			'Olmos Park',
			'Plano',
			'Richwood',
			'Rockdale',
			'Rosenberg',
			'Selma',
			'Uvalde Estates',
			'Waxahachie',
			'Weatherford',
			'Whitewright',
			'Chantilly',
			'Clintwood',
			'Dunn Loring',
			'Edinburg',
			'Greenbriar',
			'Honaker',
			'Huntington',
			'Lyndhurst',
			'Madison',
			'Marion',
			'Nellysford',
			'Oak Grove',
			'Rocky Mount',
			'Ruckersville',
			'Shawsville',
			'Spotsylvania Courthouse',
			'Elkins',
			'Granville',
			'Logan',
			'Marmet',
			'Pineville',
			'Oak Valley',
			'Deer Park',
			'Coventry Lake',
			'Old Mystic',
			'Thomaston',
			'Carlisle',
			'Cascade',
			'Ely',
			'Greene',
			'Guttenberg',
			'Manson',
			'Marcus',
			'Panora',
			'Rockwell',
			'Sergeant Bluff',
			'Winfield',
			'Astoria',
			'Braidwood',
			'Carbon Cliff',
			'Chicago',
			'Chicago Heights',
			'Dolton',
			'Farmington',
			'Fulton',
			'Gifford',
			'Glencoe',
			'Joliet',
			'Lyons',
			'Monticello',
			'North Barrington',
			'Pekin',
			'Richmond',
			'Schaumburg',
			'Waukegan',
			'Wayne',
			'Anderson',
			'DeMotte',
			'Russiaville',
			'Valparaiso',
			'Waterloo',
			'Westfield',
			'West Boylston',
			'West Brookfield',
			'Becket',
			'Brimfield',
			'Freetown',
			'Halifax',
			'Uxbridge',
			'Waltham',
			'Brownville',
			'Corinna',
			'Holden',
			'Nobleboro',
			'Norridgewock',
			// 'Paris',
			'Steep Falls',
			'Sullivan',
			'Alma',
			'Bangor',
			'Beecher',
			'Benton Harbor',
			'Charlevoix',
			'Dearborn Heights',
			'Flat Rock',
			'Garden City',
			'Haslett',
			'Newaygo',
			'Ovid',
			'Pinckney',
			'Plymouth',
			'Redford',
			'Champlin',
			'Cokato',
			'Collegeville',
			'Cottonwood',
			'Fergus Falls',
			'Hector',
			'Lexington',
			'Long Prairie',
			'Mankato',
			'Montevideo',
			'Oak Grove',
			'Red Lake Falls',
			'Saint James',
			'Slayton',
			'Victoria',
			'Watertown',
			'White Bear Lake',
			'Young America (historical)',
			'Fort Totten',
			'Butte',
			'Schuyler',
			'Ashland',
			'Hampton',
			'Thornton',
			'Wolfeboro',
			'Bayonne',
			'Belvidere',
			'Bergenfield',
			'Cedar Grove',
			'Clark',
			'East Orange',
			'Hopatcong Hills',
			'Matawan',
			'Mercerville',
			'Peapack',
			'Astoria',
			'Attica',
			'Bath',
			// 'Bergen',
			'Big Flats',
			'Cincinnatus',
			'Clay',
			'Cobleskill',
			'Cornwall',
			'Dix Hills',
			'Douglaston',
			'Durham',
			'East Ithaca',
			'East New York',
			'Endwell',
			'Fairview',
			'Fulton',
			'Huntington',
			'Johnstown',
			'Kerhonkson',
			'Lake Erie Beach',
			'Melville',
			'Monroe',
			'Mount Vernon',
			'Phelps',
			'Seneca Knolls',
			'Shrub Oak',
			'Sylvan Beach',
			'Unionport',
			'Vernon',
			'Village of the Branch',
			'Waverly',
			'West Islip',
			'Wheatley Heights',
			'Bellefontaine',
			'Bradford',
			'Cleveland',
			'Doylestown',
			'East Cleveland',
			'Fort Recovery',
			'Girard',
			'Hanover',
			'Heath',
			'Kenton',
			'Leetonia',
			'Loudonville',
			'Newark',
			'Northfield',
			'Perry',
			'Shelby',
			'Tiltonsville',
			'Yorkville',
			'Audubon',
			'Blandon',
			'Campbelltown',
			'Conemaugh',
			'Dallas',
			'Exeter',
			'Fredericksburg',
			'Hometown',
			'Hyde',
			'Kenhorst',
			'King of Prussia',
			'Leechburg',
			'New Wilmington',
			'Old Orchard',
			'Palo Alto',
			'Paxtonia',
			'River View Park',
			'Skippack',
			'Sun Valley',
			'Trucksville',
			'Waterford',
			'Cumberland',
			'Pascoag',
			'Pawtucket',
			'De Smet',
			'Freeman',
			'Ipswich',
			'Addison',
			'Townshend',
			'Washington',
			'Altoona',
			'Chippewa Falls',
			'Clinton',
			'Fox Point',
			'French Island',
			'Howard',
			'Loyal',
			'Marinette',
			'Marion',
			'Marshall',
			'Mayville',
			'Mukwonago',
			'Newburg',
			'Redgranite',
			'Saint Peter',
			'Spooner',
			'Tomah',
			'West Allis',
			'Whiting',
			'South Taft',
			'Canyon Day',
			'Ehrenberg',
			'Gilbert',
			'Oracle',
			'Sells',
			'Tonto Basin',
			'Belmont',
			'Big River',
			'Bolinas',
			'Brisbane',
			'Cabazon',
			'Cambrian Park',
			'Cameron Park',
			'Campbell',
			'China Lake Acres',
			'Coachella',
			'Dollar Point',
			'El Dorado Hills',
			'Forest Ranch',
			'Grand Terrace',
			'Gridley',
			'Indio',
			'Julian',
			'Keyes',
			'La Mesa',
			'Live Oak',
			'Los Osos',
			'Lower Lake',
			'Mariposa',
			'Mission Canyon',
			'Norco',
			'Paradise',
			'Sanger',
			'Stallion Springs',
			'Tujunga',
			'Upland',
			'Cedaredge',
			'Gypsum',
			'Highlands Ranch',
			'Lamar',
			'Loma',
			'Hatch',
			'La Puebla',
			'Meadow Lake',
			'Navajo',
			'Twin Lakes',
			'Golden Valley',
			'Crane',
			'Pecos',
			'Rocksprings',
			'Sierra Blanca',
			'Spearman',
			'Genola',
			'Santa Clara',
			'Ahwatukee Foothills',
			'Dacono',
			'Frederick',
			'Rangely',
			'Big Sky',
			'Crow Agency',
			'Forsyth',
			'Fort Belknap Agency',
			'Lockwood',
			'Pablo',
			'Beulah',
			'Mitchell',
			'Sutherland',
			'Condon',
			'Rockcreek',
			'Dupree',
			'Lead',
			'Farr West',
			'Lewiston',
			// 'Naples',
			'North Salt Lake',
			'Vernal',
			'West Point',
			'Brush Prairie',
			'Burien',
			'Fairwood',
			'Ferndale',
			'Mead',
			'Mill Plain',
			'Mukilteo',
			'Orting',
			'Parkland',
			'Rocky Point',
			'Waterville',
			'West Lake Stevens',
			'West Wenatchee',
			'White Salmon',
			'Wollochet',
			'Zillah',
			'Buffalo',
			'Upton',
			'Kahalu‘u',
			'Koloa',
			'Kualapu‘u',
			'Wailuku',
			'Wainaku',
			'Hawaiian Ocean View',
			'Lazy Mountain',
			'Meadow Lakes',
			'Tanaina',
			'Cobb Island',
			'Guilford Center',
			'Acushnet Center',
			'Leith-Hatfield',
			'East Bronson',
			'Kendall West',
			'El Cerro Mission',
			'Fort Bliss',
			'Dollar Corner',
			'Oatfield',
			'Port Hadlock-Irondale',
			'Tsaile',
			'Honaunau-Napoopoo',
			'Tri-Cities',
			'Valle Vista',
			'Meadow Oaks',
			'Franklin Center',
			'Harrison',
			'Camden',
			'Cleveland',
			'Good Hope',
			'Marion',
			'Pell City',
			'Scottsboro',
			'Taylor',
			'Austin',
			'Hazen',
			'Searcy',
			'Tontitown',
			'Bloomingdale',
			'Laurel',
			'Belleair Bluffs',
			'Big Coppitt Key',
			'Bloomingdale',
			'Bunnell',
			'Chiefland',
			'DeLand',
			'Eustis',
			'Hollywood',
			'Hunters Creek',
			'Isle of Normandy',
			'Ives Estates',
			'Jacksonville',
			'Lantana',
			'Longboat Key',
			'Mascotte',
			'Medulla',
			'Melrose Park',
			'Myrtle Grove',
			// 'Naples',
			'Oak Hill',
			'Odessa',
			'Okeechobee',
			'Paradise Heights',
			'Ridge Wood Heights',
			'Saint George',
			'South Bay',
			'The Meadows',
			'Tice',
			'Youngstown',
			'Auburn',
			'Buford',
			'Cave Spring',
			'Fairburn',
			'Fort Gaines',
			'Greenville',
			'Holly Springs',
			'Lavonia',
			'Monticello',
			'Oxford',
			'Quitman',
			'Richland',
			'Rome',
			'Athens',
			'Bement',
			'Bethany',
			'Bridgeport',
			'Brighton',
			'Bunker Hill',
			'Goreville',
			'Greenville',
			'Jonesboro',
			'Moweaqua',
			'New Athens',
			'Washington Park',
			'Bloomfield',
			'Jasonville',
			'Princes Lakes',
			'Rising Sun',
			'Sellersburg',
			'Tell City',
			'Whiteland',
			'Clay Center',
			'Wellington',
			'Bowling Green',
			'Clay',
			'Dawson Springs',
			'Dayton',
			'Flemingsburg',
			'Frenchburg',
			'Horse Cave',
			'Lawrenceburg',
			'Mount Vernon',
			'Okolona',
			'Pewee Valley',
			'Prospect',
			'Abita Springs',
			'Bayou Cane',
			'Brownsfield',
			'Bunkie',
			'Elton',
			'Gonzales',
			'Greenwood',
			'Gretna',
			'Melville',
			'Merryville',
			'New Iberia',
			'Oak Hills Place',
			'Olla',
			'Prairieville',
			'Presquille',
			'Raceland',
			'Richwood',
			'Saint Martinville',
			'Swartz',
			'Bartonsville',
			'Buckeystown',
			'Charles Village',
			'Cheverly',
			'Clover Hill',
			'Crisfield',
			'District Heights',
			'Germantown',
			'North East',
			'Potomac',
			'Robinwood',
			'Rock Hall',
			'Rossville',
			'Salisbury',
			'Ash Grove',
			'Ava',
			'Bridgeton',
			'Gladstone',
			'Huntsville',
			'Jefferson City',
			'Lakeshire',
			'Marshall',
			'Marthasville',
			'Sugar Creek',
			'Amory',
			'Brookhaven',
			'Byhalia',
			'Centreville',
			'Clarksdale',
			'Lynchburg',
			'Monticello',
			'Mound Bayou',
			'Picayune',
			'Walnut Grove',
			'Barker Heights',
			'Durham',
			'Farmville',
			'Forest City',
			'Half Moon',
			'Maggie Valley',
			'Ogden',
			'Pleasant Garden',
			'Ranlo',
			'Shallotte',
			'Cedar Glen Lakes',
			'Cherry Hill Mall',
			'Greentree',
			'Baltimore',
			'Batavia',
			'Coal Grove',
			'Fairfield',
			'Greenfield',
			'Kings Mills',
			'Lucasville',
			'Madeira',
			'Mount Healthy Heights',
			'Pleasant Run',
			'Pomeroy',
			'Powhatan Point',
			'Sabina',
			'Woodlawn',
			'Zanesville',
			'Geary',
			'Newkirk',
			'Pink',
			'Prague',
			'Sperry',
			'Wilson',
			'Wynnewood',
			'Hanover',
			'Lima',
			'New Freedom',
			'Wayne Heights',
			'Windsor',
			'Bishopville',
			'Blythewood',
			'Burton',
			'Centerville',
			'Clover',
			'East Gaffney',
			'Estill',
			'Gloverville',
			'Hampton',
			'Saxon',
			'Summerville',
			'Tigerville',
			'Walhalla',
			'Brownsville',
			'Burns',
			'Chuckey',
			'Cleveland',
			'Estill Springs',
			'Lake Tansi',
			'Ripley',
			'Rutherford',
			'Savannah',
			'Wartburg',
			'Watertown',
			'Alief',
			'Bowie',
			'Briarcliff',
			'Buchanan Dam',
			'Bullard',
			'Cooper',
			'Copperas Cove',
			'Cotulla',
			'DeCordova',
			'Encantada-Ranchito-El Calaboz',
			'Forest Hill',
			'Giddings',
			'Gorman',
			'Greatwood',
			'Harper',
			'Hearne',
			'Hebbronville',
			'Hidalgo',
			'Jersey Village',
			'Kaufman',
			'Kirbyville',
			'Krum',
			'Live Oak',
			'Llano Grande',
			'Nurillo',
			'Oak Leaf',
			'Olmito',
			'Ore City',
			'Patton Village',
			'Robinson',
			'Serenada',
			'Taft',
			'Willis',
			'Wimberley',
			'Woodway',
			'Bluefield',
			'Chatham',
			'Dayton',
			'Haymarket',
			'McLean',
			'Montrose',
			'University Center',
			'Vienna',
			'Yorkshire',
			'Barrackville',
			'Buckhannon',
			'Coal City',
			'Fairlea',
			'Fairmont',
			'Hurricane',
			'Mannington',
			'Martinsburg',
			'New Haven',
			'Ripley',
			'Tornado',
			'Sherwood',
			'Hickory Creek',
			'Alexander City',
			'Ashford',
			'Camillus',
			'Prospect',
			'Tolland',
			'Watertown',
			'Allison',
			'Alton',
			'Epworth',
			'Fairbank',
			'Monona',
			'Pleasant Hill',
			'Roland',
			'Tiffin',
			'Urbandale',
			'Bourbonnais',
			'Carthage',
			'Chicago Ridge',
			'Crete',
			'Downers Grove',
			'Elmwood Park',
			'Fairfield',
			'Gardner',
			'Hampshire',
			'Manteno',
			'McCullom Lake',
			'Medinah',
			'Mount Greenwood',
			'Portage Park',
			'Rantoul',
			'Rossville',
			'Round Lake Park',
			'South Beloit',
			'South Elgin',
			'South Pekin',
			'Spring Grove',
			'Warrenville',
			'Willowbrook',
			'Bourbon',
			'Decatur',
			'Long Beach',
			'Ossian',
			'Walton',
			'Bridgewater',
			'Brookline',
			'Hinsdale',
			'Marshfield',
			'Milton',
			'Natick',
			'Paxton',
			'Randolph',
			'Richmond',
			'Shutesbury',
			'Stow',
			'Swansea',
			'Enfield',
			'North Bath',
			'Ogunquit',
			'Randolph',
			'Raymond',
			'Rome',
			'Shapleigh',
			'Trenton',
			'Cedar Springs',
			'Ecorse',
			'Escanaba',
			'Flint',
			'Grosse Pointe Farms',
			'Hazel Park',
			'Kalamazoo',
			'Lake Odessa',
			'Ontonagon',
			'Portage',
			'South Rockwood',
			'Arden Hills',
			'Barnesville',
			'Baudette',
			'Belle Plaine',
			'Birchwood',
			'Brooklyn Center',
			'Burnsville',
			'Foley',
			'Kasson',
			'Long Lake',
			'Madison',
			'Nicollet',
			'Saint Cloud',
			'Virginia',
			'Waterville',
			'West Coon Rapids',
			'West Saint Paul',
			'Winona',
			'Albion',
			'Nebraska City',
			'Norfolk',
			'Syracuse',
			'Bristol',
			'Fremont',
			'Hampstead',
			'Lee',
			'Atlantic Highlands',
			'Avon-by-the-Sea',
			'Belmar',
			'Carlstadt',
			'Elmwood Park',
			'Essex Fells',
			'Groveville',
			'Hampton',
			'Kenilworth',
			'Middlebush',
			'Middletown',
			'Midland Park',
			'Oakland',
			'Ramsey',
			'Red Bank',
			'Ridgefield Park',
			'Robertsville',
			'South Orange',
			'Totowa',
			'Wallington',
			'Weehawken',
			'Wharton',
			'Woodbridge',
			'Bellerose',
			'Chelsea',
			'Chester',
			'Dundee',
			'East Greenbush',
			'East Patchogue',
			'East Village',
			'Elmsford',
			'Fishkill',
			'Flower Hill',
			'Fordham',
			'Great Kills',
			'Groton',
			'Hagaman',
			'Holbrook',
			'Inwood',
			'Johnson City',
			'Kings Bridge',
			'Kiryas Joel',
			'Lake Grove',
			'Lake Katrine',
			'Laurel Hollow',
			'Mamaroneck',
			'Maybrook',
			'Merrick',
			'Mohawk',
			'Morrisania',
			'Morrisville',
			'Newfane',
			'Ozone Park',
			'Plattekill',
			'Pleasantville',
			'Pulaski',
			'Sloatsburg',
			'Terrace Heights',
			'Valhalla',
			'Valley Stream',
			'Waterloo',
			'White Plains',
			'Calcutta',
			'Copley',
			'Lexington',
			'Maumee',
			'Mogadore',
			'Mount Carmel',
			'North Olmsted',
			'West Liberty',
			'Westlake',
			'Bethlehem',
			'Boalsburg',
			'Bristol',
			'Charleroi',
			'Dover',
			'Emigsville',
			'Franklin',
			'Geistown',
			'Georgetown',
			'Gibsonia',
			'Harleigh',
			'Hastings',
			'Hummelstown',
			'Intercourse',
			'Jermyn',
			'Jerome',
			'Kenmar',
			'Lake Wynonah',
			'Marysville',
			'Mountainhome',
			'Muse',
			'Reamstown',
			'Sellersville',
			'Simpson',
			'Spring House',
			'Tremont',
			'Weatherly',
			'Narragansett Pier',
			'Portsmouth',
			'Elk Point',
			'Sioux Falls',
			'Lyndon',
			'Morristown',
			'Baldwin',
			'Bangor',
			'Caledonia',
			'Camp Lake',
			'Cleveland',
			'Dickeyville',
			'Fall Creek',
			'Hortonville',
			'Howards Grove',
			'Hustisford',
			'Kewaunee',
			'Markesan',
			'Neenah',
			'New Glarus',
			'Okauchee Lake',
			'Pardeeville',
			'Superior',
			'Tichigan',
			'Bridgeport',
			'Colorado City',
			'Meadview',
			'Nogales',
			'Paradise Valley',
			'Prescott Valley',
			'Scottsdale',
			'Willcox',
			'Banning',
			'Bell',
			'Bloomington',
			'Claremont',
			'Colfax',
			'Corralitos',
			'Corte Madera',
			'Dogtown',
			'East Sonora',
			'Empire',
			'Fallbrook',
			'Garden Acres',
			'Half Moon Bay',
			'Heber',
			'Hemet',
			'La Habra Heights',
			'Lemon Grove',
			'Montecito',
			'Morgan Hill',
			'Mountain View',
			'Pacifica',
			'Pajaro',
			'Pine Valley',
			'Riverbank',
			'Riverdale',
			'Riverside',
			'San Andreas',
			'San Diego Country Estates',
			'Studio City',
			'Sun City',
			'Terra Bella',
			'Tulare',
			'Watsonville',
			'Wildomar',
			'Cripple Creek',
			'Orchard Mesa',
			'Rifle',
			'Salida',
			'The Pinery',
			'Vail',
			'Sublette',
			'Carnuel',
			'Hobbs',
			'McIntosh',
			'Mesquite',
			'Milan',
			'Fernley',
			'Eagle Pass',
			'Farwell',
			'Hamlin',
			'Homestead Meadows South',
			'Ephraim',
			'Anton',
			'Shasta',
			'Idaho City',
			'Kimberly',
			'Chester',
			'Circle',
			'Fort Benton',
			'Frenchtown',
			'Seeley Lake',
			'Burlington',
			'McCook',
			'Ashland',
			'Coburg',
			'Cornelius',
			'Cottage Grove',
			'Culp Creek',
			'Lents',
			'North Plains',
			'Talent',
			'Terrebonne',
			'White City',
			'Belle Fourche',
			'Kadoka',
			'Centerville',
			'Draper',
			'Provo',
			'Riverdale',
			'Snyderville',
			'Clear Lake',
			'Fox Island',
			'Granite Falls',
			'Millwood',
			'Point Roberts',
			'Pomeroy',
			'Port Angeles',
			'Ravensdale',
			'Republic',
			'Tanner',
			'Waitsburg',
			'West Longview',
			'West Pasco',
			'Guernsey',
			'Mountain View',
			'Kahuku',
			'Kailua',
			'Kaneohe',
			'Mākaha',
			'Mokulēia',
			'Punaluu',
			'Hau‘ula',
			'Tok',
			'South Blooming Grove',
			'City of Sammamish',
			'Hillsborough',
			'Fort Drum',
			'East Hampton North',
			'Gates-North Gates',
			'Fort Stewart',
			'Samsula-Spruce Creek',
			'Oak Grove',
			'Florence-Graham',
			'Heber-Overgaard',
			'Tanglewilde-Thompson Place',
			'Hickam Field',
			'Knik-Fairview',
			'Farmers Loop',
			'Midway',
			'Oakleaf Plantation',
			'Topaz Ranch Estates',
			'Matheny',
			'Enchanted Hills',
			'Northdale',
			'Tiki Island',
			'Big Bass Lake',
			'Sugarcreek Police Dept',
			'Bay Minette',
			'Brewton',
			'Cedar Bluff',
			'Choccolocco',
			'Haleyville',
			'Hayden',
			'Mobile',
			'Pelham',
			'Thorsby',
			'Uniontown',
			'Farmington',
			'Little Flock',
			'Mansfield',
			'Marshall',
			'Glasgow',
			'Kent Acres',
			'Smyrna',
			'Asbury Lake',
			'Bayonet Point',
			'Citrus Park',
			'Combee Settlement',
			'East Pensacola Heights',
			'Gibsonia',
			'Hallandale Beach',
			'Jacksonville Beach',
			'Jasmine Estates',
			'Minneola',
			'North Bay Village',
			'North Redington Beach',
			'Orange Park',
			'Orlovista',
			'Pace',
			'Pine Ridge',
			'Pine Ridge',
			'Pinecrest',
			'Plantation Mobile Home Park',
			'Port Orange',
			'Pretty Bayou',
			'Rockledge',
			'Roseland',
			'Sanford',
			'Schall Circle',
			'Spring Hill',
			'Union Park',
			'Wellington',
			'Whiskey Creek',
			'Williamsburg',
			'Americus',
			'Baldwin',
			'Camilla',
			'East Dublin',
			'Gibson',
			'Gray',
			'Gumlog',
			'LaGrange',
			'Lithonia',
			'Mountain City',
			'Pooler',
			'Swainsboro',
			'Trion',
			'Waynesboro',
			'Altamont',
			'Benton',
			'Breese',
			'Energy',
			'Freeburg',
			'Mound City',
			'Palestine',
			'Sumner',
			'Swansea',
			'Clinton',
			'Greendale',
			'Milan',
			'Emporia',
			'Hill City',
			'La Crosse',
			'Lansing',
			'Lawrence',
			'Prairie Village',
			'Brownsville',
			'Edgewood',
			'Hickman',
			'Hillview',
			'Irvington',
			'La Grange',
			'Northfield',
			'Shelbyville',
			'Stanford',
			'Sturgis',
			'Carville',
			'Denham Springs',
			'Pineville',
			'Prien',
			'Winnsboro',
			'Damascus',
			'Ferndale',
			'Goddard',
			'Halfway',
			'Hebron',
			'Joppatowne',
			'Lake Arbor',
			'Marlboro Meadows',
			'Redland',
			'Riverside',
			'Rockville',
			'West Ocean City',
			'Butler',
			'California',
			'Campbell',
			'Centralia',
			'Charlack',
			'Clever',
			'Hermann',
			'Hillsdale',
			'Moline Acres',
			'Pleasant Hill',
			'Savannah',
			'Sikeston',
			'Sweet Springs',
			'Valley Park',
			'Warson Woods',
			'Willow Springs',
			'Arnold Line',
			'Brooksville',
			'Coldwater',
			'Flora',
			// 'Glendale',
			'Greenville',
			'Gulf Park Estates',
			'Kosciusko',
			'Leland',
			'Moss Point',
			'Okolona',
			'Raleigh',
			'Sharon',
			'Verona',
			'Walls',
			'Wesson',
			'Wiggins',
			'Belville',
			'Bent Creek',
			'Butner',
			'Buxton',
			'Fayetteville',
			'Graham',
			'Indian Trail',
			'Lake Waccamaw',
			'Laurel Park',
			'Lillington',
			'Maysville',
			'Midway',
			'Neuse Forest',
			'North Wilkesboro',
			'Pinebluff',
			'Pittsboro',
			'Robbins',
			'Stanley',
			'Surf City',
			'Trenton',
			'Whispering Pines',
			'White Plains',
			'Whiteville',
			'Yanceyville',
			'Brigantine',
			'Dover Beaches South',
			'Margate City',
			'Marlton',
			'Paulsboro',
			'Pine Beach',
			'Port Norris',
			'Stratford',
			'Turnersville',
			'Waretown',
			'Covedale',
			'Huber Heights',
			'Landen',
			'Mount Sterling',
			'Nelsonville',
			'Newtown',
			'Arapaho',
			'Bethel Acres',
			'Broken Bow',
			'Collinsville',
			'Enid',
			'Inola',
			'Locust Grove',
			'McCord',
			'Nowata',
			'Oilton',
			'Sapulpa',
			'Biglerville',
			'Millbourne',
			'Susquehanna Trails',
			'Bonneau Beach',
			'Brookdale',
			'Cane Savannah',
			'Dalzell',
			'Easley',
			'Edgefield',
			'Landrum',
			'Meggett',
			'Seabrook Island',
			'Timmonsville',
			'Ware Shoals',
			'Brentwood Estates',
			'Christiana',
			'Elizabethton',
			'Erwin',
			'Huntingdon',
			'Kenton',
			'Loretto',
			'Pine Crest',
			'Sneedville',
			'South Fulton',
			"Thompson's Station",
			'White House',
			'Alto',
			'Beverly Hills',
			'Cameron Park',
			'Castle Hills',
			'Cedar Park',
			'Centerville',
			'Charlotte',
			'Clarksville',
			'Copper Canyon',
			'Everman',
			'Fairchilds',
			'Gardendale',
			'Gladewater',
			'Holland',
			'Hollywood Park',
			'Huntsville',
			'Iowa Colony',
			'Jacksboro',
			'Kerens',
			'Kountze',
			'La Grulla',
			'La Marque',
			'La Paloma',
			'La Porte',
			'Leonard',
			'Marble Falls',
			'Nacogdoches',
			'Onalaska',
			'Pilot Point',
			'Powderly',
			'Rendon',
			'Rhome',
			'Rockport',
			'Spring Valley',
			'Taylor',
			'West Columbia',
			'West Sharyland',
			'Wyldwood',
			'Blacksburg',
			'Centreville',
			'Dulles Town Center',
			'East Lexington',
			'Grottoes',
			'Hopewell',
			'Loch Lomond',
			'Norton',
			'Pearisburg',
			'Roanoke',
			'Tysons Corner',
			'Bluewell',
			// 'Glendale',
			'Washington',
			'Winfield',
			'Wallis',
			'Wise',
			'Cromwell',
			'New Fairfield',
			'New Milford',
			'Niantic',
			'North Haven',
			'Old Saybrook',
			'Poquonock Bridge',
			'Sherman',
			'Thompsonville',
			'West Haven',
			'West Simsbury',
			'Atkins',
			'Belmond',
			'Blue Grass',
			'Clinton',
			'Creston',
			'Iowa Falls',
			'Jesup',
			'Kalona',
			'Nashua',
			'Tama',
			'Tipton',
			'Walford',
			'West Branch',
			'Aledo',
			'Carthage',
			'Chatsworth',
			'Chillicothe',
			'Fox Lake',
			'Fox River Grove',
			'Frankfort',
			'Lakewood Shores',
			'Lincoln',
			'Morton',
			'Petersburg',
			'Preston Heights',
			'Saint Joseph',
			'South Holland',
			'Thomasboro',
			'Wilmington',
			'Hartford City',
			'Muncie',
			'Munster',
			'North Liberty',
			'Peru',
			'Winchester',
			'Buzzards Bay',
			'Douglas',
			'Dover',
			'Falmouth',
			'Hingham',
			'Nantucket',
			'North Falmouth',
			'Otis',
			'Plymouth',
			'Southborough',
			'Townsend',
			'Warren',
			'Augusta',
			'Caribou',
			'Franklin',
			'Friendship',
			'Houlton',
			'Saint George',
			'Wells Beach Station',
			'Buchanan',
			'Detroit Beach',
			'Eagle River',
			'Elk Rapids',
			'Fremont',
			'Gaylord',
			'Greilickville',
			'Hamtramck',
			'Hastings',
			'Lakeview',
			'Lathrup Village',
			'Mio',
			'Mount Clemens',
			'Quinnesec',
			'Richmond',
			'Twin Lake',
			'Walker',
			'Cottage Grove',
			'East Gull Lake',
			'Eden Prairie',
			'Falcon Heights',
			'Jordan',
			'Little Rock',
			'Maple Plain',
			'Medford',
			'New Ulm',
			'Park Rapids',
			'Pine Island',
			'Saint Francis',
			'Saint Augusta',
			'Waseca',
			'Windom',
			'Rock Port',
			'Ellendale',
			'Fargo',
			'Battle Creek',
			'Macy',
			'Red Cloud',
			'Waverly',
			'Andover',
			'Brentwood',
			'Bridgewater',
			'Epsom',
			'Fitzwilliam',
			'Hudson',
			'Madison',
			'Milan',
			'Newmarket',
			'Tilton',
			'Weare',
			'Little Ferry',
			'Old Tappan',
			'River Vale',
			'Riverdale',
			'Shrewsbury',
			'Voorhees',
			'Amagansett',
			'Bergen Beach',
			'Clark Mills',
			'Concord',
			'East Elmhurst',
			'Eden',
			'Glen Oaks',
			'Great River',
			'Hawthorne',
			'Hillside Lake',
			'Holland',
			'Huntington Bay',
			'Hunts Point',
			'Hurley',
			'Inwood',
			'Jamestown',
			'Jericho',
			'Kensington',
			'Massapequa',
			'Melrose Park',
			'Middleport',
			'Mineville',
			'Myers Corner',
			'New Hartford',
			'New Springville',
			'Perth',
			'Peru',
			'Pine Bush',
			'Port Henry',
			'Saugerties',
			'South Fallsburg',
			'South Nyack',
			'Sparkill',
			'Sparrow Bush',
			'Springfield',
			'Syracuse',
			'Tupper Lake',
			'West Point',
			'Ballville',
			'Bucyrus',
			'Dresden',
			'Hubbard',
			'Hudson',
			'Lake Mohawk',
			'Minerva Park',
			'Piqua',
			'Portage Lakes',
			'Reminderville',
			// 'Toronto',
			'Bath',
			'Bessemer',
			'Brownstown',
			'Duncansville',
			'Flourtown',
			'Flying Hills',
			'Foster Brook',
			'Gilbertsville',
			'Hawley',
			'Hemlock Farms',
			'Hyde Park',
			'Jacksonwald',
			'Johnstown',
			'Laporte',
			'Matamoras',
			'Nescopeck',
			'New Castle',
			'North Charleroi',
			'Osceola Mills',
			'Pitcairn',
			'Roaring Spring',
			'Seneca',
			'Watsontown',
			'Whitfield',
			'Wyoming',
			'Yardley',
			'Cranston',
			'Kingston',
			'Warren',
			'Platte',
			'Webster',
			'Wessington Springs',
			'Ferrisburgh',
			'Milton',
			'Williamstown',
			'Bristol',
			'Cato',
			'Combined Locks',
			'Darien',
			'Delafield',
			'Franksville',
			'Greendale',
			'Iola',
			'Jefferson',
			'Lake Delton',
			'Milton',
			'New Holstein',
			'Niagara',
			'Onalaska',
			'Portland',
			'Poynette',
			'Richfield',
			'Ripon',
			'River Falls',
			'Walworth',
			'Wausau',
			'Wind Point',
			'Weirton',
			'Three Rivers',
			'Fort Defiance',
			'Fortuna Foothills',
			'Fountain Hills',
			'Payson',
			'Phoenix',
			'White Mountain Lake',
			'Wickenburg',
			'Williamson',
			'Barstow Heights',
			'Bridgeport',
			'Burlingame',
			'Calipatria',
			'Canyon Country',
			'Carlsbad',
			'Castroville',
			'Cathedral City',
			'Diamond Bar',
			'Escondido',
			'Idyllwild',
			'Linden',
			'Lucerne',
			'Minkler',
			'Montalvin',
			'Orosi',
			'Ramona',
			'Sleepy Hollow',
			'Thornton',
			'Union City',
			'West Sacramento',
			'Yountville',
			'Bennett',
			'Fairplay',
			'Fraser',
			'Pueblo West',
			'Rocky Ford',
			'Saint Francis',
			'Columbus',
			'Española',
			'Moriarty',
			'Mosquero',
			'Zuni Pueblo',
			'Paradise',
			'Spring Valley',
			'Tonopah',
			'Goodwell',
			'Clarendon',
			'Denver City',
			'McCamey',
			'Mentone',
			'Sanderson',
			'Stinnett',
			'Castle Dale',
			'Cedar City',
			'Fountain Green',
			'Gruver',
			'Central Valley (historical)',
			'McCloud',
			'Aristocrat Ranchettes',
			'Brush',
			'Milliken',
			'Steamboat Springs',
			'Dalton Gardens',
			'Eagle',
			'Rigby',
			'Laurel',
			'Somers',
			'Townsend',
			'Warm Springs',
			'Chappell',
			'Gering',
			'Boardman',
			'Cannon Beach',
			'Columbia City',
			'Gervais',
			'Glide',
			'Grand Ronde',
			'Pacific City',
			'Mobridge',
			'Murdo',
			'Oglala',
			'Farmington',
			'Kearns',
			'Magna',
			'Sandy Hills',
			'Wendover',
			'Burley',
			'Central Park',
			'Clarkston',
			'Clearview',
			'Leavenworth',
			'Medical Lake',
			'Okanogan',
			'Purdy',
			'Sedro-Woolley',
			'Warden',
			'West Valley',
			'Yarrow Point',
			'Yelm',
			'Marbleton',
			'Rafter J Ranch',
			'Saratoga',
			'Lā‘ie',
			'Makawao',
			'Hanamā‘ulu',
			'Homer',
			'Monterey Park',
			'Rio Communities',
			'Carmel Hamlet',
			'Horseheads North',
			'Suitland-Silver Hill',
			'Level Park-Oak Park',
			'Fort Pierce North',
			'Vero Beach South',
			'Zephyrhills South',
			'Antelope Valley-Crestview',
			'Gold Canyon',
			'Royal Kunia',
			'Monument Hills',
			'Verona Walk',
			'Smithville-Sanders',
			'McConnell AFB',
			'Marlboro Village',
			'Southern Gateway',
			'Prairie Heights',
			'Patterson Tract',
			'Woodlake',
			'Clark-Fulton',
			'Bridgewater',
			'Twin Lakes',
			'Brent',
			'Dadeville',
			'Fairhope',
			'Grove Hill',
			'Harpersville',
			'Pine Level',
			'Rainbow City',
			'Red Bay',
			'Samson',
			'Westover',
			'Winfield',
			'Atkins',
			'Fayetteville',
			'Lamar',
			'Landmark',
			'Marked Tree',
			// 'Melbourne',
			'Mulberry',
			'Piney',
			'Redfield',
			'Smackover',
			'Blades',
			'North Star',
			'Rehoboth Beach',
			'Aventura',
			'Baldwin',
			'Bayshore Gardens',
			'Belleair Beach',
			'Bithlo',
			'Boynton Beach',
			'Callahan',
			'Cantonment',
			'Citrus Springs',
			'Coconut Creek',
			'Eastpoint',
			'Fern Park',
			'Fuller Heights',
			'Holmes Beach',
			'Indian Shores',
			'Iona',
			'Kings Point',
			'Lake Magdalene',
			'Lely',
			'North Fort Myers',
			'Port Richey',
			'Port Saint Joe',
			'South Highpoint',
			'Stock Island',
			'The Villages',
			'Thonotosassa',
			'Upper Grand Lagoon',
			'Wekiwa Springs',
			'Westchase',
			'West Samoset',
			'Westview',
			'Blackshear',
			'Dawson',
			'Eatonton',
			'Fayetteville',
			'Knoxville',
			'Lithia Springs',
			'Montezuma',
			'Nahunta',
			'Panthersville',
			'Pearson',
			'Rydal',
			'Scottdale',
			'Sunnyside',
			'Winder',
			'Collinsville',
			'Edinburg',
			'Golconda',
			'Jerome',
			'Louisville',
			'Norris City',
			'Oblong',
			'Taylorville',
			'Virginia',
			'French Lick',
			'Greencastle',
			'North Madison',
			'Pendleton',
			'Auburn',
			'Clay Center',
			'Ellinwood',
			'Greensburg',
			'Hoisington',
			'Oskaloosa',
			'Ashland',
			'Auburn',
			'Bellevue',
			'Cadiz',
			'Calvert City',
			'Earlington',
			'Edmonton',
			'Francisville',
			'Junction City',
			'Middlesboro',
			'Monticello',
			'Morehead',
			'North Corbin',
			'Saint Matthews',
			'Saint Dennis',
			'Arcadia',
			'Eastwood',
			'Natalbany',
			'Edmonston',
			'Green Valley',
			'Highfield-Cascade',
			'Huntingtown Town Center',
			'Ocean City',
			'Ocean Pines',
			'Parkville',
			'Scaggsville',
			'Severn',
			'Silver Spring',
			'University Park',
			'Belle',
			'Chaffee',
			'Dardenne Prairie',
			'Duquesne',
			'Grandview',
			'Keytesville',
			'Maysville',
			'North Kansas City',
			'Saint Ann',
			'Thayer',
			'Webster Groves',
			'Eupora',
			'Hazlehurst',
			'New Augusta',
			'Ridgeland',
			'Brogden',
			'Carrboro',
			'Henderson',
			'Hertford',
			'Horse Shoe',
			'Huntersville',
			'Landis',
			'Lucama',
			'Swanquarter',
			'Tarboro',
			// 'Berlin',
			'Holiday Heights',
			'Mullica Hill',
			'Westville',
			'Wood-Lynne',
			'Cherry Grove',
			'Day Heights',
			'Finneytown',
			'Lewisburg',
			'Monroe',
			'New Paris',
			'Atoka',
			'Barnsdall',
			'Cleveland',
			'Lawton',
			'McLoud',
			'Medford',
			'Muldrow',
			'Noble',
			'Ponca City',
			'Snyder',
			'Avondale',
			'Caln',
			'Gettysburg',
			'Abbeville',
			'Conway',
			'Dunean',
			'Fountain Inn',
			'Greenville',
			'Irwin',
			'Loris',
			'Mayo',
			'Pineridge',
			'Powdersville',
			'Westminster',
			'Brentwood',
			'Camden',
			'Caryville',
			'Decatur',
			'Hopewell',
			'Jacksboro',
			'Kingston Springs',
			'Lenoir City',
			'Oak Ridge',
			'Rogersville',
			'Signal Mountain',
			'Wildwood Lake',
			'Woodbury',
			'Ames',
			'Boling',
			'Brookshire',
			'Burnet',
			'DeSoto',
			'Denison',
			'Hempstead',
			'Hornsby Bend',
			'Hurst',
			'Jones Creek',
			'Mineral Wells',
			'Mount Vernon',
			'Murillo Colonia',
			'Navasota',
			'Palm Valley',
			'Palmhurst',
			'Red Lick',
			'Somerset',
			'Tenaha',
			'Terrell',
			'Wharton',
			'Whitney',
			'Woodbranch',
			'Ashland',
			'Bassett',
			'Charlotte Court House',
			'Chase City',
			'Countryside',
			'Glade Spring',
			'Lowes Island',
			'Mathews',
			'Mount Hermon',
			'North Springfield',
			'Spotsylvania',
			'Warm Springs',
			'West Gate',
			'Williamsburg',
			'Beaver',
			'Ranson',
			'Salem',
			'Florence',
			'Bremen',
			'Milford',
			'Norwalk',
			'Orange',
			'Plymouth',
			'South Windham',
			'Terramuggus',
			'Winsted',
			'Brooklyn',
			'Buffalo',
			'Center Point',
			'Clarion',
			'Milford',
			'Oelwein',
			'Palo',
			'Sac City',
			'Storm Lake',
			'Webster City',
			'Bartlett',
			'Belvidere',
			'Byron',
			'Channel Lake',
			'Clinton',
			'East Hazel Crest',
			'Fairbury',
			'Hyde Park',
			'Matteson',
			'Mount Morris',
			'Oak Park',
			'Oakwood',
			'Berne',
			'Kingsford Heights',
			'South Haven',
			'Wanatah',
			'Warsaw',
			'Wolcottville',
			'Acushnet',
			'Bear Creek',
			'Red Chute',
			'Brook Highland',
			'Carbon Hill',
			'Citronelle',
			'Creola',
			'Deatsville',
			'Goodwater',
			'Jemison',
			'Phenix City',
			'Rockford',
			'Slocomb',
			'Trussville',
			'Valley Grande',
			'Bono',
			'Dardanelle',
			'Gibson',
			'Harrison',
			'Heber Springs',
			'Huntsville',
			'Nashville',
			'Elsmere',
			'Middletown',
			'Belle Glade Camp',
			'Bokeelia',
			'Brent',
			'Crystal River',
			'Flagler Beach',
			'Gandy',
			'Glencoe',
			'Holden Heights',
			'Lake Worth Corridor',
			'Lakeside',
			'Lehigh Acres',
			'Manasota Key',
			'Moore Haven',
			'Naples Manor',
			'Oriole Beach',
			'Palm Beach',
			'Pine Castle',
			'Pompano Beach',
			'Sunny Isles Beach',
			'Sunrise',
			'Tequesta',
			"Town 'n' Country",
			'Villano Beach',
			'Warrington',
			'Wauchula',
			'Yulee',
			'Alto',
			'Chattanooga Valley',
			'Dallas',
			'Dunwoody',
			'Fort Oglethorpe',
			'Ringgold',
			'Soperton',
			'Springfield',
			'Arcola',
			'Carterville',
			'Dupo',
			'Fairfield',
			'Glen Carbon',
			'Hartford',
			'Jacksonville',
			'Lovington',
			'Nashville',
			"O'Fallon",
			'Robinson',
			'Sandoval',
			'Sullivan',
			'Tolono',
			'Waterloo',
			'Brookville',
			'Brownstown',
			'Dillsboro',
			'Fort Branch',
			'Spencer',
			'Worthington',
			'Zionsville',
			'El Dorado',
			'Hesston',
			'Louisburg',
			'Mission Hills',
			'Osborne',
			'Pleasanton',
			'Bardstown',
			'Benton',
			'Brooks',
			'Catlettsburg',
			'Cloverport',
			'Covington',
			'Hopkinsville',
			'Ironville',
			'Lancaster',
			'Mayfield',
			'Maysville',
			'Newport',
			'Park Hills',
			'Simpsonville',
			'Villa Hills',
			'Worthington',
			'Basile',
			'Bastrop',
			'Bossier City',
			'Breaux Bridge',
			'Carencro',
			'Chackbay',
			'Cullen',
			'Jackson',
			'Kaplan',
			'Moss Bluff',
			'Reserve',
			'South Vacherie',
			'Bennsville',
			'Bowling Green',
			'Charlestown',
			'Derwood',
			'Glenn Dale',
			'Mitchellville',
			'Woodlawn',
			'Alton',
			'Archie',
			'Bellefontaine Neighbors',
			'Belton',
			'East Independence',
			'Farmington',
			'Four Seasons',
			'Glendale',
			'High Ridge',
			'Pacific',
			'Pineville',
			'Shell Knob',
			'Steele',
			'Strafford',
			'Webb City',
			'Wellsville',
			'Weston',
			'Holly Springs',
			'Jackson',
			'Jonestown',
			'Natchez',
			'Quitman',
			'Ruleville',
			'Southaven',
			'Tchula',
			'Bessemer City',
			'Boonville',
			'Cedar Point',
			'Chapel Hill',
			'Concord',
			'Gibsonville',
			'Hemby Bridge',
			'Jefferson',
			'Louisburg',
			'Marshville',
			'Rutherfordton',
			'South Gastonia',
			'Sparta',
			'Swannanoa',
			'Tabor City',
			'Unionville',
			'Walkertown',
			'Walnut Cove',
			'Windsor',
			'Cherry Hill',
			'Gibbsboro',
			'North Beach Haven',
			'North Cape May',
			'Rio Grande',
			'Rosenhayn',
			'Silver Ridge',
			'Ventnor City',
			'Bexley',
			'Commercial Point',
			'Fairborn',
			'Lisbon',
			'Millersport',
			'South Bloomfield',
			'Vandalia',
			'West Carrollton City',
			'Withamsville',
			'Apache',
			'Carnegie',
			'El Reno',
			'Laverne',
			'Meridian',
			'Pawhuska',
			'Roland',
			'Tuttle',
			'Fairchance',
			'Red Lion',
			'Thorndale',
			'Cowpens',
			'Edisto',
			'Irmo',
			'Johnston',
			'Judson',
			'Lakewood',
			'Laurel Bay',
			'Myrtle Beach',
			'Pacolet',
			'Alcoa',
			'Algood',
			'Bartlett',
			'Byrdstown',
			'Clarksville',
			'Dayton',
			'Hunter',
			'Lebanon',
			'Pleasant View',
			'Portland',
			'Soddy-Daisy',
			'White Bluff',
			'White Pine',
			'Aransas Pass',
			'Atascocita',
			'Athens',
			'Balcones Heights',
			'Beverly',
			'Boyd',
			'Clute',
			'Dayton',
			'Edgecliff Village',
			'Evadale',
			'Friendswood',
			'Frisco',
			'Gatesville',
			'Honey Grove',
			'Hughes Springs',
			'Krugerville',
			'Kyle',
			'Lakehills',
			'Lufkin',
			'Malakoff',
			'Markham',
			'Mason',
			'McKinney',
			'Mexia',
			'Mont Belvieu',
			'Oak Trail Shores',
			'Palmer',
			'Port Arthur',
			'Redland',
			'Thorndale',
			'Willow Park',
			'Accomac',
			'Ashburn',
			'Bristow',
			'Bull Run Mountain Estates',
			'Cave Spring',
			'Fairlawn',
			'Falmouth',
			'Fishersville',
			'Groveton',
			'Lancaster',
			'Luray',
			'Merrimac',
			'Pennington Gap',
			'Sandston',
			'Warrenton',
			'Washington',
			'Wolf Trap',
			'Woodlawn',
			'Daniels',
			'Fort Ashby',
			'South Charleston',
			'West Union',
			'Darien',
			'Gales Ferry',
			'Southwood Acres',
			'Stamford',
			'Wallingford',
			'Westport',
			'Windsor',
			'Dubuque',
			'Eldridge',
			'Jefferson',
			'Knoxville',
			'Shenandoah',
			'Solon',
			'University Heights',
			'West Des Moines',
			'Williamsburg',
			'Andalusia',
			'Avondale',
			'Broadview',
			'Chebanse',
			'Clifton',
			'Crestwood',
			'Creve Coeur',
			'Danvers',
			'Deer Park',
			'Elk Grove Village',
			'Gages Lake',
			'Gibson City',
			'Gridley',
			'Johnsburg',
			'Lake Bluff',
			'Lindenhurst',
			'Long Grove',
			'Melrose Park',
			'Momence',
			'Morris',
			'Orland Hills',
			'Park Forest',
			'Riverdale',
			'Roanoke',
			'Thornton',
			'Willowbrook',
			'Winnebago',
			'Worth',
			'Angola',
			'Cedar Lake',
			'Dunlap',
			'Fish Lake',
			'Greentown',
			'Thorntown',
			'Bernardston',
			'Blackstone',
			'Bourne',
			'Boxborough',
			'Chesterfield',
			'Duxbury',
			'Everett',
			'Hamilton Worcester',
			'Lexington',
			'Longmeadow',
			'Lynn',
			'Rochester',
			'Southampton',
			'Bar Harbor',
			'Chisholm',
			'Hancock',
			'Leeds',
			'Manchester',
			'Old Orchard Beach',
			'Rumford',
			'Wilton',
			'Bellevue',
			'Brighton',
			'Dowagiac',
			'Ferndale',
			'Grand Ledge',
			'Hubbard Lake',
			'Huntington Woods',
			'Ithaca',
			'Kalkaska',
			'Marquette',
			'Montrose',
			'Romeo',
			'Southgate',
			'Stockbridge',
			'Webberville',
			'Baxter',
			'Big Lake',
			'Buffalo',
			'Corcoran',
			'Crosby',
			'Elk River',
			'Fairmont',
			'Fosston',
			'Hinckley',
			'Hutchinson',
			'Lake Crystal',
			'Osakis',
			'Saint Joseph',
			'Sauk Centre',
			'South Saint Paul',
			'Truman',
			'Neligh',
			'Omaha',
			'Osceola',
			'Wisner',
			'Brookline',
			'Chesterfield',
			'Freedom',
			'Suncook',
			'Sutton',
			'Chester',
			'Fort Lee',
			'Leonardo',
			'Metuchen',
			'Morris Plains',
			'Mount Arlington',
			'Paterson',
			'Perth Amboy',
			'Piscataway',
			'Yorketown',
			'Ballston Lake',
			'Batavia',
			'Bath Beach',
			'Bay Park',
			'Brinckerhoff',
			'Calcium',
			'Central Valley',
			'Charleston',
			'Cypress Hills',
			'Depew',
			'Eastchester',
			'Florida',
			'Frankfort',
			// 'Glendale',
			'Gravesend',
			'Grymes Hill',
			'Hartsdale',
			'Homer',
			'Ithaca',
			'Jamaica',
			'Jefferson Heights',
			'Manorhaven',
			'Mastic',
			'Medford',
			'Mineola',
			'Northville',
			'Noyack',
			'Oswego',
			'Port Dickinson',
			'Richland',
			'Roslyn',
			'Seaside',
			'Silver Creek',
			'Sloan',
			'South Corning',
			'Wellsville',
			'Wurtsboro',
			'Bainbridge',
			'Carrollton',
			'Clinton',
			'Craig Beach',
			'East Liverpool',
			'Garfield Heights',
			'Geneva-on-the-Lake',
			'Glandorf',
			'Highland Heights',
			'Martins Ferry',
			'Mount Vernon',
			'Rocky River',
			'Saint Marys',
			'Salineville',
			'Strongsville',
			'Urbana',
			'Adamstown',
			'Avonia',
			'Bryn Athyn',
			'Burgettstown',
			'Dale',
			'Danville',
			'Downingtown',
			'Edinboro',
			'Fox Chapel',
			'Fullerton',
			'Glassport',
			'Grove City',
			'Heidelberg',
			'Ingram',
			'Johnsonburg',
			'Langhorne',
			'Lansdale',
			'McGovern',
			'New Britain',
			'New Holland',
			'Orchard Hills',
			'Pittston',
			'Radnor',
			'Rheems',
			'Selinsgrove',
			'South Pottstown',
			'Stormstown',
			'Warminster Heights',
			'West Hills',
			'West Leechburg',
			'West Newton',
			'West Wyoming',
			'Youngwood',
			'Bradford',
			'Hope Valley',
			'Woonsocket',
			'Dell Rapids',
			'Olivet',
			'Winner',
			'Vergennes',
			'Abbotsford',
			'Belleville',
			'Cedar Grove',
			'De Pere',
			'Fall River',
			'Juneau',
			'Lake Koshkonong',
			'Luck',
			'McFarland',
			'North Prairie',
			'Pleasant Prairie',
			'Powers Lake',
			'Wisconsin Dells',
			'New Cumberland',
			'Wellsburg',
			'Bethel',
			'Byram',
			'Canaan',
			'Catalina',
			'Fredonia',
			'Tempe Junction',
			'Williams',
			'Aptos',
			'Auburn Lake Trails',
			'Bonita',
			'Chatsworth',
			'Clear Lake Riviera',
			'Clearlake',
			'Columbia',
			'Cottonwood',
			'Deer Park',
			'Elverta',
			'Foster City',
			'Green Acres',
			'Homeland',
			'Jackson',
			'King City',
			'La Jolla',
			'Las Flores',
			'Le Grand',
			'Lennox',
			'Lexington Hills',
			'Malibu',
			'Monte Rio',
			'Murrieta Hot Springs',
			'Orcutt',
			'Orinda',
			'Palo Alto',
			'Rancho Santa Margarita',
			'Rialto',
			'Rosamond',
			'San Clemente',
			//'Santa Ana',
			'North Hills',
			'Soledad',
			'Somerset',
			'South Yuba City',
			'Woodland Hills',
			'Woodville',
			'Edgewater',
			'Angel Fire',
			'Arroyo Seco',
			'Portales',
			'Santa Teresa',
			'Las Vegas',
			'Laughlin',
			'Reno',
			'Virginia City',
			'Claude',
			'Idalou',
			'Panhandle',
			'Ransom Canyon',
			'Rotan',
			'Snyder',
			'Ajo',
			'Mount Shasta',
			'Kremmling',
			'Meeker',
			'Payette',
			'Havre',
			'Lewistown',
			'South Browning',
			'West Yellowstone',
			'Wolf Point',
			'Dickinson',
			'Manning',
			'Astoria',
			'Baker City',
			'Coos Bay',
			'Durham',
			'Gold Hill',
			'Hermiston',
			'Molalla',
			'Siletz',
			'Toledo',
			'Umatilla',
			'Waldport',
			'Willamina',
			'Pine Ridge',
			'White River',
			'Brigham City',
			'Murray',
			'Oakley',
			'River Heights',
			'Willard',
			'Wolf Creek',
			'Alderton',
			'Arlington Heights',
			'Brier',
			'Ephrata',
			'Five Corners',
			'Grand Mound',
			'Maltby',
			'Mercer Island',
			'North Creek',
			'Sudden Valley',
			'Tumwater',
			'Casper',
			'Glenrock',
			'Powell',
			'Sheridan',
			'South Greeley',
			'Craig',
			'Kea‘au',
			'Kurtistown',
			'Orchidlands Estates',
			'Pāpa‘ikou',
			'Hana',
			'‘Aiea',
			'Kenai',
			'Weston Lakes',
			'Harwich Center',
			'Cheshire Village',
			'Mercerville-Hamilton Square',
			'Leisure World',
			'De Land Southwest',
			'Alamosa East',
			'Bertsch-Oceanview',
			'Holloman Air Force Base',
			'Lagunitas-Forest Knolls',
			'Monroe North',
			'Auburn Gresham',
			'Heritage Lake',
			'Decatur',
			'Maine',
			'Valley Glen',
			'Encanto',
			'Carmel',
			'Galena',
			'Newburgh',
			'Versailles',
			'Waterloo',
			'Arma',
			'Belleville',
			'Lindsborg',
			'Merriam',
			'South Hutchinson',
			'Crestwood',
			'Falmouth',
			'Fern Creek',
			'Greenup',
			'Guthrie',
			'Highland Heights',
			'Hurstbourne',
			'Irvine',
			'Monticello',
			'Somerset',
			'Cecilia',
			'Crowley',
			'Glenmora',
			'Gramercy',
			'Leonville',
			'Lockport Heights',
			'Rosepine',
			'Bel Air',
			'Bethesda',
			'Chestertown',
			'Chevy Chase',
			'Cottage City',
			'Dunkirk Town Center',
			'Ellicott City',
			'Havre de Grace',
			'Highland',
			'Kettering',
			'Lansdowne',
			'Lochearn',
			'Long Beach',
			'Morningside',
			'North Beach',
			'Pumphrey',
			'Travilah',
			'Wheaton',
			'Woodlawn',
			'Clinton',
			'Crane',
			'Granby',
			'Greenville',
			'Holden',
			'Jackson',
			'Knob Noster',
			'Lebanon',
			'Malden',
			// 'Paris',
			'Parkville',
			'Rock Hill',
			'Wellston',
			'Booneville',
			'Columbia',
			'De Kalb',
			'Pascagoula',
			'Port Gibson',
			'Prentiss',
			'Sunflower',
			'Waynesboro',
			'Asheville',
			'Banner Elk',
			'Boiling Spring Lakes',
			'Buies Creek',
			'Camden',
			'Green Level',
			'Hoopers Creek',
			'Icard',
			'Kinston',
			'Navassa',
			'Pinehurst',
			'Piney Green',
			'Polkton',
			'Swepsonville',
			'West Canton',
			'Winton',
			'Erma',
			'Laurel Lake',
			'Lumberton',
			'Ship Bottom',
			'Smithville',
			'Somerdale',
			'Franklin Furnace',
			'Harrison',
			'Jamestown',
			'Kettering',
			'Kingston',
			'Lake Darby',
			'Logan Elm Village',
			// 'London',
			'Mason',
			'Middletown',
			'Montgomery',
			'Northbrook',
			'Rossmoyne',
			'South Point',
			'Springdale',
			'Trenton',
			'Urbancrest',
			'Chandler',
			'Kingfisher',
			'Lexington',
			'Newcastle',
			'Wetumka',
			'Masontown',
			'Media',
			'Norwood',
			'Pennville',
			'Stewartstown',
			'Waynesburg',
			'Bamberg',
			'Berea',
			'Fort Mill',
			'Graniteville',
			'Jackson',
			'Manning',
			'Pendleton',
			'Prosperity',
			'Seven Oaks',
			'Sumter',
			'Tega Cay',
			'Wedgefield',
			'Williamston',
			'Winnsboro',
			'Woodruff',
			'Arlington',
			'Bells',
			'Brighton',
			'Coopertown',
			'Dandridge',
			'Fairmount',
			'Lookout Mountain',
			'Red Bank',
			'Sevierville',
			'Austin',
			'Bellaire',
			'Benjamin',
			'Bevil Oaks',
			'Canyon Lake',
			'Carrollton',
			'Central Gardens',
			'Donna',
			'Ennis',
			'Fate',
			'Galena Park',
			'Gregory',
			'Hickory Creek',
			'Highland Village',
			'Hutto',
			'Linden',
			'Los Indios',
			'Madisonville',
			'Manor',
			'Mercedes',
			'Mount Pleasant',
			'Northcrest',
			'Reno',
			'Reno',
			'Riesel',
			'Rockwall',
			'Stafford',
			'Stockdale',
			'Sugar Land',
			'Tool',
			'Van',
			'Van Alstyne',
			'Vidor',
			'Weslaco',
			'Big Stone Gap',
			'Bowling Green',
			'Cedar Bluff',
			'Cherry Hill',
			'Grundy',
			'Hollins',
			'Isle of Wight',
			'Narrows',
			'Orange',
			'Portsmouth Heights',
			'Rosslyn',
			'Strasburg',
			'Union Hall',
			'Prosperity',
			'Merchantville',
			'Adamsville',
			'Cos Cob',
			'Canton',
			'Edina',
			'East Haddam',
			'Mansfield City',
			'Noank',
			'Albia',
			'Cresco',
			'Dyersville',
			'Jewell',
			'Newton',
			'Pella',
			'Strawberry Point',
			'Waverly',
			'Berkeley',
			'Big Rock',
			'Brookfield',
			'Calumet Park',
			'Davis Junction',
			'Durand',
			'East Dundee',
			'Forreston',
			'Georgetown',
			'Highland Park',
			'Hometown',
			'La Grange',
			'Lake Zurich',
			'Libertyville',
			'Loves Park',
			'Manhattan',
			'Milford',
			'North Peoria',
			'Northbrook',
			'Port Byron',
			'Schiller Park',
			'Stone Park',
			'Sycamore',
			'Albion',
			'Frankfort',
			'Nappanee',
			'North Webster',
			'Stockton',
			'Clayton',
			'Forestdale',
			'Fort Deposit',
			'Ohatchee',
			'Satsuma',
			'Semmes',
			'Stevenson',
			'Bay',
			'Rison',
			'Salem',
			'Texarkana',
			'Yellville',
			'Brookside',
			'Georgetown',
			'Greenwood',
			'Auburndale',
			'Azalea Park',
			'Bal Harbour',
			'Bradenton Beach',
			'Cape Coral',
			'Chipley',
			'Deltona',
			'Fort Lauderdale',
			'Frostproof',
			'Gibsonton',
			'Goldenrod',
			'Harbour Heights',
			'Indian Rocks Beach',
			'Jupiter',
			'LaBelle',
			'Lockhart',
			'Marco',
			'Midway',
			'Mims',
			'Navarre',
			'Olga',
			'Orangetree',
			'Palm Springs North',
			'Pembroke Pines',
			'Pinewood',
			'Safety Harbor',
			'South Sarasota',
			'South Venice',
			'Suncoast Estates',
			'Watertown',
			'Wildwood',
			'Arcade',
			'Avondale Estates',
			'Buchanan',
			'Cedartown',
			'Harlem',
			'Hiram',
			'Lakeview',
			'Locust Grove',
			'Moultrie',
			'Peachtree City',
			'Reed Creek',
			'Rochelle',
			'Rockmart',
			'Tennille',
			'Cambria',
			'Cobden',
			'Grandview',
			'Litchfield',
			'Macon',
			'Pittsfield',
			'Shiloh',
			'Troy',
			'Winchester',
			'Dunlap',
			'Livingston',
			'Murfreesboro',
			'Nolensville',
			'Arlington',
			'Mattapoisett',
			'Monson',
			'Newburyport',
			'Oak Bluffs',
			'Osterville',
			'Salisbury',
			'Stockbridge',
			'Truro',
			'Weston',
			'Minot',
			'South Sanford',
			'Westbrook',
			'Winterport',
			'Burton',
			'Charlotte',
			'Detroit',
			'Freeland',
			'Grand Blanc',
			'Grandville',
			'Harrison',
			'Marshall',
			'Milford',
			'Petersburg',
			'Pinconning',
			'Quincy',
			'Southfield',
			'Three Rivers',
			'Whitehall',
			'Carver',
			'Hallock',
			'Lakeland',
			'Le Center',
			'North Branch',
			'Ortonville',
			'Pine City',
			'Rockford',
			'Saint Anthony',
			'Arapahoe',
			'Bartlett',
			'Ralston',
			'Waterloo',
			'West Point',
			'Atkinson',
			'Gilmanton',
			'Groveton',
			'Lebanon',
			'Milford',
			'North Hampton',
			'Berkeley Heights',
			'Bernardsville',
			'Edgewater Park',
			'Fairview',
			'Fort Dix',
			'Glen Gardner',
			'Highland Lake',
			'Kendall Park',
			'Little Silver',
			'Point Pleasant',
			'Secaucus',
			'Sparta',
			'Tinton Falls',
			'Westfield',
			'Albertson',
			'Andover',
			'Bedford Hills',
			'Bethpage',
			'Boston',
			'Catskill',
			'Cheektowaga',
			'Clifton',
			'Colonie',
			'Country Knolls',
			'Coxsackie',
			'East Aurora',
			'Hartford',
			'Hudson',
			'Katonah',
			'Lake Pleasant',
			'Lakeview',
			'Lyncourt',
			'Middle Village',
			'Moriches',
			'Norwich',
			'Ogdensburg',
			'Plandome',
			'Randolph',
			'Rocky Point',
			'Rouses Point',
			'Sag Harbor',
			'Seaford',
			'South Hempstead',
			'South Lockport',
			'Springfield Gardens',
			'Watkins Glen',
			'Ashley',
			'Bridgeport',
			'Brooklyn',
			'Dennison',
			'Eastlake',
			'Fredericktown',
			'Gnadenhutten',
			'Leipsic',
			'Mayfield Heights',
			'Mentor',
			'New Bremen',
			'North Ridgeville',
			'Vermilion-on-the-Lake',
			'Westerville',
			'Amity Gardens',
			'Bear Rocks',
			'Berwyn',
			'Bloomsburg',
			'Brookville',
			'Bryn Mawr',
			'Cherryville',
			'Clay',
			'Collegeville',
			'Croydon',
			'Denver',
			'Hasson Heights',
			'Kane',
			'Landisville',
			'Lincoln',
			'McConnellstown',
			'Monessen',
			'New Beaver',
			'New Brighton',
			'New Columbia',
			'New Eagle',
			'New Philadelphia',
			'Oakdale',
			'Penndel',
			'Pine Grove Mills',
			'Richlandtown',
			'Schlusser',
			'Smethport',
			'Swartzville',
			'Taylor',
			'Tower City',
			'Tullytown',
			'West Hamburg',
			'White Oak',
			'Middletown',
			'Parkston',
			'Redfield',
			'Bennington',
			'Londonderry',
			'Adams',
			'East Troy',
			'Menomonee Falls',
			'Merrill',
			'Monticello',
			'Oshkosh',
			'Randolph',
			'South Duxbury',
			'Cibecue',
			'Drexel Heights',
			'Globe',
			'Golden Valley',
			'Oro Valley',
			'Peridot',
			'Tanque Verde',
			'Wellton',
			'Whetstone',
			'Apple Valley',
			'Arcadia',
			'Calimesa',
			'Century City',
			'Country Club',
			'East Porterville',
			'Emerald Lake Hills',
			'Florin',
			'Garnet',
			'Hercules',
			'Highland',
			'Kensington',
			'Manhattan Beach',
			'McFarland',
			'Mendota',
			'Montara',
			'Moorpark',
			'Oakdale',
			'Rancho Cucamonga',
			'Richmond',
			'San Joaquin',
			'San Joaquin Hills',
			'Shingle Springs',
			'Signal Hill',
			'Upper Lake',
			'Val Verde',
			'Alamosa',
			'Applewood',
			'Center',
			'Eagle',
			'Edwards',
			'Elizabeth',
			'Lake City',
			'Minturn',
			'Bernalillo',
			'Dulce',
			'Mesilla',
			'San Felipe Pueblo',
			'Brackettville',
			'Fabens',
			'Fort Davis',
			'Memphis',
			'Post',
			'Paonia',
			'Humboldt Hill',
			'Akron',
			'Eaton',
			'Wellington',
			'Fort Hall',
			'Nezperce',
			'Broadus',
			'Butte',
			'Hazen',
			'Minot',
			'Florence',
			'Harrisburg',
			'John Day',
			'Rogue River',
			'Warren',
			'Woodburn',
			'Hot Springs',
			'Mound City',
			'Plain City',
			'White City',
			'Alderwood Manor',
			'Brewster',
			'Carson',
			'College Place',
			'Edmonds',
			'Enetai',
			'Kingsgate',
			'Kirkland',
			'Longview',
			'Mabton',
			'Marietta',
			'Navy Yard City',
			'Pullman',
			'Stanwood',
			'Sumas',
			'Warm Beach',
			'White Center',
			'Kalaoa',
			'Lanai City',
			'Wahiawā',
			'Wailea',
			'Waipahu',
			'King Cove',
			'Ridgeway',
			'Grant-Valkaria',
			'Fox Chase',
			'Wallingford Center',
			'Holiday City South',
			'Granville South',
			'Grissom Air Force Base',
			'Southeast Arcadia',
			'Nassau Village-Ratliff',
			'Yosemite Valley',
			'Susitna North',
			'Centennial Park',
			'Acalanes Ridge',
			'Mowbray Mountain',
			'Silver Summit',
			'Bothell East',
			'Devens',
			'Cherry Creek',
			'Lake Latonka',
			'Ashfield',
			'East Harwich',
			'Housatonic',
			'Newton',
			'Salem',
			'Somerset',
			'Springfield',
			'Walpole',
			'Westfield',
			'Buxton',
			'Chelsea',
			'East Machias',
			'Fort Fairfield',
			'Hampden',
			'Lake Arrowhead',
			'Springvale',
			'Swanville',
			'Windsor',
			'Wiscasset',
			'York Harbor',
			'Berrien Springs',
			'Cadillac',
			'Cass City',
			'Coldwater',
			'Dearborn',
			'Dimondale',
			'Indian River',
			'Lake City',
			'Michigan Center',
			'New Buffalo',
			'Otsego',
			'Pearl Beach',
			'Sand Lake',
			'Schoolcraft',
			'Sparta',
			'Trowbridge Park',
			'Albert Lea',
			'Arnold',
			'Bloomington',
			'Cloquet',
			'Eyota',
			'Greenfield',
			'Hanover',
			'Lake City',
			'Lino Lakes',
			'Moose Lake',
			'Oakport',
			'Rush City',
			'Saint Peter',
			'Tyler',
			'Walker',
			'Steele',
			'Bassett',
			'Minden',
			'Wakefield',
			'Alstead',
			'Boscawen',
			'Bow Bog',
			'Kingston',
			'New Boston',
			'Newport',
			'Allendale',
			'Asbury Park',
			'Bedminster',
			'Dumont',
			'East Freehold',
			'Ewing',
			'Florham Park',
			'Hamilton Square',
			'Hardwick',
			'Helmetta',
			'Lawrenceville',
			'Livingston',
			'Madison Park',
			'Navesink',
			'Northvale',
			'Roselle',
			'Sea Girt',
			'Sixmile Run',
			'Washington',
			'Baiting Hollow',
			'Bay Shore',
			'Bensonhurst',
			'Berne',
			'Binghamton',
			'Brentwood',
			'Byron',
			'Cayuga Heights',
			'Corning',
			'Dansville',
			'East Quogue',
			'Elmira',
			'Fort Salonga',
			'Glasco',
			'Goshen',
			'Greenvale',
			'Howard Beach',
			'Keeseville',
			'Lattingtown',
			'Liverpool',
			'Livonia',
			'Marion',
			'Massena',
			'Morris Heights',
			'North Boston',
			'Red Hook',
			'Ridgewood',
			'Rochester',
			'Seneca Falls',
			'Solvay',
			'Vails Gate',
			'Wappingers Falls',
			'Westmere',
			'Woodstock',
			'Wynantskill',
			'Glenmoor',
			'Huron',
			'Lewis Center',
			'North Royalton',
			'Orange',
			'Sheffield',
			'South Euclid',
			'Sugarcreek',
			'Sunbury',
			'Whitehouse',
			'Wickliffe',
			'Ashland',
			'Birdsboro',
			'Braddock Hills',
			'Centre Hall',
			'Coopersburg',
			'Devon',
			'Earlston',
			'Egypt',
			'Elim',
			'Emsworth',
			'Espy',
			'Ford City',
			'Fort Washington',
			'Johnsonburg',
			'Linntown',
			'Meadville',
			'Mifflinburg',
			'Morrisville',
			'Mount Pleasant',
			'North Apollo',
			'Ohioville',
			'Palmer Heights',
			'Paradise',
			'Rothsville',
			'Saxonburg',
			'South Temple',
			'Spring City',
			'Spring Mount',
			'Trafford',
			'West Kittanning',
			'Chepachet',
			'Alexandria',
			'Baltic',
			'Canton',
			'Wagner',
			'Dover',
			'Leicester',
			'Salisbury',
			'Chilton',
			'Clintonville',
			'Denmark',
			'Elkhorn',
			'Fitchburg',
			'Grafton',
			'Ladysmith',
			'Maple Bluff',
			'Neillsville',
			'Omro',
			'Park Falls',
			'Rio',
			'Roberts',
			'Rochester',
			'Suamico',
			'West Bend',
			'Whitewater',
			'Benwood',
			'Buckeye',
			'Citrus Park',
			'Clifton',
			'Congress',
			'Douglas',
			'Guadalupe',
			'Kearny',
			'Mountainaire',
			'Paulden',
			'Salome',
			'Whiteriver',
			'Altadena',
			'Arroyo Grande',
			'Auburn',
			'Barstow',
			'Boyle Heights',
			'Calistoga',
			'Casa Conejo',
			'Cotati',
			'Dos Palos',
			'Fairmead',
			'Ford City',
			'Forest Meadows',
			'Harbison Canyon',
			'Imperial Beach',
			'Lemoore',
			'Los Gatos',
			'Maricopa',
			'Mono Vista',
			'Mount Hermon',
			'Norwalk',
			'Piedmont',
			'Pleasanton',
			'Quail Valley',
			'Rancho Mirage',
			'Rohnert Park',
			'Rolling Hills',
			'San Marcos',
			'South Oroville',
			'Walnut Park',
			'Winchester',
			'Cañon City',
			'Castlewood',
			'Commerce City',
			'Indian Hills',
			'Las Animas',
			'Goodland',
			'Scott City',
			'Ulysses',
			'Chaparral',
			'Chimayo',
			'Los Ranchos de Albuquerque',
			'San Ysidro',
			'Santa Teresa',
			'Socorro',
			'Spencerville',
			'Alpine',
			'Canyon',
			'Dimmitt',
			'Matador',
			'Mertzon',
			'San Angelo',
			'Val Verde Park',
			'East Carbon City',
			'Fairview',
			'Cimarron',
			'Dunsmuir',
			'Happy Camp',
			'Hayfork',
			'Hayden',
			'Cascade',
			'Emmett',
			'Jerome',
			'Kellogg',
			'Osburn',
			'Preston',
			'Choteau',
			'Plains',
			'Polson',
			'West Glendive',
			'Bowman',
			'Thedford',
			'Cave Junction',
			'Deschutes River Woods',
			'Eagle Point',
			'Elgin',
			'Fossil',
			'Gresham',
			'Keizer',
			'Odell',
			'Lemmon',
			'Spearfish',
			'Cottonwood Heights',
			'Eagle Mountain',
			'Morgan',
			'Park City',
			'Spanish Fork',
			'Clyde Hill',
			'Granger',
			'Highland',
			'Montesano',
			'Quincy',
			'Richland',
			'Ritzville',
			'Tracyton',
			'University Place',
			'Woods Creek',
			'Riverton',
			'Rock Springs',
			'Waikapū',
			'‘Ewa Gentry',
			'Hawaiian Acres',
			'College',
			'Diamond Ridge',
			'North Pole',
			'McGuire AFB',
			'Naval Academy',
			'New Castle Northwest',
			'Northwest Harborcreek',
			'Guilford',
			'Offutt Air Force Base',
			'Redstone Arsenal',
			'Camp Pendleton South',
			'Lakeland South',
			'Waihee-Waiehu',
			'The Galena Territory',
			'Klahanie',
			'Randolph',
			'Watchtower',
			'Inverness',
			'Quail Creek',
			'Woodburn',
			'Cahaba Heights',
			'Carrollton',
			'Coaling',
			'Gordo',
			'Huntsville',
			'Lafayette',
			'Lineville',
			'Pike Road',
			'Rock Creek',
			'Sulligent',
			'Vance',
			'Wedowee',
			'Wetumpka',
			'Wilsonville',
			'Beebe',
			'Des Arc',
			'Lavaca',
			// 'London',
			'Piggott',
			'Trumann',
			'Claymont',
			'Newport',
			'Anna Maria',
			'Balm',
			'Big Pine Key',
			'Black Diamond',
			'Bonifay',
			'Country Club',
			'Five Points',
			'Havana',
			'Hernando',
			'Holley',
			'Keystone Heights',
			'Lake Wales',
			'Lakewood Park',
			'Madison',
			'Masaryktown',
			'Midway',
			'Miramar',
			'Neptune Beach',
			'Palm Bay',
			'Titusville',
			'Valrico',
			'Vamo',
			'Vero Beach',
			'West Park',
			'Berkeley Lake',
			'Blairsville',
			'Dalton',
			'Deenwood',
			'Dublin',
			'East Point',
			'Meigs',
			'Peachtree Corners',
			'Sylvania',
			'Warner Robins',
			'Wrens',
			'Albers',
			'Belleville',
			'Carrier Mills',
			'Carrollton',
			'Chester',
			'Du Quoin',
			'Okawville',
			'Shawneetown',
			'Toledo',
			'Trenton',
			'Connersville',
			'Trafalgar',
			'Baxter Springs',
			'Garnett',
			'Holton',
			'Kinsley',
			'Marion',
			'McPherson',
			'Oswego',
			'Parsons',
			'Phillipsburg',
			'Seneca',
			'Wellsville',
			'Berea',
			'Bloomfield',
			'Brooksville',
			'Cold Spring',
			'Coldstream',
			'Fairdale',
			'Hazard',
			'Jackson',
			'Mount Vernon',
			'Reidland',
			'Whitesburg',
			'Arnaudville',
			'Berwick',
			'Colfax',
			'Livingston',
			'Ossun',
			'Elkton',
			'Gambrills',
			'Greenbelt',
			'Kemp Mill',
			'Lutherville',
			'Owings Mills',
			'Seat Pleasant',
			'Selby-on-the-Bay',
			'South Gate',
			'Temple Hills',
			'Blue Springs',
			'Carl Junction',
			'Crocker',
			'Osage Beach',
			'Richmond Heights',
			'Riverview',
			'Sparta',
			'Willard',
			'Biloxi',
			'Canton',
			'Carthage',
			'Durant',
			'Greenwood',
			'Gulf Hills',
			'Horn Lake',
			'Iuka',
			'Kearney Park',
			'Liberty',
			'Mayersville',
			'Plantersville',
			'Saltillo',
			'Tunica',
			'Waveland',
			'Archer Lodge',
			'Calabash',
			'Cordova',
			'Cornelius',
			'Edenton',
			'Fremont',
			'Lewisville',
			'Princeville',
			'Rolesville',
			'Salisbury',
			'Sharpsburg',
			'Southmont',
			'Spring Lake',
			'Vander',
			'Wrightsville Beach',
			'Atlantic City',
			'Estell Manor',
			'Glassboro',
			'Jackson',
			'Mount Holly',
			'Newfield',
			'Pine Ridge at Crestwood',
			'Bethel',
			'Clayton',
			'Deer Park',
			'Lithopolis',
			'Miami Heights',
			'Moraine',
			'Mount Healthy',
			'Oak Hill',
			'Portsmouth',
			'Reno',
			'Tipp City',
			'West Portsmouth',
			'Bristow',
			'Buffalo',
			'Cheyenne',
			'Comanche',
			'Cordell',
			'Davis',
			'Fairfax',
			'Fletcher',
			'Kellyville',
			'Marietta',
			'Ninnekah',
			'Sulphur',
			'Talihina',
			'The Village',
			'Walters',
			'Weatherford',
			'Bonneauville',
			'Loganville',
			'Uniontown',
			'Upland',
			'Allendale',
			'Centerville',
			'Clearwater',
			'Mullins',
			'Shell Point',
			'Simpsonville',
			'Springdale',
			'Athens',
			'Blountville',
			'Central',
			'Cornersville',
			'Decherd',
			'Jackson',
			'Jonesborough',
			'Newport',
			'Three Way',
			'Trenton',
			'Vonore',
			'Angleton',
			'Archer City',
			'Bells',
			'Bogata',
			'Center',
			'Center',
			'Comfort',
			'Elkhart',
			'Fairview',
			'Fannett',
			'Frankston',
			'Freer',
			'Groves',
			'Hideaway',
			'Karnes City',
			'Kerrville',
			'La Blanca',
			'Lasara',
			'Lavon',
			'Lindale',
			'Livingston',
			'Mansfield',
			'Manvel',
			'Medina',
			'Menard',
			'Midlothian',
			'Midway South',
			'Mila Doce',
			'Oak Ridge North',
			'Palmview South',
			'Pecan Acres',
			'Rancho Viejo',
			'Sam Rayburn',
			'Sebastian',
			'Shenandoah',
			'Taft Southwest (historical)',
			'Woodcreek',
			'Woodville',
			'Bristol',
			'Fincastle',
			'Laurel',
			'Lovingston',
			'New Castle',
			'Prices Fork',
			'Rose Hill',
			'Stuarts Draft',
			'Suffolk',
			'Alderson',
			'Clarksburg',
			'Coal Fork',
			'Lesage',
			'Teays Valley',
			'Newton',
			'Tariffville',
			'East Falmouth',
			'Durham',
			'Oakville',
			'Plainfield',
			'South Woodstock',
			'Torrington',
			'Denver',
			'Durant',
			'Hamburg',
			'Harlan',
			'Hawarden',
			'Iowa City',
			'Keokuk',
			'Leon',
			'Lisbon',
			'Logan',
			'Madrid',
			'Maharishi Vedic City',
			'Mitchellville',
			'Mount Pleasant',
			'Onawa',
			'Postville',
			'Algonquin',
			'East Moline',
			'East Peoria',
			'Elmhurst',
			'Hainesville',
			'Lincoln Square',
			'Manito',
			'Northfield',
			'Peoria',
			'Riverside',
			'Savanna',
			'Silvis',
			'South Chicago Heights',
			'South Shore',
			'Summit',
			'West Peoria',
			'Westchester',
			'Albany',
			'Bass Lake',
			'Churubusco',
			'Dayton',
			'Huntington',
			'Koontz Lake',
			'Shadeland',
			'Trail Creek',
			'Greenwood',
			'Santa Claus',
			'East Longmeadow',
			'East Pepperell',
			'Easton',
			'Groveland',
			'Melrose',
			'North Amherst',
			'North Reading',
			'Orleans',
			'Shirley',
			'Sturbridge',
			'Tyngsboro',
			'West Stockbridge',
			'West Tisbury',
			'Bridgton',
			'Camden',
			'Cumberland Center',
			'Dixfield',
			'Eastport',
			'Ellsworth',
			'Gorham',
			'Madawaska',
			'Sidney',
			'Turner',
			'Vinalhaven',
			'Winthrop',
			'Carrollton',
			'Edwardsburg',
			'Hart',
			'Hillsdale',
			'Holly',
			'Kingsford',
			'Laingsburg',
			'Mancelona',
			'Mattawan',
			'Melvindale',
			'New Baltimore',
			'Norton Shores',
			'Oak Park',
			'Royal Oak',
			'Warren',
			'Williamston',
			'Braham',
			'Canby',
			'Eden Valley',
			'Faribault',
			'International Falls',
			'Mayer',
			'Montgomery',
			'New Prague',
			'New York Mills',
			'North Saint Paul',
			'Rockville',
			'Rosemount',
			'Saint Bonifacius',
			'Saint Paul Park',
			'Silver Bay',
			'Wells',
			'Willmar',
			'Grant City',
			'Horace',
			'Auburn',
			'Beaver City',
			'Columbus',
			'Lincoln',
			'Wayne',
			// 'Berlin',
			'Center Harbor',
			'Jefferson',
			'Kensington',
			'Lyndeborough',
			'Moultonborough',
			'Pittsfield',
			'Sandown',
			'Sunapee',
			'Allentown',
			'Columbus',
			'Dunellen',
			'Green Knoll',
			'Heathcote',
			'Hoboken',
			'Leisure Knoll',
			'Maplewood',
			'Milltown',
			'Monmouth Junction',
			'Moonachie',
			'North Plainfield',
			'Palisades Park',
			'Princeton Junction',
			'Rockaway',
			'Somerville',
			'Wanamassa',
			'West Long Branch',
			'Weston',
			'White Horse',
			'Amsterdam',
			'Baldwinsville',
			'Ballston Spa',
			'Bridgeport',
			'Brighton Beach',
			'The Bronx',
			'Cazenovia',
			'Center Moriches',
			'Clymer',
			'Cold Spring',
			'East Williston',
			'Eden',
			'Grand Island',
			'Greenwood Lake',
			'Haviland',
			'Head of the Harbor',
			'Livingston Manor',
			'Lyons',
			'Malverne',
			'Moravia',
			'Northport',
			'Oakdale',
			'Ontario',
			'Otisville',
			'Oxford',
			'Palenville',
			'Philadelphia',
			'Plandome Heights',
			'Port Jefferson',
			'Rossville',
			// 'Rotterdam',
			'Sackets Harbor',
			'Saratoga Springs',
			'Scotia',
			'Shelter Island Heights',
			'Sherrill',
			'Spackenkill',
			'Tuckahoe',
			'Weedsport',
			'West Bay Shore',
			'Whitehall',
			'Woodhaven',
			'Bluffton',
			'Bryan',
			'Cardington',
			'Celina',
			'Defiance',
			'Green',
			'Lyndhurst',
			'Montpelier',
			'Norwalk',
			'Prospect',
			'Saint Henry',
			'Woodville',
			'Apollo',
			'Braddock',
			'Bridgeville',
			'Curwensville',
			'Eagleville',
			'Elkland',
			'Enhaut',
			'Ephrata',
			'Fleetwood',
			'Lebanon',
			'Leola',
			'Marietta',
			'Meadowood',
			'Milton',
			'Mount Pocono',
			'Newport',
			'Newtown',
			'Patton',
			'Pleasant Gap',
			'Raubsville',
			'Saint Clair',
			'Sewickley',
			'Shiremanstown',
			'Trevose',
			'Washington',
			'Wind Gap',
			'Youngsville',
			'Garretson',
			'Salem',
			'Tea',
			'Chester',
			'Hyde Park',
			'Richford',
			'Saint Albans',
			'South Barre',
			'Waterbury',
			'West Rutland',
			'Bayside',
			'Bellevue',
			'Black Creek',
			'Black River Falls',
			'Bonduel',
			'Brothertown',
			'Fort Atkinson',
			'Middleton',
			'Oneida',
			'Rhinelander',
			'Rice Lake',
			'Saint Francis',
			'Strum',
			'Washburn',
			'Waukesha',
			'Wautoma',
			'Weirton Heights',
			'Clinton',
			'Flowing Wells',
			'Allendale',
			'Azusa',
			'Big Bear City',
			'Burbank',
			'Chinatown',
			'Durham',
			'El Centro',
			'Eldridge',
			'Encino',
			'Exeter',
			'Fort Bragg',
			'Greenfield',
			'Grover Beach',
			'Hamilton City',
			'Herald',
			'Huron',
			'Lakeside',
			'Laytonville',
			'Lockeford',
			'Loma Rica',
			'Los Serranos',
			'Lost Hills',
			'Morada',
			'Moraga',
			'Nuevo',
			'Oak Park',
			'Penngrove',
			'Port Hueneme',
			'Portola',
			'Rolling Hills Estates',
			'Salida',
			'San Jose',
			'Santa Clara',
			'Santa Margarita',
			'South Gate',
			'Tara Hills',
			'Temelec',
			'Tipton',
			'West Covina',
			'Westminster',
			'Arvada',
			'Breckenridge',
			'Frisco',
			'Leadville',
			'Mancos',
			'New Castle',
			'Walsenburg',
			'Grants',
			'Paradise Hills',
			'Rio Rancho',
			'Santa Fe',
			'Santo Domingo Pueblo',
			'Sunrise Manor',
			'Yerington',
			'Homestead Meadows North',
			'Lorenzo',
			'Alturas',
			'Redding',
			'Westwood',
			'La Salle',
			'Saint Maries',
			'Weiser',
			'Belgrade',
			'Miles City',
			'Plentywood',
			'Virginia City',
			'Whitehall',
			'Harrisburg',
			'Bethany',
			'Culver',
			'Drain',
			'Jacksonville',
			'Lafayette',
			'Moro',
			'Rainier',
			'Reedsport',
			'Tigard',
			'Mountain Green',
			'North Logan',
			'West Bountiful',
			'Anacortes',
			'Battle Ground',
			'Browns Point',
			'Cathlamet',
			'Chico',
			'Elma',
			'Fife Heights',
			'Goldendale',
			'Hansville',
			'Home',
			'Machias',
			'Ocean Shores',
			'Olympia',
			'Puyallup',
			'Riverton',
			'Tukwila',
			'Winlock',
			'Worland',
			'Kailua-Kona',
			'Puhi',
			'Waimalu',
			'Eagle River',
			'Kodiak',
			'Sand Point',
			'Cutler Bay',
			'Holly Lake Ranch',
			'Las Maravillas',
			'Monson Center',
			'Brittany Farms-Highlands',
			'Rising Sun-Lebanon',
			'Dade City North',
			'University',
			'Robins Air Force Base',
			'Lackland Air Force Base',
			'View Park-Windsor Hills',
			'Island Walk',
			'Summerfield',
			'Bothell West',
			'Rio Vista',
			'Shorewood Forest',
			'Limestone',
			'Plum Creek',
			'Tunica Resorts',
			'Lincoln',
			'Buda',
			'Columbiana',
			'Elberta',
			'Evergreen',
			'Fayetteville',
			'Geneva',
			'Minor',
			'Moores Mill',
			'New Market',
			'Oneonta',
			'Sylvania',
			'Tillmans Corner',
			'Woodstock',
			'Batesville',
			'Charleston',
			'Gentry',
			'Horseshoe Bend',
			'Johnson',
			'Lewisville',
			'Mayflower',
			'Monette',
			'Murfreesboro',
			'Newport',
			// 'Paris',
			'Wilmington Manor',
			'Apollo Beach',
			'Bagdad',
			'Christmas',
			'Dundee',
			'Edgewater',
			'Fellsmere',
			'Fort Myers Shores',
			'High Springs',
			'June Park',
			'Key Biscayne',
			'Key West',
			'Lake Lucerne',
			'Limestone Creek',
			'Longwood',
			'Miami Lakes',
			'Mount Dora',
			'Niceville',
			'Opa-locka',
			'Palmetto',
			'Panama City Beach',
			'Riviera Beach',
			'Seaside',
			'Terra Mar',
			'Wellborn',
			'Wesley Chapel',
			'Westwood Lake',
			'Williston',
			'Abbeville',
			'Butler',
			'Davisboro',
			'Douglas',
			'Flowery Branch',
			'Greensboro',
			'Hampton',
			'Indian Springs',
			'North Druid Hills',
			'Saint Simons Island',
			'Twin City',
			'Tybee Island',
			'Vinings',
			'Wadley',
			'Warrenton',
			'Leland Grove',
			'Marion',
			'Riverton',
			'Warrensburg',
			'Zeigler',
			'Bicknell',
			'Cumberland',
			'Hidden Valley',
			'Colwich',
			'Leavenworth',
			'Mankato',
			'Valley Center',
			'Breckinridge Center',
			'Camargo',
			'Eddyville',
			'Elizabethtown',
			'Flatwoods',
			'Jeffersonville',
			'Livermore',
			'Providence',
			'Springfield',
			'Windy Hills',
			'Abbeville',
			'Bawcomville',
			'Bourg',
			'Destrehan',
			'Dulac',
			'Tallulah',
			'Timberlane',
			'Vidalia',
			'Accokeek',
			'Essex',
			'Four Corners',
			'Garrett Park',
			'Glassmanor',
			'Irvington',
			'Mount Airy',
			'Pittsville',
			'Saint James',
			'Walker Mill',
			'Chesterfield',
			'Elvins',
			'Glasgow Village',
			'Goodman',
			'Herculaneum',
			'Joplin',
			'Marble Hill',
			'Palmyra',
			'Taos',
			'Vienna',
			'Ashland',
			'Clinton',
			'Forest',
			'Indianola',
			'Lumberton',
			'Newton',
			'Saint Martin',
			'Asheboro',
			'Benson',
			'Connelly Springs',
			'Elroy',
			'Franklin',
			'Jonesville',
			'Northlakes',
			'Plain View',
			'Pleasant Hill',
			'Stanfield',
			'Toast',
			'Waxhaw',
			'Weddington',
			'Wesley Chapel',
			'West Jefferson',
			'Wilsons Mills',
			'Beckett',
			'Collingswood',
			'Elwood',
			'Gibbstown',
			'Bellbrook',
			'Dayton',
			'Dry Run',
			'Forestville',
			'Grandview Heights',
			'Hamilton',
			'Leesburg',
			'Norwood',
			'Wilberforce',
			'Dickson',
			'Fairview',
			'Hominy',
			'Marlow',
			'Midwest City',
			'Minco',
			'Nichols Hills',
			'Oklahoma City',
			'Texanna',
			'Waukomis',
			'Airville',
			'Folsom',
			'South Connellsville',
			'Awendaw',
			'Charleston',
			'Clemson',
			'Darlington',
			'East Sumter',
			'Eureka Mill',
			'Georgetown',
			'Isle of Palms',
			'Saint Andrews',
			'Winnsboro Mills',
			'Carthage',
			'Celina',
			'Gruetli-Laager',
			'Hunter',
			'Oak Grove',
			'Piperton',
			'Powells Crossroads',
			'Somerville',
			'Tiptonville',
			'Asherton',
			'Alton',
			'Bloomington',
			'Brenham',
			'Gunter',
			'Henrietta',
			'Hudson Bend',
			'Carthage',
			'Eden',
			'Edgewood',
			'Euless',
			'Hondo',
			'Junction',
			'Kingsville',
			'La Pryor',
			'Laredo',
			'Lowry Crossing',
			'McGregor',
			'New Waverly',
			'Oak Point',
			'Pleak',
			'Richmond',
			'Santa Rosa',
			'Schertz',
			'Siesta Shores',
			'Teague',
			'The Woodlands',
			'Timberwood Park',
			'Uhland',
			'Wichita Falls',
			'Baileys Crossroads',
			'Charles City',
			'Crewe',
			'Culpeper',
			'Emporia',
			'Gretna',
			'Hanover',
			'King and Queen Court House',
			'Manassas Park',
			'Montross',
			'Mountain Road',
			'Norfolk',
			'Passapatanzy',
			'Vinton',
			'Ansted',
			'Bradley',
			'Ceredo',
			'Milton',
			'Moundsville',
			'Wiley Ford',
			'Camden',
			'Brussels',
			'Hazardville',
			'New Canaan',
			'Putnam',
			'Wethersfield',
			'Winchester Center',
			'Bondurant',
			'Charles City',
			'De Soto',
			'Hampton',
			'Lake Park',
			'Maquoketa',
			'Mount Ayr',
			'Moville',
			'Paullina',
			'Pocahontas',
			'Reinbeck',
			'Saint Ansgar',
			'Arlington Heights',
			'Batavia',
			'Beardstown',
			'Bridgeview',
			'Brighton Park',
			'Chicago Lawn',
			'Deerfield',
			'Forrest',
			'Hinckley',
			'Huntley',
			'La Harpe',
			'Lake in the Hills',
			'Mackinaw',
			'Near South Side',
			'Niles',
			'Palos Heights',
			'Savoy',
			'South Lawndale',
			'Streator',
			'Twin Grove',
			'West Englewood',
			'Auburn',
			'Brookston',
			'Butler',
			'Michigan City',
			'Barre',
			'Danvers',
			'East Douglas',
			'Erving',
			'Holland',
			'Leominster',
			'Malden',
			'North Lakeville',
			'Pepperell',
			'Shelburne Falls',
			'Upton',
			'West Newbury',
			'Hodgdon',
			'New Gloucester',
			'Stetson',
			'Allegan',
			'Whitmore Lake',
			'Annandale',
			'Blue Earth',
			'Cross Lake',
			'East Grand Forks',
			'Elbow Lake',
			'Fairfax',
			'Harris',
			'Lauderdale',
			'Litchfield',
			'Little Falls',
			'Maple Grove',
			'Minneota',
			'Pequot Lakes',
			'Spring Valley',
			'Stacy',
			'La Plata',
			'Cooperstown',
			'Valley City',
			'Chalco',
			'Eagle',
			'Grand Island',
			'Hastings',
			'Weeping Water',
			'Wymore',
			'Chester',
			'Londonderry',
			'Nashua',
			'New Ipswich',
			'Sanbornville',
			'Temple',
			'Westmoreland',
			'Winchester',
			'Beverly',
			'Bogota',
			'Fairfield',
			'Flemington',
			'Franklin Lakes',
			'Long Valley',
			'Middlesex',
			'Parsippany',
			'Vernon Valley',
			'Akron',
			'Alfred',
			'Babylon',
			'Cairo',
			'Celoron',
			'Coney Island',
			'Edgemere',
			'Farmingville',
			'Gang Mills',
			'Great Neck Gardens',
			'Greenpoint',
			'Hampton Manor',
			'Holcomb',
			'Irvington',
			'Mahopac',
			'Middleburgh',
			'Milton',
			'Mount Ivy',
			'North Collins',
			'North Valley Stream',
			'Oyster Bay Cove',
			'Portland',
			'Ravena',
			'Sayville',
			'Sheepshead Bay',
			'Sherburne',
			'Tribes Hill',
			'West Babylon',
			'Westons Mills',
			'Aurora',
			'Bradner',
			'Burton',
			'Churchill',
			'Eaton Estates',
			'Fort Loramie',
			// 'Glendale',
			'Kalida',
			'Manchester',
			'Maple Heights',
			'Masury',
			'Pioneer',
			'South Amherst',
			'Stow',
			'Van Wert',
			'Walton Hills',
			'Waterville',
			'Wauseon',
			'Bradford Woods',
			'Brickerville',
			'Brockway',
			'Calumet',
			'Churchill',
			'Clearfield',
			'Conway',
			'Duncannon',
			'Dunmore',
			'Everett',
			'Faxon',
			'Galeton',
			'Honesdale',
			'Kutztown',
			'Mahanoy City',
			'Midland',
			'Mount Cobb',
			'New Hope',
			'Port Carbon',
			'Russellton',
			'Shavertown',
			'Sheffield',
			'Shoemakersville',
			'Westfield',
			'Narragansett',
			'Howard',
			'Watertown',
			'Lyndonville',
			'Newfane',
			'Starksboro',
			'Ashland',
			'Barton',
			'Browns Lake',
			'Columbus',
			'Crandon',
			'Eagle',
			'Eagle River',
			'Florence',
			'Galesville',
			'Green Bay',
			'Lake Mills',
			'Lake Wazeecha',
			'Oconto Falls',
			'Orfordville',
			'Shawano',
			'South Milwaukee',
			'Spencer',
			'Follansbee',
			'Vine Hill',
			'Joseph City',
			'Kaibito',
			'Miami',
			'Swift Trail Junction',
			'Tortolita',
			'Yuma',
			'Aliso Viejo',
			'Angels Camp',
			'Atherton',
			'Bonsall',
			'Bostonia',
			'Buellton',
			'Bystrom',
			'Coalinga',
			'Deer Park',
			'Freedom',
			'Good Hope',
			'Ladera Heights',
			'Lynwood',
			'Murphys',
			'Orangevale',
			'Parlier',
			'Ridgemark',
			'Roseland',
			'Ross',
			'San Gabriel',
			'Valle Vista',
			'Woodcrest',
			'Woodside',
			'Bailey',
			'Cheyenne Wells',
			'Conejos',
			'Hugo',
			'Kittredge',
			'Pueblo',
			'Superior',
			'Liberal',
			'Berino',
			'Bosque Farms',
			'Edgewood',
			'Hagerman',
			'La Huerta',
			'Reserve',
			'White Sands',
			'Pioche',
			'Sparks',
			'Eldorado',
			'Midland',
			'Ozona',
			'Perryton',
			'Sweetwater',
			'Wink',
			'Spring Glen',
			'Wray',
			'Petersburg',
			'Montague',
			'Palo Cedro',
			'Fruitland',
			'Middleton',
			'Paul',
			'Sugar City',
			'Eureka',
			'Mohall',
			'Towner',
			'Washburn',
			'Watford City',
			'Bayard',
			'Gordon',
			'Ogallala',
			'Sidney',
			'Battle Mountain',
			'Canby',
			'Mill City',
			'Milton-Freewater',
			'Union',
			'Bison',
			'North Eagle Butte',
			'Oquirrh',
			'Smithfield',
			'South Jordan',
			'Uintah',
			'West Jordan',
			'Barberton',
			'Black Diamond',
			'Country Homes',
			'Hazel Dell',
			'Rochester',
			'Shoreline',
			'Tenino',
			'West Richland',
			'‘Ōma‘o',
			'Hanapēpē',
			'Fort Liberty',
			'Boulevard Gardens',
			'New Hartford Center',
			'Westbrook Center',
			'Millis-Clicquot',
			'North Attleborough Center',
			'Bethlehem Village',
			'Leisure Village West-Pine Lake Park',
			'Setauket-East Setauket',
			'Shorewood-Tower Hills-Harbert',
			'Fort Rucker',
			'North Weeki Wachee',
			'Joint Base Lewis McChord',
			'Helena Valley Southeast',
			'Deltana',
			'Sutton-Alpine',
			'Viera East',
			"Boswell's Corner",
			'Mill Creek East',
			'Belmont Estates',
			'Chicago Loop',
			'Chesaning',
			'Clinton',
			'Grand Rapids',
			'Lansing',
			'Lawton',
			'Oxford',
			'Ravenna',
			'Shields',
			'Skidway Lake',
			'Jessup',
			'Chelsea',
			'Chickasaw',
			'Clio',
			'Daphne',
			'Guntersville',
			'Hartselle',
			'Meadowbrook',
			'Odenville',
			'Talladega',
			'Troy',
			'Tuscumbia',
			'Warrior',
			'Bentonville',
			'Berryville',
			'Booneville',
			'Bryant',
			'Calico Rock',
			'Carlisle',
			'Centerton',
			'Cherokee Village',
			'East End',
			'Maumelle',
			'McAlmont',
			'Ola',
			'Bellefonte',
			'Newark',
			'Bowling Green',
			'Carrollwood Village',
			'Carver Ranches',
			'Clewiston',
			'Cocoa Beach',
			'Cross City',
			'Cutler',
			'Dunnellon',
			'Graceville',
			'Keystone',
			'Laguna Beach',
			'Lecanto',
			'Manatee Road',
			'Memphis',
			'Ojus',
			'Ormond-by-the-Sea',
			'Panama City',
			'Pensacola',
			'Pompano Beach Highlands',
			'Trenton',
			'Wedgefield',
			'Wright',
			'Blakely',
			'Bogart',
			'Brunswick',
			'Clarkesville',
			'Cleveland',
			'Franklin',
			'Helena',
			'Jesup',
			'Morrow',
			'Union Point',
			'Barry',
			'Benld',
			'Chatham',
			'Forsyth',
			'Lebanon',
			'Pawnee',
			'Pinckneyville',
			'Rosewood Heights',
			'Shelbyville',
			'Upper Alton',
			'Valmeyer',
			'Vandalia',
			'Virden',
			'Chandler',
			'Cloverdale',
			'Evansville',
			'North Vernon',
			'Odon',
			'Rockport',
			'Inman',
			'Junction City',
			'Augusta',
			'Bardwell',
			'Claryville',
			'Crescent Springs',
			'Elk Creek',
			'Frankfort',
			'Hebron',
			'Jeffersontown',
			'Lakeside Park',
			'Leitchfield',
			'Nicholasville',
			'Owenton',
			'Richmond',
			'Ryland Heights',
			'Sandy Hook',
			'Shepherdsville',
			'Stearns',
			'Union',
			'Walton',
			'Whitley City',
			'Amelia',
			'Belle Chasse',
			'Boutte',
			'Central',
			'Clarks',
			'Duson',
			'Haynesville',
			'Milton',
			'Minden',
			'North Vacherie',
			'Village Saint George',
			'Aspen Hill',
			'Boonsboro',
			'Brunswick',
			'Cape Saint Claire',
			'Catonsville',
			'Chesapeake Beach',
			'Crownsville',
			'East Riverdale',
			'Emmitsburg',
			'Forest Heights',
			'Friendly',
			'Fruitland',
			'Hyattsville',
			'Mays Chapel',
			'Potomac Park',
			'White Marsh',
			'Cedar Hill',
			'Greenwood',
			'Hanley Hills',
			'Holts Summit',
			'Kennett',
			'Monroe City',
			'Mountain Grove',
			'New London',
			'Oakland',
			'Odessa',
			'Perryville',
			'Saint George',
			'Saint Robert',
			'Town and Country',
			'Velda Village',
			'Wardsville',
			'Columbus',
			'Flowood',
			'Laurel',
			'Morton',
			'Pass Christian',
			'Ripley',
			'Stonewall',
			'Aberdeen',
			'Castle Hayne',
			'Drexel',
			'Franklinville',
			'Green Level',
			'Hayesville',
			'Kill Devil Hills',
			'Newton',
			'Norlina',
			'Oak Ridge',
			'Pembroke',
			'Stedman',
			'Wallburg',
			'Brooklawn',
			'Amberley',
			'Devola',
			'Dunlap',
			'Etna',
			'Morrow',
			'Piketon',
			'Reading',
			'Shiloh',
			'Thornport',
			'Upper Arlington',
			'Ada',
			'Afton',
			'Altus',
			'Del City',
			'Goldsby',
			'Jenks',
			'Sand Springs',
			'Stilwell',
			'Wilson',
			'Wister',
			'Aldan',
			'East Lansdowne',
			'Woodlyn',
			'Forest Acres',
			'Hanahan',
			'Inman Mills',
			'Seneca',
			'Spartanburg',
			'Wellford',
			'Whitmire',
			'Gatlinburg',
			'Graysville',
			'Jamestown',
			'La Vergne',
			'LaFollette',
			'Lafayette',
			'Mason',
			'Mount Carmel',
			'Smyrna',
			'South Carthage',
			'Bandera',
			'Batesville',
			'Bedford',
			'Cooper',
			'Corinth',
			'Fair Oaks Ranch',
			'Groveton',
			'Humble',
			'Itasca',
			'Kingsland',
			'Lake Brownwood',
			'Little Elm',
			'Nolanville',
			'North Richland Hills',
			'Olivarez',
			'Palo Pinto',
			'Pearsall',
			'Porter Heights',
			'Poteet',
			'Rusk',
			'Saint Hedwig',
			'Sealy',
			'Springtown',
			'Stephenville',
			'The Hills',
			'Venus',
			'Von Ormy',
			'Yoakum',
			'Basye',
			'Bedford',
			'Buckingham',
			'Crozet',
			'Hurt',
			'Lexington',
			'Mechanicsville',
			'Nokesville',
			'Palmyra',
			'Pannill Fork',
			'Springville',
			'Stafford',
			'Sudley',
			'Brookhaven',
			'Chapmanville',
			'Grantsville',
			'Mallory',
			'Monongah',
			'Athens',
			'Ashland',
			'Enfield',
			'Glenville',
			'Naugatuck',
			'Seymour',
			'Coralville',
			'George',
			'New Sharon',
			'Dunlap',
			'Granville',
			'Hoffman Estates',
			'La Grange Park',
			'Lake Summerset',
			'Lower West Side',
			'Macomb',
			'Metamora',
			'Near North Side',
			'Palatine',
			'Robbins',
			'Rock Falls',
			'Rockdale',
			'Seneca',
			'Tremont',
			'Warsaw',
			'Washburn',
			'Western Springs',
			'Wood Dale',
			'Woodlawn',
			'Attica',
			'Bristol',
			'Gary',
			'Hamilton',
			'North Manchester',
			'South Bend',
			'Topeka',
			'Adams',
			'Amherst',
			'Ashby',
			'Dennis Port',
			'Forestdale',
			'Middleton',
			'Nahant',
			'Norwood',
			'Pinehurst',
			'Pittsfield',
			'Smith Mills',
			'Somerville',
			'South Ashburnham',
			'Stoughton',
			'Vineyard Haven',
			'Wareham Center',
			'Wendell',
			'West Dennis',
			'West Yarmouth',
			'Bethel',
			'Bradley',
			'Brewer',
			'Farmingdale',
			'Milo',
			'Palmyra',
			'Porter',
			'Troy',
			'Whitefield',
			'Canadian Lakes',
			'Carson City',
			'Forest Hills',
			'Fruitport',
			'Homer',
			'Ironwood',
			'Ishpeming',
			'New Haven',
			'Reese',
			'Scottville',
			'Swartz Creek',
			'Woodland Beach',
			'Wyandotte',
			'Adrian',
			'Blooming Prairie',
			'Byron',
			'Center City',
			'Chatfield',
			'Cold Spring',
			'Crookston',
			'Edina',
			'Hopkins',
			'Independence',
			'Janesville',
			'Lakeville',
			'Lewiston',
			'Melrose',
			'Mound',
			'Rochester',
			'Shorewood',
			'Worthington',
			'Bethany',
			'Terre Haute',
			'Park River',
			'Tekamah',
			'Contoocook',
			'New Castle',
			'Rochester',
			'Rye',
			'West Swanzey',
			'Alpine',
			'Colts Neck',
			'Highlands',
			'Lake Hopatcong',
			'Leisure Village East',
			'Martinsville',
			'New Brunswick',
			'North Haledon',
			'Short Hills',
			'West Belmar',
			'Airmont',
			'Angola',
			'Bayport',
			'Commack',
			'Congers',
			'Coram',
			'Croton-on-Hudson',
			'Dobbs Ferry',
			'East Amherst',
			'East Meadow',
			'Elma Center',
			'Elmhurst',
			'Elwood',
			'Gowanda',
			'Harris Hill',
			'Hyde Park',
			'Islandia',
			'Kew Gardens',
			'Lorenz Park',
			'McGraw',
			'New Dorp Beach',
			'New Brighton',
			'North Massapequa',
			'Northumberland',
			'Oakdale',
			'Philmont',
			'Pittsford',
			'Port Richmond',
			'Shenorock',
			'Smithtown',
			'Tremont',
			'Wakefield',
			'Wallkill',
			'West Hills',
			'Yorktown Heights',
			'Bolindale',
			'Continental',
			'Coshocton',
			'Elyria',
			'Glenville',
			'Lakeview',
			'Louisville',
			'Malvern',
			'Niles',
			'Roaming Shores',
			'Upper Sandusky',
			'Vermilion',
			'Wolfhurst',
			'Abington',
			'Beaver',
			'Belle Vernon',
			'Bellevue',
			'California',
			'Cheltenham',
			'Colonial Park',
			'Curtisville',
			'Dewart',
			'Dickson City',
			'Dublin',
			'Ebensburg',
			'Exton',
			'Flemington',
			'Hallam',
			'Hokendauqua',
			'Lancaster',
			'Mount Lebanon',
			'Oakwood',
			'Old Forge',
			'Paxtang',
			'Phoenixville',
			'Richland',
			'Rutherford',
			'Sanatoga',
			'Saw Creek',
			'Shamokin',
			'Stony Creek Mills',
			'Tipton',
			'Treasure Lake',
			'Wellsboro',
			'White Haven',
			'Woodbourne',
			'Wormleysburg',
			'Middlebury (village)',
			'Morrisville',
			'Williston',
			'Alma',
			'Aztalan',
			'Balsam Lake',
			// 'Berlin',
			'Brodhead',
			'Cooperstown',
			'Eagle Lake',
			'Glenwood City',
			'Holmen',
			'Hudson',
			'Little Round Lake',
			'Monona',
			'Mosinee',
			'Oregon',
			'Pittsfield',
			'Princeton',
			'Sussex',
			'Trempealeau',
			'Waunakee',
			'Waupun',
			'Collinsville',
			// 'Glendale',
			'Picture Rocks',
			'Pinetop-Lakeside',
			'Safford',
			'San Luis',
			'Springerville',
			'Tombstone',
			'Youngtown',
			'Belvedere',
			'Ben Lomond',
			'Brookdale',
			'Diamond Springs',
			'Dublin',
			'East Rancho Dominguez',
			'El Monte',
			'Emeryville',
			'Eucalyptus Hills',
			'Graton',
			'Guadalupe',
			'Lakewood',
			'Los Alamitos',
			'Marina del Rey',
			'Ontario',
			'Pacheco',
			'Pismo Beach',
			'Quartz Hill',
			'Rollingwood',
			'Rubidoux',
			'San Juan Capistrano',
			'Santee',
			'Templeton',
			'West Carson',
			'Willowbrook',
			'Acres Green',
			'Gleneagle',
			'Palmer Lake',
			'Hurley',
			'Lee Acres',
			'White Rock',
			'Moapa Valley',
			'Guymon',
			'Fort Stockton',
			'Hereford',
			'Merkel',
			'Olton',
			'Carbonville',
			'Ferron',
			'Monroe',
			'Beatty',
			'Alhambra',
			'Rathdrum',
			'Red Bluff',
			'Boulder',
			'Lyons',
			'American Falls',
			'Bonners Ferry',
			'Clancy',
			'Four Corners',
			'Elko',
			'Altamont',
			'Carlton',
			'Dayton',
			'Donald',
			'Green',
			'Millersburg',
			'Mount Angel',
			'Nyssa',
			'Roseburg',
			'The Dalles',
			'Tri-City',
			'Wilsonville',
			'Yamhill',
			'Timber Lake',
			'Garland',
			'Harrisville',
			'Layton',
			'Lehi',
			'West Haven',
			'Colville',
			'Desert Aire',
			'East Port Orchard',
			'Edgewood',
			'Enumclaw',
			'Fall City',
			'Fircrest',
			'Garrett',
			'Hoquiam',
			'Issaquah',
			'Lewisville',
			'Lofall',
			'McCleary',
			'Mountlake Terrace',
			'Ridgefield',
			'Selah',
			'Stevenson',
			'Tulalip Bay',
			'Wapato',
			'Evansville',
			'Mākaha Valley',
			'Village Park',
			'Cohoe',
			'New Fairview',
			'Dewey-Humboldt',
			'Silver Firs',
			'Verde Village',
			'Remsenburg-Speonk',
			'Fountainhead-Orchard Hills',
			'St. Charles',
			'Wright-Patterson AFB',
			'East Lake-Orient Park',
			'Fort Pierce South',
			'Greater Northdale',
			'Candler-McAfee',
			'Tyndall Air Force Base',
			'Bonadelle Ranchos-Madera Ranchos',
			'Casa de Oro-Mount Helix',
			'West Pleasant View',
			'Lakes',
			'Six Shooter Canyon',
			'Upper Pohatcong',
			'Fort Covington Hamlet',
			'Travis Ranch',
			'Larch Way',
			'West Hills',
			'Wallenpaupack Lake Estates',
			'Beaver Dam Lake',
			'Waterford',
			'Henderson',
			'Fort Hunt',
			'Paducah',
			'Clanton',
			'Fairfield',
			'Frisco City',
			'Greensboro',
			'Homewood',
			'Moulton',
			'Cave City',
			'Crossett',
			'Green Forest',
			'Greenbrier',
			'Pea Ridge',
			'Salem',
			'Sheridan',
			'Warren',
			'Wynne',
			'Shaw',
			'Long Neck',
			'Wilmington',
			'Belleair',
			'Buenaventura Lakes',
			'Crystal Lake',
			'Daytona Beach Shores',
			'Golden Glades',
			'Green Cove Springs',
			'Harlem Heights',
			'Hiland Park',
			'Inverness',
			'Kendale Lakes',
			'Melbourne Beach',
			'Ocean City',
			'Palm City',
			'Punta Rassa',
			'Scott Lake',
			'Seffner',
			'Silver Lake',
			'South Patrick Shores',
			'Starke',
			'Sugarmill Woods',
			'Temple Terrace',
			'Wabasso Beach',
			'Washington Park',
			'Adairsville',
			'Calhoun',
			'Edison',
			'Forest Park',
			'Hiawassee',
			'Powder Springs',
			'Preston',
			'Raoul',
			'Riverdale',
			'Thomson',
			'Thunderbolt',
			'Trenton',
			'Auburn',
			'Carmi',
			'Casey',
			'Elizabethtown',
			'Georgetown',
			'Harristown',
			'Mount Sterling',
			'Highland',
			'Osgood',
			'Rockville',
			'Shoals',
			'Atchison',
			'Basehor',
			'Cherryvale',
			'Erie',
			'Eureka',
			'Lenexa',
			'Neodesha',
			'Roeland Park',
			'Wamego',
			'Beattyville',
			'Elsmere',
			'Louisville',
			'Nortonville',
			'Pine Knot',
			'Bayou Gauche',
			'Benton',
			'Bridge City',
			'Brownsville',
			'Chalmette',
			'Lake Providence',
			'Leesville',
			'Mamou',
			'Montz',
			'New Sarpy',
			'Norco',
			'Oakdale',
			'Saint Francisville',
			'Schriever',
			'West Monroe',
			'Cambridge',
			'Fairmount Heights',
			'Hagerstown',
			'Owings',
			'Smithsburg',
			'Benton',
			'Bloomfield',
			'Breckenridge Hills',
			'Calverton Park',
			'Caruthersville',
			'Frontenac',
			'Gower',
			'Greenfield',
			'Oak Grove',
			'Saint Martins',
			'Scott City',
			'Senath',
			'Caledonia',
			'Carriere',
			'Goodman',
			'Philadelphia',
			'Poplarville',
			'Vicksburg',
			'Eastover',
			'Erwin',
			'Flat Rock',
			'Kure Beach',
			'Lake Park',
			'Lowesville',
			'Marion',
			'Maxton',
			'Mount Olive',
			'Newport',
			'Oakboro',
			'Rockfish',
			'Sea Breeze',
			'Silver Lake',
			'Southern Pines',
			'Southport',
			'Tyro',
			'Welcome',
			'Winterville',
			'Pemberton Heights',
			'Sea Isle City',
			'Villas',
			'Wenonah',
			'Harbor Hills',
			'Hebron',
			'Highpoint',
			'North College Hill',
			'Riverside',
			'Shawnee Hills',
			'Washington Court House',
			'Waynesville',
			'Cache',
			'Checotah',
			'Cherokee',
			'Granite',
			// 'Hobart',
			'Oakhurst',
			'Slaughterville',
			'Union City',
			'Wilburton',
			'Abbottstown',
			'West Chester',
			'Denmark',
			'Gaffney',
			'Lake Secession',
			'New Ellenton',
			'Valley Falls',
			'Altamont',
			'Chapel Hill',
			'Collierville',
			'Cookeville',
			'Greenfield',
			'Linden',
			'Luttrell',
			'Monterey',
			'Mosheim',
			'Sparta',
			'Spencer',
			'Surgoinsville',
			'Union City',
			'Walden',
			'Waynesboro',
			'Alton North (historical)',
			'Alvin',
			'Blossom',
			'Brady',
			'Collinsville',
			'Grapevine',
			'Heath',
			'Kirby',
			'Lago Vista',
			'Little River-Academy',
			'Lytle',
			'Manchaca',
			'Mission Bend',
			'Scissors',
			'Sheldon',
			'Stowell',
			'Zapata',
			'Aquia Harbour',
			'Boydton',
			'Bracey',
			'Buckhall',
			'Coeburn',
			'Dahlgren',
			'Fredericksburg',
			'Great Falls',
			'Heathsville',
			'Henry Fork',
			'Kenbridge',
			'Pimmit Hills',
			'Salem',
			'Timberville',
			'West Lynchburg',
			'Craigsville',
			'Despard',
			'Gilbert Creek',
			'Middlebourne',
			'Rainelle',
			'Mystic',
			'Sherwood Manor',
			'Waterbury',
			'Woodbridge',
			'Chariton',
			'Cherokee',
			'Clarksville',
			'Gilbert',
			'Glidden',
			'Kingsley',
			'Manning',
			'Marion',
			'New London',
			'Oskaloosa',
			'Pleasantville',
			'Sanborn',
			'Sheldon',
			'Spirit Lake',
			'Waukee',
			'Bannockburn',
			'Cortland',
			'Crest Hill',
			'Cuba',
			'Hanna City',
			'Hazel Crest',
			'Homer',
			'Homewood',
			'Hudson',
			'Indian Head Park',
			'Lake Barrington',
			'Lake of the Woods',
			'Markham',
			'Minonk',
			'North Pekin',
			'Orion',
			'Poplar Grove',
			'River Forest',
			'Rockton',
			'Round Lake Heights',
			'Sandwich',
			'Stickney',
			'Toluca',
			'Watseka',
			'Dunkirk',
			'East Chicago',
			'Jonesboro',
			'Lebanon',
			'Parker City',
			'Orleans',
			'Speedway',
			'Assonet',
			'Colrain',
			'Granby',
			'Great Barrington',
			'Lee',
			'Leicester',
			'Littleton Common',
			'Mansfield',
			'Medway',
			'Mendon',
			'Norfolk',
			'Wellesley',
			'West Wareham',
			'Bradford',
			'Bucksport',
			'Castine',
			'Clinton',
			'Frenchville',
			'Mexico',
			'Peru',
			'Surry',
			'Woolwich',
			'Adrian',
			'Almont',
			'Argentine',
			'Blissfield',
			'Breckenridge',
			'Eastpointe',
			'Farmington',
			'Ferrysburg',
			'Holt',
			'Jackson',
			'Lapeer',
			'Litchfield',
			'Manchester',
			'Reed City',
			'Saginaw',
			'Spring Arbor',
			'Saint Clair Shores',
			'Sterling Heights',
			'Westwood',
			'Aitkin',
			'Bayport',
			'Chisholm',
			'Duluth',
			'Grand Rapids',
			'Mahnomen',
			'Milaca',
			'Minnetonka Mills',
			'Pelican Rapids',
			'Tonka Bay',
			'Tarkio',
			'Alma',
			'Cozad',
			'Springfield',
			'Barnstead',
			'Goffstown',
			'Hampton Beach',
			'Hanover',
			'Pelham',
			'South Hooksett',
			'Demarest',
			'Denville',
			'East Brunswick',
			'Edgewater',
			'Fair Lawn',
			'Hightstown',
			'Hopatcong',
			'Lincoln Park',
			'Mahwah',
			'Milford',
			'Montvale',
			'North Caldwell',
			'Port Monmouth',
			'Prospect Park',
			'Adams Center',
			'Addison',
			'Apalachin',
			'Arverne',
			'Augusta',
			'Avon',
			'Blasdell',
			'Brocton',
			'Brooklyn',
			'Calverton',
			'Clintondale',
			'Cold Spring Harbor',
			'Dolgeville',
			'East Rochester',
			'East Syracuse',
			'Eltingville',
			'Fort Montgomery',
			'Garden City',
			'Glenwood Landing',
			'Maspeth',
			'Mattituck',
			'Monsey',
			'Port Ewen',
			'Saint Johnsville',
			'Shortsville',
			'Steinway',
			'Stony Brook',
			'Tonawanda',
			'Washingtonville',
			'Wolcott',
			'Yaphank',
			'Beechwood Trails',
			'Berea',
			'Bowling Green',
			'Fremont',
			'Granville',
			'Jefferson',
			'Mansfield',
			'Mantua',
			'Perrysburg',
			'Struthers',
			'Ancient Oaks',
			'Baidland',
			'Beaver Falls',
			'Blawnox',
			'Canonsburg',
			'Cochranton',
			'Columbia',
			'Elkins Park',
			'Freemansburg',
			'Harleysville',
			'Hershey',
			'Lemont',
			'Levittown',
			'Ligonier',
			'Mechanicsburg',
			'Mifflintown',
			'Mount Carmel',
			'Pen Argyl',
			'Plymouth Meeting',
			'Red Hill',
			'Seven Fields',
			'Sharpsburg',
			'Shippensburg',
			'Valley Green',
			'Williamsburg',
			'Woxall',
			'West Warwick',
			'Lennox',
			'Leola',
			'Springfield',
			'Swanton',
			'Athens',
			'Cameron',
			'Dousman',
			'Fontana',
			'Lake Ripley',
			'Montello',
			'New Richmond',
			'Osceola',
			'Silver Lake',
			'Tomahawk',
			'Waterford',
			'Weston',
			'Wisconsin Rapids',
			'Wrightstown',
			'Kohler',
			'Carefree',
			'Gila Bend',
			'Lake of the Woods',
			'Mescal',
			'Parks',
			'Somerton',
			'Atascadero',
			'Berkeley',
			'Blackhawk',
			'Castro Valley',
			'Cedar Ridge',
			'Chino',
			'Del Mar',
			'Discovery Bay',
			'East Hemet',
			'Esparto',
			'French Camp',
			'Gardena',
			'Glen Avon',
			'Hartley',
			'Home Garden',
			'Ivanhoe',
			'Kennedy',
			'Kernville',
			'Lathrop',
			'Los Altos Hills',
			'Loyola',
			'Meadow Vista',
			'Meiners Oaks',
			'Niland',
			'Orange Cove',
			'Palermo',
			'Piñon Hills',
			'Pleasant Hill',
			'Quincy',
			'Rainbow',
			'Riverdale Park',
			'Rowland Heights',
			'Sacramento',
			'San Bruno',
			'San Jacinto',
			'San Lorenzo',
			'San Pasqual',
			'Santa Venetia',
			'Shadow Hills',
			'Soda Bay',
			'Van Nuys',
			'Wilton',
			'Yucca Valley',
			'Battlement Mesa',
			'Crested Butte',
			'Pagosa Springs',
			'Palisade',
			'Saguache',
			'Sheridan',
			'Snowmass Village',
			'Todd Creek',
			'Garden City',
			'Hoxie',
			'Syracuse',
			'Tribune',
			'Flora Vista',
			'Ruidoso',
			'San Miguel',
			'Thoreau',
			'Ely',
			'McGill',
			'Garden City',
			'Gardendale',
			'Hart',
			'Rankin',
			'San Elizario',
			'Shallowater',
			'Delta',
			'Ivins',
			'Centerfield',
			'Anderson',
			'Arcata',
			'Cutten',
			'Los Molinos',
			'Berthoud',
			'Estes Park',
			'Firestone',
			'Fort Morgan',
			'Boise',
			'Star',
			'Columbia Falls',
			'Philipsburg',
			'Ronan',
			'Hettinger',
			'Grant',
			'Lovelock',
			'Aumsville',
			'Beavercreek',
			'Dallas',
			'Happy Valley',
			'Klamath Falls',
			'Martin',
			'Granite',
			'Mendon',
			'South Weber',
			'West Valley City',
			'Ames Lake',
			'Bridgeport',
			'Burbank',
			'Cosmopolis',
			'Deer Park',
			'Eastgate',
			'Elk Plain',
			'Forks',
			'Key Center',
			'Long Beach',
			'Soap Lake',
			'Spokane Valley',
			'Town and Country',
			'Wauna',
			'Woodinville',
			'Afton',
			'Bar Nunn',
			'Hoback',
			'Sleepy Hollow',
			'Kaunakakai',
			'Lawai',
			'Wailua',
			'Anahola',
			'Elko New Market',
			'City of Milford (balance)',
			'High Point',
			'Chester Center',
			'Oxon Hill-Glassmanor',
			'Pymatuning Central',
			'Bellair-Meadowbrook Terrace',
			'Cocoa West',
			'Zephyrhills North',
			'Lake Murray of Richland',
			'Village of Oak Creek (Big Park)',
			'Bryn Mawr-Skyway',
			'Camp Pendleton North',
			'Challenge-Brownsville',
			'McChord Air Force Base',
			'Walla Walla East',
			'Whetstone',
			'Tamalpais-Homestead Valley',
			'Emerald Mountain',
			'Avenue B and C',
			'Connerton',
			'Penn Estates',
			'Oak Hill',
			'Greater Grand Crossing',
			'Mount Pleasant',
			'Buckhorn',
			'Martinsville',
			'Petersburg',
			'Independence',
			'Washington',
			'Barbourmeade',
			'Lebanon',
			'Murray',
			'Stanton',
			'Taylorsville',
			'Verona',
			'Amite',
			'Bayou Vista',
			'Claiborne',
			'Covington',
			'DeRidder',
			'Estelle',
			'Gardere',
			'Gray',
			'Haughton',
			'Iowa',
			'Mansfield',
			'New Llano',
			'Opelousas',
			'Welsh',
			'Braddock Heights',
			'Cavetown',
			'Colesville',
			'Cumberland',
			'Dundalk',
			'Jarrettsville',
			'Kingsville',
			'Leonardtown',
			'New Windsor',
			'North Bethesda',
			'Pleasant Hills',
			'Riviera Beach',
			'Westernport',
			'Carterville',
			'Centerville',
			'Crystal City',
			'Hayti',
			'Hazelwood',
			'Ironton',
			'Mansfield',
			'Osceola',
			'Republic',
			'Richland',
			'Riverside',
			'Rolla',
			'St. Louis',
			'Stockton',
			'Tuscumbia',
			'Versailles',
			'Winfield',
			'Cleveland',
			'Como',
			'Florence',
			'Hollandale',
			'Richton',
			'Summit',
			'Union',
			'Boiling Springs',
			'Brunswick',
			'Burnsville',
			'Clyde',
			'Dallas',
			'Foscoe',
			'Leland',
			'Seagate',
			'South Henderson',
			'Wilmington',
			'Barrington',
			'Carneys Point',
			'Fairton',
			'Magnolia',
			'National Park',
			'Sicklerville',
			'South Toms River',
			'Victory Lakes',
			'Whitesboro',
			'Williamstown',
			'Athens',
			'Buckeye Lake',
			'Chauncey',
			'Cleves',
			'Dillonvale',
			'Fruit Hill',
			'North Fork Village',
			'Springfield',
			'Union',
			'Elk City',
			'Oakland',
			'Stratford',
			'Yale',
			'Clifton Heights',
			'Oxford',
			'Beaufort',
			'Bennettsville',
			'Buffalo',
			'Catawba',
			'Five Forks',
			'Oak Grove',
			'Piedmont',
			'Ardmore',
			'Brighton',
			'Bristol',
			'Erin',
			'Gray',
			'Henderson',
			'Kimball',
			'Lawrenceburg',
			'Lone Oak',
			'Lynchburg',
			'Medina',
			'Oneida',
			'Ridgely',
			'Selmer',
			'South Pittsburg',
			'Wildwood',
			'Aledo',
			'Beach City',
			'Blanco',
			'Brownwood',
			'Bryan',
			'China',
			'Clear Lake Shores',
			'Corrigan',
			'Eagle Lake',
			'Farmersville',
			'Flower Mound',
			'Horseshoe Bay',
			'Hudson',
			'Johnson City',
			'Kennedale',
			'Lake Dallas',
			'Lorena',
			'Marlin',
			'Mauriceville',
			'Melissa',
			'Missouri City',
			'Montague',
			'Murphy',
			'Nash',
			'Nederland',
			'Onion Creek',
			'Orange Grove',
			'Pantego',
			'Pearland',
			'Pelican Bay',
			'Pleasanton',
			'Poth',
			'Quitman',
			'Richardson',
			'Santa Fe',
			'Seadrift',
			'Shady Hollow',
			'Sour Lake',
			'Sweeny',
			'Troup',
			'Vernon',
			'Waskom',
			'West Lake Hills',
			'Woodsboro',
			'Appalachia',
			'Belle Haven',
			'Cana',
			'Chesterfield',
			'Dinwiddie',
			'Glen Allen',
			'Gloucester Courthouse',
			'Gordonsville',
			'King William',
			'Laymantown',
			'Lebanon',
			'Louisa',
			'Lovettsville',
			'Pembroke',
			'Rustburg',
			'Saluda',
			'Smithfield',
			'Stanardsville',
			'Timberlake',
			'Wattsville',
			'Kenova',
			'Point Pleasant',
			'Richwood',
			'Stanaford',
			'Farmington',
			'Newington',
			'Norwich',
			'Terryville',
			'Thompson',
			'West Torrington',
			'Windham',
			'Alta',
			'Altoona',
			'Baxter',
			'Colfax',
			'Columbus Junction',
			'Dallas Center',
			'Farley',
			'Hiawatha',
			'Johnston',
			'North English',
			'Ottumwa',
			'Preston',
			'Darien',
			'Glasford',
			'Glenwood',
			'Havana',
			'Island Lake',
			'New Lenox',
			'Oakbrook Terrace',
			'Rock Island',
			'Tower Lake',
			'Wasco',
			'Kokomo',
			'Ligonier',
			'Monroeville',
			'Wabash',
			'Leeds',
			'Mount Vernon',
			'Newton',
			'Owens Cross Roads',
			'Stewartville',
			'Town Creek',
			'Vestavia Hills',
			'Vincent',
			'West Blocton',
			'Judsonia',
			'Mount Ida',
			'Lewes',
			'Milford',
			'Broadview Park',
			'Cooper City',
			'Dade City',
			'Elfers',
			'Fernandina Beach',
			'Haines City',
			'Holiday',
			'Homosassa',
			'Inwood',
			'Jan-Phyl Village',
			'Lake Hamilton',
			'Macclenny',
			'Mary Esther',
			'Mayo',
			'Naranja',
			'Perry',
			'Progress Village',
			'Saint Augustine Shores',
			'Satellite Beach',
			'South Pasadena',
			'Wallace',
			'West Melbourne',
			'White City',
			'Willow Oak',
			'Acworth',
			'Ashburn',
			'Bloomingdale',
			'College Park',
			'Columbus',
			'Douglasville',
			'Ellaville',
			'Euharlee',
			'Forsyth',
			'Grantville',
			'Irondale',
			'Lakeland',
			'Maysville',
			'Millen',
			'Mount Airy',
			'Nashville',
			'Nicholls',
			'Reynolds',
			'Shannon',
			'Tyrone',
			'Willacoochee',
			'Alton',
			'Blue Mound',
			'Columbia',
			'Greenup',
			'Harrisburg',
			'Ina',
			'Madison',
			'McLeansboro',
			'Ramsey',
			'Springfield',
			'Teutopolis',
			'Brownsburg',
			'Huntingburg',
			'New Castle',
			'Plainfield',
			'Warren Park',
			'Enterprise',
			'Level Plains',
			'Locust Fork',
			'Oxford',
			'Riverside',
			'Roanoke',
			'Arkansas City',
			'De Queen',
			'Fort Smith',
			'Hoxie',
			'Kensett',
			'Lepanto',
			'Mineral Springs',
			'Parkin',
			'Rockwell',
			'Shannon Hills',
			'Star City',
			'West Memphis',
			'Pike Creek',
			'Townsend',
			'Arcadia',
			'Bunche Park',
			'Crestview',
			'Davie',
			'East Lake',
			'Fort Myers',
			'Gateway',
			'Hypoluxo',
			'Jasper',
			'Lake Belvedere Estates',
			'Live Oak',
			'Miami Beach',
			'Palatka',
			'Point Baker',
			'Port Saint Lucie',
			'San Carlos Park',
			'Silver Springs',
			'South Gate Ridge',
			'Tallahassee',
			'Tierra Verde',
			'Venice Gardens',
			'Warm Mineral Springs',
			'Wewahitchka',
			'Zellwood',
			'Baxley',
			// 'Boston',
			'Carrollton',
			'Country Club Estates',
			'Experiment',
			'Gainesville',
			'Grovetown',
			'Hahira',
			'Jefferson',
			'Lexington',
			'Milledgeville',
			'Mount Zion',
			'Newton',
			'Oakwood',
			'Pine Mountain',
			'Putney',
			'Russell',
			'Stockbridge',
			'Unionville',
			'Wilmington Island',
			'Decatur',
			'Marissa',
			'Marshall',
			'Meredosia',
			'Quincy',
			'Worden',
			'Boonville',
			'Indianapolis',
			'Richmond',
			'Salem',
			'Scottsburg',
			'Shelburn',
			'Whitestown',
			'Alma',
			'Andover',
			'Anthony',
			'Augusta',
			'Baldwin City',
			'Bellaire',
			'Beloit',
			'Caney',
			'Ogden',
			'Pittsburg',
			'Salina',
			'Yates Center',
			'Central City',
			'Independence',
			// 'London',
			'Marion',
			'Mount Sterling',
			'Paintsville',
			'Versailles',
			'Wurtland',
			'Baldwin',
			'Carlyss',
			'Cut Off',
			'Franklinton',
			'Harrisonburg',
			'Harvey',
			'Jean Lafitte',
			'Lakeshore',
			'Port Sulphur',
			'White Castle',
			'Arden on the Severn',
			'Forestville',
			'Golden Beach',
			'Hampton',
			'Hillcrest Heights',
			'Hillsmere Shores',
			'Randallstown',
			'Rosaryville',
			'Walkersville',
			'Albany',
			'Bel-Nor',
			'Bel-Ridge',
			'Bismarck',
			'Bonne Terre',
			'Claycomo',
			'Kissee Mills',
			'La Monte',
			'Lawson',
			'Lemay',
			'Lilbourn',
			'Maplewood',
			'Neosho',
			'Oronogo',
			'Saint Charles',
			'Vandalia',
			'Velda Village Hills',
			'West Plains',
			'Baldwyn',
			'Brandon',
			'Charleston',
			'Cleary',
			'Hernando',
			'Albemarle',
			'Archdale',
			'Biscoe',
			'Carolina Shores',
			'Danbury',
			'Four Oaks',
			'Gorman',
			'Hamlet',
			'Liberty',
			'Myrtle Grove',
			'Nags Head',
			'Norwood',
			'Atco',
			'Beach Haven West',
			'Camden',
			'Dover Beaches North',
			'Ellisburg',
			'Maple Shade',
			'Somers Point',
			'Toms River',
			'Woodbine',
			'Brookville',
			'Enon',
			// 'Glendale',
			'Groesbeck',
			'Hunter',
			'Mack',
			'McArthur',
			'Northridge',
			'Pleasant Run Farm',
			'West Milton',
			'Wilmington',
			'Durant',
			'Krebs',
			'Mounds',
			'Purcell',
			'Ringling',
			'Wewoka',
			'Brookhaven',
			'Christiana',
			'Meyersdale',
			'Quarryville',
			'West York',
			'Boiling Springs',
			'Chesterfield',
			'Greenwood',
			'Honea Path',
			'Kingstree',
			'Latta',
			'Leesville',
			'Marion',
			'Orangeburg',
			'Stateburg',
			'Varnville',
			'Centerville',
			'Halls',
			'Jasper',
			'Rocky Top',
			'Millington',
			'Mount Juliet',
			'New Tazewell',
			'Newbern',
			'Pine Crest',
			'Tullahoma',
			'Walnut Hill',
			'Whitwell',
			'Anna',
			'Argyle',
			'Citrus City',
			'Corsicana',
			'Crowell',
			'Crystal City',
			'Denton',
			'El Campo',
			'Grand Saline',
			'Heidelberg',
			'Hilltop Lakes',
			'Hunters Creek Village',
			'Italy',
			'La Joya',
			'Luling',
			'Meadowlakes',
			'Mineola',
			'Mission',
			'Nixon',
			'Nocona',
			'Oyster Creek',
			'Palestine',
			'Princeton',
			'Refugio',
			'San Leon',
			'Sanger',
			'Seven Points',
			'Seymour',
			'Shavano Park',
			'Southmayd',
			'Texarkana',
			'Weimar',
			'West Tawakoni',
			'Wilmer',
			'Windemere',
			'Wylie',
			'Abingdon',
			'Bon Air',
			'Brightwood',
			'Central Garage',
			'Cloverdale',
			'Colonial Heights',
			'Dranesville',
			'Harrisonburg',
			'Idylwood',
			'Lake Barcroft',
			'Merrimac',
			'Newington',
			'Potomac Mills',
			'Saltville',
			'Triangle',
			'Weber City',
			'Berkeley Springs',
			'Chesapeake',
			'Kingwood',
			'Marlinton',
			'Romney',
			'Saint Albans',
			'Shannondale',
			'Stonewood',
			'Rosedale',
			'Ballplay',
			'Revere',
			'Watervliet',
			'Fairfield',
			'Hebron',
			'Moodus',
			'New Britain',
			'North Branford',
			'North Stamford',
			'Willimantic',
			'Woodmont',
			'Adel',
			'Ankeny',
			'Dike',
			'Ida Grove',
			'Indianola',
			'Mapleton',
			'Oakland',
			'Story City',
			'Ashburn',
			'Cambridge',
			'Carpentersville',
			'Douglas',
			'Earlville',
			'East Garfield Park',
			'Eureka',
			'Evergreen Park',
			'Fairmont',
			'Flossmoor',
			'Galesburg',
			'Green Rock',
			'Hampton',
			'Harvard',
			'Highwood',
			'Lacon',
			'Lake Forest',
			'Lakewood',
			'Morrison',
			'Mundelein',
			'Peoria Heights',
			'Philo',
			'Polo',
			'Princeville',
			'Rushville',
			'Spring Valley',
			'Villa Park',
			'Wadsworth',
			'Willow Springs',
			'Eaton',
			'Georgetown',
			'Goodland',
			'Indian Heights',
			'Monon',
			'Schererville',
			'Veedersburg',
			'Wakarusa',
			'Williamsport',
			// 'Berlin',
			'Chelmsford',
			'Dighton',
			'Hopkinton',
			'Kingston',
			'North Pembroke',
			'Saugus',
			'Cape Neddick',
			'Cushing',
			'Eddington',
			'Lovell',
			'Palermo',
			'Portland',
			'South Windham',
			'Wayne',
			'Albion',
			'Au Sable',
			'Bath',
			'Battle Creek',
			'Bay City',
			'Burt',
			'Capac',
			'Coopersville',
			'Hancock',
			'Hemlock',
			'Howard City',
			'Laurium',
			'Negaunee',
			'Petoskey',
			'Saline',
			'South Haven',
			'Traverse City',
			'Waterford',
			'Wayland',
			'Afton',
			'Benson',
			'Chaska',
			'Coleraine',
			'Dellwood',
			'Edgerton',
			'Lindstrom',
			'Madison Lake',
			'Medina',
			'Parkers Prairie',
			'Springfield',
			'Stillwater',
			'Lancaster',
			'Lakota',
			'Lexington',
			'Oakland',
			'Epping',
			'Franklin',
			'Jaffrey',
			'Keene',
			'Marlborough',
			'Mason',
			'Basking Ridge',
			'Burlington',
			'Linden',
			'North Arlington',
			'Orange',
			'Pine Lake Park',
			'Sayreville',
			'Singac',
			'Ten Mile Run',
			'Woodland Park',
			'Adams',
			'Arrochar',
			'Champlain',
			'Chittenango',
			'Churchville',
			'Cuba',
			'Dryden',
			'East Garden City',
			'Elizabethtown',
			'Emerson Hill',
			'Fonda',
			'Garden City Park',
			'Green Island',
			'Hoosick Falls',
			'Keuka Park',
			'Kirkville',
			'Locust Valley',
			'Manhasset Hills',
			'Mexico',
			'Montebello',
			'Munsons Corners',
			'Niverville',
			'Ossining',
			'Pawling',
			'Rensselaer',
			'Southold',
			'Wampsville',
			'Andover',
			'Chesterland',
			'Fayette',
			'Howland Center',
			'Leavittsburg',
			'Lodi',
			'Marion',
			'Mayfield',
			'Middlefield',
			'Perry Heights',
			'Plymouth',
			'Seville',
			'Solon',
			'Tiffin',
			'Walbridge',
			'Willard',
			'Windham',
			'Baden',
			'Birchwood Lakes',
			'Blairsville',
			'Clarion',
			'Cornwall',
			'Cornwells Heights',
			'Coudersport',
			'Cresco',
			'Cressona',
			'Duboistown',
			'East Stroudsburg',
			'Edwardsville',
			'Forty Fort',
			'Freedom',
			'Freeport',
			'Homer City',
			'Hughesville',
			'Malvern',
			'Mount Union',
			'North Wales',
			'Parkside',
			'Progress',
			'Rochester',
			'Slatington',
			'Tinicum',
			'Wescosville',
			'West View',
			'Williamstown',
			'Wyomissing Hills',
			'Foster',
			'Madison',
			'Chelsea',
			'Moretown',
			'Allouez',
			'Cashton',
			'Cudahy',
			'Easton',
			'Franklin',
			'Frederic',
			'North Fond du Lac',
			'Palmyra',
			'Pell Lake',
			'Potter Lake',
			'Richland Center',
			'Rothschild',
			'Saukville',
			'Sun Prairie',
			'Twin Lakes',
			'West Baraboo',
			'Wheeling',
			'Bloomfield',
			'Bristol',
			'Holbrook',
			'Middletown',
			'Mona',
			'Chandler',
			'Cordes Lakes',
			'Dilkon',
			'Maryvale',
			'Show Low',
			'Summit',
			'Tubac',
			'Ashland',
			'Borrego Springs',
			'Burbank',
			'Clearlake Oaks',
			'Concord',
			'Copperopolis',
			'Covelo',
			'Del Aire',
			'Delhi',
			'East Palo Alto',
			'Felton',
			'Forestville',
			'Hillsborough',
			'Knightsen',
			'Lake Forest',
			'Lamont',
			'Lone Pine',
			'Los Angeles',
			'Maywood',
			'Midpines',
			'Monterey Park',
			'National City',
			'Nice',
			'Oasis',
			'Orange',
			'Pasadena',
			'Pittsburg',
			'Porterville',
			'Redlands',
			'Sebastopol',
			'South Pasadena',
			'Sunnyside',
			'Sunnyslope',
			'Taft Heights',
			'Tuolumne City',
			'North Tustin',
			'Walnut Creek',
			'Weedpatch',
			'West Park',
			'Wofford Heights',
			'Yucaipa',
			'Monte Vista',
			'Nederland',
			'Penrose',
			'Southglenn',
			'Towaoc',
			'Sharon Springs',
			'Gallup',
			'Los Alamos',
			'Navajo',
			'Sandia Heights',
			'Santa Rosa',
			'Sun Valley',
			'Canadian',
			'Colorado City',
			'Fort Hancock',
			'Sparks',
			'Huntington',
			'Loa',
			'Apache Junction',
			'Avra Valley',
			'Wellsville',
			'Blue Lake',
			'Gerber',
			'Holyoke',
			'Lincoln',
			'Deer Lodge',
			'Lakeside',
			'Center',
			'Medora',
			'Aloha',
			'Brownsville',
			'Enterprise',
			'Forest Grove',
			'Island City',
			'Redwood',
			'Rose Lodge',
			'Sandy',
			'McIntosh',
			'North Spearfish',
			'Kamas',
			'Manila',
			'Summit Park',
			'Artondale',
			'Buckley',
			'Carnation',
			'DuPont',
			'Lake Stevens',
			'Maplewood',
			'Mattawa',
			'Meadow Glade',
			'Minnehaha',
			'Mount Vernon',
			'Newcastle',
			'Prairie Ridge',
			'South Bend',
			'Summit',
			'Wilburton',
			'Gillette',
			'Jackson',
			'Lihue',
			'North Castle',
			'Central Waterford',
			'Fairview-Ferndale',
			'Moorestown-Lenola',
			'North Ballston Spa',
			'Quantico Station',
			'Greater Upper Marlboro',
			'Whitesboro-Burleigh',
			'K. I. Sawyer Air Force Base',
			'Fetters Hot Springs-Agua Caliente',
			'La Crescenta-Montrose',
			'Westhaven-Moonstone',
			'Fishhook',
			'Heritage Pines',
			'Ocean Pointe',
			'LaBarque Creek',
			'East Valley',
			'East Franklin',
			'Detroit-Shoreway',
			'Eastvale',
			'Visitacion Valley',
			'Roma',
			'Laurel Hill',
			'Pryor Creek',
			'Carls Jr',
			'Ashland',
			'Chatham',
			'Clinton',
			'East Brookfield',
			'East Dennis',
			'Edgartown',
			'Head of Westport',
			'Leverett',
			'Lunenburg',
			'Medfield',
			'Princeton',
			'Rockland',
			'Rowley',
			'Scituate',
			'Wakefield',
			'Fairfield',
			'Gardiner',
			'Greenville',
			'Hudson',
			'Jefferson',
			'Jonesport',
			'Lewiston',
			'New Sharon',
			'Northport',
			'Stonington',
			'Chelsea',
			'Decatur',
			'Douglas',
			'Grosse Pointe Park',
			'Harrisville',
			'Rochester Hills',
			'Saint Joseph',
			'Saint Louis',
			'West Monroe',
			'Wyoming',
			'Chisago City',
			'Cohasset',
			'Granite Falls',
			'Madelia',
			'Minneapolis',
			'Montrose',
			'Mora',
			'Oronoco',
			'Wadena',
			'Kirksville',
			'Monticello',
			'Forman',
			'Larimore',
			'Thompson',
			'Ainsworth',
			'Greeley',
			'Ponca',
			'Wood River',
			'Deerfield',
			'Gorham',
			'Grantham',
			'Laconia',
			'Lancaster',
			'Unity',
			'Woodstock',
			'Belleville',
			'Boonton',
			'Brownville',
			'Cresskill',
			'Emerson',
			'Englewood Cliffs',
			'Franklin Park',
			'Kinnelon',
			'Lake Hiawatha',
			'Long Branch',
			'Tenafly',
			'Winfield',
			'Averill Park',
			'Brewster Hill',
			'Clifton Park',
			'Delhi',
			'Delmar',
			'Edinburg',
			'Garden City South',
			'Glens Falls',
			'Hamilton',
			'Hillside',
			'Kaser',
			'Lincolndale',
			'Lindley',
			'New York City',
			'Oneonta',
			'Poestenkill',
			'Pomona',
			'Rosebank',
			'Scottsville',
			'Sea Cliff',
			'South Farmingdale',
			'Springs',
			'University Heights',
			'Verplanck',
			'Walton Park',
			'Warsaw',
			'West Albany',
			'West Haverstraw',
			'Woodmere',
			'Ada',
			'Barberton',
			'Cadiz',
			'Delphos',
			'Elmore',
			'Grafton',
			'Hicksville',
			'Kent',
			'Luckey',
			'Orrville',
			'Pandora',
			'Tallmadge',
			'West Unity',
			'Albion',
			'Bradford',
			'Catasauqua',
			'Cementon',
			'Dupont',
			'Emmaus',
			'Factoryville',
			'Fairview',
			'Gastonville',
			'Greenville',
			'Harrisburg',
			'Hatboro',
			'Highspire',
			'Hiller',
			'Kulpsville',
			'Lakemont',
			'Lawnton',
			'Lawrence Park',
			'Lititz',
			'Mansfield',
			'Mountville',
			'New Kensington',
			'Newtown Grant',
			'North Catasauqua',
			'Oakmont',
			'Paoli',
			'Pleasant Hill',
			'Riverside',
			'Saint Lawrence',
			'Sandy',
			'Speers',
			'Springdale',
			'Temple',
			'West Hazleton',
			'Westmont',
			'Wilkinsburg',
			'North Scituate',
			'Fort Thompson',
			'Miller',
			'Woonsocket',
			'Hardwick',
			'Mount Holly',
			'South Burlington',
			'Amery',
			'Brown Deer',
			'Cambridge',
			'Durand',
			'Elroy',
			// 'Glendale',
			'Marshfield',
			'Mount Morris',
			'Oconomowoc',
			'Oconto',
			'Sheboygan',
			'Sturgeon Bay',
			'Groton',
			'Sheffield',
			'Castleton',
			'Casa Blanca',
			'Casas Adobes',
			'Deer Valley',
			'Desert Hills',
			'Grand Canyon',
			'New River',
			'Brawley',
			'Capitola',
			'Cerritos',
			'Channel Islands Beach',
			'Daly City',
			'Del Rio',
			'Descanso',
			'East Oakdale',
			'East Quincy',
			'East San Gabriel',
			'Escalon',
			'Goleta',
			'Goshen',
			'Hawaiian Gardens',
			'Irwindale',
			'La Habra',
			'La Presa',
			'Leona Valley',
			'Los Olivos',
			'Martinez',
			'Mecca',
			'Mission Viejo',
			'Napa',
			'Oakley',
			'Oildale',
			'Rodeo',
			'Santa Maria',
			'Seacliff',
			'Sorrento Valley',
			'South Whittier',
			'Topanga',
			'Woodacre',
			'Broomfield',
			'Golden',
			'Limon',
			'Westcliffe',
			'Atoka',
			'Bloomfield',
			'Clovis',
			'Kirtland',
			'Carson City',
			'Dayton',
			'Canutillo',
			'Odessa',
			'Price',
			'Wasco',
			'Salem',
			'McKinleyville',
			'Hot Sulphur Springs',
			'Kersey',
			'Windsor',
			'Glenns Ferry',
			'Lewiston Orchards',
			'Tyhee',
			'East Missoula',
			'Helena',
			'Superior',
			'Amidon',
			'New Town',
			'Gearhart',
			'Hillsboro',
			'Medford',
			'Metzger',
			'Myrtle Creek',
			'New Hope',
			'Portland',
			'Seaside',
			'Sheridan',
			'Tangent',
			'Vernonia',
			'Centerfield',
			'Herriman',
			'Mount Olympus',
			'Payson',
			'Pleasant View',
			'South Salt Lake',
			'Stansbury park',
			'Auburn',
			'Gold Bar',
			'Kittitas',
			'Lakewood',
			'Lochsloy',
			'Milton',
			'Pasco',
			'SeaTac',
			'Three Lakes',
			'Tieton',
			'Walnut Grove',
			'Evanston',
			'Gold Beach',
			'Kekaha',
			'Mililani Town',
			'Pupukea',
			'Ester',
			'Summerset',
			'Briarwood',
			'Kew Gardens Hills',
			'Conning Towers-Nautilus Park',
			'Essex Village',
			'Tilton-Northfield',
			'Bel Air North',
			'Bel Air South',
			'Dover Base Housing',
			'Mount Gay-Shamrock',
			'Comstock Northwest',
			'Sandalfoot Cove',
			'Palm River-Clair Mel',
			'Cascade-Chipita Park',
			'South Wenatchee',
			'Old Fig Garden',
			'Pasadena Hills',
			'Northchase',
			'Western Lake',
			'Spring Valley Lake',
			'Hide-A-Way Lake',
			'Woodstock',
			'Scotts Mill',
			'New Century, KS',
			'Brighton',
			'Dauphin Island',
			'Demopolis',
			'Double Springs',
			'Hueytown',
			'Morris',
			'Opelika',
			'Robertsdale',
			'Trinity',
			'Blytheville',
			'Caraway',
			'Harrisburg',
			'Lonoke',
			'Marmaduke',
			'Van Buren',
			'Highland Acres',
			'Andover',
			'Bay Harbor Islands',
			'Bonita Springs',
			'Bradenton',
			'Crescent City',
			'Dover',
			'Egypt Lake-Leto',
			'Gulfport',
			'Heathrow',
			'Highland City',
			'Malabar',
			'North Palm Beach',
			'Orange City',
			'Port Saint John',
			'Riverview',
			'South Miami',
			'Tampa',
			'Treasure Island',
			'Wahneta',
			'Westchester',
			'Windermere',
			'Winter Beach',
			'Zephyrhills',
			'Blue Ridge',
			'Duluth',
			'Grayson',
			'Hapeville',
			'Hoschton',
			'Lovejoy',
			'Lula',
			'Thomaston',
			'Walthourville',
			'Washington',
			'Assumption',
			'Crainville',
			'Jerseyville',
			'Roxana',
			'Staunton',
			'Wood River',
			'Bargersville',
			'Bedford',
			'Middlebury',
			'Oolitic',
			'Saint Paul',
			'Terre Haute',
			'Vevay',
			'West Terre Haute',
			'Arkansas City',
			'Chapman',
			'Edgerton',
			'Kingman',
			'Mound City',
			'Plainville',
			'Booneville',
			'Brodhead',
			'Crittenden',
			'Grayson',
			'Knottsville',
			'Olive Hill',
			'Taylor Mill',
			'Williamsburg',
			'Addis',
			'Henderson',
			'Mandeville',
			'Mansura',
			'Maringouin',
			'Metairie Terrace',
			'Simmesport',
			'Sterlington',
			'Ville Platte',
			'Vinton',
			'Aberdeen',
			'Arnold',
			'Colmar Manor',
			'Herald Harbor',
			'Hughesville',
			'Perryville',
			'Ridgely',
			'Savage',
			'Bernie',
			'Boonville',
			'Buffalo',
			'Cabool',
			'Concord',
			'Cool Valley',
			'De Soto',
			'Kingston',
			'Lake Ozark',
			'Lincoln',
			'Manchester',
			'Marlborough',
			'Mexico',
			'Pine Lawn',
			'Rogersville',
			'Saint James',
			'Sarcoxie',
			'Seymour',
			'Wentzville',
			'Windsor',
			'Bruce',
			'Bude',
			'Calhoun City',
			'Hattiesburg',
			'Long Beach',
			'Magnolia',
			'Pelahatchie',
			'West Gulfport',
			'Clayton',
			'Eden',
			'Elizabeth City',
			'Granite Falls',
			'Lexington',
			'Midland',
			'Millers Creek',
			'Mills River',
			'Oxford',
			'Pinetops',
			'Randleman',
			'Wentworth',
			'Forked River',
			'Haddonfield',
			'Lawnside',
			'Mays Landing',
			'Ashville',
			'Georgetown',
			'Milford',
			'Mount Carmel',
			'Somerset',
			'Alva',
			'Blackwell',
			'Dewey',
			'Drumright',
			'Grove',
			'Kiefer',
			'Luther',
			'McAlester',
			'Mustang',
			'Perry',
			'Sallisaw',
			'Stillwater',
			'Stroud',
			'Tulsa',
			'Warner',
			'Boothwyn',
			'Carroll Valley',
			'Chester',
			'Coatesville',
			'Glenolden',
			'Grantley',
			'Lampeter',
			'Morrisville',
			'Point Marion',
			'Clinton',
			'Goose Creek',
			'Warrenville',
			'Eagleton Village',
			'Harriman',
			'Johnson City',
			'Maynardville',
			'Monteagle',
			'Mount Carmel',
			'Oakland',
			'Sewanee',
			'Anderson',
			'Beeville',
			'Blue Mound',
			'Chico',
			'Daingerfield',
			'Ferris',
			'Fulshear',
			'Geronimo',
			'Granite Shoals',
			'Hackberry',
			'Hedwig Village',
			'Knox City',
			'Lumberton',
			'Medina',
			'New Summerfield',
			'Overton',
			'Pinehurst',
			'Primera',
			'Progreso',
			'Rancho Alegre',
			'River Oaks',
			'Rosharon',
			'Rowlett',
			'Saginaw',
			'San Carlos',
			'San Marcos',
			'Temple',
			'White Settlement',
			'Wortham',
			'Amelia Court House',
			'Berryville',
			'Blackstone',
			'Chesterfield Court House',
			'Elkton',
			'Floris',
			'Patrick Springs',
			'Ravensworth',
			'Reston',
			'Rural Retreat',
			'Rushmere',
			'Shenandoah Farms',
			'Stanleytown',
			'Sugarland Run',
			'Sussex',
			'Warsaw',
			'Bolivar',
			'MacArthur',
			'Oak Hill',
			'Philippi',
			'Shady Spring',
			'Shinnston',
			'Spencer',
			'Star City',
			'Elmore',
			'Danielson',
			'Middlebury',
			'Plainville',
			'Carter Lake',
			'Corydon',
			'Emmetsburg',
			'Humboldt',
			'New Hampton',
			'West Union',
			'Winterset',
			'Bartonville',
			'Belmont Cragin',
			'Camp Point',
			'Catlin',
			'Gage Park',
			'Green Oaks',
			'Knollwood',
			'Knoxville',
			'Lanark',
			'Onarga',
			'Ottawa',
			'Prophetstown',
			'Round Lake',
			'Westville',
			'Chesterfield',
			'Dyer',
			'Georgetown',
			'Otterbein',
			'Roselawn',
			'Simonton Lake',
			'Billerica',
			'Mashpee',
			'Millers Falls',
			'Saco',
			'Sanford',
			'Steuben',
			'Thomaston',
			'Washington',
			'Benton Heights',
			'Boyne City',
			'Brown City',
			'Comstock Park',
			'Harbor Springs',
			'Lewiston',
			'Napoleon',
			'Riverview',
			'Romulus',
			'Sault Ste. Marie',
			'Saint Clair',
			'Coon Rapids',
			'Crystal',
			'Frazee',
			'Fridley',
			'Ivanhoe',
			'Lake Saint Croix Beach',
			'Maplewood',
			'Mendota Heights',
			'New Brighton',
			'Norwood (historical)',
			'Scandia',
			'Zumbrota',
			'Finley',
			'Stromsburg',
			'Sutton',
			'Charlestown',
			'Meredith',
			'Newbury',
			'Northumberland',
			'Portsmouth',
			'Bradley Beach',
			'East Rutherford',
			'Keansburg',
			'Lebanon',
			'Morristown',
			'Mountainside',
			'Oxford',
			'Rahway',
			'Spring Lake Heights',
			'Succasunna',
			'Bernhards Bay',
			'Boonville',
			'Brighton',
			'Canton',
			'Central Islip',
			'Clayton',
			'College Point',
			'Copiague',
			'East Shoreham',
			'Fort Hamilton',
			'Gordon Heights',
			'Graniteville',
			'Hamlin',
			'Holtsville',
			'Lake Mohegan',
			'Lake Placid',
			'Manhasset',
			'Mattydale',
			'Mount Morris',
			'New Rochelle',
			'Palmyra',
			'Phoenix',
			'Pine Plains',
			'South Beach',
			'South Valley Stream',
			'Southampton',
			'Stottville',
			'Trumansburg',
			'Unadilla',
			'Village Green',
			'Wading River',
			'Wawarsing',
			'West Sayville',
			'Westhampton Beach',
			'Williston Park',
			'Willowbrook',
			'Woodside',
			'Bay Village',
			'Cridersville',
			'Dalton',
			'Deshler',
			'Green Springs',
			'Madison',
			'McComb',
			'Mingo Junction',
			'North Canton',
			'Oberlin',
			'Russells Point',
			'Avoca',
			'Back Mountain',
			'Chevy Chase Heights',
			'Churchville',
			'Conashaugh Lakes',
			'Dorneyville',
			'East Bangor',
			'East Petersburg',
			'Effort',
			'Elizabeth',
			'Ellwood City',
			'Hallstead',
			'Hatfield',
			'Hebron',
			'Lansford',
			'Leetsdale',
			'Level Green',
			'Lykens',
			'McAdoo',
			'McDonald',
			'Narberth',
			'Newmanstown',
			'Nixon',
			'Oakland',
			'Portage',
			'Saint Marys',
			'Schoeneck',
			'Scranton',
			'Shiloh',
			'Sinking Spring',
			'Sunbury',
			'Verona',
			'Vinco',
			'Yeagertown',
			'Hopkinton',
			'North Sioux City',
			'Chittenden',
			'Danby',
			'Saint Johnsbury',
			'Stowe',
			'Albany',
			'Ashford',
			'Beloit',
			'Lamartine',
			'Muscoda',
			'Saint Croix Falls',
			'Sparta',
			'Spring Green',
			'Stoughton',
			'Nowthen',
			'Dighton',
			'Mammoth',
			'Sierra Vista',
			'Sun City West',
			'American Canyon',
			'Anaheim',
			'Bear Valley Springs',
			'Beaumont',
			'Buena Park',
			'Cambria',
			'Charter Oak',
			'Corning',
			'Del Rey Oaks',
			'Desert View Highlands',
			'Downieville',
			'East La Mirada',
			'El Cerrito',
			'Gonzales',
			'Huntington Beach',
			'Laton',
			'Mira Mesa',
			'Mira Monte',
			'Montclair',
			'Morro Bay',
			'Mountain House',
			'Nipomo',
			'North Highlands',
			'Pico Rivera',
			'Redondo Beach',
			'Redwood Valley',
			'South San Francisco',
			'South San Jose Hills',
			'Spring Valley',
			'Talmage',
			'Valley Center',
			'Victorville',
			'Waterford',
			'Windsor',
			'Burlington',
			'Fountain',
			'Silverton',
			'Agua Fria',
			'Anthony',
			'Bayard',
			'Crownpoint',
			'Questa',
			'Ruidoso Downs',
			'Santa Clara Pueblo',
			'Bunkerville',
			'Whitney',
			'Booker',
			'Channing',
			'El Paso',
			'Friona',
			'Kermit',
			'Las Quintas Fronterizas Colonia',
			'Monahans',
			'Roby',
			'Roscoe',
			'Vega',
			'Wheeler',
			'Moab',
			'Mount Pleasant',
			'Platteville',
			'Ketchum',
			'Moreland',
			'Murphy',
			'Salmon',
			'Shoshone',
			'Kalispell',
			'Sidney',
			'White Sulphur Springs',
			'Bottineau',
			'Carson',
			'Parshall',
			'Rushville',
			'Terrytown',
			'Bend',
			'Estacada',
			'Four Corners',
			'Kenton',
			'Milwaukie',
			'Newport',
			'Prineville',
			'Warm Springs',
			'Deadwood',
			'Canyon Rim',
			'East Millcreek',
			'Hyrum',
			'Millville',
			'North Ogden',
			'Ogden',
			'Perry',
			'South Ogden',
			'Taylorsville',
			'Tremonton',
			'Benton City',
			'Canterwood',
			'Castle Rock',
			'Coupeville',
			'Dallesport',
			'Kalama',
			'Kennewick',
			'Kettle Falls',
			'Midland',
			'Mill Creek',
			'Mirrormont',
			'Nooksack',
			'Normandy Park',
			'North Puyallup',
			'Omak',
			'Oroville',
			'Port Ludlow',
			'Renton',
			'Royal City',
			'Woodway',
			'Arapahoe',
			'Palouse',
			'Lahaina',
			'Waianae',
			'Waimea',
			'Waipio',
			'Waipi‘o Acres',
			'Fritz Creek',
			'Kotzebue',
			'Seward',
			'Millburn',
			'Vineyard',
			'Northwest Harwinton',
			'Woodbury Center',
			'Newport East',
			'Fort Meade',
			'Conneaut Lakeshore',
			'Jamestown West',
			'Ault Field',
			'Bret Harte',
			'Lake Morton-Berrydale',
			'East Hill-Meridian',
			'Sierra Vista Southeast',
			'Eielson Air Force Base',
			'Flagler Estates',
			'East Honolulu',
			'Vernon Center',
			'Keeler Farm',
			'Providence Village',
			'Wilderness Rim',
			'Warren Township',
			'Financial District',
			'Lake Lakengren',
			'Silver Lake',
			'St. Johns',
			'Cortlandt Manor',
			'James Island',
			'Girard',
			'Hiawatha',
			'Iola',
			'Mission',
			'Clinton',
			'Highview',
			'Newburg',
			'Owensboro',
			'Vine Grove',
			'Blanchard',
			'Erath',
			'Gueydan',
			'Hahnville',
			'Homer',
			'Jena',
			'New Orleans',
			'Newellton',
			'Old Jefferson',
			'Camp Springs',
			'Carney',
			'Cresaptown',
			'Easton',
			'Forest Glen',
			'Gwynn Oak',
			'Layhill',
			'Linganore',
			'Mechanicsville',
			'Mount Rainier',
			'North Laurel',
			'Rising Sun',
			'Seabrook',
			'Woodsboro',
			'Camdenton',
			'Cape Girardeau',
			'Carthage',
			'Cassville',
			'Columbia',
			'El Dorado Springs',
			'Gallatin',
			'Kimberling City',
			'Lake Winnebago',
			'Louisiana',
			'Marshfield',
			'Noel',
			'Sedalia',
			'University City',
			'Winona',
			'Bay Saint Louis',
			'Collinsville',
			'Drew',
			'Friars Point',
			'Metcalfe',
			'Purvis',
			'Saucier',
			'West Hattiesburg',
			'West Point',
			'Avery Creek',
			'Bryson City',
			'Burlington',
			'Clemmons',
			'Columbus',
			'Forest Oaks',
			'Haw River',
			'Hildebran',
			'Hope Mills',
			'King',
			'Oak Island',
			'Pineville',
			'Princeton',
			'Raeford',
			'Red Oak',
			'Saint James',
			'Skippers Corner',
			'Spindale',
			'Stallings',
			'Stokesdale',
			'Stoneville',
			'Wallace',
			'West Raleigh',
			'Avalon',
			'Bayville',
			'Blackwood',
			'Elmer',
			'Ocean Gate',
			'Choctaw Lake',
			'Drexel',
			'Dry Ridge',
			'Evendale',
			'Manchester',
			'Miamisburg',
			'Middleport',
			'New Burlington',
			'Northgate',
			'Ripley',
			'Roseville',
			'Sherwood',
			'Sixteen Mile Stand',
			'Antlers',
			'Claremore',
			'Fairland',
			'Helena',
			'Idabel',
			'Okmulgee',
			'Thomas',
			'Westville',
			'Chambersburg',
			'Drexel Hill',
			'Hopwood',
			'McSherrystown',
			'Penn Wynne',
			'Waynesboro',
			'Belton',
			'Golden Grove',
			'Hardeeville',
			'Lake Wylie',
			'Lesslie',
			'Oakland',
			'Roebuck',
			'Shell Point',
			'Socastee',
			'Springdale',
			'Jellico',
			'Johnsonville',
			'Millersville',
			'Mountain City',
			'Spring Hill',
			'Aldine',
			'Clifton',
			'Combine',
			'Elsa',
			'Falcon Lake Estates',
			'Falfurrias',
			'Gonzales',
			'Grand Prairie',
			'Gunter',
			'Harker Heights',
			'Haskell',
			'Hawkins',
			'Iowa Park',
			'Jamaica Beach',
			'La Vernia',
			'Maud',
			'McAllen',
			'New Boston',
			'Pecan Grove',
			"Port O'Connor",
			'Pottsboro',
			'Prairie View',
			'Redwater',
			'Robstown',
			'San Benito',
			'Sienna Plantation',
			'Southlake',
			'Sparks',
			'Tatum',
			'Uvalde',
			'Waller',
			'Westworth',
			'Whitesboro',
			'Bland',
			'Halifax',
			'Motley',
			'Petersburg',
			'Powhatan',
			'Pulaski',
			'Purcellville',
			'Victoria',
			'Belington',
			'Charleston',
			'Eleanor',
			'Coalgate',
			'Dilley',
			'Crystal Lake',
			'Cleveland Heights',
			'Portland',
			'Riverside',
			'Uncasville',
			'West Hartford',
			'Woodbury',
			'Bloomfield',
			'Cedar Falls',
			'Hull',
			'Keosauqua',
			'Lake Mills',
			'Marshalltown',
			'Muscatine',
			'Nora Springs',
			'Stuart',
			'Urbana',
			'Bellevue',
			'Bolingbrook',
			'Burr Ridge',
			'Calumet City',
			'Chenoa',
			'Clarendon Hills',
			'Crystal Lawns',
			'Delavan',
			'Grand Boulevard',
			'Grayslake',
			'Hebron',
			'Justice',
			'Kingston',
			'Merrionette Park',
			'Morton Grove',
			'Oglesby',
			'Oswego',
			'Princeton',
			'Tilton',
			'Waterman',
			'West Chicago',
			'Aberdeen',
			'Bremen',
			'Elwood',
			'Flora',
			'Mount Vernon',
			'Winamac',
			'Villas',
			'Lugoff',
			'Blue Ridge',
			'Collinsville',
			'Jackson',
			'Lipscomb',
			'Munford',
			'Rogersville',
			'Tallassee',
			'Augusta',
			'Cabot',
			'Coal Hill',
			'Elm Springs',
			'Hot Springs Village',
			'Little Rock',
			'Newark',
			'Delaware City',
			'Buckhead Ridge',
			'Campbell',
			'Cape Canaveral',
			'Coconut Grove',
			'Floral City',
			'Gonzalez',
			'Hialeah',
			'Indian Harbour Beach',
			'Indian River Estates',
			'Lealman',
			'Lochmoor Waterway Estates',
			'Margate',
			'Montverde',
			'North Andrews Gardens',
			'Ocala',
			'Olympia Heights',
			'Orlando',
			'Punta Gorda Isles',
			'Royal Palm Estates',
			'Sharpes',
			'Sun City Center',
			'Tangerine',
			'Venice',
			'West DeLand',
			'West Hollywood',
			'Winter Haven',
			'Woodlawn Beach',
			'Buena Vista',
			'Chester',
			'Gordon',
			'Gresham Park',
			'Louisville',
			'Mount Zion',
			'Richmond Hill',
			'Royston',
			'Snellville',
			'Social Circle',
			'Anna',
			'East Saint Louis',
			'Griggsville',
			'Rochester',
			'Smithton',
			'Brazil',
			'Cayuga',
			'Crothersville',
			'English',
			'Hope',
			'Lawrenceburg',
			'Princeton',
			'Burlington',
			'Natalia',
			'Trinity',
			'Boaz',
			'Fayette',
			'Florala',
			'Florence',
			'Greenville',
			'Hartford',
			'Irondale',
			'Kimberly',
			'Lake View',
			'Linden',
			'Luverne',
			'Mount Olive',
			'Piedmont',
			'Vernon',
			'Weaver',
			'Ashdown',
			'England',
			'Greenwood',
			'Jonesboro',
			'Perryville',
			'Prescott',
			'Waldo',
			'Bethany Beach',
			'Felton',
			'Greenville',
			'Alachua',
			'Archer',
			'Center Hill',
			'Citra',
			'Cortez',
			'Crooked Lake Park',
			'East Palatka',
			'Ensley',
			'Fish Hawk',
			'Gainesville',
			'Golden Gate',
			'Gulf Breeze',
			'Lake City',
			'Lake Worth',
			// 'Melbourne',
			'Mexico Beach',
			'Miramar Beach',
			'New Smyrna Beach',
			'Plantation',
			'Port Salerno',
			'Saint James City',
			'Sarasota Springs',
			'South Beach',
			'Stuart',
			'Umatilla',
			'Waldo',
			'West Perrine',
			'Wimauma',
			'Austell',
			'Ball Ground',
			'Centerville',
			'Chamblee',
			'Chatsworth',
			'Commerce',
			'Conyers',
			'Country Club Estates',
			'Cusseta',
			'Fair Oaks',
			'Hogansville',
			'Homer',
			'Isle of Hope',
			'Remerton',
			'Sandy Springs',
			'Sparta',
			'Statham',
			'Tucker',
			'Zebulon',
			'Cahokia',
			'Caseyville',
			'Eldorado',
			'Greenfield',
			'Long Creek',
			'Millstadt',
			'New Berlin',
			'Red Bud',
			'Rosiclare',
			'Ingalls',
			'Jeffersonville',
			'Meridian Hills',
			'Nashville',
			'New Palestine',
			'Rockport',
			'Southport',
			'Bonner Springs',
			'Ellsworth',
			'Goddard',
			'Harper',
			'Herington',
			'Humboldt',
			'Moundridge',
			'Overland Park',
			'Erlanger',
			'Hodgenville',
			'Lewisport',
			'Ludlow',
			'Madisonville',
			'Morehead',
			'Owingsville',
			'Raceland',
			'Baton Rouge',
			'Cade',
			'Convent',
			'Coushatta',
			'Greensburg',
			'Labadieville',
			'Shenandoah',
			'Gaithersburg',
			'Maugansville',
			'Oakland',
			'Rosedale',
			'South Kensington',
			'Spencerville',
			'Spring Ridge',
			'Clarkton',
			'Ferguson',
			'Hamilton',
			'Kansas City',
			'Lake Lotawana',
			'Merriam Woods',
			'Northwoods',
			'Pierce City',
			'Poplar Bluff',
			'Seneca',
			'Springfield',
			'Troy',
			'Van Buren',
			'Corinth',
			'Escatawpa',
			'Fulton',
			'Leakesville',
			'Louisville',
			'Moorhead',
			'North Tunica',
			'Olive Branch',
			'Tupelo',
			'Wade',
			'Walthall',
			'Carolina Beach',
			'Cherryville',
			'Claremont',
			'Fairmont',
			'Fairplains',
			'Hazelwood',
			'Hudson',
			'Jacksonville',
			'Knightdale',
			'McLeansville',
			'Murphy',
			'Shelby',
			'Southern Shores',
			'Stony Point',
			'Thomasville',
			'Trinity',
			'Tryon',
			'Warrenton',
			'Wingate',
			'Chesilhurst',
			'Millville',
			'Northfield',
			'Byesville',
			'Ironton',
			'Monfort Heights',
			'Mount Repose',
			'Silverton',
			'South Charleston',
			'Xenia',
			'Bray',
			'Duncan',
			'Hollis',
			'Nicoma Park',
			'Okarche',
			'Okemah',
			'Pauls Valley',
			'Dunbar',
			'North York',
			'Oliver',
			'Shiloh',
			'Toughkenamon',
			'Yeadon',
			'Arcadia',
			'Cayce',
			'City View',
			'Folly Beach',
			'McCormick',
			'Northlake',
			'Red Hill',
			'Saint Matthews',
			'Atoka',
			'Bloomingdale',
			'Bluff City',
			'Clifton',
			'Columbia',
			'Crump',
			'Dyer',
			'Fairview',
			'Lakesite',
			'Louisville',
			'Manchester',
			'Mascot',
			'Sale Creek',
			'Annetta',
			'Ballinger',
			'Bartonville',
			'Bastrop',
			'Baytown',
			'Bellville',
			'Boerne',
			'Bolivar Peninsula',
			'Bonham',
			'Cedar Hill',
			'Conroe',
			'Crosby',
			'Crowley',
			'Cuero',
			'Cypress',
			'Deer Park',
			'Edcouch',
			'Edinburg',
			'Hutchins',
			'Jefferson',
			'Lacy-Lakeview',
			'Liberty',
			'Meridian',
			'Needville',
			'Palmview',
			'Panorama Village',
			'Parker',
			'Red Oak',
			'Rogers',
			'Sherman',
			'Splendora',
			'Texas City',
			'Timpson',
			'Trophy Club',
			'Wildwood',
			'Winters',
			'Bealeton',
			'Broadlands',
			'Chincoteague',
			'Daleville',
			'Danville',
			'Fairfax Station',
			'Linton Hall',
			'Matoaca',
			'Mount Jackson',
			'Surry',
			'West Springfield',
			'Buffalo',
			'Charles Town',
			'Cheat Lake',
			'Crab Orchard',
			'Hinton',
			'Keyser',
			'Mount Hope',
			'Ravenswood',
			'Williamson',
			'Alfred',
			'Easton',
			'Glastonbury Center',
			'Hartford',
			'Kensington',
			'Moosup',
			'New Preston',
			'North Granby',
			'Saybrook Manor',
			'Conrad',
			'Monticello',
			'Alsip',
			'Barrington Hills',
			'Beach Park',
			'Boulder Hill',
			'Colona',
			'Edgewater',
			'Elgin',
			'Elmwood',
			'Freeport',
			'Gilberts',
			'Itasca',
			'Lemont',
			'Maple Park',
			'Mason City',
			'Minier',
			'Moline',
			'Olympia Fields',
			'Phoenix',
			'Plainfield',
			'Wheaton',
			'Yorkville',
			'Columbia City',
			'Dunkirk',
			'New Paris',
			'Syracuse',
			'West Lafayette',
			'Whiting',
			'Andover',
			'Ayer',
			'Belmont',
			'Berkley',
			'Cambridge',
			'Hopedale',
			'Lanesborough',
			'Oxford',
			'Pocasset',
			'Southwick',
			'Teaticket',
			'Topsfield',
			'Worcester',
			'Yarmouth',
			'Canton',
			'Cherryfield',
			'Dedham',
			'Freeport',
			'Fryeburg',
			'Newfield',
			'North Windham',
			'Penobscot',
			'Sabattus',
			'Scarborough',
			'Bingham Farms',
			'Constantine',
			'Dollar Bay',
			'Eastwood',
			'Edgemont Park',
			"L'Anse",
			'Linden',
			'Montague',
			'Munising',
			'Nashville',
			'Okemos',
			'Orchard Lake',
			'Ortonville',
			'Rogers City',
			'Tawas City',
			'Austin',
			'Eagan',
			'Oakdale',
			'Pierz',
			'Royalton',
			'Spring Park',
			'Thief River Falls',
			'Two Harbors',
			'Stanberry',
			'Shell Valley',
			'Arlington',
			'Atkinson',
			'Center',
			'Dakota City',
			'Kearney',
			'Nelson',
			'North Bend',
			'Stanton',
			'Tecumseh',
			'Alexandria',
			'Auburn',
			'Candia',
			'Durham',
			'Haverhill',
			'Hinsdale',
			'Somersworth',
			'Woodsville',
			'Alpha',
			'Belford',
			'Bradley Gardens',
			'Chatham',
			'Clearbrook Park',
			'Clinton',
			'Jersey City',
			'Leonia',
			'Newton',
			'North Middletown',
			'Norwood',
			'Nutley',
			'Park Ridge',
			'Pompton Lakes',
			'Sea Bright',
			'Sewaren',
			'Upper Saddle River',
			'Whippany',
			'Willingboro',
			'Amityville',
			'Athens',
			'Billington Heights',
			'Bridgehampton',
			'Bushwick',
			'East Farmingdale',
			'Elmira Heights',
			'Fairmount',
			'Farmingdale',
			'Franklinville',
			'Grant City',
			'New Square',
			'North Amityville',
			'North Great River',
			'Orangeburg',
			'Owego',
			'Port Jefferson Station',
			'Queens',
			'Ronkonkoma',
			'Thiells',
			'Wellsville',
			'Westhampton',
			'Wykagyl',
			'Zena',
			'Austintown',
			'Beachwood',
			'Bratenahl',
			'De Graff',
			'Hilliard',
			'La Croft',
			'Marysville',
			'Mechanicsburg',
			'Middleburg Heights',
			'Napoleon',
			'New London',
			'Northwood',
			'Richmond Heights',
			'Sawyerwood',
			'Seven Hills',
			'Tuscarawas',
			'Willoughby',
			'Worthington',
			'Alburtis',
			'Catawissa',
			'Coaldale',
			'Crafton',
			'Duquesne',
			'Edgewood',
			'Ellport',
			'Ferndale',
			'Hazleton',
			'Jeannette',
			'Jonestown',
			'Knox',
			'Lewistown',
			'New Stanton',
			'Nicetown-Tioga',
			'Plum',
			'Russell',
			'Stroudsburg',
			'Sturgeon',
			'Trappe',
			'Trevorton',
			'Troy',
			'Highmore',
			'Fair Haven',
			'North Bennington',
			'Alto',
			'Delavan',
			'Greenfield',
			'Greenwood',
			'Kiel',
			'Kronenwetter',
			'Merton',
			'Monroe',
			'Mount Horeb',
			'Osseo',
			'Racine',
			'Rutland',
			'Sherwood',
			'Weyauwega',
			'Newtown',
			'Canton Valley',
			'Camp Verde',
			'Cornville',
			'Huachuca City',
			'Page',
			'Pirtleville',
			'Sun City',
			'Tolleson',
			'Tuba City',
			'Avalon',
			'Big Pine',
			'Biggs',
			'Bishop',
			'Chula Vista',
			'Corona',
			'Crestline',
			'Desert Shores',
			'Dixon',
			'Elkhorn',
			'Farmersville',
			'Green Valley',
			'Greenfield',
			'Hayward',
			'Lakeland Village',
			'Lenwood',
			'Livingston',
			'Morongo Valley',
			'Perris',
			'Pomona',
			'Rancho Cordova',
			'Rancho Palos Verdes',
			'Richgrove',
			'Ridgecrest',
			'San Carlos',
			'Stanton',
			'Tarpey Village',
			'Temple City',
			'Vacaville',
			'Villa Park',
			'Walnut',
			'Weldon',
			'West Hollywood',
			'Winters',
			'Woodbridge',
			'Yuba City',
			'Delta',
			'Ellicott',
			'Olathe',
			'Westminster',
			'Woodmoor',
			'Meade',
			'Church Rock',
			'Pecos',
			'Sandia Knolls',
			'Stagecoach',
			'Dalhart',
			'Floydada',
			'Fritch',
			'Morton',
			'Benson',
			'Gooding',
			'Iona',
			'Evergreen',
			'Jordan',
			'Manhattan',
			'Montana City',
			'Stapleton',
			'Tryon',
			'Carlin',
			'Cedar Hills',
			'Chenoweth',
			'Jefferson',
			'West Slope',
			'Blackhawk',
			'Francis',
			'Maeser',
			'Marriott-Slaterville',
			'Nibley',
			'Orem',
			'Springville',
			'Aberdeen',
			'Airway Heights',
			'Allyn',
			'Burlington',
			'Duvall',
			'Eatonville',
			'Longview Heights',
			'Monroe',
			'Mount Vista',
			'Newport',
			'Silverdale',
			'Snoqualmie',
			'South Hill',
			'Southworth',
			'Veradale',
			'Kemmerer',
			'North Rock Springs',
			'Torrington',
			'Makakilo City',
			'Maunawili',
			'Whitmore Village',
			'Hilo',
			'Kā‘anapali',
			'Hooper Bay',
			'Barrow',
			'Saint Augustine South',
			'Fort Belvoir',
			'Holiday City-Berkeley',
			'Wickerham Manor-Fisher',
			'Kings Bay Base',
			'Monarch Mill',
			'Ohkay Owingeh',
			'Picnic Point-North Lynnwood',
			'Sunset Park',
			'Bay Harbor',
			'Bessemer',
			'Flomaton',
			'Grayson Valley',
			'Mountain Brook',
			'New Brockton',
			'Rainsville',
			'Selma',
			'Webb',
			'Decatur',
			'Gosnell',
			'Hampton',
			'Haskell',
			'Hot Springs',
			'Lake Hamilton',
			'Marion',
			'Morrilton',
			'Russellville',
			'Millsboro',
			'Atlantic Beach',
			'Butler Beach',
			'Celebration',
			'Citrus Hills',
			'DeFuniak Springs',
			'Geneva',
			'Inglis',
			'Kendall Green',
			'Lady Lake',
			'Lower Grand Lagoon',
			'Lutz',
			'Marianna',
			'Monticello',
			'North Brooksville',
			'North River Shores',
			'Palm Aire',
			'Pebble Creek',
			'Pembroke Park',
			'Redington Shores',
			'Saint Augustine',
			'Santa Rosa Beach',
			'Sarasota',
			'Sky Lake',
			'South Brooksville',
			'South Palm Beach',
			'Tamiami',
			'Tarpon Springs',
			'West and East Lealman',
			'Atlanta',
			'Bremen',
			'Chickamauga',
			'Covington',
			'Cuthbert',
			'Dacula',
			'East Newnan',
			'Enigma',
			'Fort Valley',
			'Kennesaw',
			'Lilburn',
			'Lookout Mountain',
			'Metter',
			'Nicholson',
			'Ray City',
			'Sardis',
			'Senoia',
			'Summerville',
			'Tallapoosa',
			'Gillespie',
			'Girard',
			'Martinsville',
			'Mount Zion',
			'Salem',
			'Steeleville',
			'Clarksville',
			'Clermont',
			'Edinburgh',
			'Fishers',
			'Sunman',
			'Vincennes',
			'Westport',
			'Caldwell',
			'De Soto',
			'Derby',
			'Fort Scott',
			'Highland',
			'Saint John',
			'Saint Marys',
			'Sterling',
			'Alexandria',
			'Anchorage',
			'Annville',
			'Calhoun',
			'Carlisle',
			'Crestview Hills',
			'Harlan',
			'Providence',
			'Williamstown',
			'Baker',
			'Ferriday',
			'Houma',
			'Jennings',
			'Midway',
			'Paradis',
			'Patterson',
			'Pearl River',
			'Pine Prairie',
			'Bel Air',
			// 'Berlin',
			'Brandywine',
			'Brock Hall',
			'Charlotte Hall',
			'Fort George G Mead Junction',
			'Glenmont',
			'Marlton',
			'Springdale',
			'Trappe',
			'Westminster',
			'Castle Point',
			'Concordia',
			'Dixon',
			'Duenweg',
			'Fayette',
			'Galena',
			'Liberty',
			"O'Fallon",
			'Saint Johns',
			'Smithville',
			'Grenada',
			'Lambert',
			'Meridian',
			'Nettleton',
			'Pearlington',
			'Shelby',
			'Badin',
			'Black Mountain',
			'Blowing Rock',
			'Cary',
			'China Grove',
			'Currituck',
			'Dana',
			'Fuquay-Varina',
			'Glen Alpine',
			'River Bend',
			'Wake Forest',
			'Crestwood Village',
			'Medford Lakes',
			'Barnesville',
			'Beavercreek',
			'Blanchester',
			'Dent',
			'Kenwood',
			'Mulberry',
			'New Vienna',
			'Park Layne',
			'Pickerington',
			'Reynoldsburg',
			'Shadyside',
			'The Village of Indian Hill',
			'West Jefferson',
			'Chickasha',
			'Cleora',
			'Crescent',
			'Eufaula',
			'Harrah',
			'Konawa',
			'Mooreland',
			'Shattuck',
			'Conestoga',
			'Gap',
			'Marcus Hook',
			'Mercersburg',
			'Milford',
			'Spry',
			'Weigelstown',
			'Yoe',
			'Due West',
			'Mount Pleasant',
			'Pickens',
			'Ravenel',
			'Welcome',
			'Banner Hill',
			'Benton',
			'Covington',
			'Kingston',
			'McEwen',
			'Munford',
			'Oak Hill',
			'Seymour',
			'Shelbyville',
			'Ames',
			'Anderson Mill',
			'Aubrey',
			'Balch Springs',
			'Bee Cave',
			'Cleveland',
			'Commerce',
			'Crockett',
			'Granbury',
			'Jacksonville',
			'Jewett',
			'Lakeway',
			'Leander',
			'Lost Creek',
			'Monte Alto',
			'Olney',
			'Pinehurst',
			'Potosi',
			'Ricardo',
			'Rio Hondo',
			'Sansom Park',
			'West Livingston',
			'Wills Point',
			'Broadway',
			'Buena Vista',
			'Forest',
			'Franconia',
			'Franklin',
			'Richlands',
			'Tappahannock',
			'Verona',
			'Virginia Beach',
			'Waynesboro',
			'Wyndham',
			'Wytheville',
			'Boaz',
			'Elizabeth',
			'Nitro',
			'Petersburg',
			'Terra Alta',
			'Rockland',
			'Yardville',
			'Stafford',
			'Ackley',
			'Anamosa',
			'Bettendorf',
			'Denison',
			'Elkader',
			'Evansdale',
			'Fairfax',
			'Forest City',
			'Fort Madison',
			'Manchester',
			'Northwood',
			'Orange City',
			'Riverside',
			'Tripoli',
			'Van Meter',
			'Albany Park',
			'Beecher',
			'Blue Island',
			'Fox Lake Hills',
			'Franklin Park',
			'Genoa',
			'Hawthorn Woods',
			'Heyworth',
			'Hillcrest',
			'Logan Square',
			'Lynwood',
			'Machesney Park',
			'Mount Prospect',
			'Normal',
			'North Center',
			'North Chicago',
			'Palos Hills',
			'Pecatonica',
			'Pontiac',
			'Prospect Heights',
			'Richmond',
			'Romeoville',
			'South Chicago',
			'Toulon',
			'West Ridge',
			'Arcadia',
			'Covington',
			'Crown Point',
			'Gas City',
			'Griffith',
			'Hammond',
			'Hebron',
			'Huntertown',
			'Lapel',
			'Leo-Cedarville',
			'Middlebury',
			'New Haven',
			'Porter',
			'Rensselaer',
			'Abington',
			'Dalworthington Gardens',
			'Deweyville',
			'Galveston',
			'Highlands',
			'Baldwinville',
			'Brewster',
			'Carlisle',
			'Dudley',
			'Holyoke',
			'Lynnfield',
			'Marshfield Hills',
			'Marstons Mills',
			'Northampton',
			'Pelham',
			'Sagamore',
			'Shelburne',
			'South Peabody',
			'South Yarmouth',
			'Ware',
			'West Chatham',
			'Weymouth',
			'Whately',
			'Winthrop',
			'Addison',
			'Arundel',
			'Falmouth',
			'Harpswell Center',
			'Hollis Center',
			'Lincoln',
			'Millinocket',
			'Orland',
			'Richmond',
			'Cassopolis',
			'Flushing',
			'Fraser',
			'Gibraltar',
			'Grass Lake',
			'Holland',
			'Middleville',
			'Northview',
			'Olivet',
			'Reading',
			'Vicksburg',
			'Wacousta',
			'Breezy Point',
			'Forest Lake',
			'Hayfield',
			'Isanti',
			'New Richland',
			'Red Wing',
			'Princeton',
			'Ashley',
			'Grand Forks',
			'New Rockford',
			'David City',
			'Elkhorn',
			'Fairbury',
			'Gretna',
			'Plattsmouth',
			'Belmont',
			'Conway',
			'Exeter',
			'Greenfield',
			'Hopkinton',
			'Litchfield',
			'Lyme',
			'Merrimack',
			'New Durham',
			'Raymond',
			'Salem',
			'Troy',
			'Cliffside Park',
			'Cranbury',
			'Dover',
			'Farmingdale',
			'Finderne',
			'Franklin',
			'Gladstone',
			'Hackettstown',
			'Haskell',
			'Keyport',
			'Monmouth Beach',
			'Roseland',
			'South River',
			'Upper Montclair',
			'White Meadow Lake',
			'Woodcliff Lake',
			'Allegany',
			'Bedford',
			'Bellmore',
			'Cicero',
			'Clifton Springs',
			'Clinton',
			'East Atlantic Beach',
			'Ellenville',
			'Firthcliffe',
			'Great Neck Estates',
			'Halesite',
			'Hewlett',
			'Irondequoit',
			'Lansing',
			'Lido Beach',
			'Little Falls',
			'Mechanicstown',
			'Medina',
			'Nanuet',
			'New Dorp',
			'Orchard Park',
			'Painted Post',
			'Plattsburgh',
			'Pleasant Valley',
			'Roosevelt',
			'Scarsdale',
			'Searingtown',
			'Tarrytown',
			'Troy',
			'Wesley Hills',
			'West Carthage',
			'West Elmira',
			'Westfield',
			'Conneaut',
			'Gates Mills',
			'Genoa',
			'Greentown',
			'New Franklin',
			'Ontario',
			'Richfield',
			'Richville',
			'Rittman',
			'Rossford',
			'Saint Clairsville',
			'South Canal',
			'Wintersville',
			'Acme',
			'Bala Cynwyd',
			'Bangor',
			'Belfast',
			'Camp Hill',
			'Canadensis',
			'Chicora',
			'Chinchilla',
			'Clarks Summit',
			'Dalton',
			'Delmont',
			'Duryea',
			'Etna',
			'Feasterville',
			'Gallitzin',
			'Jessup',
			'Kenilworth',
			'Lake City',
			'Linglestown',
			'Marianne',
			'Matamoras',
			'McKees Rocks',
			'McMurray',
			'Mount Holly Springs',
			'Mundys Corner',
			'Nanticoke',
			'Nanty Glo',
			'Oreland',
			'Palmyra',
			'Penryn',
			'Perkasie',
			'Scottdale',
			'Sharon',
			'Skyline View',
			'Terre Hill',
			'Thompsonville',
			'Troy',
			'Walnutport',
			'Wrightsville',
			'East Providence',
			'Armour',
			'Chamberlain',
			'Mitchell',
			'Colchester',
			'Wilder',
			'Amherst',
			'Brice Prairie',
			'Keshena',
			'Kewaskum',
			'Kohler',
			'La Crosse',
			'Lake Nebagamon',
			'Mishicot',
			'Oostburg',
			'Richmond',
			'Rosendale',
			'Siren',
			'Wales',
			'Westfield',
			'Dolan Springs',
			'Goodyear',
			'Mesa',
			'Peach Springs',
			'San Carlos',
			'Superior',
			'Alta Sierra',
			'Aromas',
			'Avenal',
			'California City',
			'Camarillo',
			'Campo',
			'Carson',
			'Citrus Heights',
			'Commerce',
			'Davis',
			'Desert Hot Springs',
			'Hermosa Beach',
			'Hidden Valley Lake',
			'Inverness',
			'Kings Beach',
			'Lafayette',
			'Manteca',
			'Monrovia',
			'Muscoy',
			'Paso Robles',
			'Placentia',
			'Rancho Penasquitos',
			'Reedley',
			'Rocklin',
			'Saint Helena',
			'Salinas',
			'San Diego',
			'Santa Clarita',
			'Sedco Hills',
			'Sunland',
			'Twin Lakes',
			'Valley Springs',
			'Vincent',
			'Byers',
			'Creede',
			'Durango',
			'Kiowa',
			'Gove',
			'La Mesilla',
			'Roswell',
			'Sunland Park',
			'Henderson',
			'Abernathy',
			'Amarillo',
			'Bovina',
			'Bushland',
			'Plains',
			'Seth Ward',
			'Sonora',
			'Stratford',
			'Sunray',
			'Monticello',
			'Arivaca Junction',
			'Bayview',
			'Eureka',
			'Loveland',
			'Walden',
			'Caldwell',
			'Heyburn',
			'Parma',
			'Shelley',
			'Baker',
			'East Helena',
			'North Browning',
			'Stevensville',
			'Three Forks',
			'Crosby',
			'Stanton',
			'Hayes Center',
			'Burns',
			'Grants Pass',
			'Irrigon',
			'Shady Cove',
			'Three Rivers',
			'Selby',
			'Hyde Park',
			'Liberty',
			'Logan',
			'Millcreek',
			'Riverton',
			'Cascade Valley',
			'Davenport',
			'East Wenatchee',
			'Fern Prairie',
			'Frederickson',
			'Gleed',
			'Lake Forest Park',
			'Opportunity',
			'Pacific',
			'Poulsbo',
			'Washougal',
			'Bainbridge Island',
			'Cody',
			'Greybull',
			'Ranchettes',
			'Vineyard',
			'Haines',
			'Nanawale Estates',
			'Fern Acres',
			'Hālawa',
			'Gateway',
			'Soldotna',
			'Anchorage',
			'Northeast Ithaca',
			'Legend Lake',
			'Slater-Marietta',
			'Black Point-Green Point',
			'Edwards Air Force Base',
			'Laughlin Air Force Base',
			'Moses Lake North',
			'New Kingman-Butler',
			'Oroville East',
			'Union Hill-Novelty Hill',
			'West Haven-Sylvan',
			'West Modesto',
			'Wailua Homesteads',
			'Carlisle-Rockledge',
			'Fairwood',
			'Merritt Park',
			'Paloma Creek',
			'North Fort Lewis',
			'Circle D-KC Estates',
			'Dove Valley',
			'The Acreage',
			'Cranberry Township',
			'Kittery',
			'Poland',
			'South Berwick',
			'Veazie',
			'Buena Vista',
			'Fennville',
			'Grosse Pointe Woods',
			'Hudson',
			'Novi',
			'Roosevelt Park',
			'Spring Lake',
			'Springfield',
			'Standish',
			'Trenton',
			'White Cloud',
			'Zeeland',
			'Ada',
			'Appleton',
			'Bagley',
			'Blaine',
			'Branch',
			'Brooklyn Park',
			'Dassel',
			'Eagle Lake',
			'Excelsior',
			'Hoyt Lakes',
			'Lake Elmo',
			'Lakefield',
			'Minnetrista',
			'Northfield',
			'Renville',
			'Saint Michael',
			'Saint Paul',
			'Stewartville',
			'Maryville',
			'Unionville',
			'Minnewaukan',
			'La Vista',
			'Shelton',
			'South Sioux City',
			'Bedford',
			'Derry',
			'Enfield',
			'Nottingham',
			'Pembroke',
			'Stratford',
			'Bloomingdale',
			'Brielle',
			'Cedar Glen West',
			'Cranford',
			'Dayton',
			'East Newark',
			'Fairview',
			'Garfield',
			'Mendham',
			'Oradell',
			'Plainsboro Center',
			'South Belmar',
			'South Old Bridge',
			'Strathmore',
			'Alden',
			'Arlington',
			'Armonk',
			'Blue Point',
			'Bridgeport',
			'Brookhaven',
			'Brookville',
			'Brownville',
			'Carmel',
			'Chadwicks',
			'Chestnut Ridge',
			'Crown Heights',
			'Gouverneur',
			'Greenville',
			"Hell's Kitchen",
			'Honeoye Falls',
			'Lakeview',
			'Lincoln Park',
			'Marlboro',
			'Montour Falls',
			'Nedrow',
			'Niagara Falls',
			'North Hills',
			'Northville',
			'Peekskill',
			'Port Chester',
			'Port Morris',
			'Poughkeepsie',
			'Roslyn Harbor',
			'Schenectady',
			'Schoharie',
			'South Huntington',
			'Southport',
			'Staten Island',
			'University Gardens',
			'Viola',
			'Wanakah',
			'Warwick',
			'West End',
			'West Henrietta',
			'Williamsburg',
			'Apple Creek',
			'Bellville',
			'Columbus Grove',
			'Crestline',
			'Creston',
			'Danville',
			'Fairview Park',
			'Frazeysburg',
			'Holgate',
			'Lorain',
			'Lowellville',
			'Mentor-on-the-Lake',
			'Millbury',
			'Mineral Ridge',
			'Newburgh Heights',
			'North Kingsville',
			'Plain City',
			'Pleasant Hill',
			'Willoughby Hills',
			'Annville',
			'Arlington Heights',
			'Aspinwall',
			'Avon',
			'Bainbridge',
			'Bellwood',
			'Cambridge Springs',
			'Donora',
			'Dresher',
			'East Washington',
			'Franklin Park',
			'Homestead',
			'Inkerman',
			'Lawson Heights',
			'Lemoyne',
			'Lewisburg',
			'Lincoln Park',
			'Marienville',
			'Maytown',
			'Middletown',
			'Munhall',
			'Northumberland',
			'Oakdale',
			'Pennside',
			'Punxsutawney',
			'Saylorsburg',
			'Shamokin Dam',
			'Sugarcreek',
			'West Norriton',
			'Wyndmoor',
			'Tiverton',
			'Britton',
			'Kennebec',
			'Parker',
			'Essex Junction',
			'Belgium',
			'Hales Corners',
			'Kimberly',
			'Lodi',
			'Lomira',
			'Medford',
			'Menomonie',
			'Salem',
			'Seymour',
			'Thiensville',
			'Waupaca',
			'Winneconne',
			'Wittenberg',
			'Woodville',
			'Ansonia',
			'Eloy',
			'Ganado',
			'LeChee',
			'Many Farms',
			'Mayer',
			'Sun Lakes',
			'Tucson Estates',
			'Winslow',
			'Alhambra',
			'Arvin',
			'Bellflower',
			'Big Bear Lake',
			'Carmel-by-the-Sea',
			'Cherry Valley',
			'Cobb',
			'Collierville',
			'Coronado',
			'Cypress',
			'Denair',
			'East Richmond Heights',
			'Fontana',
			'Galt',
			'Gilroy',
			'Green Valley',
			'Kingsburg',
			'La Palma',
			'Lawndale',
			'Mammoth Lakes',
			'Newport Beach',
			'Olivehurst',
			'Orland',
			'Paramount',
			'Placerville',
			'Portola Valley',
			'Prunedale',
			'Rancho Calaveras',
			'Rio Linda',
			'Santa Barbara',
			'Santa Cruz',
			'Shackelford',
			'Sutter Creek',
			'Twain Harte',
			'Ventura',
			'Whittier',
			'Carriage Club',
			'Cherry Hills Village',
			'Derby',
			'Greenwood Village',
			'Keystone',
			'Springfield',
			'Farmington',
			'Laguna',
			'Los Chavez',
			'North Valley',
			'Enterprise',
			'Johnson Lane',
			'Lemmon Valley',
			'Earth',
			'Eidson Road',
			'Elm Creek',
			'Iraan',
			'Muleshoe',
			'Wellington',
			'West Odessa',
			'Moroni',
			'Panguitch',
			'Spring City',
			'West Sedona',
			'Three Rivers',
			'Janesville',
			'Myrtletown',
			'Susanville',
			'Fort Lupton',
			'McCall',
			'Montpelier',
			'Absarokee',
			'Malta',
			'Roundup',
			'Shelby',
			'Garrison',
			'Bridgeport',
			'Scottsbluff',
			'Trenton',
			'Creswell',
			'Heppner',
			'Sublimity',
			'Sutherlin',
			'Porcupine',
			'Rosebud',
			'Daniel',
			'Richmond',
			'Bellevue',
			'Bellingham',
			'Birch Bay',
			'Cathcart',
			'Electric City',
			'Finley',
			'Lacey',
			'Manson',
			'Othello',
			'Smokey Point',
			'Trentwood',
			'West Lake Sammamish',
			'Woodland',
			'Basin',
			'American Fork',
			'Mountain View',
			'Nānākuli',
			'Waimānalo Beach',
			'‘Ewa Beach',
			'Hale‘iwa',
			'Honolulu',
			'Bethel',
			'Old Saybrook Center',
			'Wakefield-Peacedale',
			'Mansfield Center',
			'Rosendale Village',
			'Minot Air Force Base',
			'Saginaw Township North',
			'Barnes Lake-Millers Lake',
			'Inverness Highlands North',
			'Watts Mills',
			'Beale Air Force Base',
			'March Air Force Base',
			'Marietta-Alderwood',
			'Garden Home-Whitford',
			'Smith Valley',
			'West Whittier-Los Nietos',
			'Kahaluu-Keauhou',
			'Schofield Barracks',
			'Prestbury',
			'Farley',
			'Ko Olina',
			'Firing Range',
			'Dixiana',
			'Brookwood',
			'Decatur',
			'Fort Payne',
			'Holt',
			'North Bibb',
			'Prattville',
			'Reform',
			'Smoke Rise',
			'Southside',
			'Spanish Fort',
			'Tuscaloosa',
			'Vandiver',
			'York',
			'Clarendon',
			'Danville',
			'Dumas',
			'Horatio',
			'Magnolia',
			'North Crossett',
			'West Fork',
			'Clayton',
			'Delmar',
			'Wyoming',
			'Astor',
			'Belle Glade',
			'Belleview',
			'Brandon',
			'Carol City',
			'Carrabelle',
			'Coral Springs',
			'Coral Terrace',
			'Country Walk',
			'Dania Beach',
			'Desoto Lakes',
			'East Milton',
			'Feather Sound',
			'Forest City',
			'Gladeview',
			'Homosassa Springs',
			'Lake Butler',
			'Leisure City',
			'Meadow Woods',
			'Newberry',
			'Nokomis',
			'Norland',
			'North Port',
			'Oakland Park',
			'Ormond Beach',
			'Palm Beach Gardens',
			'Parker',
			'Quincy',
			'Sanibel',
			'Sneads',
			'Southchase',
			'Southgate',
			'Taft',
			'Tamarac',
			'Tangelo Park',
			'Twin Lakes',
			'Winter Springs',
			'Woodville',
			'Bonanza',
			'Cairo',
			'Clayton',
			'Demorest',
			'Dock Junction',
			'Griffin',
			'Hamilton',
			'Lincolnton',
			'Lindale',
			'Madison',
			'Marietta',
			'Palmetto',
			'Pelham',
			'Shannon',
			'Sparks',
			'Valdosta',
			'Wrightsville',
			'Young Harris',
			'Atwood',
			'Cairo',
			'Lawrenceville',
			'West Frankfort',
			'Hagerstown',
			'Oak Park',
			'Coldwater',
			'Elwood',
			'Larned',
			'Mulvane',
			'Norton',
			'Paola',
			'Russell',
			'Victoria',
			'Albany',
			'Brandenburg',
			'Campton',
			'Columbia',
			'Hebron Estates',
			'Lyndon',
			'Oak Grove',
			'Plano',
			'Westwood',
			'Bernice',
			'Catahoula',
			'Church Point',
			'Clinton',
			'Eunice',
			'Kentwood',
			'Luling',
			'Paulina',
			'Port Barre',
			'Ringgold',
			'Zachary',
			'Algonquin',
			'Cloverly',
			'Hillandale',
			'Hunt Valley',
			'Lusby',
			'Oxon Hill',
			'Solomons',
			'Thurmont',
			'Urbana',
			'Westphalia',
			'White Oak',
			'Appleton City',
			'Arnold',
			'Brookfield',
			'Cameron',
			'Excelsior Springs',
			'Festus',
			'Leadwood',
			'Mountain View',
			'Pleasant Valley',
			'Salem',
			'Shelbyville',
			'Sullivan',
			'Sunset Hills',
			'Weldon Spring',
			'Wildwood',
			"D'Iberville",
			'Houston',
			'Mendenhall',
			'Mikoma',
			'Shannon',
			'Starkville',
			'Terry',
			'Balfour',
			'Broadway',
			'Gatesville',
			'Granite Quarry',
			'Havelock',
			'Hendersonville',
			'Jamestown',
			'Lenoir',
			'Mebane',
			'Mountain View',
			'River Road',
			'Rowland',
			'Selma',
			'Spring Hope',
			'Troutman',
			'Zebulon',
			'Ashland',
			'Echelon',
			'Gloucester City',
			'Haddon Heights',
			'Mount Ephraim',
			'North Wildwood',
			'Ramblewood',
			'Beckett Ridge',
			'Caldwell',
			'Canal Winchester',
			'Carlisle',
			'Frankfort',
			'Lebanon',
			'New Carlisle',
			'New Lexington',
			'The Plains',
			'Walnut Hills',
			'Wyoming',
			'Arkoma',
			'Beggs',
			'Bushyhead',
			'Hall Park',
			'Jones',
			'Kenwood',
			'Lindsay',
			'Meeker',
			'Perkins',
			'Pryor',
			'Verdigris',
			'Warr Acres',
			'Linwood',
			'Millersville',
			'Morton',
			'Ridley Park',
			'Whitman',
			'Barnwell',
			'Elgin',
			'Lancaster',
			'Moncks Corner',
			'Newberry',
			'Taylors',
			'Dresden',
			'Dyersburg',
			'East Chattanooga',
			'East Ridge',
			'Fall Branch',
			'Goodlettsville',
			'Lewisburg',
			'Roan Mountain',
			'Loudon',
			'Oliver Springs',
			'Rutledge',
			'Sweetwater',
			'Anson',
			'Bridgeport',
			'Bruceville-Eddy',
			'Cibolo',
			'De Kalb',
			'Double Oak',
			'Floresville',
			'Four Corners',
			'Fredericksburg',
			'Ganado',
			'Garden Ridge',
			'Glenn Heights',
			'Haltom City',
			'Hill Country Village',
			'Hillsboro',
			'Justin',
			'Lake Dunlap',
			'Liberty City',
			'Lockhart',
			'Longview',
			'Lucas',
			'Muniz',
			'Perezville',
			'Pine Island',
			'Ponder',
			'Round Rock',
			'Scenic Oaks',
			'Sherwood Shores',
			'South Houston',
			'Terrell Hills',
			'Throckmorton',
			'Tyler',
			'Valley Mills',
			'West University Place',
			'Altavista',
			'Belmont',
			'Cape Charles',
			'Cumberland',
			'Dublin',
			'Front Royal',
			'Gainesville',
			'Lakeside',
			'Massanetta Springs',
			'Massanutten',
			'Merrifield',
			'New Baltimore',
			'Short Pump',
			'Stuart',
			'Clay',
			'Cross Lanes',
			'Williamstown',
			'Villa Ridge',
			'Shaw',
			'Bay City',
			'Atmore',
			'Glendale Heights',
			'Union',
			'Killingworth',
			'Southbury',
			'Windsor Locks',
			'Council Bluffs',
			'Decorah',
			'Laurens',
			'Lenox',
			'Malvern',
			'North Liberty',
			'Sioux City',
			'Walcott',
			'Colfax',
			'Country Club Hills',
			'Countryside',
			'DeKalb',
			'El Paso',
			'Elburn',
			'Fisher',
			'Kirkland',
			'Lexington',
			'Montgomery',
			'Norridge',
			'Northlake',
			'Orland Park',
			'River Grove',
			'Shorewood',
			'Somonauk',
			'Walnut',
			'Wilmette',
			'Avilla',
			'Farmland',
			'Ogden Dunes',
			'Oxford',
			'Upland',
			'Walkerton',
			'Agawam',
			'Bondsville',
			'Centerville',
			'Cohasset',
			'Cordaville',
			'East Bridgewater',
			'Fitchburg',
			'Framingham Center',
			'Oakham',
			'Onset',
			'Plympton',
			'Provincetown',
			'Sherborn',
			'Westborough',
			'Winchester',
			'Yarmouth Port',
			'Belfast',
			'Belgrade',
			'Denmark',
			'Hiram',
			'Limestone',
			'Mechanic Falls',
			'Patten',
			'Avon',
			// 'Boston',
			'Boxford',
			'Cotuit',
			'Fiskdale',
			'Hanover',
			'Hyannis',
			'Lancaster',
			'Lincoln',
			'Norwell',
			'Rutland',
			'Webster',
			'Westminster',
			'White Island Shores',
			'Albion',
			'Appleton',
			'Buckfield',
			'Charleston',
			'Cornville',
			'Kenduskeag',
			'Lebanon',
			'Phippsburg',
			'Auburn Hills',
			'Beechwood',
			'Beulah',
			'Coleman',
			'Evart',
			'Frankfort',
			'Gladstone',
			'Goodrich',
			'Grosse Ile',
			'Hartford',
			'Jonesville',
			'Luna Pier',
			'Marcellus',
			'Marine City',
			'Muskegon',
			'Plainwell',
			'Port Huron',
			'Roscommon',
			'Sandusky',
			'Stony Point',
			'Sylvan Lake',
			'Tecumseh',
			'Ypsilanti',
			'Albertville',
			'Avon',
			'Deephaven',
			'Gilbert',
			'Goodview',
			'Hermantown',
			'Keewatin',
			'Nisswa',
			'Paynesville',
			'Roseau',
			'Sauk Rapids',
			'Warroad',
			'Winnebago',
			'Woodbury',
			'Kahoka',
			'Mound City',
			'Casselton',
			'Devils Lake',
			'Lisbon',
			'Aurora',
			'Fremont',
			'Fullerton',
			'Milford',
			'Pender',
			'Saint Paul',
			'Derry Village',
			'Dublin',
			'Grafton',
			'North Haverhill',
			'Tamworth',
			'Brookdale',
			'Concordia',
			'Ho-Ho-Kus',
			'Lake Telemark',
			'Lakehurst',
			'Montclair',
			'Northfield',
			'Princeton',
			'River Edge',
			'Robbinsville',
			'West Freehold',
			'Altamont',
			'Baldwin Harbor',
			'Castleton-on-Hudson',
			'Constantia',
			'Crompond',
			'Deer Park',
			'East Flatbush',
			'Eatons Neck',
			'Endicott',
			'Fort Plain',
			'Greenwich',
			'Highland Falls',
			'Hollis',
			'Kiantone',
			'Lawrence',
			'Lowville',
			'Manorville',
			'Melrose',
			'Miller Place',
			'Montrose',
			'Mount Kisco',
			'Muttontown',
			'New Windsor',
			'Patchogue',
			'Rockville Centre',
			'Rye Brook',
			'Shirley',
			'Sound Beach',
			'Saint Bonaventure',
			'Sunnyside',
			'Throgs Neck',
			'Warrensburg',
			'West Hempstead',
			'Wilson',
			'Woodrow',
			'Alliance',
			'Bedford',
			'Boardman',
			'Brecksville',
			'Columbiana',
			'Euclid',
			'Fostoria',
			'Greenwich',
			'Hartville',
			'Independence',
			'Lima',
			'Macedonia',
			'McDonald',
			'Morgandale',
			'Norton',
			'Parma',
			'Rockford',
			'South Russell',
			'Spencerville',
			'Troy',
			'Twinsburg',
			'Warren',
			'Wellington',
			'Wellsville',
			'West Salem',
			'Albion',
			'Ambridge',
			'Ashley',
			'Ben Avon',
			'Butler',
			'Cetronia',
			'Enola',
			'Falls Creek',
			'Farrell',
			'Fellsburg',
			'Halfway House',
			'Houston',
			'Jersey Shore',
			'Larksville',
			'Lenape Heights',
			'Martinsburg',
			'Monongahela',
			'Montoursville',
			'Oley',
			'Prospect',
			'Reading',
			'Reinholds',
			'Shillington',
			'South Greensburg',
			'Southwest Greensburg',
			'Stiles',
			'Tionesta',
			'East Greenwich',
			'Exeter',
			'Brookings',
			'Faulkton',
			'Huron',
			'Barron',
			'Dakota',
			'Edgar',
			'Fox Lake',
			'Germantown',
			'Gillett',
			'Horicon',
			'Hurley',
			'Lannon',
			'Madison',
			'Manawa',
			'North La Crosse',
			'Port Washington',
			'Prairie du Sac',
			'Williams Bay',
			'Wind Lake',
			'Corona de Tucson',
			'Rio Verde',
			'Surprise',
			'Thatcher',
			'Agoura',
			'Albany',
			'Avila Beach',
			'Baldwin Park',
			'Bay Point',
			'Bell Gardens',
			'Benicia',
			'Biola',
			'Buttonwillow',
			'Callender',
			'Colma',
			'Fresno',
			// 'Glendale',
			'Golden Hills',
			'Home Gardens',
			'Hughson',
			'Inglewood',
			'La Verne',
			'Lake of the Pines',
			'Lakeport',
			'Littlerock',
			'Madera',
			'Newcastle',
			'Northridge',
			'Oakhurst',
			'Oceano',
			'Parkwood',
			'Patterson',
			'Planada',
			'Rosemead',
			'San Luis Obispo',
			'Santa Ynez',
			'Soulsbyville',
			'Taft',
			'Torrance',
			'Truckee',
			'Visalia',
			'Yosemite Lakes',
			'Bayfield',
			'Castle Pines',
			'Colorado Springs',
			'Florence',
			'Fruitvale',
			'Genesee',
			'Lincoln Park',
			'Mountain Village',
			'San Luis',
			'Colby',
			'Hugoton',
			'Plains',
			'Aztec',
			'Deming',
			'Lordsburg',
			'Radium Springs',
			'Ranchos de Taos',
			'Tierra Amarilla',
			'Truth or Consequences',
			'Upper Fruitland',
			'Anthony',
			'Fort Clark Springs',
			'Levelland',
			'Shamrock',
			'Socorro',
			'Fillmore',
			'Orangeville',
			'West Bishop',
			'Indian Hills',
			'Shasta Lake',
			'Gilcrest',
			'Idaho Falls',
			'Kuna',
			'Soda Springs',
			'Wallace',
			'Clinton',
			'Conrad',
			'Dillon',
			'Scobey',
			'Bismarck',
			'Mandan',
			'Mott',
			'Arthur',
			'Cedar Mill',
			'Depoe Bay',
			'La Pine',
			'Lowell',
			'Mount Hood Village',
			'Redmond',
			'Sweet Home',
			'Tualatin',
			'Veneta',
			'Salt Lake City',
			'Asotin',
			'Basin City',
			'Cashmere',
			'Chehalis',
			'Chelan',
			'Connell',
			'Federal Way',
			'Granite Falls',
			'Newport',
			'North Bend',
			'Orchards',
			'Rosedale',
			'Vancouver',
			'Walla Walla',
			'Cheyenne',
			'Pinedale',
			'Ka‘a‘awa',
			'Butte',
			'Nikiski',
			'Sterling',
			'Valdez',
			'Willow',
			'East Harlem',
			'Village of Campton Hills',
			'Kapolei',
			'Deep River Center',
			'Branford Center',
			'West Falls Church',
			'Saugerties South',
			'Woodside East',
			'Fort Polk North',
			'New Port Richey East',
			'Helena Valley Northeast',
			'North Lakeport',
			'Vincent',
			'Sunnyside-Tahoe City',
			'Picnic Point',
			'Elmendorf Air Force Base',
			'Shoal Creek',
			'Central City',
			'Montura',
			'Delhi Hills',
			'Cherokee',
			'Childersburg',
			'Hanceville',
			'Hayneville',
			'Smiths Station',
			'Sylacauga',
			'Theodore',
			'Eudora',
			'Fairfield Bay',
			'Gassville',
			'Hamburg',
			'Hughes',
			'Jasper',
			'Marianna',
			'Mountain Home',
			'Paragould',
			'Vilonia',
			'Bear',
			'Harrington',
			'Bay Hill',
			'Belle Isle',
			'Boca Raton',
			'Century',
			'Flagami',
			'Fort Myers Beach',
			'Fort Pierce',
			'Fruitville',
			'Gifford',
			'Greenacres City',
			'Hillsboro Beach',
			'Lakeland',
			'Lauderhill',
			'Lighthouse Point',
			'Madeira Beach',
			'Malone',
			'Oviedo',
			'Pinellas Park',
			'Polk City',
			'Ponte Vedra Beach',
			'Saint Augustine Beach',
			"Sewall's Point",
			'Shady Hills',
			'Steinhatchee',
			'Surfside',
			'Sweetwater',
			'Tavares',
			'The Hammocks',
			'Timber Pines',
			'Whitfield',
			'Darien',
			'Doraville',
			'East Griffin',
			'Hawkinsville',
			'Hazlehurst',
			'Morgan',
			'Norcross',
			'North Decatur',
			'Suwanee',
			'West Point',
			'Edwardsville',
			'Germantown',
			'Grayville',
			'Mount Olive',
			// 'Paris',
			'Lawrence',
			'Liberty',
			'New Albany',
			'Seelyville',
			'Washington',
			'Concordia',
			'Douglass',
			'Fairway',
			'Howard',
			'Minneapolis',
			'Coal Run Village',
			'Fort Thomas',
			'Jamestown',
			'Midway',
			'Heritage Creek',
			'Radcliff',
			'Southgate',
			'Vanceburg',
			'Avondale',
			'Franklin',
			'Greenwood',
			'Krotz Springs',
			'Logansport',
			'Many',
			'Poydras',
			'Springhill',
			'Thibodaux',
			'Adamstown',
			'Davidsonville',
			'Hurlock',
			'Jefferson',
			'Keedysville',
			'Kingstown',
			'Largo',
			'Maryland City',
			'Mayo',
			'Middle River',
			'Pikesville',
			'Riverdale Park',
			'Stevensville',
			'Aurora',
			'Carrollton',
			'Cottleville',
			'Country Club Hills',
			'Country Club Village',
			'Des Peres',
			'Independence',
			'Jennings',
			'Oakville',
			'Overland',
			'Piedmont',
			'Rich Hill',
			'Sappington',
			'Waynesville',
			'Helena',
			'Latimer',
			'Madison',
			'Mantachie',
			'Pearl River',
			'Tutwiler',
			'Biltmore Forest',
			'Canton',
			'Cape Carteret',
			'Cherokee',
			'Coats',
			'Davidson',
			'Denver',
			'Fairview',
			'Hickory',
			'Marshall',
			'Mocksville',
			'New Bern',
			'Rhodhiss',
			'Sawmills',
			'Saxapahaw',
			'Seven Lakes',
			'Valley Hill',
			'Woodfin',
			'Absecon',
			'Country Lake Estates',
			'Penns Grove',
			'Pennsauken',
			'Beverly',
			'Centerville',
			'Glouster',
			'Logan',
			'New Concord',
			'Saint Bernard',
			'South Zanesville',
			'Burns Flat',
			'Clinton',
			'Henryetta',
			'Pawnee',
			'Skiatook',
			'Spencer',
			'Taloga',
			'Watonga',
			'Yukon',
			'Midway',
			'New Oxford',
			'Parkside',
			'Shrewsbury',
			'Stonybrook',
			'Fairfax',
			'Florence',
			'Gantt',
			'McColl',
			'North Charleston',
			'Pamplico',
			'Parker',
			'South Congaree',
			'Utica',
			'Woodfield',
			'Colonial Heights',
			'Lexington',
			'New Hope',
			'Park City',
			'Unionville',
			'Waverly',
			'Alvarado',
			'Bacliff',
			'Buna',
			'Calvert',
			'Camp Swift',
			'César Chávez',
			'Danbury',
			'Dickinson',
			'Early',
			'Fifth Street',
			'Hamilton',
			'Hitchcock',
			'Keller',
			'Lake Kiowa',
			'Liberty Hill',
			'Midway North',
			'Muenster',
			'Redwood',
			'Richland Hills',
			'Rollingwood',
			'Saint Jo',
			'Saint Paul',
			'Westlake',
			'Bensley',
			'Brandermill',
			'Charlottesville',
			'Clarksville',
			'Collinsville',
			'East Hampton',
			'Emory',
			'Enon',
			'Glasgow',
			'Independent Hill',
			'Middletown',
			'South Riding',
			'Augusta',
			'Dunbar',
			'Lubeck',
			'Mullens',
			'Sistersville',
			'Webster Springs',
			'Franklin',
			'Clyde',
			'East Haven',
			'Groton',
			'Killingly Center',
			'New Haven',
			'Ridgefield',
			'Salem',
			'Stratford',
			'Aplington',
			'Centerville',
			'Davenport',
			'De Witt',
			'Nevada',
			'Peosta',
			'Remsen',
			'State Center',
			'West Burlington',
			'Bradley',
			'Bull Valley',
			'Bushnell',
			'Dixon',
			'Galena',
			'Gilman',
			'Herscher',
			'Homer Glen',
			'Le Roy',
			'McHenry',
			'Palos Park',
			'Prairie Grove',
			'Skokie',
			'Washington',
			'West Garfield Park',
			'Wheeling',
			'Fort Wayne',
			'Fremont',
			'Geneva',
			'Hudson Lake',
			'Lakes of the Four Seasons',
			'Marion',
			'Montpelier',
			'Osceola',
			'Sweetser',
			'Union City',
			'Winfield',
			'Alturas',
			'Alva',
			'Astatula',
			'Blountstown',
			'Boca Pointe',
			'Chattahoochee',
			'Clarcona',
			'Cypress Gardens',
			'Delray Beach',
			'Dunedin',
			'Eglin Village',
			'Fruitland Park',
			'Kenneth City',
			'Marathon',
			'Miami Springs',
			'Pine Island Center',
			'Placid Lakes',
			'Ridge Manor',
			'Ridgecrest',
			'Weston',
			'Winter Garden',
			'Albany',
			'Alma',
			'Barnesville',
			'Canton',
			'Clarkston',
			'Cordele',
			'Crawfordville',
			'Irwinton',
			'Montgomery',
			'Mount Vernon',
			'Rincon',
			'Roswell',
			'Vienna',
			'Carlinville',
			'Southern View',
			'Corydon',
			'Fortville',
			'Geneva',
			'Jasper',
			'Loogootee',
			'Ashland',
			'Eudora',
			'Gardner',
			'Horton',
			'Marysville',
			'Ness City',
			'Nickerson',
			'Sedgwick',
			'Shawnee',
			'Beaver Dam',
			'Cynthiana',
			'Douglass Hills',
			'Hartford',
			'Munfordville',
			'Sebree',
			'Silver Grove',
			'Van Lear',
			'Barataria',
			'Campti',
			'Columbia',
			'Delhi',
			'Donaldsonville',
			'Elmwood',
			'Kenner',
			'Killian',
			'Natchitoches',
			'Pierre Part',
			'Saint Rose',
			'Sibley',
			'Violet',
			'Westwego',
			'Adelphi',
			'Arlington',
			'Chevy Chase Village',
			'Dunkirk',
			'Huntingtown',
			'Pasadena',
			'Severna Park',
			'Cole Camp',
			'Humansville',
			'Ladue',
			'Lake Saint Louis',
			'Mehlville',
			'New Madrid',
			'Platte City',
			'Raymore',
			'Woodson Terrace',
			'Ackerman',
			'Collins',
			'Pearl',
			'Pittsboro',
			'Raymond',
			'Brices Creek',
			'Dobson',
			'Elizabethtown',
			'Goldsboro',
			'Hillsborough',
			'Holly Ridge',
			'Holly Springs',
			'Kenansville',
			'Kings Grant',
			'Madison',
			'Maiden',
			'Mar-Mac',
			'Mount Gilead',
			'Raleigh',
			'Reidsville',
			'Rose Hill',
			'Roxboro',
			'Sherrills Ford',
			'Saint Stephens',
			'Thurmond',
			'Wilson',
			'Hammonton',
			'Leisuretowne',
			'Linwood',
			'Pleasantville',
			'Richwood',
			'Salem',
			'Springdale',
			'Woodbury Heights',
			'Blacklick Estates',
			'Cheviot',
			'Fairfield Beach',
			'Germantown',
			'Green Meadows',
			'Jeffersonville',
			'Mayfield',
			'Obetz',
			'South Lebanon',
			'West Union',
			'Wetherington',
			'Bethany',
			'Bixby',
			'Central High',
			'Kingston',
			'Muskogee',
			'Collingdale',
			'Fairdale',
			'Sharon Hill',
			'West Grove',
			'York',
			'Andrews',
			'Boiling Springs',
			'Little River',
			'South Sumter',
			'Southern Shops',
			'West Columbia',
			'Ashland City',
			'Clinton',
			'East Brainerd',
			'Englewood',
			'Fairmount',
			'Gleason',
			'Greenbrier',
			'Mount Pleasant',
			'Obion',
			'Pikeville',
			'Winchester',
			'Alvord',
			'Aurora',
			'Bangs',
			'Bertram',
			'Cameron',
			'Cameron Park Colonia',
			'Coleman',
			'Eagle Mountain',
			'Escobares',
			'Gholson',
			'Holliday',
			'Houston',
			'La Feria',
			'Lakeside',
			'Lampasas',
			'Laureles',
			'Lindsay',
			'Munday',
			'New Territory',
			'Pecan Plantation',
			'Portland',
			'Royse City',
			'Sachse',
			'San Juan',
			'Seagoville',
			'Seguin',
			'Somerville',
			'Van Vleck',
			'Waco',
			'Apple Mountain Lake',
			'Brambleton',
			'Colonial Beach',
			'Dumfries',
			'Ettrick',
			'Goochland',
			'Madison Heights',
			'Portsmouth',
			'Radford',
			'South Boston',
			'Springfield',
			'Bethlehem',
			'Huntington',
			'Ashville',
			'Derby',
			'Hayti',
			'Glastonbury',
			'Jewett City',
			'Lake Pocotopaug',
			'Lisbon',
			'Asbury',
			'Belle Plaine',
			'Britt',
			'Centerville',
			'Eddyville',
			'Fayette',
			'Garner',
			'Grinnell',
			'Manly',
			'Rock Valley',
			'Spencer',
			'Springville',
			'Toledo',
			'Barrington',
			'Bensenville',
			'Chatham',
			'Colchester',
			'Farmington',
			'Forest Park',
			'Hinsdale',
			'Ingalls Park',
			'La Salle',
			'Lincolnwood',
			'Mahomet',
			'Marengo',
			'Monee',
			'Mount Pulaski',
			'Tinley Park',
			'Urbana',
			'Vernon Hills',
			'Volo',
			'Winfield',
			'Argos',
			'Battle Ground',
			'Chesterton',
			'Dale',
			'Elkhart',
			'Galveston',
			'Lowell',
			'Rochester',
			'Sheridan',
			'Warren',
			'Amesbury',
			'Center Point',
			'Concord',
			'East Brewton',
			'Gadsden',
			'Graysville',
			'Lincoln',
			'Madison',
			'Millbrook',
			'Moundville',
			'Opp',
			'Rehobeth',
			'Springville',
			'Sumiton',
			'Valley',
			'Alma',
			'Bella Vista',
			'De Witt',
			'Gurdon',
			'New Castle',
			'Brockton',
			'Hampden',
			'Manchester-by-the-Sea',
			'Methuen',
			'Palmer',
			'Peabody',
			'Raynham Center',
			'Southbridge',
			'Wales',
			'West Falmouth',
			'Wilbraham',
			'Benton',
			'Biddeford',
			'Calais',
			'Farmington',
			'Medway',
			'Oxford',
			'Plymouth',
			'Sangerville',
			'South Portland Gardens',
			'Clawson',
			'Edmore',
			'Fair Plain',
			'Gladwin',
			'Grosse Pointe Shores',
			'Houghton',
			'Kentwood',
			'Manistique',
			'Mason',
			'Owosso',
			'Potterville',
			'Saint Charles',
			'Taylor',
			'Cambridge',
			'Cologne',
			'Columbia Heights',
			'Dayton',
			'Glyndon',
			'Goodhue',
			'Le Sueur',
			'Mountain Lake',
			'Plymouth',
			'Rushford',
			'Sartell',
			'Shakopee',
			'Tracy',
			'Vadnais Heights',
			'Wanamingo',
			'Albany',
			'King City',
			'Hillsboro',
			'Pawnee City',
			'Madbury',
			'Mont Vernon',
			'Rumney',
			'Sanbornton',
			'Glen Rock',
			'Iselin',
			'Lincroft',
			'Mountain Lakes',
			'Oakhurst',
			'Passaic',
			'Rutherford',
			'Sayreville Junction',
			'Shrewsbury',
			'Union',
			'Westville',
			'Westwood',
			'Blossvale',
			'Briarcliff Manor',
			'Centereach',
			'Centerport',
			'Corinth',
			'Cortland',
			'East Hills',
			'East Rockaway',
			'Flatbush',
			'Frewsburg',
			'Glen Head',
			'Greenburgh',
			'Hewlett Harbor',
			'Islip',
			'Lakewood',
			'Liberty',
			'Midland Beach',
			'Munsey Park',
			'Nassau',
			'North Bellport',
			'North Sea',
			'Nyack',
			'Old Westbury',
			'Park Slope',
			'Pound Ridge',
			'Rhinebeck',
			'Sidney',
			'South Glens Falls',
			'Spencerport',
			'Stapleton',
			'Thornwood',
			'Westbury',
			'Yonkers',
			'Ansonia',
			'Canfield',
			'Chardon',
			'Cortland',
			'Delta',
			'Edgewood',
			'Findlay',
			'Huber Ridge',
			'Jackson Center',
			'New Philadelphia',
			'New Waterford',
			'Sebring',
			'Swanton',
			'Sylvania',
			'Boiling Springs',
			'Coraopolis',
			'East Pittsburgh',
			'Fox Run',
			'Girardville',
			'Industry',
			'Lawrence Park',
			'Loretto',
			'Manheim',
			'Maple Glen',
			'Midland',
			'Mount Oliver',
			'Mount Wolf',
			'Muncy',
			'North Versailles',
			'Oakland',
			'Pennsburg',
			'Reservoir',
			'Schuylkill Haven',
			'Spinnerstown',
			'Spring Ridge',
			'Steelton',
			'Swoyersville',
			'Union City',
			'Upper Saint Clair',
			'Warren',
			'West Fairview',
			'West Wyomissing',
			'Whitehall Township',
			'Wilmerding',
			'Coventry',
			'North Kingstown',
			'Windsor',
			'Algoma',
			'Barneveld',
			'Colby',
			'Fennimore',
			'Lancaster',
			'Manchester',
			'Nekoosa',
			'Peshtigo',
			'Rib Mountain',
			'Stevens Point',
			'Verona',
			'Waterloo',
			'East Windsor',
			'Branford',
			'Cheshire',
			'Chino Valley',
			'Sahuarita',
			'Agoura Hills',
			'Antioch',
			'Arnold',
			'Bradbury',
			'Byron',
			'Calabasas',
			'Camino',
			'Caruthers',
			'Chowchilla',
			'Costa Mesa',
			'Fairfield',
			'Fort Irwin',
			'Fountain Valley',
			'Fowler',
			'Granite Hills',
			'Highgrove',
			'Hollister',
			'Hollywood',
			'Holtville',
			'Inyokern',
			'Kelseyville',
			'Kentfield',
			'Laguna Niguel',
			'Lakeview',
			'Larkspur',
			'Lincoln Village',
			'Lindsay',
			'Marin City',
			'Maxwell',
			'Mill Valley',
			'Milpitas',
			'Jurupa Valley',
			'Montebello',
			'North Auburn',
			'North Fair Oaks',
			'Oceanside',
			'Palm Springs',
			'Palmdale',
			'Parksdale',
			'Parkway',
			'Pioneer',
			'Rancho Murieta',
			'San Fernando',
			'Seal Beach',
			'Sierra Madre',
			'Sonoma',
			'Soquel',
			'South El Monte',
			'South Lake Tahoe',
			'Thermal',
			'Thermalito',
			'Tierra Buena',
			'Westmorland',
			'Willows',
			'Avon',
			'Brighton',
			'Castle Rock',
			'Cortez',
			'Eads',
			'Grand Junction',
			'Lafayette',
			'Louisville',
			'Thornton',
			'Satanta',
			'Eldorado at Santa Fe',
			'Elephant Butte',
			'Jemez Pueblo',
			'Lee Acres',
			'Loving',
			'Mescalero',
			'Peralta',
			'Tome',
			'Tularosa',
			'Hawthorne',
			'Minden',
			'Pahrump',
			'Sandy Valley',
			'Verdi',
			'Enoch',
			'Gunnison',
			'Bagdad',
			'Rio Dell',
			'Greeley',
			'Grangeville',
			'Homedale',
			'Malad City',
			'Moscow',
			'Rupert',
			'Twin Falls',
			'Wilder',
			'Bozeman',
			'Columbus',
			'Cut Bank',
			'Glasgow',
			'Williston',
			'Kimball',
			'Albany',
			'Bandon',
			'Barview',
			'Hayesville',
			'Hood River',
			'La Grande',
			'McMinnville',
			'Oak Hills',
			'South Lebanon',
			'Tillamook',
			'Onida',
			'Alpine',
			'Bluffdale',
			'Clinton',
			'Coalville',
			'Highland',
			'Pleasant Grove',
			'Roy',
			'Bothell',
			'Covington',
			'Des Moines',
			'Entiat',
			'Fobes Hill',
			'Longbranch',
			'Martha Lake',
			'McMillin',
			'Seattle',
			'Sequim',
			'Shelton',
			'Spokane',
			'Terrace Heights',
			'Waller',
			'Fort Washakie',
			'South Park',
			'Pāhala',
			'Paia',
			'Hawaiian Beaches',
			'Kaanapali Landing',
			'Unalaska',
			'Badger',
			'Helena-West Helena',
			'Roosevelt Gardens',
			'Norton Center',
			'Cecil-Bishop',
			'East Norriton',
			'Village Green-Green Ridge',
			'Grand Forks Air Force Base',
			'West Bloomfield Township',
			'Fort Knox',
			'Clarkston Heights-Vineland',
			'Air Force Academy',
			'Hilmar-Irwin',
			'Summerlin South',
			'Golden Shores',
			'Fruitridge Pocket',
			'West Hammond',
			'The Hideout',
			'West Greenwich',
			'Fairfield Heights',
			'Noe Valley',
			'Toftrees',
			'Rome',
			'Buckland',
			'Charlemont',
			'Easthampton',
			'Hatfield',
			'Hubbardston',
			'Ludlow',
			'Medford',
			'North Chicopee',
			'Rockport',
			'Sharon',
			'Westford',
			'Williamstown',
			'Boothbay Harbor',
			'Brunswick',
			'Canaan',
			'Dover-Foxcroft',
			'Fayette',
			'Limington',
			'Machiasport',
			'Norway',
			'South Eliot',
			'South Paris',
			'Van Buren',
			'Waldoboro',
			'Allen Park',
			'Beaverton',
			'Byron Center',
			'Clinton Township',
			'Cutlerville',
			'Durand',
			'Harper Woods',
			'Iron River',
			'Lake Fenton',
			'Lake Michigan Beach',
			'Livonia',
			'Marlette',
			'Memphis',
			'Mount Morris',
			'Muskegon Heights',
			'North Branch',
			'Rapid City',
			'Rockwood',
			'Vassar',
			'West Branch',
			'Wolf Lake',
			'Arlington',
			'Dundas',
			'Esko',
			'Fulda',
			'Howard Lake',
			'Luverne',
			'New London',
			'North Oaks',
			'Perham',
			'Roseville',
			'Shafer',
			'Central City',
			'Clay Center',
			'Crete',
			'Ravenna',
			'Taylor',
			'Gilford',
			'Hollis',
			'Northwood',
			'Ossipee',
			'Stratham Station',
			'Swanzey',
			'Wakefield',
			'Windham',
			'Bound Brook',
			'Leisure Village',
			'Port Reading',
			'Prospect Park',
			'Saddle River',
			'Somerset',
			'Twin Rivers',
			'Waldwick',
			'Wayne',
			'Albany',
			'Aquebogue',
			'Arcade',
			'Bay Wood',
			'Bayside',
			'Bellerose Terrace',
			'Black River',
			'Blauvelt',
			'Bohemia',
			'Brewerton',
			'Brooklyn Heights',
			'Canandaigua',
			'Cassville',
			'Clarkson',
			'Fairport',
			'Fayetteville',
			'Friendship',
			'Geneseo',
			'New Hempstead',
			'Hudson Falls',
			'Ilion',
			'Islip Terrace',
			'Lakeland',
			'Mariners Harbor',
			'Middle Island',
			'Minoa',
			'Montgomery',
			'Napanoch',
			'New City',
			'New York Mills',
			'Nissequogue',
			'Norfolk',
			'Nunda',
			'Olcott',
			'Point Lookout',
			'Port Byron',
			'Port Jervis',
			'Port Washington',
			'Putnam Lake',
			'Riverside',
			'Rockaway Point',
			'Roslyn Estates',
			'Salamanca',
			'Scotchtown',
			'Shinnecock Hills',
			'Tivoli',
			'Van Nest',
			'Washington Heights',
			'Water Mill',
			'West Nyack',
			'Whitesboro',
			'Woodbury',
			'Worcester',
			'Brilliant',
			'Convoy',
			'Covington',
			'East Palestine',
			'Edgerton',
			'Minerva',
			'Minster',
			'Newcomerstown',
			'Paulding',
			'Strasburg',
			'Westfield Center',
			'Willowick',
			'Wooster',
			'Allentown',
			'Arnold',
			'Belleville',
			'Bethel Park',
			'Browntown',
			'Bryn Mawr',
			'Canton',
			'Clairton',
			'Clymer',
			'Dormont',
			'Emporium',
			'Erie',
			'Frackville',
			'Greenwood',
			'Grill',
			'Hudson',
			'Jefferson Hills',
			'Macungie',
			'Maple Glen',
			'Montgomeryville',
			'Mountain Top',
			'Myerstown',
			'Nazareth',
			'New Berlinville',
			'North Warren',
			'Northampton',
			'Perryopolis',
			'Pittsburgh',
			'Reiffton',
			'Rockledge',
			'Shinglehouse',
			'South Waverly',
			'Telford',
			'Tunkhannock',
			'Vandergrift',
			'Wesleyville',
			'West Conshohocken',
			'West Pittston',
			'Wyncote',
			'Central Falls',
			'New Shoreham',
			'Westerly',
			'Clear Lake',
			'Dakota Dunes',
			'Gregory',
			'Charlotte',
			'Jericho',
			'Manchester Center',
			'Appleton',
			'Augusta',
			'Blair',
			'Cuba City',
			'Genoa City',
			'King',
			'Lac du Flambeau',
			'Lake Hallie',
			'Little Chute',
			'Manitowoc',
			'Milford',
			'Mineral Point',
			'Oakfield',
			'Plover',
			'Seymour',
			'Shullsburg',
			'Tainter Lake',
			'Thorp',
			'Whitehall',
			'Newell',
			'Colchester',
			'Catalina Foothills',
			'Cottonwood',
			'Laveen',
			'Maricopa',
			'Queen Creek',
			'Snowflake',
			'Three Points',
			'Vail',
			'Bonny Doon',
			'Boulder Creek',
			'Brentwood',
			'Cayucos',
			'Ceres',
			'Cherryland',
			'Del Rey',
			'Delano',
			'Duarte',
			'Dunnigan',
			'East Pasadena',
			'Fairfax',
			'Foresthill',
			'Grass Valley',
			'Lake Nacimiento',
			'Livermore',
			'Long Beach',
			'Los Altos',
			'Mission District',
			'Nevada City',
			'Penn Valley',
			'Rio Del Mar',
			'San Antonio Heights',
			'San Leandro',
			'Scotts Valley',
			'South Dos Palos',
			'South San Gabriel',
			'Stratford',
			'Tehachapi',
			'Vandenberg Village',
			'Westmont',
			'Berkley',
			'Carbondale',
			'La Junta',
			'Ponderosa Park',
			'Wheat Ridge',
			'Albuquerque',
			'Arenas Valley',
			'Artesia',
			'Carlsbad',
			'El Cerro',
			'Los Lunas',
			'Nambe',
			'Taos',
			'Tucumcari',
			'Gardnerville Ranchos',
			'Kingsbury',
			'Cactus',
			'Crosbyton',
			'Guthrie',
			'Aurora',
			'LaVerkin',
			'Nephi',
			'Parowan',
			'Spur',
			'Big Park',
			'Ketchikan',
			'Ault',
			'Longmont',
			'Buhl',
			'Burley',
			'Council',
			'Fairfield',
			'Ponderay',
			'Priest River',
			'Sandpoint',
			'Lincoln',
			'Oshkosh',
			'Stockville',
			'Bay City',
			'Canyon City',
			'Myrtle Point',
			'Warrenton',
			'Winston',
			'Custer',
			'Mission',
			'Clearfield',
			'Elwood',
			'Heber City',
			'Midway',
			'Washington Terrace',
			'Woodland Hills',
			'Big Lake',
			'Camas',
			'Chewelah',
			'Lynnwood',
			'Marysville',
			'Morton',
			'Salmon Creek',
			'Thermopolis',
			'Wilson',
			'Waialua',
			'Waimanalo',
			'Honoka‘a',
			'Chevak',
			'Fairbanks',
			'Healy',
			'Kalifornsky',
			'Nome',
			'Mount Vernon',
			'Jefferson Valley-Yorktown',
			'West Caldwell',
			'Inverness Highlands South',
			'Selmont-West Selmont',
			'Lake Mack-Forest Hills',
			'Fort Hood',
			'Inglewood-Finn Hill',
			'Taft Mosswood',
			'Upper Bear Creek',
			'Henderson',
			'Las Palmas II',
			'Star Valley Ranch',
			'Lake Panorama',
			'Colony Park',
			'Allenstown',
			'Stonecrest',
			'South Fulton',
			'Bayou La Batre',
			'Aurora',
			'Birmingham',
			'Georgiana',
			'Hoover',
			'Malvern',
			'Saraland',
			'Shelby',
			'Thomasville',
			'Alexander',
			'Barling',
			'Bethel Heights',
			'Corning',
			'Ozark',
			'West Crossett',
			'Edgemoor',
			'Atlantis',
			'Beacon Square',
			'Boyette',
			'Estero',
			'Fleming Island',
			'Hawthorne',
			'Hernando Beach',
			'Miami',
			'Miami Shores',
			'North Sarasota',
			'Ocean Ridge',
			'Pine Manor',
			'Saint Pete Beach',
			'Sebastian',
			'Virginia Gardens',
			'Weeki Wachee Gardens',
			'West Miami',
			'West Vero Corridor',
			'Williston Highlands',
			'Winter Park',
			'Arlington',
			'Comer',
			'Cornelia',
			'Garden City',
			'Hardwick',
			'Hephzibah',
			'LaFayette',
			'Marshallville',
			'Monroe',
			'Sparks',
			'Varnell',
			'West Point',
			'Ashland',
			'De Soto',
			'Fairview Heights',
			'Flora',
			'Pontoon Beach',
			'Venice',
			'Wayne City',
			'Dale',
			'Greenfield',
			'Hanover',
			'Henryville',
			'Conway Springs',
			'Jetmore',
			'Lyons',
			'Overbrook',
			'Park City',
			'Carrollton',
			'Elkton',
			'Harrodsburg',
			'Mount Washington',
			'Oakbrook',
			'Russell Springs',
			'Galliano',
			'Independence',
			'Inniswold',
			'Kinder',
			'Larose',
			'Merrydale',
			'Scott',
			'Chesapeake Ranch Estates',
			'Coral Hills',
			'Fairland',
			'Green Haven',
			'La Plata',
			'La Vale',
			'Lonaconing',
			'Manchester',
			'Parole',
			'Perryman',
			'Prince Frederick',
			'Robinwood',
			'West Laurel',
			'Woodmore',
			'Advance',
			'Ashland',
			'Bowling Green',
			'Clayton',
			'Desloge',
			'Doniphan',
			'Fair Grove',
			'Hermitage',
			'Montgomery City',
			'Nixa',
			'Plattsburg',
			'Potosi',
			'Aberdeen',
			'Coldwater',
			'Derma',
			'Itta Bena',
			'Lynchburg',
			'New Hope',
			'Sumrall',
			'Vardaman',
			'Atlantic Beach',
			'Bolivia',
			'Columbia',
			'Elon',
			'Etowah',
			'Farmville',
			'Fearrington Village',
			'Franklin',
			'Gaston',
			'Kings Mountain',
			'Mint Hill',
			'Moyock',
			'Mulberry',
			'Murfreesboro',
			'Richlands',
			'Rockwell',
			'Royal Pines',
			'Smithfield',
			'South Rosemary',
			'Winston-Salem',
			'Beach Haven',
			'Kingston Estates',
			'Mystic Island',
			'Pitman',
			'Amelia',
			'Bridgetown',
			'Gallipolis',
			'Goshen',
			'Lincoln Heights',
			'McConnelsville',
			'Oxford',
			'Rosemount',
			'Ross',
			'West Alexandria',
			'Williamsburg',
			'Williamsport',
			'Ardmore',
			'Calera',
			'Edmond',
			'Erick',
			'Geronimo',
			'Wagoner',
			'Atglen',
			'Chester Heights',
			'Darby',
			'Fayetteville',
			'Littlestown',
			'Philadelphia',
			'Aiken',
			'Blackville',
			'Blacksburg',
			'Central',
			'Hopkins',
			'Kershaw',
			'Murphys Estates',
			'Newport',
			'North Augusta',
			'Privateer',
			'Red Hill',
			'Sullivans Island',
			'Baxter',
			'Crossville',
			'Fincastle',
			'Hermitage',
			'Madisonville',
			'Middle Valley',
			'New South Memphis',
			'Baird',
			'Brookside Village',
			'Burkburnett',
			'Caddo Mills',
			'Cockrell Hill',
			'Doolittle',
			'Emory',
			'Farmers Branch',
			'Gainesville',
			'Glen Rose',
			'Harlingen',
			'Haslet',
			'Hico',
			'Hooks',
			'Martindale',
			'New Braunfels',
			'Port Aransas',
			'Port Isabel',
			'Port Lavaca',
			'Queen City',
			'Rio Bravo',
			'Seabrook',
			'Sulphur Springs',
			'Trinity',
			'Tye',
			'Winnie',
			'Winnsboro',
			'Bridgewater',
			'Clifton Forge',
			'Courtland',
			'Dooms',
			'Hybla Valley',
			'Jonesville',
			'Kings Park',
			'Lunenburg',
			'Manassas',
			'Shawnee Land',
			'Weyers Cave',
			'Barboursville',
			'Glenville',
			'Grafton',
			'Harrisville',
			'Paden City',
			'Parsons',
			'Shepherdstown',
			'Weston',
			'Elba',
			'New Kent',
			'Independence',
			'Aliceville',
			'South Coventry',
			'Danbury',
			'East Hampton',
			'Guilford',
			'Montville Center',
			'Old Greenwich',
			'Carroll',
			'Dakota City',
			'Glenwood',
			'Huxley',
			'Osage',
			'Park View',
			'Primghar',
			'Robins',
			'Sidney',
			'Sigourney',
			'Waukon',
			'Wellman',
			'Buffalo Grove',
			'Carol Stream',
			'Channahon',
			'Cuba',
			'Danville',
			'Geneseo',
			'Maywood',
			'Oak Lawn',
			'Oakwood Hills',
			'Park Ridge',
			'Peotone',
			'Rogers Park',
			'Sheridan',
			'Sidney',
			'Steger',
			'Wonder Lake',
			'Bluffton',
			'Daleville',
			'Merrillville',
			'Morocco',
			'Mitchell',
			'New Market',
			'Dunstable',
			'Gloucester',
			'Greenfield',
			'Hadley',
			'Hanson',
			'Holden',
			'Hudson',
			'Hull',
			'Plainville',
			'Quincy',
			'Westhampton',
			'Wrentham',
			'Cornish',
			'Etna',
			'Hebron',
			'Howland',
			'Kennebunk',
			'Madawaska',
			'Orono',
			'Sedgwick',
			'Tremont',
			'West Paris',
			'West Scarborough',
			'Allendale',
			'Ann Arbor',
			'Athens',
			'Baraga',
			'Bridgman',
			'Centreville',
			'Clarkston',
			'Corunna',
			'Crystal Falls',
			'Essexville',
			'Howell',
			'Imlay City',
			'Kilmanagh',
			'Lincoln Park',
			'Marysville',
			'Monroe',
			'Saint Ignace',
			'Walled Lake',
			'Wixom',
			'Yale',
			'Delano',
			'Gaylord',
			'La Crescent',
			'Lester Prairie',
			'Little Canada',
			'Mahtomedi',
			'Newport',
			'Red Lake',
			'Richfield',
			'Savage',
			'Spicer',
			'Memphis',
			'Belcourt',
			'Rolla',
			'Blair',
			'Broken Bow',
			"O'Neill",
			'Concord',
			'Danville',
			'North Conway',
			'Northfield',
			'Caldwell',
			'Delanco',
			'Englewood',
			'Hackensack',
			'Hopewell',
			'Kenvil',
			'Landing',
			'Little Falls',
			'Newark',
			'Pennington',
			'Princeton Meadows',
			'Stanhope',
			'Vista Center',
			'Baldwin',
			'Brockport',
			'Camden',
			'Chinatown',
			'Corona',
			'Cutchogue',
			'East Northport',
			'Far Rockaway',
			'Gardnertown',
			'Grandyle Village',
			'Greene',
			'Hannawa Falls',
			'Hornell',
			'Kensington',
			'Kings Park',
			'Mastic Beach',
			'Mount Sinai',
			'North Syracuse',
			'North Tonawanda',
			'North Wantagh',
			'Norwood',
			'Orange Lake',
			'Perry',
			'Peru',
			'Port Washington North',
			'Rapids',
			'Saint James',
			'Selden',
			'Tuckahoe',
			'Weston Mills',
			'Bedford Heights',
			'Brewster',
			'Brimfield',
			'Carey',
			'Coldwater',
			'Galion',
			'Kirtland',
			'Lakemore',
			'Massillon',
			'McKinley Heights',
			'North Lewisburg',
			'Oakwood',
			'Orwell',
			'Pemberville',
			'Poland',
			'Sheffield Lake',
			'Streetsboro',
			'Wapakoneta',
			'Youngstown',
			'Archbald',
			'Bentleyville',
			'Blakely',
			'Breinigsville',
			'Carbondale',
			'Cheswick',
			'Davidsville',
			'East Berwick',
			'Fivepointville',
			'Girard',
			'Glen Lyon',
			'Glenshaw',
			'Green Tree',
			'Hollidaysburg',
			'Houserville',
			'Indiana',
			'Jim Thorpe',
			'Liberty',
			'Manor',
			'Mechanicsville',
			'Meridian',
			'Middleburg',
			'Millersburg',
			'Monroeville',
			'Montgomery',
			'Park Forest Village',
			'Plymouth',
			'Pottsgrove',
			'Royersford',
			'Schwenksville',
			'Turtle Creek',
			'Waymart',
			'West Reading',
			'Youngsville',
			'North Smithfield',
			'Providence',
			'Warwick',
			'Burke',
			'Crooks',
			'Groton',
			'Barre',
			'Burlington',
			'Mendon',
			'Pownal',
			'Addison',
			'Big Bend',
			'Black Earth',
			'Boscobel',
			'Brillion',
			'Fond du Lac',
			'Lake Geneva',
			'Mazomanie',
			'Muskego',
			'Phillips',
			'Reedsburg',
			'Sturtevant',
			'Turtle Lake',
			'West Liberty',
			'Chinle',
			'Gold Camp',
			'Kachina Village',
			'Kayenta',
			'Linden',
			'Saint David',
			'Sedona',
			'Tempe',
			'Ahwahnee',
			'Alameda',
			'Alamo',
			'Antelope',
			'Anza',
			'Arbuckle',
			'Auberry',
			'Bodfish',
			'Boronda',
			'Covina',
			'East Foothills',
			'Guerneville',
			'Hacienda Heights',
			'Hawthorne',
			'Hidden Meadows',
			'Ione',
			'Kerman',
			'Ladera Ranch',
			'Laguna Woods',
			'Lake San Marcos',
			'Lake Wildwood',
			'Marina',
			'Moreno Valley',
			'Mountain View',
			'Mountain View Acres',
			'Oak Hills',
			'Phelan',
			'Pine Grove',
			'Rosedale',
			'Rossmoor',
			'San Pablo',
			'Santa Paula',
			'Seven Trees',
			'Sherman Oaks',
			'Stanford',
			'Suisun',
			'Tahoe Vista',
			'Thousand Oaks',
			'Twentynine Palms',
			'West Menlo Park',
			'West Puente Valley',
			'Evergreen',
			'Orchard City',
			'Parker',
			'Silt',
			'Stratmoor',
			'Clayton',
			'Lovington',
			'Ponderosa Pine',
			'Cold Springs',
			'Incline Village',
			'Silver Springs',
			'Spanish Springs',
			'Del Rio',
			'Slaton',
			'Hildale',
			'Anthem',
			'Little Cottonwood Creek Valley',
			'Bella Vista',
			'Yreka',
			'Granby',
			'Keenesburg',
			'Lochbuie',
			'Sterling',
			'Aberdeen',
			'Challis',
			'Hayden',
			'Mountain Home',
			'Rexburg',
			'Browning',
			'Libby',
			'Missoula',
			'Ryegate',
			'Whitefish',
			'Kenmare',
			'Killdeer',
			'Lincoln',
			'Valentine',
			'Spring Creek',
			'Coquille',
			'Dunes City',
			'Madras',
			'Monmouth',
			'Newberg',
			'North Bend',
			'North Portland',
			'Philomath',
			'Pilot Rock',
			'Port Orford',
			'Rockaway Beach',
			'Springfield',
			'Williams',
			'Yoncalla',
			'Fort Pierre',
			'Philip',
			'Erda',
			'South Willard',
			'Willard',
			'Woods Cross',
			'Bethel',
			'Bonney Lake',
			'Coulee Dam',
			'Fairwood',
			'Graham',
			'Ocean Park',
			'Moose Wilson Road',
			'Kapaau',
			'Waialua',
			'‘Ele‘ele',
			'Hanapēpē Heights',
			'Iroquois Point',
			'Prudhoe Bay',
			'Wasilla',
			'Big Lake',
			'Johns Creek',
			'Plainfield Village',
			'Aberdeen Proving Ground',
			'Homeacre-Lyndora',
			'Lanham-Seabrook',
			'Columbus Air Force Base',
			'Aptos Hills-Larkin Valley',
			'National Harbor',
			'Lantana',
			'Kings Park West',
			'Eastmont',
			'Pine Ridge',
			'Center City',
			'Plainview',
			'Turpin Hills',
			'Dora',
			'Flint City',
			'Lanett',
			'Midland City',
			'Monroeville',
			'Orange Beach',
			'Ragland',
			'Taylor',
			'Whitesboro',
			'Clinton',
			'Malvern',
			'Pike Creek Valley',
			'Aberdeen',
			'Allapattah',
			'Boca Del Mar',
			'Bristol',
			'Clermont',
			'Destin',
			'El Portal',
			'Englewood',
			'Freeport',
			'Grove City',
			'Harbor Bluffs',
			'Hialeah Gardens',
			'Indialantic',
			'Key Largo',
			'Maitland',
			'Oldsmar',
			'Plant City',
			'Richmond West',
			'South Bradenton',
			'South Apopka',
			'Southwest Ranches',
			'Alpharetta',
			'Bowdon',
			'Cartersville',
			'Decatur',
			'Emerson',
			'Guyton',
			'Homerville',
			'Kingsland',
			'Leesburg',
			'Midway',
			'Waycross',
			'Winterville',
			'Godfrey',
			'Highland',
			'Mascoutah',
			'Mattoon',
			'Mitchell',
			'Roodhouse',
			'Sesser',
			'Wamac',
			'Bloomington',
			'Danville',
			'Farmersburg',
			'Haubstadt',
			'North Terre Haute',
			'Oakland City',
			'Altamont',
			'Edwardsville',
			'Frontenac',
			'Halstead',
			'Kechi',
			'Newton',
			'North Newton',
			'Ottawa',
			'Towanda',
			'WaKeeney',
			'Campbellsville',
			'Cave City',
			'Fort Mitchell',
			'Greensburg',
			'Greenville',
			'Jackson',
			'Masonville',
			'Mount Olivet',
			// 'Paris',
			'Russellville',
			'West Liberty',
			'Ama',
			'Ball',
			'Des Allemands',
			'Rayne',
			'River Ridge',
			'Woodworth',
			'Baden',
			'Ballenger Creek',
			'Berwyn Heights',
			'Calverton',
			'Drum Point',
			'Indian Head',
			'Londontowne',
			'North Kensington',
			'Rossmoor',
			'Taneytown',
			'Clarkson Valley',
			'Eldon',
			'Ellisville',
			'Fulton',
			'Green Park',
			'Hallsville',
			'Murphy',
			'Park Hills',
			'Beechwood',
			'Belmont',
			'Duck Hill',
			'Morgantown',
			'Rolling Fork',
			'Advance',
			'Bethel',
			'Beulaville',
			'Brevard',
			'Chadbourn',
			'Cullowhee',
			'Dunn',
			'East Flat Rock',
			'Mooresville',
			'Newland',
			'Pilot Mountain',
			'Plymouth',
			'Sanford',
			'Statesville',
			'Trent Woods',
			'Wadesboro',
			'Weaverville',
			'White Plains',
			'Wilkesboro',
			'Magnolia',
			'Port Republic',
			'Swedesboro',
			'Columbus',
			'Elmwood Place',
			'Geneva',
			'Golf Manor',
			'Lincoln Village',
			'New Boston',
			'Sharonville',
			'Woodsfield',
			'Boley',
			'Broken Arrow',
			'Hartshorne',
			'Justice',
			'Mannford',
			'Park Hill',
			'Piedmont',
			'Dallastown',
			'Kennett Square',
			'Luzerne',
			'Yorklyn',
			'Batesburg-Leesville',
			'Dentsville',
			'Duncan',
			'Elgin',
			'Gadsden',
			'Hartsville',
			'Ladson',
			'Mauldin',
			'Wedgewood',
			'Apison',
			'Bean Station',
			'Bolivar',
			'Bradford',
			'Bruceton',
			'Olivet',
			'Pegram',
			'Tazewell',
			'Tennessee Ridge',
			'Atlanta',
			'Bartlett',
			'Bishop',
			'Brownsboro',
			'Cactus',
			'Celina',
			'College Station',
			'Cottonwood Shores',
			'Crandall',
			'Fairfield',
			'Fairview',
			'Freeport',
			'Granger',
			'Hallettsville',
			'Irving',
			'Jasper',
			'Kemah',
			'Leon Valley',
			// 'Paris',
			'Pharr',
			'Preston',
			'Prosper',
			'Quanah',
			'Shady Shores',
			'Shiner',
			'Stamford',
			'Sullivan City',
			'Webster',
			'Alexandria',
			'Appomattox',
			'Arlington',
			'Bellwood',
			'Carrollton',
			'Chatmoss',
			'Chilhowie',
			'Covington',
			'Hampton',
			'Lynchburg',
			'Marshall',
			'Poquoson',
			'Seven Corners',
			'Weber City',
			'Woodlawn',
			'Blennerhassett',
			'Bridgeport',
			'Fayetteville',
			'Madison',
			'New Martinsville',
			'Pinch',
			'Rush',
			'Higganum',
			'Ledyard',
			'Pawcatuck',
			'Preston City',
			'Southport',
			'Trumbull',
			'Audubon',
			'Clarinda',
			'Corning',
			'Estherville',
			'Fort Dodge',
			'Grundy Center',
			'Guthrie Center',
			'Hudson',
			'Mechanicsville',
			'Mount Vernon',
			'Oakland',
			'Villisca',
			'Washington',
			'Waterloo',
			'Windsor Heights',
			'East Dubuque',
			'Grant Park',
			'Irving Park',
			'Kankakee',
			'Naperville',
			'Oak Brook',
			'Rome',
			'Uptown',
			'Venetian Village',
			'Woodstock',
			'Wyoming',
			'Burns Harbor',
			'Garrett',
			'Granger',
			'Lake Dalecarlia',
			'Logansport',
			'Mulberry',
			'Noblesville',
			'Tri-Lakes',
			'Yorktown',
			'Acton',
			'Lincolnville',
			'Milford',
			'South Thomaston',
			'Warren',
			'York Beach',
			'Bridgeport',
			'Caledonia',
			'Cheboygan',
			'Madison Heights',
			'Manistee',
			'Millington',
			'Norway',
			'Portland',
			'River Rouge',
			'White Pigeon',
			'Zilwaukee',
			'Circle Pines',
			'Clearwater',
			'Dodge Center',
			'Elgin',
			'Farmington',
			'Menahga',
			'Olivia',
			'Orono',
			'Owatonna',
			'Parkville',
			'Spring Grove',
			'Winthrop',
			'Wyoming',
			'Oakes',
			'Rugby',
			'Falls City',
			'Geneva',
			'Antrim',
			'Chichester',
			'Springfield',
			'Butler',
			'Colonia',
			'Edison',
			'Englishtown',
			'High Bridge',
			'Hillside',
			'Laurence Harbor',
			'New Egypt',
			'New Providence',
			'Ridgefield',
			'Roebling',
			'Rumson',
			'Springfield',
			'Teaneck',
			'West Milford',
			'Belmont',
			'Canisteo',
			'Carthage',
			'Dannemora',
			'Falconer',
			'Forest Hills',
			'Fort Edward',
			'Fort Wadsworth',
			'Glen Cove',
			'Gloversville',
			'Greenport',
			'Henrietta',
			'Huguenot',
			'Lancaster',
			'Lewiston',
			'Little Neck',
			'Little Valley',
			'Mayville',
			'McKownville',
			'Montauk',
			'Monticello',
			'Morrisonville',
			'Northwest Harbor',
			'Plainview',
			'Red Oaks Mill',
			'Tompkinsville',
			'Town Line',
			'Washington Heights',
			'Waterville',
			'Webster',
			'Woodlawn',
			'Arlington',
			'Avon Center',
			'Bellevue',
			'Brunswick',
			'Canton',
			'Chagrin Falls',
			'Clyde',
			'Greensburg',
			'Hiram',
			'Painesville',
			'Saint Paris',
			'Silver Lake',
			'Uniontown',
			'Warrensville Heights',
			'West Hill',
			'Altoona',
			'Berwick',
			'Bloomfield',
			'Blossburg',
			'Central City',
			'Chalfont',
			'Doylestown',
			'East Conemaugh',
			'East McKeesport',
			'Evans City',
			'Fernway',
			'Industry',
			'Irwin',
			'Latrobe',
			'Leesport',
			'Lower Burrell',
			'Milesburg',
			'Mill Hall',
			'Moscow',
			'Mount Penn',
			'New Bloomfield',
			'Newville',
			'Pocono Pines',
			'Rankin',
			'Red Lion',
			'Stoneboro',
			'Swissvale',
			'Sykesville',
			'Wernersville',
			'Wilkes-Barre',
			'Williamsport',
			'Wolfdale',
			'Zion',
			'Smithfield',
			'Clark',
			'Milbank',
			'Bellows Falls',
			'Brattleboro',
			'Bristol',
			'Hartford',
			'Hinesburg',
			'Northfield',
			'Rutland',
			'Winooski',
			'Beaver Dam',
			'Boyceville',
			'Butler',
			'Cornell',
			'Hartland',
			'Hilbert',
			'Lake Wisconsin',
			'Nashotah',
			'Plymouth',
			'River Hills',
			'West Salem',
			'Blackwater',
			'Florence',
			'Kingman',
			'Naco',
			'San Carlos',
			'Sun Valley',
			'Aguanga',
			'Citrus',
			'Cloverdale',
			'Colusa',
			'Corcoran',
			'Culver City',
			'Danville',
			'Diablo',
			'El Segundo',
			'Fullerton',
			'Garden Grove',
			'Hanford',
			'Kenwood',
			'Laguna Beach',
			'Lake Arrowhead',
			'Live Oak',
			'Loomis',
			'Mead Valley',
			'Newark',
			'Oak View',
			'Pine Mountain Club',
			'Pixley',
			'Pollock Pines',
			'Rosemont',
			'San Francisco',
			'San Pedro',
			'Santa Rosa',
			'Sausalito',
			'Squaw Valley',
			'Venice',
			'Westlake Village',
			'Winton',
			'Woodland',
			'Aspen',
			'Black Forest',
			'Buena Vista',
			'El Jebel',
			'Fruita',
			// 'Glendale',
			'Parachute',
			'Silverthorne',
			'Welby',
			'Corrales',
			'La Cienega',
			'Boulder City',
			'Beaver',
			'Jayton',
			'Blanding',
			'Salina',
			'Santaquin',
			'Wellington',
			'Arizona City',
			'Juneau',
			'Hydesville',
			'Weaverville',
			'Niwot',
			'Driggs',
			'Big Timber',
			'Bigfork',
			'Great Falls',
			'Hardin',
			'Livingston',
			'Alliance',
			'Gothenburg',
			'Hubbard',
			'Jennings Lodge',
			'Lake Oswego',
			'Lakeside',
			'Mission',
			'Mulino',
			'Raleigh Hills',
			'Turner',
			'Vale',
			'West Haven',
			'West Linn',
			'Eagle Butte',
			'Pierre',
			'Rapid Valley',
			'Ballard',
			'Kaysville',
			'Syracuse',
			'Amboy',
			'Arlington',
			'Ellensburg',
			'Everson',
			'Geneva',
			'Greenwood',
			'Kingston',
			'Spanaway',
			'Yacolt',
			'Green River',
			'Kealakekua',
			'Kīhei',
			'Anchor Point',
			'Bear Creek',
			'Milton',
			'Rancho Tehama Reserve',
			'Beaverdam Lake-Salisbury Mills',
			'Plattsburgh West',
			'Lynnwood-Pricedale',
			'Wilson-Conococheague',
			'Montrose-Ghent',
			'Scott Air Force Base',
			'Eglin Air Force Base',
			'Central Heights-Midland City',
			'Fox Farm-College',
			'Gunbarrel',
			'Helena Valley Northwest',
			'Helena West Side',
			'Nellis Air Force Base',
			'Port Angeles East',
			'Valencia West',
			'North Fork',
			'Scenic',
			'Sun Village',
			'Quail Ridge',
			'Eagleview',
			'Savannah',
			'Cordry Sweetwater Lakes',
			'Notre Dame',
			'Lake Stickney',
			'Kula',
			'Union City',
			'Communications Hill',
			'Abilene',
			'Cottonwood Falls',
			'Council Grove',
			'Osawatomie',
			'Rossville',
			'Tonganoxie',
			'Topeka',
			'Wichita',
			'Audubon Park',
			'Cumberland',
			'Hendron',
			'Hyden',
			'Jenkins',
			'Pineville',
			'Princeton',
			'Russell',
			'Scottsville',
			'Valley Station',
			'Albany',
			'Arabi',
			'Cameron',
			'Edgard',
			'Jeanerette',
			'Jefferson',
			'Jonesboro',
			'Lake Charles',
			'Lutcher',
			'Oak Grove',
			'Saint Gabriel',
			'Shreveport',
			'Sunset',
			'Westlake',
			'Winnfield',
			'Woodmere',
			'Youngsville',
			'Baltimore Highlands',
			'Brooklyn',
			'Brookmont',
			'Bryans Road',
			'Burtonsville',
			'Clinton',
			'Hancock',
			'Hanover',
			'Myersville',
			'North Bel Air',
			'Shady Side',
			'Black Jack',
			'Brentwood',
			'Dexter',
			'Eureka',
			'Gray Summit',
			'Kearney',
			'Lathrop',
			'Lone Jack',
			'Maryland Heights',
			'Mount Vernon',
			'New Franklin',
			'Ozark',
			'Sainte Genevieve',
			'Salisbury',
			'Union',
			'Vinita Park',
			'Warrensburg',
			'Warrenton',
			'Guntown',
			'Hickory Hills',
			'Pontotoc',
			'Ahoskie',
			'Belhaven',
			'Belmont',
			'Bermuda Run',
			'Cramerton',
			'Emerald Isle',
			'Gastonia',
			'High Point',
			'Kernersville',
			'Lincolnton',
			'Morganton',
			'Robersonville',
			'Walkertown',
			'Alloway',
			'Barnegat',
			'Buena',
			'Clayton',
			'Folsom',
			'Lavallette',
			'Presidential Lakes Estates',
			'Tuckerton',
			'Blue Ash',
			'Loveland Park',
			'Mount Orab',
			'Pataskala',
			'Springboro',
			'Chelsea',
			'Hennessey',
			'Panama',
			'Salina',
			'East York',
			'Georgetown',
			'Lansdowne',
			'Parkesburg',
			'Spring Grove',
			'Springfield',
			'Strasburg',
			'Arial',
			'Belvedere',
			'Bluffton',
			'Fairforest',
			'Greer',
			'Kiawah Island',
			'North Hartsville',
			'Red Bank',
			'Sangaree',
			'Alamo',
			'Central',
			'Collegedale',
			'Lafayette',
			'Parsons',
			'Ridgetop',
			'Smithville',
			'Anahuac',
			'Bellmead',
			'Belton',
			'Breckenridge',
			'Burleson',
			'Canton',
			'Colleyville',
			'Converse',
			'Dallas',
			'Dublin',
			'Forney',
			'Goldthwaite',
			'Graham',
			'Grapeland',
			'Green Valley Farms',
			'Helotes',
			'Huntington',
			'Jacinto City',
			'Marion',
			'McLendon-Chisholm',
			// 'Naples',
			'Odem',
			'Old River-Winfree',
			'Pasadena',
			'Pflugerville',
			'Runge',
			'Sabinal',
			'Santa Anna',
			'South Alamo',
			'Taylor Lake Village',
			'Tom Bean',
			'University Park',
			'White Oak',
			'Blue Ridge',
			'Castlewood',
			'Deltaville',
			'Eastville',
			'Floyd',
			'Highland Springs',
			'Lake Monticello',
			'Lake Ridge',
			'Martinsville',
			'New Market',
			'North Shore',
			'Raven',
			'Windsor',
			'Woodbridge',
			'Inwood',
			'McMechen',
			'Montgomery',
			'Nutter Fort',
			'Parkersburg',
			'Sissonville',
			'Sutton',
			'Arab',
			'Hamden',
			'Burlington',
			'Eagle Grove',
			'Lamoni',
			'Osceola',
			'Wapello',
			'Wilton',
			'Bridgeport',
			'Champaign',
			'Coal Valley',
			'Crystal Lake',
			'Glen Ellyn',
			'Harwood Heights',
			'Lynwood',
			'Maroa',
			'Marseilles',
			'Minooka',
			'Roselle',
			'Rosemont',
			'Round Lake Beach',
			'St. Charles',
			'Sauk Village',
			'Warren',
			'Zion',
			// 'Hobart',
			'Kendallville',
			'New Chicago',
			'Plymouth',
			'Portland',
			'Tipton',
			'Winona Lake',
			'Woodburn',
			'Cowarts',
			'Crossville',
			'Gardendale',
			'Loxley',
			'Phil Campbell',
			'Pinson',
			'Steele',
			'Tuskegee',
			'Union Springs',
			'Brinkley',
			'Brookland',
			'Cave Springs',
			'Conway',
			'El Dorado',
			'Forrest City',
			'Highland',
			'Mena',
			'Osceola',
			'Pea Ridge',
			'Rector',
			'Rogers',
			'Stamps',
			'Ward',
			'Rodney Village',
			'Babson Park',
			'Callaway',
			'Cocoa',
			'Crawfordville',
			'Davenport',
			'Doral',
			'Fountainebleau',
			'Gulf Gate Estates',
			"Hill 'n Dale",
			'Indian River Shores',
			'Interlachen',
			'Juno Beach',
			'Lake Clarke Shores',
			'Lake Park',
			'Lake Mary',
			'Lakeland Highlands',
			'Merritt Island',
			'Milton',
			'North Lauderdale',
			'Oakland',
			'Palm Beach Shores',
			'Parkland',
			'Pine Island Ridge',
			'Port LaBelle',
			'Richmond Heights',
			'Rotonda West',
			'Seminole Manor',
			'Tavernier',
			'Three Lakes',
			'Three Oaks',
			'Valparaiso',
			'Vineyards',
			'West Bradenton',
			'West Pensacola',
			'Wilton Manors',
			'Yalaha',
			'Appling',
			'Belvedere Park',
			'Brookhaven',
			'Georgetown',
			'Lakeview Estates',
			'Loganville',
			'Lyons',
			'McDonough',
			'Newnan',
			'Oakwood',
			'Thomasville',
			'Beckemeyer',
			'Centralia',
			'Cerro Gordo',
			'Fairmont City',
			'Hardin',
			'Kincaid',
			'Vienna',
			'Charlestown',
			'Melody Hill',
			'Paoli',
			'Travelers Rest',
			'Coalfield',
			'Decaturville',
			'Midtown',
			'Amherst Center',
			'Boylston',
			'Dennis',
			'Eastham',
			'Ipswich',
			'Marlborough',
			'Merrimac',
			'New Marlborough',
			'North Adams',
			'North Scituate',
			'Norton',
			'South Boston',
			'South Hadley',
			'Templeton',
			'Watertown',
			'Whitinsville',
			'Winchendon',
			'Boothbay',
			'Brownfield',
			'Eliot',
			'Hallowell',
			'Levant',
			'Lisbon Falls',
			'Livermore Falls',
		],
	},
	Uruguay: {
		country: 'Uruguay',
		cities: [
			'Treinta y Tres',
			'Santa Bernardina',
			'San Antonio',
			'Pueblo Centenario',
			'Nueva Palmira',
			'Las Piedras',
			'Villa Constitución',
			'Sauce',
			'Pando',
			'Minas de Corrales',
			'José Pedro Varela',
			'Villa Soriano',
			'Isidoro Noblía',
			'Carmelo',
			'Rosario',
			'Empalme Olmos',
			'Durazno',
			'Atlántida',
			'Solís de Mataojo',
			'San José de Mayo',
			'Libertad',
			'Curtina',
			'Rodríguez',
			'Nuevo Berlín',
			'Nueva Helvecia',
			'Fray Bentos',
			'Cebollatí',
			'Cardona',
			'Soca',
			'Rafael Perazza',
			'Ombúes de Lavalle',
			'Juanicó',
			'Puntas de Valdéz',
			'Pajas Blancas',
			'Villa Sara',
			'Trinidad',
			'Tranqueras',
			'Tala',
			'Tacuarembó',
			'Rocha',
			'Minas',
			'Mercedes',
			'Lascano',
			'Barros Blancos',
			'Sarandí Grande',
			'San Félix',
			'Colonia del Sacramento',
			'Young',
			'Toledo',
			'Santa Clara de Olimar',
			'San Ramón',
			'San Jacinto',
			'La Paz',
			'Bella Unión',
			'Belén',
			'Artigas',
			'Punta del Este',
			'Progreso',
			'La Floresta',
			'Alejandro Gallinal',
			'Colonia Nicolich',
			'Villa del Carmen',
			'25 de Mayo',
			'Tomás Gomensoro',
			'Piriápolis',
			'Piedras Coloradas',
			'Montevideo',
			'Florida',
			'Baltasar Brum',
			'Tambores',
			'25 de Agosto',
			'Melo',
			'Castillos',
			'Canelones',
			'Aguas Corrientes',
			'Tarariras',
			'Pueblo Sequeira',
			'San Carlos',
			'Rivera',
			'Estación Porvenir',
			'Pan de Azúcar',
			'Palmitas',
			'Las Piedras',
			'La Paloma',
			'Dolores',
			'Los Cerrillos',
			'Río Branco',
			'José Enrique Rodó',
			'Florencio Sánchez',
			'Casupá',
			'Barra de Carrasco',
			'Velázquez',
			'Salto',
			'Paso de Carrasco',
			'La Paloma',
			'Cardal',
			'San Javier',
			'Maldonado',
			'Guichón',
			'Colonia Valdense',
			'General Enrique Martínez',
			'Vichadero',
			'Tupambaé',
			'Santa Rosa',
			'Quebracho',
			'Ecilda Paullier',
			'Aceguá',
			'Santa Catalina',
			'Montes',
			'Migues',
			'Juan L. Lacaze',
			'José Batlle y Ordóñez',
			'Santiago Vázquez',
			'Santa Lucía',
			'Paso de los Toros',
			'Las Toscas',
			'Aiguá',
			'Joaquín Suárez',
			'Costa Azul',
			'Sarandí del Yi',
			'Santa Teresa',
			'Porvenir',
			'Delta del Tigre',
			'Chui',
			'Vergara',
			'San Bautista',
			'Paysandú',
			'Mariscala',
			'Blanquillo',
		],
	},
	Uzbekistan: {
		country: 'Uzbekistan',
		cities: [
			'Romitan Shahri',
			'Yangi-Nishon Shahri',
			'Dashtobod',
			'Payshamba Shahri',
			'Marhamat',
			'Beruniy',
			'Quva',
			'Mug‘lon Shahar',
			'Qarshi',
			'Kattaqo’rg’on Shahri',
			'Gurlan',
			'Amir Timur',
			'Do’stlik Shahri',
			'Kyzyldzhar',
			'Beshariq',
			'Qanliko‘l',
			'Qŭnghirot',
			'Kegeyli Shahar',
			'Payariq Shahri',
			'Qorako‘l Shahri',
			'Oqtosh',
			'Zafar',
			'Toshloq',
			'Novyy Turtkul’',
			'Mŭynoq',
			'Jomboy Shahri',
			'Charxin',
			'Oqtosh Shahri',
			'Qorashina',
			'Muborak Shahri',
			'Uychi',
			'Toshbuloq',
			'Xo‘jaobod',
			'Chirchiq',
			'Qo’shrabod',
			'Toyloq Qishlog’i',
			'Galaosiyo Shahri',
			'Qo‘rg‘ontepa',
			'Kirguli',
			'Xonqa',
			'Druzhba',
			'Qorovul',
			'Shohimardon',
			'Ravon',
			'Yangiyŭl',
			'Olmaliq',
			'Gagarin Shahri',
			'Nurota Shahri',
			'Tŭragŭrghon Shahri',
			'Shumanay Shahri',
			'Tirmiz',
			'Sirdaryo',
			'Qorao‘zak',
			'Jizzax',
			'Karmana Shahri',
			'Sultonobod',
			'Usmat Shaharchasi',
			'Urgut Shahri',
			'Bukhara',
			'Beshkent Shahri',
			"Bog'don",
			'Uchqŭrghon Shahri',
			'Dang‘ara',
			'Baliqchi',
			'Oxunboboyev',
			'Xo‘jayli Shahri',
			'Kitob',
			'Qorovulbozor Shahri',
			'Shofirkon Shahri',
			'Kuyganyor',
			'Qibray',
			'Chust',
			'Beshrabot',
			'Denov',
			'Bulung’ur Shahri',
			'G’oliblar Qishlog’i',
			'Urganch',
			'Rishton',
			'Ohangaron',
			'Navoiy',
			'Manghit',
			'Bo‘ston Shahri',
			'Namangan',
			'Iskandar',
			'Oqmang‘it',
			'Muborak',
			'Yangi Mirishkor',
			'Koson',
			'Chiroqchi',
			'Beshkent',
			'Olot Shahri',
			'Koson Shahri',
			'G‘uzor Shahri',
			'Vodil',
			'Vobkent Shahri',
			'Poytug‘',
			'Qiziltepa',
			'Oltinko‘l',
			'Qamashi Shahri',
			'Uchtepa Qishlog’i',
			'Nurota',
			'Izboskan',
			'G‘ozg‘on',
			'Beruniy Shahri',
			'Jondor Shaharchasi',
			'Samarkand',
			'Yangibozor',
			'Salor',
			'Bog’ot',
			'Shumanay',
			'Karakul’',
			'Yozyovon',
			'Sho‘rsuv',
			'Haqqulobod',
			'Tinchlik',
			'Nukus',
			'Loyish Shaharchasi',
			'Ziyodin Shaharchasi',
			'Marhamat',
			'Yangi Marg‘ilon',
			'G’ijduvon Shahri',
			'Chinoz',
			'Andijon',
			'Qozonketkan',
			'Zarbdor Shaharchasi',
			'Yangirabot',
			'Yangiariq',
			'Tashkent',
			'Pop',
			'Bektemir',
			'Oqqo‘rg‘on',
			'To‘rtko‘l Shahri',
			'Yangiyer',
			'Ŭrtaowul',
			'Marg‘ilon',
			'Guliston',
			'Eskiarab',
			'G‘uzor',
			'Zafarobod Shaharchasi',
			'Yangiqo‘rg‘on',
			'Yangiobod',
			'Uchko‘prik',
			'Shahrixon',
			'Parkent',
			'Mo‘ynoq Shahri',
			'Ishtixon Shahri',
			'Shovot',
			'Qo’shko’pir',
			'G‘azalkent',
			'Fergana',
			'Mang‘it Shahri',
			'Qiziltepa Shahri',
			'Chelak',
			'Konimex',
			'Bekobod',
			'Chimboy Shahri',
			'Juma Shahri',
			'Kitob Shahri',
			'To‘rqao‘rg‘on',
			'Taxtako‘pir',
			'Navbahor',
			'Quvasoy',
			'Xiva',
			'Dardoq',
			'Yaypan',
			'Ibrat',
			'Tŭytepa',
			'Paxtaobod',
			'Hazratishoh',
			'Qorasuv',
			'Jalolquduq',
			'Oqoltin',
			'Uchquduq Shahri',
			'Zomin Shaharchasi',
			'Shahrisabz',
			'Qorovulbozor',
			'Boysun',
			'Shahrisabz Shahri',
			'Yakkabog‘ Shahri',
			'Piskent',
			'Hazorasp',
			'Bag‘dod',
			'Kogon Shahri',
			'Paxtakor Shahri',
			'Asaka',
			'G’allaorol Shahri',
			'Buloqboshi',
			'Oltiariq',
			'Qizilcha',
			'Chortoq Shahri',
			'Chiroqchi Shahri',
			'Kosonsoy',
			'Angren',
			'Nishon Tumani',
			'Sho‘rchi',
			'Xonobod',
			'Hamza',
			'Chortoq',
			'Bo‘ka',
			'Oyim',
			'Pop Shahri',
			'Langar',
			'Oltinko‘l',
			'Qo‘ng‘irot Shahri',
			'Nurobod Shahri',
			'Dahbed',
			'Yangibozor Qishlog’i',
			'Tomdibuloq',
			'Qo‘qon',
			'Gazli',
			'Kosonsoy Shahri',
		],
	},
	Vanuatu: { country: 'Vanuatu', cities: ['Saratamata', 'Sola', 'Isangel', 'Luganville', 'Lakatoro', 'Norsup', 'Port-Vila', 'Port-Olry'] },
	'Venezuela, Bolivarian Rep. of': {
		country: 'Venezuela, Bolivarian Rep. of',
		cities: [
			'Zaraza',
			'Villa de Cura',
			'Urachiche',
			'Tunapuy',
			'Tabay',
			'Santa María de Caparo',
			'Colón',
			'Quíbor',
			'Puerto Cumarebo',
			'Palmira',
			'Ocumare de la Costa',
			'La Unión',
			'La Luz',
			'La Concepción',
			'Ciudad De Nutrias',
			'Cabimas',
			'Zea',
			'Valera',
			'Tucaní',
			'Temblador',
			'Seboruco',
			'Sarare',
			'San Silvestre',
			'Pueblo Nuevo',
			'Papelón',
			'Pampatar',
			'Mamporal',
			'El Sombrero',
			'El Corozo',
			'El Cobre',
			'Cúpira',
			'Coloncito',
			'Chejendé',
			'Caraballeda',
			'Camatagua',
			'Boraure',
			'Biscucuy',
			'Aragua de Barcelona',
			'Altagracia de Orituco',
			'Tocuyito',
			'Turmero',
			'San José de Barlovento',
			'Río Caribe',
			'Mariara',
			'Las Vegas',
			'El Corozo',
			'Barinitas',
			'San José de Guaribe',
			'Yaguaraparo',
			'Valle de La Pascua',
			'Tocuyo de La Costa',
			'San Antonio',
			'Macuto',
			'Machiques',
			'Guanta',
			'Elorza',
			'Duaca',
			'Cumanacoa',
			'Concepción',
			'Clarines',
			'Ciudad Bolivia',
			'Chichiriviche',
			'Chaguaramas',
			'Chacao',
			'Cabure',
			'Boca de Aroa',
			'Bachaquero',
			'Tocópero',
			'San Mateo',
			'San Casimiro',
			'Nueva Bolivia',
			'Nirgua',
			'Maracaibo',
			'La Paragua',
			'Sierra Imataca',
			'San Fernando de Apure',
			'La Mula',
			'Táriba',
			'Sabana de Mendoza',
			'Palo Negro',
			'Mérida',
			'Las Tejerías',
			'Concepción',
			'El Limón',
			'Caucagua',
			'Caicara del Orinoco',
			'Aroa',
			'Aricagua',
			'Isla Ratón',
			'Yumare',
			'Villa Bruzual',
			'San Lorenzo',
			'San Joaquín',
			'Rubio',
			'Puerto Ayacucho',
			'Pregonero',
			'Pedraza La Vieja',
			'Lobatera',
			'Guaraque',
			'Encontrados',
			'El Palmar',
			'Calderas',
			'Cabudare',
			'Betijoque',
			'Ureña',
			'Sinamaica',
			//'Santa Ana',
			'San Rafael',
			'Pedernales',
			'La Plaza Paraguachi',
			'Mucuchíes',
			'Mene de Mauroa',
			'Guanarito',
			'Charallave',
			'Los Puertos de Altagracia',
			'El Chaparro',
			'Santa Cruz de Guacas',
			'Las Casitas del Vegon de Nutrias',
			'Siquisique',
			'San Timoteo',
			'San Francisco',
			'San Carlos del Zulia',
			'Punta de Mata',
			'Ocumare del Tuy',
			'Lagunillas',
			'El Socorro',
			'El Paradero',
			'Ciudad Piar',
			'Casigua El Cubo',
			'Boca de Río',
			'Aricagua',
			'Araure',
			'La Dolorita',
			'Tinaquillo',
			'Santa Rosa',
			'Santa Inés',
			'Palmasola',
			'Ortiz',
			'Judibana',
			'Juan Griego',
			'Guarenas',
			'Farriar',
			'San Rafael del Piñal',
			'Curbatí',
			'Araya',
			'La Fría',
			'Torondoy',
			'Tía Juana',
			'Santa Lucía',
			'San Rafael de Onoto',
			'San Pablo',
			'San Diego',
			'San Antonio de Los Altos',
			'Punto Fijo',
			'Mene Grande',
			'Marigüitar',
			'Los Teques',
			'Chameta',
			'Carora',
			'San Sebastián',
			'San José de Aerocuar',
			'San Felipe',
			'La Villa del Rosario',
			'Quiriquire',
			'Monte Carmelo',
			'Maripa',
			'Kanavayén',
			'El Tocuyo',
			'Chivacoa',
			'Caucagüito',
			'Arenas',
			'Valencia',
			'Urumaco',
			'Uracoa',
			'Santa Cruz de Bucaral',
			'San Simón',
			'San Pedro de Coche',
			'San Juan Bautista',
			'Sanare',
			'Píritu',
			'Ospino',
			'El Vigía',
			'El Pao',
			'Cúa',
			'Carache',
			'Canape Capatárida',
			'Cantaura',
			'Bejuma',
			'Arismendi',
			'Sabana de Parra',
			'Mapire',
			'Caramuca',
			'Barinas',
			'Guasdualito',
			'San Juan de Payara',
			'Santa María de Ipire',
			//'Santa Ana',
			'San Cristóbal',
			'San Antonio del Táchira',
			'Sabaneta',
			'Puerto de Nutrias',
			'Los Dos Caminos',
			'Irapa',
			'El Valle del Espíritu Santo',
			'El Palmer',
			'Caicara',
			'Boca de Uchire',
			'Upata',
			'Tinaco',
			'Santa Rita',
			'Santa Catalina',
			'Santa Apolonia',
			'Queniquea',
			'Píritu',
			'Naguanagua',
			'Miranda',
			'Libertad',
			'Capacho Viejo',
			'La Quebrada',
			'La Azulita',
			'Guanare',
			'El Tigre',
			'El Regalo',
			'Aguasay',
			'Agua Blanca',
			'Alto Barinas',
			'Tucupita',
			'Santa Cruz de Mora',
			'Puerto La Cruz',
			'Pueblo Llano',
			'Maiquetía',
			'Cagua',
			'San José de Bolívar',
			'Maturín',
			'Independencia',
			'El Real',
			'Yaritagua',
			'Santa Teresa del Tuy',
			'Porlamar',
			'Naiguatá',
			'Michelena',
			'La Guaira',
			'Jacura',
			'El Toro',
			'Cumaná',
			'Barbacoas',
			'Santa Cruz de los Taques',
			'La Asunción',
			'Anaco',
			'Tucacas',
			'San Antonio del Golfo',
			'Punta Cardón',
			'Petare',
			'Pampán',
			'Maroa',
			'La Victoria',
			'Chiquinquirá',
			'Calabozo',
			'Bum Bum',
			'Boconoito',
			'Biruaca',
			'Altamira',
			'Achaguas',
			'El Playón',
			'Tovar',
			'Torunos',
			'Santa Bárbara',
			'San Luis',
			'Pueblo Nuevo El Chivo',
			'Obispos',
			'Motatán',
			'Las Mercedes',
			'La Plata',
			'El Hatillo',
			'Casanay',
			'Campo Elías',
			'Baruta',
			'Tumeremo',
			'Tucupido',
			'Timotes',
			'Socopó',
			'San Mateo',
			'San Juan de los Cayos',
			'Sabana Grande',
			'Punta de Piedra',
			'Pedregal',
			'La Esmeralda',
			'Güiria',
			'El Dividive',
			'Dolores',
			'Curiapo',
			'Cocorote',
			'Caracas',
			'Caja Seca',
			'La Tendida',
			'Soledad',
			'Santo Domingo',
			'Santa Elena de Uairén',
			'Santa Cruz de Mara',
			'Santa Bárbara',
			//'Santa Ana',
			'Puerto Píritu',
			'Maporal',
			'Los Rastrojos',
			'La Cruz de Taratara',
			'Güigüe',
			'Guatire',
			'Los Roques',
			'El Callao',
			'Cojedes',
			'Carrizal',
			'Boconó',
			'Bailadores',
			'Abejales',
			'Las Mesas',
			'Los Guasimitos',
			'Santa Isabel',
			'Santa Elena de Arenales',
			'San Fernando de Atabapo',
			'Quebrada Seca',
			'Mirimire',
			'Libertad',
			'Veguitas',
			'La Sabana',
			'Ciudad Guayana',
			'Churuguara',
			'Caripito',
			'Bobures',
			'Barcelona',
			'El Junko',
			'Puerto Vivas',
			'Umuquena',
			'San José de Guanipa',
			'San Carlos de Río Negro',
			'Punta de Piedras',
			'Puerto Cabello',
			'Guama',
			'Ciudad Bolívar',
			'Camaguán',
			'Trujillo',
			'San Juan de Manapiare',
			'Paraíso de Chabasquén',
			'Pampanito',
			'La Vela de Coro',
			'Higuerote',
			'Guayabal',
			'El Dorado',
			'Dabajuro',
			'Caripe',
			'Barquisimeto',
			'San Josecito',
			'Arapuey',
			'Santa Lucía',
			'Santa Cruz',
			//'Santa Ana',
			'Carvajal',
			'San Rafael de Canaguá',
			'San Juan de los Morros',
			'San Carlos',
			'San Antonio',
			'Morón',
			'Canaguá',
			'Guasipati',
			'Guadarrama',
			'El Consejo',
			'Ciudad Ojeda',
			'Tacarigua',
			'Carúpano',
			'Cariaco',
			'La Grita',
			'Capacho Nuevo',
			'Caricuao',
			'Yaracal',
			'Valle de Guanape',
			'San Francisco de Yare',
			'Río Chico',
			'Punta Gorda',
			'Píritu',
			'Pariaguán',
			'Onoto',
			'Montalbán',
			'Masparrito',
			'Maracay',
			'Los Guayos',
			'Catia La Mar',
			'Bruzual',
			'Acarigua',
			'Delicias',
			'La Yuca',
			'Lagunillas',
			'Guacara',
			'Escuque',
			'El Pilar',
			'Lecherías',
			'El Cantón',
			'El Baúl',
			'Ejido',
			'Coro',
			'Capitanejo',
			'Barrancas',
			'Barrancas',
			'Arauquita',
			'Aragua',
			'Santa Rita',
			'Cordero',
			'Palmasola',
			'Macapo',
			'El Cafetal',
		],
	},
	'Viet Nam': {
		country: 'Viet Nam',
		cities: [
			'Trảng Bom',
			'Chư Prông',
			'Hai Riêng',
			'Huỳnh Hữu Nghĩa',
			'Thanh Hóa',
			'Thanh Chương',
			'Sơn Hà',
			'Sa Pa',
			'Mường Chiên',
			'Quỳ Hợp',
			'Đại Đồng',
			'Phong Thổ',
			'Ngô Đồng',
			'Nam Đàn',
			'Mộ Đức',
			'La Gi',
			'Hương Khê',
			'Đồng Xoài',
			'Đắk Song',
			'Thị Trấn Thuận Châu',
			'Bắc Giang',
			'An Châu',
			'Bộc Bố',
			'Hương Sơn',
			'Krông Klang',
			'Đắk Glei',
			'Vĩnh Yên',
			'Krông Năng',
			'Hậu Nghĩa',
			'Thị Trấn Trạm Tấu',
			'Tân Kỳ',
			'Sông Cầu',
			'Quỳnh Côi',
			'Thị Trấn Nguyên Bình',
			'Thạnh Mỹ',
			'Thị Trấn Mường Khương',
			'Hiệp Đức',
			'Giồng Riềng',
			'Châu Đốc',
			'Cần Thơ',
			'Bắc Ninh',
			'Hưng Hóa',
			'Thị Trấn Phước Long',
			'Thị Trấn Giá Rai',
			'Thắng',
			'Vạn Hà',
			'Thiên Tồn',
			'Gôi',
			'Ea Kar',
			'Quận Sáu',
			'Tân Hiệp',
			'Ba Đồn',
			'Phù Cát',
			'Đại Cường',
			'Ô Môn',
			'Nghĩa Lộ',
			'Mỹ Tho',
			'Lạc Dương',
			'Mường Xén',
			'Hoi An',
			'Chợ Mới',
			'Buôn Hồ',
			'Lập Thạch',
			'Thị Trấn Điện Biên',
			'Thị Trấn Mường Tè',
			'Thanh Lưu',
			'Hợp Hòa',
			'Thành Phố Uông Bí',
			'Lộc Thắng',
			'Thuận Nam',
			'Tân Hưng',
			'Sa Rài',
			'U Minh',
			'Phú Quốc',
			'Tĩnh Gia',
			'Tiểu Cần',
			'Than Uyên',
			'Sơn La',
			'Huế',
			'Hóc Môn',
			'Gò Quao',
			'Cát Bà',
			'Cà Mau',
			'Bỉm Sơn',
			'Tiên Lãng',
			'Mê Linh',
			'Yên Mỹ',
			'Hoàn Kiếm',
			'Liên Chiểu',
			'Ngũ Hành Sơn',
			'Khâm Đức',
			'Đắk Hà',
			'Kon Dơng',
			'Long Khánh',
			'Quận Một',
			'Xã Hoài Đức',
			'Yên Vinh',
			'Vụ Bản',
			'Thị Trấn Thất Khê',
			'Thị Trấn Phước Bửu',
			'Giồng Trôm',
			'Cao Thượng',
			'Bạc Liêu',
			'Thị Trấn Tủa Chùa',
			'Xuân Trường',
			'Thị Trấn Tuần Giáo',
			'Thanh Thủy',
			'Lương Bằng',
			'Cầu Diễn',
			'Vĩnh Long',
			'Vĩnh Lộc',
			'Việt Trì',
			'Tuyên Quang',
			'Đông Thành',
			'Quận Bốn',
			'Quận Bình Thạnh',
			'Quỳ Châu',
			'Thị Trấn Quảng Uyên',
			'Mường Nhé',
			'Mỏ Cày',
			'Lai Cách',
			'Hoài Ân',
			'Côn Sơn',
			'Bù Đốp',
			'Châu Thành',
			'Yên Lập',
			'Gia Bình',
			'Neo',
			'Bình Minh',
			'Rừng Thông',
			'Me',
			'Đại Nghĩa',
			'Đinh Văn',
			'Đạ Tẻh',
			'Ngã Bảy',
			'Trần Văn Thời',
			'Quận Năm',
			'Huyện Bảo Lâm',
			'Xã Tân Thanh',
			'Thái Nguyên',
			'Thạch Hà',
			'Đại Tân',
			'Tánh Linh',
			'Thị Trấn Sìn Hồ',
			'Qui Nhon',
			'Quan Hóa',
			'Phủ Lý',
			'Ninh Bình',
			'Thị Trấn Na Sầm',
			'Mỹ Xuyên',
			'Hạ Long',
			'Đình Lập',
			'Đắk Tô',
			'Chơn Thành',
			'Chí Thạnh',
			'Chi Nê',
			'Cẩm Xuyên',
			'Ba Tri',
			'Ấp Tân Ngãi',
			'Thị Trấn Hòa Bình',
			'Bút Sơn',
			'Bo',
			'Vị Thanh',
			'Phú Hòa',
			'Ea Drăng',
			'Mỹ Phước',
			'Ngọc Hiển',
			'Xã Tân Hà',
			'Tây Ninh',
			'Đầm Dơi',
			'Tam Kỳ',
			'Quán Hàu',
			'Quảng Ngãi',
			'Nhơn Trạch',
			'Lào Cai',
			'Hữu Lũng',
			'Hạ Hòa',
			'Đức Phong',
			'Đức Phổ',
			'Dĩ An',
			'Cờ Đỏ',
			'Chũ',
			'Cầu Kè',
			'Cẩm Phả Mines',
			'Cam Lộ',
			'Núi Sập',
			'Thị Trấn Ngan Dừa',
			'Thị Trấn Vị Xuyên',
			'Lương Sơn',
			'Vương',
			'Phú Xuyên',
			'Hưng Nguyên',
			'Thị Trấn Yên Minh',
			'Văn Quan',
			'Vân Canh',
			'Tô Hạp',
			'Phú Túc',
			'Núi Đèo',
			'Mỹ Lộc',
			'Kinh Môn',
			'Bình Long',
			'Hòa Bình',
			'Gia Nghĩa',
			'Đại Lộc',
			'Cổ Lóa',
			'Cái Nước',
			'Bảo Lộc',
			'Bẩn Yên Nhân',
			'Quan Sơn',
			'Mường Khến',
			'Đông Hưng',
			'Khoái Châu',
			'Trôi',
			'Sơn Trà',
			'Vĩnh Thuận',
			'Hòa Bình',
			'Ea Súp',
			'Đồng Nai',
			'Chợ Lầu',
			'Ma Lâm',
			'Quận Chín',
			'Trảng Bàng',
			'Trà Cú',
			'Thanh Sơn',
			'Phú Lộc',
			'Như Quỳnh',
			'Ngọc Lặc',
			'Nghi Xuân',
			'Lắk',
			'Tầm Vu',
			'Yên Phú',
			'Bát Xát',
			'Quảng Hà',
			'Thanh Miện',
			'Gia Lộc',
			'Trần Cao',
			'Lý Sơn',
			'Thị Trấn Vĩnh Tuy',
			'Vinh',
			'Vân Đình',
			'Thị Trấn Trùng Khánh',
			'Tân Thạnh',
			'Huyện Lâm Hà',
			'Thủ Đức',
			'Thới Bình',
			'Thanh Nê',
			'Tam Đảo',
			'Phù Mỹ',
			'Thị Trấn Ngải Giao',
			'Nam Định',
			'Long Phú',
			'Kim Bài',
			'Kiên Lương',
			'Diễn Châu',
			'Điện Bàn',
			'Dầu Tiếng',
			'Ðà Lạt',
			'Con Cuông',
			'Bồng Sơn',
			'Cái Dầu',
			'Sơn Thịnh',
			'Núi Đối',
			'Đu',
			'Chùa Hang',
			'Thường Tín',
			'Đông Anh',
			'Vũ Quang',
			'Sa Thầy',
			'Măng Đen',
			'Kông Chro',
			'Xuân Mai',
			'Cái Đôi Vàm',
			'Vĩnh Thạnh',
			'Ái Tử',
			'Thạnh Phú',
			'Thị Trấn Mèo Vạc',
			'Long Mỹ',
			'Lũng Hồ',
			'Duy Xuyên',
			'Đồng Xuân',
			'Ðông Hà',
			'Diên Khánh',
			'Củng Sơn',
			'Chợ Gạo',
			'Chí Linh',
			'Cái Răng',
			'Bình Sơn',
			'Bắc Sơn',
			'An Lão',
			'Pác Miầu',
			'Thứa',
			'Điện Biên Đông',
			'Tam Đường',
			'Quán Lào',
			'Yên Thịnh',
			'Đống Đa',
			'K Bang',
			'M’Đrăk',
			'Tân Phú',
			'Châu Thành',
			'Quận Mười Một',
			'Quận Đức Thành',
			'Trà Bồng',
			'Phú Lộc',
			'Thái Bình',
			'Tây Hồ',
			'Quảng Yên',
			'Thị Trấn Nước Hai',
			'Nha Trang',
			'Nhà Bè',
			'Kẻ Sặt',
			'Hưng Yên',
			'Hà Giang',
			'Chúc Sơn',
			'Ba Tơ',
			'Vũ Thư',
			'Thanh Hà',
			'Phúc Thọ',
			'Trâu Quỳ',
			'A Yun Pa',
			'Trà Vinh',
			'Trà Ôn',
			'Thốt Nốt',
			'Phú Phong',
			'Phố Châu',
			'Bến Sung',
			'Lấp Vò',
			'Hồ Xá',
			'Đồng Mỏ',
			'Da Nang',
			'Cao Lãnh',
			'Phố Mới',
			'Thị Trấn Tam Sơn',
			'Văn Giang',
			'Sóc Sơn',
			'Thống Nhất',
			'Hoàng Sa',
			'Hoàng Sa',
			'Quang Minh',
			'Vũng Tàu',
			'Tri Tôn',
			'Quảng Phú',
			'Châu Thành',
			'Ngã Sáu',
			'Pleiku',
			'Lộc Ninh',
			'Lao Chải',
			'Thị Trấn Khánh Yên',
			'Thị Trấn Đồng Văn',
			'Ít Ong',
			'Sông Thao',
			'Thị Trấn Tân Yên',
			'Chờ',
			'Thị Trấn Gành Hào',
			'Thị Trấn Xuân Hoà',
			'Cổ Lễ',
			'Vĩnh Châu',
			'Văn Điển',
			'Tuy Phước',
			'Bác Ái',
			'Tân Biên',
			'Tân Hòa',
			'Duyên Hải',
			'Quận Tân Phú',
			'Trà My',
			'Thủ Thừa',
			'Thủ Dầu Một',
			'Sơn Dương',
			'Si Ma Cai',
			'Sa Dec',
			'Nho Quan',
			'Mộc Châu',
			'Haiphong',
			'Hải Lăng',
			'Dien Bien Phu',
			'Diêm Điền',
			'Bắc Yên',
			'Quận Bảy',
			'Lim',
			'Mường Chà',
			'Thị Trấn Việt Lâm',
			'Nông Cống',
			'Hưng Hà',
			'Mường Ảng',
			'Chư Sê',
			'Nàng Mau',
			'Xã Phúc Thọ',
			'Đại Nghĩa',
			'Quy Đạt',
			'Phúc Yên',
			'Liễu Đề',
			'Hương Canh',
			'Hanoi',
			'Đắk Mil',
			'Thị Trấn Đồng Đăng',
			'Hồ',
			'Cô Tô',
			'Ân Thi',
			'Thanh Xuân',
			'Prao',
			'Đăk Đoa',
			'Võ Xu',
			'Ia Kha',
			'Thứ Mười Một',
			'Vĩnh Thạnh',
			'Một Ngàn',
			'Quận Hai',
			'Thuận An',
			'Phan Rang-Tháp Chàm',
			'Minh Long',
			'Kỳ Anh',
			'Hà Đông',
			'Thị Trấn Đông Khê',
			'Chợ Lách',
			'Cầu Giát',
			'Cao Bằng',
			'Cần Giuộc',
			'An Nhơn',
			'Tam Điệp',
			'Thị Trấn Cao Lộc',
			'Quảng Xương',
			'Đà Bắc',
			'Cao Phong',
			'Quốc Oai',
			'Nghèn',
			'Khe Tre',
			'Sịa',
			'Tây Trà',
			'Krông Kmar',
			'Thạnh Mỹ',
			'Tiên Phước',
			'Tam Bình',
			'Phú Thọ',
			'Phụng Hiệp',
			'Đại Quang',
			'Nhà Bàng',
			'Móng Cái',
			'Long Xuyên',
			'Thị Trấn Lai Vung',
			'Đức Thọ',
			'Chợ Chu',
			'Cần Đước',
			'Cái Nhum',
			'Hoàn Lão',
			'Bình Đại',
			'Tân Túc',
			'Bến Cầu',
			'Thị Trấn Vinh Quang',
			'Nam Giang',
			'Cầu Giấy',
			'Yên Châu',
			'Vũng Liêm',
			'Tuy Hòa',
			'Vĩnh Hưng',
			'Châu Thành',
			'thị xã Quảng Trị',
			'Phan Thiết',
			'Năm Căn',
			'Mường Lát',
			'Khe Sanh',
			'Hòn Đất',
			'Bình Thủy',
			'Thị Trấn Bảo Lạc',
			'Lai Châu',
			'Hà Trung',
			'Kỳ Sơn',
			'Vĩnh Trụ',
			'Vĩnh Tường',
			'Liên Quan',
			'Ia Pa',
			'Phước Dân',
			'Thọ Xuân',
			'Tân An',
			'Sơn Tây',
			'Sông Mã',
			'Kim Sơn',
			'Plei Kần',
			'Phú Vang',
			'Phú Khương',
			'Nga Sơn',
			'Hà Tĩnh',
			'Biên Hòa',
			'An Dương',
			'An Biên',
			'A Lưới',
			'Thị Trấn Long Điền',
			'Thị Trấn Thông Nông',
			'Thị Trấn Hùng Quốc',
			'Bình Mỹ',
			'Tân Hiệp',
			'Quế Sơn',
			'Phước An',
			'Phong Điền',
			'Phát Diệm',
			'Mù Cang Chải',
			'Hà Tiên',
			'Gio Linh',
			'Bến Tre',
			'An Hòa',
			'Bích Động',
			'An Phú',
			'Hậu Lộc',
			'Triệu Sơn',
			'Hàng Trạm',
			'Thanh Khê',
			'Cẩm Lệ',
			'Yên Ninh',
			'Yên Bái',
			'Vĩnh Bình',
			'Cam Lâm',
			'Gò Dầu',
			'Quận Ba',
			'Chư Ty',
			'Quận Mười',
			'Quận Đức Thịnh',
			'Quận Lấp Vò',
			'Thới Lai',
			'Ho Chi Minh City',
			'Quán Hành',
			'Ma Đa Gui',
			'Đưc Trọng',
			'Đồng Hới',
			'Cái Tàu Hạ',
			'Buôn Ma Thuột',
			'An Châu',
			'Cầu Gồ',
			'Thị Trấn Việt Quang',
			'Yên Bình',
			'Cổ Phúc',
			'Thị Trấn Phú Mỹ',
			'Hát Lót',
			'Cành Nàng',
			'Đồng Lê',
			'Yên Lạc',
			'Vạn Giã',
			'Khánh Vĩnh',
			'Thạnh Hóa',
			'Cù Lao Dung',
			'Thị Trấn Tân Thành',
			'Kim Tân',
			'Thị Trấn Tà Lùng',
			'Phù Ninh',
			'Ninh Giang',
			'Hà Lam',
			'Thị Trấn Đất Đỏ',
			'Chợ Mới',
			'Cam Ranh',
			'Cẩm Phả',
			'Bình Gia',
			'Tân Trụ',
			'Tân Việt',
			'Bằng Lũng',
			'Thị Trấn Yên Thế',
			'Thị Trấn Na Hang',
			'Yên Định',
			'Quận Phú Nhuận',
			'Tân Châu',
			'Phong Điền',
			'Ninh Hòa',
			'Nghĩa Hành',
			'Vân Tùng',
			'Long Thành',
			'Lộc Bình',
			'Liên Hương',
			'Hương Trà',
			'Hòa Mạc',
			'Anh Son',
			'Cần Giờ',
			'Cẩm Thủy',
			'Bắc Kạn',
			'Uyen Hung',
			'Phong Châu',
			'Cung Kiệm',
			'Đồi Ngô',
			'Phú Mỹ',
			'Yên Cát',
			'An Lão',
			'Hai BàTrưng',
			'Gia Ray',
			'Vĩnh An',
			'Xã Tân Văn',
			'Tràm Chim',
			'Thường Xuân',
			'Thanh Bình',
			'Tây Đằng',
			'Sơn Tịnh',
			'Rạch Giá',
			'Phủ Thông',
			'Kiến Giang',
			'Lái Thiêu',
			'Hải Dương',
			'Củ Chi',
			'Thị Trấn Phố Ràng',
			'Thanh Ba',
			'Lâm',
			'Tứ Kỳ',
			'Phú Thái',
			'Núi Thành',
			'Yên Thành',
			'Ea T’ling',
			'Tân Sơn',
			'Phú Quý',
			'Tân Phú',
			'Dương Minh Châu',
			"Xã Đạ K'Nàng",
			'Sóc Trăng',
			'Phù Yên',
			'Thị Trấn Phố Lu',
			'Tiền Hải',
			'Mường Lay',
			'Lạng Sơn',
			'Kon Tum',
			'Kế Sách',
			'Hòa Vang',
			'Thị Trấn Thanh Nhật',
			'Gò Công',
			'Bắc Hà',
			'Quế',
			'Thành Phố Bà Rịa',
			'Phùng',
			'Phú Ninh',
			'Đắk Rve',
			'Yến Lạc',
			'Tư Nghĩa',
			'Đắk Mâm',
			'Khánh Hải',
			'Tân Châu',
			'Hòa Thành',
			'Long Hồ',
			'Dương Đông',
			'Mỹ Thọ',
			'Minh Lương',
			'Lang Chánh',
			'Đoan Hùng',
			'Di Linh',
			'Buôn Trấp',
			'Thị Trấn Vĩnh Lộc',
			'Trới',
			'Nam Sách',
			'Lâm Thao',
		],
	},
	'Virgin Islands (US)': { country: 'Virgin Islands (US)', cities: ['Charlotte Amalie', 'Christiansted', 'Cruz Bay', 'Saint Croix'] },
	'Wallis and Futuna Islands': { country: 'Wallis and Futuna Islands', cities: ['Leava', 'Alo', 'Mata-Utu'] },
	'West Bank and Gaza Strip': {
		country: 'West Bank and Gaza Strip',
		cities: [
			'Zaytā Jammā‘īn',
			'Rantīs',
			'Marāḩ Rabbāḩ',
			'Jaba‘',
			'An Nazlah ash Sharqīyah',
			'Al Midyah',
			'Jinşāfūţ',
			'Wādī Fūkīn',
			'Taffūḩ',
			'Silwād',
			'Sabasţīyah',
			'Naḩḩālīn',
			'Al Majd',
			'Kafr ‘Abbūsh',
			'Bayt Fajjār',
			'Bayt Duqqū',
			'Ūşarīn',
			'Al Qubaybah',
			'Al Bīrah',
			'‘Ibwayn',
			'East Jerusalem',
			'Al Buq‘ah',
			'Rābūd',
			'Otniel',
			'Şānūr',
			'Ma‘ale Efrayim',
			'Bayt Ḩasan',
			'Jifnā',
			'Bīr Nabālā',
			'Bayt ‘Anān',
			'Barţa‘ah ash Sharqīyah',
			'Khallat ad Dār',
			'Tayāsīr',
			'Sīlat al Ḩārithīyah',
			'Sal‘it',
			'Bayt Ta‘mar',
			'Al Judayyidah',
			'Bayt Liqyā',
			'Az Zāwiyah',
			'Az Zabābidah',
			'Al ‘Aţţārah',
			'Sa‘īr',
			'Sannīryā',
			'Qīrah',
			'Ni‘līn',
			'Kafr Qūd',
			'Jūrat ash Sham‘ah',
			'Ḩūsān',
			'Efrata',
			'Dayr Sharaf',
			'Bet Arye',
			'Bayt Fūrīk',
			'Al ‘Araqa',
			'Umm an Naşr',
			'Al ‘Ubaydīyah',
			'Khallat Ḩamāmah',
			'Sīrīs',
			'Şarrah',
			'Rābā',
			'Qarāwat Banī Zayd',
			'Qalandiyā',
			'Faqqū‘ah',
			'Dayr as Sūdān',
			'Bayt Dajan',
			'Arţās',
			'Al Mazra‘ah ash Sharqīyah',
			'Khallat Şāliḩ',
			'Ūdalah',
			'Tarqūmyā',
			'Ţallūzah',
			'Qarāwat Banī Ḩasan',
			'Ma‘ale Mikhmas',
			'Kafr ad Dīk',
			'Dūrā',
			'Banī Na‘īm',
			'‘Aţārah',
			'‘Aşīrah al Qiblīyah',
			'Bruchin',
			'‘Abasān al Kabīrah',
			'Shuyūkh al ‘Arrūb',
			'Kafr Qaddūm',
			'Ḩablah',
			'Dayr Dibwān',
			'Baytūnyā',
			'Bayt Imrīn',
			'‘Azmūţ',
			'Ar Rām wa Ḑāḩiyat al Barīd',
			'Gaza',
			'Rummanah',
			'Qaffīn',
			'Masḩah',
			'Kafr Jammāl',
			'Ḩāris',
			'Bayt Iksā',
			'‘Anīn',
			'An Naşr',
			'al-Kum',
			'Al Karmil',
			'‘Ayn al Bayḑā',
			'Khuzā‘ah',
			'Şaydā',
			'Ramallah',
			'Al Burj',
			'Jūrīsh',
			'Būrīn',
			'‘Anātā',
			'Az Zaytūnīyah',
			'‘Ajjūl',
			'Abū Qashsh',
			'Old City',
			'Zubūbah',
			'Talfīt',
			'Surif City',
			'Salfīt',
			'Qibyah',
			'Qaryūt',
			'Mislīyah',
			'Maythalūn',
			'Kefar ‘Eẕyon',
			'Kafr Rā‘ī',
			'Kafr ‘Ayn',
			'Birqīn',
			'Budrus',
			'Biddū',
			'Bayt Sāḩūr',
			'Bal‘ā',
			'‘Aţārūt',
			'‘Arrānah',
			'Hebron',
			'Givat Zeev',
			'Itamar',
			'Shūkat aş Şūfī',
			'Zawātā',
			'Alei Zahav',
			'Ţūlkarm',
			'Sinjil',
			'Majdal Banī Fāḑil',
			'Khirbat Abū Falāḩ',
			'Kefar Adummim',
			'Kafr Thulth',
			'Jalqamūs',
			'Dayr al Ḩaţab',
			'Burqah',
			'Bidyā',
			'Bayt Līd',
			'Bayt Ībā',
			'Bayt Ūlā',
			'‘Attīl',
			'‘Almon',
			'Al Lubban al Gharbī',
			'Juḩr ad Dīk',
			'Jabālyā',
			'Za‘tarah',
			'Qalqīlyah',
			'Jannātah',
			'An Nuway‘imah',
			'Al Judayrah',
			'Al Fandaqūmīyah',
			'‘Abasān al Jadīdah',
			'Al Muşaddar',
			'Az Za‘ayyim',
			'Yāsūf',
			'Qedumim',
			'Qabāţīyah',
			'Pesagot',
			'Ofra',
			'Dayr al ‘Asal al Fawqā',
			'Kharbathā Banī Ḩārith',
			'Khallat al Mayyah',
			'Kafr al Labad',
			'Ḩuwwārah',
			'Ḩizmā',
			'H̱innanit',
			'Dūrā al Qar‘',
			'Dayr Abū Ḑa‘īf',
			'Bayt Kāḩil',
			'Banī Zayd',
			'Al Bādhān',
			'Alon shvut',
			'Surdā',
			'Pedu’el',
			'Nablus',
			'Qalqas',
			'Far‘ūn',
			'Dayr Ballūţ',
			'Brūqīn',
			'Bayt Ūmmar',
			'‘Ayn ‘Arīk',
			'‘Aynabūs',
			'Ash Shuhadā’',
			'Jericho',
			'Al Jīb',
			'Al Hāshimīyah',
			'Ad Dawḩah',
			'Bayt ‘Īnūn',
			'Suseya',
			'Sīlat az̧ Z̧ahr',
			'Rāmīn',
			'Quşrah',
			'Qabalān',
			'Mirkah',
			'Ar Ramāḑīn',
			'Dayr Qiddīs',
			'Bazzāryah',
			'Bayt ‘Ūr at Taḩtā',
			'Baytīn',
			'Ya‘bad',
			'Qūşīn',
			'Nūbā',
			'Kifil Ḩāris',
			'Karmah',
			'Kūbar',
			'Kafr Qallīl',
			'Ḩalḩūl',
			'Faḩmah',
			'Dayr al Ghuşūn',
			'Bayt Jālā',
			'Karney Shomron',
			'Dayr Sāmit',
			'Az Zubaydāt',
			'Zaytā',
			'Yaţţā',
			'‘Ūrīf',
			'Ţammūn',
			'Qedar',
			'Bardalah',
			'‘Azzūn',
			'‘Anzah',
			'An Nāqūrah',
			'‘Ābūd',
			'Dayr al Balaḩ',
			'Kufayrit',
			'Imrīsh',
			'Ash Shaykh Sa‘d',
			'Jalbūn',
			'Ḩajjah',
			'Dayr Abū Mash‘al',
			'Bayt Wazan',
			'Battir',
			'Bāqat al Ḩaţab',
			'Az̧ Z̧āhirīyah',
			'Ar Rīḩīyah',
			'‘Anabtā',
			'Al Mughayyir',
			'Al Jāniyah',
			'Khallat an Nu‘mān',
			'Yiẕhar',
			'Qaţanah',
			'Neve Daniel',
			'Nazlat ‘Īsá',
			'Mādamā',
			'Ma‘ale Adummim',
			'Al Lubban ash Sharqīyah',
			'Kokhav Ya‘aqov',
			'Kafr Şūr',
			'Kafr Mālik',
			'Elqana',
			'Bīr Zayt',
			'‘Aqrabā',
			'Al Khaḑir',
			'Al Faşāyil',
			'Al Qarārah',
			'‘Arab ar Rashāydah',
			'Bayt Ḩānūn',
			'Yaqqir',
			'Şarţah',
			'Mikhmās',
			'Banī Zayd ash Shārqīyah',
			'Aţ Ţīrah',
			'Alfe Menashe',
			'Al ‘Awjā',
			"Ganei Modi'in",
			'Yāşīd',
			'Kafr Dān',
			'Janīn',
			'Immātīn',
			'Balāţah',
			'Ash Shuyūkh',
			'Az Zuwāydah',
			'Wādī as Salqā',
			'Azun Atme',
			'Khursā',
			'Oranit',
			'Geva Binyamin',
			'Şaffā',
			'Rāfāt',
			'Mevo H̱oron',
			'Kharbathā al Mişbāḩ',
			'Jammā‘īn',
			'‘Immanu‘el',
			'Dayr Jarīr',
			'Battīr',
			'Bāqah ash Sharqīyah',
			'Aţ Ţaybah',
			'An Nabī Ilyās',
			'Al Ḩīlah',
			'Aş Şūrrah',
			'Bīr al Bāshā',
			'Al Jiftlik',
			'Khān Yūnis',
			'Yatmā',
			'An Naşşārīyah',
			'Jīt',
			'Jayyūs',
			'‘Ez Efrayim',
			'Dayr Istiyā',
			'Burqah',
			'Bethlehem',
			'Al Mughayyir',
			'Al ‘Ayzarīyah',
			'Abū Dīs',
			'Rūjayb',
			"Bat A'in",
			'Bayt ‘Awwā',
			'Karme Ẕur',
			'Kafr Zībād',
			'‘Illār',
			'Iktābah',
			'Idhnā',
			'Dūmā',
			'Dhannābah',
			'Dayr Ibzī‘',
			'Bayt Sūrīk',
			'As Samū‘',
			'Al Yāmūn',
			'‘Ajjah',
			'Beit El',
			'Bayt Lāhyā',
			'Al Fukhkhārī',
			'Sālim',
			'Qarne Shomeron',
			'Mardā',
			'Khārās',
			'Har Gillo',
			'Baytā al Fawqā',
			'‘Awartā',
			'Aţ Ţaybah',
			'Bayt ‘Amrah',
			'An Nuşayrāt',
			'Al Burayj',
			'Turmus‘ayyā',
			'Shūfah',
			'Rammūn',
			'Rāfāt',
			'Al Jalamah',
			'Jaba‘',
			'Al Ittiḩād',
			'‘Aşīrah ash Shamālīyah',
			'Bayt Maqdum',
			'Rafaḩ',
			'Banī Suhaylā',
			'Ţūbās',
			'Till',
			'Shuqbā',
			'Rās Karkar',
			'H̱allamish',
			'Farkhah',
			'Bil‘īn',
			'Bayt Sīrā',
			'Bayt Qād',
			'Barqan',
			'‘Ayn Yabrūd',
			'As Sāwiyah',
			'‘Arrābah',
			'Al Mughrāqah',
			'Wādī Raḩḩāl',
		],
	},
	'Western Sahara': { country: 'Western Sahara', cities: ['Boujdour', 'Laayoune', 'Laayoune Plage', 'Dakhla'] },
	Yemen: {
		country: 'Yemen',
		cities: [
			'Majzar',
			'Aţ Ţawīlah',
			'Wa‘lān',
			'Ḩays',
			'Hadibu',
			'As Sūq al Jadīd',
			'Ash Shaţţ',
			'Al Jabīn',
			'Banī al ‘Awwām',
			'Al ‘Aqabah',
			'Ḩadādah',
			'Rumāh',
			'Saḩḩab',
			'Aḑ Ḑil‘',
			'Şuwayr',
			'Taiz',
			'Minwakh',
			'Mayfa‘ah',
			'Khawrah',
			'At Tuḩaytā’',
			'Ataq',
			'Al Maḩābishah',
			'Aş Şarārah',
			'Mabyan',
			'Al Ḩayfah',
			'Sāqayn',
			'Sūq Sibāḩ',
			'Al Aqţa‘',
			'Aş Şalw',
			'Judaydah',
			'Zabīd',
			'Saḩar',
			'Ḩammām ‘Alī',
			'Az Zāhir',
			'Qaryat al Qābil',
			'Aḑ Ḑāli‘',
			'Ar Rujum',
			'Maqbanah',
			'Aslam',
			'Al Madakisha',
			'Al Ḩabīlayn',
			'Bayt al Faqīh',
			'Bayḩān',
			'Ash Shaykh ‘Uthmān',
			'Al Munīrah',
			'Mukalla',
			'Al Mighlāf',
			'Ḩurayḑah',
			'Al ‘Āqir',
			'Ad Durayhimī',
			'Ad Dimnah',
			'Al Ḩashwah',
			'Al Ḩamdī',
			'Şanā’',
			'Mīdī',
			'Al Malāḩīţ',
			'Dhī as Sufāl',
			'Bayt ‘Adhāqah',
			'Al ‘Awābil',
			'Markaz al Marīr',
			'Al Ḩarf',
			'Al Ḩadīyah',
			'Bayt Marrān',
			'Ar Riḑā’ī',
			'Al Miftāḩ',
			'Al Qurayshīyah',
			'Al Qabyal',
			'Ḩabīl al Jabr',
			'Aş Şawma‘ah',
			'Al Wuday‘',
			'Al Jum‘ah',
			'An Nāşirah',
			'Suḩayl Shibām',
			'Bi’r Ḩāt',
			'Yufrus',
			'Qa‘ţabah',
			'Banī Ḩujjāj',
			'As Sawādīyah',
			'Ash Shāhil',
			'Ḩişn Banī Sa‘d',
			'Banī an Nahārī',
			'Village of ALAMRAH',
			'CPF',
			'Al Mirwāḩ',
			'Lawdar',
			'Dhī Nā‘im',
			'Dhaybīn',
			'Marbāţ',
			'Ad Dīs ash Sharqīyah',
			'As Sukhnah',
			'Al Khirāb',
			'Al Jamīmah',
			'Ḩazm al ‘Udayn',
			'Al Shirūj',
			'Al Aḩad',
			'Nişāb',
			'Māwiyah',
			'Al Khawkhah',
			'Aḑ Ḑaḩī',
			'Al Nashmah',
			'Maswarah',
			'Ḩawrah',
			'Al Khāniq',
			'Ar Ramādī',
			'Washḩah',
			'Shibām',
			"Sa'dah",
			'Ḩajjah',
			'Ghayl Bā Wazīr',
			'Asdās',
			'Al Madu',
			'Kusmah',
			'Ḩammām Damt',
			'Ḩaydān',
			'Al ‘Ulayb',
			'Jayshān',
			'Raşad',
			'Zarājah',
			'Dhubāb',
			'Az Zuhrah',
			'Ar Rawḑah',
			'Al Khamīs',
			'Sūq al Khamīs',
			'Khayrān',
			'Az Zāhir',
			'Al Musaymīr',
			'Al Jawl',
			'Kilmia',
			'Khawr Maksar',
			'Jawf al Maqbābah',
			'Ḩayfān',
			'Burūm',
			'Al Ḩajab',
			'Al Manşūrah',
			'Al Jurbah',
			'Al Bāţinah',
			'Aden',
			'Manākhah',
			'Al Ma‘allā’',
			'Kitāf',
			'Ar Rawḑah',
			'Al Makhādir',
			'Al Ghayl',
			'Ash Shawātī',
			'At Taḩāluf',
			'Sirār',
			'Majz',
			'Al-Medy Village, قرية المدي',
			'Najd al Jamā‘ī',
			'Z̧almah al ‘Ulyā',
			'Sūq ar Rubū‘',
			'Qalansīyah',
			'Jiḩānah',
			'Madīnat Lab‘ūs',
			'Ḩabbān',
			'Banī Bakr',
			'An Nādirah',
			'Al Jarrāḩī',
			'Rajūzah',
			'Al Ḩumaydāt',
			'Kushar',
			'Al ‘Amūd',
			'Al Ḩuşūn',
			'Al Ghaylah',
			'Al Masālīyah',
			'An Naz̧īr',
			'Ash Sharyah',
			'Riḩāb',
			'Ja‘ār',
			'Ḩaraḑ',
			'Al Bilād',
			'Ash Shaghādirah',
			'‘Amrān',
			'Al Manşūrīyah',
			'Al Burayqah',
			'Al ‘Urrah',
			'Al Madān',
			'Al Maşlūb',
			'Al Ḩarjah',
			'Ḩabīl ar Raydah',
			'Aţ Ţalḩ',
			'Bidbadah',
			'Abs',
			'Shiḩan as Suflá',
			'Mukayrās',
			'Mawza‘',
			'Mafḩaq',
			'Ḩabābah',
			'Dhamār',
			'As Sayyānī',
			'Kirsh',
			'Al Ghayz̧ah',
			'Al Bayda',
			'Jadder Cemetery',
			'Thamūd',
			'Jiblah',
			'Al ‘Abr',
			'Ḑawrān ad Daydah',
			'Aş Şa‘īd',
			'Ad Dann',
			'Ar Raḑmah',
			'Al Hijrah',
			'Ar Rawnah',
			'‘Usaylān',
			'‘Ubāl',
			'Şīf',
			'Sanaa',
			'Ma‘bar',
			'Kuḩlān ‘Affār',
			'Al Milāḩ',
			'Al Maḩjal',
			'Al ‘Ashshah',
			'Ahl Mohammed bin Ahmed',
			'Daḩasuways',
			'Waḑarah',
			'Shahārat al Ghīs',
			'Sayyān',
			'Raydah',
			'As Salāsil',
			'Al Matūn',
			'Zakhim',
			'Ţawr al Bāḩah',
			'Raḩabah',
			'AL-khashā upper',
			'Yarīm',
			'Radā‘',
			'Qishn',
			'Ḩaşwayn',
			'As Sūdah',
			'As Saddah',
			'‘Amd',
			'Al ‘Udayn',
			'Al Misrākh',
			'Markaz Bilād aţ Ţa‘ām',
			'Ash Shiḩr',
			'Aş Şalīf',
			'Al Ḩusayn',
			'Qaryat ad Da‘īs',
			'Jawl al Majma‘',
			'Dhī Jalāl',
			'Khamir',
			'Ibb',
			'Matnah',
			'Sūq Şirwāḩ',
			'Mustabā’',
			'Markaz Mudhaykirah',
			'Milāḩ',
			'Ku‘aydinah',
			'Ḩūth',
			'Ḩarīb',
			'Dār an Nāşir',
			'Al Qaflah',
			'Al Maḩfid',
			'Al Ḩudaydah',
			'Ḩajar al Mashā’ikh',
			'Maţarah',
			'Ash Shuqayrah',
			'Sayḩūt',
			'Juban',
			'Al Qanāwiş',
			'Al Qaţan',
			'Aḩwar',
			'Az̧ Z̧alī‘ah',
			'Al Kharāb',
			'An Najd',
			'Sāh',
			'Al Māfūd',
			'Bājil',
			'Az Zaydīyah',
			'Al Luḩayyah',
			'Al Maghrabah',
			'Ḩabūr',
			'Bāqim as Sūq',
			'Sūq Shamar',
			'Sharas',
			'Ar Raydah',
			'Mūdīyah',
			'At Turbah',
			'Al Qurḩ',
			'Ḑubāh',
			'Ḑawrān',
			'As Suwaydā',
			'Al ‘Inān',
			"Ma'rib",
			'Ḩawf',
			'At Tawāhī',
			'Aş Şafaqayn',
			'Al Ḩazm',
			'Al Mayfa’ah',
			'Madghil',
			'Al ‘Ayn',
			'Matwaḩ',
			'Zinjibār',
			'Tarīm',
			'Ruḑūm',
			'Al Madīd',
			'Laḩij',
			'Crater',
			'Āl Ma‘ūdah',
			'Al Marāwi‘ah',
			'Al Maḩwīt',
			'Riqāb',
			'Ar Rawḑ',
			'Ar Rubū‘',
			'Al Mashāf',
			'Al Ḩazm',
			'Khimār',
		],
	},
	Zambia: {
		country: 'Zambia',
		cities: [
			'Mpulungu',
			'Chambishi',
			'Kawambwa',
			'Mufumbwe',
			'Mumbwa',
			'Livingstone',
			'Kalulushi',
			'Chama',
			'Lusaka',
			'Lukulu',
			'Chadiza',
			'Senanga',
			'Kaoma',
			'Mongu',
			'Kaputa',
			'Mansa',
			'Luwingu',
			'Mungwi',
			'Kitwe',
			'Kapiri Mposhi',
			'Mporokoso',
			'Ndola',
			'Lundazi',
			'Kasama',
			'Samfya',
			'Mpika',
			'Luanshya',
			'Chongwe',
			'Chililabombwe',
			'Mpongwe',
			'Kabwe',
			'Chipata',
			'Isoka',
			'Chibombo',
			'Kabompo',
			'Sinazongwe',
			'Kalabo',
			'Nchelenge',
			'Nakambala',
			'Sesheke',
			'Limulunga',
			'Kansanshi',
			'Mbala',
			'Petauke',
			'Mufulira',
			'Maamba',
			'Serenje',
			'Mwense',
			'Zambezi',
			'Mkushi',
			'Kalengwa',
			'Kafue',
			'Gwembe',
			'Mwinilunga',
			'Monze',
			'Luangwa',
			'Siavonga',
			'Chinsali',
			'Chingola',
			'Nakonde',
			'Solwezi',
			'Kataba',
			'Kasempa',
			'Choma',
			'Nyimba',
			'Namwala',
			'Mazabuka',
		],
	},
	Zimbabwe: {
		country: 'Zimbabwe',
		cities: [
			'Plumtree',
			'Mount Darwin',
			'Kariba',
			'Dete',
			'Odzi',
			'Gwanda',
			'Chipinge',
			'Harare',
			'Inyati',
			'Binga',
			'Nyanga',
			'Mvuma',
			'Filabusi',
			'Redcliff',
			'Norton',
			'Mutare',
			'Gokwe',
			'Esigodini',
			'Lalapanzi',
			'Beatrice',
			'Shamva',
			'Chinhoyi',
			'Chimanimani',
			'Beitbridge',
			'Insiza',
			'Chitungwiza',
			'Masvingo',
			'Hwange',
			'Victoria Falls',
			'Shurugwi',
			'Ruwa',
			'Lupane',
			'Kwekwe',
			'Concession',
			'Chirundu',
			'Penhalonga',
			'Nyazura',
			'Karoi',
			// 'Glendale',
			'Banket',
			'Bindura',
			'Chakari',
			'Bulawayo',
			'Raffingora',
			'Gweru',
			'Chiredzi',
			'Kadoma',
			'Zvishavane',
			'Epworth',
			'Rusape',
			'Murehwa',
			'Dorowa Mining Lease',
			'Chegutu',
			'Centenary',
			'Shangani',
			'Mvurwi',
			'Mashava',
			'Marondera',
			'Headlands',
			'Chivhu',
			'Macheke',
			'Kamativi Mine',
			'Mazowe',
			'Mutoko',
			'Mhangura',
		],
	},
}

export default CountryEnum
