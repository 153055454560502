import React, { useState } from 'react';
import BlogPictures from './blogPictures'
import { notification } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import './blog.modules.css';

const PostForm = ({ onSubmit }) => {
    const [title, setTitle] = useState('');
    const [background, setBackground] = useState('');
    const [bgPara, setBgPara] = useState('');
    const [solution, setSolution] = useState('');
    const [solPara1, setSolPara1] = useState('');
    const [solPara2, setSolPara2] = useState('');
    const [conclusion, setConclusion] = useState('');
    const [conclusionPara, setConclusionPara] = useState('');
    const [author, setAuthor] = useState('');
    const [publishDate, setPublishDate] = useState('');
    const [mainImage, setMainImage] = useState([]);
    const navigator = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Construct the final object including the photos URLs
            const finalValues = {
                title: title,
                background: background,
                bgPara: bgPara,
                solution: solution,
                solPara1: solPara1,
                solPara2: solPara2,
                conclusion: conclusion,
                conclusionPara: conclusionPara,
                author: author,
                publishDate: publishDate,
                pictures: mainImage.map((photo) => photo.url)
            };
    
            // Send a POST request with the finalValues object
            const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}posts/add`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify(finalValues),
            });
    
            console.log({ response });
            if (response.status === 201 || response.status === 200) {
                // Clear the form fields after successful submission
                setTitle('');
                setAuthor('');
                setBackground('');
                setBgPara('');
                setSolution('');
                setSolPara1('');
                setSolPara2('');
                setConclusion('');
                setConclusionPara('');
                setAuthor('');
                setPublishDate('');
                setMainImage([]);
                notification['success']({
                    message: 'Blog added successfully',
                    duration: 5,
                    onClick: () => {
                        notification.close()
                    },
                })
                navigator('/home/about')
            } else {
                console.log(response)
                notification['error']({
                    message: 'Blog addition failed',
                    duration: 5,
                    onClick: () => {
                        notification.close()
                    },
                })
            }
        } catch (err) {
            console.error(err);
        }
    };
    

    return (
        <form onSubmit={(e) => handleSubmit(e)} className='paddingLeftForForm'>
            <br></br><br></br><br></br>

            <textarea className='formFieldsWidthHeight' placeholder="Title" value={title} onChange={e => setTitle(e.target.value)} required></textarea>
            <br></br><br></br><br></br>
            <textarea className='formFieldsWidthHeight' placeholder="Project Background" value={background} onChange={e => setBackground(e.target.value)} required></textarea>
            <br></br><br></br><br></br>
            <textarea className='formFieldsWidthHeight' placeholder="Project Background Paragraph" value={bgPara} onChange={e => setBgPara(e.target.value)} required></textarea>
            <br></br><br></br><br></br>
            <textarea className='formFieldsWidthHeight' placeholder="Solution" value={solution} onChange={e => setSolution(e.target.value)} required></textarea>
            <br></br><br></br><br></br>
            <textarea className='formFieldsWidthHeight' placeholder="Solution Paragraph 1" value={solPara1} onChange={e => setSolPara1(e.target.value)} required></textarea>
            <br></br><br></br><br></br>
            <textarea className='formFieldsWidthHeight' placeholder="Solution Paragraph 2" value={solPara2} onChange={e => setSolPara2(e.target.value)} required></textarea>
            <br></br><br></br><br></br>
            <textarea className='formFieldsWidthHeight' placeholder="Conclusion" value={conclusion} onChange={e => setConclusion(e.target.value)} required></textarea>
            <br></br><br></br><br></br>
            <textarea className='formFieldsWidthHeight' placeholder="Conclusion Paragraph" value={conclusionPara} onChange={e => setConclusionPara(e.target.value)} required></textarea>
            <br></br><br></br>



            <input type="text" placeholder="Author" value={author} onChange={e => setAuthor(e.target.value)} required />
            <br></br><br></br>
            <input type="text" placeholder="Date" value={publishDate} onChange={e => setPublishDate(e.target.value)} required />
            <br></br><br></br>
            <BlogPictures  mainImage={mainImage} setMainImage={setMainImage} />
            {/* <button type="submit">Submit</button> */}
            <Button className='btn-primary !h-[50px] w-[200px]' type="submit" onClick={handleSubmit}>Submit</Button>
        </form>
    );
};

export default PostForm;
