import HeroBackground1 from '../../assets/drive-assets/pedro-lastra-Nyvq2juw4_o-unsplash.jpg'
import HeroBackground2 from '../../assets/drive-assets/andrea-cau-nV7GJmSq3zc-unsplash.jpg'
import HeroBackground3 from '../../assets/drive-assets/henning-witzel-ukvgqriuOgo-unsplash.jpg'
import HeroBackground4 from '../../assets/drive-assets/ralph-ravi-kayden-2d4lAQAlbDA-unsplash.jpg'

import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { PrimaryHeader, Form } from '@components'
import { Button, Carousel } from 'antd'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { endpoints } from '../../helpers/enums'
import { citiesAtom, countriesAtom, dbCities, dbCountries, listingsAtom } from '@atoms'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import CountryEnum from '../../helpers/countries'
import { mdiChevronDoubleDown } from '@mdi/js'
import Icon from '@mdi/react'
import MobileRangePicker from './components/mobileRangePicker'
import statesToCities from '../../helpers/statesCities'
import countryStates from '../../helpers/countiesStates'
import { findCities } from '../../helpers/utilFunctions'
import useFindLocations, { findLocations } from '../../hooks/useLocations'
import { locationSelector } from '@selectors'
import { Modal } from 'antd';
import Clock from './images/clock.svg'
import Year from './images/year.png'

const Home = (props) => {
	const homeRef = useRef(null)
	const navigator = useNavigate()
	const { pathname } = useLocation()
	const [form] = Form.useForm()
	const setData = useSetRecoilState(listingsAtom)
	const [loading, setLoading] = useState(false)
	const [searchHeaderVisible, setSearchHeaderVisible] = useState(true)
	const loggedInUser = JSON.parse(localStorage.getItem('user'))
	const formValues = Form.useWatch(undefined, form)
	const [countries, setCountries] = useRecoilState(countriesAtom)
	const [cities, setCities] = useRecoilState(citiesAtom)
	const [days, setDays] = useState(0)
	const [checked, setChecked] = useState(false)
	const handleChange = () => {
		setChecked(!checked);

		if(!checked){
			document.getElementById('checkMobile').style.color = "gray";
			document.getElementById('checkWeb').style.color = "gray";
			localStorage.setItem('flexibleDatesChecked', JSON.stringify('true'))
		} else {
			document.getElementById('checkMobile').style.color = "lightgray";
			document.getElementById('checkWeb').style.color = "lightgray";
			localStorage.setItem('flexibleDatesChecked', JSON.stringify('false'))
		}
	  };

	useEffect(() => {
		findLocations(setCountries, setCities)
	}, [])

    const [modalVisible, setModalVisible] = useState(false)
	// Show the Upgrade Now to only premium selected users from new pricing page just after sign-up  
	const premium = JSON.parse(localStorage.getItem('premium'))
	const [modalUpgradeVisible, setModalUpgradeVisible] = useState(false)

    useEffect(() => {
		localStorage.setItem('flexibleDatesChecked', JSON.stringify('false'))
        // Show pop-up notification on component mount
		const userDataNew = JSON.parse(localStorage.getItem('user'))
		if (userDataNew && !userDataNew?.paymentMethodId){ // if (userDataNew && !userDataNew?.paymentMethodId){
			const userCreatedAt = new Date(userDataNew?.createdAt);
			// Create a new date that is one month later than userDataCreatedAt
			const oneMonthLaterDate = new Date(userCreatedAt);
			oneMonthLaterDate.setMonth(oneMonthLaterDate.getMonth() + 1);
			// Compare the new date with the current date
			const currentDate = new Date();
			if (oneMonthLaterDate > currentDate){
				// Calculate remaining days
				const differenceInTime = oneMonthLaterDate.getTime() - currentDate.getTime();
				setDays(Math.ceil(differenceInTime / (1000 * 3600 * 24)))
				// Display the notification pop-up if 14 or less days left in 1 month free trial.
				if (Math.ceil(differenceInTime / (1000 * 3600 * 24)) < 15){// 15 original
					setModalVisible(true)
				}	
			}
			// Show the Upgrade Now to only premium selected users from new pricing page just after sign-up
			const difference = currentDate.getTime() - userCreatedAt.getTime();
			const signupDays = (difference/(1000 * 3600 * 24))
			console.log("SignUp Days" , signupDays)
			if (signupDays < 2){ // <2
				setModalUpgradeVisible(premium == 'premium' ? true : false)
				// setModalUpgradeVisible(true)
			}
		}

		
    }, []);

    const handleCloseModal = () => {
        setModalVisible(false);
    };
	const handleCloseModalUpgrade = () => {
        setModalUpgradeVisible(false)
		localStorage.setItem('premium', JSON.stringify(null))
    };


	console.log({ countries, cities })

	const countryOptions = Object.keys(CountryEnum)
		.map((country) => country)
		.map((country) => ({ label: country, value: country, disabled: !countries.includes(country) }))
		.sort((a, b) => a.disabled - b.disabled)

	const citiesArray = [
		...new Set(
			(CountryEnum[formValues?.country]?.cities && [...CountryEnum[formValues?.country]?.cities, ...countryStates[formValues?.country]]) ||
				Object.values(CountryEnum)
					.map((country) => country.cities)
					.flat()
		),
	]
	const citiesOptions = citiesArray
		.map((city) => ({
			label: city,
			value: city,
			disabled: !cities.includes(city),
		}))
		.sort((a, b) => a.disabled - b.disabled)

	const fetchData = useCallback(async () => {
		setLoading(true)
		const values = form.getFieldsValue()
		const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}${endpoints.find}`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json;charset=utf-8' },
			body: JSON.stringify({
				country: values?.country,
				city: statesToCities[values?.city] || findCities(values?.city) || values?.city,
				// startDate: values?.dateRange?.[0]?.format('YYYY-MM-DD'),
				// endDate: values?.dateRange?.[1]?.format('YYYY-MM-DD'),
				startDate: null,
				endDate: null,
				type: values?.type,
				// entirePlace: true,
				// user: true,
				// location: true,
				// list: true,
			}),
		})

		if (response.status === 200) {
			const data = await response.json()
			// console.log({ data })
			setData(data)
			setLoading(false)
		} else {
			console.log(response)
			setLoading(false)
		}
	}, [])
	useEffect(() => {
		if (!formValues?.country) {
			navigator('/home/about')
		}
	}, [])
	return (
		<div className='' ref={homeRef}>
			<PrimaryHeader />
			<Modal
                // title="Welcome"
                visible={modalVisible}
                onCancel={handleCloseModal} // No action when clicking cancel
                footer={[]}
            >
				<div className='flex flex-col justify-center items-center  mt-4'>
				<img className='w-[22%] object-cover' src={Clock} alt='' />
                <p className={`w-[80%] text-[#2A2A2A] font-[600] text-[17px] leading-[26px] pt-4 text-center`}>Your trial period is ending soon! Renew now
				to continue enjoying our services.</p>
				<p className={`w-[80%] text-[#2A2A2A] font-[600] text-[17px] leading-[26px] pb-2 text-center`}>
				Only {days} days left!</p>
				<div className='w-[60%] pt-4 text-center' onClick={() => {navigator('/auth/new')}}>
							
							<Button className='btn-primary' block >
								Upgrade Now
							</Button>
						
				</div>
				</div>
            </Modal>
			<Modal
                // title="Welcome"
                visible={modalUpgradeVisible}
                onCancel={handleCloseModalUpgrade} // No action when clicking cancel
                footer={[]}
            >
				<div className='flex flex-col justify-center items-center  mt-4'>
				{/* <img className='w-[22%] object-cover' src={Clock} alt='' /> */}
				<img className='w-[90%] object-cover pb-2' src={Year} alt='' />
                <p className={`w-[80%] text-[#2A2A2A] font-[600] text-[17px] leading-[26px] pt-4 text-center`}>Upgrade to Premium now in just $204 AUD/Year</p>
				
				<div className='w-[60%] pt-4 text-center' onClick={() => {navigator('/auth/new') 
				localStorage.setItem('premium', JSON.stringify(null))}}>
							
							<Button className='btn-primary' block >
								Upgrade Now
							</Button>
						
				</div>
				</div>
            </Modal>
			<div className='max-md:pt-14 sm:pt-20'>
				<Form form={form}>
					<div
						className={`lg:hidden relative w-full  ${
							pathname === '/home/search' ? (searchHeaderVisible ? 'h-[400px] rounded-b-2xl overflow-hidden' : 'h-[50px]') : 'h-[600x]'
						} overflow-y-clip transition-[height] duration-1000`}
					>
						{pathname === '/home/search' ? (
							<div className={`transition-[height] duration-1000 ${searchHeaderVisible ? 'h-[400px] overflow-hidden' : 'h-[0px] overflow-hidden'}`}>
								<img className='h-[400px] w-auto object-cover' src={HeroBackground1} alt='' />
							</div>
						) : (
							<Carousel className={pathname === '/home/search' ? (searchHeaderVisible ? 'h-[400px]' : 'h-[50px]') : 'h-[600px]'} autoplay autoPlaySpeed={8000}>
								<img className='h-[600px] w-auto object-cover' src={HeroBackground1} alt='' />
								<img className='h-[600px] w-auto object-cover' src={HeroBackground2} alt='' />
								<img className='h-[600px] w-auto object-cover' src={HeroBackground3} alt='' />
								<img className='h-[600px] w-auto object-cover' src={HeroBackground4} alt='' />
							</Carousel>
						)}

						<div
							className={`absolute top-0 bottom-0 right-0 left-0  ${
								pathname === '/home/search' ? (searchHeaderVisible ? 'h-[400px]' : 'h-[0px]') : 'h-[600px]'
							}  transition-[height] duration-1000 w-full bg-[#44444458]`}
						></div>

						<div
							className={`absolute flex flex-col items-center justify-start max-md:px-4 md:px-24  ${
								pathname === '/home/search' ? (searchHeaderVisible ? 'pt-8' : 'hidden') : 'pt-24'
							}  left-0 right-0 top-0  bottom-8 w-full`}
						>
							{pathname !== '/home/search' ? <p className={`text-center text-3xl font-bold text-white pb-8`}>Search Property</p> : undefined}

							<div className='w-full px-5 py-8 rounded-lg backdrop-blur-[2px] border border-solid border-gray-300 flex flex-col items-center justify-center'>
								<div className='flex flex-col items-center space-y-4 w-full'>
									{Form.renderSchema([
										[
											{
												type: 'select',
												key: 'country',
												name: ['country'],
												itemClassName: '!mb-0 !w-full',
												className: '!w-full',
												customWidth: true,
												placeholder: 'Select Country',
												required: true,
												showSearch: true,
												message: 'Please enter an description',
												options: countryOptions,
												displayProperty: 'label',
												valueProperty: 'value',
											},
											...(loggedInUser?._id
												? [
														{
															type: 'select',
															key: 'city',
															name: ['city'],
															itemClassName: '!mb-0 !w-full',
															className: '!w-full',
															customWidth: true,
															placeholder: 'Select City',
															required: true,
															showSearch: true,
															message: 'Please enter an description',
															options: citiesOptions,

															displayProperty: 'label',
															valueProperty: 'value',
														},
												  ]
												: [{}]),
										],

										{
											type: 'select',
											key: 'type',
											name: ['type'],
											itemClassName: '!mb-0 w-full',
											customWidth: true,
											placeholder: 'Select Type',
											required: true,
											message: 'Please enter an description',
											options: [
												{ label: 'Swap', value: 'swap' },
												{ label: 'Sub-Lease', value: 'sublease' },
											],
											displayProperty: 'label',
											valueProperty: 'value',
											initialValue: 'swap',
										},
									])}
									{loggedInUser?._id && (
										<Form.Item name={['dateRange']} className='!mb-0 !w-full'>
											<MobileRangePicker />
										</Form.Item>
									)}
									{loggedInUser?._id && (
										<label className='checkBoxBackground'>
										<input 
										type="checkbox"
										checked={checked}
										onChange={handleChange}
										>
										</input >
										<p id = 'checkMobile' className='checkBoxLabel'>Flexible Dates</p>
										</label>
									)}
									<Button
										disabled={
											loggedInUser?._id
												? !(formValues?.country && formValues?.city && formValues?.type && (formValues?.dateRange || checked))
												: !(formValues?.country && formValues?.type)
										}
										className='btn-primary w-full !h-[40px] '
										onClick={() => {
											fetchData()
											navigator('/home/search')
										}}
									>
										Search
									</Button>
								</div>
							</div>
						</div>
						{pathname === '/home/search' ? (
							<div className={`absolute ${!searchHeaderVisible ? 'bottom-0' : 'bottom-6'} w-full flex flex-row items-center text-center justify-center`}>
								<div
									className='rounded-full p-2 bg-[#9B83CB] text-center'
									onClick={() => {
										setSearchHeaderVisible((prev) => !prev)
									}}
								>
									<Icon
										path={mdiChevronDoubleDown}
										size={0.7}
										className={`text-base leading-8 text-center font-[500] transition-all text-white ${searchHeaderVisible ? 'rotate-180' : 'rotate-0'}`}
									/>
								</div>
							</div>
						) : undefined}
					</div>
					<div
						className={` max-lg:hidden  relative w-full ${
							pathname === '/home/search' ? 'h-[300px]' : 'h-[600px]'
						} overflow-y-clip transition-[height] duration-1000`}
					>
						<Carousel className={pathname === '/home/search' ? 'h-[300px]' : 'h-[600px]'} autoplay autoPlaySpeed={8000}>
							<img className='w-full h-auto' src={HeroBackground1} alt='' />
							<img className='w-full h-auto' src={HeroBackground2} alt='' />
							<img className='w-full h-auto' src={HeroBackground3} alt='' />
							<img className='w-full h-auto' src={HeroBackground4} alt='' />
						</Carousel>
						<div
							className={`absolute top-0 bottom-0 right-0 left-0  ${
								pathname === '/home/search' ? 'h-[300px]' : 'h-[600px]'
							}  transition-[height] duration-1000 w-full bg-[#44444458]`}
						></div>
						<div className='absolute flex flex-col items-center justify-center  left-0 right-0 top-0  bottom-0 w-full'>
							<div className={`flex flex-col items-start justify-center ${!loggedInUser?._id ? 'bg-white px-8 py-6 rounded-md' : ''}`}>
								{loggedInUser?._id ? (
									<p className='text-3xl font-bold text-white pb-4'>Search Property</p>
								) : (
									<p className='text-base font-semibold text-black-300 pb-4'>Where are you going?</p>
								)}
								<div className='flex flex-row items-center space-between space-x-4'>
									{Form.renderSchema([
										[
											{
												type: 'select',
												key: 'country',
												name: ['country'],
												itemClassName: '!mb-0 !w-[12rem]',
												customWidth: true,
												placeholder: 'Select Country',
												required: true,
												showSearch: true,
												message: 'Please enter an description',
												options: countryOptions,
												displayProperty: 'label',
												valueProperty: 'value',
											},
											...(loggedInUser?._id
												? [
														{
															type: 'select',
															key: 'country',
															name: ['city'],
															itemClassName: '!mb-0 !w-[12rem]',
															customWidth: true,
															placeholder: 'Select State/City',
															required: true,
															showSearch: true,
															message: 'Please enter an description',
															options: citiesOptions,

															displayProperty: 'label',
															valueProperty: 'value',
														},
												  ]
												: [{}]),
											{
												type: 'select',
												key: 'type',
												name: ['type'],
												itemClassName: '!mb-0 !w-[12rem]',
												customWidth: true,
												placeholder: 'Select Type',
												required: true,
												message: 'Please enter an description',
												options: [
													{ label: 'Swap', value: 'swap' },
													{ label: 'Sub-Lease', value: 'sublease' },
												],
												displayProperty: 'label',
												valueProperty: 'value',
												initialValue: 'swap',
											},
											...(loggedInUser?._id
												? [
														{
															type: 'dateRange',
															key: 'dateRange',
															name: ['dateRange'],
															itemClassName: '!mb-0 !w-[17rem]',
															customWidth: true,
															showTime: false,
															required: true,
															message: 'Please enter an description',
														},
												  ]
												: [{}]),
										],
									])}
									{loggedInUser?._id && (
										<label className='checkBoxBackground'>
										<input 
										type="checkbox"
										checked={checked}
										onChange={handleChange}
										>
										</input >
										<p id = 'checkWeb' className='checkBoxLabel'>Flexible Dates</p>
										</label>
									)}
									<Button
										disabled={
											loggedInUser?._id
												? !(formValues?.country && formValues?.city && formValues?.type && (formValues?.dateRange || checked))
												: !(formValues?.country && formValues?.type)
										}
										className='btn-primary'
										onClick={() => {
											fetchData()
											navigator('/home/search')
										}}
									>
										{loggedInUser?._id ? 'Search' : 'Find Home'}
									</Button>
								</div>
							</div>
						</div>
					</div>
					<Outlet context={{ homeRef, form, loading }} />
				</Form>
			</div>
		</div>
	)
}

export default Home
