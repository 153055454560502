const statesToCities = {
	Aargau: ['Wettingen', 'Baden', 'Aarau'],
	'Abai Region': ['Semey', 'Kalbatau', 'Ayagoz'],
	Abia: ['Umuahia', 'Ohafia-Ifigh', 'Bende', 'Amaigbo', 'Aba'],
	Abidjan: ['Bingerville', 'Anyama', 'Abobo', 'Abidjan', 'Marcory'],
	Abkhazia: ["Tqvarch'eli", 'Sokhumi', 'Och’amch’ire', 'Stantsiya Novyy Afon'],
	Abruzzo: [
		'Vasto',
		'Teramo',
		'Sulmona',
		'San Salvo',
		'Roseto degli Abruzzi',
		'Pescara',
		'Montesilvano Marina',
		"L'Aquila",
		'Lanciano',
		'Giulianova',
		'Francavilla al Mare',
		'Chieti',
		'Avezzano',
	],
	'Abu Dhabi': ['Zayed City', 'Ar Ruways', 'Al Ain City', 'Abu Dhabi', 'Khalifah A City', 'Bani Yas City', 'Musaffah', 'Al Shamkhah City'],
	'Abyan Governorate': ['Zinjibār'],
	Abşeron: ['Khirdalan'],
	Aceh: ['Singkil', 'Sigli', 'Sabang', 'Reuleuet', 'Meulaboh', 'Lhokseumawe', 'Langsa', 'Blangpidie', 'Bireun', 'Banda Aceh'],
	Achara: ['Kobuleti', 'Batumi'],
	Acre: ['Tarauacá', 'Sena Madureira', 'Rio Branco', 'Cruzeiro do Sul', 'Senador Guiomard'],
	'Ad Dakhiliyah': ['Sufālat Samā’il', 'Nizwá', 'Izkī', 'Bidbid', 'Bahlā’', 'Adam'],
	'Ad Dhahirah': ['‘Ibrī', 'Yanqul'],
	Adamaoua: ['Tibati', 'Ngaoundéré', 'Meïganga', 'Banyo'],
	Adamawa: ['Yola', 'Numan', 'Mubi', 'Jimeta', 'Gombi', 'Ganye'],
	Adana: ['Kozan', 'İmamoğlu', 'Ceyhan', 'Adana'],
	'Addis Ababa': ['Addis Ababa'],
	Aden: ['Aden'],
	Adrar: ['Reggane', 'Aoulef', 'Adrar', 'Atar'],
	'Adygeya Republic': ['Yablonovskiy', 'Maykop', 'Giaginskaya', 'Enem'],
	'Adıyaman Province': ['Kâhta', 'Gölbaşı', 'Besni', 'Adıyaman'],
	'Afyonkarahisar Province': ['Sandıklı', 'Emirdağ', 'Dinar', 'Çay', 'Bolvadin', 'Afyonkarahisar'],
	Agadez: ['Alaghsas', 'Agadez'],
	Agder: ['Kristiansand', 'Grimstad', 'Arendal'],
	Aguadilla: ['Aguadilla'],
	Aguascalientes: ['San Francisco de los Romo', 'Rincón de Romos', 'Pabellón de Arteaga', 'Jesús María', 'Calvillo', 'Aguascalientes'],
	Ahafo: ['Duayaw-Nkwanta', 'Bechem'],
	Ahal: ['Kaka', 'Abadan', 'Baharly', 'Änew', 'Tejen'],
	Ahuachapán: ['Ahuachapán'],
	Aichi: [
		'Tsushima',
		'Toyota',
		'Toyokawa',
		'Toyohashi',
		'Toyohama',
		'Tokoname',
		'Taketoyo',
		'Takahama',
		'Tahara',
		'Sobue',
		'Shinshiro',
		'Seto',
		'Okazaki',
		'Ōbu',
		'Nishio',
		'Nagoya',
		'Kuroda',
		'Kozakai-chō',
		'Kōnan',
		'Komaki',
		'Kasugai',
		'Kariya',
		'Kanie',
		'Iwakura',
		'Ishiki',
		'Inuyama',
		'Inazawa',
		'Ichinomiya',
		'Hekinan',
		'Handa',
		'Gamagōri',
		'Chiryū',
		'Anjō',
		'Kitanagoya',
		'Nisshin',
		'Chita',
	],
	Aileu: ['Aileu'],
	Ajlun: ['‘Anjarah', '‘Ajlūn'],
	Ajman: ['Ajman City'],
	Akita: ['Tennō', 'Yuzawa', 'Yokote', 'Ōmagari', 'Akita', 'Takanosu', 'Ōdate', 'Noshiro', 'Hanawa', 'Yurihonjō', 'Kita-Akita', 'Semboku'],
	Aksaray: ['Ortaköy', 'Aksaray'],
	'Akwa Ibom': ['Uyo', 'Itu', 'Ikot Ekpene', 'Esuk Oron', 'Eket'],
	'Al Anbar': ['Hīt', 'Ḩadīthah', 'Ar Ruţbah', 'Ramadi', '‘Anat al Qadīmah', 'Al Fallūjah', '‘Anah'],
	'Al Asimah': ['Kuwait City', 'Ad Dasmah', 'Ar Rābiyah'],
	'Al Aḩmadī': ['Ar Riqqah', 'Al Manqaf', 'Al Mahbūlah', 'Al Faḩāḩīl', 'Al Finţās', 'Al Aḩmadī'],
	'Al Bahah Region': ['Al Bahah'],
	'Al Batinah North': ['Sohar', 'Shināş', 'Şaḩam', 'As Suwayq', 'Liwá', 'Al Khābūrah'],
	'Al Batinah South': ['Barkā’', 'Rustaq', 'Oman Smart Future City'],
	'Al Bayda': ['Al Bayda'],
	'Al Buraimi': ['Al Buraymī'],
	'Al Buţnān': ['Tobruk'],
	'Al Farwaniyah': ['Janūb as Surrah', 'Al Farwānīyah'],
	'Al Hudaydah': ['Zabīd', 'Bayt al Faqīh', 'Bājil', 'Al Ḩudaydah'],
	'Al Jabal al Akhḑar': ['Al Bayḑā’'],
	'Al Jahrāʼ': ['Al Jahrā’'],
	'Al Jawf': ['Al Ḩazm'],
	'Al Jawf Region': ['Ţubarjal', 'Sakakah', 'Qurayyat'],
	'Al Jazīrah': ['Wad Medani', 'Al Manāqil', 'Al Hilāliyya', 'Al Hasaheisa'],
	'Al Jufrah': ['Waddān', 'Hūn'],
	'Al Khor': ['Al Khawr'],
	'Al Kufrah': ['At Tāj', 'Al Jawf'],
	'Al Marj': ['Tūkrah', 'Al Marj', 'Al Abyār'],
	'Al Marqab': ['Tarhuna', 'Masallātah', 'Al Khums'],
	'Al Muthanná': ['As Samawah', 'Ar Rumaythah'],
	'Al Qaḑārif': ['Doka', 'Al Qadarif', 'Al Ḩawātah'],
	'Al Qādisīyah': ['Nāḩiyat ash Shināfīyah', 'Ash Shāmīyah', 'Nahiyat Ghammas', '‘Afak', 'Ad Dīwānīyah'],
	'Al Wakrah': ['Al Wakrah'],
	'Al Wāḩāt': ['Az Zuwaytīnah', 'Ajdabiya'],
	'Al-Hasakah': ['Ad Darbāsīyah', 'Al Ḩasakah'],
	'Al-Qassim Region': ['Buraydah', 'Ar Rass', 'Al Mithnab', 'Al Bukayrīyah'],
	Alabama: [
		'Bessemer',
		'Birmingham',
		'Center Point',
		'Cullman',
		'Daphne',
		'Decatur',
		'Dothan',
		'East Florence',
		'Enterprise',
		'Fairhope',
		'Florence',
		'Foley',
		'Gadsden',
		'Helena',
		'Homewood',
		'Hoover',
		'Hueytown',
		'Huntsville',
		'Madison',
		'Millbrook',
		'Mobile',
		'Montgomery',
		'Mountain Brook',
		'Northport',
		'Opelika',
		'Oxford',
		'Pelham',
		'Phenix City',
		'Prattville',
		'Prichard',
		'Selma',
		'Talladega',
		'Tillmans Corner',
		'Troy',
		'Trussville',
		'Tuscaloosa',
		'Vestavia Hills',
		'Alabaster',
		'Albertville',
		'Anniston',
		'Athens',
		'Auburn',
		'Dixiana',
	],
	Alagoas: [
		'Viçosa',
		'União dos Palmares',
		'Satuba',
		'São Miguel dos Campos',
		'São Luís do Quitunde',
		'Santana do Ipanema',
		'Rio Largo',
		'Porto Calvo',
		'Pilar',
		'Murici',
		'Matriz de Camaragibe',
		'Marechal Deodoro',
		'Maragogi',
		'Maceió',
		'Delmiro Gouveia',
		'Campo Alegre',
		'Cajueiro',
		'Atalaia',
		'Arapiraca',
		'Penedo',
		'Coruripe',
	],
	'Alajuela Province': ['San José', 'Quesada', 'Alajuela'],
	'Alaotra Mangoro': ['Moramanga', 'Andilamena', 'Amparafaravola', 'Ambatondrazaka'],
	Alaska: ['Juneau', 'Eagle River', 'Fairbanks', 'Anchorage', 'Badger'],
	Alba: ['Sebeş', 'Ocna Mureş', 'Cugir', 'Blaj', 'Alba Iulia', 'Aiud'],
	Alberta: [
		'Airdrie',
		'Calgary',
		'Camrose',
		'Cochrane',
		'Edmonton',
		'Fort McMurray',
		'Grande Prairie',
		'Leduc',
		'Lethbridge',
		'Lloydminster',
		'Medicine Hat',
		'Red Deer',
		'Sherwood Park',
		'Spruce Grove',
		'St. Albert',
	],
	'Alborz Province': ['Naz̧arābād', 'Karaj'],
	Aleppo: [
		'Tall Rif‘at',
		'Tādif',
		'Kabbasin',
		'Nubl',
		'Manbij',
		'Jarābulus',
		'Aleppo',
		'Dayr Ḩāfir',
		'Batabo',
		'I‘zāz',
		'‘Ayn al ‘Arab',
		'As Safīrah',
		'Al Bāb',
		'‘Afrīn',
	],
	Alexandria: ['Alexandria'],
	Algiers: [
		'Zeralda',
		'Sidi Moussa',
		'Saoula',
		'Rouiba',
		'Reghaïa',
		'Douera',
		'Dar el Beïda',
		'Cheraga',
		'Bordj el Kiffan',
		'Birkhadem',
		'Baraki',
		'Bab Ezzouar',
		'Algiers',
		'Aïn Taya',
		'’Aïn Benian',
	],
	'Ali Sabieh': ['Ali Sabieh'],
	Alibori: ['Malanville', 'Kandi', 'Banikoara'],
	Almaty: ['Talghar', 'Otegen Batyra', 'Burunday', 'Almaty'],
	'Almaty Oblysy': ['Konayev', 'Esik'],
	'Alta Verapaz': ['San Pedro Carchá', 'San Cristóbal Verapaz', 'Panzos', 'Cobán', 'Chisec'],
	Altai: ['Mayma', 'Gorno-Altaysk'],
	'Altai Krai': [
		'Zarinsk',
		'Yuzhnyy',
		'Yarovoye',
		'Tal’menka',
		'Slavgorod',
		'Rubtsovsk',
		'Novosilikatnyy',
		'Novoaltaysk',
		'Kulunda',
		'Kamen’-na-Obi',
		'Gornyak',
		'Biysk',
		'Barnaul',
		'Aleysk',
	],
	'Alto Paraná': ['Presidente Franco', 'Ciudad del Este'],
	Alytus: ['Alytus'],
	'Alūksne Municipality': ['Dārzciems'],
	Amambay: ['Pedro Juan Caballero'],
	'Amanat Alasimah': ['Sanaa'],
	Amapá: ['Santana', 'Macapá'],
	Amasya: ['Taşova', 'Suluova', 'Merzifon', 'Amasya'],
	Amazonas: [
		'Parintins',
		'Maués',
		'Itacoatiara',
		'Tefé',
		'Tabatinga',
		'São Gabriel da Cachoeira',
		'Manaus',
		'Manacapuru',
		'Humaitá',
		'Fonte Boa',
		'Eirunepé',
		'Coari',
		'Carauari',
		'Rio Preto da Eva',
		'Leticia',
		'La Peca',
		'Chachapoyas',
		'Bagua Grande',
		'Puerto Ayacucho',
	],
	Amhara: [
		'Werota',
		'Robīt',
		'Kemisē',
		'Kombolcha',
		'Gondar',
		'Finote Selam',
		'Desē',
		'Debre Tabor',
		'Debre Mark’os',
		'Debre Birhan',
		'Debark’',
		'Burē',
		'Bichena',
		'Batī',
		'Bahir Dar',
		'Ādīs Zemen',
		'Ādēt',
		'Abomsa',
	],
	Amman: ['Wādī as Sīr', 'Umm as Summāq', 'Saḩāb', 'Amman', 'Ḩayy al Quwaysimah', 'Al Jubayhah'],
	Ammochostos: ['Protaras', 'Famagusta'],
	'Amnat Charoen': ['Amnat Charoen'],
	"Amoron'i Mania": ['Fandriana', 'Ambositra', 'Ambatofinandrahana'],
	'Amur Oblast': ['Zeya', 'Tynda', 'Svobodnyy', 'Shimanovsk', 'Raychikhinsk', 'Blagoveshchensk', 'Belogorsk'],
	'An Giang': ['Long Xuyên', 'Châu Đốc'],
	'An Najaf': ['Najaf', 'Al Mishkhāb', 'Kufa'],
	'An Nuqāţ al Khams': ['Zuwārah', 'Zalţan', 'Al Ajaylat', 'Al Jumayl'],
	Analamanga: ['Sadabe', 'Manjakandriana', 'Antananarivo', 'Ankazondandy', 'Ankazobe', 'Anjozorobe', 'Ambohitrolomahitsy', 'Alarobia'],
	Analanjirofo: ['Vavatenina', 'Soanierana Ivongo', 'Maroantsetra', 'Mananara', 'Fenoarivo Atsinanana'],
	Anambra: ['Uga', 'Ozubulu', 'Onitsha', 'Nnewi', 'Nkpor', 'Ihiala', 'Igbo-Ukwu', 'Enugu-Ukwu', 'Awka', 'Atani', 'Agulu'],
	Ancash: ['Huaraz', 'Coishco', 'Chimbote', 'Huarmey', 'Centenario'],
	Andalusia: [
		'Zubia',
		'Vícar',
		'Vélez-Málaga',
		'Utrera',
		'Ubrique',
		'Úbeda',
		'Torrox',
		'Torremolinos',
		'Torre del Mar',
		'Tomares',
		'Tarifa',
		'Sevilla',
		'Santafé',
		'San Roque',
		'San Pedro de Alcántara',
		'Sanlúcar de Barrameda',
		'San Juan de Aznalfarache',
		'San Fernando',
		'Rota',
		'Roquetas de Mar',
		'Ronda',
		'Rincón de la Victoria',
		'Puerto Real',
		'Puente-Genil',
		'Priego de Córdoba',
		'Pozoblanco',
		'Palma del Río',
		'Osuna',
		'Níjar',
		'Nerja',
		'Motril',
		'Morón de la Frontera',
		'Montilla',
		'Moguer',
		'Mijas',
		'Martos',
		'Marchena',
		'Marbella',
		'Maracena',
		'Málaga',
		'Mairena del Aljarafe',
		'Mairena del Alcor',
		'Lucena',
		'Los Palacios y Villafranca',
		'Los Barrios',
		'Lora del Río',
		'Loja',
		'Linares',
		'Lepe',
		'Lebrija',
		'Las Cabezas de San Juan',
		'La Rinconada',
		'La Línea de la Concepción',
		'La Carolina',
		'La Algaba',
		'Jerez de la Frontera',
		'Jaén',
		'Isla Cristina',
		'Huércal-Overa',
		'Huelva',
		'Guadix',
		'Granada',
		'Fuengirola',
		'Estepona',
		'El Viso del Alcor',
		'El Puerto de Santa María',
		'El Ejido',
		'El Arahal',
		'Écija',
		'Dos Hermanas',
		'Coria del Río',
		'Córdoba',
		'Conil de la Frontera',
		'Coín',
		'Chipiona',
		'Chiclana de la Frontera',
		'Castilleja de la Cuesta',
		'Cartaya',
		'Cártama',
		'Carmona',
		'Campiña',
		'Camas',
		'Cadiz',
		'Cabra',
		'Bormujos',
		'Berja',
		'Benalmádena',
		'Baza',
		'Barbate',
		'Bailén',
		'Baeza',
		'Baena',
		'Ayamonte',
		'Atarfe',
		'Arroyo de la Miel',
		'Armilla',
		'Arcos de la Frontera',
		'Antequera',
		'Andújar',
		'Almuñécar',
		'Almonte',
		'Almería',
		'Aljaraque',
		'Alhaurín el Grande',
		'Alhaurín de la Torre',
		'Algeciras',
		'Alcalá la Real',
		'Alcalá de Guadaira',
		'Albolote',
		'Adra',
		'Las Gabias',
	],
	'Andaman and Nicobar': ['Port Blair'],
	'Andhra Pradesh': [
		'Yanam',
		'Vuyyūru',
		'Vizianagaram',
		'Visakhapatnam',
		'Vinukonda',
		'Vijayawada',
		'Vetapālem',
		'Vepagunta',
		'Venkatagiri',
		'Vadlapūdi',
		'Uravakonda',
		'Tuni',
		'Tirupati',
		'Tirumala',
		'Tekkali',
		'Tanuku',
		'Tādpatri',
		'Tādepallegūdem',
		'Tādepalle',
		'Sūlūru',
		'Srīsailain',
		'Srikakulam',
		'Sompeta',
		'Singarāyakonda',
		'Sattenapalle',
		'Sāmalkot',
		'Sālūr',
		'Repalle',
		'Renigunta',
		'Rāzām',
		'Rāyadrug',
		'Rāyachoti',
		'Rāmāpuram',
		'Rāmachandrapuram',
		'Rāzampeta',
		'Rājahmundry',
		'Puttūr',
		'Punganūru',
		'Pulivendla',
		'Proddatūr',
		'Ponnūru',
		'Polavaram',
		'Pithāpuram',
		'Phirangipuram',
		'Penukonda',
		'Penugonda',
		'Peddāpuram',
		'Pedana',
		'Pārvatipuram',
		'Parlākimidi',
		'Palmaner',
		'Pālkonda',
		'Palāsa',
		'Pālakollu',
		'Pākāla',
		'Ongole',
		'Nūzvīd',
		'Nidadavole',
		'Nellore',
		'Nāyudupet',
		'Narsīpatnam',
		'Narasaraopet',
		'Narasapur',
		'Narasannapeta',
		'Nandyāl',
		'Nandikotkūr',
		'Nandigāma',
		'Nagari',
		'Mārkāpur',
		'Mangalagiri',
		'Mandapeta',
		'Madanapalle',
		'Machilīpatnam',
		'Mācherla',
		'Kurnool',
		'Kuppam',
		'Kovvūr',
		'Kovūr',
		'Kosigi',
		'Kondapalle',
		'Kāvali',
		'Kanigiri',
		'Kandukūr',
		'Kākināda',
		'Kaikalūr',
		'Kadiri',
		'Jammalamadugu',
		'Jaggayyapeta',
		'Ichchāpuram',
		'Hindupur',
		'Guntur',
		'Guntakal Junction',
		'Gūdūr',
		'Gudivāda',
		'Gorantla',
		'Gokavaram',
		'Giddalūr',
		'Gannavaram',
		'Erraguntla',
		'Emmiganūr',
		'Ellore',
		'Elamanchili',
		'Dhone',
		'Dharmavaram',
		'Darsi',
		'Cumbum',
		'Kadapa',
		'Chodavaram',
		'Rampachodavaram',
		'Chīrāla',
		'Chīpurupalle',
		'Chilakalūrupet',
		'Challapalle',
		'Bobbili',
		'Bhīmunipatnam',
		'Bhīmavaram',
		'Bhattiprolu',
		'Betamcherla',
		'Bāpatla',
		'Banganapalle',
		'Badvel',
		'Avanigadda',
		'Attili',
		'Atmakūr',
		'Anantapur',
		'Anakāpalle',
		'Amudālavalasa',
		'Amalāpuram',
		'Akivīdu',
		'Ādoni',
		'Addanki',
		'Akkarampalle',
		'Chemmumiahpet',
		'Kanuru',
		'Chinnachowk',
		'Gajuwaka',
		'Kalyandurg',
		'Ponnur',
		'Ramanayyapeta',
		'Yanamalakuduru',
		'Amaravati',
	],
	Andijon: ['Asaka', 'Qo‘rg‘ontepa', 'Xonobod', 'Xo‘jaobod', 'Qorasuv', 'Jalolquduq', 'Dardoq', 'Andijon', 'Oyim', 'Sultonobod'],
	'Andorra la Vella': ['Andorra la Vella'],
	Androy: ['Tsiombe', 'Beloha', 'Ambovombe'],
	'Ang Thong': ['Wiset Chaichan'],
	Anhui: [
		'Yingshang Chengguanzhen',
		'Xuanzhou',
		'Wuyang',
		'Wuhu',
		'Wucheng',
		'Huangshan',
		'Tongling',
		'Tangzhai',
		'Suzhou',
		'Suixi',
		'Qishan',
		'Mingguang',
		'Mengcheng Chengguanzhen',
		'Ma’anshan',
		'Lucheng',
		'Lu’an',
		'Jieshou',
		'Huoqiu Chengguanzhen',
		'Huicheng',
		'Huaiyuan Chengguanzhen',
		'Huainan',
		'Huaibei',
		'Hefei',
		'Haiyang',
		'Fuyang',
		'Datong',
		'Gushu',
		'Chuzhou',
		'Chizhou',
		'Chaohu',
		'Bozhou',
		'Biyang',
		'Bengbu',
		'Anqing',
	],
	Anjouan: ['Moutsamoudou'],
	Ankara: [
		'Şereflikoçhisar',
		'Polatlı',
		'Elmadağ',
		'Ankara',
		'Batikent',
		'Nallıhan',
		'Kızılcahamam',
		'Kazan',
		'Çubuk',
		'Beypazarı',
		'Akyurt',
		'Çankaya',
	],
	Annaba: ['El Hadjar', 'Berrahal', 'Annaba'],
	Anosy: ['Tôlanaro', 'Amboasary'],
	Anseba: ['Keren'],
	Antalya: [
		'Tekirova',
		'Serik',
		'Manavgat',
		'Mahmutlar',
		'Beykonak',
		'Korkuteli',
		'Kemer',
		'Gazipaşa',
		'Elmalı',
		'Belek',
		'Antalya',
		'Alanya',
		'Muratpaşa',
	],
	Antioquia: [
		'Zaragoza',
		'Yondó',
		'Yarumal',
		'Urrao',
		'Turbo',
		'Sonsón',
		'Segovia',
		'San Vicente',
		'Santuario',
		'Santa Rosa de Osos',
		'San Pedro de Urabá',
		'San Juan de Urabá',
		'Salgar',
		'Sabaneta',
		'Rionegro',
		'Retiro',
		'Puerto Triunfo',
		'Puerto Berrío',
		'Nechí',
		'Medellín',
		'Marinilla',
		'La Unión',
		'La Estrella',
		'La Ceja',
		'Ituango',
		'Itagüí',
		'Frontino',
		'Envigado',
		'El Bagre',
		'Dabeiba',
		'Copacabana',
		'Concordia',
		'Chigorodó',
		'Caucasia',
		'Carmen de Viboral',
		'Caldas',
		'Betulia',
		'Bello',
		'Barbosa',
		'Apartadó',
		'Santa Fe de Antioquia',
		'Andes',
		'Abejorral',
		'Fredonia',
		'Carepa',
		'Ciudad Bolívar',
	],
	Antofagasta: ['Tocopilla', 'Calama', 'Antofagasta'],
	Anzoátegui: [
		'Anaco',
		'Soledad',
		'San José de Guanipa',
		'Puerto Píritu',
		'Puerto La Cruz',
		'Pariaguán',
		'El Tigre',
		'Lecherías',
		'Clarines',
		'Cantaura',
		'Barcelona',
		'Aragua de Barcelona',
	],
	Aomori: ['Mutsu', 'Namioka', 'Misawa', 'Kuroishi', 'Shimokizukuri', 'Hirosaki', 'Hachinohe', 'Aomori', 'Goshogawara', 'Hirakawa', 'Towada'],
	'Aosta Valley': ['Aosta'],
	'Appenzell Ausserrhoden': ['Herisau'],
	Apulia: [
		'Casarano',
		'Valenzano',
		'Triggiano',
		'Trani',
		'Torremaggiore',
		'Terlizzi',
		'Taranto',
		'Sava',
		'San Vito dei Normanni',
		'Santeramo in Colle',
		'San Severo',
		'Sannicandro Garganico',
		'San Giovanni Rotondo',
		'Ruvo di Puglia',
		'Rutigliano',
		'Putignano',
		'Palo del Colle',
		'Palagiano',
		'Ostuni',
		'Orta Nova',
		'Noicattaro',
		'Noci',
		'Nardò',
		'Monopoli',
		'Molfetta',
		'Mola di Bari',
		'Modugno',
		'Mesagne',
		'Massafra',
		'Martina Franca',
		'Manfredonia',
		'Manduria',
		'Lucera',
		'Lecce',
		'Grottaglie',
		'Gravina in Puglia',
		'Giovinazzo',
		'Gioia del Colle',
		'Ginosa',
		'Gallipoli',
		'Galatina',
		'Francavilla Fontana',
		'Foggia',
		'Fasano',
		'Corato',
		'Copertino',
		'Conversano',
		'Cerignola',
		'Ceglie Messapica',
		'Casamassima',
		'Canosa di Puglia',
		'Brindisi',
		'Bitonto',
		'Bisceglie',
		'Barletta',
		'Bari',
		'Andria',
		'Altamura',
		'Adelfia',
		'Acquaviva delle Fonti',
		'San Paolo',
		'Paolo VI',
	],
	Apure: ['Elorza', 'Biruaca', 'Achaguas', 'Guasdualito', 'San Fernando de Apure'],
	Apurímac: ['Andahuaylas', 'Abancay'],
	Aqaba: ['Aqaba'],
	Aqmola: ['Shchuchinsk', 'Makinsk', 'Kokshetau', 'Atbasar', 'Akkol’', 'Stepnogorsk'],
	Aqtöbe: ['Shalqar', 'Kandyagash', 'Khromtau', 'Embi', 'Aktobe'],
	'Ar-Raqqah': ['Ath Thawrah', 'Ar Raqqah'],
	Arad: ['Arad'],
	Aragatsotn: ['Ashtarak'],
	Aragon: [
		'Zaragoza',
		'Utebo',
		'Teruel',
		'Monzón',
		'Huesca',
		'Ejea de los Caballeros',
		'Calatayud',
		'Barbastro',
		'Alcañiz',
		'Delicias',
		'Almozara',
		'Montecanal',
		'Oliver-Valdefierro',
	],
	Aragua: ['Villa de Cura', 'Turmero', 'San Mateo', 'Palo Negro', 'Maracay', 'La Victoria', 'Las Tejerías', 'El Limón', 'Cagua'],
	Ararat: ['Artashat', 'Ararat', 'Masis'],
	Araucanía: ['Villarrica', 'Victoria', 'Temuco', 'Pucón', 'Nueva Imperial', 'Loncoche', 'Lautaro', 'Collipulli', 'Angol'],
	Arbīl: ['Ṟuwandiz', 'Koysinceq', 'Erbil', 'Soran'],
	Ardabīl: ['Pārsābād', 'Khalkhāl', 'Ardabīl'],
	Ardahan: ['Ardahan'],
	Arecibo: ['Arecibo'],
	Arequipa: ['Mollendo', 'Camaná', 'Arequipa', 'Jacobo Hunter', 'Ciudad Satelite', 'Cono Norte', 'El Pedregal', 'Selva Alegre'],
	Arges: ['Piteşti', 'Curtea de Argeş', 'Mioveni'],
	'Ariana Governorate': ['Galaat el Andeless', 'Ariana'],
	'Arica y Parinacota': ['Arica'],
	Arizona: [
		'Buckeye',
		'Bullhead City',
		'Casa Grande',
		'Casas Adobes',
		'Catalina Foothills',
		'Chandler',
		'Deer Valley',
		'Douglas',
		'Drexel Heights',
		'El Mirage',
		'Eloy',
		'Flagstaff',
		'Florence',
		'Flowing Wells',
		'Fortuna Foothills',
		'Fountain Hills',
		'Gilbert',
		'Glendale',
		'Goodyear',
		'Green Valley',
		'Kingman',
		'Lake Havasu City',
		'Marana',
		'Maricopa',
		'Maryvale',
		'Mesa',
		'Nogales',
		'Oro Valley',
		'Payson',
		'Peoria',
		'Phoenix',
		'Prescott',
		'Prescott Valley',
		'Queen Creek',
		'Rio Rico',
		'Sahuarita',
		'San Luis',
		'Scottsdale',
		'Sierra Vista',
		'Somerton',
		'Sun City',
		'Sun City West',
		'Surprise',
		'Tanque Verde',
		'Tempe',
		'Tempe Junction',
		'Tucson',
		'Yuma',
		'Alhambra',
		'Anthem',
		'Apache Junction',
		'Avondale',
		'Ahwatukee Foothills',
		'San Tan Valley',
		'Encanto',
		'Central City',
	],
	Arkansas: [
		'Bella Vista',
		'Benton',
		'Bentonville',
		'Bryant',
		'Cabot',
		'Conway',
		'El Dorado',
		'Fayetteville',
		'Fort Smith',
		'Hot Springs',
		'Jacksonville',
		'Jonesboro',
		'Little Rock',
		'Maumelle',
		'North Little Rock',
		'Paragould',
		'Pine Bluff',
		'Rogers',
		'Russellville',
		'Searcy',
		'Siloam Springs',
		'Springdale',
		'Texarkana',
		'Van Buren',
		'West Memphis',
		'Sherwood',
		'Hot Springs National Park',
	],
	'Arkhangai Province': ['Tsetserleg'],
	Arkhangelskaya: ['Yagry', 'Vel’sk', 'Severodvinsk', 'Onega', 'Nyandoma', 'Novodvinsk', 'Kotlas', 'Koryazhma', 'Arkhangel’sk', 'Mirnyy', 'Isakogorka'],
	Armavir: ['Vagharshapat', 'Armavir'],
	Artemisa: ['San Cristobal', 'San Antonio de los Baños', 'Mariel', 'Güira de Melena', 'Guanajay', 'Bauta', 'Bahía Honda', 'Artemisa', 'Alquízar'],
	Artibonite: ['Verrettes', 'Saint-Marc', 'Gonaïves', 'Désarmes'],
	Artigas: ['Artigas'],
	Artvin: ['Hopa', 'Artvin', 'Arhavi'],
	'Arunachal Pradesh': ['Pāsighāt', 'Itānagar', 'Along', 'Naharlagun'],
	Arusha: ['Usa River', 'Mto wa Mbu', 'Mbuguni', 'Kingori', 'Kiratu', 'Arusha', 'Merelani'],
	'As-Suwayda': ['As-Suwayda'],
	'Ash Shāţiʼ': ['Brak'],
	Ashanti: ['Tafo', 'Obuase', 'Mampong', 'Kumasi', 'Konongo', 'Ejura', 'Agogo'],
	Ashgabat: ['Ashgabat'],
	'Asir Region': ['Qal‘at Bīshah', 'Khamis Mushait', 'An Nimas', 'Abha', 'Sabt Alalayah', 'Bariq'],
	Assaba: ['Kiffa'],
	Assam: [
		'Udalguri',
		'Tinsukia',
		'Tezpur',
		'Sonāri',
		'Silchar',
		'Sibsāgar',
		'Rangia',
		'Rangāpāra',
		'North Lakhimpur',
		'North Guwāhāti',
		'Nāmrup',
		'Nahorkatiya',
		'Mariāni',
		'Margherita',
		'Mangaldai',
		'Mākum',
		'Kokrajhar',
		'Khārupatia',
		'Karīmganj',
		'Jorhāt',
		'Howli',
		'Hojāi',
		'Hājo',
		'Hailākāndi',
		'Hāflong',
		'Golāghāt',
		'Goālpāra',
		'Gauripur',
		'Guwahati',
		'Dum Duma',
		'Duliajan',
		'Diphu',
		'Digboi',
		'Dibrugarh',
		'Dhuburi',
		'Dhing',
		'Dhekiajuli',
		'Chāpar',
		'Bongaigaon',
		'Bokajān',
		'Bilāsipāra',
		'Barpeta',
		'Abhayāpuri',
		'Barpeta Road',
		'Morigaon',
		'Silapathar',
		'Lumding Railway Colony',
	],
	Astana: ['Astana'],
	Astara: ['Astara'],
	'Astrakhan Oblast': ['Kharabali', 'Kamyzyak', 'Astrakhan', 'Akhtubinsk', 'Znamensk'],
	Asturias: ['Sama', 'Oviedo', 'Mieres', 'Gijón', 'Avilés', 'Corvera de Asturias', 'Natahoyo'],
	Asunción: ['Asunción'],
	Aswan: ['Kawm Umbū', 'Idfū', 'Aswan'],
	Asyut: ['Manfalūţ', 'Dayrūţ', 'Asyūţ', 'Al Qūşīyah', 'Al Badārī', 'Abū Tīj', 'Abnūb'],
	Atacama: ['Vallenar', 'Diego de Almagro', 'Copiapó'],
	Atakora: ['Tanguiéta', 'Natitingou'],
	Atlantique: ['Ouidah', 'Allada', 'Abomey-Calavi'],
	Atlántico: [
		'Soledad',
		'Santo Tomás',
		'Santa Lucía',
		'Sabanalarga',
		'Sabanagrande',
		'Repelón',
		'Puerto Colombia',
		'Ponedera',
		'Polonuevo',
		'Palmar de Varela',
		'Manatí',
		'Malambo',
		'Luruaco',
		'Juan de Acosta',
		'Galapa',
		'Candelaria',
		'Campo de la Cruz',
		'Barranquilla',
		'Baranoa',
	],
	'Atlántida Department': ['Tela', 'La Ceiba'],
	'Atsimo-Andrefana': ['Toliara', 'Sakaraha', 'Betioky', 'Beroroha', 'Ankazoabo', 'Ampanihy'],
	'Atsimo-Atsinanana': ['Vondrozo', 'Vohipaho', 'Vangaindrano', 'Farafangana'],
	Atsinanana: ['Toamasina', 'Vohibinany', 'Marolambo', 'Mahanoro', 'Ampasimanolotra'],
	Attica: [
		'Voúla',
		'Výronas',
		'Vári',
		'Salamína',
		'Piraeus',
		'Petroúpolis',
		'Peristéri',
		'Pérama',
		'Palaió Fáliro',
		'Níkaia',
		'Néa Smýrni',
		'Néa Mákri',
		'Ílion',
		'Néa Ionía',
		'Néa Filadélfeia',
		'Néa Erythraía',
		'Moskháton',
		'Melíssia',
		'Mégara',
		'Artémida',
		'Metamórfosi',
		'Koropí',
		'Kifisiá',
		'Cholargós',
		'Khalándrion',
		'Chaïdári',
		'Keratsíni',
		'Kamaterón',
		'Kallithéa',
		'Álimos',
		'Kaisarianí',
		'Irákleio',
		'Glyfáda',
		'Galátsi',
		'Ellinikó',
		'Elefsína',
		'Dhafní',
		'Agios Dimitrios',
		'Agía Varvára',
		'Agía Paraskeví',
		'Athens',
		'Asprópyrgos',
		'Argyroúpoli',
		'Áno Liósia',
		'Maroúsi',
		'Acharnés',
		'Aigáleo',
		'Vrilissia',
		'Gérakas',
		'Ilioúpoli',
		'Korydallós',
		'Zográfos',
		'Péfki',
		'Ágioi Anárgyroi',
		'Agios Ioannis Rentis',
	],
	Atyraū: ['Shalkar', 'Qulsary', 'Balykshi', 'Atyrau'],
	Auckland: [
		'Pukekohe East',
		'North Shore',
		'Manukau City',
		'Mangere',
		'Avondale',
		'Auckland',
		'Papakura',
		'Takanini',
		'Mangere East',
		'Papatoetoe',
		'Otahuhu',
		'Manurewa',
		'Otara',
		'Blockhouse Bay',
		'New Lynn',
		'Epsom',
		'Remuera',
		'Massey East',
		'Te Atatu South',
		'Massey',
	],
	'Australian Capital Territory': ['Canberra'],
	'Autonomous Region in Muslim Mindanao': [
		'Wao',
		'Sitangkai',
		'Parang',
		'Pagaluñgan',
		'Marawi City',
		'Maluso',
		'Maganoy',
		'Jolo',
		'City of Isabela',
		'Cotabato',
		'Buluan',
		'Budta',
		'Bongao',
	],
	'Auvergne-Rhône-Alpes': [
		'Voiron',
		'Villeurbanne',
		'Villefranche-sur-Saône',
		'Villefontaine',
		'Vienne',
		'Vichy',
		'Vénissieux',
		'Vaulx-en-Velin',
		'Valence',
		'Thonon-les-Bains',
		'Tassin-la-Demi-Lune',
		'Seynod',
		'Sallanches',
		'Saint-Priest',
		"Saint-Martin-d'Hères",
		'Saint-Genis-Laval',
		'Saint-Fons',
		'Saint-Étienne',
		'Sainte-Foy-lès-Lyon',
		'Saint-Égrève',
		'Saint-Chamond',
		'Romans-sur-Isère',
		'Roanne',
		'Riom',
		'Rillieux-la-Pape',
		'Oyonnax',
		'Oullins',
		'Moulins',
		'Montluçon',
		'Montélimar',
		'Montbrison',
		'Meyzieu',
		'Meylan',
		'Lyon',
		'Le Puy-en-Velay',
		'Issoire',
		'Grenoble',
		'Givors',
		'Fontaine',
		'Firminy',
		'Écully',
		'Échirolles',
		'Décines-Charpieu',
		'Cran-Gevrier',
		"Cournon-d'Auvergne",
		'Cluses',
		'Clermont-Ferrand',
		'Chambéry',
		'Chamalières',
		'Caluire-et-Cuire',
		'Bron',
		'Bourg-lès-Valence',
		'Bourg-en-Bresse',
		'Aurillac',
		'Annonay',
		'Annemasse',
		'Annecy-le-Vieux',
		'Annecy',
		'Albertville',
		'Aix-les-Bains',
		'Lyon 02',
		'Lyon 03',
		'Lyon 04',
		'Lyon 05',
		'Lyon 06',
		'Lyon 07',
		'Lyon 08',
		'Lyon 09',
		'Bourgoin-Jallieu',
		'Lyon 01',
	],
	Aveiro: ['São João da Madeira', 'Ovar', 'Ílhavo', 'Feira', 'Espinho', 'Aveiro'],
	Awdal: ['Baki'],
	Ayacucho: ['Huanta', 'Ayacucho'],
	Aydın: ['Didim', 'Söke', 'Nazilli', 'Kuşadası', 'İncirliova', 'Çine', 'Aydın'],
	Ayeyarwady: ['Nyaungdon', 'Wakema', 'Pyapon', 'Myanaung', 'Mawlamyinegyunn', 'Maubin', 'Kyaiklat', 'Hinthada', 'Bogale', 'Pathein'],
	Aysén: ['Puerto Aysén', 'Coyhaique'],
	'Az Zāwiyah': ['Şurmān', 'Şabrātah', 'Zawiya', 'Az Zāwīyah'],
	'Azad Kashmir': ['Rawalakot', 'New Mirpur', 'Muzaffarābād', 'Kotli', 'Bhimber'],
	Azores: ['Ponta Delgada'],
	Azua: ['Azua'],
	Azuay: ['Gualaceo', 'Cuenca'],
	'Aïn Defla': ['Khemis Miliana', 'El Attaf', 'El Abadia', 'Aïn Defla'],
	'Aïn Témouchent': ['Hammam Bou Hadjar', 'El Malah', 'El Amria', 'Beni Saf', 'Aïn Temouchent'],
	Ağcabǝdi: ['Agdzhabedy'],
	Ağdam: ['Ağdam'],
	Ağdaş: ['Ağdaş'],
	Ağrı: ['Patnos', 'Ağrı', 'Eleşkirt', 'Doğubayazıt', 'Diyadin'],
	Ağsu: ['Aghsu'],
	'Baalbek-Hermel': ['Baalbek'],
	Bacău: ['Moineşti', 'Comăneşti', 'Buhuşi', 'Bacău'],
	Badakhshan: ['Fayzabad'],
	'Baden-Wurttemberg': [
		'Winnenden',
		'Wiesloch',
		'Wertheim',
		'Wendlingen am Neckar',
		'Weinstadt-Endersbach',
		'Weinheim',
		'Weingarten',
		'Weil der Stadt',
		'Weil am Rhein',
		'Wangen',
		'Waldshut-Tiengen',
		'Waldkirch',
		'Waiblingen',
		'Waghäusel',
		'Villingen-Schwenningen',
		'Vaihingen an der Enz',
		'Unterkrozingen',
		'Ulm',
		'Überlingen',
		'Tuttlingen',
		'Tübingen',
		'Trossingen',
		'Tettnang',
		'Stuttgart',
		'Stockach',
		'Sinsheim',
		'Singen',
		'Sindelfingen',
		'Sigmaringen',
		'Schwetzingen',
		'Schwäbisch Hall',
		'Schwäbisch Gmünd',
		'Schramberg',
		'Schorndorf',
		'Schopfheim',
		'Bad Saulgau',
		'Rottweil',
		'Rottenburg',
		'Rheinfelden',
		'Reutlingen',
		'Renningen',
		'Ravensburg',
		'Rastatt',
		'Radolfzell',
		'Pfullingen',
		'Pforzheim',
		'Öhringen',
		'Offenburg',
		'Oberkirch',
		'Nürtingen',
		'Neckarsulm',
		'Nagold',
		'Müllheim',
		'Stuttgart Mühlhausen',
		'Mühlacker',
		'Mössingen',
		'Mosbach',
		'Metzingen',
		'Marbach am Neckar',
		'Mannheim',
		'Ludwigsburg',
		'Lörrach',
		'Leutkirch',
		'Leonberg',
		'Leinfelden-Echterdingen',
		'Leimen',
		'Laupheim',
		'Lahr',
		'Künzelsau',
		'Kornwestheim',
		'Korntal',
		'Konstanz',
		'Klingenstein',
		'Kirchheim unter Teck',
		'Kehl',
		'Karlsruhe',
		'Horb am Neckar',
		'Hockenheim',
		'Herrenberg',
		'Heilbronn',
		'Heidenheim an der Brenz',
		'Heidelberg',
		'Hechingen',
		'Göppingen',
		'Giengen an der Brenz',
		'Gerlingen',
		'Geislingen an der Steige',
		'Gaggenau',
		'Friedrichshafen',
		'Freudenstadt',
		'Freiburg',
		'Freiberg am Neckar',
		'Stuttgart Feuerbach',
		'Fellbach',
		'Ettlingen',
		'Esslingen',
		'Eppingen',
		'Emmendingen',
		'Ellwangen',
		'Eislingen',
		'Ehingen',
		'Eggenstein-Leopoldshafen',
		'Ebersbach an der Fils',
		'Eberbach',
		'Donaueschingen',
		'Ditzingen',
		'Crailsheim',
		'Calw',
		'Bühl',
		'Buchen in Odenwald',
		'Bruchsal',
		'Bretten',
		'Brackenheim',
		'Böblingen',
		'Bietigheim-Bissingen',
		'Biberach an der Riß',
		'Balingen',
		'Baiersbronn',
		'Bad Waldsee',
		'Bad Säckingen',
		'Bad Rappenau',
		'Bad Mergentheim',
		'Baden-Baden',
		'Backnang',
		'Albstadt',
		'Achern',
		'Aalen',
		'Lauda-Königshofen',
		'Filderstadt',
		'Ostfildern',
		'Rheinstetten',
		'Stuttgart-Ost',
		'Sachsenheim',
		'Petershausen-West',
		'Remseck am Neckar',
	],
	Badghis: ['Ghormach'],
	Bafatá: ['Bafatá'],
	Baghdad: ['Baghdad', 'Abū Ghurayb'],
	Baghlan: ['Pul-e Khumrī', 'Nahrīn', 'Baghlān'],
	'Bagmati Province': ['Pātan', 'Panauti̇̄', 'Kirtipur', 'Kathmandu', 'Hetauda', 'Dhulikhel', 'Bharatpur', 'Banepā', 'Panauti', 'Madhyapur Thimi'],
	Bago: ['Taungoo', 'Tharyarwady', 'Thanatpin', 'Pyu', 'Pyay', 'Bago', 'Paungde', 'Nyaunglebin', 'Letpandan'],
	Bahia: [
		'Paulo Afonso',
		'Xique Xique',
		'Vitória da Conquista',
		'Vera Cruz',
		'Valença',
		'Uruçuca',
		'Una',
		'Ubatã',
		'Ubaitaba',
		'Tucano',
		'Tapiramutá',
		'Sobradinho',
		'Serrinha',
		'Senhor do Bonfim',
		'Seabra',
		'Saubara',
		'São Sebastião do Passé',
		'São Francisco do Conde',
		'Santo Estêvão',
		'Santo Antônio de Jesus',
		'Santo Amaro',
		'Santa Maria da Vitória',
		'Santaluz',
		'Santa Cruz Cabrália',
		'Salvador',
		'Ruy Barbosa',
		'Rio Real',
		'Ribeira do Pombal',
		'Riachão do Jacuípe',
		'Prado',
		'Porto Seguro',
		'Poções',
		'Piritiba',
		'Pindobaçu',
		'Posto da Mata',
		'Nova Viçosa',
		'Nazaré',
		'Muritiba',
		'Mucuri',
		'Morro do Chapéu',
		'Medeiros Neto',
		'Mata de São João',
		'Mascote',
		'Maragogipe',
		'Maracás',
		'Livramento do Brumado',
		'Jeremoabo',
		'Jequié',
		'Jaguarari',
		'Jaguaquara',
		'Jacobina',
		'Ituberá',
		'Itororó',
		'Itapetinga',
		'Itaparica',
		'Itambé',
		'Itamaraju',
		'Itajuípe',
		'Itabuna',
		'Itaberaba',
		'Irecê',
		'Ipirá',
		'Ipiaú',
		'Ilhéus',
		'Ibotirama',
		'Ibirataia',
		'Ibicaraí',
		'Iaçu',
		'Guanambi',
		'Gandu',
		'Feira de Santana',
		'Eunápolis',
		'Euclides da Cunha',
		'Esplanada',
		'Entre Rios',
		'Cruz das Almas',
		'Conde',
		'Conceição do Coité',
		'Conceição do Jacuípe',
		'Conceição da Feira',
		'Coaraci',
		'Cícero Dantas',
		'Catu',
		'Capim Grosso',
		'Canavieiras',
		'Campo Formoso',
		'Camaçari',
		'Caetité',
		'Cachoeira',
		'Buerarema',
		'Brumado',
		'Bom Jesus da Lapa',
		'Barreiras',
		'Barra',
		'Araci',
		'Amargosa',
		'Alagoinhas',
		'Lauro de Freitas',
		'Simões Filho',
	],
	Baikonur: ['Baikonur'],
	'Baja California': [
		'Lázaro Cárdenas',
		'Santa Isabel',
		'Guadalupe Victoria',
		'Tijuana',
		'Tecate',
		'San Felipe',
		'Rosarito',
		'Rancho La Gloria',
		'Mexicali',
		'Rodolfo Sánchez Taboada',
		'Ensenada',
		'Puebla',
		'Las Delicias',
		'Pórticos de San Antonio',
		'Terrazas del Valle',
		'Villa del Prado 2da Sección',
	],
	'Baja California Sur': ['Cabo San Lucas', 'San José del Cabo', 'La Paz', 'Ciudad Constitución', 'Colonia del Sol'],
	'Baja Verapaz': ['Salamá', 'Rabinal'],
	Baki: [
		'Zabrat',
		'Qaraçuxur',
		'Sabunçu',
		'Maştağa',
		'Mardakan',
		'Lokbatan',
		'Yeni Suraxanı',
		'Hövsan',
		'Buzovna',
		'Biny Selo',
		'Bilajari',
		'Baku',
		'Amirdzhan',
		'Bakıxanov',
	],
	'Baladīyat Umm Şalāl': ['Umm Şalāl Muḩammad'],
	'Baladīyat ad Dawḩah': ['Doha'],
	'Baladīyat ar Rayyān': ['Ar Rayyān'],
	'Balearic Islands': [
		'Santa Eulària des Riu',
		'Sant Antoni de Portmany',
		'Pollença',
		'Palma',
		'Marratxí',
		'Manacor',
		'Maó',
		'Llucmajor',
		'Inca',
		'Ibiza',
		'Felanitx',
		'Calvià',
		'Alcúdia',
		'Ciutadella',
	],
	Bali: ['Ubud', 'Tabanan', 'Singaraja', 'Negara', 'Kuta', 'Klungkung', 'Amlapura', 'Denpasar', 'Banjar', 'Seririt', 'Bedugul', 'Amlapura city'],
	Balkan: ['Balkanabat', 'Gumdag', 'Bereket', 'Türkmenbaşy'],
	Balkh: ['Mazār-e Sharīf', 'Khulm', 'Balkh'],
	Balochistan: [
		'Zhob',
		'Uthal',
		'Usta Muhammad',
		'Turbat',
		'Sibi',
		'Quetta',
		'Pishin',
		'Pasni',
		'Nushki',
		'Mehrabpur',
		'Mastung',
		'Mach',
		'Loralai',
		'Kot Malik Barkhurdar',
		'Khuzdar',
		'Kharan',
		'Kalat',
		'Jiwani',
		'Gwadar',
		'Dera Bugti',
		'Dadhar',
		'Chaman',
		'Bela',
	],
	Balqa: ['As Salţ'],
	Balıkesir: ['Susurluk', 'Edremit', 'Dursunbey', 'Burhaniye', 'Bigadiç', 'Balıkesir', 'Ayvalık', 'Gönen', 'Erdek', 'Bandırma'],
	Bamako: ['Bamako'],
	Bamyan: ['Bāzār-e Yakāwlang', 'Bāmyān'],
	Banaadir: ['Mogadishu'],
	Banghāzī: ['Qaryat Sulūq', 'Benghazi'],
	'Bangka–Belitung Islands': ['Tanjung Pandan', 'Sungailiat', 'Pangkalpinang', 'Muntok', 'Manggar'],
	Bangkok: ['Phasi Charoen', 'Makkasan', 'Bangkok'],
	Bangui: ['Bangui'],
	Banjul: ['Serekunda', 'Banjul', 'Bakau'],
	'Banskobystrický kraj': ['Rimavská Sobota', 'Zvolen', 'Žiar nad Hronom', 'Lučenec', 'Brezno', 'Banská Bystrica'],
	'Banteay Meanchey': ['Sisophon', 'Paoy Paet'],
	Banten: ['Tangerang', 'Serang', 'Rangkasbitung', 'Pandeglang', 'Labuan', 'Curug', 'South Tangerang'],
	Baoruco: ['Neiba'],
	Bar: ['Bar'],
	Barahona: ['Santa Cruz de Barahona'],
	Baranya: ['Pécs', 'Mohács', 'Komló'],
	Barceloneta: ['Barceloneta'],
	Barda: ['Barda'],
	'Barh el Gazel': ['Moussoro'],
	Bari: ['Qandala', 'Bosaso'],
	Barinas: ['Alto Barinas', 'Socopó', 'Santa Bárbara', 'Sabaneta', 'Obispos', 'Ciudad Bolivia', 'Barrancas', 'Barinitas', 'Barinas'],
	Baringo: ['Kabarnet'],
	Barisāl: ['Pirojpur', 'Nālchiti', 'Mehendiganj', 'Mathba', 'Gaurnadi', 'Lālmohan', 'Burhānuddin', 'Bhola', 'Barishal', 'Bhāndāria'],
	Bartın: ['Bartın'],
	'Bas-Congo': ['Tshela', 'Moanda', 'Mbanza-Ngungu', 'Matadi', 'Kasangulu', 'Boma'],
	'Bas-Sassandra': ['Tabou', 'Sassandra', 'San-Pédro'],
	'Bas-Uele': ['Buta', 'Bondo', 'Aketi'],
	'Basel-City': ['Basel', 'Riehen'],
	'Basel-Landschaft': ['Muttenz', 'Allschwil'],
	'Bashkortostan Republic': [
		'Yanaul',
		'Ufa',
		'Uchaly',
		'Tuymazy',
		'Agidel’',
		'Sterlitamak',
		'Sibay',
		'Salavat',
		'Rayevskiy',
		'Priyutovo',
		'Oktyabrsky',
		'Neftekamsk',
		'Meleuz',
		'Kumertau',
		'Ishimbay',
		'Dyurtyuli',
		'Davlekanovo',
		'Chishmy',
		'Blagoveshchensk',
		'Birsk',
		'Beloretsk',
		'Belebey',
		'Baymak',
		"Mezgor'e",
	],
	Basilicate: ['Potenza', 'Matera'],
	'Basque Country': [
		'Zarautz',
		'Gasteiz / Vitoria',
		'Tolosa',
		'Sestao',
		'Barakaldo',
		'Santurtzi',
		'Donostia / San Sebastián',
		'Errenteria',
		'Portugalete',
		'Oria',
		'Mungia',
		'Arrasate / Mondragón',
		'Laudio / Llodio',
		'Leioa',
		'Lasarte',
		'Irun',
		'Hernani',
		'Gernika-Lumo',
		'Getxo',
		'Galdakao',
		'Hondarribia',
		'Ermua',
		'Erandio',
		'Eibar',
		'Durango',
		'Bilbao',
		'Bermeo',
		'Amorebieta',
		'Algorta',
		'Santutxu',
		'Pasaia',
		'Basauri',
	],
	Basra: ['Umm Qaşr', 'Az Zubayr', 'Al Hārithah', 'Al Fāw', 'Basrah', 'Al Başrah al Qadīmah'],
	'Basse-Kotto': ['Mobaye'],
	Batha: ['Oum Hadjer', 'Ati'],
	Batken: ['Suluktu', 'Razzakov', 'Kyzyl-Kyya', 'Iradan', 'Batken'],
	Batman: ['Kozluk', 'Batman'],
	Batna: ['Tazoult-Lambese', 'Râs el Aïoun', 'Merouana', 'Batna', 'Barika', 'Arris', 'Aïn Touta'],
	Battambang: ['Battambang'],
	'Batys Qazaqstan': ['Oral', 'Aqsay'],
	Baucau: ['Baukau', 'Venilale'],
	Bauchi: ['Kari', 'Darazo', 'Bauchi', 'Azare'],
	Bavaria: [
		'Zirndorf',
		'Würzburg',
		'Wolfratshausen',
		'Wendelstein',
		'Weißenburg in Bayern',
		'Weilheim',
		'Weiden',
		'Waldkraiburg',
		'Vilshofen',
		'Vaterstetten',
		'Unterschleißheim',
		'Unterhaching',
		'Traunstein',
		'Traunreut',
		'Taufkirchen',
		'Sulzbach-Rosenberg',
		'Straubing',
		'Starnberg',
		'Sonthofen',
		'Senden',
		'Selb',
		'Schweinfurt',
		'Schwandorf in Bayern',
		'Schwabach',
		'Schrobenhausen',
		'Roth',
		'Rosenheim',
		'Regensburg',
		'Puchheim',
		'Pfaffenhofen an der Ilm',
		'Penzberg',
		'Passau',
		'Pasing',
		'Ottobrunn bei München',
		'Olching',
		'Oberasbach',
		'Nürnberg',
		'Nördlingen',
		'Neu-Ulm',
		'Neustadt bei Coburg',
		'Neumarkt in der Oberpfalz',
		'Neufahrn bei Freising',
		'Neuburg an der Donau',
		'Munich',
		'Mühldorf',
		'Moosburg',
		'Memmingen',
		'Marktredwitz',
		'Marktoberdorf',
		'Lohr am Main',
		'Lindau',
		'Lichtenfels',
		'Lauf an der Pegnitz',
		'Landshut',
		'Landsberg am Lech',
		'Kulmbach',
		'Kronach',
		'Königsbrunn',
		'Kolbermoor',
		'Kitzingen',
		'Kempten (Allgäu)',
		'Kelheim',
		'Kaufbeuren',
		'Karlstadt',
		'Karlsfeld',
		'Ingolstadt',
		'Illertissen',
		'Holzkirchen',
		'Hof',
		'Herzogenaurach',
		'Haar',
		'Gunzenhausen',
		'Günzburg',
		'Großostheim',
		'Gröbenzell',
		'Gilching',
		'Gersthofen',
		'Germering',
		'Geretsried',
		'Gauting',
		'Garmisch-Partenkirchen',
		'Garching',
		'Füssen',
		'Fürth',
		'Fürstenfeldbruck',
		'Friedberg',
		'Freising',
		'Freilassing',
		'Forchheim',
		'Erlangen',
		'Erding',
		'Donauwörth',
		'Dingolfing',
		'Dillingen an der Donau',
		'Deggendorf',
		'Dachau',
		'Coburg',
		'Cham',
		'Burghausen',
		'Bruckmühl',
		'Bogenhausen',
		'Bobingen',
		'Bayreuth',
		'Bamberg',
		'Bad Tölz',
		'Bad Reichenhall',
		'Bad Neustadt an der Saale',
		'Bad Kissingen',
		'Bad Aibling',
		'Augsburg',
		'Aschaffenburg',
		'Ansbach',
		'Amberg',
		'Alzenau in Unterfranken',
		'Altdorf bei Nurnberg',
		'Aichach',
		'Burg Unter-Falkenstein',
	],
	Bay: ['Diinsoor', 'Buurhakaba', 'Baidoa'],
	'Bay of Plenty': ['Tauranga', 'Whakatane', 'Rotorua'],
	Bayamón: ['Bayamón'],
	'Bayan-Ölgiy': ['Ölgii'],
	Bayanhongor: ['Bayanhongor'],
	'Bayburt Province': ['Bayburt'],
	Bayelsa: ['Yenagoa'],
	Beheira: ['Rosetta', 'Kawm Ḩamādah', 'Kafr ad Dawwār', 'Idkū', 'Ḩawsh ‘Īsá', 'Damanhūr', 'Ad Dilinjāt', 'Abū al Maţāmīr'],
	Beijing: ['Tongzhou', 'Mentougou', 'Liangxiang', 'Daxing', 'Fangshan', 'Beijing', 'Shunyi', 'Changping'],
	Beja: ['Beja'],
	'Bekes County': ['Szarvas', 'Orosháza', 'Gyula', 'Gyomaendrőd', 'Békéscsaba', 'Békés'],
	Belait: ['Seria', 'Kuala Belait'],
	'Belgorod Oblast': ['Valuyki', 'Stroitel', 'Staryy Oskol', 'Shebekino', 'Razumnoye', 'Novy Oskol', 'Gubkin', 'Chernyanka', 'Belgorod', 'Alexeyevka'],
	Belize: ['San Pedro', 'Belize City'],
	'Ben Arous Governorate': ['La Sebala du Mornag', 'Radès', 'Hammam-Lif', 'Ben Arous'],
	'Bender Municipality': ['Bender'],
	Bengkulu: ['Curup', 'Bengkulu'],
	Bengo: ['Caxito'],
	Benguela: ['Lobito', 'Catumbela', 'Benguela'],
	'Beni Department': ['Trinidad', 'Riberalta', 'Guayaramerín', 'San Borja'],
	'Beni Suweif': ['Sumusţā as Sulţānī', 'Būsh', 'Banī Suwayf', 'Al Fashn'],
	Benue: ['Takum', 'Otukpa', 'Makurdi', 'Katsina-Ala', 'Igbor'],
	'Berat County': ['Berat'],
	Berlin: [
		'Zehlendorf',
		'Wittenau',
		'Wilmersdorf',
		'Wilhelmstadt',
		'Westend',
		'Weißensee',
		'Wedding',
		'Tempelhof',
		'Tegel',
		'Steglitz',
		'Staaken',
		'Schöneberg',
		'Schmargendorf',
		'Rummelsburg',
		'Rudow',
		'Reinickendorf',
		'Prenzlauer Berg',
		'Pankow',
		'Oberschöneweide',
		'Niederschönhausen',
		'Neukölln',
		'Moabit',
		'Marzahn',
		'Märkisches Viertel',
		'Marienfelde',
		'Mariendorf',
		'Mahlsdorf',
		'Lichterfelde',
		'Lichtenrade',
		'Lichtenberg',
		'Lankwitz',
		'Kreuzberg',
		'Köpenick',
		'Berlin Köpenick',
		'Kladow',
		'Kaulsdorf',
		'Karow',
		'Karlshorst',
		'Johannisthal',
		'Hermsdorf',
		'Hellersdorf',
		'Heiligensee',
		'Haselhorst',
		'Hakenfelde',
		'Gesundbrunnen',
		'Frohnau',
		'Friedrichshain',
		'Friedrichshagen',
		'Friedrichsfelde',
		'Friedenau',
		'Dahlem',
		'Charlottenburg',
		'Buckow',
		'Französisch Buchholz',
		'Britz',
		'Biesdorf',
		'Berlin',
		'Baumschulenweg',
		'Altglienicke',
		'Adlershof',
		'Gropiusstadt',
		'Charlottenburg-Nord',
		'Mitte',
		'Spandau',
		'Berlin Treptow',
		'Falkenhagener Feld',
		'Neu-Hohenschönhausen',
		'Alt-Hohenschönhausen',
		'Fennpfuhl',
	],
	Bern: ['Thun', 'Steffisburg', 'Köniz', 'Burgdorf', 'Biel/Bienne', 'Bern'],
	Betsiboka: ['Tsaratanana', 'Maevatanana'],
	Beyləqan: ['Beylagan'],
	Beyrouth: ['Ra’s Bayrūt', 'Beirut'],
	Bicol: [
		'Virac',
		'Tiwi',
		'Tagas',
		'Tabaco',
		'Sorsogon',
		'San Jose',
		'Polangui',
		'Naga',
		'Nabua',
		'Mercedes',
		'Masbate',
		'Malilipot',
		'Magarao',
		'Libon',
		'Legaspi',
		'Labo',
		'Jose Pañganiban',
		'Irosin',
		'Iriga City',
		'Goa',
		'Daet',
		'Calabanga',
		'Bulan',
		'Buhi',
		'Bato',
		'Baao',
	],
	Bihar: [
		'Wāris Alīganj',
		'Thākurganj',
		'Tekāri',
		'Teghra',
		'Supaul',
		'Siwān',
		'Sītāmarhi',
		'Silao',
		'Sherghāti',
		'Sheohar',
		'Sheikhpura',
		'Shāhpur',
		'Samāstipur',
		'Saharsa',
		'Sagauli',
		'Rusera',
		'Revelganj',
		'Raxaul',
		'Rāmnagar',
		'Rājgīr',
		'Rafiganj',
		'Kasba',
		'Purnia',
		'Pupri',
		'Piro',
		'Patna',
		'Nirmāli',
		'Nawāda',
		'Naugachhia',
		'Nāsriganj',
		'Nabīnagar',
		'Muzaffarpur',
		'Murlīganj',
		'Monghyr',
		'Mothīhāri',
		'Mokameh',
		'Masaurhi Buzurg',
		'Marhaura',
		'Manihāri',
		'Maner',
		'Mairwa',
		'Mahārājgani',
		'Madhubani',
		'Madhipura',
		'Luckeesarai',
		'Lālganj',
		'Koelwār',
		'Koāth',
		'Kishanganj',
		'Kharagpur',
		'Khagaul',
		'Khagaria',
		'Katihar',
		'Jogbani',
		'Jhanjhārpur',
		'Jhā-Jhā',
		'Jainagar',
		'Jamūī',
		'Jamālpur',
		'Jahānābād',
		'Jagdīspur',
		'Islāmpur',
		'Hisuā',
		'Hilsa',
		'Hājīpur',
		'Gopālganj',
		'Gaya',
		'Forbesganj',
		'Fatwa',
		'Dumraon',
		'Dumra',
		'Dighwāra',
		'Dhāka',
		'Dehri',
		'Daudnagar',
		'Darbhanga',
		'Dinapore',
		'Dalsingh Sarai',
		'Colgong',
		'Chhātāpur',
		'Chāpra',
		'Chākia',
		'Buxar',
		'Buddh Gaya',
		'Bīrpur',
		'Bikramganj',
		'Bihār Sharīf',
		'Bihārīganj',
		'Bhāgalpur',
		'Bhabhua',
		'Bettiah',
		'Belsand',
		'Begusarai',
		'Bāruni',
		'Barhiya',
		'Bārh',
		'Bar Bigha',
		'Barauli',
		'Banmankhi',
		'Bānka',
		'Bangaon',
		'Bakhtiyārpur',
		'Bairāgnia',
		'Bahādurganj',
		'Bagaha',
		'Bagaha',
		'Aurangābād',
		'Arāria',
		'Arrah',
		'Amarpur',
		'Bhawanipur',
		'Shahbazpur',
	],
	Bihor: ['Salonta', 'Oradea'],
	'Bijelo Polje': ['Bijelo Polje'],
	Bilecik: ['Bozüyük', 'Osmaneli', 'Bilecik'],
	Bilǝsuvar: ['Pushkino'],
	Bingöl: ['Solhan', 'Genç', 'Bingöl'],
	Biobío: [
		'Tomé',
		'Talcahuano',
		'Penco',
		'Nacimiento',
		'Mulchén',
		'Lota',
		'Los Ángeles',
		'Lebu',
		'Laja',
		'Curanilahue',
		'Coronel',
		'Concepción',
		'Chiguayante',
		'Cañete',
		'Cabrero',
		'Arauco',
	],
	'Bioko Norte': ['Malabo'],
	Birkirkara: ['Birkirkara'],
	Bishkek: ['Bishkek'],
	Biskra: ['Zeribet el Oued', 'Tolga', 'Sidi Okba', 'Biskra'],
	Bissau: ['Bissau'],
	'Bistrița-Năsăud': ['Bistriţa'],
	Bitlis: ['Tatvan', 'Hizan', 'Güroymak', 'Bitlis', 'Ahlat', 'Adilcevaz'],
	Bitola: ['Bitola'],
	'Bizerte Governorate': ['Mateur', 'Menzel Jemil', 'Menzel Bourguiba', 'Menzel Abderhaman', 'Douar Tindja', 'Bizerte', 'El Alia'],
	'Bjelovar-Bilogora': ['Bjelovar'],
	'Black River': ['Bambous'],
	Blagoevgrad: ['Sandanski', 'Petrich', 'Gotse Delchev', 'Blagoevgrad'],
	Blekinge: ['Karlskrona', 'Karlshamn'],
	Blida: [
		'Souma',
		'Oued el Alleug',
		'Mouzaïa',
		'Meftah',
		'Larbaâ',
		'El Affroun',
		'Chiffa',
		'Chebli',
		'Bouinan',
		'Bougara',
		'Boufarik',
		'Boû Arfa',
		'Blida',
		'Beni Mered',
	],
	'Blue Nile': ['Ar Ruseris', 'Ad-Damazin'],
	'Boaco Department': ['Camoapa', 'Boaco'],
	Bobonaro: ['Maliana'],
	'Bocas del Toro Province': ['Changuinola'],
	Boeny: ['Sitampiky', 'Marovoay', 'Mahajanga', 'Ambato Boeny'],
	'Bogota D.C.': ['Bogotá'],
	Bogovinje: ['Kamenjane', 'Bogovinje'],
	Boke: ['Fria', 'Boké'],
	Bokeo: ['Ban Houakhoua'],
	Bolikhamsai: ['Pakxan'],
	Bolu: ['Gerede', 'Bolu'],
	Bolívar: [
		'Turbaco',
		'Simití',
		'Santa Rosa',
		'San Pablo',
		'San Juan Nepomuceno',
		'San Jacinto',
		'San Estanislao',
		'Pinillos',
		'Morales',
		'Mompós',
		'María la Baja',
		'Mahates',
		'Magangué',
		'El Carmen de Bolívar',
		'Cartagena',
		'Arjona',
		'Tiquisio',
		'Guaranda',
		'Upata',
		'Tumeremo',
		'Santa Elena de Uairén',
		'La Paragua',
		'Guasipati',
		'El Dorado',
		'Ciudad Piar',
		'Ciudad Guayana',
		'Ciudad Bolívar',
		'Caicara del Orinoco',
	],
	Bonaire: ['Kralendijk'],
	Bong: ['Gbarnga'],
	Bongolava: ['Tsiroanomandidy', 'Fenoarivo Be'],
	Bono: ['Wankyi', 'Sunyani', 'Japekrom', 'Berekum'],
	'Bono East': ['Techiman', 'Kintampo'],
	Boquerón: ['Filadelfia'],
	'Bordj Bou Arréridj': ['Râs el Oued', 'Mansourah', 'El Achir', 'Bordj Zemoura', 'Bordj Ghdir', 'Bordj Bou Arréridj'],
	Borgou: ['Tchaourou', 'Parakou', 'Nikki', 'Bembèrèkè'],
	Borno: ['Monguno', 'Marte', 'Maiduguri', 'Magumeri', 'Kukawa', 'Gwoza', 'Gamboru', 'Dikwa', 'Damboa', 'Biu', 'Bama'],
	'Borough of Arima': ['Arima'],
	'Borsod-Abaúj-Zemplén': ['Tiszaújváros', 'Sátoraljaújhely', 'Ózd', 'Miskolc', 'Mezőkövesd', 'Kazincbarcika'],
	Botoșani: ['Dorohoi', 'Botoşani'],
	'Boucle du Mouhoun': ['Tougan', 'Toma', 'Salanso', 'Nouna', 'Dédougou', 'Boromo'],
	Bouenza: ['Madingou', 'Kayes'],
	Bougainville: ['Arawa'],
	Bouira: ['Sour el Ghozlane', 'Lakhdaria', 'Chorfa', 'Bouïra', 'Aïn Bessem'],
	Boumerdes: ['Boumerdas', 'Thenia', 'Naciria', 'Khemis el Khechna', 'Dellys', 'Chabet el Ameur', 'Boudouaou', 'Beni Amrane', 'Arbatache'],
	'Bourgogne-Franche-Comté': [
		'Vesoul',
		'Sens',
		'Saint-Leu',
		'Pontarlier',
		'Nevers',
		'Montceau-les-Mines',
		'Montbéliard',
		'Mâcon',
		'Lons-le-Saunier',
		'Le Creusot',
		'Dole',
		'Dijon',
		'Chenôve',
		'Chalon-sur-Saône',
		'Besançon',
		'Belfort',
		'Beaune',
		'Auxerre',
		'Autun',
		'Audincourt',
	],
	Boyacá: ['Tunja', 'Sogamoso', 'Puerto Boyacá', 'Moniquirá', 'Duitama', 'Chiquinquirá'],
	Braga: ['Guimarães', 'Fafe', 'Esposende', 'Cabeceiras de Basto', 'Braga', 'Barcelos'],
	Bragança: ['Bragança'],
	Brakna: ['Bogué', 'Aleg'],
	Brandenburg: [
		'Zossen',
		'Wittstock',
		'Wittenberge',
		'Werder',
		'Wandlitz',
		'Templin',
		'Teltow',
		'Strausberg',
		'Senftenberg',
		'Schwedt (Oder)',
		'Rathenow',
		'Prenzlau',
		'Potsdam',
		'Oranienburg',
		'Neuruppin',
		'Neuenhagen',
		'Nauen',
		'Ludwigsfelde',
		'Luckenwalde',
		'Lubnjow',
		'Lauchhammer',
		'Königs Wusterhausen',
		'Kleinmachnow',
		'Hohen Neuendorf',
		'Hennigsdorf',
		'Guben',
		'Fürstenwalde',
		'Frankfurt (Oder)',
		'Forst',
		'Finsterwalde',
		'Falkensee',
		'Eisenhüttenstadt',
		'Eberswalde',
		'Cottbus',
		'Brandenburg an der Havel',
		'Bernau bei Berlin',
		'Angermünde',
	],
	'Bratislavský Kraj': ['Stupava', 'Staré Mesto', 'Pezinok', 'Malacky', 'Bratislava'],
	Brazzaville: ['Brazzaville'],
	Brașov: ['Zărnești', 'Săcele', 'Râşnov', 'Făgăraș', 'Codlea', 'Braşov'],
	Bremen: ['Vegesack', 'Bremerhaven', 'Bremen', 'Burglesum'],
	Brest: ['Pruzhany', 'Pinsk', 'Luninyets', 'Kobryn', 'Ivatsevichy', 'Byaroza', 'Brest', 'Baranovichi'],
	'British Columbia': [
		'Abbotsford',
		'Burnaby',
		'Campbell River',
		'Chilliwack',
		'Colwood',
		'Coquitlam',
		'Courtenay',
		'Cranbrook',
		'Delta',
		'Duncan',
		'Esquimalt',
		'Fort St. John',
		'Kamloops',
		'Kelowna',
		'Langford',
		'Langley',
		'Maple Ridge',
		'Mission',
		'Nanaimo',
		'New Westminster',
		'North Cowichan',
		'North Vancouver',
		'Oak Bay',
		'Penticton',
		'Pitt Meadows',
		'Port Alberni',
		'Port Coquitlam',
		'Port Moody',
		'Prince George',
		'Richmond',
		'Squamish',
		'Surrey',
		'Terrace',
		'Tsawwassen',
		'Vancouver',
		'Vernon',
		'Victoria',
		'West End',
		'White Rock',
		'Okanagan',
		'West Kelowna',
		'Ladner',
		'Walnut Grove',
		'West Vancouver',
	],
	Brittany: [
		'Vitré',
		'Vannes',
		'Saint-Malo',
		'Saint-Brieuc',
		'Rennes',
		'Quimper',
		'Pontivy',
		'Ploemeur',
		'Morlaix',
		'Lorient',
		'Lannion',
		'Lanester',
		'Landerneau',
		'Fougères',
		'Douarnenez',
		'Concarneau',
		'Cesson-Sévigné',
		'Brest',
	],
	'Brod-Posavina': ['Slavonski Brod'],
	'Brunei-Muara District': ['Bandar Seri Begawan'],
	'Brussels Capital': [
		'Woluwe-Saint-Lambert',
		'Watermael-Boitsfort',
		'Uccle',
		'Schaerbeek',
		'Saint-Josse-ten-Noode',
		'Saint-Gilles',
		'Molenbeek-Saint-Jean',
		'Koekelberg',
		'Jette',
		'Ixelles',
		'Ganshoren',
		'Forest',
		'Evere',
		'Etterbeek',
		'Brussels',
		'Berchem-Sainte-Agathe',
		'Auderghem',
		'Anderlecht',
	],
	Brvenica: ['Brvenica'],
	'Bryansk Oblast': ['Zhukovka', 'Unecha', 'Trubchevsk', 'Starodub', 'Sel’tso', 'Pochep', 'Novozybkov', 'Klintsy', 'Karachev', 'Dyat’kovo', 'Bryansk'],
	Brăila: ['Brăila'],
	Brčko: ['Brčko'],
	București: ['Bucharest', 'Sector 1', 'Sector 2', 'Sector 3', 'Sector 4', 'Sector 5', 'Sector 6'],
	Budapest: [
		'Zugló',
		'Kőbánya',
		'Kispest',
		'Erzsébetváros',
		'Budapest',
		'Angyalföld',
		'Budapest XII. kerület',
		'Budapest XI. kerület',
		'Budapest VIII. kerület',
		'Budapest VI. kerület',
		'Budapest XIII. kerület',
		'Budapest IV. kerület',
		'Budapest XV. kerület',
		'Budapest XVI. kerület',
		'Budapest XIX. kerület',
		'Budapest XVIII. kerület',
		'Budapest XXIII. kerület',
		'Budapest XXII. kerület',
		'Budapest XXI. kerület',
		'Budapest XX. kerület',
		'Budapest XVII. kerület',
		'Budapest III. kerület',
		'Budapest II. kerület',
		'Budapest I. kerület',
		'Józsefváros',
	],
	Budva: ['Budva'],
	'Bueng Kan': ['Seka'],
	'Buenos Aires': [
		'Zárate',
		'Villa Gesell',
		'Villa de Mayo',
		'Tortuguitas',
		'Tigre',
		'Tandil',
		'Santos Lugares',
		'San Pedro',
		'San Isidro',
		'Quilmes',
		'Pontevedra',
		'Pilar',
		'Necochea',
		'Muñiz',
		'Morón',
		'Merlo',
		'Mercedes',
		'Mar del Plata',
		'Luján',
		'Los Polvorines',
		'La Plata',
		'Hurlingham',
		'General Pacheco',
		'Don Torcuato',
		'Dolores',
		'Campana',
		'Berazategui',
		'Bella Vista',
		'Balcarce',
		'Azul',
		'Veinticinco de Mayo',
		'Tres Arroyos',
		'San Nicolás de los Arroyos',
		'Punta Alta',
		'Pergamino',
		'Olavarría',
		'Nueve de Julio',
		'Lincoln',
		'Junín',
		'General Villegas',
		'Coronel Suárez',
		'Chivilcoy',
		'Chacabuco',
		'Bahía Blanca',
		'San Miguel',
		'Adrogué',
		'Ingeniero Pablo Nogués',
		'José C. Paz',
		'Santa María',
	],
	'Buenos Aires F.D.': [
		'Villa Ortúzar',
		'Villa Lugano',
		'Retiro',
		'Colegiales',
		'Buenos Aires',
		'Boedo',
		'Belgrano',
		'Barracas',
		'Balvanera',
		'Villa Santa Rita',
	],
	'Bujumbura Mairie': ['Bujumbura'],
	Bukhara: ['Karakul’', 'Kogon Shahri', 'Galaosiyo Shahri', 'Bukhara', 'Vobkent Shahri', 'Shofirkon Shahri', 'G’ijduvon Shahri'],
	Bulawayo: ['Bulawayo'],
	Bulgan: ['Bulgan'],
	Bungoma: ['Webuye', 'Bungoma'],
	Burdur: ['Burdur', 'Bucak'],
	Burgas: ['Karnobat', 'Burgas', 'Aytos'],
	Buriram: ['Prakhon Chai', 'Nang Rong', 'Buri Ram'],
	'Bursa Province': ['Yenişehir', 'Orhangazi', 'Mustafakemalpaşa', 'Mudanya', 'Kestel', 'Karacabey', 'İznik', 'İnegol', 'Gürsu', 'Gemlik', 'Bursa'],
	Bururi: ['Bururi'],
	'Buryatiya Republic': ['Ulan-Ude', 'Severobaykal’sk', 'Kyakhta', 'Gusinoozyorsk'],
	Busan: ['Busan', 'Gijang'],
	Bushehr: ['Bandar-e Genāveh', 'Bushehr', 'Borāzjān'],
	Busia: ['Lugulu', 'Busia'],
	'Butha-Buthe': ['Butha-Buthe'],
	Buzău: ['Râmnicu Sărat', 'Buzău'],
	'Bà Rịa-Vũng Tàu': ['Vũng Tàu'],
	'Bács-Kiskun': ['Kiskunhalas', 'Kiskunfélegyháza', 'Kiskőrös', 'Kecskemét', 'Kalocsa', 'Baja'],
	Béchar: ['Béchar'],
	'Béja Governorate': ['Medjez el Bab', 'Béja'],
	Béjaïa: ['el hed', 'Seddouk', 'Ighram', 'Feraoun', 'El Kseur', 'Chemini', 'Bejaïa', 'Barbacha', 'Amizour', 'Akbou'],
	'Béni Mellal-Khénifra': ['Oued Zem', 'Midelt', 'Khouribga', 'Khenifra', 'Kasba Tadla', 'Al Fqih Ben Çalah', 'Beni Mellal', 'Boujniba'],
	Béqaa: ['Zahlé'],
	'Bình Dương': ['Thủ Dầu Một', 'Dĩ An', 'Thuận An'],
	'Bình Phước': ['Đồng Xoài'],
	'Bình Thuận': ['Phan Thiết', 'La Gi'],
	'Bình Định': ['Qui Nhon'],
	Bíe: ['Cuito', 'Catabola', 'Camacupa'],
	Bābil: ['Nāḩīyat Saddat al Hindīyah', 'Imam Qasim', 'Al Musayyib', 'Al Ḩillah'],
	Bălţi: ['Bălţi'],
	'Bīnshangul Gumuz': ['Āsosa'],
	'Bạc Liêu': ['Bạc Liêu'],
	'Bắc Giang': ['Bắc Giang'],
	'Bắc Kạn': ['Bắc Kạn'],
	'Bắc Ninh': ['Bắc Ninh', 'Cung Kiệm'],
	'Bến Tre': ['Bến Tre'],
	Caaguazú: ['Coronel Oviedo', 'Caaguazú'],
	Caazapá: ['Caazapá'],
	Cabañas: ['Sensuntepeque'],
	Cabinda: ['Cabinda'],
	'Cabo Delgado': ['Pemba', 'Montepuez', 'Mocímboa'],
	'Cagayan Valley': [
		'Tuguegarao',
		'Solano',
		'Solana',
		'Santiago',
		'San Mateo',
		'Roxas',
		'Ramon',
		'Ilagan',
		'Diadi',
		'Cabagan',
		'Bayombong',
		'Bambang',
		'Baggabag B',
		'Aparri',
		'Alicia',
	],
	Caguas: ['Caguas'],
	Cahul: ['Cahul'],
	Cairo: ['Madīnat an Naşr', 'Ḩalwān', 'Cairo', 'New Cairo'],
	Cajamarca: ['Jaén', 'Cajamarca'],
	Calabarzon: [
		'Victoria',
		'Tunasan',
		'Ternate',
		'Teresa',
		'Taytay',
		'Tayabas',
		'Tanay',
		'Tanauan',
		'Talisay',
		'Tagaytay',
		'Taal',
		'Silang',
		'Sariaya',
		'Santo Tomas',
		'Santa Rosa',
		'Santa Cruz',
		'San Pedro',
		'San Pascual',
		'San Pablo',
		'San Mateo',
		'Aurora',
		'Sampaloc',
		'Pulong Santa Cruz',
		'Pililla',
		'Pila',
		'Patuto',
		'Pangil',
		'Pagbilao',
		'Paete',
		'Noveleta',
		'Nasugbu',
		'Naic',
		'Nagcarlan',
		'Morong',
		'Rodriguez',
		'Mendez-Nuñez',
		'Mauban',
		'Maragondon',
		'Mamatid',
		'Malvar',
		'Malanday',
		'Malabanban Norte',
		'Lumbang',
		'Lucena',
		'Lucban',
		'Los Baños',
		'Lopez',
		'Lipa City',
		'Lilio',
		'Kawit',
		'Jalajala',
		'Indang',
		'Imus',
		'Gumaca',
		'General Trias',
		'Dasmariñas',
		'Cuenca',
		'Concepcion Ibaba',
		'Cavite City',
		'Catanauan',
		'Carmona',
		'Cardona',
		'Candelaria',
		'Calauan',
		'Calauag',
		'Calatagan',
		'Calamba',
		'Calaca',
		'Cainta',
		'Cabuyao',
		'Binangonan',
		'Biñan',
		'Bignay Uno',
		'Bay',
		'Bauan',
		'Batangas',
		'Baras',
		'Balayan',
		'Bacoor',
		'Baclaran',
		'Atimonan',
		'Antipolo',
		'Angono',
		'Amadeo',
		'Alaminos',
		'Bagong Pagasa',
	],
	Calabria: [
		'Vibo Valentia',
		'Siderno',
		'San Giovanni in Fiore',
		'Sambiase',
		'Rossano Stazione',
		'Reggio Calabria',
		'Quattromiglia',
		'Palmi',
		'Nicastro',
		'Gioia Tauro',
		'Crotone',
		'Cosenza',
		'Catanzaro',
		'Castrovillari',
		'Lamezia Terme',
		'Corigliano Scalo',
	],
	'Caldas Department': ['Villamaría', 'Supía', 'Salamina', 'Riosucio', 'Neira', 'Manzanares', 'Manizales', 'La Dorada', 'Chinchiná', 'Aguadas'],
	California: [
		'Fillmore',
		'Adelanto',
		'Agoura',
		'Agoura Hills',
		'Alameda',
		'Albany',
		'Alhambra',
		'Aliso Viejo',
		'Altadena',
		'Alum Rock',
		'American Canyon',
		'Anaheim',
		'Antelope',
		'Antioch',
		'Apple Valley',
		'Arcadia',
		'Arroyo Grande',
		'Artesia',
		'Arvin',
		'Ashland',
		'Atascadero',
		'Atwater',
		'Avocado Heights',
		'Azusa',
		'Bakersfield',
		'Baldwin Park',
		'Banning',
		'Barstow',
		'Barstow Heights',
		'Bay Point',
		'Beaumont',
		'Bell',
		'Bell Gardens',
		'Bellflower',
		'Belmont',
		'Benicia',
		'Berkeley',
		'Beverly Hills',
		'Bloomington',
		'Blythe',
		'Bostonia',
		'Boyle Heights',
		'Brawley',
		'Brea',
		'Brentwood',
		'Buena Park',
		'Burbank',
		'Burlingame',
		'Calabasas',
		'Calexico',
		'Camarillo',
		'Cameron Park',
		'Campbell',
		'Canoga Park',
		'Canyon Country',
		'Carlsbad',
		'Carmichael',
		'Carson',
		'Castaic',
		'Castro Valley',
		'Cathedral City',
		'Ceres',
		'Cerritos',
		'Chatsworth',
		'Chico',
		'Chinatown',
		'Chino',
		'Chino Hills',
		'Chowchilla',
		'Chula Vista',
		'Citrus Heights',
		'Claremont',
		'Clearlake',
		'Clovis',
		'Coachella',
		'Coalinga',
		'Colton',
		'Compton',
		'Concord',
		'Corcoran',
		'Corona',
		'Coronado',
		'Costa Mesa',
		'Covina',
		'Cudahy',
		'Culver City',
		'Cupertino',
		'Cypress',
		'Daly City',
		'Dana Point',
		'Danville',
		'Davis',
		'Delano',
		'Desert Hot Springs',
		'Diamond Bar',
		'Dinuba',
		'Dixon',
		'Downey',
		'Duarte',
		'Dublin',
		'East Rancho Dominguez',
		'East Hemet',
		'East Los Angeles',
		'East Palo Alto',
		'El Cajon',
		'El Centro',
		'El Cerrito',
		'El Dorado Hills',
		'El Monte',
		'El Segundo',
		'Elk Grove',
		'Encinitas',
		'Encino',
		'Escondido',
		'Fair Oaks',
		'Fairfield',
		'Fallbrook',
		'Florin',
		'Folsom',
		'Fontana',
		'Foothill Farms',
		'Foster City',
		'Fountain Valley',
		'Fremont',
		'Fresno',
		'Fullerton',
		'Galt',
		'Garden Grove',
		'Gardena',
		'Gilroy',
		'Glen Avon',
		'Glendale',
		'Glendora',
		'Goleta',
		'Granite Bay',
		'Greenfield',
		'Hacienda Heights',
		'Hanford',
		'Hawthorne',
		'Hayward',
		'Hemet',
		'Hercules',
		'Hermosa Beach',
		'Hesperia',
		'Highland',
		'Hollister',
		'Hollywood',
		'Huntington Beach',
		'Huntington Park',
		'Imperial',
		'Imperial Beach',
		'Indio',
		'Inglewood',
		'Irvine',
		'Isla Vista',
		'Koreatown',
		'La Cañada Flintridge',
		'La Habra',
		'La Jolla',
		'La Mesa',
		'La Mirada',
		'La Palma',
		'La Presa',
		'La Puente',
		'La Quinta',
		'La Verne',
		'Ladera Ranch',
		'Lafayette',
		'Laguna',
		'Laguna Beach',
		'Laguna Hills',
		'Laguna Niguel',
		'Laguna Woods',
		'Lake Elsinore',
		'Lake Forest',
		'Lakeside',
		'Lakewood',
		'Lamont',
		'Lancaster',
		'Lathrop',
		'Lawndale',
		'Lemon Grove',
		'Lemoore',
		'Lennox',
		'Lincoln',
		'Linda',
		'Live Oak',
		'Livermore',
		'Lodi',
		'Loma Linda',
		'Lomita',
		'Lompoc',
		'Long Beach',
		'Los Altos',
		'Los Angeles',
		'Los Banos',
		'Los Gatos',
		'Lynwood',
		'Madera',
		'Manhattan Beach',
		'Manteca',
		'Marina',
		'Martinez',
		'Maywood',
		'Mead Valley',
		'Menifee',
		'Menlo Park',
		'Merced',
		'Millbrae',
		'Milpitas',
		'Jurupa Valley',
		'Mira Mesa',
		'Mission District',
		'Mission Viejo',
		'Modesto',
		'Monrovia',
		'Montclair',
		'Montebello',
		'Monterey',
		'Monterey Park',
		'Moorpark',
		'Moraga',
		'Moreno Valley',
		'Morgan Hill',
		'Mountain View',
		'Murrieta',
		'Napa',
		'National City',
		'Newark',
		'Newport Beach',
		'Nipomo',
		'Norco',
		'North Highlands',
		'North Hollywood',
		'Northridge',
		'Norwalk',
		'Novato',
		'Oakdale',
		'Oakland',
		'Oakley',
		'Oceanside',
		'Oildale',
		'Ontario',
		'Orange',
		'Orangevale',
		'Orcutt',
		'Orinda',
		'Oroville',
		'Oxnard',
		'Pacifica',
		'Pacific Grove',
		'Palm Desert',
		'Palm Springs',
		'Palmdale',
		'Palo Alto',
		'Paradise',
		'Paramount',
		'Parlier',
		'Pasadena',
		'Paso Robles',
		'Patterson',
		'Perris',
		'Petaluma',
		'Pico Rivera',
		'Pinole',
		'Pittsburg',
		'Placentia',
		'Pleasant Hill',
		'Pleasanton',
		'Pomona',
		'Port Hueneme',
		'Porterville',
		'Poway',
		'Prunedale',
		'Ramona',
		'Rancho Cordova',
		'Rancho Cucamonga',
		'Rancho Mirage',
		'Rancho Palos Verdes',
		'Rancho Penasquitos',
		'Rancho San Diego',
		'Rancho Santa Margarita',
		'Redlands',
		'Redondo Beach',
		'Redwood City',
		'Reedley',
		'Rialto',
		'Richmond',
		'Ridgecrest',
		'Rio Linda',
		'Ripon',
		'Riverbank',
		'Riverside',
		'Rocklin',
		'Rohnert Park',
		'Rosamond',
		'Rosemead',
		'Rosemont',
		'Roseville',
		'Rowland Heights',
		'Rubidoux',
		'Sacramento',
		'Salinas',
		'San Bernardino',
		'San Bruno',
		'San Carlos',
		'San Clemente',
		'San Diego',
		'San Dimas',
		'San Fernando',
		'San Francisco',
		'San Gabriel',
		'San Jacinto',
		'San Jose',
		'San Juan Capistrano',
		'San Leandro',
		'San Lorenzo',
		'San Luis Obispo',
		'San Marcos',
		'San Mateo',
		'San Pablo',
		'San Pedro',
		'San Rafael',
		'San Ramon',
		'Sanger',
		'Santa Ana',
		'Santa Barbara',
		'Santa Clara',
		'Santa Clarita',
		'Santa Cruz',
		'Santa Fe Springs',
		'Santa Maria',
		'Santa Monica',
		'Santa Paula',
		'Santa Rosa',
		'Santee',
		'Saratoga',
		'Seal Beach',
		'Seaside',
		'Selma',
		'North Hills',
		'Shafter',
		'Sherman Oaks',
		'Simi Valley',
		'Soledad',
		'South El Monte',
		'South Gate',
		'South Lake Tahoe',
		'South Pasadena',
		'South San Francisco',
		'South San Jose Hills',
		'South Whittier',
		'South Yuba City',
		'Spring Valley',
		'Stanton',
		'Stockton',
		'Studio City',
		'Suisun',
		'Sun City',
		'Sunland',
		'Sunnyvale',
		'Temecula',
		'Temple City',
		'Thousand Oaks',
		'Torrance',
		'Tracy',
		'Truckee',
		'Tujunga',
		'Tulare',
		'Turlock',
		'Tustin',
		'North Tustin',
		'Twentynine Palms',
		'Ukiah',
		'Union City',
		'Universal City',
		'Upland',
		'Vacaville',
		'Valencia',
		'Valinda',
		'Vallejo',
		'Van Nuys',
		'Venice',
		'Ventura',
		'Victorville',
		'Vincent',
		'Visalia',
		'Vista',
		'Walnut',
		'Walnut Creek',
		'Walnut Park',
		'Watsonville',
		'West Carson',
		'West Covina',
		'West Hollywood',
		'West Puente Valley',
		'West Sacramento',
		'Westminster',
		'Westmont',
		'Whittier',
		'Wildomar',
		'Willowbrook',
		'Windsor',
		'Winter Gardens',
		'Woodland',
		'Woodland Hills',
		'Yorba Linda',
		'Yuba City',
		'Yucaipa',
		'Yucca Valley',
		'Wasco',
		'Arcata',
		'Bayside',
		'Eureka',
		'McKinleyville',
		'Redding',
		'Susanville',
		'Stevenson Ranch',
		'Vineyard',
		'Arden-Arcade',
		'Casa de Oro-Mount Helix',
		'Florence-Graham',
		'La Crescenta-Montrose',
		'Vincent',
		'West Whittier-Los Nietos',
		'West Hills',
		'Eastvale',
		'Noe Valley',
		'Visitacion Valley',
		'Silver Lake',
		'Echo Park',
		'Valley Glen',
	],
	Callao: ['Callao', 'Carmen De La Legua Reynoso'],
	Camagüey: ['Vertientes', 'Sibanicú', 'Santa Cruz del Sur', 'Nuevitas', 'Minas', 'Guáimaro', 'Florida', 'Esmeralda', 'Camagüey', 'Jimaguayú'],
	Campania: [
		'Vomero',
		'Villaricca',
		'Trentola-Ducenta',
		'Torre del Greco',
		'Torre Annunziata',
		'Terzigno',
		'Somma Vesuviana',
		'Soccavo',
		'Secondigliano',
		'Scafati',
		'Sarno',
		"Sant'Antonio Abate",
		"Sant'Antimo",
		"Sant'Anastasia",
		'Santa Maria Capua Vetere',
		'San Giuseppe Vesuviano',
		'San Giovanni a Teduccio',
		'San Giorgio a Cremano',
		'Salerno',
		'Qualiano',
		'Pozzuoli',
		'Posillipo',
		'Portici',
		'Ponticelli',
		'Pompei',
		"Pomigliano d'Arco",
		'Poggioreale',
		'Poggiomarino',
		'Piscinola',
		'Pianura',
		'Pagani',
		'Ottaviano',
		'Nola',
		'Nocera Superiore',
		'Nocera Inferiore',
		'Naples',
		'Mugnano di Napoli',
		'Mondragone',
		'Miano',
		'Melito di Napoli',
		'Marigliano',
		'Marcianise',
		'Marano di Napoli',
		'Maddaloni',
		'Ischia Porto',
		'Ischia',
		'Grumo Nevano',
		'Gragnano',
		'Giugliano in Campania',
		'Fuorigrotta',
		'Frattaminore',
		'Frattamaggiore',
		'Forio',
		'Ercolano',
		'Eboli',
		'Cercola',
		'Cava Dè Tirreni',
		'Castel Volturno',
		'Castellammare di Stabia',
		'Casoria',
		'Caserta',
		'Casalnuovo di Napoli',
		'Casal di Principe',
		'Cardito',
		'Capua',
		'Caivano',
		'Brusciano',
		'Boscoreale',
		'Benevento',
		'Battipaglia',
		'Barra',
		'Bagnoli',
		'Bacoli',
		'Aversa',
		'Avellino',
		'Arzano',
		'Angri',
		'Agropoli',
		'Afragola',
		'Acerra',
		'San Felice A Cancello',
		'San Nicola la Strada',
		'Quarto',
		'Orta di Atella',
		'Casavatore',
		'Volla',
		'Monterusciello',
		'Arpino',
		'Stella',
		"San Carlo All'Arena",
		'Chiaia',
		'Chiaiano',
		'Vicaria',
		'San Ferdinando',
		'Pendino',
		'Arenella',
		'Avvocata',
		'Montecalvario',
		'Scampia',
		'San Pietro a Patierno',
		'San Lorenzo',
	],
	Campeche: ['Escárcega', 'Ciudad del Carmen', 'Champotón', 'Campeche'],
	'Can Tho': ['Thốt Nốt', 'Thới Lai', 'Phong Điền', 'Ô Môn', 'Cờ Đỏ', 'Cần Thơ', 'Cái Răng', 'Bình Thủy', 'Vĩnh Thạnh'],
	Canakkale: ['Ezine', 'Gelibolu', 'Çanakkale', 'Çan', 'Biga'],
	'Canary Islands': [
		'Tías',
		'Telde',
		'Teguise',
		'Tacoronte',
		'Santa Lucía',
		'Santa Cruz de Tenerife',
		'Santa Cruz de la Palma',
		'Santa Brígida',
		'San Miguel De Abona',
		'San Isidro',
		'La Laguna',
		'San Bartolomé de Tirajana',
		'San Bartolomé',
		'Realejo Alto',
		'Puerto del Rosario',
		'Puerto de la Cruz',
		'Pájara',
		'Mogán',
		'Maspalomas',
		'Los Llanos de Aridane',
		'Las Palmas de Gran Canaria',
		'La Orotava',
		'La Oliva',
		'Ingenio',
		'Icod de los Vinos',
		'Güimar',
		'Guía de Isora',
		'Granadilla de Abona',
		'Gáldar',
		'Carrizal',
		'Candelaria',
		'Arucas',
		'Arrecife',
		'Arona',
		'Agüimes',
		'Adeje',
		'Playa del Ingles',
		'Puerto del Carmen',
		'Los Realejos',
	],
	Canelones: ['Santa Lucía', 'Progreso', 'Paso de Carrasco', 'Pando', 'Las Piedras', 'La Paz', 'Canelones', 'Barros Blancos'],
	Cantabria: ['Torrelavega', 'Santander', 'El Astillero', 'Castro-Urdiales', 'Camargo'],
	Canterbury: ['Timaru', 'Rolleston', 'Christchurch', 'Ashburton', 'Rangiora'],
	'Cao Bằng': ['Cao Bằng'],
	'Capital Region': [
		'Vanløse',
		'Vallensbæk',
		'Valby',
		'Taastrup',
		'Stenløse',
		'Rødovre',
		'Lillerød',
		'Copenhagen',
		'Ishøj',
		'Hvidovre',
		'Hørsholm',
		'Hillerød',
		'Helsingør',
		'Glostrup',
		'Frederikssund',
		'Frederiksberg',
		'Farum',
		'Charlottenlund',
		'Brøndbyvester',
		'Birkerød',
		'Ballerup',
		'Allerød',
		'Albertslund',
		'Reykjavík',
		'Kópavogur',
		'Hafnarfjörður',
	],
	Caquetá: ['Puerto Rico', 'Florencia', 'El Doncello'],
	Carabobo: ['Valencia', 'San Joaquín', 'Puerto Cabello', 'Morón', 'Montalbán', 'Miranda', 'Mariara', 'Güigüe', 'Guacara', 'Tacarigua', 'Bejuma'],
	Caraga: [
		'Veruela',
		'Trento',
		'Tandag',
		'Talacogon',
		'Surigao',
		'San Francisco',
		'Libertad',
		'Cabadbaran',
		'Butuan',
		'Bunawan',
		'Buenavista',
		'Bislig',
		'Bayugan',
		'Bah-Bah',
	],
	'Carazo Department': ['San Marcos', 'Jinotepe', 'Diriamba'],
	'Caraș-Severin': ['Reşiţa', 'Caransebeş', 'Bocşa'],
	Carchi: ['Tulcán'],
	Carinthia: ['Villach', 'Sankt Peter', 'Sankt Martin', 'Klagenfurt am Wörthersee'],
	Carolina: ['Carolina'],
	'Cartago Province': ['Turrialba', 'San Diego', 'Paraíso', 'Cartago'],
	'Casablanca-Settat': ['Sidi Bennour', 'Settat', 'Mohammedia', 'El Jadid', 'Casablanca', 'Bouznika', 'Berrechid', 'Azemmour'],
	'Casanare Department': ['Yopal', 'Villanueva', 'Tauramena', 'Aguazul'],
	Cascades: ['Banfora'],
	'Castelo Branco': ['Castelo Branco', 'Covilhã'],
	'Castille and León': [
		'Zamora',
		'Villaquilambre',
		'Valladolid',
		'Soria',
		'Segovia',
		'San Andrés del Rabanedo',
		'Salamanca',
		'Ponferrada',
		'Palencia',
		'Miranda de Ebro',
		'Medina del Campo',
		'León',
		'Laguna de Duero',
		'Gamonal',
		'Burgos',
		'Benavente',
		'Béjar',
		'Ávila',
		'Aranda de Duero',
	],
	'Castille-La Mancha': [
		'Villarrobledo',
		'Valdepeñas',
		'Tomelloso',
		'Toledo',
		'Talavera de la Reina',
		'Puertollano',
		'Miguelturra',
		'Manzanares',
		'La Solana',
		'La Roda',
		'Hellín',
		'Daimiel',
		'Ciudad Real',
		'Campo de Criptana',
		'Almansa',
		'Alcázar de San Juan',
		'Albacete',
		'Tarancón',
		'Seseña',
		'Illescas',
		'Guadalajara',
		'Cuenca',
		'Azuqueca de Henares',
	],
	Castries: ['Castries'],
	Catalonia: [
		'Vilanova i la Geltrú',
		'Vilafranca del Penedès',
		'Vila-seca',
		'Vilaseca',
		'Viladecans',
		'Vic',
		'El Vendrell',
		'Valls',
		'Tortosa',
		'Torredembarra',
		'Tordera',
		'Tàrrega',
		'Terrassa',
		'Tarragona',
		'Sitges',
		'Cerdanyola del Vallès',
		'Sant Vicenç dels Horts',
		'Sants',
		'Sant Just Desvern',
		'Santa Perpètua de Mogoda',
		'Barberà del Vallès',
		'Santa Coloma de Gramenet',
		'Sant Quirze del Vallès',
		'Sant Pere de Ribes',
		'Vilassar de Mar',
		'Sant Joan Despí',
		'Sant Feliu de Llobregat',
		'Sant Feliu de Guíxols',
		'Sant Cugat del Vallès',
		'Sant Celoni',
		'Sant Carles de la Ràpita',
		'Sant Boi de Llobregat',
		'Sant Andreu',
		'Sant Andreu de la Barca',
		'Sant Adrià de Besòs',
		'Salt',
		'Salou',
		'Sabadell',
		'Rubí',
		'Roses',
		'Ripollet',
		'Reus',
		'Premià de Mar',
		'Pineda de Mar',
		'Piera',
		'Parets del Vallès',
		'Palamós',
		'Palafrugell',
		'Olot',
		'Olesa de Montserrat',
		'Montornès del Vallès',
		'Montcada i Reixac',
		'Mollet del Vallès',
		'Molins de Rei',
		'Mataró',
		'El Masnou',
		'Martorell',
		'Manresa',
		'Manlleu',
		'Malgrat de Mar',
		'Lloret de Mar',
		'Lleida',
		'La Sagrera',
		'Sant Pere, Santa Caterina i La Ribera',
		'La Pineda',
		'Igualada',
		"L'Hospitalet de Llobregat",
		'Horta',
		'Granollers',
		'Gràcia',
		'Girona',
		'Gavà',
		'Figueres',
		'Esplugues de Llobregat',
		'Esparreguera',
		'El Prat de Llobregat',
		'el Raval',
		'Cornellà de Llobregat',
		'el Clot',
		'Castelldefels',
		'Castellar del Vallès',
		'Cardedeu',
		'Canovelles',
		'Cambrils',
		'Calella',
		'Caldes de Montbui',
		'Calafell',
		'Blanes',
		'Berga',
		'la Barceloneta',
		'Barcelona',
		'Banyoles',
		'Balaguer',
		'Badalona',
		'Arenys de Mar',
		'Amposta',
		'Nou Barris',
		'Eixample',
		'Les Corts',
		'Sarrià-Sant Gervasi',
		'Horta-Guinardó',
		'Sants-Montjuïc',
		'Sant Martí',
		'Ciutat Vella',
		'Barri Gòtic',
		'Poble Sec',
		'Hostafrancs',
		'La Bordeta',
		'La Bonanova',
		'Las Tres Torres',
		'el Poblenou',
		'El Carmel',
		'Fort Pienc',
		"Dreta de l'Eixample",
		'Vallcarca',
		'Sagrada Família',
		'Sant Martí de Provençals',
		'Llefià',
		'Sarrià',
		"l'Antiga Esquerra de l'Eixample",
		'el Baix Guinardó',
		'la Prosperitat',
		'el Putxet i el Farró',
		'Provenals del Poblenou',
		'Sant Antoni',
		'la Vila de Gràcia',
		"la Nova Esquerra de l'Eixample",
		'Porta',
		'Sants - Badal',
		'la Maternitat i Sant Ramon',
		'Sant Gervasi - Galvany',
		"el Camp d'en Grassot i Gràcia Nova",
		'la Marina de Port',
		'Vilapicina i la Torre Llobeta',
		"el Camp de l'Arpa del Clot",
		'Navas',
		'la Guineueta',
		'les Roquetes',
		'la Verneda i la Pau',
		'el Turó de la Peira',
		'el Besòs i el Maresme',
		'el Guinardó',
		'Barri de Sant Andreu',
		'Barri de les Corts',
	],
	Catamarca: ['Catamarca'],
	Cataño: ['Cataño'],
	Cauca: [
		'Timbiquí',
		'Suárez',
		'Santander de Quilichao',
		'Puerto Tejada',
		'Popayán',
		'Patía',
		'Belalcázar',
		'Miranda',
		'Corinto',
		'Balboa',
		'Morales',
		'Villa Rica',
		'Piendamo',
	],
	Cayey: ['Cayey'],
	Cayo: ['San Ignacio', 'Belmopan'],
	Cañar: ['La Troncal', 'Azogues'],
	Ceará: [
		'Viçosa do Ceará',
		'Várzea Alegre',
		'Varjota',
		'Trairi',
		'Tianguá',
		'São João dos Inhamuns',
		'Sobral',
		'Senador Pompeu',
		'São Gonçalo do Amarante',
		'Santa Quitéria',
		'Russas',
		'Quixeramobim',
		'Quixadá',
		'Pentecoste',
		'Pedra Branca',
		'Paraipaba',
		'Paracuru',
		'Pacatuba',
		'Pacajus',
		'Orós',
		'Nova Russas',
		'Morada Nova',
		'Mombaça',
		'Maracanaú',
		'Limoeiro do Norte',
		'Lavras da Mangabeira',
		'Juazeiro do Norte',
		'Jaguaruana',
		'Jaguaribe',
		'Itapipoca',
		'Itapagé',
		'Itaitinga',
		'Ipu',
		'Iguatu',
		'Icó',
		'Horizonte',
		'Guaraciaba do Norte',
		'Guaiúba',
		'Granja',
		'Fortaleza',
		'Eusébio',
		'Crato',
		'Crateús',
		'Caucaia',
		'Cascavel',
		'Canindé',
		'Campos Sales',
		'Camocim',
		'Brejo Santo',
		'Boa Viagem',
		'Beberibe',
		'Baturité',
		'Barbalha',
		'Aracati',
		'Aquiraz',
		'Acopiara',
		'Acaraú',
		'Cambebba',
	],
	Celje: ['Celje'],
	'Centar Zhupa': ['Centar Župa'],
	Central: [
		'Tonota',
		'Serowe',
		'Palapye',
		'Mahalapye',
		'Letlhakane',
		'Suva',
		'Nasinu',
		'Winneba',
		'Swedru',
		'Saltpond',
		'Kasoa',
		'Foso',
		'Elmina',
		'Dunkwa',
		'Cape Coast',
		'Apam',
		'Nuwara Eliya',
		'Matale',
		'Kandy',
		'Hatton',
		'Gampola',
		'Dambulla',
		'Villa Elisa',
		'San Lorenzo',
		'San Antonio',
		'Nemby',
		'Limpio',
		'Lambaré',
		'Itauguá',
		'Itá',
		'Fernando de la Mora',
		'Mariano Roque Alonso',
		'Capiatá',
		'Mumbwa',
		'Kapiri Mposhi',
		'Kabwe',
	],
	'Central Aimak': ['Dzuunmod', 'Zuunmod'],
	'Central Bohemia': [
		'Slaný',
		'Rakovník',
		'Příbram',
		'Neratovice',
		'Mladá Boleslav',
		'Mělník',
		'Kutná Hora',
		'Kralupy nad Vltavou',
		'Kolín',
		'Kladno',
		'Brandýs nad Labem-Stará Boleslav',
		'Beroun',
		'Benešov',
	],
	'Central Darfur': ['Zalingei'],
	'Central District': [
		'Modi‘in Makkabbim Re‘ut',
		'Yehud',
		'Yavné',
		'Rosh Ha‘Ayin',
		'Rishon LeTsiyyon',
		'Reẖovot',
		'Ramla',
		"Ra'anana",
		'Qalansuwa',
		'Petaẖ Tiqva',
		'Netanya',
		'Ness Ziona',
		'Lod',
		'Kfar Yona',
		'Kfar Saba',
		'Kafr Qāsim',
		'Hod HaSharon',
		'Gedera',
		'Gan Yavne',
		'Ganei Tikva',
		'Eṭ Ṭīra',
		'Eṭ Ṭaiyiba',
		'Be’er Ya‘aqov',
		'Shoham',
		'El‘ad',
		'Kadima Zoran',
	],
	'Central Equatoria': ['Yei', 'Juba'],
	'Central Finland': ['Laukaa', 'Jyväskylä', 'Jämsä'],
	'Central Greece': ['Thívai', 'Livadeiá', 'Lamía', 'Chalkída'],
	'Central Java': [
		'Wonosobo',
		'Wonopringgo',
		'Wiradesa',
		'Weleri',
		'Welahan',
		'Wedi',
		'Wangon',
		'Ungaran',
		'Trucuk',
		'Tegal',
		'Tayu',
		'Tarub',
		'Surakarta',
		'Sokaraja',
		'Sragen',
		'Slawi',
		'Sidareja',
		'Semarang',
		'Selogiri',
		'Salatiga',
		'Rembangan',
		'Randudongkal',
		'Purwokerto',
		'Purwodadi',
		'Purbalingga',
		'Candi Prambanan',
		'Pemalang',
		'Pekalongan',
		'Pecangaan',
		'Pati',
		'Muntilan',
		'Mranggen',
		'Mlonggo',
		'Mertoyudan',
		'Margasari',
		'Majenang',
		'Magelang',
		'Lebaksiu',
		'Lasem',
		'Kutoarjo',
		'Kudus',
		'Kroya',
		'Klaten',
		'Ketanggungan',
		'Kedungwuni',
		'Kebonarun',
		'Karanganom',
		'Juwana',
		'Jogonalan',
		'Jepara',
		'Jekulo',
		'Jatiroto',
		'Jaten',
		'Grogol',
		'Gombong',
		'Gebog',
		'Dukuhturi',
		'Demak',
		'Delanggu',
		'Comal',
		'Colomadu',
		'Cilacap',
		'Cepu',
		'Ceper',
		'Bulakamba',
		'Buaran',
		'Boyolali',
		'Blora',
		'Baturaden',
		'Batang',
		'Banyumas',
		'Balapulang',
		'Baki',
		'Baekrajan',
		'Ambarawa',
		'Adiwerna',
		'Ngemplak',
		'Kartasura',
		'Gatak',
	],
	'Central Jutland': ['Viborg', 'Skive', 'Silkeborg', 'Randers', 'Horsens', 'Holstebro', 'Herning', 'Hedensted', 'Århus'],
	'Central Kalimantan': ['Sampit', 'Pangkalanbuun', 'Palangkaraya', 'Muara Teweh', 'Kualakapuas'],
	'Central Luzon': [
		'Telabastagan',
		'Tarlac City',
		'Talavera',
		'Subic',
		'Sexmoan',
		'San Vicente',
		'Santol',
		'Santa Cruz',
		'Santa Cruz',
		'Santa Ana',
		'San Simon',
		'San Narciso',
		'San Miguel',
		'San Miguel',
		'San Marcelino',
		'San Luis',
		'San Leonardo',
		'San Jose del Monte',
		'San Ildefonso',
		'San Francisco',
		'San Fernando',
		'San Antonio',
		'San Antonio',
		'Samal',
		'Rizal',
		'Ramos',
		'Quezon',
		'Pulilan',
		'Porac',
		'Plaridel',
		'Pio',
		'Pilar',
		'Peñaranda',
		'Papaya',
		'Paombong',
		'Pantubig',
		'Paniqui',
		'Pandi',
		'Pandacaqui',
		'Palayan City',
		'Orion',
		'Orani',
		'Olongapo',
		'Obando',
		'Norzagaray',
		'Muñoz',
		'Morong',
		'Meycauayan',
		'Mexico',
		'Masinloc',
		'Masantol',
		'Mariveles',
		'Marilao',
		'Manibaug Pasig',
		'Malolos',
		'Malanday',
		'Magalang',
		'Mabalacat City',
		'Lubao',
		'Loma de Gato',
		'Limay',
		'Laur',
		'La Paz',
		'Jaen',
		'Iba',
		'Hermosa',
		'Hagonoy',
		'Guyong',
		'Guimba',
		'Guiguinto',
		'Gerona',
		'General Tinio',
		'General Mamerto Natividad',
		'Gapan',
		'Dinalupihan',
		'Del Pilar',
		'Concepcion',
		'Castillejos',
		'Capas',
		'Candaba',
		'Camiling',
		'Calumpit',
		'Cabiao',
		'Cabanatuan City',
		'Bustos',
		'Burgos',
		'Bulaon',
		'Bulacan',
		'Botolan',
		'Bongabon',
		'Bocaue',
		'Baliuag',
		'Balanga',
		'Balagtas',
		'Babo-Pangulo',
		'Arayat',
		'Apalit',
		'Angeles City',
		'Angat',
		'Aliaga',
		'Abucay',
	],
	'Central Macedonia': [
		'Giannitsá',
		'Véroia',
		'Thessaloníki',
		'Stavroúpoli',
		'Sykiés',
		'Sérres',
		'Políchni',
		'Pylaía',
		'Peraía',
		'Panórama',
		'Oraiókastro',
		'Náousa',
		'Meneméni',
		'Kilkís',
		'Kateríni',
		'Kalamariá',
		'Évosmos',
		'Elefthério - Kordelió',
		'Édessa',
		'Neapoli',
	],
	'Central Ostrobothnia': ['Kokkola'],
	'Central Papua': ['Nabire'],
	'Central Region': [
		'Salima',
		'Nkhotakota',
		'Mchinji',
		'Lilongwe',
		'Kasungu',
		'Dedza',
		'Wobulenzi',
		'Wakiso',
		'Njeru',
		'Namasuba',
		'Mukono',
		'Mubende',
		'Mityana',
		'Masaka',
		'Luwero',
		'Lugazi',
		'Kireka',
		'Kayunga',
		'Kampala',
		'Entebbe',
	],
	'Central Serbia': [
		'Zemun',
		'Zaječar',
		'Vranje',
		'Trstenik',
		'Sremčica',
		'Smederevska Palanka',
		'Smederevo',
		'Prokuplje',
		'Požarevac',
		'Pirot',
		'Obrenovac',
		'Novi Pazar',
		'Niš',
		'Negotin',
		'Leskovac',
		'Lazarevac',
		'Kruševac',
		'Kraljevo',
		'Kragujevac',
		'Jagodina',
		'Gornji Milanovac',
		'Ćuprija',
		'Čačak',
		'Bor',
		'Belgrade',
		'Aranđelovac',
		'Valjevo',
		'Užice',
		'Šabac',
		'Knjazevac',
	],
	'Central Sulawesi': ['Poso', 'Palu', 'Luwuk'],
	'Central Visayas': [
		'Toledo',
		'Tanjay',
		'Talisay',
		'Tagbilaran City',
		'Sibulan',
		'Santa Catalina',
		'San Fernando',
		'Pinamungahan',
		'Naga',
		'Minglanilla',
		'Mandaue City',
		'Loboc',
		'Liloan',
		'Lapu-Lapu City',
		'Jagna',
		'Guihulñgan',
		'Dumaguete',
		'Danao',
		'Danao',
		'Cordova',
		'Consolacion',
		'Compostela',
		'Cebu City',
		'Cogan',
		'Carcar',
		'Canlaon',
		'Bogo',
		'Bayawan',
		'Bantayan',
		'Balamban',
		'Bais',
		'Apas',
	],
	'Central and Western': ['Hong Kong', 'Victoria'],
	Centrale: ['Tchamba', 'Sotouboua', 'Sokodé'],
	Centre: [
		'Ouagadougou',
		'Yaoundé',
		'Obala',
		'Nkoteng',
		'Nanga Eboko',
		'Mbandjok',
		'Mbalmayo',
		'Eséka',
		'Bafia',
		'Akonolinga',
		'Vierzon',
		'Vendôme',
		'Tours',
		'Saran',
		'Saint-Pierre-des-Corps',
		'Saint-Jean-de-la-Ruelle',
		'Saint-Jean-de-Braye',
		'Saint-Cyr-sur-Loire',
		'Saint-Avertin',
		'Romorantin-Lanthenay',
		'Orléans',
		'Olivet',
		'Montargis',
		'Lucé',
		'Joué-lès-Tours',
		'Gien',
		'Fleury-les-Aubrais',
		'Dreux',
		'Châteauroux',
		'Châteaudun',
		'Chartres',
		'Bourges',
		'Blois',
		'Hinche',
	],
	'Centre-Est': ['Tenkodogo', 'Koupéla', 'Garango'],
	'Centre-Nord': ['Kongoussi', 'Kaya', 'Boulsa'],
	'Centre-Ouest': ['Sapouy', 'Réo', 'Léo', 'Koudougou', 'Kokologo'],
	'Centre-Sud': ['Pô', 'Manga', 'Kombissiri'],
	'Cerro Largo': ['Melo'],
	Cesar: [
		'Valledupar',
		'San Diego',
		'Pailitas',
		'La Jagua de Ibirico',
		'El Copey',
		'Curumaní',
		'Chiriguaná',
		'Chimichagua',
		'Becerril',
		'Astrea',
		'Ariguaní',
		'Aguachica',
		'Agustín Codazzi',
		'San Martín',
	],
	Cetinje: ['Cetinje'],
	Ceuta: ['Ceuta'],
	Chachoengsao: ['Phanom Sarakham', 'Chachoengsao', 'Bang Pakong'],
	Chaco: [
		'Resistencia',
		'General José de San Martín',
		'Fontana',
		'Barranqueras',
		'Villa Ángela',
		'Tres Isletas',
		'Quitilipi',
		'Presidencia Roque Sáenz Peña',
		'Machagai',
		'Las Breñas',
		'General Pinedo',
		'Charata',
		'Castelli',
	],
	'Chagang-do': ['Manp’o', 'Kanggye'],
	Chaguanas: ['Chaguanas'],
	'Chaharmahal and Bakhtiari': ['Shahr-e Kord', 'Farrokh Shahr', 'Fārsān', 'Borūjen'],
	'Chai Nat': ['Chai Nat'],
	Chaiyaphum: ['Phu Khiao', 'Kaset Sombun', 'Kaeng Khro', 'Chaiyaphum'],
	Chalatenango: ['Chalatenango'],
	Champasak: ['Pakse'],
	Chandigarh: ['Chandīgarh'],
	Chanthaburi: ['Tha Mai', 'Laem Sing', 'Chanthaburi'],
	'Chari-Baguirmi': ['Dourbali'],
	Chechnya: ['Urus-Martan', 'Shali', 'Tsotsin-Yurt', 'Kurchaloy', 'Gudermes', 'Groznyy', 'Avtury', 'Argun', 'Achkhoy-Martan'],
	Chelyabinsk: [
		'Zlatoust',
		'Ust’-Katav',
		'Sim',
		'Satka',
		'Magnitogorsk',
		'Kusa',
		'Katav-Ivanovsk',
		'Bakal',
		'Asha',
		'Trëkhgornyy',
		'Yuzhnoural’sk',
		'Yemanzhelinsk',
		'Verkhniy Ufaley',
		'Troitsk',
		'Plast',
		'Miass',
		'Kyshtym',
		'Korkino',
		'Kopeysk',
		'Kasli',
		'Kartaly',
		'Karabash',
		'Chelyabinsk',
		'Chebarkul’',
		'Snezhinsk',
		'Ozersk',
	],
	Cherkasy: ['Zvenihorodka', 'Zolotonosha', 'Vatutine', 'Uman', 'Smila', 'Shpola', 'Korsun-Shevchenkivskyy', 'Kaniv', 'Cherkasy'],
	Chernihiv: ['Pryluky', 'Nizhyn', 'Chernihiv', 'Bakhmach'],
	Chernivtsi: ['Chernivtsi'],
	Chhattisgarh: [
		'Umarkot',
		'Takhatpur',
		'Saraipali',
		'Saktī',
		'Ratanpur',
		'Rāj-Nāndgaon',
		'Raipur',
		'Raigarh',
		'Pathalgaon',
		'Pasān',
		'Mungeli',
		'Mahāsamund',
		'Kumhāri',
		'Kotapārh',
		'Kotā',
		'Korba',
		'Kondagaon',
		'Kharsia',
		'Khairāgarh',
		'Khairāgarh',
		'Kawardha',
		'Katghora',
		'Kānker',
		'Jūnāgarh',
		'Jashpurnagar',
		'Jānjgīr',
		'Jagdalpur',
		'Durg',
		'Dongargarh',
		'Dhamtari',
		'Chāmpa',
		'Bilāspur',
		'Bhilai',
		'Bhātāpāra',
		'Bhānpurī',
		'Bemetāra',
		'Baloda Bāzār',
		'Balod',
		'Arang',
		'Ambikāpur',
		'Akaltara',
		'Kirandul',
		'Chirmiri',
	],
	'Chiang Mai': ['San Pa Tong', 'San Kamphaeng', 'Hang Dong', 'Chiang Mai'],
	'Chiang Rai': ['Mae Sai', 'Mae Chan', 'Chiang Rai'],
	Chiapas: [
		'Yajalón',
		'Villaflores',
		'Venustiano Carranza',
		'Tuxtla',
		'Teopisca',
		'Tapachula',
		'Suchiapa',
		'San Cristóbal de las Casas',
		'Reforma',
		'Pijijiapan',
		'Palenque',
		'Ocozocoautla de Espinosa',
		'Ocosingo',
		'Motozintla',
		'Mapastepec',
		'Las Rosas',
		'Las Margaritas',
		'Huixtla',
		'Comalapa',
		'Emiliano Zapata',
		'Comitán',
		'Cintalapa de Figueroa',
		'Chiapa de Corzo',
		'Cacahoatán',
		'Berriozábal',
		'Tonalá',
		'Arriaga',
	],
	Chiba: [
		'Tateyama',
		'Noda',
		'Nagareyama',
		'Matsudo',
		'Kisarazu',
		'Kashiwa',
		'Futtsu',
		'Honchō',
		'Kimitsu',
		'Yotsukaidō',
		'Yōkaichiba',
		'Yachimata',
		'Tōgane',
		'Shisui',
		'Shiroi',
		'Sawara',
		'Sakura',
		'Omigawa',
		'Ōhara',
		'Ōami',
		'Narutō',
		'Narita',
		'Mobara',
		'Kamogawa',
		'Katsuura',
		'Katori-shi',
		'Ichihara',
		'Hasaki',
		'Chiba',
		'Asahi',
		'Abiko',
		'Sōsa',
		'Inzai',
	],
	Chihuahua: [
		'Ojinaga',
		'Nuevo Casas Grandes',
		'Meoqui',
		'Madera',
		'Parral',
		'Cuauhtémoc',
		'Ciudad Juárez',
		'José Mariano Jiménez',
		'Ciudad Delicias',
		'Camargo',
		'Chihuahua',
		'Aldama',
		'Manuel Ojinaga',
	],
	Chimaltenango: ['Zaragoza', 'Yepocapa', 'Tecpán Guatemala', 'San Andrés Itzapa', 'Patzún', 'Patzicía', 'El Tejar', 'Comalapa', 'Chimaltenango'],
	Chimborazo: ['Riobamba'],
	Chin: ['Hakha'],
	Chinandega: ['Somotillo', 'El Viejo', 'Corinto', 'Chinandega', 'Chichigalpa'],
	Chiquimula: ['Esquipulas', 'Chiquimula'],
	'Chiriquí Province': ['Pedregal', 'La Concepción', 'David'],
	Chittagong: [
		'Teknāf',
		'Sandwīp',
		'Rāmganj',
		'Patiya',
		'Lākshām',
		'Comilla',
		'Feni',
		'Rāipur',
		'Chhāgalnāiya',
		'Khagrachhari',
		'Bāndarban',
		'Raojān',
		'Nabīnagar',
		'Lakshmīpur',
		'Hājīganj',
		'Chattogram',
		'Sātkania',
		'Cox’s Bāzār',
		'Manikchari',
		'Bibir Hat',
	],
	'Chișinău Municipality': ['Codru', 'Chisinau'],
	Chlef: ['Sidi Akkacha', 'Oued Sly', 'Oued Fodda', 'Chlef', 'Ech Chettia', 'Boukadir', '’Aïn Merane', 'Abou el Hassan'],
	Chocó: ['Tadó', 'Quibdó', 'Pizarro'],
	'Choluteca Department': ['Ciudad Choluteca'],
	'Chon Buri': ['Si Racha', 'Sattahip', 'Phan Thong', 'Phanat Nikhom', 'Chon Buri', 'Pattaya', 'Bang Lamung', 'Ban Bueng', 'Ban Talat Bueng'],
	Chongqing: [
		'Zitong',
		'Zishui',
		'Zhuyuan',
		'Zhuyang',
		'Zhuxi',
		'Zhutuo',
		'Zhuoshui',
		'Zhoujia',
		'Zhonghe',
		'Zhonghe',
		'Zhongduo',
		'Zhong’ao',
		'Zhenxi',
		'Zhengxing',
		'Yuxi',
		'Yushan',
		'Yuntai',
		'Yunmen',
		'Yujia',
		'Yuexi',
		'Yudong',
		'Yuanyang',
		'Youxi',
		'Youting',
		'Youchou',
		'Yongxing',
		'Yongxin',
		'Yongjia',
		'Yongchuan',
		'Yong’an',
		'Yanjing',
		'Yanjia',
		'Yajiang',
		'Xituo',
		'Xintian',
		'Xinmin',
		'Xinmiao',
		'Xinglong',
		'Xinglong',
		'Wenfeng',
		'Xinshi',
		'Xiema',
		'Xiaomian',
		'Xiangshui',
		'Xianfeng',
		'Wushan',
		'Wuqiao',
		'Wuling',
		'Wujia',
		'Wudong',
		'Wenquan',
		'Weituo',
		'Wanxian',
		'Wanshun',
		'Wansheng',
		'Wangu',
		'Tuxiang',
		'Tongxi',
		'Tongjing',
		'Tieqiao',
		'Tiaoshi',
		'Tanjia',
		'Tangba',
		'Taiping',
		'Shanghe',
		'Taihe',
		'Tai’an',
		'Shuitu',
		'Shuijiang',
		'Shuangshi',
		'Shuanglong',
		'Shuangjiang',
		'Shuanghe',
		'Shituo',
		'Shimen',
		'Shima',
		'Xianglong',
		'Shijiao',
		'Shihui',
		'Shichuan',
		'Shetan',
		'Yunlong',
		'Rensha',
		'Shaping',
		'Shanghuang',
		'Shahe',
		'Sanqu',
		'Sanjiao',
		'Sanjiang',
		'Sanhui',
		'Xiaodu',
		'Sangzhe',
		'Ronglong',
		'Renyi',
		'Renxian',
		'Qingping',
		'Puzi',
		'Pingtan',
		'Pingjin',
		'Panlong',
		'Nanping',
		'Nanbin',
		'Mu’er',
		'Mudong',
		'Miaoyu',
		'Meijiang',
		'Mawu',
		'Mawang',
		'Luoqi',
		'Luoping',
		'Luohuang',
		'Longxing',
		'Longtan',
		'Longtan',
		'Longshui',
		'Longshi',
		'Longmen',
		'Longhua',
		'Longju',
		'Longgang',
		'Longchi',
		'Liuyin',
		'Lishi',
		'Linshi',
		'Linjiang',
		'Linjiang',
		'Linfeng',
		'Lidu',
		'Jiangbei',
		'Laisu',
		'Jiuxian',
		'Jingguan',
		'Jijiang',
		'Jielong',
		'Jiasi',
		'Jiaoshi',
		'Jianshan',
		'Jiangkou',
		'Jiangjia',
		'Huolu',
		'Hufeng',
		'Honglu',
		'Hong’an',
		'Hechuan',
		'Helin',
		'Heishui',
		'Hebian',
		'Hanjia',
		'Hanfeng',
		'Guxi',
		'Guofu',
		'Gunan',
		'Guixi',
		'Guangshun',
		'Guandu',
		'Guandu',
		'Gongtan',
		'Gongping',
		'Gelan',
		'Gecheng',
		'Gaojia',
		'Gaogu',
		'Gao’an',
		'Ganshui',
		'Wofo',
		'Fulu',
		'Fuling',
		'Fenshui',
		'Fengjia',
		'Fenggao',
		'Duzhou',
		'Dushi',
		'Dongxi',
		'Dingshi',
		'Dingjia',
		'Degan',
		'Daxie',
		'Dashun',
		'Dalu',
		'Dajin',
		'Daguan',
		'Dachang',
		'Shuren',
		'Da’an',
		'Cizhu',
		'Chongqing',
		'Chongkan',
		'Chengxi',
		'Chengjiang',
		'Changyuan',
		'Changba',
		'Longxing',
		'Caijia',
		'Bishan',
		'Beibei',
		'Baiyang',
		'Shuanglong',
		'Batang',
		'Baoluan',
		'Baojia',
		'Banqiao',
		'Baizi',
		'Baitu',
		'Baitao',
		'Baisha',
		'Baima',
		'Bailin',
		'Bachuan',
		'Qingyang',
		'Anping',
		'Anju',
		'Anfu',
		'Lüfeng',
		'Xinsheng',
		'Yongxi',
		'Pulü',
		'Caijiagang',
		'Tieshan',
		'Hebao',
		'Baoxing',
		'Damiao',
		'Daxing',
		'Jinlong',
		'Sanjiao',
		'Guangpu',
		'Chenshi',
		'Qingfeng',
		'Wutan',
		'Wujian',
		'Hegeng',
		'Xianlong',
		'Ji’an',
		'Shima',
		'Banan',
		'Conglin',
		'Ciyun',
		'Xiaba',
		'Anlan',
		'Shilong',
		'Nanpeng',
		'Huimin',
		'Ersheng',
		'Yufengshan',
		'Tongjiaxi',
		'Dasheng',
		'Taiping',
		'Tuchang',
		'Honghu',
		'Zhaojia',
		'Gulu',
		'Zouma',
		'Yantai',
		'Sanmiao',
		'Rongxi',
		'Longsheng',
		'Lixi',
		'Shigang',
		'Chengxiang',
		'Tangfang',
		'Fengling',
		'Longjing',
		'Baolong',
		'Tonggu',
		'Sanxi',
		'Liangping',
		'Longxi',
		'Futian',
		'Dashu',
		'Kangle',
		'Fenhe',
		'Baidi',
		'Caotang',
		'Yongle',
		'Jiagao',
		'Qinglong',
		'Wuma',
		'Fengping',
		'Qinglian',
		'Heqian',
		'Maliu',
		'Heyan',
		'Guojia',
		'Baihe',
		'Dunhao',
		'Gaoqiao',
		'Yihe',
		'Jiulongshan',
		'Zhen’an',
		'Dade',
		'Fengle',
		'Zhendong',
		'Houba',
		'Changsha',
		'Nanmen',
		'Wushan',
		'Nanya',
		'Zhuxi',
		'Houshan',
		'Lihe',
		'Xiongjia',
		'Tiancheng',
		'Zhoujiaba',
		'Taibai',
		'Gaoliang',
		'Zhonggulou',
		'Jiuchi',
		'Pailou',
		'Chenjiaba',
		'Bai’anba',
		'Shuanghekou',
		'Zhushan',
		'Danzi',
		'Changtan',
		'Tai’an',
		'Luotian',
		'Guocun',
		'Longsha',
		'Ganning',
		'Gaofeng',
		'Linxi',
		'Xialu',
		'Caohui',
		'Xinmin',
		'Changlong',
		'Yong’an',
		'Pushun',
		'Gangjia',
		'Gaofeng',
		'Pingshan',
		'Baijia',
		'Panlong',
		'Yinping',
		'Jukui',
		'Bishan',
		'Huilong',
		'Mingda',
		'Lirang',
		'Hucheng',
		'Xinsheng',
		'Hexing',
		'Baohe',
		'Dongjia',
		'Shizhi',
		'Huwei',
		'Sanhe',
		'Xingyi',
		'Longkong',
		'Longhe',
		'Qingxichang',
		'Pingkai',
		'Zhongling',
		'Erong',
		'Guanzhuang',
		'Wanmu',
		'Tonggu',
		'Apengjiang',
		'Qianjiang',
		'Zhoubai',
		'Dianshui',
		'Sanhe',
		'Xintian',
		'Longshe',
		'Lianhu',
		'Tongle',
		'Zengfu',
		'Longqiao',
		'Lizhi',
		'Nantuo',
		'Qingxi',
		'Baisheng',
		'Yihe',
		'Yunji',
		'Changshouhu',
		'Haitang',
		'Shiyan',
		'Longhe',
		'Fengcheng',
		'Jiangnan',
		'Bake',
		'Dawan',
		'Gulu',
		'Lijia',
		'Shuangfengqiao',
		'Huixing',
		'Cuiyun',
		'Wangjia',
		'Tianfu',
		'Fuxing',
		'Dongyang',
		'Longfeng',
		'Shuanghuai',
		'Shuangfeng',
		'Shitan',
		'Xiaojia',
		'Laitan',
		'Qiantang',
		'Dashi',
		'Gulou',
		'Yanwo',
		'Erlang',
		'Longfeng',
		'Longxing',
		'Yangcheng',
		'Diaoyucheng',
		'Nanjin',
		'Baoding',
		'Zhifeng',
		'Shiwan',
		'Yulong',
		'Shuanglu',
		'Tangxiang',
		'Guchang',
		'Shenglilu',
		'Nandajie',
		'Shaoyun',
		'Qinggang',
		'Jianlong',
		'Shuangfu',
		'Zhiping',
		'Nanchuan',
		'Anwen',
		'Datong',
		'Guanba',
		'Nantong',
		'Maliuzui',
		'Sansheng',
		'Wulong',
		'Wanzhou',
		'Zhuyi',
		'Dongwenquan',
		'Jieshi',
		'Qitang',
		'Shuanglonghu',
		'Jiaping',
		'Xihu',
		'Zhongshan',
		'Mingshan',
		'Caojie',
		'Liangping',
		'Xinglong',
		'Fuhuan',
		'Qingnian',
		'Shihao',
		'Yongcheng',
		'Zhuantang',
		'Tianjia',
		'Changling',
		'Weixinghu',
		'Zhuhai',
	],
	'Chontales Department': ['Juigalpa'],
	Chubut: ['Trelew', 'Rawson', 'Puerto Madryn', 'Esquel', 'Comodoro Rivadavia'],
	Chukha: ['Phuntsholing'],
	Chukotka: ['Anadyr'],
	Chumphon: ['Lang Suan', 'Chumphon'],
	'Chungcheongnam-do': [
		'Yesan',
		'Tangjin',
		'Taesal-li',
		'Boryeong',
		'Seosan',
		'Seonghwan',
		'Buyeo',
		'Asan',
		'Nonsan',
		'Kinzan',
		'Gongju',
		'Hongseong',
		'Cheonan',
		'Yŏnmu',
	],
	'Chuquisaca Department': ['Sucre'],
	Chuvashia: ['Shumerlya', 'Novocheboksarsk', 'Kanash', 'Cheboksary', 'Alatyr’'],
	Chüy: ['Tokmok', 'Lebedinovka', 'Kara-Balta', 'Kant', 'Ivanovka', 'Belovodskoye'],
	'Ciego de Ávila Province': ['Venezuela', 'Primero de Enero', 'Morón', 'Florencia', 'Ciro Redondo', 'Ciego de Ávila', 'Chambas', 'Baraguá'],
	'Cienfuegos Province': ['Rodas', 'Palmira', 'Lajas', 'Cumanayagua', 'Cruces', 'Cienfuegos', 'Aguada de Pasajeros', 'Abreus'],
	'City of Francistown': ['Francistown'],
	'City of Zagreb': ['Zagreb', 'Sesvete', 'Centar'],
	Clarendon: ['May Pen'],
	Cluj: ['Turda', 'Gherla', 'Dej', 'Cluj-Napoca', 'Câmpia Turzii'],
	Coahuila: [
		'Frontera',
		'Torreón',
		'San Pedro',
		'San Pedro',
		'San Buenaventura',
		'Saltillo',
		'Sabinas',
		'Ramos Arizpe',
		'Piedras Negras',
		'Parras de la Fuente',
		'Palau',
		'Nueva Rosita',
		'Nava',
		'Monclova',
		'Ciudad Melchor Múzquiz',
		'Matamoros',
		'Francisco I. Madero',
		'Ciudad Acuña',
		'Castaños',
		'Allende',
	],
	Cochabamba: ['Vinto', 'Tiquipaya', 'Sipe Sipe', 'Sacaba', 'Quillacollo', 'Punata', 'Mizque', 'Colcapirhua', 'Cochabamba'],
	Coclé: ['Penonomé'],
	Coimbra: ['Santo António dos Olivais', 'Figueira da Foz', 'Condeixa-a-Nova', 'Coimbra'],
	Cojedes: ['Tinaquillo', 'Tinaco', 'San Carlos', 'Las Vegas', 'El Pao'],
	Colima: ['Ciudad de Villa de Álvarez', 'Tecomán', 'Manzanillo', 'Colima', 'Ciudad de Armería'],
	Collines: ['Savé', 'Savalou', 'Dassa-Zoumé', 'Comé'],
	Colonia: ['Colonia del Sacramento', 'Carmelo'],
	Colorado: [
		'Arvada',
		'Aurora',
		'Brighton',
		'Broomfield',
		'Cañon City',
		'Castle Rock',
		'Castlewood',
		'Centennial',
		'Cimarron Hills',
		'Clifton',
		'Colorado Springs',
		'Columbine',
		'Commerce City',
		'Denver',
		'Durango',
		'Englewood',
		'Fountain',
		'Golden',
		'Grand Junction',
		'Greenwood Village',
		'Highlands Ranch',
		'Ken Caryl',
		'Lafayette',
		'Lakewood',
		'Littleton',
		'Louisville',
		'Montrose',
		'Northglenn',
		'Parker',
		'Pueblo',
		'Pueblo West',
		'Sherrelwood',
		'Southglenn',
		'Thornton',
		'Westminster',
		'Wheat Ridge',
		'Boulder',
		'Erie',
		'Evans',
		'Fort Collins',
		'Greeley',
		'Longmont',
		'Loveland',
		'Windsor',
		'Security-Widefield',
	],
	Colón: ['Sabanitas', 'Colón', 'Cativá'],
	'Colón Department': ['Tocoa'],
	'Comayagua Department': ['Siguatepeque', 'Comayagua'],
	Comoé: ['Grand-Bassam', 'Bonoua', 'Agnibilékrou', 'Adiaké', 'Aboisso', 'Abengourou'],
	Conakry: ['Conakry', 'Camayenne'],
	Concepción: ['Concepción'],
	Connacht: ['Sligo', 'Galway'],
	Connecticut: [
		'Danbury',
		'Darien',
		'East Hartford',
		'East Haven',
		'East Norwalk',
		'Enfield',
		'Fairfield',
		'Farmington',
		'Glastonbury',
		'Guilford',
		'Hamden',
		'Hartford',
		'Killingly Center',
		'Ledyard',
		'Madison',
		'Manchester',
		'Mansfield City',
		'Meriden',
		'Middletown',
		'Milford',
		'Montville Center',
		'Naugatuck',
		'New Britain',
		'New Canaan',
		'New Haven',
		'New London',
		'Newington',
		'North Haven',
		'North Stamford',
		'Norwalk',
		'Norwich',
		'Plainfield',
		'Plainville',
		'Seymour',
		'Shelton',
		'South Windsor',
		'Southbury',
		'Stamford',
		'Storrs',
		'Stratford',
		'Torrington',
		'Trumbull',
		'Wallingford',
		'Waterbury',
		'West Hartford',
		'West Haven',
		'West Torrington',
		'Westport',
		'Wethersfield',
		'Willimantic',
		'Wilton',
		'Windham',
		'Windsor',
		'Wolcott',
		'Ansonia',
		'Bloomfield',
		'Bridgeport',
		'Bristol',
		'Branford',
		'Cheshire',
		'City of Milford (balance)',
		'Wallingford Center',
		'Waterford',
	],
	Constantine: ['Hamma Bouziane', 'El Khroub', 'Didouche Mourad', 'Constantine', 'Aïn Smara', '’Aïn Abid'],
	Constanța: ['Năvodari', 'Medgidia', 'Mangalia', 'Constanţa', 'Cernavodă'],
	Copperbelt: ['Ndola', 'Mufulira', 'Luanshya', 'Kitwe', 'Kalulushi', 'Chingola', 'Chililabombwe'],
	'Copán Department': ['Santa Rosa de Copán'],
	'Coquimbo Region': ['Ovalle', 'La Serena', 'Illapel', 'Coquimbo'],
	Cordillera: ['Tabuk', 'Mankayan', 'La Trinidad', 'Itogon', 'Baguio', 'Caacupé'],
	Cordoba: [
		'Villa Nueva',
		'Villa María',
		'Villa Dolores',
		'Villa Carlos Paz',
		'Villa Allende',
		'Unquillo',
		'San Francisco',
		'Río Tercero',
		'Río Segundo',
		'Río Cuarto',
		'Río Ceballos',
		'Morteros',
		'Marcos Juárez',
		'La Falda',
		'La Calera',
		'Laboulaye',
		'Jesús María',
		'Embalse',
		'Deán Funes',
		'Cruz del Eje',
		'Cosquín',
		'Córdoba',
		'Bell Ville',
		'Arroyito',
		'Alta Gracia',
	],
	Corrientes: [
		'Santo Tomé',
		'San Luis del Palmar',
		'San Lorenzo',
		'Saladas',
		'Paso de los Libres',
		'Monte Caseros',
		'Mercedes',
		'Goya',
		'Gobernador Virasora',
		'Esquina',
		'Curuzú Cuatiá',
		'Corrientes',
	],
	Corsica: ['Bastia', 'Ajaccio'],
	'Cortés Department': ['Puerto Cortez', 'Villanueva', 'San Pedro Sula', 'Potrerillos', 'La Lima', 'Cofradía', 'Choloma'],
	Cotopaxi: ['Pujilí', 'Latacunga', 'La Maná'],
	'County of Koprivnica-Križevci': ['Koprivnica'],
	'County of Međimurje': ['Čakovec'],
	'County of Osijek-Baranja': ['Osijek', 'Đakovo'],
	'County of Požega-Slavonia': ['Požega'],
	'County of Primorje-Gorski Kotar': ['Rijeka'],
	'County of Sisak-Moslavina': ['Sisak'],
	'County of Varaždin': ['Varaždin'],
	'County of Zadar': ['Zadar'],
	'County of Zagreb': ['Zaprešić', 'Velika Gorica', 'Samobor'],
	'Cova Lima': ['Suai'],
	Covasna: ['Târgu Secuiesc', 'Sfântu Gheorghe'],
	Crete: ['Rethymno', 'Chaniá', 'Irákleion'],
	Crimea: [
		'Yevpatoriya',
		'Yalta',
		'Sudak',
		'Simferopol',
		'Saki',
		'Yany Kapu',
		'Kerch',
		'Feodosiya',
		'Dzhankoi',
		'Bilohirsk',
		'Bakhchysarai',
		'Armyansk',
		'Alushta',
	],
	'Cross River': ['Ugep', 'Ogoja', 'Obudu', 'Ikom', 'Calabar'],
	Csongrád: ['Szeged', 'Makó', 'Hódmezővásárhely', 'Csongrád'],
	'Cuando Cobango': ['Menongue'],
	'Cuanza Norte': ['N’dalatando'],
	Cundinamarca: [
		'Zipaquirá',
		'Villeta',
		'Villa de San Diego de Ubaté',
		'Soacha',
		'Silvania',
		'Sibaté',
		'Puerto Salgar',
		'Pacho',
		'Mosquera',
		'Madrid',
		'La Mesa',
		'Guaduas',
		'Girardot City',
		'Fusagasugá',
		'Funza',
		'Facatativá',
		'Chía',
		'Cajicá',
	],
	Cuscatlán: ['San Martín', 'Cojutepeque'],
	Cusco: ['Yauri', 'Sicuani', 'Santa Ana', 'Cusco', 'Wanchaq', 'Santiago'],
	Cuvette: ['Owando'],
	'Cà Mau': ['Cà Mau'],
	Córdoba: [
		'Tierralta',
		'San Carlos',
		'San Antero',
		'Sahagún',
		'Planeta Rica',
		'Montería',
		'Montelíbano',
		'Momil',
		'Lorica',
		'Cotorra',
		'Ciénaga de Oro',
		'Chinú',
		'Cereté',
		'Ayapel',
	],
	Călărași: ['Olteniţa'],
	Căuşeni: ['Căuşeni'],
	'Cēsis Municipality': ['Cēsis'],
	'Da Nang': ['Da Nang'],
	'Dadra and Nagar Haveli and Daman and Diu': ['Silvassa', 'Diu', 'Daman', 'Āmli'],
	Daegu: ['Daegu', 'Hwawŏn'],
	Daejeon: ['Daejeon'],
	Dagestan: ['Makhachkala', 'Kizlyar', 'Kizilyurt', 'Khasavyurt', 'Kaspiysk', 'Izberbash', 'Derbent', 'Dagestanskiye Ogni', 'Buynaksk', 'Belidzhi'],
	Dajabón: ['Dajabón'],
	Dakahlia: [
		'Ţalkhā',
		'Shirbīn',
		'Mīt Ghamr',
		'Minyat an Naşr',
		'‘Izbat al Burj',
		'Dikirnis',
		'Bilqās',
		'As Sinbillāwayn',
		'Al Maţarīyah',
		'Al Manzalah',
		'Al Manşūrah',
		'Al Jammālīyah',
		'Ajā',
	],
	Dakar: ['Pikine', 'Dakar', 'N’diareme limamoulaye'],
	'Dakhlet Nouadhibou': ['Nouadhibou'],
	Dalarna: ['Ludvika', 'Falun', 'Borlänge'],
	Damietta: ['Fāraskūr', 'Damietta', 'Az Zarqā'],
	'Dar es Salaam': ['Magomeni', 'Dar es Salaam'],
	Daraa: ['Ţafas', 'Shaykh Miskīn', 'Jāsim', 'Inkhil', 'Dar‘ā', 'Aş Şanamayn', 'Al Ḩirāk'],
	'Darhan Uul': ['Darhan'],
	Darnah: ['Darnah', 'Al Qubbah'],
	Daugavpils: ['Daugavpils'],
	Davao: [
		'Magugpo Poblacion',
		'Santa Maria',
		'San Mariano',
		'Samal',
		'Panabo',
		'New Corella',
		'Nabunturan',
		'Monkayo',
		'Mati',
		'Manay',
		'Malita',
		'Magsaysay',
		'Lupon',
		'Digos',
		'Davao',
		'Compostela',
		'Cabayangan',
		'Bansalan',
	],
	Daşoguz: ['Yylanly', 'Tagta', 'Köneürgench', 'Boldumsaz', 'Daşoguz'],
	Debar: ['Debar'],
	Debub: ['Mendefera'],
	Dededo: ['Dededo Village'],
	'Deir ez-Zor': ['Subaykhān', 'Deir ez-Zor', 'Al Mayādīn', 'Ālbū Kamāl', 'Hajīn'],
	Delaware: ['Bear', 'Dover', 'Middletown', 'Newark', 'Wilmington'],
	Delchevo: ['Delcevo'],
	Delhi: ['New Delhi', 'Narela', 'Nāngloi Jāt', 'Najafgarh', 'Karol Bāgh', 'Delhi', 'Bawāna', 'Alīpur', 'Deoli', 'Rohini'],
	Delta: ['Warri', 'Ughelli', 'Sapele', 'Ogwashi-Uku', 'Kwale', 'Burutu', 'Asaba', 'Agbor'],
	'Delta Amacuro': ['Tucupita'],
	'Demerara-Mahaica': ['Georgetown'],
	Denguélé: ['Odienné'],
	Denizli: ['Sarayköy', 'Serinhisar', 'Denizli', 'Merkezefendi'],
	'Departamento de Arauca': ['Tame', 'Arauca'],
	Dhaka: [
		'Tungi',
		'Dohār',
		'Narsingdi',
		'Nārāyanganj',
		'Nāgarpur',
		'Mirzāpur',
		'Dhaka',
		'Bhairab Bāzār',
		'Bājitpur',
		'Tungipāra',
		'Sonārgaon',
		'Sakhipur',
		'Pālang',
		'Farīdpur',
		'Char Bhadrāsan',
		'Tāngāil',
		'Madaripur',
		'Kishorganj',
		'Azimpur',
		'Paltan',
		'Joymontop',
	],
	Dhamār: ['Dhamār'],
	'Dhi Qar': ['Ash Shaţrah', 'Nasiriyah', 'Nāḩiyat al Fuhūd'],
	Dhofar: ['Şalālah'],
	Diana: ['Antsohimbondrona', 'Antsiranana', 'Hell-Ville', 'Ambilobe', 'Ambarakaraka', 'Ambanja'],
	'Dibër County': ['Burrel'],
	Diffa: ['Nguigmi', 'Diffa'],
	Dimashq: ['Damascus'],
	Diourbel: ['Touba', 'Tiébo', 'Mbaké'],
	'Dire Dawa': ['Dire Dawa'],
	'Distrito Federal': ['Caracas'],
	'Diyarbakır Province': ['Silvan', 'Lice', 'Kulp', 'Ergani', 'Diyarbakır', 'Çermik', 'Bismil'],
	Diyālá: ['Mandalī', 'Kifrī', 'Baqubah', 'Al Miqdādīyah', 'Khāliş'],
	Djelfa: ['Messaad', 'El Idrissia', 'Djelfa', 'Dar Chioukh', 'Charef', 'Birine', 'Aïn Oussera', '’Aïn el Bell'],
	Djibouti: ['Djibouti'],
	Dnipropetrovsk: [
		'Zhovti Vody',
		'Vilnohirsk',
		'Verkhnodniprovsk',
		'Ternivka',
		'Synelnykove',
		'Pyatykhatky',
		'Pidhorodne',
		'Pershotravensk',
		'Pavlohrad',
		'Pokrov',
		'Novomoskovsk',
		'Nikopol',
		'Marhanets',
		'Kryvyy Rih',
		'Dnipro',
		'Kamyanske',
		'Apostolove',
	],
	Dobrich: ['Dobrich'],
	Dodoma: ['Mpwapwa', 'Kondoa', 'Kibakwe', 'Dodoma'],
	Dolj: ['Filiaşi', 'Craiova', 'Calafat', 'Băileşti'],
	Donetsk: [
		'Druzhkivka',
		'Zuhres',
		'Yenakiieve',
		'Yasynuvata',
		'Volnovakha',
		'Chystyakove',
		'Snizhne',
		'Sloviansk',
		'Shakhtarsk',
		'Selydove',
		'Kurakhove',
		'Mariupol',
		'Makiivka',
		'Kurakhovo',
		'Lyman',
		'Pokrovsk',
		'Kramatorsk',
		'Kostiantynivka',
		'Khrestivka',
		'Khartsyzk',
		'Ilovays’k',
		'Horlivka',
		'Toretsk',
		'Donetsk',
		'Dokuchaievsk',
		'Dobropillia',
		'Myrnohrad',
		'Debaltseve',
		'Avdiivka',
		'Bakhmut',
		'Amvrosiivka',
	],
	Donga: ['Djougou', 'Bassila'],
	Dosso: ['Gaya', 'Dosso', 'Dogondoutchi'],
	Douglas: ['Douglas'],
	Drenthe: ['Meppel', 'Hoogeveen', 'Emmen', 'Assen'],
	Drochia: ['Drochia'],
	'Drâa-Tafilalet': ['Zagora', 'Tinghir', 'Reçani', 'Ouarzazat', 'Arfoud', 'Errachidia'],
	Duarte: ['San Francisco de Macorís'],
	Dubai: ['Dubai'],
	'Dubrovnik-Neretva': ['Dubrovnik'],
	Duhok: ['Zaxo', 'Sīnah', 'Dihok', 'Batifa'],
	Durango: [
		'Santiago Papasquiaro',
		'Ciudad Guadalupe Victoria',
		'Gómez Palacio',
		'Vicente Guerrero',
		'El Salto',
		'Pueblo Nuevo',
		'Victoria de Durango',
		'Ciudad Lerdo',
	],
	'Durazno Department': ['Durazno'],
	'Durrës County': ['Krujë', 'Durrës'],
	Dushanbe: ['Dushanbe', 'Boshkengash'],
	Dzabkhan: ['Uliastay'],
	Dzaoudzi: ['Dzaoudzi'],
	Dâmbovița: ['Târgovişte', 'Moreni', 'Găeşti'],
	Díli: ['Dili'],
	Düzce: ['Düzce', 'Akçakoca'],
	East: ['Garoua Boulaï', 'Bertoua', 'Bélabo', 'Batouri'],
	'East Azerbaijan': ['Tabriz', 'Marand', 'Bonāb', 'Hashtrūd', 'Ajab Shīr', 'Ahar'],
	'East Berbice-Corentyne': ['New Amsterdam'],
	'East Gobi Aymag': ['Saynshand'],
	'East Java': [
		'Wongsorejo',
		'Tulungagung',
		'Tulangan Utara',
		'Tuban',
		'Trenggalek',
		'Tanggulangin',
		'Surabaya',
		'Sumenep',
		'Sumberpucung',
		'Srono',
		'Soko',
		'Situbondo',
		'Singosari',
		'Singojuruh',
		'Sidoarjo',
		'Sampang',
		'Probolinggo',
		'Prigen',
		'Ponorogo',
		'Pasuruan',
		'Pare',
		'Panji',
		'Pandaan',
		'Panarukan',
		'Pamekasan',
		'Pakisaji',
		'Paciran',
		'Ngunut',
		'Ngoro',
		'Ngawi',
		'Nganjuk',
		'Muncar',
		'Mojokerto',
		'Mojoagung',
		'Malang',
		'Madiun',
		'Lumajang',
		'Lawang',
		'Lamongan',
		'Krian',
		'Kraksaan',
		'Kertosono',
		'Kepanjen',
		'Kencong',
		'Kedungwaru',
		'Kediri',
		'Kebomas',
		'Kamal',
		'Kalianget',
		'Jombang',
		'Jember',
		'Gresik',
		'Gongdanglegi Kulon',
		'Genteng',
		'Gedangan',
		'Gampengrejo',
		'Gambiran Satu',
		'Driyorejo',
		'Diwek',
		'Dampit',
		'Buduran',
		'Boyolangu',
		'Bondowoso',
		'Bojonegoro',
		'Blitar',
		'Besuki',
		'Batu',
		'Banyuwangi',
		'Bangkalan',
		'Bangil',
		'Balung',
		'Babat',
	],
	'East Kalimantan': ['Samarinda', 'Loa Janan', 'Bontang', 'Balikpapan'],
	'East Kazakhstan': ['Altay', 'Zaysan', 'Shemonaikha', 'Ust-Kamenogorsk', 'Ridder'],
	'East Macedonia and Thrace': ['Xánthi', 'Orestiáda', 'Komotiní', 'Kavála', 'Dráma', 'Alexandroupoli'],
	'East New Britain': ['Kokopo'],
	'East Nusa Tenggara': ['Waingapu', 'Soe', 'Ruteng', 'Maumere', 'Labuan Bajo', 'Kefamenanu', 'Ende', 'Atambua', 'Kupang', 'Tambolaka'],
	'East Sepik': ['Wewak'],
	Eastern: ['Suhum', 'Akim Oda', 'Nsawam', 'Koforidua', 'Begoro', 'Asamankese', 'Akwatia', 'Aburi', 'Petauke', 'Chipata'],
	'Eastern Cape': [
		'Mthatha',
		'Uitenhage',
		'Stutterheim',
		'Somerset East',
		'Queenstown',
		'Queensdale',
		'Port Elizabeth',
		'Port Alfred',
		'Middelburg',
		'Lady Frere',
		'Kruisfontein',
		'Grahamstown',
		'Graaff-Reinet',
		'Fort Beaufort',
		'East London',
		'Cradock',
		'Butterworth',
		'Bhisho',
		'Aliwal North',
	],
	'Eastern Darfur': ['El Daein'],
	'Eastern District': ['Pago Pago'],
	'Eastern Equatoria': ['Torit', 'Pajok'],
	'Eastern Highlands': ['Goroka'],
	'Eastern Province': [
		'Vakarai',
		'Trincomalee',
		'Kalmunai',
		'Eravur Town',
		'Batticaloa',
		'Ampara',
		'Rwamagana',
		'Kibungo',
		'Tārūt',
		'Sayhāt',
		'Şafwá',
		'Raḩīmah',
		'Hafar Al-Batin',
		'Abqaiq',
		'Dhahran',
		'Aţ Ţaraf',
		'Qaisumah',
		'Al Qaţīf',
		'Al Munayzilah',
		'Al Mubarraz',
		'Khobar',
		'Al Khafjī',
		'Al Jubayl',
		'Al Hufūf',
		'Al Baţţālīyah',
		'Dammam',
		'Segbwema',
		'Koidu',
		'Kenema',
	],
	'Eastern Region': ['Tororo', 'Soroti', 'Pallisa', 'Mbale', 'Jinja', 'Iganga', 'Buwenge', 'Busia', 'Busembatia', 'Bugiri'],
	'Eastern Visayas': [
		'Tanauan',
		'Tacloban',
		'Panalanoy',
		'Palo',
		'Ormoc',
		'Maasin',
		'Laoang',
		'Catbalogan',
		'Catarman',
		'Carigara',
		'Calbayog City',
		'Borongan',
		'Baybay',
		'Abuyog',
	],
	Ebonyi: ['Isieke', 'Ezza-Ohu', 'Effium', 'Afikpo', 'Abakaliki'],
	Edirne: ['Uzunköprü', 'Keşan', 'Edirne'],
	Edo: ['Uromi', 'Ubiaja', 'Ekpoma', 'Benin City', 'Auchi'],
	Ehime: ['Niihama', 'Iyo', 'Uwajima', 'Saijō', 'Ōzu', 'Matsuyama', 'Masaki-chō', 'Kawanoechō', 'Hōjō', 'Yawatahama'],
	Ekiti: [
		'Oke Ila',
		'Ise-Ekiti',
		'Ipoti',
		'Ikere-Ekiti',
		'Ijero-Ekiti',
		'Igede-Ekiti',
		'Igbara-Odo',
		'Emure-Ekiti',
		'Efon-Alaaye',
		'Aramoko-Ekiti',
		'Ado-Ekiti',
	],
	'El Bayadh': ['El Bayadh', 'El Abiodh Sidi Cheikh', 'Brezina'],
	'El Menia': ['El Meniaa'],
	'El Mghair': ['Sidi Amrane', 'El Meghaier', 'Djamaa'],
	'El Oro': ['Santa Rosa', 'Piñas', 'Pasaje', 'Machala', 'Huaquillas'],
	'El Oued': ['Robbah', 'Reguiba', 'El Oued', 'Debila'],
	'El Paraíso Department': ['El Paraíso', 'Danlí'],
	'El Progreso': ['Sanarate', 'Guastatoya'],
	'El Seíbo': ['Santa Cruz de El Seibo'],
	'El Tarf': ['El Tarf', 'El Kala', 'Drean', 'Besbes', 'Ben Mehidi'],
	Elazığ: ['Kovancılar', 'Karakoçan', 'Elazığ', 'Baskil'],
	'Elbasan County': ['Elbasan'],
	'Elegeyo-Marakwet': ['Iten'],
	Embu: ['Embu'],
	'Emilia-Romagna': [
		'Zola Predosa',
		'Vignola',
		'Scandiano',
		'Sassuolo',
		'San Lazzaro',
		'San Giovanni in Persiceto',
		'Salsomaggiore Terme',
		'Rimini',
		'Riccione',
		"Reggio nell'Emilia",
		'Ravenna',
		'Piacenza',
		'Parma',
		'Modena',
		'Mirandola',
		'Lugo',
		'Imola',
		'Formigine',
		'Forlì',
		'Fiorano',
		'Fidenza',
		'Ferrara',
		'Faenza',
		'Correggio',
		'Cesenatico',
		'Cesena',
		'Cervia',
		'Cento',
		'Cattolica',
		'Castel Maggiore',
		'Castelfranco Emilia',
		'Casalecchio di Reno',
		'Carpi Centro',
		'Bologna',
		'Bellaria-Igea Marina',
	],
	England: [
		'York',
		'Yeovil',
		'Yeadon',
		'Yate',
		'Yarm',
		'Worthing',
		'Worksop',
		'Workington',
		'Worcester Park',
		'Worcester',
		'Wood Green',
		'Woodford Green',
		'Wombwell',
		'Wolverhampton',
		'Wokingham',
		'Woking',
		'Witney',
		'Witham',
		'Wisbech',
		'Winsford',
		'Windsor',
		'Winchester',
		'Wimbledon',
		'Wilmslow',
		'Willesden',
		'Willenhall',
		'Wigston Magna',
		'Wigan',
		'Widnes',
		'Wickford',
		'Whitstable',
		'Whitley Bay',
		'Whitehaven',
		'Whitefield',
		'Whickham',
		'Weymouth',
		'Weybridge',
		'Weston-super-Mare',
		'West Molesey',
		'City of Westminster',
		'Westhoughton',
		'West Ham',
		'Westbury',
		'West Bromwich',
		'West Bridgford',
		'Wembley',
		'Welwyn Garden City',
		'Wellington',
		'Wellingborough',
		'Welling',
		'Wednesfield',
		'Wednesbury',
		'Wath upon Dearne',
		'Watford',
		'Waterlooville',
		'Washington',
		'Warwick',
		'Warrington',
		'Warminster',
		'Ware',
		'Wantage',
		'Walton-on-Thames',
		'Walton-on-the-Naze',
		'Walthamstow',
		'Waltham Abbey',
		'Walsall',
		'Wallsend',
		'Wallington',
		'Wallasey',
		'Walkden',
		'Wakefield',
		'Uxbridge',
		'Urmston',
		'Upper Norwood',
		'Uckfield',
		'Tynemouth',
		'Tyldesley',
		'Truro',
		'Trowbridge',
		'Totton',
		'Totteridge',
		'Tottenham',
		'Torquay',
		'Tonbridge',
		'Tiverton',
		'Tipton',
		'Thorne',
		'Thornaby-on-Tees',
		'Thetford',
		'Thatcham',
		'Tewkesbury',
		'Teignmouth',
		'Taunton',
		'Tamworth',
		'Tadley',
		'Swinton',
		'Swindon',
		'Swanscombe',
		'Swanley',
		'Swadlincote',
		'Sutton in Ashfield',
		'Sutton Coldfield',
		'Sutton',
		'Sunderland',
		'Sunbury-on-Thames',
		'Sudbury',
		'Stroud',
		'Strood',
		'Stretford',
		'Streatham',
		'Stratford-upon-Avon',
		'Stratford',
		'Stowmarket',
		'Stourport-on-Severn',
		'Stourbridge',
		'Stoke-on-Trent',
		'Stockton-on-Tees',
		'Stockport',
		'Stevenage',
		'Stepney',
		'Staveley',
		'Stanford-le-Hope',
		'Stamford',
		'Stalybridge',
		'Staines',
		'Stafford',
		'Spennymoor',
		'Spalding',
		'South Shields',
		'Southsea',
		'Southport',
		'South Ockendon',
		'South Norwood',
		'South Hayling',
		'Southend-on-Sea',
		'South Elmsall',
		'South Benfleet',
		'Southampton',
		'Southall',
		'Solihull',
		'Slough',
		'Sleaford',
		'Skelmersdale',
		'Skegness',
		'Sittingbourne',
		'Shrewsbury',
		'Shoreham-by-Sea',
		'Shipley',
		'Shepherds Bush',
		'Sheffield',
		'Sevenoaks',
		'Selby',
		'Seaham',
		'Seaford',
		'Scunthorpe',
		'Scarborough',
		'Sandown',
		'Sandhurst',
		'Sandbach',
		'Saltash',
		'Salisbury',
		'Salford',
		'Sale',
		'Saint Peters',
		'Saint Neots',
		'St Helens',
		'St Austell',
		'St Albans',
		'Saffron Walden',
		'Ryton',
		'Ryde',
		'Rushden',
		'Runcorn',
		'Ruislip',
		'Rugeley',
		'Rugby',
		'Royton',
		'Royston',
		'Royal Tunbridge Wells',
		'Rottingdean',
		'Rotherham',
		'Romsey',
		'Romford',
		'Roehampton',
		'Rochford',
		'Rochester',
		'Rochdale',
		'Ripon',
		'Ripley',
		'Richmond',
		'Reigate',
		'Redruth',
		'Redhill',
		'Redditch',
		'Redcar',
		'Reading',
		'Rayleigh',
		'Rawtenstall',
		'Rawmarsh',
		'Ramsgate',
		'Ramsbottom',
		'Putney',
		'Purley',
		'Pudsey',
		'Prestwich',
		'Preston',
		'Prescot',
		'Poulton-le-Fylde',
		'Potters Bar',
		'Portsmouth',
		'Portslade',
		'Portishead',
		'Poole',
		'Pontefract',
		'Ponders End',
		'Plymstock',
		'Plymouth',
		'Plumstead',
		'Pitsea',
		'Pinner',
		'Peterlee',
		'Peterborough',
		'Penzance',
		'Penrith',
		'Palmers Green',
		'Paignton',
		'Oxford',
		'Oswestry',
		'Ossett',
		'Orpington',
		'Ormskirk',
		'Oldham',
		'Oadby',
		'Nuneaton',
		'Nottingham',
		'Norwich',
		'Northwich',
		'North Shields',
		'Northolt',
		'Lancing',
		'Northampton',
		'Northallerton',
		'Norbury',
		'Newton-le-Willows',
		'Newton Aycliffe',
		'Newton Abbot',
		'Newquay',
		'Newport Pagnell',
		'Newport',
		'New Milton',
		'New Mills',
		'Newmarket',
		'New Malden',
		'New Cross',
		'Newcastle upon Tyne',
		'Newcastle under Lyme',
		'Newbury',
		'Newburn',
		'Newark on Trent',
		'Neston',
		'Nelson',
		'Nantwich',
		'Nailsea',
		'Mountsorrel',
		'Morley',
		'Moreton',
		'Morecambe',
		'Morden',
		'Mitcham',
		'Mirfield',
		'Milton Keynes',
		'Middleton',
		'Middlesbrough',
		'Mexborough',
		'Melton Mowbray',
		'Melksham',
		'Marple',
		'Marlow',
		'Market Harborough',
		'Margate',
		'March',
		'Mansfield Woodhouse',
		'Mansfield',
		'Mangotsfield',
		'Manchester',
		'Maltby',
		'Maldon',
		'Maidstone',
		'Maidenhead',
		'Maghull',
		'Macclesfield',
		'Lymington',
		'Luton',
		'Lowestoft',
		'Louth',
		'Loughborough',
		'Longfield',
		'Long Eaton',
		'London',
		'Lofthouse',
		'Liverpool',
		'Littlehampton',
		'Litherland',
		'Lincoln',
		'Limehouse',
		'Lichfield',
		'Leyland',
		'Lewes',
		'Leighton Buzzard',
		'Leigh',
		'Leicester',
		'Leek',
		'Leeds',
		'Leatherhead',
		'Royal Leamington Spa',
		'Lancaster',
		'Kirkby in Ashfield',
		'Kirkby',
		'Kippax',
		'Kingswood',
		'Kingswinford',
		'Kingston upon Hull',
		"King's Lynn",
		'Kidsgrove',
		'Kidlington',
		'Kidderminster',
		'Keynsham',
		'Kettering',
		'Kenilworth',
		'Kendal',
		'Kempston',
		'Keighley',
		'Jarrow',
		'Islington',
		'Isleworth',
		'Irlam',
		'Ipswich',
		'Ilkeston',
		'Ilford',
		'Hythe',
		'Hyde',
		'Huyton',
		'Huntingdon',
		'Huddersfield',
		'Hucknall',
		'Hoyland Nether',
		'Hove',
		'Hounslow',
		'Houghton-Le-Spring',
		'Horwich',
		'Horsham',
		'Horsforth',
		'Horley',
		'Hook',
		'Hoddesdon',
		'Hitchin',
		'Hindley',
		'Hinckley',
		'High Wycombe',
		'Highbury',
		'Heysham',
		'Heswall',
		'Heston',
		'Hertford',
		'Herne Bay',
		'Hereford',
		'Hemel Hempstead',
		'Hebburn',
		'Heanor',
		'Hazel Grove',
		'Haywards Heath',
		'Hayes',
		'Hayes',
		'Haydock',
		'Haverhill',
		'Havant',
		'Hatfield',
		'Hastings',
		'Haslingden',
		'Harwich',
		'Hartley',
		'Hartlepool',
		'Harrow',
		'Harrogate',
		'Harpenden',
		'Harlow',
		'Harlesden',
		'Hanworth',
		'Hampton',
		'Halifax',
		'Halesowen',
		'Hale',
		'Hailsham',
		'Hadleigh',
		'Guiseley',
		'Guisborough',
		'Guildford',
		'Grove',
		'Grimsby',
		'Greenford',
		'Great Yarmouth',
		'Great Wyrley',
		'Great Sankey',
		'Great Malvern',
		'Grays',
		'Gravesend',
		'Grantham',
		'Gosport',
		'Gosforth',
		'Goole',
		'Golborne',
		'Godalming',
		'Gloucester',
		'Glossop',
		'Gillingham',
		'Gateshead',
		'Gainsborough',
		'Fylde',
		'Fulwood',
		'Fulham',
		'Frome',
		'Frinton-on-Sea',
		'Friern Barnet',
		'Formby',
		'Folkestone',
		'Fleetwood',
		'Fleet',
		'Finchley',
		'Feltham',
		'Felling',
		'Felixstowe',
		'Faversham',
		'Farnworth',
		'Farnham',
		'Farnborough',
		'Fareham',
		'Falmouth',
		'Exmouth',
		'Exeter',
		'Evesham',
		'Esher',
		'Epsom',
		'Enfield Lock',
		'Enfield Town',
		'Emsworth',
		'Ely',
		'Eltham',
		'Ellesmere Port Town',
		'Elland',
		'Egham',
		'Edmonton',
		'Eccles',
		'Eastwood',
		'Retford',
		'East Molesey',
		'Eastleigh',
		'East Grinstead',
		'East Dereham',
		'Eastbourne',
		'Durham',
		'Dunstable',
		'Dukinfield',
		'Dudley',
		'Droylsden',
		'Dronfield',
		'Droitwich',
		'Dover',
		'Dorking',
		'Dorchester',
		'Doncaster',
		'Dinnington',
		'Didcot',
		'Dewsbury',
		'Devizes',
		'Derby',
		'Denton',
		'Deal',
		'Daventry',
		'Darwen',
		'Darton',
		'Dartford',
		'Darlington',
		'Dagenham',
		'Croydon',
		'Crowthorne',
		'Crowborough',
		'Cricklewood',
		'Crewe',
		'Crawley',
		'Cramlington',
		'Cowley',
		'Cowes',
		'Coventry',
		'Coulsdon',
		'Corby',
		'Consett',
		'Conisbrough',
		'Congleton',
		'Colne',
		'Colchester',
		'Cobham',
		'Coalville',
		'Clevedon',
		'Cleethorpes',
		'Cleckheaton',
		'Clacton-on-Sea',
		'Cirencester',
		'Christchurch',
		'Chorley',
		'Chiswick',
		'Chislehurst',
		'Chipping Sodbury',
		'Chippenham',
		'Chingford',
		'Chichester',
		'Chester-le-Street',
		'Chesterfield',
		'Chester',
		'Chessington',
		'Cheshunt',
		'Chesham',
		'Cheltenham',
		'Chelsea',
		'Chelmsford',
		'Cheadle Hulme',
		'Chatham',
		'Chapeltown',
		'Chalfont Saint Peter',
		'Catford',
		'Caterham',
		'Castleford',
		'Carterton',
		'Carshalton',
		'Carlisle',
		'Canterbury',
		'Cannock',
		'Cambridge',
		'Camborne',
		'Camberley',
		'Calne',
		'Buxton',
		'Bushey',
		'Bury St Edmunds',
		'Bury',
		'Burton upon Trent',
		'Burntwood',
		'Burnley',
		'Burnham-on-Sea',
		'Burgess Hill',
		'Brownhills',
		'Brough',
		'Bromsgrove',
		'Broadstairs',
		'Brixham',
		'Bristol',
		'Brighton',
		'Brighouse',
		'Brierley Hill',
		'Bridlington',
		'Bridgwater',
		'Brentwood',
		'Brent',
		'Bredbury',
		'Bramhall',
		'Braintree',
		'Bradford',
		'Bracknell',
		'Bow',
		'Bournemouth',
		'Boston',
		'Borehamwood',
		'Bootle',
		'Bolton',
		'Bognor Regis',
		'Blyth',
		'Bloxwich',
		'Bletchley',
		'Blackwall',
		'Blackpool',
		'Blackburn',
		'Bishopstoke',
		'Bishops Stortford',
		'Bishop Auckland',
		'Birmingham',
		'Birkenhead',
		'Bingley',
		'Billingham',
		'Billericay',
		'Biggleswade',
		'Bideford',
		'Biddulph',
		'Bicester',
		'Bexley',
		'Bexhill-on-Sea',
		'Beverley',
		'Berkhamsted',
		'Bentley',
		'Belper',
		'Bedworth',
		'Bedlington',
		'Bedford',
		'Beckenham',
		'Bebington',
		'Batley',
		'Bath',
		'Basingstoke',
		'Basildon',
		'Basford',
		'Barrow in Furness',
		'Barnstaple',
		'Barnsley',
		'Barnet',
		'Barnes',
		'Barking',
		'Banstead',
		'Banbury',
		'Baildon',
		'Aylesbury',
		'Atherton',
		'Aston',
		'Ashton-under-Lyne',
		'Ashton in Makerfield',
		'Ashington',
		'Ashford',
		'Ashford',
		'Ascot',
		'Arnold',
		'Andover',
		'Ampthill',
		'Amersham',
		'Altrincham',
		'Alton',
		'Alfreton',
		'Aldridge',
		'Aldershot',
		'Adwick le Street',
		'Acton',
		'Acocks Green',
		'Accrington',
		'Abingdon',
		'Crosby',
		'Neston',
		'Camden Town',
		'Telford',
		'Soho',
		'Bayswater',
		'Yateley',
		'Bowthorpe',
		'Hedge End',
		'Gerrards Cross',
		'Hale',
		'Kilburn',
		'Chalk Farm',
		'Amersham on the Hill',
		'Shadwell',
		'Battersea',
		'Tooting',
		'South Croydon',
		'Hornchurch',
		'Surbiton',
		'Ewell',
		'Becontree',
		'Brixton',
		'Bethnal Green',
		'Failsworth',
		'Radcliffe',
		'Heywood',
		'Longsight',
		'Heavitree',
		'Ferndown',
		'Canary Wharf',
		'Leytonstone',
		'Earl Shilton',
		'Bordon',
		'Lytham St Annes',
		'Hadley Wood',
		'Muswell Hill',
		'Chapel Allerton',
		'Blackheath',
		'Kempston Hardwick',
		'Mendip',
		'Lower Earley',
		'Bartley Green',
		'Golders Green',
		'Canvey Island',
		'Earlsfield',
		'Peacehaven',
		'Letchworth Garden City',
		'Peckham',
		'Shirley',
		'Stanley',
		'Rossendale',
		'Thornton-Cleveleys',
		'High Peak',
		'Hayling Island',
		'Holloway',
		'Harringay',
		'Canning Town',
		'Manor Park',
		'Thamesmead',
		'Brockley',
		'Stamford Hill',
		'Burngreave',
		'Archway',
		'Sinfin',
		'Perry Vale',
		'Furzedown',
		'Seven Sisters',
		'Tottenham Hale',
		'Washwood Heath',
	],
	'English River': ['Victoria'],
	'Ennedi-Ouest': ['Fada'],
	'Entre Rios': [
		'Villaguay',
		'Santa Elena',
		'La Paz',
		'Gualeguaychú',
		'Gualeguay',
		'Federal',
		'Concordia',
		'Concepción del Uruguay',
		'Chajarí',
		'Victoria',
		'Paraná',
		'Diamante',
		'Crespo',
	],
	Enugu: ['Nsukka', 'Enugu-Ezike', 'Enugu', 'Eha Amufu', 'Awgu', 'Aku'],
	Epirus: ['Préveza', 'Ioánnina', 'Árta'],
	Erongo: ['Swakopmund', 'Walvis Bay'],
	Erzincan: ['Cimin', 'Erzincan'],
	Erzurum: ['Pasinler', 'Karaçoban', 'Hınıs', 'Erzurum', 'Aşkale', 'Oltu', 'Horasan'],
	'Escaldes-Engordany': ['les Escaldes'],
	'Esch-sur-Alzette': ['Esch-sur-Alzette', 'Dudelange'],
	Escuintla: ['Santa Lucía Cotzumalguapa', 'Puerto San José', 'Tiquisate', 'Palín', 'Masagua', 'La Gomera', 'Escuintla'],
	Eskişehir: ['Eskişehir'],
	Esmeraldas: ['San Lorenzo de Esmeraldas', 'Rosa Zarate', 'Esmeraldas'],
	Espaillat: ['Moca'],
	'Espírito Santo': [
		'Vitória',
		'Vila Velha',
		'Viana',
		'Serra',
		'São Mateus',
		'Piúma',
		'Nova Venécia',
		'Marataizes',
		'Linhares',
		'Itapemirim',
		'Guarapari',
		'Guaçuí',
		'Conceição da Barra',
		'Colatina',
		'Castelo',
		'Cachoeiro de Itapemirim',
		'Barra de São Francisco',
		'Baixo Guandu',
		'Aracruz',
		'Alegre',
	],
	Est: ['Gayéri', "Fada N'gourma", 'Diapaga', 'Bogandé'],
	'Estelí Department': ['Estelí'],
	Estuaire: ['Libreville'],
	Extremadura: [
		'Zafra',
		'Villanueva de la Serena',
		'Navalmoral de la Mata',
		'Montijo',
		'Mérida',
		'Don Benito',
		'Cáceres',
		'Badajoz',
		'Almendralejo',
		'Plasencia',
	],
	FCT: ['Kuje', 'Abuja'],
	Faiyum: ['Ţāmiyah', 'Sinnūris', 'Iţsā', 'Ibshawāy', 'Al Wāsiţah', 'Al Fayyūm'],
	Fajardo: ['Fajardo'],
	Falcón: [
		'Tucacas',
		'Punto Fijo',
		'Punta Cardón',
		'Puerto Cumarebo',
		'Pueblo Nuevo',
		'Mene de Mauroa',
		'La Vela de Coro',
		'Judibana',
		'Dabajuro',
		'Coro',
		'Santa Cruz de los Taques',
	],
	'Far North': ['Yagoua', 'Mora', 'Mokolo', 'Maroua', 'Kousséri', 'Kaélé', 'Bogo'],
	Farah: ['Farah'],
	Faranah: ['Kissidougou'],
	Faro: ['Tavira', 'Quarteira', 'Portimão', 'Olhão', 'Loulé', 'Laranjeiro', 'Lagos', 'Faro', 'Albufeira'],
	Fars: [
		'Surian',
		'Shiraz',
		'Nūrābād',
		'Neyrīz',
		'Mohr',
		'Marvdasht',
		'Kāzerūn',
		'Gerāsh',
		'Fīrūzābād',
		'Fasā',
		'Dārāb',
		'Akbarābād',
		'Abadeh',
		'Pāsārgād',
	],
	Faryab: ['Maymana', 'Andkhōy'],
	Fatick: ['Pourham', 'Guinguinéo'],
	'Federal District': ['Brasília', 'Planaltina'],
	'Federation of B&H': [
		'Zenica',
		'Visoko',
		'Velika Kladuša',
		'Tuzla',
		'Travnik',
		'Sarajevo',
		'Mostar',
		'Lukavac',
		'Gradačac',
		'Gračanica',
		'Goražde',
		'Cazin',
		'Bugojno',
		'Bosanska Krupa',
		'Bihać',
		'Konjic',
	],
	Fejér: ['Székesfehérvár', 'Dunaújváros'],
	Fergana: ['Yaypan', 'Toshloq', 'Qo‘qon', 'Marg‘ilon', 'Quvasoy', 'Kirguli', 'Fergana', 'Beshariq', 'Oltiariq', 'Quva'],
	Ferizaj: ['Ferizaj', 'Shtime'],
	'Fier County': ['Patos Fshat', 'Patos', 'Lushnjë', 'Fier-Çifçi', 'Fier'],
	'Fitovinany Region': ['Manakara', 'Ikongo', 'Amboanjo'],
	Flacq: ['Centre de Flacq', 'Bel Air Rivière Sèche'],
	Flanders: [
		'Zwijndrecht',
		'Zwevegem',
		'Zottegem',
		'Zonhoven',
		'Zolder',
		'Zemst',
		'Zele',
		'Zedelgem',
		'Zaventem',
		'Wondelgem',
		'Wilrijk',
		'Willebroek',
		'Wevelgem',
		'Wetteren',
		'Westerlo',
		'Wervik',
		'Waregem',
		'Vilvoorde',
		'Turnhout',
		'Torhout',
		'Tongeren',
		'Tienen',
		'Tielt',
		'Tessenderlo',
		'Tervuren',
		'Temse',
		'Stekene',
		'Stabroek',
		'Sint-Truiden',
		'Sint-Pieters-Leeuw',
		'Sint-Niklaas',
		'Sint-Kruis',
		'Sint-Katelijne-Waver',
		'Sint-Gillis-Waas',
		'Sint-Genesius-Rode',
		'Sint-Andries',
		'Sint-Amandsberg',
		'Schoten',
		'Schilde',
		'Rotselaar',
		'Ronse',
		'Roeselare',
		'Riemst',
		'Ranst',
		'Puurs',
		'Putte',
		'Poperinge',
		'Peer',
		'Overijse',
		'Oudenaarde',
		'Oostkamp',
		'Ostend',
		'Ninove',
		'Nijlen',
		'Neerpelt',
		'Mortsel',
		'Mol',
		'Middelkerke',
		'Merksem',
		'Merelbeke',
		'Menen',
		'Meise',
		'Mechelen-aan-de-Maas',
		'Mechelen',
		'Mariakerke',
		'Maldegem',
		'Maasmechelen',
		'Maaseik',
		'Lommel',
		'Lokeren',
		'Lochristi',
		'Lille',
		'Lier',
		'Leuven',
		'Lede',
		'Lebbeke',
		'Lanaken',
		'Kortrijk',
		'Kortenberg',
		'Kontich',
		'Koksijde',
		'Koersel',
		'Knokke-Heist',
		'Kessel-Lo',
		'Kasterlee',
		'Kapellen',
		'Kalmthout',
		'Izegem',
		'Ieper',
		'Houthalen',
		'Hoogstraten',
		'Hoboken',
		'Heverlee',
		'Heusden',
		'Herzele',
		'Herentals',
		'Herent',
		'Helchteren',
		'Heist-op-den-Berg',
		'Hasselt',
		'Harelbeke',
		'Hamme',
		'Halle',
		'Haaltert',
		'Grimbergen',
		'Geraardsbergen',
		'Gentbrugge',
		'Gent',
		'Genk',
		'Geel',
		'Evergem',
		'Ekeren',
		'Eeklo',
		'Edegem',
		'Duffel',
		'Dilbeek',
		'Diksmuide',
		'Diest',
		'Diepenbeek',
		'Deurne',
		'Destelbergen',
		'Dendermonde',
		'Denderleeuw',
		'Deinze',
		'Brugge',
		'Brasschaat',
		'Bornem',
		'Borgerhout',
		'Boom',
		'Blauwput',
		'Blankenberge',
		'Bilzen',
		'Beveren',
		'Bevere',
		'Beringen',
		'Berchem',
		'Beersel',
		'Beerse',
		'Balen',
		'Assebroek',
		'Asse',
		'Antwerpen',
		'Aarschot',
		'Aalter',
		'Aalst',
	],
	Flevoland: ['Zeewolde', 'Urk', 'Lelystad', 'Emmeloord', 'Dronten', 'Almere Stad'],
	'Flores Department': ['Trinidad'],
	Floreşti: ['Floreşti'],
	Florida: [
		'Allapattah',
		'Altamonte Springs',
		'Apopka',
		'Auburndale',
		'Aventura',
		'Bartow',
		'Bayonet Point',
		'Bayshore Gardens',
		'Belle Glade',
		'Bellview',
		'Bloomingdale',
		'Boca Del Mar',
		'Boca Raton',
		'Bonita Springs',
		'Boynton Beach',
		'Bradenton',
		'Brandon',
		'Brent',
		'Brownsville',
		'Buenaventura Lakes',
		'Cantonment',
		'Cape Coral',
		'Carol City',
		'Carrollwood',
		'Carrollwood Village',
		'Casselberry',
		'Citrus Park',
		'Clearwater',
		'Clermont',
		'Cocoa',
		'Coconut Creek',
		'Coconut Grove',
		'Cooper City',
		'Coral Gables',
		'Coral Springs',
		'Coral Terrace',
		'Country Walk',
		'Country Club',
		'Crestview',
		'Cutler',
		'Cutler Ridge',
		'Dania Beach',
		'Davie',
		'Daytona Beach',
		'DeLand',
		'DeBary',
		'Deerfield Beach',
		'Delray Beach',
		'Deltona',
		'Doral',
		'Dunedin',
		'East Lake',
		'East Naples',
		'East Pensacola Heights',
		'Edgewater',
		'Egypt Lake-Leto',
		'Ensley',
		'Estero',
		'Eustis',
		'Ferry Pass',
		'Flagami',
		'Fleming Island',
		'Florida Ridge',
		'Fort Lauderdale',
		'Fort Myers',
		'Fort Pierce',
		'Fort Walton Beach',
		'Fountainebleau',
		'Fruit Cove',
		'Gainesville',
		'Glenvar Heights',
		'Golden Gate',
		'Golden Glades',
		'Greenacres City',
		'Haines City',
		'Hallandale Beach',
		'Hialeah',
		'Hialeah Gardens',
		'Holiday',
		'Hollywood',
		'Homestead',
		'Immokalee',
		'Iona',
		'Ives Estates',
		'Jacksonville',
		'Jacksonville Beach',
		'Jasmine Estates',
		'Jupiter',
		'Kendale Lakes',
		'Kendall',
		'Key West',
		'Keystone',
		'Kissimmee',
		'Lake Butler',
		'Lake Magdalene',
		'Lake Mary',
		'Lake Wales',
		'Lake Worth',
		'Lake Worth Corridor',
		'Lakeland',
		'Lakeside',
		"Land O' Lakes",
		'Largo',
		'Lauderdale Lakes',
		'Lauderhill',
		'Lealman',
		'Leesburg',
		'Lehigh Acres',
		'Leisure City',
		'Lutz',
		'Lynn Haven',
		'Maitland',
		'Marco Island',
		'Margate',
		'Meadow Woods',
		'Melbourne',
		'Merritt Island',
		'Miami',
		'Miami Beach',
		'Miami Gardens',
		'Miami Lakes',
		'Miramar',
		'Myrtle Grove',
		'Naples',
		'Navarre',
		'New Port Richey',
		'New Smyrna Beach',
		'Norland',
		'North Fort Myers',
		'North Lauderdale',
		'North Miami',
		'North Miami Beach',
		'North Port',
		'Oak Ridge',
		'Oakland Park',
		'Ocala',
		'Ocoee',
		'Ojus',
		'Opa-locka',
		'Orlando',
		'Ormond Beach',
		'Oviedo',
		'Pace',
		'Palm Bay',
		'Palm Beach Gardens',
		'Palm City',
		'Palm Coast',
		'Palm Harbor',
		'Palm Springs',
		'Palm Valley',
		'Palmetto Bay',
		'Panama City',
		'Parkland',
		'Pembroke Pines',
		'Pensacola',
		'Pine Hills',
		'Pinecrest',
		'Pinellas Park',
		'Pinewood',
		'Plant City',
		'Plantation',
		'Poinciana',
		'Pompano Beach',
		'Ponte Vedra Beach',
		'Port Charlotte',
		'Port Orange',
		'Port Saint Lucie',
		'Princeton',
		'Punta Gorda',
		'Punta Gorda Isles',
		'Richmond West',
		'Riverview',
		'Riviera Beach',
		'Rockledge',
		'Royal Palm Beach',
		'Ruskin',
		'Safety Harbor',
		'Saint Cloud',
		'St. Petersburg',
		'San Carlos Park',
		'Sanford',
		'Santa Rosa Beach',
		'Sarasota',
		'Sebastian',
		'Seminole',
		'South Bradenton',
		'South Miami Heights',
		'Southchase',
		'Spring Hill',
		'Stuart',
		'Sun City Center',
		'Sunny Isles Beach',
		'Sunrise',
		'Sunset',
		'Sweetwater',
		'Tallahassee',
		'Tamarac',
		'Tamiami',
		'Tampa',
		'Tarpon Springs',
		'Tavares',
		'Temple Terrace',
		'The Crossings',
		'The Hammocks',
		'The Villages',
		'Three Lakes',
		'Titusville',
		"Town 'n' Country",
		'University Park',
		'Valrico',
		'Venice',
		'Vero Beach',
		'Wekiwa Springs',
		'Wellington',
		'Wesley Chapel',
		'Westchase',
		'West Hollywood',
		'West Little River',
		'West Melbourne',
		'West Palm Beach',
		'West Park',
		'West Pensacola',
		'West and East Lealman',
		'Westchester',
		'Weston',
		'Winter Garden',
		'Winter Haven',
		'Winter Park',
		'Winter Springs',
		'Wright',
		'Cutler Bay',
		'Alafaya',
		'East Lake-Orient Park',
		'Four Corners',
		'Greater Northdale',
		'Sandalfoot Cove',
		'University',
		'Vero Beach South',
		'Kendall West',
		'Palm River-Clair Mel',
		'Midway',
		'Oakleaf Plantation',
		'The Acreage',
		'Northdale',
		'St. Johns',
		'Florida',
	],
	Formosa: ['Pirané', 'Formosa'],
	'Francisco Morazán Department': ['Tegucigalpa'],
	Freeport: ['Lucaya', 'Freeport'],
	Fribourg: ['Fribourg', 'Bulle'],
	Friesland: ['Sneek', 'Leeuwarden', 'Heerenveen', 'Harlingen', 'Drachten'],
	'Friuli Venezia Giulia': ['Udine', 'Trieste', 'Sacile', 'Pordenone', 'Monfalcone', 'Gorizia', 'Cordenons'],
	Fujairah: ['Dibba Al-Fujairah', 'Dibba Al-Hisn', 'Al Fujairah City', 'Reef Al Fujairah City'],
	Fujian: [
		'Zhangzhou',
		'Ximeicun',
		'Xiamen',
		'Wenheng',
		'Fuding',
		'Tantou',
		'Songcheng',
		'Shima',
		'Shaowu',
		'Sanming',
		'Fuqing',
		'Quanzhou',
		'Jinjiang',
		'Putian',
		'Pucheng',
		'Nanping',
		'Luoyang',
		'Longyan',
		'Liancheng',
		'Ningde',
		'Jian’ou',
		'Jiangkou',
		'Fu’an',
		'Fuzhou',
		'Wuyishan',
	],
	Fukien: ['Jincheng'],
	Fukui: ['Tsuruga', 'Takefu', 'Sabae', 'Ono', 'Obama', 'Mikuni', 'Maruoka', 'Katsuyama', 'Fukui-shi', 'Sakai'],
	Fukuoka: [
		'Yukuhashi',
		'Yanagawa',
		'Umi',
		'Tanushimarumachi-toyoki',
		'Tagawa',
		'Tachiarai',
		'Shingū',
		'Setakamachi-takayanagi',
		'Sasaguri',
		'Ōmuta',
		'Ōkawa',
		'Nōgata',
		'Nakama',
		'Miyata',
		'Maebaru-chūō',
		'Kurume',
		'Koga',
		'Kitakyushu',
		'Kawasaki',
		'Kanda',
		'Iizuka',
		'Fukuoka',
		'Nishifukuma',
		'Chikushino-shi',
		'Amagi',
		'Ōnojō',
		'Dazaifu',
		'Buzen',
		'Sue',
	],
	Fukushima: [
		'Kitakata',
		'Yanagawamachi-saiwaichō',
		'Yabuki',
		'Sukagawa',
		'Nihommatsu',
		'Namie',
		'Motomiya',
		'Miharu',
		'Kōriyama',
		'Iwaki',
		'Ishikawa',
		'Hobaramachi',
		'Funehikimachi-funehiki',
		'Fukushima',
		'Date',
		'Minami-Sōma',
		'Sōma',
		'Shirakawa',
	],
	Funafuti: ['Funafuti'],
	'Fès-Meknès': ['Taza', 'Taounate', 'Tahla', 'Sefrou', 'Meknès', 'Ifrane', 'Guercif', 'Fès al Bali', 'Fès', 'El Hajeb', 'Azrou'],
	Füzuli: ['Fizuli'],
	Gaborone: ['Gaborone'],
	Gabrovo: ['Sevlievo', 'Gabrovo'],
	'Gabès Governorate': ['Gabès', 'El Hamma'],
	Gafsa: ['Gafsa', 'Ar Rudayyif', 'Metlaoui'],
	Galați: ['Tecuci', 'Galaţi'],
	Galguduud: ['Ceeldheer', 'Cabudwaaq', 'Balanbale', 'Dhabad'],
	Galicia: [
		'Viveiro',
		'Vilalba',
		'Vilagarcía de Arousa',
		'Vigo',
		'Tui',
		'Teo',
		'Santiago de Compostela',
		'Santa Uxía de Ribeira',
		'Sanxenxo',
		'Ribeira',
		'Redondela',
		'Ponteareas',
		'Poio',
		'Porriño',
		'Pontevedra',
		'Ourense',
		'Oleiros',
		'Nigrán',
		'Narón',
		'Monforte de Lemos',
		'Moaña',
		'Marín',
		'Lugo',
		'Lalín',
		'A Estrada',
		'A Coruña',
		'Ferrol',
		'Culleredo',
		'Carballo',
		'Cangas do Morrazo',
		'Cambre',
		'Boiro',
		'Arteixo',
	],
	Gambela: ['Gambēla'],
	'Gangwon-do': [
		'Neietsu',
		'Yanggu',
		'Wŏnju',
		'T’aebaek',
		'Sokcho',
		'Santyoku',
		'Kosong',
		'Gangneung',
		'Hwacheon',
		'Hongch’ŏn',
		'Chuncheon',
		'Jumunjin',
		'Donghae City',
	],
	Gansu: [
		'Jiuquan',
		'Laojunmiao',
		'Jiayuguan',
		'Dunhuang',
		'Lintan Chengguanzhen',
		'Zuitai',
		'Nima',
		'Zhouqu Chengguanzhen',
		'Zhongzhai',
		'Zhangye',
		'Zhangjiachuan',
		'Yunshan',
		'Yongxing',
		'Yongqing',
		'Yanguan',
		'Xingguo',
		'Xincheng',
		'Xiaochuan',
		'Wushan',
		'Wenxian Chengguanzhen',
		'Wangpu',
		'Waina',
		'Tianshui',
		'Simen',
		'Shawan',
		'Qiaotou',
		'Pingliang',
		'Pan’an',
		'Luomen',
		'Longshan',
		'Longlin',
		'Longcheng',
		'Lixin',
		'Lixian',
		'Linxia Chengguanzhen',
		'Wuwei',
		'Liangshui',
		'Lanzhou',
		'Labuleng',
		'Liulin',
		'Jinchang',
		'Jiaogong',
		'Jiangluo',
		'Huixian Chengguanzhen',
		'Hezuo',
		'Heba',
		'Hanyuan',
		'Gongmen',
		'Gangu Chengguanzhen',
		'Fujia',
		'Dingxi',
		'Tanchang Chengguanzhen',
		'Chengxian Chengguanzhen',
		'Bikou',
		'Beidao',
		'Baiyin',
		'Baituo',
		'Anyuan',
		'Anhua',
		'Anfu',
		'Baijiawan',
		'Baliwan',
		'Dashi',
		'Dazhuang',
		'Guochuan',
		'Jinshan',
		'Jutou',
		'Kuanchuan',
		'Liuping',
		'Qianhu',
		'Shiqiao',
		'Suhe',
		'Wangyao',
		'Wangyin',
		'Wenquan',
		'Xingfeng',
		'Xinglong',
		'Xinxing',
		'Yebao',
		'Yongping',
		'Zhongshan',
		'Baiguan',
		'Zhongba',
		'Baihe',
		'Puchi',
		'Maying',
		'Hanwang',
		'Majie',
		'Dianga',
		'Dongjiang',
		'Chengjiao',
		'Wuying',
		'Lianhua',
		'Weidian',
		'Shibao',
		'Luotang',
		'Yulong',
		'Paosha',
		'Xigaoshan',
		'Luoyu',
		'Shili',
		'Jiangxi',
		'Luhe',
		'Xiyu',
		'Shaoyu',
		'Hadapu Zhen',
		'Lichuan Zhen',
		'Tange',
		'Mali',
		'Shandan',
		'Gaolou',
		'Yuanyang',
		'Hualin',
		'Xiping',
		'Xichuan',
		'Longnan',
		'Jifeng',
		'Changdao',
		'Liufeng',
		'Xiejiawan',
		'Guojia',
		'Hongbao',
		'Dayang',
		'Maguan',
		'Muhe',
		'Qingyang',
	],
	Gao: ['Gao'],
	Garissa: ['Garissa'],
	'Gash-Barka': ['Barentu'],
	Gauteng: [
		'Roodepoort',
		'Westonaria',
		'Vereeniging',
		'Vanderbijlpark',
		'Tembisa',
		'Springs',
		'Soweto',
		'Randfontein',
		'Randburg',
		'Pretoria',
		'Nigel',
		'Mabopane',
		'Krugersdorp',
		'Johannesburg',
		'Heidelberg',
		'Edenvale',
		'Cullinan',
		'Carletonville',
		'Bronkhorstspruit',
		'Brakpan',
		'Boksburg',
		'Benoni',
		'Alberton',
		'Ekangala',
		'Midrand',
		'Centurion',
		'Midstream',
		'Diepsloot',
		'Riverlea',
	],
	Gaza: ['Xai-Xai', 'Macia', 'Chokwé', 'Chibuto'],
	'Gaza Strip': [
		'Rafaḩ',
		'An Nuşayrāt',
		'Khān Yūnis',
		'Jabālyā',
		'Gaza',
		'Dayr al Balaḩ',
		'Bayt Lāhyā',
		'Bayt Ḩānūn',
		'Banī Suhaylā',
		'Al Burayj',
		'‘Abasān al Kabīrah',
		'Al Qarārah',
		'Az Zuwāydah',
	],
	Gaziantep: ['Nizip', 'Gaziantep'],
	Gedo: ['Luuq', 'Baardheere', 'Beled Hawo'],
	Gegharkunik: ['Sevan', 'Gavar'],
	Geita: ['Uyovu', 'Ushirombo', 'Masumbwe', 'Katoro', 'Kasamwa', 'Geita', 'Chato', 'Buseresere'],
	Gelderland: [
		'Zutphen',
		'Zevenaar',
		'Zaltbommel',
		'Wisch',
		'Winterswijk',
		'Wijchen',
		'Wageningen',
		'Velp',
		'Tiel',
		'Putten',
		'Nunspeet',
		'Nijmegen',
		'Nijkerk',
		'Lindenholt',
		'Lichtenvoorde',
		'Harderwijk',
		'Groesbeek',
		'Geldermalsen',
		'Epe',
		'Elst',
		'Elburg',
		'Eibergen',
		'Ede',
		'Duiven',
		'Doetinchem',
		'Culemborg',
		'Brummen',
		'Beuningen',
		'Barneveld',
		'Arnhem',
		'Apeldoorn',
		'Aalten',
	],
	Geneva: ['Vernier', 'Onex', 'Meyrin', 'Genève', 'Carouge', 'Lancy'],
	'George Town': ['George Town'],
	Georgia: [
		'Acworth',
		'Albany',
		'Alpharetta',
		'Americus',
		'Athens',
		'Atlanta',
		'Augusta',
		'Belvedere Park',
		'Brookhaven',
		'Brunswick',
		'Calhoun',
		'Canton',
		'Carrollton',
		'Cartersville',
		'Chamblee',
		'Columbus',
		'Conyers',
		'Dalton',
		'Decatur',
		'Douglasville',
		'Dublin',
		'Duluth',
		'Dunwoody',
		'East Point',
		'Evans',
		'Fayetteville',
		'Forest Park',
		'Gainesville',
		'Griffin',
		'Hinesville',
		'Kennesaw',
		'Kingsland',
		'LaGrange',
		'Lawrenceville',
		'Lithia Springs',
		'Mableton',
		'Macon',
		'Marietta',
		'Martinez',
		'McDonough',
		'Milledgeville',
		'Newnan',
		'Norcross',
		'North Atlanta',
		'North Decatur',
		'North Druid Hills',
		'Peachtree City',
		'Peachtree Corners',
		'Perry',
		'Pooler',
		'Redan',
		'Riverdale',
		'Rome',
		'Roswell',
		'St. Marys',
		'Sandy Springs',
		'Savannah',
		'Smyrna',
		'Snellville',
		'Statesboro',
		'Stockbridge',
		'Sugar Hill',
		'Suwanee',
		'Thomasville',
		'Tifton',
		'Tucker',
		'Union City',
		'Valdosta',
		'Warner Robins',
		'Wilmington Island',
		'Winder',
		'Woodstock',
		'Milton',
		'Johns Creek',
		'Candler-McAfee',
		'Stonecrest',
		'South Fulton',
	],
	Gevgelija: ['Gevgelija'],
	Gharbia: ['Zefta', 'Ţanţā', 'Samannūd', 'Quţūr', 'Kafr az Zayyāt', 'Basyūn', 'Al Maḩallah al Kubrá'],
	Ghardaia: ['Metlili Chaamba', 'Ghardaïa', 'Berriane'],
	Ghazni: ['Ghazni'],
	Ghowr: ['Shahrak'],
	Ghāt: ['Ghat'],
	'Gia Lai': ['Pleiku'],
	Gifu: [
		'Gero',
		'Toki',
		'Tarui',
		'Takayama',
		'Tajimi',
		'Ōgaki',
		'Nakatsugawa',
		'Mizunami',
		'Mitake',
		'Mino',
		'Kitagata',
		'Kasamatsuchō',
		'Kakamigahara',
		'Gōdo',
		'Gifu-shi',
		'Yamagata',
		'Hashima',
		'Kaizu',
		'Kani',
		'Minokamo',
		'Gujō',
		'Ena',
		'Mizuho',
	],
	'Gilbert Islands': ['Tarawa'],
	Giresun: ['Tirebolu', 'Şebin Karahisar', 'Görele', 'Giresun', 'Bulancak', 'Espiye'],
	Gisborne: ['Gisborne'],
	Gitega: ['Gitega'],
	Giurgiu: ['Giurgiu'],
	Giza: ['Madīnat Sittah Uktūbar', 'Kirdāsah', 'Awsīm', 'Aş Şaff', 'Giza', 'Al Ḩawāmidīyah', 'Al Bawīţī', 'Al ‘Ayyāţ'],
	Gjakova: ['Orahovac', 'Deçan', 'Gjakovë'],
	Gjilan: ['Vitina', 'Gjilan'],
	'Gjirokastër County': ['Gjirokastër'],
	Goa: ['Vasco da Gama', 'Taleigao', 'Sancoale', 'Ponda', 'Panaji', 'Mormugao', 'Māpuca', 'Madgaon', 'Dicholi', 'Curchorem', 'Cuncolim', 'Calangute'],
	Goiás: [
		'Uruaçu',
		'Trindade',
		'Senador Canedo',
		'São Miguel do Araguaia',
		'São Luís de Montes Belos',
		'Santa Helena de Goiás',
		'Rubiataba',
		'Rio Verde',
		'Quirinópolis',
		'Posse',
		'Porangatu',
		'Planaltina',
		'Pires do Rio',
		'Piracanjuba',
		'Padre Bernardo',
		'Niquelândia',
		'Nerópolis',
		'Morrinhos',
		'Mineiros',
		'Luziânia',
		'Jataí',
		'Jaraguá',
		'Itumbiara',
		'Itapuranga',
		'Itapaci',
		'Itaberaí',
		'Iporá',
		'Ipameri',
		'Inhumas',
		'Goiatuba',
		'Goiás',
		'Goianira',
		'Goiânia',
		'Goianésia',
		'Formosa',
		'Cristalina',
		'Ceres',
		'Catalão',
		'Campos Belos',
		'Caldas Novas',
		'Aragarças',
		'Anicuns',
		'Anápolis',
		'Aparecida de Goiânia',
		'Minaçu',
	],
	Golestan: ['Āzādshahr', 'Kalāleh', 'Gorgān', 'Gonbad-e Kāvūs'],
	Gombe: ['Pindiga', 'Nafada', 'Kumo', 'Gombe', 'Garko', 'Dukku', 'Deba', 'Biliri'],
	'Gomel Oblast': ['Zhlobin', 'Svyetlahorsk', 'Rahachow', 'Rechytsa', 'Mazyr', 'Kalinkavichy', "Homyel'", 'Dobrush'],
	Gorgol: ['Kaédi'],
	Gorj: ['Târgu Jiu', 'Motru'],
	'Gorno-Badakhshan': ['Khorugh', 'Ishqoshim'],
	Gorontalo: ['Gorontalo'],
	Gostivar: ['Gostivar'],
	Gotland: ['Visby'],
	'Govi-Altai Province': ['Altai'],
	'Goygol Rayon': ['Göygöl'],
	'Grad Skopje': ['Skopje', 'Saraj', 'Gjorče Petro', 'Šuto Orizare', 'Butel', 'Čair', 'Kisela Voda'],
	'Granada Department': ['Nandaime', 'Granada'],
	'Grand Bassa': ['Buchanan'],
	'Grand Est': [
		'Wittenheim',
		'Vitry-le-François',
		'Villers-lès-Nancy',
		'Verdun',
		'Vandœuvre-lès-Nancy',
		'Troyes',
		'Toul',
		'Thionville',
		'Strasbourg',
		'Sélestat',
		'Sedan',
		'Schiltigheim',
		'Sarreguemines',
		'Saint-Louis',
		'Saint-Dizier',
		'Saint-Dié-des-Vosges',
		'Saint-Avold',
		'Reims',
		'Pont-à-Mousson',
		'Nancy',
		'Mulhouse',
		'Montigny-lès-Metz',
		'Metz',
		'Lunéville',
		'Lingolsheim',
		'Laxou',
		'Illzach',
		'Illkirch-Graffenstaden',
		'Hayange',
		'Yutz',
		'Haguenau',
		'Forbach',
		'Épinal',
		'Épernay',
		'Colmar',
		'Chaumont',
		'Charleville-Mézières',
		'Châlons-en-Champagne',
		'Bischheim',
		'Bar-le-Duc',
	],
	'Grand Gedeh': ['Zwedru'],
	'Grand Port': ['Mahébourg'],
	'Grande Comore': ['Moroni'],
	GrandʼAnse: ['Jérémie'],
	'Granma Province': [
		'Yara',
		'Río Cauto',
		'Niquero',
		'Media Luna',
		'Manzanillo',
		'Jiguaní',
		'Guisa',
		'Cauto Cristo',
		'Campechuela',
		'Bayamo',
		'Bartolomé Masó',
	],
	'Greater Accra': ['Teshi Old Town', 'Tema', 'Nungua', 'Medina Estates', 'Gbawe', 'Dome', 'Atsiaman', 'Accra'],
	'Greater Poland': [
		'Złotów',
		'Września',
		'Wągrowiec',
		'Turek',
		'Trzcianka',
		'Szamotuły',
		'Swarzędz',
		'Środa Wielkopolska',
		'Śrem',
		'Rawicz',
		'Poznań',
		'Pleszew',
		'Piła',
		'Ostrów Wielkopolski',
		'Oborniki',
		'Nowy Tomyśl',
		'Luboń',
		'Leszno',
		'Krotoszyn',
		'Kościan',
		'Konin',
		'Koło',
		'Kalisz',
		'Jarocin',
		'Gostyń',
		'Gniezno',
		'Chodzież',
	],
	Grisons: ['Chur'],
	Grodnenskaya: ['Volkovysk', 'Smarhoń', 'Slonim', 'Shchuchyn', 'Novogrudok', 'Mosty', 'Lida', 'Hrodna'],
	Groningen: ['Winschoten', 'Veendam', 'Stadskanaal', 'Leek', 'Korrewegwijk', 'Hoogezand', 'Groningen', 'Delfzijl'],
	Guadeloupe: [
		'Sainte-Rose',
		'Sainte-Anne',
		'Pointe-à-Pitre',
		'Petit-Bourg',
		'Les Abymes',
		'Le Moule',
		'Le Gosier',
		'Capesterre-Belle-Eau',
		'Basse-Terre',
		'Baie-Mahault',
	],
	Guairá: ['Villarrica'],
	'Guanacaste Province': ['Nicoya', 'Liberia', 'Cañas'],
	Guanajuato: [
		'Yuriria',
		'Villagrán',
		'Valle de Santiago',
		'Uriangato',
		'Silao',
		'San Miguel de Allende',
		'San Luis de la Paz',
		'San José Iturbide',
		'San Francisco del Rincón',
		'San Felipe',
		'Salvatierra',
		'Salamanca',
		'Romita',
		'Purísima de Bustos',
		'Pénjamo',
		'Moroleón',
		'Medina',
		'Marfil',
		'León de los Aldama',
		'Juventino Rosas',
		'Jaral del Progreso',
		'Irapuato',
		'Guanajuato',
		'Cortazar',
		'Comonfort',
		'Celaya',
		'Apaseo el Grande',
		'Apaseo el Alto',
		'Acámbaro',
		'Abasolo',
		'Dolores Hidalgo',
		'Centro Familiar la Soledad',
		'La Ermita',
	],
	Guangdong: [
		'Zhaoqing',
		'Zhanjiang',
		'Yunfu',
		'Heyuan',
		'Xucheng',
		'Xiongzhou',
		'Sanshui',
		'Zhuhai',
		'Tangping',
		'Taishan',
		'Shuizhai',
		'Shuikou',
		'Shiwan',
		'Shiqiao',
		'Shiqi',
		'Shilong',
		'Shenzhen',
		'Shaping',
		'Shaoguan',
		'Shanwei',
		'Shantou',
		'Jieyang',
		'Qingyuan',
		'Pingshan',
		'Nanfeng',
		'Meizhou',
		'Wuchuan',
		'Maoming',
		'Maba',
		'Luoyang',
		'Lubu',
		'Puning',
		'Licheng',
		'Lianzhou',
		'Lianjiang',
		'Lecheng',
		'Jieshi',
		'Jiazi',
		'Jiangmen',
		'Yangjiang',
		'Humen',
		'Huizhou',
		'Huicheng',
		'Huazhou',
		'Dasha',
		'Huanggang',
		'Huaicheng',
		'Huacheng',
		'Hepo',
		'Hengbei',
		'Haimen',
		'Guangzhou',
		'Gaozhou',
		'Foshan',
		'Encheng',
		'Ducheng',
		'Xinyi',
		'Donghai',
		'Dongguan',
		'Danshui',
		'Daliang',
		'Yangchun',
		'Chenghua',
		'Chaozhou',
		'Anliu',
		'Anbu',
		'Hedong',
		'Zhongshan',
		'Shixing',
		'Xingning',
	],
	Guangxi: [
		'Yulin',
		'Yashan',
		'Yangshuo',
		'Xichang',
		'Wuzhou',
		'Wujia',
		'Shangsi',
		'Shiwan',
		'Shikang',
		'Shankou',
		'Shagang',
		'Qinzhou',
		'Yizhou',
		'Yongning',
		'Pingnan',
		'Nanning',
		'Nandu',
		'Naliang',
		'Luorong',
		'Liuzhou',
		'Beiliu',
		'Hepu',
		'Laibin',
		'Jinji',
		'Jiangping',
		'Guiping',
		'Guilin',
		'Guigang',
		'Gongguan',
		'Dongxing',
		'Dangjiang',
		'Changle',
		'Baise',
		'Beihai',
		'Baisha',
		'Baihecun',
		'Babu',
		'Hezhou',
		'Chongzuo',
		'Zhakou',
		'Fangchenggang',
		'Hechi',
		'Xingdaohu',
	],
	'Guantánamo Province': ['Río Guayabal de Yateras', 'Maisí', 'Guantánamo', 'Baracoa'],
	Guarda: ['Sequeira', 'Guarda', 'Custoias'],
	Guatemala: [
		'Villa Nueva',
		'Villa Canales',
		'Santa Catarina Pinula',
		'San Pedro Ayampuc',
		'San Juan Sacatepéquez',
		'San José Pinula',
		'Petapa',
		'Palencia',
		'Mixco',
		'Fraijanes',
		'Guatemala City',
		'Chinautla',
		'Amatitlán',
	],
	Guaviare: ['San José del Guaviare'],
	Guayama: ['Guayama'],
	Guayas: [
		'Yaguachi Nuevo',
		'Velasco Ibarra',
		'Samborondón',
		'Playas',
		'Pedro Carbo',
		'Naranjito',
		'Naranjal',
		'Milagro',
		'La Libertad',
		'Guayaquil',
		'El Triunfo',
		'Eloy Alfaro',
		'Daule',
		'Balzar',
	],
	Guaynabo: ['Guaynabo'],
	Guelma: ['Héliopolis', 'Guelma', 'Boumahra Ahmed'],
	'Guelmim-Oued Noun': ['Tan-Tan', 'Guelmim'],
	Guerrero: [
		'Zumpango del Río',
		'Tlapa de Comonfort',
		'Tixtla de Guerrero',
		'Teloloapan',
		'Taxco de Alarcón',
		'Ometepec',
		'Iguala de la Independencia',
		'Ciudad de Huitzuco',
		'Chilpancingo',
		'Chilapa de Álvarez',
		'Ayutla de los Libres',
		'Acapulco de Juárez',
		'Ixtapa-Zihuatanejo',
		'Técpan de Galeana',
		'Petatlán',
		'Ciudad Altamirano',
		'Atoyac de Álvarez',
		'Arcelia',
	],
	Guidimaka: ['Sélibaby'],
	Guizhou: [
		'Zunyi',
		'Zhouxi',
		'Zhongchao',
		'Zhelou',
		'Xingren',
		'Xiasi',
		'Xiaoweizhai',
		'Wanshui',
		'Loushanguan',
		'Tongren',
		'Shuikou',
		'Shuangjiang',
		'Panghai',
		'Lushan',
		'Long’e',
		'Longchang',
		'Kaili',
		'Xingyi',
		'Hongzhou',
		'Guiyang',
		'Duyun',
		'Dushan',
		'Liping',
		'Dafengdong',
		'Weining',
		'Bijie',
		'Anshun',
		'Anlong',
		'Kaitang',
		'Bibo',
		'Jiuchao',
		'Zhaoxing',
		'Liupanshui',
		'Liuzhi',
		'Shangchong',
		'Sankeshu',
		'Guiyang, Guizhou',
	],
	Gujarat: [
		'Wānkāner',
		'Vyāra',
		'Visnagar',
		'Vīsāvadar',
		'Vijāpur',
		'Verāval',
		'Vejalpur',
		'Vasa',
		'Valsād',
		'Vallabh Vidyanagar',
		'Valabhīpur',
		'Vadodara',
		'Vadnagar',
		'Upleta',
		'Unjha',
		'Una',
		'Un',
		'Umreth',
		'Thāsra',
		'Tharād',
		'Thān',
		'Talāja',
		'Surendranagar',
		'Surat',
		'Songadh',
		'Sojītra',
		'Sikka',
		'Sihor',
		'Siddhapur',
		'Sāyla',
		'Sarkhej',
		'Sānand',
		'Salāya',
		'Rāpar',
		'Rānāvāv',
		'Rājula',
		'Rājpīpla',
		'Rājkot',
		'Rādhanpur',
		'Porbandar',
		'Petlād',
		'Pātan',
		'Pārdi',
		'Pālitāna',
		'Pālanpur',
		'Padra',
		'Okha',
		'Navsari',
		'Nadiād',
		'Mundra',
		'Morwa',
		'Morbi',
		'Modāsa',
		'Mendarda',
		'Mānsa',
		'Māngrol',
		'Māndvi',
		'Māndvi',
		'Mānāvadar',
		'Mahudha',
		'Mahemdāvād',
		'Lūnāvāda',
		'Limbdi',
		'Lāthi',
		'Lālpur',
		'Kutiyāna',
		'Kundla',
		'Kosamba',
		'Kodīnar',
		'Kherālu',
		'Khedbrahma',
		'Kheda',
		'Khambhāt',
		'Khambhāliya',
		'Keshod',
		'Kāthor',
		'Karamsad',
		'Kapadvanj',
		'Kāndla',
		'Kālol',
		'Kālāvad',
		'Kadod',
		'Kadi',
		'Jūnāgadh',
		'Jodiya Bandar',
		'Jodhpur',
		'Jetpur',
		'Jasdan',
		'Jamnagar',
		'Jambusar',
		'Jalalpore',
		'Himatnagar',
		'Hārij',
		'Halvad',
		'Hālol',
		'Gondal',
		'Godhra',
		'Gariadhar',
		'Gandhinagar',
		'Gāndhīdhām',
		'Gandevi',
		'Gadhada',
		'Dwārka',
		'Dīsa',
		'Dhrol',
		'Dhrāngadhra',
		'Dhorāji',
		'Dholka',
		'Dhāri',
		'Dharampur',
		'Dhanera',
		'Dhandhuka',
		'Devgadh Bāriya',
		'Dāmnagar',
		'Dākor',
		'Dohad',
		'Dahegām',
		'Dabhoi',
		'Chotila',
		'Chhota Udepur',
		'Chhala',
		'Chānasma',
		'Chalāla',
		'Chaklāsi',
		'Botād',
		'Borsad',
		'Bilimora',
		'Bhuj',
		'Bhāyāvadar',
		'Bhavnagar',
		'Bharūch',
		'Bhānvad',
		'Bhachāu',
		'Bedi',
		'Bārdoli',
		'Bāntva',
		'Bagasra',
		'Bābra',
		'Ankleshwar',
		'Anjār',
		'Anand',
		'Amroli',
		'Amreli',
		'Amod',
		'Ahmedabad',
		'Vapi',
		'Abrama',
		'Savarkundla',
	],
	Gunma: [
		'Yoshii',
		'Tomioka',
		'Tatebayashi',
		'Tamamura',
		'Takasaki',
		'Shibukawa',
		'Sakai-nakajima',
		'Ōta',
		'Ōmamachō-ōmama',
		'Numata',
		'Nakanojōmachi',
		'Maebashi',
		'Kiryū',
		'Kanekomachi',
		'Isesaki',
		'Fujioka',
		'Annaka',
		'Midori',
	],
	Guyane: ['Saint-Laurent-du-Maroni', 'Rémire-Montjoly', 'Matoury', 'Kourou', 'Cayenne'],
	Guárico: [
		'Zaraza',
		'Valle de La Pascua',
		'Tucupido',
		'San Juan de los Morros',
		'El Sombrero',
		'Chaguaramas',
		'Camaguán',
		'Calabozo',
		'Altagracia de Orituco',
	],
	Guéra: ['Mongo', 'Bitkine'],
	Gwangju: ['Masan', 'Gwangju'],
	'Gyeonggi-do': [
		'Yeoju',
		"Yangp'yŏng",
		'Yangju',
		'Uijeongbu-si',
		'Suwon',
		'Bucheon-si',
		'Osan',
		'Munsan',
		'Gwangju',
		'Guri-si',
		'Gunpo',
		'Goyang-si',
		'Gapyeong',
		'Icheon-si',
		'Hwaseong-si',
		'Anyang-si',
		'Anseong',
		'Ansan-si',
		'Pubal',
		'Seongnam-si',
		'Hanam',
		'Hwado',
		'Namyangju',
		'Wabu',
		'Gwangmyeong',
	],
	'Gyeongsangbuk-do': [
		'Heunghae',
		'Yeonil',
		'Yeongju',
		'Eisen',
		'Waegwan',
		'Jenzan',
		'Sangju',
		'Pohang',
		'Mungyeong',
		'Gyeongsan-si',
		'Gyeongju',
		'Kunwi',
		'Gumi',
		'Gimcheon',
		'Hayang',
		'Cheongsong gun',
		'Andong',
	],
	'Gyeongsangnam-do': ['Yangsan', 'Miryang', 'Goseong', 'Kyosai', 'Kimhae', 'Chinju', 'Changwon', 'Sinhyeon', 'Ungsang', 'Naesŏ', 'Changnyeong'],
	'Győr-Moson-Sopron': ['Sopron', 'Mosonmagyaróvár', 'Győr'],
	Gävleborg: ['Sandviken', 'Hudiksvall', 'Gävle'],
	'Gôh-Djiboua': ['Oumé', 'Lakota', 'Guibéroua', 'Gagnoa', 'Divo'],
	Göyçay: ['Göyçay'],
	'Gümüşhane Province': ['Kelkit', 'Gümüşhane'],
	Găgăuzia: ['Comrat', 'Ceadîr-Lunga'],
	Gīlān: ['Pādegān-e Manjīl', 'Hashtpar', 'Rūdsar', 'Rasht', 'Manjīl', 'Langarūd', 'Fūman', 'Bandar-e Anzalī', 'Āstārā', 'Āstāneh-ye Ashrafīyeh'],
	Gǝncǝ: ['Ganja'],
	"Ha'il Region": ["Ha'il"],
	Hacıqabul: ['Hacıqabul'],
	'Hadjer-Lamis': ['Massakory', 'Massaguet'],
	Hagatna: ['Guam Government House', 'Hagåtña'],
	Haifa: [
		'Zikhron Ya‘aqov',
		'Umm el Faḥm',
		'Tirat Karmel',
		'Qiryat Yam',
		'Qiryat Tiv‘on',
		'Qiryat Motsqin',
		'Qiryat Bialik',
		'Qiryat Ata',
		'Or Akiva',
		'Nesher',
		'Kafr Qari‘',
		'Haifa',
		'Hadera',
		'Daliyat al Karmel',
		'Binyamina',
		'Bāqa el Gharbīya',
		'‘Ar‘ara',
		"Binyamina-Giv'at Ada",
	],
	Hainan: [
		'Yaxing',
		'Xiuying',
		'Xinzhou',
		'Wenchang',
		'Wanning',
		'Wangwu',
		'Sanya',
		'Qiongshan',
		'Paipu',
		'Nanfeng',
		'Nada',
		'Lingao',
		'Jinjiang',
		'Qionghai',
		'Haitou',
		'Haikou',
		'Eman',
		'Basuo',
		'Baimajing',
		'Zhonghe',
		'Dacheng',
		'Lanyang',
		'Guangcun',
		'Sandu',
		'Mutang',
		'Dongcheng',
		'Heqing',
		'Wuzhishan',
	],
	Haiphong: ['Haiphong', 'Cát Bà'],
	'Hajdú-Bihar': ['Püspökladány', 'Hajdúszoboszló', 'Hajdúnánás', 'Hajdúböszörmény', 'Debrecen', 'Balmazújváros'],
	Hakkâri: ['Yüksekova', 'Şemdinli', 'Hakkâri'],
	Halland: ['Varberg', 'Kungsbacka', 'Halmstad', 'Falkenberg'],
	Hama: ['Ţayyibat al Imām', 'Şūrān', 'As Salamīyah', 'Maşyāf', 'Kafr Zaytā', 'Ḩamāh', 'Ḩalfāyā'],
	Hamadān: ['Āzādshahr', 'Nahāvand', 'Malāyer', 'Hamadān', 'Bahār', 'Asadābād', 'Pasragad Branch'],
	Hambuk: ['Onsŏng', 'Ranam', 'Namyang', 'Musan-ŭp', 'Kyŏngsŏng', 'Kilju', 'Hoeryŏng', 'Chongjin', 'Aoji'],
	Hamburg: [
		'Winterhude',
		'Wilhelmsburg',
		'Volksdorf',
		'Stellingen',
		'Steilshoop',
		'Langenhorn',
		'Schnelsen',
		'Sasel',
		'Rotherbaum',
		'Rissen',
		'Rahlstedt',
		'Poppenbüttel',
		'Ottensen',
		'Othmarschen',
		'Osdorf',
		'Niendorf',
		'Neugraben-Fischbek',
		'Lurup',
		'Hummelsbüttel',
		'Horn',
		'Hausbruch',
		'Harvestehude',
		'Harburg',
		'Hamm',
		'Wandsbek',
		'Marienthal',
		'Hamburg-Mitte',
		'Eimsbüttel',
		'Altona',
		'Hamburg',
		'Eppendorf',
		'Eißendorf',
		'Eilbek',
		'Billstedt',
		'Alsterdorf',
		'St. Pauli',
		'Eidelstedt',
		'Bergedorf',
		'Barmbek-Nord',
		'Barmbek-Süd',
		'Farmsen-Berne',
		'Hamburg-Nord',
		'Altona-Altstadt',
		'Altona-Nord',
		'Dulsberg',
		'Hoheluft-Ost',
	],
	'Hamgyŏng-namdo': ['Samho-rodongjagu', 'Kowŏn-ŭp', 'Hŭngnam', 'Hamhŭng', 'Yuktae-dong', 'Yŏnggwang-ŭp', 'Iwŏn-ŭp', 'Hongwŏn'],
	'Hamilton city': ['Hamilton'],
	Hanoi: ['Xuân Mai', 'Sơn Tây', 'Hanoi', 'Hà Đông', 'Cổ Lóa', 'Quang Minh'],
	Harare: ['Harare', 'Epworth', 'Chitungwiza'],
	Harari: ['Harar'],
	Hardap: ['Rehoboth'],
	Harghita: ['Odorheiu Secuiesc', 'Miercurea-Ciuc', 'Gheorgheni'],
	Harjumaa: ['Tallinn', 'Nõmme', 'Maardu'],
	Haryana: [
		'Yamunānagar',
		'Bara Uchāna',
		'Tohāna',
		'Thānesar',
		'Tāoru',
		'Sonīpat',
		'Sohna',
		'Sirsa',
		'Shāhābād',
		'Samālkha',
		'Safidon',
		'Rohtak',
		'Rewāri',
		'Ratia',
		'Rānia',
		'Pūndri',
		'Pūnāhāna',
		'Pinjaur',
		'Pehowa',
		'Pataudi',
		'Pānīpat',
		'Palwal',
		'Nīlokheri',
		'Narwāna',
		'Nārnaund',
		'Nārnaul',
		'Narāyangarh',
		'Mahendragarh',
		'Maham',
		'Lādwa',
		'Kharkhauda',
		'Karnāl',
		'Kālānwāli',
		'Kalānaur',
		'Kaithal',
		'Jīnd',
		'Jhajjar',
		'Jagādhri',
		'Indri',
		'Hodal',
		'Hisar',
		'Hānsi',
		'Gurgaon',
		'Gorakhpur',
		'Gohāna',
		'Gharaunda',
		'Fīrozpur Jhirka',
		'Fatehābād',
		'Faridabad',
		'Ellenabad',
		'Dhāruhera',
		'Dabwāli',
		'Charkhi Dādri',
		'Bhiwāni',
		'Beri Khās',
		'Barwāla',
		'Bahādurgarh',
		'Āsandh',
		'Ambāla',
		'Panchkula',
	],
	Haskovo: ['Svilengrad', 'Haskovo', 'Harmanli', 'Dimitrovgrad'],
	Hatay: ['Erzin', 'Serinyol', 'Reyhanlı', 'Kırıkhan', 'İskenderun', 'Dörtyol', 'Belen', 'Antakya', 'Denizciler'],
	'Hato Mayor': ['Hato Mayor del Rey'],
	'Haut-Katanga': ['Lubumbashi', 'Likasi', 'Kipushi', 'Kambove'],
	'Haut-Lomami': ['Kamina', 'Bukama'],
	'Haut-Ogooué': ['Moanda', 'Franceville'],
	'Haut-Uele': ['Watsa', 'Wamba', 'Isiro'],
	'Haute-Kotto': ['Bria'],
	'Hauts-Bassins': ['Houndé', 'Bobo-Dioulasso'],
	'Hauts-de-France': [
		'Wattrelos',
		'Wasquehal',
		'Valenciennes',
		'Tourcoing',
		'Tergnier',
		'Soissons',
		'Sin-le-Noble',
		'Senlis',
		'Saint-Quentin',
		'Saint-Pol-sur-Mer',
		'Saint-Omer',
		'Saint-Amand-les-Eaux',
		'Roubaix',
		'Ronchin',
		'Outreau',
		'Noyon',
		'Nogent-sur-Oise',
		'Mons-en-Barœul',
		'Maubeuge',
		'Marcq-en-Barœul',
		'Loos',
		'Lomme',
		'Lille',
		'Liévin',
		'Lens',
		'Laon',
		'Lambersart',
		'La Madeleine',
		'Hénin-Beaumont',
		'Hem',
		'Hazebrouck',
		'Hautmont',
		'Haubourdin',
		'Halluin',
		'Grande-Synthe',
		'Faches-Thumesnil',
		'Dunkerque',
		'Douai',
		'Denain',
		'Croix',
		'Crépy-en-Valois',
		'Creil',
		'Coudekerque-Branche',
		'Compiègne',
		'Château-Thierry',
		'Carvin',
		'Cambrai',
		'Calais',
		'Bruay-la-Buissière',
		'Boulogne-sur-Mer',
		'Béthune',
		'Berck',
		'Berck-Plage',
		'Beauvais',
		'Avion',
		'Arras',
		'Armentières',
		'Amiens',
		'Abbeville',
		"Villeneuve-d'Ascq",
	],
	Havana: [
		'Santiago de las Vegas',
		'San Miguel del Padrón',
		'Regla',
		'Havana',
		'Guanabacoa',
		'Cerro',
		'Arroyo Naranjo',
		'Alamar',
		'Ciudad Camilo Cienfuegos',
		'Centro Habana',
		'La Habana Vieja',
		'Boyeros',
		'Diez de Octubre',
	],
	Hawaii: [
		'Kahului',
		'Kailua',
		'Kaneohe',
		'Kīhei',
		'Makakilo City',
		'Mililani Town',
		'Pearl City',
		'Wahiawā',
		'Wailuku',
		'Waipahu',
		'‘Ewa Gentry',
		'Hilo',
		'Honolulu',
		'Kapolei',
		'Makakilo',
		'Schofield Barracks',
		'East Honolulu',
	],
	Hawalli: ['Ḩawallī', 'Bayān', 'As Sālimīyah', 'Ar Rumaythīyah', 'Salwá'],
	"Hawke's Bay": ['Napier', 'Hastings'],
	Hebei: [
		'Zhengding',
		'Zhaogezhuang',
		'Huaiyang',
		'Xinji',
		'Xingtai',
		'Tangshan',
		'Tangjiazhuang',
		'Shijiazhuang',
		'Shahecheng',
		'Renqiu',
		'Qinhuangdao',
		'Pengcheng',
		'Nangong',
		'Luancheng',
		'Linxi',
		'Linshui',
		'Langfang',
		'Kuangshi',
		'Jizhou',
		'Tianchang',
		'Hengshui',
		'Hecun',
		'Handan',
		'Guye',
		'Fengrun',
		'Xinle',
		'Dingzhou',
		'Changli',
		'Cangzhou',
		'Botou',
		'Beiwangli',
		'Beisu',
		'Beidaihehaibin',
		'Baoding',
		'Zhangjiakou',
		'Xuanhua',
		'Shanhaiguan',
		'Chengde',
		'Songling',
		'Siwei',
	],
	Heilongjiang: [
		'Zhaozhou',
		'Zhaoyuan',
		'Zhaodong',
		'Youhao',
		'Yilan',
		'Yichun',
		'Xinqing',
		'Wuchang',
		'Wangkui',
		'Tieli',
		'Tailai',
		'Dorbod',
		'Tahe',
		'Suiling',
		'Suihua',
		'Suifenhe',
		'Shuangyashan',
		'Shuangcheng',
		'Shanhecun',
		'Shangzhi',
		'Fendou',
		'Qitaihe',
		'Qiqihar',
		'Qinggang',
		'Nianzishan',
		'Nenjiang',
		'Nehe',
		'Lianhe',
		'Mudanjiang',
		'Mishan',
		'Mingshui',
		'Longjiang',
		'Longfeng',
		'Linkou',
		'Lingdong',
		'Lanxi',
		'Langxiang',
		'Jixi',
		'Jidong',
		'Jiamusi',
		'Hulan Ergi',
		'Hulan',
		'Huanan',
		'Honggang',
		'Hengshan',
		'Heihe',
		'Hegang',
		'Harbin',
		'Hailun',
		'Hailin',
		'Gannan',
		'Fuyuan',
		'Fuyu',
		'Jixian',
		'Fujin',
		'Luobei',
		'Dongning',
		'Didao',
		'Daqing',
		'Chengzihe',
		'Chaihe',
		'Boli',
		'Bin Xian',
		'Bei’an',
		'Bayan',
		'Baoshan',
		'Baoqing',
		'Muling',
		'Baiquan',
		'Anda',
		'Acheng',
	],
	Helmand: ['Lashkar Gāh', 'Gereshk'],
	Henan: [
		'Zhumadian',
		'Shangqiu',
		'Zhoukou',
		'Zhenping Chengguanzhen',
		'Zhengzhou',
		'Anyang',
		'Yingchuan',
		'Yunyang',
		'Yima',
		'Yigou',
		'Xuchang',
		'Xixiang',
		'Hancheng',
		'Xinyang',
		'Xinxiang',
		'Wacheng Neighborhood',
		'Gongyi',
		'Xiangcheng Chengguanzhen',
		'Binhe',
		'Sanmenxia',
		'Runing',
		'Puyang Chengguanzhen',
		'Pingdingshan',
		'Nanyang',
		'Minggang',
		'Luoyang',
		'Luohe',
		'Kaifeng',
		'Jiyuan',
		'Jishui',
		'Jiaozuo',
		'Dingcheng',
		'Huaidian',
		'Hebi',
		'Fangcheng Chengguanzhen',
		'Dengzhou',
		'Songyang',
		'Daokou',
		'Xincheng',
		'Anxiang',
		'Puyang',
	],
	Hentiy: ['Undurkhaan'],
	Herat: ['Shīnḏanḏ', 'Kushk', 'Karukh', 'Herāt', 'Eslam Qaleh'],
	'Herceg Novi': ['Herceg Novi'],
	'Heredia Province': ['San Pablo', 'San Francisco', 'Mercedes Norte', 'Heredia'],
	'Hermanas Mirabal': ['Salcedo'],
	Hesse: [
		'Witzenhausen',
		'Wiesbaden',
		'Wetzlar',
		'Weiterstadt',
		'Viernheim',
		'Taunusstein',
		'Stadtallendorf',
		'Seligenstadt',
		'Schwalmstadt',
		'Schlüchtern',
		'Rüsselsheim',
		'Reinheim',
		'Pfungstadt',
		'Offenbach',
		'Oberursel',
		'Obertshausen',
		'Ober-Ramstadt',
		'Nidderau',
		'Nidda',
		'Neu-Isenburg',
		'Neu-Anspach',
		'Mühlheim am Main',
		'Michelstadt',
		'Marburg an der Lahn',
		'Maintal',
		'Limburg an der Lahn',
		'Langen',
		'Lampertheim',
		'Künzell',
		'Kronberg',
		'Korbach',
		'Königstein im Taunus',
		'Kirchhain',
		'Kelkheim',
		'Kassel',
		'Karben',
		'Idstein',
		'Hünfeld',
		'Hofheim am Taunus',
		'Hofgeismar',
		'Hochheim am Main',
		'Heusenstamm',
		'Herborn',
		'Heppenheim an der Bergstrasse',
		'Hattersheim',
		'Hanau am Main',
		'Haiger',
		'Groß-Umstadt',
		'Groß-Gerau',
		'Griesheim',
		'Ginsheim-Gustavsburg',
		'Gießen',
		'Gelnhausen',
		'Fulda',
		'Friedrichsdorf',
		'Friedberg',
		'Frankfurt am Main',
		'Frankenberg',
		'Flörsheim',
		'Eschwege',
		'Eschborn',
		'Eltville',
		'Dreieich',
		'Dotzheim',
		'Dillenburg',
		'Dietzenbach',
		'Dieburg',
		'Darmstadt',
		'Butzbach',
		'Bürstadt',
		'Büdingen',
		'Bruchköbel',
		'Biebrich',
		'Bensheim',
		'Baunatal',
		'Bad Wildungen',
		'Bad Vilbel',
		'Bad Soden am Taunus',
		'Bad Nauheim',
		'Bad Homburg vor der Höhe',
		'Bad Hersfeld',
		'Babenhausen',
		'Bad Arolsen',
		'Alsfeld',
		'Vellmar',
		'Mörfelden-Walldorf',
		'Riedstadt',
		'Rodgau',
		'Seeheim-Jugenheim',
		'Gallus',
		'Niederrad',
		'Kronberg Tal',
	],
	Heves: ['Eger', 'Parádsasvár', 'Hatvan', 'Gyöngyös'],
	Hhohho: ['Mbabane', 'Lobamba'],
	Hidalgo: [
		'Zacualtipán',
		'Tulancingo',
		'Tula de Allende',
		'Tizayuca',
		'Tepeji del Río de Ocampo',
		'Tepeapulco',
		'Santiago Tulantepec',
		'Progreso de Alvaro Obregon',
		'Pachuca de Soto',
		'Mixquiahuala de Juarez',
		'Ixmiquilpan',
		'Huejutla de Reyes',
		'Cuautepec de Hinojosa',
		'Ciudad Sahagun',
		'Apan',
		'Actopan',
		'Don Antonio',
		'La Providencia Siglo XXI',
	],
	Hiiraan: ['Buulobarde', 'Beledweyne'],
	'Himachal Pradesh': [
		'Una',
		'Sundarnagar',
		'Solan',
		'Shimla',
		'Pāonta Sāhib',
		'Nāhan',
		'Mandi',
		'Kulu',
		'Kālka',
		'Hamīrpur',
		'Dharamsala',
		'Chamba',
		'Baddi',
	],
	Hiroshima: [
		'Takehara',
		'Shōbara',
		'Shin’ichi',
		'Ōtake',
		'Onomichi',
		'Ōno-hara',
		'Miyoshi',
		'Mihara',
		'Kure',
		'Kannabechō-yahiro',
		'Innoshima',
		'Hiroshima',
		'Hatsukaichi',
		'Fukuyama',
		'Fuchūchō',
	],
	'Ho Chi Minh': ['Ho Chi Minh City', 'Nhà Bè', 'Củ Chi', 'Cần Giờ'],
	'Hodh Ech Chargi': ['Néma'],
	Hokkaido: [
		'Motomachi',
		'Wakkanai',
		'Tomakomai',
		'Tōbetsu',
		'Takikawa',
		'Sunagawa',
		'Shizunai-furukawachō',
		'Shiraoi',
		'Shimo-furano',
		'Minamishibetsuchō',
		'Shibetsu',
		'Sapporo',
		'Rumoi',
		'Otofuke',
		'Otaru',
		'Obihiro',
		'Nemuro',
		'Nayoro',
		'Honchō',
		'Nakashibetsu',
		'Muroran',
		'Mombetsu',
		'Yoichi',
		'Makubetsu',
		'Kushiro',
		'Kitami',
		'Kamiiso',
		'Iwamizawa',
		'Ishikari',
		'Kitahiroshima',
		'Hakodate',
		'Fukagawa',
		'Ebetsu',
		'Date',
		'Chitose',
		'Bibai',
		'Ashibetsu',
		'Asahikawa',
		'Abashiri',
		'Hokuto',
	],
	'Holguín Province': ['San Germán', 'Sagua de Tánamo', 'Moa', 'Jobabo', 'Holguín', 'Gibara', 'Cueto', 'Cacocum', 'Banes'],
	'Homa Bay': ['Homa Bay'],
	Homs: ['Tallkalakh', 'Tallbīsah', 'Tadmur', 'Kafr Lāhā', 'Homs', 'Ar Rastan', 'Al Quşayr', 'Al Qaryatayn'],
	Honiara: ['Honiara'],
	Hormozgan: ['Qeshm', 'Mīnāb', 'Kīsh', 'Ḩājjīābād', 'Bandar-e Lengeh', 'Bandar Abbas'],
	Houaphan: ['Xam Nua'],
	Hovd: ['Khovd'],
	Huambo: ['Longonjo', 'Huambo', 'Caála'],
	Huancavelica: ['Huancavelica'],
	Huanuco: ['Tingo María', 'Huánuco', 'Yanacancha'],
	Hubei: [
		'Zhicheng',
		'Zaoyang',
		'Yunmeng Chengguanzhen',
		'Qianjiang',
		'Zhongxiang',
		'Chengzhong',
		'Yicheng',
		'Yichang',
		'Yezhou',
		'Xiulin',
		'Xinzhou',
		'Xinshi',
		'Xindi',
		'Xihe',
		'Xiaogan',
		'Xiantao',
		'Xianning',
		'Xiangyang',
		'Wuxue',
		'Wuhan',
		'Suizhou',
		'Songbai',
		'Shiyan',
		'Qingquan',
		'Puqi',
		'Nanzhang Chengguanzhen',
		'Zhijiang',
		'Macheng',
		'Longfeng',
		'Lichuan',
		'Laohekou',
		'Jingzhou',
		'Jingmen',
		'Jingling',
		'Huangzhou',
		'Huangshi',
		'Huangpi',
		'Huangmei',
		'Hanchuan',
		'Gucheng Chengguanzhen',
		'Guangshui',
		'Fengkou',
		'Ezhou',
		'Enshi',
		'Duobao',
		'Daye',
		'Danjiangkou',
		'Caohe',
		'Caidian',
		'Buhe',
		'Anlu',
		'E’zhou',
		'Tuanbao',
		'Huanggang',
	],
	Huehuetenango: ['Soloma', 'Jacaltenango', 'Huehuetenango'],
	Huila: ['Tarqui', 'Rivera', 'Pitalito', 'Neiva', 'La Plata', 'Guadalupe', 'Gigante', 'Garzón', 'Campoalegre', 'Isnos'],
	Humacao: ['Humacao'],
	Hunan: [
		'Zhuzhou',
		'Wulingyuan',
		'Yongzhou',
		'Yongfeng',
		'Shangmei',
		'Wenxing',
		'Xiangxiang',
		'Xiangtan',
		'Changde',
		'Wugang',
		'Wuxi',
		'Qionghu',
		'Qingshuping',
		'Hongqiao',
		'Yutan',
		'Nanzhou',
		'Loudi',
		'Guankou',
		'Xishan',
		'Lengshuitan',
		'Lengshuijiang',
		'Leiyang',
		'Lianyuan',
		'Qianzhou',
		'Jinshi',
		'Huaihua',
		'Hongjiang',
		'Yiyang',
		'Hengyang',
		'Gantang',
		'Gantang',
		'Zhangjiajie',
		'Chenzhou',
		'Changsha',
		'Shaoyang',
		'Anxiang',
		'Anjiang',
		'Yueyang',
		'Bojia',
		'Santangpu',
		'Fenghuang',
	],
	Hunedoara: ['Vulcan', 'Petroşani', 'Petrila', 'Orăştie', 'Lupeni', 'Hunedoara', 'Deva', 'Brad'],
	Huíla: ['Lubango'],
	'Hwanghae-bukto': ['Songnim-ni', 'Sinmak', 'Sariwŏn', 'Hwangju-ŭp', 'Hŭkkyo-ri', 'Anak'],
	'Hwanghae-namdo': ['Yŏnan-ŭp', 'Ongjin', 'Kaesŏng', 'Haeju', 'Changyŏn', 'Chaeryŏng-ŭp', 'Ayang-ni'],
	Hyōgo: [
		'Akashi',
		'Yashiro',
		'Yamazakichō-nakabirose',
		'Yabu',
		'Toyooka',
		'Tatsunochō-tominaga',
		'Takarazuka',
		'Sumoto',
		'Shirahamachō-usazakiminami',
		'Sasayama',
		'Sandachō',
		'Ono',
		'Nishiwaki',
		'Nishinomiya-hama',
		'Miki',
		'Kobe',
		'Kawanishi',
		'Kamigōri',
		'Kakogawachō-honmachi',
		'Itami',
		'Himeji',
		'Fukura',
		'Ashiya',
		'Amagasaki',
		'Kariya',
		'Aioi',
		'Katō',
		'Kasai',
		'Minamiawaji',
		'Shisō',
		'Tanba',
	],
	'Hà Giang': ['Hà Giang'],
	'Hà Nam': ['Phủ Lý'],
	'Hà Tĩnh': ['Hà Tĩnh'],
	Hînceşti: ['Hînceşti'],
	'Hòa Bình': ['Hòa Bình'],
	'Hưng Yên': ['Hưng Yên'],
	'Hải Dương': ['Hải Dương', 'Chí Linh'],
	'Hậu Giang': ['Vị Thanh'],
	Ialomița: ['Urziceni', 'Fetești-Gară', 'Feteşti', 'Slobozia'],
	Iași: ['Iaşi', 'Paşcani'],
	Ibaraki: [
		'Yūki',
		'Shimodate',
		'Sakai',
		'Mitsukaidō',
		'Koga',
		'Kashima-shi',
		'Iwai',
		'Ishige',
		'Ushiku',
		'Tsukuba',
		'Toride',
		'Tomobe',
		'Takahagi',
		'Ryūgasaki',
		'Ōmiya',
		'Okunoya',
		'Ōarai',
		'Naka',
		'Moriya',
		'Mito',
		'Makabe',
		'Kitaibaraki',
		'Katsuta',
		'Kasama',
		'Iwase',
		'Itako',
		'Ishioka',
		'Hitachi',
		'Funaishikawa',
		'Fujishiro',
		'Edosaki',
		'Daigo',
		'Ami',
		'Hitachi-Naka',
		'Chikusei',
		'Hitachi-ota',
		'Inashiki',
	],
	'Ibb Governorate': ['Yarīm', 'Ibb', 'Dhī as Sufāl'],
	Ica: ['San Clemente', 'Pisco', 'Nazca', 'Minas de Marcona', 'Ica', 'Chincha Alta'],
	'Ida-Virumaa': ['Narva', 'Kohtla-Järve'],
	Idaho: [
		'Boise',
		'Caldwell',
		"Coeur d'Alene",
		'Eagle',
		'Idaho Falls',
		'Kuna',
		'Lewiston',
		'Lewiston Orchards',
		'Meridian',
		'Moscow',
		'Nampa',
		'Pocatello',
		'Post Falls',
		'Rexburg',
		'Twin Falls',
	],
	Idlib: ['Saraqib', 'Salqīn', 'Ma‘arratmişrīn', 'Khān Shaykhūn', 'Kafr Takhārīm', 'Kafranbel', 'Jisr ash Shughūr', 'Idlib', 'Binnish', 'Ad Dānā'],
	Ihorombe: ['Ihosy'],
	'Il-Mosta': ['Mosta'],
	'Ilam Province': ['Īlām', 'Dehlorān', 'Darreh Shahr', 'Ābdānān'],
	Ilfov: ['Voluntari', 'Popeşti-Leordeni', 'Pantelimon', 'Buftea'],
	Ilinden: ['Ilinden'],
	Illinois: [
		'Alton',
		'Belleville',
		'Carbondale',
		'Charleston',
		'Collinsville',
		'Decatur',
		'East Saint Louis',
		'Edwardsville',
		'Fairview Heights',
		'Godfrey',
		'Granite City',
		'Jacksonville',
		'Marion',
		'Mattoon',
		'Mount Vernon',
		"O'Fallon",
		'Quincy',
		'Springfield',
		'Upper Alton',
		'Glendale Heights',
		'Addison',
		'Albany Park',
		'Algonquin',
		'Alsip',
		'Arlington Heights',
		'Ashburn',
		'Aurora',
		'Avondale',
		'Bartlett',
		'Batavia',
		'Bellwood',
		'Belmont Cragin',
		'Belvidere',
		'Bensenville',
		'Berwyn',
		'Bloomingdale',
		'Bloomington',
		'Blue Island',
		'Bolingbrook',
		'Bourbonnais',
		'Bradley',
		'Bridgeport',
		'Bridgeview',
		'Brighton Park',
		'Brookfield',
		'Buffalo Grove',
		'Burbank',
		'Calumet City',
		'Carol Stream',
		'Carpentersville',
		'Cary',
		'Champaign',
		'Chatham',
		'Chicago',
		'Chicago Heights',
		'Chicago Lawn',
		'Cicero',
		'Country Club Hills',
		'Crest Hill',
		'Crystal Lake',
		'Danville',
		'Darien',
		'DeKalb',
		'Deerfield',
		'Des Plaines',
		'Dixon',
		'Dolton',
		'Douglas',
		'Downers Grove',
		'East Garfield Park',
		'East Moline',
		'East Peoria',
		'Edgewater',
		'Elgin',
		'Elk Grove Village',
		'Elmhurst',
		'Elmwood Park',
		'Englewood',
		'Evanston',
		'Evergreen Park',
		'Frankfort',
		'Franklin Park',
		'Freeport',
		'Gage Park',
		'Galesburg',
		'Geneva',
		'Glen Ellyn',
		'Glenview',
		'Goodings Grove',
		'Grand Boulevard',
		'Grayslake',
		'Gurnee',
		'Hanover Park',
		'Harvey',
		'Highland Park',
		'Hinsdale',
		'Hoffman Estates',
		'Homer Glen',
		'Homewood',
		'Huntley',
		'Hyde Park',
		'Irving Park',
		'Joliet',
		'Kankakee',
		'Kenwood',
		'La Grange',
		'Lake Forest',
		'Lake Zurich',
		'Lake in the Hills',
		'Lansing',
		'Lemont',
		'Libertyville',
		'Lincoln Park',
		'Lincoln Square',
		'Lisle',
		'Lockport',
		'Logan Square',
		'Lombard',
		'Loves Park',
		'Lower West Side',
		'Machesney Park',
		'Macomb',
		'Matteson',
		'Maywood',
		'McHenry',
		'McKinley Park',
		'Melrose Park',
		'Mokena',
		'Moline',
		'Montgomery',
		'Morgan Park',
		'Morton',
		'Morton Grove',
		'Mount Greenwood',
		'Mount Prospect',
		'Mundelein',
		'Naperville',
		'Near North Side',
		'Near South Side',
		'New City',
		'New Lenox',
		'Niles',
		'Normal',
		'North Aurora',
		'North Center',
		'North Chicago',
		'North Lawndale',
		'North Peoria',
		'Northbrook',
		'Oak Forest',
		'Oak Lawn',
		'Oak Park',
		'Orland Park',
		'Oswego',
		'Ottawa',
		'Palatine',
		'Palos Hills',
		'Park Forest',
		'Park Ridge',
		'Pekin',
		'Peoria',
		'Plainfield',
		'Portage Park',
		'Prospect Heights',
		'Rock Island',
		'Rockford',
		'Rogers Park',
		'Rolling Meadows',
		'Romeoville',
		'Roselle',
		'Round Lake',
		'Round Lake Beach',
		'St. Charles',
		'Schaumburg',
		'Shorewood',
		'Skokie',
		'South Chicago',
		'South Elgin',
		'South Holland',
		'South Lawndale',
		'South Shore',
		'Sterling',
		'Streamwood',
		'Sycamore',
		'Tinley Park',
		'Uptown',
		'Urbana',
		'Vernon Hills',
		'Villa Park',
		'Wasco',
		'Washington',
		'Waukegan',
		'West Chicago',
		'West Elsdon',
		'West Englewood',
		'West Garfield Park',
		'West Lawn',
		'West Ridge',
		'West Town',
		'Westchester',
		'Westmont',
		'Wheaton',
		'Wheeling',
		'Wilmette',
		'Woodlawn',
		'Woodridge',
		'Woodstock',
		'Yorkville',
		'Zion',
		'Chicago Loop',
		'Greater Grand Crossing',
		'Auburn Gresham',
	],
	Ilocos: [
		'Vigan',
		'Urdaneta',
		'Tagudin',
		'Santa Maria',
		'Santa Barbara',
		'San Nicolas',
		'San Fernando',
		'Mangaldan',
		'Manaoag',
		'Lingayen',
		'Laoag',
		'Guiset East',
		'Domalanoan',
		'Dagupan',
		'Calasiao',
		'Binmaley',
		'Bayambang',
		'Bauang',
		'Batac City',
		'Aringay',
		'Agoo',
	],
	Imbabura: ['Otavalo', 'Ibarra', 'Atuntaqui'],
	Imereti: ['Zest’aponi', 'Samtredia', 'Kutaisi', 'Ts’q’alt’ubo'],
	Imo: ['Owerri', 'Okigwe', 'Oguta', 'Nkwerre'],
	'Imārat Umm al Qaywayn': ['Umm Al Quwain City'],
	'In Salah': ['In Salah'],
	Incheon: ['Ganghwa-gun', 'Incheon'],
	Indiana: [
		'Avon',
		'Bloomington',
		'Broad Ripple',
		'Brownsburg',
		'Carmel',
		'Clarksville',
		'Columbus',
		'Evansville',
		'Fishers',
		'Greenfield',
		'Greenwood',
		'Indianapolis',
		'Jasper',
		'Jeffersonville',
		'Lawrence',
		'New Albany',
		'New Castle',
		'Plainfield',
		'Richmond',
		'Seymour',
		'Shelbyville',
		'Terre Haute',
		'Vincennes',
		'Zionsville',
		'Franklin',
		'Anderson',
		'Crawfordsville',
		'Crown Point',
		'Dyer',
		'East Chicago',
		'Elkhart',
		'Fort Wayne',
		'Frankfort',
		'Gary',
		'Goshen',
		'Granger',
		'Griffith',
		'Hammond',
		'Highland',
		'Hobart',
		'Huntington',
		'Kokomo',
		'LaPorte',
		'Lafayette',
		'Lebanon',
		'Logansport',
		'Marion',
		'Merrillville',
		'Michigan City',
		'Mishawaka',
		'Muncie',
		'Munster',
		'New Haven',
		'Noblesville',
		'Portage',
		'Schererville',
		'South Bend',
		'Valparaiso',
		'West Lafayette',
		'Westfield',
		'Fairfield Heights',
		'La Porte',
	],
	'Ingushetiya Republic': [
		'Troitskaya',
		'Surkhakhi',
		'Sunzha',
		'Nesterovskaya',
		'Nazran’',
		'Malgobek',
		'Karabulak',
		'Kantyshevo',
		'Ekazhevo',
		'Staryy Malgobek',
	],
	Inhambane: ['Maxixe', 'Inhambane'],
	'Inner Mongolia': [
		'Wuhai',
		'Wuda',
		'Ehen Hudag',
		'Dongsheng',
		'Bayan Hot',
		'Zhalantun',
		'Yakeshi',
		'Xilin Hot',
		'Ulanhot',
		'Tongliao',
		'Shiguai',
		'Salaqi',
		'Pingzhuang',
		'Mujiayingzi',
		'Manzhouli',
		'Jining',
		'Jalai Nur',
		'Jiagedaqi',
		'Hohhot',
		'Hailar',
		'Genhe',
		'Beichengqu',
		'Erenhot',
		'Dalain Hob',
		'Chifeng',
		'Baotou',
		'Oroqen Zizhiqi',
		'Ordos',
		'Bayan Nur',
	],
	Innlandet: ['Lillehammer', 'Kongsvinger', 'Hamar', 'Gjøvik'],
	'Ionian Islands': ['Corfu'],
	Iowa: [
		'Altoona',
		'Ames',
		'Ankeny',
		'Bettendorf',
		'Burlington',
		'Cedar Falls',
		'Cedar Rapids',
		'Clinton',
		'Clive',
		'Coralville',
		'Council Bluffs',
		'Davenport',
		'Des Moines',
		'Dubuque',
		'Fort Dodge',
		'Indianola',
		'Iowa City',
		'Johnston',
		'Marion',
		'Marshalltown',
		'Mason City',
		'Muscatine',
		'Newton',
		'North Liberty',
		'Ottumwa',
		'Sioux City',
		'Urbandale',
		'Waterloo',
		'Waukee',
		'West Des Moines',
	],
	Irbid: ['Kurayyimah', 'Judita', 'Irbid', 'Aydūn', 'Ar Ramthā'],
	Iringa: ['Mafinga', 'Izazi', 'Iringa', 'Ilula'],
	'Irkutsk Oblast': [
		'Tayshet',
		'Nizhneudinsk',
		'Chunskiy',
		'Zima',
		'Zheleznogorsk-Ilimskiy',
		'Vikhorevka',
		'Ust’-Kut',
		'Ust’-Ilimsk',
		'Usol’ye-Sibirskoye',
		'Tulun',
		'Slyudyanka',
		'Shelekhov',
		'Irkutsk',
		'Cheremkhovo',
		'Bodaybo',
		'Baykal’sk',
		'Angarsk',
		'Bratsk',
		'Sayansk',
		'Markova',
	],
	Isfahan: [
		'Semīrom',
		'Khvānsār',
		'Golpāyegān',
		'Ardestān',
		'Rehnān',
		'Qahderījān',
		'Najafābād',
		'Khomeynī Shahr',
		'Kelīshād va Sūdarjān',
		'Falāvarjān',
		'Isfahan',
		'Dowlatābād',
		'Dorcheh Pīāz',
		'Abrīsham',
	],
	Ishikawa: ['Tsurugi-asahimachi', 'Tsubata', 'Nonoichi', 'Nanao', 'Matsutō', 'Komatsu', 'Kanazawa', 'Hakui', 'Anamizu', 'Kaga'],
	Isiolo: ['Isiolo'],
	'Isla de la Juventud': ['Nueva Gerona'],
	Islamabad: ['Islamabad'],
	Islands: ['Tung Chung'],
	Ismailia: ['Ismailia'],
	Isparta: ['Yalvaç', 'Senirkent', 'Şarkîkaraağaç', 'Isparta', 'Eğirdir'],
	'Issyk-Kul': ['Balykchy', 'Kyzyl-Suu', 'Karakol'],
	Istanbul: [
		'Zeytinburnu',
		'Yakuplu',
		'Üsküdar',
		'Umraniye',
		'Tepecik',
		'Şişli',
		'Silivri',
		'Mimarsinan',
		'Maltepe',
		'Kavaklı',
		'Istanbul',
		'Gürpınar',
		'Esenyurt',
		'Esenler',
		'Eminönü',
		'Çatalca',
		'Bağcılar',
		'Arnavutköy',
		'Merter Keresteciler',
		'güngören merter',
		'Ataşehir',
		'Başakşehir',
		'Beylikdüzü',
		'Büyükçekmece',
		'Bahçelievler',
		'Sultangazi',
		'Sultanbeyli',
		'Sancaktepe',
	],
	Istria: ['Pula'],
	Itapúa: ['Encarnación'],
	Itasy: ['Soavinandriana', 'Arivonimamo'],
	Ituri: ['Bunia'],
	'Ivano-Frankivsk': ['Nadvirna', 'Kolomyia', 'Kalush', 'Ivano-Frankivsk', 'Dolyna'],
	'Ivanovo Oblast': ['Vichuga', 'Teykovo', 'Shuya', 'Rodniki', 'Privolzhsk', 'Kokhma', 'Kineshma', 'Ivanovo', 'Furmanov'],
	Iwate: [
		'Yamada',
		'Tōno',
		'Shizukuishi',
		'Ōfunato',
		'Morioka',
		'Mizusawa',
		'Miyako',
		'Kitakami',
		'Kanegasaki',
		'Kamaishi',
		'Ichinoseki',
		'Hanamaki',
		'Kuji',
		'Hachimantai',
		'Takizawa',
	],
	'Izabal Department': ['Puerto Barrios', 'Morales', 'Lívingston', 'El Estor'],
	Iğdır: ['Iğdır'],
	'Jabal al Gharbi': ['Yafran', 'Mizdah', 'Gharyan', 'Zintan'],
	Jakarta: ['Jakarta'],
	'Jalal-Abad': ['Tash-Kumyr', 'Suzak', 'Massy', 'Kochkor-Ata', 'Jalal-Abad', 'Bazar-Korgon', 'Ala-Buka', 'Toktogul'],
	Jalapa: ['San Luis Jilotepeque', 'Jalapa'],
	Jalilabad: ['Jalilabad'],
	Jalisco: [
		'Cihuatlán',
		'Zapotlanejo',
		'Zapotiltic',
		'Zapopan',
		'Zacoalco',
		'Villa Hidalgo',
		'Tuxpan',
		'Tonalá',
		'Tlaquepaque',
		'Tlajomulco de Zúñiga',
		'Tesistán',
		'Tequila',
		'Tepatitlán de Morelos',
		'Teocaltiche',
		'Tamazula de Gordiano',
		'Tala',
		'Sayula',
		'Santa Anita',
		'San Sebastián el Grande',
		'San Miguel el Alto',
		'San Juan de los Lagos',
		'San José del Castillo',
		'Nicolás R Casillas',
		'Puerto Vallarta',
		'Ocotlán',
		'Nuevo México',
		'Magdalena',
		'Las Pintas de Arriba',
		'Lagos de Moreno',
		'La Barca',
		'Jocotepec',
		'Jamay',
		'Jalostotitlán',
		'Ixtapa',
		'Guadalajara',
		'Encarnación de Díaz',
		'El Salto',
		'El Salto',
		'El Quince',
		'El Grullo',
		'Coyula',
		'Ciudad Guzmán',
		'Chapala',
		'Autlán de Navarro',
		'Atotonilco el Alto',
		'Arandas',
		'Ameca',
		'Ahualulco de Mercado',
		'San José del Valle',
		'Hacienda Santa Fe',
		'Las Pintitas',
		'Lomas del Sur',
	],
	Jambi: ['Sungai Penuh', 'Simpang', 'Muara Bungo', 'Mendaha', 'Kuala Tungkal', 'Jambi City'],
	'Jammu and Kashmir': [
		'Pūnch',
		'Udhampur',
		'Srinagar',
		'Sopur',
		'Sāmba',
		'Rajaori',
		'Pulwama',
		'Kulgam',
		'Kishtwār',
		'Kathua',
		'Jammu',
		'Gāndarbal',
		'Doda',
		'Bijbehara',
		'Bāramūla',
		'Bandipura',
		'Anantnag',
		'Soyībug',
	],
	'Jazan Region': ['Şāmitah', 'Şabyā', 'Jizan', 'Abū ‘Arīsh'],
	'Jeju-do': ['Jeju City', 'Gaigeturi', 'Seogwipo'],
	Jelgava: ['Jelgava'],
	'Jendouba Governorate': ['Jendouba', 'Ghardimaou'],
	'Jeollabuk-do': ['Wanju', 'Puan', 'Nangen', 'Gunsan', "Koch'ang", 'Kimje', 'Iksan', 'Imsil', 'Jeonju', 'Jinan-gun', 'Changsu', 'Jeongeup'],
	'Jeollanam-do': ['Yeonggwang', 'Suncheon', 'Beolgyo', 'Naju', 'Muan', 'Mokpo', 'Kwangyang', 'Kurye', 'Hwasun', 'Haenam', 'Yeosu', 'Sinan', 'Yeongam'],
	Jerash: ['Jarash'],
	Jerusalem: ['Jerusalem', 'Mevasseret Tsiyyon', 'Bet Shemesh', 'West Jerusalem', 'Modiin Ilit'],
	'Jetisu Region': ['Ushtobe', 'Tekeli', 'Taldykorgan', 'Sarqant', 'Zharkent'],
	'Jewish Autonomous Oblast': ['Birobidzhan'],
	Jharkhand: [
		'Simdega',
		'Sijua',
		'Sāhibganj',
		'Ranchi',
		'Rāmgarh',
		'Rājmahal',
		'Pāthardih',
		'Pakur',
		'Noāmundi',
		'Mushābani',
		'Madhupur',
		'Lohārdagā',
		'Lātehār',
		'Kuju',
		'Kodarmā',
		'Khunti',
		'Kātrās',
		'Kānke',
		'Jhumri Telaiya',
		'Jugsālai',
		'Jharia',
		'Jasidih',
		'Jāmtāra',
		'Jamshedpur',
		'Jāmadoba',
		'Husainābād',
		'Hazāribāgh',
		'Gumlā',
		'Gumia',
		'Gomoh',
		'Godda',
		'Gobindpur',
		'Gīrīdīh',
		'Ghātsīla',
		'Garhwa',
		'Dumka',
		'Dugda',
		'Dhanbad',
		'Deoghar',
		'Daltonganj',
		'Chatrā',
		'Chās',
		'Chakradharpur',
		'Chāībāsa',
		'Būndu',
		'Bokāro',
		'Barki Saria',
		'Barkā Kānā',
		'Phusro',
	],
	Jiangsu: [
		'Liangji',
		'Lanshan',
		'Qing’an',
		'Malingshan',
		'Chenlou',
		'Hegou',
		'Gangshang',
		'Zouzhuang',
		'Tangzhang',
		'Hanwang',
		'Yitang',
		'Xuzhuang',
		'Nianzhuang',
		'Tashan',
		'Zizhuang',
		'Liuji',
		'Jiangzhuang',
		'Daizhuang',
		'Zhangzhuang',
		'Xinglou',
		'Huzhai',
		'Heqiao',
		'Fanlou',
		'Hekou',
		'Songlou',
		'Sunlou',
		'Zhuzhai',
		'Zhaozhuang',
		'Anguo',
		'Shizhai',
		'Zhouzhuang',
		'Zhongxing',
		'Zhenzhou',
		'Zhenjiang',
		'Zhengji',
		'Xinghua',
		'Zhaodun',
		'Zhangzhai',
		'Zhangji',
		'Zhancheng',
		'Kunshan',
		'Pizhou',
		'Yizhuang',
		'Yixing',
		'Yaowan',
		'Yaoji',
		'Yanzibu',
		'Yangzhou',
		'Yangtun',
		'Zhangjiagang',
		'Yancheng',
		'Tongshan',
		'Xinyi',
		'Xiaolingwei',
		'Xiannü',
		'Wuxi',
		'Weimiao',
		'Weiji',
		'Wayao',
		'Wangji',
		'Tushan',
		'Tiefu',
		'Taoyuan',
		'Taizhou',
		'Taixing',
		'Taicang',
		'Suyangshan',
		'Suqian',
		'Suicheng',
		'Sihu',
		'Shunhe',
		'Shuanggou',
		'Shouxian',
		'Shiji',
		'Shaodian',
		'Shanji',
		'Shaji',
		'Qiuji',
		'Qishan',
		'Qinnan',
		'Qingshanquan',
		"Huai'an",
		'Peicheng',
		'Picheng',
		'Nantong',
		'Nanjing',
		'Mapo',
		'Maocun',
		'Lulou',
		'Longgu',
		'Licheng',
		'Liuxin',
		'Liuquan',
		'Lingcheng',
		'Liji',
		'Liangzhai',
		'Juegang',
		'Jinsha',
		'Jiawang',
		'Jiangyan',
		'Hutang',
		'Huilong',
		'Huashan',
		'Huankou',
		'Huangji',
		'Hede',
		'Haizhou',
		'Gupi',
		'Guiren',
		'Guanhu',
		'Gaozuo',
		'Gaoyou',
		'Gaoliu',
		'Gaogou',
		'Gangtou',
		'Fengcheng',
		'Fangcun',
		'Dongtai',
		'Dongkan',
		'Dazhong',
		'Datun',
		'Damiao',
		'Dahuangshan',
		'Jiangyin',
		'Chefushan',
		'Changzhou',
		'Changdian',
		'Chahe',
		'Caoqiao',
		'Biantang',
		'Bayiji',
		'Baoying',
		'Balu',
		'Ahu',
		'Suzhou',
		'Wuduan',
		'Guanshan',
		'Mudu',
		'Songling',
		'Changshu',
		'Daocheng',
		'Xuzhou',
		'Lianyungang',
		'Rugao',
		'Qipan',
		'Yunlong',
		'Shuangtang',
		'Xindian',
		'Xinhe',
		'Dapeng',
		'Daxu',
		'Jiawang Zhen',
		'Dashahe',
		'Wanggou',
		"Jing'an",
	],
	Jiangxi: [
		'Yingtan',
		'Yichun',
		'Shangrao',
		'Xujiang',
		'Xiugu',
		'Guixi',
		'Xinyu',
		'Xiaogang',
		'Qincheng',
		'Poyang',
		'Pingxiang',
		'Nanchang',
		'Jiujiang',
		'Jinjiang',
		'Jingdezhen',
		'Jianguang',
		'Jianchang',
		'Ji’an',
		'Ganzhou',
		'Fuzhou',
		'Fenyi',
		'Fenggang',
		'Dunhou',
		'Changleng',
		'Bashan',
		'Aoxi',
		'Dengbu',
		'Gongqingcheng',
	],
	Jigawa: ['Kiyawa', 'Hadejia', 'Gwaram', 'Gumel', 'Dutse', 'Birnin Kudu', 'Chakwama'],
	'Jihočeský kraj': ['Tábor', 'Strakonice', 'Písek', 'Jindřichův Hradec', 'České Budějovice'],
	Jijel: ['Jijel', 'District of Taher'],
	Jilin: [
		'Zhenlai',
		'Shuangliao',
		'Yushu',
		'Yantongshan',
		'Yanji',
		'Xinglongshan',
		'Wangqing',
		'Hepingjie',
		'Tumen',
		'Tonghua',
		'Taonan',
		'Songjianghe',
		'Siping',
		'Shulan',
		'Shuangyang',
		'Sanchazi',
		'Songyuan',
		'Panshi',
		'Antu',
		'Meihekou',
		'Longjing',
		'Liuhe',
		'Lishu',
		'Linjiang',
		'Liaoyuan',
		'Kaitong',
		'Jiutai',
		'Jishu',
		'Jilin',
		'Jiaohe',
		'Hunchun',
		'Huinan',
		'Huangnihe',
		'Huadian',
		'Helong',
		'Gongzhuling',
		'Fuyu',
		'Erdaojiang',
		'Dunhua',
		'Dongfeng',
		'Dehui',
		'Dashitou',
		'Dalai',
		'Chaoyang',
		'Changling',
		'Changchun',
		'Baishishan',
		'Baicheng',
		'Baishan',
	],
	'Jinotega Department': ['Jinotega'],
	Jizzax: ['Zomin Shaharchasi', 'Dashtobod', 'Paxtakor Shahri', 'Jizzax', 'Gagarin Shahri', 'Do’stlik Shahri'],
	Johor: [
		'Batu Pahat',
		'Parit Raja',
		'Pekan Nenas',
		'Pontian Kechil',
		'Kampung Pasir Gudang Baru',
		'Kota Tinggi',
		'Taman Senai',
		'Kulai',
		'Skudai',
		'Johor Bahru',
		'Kluang',
		'Yong Peng',
		'Mersing',
		'Segamat',
		'Tangkak',
		'Muar',
		'Bakri',
		'Labis',
		'Ulu Tiram',
		'Kampung Simpang Renggam',
	],
	Jonglei: ['Bor'],
	Jowzjan: ['Shibirghān', 'Qarqīn'],
	'Judea and Samaria Area': ['Ariel'],
	Jujuy: ['San Salvador de Jujuy', 'San Pedro de Jujuy', 'Palpalá', 'Libertador General San Martín'],
	Junin: ['Tarma', 'Satipo', 'La Oroya', 'Junín', 'Jauja', 'Huancayo', 'Chilca', 'Santa Rosa'],
	Jutiapa: ['Jutiapa', 'Jalpatagua', 'Asunción Mita'],
	'Jász-Nagykun-Szolnok': ['Törökszentmiklós', 'Szolnok', 'Mezőtúr', 'Karcag', 'Jászberény'],
	Jämtland: ['Östersund'],
	Jönköping: ['Värnamo', 'Nässjö', 'Jönköping', 'Huskvarna'],
	'Jēkabpils Municipality': ['Jēkabpils'],
	Jūrmala: ['Jūrmala'],
	'Kaafu Atoll': ['Male'],
	'Kabardino-Balkariya Republic': ['Tyrnyauz', 'Terek', 'Prokhladnyy', 'Nartkala', 'Nalchik', 'Dugulubgey', 'Chegem', 'Baksan'],
	Kabul: ['Paghmān', 'Kabul'],
	Kachin: ['Myitkyina', 'Bhamo'],
	Kaduna: ['Zaria', 'Soba', 'Lere', 'Kagoro', 'Kafanchan', 'Kaduna', 'Kachia', 'Dutsen Wai', 'Sofo-Birnin-Gwari', 'Anchau'],
	Kaffrine: ['Kaffrine'],
	'Kafr el-Sheikh': ['Sīdī Sālim', 'Kafr ash Shaykh', 'Fuwwah', 'Disūq', 'Al Ḩāmūl'],
	Kagawa: ['Utazu', 'Takamatsu', 'Tadotsu', 'Shido', 'Sakaidechō', 'Marugame', 'Kan’onjichō', 'Mitoyo', 'Higashikagawa'],
	Kagera: ['Rulenge', 'Nsunga', 'Nshamba', 'Ngara', 'Katerero', 'Kamachumu', 'Kabanga', 'Bukoba', 'Bugarama', 'Biharamulo'],
	Kagoshima: [
		'Tarumizu',
		'Sueyoshichō-ninokata',
		'Shibushi',
		'Satsumasendai',
		'Ōkuchi-shinohara',
		'Nishinoomote',
		'Naze',
		'Makurazaki',
		'Kushikino',
		'Kokubu-matsuki',
		'Kaseda-shirakame',
		'Kanoya',
		'Kajiki',
		'Kagoshima',
		'Izumi',
		'Ijūin',
		'Ibusuki',
		'Hamanoichi',
		'Akune',
		'Kirishima',
	],
	Kahramanmaraş: ['Pazarcık', 'Kahramanmaraş', 'Göksun', 'Elbistan', 'Çağlayancerit', 'Afşin'],
	Kainuu: ['Kajaani'],
	Kairouan: ['Kairouan'],
	Kajiado: ['Kajiado'],
	Kakamega: ['Mumias', 'Kakamega'],
	Kakheti: ['Telavi'],
	Kalasin: ['Nong Kung Si', 'Kuchinarai', 'Khao Wong', 'Kamalasai', 'Kalasin'],
	'Kaliningrad Oblast': ['Zelenogradsk', 'Svetlyy', 'Sovetsk', 'Kaliningrad', 'Gusev', 'Chernyakhovsk', 'Baltiysk'],
	Kalmar: ['Västervik', 'Oskarshamn', 'Kalmar'],
	'Kalmykiya Republic': ['Elista'],
	'Kaluga Oblast': ['Sukhinichi', 'Obninsk', 'Maloyaroslavets', 'Lyudinovo', 'Kozel’sk', 'Kirov', 'Kaluga', 'Balabanovo'],
	Kamchatka: ['Vilyuchinsk', 'Yelizovo', 'Petropavlovsk-Kamchatsky'],
	'Kamphaeng Phet': ['Khanu Woralaksaburi', 'Kamphaeng Phet'],
	'Kampong Cham': ['Kampong Cham'],
	'Kampong Chhnang': ['Kampong Chhnang'],
	'Kampong Speu': ['Kampong Speu'],
	'Kampong Thom': ['Kampong Thom'],
	Kampot: ['Kampot'],
	Kanagawa: [
		'Atsugi',
		'Zushi',
		'Zama',
		'Yugawara',
		'Yokosuka',
		'Yokohama',
		'Ōiso',
		'Odawara',
		'Ninomiya',
		'Minamirinkan',
		'Kawasaki',
		'Kamakura',
		'Isehara',
		'Hiratsuka',
		'Hayama',
		'Hadano',
		'Fujisawa',
		'Chigasaki',
		'Miura',
		'Yamato',
	],
	Kanchanaburi: ['Tha Muang', 'Tha Maka', 'Kanchanaburi', 'Bo Phloi'],
	Kandahar: ['Kandahār'],
	Kandal: ['Ta Khmau'],
	Kanem: ['Mao'],
	'Kangwŏn-do': ['Wŏnsan', 'T’ongch’ŏn-ŭp', 'Kosan', 'Hoeyang', 'Anbyŏn-ŭp'],
	Kankan: ['Tomba kanssa', 'Siguiri', 'Kintinian', 'Kankan'],
	Kano: ['Wudil', 'Rano', 'Kano', 'Gwarzo', 'Gaya'],
	Kansas: [
		'Derby',
		'Emporia',
		'Gardner',
		'Great Bend',
		'Hays',
		'Hutchinson',
		'Junction City',
		'Kansas City',
		'Lawrence',
		'Leavenworth',
		'Leawood',
		'Lenexa',
		'Manhattan',
		'Newton',
		'Olathe',
		'Overland Park',
		'Pittsburg',
		'Prairie Village',
		'Salina',
		'Shawnee',
		'Topeka',
		'Wichita',
		'Dodge City',
		'Garden City',
		'Liberal',
	],
	'Kanta-Häme': ['Riihimäki', 'Janakkala', 'Hämeenlinna', 'Forssa'],
	Kaolack: ['Nioro du Rip', 'Kaolack'],
	Kara: ['Niamtougou', 'Kara', 'Bassar', 'Bafilo'],
	'Karabük Province': ['Safranbolu', 'Karabük'],
	'Karachayevo-Cherkesiya Republic': ['Zelenchukskaya', 'Ust’-Dzheguta', 'Uchkeken', 'Karachayevsk', 'Cherkessk'],
	Karaganda: ['Karagandy', 'Temirtau', 'Shakhtinsk', 'Soran', 'Balqash', 'Abay'],
	Karak: ['‘Izrā', 'Safi', 'Karak City'],
	Karakalpakstan: ['Nukus', 'Qŭnghirot', 'Xo‘jayli Shahri', 'Oltinko‘l', 'Novyy Turtkul’', 'Manghit', 'Beruniy'],
	Karaman: ['Karaman', 'Ermenek'],
	Karas: ['Lüderitz', 'Keetmanshoop'],
	Karbalāʼ: ['Karbala', 'Al Hindīyah'],
	Kardzhali: ['Kardzhali'],
	Karelia: ['Sortavala', 'Segezha', 'Petrozavodsk', 'Medvezh’yegorsk', 'Kostomuksha', 'Kondopoga'],
	Karlovac: ['Karlovac'],
	'Karlovarský kraj': ['Sokolov', 'Ostrov', 'Karlovy Vary', 'Cheb'],
	'Karnali Pradesh': ['Dailekh', 'Birendranagar'],
	Karnataka: [
		'Yellāpur',
		'Yelahanka',
		'Yādgīr',
		'Wādi',
		'Virajpet',
		'Ullal',
		'Udupi',
		'Tumkūr',
		'Tīrthahalli',
		'Tiptūr',
		'Terdāl',
		'Tekkalakote',
		'Tarikere',
		'Tālīkota',
		'Sulya',
		'Srīnivāspur',
		'Someshwar',
		'Siruguppa',
		'Sirsi',
		'Sīra',
		'Sindhnūr',
		'Sindgi',
		'Sidlaghatta',
		'Shrīrangapattana',
		'Shorāpur',
		'Shirhatti',
		'Shimoga',
		'Shikārpur',
		'Shiggaon',
		'Shāhpur',
		'Shāhābād',
		'Seram',
		'Savanūr',
		'Saundatti',
		'Sankeshwar',
		'Sandūr',
		'Sakleshpur',
		'Sāgar',
		'Sadalgi',
		'Ron',
		'Robertsonpet',
		'Raybag',
		'Rānībennur',
		'Closepet',
		'Rāichūr',
		'Rabkavi',
		'Puttūr',
		'Piriyāpatna',
		'Pāvugada',
		'French Rocks',
		'Nelamangala',
		'Navalgund',
		'Nargund',
		'Naregal',
		'Nanjangūd',
		'Nāgamangala',
		'Mysore',
		'Mundgod',
		'Mundargi',
		'Mūlki',
		'Mulgund',
		'Mulbāgal',
		'Mudhol',
		'Mudgal',
		'Muddebihāl',
		'Mūdbidri',
		'Mānvi',
		'Manipal',
		'Mangalore',
		'Mandya',
		'Mālūr',
		'Malpe',
		'Malavalli',
		'Mahālingpur',
		'Māgadi',
		'Madikeri',
		'Madhugiri',
		'Maddūr',
		'Lingsugūr',
		'Lakshmeshwar',
		'Kushtagi',
		'Kunigal',
		'Kundgol',
		'Kumta',
		'Kūdligi',
		'Kudachi',
		'Krishnarājpet',
		'Kottūru',
		'Koppal',
		'Konnūr',
		'Kollegāl',
		'Kolār',
		'Khānāpur',
		'Kerūr',
		'Karwar',
		'Kārkala',
		'Kānkānhalli',
		'Kampli',
		'Kalghatgi',
		'Kadūr',
		'Jevargi',
		'Jamkhandi',
		'Jagalūr',
		'Indi',
		'Ilkal',
		'Hadagalli',
		'Hunsūr',
		'Hungund',
		'Hukeri',
		'Hubli',
		'Hospet',
		'Hoskote',
		'Hosdurga',
		'Honnāli',
		'Honavar',
		'Homnābād',
		'Hole Narsipur',
		'Holalkere',
		'Hiriyūr',
		'Hirekerūr',
		'Hāveri',
		'Hassan',
		'Harpanahalli',
		'Harihar',
		'Hāngal',
		'Haliyal',
		'Gurmatkāl',
		'Gundlupēt',
		'Guledagudda',
		'Kalaburagi',
		'Gubbi',
		'Gokarna',
		'Gokak',
		'Gauribidanur',
		'Gangolli',
		'Gangāwati',
		'Gajendragarh',
		'Gadag',
		'Doddaballapura',
		'Devanhalli',
		'Davangere',
		'Dandeli',
		'Coondapoor',
		'Chitradurga',
		'Chītāpur',
		'Chintāmani',
		'Chincholi',
		'Chikodi',
		'Chiknāyakanhalli',
		'Chikmagalūr',
		'Chik Ballāpur',
		'Channarāyapatna',
		'Channapatna',
		'Channagiri',
		'Chamrajnagar',
		'Challakere',
		'Byādgi',
		'Birūr',
		'Bilgi',
		'Bijapur',
		'Bīdar',
		'Bhatkal',
		'Bhālki',
		'Bhadrāvati',
		'Belūr',
		'Bellary',
		'Belagavi',
		'Basavana Bāgevādi',
		'Basavakalyān',
		'Bantvāl',
		'Bannūr',
		'Bangarapet',
		'Bengaluru',
		'Byndoor',
		'Bail-Hongal',
		'Bāgepalli',
		'Bagalkot',
		'Bādāmi',
		'Aurād',
		'Athni',
		'Arsikere',
		'Arkalgūd',
		'Annigeri',
		'Anekal',
		'Alnāvar',
		'Aland',
		'Afzalpur',
		'Vadigenhalli',
		'Murudeshwara',
		'Gadag-Betageri',
	],
	'Kars Province': ['Sarıkamış', 'Kars', 'Kağızman'],
	Kasai: ['Tshikapa', 'Mweka', 'Luebo', 'Ilebo'],
	'Kasai-Central': ['Kananga', 'Demba'],
	'Kasaï-Oriental': ['Mbuji-Mayi'],
	Kassala: ['Kassala'],
	'Kasserine Governorate': ['Thala', 'Kasserine'],
	Kastamonu: ['Tosya', 'Taşköprü', 'Kastamonu'],
	Katavi: ['Usevia', 'Mpanda'],
	Katsina: ['Malumfashi', 'Katsina', 'Funtua', 'Daura'],
	Kaunas: ['Kėdainiai', 'Kaunas', 'Jonava', 'Aleksotas', 'Dainava (Kaunas)', 'Šilainiai', 'Eiguliai'],
	Kavadarci: ['Kavadarci'],
	'Kavango East': ['Rundu'],
	Kayah: ['Loikaw'],
	Kayanza: ['Kayanza'],
	Kayes: ['Sagalo', 'Kayes', 'Bafoulabé'],
	Kayin: ['Hpa-An', 'Myawadi', 'Kyain Seikgyi Township'],
	Kayseri: ['Yahyalı', 'Talas', 'Kayseri', 'Hacılar', 'Develi'],
	Kebbi: ['Zuru', 'Kamba', 'Jega', 'Birnin Kebbi', 'Argungu'],
	'Kebili Governorate': ['Kebili', 'Douz'],
	Kedah: ['Kuah', 'Kulim', 'Bedong', 'Gurun', 'Sungai Petani', 'Jitra', 'Kuala Kedah', 'Alor Setar'],
	'Kef Governorate': ['Tajerouine', 'El Kef'],
	Kelantan: ['Gua Musang', 'Tanah Merah', 'Pasir Mas', 'Kota Bharu', 'Peringat', 'Kampong Pangkal Kalong', 'Kampong Kadok'],
	Kentucky: [
		'Paducah',
		'Ashland',
		'Bowling Green',
		'Burlington',
		'Covington',
		'Danville',
		'Elizabethtown',
		'Erlanger',
		'Fern Creek',
		'Florence',
		'Fort Thomas',
		'Frankfort',
		'Georgetown',
		'Henderson',
		'Highview',
		'Hopkinsville',
		'Independence',
		'Ironville',
		'Jeffersontown',
		'Lexington',
		'Lexington-Fayette',
		'Louisville',
		'Madisonville',
		'Meads',
		'Murray',
		'Newburg',
		'Newport',
		'Nicholasville',
		'Okolona',
		'Owensboro',
		'Pleasure Ridge Park',
		'Radcliff',
		'Richmond',
		'Saint Matthews',
		'Shelbyville',
		'Shively',
		'Valley Station',
		'Winchester',
	],
	Kerala: [
		'Vettūr',
		'Vayalār',
		'Varkala',
		'Vaikam',
		'Thiruvananthapuram',
		'Thrissur',
		'Tiruvalla',
		'Tirur',
		'Tellicherry',
		'Talipparamba',
		'Shōranūr',
		'Shertallai',
		'Kollam',
		'Punalūr',
		'Ponnāni',
		'Piravam',
		'Perumpāvūr',
		'Payyannūr',
		'Pathanāmthitta',
		'Pariyāpuram',
		'Paravūr Tekkumbhāgam',
		'Pāppinisshēri',
		'Ponmana',
		'Palakkad',
		'Ottappālam',
		'Nīlēshwar',
		'Neyyāttinkara',
		'Nedumangād',
		'Naduvannūr',
		'Nādāpuram',
		'Mūvattupula',
		'Munnar',
		'Muluppilagadu',
		'Mavoor',
		'Māvelikara',
		'Mattanur',
		'Marayur',
		'Mannārakkāt',
		'Manjeri',
		'Malappuram',
		'Mahē',
		'Kuttampuzha',
		'Kutiatodu',
		'Kunnamkulam',
		'Kunnamangalam',
		'Kozhikode',
		'Kottayam',
		'Kotamangalam',
		'Kodungallūr',
		'Kizhake Chālakudi',
		'Kāyankulam',
		'Kattanam',
		'Kāsaragod',
		'Kānnangād',
		'Kalpatta',
		'Irinjālakuda',
		'Iringal',
		'Guruvāyūr',
		'Ferokh',
		'Erāttupetta',
		'Elūr',
		'Dharmadam',
		'Cochin',
		'Chittūr',
		'Chengannūr',
		'Chetwayi',
		'Changanācheri',
		'Kannur',
		'Beypore',
		'Badagara',
		'Attingal',
		'Arukutti',
		'Angamāli',
		'Alwaye',
		'Alappuzha',
		'Adūr',
		'Aluva',
		'Muvattupuzha',
		'Perumbavoor',
		'Cherpulassery',
		'Kumbalam',
		'Aroor',
		'Kadakkavoor',
		'Kalavoor',
		'Kalamassery',
		'Cherthala',
		'Azhiyūr',
		'Alangad',
		'Kanayannur',
	],
	Kerguelen: ['Port-aux-Français'],
	Kericho: ['Kericho'],
	Kerman: ['Zarand', 'Sirjan', 'Shahr-e Bābak', 'Rāvar', 'Rafsanjān', 'Bardsīr', 'Kerman', 'Bam'],
	Kermānshāh: ['Kahrīz', 'Sonqor', 'Sarpol-e Z̄ahāb', 'Pāveh', 'Kermanshah', 'Kangāvar', 'Javānrūd', 'Harsīn'],
	Keryneia: ['Kyrenia'],
	Kgatleng: ['Mochudi'],
	Khabarovsk: [
		'Vyazemskiy',
		'Komsomolsk-on-Amur',
		'Khabarovsk',
		'Bikin',
		'Amursk',
		'Khabarovsk Vtoroy',
		'Vanino',
		'Sovetskaya Gavan’',
		'Nikolayevsk-on-Amure',
	],
	'Khakasiya Republic': ['Sayanogorsk', 'Chernogorsk', 'Abaza', 'Abakan'],
	Khammouan: ['Thakhèk'],
	'Khanty-Mansia': [
		'Uray',
		'Surgut',
		'Sovetskiy',
		'Poykovskiy',
		'Nyagan',
		'Nizhnevartovsk',
		'Nefteyugansk',
		'Megion',
		'Yugorsk',
		'Khanty-Mansiysk',
		'Beloyarskiy',
		'Raduzhny',
		'Lyantor',
		'Kogalym',
		'Pyt-Yakh',
		'Langepas',
		'Nizhnesortymskiy',
		'Pokachi',
		'Izluchinsk',
		'Fedorovskiy',
	],
	Kharkiv: [
		'Vovchansk',
		'Pervomaiskyi',
		'Merefa',
		'Lyubotyn',
		'Lozova',
		'Kupiansk',
		'Krasnohrad',
		'Kivsharivka',
		'Slobozhans’ke',
		'Kharkiv',
		'Izium',
		'Derhachi',
		'Chuhuiv',
		'Bohodukhiv',
		'Balakliia',
	],
	Khartoum: ['Omdurman', 'Khartoum'],
	Khatlon: ['Yovon', 'Hulbuk', 'Vakhsh', 'Bokhtar', 'Farkhor', 'Norak', 'Kŭlob', 'Kolkhozobod', 'Danghara', 'Chubek', 'Moskovskiy'],
	Khenchela: ['Khenchela'],
	Kherson: ['Oleshky', 'Skadovsk', 'Nova Kakhovka', 'Kherson', 'Kakhovka', 'Henichesk'],
	Khmelnytskyi: [
		'Volochysk',
		'Starokostyantyniv',
		'Slavuta',
		'Shepetivka',
		'Polonne',
		'Netishyn',
		'Krasyliv',
		'Khmelnytskyi',
		'Kamianets-Podilskyi',
		'Iziaslav',
		'Horodok',
		'Dunayivtsi',
	],
	Khomas: ['Windhoek', 'Katutura'],
	'Khon Kaen': ['Khon Kaen', 'Chum Phae', 'Ban Phai'],
	Khowst: ['Khōst'],
	Khulna: [
		'Sātkhira',
		'Morrelgonj',
		'Kushtia',
		'Kālīganj',
		'Jhingergācha',
		'Sarankhola',
		'Bhātpāra Abhaynagar',
		'Bherāmāra',
		'Kālia',
		'Bagerhat',
		'Narail',
		'Phultala',
		'Kesabpur',
		'Uttar Char Fasson',
		'Khulna',
		'Jessore',
	],
	Khuzestan: [
		'Shūshtar',
		'Shūsh',
		'Shādegān',
		'Rāmshīr',
		'Rāmhormoz',
		'Omīdīyeh',
		'Masjed Soleymān',
		'Khorramshahr',
		'Sūsangerd',
		'Behbahān',
		'Ahvaz',
		'Aghajari',
		'Abadan',
	],
	'Khyber Pakhtunkhwa': [
		'Zaida',
		'Utmanzai',
		'Tordher',
		'Topi',
		'Tank',
		'Tangi',
		'Thal',
		'Swabi',
		'Shorkot',
		'Shabqadar',
		'Sarai Naurang',
		'Peshawar',
		'Paharpur',
		'Pabbi',
		'Mingora',
		'Mardan',
		'Mansehra',
		'Lakki',
		'Lachi',
		'Kulachi',
		'Kohat',
		'Khalabat',
		'Havelian',
		'Haripur',
		'Hangu',
		'Upper Dir',
		'Dera Ismail Khan',
		'Charsadda',
		'Bat Khela',
		'Battagram',
		'Bannu',
		'Aman Garh',
		'Akora',
		'Abbottabad',
		'Nowshera Cantonment',
		'Risalpur Cantonment',
	],
	'Khánh Hòa': ['Ninh Hòa', 'Nha Trang', 'Cam Ranh'],
	'Khövsgöl Province': ['Mörön'],
	Kiambu: ['Ruiru', 'Limuru', 'Kikuyu', 'Kiambu'],
	Kichevo: ['Kičevo'],
	Kiev: [
		'Yahotyn',
		'Vyshhorod',
		'Vyshneve',
		'Vasylkiv',
		'Skvyra',
		'Pereiaslav',
		'Obukhiv',
		'Irpin',
		'Hostomel',
		'Fastiv',
		'Bucha',
		'Brovary',
		'Boiarka',
		'Boryspil',
		'Bohuslav',
		'Bila Tserkva',
		'Berezan',
		'Slavutych',
	],
	Kigali: ['Kigali'],
	Kigoma: ['Uvinza', 'Nguruka', 'Mwandiga', 'Kigoma', 'Kibondo', 'Kasulu', 'Kakonko'],
	Kilifi: ['Malindi', 'Kilifi'],
	Kilimanjaro: ['Same', 'Moshi', 'Lembeni', 'Hedaru'],
	Kilis: ['Kilis'],
	Kindia: ['Télimélé', 'Kindia', 'Coyah'],
	Kingston: ['Kingston'],
	Kinshasa: ['Kinshasa', 'Masina'],
	Kirinyaga: ['Kerugoya'],
	Kirkuk: ['Kirkuk'],
	'Kirov Oblast': ['Yaransk', 'Vyatskiye Polyany', 'Sovetsk', 'Slobodskoy', 'Omutninsk', 'Kotel’nich', 'Kirovo-Chepetsk', 'Kirov'],
	Kirovohrad: ['Znamyanka', 'Svitlovodsk', 'Oleksandriya', 'Novoukrayinka', 'Kropyvnytskyy', 'Dolynska'],
	Kisii: ['Ogembo', 'Kisii'],
	Kisumu: ['Muhoroni', 'Kisumu'],
	Kitui: ['Kitui'],
	'Kié-Ntem': ['Ebebiyin'],
	'Kiến Giang': ['Rạch Giá', 'Hà Tiên', 'Phú Quốc'],
	'Klaipėda County': ['Palanga', 'Kretinga', 'Klaipėda', 'Gargždai'],
	Kocaeli: ['Körfez', 'Karamürsel', 'İzmit', 'Gölcük', 'Gebze', 'Derince'],
	Kochani: ['Kochani'],
	Kochi: ['Susaki', 'Sukumo', 'Nakamura', 'Muroto-misakicho', 'Kochi', 'Ino', 'Aki'],
	Kogi: ['Okene', 'Ogaminana', 'Lokoja', 'Kabba', 'Isanlu-Itedoijowa', 'Idah', 'Egbe', 'Ajaokuta'],
	'Koh Kong': ['Koh Kong', 'Smach Mean Chey'],
	'Kohgiluyeh and Boyer-Ahmad': ['Yasuj', 'Dogonbadan', 'Dehdasht'],
	Kolda: ['Vélingara', 'Kolda'],
	Komi: ['Yemva', 'Ukhta', 'Syktyvkar', 'Sosnogorsk', 'Pechora', 'Ezhva', 'Usinsk', 'Vorkuta', 'Vorgashor'],
	'Komárom-Esztergom': ['Tatabánya', 'Tata', 'Oroszlány', 'Komárom', 'Esztergom'],
	'Kon Tum': ['Kon Tum'],
	Konya: ['Seydişehir', 'Kulu', 'Konya', 'Karapınar', 'Kadınhanı', 'Ilgın', 'Hadim', 'Ereğli', 'Çumra', 'Beyşehir', 'Akşehir'],
	'Koper-Capodistria': ['Koper'],
	Kordestān: ['Saqqez', 'Sanandaj', 'Qorveh', 'Marīvān', 'Kāmyārān', 'Bījār', 'Bāneh'],
	'Korçë County': ['Pogradec', 'Korçë'],
	'Kostroma Oblast': ['Volgorechensk', 'Shar’ya', 'Nerekhta', 'Manturovo', 'Kostroma', 'Galich', 'Buy'],
	Kotayk: ['Hrazdan', 'Ch’arents’avan', 'Abovyan'],
	Kouffo: ['Dogbo', 'Aplahoué'],
	Koulikoro: ['Koulikoro', 'Kolokani', 'Kati', 'Kangaba', 'Banamba'],
	Koungou: ['Koungou'],
	'Kowloon City': ['Kowloon'],
	'Košický kraj': ['Trebišov', 'Spišská Nová Ves', 'Rožňava', 'Michalovce', 'Košice'],
	Krabi: ['Krabi'],
	Kranj: ['Kranj'],
	'Krasnodar Krai': [
		'Yeysk',
		'Yelizavetinskaya',
		'Vyselki',
		'Ust’-Labinsk',
		'Tuapse',
		'Timashyovsk',
		'Tikhoretsk',
		'Temryuk',
		'Tbilisskaya',
		'Staroshcherbinovskaya',
		'Starominskaya',
		'Sochi',
		'Slavyansk-na-Kubani',
		'Severskaya',
		'Primorsko-Akhtarsk',
		'Pavlovskaya',
		'Pashkovskiy',
		'Otradnaya',
		'Novotitarovskaya',
		'Novorossiysk',
		'Novopokrovskaya',
		'Novokubansk',
		'Mostovskoy',
		'Medvedovskaya',
		'Leningradskaya',
		'Lazarevskoye',
		'Labinsk',
		'Kushchyovskaya',
		'Kurganinsk',
		'Kudepsta',
		'Krymsk',
		'Kropotkin',
		'Krasnodar',
		'Krasnoarmeyskaya',
		'Korenovsk',
		'Khosta',
		'Kholmskiy',
		'Khadyzhensk',
		'Kanevskaya',
		'Il’skiy',
		'Gul’kevichi',
		'Goryachiy Klyuch',
		'Gelendzhik',
		'Dinskaya',
		'Bryukhovetskaya',
		'Belorechensk',
		'Belaya Glina',
		'Armavir',
		'Apsheronsk',
		'Anapa',
		'Akhtyrskiy',
		'Afipskiy',
		'Adler',
		'Abinsk',
		'Dagomys',
	],
	'Krasnoyarsk Krai': [
		'Yeniseysk',
		'Uzhur',
		'Zelenogorsk',
		'Talnakh',
		'Sosnovoborsk',
		'Shushenskoye',
		'Sharypovo',
		'Norilsk',
		'Nazarovo',
		'Minusinsk',
		'Lesosibirsk',
		'Krasnoyarsk',
		'Kodinsk',
		'Kayyerkan',
		'Kansk',
		'Ilanskiy',
		'Dudinka',
		'Divnogorsk',
		'Borodino',
		'Bogotol',
		'Berëzovka',
		'Achinsk',
		'Zheleznogorsk',
	],
	Kratie: ['Kratié'],
	'Kriva Palanka': ['Kriva Palanka'],
	Kronoberg: ['Växjö', 'Ljungby'],
	'Královéhradecký kraj': ['Dvůr Králové nad Labem', 'Trutnov', 'Náchod', 'Jičín', 'Hradec Králové'],
	'Kuala Lumpur': ['Kuala Lumpur', 'Ampang'],
	'Kujawsko-Pomorskie': [
		'Włocławek',
		'Toruń',
		'Świecie',
		'Solec Kujawski',
		'Rypin',
		'Nakło nad Notecią',
		'Inowrocław',
		'Grudziądz',
		'Fordon',
		'Chełmża',
		'Chełmno',
		'Bydgoszcz',
		'Brodnica',
	],
	'Kukës County': ['Kukës'],
	Kumamoto: [
		'Honmachi',
		'Yamaga',
		'Uto',
		'Ushibukamachi',
		'Uekimachi-mōno',
		'Tamana',
		'Ōzu',
		'Nagasu',
		'Minamata',
		'Mifune',
		'Matsubase',
		'Kumamoto',
		'Kikuchi',
		'Hondomachi-hondo',
		'Hitoyoshi',
		'Aso',
		'Amakusa',
		'Arao',
		'Kōshi',
		'Uki',
	],
	Kumanovo: ['Kumanovo'],
	Kunar: ['Āsmār', 'Asadābād'],
	Kunduz: ['Qarāwul', 'Kunduz', 'Khanabad'],
	'Kurgan Oblast': ['Shumikha', 'Shadrinsk', 'Kurtamysh', 'Kurgan', 'Kataysk'],
	'Kursk Oblast': ['Zheleznogorsk', 'Shchigry', 'L’govskiy', 'Kursk', 'Kurchatov', 'Ryl’sk'],
	Kuzbass: [
		'Yurga',
		'Yashkino',
		'Topki',
		'Tayga',
		'Tashtagol',
		'Promyshlennaya',
		'Prokop’yevsk',
		'Polysayevo',
		'Osinniki',
		'Novokuznetsk',
		'Myski',
		'Mezhdurechensk',
		'Mariinsk',
		'Leninsk-Kuznetsky',
		'Kiselëvsk',
		'Kemerovo',
		'Kedrovka',
		'Kaltan',
		'Gur’yevsk',
		'Berëzovskiy',
		'Belovo',
		'Anzhero-Sudzhensk',
	],
	'Kvemo Kartli': ['Rustavi', 'Marneuli'],
	'KwaZulu-Natal': [
		'Vryheid',
		'eMkhomazi',
		'Ulundi',
		'KwaDukuza',
		'Scottburgh',
		'Richmond',
		'Richards Bay',
		'Port Shepstone',
		'Pinetown',
		'Pietermaritzburg',
		'Newcastle',
		'Mpumalanga',
		'Mpophomeni',
		'Mondlo',
		'Margate',
		'Kokstad',
		'Howick',
		'eSikhaleni',
		'Empangeni',
		'Durban',
		'Dundee',
		'Ballito',
	],
	'Kwai Tsing': ['Tsing Yi Town'],
	Kwango: ['Kasongo-Lunda'],
	'Kwanza Sul': ['Sumbe'],
	Kwara: ['Patigi', 'Okuta', 'Offa', 'Lafiagi', 'Kaiama', 'Jebba', 'Ilorin', 'Bode Saadu'],
	Kweneng: ['Thamaga', 'Molepolole', 'Mogoditshane'],
	Kwilu: ['Mangai', 'Kikwit', 'Bulungu', 'Bandundu'],
	'Kyiv City': ['Kyiv', 'Kotsiubynske'],
	Kymenlaakso: ['Kouvola', 'Kotka', 'Karhula', 'Hamina', 'Anjala'],
	Kyoto: ['Yawata', 'Uji', 'Tanabe', 'Mukō', 'Miyazu', 'Maizuru', 'Kyoto', 'Kameoka', 'Fukuchiyama', 'Ayabe', 'Arashiyama', 'Kamigyō-ku'],
	Kyustendil: ['Dupnitsa', 'Kyustendil'],
	Kédougou: ['Kédougou'],
	Kémo: ['Sibut'],
	Kürdǝmir: ['Kyurdarmir'],
	Kütahya: ['Tavşanlı', 'Simav', 'Kütahya', 'Gediz', 'Emet'],
	Kırklareli: ['Lüleburgaz', 'Kırklareli', 'Babaeski'],
	Kırıkkale: ['Kırıkkale', 'Keskin'],
	Kırşehir: ['Mucur', 'Kırşehir', 'Kaman'],
	'La Altagracia': ['Salvaleón de Higüey', 'Punta Cana'],
	'La Guajira Department': ['Villanueva', 'San Juan del Cesar', 'Riohacha', 'Maicao', 'Fonseca', 'Barrancas', 'Albania'],
	'La Libertad': [
		'Virú',
		'Trujillo',
		'Santiago de Cao',
		'San Pedro de Lloc',
		'Paiján',
		'Pacasmayo',
		'Moche',
		'Laredo',
		'Huamachuco',
		'Guadalupe',
		'Chocope',
		'Chepén',
		'Quezaltepeque',
		'Santa Tecla',
		'La Libertad',
		'Antiguo Cuscatlán',
	],
	'La Pampa': ['Santa Rosa', 'General Pico'],
	'La Paz': ['Zacatecoluca'],
	'La Paz Department': ['Viacha', 'La Paz', 'Laja', 'Caranavi', 'Achocalla', 'La Paz'],
	'La Rioja': ['La Rioja', 'Chilecito', 'Logroño', 'Calahorra'],
	'La Romana': ['La Romana'],
	'La Unión': ['La Unión'],
	'La Vega': ['Jarabacoa', 'Constanza', 'Concepción de La Vega'],
	Labe: ['Tougué', 'Labé'],
	Labuan: ['Labuan'],
	Lacs: ['Yamoussoukro', 'Toumodi', 'Dimbokro', 'Daoukro', 'Bongouanou', 'Arrah'],
	Ladakh: ['Padam', 'Leh'],
	Laghman: ['Mehtar Lām'],
	Laghouat: ['Laghouat', 'Aflou'],
	Lagos: ['Makoko', 'Lekki', 'Lagos', 'Ikeja', 'Epe', 'Ebute Ikorodu', 'Badagry'],
	Lagunes: ['Tiassalé', 'Affery', 'Dabou', 'Akoupé', 'Agboville', 'Adzopé', 'Ahouanou'],
	Laikipia: ['Nyahururu', 'Nanyuki'],
	Lakes: ['Rumbek'],
	Lambayeque: ['Saña', 'Pimentel', 'Picsi', 'Pátapo', 'Monsefú', 'Lambayeque', 'Ferreñafe', 'Chongoyape', 'Chiclayo'],
	Lampang: ['Thoen', 'Lampang'],
	Lamphun: ['Pa Sang', 'Lamphun'],
	Lampung: ['Terbanggi Besar', 'Bandar Lampung', 'Metro', 'Kotabumi'],
	Lamu: ['Lamu'],
	Lapland: ['Tornio', 'Rovaniemi', 'Kemi'],
	Lara: ['Sanare', 'Quíbor', 'Los Rastrojos', 'El Tocuyo', 'Duaca', 'Carora', 'Cabudare', 'Barquisimeto'],
	Larnaka: ['Larnaca'],
	'Las Tunas': ['Puerto Padre', 'Las Tunas', 'Jobabo', 'Jesús Menéndez', 'Colombia', 'Amancio'],
	Latakia: ['Jablah', 'Latakia'],
	Lautém: ['Lospalos'],
	Lavalleja: ['Minas'],
	Lazio: [
		'Viterbo',
		'Velletri',
		'Tivoli',
		'Terracina',
		'Sora',
		'Sezze',
		'Rome',
		'Rocca di Papa',
		'Rieti',
		'Pomezia',
		'Nettuno',
		'Monterotondo',
		'Minturno',
		'Mentana',
		'Marino',
		'Lido di Ostia',
		'Latina',
		'Ladispoli',
		'Guidonia',
		'Grottaferrata',
		'Genzano di Roma',
		'Gaeta',
		'Frosinone',
		'Frascati',
		'Formia',
		'Fondi',
		'Fiumicino',
		'Colleferro',
		'Civitavecchia',
		'Cisterna di Latina',
		'Ciampino',
		'Cerveteri',
		'Cassino',
		'Ariccia',
		'Ardea',
		'Aprilia',
		'Anzio',
		'Albano Laziale',
		'Acilia-Castel Fusano-Ostia Antica',
		'Guidonia Montecelio',
		'Tor Lupara',
		'Torvaianica',
		'Casal Palocco',
		'Villanova',
		'Marina di Ardea-Tor San Lorenzo',
		"Casal de' Pazzi",
		'Giardinetti-Tor Vergata',
		'Casal Bertone',
	],
	'Laâyoune-Sakia El Hamra': ['Smara'],
	Laḩij: ['Laḩij'],
	Lebap: ['Saýat', 'Kerki', 'Gowurdak', 'Türkmenabat', 'Gazojak'],
	Leinster: [
		'Wexford',
		'Tallaght',
		'Swords',
		'Rathfarnham',
		'Droichead Nua',
		'Navan',
		'Naas',
		'An Muileann gCearr',
		'Malahide',
		'Lucan',
		'Leixlip',
		'Kilkenny',
		'Greystones',
		'Finglas',
		'Dún Laoghaire',
		'Dundalk',
		'Dublin',
		'Drogheda',
		'Celbridge',
		'Carlow',
		'Bray',
		'Blanchardstown',
		'Balbriggan',
		'Athlone',
		'Sandyford',
		'Donaghmede',
		'South Dublin',
	],
	Leiria: ['Pombal', 'Peniche', 'Marinha Grande', 'Leiria', 'Caldas da Rainha', 'Alcobaça'],
	"Leningradskaya Oblast'": [
		'Vyborg',
		'Vsevolozhsk',
		'Volkhov',
		'Tosno',
		'Tikhvin',
		'Svetogorsk',
		'Sosnovyy Bor',
		'Slantsy',
		'Sertolovo',
		'Rybatskoye',
		'Priozërsk',
		'Podporozh’ye',
		'Pikalëvo',
		'Otradnoye',
		'Nikol’skoye',
		'Luga',
		'Lodeynoye Pole',
		'Kudrovo',
		'Kommunar',
		'Kirovsk',
		'Kirishi',
		'Kingisepp',
		'Gatchina',
		'Boksitogorsk',
		'Kurortnyy',
		'Chernaya Rechka',
		'Untolovo',
		'Parnas',
		'Krestovskiy ostrov',
		'Akademicheskoe',
		'Finlyandskiy',
		'Svetlanovskiy',
		'Sampsonievskiy',
	],
	Leribe: ['Maputsoe', 'Leribe'],
	Lerik: ['Lerik'],
	'Lesser Poland': [
		'Wieliczka',
		'Tarnów',
		'Nowy Targ',
		'Nowy Sącz',
		'Gorlice',
		'Brzesko',
		'Bochnia',
		'Zakopane',
		'Wadowice',
		'Trzebinia',
		'Skawina',
		'Oświęcim',
		'Olkusz',
		'Myślenice',
		'Libiąż',
		'Kraków',
		'Kęty',
		'Chrzanów',
		'Andrychów',
	],
	'Lezhë County': ['Lezhë', 'Laç'],
	'León Department': ['Nagarote', 'León', 'La Paz Centro'],
	Liaoning: [
		'Zhuanghe',
		'Wafangdian',
		'Dachang Shandao',
		'Pulandian',
		'Lüshun',
		'Jinzhou',
		'Dalian',
		'Dalianwan',
		'Xiaochangshan',
		'Yingkou',
		'Yebaishou',
		'Xiuyan',
		'Xinmin',
		'Xingcheng',
		'Xifeng',
		'Xiaoshi',
		'Tieling',
		'Sujiatun',
		'Shenyang',
		'Panshan',
		'Nantai',
		'Nanpiao',
		'Lingyuan',
		'Liaozhong',
		'Liaoyang',
		'Langtoucun',
		'Kuandian',
		'Kaiyuan',
		'Jiupu',
		'Jinzhou',
		'Lianshan',
		'Hushitai',
		'Huanren',
		'Heishan',
		'Haicheng',
		'Gongchangling',
		'Fuxin',
		'Fushun',
		'Fengcheng',
		'Dongling',
		'Dashiqiao',
		'Dandong',
		'Linghai',
		'Chaoyang',
		'Changtu',
		'Benxi',
		'Beipiao',
		'Anshan',
	],
	'Liban-Nord': ['Tripoli', 'Bcharré'],
	'Liberecký kraj': ['Liberec', 'Jablonec nad Nisou', 'Česká Lípa'],
	Liepāja: ['Liepāja'],
	Liguria: ['Ventimiglia', 'Sestri Levante', 'Savona', 'Sarzana', 'San Remo', 'Rapallo', 'La Spezia', 'Imperia', 'Genoa', 'Chiavari', 'Albenga'],
	Likouala: ['Impfondo'],
	Lima: ['Lima'],
	'Lima region': [
		'Santiago de Surco',
		'San Vicente de Cañete',
		'San Isidro',
		'Paramonga',
		'Nuevo Imperial',
		'Mala',
		'Imperial',
		'Huaura',
		'Huaral',
		'Hualmay',
		'Huacho',
		'Chosica',
		'Chancay',
		'Barranca',
		'Santa Anita - Los Ficus',
		'San Francisco De Borja',
		'Jesus Maria',
		'Breña',
	],
	Limassol: ['Limassol'],
	Limburg: [
		'Weert',
		'Venray',
		'Venlo',
		'Tegelen',
		'Sittard',
		'Roermond',
		'Maastricht',
		'Kerkrade',
		'Hoensbroek',
		'Heerlen',
		'Gennep',
		'Geleen',
		'Brunssum',
	],
	Limpopo: [
		'Bela Bela',
		'Tzaneen',
		'Thohoyandou',
		'Mokopane',
		'Polokwane',
		'Phalaborwa',
		'Modimolle',
		'Nkowakowa',
		'Louis Trichardt',
		'Lebowakgomo',
		'Giyani',
		'Duiwelskloof',
	],
	'Limón Province': ['Siquirres', 'Limón', 'Guápiles'],
	Lindi: ['Liwale', 'Nyangao', 'Nachingwea', 'Lindi'],
	'Lipetsk Oblast': ['Yelets', 'Usman’', 'Lipetsk', 'Lebedyan’', 'Gryazi', 'Dankov'],
	Lipkovo: ['Lipkovo'],
	Liquiçá: ['Maubara', 'Likisá'],
	Lisbon: [
		'Vila Franca de Xira',
		'Vialonga',
		'Torres Vedras',
		'Sintra',
		'São João da Talha',
		'São Domingos de Rana',
		'Santa Iria da Azóia',
		'Sacavém',
		'Rio de Mouro',
		'Ramada',
		'Queluz',
		'Póvoa de Santa Iria',
		'Pontinha',
		'Parede',
		'Paço de Arcos',
		'Olivais',
		'Oeiras',
		'Odivelas',
		'Moscavide e Portela',
		'Monte Estoril',
		'Massamá',
		'Loures',
		'Lisbon',
		'Linda-a-Velha',
		'Estoril',
		'Cascais',
		'Carnaxide',
		'Carcavelos',
		'Camarate',
		'Cacém',
		'Benfica',
		'Belas',
		'Amadora',
		'Algueirão',
		'Algés',
		'Alcabideche',
		'Alfragide',
		'Alvalade',
	],
	Litoral: ['Bata'],
	Littoral: ['Cotonou', 'Penja', 'Nkongsamba', 'Melong', 'Mbanga', 'Manjo', 'Loum', 'Edéa', 'Douala', 'Dizangué'],
	Ljubljana: ['Ljubljana'],
	Lobatse: ['Lobatse'],
	Lobaye: ['Mbaïki', 'Boda'],
	Loei: ['Wang Saphung', 'Phu Kradueng', 'Loei'],
	Lofa: ['Voinjama'],
	Logar: ['Baraki Barak'],
	'Logone Occidental': ['Moundou', 'Benoy'],
	'Logone Oriental': ['Doba'],
	Loja: ['Loja', 'Catamayo', 'Cariamanga'],
	Lomami: ['Mwene-Ditu', 'Lubao', 'Kabinda', 'Gandajika'],
	Lombardy: [
		'Voghera',
		'Vimodrone',
		'Vimercate',
		'Vigevano',
		'Viadana',
		'Varese',
		'Trezzano sul Naviglio',
		'Treviglio',
		'Tradate',
		'Suzzara',
		'Sondrio',
		'Seveso',
		'Sesto San Giovanni',
		'Seriate',
		'Seregno',
		'Senago',
		'Segrate',
		'Saronno',
		'San Sebastiano',
		'San Giuliano Milanese',
		'San Donato Milanese',
		'Rozzano',
		'Romano di Lombardia',
		'Rho',
		'Pioltello',
		'Pavia',
		'Parabiago',
		"Palazzolo sull'Oglio",
		'Paderno Dugnano',
		'Novate Milanese',
		'Nova Milanese',
		'Nerviano',
		'Muggiò',
		'Monza',
		'Montichiari',
		'Milan',
		'Melzo',
		'Melegnano',
		'Meda',
		'Mariano Comense',
		'Mantova',
		'Malnate',
		'Magenta',
		'Lodi',
		'Lissone',
		'Limbiate',
		'Legnano',
		'Lecco',
		'Lainate',
		'Gorgonzola',
		'Giussano',
		'Ghedi',
		'Garbagnate Milanese',
		'Gallarate',
		'Erba',
		'Desio',
		'Desenzano del Garda',
		'Dalmine',
		'Cusano',
		'Cremona',
		'Crema',
		'Corsico',
		'Cornaredo',
		'Cormano',
		'Como',
		'Cologno Monzese',
		'Cinisello Balsamo',
		'Chiari',
		'Cesano Maderno',
		'Cernusco sul Naviglio',
		'Castiglione delle Stiviere',
		"Cassano d'Adda",
		'Carugate',
		'Carate Brianza',
		'Cantù',
		'Busto Arsizio',
		'Brugherio',
		'Bresso',
		'Brescia',
		'Bovisio-Masciago',
		'Bollate',
		'Bergamo',
		'Bareggio',
		'Arese',
		'Arcore',
		'Abbiategrasso',
		'Lumezzane',
		'Caronno Pertusella',
		'Cassano Magnago',
		'Cesano Boscone',
		'Romano Banco',
	],
	'Long An': ['Tân An', 'Cần Giuộc'],
	Lopburi: ['Lop Buri', 'Chai Badan'],
	'Lorestan Province': ['Nūrābād', 'Kūhdasht', 'Khorramabad', 'Borūjerd', 'Aznā', 'Alīgūdarz', 'Aleshtar'],
	Loreto: ['Yurimaguas', 'San Juan', 'Iquitos', 'Belen'],
	Lori: ['Stepanavan', 'Spitak', 'Vanadzor'],
	'Los Lagos Region': ['Puerto Varas', 'Quellón', 'Puerto Montt', 'Osorno', 'Castro', 'Ancud'],
	'Los Ríos': ['Vinces', 'Ventanas', 'Quevedo', 'Montalvo', 'Babahoyo'],
	'Los Ríos Region': ['Valdivia', 'Río Bueno', 'Panguipulli', 'La Unión', 'Las Animas'],
	Louangphabang: ['Luang Prabang'],
	Louga: ['Ndibène Dahra', 'Louga', 'Dara'],
	Louisiana: [
		'Alexandria',
		'Baton Rouge',
		'Bayou Cane',
		'Bossier City',
		'Central',
		'Chalmette',
		'Estelle',
		'Gretna',
		'Hammond',
		'Harvey',
		'Houma',
		'Kenner',
		'Lafayette',
		'Lake Charles',
		'Laplace',
		'Marrero',
		'Metairie',
		'Metairie Terrace',
		'Monroe',
		'Natchitoches',
		'New Iberia',
		'New Orleans',
		'Opelousas',
		'Prairieville',
		'Ruston',
		'Shenandoah',
		'Shreveport',
		'Slidell',
		'Sulphur',
		'Terrytown',
		'Zachary',
	],
	Lovech: ['Troyan', 'Lovech'],
	'Lower Austria': [
		'Wiener Neustadt',
		'Weinzierl bei Krems',
		'Stockerau',
		'Sankt Pölten',
		'Perchtoldsdorf',
		'Mödling',
		'Klosterneuburg',
		'Baden',
		'Amstetten',
	],
	'Lower Juba': ['Kismayo', 'Jamaame'],
	'Lower Saxony': [
		'Wunstorf',
		'Wolfsburg',
		'Wolfenbüttel',
		'Wittmund',
		'Winsen',
		'Wilhelmshaven',
		'Wildeshausen',
		'Westerstede',
		'Weener',
		'Wardenburg',
		'Walsrode',
		'Wallenhorst',
		'Verden',
		'Vechta',
		'Vechelde',
		'Varel',
		'Uslar',
		'Uelzen',
		'Syke',
		'Stuhr',
		'Stadthagen',
		'Stade',
		'Springe',
		'Soltau',
		'Sehnde',
		'Seevetal',
		'Seesen',
		'Seelze',
		'Schwanewede',
		'Schortens',
		'Schneverdingen',
		'Sarstedt',
		'Salzgitter',
		'Rotenburg',
		'Ronnenberg',
		'Rinteln',
		'Rastede',
		'Peine',
		'Papenburg',
		'Oyten',
		'Osterholz-Scharmbeck',
		'Osnabrück',
		'Oldenburg',
		'Northeim',
		'Nordhorn',
		'Nordenham',
		'Norden',
		'Nienburg',
		'Neu Wulmstorf',
		'Neustadt am Rübenberge',
		'Munster',
		'Hannoversch Münden',
		'Meppen',
		'Melle',
		'Lüneburg',
		'Loxstedt',
		'Lohne',
		'Lingen',
		'Lilienthal',
		'Lehrte',
		'Leer',
		'Langenhagen',
		'Langen',
		'Laatzen',
		'Königslutter am Elm',
		'Isernhagen Farster Bauerschaft',
		'Hude',
		'Holzminden',
		'Hildesheim',
		'Hessisch Oldendorf',
		'Hemmingen',
		'Helmstedt',
		'Haren',
		'Hannover',
		'Hameln',
		'Göttingen',
		'Goslar',
		'Gifhorn',
		'Georgsmarienhütte',
		'Garbsen',
		'Ganderkesee',
		'Friesoythe',
		'Emden',
		'Einbeck',
		'Edewecht',
		'Duderstadt',
		'Diepholz',
		'Delmenhorst',
		'Damme',
		'Cuxhaven',
		'Cloppenburg',
		'Clausthal-Zellerfeld',
		'Celle',
		'Buxtehude',
		'Burgdorf',
		'Bückeburg',
		'Buchholz in der Nordheide',
		'Bremervörde',
		'Braunschweig',
		'Bramsche',
		'Brake (Unterweser)',
		'Bassum',
		'Bad Zwischenahn',
		'Bad Pyrmont',
		'Bad Münder am Deister',
		'Bad Harzburg',
		'Bad Essen',
		'Bad Bentheim',
		'Aurich',
		'Alfeld',
		'Achim',
	],
	'Lower Shabeelle': ['Wanlaweyn', 'Qoryooley', 'Marka', 'Afgooye'],
	'Lower Silesia': [
		'Złotoryja',
		'Zgorzelec',
		'Ząbkowice Śląskie',
		'Wrocław',
		'Wałbrzych',
		'Świebodzice',
		'Świdnica',
		'Strzegom',
		'Polkowice',
		'Oleśnica',
		'Oława',
		'Nowa Ruda',
		'Lubin',
		'Lubań',
		'Legnica',
		'Kłodzko',
		'Kamienna Góra',
		'Jelenia Góra',
		'Jelcz',
		'Jawor',
		'Głogów',
		'Dzierżoniów',
		'Bolesławiec',
		'Boguszów-Gorce',
		'Bogatynia',
		'Bielawa',
		'Jelcz Laskowice',
		'Osiedle Kosmonautów',
		'Rejon placu Grunwaldzkiego',
		'Rejon ulicy Traugutta',
		'Kozanów',
		'Rejon placu Świętego Macieja',
		'Różanka-Polanka',
	],
	Lualaba: ['Kolwezi'],
	Luanda: ['Luanda'],
	'Luanda Norte': ['Lucapa'],
	Luapula: ['Nchelenge', 'Kawambwa', 'Samfya', 'Mansa'],
	Lublin: [
		'Zamość',
		'Tomaszów Lubelski',
		'Świdnik',
		'Radzyń Podlaski',
		'Puławy',
		'Międzyrzec Podlaski',
		'Łuków',
		'Lublin',
		'Lubartów',
		'Łęczna',
		'Krasnystaw',
		'Kraśnik',
		'Hrubieszów',
		'Dęblin',
		'Chełm',
		'Biłgoraj',
		'Biała Podlaska',
	],
	Lubusz: [
		'Zielona Góra',
		'Żary',
		'Żagań',
		'Świebodzin',
		'Sulechów',
		'Słubice',
		'Nowa Sól',
		'Międzyrzecz',
		'Kostrzyn nad Odrą',
		'Gubin',
		'Gorzów Wielkopolski',
	],
	Lucerne: ['Luzern', 'Littau', 'Kriens', 'Emmen'],
	Luhansk: [
		'Sievierodonetsk',
		'Dovzhansk',
		'Svatove',
		'Starobilsk',
		'Kadiyivka',
		'Rubizhne',
		'Rovenky',
		'Popasna',
		'Pervomaisk',
		'Perevalsk',
		'Molodohvardiisk',
		'Lutuhyne',
		'Luhansk',
		'Lysychansk',
		'Kreminna',
		'Khrustalnyi',
		'Sorokyne',
		'Holubivka',
		'Chervonopartyzansk',
		'Brianka',
		'Antratsyt',
		'Alchevsk',
	],
	'Lumbini Province': ['Tulsīpur', 'Tānsen', 'Gulariyā', 'Butwāl', 'Siddharthanagar', 'Nepalgunj'],
	'Lunda Sul': ['Saurimo'],
	Lusaka: ['Lusaka', 'Kafue'],
	Luxembourg: ['Luxembourg'],
	Luxor: ['Luxor'],
	Lviv: [
		'Zolochiv',
		'Truskavets',
		'Stryi',
		'Stebnyk',
		'Sokal',
		'Sambir',
		'Mostyska',
		'Lviv',
		'Horodok',
		'Drohobych',
		'Chervonohrad',
		'Brody',
		'Boryslav',
		"Novoyavorivs'k",
	],
	'Lào Cai': ['Lào Cai'],
	'Lâm Đồng': ['Đưc Trọng', 'Ðà Lạt', 'Bảo Lộc', 'Đinh Văn', 'Huyện Lâm Hà'],
	Lékoumou: ['Sibiti'],
	Lənkəran: ['Lankaran'],
	'Lạng Sơn': ['Lạng Sơn'],
	"M'Sila": ['Sidi Aïssa', 'M’sila', 'Melouza', '’Aïn el Melh', '‘Aïn el Hadjel'],
	Machakos: ['Machakos', 'Athi River'],
	Madaba: ['Mādabā'],
	Madang: ['Madang'],
	Madeira: ['São Martinho', 'Funchal', 'Caniço', 'Câmara de Lobos'],
	'Madhya Pradesh': [
		'Wārāseonī',
		'Vidisha',
		'Unhel',
		'Umaria',
		'Ujjain',
		'Udaipura',
		'Tīkamgarh',
		'Teonthar',
		'Tarāna',
		'Sohāgpur',
		'Sironj',
		'Sihorā',
		'Sidhi',
		'Shujālpur',
		'Shivpuri',
		'Sheopur',
		'Shāmgarh',
		'Shājāpur',
		'Shāhpur',
		'Shahdol',
		'Seoni Mālwa',
		'Seoni',
		'Seondha',
		'Sendhwa',
		'Sehore',
		'Sausar',
		'Satna',
		'Sārangpur',
		'Sanāwad',
		'Saugor',
		'Sabalgarh',
		'Rewa',
		'Rehli',
		'Ratlām',
		'Rāmpura',
		'Rajpur',
		'Rajpur',
		'Rājgarh',
		'Rājgarh',
		'Raisen',
		'Rāhatgarh',
		'Rāghogarh',
		'Punāsa',
		'Porsa',
		'Patharia',
		'Parāsia',
		'Panna',
		'Pāndhurnā',
		'Panāgar',
		'Pāli',
		'Palera',
		'Nepānagar',
		'Nasrullāhganj',
		'Narwar',
		'Narsinghgarh',
		'Narsimhapur',
		'Naraini',
		'Nainpur',
		'Nāgod',
		'Nagda',
		'Murwāra',
		'Mungaoli',
		'Multai',
		'Morena',
		'Morār',
		'Mihona',
		'Mauganj',
		'Mau',
		'Mandsaur',
		'Mandlā',
		'Manāwar',
		'Manāsa',
		'Maksi',
		'Maihar',
		'Mahgawān',
		'Maheshwar',
		'Leteri',
		'Lakhnādon',
		'Lahār',
		'Kumbhrāj',
		'Kukshi',
		'Kotma',
		'Korwai',
		'Kolāras',
		'Khurai',
		'Khirkiyān',
		'Khilchipur',
		'Khātegaon',
		'Khargone',
		'Khandwa',
		'Khamaria',
		'Khajuraho Group of Monuments',
		'Khāchrod',
		'Katangi',
		'Katangi',
		'Kasrāwad',
		'Karera',
		'Kareli',
		'Kannod',
		'Kaimori',
		'Kailāras',
		'Jora',
		'Jhābua',
		'Jāwad',
		'Jatāra',
		'Jaorā',
		'Jāmai',
		'Jabalpur',
		'Itārsi',
		'Indore',
		'Narmadapuram',
		'Hindoria',
		'Hatta',
		'Harsūd',
		'Harpālpur',
		'Harda Khās',
		'Gwalior',
		'Guna',
		'Gohadi',
		'Garhākota',
		'Gādarwāra',
		'Dindori',
		'Dhār',
		'Dhāmnod',
		'Dewas',
		'Depālpur',
		'Deori Khās',
		'Datia',
		'Damoh',
		'Dabra',
		'Daboh',
		'Chhindwāra',
		'Chhatarpur',
		'Chanderi',
		'Burhar',
		'Burhānpur',
		'Etāwa',
		'Bijāwar',
		'Biaora',
		'Bhopāl',
		'Bhitarwār',
		'Bhind',
		'Bhikangaon',
		'Bhawāni Mandi',
		'Bhānpura',
		'Bhānder',
		'Bhainsdehi',
		'Betūl',
		'Berasia',
		'Beohāri',
		'Begamganj',
		'Bāsoda',
		'Barwāni',
		'Bargi',
		'Banda',
		'Bāmor Kalān',
		'Bālāghāt',
		'Baihar',
		'Badnāwar',
		'Bābai',
		'Ashta',
		'Ashoknagar',
		'Āron',
		'Anūppur',
		'Anjad',
		'Amla',
		'Ambāh',
		'Amarpātan',
		'Alot',
		'Agar',
		'Pithampur',
		'Mandideep',
		'Singrauli',
	],
	'Madre de Dios': ['Tambopata', 'Puerto Maldonado'],
	Madrid: [
		'Villaviciosa de Odón',
		'Villaverde',
		'Villanueva del Pardillo',
		'Villanueva de la Cañada',
		'Vicálvaro',
		'Valdemoro',
		'Rivas-Vaciamadrid',
		'Torrelodones',
		'Torrejón de Ardoz',
		'Tetuán de las Victorias',
		'San Sebastián de los Reyes',
		'San Martín de la Vega',
		'San Lorenzo de El Escorial',
		'San Fernando de Henares',
		'Puente de Vallecas',
		'Pozuelo de Alarcón',
		'Pinto',
		'Parla',
		'Navalcarnero',
		'Móstoles',
		'Moratalaz',
		'Mejorada del Campo',
		'Majadahonda',
		'Madrid',
		'Leganés',
		'Las Rozas de Madrid',
		'Humanes de Madrid',
		'Hortaleza',
		'Getafe',
		'Galapagar',
		'Fuenlabrada',
		'El Escorial',
		'Coslada',
		'Colmenar Viejo',
		'Collado-Villalba',
		'Ciudad Lineal',
		'Ciempozuelos',
		'Chamartín',
		'Boadilla del Monte',
		'Barajas de Madrid',
		'Arroyomolinos',
		'Arganda',
		'Aranjuez',
		'Algete',
		'Alcorcón',
		'Alcobendas',
		'Alcalá de Henares',
		'Pinar de Chamartín',
		'Moncloa-Aravaca',
		'Arganzuela',
		'San Blas-Canillejas',
		'Latina',
		'Usera',
		'Salamanca',
		'Chamberí',
		'Carabanchel',
		'Madrid Centro',
		'Retiro',
		'Tres Cantos',
		'Fuencarral-El Pardo',
		'Villa de Vallecas',
	],
	'Madriz Department': ['Somoto'],
	Maekel: ['Asmara'],
	Mafeteng: ['Mafeteng'],
	Mafraq: ['Mafraq', 'Rukban'],
	'Magadan Oblast': ['Magadan'],
	Magdalena: [
		'Sitionuevo',
		'Santa Marta',
		'Puebloviejo',
		'Pivijay',
		'Nueva Granada',
		'Guamal',
		'Fundación',
		'El Retén',
		'El Banco',
		'Ciénaga',
		'Chivolo',
		'Aracataca',
		'Plato',
	],
	Magway: ['Yenangyaung', 'Thayetmyo', 'Taungdwingyi', 'Pakokku', 'Minbu', 'Magway', 'Chauk', 'Myaydo'],
	'Maha Sarakham': ['Maha Sarakham'],
	Maharashtra: [
		'Yeola',
		'Yavatmāl',
		'Yāval',
		'Wāshīm',
		'Warud',
		'Warora',
		'Wardha',
		'Wani',
		'Wai',
		'Vite',
		'Virār',
		'Vasind',
		'Varangaon',
		'Vaijāpur',
		'Vāda',
		'Uran',
		'Umred',
		'Umarkhed',
		'Umarga',
		'Ulhasnagar',
		'Udgīr',
		'Tumsar',
		'Tuljāpur',
		'Thāne',
		'Telhāra',
		'Tāsgaon',
		'Taloda',
		'Talegaon Dābhāde',
		'Srīvardhan',
		'Soygaon',
		'Sirūr',
		'Sinnar',
		'Sillod',
		'Shrīgonda',
		'Solāpur',
		'Shirpur',
		'Shirdi',
		'Shegaon',
		'Shāhāda',
		'Sāvda',
		'Sāvantvādi',
		'Satara',
		'Satānā',
		'Sāsvad',
		'Saoner',
		'Sāngola',
		'Sāngli',
		'Sangamner',
		'Selu',
		'Roha',
		'Risod',
		'Rāver',
		'Ratnagiri',
		'Rāmtek',
		'Rājūra',
		'Rājgurunagar',
		'Rāhuri',
		'Rahimatpur',
		'Pusad',
		'Pūrna',
		'Pune',
		'Pulgaon',
		'Pīpri',
		'Pimpri',
		'Phaltan',
		'Pen',
		'Pawni',
		'Pātūr',
		'Pāthri',
		'Pāthardi',
		'Partūr',
		'Parola',
		'Parli Vaijnāth',
		'Parbhani',
		'Panvel',
		'Pandharpur',
		'Pālghar',
		'Paithan',
		'Pāchora',
		'Dharashiv',
		'Ozar',
		'Nipāni',
		'Nilanga',
		'Neral',
		'Nashik',
		'Nandurbar',
		'Nāndūra Buzurg',
		'Nāndgaon',
		'Nanded',
		'Naldurg',
		'Nagpur',
		'Murtajāpur',
		'Murbād',
		'Mūl',
		'Mukher',
		'Mudkhed',
		'Morsi',
		'Moram',
		'Mhāsvād',
		'Mehkar',
		'Mānwat',
		'Manmād',
		'Majalgaon',
		'Mangrūl Pīr',
		'Mālvan',
		'Malkāpur',
		'Mālegaon',
		'Mahād',
		'Lonavla',
		'Lonar',
		'Latur',
		'Kurduvādi',
		'Kurandvād',
		'Koynanagar',
		'Koregaon',
		'Kopargaon',
		'Kolhāpur',
		'Kodoli',
		'Kinwat',
		'Khuldābād',
		'Khopoli',
		'Khetia',
		'Kharakvasla',
		'Khāpa',
		'Khāmgaon',
		'Khadki',
		'Kātol',
		'Karmāla',
		'Karjat',
		'Kāranja',
		'Karād',
		'Kannad',
		'Kankauli',
		'Kāmthi',
		'Kalyān',
		'Kalmeshwar',
		'Kalamnūri',
		'Kalamb',
		'Kāgal',
		'Junnar',
		'Jintūr',
		'Jālna',
		'Jalgaon Jamod',
		'Jalgaon',
		'Jaisingpur',
		'Indāpur',
		'Igatpuri',
		'Ichalkaranji',
		'Hingoli',
		'Hinganghāt',
		'Hadgāon',
		'Gondiā',
		'Ghugus',
		'Ghoti Budrukh',
		'Ghātanji',
		'Gevrai',
		'Gangāpur',
		'Gangākher',
		'Gadhinglaj',
		'Faizpur',
		'Erandol',
		'Durgāpur',
		'Dondaicha',
		'Dombivli',
		'Digras',
		'Dīglūr',
		'Dhūlia',
		'Dhārūr',
		'Dharmābād',
		'Dharangaon',
		'Deūlgaon Rāja',
		'Deoli',
		'Deolāli',
		'Daund',
		'Dattāpur',
		'Daryāpur',
		'Dārwha',
		'Dāhānu',
		'Chopda',
		'Chiplūn',
		'Chikhli',
		'Chicholi',
		'Chāndor',
		'Chāndūr Bāzār',
		'Chāndūr',
		'Chāndur',
		'Chānda',
		'Chālisgaon',
		'Chākan',
		'Buldāna',
		'Borivli',
		'Mumbai',
		'Boisar',
		'Bhusāval',
		'Bhūm',
		'Bhudgaon',
		'Bhor',
		'Bhiwandi',
		'Bhayandar',
		'Bhandāra',
		'Basmat',
		'Bārsi',
		'Bārāmati',
		'Ballālpur',
		'Bālāpur',
		'Badlapur',
		'Ausa',
		'Sambhaji Nagar',
		'Ashta',
		'Ārvi',
		'Ārangaon',
		'Anshing',
		'Anjangaon',
		'Amrāvati',
		'Ambājogāi',
		'Ambad',
		'Amarnāth',
		'Amalner',
		'Alībāg',
		'Alandi',
		'Akot',
		'Akola',
		'Akalkot',
		'Ajra',
		'Ahmadpur',
		'Ahmadnagar',
		'Achalpur',
		'Powai',
		'Navi Mumbai',
		'Shivaji Nagar',
		'Airoli',
		'Shiraguppi',
		'Malkapur',
		'Shahuwadi',
	],
	'Mahdia Governorate': ['Ksour Essaf', 'Zouila', 'Chebba', 'Mahdia', 'El Jem'],
	'Mai-Ndombe': ['Nioki', 'Mushie', 'Inongo', 'Bolobo'],
	Maine: [
		'Auburn',
		'Augusta',
		'Bangor',
		'Biddeford',
		'Brunswick',
		'Lewiston',
		'Portland',
		'Saco',
		'Sanford',
		'South Portland',
		'South Portland Gardens',
		'Waterville',
		'West Scarborough',
		'Westbrook',
	],
	'Majuro Atoll': ['Majuro', 'RMI Capitol'],
	Makamba: ['Makamba'],
	Makueni: ['Makueni Boma'],
	Malanje: ['Malanje'],
	Malatya: ['Malatya', 'Darende'],
	Maldonado: ['San Carlos', 'Maldonado'],
	Maluku: ['Tual', 'Ambon', 'Amahai', 'Masohi'],
	'Mambéré-Kadéï': ['Carnot', 'Berbérati'],
	Mamou: ['Pita', 'Mamou'],
	Mamoudzou: ['Mamoudzou'],
	Manabí: ['Sucre', 'Portoviejo', 'Montecristi', 'Manta', 'Jipijapa', 'Chone', 'Calceta', 'Bahía de Caráquez'],
	'Managua Department': ['Tipitapa', 'San Rafael del Sur', 'Managua', 'El Crucero', 'Ciudad Sandino'],
	Manama: ['Sitrah', 'Jidd Ḩafş', 'Manama'],
	Manatí: ['Manatí'],
	'Manawatu-Wanganui': ['Whanganui', 'Palmerston North', 'Levin', 'Linton Military Camp'],
	Manchester: ['Mandeville'],
	Mandalay: ['Yamethin', 'Myingyan', 'Mogok', 'Meiktila', 'Pyin Oo Lwin', 'Mandalay', 'Kyaukse'],
	Mandera: ['Mandera'],
	Mandoul: ['Koumra'],
	Mangghystaū: ['Zhanaozen', 'Shevchenko'],
	Mangilao: ['Mangilao Village'],
	Manica: ['Chimoio'],
	Manicaland: ['Rusape', 'Mutare', 'Chipinge'],
	Maniema: ['Kindu', 'Kasongo', 'Kampene'],
	Manipur: ['Thoubāl', 'Phek', 'Moirāng', 'Mayāng Imphāl', 'Kakching', 'Imphāl', 'Churāchāndpur'],
	Manisa: ['Turgutlu', 'Soma', 'Salihli', 'Manisa', 'Kula', 'Kırkağaç', 'Demirci', 'Alaşehir', 'Akhisar'],
	Manitoba: ['Brandon', 'Steinbach', 'Winnipeg'],
	Manouba: ['Oued Lill', 'Manouba'],
	Manyara: ['Nangwa', 'Magugu', 'Galappo', 'Dongobesh', 'Dareda', 'Basotu', 'Bashanet', 'Babati'],
	Manzini: ['Manzini'],
	'Maputo City': ['Maputo'],
	'Maputo Province': ['Ressano Garcia', 'Matola'],
	Mara: ['Tarime', 'Sirari', 'Nyamuswa', 'Musoma', 'Muriti', 'Mugumu', 'Kibara', 'Butiama', 'Bunda'],
	Maradi: ['Tibiri', 'Tessaoua', 'Mayahi', 'Maradi', 'Dakoro'],
	Maramureş: ['Vişeu de Sus', 'Sighetu Marmaţiei', 'Borşa', 'Baia Sprie', 'Baia Mare'],
	Maranhão: [
		'Vitória do Mearim',
		'Viana',
		'Vargem Grande',
		'Tuntum',
		'Timon',
		'Timbiras',
		'São Mateus do Maranhão',
		'São Luís',
		'São José de Ribamar',
		'São João dos Patos',
		'São Domingos do Maranhão',
		'São Bento',
		'Santa Quitéria do Maranhão',
		'Santa Luzia',
		'Santa Inês',
		'Santa Helena',
		'Presidente Dutra',
		'Pinheiro',
		'Pindaré Mirim',
		'Penalva',
		'Lago da Pedra',
		'Itapecuru Mirim',
		'Imperatriz',
		'Grajaú',
		'Estreito',
		'Dom Pedro',
		'Cururupu',
		'Coroatá',
		'Colinas',
		'Coelho Neto',
		'Codó',
		'Chapadinha',
		'Caxias',
		'Carolina',
		'Buriti Bravo',
		'Barreirinhas',
		'Barra do Corda',
		'Balsas',
		'Bacabal',
		'Arari',
	],
	Mardin: ['Yeşilli', 'Nusaybin', 'Midyat', 'Mardin', 'Kızıltepe', 'Dargeçit'],
	Margibi: ['Kakata'],
	Maribor: ['Maribor'],
	'Mariehamns stad': ['Mariehamn'],
	'Marijampolė County': ['Marijampolė'],
	Maritime: ['Vogan', 'Tsévié', 'Lomé', 'Aného'],
	'Mariy-El Republic': ['Yoshkar-Ola', 'Volzhsk', 'Medvedevo', 'Koz’modem’yansk'],
	Markazi: ['Sāveh', 'Khomeyn', 'Delījān', 'Arāk', 'Ābyek'],
	Marlborough: ['Blenheim'],
	'Marrakesh-Safi': ['Setti Fatma', 'Youssoufia', 'Safi', 'Marrakesh', 'Essaouira'],
	Marsabit: ['Moyale', 'Marsabit'],
	Martinique: ['Saint-Joseph', 'Sainte-Marie', 'Le Robert', 'Le Lamentin', 'Le François', 'La Trinité', 'Fort-de-France', 'Ducos'],
	Mary: ['Yolöten', 'Seydi', 'Mary', 'Bayramaly'],
	Maryland: [
		'Harper',
		'Aberdeen',
		'Adelphi',
		'Annapolis',
		'Arbutus',
		'Arnold',
		'Aspen Hill',
		'Baltimore',
		'Ballenger Creek',
		'Beltsville',
		'Bethesda',
		'Bowie',
		'Calverton',
		'Camp Springs',
		'Carney',
		'Catonsville',
		'Chillum',
		'Cloverly',
		'Clinton',
		'Cockeysville',
		'College Park',
		'Columbia',
		'Crofton',
		'Cumberland',
		'Damascus',
		'Dundalk',
		'East Riverdale',
		'Easton',
		'Edgewood',
		'Eldersburg',
		'Elkton',
		'Elkridge',
		'Ellicott City',
		'Essex',
		'Fairland',
		'Ferndale',
		'Fort Washington',
		'Frederick',
		'Gaithersburg',
		'Germantown',
		'Glassmanor',
		'Glen Burnie',
		'Green Haven',
		'Greenbelt',
		'Gwynn Oak',
		'Hagerstown',
		'Hanover',
		'Hillcrest Heights',
		'Hunt Valley',
		'Hyattsville',
		'Ilchester',
		'Lake Shore',
		'Landover',
		'Langley Park',
		'Laurel',
		'Lochearn',
		'Maryland City',
		'Middle River',
		'Milford Mill',
		'Montgomery Village',
		'North Bel Air',
		'North Bethesda',
		'North Potomac',
		'Odenton',
		'Olney',
		'Owings Mills',
		'Oxon Hill',
		'Parkville',
		'Parole',
		'Pasadena',
		'Perry Hall',
		'Pikesville',
		'Potomac',
		'Randallstown',
		'Redland',
		'Reisterstown',
		'Rockville',
		'Rosedale',
		'Rossville',
		'Saint Charles',
		'Salisbury',
		'Scaggsville',
		'Seabrook',
		'Severn',
		'Severna Park',
		'Silver Spring',
		'South Bel Air',
		'South Gate',
		'South Laurel',
		'Suitland',
		'Takoma Park',
		'Towson',
		'Waldorf',
		'West Elkridge',
		'Westminster',
		'Wheaton',
		'White Oak',
		'Woodlawn',
		'Bel Air North',
		'Bel Air South',
		'Oxon Hill-Glassmanor',
		'Greater Upper Marlboro',
		'Lanham-Seabrook',
		'Lutherville-Timonium',
		'St. Charles',
		'Suitland-Silver Hill',
	],
	'María Trinidad Sánchez': ['Nagua'],
	'Masaya Department': ['Masaya', 'Masatepe'],
	Mascara: ['Sig', 'Oued el Abtal', 'Mascara', 'Bou Hanifia el Hamamat'],
	Maseru: ['Maseru'],
	'Mashonaland Central': ['Bindura'],
	'Mashonaland East': ['Ruwa', 'Marondera'],
	'Mashonaland West': ['Norton', 'Karoi', 'Kariba', 'Kadoma', 'Chinhoyi', 'Chegutu'],
	Massachusetts: [
		'North Andover',
		'Revere',
		'Abington',
		'Acton',
		'Agawam',
		'Amesbury',
		'Amherst',
		'Amherst Center',
		'Arlington',
		'Ashland',
		'Attleboro',
		'Auburn',
		'Barnstable',
		'Belmont',
		'Beverly',
		'Beverly Cove',
		'Billerica',
		'Boston',
		'Brockton',
		'Brookline',
		'Burlington',
		'Cambridge',
		'Canton',
		'Chelmsford',
		'Chelsea',
		'Chicopee',
		'Concord',
		'Danvers',
		'Dedham',
		'Dracut',
		'Duxbury',
		'East Longmeadow',
		'Easthampton',
		'Easton',
		'Everett',
		'Fairhaven',
		'Fall River',
		'Fitchburg',
		'Framingham',
		'Framingham Center',
		'Franklin',
		'Gardner',
		'Gloucester',
		'Grafton',
		'Greenfield',
		'Hanover',
		'Haverhill',
		'Holden',
		'Holyoke',
		'Jamaica Plain',
		'Lawrence',
		'Leominster',
		'Lexington',
		'Longmeadow',
		'Lowell',
		'Ludlow',
		'Lynn',
		'Malden',
		'Mansfield',
		'Marblehead',
		'Marlborough',
		'Medford',
		'Melrose',
		'Methuen',
		'Middleborough',
		'Milford',
		'Milton',
		'Natick',
		'Needham',
		'New Bedford',
		'Newburyport',
		'Newton',
		'North Chicopee',
		'Northampton',
		'Norton',
		'Norwood',
		'Palmer',
		'Peabody',
		'Pittsfield',
		'Quincy',
		'Randolph',
		'Reading',
		'Rockland',
		'Salem',
		'Saugus',
		'Shrewsbury',
		'Somerset',
		'Somerville',
		'South Boston',
		'South Hadley',
		'South Peabody',
		'Southbridge',
		'Springfield',
		'Stoneham',
		'Stoughton',
		'Sudbury',
		'Swansea',
		'Taunton',
		'Tewksbury',
		'Wakefield',
		'Waltham',
		'Watertown',
		'Wellesley',
		'West Springfield',
		'Westfield',
		'Westford',
		'Weymouth',
		'Wilmington',
		'Winchester',
		'Winthrop',
		'Woburn',
		'Worcester',
		'Yarmouth',
		'Braintree',
		'North Attleborough Center',
	],
	Masvingo: ['Zvishavane', 'Masvingo', 'Chiredzi'],
	'Matabeleland North': ['Victoria Falls', 'Hwange'],
	'Matabeleland South': ['Gwanda', 'Beitbridge'],
	'Matagalpa Department': ['Río Blanco', 'Matagalpa'],
	Matam: ['Matam'],
	'Matanzas Province': [
		'Varadero',
		'Unión de Reyes',
		'Perico',
		'Pedro Betancourt',
		'Matanzas',
		'Limonar',
		'Jovellanos',
		'Jagüey Grande',
		'Colón',
		'Cárdenas',
		'Calimete',
	],
	'Mato Grosso': [
		'Várzea Grande',
		'Rondonópolis',
		'Pontes e Lacerda',
		'Poconé',
		'Nova Olímpia',
		'Lucas',
		'Jaciara',
		'Diamantino',
		'Cuiabá',
		'Nova Xavantina',
		'Barra do Garças',
		'Barra do Bugres',
		'Aripuanã',
		'Sinop',
	],
	'Mato Grosso do Sul': [
		'Três Lagoas',
		'Sidrolândia',
		'Rio Verde de Mato Grosso',
		'Rio Brilhante',
		'Ponta Porã',
		'Paranaíba',
		'Naviraí',
		'Maracaju',
		'Ladário',
		'Jardim',
		'Dourados',
		'Coxim',
		'Corumbá',
		'Cassilândia',
		'Campo Verde',
		'Campo Grande',
		'Bela Vista',
		'Aquidauana',
		'Aparecida do Taboado',
		'Anastácio',
	],
	Matruh: ['Sīdī Barānī', 'Mersa Matruh'],
	'Maule Region': ['Talca', 'San Javier', 'Parral', 'Molina', 'Linares', 'Curicó', 'Constitución', 'Cauquenes'],
	Mayabeque: ['Santa Cruz del Norte', 'San José de las Lajas', 'Madruga', 'La Salud', 'Jaruco', 'Güines', 'Bejucal'],
	Mayagüez: ['Mayagüez'],
	Mayaro: ['Rio Claro'],
	'Mayo-Kebbi Est': ['Bongor'],
	'Mayo-Kebbi Ouest': ['Pala', 'Mboursou Léré'],
	Maysan: ['‘Alī al Gharbī', 'Al ‘Amārah'],
	Mazovia: [
		'Żyrardów',
		'Żoliborz',
		'Zielonka',
		'Ząbki',
		'Wyszków',
		'Wołomin',
		'Wola',
		'Włochy',
		'Wesoła',
		'Wawer',
		'Warsaw',
		'Ursus',
		'Targówek',
		'Sulejówek',
		'Śródmieście',
		'Sokołów Podlaski',
		'Sochaczew',
		'Siedlce',
		'Rembertów',
		'Radom',
		'Pułtusk',
		'Przasnysz',
		'Pruszków',
		'Płońsk',
		'Pionki',
		'Piastów',
		'Piaseczno',
		'Otwock',
		'Ostrów Mazowiecka',
		'Ostrołęka',
		'Ochota',
		'Nowy Dwór Mazowiecki',
		'Mokotów',
		'Mława',
		'Mińsk Mazowiecki',
		'Milanówek',
		'Marki',
		'Łomianki',
		'Legionowo',
		'Kozienice',
		'Konstancin-Jeziorna',
		'Kobyłka',
		'Kabaty',
		'Józefów',
		'Grodzisk Mazowiecki',
		'Garwolin',
		'Ciechanów',
		'Bielany',
		'Białołeka',
		'Bemowo',
		'Sierpc',
		'Płock',
		'Gostynin',
		'Ursynów',
		'Praga Północ',
		'Praga Południe',
	],
	'Ma’an': ["Ma'an", 'Qīr Moāv'],
	'Ma’rib': ["Ma'rib"],
	Mbeya: ['Tukuyu', 'Rujewa', 'Mbeya', 'Kyela', 'Kiwira', 'Katumba', 'Ipinda', 'Igurusi', 'Chimala'],
	Mbomou: ['Bangassou'],
	'Mecca Region': ['Turabah', 'Rābigh', 'Mecca', 'Jeddah', 'Ta’if', 'Al Jumūm', 'Ash Shafā'],
	'Mecklenburg-Vorpommern': [
		'Wismar',
		'Waren',
		'Stralsund',
		'Schwerin',
		'Rostock',
		'Ribnitz-Damgarten',
		'Parchim',
		'Neustrelitz',
		'Neubrandenburg',
		'Güstrow',
		'Greifswald',
		'Kröpeliner-Tor-Vorstadt',
	],
	Medea: ['Médéa', 'Ksar el Boukhari', 'Berrouaghia'],
	'Medenine Governorate': ['Midoun', 'Medenine', 'Zarzis', 'Houmt El Souk'],
	'Medina Region': ['Yanbu', 'Sulţānah', 'Badr Ḩunayn', 'Al-`Ula', 'Medina'],
	Meghalaya: ['Tura', 'Shillong', 'Nongstoin', 'Mankāchar'],
	Mehedinți: ['Drobeta-Turnu Severin'],
	Melaka: [
		'Alor Gajah',
		'Kampung Ayer Keroh',
		'Sungai Udang',
		'Malacca',
		'Klebang Besar',
		'Kampong Masjid Tanah',
		'Kampung Bukit Baharu',
		'Kampung Ayer Molek',
		'Bukit Rambai',
		'Batu Berendam',
	],
	Melaky: ['Maintirano'],
	Melekeok: ['Ngerulmud'],
	Melilla: ['Melilla'],
	Menabe: ['Morondava', 'Miandrivazo', 'Belo sur Tsiribihina'],
	Mendoza: ['San Rafael', 'San Martín', 'Mendoza'],
	Mersin: ['Tarsus', 'Silifke', 'Mut', 'Mersin', 'Erdemli', 'Bozyazı', 'Anamur'],
	Meru: ['Meru'],
	Meta: ['Villavicencio', 'San Martín', 'Restrepo', 'Puerto López', 'Granada', 'Acacías'],
	'Metro Manila': [
		'Tanza',
		'Taguig',
		'Santa Cruz',
		'San Juan',
		'Quiapo',
		'Quezon City',
		'Port Area',
		'Pasay',
		'Paranaque City',
		'Paco',
		'Navotas',
		'Manila',
		'Mandaluyong City',
		'Makati City',
		'Las Piñas',
		'Calumpang',
		'Caloocan City',
		'Binondo',
		'Upper Bicutan',
		'Bel Air',
		'Bagong Silangan',
		'Pasig City',
		'Ayala Alabang',
		'Banco Filipino Homes',
	],
	'Mexico City': [
		'Xochimilco',
		'Álvaro Obregón',
		'Gustavo Adolfo Madero',
		'San Miguel Topilejo',
		'Tlalpan',
		'Tláhuac',
		'San Miguel Ajusco',
		'San Juan Ixtayopan',
		'Polanco',
		'San Pablo Oztotepec',
		'Milpa Alta',
		'Magdalena Contreras',
		'Iztapalapa',
		'Iztacalco',
		'Delegación Cuajimalpa de Morelos',
		'Coyoacán',
		'Mexico City',
		'Azcapotzalco',
		'San Lorenzo Acopilco',
		'Benito Juarez',
		'Venustiano Carranza',
		'Miguel Hidalgo',
		'Cuauhtémoc',
		'Benito Juárez',
		'Colonia del Valle',
		'Colonia Nativitas',
		'San Antonio Tecómitl',
	],
	Michigan: [
		'Inkster',
		'Adrian',
		'Allen Park',
		'Allendale',
		'Ann Arbor',
		'Auburn Hills',
		'Battle Creek',
		'Bay City',
		'Berkley',
		'Birmingham',
		'Burton',
		'Canton',
		'Clinton Township',
		'Dearborn',
		'Dearborn Heights',
		'Detroit',
		'East Lansing',
		'Eastpointe',
		'Farmington Hills',
		'Ferndale',
		'Flint',
		'Forest Hills',
		'Garden City',
		'Grand Rapids',
		'Grandville',
		'Grosse Pointe Woods',
		'Hamtramck',
		'Haslett',
		'Hazel Park',
		'Holland',
		'Holt',
		'Jackson',
		'Jenison',
		'Kalamazoo',
		'Kentwood',
		'Lansing',
		'Lincoln Park',
		'Livonia',
		'Madison Heights',
		'Marquette',
		'Midland',
		'Monroe',
		'Mount Clemens',
		'Mount Pleasant',
		'Muskegon',
		'Norton Shores',
		'Novi',
		'Oak Park',
		'Okemos',
		'Pontiac',
		'Port Huron',
		'Portage',
		'Redford',
		'Rochester Hills',
		'Romulus',
		'Roseville',
		'Royal Oak',
		'Saginaw',
		'Shelby',
		'Southfield',
		'Southgate',
		'Saint Clair Shores',
		'Sterling Heights',
		'Taylor',
		'Traverse City',
		'Trenton',
		'Troy',
		'Walker',
		'Warren',
		'Waterford',
		'Waverly',
		'Wayne',
		'Westland',
		'Wyandotte',
		'Wyoming',
		'Ypsilanti',
		'Saginaw Township North',
		'West Bloomfield Township',
	],
	Michoacán: [
		'Zinapécuaro',
		'Zamora',
		'Zacapú',
		'Yurécuaro',
		'Uruapan',
		'Tuzantla',
		'Tepalcatepec',
		'Tangancícuaro de Arista',
		'Tacámbaro de Codallos',
		'Sahuayo de Morelos',
		'Puruándiro',
		'Peribán de Ramos',
		'Pátzcuaro',
		'Paracho de Verduzco',
		'Nueva Italia de Ruiz',
		'Morelia',
		'Maravatío de Ocampo',
		'Los Reyes de Salgado',
		'La Piedad',
		'La Orilla',
		'Jiquílpan de Juárez',
		'Jacona de Plancarte',
		'Huetamo de Núñez',
		'Heróica Zitácuaro',
		'Ciudad Hidalgo',
		'Ario de Rosales',
		'Apatzingán',
		'Guacamayas',
		'Ciudad Lázaro Cárdenas',
		'Zacapu',
	],
	'Middle Juba': ['Jilib'],
	'Middle Shabele': ['Jawhar'],
	Midlands: ['Shurugwi', 'Redcliff', 'Kwekwe', 'Gweru', 'Gokwe'],
	Mie: [
		'Yokkaichi',
		'Ueno-ebisumachi',
		'Tsu',
		'Toba',
		'Suzuka',
		'Shima',
		'Owase',
		'Nabari',
		'Komono',
		'Kameyama',
		'Ise',
		'Hisai-motomachi',
		'Kawage',
		'Kumano',
		'Iga',
		'Kuwana',
	],
	Migori: ['Migori'],
	Mila: ['Telerghma', 'Sidi Mérouane', 'Rouached', 'Mila', 'Chelghoum el Aïd'],
	Mimaropa: ['San Jose', 'Sablayan', 'Roxas', 'Romblon', 'Puerto Princesa', 'Pinamalayan', 'Narra', 'Mansalay', 'Mamburao', 'Calapan'],
	'Minas Gerais': [
		'Visconde do Rio Branco',
		'Viçosa',
		'Vespasiano',
		'Vazante',
		'Várzea da Palma',
		'Varginha',
		'Unaí',
		'Uberlândia',
		'Uberaba',
		'Ubá',
		'Tupaciguara',
		'Três Pontas',
		'Três Corações',
		'Timóteo',
		'Teófilo Otoni',
		'Taiobeiras',
		'Sete Lagoas',
		'Sarzedo',
		'São Sebastião do Paraíso',
		'São Lourenço',
		'São João Nepomuceno',
		'São João del Rei',
		'São Gotardo',
		'São Gonçalo do Sapucaí',
		'São Francisco',
		'Santos Dumont',
		'Santo Antônio do Monte',
		'Santo Antônio do Amparo',
		'Santa Rita do Sapucaí',
		'Santana do Paraíso',
		'Santa Luzia',
		'Salinas',
		'Sacramento',
		'Ribeirão das Neves',
		'Resplendor',
		'Prata',
		'Pouso Alegre',
		'Ponte Nova',
		'Pompéu',
		'Poços de Caldas',
		'Piuí',
		'Pitangui',
		'Pirapora',
		'Perdões',
		'Pedro Leopoldo',
		'Pedra Azul',
		'Patrocínio',
		'Patos de Minas',
		'Passos',
		'Paraguaçu',
		'Pará de Minas',
		'Paracatu',
		'Ouro Preto',
		'Ouro Branco',
		'Oliveira',
		'Nova Lima',
		'Nova Era',
		'Nepomuceno',
		'Nanuque',
		'Muzambinho',
		'Muriaé',
		'Montes Claros',
		'Monte Santo de Minas',
		'Monte Carmelo',
		'Matozinhos',
		'Mateus Leme',
		'Mariana',
		'Manhumirim',
		'Manhuaçu',
		'Machado',
		'Leopoldina',
		'Lavras',
		'Lajinha',
		'Lagoa Santa',
		'Lagoa da Prata',
		'Juiz de Fora',
		'Juatuba',
		'João Pinheiro',
		'João Monlevade',
		'Jequitinhonha',
		'Januária',
		'Janaúba',
		'Jacutinga',
		'Iturama',
		'Ituiutaba',
		'Itaúna',
		'Itapecerica',
		'Itamarandiba',
		'Itajubá',
		'Itabirito',
		'Itabira',
		'Ipatinga',
		'Ipaba',
		'Igarapé',
		'Ibirité',
		'Ibiá',
		'Guaxupé',
		'Guaranésia',
		'Guanhães',
		'Governador Valadares',
		'Frutal',
		'Formiga',
		'Espinosa',
		'Esmeraldas',
		'Elói Mendes',
		'Divinópolis',
		'Diamantina',
		'Curvelo',
		'Coronel Fabriciano',
		'Coromandel',
		'Corinto',
		'Contagem',
		'Conselheiro Lafaiete',
		'Congonhas',
		'Conceição das Alagoas',
		'Cláudio',
		'Caxambu',
		'Cataguases',
		'Carmo do Paranaíba',
		'Carmo do Cajuru',
		'Caratinga',
		'Carangola',
		'Carandaí',
		'Capelinha',
		'Campos Gerais',
		'Campo Belo',
		'Cambuí',
		'Camanducaia',
		'Caeté',
		'Buritizeiro',
		'Buritis',
		'Brumadinho',
		'Bom Despacho',
		'Bocaiúva',
		'Boa Esperança',
		'Betim',
		'Belo Oriente',
		'Belo Horizonte',
		'Barroso',
		'Barreiro do Jaíba',
		'Barbacena',
		'Barão de Cocais',
		'Bambuí',
		'Arcos',
		'Araxá',
		'Araguari',
		'Araçuaí',
		'Andradas',
		'Almenara',
		'Alfenas',
		'Além Paraíba',
		'Aimorés',
		'Águas Vermelhas',
		'Abaeté',
	],
	Mingǝcevir: ['Mingelchaur'],
	Minnesota: [
		'Albert Lea',
		'Andover',
		'Anoka',
		'Apple Valley',
		'Austin',
		'Blaine',
		'Bloomington',
		'Brooklyn Center',
		'Brooklyn Park',
		'Buffalo',
		'Burnsville',
		'Champlin',
		'Chanhassen',
		'Chaska',
		'Columbia Heights',
		'Coon Rapids',
		'Cottage Grove',
		'Crystal',
		'Duluth',
		'Eagan',
		'Eden Prairie',
		'Edina',
		'Elk River',
		'Faribault',
		'Farmington',
		'Forest Lake',
		'Fridley',
		'Golden Valley',
		'Ham Lake',
		'Hastings',
		'Hibbing',
		'Hopkins',
		'Inver Grove Heights',
		'Lakeville',
		'Lino Lakes',
		'Mankato',
		'Maple Grove',
		'Maplewood',
		'Minneapolis',
		'Minnetonka',
		'Minnetonka Mills',
		'Moorhead',
		'New Brighton',
		'New Hope',
		'Northfield',
		'Oakdale',
		'Otsego',
		'Owatonna',
		'Plymouth',
		'Prior Lake',
		'Ramsey',
		'Red Wing',
		'Richfield',
		'Rochester',
		'Rosemount',
		'Roseville',
		'Saint Cloud',
		'Saint Louis Park',
		'Saint Michael',
		'Saint Paul',
		'Sartell',
		'Savage',
		'Shakopee',
		'Shoreview',
		'South Saint Paul',
		'Stillwater',
		'West Coon Rapids',
		'West Saint Paul',
		'White Bear Lake',
		'Willmar',
		'Winona',
		'Woodbury',
	],
	Minsk: [
		'Horad Zhodzina',
		'Vilyeyka',
		'Stowbtsy',
		'Smaliavičy',
		'Slutsk',
		'Salihorsk',
		'Mar’’ina Horka',
		'Maladziečna',
		'Kalodzishchy',
		'Dzyarzhynsk',
		'Barysaw',
	],
	'Minsk City': ['Minsk'],
	Minya: ['Samālūţ', 'Maţāy', 'Mallawī', 'Maghāghah', 'Dayr Mawās', 'Banī Mazār', 'Al Minyā', 'Abū Qurqāş'],
	Miranda: [
		'Santa Teresa del Tuy',
		'San José de Barlovento',
		'San Antonio de Los Altos',
		'Río Chico',
		'Petare',
		'Ocumare del Tuy',
		'Mamporal',
		'Los Teques',
		'Los Dos Caminos',
		'Higuerote',
		'Guatire',
		'Guarenas',
		'El Hatillo',
		'Cúa',
		'Charallave',
		'Chacao',
		'Caucagüito',
		'Caucagua',
		'Carrizal',
		'Baruta',
		'El Cafetal',
		'La Dolorita',
	],
	Misiones: [
		'Wanda',
		'San Vicente',
		'San Pedro',
		'Puerto Rico',
		'Puerto Iguazú',
		'Puerto Esperanza',
		'Puerto Eldorado',
		'Posadas',
		'Oberá',
		'Montecarlo',
		'Jardín América',
		'Garupá',
		'El Soberbio',
		'Aristóbulo del Valle',
		'San Juan Bautista',
	],
	Mississippi: [
		'Biloxi',
		'Brandon',
		'Clarksdale',
		'Clinton',
		'Columbus',
		'Gautier',
		'Greenville',
		'Greenwood',
		'Gulfport',
		'Hattiesburg',
		'Hernando',
		'Horn Lake',
		'Jackson',
		'Laurel',
		'Long Beach',
		'Madison',
		'Meridian',
		'Natchez',
		'Ocean Springs',
		'Olive Branch',
		'Oxford',
		'Pascagoula',
		'Pearl',
		'Ridgeland',
		'Southaven',
		'Starkville',
		'Tupelo',
		'Vicksburg',
		'West Gulfport',
	],
	Missouri: [
		'Affton',
		'Arnold',
		'Ballwin',
		'Belton',
		'Blue Springs',
		'Cape Girardeau',
		'Chesterfield',
		'Clayton',
		'Columbia',
		'Concord',
		'Creve Coeur',
		'East Independence',
		'Farmington',
		'Ferguson',
		'Florissant',
		'Gladstone',
		'Grandview',
		'Hannibal',
		'Hazelwood',
		'Independence',
		'Jefferson City',
		'Joplin',
		'Kansas City',
		'Kirkwood',
		"Lee's Summit",
		'Lemay',
		'Liberty',
		'Manchester',
		'Maryland Heights',
		'Mehlville',
		'Nixa',
		"O'Fallon",
		'Oakville',
		'Overland',
		'Ozark',
		'Poplar Bluff',
		'Raymore',
		'Raytown',
		'Republic',
		'Rolla',
		'Saint Charles',
		'Saint Joseph',
		'St. Louis',
		'Saint Peters',
		'Sedalia',
		'Sikeston',
		'Spanish Lake',
		'Springfield',
		'University City',
		'Warrensburg',
		'Webster Groves',
		'Wentzville',
		'Wildwood',
		'Kirksville',
		'Fort Leonard Wood',
		'Old Jamestown',
	],
	Mitrovica: ['Zvečan', 'Vushtrri', 'Leposaviq', 'Mitrovicë'],
	Miyagi: [
		'Yamoto',
		'Watari',
		'Wakuya',
		'Tomiya',
		'Shiroishi',
		'Shiogama',
		'Sendai',
		'Rifu',
		'Ōkawara',
		'Matsushima',
		'Kogota',
		'Kakuda',
		'Iwanuma',
		'Ishinomaki',
		'Furukawa',
		'Ōsaki',
		'Higashimatsushima',
		'Kurihara',
		'Tome',
	],
	Miyazaki: ['Tsuma', 'Takanabe', 'Nobeoka', 'Nichinan', 'Miyazaki', 'Miyakonojō', 'Kobayashi', 'Kawaminami', 'Kadogawa', 'Kushima', 'Saito', 'Hyūga'],
	Mizoram: ['Serchhīp', 'Saiha', 'Lunglei', 'Kolasib', 'Aizawl'],
	Mişrātah: ['Zliten', 'Mişrātah', 'Bani Walid'],
	Mogilev: ['Asipovichy', 'Mahilyow', 'Krychaw', 'Horki', 'Bykhaw', 'Babruysk'],
	'Mohaleʼs Hoek': ['Mohale’s Hoek'],
	Moka: ['Saint Pierre'],
	Molise: ['Termoli', 'Isernia', 'Campobasso'],
	Mombasa: ['Mombasa'],
	Mon: ['Thaton', 'Mudon', 'Mawlamyine', 'Martaban', 'Kyaikto', 'Kyaikkami'],
	Monagas: ['Temblador', 'Punta de Mata', 'Caripito', 'Caicara', 'Barrancas', 'Maturín'],
	'Monastir Governorate': ['Skanes', 'Ksar Hellal', 'Djemmal', 'Ouardenine', 'Monastir', 'Bekalta'],
	Mongala: ['Lisala', 'Bumba'],
	Mono: ['Lokossa'],
	'Monseñor Nouel': ['Bonao'],
	'Mont-Liban': ['Jounieh', 'Jbaïl'],
	Montagnes: ['Man', 'Guiglo', 'Duekoué', 'Danané', 'Biankouma', 'Bangolo'],
	Montana: ['Montana', 'Lom', 'Berkovitsa', 'Billings', 'Bozeman', 'Butte', 'Great Falls', 'Helena', 'Kalispell', 'Missoula'],
	'Monte Cristi': ['San Fernando de Monte Cristi'],
	'Monte Plata': ['Sabana Grande de Boyá', 'Monte Plata', 'Bayaguana'],
	'Montevideo Department': ['Montevideo'],
	Montserrado: ['Monrovia', 'Bensonville'],
	Monufia: ['Talā', 'Shibīn al Kawm', 'Quwaysinā', 'Munūf', 'Ash Shuhadā’', 'Ashmūn', 'Al Bājūr'],
	Mopti: ['Mopti', 'Djénné'],
	Moquegua: ['Moquegua', 'Ilo', 'San Antonio'],
	Moravskoslezský: [
		'Třinec',
		'Starý Bohumín',
		'Ostrava',
		'Orlová',
		'Opava',
		'Nový Jičín',
		'Krnov',
		'Kopřivnice',
		'Karviná',
		'Havířov',
		'Frýdek-Místek',
		'Český Těšín',
		'Bruntál',
		'Bohumín',
	],
	Morazán: ['San Francisco'],
	'Mordoviya Republic': ['Saransk', 'Ruzayevka', 'Kovylkino'],
	Morelos: [
		'Zacatepec',
		'Yecapixtla',
		'Yautepec',
		'Xoxocotla',
		'Xochitepec',
		'Santa Rosa Treinta',
		'Tlaquiltenango',
		'Temixco',
		'Puente de Ixtla',
		'Jojutla',
		'Jiutepec',
		'Emiliano Zapata',
		'Cuernavaca',
		'Cuautla',
		'Axochiapan',
		'Tres de Mayo',
	],
	Morobe: ['Lae', 'Bulolo'],
	Morogoro: ['Ngerengere', 'Msowero', 'Morogoro', 'Mlimba', 'Mikumi', 'Malinyi', 'Magole', 'Kilosa', 'Kidodi', 'Kidatu', 'Ifakara', 'Geiro'],
	'Morona-Santiago': ['Macas'],
	Moscow: [
		'Zyuzino',
		'Zyablikovo',
		'Zhulebino',
		'Zelenograd',
		'Zamoskvorech’ye',
		'Yasenevo',
		'Vykhino-Zhulebino',
		'Altuf’yevskiy',
		'Vorob’yovo',
		'Nagornyy',
		'Novovladykino',
		'Veshnyaki',
		'Vatutino',
		'Vagonoremont',
		'Troparëvo',
		'Troitsk',
		'Tyoply Stan',
		'Tekstil’shchiki',
		'Taganskiy',
		'Sviblovo',
		'Strogino',
		'Solntsevo',
		'Sokol’niki',
		'Sokol',
		'Slobodka',
		'Shchukino',
		'Shcherbinka',
		'Severnyy',
		'Setun’',
		'Rublëvo',
		'Rostokino',
		'Ramenki',
		'Kotlovka',
		'Annino',
		'Pokrovskoye-Streshnëvo',
		'Ryazanskiy',
		'Novo-Peredelkino',
		'Otradnoye',
		'Ostankinskiy',
		'Orekhovo-Borisovo Severnoye',
		'Ochakovo-Matveyevskoye',
		'Novyye Kuz’minki',
		'Novyye Cherëmushki',
		'Novokuz’minki',
		'Novokhovrino',
		'Novogireyevo',
		'Nikulino',
		'Nikol’skoye',
		'Moscow',
		'Mikhalkovo',
		'Matveyevskoye',
		'Mar’ino',
		'Yaroslavskiy',
		'Lyublino',
		'Luzhniki',
		'Likhobory',
		'Lianozovo',
		'Leonovo',
		'Tsaritsyno',
		'Lefortovo',
		'Kuz’minki',
		'Kur’yanovo',
		'Presnenskiy',
		'Kozeyevo',
		'Kolomenskoye',
		'Kapotnya',
		'Ivanovskoye',
		'Gol’yanovo',
		'Davydkovo',
		'Cherëmushki',
		'Brateyevo',
		'Bogorodskoye',
		'Biryulëvo',
		'Bibirevo',
		'Babushkin',
		'Amin’yevo',
		'Vnukovo',
		'Moskovskiy',
		'Chertanovo Yuzhnoye',
		'Zagor’ye',
		'Orekhovo-Borisovo',
		'Metrogorodok',
		'Vostochnoe Degunino',
		'Obruchevo',
	],
	'Moscow Oblast': [
		'Zvenigorod',
		'Zhukovsky',
		'Zheleznodorozhnyy',
		'Zavety Il’icha',
		'Zaraysk',
		'Yegor’yevsk',
		'Vostryakovo',
		'Vostryakovo',
		'Voskresensk',
		'Volokolamsk',
		'Vidnoye',
		'Tuchkovo',
		'Tomilino',
		'Stupino',
		'Staraya Kupavna',
		'Solnechnogorsk',
		'Sofrino',
		'Skhodnya',
		'Shchyolkovo',
		'Shatura',
		'Serpukhov',
		'Sergiyev Posad',
		'Semënovskoye',
		'Roshal’',
		'Reutov',
		'Ramenskoye',
		'Pushkino',
		'Pushchino',
		'Protvino',
		'Podolsk',
		'Petrovskaya',
		'Pavlovskiy Posad',
		'Ozëry',
		'Orekhovo-Zuyevo',
		'Odintsovo',
		'Noginsk',
		'Naro-Fominsk',
		'Nakhabino',
		'Mytishchi',
		'Mozhaysk',
		'Monino',
		'Malakhovka',
		'Lyubertsy',
		'Lytkarino',
		'Lukhovitsy',
		'Losino-Petrovskiy',
		'Lobnya',
		'Likino-Dulevo',
		'Leninskiye Gory',
		'Yubileyny',
		'Kuskovo',
		'Kurovskoye',
		'Kubinka',
		'Biryulëvo Zapadnoye',
		'Krasnogorsk',
		'Krasnoarmeysk',
		'Kozhukhovo',
		'Kotel’niki',
		'Kolomna',
		'Klin',
		'Klimovsk',
		"Khot'kovo",
		'Khoroshëvo-Mnevniki',
		'Khimki',
		'Kastanayevo',
		'Kashira',
		'Korolev',
		'Kabanovo',
		'Izmaylovo',
		'Ivanteyevka',
		'Istra',
		'Golitsyno',
		'Fryazino',
		'Fryazevo',
		'Fili',
		'Elektrougli',
		'Elektrostal’',
		'Elektrogorsk',
		'Dzerzhinskiy',
		'Dubna',
		'Dorogomilovo',
		'Domodedovo',
		'Dolgoprudnyy',
		'Dmitrov',
		'Dedovsk',
		'Chernogolovka',
		'Chekhov',
		'Bronnitsy',
		'Bol’shaya Setun’',
		'Beloozyorskiy',
		'Balashikha',
		'Aprelevka',
		'Andreyevskoye',
		'Alekseyevka',
		'Levoberezhnyy',
		'Annino',
		'Krasnoznamensk',
		'Zarya',
		'Dzerzhinsky',
	],
	Mostaganem: ['Mostaganem'],
	Moxico: ['Luau', 'Luena'],
	'Moyen-Chari': ['Sarh', 'Kyabé'],
	'Moyen-Ogooué': ['Lambaréné'],
	Mpumalanga: [
		'White River',
		'Emalahleni',
		'Volksrust',
		'Standerton',
		'Siyabuswa',
		'Secunda',
		'Piet Retief',
		'Nelspruit',
		'Middelburg',
		'Lydenburg',
		'Kriel',
		'Komatipoort',
		'Hendrina',
		'Ermelo',
		'eMbalenhle',
		'Driefontein',
		'Delmas',
		'Bethal',
		'Barberton',
		'Balfour',
	],
	Mtwara: ['Tandahimba', 'Newala Kisimani', 'Nanyamba', 'Nangomba', 'Nanganga', 'Mtwara', 'Masasi', 'Lukuledi', 'Luchingu', 'Kitama'],
	'Mubārak al Kabīr': ['Şabāḩ as Sālim'],
	Muchinga: ['Mpika'],
	Mudug: ['Gaalkacyo', 'Saaxo'],
	'Muhafazat Hadramaout': ['Mukalla'],
	Muharraq: ['Al Muharraq'],
	Mukdahan: ['Mukdahan'],
	'Municipality of Monaco': ['Monte-Carlo', 'Monaco'],
	Munster: ['Waterford', 'Tralee', 'Limerick', 'Ennis', 'Douglas', 'Cork', 'Cluain Meala', 'Carrigaline', 'Ballincollig'],
	Muramvya: ['Muramvya'],
	"Murang'A": ['Karuri'],
	Murcia: [
		'Yecla',
		'Totana',
		'Torre-Pacheco',
		'Santomera',
		'San Pedro del Pinatar',
		'San Javier',
		'Murcia',
		'Mula',
		'Molina de Segura',
		'Mazarrón',
		'Los Alcázares',
		'Lorca',
		'La Unión',
		'Las Torres de Cotillas',
		'Jumilla',
		'Cieza',
		'Cehegín',
		'Cartagena',
		'Caravaca',
		'Atamaría',
		'Archena',
		'Alhama de Murcia',
		'Alcantarilla',
		'Águilas',
	],
	Mureș: ['Târnăveni', 'Târgu-Mureş', 'Reghin-Sat', 'Luduş', 'Sighișoara'],
	Murmansk: [
		'Zapolyarnyy',
		'Severomorsk',
		'Polyarnyye Zori',
		'Polyarnyy',
		'Olenegorsk',
		'Nikel',
		'Murmansk',
		'Monchegorsk',
		'Kovdor',
		'Kirovsk',
		'Kandalaksha',
		'Apatity',
	],
	Murzuq: ['Murzuq'],
	'Musandam Governorate': ['Khasab'],
	Muscat: ['Muscat', 'Bawshar', 'Seeb'],
	Muyinga: ['Muyinga'],
	Muğla: ['Yatağan', 'Ortaca', 'Muğla', 'Milas', 'Marmaris', 'Fethiye', 'Bodrum', 'Dalaman', 'Turgutreis', 'Sarigerme'],
	Muş: ['Varto', 'Muş', 'Malazgirt', 'Bulanık'],
	Mwanza: ['Usagara', 'Ngudu', 'Mwanza', 'Misungwi', 'Kihangara'],
	Mykolaiv: ['Voznesensk', 'Pervomaysk', 'Mykolayiv', 'Yuzhnoukrayinsk'],
	'Mymensingh Division': ['Jamālpur', 'Netrakona', 'Mymensingh', 'Muktāgācha', 'Gafargaon', 'Sarishābāri', 'Sherpur'],
	Mérida: [
		'Tucaní',
		'Tovar',
		'Timotes',
		'Tabay',
		'Santa Cruz de Mora',
		'Nueva Bolivia',
		'Mérida',
		'Lagunillas',
		'La Azulita',
		'El Vigía',
		'Ejido',
		'Bailadores',
	],
	México: [
		'Zumpango',
		'Xonacatlán',
		'Xico',
		'Tultitlán de Mariano Escobedo',
		'Tultepec',
		'Toluca',
		'Tlalnepantla',
		'Texcoco de Mora',
		'Tequixquiac',
		'Cuautitlán Izcalli',
		'Tepotzotlán',
		'Teoloyucan',
		'Tenango de Arista',
		'Santa María Chimalhuacán',
		'Tecámac',
		'San Salvador Atenco',
		'San Rafael',
		'San Pablo de las Salinas',
		'San Pablo Autopan',
		'Zinacantepec',
		'San Miguel Coatlinchán',
		'San Mateo Atenco',
		'San Juan Zitlaltepec',
		'San Jerónimo',
		'San Francisco Tlalcilalcalpan',
		'San Francisco Acuautla',
		'Ozumba de Alzate',
		'Ocoyoacac',
		'Nicolás Romero',
		'Naucalpan de Juárez',
		'Metepec',
		'Reyes Acozac',
		'Los Reyes Acaquilpan',
		'Lerma de Villada',
		'Magdalena Atlicpac',
		'Juchitepec',
		'Xalatlaco',
		'Ixtapan de la Sal',
		'Ixtapaluca',
		'Ecatepec de Morelos',
		'Cuautitlán',
		'Coyotepec',
		'Coacalco',
		'Ciudad Nezahualcoyotl',
		'Chiconcuac',
		'Chicoloapan',
		'Chalco',
		'Capulhuac de Mirafuentes',
		'Atlacomulco de Fabela',
		'Ciudad López Mateos',
		'Amecameca',
		'San Antonio Acahualco',
		'Santiago Tlacotepec',
		'San Mateo Huitzilzingo',
		'San Martín Cuautlalpan',
		'Tulantongo',
		'Tezoyuca',
		'Tepexpan',
		'Santa María Ajoloapan',
		'San Andrés Cuexcontitlán',
		'San Mateo Otzacatipan',
		'San Pedro Totoltepec',
		'Santa María Totoltepec',
		'San Francisco Cuaxusco',
		'Huixquilucan',
		'Melchor Ocampo',
		'Huilango',
		'Santiago Teyahualco',
		'Ojo de Agua',
		'Buenavista',
		'Valle de Bravo',
		'Tejupilco de Hidalgo',
		'Colonia Lindavista',
		'Fuentes del Valle',
		'San Salvador Tizatlalli',
		'San Buenaventura',
		'Santa Teresa',
		'San Jerónimo Cuatro Vientos',
		'San Martín Azcatepec',
		'San Isidro',
		'San José Guadalupe Otzacatipan',
		'Emiliano Zapata',
		'Teotihuacán de Arista',
		'Jesús del Monte',
		'San Jorge Pueblo Nuevo',
		'Ampliación San Mateo',
		'Tecámac de Felipe Villanueva',
		'Alborada Jaltenco',
	],
	'Møre og Romsdal': ['Molde', 'Kristiansund', 'Ålesund'],
	Māzandarān: ['Tonekābon', 'Sari', 'Nowshahr', 'Nekā', 'Jūybār', 'Fereydūn Kenār', 'Chālūs', 'Behshahr', 'Bābolsar', 'Bābol', 'Āmol'],
	Naama: ['Aïn Sefra'],
	Nabatîyé: ['Habboûch', 'Nabatîyé et Tahta'],
	'Nabeul Governorate': ['Takelsa', 'Korba', 'Kélibia', 'Nabeul', 'Mennzel Bou Zelfa', 'Dar Chabanne', 'Beni Khiar', 'Hammamet', 'Douane'],
	Nacional: ['Villa Francisca', 'Villa Consuelo', 'Santo Domingo', 'Ciudad Nueva', 'Bella Vista'],
	Nagaland: ['Zunheboto', 'Wokha', 'Tuensang', 'Mon', 'Mokokchūng', 'Kohīma', 'Dimāpur'],
	Nagano: [
		'Ueda',
		'Toyoshina',
		'Tatsuno',
		'Suzaka',
		'Suwa',
		'Shiojiri',
		'Saku',
		'Sakaki',
		'Ōmachi',
		'Okaya',
		'Nakano',
		'Nagano',
		'Matsumoto',
		'Kamimaruko',
		'Komoro',
		'Ina',
		'Iiyama',
		'Iida',
		'Hotaka',
		'Chino',
		'Chikuma',
		'Miyota',
	],
	Nagasaki: ['Togitsu', 'Shimabara', 'Sasebo', 'Ōmura', 'Obita', 'Nagasaki', 'Isahaya', 'Hirado', 'Fukuechō', 'Matsuura'],
	'Nairobi Area': ['Thika', 'Pumwani', 'Nairobi'],
	'Najran Region': ['Najrān'],
	Nakhichevan: ['Nakhchivan'],
	'Nakhon Nayok': ['Nakhon Nayok'],
	'Nakhon Pathom': ['Sam Phran', 'Nakhon Pathom', 'Bang Len'],
	'Nakhon Phanom': ['Nakhon Phanom'],
	'Nakhon Ratchasima': [
		'Ban Huai Thalaeng',
		'Pak Chong',
		'Non Sung',
		'Nakhon Ratchasima',
		'Khon Buri',
		'Dan Khun Thot',
		'Chok Chai',
		'Bua Yai',
		'Amphoe Sikhiu',
	],
	'Nakhon Sawan': ['Lat Yao', 'Nakhon Sawan'],
	'Nakhon Si Thammarat': ['Thung Song', 'Ron Phibun', 'Nakhon Si Thammarat', 'Pak Phanang'],
	Nakuru: ['Rongai', 'Nakuru', 'Naivasha', 'Molo'],
	'Nam Định': ['Nam Định'],
	Namangan: [
		'Yangiqo‘rg‘on',
		'Uychi',
		'Uchqŭrghon Shahri',
		'To‘rqao‘rg‘on',
		'Toshbuloq',
		'Pop',
		'Namangan',
		'Haqqulobod',
		'Kosonsoy',
		'Chust',
		'Chortoq',
	],
	Namibe: ['Moçâmedes'],
	Nampula: ['Nampula', 'Nacala', 'Mozambique', 'António Enes', 'Mutuáli'],
	Nan: ['Nan'],
	'Nana-Grébizi': ['Kaga Bandoro'],
	'Nana-Mambéré': ['Bouar'],
	Nangarhar: ['Jalālābād', 'Markaz-e Woluswalī-ye Āchīn'],
	Napo: ['Tena'],
	Nara: ['Tenri', 'Tawaramoto', 'Sakurai', 'Nara-shi', 'Kashihara-shi', 'Ikoma', 'Hōryūji', 'Haibara-akanedai', 'Gose', 'Gojō', 'Katsuragi', 'Kanmaki'],
	Narathiwat: ['Tak Bai', 'Su-ngai Kolok', 'Ra-ngae', 'Narathiwat'],
	Nariño: ['Túquerres', 'Tumaco', 'San Lorenzo', 'Samaniego', 'Pupiales', 'Pasto', 'La Unión', 'Ipiales', 'El Charco', 'Buesaco'],
	Narok: ['Narok'],
	Naryn: ['Naryn'],
	Nassarawa: ['Wamba', 'Nasarawa', 'Lafia', 'Keffi', 'Doma', 'Akwanga'],
	'National Capital': ['Port Moresby'],
	Navarre: ['Tudela', 'Pamplona', 'Burlata', 'Barañáin', 'Iturrama', 'Ermitagaña', 'Primer Ensanche', 'Segundo Ensanche'],
	Navoiy: ['Yangirabot', 'Nurota', 'Navoiy'],
	'Nay Pyi Taw': ['Pyinmana', 'Nay Pyi Taw'],
	Nayarit: ['Tuxpan', 'Tepic', 'Santiago Ixcuintla', 'Mezcales', 'Xalisco', 'Ixtlán del Río', 'Compostela', 'Acaponeta'],
	Neamț: ['Târgu Neamţ', 'Roman', 'Piatra Neamţ'],
	Nebraska: [
		'Bellevue',
		'Columbus',
		'Fremont',
		'Grand Island',
		'Hastings',
		'Kearney',
		'La Vista',
		'Lincoln',
		'Norfolk',
		'Omaha',
		'Papillion',
		'North Platte',
	],
	Neftçala: ['Neftçala'],
	'Negeri Sembilan': ['Tampin', 'Seremban', 'Port Dickson', 'Bahau', 'Kuala Pilah', 'Kampung Baharu Nilai'],
	Negotino: ['Negotino'],
	Nelson: ['Stoke', 'Nelson'],
	Nenets: ["Nar'yan-Mar"],
	Neuchâtel: ['Neuchâtel', 'La Chaux-de-Fonds'],
	Neuquen: ['Zapala', 'San Martín de los Andes', 'Plottier', 'Neuquén', 'Cutral-Có', 'Centenario'],
	Nevada: [
		'Boulder City',
		'Carson City',
		'Enterprise',
		'Fernley',
		'Henderson',
		'Las Vegas',
		'Mesquite',
		'North Las Vegas',
		'Pahrump',
		'Paradise',
		'Reno',
		'Spanish Springs',
		'Sparks',
		'Spring Valley',
		'Sun Valley',
		'Sunrise Manor',
		'Whitney',
		'Winchester',
		'Elko',
		'Summerlin South',
	],
	'Nevşehir Province': ['Ürgüp', 'Nevşehir'],
	'New Brunswick': ['Dieppe', 'Fredericton', 'Lutes Mountain', 'Miramichi', 'Moncton', 'Saint John', 'Edmundston'],
	'New Hampshire': [
		'Bedford',
		'Concord',
		'Derry',
		'Derry Village',
		'Dover',
		'East Concord',
		'Keene',
		'Laconia',
		'Manchester',
		'Merrimack',
		'Nashua',
		'Portsmouth',
		'Rochester',
		'Salem',
	],
	'New Jersey': [
		'Atlantic City',
		'Bayville',
		'Bridgeton',
		'Camden',
		'Cherry Hill',
		'Glassboro',
		'Jackson',
		'Lindenwold',
		'Maple Shade',
		'Millville',
		'Mount Laurel',
		'Ocean Acres',
		'Pennsauken',
		'Pleasantville',
		'Sewell',
		'Sicklerville',
		'South Vineland',
		'Toms River',
		'Vincentown',
		'Vineland',
		'Williamstown',
		'Asbury Park',
		'Avenel',
		'Basking Ridge',
		'Bayonne',
		'Belleville',
		'Bergenfield',
		'Bloomfield',
		'Carteret',
		'Cliffside Park',
		'Clifton',
		'Colonia',
		'Cranford',
		'Denville',
		'Dover',
		'Dumont',
		'East Brunswick',
		'East Orange',
		'Edison',
		'Elizabeth',
		'Elmwood Park',
		'Englewood',
		'Ewing',
		'Fair Lawn',
		'Fords',
		'Fort Lee',
		'Garfield',
		'Hackensack',
		'Harrison',
		'Hawthorne',
		'Hillsborough',
		'Hillside',
		'Hoboken',
		'Hopatcong Hills',
		'Irvington',
		'Iselin',
		'Jersey City',
		'Kearny',
		'Lakewood',
		'Linden',
		'Livingston',
		'Lodi',
		'Long Branch',
		'Lyndhurst',
		'Madison',
		'Mahwah',
		'Maplewood',
		'Marlboro',
		'Middletown',
		'Montclair',
		'Morristown',
		'New Brunswick',
		'New Milford',
		'Newark',
		'North Arlington',
		'North Bergen',
		'North Plainfield',
		'Nutley',
		'Old Bridge',
		'Orange',
		'Palisades Park',
		'Paramus',
		'Parsippany',
		'Passaic',
		'Paterson',
		'Perth Amboy',
		'Piscataway',
		'Plainfield',
		'Point Pleasant',
		'Princeton',
		'Rahway',
		'Ramsey',
		'Ridgewood',
		'Roselle',
		'Rutherford',
		'Sayreville',
		'Sayreville Junction',
		'Scotch Plains',
		'Secaucus',
		'Somerset',
		'South Old Bridge',
		'South Orange',
		'South Plainfield',
		'South River',
		'Sparta',
		'Summit',
		'Teaneck',
		'Tinton Falls',
		'Trenton',
		'Union',
		'Union City',
		'Voorhees',
		'Wayne',
		'West Milford',
		'West New York',
		'West Orange',
		'Westfield',
		'Willingboro',
		'Woodbridge',
		'Wyckoff',
		'Millburn',
		'Mercerville-Hamilton Square',
		'Bridgewater',
		'Warren Township',
		'Randolph',
	],
	'New Mexico': [
		'Alamogordo',
		'Albuquerque',
		'Carlsbad',
		'Clovis',
		'Farmington',
		'Gallup',
		'Hobbs',
		'Las Cruces',
		'Los Lunas',
		'Rio Rancho',
		'Roswell',
		'Santa Fe',
		'South Valley',
		'Sunland Park',
		'Enchanted Hills',
	],
	'New Providence': ['Nassau'],
	'New South Wales': [
		'Bondi Beach',
		'Bondi',
		'Bondi Junction',
		'Queens Park',
		'Double Bay',
		'Westmead',
		'Wahroonga',
		'Taree',
		'Tamworth',
		'Sydney',
		'Surry Hills',
		'Strathfield',
		'Seven Hills',
		'Saint Ives',
		'Ryde',
		'Rockdale',
		'Quakers Hill',
		'Port Macquarie',
		'Orange',
		'Newcastle',
		'Mount Druitt',
		'Mosman',
		'Miranda',
		'Merrylands',
		'Mascot',
		'Marrickville',
		'Maroubra',
		'Manly',
		'Maitland',
		'Liverpool',
		'Lidcombe',
		'Lakemba',
		'Kogarah',
		'Kensington',
		'Hornsby',
		'Griffith',
		'Granville',
		'Goulburn',
		'Fairfield',
		'Epping',
		'Engadine',
		'Eastwood',
		'Earlwood',
		'Dubbo',
		'Cronulla',
		'Coogee',
		'Coffs Harbour',
		'Wollongong',
		'Chatswood',
		'Cessnock',
		'Castle Hill',
		'Carlingford',
		'Burwood',
		'Broken Hill',
		'Blacktown',
		'Bexley',
		'Baulkham Hills',
		'Bathurst',
		'Banora Point',
		'Bankstown',
		'Auburn',
		'Ashfield',
		'Armidale',
		'Randwick',
		'Dee Why',
		'Umina Beach',
		'Sydney Central Business District',
		'Kingsford',
		'Parramatta',
		'Glenmore Park',
		'Kellyville',
		'Cherrybrook',
		'Prestons',
		'Glenwood',
		'Cabramatta',
		'Campsie',
		'Casula',
		'Cranebrook',
		'Greenacre',
		'Greystanes',
		'Yagoona',
		'Punchbowl',
		'St Clair',
		'Hurstville',
		'West Pennant Hills',
		'Wollongong city centre',
	],
	'New Valley': ['Al Khārjah'],
	'New York': [
		'Albany',
		'Amherst',
		'Amsterdam',
		'Astoria',
		'Auburn',
		'Baldwin',
		'Batavia',
		'Bath Beach',
		'Bay Shore',
		'Baychester',
		'Bayside',
		'Bellmore',
		'Bensonhurst',
		'Bethpage',
		'Binghamton',
		'Borough Park',
		'Brentwood',
		'Brighton',
		'Brighton Beach',
		'The Bronx',
		'Brooklyn',
		'Brooklyn Heights',
		'Brownsville',
		'Buffalo',
		'Bushwick',
		'Cambria Heights',
		'Canarsie',
		'Centereach',
		'Central Islip',
		'Cheektowaga',
		'Chinatown',
		'Cicero',
		'Clay',
		'Clifton Park',
		'Cohoes',
		'College Point',
		'Commack',
		'Coney Island',
		'Copiague',
		'Coram',
		'Corona',
		'Cortland',
		'Cypress Hills',
		'Deer Park',
		'Depew',
		'Dix Hills',
		'Dyker Heights',
		'East Amherst',
		'East Elmhurst',
		'East Flatbush',
		'East Meadow',
		'East Massapequa',
		'East New York',
		'East Northport',
		'East Patchogue',
		'East Setauket',
		'East Tremont',
		'East Village',
		'Eastchester',
		'Eggertsville',
		'Elmhurst',
		'Elmira',
		'Elmont',
		'Emerson Hill',
		'Far Rockaway',
		'Farmingville',
		'Flatbush',
		'Flatlands',
		'Floral Park',
		'Fordham',
		'Forest Hills',
		'Fort Hamilton',
		'Franklin Square',
		'Freeport',
		'Fresh Meadows',
		'Garden City',
		'Glen Cove',
		'Glendale',
		'Gloversville',
		'Grand Island',
		'Graniteville',
		'Gravesend',
		'Great Kills',
		'Greenburgh',
		'Greenpoint',
		'Harlem',
		'Harrison',
		'Hauppauge',
		"Hell's Kitchen",
		'Hempstead',
		'Henrietta',
		'Hicksville',
		'Hillside',
		'Holbrook',
		'Hollis',
		'Holtsville',
		'Howard Beach',
		'Huntington',
		'Huntington Station',
		'Hunts Point',
		'Irondequoit',
		'Islip',
		'Ithaca',
		'Jackson Heights',
		'Jamaica',
		'Jamestown',
		'Kenmore',
		'Kensington',
		'Kew Gardens',
		'Kings Bridge',
		'Kings Park',
		'Kingston',
		'Kiryas Joel',
		'Lackawanna',
		'Lake Ronkonkoma',
		'Latham',
		'Laurelton',
		'Levittown',
		'Lindenhurst',
		'Lockport',
		'Long Beach',
		'Long Island City',
		'Lynbrook',
		'Mamaroneck',
		'Manhattan',
		'Mariners Harbor',
		'Maspeth',
		'Massapequa',
		'Massapequa Park',
		'Mastic',
		'Medford',
		'Melrose',
		'Melville',
		'Merrick',
		'Middle Village',
		'Middletown',
		'Mineola',
		'Monsey',
		'Morris Heights',
		'Morrisania',
		'Mott Haven',
		'Mount Vernon',
		'Nanuet',
		'New City',
		'New Rochelle',
		'New Springville',
		'New York City',
		'Newburgh',
		'Niagara Falls',
		'North Amityville',
		'North Babylon',
		'North Bay Shore',
		'North Bellmore',
		'North Massapequa',
		'North Tonawanda',
		'North Valley Stream',
		'Oceanside',
		'Ossining',
		'Oswego',
		'Ozone Park',
		'Park Slope',
		'Parkchester',
		'Pearl River',
		'Peekskill',
		'Plainview',
		'Plattsburgh',
		'Port Chester',
		'Port Richmond',
		'Port Washington',
		'Poughkeepsie',
		'Queens Village',
		'Queens',
		'Queensbury',
		'Rego Park',
		'Richmond Hill',
		'Ridgewood',
		'Rochester',
		'Rockville Centre',
		'Rome',
		'Ronkonkoma',
		'Roosevelt',
		'Rosedale',
		'Rossville',
		'Rotterdam',
		'Rye',
		'Saratoga Springs',
		'Sayville',
		'Scarsdale',
		'Schenectady',
		'Seaford',
		'Selden',
		'Sheepshead Bay',
		'Shirley',
		'Smithtown',
		'South Ozone Park',
		'Springfield Gardens',
		'Spring Valley',
		'Staten Island',
		'Sunnyside',
		'Syosset',
		'Syracuse',
		'Terrace Heights',
		'Throgs Neck',
		'Tremont',
		'Troy',
		'Uniondale',
		'Unionport',
		'University Heights',
		'Utica',
		'Valley Stream',
		'Van Nest',
		'Vestal',
		'Wakefield',
		'Wantagh',
		'Washington Heights',
		'Watertown',
		'West Albany',
		'West Babylon',
		'West Hempstead',
		'West Islip',
		'West Seneca',
		'Westbury',
		'White Plains',
		'Whitestone',
		'Williamsburg',
		'Woodhaven',
		'Woodmere',
		'Woodrow',
		'Woodside',
		'Yonkers',
		'Morningside Heights',
		'East Harlem',
		'Briarwood',
		'Gramercy Park',
		'Kew Gardens Hills',
		'Gates-North Gates',
		'Setauket-East Setauket',
		'Financial District',
		'Sunset Park',
		'Cortlandt Manor',
	],
	'Newfoundland and Labrador': ['Conception Bay South', 'Corner Brook', 'Mount Pearl', "St. John's"],
	'Nghệ An': ['Yên Vinh', 'Vinh'],
	Ngouni: ['Mouila'],
	Ngozi: ['Ngozi'],
	Ngwaketsi: ['Mosopa', 'Kanye'],
	Niamey: ['Niamey'],
	Niari: ['Mossendjo', 'Dolisie'],
	Niassa: ['Mandimba', 'Lichinga', 'Cuamba'],
	Nicosia: ['Stróvolos', 'Nicosia', 'Mórfou'],
	Niger: ['Zungeru', 'Tegina', 'Suleja', 'Rijau', 'Mokwa', 'Minna', 'Lapai', 'Kontagora', 'Ibeto', 'Bida', 'Baro', 'Babana'],
	Niigata: [
		'Yoshida-kasugachō',
		'Tsubame',
		'Tōkamachi',
		'Tochio-honchō',
		'Suibara',
		'Shirone',
		'Shiozawa',
		'Shibata',
		'Sanjō',
		'Ryōtsu-minato',
		'Ojiya',
		'Niitsu-honchō',
		'Niigata',
		'Nagaoka',
		'Muramatsu',
		'Murakami',
		'Muikamachi',
		'Mitsuke',
		'Maki',
		'Kashiwazaki',
		'Kamo',
		'Kameda-honchō',
		'Itoigawa',
		'Gosen',
		'Arai',
		'Jōetsu',
		'Minamiuonuma',
		'Sado',
		'Tainai',
		'Uonuma',
		'Agano',
	],
	Nimba: ['New Yekepa'],
	Nimroz: ['Zaranj', 'Khāsh'],
	Nineveh: ['Tallkayf', 'Al-Hamdaniya', '‘Aqrah', 'Al Mawşil al Jadīdah', 'Mosul', 'Sinjār'],
	'Ningxia Hui Autonomous Region': [
		'Yinchuan',
		'Yanghe',
		'Xigang',
		'Wutongshu',
		'Shizuishan',
		'Shitanjing',
		'Dongta',
		'Jingui',
		'Haojiaqiao',
		'Guyuan',
		'Dawukou',
		'Chongxing',
		'Changxin',
		'Wanghong',
		'Hongguang Qidui',
		'Linhe',
		'Ligang',
		'Lingwu',
		'Ningdong',
		'Wangyuan',
		'Minning',
		'Lijun',
		'Shizuishan',
	],
	'Ninh Bình': ['Ninh Bình'],
	'Ninh Thuận': ['Phan Rang-Tháp Chàm'],
	Nippes: ['Miragoâne'],
	'Nitriansky kraj': ['Zlaté Moravce', 'Šaľa', 'Nové Zámky', 'Nitra', 'Levice', 'Komárno'],
	'Nizhny Novgorod Oblast': [
		'Zavolzh’ye',
		'Vyksa',
		'Shakhun’ya',
		'Sergach',
		'Semënov',
		'Sarov',
		'Nizhniy Novgorod',
		'Lyskovo',
		'Kulebaki',
		'Kstovo',
		'Gorodets',
		'Dzerzhinsk',
		'Bor',
		'Bogorodsk',
		'Novaya Balakhna',
		'Arzamas',
		'Pavlovo',
	],
	'Niğde Province': ['Niğde', 'Bor'],
	Njombe: ['Njombe', 'Mtwango', 'Mlangali', 'Makumbako', 'Ilembula'],
	'Nong Bua Lam Phu': ['Na Klang', 'Nong Bua Lamphu'],
	'Nong Khai': ['Tha Bo', 'Phon Charoen', 'Nong Khai'],
	Nonthaburi: ['Pak Kret', 'Mueang Nonthaburi', 'Bang Kruai', 'Bang Bua Thong'],
	Nord: ['Yako', 'Titao', 'Ouahigouya', 'Gourcy', 'Saint-Raphaël', 'Lenbe', 'Cap-Haïtien'],
	'Nord Kivu': ['Sake', 'Goma', 'Butembo', 'Beni'],
	'Nord-Ouest': ['Port-de-Paix', 'Ti Port-de-Paix'],
	'Nord-Ubangi': ['Gbadolite', 'Businga'],
	Nordland: ['Mo i Rana', 'Bodø'],
	Normandy: [
		'Vernon',
		'Tourlaville',
		'Sotteville-lès-Rouen',
		'Saint-Lô',
		'Saint-Étienne-du-Rouvray',
		'Rouen',
		'Octeville',
		'Mont-Saint-Aignan',
		'Montivilliers',
		'Louviers',
		'Lisieux',
		'Le Petit-Quevilly',
		'Le Havre',
		'Le Grand-Quevilly',
		'Hérouville-Saint-Clair',
		'Flers',
		'Fécamp',
		'Évreux',
		'Équeurdreville-Hainneville',
		'Elbeuf',
		'Dieppe',
		'Cherbourg-Octeville',
		'Canteleu',
		'Caen',
		'Bayeux',
		'Argentan',
		'Alençon',
	],
	Norrbotten: ['Piteå', 'Luleå', 'Kiruna', 'Boden'],
	'Norte de Santander Department': ['Villa del Rosario', 'Puerto Santander', 'Pamplona', 'Ocaña', 'Los Patios', 'El Zulia', 'Cúcuta'],
	North: ['Tcholliré', 'Lagdo', 'Guider', 'Garoua', 'Fanling'],
	'North Aegean': ['Mytilene', 'Chios'],
	'North Bank': ['Lamin', 'Farafenni'],
	'North Brabant': [
		'Waalwijk',
		'Vught',
		'Veghel',
		'Valkenswaard',
		'Uden',
		'Tongelre',
		'Tilburg',
		'Steenbergen',
		'Sint-Oedenrode',
		's-Hertogenbosch',
		'Schijndel',
		'Roosendaal',
		'Rijen',
		'Oss',
		'Oosterhout',
		'Oisterwijk',
		'Nuenen',
		'Loon op Zand',
		'Hoge Vucht',
		'Helmond',
		'Goirle',
		'Gemert',
		'Geldrop',
		'Geertruidenberg',
		'Eindhoven',
		'Eersel',
		'Dongen',
		'Cranendonck',
		'Breda',
		'Boxtel',
		'Best',
		'Bergeijk',
		'Bergen op Zoom',
	],
	'North Caribbean Coast': ['Siuna', 'Puerto Cabezas'],
	'North Carolina': [
		'Albemarle',
		'Apex',
		'Asheboro',
		'Asheville',
		'Boone',
		'Burlington',
		'Carrboro',
		'Cary',
		'Chapel Hill',
		'Charlotte',
		'Clayton',
		'Clemmons',
		'Concord',
		'Cornelius',
		'Durham',
		'Eden',
		'Elizabeth City',
		'Fayetteville',
		'Fuquay-Varina',
		'Garner',
		'Gastonia',
		'Goldsboro',
		'Greensboro',
		'Greenville',
		'Havelock',
		'Henderson',
		'Hickory',
		'High Point',
		'Holly Springs',
		'Hope Mills',
		'Huntersville',
		'Indian Trail',
		'Jacksonville',
		'Kannapolis',
		'Kernersville',
		'Kinston',
		'Laurinburg',
		'Leland',
		'Lenoir',
		'Lexington',
		'Lumberton',
		'Matthews',
		'Mint Hill',
		'Monroe',
		'Mooresville',
		'Morganton',
		'Morrisville',
		'New Bern',
		'Pinehurst',
		'Raleigh',
		'Roanoke Rapids',
		'Rocky Mount',
		'Salisbury',
		'Sanford',
		'Shelby',
		'Stallings',
		'Statesville',
		'Thomasville',
		'Wake Forest',
		'West Raleigh',
		'Wilmington',
		'Wilson',
		'Winston-Salem',
		'Fort Liberty',
	],
	'North Central': ['Anuradhapura'],
	'North Chungcheong': ['Yeongdong', 'Okcheon', 'Koesan', 'Chungju', 'Cheongju-si', "Chinch'ŏn"],
	'North Dakota': ['Fargo', 'Grand Forks', 'Jamestown', 'West Fargo', 'Bismarck', 'Dickinson', 'Mandan', 'Minot', 'Williston'],
	'North Denmark': ['Nørresundby', 'Hjørring', 'Frederikshavn', 'Aalborg'],
	'North Holland': [
		'Zandvoort',
		'Zaanstad',
		'Zaandam',
		'Weesp',
		'Volendam',
		'Velsen-Zuid',
		'Uithoorn',
		'Purmerend',
		'Naarden',
		'Medemblik',
		'IJmuiden',
		'Huizen',
		'Hoorn',
		'Hoofddorp',
		'Hilversum',
		'Heiloo',
		'Heerhugowaard',
		'Heemstede',
		'Heemskerk',
		'Harenkarspel',
		'Haarlem',
		'Enkhuizen',
		'Diemen',
		'Den Helder',
		'Castricum',
		'Bussum',
		'Beverwijk',
		'Bergen',
		'Assendelft',
		'Amsterdam',
		'Amstelveen',
		'Alkmaar',
		'Aalsmeer',
		'Amsterdam-Zuidoost',
	],
	'North Kalimantan': ['Tarakan', 'Tanjung Selor', 'Malinau'],
	'North Karelia': ['Joensuu'],
	'North Kazakhstan': ['Petropavl'],
	'North Khorasan': ['Shīrvān', 'Esfarāyen', 'Bojnūrd'],
	'North Kordofan': ['Umm Ruwaba', 'Bārah', 'Ar Rahad', 'El Obeid'],
	'North Maluku': ['Tobelo', 'Ternate', 'Sofifi', 'Sanana'],
	'North Ossetia–Alania': ['Vladikavkaz', 'Mozdok', 'Beslan', 'Ardon', 'Alagir'],
	'North Ostrobothnia': ['Raahe', 'Oulu', 'Kuusamo', 'Haukipudas'],
	'North Rhine-Westphalia': [
		'Zülpich',
		'Xanten',
		'Würselen',
		'Wuppertal',
		'Wülfrath',
		'Witten',
		'Wipperfürth',
		'Wilnsdorf',
		'Willich',
		'Wiehl',
		'Wetter',
		'Wesseling',
		'Wesel',
		'Wersten',
		'Werne',
		'Wermelskirchen',
		'Werl',
		'Werdohl',
		'Wenden',
		'Weilerswist',
		'Wegberg',
		'Wassenberg',
		'Warstein',
		'Warendorf',
		'Warburg',
		'Waltrop',
		'Waldbröl',
		'Wachtberg',
		'Vreden',
		'Voerde',
		'Vlotho',
		'Viersen',
		'Versmold',
		'Verl',
		'Velbert',
		'Unna',
		'Uerdingen',
		'Übach-Palenberg',
		'Troisdorf',
		'Tönisvorst',
		'Telgte',
		'Sundern',
		'Straelen',
		'Stolberg',
		'Steinhagen',
		'Steinfurt',
		'Stadtlohn',
		'Sprockhövel',
		'Spenge',
		'Solingen',
		'Soest',
		'Simmerath',
		'Siegen',
		'Siegburg',
		'Senden',
		'Selm',
		'Schwerte',
		'Schwelm',
		'Schwalmtal',
		'Schmallenberg',
		'Sankt Augustin',
		'Salzkotten',
		'Rösrath',
		'Rietberg',
		'Rheinhausen',
		'Rheine',
		'Rheinberg',
		'Rheinbach',
		'Rhede',
		'Rheda-Wiedenbrück',
		'Remscheid',
		'Rees',
		'Recklinghausen',
		'Ratingen',
		'Rahden',
		'Radevormwald',
		'Pulheim',
		'Porz am Rhein',
		'Porta Westfalica',
		'Plettenberg',
		'Petershagen',
		'Paderborn',
		'Overath',
		'Opladen',
		'Olsberg',
		'Olpe',
		'Oerlinghausen',
		'Oer-Erkenschwick',
		'Oelde',
		'Odenthal',
		'Ochtrup',
		'Oberhausen',
		'Nümbrecht',
		'Nottuln',
		'Nippes',
		'Niederkrüchten',
		'Niederkassel',
		'Neuss',
		'Neubrück',
		'Nettetal',
		'Netphen',
		'Münster',
		'Mülheim',
		'Much',
		'Monheim am Rhein',
		'Mönchengladbach',
		'Moers',
		'Minden',
		'Mettmann',
		'Meschede',
		'Menden',
		'Meinerzhagen',
		'Meiderich',
		'Meerbusch',
		'Meckenheim',
		'Mechernich',
		'Marsberg',
		'Marl',
		'Lünen',
		'Lüdinghausen',
		'Lüdenscheid',
		'Lübbecke',
		'Löhne',
		'Lohmar',
		'Lippstadt',
		'Lindlar',
		'Leverkusen',
		'Leopoldshöhe',
		'Lennestadt',
		'Lengerich',
		'Lemgo',
		'Leichlingen',
		'Langenfeld',
		'Lage',
		'Bad Laasphe',
		'Kürten',
		'Kreuztal',
		'Kreuzau',
		'Krefeld',
		'Korschenbroich',
		'Königswinter',
		'Köln',
		'Kleve',
		'Kirchlengern',
		'Kierspe',
		'Kevelaer',
		'Kerpen',
		'Kempen',
		'Kamp-Lintfort',
		'Kamen',
		'Kaarst',
		'Jülich',
		'Jüchen',
		'Iserlohn',
		'Ibbenbüren',
		'Hürth',
		'Humboldtkolonie',
		'Hückeswagen',
		'Hückelhoven',
		'Höxter',
		'Hövelhof',
		'Hörstel',
		'Holzwickede',
		'Hochfeld',
		'Hille',
		'Hilden',
		'Hilchenbach',
		'Hiddenhausen',
		'Herzogenrath',
		'Herten',
		'Herne',
		'Herford',
		'Herdecke',
		'Hennef (Sieg)',
		'Hemer',
		'Heinsberg',
		'Heiligenhaus',
		'Hattingen',
		'Harsewinkel',
		'Hamminkeln',
		'Hamm',
		'Halver',
		'Haltern am See',
		'Halle',
		'Hagen',
		'Haan',
		'Gütersloh',
		'Gummersbach',
		'Gronau',
		'Grevenbroich',
		'Greven',
		'Grefrath',
		'Goch',
		'Gladbeck',
		'Gevelsberg',
		'Geseke',
		'Gescher',
		'Gelsenkirchen',
		'Geldern',
		'Geilenkirchen',
		'Fröndenberg',
		'Freudenberg',
		'Frechen',
		'Fischeln',
		'Eving',
		'Euskirchen',
		'Essen',
		'Espelkamp',
		'Eschweiler',
		'Erwitte',
		'Erkrath',
		'Erkelenz',
		'Erftstadt',
		'Ennigerloh',
		'Ennepetal',
		'Enger',
		'Engelskirchen',
		'Emsdetten',
		'Emmerich',
		'Elsdorf',
		'Eitorf',
		'Düsseldorf',
		'Düren',
		'Dülmen',
		'Duisburg',
		'Drensteinfurt',
		'Dortmund',
		'Dorsten',
		'Dormagen',
		'Dinslaken',
		'Deutz',
		'Detmold',
		'Delbrück',
		'Datteln',
		'Coesfeld',
		'Castrop-Rauxel',
		'Burscheid',
		'Büren',
		'Bünde',
		'Brühl',
		'Brüggen',
		'Brilon',
		'Brakel',
		'Bottrop',
		'Bornheim',
		'Borken',
		'Bonn',
		'Bönen',
		'Bockum',
		'Bochum',
		'Bocholt',
		'Blomberg',
		'Bielefeld',
		'Beverungen',
		'Bergneustadt',
		'Bergkamen',
		'Bergisch Gladbach',
		'Bergheim',
		'Bedburg',
		'Beckum',
		'Baesweiler',
		'Bad Salzuflen',
		'Bad Oeynhausen',
		'Bad Münstereifel',
		'Bad Lippspringe',
		'Bad Honnef',
		'Bad Driburg',
		'Bad Berleburg',
		'Attendorn',
		'Ascheberg',
		'Arnsberg',
		'Aplerbeck',
		'Altena',
		'Alsdorf',
		'Alfter',
		'Ahlen',
		'Ahaus',
		'Aachen',
		'Altstadt Sud',
		'Altstadt Nord',
		'Neuehrenfeld',
		'Bilderstöckchen',
		'Bochum-Hordel',
		'Neustadt/Nord',
		'Neustadt/Süd',
		'Kalk',
		'Rodenkirchen',
		'Mülheim',
		'Düsseldorf-Pempelfort',
	],
	'North Savo': ['Varkaus', 'Siilinjärvi', 'Kuopio', 'Iisalmi'],
	'North Sinai': ['Arish'],
	'North Sulawesi': ['Tondano', 'Tomohon', 'Manado', 'Bitung'],
	'North Sumatra': [
		'Tongging',
		'Teluk Nibung',
		'Teluk Dalam',
		'Tebingtinggi',
		'Tanjungtiram',
		'Tanjungbalai',
		'Sunggal',
		'Stabat',
		'Sibolga',
		'Rantauprapat',
		'Percut',
		'Perbaungan',
		'Pematangsiantar',
		'Pangkalan Brandan',
		'Padangsidempuan',
		'Medan',
		'Labuhan Deli',
		'Kisaran',
		'Kabanjahe',
		'Gunungsitoli',
		'Deli Tua',
		'Binjai',
		'Berastagi',
		'Belawan',
		'Bandar',
		'Pekan Bahapal',
	],
	'North West': ['Port Loko'],
	'North Western': ['Puttalam', 'Kurunegala', 'Chilaw'],
	'North-West': [
		'Maun',
		'Wum',
		'Kumbo',
		'Fundong',
		'Bamenda',
		'Bali',
		'Zeerust',
		'Wolmaransstad',
		'Vryburg',
		'Stilfontein',
		'Schweizer-Reneke',
		'Rustenburg',
		'Potchefstroom',
		'Mmabatho',
		'Lichtenburg',
		'Klerksdorp',
		'Ga-Rankuwa',
		'Fochville',
		'Christiana',
		'Brits',
		'Bloemhof',
	],
	'North-Western': ['Kansanshi'],
	Northeast: ['Akureyri'],
	'Northeastern Governorate': ['Ibrā’', 'Badīyah'],
	Northern: ['Madīnat Ḩamad', 'Labasa', 'Yendi', 'Tamale', 'Savelugu', 'Mbala', 'Kasama'],
	'Northern Bahr al Ghazal': ['Winejok', 'Aweil'],
	'Northern Borders Region': ['Turaif', 'Arar'],
	'Northern Cape': ['Warrenton', 'Upington', 'Postmasburg', 'Pampierstad', 'Kimberley', 'De Aar'],
	'Northern Darfur': ['El Fasher'],
	'Northern District': [
		'Safed',
		'Yoqne‘am ‘Illit',
		'Yirkā',
		'Yāfā',
		'Tiberias',
		'maalot Tarshīhā',
		'Tamra',
		'Shefar‘am',
		'Sakhnīn',
		'Qiryat Shmona',
		'Naẕerat ‘Illit',
		'Nazareth',
		'Nahariyya',
		'Migdal Ha‘Emeq',
		'Majd el Kurūm',
		'Maghār',
		'Karmi’el',
		'Kafr Mandā',
		'Kafr Kannā',
		'Judeida Makr',
		'Er Reina',
		'Bet She’an',
		'Acre',
		'Afula',
	],
	'Northern Ireland': [
		'Portadown',
		'Omagh',
		'Newtownards',
		'Newtownabbey',
		'Newry',
		'Londonderry County Borough',
		'Derry',
		'Lisburn',
		'Larne',
		'Coleraine',
		'Castlereagh',
		'Carrickfergus',
		'Belfast',
		'Bangor',
		'Banbridge',
		'Ballymena',
		'Antrim',
		'Craigavon',
	],
	'Northern Mindanao': [
		'NIA Valencia',
		'Tangub',
		'Tagoloan',
		'Quezon',
		'Ozamiz City',
		'Oroquieta',
		'Mariano',
		'Maramag',
		'Mantampay',
		'Manolo Fortich',
		'Malaybalay',
		'Jasaan',
		'Iligan',
		'Don Carlos',
		'Dologon',
		'Cagayan de Oro',
		'Boroon',
		'Bugo',
	],
	'Northern Province': ['Vavuniya', 'Valvedditturai', 'Point Pedro', 'Jaffna', 'Popondetta', 'Musanze', 'Byumba', 'Makeni', 'Lunsar', 'Kabala'],
	'Northern Red Sea': ['Massawa'],
	'Northern Region': [
		'Karonga',
		'Rumphi',
		'Mzuzu',
		'Mzimba',
		'Yumbe',
		'Paidha',
		'Nebbi',
		'Moyo',
		'Lira',
		'Kotido',
		'Kitgum',
		'Gulu',
		'Arua',
		'Apac',
		'Adjumani',
	],
	'Northern State': ['Kuraymah'],
	'Northern Territory': ['Darwin', 'Alice Springs', 'Palmerston'],
	Northland: ['Whangarei'],
	'Northwest Territories': ['Yellowknife'],
	'Nouakchott Nord': ['Dar Naim'],
	'Nouakchott Ouest': ['Tevragh Zeina'],
	'Nouvelle-Aquitaine': [
		'Villeneuve-sur-Lot',
		"Villenave-d'Ornon",
		'Tulle',
		'Talence',
		'Saint-Médard-en-Jalles',
		'Saintes',
		'Royan',
		'Rochefort',
		'Poitiers',
		'Pessac',
		'Périgueux',
		'Pau',
		'Niort',
		'Mont-de-Marsan',
		'Mérignac',
		'Marmande',
		'Lormont',
		'Limoges',
		'Libourne',
		'Le Bouscat',
		'La Teste-de-Buch',
		'La Rochelle',
		'Gujan-Mestras',
		'Guéret',
		'Gradignan',
		'Floirac',
		'Eysines',
		'Dax',
		'Cognac',
		'Châtellerault',
		'Cestas',
		'Cenon',
		'Brive-la-Gaillarde',
		'Bressuire',
		'Bordeaux',
		'Blanquefort',
		'Biarritz',
		'Bergerac',
		'Bègles',
		'Bayonne',
		'Angoulême',
		'Anglet',
		'Agen',
	],
	'Nova Scotia': ['Dartmouth', 'Glace Bay', 'New Glasgow', 'Halifax', 'Sydney', 'Cole Harbour', 'Lower Sackville'],
	'Novgorod Oblast': ['Valday', 'Staraya Russa', 'Pestovo', 'Velikiy Novgorod', 'Chudovo', 'Borovichi'],
	'Novo Mesto': ['Novo Mesto'],
	'Novosibirsk Oblast': [
		'Toguchin',
		'Tatarsk',
		'Suzun',
		'Ob’',
		'Novosibirsk',
		'Linëvo',
		'Kuybyshev',
		'Kupino',
		'Krasnoobsk',
		'Kochenëvo',
		'Karasuk',
		'Iskitim',
		'Cherepanovo',
		'Bolotnoye',
		'Berdsk',
		'Barabinsk',
		'Akademgorodok',
	],
	'Nueva Esparta': ['La Asunción', 'Santa Ana', 'San Juan Bautista', 'Punta de Piedras', 'Porlamar', 'Pampatar', 'Juan Griego'],
	'Nueva Segovia Department': ['Ocotal', 'Jalapa'],
	'Nuevo León': [
		'Montemorelos',
		'Linares',
		'Cadereyta Jiménez',
		'Cadereyta',
		'García',
		'Santiago',
		'Santa Catarina',
		'San Nicolás de los Garza',
		'Ciudad Sabinas Hidalgo',
		'Monterrey',
		'Ciudad Benito Juárez',
		'Guadalupe',
		'Ciudad General Escobedo',
		'San Pedro Garza García',
		'Anáhuac',
		'Ciénega de Flores',
		'Ciudad Apodaca',
		'Ciudad de Allende',
		'Hidalgo',
		'Jardines de la Silla (Jardines)',
		'Fraccionamiento Real Palmas',
		'Parque Industrial Ciudad Mitras',
		'Mitras Poniente',
	],
	Nugaal: ['Garoowe', 'Eyl'],
	Nyandarua: ['Ol Kalou'],
	Nyanga: ['Tchibanga'],
	Nyeri: ['Nyeri'],
	Nzerekore: ['Nzérékoré', 'Macenta', 'Gueckedou'],
	Nógrád: ['Salgótarján', 'Balassagyarmat'],
	Nālūt: ['Nālūt'],
	'N’Djaména': ["N'Djamena"],
	"O'Higgins Region": ['San Vicente de Tagua Tagua', 'San Vicente', 'Santa Cruz', 'Rengo', 'Rancagua', 'Machalí', 'Graneros', 'Chimbarongo'],
	Oaxaca: [
		'Santo Domingo Tehuantepec',
		'Pinotepa Nacional',
		'Santa María Atzompa',
		'Heroica Ciudad de Tlaxiaco',
		'Santa Cruz Xoxocotlán',
		'Tuxtepec',
		'Salina Cruz',
		'Puerto Escondido',
		'Ocotlán de Morelos',
		'Oaxaca',
		'Miahuatlán de Porfirio Díaz',
		'Matías Romero',
		'San Antonio de la Cal',
		'Juchitán de Zaragoza',
		'Ixtepec',
		'Ciudad de Huajuapan de León',
		'Loma Bonita',
		'Crucecita',
	],
	Obock: ['Obock'],
	Occitanie: [
		'Tournefeuille',
		'Toulouse',
		'Tarbes',
		'Sète',
		'Rodez',
		'Plaisance-du-Touch',
		'Perpignan',
		'Pamiers',
		'Nîmes',
		'Narbonne',
		'Muret',
		'Montpellier',
		'Montauban',
		'Millau',
		'Mauguio',
		'Lunel',
		'Lourdes',
		'Lattes',
		'Frontignan',
		'Cugnaux',
		'Colomiers',
		'Castres',
		'Castelnau-le-Lez',
		'Carcassonne',
		'Cahors',
		'Blagnac',
		'Béziers',
		'Balma',
		'Bagnols-sur-Cèze',
		'Auch',
		'Alès',
		'Albi',
		'Agde',
	],
	Odessa: ['Yuzhne', 'Rozdilna', 'Odesa', 'Podilsk', 'Kiliya', 'Izmail', 'Chornomors’k', 'Bolhrad', 'Bilhorod-Dnistrovskyi', 'Balta', 'Reni'],
	Odisha: [
		'Titlāgarh',
		'Tālcher',
		'Sundargarh',
		'Soro',
		'Sorada',
		'Sonepur',
		'Sambalpur',
		'Remuna',
		'Raurkela',
		'Puri',
		'Polasara',
		'Pipili',
		'Phulbāni',
		'Patnāgarh',
		'Patāmundai',
		'Parādīp Garh',
		'Padampur',
		'Nowrangapur',
		'Nimāparha',
		'Nīlgiri',
		'Nayāgarh',
		'Malakanagiri',
		'Kuchaiburi',
		'Korāput',
		'Konārka',
		'Khurda',
		'Kesinga',
		'Kendrāparha',
		'Kantābānji',
		'Kāmākhyānagar',
		'Jharsuguda',
		'Jeypore',
		'Jatani',
		'Jaleshwar',
		'Jājpur',
		'Jagatsinghapur',
		'Hīrākud',
		'Hinjilikatu',
		'Gunupur',
		'Dhenkānāl',
		'Deogarh',
		'Cuttack',
		'Chatrapur',
		'Burla',
		'Brājarājnagar',
		'Brahmapur',
		'Birmitrapur',
		'Binka',
		'Bhubaneshwar',
		'Bhuban',
		'Bhawānipatna',
		'Bhanjanagar',
		'Bhadrakh',
		'Baud',
		'Bāsudebpur',
		'Barpāli',
		'Bargarh',
		'Bānki',
		'Bānapur',
		'Bālugaon',
		'Balasore',
		'Balāngīr',
		'Bada Barabīl',
		'Āthagarh',
		'Āsika',
		'Angul',
		'Barbil',
	],
	'Ogooué-Lolo': ['Koulamoutou'],
	'Ogooué-Maritime': ['Port-Gentil'],
	Ogre: ['Ogre'],
	Ogun: ['Shagamu', 'Iperu', 'Ilaro', 'Ijebu-Ode', 'Ijebu-Igbo', 'Ifo', 'Ado-Odo', 'Abeokuta'],
	Ohio: [
		'Athens',
		'Beavercreek',
		'Centerville',
		'Cincinnati',
		'Columbus',
		'Dayton',
		'Fairborn',
		'Fairfield',
		'Forest Park',
		'Grove City',
		'Hamilton',
		'Huber Heights',
		'Kettering',
		'Lancaster',
		'Lebanon',
		'Mason',
		'Miamisburg',
		'Middletown',
		'Norwood',
		'Oxford',
		'Pataskala',
		'Pickerington',
		'Portsmouth',
		'Reynoldsburg',
		'Riverside',
		'Springboro',
		'Springfield',
		'Trotwood',
		'Upper Arlington',
		'Vandalia',
		'White Oak',
		'Whitehall',
		'Xenia',
		'Zanesville',
		'Chillicothe',
		'Cleveland Heights',
		'Akron',
		'Alliance',
		'Ashland',
		'Ashtabula',
		'Aurora',
		'Austintown',
		'Avon',
		'Avon Center',
		'Avon Lake',
		'Barberton',
		'Bay Village',
		'Berea',
		'Boardman',
		'Bowling Green',
		'Broadview Heights',
		'Brook Park',
		'Brunswick',
		'Canton',
		'Cleveland',
		'Collinwood',
		'Cuyahoga Falls',
		'Defiance',
		'Delaware',
		'Dublin',
		'East Cleveland',
		'Eastlake',
		'Elyria',
		'Euclid',
		'Fairview Park',
		'Findlay',
		'Fremont',
		'Gahanna',
		'Garfield Heights',
		'Glenville',
		'Green',
		'Hilliard',
		'Hough',
		'Hudson',
		'Kent',
		'Lakewood',
		'Lima',
		'Lorain',
		'Mansfield',
		'Maple Heights',
		'Marion',
		'Marysville',
		'Massillon',
		'Mayfield Heights',
		'Medina',
		'Mentor',
		'Middleburg Heights',
		'Mount Vernon',
		'New Philadelphia',
		'Newark',
		'Niles',
		'North Canton',
		'North Olmsted',
		'North Ridgeville',
		'North Royalton',
		'Norwalk',
		'Oregon',
		'Painesville',
		'Parma',
		'Parma Heights',
		'Perrysburg',
		'Piqua',
		'Rocky River',
		'Sandusky',
		'Shaker Heights',
		'Sidney',
		'Solon',
		'South Euclid',
		'Steubenville',
		'Stow',
		'Streetsboro',
		'Strongsville',
		'Sylvania',
		'Tallmadge',
		'Tiffin',
		'Toledo',
		'Troy',
		'Twinsburg',
		'Wadsworth',
		'Warren',
		'Westerville',
		'Westlake',
		'Willoughby',
		'Wooster',
		'Youngstown',
		'Detroit-Shoreway',
		'Clark-Fulton',
	],
	Ohrid: ['Ohrid'],
	Oita: [
		'Usuki',
		'Tsurusaki',
		'Tsukumiura',
		'Tsukawaki',
		'Takedamachi',
		'Bungo-Takada-shi',
		'Saiki',
		'Ōita',
		'Nakatsu',
		'Kitsuki',
		'Hita',
		'Hiji',
		'Beppu',
	],
	Okayama: ['Tsuyama', 'Tamano', 'Takahashi', 'Sōja', 'Okayama', 'Niimi', 'Kurashiki', 'Kasaoka', 'Kamogatachō-kamogata', 'Ibara'],
	Okinawa: [
		'Yonabaru',
		'Tomigusuku',
		'Naha',
		'Nago',
		'Itoman',
		'Ishikawa',
		'Ishigaki',
		'Hirara',
		'Katsuren-haebaru',
		'Gushikawa',
		'Ginowan',
		'Chatan',
		'Okinawa',
		'Miyakojima',
		'Yomitan',
	],
	Oklahoma: [
		'Ada',
		'Altus',
		'Ardmore',
		'Bartlesville',
		'Bethany',
		'Bixby',
		'Broken Arrow',
		'Chickasha',
		'Claremore',
		'Del City',
		'Duncan',
		'Durant',
		'Edmond',
		'El Reno',
		'Enid',
		'Jenks',
		'Lawton',
		'McAlester',
		'Midwest City',
		'Moore',
		'Muskogee',
		'Mustang',
		'Norman',
		'Oklahoma City',
		'Owasso',
		'Ponca City',
		'Sand Springs',
		'Sapulpa',
		'Shawnee',
		'Stillwater',
		'Tahlequah',
		'Tulsa',
		'Yukon',
	],
	'Olancho Department': ['Juticalpa'],
	Olomoucký: ['Šumperk', 'Prostějov', 'Přerov', 'Olomouc', 'Hranice'],
	Olt: ['Slatina', 'Corabia', 'Caracal', 'Balş'],
	Omaheke: ['Gobabis'],
	"Ombella-M'Poko": ['Damara', 'Bimbo'],
	Omran: ['‘Amrān'],
	'Omsk Oblast': ['Tara', 'Omsk', 'Kalachinsk'],
	Ondo: ['Owo', 'Ondo', 'Ode', 'Idanre', 'Akure'],
	Ontario: [
		'Agincourt North',
		'Ajax',
		'Alliston',
		'Aurora',
		'Barrie',
		'Bayview Village',
		'Belleville',
		'Bendale',
		'Bracebridge',
		'Brampton',
		'Brant',
		'Brantford',
		'Brockville',
		'Burlington',
		'Caledon',
		'Cambridge',
		'Chatham',
		'Clarence-Rockland',
		'Cliffcrest',
		'Cobourg',
		'Collingwood',
		'Cornwall',
		'Dorset Park',
		'East Gwillimbury',
		'Humbermede',
		'Flemingdon Park',
		'Fort Erie',
		'Greater Sudbury',
		'Greater Napanee',
		'Grimsby',
		'Guelph',
		'Hamilton',
		'Henry Farm',
		'Hillcrest Village',
		'Huntsville',
		'Kanata',
		'Kennedy Park',
		'Kenora',
		'Keswick',
		'Kingston',
		'Kitchener',
		'Leamington',
		'Lindsay',
		'London',
		'Malvern',
		'Markham',
		'Midland',
		'Milliken',
		'Milton',
		'Mimico',
		'Mississauga',
		'Nepean',
		'Newmarket',
		'Niagara Falls',
		'Niagara-on-the-Lake',
		'Norfolk County',
		'North Bay',
		'Oakville',
		'Oakwood Village',
		'Orangeville',
		'Orillia',
		'Oshawa',
		'Ottawa',
		'Owen Sound',
		'Petawawa',
		'Peterborough',
		'Pickering',
		'Port Colborne',
		'Prince Edward',
		'Quinte West',
		'Richmond Hill',
		'Rouge',
		'Sarnia',
		'Sault Ste. Marie',
		'Scarborough Village',
		'St. Catharines',
		'Steeles',
		'Stratford',
		'St. Thomas',
		"Tam O'Shanter-Sullivan",
		'Tecumseh',
		'The Beaches',
		'Thorold',
		'Thunder Bay',
		'Timmins',
		'Toronto',
		'Uxbridge',
		'Vanier',
		'Vaughan',
		'Wasaga Beach',
		'Waterloo',
		'Welland',
		'West Hill',
		'Weston',
		'Windsor',
		'Woburn',
		'Woodstock',
		'Moss Park',
		'High Park North',
		'Little Portugal',
		'Niagara',
		'Trinity-Bellwoods',
		'Morningside',
		'Eglinton East',
		'Pleasant View',
		'Fergus',
		'Ancaster',
		'Willowdale',
		'Fallingbrook',
		'Valley East',
		'York University Heights',
		'Parkwoods-Donalda',
		'South Parkdale',
		'South Riverdale',
		'North St.James Town',
		'Waterfront Communities-The Island',
		'West Humber-Clairville',
		'Banbury-Don Mills',
		'Birchcliffe-Cliffside',
		'Islington-City Centre West',
		'Mount Pleasant West',
		'Eringate-Centennial-West Deane',
		'Dovercourt-Wallace Emerson-Junction',
		'Edenbridge-Humber Valley',
		'Newtonbrook East',
		'Newtonbrook West',
		"L'Amoreaux",
		'Taylor-Massey',
		'Lansing-Westgate',
		'Lawrence Park South',
		'Leaside-Bennington',
		'Mount Olive-Silverstone-Jamestown',
		'Mount Pleasant East',
		'Willowdale West',
		'Black Creek',
		'Rockcliffe-Smythe',
		'Rosedale-Moore Park',
		'Stonegate-Queensway',
		'Danforth East York',
		'St.Andrew-Windfields',
		'Annex',
		'Thorncliffe Park',
		'Victoria Village',
		'Wexford/Maryvale',
		'Westminster-Branson',
		'Willowdale East',
		'Willowridge-Martingrove-Richview',
		'Church-Yonge Corridor',
		'Brookhaven-Amesbury',
		"O'Connor-Parkview",
		'Kensington-Chinatown',
		'Bay Street Corridor',
		'Agincourt South-Malvern West',
		'East End-Danforth',
		'Englemount-Lawrence',
		'Bedford Park-Nortown',
		'Bathurst Manor',
		'Clanton Park',
		'Clairlea-Birchmount',
		'Don Valley Village',
		'Downsview-Roding-CFB',
		'Glenfield-Jane Heights',
		'High Park-Swansea',
		'Kingsview Village-The Westway',
	],
	'Opole Voivodeship': [
		'Strzelce Opolskie',
		'Prudnik',
		'Opole',
		'Nysa',
		'Namysłów',
		'Krapkowice',
		'Kluczbork',
		'Kędzierzyn-Koźle',
		'Głuchołazy',
		'Brzeg',
	],
	'Opština Nikšić': ['Nikšić'],
	Oran: ['Sidi ech Chahmi', 'Oran', 'Mers el Kebir', 'Es Senia', 'Bou Tlelis', 'Bir el Djir', '’Aïn el Turk', 'Aïn el Bya'],
	'Orange Free State': [
		'Wesselsbron',
		'Welkom',
		'Virginia',
		'Viljoenskroon',
		'Theunissen',
		'Thaba Nchu',
		'Senekal',
		'Sasolburg',
		'Reitz',
		'Phuthaditjhaba',
		'Parys',
		'Ladybrand',
		'Kutloanong',
		'Kroonstad',
		'Hennenman',
		'Heilbron',
		'Harrismith',
		'Botshabelo',
		'Bothaville',
		'Bloemfontein',
		'Bethlehem',
		'Allanridge',
	],
	'Orange Walk District': ['Orange Walk'],
	Ordu: ['Ünye', 'Ordu', 'Kumru', 'Korgan', 'Gürgentepe', 'Fatsa'],
	Oregon: [
		'Albany',
		'Aloha',
		'Altamont',
		'Ashland',
		'Beaverton',
		'Bend',
		'Bethany',
		'Canby',
		'Central Point',
		'Coos Bay',
		'Corvallis',
		'Dallas',
		'Eugene',
		'Forest Grove',
		'Four Corners',
		'Grants Pass',
		'Gresham',
		'Happy Valley',
		'Hayesville',
		'Hermiston',
		'Hillsboro',
		'Keizer',
		'Klamath Falls',
		'Lake Oswego',
		'Lebanon',
		'Lents',
		'McMinnville',
		'Medford',
		'Milwaukie',
		'Newberg',
		'Oak Grove',
		'Oregon City',
		'Pendleton',
		'Portland',
		'Redmond',
		'Roseburg',
		'Salem',
		'Sherwood',
		'Springfield',
		'The Dalles',
		'Tigard',
		'Troutdale',
		'Tualatin',
		'West Linn',
		'Wilsonville',
		'Woodburn',
	],
	Orellana: ['Puerto Francisco de Orellana', 'Boca Suno'],
	'Orenburg Oblast': [
		'Yasnyy',
		'Sorochinsk',
		'Sol’-Iletsk',
		'Saraktash',
		'Orsk',
		'Orenburg',
		'Novotroitsk',
		'Mednogorsk',
		'Kuvandyk',
		'Gay',
		'Buzuluk',
		'Buguruslan',
		'Abdulino',
		'Krasnaya Glinka',
	],
	Orhei: ['Orhei'],
	Orhon: ['Erdenet'],
	Oriental: ['Zaïo', 'Taourirt', 'Oujda-Angad', 'Nador', 'Al Aaroui', 'Jerada', 'El Aïoun', 'Bouarfa', 'Berkane', 'Aïn Beni Mathar', 'Ahfir'],
	Oromiya: [
		'Ziway',
		'Yabēlo',
		'Wenjī',
		'Shashemenē',
		'Shambu',
		'Shakiso',
		'Sebeta',
		'Nejo',
		'Nazrēt',
		'Mojo',
		'Metu',
		'Metahāra',
		'Mendī',
		'Kibre Mengist',
		'Jimma',
		'Hāgere Hiywet',
		'Goba',
		'Waliso',
		'Ginir',
		'Gimbi',
		'Genet',
		'Gelemso',
		'Gebre Guracha',
		'Fichē',
		'Dodola',
		'Dembī Dolo',
		'Bishoftu',
		'Bedēsa',
		'Bedelē',
		'Āsbe Teferī',
		'Āsasa',
		'Hagere Maryam',
		'Āgaro',
	],
	Oruro: ['Oruro', 'Huanuni'],
	'Oryol oblast': ['Orël', 'Mtsensk', 'Livny'],
	Osh: ['Uzgen', 'Osh', 'Kara Suu', 'Nookat', 'Kara-Kulja'],
	Oshana: ['Oshakati'],
	Oslo: ['Oslo'],
	Osmaniye: ['Osmaniye', 'Kadirli'],
	Ostrobothnia: ['Vaasa', 'Korsholm', 'Jakobstad'],
	Osun: [
		'Oyan',
		'Otan Ayegbaju',
		'Osogbo',
		'Olupona',
		'Oke Mesi',
		'Modakeke',
		'Iwo',
		'Inisa',
		'Ilobu',
		'Ilesa',
		'Ila Orangun',
		'Ikirun',
		'Ikire',
		'Ijebu-Jesa',
		'Gbongan',
		'Ejigbo',
		'Apomu',
	],
	Otago: ['Dunedin'],
	Otjozondjupa: ['Otjiwarongo', 'Okahandja', 'Grootfontein'],
	Ouadaï: ['Abéché'],
	Ouaka: ['Ippy', 'Bambari'],
	Ouargla: ['Rouissat', 'Ouargla', 'Hassi Messaoud', 'El Hadjira'],
	Oudômxai: ['Muang Xay'],
	Ouest: [
		'Thomazeau',
		'Port-au-Prince',
		'Tigwav',
		'Pétionville',
		'Léogâne',
		'Kenscoff',
		'Gressier',
		'Grangwav',
		'Fond Parisien',
		'Delmas 73',
		'Croix-des-Bouquets',
		'Carrefour',
	],
	Ouham: ['Bossangoa', 'Batangafo'],
	'Ouham-Pendé': ['Paoua', 'Bozoum'],
	'Ouled Djellal': ['Sidi Khaled'],
	'Oum el Bouaghi': ['Oum el Bouaghi', 'Meskiana', 'Aïn Kercha', 'Aïn Fakroun', 'Aïn Beïda'],
	Ouémé: ['Porto-Novo'],
	Overijssel: [
		'Zwolle',
		'Wierden',
		'Tubbergen',
		'Steenwijk',
		'Staphorst',
		'Raalte',
		'Oldenzaal',
		'Losser',
		'Kampen',
		'Hengelo',
		'Hardenberg',
		'Haaksbergen',
		'Enschede',
		'Deventer',
		'Dalfsen',
		'Borne',
		'Almelo',
	],
	Oyo: ['Saki', 'Oyo', 'Orita-Eruwa', 'Ogbomoso', 'Lalupon', 'Kisi', 'Igbo-Ora', 'Igboho', 'Igbeti', 'Ibadan', 'Fiditi'],
	"P'yŏngan-bukto": ['Kusŏng', 'Kujang-ŭp', 'Chŏngju', 'Uiju', 'Sinŭiju', 'Sakchu-ŭp'],
	Pafos: ['Paphos'],
	Pahang: ['Jerantut', 'Raub', 'Kuala Lipis', 'Pekan', 'Mentekab', 'Temerluh', 'Kuantan', 'Bentong Town'],
	'Paijat-Hame': ['Lahti', 'Hollola', 'Heinola'],
	Pailin: ['Pailin'],
	Paktia: ['Gardez'],
	Pamplemousses: ['Triolet', 'Le Hochet'],
	Panamá: [
		'Tocumen',
		'San Miguelito',
		'Río Abajo',
		'Pedregal',
		'Parque Lefevre',
		'Panamá',
		'Las Cumbres',
		'La Cabima',
		'Juan Díaz',
		'El Chorrillo',
		'Curundú',
		'Chilibre',
		'Ancón',
		'Alcalde Díaz',
		'Nuevo Belén',
	],
	'Panamá Oeste Province': ['Veracruz', 'La Chorrera', 'Arraiján'],
	Pando: ['Cobija'],
	Panevėžys: ['Panevėžys'],
	Panjshir: ['Bāzārak'],
	Papua: ['Fakfak', 'Jayapura', 'Abepura'],
	Paramaribo: ['Paramaribo'],
	Paraná: [
		'Wenceslau Braz',
		'União da Vitória',
		'Umuarama',
		'Toledo',
		'Telêmaco Borba',
		'Sarandi',
		'São Miguel do Iguaçu',
		'São Mateus do Sul',
		'São José dos Pinhais',
		'Santo Antônio da Platina',
		'Rolândia',
		'Rio Negro',
		'Rio Branco do Sul',
		'Quatro Barras',
		'Prudentópolis',
		'Ponta Grossa',
		'Pitanga',
		'Piraquara',
		'Piraí do Sul',
		'Pinhão',
		'Pato Branco',
		'Paranavaí',
		'Paranaguá',
		'Palotina',
		'Palmeira',
		'Palmas',
		'Paiçandu',
		'Medianeira',
		'Maringá',
		'Marialva',
		'Marechal Cândido Rondon',
		'Mandaguari',
		'Londrina',
		'Loanda',
		'Laranjeiras do Sul',
		'Lapa',
		'Jandaia do Sul',
		'Jaguariaíva',
		'Jacarezinho',
		'Itaperuçu',
		'Irati',
		'Imbituva',
		'Ibiporã',
		'Ibaiti',
		'Guaratuba',
		'Guarapuava',
		'Francisco Beltrão',
		'Foz do Iguaçu',
		'Dois Vizinhos',
		'Curitiba',
		'Cruzeiro do Oeste',
		'Coronel Vivida',
		'Cornélio Procópio',
		'Colorado',
		'Colombo',
		'Cianorte',
		'Castro',
		'Cascavel',
		'Campo Mourão',
		'Campo Largo',
		'Campina Grande do Sul',
		'Cambé',
		'Cambará',
		'Bandeirantes',
		'Astorga',
		'Araucária',
		'Arapongas',
		'Apucarana',
		'Antonina',
		'Almirante Tamandaré',
		'Pinhais',
	],
	Paraíba: [
		'Conde',
		'Sousa',
		'Solânea',
		'São Bento',
		'Santa Rita',
		'Pombal',
		'Patos',
		'Monteiro',
		'Mari',
		'Mamanguape',
		'João Pessoa',
		'Itaporanga',
		'Itabaiana',
		'Guarabira',
		'Esperança',
		'Catolé do Rocha',
		'Campina Grande',
		'Cajazeiras',
		'Cabedelo',
		'Belém',
		'Bayeux',
		'Alagoa Grande',
	],
	Pardubický: ['Ústí nad Orlicí', 'Svitavy', 'Pardubice', 'Chrudim', 'Česká Třebová'],
	Parwan: ['Jabal os Saraj', 'Charikar'],
	Pará: [
		'Viseu',
		'Vigia',
		'Tucuruí',
		'Tucumã',
		'Tomé Açu',
		'Soure',
		'São Miguel do Guamá',
		'São Félix do Xingu',
		'Santarém',
		'Salinópolis',
		'Portel',
		'Paragominas',
		'Oriximiná',
		'Óbidos',
		'Monte Alegre',
		'Moju',
		'Mocajuba',
		'Marabá',
		'Itupiranga',
		'Itaituba',
		'Igarapé Miri',
		'Igarapé Açu',
		'Conceição do Araguaia',
		'Castanhal',
		'Capitão Poço',
		'Capanema',
		'Cametá',
		'Breves',
		'Bragança',
		'Benevides',
		'Belém',
		'Barcarena',
		'Augusto Corrêa',
		'Ananindeua',
		'Altamira',
		'Almeirim',
		'Alenquer',
		'Abaetetuba',
		'Ourilândia do Norte',
		'Parauapebas',
		'Redenção',
		'Tucumã',
		'Xinguara',
	],
	Pasco: ['Chaupimarca', 'Cerro de Pasco', 'Yanacancha'],
	Pastaza: ['Puyo'],
	'Pathum Thani': ['Pathum Thani', 'Khlong Luang', 'Ban Lam Luk Ka'],
	Pattani: ['Yaring', 'Pattani'],
	'Pavlodar Region': ['Pavlodar', 'Aksu', 'Ekibastuz'],
	'Pays de la Loire': [
		'Vertou',
		'Saumur',
		'Saint-Sébastien-sur-Loire',
		'Saint-Nazaire',
		'Saint-Herblain',
		'Rezé',
		'Orvault',
		'Nantes',
		'Mayenne',
		"Les Sables-d'Olonne",
		'Les Herbiers',
		'Le Mans',
		'Laval',
		'La Roche-sur-Yon',
		'La Flèche',
		'La Chapelle-sur-Erdre',
		'Guérande',
		'Fontenay-le-Comte',
		'La Baule-Escoublac',
		'Couëron',
		'Cholet',
		'Challans',
		'Carquefou',
		'Bouguenais',
		'Angers',
	],
	'Paysandú Department': ['Paysandú'],
	Pazardzhik: ['Velingrad', 'Peshtera', 'Pazardzhik', 'Panagyurishte'],
	Pec: ['Peć', 'Istok'],
	Peloponnese: ['Trípoli', 'Sparta', 'Kórinthos', 'Kalamata', 'Árgos'],
	'Pemba North': ['Wete'],
	'Pemba South': ['Chake Chake'],
	Penang: [
		'Batu Feringgi',
		'Butterworth',
		'Perai',
		'Bukit Mertajam',
		'Nibong Tebal',
		'Tasek Glugor',
		'George Town',
		'Kepala Batas',
		'Tanjung Tokong',
		'Permatang Kuching',
		'Kampung Sungai Ara',
	],
	Pennsylvania: [
		'Chambersburg',
		'Chester',
		'Drexel Hill',
		'Hanover',
		'Pennsport',
		'Philadelphia',
		'Springfield',
		'West Chester',
		'Wharton',
		'Whitman',
		'York',
		'Abington',
		'Allentown',
		'Allison Park',
		'Altoona',
		'Back Mountain',
		'Baldwin',
		'Bensalem',
		'Bethel Park',
		'Bethlehem',
		'Carlisle',
		'Easton',
		'Erie',
		'Harrisburg',
		'Hazleton',
		'Hermitage',
		'Johnstown',
		'King of Prussia',
		'Lancaster',
		'Lansdale',
		'Lebanon',
		'Levittown',
		'Limerick',
		'McKeesport',
		'Monroeville',
		'Mount Lebanon',
		'Murrysville',
		'New Castle',
		'Nicetown-Tioga',
		'Norristown',
		'Penn Hills',
		'Phoenixville',
		'Pittsburgh',
		'Plum',
		'Pottstown',
		'Radnor',
		'Reading',
		'Scranton',
		'State College',
		'Upper Saint Clair',
		'Wayne',
		'West Mifflin',
		'Whitehall Township',
		'Wilkes-Barre',
		'Wilkinsburg',
		'Williamsport',
		'Willow Grove',
		'Cranberry Township',
		'Center City',
	],
	'Penza Oblast': ['Zarechnyy', 'Serdobsk', 'Penza', 'Nizhniy Lomov', 'Nikol’sk', 'Kuznetsk', 'Zarechnyy'],
	Perak: [
		'Bagan Serai',
		'Simpang Empat',
		'Taiping',
		'Kuala Kangsar',
		'Ipoh',
		'Parit Buntar',
		'Batu Gajah',
		'Kampar',
		'Tapah Road',
		'Bidur',
		'Lumut',
		'Teluk Intan',
		'Pantai Remis',
		'Kampong Dungun',
	],
	Peravia: ['Baní'],
	Perlis: ['Kangar'],
	'Perm Krai': [
		'Vereshchagino',
		'Solikamsk',
		'Perm',
		'Osa',
		'Ochër',
		'Nytva',
		'Lys’va',
		'Kungur',
		'Kudymkar',
		'Krasnovishersk',
		'Krasnokamsk',
		'Kizel',
		'Gubakha',
		'Dobryanka',
		'Chusovoy',
		'Chernushka',
		'Chaykovskiy',
		'Berezniki',
		'Aleksandrovsk',
	],
	Pernambuco: [
		'Vitória de Santo Antão',
		'Trindade',
		'Toritama',
		'Timbaúba',
		'Tamandaré',
		'Tabira',
		'Surubim',
		'Sirinhaém',
		'Sertânia',
		'Serra Talhada',
		'São Lourenço da Mata',
		'São José do Egito',
		'Santa Cruz do Capibaribe',
		'Salgueiro',
		'Rio Formoso',
		'Ribeirão',
		'Recife',
		'Pombos',
		'Petrolina',
		'Jatobá',
		'Pesqueira',
		'Paulista',
		'Palmares',
		'Ouricuri',
		'Olinda',
		'Nazaré da Mata',
		'Moreno',
		'Limoeiro',
		'Lajedo',
		'Lagoa do Itaenga',
		'Jaboatão',
		'Itapissuma',
		'Ipubi',
		'Ipojuca',
		'Igarassu',
		'Gravatá',
		'Goiana',
		'Glória do Goitá',
		'Garanhuns',
		'Gameleira',
		'Floresta',
		'Escada',
		'Custódia',
		'Cupira',
		'Condado',
		'Chã Grande',
		'Catende',
		'Caruaru',
		'Carpina',
		'Cabrobó',
		'Cabo',
		'Buíque',
		'Brejo da Madre de Deus',
		'Bom Conselho',
		'Bezerros',
		'Belo Jardim',
		'Barreiros',
		'Arcoverde',
		'Araripina',
		'Amaraji',
		'Águas Belas',
		'Água Preta',
		'Afogados da Ingazeira',
		'Abreu e Lima',
		'Jaboatão dos Guararapes',
	],
	Pernik: ['Pernik'],
	Pest: [
		'Abony',
		'Veresegyház',
		'Vecsés',
		'Vác',
		'Szigetszentmiklós',
		'Szentendre',
		'Százhalombatta',
		'Nagykőrös',
		'Monor',
		'Gyál',
		'Gödöllő',
		'Göd',
		'Fót',
		'Érd',
		'Dunakeszi',
		'Dunaharaszti',
		'Dabas',
		'Cegléd',
		'Budaörs',
	],
	Petén: ['San Benito', 'Poptún', 'Flores'],
	Phatthalung: ['Phatthalung'],
	Phayao: ['Phayao', 'Dok Kham Tai'],
	Phetchabun: ['Wichian Buri', 'Phetchabun', 'Nong Phai', 'Lom Sak', 'Chon Daen'],
	Phetchaburi: ['Phetchaburi', 'Khao Yoi', 'Cha-am', 'Tha Yang'],
	Phichit: ['Thap Khlo', 'Taphan Hin', 'Phichit', 'Bang Mun Nak'],
	Phitsanulok: ['Phitsanulok', 'Bang Rakam', 'Bang Krathum'],
	'Phnom Penh': ['Phnom Penh'],
	'Phra Nakhon Si Ayutthaya': [
		'Wang Noi',
		'Tha Ruea',
		'Phra Nakhon Si Ayutthaya',
		'Phak Hai',
		'Nakhon Luang',
		'Bang Pa-in',
		'Bang Ban',
		'Ban Bang Kadi Pathum Thani',
	],
	Phrae: ['Phrae', 'Den Chai'],
	Phuket: ['Ban Talat Yai', 'Ban Talat Nua', 'Phuket', 'Kathu', 'Wichit', 'Ban Chalong', 'Ban Ratsada'],
	'Phú Thọ': ['Việt Trì'],
	'Phú Yên': ['Tuy Hòa', 'Sông Cầu'],
	Piauí: [
		'Valença do Piauí',
		'União',
		'Teresina',
		'São Raimundo Nonato',
		'Piripiri',
		'Piracuruca',
		'Picos',
		'Pedro II',
		'Parnaíba',
		'Oeiras',
		'José de Freitas',
		'Ipueiras',
		'Floriano',
		'Esperantina',
		'Demerval Lobão',
		'Campo Maior',
		'Barras',
		'Altos',
	],
	Pichincha: ['Quito', 'Machachi', 'Cayambe'],
	Piedmont: [
		'Vercelli',
		'Venaria Reale',
		'Valenza',
		'Trecate',
		'Tortona',
		'Turin',
		'Settimo Torinese',
		'Savigliano',
		'San Mauro Torinese',
		'Saluzzo',
		'Rivoli',
		'Piossasco',
		'Pinerolo',
		'Orbassano',
		'Novi Ligure',
		'Novara',
		'Nichelino',
		'Mondovì',
		'Moncalieri',
		'Ivrea',
		'Pallanza-Intra-Suna',
		'Grugliasco',
		'Galliate',
		'Fossano',
		'Domodossola',
		'Cuneo',
		'Collegno',
		'Ciriè',
		'Chivasso',
		'Chieri',
		'Casale Monferrato',
		'Carmagnola',
		'Bra',
		'Borgomanero',
		'Biella',
		'Asti',
		'Alpignano',
		'Alessandria',
		'Alba',
		'Acqui Terme',
		'Verbania',
	],
	'Pinar del Río': ['Viñales', 'Pinar del Río', 'Minas de Matahambre', 'Los Palacios', 'Guane', 'Consolación del Sur'],
	Pirkanmaa: ['Ylöjärvi', 'Valkeakoski', 'Tampere', 'Pirkkala', 'Nokia', 'Lempäälä', 'Kangasala'],
	Piura: [
		'Tambo Grande',
		'Talara',
		'Sullana',
		'Sechura',
		'Querecotillo',
		'Piura',
		'Paita',
		'Marcavelica',
		'La Unión',
		'Chulucanas',
		'Catacaos',
		'La Breita',
		'San Martin',
	],
	'Plaines Wilhems': ['Vacoas', 'Quatre Bornes', 'Curepipe', 'Beau Bassin-Rose Hill'],
	Plateau: ['Sakété', 'Pobé', 'Kétou', 'Pankshin', 'Jos', 'Bukuru'],
	'Plateau-Central': ['Zorgo', 'Ziniaré', 'Boussé'],
	Plateaux: ['Gamboma', 'Notsé', 'Kpalimé', 'Badou', 'Atakpamé'],
	Pleven: ['Pleven'],
	Pljevlja: ['Pljevlja'],
	Plovdiv: ['Rakovski', 'Parvomay', 'Plovdiv', 'Karlovo', 'Asenovgrad'],
	'Plzeň Region': ['Pilsen', 'Klatovy'],
	Podgorica: ['Podgorica'],
	Podlasie: ['Zambrów', 'Suwałki', 'Sokółka', 'Siemiatycze', 'Łomża', 'Łapy', 'Hajnówka', 'Grajewo', 'Bielsk Podlaski', 'Białystok', 'Augustów'],
	Pohnpei: ['Palikir - National Government Center'],
	'Point Fortin': ['Point Fortin'],
	'Pointe-Noire': ['Pointe-Noire', 'Loandjili'],
	Poltava: ['Poltava', 'Myrhorod', 'Lubny', 'Kremenchuk', 'Hadyach', 'Horishni Plavni'],
	Pomerania: [
		'Wejherowo',
		'Ustka',
		'Tczew',
		'Starogard Gdański',
		'Sopot',
		'Słupsk',
		'Rumia',
		'Reda',
		'Pruszcz Gdański',
		'Malbork',
		'Lębork',
		'Kwidzyn',
		'Kościerzyna',
		'Kartuzy',
		'Gdynia',
		'Gdańsk',
		'Chojnice',
		'Bytów',
	],
	Ponce: ['Ponce'],
	'Port Louis': ['Port Louis'],
	'Port Said': ['Port Said'],
	'Port of Spain': ['Port of Spain'],
	Portalegre: ['Portalegre'],
	Porto: [
		'Vilar de Andorinho',
		'Vila Nova de Gaia',
		'Vila do Conde',
		'Valongo',
		'Trofa',
		'Senhora da Hora',
		'São Pedro da Cova',
		'São Mamede de Infesta',
		'Rio Tinto',
		'Póvoa de Varzim',
		'Porto',
		'Pedroso',
		'Paredes',
		'Oliveira do Douro',
		'Matosinhos',
		'Maia',
		'Leça do Bailio',
		'Leça da Palmeira',
		'Gondomar',
		'Felgueiras',
		'Fânzeres',
		'Ermesinde',
		'Canidelo',
		'Bougado',
		'Baião',
		'Baguim do Monte',
		'Alfena',
		'Águas Santas',
	],
	Portuguesa: ['Villa Bruzual', 'San Rafael de Onoto', 'Píritu', 'Ospino', 'Guanarito', 'Guanare', 'Boconoito', 'Araure', 'Acarigua'],
	'Potosí Department': ['Villazón', 'Tupiza', 'Potosí', 'Llallagua'],
	'Prachin Buri': ['Prachin Buri', 'Kabin Buri'],
	'Prachuap Khiri Khan': ['Sam Roi Yot', 'Pran Buri', 'Prachuap Khiri Khan', 'Kui Buri', 'Hua Hin', 'Bang Saphan'],
	Prague: ['Prosek', 'Prague', 'Modřany', 'Libeň', 'Letňany', 'Braník', 'Černý Most'],
	Prahova: ['Ploieşti', 'Mizil', 'Câmpina', 'Breaza', 'Băicoi'],
	Praia: ['Praia'],
	'Preah Sihanouk': ['Sihanoukville'],
	'Preah Vihear': ['Tbeng Meanchey'],
	'Presidente Hayes': ['Villa Hayes'],
	'Prey Veng': ['Prey Veng'],
	'Prešovský kraj': ['Vranov nad Topľou', 'Stará Ľubovňa', 'Snina', 'Prešov', 'Poprad', 'Kežmarok', 'Humenné', 'Bardejov'],
	Prilep: ['Prilep'],
	Primorye: [
		'Vrangel’',
		'Vladivostok',
		'Ussuriysk',
		'Trudovoye',
		'Fokino',
		'Spassk-Dal’niy',
		'Partizansk',
		'Nakhodka',
		'Luchegorsk',
		'Lesozavodsk',
		'Kavalerovo',
		'Dalnerechensk',
		'Bol’shoy Kamen’',
		'Artëm',
		'Arsen’yev',
		'Dalnegorsk',
	],
	'Prince Edward Island': ['Charlottetown'],
	Pristina: ['Pristina', 'Podujeva', 'Kosovo Polje', 'Glogovac'],
	Prizren: ['Suva Reka', 'Prizren', 'Llazicë', 'Dragash'],
	"Provence-Alpes-Côte d'Azur": [
		'Vitrolles',
		'Vence',
		'Vallauris',
		'Toulon',
		'Sorgues',
		'Six-Fours-les-Plages',
		'Sanary-sur-Mer',
		'Salon-de-Provence',
		'Saint-Raphaël',
		'Saint-Maximin-la-Sainte-Baume',
		'Saint-Loup',
		'Saint-Laurent-du-Var',
		'Sainte-Marguerite',
		'Port-de-Bouc',
		'Pertuis',
		'Orange',
		'Nice',
		'Mougins',
		'Miramas',
		'Menton',
		'Mazargues',
		'Martigues',
		'Marseille',
		'Marignane',
		'Manosque',
		'Mandelieu-la-Napoule',
		"L'Isle-sur-la-Sorgue",
		'Les Pennes-Mirabeau',
		'Les Olives',
		'Le Pontet',
		'Le Cannet',
		'La Valette-du-Var',
		'La Seyne-sur-Mer',
		'La Pomme',
		'La Garde',
		'La Crau',
		'La Ciotat',
		'Istres',
		'Hyères',
		'Grasse',
		'Gardanne',
		'Gap',
		'Fréjus',
		'Fos-sur-Mer',
		'Draguignan',
		'Digne-les-Bains',
		'Cavaillon',
		'Carpentras',
		'Cannes',
		'Cagnes-sur-Mer',
		'Brignoles',
		'Avignon',
		'Aubagne',
		'Arles',
		'Antibes',
		'Allauch',
		'Aix-en-Provence',
		'Marseille 01',
		'Marseille 02',
		'Marseille 03',
		'Marseille 04',
		'Marseille 05',
		'Marseille 06',
		'Marseille 07',
		'Marseille 08',
		'Marseille 10',
		'Marseille 09',
		'Marseille 11',
		'Marseille 12',
		'Marseille 13',
		'Marseille 14',
		'Marseille 15',
		'Marseille 16',
		'La Blancarde',
		'Le Camas',
		'Saint-Barthélémy',
	],
	'Province 1': ['Khā̃dbāri̇̄', 'Īṭahari̇̄', 'Ilām', 'Dharān', 'Dhankutā', 'Biratnagar', 'Bhadrapur', 'Inaruwa', 'Triyuga'],
	'Province 2': ['Siraha', 'Rājbirāj', 'Malaṅgawā', 'Lahān', 'Janakpur', 'Jaleshwar', 'Gaur', 'Birgañj'],
	'Province 4': ['Wāliṅ', 'Pokhara', 'Bāglung'],
	'Pskov Oblast': ['Velikiye Luki', 'Pskov', 'Ostrov', 'Nevel’'],
	Ptuj: ['Ptuj'],
	Puducherry: ['Puducherry', 'Kāraikāl'],
	Puebla: [
		'Zacatlán',
		'Xicotepec de Juárez',
		'Santa Ana Xalmimilulco',
		'San Bernardino Tlaxcalancingo',
		'Teziutlan',
		'Tepeaca',
		'Tepatlaxco de Hidalgo',
		'Tehuacán',
		'Tecamachalco',
		'Moyotzingo',
		'Zinacatepec',
		'San Salvador El Seco',
		'San Martin Texmelucan de Labastida',
		'Sanctorum',
		'Puebla',
		'Palmarito Tochapan',
		'Santiago Momoxpan',
		'Los Reyes de Juárez',
		'Libres',
		'Izúcar de Matamoros',
		'Huejotzingo',
		'Huauchinango',
		'Cuautlancingo',
		'Ciudad Serdán',
		'Cholula',
		'Chignahuapan',
		'Atlixco',
		'Amozoc de Mota',
		'Altepexi',
		'Ajalpan',
		'Acatzingo',
		'Acatlán de Osorio',
		'Acajete',
		'San Andrés Cholula',
		'Casa Blanca',
		'San Rafael Tlanalapan',
	],
	'Puerto Plata': ['Puerto Plata', 'Río Grande', 'Monte Llano'],
	Punakha: ['Punākha'],
	Punjab: [
		'Tarn Tāran',
		'Talwāra',
		'Talwandi Bhai',
		'Sunām',
		'Sultanpur',
		'Sirhind',
		'Sardulgarh',
		'Sangrūr',
		'Sanaur',
		'Samrāla',
		'Ropar',
		'Rāmpura',
		'Rājpura',
		'Rāikot',
		'Qādiān',
		'Phillaur',
		'Phagwāra',
		'Patti',
		'Patiāla',
		'Pathānkot',
		'Nawanshahr',
		'Nangal',
		'Nakodar',
		'Nābha',
		'Morinda',
		'Muktsar',
		'Mukeriān',
		'Moga',
		'Maur',
		'Mānsa',
		'Māler Kotla',
		'Malaut',
		'Māchhīwāra',
		'Ludhiāna',
		'Laungowāl',
		'Kharar',
		'Khanna',
		'Kartārpur',
		'Kapūrthala',
		'Jalandhar',
		'Jandiāla',
		'Jalālābād',
		'Jaito',
		'Jagraon',
		'Hoshiārpur',
		'Guru Har Sahāi',
		'Giddarbāha',
		'Garhshankar',
		'Ferozepore',
		'Fāzilka',
		'Fatehgarh Chūriān',
		'Farīdkot',
		'Dorāha',
		'Dīnānagar',
		'Dhūri',
		'Dhāriwāl',
		'Dhanaula',
		'Dasūya',
		'Budhlāda',
		'Bhogpur',
		'Bhīkhi',
		'Bhawānīgarh',
		'Bathinda',
		'Bhadaur',
		'Batāla',
		'Basi',
		'Basi',
		'Barnāla',
		'Banūr',
		'Banga',
		'Bālāchor',
		'Bāgha Purāna',
		'Anandpur',
		'Amritsar',
		'Ajnāla',
		'Abohar',
		'Adampur',
		'Mohali',
		'Kotkapura',
		'Zira',
		'Zahir Pir',
		'Zafarwal',
		'Yazman',
		'Wazirabad',
		'Chak Five Hundred Seventy-five',
		'Vihari',
		'Toba Tek Singh',
		'Taunsa',
		'Tandlianwala',
		'Talamba',
		'Talagang',
		'Surkhpur',
		'Sukheke Mandi',
		'Sodhri',
		'Sillanwali',
		'Sialkot',
		'Shujaabad',
		'Shekhupura',
		'Sharqpur Sharif',
		'Shakargarh',
		'Shahr Sultan',
		'Shahkot',
		'Sargodha',
		'Sarai Sidhu',
		'Sarai Alamgir',
		'Sangla Hill',
		'Sambrial',
		'Sahiwal',
		'Sahiwal',
		'Saddiqabad',
		'Renala Khurd',
		'Rawalpindi',
		'Rajanpur',
		'Raja Jang',
		'Raiwind',
		'Rahim Yar Khan',
		'Chenab Nagar',
		'Qadirpur Ran',
		'Pir Mahal',
		'Pindi Gheb',
		'Pindi Bhattian',
		'Pind Dadan Khan',
		'Phalia',
		'Pattoki',
		'Pasrur',
		'Pakpattan',
		'Okara',
		'Naushahra Virkan',
		'Narowal',
		'Narang Mandi',
		'Nankana Sahib',
		'Muzaffargarh',
		'Mustafabad',
		'Murree',
		'Muridke',
		'Multan',
		'Mitha Tiwana',
		'Minchianabad',
		'Mianwali',
		'Mian Channun',
		'Mangla',
		'Mandi Bahauddin',
		'Mananwala',
		'Mamu Kanjan',
		'Malakwal',
		'Mailsi',
		'Lodhran',
		'Layyah',
		'Lalian',
		'Lala Musa',
		'Lahore',
		'Ladhewala Waraich',
		'Kunjah',
		'Kundian',
		'Kot Samaba',
		'Kot Radha Kishan',
		'Kot Mumin',
		'Kotli Loharan',
		'Kot Ghulam Muhammad',
		'Kot Addu',
		'Khushāb',
		'Khurrianwala',
		'Khewra',
		'Kharian',
		'Khanpur',
		'Khangarh',
		'Khangah Dogran',
		'Khairpur Tamewah',
		'Kasur',
		'Karor',
		'Kanganpur',
		'Kamra',
		'Kamoke',
		'Chak One Hundred Twenty Nine Left',
		'Kamar Mushani',
		'Kamalia',
		'Kalur Kot',
		'Kallar Kahar',
		'Kaleke Mandi',
		'Kalabagh',
		'Kahuta',
		'Kahror Pakka',
		'Kahna Nau',
		'Kabirwala',
		'Chak Jhumra',
		'Jhelum',
		'Jhawarian',
		'Jhang Sadr',
		'Jauharabad',
		'Jatoi Shimali',
		'Jaranwala',
		'Jand',
		'Jampur',
		'Jalalpur Pirwala',
		'Jalalpur Jattan',
		'Jahanian Shah',
		'Hujra Shah Muqim',
		'Hazro City',
		'Haveli Lakha',
		'Hasilpur',
		'Chak Thirty-one -Eleven Left',
		'Harunabad',
		'Harnoli',
		'Hafizabad',
		'Hadali',
		'Gujrat',
		'Gujranwala',
		'Gujar Khan',
		'Gojra',
		'Garh Maharaja',
		'Fort Abbas',
		'Fazilpur',
		'Fatehpur',
		'Faruka',
		'Faqirwali',
		'Faisalabad',
		'Eminabad',
		'Dunyapur',
		'Dunga Bunga',
		'Dullewala',
		'Dipalpur',
		'Dinga',
		'Dijkot',
		'Dhanot',
		'Dera Ghazi Khan',
		'Daud Khel',
		'Daska Kalan',
		'Darya Khan',
		'Dajal',
		'Chunian',
		'Chuhar Kana',
		'Choa Saidan Shah',
		'Chishtian',
		'Chiniot',
		'Chichawatni',
		'Chawinda',
		'Chakwal',
		'Chak Azam Sahu',
		'Burewala',
		'Bhopalwala',
		'Bhera',
		'Bhawana',
		'Bhalwal',
		'Bhakkar',
		'Mianke Mor',
		'Basirpur',
		'Bahawalpur',
		'Bahawalnagar',
		'Baddomalhi',
		'Attock City',
		'Arifwala',
		'Alipur',
		'Ahmadpur East',
		'Bahawalnagar',
		'Ahmadpur Sial',
		'Sook Kalan',
		'Malakwal City',
	],
	Puno: ['Yunguyo', 'Puno', 'Juliaca', 'Ilave', 'Ayaviri', 'La Rinconada'],
	'Puntarenas Province': ['Puntarenas', 'Esparza', 'Chacarita'],
	Pursat: ['Pursat'],
	Putrajaya: ['Putrajaya'],
	Putumayo: ['Puerto Leguízamo', 'Puerto Asís', 'Mocoa', 'Orito'],
	Pwani: ['Vikindu', 'Mvomero', 'Mlandizi', 'Mkuranga', 'Lugoba', 'Kibiti', 'Kibaha', 'Chalinze', 'Bungu', 'Bagamoyo'],
	Pyongyang: ['Sŭngho 1-tong', 'Sunan', 'Pyongyang', 'Kangdong-ŭp', 'Chunghwa'],
	Pärnumaa: ['Pärnu'],
	'Qachaʼs Nek': ['Qacha’s Nek'],
	Qalyubia: ['Ţūkh', 'Shubrā al Khaymah', 'Shibīn al Qanāṭir', 'Qalyūb', 'Banhā', 'Al Qanāţir al Khayrīyah', 'Al Khuşūş', 'Al Khānkah', "Al-'Ubūr"],
	Qashqadaryo: ['Shahrisabz', 'Qarshi', 'Muborak', 'Kitob', 'Koson', 'G‘uzor', 'Chiroqchi', 'Beshkent'],
	Qazax: ['Qazax'],
	Qazvīn: ['Alvand', 'Tākestān', 'Qazvin'],
	Qena: ['Qūş', 'Qinā', "Naja' Ḥammādī", 'Isnā', 'Farshūţ', 'Dishnā'],
	Qinghai: ['Xining', 'Qingshizui', 'Qiaotou', 'Nianbo', 'Lushar', 'Haomen', 'Duoba', 'Dongchuan', 'Qilian', 'Quankou'],
	Qom: ['Qom'],
	Qormi: ['Qormi'],
	Qostanay: ['Zhitikara', 'Rudnyy', 'Kostanay', 'Lisakovsk', 'Arkalyk'],
	Quba: ['Quba'],
	Quebec: [
		'Alma',
		'Amos',
		'Baie-Comeau',
		'Beaconsfield',
		'Beloeil',
		'Blainville',
		'Boisbriand',
		'Boucherville',
		'Brossard',
		'Candiac',
		'Chambly',
		'Châteauguay',
		'Côte-Saint-Luc',
		'Deux-Montagnes',
		'Dollard-Des Ormeaux',
		'Dorval',
		'Drummondville',
		'Gatineau',
		'Granby',
		'Joliette',
		'Kirkland',
		'La Prairie',
		"L'Assomption",
		'Laval',
		'Les Coteaux',
		'Longueuil',
		'Magog',
		'Mascouche',
		'Mirabel',
		'Montréal',
		'Mont-Royal',
		'Mont-Saint-Hilaire',
		'Notre-Dame-de-Grâce',
		'Pointe-Claire',
		'Repentigny',
		'Rock Forest',
		'Rouyn-Noranda',
		'Saguenay',
		'Saint-Basile-le-Grand',
		'Saint-Bruno-de-Montarville',
		'Saint-Constant',
		'Sainte-Catherine',
		'Sainte-Catherine',
		'Sainte-Julie',
		'Sainte-Thérèse',
		'Saint-Eustache',
		'Saint-Hyacinthe',
		'Saint-Jean-sur-Richelieu',
		'Saint-Jérôme',
		'Saint-Laurent',
		'Saint-Lazare',
		'Saint-Léonard',
		'Saint-Lin-Laurentides',
		'Salaberry-de-Valleyfield',
		'Sept-Îles',
		'Shawinigan',
		'Sherbrooke',
		'Sorel-Tracy',
		'Terrebonne',
		'Trois-Rivières',
		"Val-d'Or",
		'Varennes',
		'Vaudreuil-Dorion',
		'Victoriaville',
		'Westmount',
		'Québec',
		'Lévis',
		'Rimouski',
		'Rivière-du-Loup',
		"L'Ancienne-Lorette",
		'Saint-Georges',
		'Buckingham',
		'Thetford-Mines',
		'Jonquière',
		'Saint-Augustin-de-Desmaures',
		'La Haute-Saint-Charles',
	],
	Queensland: [
		'Mount Isa',
		'Warwick',
		'Upper Coomera',
		'Townsville',
		'Toowoomba',
		'Surfers Paradise',
		'Southport',
		'Rockhampton',
		'Palm Beach',
		'Nerang',
		'Narangba',
		'Morayfield',
		'Maryborough',
		'Mackay',
		'Gold Coast',
		'Gladstone',
		'Eight Mile Plains',
		'Deception Bay',
		'Coorparoo',
		'Capalaba',
		'Cairns',
		'Caboolture',
		'Bundaberg',
		'Buderim',
		'Brisbane',
		'Albany Creek',
		'Maroochydore',
		'Hervey Bay',
		'Springfield Lakes',
		'Bracken Ridge',
		'Kallangur',
		'North Lakes',
		'The Gap',
		'Logan City',
		'Carindale',
		'Helensvale',
		'Redbank Plains',
		'Labrador',
		'Alexandra Hills',
		'Calamvale',
		'Pacific Pines',
		'Robina',
		'Kirwan',
		'Forest Lake',
		'Sunnybank Hills',
		'Rochedale South',
	],
	Querétaro: [
		'Tequisquiapan',
		'San Juan del Río',
		'El Pueblito',
		'Santa Rosa Jauregui',
		'Santiago de Querétaro',
		'Venceremos',
		'San Jose de los Olvera',
	],
	Quetzaltenango: ['Salcajá', 'Quetzaltenango', 'Ostuncalco', 'La Esperanza', 'El Palmar', 'Colomba', 'Coatepeque', 'Cantel', 'Cabricán'],
	Quiché: ['Santa Cruz del Quiché', 'Nebaj', 'Chichicastenango'],
	Quindío: ['Quimbaya', 'Montenegro', 'La Tebaida', 'Circasia', 'Calarcá', 'Armenia'],
	'Quintana Roo': ['Tulum', 'Playa del Carmen', 'Felipe Carrillo Puerto', 'Cozumel', 'Chetumal', 'Cancún'],
	Quneitra: ['Al Qunayţirah'],
	Qusar: ['Qusar'],
	Quthing: ['Quthing'],
	'Quảng Bình': ['Đồng Hới'],
	'Quảng Nam': ['Tam Kỳ', 'Hoi An', 'Đại Lộc'],
	'Quảng Ngãi Province': ['Quảng Ngãi'],
	'Quảng Ninh': ['Thành Phố Uông Bí', 'Móng Cái', 'Hạ Long', 'Cẩm Phả Mines', 'Cẩm Phả'],
	'Quảng Trị': ['Ðông Hà', 'thị xã Quảng Trị'],
	Qyzylorda: ['Zhosaly', 'Novokazalinsk', 'Zhangaqorghan', 'Tasbuget', 'Kyzylorda', 'Shiyeli', 'Aral'],
	'Rabat-Salé-Kénitra': [
		'Tiflet',
		'Temara',
		'Souq Larb’a al Gharb',
		'Sidi Yahia El Gharb',
		'Sidi Slimane',
		'Sidi Qacem',
		'Sale',
		'Rabat',
		'Oulmes',
		'Mechraa Bel Ksiri',
		'Kenitra',
		'Khemisset',
		'Skhirate',
		'Salé Al Jadida',
	],
	Radovish: ['Radovis'],
	'Raionul Edineţ': ['Edineţ'],
	'Raionul Soroca': ['Soroca'],
	Rajasthan: [
		'Wer',
		'Udaipur',
		'Udaipur',
		'Tonk',
		'Todaraisingh',
		'Todabhim',
		'Tijāra',
		'Tārānagar',
		'Takhatgarh',
		'Sūratgarh',
		'Sūrajgarh',
		'Sunel',
		'Suket',
		'Sūjāngarh',
		'Sri Mādhopur',
		'Karanpur',
		'Sri Dūngargarh',
		'Sojat',
		'Siwāna',
		'Sirohi',
		'Sīkar',
		'Sheoganj',
		'Shāhpura',
		'Shāhpura',
		'Sawāi Mādhopur',
		'Sarwār',
		'Sardārshahr',
		'Sangod',
		'Sangariā',
		'Sānchor',
		'Samdari',
		'Sāmbhar',
		'Sālūmbar',
		'Sādri',
		'Rīngas',
		'Rāwatsār',
		'Rāwatbhāta',
		'Ratangarh',
		'Rāmgarh',
		'Rāmganj Mandi',
		'Rājsamand',
		'Rājgarh',
		'Rājgarh',
		'Rājaldesar',
		'Rājākhera',
		'Rāisinghnagar',
		'Raipur',
		'Pushkar',
		'Pratāpgarh',
		'Pokaran',
		'Pīpār',
		'Pindwāra',
		'Pilibangan',
		'Pilāni',
		'Phulera',
		'Phalodi',
		'Parvatsar',
		'Pāli',
		'Padampur',
		'Nokha',
		'Nohar',
		'Neem ka Thana',
		'Nīmbāhera',
		'Nīmāj',
		'Nawalgarh',
		'Niwai',
		'Nāwa',
		'Nāthdwāra',
		'Nasīrābād',
		'Naraina',
		'Napāsar',
		'Nainwa',
		'Nāgaur',
		'Nagar',
		'Nādbai',
		'Mūndwa',
		'Merta',
		'Manoharpur',
		'Māngrol',
		'Māndalgarh',
		'Māndal',
		'Mālpura',
		'Makrāna',
		'Mahwah',
		'Losal',
		'Lālsot',
		'Lākheri',
		'Lādnūn',
		'Lachhmangarh Sīkar',
		'Kūmher',
		'Kuchera',
		'Kuchāman',
		'Kotputli',
		'Kota',
		'Kishangarh',
		'Khetri',
		'Khandela',
		'Keshorai Pātan',
		'Kekri',
		'Karauli',
		'Kāpren',
		'Kāman',
		'Jodhpur',
		'Jhunjhunūn',
		'Jhālrapātan',
		'Jhālāwār',
		'Jalor',
		'Jaitāran',
		'Jaisalmer',
		'Jaipur',
		'Jahāzpur',
		'Hindaun',
		'Hanumāngarh',
		'Gulābpura',
		'Gangāpur',
		'Gangāpur',
		'Gangānagar',
		'Fatehpur',
		'Dūngarpur',
		'Dīg',
		'Dīdwāna',
		'Dhaulpur',
		'Deoli',
		'Devgarh',
		'Deshnoke',
		'Dausa',
		'Chūru',
		'Chittaurgarh',
		'Chidawa',
		'Chhoti Sādri',
		'Chhāpar',
		'Chhabra',
		'Chaksu',
		'Būndi',
		'Bissāu',
		'Bilāra',
		'Bīkaner',
		'Bhīnmāl',
		'Bhindār',
		'Bhīlwāra',
		'Bhasāwar',
		'Bharatpur',
		'Bhādra',
		'Bhādāsar',
		'Behror',
		'Begūn',
		'Beāwar',
		'Bayāna',
		'Baswa',
		'Basni',
		'Basi',
		'Bārmer',
		'Bari Sādri',
		'Bāri',
		'Bārān',
		'Bānswāra',
		'Bāndīkūi',
		'Bālotra',
		'Bāli',
		'Bagar',
		'Āsind',
		'Anūpgarh',
		'Anta',
		'Amet',
		'Alwar',
		'Aklera',
		'Ajmer',
		'Ābu Road',
		'Ābu',
		'Bhiwadi',
	],
	'Rajshahi Division': [
		'Shibganj',
		'Shibganj',
		'Sirajganj',
		'Shāhzādpur',
		'Rājshāhi',
		'Joypur Hāt',
		'Ishurdi',
		'Pār Naogaon',
		'Bera',
		'Pābna',
		'Bogra',
		'Nawābganj',
		'Puthia',
		'Natore',
	],
	Rakhine: ['Sittwe'],
	'Rangpur Division': [
		'Thākurgaon',
		'Panchagarh',
		'Parbatipur',
		'Nageswari',
		'Lalmonirhat',
		'Rangpur',
		'Chilmāri',
		'Badarganj',
		'Dinājpur',
		'Pīrgaaj',
		'Saidpur',
		'Paotana Hat',
	],
	Ranong: ['Ranong'],
	Rarotonga: ['Avarua'],
	Rason: ['Sŏnbong', 'Rajin'],
	Ratanakiri: ['Lumphat', 'Banlung'],
	Ratchaburi: ['Ratchaburi', 'Photharam', 'Damnoen Saduak', 'Chom Bueng', 'Ban Pong', 'Bang Phae'],
	Rayong: ['Rayong', 'Klaeng', 'Ban Phe', 'Ban Chang'],
	'Razavi Khorasan': [
		'Torbat-e Ḩeydarīyeh',
		'Sabzevar',
		'Qūchān',
		'Neyshābūr',
		'Mashhad',
		'Kāshmar',
		'Gonābād',
		'Chenārān',
		'Bardaskan',
		'Torbat-e Jām',
		'Tāybād',
		'Sarakhs',
	],
	Razgrad: ['Razgrad'],
	'Raʼs al Khaymah': ['Ras Al Khaimah City'],
	'Red Sea': ['Ras Gharib', 'Safaga', 'Al Quşayr', 'Hurghada', 'Tokār', 'Sawākin', 'Port Sudan'],
	'Region of Magallanes': ['Punta Arenas', 'Puerto Natales'],
	Relizane: ['Zemoura', 'Relizane', 'Oued Rhiou', 'Mazouna', 'Djidiouia', 'Ammi Moussa'],
	'Republic of Tyva': ['Kyzyl'],
	'Republican Subordination': ['Vahdat', 'Hisor', 'Tursunzoda'],
	Resen: ['Resen'],
	Retalhuleu: ['San Sebastián', 'San Felipe', 'Retalhuleu', 'Nuevo San Carlos', 'El Asintal'],
	'Rheinland-Pfalz': [
		'Zweibrücken',
		'Wörth am Rhein',
		'Worms',
		'Wittlich',
		'Trier',
		'Speyer',
		'Sinzig',
		'Schifferstadt',
		'Remagen',
		'Pirmasens',
		'Nieder-Ingelheim',
		'Neuwied',
		'Neustadt an der Weinstraße',
		'Mayen',
		'Mainz',
		'Ludwigshafen am Rhein',
		'Landau in der Pfalz',
		'Lahnstein',
		'Konz',
		'Koblenz',
		'Kaiserslautern',
		'Ingelheim am Rhein',
		'Idar-Oberstein',
		'Haßloch',
		'Germersheim',
		'Frankenthal',
		'Boppard',
		'Bingen am Rhein',
		'Bendorf',
		'Bad Neuenahr-Ahrweiler',
		'Bad Kreuznach',
		'Bad Dürkheim',
		'Andernach',
		'Alzey',
		'Gartenstadt',
		'Altstadt',
	],
	'Rhode Island': [
		'Barrington',
		'Bristol',
		'Central Falls',
		'Coventry',
		'Cranston',
		'Cumberland',
		'East Providence',
		'Middletown',
		'Narragansett',
		'Newport',
		'North Kingstown',
		'North Providence',
		'Pawtucket',
		'Portsmouth',
		'Providence',
		'Smithfield',
		'Warwick',
		'West Warwick',
		'Westerly',
		'Woonsocket',
		'South Kingstown',
		'Lincoln',
		'Johnston',
	],
	Riau: ['Pekanbaru', 'Dumai', 'Balai Pungut', 'Batam'],
	'Riau Islands': ['Tanjung Pinang', 'Tanjung Balai'],
	'Rif-dimashq': [
		'Yabrūd',
		'Qaţanā',
		'Qārah',
		'Jayrūd',
		'‘Irbīn',
		'Ḩarastā',
		'Dūmā',
		'Dārayyā',
		'Az Zabadānī',
		'At Tall',
		'An Nabk',
		'Al Quţayfah',
		'Al Kiswah',
	],
	Riga: ['Riga'],
	'Rio Grande do Norte': [
		'São José de Mipibu',
		'Santa Cruz',
		'Parnamirim',
		'Parelhas',
		'Nova Cruz',
		'Natal',
		'Mossoró',
		'Macau',
		'Macaíba',
		'João Câmara',
		'Extremoz',
		'Currais Novos',
		'Ceará Mirim',
		'Canguaretama',
		'Caicó',
		'Areia Branca',
		'Apodi',
		'Açu',
	],
	'Rio Grande do Sul': [
		'Viamão',
		'Veranópolis',
		'Venâncio Aires',
		'Vacaria',
		'Uruguaiana',
		'Tupanciretã',
		'Três Passos',
		'Três de Maio',
		'Três Coroas',
		'Tramandaí',
		'Torres',
		'Teutônia',
		'Taquari',
		'Taquara',
		'Tapes',
		'Soledade',
		'Sarandi',
		'Sapucaia',
		'Sapiranga',
		'São Sepé',
		'São Sebastião do Caí',
		'São Marcos',
		'São Luiz Gonzaga',
		'São Lourenço do Sul',
		'São Leopoldo',
		'São Jerônimo',
		'São Gabriel',
		'São Borja',
		'Santo Ângelo',
		'Santiago',
		'Santa Vitória do Palmar',
		'Santa Rosa',
		'Santana do Livramento',
		'Santa Maria',
		'Santa Cruz do Sul',
		'Rosário do Sul',
		'Rolante',
		'Rio Pardo',
		'Rio Grande',
		'Quaraí',
		'Porto Alegre',
		'Portão',
		'Pelotas',
		'Passo Fundo',
		'Parobé',
		'Panambi',
		'Palmeira das Missões',
		'Osório',
		'Novo Hamburgo',
		'Nova Prata',
		'Nova Petrópolis',
		'Montenegro',
		'Marau',
		'Lajeado',
		'Lagoa Vermelha',
		'Júlio de Castilhos',
		'Jaguarão',
		'Ivoti',
		'Itaqui',
		'Ijuí',
		'Igrejinha',
		'Herval',
		'Guaporé',
		'Gravataí',
		'Garibaldi',
		'Frederico Westphalen',
		'Flores da Cunha',
		'Farroupilha',
		'Estrela',
		'Esteio',
		'Estância Velha',
		'Erechim',
		'Encruzilhada do Sul',
		'Encantado',
		'Dom Pedrito',
		'Cruz Alta',
		'Charqueadas',
		'Caxias do Sul',
		'Carlos Barbosa',
		'Carazinho',
		'Capão da Canoa',
		'Canoas',
		'Canguçu',
		'Canela',
		'Candelária',
		'Amaral Ferrador',
		'Cachoeirinha',
		'Cachoeira do Sul',
		'Caçapava do Sul',
		'Butiá',
		'Bento Gonçalves',
		'Bagé',
		'Arroio Grande',
		'Arroio do Meio',
		'Alegrete',
		'Guaíba',
		'Alvorada',
	],
	'Rio Negro': [
		'Villa Regina',
		'Viedma',
		'San Antonio Oeste',
		'General Roca',
		'El Bolsón',
		'Cipolletti',
		'Cinco Saltos',
		'Catriel',
		'Allen',
		'San Carlos de Bariloche',
	],
	'Rio de Janeiro': [
		'Volta Redonda',
		'Vassouras',
		'Valença',
		'Três Rios',
		'Teresópolis',
		'Tanguá',
		'Silva Jardim',
		'Seropédica',
		'Saquarema',
		'São Pedro da Aldeia',
		'São João de Meriti',
		'São João da Barra',
		'São Fidélis',
		'Santo Antônio de Pádua',
		'Rio de Janeiro',
		'Rio das Ostras',
		'Rio Bonito',
		'Resende',
		'Queimados',
		'Piraí',
		'Pinheiral',
		'Petrópolis',
		'Paty do Alferes',
		'Paraty',
		'Paraíba do Sul',
		'Paracambi',
		'Nova Iguaçu',
		'Nova Friburgo',
		'Niterói',
		'Nilópolis',
		'Miracema',
		'Miguel Pereira',
		'Mendes',
		'Maricá',
		'Mangaratiba',
		'Macaé',
		'Japeri',
		'Itaperuna',
		'Itaocara',
		'Itaguaí',
		'Itaboraí',
		'Guapimirim',
		'Duque de Caxias',
		'Cordeiro',
		'Casimiro de Abreu',
		'Campos dos Goytacazes',
		'Cachoeiras de Macacu',
		'Cabo Frio',
		'Bom Jesus do Itabapoana',
		'Belford Roxo',
		'Barra Mansa',
		'Barra do Piraí',
		'Arraial do Cabo',
		'Armação de Búzios',
		'Araruama',
		'Angra dos Reis',
		'São Pedro',
	],
	Risaralda: ['Santa Rosa de Cabal', 'Quinchía', 'Pereira', 'Marsella', 'La Virginia', 'Dosquebradas', 'Belén de Umbría', 'Anserma'],
	Rivas: ['Rivas'],
	'River Nile': ['Shendi', 'Berber', 'Atbara', 'El Bauga', 'Ed Damer'],
	'Rivera Department': ['Rivera'],
	Rivers: ['Port Harcourt', 'Okrika', 'Obonoma', 'Elele', 'Buguma', 'Bonny', 'Degema Hulk'],
	'Rivière du Rempart': ['Goodlands'],
	Rivne: ['Zdolbuniv', 'Sarny', 'Rivne', 'Kostopil', 'Dubno', 'Varash'],
	'Riyadh Region': ['Az Zulfī', 'As Sulayyil', 'Riyadh', 'Al Kharj', 'Afif', 'Ad Dilam', 'Ad Dawādimī'],
	'Rize Province': ['Rize', 'Çayeli', 'Ardeşen'],
	'Rocha Department': ['Rocha'],
	Rogaland: ['Stavanger', 'Sandnes', 'Haugesund'],
	'Roi Et': ['Roi Et', 'Kaset Wisai', 'Ban Selaphum'],
	Rondônia: [
		'Porto Velho',
		'Ariquemes',
		'Vilhena',
		'Pôsto Fiscal Rolim de Moura',
		'Pimenta Bueno',
		'Ouro Preto do Oeste',
		'Ji Paraná',
		'Jaru',
		'Guajará Mirim',
		'Cacoal',
	],
	Roraima: ['Boa Vista'],
	Rostov: [
		'Zverevo',
		'Zimovniki',
		'Zernograd',
		'Yegorlykskaya',
		'Volgodonsk',
		'Tsimlyansk',
		'Taganrog',
		'Shakhty',
		'Semikarakorsk',
		'Sal’sk',
		'Rostov-na-Donu',
		'Proletarsk',
		'Orlovskiy',
		'Novoshakhtinsk',
		'Novocherkassk',
		'Morozovsk',
		'Millerovo',
		'Krasnyy Sulin',
		'Konstantinovsk',
		'Kamensk-Shakhtinsky',
		'Gukovo',
		'Donetsk',
		'Belaya Kalitva',
		'Bataysk',
		'Bagayevskaya',
		'Azov',
		'Aksay',
		'Persianovka',
	],
	Rukwa: ['Sumbawanga', 'Namanyere', 'Matai', 'Laela', 'Kirando', 'Chala'],
	Ruse: ['Ruse'],
	Rutana: ['Rutana'],
	Ruvuma: ['Mahanje', 'Tingi', 'Songea', 'Mbinga', 'Matiri', 'Maposeni', 'Kigonsera'],
	Ruyigi: ['Ruyigi'],
	'Ryazan Oblast': ['Sasovo', 'Skopin', 'Shilovo', 'Rybnoye', 'Ryazhsk', 'Ryazan’', 'Novomichurinsk', 'Kasimov'],
	Réunion: [
		'Saint-Pierre',
		'Saint-Paul',
		'Saint-Louis',
		'Saint-Leu',
		'Saint-Joseph',
		'Sainte-Suzanne',
		'Sainte-Marie',
		'Saint-Denis',
		'Saint-Benoît',
		'Saint-André',
		'Le Tampon',
		'Le Port',
		'La Possession',
		'Piton Saint-Leu',
	],
	'Río Negro Department': ['Young', 'Fray Bentos'],
	Rēzekne: ['Rēzekne'],
	SNNPR: [
		'Yirga ‘Alem',
		'Tēpī',
		'K’olīto',
		'Jinka',
		'Hosa’ina',
		'Felege Neway',
		'Dīla',
		'Butajīra',
		'Bonga',
		'Bodītī',
		'Bako',
		'Hawassa',
		'Āreka',
		'Arba Minch',
		'Sodo',
	],
	'Sa Kaeo': ['Wang Nam Yen', 'Sa Kaeo', 'Aranyaprathet'],
	Saarland: [
		'Wadgassen',
		'Wadern',
		'Völklingen',
		'Sulzbach',
		'Schwalbach',
		'Schmelz',
		'Schiffweiler',
		'Sankt Wendel',
		'Sankt Ingbert',
		'Saarlouis',
		'Saarbrücken',
		'Riegelsberg',
		'Püttlingen',
		'Ottweiler',
		'Neunkirchen',
		'Merzig',
		'Losheim',
		'Lebach',
		'Illingen',
		'Homburg',
		'Heusweiler',
		'Eppelborn',
		'Dillingen',
		'Blieskastel',
		'Bexbach',
		'Beckingen',
	],
	Saatlı: ['Əhmədbəyli', 'Saatlı'],
	Sabah: [
		'Papar',
		'Kota Kinabalu',
		'Donggongon',
		'Putatan',
		'Kinarut',
		'Ranau',
		'Semporna',
		'Beaufort',
		'Lahad Datu',
		'Sandakan',
		'Keningau',
		'Tawau',
		'Kudat',
		'Bandar Labuan',
	],
	Sabaragamuwa: ['Ratnapura', 'Kegalle'],
	Sabhā: ['Sabhā', 'Al Jadīd'],
	Sabirabad: ['Sabirabad'],
	Sacatepéquez: [
		'Sumpango',
		'Santiago Sacatepéquez',
		'Santa María de Jesús',
		'San Lucas Sacatepéquez',
		'Jocotenango',
		'Ciudad Vieja',
		'Antigua Guatemala',
		'Alotenango',
	],
	Saga: ['Ureshinomachi-shimojuku', 'Tosu', 'Takeo', 'Saga', 'Kashima', 'Karatsu', 'Kanzakimachi-kanzaki', 'Imarichō-kō', 'Arita'],
	Sagain: ['Shwebo', 'Sagaing', 'Monywa', 'Mawlaik'],
	Sahel: ['Dori', 'Djibo'],
	'Sai Kung': ['Tseung Kwan O', 'Sai Kung'],
	Saida: ['Saïda'],
	'Saint Anthony': ['Plymouth'],
	'Saint Catherine': ['Spanish Town', 'Portmore', 'Old Harbour', 'Linstead'],
	'Saint Croix Island': ['Saint Croix'],
	'Saint Gallen': ['Wil', 'Sankt Gallen', 'Rapperswil', 'Jona', 'Gossau'],
	'Saint George': ['Roseau', "Saint George's", 'Kingstown', 'Kingstown Park'],
	'Saint George Basseterre': ['Basseterre'],
	'Saint Helena': ['Jamestown'],
	'Saint John': ['Saint John’s'],
	'Saint Michael': ['Bridgetown'],
	'Saint Paul’s Bay': ['San Pawl il-Baħar'],
	'Saint Peter': ['Brades'],
	'Saint Thomas Island': ['Charlotte Amalie'],
	'Saint-Louis': ['Saint-Louis', 'Richard-Toll'],
	'Saint-Pierre': ['Saint-Pierre'],
	Saipan: ['Saipan'],
	Saitama: [
		'Yoshikawa',
		'Yorii',
		'Yono',
		'Yashio',
		'Tokorozawa',
		'Sugito',
		'Sōka',
		'Shōbu',
		'Shiraoka',
		'Shiki',
		'Satte',
		'Sakado',
		'Okegawa',
		'Ōi',
		'Ogawa',
		'Morohongō',
		'Menuma',
		'Kurihashi',
		'Kumagaya',
		'Kukichūō',
		'Koshigaya',
		'Kōnosu',
		'Kodamachō-kodamaminami',
		'Kisai',
		'Kazo',
		'Kawaguchi',
		'Kawagoe',
		'Kasukabe',
		'Iwatsuki',
		'Honjō',
		'Hatogaya-honchō',
		'Hanyū',
		'Hannō',
		'Gyōda',
		'Fukiage-fujimi',
		'Fukayachō',
		'Chichibu',
		'Ageoshimo',
		'Hasuda',
		'Kamifukuoka',
		'Sayama',
		'Asaka',
		'Wako',
		'Shimotoda',
		'Hidaka',
		'Iruma',
		'Saitama',
		'Matsubushi',
	],
	Sakarya: ['Sapanca', 'Kocaali', 'Karasu', 'Karasu Mahallesi', 'Hendek', 'Geyve', 'Ferizli', 'Akyazı', 'Adapazarı'],
	Sakha: ['Yakutsk', 'Udachny', 'Neryungri', 'Mirny', 'Lensk', 'Aykhal', 'Aldan'],
	'Sakhalin Oblast': ['Yuzhno-Sakhalinsk', 'Poronaysk', 'Okha', 'Nevel’sk', 'Korsakov', 'Kholmsk'],
	'Sakon Nakhon': ['Sawang Daen Din', 'Sakon Nakhon'],
	Sal: ['Santa Maria'],
	'Salah ad Din': ['Ţūz Khūrmātū', 'Tikrīt', 'Sāmarrā’', 'Bayjī', 'Balad', 'Ad Dujayl'],
	Salamat: ['Am-Timan'],
	'Salaspils Municipality': ['Salaspils'],
	Salta: ['Tartagal', 'San Ramón de la Nueva Orán', 'Salta', 'Joaquín V. González', 'General Mosconi', 'Embarcación'],
	'Salto Department': ['Salto'],
	Salyan: ['Salyan'],
	Salzburg: ['Salzburg', 'Saalfelden am Steinernen Meer'],
	Samangan: ['Aībak'],
	'Samara Oblast': [
		'Zhigulevsk',
		'Tolyatti',
		'Syzran',
		'Samara',
		'Pokhvistnevo',
		'Otradnyy',
		'Oktyabr’sk',
		'Novokuybyshevsk',
		'Neftegorsk',
		'Kinel’-Cherkassy',
		'Kinel’',
		'Chapayevsk',
		'Bezenchuk',
	],
	Samarqand: ['Urgut Shahri', 'Samarkand', 'Kattaqo’rg’on Shahri', 'Juma Shahri', 'Chelak', 'Bulung’ur Shahri', 'Oqtosh', 'Payshamba Shahri'],
	Samburu: ['Maralal'],
	'Samegrelo and Zemo Svaneti': ['Zugdidi', 'Senak’i', 'P’ot’i'],
	Samsun: ['Vezirköprü', 'Terme', 'Tekkeköy', 'Samsun', 'Havza', 'Çarşamba', 'Bafra'],
	'Samtskhe-Javakheti': ['Akhaltsikhe'],
	'Samut Prakan': ['Ban Khlong Bang Sao Thong', 'Samut Prakan', 'Phra Pradaeng', 'Bang Bo District'],
	'Samut Sakhon': ['Samut Sakhon', 'Krathum Baen', 'Ban Phaeo'],
	'Samut Songkhram': ['Samut Songkhram'],
	'San Andres y Providencia': ['San Andrés'],
	'San Cristóbal': ['Villa Altagracia', 'San Cristóbal', 'Bajos de Haina'],
	'San Fernando': ['San Fernando', 'Mon Repos', 'Marabella'],
	'San José': [
		'Tejar',
		'San Vicente',
		'San Rafael Arriba',
		'San Rafael Abajo',
		'San Rafael',
		'San Pedro',
		'San Miguel',
		'San Juan de Dios',
		'San Juan',
		'San José',
		'San Isidro',
		'San Felipe',
		'Purral',
		'Patarrá',
		'Ipís',
		'Guadalupe',
		'Curridabat',
		'Colima',
		'Calle Blancos',
		'Aserrí',
		'San Vicente de Moravia',
	],
	'San José Department': ['San José de Mayo', 'Delta del Tigre'],
	'San José de Ocoa': ['San José de Ocoa'],
	'San Juan': [
		'Villa Paula de Sarmiento',
		'Santa Lucía',
		'San Juan',
		'San José de Jáchal',
		'Pocito',
		'Chimbas',
		'Caucete',
		'Albardón',
		'San Juan de la Maguana',
		'Las Matas de Farfán',
		'San Juan',
	],
	'San Juan/Laventille': ['Laventille'],
	'San Luis': ['San Luis', 'Villa Mercedes'],
	'San Luis Potosí': [
		'Ciudad Valles',
		'Tamuín',
		'Tamazunchale',
		'Rioverde',
		'Ébano',
		'Ciudad Fernández',
		'Cárdenas',
		'Soledad de Graciano Sánchez',
		'San Luis Potosí',
		'Salinas de Hidalgo',
		'Matehuala',
	],
	'San Marcos': ['San Pedro Sacatepéquez', 'San Marcos', 'Malacatán', 'Comitancillo'],
	'San Marino': ['San Marino'],
	'San Martín': ['Uchiza', 'Tocache', 'Rioja', 'Moyobamba', 'Juanjuí', 'Bellavista', 'La Banda'],
	'San Miguel': ['San Rafael Oriente', 'San Miguel'],
	'San Pedro de Macorís': ['San Pedro de Macorís', 'Quisqueya'],
	'San Salvador': [
		'Soyapango',
		'San Salvador',
		'San Marcos',
		'Mejicanos',
		'Ilopango',
		'Delgado',
		'Cuscatancingo',
		'Ayutuxtepeque',
		'Apopa',
		'Aguilares',
	],
	'San Vicente': ['San Vicente'],
	'Sanaa Governorate': ['Sayyān', 'Saḩar'],
	Sanaag: ['Ceerigaabo'],
	'Sancti Spíritus Province': ['Yaguajay', 'Trinidad', 'Sancti Spíritus', 'La Sierpe', 'Jatibonico', 'Fomento', 'Condado', 'Cabaiguán'],
	Sangha: ['Ouésso'],
	'Sangha-Mbaéré': ['Nola'],
	'Sangre Grande': ['Sangre Grande'],
	Sankuru: ['Lusambo', 'Lodja'],
	'Santa Ana': ['Santa Ana', 'Metapán', 'Chalchuapa'],
	'Santa Bárbara Department': ['Santa Bárbara'],
	'Santa Catarina': [
		'Xanxerê',
		'Videira',
		'Tubarão',
		'Timbó',
		'Tijucas',
		'Schroeder',
		'São José',
		'São Joaquim',
		'São Francisco do Sul',
		'São Bento do Sul',
		'Santo Amaro da Imperatriz',
		'Santa Cecília',
		'Rio Negrinho',
		'Rio do Sul',
		'Ribeirão da Ilha',
		'Porto União',
		'Pomerode',
		'Penha',
		'Palhoça',
		'Orleans',
		'Navegantes',
		'Mafra',
		'Lages',
		'Laguna',
		'Joinville',
		'Joaçaba',
		'Jaraguá do Sul',
		'Itapema',
		'Itajaí',
		'Indaial',
		'Imbituba',
		'Içara',
		'Ibirama',
		'Guaramirim',
		'Gaspar',
		'Forquilhinha',
		'Florianópolis',
		'Curitibanos',
		'Criciúma',
		'Concórdia',
		'Chapecó',
		'Celso Ramos',
		'Capinzal',
		'Canoinhas',
		'Campos Novos',
		'Caçador',
		'Brusque',
		'Braço do Norte',
		'Blumenau',
		'Biguaçu',
		'Barra Velha',
		'Balneário Camboriú',
		'Araranguá',
		'Campinas',
		'Trindade',
		'Freguesia do Ribeirao da Ilha',
	],
	'Santa Catarina do Fogo': ['Cova Figueira'],
	'Santa Cruz': ['Río Gallegos', 'Caleta Olivia'],
	'Santa Cruz Department': [
		'Warnes',
		'Villa Yapacaní',
		'El Torno',
		'Santa Cruz de la Sierra',
		'San Ignacio de Velasco',
		'Montero',
		'Cotoca',
		'Camiri',
		'Ascención de Guarayos',
	],
	'Santa Elena': ['Santa Elena', 'Salinas'],
	'Santa Fe': [
		'Villa Ocampo',
		'San Javier',
		'Reconquista',
		'Avellaneda',
		'Villa Constitución',
		'Vera',
		'Venado Tuerto',
		'Sunchales',
		'Santo Tomé',
		'Santa Fe',
		'San Justo',
		'San Jorge',
		'Rufino',
		'Rosario',
		'Rafaela',
		'Pérez',
		'Granadero Baigorria',
		'Gobernador Gálvez',
		'Gálvez',
		'Firmat',
		'Esperanza',
		'Coronda',
		'Casilda',
		'Carcarañá',
		'Capitán Bermúdez',
		'Cañada de Gómez',
		'Arroyo Seco',
	],
	'Santa Rosa Department': ['Guazacapán', 'Cuilapa', 'Barberena'],
	Santander: [
		'Vélez',
		'Socorro',
		'San Gil',
		'Sabana de Torres',
		'Puerto Wilches',
		'Piedecuesta',
		'Málaga',
		'Girón',
		'Floridablanca',
		'El Carmen de Chucurí',
		'Cimitarra',
		'Bucaramanga',
		'Barrancabermeja',
		'Barbosa',
	],
	Santarém: ['Torres Novas', 'Tomar', 'Santarém', 'Monsanto', 'Entroncamento'],
	Santiago: ['Villa Bisonó', 'Tamboril', 'Santiago de los Caballeros'],
	'Santiago Metropolitan': [
		'Talagante',
		'Santiago',
		'San Bernardo',
		'Puente Alto',
		'Peñaflor',
		'Paine',
		'Melipilla',
		'Lampa',
		'El Monte',
		'Chicureo',
		'Buin',
		'La Pintana',
		'Lo Prado',
	],
	'Santiago Rodríguez': ['Sabaneta'],
	'Santiago de Cuba': ['Santiago de Cuba', 'San Luis', 'Palma Soriano', 'Contramaestre'],
	'Santiago del Estero': ['Termas de Río Hondo', 'Santiago del Estero', 'Añatuya'],
	'Santo Domingo': ['Boca Chica', 'Santo Domingo Oeste', 'Santo Domingo Este'],
	'Santo Domingo de los Tsáchilas': ['Santo Domingo de los Colorados'],
	'Sar-e Pol Province': ['Sar-e Pul', 'Sang-e Chārak'],
	Saraburi: ['Saraburi', 'Phra Phutthabat', 'Nong Khae', 'Kaeng Khoi', 'Ban Mo'],
	'Saratov Oblast': [
		'Yershov',
		'Vol’sk',
		'Saratov',
		'Rtishchevo',
		'Pugachev',
		'Privolzhskiy',
		'Petrovsk',
		'Novouzensk',
		'Marks',
		'Krasnoarmeysk',
		'Kalininsk',
		'Engels',
		'Balashov',
		'Balakovo',
		'Atkarsk',
	],
	Sarawak: ['Mukah', 'Kuching', 'Simanggang', 'Sarikei', 'Sibu', 'Kapit', 'Bintulu', 'Lawas', 'Limbang', 'Marudi', 'Miri'],
	Sardinia: [
		'Sinnai',
		'Sestu',
		'Selargius',
		"Quartu Sant'Elena",
		'Oristano',
		'Monserrato',
		'Iglesias',
		'Carbonia',
		'Cagliari',
		'Assemini',
		'Sassari',
		'Porto Torres',
		'Olbia',
		'Nuoro',
		'Alghero',
		'Monte Rosello',
	],
	Saskatchewan: ['Moose Jaw', 'North Battleford', 'Prince Albert', 'Regina', 'Saskatoon', 'Swift Current', 'Yorkton'],
	'Sassandra-Marahoué': ['Zuénoula', 'Vavoua', 'Issia', 'Daloa', 'Bouaflé'],
	Satakunta: ['Rauma', 'Pori'],
	'Satu Mare': ['Satu Mare', 'Carei'],
	Satun: ['Satun'],
	Sava: ['Sambava', 'Antalaha', 'Andapa', 'Ampahana'],
	Savanes: ['Tengréla', 'Korhogo', 'Ferkessédougou', 'Boundiali', 'Mango', 'Dapaong'],
	Savannah: ['Salaga'],
	Savannahkhét: ['Savannakhet'],
	Saxony: [
		'Zwickau',
		'Zittau',
		'Wurzen',
		'Werdau',
		'Weißwasser',
		'Torgau',
		'Schwarzenberg',
		'Schneeberg',
		'Schkeuditz',
		'Riesa',
		'Reichenbach/Vogtland',
		'Radebeul',
		'Radeberg',
		'Plauen',
		'Pirna',
		'Oschatz',
		'Mittweida',
		'Meissen',
		'Meerane',
		'Markkleeberg',
		'Marienberg',
		'Loschwitz',
		'Löbtau',
		'Löbau',
		'Limbach-Oberfrohna',
		'Leuben',
		'Leipzig',
		'Klotzsche',
		'Kleinzschocher',
		'Kamenz',
		'Hoyerswerda',
		'Hohenstein-Ernstthal',
		'Heidenau',
		'Großzschocher',
		'Großenhain',
		'Grimma',
		'Görlitz',
		'Gorbitz',
		'Gohlis',
		'Glauchau',
		'Freital',
		'Freiberg',
		'Frankenberg',
		'Eilenburg',
		'Dresden',
		'Döbeln',
		'Delitzsch',
		'Crimmitschau',
		'Coswig',
		'Chemnitz',
		'Borna',
		'Blasewitz',
		'Bautzen',
		'Auerbach',
		'Aue',
		'Annaberg-Buchholz',
		'Haselbachtal',
		'Äußere Neustadt',
	],
	'Saxony-Anhalt': [
		'Zerbst',
		'Zeitz',
		'Wolfen',
		'Wernigerode',
		'Weißenfels',
		'Stendal',
		'Staßfurt',
		'Schönebeck',
		'Sangerhausen',
		'Salzwedel',
		'Quedlinburg',
		'Oschersleben',
		'Neue Neustadt',
		'Naumburg',
		'Merseburg',
		'Magdeburg',
		'Köthen',
		'Hettstedt',
		'Halle (Saale)',
		'Haldensleben I',
		'Halberstadt',
		'Dessau',
		'Burg bei Magdeburg',
		'Blankenburg',
		'Bitterfeld-Wolfen',
		'Bernburg',
		'Aschersleben',
		'Eisleben Lutherstadt',
		'Halle Neustadt',
		'Wittenberg',
	],
	Schaffhausen: ['Schaffhausen'],
	'Schleswig-Holstein': [
		'Wedel',
		'Uetersen',
		'Stockelsdorf',
		'Schleswig',
		'Rendsburg',
		'Reinbek',
		'Ratekau',
		'Quickborn',
		'Preetz',
		'Pinneberg',
		'Norderstedt',
		'Neustadt in Holstein',
		'Neumünster',
		'Mölln',
		'Lübeck',
		'Kiel',
		'Kaltenkirchen',
		'Itzehoe',
		'Husum',
		'Heide',
		'Halstenbek',
		'Glinde',
		'Geesthacht',
		'Flensburg',
		'Eutin',
		'Elmshorn',
		'Eckernförde',
		'Bad Segeberg',
		'Bad Schwartau',
		'Bad Oldesloe',
		'Ahrensburg',
	],
	Scotland: [
		'Wishaw',
		'Stirling',
		'Saint Andrews',
		'Rutherglen',
		'Renfrew',
		'Port Glasgow',
		'Peterhead',
		'Perth',
		'Penicuik',
		'Paisley',
		'Newton Mearns',
		'Musselburgh',
		'Motherwell',
		'Meikle Earnock',
		'Livingston',
		'Larkhall',
		'Kirkintilloch',
		'Kirkcaldy',
		'Kilwinning',
		'Kilmarnock',
		'Johnstone',
		'Irvine',
		'Inverness',
		'High Blantyre',
		'Hamilton',
		'Greenock',
		'Grangemouth',
		'Glenrothes',
		'Glasgow',
		'Falkirk',
		'Elgin',
		'Edinburgh',
		'East Kilbride',
		'Dunfermline',
		'Dundee',
		'Dumfries',
		'Dumbarton',
		'Dalserf',
		'Cumbernauld',
		'Coatbridge',
		'Clydebank',
		'Cambuslang',
		'Broxburn',
		'Bonnyrigg',
		'Bishopbriggs',
		'Bellshill',
		'Bearsden',
		'Bathgate',
		'Barrhead',
		'Ayr',
		'Arbroath',
		'Alloa',
		'Airdrie',
		'Aberdeen',
		'Erskine',
		'Isle of Lewis',
		'Blantyre',
		'Vale of Leven',
		'Viewpark',
	],
	'Sejong-si': ['Sejong'],
	Selangor: [
		'Kuala Selangor',
		'Batang Berjuntai',
		'Batu Arang',
		'Shah Alam',
		'Klang',
		'Pelabuhan Klang',
		'Banting',
		'Jenjarum',
		'Semenyih',
		'Sepang',
		'Serendah',
		'Rawang',
		'Petaling Jaya',
		'Sabak Bernam',
		'Sungai Besar',
		'Tanjung Sepat',
		'Ladang Seri Kundang',
		'Kuang',
		'Kampung Tanjung Karang',
		'Kampung Baru Subang',
		'Kampong Baharu Balakong',
		'Subang Jaya',
		'Putra Heights',
	],
	Selenge: ['Sühbaatar', 'Dzüünharaa'],
	'Selibe Phikwe': ['Selebi-Phikwe'],
	Semnan: ['Īstgāh-e Rāh Āhan-e Garmsār', 'Semnan', 'Shahrud', 'Dāmghān', 'Mahdishahr'],
	Seoul: ['Seoul'],
	Sergipe: [
		'Tobias Barreto',
		'Simão Dias',
		'São Cristóvão',
		'Propriá',
		'Nossa Senhora do Socorro',
		'Nossa Senhora da Glória',
		'Laranjeiras',
		'Lagarto',
		'Itabaianinha',
		'Itabaiana',
		'Estância',
		'Capela',
		'Barra dos Coqueiros',
		'Aracaju',
	],
	Sermersooq: ['Nuuk'],
	Setúbal: [
		'Sesimbra',
		'Setúbal',
		'Pinhal Novo',
		'Piedade',
		'Palmela',
		'Montijo',
		'Moita',
		'Lavradio',
		'Laranjeiro',
		'Corroios',
		'Charneca de Caparica',
		'Caparica',
		'Barreiro',
		'Arrentela',
		'Amora',
		'Almada',
		'Quinta Do Conde',
		'Azeitão',
	],
	'Sevastopol City': ['Sevastopol', 'Balaklava'],
	'Sfax Governorate': ['Sfax', 'Gremda'],
	'Sha Tin': ['Sha Tin', 'Ma On Shan'],
	Shaanxi: [
		'Ziyang Chengguanzhen',
		'Yuxia',
		'Yulinshi',
		'Yanliang',
		'Yangzhou',
		'Yan’an',
		'Xunyang',
		'Xixiang',
		'Ankang',
		'Xianyang',
		'Xi’an',
		'Weinan',
		'Wayaobu',
		'Shiquan',
		'Tongchuanshi',
		'Shangluo',
		'Pingli',
		'Hanyuan',
		'Mianyang',
		'Lüeyang Chengguanzhen',
		'Lintong',
		'Langao Chengguanzhen',
		'Huayin',
		'Hanzhong',
		'Hanyin Chengguanzhen',
		'Hancheng',
		'Guozhen',
		'Chenggu',
		'Tongchuan',
		'Baoji',
		'Yintai',
	],
	Shabran: ['Divichibazar'],
	Shabwah: ['Ataq'],
	'Shaki City': ['Sheki'],
	Shan: ['Taunggyi', 'Tachilek', 'Lashio', 'Kēng Tung'],
	Shandong: [
		'Zoucheng',
		'Zhucheng',
		'Zhoucun',
		'Zhigou',
		'Zhaoyuan',
		'Zibo',
		'Zaozhuang',
		'Yucheng',
		'Yuanshang',
		'Yishui',
		'Yinma',
		'Qingzhou',
		'Yeyuan',
		'Yatou',
		'Yanzhou',
		'Yantai',
		'Yanggu',
		'Xinzhai',
		'Xinxing',
		'Xintai',
		'Xinhe',
		'Xindian',
		'Feicheng',
		'Xiazhuang',
		'Xiazhuang',
		'Xiazhen',
		'Xiangzhou',
		'Xiagezhuang',
		'Wujing',
		'Wenshang',
		'Tianfu',
		'Weihai',
		'Weifang',
		'Yinzhu',
		'Wangfen',
		'Tianzhuang',
		'Tianliu',
		'Chengtangcun',
		'Taozhuang',
		'Taoluo',
		'Taolin',
		'Tangwu',
		'Tai’an',
		'Suozhen',
		'Sitou',
		'Sishui',
		'Laixi',
		'Shouguang',
		'Shizilu',
		'Shiqiaozi',
		'Shidui',
		'Shibuzi',
		'Yanta',
		'Chentuan',
		'Shancheng',
		'Shanting',
		'Shangkou',
		'Sanzhuang',
		'Rizhuang',
		'Renzhao',
		'Qufu',
		'Zhuangyuan',
		'Qingdao',
		'Qiaoguan',
		'Puji',
		'Pingyin',
		'Pingyi',
		'Pingdu',
		'Ningyang',
		'Ninghai',
		'Nanshu',
		'Nanma',
		'Nanhu',
		'Nanding',
		'Nancun',
		'Mingshui',
		'Mingcun',
		'Mengyin',
		'Malianzhuang',
		'Longgang',
		'Liutuan',
		'Linyi',
		'Linqu',
		'Qingnian',
		'Linjiacun',
		'Linghe',
		'Ligezhuang',
		'Licha',
		'Liaolan',
		'Liaocheng',
		'Laizhou',
		'Laiyang',
		'Laiwu',
		'Juye',
		'Jiushan',
		'Jiudian',
		'Jining',
		'Jingzhi',
		'Jinan',
		'Jimo',
		'Jiehu',
		'Jiayue',
		'Jiaozhou',
		'Jiangzhuang',
		'Jiangshan',
		'Huanghua',
		'Houzhen',
		'Honghe',
		'Heze',
		'Hetoudian',
		'Hanting',
		'Guxian',
		'Guanzhuang',
		'Gaomi',
		'Gaoliu',
		'Qingyang',
		'Feicheng',
		'Dongying',
		'Dongdu',
		'Dongcun',
		'Dingtao',
		'Dezhou',
		'Dengzhou',
		'Daotian',
		'Cuijiaji',
		'Chengyang',
		'Changyi',
		'Changqing',
		'Changle',
		'Changcheng',
		'Chaigou',
		'Boshan',
		'Binzhou',
		'Bianzhuang',
		'Beimeng',
		'Baichihe',
		'Anqiu',
		'Dianbu',
		'Yunshan',
		'Zhoucheng',
		'Zhu Cheng City',
		'Damoujia',
		'Rizhao',
		'Shanwang',
		'Liguo',
		'Yingqiu',
		'Liushan',
		'Yishan',
		'Dasheng',
		'Huiqu',
		'Jinzhongzi',
		'Wushan',
		'Zheshan',
		'Buzhuang',
		'Longchi',
		'Xiaying',
		'Baicheng',
		'Jinggou',
		'Kanjia',
		'Huanglou',
		'Yidu',
		'Dongxia',
		'Heguan',
		'Miaozi',
		'Mihe',
		'Shaozhuang',
		'Tanfang',
		'Gucheng',
		'Hualong',
		'Jitai',
		'Taitou',
		'Yingli',
		'Heshan',
		'Xihu',
		'Dazeshan',
		'Dianzi',
		'Yanghe',
	],
	Shanghai: ['Zhujiajiao', 'Zhabei', 'Songjiang', 'Shanghai'],
	Shanxi: [
		'Yuncheng',
		'Yuci',
		'Yuanping',
		'Yangquan',
		'Xinzhou',
		'Xinzhi',
		'Taiyuan',
		'Gutao',
		'Linfen',
		'Jincheng',
		'Jiexiu',
		'Changzhi',
		'Changzhi',
		'Datong',
		'Jinzhong',
	],
	Sharjah: ['Khawr Fakkān', 'Sharjah', 'Adh Dhayd'],
	Sharqia: [
		'Mashtūl as Sūq',
		'Al ‘Āshir min Ramaḑān',
		'Kafr Şaqr',
		'Hihyā',
		'Fāqūs',
		'Diyarb Najm',
		'Bilbays',
		'Zagazig',
		'At Tall al Kabīr',
		'Al Qurayn',
		'Al Qanāyāt',
		'Al Ibrāhīmīyah',
		'Abū Kabīr',
	],
	Shefa: ['Port-Vila'],
	'Shida Kartli': ['Ts’khinvali', 'Khashuri', 'Gori'],
	Shiga: [
		'Youkaichi',
		'Ōtsu',
		'Nagahama',
		'Moriyama',
		'Minakuchichō-matoba',
		'Maibara',
		'Kusatsu',
		'Kitahama',
		'Hino',
		'Hikone',
		'Ōmihachiman',
		'Rittō',
		'Takashima',
		'Kōka',
		'Yasu',
		'Konan',
	],
	Shimane: ['Yasugichō', 'Ōdachō-ōda', 'Matsue', 'Masuda', 'Izumo', 'Hiratachō', 'Hamada', 'Gōtsuchō'],
	Shinyanga: ['Tinde', 'Songwa', 'Shinyanga', 'Old Shinyanga', 'Mwadui', 'Mhango', 'Kishapu', 'Kahama', 'Isaka'],
	Shirak: ['Gyumri'],
	Shirvan: ['Şirvan'],
	Shizuoka: [
		'Yaizu',
		'Shizuoka',
		'Shimoda',
		'Shimada',
		'Sagara',
		'Ōyama',
		'Numazu',
		'Mori',
		'Mishima',
		'Kanaya',
		'Kakegawa',
		'Iwata',
		'Itō',
		'Hamamatsu',
		'Hamakita',
		'Gotenba',
		'Fukuroi',
		'Fujinomiya',
		'Fujieda',
		'Fuji',
		'Atami',
		'Arai',
		'Izunokuni',
		'Izu',
		'Kikugawa',
		'Kosai',
		'Susono',
	],
	'Shkodër County': ['Shkodër'],
	Shtip: ['Shtip'],
	Shumen: ['Shumen'],
	Shusha: ['Şuşa'],
	Shymkent: ['Shymkent'],
	'Si Sa Ket': ['Si Sa Ket', 'Kantharalak'],
	Siauliai: ['Šiauliai', 'Radviliskis'],
	Siaya: ['Siaya'],
	Sibiu: ['Sibiu', 'Mediaş', 'Cisnădie'],
	Sichuan: [
		'Ziyang',
		'Zigong',
		'Gusong',
		'Yunjin',
		'Yibin',
		"Ya'an",
		'Xuyong',
		'Xunsi',
		'Xunchang',
		'Xuantan',
		'Xichang',
		'Wenjiang',
		'Tongchuan',
		'Tianpeng',
		'Suining',
		'Qingfu',
		'Pu’an',
		'Guang’an',
		'Neijiang',
		'Nanxi',
		'Nanlong',
		'Nanchong',
		'Mianyang',
		'Meishan',
		'Luzhou',
		'Luolong',
		'Luojiang',
		'Luocheng',
		'Kangding',
		'Linqiong',
		'Leshan',
		'Kongtan',
		'Junlian',
		'Juexi',
		'Jiang’an',
		'Guangyuan',
		'Jiachuan',
		'Huguo',
		'Donghe',
		'Hejiang',
		'Gulin',
		'Guanyin',
		'Gaoping',
		'Fuji',
		'Deyang',
		'Dazhou',
		'Chonglong',
		'Chengdu',
		'Chaotian',
		'Changning',
		'Langzhong',
		'Baixi',
		'Baihua',
		'Naxi',
		'Jiangyou',
		'Xiasi',
		'Bowangshan',
		'Shuanglong',
		'Panzhihua',
		'Taifu',
		'Mianzhu, Deyang, Sichuan',
		'Pingwu County',
	],
	Sicily: [
		'Vittoria',
		'Villabate',
		'Trapani',
		'Termini Imerese',
		'Siracusa',
		'Scordia',
		'Scicli',
		'Sciacca',
		'San Giovanni la Punta',
		'San Cataldo',
		'Rosolini',
		'Ribera',
		'Ragusa',
		'Pozzallo',
		'Porto Empedocle',
		'Piazza Armerina',
		'Paternò',
		'Partinico',
		'Palma di Montechiaro',
		'Palermo',
		'Palagonia',
		'Pachino',
		'Noto',
		'Niscemi',
		'Monreale',
		'Modica',
		'Misterbianco',
		'Misilmeri',
		'Milazzo',
		'Messina',
		'Mazara del Vallo',
		'Mascalucia',
		'Marsala',
		'Licata',
		'Lentini',
		'Giarre',
		'Gela',
		'Floridia',
		'Favara',
		'Enna',
		'Comiso',
		'Catania',
		'Castelvetrano',
		'Carini',
		'Canicattì',
		'Caltanissetta',
		'Caltagirone',
		'Bronte',
		'Biancavilla',
		'Belpasso',
		'Barcellona Pozzo di Gotto',
		'Bagheria',
		'Avola',
		'Augusta',
		'Alcamo',
		'Agrigento',
		'Adrano',
		'Acireale',
		'Aci Catena',
		'Aci Castello',
		'Gravina di Catania',
		'Casa Santa',
	],
	'Sidi Bel Abbès': ['Sidi Bel Abbès', 'Sfizef', '’Aïn el Berd'],
	'Sidi Bouzid Governorate': ['Sidi Bouzid'],
	'Siem Reap': ['Siem Reap'],
	Siirt: ['Siirt', 'Kurtalan'],
	Sikasso: ['Yorosso', 'Sikasso', 'Koutiala', 'Bougouni'],
	Sikkim: ['Gangtok'],
	Silesia: [
		'Żywiec',
		'Żory',
		'Zawiercie',
		'Zabrze',
		'Wodzisław Śląski',
		'Ustroń',
		'Tychy',
		'Tarnowskie Góry',
		'Świętochłowice',
		'Sosnowiec',
		'Siemianowice Śląskie',
		'Rydułtowy',
		'Rybnik',
		'Ruda Śląska',
		'Radzionków',
		'Radlin',
		'Racibórz',
		'Pyskowice',
		'Pszczyna',
		'Piekary Śląskie',
		'Orzesze',
		'Myszków',
		'Mysłowice',
		'Mikołów',
		'Lubliniec',
		'Lędziny',
		'Łaziska Górne',
		'Knurów',
		'Katowice',
		'Jaworzno',
		'Jastrzębie Zdrój',
		'Gliwice',
		'Dąbrowa Górnicza',
		'Częstochowa',
		'Czerwionka-Leszczyny',
		'Czeladź',
		'Czechowice-Dziedzice',
		'Cieszyn',
		'Chorzów',
		'Bytom',
		'Bieruń',
		'Bielsko-Biala',
		'Będzin',
	],
	'Siliana Governorate': ['Siliana'],
	Silistra: ['Silistra'],
	Simiyu: ['Somanda', 'Nyalikungu', 'Nyakabindi', 'Maswa', 'Malampaka', 'Lalago', 'Kisesa', 'Bariadi'],
	Sinaloa: [
		'Leyva Solano',
		'El Rosario',
		'Navolato',
		'Mazatlán',
		'Los Mochis',
		'La Cruz',
		'Guasave',
		'Guamúchil',
		'Juan José Ríos',
		'Escuinapa',
		'Culiacán',
		'Villa de Costa Rica',
		'Villa Juárez',
		'Licenciado Benito Juárez',
	],
	Sindh: [
		'Chuhar Jamali',
		'Pir Jo Goth',
		'Khairpur Mir’s',
		'Warah',
		'Umarkot',
		'Ubauro',
		'Thul',
		'Thatta',
		'Tharu Shah',
		'Tando Muhammad Khan',
		'Tando Jam',
		'Tando Allahyar',
		'Tando Adam',
		'Talhar',
		'Sukkur',
		'Sita Road',
		'Sinjhoro',
		'Shikarpur',
		'Shahpur Chakar',
		'Shahdadpur',
		'Shahdad Kot',
		'Sehwan',
		'Sanghar',
		'Sakrand',
		'Rohri',
		'Ratodero',
		'Ranipur',
		'Goth Radhan',
		'Kambar',
		'Pano Aqil',
		'Pad Idan',
		'Nawabshah',
		'Naushahro Firoz',
		'Naudero',
		'Nasirabad',
		'Naukot',
		'Moro',
		'Mithi',
		'Mirpur Mathelo',
		'Mirpur Khas',
		'Mehar',
		'Matli',
		'Matiari',
		'Malir Cantonment',
		'Larkana',
		'Kunri',
		'Kotri',
		'Kot Diji',
		'Khanpur Mahar',
		'Khairpur Nathan Shah',
		'Khairpur',
		'Kashmor',
		'Karachi',
		'Kandiaro',
		'Kandhkot',
		'Johi',
		'Jhol',
		'Jacobabad',
		'Hyderabad',
		'Hingorja',
		'Hala',
		'Ghotki',
		'Ghauspur',
		'Gharo',
		'Gambat',
		'Digri',
		'Dhoro Naro',
		'Daur',
		'Dadu',
		'Chhor',
		'Bhit Shah',
		'Bhan',
		'Badin',
		'New Badah',
		'Setharja Old',
	],
	'Sing Buri': ['Sing Buri', 'Bang Racham'],
	Singida: ['Singida', 'Shelui', 'Sepuka', 'Puma', 'Mungaa', 'Mtinko', 'Mgandu', 'Kiomboi', 'Itigi', 'Ilongero', 'Ikungi', 'Igugunu'],
	Sinnār: ['Singa', 'Sinnar', 'Maiurno', 'Kināna', 'As Sūkī', 'Ad Dindar'],
	Sinoe: ['Greenville'],
	Sinop: ['Sinop', 'Boyabat'],
	'Sirdaryo Region': ['Yangiyer', 'Sirdaryo', 'Guliston'],
	'Sistan and Baluchestan': ['Zābol', 'Zahedan', 'Qaşr-e Qand', 'Nīkshahr', 'Khāsh', 'Iranshahr', 'Chabahar'],
	Sivas: ['Sivas', 'Şarkışla', 'Gemerek', 'Suşehri'],
	Skikda: ['Tamalous', 'Skikda', 'Kerkera', 'Azzaba'],
	Skåne: ['Ystad', 'Trelleborg', 'Staffanstorp', 'Malmö', 'Lund', 'Landskrona', 'Kristianstad', 'Helsingborg', 'Hässleholm', 'Eslöv', 'Ängelholm'],
	Sliven: ['Sliven', 'Nova Zagora'],
	'Smolensk Oblast': ['Yartsevo', 'Vyaz’ma', 'Smolensk', 'Safonovo', 'Roslavl’', 'Gagarin', 'Desnogorsk'],
	Smolyan: ['Smolyan'],
	Soccsksargen: [
		'Tupi',
		'Tacurong',
		'Surallah',
		'Lake Sebu',
		'Polomolok',
		'Midsayap',
		'Maluñgun',
		'Malapatan',
		'Laguilayan',
		'Koronadal',
		'Kidapawan',
		'Kabacan',
		'Isulan',
		'Iligan City',
		'Glan',
		'General Santos',
		'Bañga',
		'Alabel',
		'Malingao',
	],
	Sofala: ['Dondo', 'Beira'],
	Sofia: ['Samokov', 'Botevgrad', 'Bealanana', 'Antsohihy'],
	'Sofia-Capital': ['Sofia'],
	Sohag: ['Ţimā', 'Ţahţā', 'Sohag', 'Juhaynah', 'Girga', 'Al Minshāh', 'Al Balyanā', 'Akhmīm'],
	Sokoto: ['Tambuwal', 'Sokoto', 'Illela', 'Gwadabawa'],
	Sololá: ['Sololá', 'Santiago Atitlán', 'Nahualá'],
	Solothurn: ['Olten', 'Grenchen'],
	Somali: [
		'Waal',
		'Fadhigaradle',
		'Jijiga',
		'El Bahay',
		'Raqo',
		'Golwayn',
		'Qorof',
		'Kahandhale',
		'Lasoano',
		'Neefkuceliye',
		'Yamarugley',
		'Digih Habar Es',
	],
	Somogy: ['Siófok', 'Kaposvár'],
	Songkhla: ['Ban Mai', 'Songkhla', 'Sadao', 'Ranot', 'Hat Yai'],
	Songwe: ['Vwawa', 'Tunduma', 'Mlowo'],
	Sonora: [
		'Miguel Alemán (La Doce)',
		'San Luis Río Colorado',
		'Puerto Peñasco',
		'Navojoa',
		'Magdalena de Kino',
		'Huatabampo',
		'Nogales',
		'Heroica Caborca',
		'Hermosillo',
		'Heroica Guaymas',
		'Empalme',
		'Esperanza',
		'Ciudad Obregón',
		'Cananea',
		'Agua Prieta',
	],
	Sonsonate: ['Sonzacate', 'Sonsonate', 'Izalco', 'Acajutla'],
	Sool: ['Laascaanood'],
	Soriano: ['Mercedes', 'Dolores'],
	'Souk Ahras': ['Souk Ahras', 'Sedrata'],
	'Souss-Massa': ['Tiznit', 'Taroudant', 'Sidi Ifni', 'Oulad Teïma', 'Agadir', 'Dakhla'],
	'Sousse Governorate': ['Sousse', 'Msaken', 'Hammam Sousse', 'Akouda', 'Ezzouhour'],
	South: ['Sangmélima', 'Lolodorf', 'Kribi', 'Ébolowa'],
	'South Aegean': ['Kos', 'Ródos'],
	'South Australia': [
		'Morphett Vale',
		'Gawler',
		'Adelaide',
		'Mount Gambier',
		'Adelaide Hills',
		'Paralowie',
		'Parafield Gardens',
		'Adelaide city centre',
	],
	'South Caribbean Coast': ['Rama', 'Nueva Guinea', 'Bluefields'],
	'South Carolina': [
		'Aiken',
		'Anderson',
		'Bluffton',
		'Charleston',
		'Clemson',
		'Columbia',
		'Conway',
		'Easley',
		'Florence',
		'Goose Creek',
		'Greenville',
		'Greenwood',
		'Greer',
		'Hanahan',
		'Hilton Head Island',
		'Hilton Head',
		'Lexington',
		'Mauldin',
		'Mount Pleasant',
		'Myrtle Beach',
		'North Augusta',
		'North Charleston',
		'North Myrtle Beach',
		'Rock Hill',
		'Saint Andrews',
		'Seven Oaks',
		'Simpsonville',
		'Socastee',
		'Spartanburg',
		'Summerville',
		'Sumter',
		'Taylors',
		'Wade Hampton',
		'West Columbia',
	],
	'South Dakota': ['Aberdeen', 'Brookings', 'Mitchell', 'Sioux Falls', 'Watertown', 'Rapid City'],
	'South Denmark': [
		'Vejle',
		'Tønder',
		'Svendborg',
		'Sønderborg',
		'Odense',
		'Nyborg',
		'Middelfart',
		'Kolding',
		'Haderslev',
		'Fredericia',
		'Esbjerg',
		'Aabenraa',
	],
	'South Governorate': ['Tyre', 'Sidon', 'En Nâqoûra', 'Ghazieh'],
	'South Holland': [
		'Zwijndrecht',
		'Zoetermeer',
		'Wassenaar',
		'Waddinxveen',
		'Voorschoten',
		'Voorburg',
		'Vlaardingen',
		'Spijkenisse',
		'Sliedrecht',
		's-Gravenzande',
		'The Hague',
		'Schiedam',
		'Schiebroek',
		'Scheveningen',
		'Sassenheim',
		'Rotterdam',
		'Rijswijk',
		'Ridderkerk',
		'Rhoon',
		'Pijnacker',
		'Papendrecht',
		'Oud-Beijerland',
		'Ommoord',
		'Oegstgeest',
		'Noordwijk-Binnen',
		'Naaldwijk',
		'Middelharnis',
		'Merenwijk',
		'Maassluis',
		'Lisse',
		'Leiderdorp',
		'Leiden',
		'Krimpen aan den IJssel',
		'Katwijk aan Zee',
		'Hoogvliet',
		'Hillegom',
		'Hendrik-Ido-Ambacht',
		'Hellevoetsluis',
		'Groot IJsselmonde',
		'Gouda',
		'Gorinchem',
		'Dordrecht',
		'Delft',
		'Capelle aan den IJssel',
		'Boskoop',
		'Bodegraven',
		'Bergschenhoek',
		'Benthuizen',
		'Barendrecht',
		'Alphen aan den Rijn',
		'Alblasserdam',
		'Berkel en Rodenrijs',
		'Ypenburg',
	],
	'South Kalimantan': ['Tanjung', 'Martapura', 'Barabai', 'Banjarmasin', 'Amuntai'],
	'South Karelia': ['Lappeenranta', 'Imatra'],
	'South Kazakhstan': ['Turar Ryskulov', 'Turkestan', 'Saryaghash', 'Lenger', 'Kentau', 'Shardara', 'Aksu', 'Arys'],
	'South Khorasan Province': ['Qā’en', 'Bīrjand', 'Boshrūyeh'],
	'South Kivu': ['Uvira', 'Kabare', 'Bukavu'],
	'South Moravian': ['Znojmo', 'Vyškov', 'Hodonín', 'Brno', 'Břeclav', 'Blansko'],
	'South Ostrobothnia': ['Seinäjoki'],
	'South Papua': ['Agats', 'Merauke'],
	'South Province': ['Nouméa', 'Mont-Dore', 'Dumbéa'],
	'South Pyongan': ['Sunch’ŏn', 'Sil-li', 'Sinanju', 'P’yŏngsŏng', 'Namp’o', 'Anju'],
	'South Savo': ['Savonlinna', 'Mikkeli'],
	'South Sulawesi': ['Watampone', 'Makassar', 'Sinjai', 'Sengkang', 'Rantepao', 'Parepare', 'Palopo', 'Masamba', 'Maros', 'Galesong'],
	'South Sumatra': ['Tanjungagung', 'Prabumulih', 'Palembang', 'Pagar Alam', 'Lubuklinggau', 'Lahat', 'Baturaja'],
	'South-East': ['Ramotswa', 'Janeng'],
	'South-West': ['Tiko', 'Muyuka', 'Mutengene', 'Mamfe', 'Limbe', 'Kumba', 'Fontem', 'Buea', 'Bamusso'],
	'Southeast Sulawesi': ['Raha', 'Kendari', 'Katobu', 'Baubau'],
	'Southeastern Governorate': ['Sur'],
	Southern: [
		'Pok Fu Lam',
		'Aberdeen',
		'Weligama',
		'Matara',
		'Galle',
		'Devinuwara',
		'Bentota',
		'Ambalangoda',
		'Siavonga',
		'Monze',
		'Mazabuka',
		'Livingstone',
		'Choma',
	],
	'Southern Darfur': ['Nyala', 'Gereida'],
	'Southern District': [
		'Sderot',
		'Qiryat Mal’akhi',
		'Kiryat Gat',
		'Ofaqim',
		'Netivot',
		'Eilat',
		'Dimona',
		'Beersheba',
		'Ashkelon',
		'Ashdod',
		'Arad',
		'Rahat',
		'Tel Sheva‘',
		'‘Ar‘ara BaNegev',
		'H̱ura',
		'Kuseifa',
	],
	'Southern Governorate': ['Madīnat ‘Īsá', 'Dār Kulayb', 'Ar Rifā‘'],
	'Southern Highlands': ['Mendi'],
	'Southern Kordofan': ['Kadugli', 'Dilling', 'Abu Jibeha'],
	'Southern Peninsula': ['Keflavík', 'Reykjanesbær'],
	'Southern Province': ['Nzega', 'Gitarama', 'Butare', 'Bumpe', 'Bo'],
	'Southern Red Sea': ['Assab'],
	'Southern Region': ['Zomba', 'Nsanje', 'Mulanje', 'Mangochi', 'Liwonde', 'Blantyre', 'Balaka'],
	Southland: ['Invercargill'],
	'Southwest Finland': ['Uusikaupunki', 'Turku', 'Salo', 'Raisio', 'Loimaa', 'Lieto', 'Kaarina', 'Väståboland'],
	'Southwest Papua': ['Waisai', 'Teminabuan', 'Sorong'],
	'Split-Dalmatia': ['Split', 'Solin'],
	Srpska: ['Trebinje', 'Prijedor', 'Doboj', 'Bijeljina', 'Banja Luka'],
	'St Helier': ['Saint Helier'],
	'St Peter Port': ['Saint Peter Port'],
	'St. Andrew': ['New Kingston', 'Half Way Tree'],
	'St. James': ['Montego Bay'],
	'St.-Petersburg': [
		'Sosnovka',
		'Uritsk',
		'Staraya Derevnya',
		'Sosnovaya Polyana',
		'Shushary',
		'Sestroretsk',
		'Saint Petersburg',
		'Pushkin',
		'Petrodvorets',
		'Peterhof',
		'Pavlovsk',
		'Ozerki',
		'Novaya Derevnya',
		'Metallostroy',
		'Lomonosov',
		'Komendantsky aerodrom',
		'Kupchino',
		'Kronstadt',
		'Krasnoye Selo',
		'Kolpino',
		'Kolomyagi',
		'Grazhdanka',
		'Gorelovo',
		'Dachnoye',
		'Avtovo',
		'Obukhovo',
		'Petrogradka',
		"Vasyl'evsky Ostrov",
		'Kalininskiy',
		'Krasnogvargeisky',
		'Admiralteisky',
		'Centralniy',
	],
	'Stara Zagora': ['Stara Zagora', 'Kazanlak', 'Chirpan'],
	'Stavropol Kray': [
		'Zheleznovodsk',
		'Zelenokumsk',
		'Yessentukskaya',
		'Yessentuki',
		'Svobody',
		'Svetlograd',
		'Suvorovskaya',
		'Stavropol’',
		'Mikhaylovsk',
		'Pyatigorsk',
		'Novopavlovsk',
		'Novoaleksandrovsk',
		'Nezlobnaya',
		'Nevinnomyssk',
		'Mineralnye Vody',
		'Lermontov',
		'Krasnogvardeyskoye',
		'Kislovodsk',
		'Izobil’nyy',
		'Ipatovo',
		'Inozemtsevo',
		'Goryachevodskiy',
		'Georgiyevsk',
		'Donskoye',
		'Divnoye',
		'Budyonnovsk',
		'Blagodarnyy',
		'Arzgir',
		'Aleksandrovskoye',
		'Neftekumsk',
		'Kochubeyevskoye',
	],
	Stockholm: [
		'Vasastan',
		'Vallentuna',
		'Upplands Väsby',
		'Tumba',
		'Tullinge',
		'Täby',
		'Sundbyberg',
		'Stockholm',
		'Solna',
		'Sollentuna',
		'Södertälje',
		'Södermalm',
		'Råsunda',
		'Östermalm',
		'Norrtälje',
		'Nacka',
		'Märsta',
		'Lidingö',
		'Kungsholmen',
		'Jakobsberg',
		'Huddinge',
		'Haninge',
		'Gustavsberg',
		'Bromma',
		'Boo',
		'Årsta',
		'Åkersberga',
		'Tensta',
	],
	Streymoy: ['Tórshavn'],
	Struga: ['Struga'],
	Strumica: ['Strumica'],
	Strășeni: ['Strășeni'],
	Studenichani: ['Studeničani'],
	'Stung Treng': ['Stung Treng'],
	Styria: ['Wetzelsdorf', 'Straßgang', 'Sankt Peter', 'Graz', 'Eggenberg', 'Jakomini', 'Lend', 'Geidorf', 'Sankt Leonhard', 'Andritz', 'Gries'],
	Subcarpathia: [
		'Tarnobrzeg',
		'Stalowa Wola',
		'Sanok',
		'Rzeszów',
		'Ropczyce',
		'Przeworsk',
		'Przemyśl',
		'Nisko',
		'Mielec',
		'Łańcut',
		'Krosno',
		'Jasło',
		'Jarosław',
		'Dębica',
	],
	Suceava: ['Vatra Dornei', 'Suceava', 'Rădăuți', 'Gura Humorului', 'Fălticeni', 'Câmpulung Moldovenesc'],
	Suchitepeque: ['Santa Bárbara', 'San Pablo Jocopilas', 'San Francisco Zapotitlán', 'Patulul', 'Mazatenango', 'Chicacao'],
	Sucre: [
		'Tolú Viejo',
		'Santiago de Tolú',
		'Sucre',
		'Sincelejo',
		'San Luis de Sincé',
		'San Onofre',
		'San Marcos',
		'San Benito Abad',
		'Sampués',
		'Nueva Granada',
		'Corozal',
		'Güiria',
		'Cumanacoa',
		'Cumaná',
		'Casanay',
		'Carúpano',
		'Cariaco',
		'Araya',
	],
	Sucumbios: ['Nueva Loja'],
	Sud: ['Les Cayes'],
	'Sud-Est': ['Jacmel'],
	'Sud-Ouest': ['Gaoua', 'Diébougou', 'Dano', 'Batié'],
	'Sud-Ubangi': ['Libenge', 'Gemena'],
	'Sudurpashchim Pradesh': ['Ṭikāpur', 'Mahendranagar', 'Dhangaḍhi̇̄', 'Dārchulā', 'Dadeldhurā', 'Dipayal'],
	Suez: ['Suez', 'Ain Sukhna'],
	Sughd: ['Istaravshan', 'Panjakent', 'Taboshar', 'Proletar', 'Khujand', 'Qayroqqum', 'Konibodom', 'Isfara', 'Buston'],
	Sukhothai: ['Sukhothai', 'Si Satchanalai', 'Sawankhalok', 'Ban Na'],
	Sulaymaniyah: ['Ḩalabja', 'Jamjamāl', 'Baynjiwayn', 'As Sulaymānīyah', 'Hajiawa'],
	Sumqayit: ['Sumqayıt', 'Hacı Zeynalabdin'],
	Sumy: ['Lebedyn', 'Trostianets', 'Sumy', 'Shostka', 'Romny', 'Krolevets', 'Konotop', 'Hlukhiv', 'Bilopillia', 'Okhtyrka'],
	Suphanburi: ['Suphan Buri'],
	'Surat Thani': ['Surat Thani', 'Tha Kham', 'Ko Samui', 'Ban Na San'],
	Surin: ['Surin'],
	Surt: ['Sirte'],
	Surxondaryo: ['Tirmiz', 'Sho‘rchi', 'Denov', 'Boysun'],
	Svalbard: ['Longyearbyen'],
	'Svay Rieng': ['Svay Rieng'],
	'Sverdlovsk Oblast': [
		'Severoural’sk',
		'Revda',
		'Pervouralsk',
		'Nizhnyaya Tura',
		'Nizhny Tagil',
		'Kushva',
		'Krasnoufimsk',
		'Kachkanar',
		'Lesnoy',
		'Zarechnyy',
		'Yekaterinburg',
		'Verkhnyaya Salda',
		'Verkhnyaya Pyshma',
		'Turinsk',
		'Tavda',
		'Talitsa',
		'Talitsa',
		'Sysert’',
		'Sukhoy Log',
		'Sredneuralsk',
		'Serov',
		'Rezh',
		'Reftinskiy',
		'Polevskoy',
		'Nizhnyaya Salda',
		'Nev’yansk',
		'Krasnoural’sk',
		'Krasnoturinsk',
		'Kirovgrad',
		'Karpinsk',
		'Kamyshlov',
		'Kamensk-Ural’skiy',
		'Ivdel’',
		'Irbit',
		'Degtyarsk',
		'Bogdanovich',
		'Beryozovsky',
		'Asbest',
		'Artëmovskiy',
		'Aramil',
		'Alapayevsk',
		'Novoural’sk',
		'Lesnoy',
	],
	Sylhet: ['Sylhet', 'Maulavi Bāzār', 'Habiganj', 'Chhātak', 'Baniachang'],
	Syunik: ['Kapan', 'Goris', 'Hats’avan'],
	'Szabolcs-Szatmár-Bereg': ['Nyíregyháza', 'Mátészalka', 'Kisvárda'],
	'Sánchez Ramírez': ['Cotuí'],
	'São Paulo': [
		'Votuporanga',
		'Votorantim',
		'Viradouro',
		'Vinhedo',
		'Várzea Paulista',
		'Vargem Grande do Sul',
		'Valparaíso',
		'Valinhos',
		'Ubatuba',
		'Tupã',
		'Tremembé',
		'Tietê',
		'Teodoro Sampaio',
		'Taubaté',
		'Tatuí',
		'Taquarituba',
		'Taquaritinga',
		'Tanabi',
		'Tambaú',
		'Taboão da Serra',
		'Suzano',
		'Sumaré',
		'Sorocaba',
		'Socorro',
		'Sertãozinho',
		'Serra Negra',
		'Serrana',
		'São Vicente',
		'São Sebastião',
		'São Roque',
		'São Pedro',
		'São Paulo',
		'São Manuel',
		'São José dos Campos',
		'São José do Rio Preto',
		'São José do Rio Pardo',
		'São Joaquim da Barra',
		'São João da Boa Vista',
		'São Carlos',
		'São Caetano do Sul',
		'São Bernardo do Campo',
		'Santos',
		'Santo Antônio de Posse',
		'Santo André',
		'Santo Anastácio',
		'Santa Rosa de Viterbo',
		'Santa Rita do Passa Quatro',
		'Santana de Parnaíba',
		'Santa Isabel',
		'Santa Gertrudes',
		'Santa Fé do Sul',
		'Santa Cruz do Rio Pardo',
		'Santa Cruz das Palmeiras',
		"Santa Bárbara d'Oeste",
		'Salto de Pirapora',
		'Salto',
		'Rio Grande da Serra',
		'Rio das Pedras',
		'Rio Claro',
		'Ribeirão Preto',
		'Ribeirão Pires',
		'Registro',
		'Regente Feijó',
		'Rancharia',
		'Promissão',
		'Presidente Venceslau',
		'Presidente Prudente',
		'Presidente Epitácio',
		'Praia Grande',
		'Porto Ferreira',
		'Porto Feliz',
		'Pontal',
		'Pompéia',
		'Poá',
		'Pitangueiras',
		'Pirapozinho',
		'Pirajuí',
		'Piraju',
		'Pirassununga',
		'Piracicaba',
		'Piracaia',
		'Espírito Santo do Pinhal',
		'Pindamonhangaba',
		'Pilar do Sul',
		'Piedade',
		'Peruíbe',
		'Pereira Barreto',
		'Penápolis',
		'Pedreira',
		'Pederneiras',
		'Paulínia',
		'Paranapanema',
		'Paraguaçu Paulista',
		'Palmital',
		'Ourinhos',
		'Osvaldo Cruz',
		'Osasco',
		'Orlândia',
		'Olímpia',
		'Novo Horizonte',
		'Nova Odessa',
		'Nova Granada',
		'Morro Agudo',
		'Monte Mor',
		'Monte Azul Paulista',
		'Monte Aprazível',
		'Monte Alto',
		'Mongaguá',
		'Mogi Mirim',
		'Mogi Guaçu',
		'Mogi das Cruzes',
		'Mococa',
		'Mirandopólis',
		'Miracatu',
		'Miguelópolis',
		'Mauá',
		'Matão',
		'Martinópolis',
		'Marília',
		'Mairiporã',
		'Mairinque',
		'Macatuba',
		'Louveira',
		'Lorena',
		'Lins',
		'Limeira',
		'Lençóis Paulista',
		'Leme',
		'Laranjal Paulista',
		'Jundiaí',
		'José Bonifácio',
		'Jaú',
		'Jarinu',
		'Jardinópolis',
		'Jandira',
		'Jales',
		'Jaguariúna',
		'Jacareí',
		'Jaboticabal',
		'Ituverava',
		'Itupeva',
		'Itu',
		'Itatinga',
		'Itatiba',
		'Itararé',
		'Itaquaquecetuba',
		'Itápolis',
		'Itapira',
		'Itapevi',
		'Itapeva',
		'Itapetininga',
		'Itapecerica da Serra',
		'Itanhaém',
		'Itaí',
		'Iracemápolis',
		'Iperó',
		'Indaiatuba',
		'Ilha Solteira',
		'Ilhabela',
		'Iguape',
		'Igarapava',
		'Igaraçu do Tietê',
		'Ibiúna',
		'Ibitinga',
		'Ibaté',
		'Hortolândia',
		'Guarulhos',
		'Guarujá',
		'Guariba',
		'Guaratinguetá',
		'Guararema',
		'Guararapes',
		'Guará',
		'Guaíra',
		'Garça',
		'Franco da Rocha',
		'Francisco Morato',
		'Franca',
		'Ferraz de Vasconcelos',
		'Fernandópolis',
		'Embu Guaçu',
		'Embu',
		'Dois Córregos',
		'Diadema',
		'Descalvado',
		'Cubatão',
		'Cruzeiro',
		'Cravinhos',
		'Cotia',
		'Cosmópolis',
		'Cordeirópolis',
		'Conchal',
		'Cerquilho',
		'Catanduva',
		'Casa Branca',
		'Carapicuíba',
		'Caraguatatuba',
		'Capivari',
		'Capâo Bonito',
		'Cândido Mota',
		'Campos do Jordão',
		'Campinas',
		'Cajuru',
		'Cajati',
		'Cajamar',
		'Caieiras',
		'Caçapava',
		'Cabreúva',
		'Buri',
		'Brotas',
		'Brodósqui',
		'Bragança Paulista',
		'Botucatu',
		'Boituva',
		'Biritiba Mirim',
		'Birigui',
		'Bertioga',
		'Bebedouro',
		'Bauru',
		'Batatais',
		'Bastos',
		'Barueri',
		'Barrinha',
		'Barretos',
		'Barra Bonita',
		'Bariri',
		'Avaré',
		'Atibaia',
		'Assis',
		'Arujá',
		'Artur Nogueira',
		'Araras',
		'Araraquara',
		'Araçoiaba da Serra',
		'Araçatuba',
		'Apiaí',
		'Aparecida',
		'Andradina',
		'Amparo',
		'Américo Brasiliense',
		'Americana',
		'Álvares Machado',
		'Agudos',
		'Águas de Lindóia',
		'Aguaí',
		'Adamantina',
		'Jardim Paulista',
	],
	'São Tomé Island': ['São Tomé'],
	'São Vicente': ['Mindelo'],
	Sédhiou: ['Sédhiou'],
	Ségou: ['Ségou', 'San', 'Markala', 'Ké-Macina'],
	Sétif: ['Sétif', 'Salah Bey', 'El Eulma', 'Bougaa', 'Aïn Arnat', 'BABOR - VILLE'],
	Sîngerei: ['Bilicenii Vechi'],
	'Sóc Trăng': ['Vĩnh Châu', 'Sóc Trăng'],
	Södermanland: ['Nyköping', 'Katrineholm', 'Eskilstuna'],
	Sühbaatar: ['Baruun-Urt'],
	Sălaj: ['Zalău', 'Şimleu Silvaniei'],
	'Sơn La': ['Sơn La'],
	"T'bilisi": ['Tbilisi'],
	Tabasco: [
		'Villahermosa',
		'Tenosique',
		'Teapa',
		'Paraiso',
		'Macuspana',
		'Jalpa de Méndez',
		'Huimanguillo',
		'Frontera',
		'Cunduacán',
		'Comalcalco',
		'Cárdenas',
		'Playas del Rosario',
		'Río de Teapa',
	],
	Tabora: ['Urambo', 'Tumbi', 'Tabora', 'Sikonge', 'Nzega', 'Mabama', 'Igunga'],
	'Tabuk Region': ['Umluj', 'Tabuk', 'Duba', 'Al Wajh'],
	Tacna: ['Tacna'],
	'Tacuarembó Department': ['Tacuarembó'],
	Tadjourah: ['Tadjourah'],
	Tafielah: ['Aţ Ţafīlah'],
	Tahoua: ['Birni N Konni', 'Tahoua', 'Madaoua', 'Illéla'],
	'Tai Po': ['Tai Po'],
	Taipei: ['Yingge', 'Shulin', 'Banqiao', 'Sanxia', 'Sanzhi', 'Xizhi'],
	'Taita Taveta': ['Voi'],
	Taiwan: [
		'Douliu',
		'Yongkang',
		'Yujing',
		'Yuanlin',
		'Wufeng',
		'Donggang',
		'Taipei',
		'Tainan',
		'Taichung',
		'Daxi',
		'Puli',
		'Bade',
		'Neihu',
		'Nantou',
		'Magong',
		'Lugu',
		'Yilan',
		'Hualien City',
		'Hsinchu',
		'Hengchun',
		'Keelung',
		'Taoyuan City',
		'Zhongxing New Village',
	],
	Tak: ['Tak', 'Mae Sot', 'Mae Ramat', 'Ban Tak'],
	Takao: ['Kaohsiung'],
	Takeo: ['Takeo', 'Phumĭ Véal Srê'],
	Takhar: ['Taloqan', 'Rustāq', 'Ārt Khwājah'],
	Talas: ['Talas'],
	Tamanrasset: ['Tamanrasset'],
	Tamaulipas: [
		'San Fernando',
		'Valle Hermoso',
		'Tampico',
		'Ciudad Río Bravo',
		'Reynosa',
		'Nuevo Laredo',
		'Miramar',
		'Heroica Matamoros',
		'Ciudad Mante',
		'Ciudad Victoria',
		'Ciudad Miguel Alemán',
		'Ciudad Madero',
		'Altamira',
	],
	Tambacounda: ['Tambacounda'],
	'Tambov Oblast': ['Zherdevka', 'Uvarovo', 'Tambov', 'Rasskazovo', 'Morshansk', 'Michurinsk', 'Kotovsk', 'Kirsanov'],
	'Tamil Nadu': [
		'Keelakarai',
		'Wellington',
		'Walajapet',
		'Vriddhāchalam',
		'Virudunagar',
		'Vadakku Viravanallur',
		'Villupuram',
		'Vettaikkaranpudur',
		'Velur',
		'Vellore',
		'Vedaraniyam',
		'Vattalkundu',
		'Vāsudevanallūr',
		'Vaniyambadi',
		'Vandavāsi',
		'Valparai',
		'Vādippatti',
		'Vadamadurai',
		'Vadakku Valliyūr',
		'Uttiramerūr',
		'Uttamapālaiyam',
		'Usilampatti',
		'Udumalaippettai',
		'Udangudi',
		'Ooty',
		'Turaiyūr',
		'Tondi',
		'Tisaiyanvilai',
		'Tiruvottiyūr',
		'Cheyyar',
		'Tiruvannāmalai',
		'Tiruvallur',
		'Thiruthani',
		'Tiruttangal',
		'Tiruppuvanam',
		'Tiruppur',
		'Tirupparangunram',
		'Tirunelveli',
		'Tirukkoyilur',
		'Tiruchengode',
		'Tiruchirappalli',
		'Tiruchchendur',
		'Tinnanūr',
		'Tindivanam',
		'Thiruvarur',
		'Thanjavur',
		'Thenkasi',
		'Teni',
		'Tharangambadi',
		'Tāramangalam',
		'Tambaram',
		'Sūrandai',
		'Sulur',
		'Srivilliputhur',
		'Srivaikuntam',
		'Srīperumbūdūr',
		'Sivakasi',
		'Sivagiri',
		'Sivagiri',
		'Sivaganga',
		'Sirumugai',
		'Sīrkāzhi',
		'Singānallūr',
		'Sholinghur',
		'Sathyamangalam',
		'Sattur',
		'Salem',
		'Saint Thomas Mount',
		'Rasipuram',
		'Rānipet',
		'Rameswaram',
		'Ramanathapuram',
		'Rajapalaiyam',
		'Punjai Puliyampatti',
		'Puliyangudi',
		'Pudukkottai',
		'Poonamalle',
		'Ponneri',
		'Polūr',
		'Pollachi',
		'Perundurai',
		'Periyanayakkanpalaiyam',
		'Periyakulam',
		'Peravurani',
		'Peranāmpattu',
		'Perambalur',
		'Pennāgaram',
		'Pennādam',
		'Pattukkottai',
		'Paramagudi',
		'Papanasam',
		'Panruti',
		'Palani',
		'Pallippatti',
		'Pallikondai',
		'Pallāvaram',
		'Pallappatti',
		'Palladam',
		'Pālakkodu',
		'Padmanābhapuram',
		'Nilakottai',
		'Neyveli',
		'Nellikkuppam',
		'Nattam',
		'Nāravārikuppam',
		'Nambiyūr',
		'Nāmakkal',
		'Nāmagiripettai',
		'Nāgercoil',
		'Negapatam',
		'Muttupet',
		'Musiri',
		'Mīnjūr',
		'Mettur',
		'Mettupalayam',
		'Melur',
		'Mayiladuthurai',
		'Marakkanam',
		'Mannargudi',
		'Mandapam',
		'Manapparai',
		'Manamadurai',
		'Manali',
		'Mallasamudram',
		'Madurāntakam',
		'Madurai',
		'Madukkūr',
		'Madukkarai',
		'Chennai',
		'Lalgudi',
		'Kuzhithurai',
		'Koothanallur',
		'Kurinjippādi',
		'Kumbakonam',
		'Kulittalai',
		'Krishnagiri',
		'Kovilpatti',
		'Kotagiri',
		'Colachel',
		'Kodaikānāl',
		'Kayalpattinam',
		'Kattivākkam',
		'Kātpādi',
		'Karur',
		'Kāramadai',
		'Kāraikkudi',
		'Kanniyākumāri',
		'Kangayam',
		'Kanchipuram',
		'Cumbum',
		'Kalugumalai',
		'Kallidaikurichi',
		'Kallakkurichchi',
		'Kalakkādu',
		'Kadayanallur',
		'Jalārpet',
		'Jayamkondacholapuram',
		'Irugūr',
		'Idappadi',
		'Hosūr',
		'Harūr',
		'Gummidipundi',
		'Gūduvāncheri',
		'Gudiyatham',
		'Gudalur',
		'Gobichettipalayam',
		'Gingee',
		'Erode',
		'Dindigul',
		'Dharmapuri',
		'Dharapuram',
		'Devakottai',
		'Denkanikota',
		'Cuddalore',
		'Coimbatore',
		'Chinna Salem',
		'Chinnamanūr',
		'Chidambaram',
		'Chettipālaiyam',
		'Chetput',
		'Chennimalai',
		'Chengam',
		'Chengalpattu',
		'Bodināyakkanūr',
		'Kīl Bhuvanagiri',
		'Bhavāni',
		'Ayakudi',
		'Avinashi',
		'Āvadi',
		'Attur',
		'Adirampattinam',
		'Aruppukkottai',
		'Arumuganeri',
		'Ariyalūr',
		'Arcot',
		'Arantāngi',
		'Arni',
		'Arakkonam',
		'Annur',
		'Anthiyur',
		'Āndippatti',
		'Anamalais',
		'Ambur',
		'Ambattūr',
		'Ambasamudram',
		'Ālangulam',
		'Alangāyam',
		'Alandur',
		'Porur',
		'Madipakkam',
		'Perungudi',
		'Madambakkam',
		'Kumarapalayam',
		'Thoothukudi',
		'V.S.K.Valasai (Dindigul-Dist.)',
		'Neelankarai',
		'Injambakkam',
		'Podaturpet',
		'Kallakurichi',
		'Paramathi Velur',
	],
	Tamuning: ['Tamuning-Tumon-Harmon Village', 'Tamuning'],
	Tandjilé: ['Laï', 'Kelo'],
	Tanga: ['Tanga', 'Muheza', 'Mlalo', 'Mazinde', 'Matui', 'Maramba', 'Makuyuni', 'Lushoto', 'Chanika'],
	Tanganyika: ['Kongolo', 'Kalemie', 'Kabalo'],
	'Tanger-Tetouan-Al Hoceima': [
		'Tirhanimîne',
		'Tétouan',
		'Tangier',
		'Ouezzane',
		'Martil',
		'Larache',
		'Ksar El Kebir',
		'Fnidek',
		'Imzouren',
		'Chefchaouen',
		'Asilah',
		'Al Hoceïma',
		'Bni Bouayach',
	],
	Tanintharyi: ['Dawei', 'Myeik', 'Kawthoung'],
	Taraba: ['Wukari', 'Jalingo', 'Ibi', 'Gembu', 'Beli'],
	Taranaki: ['New Plymouth'],
	Tarapacá: ['Pozo Almonte', 'Iquique', 'Cavancha', 'Alto Hospicio', 'Goméz Carreño', 'Playa Brava', 'La Tirana', 'Caupolicán'],
	Targovishte: ['Targovishte', 'Popovo'],
	'Tarija Department': ['Yacuiba', 'Villamontes', 'Tarija', 'Bermejo'],
	Tartu: ['Tartu'],
	Tartus: ['Ţarţūs', 'Şāfītā', 'Bāniyās'],
	'Tas-Sliema': ['Sliema'],
	Tashkent: ['Tashkent', 'Bektemir'],
	Tasman: ['Richmond'],
	Tasmania: ['Launceston', 'Hobart', 'Burnie'],
	Tataouine: ['Tataouine'],
	'Tatarstan Republic': [
		'Zelenodolsk',
		'Zainsk',
		'Yelabuga',
		'Nurlat',
		'Nizhnekamsk',
		'Naberezhnyye Chelny',
		'Menzelinsk',
		'Mendeleyevsk',
		'Leninogorsk',
		'Kukmor',
		'Kazan',
		'Chistopol’',
		'Buinsk',
		'Bugulma',
		'Bavly',
		'Arsk',
		'Al’met’yevsk',
		'Agryz',
		'Vasil’yevo',
	],
	'Tauragė County': ['Taurage'],
	'Ta‘izz': ['Taiz'],
	'Tboung Khmum': ['Suong'],
	Tearce: ['Tearce'],
	Tehran: ['Qarchak', 'Shahre Jadide Andisheh', 'Varāmīn', 'Tehran', 'Robāţ Karīm', 'Pīshvā', 'Malārd', 'Damāvand', 'Shahrīār', 'Eqbālīyeh'],
	Tekirdağ: ['Tekirdağ', 'Malkara', 'Hayrabolu', 'Çorlu', 'Çerkezköy'],
	'Tel Aviv': [
		'Jaffa',
		'Tel Aviv',
		'Ramat HaSharon',
		'Ramat Gan',
		'Or Yehuda',
		'H̱olon',
		'Herzliya',
		"Giv'at Shmuel",
		'Givatayim',
		'Bnei Brak',
		'Bat Yam',
		'Yehud-Monosson',
		'Kiryat Ono',
	],
	Telangana: [
		'Zahirābād',
		'Yellandu',
		'Warangal',
		'Wanparti',
		'Vikārābād',
		'Vemalwāda',
		'Uppal Kalan',
		'Tāndūr',
		'Suriāpet',
		'Srīrāmnagar',
		'Sirsilla',
		'Siddipet',
		'Secunderabad',
		'Sangāreddi',
		'Sadashivpet',
		'Rāmgundam',
		'Peddapalli',
		'Patancheru',
		'Pāloncha',
		'Nizāmābād',
		'Nirmal',
		'Nāspur',
		'Nārāyanpet',
		'Nalgonda',
		'Nāgar Karnūl',
		'Miriālgūda',
		'Medak',
		'Manthani',
		'Mancherāl',
		'Mahbūbnagar',
		'Mahbūbābād',
		'Kūkatpalli',
		'Kottagūdem',
		'Koratla',
		'Kodār',
		'Khammam',
		'Karīmnagar',
		'Kāmāreddi',
		'Jangaon',
		'Jagtiāl',
		'Hyderābād',
		'Gadwāl',
		'Farrukhnagar',
		'Dornakal',
		'Devarkonda',
		'Bodhan',
		'Bhongīr',
		'Bhaisa',
		'Bhadrāchalam',
		'Bānswāda',
		'Asifābād',
		'Andol',
		'Ādilābād',
		'Singāpur',
		'Ghatkesar',
		'Bellampalli',
		'Gaddi Annaram',
		'Dasnapur',
		'Lal Bahadur Nagar',
		'Malkajgiri',
		'Mandamarri',
		'Kyathampalle',
		'Manuguru',
		'Quthbullapur',
		'Palwancha',
		'Sathupalli',
		'Serilingampalle',
		'Ramagundam',
		'Mulugu',
	],
	Teleorman: ['Zimnicea', 'Turnu Măgurele', 'Roșiorii de Vede', 'Alexandria'],
	Telsiai: ['Telsiai', 'Plunge', 'Mazeikiai'],
	Tennessee: [
		'Cordova',
		'Bartlett',
		'Brentwood',
		'Brentwood Estates',
		'Bristol',
		'Chattanooga',
		'Clarksville',
		'Cleveland',
		'Collierville',
		'Columbia',
		'Cookeville',
		'Dickson',
		'Dyersburg',
		'East Brainerd',
		'East Chattanooga',
		'East Ridge',
		'Ellendale',
		'Farragut',
		'Franklin',
		'Gallatin',
		'Germantown',
		'Goodlettsville',
		'Greeneville',
		'Hendersonville',
		'Hermitage',
		'Jackson',
		'Johnson City',
		'Kingsport',
		'Knoxville',
		'La Vergne',
		'Lebanon',
		'Maryville',
		'Memphis',
		'Morristown',
		'Mount Juliet',
		'Murfreesboro',
		'Nashville',
		'New South Memphis',
		'Oak Ridge',
		'Sevierville',
		'Shelbyville',
		'Smyrna',
		'Spring Hill',
		'Springfield',
		'Tullahoma',
	],
	Terengganu: ['Cukai', 'Paka', 'Kertih', 'Kuala Terengganu', 'Marang'],
	Ternopil: ['Ternopil', 'Kremenets', 'Chortkiv', 'Berezhany'],
	Tete: ['Tete'],
	Tetovo: ['Tetovo'],
	Texas: [
		'Abilene',
		'Addison',
		'Alamo',
		'Aldine',
		'Alice',
		'Alief',
		'Allen',
		'Alton',
		'Alvin',
		'Angleton',
		'Arlington',
		'Atascocita',
		'Austin',
		'Balch Springs',
		'Baytown',
		'Beaumont',
		'Bedford',
		'Bellaire',
		'Belton',
		'Benbrook',
		'Brenham',
		'Brownsville',
		'Brownwood',
		'Brushy Creek',
		'Bryan',
		'Burleson',
		'Canyon Lake',
		'Carrollton',
		'Cedar Hill',
		'Cedar Park',
		'Channelview',
		'Cibolo',
		'Cinco Ranch',
		'Cleburne',
		'Cloverleaf',
		'College Station',
		'Colleyville',
		'Conroe',
		'Converse',
		'Coppell',
		'Copperas Cove',
		'Corinth',
		'Corpus Christi',
		'Corsicana',
		'Cypress',
		'Dallas',
		'DeSoto',
		'Deer Park',
		'Denison',
		'Denton',
		'Dickinson',
		'Donna',
		'Duncanville',
		'Edinburg',
		'Ennis',
		'Euless',
		'Farmers Branch',
		'Flower Mound',
		'Forney',
		'Fort Worth',
		'Fresno',
		'Friendswood',
		'Frisco',
		'Gainesville',
		'Galveston',
		'Garland',
		'Gatesville',
		'Georgetown',
		'Grand Prairie',
		'Grapevine',
		'Greenville',
		'Groves',
		'Haltom City',
		'Harker Heights',
		'Harlingen',
		'Highland Village',
		'Houston',
		'Humble',
		'Huntsville',
		'Hurst',
		'Hutto',
		'Irving',
		'Jollyville',
		'Katy',
		'Keller',
		'Kerrville',
		'Killeen',
		'Kingsville',
		'Kyle',
		'La Marque',
		'La Porte',
		'Lake Jackson',
		'Lancaster',
		'Laredo',
		'League City',
		'Leander',
		'Lewisville',
		'Little Elm',
		'Live Oak',
		'Longview',
		'Lufkin',
		'Mansfield',
		'Marshall',
		'McAllen',
		'McKinney',
		'Mercedes',
		'Mesquite',
		'Midlothian',
		'Mission',
		'Mission Bend',
		'Missouri City',
		'Mount Pleasant',
		'Murphy',
		'Nacogdoches',
		'Nederland',
		'New Braunfels',
		'New Territory',
		'North Richland Hills',
		'Orange',
		'Palestine',
		'Paris',
		'Pasadena',
		'Pearland',
		'Pecan Grove',
		'Pflugerville',
		'Pharr',
		'Plano',
		'Port Arthur',
		'Portland',
		'Prosper',
		'Richardson',
		'Rockwall',
		'Rosenberg',
		'Round Rock',
		'Rowlett',
		'Sachse',
		'Saginaw',
		'San Antonio',
		'San Benito',
		'San Juan',
		'San Marcos',
		'Schertz',
		'Seagoville',
		'Seguin',
		'Sherman',
		'South Houston',
		'Southlake',
		'Spring',
		'Stafford',
		'Stephenville',
		'Sugar Land',
		'Sulphur Springs',
		'Taylor',
		'Temple',
		'Terrell',
		'Texarkana',
		'Texas City',
		'The Colony',
		'The Woodlands',
		'Tyler',
		'Universal City',
		'University Park',
		'Uvalde',
		'Victoria',
		'Waco',
		'Watauga',
		'Waxahachie',
		'Weatherford',
		'Weslaco',
		'West University Place',
		'White Settlement',
		'Wichita Falls',
		'Wylie',
		'Bay City',
		'Amarillo',
		'Big Spring',
		'Del Rio',
		'Dumas',
		'Eagle Pass',
		'El Paso',
		'Hereford',
		'Horizon City',
		'Lubbock',
		'Midland',
		'Odessa',
		'Pampa',
		'Plainview',
		'San Angelo',
		'Socorro',
		'Socorro Mission Number 1 Colonia',
		'West Odessa',
		'Fort Hood',
	],
	'Thaba-Tseka': ['Thaba-Tseka'],
	'Thanh Hóa': ['Thanh Hóa', 'Bỉm Sơn'],
	'The Marches': [
		'Tolentino',
		'Senigallia',
		'San Benedetto del Tronto',
		"Porto Sant'Elpidio",
		'Porto San Giorgio',
		'Civitanova Marche',
		'Pesaro',
		'Osimo',
		'Macerata',
		'Jesi',
		'Fermo',
		'Fano',
		'Falconara Marittima',
		'Fabriano',
		'Ascoli Piceno',
		'Ancona',
	],
	'The Valley': ['The Valley'],
	Thessaly: ['Volos', 'Tríkala', 'Lárisa', 'Kardítsa', 'Néa Ionía'],
	'Thimphu District': ['Thimphu'],
	Thiès: ['Thiès Nones', 'Thiès', 'Pout', 'Nguékhokh', 'Mékhé', 'Kayar', 'Joal-Fadiout'],
	Thurgau: ['Kreuzlingen', 'Frauenfeld'],
	Thuringia: [
		'Weimar',
		'Suhl',
		'Sonneberg',
		'Sondershausen',
		'Sömmerda',
		'Schmalkalden',
		'Saalfeld',
		'Rudolstadt',
		'Nordhausen',
		'Mühlhausen',
		'Meiningen',
		'Jena',
		'Ilmenau',
		'Heilbad Heiligenstadt',
		'Greiz',
		'Gotha',
		'Gera',
		'Erfurt',
		'Eisenach',
		'Bad Salzungen',
		'Bad Langensalza',
		'Arnstadt',
		'Apolda',
		'Altenburg',
	],
	'Thái Bình': ['Thái Bình'],
	'Thái Nguyên': ['Thái Nguyên'],
	'Thừa Thiên-Huế': ['Huế'],
	Tianjin: ['Yangliuqing', 'Yangcun', 'Xianshuigu', 'Tianjin', 'Tanggu', 'Hangu'],
	Tiaret: ['Tiaret', 'Sougueur', 'Mehdia daira de meghila', 'Ksar Chellala', 'Frenda', '’Aïn Deheb'],
	Tibet: ['Rikaze', 'Qamdo', 'Nagqu', 'Lhasa', 'Dêqên'],
	Ticino: ['Lugano', 'Bellinzona'],
	'Tierra del Fuego': ['Ushuaia', 'Río Grande'],
	Tigray: ["Mek'ele", 'Maych’ew', 'Korem', 'Inda Silasē', 'Axum', 'Ādīgrat'],
	Tillabéri: ['Tillabéri', 'Téra', 'Ayorou'],
	Timimoun: ['Timimoun'],
	Timiș: ['Timişoara', 'Lugoj'],
	Tindouf: ['Tindouf'],
	Tipaza: ['Tipaza', 'Kolea', 'Hadjout', 'Bou Ismaïl'],
	Tirana: ['Tirana', 'Kavajë'],
	'Tiris Zemmour': ['Zouerate'],
	Tissemsilt: ['Tissemsilt', 'Theniet el Had', 'Lardjem'],
	'Tizi Ouzou': [
		'Tizi Rached',
		'Tizi Ouzou',
		'Tizi-n-Tleta',
		'Tizi Gheniff',
		'Tirmitine',
		'Timizart',
		'Tadmaït',
		'Mekla',
		'Makouda',
		'L’Arbaa Naït Irathen',
		'Freha',
		'Draa el Mizan',
		'Draa Ben Khedda',
		'Boudjima',
		'Boghni',
		'Beni Douala',
		'Azazga',
		'Arhribs',
		'’Aïn el Hammam',
	],
	'Tiền Giang': ['Mỹ Tho', 'Gò Công'],
	Tlaxcala: [
		'Tetla',
		'Santa Ana Chiautempan',
		'Teolocholco',
		'Contla',
		'Calpulalpan',
		'Apizaco',
		'Huamantla',
		'La Magdalena Tlaltelulco',
		'Zacatelco',
		'Tlaxcala',
		'Papalotla',
		'Villa Vicente Guerrero',
	],
	Tlemcen: ['Tlemcen', 'Sidi Abdelli', 'Sebdou', 'Remchi', 'Ouled Mimoun', 'Nedroma', 'Mansoûra', 'Hennaya', 'Chetouane', 'Bensekrane', 'Beni Mester'],
	'Toa Baja': ['Candelaria', 'Levittown'],
	Tobago: ['Scarborough'],
	Tocantins: ['Miracema do Tocantins', 'Araguaína', 'Gurupi', 'Palmas'],
	Tochigi: [
		'Yaita',
		'Utsunomiya',
		'Ujiie',
		'Tochigi',
		'Tanuma',
		'Sano',
		'Oyama',
		'Nikkō',
		'Mibu',
		'Kanuma',
		'Kaminokawa',
		'Imaichi',
		'Fujioka',
		'Ashikaga',
		'Ōtawara',
		'Mooka',
		'Mashiko',
		'Kuroiso',
		'Karasuyama',
		'Nasushiobara',
		'Shimotsuke',
		'Sakura',
	],
	Togdheer: ['Burao'],
	Tokat: ['Zile', 'Turhal', 'Tokat', 'Niksar', 'Erbaa'],
	Tokushima: ['Wakimachi', 'Tokushima', 'Narutochō-mitsuishi', 'Komatsushimachō', 'Kamojimachō-jōgejima', 'Ishii', 'Ikedachō', 'Anan'],
	Tokyo: [
		'Urayasu',
		'Tokyo',
		'Nishi-Tokyo-shi',
		'Tanashichō',
		'Ōme',
		'Musashino',
		'Machida',
		'Kokubunji',
		'Kamirenjaku',
		'Itsukaichi',
		'Hino',
		'Hachiōji',
		'Chōfu',
		'Fussa',
		'Tama',
		'Higashimurayama',
		'Nakano',
		'Hamura',
		'Fuchū',
	],
	Tolima: [
		'Rovira',
		'Rioblanco',
		'Purificación',
		'Planadas',
		'Payandé',
		'Padua',
		'Natagaima',
		'Melgar',
		'San Sebastián de Mariquita',
		'Líbano',
		'Lérida',
		'Ibagué',
		'Honda',
		'Guamo',
		'Fresno',
		'Flandes',
		'Espinal',
		'Chaparral',
		'Anaime',
	],
	Tolna: ['Szekszárd', 'Paks', 'Dombóvár'],
	Tombouctou: ['Timbuktu'],
	'Tomsk Oblast': ['Tomsk', 'Strezhevoy', 'Kolpashevo', 'Asino', 'Seversk'],
	Tongatapu: ['Nuku‘alofa'],
	Toshkent: [
		'Zafar',
		'Yangiyŭl',
		'Yangiobod',
		'Tŭytepa',
		'Salor',
		'Piskent',
		'Parkent',
		'Olmaliq',
		'Ohangaron',
		'Qibray',
		'Iskandar',
		'G‘azalkent',
		'Chirchiq',
		'Chinoz',
		'Bo‘ka',
		'Bekobod',
		'Angren',
	],
	Totonicapán: ['Totonicapán', 'San Francisco El Alto', 'San Andrés Xecul', 'Momostenango'],
	Tottori: ['Yonago', 'Tottori', 'Sakaiminato', 'Kurayoshi'],
	Touggourt: ['Touggourt', 'Tebesbest', 'Taïbet', 'Megarine'],
	Tovuz: ['Tovuz'],
	Toyama: [
		'Yatsuomachi-higashikumisaka',
		'Uozu',
		'Toyama',
		'Takaoka',
		'Nishishinminato',
		'Nyūzen',
		'Namerikawa',
		'Kamiichi',
		'Himimachi',
		'Fukumitsu',
		'Nanto-shi',
		'Nanto',
		'Kurobe-shi',
		'Tonami',
		'Himi',
	],
	'Tozeur Governorate': ['Tozeur', 'Nefta'],
	Trabzon: ['Yomra', 'Vakfıkebir', 'Trabzon', 'Sürmene', 'Of', 'Beşikdüzü', 'Arsin', 'Araklı', 'Akçaabat'],
	Trang: ['Trang', 'Huai Yot'],
	'Trans Nzoia': ['Kitale'],
	Transcarpathia: ['Vynohradiv', 'Uzhgorod', 'Svalyava', 'Rakhiv', 'Mukachevo', 'Khust', 'Berehove'],
	Transnistria: ['Tiraspol', 'Slobozia', 'Rîbniţa', 'Dubăsari'],
	Trarza: ['Tékane', 'Rosso'],
	Trat: ['Trat'],
	Trbovlje: ['Trbovlje'],
	'Treinta y Tres Department': ['Treinta y Tres'],
	'Trentino-Alto Adige': ['Trento', 'Rovereto', 'Riva del Garda', 'Merano', 'Bressanone', 'Bolzano'],
	'Trenčiansky kraj': [
		'Trenčín',
		'Púchov',
		'Prievidza',
		'Považská Bystrica',
		'Partizánske',
		'Nové Mesto nad Váhom',
		'Handlová',
		'Dubnica nad Váhom',
		'Bánovce nad Bebravou',
	],
	Tripoli: ['Tripoli', 'Tājūrā’'],
	Tripura: ['Udaipur', 'Khowai', 'Kailāshahar', 'Dharmanagar', 'Belonia', 'Barjala', 'Agartala'],
	Trnava: ['Trnava', 'Skalica', 'Senica', 'Piešťany', 'Hlohovec', 'Galanta', 'Dunajská Streda'],
	'Troms og Finnmark': ['Alta', 'Tromsø', 'Harstad'],
	Trujillo: ['Valera', 'Trujillo', 'Pampanito', 'Pampán', 'Motatán', 'Escuque', 'Boconó'],
	'Trujillo Alto': ['Trujillo Alto'],
	'Trà Vinh': ['Trà Vinh'],
	Trøndelag: ['Trondheim', 'Steinkjer', 'Levanger'],
	Tshopo: ['Yangambi', 'Kisangani', 'Basoko'],
	Tshuapa: ['Boende'],
	'Tsirang District': ['Tsirang'],
	'Tsuen Wan': ['Tsuen Wan', 'Kwai Chung'],
	Tuamasaga: ['Apia'],
	Tucuman: ['Yerba Buena', 'Tafí Viejo', 'San Miguel de Tucumán', 'Monteros', 'Famaillá', 'Bella Vista', 'Alderetes', 'Aguilares'],
	'Tuen Mun': ['Tuen Mun'],
	'Tukums Municipality': ['Tukums'],
	'Tula Oblast': [
		'Yefremov',
		'Yasnogorsk',
		'Venëv',
		'Uzlovaya',
		'Tula',
		'Suvorov',
		'Shchëkino',
		'Severo-Zadonsk',
		'Plavsk',
		'Novomoskovsk',
		'Kosaya Gora',
		'Kireyevsk',
		'Kimovsk',
		'Donskoy',
		'Bogoroditsk',
		'Belëv',
		'Aleksin',
	],
	Tulcea: ['Tulcea'],
	Tumbes: ['Tumbes', 'Zarumilla'],
	'Tunapuna/Piarco': ['Tunapuna', 'Paradise'],
	Tunceli: ['Tunceli'],
	Tungurahua: ['Pelileo', 'Ambato'],
	'Tunis Governorate': ['Tunis', 'Carthage', 'La Goulette', 'La Mohammedia', 'Al Marsá'],
	Turkana: ['Lodwar'],
	Tuscany: [
		'Viareggio',
		'Siena',
		'Sesto Fiorentino',
		'Scandicci',
		'San Miniato',
		'San Miniato Basso',
		'San Giovanni Valdarno',
		'Rosignano Solvay-Castiglioncello',
		'Prato',
		'Pontedera',
		'Poggibonsi',
		'Pistoia',
		'Pisa',
		'Piombino',
		'Pietrasanta',
		'Montevarchi',
		'Montemurlo',
		'Montecatini-Terme',
		'Massarosa',
		'Massa',
		'Marina di Massa',
		'Marina di Carrara',
		'Lucca',
		'Livorno',
		'Grosseto',
		'Fornacelle',
		'Follonica',
		'Florence',
		'Empoli',
		"Colle di Val d'Elsa",
		'Cecina',
		'Cascina',
		'Carrara',
		'Capannori',
		'Campi Bisenzio',
		'Camaiore',
		'Arezzo',
	],
	Tutong: ['Tutong'],
	'Tuyên Quang': ['Tuyên Quang'],
	'Tver Oblast': [
		'Udomlya',
		'Vyshniy Volochëk',
		'Tver',
		'Torzhok',
		'Rzhev',
		'Ostashkov',
		'Nelidovo',
		'Konakovo',
		'Kimry',
		'Kashin',
		'Bologoye',
		'Bezhetsk',
	],
	Tyrol: ['Wilten', 'Telfs', 'Pradl', 'Innsbruck', 'Hötting'],
	'Tyumen Oblast': ['Zavodoukovsk', 'Yalutorovsk', 'Tyumen', 'Tobolsk', 'Ishim', 'Borovskiy'],
	Táchira: [
		'Ureña',
		'Táriba',
		'Santa Ana',
		'Colón',
		'San Cristóbal',
		'San Antonio del Táchira',
		'Rubio',
		'Palmira',
		'Michelena',
		'Coloncito',
		'San Josecito',
		'La Fría',
		'La Grita',
		'Capacho Nuevo',
	],
	'Tây Ninh Province': ['Tây Ninh', 'Phú Khương'],
	Tébessa: ['Tébessa', 'Hammamet', 'El Aouinet', 'Cheria', 'Bir el Ater'],
	Tǝrtǝr: ['Terter'],
	'Uasin Gishu': ['Eldoret'],
	'Ubon Ratchathani': ['Warin Chamrap', 'Ubon Ratchathani', 'Phibun Mangsahan', 'Det Udom'],
	Ucar: ['Ujar'],
	Ucayali: ['Pucallpa', 'San Fernando'],
	'Udmurtiya Republic': ['Votkinsk', 'Uva', 'Sarapul', 'Mozhga', 'Izhevsk', 'Igra', 'Glazov', 'Balezino'],
	'Udon Thani': ['Ban Nong Wua So', 'Udon Thani', 'Nam Som', 'Kut Chap', 'Ban Phan Don', 'Ban Dung'],
	Ulaanbaatar: ['Ulan Bator'],
	Ulsan: ['Ulsan'],
	Ulster: ['Letterkenny'],
	Ulyanovsk: ['Ulyanovsk', 'Novoul’yanovsk', 'Inza', 'Dimitrovgrad', 'Barysh'],
	'Ulytau Region': ['Zhezqazghan'],
	Umbria: ['Terni', 'Spoleto', 'Perugia', 'Foligno', 'Città di Castello', 'Bastia umbra', 'bishopric of Perugia'],
	Ungheni: ['Ungheni'],
	Unity: ['Leer'],
	'Upper Austria': ['Wels', 'Steyr', 'Linz'],
	'Upper Demerara-Berbice': ['Linden'],
	'Upper East': ['Navrongo', 'Bolgatanga', 'Bawku'],
	'Upper Matsiatra': ['Ikalamavony', 'Fianarantsoa', 'Ambalavao'],
	'Upper Nile': ['Malakal'],
	'Upper West': ['Wa'],
	Uppsala: ['Uppsala', 'Gamla Uppsala', 'Enköping'],
	Usulután: ['Usulután', 'Santiago de María', 'Puerto El Triunfo'],
	Utah: [
		'Cedar City',
		'Hurricane',
		'Saint George',
		'Washington',
		'Bountiful',
		'Brigham City',
		'Centerville',
		'Clearfield',
		'Clinton',
		'Cottonwood Heights',
		'Draper',
		'Eagle Mountain',
		'East Millcreek',
		'Farmington',
		'Herriman',
		'Highland',
		'Holladay',
		'Kaysville',
		'Kearns',
		'Layton',
		'Lehi',
		'Logan',
		'Magna',
		'Midvale',
		'Millcreek',
		'Murray',
		'North Ogden',
		'North Salt Lake',
		'Ogden',
		'Orem',
		'Payson',
		'Pleasant Grove',
		'Provo',
		'Riverton',
		'Roy',
		'Salt Lake City',
		'Sandy',
		'Sandy Hills',
		'Saratoga Springs',
		'South Jordan Heights',
		'South Jordan',
		'South Ogden',
		'South Salt Lake',
		'Spanish Fork',
		'Springville',
		'Syracuse',
		'Taylorsville',
		'Tooele',
		'West Jordan',
		'West Valley City',
		'American Fork',
	],
	Utena: ['Visaginas', 'Utena'],
	'Uthai Thani': ['Thap Than', 'Uthai Thani'],
	Utrecht: [
		'Zeist',
		'Woerden',
		'Wijk bij Duurstede',
		'Vianen',
		'Veenendaal',
		'Utrecht',
		'Soest',
		'Rhenen',
		'Nieuwegein',
		'Mijdrecht',
		'Maarssen',
		'Leusden',
		'Leerdam',
		'IJsselstein',
		'Driebergen-Rijsenburg',
		'De Meern',
		'Baarn',
		'Amersfoort',
	],
	'Uttar Pradesh': [
		'Zamānia',
		'Zaidpur',
		'Wazīrganj',
		'Vrindāvan',
		'Varanasi',
		'Utraula',
		'Usehat',
		'Unnāo',
		'Ūn',
		'Ujhāni',
		'Tūndla',
		'Tulsīpur',
		'Tilhar',
		'Thāna Bhawan',
		'Thakurdwara',
		'Tānda',
		'Tāndā',
		'Tājpur',
		'Suriānwān',
		'Sultānpur',
		'Suār',
		'Soron',
		'Sītāpur',
		'Siswā Bāzār',
		'Sisauli',
		'Sirsi',
		'Sirsāganj',
		'Sikandra Rao',
		'Sikandarpur',
		'Sikandarābād',
		'Sidhaulī',
		'Shīshgarh',
		'Shikohābād',
		'Shikārpūr',
		'Sherkot',
		'Shamsābād',
		'Shamsābād',
		'Shāmli',
		'Shāhpur',
		'Shāhjānpur',
		'Shāhi',
		'Shāhganj',
		'Shāhābād',
		'Shāhābād',
		'Seohāra',
		'Sardhana',
		'Sarauli',
		'Sarāi Mīr',
		'Sarāi Ākil',
		'Sandīla',
		'Sāndi',
		'Samthar',
		'Sambhal',
		'Saidpur',
		'Sahāwar',
		'Sahaswān',
		'Sahaspur',
		'Sahāranpur',
		'Safīpur',
		'Sadābād',
		'Rura',
		'Rūdarpur',
		'Robertsganj',
		'Richha',
		'Reoti',
		'Renukūt',
		'Rāya',
		'Rāth',
		'Rasrā',
		'Rānīpur',
		'Rāmpur',
		'Rāmpur',
		'Rāmnagar',
		'Raebareli',
		'Purwā',
		'Pūranpur',
		'Pukhrāyān',
		'Pipraich',
		'Pināhat',
		'Pilkhua',
		'Pīlibhīt',
		'Pihānī',
		'Phulpur',
		'Phaphūnd',
		'Phalauda',
		'Pawāyan',
		'Parīchhatgarh',
		'Paliā Kalān',
		'Pahāsu',
		'Padrauna',
		'Pachperwa',
		'Orai',
		'Obra',
		'Nūrpur',
		'Nihtaur',
		'Nichlaul',
		'Nawābganj',
		'Nawābganj',
		'Nawābganj',
		'Nautanwa',
		'Naraura',
		'Narauli',
		'Nānpāra',
		'Nanauta',
		'Nakūr',
		'Najībābād',
		'Nagīna',
		'Muzaffarnagar',
		'Murādnagar',
		'Muhammadābād',
		'Muhammadābād',
		'Muhammadābād',
		'Mughal Sarāi',
		'Mubarakpur',
		'Morādābād',
		'Misrikh',
		'Mirzāpur',
		'Mīrganj',
		'Mīrānpur Katra',
		'Mīrānpur',
		'Milak',
		'Mehndāwal',
		'Meerut',
		'Mawāna',
		'Maudaha',
		'Mau Aimma',
		'Mau',
		'Mathura',
		'Mariāhu',
		'Mārahra',
		'Manjhanpur',
		'Maniar',
		'Mandāwar',
		'Malīhābād',
		'Mainpuri',
		'Maholi',
		'Mahobā',
		'Mahmudābād',
		'Mahārāganj',
		'Maghar',
		'Machhlīshahr',
		'Lucknow',
		'Loni',
		'Lāwar Khās',
		'Lar',
		'Lalitpur',
		'Lālganj',
		'Lakhīmpur',
		'Lāharpur',
		'Kundarkhi',
		'Kunda',
		'Kulpahār',
		'Kosi',
		'Kopāganj',
		'Konch',
		'Kithor',
		'Kīratpur',
		'Kiraoli',
		'Khūtār',
		'Khurja',
		'Kheri',
		'Khekra',
		'Khatauli',
		'Khalīlābād',
		'Khairābād',
		'Khair',
		'Khada',
		'Kemrī',
		'Kāsganj',
		'Karhal',
		'Kānth',
		'Kānt',
		'Kanpur',
		'Kannauj',
		'Kāndhla',
		'Kamalganj',
		'Kālpi',
		'Kakrāla',
		'Kākori',
		'Kairāna',
		'Kaimganj',
		'Kachhwa',
		'Kabrāi',
		'Jhūsi',
		'Jhinjhāna',
		'Jhīnjhak',
		'Jhānsi',
		'Jhālu',
		'Jewar',
		'Jaunpur',
		'Jaswantnagar',
		'Jarwal',
		'Jānsath',
		'Jalesar',
		'Jālaun',
		'Jalālpur',
		'Jalālī',
		'Jalālābad',
		'Jalālābād',
		'Jais',
		'Jahāngīrābād',
		'Jagdīshpur',
		'Itimādpur',
		'Islāmnagar',
		'Indergarh',
		'Hāthras',
		'Hastināpur',
		'Hasanpur',
		'Hardoī',
		'Hāpur',
		'Handiā',
		'Hamīrpur',
		'Haldaur',
		'Gyānpur',
		'Gursarāi',
		'Gursahāiganj',
		'Gunnaur',
		'Gulāothi',
		'Govardhan',
		'Gorakhpur',
		'Gondā City',
		'Gola Gokarannāth',
		'Ghosī',
		'Ghazīpur',
		'Ghāziābād',
		'Ghātampur',
		'Garhmuktesar',
		'Gangoh',
		'Gajraula',
		'Fīrozābād',
		'Fatehpur Sīkri',
		'Fatehpur',
		'Fatehpur',
		'Fatehganj West',
		'Fatehābād',
		'Farrukhābād',
		'Farīdpur',
		'Fyzābād',
		'Etāwah',
		'Ganj Dundwāra',
		'Dibai',
		'Dhaurahra',
		'Dhanaura',
		'Dhāmpur',
		'Deoria',
		'Deoraniān',
		'Deoband',
		'Dātāganj',
		'Dāsna',
		'Dādri',
		'Colonelganj',
		'Chunār',
		'Chillupār',
		'Chhibrāmau',
		'Chhāta',
		'Chharra',
		'Chhaprauli',
		'Charthāwal',
		'Charkhāri',
		'Chāndpur',
		'Chanduasi',
		'Chandauli',
		'Bulandshahr',
		'Budhāna',
		'Budaun',
		'Biswān',
		'Bisauli',
		'Bīsalpur',
		'Bindki',
		'Bilthra',
		'Bilsi',
		'Bilsanda',
		'Bilhaur',
		'Bilgrām',
		'Bīlāspur',
		'Bilāri',
		'Bijnor',
		'Bidhūna',
		'Bhongaon',
		'Bhinga',
		'Bharwāri',
		'Bharthana',
		'Bhadohi',
		'Bewar',
		'Bela',
		'Behat',
		'Bastī',
		'Bareilly',
		'Baraut',
		'Bānsi',
		'Bānsdīh',
		'Bāngarmau',
		'Bānda',
		'Banat',
		'Balrāmpur',
		'Bahraigh',
		'Bahjoi',
		'Baheri',
		'Bāh',
		'Bāghpat',
		'Bachhraon',
		'Babrāla',
		'Babīna',
		'Baberu',
		'Azamgarh',
		'Ayodhya',
		'Auraiya',
		'Atraulī',
		'Atarra',
		'Aonla',
		'Anūpshahr',
		'Amroha',
		'Prayagraj',
		'Alīgarh',
		'Alīganj',
		'Akbarpur',
		'Akbarpur',
		'Ahraura',
		'Agra',
		'Afzalgarh',
		'Achhnera',
		'Greater Noida',
		'Noida',
		'Lalganj',
	],
	Uttaradit: ['Uttaradit'],
	Uttarakhand: [
		'Uttarkāshi',
		'Tehri',
		'Tanakpur',
		'Srīnagar',
		'Sitārganj',
		'Roorkee',
		'Rishīkesh',
		'Rānikhet',
		'Rāmnagar',
		'Rāipur',
		'Pithorāgarh',
		'Pauri',
		'Naini Tāl',
		'Mussoorie',
		'Manglaur',
		'Laksar',
		'Kotdwāra',
		'Kichha',
		'Khatīma',
		'Kashipur',
		'Jaspur',
		'Haridwar',
		'Haldwani',
		'Dehra Dūn',
		'Clement Town',
		'Bāzpur',
		'Almora',
	],
	Uusimaa: [
		'Vuosaari',
		'Vihti',
		'Vantaa',
		'Tuusula',
		'Sibbo',
		'Nurmijärvi',
		'Munkkiniemi',
		'Mellunkylä',
		'Mäntsälä',
		'Lovisa',
		'Lohja',
		'Lauttasaari',
		'Kirkkonummi',
		'Klaukkala',
		'Kerava',
		'Kallio',
		'Kaarela',
		'Järvenpää',
		'Hyvinge',
		'Helsinki',
		'Espoo',
		'Porvoo',
	],
	Uva: ['Kataragama', 'Badulla'],
	Uvea: ['Mata-Utu'],
	'Uvs Province': ['Ulaangom'],
	Uíge: ['Uíge'],
	Uşak: ['Uşak', 'Banaz'],
	Vaduz: ['Vaduz'],
	Vakinankaratra: ['Soanindrariny', 'Miandrarivo', 'Faratsiho', 'Betafo', 'Antsirabe', 'Antanifotsy', 'Ambatolampy'],
	Valais: ['Sitten', 'Sierre', 'Monthey'],
	Valencia: [
		'Villena',
		'Vila-real',
		'Villajoyosa',
		'Valencia',
		'Torrevieja',
		'Torrent',
		'Tavernes de la Valldigna',
		'Sueca',
		'Silla',
		'San Vicent del Raspeig',
		'Santa Pola',
		"Sant Joan d'Alacant",
		'Sagunto',
		'Rojales',
		'Ribarroja del Turia',
		'Requena',
		'Puçol',
		'La Pobla de Vallbona',
		'Pilar de la Horadada',
		'Picassent',
		'Paterna',
		'Paiporta',
		'Orihuela',
		'Ontinyent',
		'Onda',
		'Oliva',
		'Novelda',
		'Mutxamel',
		'Moncada',
		'Mislata',
		'Massamagrell',
		'Manises',
		'Llíria',
		'la Nucia',
		"L'Eliana",
		'Javea',
		'Xàtiva',
		'Ibi',
		'Guardamar del Segura',
		'Gandia',
		'El Grao',
		'Elda',
		'Elche',
		'Denia',
		'Cullera',
		'Quart de Poblet',
		'Crevillente',
		'Xirivella',
		'Catarroja',
		'Castelló de la Plana',
		'Carlet',
		'Carcaixent',
		'El Campello',
		'Calp',
		'Callosa de Segura',
		'Burriana',
		'Burjassot',
		'Bétera',
		'Benidorm',
		'Benetússer',
		'Aspe',
		'Altea',
		'Almoradí',
		'Almassora',
		'Alicante',
		'Algemesí',
		'Alfafar',
		'Aldaia',
		'Alcoy',
		'Alzira',
		'Alboraya',
		'Albal',
		'Alaquàs',
		'Grao de Murviedro',
		'Vinaròs',
		'Benicàssim',
		'Benicarló',
		"l'Alfàs del Pi",
	],
	'Valle Department': ['San Lorenzo'],
	'Valle del Cauca': [
		'Zarzal',
		'Yumbo',
		'Tuluá',
		'Sevilla',
		'Roldanillo',
		'Pradera',
		'Palmira',
		'La Unión',
		'Jamundí',
		'Guacarí',
		'Florida',
		'El Cerrito',
		'Darien',
		'Cartago',
		'Candelaria',
		'Cali',
		'Caicedonia',
		'Guadalajara de Buga',
		'Buenaventura',
		'Buenaventura',
		'Andalucía',
	],
	Valletta: ['Valletta'],
	'Vallée du Bandama': ['Sakassou', 'Katiola', 'Bouaké', 'Béoumi'],
	Valmiera: ['Valmiera'],
	Valparaíso: [
		'Viña del Mar',
		'Villa Alemana',
		'Valparaíso',
		'San Felipe',
		'San Antonio',
		'Quilpué',
		'Quillota',
		'Los Andes',
		'Llaillay',
		'Limache',
		'La Ligua',
		'Hacienda La Calera',
		'Cartagena',
	],
	Valverde: ['Mao', 'Esperanza'],
	Van: ['Van', 'Erciş'],
	Vargas: ['Maiquetía', 'La Guaira', 'Catia La Mar', 'Caraballeda'],
	Varna: ['Varna', 'Asparuhovo'],
	Vas: ['Szombathely', 'Sárvár'],
	Vaslui: ['Vaslui', 'Huşi', 'Bârlad'],
	'Vatovavy Region': ['Sahavato', 'Nosy Varika', 'Mananjary', 'Ifanadiana'],
	Vaud: ['Yverdon-les-Bains', 'Vevey', 'Renens', 'Pully', 'Nyon', 'Montreux', 'Lausanne'],
	Vaupés: ['Mitú'],
	'Vega Baja': ['Vega Baja'],
	Velenje: ['Velenje'],
	Veles: ['Veles'],
	'Veliko Tarnovo': ['Veliko Tŭrnovo', 'Svishtov', 'Gorna Oryahovitsa'],
	Veneto: [
		'Vittorio Veneto',
		'Lancenigo-Villorba',
		'Villafranca di Verona',
		'Vicenza',
		'Verona',
		'Venice',
		'Valdagno',
		'Treviso',
		'Thiene',
		'Schio',
		'San Giovanni Lupatoto',
		'San Donà di Piave',
		'San Bonifacio',
		'Rovigo',
		'Portogruaro',
		'Padova',
		'Oderzo',
		'Montecchio Maggiore-Alte Ceccato',
		'Montebelluna',
		'Monselice',
		'Mogliano Veneto',
		'Mirano',
		'Mira Taglio',
		'Mestre',
		'Lido',
		'Legnago',
		'Este',
		'Conegliano',
		'Chioggia',
		'Castelfranco Veneto',
		'Bussolengo',
		'Belluno',
		'Bassano del Grappa',
		'Arzignano',
		'Albignasego',
		'Abano Terme',
		'Spinea-Orgnano',
		'Arcella',
	],
	Ventspils: ['Ventspils'],
	Veracruz: [
		'Xico',
		'Veracruz',
		'Túxpam de Rodríguez Cano',
		'Tlapacoyan',
		'Tantoyuca',
		'Santiago Tuxtla',
		'San Andrés Tuxtla',
		'Río Blanco',
		'Poza Rica de Hidalgo',
		'Perote',
		'Papantla de Olarte',
		'Pánuco',
		'Orizaba',
		'Nogales',
		'Naranjos',
		'Nanchital de Lázaro Cárdenas del Río',
		'Misantla',
		'Minatitlán',
		'Martínez de la Torre',
		'Lerdo de Tejada',
		'Las Choapas',
		'La Isla',
		'José Cardel',
		'Jáltipan de Morelos',
		'Xalapa de Enríquez',
		'Ixtaczoquitlán',
		'Villa Independencia',
		'Huatusco',
		'Fortín de las Flores',
		'Cosoleacaque',
		'Coscomatepec de Bravo',
		'Cosamaloapan',
		'Córdoba',
		'Coatzintla',
		'Coatzacoalcos',
		'Coatepec',
		'Ciudad Mendoza',
		'Cerro Azul',
		'Catemaco',
		'Carlos A. Carrillo',
		'Banderilla',
		'Alvarado',
		'Altotonga',
		'Allende',
		'Álamo',
		'Agua Dulce',
		'Acayucan',
		'Valente Diaz',
		'Tierra Blanca',
		'Alto Lucero',
		'Isla',
		'Fraccionamiento Ciudad Olmeca',
		'Tres Valles',
	],
	Veraguas: ['Santiago de Veraguas'],
	Vermont: ['Burlington', 'Colchester', 'Rutland', 'South Burlington'],
	'Vestfold og Telemark': ['Tønsberg', 'Skien', 'Sandefjord', 'Porsgrunn', 'Larvik', 'Horten'],
	Vestland: ['Nesttun', 'Bergen', 'Ytrebygda'],
	Veszprém: ['Veszprém', 'Várpalota', 'Tapolca', 'Pápa', 'Ajka'],
	'Viana do Castelo': ['Viana do Castelo', 'Ponte de Lima', 'Monção', 'Arcos de Valdevez'],
	Vichada: ['Puerto Carreño', 'Cumaribo'],
	Victoria: [
		'Yarraville',
		'Wollert',
		'Wodonga',
		'Wheelers Hill',
		'Werribee',
		'Warrnambool',
		'Warragul',
		'Wantirna South',
		'Wangaratta',
		'Truganina',
		'Traralgon',
		'Thornbury',
		'Thomastown',
		'Templestowe',
		'Tarneit',
		'Sunbury',
		'Springvale',
		'South Yarra',
		'Shepparton',
		'Seaford',
		'Saint Kilda',
		'Saint Albans',
		'Rowville',
		'Ringwood',
		'Richmond',
		'Reservoir',
		'Preston',
		'Port Melbourne',
		'Point Cook',
		'Pascoe Vale',
		'Pakenham',
		'Officer',
		'North Melbourne',
		'Northcote',
		'Noble Park',
		'Narre Warren',
		'Mulgrave',
		'Mount Waverley',
		'Mount Martha',
		'Mount Eliza',
		'Mornington',
		'South Morang',
		'Mooroolbark',
		'Moonee Ponds',
		'Mitcham',
		'Mill Park',
		'Mildura',
		'Mernda',
		'Melbourne',
		'Lilydale',
		'Lara',
		'Langwarrin',
		'Lalor',
		'Keysborough',
		'Kew',
		'Hawthorn South',
		'Hawthorn',
		'Hampton Park',
		'Greenvale',
		'Greensborough',
		'Glen Waverley',
		'Glenroy',
		'Glen Iris',
		'Glenferrie',
		'Geelong',
		'Frankston East',
		'Frankston',
		'Footscray',
		'Essendon',
		'Epping',
		'Eltham',
		'Doreen',
		'Doncaster East',
		'Doncaster',
		'Deer Park',
		'Dandenong',
		'Croydon',
		'Cranbourne',
		'Craigieburn',
		'Corio',
		'Coburg',
		'Clayton',
		'Caulfield North',
		'Carrum Downs',
		'Carnegie',
		'Carlton',
		'Camberwell',
		'Brunswick',
		'Brighton',
		'Boronia',
		'Berwick',
		'Bentleigh East',
		'Bentleigh',
		'Bendigo',
		'Ballarat',
		'Bairnsdale',
		'Ascot Vale',
		'Cheltenham',
		'Caroline Springs',
		'Hoppers Crossing',
		'Ferntree Gully',
		'Southbank',
		'Docklands',
		'Elwood',
		'Balwyn North',
		'Malvern East',
		'Brighton East',
		'St Albans',
		'Endeavour Hills',
		'Taylors Lakes',
		'Roxburgh Park',
		'Wyndham Vale',
		'Highton',
		'Hillside',
		'Bundoora',
		'Keilor East',
		'Cranbourne East',
		'Cranbourne North',
		'Cranbourne West',
		'Narre Warren South',
		'Dandenong North',
		'Frankston South',
		'Sunshine West',
		'Altona Meadows',
		'Taylors Hill',
		'Melbourne City Centre',
	],
	Vidin: ['Vidin'],
	Vienna: ['Vienna', 'Simmering', 'Ottakring', 'Meidling', 'Innere Stadt', 'Hietzing', 'Hernals', 'Floridsdorf', 'Favoriten', 'Donaustadt'],
	Vientiane: ['Vang Vieng'],
	'Vientiane Prefecture': ['Vientiane'],
	Vihiga: ['Mbale'],
	Viken: ['Sarpsborg', 'Moss', 'Lillestrøm', 'Kongsberg', 'Halden', 'Fredrikstad', 'Drammen', 'Asker'],
	'Vila Real': ['Vila Real', 'Chaves'],
	Viljandimaa: ['Viljandi'],
	'Villa Clara Province': [
		'Santo Domingo',
		'Santa Clara',
		'Sagua la Grande',
		'Remedios',
		'Ranchuelo',
		'Placetas',
		'Manicaragua',
		'Encrucijada',
		'Corralillo',
		'Cifuentes',
		'Camajuaní',
		'Caibarién',
	],
	Vilnius: [
		'Vilnius',
		'Ukmerge',
		'Fabijoniškės',
		'Naujoji Vilnia',
		'Antakalnis',
		'Naujininkai',
		'Karoliniškės',
		'Pašilaičiai',
		'Pilaitė',
		'Justiniškės',
		'Šeškinė',
		'Lazdynai',
		'Vilkpėdė',
		'Naujamiestis',
	],
	Vinica: ['Vinica'],
	Vinnytsia: ['Zhmerynka', 'Vinnytsya', 'Tulchyn', 'Mohyliv-Podilskyy', 'Ladyzhyn', 'Khmilnyk', 'Kozyatyn', 'Kalynivka', 'Haysyn', 'Bar'],
	Virginia: [
		'Fort Hunt',
		'Alexandria',
		'Annandale',
		'Arlington',
		'Ashburn',
		'Baileys Crossroads',
		'Blacksburg',
		'Bon Air',
		'Bristol',
		'Buckhall',
		'Burke',
		'Cave Spring',
		'Centreville',
		'Chantilly',
		'Charlottesville',
		'Cherry Hill',
		'Chesapeake',
		'Chester',
		'Christiansburg',
		'Colonial Heights',
		'Culpeper',
		'Dale City',
		'Danville',
		'East Hampton',
		'Fairfax',
		'Franconia',
		'Fredericksburg',
		'Front Royal',
		'Great Falls',
		'Hampton',
		'Harrisonburg',
		'Herndon',
		'Highland Springs',
		'Hopewell',
		'Hybla Valley',
		'Idylwood',
		'Lake Ridge',
		'Laurel',
		'Leesburg',
		'Lincolnia',
		'Linton Hall',
		'Lorton',
		'Lynchburg',
		'Manassas',
		'Manassas Park',
		'McLean',
		'Mechanicsville',
		'Merrifield',
		'Montclair',
		'Newport News',
		'Norfolk',
		'Oakton',
		'Petersburg',
		'Portsmouth',
		'Portsmouth Heights',
		'Radford',
		'Reston',
		'Richmond',
		'Roanoke',
		'Rose Hill',
		'Salem',
		'Short Pump',
		'South Riding',
		'South Suffolk',
		'Springfield',
		'Staunton',
		'Sterling',
		'Sudley',
		'Suffolk',
		'Tuckahoe',
		'Tysons Corner',
		'Vienna',
		'Virginia Beach',
		'Waynesboro',
		'West Lynchburg',
		'West Springfield',
		'Williamsburg',
		'Winchester',
		'Wolf Trap',
		'Woodlawn',
		'West Falls Church',
		'Oak Hill',
		'Meadowbrook',
	],
	Viseu: ['Viseu'],
	Vitebsk: ['Vitebsk', 'Polatsk', 'Pastavy', 'Orsha', 'Navapolatsk', 'Lyepyel’', 'Hlybokaye'],
	'Vladimir Oblast': [
		'Yur’yev-Pol’skiy',
		'Vyazniki',
		'Vladimir',
		'Strunino',
		'Sobinka',
		'Pokrov',
		'Petushki',
		'Murom',
		'Melenki',
		'Lakinsk',
		'Kovrov',
		'Kol’chugino',
		'Kirzhach',
		'Karabanovo',
		'Gus’-Khrustal’nyy',
		'Aleksandrov',
		'Raduzhnyy',
	],
	'Vlorë County': ['Sarandë', 'Vlorë'],
	Vojvodina: [
		'Zrenjanin',
		'Vršac',
		'Stara Pazova',
		'Senta',
		'Pančevo',
		'Nova Pazova',
		'Kikinda',
		'Inđija',
		'Bečej',
		'Vrbas',
		'Subotica',
		'Sremska Mitrovica',
		'Sombor',
		'Ruma',
		'Novi Sad',
		'Bačka Topola',
		'Bačka Palanka',
		'Apatin',
	],
	'Volgograd Oblast': [
		'Zhirnovsk',
		'Yelan’',
		'Volzhsky',
		'Volgograd',
		'Uryupinsk',
		'Surovikino',
		'Pallasovka',
		'Novoanninskiy',
		'Nikolayevsk',
		'Mikhaylovka',
		'Leninsk',
		'Kotovo',
		'Kotel’nikovo',
		'Kamyshin',
		'Kalach-na-Donu',
		'Gorodishche',
		'Frolovo',
		'Dubovka',
	],
	'Vologda Oblast': ['Vologda', 'Velikiy Ustyug', 'Sokol', 'Sheksna', 'Gryazovets', 'Cherepovets'],
	Volta: ['Kpandu', 'Keta', 'Hohoe', 'Ho', 'Anloga', 'Aflao'],
	Volyn: ['Volodymyr-Volynskyi', 'Novovolynsk', 'Lutsk', 'Kovel'],
	Vorarlberg: ['Lustenau', 'Hohenems', 'Feldkirch', 'Dornbirn', 'Bregenz'],
	'Voronezh Oblast': [
		'Voronezh',
		'Semiluki',
		'Rossosh’',
		'Rossosh’',
		'Pridonskoy',
		'Povorino',
		'Pavlovsk',
		'Ostrogozhsk',
		'Novovoronezh',
		'Novaya Usman’',
		'Liski',
		'Kalach',
		'Gribanovskiy',
		'Buturlinovka',
		'Borisoglebsk',
		'Boguchar',
		'Bobrov',
		'Anna',
	],
	Vrancea: ['Focșani', 'Adjud'],
	Vrapchishte: ['Negotino'],
	Vratsa: ['Vratsa'],
	'Vukovar-Sirmium': ['Vukovar', 'Vinkovci'],
	Vysočina: ['Žďár nad Sázavou', 'Třebíč', 'Pelhřimov', 'Jihlava', 'Havlíčkův Brod'],
	Vâlcea: ['Râmnicu Vâlcea', 'Drăgăşani'],
	Värmland: ['Kristinehamn', 'Karlstad'],
	Västerbotten: ['Umeå', 'Skellefteå'],
	Västernorrland: ['Sundsvall', 'Örnsköldsvik', 'Härnösand'],
	Västmanland: ['Västerås', 'Köping'],
	'Västra Götaland': [
		'Västra Frölunda',
		'Vänersborg',
		'Uddevalla',
		'Trollhättan',
		'Skövde',
		'Partille',
		'Mölnlycke',
		'Mölndal',
		'Mariestad',
		'Lidköping',
		'Lerum',
		'Kungälv',
		'Kinna',
		'Göteborg',
		'Falköping',
		'Borås',
		'Björlanda',
		'Alingsås',
		'Majorna',
	],
	'Vĩnh Long': ['Vĩnh Long', 'Bình Minh'],
	'Vĩnh Phúc': ['Vĩnh Yên'],
	Waikato: ['Taupo', 'Hamilton', 'Cambridge'],
	Wajir: ['Wajir'],
	Wakayama: ['Shingū', 'Tanabe', 'Shirahama', 'Minato', 'Kushimoto', 'Kainan', 'Iwade', 'Hashimoto', 'Gobō', 'Wakayama', 'Kinokawa', 'Arida'],
	Wales: [
		'Wrexham',
		'Swansea',
		'Risca',
		'Rhyl',
		'Rhosllanerchrugog',
		'Rhondda',
		'Prestatyn',
		'Porthcawl',
		'Pontypridd',
		'Pontypool',
		'Penarth',
		'Newport',
		'Neath',
		'Merthyr Tydfil',
		'Maesteg',
		'Llanelli',
		'Llandudno',
		'Hawarden',
		'Gorseinon',
		'Gelligaer',
		'Flint',
		'Ebbw Vale',
		'Cwmbran',
		'Colwyn Bay',
		'Coity',
		'Clydach',
		'Chepstow',
		'Carmarthen',
		'Cardiff',
		'Caerphilly',
		'Buckley',
		'Brymbo',
		'Briton Ferry',
		'Bridgend',
		'Barry',
		'Bangor',
		'Ammanford',
		'Aberystwyth',
		'Abergele',
		'Aberdare',
		'Tonypandy',
		'Blackwood',
		'Deeside',
	],
	Wallonia: [
		'Wavre',
		'Waterloo',
		'Walcourt',
		'Visé',
		'Verviers',
		'Tubize',
		'Tournai',
		'Soumagne',
		'Soignies',
		'Seraing',
		'Saint-Nicolas',
		'Saint-Ghislain',
		'Rixensart',
		'Quaregnon',
		'Pont-à-Celles',
		'Péruwelz',
		'Oupeye',
		'Nivelles',
		'Namur',
		'Mouscron',
		'Morlanwelz-Mariemont',
		'Montignies-sur-Sambre',
		'Mons',
		'Marcinelle',
		'Marchienne-au-Pont',
		'Marche-en-Famenne',
		'Manage',
		'Louvain-la-Neuve',
		'Liège',
		'Lessines',
		'La Louvière',
		'Jumet',
		'Jambes',
		'Huy',
		'Heusy',
		'Herve',
		'Herstal',
		'Grivegnée',
		'Gilly',
		'Gembloux',
		'Frameries',
		'Fleurus',
		'Fléron',
		'Flémalle-Haute',
		'Eupen',
		'Dour',
		'Courcelles',
		'Colfontaine',
		'Chaudfontaine',
		'Châtelineau',
		'Châtelet',
		'Chasse Royale',
		'Charleroi',
		'Braine-le-Comte',
		"Braine-l'Alleud",
		'Boussu',
		'Binche',
		'Ath',
		'Arlon',
		'Ans',
		'Andenne',
	],
	Wanica: ['Lelydorp'],
	'Warmia-Masuria': [
		'Szczytno',
		'Pisz',
		'Olsztyn',
		'Olecko',
		'Mrągowo',
		'Lidzbark Warmiński',
		'Kętrzyn',
		'Giżycko',
		'Gierłoż',
		'Ełk',
		'Działdowo',
		'Bartoszyce',
		'Ostróda',
		'Iława',
		'Elbląg',
		'Braniewo',
	],
	Warrap: ['Tonj', 'Kuacjok', 'Gogrial'],
	Washington: [
		'Aberdeen',
		'Anacortes',
		'Arlington',
		'Auburn',
		'Battle Ground',
		'Bellevue',
		'Bellingham',
		'Bonney Lake',
		'Bothell',
		'Bremerton',
		'Burien',
		'Camas',
		'Centralia',
		'Cottage Lake',
		'Covington',
		'Des Moines',
		'Edmonds',
		'Ellensburg',
		'Everett',
		'Fairwood',
		'Federal Way',
		'Five Corners',
		'Frederickson',
		'Graham',
		'Hazel Dell',
		'Issaquah',
		'Kenmore',
		'Kennewick',
		'Kent',
		'Kirkland',
		'Lacey',
		'Lake Stevens',
		'Lakewood',
		'Longview',
		'Lynnwood',
		'Maple Valley',
		'Martha Lake',
		'Marysville',
		'Mercer Island',
		'Mill Creek',
		'Monroe',
		'Moses Lake',
		'Mount Vernon',
		'Mountlake Terrace',
		'Mukilteo',
		'North Creek',
		'Oak Harbor',
		'Olympia',
		'Opportunity',
		'Orchards',
		'Parkland',
		'Pasco',
		'Port Angeles',
		'Pullman',
		'Puyallup',
		'Redmond',
		'Renton',
		'Richland',
		'Salmon Creek',
		'Sammamish',
		'SeaTac',
		'Seattle',
		'Shoreline',
		'Silverdale',
		'South Hill',
		'Spanaway',
		'Spokane',
		'Spokane Valley',
		'Sunnyside',
		'Tacoma',
		'Tukwila',
		'Tumwater',
		'University Place',
		'Vancouver',
		'Walla Walla',
		'Washougal',
		'Wenatchee',
		'West Lake Sammamish',
		'West Lake Stevens',
		'Bainbridge Island',
		'Yakima',
		'City of Sammamish',
		'Silver Firs',
		'Bryn Mawr-Skyway',
		'Inglewood-Finn Hill',
		'East Hill-Meridian',
		'Picnic Point-North Lynnwood',
		'Union Hill-Novelty Hill',
		'Tri-Cities',
		'Bothell West',
		'Eastmont',
		'Mill Creek East',
	],
	'Washington, D.C.': ['Adams Morgan', 'Shaw', 'Washington'],
	Wellington: ['Wellington', 'Wainuiomata', 'Porirua', 'Paraparaumu', 'Lower Hutt', 'Karori', 'Masterton', 'Upper Hutt', 'Te Aro'],
	West: ['Tonga', 'Mbouda', 'Foumbot', 'Foumban', 'Dschang', 'Bangangté', 'Bafoussam', 'Bafang'],
	'West Azerbaijan': [
		'Takāb',
		'Salmās',
		'Shāhīn Dezh',
		'Qarah Ẕīā’ od Dīn',
		'Piranshahr',
		'Oshnavīyeh',
		'Orūmīyeh',
		'Naqadeh',
		'Mīāndoāb',
		'Mahābād',
		'Khowy',
		'Būkān',
	],
	'West Bank': [
		'Yaţţā',
		'Ţūlkarm',
		'Ţūbās',
		'Surif City',
		'Sa‘īr',
		'Ramallah',
		'Qalqīlyah',
		'Qabāţīyah',
		'Nablus',
		'Ma‘ale Adummim',
		'Janīn',
		'Idhnā',
		'Ḩalḩūl',
		'Dūrā',
		'Bethlehem',
		'Bayt Jālā',
		'Battir',
		'Banī Na‘īm',
		'Balāţah',
		'Az̧ Z̧āhirīyah',
		'‘Aţārūt',
		'As Samū‘',
		'Ar Rām wa Ḑāḩiyat al Barīd',
		'Jericho',
		'Al Yāmūn',
		'Hebron',
		'Al Bīrah',
		'Al ‘Ayzarīyah',
		'Old City',
		'East Jerusalem',
	],
	'West Bay': ['West Bay'],
	'West Bengal': [
		'Tufānganj',
		'Titāgarh',
		'Tarakeswar',
		'Tamlūk',
		'Tāki',
		'Rajpur Sonarpur',
		'Sonāmukhi',
		'Siuri',
		'Singur',
		'Shyamnagar',
		'Shrīrāmpur',
		'Siliguri',
		'Shāntipur',
		'Sānkrāil',
		'Sainthia',
		'Rishra',
		'Rānīganj',
		'Rānāghāt',
		'Rampur Hat',
		'Rāmjībanpur',
		'Rāiganj',
		'Raghunathpur',
		'Puruliya',
		'Pānihāti',
		'Pandua',
		'Navadwīp',
		'Nalhāti',
		'Naksalbāri',
		'Naihāti',
		'Murshidābād',
		'Memāri',
		'Medinīpur',
		'Mātābhānga',
		'Maināguri',
		'Mahīshādal',
		'Madhyamgram',
		'Lālgola',
		'Lakhyabad',
		'Kulti',
		'Krishnanagar',
		'Konnagar',
		'Koch Bihār',
		'Khātra',
		'Khardah',
		'Kharagpur',
		'Kenda',
		'Kātoya',
		'Kārsiyāng',
		'Kāndi',
		'Kāmārhāti',
		'Kalyani',
		'Kālna',
		'Kāliyāganj',
		'Kālimpong',
		'Kakdwip',
		'Kānchrāpāra',
		'Jhārgrām',
		'Jhalidā',
		'Jaynagar Majilpur',
		'Jangipur',
		'Jāmuria',
		'Jalpāiguri',
		'Islāmpur',
		'Ingrāj Bāzār',
		'Hugli',
		'Hāsimāra',
		'Hāora',
		'Hālīsahar',
		'Hābra',
		'Guskhara',
		'Goyerkāta',
		'Gosāba',
		'Gobārdānga',
		'Ghātāl',
		'Garui',
		'Gangārāmpur',
		'Fort Gloster',
		'Farakka',
		'Fālākāta',
		'Egra',
		'Durgapur',
		'Dam Dam',
		'Dubrājpur',
		'Dīnhāta',
		'Diamond Harbour',
		'Dhupgāri',
		'Dhuliān',
		'Dārjiling',
		'Dalkola',
		'Chittaranjan',
		'Chandrakona',
		'Chandannagar',
		'Canning',
		'Kolkata',
		'Bolpur',
		'Bishnupur',
		'Bhātpāra',
		'Bhadreswar',
		'Beldānga',
		'Baruipur',
		'Barddhamān',
		'Bārāsat',
		'Baranagar',
		'Bārākpur',
		'Bānsbāria',
		'Bānkura',
		'Bangaon',
		'Bālurghāt',
		'Bāli',
		'Balarāmpur',
		'Budge Budge',
		'Baidyabāti',
		'Baharampur',
		'Bagulā',
		'Bagdogra',
		'Badūria',
		'Āsansol',
		'Arāmbāgh',
		'Āmta',
		'Āmlāgora',
		'Alīpur Duār',
		'Adra',
		'Contai',
		'Haldia',
		'Srirāmpur',
		'Dumjor',
		'Bankra',
		'Chakapara',
		'Mahiari',
		'Dhulagari',
		'Pānchla',
		'Pujali',
		'Maheshtala',
		'Monoharpur',
		'Birpara',
		'Jaigaon',
		'Aistala',
		'Ashoknagar Kalyangarh',
		'Bahula',
	],
	'West Greece': ['Pýrgos', 'Pátra', 'Amaliáda', 'Aígio', 'Agrínio'],
	'West Java': [
		'Weru',
		'Wanaraja',
		'Tasikmalaya',
		'Sumedang Utara',
		'Sumedang',
		'Sumber',
		'Sukabumi',
		'Soreang',
		'Singaparna',
		'Serpong',
		'Sepatan',
		'Sawangan',
		'Rengasdengklok',
		'Rajapolah',
		'Purwakarta',
		'Plumbon',
		'Pelabuhanratu',
		'Paseh',
		'Pasarkemis',
		'Parung',
		'Pamulang',
		'Pameungpeuk',
		'Pamanukan',
		'Palimanan',
		'Margahayukencana',
		'Majalengka',
		'Lembang',
		'Kuningan',
		'Kresek',
		'Klangenan',
		'Kawalu',
		'Karangsembung',
		'Karangampel',
		'Jatiwangi',
		'Jatibarang',
		'Indramayu',
		'Depok',
		'Citeureup',
		'Cirebon',
		'Ciputat',
		'Cimahi',
		'Cileunyi',
		'Cileungsir',
		'Cikarang',
		'Cikampek',
		'Cicurug',
		'Cibinong',
		'Cianjur',
		'Ciamis',
		'Caringin',
		'Ciampea',
		'Bogor',
		'Bekasi',
		'Banjaran',
		'Banjar',
		'Bandung',
		'Astanajapura',
		'Arjawinangun',
		'Padalarang',
		'Ciranjang-hilir',
		'Cikupa',
		'Teluknaga',
	],
	'West Kalimantan': ['Sungai Raya', 'Singkawang', 'Pontianak', 'Pemangkat', 'Manismata'],
	'West Kordofan State': ['An Nuhūd', 'Al Mijlad', 'Abū Zabad'],
	'West Macedonia': ['Ptolemaḯda', 'Kozáni', 'Flórina'],
	'West New Britain': ['Kimbe'],
	'West Nusa Tenggara': ['Taliwang', 'Sumbawa Besar', 'Selong', 'Praya', 'Mataram', 'Dompu', 'Bima'],
	'West Papua': ['Ransiki', 'Manokwari', 'Bintuni'],
	'West Pokot': ['Kapenguria'],
	'West Pomerania': [
		'Wałcz',
		'Szczecinek',
		'Szczecin',
		'Świnoujście',
		'Świdwin',
		'Stargard',
		'Police',
		'Nowogard',
		'Koszalin',
		'Kołobrzeg',
		'Gryfino',
		'Gryfice',
		'Goleniów',
		'Choszczno',
		'Białogard',
	],
	'West Sulawesi': ['Polewali', 'Majene'],
	'West Sumatra': ['Solok', 'Sijunjung', 'Payakumbuh', 'Pariaman', 'Padang', 'Bukittinggi'],
	'West Virginia': [
		'Beckley',
		'Charleston',
		'Clarksburg',
		'Fairmont',
		'Huntington',
		'Martinsburg',
		'Morgantown',
		'Parkersburg',
		'Weirton',
		'Weirton Heights',
		'Wheeling',
	],
	Western: [
		'Sigatoka',
		'Nadi',
		'Lautoka',
		'Tarkwa',
		'Takoradi',
		'Shama Junction',
		'Sekondi-Takoradi',
		'Prestea',
		'Axim',
		'Sukuta',
		'Brikama',
		'Welisara',
		'Wattala',
		'Pita Kotte',
		'Peliyagoda',
		'Panadura',
		'Negombo',
		'Mulleriyawa',
		'Dehiwala-Mount Lavinia',
		'Moratuwa',
		'Maharagama',
		'Sri Jayewardenepura Kotte',
		'Kotikawatta',
		'Kolonnawa',
		'Kelaniya',
		'Katunayaka',
		'Kandana',
		'Kalutara',
		'Ja Ela',
		'Homagama',
		'Hendala',
		'Hanwella Ihala',
		'Colombo',
		'Beruwala',
		'Battaramulla South',
		'Sesheke',
		'Mongu',
	],
	'Western Area': ['Waterloo', 'Freetown', 'Goderich'],
	'Western Australia': [
		'Scarborough',
		'Rockingham',
		'Perth',
		'Mandurah',
		'Kwinana',
		'Gosnells',
		'Geraldton',
		'Byford',
		'Busselton',
		'Bunbury',
		'Australind',
		'Albany',
		'Canning Vale',
		'Ballajura',
		'Willetton',
		'Ellenbrook',
		'Baldivis',
		'Dianella',
		'Duncraig',
		'Morley',
		'Thornlie',
		'Landsdale',
	],
	'Western Bahr al Ghazal': ['Wau', 'Kafia Kingi'],
	'Western Cape': [
		'Riversdale',
		'Plettenberg Bay',
		'Oudtshoorn',
		'Mossel Bay',
		'Montagu',
		'Knysna',
		'George',
		'Beaufort West',
		'Worcester',
		'Wellington',
		'Vredenburg',
		'Stellenbosch',
		'Saldanha',
		'Robertson',
		'Paarl',
		'Malmesbury',
		'Lansdowne',
		'Kraaifontein',
		'Hermanus',
		'Grabouw',
		'Ceres',
		'Cape Town',
		'Atlantis',
		'Rondebosch',
		'Retreat',
	],
	'Western Darfur': ['Geneina'],
	'Western Equatoria': ['Yambio'],
	'Western Highlands': ['Mount Hagen'],
	'Western North': ['Bibiani'],
	'Western Province': ['Daru', 'Kibuye', 'Cyangugu', 'Gisenyi'],
	'Western Region': [
		'Nyachera',
		'Ntungamo',
		'Ntungamo',
		'Mbarara',
		'Masindi',
		'Kyenjojo',
		'Kasese',
		'Kamwenge',
		'Kabale',
		'Ibanda',
		'Hoima',
		'Fort Portal',
		'Bwizibwera',
		'Bundibugyo',
	],
	'Western Visayas': [
		'Victorias',
		'Ualog',
		'Tinongan',
		'Taloc',
		'Talisay',
		'Sumag',
		'Suay',
		'Sipalay',
		'Sagay',
		'Roxas City',
		'Pulupandan',
		'Passi',
		'Paraiso',
		'Pacol',
		'Murcia',
		'Mansilingan',
		'Manapla',
		'Maao',
		'La Castellana',
		'La Carlota',
		'Kalibo (poblacion)',
		'Kabankalan',
		'Isabela',
		'Iloilo',
		'Hinigaran',
		'Himamaylan',
		'Escalante',
		'Saravia',
		'Cadiz',
		'Binonga',
		'Binalbagan',
		'Bago City',
		'Bacolod City',
		'Asia',
		'Pandan',
	],
	Westmoreland: ['Savanna-la-Mar'],
	'White Nile': ['Tandaltī', 'Rabak', 'Kosti', 'Al Qiţena', 'Ad Douiem'],
	Wisconsin: [
		'Appleton',
		'Ashwaubenon',
		'Beaver Dam',
		'Bellevue',
		'Beloit',
		'Brookfield',
		'Caledonia',
		'Cudahy',
		'De Pere',
		'Eau Claire',
		'Fitchburg',
		'Fond du Lac',
		'Franklin',
		'Germantown',
		'Green Bay',
		'Greenfield',
		'Howard',
		'Janesville',
		'Kaukauna',
		'Kenosha',
		'La Crosse',
		'Madison',
		'Manitowoc',
		'Marshfield',
		'Menasha',
		'Menomonee Falls',
		'Menomonie',
		'Mequon',
		'Middleton',
		'Milwaukee',
		'Muskego',
		'Neenah',
		'New Berlin',
		'North La Crosse',
		'Oak Creek',
		'Oconomowoc',
		'Onalaska',
		'Oshkosh',
		'Pleasant Prairie',
		'Racine',
		'River Falls',
		'Sheboygan',
		'South Milwaukee',
		'Stevens Point',
		'Sun Prairie',
		'Superior',
		'Watertown',
		'Waukesha',
		'Wausau',
		'Wauwatosa',
		'West Allis',
		'West Bend',
		'Weston',
		'Wisconsin Rapids',
		'Mount Pleasant',
	],
	'Woleu-Ntem': ['Oyem'],
	'Woqooyi Galbeed': ['Hargeysa', 'Berbera'],
	Woroba: ['Touba', 'Mankono', 'Séguéla'],
	Wyoming: ['Casper', 'Cheyenne', 'Gillette', 'Laramie', 'Rock Springs', 'Sheridan'],
	'Wādī al Ḩayāt': ['Ubari'],
	Wāsiţ: ['Aş Şuwayrah', 'An Nu‘mānīyah', 'Al Kūt', 'Al Ḩayy', 'Al ‘Azīzīyah'],
	Xankǝndi: ['Xankandi'],
	Xaçmaz: ['Xaçmaz'],
	Xiangkhoang: ['Muang Phônsavan'],
	Xinjiang: [
		'Zepu',
		'Shache',
		'Qiemo',
		'Qarek',
		'Oytograk',
		'Qaraqash',
		'Langru',
		'Kokyar',
		'Kashgar',
		'Kaqun',
		'Hotan',
		'Elixku',
		'Langgar',
		'Layka',
		'Saybag',
		'Arix',
		'Xambabazar',
		'Xinyuan',
		'Wusu',
		'Ürümqi',
		'Turpan',
		'Tacheng',
		'Shihezi',
		'Sandaohezi',
		'Qapqal',
		'Kuqa',
		'Korla',
		'Karamay',
		'Jelilyüzi',
		'Yili',
		'Hoxtolgay',
		'Hami',
		'Fuyun',
		'Emin',
		'Dushanzi',
		'Dure',
		'Changji',
		'Burqin',
		'Baijiantan',
		'Aral',
		'Altay',
		'Aksu',
		'Oymak',
		'Kax',
		'Baytokay',
		'Yengitam',
		'Dadamtu',
		'Yuqunweng',
		'Panjim',
		'Bole',
		'Mugala',
		'Bageqi',
		'Tawakule',
		'Han’airike',
		'Ying’awati',
		'Taxkowrük',
		'Onyar',
		'Huocheng',
		'Huoshilafu',
		'Gulebage',
		'Sandaoling Lutiankuang Wuqi Nongchang',
		'Siyeke',
		'Tumxuk',
		'Alamaiti',
		'Arele',
		'Awati',
		'Azhatebage',
		"Bage'awati",
		'Baishikante',
		'Dunbage',
		'Huangdi',
		"Kuoshi'airike",
		'Mixia',
		'Paikeqi',
		"Tage'erqi",
		'Tuomuwusitang',
		'Wudalike',
		"Yigai'erqi",
		'Yishikuli',
		'Bayandai',
		'Hudiyuzi',
		'Arewusitang',
		'Awuliya',
		'Samuyuzi',
		"Weiwu'eryuqiwen",
		'Arele',
		'Jiayi',
		'Buzhake',
		"Yisilamu'awati",
		'Akesalayi',
		'Jiahanbage',
		"Ka'ersai",
		'Kuiya',
		'Kuoyiqi',
		'Manglai',
		'Puqiakeqi',
		'Tuohula',
		'Tuwaite',
		"Wu'erqi",
		'Yawa',
		'Zhawa',
	],
	Xorazm: ['Urganch', 'Shovot', 'Qo’shko’pir', 'Xiva', 'Hazorasp', 'Gurlan', 'Druzhba'],
	Yala: ['Yala', 'Betong'],
	Yalova: ['Yalova'],
	Yamagata: [
		'Tsuruoka',
		'Sakata',
		'Yonezawa',
		'Yamagata',
		'Tendō',
		'Takahata',
		'Shinjō',
		'Sagae',
		'Obanazawa',
		'Nagai',
		'Kaminoyama',
		'Higashine',
		'Shonai',
	],
	Yamaguchi: [
		'Yanai',
		'Yamaguchi',
		'Ube',
		'Tokuyama',
		'Shimonoseki',
		'Onoda',
		'Ogōri-shimogō',
		'Kudamatsu',
		'Iwakuni',
		'Hōfu',
		'Hagi',
		'Hikari',
		'Nagato',
	],
	'Yamalo-Nenets': ['Tarko-Sale', 'Salekhard', 'Noyabrsk', 'Novyy Urengoy', 'Nadym', 'Labytnangi', 'Gubkinskiy', 'Muravlenko'],
	Yamanashi: [
		'Uenohara',
		'Ryūō',
		'Ōtsuki',
		'Nirasaki',
		'Kōfu',
		'Isawa',
		'Enzan',
		'Fujikawaguchiko',
		'Fujiyoshida',
		'Minami-Alps',
		'Hokuto',
		'Kai',
		'Chūō',
		'Fuefuki',
		'Kōshū',
	],
	Yambol: ['Yambol'],
	'Yanggang-do': ['Kapsan-ŭp', 'Hyesan-dong', 'Hyesan'],
	Yangon: ['Twante', 'Thongwa', 'Syriam', 'Yangon', 'Kayan', 'Kanbe'],
	Yaracuy: ['Yumare', 'Yaritagua', 'Urachiche', 'San Felipe', 'Sabana de Parra', 'Nirgua', 'Guama', 'Cocorote', 'Chivacoa', 'Boraure', 'Aroa'],
	Yaren: ['Yaren'],
	'Yaroslavl Oblast': ['Yaroslavl', 'Uglich', 'Tutayev', 'Rybinsk', 'Rostov', 'Pereslavl’-Zalesskiy', 'Gavrilov-Yam', 'Danilov'],
	Yasothon: ['Yasothon'],
	Yauco: ['Yauco'],
	Yazd: ['Yazd', 'Taft', 'Tabas', 'Meybod', 'Mahrīz', 'Bāfq', 'Ardakān'],
	Yerevan: ['Yerevan'],
	'Yevlax City': ['Yevlakh'],
	Yigo: ['Yigo Village'],
	Yobe: ['Potiskum', 'Nguru', 'Kumagunnam', 'Geidam', 'Gashua', 'Daura', 'Damaturu'],
	Yogyakarta: [
		'Yogyakarta',
		'Wonosari',
		'Srandakan',
		'Sleman',
		'Sewon',
		'Pundong',
		'Pandak',
		'Melati',
		'Godean',
		'Depok',
		'Bantul',
		'Bambanglipuro',
		'Gamping Lor',
		'Kasihan',
	],
	'Yoro Department': ['Yoro', 'Olanchito', 'El Progreso'],
	Yozgat: ['Yozgat', 'Yerköy', 'Sorgun', 'Akdağmadeni'],
	Yucatán: [
		'Valladolid',
		'Umán',
		'Tizimín',
		'Ticul',
		'Tecax',
		'Progreso',
		'Peto',
		'Oxkutzkab',
		'Motul',
		'Mérida',
		'Kanasín',
		'Izamal',
		'Hunucmá',
		'Chichén-Itzá',
	],
	'Yuen Long': ['Yuen Long Kau Hui', 'Tin Shui Wai', 'Ping Shan'],
	Yukon: ['Whitehorse'],
	Yunnan: [
		'Zhefang',
		'Zhangfeng',
		'Zibihu',
		'Yingpan',
		'Tengyue',
		'Shigu',
		'Shangyun',
		'Shangpa',
		'Ruidian',
		'Qushi',
		'Yingjiang',
		'Mengmao',
		'Menglang',
		'Mangshi',
		'Liuku',
		'Bonan',
		'Judian',
		'Jinhua',
		'Jinding',
		'Jietou',
		'Houqiao',
		'Gudong',
		'Baoshan',
		'Yuxi',
		'Zhongxin',
		'Zhongshu',
		'Zhaotong',
		'Zhanhe',
		'Yunnanyi',
		'Jinghong',
		'Yongning',
		'Yongbei',
		'Xiazhuang',
		'Xiangcheng',
		'Simao',
		'Rongjiang',
		'Renhe',
		'Qujing',
		'Qina',
		'Qianliu',
		'Pianjiao',
		'Nanjian',
		'Miyang',
		'Micheng',
		'Majie',
		'Longquan',
		'Lincang',
		'Anning',
		'Kunming',
		'Kaiyuan',
		'Kaihua',
		'Huangshan',
		'Haikou',
		'Gejiu',
		'Fengyi',
		'Lijiang',
		'Daxing',
		'Dashan',
		'Dali',
		'Mabai',
		'Jinniu',
		'Xiangjiaba',
		'Shilin',
		'Shangri-La',
		'Wenshan City',
		'Fudong',
		'Jianshui',
		'Tuantian',
		'Zhonghe',
		'Taoyuan',
		'Dali Old Town',
		'Haidong Zhen',
		'Yinqiao Zhen',
		'Wanqiao Zhen',
		'Donghui',
		'Fazhanhe',
		'Fubang',
		'Huimin',
		'Mujia',
		'Nanling',
		'Nuofu',
		'Nuozhadu',
		'Zhutang',
		'Menghuan',
		'Fengping',
		'Nongzhang',
		'Taiping',
		'Beihai',
		'Hehua',
		'Mangbang',
		'Mazhan',
		'Mingguang',
		'Puchuan',
		'Wuhe',
		'Xinhua',
		'Miaojie',
		'Yongjian',
		'Yousuo',
		'Sanchuan',
		'Chenghai',
		'Shunzhou',
		'Jiuhe',
		'Xinyingpan',
		'Shilongba',
		'Hongqiao',
		'Longling County',
	],
	'Yên Bái': ['Yên Bái', 'Nghĩa Lộ'],
	'Zabaykalskiy (Transbaikal) Kray': ['Petrovsk-Zabaykal’skiy', 'Nerchinsk', 'Krasnokamensk', 'Chita', 'Borzya', 'Aginskoye'],
	Zacapa: ['Zacapa', 'Gualán'],
	Zacatecas: [
		'Zacatecas',
		'Sombrerete',
		'Tlaltenango de Sánchez Román',
		'Río Grande',
		'Nochistlán de Mejía',
		'Loreto',
		'Juan Aldama',
		'Jerez de García Salinas',
		'Guadalupe',
		'Fresnillo',
		'Víctor Rosales',
	],
	'Zaghouan Governorate': ['Zaghouan', 'El Fahs'],
	Zaire: ['Soio', "N'zeto", 'Mbanza Kongo'],
	Zala: ['Zalaegerszeg', 'Nagykanizsa', 'Keszthely'],
	Zambezi: ['Katima Mulilo'],
	'Zamboanga Peninsula': ['Zamboanga', 'Recodo', 'Pagadian', 'Muricay', 'Molave', 'Mahayag', 'Ipil', 'Dipolog', 'Dapitan'],
	Zambézia: ['Chinde', 'Quelimane'],
	Zamfara: ['Talata Mafara', 'Moriki', 'Kaura Namoda', 'Gusau', 'Gummi'],
	'Zamora-Chinchipe': ['Zamora'],
	Zanjan: ['Khorramdarreh', 'Zanjān', 'Alvand', 'Abhar'],
	Zanzan: ['Tanda', 'Sinfra', 'Doropo', 'Bouna', 'Bondoukou'],
	'Zanzibar Central/South': ['Sokoni'],
	'Zanzibar Urban/West': ['Zanzibar'],
	Zaporizhzhia: ['Zaporizhzhya', 'Vilnyansk', 'Tokmak', 'Polohy', 'Melitopol', 'Enerhodar', 'Dniprorudne', 'Berdyansk'],
	Zaqatala: ['Zaqatala'],
	Zarqa: ['Zarqa', 'Russeifa'],
	Zealand: ['Vordingborg', 'Solrød Strand', 'Slagelse', 'Roskilde', 'Ringsted', 'Nykøbing Falster', 'Næstved', 'Køge', 'Kalundborg', 'Holbæk', 'Greve'],
	Zeeland: ['Vlissingen', 'Terneuzen', 'Middelburg', 'Goes', 'Borssele'],
	Zhambyl: ['Zhangatas', 'Taraz', 'Shu', 'Karatau', 'Sarykemer', 'Merke'],
	Zhejiang: [
		'Zhuji',
		'Zhicheng',
		'Zhaobaoshan',
		'Yuyao',
		'Haining',
		'Xiaoshan',
		'Xianju',
		'Wuzhen',
		'Dongyang',
		'Wenzhou',
		'Wenling',
		'Shenjiamen',
		'Shaoxing',
		'Rui’an',
		'Quzhou',
		'Puyang',
		'Ninghai',
		'Ningbo',
		'Lishui',
		'Linping',
		'Lanxi',
		'Kunyang',
		'Jinxiang',
		'Jinhua',
		'Jiaxing',
		'Huzhou',
		'Cixi',
		'Hangzhou',
		'Jiaojiang',
		'Guli',
		'Fuyang',
		'Deqing',
		'Fenghua',
		'Yiwu',
		'Shangyu',
		'Zhoushan',
		'Lianghu',
		'Jiashan',
		'Taizhou',
	],
	Zhelino: ['Zelino'],
	Zhytomyr: ['Zhytomyr', 'Radomyshl', 'Ovruch', 'Zviahel', 'Malyn', 'Korostyshiv', 'Korosten', 'Berdychiv'],
	Ziguinchor: ['Ziguinchor', 'Bignona'],
	Zinder: ['Zinder', 'Tanout', 'Mirriah', 'Matamey', 'Magaria'],
	Zlín: ['Zlín', 'Vsetín', 'Valašské Meziříčí', 'Uherský Brod', 'Uherské Hradiště', 'Rožnov pod Radhoštěm', 'Otrokovice', 'Kroměříž'],
	Zonguldak: ['Zonguldak', 'Ereğli', 'Devrek', 'Çaycuma', 'Alaplı'],
	Zou: ['Cové', 'Bohicon', 'Abomey'],
	Zug: ['Zug', 'Cham', 'Baar'],
	Zulia: [
		'Tía Juana',
		'Santa Rita',
		'Santa Cruz de Mara',
		'San Rafael',
		'San Carlos del Zulia',
		'La Villa del Rosario',
		'Mene Grande',
		'Maracaibo',
		'Machiques',
		'Lagunillas',
		'La Concepción',
		'Concepción',
		'Encontrados',
		'Ciudad Ojeda',
		'Chiquinquirá',
		'Casigua El Cubo',
		'Caja Seca',
		'Cabimas',
		'Los Puertos de Altagracia',
	],
	Zurich: [
		'Zürich',
		'Winterthur',
		'Wetzikon',
		'Uster',
		'Zürich (Kreis 11) / Seebach',
		'Zürich (Kreis 11) / Oerlikon',
		'Kloten',
		'Horgen',
		'Zürich (Kreis 10) / Höngg',
		'Dübendorf',
		'Dietikon',
		'Zürich (Kreis 4) / Aussersihl',
		'Adliswil',
		'Zürich (Kreis 10) / Wipkingen',
		'Zürich (Kreis 11) / Affoltern',
		'Zürich (Kreis 2) / Wollishofen',
		'Zürich (Kreis 3) / Sihlfeld',
		'Zürich (Kreis 6) / Unterstrass',
		'Zürich (Kreis 9) / Albisrieden',
		'Zürich (Kreis 9) / Altstetten',
		'Stadt Winterthur (Kreis 1)',
		'Zürich (Kreis 12)',
		'Seen (Kreis 3)',
		'Zürich (Kreis 3)',
		'Zürich (Kreis 11)',
		'Zürich (Kreis 9)',
		'Oberwinterthur (Kreis 2)',
		'Zürich (Kreis 10)',
		'Zürich (Kreis 2)',
		'Zürich (Kreis 8)',
		'Zürich (Kreis 7)',
		'Zürich (Kreis 6)',
	],
	Çankırı: ['Çerkeş', 'Çankırı'],
	Çorum: ['Sungurlu', 'Osmancık', 'İskilip', 'Çorum', 'Alaca'],
	Équateur: ['Mbandaka'],
	Évora: ['Évora'],
	'Île-de-France': [
		'Yerres',
		'Vitry-sur-Seine',
		'Viry-Châtillon',
		'Viroflay',
		'Vincennes',
		'Villiers-sur-Marne',
		'Villiers-le-Bel',
		'Villepinte',
		'Villeparisis',
		'Villeneuve-Saint-Georges',
		'Villeneuve-le-Roi',
		'Villeneuve-la-Garenne',
		'Villemomble',
		'Villejuif',
		'Vigneux-sur-Seine',
		'Versailles',
		'Verrières-le-Buisson',
		'Verneuil-sur-Seine',
		'Vélizy-Villacoublay',
		'Vauréal',
		'Vanves',
		'Tremblay-en-France',
		'Trappes',
		'Torcy',
		'Thiais',
		'Taverny',
		'Suresnes',
		'Sucy-en-Brie',
		'Stains',
		'Soisy-sous-Montmorency',
		'Sèvres',
		'Sevran',
		'Sceaux',
		'Savigny-sur-Orge',
		'Savigny-le-Temple',
		'Sartrouville',
		'Sarcelles',
		'Sannois',
		"Saint-Ouen-l'Aumône",
		'Saint-Ouen',
		'Saint-Michel-sur-Orge',
		'Saint-Maur-des-Fossés',
		'Saint-Mandé',
		'Saint-Leu-la-Forêt',
		'Saint-Gratien',
		'Saint-Germain-en-Laye',
		'Sainte-Geneviève-des-Bois',
		'Saint-Denis',
		"Saint-Cyr-l'École",
		'Saint-Cloud',
		'Rueil-Malmaison',
		'Rosny-sous-Bois',
		'Romainville',
		'Roissy-en-Brie',
		'Ris-Orangis',
		'Rambouillet',
		'Puteaux',
		'Pontoise',
		'Poissy',
		'Plaisir',
		'Pierrefitte-sur-Seine',
		'Paris',
		'Pantin',
		'Palaiseau',
		'Ozoir-la-Ferrière',
		'Osny',
		'Orsay',
		'Orly',
		'Noisy-le-Sec',
		'Noisy-le-Grand',
		'Noisiel',
		'Nogent-sur-Marne',
		'Neuilly-sur-Seine',
		'Neuilly-sur-Marne',
		'Neuilly-Plaisance',
		'Nanterre',
		'Morsang-sur-Orge',
		'Montrouge',
		'Montreuil',
		'Montmorency',
		'Montigny-lès-Cormeilles',
		'Montigny-le-Bretonneux',
		'Montgeron',
		'Montfermeil',
		'Montesson',
		'Montereau-Fault-Yonne',
		'Moissy-Cramayel',
		'Mitry-Mory',
		'Meudon',
		'Melun',
		'Meaux',
		'Maurepas',
		'Massy',
		'Marly-le-Roi',
		'Mantes-la-Ville',
		'Mantes-la-Jolie',
		'Malakoff',
		'Maisons-Laffitte',
		'Maisons-Alfort',
		'Longjumeau',
		'Lognes',
		'Livry-Gargan',
		'Limeil-Brévannes',
		'Limay',
		"L'Haÿ-les-Roses",
		'Le Vésinet',
		'Levallois-Perret',
		'Les Pavillons-sous-Bois',
		'Les Mureaux',
		'Les Lilas',
		'Les Clayes-sous-Bois',
		'Le Pré-Saint-Gervais',
		'Le Plessis-Trévise',
		'Le Plessis-Robinson',
		'Le Perreux-sur-Marne',
		'Le Pecq',
		'Le Mée-sur-Seine',
		'Le Kremlin-Bicêtre',
		'Le Chesnay',
		'Le Blanc-Mesnil',
		'Lagny-sur-Marne',
		'La Garenne-Colombes',
		'La Courneuve',
		'La Celle-Saint-Cloud',
		'Jouy-le-Moutier',
		'Joinville-le-Pont',
		'Ivry-sur-Seine',
		'Issy-les-Moulineaux',
		'Houilles',
		'Herblay',
		'Guyancourt',
		'Grigny',
		'Goussainville',
		'Gonesse',
		'Gif-sur-Yvette',
		'Gentilly',
		'Gennevilliers',
		'Garges-lès-Gonesse',
		'Garches',
		'Gagny',
		'Fresnes',
		'Franconville',
		'Fontenay-sous-Bois',
		'Fontenay-aux-Roses',
		'Fontainebleau',
		'Évry',
		'Étampes',
		'Ermont',
		'Éragny',
		'Épinay-sur-Seine',
		'Élancourt',
		'Eaubonne',
		'Draveil',
		'Drancy',
		'Domont',
		'Deuil-la-Barre',
		'Dammarie-les-Lys',
		'Créteil',
		'Courbevoie',
		'Coulommiers',
		'Cormeilles-en-Parisis',
		'Corbeil-Essonnes',
		'Conflans-Sainte-Honorine',
		'Combs-la-Ville',
		'Pontault-Combault',
		'Colombes',
		'Clichy-sous-Bois',
		'Clichy',
		'Clamart',
		'Choisy-le-Roi',
		'Chilly-Mazarin',
		'Chevilly-Larue',
		'Chennevières-sur-Marne',
		'Chelles',
		'Chaville',
		'Chatou',
		'Châtillon',
		'Châtenay-Malabry',
		'Charenton-le-Pont',
		'Champs-sur-Marne',
		'Champigny-sur-Marne',
		'Cergy',
		'Carrières-sous-Poissy',
		'Cachan',
		'Bry-sur-Marne',
		'Brunoy',
		'Brie-Comte-Robert',
		'Brétigny-sur-Orge',
		'Bourg-la-Reine',
		'Boulogne-Billancourt',
		'Bonneuil-sur-Marne',
		'Bondy',
		'Boissy-Saint-Léger',
		'Bois-Colombes',
		'Bobigny',
		'Bezons',
		'Bagnolet',
		'Bagneux',
		'Avon',
		'Aulnay-sous-Bois',
		'Aubervilliers',
		'Athis-Mons',
		'Asnières-sur-Seine',
		'Argenteuil',
		'Arcueil',
		'Antony',
		'Alfortville',
		'Achères',
		'Les Ulis',
		'La Defense',
		'Saint-Quentin-en-Yvelines',
		'Cergy-Pontoise',
	],
	'Îles du Vent': ['Punaauia', 'Papeete', 'Faaa'],
	'Ðiện Biên': ['Mường Lay', 'Dien Bien Phu'],
	'Ðắk Nông': ['Gia Nghĩa'],
	Ñeembucú: ['Pilar'],
	Ñuble: ['San Carlos', 'Chillán'],
	Ömnögovĭ: ['Dalandzadgad'],
	Örebro: ['Örebro', 'Kumla', 'Karlskoga'],
	Östergötland: ['Norrköping', 'Motala', 'Linköping'],
	Övörhangay: ['Hovd', 'Arvayheer'],
	'Ústecký kraj': [
		'Žatec',
		'Varnsdorf',
		'Ústí nad Labem',
		'Teplice',
		'Most',
		'Louny',
		'Litvínov',
		'Litoměřice',
		'Klášterec nad Ohří',
		'Kadaň',
		'Jirkov',
		'Děčín',
		'Chomutov',
		'Bílina',
	],
	Āfar: ['Dubti', 'Asaita'],
	'Đắk Lắk': ['Buôn Ma Thuột', 'Buôn Hồ', 'Ea Drăng'],
	'Đồng Nai': ['Biên Hòa'],
	'Đồng Tháp': ['Sa Dec', 'Cao Lãnh', 'Thị Trấn Tân Thành', 'Quận Đức Thịnh', 'Quận Đức Thành'],
	'Ħaż-Żabbar': ['Żabbar'],
	İmişli: ['Imishli'],
	İsmayıllı: ['İsmayıllı'],
	'İzmir Province': [
		'Urla',
		'Torbalı',
		'Tire',
		'Selçuk',
		'Seferihisar',
		'Ödemiş',
		'Menemen',
		'Kemalpaşa',
		'İzmir',
		'Foça',
		'Menderes',
		'Çeşme',
		'Bergama',
		'Bayındır',
		'Aliağa',
		'Karabağlar',
	],
	'Łódź Voivodeship': [
		'Tomaszów Mazowiecki',
		'Skierniewice',
		'Rawa Mazowiecka',
		'Opoczno',
		'Zgierz',
		'Zduńska Wola',
		'Wieluń',
		'Sieradz',
		'Radomsko',
		'Piotrków Trybunalski',
		'Pabianice',
		'Ozorków',
		'Łowicz',
		'Łódź',
		'Łęczyca',
		'Łask',
		'Kutno',
		'Konstantynów Łódzki',
		'Głowno',
		'Bełchatów',
		'Aleksandrów Łódzki',
	],
	Ōsaka: [
		'Yao',
		'Toyonaka',
		'Tondabayashichō',
		'Takatsuki',
		'Takaishi',
		'Suita',
		'Sakai',
		'Osaka',
		'Moriguchi',
		'Mino',
		'Matsubara',
		'Kishiwada',
		'Kashihara',
		'Kaizuka',
		'Kadoma',
		'Izumisano',
		'Izumiōtsu',
		'Izumi',
		'Ikeda',
		'Ibaraki',
		'Hirakata',
		'Higashi-ōsaka',
		'Daitō',
		'Neyagawa',
		'Habikino',
		'Hannan',
	],
	'Ŏtâr Méanchey': ['Samraong'],
	Świętokrzyskie: [
		'Staszów',
		'Starachowice',
		'Skarżysko-Kamienna',
		'Sandomierz',
		'Ostrowiec Świętokrzyski',
		'Końskie',
		'Kielce',
		'Jędrzejów',
		'Busko-Zdrój',
	],
	Şamaxı: ['Shamakhi'],
	Şanlıurfa: ['Viranşehir', 'Şanlıurfa', 'Suruç', 'Siverek', 'Hilvan', 'Ceylanpınar', 'Bozova', 'Birecik', 'Akçakale'],
	'Şa‘dah': ["Sa'dah"],
	Şırnak: ['Şırnak', 'Silopi', 'İdil', 'Cizre'],
	Şǝmkir: ['Shamkhor'],
	'Šibenik-Knin': ['Šibenik'],
	'Žilinský kraj': ['Žilina', 'Ružomberok', 'Martin', 'Liptovský Mikuláš', 'Kysucké Nové Mesto', 'Dolný Kubín', 'Čadca'],
	Ḩajjah: ['Ḩajjah'],
}

export default statesToCities
