import { chatsAtom, usersAtom } from '@atoms'
import { selectorFamily } from 'recoil'

export const userChatSelector = selectorFamily({
	key: 'userChatSelector',
	get:
		(props) =>
		({ get }) => {
			const { id, selectedChatId } = props

			const chats = get(chatsAtom)
			const users = get(usersAtom)
			// console.log({ selectedChatId, chats })
			const userChats = chats
				.filter((chat) => chat.users.some((user) => user?._id === id))
				.map((chat) => ({
					...chat,
					selected: selectedChatId === chat._id || chat.users.some((user) => user?._id === selectedChatId),
					users: chat.users.map((user) => ({ ...user, ...users.find((u) => u._id === user._id) })),
				}))
			console.log({ userChats })
			return userChats
		},
})
