import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Scott from './images/scott.png';
import Boy from './images/boy.jpg';
import BoyTwo from './images/boyTwo.jpg';
import BoyThree from './images/boyThree.jpg';
import BoyFour from './images/boyFour.jpg';
import Rebecca from './images/rebecca.png';
import Melanie from './images/melanie.png';
import Isbell from './images/isbell.jpg';

export default function SimpleSlider() {

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#9B83CB" , borderRadius: "11px" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "#9B83CB" , borderRadius: "11px" }}
        onClick={onClick}
      />
    );
  }
  
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: window.innerWidth > 768 ? 3:1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
  return (
    <Slider {...settings}>
      <div>
        <div className ='innerTestimonial'>
        <p className='text-[18px] text-[#666666] font-[300] text-center'>
					"Easy to sign up and list the place."
					</p>
          <div className ='innerInnerTestimonial'>
          <img className='testimonialImage' src={Scott} alt='' />
            <div>
            <p className={`text-[#2A2A2A] font-[600] text-[17px] leading-[22px] pl-2`}>Scott Beattie</p>
            <p className='text-[16px] text-[#666666] font-[300] pl-2'>DIGITAL NOMAD</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className ='innerTestimonial'>
        <p className='text-[18px] text-[#666666] font-[300] text-center'>
					"Efficient and reliable platform."
					</p>
          <div className ='innerInnerTestimonial'>
          <img className='testimonialImage' src={Boy} alt='' />
            <div>
            <p className={`text-[#2A2A2A] font-[600] text-[17px] leading-[22px] pl-2`}>Mark Thompson</p>
            <p className='text-[16px] text-[#666666] font-[300] pl-2'>PRODUCT MANAGER</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className ='innerTestimonial'>
        <p className='text-[18px] text-[#666666] font-[300] text-center'>
					"Smooth process from start to finish."
					</p>
          <div className ='innerInnerTestimonial'>
          <img className='testimonialImage' src={Rebecca} alt='' />
            <div>
            <p className={`text-[#2A2A2A] font-[600] text-[17px] leading-[22px] pl-2`}>Rebecca Jarrett-Dalton</p>
            <p className='text-[16px] text-[#666666] font-[300] pl-2'>TRAVELLING ARTIST</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className ='innerTestimonial'>
        <p className='text-[18px] text-[#666666] font-[300] text-center'>
        "Simple registration process and property listing."
					</p>
          <div className ='innerInnerTestimonial'>
          <img className='testimonialImage' src={Melanie} alt='' />
            <div>
            <p className={`text-[#2A2A2A] font-[600] text-[17px] leading-[22px] pl-2`}>Melanie</p>
            <p className='text-[16px] text-[#666666] font-[300] pl-2'>HOLISTIC PRACTITIONER</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className ='innerTestimonial'>
        <p className='text-[18px] text-[#666666] font-[300] text-center'>
        "Outstanding property search results in all the main cities."
					</p>
          <div className ='innerInnerTestimonial'>
          <img className='testimonialImage' src={Isbell} alt='' />
            <div>
            <p className={`text-[#2A2A2A] font-[600] text-[17px] leading-[22px] pl-2`}>Isbell Springer</p>
            <p className='text-[16px] text-[#666666] font-[300] pl-2'>PSYCHOTHERAPIST</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className ='innerTestimonial'>
        <p className='text-[18px] text-[#666666] font-[300] text-center'>
        "Simple to exchange with confidence and trust."
					</p>
          <div className ='innerInnerTestimonial'>
          <img className='testimonialImage' src={BoyFour} alt='' />
            <div>
            <p className={`text-[#2A2A2A] font-[600] text-[17px] leading-[22px] pl-2`}>Taylor Charlotte</p>
            <p className='text-[16px] text-[#666666] font-[300] pl-2'>SOFTWARE DEVELOPER</p>
            </div>
          </div>
        </div>
      </div>
    </Slider>
  );
}