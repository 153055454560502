import { AlertBanner, Form } from '@components'
import { firebase } from '../../auth/firebase/config'
import Image from '../../assets/drive-assets/erol-ahmed-FTy5VSGIfiQ-unsplash (1).jpg'
import { useNavigate, useParams } from 'react-router-dom'
import { authFormSchema } from './helpers/authFormSchemas'
import { Button } from 'antd'
import { userAtom } from '../../recoil/atoms'
import { authSelector } from '../../recoil/selectors'
import { AUTH_EVENTS, useAuth } from '@auth'
import { useEffect, useState } from 'react'
import PaymentInfo from './components/paymentInfo'
import AuthIcon from '../../assets/images/Logomark.png'
import StepsSignup from './components/stepsSignup'
import { useSetRecoilState } from 'recoil'
const Auth = (props) => {
	const userData = JSON.parse(localStorage.getItem('user'))
	const { action } = useParams()
	const [form] = Form.useForm()
	const navigator = useNavigate()
	const [success, setSuccess] = useState(false)
	const [alert, setAlert] = useState({ type: '', message: '' })
	const setUserAtom = useSetRecoilState(userAtom)
	const [dispatch, loading, signupComplete, userEmail] = useAuth({
		reroute: '/home',
		userAtom: userAtom,
		authSelector: authSelector,
		setAlert,
	})


	const userDataNew = JSON.parse(localStorage.getItem('user'))
	// Assuming userDataCreatedAt is a valid date object or string representing a date
	const userCreatedAt = new Date(userDataNew?.createdAt);
	// Create a new date that is one month later than userDataCreatedAt
	const oneMonthLaterDate = new Date(userCreatedAt);
	oneMonthLaterDate.setMonth(oneMonthLaterDate.getMonth() + 1);
	// Compare the new date with the current date
	const currentDate = new Date();


	useEffect(() => {
		setAlert({ type: '', message: '' })
	}, [])

	return (
		<div className=' min-h-screen max-h-[110vh] flex'>
			<div className='w-[50%] h-full hidden lg:block rounded-[0_80px_0px_0]'>
				<img className='w-full h-full object-cover' src={Image} alt='' />
			</div>
			<div className='w-[50%] overflow-y-scroll max-lg:w-full h-full flex flex-col justify-center items-center'>
				{(action === 'signup'||action === 'new') && (signupComplete||action === 'new') && !success && (
					<Button
						className='mr-8 mt-8 self-end btn-primary'
						onClick={() => {
							firebase.auth().signOut()
							localStorage.setItem('user', JSON.stringify(null))
							localStorage.setItem('token', JSON.stringify(null))
							localStorage.setItem('premium', JSON.stringify(null))
							localStorage.setItem('flexibleDatesChecked', JSON.stringify('false'))
							setUserAtom(null)
							navigator('/home/about')
						}}
					>
						<p size={1} className='text-white pl-2 '>
							LOGOUT
						</p>
					</Button>
				)}
				{action === 'signup'||action === 'new' ? (
					<div className='flex flex-col justify-center items-center mt-16'>
						{signupComplete || action === 'new' ? (
							<>
								<div className='flex flex-col items-center w-[300px] mx-auto'>
									<div className='w-14 h-14 mx-auto'>
										<img className='w-14 h-14 object-contain' src={AuthIcon} alt='' />
									</div>

									<p className={`text-[#101828] font-[600] text-[30px] leading-[38px] ${action === 'login' ? 'pb-3' : 'pb-3'} text-center`}>
										{success ? 'Subscription Successful' : ( action === 'new') ? 'Subscribe':(oneMonthLaterDate < currentDate) ? 'Your trial has ended':'Create an account'}
									</p>
									<p className={`text-[#6d6e78] text-start font-[400] text-[0.75rem] leading-[1.45rem] ${action === 'login' ? 'pb-3' : 'pb-2'} text-center`}>
										{success
											? 'Your subscription is now active!'
											: ( action === 'new') 
											? `Continue for only $204 AUD/Year. Cancel anytime before your trial period ends.`
											: (oneMonthLaterDate < currentDate) 
											? `Continue for only $204 AUD/Year.`
											: `Enjoy a free trial on us. Afterwards, continue for only $204 AUD/Year. Cancel anytime before your trial period ends.`}
									</p>
								</div>
								<PaymentInfo success={success} setSuccess={setSuccess} userEmail={action === 'new' ? userData?.email: userEmail} action={action === 'new' ? 'new' : null}/>
							</>
						) : (
							<div>
								<StepsSignup dispatch={dispatch} dispatchLoading={loading} alert={alert} setAlert={setAlert} />
							</div>
						)}
					</div>
				) : (
					<Form
						key='AuthForm'
						layout='vertical'
						className='w-3/4 md:w-[55%] overflow-y-scroll mt-32'
						form={form}
						onFinish={(values) =>
							dispatch({
								type: action === 'login' ? AUTH_EVENTS.LOGIN : action === 'signup' ? AUTH_EVENTS.SIGNUP : AUTH_EVENTS.RESET_PASSWORD,
								payload: values,
							})
						}
					>
						<div className='w-14 h-14 mx-auto mb-6'>
							<img className='w-14 h-14 object-contain' src={AuthIcon} alt='' />
						</div>
						<p className={`text-[#101828] font-[600] text-[30px] leading-[38px] ${action === 'login' ? 'pb-3' : 'pb-8'} text-center`}>
							{action === 'signup' ? 'Create an account' : action === 'forgotPassword' ? 'Reset Password' : 'Log in to your account'}
						</p>
						{action === 'login' ? (
							<p className='text-[#475467] font-[400] text-[16px] leading-6 pb-8 text-center'>Welcome back! Please enter your details.</p>
						) : null}
						{alert.type !== '' && AlertBanner(alert, setAlert)}
						{Form.renderSchema(authFormSchema(action))}
						{action === 'login' ? (
							<div className='flex flex-row justify-between items-center'>
								{Form.renderFormItem({
									key: 'remember',
									type: 'checkbox',
									name: ['remember'],
									label: 'Remember for 30 days',
								})}
								<a href='/auth/forgotPassword' className='text-[#6941C6] font-[600]'>
									Forgot Password
								</a>
							</div>
						) : null}
						{action === 'signup' ? <p className='text-[#475467] text-sm font-[400]'>Must be at least 8 characters.</p> : null}
						<div className='w-full pt-4 text-center'>
							<Form.Item>
								<Button className='btn-primary' htmlType='submit' disabled={loading} loading={loading} block>
									{loading ? 'Please Wait' : action === 'login' ? 'Login' : action === 'signup' ? 'Sign Up' : 'Send Reset Pasword Link'}
								</Button>
							</Form.Item>
						</div>

						<p className='text-sm font-[400] text-[#475467] text-center'>
							{action === 'login' ? "Don't have an account ? " : action === 'forgotPassword' ? 'Try logging in ? ' : 'Already have an account ? '}
							<a href={action === 'signup' ? '/auth/login' : action === 'forgotPassword' ? '/auth/login' : '/auth/signup'} className='text-[#6941C6] font-[600]'>
								{action === 'signup' ? 'Log in' : action === 'forgotPassword' ? 'Log in' : 'Sign Up'}
							</a>
						</p>
					</Form>
				)}
			</div>
		</div>
	)
}

export default Auth
