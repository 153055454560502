import React, { useState, useEffect } from 'react';
import { PrimaryHeader, Footer } from '@components'
import axios from 'axios';
import Post from './Post';
import cover from './aboutCover.png';
import './blog.modules.css';



// Note: This is the main blog page which have thumbnails of all the blogs.

const Posts = () => {
    const [posts, setPosts] = useState([]);
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}posts`)
            .then(res => setPosts(res.data))
            .catch(err => console.error(err));
    }, []);

    return (
        <div >
            
            <PrimaryHeader />
        <div className='mainClassAboutWebsite'>
            <br></br>
            <br></br>
            <br></br> 

        <div className='mainClassAboutContainer'>
            {/* <img src={cover} className='aboutCoverImage' alt='Ali' /> */}
            <br></br><br></br><br></br>

            <p className='text-[2rem] leading-[2.8rem] text-[#474747] font-bold pb-6 text-center'>
				Blog
					</p>
					<p className='font-[500] text-[gray] text-[20px] leading-[26px] pb-4 text-center'>Explore our blogs for trendy ideas. </p>
            <div className='flexPost'>
                {posts.map(post => (
                    <Post key={post._id} post={post} />
                ))}
            </div>
        </div>
        </div>
        <Footer />
        </div>
    );
};

export default Posts;
