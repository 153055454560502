import { Button } from 'antd'
import { PrimaryHeader, Footer } from '@components'
import BG6 from '../../assets/drive-assets/outsite-co-R-LK3sqLiBw-unsplash.jpg'
import { useNavigate } from 'react-router-dom'
import Month from './images/month.png'
import Year from './images/year.png'
import Check from './images/check.svg'

const PricingNew = () => {
	const navigator = useNavigate()
	function isMobileView() {
		return window.innerWidth < 768;
	}

	return (
		<div className=''>

			<PrimaryHeader />
			<div className='flex flex-col lg:px-24 md:px-12 max-md:px-6 items-center pt-32 pb-24 min-h-[700px] extraLargePadding'>

				<p className='text-[2rem] leading-[2.8rem] text-[#474747] font-bold pb-6 text-center'>
				Choose a plan to start swapping and subleasing
					</p>
					<p className='font-[500] text-[gray] text-[20px] leading-[26px] pb-4'>Explore our global community free for a month </p>


					<div className='mainFlexB'>
						<div className='flex flex-col lg:px-24 md:px-12 xs:px-0 items-center pt-4 pb-2 mb-6 min-h-[500px] extraLargePadding cardDesign'>
								<img className='w-[100%] object-cover pb-2' src={Month} alt='' />
								<p className='text-[1.5rem] leading-[1.9rem] text-[#474747] font-sami-bold pb-6 pt-8'>
								Free Trial - 30 Days</p>
								<p className='font-[500] text-[gray] text-[16px] leading-[22px] pb-3'>Start Your Adventure with Our Free Trial </p>
								
								<div className='w-[100%]'>
									<p className='font-[500] text-[gray] text-[16px] leading-[22px] pb-3'>What's Included ? </p>
									<div className='flex items-start'>
										<img className='w-24px pr-3' src={Check} alt='' />
										<p className='font-[500] text-[gray] text-[16px] leading-[26px] pb-3'><b>Unlimited Property Listings:</b> {isMobileView() ? <br></br> : ''} Showcase up to 5 of your homes for swap or sublease without limitations.</p>
									</div>
									<div className='flex items-start'>
										<img className='w-24px pr-3' src={Check} alt='' />
										<p className='font-[500] text-[gray] text-[16px] leading-[26px] pb-3'><b>Date Flexibility: </b> {isMobileView() ? <br></br> : ''} Enjoy the freedom to make swap requests within a 30-day timeframe during your trial.
</p>
									</div>
									<div className='flex items-start'>
										<img className='w-24px pr-3' src={Check} alt='' />
										<p className='font-[500] text-[gray] text-[16px] leading-[26px] pb-3'><b>Essential Support:</b> {isMobileView() ? <br></br> : ''} Connect with our support team during regular business hours for any help needed.</p>
									</div>
									<div className='flex items-start'>
										<img className='w-24px pr-3' src={Check} alt='' />
										<p className='font-[500] text-[gray] text-[16px] leading-[26px] pb-4'><b>Easy Sign-Up, No Cost:</b> {isMobileView() ? <br></br> : ''} We offer hassle-free sign-up without credit card information or upfront payments.
</p>
									</div>

								</div>
								
								<Button className='btn-primary !h-[50px] w-[100%]' onClick={() => navigator('/auth/signup')}>
									Start Free Trial
								</Button>
								<p className='font-[500] text-[gray] text-[16px] leading-[26px] pt-4 pb-3 text-center'>City Swapp offers premium benefits at no cost! Take advantage of a limited-time trial of our Premium Plan now!</p>
						</div>


						<div className='flex flex-col lg:px-24 md:px-12 xs:px-0 items-center pt-4 pb-2 mb-6 min-h-[500px] extraLargePadding cardDesign'>
								<img className='w-[100%] object-cover pb-2' src={Year} alt='' />
								<p className='text-[1.5rem] leading-[1.9rem] text-[#474747] font-sami-bold pb-6 pt-8'>
								Premium</p>
								<p className='font-[500] text-[gray] text-[16px] leading-[22px] pb-3 text-center'>Join the Elite Home-Swapping & Subleasing Community today 
and unlock premium features </p>
								
								<div className='w-[100%]'>
									<p className='font-[500] text-[gray] text-[16px] leading-[22px] pb-3'>What's Included ? </p>
									<div className='flex items-start'>
										<img className='w-24px pr-3' src={Check} alt='' />
										<p className='font-[500] text-[gray] text-[16px] leading-[26px] pb-3'><b>Unlimited Property Listings:</b> {isMobileView() ? <br></br> : ''} Showcase up to 5 of your homes for swap or sublease without limitations.</p>
									</div>
									<div className='flex items-start'>
										<img className='w-24px pr-3' src={Check} alt='' />
										<p className='font-[500] text-[gray] text-[16px] leading-[26px] pb-3'><b>Date Flexibility: </b> {isMobileView() ? <br></br> : ''} Enjoy the freedom to make swap requests within a 30-day timeframe during your trial.
</p>
									</div>
									<div className='flex items-start'>
										<img className='w-24px pr-3' src={Check} alt='' />
										<p className='font-[500] text-[gray] text-[16px] leading-[26px] pb-3'><b>Essential Support:</b> {isMobileView() ? <br></br> : ''} Connect with our support team during regular business hours for any help needed.</p>
									</div>
									<div className='flex items-start'>
										<img className='w-24px pr-3' src={Check} alt='' />
										<p className='font-[500] text-[gray] text-[16px] leading-[26px] pb-3'><b>Exclusive Savings:</b> {isMobileView() ? <br></br> : ''} Benefit from special discounts on affiliated services to enrich your experience.</p>
									</div>
									<div className='flex items-start'>
										<img className='w-24px pr-3' src={Check} alt='' />
										<p className='font-[500] text-[gray] text-[16px] leading-[26px] pb-3'><b>Transparent Pricing:</b> {isMobileView() ? <br></br> : ''} Know exactly what you're paying for with our clear and straightforward pricing.</p>
									</div>
									<div className='flex items-start'>
										<img className='w-24px pr-3' src={Check} alt='' />
										<p className='font-[500] text-[gray] text-[16px] leading-[26px] pb-4'><b>Fee-Free Experience:</b> {isMobileView() ? <br></br> : ''} With our $204 AUD/year plan, say goodbye to booking fees and enjoy hastle-free transactions.</p>
									</div>

								</div>
								
								<Button className='btn-primary !h-[50px] w-[100%]' onClick={() => {navigator('/auth/signup')
								localStorage.setItem('premium', JSON.stringify('premium')) }}>
									Upgrade Now
								</Button>
								<p className='font-[500] text-[gray] text-[16px] leading-[26px] pt-4 pb-3 text-center'>Don't miss out! Limited slots are available for special bonuses.</p>
						</div>
				

				</div>
			</div>
			<Footer />
		</div>
	)
}
export default PricingNew
