import { useNavigate } from 'react-router-dom'
import { FooterLogo } from '@components'
import dri from './images/Instagram.svg';
import ins from './images/tik.svg';
import beh from './images/in.svg';
import lin from './images/fb.svg';
import './sectionFooter.modules.css';

const Footer = () => {
	const navigator = useNavigate()
	return (
		<div className='w-full bg-[#664F94] min-h-[280px] h-fit flex flex-row items-center'>
			<div className='flex md:flex-row max-md:flex-col  w-full md:justify-between lg:px-32 md:px-12 max-md:px-6 py-12  md:items-end max-md:items-center max-md:justify-between max-md:mx-auto  min-h-[250px] h-fit'>
				<div className='flex flex-col justify-center items-center'>
					<FooterLogo />
					<p className='text-white font-bold lg:text-sm md:text-xs max-md:text-[10px] max-md:font-[400]'>Copyright {new Date().getFullYear()} City Swapp</p>
					<br></br>
					<div className='socialMediaIconsContainer'>
                            <a href="https://www.tiktok.com/@cityswappp?_t=8goycrJ4WEk&_r=1" target="_blank"><img src={ins} className='socialMediaIcon' alt='Twitter Logo' /></a>
                            <a href="https://www.facebook.com/cityswappofficial" target="_blank"><img src={lin} className='socialMediaIcon' alt='Linkedin Logo' /></a>
                            <a href="https://www.instagram.com/city_swapp/" target="_blank"><img src={dri} className='socialMediaIcon' alt='Github Logo' /></a>
                            <a href="https://www.linkedin.com/company/city-swapp/" target="_blank"><img src={beh} className='socialMediaIcon' alt='Instagram Logo' /></a>
                    </div>
				</div>
				<div className='md:hidden max-md:block w-full h-1 border-b border-solid border-[#fafafa55] max-md:pt-6'></div>
				<div className='flex md:flex-row max-md:flex-col max-md:items-center max-md:justify-between md:space-x-9 max-md:pt-8 max-md:space-y-4'>
					<p className='text-[#E5E5E5] hover:text-white cursor-pointer font-[400] lg:text-sm sm:text-xs' onClick={() => navigator('/privacy')}>
						Privacy Policy
					</p>
					<p className='text-[#E5E5E5] hover:text-white cursor-pointer font-[400] lg:text-sm sm:text-xs' onClick={() => navigator('/terms-and-conditions')}>
						Terms & Conditions
					</p>
					<p className='text-[#E5E5E5] hover:text-white cursor-pointer font-[400] lg:text-sm sm:text-xs' onClick={() => navigator('/about-us')}>
						About Us
					</p>
					{/* <p className='text-[#E5E5E5] hover:text-white cursor-pointer font-[400] lg:text-sm sm:text-xs' onClick={() => navigator('/contact')}>
						Contact
					</p> */}
					<a href="mailto:jay@cityswapp.com"><p className='text-[#E5E5E5] hover:text-white cursor-pointer font-[400] lg:text-sm sm:text-xs' >
					Contact
				</p></a>
				</div>
			</div>
		</div>
	)
}

export default Footer
